import React, { Component, useReducer, useState, useEffect } from 'react';
import {
    StyleSheet,
    ScrollView,
    Image,
    View,
    Text,
    Alert,
    Dimensions,
    TouchableOpacity,
    Switch,
    FlatList,
    Modal,
    TextInput,
    KeyboardAvoidingView,
    PermissionsAndroid,
    Platform,
    ActivityIndicator,
    useWindowDimensions,
} from 'react-native';
import Colors from "../constant/Colors";
import SideBar from './SideBar';
import Feather from 'react-native-vector-icons/Feather';
import Icon from 'react-native-vector-icons/FontAwesome5';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as User from '../util/User';
import { Picker } from "react-native";
import DropDownPicker from 'react-native-dropdown-picker';
import Entypo from 'react-native-vector-icons/Entypo';
import Styles from '../constant/Styles';
import moment, { now } from 'moment';
//import moment from "moment-timezone";
import Ionicon from 'react-native-vector-icons/Ionicons';
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import { ReactComponent as Magnify } from "../assets/svg/Magnify.svg";
import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";

import { Rect, Text as TextSVG, Svg } from "react-native-svg";
import DropDownPickerPlus from './components/DropDownPickerPlus'
import Icon1 from "react-native-vector-icons/Feather";
import AntDesign from 'react-native-vector-icons/AntDesign';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import { CommonStore } from '../store/commonStore';
import { listenToUserChangesMerchant, listenToMerchantIdChangesMerchant, listenToCurrOutletIdChangesWaiter, listenToAllOutletsChangesMerchant, listenToCommonChangesMerchant, listenToSelectedOutletItemChanges, convertArrayToCSV, listenToSelectedOutletTableIdChanges, requestNotificationsPermission, sortReportDataList, listenToSelectedCustomerChangesMerchant, generateEmailReport } from '../util/common';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { ORDER_TYPE_PARSED, NOTIFICATIONS_TYPE, REPORT_SORT_FIELD_TYPE, TABLE_PAGE_SIZE_DROPDOWN_LIST, MERCHANT_VOUCHER_TYPE_PARSED, EMAIL_REPORT_TYPE } from '../constant/common';
import { OutletStore } from '../store/outletStore';

import { CHART_DATA, CHART_TYPE, FS_LIBRARY_PATH, CHART_Y_AXIS_DROPDOWN_LIST, CHART_FIELD_COMPARE_DROPDOWN_LIST, CHART_FIELD_NAME_DROPDOWN_LIST, CHART_FIELD_TYPE, CHART_FIELD_COMPARE_DICT, CHART_PERIOD } from '../constant/chart';

import { parseMessages } from '../util/notifications';
import { NotificationStore } from '../store/notificationStore';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";

import XLSX from 'xlsx';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
const { nanoid } = require('nanoid');



const VoucherReport = (props) => {
    const {
        navigation,
    } = props;

    const { height: windowHeight, width: windowWidth } = useWindowDimensions();
    const [currentDateTime, setCurrentDateTime] = useState(Date().toLocaleString());
    const [tempData, setTempData] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [yearlyData, setYearlyData] = useState([]);
    const [months, setMonths] = useState([]);
    const [test, setTest] = useState([]);
    const [yearlyData1, setYearlyData1] = useState([]);
    const [months1, setMonths1] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [test1, setTest1] = useState([]);
    const [displayTodayData, setDisplayTodayData] = useState([]);
    const [displayTodayLabel, setDisplayTodayLabel] = useState([]);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [currentOutletTotalSales, setCurrentOutletTotalSales] = useState(0);
    const [allDaySalesDetails, setAllDaySalesDetails] = useState([]);
    const [allDayTotalTransaction, setAllDayTotalTransaction] = useState(0);
    const [allDayTotalSold, setAllDayTotalSold] = useState(0);
    const [totalSold, setTotalSold] = useState(0);
    const [selectedTotalSales, setSelectedTotalSales] = useState(0);
    const [selectedOutlets, setSelectedOutlets] = useState([]);
    const [outletLabel, setOutletLabel] = useState([]);
    const [outletSalesData, setOutletSalesData] = useState([{
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        color: (opacity = 1) => Colors.tabMint,
    }]);
    const [viewDownload, setvViewDownload] = useState(0);
    const [viewEmail, setViewEmail] = useState(0);
    const [CsvData, setCsvData] = useState([]);
    const [exportEmail, setExportEmail] = useState('');
    const [visible, setVisible] = useState(false);
    const [switchMerchant, setSwitchMerchant] = useState(false);

    const [showDateTimePicker, setShowDateTimePicker] = useState(false);
    const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
    const [showDateTimePickerFilterLineChart, setShowDateTimePickerFilterLineChart] = useState(false);
    const [showDateTimePickerFilterBarChart, setShowDateTimePickerFilterBarChart] = useState(false);

    const [rev_date, setRev_date] = useState(moment().subtract(6, 'days').startOf('day'));
    const [rev_date1, setRev_date1] = useState(moment().endOf(Date.now()).endOf('day'));

    const [perPage, setPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentDetailsPage, setCurrentDetailsPage] = useState(1);

    const [search, setSearch] = useState('');

    const [expandDetailsDict, setExpandDetailsDict] = useState({});

    const [isLoadingExcel, setIsLoadingExcel] = useState(false);
    const [isLoadingCsv, setIsLoadingCsv] = useState(false);

    //////////////////////////////////////////////////////////////

    const [todaySalesChart, setTodaySalesChart] = useState({

    });

    const [todaySalesChartPeriod, setTodaySalesChartPeriod] = useState(CHART_PERIOD.TODAY);

    const [salesLineChart, setSalesLineChart] = useState({

    });

    const [salesLineChartPeriod, setSalesLineChartPeriod] = useState(CHART_PERIOD.THIS_MONTH);
    const [salesBarChartPeriod, setSalesBarChartPeriod] = useState(CHART_PERIOD.THIS_MONTH);

    const [dailySalesDetailsList, setDailySalesDetailsList] = useState([]);

    const [selectedItemSummary, setSelectedItemSummary] = useState({});

    const userName = UserStore.useState(s => s.name);
    const merchantName = MerchantStore.useState(s => s.name);

    const merchantId = UserStore.useState(s => s.merchantId);
    const role = UserStore.useState(s => s.role);
    const firebaseUid = UserStore.useState(s => s.firebaseUid);

    const currOutletId = MerchantStore.useState(s => s.currOutletId);
    const currOutlet = MerchantStore.useState(s => s.currOutlet);
    const allOutlets = MerchantStore.useState(s => s.allOutlets);
    // const outletsTaxDict = CommonStore.useState(s => s.outletsTaxDict);

    const selectedOutletItem = CommonStore.useState(s => s.selectedOutletItem);
    const selectedOutletTable = CommonStore.useState(s => s.selectedOutletTable);

    const userOrders = OutletStore.useState(s => s.userOrders);

    const merchantVouchers = CommonStore.useState(s => s.merchantVouchers);
    const merchantVoucherReportsVoucherIdDict = CommonStore.useState(s => s.merchantVoucherReportsVoucherIdDict);

    const crmUsers = OutletStore.useState(s => s.crmUsers);
    const crmUsersRaw = OutletStore.useState(s => s.crmUsersRaw);
    const linkedMerchantIdUsers = OutletStore.useState(s => s.linkedMerchantIdUsers);
    const favoriteMerchantIdUsers = OutletStore.useState(s => s.favoriteMerchantIdUsers);

    const nUserOrder = NotificationStore.useState(s => s.nUserOrder);
    const nUserReservation = NotificationStore.useState(s => s.nUserReservation);
    const nUserQueue = NotificationStore.useState(s => s.nUserQueue);
    const nOutletSupplyItemLow = NotificationStore.useState(s => s.nOutletSupplyItemLow);

    const selectedCustomerEdit = CommonStore.useState(s => s.selectedCustomerEdit);
    const selectedCustomerDineInOrders = CommonStore.useState(s => s.selectedCustomerDineInOrders);

    const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);

    const outletSupplyItems = CommonStore.useState(s => s.outletSupplyItems);
    const userReservations = OutletStore.useState(s => s.userReservations);
    const userQueues = OutletStore.useState(s => s.userQueues);

    const [list1, setList1] = useState(true);

    const [showDetails, setShowDetails] = useState(false);
    const [transactionTypeSalesDetails, setTransactionTypeSalesDetails] = useState([]);

    const [exportModalVisibility, setExportModalVisibility] = useState(false);

    const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
    const [selectedTargetOutletId, setSelectedTargetOutletId] = useState('');


    const [expandBarSelection, setExpandBarSelection] = useState(props.expandBarSelection === undefined ? false : props.expandBarSelection);
    const [barFilterTapped, setBarFilterTapped] = useState(props.barFilterTapped === undefined ? 0 : props.barFilterTapped);
    const [expandLineSelection, setExpandLineSelection] = useState(false);
    const [lineFilterTapped, setLineFilterTapped] = useState(props.lineFilterTapped === undefined ? 0 : props.lineFilterTapped);

    const [selectedChartDropdownValueLineChart, setSelectedChartDropdownValueLineChart] = useState(CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value);
    const [selectedChartFilterQueriesLineChart, setSelectedChartFilterQueriesLineChart] = useState([
        {
            fieldNameKey: CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value,
            fieldNameType: CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].fieldType,
            fieldCompare: CHART_FIELD_COMPARE_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value,
            fieldDataValue: null,
        },
    ]);
    const [appliedChartFilterQueriesLineChart, setAppliedChartFilterQueriesLineChart] = useState([]);

    const [selectedChartDropdownValueBarChart, setSelectedChartDropdownValueBarChart] = useState(CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0].value);
    const [selectedChartFilterQueriesBarChart, setSelectedChartFilterQueriesBarChart] = useState([
        {
            fieldNameKey: CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0].value,
            fieldNameType: CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0].fieldType,
            fieldCompare: CHART_FIELD_COMPARE_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0].value,
            fieldDataValue: null,
        },
    ]);
    const [appliedChartFilterQueriesBarChart, setAppliedChartFilterQueriesBarChart] = useState([]);

    const [currReportSummarySort, setCurrReportSummarySort] = useState('');
    const [currReportDetailsSort, setCurrReportDetailsSort] = useState('');

    const [overviewTotalViews, setOverviewTotalViews] = useState(0);
    const [overviewTotalRedeems, setOverviewTotalRedeems] = useState(0);
    const [overviewTotalUses, setOverviewTotalUses] = useState(0);
    const [overviewTotalExpires, setOverviewTotalExpires] = useState(0);

    const outletsUserOrdersDone = CommonStore.useState(s => s.outletsUserOrdersDone);

    const allOutletsUserOrdersDone = OutletStore.useState(s => s.allOutletsUserOrdersDone);
    const [pushPagingToTop, setPushPagingToTop] = useState(false);

    /////////////////////////////////////////////////////////////////////////////////////

    // useEffect(() => {

    //     var targetOutletDropdownListTemp = allOutlets.map(outlet => ({ label: outlet.name, value: outlet.uniqueId }));

    //     setExpandLineSelection(false);

    //     CommonStore.update(s => {
    //         s.outletSelectDropdownView = () => {
    //             return (
    //                 <>
    //                     {
    //                         currOutletId.length > 0 && allOutlets.find(item => item.uniqueId === currOutletId)
    //                             ?
    //                             <DropDownPicker
    //                                 containerStyle={{ height: 40, paddingVertical: 0 }}
    //                                 style={{ backgroundColor: '#345F4A', borderWidth: 0, paddingVertical: 0 }}
    //                                 arrowSize={20}
    //                                 arrowColor={'#ffffff'}
    //                                 arrowStyle={{ fontWeight: 'bold', marginLeft: 5 }}
    //                                 //itemStyle={{ justifyContent: 'flex-start' }}
    //                                 placeholder='Choose outlet'
    //                                 placeholderStyle={{ color: Colors.whiteColor, fontWeight: '600' }}
    //                                 labelStyle={{ color: Colors.whiteColor, fontWeight: '600' }}
    //                                 dropDownStyle={{ backgroundColor: '#345F4A', borderRadius: 5, zIndex: 1 }}
    //                                 items={targetOutletDropdownListTemp}
    //                                 onChangeItem={(item) => {
    //                                     MerchantStore.update(s => {
    //                                         s.currOutletId = item.value;
    //                                     });
    //                                 }}
    //                                 defaultValue={currOutletId}
    //                             />
    //                             :
    //                             <></>
    //                     }
    //                 </>
    //             );
    //         };
    //     });
    // }, [allOutlets, currOutletId]);

    /////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        //////////////////////////////////////////////////////////////////////////////////////

        var dailySalesDetailsListTemp = [];

        var overviewTotalViewsTemp = 0;
        var overviewTotalRedeemsTemp = 0;
        var overviewTotalUsesTemp = 0;
        var overviewTotalExpiresTemp = 0;

        var merchantVoucherSalesDict = {};

        if (currOutletId && currOutletId.length > 0 && merchantVouchers.length > 0) {
            var currOutletUserOrdersDone = allOutletsUserOrdersDone.filter(order => order.outletId === currOutletId && (order.voucherIdList && order.voucherIdList.length > 0));

            for (var index = 0; index < merchantVouchers.length; index++) {
                if (merchantVouchers[index].outletIdList.includes(currOutletId) && merchantVoucherReportsVoucherIdDict[merchantVouchers[index].uniqueId]) {
                    // means current outlet's promotion

                    var record = {
                        summaryId: nanoid(),
                        voucherName: merchantVouchers[index].title || 'N/A',
                        voucherType: MERCHANT_VOUCHER_TYPE_PARSED[merchantVouchers[index].type] || 'N/A',
                        // addOnChoices: 'N/A',
                        // totalItemsSold: 0,
                        totalViews: merchantVouchers[index].totalViews ? merchantVouchers[index].totalViews : 0,
                        totalRedeems: merchantVouchers[index].totalRedeems ? merchantVouchers[index].totalRedeems : 0,
                        totalUses: merchantVouchers[index].totalUses ? merchantVouchers[index].totalUses : 0,
                        // totalSalesReturn: 0,                        
                        // averageCost: 0,
                        // itemNetSales: 0,
                        // averageNetSales: 0,
                        // gp: 0,
                        detailsList: merchantVoucherReportsVoucherIdDict[merchantVouchers[index].uniqueId].redemptions,

                        // startDate: promotions[index].promoDateStart,
                        // endDate: promotions[index].promoDateEnd,
                        // startTime: promotions[index].promoTimeStart,
                        // endTime: promotions[index].promoTimeEnd,
                    };

                    overviewTotalViewsTemp += record.totalViews;
                    overviewTotalRedeemsTemp += record.totalRedeems;
                    overviewTotalUsesTemp += record.totalUses;

                    merchantVoucherSalesDict[record.summaryId] = record;

                    // will optimize this flow in future....
                    // for (var i = 0; i < currOutletUserOrdersDone.length; i++) {
                    //     if (currOutletUserOrdersDone[i].voucherIdList.includes(record.uniqueId) &&
                    //         moment(rev_date).isSameOrBefore(currOutletUserOrdersDone[i].completedDate) &&
                    //         moment(rev_date1).isAfter(currOutletUserOrdersDone[i].completedDate)) {

                    //         var voucherDetailsIndex = currOutletUserOrdersDone[i].voucherDetailsList.findIndex(details => {
                    //             return details.promotionId === record.uniqueId;
                    //         });

                    //         const voucherDetails = currOutletUserOrdersDone[i].voucherDetailsList[voucherDetailsIndex];

                    //         if (voucherDetails) {
                    //             promotionSalesDict[record.summaryId].totalOrders += 1;
                    //             promotionSalesDict[record.summaryId].totalSales += currOutletUserOrdersDone[i].finalPrice;
                    //             promotionSalesDict[record.summaryId].discountAmount += voucherDetails.discount;
                    //             promotionSalesDict[record.summaryId].discountPercentage += +(voucherDetails.discount / currOutletUserOrdersDone[i].finalPrice * 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                    //             // promotionSalesDict[record.summaryId].detailsList.push(currOutletUserOrdersDone[i]);

                    //             // overviewAmountTotalTemp += voucherDetails.discount;

                    //             // if (moment(currOutletUserOrdersDone[i].completedDate).diff(moment(), 'month') <= 12) {
                    //             //     overviewAmountYTDTemp += voucherDetails.discount;

                    //             //     if (moment(currOutletUserOrdersDone[i].completedDate).diff(moment(), 'month') <= 6) {
                    //             //         overviewAmount6MonthsTemp += voucherDetails.discount;

                    //             //         if (moment(currOutletUserOrdersDone[i].completedDate).diff(moment(), 'month') <= 3) {
                    //             //             overviewAmount3MonthsTemp += voucherDetails.discount;
                    //             //         }
                    //             //     }
                    //             // }
                    //         }
                    //     }
                    // }                    
                }
            }
        }

        setOverviewTotalViews(overviewTotalViewsTemp);
        setOverviewTotalRedeems(overviewTotalRedeemsTemp);
        setOverviewTotalUses(overviewTotalUsesTemp);

        const merchantVoucherSalesTemp = Object.entries(merchantVoucherSalesDict).map(([key, value]) => ({ ...value }));

        setDailySalesDetailsList(merchantVoucherSalesTemp);

        setCurrentPage(1);
        setPageCount(Math.ceil(merchantVoucherSalesTemp.length / perPage));

        setShowDetails(false);
    }, [
        allOutlets,
        allOutletsUserOrdersDone,
        currOutletId,
        rev_date,
        rev_date1,
        perPage,
    ]);

    useEffect(() => {
        if (showDetails && selectedItemSummary.detailsList) {
            setTransactionTypeSalesDetails(selectedItemSummary.detailsList);

            setCurrentPage(1);
            setPageCount(Math.ceil(selectedItemSummary.detailsList.length / perPage));
        }
    }, [
        showDetails,
        selectedItemSummary,
        perPage
    ]);

    //////////////////////////////////////////////////////////////////////////////////

    // navigation.dangerouslyGetParent().setOptions({
    //   tabBarVisible: false,
    // });

    navigation.setOptions({
        headerLeft: () => (
            <View style={styles.headerLeftStyle}>
                <Image
                    style={{
                        width: 124,
                        height: 26,
                    }}
                    resizeMode="contain"
                    source={require('../assets/image/logo.png')}
                />
            </View>
        ),
        headerTitle: () => (
            <View style={[{
                justifyContent: 'center',
                alignItems: 'center',
                // bottom: -2,
                bottom: switchMerchant ? '2%' : 0,
            }, Dimensions.get('screen').width >= 768 && switchMerchant ? { right: Dimensions.get('screen').width * 0.1 } : {}, Dimensions.get('screen').width <= 768 ? { right: Dimensions.get('screen').width * 0.12 } : {}]}>
                <Text
                    style={{
                        fontSize: switchMerchant ? 20 : 24,
                        // lineHeight: 25,
                        textAlign: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        color: Colors.whiteColor,
                        opacity: 1,
                    }}>
                    Voucher Report
                </Text>
            </View>
        ),
        headerRight: () => (
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                {outletSelectDropdownView()}
                <View style={{
                    backgroundColor: 'white',
                    width: 0.5,
                    height: Dimensions.get('screen').height * 0.025,
                    opacity: 0.8,
                    marginHorizontal: 15,
                    bottom: -1,
                }}>
                </View>
                <TouchableOpacity onPress={() => navigation.navigate('Setting')}
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                    <Text
                        style={{
                            fontFamily: 'NunitoSans-SemiBold',
                            fontSize: 16,
                            color: Colors.secondaryColor,
                            marginRight: 15,
                        }}>
                        {userName}
                    </Text>
                    <View style={{
                        marginRight: 30,
                        width: Dimensions.get('screen').height * 0.05,
                        height: Dimensions.get('screen').height * 0.05,
                        borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                    }}>
                        <Image style={{
                            width: Dimensions.get('screen').height * 0.035,
                            height: Dimensions.get('screen').height * 0.035,
                            alignSelf: 'center',
                        }} source={require('../assets/image/profile-pic.jpg')} />
                    </View>
                </TouchableOpacity>
            </View>

        ),
    });


    useEffect(() => {
        requestStoragePermission();

        getTotalSales(0);
        getSalesSummary();
        getTodaySales();
        getOutlets();
    }, []);

    const setState = () => {

    };

    const requestStoragePermission = async () => {
        try {
            const granted = await PermissionsAndroid.request(
                PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
                {
                    title: "KooDoo Merchant Storage Permission",
                    message:
                        "KooDoo Merchant App needs access to your storage ",
                    buttonNegative: "Cancel",
                    buttonPositive: "OK"
                }
            );
            if (granted === PermissionsAndroid.RESULTS.GRANTED) {
                console.log("Storage permission granted");
            } else {
                console.log("Storage permission denied");
            }
        } catch (err) {
            console.warn(err);
        }
    }

    const eventsChart = {
        dataPlotClick: (e, item) => {
            console.log('test data plot');
        }
    }

    const getOutlets = async () => {
    }

    const getSalesSummary = async () => {
    }

    const getTodaySales = () => {
    }

    const getTotalSales = async (type) => {
        const outletStat = [];
        const outletLabel = [];
        const colorList = [Colors.tabGold, Colors.tabMint, Colors.tabGrey, Colors.tabYellow, Colors.tabCyan, Colors.fieldtBgColor, Colors.primaryColor, Colors.secondaryColor, Colors.darkBgColor, Colors.highlightColor, Colors.lightRed, Colors.blackColor];
        if (type == 0) {
            for (const outlets of selectedOutlets) {

                console.log("outlets", outlets)

                const price = [];
                const label = [];
                ApiClient.GET(API.dashboardSaleOutletToday + "?merchantId=" + User.getMerchantId() + "&outletId=" + outlets).then(result => {
                    console.log("reuslt", result)
                    var i = selectedOutlets.indexOf(outlets)
                    console.log("initial i", i)
                    for (const entries of result) {
                        price.push(Number(entries.totalPrice))
                        label.push(entries.hour.toString())
                    }
                    outletStat.push(
                        {
                            data: price,
                            color: (opacity = 1) => colorList[i],
                        }
                    )
                    outletLabel.push(label)
                    console.log("outletStat", outletStat)
                    setState({
                        outletSalesData: outletStat.length == 0 ? [{
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            color: (opacity = 1) => Colors.tabMint,
                        }] : outletStat,
                        outletLabel: outletLabel[0]
                    })

                })

            }

        }

        const dummyYear = [
            {
                "month": 1,
                "monthName": "January",
                "year": 2021,
                "totalPrice": displayTodayData[0]
            },
            {
                "month": 2,
                "monthName": "February",
                "year": 2021,
                "totalPrice": displayTodayData[1]
            },
            {
                "month": 3,
                "monthName": "March",
                "year": 2021,
                "totalPrice": displayTodayData[2]
            },
            {
                "month": 4,
                "monthName": "April",
                "year": 2021,
                "totalPrice": displayTodayData[3]
            },
            {
                "month": 5,
                "monthName": "May",
                "year": 2021,
                "totalPrice": displayTodayData[4]
            },
            {
                "month": 6,
                "monthName": "June",
                "year": 2021,
                "totalPrice": displayTodayData[5]
            },
            {
                "month": 7,
                "monthName": "July",
                "year": 2021,
                "totalPrice": displayTodayData[6]
            },
            {
                "month": 8,
                "monthName": "August",
                "year": 2021,
                "totalPrice": displayTodayData[7]
            },
            {
                "month": 9,
                "monthName": "September",
                "year": 2021,
                "totalPrice": displayTodayData[8]
            },
            {
                "month": 10,
                "monthName": "October",
                "year": 2020,
                "totalPrice": displayTodayData[9]
            },
            {
                "month": 11,
                "monthName": "November",
                "year": 2021,
                "totalPrice": displayTodayData[10]
            },
            {
                "month": 12,
                "monthName": "December",
                "year": 2021,
                "totalPrice": displayTodayData[11]
            },
        ]

        // setState({ CsvData: dummyYear})

        const dummyYear1 = [
            {
                "month": 1,
                "monthName": "January",
                "year": 2020,
                "totalPrice": "212.61"
            },
            {
                "month": 2,
                "monthName": "February",
                "year": 2020,
                "totalPrice": "157.90"
            },
            {
                "month": 3,
                "monthName": "March",
                "year": 2020,
                "totalPrice": "199.08"
            },
            {
                "month": 4,
                "monthName": "April",
                "year": 2020,
                "totalPrice": "332.61"
            },
            {
                "month": 5,
                "monthName": "May",
                "year": 2020,
                "totalPrice": "544.90"
            },
            {
                "month": 6,
                "monthName": "June",
                "year": 2020,
                "totalPrice": "873.50"
            },
            {
                "month": 7,
                "monthName": "July",
                "year": 2020,
                "totalPrice": "843.00"
            },
            {
                "month": 8,
                "monthName": "August",
                "year": 2020,
                "totalPrice": "632.30"
            },
            {
                "month": 9,
                "monthName": "September",
                "year": 2020,
                "totalPrice": "500.50"
            },
            {
                "month": 10,
                "monthName": "October",
                "year": 2020,
                "totalPrice": "340.00"
            },
            {
                "month": 11,
                "monthName": "November",
                "year": 2020,
                "totalPrice": "229.50"
            },
            {
                "month": 12,
                "monthName": "December",
                "year": 2020,
                "totalPrice": "792.80"
            },
        ]

        const months = [];
        const yearlyData = [];
        const test = []
        for (const entries of dummyYear) {
            months.push(entries.monthName)
            yearlyData.push(entries.month)
            test.push(parseFloat(entries.totalPrice))
        }
        const months1 = [];
        const yearlyData1 = [];
        const test1 = []
        for (const entries of dummyYear1) {
            months1.push(entries.monthName)
            yearlyData1.push(entries.month)
            test1.push(parseFloat(entries.totalPrice))
        }
        setState({ yearlyData: yearlyData, months: months, test: test, test1: test1, months1: months1, yearlyData1: yearlyData1 })
    }


    const downloadPDF = () => {

    };

    const emailDashboard = () => {
        const excelData = convertDataToExcelFormat();

        var body = {
            // data: CsvData,
            //data: convertArrayToCSV(todaySalesChart.dataSource.data),
            data: JSON.stringify(excelData),
            //data: convertDataToExcelFormat(),
            email: exportEmail,
        };

        ApiClient.POST(API.emailDashboard, body, false).then((result) => {
            if (result !== null) {
                Alert.alert(
                    'Success',
                    'Email has been sent',
                    [{ text: 'OK', onPress: () => { } }],
                    { cancelable: false },
                );
            }
        });

        setVisible(false);
    };

    const nextPage = () => {
        setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
    };

    const nextDetailsPage = () => {
        setCurrentDetailsPage(currentDetailsPage + 1 > pageCount ? currentDetailsPage : currentDetailsPage + 1);
    };

    const prevDetailsPage = () => {
        setCurrentDetailsPage(currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1);
    };

    const renderLabel = (item) => {
        return (
            // <View style={{ elevation: 10, backgroundColor: category.active ? Colors.primaryColor : Colors.whiteColor, height: Dimensions.get('screen').width * 0.03, alignItems: 'center', justifyContent: 'center', borderRadius: 9, marginHorizontal: 1 }}>
            //<Text style={{ fontFamily: 'NunitoSans-Bold', marginHorizontal: 30, fontFamily: 'NunitoSans-Bold', color: category.active ? Colors.whiteColor : Colors.primaryColor }}>{category.label}</Text>
            //</View>
            <View style={{ flexDirection: "row", backgroundColor: Colors.tabGrey, height: Dimensions.get('screen').width * 0.04, alignItems: 'center', justifyContent: 'space-between', borderRadius: 6, marginHorizontal: 1 }}>
                {/* <Text style={{ marginHorizontal: 5, color: 'white', fontWeight: 'bold' }}>{item.label}</Text> */}
                <Text style={{ marginHorizontal: 5, color: 'white', fontWeight: 'bold' }}>asdfasdfasdf</Text>
                <TouchableOpacity
                    style={{ marginRight: 3 }}
                    onPress={() => {
                        //select(item) 
                    }}
                >
                    <AntDesign name={"close"} size={15} color={'#FFFFFF'} />
                </TouchableOpacity>
            </View>
        )
    }

    const onItemSummaryClicked = item => {
        // setTransactionTypeSalesDetails(item.detailsList);
        setSelectedItemSummary(item);
        setShowDetails(true);

        // setCurrentPage(1);
        // setPageCount(Math.ceil(item.detailsList.length / perPage));

        console.log('item.detailsList');
        console.log(item.detailsList);
    };

    //Daily Sales Report
    //Insert Dummy Data first
    //Text below is Dummy Data
    const renderItem = ({ item, index }) => (
        // (index + 1) % 2 == 0 ?
        <TouchableOpacity
            onPress={() => onItemSummaryClicked(item)}
            style={{
                backgroundColor:
                    (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
                paddingVertical: 10,
                paddingHorizontal: 3,
                paddingLeft: 1,
                borderColor: '#BDBDBD',
                borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                // width: '100%',
            }}>
            <View style={{ flexDirection: 'row', width: '100%' }}>

                <Text
                    style={[{
                        fontSize: 14,
                        fontFamily: 'NunitoSans-Regular',
                        color: 'black',
                        textAlign: 'left',
                        width: '6%',
                        marginHorizontal: 10
                    }, switchMerchant ? { fontSize: 10, marginHorizontal: 5 } : {}]}>
                    {index + 1}
                </Text>
                <Text
                    style={[{
                        fontSize: 14,
                        fontFamily: 'NunitoSans-Regular',
                        color: 'black',
                        textAlign: 'left',
                        width: '25%',
                        marginHorizontal: 10
                    }, switchMerchant ? { fontSize: 10, width: '25%', marginHorizontal: 5, } : {}]}>
                    {item.voucherName}
                </Text>
                <Text
                    style={[{
                        fontSize: 14,
                        fontFamily: 'NunitoSans-Regular',
                        textAlign: 'left',
                        width: '25%',
                        marginHorizontal: 10
                    }, switchMerchant ? { fontSize: 10, width: '23%', marginHorizontal: 5, } : {}]}>
                    {item.voucherType}
                </Text>
                <Text
                    style={[{
                        fontSize: 14,
                        fontFamily: 'NunitoSans-Regular',
                        textAlign: 'left',
                        width: '11%',
                        marginHorizontal: 10
                    }, switchMerchant ? { fontSize: 10, marginHorizontal: 5, } : {}]}>
                    {item.totalViews ? item.totalViews : 'N/A'}
                </Text>
                <Text
                    style={[{
                        fontSize: 14,
                        fontFamily: 'NunitoSans-Regular',
                        textAlign: 'left',
                        width: '12%',
                        marginHorizontal: 10
                    }, switchMerchant ? { fontSize: 10, marginHorizontal: 5, width: '15%', } : {}]}>
                    {item.totalRedeems ? item.totalRedeems : 'N/A'}
                </Text>
                <Text
                    style={[{
                        fontSize: 14,
                        fontFamily: 'NunitoSans-Regular',
                        textAlign: 'left',
                        width: '11%',
                        marginHorizontal: 10
                    }, switchMerchant ? { fontSize: 10, marginHorizontal: 5 } : {}]}>
                    {item.totalUses ? item.totalUses : 'N/A'}
                </Text>
            </View>
        </TouchableOpacity>

    );

    const onClickItemDetails = (item) => {
        setExpandDetailsDict({
            ...expandDetailsDict,
            [item.uniqueId]: expandDetailsDict[item.uniqueId] ? false : true,
        });
    };

    const renderItemDetails = ({ item, index }) => (
        <View style={{
            backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
            paddingVertical: 10,
            paddingHorizontal: 3,
            paddingLeft: 1,
            borderColor: '#BDBDBD',
            borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
            borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}>
            <View style={{ flexDirection: 'row', }}>
                <Text style={[{ width: '16.5%', marginHorizontal: 10, fontSize: 14, fontFamily: 'NunitoSans-Regular', textAlign: 'left' }, switchMerchant ? { fontSize: 10, marginHorizontal: 5, } : {}]}>{index + 1}</Text>
                <Text style={[{ width: '24.5%', marginHorizontal: 10, fontSize: 14, fontFamily: 'NunitoSans-Regular', textAlign: 'left' }, switchMerchant ? { fontSize: 10, marginHorizontal: 5, } : {}]}>{item.userName || 'N/A'}</Text>
                <Text style={[{ width: '31%', marginHorizontal: 10, fontSize: 14, fontFamily: 'NunitoSans-Regular', textAlign: 'left', fontVariant: ['tabular-nums'] }, switchMerchant ? { fontSize: 10, width: '26%', marginHorizontal: 5, } : {}]}>{item.redeemDate ? moment(item.redeemDate).format('DD MMM YYYY hh:mm A') : 'N/A'}</Text>
                <Text style={[{ width: '28%', marginHorizontal: 10, fontSize: 14, fontFamily: 'NunitoSans-Regular', textAlign: 'left', fontVariant: ['tabular-nums'] }, switchMerchant ? { fontSize: 10, marginHorizontal: 5, width: '26%' } : {}]}>{item.usedDate ? moment(item.usedDate).format('DD MMM YYYY hh:mm A') : 'N/A'}</Text>
            </View>
        </View>
    )

    const convertDataToExcelFormat = () => {
        var excelData = [];

        if (!showDetails) {
            //List
            for (var i = 0; i < dailySalesDetailsList.length; i++) {
                var excelRow = {
                    'Voucher Name': dailySalesDetailsList[i].voucherName,
                    'Voucher Type': dailySalesDetailsList[i].voucherType,
                    'Viewed': dailySalesDetailsList[i].totalViews ? dailySalesDetailsList[i].totalViews : 'N/A',
                    'Redemption': dailySalesDetailsList[i].totalRedeems ? dailySalesDetailsList[i].totalRedeems : 'N/A',
                    'Used': dailySalesDetailsList[i].totalUses ? dailySalesDetailsList[i].totalUses : 'N/A',
                    // 'Date/Time': moment(dailySalesDetailsList[i].completedDate).format('DD MMM hh:mma'),
                    // 'Total Sales (RM)': parseFloat(dailySalesDetailsList[i].totalSales).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Total Transaction': dailySalesDetailsList[i].totalTransactions,
                    // 'Total Discount (RM)': parseFloat(dailySalesDetailsList[i].totalDiscount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Discount (%)': parseFloat(dailySalesDetailsList[i].discount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Tax (RM)': parseFloat(dailySalesDetailsList[i].tax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Tax (RM)': parseFloat(dailySalesDetailsList[i].serviceCharge).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'GP (%)': parseFloat(dailySalesDetailsList[i].gp).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Total Sales Return': parseFloat(dailySalesDetailsList[i].totalSalesReturn).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Net Sales (RM)': parseFloat(dailySalesDetailsList[i].netSales).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Average Net Sales (RM)': parseFloat(dailySalesDetailsList[i].averageNetSales).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                };

                excelData.push(excelRow);
            }
        }
        else {
            //Detail List
            for (var i = 0; i < transactionTypeSalesDetails.length; i++) {
                var excelRow = {
                    'User Name': transactionTypeSalesDetails[i].userName || 'N/A',
                    'Redeem Date/Time': transactionTypeSalesDetails[i].redeemDate ? moment(transactionTypeSalesDetails[i].redeemDate).format('DD MMM YYYY hh:mm A') : 'N/A',
                    'Used Date/Time': transactionTypeSalesDetails[i].usedDate ? moment(transactionTypeSalesDetails[i].usedDate).format('DD MMM YYYY hh:mm A') : 'N/A',

                    // 'Date/Time': moment(transactionTypeSalesDetails[i].completedDate).format('DD MMM hh:mma'),
                    // 'Sales (RM)': parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Total Discount (RM)': parseFloat(transactionTypeSalesDetails[i].discount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Discount (%)': parseFloat(transactionTypeSalesDetails[i].discountPercentage).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Tax (RM)': parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Tax (RM)': parseFloat(0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'GP (%)': parseFloat(0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Sales Return': parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                    // 'Net Sales (RM)': parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
                };

                excelData.push(excelRow);
            }
        }

        console.log('excelData');
        console.log(excelData);

        return excelData;
    };

    const barFilterPressed = (barFilterTapped) => {

        setExpandBarSelection(true)

    }

    const lineFilterPressed = (lineFilterTapped) => {

        setExpandLineSelection(true)

    }

    return (
        <View
            style={[
                styles.container,
                {
                    height: windowHeight,
                    width: windowWidth,
                },
            ]}>
            <View style={{ flex: 0.8 }}>
                <SideBar navigation={props.navigation} selectedTab={5} expandVoucher={true} />
            </View>
            <View style={{ height: windowHeight, flex: 9 }}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{}}
                    contentContainerStyle={{
                        paddingBottom: Dimensions.get('screen').height * 0.1,
                    }}
                >
                    <DateTimePickerModal
                        isVisible={showDateTimePicker}
                        mode={'date'}
                        onConfirm={(text) => {
                            setRev_date(moment(text).startOf('day'));
                            setShowDateTimePicker(false);
                        }}
                        onCancel={() => {
                            setShowDateTimePicker(false);
                        }}
                    />

                    <DateTimePickerModal
                        isVisible={showDateTimePickerFilterLineChart}
                        mode={'date'}
                        onConfirm={(text) => {
                            setSelectedChartFilterQueriesLineChart([
                                {
                                    ...selectedChartFilterQueriesLineChart[0],
                                    fieldDataValue: text,
                                },
                            ]);

                            setShowDateTimePickerFilterLineChart(false);
                        }}
                        onCancel={() => {
                            setShowDateTimePickerFilterLineChart(false);
                        }}
                    />

                    <DateTimePickerModal
                        isVisible={showDateTimePickerFilterBarChart}
                        mode={'date'}
                        onConfirm={(text) => {
                            setSelectedChartFilterQueriesBarChart([
                                {
                                    ...selectedChartFilterQueriesBarChart[0],
                                    fieldDataValue: text,
                                },
                            ]);

                            setShowDateTimePickerFilterBarChart(false);
                        }}
                        onCancel={() => {
                            setShowDateTimePickerFilterBarChart(false);
                        }}
                    />

                    <Modal
                        style={{
                            // flex: 1
                        }}
                        visible={exportModalVisibility}
                        transparent={true}
                        animationType={'slide'}
                    >
                        <View style={styles.modalContainer}>
                            <View style={[styles.modalView, switchMerchant ? { height: Dimensions.get('screen').width * 0.25, width: Dimensions.get('screen').width * 0.35, } : {}]}>
                                <TouchableOpacity
                                    style={styles.closeButton}
                                    onPress={() => {
                                        setExportModalVisibility(false);
                                    }}>
                                    <AntDesign name="closecircle" size={switchMerchant ? 15 : 25} color={Colors.fieldtTxtColor} />
                                </TouchableOpacity>
                                <View style={styles.modalTitle}>
                                    <Text style={[styles.modalTitleText, switchMerchant ? { fontSize: 18 } : {}]}>
                                        Download Report
                                    </Text>
                                </View>
                                <View style={{ top: '10%', }}>
                                    <Text style={[{ fontSize: 16, fontFamily: 'NunitoSans-Bold' }, switchMerchant ? { fontSize: 10 } : {}]}>Email Address:</Text>
                                    <TextInput
                                        underlineColorAndroid={Colors.fieldtBgColor}
                                        style={[{
                                            backgroundColor: Colors.fieldtBgColor,
                                            width: 370,
                                            height: 50,
                                            borderRadius: 5,
                                            padding: 5,
                                            marginVertical: 5,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                            paddingLeft: 10,
                                        }, switchMerchant ? { width: 260, height: 35, fontSize: 10 } : {}]}
                                        placeholderStyle={{ padding: 5 }}
                                        placeholder="Enter Your Email"
                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                        onChangeText={(text) => {
                                            setExportEmail(text);
                                        }}
                                        value={exportEmail}
                                    />
                                    <Text style={[{ fontSize: 16, fontFamily: 'NunitoSans-Bold', marginTop: 15 }, switchMerchant ? { fontSize: 10 } : {}]}>Send As:</Text>


                                    <View style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        //top: '10%',
                                        flexDirection: 'row',
                                        marginTop: 15,
                                    }}>
                                        <TouchableOpacity
                                            style={[{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 1,
                                                borderColor: Colors.primaryColor,
                                                backgroundColor: '#4E9F7D',
                                                borderRadius: 5,
                                                width: 120,
                                                paddingHorizontal: 10,
                                                height: 40,
                                                alignItems: 'center',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                            }, switchMerchant ? { width: 80, height: 20 } : {}]}
                                            onPress={() => {
                                                if (exportEmail.length > 0) {
                                                    CommonStore.update(s => {
                                                        s.isLoading = true;
                                                    });

                                                    setIsLoadingExcel(true);

                                                    const excelData = convertDataToExcelFormat();

                                                    generateEmailReport(
                                                        EMAIL_REPORT_TYPE.EXCEL,
                                                        excelData,
                                                        'KooDoo Voucher Report',
                                                        'KooDoo Voucher Report.xlsx',
                                                        `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                                                        exportEmail,
                                                        'KooDoo Voucher Report',
                                                        'KooDoo Voucher Report',
                                                        () => {
                                                            CommonStore.update(s => {
                                                                s.isLoading = false;
                                                            });

                                                            setIsLoadingExcel(false);

                                                            Alert.alert('Success', 'Report will be sent to the email address soon');

                                                            setExportModalVisibility(false);
                                                        },
                                                    );
                                                }
                                                else {
                                                    Alert.alert('Info', 'Invalid email address');
                                                }
                                            }}>
                                            {
                                                isLoadingExcel
                                                    ?
                                                    <ActivityIndicator
                                                        size={'small'}
                                                        color={Colors.whiteColor}
                                                    />
                                                    :
                                                    <Text style={{
                                                        color: Colors.whiteColor,
                                                        //marginLeft: 10,
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        color: '#FFFFFF',
                                                        fontFamily: 'NunitoSans-Bold',
                                                    }}>
                                                        EXCEL
                                                    </Text>

                                            }
                                        </TouchableOpacity>

                                        <TouchableOpacity
                                            style={[{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 1,
                                                borderColor: Colors.primaryColor,
                                                backgroundColor: '#4E9F7D',
                                                borderRadius: 5,
                                                width: 120,
                                                paddingHorizontal: 10,
                                                height: 40,
                                                alignItems: 'center',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                                marginLeft: 15,
                                            }, switchMerchant ? { width: 80, height: 20 } : {}]}
                                            onPress={() => {
                                                if (exportEmail.length > 0) {
                                                    CommonStore.update(s => {
                                                        s.isLoading = true;
                                                    });

                                                    setIsLoadingCsv(true);

                                                    // const csvData = convertArrayToCSV(todaySalesChart.dataSource.data);
                                                    const csvData = convertArrayToCSV(dailySalesDetailsList);

                                                    generateEmailReport(
                                                        EMAIL_REPORT_TYPE.CSV,
                                                        csvData,
                                                        'KooDoo Voucher Report',
                                                        'KooDoo Voucher Report.csv',
                                                        `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                                                        exportEmail,
                                                        'KooDoo Voucher Report',
                                                        'KooDoo Voucher Report',
                                                        () => {
                                                            CommonStore.update(s => {
                                                                s.isLoading = false;
                                                            });

                                                            setIsLoadingCsv(false);

                                                            Alert.alert('Success', 'Report will be sent to the email address soon');

                                                            setExportModalVisibility(false);
                                                        },
                                                    );
                                                }
                                                else {
                                                    Alert.alert('Info', 'Invalid email address');
                                                }
                                            }}>
                                            {
                                                isLoadingCsv
                                                    ?
                                                    <ActivityIndicator
                                                        size={'small'}
                                                        color={Colors.whiteColor}
                                                    />
                                                    :
                                                    <Text style={{
                                                        color: Colors.whiteColor,
                                                        //marginLeft: 10,
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        color: '#FFFFFF',
                                                        fontFamily: 'NunitoSans-Bold',
                                                    }}>
                                                        CSV
                                                    </Text>

                                            }
                                        </TouchableOpacity>

                                        {/* <TouchableOpacity
                                            style={{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 1,
                                                borderColor: Colors.primaryColor,
                                                backgroundColor: '#4E9F7D',
                                                borderRadius: 5,
                                                width: 120,
                                                paddingHorizontal: 10,
                                                height: 40,
                                                alignItems: 'center',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                            }}
                                            onPress={() => { downloadPDF() }}>
                                            <Text style={{
                                                color: Colors.whiteColor,
                                                //marginLeft: 5,
                                                fontSize: 16,
                                                fontFamily: 'NunitoSans-Bold',
                                            }}>
                                            PDF</Text>
                                        </TouchableOpacity> */}
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Modal>

                    <DateTimePickerModal
                        isVisible={showDateTimePicker1}
                        mode={'date'}
                        onConfirm={(text) => {
                            setRev_date1(moment(text).endOf('day'));
                            setShowDateTimePicker1(false);
                        }}
                        onCancel={() => {
                            setShowDateTimePicker1(false);
                        }}
                    />

                    <View
                        style={{
                            paddingHorizontal: 35,
                            paddingVertical: 10,
                            width:
                                Dimensions.get('screen').width * (1 - Styles.sideBarWidth),
                        }}>


                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1%' }}>
                            <Text
                                style={[{
                                    fontSize: 26,
                                    // fontWeight: '700',
                                    fontFamily: 'NunitoSans-Bold',
                                    marginBottom: 10,
                                }, switchMerchant ? { fontSize: 20, } : {}]}>
                                Voucher Report
                            </Text>

                            <TouchableOpacity
                                style={[{
                                    justifyContent: 'center',
                                    flexDirection: 'row',
                                    borderWidth: 1,
                                    borderColor: Colors.primaryColor,
                                    backgroundColor: '#4E9F7D',
                                    borderRadius: 5,
                                    //width: 160,
                                    paddingHorizontal: 10,
                                    height: 40,
                                    alignItems: 'center',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                    zIndex: -1,
                                }, switchMerchant ? {
                                    height: 35
                                } : {}]}
                                onPress={() => { setExportModalVisibility(true) }}
                            >
                                <Feather name="download" size={switchMerchant ? 10 : 20} style={{ color: Colors.whiteColor }} />
                                <Text style={[{
                                    color: Colors.whiteColor,
                                    marginLeft: 5,
                                    fontSize: 16,
                                    fontFamily: 'NunitoSans-Bold',
                                }, switchMerchant ? {
                                    fontSize: 10,
                                } : {}]}>
                                    DOWNLOAD</Text>
                            </TouchableOpacity>
                        </View>

                        {/* <Text style={{ marginTop: 5, marginBottom: 10, fontSize: 15, color: "#bababa", fontFamily: 'NunitoSans-Bold' }}>Date last updated on {moment(currentDateTime).format("DD MMM yyyy")}, {moment(currentDateTime).format("HH:mm a")}</Text> */}

                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '100%',
                                marginBottom: 10,
                            }}>
                            <View
                                style={[{
                                    width: '23%',
                                    height: 100,
                                    backgroundColor: Colors.tabMint,
                                    borderRadius: 10,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingHorizontal: 30,
                                    paddingVertical: 15,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 3,
                                }, switchMerchant ? { marginTop: 15, height: 60 } : {}]}>
                                <View style={[{ marginTop: -15 }, switchMerchant ? { marginTop: 15, height: 60 } : {}]}>
                                    <Text
                                        style={[{
                                            fontFamily: 'NunitoSans-Bold',
                                            color: Colors.whiteColor,
                                            fontSize: 28,
                                        }, switchMerchant ? { fontSize: 20, } : {}]}>
                                        {overviewTotalViews}
                                    </Text>
                                    <Text
                                        style={[{
                                            fontFamily: 'NunitoSans-Regular',
                                            color: Colors.whiteColor,
                                            fontSize: 16,
                                        }, switchMerchant ? { fontSize: 10, } : {}]}>
                                        Total View
                                    </Text>
                                </View>
                                <View>
                                    {/* <Coins height={70} width={70} /> */}
                                    {/* <Ionicon
                      name="eye-outline"
                      color={'#F7F7F7'}
                      size={65}
                      style={{opacity: 0.6, paddingLeft: 2}}
                    /> */}
                                    <AntDesign
                                        name='filetext1'
                                        size={switchMerchant ? 28 : 65}
                                        style={{ color: '#F7F7F7', opacity: 0.6, paddingLeft: 2, paddingBottom: 2 }} />
                                </View>
                            </View>
                            <View
                                style={[{
                                    width: '23%',
                                    height: 100,
                                    backgroundColor: Colors.tabGold,
                                    borderRadius: 10,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingHorizontal: 30,
                                    paddingVertical: 15,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 3,
                                }, switchMerchant ? { marginTop: 15, height: 60 } : {}]}>
                                <View style={[{ marginTop: -15 }, switchMerchant ? { marginTop: 15, height: 60 } : {}]}>
                                    <Text
                                        style={[{
                                            fontFamily: 'NunitoSans-Bold',
                                            color: Colors.whiteColor,
                                            fontSize: 28,
                                        }, switchMerchant ? { fontSize: 20, } : {}]}>
                                        {overviewTotalRedeems}
                                    </Text>
                                    <Text
                                        style={[{
                                            fontFamily: 'NunitoSans-Regular',
                                            color: Colors.whiteColor,
                                            fontSize: 16,
                                        }, switchMerchant ? { fontSize: 10, } : {}]}>
                                        Total Redemption
                                    </Text>
                                </View>
                                <View>
                                    {/* <MaterialIcons name={'redeem'} size={70} style={{ color: '#F7F7F7', opacity: 0.55 }}  /> */}
                                    <Ionicon
                                        name="gift-outline"
                                        color={'#F7F7F7'}
                                        size={switchMerchant ? 30 : 65}
                                        style={{ opacity: 0.6, paddingLeft: 2 }}
                                    />
                                </View>
                            </View>
                            <View
                                style={[{
                                    width: '23%',
                                    height: 100,
                                    backgroundColor: Colors.tabCyan,
                                    borderRadius: 10,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingHorizontal: 30,
                                    paddingVertical: 15,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 3,
                                }, switchMerchant ? { marginTop: 15, height: 60 } : {}]}>
                                <View style={[{ marginTop: -15 }, switchMerchant ? { marginTop: 15, height: 60 } : {}]}>
                                    {/* <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28 }}>{allDayTotalSold}</Text> */}
                                    <Text
                                        style={[{
                                            fontFamily: 'NunitoSans-Bold',
                                            color: Colors.whiteColor,
                                            fontSize: 28,
                                        }, switchMerchant ? { fontSize: 20, } : {}]}>
                                        {overviewTotalUses}
                                    </Text>
                                    <Text
                                        style={[{
                                            fontFamily: 'NunitoSans-Regular',
                                            color: Colors.whiteColor,
                                            fontSize: 16,
                                        }, switchMerchant ? { fontSize: 10, } : {}]}>
                                        Total Used
                                    </Text>
                                </View>
                                <View>
                                    {/* <Dish height={70} width={70} /> */}
                                    <Ionicon
                                        name="trash-bin-outline"
                                        color={'#F7F7F7'}
                                        size={switchMerchant ? 30 : 65}
                                        style={{ opacity: 0.6, paddingLeft: 2 }}
                                    />
                                </View>
                            </View>
                            <View
                                style={[{
                                    width: '23%',
                                    height: 100,
                                    backgroundColor: '#d90000',
                                    borderRadius: 10,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingHorizontal: 30,
                                    paddingVertical: 15,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 3,
                                }, switchMerchant ? { marginTop: 15, height: 60 } : {}]}>
                                <View style={[{ marginTop: -15 }, switchMerchant ? { marginTop: 15, height: 60 } : {}]}>
                                    {/* <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28 }}>{allDayTotalSold}</Text> */}
                                    <Text
                                        style={[{
                                            fontFamily: 'NunitoSans-Bold',
                                            color: Colors.whiteColor,
                                            fontSize: 28,
                                        }, switchMerchant ? { fontSize: 20, } : {}]}>
                                        {overviewTotalExpires}
                                    </Text>
                                    <Text
                                        style={[{
                                            fontFamily: 'NunitoSans-Regular',
                                            color: Colors.whiteColor,
                                            fontSize: 16,
                                        }, switchMerchant ? { fontSize: 10, } : {}]}>
                                        Total Expired
                                    </Text>
                                </View>
                                <View>
                                    <Ionicon
                                        name="timer-outline"
                                        color={'#F7F7F7'}
                                        size={switchMerchant ? 30 : 65}
                                        style={{ opacity: 0.6, paddingLeft: 2 }}
                                    />
                                </View>
                            </View>
                        </View>


                        {/* Daily Sales Report */}
                        <View style={{ width: '100%', marginTop: 10 }}>
                            <View
                                style={{
                                    backgroundColor: Colors.whiteColor,
                                    padding: 10,
                                    paddingHorizontal: 5,
                                    // height: '82%',
                                    // borderRadius: 10,
                                    borderRadius: 5,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    // elevation: 1,
                                    elevation: 3,

                                    // marginBottom: '15%',
                                }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        paddingTop: switchMerchant ? 0 : 10,
                                        paddingLeft: 10,
                                        alignItems: 'center',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                    }}>
                                    <View
                                        style={[{
                                            flexDirection: 'row',
                                        }, switchMerchant ? { alignItems: 'center' } : {}]}>
                                        <View style={[{ flexDirection: 'row', }, switchMerchant ? { alignItems: 'center' } : {}]}>
                                            <Magnify width={switchMerchant ? 10 : 20} height={39} />

                                            <Text
                                                style={[{
                                                    fontFamily: 'NunitoSans-Bold',
                                                    color: Colors.primaryColor,
                                                    fontSize: 18,
                                                    paddingHorizontal: 10,
                                                    paddingTop: 7,
                                                    position: 'relative',
                                                    // top: '-1%'
                                                    marginBottom: Platform.OS === 'ios' ? 0 : 1,
                                                }, switchMerchant ? { fontSize: 10, paddingTop: 0, marginBottom: 1 } : {}]}>
                                                Redemption Record
                                            </Text>

                                        </View>

                                        <View style={{ flexDirection: 'row', }}>
                                            <TouchableOpacity
                                                style={[{
                                                    justifyContent: 'center',
                                                    flexDirection: 'row',
                                                    borderWidth: 1,
                                                    borderColor: Colors.primaryColor,
                                                    backgroundColor: '#4E9F7D',
                                                    borderRadius: 5,
                                                    //width: 160,
                                                    paddingHorizontal: 10,
                                                    height: 40,
                                                    alignItems: 'center',
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.22,
                                                    shadowRadius: 3.22,
                                                    elevation: 1,
                                                    zIndex: -1,

                                                    opacity: !showDetails ? 0 : 100,
                                                }, switchMerchant ? { height: 35, } : {}]}
                                                onPress={() => {
                                                    setShowDetails(false);

                                                    setCurrentPage(1);
                                                    setPageCount(
                                                        Math.ceil(dailySalesDetailsList.length / perPage),
                                                    );
                                                }}
                                                disabled={!showDetails}>
                                                <AntDesign
                                                    name="arrowleft"
                                                    size={switchMerchant ? 10 : 18}
                                                    color={Colors.whiteColor}
                                                    style={{
                                                        // top: -1,
                                                    }}
                                                />
                                                <Text
                                                    style={[{
                                                        color: Colors.whiteColor,
                                                        paddingLeft: 5,
                                                        fontSize: 16,
                                                        fontFamily: 'NunitoSans-Bold',
                                                    }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                    Summary
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: 'row', paddingRight: 15, }}>
                                        <View
                                            style={[{
                                                // marginRight: 10,

                                                paddingHorizontal: 15,
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                borderRadius: 10,
                                                paddingVertical: 10,
                                                justifyContent: 'center',
                                                backgroundColor: Colors.whiteColor,
                                                shadowOpacity: 0,
                                                shadowColor: '#000',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                            }, switchMerchant ? { borderRadius: 5, height: 35 } : {}]}>
                                            <View
                                                style={{ alignSelf: 'center', right: 3 }}
                                                onPress={() => {
                                                    setState({
                                                        pickerMode: 'date',
                                                        showDateTimePicker: true,
                                                    });
                                                }}>
                                                {/* <EvilIcons name="calendar" size={25} color={Colors.primaryColor} /> */}
                                                <GCalendar
                                                    width={switchMerchant ? 15 : 20}
                                                    height={switchMerchant ? 15 : 20}
                                                    color={Colors.primaryColor}
                                                />
                                            </View>

                                            {/* <TouchableOpacity
                                                onPress={() => {
                                                    setShowDateTimePicker(true);
                                                    setShowDateTimePicker1(false);
                                                }}
                                                style={{
                                                    marginHorizontal: 4,
                                                }}>
                                                <Text style={[{ fontFamily: 'NunitoSans-Regular' }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                    {moment(rev_date).format('DD MMM yyyy')}
                                                </Text>
                                            </TouchableOpacity> */}
                                            <DatePicker //blocked
                                                selected={rev_date.toDate()}
                                                onChange={(date) => {
                                                    setRev_date(moment(date).startOf('day'));
                                                }}
                                                maxDate={moment(rev_date1).toDate()}
                                            />

                                            <Text style={[{ fontFamily: 'NunitoSans-Regular' }, switchMerchant ? { fontSize: 10, } : {}]}>-</Text>

                                            {/* <TouchableOpacity
                                                onPress={() => {
                                                    setShowDateTimePicker(false);
                                                    setShowDateTimePicker1(true);
                                                }}
                                                style={{
                                                    marginHorizontal: 4,
                                                }}>
                                                <Text style={[{ fontFamily: 'NunitoSans-Regular' }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                    {moment(rev_date1).format('DD MMM yyyy')}
                                                </Text>
                                            </TouchableOpacity> */}

                                            <DatePicker //blocked
                                                selected={rev_date1.toDate()}
                                                onChange={(date) => {
                                                    setRev_date(moment(date).startOf('day'));
                                                }}
                                                minDate={moment(rev_date).toDate()}
                                            />

                                        </View>
                                    </View>
                                </View>

                                {!showDetails ? (
                                    <View
                                        style={{
                                            marginTop: 15,
                                            flexDirection: 'row',
                                            paddingHorizontal: 3,
                                            paddingLeft: 1,
                                        }}>
                                        <View
                                            style={[{
                                                flexDirection: 'row',
                                                width: '6%',
                                                marginHorizontal: 10,
                                                borderRightWidth: 1,
                                                borderRightColor: 'lightgrey',
                                            }, switchMerchant ? { marginHorizontal: 5 } : {}]}>
                                            <View
                                                style={[{
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'row',
                                                    // justifyContent:'center',
                                                }, switchMerchant ? {} : {}]}>
                                                <View style={{
                                                    flexDirection: 'column',
                                                    top: Platform.OS == 'ios' ? 5 : 2,
                                                }}>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Text
                                                            numberOfLines={1}
                                                            style={[{
                                                                fontSize: 14,
                                                                fontFamily: 'NunitoSans-Bold',
                                                            }, switchMerchant ? {
                                                                fontSize: 10,
                                                            } : {}]}>
                                                            No.
                                                        </Text>
                                                        {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                                                    </View>
                                                </View>
                                            </View>
                                        </View>


                                        <View
                                            style={[{
                                                flexDirection: 'row',
                                                width: '25%',
                                                marginHorizontal: 10,
                                                borderRightWidth: 1,
                                                borderRightColor: 'lightgrey',
                                            }, switchMerchant ? { fontSize: 10, width: '25%', marginHorizontal: 5, } : {}]}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (
                                                        currReportSummarySort ===
                                                        REPORT_SORT_FIELD_TYPE.VOUCHER_NAME_ASC
                                                    ) {
                                                        setCurrReportSummarySort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_NAME_DESC,
                                                        );
                                                    } else {
                                                        setCurrReportSummarySort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_NAME_ASC,
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'row',
                                                }}>
                                                <View
                                                    style={{
                                                        flexDirection: 'column',
                                                        top: Platform.OS == 'ios' ? 5 : 2,
                                                    }}>
                                                    <Text
                                                        numberOfLines={2}
                                                        style={[{
                                                            fontSize: 14,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }, switchMerchant ? {
                                                            fontSize: 10,
                                                        } : {}]}>
                                                        Voucher Name{' '}
                                                    </Text>
                                                </View>
                                                {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}

                                                <View style={[{ marginLeft: 0 }, switchMerchant ? { justifyContent: 'center', } : {}]}>
                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-up"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportSummarySort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_NAME_ASC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-down"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportSummarySort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_NAME_DESC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </TouchableOpacity>
                                        </View>

                                        <View
                                            style={[{
                                                flexDirection: 'row',
                                                width: '25%',
                                                marginHorizontal: 10,
                                                borderRightWidth: 1,
                                                borderRightColor: 'lightgrey',
                                            }, switchMerchant ? {
                                                width: '23%', marginHorizontal: 5,
                                            } : {}]}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (
                                                        currReportSummarySort ===
                                                        REPORT_SORT_FIELD_TYPE.VOUCHER_TYPE_ASC
                                                    ) {
                                                        setCurrReportSummarySort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_TYPE_DESC,
                                                        );
                                                    } else {
                                                        setCurrReportSummarySort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_TYPE_ASC,
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'row',
                                                }}>
                                                <View
                                                    style={{
                                                        flexDirection: 'column',
                                                        top: Platform.OS == 'ios' ? 5 : 2,
                                                    }}>
                                                    <Text
                                                        numberOfLines={2}
                                                        style={[{
                                                            fontSize: 14,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }, switchMerchant ? {
                                                            fontSize: 10,
                                                        } : {}]}>
                                                        {'Voucher Type'}
                                                    </Text>
                                                    {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                                                </View>
                                                <View style={[{ marginLeft: '3%' }, switchMerchant ? { justifyContent: 'center', } : {}]}>
                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-up"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportSummarySort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_TYPE_ASC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-down"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportSummarySort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_TYPE_DESC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </TouchableOpacity>
                                        </View>

                                        <View
                                            style={[{
                                                flexDirection: 'row',
                                                width: '11%',
                                                marginHorizontal: 10,
                                                borderRightWidth: 1,
                                                borderRightColor: 'lightgrey',
                                            }, switchMerchant ? { marginHorizontal: 5, } : {}]}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (
                                                        currReportSummarySort ===
                                                        REPORT_SORT_FIELD_TYPE.VOUCHER_VIEWED_ASC
                                                    ) {
                                                        setCurrReportSummarySort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_VIEWED_DESC,
                                                        );
                                                    } else {
                                                        setCurrReportSummarySort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_VIEWED_ASC,
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'row',
                                                    // left: Platform.OS == 'ios' ? 25 : 0,
                                                }}>
                                                <View
                                                    style={{
                                                        flexDirection: 'column',
                                                        top: Platform.OS == 'ios' ? 5 : 2,
                                                    }}>
                                                    <Text
                                                        numberOfLines={2}
                                                        style={[{
                                                            fontSize: 14,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }, switchMerchant ? {
                                                            fontSize: 10,
                                                        } : {}]}>
                                                        Viewed
                                                    </Text>
                                                </View>
                                                <View style={[{ marginLeft: '3%' }, switchMerchant ? { justifyContent: 'center', } : {}]}>
                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-up"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportSummarySort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_VIEWED_ASC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-down"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportSummarySort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_VIEWED_DESC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </TouchableOpacity>
                                        </View>

                                        <View
                                            style={[{
                                                flexDirection: 'row',
                                                width: '12%',
                                                marginHorizontal: 10,
                                                borderRightWidth: 1,
                                                borderRightColor: 'lightgrey',
                                            }, switchMerchant ? { marginHorizontal: 5, width: '15%', } : {}]}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (
                                                        currReportSummarySort ===
                                                        REPORT_SORT_FIELD_TYPE.VOUCHER_REDEMPTION_ASC
                                                    ) {
                                                        setCurrReportSummarySort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_REDEMPTION_DESC,
                                                        );
                                                    } else {
                                                        setCurrReportSummarySort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_REDEMPTION_ASC,
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'row',
                                                }}>
                                                <View
                                                    style={{
                                                        flexDirection: 'column',
                                                        top: Platform.OS == 'ios' ? 5 : 2,
                                                    }}>
                                                    <Text
                                                        numberOfLines={2}
                                                        style={[{
                                                            fontSize: 14,
                                                            fontFamily: 'NunitoSans-Bold',
                                                            textAlign: 'center',
                                                        }, switchMerchant ? {
                                                            fontSize: 10,
                                                        } : {}]}>
                                                        {'Redemption'}
                                                    </Text>
                                                    {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                                                </View>
                                                <View style={[{ marginLeft: '3%' }, switchMerchant ? { justifyContent: 'center', } : {}]}>
                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-up"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportSummarySort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_REDEMPTION_ASC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-down"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportSummarySort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_REDEMPTION_DESC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </TouchableOpacity>
                                        </View>

                                        <View style={[{
                                            flexDirection: 'row',
                                            width: '11%',
                                            marginHorizontal: 10,
                                        }, switchMerchant ? { fontSize: 10, width: '20%', marginHorizontal: 5, } : {}]}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (
                                                        currReportSummarySort ===
                                                        REPORT_SORT_FIELD_TYPE.VOUCHER_USED_ASC
                                                    ) {
                                                        setCurrReportSummarySort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_USED_DESC,
                                                        );
                                                    } else {
                                                        setCurrReportSummarySort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_USED_ASC,
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'row',
                                                }}>
                                                <View
                                                    style={{
                                                        flexDirection: 'column',
                                                        top: Platform.OS == 'ios' ? 5 : 2,
                                                    }}>
                                                    <Text
                                                        numberOfLines={2}
                                                        style={[{
                                                            fontSize: 14,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }, switchMerchant ? {
                                                            fontSize: 10,
                                                        } : {}]}>
                                                        Used
                                                    </Text>
                                                    {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                                                </View>
                                                <View style={[{ marginLeft: '3%' }, switchMerchant ? { justifyContent: 'center', } : {}]}>
                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-up"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportSummarySort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_USED_ASC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-down"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportSummarySort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_USED_DESC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                ) : (
                                    <View style={{
                                        marginTop: 15, flexDirection: 'row', width: '100%', paddingHorizontal: 3,
                                        paddingLeft: 1,
                                    }}>
                                        <View
                                            style={[{
                                                flexDirection: 'row',
                                                width: '16.5%',
                                                marginHorizontal: 10,
                                                borderRightWidth: 1,
                                                borderRightColor: 'lightgrey',
                                            }, switchMerchant ? { marginHorizontal: 5, } : {}]}>
                                            <View
                                                style={{
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'row',
                                                }}>
                                                <View style={{ flexDirection: 'column', top: Platform.OS == 'ios' ? 5 : 2, }}>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Text
                                                            numberOfLines={1}
                                                            style={[{
                                                                fontSize: 14,
                                                                fontFamily: 'NunitoSans-Bold',
                                                            }, switchMerchant ? {
                                                                fontSize: 10,
                                                            } : {}]}>
                                                            No.
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                fontSize: 10,
                                                                color: Colors.descriptionColor,
                                                            }}></Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>

                                        <View
                                            style={[{
                                                flexDirection: 'row',
                                                width: '24.5%',
                                                marginHorizontal: 10,
                                                borderRightWidth: 1,
                                                borderRightColor: 'lightgrey',
                                            }, switchMerchant ? {
                                                marginHorizontal: 5,
                                            } : {}]}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (
                                                        currReportDetailsSort ===
                                                        REPORT_SORT_FIELD_TYPE.VOUCHER_USERNAME_ASC
                                                    ) {
                                                        setCurrReportDetailsSort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_USERNAME_DESC,
                                                        );
                                                    } else {
                                                        setCurrReportDetailsSort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_USERNAME_ASC,
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'row',
                                                }}>
                                                <View
                                                    style={{
                                                        flexDirection: 'column',
                                                        top: Platform.OS == 'ios' ? 5 : 2,
                                                    }}>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Text
                                                            numberOfLines={1}
                                                            style={[{
                                                                fontSize: 14,
                                                                fontFamily: 'NunitoSans-Bold',
                                                            }, switchMerchant ? {
                                                                fontSize: 10,
                                                            } : {}]}>
                                                            User Name
                                                        </Text>
                                                        {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                                                    </View>
                                                </View>
                                                <View style={[{ marginLeft: '3%' }, switchMerchant ? { justifyContent: 'center', } : {}]}>
                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-up"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportDetailsSort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_USERNAME_ASC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-down"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportDetailsSort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_USERNAME_DESC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </TouchableOpacity>
                                        </View>

                                        <View
                                            style={[{
                                                flexDirection: 'row',
                                                width: '31%',
                                                marginHorizontal: 10,
                                                borderRightWidth: 1,
                                                borderRightColor: 'lightgrey',
                                            }, switchMerchant ? {
                                                width: '26%', marginHorizontal: 5,
                                            } : {}]}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (
                                                        currReportDetailsSort ===
                                                        REPORT_SORT_FIELD_TYPE.VOUCHER_REDEEMED_DATE_ASC
                                                    ) {
                                                        setCurrReportDetailsSort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_REDEEMED_DATE_DESC,
                                                        );
                                                    } else {
                                                        setCurrReportDetailsSort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_REDEEMED_DATE_ASC,
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'row',
                                                }}>
                                                <View
                                                    style={{
                                                        flexDirection: 'column',
                                                        top: Platform.OS == 'ios' ? 5 : 2,
                                                    }}>
                                                    <Text
                                                        numberOfLines={2}
                                                        style={[{
                                                            fontSize: 14,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }, switchMerchant ? {
                                                            fontSize: 10,
                                                        } : {}]}>
                                                        Redeem Date/Time
                                                    </Text>
                                                    {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                                                </View>
                                                <View style={[{ marginLeft: '3%' }, switchMerchant ? { justifyContent: 'center', } : {}]}>
                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-up"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportDetailsSort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_REDEEMED_DATE_ASC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-down"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportDetailsSort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_REDEEMED_DATE_DESC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                        <View
                                            style={[{
                                                flexDirection: 'row',
                                                width: '28%',
                                                marginHorizontal: 10,
                                                // borderRightWidth: 1,
                                                // borderRightColor: 'lightgrey',
                                            }, switchMerchant ? {
                                                width: '26%', marginHorizontal: 5,
                                            } : {}]}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (
                                                        currReportDetailsSort ===
                                                        REPORT_SORT_FIELD_TYPE.VOUCHER_USED_DATE_ASC
                                                    ) {
                                                        setCurrReportDetailsSort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_USED_DATE_DESC,
                                                        );
                                                    } else {
                                                        setCurrReportDetailsSort(
                                                            REPORT_SORT_FIELD_TYPE.VOUCHER_USED_DATE_ASC,
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    alignItems: 'flex-start',
                                                    flexDirection: 'row',
                                                }}>
                                                <View
                                                    style={{
                                                        flexDirection: 'column',
                                                        top: Platform.OS == 'ios' ? 5 : 2,
                                                    }}>
                                                    <Text
                                                        numberOfLines={2}
                                                        style={[{
                                                            fontSize: 14,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }, switchMerchant ? {
                                                            fontSize: 10,
                                                        } : {}]}>
                                                        Used Date/Time
                                                    </Text>
                                                    {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                                                </View>
                                                <View style={[{ marginLeft: '3%' }, switchMerchant ? { justifyContent: 'center', } : {}]}>
                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-up"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportDetailsSort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_USED_DATE_ASC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity>
                                                        <Entypo
                                                            name="triangle-down"
                                                            size={switchMerchant ? 10 : 14}
                                                            color={
                                                                currReportDetailsSort ===
                                                                    REPORT_SORT_FIELD_TYPE.VOUCHER_USED_DATE_DESC
                                                                    ? Colors.secondaryColor
                                                                    : Colors.descriptionColor
                                                            }></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )}

                                {!showDetails ? (
                                    <>
                                        {dailySalesDetailsList.length > 0 ? (
                                            <FlatList
                                                //data and extraData using dummy data
                                                data={sortReportDataList(
                                                    dailySalesDetailsList,
                                                    currReportSummarySort,
                                                ).slice(
                                                    (currentPage - 1) * perPage,
                                                    currentPage * perPage,
                                                )}
                                                extraData={merchantVouchers}
                                                renderItem={renderItem}
                                                keyExtractor={(item, index) => String(index)}
                                                style={{ marginTop: 10 }}
                                                initialNumToRender={8}
                                            />
                                        ) : (
                                            <View
                                                style={{
                                                    height: Dimensions.get('screen').height * 0.4,
                                                }}>
                                                <View
                                                    style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: '100%',
                                                    }}>
                                                    <Text style={[{ color: Colors.descriptionColor }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                        - No Data Available -
                                                    </Text>
                                                </View>
                                            </View>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {transactionTypeSalesDetails.length > 0 ? (
                                            <FlatList
                                                data={sortReportDataList(
                                                    transactionTypeSalesDetails,
                                                    currReportDetailsSort,
                                                ).slice(
                                                    (currentPage - 1) * perPage,
                                                    currentPage * perPage,
                                                )}
                                                // extraData={transactionTypeSales}
                                                // extraData={merchantVouchers}
                                                renderItem={renderItemDetails}
                                                keyExtractor={(item, index) => String(index)}
                                                style={{ marginTop: 10 }}
                                            />
                                        ) : (
                                            <View
                                                style={{
                                                    height: Dimensions.get('screen').height * 0.4,
                                                }}>
                                                <View
                                                    style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: '100%',
                                                    }}>
                                                    <Text style={[{ color: Colors.descriptionColor }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                        - No Data Available -
                                                    </Text>
                                                </View>
                                            </View>
                                        )}
                                    </>
                                )}
                            </View>
                            {!showDetails ? (
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginTop: 10,
                                        width: '100%',
                                        alignItems: 'center', alignSelf: 'center',
                                        justifyContent: 'flex-end',
                                        paddingBottom: 15,
                                    }}>
                                    <Text
                                        style={
                                            switchMerchant
                                                ? { fontSize: 10, marginRight: '1%' }
                                                : {
                                                    marginRight: '1%', fontSize: 14, fontFamily: 'NunitoSans-Bold'
                                                }
                                        }>
                                        Items Showed
                                    </Text>
                                    <View style={{
                                        width: Platform.OS === 'ios' ? 65 : '13%', //65,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: Platform.OS === 'ios' ? 0 : 0,
                                        //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                                        // paddingTop: '-60%',
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                        marginRight: '1%',
                                    }}>
                                        <Picker
                                            selectedValue={perPage}
                                            style={{
                                                inputIOS: {
                                                    fontSize: switchMerchant ? 10 : 14,
                                                    fontFamily: "NunitoSans-Regular",
                                                    textAlign: "center",
                                                },
                                                inputAndroid: {
                                                    fontSize: switchMerchant ? 10 : 14,
                                                    fontFamily: "NunitoSans-Regular",
                                                    justifyContent: "center",
                                                    textAlign: "center",
                                                    height: 40,
                                                    color: "black",
                                                },
                                                inputAndroidContainer: { width: "100%" },
                                                //backgroundColor: 'red',
                                                height: 35,
                                                borderRadius: 5,

                                                chevronContainer: {
                                                    display: "none",
                                                },
                                                chevronDown: {
                                                    display: "none",
                                                },
                                                chevronUp: {
                                                    display: "none",
                                                },
                                            }}
                                            onValueChange={(value, text) => {
                                                setPerPage(value);
                                                var currentPageTemp =
                                                    text.length > 0 ? parseInt(text) : 1;

                                                setCurrentPage(
                                                    currentPageTemp > pageCount
                                                        ? pageCount
                                                        : currentPageTemp < 1
                                                            ? 1
                                                            : currentPageTemp
                                                );
                                            }}
                                        >
                                            {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                                                label: "All",
                                                value: !showDetails
                                                    ? dailySalesDetailsList.length
                                                    : transactionTypeSalesDetails.length,
                                            }).map((value, index) => {
                                                return (
                                                    <Picker.Item
                                                        key={index}
                                                        label={value.label}
                                                        value={value.value}
                                                    />
                                                );
                                            })}
                                        </Picker>
                                    </View>

                                    <Text
                                        style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%' }
                                        }>
                                        Page
                                    </Text>
                                    <View
                                        style={{
                                            width: switchMerchant ? 65 : 70,
                                            height: switchMerchant ? 20 : 35,
                                            backgroundColor: Colors.whiteColor,
                                            borderRadius: 10,
                                            justifyContent: 'center',
                                            paddingHorizontal: 22,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                        }}>
                                        {console.log('currentPage')}
                                        {console.log(currentPage)}

                                        <TextInput
                                            onChangeText={(text) => {
                                                var currentPageTemp =
                                                    text.length > 0 ? parseInt(text) : 1;

                                                setCurrentPage(
                                                    currentPageTemp > pageCount
                                                        ? pageCount
                                                        : currentPageTemp < 1
                                                            ? 1
                                                            : currentPageTemp,
                                                );
                                            }}
                                            placeholder={currentPage.toString()}
                                            placeholderTextColor={Colors.descriptionColor}
                                            style={{
                                                color: 'black',
                                                // fontFamily: 'NunitoSans-Regular',
                                                fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular',
                                                marginTop: Platform.OS === 'ios' ? 0 : -15,
                                                marginBottom: Platform.OS === 'ios' ? 0 : -15,
                                                textAlign: 'center',
                                                width: '100%',
                                            }}
                                            value={currentPage.toString()}
                                            defaultValue={currentPage.toString()}
                                            keyboardType={'numeric'}
                                            onFocus={() => {
                                                setPushPagingToTop(true);
                                            }}
                                        />
                                    </View>
                                    <Text
                                        style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%', marginLeft: '1%' }
                                        }>
                                        of {pageCount}
                                    </Text>
                                    <TouchableOpacity
                                        style={{
                                            width: switchMerchant ? 30 : 45,
                                            height: switchMerchant ? 20 : 28,
                                            backgroundColor: Colors.primaryColor,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onPress={() => {
                                            prevPage();
                                        }}>
                                        <ArrowLeft
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={{
                                            width: switchMerchant ? 30 : 45,
                                            height: switchMerchant ? 20 : 28,
                                            backgroundColor: Colors.primaryColor,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onPress={() => {
                                            nextPage();
                                        }}>
                                        <ArrowRight
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                </View>
                            ) : (
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        marginTop: 10,
                                        width: '100%',
                                        alignItems: 'center', alignSelf: 'center',
                                        justifyContent: 'flex-end',
                                        paddingBottom: 15,
                                    }}>
                                    <Text
                                        style={
                                            switchMerchant
                                                ? { fontSize: 10, marginRight: '1%' }
                                                : {
                                                    marginRight: '1%', fontSize: 14, fontFamily: 'NunitoSans-Bold'
                                                }
                                        }>
                                        Items Showed
                                    </Text>
                                    <View style={{
                                        width: Platform.OS === 'ios' ? 65 : '13%', //65,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: Platform.OS === 'ios' ? 0 : 0,
                                        //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                                        // paddingTop: '-60%',
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                        marginRight: '1%',
                                    }}>
                                        <Picker
                                            useNativeAndroidPickerStyle={false}
                                            style={{
                                                inputIOS: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', textAlign: 'center' },
                                                inputAndroid: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', justifyContent: 'center', textAlign: 'center', height: 40, color: 'black' },
                                                inputAndroidContainer: { width: '100%' },
                                                //backgroundColor: 'red',
                                                height: 35,

                                                chevronContainer: {
                                                    display: "none",
                                                },
                                                chevronDown: {
                                                    display: "none",
                                                },
                                                chevronUp: {
                                                    display: "none",
                                                },
                                            }}
                                            items={TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                                                label: 'All',
                                                value: !showDetails
                                                    ? dailySalesDetailsList.length
                                                    : transactionTypeSalesDetails.length,
                                            })}
                                            value={perPage}
                                            onValueChange={(value) => {
                                                setPerPage(value);
                                            }}
                                        />
                                    </View>

                                    <Text
                                        style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%' }
                                        }>
                                        Page
                                    </Text>
                                    <View
                                        style={{
                                            width: switchMerchant ? 65 : 70,
                                            height: switchMerchant ? 20 : 35,
                                            backgroundColor: Colors.whiteColor,
                                            borderRadius: 10,
                                            justifyContent: 'center',
                                            paddingHorizontal: 22,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                        }}>
                                        {console.log('currentDetailsPage')}
                                        {console.log(currentDetailsPage)}

                                        <TextInput
                                            onChangeText={(text) => {
                                                var currentPageTemp =
                                                    text.length > 0 ? parseInt(text) : 1;

                                                setCurrentDetailsPage(
                                                    currentPageTemp > pageCount
                                                        ? pageCount
                                                        : currentPageTemp < 1
                                                            ? 1
                                                            : currentPageTemp,
                                                );
                                            }}
                                            placeholder={currentDetailsPage.toString()}
                                            placeholderTextColor={Colors.descriptionColor}
                                            style={{
                                                color: 'black',
                                                // fontFamily: 'NunitoSans-Regular',
                                                fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular',
                                                marginTop: Platform.OS === 'ios' ? 0 : -15,
                                                marginBottom: Platform.OS === 'ios' ? 0 : -15,
                                                textAlign: 'center',
                                                width: '100%',
                                            }}
                                            value={currentDetailsPage.toString()}
                                            defaultValue={currentDetailsPage.toString()}
                                            keyboardType={'numeric'}
                                            onFocus={() => {
                                                setPushPagingToTop(true);
                                            }}
                                        />
                                    </View>
                                    <Text
                                        style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginLeft: '1%', marginRight: '1%' }
                                        }>
                                        of {pageCount}
                                    </Text>
                                    <TouchableOpacity
                                        style={{
                                            width: switchMerchant ? 30 : 45,
                                            height: switchMerchant ? 20 : 28,
                                            backgroundColor: Colors.primaryColor,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onPress={() => {
                                            prevDetailsPage();
                                        }}>
                                        <ArrowLeft
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={{
                                            width: switchMerchant ? 30 : 45,
                                            height: switchMerchant ? 20 : 28,
                                            backgroundColor: Colors.primaryColor,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onPress={() => {
                                            nextDetailsPage();
                                        }}>
                                        <ArrowRight
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                </View>
                            )}
                        </View>
                    </View>
                </ScrollView>
            </View>

            {expandLineSelection ? (
                <View style={[styles.ManageFilterBox]}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            margin: 10,
                        }}>
                        <Text
                            style={{
                                fontSize: 16,
                                Colors: '#27353C',
                                fontFamily: 'Nunitosans-bold',
                            }}>
                            Manage Filter
                        </Text>
                    </View>
                    <View
                        style={{
                            borderWidth: 0.5,
                            borderColor: '#D2D2D2',
                            width: '100%',
                            marginTop: 5,
                            marginBottom: 5,
                        }}></View>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            margin: 10,
                            height: 35,
                        }}>
                        <DropDownPicker
                            arrowColor={'#BDBDBD'}
                            arrowSize={23}
                            arrowStyle={{ fontWeight: 'bold' }}
                            style={{ width: 250, height: 35 }}
                            items={
                                CHART_FIELD_NAME_DROPDOWN_LIST[
                                CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                                ]
                            }
                            // placeholder={'Field name'}
                            // placeholderStyle={{ color: 'black' }}
                            dropDownStyle={{
                                width: 250,
                            }}
                            itemStyle={{
                                justifyContent: 'flex-start',
                            }}
                            globalTextStyle={{
                                fontFamily: 'NunitoSans-SemiBold',
                                // fontSize: 12,
                                color: Colors.fontDark,
                                marginLeft: 5,
                            }}
                            onChangeItem={(item) => {
                                // setState({ sort: selectedSort }),
                                //sortingFunc(selectedSort);
                                setSelectedChartFilterQueriesLineChart([
                                    {
                                        ...selectedChartFilterQueriesLineChart[0],
                                        fieldNameKey: item.value,
                                        fieldNameType: item.fieldType,
                                    },
                                ]);
                            }}
                            defaultValue={
                                selectedChartFilterQueriesLineChart[0].fieldNameKey
                            }
                            listMode={'SCROLLVIEW'}
                            scrollViewProps={{
                                nestedScrollEnabled: true,
                            }}
                        />
                        <DropDownPicker
                            arrowColor={'#BDBDBD'}
                            arrowSize={23}
                            arrowStyle={{ fontWeight: 'bold' }}
                            style={{ marginLeft: '5%', width: 150 }}
                            dropDownStyle={{ marginLeft: '5%', width: 150 }}
                            items={
                                CHART_FIELD_COMPARE_DROPDOWN_LIST[
                                CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                                ]
                            }
                            // placeholder={'Field name'}
                            // placeholderStyle={{ color: 'black' }}
                            dropDownStyle={{
                                width: 150,
                                marginLeft: '5%',
                            }}
                            itemStyle={{
                                justifyContent: 'flex-start',
                            }}
                            globalTextStyle={{
                                fontFamily: 'NunitoSans-SemiBold',
                                // fontSize: 12,
                                color: Colors.fontDark,
                                marginLeft: 5,
                            }}
                            onChangeItem={(item) => {
                                // setState({ sort: selectedSort }),
                                //sortingFunc(selectedSort);
                                setSelectedChartFilterQueriesLineChart([
                                    {
                                        ...selectedChartFilterQueriesLineChart[0],
                                        fieldCompare: item.value,
                                    },
                                ]);
                            }}
                            defaultValue={
                                selectedChartFilterQueriesLineChart[0].fieldCompare
                            }
                            listMode={'SCROLLVIEW'}
                            scrollViewProps={{
                                nestedScrollEnabled: true,
                            }}
                        />
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            margin: 10,
                            height: 35,
                            zIndex: -1,
                        }}>
                        <TextInput
                            style={{
                                width: 410,
                                borderWidth: 1,
                                borderColor: '#D2D2D2',
                                alignItems: 'center',
                                justifyContent: 'center',
                                color: 'black',
                                fontFamily: 'NunitoSans-Regular',
                                borderRadius: 8,
                                paddingVertical: 3,
                                paddingLeft: 15,
                            }}
                            {...(selectedChartFilterQueriesLineChart[0].fieldNameType ===
                                CHART_FIELD_TYPE.DATETIME && {
                                onPressIn: () => {
                                    setShowDateTimePickerFilterLineChart(true);
                                },
                            })}
                            editable={
                                selectedChartFilterQueriesLineChart[0].fieldNameType ===
                                    CHART_FIELD_TYPE.DATETIME
                                    ? false
                                    : true
                            }
                            placeholder="   Field Value"
                            placeholderStyle={{
                                color: 'black',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontFamily: 'NunitoSans-Regular',
                            }}
                            defaultValue={
                                selectedChartFilterQueriesLineChart[0].fieldNameType ===
                                    CHART_FIELD_TYPE.DATETIME
                                    ? moment(
                                        selectedChartFilterQueriesLineChart[0].fieldDataValue,
                                    ).format('DD/MM/YYYY')
                                    : selectedChartFilterQueriesLineChart[0].fieldDataValue
                                        ? selectedChartFilterQueriesLineChart[0].fieldDataValue
                                        : ''
                            }
                            onChangeText={(text) => {
                                setSelectedChartFilterQueriesLineChart([
                                    {
                                        ...selectedChartFilterQueriesLineChart[0],
                                        fieldDataValue: text,
                                    },
                                ]);
                            }}
                            keyboardType={
                                selectedChartFilterQueriesLineChart[0].fieldNameType ===
                                    CHART_FIELD_TYPE.STRING
                                    ? 'default'
                                    : 'numeric'
                            }
                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        />
                    </View>

                    <View
                        style={{
                            borderWidth: 0.5,
                            borderColor: '#D2D2D2',
                            width: '100%',
                            marginTop: 5,
                            marginBottom: 5,
                        }}></View>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            margin: 10,
                            zIndex: -1,
                        }}>
                        <TouchableOpacity
                            style={{
                                justifyContent: 'center',
                                width: 130,
                            }}
                            onPress={() => {
                                // setExpandLineSelection(!expandLineSelection)

                                setExpandLineSelection(false);

                                setAppliedChartFilterQueriesLineChart([]);
                            }}>
                            <View
                                style={{
                                    justifyContent: 'center',
                                    flexDirection: 'row',
                                    borderWidth: 1,
                                    borderColor: '#BDBDBD',
                                    borderRadius: 5,
                                    alignItems: 'center',
                                    backgroundColor: '#FFFFFF',
                                    width: 130,
                                    height: 40,
                                }}>
                                <Text
                                    style={{
                                        color: '#BDBDBD',
                                        fontSize: 16,
                                        fontFamily: 'Nunitosans-bold',
                                        marginLeft: 7,
                                    }}>
                                    CANCEL
                                </Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={{
                                justifyContent: 'center',
                                width: 130,
                                marginLeft: '3%',
                            }}
                            onPress={() => {
                                // setExpandLineSelection(!expandLineSelection)

                                setExpandLineSelection(false);

                                setAppliedChartFilterQueriesLineChart(
                                    selectedChartFilterQueriesLineChart,
                                );
                            }}>
                            <View
                                style={{
                                    justifyContent: 'center',
                                    width: '100%',
                                    flexDirection: 'row',
                                    backgroundColor: '#4E9F7D',
                                    borderRadius: 5,
                                    height: 40,
                                    alignItems: 'center',
                                }}>
                                <Text
                                    style={{
                                        color: '#FFFFFF',
                                        fontSize: 16,
                                        fontFamily: 'Nunitosans-bold',
                                        marginLeft: 7,
                                    }}>
                                    APPLY
                                </Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            ) : null}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.highlightColor,
        flexDirection: 'row',
    },
    headerLogo: {
        width: 112,
        height: 25,
        marginLeft: 10,
    },
    sidebar: {
        width: Dimensions.get('screen').width * Styles.sideBarWidth,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
    circleIcon: {
        width: 30,
        height: 30,
        // resizeMode: 'contain',
        marginRight: 10,
        alignSelf: 'center'
    },
    confirmBox: {
        width: Dimensions.get('screen').width * 0.4,
        height: Dimensions.get('screen').height * 0.3,
        borderRadius: 10,
        backgroundColor: Colors.whiteColor,
        justifyContent: 'space-between'
    },
    textInput8: {
        fontFamily: 'NunitoSans-Regular',
        width: 75,
        height: 50,
        flex: 1,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 5,
    },

    textInput9: {
        fontFamily: 'NunitoSans-Regular',
        width: 430,
        height: 50,
        //flex: 1,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 5,
    },

    headerLeftStyle: {
        width: Dimensions.get('screen').width * 0.17,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        flex: 1,
        backgroundColor: Colors.modalBgColor,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalView: {
        height: Dimensions.get('screen').width * 0.3,
        width: Dimensions.get('screen').width * 0.4,
        backgroundColor: Colors.whiteColor,
        borderRadius: 12,
        padding: Dimensions.get('screen').width * 0.03,
        alignItems: 'center',
        justifyContent: 'center'
    },
    closeButton: {
        position: 'absolute',
        right: Dimensions.get('screen').width * 0.02,
        top: Dimensions.get('screen').width * 0.02,

        elevation: 1000,
        zIndex: 1000,
    },
    modalTitle: {
        alignItems: 'center',
        top: '20%',
        position: 'absolute',
    },
    modalBody: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'

    },
    modalTitleText: {
        fontFamily: 'NunitoSans-Bold',
        textAlign: 'center',
        fontSize: 20,
    },
    modalDescText: {
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 16,
        color: Colors.fieldtTxtColor
    },
    modalBodyText: {
        flex: 1,
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 25,
        width: "20%",
    },
    modalSaveButton: {
        width: 130,
        backgroundColor: Colors.fieldtBgColor,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,

        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 1,

        marginVertical: 10,
    },
    ManageFilterBox: {
        height: Platform.OS === 'ios' ? 180 : Dimensions.get('screen').height * 0.24,
        width: Platform.OS === 'ios' ? 400 : Dimensions.get('screen').width * 0.33,
        borderWidth: 1,
        borderColor: '#E5E5E5',
        position: 'absolute',
        marginTop: Platform.OS === 'ios' ? '13%' : '13%',
        marginLeft: Platform.OS === 'ios' ? '12%' : '12%',
        shadowColor: '#000',
        shadowOffset: {
            width: 1,
            height: 5,
        },
        shadowOpacity: 0.32,
        shadowRadius: 3.22,
        elevation: 10,
        zIndex: 1000,
        borderRadius: 10,
        //borderTopLeftRadius: 0,
        backgroundColor: Colors.whiteColor,
        //justifyContent: 'space-between'
    },
});

export default VoucherReport;
