import React, { Component, useReducer, useState, useEffect } from 'react';
import {
    StyleSheet,
    ScrollView,
    Image,
    View,
    Text,
    Alert,
    TouchableOpacity,
    TextInput,
    Dimensions,
    FlatList,
    Modal,
    PermissionsAndroid,
    Platform,
    ActivityIndicator,
    KeyboardAvoidingView,
    useWindowDimensions,
} from 'react-native';
import Colors from '../constant/Colors';
import SideBar from './SideBar';
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Feather';
import Icon1 from 'react-native-vector-icons/FontAwesome';
import Icon2 from 'react-native-vector-icons/EvilIcons';
import DropDownPicker from 'react-native-dropdown-picker';
// import { ceil } from 'react-native-reanimated';
import DateTimePicker from 'react-native-modal-datetime-picker';
import moment from 'moment';
import Close from 'react-native-vector-icons/AntDesign';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Styles from '../constant/Styles';
//import DocumentPicker from 'react-native-document-picker'; //need fix
import Ionicons from 'react-native-vector-icons/Ionicons';
import { CommonStore } from '../store/commonStore';
import { EMAIL_REPORT_TYPE, PURCHASE_ORDER_STATUS } from '../constant/common';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import {
    convertArrayToCSV,
    //autofitColumns,
    getImageFromFirebaseStorage,
    getPathForFirebaseStorageFromBlob,
    uploadFileToFirebaseStorage,
    generateEmailReport,
} from '../util/common';
import SupplierScreen from './SupplierScreen';
import XLSX from 'xlsx';
import AntDesign from 'react-native-vector-icons/AntDesign';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { OutletStore } from '../store/outletStore';
import AsyncImage from '../components/asyncImage';

//const RNFS = require('react-native-fs');

const InventoryScreen = (props) => {
    const { navigation } = props;

    const { height: windowHeight, width: windowWidth } = useWindowDimensions();
    const [productUsingSupplyItem, setProductUsingSupplyItem] = useState(false);
    const [lowStockAlert, setLowStockAlert] = useState(true);
    const [purchaseOrder, setPurchaseOrder] = useState(false);
    const [stockTransfer, setStockTransfer] = useState(false);
    const [stockTake, setStockTake] = useState(false);
    const [addStockTake, setAddStockTake] = useState(false);
    const [addPurchase, setAddPurchase] = useState(false);
    const [editPurchase, setEditPurchase] = useState(false);
    const [addStockTransfer, setAddStockTransfer] = useState(false);
    const [stockList, setStockList] = useState([]);
    const [stockTransferList, setStockTransferList] = useState([]);
    const [stockTakeList, setStockTakeList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [itemsToOrder, setItemsToOrder] = useState([{}, {}, {}]);
    const [itemsToOrder2, setItemsToOrder2] = useState([{}, {}, {}]);
    const [addStockTransferList, setAddStockTransferList] = useState([
        {},
        {},
        {},
    ]);
    const [switchMerchant, setSwitchMerchant] = useState(false);
    const [addCountedStockTakeList, setAddCountedStockTakeList] = useState([
        {},
        {},
        {},
    ]);
    const [addUnCountedStockTakeList, setAddUnCountedStockTakeList] = useState([
        {},
        {},
        {},
    ]);
    const [productList, setProductList] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [isSelected2, setIsSelected2] = useState(false);
    const [isSelected3, setIsSelected3] = useState(true);
    const [isSelected4, setIsSelected4] = useState(false);
    const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
    const [date, setDate] = useState('');
    const [visible, setVisible] = useState(false);
    const [Email, setEmail] = useState(null);
    const [modal, setModal] = useState(false);
    // const [// outletId, set// outletId] = useState(1);
    const [outletId, setOutletId] = useState(User.getOutletId());
    const [search, setSearch] = useState('');
    const [search2, setSearch2] = useState('');
    const [search3, setSearch3] = useState('');
    const [ideal, setIdeal] = useState('');
    const [minimum, setMinimum] = useState('');
    const [itemId, setItemId] = useState('');
    const [choose, setChoose] = useState(null);

    const [loading, setLoading] = useState(false);

    const [exportModal, setExportModal] = useState(false);
    const [importBatchModal, setImportBatchModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [selectedOutletSupplyItem, setSelectedOutletSupplyItem] = useState({});
    const [inUsedOutletItems, setInUsedOutletItems] = useState([]);

    const [exportModalVisibility, setExportModalVisibility] = useState(false);
    const [exportEmail, setExportEmail] = useState('');

    //const [navigateToSupplier, setNavigateToSupplier] = useState(false);
    //const selectedSupplierEdit = CommonStore.useState(s => s.selectedSupplierEdit);
    //const [goToSupplier, setGoToSupplier] = useState(false);

    const [totalLowStockQuantity, setTotalLowStockQuantity] = useState(0);
    ///////////////////////////////////////////////////////

    const [skuOrderQuantityDict, setSkuOrderQuantityDict] = useState({});
    const [skuEstimatedArrivalDateDict, setSkuEstimatedArrivalDateDict] =
        useState({});

    const [inputStockIdealDict, setInputStockIdealDict] = useState({});
    const [inputStockWarningDict, setInputStockWarningDict] = useState({});

    const outletSupplyItems = CommonStore.useState((s) => s.outletSupplyItems);

    const supplyItemsSkuDict = CommonStore.useState((s) => s.supplyItemsSkuDict);
    const suppliersDict = CommonStore.useState((s) => s.suppliersDict);

    const outletCategoriesDict = OutletStore.useState(
        (s) => s.outletCategoriesDict,
    );

    const purchaseOrders = CommonStore.useState((s) => s.purchaseOrders);

    const isLoading = CommonStore.useState((s) => s.isLoading);

    const [isLoadingExcel, setIsLoadingExcel] = useState(false);
    const [isLoadingCsv, setIsLoadingCsv] = useState(false);

    const firebaseUid = UserStore.useState((s) => s.firebaseUid);
    const userName = UserStore.useState((s) => s.name);
    const merchantName = MerchantStore.useState((s) => s.name);

    const currOutletId = MerchantStore.useState((s) => s.currOutletId);

    const outletItems = OutletStore.useState((s) => s.outletItems);

    useEffect(() => {
        var inUsedOutletItemsTemp = [];

        if (selectedOutletSupplyItem && selectedOutletSupplyItem.uniqueId) {
            for (var i = 0; i < outletItems.length; i++) {
                if (outletItems[i].stockLinkItems) {
                    for (var j = 0; j < outletItems[i].stockLinkItems.length; j++) {
                        if (
                            outletItems[i].stockLinkItems[j].sku ===
                            selectedOutletSupplyItem.sku
                        ) {
                            // means got used this outlet supply item

                            inUsedOutletItemsTemp.push(outletItems[i]);
                        }
                    }
                }
            }
        }

        setInUsedOutletItems(inUsedOutletItemsTemp);
    }, [selectedOutletSupplyItem, outletItems]);

    useEffect(() => {
        var skuOrderQuantityDictTemp = {};
        var skuEstimatedArrivalDateDictTemp = {};

        var purchaseOrdersActive = purchaseOrders.filter(
            (po) => po.status !== PURCHASE_ORDER_STATUS.COMPLETED,
        );

        for (var i = 0; i < purchaseOrdersActive.length; i++) {
            for (var j = 0; j < purchaseOrdersActive[i].poItems.length; j++) {
                const record = purchaseOrdersActive[i].poItems[j];

                if (skuOrderQuantityDictTemp[record.sku] === undefined) {
                    skuOrderQuantityDictTemp[record.sku] = record.orderQuantity;
                } else {
                    skuOrderQuantityDictTemp[record.sku] += record.orderQuantity;
                }

                skuEstimatedArrivalDateDictTemp[record.sku] =
                    record.estimatedArrivalDate;
            }
        }

        setSkuOrderQuantityDict(skuOrderQuantityDictTemp);
        setSkuEstimatedArrivalDateDict(skuEstimatedArrivalDateDictTemp);
    }, [purchaseOrders]);

    useEffect(() => {
        requestStoragePermission();
    }, []);

    ///////////////////////////////////////////////////////

    // add item screen

    const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
    const [selectedTargetOutletId, setSelectedTargetOutletId] = useState('');

    const [supplyItemDropdownList, setSupplyItemDropdownList] = useState([]);

    const [poItems, setPoItems] = useState([
        {
            supplyItemId: '',
            name: '',
            sku: '',
            skuMerchant: '',
            quantity: 0,
            unit: '',
            // orderQuantity: 0,
            // receivedQuantity: 0,
            // price: 0,
            // totalPrice: 0,
            insertQuantity: 0,
            supplyItem: null,
        },
    ]);

    const outletSupplyItemsSkuDict = CommonStore.useState(
        (s) => s.outletSupplyItemsSkuDict,
    );

    const supplyItems = CommonStore.useState((s) => s.supplyItems);
    const supplyItemsDict = CommonStore.useState((s) => s.supplyItemsDict);
    const suppliers = CommonStore.useState((s) => s.suppliers);
    const allOutlets = MerchantStore.useState((s) => s.allOutlets);
    const merchantId = UserStore.useState((s) => s.merchantId);
    const merchantLogo = MerchantStore.useState((s) => s.logo);

    const outletSelectDropdownView = CommonStore.useState(
        (s) => s.outletSelectDropdownView,
    );

    useEffect(() => {
        setTargetOutletDropdownList(
            allOutlets.map((outlet) => ({
                label: outlet.name,
                value: outlet.uniqueId,
            })),
        );

        if (selectedTargetOutletId === '' && allOutlets.length > 0) {
            setSelectedTargetOutletId(allOutlets[0].uniqueId);
        }
    }, [allOutlets]);

    useEffect(() => {
        setSupplyItemDropdownList(
            supplyItems.map((supplyItem) => {
                // if (selectedSupplierId === supplyItem.supplierId) {
                //   return { label: supplyItem.name, value: supplyItem.uniqueId };
                // }

                return { label: supplyItem.name, value: supplyItem.uniqueId };
            }),
        );

        if (
            supplyItems.length > 0 &&
            poItems.length === 1 &&
            poItems[0].supplyItemId === ''
        ) {
            setPoItems([
                {
                    supplyItemId: supplyItems[0].uniqueId,
                    name: supplyItems[0].name,
                    sku: supplyItems[0].sku,
                    skuMerchant: supplyItems[0].skuMerchant,
                    quantity: outletSupplyItemsSkuDict[supplyItems[0].sku]
                        ? outletSupplyItemsSkuDict[supplyItems[0].sku].quantity
                        : 0, // check if the supply item sku for this outlet existed
                    unit: supplyItems[0].unit,
                    // orderQuantity: 0,
                    // receivedQuantity: 0,
                    // price: supplyItems[0].price,
                    // totalPrice: 0,
                    insertQuantity: 0,
                    supplyItem: supplyItems[0],
                },
            ]);
        } else if (
            poItems[0].supplyItemId !== '' &&
            Object.keys(supplyItemsDict).length > 0
        ) {
            var poItemsTemp = [...poItems];

            for (var i = 0; i < poItemsTemp.length; i++) {
                const supplyItem = supplyItemsDict[poItemsTemp[i].supplyItemId];

                poItemsTemp[i] = {
                    ...poItemsTemp[i],
                    quantity: outletSupplyItemsSkuDict[supplyItem.sku]
                        ? outletSupplyItemsSkuDict[supplyItem.sku].quantity
                        : 0, // check if the supply item sku for this outlet existed | might changed in real time
                    // price: supplyItem.price, // might changed in real time
                };
            }

            setPoItems(poItemsTemp);
        }
    }, [supplyItems, supplyItemsDict, outletSupplyItemsSkuDict]);

    ///////////////////////////////////////////////////////

    const setState = () => { };

    // navigation.dangerouslyGetParent().setOptions({
    //   tabBarVisible: false,
    // });

    navigation.setOptions({
        headerLeft: () => (
            <View style={styles.headerLeftStyle}>
                <Image
                    style={{
                        width: 124,
                        height: 26,
                    }}
                    resizeMode="contain"
                    source={require('../assets/image/logo.png')}
                />
            </View>
        ),
        headerTitle: () => (
            <View
                style={[
                    {
                        justifyContent: 'center',
                        alignItems: 'center',
                        // bottom: -2,
                        bottom: switchMerchant ? '2%' : 0,
                    },
                    Dimensions.get('screen').width >= 768 && switchMerchant
                        ? { right: Dimensions.get('screen').width * 0.1 }
                        : {},
                    Dimensions.get('screen').width <= 768
                        ? { right: Dimensions.get('screen').width * 0.12 }
                        : {},
                ]}>
                <Text
                    style={{
                        fontSize: switchMerchant ? 20 : 24,
                        // lineHeight: 25,
                        textAlign: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        color: Colors.whiteColor,
                        opacity: 1,
                    }}>
                    Inventory
                </Text>
            </View>
        ),
        headerRight: () => (
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                {outletSelectDropdownView()}
                <View
                    style={{
                        backgroundColor: 'white',
                        width: 0.5,
                        height: Dimensions.get('screen').height * 0.025,
                        opacity: 0.8,
                        marginHorizontal: 15,
                        bottom: -1,
                    }}></View>
                <TouchableOpacity
                    onPress={() => navigation.navigate('Setting')}
                    style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                        style={{
                            fontFamily: 'NunitoSans-SemiBold',
                            fontSize: 16,
                            color: Colors.secondaryColor,
                            marginRight: 15,
                        }}>
                        {userName}
                    </Text>
                    <View
                        style={{
                            marginRight: 30,
                            width: Dimensions.get('screen').height * 0.05,
                            height: Dimensions.get('screen').height * 0.05,
                            borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                        }}>
                        <Image
                            style={{
                                width: Dimensions.get('screen').height * 0.035,
                                height: Dimensions.get('screen').height * 0.035,
                                alignSelf: 'center',
                            }}
                            source={require('../assets/image/profile-pic.jpg')}
                        />
                    </View>
                </TouchableOpacity>
            </View>
        ),
    });

    // componentDidMount = () => {
    //   // setInterval(() => {
    //   //   getStockOrder()
    //   //   getStockTransfer()
    //   //   getLowStock()
    //   // }, 1000);
    //   getStockOrder()
    //   getStockTransfer()
    //   getLowStock()
    // }

    // async componentWillMount = () => {
    //   await requestStoragePermission()
    // }

    const requestStoragePermission = async () => {
        try {
            const granted = await PermissionsAndroid.request(
                PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
                {
                    title: 'KooDoo Merchant Storage Permission',
                    message: 'KooDoo Merchant App needs access to your storage ',
                    buttonNegative: 'Cancel',
                    buttonPositive: 'OK',
                },
            );
            if (granted === PermissionsAndroid.RESULTS.GRANTED) {
                console.log('Storage permission granted');
            } else {
                console.log('Storage permission denied');
            }
        } catch (err) {
            console.warn(err);
        }
    };

    const importCSV = () => {
        /* try {
          const res = DocumentPicker.pick({
            type: [DocumentPicker.types.csv],
          });
          console.log(res.uri, res.type, res.name, res.size);
        } catch (err) {
          if (DocumentPicker.isCancel(err)) {
            // User cancelled the picker, exit any dialogs or menus and move on
          } else {
            throw err;
          }
        } */
    };

    const convertTemplateToExcelFormat = () => {
        var excelTemplate = [];

        // var allOutletsStr = allOutlets.map(item => item.name).join(';');
        var allOutletsStr = allOutlets
            .map(
                (item) =>
                    `${item.name}:${Math.floor(Math.random() * (100 - 0 + 1)) + 0}|${Math.floor(Math.random() * (100 - 0 + 1)) + 0
                    }|${Math.floor(Math.random() * (100 - 0 + 1)) + 0}`,
            )
            .join(';');

        // var taxName = currOutletTaxes[0].name;

        var excelColumn = {
            Name: 'Potato',
            'Outlet Stock (Quantity|Ideal|Warning)': allOutletsStr,
            SKU: 'RP001',
            Description: 'Fresh potato',
            Price: '1.99',
            Currency: 'MYR',
            Unit: 'kg',
            'Supplier Name': 'Fresh Harvest Sdn Bhd',
            'Supplier Email': 'contact@freshharvest.com.my',
            'Supplier Phone': '60195935968',
            'Supplier Tax Rate': '0.05',
            'Supplier Description': 'Vegetables & fruits supplier',
            'Image Path': 'Potato',
        };
        excelTemplate.push(excelColumn);

        var excelColumn2 = {
            Name: 'Sesame oil',
            'Outlet Stock (Quantity|Ideal|Warning)': allOutletsStr,
            SKU: 'RP002',
            Description: 'Sesame oil',
            Price: '2.99',
            Currency: 'MYR',
            Unit: 'l',
            'Supplier Name': 'Oil Holding Sdn Bhd',
            'Supplier Email': 'contact@oil.com.my',
            'Supplier Phone': '60124591893',
            'Supplier Tax Rate': '0.05',
            'Supplier Description': 'Oils supplier',
            'Image Path': 'Sesame_oil',
        };
        excelTemplate.push(excelColumn2);

        console.log('excelTemplate');
        console.log(excelTemplate);

        return excelTemplate;
    };

    const exportTemplate = async () => {
        /* try {
          const excelTemplate = convertTemplateToExcelFormat();
    
          const tempFolderName = 'koodoo-inventory-template';
          const tempFolderPath = `${
            Platform.OS === 'ios'
              ? RNFS.DocumentDirectoryPath
              : RNFS.DownloadDirectoryPath
          }/${tempFolderName}`;
          RNFS.mkdir(tempFolderPath);
    
          const tempImageFolderName = 'images/Potato';
          const tempImageFolderPath = `${
            Platform.OS === 'ios'
              ? RNFS.DocumentDirectoryPath
              : RNFS.DownloadDirectoryPath
          }/${tempFolderName}/${tempImageFolderName}`;
          RNFS.mkdir(tempImageFolderPath);
    
          const tempImageFolderName2 = 'images/Sesame_oil';
          const tempImageFolderPath2 = `${
            Platform.OS === 'ios'
              ? RNFS.DocumentDirectoryPath
              : RNFS.DownloadDirectoryPath
          }/${tempFolderName}/${tempImageFolderName2}`;
          RNFS.mkdir(tempImageFolderPath2);
    
          var templateImageUrl = '';
    
          // download merchant logo as example image file
    
          await new Promise((resolve, reject) => {
            if (
              merchantLogo.startsWith('http') ||
              merchantLogo.startsWith('file')
            ) {
              templateImageUrl = merchantLogo;
    
              resolve();
            } else {
              getImageFromFirebaseStorage(merchantLogo, (parsedUrl) => {
                templateImageUrl = parsedUrl;
    
                resolve();
              });
            }
          });
    
          var tempImageFileName = 'image.' + templateImageUrl.split('.').pop();
          tempImageFileName = tempImageFileName.split('?')[0];
    
          const tempImageFilePath = `${
            Platform.OS === 'ios'
              ? RNFS.DocumentDirectoryPath
              : RNFS.DownloadDirectoryPath
          }/${tempFolderName}/${tempImageFolderName}/${tempImageFileName}`;
    
          const downloadJob = RNFS.downloadFile({
            fromUrl: templateImageUrl,
            toFile: tempImageFilePath,
          });
    
          await downloadJob.promise;
    
          const tempImageFilePath2 = `${
            Platform.OS === 'ios'
              ? RNFS.DocumentDirectoryPath
              : RNFS.DownloadDirectoryPath
          }/${tempFolderName}/${tempImageFolderName2}/${tempImageFileName}`;
    
          const downloadJob2 = RNFS.downloadFile({
            fromUrl: templateImageUrl,
            toFile: tempImageFilePath2,
          });
    
          await downloadJob2.promise;
    
          // var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/koodoo-Product${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
          var excelFile = `${
            Platform.OS === 'ios'
              ? RNFS.DocumentDirectoryPath
              : RNFS.DownloadDirectoryPath
          }/${tempFolderName}/koodoo-inventory-template.xlsx`;
          var excelWorkSheet = XLSX.utils.json_to_sheet(excelTemplate);
          var excelWorkBook = XLSX.utils.book_new();
    
          //excelWorkSheet = autofitColumns(excelTemplate, excelWorkSheet);
    
          XLSX.utils.book_append_sheet(
            excelWorkBook,
            excelWorkSheet,
            'Inventory Template',
          );
    
          const workBookData = XLSX.write(excelWorkBook, {
            type: 'binary',
            bookType: 'xlsx',
          });
    
          RNFS.writeFile(excelFile, workBookData, 'ascii')
            .then((success) => {
              console.log(`wrote file ${excelFile}`);
    
              // zip the folder
    
              const tempZipPath = `${
                Platform.OS === 'ios'
                  ? RNFS.DocumentDirectoryPath
                  : RNFS.DownloadDirectoryPath
              }/${tempFolderName}.zip`;
    
              try {
                // try unlink zip first to make sure clean zip file
    
                RNFS.unlink(tempZipPath);
              } catch (ex) {
                console.error(ex);
              }
    
              zip(tempFolderPath, tempZipPath)
                .then((path) => {
                  console.log(`zip completed at ${path}`);
    
                  RNFS.unlink(tempFolderPath);
    
                  Alert.alert(
                    'Success',
                    `Sent to ${tempZipPath}`,
                    [{text: 'OK', onPress: () => {}}],
                    {cancelable: false},
                  );
                })
                .catch((error) => {
                  console.error(error);
    
                  Alert.alert('Error', 'Failed to export template');
                });
            })
            .catch((err) => {
              console.log(err.message);
    
              Alert.alert('Error', 'Failed to export template');
            });
        } catch (ex) {
          console.error(ex);
    
          Alert.alert('Error', 'Failed to export template');
        } */
    };

    //const importTemplateData = async () => {
    /* CommonStore.update((s) => {
      s.isLoading = true;
    });

    try {
      const res = await DocumentPicker.pick({
        type: [DocumentPicker.types.zip],
        copyTo: 'documentDirectory',
      });
      console.log('res');
      console.log(res);

      // RNFetchBlob.fs.readFile(res.uri, 'base64').then(async data => {
      //   // upload to firebase storage

      //   var referenceId = uuidv4();
      //   var referencePath = `/merchant/${merchantId}}/batchUploads/${referenceId}.zip`;

      //   await uploadFileToFirebaseStorage(data, referencePath);
      // });

      var referenceId = uuidv4();
      var referencePath = `/merchant/${merchantId}}/batchUploads/${referenceId}.zip`;

      var translatedPath = await getPathForFirebaseStorageFromBlob(res[0]);

      console.log('translatedPath');
      console.log(translatedPath);

      await uploadFileToFirebaseStorage(translatedPath, referencePath);

      const body = {
        zipId: referenceId,
        zipPath: referencePath,

        userId: firebaseUid,
        merchantId: merchantId,
        outletId: currOutletId,
      };

      ApiClient.POST(API.batchCreateOutletSupplyItems, body)
        .then((result) => {
          if (result && result.status === 'success') {
            Alert.alert(
              'Success',
              'Submitted to upload queue, we will notify you once processing is done',
            );
          } else {
            Alert.alert('Error', 'Failed to import the data');
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        })
        .catch((err) => {
          console.log(err);

          Alert.alert('Error', 'Failed to import the data');

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        });
    } catch (err) {
      CommonStore.update((s) => {
        s.isLoading = false;
      });

      if (DocumentPicker.isCancel(err)) {
        // User cancelled the picker, exit any dialogs or menus and move on
      } else {
        console.error(err);

        Alert.alert('Error', 'Failed to import the data');
      }
    } */
    //};

    const importSelectFile = async () => {
        /* try {
          const res = await DocumentPicker.pick({
            type: [DocumentPicker.types.xlsx],
          });
    
          console.log(res);
        } catch (err) {
          if (DocumentPicker.isCancel(err)) {
          } else {
            throw err;
          }
        } */
    };

    //error show readAsArrayBuffer not implemented
    const importTemplate = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: 'buffer' });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            console.log(d);
        });
    };

    const exportPdfFunc = () => { };

    const exportExcelFunc = () => {
        // if (outletSupplyItems) {
        //   const csvData = convertArrayToCSV(outletSupplyItems);
        //   const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir
        //     }/koodoo-report-inventory-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
        //   console.log('PATH', pathToWrite);
        //   RNFetchBlob.fs
        //     .writeFile(pathToWrite, csvData, 'utf8')
        //     .then(() => {
        //       console.log(`wrote file ${pathToWrite}`);
        //       // wrote file /storage/emulated/0/Download/data.csv
        //       Alert.alert(
        //         'Success',
        //         `Send to ${pathToWrite}`,
        //         [{ text: 'OK', onPress: () => { } }],
        //         { cancelable: false },
        //       );
        //     })
        //     .catch((error) => console.error(error));
        // }
        // var body = {
        //   data: stockList
        // }
        // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
        //   console.log("RESULT", result)
        //   if (result !== null) {
        //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/StockData.csv`;
        //     console.log("PATH", pathToWrite)
        //     RNFetchBlob.fs
        //       .writeFile(pathToWrite, result, 'utf8')
        //       .then(() => {
        //         console.log(`wrote file ${pathToWrite}`);
        //         // wrote file /storage/emulated/0/Download/data.csv
        //         Alert.alert(
        //           'Success',
        //           'The data had exported',
        //           [{ text: 'OK', onPress: () => { } }],
        //           { cancelable: false },
        //         );
        //       })
        //       .catch(error => console.error(error));
        //   }
        // });
    };

    const importFunc = () => {
        var body = {
            data: stockList,
        };
        // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
        //   console.log(result)
        // });

        Alert.alert(
            'Success',
            'The data has imported',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
        );
    };

    const getLowStock = () => {
        // ApiClient.GET(API.getLowStock + outletId).then((result) => {
        //   setState({ stockList: result });
        // });
    };

    // function here
    const showDateTimePicker = () => {
        setState({ isDateTimePickerVisible: true });
    };

    const hideDateTimePicker = () => {
        setState({ isDateTimePickerVisible: false });
    };

    const handleDatePicked = (date) => {
        setState({ date: date.toString() });
    };

    //When supplier name tap navigate to supplier screen and its specific edit supplier screen
    // const navigateToSupplier = (item) => {
    //   CommonStore.update(s => {
    //     s.selectedSupplierEdit = item;
    //   });
    //   //navigation.navigate('Supplier');
    //   //setGoToSupplier(true)
    //   //setLowStockAlert(false)
    //   <SupplierScreen/>

    // };

    //trash icon
    const trashIconTapped = () => {
        CommonStore.update((s) => {
            s.isLoading = true;
        });

        var outletItemIdList = inUsedOutletItems.map((item) => item.uniqueId);

        const body = {
            outletSupplyItemId: selectedOutletSupplyItem.uniqueId,

            outletItemIdList: outletItemIdList,
        };

        ApiClient.POST(API.removeOutletSupplyItem, body)
            .then((result) => {
                if (result && result.status === 'success') {
                    Alert.alert('Success', 'Inventory has been removed');
                } else {
                    Alert.alert('Error', 'Failed to remove the inventory');
                }

                CommonStore.update((s) => {
                    s.isLoading = false;
                });

                setDeleteModal(false);
            })
            .catch((err) => {
                console.log(err);

                Alert.alert('Error', 'Failed to remove the inventory');

                CommonStore.update((s) => {
                    s.isLoading = false;
                });

                setDeleteModal(false);
            });
    };

    useEffect(() => {
        var totalLowStock = 0;

        for (var i = 0; i < outletSupplyItems.length; i++) {
            console.log('Loop total outletsupply');
            if (
                outletSupplyItems[i].quantity <
                outletSupplyItems[i].stockWarningQuantity
            ) {
                totalLowStock += 1;
                console.log('Loop if quantity small than stockidealquantity');
            }
        }
        setTotalLowStockQuantity(totalLowStock);
    }, [
        totalLowStockQuantity,
        supplyItems,
        supplyItemsDict,
        outletSupplyItemsSkuDict,
        allOutlets,
    ]);

    const renderStockItem = ({ item }) => {
        return (
            <TouchableOpacity
                onPress={() => {
                    setProductUsingSupplyItem(true);
                    setLowStockAlert(false);
                    setAddPurchase(false);
                    setSelectedOutletSupplyItem(item);
                }}
                style={{
                    backgroundColor:
                        (inputStockIdealDict[item.uniqueId]
                            ? inputStockIdealDict[item.uniqueId]
                            : item.stockIdealQuantity) <
                            (inputStockWarningDict[item.uniqueId]
                                ? inputStockWarningDict[item.uniqueId]
                                : item.stockWarningQuantity)
                            ? '#ed5740'
                            : '#FFFFFF',
                    flexDirection: 'row',
                    paddingVertical: 20,
                    paddingHorizontal: 10,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    borderBottomColor: '#C4C4C4',
                    alignItems: 'center',
                }}>
                <Text
                    style={{
                        width: '14%',
                        color: Colors.primaryColor,
                        marginRight: 2,
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Regular',
                    }}>
                    {item.name}
                </Text>
                <Text
                    style={{
                        width: '12%',
                        marginRight: 2,
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Regular',
                    }}>
                    {item.skuMerchant || item.sku}
                </Text>
                <Text
                    style={{
                        width: '8%',
                        marginRight: 2,
                        color:
                            item.quantity < item.stockIdealQuantity &&
                                item.quantity > item.stockWarningQuantity
                                ? Colors.tabGold
                                : item.quantity < item.stockWarningQuantity
                                    ? Colors.tabRed
                                    : Colors.blackColor,
                        fontWeight: item.quantity < item.stockIdealQuantity ? '500' : null,
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Regular',
                    }}>
                    {item.quantity}
                </Text>

                <Text
                    style={{
                        width: '8.5%',
                        marginRight: 2,
                        color:
                            item.quantity < item.stockIdealQuantity &&
                                item.quantity > item.stockWarningQuantity
                                ? Colors.tabGold
                                : item.quantity < item.stockWarningQuantity
                                    ? Colors.tabRed
                                    : Colors.blackColor,
                        fontWeight: item.quantity < item.stockIdealQuantity ? '500' : null,
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Regular',
                    }}>
                    {item.unit}
                </Text>

                <View
                    style={{
                        width: '12%',
                        marginRight: 2,
                        //alignItems: 'center',
                    }}>
                    <TextInput
                        editable={true}
                        //editable={!loading}
                        underlineColorAndroid={Colors.whiteColor}
                        clearButtonMode="while-editing"
                        style={[
                            styles.textInput2,
                            {
                                fontSize: switchMerchant ? 10 : 14,
                                fontFamily: 'NunitoSans-Regular',
                                width: switchMerchant ? 60 : 70,
                            },
                        ]}
                        placeholder={item.stockIdealQuantity.toFixed(0)}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        onChangeText={(text) => {
                            // setState({ ideal: text.trim(), itemId: result.itemInventory.itemId });
                            setInputStockIdealDict({
                                ...inputStockIdealDict,
                                [item.uniqueId]: text,
                            });

                            // if (text < item.quantity) {
                            //   Alert.alert('Ideal Stock Quantity must be higher than In Stock Quantity');
                            // }
                            // else{
                            //   setInputStockIdealDict({
                            //     ...inputStockIdealDict,
                            //     [item.uniqueId]: text,
                            //   });
                            // }
                        }}
                        value={
                            inputStockIdealDict[item.uniqueId]
                                ? inputStockIdealDict[item.uniqueId]
                                : item.stockIdealQuantity.toFixed(0)
                        }
                        keyboardType={'number-pad'}
                    />
                </View>

                <View
                    style={{
                        width: '12%',
                        marginRight: switchMerchant ? 5 : 2,
                        //alignItems: 'center',
                        //marginLeft: 30,
                    }}>
                    <TextInput
                        editable={true}
                        // editable={!loading}
                        underlineColorAndroid={Colors.whiteColor}
                        clearButtonMode="while-editing"
                        style={[
                            styles.textInput2,
                            {
                                fontSize: switchMerchant ? 10 : 14,
                                fontFamily: 'NunitoSans-Regular',
                                width: switchMerchant ? 60 : 70,
                            },
                        ]}
                        placeholder={item.stockWarningQuantity.toFixed(0)}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        onChangeText={(text) => {
                            // setState({ ideal: text.trim(), itemId: result.itemInventory.itemId });
                            setInputStockWarningDict({
                                ...inputStockWarningDict,
                                [item.uniqueId]: text,
                            });
                        }}
                        value={
                            inputStockWarningDict[item.uniqueId]
                                ? inputStockWarningDict[item.uniqueId]
                                : item.stockWarningQuantity.toFixed(0)
                        }
                        keyboardType={'number-pad'}
                    />
                </View>
                <Text
                    style={{
                        width: '10.5%',
                        marginRight: 2,
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Regular',
                        //marginLeft: 40
                    }}>
                    {' '}
                    {skuOrderQuantityDict[item.sku] ? skuOrderQuantityDict[item.sku] : 0}
                </Text>
                {/* <Text style={{ width: '12%' }}>
          {skuEstimatedArrivalDateDict[item.sku]
            ? moment(skuEstimatedArrivalDateDict[item.sku]).format('DD/MM/YYYY')
            : 'N/A'}
        </Text> */}
                <View style={{ width: '18%', marginRight: 2 }}>
                    <TouchableOpacity
                        onPress={() => {
                            CommonStore.update(
                                (s) => {
                                    s.selectedSupplierEdit = suppliersDict[item.supplierId]
                                        ? suppliersDict[item.supplierId]
                                        : null;

                                    s.routeParams = {
                                        pageFrom: 'Inventory',
                                    };
                                },
                                () => {
                                    navigation.navigate('Supplier');
                                },
                            );
                        }}>
                        <Text
                            style={{
                                fontSize: switchMerchant ? 10 : 14,
                                fontFamily: 'NunitoSans-Regular',
                            }}>
                            {supplyItemsSkuDict[item.sku] &&
                                suppliersDict[supplyItemsSkuDict[item.sku].supplierId]
                                ? suppliersDict[supplyItemsSkuDict[item.sku].supplierId].name
                                : 'N/A'}
                        </Text>
                    </TouchableOpacity>
                </View>
                <TouchableOpacity
                    style={{ width: '4%', marginLeft: switchMerchant ? 1 : 5 }}
                    onPress={() => {
                        setSelectedOutletSupplyItem(item);
                        setDeleteModal(true);
                        // if (item.quantity > 0) {
                        //   Alert.alert(
                        //     'Caution',
                        //     `There are still stock remaining in inventory: ${item.quantity}`,

                        //     [{
                        //       text: 'Delete',
                        //       onPress: () => { trashIconTapped() }
                        //     },
                        //     {
                        //       text: 'Cancel',
                        //       onPress: () => { }
                        //     }
                        //     ]
                        //   )
                        // }
                        // else {
                        //   Alert.alert(
                        //     'Caution',
                        //     'Do you want to delete this?',
                        //     [{
                        //       text: 'Delete',
                        //       onPress: () => { trashIconTapped() }
                        //     },
                        //     {
                        //       text: 'Cancel',
                        //       onPress: () => { }
                        //     }
                        //     ]
                        //   )
                        // }
                        //trashIconTapped(),
                        console.log(item.quantity);
                        //console.log(outletSupplyItemsSkuDict[supplyItems[item].sku].quantity)
                    }}>
                    <Icon name="trash-2" size={switchMerchant ? 15 : 20} color={'red'} />
                </TouchableOpacity>
            </TouchableOpacity>
        );
    };

    const renderProductUsingInventory = ({ item, index }) => {
        return (
            <View
                style={{
                    backgroundColor: '#ffffff',
                    flexDirection: 'row',
                    paddingVertical: 10,
                    //paddingHorizontal: 20,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    borderBottomColor: '#c4c4c4',
                    alignItems: 'center',
                }}>
                <View>
                    <Text style={{ fontWeight: '500', fontSize: switchMerchant ? 10 : 14 }}>
                        {item.name}
                    </Text>
                </View>
            </View>
        );
    };

    const renderProductUsingInventory1 = ({ item, index }) => {
        return (
            <View
                style={{
                    backgroundColor: '#ffffff',
                    flexDirection: 'row',
                    paddingVertical: 10,
                    paddingHorizontal: 10,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    borderBottomColor: '#c4c4c4',
                    alignItems: 'center',
                }}>
                {item.image ? (
                    <AsyncImage
                        source={{ uri: item.image }}
                        item={item}
                        style={{
                            width: switchMerchant ? 45 : 50,
                            height: switchMerchant ? 45 : 50,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                            marginRight: 10,
                        }}
                        hideLoading={true}
                    />
                ) : (
                    <View
                        style={{
                            width: switchMerchant ? 45 : 50,
                            height: switchMerchant ? 45 : 50,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                            marginRight: 10,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: Colors.fieldtBgColor,
                        }}>
                        <Image
                            style={{ width: 35, height: 35 }}
                            source={require('../assets/image/dineinGrey.png')}
                        />
                    </View>
                )}
                <Text
                    style={{
                        fontWeight: '500',
                        width: '35%',
                        marginRight: 8,
                        fontSize: switchMerchant ? 10 : 14,
                    }}>
                    {item.name}
                </Text>
                <Text
                    style={{
                        fontWeight: '500',
                        width: '30%',
                        marginRight: 8,
                        fontSize: switchMerchant ? 10 : 14,
                    }}>
                    {item.skuMerchant || 'N/A'}
                </Text>
                <Text
                    style={{
                        fontWeight: '500',
                        width: '15%',
                        marginRight: 8,
                        fontSize: switchMerchant ? 10 : 14,
                    }}>
                    {outletCategoriesDict[item.categoryId]
                        ? outletCategoriesDict[item.categoryId].name
                        : 'N/A'}
                </Text>
            </View>
        );
    };

    const renderAddPO = () => {
        // console.log('poItems[index]');
        // console.log(poItems[index]);
        // console.log('supplyItemDropdownList');
        // console.log(supplyItemDropdownList);

        // var isValidToRender = false;

        // for (var i = 0; i < supplyItems.length; i++) {

        // }

        // becoz poitems don't store supplier id
        if (
            // poItems[index].supplierId === selectedSupplierId
            true
        ) {
            return (
                <>
                    {poItems.map((item, index) => {
                        return (
                            <View
                                style={{
                                    backgroundColor: '#ffffff',
                                    flexDirection: 'row',
                                    // paddingVertical: 20,
                                    paddingVertical: 5,
                                    paddingHorizontal: 20,
                                    // borderBottomWidth: StyleSheet.hairlineWidth,
                                    // borderBottomColor: '#c4c4c4',
                                    alignItems: 'center',
                                    zIndex: 10001 + poItems.length - index,
                                    // height: (Dimensions.get('window').width * 0.1) * 3,
                                }}>
                                <View
                                    style={{
                                        width: '28%',
                                        justifyContent: 'center',
                                        alignSelf: 'center',
                                    }}>
                                    {supplyItemDropdownList.length > 0 &&
                                        Object.keys(supplyItemsDict).length > 0 && (
                                            //     <DropDownPicker
                                            //     items={supplyItemDropdownList}
                                            //     searchable={true}
                                            //     searchablePlaceholder="Search"
                                            //     searchableError={() => <CText fontSize={16}>Not found</CText>}
                                            //     searchableStyle={{ fontSize: 18 }}
                                            //     defaultValue={poItems[index].supplyItemId ? poItems[index].supplyItemId : ''}
                                            //     containerStyle={{ height: 40 }}
                                            //     style={{ backgroundColor: '#fafafa' }}
                                            //     dropDownStyle={{ backgroundColor: '#fafafa' }}
                                            //     dropDownMaxHeight={300}
                                            //     labelStyle={{ fontSize: 20 }}
                                            //     itemStyle={{
                                            //         justifyContent: 'flex-start'
                                            //     }}
                                            // />
                                            <DropDownPicker
                                                containerStyle={{
                                                    height: switchMerchant ? 30 : 30,
                                                    zIndex: 2,
                                                }}
                                                arrowColor={'black'}
                                                arrowSize={20}
                                                arrowStyle={{
                                                    fontWeight: 'bold',
                                                    marginTop: switchMerchant ? 0 : -3,
                                                }}
                                                labelStyle={{
                                                    fontFamily: 'NunitoSans-Regular',
                                                    fontSize: switchMerchant ? 10 : 14,
                                                }}
                                                style={{
                                                    width: switchMerchant ? 90 : 150,
                                                    paddingVertical: 0,
                                                    backgroundColor: Colors.fieldtBgColor,
                                                    borderRadius: 10,
                                                    fontSize: switchMerchant ? 11 : 14,
                                                }}
                                                placeholderStyle={{ color: Colors.fieldtTxtColor }}
                                                items={supplyItemDropdownList}
                                                itemStyle={{
                                                    justifyContent: 'flex-start',
                                                    marginLeft: 5,
                                                    zIndex: 2,
                                                }}
                                                placeholder={'Supply Items'}
                                                zIndex={10000 + poItems.length - index}
                                                //customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                                searchable={true}
                                                searchableStyle={{
                                                    paddingHorizontal:
                                                        Dimensions.get('screen').width * 0.0079,
                                                }}
                                                defaultValue={
                                                    poItems[index].supplyItemId
                                                        ? poItems[index].supplyItemId
                                                        : ''
                                                }
                                                onChangeItem={(item) =>
                                                    setPoItems(
                                                        poItems.map((poItem, i) =>
                                                            i === index
                                                                ? {
                                                                    ...poItem,
                                                                    supplyItemId: item.value,
                                                                    skuMerchant:
                                                                        supplyItemsDict[item.value].skuMerchant,
                                                                    sku: supplyItemsDict[item.value].sku,
                                                                    quantity: outletSupplyItemsSkuDict[
                                                                        supplyItemsDict[item.value].sku
                                                                    ]
                                                                        ? outletSupplyItemsSkuDict[
                                                                            supplyItemsDict[item.value].sku
                                                                        ].quantity
                                                                        : 0,
                                                                    unit: supplyItemsDict[item.value].unit,
                                                                    // orderQuantity: 0,
                                                                    // receivedQuantity: 0,
                                                                    // price: supplyItemsDict[value].price,
                                                                    // totalPrice: 0,
                                                                    insertQuantity: 0,

                                                                    supplyItem: supplyItemsDict[item.value],
                                                                }
                                                                : poItem,
                                                        ),
                                                    )
                                                }
                                                dropDownMaxHeight={150}
                                                dropDownStyle={{
                                                    width: switchMerchant ? 90 : 150,
                                                    height: 150,
                                                    backgroundColor: Colors.fieldtBgColor,
                                                    borderRadius: 10,
                                                    borderWidth: 1,
                                                    textAlign: 'left',
                                                    zIndex: 2,
                                                }}
                                            />
                                        )}
                                    {/* <View style={{
              width: switchMerchant? '90%' : 180,
              //color: '#969696',
              height: switchMerchant? 35 : 38,
              borderColor: '#E5E5E5',
              borderWidth: 1,
              borderRadius: 5,
              backgroundColor: 'white',
              //alignItems: center,
              justifyContent: 'center',
              paddingLeft: 10,
              shadowOpacity: 0,
              shadowColor: '#000',
              shadowOffset: {
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 2,
            }}>
              <RNPickerSelect
                useNativeAndroidPickerStyle={false}
                //pickerProps={{ style: { height: 160, overflow: 'hidden',} }}
                style={{
                  inputIOS: {fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, paddingVertical: 5},
                  inputAndroid: {fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, paddingVertical: 5},
                  inputAndroidContainer: {
                    //backgroundColor: 'red',
                    width: '100%',
                  }
                }}
                items={supplyItemDropdownList}
                value={
                  poItems[index].supplyItemId ? poItems[index].supplyItemId : ''
                }
                onValueChange={(value) => {
                  setPoItems(
                    poItems.map((poItem, i) =>
                      i === index
                        ? {
                          ...poItem,
                          supplyItemId: value,
                          skuMerchant: supplyItemsDict[value].skuMerchant,
                          sku: supplyItemsDict[value].sku,
                          quantity: outletSupplyItemsSkuDict[
                            supplyItemsDict[value].sku
                          ]
                            ? outletSupplyItemsSkuDict[
                              supplyItemsDict[value].sku
                            ].quantity
                            : 0,
                          unit: supplyItemsDict[value].unit,
                          // orderQuantity: 0,
                          // receivedQuantity: 0,
                          // price: supplyItemsDict[value].price,
                          // totalPrice: 0,
                          insertQuantity: 0,

                          supplyItem: supplyItemsDict[value],
                        }
                        : poItem,
                    ),
                  );
                }}
              />
            </View> */}
                                </View>

                                <Text
                                    style={{
                                        width: '21%',
                                        color: '#8f8f8f',
                                        marginLeft: 10,
                                        fontFamily: 'NunitoSans-Regular',
                                        fontSize: switchMerchant ? 10 : 14,
                                    }}>
                                    {poItems[index].skuMerchant || poItems[index].sku}
                                </Text>
                                <Text
                                    style={{
                                        width: '12%',
                                        color: '#8f8f8f',
                                        fontFamily: 'NunitoSans-Regular',
                                        fontSize: switchMerchant ? 10 : 14,
                                    }}>
                                    {poItems[index].unit}
                                </Text>
                                <Text
                                    style={{
                                        width: '18%',
                                        color: '#8f8f8f',
                                        fontFamily: 'NunitoSans-Regular',
                                        fontSize: switchMerchant ? 10 : 14,
                                    }}>
                                    {poItems[index].quantity}
                                </Text>

                                <View
                                    style={{
                                        width: '16%',
                                        // marginLeft: 50,
                                        // backgroundColor: 'blue',
                                    }}>
                                    <TextInput
                                        style={{
                                            backgroundColor: Colors.fieldtBgColor,
                                            width: switchMerchant ? '90%' : 100,
                                            height: 35,
                                            borderRadius: 5,
                                            padding: 5,
                                            marginVertical: 5,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                            paddingLeft: 10,
                                            fontFamily: 'NunitoSans-Regular',
                                            fontSize: switchMerchant ? 10 : 14,
                                        }}
                                        placeholder={'50'}
                                        placeholderStyle={{
                                            fontFamily: 'NunitoSans-Regular',
                                            fontSize: switchMerchant ? 10 : 14,
                                        }}
                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                        keyboardType={'number-pad'}
                                        // placeholder={itemName}
                                        onChangeText={(text) => {
                                            // setState({ itemName: text });
                                            setPoItems(
                                                poItems.map((poItem, i) =>
                                                    i === index
                                                        ? {
                                                            ...poItem,
                                                            // orderQuantity: parseInt(text),
                                                            insertQuantity: parseInt(text),
                                                            // totalPrice: parseInt(text) * poItem.price,
                                                        }
                                                        : poItem,
                                                ),
                                            );
                                        }}
                                        value={poItems[index].insertQuantity}
                                    // ref={myTextInput}
                                    />
                                </View>

                                {/* <Text style={{ width: '18%', color: '#8f8f8f' }}>{`RM${poItems[index].price.toFixed(2)}`}</Text>
          <Text style={{ width: '10%', color: '#8f8f8f' }}>{`RM${poItems[index].totalPrice.toFixed(2)}`}</Text> */}
                                <TouchableOpacity
                                    style={{ marginLeft: 10 }}
                                    onPress={() => {
                                        setPoItems([
                                            ...poItems.slice(0, index),
                                            ...poItems.slice(index + 1),
                                        ]);
                                    }}>
                                    <Icon
                                        name="trash-2"
                                        size={switchMerchant ? 15 : 20}
                                        color="#eb3446"
                                    />
                                </TouchableOpacity>
                            </View>
                        );
                    })}
                </>
            );
        }
    };

    const insertInventory = () => {
        var body = {
            // poId: poId,
            poItems: poItems,
            // supplierId: selectedSupplierId,
            // status: poStatus,
            // outletId: selectedTargetOutletId,
            outletId: currOutletId,

            // outletName: allOutlets.find(outlet => outlet.uniqueId === currOutletId).name,

            merchantId: merchantId,
            // remarks: '',
        };

        console.log(body);

        ApiClient.POST(API.insertInventory, body).then((result) => {
            if (result && result.status === 'success') {
                Alert.alert(
                    'Success',
                    'Inventory has been inserted',
                    [
                        {
                            text: 'OK',
                            onPress: () => {
                                //props.navigation.goBack();
                                setLowStockAlert(true);
                            },
                        },
                    ],
                    { cancelable: false },
                );
            } else {
                Alert.alert('Error', 'Failed to insert inventory');
            }
        });
    };

    const email = () => {
        var body = {
            stockTransferId: 1,
            email: Email,
        };
        // ApiClient.POST(API.emailStockTransfer, body, false).then((result) => {

        //   if (result == true) {

        //     Alert.alert(
        //       'Success',
        //       'The email had sent',
        //       [
        //         { text: "OK", onPress: () => { } }
        //       ],
        //       { cancelable: false },
        //     );
        //   }
        // });
    };

    const settingLowStock = () => {
        const stockIdealList = Object.entries(inputStockIdealDict).map(
            ([key, value]) => ({ key: key, value: value }),
        );
        const stockWarningList = Object.entries(inputStockWarningDict).map(
            ([key, value]) => ({ key: key, value: value }),
        );

        CommonStore.update((s) => {
            s.isLoading = true;
        });

        var body = {
            stockIdealList: stockIdealList,
            stockWarningList: stockWarningList,
        };

        if (
            outletSupplyItems.find(
                (item) =>
                    (inputStockIdealDict[item.uniqueId]
                        ? inputStockIdealDict[item.uniqueId]
                        : item.stockIdealQuantity) <
                    (inputStockWarningDict[item.uniqueId]
                        ? inputStockWarningDict[item.uniqueId]
                        : item.stockWarningQuantity),
            )
        ) {
            Alert.alert(
                'Ideal Stock Quantity must be higher than Warning Stock Quantity',
            );
            CommonStore.update((s) => {
                s.isLoading = false;
            });
            return;
        }

        ApiClient.POST(API.updateStockIdealWarning, body).then((result) => {
            if (result && result.status === 'success') {
                Alert.alert(
                    'Success',
                    'Updated successfully',
                    [{ text: 'OK', onPress: () => { } }],
                    { cancelable: false },
                );
            } else {
                Alert.alert(
                    'Error',
                    'Operation failed',
                    [{ text: 'OK', onPress: () => { } }],
                    { cancelable: false },
                );
            }

            CommonStore.update((s) => {
                s.isLoading = false;
            });
        });
    };

    const convertDataToExcelFormat = () => {
        var excelData = [];

        for (var i = 0; i < outletSupplyItems.length; i++) {
            var excelRow = {
                'Product Name': outletSupplyItems[i].name
                    ? outletSupplyItems[i].name
                    : 'N/A',
                SKU: outletSupplyItems[i].skuMerchant
                    ? outletSupplyItems[i].skuMerchant
                    : 'N/A',
                'In Stock': parseFloat(outletSupplyItems[i].quantity).toFixed(2),
                Unit: outletSupplyItems[i].unit ? outletSupplyItems[i].unit : 'N/A',
                'Ideal Stock Level': parseFloat(
                    outletSupplyItems[i].stockIdealQuantity,
                ).toFixed(2),
                'Warning Stock': parseFloat(
                    outletSupplyItems[i].stockWarningQuantity,
                ).toFixed(2),
                'Ordered Qty': skuOrderQuantityDict[outletSupplyItems[i].sku]
                    ? skuOrderQuantityDict[outletSupplyItems[i].sku]
                    : 0,
                Supplier:
                    supplyItemsSkuDict[outletSupplyItems[i].sku] &&
                        suppliersDict[supplyItemsSkuDict[outletSupplyItems[i].sku].supplierId]
                        ? suppliersDict[
                            supplyItemsSkuDict[outletSupplyItems[i].sku].supplierId
                        ].name
                        : 'N/A',
            };

            excelData.push(excelRow);
        }

        console.log('excelData');
        console.log(excelData);

        return excelData;
    };

    // function end

    return (
        <View
            style={[
                styles.container,
                {
                    height: windowHeight,
                    width: windowWidth,
                },
            ]}>
            <View style={{ flex: 0.8 }}>
                <SideBar navigation={props.navigation} selectedTab={3} expandInventory={true} />
            </View>
            <View style={{ height: windowHeight, flex: 9 }}>
                <ScrollView scrollEnabled={switchMerchant}>
                    <View
                        style={[
                            styles.content,
                            {
                                // shadowColor: '#000',
                                // shadowOffset: {
                                //   width: 0,
                                //   height: 2,
                                // },
                                // shadowOpacity: 0.22,
                                // shadowRadius: 3.22,
                                // elevation: 3,
                                // borderRadius: 5,
                                //top: Platform.OS === 'ios' ? -keyboardHeight * 0.5 : 0,
                            },
                        ]}>
                        <Modal
                            style={
                                {
                                    // flex: 1
                                }
                            }
                            supportedOrientations={['portrait', 'landscape']}
                            visible={exportModalVisibility}
                            transparent={true}
                            animationType={'fade'}>
                            <View
                                style={{
                                    flex: 1,
                                    backgroundColor: Colors.modalBgColor,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <View
                                    style={{
                                        height: Dimensions.get('screen').width * 0.3,
                                        width: Dimensions.get('screen').width * 0.4,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 12,
                                        padding: Dimensions.get('screen').width * 0.03,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <TouchableOpacity
                                        disabled={isLoading}
                                        style={{
                                            position: 'absolute',
                                            right: Dimensions.get('screen').width * 0.02,
                                            top: Dimensions.get('screen').width * 0.02,

                                            elevation: 1000,
                                            zIndex: 1000,
                                        }}
                                        onPress={() => {
                                            setExportModalVisibility(false);
                                        }}>
                                        <AntDesign
                                            name="closecircle"
                                            size={switchMerchant ? 15 : 25}
                                            color={Colors.fieldtTxtColor}
                                        />
                                    </TouchableOpacity>
                                    <View
                                        style={{
                                            alignItems: 'center',
                                            top: '20%',
                                            position: 'absolute',
                                        }}>
                                        <Text
                                            style={{
                                                fontFamily: 'NunitoSans-Bold',
                                                textAlign: 'center',
                                                fontSize: switchMerchant ? 18 : 20,
                                            }}>
                                            Download Report
                                        </Text>
                                    </View>
                                    <View style={{ top: switchMerchant ? '14%' : '10%' }}>
                                        <Text
                                            style={{
                                                fontSize: switchMerchant ? 10 : 16,
                                                fontFamily: 'NunitoSans-Bold',
                                            }}>
                                            Email Address:
                                        </Text>
                                        <TextInput
                                            style={{
                                                backgroundColor: Colors.fieldtBgColor,
                                                width: switchMerchant ? 240 : 370,
                                                height: switchMerchant ? 35 : 50,
                                                borderRadius: 5,
                                                padding: 5,
                                                marginVertical: 5,
                                                borderWidth: 1,
                                                borderColor: '#E5E5E5',
                                                paddingLeft: 10,
                                                fontSize: switchMerchant ? 10 : 16,
                                            }}
                                            placeholderStyle={{ padding: 5 }}
                                            placeholder="Enter Your Email"
                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                            onChangeText={(text) => {
                                                setExportEmail(text);
                                            }}
                                            value={exportEmail}
                                        />
                                        <Text
                                            style={{
                                                fontSize: switchMerchant ? 10 : 16,
                                                fontFamily: 'NunitoSans-Bold',
                                                marginTop: 15,
                                            }}>
                                            Send As:
                                        </Text>

                                        <View
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                //top: '10%',
                                                flexDirection: 'row',
                                                marginTop: 15,
                                            }}>
                                            <TouchableOpacity
                                                disabled={isLoading}
                                                style={{
                                                    justifyContent: 'center',
                                                    flexDirection: 'row',
                                                    borderWidth: 1,
                                                    borderColor: Colors.primaryColor,
                                                    backgroundColor: '#4E9F7D',
                                                    borderRadius: 5,
                                                    width: switchMerchant ? 100 : 120,
                                                    paddingHorizontal: 10,
                                                    height: switchMerchant ? 35 : 40,
                                                    alignItems: 'center',
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.22,
                                                    shadowRadius: 3.22,
                                                    elevation: 1,
                                                    zIndex: -1,
                                                }}
                                                onPress={() => {
                                                    if (exportEmail.length > 0) {
                                                        CommonStore.update((s) => {
                                                            s.isLoading = true;
                                                        });
                                                        setIsLoadingExcel(true);
                                                        const excelData = convertDataToExcelFormat();

                                                        generateEmailReport(
                                                            EMAIL_REPORT_TYPE.EXCEL,
                                                            excelData,
                                                            'KooDoo Inventory Report',
                                                            'KooDoo Inventory Report.xlsx',
                                                            `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                                                            exportEmail,
                                                            'KooDoo Inventory Report',
                                                            'KooDoo Inventory Report',
                                                            () => {
                                                                CommonStore.update((s) => {
                                                                    s.isLoading = false;
                                                                });
                                                                setIsLoadingExcel(false);

                                                                Alert.alert(
                                                                    'Success',
                                                                    'Report will be sent to the email address soon.',
                                                                );

                                                                setExportModalVisibility(false);
                                                            },
                                                        );
                                                    } else {
                                                        Alert.alert('Info', 'Invalid email address.');
                                                    }
                                                }}>
                                                {isLoadingExcel ? (
                                                    <ActivityIndicator
                                                        size={'small'}
                                                        color={Colors.whiteColor}
                                                    />
                                                ) : (
                                                    <Text
                                                        style={{
                                                            color: Colors.whiteColor,
                                                            //marginLeft: 5,
                                                            fontSize: switchMerchant ? 10 : 16,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }}>
                                                        EXCEL
                                                    </Text>
                                                )}
                                            </TouchableOpacity>

                                            <TouchableOpacity
                                                disabled={isLoading}
                                                style={{
                                                    justifyContent: 'center',
                                                    flexDirection: 'row',
                                                    borderWidth: 1,
                                                    borderColor: Colors.primaryColor,
                                                    backgroundColor: '#4E9F7D',
                                                    borderRadius: 5,
                                                    width: switchMerchant ? 100 : 120,
                                                    paddingHorizontal: 10,
                                                    height: switchMerchant ? 35 : 40,
                                                    alignItems: 'center',
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.22,
                                                    shadowRadius: 3.22,
                                                    elevation: 1,
                                                    zIndex: -1,
                                                    marginLeft: 15,
                                                }}
                                                onPress={() => {
                                                    if (exportEmail.length > 0) {
                                                        CommonStore.update((s) => {
                                                            s.isLoading = true;
                                                        });
                                                        setIsLoadingCsv(true);
                                                        const csvData =
                                                            convertArrayToCSV(outletSupplyItems);

                                                        generateEmailReport(
                                                            EMAIL_REPORT_TYPE.CSV,
                                                            csvData,
                                                            'KooDoo Inventory Report',
                                                            'KooDoo Inventory Report.csv',
                                                            `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                                                            exportEmail,
                                                            'KooDoo Inventory Report',
                                                            'KooDoo Inventory Report',
                                                            () => {
                                                                CommonStore.update((s) => {
                                                                    s.isLoading = false;
                                                                });
                                                                setIsLoadingCsv(false);
                                                                Alert.alert(
                                                                    'Success',
                                                                    'Report will be sent to the email address soon.',
                                                                );

                                                                setExportModalVisibility(false);
                                                            },
                                                        );
                                                    } else {
                                                        Alert.alert('Info', 'Invalid email address.');
                                                    }
                                                }}>
                                                {isLoadingCsv ? (
                                                    <ActivityIndicator
                                                        size={'small'}
                                                        color={Colors.whiteColor}
                                                    />
                                                ) : (
                                                    <Text
                                                        style={{
                                                            color: Colors.whiteColor,
                                                            //marginLeft: 5,
                                                            fontSize: switchMerchant ? 10 : 16,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }}>
                                                        CSV
                                                    </Text>
                                                )}
                                            </TouchableOpacity>

                                            {/* <TouchableOpacity
                            style={[styles.modalSaveButton, {
                                zIndex: -1
                            }]}
                            onPress={() => { downloadPDF() }}>
                            <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>PDF</Text>
                        </TouchableOpacity> */}
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Modal>

                        <Modal
                            style={
                                {
                                    // flex: 1
                                }
                            }
                            supportedOrientations={['portrait', 'landscape']}
                            visible={importBatchModal}
                            transparent={true}
                            animationType={'fade'}>
                            <View style={styles.modalContainer}>
                                <View style={[styles.modalView, {}]}>
                                    <TouchableOpacity
                                        style={styles.closeButton}
                                        onPress={() => {
                                            // setState({ changeTable: false });
                                            setImportBatchModal(false);
                                        }}>
                                        <AntDesign
                                            name="closecircle"
                                            size={switchMerchant ? 15 : 25}
                                            color={Colors.fieldtTxtColor}
                                        />
                                    </TouchableOpacity>
                                    <View style={styles.modalTitle}>
                                        <Text
                                            style={[
                                                styles.modalTitleText,
                                                { fontSize: switchMerchant ? 18 : 20 },
                                            ]}>
                                            Upload Options
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            alignItems: 'center',
                                            top: switchMerchant ? '20%' : '10%',
                                        }}>
                                        <TouchableOpacity
                                            style={{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 1,
                                                borderColor: Colors.primaryColor,
                                                backgroundColor: '#4E9F7D',
                                                borderRadius: 5,
                                                width: switchMerchant ? 180 : 240,
                                                paddingHorizontal: 10,
                                                height: switchMerchant ? 35 : 40,
                                                alignItems: 'center',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                                marginBottom: 10,
                                            }}
                                            onPress={() => {
                                                //importTemplateData(); //need fix
                                            }}
                                            disabled={isLoading}>
                                            <Text
                                                style={{
                                                    color: Colors.whiteColor,
                                                    //marginLeft: 5,
                                                    fontSize: switchMerchant ? 10 : 16,
                                                    fontFamily: 'NunitoSans-Bold',
                                                }}>
                                                {isLoading ? 'LOADING...' : 'UPLOAD BATCH TEMPLATE'}
                                            </Text>

                                            {isLoading ? (
                                                <ActivityIndicator
                                                    style={{
                                                        marginLeft: 5,
                                                    }}
                                                    color={Colors.whiteColor}
                                                    size={'small'}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </TouchableOpacity>

                                        <TouchableOpacity
                                            style={{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 1,
                                                borderColor: Colors.primaryColor,
                                                backgroundColor: '#4E9F7D',
                                                borderRadius: 5,
                                                width: switchMerchant ? 180 : 240,
                                                paddingHorizontal: 10,
                                                height: switchMerchant ? 35 : 40,
                                                alignItems: 'center',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                            }}
                                            onPress={() => {
                                                exportTemplate();
                                            }}>
                                            <Text
                                                style={{
                                                    color: Colors.whiteColor,
                                                    //marginLeft: 5,
                                                    fontSize: switchMerchant ? 10 : 16,
                                                    fontFamily: 'NunitoSans-Bold',
                                                }}>
                                                EXPORT TEMPLATE
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                        </Modal>

                        <Modal
                            style={
                                {
                                    // flex: 1
                                }
                            }
                            supportedOrientations={['portrait', 'landscape']}
                            visible={importModal}
                            transparent={true}
                            animationType={'fade'}>
                            <View style={styles.modalContainer}>
                                <View
                                    style={[
                                        styles.modalViewImport,
                                        {
                                        },
                                    ]}>
                                    <TouchableOpacity
                                        style={styles.closeButton}
                                        onPress={() => {
                                            // setState({ changeTable: false });
                                            setImportBatchModal(false);
                                        }}>
                                        <AntDesign
                                            name="closecircle"
                                            size={25}
                                            color={Colors.fieldtTxtColor}
                                        />
                                    </TouchableOpacity>
                                    <View style={{ padding: 10, margin: 30 }}>
                                        <View
                                            style={[
                                                styles.modalTitle1,
                                                { justifyContent: 'center', alignItems: 'center' },
                                            ]}>
                                            <Text
                                                style={[
                                                    styles.modalTitleText1,
                                                    { fontSize: 16, fontWeight: '500' },
                                                ]}>
                                                Imported List
                                            </Text>
                                        </View>
                                        {/* <View style={{
                    heigth: 70,
                    marginVertical: 10,
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    height: '80%'
                  }}>
                  <Table borderStyle={{ borderWidth: 1 }}>
                    <Row data={TableData.tableHead} flexArr={[1, 2, 1, 1]} style={{}}/>
                    <TableWrapper style={{}}>
                    <Col data={TableData.tableTitle} style={{flex: 1}} heightArr={[28, 28, 28, 28]} textStyle={{}}/>
                    <Rows data={TableData.tableData} flexArr={[1, 2, 1, 1]} style={{height: 28}} textStyle={{textAlign: 'center'}}/>
                    </TableWrapper>
                  </Table>
                  </View> */}
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}>
                                            <View
                                                style={{
                                                    backgroundColor: Colors.primaryColor,
                                                    width: 150,
                                                    height: 40,
                                                    marginVertical: 15,
                                                    borderRadius: 5,
                                                    alignSelf: 'center',
                                                }}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        importSelectFile();
                                                    }}>
                                                    <Text
                                                        style={{
                                                            color: Colors.whiteColor,
                                                            alignSelf: 'center',
                                                            marginVertical: 10,
                                                        }}>
                                                        IMPORT
                                                    </Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ flexDirection: 'row' }}>
                                                <View
                                                    style={{
                                                        backgroundColor: Colors.whiteColor,
                                                        width: 150,
                                                        height: 40,
                                                        marginVertical: 15,
                                                        borderRadius: 5,
                                                        alignSelf: 'center',
                                                    }}>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            //setImportTemplate(false);  //need fix
                                                        }}>
                                                        <Text
                                                            style={{
                                                                color: Colors.primaryColor,
                                                                alignSelf: 'center',
                                                                marginVertical: 10,
                                                            }}>
                                                            CANCEL
                                                        </Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View
                                                    style={{
                                                        backgroundColor: Colors.primaryColor,
                                                        width: 150,
                                                        height: 40,
                                                        marginVertical: 15,
                                                        borderRadius: 5,
                                                        alignSelf: 'center',
                                                    }}>
                                                    <TouchableOpacity onPress={() => { }}>
                                                        <Text
                                                            style={{
                                                                color: Colors.whiteColor,
                                                                alignSelf: 'center',
                                                                marginVertical: 10,
                                                            }}>
                                                            SAVE
                                                        </Text>
                                                    </TouchableOpacity>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Modal>

                        {/* **************Delete Modal Start**************** */}
                        <Modal
                            style={
                                {
                                    // flex: 1
                                }
                            }
                            supportedOrientations={['portrait', 'landscape']}
                            visible={deleteModal}
                            transparent={true}
                            animationType={'fade'}>
                            <View style={styles.modalContainer1}>
                                <View
                                    style={[
                                        styles.modalView1,
                                        {
                                            height: switchMerchant
                                                ? Dimensions.get('screen').height * 0.73
                                                : Dimensions.get('screen').height * 0.55,
                                        },
                                    ]}>
                                    <TouchableOpacity
                                        style={styles.closeButton}
                                        onPress={() => {
                                            // setState({ changeTable: false });
                                            setDeleteModal(false);
                                        }}>
                                        <AntDesign
                                            name="closecircle"
                                            size={switchMerchant ? 15 : 25}
                                            color={Colors.fieldtTxtColor}
                                        />
                                    </TouchableOpacity>
                                    <View
                                        style={
                                            {
                                                // borderBottomWidth: 1, borderBottomColor: '#E5E5E5', width: '100%',
                                            }
                                        }>
                                        <Text
                                            style={[
                                                styles.modalTitleText,
                                                { fontSize: switchMerchant ? 18 : 20 },
                                            ]}>
                                            Inventory
                                        </Text>
                                    </View>
                                    <View style={{ marginVertical: 5, flexDirection: 'row' }}>
                                        <Text style={{ fontSize: switchMerchant ? 13 : 14 }}>
                                            {`Remaining Stock of ${selectedOutletSupplyItem.name}: `}
                                        </Text>
                                        <Text
                                            style={{
                                                fontWeight: '600',
                                                marginLeft: 3,
                                                fontSize: switchMerchant ? 13 : 14,
                                            }}>
                                            {selectedOutletSupplyItem.quantity}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            //alignItems: 'center',
                                            //top: '10%',
                                            // height: 300,
                                            // width: 450,
                                            height: switchMerchant
                                                ? Dimensions.get('screen').height * 0.34
                                                : Dimensions.get('screen').height * 0.38,
                                            width: Dimensions.get('screen').width * 0.4,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                            borderRadius: 5,
                                            padding: 10,
                                            marginBottom: 20,
                                            justifyContent:
                                                inUsedOutletItems.length < 1 ? 'center' : null,
                                        }}>
                                        {inUsedOutletItems.length > 0 ? (
                                            <FlatList
                                                nestedScrollEnabled={true}
                                                data={inUsedOutletItems}
                                                renderItem={renderProductUsingInventory}
                                                keyExtractor={(item, index) => String(index)}
                                                style={{}}
                                            />
                                        ) : (
                                            <View style={{ alignItems: 'center' }}>
                                                <Text
                                                    style={{
                                                        fontWeight: '500',
                                                        color: Colors.descriptionColor,
                                                        fontSize: switchMerchant ? 12 : 14,
                                                    }}>
                                                    No Product Link to Current Item
                                                </Text>
                                            </View>
                                        )}
                                    </View>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            justifyContent: 'space-evenly',
                                            width: '100%',
                                        }}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                trashIconTapped();
                                            }}
                                            style={{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 1,
                                                borderColor: Colors.tabRed,
                                                backgroundColor: Colors.tabRed,
                                                borderRadius: 5,
                                                width: 120,
                                                paddingHorizontal: 10,
                                                height: switchMerchant ? 35 : 40,
                                                alignItems: 'center',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                            }}>
                                            {isLoading ? (
                                                <Text
                                                    style={{
                                                        color: Colors.whiteColor,
                                                        //marginLeft: 5,
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        fontFamily: 'NunitoSans-Bold',
                                                    }}>
                                                    {'LOADING...'}
                                                </Text>
                                            ) : (
                                                <Text
                                                    style={{
                                                        color: Colors.whiteColor,
                                                        //marginLeft: 5,
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        fontFamily: 'NunitoSans-Bold',
                                                    }}>
                                                    {'DELETE'}
                                                </Text>
                                            )}

                                            {/* {!isLoading ?
                      <ActivityIndicator style={{
                        // marginLeft: 5,
                        // backgroundColor: 'blue'
                        zIndex: 100,
                      }} color={Colors.white} size={'large'} />
                      : <></>
                    } */}
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                            onPress={() => {
                                                setDeleteModal(false);
                                            }}
                                            style={{
                                                borderColor: '#E5E5E5',
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                width: 120,
                                                height: switchMerchant ? 35 : 40,
                                                paddingHorizontal: 10,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                            <Text
                                                style={{
                                                    //color: Colors.whiteColor,
                                                    //marginLeft: 5,
                                                    fontSize: switchMerchant ? 10 : 16,
                                                    fontFamily: 'NunitoSans-Bold',
                                                }}>
                                                CANCEL
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </View>
                        </Modal>
                        {/* **************Delete Modal End****************** */}

                        {lowStockAlert ? (
                            <View
                                style={{
                                    flexDirection: 'row',
                                    width: Dimensions.get('screen').width * 0.87,
                                    alignSelf: 'center',
                                    justifyContent: 'space-between',
                                    zIndex: 100,
                                }}>
                                <View
                                    style={{
                                        alignItems: 'flex-start',
                                        flexDirection: 'column',
                                        marginBottom: -5,
                                        alignContent: 'center',
                                        alignSelf: 'center',
                                    }}>
                                    <Text
                                        style={{
                                            fontSize: switchMerchant ? 20 : 26,
                                            fontFamily: 'NunitoSans-Bold',
                                        }}>
                                        {outletSupplyItems.length} Inventory
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        alignItems: 'flex-end',
                                        flexDirection: 'row',
                                        paddingTop: '0.5%',
                                    }}>
                                    <TouchableOpacity
                                        style={{
                                            justifyContent: 'center',
                                            flexDirection: 'row',
                                            borderWidth: 1,
                                            borderColor: Colors.primaryColor,
                                            backgroundColor: '#4E9F7D',
                                            borderRadius: 5,
                                            //width: 160,
                                            paddingHorizontal: 10,
                                            height: switchMerchant ? 35 : 40,
                                            alignItems: 'center',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 1,
                                            //zIndex: -1,
                                        }}
                                        onPress={() => {
                                            setExportModalVisibility(true);
                                        }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <Icon
                                                name="download"
                                                size={switchMerchant ? 10 : 20}
                                                color={Colors.whiteColor}
                                            />
                                            <Text
                                                style={{
                                                    color: Colors.whiteColor,
                                                    marginLeft: 5,
                                                    fontSize: switchMerchant ? 10 : 16,
                                                    fontFamily: 'NunitoSans-Bold',
                                                }}>
                                                DOWNLOAD
                                            </Text>
                                        </View>
                                    </TouchableOpacity>

                                    <TouchableOpacity
                                        style={{
                                            justifyContent: 'center',
                                            flexDirection: 'row',
                                            borderWidth: 1,
                                            borderColor: Colors.primaryColor,
                                            backgroundColor: '#4E9F7D',
                                            borderRadius: 5,
                                            //width: 160,
                                            paddingHorizontal: 10,
                                            height: switchMerchant ? 35 : 40,
                                            alignItems: 'center',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 1,
                                            //zIndex: -1,
                                            marginHorizontal: 15,
                                        }}
                                        onPress={() => {
                                            setImportBatchModal(true);
                                        }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            <Icon
                                                name="upload"
                                                size={switchMerchant ? 10 : 20}
                                                color={Colors.whiteColor}
                                            />
                                            <Text
                                                style={{
                                                    color: Colors.whiteColor,
                                                    marginLeft: 5,
                                                    fontSize: switchMerchant ? 10 : 16,
                                                    fontFamily: 'NunitoSans-Bold',
                                                }}>
                                                BATCH UPLOAD
                                            </Text>
                                        </View>
                                    </TouchableOpacity>

                                    <TouchableOpacity
                                        style={{
                                            justifyContent: 'center',
                                            flexDirection: 'row',
                                            borderWidth: 1,
                                            borderColor: Colors.primaryColor,
                                            backgroundColor: '#4E9F7D',
                                            borderRadius: 5,
                                            //width: 160,
                                            paddingHorizontal: 10,
                                            height: switchMerchant ? 35 : 40,
                                            alignItems: 'center',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 1,
                                            //zIndex: -1,
                                        }}
                                        onPress={() => {
                                            setLowStockAlert(false);
                                            setAddPurchase(true);
                                        }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                            {/* <Icon name="upload" size={20} color={Colors.primaryColor} /> */}
                                            <AntDesign
                                                name="pluscircle"
                                                size={switchMerchant ? 10 : 20}
                                                color={Colors.whiteColor}
                                            />
                                            <Text
                                                style={{
                                                    color: Colors.whiteColor,
                                                    marginLeft: 5,
                                                    fontSize: switchMerchant ? 10 : 16,
                                                    fontFamily: 'NunitoSans-Bold',
                                                }}>
                                                INVENTORY
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        ) : null}

                        {lowStockAlert ? (
                            // <KeyboardAvoidingView behavior='position'>
                            <View
                                style={{
                                    width: Dimensions.get('screen').width * 0.87,
                                    alignSelf: 'center',
                                }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        //backgroundColor: '#ffffff',
                                        //justifyContent: 'center',
                                        //marginVertical: 10,
                                        width: Dimensions.get('screen').width * 0.87,
                                        justifyContent: 'space-between',
                                        zIndex: -1,
                                    }}>
                                    <View
                                        style={{
                                            alignItems: 'flex-start',
                                            flexDirection: 'column',
                                            marginBottom: -5,
                                        }}>
                                        <Text
                                            style={{
                                                fontSize: switchMerchant ? 20 : 26,
                                                fontFamily: 'NunitoSans-Bold',
                                            }}>
                                            {suppliers.length} Supply
                                        </Text>

                                        <Text
                                            style={{
                                                fontSize: switchMerchant ? 20 : 26,
                                                fontFamily: 'NunitoSans-Bold',
                                                color: Colors.tabRed,
                                            }}>
                                            {totalLowStockQuantity > 1
                                                ? `${totalLowStockQuantity} Low Stock`
                                                : ' '}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            width: switchMerchant ? 200 : 250,
                                            height: switchMerchant ? 35 : 40,
                                            backgroundColor: 'white',
                                            borderRadius: 5,
                                            flexDirection: 'row',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            alignSelf: 'flex-end',

                                            shadowColor: '#000',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 3,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                        }}>
                                        <Icon
                                            name="search"
                                            size={switchMerchant ? 13 : 18}
                                            color={Colors.primaryColor}
                                            style={{ marginLeft: 15 }}
                                        />
                                        <TextInput
                                            editable={!loading}
                                            underlineColorAndroid={Colors.whiteColor}
                                            style={{
                                                width: switchMerchant ? 180 : 220,
                                                fontSize: switchMerchant ? 10 : 15,
                                                fontFamily: 'NunitoSans-Regular',
                                                paddingLeft: 5,
                                                height: 45,
                                            }}
                                            clearButtonMode="while-editing"
                                            placeholder=" Search"
                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                            onChangeText={(text) => {
                                                setSearch(text.trim());
                                            }}
                                            value={search}
                                        />
                                    </View>
                                </View>
                                <View
                                    style={
                                        {
                                            //backgroundColor: 'red'
                                        }
                                    }>
                                    <View
                                        style={{
                                            backgroundColor: Colors.whiteColor,
                                            width: Dimensions.get('screen').width * 0.87,
                                            height: Dimensions.get('screen').height * 0.68,
                                            marginTop: 20,
                                            marginHorizontal: 30,
                                            marginBottom: 30,
                                            alignSelf: 'center',
                                            borderRadius: 5,
                                            shadowOpacity: 0,
                                            shadowColor: '#000',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 3,
                                            //borderRadius: 10,
                                        }}>
                                        <View
                                            style={{
                                                borderTopLeftRadius: 5,
                                                borderTopRightRadius: 5,
                                                backgroundColor: '#ffffff',
                                                flexDirection: 'row',
                                                paddingVertical: 15,
                                                paddingHorizontal: 10,
                                                //paddingLeft: 20,
                                                //marginTop: 1,
                                                borderRadius: 5,
                                                borderBottomWidth: StyleSheet.hairlineWidth,
                                            }}>
                                            <Text
                                                style={{
                                                    width: '14%',
                                                    alignSelf: 'center',
                                                    marginRight: 2,
                                                    fontSize: switchMerchant ? 10 : 14,
                                                    fontFamily: 'NunitoSans-Bold',
                                                }}>
                                                Product Name
                                            </Text>
                                            <Text
                                                style={{
                                                    width: '12%',
                                                    alignSelf: 'center',
                                                    fontSize: switchMerchant ? 10 : 14,
                                                    fontFamily: 'NunitoSans-Bold',
                                                    marginRight: 2,
                                                }}>
                                                SKU
                                            </Text>
                                            <Text
                                                style={{
                                                    width: '8%',
                                                    alignSelf: 'center',
                                                    fontSize: switchMerchant ? 10 : 14,
                                                    marginRight: 2,
                                                }}>
                                                In Stock
                                            </Text>
                                            <Text
                                                style={{
                                                    width: '8.5%',
                                                    alignSelf: 'center',
                                                    fontSize: switchMerchant ? 10 : 14,
                                                    fontFamily: 'NunitoSans-Bold',
                                                    marginRight: 2,
                                                }}>
                                                Unit
                                            </Text>
                                            <Text
                                                style={{
                                                    width: '12%',
                                                    alignSelf: 'center',
                                                    fontSize: switchMerchant ? 10 : 14,
                                                    fontFamily: 'NunitoSans-Bold',
                                                    marginRight: 2,
                                                }}>
                                                {''}
                                                {/* Ideal Stock Level{' '} */}
                                                Ideal Stock{' '}
                                            </Text>

                                            <Text
                                                style={{
                                                    width: '12%',
                                                    alignSelf: 'center',
                                                    fontSize: switchMerchant ? 10 : 14,
                                                    fontFamily: 'NunitoSans-Bold',
                                                    marginRight: switchMerchant ? 5 : 2,
                                                }}>
                                                {''}
                                                Warning Stock{' '}
                                            </Text>
                                            <Text
                                                style={{
                                                    width: '10.5%',
                                                    alignSelf: 'center',
                                                    fontSize: switchMerchant ? 10 : 14,
                                                    fontFamily: 'NunitoSans-Bold',
                                                    marginRight: 2,
                                                }}>
                                                Ordered Qty
                                            </Text>
                                            {/* <Text
                      style={{ width: '12%', alignSelf: 'center',  marginRight: 2 }}>
                      {''}
                      ETA{' '}
                    </Text> */}
                                            <Text
                                                style={{
                                                    width: '18%',
                                                    alignSelf: 'center',
                                                    fontSize: switchMerchant ? 10 : 14,
                                                    fontFamily: 'NunitoSans-Bold',
                                                    marginRight: 2,
                                                }}>
                                                {''}
                                                Supplier
                                            </Text>
                                        </View>
                                        {/* <KeyboardAvoidingView behavior='position'> */}
                                        <ScrollView
                                            showsVerticalScrollIndicator={false}
                                            nestedScrollEnabled={true}>
                                            {/* <KeyboardAvoidingView behavior='position'> */}
                                            <FlatList
                                                nestedScrollEnabled={true}
                                                data={outletSupplyItems.filter((item) => {
                                                    if (search !== '') {
                                                        return item.name
                                                            .toLowerCase()
                                                            .includes(search.toLowerCase());
                                                    } else {
                                                        return true;
                                                    }
                                                })}
                                                extraData={outletSupplyItems.filter((item) => {
                                                    if (search !== '') {
                                                        return item.name
                                                            .toLowerCase()
                                                            .includes(search.toLowerCase());
                                                    } else {
                                                        return true;
                                                    }
                                                })}
                                                renderItem={renderStockItem}
                                                keyExtractor={(item, index) => String(index)}
                                            />
                                            {/* </KeyboardAvoidingView> */}
                                            {/* </KeyboardAvoidingView> */}
                                        </ScrollView>

                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                //backgroundColor: 'red',
                                                marginVertical: 5,
                                                marginTop: 5,
                                                //alignItems: 'baseline',
                                                justifyContent: 'center',
                                            }}>
                                            <TouchableOpacity
                                                style={{
                                                    justifyContent: 'center',
                                                    flexDirection: 'row',
                                                    borderWidth: 1,
                                                    borderColor: Colors.primaryColor,
                                                    backgroundColor: '#4E9F7D',
                                                    borderRadius: 5,
                                                    width: 120,
                                                    paddingHorizontal: 10,
                                                    height: switchMerchant ? 35 : 40,
                                                    alignItems: 'center',
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.22,
                                                    shadowRadius: 3.22,
                                                    elevation: 1,
                                                    zIndex: -1,
                                                }}
                                                onPress={() => {
                                                    settingLowStock();
                                                }}>
                                                {isLoading ? (
                                                    <ActivityIndicator
                                                        size={'small'}
                                                        color={Colors.whiteColor}
                                                    />
                                                ) : (
                                                    <Text
                                                        style={{
                                                            color: Colors.whiteColor,
                                                            //marginLeft: 5,
                                                            fontSize: switchMerchant ? 10 : 16,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }}>
                                                        SAVE
                                                    </Text>
                                                )}
                                            </TouchableOpacity>
                                        </View>
                                        {/* </KeyboardAwareScrollView> */}
                                    </View>
                                    {/* <View
                style={{
                  flexDirection: 'row',
                  backgroundColor: '#ffffff',
                  justifyContent: 'center',
                  padding: Platform.OS == 'ios' ? 0 : 0,
                  marginTop: 10,
                }}>
                <View style={{ alignItems: 'center' }}>
                  <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
                    {outletSupplyItems.length}
                  </Text>
                  <Text>INVENTORIES</Text>
                </View>
              </View> */}
                                </View>
                            </View>
                        ) : // {/* </KeyboardAvoidingView> */}

                            null}

                        {productUsingSupplyItem ? (
                            <View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        //backgroundColor: '#ffffff',
                                        //justifyContent: 'center',
                                        padding: Platform.OS == 'ios' ? 0 : 0,
                                        marginVertical: switchMerchant ? 0 : 10,
                                        zIndex: 1000,
                                        marginLeft: -Dimensions.get('screen').width * 0.005,
                                    }}>
                                    <View>
                                        <TouchableOpacity
                                            style={{
                                                //marginBottom: 20,
                                                flexDirection: 'row',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                //marginTop: 20,
                                            }}
                                            onPress={() => {
                                                setLowStockAlert(true);
                                                setAddPurchase(false);
                                                setProductUsingSupplyItem(false);
                                            }}>
                                            <Icon
                                                name="chevron-left"
                                                size={switchMerchant ? 20 : 30}
                                                color={Colors.primaryColor}
                                            />
                                            <Text
                                                style={{
                                                    fontFamily: 'NunitoSans-Bold',
                                                    fontSize: switchMerchant ? 14 : 17,
                                                    color: Colors.primaryColor,
                                                    marginBottom: Platform.OS === 'ios' ? 0 : 2,
                                                }}>
                                                Back
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        marginTop: 5,
                                        // height:
                                        //   Platform.OS == 'ios'
                                        //     ? Dimensions.get('window').height - 200
                                        //     : Dimensions.get('window').height - 200
                                        height: switchMerchant
                                            ? Dimensions.get('screen').height * 0.68
                                            : Dimensions.get('window').height - 200,
                                        //top: Platform.OS === 'ios' ? -keyboardHeight * 0.5 : 0,
                                    }}>
                                    <View
                                        style={{
                                            height: '100%',
                                            backgroundColor: Colors.whiteColor,
                                            // borderRadius: 10,
                                            borderRadius: 5,
                                            shadowOpacity: 0,
                                            shadowColor: '#000',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 3,
                                        }}>
                                        <View
                                            style={{
                                                backgroundColor: '#ffffff',
                                                flexDirection: 'row',
                                                paddingVertical: 15,
                                                paddingHorizontal: 10,
                                                //paddingLeft: 20,
                                                //marginTop: 1,
                                                borderRadius: 10,
                                                borderBottomWidth: StyleSheet.hairlineWidth,
                                            }}>
                                            <View
                                                style={{
                                                    width: switchMerchant ? 45 : 50,
                                                    marginRight: 10,
                                                }}
                                            />
                                            <Text
                                                style={{
                                                    width: '35%',
                                                    alignSelf: 'center',
                                                    color: '#969696',
                                                    marginRight: 8,
                                                    fontSize: switchMerchant ? 10 : 16,
                                                }}>
                                                Product Name
                                            </Text>
                                            <Text
                                                style={{
                                                    width: '30%',
                                                    alignSelf: 'center',
                                                    color: '#969696',
                                                    marginRight: 8,
                                                    fontSize: switchMerchant ? 10 : 16,
                                                }}>
                                                SKU
                                            </Text>
                                            <Text
                                                style={{
                                                    width: '15%',
                                                    alignSelf: 'center',
                                                    color: '#969696',
                                                    marginRight: 5,
                                                    fontSize: switchMerchant ? 10 : 16,
                                                }}>
                                                Category
                                            </Text>
                                        </View>
                                        <View>
                                            {inUsedOutletItems.length > 0 ? (
                                                <FlatList
                                                    nestedScrollEnabled={true}
                                                    data={inUsedOutletItems}
                                                    renderItem={renderProductUsingInventory1}
                                                    keyExtractor={(item, index) => String(index)}
                                                    style={{}}
                                                />
                                            ) : (
                                                <View
                                                    style={{
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        height: '80%',
                                                    }}>
                                                    <Text
                                                        style={{
                                                            fontWeight: '500',
                                                            color: Colors.descriptionColor,
                                                            fontSize: switchMerchant ? 12 : 14,
                                                        }}>
                                                        No Product Link to Current Item
                                                    </Text>
                                                </View>
                                            )}
                                        </View>
                                    </View>
                                </View>
                            </View>
                        ) : null}

                        {addPurchase && !lowStockAlert ? (
                            <View
                                style={{
                                    //marginTop: 30,
                                    marginHorizontal: 30,
                                    marginBottom: 0,
                                    alignSelf: 'center',
                                    borderRadius: 5,
                                    shadowOpacity: 0,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                }}>
                                <View
                                    style={
                                        {
                                            //top: Platform.OS === 'ios' ? -keyboardHeight * 1 : 0,
                                        }
                                    }>
                                    <Modal
                                        style={{ flex: 1 }}
                                        supportedOrientations={['portrait', 'landscape']}
                                        visible={visible}
                                        transparent={true}
                                        animationType="slide">
                                        <View
                                            style={{
                                                backgroundColor: 'rgba(0,0,0,0.5)',
                                                flex: 1,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                minHeight: Dimensions.get('window').height,
                                            }}>
                                            <View style={styles.confirmBox}>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        setState({ visible: false });
                                                    }}>
                                                    <View
                                                        style={{
                                                            alignSelf: 'flex-end',
                                                            padding: 16,
                                                        }}>
                                                        {/* <Close name="closecircle" size={25} /> */}
                                                        <AntDesign
                                                            name="closecircle"
                                                            size={25}
                                                            color={Colors.fieldtTxtColor}
                                                        />
                                                    </View>
                                                </TouchableOpacity>
                                                <View>
                                                    <Text
                                                        style={{
                                                            textAlign: 'center',
                                                            fontWeight: '700',
                                                            fontSize: 28,
                                                        }}>
                                                        Purchase Order
                                                    </Text>
                                                </View>
                                                <View style={{ marginTop: 20 }}>
                                                    <Text
                                                        style={{
                                                            textAlign: 'center',
                                                            color: Colors.descriptionColor,
                                                            fontSize: 25,
                                                        }}>
                                                        Fill in the email information
                                                    </Text>
                                                </View>
                                                <View
                                                    style={{
                                                        backgroundColor: 'white',
                                                        alignSelf: 'center',
                                                        flexDirection: 'row',
                                                    }}>
                                                    <Text style={{ fontSize: 20, marginTop: 70 }}>
                                                        Email:
                                                    </Text>
                                                    <View
                                                        style={{
                                                            marginTop: 60,
                                                            backgroundColor: '#f7f5f5',
                                                            marginLeft: 10,
                                                        }}>
                                                        <TextInput
                                                            editable={!loading}
                                                            underlineColorAndroid={Colors.fieldtBgColor}
                                                            clearButtonMode="while-editing"
                                                            style={styles.textCapacity}
                                                            placeholder="myburgerlabmytown@gmail.com"
                                                            placeholderTextColor={Platform.select({
                                                                ios: '#a9a9a9',
                                                            })}
                                                            onChangeText={(text) => {
                                                                setState({ Email: text });
                                                            }}
                                                            value={Email}
                                                        />
                                                    </View>
                                                </View>
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignSelf: 'center',
                                                        marginTop: 20,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '50%',
                                                        alignContent: 'center',
                                                        zIndex: 6000,
                                                    }}></View>
                                                <View
                                                    style={{
                                                        alignSelf: 'center',
                                                        marginTop: 20,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: 250,
                                                        height: 40,
                                                        alignContent: 'center',
                                                        flexDirection: 'row',
                                                        marginTop: 40,
                                                    }}>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            //email(), setState({visible: false}); //need fix
                                                        }}
                                                        style={{
                                                            backgroundColor: Colors.fieldtBgColor,
                                                            width: '60%',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            alignContent: 'center',
                                                            borderRadius: 10,
                                                            height: 60,
                                                        }}>
                                                        <Text
                                                            style={{
                                                                fontSize: 28,
                                                                color: Colors.primaryColor,
                                                            }}>
                                                            Send
                                                        </Text>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            setState({ visible: false });
                                                        }}
                                                        style={{
                                                            backgroundColor: Colors.fieldtBgColor,
                                                            width: '60%',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            alignContent: 'center',
                                                            borderRadius: 10,
                                                            height: 60,
                                                            marginLeft: 30,
                                                        }}>
                                                        <Text
                                                            style={{
                                                                fontSize: 28,
                                                                color: Colors.primaryColor,
                                                            }}>
                                                            No
                                                        </Text>
                                                    </TouchableOpacity>
                                                </View>
                                            </View>
                                        </View>
                                    </Modal>

                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginBottom: 10,
                                            //marginHorizontal: 25,
                                            //marginTop: -25,
                                            zIndex: 1000,
                                            alignSelf: 'center',
                                            width: Dimensions.get('screen').width * 0.886,
                                        }}>
                                        <TouchableOpacity
                                            style={{
                                                //marginBottom: 20,
                                                flexDirection: 'row',
                                                alignContent: 'center',
                                                alignItems: 'center',
                                                //marginTop: 20,
                                            }}
                                            onPress={() => {
                                                setLowStockAlert(true);
                                                setAddPurchase(false);
                                            }}>
                                            <Icon
                                                name="chevron-left"
                                                size={switchMerchant ? 20 : 30}
                                                color={Colors.primaryColor}
                                            />
                                            <Text
                                                style={{
                                                    fontFamily: 'NunitoSans-Bold',
                                                    fontSize: switchMerchant ? 14 : 17,
                                                    color: Colors.primaryColor,
                                                    marginBottom: Platform.OS === 'ios' ? 0 : 2,
                                                }}>
                                                Back
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View
                                        style={{
                                            backgroundColor: Colors.whiteColor,
                                            width: Dimensions.get('screen').width * 0.87,
                                            height: Dimensions.get('screen').height * 0.79,
                                            //marginTop: 20,
                                            marginHorizontal: 30,
                                            marginBottom: 30,
                                            alignSelf: 'center',
                                            //borderRadius: 5,
                                            shadowOpacity: 0,
                                            shadowColor: '#000',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 3,
                                            borderRadius: 5,
                                        }}>
                                        <ScrollView
                                            nestedScrollEnabled={true}
                                            showsVerticalScrollIndicator={false}
                                            contentContainerStyle={
                                                {
                                                    // top: Platform.OS === 'ios' ? -keyboardHeight * 0.7 : 0,
                                                    //borderWidth: 1,
                                                    //borderRadius: 5,
                                                    //borderColor: '#E5E5E5',
                                                    //backgroundColor: Colors.whiteColor,
                                                }
                                            }>
                                            {/* <View
                    style={{
                      width: 140,
                      marginRight: 10,
                      marginTop: 10,
                      alignSelf: 'flex-end',
                    }}></View> */}
                                            <View
                                                style={{ borderBottomWidth: StyleSheet.hairlineWidth }}>
                                                <TouchableOpacity
                                                    style={{
                                                        justifyContent: 'center',
                                                        flexDirection: 'row',
                                                        borderWidth: 1,
                                                        borderColor: Colors.primaryColor,
                                                        backgroundColor: '#4E9F7D',
                                                        borderRadius: 5,
                                                        width: 120,
                                                        paddingHorizontal: 10,
                                                        height: switchMerchant ? 35 : 40,
                                                        alignItems: 'center',
                                                        shadowOffset: {
                                                            width: 0,
                                                            height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: 1,
                                                        alignSelf: 'flex-end',
                                                        position: 'absolute',
                                                        marginTop: 30,
                                                        right: 20,
                                                    }}
                                                    onPress={insertInventory}>
                                                    <Text
                                                        style={{
                                                            color: Colors.whiteColor,
                                                            //marginLeft: 5,
                                                            fontSize: switchMerchant ? 10 : 16,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }}>
                                                        ADD
                                                    </Text>
                                                </TouchableOpacity>
                                                <View
                                                    style={{
                                                        marginBottom: 40,
                                                    }}>
                                                    <Text
                                                        style={{
                                                            alignSelf: 'center',
                                                            marginTop: 30,
                                                            fontSize: switchMerchant ? 20 : 40,
                                                            fontWeight: 'bold',
                                                        }}>
                                                        Add Inventory
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            alignSelf: 'center',
                                                            fontSize: switchMerchant ? 10 : 16,
                                                            color: '#adadad',
                                                        }}>
                                                        Fill In The Inventory Information
                                                    </Text>
                                                </View>
                                            </View>

                                            <View>
                                                {/* <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                      <Text
                        style={{
                          alignSelf: 'center',
                          //marginTop: 30,
                          fontSize: 25,
                          fontWeight: 'bold',
                          //marginLeft: '40%',
                        }}>
                        Inventories to insert
                      </Text>
                    </View> */}
                                                <View
                                                    style={{
                                                        marginTop: 20,
                                                        position: 'absolute',
                                                        //backgroundColor: 'red',
                                                        alignSelf: 'flex-end',
                                                    }}>
                                                    {/* <TouchableOpacity
                        style={{
                          backgroundColor: Colors.primaryColor,
                          width: 150,
                          height: 40,
                          borderRadius: 5,
                          justifyContent: 'center',
                          alignSelf: 'flex-end',
                          marginRight: 10,
                        }}
                        onPress={() => {
                          setImportModal(true)
                        }}>
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            textAlign: 'center',
                          }}>
                          IMPORT
                        </Text>
                      </TouchableOpacity> */}
                                                </View>
                                            </View>

                                            <View
                                                style={{
                                                    backgroundColor: '#ffffff',
                                                    flexDirection: 'row',
                                                    paddingVertical: 20,
                                                    paddingHorizontal: 20,
                                                    marginTop: 10,
                                                    borderBottomWidth: StyleSheet.hairlineWidth,
                                                }}>
                                                <Text
                                                    style={{
                                                        fontFamily: 'NunitoSans-Bold',
                                                        fontSize: switchMerchant ? 10 : 14,
                                                        width: '28%',
                                                        alignSelf: 'center',
                                                        paddingLeft: '1%',
                                                    }}>
                                                    Product Name
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: 'NunitoSans-Bold',
                                                        fontSize: switchMerchant ? 10 : 14,
                                                        width: '21%',
                                                        alignSelf: 'center',
                                                        marginLeft: 10,
                                                    }}>
                                                    SKU
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: 'NunitoSans-Bold',
                                                        fontSize: switchMerchant ? 10 : 14,
                                                        width: '12%',
                                                    }}>
                                                    Unit
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: 'NunitoSans-Bold',
                                                        fontSize: switchMerchant ? 10 : 14,
                                                        width: '18%',
                                                        alignSelf: 'center',
                                                    }}>
                                                    In Stock
                                                </Text>
                                                <Text
                                                    style={{
                                                        fontFamily: 'NunitoSans-Bold',
                                                        fontSize: switchMerchant ? 10 : 14,
                                                        width: '16%',
                                                        alignSelf: 'center',
                                                    }}>
                                                    Insert Quantity
                                                </Text>
                                            </View>

                                            {console.log('poItems')}
                                            {console.log(poItems)}
                                            {console.log('supplyItems')}
                                            {console.log(supplyItems)}
                                            {console.log('supplyItemDropdownList')}
                                            {console.log(supplyItemDropdownList)}

                                            {/* <FlatList
                        nestedScrollEnabled={true}
                        data={poItems}
                        extraData={poItems}
                        renderItem={renderAddPO}
                        keyExtractor={(item, index) => String(index)}
                        contentContainerStyle={{
                          paddingTop: 15,
                        }}
                      /> */}
                                            {renderAddPO()}

                                            {/* <ScrollView>
                    {
                      // itemsToOrder2 && itemsToOrder2.map((item, i) => {
                      //   return renderAddPO(item);
                      // })
                      poItems && poItems.map((item, i) => {
                        return renderAddPO(item, i);
                      })
                    }
                  </ScrollView> */}

                                            <View style={{ flexDirection: 'row' }}>
                                                <View>
                                                    <TouchableOpacity
                                                        style={styles.submitText2}
                                                        onPress={() => {
                                                            if (supplyItems.length > 0) {
                                                                setPoItems([
                                                                    ...poItems,
                                                                    {
                                                                        // supplyItemId: '',
                                                                        // name: '',
                                                                        // sku: '',
                                                                        // quantity: '',
                                                                        // insertQuantity: 0,
                                                                        supplyItemId: supplyItems[0].uniqueId,
                                                                        name: supplyItems[0].name,
                                                                        sku: supplyItems[0].sku,
                                                                        skuMerchant: supplyItems[0].skuMerchant,
                                                                        unit: supplyItems[0].unit,
                                                                        quantity: outletSupplyItemsSkuDict[
                                                                            supplyItems[0].sku
                                                                        ]
                                                                            ? outletSupplyItemsSkuDict[
                                                                                supplyItems[0].sku
                                                                            ].quantity
                                                                            : 0, // check if the supply item sku for this outlet existed
                                                                        // orderQuantity: 0,
                                                                        // receivedQuantity: 0,
                                                                        // price: supplyItems[0].price,
                                                                        // totalPrice: 0,
                                                                        insertQuantity: 0,
                                                                        supplyItem: supplyItems[0],
                                                                    },
                                                                ]);
                                                            } else {
                                                                Alert.alert(
                                                                    'Error',
                                                                    'No supplier items added.',
                                                                );
                                                            }
                                                        }}>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                            }}>
                                                            <Icon1
                                                                name="plus-circle"
                                                                size={switchMerchant ? 15 : 20}
                                                                color={Colors.primaryColor}
                                                            />
                                                            <Text
                                                                style={{
                                                                    marginLeft: switchMerchant ? 5 : 10,
                                                                    color: Colors.primaryColor,
                                                                    fontFamily: 'NunitoSans-Regular',
                                                                    fontSize: switchMerchant ? 10 : 14,
                                                                }}>
                                                                Add Product Slot
                                                            </Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </View>

                                                {/* <View style={{ marginLeft: 350, marginTop: 10 }}>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={{ color: '#adadad' }}>Subtotal</Text>
                        <Text style={{ color: '#adadad', marginLeft: 50 }}>{`RM${subtotal.toFixed(2)}`}</Text>
                      </View>
                      <View style={{ flexDirection: "row", marginTop: 10 }}>
                        <Text style={{ color: '#adadad' }}>Tax</Text>
                        <Text style={{ color: '#adadad', marginLeft: 80 }}>{`RM${taxTotal.toFixed(2)}`}</Text>
                      </View>
                      <View style={{ flexDirection: "row", marginTop: 10 }}>
                        <Text style={{ color: '#adadad' }}>Discount</Text>
                        <Text style={{ color: '#adadad', marginLeft: 50 }}>{`RM${discountTotal.toFixed(2)}`}</Text>
                      </View>
                      <View style={{ flexDirection: "row", marginTop: 10 }}>
                        <Text style={{ fontWeight: 'bold' }}>Total (RM)</Text>
                        <Text style={{ fontWeight: 'bold', marginLeft: 40 }}>{`RM${finalTotal.toFixed(2)}`}</Text>
                      </View>
                    </View> */}
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignSelf: 'center',
                                                    justifyContent: 'space-evenly',
                                                    marginTop: 20,
                                                    height: 30,
                                                }}>
                                                {/* <View
                      style={{
                        backgroundColor: Colors.primaryColor,
                        width: 200,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: 'center',
                      }}>
                      <TouchableOpacity onPress={insertInventory}>
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            alignSelf: 'center',
                            marginVertical: 10,
                          }}>
                          ADD
                        </Text>
                      </TouchableOpacity>
                    </View> */}
                                                {/* <View
                      style={{
                        backgroundColor: Colors.primaryColor,
                        width: 200,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: 'center',
                        marginLeft: 40
                      }}>
                      <TouchableOpacity onPress={() => { setVisible(true) }}>
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            alignSelf: 'center',
                            marginVertical: 10,
                          }}>
                          SAVE & SEND
                        </Text>
                      </TouchableOpacity>
                    </View> */}
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    backgroundColor: '#ffffff',
                                                    justifyContent: 'center',
                                                    padding: 18,
                                                    borderRadius: 5,
                                                }}>
                                                {/* <View style={{ alignItems: 'center' }}>
                      <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
                        {purchaseOrders.length}
                      </Text>
                      <Text>PURCHASE ORDERS</Text>
                    </View> */}
                                            </View>
                                        </ScrollView>
                                    </View>
                                </View>
                            </View>
                        ) : null}
                    </View>
                </ScrollView>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.highlightColor,
        flexDirection: 'row',
    },
    headerLogo: {
        width: 112,
        height: 25,
        marginLeft: 10,
    },
    list: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
    },
    listItem: {
        marginLeft: 20,
        color: Colors.descriptionColor,
        fontSize: 16,
    },
    sidebar: {
        width: Dimensions.get('screen').width * Styles.sideBarWidth,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
    content: {
        padding: 16,
        width: Dimensions.get('screen').width * (1 - Styles.sideBarWidth),
    },
    submitText: {
        //height: Platform.OS == 'ios' ? Dimensions.get('screen').height * 0.06 : Dimensions.get('screen').height * 0.07,
        height:
            Platform.OS == 'ios' ? Dimensions.get('screen').height * 0.06 : '85%',
        paddingVertical: 5,
        paddingHorizontal: 20,
        flexDirection: 'row',
        color: '#4cd964',
        textAlign: 'center',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
    submitText1: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        flexDirection: 'row',
        color: '#4cd964',
        textAlign: 'center',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        alignSelf: 'flex-end',
        marginRight: 20,
        marginTop: 15,
    },
    submitText2: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        flexDirection: 'row',
        color: '#4cd964',
        textAlign: 'center',
        alignSelf: 'flex-end',
        marginRight: 20,
        marginTop: 15,
    },
    /* textInput: {
      width: 300,
      height: '10%',
      padding: 20,
      backgroundColor: Colors.fieldtBgColor,
      borderRadius: 5,
      borderRadius: 5,
      paddingTop: 20,
    }, */

    textInput: {
        width: 200,
        height: 40,
        // padding:5,
        backgroundColor: Colors.whiteColor,
        borderRadius: 0,
        //marginRight: '35%',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'flex-end',
        alignSelf: 'flex-end',

        shadowOffset:
            Platform.OS == 'ios'
                ? {
                    width: 0,
                    height: 0,
                }
                : {
                    width: 0,
                    height: 7,
                },
        shadowOpacity: Platform.OS == 'ios' ? 0 : 0.43,
        shadowRadius: Platform.OS == 'ios' ? 0 : 0.51,
        elevation: 15,
    },
    searchIcon: {
        backgroundColor: Colors.whiteColor,
        height: 40,
        padding: 10,
        shadowOffset:
            Platform.OS == 'ios'
                ? {
                    width: 0,
                    height: 0,
                }
                : {
                    width: 0,
                    height: 7,
                },
        shadowOpacity: Platform.OS == 'ios' ? 0 : 0.43,
        shadowRadius: Platform.OS == 'ios' ? 0 : 9.51,

        elevation: 15,
    },
    textInput1: {
        width: 160,
        padding: 5,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 5,
        borderRadius: 5,
        paddingTop: 5,
    },
    textInput2: {
        backgroundColor: Colors.fieldtBgColor,
        width: 70,
        height: 35,
        borderRadius: 5,
        padding: 5,
        borderWidth: 1,
        borderColor: '#E5E5E5',
        paddingLeft: 10,
    },
    confirmBox: {
        width: 450,
        height: 450,
        borderRadius: 12,
        backgroundColor: Colors.whiteColor,
    },
    textFieldInput: {
        height: 80,
        width: 900,
        paddingHorizontal: 20,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 5,
        borderRadius: 5,
    },
    circleIcon: {
        width: 30,
        height: 30,
        // resizeMode: 'contain',
        marginRight: 10,
        alignSelf: 'center',
    },
    headerLeftStyle: {
        width: Dimensions.get('screen').width * 0.17,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        flex: 1,
        backgroundColor: Colors.modalBgColor,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalView: {
        height: Dimensions.get('screen').width * 0.2,
        width: Dimensions.get('screen').width * 0.3,
        backgroundColor: Colors.whiteColor,
        borderRadius: 12,
        padding: Dimensions.get('screen').width * 0.03,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContainer1: {
        flex: 1,
        backgroundColor: Colors.modalBgColor,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalView1: {
        // height: 450,
        // width: 500,
        height: Dimensions.get('screen').height * 0.6,
        width: Dimensions.get('screen').width * 0.5,
        backgroundColor: Colors.whiteColor,
        borderRadius: 12,
        padding: 20,
        alignItems: 'center',
        //justifyContent: 'center'
    },
    modalViewImport: {
        height: Dimensions.get('screen').width * 0.6,
        width: Dimensions.get('screen').width * 0.6,
        backgroundColor: Colors.whiteColor,
        borderRadius: Dimensions.get('screen').width * 0.03,
        padding: 20,
        paddingTop: 25,
        //paddingBottom: 30,
    },
    closeButton: {
        position: 'absolute',
        right: Dimensions.get('screen').width * 0.01,
        top: Dimensions.get('screen').width * 0.01,

        elevation: 1000,
        zIndex: 1000,
    },
    modalTitle: {
        alignItems: 'center',
        top: '20%',
        position: 'absolute',
    },
    modalBody: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTitleText: {
        fontFamily: 'NunitoSans-Bold',
        textAlign: 'center',
        fontSize: 20,
    },
    modalDescText: {
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 18,
        color: Colors.fieldtTxtColor,
    },
    modalBodyText: {
        flex: 1,
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 25,
        width: '20%',
    },
    modalSaveButton: {
        width: Dimensions.get('screen').width * 0.15,
        backgroundColor: Colors.fieldtBgColor,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,

        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 1,

        marginVertical: 10,
    },
    textCapacity: {
        backgroundColor: Colors.fieldtBgColor,
        width: 370,
        height: 50,
        borderRadius: 5,
        padding: 5,
        marginVertical: 5,
        borderWidth: 1,
        borderColor: '#E5E5E5',
        paddingLeft: 10,
    },
});
export default InventoryScreen;
