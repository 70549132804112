import React, { Component, useReducer, useState, useEffect } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Modal,
  Dimensions,
  TextInput,
  FlatList,
  KeyboardAvoidingView,
  ActivityIndicator,
  Picker,
  useWindowDimensions
} from 'react-native';
// import CheckBox from 'react-native-check-box';
// import CheckBox from '@react-native-community/checkbox';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import SideBar from './SideBar';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Ionicons';
import Icon1 from 'react-native-vector-icons/Feather';
import Ionicon from 'react-native-vector-icons/Ionicons';
//import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
// import DropDownPicker from 'react-native-dropdown-picker';
import Select from 'react-select';
import Multiselect from "multiselect-react-dropdown";
// import GCalendar from '../assets/svg/GCalendar';
import Feather from 'react-native-vector-icons/Feather';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import moment from 'moment';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import TimeKeeper from "react-timekeeper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
import { useLinkTo, useRoute } from "@react-navigation/native";
// import { isTablet } from 'react-native-device-detection';
import {
  MERCHANT_VOUCHER_CODE_FORMAT,
  MERCHANT_VOUCHER_TYPE,
  SEGMENT_TYPE,
  ORDER_TYPE,
  ORDER_TYPE_PARSED,
  ORDER_TYPE_DROP_DOWN_LIST,
} from '../constant/common';
import { CommonStore } from '../store/commonStore';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { OutletStore } from '../store/outletStore';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
// import RNPickerSelect from 'react-native-picker-select';
// import { useKeyboard } from '../hooks';
// import { get } from 'react-native/Libraries/Utilities/PixelRatio';
// import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
// import Switch from 'react-native-switch-pro';
import {
  EFFECTIVE_DAY_DROPDOWN_LIST,
  EFFECTIVE_DAY_DROPDOWN_LIST2,
  TARGET_USER_GROUP_DROPDOWN_LIST,
  PROMOTION_TYPE_VARIATION,
  PROMOTION_TYPE_VARIATION_DROPDOWN_LIST,
  EFFECTIVE_TYPE,
  CRM_SEGMENT_DROPDOWN_LIST,
} from '../constant/promotions';
import { areArraysEqual, uploadImageToFirebaseStorage } from '../util/common';
import AsyncImage from '../components/asyncImage';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import AntDesign from 'react-native-vector-icons/AntDesign';
// import NumericInput from 'react-native-numeric-input';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { ReactComponent as Edit } from '../assets/svg/Edit.svg'
// import { getAllExternalFilesDirs } from 'react-native-fs';
import {
  LOYALTY_CAMPAIGN_DROPDOWN_LIST,
  LOYALTY_CAMPAIGN_TYPE,
  LOYALTY_CAMPAIGN_TYPE_PARSED,
  LOYALTY_PROMOTION_TYPE,
  LOYALTY_PROMOTION_TYPE_DROPDOWN_LIST,
} from '../constant/loyalty';
import {Platform} from 'react-native';
import { useFilePicker } from "use-file-picker";
import { prefix } from "../constant/env";

//////////////////////////////////////////////////////////////////////////////////////////////////////////

const NewLoyaltyCampaignScreen = (props) => {
  const { navigation } = props;
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const [keyboardHeight] = useKeyboard();
  const linkTo = useLinkTo();

  const [campaignName, setCampaignName] = useState('');
  const [isEnableSellOnline, setIsEnableSellOnline] = useState(false);

  const [selectedTargetUserGroup, setSelectedTargetUserGroup] = useState(
    TARGET_USER_GROUP_DROPDOWN_LIST[0].value,
  );
  const [selectedTargetSegmentGroupList, setSelectedTargetSegmentGroupList] =
    useState([]);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [isPromoCodeUsageLimit, setIsPromoCodeUsageLimit] = useState(false);
  const [promoCodeUsageLimit, setPromoCodeUsageLimit] = useState('');
  const [promoCodeUsageLimitTicked, setPromoCodeUsageLimitTicked] =
    useState(false);

  const [selectedEffectiveType, setSelectedEffectiveType] = useState(
    EFFECTIVE_TYPE.DAY,
  );
  const [selectedEffectiveTypeOptions, setSelectedEffectiveTypeOptions] =
    useState([EFFECTIVE_DAY_DROPDOWN_LIST[0].value]);
  const [selectedEffectiveDay, setSelectedEffectiveDay] = useState(
    EFFECTIVE_DAY_DROPDOWN_LIST[0].value,
  );
  const [showEffectiveTimeStartPicker, setShowEffectiveTimeStartPicker] =
    useState(false);
  const [showEffectiveTimeEndPicker, setShowEffectiveTimeEndPicker] =
    useState(false);
  const [effectiveTimeStart, setEffectiveTimeStart] = useState(moment());
  const [rev_EffectiveTimeStart, setRev_EffectiveTimeStart] = useState(
    moment(Date.now()).format("hh:mm A")  
  )
  const [effectiveTimeEnd, setEffectiveTimeEnd] = useState(moment());
  const [rev_EffectiveTimeEnd, setRev_EffectiveTimeEnd] = useState(
    moment(Date.now()).format("hh:mm A")  
  )

  const [showPromoDateStartPicker, setShowPromoDateStartPicker] =
    useState(false);
  const [showPromoDateEndPicker, setShowPromoDateEndPicker] = useState(false);
  const [showPromoTimeStartPicker, setShowPromoTimeStartPicker] =
    useState(false);
  const [showPromoTimeEndPicker, setShowPromoTimeEndPicker] = useState(false);
  const [promoDateStart, setPromoDateStart] = useState(moment());
  const [promoDateEnd, setPromoDateEnd] = useState(moment());
  const [rev_PromoDateEnd, setRev_PromoDateEnd] = useState(
    moment(Date.now()).format("hh:mm A")  
  );
  const [promoTimeStart, setPromoTimeStart] = useState(moment());
  const [rev_PromoTimeStart, setRev_PromoTimeStart] = useState(
    moment(Date.now()).format("hh:mm A")  
  );
  const [rev_PromoDateStart, setRev_PromoDateStart] = useState(
    moment(Date.now()).format("hh:mm A")  
  );
  const [promoTimeEnd, setPromoTimeEnd] = useState(moment());
  const [rev_PromoTimeEnd, setRev_PromoTimeEnd] = useState(
    moment(Date.now()).format("hh:mm A")  
  );
  const [campaignDescription, setCampaignDescription] = useState('');

  const [selectedPromotionType, setSelectedPromotionType] = useState(
    LOYALTY_PROMOTION_TYPE_DROPDOWN_LIST[0].value,
  );

  const [
    isRequireSpecificProductPurchase,
    setIsRequireSpecificProductPurchase,
  ] = useState(false);

  const [image, setImage] = useState('');
  const [imageType, setImageType] = useState('');
  const [isImageChanged, setIsImageChanged] = useState(false);

  const [outletDropdownList, setOutletDropdownList] = useState([]);
  const [selectedOutletList, setSelectedOutletList] = useState([]); // multi-outlets

  //const [availabilityDropdownList, setAvailabilityDropdownList] = useState([]); // DropDownList for Dine in, TakeAway, Delivery
  const [selectedAvailability, setSelectedAvailability] = useState([
    ORDER_TYPE_DROP_DOWN_LIST[0].value,
  ]); // Enable Promotion for Dine in, TakeAway, Delivery

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [isChecked, setIsChecked] = useState(false);
  const [showDateTimePicker_Date, setShowDateTimePicker_Date] = useState(false);
  const [showDateTimePicker_Time, setShowDateTimePicker_Time] = useState(false);
  //const [isLoading1, setIsLoading1] = useState(false);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  ////////////////////////////////Combo Set/Package/Bundle Function//////////////////////////////////////////

  const [packageItemPrice, setPackageItemPrice] = useState('0');
  const [packageMinQuantity, setPackageMinQuantity] = useState('0');
  const [packagePriceFinal, setPackagePriceFinal] = useState(0);

  const [packageTaxDropdownList, setPackageTaxDropdownList] = useState([]);
  const [packageTax, setPackageTax] = useState('');

  const [packageProductDropdownList, setPackageProductDropdownList] = useState(
    [],
  );
  const [packageselectedProductList, setPackageSelectedProductList] = useState(
    [],
  );

  const [packageCategoryDropdownList, setPackageCategoryDropdownList] =
    useState([]);
  const [packageSelectedCategoryList, setPackageSelectedCategoryList] =
    useState([]);

  ////////////////////////////////Override Function//////////////////////////////////////////

  const [overrideItemPrice, setOverrideItemPrice] = useState('0');
  const [overridePriceFinal, setOverridePriceFinal] = useState(0);
  // const [overrideMinQuantity, setOverrideMinQuantity] = useState('');

  const [overrideTaxDropdownList, setOverrideTaxDropdownList] = useState([]);
  const [overrideTax, setOverrideTax] = useState('');

  const [overrideProductDropdownList, setOverrideProductDropdownList] =
    useState([]);
  const [overrideSelectedProductList, setOverrideSelectedProductList] =
    useState([]);

  const [overrideCategoryDropdownList, setOverrideCategoryDropdownList] =
    useState([]);
  const [overrideSelectedCategoryList, setOverrideSelectedCategoryList] =
    useState([]);

  ///////////////////////////////////////////////////////////////////////////////////////////

  // Override in array form

  const [overrideItems, setOverrideItems] = useState([
    {
      priceBeforeTax: 0,
      variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
      variationItems: [],
      variationItemsSku: [],
    },
  ]);

  const [variationItemsProducts, setVariationItemsProducts] = useState([]);
  const [variationItemsCategories, setVariationItemsCategories] = useState([]);

  ///////////////////////////////Cashback Function////////////////////////////////////////////

  const [cashbackPercentage, setCashbackPercentage] = useState('');
  const [cashbackMinQuantity, setCashbackMinQuantity] = useState('');
  const [cashbackExpired, setCashbackExpired] = useState('');

  const [cashbackExpiredDropdownList, setCashbackExpiredDropdownList] =
    useState([]);
  const [cashbackSelectedExpiredList, setCashbackSelectedExpiredList] =
    useState([]);

  const [cashbackProductDropdownList, setCashbackProductDropdownList] =
    useState([]);
  const [cashbackSelectedProductList, setCashbackSelectedProductList] =
    useState([]);

  const [cashbackCategoryDropdownList, setCashbackCategoryDropdownList] =
    useState([]);
  const [cashbackSelectedCategoryList, setCashbackSelectedCategoryList] =
    useState([]);

  ///////////////////////////////Buy 1 Free 1 Function////////////////////////////////////////////

  const [buyInput, setBuyInput] = useState('1');
  const [getInput, setGetInput] = useState('1');
  const [getPriceInput, setGetPriceInput] = useState('0');

  const [buy1Free1ProductDropdownList, setBuy1Free1ProductDropdownList] =
    useState([]);
  const [buy1Free1SelectedProductList, setBuy1Free1SelectedProductList] =
    useState([]);

  const [selectedVariationB1F1, setSelectedVariationB1F1] = useState(
    PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
  );

  const [selectedVariationItemsB1F1, setSelectedVariationItemsB1F1] = useState(
    [],
  );

  const [selectedVariationItemsSkuB1F1, setSelectedVariationItemsSkuB1F1] =
    useState([]); // for multi-outlets

  const [variationItemsB1F1DropdownList, setVariationItemsB1F1DropdownList] =
    useState([]);

  ///////////////////////////////Take amount off////////////////////////////////////////////

  const [amountOffPrice, setAmountOffPrice] = useState('0');
  const [amountOffMinQuantity, setAmountOffMinQuantity] = useState('0');
  const [amountOffMaxQuantity, setAmountOffMaxQuantity] = useState('0');

  const [amountOffProductDropdownList, setAmountOffProductDropdownList] =
    useState([]);
  const [amountOffSelectedProductList, setAmountOffSelectedProductList] =
    useState([]);

  const [amountOffCategoryDropdownList, setAmountOffCategoryDropdownList] =
    useState([]);
  const [amountOffSelectedCategoryList, setAmountOffSelectedCategoryList] =
    useState([]);

  ///////////////////////////////Take percentage off////////////////////////////////////////////

  const [percentageOffPrice, setPercentageOffPrice] = useState('0');
  const [percentageOffMinQuantity, setPercentageOffMinQuantity] = useState('0');
  const [percentageOffMaxQuantity, setPercentageOffMaxQuantity] = useState('0');

  const [
    percentageOffProductDropdownList,
    setPercentageOffProductDropdownList,
  ] = useState([]);
  const [
    percentageOffSelectedProductList,
    setPercentageOffSelectedProductList,
  ] = useState([]);

  const [
    percentageOffCategoryDropdownList,
    setPercentageOffCategoryDropdownList,
  ] = useState([]);
  const [
    percentageOffSelectedCategoryList,
    setPercentageOffSelectedCategoryList,
  ] = useState([]);

  ///////////////////////////////Buy A Get B For % Discount////////////////////////////////////////////

  const [percentageDiscount, setPercentageDiscount] = useState('0');

  ///////////////////////////////Buy AB Get Voucher////////////////////////////////////////////

  const [selectedVoucher, setSelectedVoucher] = useState([]);
  const [selectedVoucherItems, setSelectedVoucherItems] = useState([]);
  const merchantVouchers = CommonStore.useState((s) => s.merchantVouchers);

  ///////////////////////////////Delivery////////////////////////////////////////////

  const [myTextInput, setMyTextInput] = useState(React.createRef());
  const [deliveryFreeFlag, setDeliveryFreeFlag] = useState(false);
  const [deliveryFreeAboveAmount, setDeliveryFreeAboveAmount] = useState('0');
  const [deliveryDiscountAmount, setDeliveryDiscountAmount] = useState('0');
  const [deliveryDiscountAboveAmount, setDeliveryDiscountAboveAmount] =
    useState('0');

  ///////////////////////////////////Takeaway////////////////////////////////////////////////////////

  const [takeawayFreeFlag, setTakeawayFreeFlag] = useState(false);
  const [takeawayFreeAboveAmount, setTakeawayFreeAboveAmount] = useState('0');
  const [takeawayDiscountAmount, setTakeawayDiscountAmount] = useState('0');
  const [takeawayDiscountAboveAmount, setTakeawayDiscountAboveAmount] =
    useState('0');

  // common

  const [minSpend, setMinSpend] = useState('0');

  ///////////////////////////////////////////////////////////////////////////////////////////

  // Notification

  const [isEditNotification, setIsEditNotification] = useState(true);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [isPushToSMS, setIsPushToSMS] = useState(false);
  const [isPushToApp, setIsPushToApp] = useState(true);
  const [isPushToEmail, setIsPushToEmail] = useState(false);
  const [notificationDescription, setNotificationDescription] = useState('Hi %userName%, we have sent you a voucher of a free cup of coffee, visit %outletName% to redeem now!');
  const [showNotificationDatePicker, setShowNotificationDatePicker] =
    useState(false);
  const [showNotificationTimePicker, setShowNotificationTimePicker] =
    useState(false);
  const [notificationDate, setNotificationDate] = useState(moment());
  const [notificationTime, setNotificationTime] = useState(moment());
  const [notificationPromoCode, setNotificationPromoCode] = useState('');
  const [wordCount, setWordCount] = useState();

  ///////////////////////////////////////////////////////////////////////////////////////////

  // const [variationDropdownList, setVariationDropdownList] = useState(PROMOTION_TYPE_VARIATION_DROPDOWN_LIST);
  const [selectedVariation, setSelectedVariation] = useState(
    PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
  );

  const [variationItemsDropdownList, setVariationItemsDropdownList] = useState(
    [],
  );
  const [selectedVariationItems, setSelectedVariationItems] = useState([]);

  const [selectedVariationItemsSku, setSelectedVariationItemsSku] = useState(
    [],
  ); // for multi-outlets

  const [taxDropdownList, setTaxDropdownList] = useState([]);
  const [selectedTax, setSelectedTax] = useState('');
  const [selectedTaxName, setSelectedTaxName] = useState('');
  const [selectedTaxRate, setSelectedTaxRate] = useState(0);

  const [crmUserTagsDropdownList, setCrmUserTagsDropdownList] = useState([]);
  const [crmSegmentsDropdownList, setCrmSegmentsDropdownList] = useState([]);

  const [priceMin, setPriceMin] = useState('');
  const [priceMax, setPriceMax] = useState('');
  const [quantityMin, setQuantityMin] = useState('');
  const [quantityMax, setQuantityMax] = useState('');

  const [smsText, setSmsText] = useState('Hi %userName%, we have sent you a voucher of a free cup of coffee, visit %outletName% to redeem now!');

  ///////////////////////////////////////////////////////////////////////////////////////////

  const [outletItems, setOutletItems] = useState([]);
  const [outletCategories, setOutletCategories] = useState([]);

  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const outletItemsUnsorted = OutletStore.useState((s) => s.outletItems);
  const outletCategoriesUnsorted = OutletStore.useState(
    (s) => s.outletCategories,
  );
  const outletCategoriesDict = OutletStore.useState(
    (s) => s.outletCategoriesDict,
  );
  const outletsTaxDict = OutletStore.useState((s) => s.outletsTaxDict);
  const currOutletTaxes = CommonStore.useState((s) => s.currOutletTaxes);
  const isLoading = CommonStore.useState((s) => s.isLoading);

  const selectedLoyaltyCampaignEdit = CommonStore.useState(
    (s) => s.selectedLoyaltyCampaignEdit,
  );

  const merchantId = UserStore.useState((s) => s.merchantId);

  const allOutletsCategoriesUnique = OutletStore.useState(
    (s) => s.allOutletsCategoriesUnique,
  );

  const crmUserTags = OutletStore.useState((s) => s.crmUserTags);
  const crmSegments = OutletStore.useState((s) => s.crmSegments);

  ///////////////////////////////////////////////////////////////////////////////////////////
  //Push Button
  const [promotionsNotifications, setPromotionsNotifications] = useState([]);
  const loyaltyCampaigns = OutletStore.useState((s) => s.loyaltyCampaigns);
  //const [isAutoPush, setIsAutoPush] = useState(false);
  //const [isLoading1, setIsLoading1] = useState(false);
  const [promotionPushed, setPromotionPushed] = useState(false);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView,
  );

  const [importModal, setImportModal] = useState(false);
  const [showImportListModal, setShowImportListModal] = useState(false);
  const [importSmsModal, setImportSmsModal] = useState(false);

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  // loyalty campaign type

  const [selectedLoyaltyCampaignType, setSelectedLoyaltyCampaignType] =
    useState(LOYALTY_CAMPAIGN_DROPDOWN_LIST[0].value);

  const [
    showLoyaltyCampaignSendTimePicker,
    setShowLoyaltyCampaignSendTimePicker,
  ] = useState(false);
  const [loyaltyCampaignSendTime, setLoyaltyCampaignSendTime] = useState(
    moment(Date.now()).format("hh:mm A")  );

  const [rev_LoyaltyCampaignSendTime, setRev_LoyaltyCampaignSendTime] = useState(
    moment(Date.now()).format("hh:mm A")  );

  const [loyaltyCampaignExpirationDays, setLoyaltyCampaignExpirationDays] =
    useState('14');

  const [
    loyaltyCampaignGuestNotVisitedDays,
    setLoyaltyCampaignGuestNotVisitedDays,
  ] = useState('30');
  const [
    loyaltyCampaignGuestBirthdayBeforeDays,
    setLoyaltyCampaignGuestBirthdayBeforeDays,
  ] = useState('7');
  const [
    loyaltyCampaignGuestAfterEveryVisits,
    setLoyaltyCampaignGuestAfterEveryVisits,
  ] = useState('10');
  const [
    loyaltyCampaignGuestAfterEverySpends,
    setLoyaltyCampaignGuestAfterEverySpends,
  ] = useState('3000');

  //////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////

  const setState = () => { };

  // useEffect(() => {
  //   if (outletsTaxDict[currOutletId] && outletsTaxDict[currOutletId].length > 0) {
  //     setTaxDropdownList(outletsTaxDict[currOutletId].map(item => ({ label: item.name, value: item.uniqueId })));

  //     if (selectedTax === '') {
  //       setSelectedTax(outletsTaxDict[currOutletId][0]);
  //     }
  //   }
  // }, [outletsTaxDict, currOutletId]);

  /////////////////////////////////////////////////////////////
  // const confirmDeletePromotion = () => {
  //   return window.confirm(
  //     'Delete',
  //     'Are you sure you want to delete this promotion?',
  //     [
  //       {
  //         text: 'YES',
  //         onPress: () => {
  //           deletePromotion(item);
  //         },
  //       },
  //       {
  //         text: 'NO',
  //         onPress: () => { },
  //       },
  //     ],
  //   );
  // };

  /////////////////////////////////////////////////////////////

  useEffect(() => {
    console.log('================================');
    console.log('selectedLoyaltyCampaignEdit');
    console.log(selectedLoyaltyCampaignEdit);

    if (
      selectedLoyaltyCampaignEdit &&
      currOutletTaxes.length > 0 &&
      // && variationItemsDropdownList.length > 0
      taxDropdownList.length > 0
    ) {
      // insert info

      setCampaignName(selectedLoyaltyCampaignEdit.campaignName);
      setCampaignDescription(selectedLoyaltyCampaignEdit.campaignDescription);
      setIsEnableSellOnline(selectedLoyaltyCampaignEdit.isEnableSellOnline);
      setSelectedAvailability(
        selectedLoyaltyCampaignEdit.orderTypes || [
          ORDER_TYPE_DROP_DOWN_LIST[0].value,
        ],
      );
      setSelectedTargetUserGroup(selectedLoyaltyCampaignEdit.targetUserGroup);
      if (selectedLoyaltyCampaignEdit.targetSegmentGroupList) {
        setSelectedTargetSegmentGroupList(
          selectedLoyaltyCampaignEdit.targetSegmentGroupList,
        );
      }
      if (selectedLoyaltyCampaignEdit.effectiveType) {
        setSelectedEffectiveType(selectedLoyaltyCampaignEdit.effectiveType);
      } else {
        setSelectedEffectiveType(EFFECTIVE_TYPE.DAY);
      }
      if (selectedLoyaltyCampaignEdit.effectiveTypeOptions) {
        setSelectedEffectiveTypeOptions(
          selectedLoyaltyCampaignEdit.effectiveTypeOptions,
        );
      }
      setSelectedEffectiveDay(selectedLoyaltyCampaignEdit.effectiveDay);
      setEffectiveTimeStart(
        selectedLoyaltyCampaignEdit.effectiveTimeStart
          ? selectedLoyaltyCampaignEdit.effectiveTimeStart
          : moment(),
      );
      setEffectiveTimeEnd(
        selectedLoyaltyCampaignEdit.effectiveTimeEnd
          ? selectedLoyaltyCampaignEdit.effectiveTimeEnd
          : moment(),
      );

      setMinSpend(
        selectedLoyaltyCampaignEdit.minSpend
          ? selectedLoyaltyCampaignEdit.minSpend.toFixed(2)
          : '0',
      );

      setPromoCode(selectedLoyaltyCampaignEdit.promoCode);
      setIsPromoCodeUsageLimit(
        selectedLoyaltyCampaignEdit.isPromoCodeUsageLimit,
      );
      if (selectedLoyaltyCampaignEdit.promoCodeUsageLimit) {
        setPromoCodeUsageLimit(
          selectedLoyaltyCampaignEdit.promoCodeUsageLimit.toFixed(0),
        );
      }
      setPromoDateStart(selectedLoyaltyCampaignEdit.promoDateStart);
      setPromoDateEnd(selectedLoyaltyCampaignEdit.promoDateEnd);
      setPromoTimeStart(selectedLoyaltyCampaignEdit.promoTimeStart);
      setPromoTimeEnd(selectedLoyaltyCampaignEdit.promoTimeEnd);
      setImage(selectedLoyaltyCampaignEdit.image);
      setIsImageChanged(false);
      setSelectedOutletList(selectedLoyaltyCampaignEdit.outletIdList);

      setSelectedPromotionType(selectedLoyaltyCampaignEdit.promotionType);

      // setSmsText(selectedLoyaltyCampaignEdit.notificationText || '');

      if (
        selectedLoyaltyCampaignEdit.promotionType ===
        LOYALTY_PROMOTION_TYPE.COMBO_SET_OR_PACKAGE_OR_BUNDLE
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.criteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.criteriaList[i];

          setPackageItemPrice(criteria.priceBeforeTax.toFixed(2));
          setPackageMinQuantity(criteria.minQuantity.toFixed(0));
          setPackagePriceFinal(criteria.priceAfterTax);

          var currOutletTax = null;
          for (var j = 0; j < currOutletTaxes.length; j++) {
            if (criteria.taxId === currOutletTaxes[j].uniqueId) {
              currOutletTax = currOutletTaxes[j];
            }
          }

          if (!currOutletTax) {
            currOutletTax = currOutletTaxes[0];
          }

          setPackageTax(currOutletTax.uniqueId);

          setSelectedTax(currOutletTax.uniqueId);
          setSelectedTaxName(currOutletTax.name);
          setSelectedTaxRate(currOutletTax.rate);

          setSelectedVariation(criteria.variation);
          setSelectedVariationItems(criteria.variationItems);
          setSelectedVariationItemsSku(criteria.variationItemsSku);
        }
      } else if (
        selectedLoyaltyCampaignEdit.promotionType ===
        LOYALTY_PROMOTION_TYPE.BUY_A_GET_B_FOR_FREE
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.criteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.criteriaList[i];

          setBuyInput(criteria.buyAmount.toFixed(0));
          setGetInput(criteria.getAmount.toFixed(0));
          setGetPriceInput(criteria.getPrice.toFixed(2));

          setSelectedVariation(criteria.buyVariation);
          setSelectedVariationItems(criteria.buyVariationItems);
          setSelectedVariationItemsSku(criteria.buyVariationItemsSku);
          setSelectedVariationB1F1(criteria.getVariation);
          setSelectedVariationItemsB1F1(criteria.getVariationItems);
          setSelectedVariationItemsSkuB1F1(criteria.getVariationItemsSku);

          console.log('set items db');
        }
      } else if (
        selectedLoyaltyCampaignEdit.promotionType ===
        LOYALTY_PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE
      ) {
        // for (var i = 0; i < selectedLoyaltyCampaignEdit.criteriaList.length; i++) {
        //   const criteria = selectedLoyaltyCampaignEdit.criteriaList[i];

        //   setOverrideItemPrice(criteria.priceBeforeTax.toFixed(2));
        //   setOverridePriceFinal(criteria.priceAfterTax);

        //   var currOutletTax = null;
        //   for (var j = 0; j < currOutletTaxes.length; j++) {
        //     if (criteria.taxId === currOutletTaxes[j].uniqueId) {
        //       currOutletTax = currOutletTaxes[j];
        //     }
        //   }

        //   if (!currOutletTax) {
        //     currOutletTax = currOutletTaxes[0];
        //   }

        //   setOverrideTax(currOutletTax.uniqueId);

        //   setSelectedTax(currOutletTax.uniqueId);
        //   setSelectedTaxName(currOutletTax.name);
        //   setSelectedTaxRate(currOutletTax.rate);

        //   setSelectedVariation(criteria.variation);
        //   setSelectedVariationItems(criteria.variationItems);
        //   setSelectedVariationItemsSku(criteria.variationItemsSku);
        // }

        setOverrideItems(selectedLoyaltyCampaignEdit.criteriaList);
      } else if (
        selectedLoyaltyCampaignEdit.promotionType ===
        LOYALTY_PROMOTION_TYPE.TAKE_AMOUNT_OFF
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.criteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.criteriaList[i];

          setAmountOffPrice(criteria.amountOff.toFixed(2));
          setAmountOffMinQuantity(criteria.minQuantity.toFixed(0));
          setAmountOffMaxQuantity(criteria.maxQuantity.toFixed(0));

          setSelectedVariation(criteria.variation);
          setSelectedVariationItems(criteria.variationItems);
          setSelectedVariationItemsSku(criteria.variationItemsSku);

          setPriceMin(criteria.priceMin ? criteria.priceMin.toFixed(2) : '');
          setPriceMax(criteria.priceMax ? criteria.priceMax.toFixed(2) : '');
          setQuantityMin(
            criteria.quantityMin ? criteria.quantityMin.toFixed(0) : '',
          );
          setQuantityMax(
            criteria.quantityMax ? criteria.quantityMax.toFixed(0) : '',
          );
        }
      } else if (
        selectedLoyaltyCampaignEdit.promotionType ===
        LOYALTY_PROMOTION_TYPE.TAKE_PERCENTAGE_OFF
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.criteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.criteriaList[i];

          setPercentageOffPrice(criteria.percentageOff.toFixed(2));
          setPercentageOffMinQuantity(criteria.minQuantity.toFixed(0));
          setPercentageOffMaxQuantity(criteria.maxQuantity.toFixed(0));

          setSelectedVariation(criteria.variation);
          setSelectedVariationItems(criteria.variationItems);
          setSelectedVariationItemsSku(criteria.variationItemsSku);

          setPriceMin(criteria.priceMin ? criteria.priceMin.toFixed(2) : '');
          setPriceMax(criteria.priceMax ? criteria.priceMax.toFixed(2) : '');
          setQuantityMin(
            criteria.quantityMin ? criteria.quantityMin.toFixed(0) : '',
          );
          setQuantityMax(
            criteria.quantityMax ? criteria.quantityMax.toFixed(0) : '',
          );
        }
      } else if (
        selectedLoyaltyCampaignEdit.promotionType ===
        LOYALTY_PROMOTION_TYPE.DELIVERY
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.criteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.criteriaList[i];

          setDeliveryFreeFlag(criteria.deliveryFreeFlag);
          setDeliveryFreeAboveAmount(
            criteria.deliveryFreeAboveAmount.toFixed(0),
          );
          setDeliveryDiscountAmount(criteria.deliveryDiscountAmount.toFixed(0));
          setDeliveryDiscountAboveAmount(
            criteria.deliveryDiscountAboveAmount.toFixed(0),
          );
        }
      } else if (
        selectedLoyaltyCampaignEdit.promotionType ===
        LOYALTY_PROMOTION_TYPE.TAKEAWAY
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.criteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.criteriaList[i];

          setTakeawayFreeFlag(criteria.takeawayFreeFlag);
          setTakeawayFreeAboveAmount(
            criteria.takeawayFreeAboveAmount.toFixed(0),
          );
          setTakeawayDiscountAmount(criteria.takeawayDiscountAmount.toFixed(0));
          setTakeawayDiscountAboveAmount(
            criteria.takeawayDiscountAboveAmount.toFixed(0),
          );

          setSelectedVariation(criteria.variation);
          setSelectedVariationItems(criteria.variationItems);
          setSelectedVariationItemsSku(criteria.variationItemsSku);
        }
      }

      setIsEditNotification(true);

      if (selectedLoyaltyCampaignEdit.notification) {
        setNotificationTitle(selectedLoyaltyCampaignEdit.notification.title);
        setIsPushToSMS(selectedLoyaltyCampaignEdit.notification.isPushToSMS);
        setIsPushToApp(selectedLoyaltyCampaignEdit.notification.isPushToApp);
        setIsPushToEmail(
          selectedLoyaltyCampaignEdit.notification.isPushToEmail,
        );
        setNotificationDescription(
          selectedLoyaltyCampaignEdit.notification.description,
        );
        setNotificationDate(selectedLoyaltyCampaignEdit.notification.date);
        setNotificationTime(selectedLoyaltyCampaignEdit.notification.time);
        setNotificationPromoCode(
          selectedLoyaltyCampaignEdit.notification.promoCode,
        );

        setSmsText(
          selectedLoyaltyCampaignEdit.notification.description,
        );
      } else {
        setNotificationTitle('');
        setIsPushToSMS(false);
        setIsPushToApp(true);
        setIsPushToEmail(false);
        setNotificationDescription('Hi %userName%, we have sent you a voucher of a free cup of coffee, visit %outletName% to redeem now!');
        setNotificationDate(moment());
        setNotificationTime(moment());
        setNotificationPromoCode('');

        setSmsText(
          'Hi %userName%, we have sent you a voucher of a free cup of coffee, visit %outletName% to redeem now!'
        );
      }

      //////////////////////////////////////////////////////////////////

      setSelectedLoyaltyCampaignType(
        selectedLoyaltyCampaignEdit.loyaltyCampaignType,
      );

      if (
        selectedLoyaltyCampaignEdit.loyaltyCampaignType ===
        LOYALTY_CAMPAIGN_TYPE.FIRST_VISIT
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.loyaltyCriteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.loyaltyCriteriaList[i];

          setLoyaltyCampaignSendTime(criteria.loyaltyCampaignSendTime);
          setLoyaltyCampaignExpirationDays(
            criteria.loyaltyCampaignExpirationDays.toFixed(0),
          );
        }
      } else if (
        selectedLoyaltyCampaignEdit.loyaltyCampaignType ===
        LOYALTY_CAMPAIGN_TYPE.AT_RISK
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.loyaltyCriteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.loyaltyCriteriaList[i];

          setLoyaltyCampaignSendTime(criteria.loyaltyCampaignSendTime);
          setLoyaltyCampaignExpirationDays(
            criteria.loyaltyCampaignExpirationDays.toFixed(0),
          );
          setLoyaltyCampaignGuestNotVisitedDays(
            criteria.loyaltyCampaignGuestNotVisitedDays.toFixed(0),
          );
        }
      } else if (
        selectedLoyaltyCampaignEdit.loyaltyCampaignType ===
        LOYALTY_CAMPAIGN_TYPE.BIRTHDAY
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.loyaltyCriteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.loyaltyCriteriaList[i];

          setLoyaltyCampaignSendTime(criteria.loyaltyCampaignSendTime);
          setLoyaltyCampaignExpirationDays(
            criteria.loyaltyCampaignExpirationDays.toFixed(0),
          );
          setLoyaltyCampaignGuestBirthdayBeforeDays(
            criteria.loyaltyCampaignGuestBirthdayBeforeDays.toFixed(0),
          );
        }
      } else if (
        selectedLoyaltyCampaignEdit.loyaltyCampaignType ===
        LOYALTY_CAMPAIGN_TYPE.GROWTH
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.loyaltyCriteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.loyaltyCriteriaList[i];

          setLoyaltyCampaignSendTime(criteria.loyaltyCampaignSendTime);
          setLoyaltyCampaignExpirationDays(
            criteria.loyaltyCampaignExpirationDays.toFixed(0),
          );
          setLoyaltyCampaignGuestAfterEveryVisits(
            criteria.loyaltyCampaignGuestAfterEveryVisits.toFixed(0),
          );
        }
      } else if (
        selectedLoyaltyCampaignEdit.loyaltyCampaignType ===
        LOYALTY_CAMPAIGN_TYPE.BIG_SPENDER
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.loyaltyCriteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.loyaltyCriteriaList[i];

          setLoyaltyCampaignSendTime(criteria.loyaltyCampaignSendTime);
          setLoyaltyCampaignExpirationDays(
            criteria.loyaltyCampaignExpirationDays.toFixed(0),
          );
          setLoyaltyCampaignGuestAfterEverySpends(
            criteria.loyaltyCampaignGuestAfterEverySpends.toFixed(2),
          );
        }
      } else if (
        selectedLoyaltyCampaignEdit.loyaltyCampaignType ===
        LOYALTY_CAMPAIGN_TYPE.SIGN_UP
      ) {
        for (
          var i = 0;
          i < selectedLoyaltyCampaignEdit.loyaltyCriteriaList.length;
          i++
        ) {
          const criteria = selectedLoyaltyCampaignEdit.loyaltyCriteriaList[i];

          setLoyaltyCampaignSendTime(criteria.loyaltyCampaignSendTime);
          setLoyaltyCampaignExpirationDays(
            criteria.loyaltyCampaignExpirationDays.toFixed(0),
          );
        }
      }

      //////////////////////////////////////////////////////////////////

      // var selectedOutletListTemp = [];
      // if (allOutletsItemsSkuDict[selectedLoyaltyCampaignEdit.sku]) {
      //   for (var i = 0; i < allOutletsItemsSkuDict[selectedLoyaltyCampaignEdit.sku].length; i++) {
      //     selectedOutletListTemp.push(allOutletsItemsSkuDict[selectedLoyaltyCampaignEdit.sku][i].outletId);
      //   }
      // }
      // setSelectedOutletList(selectedOutletListTemp);

      // setPlace(selectedLoyaltyCampaignEdit.outletId);
      // setItemName(selectedLoyaltyCampaignEdit.name);
      // setItemDescription(selectedLoyaltyCampaignEdit.description);
      // setImage(selectedLoyaltyCampaignEdit.image);
      // setItemPrice(selectedLoyaltyCampaignEdit.price.toFixed(2));

      // setSelectedOutletCategoryId(selectedLoyaltyCampaignEdit.categoryId);

      // if (selectedLoyaltyCampaignEdit.stockLinkItems) {
      //   setStockLinkItems(selectedLoyaltyCampaignEdit.stockLinkItems);
      // }
    } else {
      // designed to always mounted, thus need clear manually...

      setCampaignName('');
      setCampaignDescription('');
      setIsEnableSellOnline(false);
      setSelectedAvailability([]);
      setSelectedTargetUserGroup(TARGET_USER_GROUP_DROPDOWN_LIST[0].value);
      setSelectedTargetSegmentGroupList([]);
      setSelectedEffectiveType(EFFECTIVE_TYPE.DAY);
      setSelectedEffectiveTypeOptions([]);
      setSelectedEffectiveDay(EFFECTIVE_DAY_DROPDOWN_LIST[0].value);
      setPromoCode('');
      setIsPromoCodeUsageLimit(false);
      setPromoCodeUsageLimit('');
      setPromoDateStart(moment());
      setPromoDateEnd(moment());
      setPromoTimeStart(moment());
      setPromoTimeEnd(moment());
      setImage('');
      clearImageContainer();
      setIsImageChanged(false);
      setSelectedOutletList([]);

      setSelectedPromotionType(LOYALTY_PROMOTION_TYPE_DROPDOWN_LIST[0].value);

      // setSmsText('');

      setPackageItemPrice('0');
      setPackageMinQuantity('0');
      setPackagePriceFinal(0);

      setOverrideItemPrice('0');
      setOverridePriceFinal(0);

      if (currOutletTaxes.length > 0) {
        setSelectedTax(currOutletTaxes[0].uniqueId);
        setSelectedTaxName(currOutletTaxes[0].name);
        setSelectedTaxRate(currOutletTaxes[0].rate);

        setPackageTax(currOutletTaxes[0].rate);
        setOverrideTax(currOutletTaxes[0].rate);
      } else {
        setSelectedTax('');
        setSelectedTaxName('');
        setSelectedTaxRate(0);

        setPackageTax('');
        setOverrideTax('');
      }

      setPriceMin('');
      setPriceMax('');
      setQuantityMin('');
      setQuantityMax('');

      // setSelectedVariation(PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS);
      // setSelectedVariationItems([]);
      // setSelectedVariationItemsSku([]);

      setOverrideItems([
        {
          priceBeforeTax: 0,
          variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
          variationItems: [],
          variationItemsSku: [],
        },
      ]);

      //////////////////////////////////////////////

      setSelectedVariationB1F1(PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS);
      setSelectedVariationItemsB1F1([]);
      setSelectedVariationItemsSkuB1F1([]);

      //////////////////////////////////////////////

      setIsEditNotification(true);
      setNotificationTitle('');
      setIsPushToSMS(false);
      setIsPushToApp(true);
      setIsPushToEmail(false);
      setNotificationDescription('Hi %userName%, we have sent you a voucher of a free cup of coffee, visit %outletName% to redeem now!');
      setNotificationDate(moment());
      setNotificationTime(moment());
      setNotificationPromoCode('');

      setSmsText('Hi %userName%, we have sent you a voucher of a free cup of coffee, visit %outletName% to redeem now!');

      //////////////////////////////////////////////

      setSelectedLoyaltyCampaignType(LOYALTY_CAMPAIGN_DROPDOWN_LIST[0].value);
      setLoyaltyCampaignSendTime(moment(Date.now()).format("hh:mm A"));
      setLoyaltyCampaignExpirationDays('14');
      setLoyaltyCampaignGuestNotVisitedDays('30');
      setLoyaltyCampaignGuestBirthdayBeforeDays('7');
      setLoyaltyCampaignGuestAfterEveryVisits('10');
      setLoyaltyCampaignGuestAfterEverySpends('3000');

      //////////////////////////////////////////////

      // setSelectedOutletList([]);

      // setPlace('');
      // setItemSKU('');
      // setItemName('');
      // setItemDescription('');
      // setImage('');
      // setItemPrice('0.00');

      // if (outletSupplyItems.length > 0) {
      //   setStockLinkItems([
      //     {
      //       outletSupplyItemId: outletSupplyItems[0].uniqueId,
      //       sku: outletSupplyItems[0].sku,
      //       name: outletSupplyItems[0].name,
      //       unit: outletSupplyItems[0].unit,
      //       quantityUsage: 0,
      //     }
      //   ]);

      // }
      // else {
      //   setStockLinkItems([
      //     {
      //       outletSupplyItemId: '',
      //       sku: '',
      //       name: '',
      //       unit: '',
      //       quantityUsage: 0,
      //     }
      //   ]);

      // }

      // setIsImageChanged(false);

      // setVariantGroupList([]);
      // setAddOnGroupList([]);
    }
  }, [
    selectedLoyaltyCampaignEdit,
    currOutletTaxes,
    // variationItemsDropdownList,
    taxDropdownList,
  ]);

  useEffect(() => {
    console.log('crmUserTagsDropdownList');
    console.log(
      TARGET_USER_GROUP_DROPDOWN_LIST.concat(
        crmUserTags.map((item) => ({ label: item.name, value: item.uniqueId })),
      ),
    );

    setCrmUserTagsDropdownList(
      TARGET_USER_GROUP_DROPDOWN_LIST.concat(
        crmUserTags.map((item) => ({ label: item.name, value: item.uniqueId })),
      ),
    );
  }, [crmUserTags]);

  useEffect(() => {
    setCrmSegmentsDropdownList(
      crmSegments.map((segment) => ({
        label: segment.name,
        value: segment.uniqueId,
      })),
    );
  }, [crmSegments]);

  useEffect(() => {
    if (
      selectedPromotionType ===
      LOYALTY_PROMOTION_TYPE.COMBO_SET_OR_PACKAGE_OR_BUNDLE &&
      packageItemPrice.length > 0 &&
      parseFloat(packageItemPrice) >= 0
    ) {
      const packageItemPriceFloat = parseFloat(packageItemPrice);

      setPackagePriceFinal(
        packageItemPriceFloat + packageItemPriceFloat * selectedTaxRate,
      );
    } else if (
      selectedPromotionType ===
      LOYALTY_PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE &&
      overrideItemPrice.length > 0 &&
      parseFloat(overrideItemPrice) >= 0
    ) {
      const overrideItemPriceFloat = parseFloat(overrideItemPrice);

      setOverridePriceFinal(
        overrideItemPriceFloat + overrideItemPriceFloat * selectedTaxRate,
      );
    }
  }, [
    selectedPromotionType,

    packageItemPrice,
    overrideItemPrice,

    selectedTaxRate,
  ]);

  /////////////////////////////////////////////////////////////

  useEffect(() => {
    if (currOutletTaxes.length > 0) {
      setTaxDropdownList(
        currOutletTaxes.map((item) => ({
          label: item.name,
          value: item.uniqueId,
        })),
      );

      if (selectedTax === '') {
        setSelectedTax(currOutletTaxes[0].uniqueId);

        for (var i = 0; i < currOutletTaxes.length; i++) {
          if (currOutletTaxes[i].uniqueId === currOutletTaxes[0].uniqueId) {
            setSelectedTaxName(currOutletTaxes[i].name);
            setSelectedTaxRate(currOutletTaxes[i].rate);
            break;
          }
        }
      }
    }
  }, [currOutletTaxes, currOutletId]);

  ////////////////////////////////////////////////////////
  // useEffect(() => {
  //   //var selectedVariationItemsSkuTemp = [];
  //   var selectedVoucherTemp = [];

  //   // if (selectedVariation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
  //   //   for (var i = 0; i < outletItems.length; i++) {
  //   //     if (selectedVariationItems.includes(outletItems[i].uniqueId)) {
  //   //       selectedVariationItemsSkuTemp.push(outletItems[i].sku);
  //   //     }
  //   //   }
  //   // }
  //   // else if (selectedVariation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
  //   //   for (var i = 0; i < allOutletsCategoriesUnique.length; i++) {
  //   //     if (selectedVariationItems.includes(allOutletsCategoriesUnique[i].uniqueId)) {
  //   //       selectedVariationItemsSkuTemp.push(allOutletsCategoriesUnique[i].name);
  //   //     }
  //   //   }
  //   // }

  //   for (var i=0; i < merchantVouchers.length; i++){
  //     if (selectedVoucherItems.includes(merchantVouchers[i].uniqueId)){
  //       selectedVoucherTemp.push(merchantVouchers[i].name);
  //     }
  //   }

  //   setSelectedVoucher(selectedVoucherTemp);
  // }, [
  //   selectedVoucher,
  //   merchantVouchers,
  // ]);

  ////////////////////////////////////////

  // change when pick better

  useEffect(() => {
    // var overrideItemsSkuArrayChangesList = [];

    var isDiff = false;

    var overrideItemsTemp = [];

    for (var index = 0; index < overrideItems.length; index++) {
      var skuArray = [];

      if (
        overrideItems[index].variation ===
        PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
      ) {
        for (var i = 0; i < outletItems.length; i++) {
          if (
            overrideItems[index].variationItems.includes(
              outletItems[i].uniqueId,
            )
          ) {
            skuArray.push(outletItems[i].sku);
          }
        }
      } else if (
        overrideItems[index].variation ===
        PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
      ) {
        for (var i = 0; i < allOutletsCategoriesUnique.length; i++) {
          if (
            overrideItems[index].variationItems.includes(
              allOutletsCategoriesUnique[i].uniqueId,
            )
          ) {
            skuArray.push(allOutletsCategoriesUnique[i].name);
          }
        }
      }

      isDiff = !areArraysEqual(
        skuArray,
        overrideItems[index].variationItemsSku,
      );

      var overrideItemTemp = {
        priceBeforeTax: overrideItems[index].priceBeforeTax,
        variation: overrideItems[index].variation,
        variationItems: overrideItems[index].variationItems,
        variationItemsSku: skuArray,
      };

      overrideItemsTemp.push(overrideItemTemp);

      // if (isDiff) {

      //   // setOverrideItems(overrideItems.map((overrideItem, j) => (j === i ? {
      //   //   ...overrideItem,
      //   //   variationItemsSku: skuArray,
      //   // } : overrideItem)));

      //   // break;
      // }
    }

    if (isDiff) {
      setOverrideItems(overrideItemsTemp);
    }

    // setSelectedVariationItemsSku(selectedVariationItemsSkuTemp);
  }, [
    // selectedVariation,

    // selectedVariationItems,

    overrideItems,

    outletItems,

    allOutletsCategoriesUnique,
  ]);

  useEffect(() => {
    var selectedVariationItemsSkuTemp = [];

    if (selectedVariation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
      for (var i = 0; i < outletItems.length; i++) {
        if (selectedVariationItems.includes(outletItems[i].uniqueId)) {
          selectedVariationItemsSkuTemp.push(outletItems[i].sku);
        }
      }
    } else if (
      selectedVariation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
    ) {
      for (var i = 0; i < allOutletsCategoriesUnique.length; i++) {
        if (
          selectedVariationItems.includes(
            allOutletsCategoriesUnique[i].uniqueId,
          )
        ) {
          selectedVariationItemsSkuTemp.push(
            allOutletsCategoriesUnique[i].name,
          );
        }
      }
    }

    setSelectedVariationItemsSku(selectedVariationItemsSkuTemp);
  }, [
    selectedVariation,

    selectedVariationItems,
    outletItems,

    allOutletsCategoriesUnique,
  ]);

  useEffect(() => {
    var selectedVariationItemsSkuB1F1Temp = [];

    if (selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
      for (var i = 0; i < outletItems.length; i++) {
        if (selectedVariationItemsB1F1.includes(outletItems[i].uniqueId)) {
          selectedVariationItemsSkuB1F1Temp.push(outletItems[i].sku);
        }
      }
    } else if (
      selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
    ) {
      for (var i = 0; i < allOutletsCategoriesUnique.length; i++) {
        if (
          selectedVariationItemsB1F1.includes(
            allOutletsCategoriesUnique[i].uniqueId,
          )
        ) {
          selectedVariationItemsSkuB1F1Temp.push(
            allOutletsCategoriesUnique[i].name,
          );
        }
      }
    }

    setSelectedVariationItemsSkuB1F1(selectedVariationItemsSkuB1F1Temp);
  }, [
    selectedVariationB1F1,

    selectedVariationItemsB1F1,
    outletItems,

    allOutletsCategoriesUnique,
  ]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    if (selectedVariation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
      setVariationItemsDropdownList(
        allOutletsCategoriesUnique.map((item) => ({
          label: item.name,
          value: item.uniqueId,
        })),
      );

      if (
        allOutletsCategoriesUnique.length > 0 &&
        !selectedLoyaltyCampaignEdit
      ) {
        // setSelectedVariationItems([allOutletsCategoriesUnique[0].uniqueId]);
      } else {
        // if (selectedVariationItems.length > 0 && variationItemsDropdownList.find(item => item.value === selectedVariationItems[0]) === null) {
        //   console.log('clear items');
        //   setSelectedVariationItems([]);
        // }
      }
    } else if (
      selectedVariation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
    ) {
      setVariationItemsDropdownList(
        outletItems.map((item) => ({ label: item.name, value: item.uniqueId })),
      );

      if (outletItems.length > 0 && !selectedLoyaltyCampaignEdit) {
        // setSelectedVariationItems([outletItems[0].uniqueId]);
      } else {
        // if (selectedVariationItems.length > 0 && variationItemsDropdownList.find(item => item.value === selectedVariationItems[0]) === null) {
        //   setSelectedVariationItems([]);
        // }
      }
    }

    if (
      selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
    ) {
      setVariationItemsB1F1DropdownList(
        allOutletsCategoriesUnique.map((item) => ({
          label: item.name,
          value: item.uniqueId,
        })),
      );

      if (
        allOutletsCategoriesUnique.length > 0 &&
        !selectedLoyaltyCampaignEdit
      ) {
        // setSelectedVariationItemsB1F1([allOutletsCategoriesUnique[0].uniqueId]);
      } else {
        // if (selectedVariationItemsB1F1.length > 0 && variationItemsB1F1DropdownList.find(item => item.value === selectedVariationItemsB1F1[0]) === null) {
        //   setSelectedVariationItemsB1F1([]);
        // }
      }
    } else if (
      selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
    ) {
      setVariationItemsB1F1DropdownList(
        outletItems.map((item) => ({ label: item.name, value: item.uniqueId })),
      );

      if (outletItems.length > 0 && !selectedLoyaltyCampaignEdit) {
        // setSelectedVariationItemsB1F1([outletItems[0].uniqueId]);
      } else {
        // if (selectedVariationItemsB1F1.length > 0 && variationItemsB1F1DropdownList.find(item => item.value === selectedVariationItemsB1F1[0]) === null) {
        //   setSelectedVariationItemsB1F1([]);
        // }
      }
    }

    setVariationItemsProducts(
      outletItems.map((item) => ({ label: item.name, value: item.uniqueId })),
    );
    setVariationItemsCategories(
      allOutletsCategoriesUnique.map((item) => ({
        label: item.name,
        value: item.uniqueId,
      })),
    );
  }, [
    outletItems,
    // outletCategories,
    allOutletsCategoriesUnique,

    selectedVariation,
    selectedVariationB1F1,

    selectedVariationItems,
    selectedVariationItemsB1F1,

    selectedLoyaltyCampaignEdit,
  ]);

  ////////////////////////////////////////////////////////

  // to solve product not show

  useEffect(() => {
    // if (
    //   selectedVariationItemsB1F1.length > 0 &&
    //   variationItemsB1F1DropdownList.find(
    //     (item) => item.value === selectedVariationItemsB1F1[0],
    //   ) === undefined
    // ) {
    //   // setSelectedVariationItemsB1F1(variationItemsB1F1DropdownList.length > 0 ? [variationItemsB1F1DropdownList[0].uniqueId] : []);
    //   setSelectedVariationItemsB1F1([]);
    // }

    // console.log('condition');
    // console.log(selectedVariationItems.length > 0);
    // console.log(
    //   variationItemsDropdownList.find(
    //     (item) => item.value === selectedVariationItems[0],
    //   ),
    // );

    if (selectedVariationItemsB1F1.length > 0 &&
      selectedVariationItemsB1F1.filter(itemId => {
        return variationItemsB1F1DropdownList.find(item2 => item2.value === itemId) ? true : false;
      }).length !== selectedVariationItemsB1F1.length

    ) {
      // setSelectedVariationItemsB1F1(variationItemsB1F1DropdownList.length > 0 ? [variationItemsB1F1DropdownList[0].uniqueId] : []);
      setSelectedVariationItemsB1F1([]);
    }

    // if (
    //   selectedVariationItems.length > 0 &&
    //   variationItemsDropdownList.find(
    //     (item) => item.value === selectedVariationItems[0],
    //   ) === undefined
    // ) {
    //   // setSelectedVariationItems(variationItemsDropdownList.length > 0 ? [variationItemsDropdownList[0].uniqueId] : []);
    //   setSelectedVariationItems([]);
    // }

    if (selectedVariationItems.length > 0 &&
      selectedVariationItems.filter(itemId => {
        return variationItemsDropdownList.find(item2 => item2.value === itemId) ? true : false;
      }).length !== selectedVariationItems.length

    ) {
      // setSelectedVariationItems(variationItemsDropdownList.length > 0 ? [variationItemsDropdownList[0].uniqueId] : []);
      setSelectedVariationItems([]);
    }

    var overrideItemsTemp = [];

    for (var i = 0; i < overrideItems.length; i++) {
      var overrideItemTemp = {
        priceBeforeTax: overrideItems[i].priceBeforeTax,
        variation: overrideItems[i].variation,
        variationItems: overrideItems[i].variationItems,
        variationItemsSku: overrideItems[i].variationItemsSku,
      };

      if (
        overrideItems[i].variationItems.length > 0 &&
        variationItemsDropdownList.find(
          (item) => item.value === overrideItems[i].variationItems[0],
        ) === undefined
      ) {
        overrideItemTemp.variationItems = [];
      }

      overrideItemsTemp.push(overrideItemTemp);
    }

    setOverrideItems(overrideItemsTemp);
  }, [variationItemsDropdownList, variationItemsB1F1DropdownList]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    var outletItemsTemp = [...outletItemsUnsorted];

    outletItemsTemp.sort((a, b) => a.name.localeCompare(b.name));

    setOutletItems(outletItemsTemp);
  }, [outletItemsUnsorted]);

  useEffect(() => {
    var outletCategoriesTemp = [...outletCategoriesUnsorted];

    outletCategoriesTemp.sort((a, b) => a.name.localeCompare(b.name));

    setOutletCategories(outletCategoriesTemp);
  }, [outletCategoriesUnsorted]);

  useEffect(() => {
    setOutletDropdownList(
      allOutlets.map((item) => ({
        label: item.name,
        value: item.uniqueId,
      })),
    );
  }, [allOutlets]);

  // useEffect(() => {
  //   setAvailabilityDropdownList(ORDER_TYPE.map(item => ({
  //     label: item.name, value: item.uniqueId,
  //   })))
  // }, [ORDER_TYPE])

  useEffect(() => {
    setPackageTaxDropdownList();
  }, []);

  useEffect(() => {
    setPackageProductDropdownList();
  }, []);

  useEffect(() => {
    setPackageCategoryDropdownList();
  }, []);

  /////////

  useEffect(() => {
    setOverrideTaxDropdownList();
  }, []);

  useEffect(() => {
    setOverrideProductDropdownList();
  }, []);

  useEffect(() => {
    setOverrideCategoryDropdownList();
  }, []);

  ////////

  useEffect(() => {
    setCashbackExpiredDropdownList();
  }, []);

  useEffect(() => {
    setCashbackProductDropdownList();
  }, []);

  useEffect(() => {
    setCashbackCategoryDropdownList();
  }, []);

  ////////

  useEffect(() => {
    setBuy1Free1ProductDropdownList();
  }, []);

  ///////

  useEffect(() => {
    setAmountOffProductDropdownList();
  }, []);

  useEffect(() => {
    setAmountOffCategoryDropdownList();
  }, []);

  ////////

  useEffect(() => {
    setPercentageOffProductDropdownList();
  }, []);

  useEffect(() => {
    setPercentageOffCategoryDropdownList();
  }, []);

  ///////

  useEffect(() => {
    var promotionsNotificationsTemp = [];

    for (var i = 0; i < loyaltyCampaigns.length; i++) {
      if (loyaltyCampaigns[i].notification) {
        var pushToTargetArr = [];

        if (loyaltyCampaigns[i].notification.isPushToSMS) {
          pushToTargetArr.push('SMS');
        }

        // if (loyaltyCampaigns[i].notification.isPushToEmail) {
        //   pushToTargetArr.push('Email');
        // }

        if (loyaltyCampaigns[i].notification.isPushToApp) {
          pushToTargetArr.push('App');
        }

        promotionsNotificationsTemp.push({
          ...loyaltyCampaigns[i],
          notification: {
            ...loyaltyCampaigns[i].notification,
            pushTargets: pushToTargetArr.join('/'),
          },
        });
      }
    }

    setPromotionsNotifications(promotionsNotificationsTemp);

    // setCurrentPage(1);
    // setPageCount(Math.ceil(promotionsNotificationsTemp.length / perPage));
  }, [loyaltyCampaigns]);

  ///////

  //To remove unwanted sidebar
  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  //Header
  navigation.setOptions({
    headerLeft: () => (
      <View style={[styles.headerLeftStyle, {width: windowWidth * 0.17}]}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            // bottom: -2,
            bottom: switchMerchant ? '2%' : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? { right: windowWidth * 0.1 }
            : {},
          windowWidth <= 768
            ? { right: windowWidth * 0.12 }
            : {},
        ]}>
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Loyalty Campaign
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: 'white',
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}></View>
        <TouchableOpacity
          onPress={
            () => {
            // navigation.navigate('Setting')
            linkTo && linkTo(`${prefix}/settingsscreen`)
          }}
          style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}>
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: 'center',
              }}
              source={require('../assets/image/profile-pic.jpg')}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  /////////////////////////////////////////////////
  const [openFileSelector, { plainFiles, filesContent, loading: loadingImageInput, clear: clearImageContainer, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
  });

  useEffect(() => {
    console.log(plainFiles, filesContent, loadingImageInput)

    // display the image when it is finish loaded
    if (plainFiles.length && filesContent.length && !loadingImageInput) {
      // image in base64 filecontent
      setImage(filesContent[0].content);
      setImageType(filesContent[0].name.slice(filesContent[0].name.lastIndexOf('.')));
      setIsImageChanged(true);

    }

    if(errors.length)
      console.error(errors)

  }, [plainFiles, filesContent, loadingImageInput, errors])
  
  // const handleChoosePhoto = () => {
  //   const imagePickerOptions = {
  //     mediaType: 'photo',
  //     quality: 0.5,
  //     includeBase64: false,
  //   };

  //   launchImageLibrary(imagePickerOptions, (response) => {
  //     if (response.didCancel) {
  //     } else if (response.error) {
  //       window.confirm(response.error.toString());
  //     } else {
  //       // setState({ image: response.uri });
  //       setImage(response.uri);
  //       setImageType(response.uri.slice(response.uri.lastIndexOf('.')));

  //       setIsImageChanged(true);
  //     }
  //   });
  // };

  const canOnlyPushOnce = () => {
    window.confirm(
      'Notice',
      'Each promotion can only be pushed once. Are you sure you want to proceed?',
      [
        {
          text: 'Push',
          onPress: () => {
            createPromotion(true);
          },
        },
        {
          text: 'Cancel',
          onPress: () => { },
        },
      ],
    );
  };

  const createPromotion = async (isAutoPush = false) => {
    var message = '';

    if (!campaignName) {
      message += 'Campaign name must be filled\n';
    }
    // if ( !promoCode ){
    //   message += 'Promo code must be filled\n'
    // }
    if (selectedAvailability.length <= 0) {
      message += 'Availability must be selected\n';
    }
    if (!campaignDescription) {
      message += 'Campaign description must be filled\n';
    }
    // if (!image) {
    //   message += 'Campaign image must be selected\n';
    // }
    if (selectedOutletList.length <= 0) {
      message += 'Outlet(s) must be selected\n';
    }
    if (smsText.length <= 0) {
      message += 'Notification/SMS message must be filled\n'
    }

    if (message.length > 0) {
      window.confirm('Info\n' + message);

      return;
    } else {
      // if (isPromoCodeUsageLimit) {
      //   if (!promoCodeUsageLimit) {
      //     window.confirm(
      //       'Error',
      //       'Please fill in promo code usage',
      //       [{ text: 'OK', onPress: () => { } }],
      //       { cancelable: false },
      //     );
      //     return;
      //   }
      //   else if (parseInt(promoCodeUsageLimit) <= 0) {
      //     window.confirm(
      //       'Error',
      //       'Promo code usage must be more than 0',
      //       [{ text: 'OK', onPress: () => { } }],
      //       { cancelable: false },
      //     );
      //     return;
      //   }
      // }

      // if (moment(promoDateEnd).isSameOrBefore(moment(promoDateStart))) {
      //   window.confirm(
      //     'Error',
      //     'Promo start date must be before the Promo end date',
      //     [{ text: 'OK', onPress: () => { } }],
      //     { cancelable: false },
      //   );
      //   return;
      // }
      // else if (moment(promoDateEnd).isSame(moment(promoDateStart), 'day')) {
      //   window.confirm(
      //     'Error',
      //     'Promo start date and Promo end date cannot be on the same day',
      //     [{ text: 'OK', onPress: () => { } }],
      //     { cancelable: false },
      //   );
      //   return;
      // }

      var notification = {};

      if (
        // isEditNotification
        true
      ) {
        var atLeastOnePush = false;

        if (isPushToSMS || isPushToApp || isPushToEmail) {
          atLeastOnePush = true;
        }

        if (!atLeastOnePush) {
          window.confirm(
            'Error\nPlease select at least one push to target',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (
          // !notificationTitle ||
          // !notificationDescription
          !smsText
        ) {
          window.confirm(
            'Error\nPlease fill in all required information:\nNotification/SMS Message',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        notification = {
          title: notificationTitle || '',
          description: smsText,
          // promoCode: notificationPromoCode,
          isPushToApp: isPushToApp || true,
          isPushToEmail: isPushToEmail || false,
          isPushToSMS: isPushToSMS || false,
          date: moment(notificationDate).valueOf(),
          time: moment(notificationTime).valueOf(),
          isAutoPush: false,
        };
      }

      ////////////////////////////////////////////////////////////////

      var criteriaList = [];

      if (
        selectedPromotionType ===
        LOYALTY_PROMOTION_TYPE.COMBO_SET_OR_PACKAGE_OR_BUNDLE
      ) {
        if (!packageItemPrice || !packageMinQuantity) {
          window.confirm(
            'Error\nPlease fill in all required information:\nPackage Item Price\nPackage Min Quantity',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (
          parseFloat(packageItemPrice) <= 0 ||
          parseInt(packageMinQuantity) <= 0
        ) {
          window.confirm(
            'Error\nPackage Item Price and Package Min Quantity must be more than 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (selectedVariationItems.length <= 0) {
          window.confirm(
            'Error\nPlease select at least one product',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          priceBeforeTax: +parseFloat(packageItemPrice).toFixed(2),
          minQuantity: parseInt(packageMinQuantity),
          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,
          priceAfterTax: +parseFloat(packagePriceFinal).toFixed(2),
          taxId: packageTax,
        });
      } else if (
        selectedPromotionType === LOYALTY_PROMOTION_TYPE.BUY_A_GET_B_FOR_FREE
      ) {
        if (!buyInput || !getInput || getPriceInput === '') {
          window.confirm(
            'Error\nPlease fill in all required information:\nBuy amount\nGet amount\nGet price',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (parseInt(buyInput) <= 0 || parseInt(getInput) <= 0) {
          window.confirm(
            'Error\nBuy amount and Get amount must be more than 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (parseFloat(getPriceInput) < 0) {
          window.confirm(
            'Error\nGet price must be more or equal to 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (
          selectedVariationItems.length <= 0 ||
          selectedVariationItemsB1F1.length <= 0
        ) {
          window.confirm(
            'Error\nPlease select at least one product for Buy and Get',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          // buyAmount: parseFloat(buyInput),
          buyAmount: parseInt(buyInput),
          getAmount: parseInt(getInput),
          getPrice: parseInt(getPriceInput),
          buyVariation: selectedVariation,
          buyVariationItems: selectedVariationItems,
          buyVariationItemsSku: selectedVariationItemsSku,
          getVariation: selectedVariationB1F1,
          getVariationItems: selectedVariationItemsB1F1,
          getVariationItemsSku: selectedVariationItemsSkuB1F1,
        });
      } else if (
        selectedPromotionType === LOYALTY_PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE
      ) {
        // if (!overrideItemPrice) {
        //   window.confirm(
        //     'Error',
        //     'Please fill up all required information:\nOverride Item Price',
        //     [{ text: 'OK', onPress: () => { } }],
        //     { cancelable: false },
        //   );
        //   return;
        // }

        // if (parseFloat(overrideItemPrice) <= 0) {
        //   window.confirm(
        //     'Error',
        //     'Override item price must be greater than 0.',
        //     [{ text: 'OK', onPress: () => { } }],
        //     { cancelable: false },
        //   );
        //   return;
        // }

        // if (selectedVariationItems.length <= 0) {
        //   window.confirm(
        //     'Error',
        //     'Please select at least one product for override product.',
        //     [{ text: 'OK', onPress: () => { } }],
        //     { cancelable: false },
        //   );
        //   return;
        // }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        // criteriaList.push({
        //   priceBeforeTax: +parseFloat(overrideItemPrice).toFixed(2),
        //   variation: selectedVariation,
        //   variationItems: selectedVariationItems,
        //   variationItemsSku: selectedVariationItemsSku,
        //   priceAfterTax: +parseFloat(overridePriceFinal).toFixed(2),
        //   taxId: overrideTax,

        //   quantityMin: quantityMin.length > 0 ? parseInt(quantityMin) : null,
        //   quantityMax: quantityMax.length > 0 ? parseInt(quantityMax) : null,
        //   priceMin: priceMin.length > 0 ? parseFloat(priceMin) : null,
        //   priceMax: priceMax.length > 0 ? parseFloat(priceMax) : null,
        // });

        criteriaList = overrideItems;
      } else if (
        selectedPromotionType === LOYALTY_PROMOTION_TYPE.TAKE_AMOUNT_OFF
      ) {
        if (
          !amountOffPrice ||
          // !amountOffMinQuantity ||
          // !amountOffMaxQuantity
          quantityMin.length === 0 ||
          quantityMax.length === 0 ||
          priceMin.length === 0
          // priceMax.length === 0
        ) {
          window.confirm(
            'Error\nPlease fill in all required information:\nOff Amount\nMin Quantity\nMax Quantity\nMin Amount',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (
          parseFloat(amountOffPrice) <= 0 ||
          parseInt(quantityMin) <= 0 ||
          parseInt(quantityMax) <= 0
        ) {
          window.confirm(
            'Error\nOff amount, Min quantity, and Max quantity must be more than 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (parseFloat(amountOffPrice) >= parseInt(priceMin)) {
          window.confirm(
            'Error\nOff Amount must be more than Min Amount',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (selectedVariationItems.length <= 0) {
          window.confirm(
            'Error\nPlease select at least one product',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          amountOff: +parseFloat(amountOffPrice).toFixed(2),
          minQuantity: parseInt(amountOffMinQuantity),
          maxQuantity: parseInt(amountOffMaxQuantity),
          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,

          quantityMin: quantityMin.length > 0 ? parseInt(quantityMin) : null,
          quantityMax: quantityMax.length > 0 ? parseInt(quantityMax) : null,
          priceMin: priceMin.length > 0 ? parseFloat(priceMin) : null,
          priceMax: priceMax.length > 0 ? parseFloat(priceMax) : null,
        });
      } else if (
        selectedPromotionType === LOYALTY_PROMOTION_TYPE.TAKE_PERCENTAGE_OFF
      ) {
        if (
          !percentageOffPrice ||
          // !percentageOffMinQuantity ||
          // !percentageOffMaxQuantity
          quantityMin.length === 0 ||
          quantityMax.length === 0 ||
          priceMin.length === 0
          // priceMax.length === 0
        ) {
          window.confirm(
            'Error\nPlease fill up all required information:\nOff Percentage\nMin Quantity\nMax Quantity\nMin Amount',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (
          parseFloat(percentageOffPrice) <= 0 ||
          parseInt(quantityMin) <= 0 ||
          parseInt(quantityMax) <= 0
        ) {
          window.confirm(
            'Error\nOff percentage, Min quantity, and Max quantity must be more than 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (selectedVariationItems.length <= 0) {
          window.confirm(
            'Error\nPlease select at least one product',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          percentageOff: +parseFloat(percentageOffPrice).toFixed(0),
          minQuantity: parseInt(percentageOffMinQuantity),
          maxQuantity: parseInt(percentageOffMaxQuantity),
          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,

          quantityMin: quantityMin.length > 0 ? parseInt(quantityMin) : null,
          quantityMax: quantityMax.length > 0 ? parseInt(quantityMax) : null,
          priceMin: priceMin.length > 0 ? parseFloat(priceMin) : null,
          priceMax: priceMax.length > 0 ? parseFloat(priceMax) : null,
        });
      } else if (selectedPromotionType === LOYALTY_PROMOTION_TYPE.DELIVERY) {
        // if (!amountOffPrice ||
        //   // !amountOffMinQuantity ||
        //   // !amountOffMaxQuantity
        //   quantityMin.length === 0 ||
        //   quantityMax.length === 0 ||
        //   priceMin.length === 0 ||
        //   priceMax.length === 0
        // ) {
        //   window.confirm(
        //     'Error',
        //     'Please fill up all required information:\nOff Amount\nMin Quantity\nMax Quantity\nMin Amount\nMax Amount',
        //     [{ text: 'OK', onPress: () => { } }],
        //     { cancelable: false },
        //   );
        //   return;
        // }

        if (
          parseFloat(deliveryFreeAboveAmount) < 0 ||
          parseInt(deliveryDiscountAmount) < 0 ||
          parseInt(deliveryDiscountAboveAmount) < 0
        ) {
          window.confirm(
            'Error\nFree delivery above, Discount, and Order above must be more or equal to 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (selectedVariationItems.length <= 0) {
          window.confirm(
            'Error\nPlease select at least one product',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          deliveryFreeFlag: deliveryFreeFlag,
          deliveryFreeAboveAmount: +parseFloat(deliveryFreeAboveAmount).toFixed(
            2,
          ),
          deliveryDiscountAmount: +parseFloat(deliveryDiscountAmount).toFixed(
            2,
          ),
          deliveryDiscountAboveAmount: +parseFloat(
            deliveryDiscountAboveAmount,
          ).toFixed(2),
          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,
        });
      } else if (selectedPromotionType === LOYALTY_PROMOTION_TYPE.TAKEAWAY) {
        // if (!amountOffPrice ||
        //   // !amountOffMinQuantity ||
        //   // !amountOffMaxQuantity
        //   quantityMin.length === 0 ||
        //   quantityMax.length === 0 ||
        //   priceMin.length === 0 ||
        //   priceMax.length === 0
        // ) {
        //   window.confirm(
        //     'Error',
        //     'Please fill up all required information:\nOff Amount\nMin Quantity\nMax Quantity\nMin Amount\nMax Amount',
        //     [{ text: 'OK', onPress: () => { } }],
        //     { cancelable: false },
        //   );
        //   return;
        // }

        if (
          parseFloat(takeawayFreeAboveAmount) < 0 ||
          parseInt(takeawayDiscountAmount) < 0 ||
          parseInt(takeawayDiscountAboveAmount) < 0
        ) {
          window.confirm(
            'Error\nFree takeaway above, Discount, and Order above must be more or equal to 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (selectedVariationItems.length <= 0) {
          window.confirm(
            'Error\nPlease select at least one product',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          takeawayFreeFlag: takeawayFreeFlag,
          takeawayFreeAboveAmount: +parseFloat(takeawayFreeAboveAmount).toFixed(
            2,
          ),
          takeawayDiscountAmount: +parseFloat(takeawayDiscountAmount).toFixed(
            2,
          ),
          takeawayDiscountAboveAmount: +parseFloat(
            takeawayDiscountAboveAmount,
          ).toFixed(2),
          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,
        });
      } else if (selectedPromotionType === LOYALTY_PROMOTION_TYPE.CASHBACK) {
        // if (parseFloat(takeawayFreeAboveAmount) < 0 ||
        //   parseInt(takeawayDiscountAmount) < 0 ||
        //   parseInt(takeawayDiscountAboveAmount) < 0) {
        //   window.confirm(
        //     'Error',
        //     'Free takeaway above, Discount, and Order above must be more or equal to 0',
        //     [{ text: 'OK', onPress: () => { } }],
        //     { cancelable: false },
        //   );
        //   return;
        // }

        // if (selectedVariationItems.length <= 0) {
        //   window.confirm(
        //     'Error',
        //     'Please select at least one product',
        //     [{ text: 'OK', onPress: () => { } }],
        //     { cancelable: false },
        //   );
        //   return;
        // }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          // takeawayFreeFlag: takeawayFreeFlag,
          // takeawayFreeAboveAmount: +parseFloat(takeawayFreeAboveAmount).toFixed(2),
          // takeawayDiscountAmount: +parseFloat(takeawayDiscountAmount).toFixed(2),
          // takeawayDiscountAboveAmount: +parseFloat(takeawayDiscountAboveAmount).toFixed(2),
          // variation: selectedVariation,
          // variationItems: selectedVariationItems,
          // variationItemsSku: selectedVariationItemsSku,
        });
      }

      ////////////////////////////////////////////////////////////////

      var loyaltyCriteriaList = [];

      if (selectedLoyaltyCampaignType === LOYALTY_CAMPAIGN_TYPE.FIRST_VISIT) {
        if (!loyaltyCampaignExpirationDays) {
          window.confirm(
            'Error\nPlease fill in all required information:\nExpiration (Days)',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (parseInt(loyaltyCampaignExpirationDays) <= 0) {
          window.confirm(
            'Error\nExpiration (Days) must be more than 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        loyaltyCriteriaList.push({
          // priceBeforeTax: +parseFloat(packageItemPrice).toFixed(2),
          loyaltyCampaignExpirationDays: parseInt(
            loyaltyCampaignExpirationDays,
          ),
          loyaltyCampaignSendTime: moment(loyaltyCampaignSendTime).valueOf(),
        });
      } else if (
        selectedLoyaltyCampaignType === LOYALTY_CAMPAIGN_TYPE.AT_RISK
      ) {
        if (
          !loyaltyCampaignExpirationDays ||
          !loyaltyCampaignGuestNotVisitedDays
        ) {
          window.confirm(
            'Error\nPlease fill in all required information:\nExpiration (Days)\nNot Visited (Days)',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (
          parseInt(loyaltyCampaignExpirationDays) <= 0 ||
          parseInt(loyaltyCampaignGuestNotVisitedDays) <= 0
        ) {
          window.confirm(
            'Error\nExpiration (Days) and Not Visited (Days) must be more than 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        loyaltyCriteriaList.push({
          // priceBeforeTax: +parseFloat(packageItemPrice).toFixed(2),
          loyaltyCampaignExpirationDays: parseInt(
            loyaltyCampaignExpirationDays,
          ),
          loyaltyCampaignSendTime: moment(loyaltyCampaignSendTime).valueOf(),
          loyaltyCampaignGuestNotVisitedDays: parseInt(
            loyaltyCampaignGuestNotVisitedDays,
          ),
        });
      } else if (
        selectedLoyaltyCampaignType === LOYALTY_CAMPAIGN_TYPE.BIRTHDAY
      ) {
        if (
          !loyaltyCampaignExpirationDays ||
          !loyaltyCampaignGuestBirthdayBeforeDays
        ) {
          window.confirm(
            'Error\nPlease fill in all required information:\nExpiration (Days)\nBefore Birthday (Days)',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (
          parseInt(loyaltyCampaignExpirationDays) <= 0 ||
          parseInt(loyaltyCampaignGuestBirthdayBeforeDays) <= 0
        ) {
          window.confirm(
            'Error\nExpiration (Days) and Before Birthday (Days) must be more than 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        loyaltyCriteriaList.push({
          // priceBeforeTax: +parseFloat(packageItemPrice).toFixed(2),
          loyaltyCampaignExpirationDays: parseInt(
            loyaltyCampaignExpirationDays,
          ),
          loyaltyCampaignSendTime: moment(loyaltyCampaignSendTime).valueOf(),
          loyaltyCampaignGuestBirthdayBeforeDays: parseInt(
            loyaltyCampaignGuestBirthdayBeforeDays,
          ),
        });
      } else if (selectedLoyaltyCampaignType === LOYALTY_CAMPAIGN_TYPE.GROWTH) {
        if (
          !loyaltyCampaignExpirationDays ||
          !loyaltyCampaignGuestAfterEveryVisits
        ) {
          window.confirm(
            'Error\nPlease fill in all required information:\nExpiration (Days)\nEvery Visits',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (
          parseInt(loyaltyCampaignExpirationDays) <= 0 ||
          parseInt(loyaltyCampaignGuestAfterEveryVisits) <= 0
        ) {
          window.confirm(
            'Error\nExpiration (Days) and Every Visits must be more than 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        loyaltyCriteriaList.push({
          // priceBeforeTax: +parseFloat(packageItemPrice).toFixed(2),
          loyaltyCampaignExpirationDays: parseInt(
            loyaltyCampaignExpirationDays,
          ),
          loyaltyCampaignSendTime: moment(loyaltyCampaignSendTime).valueOf(),
          loyaltyCampaignGuestAfterEveryVisits: parseInt(
            loyaltyCampaignGuestAfterEveryVisits,
          ),
        });
      } else if (
        selectedLoyaltyCampaignType === LOYALTY_CAMPAIGN_TYPE.BIG_SPENDER
      ) {
        if (
          !loyaltyCampaignExpirationDays ||
          !loyaltyCampaignGuestAfterEverySpends
        ) {
          window.confirm(
            'Error\nPlease fill in all required information:\nExpiration (Days)\nEvery Spends',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (
          parseInt(loyaltyCampaignExpirationDays) <= 0 ||
          parseFloat(loyaltyCampaignGuestAfterEverySpends) <= 0
        ) {
          window.confirm(
            'Error\nExpiration (Days) and Every Spends must be more than 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        loyaltyCriteriaList.push({
          // priceBeforeTax: +parseFloat(packageItemPrice).toFixed(2),
          loyaltyCampaignExpirationDays: parseInt(
            loyaltyCampaignExpirationDays,
          ),
          loyaltyCampaignSendTime: moment(loyaltyCampaignSendTime).valueOf(),
          loyaltyCampaignGuestAfterEverySpends: +parseFloat(
            loyaltyCampaignGuestAfterEverySpends,
          ).toFixed(2),
        });
      } else if (
        selectedLoyaltyCampaignType === LOYALTY_CAMPAIGN_TYPE.SIGN_UP
      ) {
        if (!loyaltyCampaignExpirationDays) {
          window.confirm(
            'Error\nPlease fill in all required information:\nExpiration (Days)',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        if (parseInt(loyaltyCampaignExpirationDays) <= 0) {
          window.confirm(
            'Error\nExpiration (Days) must be more than 0',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        loyaltyCriteriaList.push({
          // priceBeforeTax: +parseFloat(packageItemPrice).toFixed(2),
          loyaltyCampaignExpirationDays: parseInt(
            loyaltyCampaignExpirationDays,
          ),
          loyaltyCampaignSendTime: moment(loyaltyCampaignSendTime).valueOf(),
        });
      }

      ////////////////////////////////////////////////////////////////

      var taxIdList = []; // for each outlet
      var categoryIdList = []; // for each outlet

      var taxName = '';
      var taxRate = 0.05;

      for (var i = 0; i < currOutletTaxes.length; i++) {
        if (currOutletTaxes[i].uniqueId === selectedTax) {
          taxName = currOutletTaxes[i].name;
          taxRate = currOutletTaxes[i].rate;
          break;
        }
      }

      var outletNames = [];

      for (var i = 0; i < allOutlets.length; i++) {
        for (var j = 0; j < selectedOutletList.length; j++) {
          if (selectedOutletList.includes(allOutlets[i].uniqueId)) {
            outletNames.push(allOutlets[i].name);
            break;
          }
        }
      }

      // var categoryName = outletCategoriesDict[selectedOutletCategoryId].name;

      // for (var i = 0; i < selectedOutletList.length; i++) {
      //   const outletIdTemp = selectedOutletList[i];

      //   if (outletsTaxDict[outletIdTemp]) {
      //     taxIdList.push(outletsTaxDict[outletIdTemp].uniqueId);

      //     taxName = outletsTaxDict[outletIdTemp].name;
      //     taxRate = outletsTaxDict[outletIdTemp].rate;
      //   }
      //   else {
      //     taxIdList.push('');
      //   }

      //   if (allOutletsCategoriesNameDict[categoryName]) {
      //     var isSameCategoryNameExisted = false;
      //     var categoryIdTemp = '';

      //     for (var j = 0; j < allOutletsCategoriesNameDict[categoryName].length; j++) {
      //       if (allOutletsCategoriesNameDict[categoryName][j].outletId === outletIdTemp) {
      //         isSameCategoryNameExisted = true;
      //         categoryIdTemp = allOutletsCategoriesNameDict[categoryName][j].uniqueId;
      //         break;
      //       }
      //     }

      //     categoryIdList.push(categoryIdTemp);
      //   }
      //   else {
      //     categoryIdList.push('');
      //   }
      // }

      ///////////////////////////////////
      // upload image

      var promotionImagePath = '';
      var promotionCommonIdLocal = selectedLoyaltyCampaignEdit
        ? selectedLoyaltyCampaignEdit.commonId
        : uuidv4();

      if (image && imageType) {
        // promotionCommonIdLocal = uuidv4();

        promotionImagePath = await uploadImageToFirebaseStorage(
          {
            uri: image,
            type: imageType,
          },
          `/merchant/${merchantId}/promotion/${promotionCommonIdLocal}/image${imageType}`,
        );
      }

      ///////////////////////////////////

      if (selectedLoyaltyCampaignEdit === null) {
        // means new item

        var body = {
          merchantId: merchantId,
          merchantName: merchantName,

          campaignName: campaignName,
          isEnableSellOnline: isEnableSellOnline,
          orderTypes: selectedAvailability,
          targetUserGroup: selectedTargetUserGroup,
          targetSegmentGroupList: selectedTargetSegmentGroupList,
          promoCode: promoCode,
          isPromoCodeUsageLimit: isPromoCodeUsageLimit,
          promoCodeUsageLimit: parseInt(promoCodeUsageLimit),
          image: promotionImagePath,
          commonId: promotionCommonIdLocal,
          effectiveType: selectedEffectiveType,
          effectiveTypeOptions: selectedEffectiveTypeOptions,
          effectiveDay: selectedEffectiveDay,
          effectiveTimeStart: moment(effectiveTimeStart).valueOf(),
          effectiveTimeEnd: moment(effectiveTimeEnd).valueOf(),

          minSpend: parseFloat(minSpend),

          promoDateStart: moment(promoDateStart).valueOf(),
          promoDateEnd: moment(promoDateEnd).valueOf(),
          promoTimeStart: moment(promoTimeStart).valueOf(),
          promoTimeEnd: moment(promoTimeEnd).valueOf(),

          campaignDescription: campaignDescription,

          promotionType: selectedPromotionType,

          criteriaList: criteriaList,

          isEditNotification: true,
          notification: notification,

          //////////////
          // multi outlet supports

          outletIdList: selectedOutletList,
          outletNameList: outletNames,

          currOutletTaxName: taxName,
          currOutletTaxRate: taxRate,
          currOutletTaxId: selectedTax,
          currOutletId: currOutletId,

          loyaltyCampaignType: selectedLoyaltyCampaignType,
          loyaltyCriteriaList: loyaltyCriteriaList,

          notificationText: smsText,
        };

        console.log(body);

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ApiClient.POST(API.createLoyaltyCampaign, body, false).then(
          (result) => {
            if (result && result.status === 'success') {
              // if (isAutoPush) {
              //   if (isEditNotification) {
              //     ApiClient.POST(API.switchPromotionNotificationAutoPushStatus, {
              //       promotionId: result.promotion.uniqueId,
              //       notificationAutoPushStatus: true,
              //     }).then((result) => {
              //       if (result && result.status === 'success') {
              //         window.confirm(
              //           'Success',
              //           "Promotion auto-push scheduled.",
              //           [
              //             {
              //               text: 'OK',
              //               onPress: () => {
              //                 navigation.navigate('PromotionList');
              //               },
              //             },
              //           ],
              //           { cancelable: false },
              //         );
              //       }
              //       CommonStore.update(s => {
              //         s.isLoading = false;
              //       });
              //     }).catch(err => { console.log(err) });
              //   }
              //   else {
              //     ApiClient.POST(API.pushPromotionNotificationManual, body).then((result) => {
              //       if (result && result.status === 'success') {
              //         window.confirm(
              //           'Success',
              //           "Promotion pushed.",
              //           [
              //             {
              //               text: 'OK',
              //               onPress: () => {
              //                 navigation.navigate('PromotionList');
              //               },
              //             },
              //           ],
              //           { cancelable: false },
              //         );
              //       }
              //       CommonStore.update(s => {
              //         s.isLoading = false;
              //       });
              //     }).catch(err => { console.log(err) });
              //   }
              // }

              window.confirm(
                'Success',
                'Loyalty campaign added successfully.',
                [
                  {
                    text: 'OK',
                    onPress: () => {
                      // navigation.navigate('PromotionList');

                      props.navigation.navigate('SettingLoyalty');
                    },
                  },
                ],
                { cancelable: false },
              );
            }

            CommonStore.update((s) => {
              s.isLoading = false;
            });
          },
        );
      } else if (selectedLoyaltyCampaignEdit !== null) {
        // means existing item

        var body = {
          promotionId: selectedLoyaltyCampaignEdit.uniqueId,

          merchantId: merchantId,
          merchantName: merchantName,

          campaignName: campaignName,
          isEnableSellOnline: isEnableSellOnline,
          orderTypes: selectedAvailability,
          targetUserGroup: selectedTargetUserGroup,
          targetSegmentGroupList: selectedTargetSegmentGroupList,
          promoCode: promoCode,
          isPromoCodeUsageLimit: isPromoCodeUsageLimit,
          promoCodeUsageLimit: parseInt(promoCodeUsageLimit),

          image: promotionImagePath,
          commonId: promotionCommonIdLocal,
          isImageChanged: isImageChanged,

          effectiveType: selectedEffectiveType,
          effectiveTypeOptions: selectedEffectiveTypeOptions,
          effectiveDay: selectedEffectiveDay,
          effectiveTimeStart: moment(effectiveTimeStart).valueOf(),
          effectiveTimeEnd: moment(effectiveTimeEnd).valueOf(),

          minSpend: parseFloat(minSpend),

          promoDateStart: moment(promoDateStart).valueOf(),
          promoDateEnd: moment(promoDateEnd).valueOf(),
          promoTimeStart: moment(promoTimeStart).valueOf(),
          promoTimeEnd: moment(promoTimeEnd).valueOf(),

          campaignDescription: campaignDescription,

          promotionType: selectedPromotionType,

          criteriaList: criteriaList,

          isEditNotification: true,
          notification: notification,

          //////////////
          // multi outlet supports

          outletIdList: selectedOutletList,
          outletNameList: outletNames,

          currOutletTaxName: taxName,
          currOutletTaxRate: taxRate,
          currOutletTaxId: selectedTax,
          currOutletId: currOutletId,

          loyaltyCampaignType: selectedLoyaltyCampaignType,
          loyaltyCriteriaList: loyaltyCriteriaList,

          notificationText: smsText,
        };

        console.log(body);

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ApiClient.POST(API.updateLoyaltyCampaign, body, false).then(
          (result) => {
            if (result && result.status === 'success') {
              // window.confirm(
              //   'Caution',
              //   'Each Promotion can only be Push ONCE',
              //   [
              //     {
              //       text: 'Push',
              //       onPress: () => { createPromotion(true) },
              //     },
              //     {
              //       text: 'Cancel',
              //       onPress: () => { createPromotion(false) },
              //     }
              //   ]
              // );

              // if (isAutoPush) {
              //   if (selectedLoyaltyCampaignEdit.sendQuota > 0) {
              //     if (isEditNotification) {
              //       ApiClient.POST(API.switchPromotionNotificationAutoPushStatus, {
              //         promotionId: selectedLoyaltyCampaignEdit.uniqueId,
              //         notificationAutoPushStatus: true,
              //       }).then((result) => {
              //         if (result && result.status === 'success') {
              //           window.confirm(
              //             'Success',
              //             "Promotion auto-push scheduled.",
              //             [
              //               {
              //                 text: 'OK',
              //                 onPress: () => {
              //                   navigation.navigate('PromotionList');
              //                 },
              //               },
              //             ],
              //             { cancelable: false },
              //           );
              //         }
              //         CommonStore.update(s => {
              //           s.isLoading = false;
              //         });
              //       }).catch(err => { console.log(err) });
              //     }
              //     else {
              //       ApiClient.POST(API.pushPromotionNotificationManual, body).then((result) => {
              //         if (result && result.status === 'success') {
              //           window.confirm(
              //             'Success',
              //             "Promotion pushed.",
              //             [
              //               {
              //                 text: 'OK',
              //                 onPress: () => {
              //                   navigation.navigate('PromotionList');
              //                 },
              //               },
              //             ],
              //             { cancelable: false },
              //           );
              //         }
              //         CommonStore.update(s => {
              //           s.isLoading = false;
              //         });
              //       }).catch(err => { console.log(err) });
              //     }
              //   }
              //   else {
              //     window.confirm(
              //       'Error',
              //       "This promotion has been pushed before.",
              //       [
              //         {
              //           text: 'OK',
              //           onPress: () => {
              //             // navigation.navigate('PromotionList');
              //           },
              //         },
              //       ],
              //       { cancelable: false },
              //     );
              //   }
              // }

              window.confirm(
                'Success',
                'Loyalty campaign has been updated.',
                [
                  {
                    text: 'OK',
                    onPress: () => {
                      // navigation.navigate('PromotionList');

                      props.navigation.navigate('SettingLoyalty');
                    },
                  },
                ],
                { cancelable: false },
              );
            }

            CommonStore.update((s) => {
              s.isLoading = false;
            });
          },
        );
      }
      // else {
      //   // means existing item

      //   var outletItemImagePath = '';
      //   var outletItemIdLocal = selectedLoyaltyCampaignEdit.uniqueId;

      //   if (image && imageType && isImageChanged) {
      //     // outletItemIdLocal = selectedLoyaltyCampaignEdit.uniqueId;

      //     outletItemImagePath = await uploadImageToFirebaseStorage({
      //       uri: image,
      //       type: imageType,
      //     }, `/merchant/${merchantId}/outletItem/${outletItemIdLocal}/image${imageType}`);
      //   }

      //   ///////////////////////////////////

      //   var body = {
      //     //<- this works, the database is updated
      //     // name: itemName,
      //     // code: '',
      //     // description: description,
      //     // price: itemPrice,
      //     // url: image,
      //     // materialTag: materialTag,
      //     // materialSKU: materialSKU,
      //     // categoryId: categoryId,
      //     // options: [
      //     //     {
      //     //         name: options,
      //     //         least: '1',
      //     //         choice: [{ label: options1, price: '1' }],
      //     //     },
      //     // ],
      //     // material: [{ name: material, qty: material }],
      //     // suppliers: [{ name: supplier }],

      //     merchantId: merchantId,
      //     // outletId: place,
      //     name: itemName,
      //     // categoryId: selectedOutletCategoryId,
      //     code: '',
      //     currency: 'MYR',
      //     description: itemDescription,
      //     image: outletItemImagePath,
      //     price: itemPrice,
      //     // taxId: outletsTaxDict[place].uniqueId,
      //     isActive: true,
      //     isComposite: true,
      //     isDelivery: true,
      //     isSellable: true,
      //     isTakeaway: true,
      //     options: '',
      //     prepareTime: 600,
      //     sku: itemName,

      //     stockLinkItems: stockLinkItems,

      //     outletItemIdLocal: outletItemIdLocal,

      //     variantGroupList: variantGroupList,
      //     addOnGroupList: addOnGroupList,

      //     //////////////
      //     // multi outlet supports

      //     categoryIdList: categoryIdList,
      //     categoryName: outletCategoriesDict[selectedOutletCategoryId].name,

      //     outletIdList: selectedOutletList,

      //     taxIdList: taxIdList,
      //     taxName: taxName,
      //     taxRate: taxRate,

      //     //////////////
      //     // for update purpose

      //     editItemId: selectedLoyaltyCampaignEdit.uniqueId,
      //     editItemSku: selectedLoyaltyCampaignEdit.sku,
      //     editItemImage: selectedLoyaltyCampaignEdit.image,
      //   };

      //   console.log(body);

      //   CommonStore.update(s => {
      //     s.isLoading = true;
      //   });

      //   ApiClient.POST(API.updateOutletItem, body, false).then((result) => {
      //     if (result && result.status === 'success') {
      //       window.confirm(
      //         'Success',
      //         "Product updated.",
      //         [
      //           {
      //             text: 'OK',
      //             onPress: () => { },
      //           },
      //         ],
      //         { cancelable: false },
      //       );
      //     }

      //     CommonStore.update(s => {
      //       s.isLoading = false;
      //     });
      //   });
      // }
    }
  };

  // const pushSavedPromotion = () => {
  //   if ()

  // }

  // const pushPromotionNotificationManual = (promotion) => {
  //   // self.onButtonClickHandler();

  //   var body = {
  //     // outletId: User.getOutletId()
  //     promotionId: promotion.uniqueId,
  //   };

  //   ApiClient.POST(API.pushPromotionNotificationManual, body).then(result => {
  //     if (result && result.status === 'success') {
  //       window.confirm(
  //         'Success',
  //         "Notification pushed.",
  //         [
  //           {
  //             text: 'OK',
  //             onPress: () => {
  //             },
  //           },
  //         ],
  //         { cancelable: false },
  //       );
  //     }
  //   }).catch(err => { console.log(err) });
  // };

  /////////////////////////////////////////////////

  //Render start here
  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={0} />
      </View>

      <View style={{ height: windowHeight, flex: 9 }}>
      <ScrollView horizontal={true} scrollEnabled={switchMerchant}>
        {/* Modals */}

        {/* <DatePicker
          // isVisible={showPromoDateStartPicker}
          // mode={'date'}
          selected={rev_PromoDateStart.toDate()}
          onChange={(date) => {
            setPromoDateStart(moment(date));
            setRev_PromoDateStart(moment(date))
            // setShowPromoDateStartPicker(false);
          }}
          // onCancel={() => {
          //   setShowPromoDateStartPicker(false);
          // }}
        />

        <DatePicker
          // isVisible={showPromoDateEndPicker}
          // mode={'date'}
          selected={rev_PromoDateEnd.toDate()}
          onChange={(date) => {
            setPromoDateEnd(moment(date));
            setRev_PromoDateEnd(moment(date))
            // setShowPromoDateEndPicker(false);
          }}
          // onCancel={() => {
          //   setShowPromoDateEndPicker(false);
          // }}
        /> */}

          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showPromoTimeStartPicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.4,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      Promo Start Time Picker
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowPromoTimeStartPicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TimeKeeper
                    // isVisible={showPromoTimeStartPicker}
                    // mode={'time'}
                    time={rev_PromoTimeStart}
                    onChange={(time) => {
                      setPromoTimeStart(time.formatted12);
                      setRev_PromoTimeStart(moment(time.formatted12));

                      // setShowPromoTimeStartPicker(false);
                    }}
                    onDoneClick={() => {
                      setShowPromoTimeStartPicker(false);
                    }}
                  />
                </View>
              </View>
            </View>
          </Modal>
        

          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showPromoTimeEndPicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.4,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      Promo End Time Picker
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowPromoTimeEndPicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TimeKeeper
                    // isVisible={showPromoTimeEndPicker}
                    // mode={'time'}
                    time={rev_PromoTimeEnd}
                    onChange={(time) => {
                      setPromoTimeEnd(time.formatted12);
                      setRev_PromoTimeEnd(time.formatted12);
                      // setShowPromoTimeEndPicker(false);
                    }}
                    onDoneClick={() => {
                      setShowPromoTimeEndPicker(false);
                    }}
                  />
                </View>
              </View>
            </View>
          </Modal>
        
          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showEffectiveTimeStartPicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.4,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      Effective Start Time Picker
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowEffectiveTimeStartPicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TimeKeeper
                    // isVisible={showEffectiveTimeStartPicker}
                    // mode={'time'}
                    time={rev_EffectiveTimeStart}
                    onChange={(time) => {
                      setEffectiveTimeStart(time.formatted12);
                      setRev_EffectiveTimeStart(time.formatted12);
                      
                      // setShowEffectiveTimeStartPicker(false);
                    }}
                    onDoneClick={() => {
                      setShowEffectiveTimeStartPicker(false);
                    }}
                  />
                </View>
              </View>
            </View>
          </Modal>
        
          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showEffectiveTimeEndPicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.4,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      Effective End Time Picker
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowEffectiveTimeEndPicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TimeKeeper
                    // isVisible={showEffectiveTimeEndPicker}
                    // mode={'time'}
                    time={rev_EffectiveTimeEnd}
                    onChange={(time) => {
                      setEffectiveTimeEnd(time.formatted12)
                      setRev_EffectiveTimeEnd(time.formatted12);
                   
                      // setShowEffectiveTimeEndPicker(false);
                    }}
                    onDoneClick={() => {
                      setShowEffectiveTimeEndPicker(false);
                    }}
                  />
                </View>
              </View>
            </View>
          </Modal>
        
          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showLoyaltyCampaignSendTimePicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.4,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{flex: 0.3}}></View>
                  <View style={{ flex: 0.4 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "center",
                      }}
                    >
                      Send Time Picker
                    </Text>
                  </View>
                  <View style={{ flex: 0.3 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowLoyaltyCampaignSendTimePicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TimeKeeper
                    // isVisible={showLoyaltyCampaignSendTimePicker}
                    // mode={'time'}
                    time={rev_LoyaltyCampaignSendTime}
                    onChange={(time) => {
                      setLoyaltyCampaignSendTime(time.formatted12);
                      setRev_LoyaltyCampaignSendTime(time.formatted12)
                    }}
                    onDoneClick={() => {
                      setShowLoyaltyCampaignSendTimePicker(false);
                    }}
                  />
                </View>
              </View>
            </View>
          </Modal>
        

        {/* Modals */}
        {/* ********************** Import Modal Start ************************ */}
        <Modal visible={importModal} transparent={true} animationType={'slide'}>
          <View style={[styles.modalContainer1, {}]}>
            <View
              style={[
                styles.modalView,
                {
                  // top:
                  //   Platform.OS === 'ios' && keyboardHeight > 0
                  //     ? -keyboardHeight * 0.3
                  //     : 0,
                  width: 300,
                  height: 240,
                  borderRadius: windowWidth * 0.03,
                },
              ]}>
              <TouchableOpacity
                style={styles.closeButton}
                onPress={() => {
                  // setState({ changeTable: false });
                  setImportModal(false);
                }}>
                <AntDesign
                  name="closecircle"
                  size={25}
                  color={Colors.fieldtTxtColor}
                />
              </TouchableOpacity>
              <View style={styles.modalTitle}>
                <Text style={[styles.modalTitleText, { fontSize: 16 }]}>
                  {/* Import Options */}
                  Import
                </Text>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  top: '10%',
                }}>
                <TouchableOpacity
                  style={[
                    styles.modalSaveButton,
                    {
                      zIndex: -1,
                      width: windowWidth * 0.15,
                    },
                  ]}
                  onPress={() => {
                    setShowImportListModal(true);
                  }}>
                  <Text
                    style={[
                      styles.modalDescText,
                      { color: Colors.primaryColor },
                    ]}>
                    Import Template (Excel)
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>

        <Modal
          visible={showImportListModal}
          transparent={true}
          animationType={'slide'}>
          <View style={[styles.modalContainer1, {}]}>
            <View
              style={[
                styles.modalView1,
                {
                  height:
                  Platform.OS === 'ios'
                    ? windowWidth * 0.7
                    : windowWidth * 0.6,
                  width:
                    Platform.OS === 'ios'
                      ? windowWidth * 0.7
                      : windowWidth * 0.6,
                  borderRadius: windowWidth * 0.03,
                },
              ]}>
              <TouchableOpacity
                style={styles.closeButton}
                onPress={() => {
                  setShowImportListModal(false);
                }}>
                <AntDesign
                  name="closecircle"
                  size={25}
                  color={Colors.fieldtTxtColor}
                />
              </TouchableOpacity>
              <View style={styles.modalTitle}>
                <Text
                  style={[
                    styles.modalTitleText,
                    { fontSize: 16, fontWeight: '500' },
                  ]}>
                  Imported List
                </Text>
              </View>
              <View
                style={{
                  //backgroundColor:'red',
                  height: 70,
                  marginVertical: 10,
                  marginTop: 15,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  height:
                    Platform.OS === 'ios'
                      ? windowWidth * 0.57
                      : windowWidth * 0.47,
                  width:
                    Platform.OS === 'ios'
                      ? windowWidth * 0.66
                      : windowWidth * 0.56,
                }}>
                <View style={{ width: '100%', marginTop: 0 }}>
                  <View
                    style={{
                      backgroundColor: Colors.whiteColor,
                      padding: 7,
                      paddingTop: 0,
                      height: '82%',
                    }}>
                    <View style={{ flexDirection: 'row' }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          flex: 1.5,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={{
                              fontSize: 15,
                              color: Colors.fieldtTxtColor,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            Name
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          flex: 1.8,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={{
                              fontSize: 15,
                              color: Colors.fieldtTxtColor,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            Email
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          flex: 1.2,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={{
                              fontSize: 15,
                              color: Colors.fieldtTxtColor,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            Phone Number
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          flex: 0.8,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={{
                              fontSize: 15,
                              color: Colors.fieldtTxtColor,
                              fontFamily: 'NunitoSans-Bold',
                            }}></Text>
                        </View>
                      </View>
                    </View>

                    <FlatList />
                  </View>
                </View>
                {/* <Table borderStyle={{ borderWidth: 1}}>
                      <Row data={TableData.tableHead} flexArr={[1, 1, 1, 1, 1, 1, 1]} style={{}}/>
                      <TableWrapper style={{}}>
                        <Col data={TableData.tableTitle} style={{flex:1,}} heightArr={[28,28,28,28]} textStyle={{}}/>
                        <Rows data={TableData.tableData} flexArr={[ 1, 2, 1]} style={{height: 28}} textStyle={{textAlign: 'center'}}/>
                      </TableWrapper>
                    </Table> */}
              </View>
              <View
                style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    borderRadius: 10,
                    padding: 5,
                    alignItems: 'center',
                    height: 35,
                    width: 85,
                    borderColor: Colors.primaryColor,
                    backgroundColor: Colors.primaryColor,
                  }}
                  onPress={() => {
                    //importSelectFile()
                    //importTemplate()
                  }}>
                  <Text
                    style={{
                      fontWeight: '600',
                      fontSize: 18,
                      textAlign: 'center',
                      color: Colors.whiteColor,
                    }}>
                    Import
                  </Text>
                </TouchableOpacity>
                <View
                  style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      borderRadius: 10,
                      padding: 5,
                      alignItems: 'center',
                      height: 35,
                      width: 85,
                      borderColor: Colors.primaryColor,
                      backgroundColor: Colors.whiteColor,
                      marginRight: 10,
                    }}
                    onPress={() => {
                      setImportModal(false);
                    }}>
                    <Text
                      style={{
                        fontWeight: '600',
                        fontSize: 18,
                        textAlign: 'center',
                        color: Colors.primaryColor,
                      }}>
                      Cancel
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      borderRadius: 10,
                      padding: 5,
                      alignItems: 'center',
                      height: 35,
                      width: 85,
                      borderColor: Colors.primaryColor,
                      backgroundColor: Colors.primaryColor,
                    }}>
                    <Text
                      style={{
                        fontWeight: '600',
                        fontSize: 18,
                        textAlign: 'center',
                        color: 'white',
                      }}>
                      Submit
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </Modal>

        <Modal
          visible={importSmsModal}
          transparent={true}
          animationType={'slide'}>
          <View style={[styles.modalContainer1, {}]}>
            <View
              style={[
                styles.modalView2,
                {
                  height:
                  Platform.OS === 'ios'
                    ? windowWidth * 0.35
                    : windowWidth * 0.25,
                width:
                  Platform.OS === 'ios'
                    ? windowWidth * 0.5
                    : windowWidth * 0.4,
                borderRadius: windowWidth * 0.03,
                },
              ]}>
              <TouchableOpacity
                style={styles.closeButton}
                onPress={() => {
                  // setState({ changeTable: false });
                  setImportSmsModal(false);
                }}>
                <AntDesign
                  name="closecircle"
                  size={25}
                  color={Colors.fieldtTxtColor}
                />
              </TouchableOpacity>
              <View style={{}}>
                <View style={{ height: windowHeight * 0.1 }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontWeight: '700',
                      fontSize: 30,
                    }}>
                    Insufficient Credit!
                  </Text>
                </View>

                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: windowHeight * 0.15,
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      color: Colors.descriptionColor,
                      fontSize: 25,
                      width: '100%',
                      alignSelf: 'center',
                    }}>
                    Please contact your account manager
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Modal>
        {/* ********************** Import Modal End ************************ */}

        <KeyboardAvoidingView>
          <View
            style={
              switchMerchant
                ? {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: windowWidth * 0.8,
                  marginHorizontal: 15,
                  //alignSelf: 'center',
                  margin: 10,
                  paddingHorizontal: 0,
                  paddingTop: 10,
                }
                : {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: windowWidth * 0.9,
                  marginHorizontal: 15,
                  alignSelf: 'center',
                  margin: 10,
                  paddingHorizontal: 0,
                  paddingTop: 10,
                }
            }>
            <TouchableOpacity
              style={{ width: 90, height: 35, justifyContent: 'center' }}
              onPress={() => {
                // props.navigation.navigate('PromotionList');

                // props.navigation.navigate('SettingLoyalty');
                linkTo && linkTo(`${prefix}/settingloyaltyscreen`);
              }}>
              <View
                style={{
                  flexDirection: 'row',
                  paddingHorizontal: '10%',
                  alignContent: 'center',
                  alignItems: 'center',
                  marginTop: switchMerchant ? 10 : 0,
                }}>
                <View style={{ justifyContent: 'center' }}>
                  <Feather
                    name="chevron-left"
                    size={switchMerchant ? 20 : 30}
                    style={{ color: Colors.primaryColor, alignSelf: 'center' }}
                  />
                </View>
                <Text
                  style={[
                    {
                      fontSize: 17,
                      color: Colors.primaryColor,
                      fontWeight: '600',
                      marginBottom: Platform.OS === 'ios' ? 0 : 1,
                    },
                    switchMerchant
                      ? {
                        fontSize: 14,
                      }
                      : {},
                  ]}>
                  Back
                </Text>
              </View>
            </TouchableOpacity>
          </View>

          {/* <KeyBoardAwareScrollView style={styles.list}> */}
          <ScrollView
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled={true}
            style={{
              backgroundColor: Colors.whiteColor,
              width: switchMerchant
                ? windowWidth * 0.87
                : windowWidth * 0.87,
              height: windowHeight * 0.825,
              marginTop: 0,
              marginHorizontal: switchMerchant ? 30 : 30,
              //alignSelf: 'center',
              borderRadius: 5,
              shadowOpacity: 0,
              shadowColor: '#000',
              shadowOffset: {
                width: 1,
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 3,

              marginBottom: switchMerchant ? 50 : 50,
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              }}>
              <View
                style={{
                  //flexDirection: 'row',
                  margin: 20,
                  marginBottom: 10,
                  width: '70%',
                }}>
                <View
                  style={{
                    width: '100%',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Text
                    style={[
                      { fontFamily: 'NunitoSans-Bold', fontSize: 30 },
                      switchMerchant
                        ? {
                          fontSize: 20,
                        }
                        : {},
                    ]}>
                    {campaignName.length > 0
                      ? campaignName
                      : 'New Loyalty Campaign'}
                  </Text>
                </View>

                {campaignDescription.length > 0 ? (
                  <View
                    style={{
                      width: '100%',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      marginTop: 5,
                    }}>
                    <Text
                      style={{ fontFamily: 'NunitoSans-Regular', fontSize: 17 }}>
                      {campaignDescription}
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
              </View>
              <View
                style={{
                  margin: 20,
                  marginBottom: 10,
                }}>
                <View style={{}}>
                  <TouchableOpacity
                    style={[
                      {
                        justifyContent: 'center',
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: '#4E9F7D',
                        borderRadius: 5,
                        width: 130,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: 'center',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginBottom: 10,
                      },
                      switchMerchant
                        ? {
                          height: 35,
                          width: 120,
                        }
                        : {},
                    ]}
                    disabled={isLoading}
                    onPress={() => createPromotion()}>
                    <Text
                      style={[
                        {
                          color: Colors.whiteColor,
                          fontSize: 16,
                          fontFamily: 'NunitoSans-Bold',
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                          }
                          : {},
                      ]}>
                      {isLoading ? 'LOADING...' : 'SAVE'}
                    </Text>

                    {isLoading ? (
                      <ActivityIndicator
                        color={Colors.whiteColor}
                        size={'small'}
                      />
                    ) : (
                      <></>
                    )}
                  </TouchableOpacity>

                  {/* <TouchableOpacity
                    style={[
                      {
                        justifyContent: 'center',
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: Colors.tabRed,
                        backgroundColor: Colors.tabRed,
                        borderRadius: 5,
                        width: 130,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: 'center',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginBottom: 10,
                      },
                      switchMerchant
                        ? {
                          height: 35,
                          width: 120,
                        }
                        : {},
                    ]}
                    onPress={() => {
                      confirmDeletePromotion();
                    }}>
                    <Text
                      style={[
                        {
                          color: Colors.whiteColor,
                          fontSize: 16,
                          fontFamily: 'NunitoSans-Bold',
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                          }
                          : {},
                      ]}>
                      DELETE
                    </Text>
                  </TouchableOpacity> */}

                  {/* <TouchableOpacity
                    style={[
                      {
                        justifyContent: 'center',
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: '#4E9F7D',
                        borderRadius: 5,
                        width: 130,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: 'center',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginBottom: 10,
                      },
                      switchMerchant
                        ? {
                          height: 35,
                          width: 120,
                        }
                        : {},
                    ]}
                    onPress={() => {
                      canOnlyPushOnce();
                    }}>
                    <Text
                      style={[
                        {
                          color: Colors.whiteColor,
                          fontSize: 16,
                          fontFamily: 'NunitoSans-Bold',
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                          }
                          : {},
                      ]}>
                      {isLoading ? 'LOADING...' : 'PUBLISH'}
                    </Text>

                    {isLoading ? (
                      <ActivityIndicator
                        color={Colors.whiteColor}
                        size={'small'}
                      />
                    ) : (
                      <></>
                    )}
                  </TouchableOpacity> */}
                </View>
              </View>
            </View>

            <View
              style={{
                flexDirection: 'column',
                borderWidth: 1,
                borderColor: '#c4c4c4',
                width: '95%',
                alignSelf: 'center',
                flex: 1,
                paddingBottom: 30,
              }}>
              <View
                style={{ flexDirection: 'row', flex: 1 }}>
                <View
                  style={{
                    flex: 4,
                    flexDirection: 'column',
                    marginVertical: 20,
                  }}>
                  <View style={{ flexDirection: 'column', marginLeft: 20 }}>
                    <TouchableOpacity
                      onPress={() => {
                        openFileSelector();
                      }}>
                      <View style={{ flexDirection: 'row', zIndex: -2 }}>
                        {image ? (
                          <View
                            style={{
                              backgroundColor: '#F7F7F7',
                              borderRadius: 5,
                              zIndex: 1,
                            }}>
                            <AsyncImage
                              source={{ uri: image }}
                              style={[
                                { width: 260, height: 200, borderRadius: 5 },
                                switchMerchant
                                  ? {
                                    width: 200,
                                    height: 160,
                                  }
                                  : {},
                              ]}
                              hideLoading={true}
                            />
                            <View
                              style={{
                                position: 'absolute',
                                bottom: 5,
                                right: 5,
                                //opacity: 0.5,
                              }}>
                              <Edit
                                size={switchMerchant ? 10 : 23}
                                color={Colors.primaryColor}
                              />
                            </View>
                          </View>
                        ) : (
                          <View
                            style={[
                              {
                                backgroundColor: '#F7F7F7',
                                borderRadius: 5,
                                width: 260,
                                height: 200,
                                alignItems: 'center',
                                justifyContent: 'center',
                              },
                              switchMerchant
                                ? {
                                  width: 200,
                                  height: 160,
                                }
                                : {},
                            ]}>
                            <Icon1
                              name="upload"
                              size={switchMerchant ? 100 : 150}
                              color="lightgrey"
                              style={{ zIndex: -1 }}
                            />
                            <View
                              style={{
                                position: 'absolute',
                                bottom: 5,
                                right: 5,
                                //opacity: 0.5,
                              }}>
                              <Edit
                                size={switchMerchant ? 10 : 23}
                                color={Colors.primaryColor}
                              />
                            </View>
                          </View>
                        )}
                      </View>
                    </TouchableOpacity>

                    <Text
                      style={[
                        {
                          fontWeight: '500',
                          marginTop: 10,
                          fontFamily: 'NunitoSans-Bold',
                          fontSize: 14,
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                          }
                          : {},
                      ]}>
                      Campaign Description
                    </Text>
                    <TextInput
                      style={[
                        {
                          marginTop: '2%',
                          padding: 5,
                          backgroundColor: Colors.fieldtBgColor,
                          width: Platform.OS == 'ios' ? '90%' : '85%',
                          height: Platform.OS == 'ios' ? 100 : 117,
                          borderRadius: 5,
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          paddingTop: Platform.OS == 'ios' ? 10 : 10,
                          paddingLeft: 10,
                          fontFamily: 'NunitoSans-Regular',
                          fontSize: 14,
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                            width: '92%',
                            height: 97,
                          }
                          : {},
                      ]}
                      textAlignVertical={'top'}
                      placeholderStyle={{
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 14,
                      }}
                      placeholder="Description..."
                      placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                      defaultValue={campaignDescription}
                      multiline={true}
                      onChangeText={(text) => {
                        setCampaignDescription(text);
                      }}
                    />
                  </View>
                </View>

                <View
                  style={{
                    flex: 7,
                    marginVertical: 20,
                    marginHorizontal: 20,
                    marginLeft: 10,
                  }}>
                  <View style={{ flexDirection: 'row', flex: 1, zIndex: 1 }}>
                    <View
                      style={{
                        flex: 1,
                        marginRight: switchMerchant
                          ? '5%'
                          : windowWidth <= 1024
                            ? '3%'
                            : '2%',
                      }}>
                      <Text
                        style={[
                          {
                            alignSelf: 'flex-start',
                            fontFamily: 'NunitoSans-Bold',
                            fontSize: 14,
                            fontWeight: '500',
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}>
                        Campaign Name
                      </Text>
                      <TextInput
                        placeholder="Campaign Name"
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        placeholderStyle={{
                          fontFamily: 'NunitoSans-Regular',
                          fontSize: 14,
                        }}
                        style={[
                          {
                            backgroundColor: Colors.fieldtBgColor,
                            width: 250,
                            height: 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                              width: 200,
                              height: 35,
                            }
                            : {},
                        ]}
                        onChangeText={(text) => {
                          setCampaignName(text);
                        }}
                        defaultValue={campaignName}
                      />
                      {/************************Got Two Types of CHeckBox/ If want change CheckBox remember change the imported CheckBox *************************/}

                      {/* <View style={{ marginTop: 0 }}>
                        <Text style={[{ alignSelf: 'flex-start', fontWeight: '500', fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? {
                          fontSize: 10,
                        } : {}]}>
                          Promo Date
                        </Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <TouchableOpacity onPress={() => {
                            setShowPromoDateStartPicker(true);
                          }}
                            style={[{
                              backgroundColor: Colors.fieldtBgColor,
                              width: 115,
                              padding: 5,
                              height: 40,
                              borderRadius: 5,
                              marginVertical: 5,
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              fontFamily: 'NunitoSans-Regular', 
                              fontSize: 14
                            }, switchMerchant ? {
                              fontSize: 10,
                              width: 93,
                              height: 35,
                            } : {}]}
                          >
                            <Text style={[{ fontVariant: ['tabular-nums'], fontFamily: 'NunitoSans-Regular', fontSize: 14 }, switchMerchant ? {
                              fontSize: 10,
                            } : {}]}>{moment(promoDateStart).format('DD MMM YYYY')}</Text>
                          </TouchableOpacity>
                          <Text style={[{ marginLeft: '1%', marginRight: '1%', fontWeight: '500', fontFamily: 'NunitoSans-Regular', fontSize: 14 }, switchMerchant ? {
                            fontSize: 10,
                          } : {}]}>to</Text>
                          <TouchableOpacity onPress={() => {
                            setShowPromoDateEndPicker(true);
                          }}
                            style={[{
                              backgroundColor: Colors.fieldtBgColor,
                              width: 115,
                              height: 40,
                              borderRadius: 5,
                              padding: 1,
                              marginVertical: 5,
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              fontFamily: 'NunitoSans-Regular', 
                              fontSize: 14
                            }, switchMerchant ? {
                              fontSize: 10,
                              width: 93,
                              height: 35,
                            } : {}]}                       >
                            <Text style={[{ fontVariant: ['tabular-nums'], fontFamily: 'NunitoSans-Regular', fontSize: 14 }, switchMerchant ? {
                              fontSize: 10,
                            } : {}]}>{moment(promoDateEnd).format('DD MMM YYYY')}</Text>
                          </TouchableOpacity>
                        </View>
                      </View> */}
                      <Text
                        style={[
                          {
                            alignSelf: 'flex-start',
                            fontWeight: '500',
                            fontFamily: 'NunitoSans-Bold',
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}>
                        Effective On Every
                      </Text>
                      <View style={{ marginTop: '2%' }}>
                        {[{ label: 'Day', value: 'DAY' }].find(
                          (item) => item.value === selectedEffectiveType,
                        ) ? (
                          <Picker
                            containerStyle={[
                              { height: 40 },
                              switchMerchant ? { height: 35 } : {},
                            ]}
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold' }}
                            labelStyle={{
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 14,
                            }}
                            style={[
                              {
                                width: 250,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              },
                              switchMerchant
                                ? {
                                  width: 200,
                                }
                                : {},
                            ]}
                            placeholderStyle={{
                              color: Colors.fieldtTxtColor,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 14,
                            }}
                            // items={[{ label: 'Everyone', value: 1 }, { label: 'Active Member', value: 2 }, { label: 'Inactive Member', value: 3 }, { label: 'Female', value: 4 }, { label: 'Male', value: 5 }, { label: 'VIP Only', value: 6 }]}
                            //items={''}
                            itemStyle={{
                              justifyContent: 'flex-start',
                              marginLeft: 5,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 14,
                            }}
                            placeholder={'Day'}
                            //multiple={true}
                            //customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                            // onValueChange={item => {
                            //   setSelectedEffectiveDay(item.value);
                            // }}
                            onValueChange={{}}
                            defaultValue={selectedEffectiveType}
                            //defaultValue={''}
                            dropDownMaxHeight={350}
                            dropDownStyle={[
                              {
                                width: 250,
                                height: 350,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                borderWidth: 1,
                              },
                              switchMerchant
                                ? {
                                  width: 200,
                                }
                                : {},
                            ]}
                            disabled={true}
                            globalTextStyle={{
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                          >
                            {[{ label: 'Day', value: 'DAY' }].map((value, index) => {
                              return (
                                <Picker.Item
                                key={index}
                                value={value.value}
                                label={value.label}
                                />
                              )
                            })}
                          </Picker>
                        ) : null}
                      </View>
                      <View style={{ marginTop: '2%', zIndex: -2 }}>
                        <Text
                          style={[
                            {
                              alignSelf: 'flex-start',
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Availability
                        </Text>
                        <View style={{ marginTop: '2%' }}>
                          <Multiselect
                          style={[
                            {
                              width: 250,
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                            },
                            switchMerchant
                              ? {
                                width: 200,
                              }
                              : {},
                          ]}
                          options={ORDER_TYPE_DROP_DOWN_LIST}
                          selectedValues={selectedAvailability}
                          onSelect={(items) => {
                            setSelectedAvailability(items);
                          }}
                          onRemove={(items) => {
                            setSelectedAvailability(items);
                          }}
                          displayValue="value"
                          />
                          {/* <Select
                            containerStyle={[
                              { height: 40 },
                              switchMerchant ? { height: 35 } : {},
                            ]}
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold' }}
                            labelStyle={{
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 14,
                            }}
                            style={[
                              {
                                width: 250,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              },
                              switchMerchant
                                ? {
                                  width: 200,
                                }
                                : {},
                            ]}
                            placeholderStyle={{
                              color: Colors.fieldtTxtColor,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 14,
                            }}
                            //items={[{ label: 'Dine In', value: 1 }, { label: 'Takeaway', value: 2 }, { label: 'Delivery', value: 3 } ]}
                            options={ORDER_TYPE_DROP_DOWN_LIST}
                            itemStyle={{
                              justifyContent: 'flex-start',
                              marginLeft: 5,
                              zIndex: 2,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 14,
                            }}
                            defaultValue={selectedAvailability}
                            placeholder={'Select'}
                            multipleText={'%d availability selected'}
                            isMulti
                            customTickIcon={(press) => (
                              <Ionicon
                                name={'md-checkbox'}
                                color={
                                  press
                                    ? Colors.fieldtBgColor
                                    : Colors.primaryColor
                                }
                                size={25}
                              />
                            )}
                            onChange={(items) => {
                              setSelectedAvailability(items);
                            }}
                            dropDownMaxHeight={100}
                            dropDownStyle={[
                              {
                                width: 250,
                                height: 100,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                borderWidth: 1,
                              },
                              switchMerchant
                                ? {
                                  width: 200,
                                }
                                : {},
                            ]}
                            globalTextStyle={{
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                          /> */}
                        </View>
                      </View>
                      <Text
                        style={[
                          {
                            fontWeight: '500',
                            zIndex: -3,
                            marginTop: 5,
                            fontFamily: 'NunitoSans-Bold',
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}>
                        Loyalty Type
                      </Text>
                      <View style={{ marginTop: '2%', zIndex: -3 }}>
                        <Picker
                          containerStyle={[
                            { height: 40 },
                            switchMerchant ? { height: 35 } : {},
                          ]}
                          arrowColor={'black'}
                          arrowSize={20}
                          arrowStyle={{ fontWeight: 'bold' }}
                          labelStyle={{
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          style={[
                            {
                              width: 250,
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                            },
                            switchMerchant
                              ? {
                                width: 200,
                              }
                              : {},
                          ]}
                          placeholderStyle={{
                            color: Colors.fieldtTxtColor,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          itemStyle={{
                            justifyContent: 'flex-start',
                            marginLeft: 5,
                            zIndex: 2,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          placeholder={'Select'}
                          //multiple={true}
                          customTickIcon={(press) => (
                            <Ionicon
                              name={'md-checkbox'}
                              color={
                                press
                                  ? Colors.fieldtBgColor
                                  : Colors.primaryColor
                              }
                              size={25}
                            />
                          )}
                          onValueChange={(item) => {
                            setSelectedLoyaltyCampaignType(item);
                          }}
                          defaultValue={selectedLoyaltyCampaignType}
                          dropDownMaxHeight={150}
                          dropDownStyle={[
                            {
                              width: 250,
                              height: 150,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              borderWidth: 1,
                              textAlign: 'left',
                              zIndex: 2,
                            },
                            switchMerchant
                              ? {
                                width: 200,
                              }
                              : {},
                          ]}
                          globalTextStyle={{
                            fontSize: switchMerchant ? 10 : 14,
                          }}
                        //dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 200, paddingVertical: 0, margin: 5, marginLeft: 0 }}
                        >
                          {LOYALTY_CAMPAIGN_DROPDOWN_LIST.map((value, index) => {
                            return (
                              <Picker.Item
                              key={index}
                              value={value.value}
                              label={value.label}
                              />
                            )
                          })}
                        </Picker>
                      </View>
                    </View>
                    <View style={{ flex: 1, zIndex: -1, marginTop: 1 }}>
                      <Text
                        style={[
                          {
                            fontWeight: '500',
                            fontFamily: 'NunitoSans-Bold',
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}>
                        Apply To Outlets
                      </Text>
                      <View style={{ marginTop: 4 }}>
                        <Multiselect
                        style={{
                          width: '100%',
                          paddingVertical: 0,
                          backgroundColor: Colors.fieldtBgColor,
                          borderRadius: 10,
                        }}
                        options={outletDropdownList}
                        selectedValues={selectedOutletList}
                        onSelect={(items) => {
                          setSelectedOutletList(items);
                        }}
                        onRemove={(items) => {
                          setSelectedOutletList(items);
                        }}
                        displayValue='label'
                        />
                        {/* <Select
                          containerStyle={[
                            { height: 40 },
                            switchMerchant
                              ? {
                                height: 35,
                              }
                              : {},
                          ]}
                          arrowColor={'black'}
                          arrowSize={20}
                          arrowStyle={{ fontWeight: 'bold' }}
                          labelStyle={{
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          style={{
                            width: '100%',
                            paddingVertical: 0,
                            backgroundColor: Colors.fieldtBgColor,
                            borderRadius: 10,
                          }}
                          placeholderStyle={{
                            color: Colors.fieldtTxtColor,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          // items={[{ label: 'Kota Damansara', value: 1 }, { label: 'Petaling Jaya', value: 2 }, { label: 'Loke Yo', value: 3 }, { label: 'Puchong', value: 4 }, { label: 'Daigong', value: 5 }, { label: 'KL', value: 6 }]}
                          options={outletDropdownList}
                          itemStyle={{
                            justifyContent: 'flex-start',
                            marginLeft: 5,
                            zIndex: 2,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          defaultValue={selectedOutletList}
                          placeholder={'Select outlet(s)'}
                          multipleText={'%d outlet(s) selected'}
                          isMulti
                          customTickIcon={(press) => (
                            <Ionicon
                              name={'md-checkbox'}
                              color={
                                press
                                  ? Colors.fieldtBgColor
                                  : Colors.primaryColor
                              }
                              size={25}
                            />
                          )}
                          onChange={(items) => {
                            setSelectedOutletList(items);
                          }}
                          dropDownMaxHeight={100}
                          dropDownStyle={{
                            width: '100%',
                            height: 100,
                            backgroundColor: Colors.fieldtBgColor,
                            borderRadius: 10,
                            borderWidth: 1,
                          }}
                          globalTextStyle={{
                            fontSize: switchMerchant ? 10 : 14,
                          }}
                        /> */}
                      </View>

                      {/* <View style={{ marginTop: '2%', zIndex: -2, }}>
                        <Text style={[{ alignSelf: 'flex-start', fontWeight: '500', fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? {
                          fontSize: 10,
                        } : {}]}>
                          Promo Time
                        </Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                          <TouchableOpacity onPress={() => {
                            setShowPromoTimeStartPicker(true);
                          }}
                            style={[{
                              backgroundColor: Colors.fieldtBgColor,
                              // width: 115,
                              width: '45%',
                              height: 40,
                              borderRadius: 5,
                              padding: 1,
                              marginVertical: 5,
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              fontFamily: 'NunitoSans-Regular', fontSize: 14
                            }, switchMerchant ? {
                              height: 35
                            } : {}]}>
                            <Text style={[{ fontVariant: ['tabular-nums'], fontFamily: 'NunitoSans-Regular', fontSize: 14 }, switchMerchant ? {
                              fontSize: 10,
                            } : {}]}>{moment(promoTimeStart).format('hh:mm A')}</Text>
                          </TouchableOpacity>
                          <Text style={[{ width: '10%', fontWeight: '500', textAlign: 'center', fontFamily: 'NunitoSans-Regular', fontSize: 14 }, switchMerchant ? {
                            fontSize: 10,
                          } : {}]}>to</Text>
                          <TouchableOpacity onPress={() => {
                            setShowPromoTimeEndPicker(true);
                          }}
                            style={[{
                              backgroundColor: Colors.fieldtBgColor,
                              // width: 115,
                              width: '44.5%',
                              height: 40,
                              borderRadius: 5,
                              padding: 1,
                              marginVertical: 5,
                              alignItems: 'center',
                              justifyContent: 'center',
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              fontFamily: 'NunitoSans-Regular', fontSize: 14
                            }, switchMerchant ? {
                              height: 35
                            } : {}]}>
                            <Text style={[{ fontVariant: ['tabular-nums'], fontFamily: 'NunitoSans-Regular', fontSize: 14 }, switchMerchant ? {
                              fontSize: 10,
                            } : {}]}>{moment(promoTimeEnd).format('hh:mm A')}</Text>
                          </TouchableOpacity>
                        </View>
                      </View> */}

                      <View style={{ flexDirection: 'row', zIndex: -2 }}>
                        <Text
                          style={[
                            {
                              marginTop: 5,
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Effective
                        </Text>
                        <Text
                          style={[
                            {
                              marginTop: 5,
                              marginLeft: 5,
                              fontWeight: '500',
                              color: 'red',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Options
                        </Text>
                      </View>

                      <View style={{ marginTop: '2%', zIndex: -2 }}>
                        <Multiselect
                        style={{
                          width: '100%',
                          paddingVertical: 0,
                          backgroundColor: Colors.fieldtBgColor,
                          borderRadius: 10,
                        }}
                        options={EFFECTIVE_DAY_DROPDOWN_LIST}
                        selectedValues={selectedEffectiveTypeOptions}
                        onSelect={(items) => {
                          // setSelectedEffectiveDay(item.value);
                          setSelectedEffectiveTypeOptions(items);
                        }}
                        onRemove={(items) => {
                          // setSelectedEffectiveDay(item.value);
                          setSelectedEffectiveTypeOptions(items);
                        }}
                        displayValue='value'
                        />
                        {/* <Select
                          containerStyle={[
                            { height: 40 },
                            switchMerchant
                              ? {
                                height: 35,
                              }
                              : {},
                          ]}
                          arrowColor={'black'}
                          arrowSize={20}
                          arrowStyle={{ fontWeight: 'bold' }}
                          labelStyle={{
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          style={{
                            width: '100%',
                            paddingVertical: 0,
                            backgroundColor: Colors.fieldtBgColor,
                            borderRadius: 10,
                          }}
                          placeholderStyle={{
                            color: Colors.fieldtTxtColor,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          // items={[{ label: 'Everyone', value: 1 }, { label: 'Active Member', value: 2 }, { label: 'Inactive Member', value: 3 }, { label: 'Female', value: 4 }, { label: 'Male', value: 5 }, { label: 'VIP Only', value: 6 }]}
                          options={EFFECTIVE_DAY_DROPDOWN_LIST}
                          itemStyle={{
                            justifyContent: 'flex-start',
                            marginLeft: 5,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          placeholder={'Monday'}
                          isMulti
                          multipleText={'%d day(s) selected'}
                          customTickIcon={(press) => (
                            <Ionicon
                              name={'md-checkbox'}
                              color={
                                press
                                  ? Colors.fieldtBgColor
                                  : Colors.primaryColor
                              }
                              size={25}
                            />
                          )}
                          onChange={(items) => {
                            // setSelectedEffectiveDay(item.value);
                            setSelectedEffectiveTypeOptions(items);
                          }}
                          // defaultValue={selectedEffectiveDay}
                          defaultValue={selectedEffectiveTypeOptions}
                          dropDownMaxHeight={150}
                          dropDownStyle={{
                            width: '100%',
                            height: 150,
                            backgroundColor: Colors.fieldtBgColor,
                            borderRadius: 10,
                            borderWidth: 1,
                          }}
                          globalTextStyle={{
                            fontSize: switchMerchant ? 10 : 14,
                          }}
                        /> */}
                      </View>
                      <View style={{ zIndex: 2, marginTop: '2%', zIndex: -3 }}>
                        <Text
                          style={[
                            {
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Target Segment(s)
                        </Text>
                        {/* {
                        crmUserTagsDropdownList.find(item => item.value === selectedTargetUserGroup)
                          ?
                          <View style={{marginTop: '2%'}}>
                            <DropDownPicker containerStyle={{ height: 40, zIndex: 2}}
                              arrowColor={'black'}
                              arrowSize={20}
                              arrowStyle={{ fontWeight: 'bold' }}
                              labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                              style={{ width: 250, height: 40, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 5}}
                              placeholderStyle={{ color: Colors.fieldtTxtColor }}
                              // items={[{ label: 'Everyone', value: 1 }, { label: 'Active Member', value: 2 }, { label: 'Inactive Member', value: 3 }, { label: 'Female', value: 4 }, { label: 'Male', value: 5 }, { label: 'VIP Only', value: 6 }]}
                              // items={TARGET_USER_GROUP_DROPDOWN_LIST}
                              itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                              items={crmUserTagsDropdownList}
                              // placeholder={"Select"}
                              //multiple={true}
                              customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                              onValueChange={item => {
                                setSelectedTargetUserGroup(item.value);
                              }}
                              defaultValue={selectedTargetUserGroup}
                              searchable={true}
                              dropDownMaxHeight={150}
                              dropDownStyle={{ width: 250, height: 150, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1, }}
                            />
                          </View>
                          :
                          <></>
                      } */}

                        {/* {CRM_SEGMENT_DROPDOWN_LIST.concat(
                          crmSegmentsDropdownList,
                        ).find((item) => 
                          selectedTargetSegmentGroupList.includes(item.value)
                        ) ? ( */}
                          <View style={{ marginTop: '2%'}}>
                            <Multiselect
                            style={{
                              width: '100%',
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                            }}
                            options={CRM_SEGMENT_DROPDOWN_LIST.concat(
                              crmSegmentsDropdownList,
                            )}
                            onSelect={(items) => {
                              console.log("HEREEHERHERH: ", CRM_SEGMENT_DROPDOWN_LIST[0].value)
                              // setSelectedTargetUserGroup(item.value);
                              setSelectedTargetSegmentGroupList(items);
                            }}
                            onRemove={(items) => {
                              // setSelectedTargetUserGroup(item.value);
                              setSelectedTargetSegmentGroupList(items);
                            }}
                            selectedValues={selectedTargetSegmentGroupList}
                            displayValue='label'
                            />
                            {/* <Select
                              containerStyle={[
                                { height: 40, zIndex: 2 },
                                switchMerchant
                                  ? {
                                    height: 35,
                                  }
                                  : {},
                              ]}
                              arrowColor={'black'}
                              arrowSize={20}
                              arrowStyle={{ fontWeight: 'bold' }}
                              labelStyle={{
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              }}
                              style={{
                                width: '100%',
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              }}
                              placeholderStyle={{
                                color: Colors.fieldtTxtColor,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              }}
                              // items={[{ label: 'Everyone', value: 1 }, { label: 'Active Member', value: 2 }, { label: 'Inactive Member', value: 3 }, { label: 'Female', value: 4 }, { label: 'Male', value: 5 }, { label: 'VIP Only', value: 6 }]}
                              // items={TARGET_USER_GROUP_DROPDOWN_LIST}
                              itemStyle={{
                                justifyContent: 'flex-start',
                                marginLeft: 5,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              }}
                              options={CRM_SEGMENT_DROPDOWN_LIST.concat(
                                crmSegmentsDropdownList,
                              )}
                              // placeholder={"Select"}
                              isMulti
                              multipleText={'%d segment(s) selected'}
                              customTickIcon={(press) => (
                                <Ionicon
                                  name={'md-checkbox'}
                                  color={
                                    press
                                      ? Colors.fieldtBgColor
                                      : Colors.primaryColor
                                  }
                                  size={25}
                                />
                              )}
                              onChange={(items) => {
                                // setSelectedTargetUserGroup(item.value);
                                setSelectedTargetSegmentGroupList(items);
                              }}
                              defaultValue={selectedTargetSegmentGroupList}
                              searchable={true}
                              dropDownMaxHeight={150}
                              dropDownStyle={{
                                width: '100%',
                                height: 150,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                borderWidth: 1,
                              }}
                              globalTextStyle={{
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            /> */}
                          </View>
                         {/* ) : (
                          <></>
                        )} */}
                      </View>

                      <Text
                        style={[
                          {
                            fontWeight: '500',
                            zIndex: -10,
                            marginTop: 5,
                            fontFamily: 'NunitoSans-Bold',
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}>
                        Promotion Type
                      </Text>
                      <View style={{ marginTop: '2%', zIndex: -10 }}>
                        <Picker
                          containerStyle={[
                            { height: 40 },
                            switchMerchant ? { height: 35 } : {},
                          ]}
                          arrowColor={'black'}
                          arrowSize={20}
                          arrowStyle={{ fontWeight: 'bold' }}
                          labelStyle={{
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          style={[
                            {
                              width: '100%',
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                            },
                            switchMerchant
                              ? {
                                width: '100%',
                              }
                              : {},
                          ]}
                          placeholderStyle={{
                            color: Colors.fieldtTxtColor,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          itemStyle={{
                            justifyContent: 'flex-start',
                            marginLeft: 5,
                            zIndex: 2,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}
                          placeholder={'Select'}
                          //multiple={true}
                          customTickIcon={(press) => (
                            <Ionicon
                              name={'md-checkbox'}
                              color={
                                press
                                  ? Colors.fieldtBgColor
                                  : Colors.primaryColor
                              }
                              size={25}
                            />
                          )}
                          onValueChange={(item) => {
                            setSelectedPromotionType(item);
                          }}
                          defaultValue={selectedPromotionType}
                          dropDownMaxHeight={150}
                          dropDownStyle={[
                            {
                              width: '100%',
                              height: 150,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              borderWidth: 1,
                              textAlign: 'left',
                              zIndex: 2,
                            },
                            switchMerchant
                              ? {
                                width: '100%',
                              }
                              : {},
                          ]}
                          globalTextStyle={{
                            fontSize: switchMerchant ? 10 : 14,
                          }}
                        //dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 200, paddingVertical: 0, margin: 5, marginLeft: 0 }}
                        >
                          {LOYALTY_PROMOTION_TYPE_DROPDOWN_LIST.map((value, index) => {
                            return (
                              <Picker.Item 
                              key={index}
                              value={value.value}
                              label={value.label}
                              />
                            )
                          })}
                        </Picker>
                      </View>

                      {/* <View style={{ flexDirection: 'row', marginTop: '2%', zIndex: -4, alignItems: 'center' }}>
                        <View style={{
                          width: '60%',
                          zIndex: -1
                        }}>
                          <Text style={[{ textAlign: 'left', fontWeight: '500', fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? {
                            fontSize: 10,
                          } : {}]}>
                            Promo Code
                          </Text>
                        </View>

                        <View style={{
                          width: '5%',
                        }}>
                        </View>

                        <View style={{
                          width: '35%',
                        }}>
                          <Text style={[{ textAlign: 'left', color: 'black', fontWeight: '500', fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? {
                            fontSize: 10,
                          } : {}]}>
                            Usage Limit
                          </Text>
                        </View>
                      </View>

                      <View style={{ flexDirection: 'row', marginTop: 5, zIndex: -4 }}>
                        <View style={{
                          width: '60%',
                          zIndex: -1,
                        }}>
                          <TextInput placeholder='Promo Code'
                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: 14}}
                            style={[{
                              backgroundColor: Colors.fieldtBgColor,
                              // width: 160,
                              height: 40,
                              borderRadius: 5,
                              padding: 5,
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              paddingLeft: 10,
                              fontFamily: 'NunitoSans-Regular', 
                              fontSize: 14
                            }, switchMerchant ? {
                              fontSize: 10,
                              height: 35
                            } : {}]}
                            onChangeText={text => {
                              setPromoCode(text);
                            }}
                            defaultValue={promoCode}
                          />
                        </View>

                        <View style={{
                          width: '5%',
                        }}>
                        </View>

                        <View style={{
                          width: '35%',
                        }}>
                          <TextInput placeholder='100'
                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: 14}}
                            style={[{
                              backgroundColor: Colors.fieldtBgColor,
                              // width: 80,
                              height: 40,
                              borderRadius: 5,
                              padding: 5,
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              paddingLeft: 27,
                              fontFamily: 'NunitoSans-Regular', 
                              fontSize: 14
                            }, switchMerchant ? {
                              fontSize: 10,
                              height: 35
                            } : {}]}
                            onChangeText={text => {
                              setPromoCodeUsageLimit(text);
                            }}
                            defaultValue={promoCodeUsageLimit}
                            keyboardType={"number-pad"}
                          />
                        </View>
                      </View> */}

                      {/* <View style={{ flexDirection: 'row', marginVertical: 5, marginTop: '5%' }}>
                        <CheckBox style={{}}
                          onClick={() => {
                            setState({
                              isChecked: !isChecked
                            })
                          }}
                          checkBoxColor={Colors.fieldtBgColor}
                          uncheckedCheckBoxColor={Colors.tabGrey}
                          checkedCheckBoxColor={Colors.primaryColor}
                          isChecked={isChecked}
                        />
                        <Text style={{ alignSelf: 'center', color: '#828282', fontWeight: '500' }}>
                          Promo Code Usage Limit
                                </Text>
                      </View> */}
                    </View>
                  </View>
                  <View style={{ flexDirection: 'column', marginTop: '5%' }}>
                    {/* <CheckBox style={{ marginLeft: '5%' }}
                      onClick={() => {
                        setIsRequireSpecificProductPurchase(!isRequireSpecificProductPurchase);
                      }}
                      checkBoxColor={Colors.fieldtBgColor}
                      uncheckedCheckBoxColor={Colors.tabGrey}
                      checkedCheckBoxColor={Colors.primaryColor}
                      isChecked={isRequireSpecificProductPurchase}
                    />
                    <Text style={{ marginLeft: '2%' }}>Require specific product purchase</Text> */}
                  </View>
                </View>
              </View>

              {/*///////////////////////////////////FIRST_VISIT/////////////////////*/}
              {selectedLoyaltyCampaignType ===
                LOYALTY_CAMPAIGN_TYPE.FIRST_VISIT ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: 10,
                      marginBottom: 20,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '90%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                          style={[
                            {
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          {/* Criteria */}
                          {
                            LOYALTY_CAMPAIGN_TYPE_PARSED[
                            selectedLoyaltyCampaignType
                            ]
                          }
                        </Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          zIndex: -1,
                        }}>
                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            What time of the day should it be sent
                          </Text>
                          <TouchableOpacity
                            onPress={() => {
                              setShowLoyaltyCampaignSendTimePicker(true);
                            }}
                            style={[
                              {
                                backgroundColor: Colors.fieldtBgColor,
                                // width: 115,
                                // width: '45%',
                                width: '20%',
                                height: 40,
                                borderRadius: 5,
                                padding: 1,
                                marginVertical: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  height: 35,
                                }
                                : {},
                            ]}>
                            <Text
                              style={[
                                {
                                  fontVariant: ['tabular-nums'],
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              {loyaltyCampaignSendTime}
                            </Text>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginTop: 10,
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Expiration (days)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              // width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              marginTop: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'14'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              var value =
                                text.length >= 0
                                  ? !isNaN(text)
                                    ? text
                                    : '0'
                                  : '0';

                              setLoyaltyCampaignExpirationDays(value);
                            }}
                            defaultValue={loyaltyCampaignExpirationDays}
                            keyboardType={'number-pad'}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              ) : (
                <></>
              )}

              {/*///////////////////////////////////AT_RISK/////////////////////*/}
              {selectedLoyaltyCampaignType === LOYALTY_CAMPAIGN_TYPE.AT_RISK ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: 10,
                      marginBottom: 20,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '90%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                          style={[
                            {
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          {/* Criteria */}
                          {
                            LOYALTY_CAMPAIGN_TYPE_PARSED[
                            selectedLoyaltyCampaignType
                            ]
                          }
                        </Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          zIndex: -1,
                        }}>
                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            What time of the day should it be sent
                          </Text>
                          <TouchableOpacity
                            onPress={() => {
                              setShowLoyaltyCampaignSendTimePicker(true);
                            }}
                            style={[
                              {
                                backgroundColor: Colors.fieldtBgColor,
                                // width: 115,
                                // width: '45%',
                                width: '20%',
                                height: 40,
                                borderRadius: 5,
                                padding: 1,
                                marginVertical: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  height: 35,
                                }
                                : {},
                            ]}>
                            <Text
                              style={[
                                {
                                  fontVariant: ['tabular-nums'],
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              {loyaltyCampaignSendTime}
                            </Text>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginTop: 10,
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Expiration (days)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              // width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              marginTop: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'14'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              var value =
                                text.length >= 0
                                  ? !isNaN(text)
                                    ? text
                                    : '0'
                                  : '0';

                              setLoyaltyCampaignExpirationDays(value);
                            }}
                            defaultValue={loyaltyCampaignExpirationDays}
                            keyboardType={'number-pad'}
                          />
                        </View>

                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginTop: 10,
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Send when guest hasn't visisted for (days)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              // width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              marginTop: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'14'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              var value =
                                text.length >= 0
                                  ? !isNaN(text)
                                    ? text
                                    : '0'
                                  : '0';

                              setLoyaltyCampaignGuestNotVisitedDays(value);
                            }}
                            defaultValue={loyaltyCampaignGuestNotVisitedDays}
                            keyboardType={'number-pad'}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              ) : (
                <></>
              )}

              {/*///////////////////////////////////BIRTHDAY/////////////////////*/}
              {selectedLoyaltyCampaignType ===
                LOYALTY_CAMPAIGN_TYPE.BIRTHDAY ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: 10,
                      marginBottom: 20,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '90%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                          style={[
                            {
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          {/* Criteria */}
                          {
                            LOYALTY_CAMPAIGN_TYPE_PARSED[
                            selectedLoyaltyCampaignType
                            ]
                          }
                        </Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          zIndex: -1,
                        }}>
                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            What time of the day should it be sent
                          </Text>
                          <TouchableOpacity
                            onPress={() => {
                              setShowLoyaltyCampaignSendTimePicker(true);
                            }}
                            style={[
                              {
                                backgroundColor: Colors.fieldtBgColor,
                                // width: 115,
                                // width: '45%',
                                width: '20%',
                                height: 40,
                                borderRadius: 5,
                                padding: 1,
                                marginVertical: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  height: 35,
                                }
                                : {},
                            ]}>
                            <Text
                              style={[
                                {
                                  fontVariant: ['tabular-nums'],
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              {loyaltyCampaignSendTime}
                            </Text>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginTop: 10,
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Expiration (days)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              // width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              marginTop: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'14'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              var value =
                                text.length >= 0
                                  ? !isNaN(text)
                                    ? text
                                    : '0'
                                  : '0';

                              setLoyaltyCampaignExpirationDays(value);
                            }}
                            defaultValue={loyaltyCampaignExpirationDays}
                            keyboardType={'number-pad'}
                          />
                        </View>

                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginTop: 10,
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Send when guest birthday is in (days)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              // width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              marginTop: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'14'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              var value =
                                text.length >= 0
                                  ? !isNaN(text)
                                    ? text
                                    : '0'
                                  : '0';

                              setLoyaltyCampaignGuestBirthdayBeforeDays(value);
                            }}
                            defaultValue={
                              loyaltyCampaignGuestBirthdayBeforeDays
                            }
                            keyboardType={'number-pad'}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              ) : (
                <></>
              )}

              {/*///////////////////////////////////GROWTH/////////////////////*/}
              {selectedLoyaltyCampaignType === LOYALTY_CAMPAIGN_TYPE.GROWTH ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: 10,
                      marginBottom: 20,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '90%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                          style={[
                            {
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          {/* Criteria */}
                          {
                            LOYALTY_CAMPAIGN_TYPE_PARSED[
                            selectedLoyaltyCampaignType
                            ]
                          }
                        </Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          zIndex: -1,
                        }}>
                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            What time of the day should it be sent
                          </Text>
                          <TouchableOpacity
                            onPress={() => {
                              setShowLoyaltyCampaignSendTimePicker(true);
                            }}
                            style={[
                              {
                                backgroundColor: Colors.fieldtBgColor,
                                // width: 115,
                                // width: '45%',
                                width: '20%',
                                height: 40,
                                borderRadius: 5,
                                padding: 1,
                                marginVertical: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  height: 35,
                                }
                                : {},
                            ]}>
                            <Text
                              style={[
                                {
                                  fontVariant: ['tabular-nums'],
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              {loyaltyCampaignSendTime}
                            </Text>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginTop: 10,
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Expiration (days)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              // width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              marginTop: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'14'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              var value =
                                text.length >= 0
                                  ? !isNaN(text)
                                    ? text
                                    : '0'
                                  : '0';

                              setLoyaltyCampaignExpirationDays(value);
                            }}
                            defaultValue={loyaltyCampaignExpirationDays}
                            keyboardType={'number-pad'}
                          />
                        </View>

                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginTop: 10,
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Send after every (visits)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              // width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              marginTop: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'14'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              var value =
                                text.length >= 0
                                  ? !isNaN(text)
                                    ? text
                                    : '0'
                                  : '0';

                              setLoyaltyCampaignGuestAfterEveryVisits(value);
                            }}
                            defaultValue={loyaltyCampaignGuestAfterEveryVisits}
                            keyboardType={'number-pad'}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              ) : (
                <></>
              )}

              {/*///////////////////////////////////BIG_SPENDER/////////////////////*/}
              {selectedLoyaltyCampaignType ===
                LOYALTY_CAMPAIGN_TYPE.BIG_SPENDER ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: 10,
                      marginBottom: 20,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '90%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                          style={[
                            {
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          {/* Criteria */}
                          {
                            LOYALTY_CAMPAIGN_TYPE_PARSED[
                            selectedLoyaltyCampaignType
                            ]
                          }
                        </Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          zIndex: -1,
                        }}>
                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            What time of the day should it be sent
                          </Text>
                          <TouchableOpacity
                            onPress={() => {
                              setShowLoyaltyCampaignSendTimePicker(true);
                            }}
                            style={[
                              {
                                backgroundColor: Colors.fieldtBgColor,
                                // width: 115,
                                // width: '45%',
                                width: '20%',
                                height: 40,
                                borderRadius: 5,
                                padding: 1,
                                marginVertical: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  height: 35,
                                }
                                : {},
                            ]}>
                            <Text
                              style={[
                                {
                                  fontVariant: ['tabular-nums'],
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              {loyaltyCampaignSendTime}
                            </Text>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginTop: 10,
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Expiration (days)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              // width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              marginTop: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'14'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              var value =
                                text.length >= 0
                                  ? !isNaN(text)
                                    ? text
                                    : '0'
                                  : '0';

                              setLoyaltyCampaignExpirationDays(value);
                            }}
                            defaultValue={loyaltyCampaignExpirationDays}
                            keyboardType={'number-pad'}
                          />
                        </View>

                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginTop: 10,
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Send every time guest spends (RM)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              // width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              marginTop: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'14'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              var value =
                                text.length >= 0
                                  ? !isNaN(text)
                                    ? text
                                    : '0'
                                  : '0';

                              setLoyaltyCampaignGuestAfterEverySpends(value);
                            }}
                            defaultValue={loyaltyCampaignGuestAfterEverySpends}
                            keyboardType={'number-pad'}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              ) : (
                <></>
              )}

              {/*///////////////////////////////////SIGN_UP/////////////////////*/}
              {selectedLoyaltyCampaignType === LOYALTY_CAMPAIGN_TYPE.SIGN_UP ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: 10,
                      marginBottom: 20,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '90%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                          style={[
                            {
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          {/* Criteria */}
                          {
                            LOYALTY_CAMPAIGN_TYPE_PARSED[
                            selectedLoyaltyCampaignType
                            ]
                          }
                        </Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          zIndex: -1,
                        }}>
                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            What time of the day should it be sent
                          </Text>
                          <TouchableOpacity
                            onPress={() => {
                              setShowLoyaltyCampaignSendTimePicker(true);
                            }}
                            style={[
                              {
                                backgroundColor: Colors.fieldtBgColor,
                                // width: 115,
                                // width: '45%',
                                width: '20%',
                                height: 40,
                                borderRadius: 5,
                                padding: 1,
                                marginVertical: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  height: 35,
                                }
                                : {},
                            ]}>
                            <Text
                              style={[
                                {
                                  fontVariant: ['tabular-nums'],
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              {loyaltyCampaignSendTime}
                            </Text>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            marginTop: 10,
                          }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Expiration (days)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              // width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              marginTop: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'14'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              var value =
                                text.length >= 0
                                  ? !isNaN(text)
                                    ? text
                                    : '0'
                                  : '0';

                              setLoyaltyCampaignExpirationDays(value);
                            }}
                            defaultValue={loyaltyCampaignExpirationDays}
                            keyboardType={'number-pad'}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              ) : (
                <></>
              )}

              {/*//////////////////////////////////START////////////////////////////////////////*/}
              {/*//////////////////////////////////Combo Set/Package/Bundle/////////////////////*/}
              {/* here */}
              {selectedPromotionType ===
                LOYALTY_PROMOTION_TYPE.COMBO_SET_OR_PACKAGE_OR_BUNDLE ? (
                <>
                  <View
                    style={{
                      marginTop: 10,
                      marginLeft: 20,
                      marginBottom: 20,
                      marginRight: 20,
                      zIndex: -1,
                    }}>
                    <Text
                      style={[
                        {
                          fontWeight: '500',
                          fontFamily: 'NunitoSans-Bold',
                          fontSize: 14,
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                          }
                          : {},
                      ]}>
                      Criteria
                    </Text>
                    <View
                      style={{
                        marginTop: '1%',
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        width: '80%',
                        borderRadius: 3,
                      }}>
                      <View style={{ margin: '2%' }}>
                        <Text
                          style={{
                            fontWeight: '500',
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: 14,
                          }}>
                          Package Price
                        </Text>
                        <View
                          style={{
                            flexDirection: 'row',
                            marginTop: '1%',
                            alignItems: 'center',
                          }}>
                          <Text
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: '1%',
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 14,
                            }}>
                            (RM)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              //marginLeft: '1%',
                              height: 40,
                              width: 70,
                              textAlign: 'center',
                              borderRadius: 5,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            placeholder={'0'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setPackageItemPrice(text);
                            }}
                            defaultValue={packageItemPrice}
                            keyboardType={'number-pad'}
                          />
                          <Text
                            style={{
                              alignItems: 'center',
                              marginLeft: '2%',
                              marginRight: '2%',
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}>
                            +
                          </Text>
                          <Picker
                            containerStyle={{ height: 40 }}
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold' }}
                            labelStyle={{
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            style={{
                              width: 120,
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                            }}
                            placeholderStyle={{
                              color: Colors.fieldtTxtColor,
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            placeholder={'Tax'}
                            // items={[{ label: 'Tax', value: 1 }]}
                            //defaultValue={packageSelectedTaxList}
                            //multiple={true}
                            customTickIcon={(press) => (
                              <Ionicon
                                name={'md-checkbox'}
                                color={
                                  press
                                    ? Colors.fieldtBgColor
                                    : Colors.primaryColor
                                }
                                size={25}
                              />
                            )}
                            onValueChange={(item) => {
                              // console.log(item)

                              setPackageTax(item);

                              setSelectedTax(item);

                              for (var i = 0; i < currOutletTaxes.length; i++) {
                                if (
                                  currOutletTaxes[i].uniqueId === item
                                ) {
                                  setSelectedTaxName(currOutletTaxes[i].name);
                                  setSelectedTaxRate(currOutletTaxes[i].rate);
                                  break;
                                }
                              }
                            }}
                            defaultValue={selectedTax}
                            dropDownMaxHeight={150}
                            dropDownStyle={{
                              width: 120,
                              height: 80,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              borderWidth: 0,
                            }}
                          >
                            {taxDropdownList.map((value,index) => {
                              return(
                                <Picker.Item
                                key={index}
                                value={value.value}
                                label={value.label}
                                />
                              )
                            })}
                          </Picker>
                          <View
                            style={{
                              flexDirection: 'column',
                              alignItems: 'center',
                              marginLeft: '2%',
                            }}>
                            <View style={{ flexDirection: 'row' }}>
                              <Text
                                style={{
                                  alignItems: 'center',
                                  fontWeight: '500',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}>
                                =
                              </Text>
                              <Text
                                style={{
                                  alignItems: 'center',
                                  marginLeft: '3%',
                                  fontWeight: '500',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}>
                                (RM)
                              </Text>
                            </View>
                            <Text
                              style={{
                                alignItems: 'center',
                                color: '#4E9F7D',
                                fontSize: 10,
                                fontStyle: 'italic',
                              }}>
                              *tax-inclusive
                            </Text>
                          </View>
                          <View
                            style={{
                              marginLeft: '2%',
                              justifyContent: 'center',
                              width: 80,
                              height: 40,
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              justifyContent: 'center',
                              alignItems: 'center',
                              paddingRight: 2,
                              borderRadius: 5,
                            }}>
                            <Text
                              style={{
                                textAlign: 'center',
                              }}>
                              {packagePriceFinal
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            width: '100%',
                            marginTop: '3%',
                            marginBottom: '3%',
                          }}
                        />
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ fontWeight: '500' }}>Product</Text>
                          <Text style={{ marginLeft: '67%', fontWeight: '500' }}>
                            Min. Quantity
                          </Text>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginTop: '1%',
                          }}>
                          <Picker
                            containerStyle={{ height: 50 }}
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold' }}
                            labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                            style={{
                              width: 190,
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              marginTop: '2%',
                            }}
                            placeholderStyle={{
                              color: Colors.fieldtTxtColor,
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            placeholder={'Product'}
                            // items={[{ label: 'Product of Category', value: 1 }, { label: 'Product of Tags', value: 2 }, { label: 'Specific Products', value: 3 }]}
                            //defaultValue={packageSelectedProductList}
                            //multiple={true}
                            customTickIcon={(press) => (
                              <Ionicon
                                name={'md-checkbox'}
                                color={
                                  press
                                    ? Colors.fieldtBgColor
                                    : Colors.primaryColor
                                }
                                size={25}
                              />
                            )}
                            onValueChange={(item) =>
                              // console.log(item)
                              //setPackageSelectedProductList(item)
                              setSelectedVariation(item)
                            }
                            defaultValue={selectedVariation}
                            dropDownMaxHeight={150}
                            dropDownStyle={{
                              width: 190,
                              height: 100,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              borderWidth: 0,
                            }}
                          >
                            {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                              return(
                                <Picker.Item
                                key={index}
                                value={value.value}
                                label={value.label}
                                />
                              )
                            })}
                          </Picker>

                          <Text
                            style={{
                              alignItems: 'center',
                              marginLeft: '2%',
                              marginRight: '2%',
                              fontWeight: '500',
                            }}>
                            +
                          </Text>

                          {variationItemsDropdownList.length > 0
                            &&
                            (
                              selectedVariationItems.filter(itemId => {
                                return variationItemsDropdownList.find(item2 => item2.value === itemId) ? true : false;
                              }).length === selectedVariationItems.length
                              ||
                              selectedVariationItems.length === 0
                            ) ? (
                              <Multiselect
                              style={{
                                width: 190,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                marginTop: '2%',
                              }}
                              options={variationItemsDropdownList}
                              onSelect={(items) =>
                                // console.log(item)
                                //setPackageSelectedCategoryList(item)
                                setSelectedVariationItems(items)
                              }
                              onRemove={(items) =>
                                // console.log(item)
                                //setPackageSelectedCategoryList(item)
                                setSelectedVariationItems(items)
                              }
                              selectedValues={selectedVariationItems}
                              displayValue='value'
                              />
                            // <Select
                            //   containerStyle={{ height: 50 }}
                            //   arrowColor={'black'}
                            //   arrowSize={20}
                            //   arrowStyle={{ fontWeight: 'bold' }}
                            //   labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                            //   style={{
                            //     width: 190,
                            //     paddingVertical: 0,
                            //     backgroundColor: Colors.fieldtBgColor,
                            //     borderRadius: 10,
                            //     marginTop: '2%',
                            //   }}
                            //   placeholderStyle={{
                            //     color: Colors.fieldtTxtColor,
                            //     alignItems: 'center',
                            //     justifyContent: 'center',
                            //   }}
                            //   placeholder={'Products'}
                            //   // items={[{ label: 'Shisheido shampoo', value: 1 }, { label: 'Shisheido conditioner', value: 2 }, { label: 'Shisheido Hair mask', value: 3 }]}
                            //   options={variationItemsDropdownList}
                            //   //defaultValue={packageSelectedCategoryList}
                            //   customTickIcon={(press) => (
                            //     <Ionicon
                            //       name={'md-checkbox'}
                            //       color={
                            //         press
                            //           ? Colors.fieldtBgColor
                            //           : Colors.primaryColor
                            //       }
                            //       size={25}
                            //     />
                            //   )}
                            //   onChange={(items) =>
                            //     // console.log(item)
                            //     //setPackageSelectedCategoryList(item)
                            //     setSelectedVariationItems(items)
                            //   }
                            //   defaultValue={selectedVariationItems}
                            //   isMulti
                            //   dropDownMaxHeight={190}
                            //   dropDownStyle={{
                            //     width: 190,
                            //     height: 100,
                            //     backgroundColor: Colors.fieldtBgColor,
                            //     borderRadius: 10,
                            //     borderWidth: 0,
                            //   }}
                            // />
                          ) : (
                            <></>
                          )}

                          <Text
                            style={{
                              alignItems: 'center',
                              marginLeft: '2%',
                              marginRight: '2%',
                              fontWeight: '500',
                            }}>
                            +
                          </Text>

                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              marginLeft: '2%',
                              height: 40,
                              width: 70,
                              textAlign: 'center',
                              borderRadius: 5,
                            }}
                            placeholder={'0'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{ justifyContent: 'center' }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setPackageMinQuantity(text);
                            }}
                            defaultValue={packageMinQuantity}
                            keyboardType={'number-pad'}
                          />

                          {/* <TouchableOpacity style={{ marginLeft: 25 }}
                          onPress={() => { }}
                        >
                          <MaterialCommunityIcons name='delete-sweep' color={'red'} size={20} />
                        </TouchableOpacity> */}
                        </View>
                      </View>
                    </View>

                    <View style={{ marginTop: 15, width: '80%' }}>
                      <TouchableOpacity
                        style={{
                          alignSelf: 'flex-end',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          borderRadius: 10,
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          width: 125,
                          height: 35,
                        }}
                        onPress={() => { }}>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            alignItems: 'center',
                            paddingLeft: 1.5,
                            borderRadius: 200,
                            width: 20,
                            height: 20,
                          }}>
                          <Icon name="add" color="white" size={18} />
                        </View>
                        <View style={{}}>
                          <Text
                            style={{
                              alignSelf: 'center',
                              color: Colors.primaryColor,
                            }}>
                            Add Criteria
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                  {/* <View style={{ marginTop: 10, marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -1 }}>
                      <Text style={{ fontWeight: '500' }}>Blasting Notification</Text>
                      <View style={{ marginTop: '1%', borderWidth: 1, borderColor: '#E5E5E5', width: '79%' }}>
                        <View style={{ margin: '2%' }}>
                          <View style={{ flexDirection: 'row' }}>

                            <DateTimePickerModal
                              isVisible={showNotificationDatePicker}
                              mode={'date'}
                              onConfirm={(text) => {
                                setNotificationDate(moment(text));

                                setShowNotificationDatePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationDatePicker(false);
                              }}
                            />

                            <DateTimePickerModal
                              isVisible={showNotificationTimePicker}
                              mode={'time'}
                              onConfirm={(text) => {
                                setNotificationTime(moment(text));

                                setShowNotificationTimePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationTimePicker(false);
                              }}
                            />

                            <View style={{ flexDirection: 'column' }}>
                              <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', fontWeight: '500', }}>
                                Send Date
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationDatePicker(true)
                              }} style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 16,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <Text style={{
                                  marginLeft: 15,
                                  color: '#B6B6B6',
                                  fontSize: 14,
                                  fontVariant: ['tabular-nums']
                                }}
                                >
                                  {moment(notificationDate).format('DD MMM YYYY')}
                                </Text>

                                <GCalendar width={20} height={20} style={{ marginRight: 0 }} />

                              </TouchableOpacity>
                            </View>

                            <View style={{ flexDirection: 'column', marginLeft: 15 }}>
                              <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', fontWeight: '500', }}>
                                Send Time
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationTimePicker(true);
                              }} style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 16,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <Text style={{
                                  marginLeft: 15,
                                  color: '#B6B6B6',
                                  fontSize: 14,
                                  fontVariant: ['tabular-nums'],
                                }}
                                >
                                  {moment(notificationTime).format('hh mm A')}
                                </Text>

                                <EvilIcons name="clock" size={25} color={Colors.primaryColor} style={{ marginRight: 0 }} />

                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View> */}
                </>
              ) : (
                <></>
              )}

              {/*///////////////////////////////////Buy A Get B For Free////////////////////////*/}

              {selectedPromotionType ===
                LOYALTY_PROMOTION_TYPE.BUY_A_GET_B_FOR_FREE ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginBottom: 20,
                      marginTop: 10,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '100%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                          style={{
                            fontWeight: '500',
                            fontFamily: 'NunitoSans-Bold',
                            fontSize: switchMerchant ? 10 : 14,
                          }}>
                          Criteria
                        </Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          width: 690,
                          alignItems: 'flex-start',
                        }}>
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text
                            style={{
                              fontWeight: '500',
                              marginRight: 2,
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: switchMerchant ? 10 : 14,
                            }}>
                            Buy
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              width: 40,
                              justifyContent: 'center',
                              textAlign: 'center',
                              margin: 6,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            placeholder={'0'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setBuyInput(text);
                            }}
                            defaultValue={buyInput}
                            keyboardType={'number-pad'}
                          />
                          <View style={{ marginRight: 5 }}>
                            <Picker
                              containerStyle={{
                                height: switchMerchant ? 35 : 40,
                                zIndex: 2,
                              }}
                              arrowColor={'black'}
                              arrowSize={20}
                              arrowStyle={{ fontWeight: 'bold' }}
                              labelStyle={{
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              style={{
                                width: 250,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderStyle={{
                                color: Colors.fieldtTxtColor,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              itemStyle={{
                                justifyContent: 'flex-start',
                                marginLeft: 5,
                                zIndex: 2,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder={'Products'}
                              //multiple={true}
                              customTickIcon={(press) => (
                                <Ionicon
                                  name={'md-checkbox'}
                                  color={
                                    press
                                      ? Colors.fieldtBgColor
                                      : Colors.primaryColor
                                  }
                                  size={25}
                                />
                              )}
                              onValueChange={(item) => {
                                console.log(item);
                                setSelectedVariation(item);
                              }}
                              defaultValue={selectedVariation}
                              dropDownMaxHeight={150}
                              dropDownStyle={{
                                width: 250,
                                height: 80,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                borderWidth: 1,
                                textAlign: 'left',
                                zIndex: 2,
                                fontSize: switchMerchant ? 10 : 16,
                              }}
                            // style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 150, paddingVertical: 0, margin: 6 }}
                            //   dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 150, paddingVertical: 0, margin: 6 }}
                            //   arrowSize={20}
                            //   arrowColor={'black'}
                            //   arrowStyle={{ fontWeight: 'bold' }}
                            //   placeholderStyle={{ marginLeft: 0 }}
                            //   placeholder={"Products"}
                            //   // items={[{ label: 'Product A', value: 1 }, { label: 'Product B', value: 2 }]}
                            //   items={PROMOTION_TYPE_VARIATION_DROPDOWN_LIST}
                            //   itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                            //   //defaultValue={buy1Free1SelectedProductList}
                            //   //multiple={true}
                            //   customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                            //   onValueChange={(item) => {
                            //     console.log(item)
                            //     //setBuy1Free1SelectedProductList(item)
                            //     setSelectedVariation(item.value);
                            //   }}
                            //   defaultValue={selectedVariation}
                            // //multiple={true}
                            >
                              {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                return (
                                  <Picker.Item
                                  key={index}
                                  value={value.value}
                                  label={value.label}
                                  />
                                )
                              })}
                            </Picker>
                          </View>

                          {variationItemsDropdownList.length > 0 &&
                            (variationItemsDropdownList.find(
                              (item) => item.value === selectedVariationItems[0],
                            ) ||
                              selectedVariationItems.length === 0) ? (
                              <Multiselect
                                style={{
                                width: 250,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              options={variationItemsDropdownList}
                              onChange={(items) =>
                                {setSelectedVariationItems(items)}
                              }
                              // onRemove={(items) =>
                              //   {setSelectedVariationItems(items)}
                              // }
                              selectedValues={selectedVariationItems}
                              displayValue='label'
                              />

                            // <Select
                            //   containerStyle={{
                            //     height: switchMerchant ? 35 : 40,
                            //     zIndex: 2,
                            //   }}
                            //   arrowColor={'black'}
                            //   arrowSize={20}
                            //   arrowStyle={{ fontWeight: 'bold' }}
                            //   labelStyle={{
                            //     fontFamily: 'NunitoSans-Regular',
                            //     fontSize: switchMerchant ? 10 : 14,
                            //   }}
                            //   style={{
                            //     width: 250,
                            //     paddingVertical: 0,
                            //     backgroundColor: Colors.fieldtBgColor,
                            //     borderRadius: 10,
                            //     fontFamily: 'NunitoSans-Regular',
                            //     fontSize: switchMerchant ? 10 : 14,
                            //   }}
                            //   placeholderStyle={{
                            //     color: Colors.fieldtTxtColor,
                            //     fontFamily: 'NunitoSans-Regular',
                            //     fontSize: switchMerchant ? 10 : 14,
                            //   }}
                            //   placeholder={'Select a Product'}
                            //   multipleText={'%d products selected'}
                            //   options={variationItemsDropdownList}
                            //   itemStyle={{
                            //     justifyContent: 'flex-start',
                            //     marginLeft: 5,
                            //     zIndex: 2,
                            //     fontFamily: 'NunitoSans-Regular',
                            //     fontSize: switchMerchant ? 10 : 14,
                            //   }}
                            //   customTickIcon={(press) => (
                            //     <Ionicon
                            //       name={'md-checkbox'}
                            //       color={
                            //         press
                            //           ? Colors.fieldtBgColor
                            //           : Colors.primaryColor
                            //       }
                            //       size={25}
                            //     />
                            //   )}
                            //   onChange={(items) =>
                            //     setSelectedVariationItems(items)
                            //   }
                            //   defaultValue={selectedVariationItems}
                            //   isMulti
                            //   searchable={true}
                            //   dropDownMaxHeight={150}
                            //   dropDownStyle={{
                            //     width: 250,
                            //     height: 150,
                            //     backgroundColor: Colors.fieldtBgColor,
                            //     borderRadius: 10,
                            //     borderWidth: 1,
                            //     textAlign: 'left',
                            //     zIndex: 2,
                            //     fontSize: switchMerchant ? 10 : 16,
                            //   }}

                            // // containerStyle={{ height: 35 }}
                            // // arrowColor={'black'}
                            // // arrowSize={20}
                            // // arrowStyle={{ fontWeight: 'bold' }}
                            // // labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                            // // style={{ width: 210, paddingVertical: 0, borderRadius: 10 }}
                            // // placeholderStyle={{ color: Colors.fieldtTxtColor, alignItems: 'center', justifyContent: 'center' }}
                            // // placeholder={"Products"}
                            // // multipleText={'%d products selected'}
                            // // items={variationItemsDropdownList}
                            // // //defaultValue={packageSelectedCategoryList}
                            // // customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                            // // onValueChange={(items) =>
                            // //   // console.log(item)
                            // //   //setPackageSelectedCategoryList(item)
                            // //   setSelectedVariationItems(items)
                            // // }
                            // // defaultValue={selectedVariationItems}
                            // // multiple={true}
                            // // searchable={true}
                            // // dropDownMaxHeight={190}
                            // // dropDownStyle={{ width: 210, height: 190, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1 }}
                            // />
                          ) : (
                            <></>
                          )}
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            zIndex: -1,
                          }}>
                          <Text
                            style={{
                              fontWeight: '500',
                              marginRight: 4,
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: switchMerchant ? 10 : 14,
                            }}>
                            Get
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              width: 40,
                              justifyContent: 'center',
                              textAlign: 'center',
                              margin: 6,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            placeholder={'0'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setGetInput(text);
                            }}
                            defaultValue={getInput}
                            keyboardType={'number-pad'}
                          />

                          <View style={{ marginRight: 5 }}>
                            <Picker
                              containerStyle={{
                                height: switchMerchant ? 35 : 40,
                                zIndex: 2,
                              }}
                              arrowColor={'black'}
                              arrowSize={20}
                              arrowStyle={{ fontWeight: 'bold' }}
                              labelStyle={{
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              style={{
                                width: 250,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderStyle={{
                                color: Colors.fieldtTxtColor,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              itemStyle={{
                                justifyContent: 'flex-start',
                                marginLeft: 5,
                                zIndex: 2,
                              }}
                              placeholder={'Product'}
                              customTickIcon={(press) => (
                                <Ionicon
                                  name={'md-checkbox'}
                                  color={
                                    press
                                      ? Colors.fieldtBgColor
                                      : Colors.primaryColor
                                  }
                                  size={25}
                                />
                              )}
                              onValueChange={(item) => {
                                console.log(item);
                                setSelectedVariationB1F1(item);
                              }}
                              defaultValue={selectedVariationB1F1}
                              dropDownMaxHeight={150}
                              dropDownStyle={{
                                width: 250,
                                height: 80,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                borderWidth: 1,
                                textAlign: 'left',
                                zIndex: 2,
                                fontSize: switchMerchant ? 10 : 16,
                              }}
                            // style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 150, paddingVertical: 0, margin: 6 }}
                            //   dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 150, paddingVertical: 0, margin: 6 }}
                            //   arrowSize={20}
                            //   arrowColor={'black'}
                            //   arrowStyle={{ fontWeight: 'bold' }}
                            //   placeholderStyle={{ marginLeft: 0 }}
                            //   placeholder={"Product"}
                            //   // items={[{ label: 'Product A', value: 1 }, { label: 'Product B', value: 2 }]}
                            //   items={PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.slice(1)}
                            //   itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                            //   //defaultValue={buy1Free1SelectedProductList}
                            //   //multiple={true}
                            //   customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                            //   onValueChange={(item) => {
                            //     console.log(item)
                            //     //setBuy1Free1SelectedProductList(item)
                            //     setSelectedVariationB1F1(item.value);
                            //   }}
                            //   defaultValue={selectedVariationB1F1}
                            //multiple={true}
                            >
                              {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.slice(1, ).map((value, index) => {
                                return(
                                  <Picker.Item
                                  key={index}
                                  value={value.value}
                                  label={value.label}
                                  />
                                )
                              })}
                            </Picker>
                          </View>

                          {variationItemsB1F1DropdownList.length > 0 &&
                            (variationItemsB1F1DropdownList.find(
                              (item) =>
                                item.value === selectedVariationItemsB1F1[0],
                            ) ||
                              selectedVariationItemsB1F1.length === 0) ? (
                              <Multiselect
                              style={{
                                width: 250,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              }}
                              options={variationItemsB1F1DropdownList}
                              selectedValues={selectedVariationItemsB1F1}
                              onChange={(items) => {
                                setSelectedVariationItemsB1F1(items)
                              }}
                              // onRemove={(items) => {
                              //   setSelectedVariationItemsB1F1(items)
                              // }}
                              displayValue='label'
                              />
                            // <Select
                            //   containerStyle={{
                            //     height: switchMerchant ? 35 : 40,
                            //     zIndex: 2,
                            //   }}
                            //   arrowColor={'black'}
                            //   arrowSize={20}
                            //   arrowStyle={{ fontWeight: 'bold' }}
                            //   labelStyle={{
                            //     fontFamily: 'NunitoSans-Regular',
                            //     fontSize: switchMerchant ? 10 : 14,
                            //   }}
                            //   style={{
                            //     width: 250,
                            //     paddingVertical: 0,
                            //     backgroundColor: Colors.fieldtBgColor,
                            //     borderRadius: 10,
                            //   }}
                            //   placeholderStyle={{
                            //     color: Colors.fieldtTxtColor,
                            //     fontFamily: 'NunitoSans-Regular',
                            //     fontSize: switchMerchant ? 10 : 14,
                            //   }}
                            //   itemStyle={{
                            //     justifyContent: 'flex-start',
                            //     marginLeft: 5,
                            //     zIndex: 2,
                            //     fontFamily: 'NunitoSans-Regular',
                            //     fontSize: switchMerchant ? 10 : 14,
                            //   }}
                            //   placeholder={'Select a Product'}
                            //   multipleText={'%d product(s) selected'}
                            //   options={variationItemsB1F1DropdownList}
                            //   customTickIcon={(press) => (
                            //     <Ionicon
                            //       name={'md-checkbox'}
                            //       color={
                            //         press
                            //           ? Colors.fieldtBgColor
                            //           : Colors.primaryColor
                            //       }
                            //       size={25}
                            //     />
                            //   )}
                            //   onChange={(items) => {
                            //     console.log("HERE: ", items)
                            //     setSelectedVariationItemsB1F1(items)
                            //   }}
                            //   defaultValue={selectedVariationItemsB1F1}
                            //   isMulti
                            //   searchable={true}
                            //   dropDownMaxHeight={150}
                            //   dropDownStyle={{
                            //     width: 250,
                            //     height: 150,
                            //     backgroundColor: Colors.fieldtBgColor,
                            //     borderRadius: 10,
                            //     borderWidth: 1,
                            //     textAlign: 'left',
                            //     zIndex: 2,
                            //     fontSize: switchMerchant ? 10 : 16,
                            //   }}
                            // // containerStyle={{ height: 35 }}
                            // // arrowColor={'black'}
                            // // arrowSize={20}
                            // // arrowStyle={{ fontWeight: 'bold' }}
                            // // labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                            // // style={{ width: 210, paddingVertical: 0, borderRadius: 10 }}
                            // // placeholderStyle={{ color: Colors.fieldtTxtColor, alignItems: 'center', justifyContent: 'center' }}
                            // // placeholder={"Products"}
                            // // multipleText={'%d products selected'}
                            // // items={variationItemsB1F1DropdownList}
                            // // itemStyle={{ justifyContent: 'flex-start', }}
                            // // //defaultValue={packageSelectedCategoryList}
                            // // customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                            // // onValueChange={(items) =>
                            // //   // console.log(item)
                            // //   //setPackageSelectedCategoryList(item)
                            // //   setSelectedVariationItemsB1F1(items)
                            // // }
                            // // defaultValue={selectedVariationItemsB1F1}
                            // // multiple={true}
                            // // searchable={true}
                            // // dropDownMaxHeight={190}
                            // // dropDownStyle={{ width: 210, height: 190, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1 }}
                            // />
                          ) : (
                            <></>
                          )}
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            zIndex: -2,
                          }}>
                          <Text
                            style={{
                              fontWeight: '500',
                              marginRight: 5,
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: switchMerchant ? 10 : 14,
                            }}>
                            Item(s) for
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              margin: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            placeholder={'0.00'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setGetPriceInput(text);
                            }}
                            defaultValue={getPriceInput}
                            keyboardType={'number-pad'}
                          />

                          <Text
                            style={{
                              fontWeight: '500',
                              marginRight: 5,
                              marginLeft: 15,
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: switchMerchant ? 10 : 14,
                            }}>
                            Min Spend Amount (RM)
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: switchMerchant ? 35 : 40,
                              width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              margin: 5,
                              width: '20%',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              //left: 8
                            }}
                            placeholder={'0.00'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              setMinSpend(text);
                            }}
                            defaultValue={minSpend}
                            keyboardType={'number-pad'}
                          />
                        </View>
                      </View>

                      {/* <View style={{ marginTop: 15, }}>
                    <TouchableOpacity style={{ alignSelf: 'flex-end', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', borderRadius: 10, borderWidth: 1, borderColor: Colors.primaryColor, width: 125, height: 35 }}
                      onPress={() => { }}>
                      <View style={{ backgroundColor: Colors.primaryColor, alignItems: 'center', paddingLeft: 1.5, borderRadius: 200, width: 20, height: 20 }}>
                        <Icon name='add' color='white' size={18} />
                      </View>
                      <View style={{}}>
                        <Text style={{ alignSelf: 'center', color: Colors.primaryColor }} >
                          Add Criteria
                    </Text>
                      </View>
                    </TouchableOpacity>
                  </View> */}
                    </View>
                  </View>
                  {/* <View style={{ marginTop: 10, marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -3 }}>
                    <Text style={{ fontWeight: '500', fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>Blasting Notification</Text>
                    <View style={{ marginTop: '1%', borderWidth: 1, borderColor: '#E5E5E5', width: '79%' }}>
                      <View style={{ margin: '2%' }}>
                        <View style={{ flexDirection: 'row' }}>

                          <DateTimePickerModal
                            isVisible={showNotificationDatePicker}
                            mode={'date'}
                            onConfirm={(text) => {
                              setNotificationDate(moment(text));

                              setShowNotificationDatePicker(false);
                            }}
                            onCancel={() => {
                              setShowNotificationDatePicker(false);
                            }}
                          />

                          <DateTimePickerModal
                            isVisible={showNotificationTimePicker}
                            mode={'time'}
                            onConfirm={(text) => {
                              setNotificationTime(moment(text));

                              setShowNotificationTimePicker(false);
                            }}
                            onCancel={() => {
                              setShowNotificationTimePicker(false);
                            }}
                          />

                          <View style={{ flexDirection: 'column' }}>
                            <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14, fontWeight: '500', }}>
                              Send Date
                            </Text>
                            <TouchableOpacity onPress={() => {
                              setShowNotificationDatePicker(true)
                            }} style={{
                              // height: 50,
                              height: switchMerchant ? 35 : 40,
                              paddingHorizontal: 20,
                              backgroundColor: Colors.fieldtBgColor,
                              //marginBottom: 20,
                              width: 160,
                              //marginHorizontal: 10,
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              alignContent: 'center',
                              borderColor: '#E5E5E5',
                              borderWidth: 1,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              borderRadius: 12,
                              marginTop: 10,
                            }}>
                              <GCalendar width={20} height={20} style={{ marginLeft: 0 }} />

                              <Text style={{
                                marginRight: 0,
                                //color: '#B6B6B6',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14
                              }}
                              >
                                {moment(notificationDate).format('DD MMM YYYY')}
                              </Text>
                              
                            </TouchableOpacity>
                          </View>

                          <View style={{ flexDirection: 'column', marginLeft: 15 }}>
                            <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14, fontWeight: '500', }}>
                              Send Time
                            </Text>
                            <TouchableOpacity onPress={() => {
                              setShowNotificationTimePicker(true);
                            }} style={{
                              // height: 50,
                              height: switchMerchant ? 35 : 40,
                              paddingHorizontal: 20,
                              backgroundColor: Colors.fieldtBgColor,
                              //marginBottom: 20,
                              width: 160,
                              //marginHorizontal: 10,
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              alignContent: 'center',
                              borderColor: '#E5E5E5',
                              borderWidth: 1,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              borderRadius: 12,
                              marginTop: 10,
                            }}>
                              <EvilIcons name="clock" size={25} color={Colors.primaryColor} style={{ marginLeft: 0 }} />

                              <Text style={{
                                marginRight: 10,
                                //color: '#B6B6B6',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14
                              }}
                              >
                                {moment(notificationTime).format('hh:mm A')}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View> */}
                </>
              ) : (
                <></>
              )}
              {/*///////////////////////////////////Override Existing Price/////////////////////*/}

              {selectedPromotionType ===
                LOYALTY_PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: 10,
                      marginBottom: 20,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '90%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                          style={[
                            {
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Criteria
                        </Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          zIndex: -1,
                        }}>
                        {/* <Text style={{ fontWeight: '500', zIndex: -2 }}>
                        Price
                      </Text>

                      <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10, zIndex: -2 }}>

                        <View style={{ flexDirection: 'column' }}>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={{ fontWeight: '500', marginRight: 5 }}>
                              (RM)
                            </Text>
                            <TextInput style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: 35,
                              width: 80,
                              justifyContent: 'center',
                              textAlign: 'center',
                              margin: 5
                            }}
                              placeholder={'0'}
                              placeholderStyle={{ justifyContent: 'center' }}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                setOverrideItemPrice(text);
                              }}
                              defaultValue={overrideItemPrice}
                              keyboardType={'number-pad'}

                            />
                            <Text style={{ fontWeight: '500', margin: 5 }}>
                              +
                            </Text>
                          </View>
                          <Text style={{ color: Colors.primaryColor, fontSize: 10 }}>
                            *tax-exclusive
                          </Text>
                        </View>

                        <View style={{ flexDirection: 'column' }}>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            {
                              taxDropdownList.find(item => item.value === overrideTax)
                                ?
                                <DropDownPicker
                                  containerStyle={{ height: 40, zIndex: 2 }}
                                  arrowColor={'black'}
                                  arrowSize={20}
                                  arrowStyle={{ fontWeight: 'bold' }}
                                  labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                                  style={{ width: 250, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, margin: 5 }}
                                  placeholderStyle={{ color: Colors.fieldtTxtColor }}
                                  items={taxDropdownList}
                                  itemStyle={{ justifyContent: 'flex-start', marginLeft: 5, zIndex: 2 }}
                                  placeholder={"Tax"}
                                  //multiple={true}
                                  customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                  onValueChange={(item) => {
                                    setOverrideTax(item.value);
                                    setSelectedTax(item.value);
                                    for (var i = 0; i < currOutletTaxes.length; i++) {
                                      if (currOutletTaxes[i].uniqueId === item.value) {
                                        setSelectedTaxName(currOutletTaxes[i].name);
                                        setSelectedTaxRate(currOutletTaxes[i].rate);
                                        break;
                                      }
                                    }
                                  }}
                                  defaultValue={overrideTax}
                                  dropDownMaxHeight={150}
                                  dropDownStyle={{ width: 250, height: 150, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1, textAlign: 'left', zIndex: 2 }}
                                // style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 130, paddingVertical: 0, margin: 5 }}
                                //   dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 130, paddingVertical: 0, margin: 5 }}
                                //   arrowSize={20}
                                //   arrowColor={'black'}
                                //   arrowStyle={{ fontWeight: 'bold' }}
                                //   placeholderStyle={{ marginLeft: 0 }}
                                //   placeholder={"Tax"}
                                //   // items={[{ label: 'GST: 6%', value: 1 }, { label: 'SST: 7%', value: 2 }]}
                                //   items={taxDropdownList}
                                //   //defaultValue={overrideSelectedCategoryList}
                                //   //multiple={true}
                                //   customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                //   onValueChange={(item) => {
                                //     // console.log(item)

                                //     setOverrideTax(item.value);

                                //     setSelectedTax(item.value);

                                //     for (var i = 0; i < currOutletTaxes.length; i++) {
                                //       if (currOutletTaxes[i].uniqueId === item.value) {
                                //         setSelectedTaxName(currOutletTaxes[i].name);
                                //         setSelectedTaxRate(currOutletTaxes[i].rate);
                                //         break;
                                //       }
                                //     }
                                //   }}
                                //   defaultValue={overrideTax}
                                //   dropDownMaxHeight={100}
                                />
                                :
                                <></>
                            }

                          </View>
                          <Text style={{ color: Colors.primaryColor, fontSize: 10, marginLeft: 5, zIndex: -1 }}>
                            *tax
                          </Text>
                        </View>

                        <View style={{ flexDirection: 'column' }}>
                          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={{ fontWeight: '500', margin: 5 }}>
                              =
                            </Text>
                            <Text style={{ fontWeight: '500', margin: 5 }}>
                              (RM)
                            </Text>

                            <View style={{
                              marginLeft: '2%', justifyContent: 'center',
                              width: 80,
                              height: 35,
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              justifyContent: 'center',
                              alignItems: 'center',
                              paddingRight: 2,
                              margin: 5,
                            }}>
                              <Text style={{
                                textAlign: 'center',
                              }}>{overridePriceFinal.toFixed(2)}</Text>
                            </View>
                          </View>
                          <Text style={{ color: Colors.primaryColor, fontSize: 10, marginLeft: 25 }}>
                            *tax-inclusive
                          </Text>
                        </View>
                      </View> */}
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text
                            style={[
                              {
                                fontWeight: '500',
                                marginRight: 15,
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Min Spend Amount (RM)
                          </Text>
                          <TextInput
                            style={[
                              {
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                borderRadius: 5,
                                height: switchMerchant ? 35 : 40,
                                width: 80,
                                justifyContent: 'center',
                                textAlign: 'center',
                                margin: 5,
                                width: '20%',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                  height: 35,
                                  width: 50,
                                }
                                : {},
                            ]}
                            placeholder={'0.00'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              setMinSpend(text);
                            }}
                            defaultValue={minSpend}
                            keyboardType={'number-pad'}
                          />
                        </View>
                        {/* ~~~~~~~BorderLine~~~~~~~~~~ */}
                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            marginHorizontal: -13,
                            marginVertical: 12,
                            zIndex: -1,
                          }}
                        />

                        <View style={{ flexDirection: 'row', zIndex: -1 }}>
                          <View style={{ flexDirection: 'column' }}>
                            <Text
                              style={[
                                {
                                  fontWeight: '500',
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Product
                            </Text>

                            <View>
                              {overrideItems.map((overrideItem, index) => {
                                return (
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      marginTop: 5,
                                      zIndex:
                                        10001 + overrideItems.length - index,
                                    }}>
                                    <View style={{ marginRight: 10 }}>
                                      {overrideItem.variation ? (
                                        <Picker
                                          containerStyle={[
                                            {
                                              height: switchMerchant ? 35 : 40,
                                              zIndex: 2,
                                            },
                                            switchMerchant
                                              ? {
                                                height: 35,
                                              }
                                              : {},
                                          ]}
                                          arrowColor={'black'}
                                          arrowSize={20}
                                          arrowStyle={{ fontWeight: 'bold' }}
                                          labelStyle={{
                                            fontFamily: 'NunitoSans-Regular',
                                            fontFamily: 'NunitoSans-Regular',
                                            fontSize: switchMerchant ? 10 : 14,
                                          }}
                                          style={[
                                            {
                                              width: 250,
                                              paddingVertical: 0,
                                              backgroundColor:
                                                Colors.fieldtBgColor,
                                              borderRadius: 10,
                                              fontSize: switchMerchant
                                                ? 10
                                                : 14,
                                            },
                                            switchMerchant
                                              ? {
                                                width:
                                                  windowWidth * 0.2,
                                              }
                                              : {},
                                          ]}
                                          placeholderStyle={{
                                            color: Colors.fieldtTxtColor,
                                            fontFamily: 'NunitoSans-Regular',
                                            fontSize: switchMerchant ? 10 : 14,
                                          }}
                                          itemStyle={{
                                            justifyContent: 'flex-start',
                                            marginLeft: 5,
                                            zIndex: 2,
                                            fontFamily: 'NunitoSans-Regular',
                                            fontSize: switchMerchant ? 10 : 14,
                                          }}
                                          placeholder={'Product of Category'}
                                          //multiple={true}
                                          //customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                          onValueChange={(itemDropdown) => {
                                            // setSelectedVariation(item.value)
                                            setOverrideItems(
                                              overrideItems.map((item, i) =>
                                                i === index
                                                  ? {
                                                    ...item,
                                                    variation:
                                                      itemDropdown,
                                                  }
                                                  : item,
                                              ),
                                            );
                                          }}
                                          defaultValue={overrideItem.variation}
                                          dropDownMaxHeight={150}
                                          dropDownStyle={[
                                            {
                                              width: 250,
                                              height: 80,
                                              backgroundColor:
                                                Colors.fieldtBgColor,
                                              borderRadius: 10,
                                              borderWidth: 1,
                                              textAlign: 'left',
                                              zIndex: 2,
                                              fontSize: switchMerchant
                                                ? 10
                                                : 14,
                                            },
                                            switchMerchant
                                              ? {
                                                width:
                                                  windowWidth * 0.2,
                                              }
                                              : {},
                                          ]}
                                          globalTextStyle={{
                                            fontSize: switchMerchant ? 10 : 14,
                                          }}
                                        // style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 200, paddingVertical: 0, margin: 5, marginLeft: 0 }}
                                        // dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 200, paddingVertical: 0, margin: 5, marginLeft: 0 }}
                                        // arrowSize={20}
                                        // arrowColor={'black'}
                                        // arrowStyle={{ fontWeight: 'bold' }}
                                        // placeholderStyle={{ marginLeft: 0, color: Colors.fieldtTxtColor }}
                                        // placeholder={"Product of Category"}
                                        // // items={[{ label: 'Product of Category', value: 1 }, { label: 'Product of Tags', value: 2 }, { label: 'Specific Products', value: 3 }]}
                                        // items={PROMOTION_TYPE_VARIATION_DROPDOWN_LIST}
                                        // itemStyle={{ marginLeft: 5, justifyContent: 'flex-start' }}
                                        // //defaultValue={overrideSelectedProductList}
                                        // //multiple={true}
                                        // customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                        // onValueChange={(item) =>
                                        //   // console.log(item)
                                        //   //setPackageSelectedProductList(item)
                                        //   setSelectedVariation(item.value)
                                        // }
                                        // defaultValue={selectedVariation}
                                        // dropDownMaxHeight={140}
                                        >
                                          {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                            return (
                                              <Picker.Item 
                                              key={index}
                                              value={value.value}
                                              label={value.label}
                                              />
                                            )
                                          })}
                                        </Picker>
                                      ) : null}
                                    </View>

                                    {/* {console.log()} */}

                                    {(
                                      overrideItem.variation ===
                                        PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                        ? variationItemsCategories.length > 0 &&
                                        (variationItemsCategories.find(
                                          (item) =>
                                            item.value ===
                                            overrideItem.variationItems[0],
                                        ) ||
                                          overrideItem.variationItems
                                            .length === 0)
                                        : variationItemsProducts.length > 0 &&
                                        (variationItemsProducts.find(
                                          (item) =>
                                            item.value ===
                                            overrideItem.variationItems[0],
                                        ) ||
                                          overrideItem.variationItems
                                            .length === 0)
                                    ) ? (
                                      // variationItemsDropdownList.length > 0
                                      //   && (variationItemsDropdownList.find(item => item.value === overrideItem.variationItems[0]) || overrideItem.variationItems.length === 0)
                                      <Multiselect
                                      style={[
                                        {
                                          width: 250,
                                          paddingVertical: 0,
                                          backgroundColor:
                                            Colors.fieldtBgColor,
                                          borderRadius: 10,
                                          fontFamily: 'NunitoSans-Regular',
                                          fontSize: switchMerchant ? 10 : 14,
                                        },
                                        switchMerchant
                                          ? {
                                            width:
                                              windowWidth * 0.2,
                                          }
                                          : {},
                                      ]}
                                      options={
                                        overrideItem.variation ===
                                          PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                          ? variationItemsCategories
                                          : variationItemsProducts
                                      }
                                      onChange={(items) => {
                                        // setSelectedVariationItems(items)
                                        console.log("OVERRIDE: ", overrideItems)
                                        setOverrideItems(
                                          overrideItems.map((item, i) =>
                                            i === index
                                              ? {
                                                ...item,
                                                variationItems: items,
                                              }
                                              : item,
                                          ),
                                        );
                                      }}
                                      // onRemove={(items) => {
                                      //   // setSelectedVariationItems(items)
                                      //   console.log("OVERRIDE: ", overrideItems)
                                      //   setOverrideItems(
                                      //     overrideItems.map((item, i) =>
                                      //       i === index
                                      //         ? {
                                      //           ...item,
                                      //           variationItems: items,
                                      //         }
                                      //         : item,
                                      //     ),
                                      //   );
                                      // }}
                                      selectedValues={
                                        overrideItem.variationItems
                                      }
                                      displayValue='label'
                                      />
                                      // <Select
                                      //   containerStyle={[
                                      //     {
                                      //       height: switchMerchant ? 35 : 40,
                                      //       zIndex: 2,
                                      //     },
                                      //     switchMerchant
                                      //       ? {
                                      //         height: 35,
                                      //       }
                                      //       : {},
                                      //   ]}
                                      //   arrowColor={'black'}
                                      //   arrowSize={20}
                                      //   arrowStyle={{ fontWeight: 'bold' }}
                                      //   labelStyle={{
                                      //     fontFamily: 'NunitoSans-Regular',
                                      //     fontFamily: 'NunitoSans-Regular',
                                      //     fontSize: switchMerchant ? 10 : 14,
                                      //   }}
                                      //   style={[
                                      //     {
                                      //       width: 250,
                                      //       paddingVertical: 0,
                                      //       backgroundColor:
                                      //         Colors.fieldtBgColor,
                                      //       borderRadius: 10,
                                      //       fontFamily: 'NunitoSans-Regular',
                                      //       fontSize: switchMerchant ? 10 : 14,
                                      //     },
                                      //     switchMerchant
                                      //       ? {
                                      //         width:
                                      //           windowWidth * 0.2,
                                      //       }
                                      //       : {},
                                      //   ]}
                                      //   placeholderStyle={{
                                      //     color: Colors.fieldtTxtColor,
                                      //   }}
                                      //   options={
                                      //     overrideItem.variation ===
                                      //       PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                      //       ? variationItemsCategories
                                      //       : variationItemsProducts
                                      //   }
                                      //   // items={variationItemsDropdownList}
                                      //   itemStyle={{
                                      //     justifyContent: 'flex-start',
                                      //     marginLeft: 5,
                                      //     zIndex: 2,
                                      //     fontFamily: 'NunitoSans-Regular',
                                      //     fontSize: switchMerchant ? 10 : 14,
                                      //   }}
                                      //   placeholder={
                                      //     overrideItem.variation ===
                                      //       PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                      //       ? 'Select a Category'
                                      //       : 'Select a Product'
                                      //   }
                                      //   multipleText={
                                      //     overrideItem.variation ===
                                      //       PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                      //       ? '%d category(s) selected'
                                      //       : '%d product(s) selected'
                                      //   }
                                      //   customTickIcon={(press) => (
                                      //     <Ionicon
                                      //       name={'md-checkbox'}
                                      //       color={
                                      //         press
                                      //           ? Colors.fieldtBgColor
                                      //           : Colors.primaryColor
                                      //       }
                                      //       size={25}
                                      //     />
                                      //   )}
                                      //   onChange={(items) => {
                                      //     // setSelectedVariationItems(items)
                                      //     console.log("OVERRIDE: ", overrideItems)
                                      //     setOverrideItems(
                                      //       overrideItems.map((item, i) =>
                                      //         i === index
                                      //           ? {
                                      //             ...item,
                                      //             variationItems: items,
                                      //           }
                                      //           : item,
                                      //       ),
                                      //     );
                                      //   }}
                                      //   defaultValue={
                                      //     overrideItem.variationItems
                                      //   }
                                      //   isMulti
                                      //   searchable={true}
                                      //   dropDownMaxHeight={150}
                                      //   dropDownStyle={[
                                      //     {
                                      //       width: 250,
                                      //       height: 150,
                                      //       backgroundColor:
                                      //         Colors.fieldtBgColor,
                                      //       borderRadius: 10,
                                      //       borderWidth: 1,
                                      //       textAlign: 'left',
                                      //       zIndex: 2,
                                      //       fontSize: switchMerchant ? 10 : 14,
                                      //     },
                                      //     switchMerchant
                                      //       ? {
                                      //         width:
                                      //           windowWidth * 0.2,
                                      //       }
                                      //       : {},
                                      //   ]}
                                      //   globalTextStyle={{
                                      //     fontSize: switchMerchant ? 10 : 14,
                                      //   }}
                                      // // style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 230, paddingVertical: 0, margin: 5 }}
                                      // //   dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 230, paddingVertical: 0, margin: 5 }}
                                      // //   arrowSize={20}
                                      // //   arrowColor={'black'}
                                      // //   arrowStyle={{ fontWeight: 'bold' }}
                                      // //   placeholderStyle={{ marginLeft: 0, color: Colors.fieldtTxtColor }}
                                      // //   placeholder={"Products"}
                                      // //   multipleText={'%d products selected'}
                                      // //   // items={[{ label: 'Shisheido shampoo', value: 1 }, { label: 'Shisheido conditioner', value: 2 }, { label: 'Shisheido Hair mask', value: 3 }]}
                                      // //   items={variationItemsDropdownList}
                                      // //   //defaultValue={overrideSelectedCategoryList}
                                      // //   customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                      // //   onValueChange={(items) =>
                                      // //     // console.log(item)
                                      // //     //setPackageSelectedCategoryList(item)
                                      // //     setSelectedVariationItems(items)
                                      // //   }
                                      // //   defaultValue={selectedVariationItems}
                                      // //   multiple={true}
                                      // //   searchable={true}
                                      // //   dropDownMaxHeight={160}
                                      // />
                                    ) : (
                                      <></>
                                    )}

                                    <View
                                      style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}>
                                      <Text
                                        style={[
                                          {
                                            fontWeight: '500',
                                            marginRight: 5,
                                            marginLeft: 15,
                                            bottom: 5,
                                            fontFamily: 'NunitoSans-Regular',
                                            fontSize: 14,
                                          },
                                          switchMerchant
                                            ? {
                                              fontSize: 10,
                                            }
                                            : {},
                                        ]}>
                                        RM
                                      </Text>
                                      <TextInput
                                        style={[
                                          {
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                            borderRadius: 5,
                                            height: switchMerchant ? 35 : 40,
                                            width: 80,
                                            justifyContent: 'center',
                                            textAlign: 'center',
                                            margin: 5,
                                            bottom: 5,
                                            fontFamily: 'NunitoSans-Regular',
                                            fontSize: 14,
                                            // paddingTop: 2,
                                          },
                                          switchMerchant
                                            ? {
                                              fontSize: 10,
                                              height: 35,
                                              width: 50,
                                            }
                                            : {},
                                        ]}
                                        placeholder={'0'}
                                        placeholderTextColor={Platform.select({
                                          ios: '#a9a9a9',
                                        })}
                                        placeholderStyle={{
                                          justifyContent: 'center',
                                          fontFamily: 'NunitoSans-Regular',
                                          fontSize: switchMerchant ? 10 : 14,
                                        }}
                                        onChangeText={(text) => {
                                          // setState({ itemPrice: text });
                                          // setOverrideItemPrice(text);

                                          setOverrideItems(
                                            overrideItems.map((item, i) =>
                                              i === index
                                                ? {
                                                  ...item,
                                                  priceBeforeTax:
                                                    text.length > 0
                                                      ? parseFloat(text)
                                                      : 0,
                                                }
                                                : item,
                                            ),
                                          );
                                        }}
                                        defaultValue={overrideItem.priceBeforeTax
                                          .toFixed(2)
                                          .replace(
                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                            '$1,',
                                          )}
                                        keyboardType={'number-pad'}
                                      />

                                      <View
                                        style={{
                                          marginLeft: 10,
                                          bottom: 5,
                                        }}>
                                        {index === 0 ? (
                                          <TouchableOpacity
                                            onPress={() => {
                                              // onadditem1();

                                              setOverrideItems([
                                                ...overrideItems,
                                                {
                                                  priceBeforeTax: 0,
                                                  variation:
                                                    PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
                                                  variationItems: [],
                                                  variationItemsSku: [],
                                                },
                                              ]);
                                            }}
                                            style={{
                                              marginBottom: '3%',
                                              backgroundColor:
                                                Colors.whiteColor,
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                            }}>
                                            <Feather
                                              name="plus-circle"
                                              size={switchMerchant ? 17 : 20}
                                              color={Colors.primaryColor}
                                            />
                                          </TouchableOpacity>
                                        ) : (
                                          <TouchableOpacity
                                            style={{ marginBottom: '3%' }}
                                            onPress={() => {
                                              setOverrideItems([
                                                ...overrideItems.slice(
                                                  0,
                                                  index,
                                                ),
                                                ...overrideItems.slice(
                                                  index + 1,
                                                ),
                                              ]);
                                            }}>
                                            <Feather
                                              name="minus-circle"
                                              size={switchMerchant ? 17 : 20}
                                              color="#eb3446"
                                            />
                                          </TouchableOpacity>
                                        )}
                                      </View>
                                    </View>
                                  </View>
                                );
                              })}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </>
              ) : (
                <></>
              )}

              {/*///////////////////////////////////PWP/////////////////////////////////////////*/}

              {selectedPromotionType === LOYALTY_PROMOTION_TYPE.PWP ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: 10,
                      marginBottom: 20,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '85%' }}>
                      <Text style={{ alignSelf: 'flex-end' }}>
                        RM(tax-exclusive price)+(tax) = RM(tax-inclusive price)
                      </Text>
                    </View>
                    <View style={{ marginBottom: 5, marginTop: 0 }}>
                      <Text style={{ fontWeight: '500' }}>Criteria</Text>
                    </View>
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        borderRadius: 3,
                        width: '85%',
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          margin: 15,
                          marginBottom: 0,
                        }}>
                        <View style={{ marginRight: 10 }}>
                          <Text style={{ marginBottom: 5, fontWeight: '500' }}>
                            Buy
                          </Text>
                          <Picker
                            containerStyle={{ height: 35 }}
                            arrowColor={'black'}
                            arrowSize={20}
                            labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                            style={{
                              width: 110,
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                            }}
                            placeholderStyle={{ color: Colors.fieldtTxtColor }}
                            placeholder={'Select'}
                            onValueChange={(selectedOutlet) => { }}
                            dropDownMaxHeight={100}
                            dropDownStyle={{
                              width: 110,
                              height: 100,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              borderWidth: 0,
                            }}
                          >
                            {[
                              { label: 'Coca Cola', value: 1 },
                              { label: '100 Plus', value: 2 },
                              { label: 'Kari Cintan', value: 3 },
                              { label: 'KitKat', value: 4 },
                            ].map((value, index) => {
                              return (
                                <Picker.Item
                                key={index}
                                value={value.value}
                                label={value.label}
                                />
                              )
                            })}
                          </Picker>
                        </View>

                        <View style={{ marginHorizontal: 10 }}>
                          <Text style={{ marginBottom: 5, fontWeight: '500' }}>
                            Get
                          </Text>
                          <Picker
                            containerStyle={{ height: 35 }}
                            arrowColor={'black'}
                            arrowSize={20}
                            labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                            style={{
                              width: 110,
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                            }}
                            placeholderStyle={{ color: Colors.fieldtTxtColor }}
                            placeholder={'Select'}
                            onValueChange={(selectedOutlet) => { }}
                            dropDownMaxHeight={100}
                            dropDownStyle={{
                              width: 110,
                              height: 100,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              borderWidth: 0,
                            }}
                          >
                            {[
                              { label: 'Coca Cola', value: 1 },
                              { label: '100 Plus', value: 2 },
                              { label: 'Kari Cintan', value: 3 },
                              { label: 'KitKat', value: 4 },
                            ].map((value, index) => {
                              return (
                                <Picker.Item
                                  key={index}
                                  value={value.value}
                                  label={value.label}
                                />
                              )
                            })}
                          </Picker>
                        </View>

                        <View style={{ marginHorizontal: 10 }}>
                          <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                            <Text style={{ fontWeight: '500' }}>RM</Text>
                            <Text
                              style={{
                                color: Colors.primaryColor,
                                fontSize: 10,
                              }}>
                              *tax-exclusive
                            </Text>
                          </View>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              width: 90,
                              height: 35,
                              paddingLeft: 10,
                            }}
                            placeholder="0"
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            keyboardType="number-pad"
                          />
                        </View>

                        <Text style={{ alignSelf: 'center', paddingTop: 15 }}>
                          {' '}
                          +{' '}
                        </Text>

                        <View style={{ marginHorizontal: 10 }}>
                          <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                            <Text style={{ fontWeight: '500' }}>RM</Text>
                            <Text
                              style={{
                                color: Colors.primaryColor,
                                fontSize: 10,
                              }}>
                              *tax
                            </Text>
                          </View>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              width: 90,
                              height: 35,
                              paddingLeft: 10,
                            }}
                            placeholder="0"
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            keyboardType="number-pad"
                          />
                        </View>

                        <Text style={{ alignSelf: 'center', paddingTop: 15 }}>
                          {' '}
                          ={' '}
                        </Text>

                        <View style={{ marginHorizontal: 10 }}>
                          <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                            <Text style={{ fontWeight: '500' }}>RM</Text>
                            <Text
                              style={{
                                color: Colors.primaryColor,
                                fontSize: 10,
                              }}>
                              *tax-inclusive price
                            </Text>
                          </View>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              width: 90,
                              height: 35,
                              paddingLeft: 10,
                            }}
                            placeholder="0"
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            keyboardType="number-pad"
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          margin: 15,
                          marginTop: 25,
                          zIndex: -1,
                          alignItems: 'center',
                        }}>
                        <Text style={{ marginRight: 5, fontWeight: '500' }}>
                          Min. Quantity
                        </Text>
                        <TextInput
                          style={{
                            marginRight: 15,
                            borderWidth: 1,
                            borderRadius: 5,
                            borderColor: '#E5E5E5',
                            width: 80,
                            height: 35,
                            padding: 5,
                          }}
                          placeholder="e.g. 1"
                          placeholderTextColor={Platform.select({
                            ios: '#a9a9a9',
                          })}
                          keyboardType="number-pad"
                        />
                        <Text style={{ marginRight: 5, fontWeight: '500' }}>
                          Max. Quantity
                        </Text>
                        <TextInput
                          style={{
                            borderWidth: 1,
                            borderRadius: 5,
                            borderColor: '#E5E5E5',
                            width: 80,
                            height: 35,
                            padding: 5,
                          }}
                          placeholder="e.g. 2"
                          placeholderTextColor={Platform.select({
                            ios: '#a9a9a9',
                          })}
                          keyboardType="number-pad"
                        />
                      </View>
                    </View>
                    <View>
                      <View style={{ width: '85%', marginVertical: 15 }}>
                        <TouchableOpacity
                          style={{
                            alignSelf: 'flex-end',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            borderRadius: 10,
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            width: 125,
                            height: 35,
                          }}
                          onPress={() => { }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              alignItems: 'center',
                              paddingLeft: 1.5,
                              borderRadius: 200,
                              width: 20,
                              height: 20,
                            }}>
                            <Icon name="add" color="white" size={18} />
                          </View>
                          <View style={{}}>
                            <Text
                              style={{
                                alignSelf: 'center',
                                color: Colors.primaryColor,
                              }}>
                              Add Criteria
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                      marginLeft: 20,
                      marginRight: 20,
                      marginBottom: 20,
                      zIndex: -3,
                    }}>
                    {/* <Text style={{ fontWeight: '500' }}>Blasting Notification</Text>
                      <View style={{ marginTop: '1%', borderWidth: 1, borderColor: '#E5E5E5', width: '79%' }}>
                        <View style={{ margin: '2%' }}>
                          <View style={{ flexDirection: 'row' }}>

                            <DateTimePickerModal
                              isVisible={showNotificationDatePicker}
                              mode={'date'}
                              onConfirm={(text) => {
                                setNotificationDate(moment(text));

                                setShowNotificationDatePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationDatePicker(false);
                              }}
                            />

                            <DateTimePickerModal
                              isVisible={showNotificationTimePicker}
                              mode={'time'}
                              onConfirm={(text) => {
                                setNotificationTime(moment(text));

                                setShowNotificationTimePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationTimePicker(false);
                              }}
                            />

                            <View style={{ flexDirection: 'column' }}>
                              <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', fontWeight: '500', }}>
                                Send Date
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationDatePicker(true)
                              }} style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 16,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <Text style={{
                                  marginLeft: 15,
                                  color: '#B6B6B6',
                                  fontSize: 14
                                }}
                                >
                                  {moment(notificationDate).format('DD/MM/YYYY')}
                                </Text>
                                
                                <GCalendar width={20} height={20} style={{ marginRight: 0 }} />
                              </TouchableOpacity>
                            </View>

                            <View style={{ flexDirection: 'column', marginLeft: 15 }}>
                              <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', fontWeight: '500', }}>
                                Send Time
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationTimePicker(true);
                              }} style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 16,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <Text style={{
                                  marginLeft: 15,
                                  color: '#B6B6B6',
                                  fontSize: 14
                                }}
                                >
                                  {moment(notificationTime).format('hh:mm a')}
                                </Text>

                                <EvilIcons name="clock" size={25} color={Colors.primaryColor} style={{ marginRight: 0 }} />

                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View> */}
                  </View>
                </>
              ) : (
                <></>
              )}

              {/*///////////////////////////////////Cashback////////////////////////////////////*/}

              {/* {
                selectedPromotionType === LOYALTY_PROMOTION_TYPE.CASHBACK ?
                  <><View style={{ marginHorizontal: 20, marginTop: 10, marginBottom: 20, zIndex: -1 }}>
                    <View style={{ width: '65%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text style={{ fontWeight: '500' }}>
                          Criteria
                        </Text>
                      </View>

                      <View style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 3, padding: 13, paddingTop: 13 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ fontWeight: '500' }}>
                            Cashback
                          </Text>
                          <TextInput style={{ marginHorizontal: 5, borderWidth: 1, borderRadius: 5, borderColor: '#E5E5E5', width: 40, height: 35, textAlign: 'center' }}
                            placeholder={'0'}
                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            placeholderStyle={{ justifyContent: 'center' }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setCashbackPercentage(text);
                            }}
                            value={cashbackPercentage}
                            keyboardType={'number-pad'}
                          />
                          <Text style={{ fontWeight: '500' }}>
                            (%)
                          </Text>
                        </View>

                        <View style={{ borderWidth: 1, borderColor: '#E5E5E5', marginHorizontal: -13, marginVertical: 12 }} />

                        <View style={{ flexDirection: 'row' }}>
                          <View style={{ flexDirection: 'column' }}>
                            <Text style={{ fontWeight: '500' }}>
                              Product
                            </Text>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                              <DropDownPicker style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 150, paddingVertical: 0, margin: 5, marginLeft: 0 }}
                                placeholderStyle={{ marginLeft: 0 }}
                                placeholder={"Product of Category"}
                                items={[{ label: 'Product of Category', value: 1 }, { label: 'Product of Tags', value: 2 }, { label: 'Specific Products', value: 3 }]}
                                //defaultValue={cashbackSelectedProductList}
                                //multiple={true}
                                customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                onValueChange={(item) =>
                                  console.log(item)
                                  //setCashbackSelectedProductList(item)
                                }
                                dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 150, paddingVertical: 0, margin: 5, marginLeft: 0 }}
                                arrowSize={20}
                                arrowColor={'black'}
                                arrowStyle={{ fontWeight: 'bold' }}
                                itemStyle={{ justifyContent: 'flex-start' }}
                              //multiple={true}
                              />
                              <Text style={{ fontWeight: '500', margin: 3 }}>
                                +
                              </Text>
                              <DropDownPicker style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 180, paddingVertical: 0, margin: 5, marginLeft: 0 }}
                                placeholderStyle={{ marginLeft: 0 }}
                                placeholder={"Select Product Categories"}
                                items={[{ label: 'Shisheido shampoo', value: 1 }, { label: 'Shisheido conditioner', value: 2 }, { label: 'Shisheido Hair mask', value: 3 }]}
                                //defaultValue={cashbackSelectedCategoryList}
                                //multiple={true}
                                customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                onValueChange={(item) =>
                                  console.log(item)
                                  //setCashbackSelectedCategoryList(item)
                                }
                                dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 180, paddingVertical: 0, margin: 5, marginLeft: 0 }}
                                arrowSize={20}
                                arrowColor={'black'}
                                arrowStyle={{ fontWeight: 'bold' }}
                                itemStyle={{ justifyContent: 'flex-start' }}
                              //multiple={true}
                              />
                              <Text style={{ fontWeight: '500', margin: 3 }}>
                                +
                              </Text>
                            </View>
                          </View>

                          <View style={{ flexDirection: 'column', marginLeft: 5 }}>
                            <Text style={{ fontWeight: '500' }}>
                              Min. Quantity
                            </Text>
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                              <TextInput style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 80, height: 35, marginTop: 5, textAlign: 'center' }}
                                placeholder={'0'}
                                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                placeholderStyle={{ justifyContent: 'center' }}
                                onChangeText={(text) => {
                                  // setState({ itemPrice: text });
                                  setCashbackMinQuantity(text);
                                }}
                                value={cashbackMinQuantity}
                                keyboardType={'number-pad'}
                              />
                            </View>
                          </View>
                        </View>

                        <View style={{ marginVertical: 20, zIndex: -1 }}>
                          <TouchableOpacity style={{ flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', borderRadius: 10, borderWidth: 1, borderColor: Colors.primaryColor, width: 125, height: 35 }}
                            onPress={() => { }}>
                            <View style={{ backgroundColor: Colors.primaryColor, alignItems: 'center', paddingLeft: 1.5, borderRadius: 200, width: 20, height: 20 }}>
                              <Icon name='add' color='white' size={18} />
                            </View>
                            <View style={{}}>
                              <Text style={{ alignSelf: 'center', color: Colors.primaryColor, fontWeight: '400' }} >
                                Add Product
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>

                        <View style={{ marginTop: 7, zIndex: -1 }}>
                          <Text style={{ fontWeight: '500' }}>
                            Cashback expired in
                          </Text>
                          <View style={{ flexDirection: 'row' }}>
                            <TextInput style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 80, height: 35, marginTop: 5, marginRight: 5, textAlign: 'center' }}
                              placeholder={'0'}
                              placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                              placeholderStyle={{ justifyContent: 'center' }}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                setCashbackExpired(text);
                              }}
                              value={cashbackExpired}
                              keyboardType={'number-pad'}
                            />
                            <DropDownPicker style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 140, paddingVertical: 0, margin: 5 }}
                              dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 100, width: 140, paddingVertical: 0, margin: 5 }}
                              placeholderStyle={{ marginLeft: 0 }}
                              placeholder={"Select one"}
                              items={[{ label: 'Day(s)', value: 1 }, { label: 'Week(s)', value: 2 }, { label: 'Month(s)', value: 3 }, { label: 'Year(s)', value: 4 }]}
                              //defaultValue={cashbackSelectedExpiredList}
                              //multiple={true}
                              customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                              onValueChange={(item) =>
                                console.log(item)
                                //setCashbackSelectedExpiredList(item)
                              }
                            //multiple={true}
                            />
                          </View>
                        </View>

                      </View>

                      <View style={{ marginVertical: 15, }}>
                        <TouchableOpacity style={{ alignSelf: 'flex-end', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', borderRadius: 10, borderWidth: 1, borderColor: Colors.primaryColor, width: 125, height: 35 }}
                          onPress={() => { }}>
                          <View style={{ backgroundColor: Colors.primaryColor, alignItems: 'center', paddingLeft: 1.5, borderRadius: 200, width: 20, height: 20 }}>
                            <Icon name='add' color='white' size={18} />
                          </View>
                          <View style={{}}>
                            <Text style={{ alignSelf: 'center', color: Colors.primaryColor }} >
                              Add Criteria
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>

                    </View>
                  </View>
                  </>
                  :
                  <></>
              } */}

              {/*////////////////////////////////////Take Amount Off////////////////////////////*/}
              {/* here */}
              {selectedPromotionType ===
                LOYALTY_PROMOTION_TYPE.TAKE_AMOUNT_OFF ? (
                <>
                  <View
                    style={{
                      marginTop: 10,
                      marginLeft: 20,
                      marginRight: 20,
                      marginBottom: 20,
                      zIndex: -1,
                    }}>
                    <Text
                      style={[
                        {
                          fontWeight: '500',
                          fontFamily: 'NunitoSans-Bold',
                          fontSize: 14,
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                          }
                          : {},
                      ]}>
                      Criteria
                    </Text>
                    <View
                      style={{
                        marginTop: '1%',
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        width: '100%',
                      }}>
                      <View style={{ margin: '2%' }}>
                        {/* <View style={{ zIndex: -1, margin: 5, marginLeft: 0, marginTop: 0 }}>
                        <Text style={{ fontWeight: '500', marginBottom: 10 }}>
                          Condition
                        </Text> */}
                        {/* <View style={{ flexDirection: 'row' }}>
                          <DropDownPicker
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold', paddingVertical: 0 }}
                            style={{ width: 250, height: 35, paddingVertical: 0 }}
                            dropDownStyle={{ width: 250, borderWidth: 1, height: 100 }}
                            items={[
                              { label: 'Customer purchase', value: 1 },
                              { label: 'Customer Age', value: 2 },
                              { label: 'Quantity purchase', value: 3 },
                            ]}
                            //items={CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES]}
                            // placeholder={'Field name'}
                            // placeholderStyle={{ color: 'black' }}
                            itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                            onValueChange={item => {
                              // setSelectedChartFilterQueries([
                              //     {
                              //         ...selectedChartFilterQueries[0],
                              //         fieldNameKey: item.value,
                              //         fieldNameType: item.fieldType,
                              //     },
                              // ]);
                            }}
                          //defaultValue={selectedChartFilterQueries[0].fieldNameKey}
                          />

                          <DropDownPicker
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold', paddingVertical: 0 }}
                            style={{ marginLeft: 10, width: 150, height: 35, paddingVertical: 0 }}
                            dropDownStyle={{ width: 150, marginLeft: 10, borderWidth: 1, height: 100 }}
                            items={[
                              { label: 'is', value: 1 },
                              { label: 'like', value: 2 },
                              { label: 'more than', value: 3 },
                              { label: 'less than', value: 4 },
                              //{ label: 'Day of week', value: 5 }
                            ]}
                            //items={CHART_FIELD_COMPARE_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES]}
                            // placeholder={'Field name'}
                            // placeholderStyle={{ color: 'black' }}
                            itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                            onValueChange={item => {
                              // setSelectedChartFilterQueries([
                              //     {
                              //         ...selectedChartFilterQueries[0],
                              //         fieldCompare: item.value,
                              //     },
                              // ]);
                            }}
                          //defaultValue={selectedChartFilterQueries[0].fieldCompare}
                          />
                        </View>

                        <TextInput
                          // {...selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.DATETIME && {
                          //     onPressIn: () => {
                          //         setShowDateTimePickerFilter(true);
                          //     },
                          // }}
                          //editable={selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.DATETIME ? false : true}
                          style={{
                            width: 410,
                            height: 35,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'black',
                            fontFamily: 'NunitoSans-Regular',
                            borderRadius: 5,
                            paddingVertical: 3,
                            paddingLeft: 15,
                            marginTop: 8,
                            zIndex: -1,
                          }}
                          placeholder='Condition'
                          placeholderStyle={{ color: 'black', justifyContent: 'center', alignItems: 'center', fontFamily: 'NunitoSans-Regular' }}
                          // defaultValue={
                          //     selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.DATETIME ?
                          //         moment(selectedChartFilterQueries[0].fieldDataValue).format('DD/MM/YYYY') :
                          //         selectedChartFilterQueries[0].fieldDataValue ? selectedChartFilterQueries[0].fieldDataValue : ''
                          // }
                          onChangeText={(text) => {
                            // setSelectedChartFilterQueries([
                            //     {
                            //         ...selectedChartFilterQueries[0],
                            //         fieldDataValue: text,
                            //     },
                            // ]);
                          }}
                        //keyboardType={selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.STRING ? 'default' : 'numeric'}
                        /> */}

                        {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ fontWeight: '500', marginRight: 5, width: '15%' }}>
                            {'Min Amount\n(RM)'}
                          </Text>
                          <TextInput style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                            height: 35,
                            width: 80,
                            justifyContent: 'center',
                            textAlign: 'center',
                            margin: 5,
                            width: '20%'
                          }}
                            placeholder={'0'}
                            placeholderStyle={{ justifyContent: 'center' }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setPriceMin(text);
                            }}
                            defaultValue={priceMin}
                            keyboardType={'number-pad'}
                          />

                          <Text style={{ fontWeight: '500', marginRight: 5, marginLeft: 15, width: '15%' }}>
                            {'Max Amount\n(RM)'}
                          </Text>
                          <TextInput style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                            height: 35,
                            width: 80,
                            justifyContent: 'center',
                            textAlign: 'center',
                            margin: 5,
                            width: '20%'
                          }}
                            placeholder={'0'}
                            placeholderStyle={{ justifyContent: 'center' }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setPriceMax(text);
                            }}
                            defaultValue={priceMax}
                            keyboardType={'number-pad'}
                          />
                        </View>

                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ fontWeight: '500', marginRight: 5, width: '15%' }}>
                            Min Quantity
                          </Text>
                          <TextInput style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                            height: 35,
                            width: 80,
                            justifyContent: 'center',
                            textAlign: 'center',
                            margin: 5,
                            width: '20%',
                          }}
                            placeholder={'0'}
                            placeholderStyle={{ justifyContent: 'center' }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setQuantityMin(text);
                            }}
                            defaultValue={quantityMin}
                            keyboardType={'number-pad'}
                          />

                          <Text style={{ fontWeight: '500', marginRight: 5, marginLeft: 15, width: '15%' }}>
                            Max Quantity
                          </Text>
                          <TextInput style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                            height: 35,
                            width: 80,
                            justifyContent: 'center',
                            textAlign: 'center',
                            margin: 5,
                            width: '20%',
                          }}
                            placeholder={'0'}
                            placeholderStyle={{ justifyContent: 'center' }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setQuantityMax(text);
                            }}
                            defaultValue={quantityMax}
                            keyboardType={'number-pad'}
                          />
                        </View>


                      </View> */}

                        {/* *******************Border Line******************* */}
                        {/* <View style={{ borderWidth: 1, borderColor: '#E5E5E5', marginVertical: 12, marginHorizontal: -12, zIndex: -3 }} /> */}

                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text
                            style={[
                              {
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: 5,
                                fontWeight: '500',
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            Amount Off (RM)
                          </Text>
                          <TextInput
                            style={[
                              {
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                borderRadius: 5,
                                height: switchMerchant ? 35 : 40,
                                width: 80,
                                textAlign: 'center',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                            placeholder={'0'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 14,
                            }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setAmountOffPrice(text);
                            }}
                            defaultValue={amountOffPrice}
                            keyboardType={'number-pad'}
                          />
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              marginLeft: 20,
                            }}>
                            <Text
                              style={[
                                {
                                  fontWeight: '500',
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Min Spend Amount (RM)
                            </Text>
                            <TextInput
                              style={[
                                {
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  borderRadius: 5,
                                  height: switchMerchant ? 35 : 40,
                                  width: 80,
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  margin: 5,
                                  width: '20%',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                              placeholder={'0.00'}
                              placeholderTextColor={Platform.select({
                                ios: '#a9a9a9',
                              })}
                              placeholderStyle={{
                                justifyContent: 'center',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              onChangeText={(text) => {
                                // setMinSpend(text);
                                setPriceMin(text);
                              }}
                              // defaultValue={minSpend}
                              defaultValue={priceMin}
                              keyboardType={'number-pad'}
                            />
                          </View>
                        </View>

                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            marginVertical: 12,
                            marginHorizontal: -12,
                            zIndex: -3,
                          }}
                        />
                        <View style={{ flexDirection: 'row' }}>
                          <View
                            style={{ flexDirection: 'column', marginBottom: 5 }}>
                            <Text
                              style={[
                                {
                                  fontWeight: '500',
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Apply To
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                marginTop: 10,
                                marginRight: 5,
                                width: '85%',
                              }}>
                              <Picker
                                containerStyle={{
                                  height: switchMerchant ? 35 : 40,
                                  zIndex: 2,
                                }}
                                arrowColor={'black'}
                                arrowSize={20}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                style={{
                                  width: switchMerchant ? 200 : 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                }}
                                placeholderStyle={{
                                  color: Colors.fieldtTxtColor,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                itemStyle={{
                                  justifyContent: 'flex-start',
                                  marginLeft: 5,
                                  zIndex: 2,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholder={'Product'}
                                customTickIcon={(press) => (
                                  <Ionicon
                                    name={'md-checkbox'}
                                    color={
                                      press
                                        ? Colors.fieldtBgColor
                                        : Colors.primaryColor
                                    }
                                    size={25}
                                  />
                                )}
                                onValueChange={(item) =>
                                  setSelectedVariation(item)
                                }
                                defaultValue={selectedVariation}
                                dropDownMaxHeight={150}
                                dropDownStyle={{
                                  width: switchMerchant ? 200 : 250,
                                  height: 80,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  textAlign: 'left',
                                  zIndex: 2,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              // containerStyle={{ height: 40 }}
                              // arrowColor={'black'}
                              // arrowSize={20}
                              // arrowStyle={{ fontWeight: 'bold' }}
                              // labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                              // style={{ width: 130, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, }}
                              // placeholderStyle={{ color: Colors.fieldtTxtColor, alignItems: 'center', justifyContent: 'center' }}
                              // placeholder={"Product"}
                              // // items={[{ label: 'Product of Category', value: 1 }, { label: 'Product of Tags', value: 2 }, { label: 'Specific Products', value: 3 }]}
                              // items={PROMOTION_TYPE_VARIATION_DROPDOWN_LIST}
                              // itemStyle={{ marginLeft: 5, justifyContent: 'flex-start' }}
                              // //defaultValue={amountOffSelectedProductList}
                              // //multiple={true}
                              // customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                              // onValueChange={(item) =>
                              //   // console.log(item)
                              //   //setPackageSelectedProductList(item)
                              //   setSelectedVariation(item.value)
                              // }
                              // defaultValue={selectedVariation}
                              // dropDownMaxHeight={150}
                              // dropDownStyle={{ width: 130, height: 100, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1 }}
                              >
                                {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                  return(
                                    <Picker.Item
                                    key={index}
                                    value={value.value}
                                    label={value.label}
                                    />
                                  )
                                })}
                              </Picker>

                              <Text
                                style={[
                                  {
                                    alignItems: 'center',
                                    marginLeft: '2%',
                                    marginRight: '2%',
                                    alignSelf: 'center',
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}>
                                +
                              </Text>

                              {variationItemsDropdownList.length > 0
                                &&
                                (
                                  selectedVariationItems.filter(itemId => {
                                    return variationItemsDropdownList.find(item2 => item2.value === itemId) ? true : false;
                                  }).length === selectedVariationItems.length
                                  ||
                                  selectedVariationItems.length === 0
                                ) ? (
                                  <Multiselect
                                  style={{
                                    width: switchMerchant ? 200 : 250,
                                    paddingVertical: 0,
                                    backgroundColor: Colors.fieldtBgColor,
                                    borderRadius: 10,
                                  }}
                                  options={variationItemsDropdownList}
                                  onSelect={(items) =>
                                    setSelectedVariationItems(items)
                                  }
                                  // onRemove={(items) =>
                                  //   setSelectedVariationItems(items)
                                  // }
                                  selectedValues={selectedVariationItems}
                                  displayValue='label'
                                  />
                                // <Select
                                //   containerStyle={{
                                //     height: switchMerchant ? 35 : 40,
                                //     zIndex: 2,
                                //   }}
                                //   arrowColor={'black'}
                                //   arrowSize={20}
                                //   arrowStyle={{ fontWeight: 'bold' }}
                                //   labelStyle={{
                                //     fontFamily: 'NunitoSans-Regular',
                                //     fontSize: switchMerchant ? 10 : 14,
                                //   }}
                                //   style={{
                                //     width: switchMerchant ? 200 : 250,
                                //     paddingVertical: 0,
                                //     backgroundColor: Colors.fieldtBgColor,
                                //     borderRadius: 10,
                                //   }}
                                //   placeholderStyle={{
                                //     color: Colors.fieldtTxtColor,
                                //     fontFamily: 'NunitoSans-Regular',
                                //     fontSize: switchMerchant ? 10 : 14,
                                //   }}
                                //   itemStyle={{
                                //     justifyContent: 'flex-start',
                                //     marginLeft: 5,
                                //     zIndex: 2,
                                //   }}
                                //   placeholder={'Select a Product'}
                                //   multipleText={'%d product(s) selected'}
                                //   options={variationItemsDropdownList}
                                //   customTickIcon={(press) => (
                                //     <Ionicon
                                //       name={'md-checkbox'}
                                //       color={
                                //         press
                                //           ? Colors.fieldtBgColor
                                //           : Colors.primaryColor
                                //       }
                                //       size={25}
                                //     />
                                //   )}
                                //   onChange={(items) =>
                                //     setSelectedVariationItems(items)
                                //   }
                                //   defaultValue={selectedVariationItems}
                                //   isMulti
                                //   searchable={true}
                                //   dropDownMaxHeight={150}
                                //   dropDownStyle={{
                                //     width: switchMerchant ? 200 : 250,
                                //     height: 150,
                                //     backgroundColor: Colors.fieldtBgColor,
                                //     borderRadius: 10,
                                //     borderWidth: 1,
                                //     textAlign: 'left',
                                //     zIndex: 2,
                                //     fontSize: switchMerchant ? 10 : 16,
                                //   }}
                                // // containerStyle={{ height: 40 }}
                                // // arrowColor={'black'}
                                // // arrowSize={20}
                                // // arrowStyle={{ fontWeight: 'bold' }}
                                // // labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                                // // style={{ width: 230, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, }}
                                // // placeholderStyle={{ color: Colors.fieldtTxtColor, alignItems: 'center', justifyContent: 'center' }}
                                // // placeholder={"Products"}
                                // // // items={[{ label: 'Shisheido shampoo', value: 1 }, { label: 'Shisheido conditioner', value: 2 }, { label: 'Shisheido Hair mask', value: 3 }]}
                                // // items={variationItemsDropdownList}
                                // // //defaultValue={amountOffSelectedCategoryList}
                                // // customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                // // onValueChange={(items) =>
                                // //   // console.log(item)
                                // //   //setPackageSelectedCategoryList(item)
                                // //   setSelectedVariationItems(items)
                                // // }
                                // // defaultValue={selectedVariationItems}
                                // // multiple={true}
                                // // dropDownMaxHeight={190}
                                // // dropDownStyle={{ width: 230, height: 120, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1 }}
                                // />
                              ) : (
                                <></>
                              )}
                            </View>
                          </View>

                          <View style={{ flexDirection: 'column' }}>
                            <Text
                              style={[
                                {
                                  marginLeft: 10,
                                  fontWeight: '500',
                                  marginBottom: 10,
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: switchMerchant ? 10 : 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Min. Quantity
                            </Text>
                            <TextInput
                              style={[
                                {
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                  borderWidth: 1,
                                  borderRadius: 5,
                                  borderColor: '#E5E5E5',
                                  marginLeft: 10,
                                  height: switchMerchant ? 35 : 40,
                                  width: 60,
                                  textAlign: 'center',
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                              placeholder={'0'}
                              placeholderTextColor={Platform.select({
                                ios: '#a9a9a9',
                              })}
                              placeholderStyle={{
                                justifyContent: 'center',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                // setAmountOffMinQuantity(text);
                                setQuantityMin(text);
                              }}
                              // defaultValue={amountOffMinQuantity}
                              defaultValue={quantityMin}
                              keyboardType={'number-pad'}
                            />
                          </View>

                          <View
                            style={{ flexDirection: 'column', marginLeft: 5 }}>
                            <Text
                              style={[
                                {
                                  marginLeft: 10,
                                  fontWeight: '500',
                                  marginBottom: 10,
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Max. Quantity
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}>
                              <TextInput
                                style={[
                                  {
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    borderColor: '#E5E5E5',
                                    marginLeft: '9%',
                                    height: switchMerchant ? 35 : 40,
                                    width: 60,
                                    textAlign: 'center',
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder={'0'}
                                placeholderTextColor={Platform.select({
                                  ios: '#a9a9a9',
                                })}
                                placeholderStyle={{
                                  justifyContent: 'center',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                onChangeText={(text) => {
                                  // setState({ itemPrice: text });
                                  // setAmountOffMaxQuantity(text);
                                  setQuantityMax(text);
                                }}
                                // defaultValue={amountOffMaxQuantity}
                                defaultValue={quantityMax}
                                keyboardType={'number-pad'}
                              />

                              {/* <View style={{ marginTop: 10, marginLeft: 45, alignItems: 'center' }}>
                            <TouchableOpacity style={{ }}
                              onPress={() => { }}
                            >
                              <MaterialCommunityIcons name='delete-sweep' color={'red'} size={20} />
                            </TouchableOpacity>
                            </View> */}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    {/* <View style={{ marginVertical: 15, width: '70%' }}>
                    <TouchableOpacity style={{ alignSelf: 'flex-end', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', borderRadius: 10, borderWidth: 1, borderColor: Colors.primaryColor, width: 125, height: 35 }}
                      onPress={() => { }}>
                      <View style={{ backgroundColor: Colors.primaryColor, alignItems: 'center', paddingLeft: 1.5, borderRadius: 200, width: 20, height: 20 }}>
                        <Icon name='add' color='white' size={18} />
                      </View>
                      <View style={{}}>
                        <Text style={{ alignSelf: 'center', color: Colors.primaryColor }} >
                          Add Criteria
                          </Text>
                      </View>
                    </TouchableOpacity>
                  </View> */}
                  </View>

                  {/* <View style={{ marginTop: 10, marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -3 }}>
                      <Text style={[{ fontWeight: '500', fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? {
                        fontSize: 10,
                      } : {}]}>Blasting Notification</Text>
                      <View style={{ marginTop: '1%', borderWidth: 1, borderColor: '#E5E5E5', width: '79%' }}>
                        <View style={{ margin: '2%' }}>
                          <View style={{ flexDirection: 'row' }}>

                            <DateTimePickerModal
                              isVisible={showNotificationDatePicker}
                              mode={'date'}
                              onConfirm={(text) => {
                                setNotificationDate(moment(text));

                                setShowNotificationDatePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationDatePicker(false);
                              }}
                            />

                            <DateTimePickerModal
                              isVisible={showNotificationTimePicker}
                              mode={'time'}
                              onConfirm={(text) => {
                                setNotificationTime(moment(text));

                                setShowNotificationTimePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationTimePicker(false);
                              }}
                            />

                            <View style={{ flexDirection: 'column' }}>
                              <Text style={[{ fontSize: 14, fontFamily: 'NunitoSans-Bold', fontWeight: '500', }, switchMerchant ? {
                                fontSize: 10,
                              } : {}]}>
                                Send Date
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationDatePicker(true)
                              }} style={{
                                // height: 50,
                                height: switchMerchant ? 35 : 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <GCalendar width={20} height={20} style={{ marginLeft: 0 }} />

                                <Text style={[{
                                  marginRight: 0,
                                  //color: '#B6B6B6',
                                  fontSize: 14,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontVariant: ['tabular-nums'],
                                }, switchMerchant ? {
                                  fontSize: 10,
                                } : {}]}
                                >
                                  {moment(notificationDate).format('DD MMM YYYY')}
                                </Text>

                              </TouchableOpacity>
                            </View>

                            <View style={{ flexDirection: 'column', marginLeft: 15 }}>
                              <Text style={[{ fontSize: 14, fontFamily: 'NunitoSans-Bold', fontWeight: '500', }, switchMerchant ? {
                                fontSize: 10,
                              } : {}]}>
                                Send Time
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationTimePicker(true);
                              }} style={{
                                // height: 50,
                                height: switchMerchant ? 35 : 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <EvilIcons name="clock" size={25} color={Colors.primaryColor} style={{ marginLeft: 0 }} />

                                <Text style={[{
                                  marginRight: 10,
                                  //color: '#B6B6B6',
                                  fontSize: 14,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontVariant: ['tabular-nums'],
                                }, switchMerchant ? {
                                  fontSize: 10,
                                } : {}]}
                                >
                                  {moment(notificationTime).format('hh:mm A')}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View> */}
                </>
              ) : (
                <></>
              )}

              {/*////////////////////////////////////Take Percentage Off////////////////////////*/}
              {/* here */}
              {selectedPromotionType ===
                LOYALTY_PROMOTION_TYPE.TAKE_PERCENTAGE_OFF ? (
                <>
                  <View
                    style={{
                      marginTop: 10,
                      marginLeft: 20,
                      marginRight: 20,
                      zIndex: -1,
                    }}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Bold',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      Criteria
                    </Text>
                    <View
                      style={{
                        marginTop: '1%',
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        borderRadius: 5,
                        width: '100%',
                      }}>
                      <View style={{ margin: '2%' }}>
                        {/* <View style={{ zIndex: -1, marginBottom: 5 }}>
                        <Text style={{ fontWeight: '500', marginBottom: 10 }}>
                          Condition
                        </Text> */}
                        {/* <View style={{ flexDirection: 'row' }}>
                          <DropDownPicker
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold', paddingVertical: 0 }}
                            style={{ width: 250, height: 35, paddingVertical: 0 }}
                            dropDownStyle={{ width: 250, borderWidth: 1, height: 100 }}
                            items={[
                              { label: 'Customer purchase', value: 1 },
                              { label: 'Customer Age', value: 2 },
                              { label: 'Quantity purchase', value: 3 },
                            ]}
                            //items={CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES]}
                            // placeholder={'Field name'}
                            // placeholderStyle={{ color: 'black' }}
                            itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                            onValueChange={item => {
                              // setSelectedChartFilterQueries([
                              //     {
                              //         ...selectedChartFilterQueries[0],
                              //         fieldNameKey: item.value,
                              //         fieldNameType: item.fieldType,
                              //     },
                              // ]);
                            }}
                          //defaultValue={selectedChartFilterQueries[0].fieldNameKey}
                          />

                          <DropDownPicker
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold', paddingVertical: 0 }}
                            style={{ marginLeft: 10, width: 150, height: 35, paddingVertical: 0 }}
                            dropDownStyle={{ width: 150, marginLeft: 10, borderWidth: 1, height: 100 }}
                            items={[
                              { label: 'is', value: 1 },
                              { label: 'like', value: 2 },
                              { label: 'more than', value: 3 },
                              { label: 'less than', value: 4 },
                              //{ label: 'Day of week', value: 5 }
                            ]}
                            //items={CHART_FIELD_COMPARE_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES]}
                            // placeholder={'Field name'}
                            // placeholderStyle={{ color: 'black' }}
                            itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                            onValueChange={item => {
                              // setSelectedChartFilterQueries([
                              //     {
                              //         ...selectedChartFilterQueries[0],
                              //         fieldCompare: item.value,
                              //     },
                              // ]);
                            }}
                          //defaultValue={selectedChartFilterQueries[0].fieldCompare}
                          />
                        </View>

                        <TextInput
                          // {...selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.DATETIME && {
                          //     onPressIn: () => {
                          //         setShowDateTimePickerFilter(true);
                          //     },
                          // }}
                          //editable={selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.DATETIME ? false : true}
                          style={{
                            width: 410,
                            height: 35,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'black',
                            fontFamily: 'NunitoSans-Regular',
                            borderRadius: 5,
                            paddingVertical: 3,
                            paddingLeft: 15,
                            marginTop: 8,
                            zIndex: -1,
                          }}
                          placeholder='Condition'
                          placeholderStyle={{ color: 'black', justifyContent: 'center', alignItems: 'center', fontFamily: 'NunitoSans-Regular' }}
                          // defaultValue={
                          //     selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.DATETIME ?
                          //         moment(selectedChartFilterQueries[0].fieldDataValue).format('DD/MM/YYYY') :
                          //         selectedChartFilterQueries[0].fieldDataValue ? selectedChartFilterQueries[0].fieldDataValue : ''
                          // }
                          onChangeText={(text) => {
                            // setSelectedChartFilterQueries([
                            //     {
                            //         ...selectedChartFilterQueries[0],
                            //         fieldDataValue: text,
                            //     },
                            // ]);
                          }}
                        //keyboardType={selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.STRING ? 'default' : 'numeric'}
                        /> */}

                        {/* <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ fontWeight: '500', marginRight: 5, width: '15%' }}>
                            {'Min Amount\n(RM)'}
                          </Text>
                          <TextInput style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                            height: 35,
                            width: 80,
                            justifyContent: 'center',
                            textAlign: 'center',
                            margin: 5,
                            width: '20%'
                          }}
                            placeholder={'0'}
                            placeholderStyle={{ justifyContent: 'center' }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setPriceMin(text);
                            }}
                            defaultValue={priceMin}
                            keyboardType={'number-pad'}
                          />

                          <Text style={{ fontWeight: '500', marginRight: 5, marginLeft: 15, width: '15%' }}>
                            {'Max Amount\n(RM)'}
                          </Text>
                          <TextInput style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                            height: 35,
                            width: 80,
                            justifyContent: 'center',
                            textAlign: 'center',
                            margin: 5,
                            width: '20%'
                          }}
                            placeholder={'0'}
                            placeholderStyle={{ justifyContent: 'center' }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setPriceMax(text);
                            }}
                            defaultValue={priceMax}
                            keyboardType={'number-pad'}
                          />
                        </View>

                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ fontWeight: '500', marginRight: 5, width: '15%' }}>
                            Min Quantity
                          </Text>
                          <TextInput style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                            height: 35,
                            width: 80,
                            justifyContent: 'center',
                            textAlign: 'center',
                            margin: 5,
                            width: '20%',
                          }}
                            placeholder={'0'}
                            placeholderStyle={{ justifyContent: 'center' }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setQuantityMin(text);
                            }}
                            defaultValue={quantityMin}
                            keyboardType={'number-pad'}
                          />

                          <Text style={{ fontWeight: '500', marginRight: 5, marginLeft: 15, width: '15%' }}>
                            Max Quantity
                          </Text>
                          <TextInput style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                            height: 35,
                            width: 80,
                            justifyContent: 'center',
                            textAlign: 'center',
                            margin: 5,
                            width: '20%',
                          }}
                            placeholder={'0'}
                            placeholderStyle={{ justifyContent: 'center' }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setQuantityMax(text);
                            }}
                            defaultValue={quantityMax}
                            keyboardType={'number-pad'}
                          />
                        </View>


                      </View>

                      {/* *******************Border Line******************* */}
                        {/*<View style={{ borderWidth: 1, borderColor: '#E5E5E5', marginVertical: 12, marginHorizontal: '-2%', zIndex: -3 }} /> */}

                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              marginRight: '1%',
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: switchMerchant ? 10 : 14,
                            }}>
                            Discount (%)
                          </Text>
                          <TextInput
                            style={[
                              {
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                marginLeft: '2%',
                                height: switchMerchant ? 35 : 40,
                                width: 80,
                                borderRadius: 5,
                                textAlign: 'center',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                            placeholder={'0'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            placeholderStyle={{
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            onChangeText={(text) => {
                              // setState({ itemPrice: text });
                              setPercentageOffPrice(text);
                            }}
                            defaultValue={percentageOffPrice}
                            keyboardType={'number-pad'}
                          />
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}>
                            <Text
                              style={[
                                {
                                  fontWeight: '500',
                                  marginRight: '1%',
                                  marginLeft: '2%',
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: switchMerchant ? 10 : 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Min Spend Amount (RM)
                            </Text>
                            <TextInput
                              style={[
                                {
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  borderRadius: 5,
                                  height: switchMerchant ? 35 : 40,
                                  width: 80,
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  margin: 5,
                                  width: '20%',
                                  marginLeft: 20,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                              placeholder={'0.00'}
                              placeholderStyle={{
                                justifyContent: 'center',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderTextColor={Platform.select({
                                ios: '#a9a9a9',
                              })}
                              onChangeText={(text) => {
                                // setMinSpend(text);
                                setPriceMin(text);
                              }}
                              // defaultValue={minSpend}
                              defaultValue={priceMin}
                              keyboardType={'number-pad'}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            width: '100%',
                            marginTop: '3%',
                            marginBottom: '3%',
                          }}
                        />
                        <View style={{ flexDirection: 'row' }}>
                          <View
                            style={{ flexDirection: 'column', marginBottom: 5 }}>
                            <Text
                              style={[
                                {
                                  fontWeight: '500',
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Apply To
                            </Text>
                            <View style={{ flexDirection: 'row', marginTop: 10 }}>
                              <Picker
                                containerStyle={{
                                  height: switchMerchant ? 35 : 40,
                                }}
                                arrowColor={'black'}
                                arrowSize={20}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                style={{
                                  width: switchMerchant ? 200 : 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                }}
                                placeholderStyle={{
                                  color: Colors.fieldtTxtColor,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholder={'Product'}
                                // items={[{ label: 'Product of Category', value: 1 }, { label: 'Product of Tags', value: 2 }, { label: 'Specific Products', value: 3 }]}
                                itemStyle={{
                                  marginLeft: 5,
                                  justifyContent: 'flex-start',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                //defaultValue={percentageOffSelectedProductList}
                                //multiple={true}
                                customTickIcon={(press) => (
                                  <Ionicon
                                    name={'md-checkbox'}
                                    color={
                                      press
                                        ? Colors.fieldtBgColor
                                        : Colors.primaryColor
                                    }
                                    size={25}
                                  />
                                )}
                                onValueChange={(item) =>
                                  // console.log(item)
                                  //setPackageSelectedProductList(item)
                                  setSelectedVariation(item)
                                }
                                defaultValue={selectedVariation}
                                dropDownMaxHeight={150}
                                dropDownStyle={{
                                  width: switchMerchant ? 200 : 250,
                                  height: 80,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                  return (
                                    <Picker.Item
                                    key={index}
                                    value={value.value}
                                    label={value.label}
                                    />
                                  )
                                })}
                              </Picker>

                              <Text
                                style={{
                                  alignItems: 'center',
                                  marginLeft: '2%',
                                  marginRight: '2%',
                                  alignSelf: 'center',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}>
                                +
                              </Text>

                              {variationItemsDropdownList.length > 0
                                &&
                                (
                                  selectedVariationItems.filter(itemId => {
                                    return variationItemsDropdownList.find(item2 => item2.value === itemId) ? true : false;
                                  }).length === selectedVariationItems.length
                                  ||
                                  selectedVariationItems.length === 0
                                ) ? (
                                  <Multiselect
                                  style={{
                                    width: switchMerchant ? 200 : 250,
                                    paddingVertical: 0,
                                    backgroundColor: Colors.fieldtBgColor,
                                    borderRadius: 10,
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                  options={variationItemsDropdownList}
                                  onChange={(items) =>
                                    // console.log(item)
                                    //setPackageSelectedCategoryList(item)
                                    setSelectedVariationItems(items)
                                  }
                                  // onRemove={(items) =>
                                  //   // console.log(item)
                                  //   //setPackageSelectedCategoryList(item)
                                  //   setSelectedVariationItems(items)
                                  // }
                                  selectedValues={selectedVariationItems}
                                  displayValue='label'
                                  />
                                  
                                // <Select
                                //   containerStyle={{
                                //     height: switchMerchant ? 35 : 40,
                                //   }}
                                //   arrowColor={'black'}
                                //   arrowSize={20}
                                //   arrowStyle={{ fontWeight: 'bold' }}
                                //   labelStyle={{
                                //     fontFamily: 'NunitoSans-Regular',
                                //     fontSize: switchMerchant ? 10 : 14,
                                //   }}
                                //   style={{
                                //     width: switchMerchant ? 200 : 250,
                                //     paddingVertical: 0,
                                //     backgroundColor: Colors.fieldtBgColor,
                                //     borderRadius: 10,
                                //     fontFamily: 'NunitoSans-Regular',
                                //     fontSize: switchMerchant ? 10 : 14,
                                //   }}
                                //   placeholderStyle={{
                                //     color: Colors.fieldtTxtColor,
                                //     alignItems: 'center',
                                //     justifyContent: 'center',
                                //     fontFamily: 'NunitoSans-Regular',
                                //     fontSize: switchMerchant ? 10 : 14,
                                //   }}
                                //   // placeholder={"Category"}
                                //   placeholder={'Select a Product'}
                                //   multipleText={'%d product(s) selected'}
                                //   // items={[{ label: 'Shisheido shampoo', value: 1 }, { label: 'Shisheido conditioner', value: 2 }, { label: 'Shisheido Hair mask', value: 3 }]}
                                //   options={variationItemsDropdownList}
                                //   //defaultValue={percentageOffSelectedCategoryList}
                                //   //multiple={true}
                                //   customTickIcon={(press) => (
                                //     <Ionicon
                                //       name={'md-checkbox'}
                                //       color={
                                //         press
                                //           ? Colors.fieldtBgColor
                                //           : Colors.primaryColor
                                //       }
                                //       size={25}
                                //     />
                                //   )}
                                //   onChange={(items) =>
                                //     // console.log(item)
                                //     //setPackageSelectedCategoryList(item)
                                //     setSelectedVariationItems(items)
                                //   }
                                //   defaultValue={selectedVariationItems}
                                //   isMulti
                                //   searchable={true}
                                //   dropDownMaxHeight={150}
                                //   dropDownStyle={{
                                //     width: switchMerchant ? 200 : 250,
                                //     height: 150,
                                //     backgroundColor: Colors.fieldtBgColor,
                                //     borderRadius: 10,
                                //     borderWidth: 1,
                                //     textAlign: 'left',
                                //     zIndex: 2,
                                //     fontFamily: 'NunitoSans-Regular',
                                //     fontSize: switchMerchant ? 10 : 14,
                                //   }}
                                // />
                              ) : (
                                <></>
                              )}
                            </View>
                          </View>

                          <View style={{ flexDirection: 'column' }}>
                            <Text
                              style={[
                                {
                                  marginLeft: 10,
                                  fontWeight: '500',
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Min. Quantity
                            </Text>
                            <TextInput
                              style={[
                                {
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  marginLeft: 10,
                                  height: switchMerchant ? 35 : 40,
                                  width: 60,
                                  borderRadius: 5,
                                  marginTop: 10,
                                  textAlign: 'center',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                              placeholder={'0'}
                              placeholderStyle={{
                                justifyContent: 'center',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderTextColor={Platform.select({
                                ios: '#a9a9a9',
                              })}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                // setPercentageOffMinQuantity(text);
                                setQuantityMin(text);
                              }}
                              // defaultValue={percentageOffMinQuantity}
                              defaultValue={quantityMin}
                              keyboardType={'number-pad'}
                            />
                          </View>

                          <View
                            style={{ flexDirection: 'column', marginLeft: 5 }}>
                            <Text
                              style={[
                                {
                                  marginLeft: 10,
                                  fontWeight: '500',
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Max. Quantity
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}>
                              <TextInput
                                style={[
                                  {
                                    borderWidth: 1,
                                    borderColor: '#E5E5E5',
                                    marginLeft: 10,
                                    height: switchMerchant ? 35 : 40,
                                    width: 60,
                                    borderRadius: 5,
                                    marginTop: 10,
                                    textAlign: 'center',
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder={'0'}
                                placeholderStyle={{
                                  justifyContent: 'center',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: '#a9a9a9',
                                })}
                                onChangeText={(text) => {
                                  // setState({ itemPrice: text });
                                  // setPercentageOffMaxQuantity(text);
                                  setQuantityMax(text);
                                }}
                                // defaultValue={percentageOffMaxQuantity}
                                defaultValue={quantityMax}
                                keyboardType={'number-pad'}
                              />

                              {/* <View style={{ marginTop: 10, marginLeft: 45, alignItems: 'center' }}>
                            <TouchableOpacity style={{ }}
                              onPress={() => { }}
                            >
                              <MaterialCommunityIcons name='delete-sweep' color={'red'} size={20} />
                            </TouchableOpacity>
                            </View> */}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    {/* <View style={{ marginVertical: 15, width: '70%' }}>
                    <TouchableOpacity style={{ alignSelf: 'flex-end', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', borderRadius: 10, borderWidth: 1, borderColor: Colors.primaryColor, width: 125, height: 35 }}
                      onPress={() => { }}>
                      <View style={{ backgroundColor: Colors.primaryColor, alignItems: 'center', paddingLeft: 1.5, borderRadius: 200, width: 20, height: 20 }}>
                        <Icon name='add' color='white' size={18} />
                      </View>
                      <View style={{}}>
                        <Text style={{ alignSelf: 'center', color: Colors.primaryColor }} >
                          Add Criteria
                          </Text>
                      </View>
                    </TouchableOpacity>
                  </View> */}
                  </View>

                  {/* <View style={{ marginTop: 10, marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -3 }}>
                      <Text style={[{ fontWeight: '500', fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? {
                        fontSize: 10,
                      } : {}]}>Blasting Notification</Text>
                      <View style={{ marginTop: '1%', borderWidth: 1, borderColor: '#E5E5E5', width: '79%' }}>
                        <View style={{ margin: '2%' }}>
                          <View style={{ flexDirection: 'row' }}>

                            <DateTimePickerModal
                              isVisible={showNotificationDatePicker}
                              mode={'date'}
                              onConfirm={(text) => {
                                setNotificationDate(moment(text));

                                setShowNotificationDatePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationDatePicker(false);
                              }}
                            />

                            <DateTimePickerModal
                              isVisible={showNotificationTimePicker}
                              mode={'time'}
                              onConfirm={(text) => {
                                setNotificationTime(moment(text));

                                setShowNotificationTimePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationTimePicker(false);
                              }}
                            />

                            <View style={{ flexDirection: 'column' }}>
                              <Text style={[{ fontWeight: '500', fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? {
                                fontSize: 10,
                              } : {}]}>
                                Send Date
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationDatePicker(true)
                              }} style={{
                                // height: 50,s
                                height: switchMerchant ? 35 : 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 16,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <GCalendar width={switchMerchant ? 10 : 20} height={switchMerchant ? 10 : 20} style={{ marginLeft: 0 }} />

                                <Text style={[{
                                  marginRight: 0,
                                  //color: '#B6B6B6',
                                  fontSize: 14,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontVariant: ['tabular-nums'],
                                }, switchMerchant ? {
                                  fontSize: 10,
                                } : {}]}
                                >
                                  {moment(notificationDate).format('DD MMM YYYY')}
                                </Text>

                              </TouchableOpacity>
                            </View>

                            <View style={{ flexDirection: 'column', marginLeft: 15 }}>
                              <Text style={[{ fontFamily: 'NunitoSans-Bold', fontSize: 14, fontWeight: '500', }, switchMerchant ? {
                                fontSize: 10,
                              } : {}]}>
                                Send Time
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationTimePicker(true);
                              }} style={{
                                // height: 50,
                                height: switchMerchant ? 35 : 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>

                                <EvilIcons name="clock" size={switchMerchant ? 10 : 25} color={Colors.primaryColor} style={{ marginLeft: 0 }} />

                                <Text style={[{
                                  marginRight: 10,
                                  //color: '#B6B6B6',
                                  fontSize: 14,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontVariant: ['tabular-nums'],
                                }, switchMerchant ? {
                                  fontSize: 10,
                                } : {}]}
                                >
                                  {moment(notificationTime).format('hh:mm A')}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View> */}
                </>
              ) : (
                <></>
              )}

              {/*//////////////////////Require specific product purchase/////////////////////////*/}

              {isRequireSpecificProductPurchase ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginTop: 20,
                      marginBottom: 0,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '65%' }}>
                      {/* <View style={{ marginBottom: 5 }}>
                  <Text style={{ fontWeight: '500' }}>
                    Criteria
                   </Text>
                </View> */}

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 15,
                          paddingTop: 13,
                        }}>
                        <View style={{ flexDirection: 'row' }}>
                          <View style={{ flexDirection: 'column' }}>
                            <Text style={{ fontWeight: '500' }}>Product</Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}>
                              <Picker
                                style={{
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  borderRadius: 5,
                                  height: 35,
                                  width: 130,
                                  paddingVertical: 0,
                                  margin: 5,
                                  marginLeft: 0,
                                }}
                                dropDownStyle={{
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  borderRadius: 5,
                                  width: 130,
                                  paddingVertical: 0,
                                  margin: 5,
                                  marginLeft: 0,
                                }}
                                arrowSize={20}
                                arrowColor={'black'}
                                arrowStyle={{ fontWeight: 'bold' }}
                                placeholder="Product"
                                placeholderStyle={{ marginLeft: 0 }}
                                itemStyle={{ justifyContent: 'flex-start' }}
                              >
                                {[
                                  { label: 'Coke', value: 1 },
                                  { label: 'HL', value: 2 },
                                ].map((value, index) => {
                                  return(
                                    <Picker.Item 
                                    key={index}
                                    value={value.value}
                                    label={value.label}
                                    />
                                  )
                                })}
                              </Picker>
                              <Text style={{ fontWeight: '500', margin: 3 }}>
                                +
                              </Text>
                              <Picker
                                style={{
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  borderRadius: 5,
                                  height: 35,
                                  width: 130,
                                  paddingVertical: 0,
                                  margin: 5,
                                }}
                                dropDownStyle={{
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  borderRadius: 5,
                                  width: 130,
                                  paddingVertical: 0,
                                  margin: 5,
                                }}
                                arrowSize={20}
                                arrowColor={'black'}
                                arrowStyle={{ fontWeight: 'bold' }}
                                placeholder="Product"
                                placeholderStyle={{ marginLeft: 0 }}
                                itemStyle={{ justifyContent: 'flex-start' }}
                              > 
                                {[
                                  { label: 'KFC', value: 1 },
                                  { label: 'MCD', value: 2 },
                                ].map((value, index) => {
                                  return(
                                    <Picker.Item
                                    key={index}
                                    value={value.value}
                                    label={value.label}
                                    />
                                  )
                                })}
                              </Picker>
                              <Text style={{ fontWeight: '500', margin: 3 }}>
                                +
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{ flexDirection: 'column', marginLeft: 5 }}>
                            <Text style={{ fontWeight: '500' }}>
                              Min. Quantity
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                              }}>
                              <TextInput
                                style={{
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  borderRadius: 5,
                                  width: 80,
                                  height: 35,
                                  marginTop: 5,
                                  textAlign: 'center',
                                }}
                                placeholder="0"
                                placeholderTextColor={Platform.select({
                                  ios: '#a9a9a9',
                                })}
                                keyboardType="number-pad"
                              />
                              <TouchableOpacity
                                style={{ marginLeft: 25 }}
                                onPress={() => { }}>
                                <MaterialCommunityIcons
                                  name="delete-sweep"
                                  color={'red'}
                                  size={20}
                                />
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  {/* <View style={{ marginTop: 10, marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -3 }}>
                      <Text style={{ fontWeight: '500' }}>Blasting Notification</Text>
                      <View style={{ marginTop: '1%', borderWidth: 1, borderColor: '#E5E5E5', width: '79%' }}>
                        <View style={{ margin: '2%' }}>
                          <View style={{ flexDirection: 'row' }}>

                            <DateTimePickerModal
                              isVisible={showNotificationDatePicker}
                              mode={'date'}
                              onConfirm={(text) => {
                                setNotificationDate(moment(text));

                                setShowNotificationDatePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationDatePicker(false);
                              }}
                            />

                            <DateTimePickerModal
                              isVisible={showNotificationTimePicker}
                              mode={'time'}
                              onConfirm={(text) => {
                                setNotificationTime(moment(text));

                                setShowNotificationTimePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationTimePicker(false);
                              }}
                            />

                            <View style={{ flexDirection: 'column' }}>
                              <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', fontWeight: '500', }}>
                                Send Date
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationDatePicker(true)
                              }} style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 16,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <Text style={{
                                  marginLeft: 15,
                                  color: '#B6B6B6',
                                  fontSize: 14
                                }}
                                >
                                  {moment(notificationDate).format('DD/MM/YYYY')}
                                </Text>
                                
                                <GCalendar width={20} height={20} style={{ marginRight: 0 }} />
                              </TouchableOpacity>
                            </View>

                            <View style={{ flexDirection: 'column', marginLeft: 15 }}>
                              <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', fontWeight: '500', }}>
                                Send Time
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationTimePicker(true);
                              }} style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 16,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <Text style={{
                                  marginLeft: 15,
                                  color: '#B6B6B6',
                                  fontSize: 14
                                }}
                                >
                                  {moment(notificationTime).format('hh:mm a')}
                                </Text>

                                <EvilIcons name="clock" size={25} color={Colors.primaryColor} style={{ marginRight: 0 }} />

                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View> */}
                </>
              ) : (
                <></>
              )}

              {/*/////////////////////////////////Delivery/////////////////////////////////*/}
              {/* here */}
              {selectedPromotionType === LOYALTY_PROMOTION_TYPE.DELIVERY ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginBottom: 20,
                      marginTop: 10,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '100%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                          style={[
                            {
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Criteria
                        </Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          width: 600,
                          alignItems: 'flex-start',
                        }}>
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}>
                          {/* <Text style={{ fontWeight: '500', marginRight: 2 }}>
                        Buy
                      </Text>
                      <TextInput style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 40, justifyContent: 'center', textAlign: 'center', margin: 6 }}
                        placeholder={'0'}
                        placeholderStyle={{ justifyContent: 'center' }}
                        onChangeText={(text) => {
                          // setState({ itemPrice: text });
                          setBuyInput(text);
                        }}
                        defaultValue={buyInput}
                        keyboardType={'number-pad'}
                      /> */}
                          <View style={{ marginRight: 5, marginBottom: 5 }}>
                            <Picker
                              containerStyle={{
                                height: switchMerchant ? 35 : 40,
                                zIndex: 2,
                              }}
                              arrowColor={'black'}
                              arrowSize={20}
                              arrowStyle={{ fontWeight: 'bold' }}
                              labelStyle={{
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              style={{
                                width: 250,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderStyle={{
                                color: Colors.fieldtTxtColor,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              itemStyle={{
                                justifyContent: 'flex-start',
                                marginLeft: 5,
                                zIndex: 2,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder={'Products'}
                              customTickIcon={(press) => (
                                <Ionicon
                                  name={'md-checkbox'}
                                  color={
                                    press
                                      ? Colors.fieldtBgColor
                                      : Colors.primaryColor
                                  }
                                  size={25}
                                />
                              )}
                              onValueChange={(item) => {
                                console.log(item);
                                setSelectedVariation(item);
                              }}
                              defaultValue={selectedVariation}
                              dropDownMaxHeight={150}
                              dropDownStyle={{
                                width: 250,
                                height: 80,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                borderWidth: 1,
                                textAlign: 'left',
                                zIndex: 2,
                              }}

                            // style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 150, paddingVertical: 0, margin: 6, marginLeft: 0 }}
                            // dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 150, paddingVertical: 0, margin: 6 }}
                            // arrowSize={20}
                            // arrowColor={'black'}
                            // arrowStyle={{ fontWeight: 'bold' }}
                            // placeholderStyle={{ marginLeft: 0 }}
                            // placeholder={"Product"}
                            // // items={[{ label: 'Product A', value: 1 }, { label: 'Product B', value: 2 }]}
                            // items={PROMOTION_TYPE_VARIATION_DROPDOWN_LIST}
                            // itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                            // //defaultValue={buy1Free1SelectedProductList}
                            // //multiple={true}
                            // customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                            // onValueChange={(item) => {
                            //   console.log(item)
                            //   //setBuy1Free1SelectedProductList(item)
                            //   setSelectedVariation(item.value);
                            // }}
                            // defaultValue={selectedVariation}
                            //multiple={true}
                            >
                              {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                return (
                                  <Picker.Item
                                  key={index}
                                  value={value.value}
                                  label={value.label}
                                  />
                                )
                              })}
                            </Picker>
                          </View>

                          {variationItemsDropdownList.length > 0
                            &&
                            (
                              selectedVariationItems.filter(itemId => {
                                return variationItemsDropdownList.find(item2 => item2.value === itemId) ? true : false;
                              }).length === selectedVariationItems.length
                              ||
                              selectedVariationItems.length === 0
                            ) ? (
                            <View style={{ marginRight: 5, marginBottom: 5 }}>
                              <Multiselect
                              style={{
                                width: 250,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              }}
                              options={variationItemsDropdownList}
                              onChange={(items) =>
                                setSelectedVariationItems(items)
                              }
                              // onRemove={(items) =>
                              //   setSelectedVariationItems(items)
                              // }
                              selectedValues={selectedVariationItems}
                              displayValue='label'
                              />
                              {/* <Select
                                containerStyle={{
                                  height: switchMerchant ? 35 : 40,
                                  zIndex: 2,
                                }}
                                arrowColor={'black'}
                                arrowSize={20}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                style={{
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                }}
                                placeholderStyle={{
                                  color: Colors.fieldtTxtColor,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                itemStyle={{
                                  justifyContent: 'flex-start',
                                  marginLeft: 5,
                                  zIndex: 2,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholder={'Select a Product'}
                                multipleText={'%d product(s) selected'}
                                options={variationItemsDropdownList}
                                customTickIcon={(press) => (
                                  <Ionicon
                                    name={'md-checkbox'}
                                    color={
                                      press
                                        ? Colors.fieldtBgColor
                                        : Colors.primaryColor
                                    }
                                    size={25}
                                  />
                                )}
                                onChange={(items) =>
                                  setSelectedVariationItems(items)
                                }
                                defaultValue={selectedVariationItems}
                                isMulti
                                searchable={true}
                                dropDownMaxHeight={150}
                                dropDownStyle={{
                                  width: 250,
                                  height: 150,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  textAlign: 'left',
                                  zIndex: 2,
                                }}

                              // containerStyle={{ height: 35 }}
                              // arrowColor={'black'}
                              // arrowSize={20}
                              // arrowStyle={{ fontWeight: 'bold' }}
                              // labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                              // style={{ width: 210, paddingVertical: 0, borderRadius: 10 }}
                              // placeholderStyle={{ color: Colors.fieldtTxtColor, alignItems: 'center', justifyContent: 'center' }}
                              // placeholder={"Products"}
                              // multipleText={'%d products selected'}
                              // items={variationItemsDropdownList}
                              // //defaultValue={packageSelectedCategoryList}
                              // customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                              // onValueChange={(items) =>
                              //   // console.log(item)
                              //   //setPackageSelectedCategoryList(item)
                              //   setSelectedVariationItems(items)
                              // }
                              // defaultValue={selectedVariationItems}
                              // multiple={true}
                              // searchable={true}
                              // dropDownMaxHeight={190}
                              // dropDownStyle={{ width: 210, height: 190, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1 }}
                              /> */}
                            </View>
                          ) : (
                            <></>
                          )}
                        </View>

                        {/* <View style={{ flexDirection: 'row', padding: 30 }}> */}
                        {/* <View style={{ flex: 3 }}> */}
                        {/* <View style={{ flexDirection: 'row', flex: 1, width: '100%', }}>
                      <View style={{ flex: 0.57, justifyContent: 'center' }}>
                        <Text style={styles.textSize}>Choose Outlet: </Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <View
                          style={{
                            width: "100%",
                            height: 50,
                            justifyContent: 'center',
                            marginLeft: '5%',

                          }}>
                          <DropDownPicker

                            containerStyle={{ height: 40 }}
                            // placeholder="Choose outlet"
                            items={[{ label: 'Ho Min San(TTDI)', value: 1 }, { label: 'Ho Min San(Mid Valley)', value: 2 }]}
                            // items={targetOutletDropdownList}
                            // defaultValue={selectedTargetOutletId}
                            placeholderStyle={{ color: 'black' }}
                            style={{ backgroundColor: '#fafafa' }}
                            itemStyle={{
                              justifyContent: 'flex-start', marginLeft: 5,
                            }}
                            dropDownStyle={{ backgroundColor: '#fafafa' }}
                          // onValueChange={(item) => {
                          //   setSelectedTargetOutletId(item.value)
                          // }}
                          />
                        </View>
                      </View>

                      <View
                        style={{
                          justifyContent: 'center',
                          flex: 1,
                          marginLeft: '5%',
                        }}>
                      </View>
                    </View> */}

                        {/* <View
                      style={{ flexDirection: 'row', flex: 2, zIndex: -1 }}>                      
                      <View style={{ justifyContent: 'center', flex: 0.6 }}>
                        <View>
                          <Text style={styles.textSize}>
                            Delivery Distance:{' '}
                          </Text>
                          <Text
                            style={{
                              fontSize: 14,
                              color: Colors.descriptionColor,
                            }}>
                            Max: 15KM{' '}
                          </Text>
                        </View>
                      </View>
                      <View style={{ flex: 1, }}>
                        <View
                          style={{
                            width: 200,
                            height: 50,
                            justifyContent: 'center',
                          }}>
                          <DropDownPicker
                            items={[
                              {
                                label: '1',
                                value: '1',
                              },
                              {
                                label: '2',
                                value: '2',
                              },
                              {
                                label: '3',
                                value: '3',
                              },
                              {
                                label: '4',
                                value: '4',
                              },
                              {
                                label: '5',
                                value: '5',
                              },
                              {
                                label: '6',
                                value: '6',
                              },
                              {
                                label: '7',
                                value: '7',
                              },
                              {
                                label: '8',
                                value: '8',
                              },
                              {
                                label: '9',
                                value: '9',
                              },
                              {
                                label: '10',
                                value: '10',
                              },
                              {
                                label: '11',
                                value: '11',
                              },
                              {
                                label: '12',
                                value: '12',
                              },
                              {
                                label: '13',
                                value: '13',
                              },
                              {
                                label: '14',
                                value: '14',
                              },
                              {
                                label: '15',
                                value: '15',
                              },
                            ]}
                            containerStyle={{ height: 40 }}
                            placeholder="Select KM"
                            // defaultValue={deliveryDistance}
                            placeholderStyle={{ color: 'black' }}
                            style={{ backgroundColor: '#fafafa' }}
                            itemStyle={{
                              justifyContent: 'flex-start', marginLeft: 5,
                            }}
                            dropDownStyle={{ backgroundColor: '#fafafa' }}
                          // onValueChange={(item) => {
                          //   setDeliveryDistance(item.value);
                          // }}
                          />
                        </View>
                      </View>
                      <View style={{ flex: 1 }}></View>
                    </View> */}
                        {/* <View
                      style={{ flexDirection: 'row', flex: 1, zIndex: -2 }}>
                      <View style={{ justifyContent: 'center', flex: 0.57 }}>
                        <Text style={styles.textSize}>Delivery Fee: </Text>
                      </View>
                      <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ flex: 2 }}>
                          <CheckBox
                            style={{
                              paddingVertical: 10,
                            }}
                            onClick={() => {
                              setDeliveryFreeFlag(!deliveryFreeFlag);
                            }}
                            checkBoxColor={Colors.fieldtBgColor}
                            uncheckedCheckBoxColor={Colors.tabGrey}
                            checkedCheckBoxColor={Colors.primaryColor}
                            isChecked={deliveryFreeFlag}
                            rightText={'Free Delivery Above RM:'}
                            rightTextStyle={{
                              fontSize: 13,
                              fontFamily: 'NunitoSans-SemiBold',
                              color: Colors.descriptionColor,
                            }}
                          />
                        </View>
                        <View style={{ flex: 0.4, height: 35, alignSelf: 'center' }}>
                          <TextInput
                            underlineColorAndroid={Colors.fieldtBgColor}
                            style={[styles.textInput8, { textAlign: 'center' }]}
                            placeholder="0"
                            onChangeText={(text) => {
                              setDeliveryFreeAboveAmount(text);
                            }}
                            defaultValue={deliveryFreeAboveAmount}
                            // ref={myTextInput}
                            keyboardType={'number-pad'}
                          />
                        </View>
                      </View>
                      <View style={{ flex: 1 }}></View>
                    </View> */}
                        <View
                          style={{
                            flexDirection: 'row',
                            flex: 1,
                            alignItems: 'center',
                            zIndex: -2,
                            marginTop: '1%',
                          }}>
                          <View style={{ alignItems: 'flex-start' }}>
                            <Text
                              style={[
                                {
                                  color: 'black',
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Discount (RM)
                            </Text>
                          </View>
                          <View
                            style={{
                              height: switchMerchant ? 35 : 40,
                              paddingHorizontal: 5,
                            }}>
                            <TextInput
                              underlineColorAndroid={Colors.fieldtBgColor}
                              style={[
                                styles.textInput8,
                                { textAlign: 'center', fontSize: 14 },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                              placeholder="0"
                              placeholderTextColor={Platform.select({
                                ios: '#a9a9a9',
                              })}
                              placeholderStyle={{
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              onChangeText={(text) => {
                                setDeliveryDiscountAmount(text);
                              }}
                              value={deliveryDiscountAmount}
                              // ref={myTextInput}
                              keyboardType={'number-pad'}
                            />
                          </View>

                          <View style={{ marginLeft: 15 }}>
                            <Text
                              style={[
                                {
                                  color: 'black',
                                  fontSize: 14,
                                  fontFamily: 'NunitoSans-Bold',
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Order Above (RM)
                            </Text>
                          </View>

                          <View
                            style={{
                              height: switchMerchant ? 35 : 40,
                              paddingHorizontal: 5,
                            }}>
                            <TextInput
                              underlineColorAndroid={Colors.fieldtBgColor}
                              style={[
                                styles.textInput8,
                                { textAlign: 'center', fontSize: 14 },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                              placeholder="0"
                              placeholderTextColor={Platform.select({
                                ios: '#a9a9a9',
                              })}
                              onChangeText={(text) => {
                                setDeliveryDiscountAboveAmount(text);
                              }}
                              value={deliveryDiscountAboveAmount}
                              // ref={myTextInput}
                              keyboardType={'number-pad'}
                            />
                          </View>
                        </View>

                        {/* <View style={{ borderWidth: 1, borderColor: '#E5E5E5', marginVertical: 12, marginHorizontal: -12, zIndex: -3 }} /> */}

                        {/* *******************Border Line******************* */}
                        {/* <View style={{ borderWidth: 1, width: '104.5%', borderColor: '#E5E5E5', marginVertical: 12, marginHorizontal: -12, zIndex: -3 }} />
                    <View style={{ flexDirection: 'row', }}>
                      <View style={{ flex: 3 }}>
                        <View>
                          <View
                            style={{
                              flexDirection: 'row',
                              flex: 1,
                            }}>
                            <View style={{ justifyContent: 'center' }}>
                              <Text style={styles.textSize}>
                                Delivery Price:{'    '}
                              </Text>
                            </View>
                            <View style={{ flexDirection: 'row' }}>
                              <View style={{ flex: 1 }}>
                                <NumericInput
                                  // value={deliveryPrice}
                                  // onChange={(value) =>
                                  //   setDeliveryPrice(value),
                                  //   console.log('pressed')
                                  // }
                                  minValue={0}
                                  maxValue={50}
                                  onLimitReached={(isMax, msg) =>
                                    console.log(isMax, msg)
                                  }
                                  totalWidth={150}
                                  totalHeight={32}
                                  iconSize={25}
                                  step={1}
                                  valueType="real"
                                  rounded
                                  textColor={Colors.primaryColor}
                                  iconStyle={{ color: 'white' }}
                                  rightButtonBackgroundColor={Colors.primaryColor}
                                  leftButtonBackgroundColor={'grey'}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View> */}
                      </View>
                    </View>
                  </View>
                  {/* <View style={{ marginTop: 10, marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -3 }}>
                    <Text style={[{ fontWeight: '500', fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }, switchMerchant ? {

                    } : {}]}>Blasting Notification</Text>
                    <View style={{ marginTop: '1%', borderWidth: 1, borderColor: '#E5E5E5', width: '79%' }}>
                      <View style={{ margin: '2%' }}>
                        <View style={{ flexDirection: 'row' }}>

                          <DateTimePickerModal
                            isVisible={showNotificationDatePicker}
                            mode={'date'}
                            onConfirm={(text) => {
                              setNotificationDate(moment(text));

                              setShowNotificationDatePicker(false);
                            }}
                            onCancel={() => {
                              setShowNotificationDatePicker(false);
                            }}
                          />

                          <DateTimePickerModal
                            isVisible={showNotificationTimePicker}
                            mode={'time'}
                            onConfirm={(text) => {
                              setNotificationTime(moment(text));

                              setShowNotificationTimePicker(false);
                            }}
                            onCancel={() => {
                              setShowNotificationTimePicker(false);
                            }}
                          />

                          <View style={{ flexDirection: 'column' }}>
                            <Text style={[{ fontSize: 14, fontFamily: 'NunitoSans-Bold', fontWeight: '500', }, switchMerchant ? {
                              fontSize: 10,
                            } : {}]}>
                              Send Date
                            </Text>
                            <TouchableOpacity onPress={() => {
                              setShowNotificationDatePicker(true)
                            }} style={{
                              // height: 50,
                              height: switchMerchant ? 35 : 40,
                              paddingHorizontal: 20,
                              backgroundColor: Colors.fieldtBgColor,
                              //marginBottom: 20,
                              width: 160,
                              //marginHorizontal: 10,
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              alignContent: 'center',
                              borderColor: '#E5E5E5',
                              borderWidth: 1,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              borderRadius: 12,
                              marginTop: 10,
                            }}>
                              <GCalendar width={20} height={20} style={{ marginLeft: 0 }} />

                              <Text style={[{
                                marginRight: 0,
                                //color: '#B6B6B6',
                                fontSize: 14,
                                fontFamily: 'NunitoSans-Regular',
                                fontVariant: ['tabular-nums'],
                              }, switchMerchant ? {
                                fontSize: 10,
                              } : {}]}
                              >
                                {moment(notificationDate).format('DD MMM YYYY')}
                              </Text>

                            </TouchableOpacity>
                          </View>

                          <View style={{ flexDirection: 'column', marginLeft: 15 }}>
                            <Text style={[{ fontSize: 14, fontFamily: 'NunitoSans-Bold', fontWeight: '500', }, switchMerchant ? {
                              fontSize: 10,
                            } : {}]}>
                              Send Time
                            </Text>
                            <TouchableOpacity onPress={() => {
                              setShowNotificationTimePicker(true);
                            }} style={{
                              // height: 50,
                              height: switchMerchant ? 35 : 40,
                              paddingHorizontal: 20,
                              backgroundColor: Colors.fieldtBgColor,
                              //marginBottom: 20,
                              width: 160,
                              //marginHorizontal: 10,
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              alignContent: 'center',
                              borderColor: '#E5E5E5',
                              borderWidth: 1,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              borderRadius: 12,
                              marginTop: 10,
                            }}>
                              <EvilIcons name="clock" size={25} color={Colors.primaryColor} style={{ marginLeft: 0 }} />
                              <Text style={[{
                                marginRight: 10,
                                //color: '#B6B6B6',
                                fontSize: 14,
                                fontFamily: 'NunitoSans-Regular',
                                fontVariant: ['tabular-nums'],
                              }, switchMerchant ? {
                                fontSize: 10,
                              } : {}]}
                              >
                                {moment(notificationTime).format('hh:mm A')}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View> */}
                </>
              ) : (
                <></>
              )}

              {/*/////////////////////////////////Takeaway/////////////////////////////////*/}
              {/* here */}
              {selectedPromotionType === LOYALTY_PROMOTION_TYPE.TAKEAWAY ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginBottom: 20,
                      marginTop: 10,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '100%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text
                          style={[
                            {
                              fontWeight: '500',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Criteria
                        </Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          width: 600,
                          alignItems: 'flex-start',
                        }}>
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}>
                          {/* <Text style={{ fontWeight: '500', marginRight: 2 }}>
                        Buy
                      </Text>
                      <TextInput style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 40, justifyContent: 'center', textAlign: 'center', margin: 6 }}
                        placeholder={'0'}
                        placeholderStyle={{ justifyContent: 'center' }}
                        onChangeText={(text) => {
                          // setState({ itemPrice: text });
                          setBuyInput(text);
                        }}
                        defaultValue={buyInput}
                        keyboardType={'number-pad'}
                      /> */}
                          <View style={{ marginRight: 5, marginBottom: 5 }}>
                            <Picker
                              containerStyle={{
                                height: switchMerchant ? 35 : 40,
                                zIndex: 2,
                              }}
                              arrowColor={'black'}
                              arrowSize={20}
                              arrowStyle={{ fontWeight: 'bold' }}
                              labelStyle={{
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              style={{
                                width: 250,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              }}
                              placeholderStyle={{
                                color: Colors.fieldtTxtColor,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              itemStyle={{
                                justifyContent: 'flex-start',
                                marginLeft: 5,
                                zIndex: 2,
                              }}
                              placeholder={'Product'}
                              customTickIcon={(press) => (
                                <Ionicon
                                  name={'md-checkbox'}
                                  color={
                                    press
                                      ? Colors.fieldtBgColor
                                      : Colors.primaryColor
                                  }
                                  size={25}
                                />
                              )}
                              onValueChange={(item) => {
                                console.log(item);
                                setSelectedVariation(item);
                              }}
                              defaultValue={selectedVariation}
                              dropDownMaxHeight={150}
                              dropDownStyle={{
                                width: 250,
                                height: 80,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                borderWidth: 1,
                                textAlign: 'left',
                                zIndex: 2,
                              }}

                            // style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 150, paddingVertical: 0, margin: 6, marginLeft: 0 }}
                            // dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 150, paddingVertical: 0, margin: 6 }}
                            // arrowSize={20}
                            // arrowColor={'black'}
                            // arrowStyle={{ fontWeight: 'bold' }}
                            // placeholderStyle={{ marginLeft: 0 }}
                            // placeholder={"Product"}
                            // // items={[{ label: 'Product A', value: 1 }, { label: 'Product B', value: 2 }]}
                            // items={PROMOTION_TYPE_VARIATION_DROPDOWN_LIST}
                            // itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                            // //defaultValue={buy1Free1SelectedProductList}
                            // //multiple={true}
                            // customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                            // onValueChange={(item) => {
                            //   console.log(item)
                            //   //setBuy1Free1SelectedProductList(item)
                            //   setSelectedVariation(item.value);
                            // }}
                            // defaultValue={selectedVariation}
                            //multiple={true}
                            >
                              {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                return (
                                  <Picker.Item
                                  key={index}
                                  value={value.value}
                                  label={value.label}
                                  />
                                )
                              })}
                            </Picker>
                          </View>

                          {variationItemsDropdownList.length > 0
                            &&
                            (
                              selectedVariationItems.filter(itemId => {
                                return variationItemsDropdownList.find(item2 => item2.value === itemId) ? true : false;
                              }).length === selectedVariationItems.length
                              ||
                              selectedVariationItems.length === 0
                            ) ? (
                            <View style={{ marginRight: 5, marginBottom: 5 }}>
                              <Multiselect
                              style={{
                                width: 250,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              }}
                              options={variationItemsDropdownList}
                              onChange={(items) =>
                                setSelectedVariationItems(items)
                              }
                              // onRemove={(items) =>
                              //   setSelectedVariationItems(items)
                              // }
                              selectedValues={selectedVariationItems}
                              displayValue='label'
                              />
                              {/* <Select
                                containerStyle={{
                                  height: switchMerchant ? 35 : 40,
                                  zIndex: 2,
                                }}
                                arrowColor={'black'}
                                arrowSize={20}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                style={{
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                }}
                                placeholderStyle={{
                                  color: Colors.fieldtTxtColor,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                itemStyle={{
                                  justifyContent: 'flex-start',
                                  marginLeft: 5,
                                  zIndex: 2,
                                }}
                                placeholder={'Select a Product'}
                                multipleText={'%d product(s) selected'}
                                options={variationItemsDropdownList}
                                customTickIcon={(press) => (
                                  <Ionicon
                                    name={'md-checkbox'}
                                    color={
                                      press
                                        ? Colors.fieldtBgColor
                                        : Colors.primaryColor
                                    }
                                    size={25}
                                  />
                                )}
                                onChange={(items) =>
                                  setSelectedVariationItems(items)
                                }
                                defaultValue={selectedVariationItems}
                                isMulti
                                searchable={true}
                                dropDownMaxHeight={150}
                                dropDownStyle={{
                                  width: 250,
                                  height: 150,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  textAlign: 'left',
                                  zIndex: 2,
                                }}

                              // containerStyle={{ height: 35 }}
                              // arrowColor={'black'}
                              // arrowSize={20}
                              // arrowStyle={{ fontWeight: 'bold' }}
                              // labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                              // style={{ width: 210, paddingVertical: 0, borderRadius: 10 }}
                              // placeholderStyle={{ color: Colors.fieldtTxtColor, alignItems: 'center', justifyContent: 'center' }}
                              // placeholder={"Products"}
                              // multipleText={'%d products selected'}
                              // items={variationItemsDropdownList}
                              // //defaultValue={packageSelectedCategoryList}
                              // customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                              // onValueChange={(items) =>
                              //   // console.log(item)
                              //   //setPackageSelectedCategoryList(item)
                              //   setSelectedVariationItems(items)
                              // }
                              // defaultValue={selectedVariationItems}
                              // multiple={true}
                              // searchable={true}
                              // dropDownMaxHeight={190}
                              // dropDownStyle={{ width: 210, height: 190, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1 }}
                              /> */}
                            </View>
                          ) : (
                            <></>
                          )}
                        </View>

                        {/* <View style={{ flexDirection: 'row', padding: 30 }}> */}
                        {/* <View style={{ flex: 3 }}> */}
                        {/* <View style={{ flexDirection: 'row', flex: 1, width: '100%', }}>
                    <View style={{ flex: 0.57, justifyContent: 'center' }}>
                      <Text style={styles.textSize}>Choose Outlet: </Text>
                    </View>
                    <View style={{ flex: 1 }}>
                      <View
                        style={{
                          width: "100%",
                          height: 50,
                          justifyContent: 'center',
                          marginLeft: '5%',

                        }}>
                        <DropDownPicker

                          containerStyle={{ height: 40 }}
                          // placeholder="Choose outlet"
                          items={[{ label: 'Ho Min San(TTDI)', value: 1 }, { label: 'Ho Min San(Mid Valley)', value: 2 }]}
                          // items={targetOutletDropdownList}
                          // defaultValue={selectedTargetOutletId}
                          placeholderStyle={{ color: 'black' }}
                          style={{ backgroundColor: '#fafafa' }}
                          itemStyle={{
                            justifyContent: 'flex-start', marginLeft: 5,
                          }}
                          dropDownStyle={{ backgroundColor: '#fafafa' }}
                        // onValueChange={(item) => {
                        //   setSelectedTargetOutletId(item.value)
                        // }}
                        />
                      </View>
                    </View>

                    <View
                      style={{
                        justifyContent: 'center',
                        flex: 1,
                        marginLeft: '5%',
                      }}>
                    </View>
                  </View> */}

                        {/* <View
                    style={{ flexDirection: 'row', flex: 2, zIndex: -1 }}>                      
                    <View style={{ justifyContent: 'center', flex: 0.6 }}>
                      <View>
                        <Text style={styles.textSize}>
                          Delivery Distance:{' '}
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            color: Colors.descriptionColor,
                          }}>
                          Max: 15KM{' '}
                        </Text>
                      </View>
                    </View>
                    <View style={{ flex: 1, }}>
                      <View
                        style={{
                          width: 200,
                          height: 50,
                          justifyContent: 'center',
                        }}>
                        <DropDownPicker
                          items={[
                            {
                              label: '1',
                              value: '1',
                            },
                            {
                              label: '2',
                              value: '2',
                            },
                            {
                              label: '3',
                              value: '3',
                            },
                            {
                              label: '4',
                              value: '4',
                            },
                            {
                              label: '5',
                              value: '5',
                            },
                            {
                              label: '6',
                              value: '6',
                            },
                            {
                              label: '7',
                              value: '7',
                            },
                            {
                              label: '8',
                              value: '8',
                            },
                            {
                              label: '9',
                              value: '9',
                            },
                            {
                              label: '10',
                              value: '10',
                            },
                            {
                              label: '11',
                              value: '11',
                            },
                            {
                              label: '12',
                              value: '12',
                            },
                            {
                              label: '13',
                              value: '13',
                            },
                            {
                              label: '14',
                              value: '14',
                            },
                            {
                              label: '15',
                              value: '15',
                            },
                          ]}
                          containerStyle={{ height: 40 }}
                          placeholder="Select KM"
                          // defaultValue={deliveryDistance}
                          placeholderStyle={{ color: 'black' }}
                          style={{ backgroundColor: '#fafafa' }}
                          itemStyle={{
                            justifyContent: 'flex-start', marginLeft: 5,
                          }}
                          dropDownStyle={{ backgroundColor: '#fafafa' }}
                        // onValueChange={(item) => {
                        //   setDeliveryDistance(item.value);
                        // }}
                        />
                      </View>
                    </View>
                    <View style={{ flex: 1 }}></View>
                  </View> */}
                        <View style={{ flexDirection: 'row', zIndex: -2 }}>
                          <View style={{ justifyContent: 'center', flex: 1 }}>
                            <Text
                              style={[
                                { fontFamily: 'NunitoSans-Bold', fontSize: 14 },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Delivery Fee{' '}
                            </Text>
                          </View>
                          <View
                            style={{
                              flex: 3.5,
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}>
                            <Text
                              style={[
                                {
                                  fontSize: 14,
                                  fontFamily: 'NunitoSans-Bold',
                                  color: 'black',
                                  paddingVertical: 15,
                                  marginRight: 20,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              Free Takeaway Above RM
                            </Text>

                            <View
                              style={{
                                height: switchMerchant ? 35 : 40,
                                alignSelf: 'center',
                              }}>
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={[
                                  styles.textInput8,
                                  { textAlign: 'center', fontSize: 14 },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder="0"
                                placeholderStyle={{
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: '#a9a9a9',
                                })}
                                onChangeText={(text) => {
                                  setTakeawayFreeAboveAmount(text);
                                }}
                                defaultValue={takeawayFreeAboveAmount}
                                // ref={myTextInput}
                                keyboardType={'number-pad'}
                              />
                            </View>
                            <input
                              style={{
                                paddingVertical: 10,
                                marginLeft: '4%',
                                zIndex: 1,
                              }}
                              onChange={() => {
                                setTakeawayFreeFlag(!takeawayFreeFlag);
                              }}
                              type='checkbox'
                              checked={takeawayFreeFlag}
                            // rightText={'Free Takeaway Above RM:'}
                            // rightTextStyle={{
                            //   fontSize: 13,
                            //   fontFamily: 'NunitoSans-SemiBold',
                            //   color: Colors.descriptionColor,
                            // }}
                            />
                          </View>

                          {/* <View style={{ flex: 1 }}></View> */}
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            zIndex: -2,
                          }}>
                          <View style={{ flex: 1 }}></View>
                          <View
                            style={{
                              flex: 3.5,
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}>
                            <View style={{}}>
                              <Text
                                style={[
                                  {
                                    color: 'black',
                                    fontFamily: 'NunitoSans-Bold',
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}>
                                Discount (RM)
                              </Text>
                            </View>
                            <View
                              style={{
                                height: switchMerchant ? 35 : 40,
                                paddingHorizontal: 5,
                              }}>
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={[
                                  styles.textInput8,
                                  { textAlign: 'center', fontSize: 14 },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder="0"
                                placeholderStyle={{
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                onChangeText={(text) => {
                                  setTakeawayDiscountAmount(text);
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: '#a9a9a9',
                                })}
                                value={takeawayDiscountAmount}
                                // ref={myTextInput}
                                keyboardType={'number-pad'}
                              />
                            </View>

                            <View style={{ marginLeft: 15 }}>
                              <Text
                                style={[
                                  {
                                    color: 'black',
                                    fontSize: 14,
                                    fontFamily: 'NunitoSans-Bold',
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}>
                                Order Above (RM)
                              </Text>
                            </View>

                            <View
                              style={{
                                height: switchMerchant ? 35 : 40,
                                paddingHorizontal: 5,
                              }}>
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={[
                                  styles.textInput8,
                                  { textAlign: 'center', fontSize: 14 },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder="0"
                                placeholderStyle={{
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                onChangeText={(text) => {
                                  setTakeawayDiscountAboveAmount(text);
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: '#a9a9a9',
                                })}
                                value={takeawayDiscountAboveAmount}
                                // ref={myTextInput}
                                keyboardType={'number-pad'}
                              />
                            </View>
                          </View>
                        </View>

                        {/* <View style={{ borderWidth: 1, borderColor: '#E5E5E5', marginVertical: 12, marginHorizontal: -12, zIndex: -3 }} /> */}

                        {/* *******************Border Line******************* */}
                        {/* <View style={{ borderWidth: 1, width: '104.5%', borderColor: '#E5E5E5', marginVertical: 12, marginHorizontal: -12, zIndex: -3 }} />
                  <View style={{ flexDirection: 'row', }}>
                    <View style={{ flex: 3 }}>
                      <View>
                        <View
                          style={{
                            flexDirection: 'row',
                            flex: 1,
                          }}>
                          <View style={{ justifyContent: 'center' }}>
                            <Text style={styles.textSize}>
                              Delivery Price:{'    '}
                            </Text>
                          </View>
                          <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 1 }}>
                              <NumericInput
                                // value={deliveryPrice}
                                // onChange={(value) =>
                                //   setDeliveryPrice(value),
                                //   console.log('pressed')
                                // }
                                minValue={0}
                                maxValue={50}
                                onLimitReached={(isMax, msg) =>
                                  console.log(isMax, msg)
                                }
                                totalWidth={150}
                                totalHeight={32}
                                iconSize={25}
                                step={1}
                                valueType="real"
                                rounded
                                textColor={Colors.primaryColor}
                                iconStyle={{ color: 'white' }}
                                rightButtonBackgroundColor={Colors.primaryColor}
                                leftButtonBackgroundColor={'grey'}
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View> */}
                      </View>
                    </View>
                  </View>
                  {/* <View style={{ marginTop: 10, marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -3 }}>
                    <Text style={[{ fontWeight: '500', fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? {
                      fontSize: 10,
                    } : {}]}>Blasting Notification</Text>
                    <View style={{ marginTop: '1%', borderWidth: 1, borderColor: '#E5E5E5', width: '79%' }}>
                      <View style={{ margin: '2%' }}>
                        <View style={{ flexDirection: 'row' }}>

                          <DateTimePickerModal
                            isVisible={showNotificationDatePicker}
                            mode={'date'}
                            onConfirm={(text) => {
                              setNotificationDate(moment(text));

                              setShowNotificationDatePicker(false);
                            }}
                            onCancel={() => {
                              setShowNotificationDatePicker(false);
                            }}
                          />

                          <DateTimePickerModal
                            isVisible={showNotificationTimePicker}
                            mode={'time'}
                            onConfirm={(text) => {
                              setNotificationTime(moment(text));

                              setShowNotificationTimePicker(false);
                            }}
                            onCancel={() => {
                              setShowNotificationTimePicker(false);
                            }}
                          />

                          <View style={{ flexDirection: 'column' }}>
                            <Text style={[{ fontSize: 14, fontFamily: 'NunitoSans-Bold', fontWeight: '500', }, switchMerchant ? {
                              fontSize: 10,
                            } : {}]}>
                              Send Date
                            </Text>
                            <TouchableOpacity onPress={() => {
                              setShowNotificationDatePicker(true)
                            }} style={{
                              // height: 50,
                              height: switchMerchant ? 35 : 40,
                              paddingHorizontal: 20,
                              backgroundColor: Colors.fieldtBgColor,
                              //marginBottom: 20,
                              width: 160,
                              //marginHorizontal: 10,
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              alignContent: 'center',
                              borderColor: '#E5E5E5',
                              borderWidth: 1,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              borderRadius: 12,
                              marginTop: 10,
                            }}>
                              <GCalendar width={20} height={20} style={{ marginLeft: 0 }} />
                              <Text style={[{
                                marginRight: 0,
                                //color: '#B6B6B6',
                                fontSize: 14,
                                fontFamily: 'NunitoSans-Regular',
                                fontVariant: ['tabular-nums'],
                              }, switchMerchant ? {
                                fontSize: 10,
                              } : {}]}>
                                {moment(notificationDate).format('DD MMM YYYY')}
                              </Text>

                            </TouchableOpacity>
                          </View>

                          <View style={{ flexDirection: 'column', marginLeft: 15 }}>
                            <Text style={[{ fontSize: 14, fontFamily: 'NunitoSans-Bold', fontWeight: '500', }, switchMerchant ? {
                              fontSize: 10,
                            } : {}]}>
                              Send Time
                            </Text>
                            <TouchableOpacity onPress={() => {
                              setShowNotificationTimePicker(true);
                            }} style={{
                              // height: 50,
                              height: switchMerchant ? 35 : 40,
                              paddingHorizontal: 20,
                              backgroundColor: Colors.fieldtBgColor,
                              //marginBottom: 20,
                              width: 160,
                              //marginHorizontal: 10,
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              alignContent: 'center',
                              borderColor: '#E5E5E5',
                              borderWidth: 1,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              borderRadius: 12,
                              marginTop: 10,
                            }}>
                              <EvilIcons name="clock" size={25} color={Colors.primaryColor} style={{ marginLeft: 0 }} />
                              <Text style={[{
                                marginRight: 10,
                                //color: '#B6B6B6',
                                fontSize: 14,
                                fontFamily: 'NunitoSans-Regular',
                              }, switchMerchant ? {
                                fontSize: 10,
                              } : {}]}>
                                {moment(notificationTime).format('hh:mm A')}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View> */}
                </>
              ) : (
                <></>
              )}

              {/*//////////////////////Buy A Get B For % Discount/////////////////////////*/}

              {selectedPromotionType ===
                LOYALTY_PROMOTION_TYPE.BUY_A_GET_X_FOR_DISCOUNT ? (
                <>
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginBottom: 20,
                      marginTop: 10,
                      zIndex: -1,
                    }}>
                    <View style={{ width: '100%' }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text style={{ fontWeight: '500' }}>Criteria</Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          width: 500,
                          alignItems: 'flex-start',
                        }}>
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text style={{ fontWeight: '500', marginRight: 2 }}>
                            Buy
                          </Text>
                          {/* <TextInput style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 40, justifyContent: 'center', textAlign: 'center', margin: 6 }}
                      placeholder={'0'}
                      placeholderStyle={{ justifyContent: 'center' }}
                      onChangeText={(text) => {
                        // setState({ itemPrice: text });
                        setBuyInput(text);
                      }}
                      defaultValue={buyInput}
                      keyboardType={'number-pad'}
                    /> */}
                          <View>
                            <Picker
                              style={{
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                borderRadius: 5,
                                height: 35,
                                width: 150,
                                paddingVertical: 0,
                                margin: 6,
                              }}
                              dropDownStyle={{
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                borderRadius: 5,
                                width: 150,
                                paddingVertical: 0,
                                margin: 6,
                              }}
                              arrowSize={20}
                              arrowColor={'black'}
                              arrowStyle={{ fontWeight: 'bold' }}
                              placeholderStyle={{ marginLeft: 0 }}
                              placeholder={'Product'}
                              // items={[{ label: 'Product A', value: 1 }, { label: 'Product B', value: 2 }]}
                              itemStyle={{
                                justifyContent: 'flex-start',
                                marginLeft: 5,
                              }}
                              //defaultValue={buy1Free1SelectedProductList}
                              //multiple={true}
                              customTickIcon={(press) => (
                                <Ionicon
                                  name={'md-checkbox'}
                                  color={
                                    press
                                      ? Colors.fieldtBgColor
                                      : Colors.primaryColor
                                  }
                                  size={25}
                                />
                              )}
                              onValueChange={(item) => {
                                console.log(item);
                                //setBuy1Free1SelectedProductList(item)
                                setSelectedVariation(item);
                              }}
                              defaultValue={selectedVariation}
                            //multiple={true}
                            >
                              {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                return (
                                  <Picker.Item
                                  key={index}
                                  value={value.value}
                                  label={value.label}
                                  />
                                )
                              })}
                            </Picker>
                          </View>

                          {variationItemsDropdownList.length > 0
                            &&
                            (
                              selectedVariationItems.filter(itemId => {
                                return variationItemsDropdownList.find(item2 => item2.value === itemId) ? true : false;
                              }).length === selectedVariationItems.length
                              ||
                              selectedVariationItems.length === 0
                            ) ? (
                            <Multiselect
                            style={{
                              width: 210,
                              paddingVertical: 0,
                              borderRadius: 10,
                            }}
                            options={variationItemsDropdownList}
                            onSelect={(items) =>
                              // console.log(item)
                              //setPackageSelectedCategoryList(item)
                              setSelectedVariationItems(items)
                            }
                            onRemove={(items) =>
                              // console.log(item)
                              //setPackageSelectedCategoryList(item)
                              setSelectedVariationItems(items)
                            }
                            selectedValues={selectedVariationItems}
                            displayValue='label'
                            />
                            // <Select
                            //   containerStyle={{ height: 35 }}
                            //   arrowColor={'black'}
                            //   arrowSize={20}
                            //   arrowStyle={{ fontWeight: 'bold' }}
                            //   labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                            //   style={{
                            //     width: 210,
                            //     paddingVertical: 0,
                            //     borderRadius: 10,
                            //   }}
                            //   placeholderStyle={{
                            //     color: Colors.fieldtTxtColor,
                            //     alignItems: 'center',
                            //     justifyContent: 'center',
                            //   }}
                            //   placeholder={'Products'}
                            //   multipleText={'%d products selected'}
                            //   options={variationItemsDropdownList}
                            //   //defaultValue={packageSelectedCategoryList}
                            //   customTickIcon={(press) => (
                            //     <Ionicon
                            //       name={'md-checkbox'}
                            //       color={
                            //         press
                            //           ? Colors.fieldtBgColor
                            //           : Colors.primaryColor
                            //       }
                            //       size={25}
                            //     />
                            //   )}
                            //   onChange={(items) =>
                            //     // console.log(item)
                            //     //setPackageSelectedCategoryList(item)
                            //     setSelectedVariationItems(items)
                            //   }
                            //   defaultValue={selectedVariationItems}
                            //   isMulti
                            //   searchable={true}
                            //   dropDownMaxHeight={190}
                            //   dropDownStyle={{
                            //     width: 210,
                            //     height: 190,
                            //     backgroundColor: Colors.fieldtBgColor,
                            //     borderRadius: 10,
                            //     borderWidth: 1,
                            //   }}
                            // />
                          ) : (
                            <></>
                          )}
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            zIndex: -1,
                          }}>
                          <Text style={{ fontWeight: '500', marginRight: 4 }}>
                            Get
                          </Text>
                          {/* <TextInput style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 40, justifyContent: 'center', textAlign: 'center', margin: 6 }}
                      placeholder={'0'}
                      placeholderStyle={{ justifyContent: 'center' }}
                      onChangeText={(text) => {
                        // setState({ itemPrice: text });
                        setGetInput(text);
                      }}
                      defaultValue={getInput}
                      keyboardType={'number-pad'}
                    /> */}
                          <View>
                            <Picker
                              style={{
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                borderRadius: 5,
                                height: 35,
                                width: 150,
                                paddingVertical: 0,
                                margin: 6,
                              }}
                              dropDownStyle={{
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                borderRadius: 5,
                                width: 150,
                                paddingVertical: 0,
                                margin: 6,
                              }}
                              arrowSize={20}
                              arrowColor={'black'}
                              arrowStyle={{ fontWeight: 'bold' }}
                              placeholderStyle={{ marginLeft: 0 }}
                              placeholder={'Product'}
                              itemStyle={{
                                justifyContent: 'flex-start',
                                marginLeft: 5,
                              }}
                              //defaultValue={buy1Free1SelectedProductList}
                              //multiple={true}
                              customTickIcon={(press) => (
                                <Ionicon
                                  name={'md-checkbox'}
                                  color={
                                    press
                                      ? Colors.fieldtBgColor
                                      : Colors.primaryColor
                                  }
                                  size={25}
                                />
                              )}
                              onValueChange={(item) => {
                                console.log(item);
                                //setBuy1Free1SelectedProductList(item)
                                setSelectedVariationB1F1(item);
                              }}
                              defaultValue={selectedVariationB1F1}
                            //multiple={true}
                            >
                              {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.slice(1,).map((value,index) => {
                                return (
                                  <Picker.Item
                                  key={index}
                                  value={value.value}
                                  label={value.label}
                                  />
                                )
                              })}
                            </Picker>
                          </View>

                          {variationItemsB1F1DropdownList.length > 0 &&
                            (variationItemsB1F1DropdownList.find(
                              (item) =>
                                item.value === selectedVariationItemsB1F1[0],
                            ) ||
                              selectedVariationItemsB1F1.length === 0) ? (
                            <Multiselect
                            style={{
                              width: 210,
                              paddingVertical: 0,
                              borderRadius: 10,
                            }}
                            options={variationItemsB1F1DropdownList}
                            onSelect={(items) =>
                              // console.log(item)
                              //setPackageSelectedCategoryList(item)
                              setSelectedVariationItemsB1F1(items)
                            }
                            onRemove={(items) =>
                              // console.log(item)
                              //setPackageSelectedCategoryList(item)
                              setSelectedVariationItemsB1F1(items)
                            }
                            selectedValues={selectedVariationItemsB1F1}
                            displayValue='label'
                            />
                            // <Select
                            //   containerStyle={{ height: 35 }}
                            //   arrowColor={'black'}
                            //   arrowSize={20}
                            //   arrowStyle={{ fontWeight: 'bold' }}
                            //   labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                            //   style={{
                            //     width: 210,
                            //     paddingVertical: 0,
                            //     borderRadius: 10,
                            //   }}
                            //   placeholderStyle={{
                            //     color: Colors.fieldtTxtColor,
                            //     alignItems: 'center',
                            //     justifyContent: 'center',
                            //   }}
                            //   placeholder={'Products'}
                            //   multipleText={'%d products selected'}
                            //   options={variationItemsB1F1DropdownList}
                            //   itemStyle={{ justifyContent: 'flex-start' }}
                            //   //defaultValue={packageSelectedCategoryList}
                            //   customTickIcon={(press) => (
                            //     <Ionicon
                            //       name={'md-checkbox'}
                            //       color={
                            //         press
                            //           ? Colors.fieldtBgColor
                            //           : Colors.primaryColor
                            //       }
                            //       size={25}
                            //     />
                            //   )}
                            //   onChange={(items) =>
                            //     // console.log(item)
                            //     //setPackageSelectedCategoryList(item)
                            //     setSelectedVariationItemsB1F1(items)
                            //   }
                            //   defaultValue={selectedVariationItemsB1F1}
                            //   isMulti
                            //   searchable={true}
                            //   dropDownMaxHeight={190}
                            //   dropDownStyle={{
                            //     width: 210,
                            //     height: 190,
                            //     backgroundColor: Colors.fieldtBgColor,
                            //     borderRadius: 10,
                            //     borderWidth: 1,
                            //   }}
                            // />
                          ) : (
                            <></>
                          )}
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            zIndex: -2,
                            marginTop: 4,
                          }}>
                          <Text style={{ fontWeight: '500', marginRight: 12 }}>
                            For
                          </Text>
                          <TextInput
                            style={{
                              borderColor: '#E5E5E5',
                              borderWidth: 1,
                              borderRadius: 5,
                              width: 40,
                              height: 30,
                              textAlign: 'center',
                            }}
                            placeholder={'%'}
                            placeholderTextColor={Platform.select({
                              ios: '#a9a9a9',
                            })}
                            onChangeText={(text) => {
                              setPercentageDiscount(text);
                            }}
                            value={percentageDiscount}
                          />
                          <Text style={{ fontWeight: '500', marginLeft: 5 }}>
                            % Discount
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                  {/* <View style={{ marginTop: 10, marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -3 }}>
                      <Text style={{ fontWeight: '500' }}>Blasting Notification</Text>
                      <View style={{ marginTop: '1%', borderWidth: 1, borderColor: '#E5E5E5', width: '79%' }}>
                        <View style={{ margin: '2%' }}>
                          <View style={{ flexDirection: 'row' }}>

                            <DateTimePickerModal
                              isVisible={showNotificationDatePicker}
                              mode={'date'}
                              onConfirm={(text) => {
                                setNotificationDate(moment(text));

                                setShowNotificationDatePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationDatePicker(false);
                              }}
                            />

                            <DateTimePickerModal
                              isVisible={showNotificationTimePicker}
                              mode={'time'}
                              onConfirm={(text) => {
                                setNotificationTime(moment(text));

                                setShowNotificationTimePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationTimePicker(false);
                              }}
                            />

                            <View style={{ flexDirection: 'column' }}>
                              <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', fontWeight: '500', }}>
                                Send Date
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationDatePicker(true)
                              }} style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 16,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <Text style={{
                                  marginLeft: 15,
                                  color: '#B6B6B6',
                                  fontSize: 14
                                }}
                                >
                                  {moment(notificationDate).format('DD/MM/YYYY')}
                                </Text>

                                <GCalendar width={20} height={20} style={{ marginRight: 0 }} />
                              </TouchableOpacity>
                            </View>

                            <View style={{ flexDirection: 'column', marginLeft: 15 }}>
                              <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', fontWeight: '500', }}>
                                Send Time
                              </Text>
                              <TouchableOpacity onPress={() => {
                                setShowNotificationTimePicker(true);
                              }} style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                alignContent: 'center',
                                borderColor: '#E5E5E5',
                                borderWidth: 1,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 16,
                                borderRadius: 12,
                                marginTop: 10,
                              }}>
                                <Text style={{
                                  marginLeft: 15,
                                  color: '#B6B6B6',
                                  fontSize: 14
                                }}
                                >
                                  {moment(notificationTime).format('hh:mm a')}
                                </Text>

                                <EvilIcons name="clock" size={25} color={Colors.primaryColor} style={{ marginRight: 0 }} />

                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View> */}
                </>
              ) : (
                <></>
              )}

              {/*//////////////////////Buy AB Get Voucher/////////////////////////*/}

              {selectedPromotionType ===
                LOYALTY_PROMOTION_TYPE.BUY_AB_GET_VOUCHER ? (
                <View
                  style={{
                    marginHorizontal: 20,
                    marginBottom: 20,
                    marginTop: 10,
                    zIndex: -1,
                  }}>
                  <View style={{ width: '100%' }}>
                    <View style={{ marginBottom: 5 }}>
                      <Text style={{ fontWeight: '500' }}>Criteria</Text>
                    </View>

                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        borderRadius: 3,
                        padding: 13,
                        paddingTop: 10,
                        width: 500,
                        alignItems: 'flex-start',
                      }}>
                      <View
                        style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontWeight: '500', marginRight: 2 }}>
                          Buy
                        </Text>
                        <View>
                          <Picker
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: 35,
                              width: 150,
                              paddingVertical: 0,
                              margin: 6,
                            }}
                            dropDownStyle={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              width: 150,
                              paddingVertical: 0,
                              margin: 6,
                            }}
                            arrowSize={20}
                            arrowColor={'black'}
                            arrowStyle={{ fontWeight: 'bold' }}
                            placeholderStyle={{ marginLeft: 0 }}
                            placeholder={'Product'}
                            // items={[{ label: 'Product A', value: 1 }, { label: 'Product B', value: 2 }]}
                            itemStyle={{
                              justifyContent: 'flex-start',
                              marginLeft: 5,
                            }}
                            //defaultValue={buy1Free1SelectedProductList}
                            //multiple={true}
                            customTickIcon={(press) => (
                              <Ionicon
                                name={'md-checkbox'}
                                color={
                                  press
                                    ? Colors.fieldtBgColor
                                    : Colors.primaryColor
                                }
                                size={25}
                              />
                            )}
                            onValueChange={(item) => {
                              console.log(item);
                              //setBuy1Free1SelectedProductList(item)
                              setSelectedVariation(item);
                            }}
                            defaultValue={selectedVariation}
                          //multiple={true}
                          >
                            {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                              return (
                                <Picker.Item 
                                key={index}
                                value={value.value}
                                label={value.label}
                                />
                              )
                            })}
                          </Picker>
                        </View>

                        {variationItemsDropdownList.length > 0
                          &&
                          (
                            selectedVariationItems.filter(itemId => {
                              return variationItemsDropdownList.find(item2 => item2.value === itemId) ? true : false;
                            }).length === selectedVariationItems.length
                            ||
                            selectedVariationItems.length === 0
                          ) ? (
                          <Multiselect
                          style={{
                            width: 210,
                            paddingVertical: 0,
                            borderRadius: 10,
                          }}
                          options={variationItemsDropdownList}
                          onSelect={(items) =>
                            // console.log(item)
                            //setPackageSelectedCategoryList(item)
                            setSelectedVariationItems(items)
                          }
                          onRemove={(items) =>
                            // console.log(item)
                            //setPackageSelectedCategoryList(item)
                            setSelectedVariationItems(items)
                          }
                          selectedValues={selectedVariationItems}
                          displayValue='label'
                          />
                          // <Select
                          //   containerStyle={{ height: 35 }}
                          //   arrowColor={'black'}
                          //   arrowSize={20}
                          //   arrowStyle={{ fontWeight: 'bold' }}
                          //   labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                          //   style={{
                          //     width: 210,
                          //     paddingVertical: 0,
                          //     borderRadius: 10,
                          //   }}
                          //   placeholderStyle={{
                          //     color: Colors.fieldtTxtColor,
                          //     alignItems: 'center',
                          //     justifyContent: 'center',
                          //   }}
                          //   placeholder={'Products'}
                          //   multipleText={'%d products selected'}
                          //   options={variationItemsDropdownList}
                          //   //defaultValue={packageSelectedCategoryList}
                          //   customTickIcon={(press) => (
                          //     <Ionicon
                          //       name={'md-checkbox'}
                          //       color={
                          //         press
                          //           ? Colors.fieldtBgColor
                          //           : Colors.primaryColor
                          //       }
                          //       size={25}
                          //     />
                          //   )}
                          //   onChange={(items) =>
                          //     // console.log(item)
                          //     //setPackageSelectedCategoryList(item)
                          //     setSelectedVariationItems(items)
                          //   }
                          //   defaultValue={selectedVariationItems}
                          //   isMulti
                          //   searchable={true}
                          //   dropDownMaxHeight={190}
                          //   dropDownStyle={{
                          //     width: 210,
                          //     height: 190,
                          //     backgroundColor: Colors.fieldtBgColor,
                          //     borderRadius: 10,
                          //     borderWidth: 1,
                          //   }}
                          // />
                        ) : (
                          <></>
                        )}
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          zIndex: -1,
                        }}>
                        <Text style={{ fontWeight: '500', marginRight: 4 }}>
                          Get
                        </Text>

                        <View>
                          <Picker
                            style={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              height: 35,
                              width: 200,
                              paddingVertical: 0,
                              margin: 6,
                            }}
                            dropDownStyle={{
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                              width: 200,
                              paddingVertical: 0,
                              margin: 6,
                            }}
                            arrowSize={20}
                            arrowColor={'black'}
                            arrowStyle={{ fontWeight: 'bold' }}
                            placeholderStyle={{ marginLeft: 0 }}
                            placeholder={'Voucher'}
                            // items={[{ label: 'Product A', value: 1 }, { label: 'Product B', value: 2 }]}
                            itemStyle={{
                              justifyContent: 'flex-start',
                              marginLeft: 5,
                            }}
                            //defaultValue={buy1Free1SelectedProductList}
                            //multiple={true}
                            customTickIcon={(press) => (
                              <Ionicon
                                name={'md-checkbox'}
                                color={
                                  press
                                    ? Colors.fieldtBgColor
                                    : Colors.primaryColor
                                }
                                size={25}
                              />
                            )}
                            onValueChange={(item) => {
                              console.log(item);
                              //setBuy1Free1SelectedProductList(item)
                              setSelectedVariationB1F1(item);
                            }}
                            defaultValue={selectedVariationB1F1}
                          //multiple={true}
                          >
                            {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.slice(1,).map((value,index) => {
                              return(
                                <Picker.Item
                                key={index}
                                value={value.value}
                                label={value.label}
                                />
                              )
                            })}
                          </Picker>
                        </View>
                        <Text style={{ fontWeight: '500', marginLeft: 4 }}>
                          Voucher
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              ) : (
                <></>
              )}

              {/*//////////////////////////////////END HERE//////////////////////////////////////*/}

              {/* <View style={{ marginBottom: '2%' }}>
              <Text>
              </Text>
            </View> */}
              <View style={{ paddingTop: 30, zIndex: -3 }}>
                <Text
                  style={[
                    {
                      fontWeight: '500',
                      fontFamily: 'NunitoSans-Bold',
                      fontSize: 14,
                      marginLeft: '2%',
                    },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}>
                  Blasting Notification
                </Text>
                <View
                  style={{
                    marginTop: '1%',
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    width: '80%',
                    // width: 690,
                    marginHorizontal: 20,
                  }}>
                  {/* <View style={{margin: '2%'}}>
                          <View style={{flexDirection: 'row'}}>
                            <DateTimePickerModal
                              isVisible={showNotificationDatePicker}
                              mode={'date'}
                              onConfirm={(text) => {
                                setNotificationDate(moment(text));

                                setShowNotificationDatePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationDatePicker(false);
                              }}
                            />

                            <DateTimePickerModal
                              isVisible={showNotificationTimePicker}
                              mode={'time'}
                              onConfirm={(text) => {
                                setNotificationTime(moment(text));

                                setShowNotificationTimePicker(false);
                              }}
                              onCancel={() => {
                                setShowNotificationTimePicker(false);
                              }}
                            />

                            <View style={{flexDirection: 'column'}}>
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: 'NunitoSans-Bold',
                                    fontWeight: '500',
                                  },
                                  switchMerchant
                                    ? {
                                        fontSize: 10,
                                      }
                                    : {},
                                ]}>
                                Send Date
                              </Text>
                              <TouchableOpacity
                                onPress={() => {
                                  setShowNotificationDatePicker(true);
                                }}
                                style={[
                                  {
                                    // height: 50,
                                    height: switchMerchant ? 35 : 40,
                                    paddingHorizontal: 20,
                                    backgroundColor: Colors.fieldtBgColor,
                                    //marginBottom: 20,
                                    width: 160,
                                    //marginHorizontal: 10,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    alignContent: 'center',
                                    borderColor: '#E5E5E5',
                                    borderWidth: 1,
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: switchMerchant ? 10 : 14,
                                    borderRadius: 12,
                                    marginTop: 10,
                                  },
                                  switchMerchant
                                    ? {
                                        width: 130,
                                        height: 35,
                                      }
                                    : {},
                                ]}>
                                <View
                                  style={{alignSelf: 'center'}}
                                  onPress={() => {
                                    setState({
                                      pickerMode: 'date',
                                      showDateTimePicker: true,
                                    });
                                  }}>
                                  <GCalendar
                                    width={switchMerchant ? 15 : 20}
                                    height={switchMerchant ? 15 : 20}
                                  />
                                </View>
                                <Text
                                  style={[
                                    {
                                      marginRight: 0,
                                      //color: '#B6B6B6',
                                      fontSize: 14,
                                      fontFamily: 'NunitoSans-Regular',
                                      fontVariant: ['tabular-nums'],
                                    },
                                    switchMerchant
                                      ? {
                                          fontSize: 10,
                                        }
                                      : {},
                                  ]}>
                                  {moment(notificationDate).format(
                                    'DD MMM YYYY',
                                  )}
                                </Text>
                              </TouchableOpacity>
                            </View>

                            <View
                              style={{flexDirection: 'column', marginLeft: 15}}>
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: 'NunitoSans-Bold',
                                    fontWeight: '500',
                                  },
                                  switchMerchant
                                    ? {
                                        fontSize: 10,
                                      }
                                    : {},
                                ]}>
                                Send Time
                              </Text>
                              <TouchableOpacity
                                onPress={() => {
                                  setShowNotificationTimePicker(true);
                                }}
                                style={[
                                  {
                                    // height: 50,
                                    height: switchMerchant ? 35 : 40,
                                    paddingHorizontal: 20,
                                    backgroundColor: Colors.fieldtBgColor,
                                    //marginBottom: 20,
                                    width: 160,
                                    //marginHorizontal: 10,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    alignContent: 'center',
                                    borderColor: '#E5E5E5',
                                    borderWidth: 1,
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: switchMerchant ? 10 : 14,
                                    borderRadius: 12,
                                    marginTop: 10,
                                  },
                                  switchMerchant
                                    ? {
                                        width: 130,
                                        height: 35,
                                      }
                                    : {},
                                ]}>
                                <EvilIcons
                                  name="clock"
                                  size={switchMerchant ? 20 : 25}
                                  color={Colors.primaryColor}
                                  style={{marginLeft: 0}}
                                />

                                <Text
                                  style={[
                                    {
                                      marginRight: 10,
                                      //color: '#B6B6B6',
                                      fontSize: 14,
                                      fontFamily: 'NunitoSans-Regular',
                                      fontVariant: ['tabular-nums'],
                                    },
                                    switchMerchant
                                      ? {
                                          fontSize: 10,
                                        }
                                      : {},
                                  ]}>
                                  {moment(notificationTime).format('hh:mm A')}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View> */}
                  <View
                    style={{
                      margin: '2%',
                    }}>
                    <Text
                      style={{
                        fontFamily: 'NunitoSans-Bold',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      Notification/SMS Message
                    </Text>
                    {/* <View
                            style={{
                              flexDirection: 'row',
                              width: '100%',
                              justifyContent: 'space-between',
                              paddingTop: 10,
                              alignItems: 'center',
                            }}>
                            <Text
                              style={{
                                borderRadius: 999,
                                padding: 1,
                                borderWidth: 1,
                                height: 35,
                                textAlignVertical: 'center',
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}>
                              {`  en  `}
                            </Text>
                          </View> */}
                  </View>
                  <View style={{ marginTop: 0, }}>
                    <TextInput
                      //underlineColorAndroid={Colors.fieldtBgColor}
                      // style={[styles.textFieldInput, {
                      //   justifyContent: 'flex-start',
                      //   alignContent: 'flex-start',
                      //   backgroundColor: Colors.whiteColor,
                      //   borderRadius: 10,
                      //   padding: 10,
                      //   shadowColor: '#000',
                      //   shadowOffset: {
                      //     width: 0,
                      //     height: 2,
                      //   },
                      //   shadowOpacity: 0.22,
                      //   shadowRadius: 3.22,
                      //   elevation: 1,
                      // }]}
                      style={[{
                        // marginTop: '2%',

                        marginLeft: '2%',

                        padding: 5,
                        backgroundColor: Colors.fieldtBgColor,
                        // width: Platform.OS == 'ios' ? '90%' : '85%',
                        // height: Platform.OS == 'ios' ? 100 : 117,
                        width: 650,
                        height: 140,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        paddingTop: Platform.OS == 'ios' ? 10 : 10,
                        paddingLeft: 10,
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 14
                      }, switchMerchant ? {
                        fontSize: 10,
                        width: '92%',
                        height: 97,
                      } : {}]}
                      textAlignVertical={'top'}
                      placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: 14 }}
                      placeholder="Hi %userName%, we have sent you a voucher of a free cup of coffee, visit %outletName% to redeem now!"
                      placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                      //placeholderStyle={{alignItems: 'flex-start', alignContent: 'flex-start'}}
                      onChangeText={(text) => {
                        // setState({ remark: text });
                        //setRemark(text);
                        text.length <= 150 ? setSmsText(text) : Alert.alert('Error', 'Maximum character 150')
                        // setWordCount(text.length)
                        //setNotificationDescription(text);
                      }}
                      //value={remark}
                      defaultValue={smsText}
                      multiline={true}
                    //maxLength={100}
                    />

                    <View style={{
                      width: 650,
                      marginLeft: '2%',
                      marginBottom: '2%',
                    }}>
                      <Text style={{
                        color: Colors.fieldtTxtColor,
                        fontSize: 13,
                        alignSelf: 'flex-end',
                        marginTop: 2,
                      }}>
                        {/* Character: {notificationDescription ? wordCount : '0'}/150 */}
                        Characters: {`${smsText.length}`}/150
                      </Text>
                    </View>
                  </View>
                  {/* <View style={{ margin: '2%' }}>
                          <TextInput
                            style={[
                              {
                                backgroundColor: Colors.whiteColor,
                                width: '100%',
                                height: Dimensions.get('screen').height * 0.15,
                                borderRadius: 5,
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                paddingVertical:
                                  Dimensions.get('screen').height * 0.02,
                                paddingHorizontal:
                                  Dimensions.get('screen').width * 0.01,
                                textAlignVertical: 'top',
                                fontSize: switchMerchant ? 10 : 16,
                                fontFamily: 'NunitoSans-Regular',
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                            placeholder="Notification text here..."
                            placeholderTextColor={Colors.descriptionColor}
                            onChangeText={(text) => {
                              setSmsText(text);
                            }}
                            defaultValue={smsText}
                            multiline={true}
                          />
                        </View> */}
                </View>
              </View>

            </View>

            {/* <View style={{
            flexDirection: 'row', justifyContent: 'flex-end',
            width: '90%',
            marginTop: 20, marginBottom: 20,
          }}>
            <View style={{
              flexDirection: 'row', justifyContent: 'space-between',
              width: '25%',
            }}>
              <TouchableOpacity style={{
                borderWidth: 1, borderColor: Colors.primaryColor, borderRadius: 5, height: 35, width: '48%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
                onPress={() => { props.navigation.goBack() }}
              >
                <Text style={{ alignSelf: 'center', fontSize: 16, fontFamily: 'Nunitosans-Regular', fontWeight: '500', color: Colors.primaryColor }}>
                  Cancel
                    </Text>
              </TouchableOpacity>
              <TouchableOpacity style={{
                borderWidth: 1,
                borderColor: Colors.primaryColor, backgroundColor: Colors.primaryColor, borderRadius: 5, height: 35, width: '48%',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
                disabled={isLoading}
                onPress={createPromotion}
              >
                <Text style={{ alignSelf: 'center', fontSize: 16, fontFamily: 'Nunitosans-Regular', fontWeight: '500', color: Colors.whiteColor }}>
                  {isLoading ? 'LOADING...' : 'Save'}
                </Text>

                {isLoading ?
                  <ActivityIndicator color={Colors.whiteColor} size={'small'} />
                  : <></>
                }
              </TouchableOpacity>
            </View>
          </View> */}

            {/* *************NOTIFICATION******************** */}

            <View
              style={{
                marginTop: 35,
                marginBottom: 15,
                marginLeft: 23,
                flexDirection: 'row',
                alignItems: 'center',
                zIndex: -1,
              }}>
              {/* <Text style={{ marginRight: 15, fontFamily: 'Nunitosans-regular', fontWeight: '500', fontSize: 19, }}>Edit Notification</Text>
            <View style={{ transform: [{ scaleX: 1 }, { scaleY: 1 }] }}>
              <Switch
                onSyncPress={(value) => {
                  setIsEditNotification(value);
                }}
                value={isEditNotification}
                circleColorActive={Colors.primaryColor}
                circleColorInactive={Colors.fieldtTxtColor}
                backgroundActive='#dddddd'
              />
            </View>

            <Text
              style={{ fontSize: 16, marginLeft: 10, fontWeight: '600', color: isEditNotification ? Colors.primaryColor : 'red' }}>
              {
                isEditNotification ?
                  'ON'
                  :
                  'OFF'
              }
            </Text>
          </View>
          <View style={{
            flexDirection: 'row',
            borderWidth: 1,
            borderColor: '#c4c4c4',
            width: '95%',
            alignSelf: 'center',
            flex: 1,
            marginBottom: 15,
            zIndex: -1,
            //marginTop: 30
          }}>
            <View style={{ flex: 6, alignSelf: 'flex-start' }}>
              <View style={{ margin: 30, flexDirection: 'column', alignSelf: 'flex-start' }}>

                <Text style={{
                  fontSize: 14,
                  fontFamily: 'NunitoSans-Regular',
                  //marginLeft: 10,
                  fontWeight: '500',
                }}>
                  Title
                </Text>

                <TextInput style={{
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  //marginLeft:'6%', 
                  height: 40,
                  width: 250,
                  marginTop: 10,
                  paddingLeft: 10
                }}
                  placeholder='Notification Name'
                  placeholderStyle={{ justifyContent: 'center' }}
                  onChangeText={text => {
                    setNotificationTitle(text);
                  }}
                  defaultValue={notificationTitle}
                />

                <Text style={{
                  fontSize: 14,
                  fontFamily: 'NunitoSans-Regular',
                  marginTop: 40,
                  //marginLeft: 15,
                  fontWeight: '500',
                }}>
                  Push To
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'center', marginTop: 10 }}>
                  <TouchableOpacity onPress={() => {
                    setImportSmsModal(true);
                    setIsPushToSMS(!isPushToSMS);
                  }}>
                    <View style={{
                      borderRadius: 5,
                      backgroundColor: isPushToSMS ? Colors.primaryColor : '#ACACAC',
                      marginRight: 2,
                      marginLeft: 2,
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 34,
                      height: 34
                    }}>
                      <Icon name="checkmark-sharp" size={20} color={'#FFFFFF'} style={{ margin: 2 }} />
                    </View>
                  </TouchableOpacity>

                  <Text style={{
                    fontSize: 14,
                    fontFamily: 'NunitoSans-Regular',
                    marginLeft: 10,
                    fontWeight: '500',
                  }}>
                    SMS
                  </Text>

                  <TouchableOpacity onPress={() => {
                    setIsPushToApp(!isPushToApp);
                  }}>
                    <View style={{
                      borderRadius: 5,
                      backgroundColor: isPushToApp ? Colors.primaryColor : '#ACACAC',
                      marginRight: 2,
                      marginLeft: 30,
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 34,
                      height: 34
                    }}>
                      <Icon name="checkmark-sharp" size={20} color={'#FFFFFF'} style={{ margin: 2 }} />
                    </View>
                  </TouchableOpacity>

                  <Text style={{
                    fontSize: 14,
                    fontFamily: 'NunitoSans-Regular',
                    marginLeft: 10,
                    fontWeight: '500',
                  }}>
                    App
                  </Text> */}

              {/* <TouchableOpacity onPress={() => {
                    setIsPushToEmail(!isPushToEmail);
                  }}>
                    <View style={{
                      borderRadius: 5,
                      backgroundColor: isPushToEmail ? Colors.primaryColor : '#ACACAC',
                      marginRight: 2,
                      marginLeft: 30,
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 34,
                      height: 34
                    }}>
                      <Icon name="checkmark-sharp" size={20} color={'#FFFFFF'} style={{ margin: 2 }} />
                    </View>
                  </TouchableOpacity>

                  <Text style={{
                    fontSize: 14,
                    fontFamily: 'NunitoSans-Regular',
                    marginLeft: 10,
                    fontWeight: '500',
                  }}>
                    Email
                  </Text> */}
              {/* </View> */}

              {/* <TouchableOpacity style={{
                  //alignSelf: 'flex-end', 
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 10,
                  borderWidth: 1,
                  borderColor: Colors.primaryColor,
                  width: 70,
                  height: 35,
                  marginTop: 20
                }}
                  onPress={() => { }}>
                  <View style={{ backgroundColor: Colors.primaryColor, alignItems: 'center', paddingLeft: 1.5, borderRadius: 200, width: 20, height: 20 }}>
                    <Icon name='add' color='white' size={18} />
                  </View>
                  <View style={{}}>
                    <Text style={{ alignSelf: 'center', color: Colors.primaryColor, marginLeft: 5 }} >
                      Add
                  </Text>
                  </View>
                </TouchableOpacity> */}

              {/* <Text style={{
                  fontSize: 14,
                  fontFamily: 'NunitoSans-Regular',
                  //marginLeft: 10,
                  marginTop: 60,
                  fontWeight: '500',
                }}>
                  Notification Description
                </Text>

                <View style={{ marginTop: 15 }}>
                  <TextInput
                    //underlineColorAndroid={Colors.fieldtBgColor}
                    style={[styles.textFieldInput, {
                      justifyContent: 'flex-start',
                      alignContent: 'flex-start',
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      padding: 10,
                      shadowColor: '#000',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                    }]}
                    placeholder="Description..."
                    //placeholderStyle={{alignItems: 'flex-start', alignContent: 'flex-start'}}
                    onChangeText={(text) => {
                      // setState({ remark: text });
                      //setRemark(text);
                      text.length <= 100 ? setNotificationDescription(text) : window.confirm('Error', 'Maximum character 100')
                      setWordCount(text.length)
                      //setNotificationDescription(text);
                    }}
                    //value={remark}
                    defaultValue={notificationDescription}
                    multiline={true}
                  //maxLength={100}
                  />
                  <Text style={{ color: Colors.fieldtTxtColor, fontSize: 13, alignSelf: 'flex-end', marginTop: 2 }}>Character: {notificationDescription ? wordCount : '0'}/100</Text>
                </View>

              </View>
            </View> */}
              {/* <View style={{ flex: 10, alignSelf: 'flex-start' }}>
              <View style={{ margin: 30, flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row' }}>

                  <DateTimePickerModal
                    isVisible={showNotificationDatePicker}
                    mode={'date'}
                    onConfirm={(text) => {
                      setNotificationDate(moment(text));

                      setShowNotificationDatePicker(false);
                    }}
                    onCancel={() => {
                      setShowNotificationDatePicker(false);
                    }}
                  />

                  <DateTimePickerModal
                    isVisible={showNotificationTimePicker}
                    mode={'time'}
                    onConfirm={(text) => {
                      setNotificationTime(moment(text));

                      setShowNotificationTimePicker(false);
                    }}
                    onCancel={() => {
                      setShowNotificationTimePicker(false);
                    }}
                  />

                  <View style={{ flexDirection: 'column' }}>
                    <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', fontWeight: '500', }}>
                      Send Date
                    </Text>
                    <TouchableOpacity onPress={() => {
                      setShowNotificationDatePicker(true)
                    }} style={{
                      //marginHorizontal: 4,
                      flexDirection: 'row',
                      alignItems: 'center',
                      //marginLeft: 5,
                      borderRadius: 5,
                      borderWidth: 1,
                      borderColor: '#E5E5E5',
                      //marginLeft:'6%', 
                      height: 40,
                      width: 125,
                      marginTop: 10,
                      justifyContent: 'space-between'
                    }}>
                      <Text style={{
                        marginLeft: 15,
                        color: '#B6B6B6',
                        fontSize: 14
                      }}
                      >
                        {moment(notificationDate).format('DD/MM/YYYY')}
                      </Text>

                      <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} />

                    </TouchableOpacity>
                  </View>

                  <View style={{ flexDirection: 'column', marginLeft: 15 }}>
                    <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', fontWeight: '500', }}>
                      Send Time
                    </Text>
                    <TouchableOpacity onPress={() => {
                      setShowNotificationTimePicker(true);
                    }} style={{
                      //marginHorizontal: 4,
                      flexDirection: 'row',
                      alignItems: 'center',
                      //marginLeft: 5,
                      borderRadius: 5,
                      borderWidth: 1,
                      borderColor: '#E5E5E5',
                      //marginLeft:'6%', 
                      height: 40,
                      width: 110,
                      marginTop: 10,
                      justifyContent: 'space-between',
                    }}>
                      <Text style={{
                        marginLeft: 15,
                        color: '#B6B6B6',
                        fontSize: 14
                      }}
                      >
                        {moment(notificationTime).format('hh:mm a')}
                      </Text>

                      <EvilIcons name="clock" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} />

                    </TouchableOpacity>
                  </View>

                </View> */}

              {/* <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', marginLeft: 0, marginTop: 40, fontWeight: '500' }}>Promo Code</Text>

                <TextInput style={{
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  //marginLeft:'6%', 
                  height: 40,
                  width: 250,
                  marginTop: 10,
                  paddingLeft: 10,
                }}
                placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                  placeholder='Promo Code'
                  placeholderStyle={{ justifyContent: 'center' }}
                  onChangeText={text => {
                    setNotificationPromoCode(text)
                  }}
                  defaultValue={notificationPromoCode}
                /> */}

              {/* </View> */}
              {/* </View> */}
            </View>

            <View style={{ height: 60 }}></View>
          </ScrollView>
        </KeyboardAvoidingView>
      </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
  },
  list: {
    backgroundColor: Colors.whiteColor,
    // width: windowWidth * 0.87,
    // height: windowHeight * 0.825,
    marginTop: 0,
    marginHorizontal: 20,
    alignSelf: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    //width: windowWidth * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 16,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: 'center',
  },
  titleList: {
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 20,
    //marginTop: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

    alignItems: 'center',

    // shadowOpacity: 0,
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.22,
    // shadowRadius: 3.22,
    // elevation: 3,
  },
  textTitle: {
    fontSize: 16,
    fontFamily: 'NunitoSans-Bold',
  },
  textItem: {
    fontSize: 14,
    fontFamily: 'NunitoSans-Regular',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    // alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 20,
    paddingTop: 15,
    marginTop: 0,
    width: '100%',

    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 7,
  },
  addVoucher: {
    marginTop: 0,
    //justifyContent: 'center',
    alignItems: 'center',
    //alignContent: 'center',
    // width: windowWidth * 0.78,
    backgroundColor: Colors.whiteColor,
    // marginRight: 100,

    borderRadius: 4,

    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
  textInput: {
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 20,
    width: 300,
  },
  textInput1: {
    width: 250,
    height: 40,
    backgroundColor: 'white',
    borderRadius: 10,
    // marginLeft: '53%',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',

    // marginRight: windowWidth * Styles.sideBarWidth,

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  headerLeftStyle: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  textFieldInput: {
    height: 140,
    width: 650,
    //paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
  },
  modalView: {
    height: 500,
    width: 415,
    backgroundColor: Colors.whiteColor,
    padding: 12,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  modalView1: {
    backgroundColor: Colors.whiteColor,
    padding: 20,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  modalView2: {
    backgroundColor: Colors.whiteColor,
    padding: 20,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  modalContainer1: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'relative',
    alignSelf: 'flex-end',
    marginRight: -10,
    marginTop: -15,

    elevation: 1000,
    zIndex: 1000,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
  },
  modalSaveButton: {
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  textInput8: {
    fontFamily: 'NunitoSans-Regular',
    width: 60,
    height: 40,
    flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    paddingHorizontal: 5,
    borderColor: '#E5E5E5',
    borderWidth: 1,
  },
});

export default NewLoyaltyCampaignScreen;
