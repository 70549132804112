import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Switch,
  Modal,
  Platform,
  KeyboardAvoidingView,
  ActivityIndicator,
  Picker,
  useWindowDimensions,
} from "react-native";
import Colors from "../constant/Colors";
import Select from "react-select/async";
import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
import AntDesign from "react-native-vector-icons/AntDesign";
import Entypo from "react-native-vector-icons/Entypo";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import EvilIcons from "react-native-vector-icons/EvilIcons";
import { TextInput, FlatList } from "react-native-gesture-handler";
import API from "../constant/API";
import ApiClient from "../util/ApiClient";
import * as User from "../util/User";
import AsyncStorage from "@react-native-async-storage/async-storage";
import moment from "moment";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import Styles from "../constant/Styles";
import { OutletStore } from "../store/outletStore";
import { MerchantStore } from "../store/merchantStore";
import { UserStore } from "../store/userStore";
import { ReactComponent as Upload } from "../assets/svg/Upload.svg";
import { ReactComponent as Download } from "../assets/svg/Download.svg";
import Multiselect from "multiselect-react-dropdown";
import {
  listenToUserChangesMerchant,
  listenToMerchantIdChangesMerchant,
  listenToCurrOutletIdChangesWaiter,
  listenToAllOutletsChangesMerchant,
  listenToCommonChangesMerchant,
  listenToSelectedOutletItemChanges,
  convertArrayToCSV,
  listenToSelectedOutletTableIdChanges,
  requestNotificationsPermission,
} from "../util/common";
import Feather from "react-native-vector-icons/Feather";
import Ionicon from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { CommonStore } from "../store/commonStore";
import AsyncImage from "../components/asyncImage";
import AIcon from "react-native-vector-icons/AntDesign";
import {
  SEGMENT_SORT_FIELD_TYPE,
  SEGMENT_SORT_FIELD_TYPE_VALUE,
  REPORT_SORT_FIELD_TYPE_COMPARE,
  REPORT_SORT_COMPARE_OPERATOR,
} from "../constant/common";
import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";

////////////////////////////////////
let temp = [];

///////////////////////////////////

///////////////////////////////////

const ADD_SEGMENT_SCREEN = {
  ADD_SEGMENT: "ADD_SEGMENT_SCREEN.ADD_SEGMENT",
  SEGMENT_LIST: "ADD_SEGMENT_SCREEN.SEGMENT_LIST",
};

const SegmentScreen = (props) => {
  const { navigation } = props;

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  //////////////////////////////////// UseState Here
  // const [keyboardHeight] = useKeyboard();

  // testing
  const options = [{name: 'Option 1️⃣', id: 1},{name: 'Option 2️⃣', id: 2}]

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  const [list1, setList1] = useState(true);
  const [customerList, setCustomerList] = useState([]);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  const [search, setSearch] = useState("");

  const [visible, setVisible] = useState(false);

  const [controller, setController] = useState({});
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [addSegmentScreen, setAddSegmentScreen] = useState(
    ADD_SEGMENT_SCREEN.SEGMENT_LIST
  );
  const [userTagName, setUserTagName] = useState("");
  const [userTagList, setUserTagList] = useState([]);
  const [userTagDropdownList, setUserTagDropdownList] = useState([]);
  const [selectedUserTagList, setSelectedUserTagList] = useState([]);
  const [searchingUserTagText, setSearchingUserTagText] = useState("");

  const [segmentName, setSegmentName] = useState("");
  //////////////////////////////////////////////////////////////

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const merchantId = UserStore.useState((s) => s.merchantId);
  const crmUserTags = OutletStore.useState((s) => s.crmUserTags);
  const crmUserTagsDict = OutletStore.useState((s) => s.crmUserTagsDict);
  const selectedCustomerEdit = CommonStore.useState(
    (s) => s.selectedSegmentEdit
  );
  const selectedSegmentEdit = CommonStore.useState(
    (s) => s.selectedSegmentEdit
  );

  const crmUsers = OutletStore.useState((s) => s.crmUsers);
  const crmSegments = OutletStore.useState((s) => s.crmSegments);

  const [currSummarySort, setCurrSummarySort] = useState("");

  // const selectedCustomerDineInOrders = OutletStore.useState(s => s.selectedCustomerDineInOrders);
  const isLoading = CommonStore.useState((s) => s.isLoading);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );
  const [pushPagingToTop, setPushPagingToTop] = useState(false);

  useEffect(() => {
    console.log("================================");
    console.log("selectedSegmentEdit");
    console.log(selectedSegmentEdit);
    console.log(crmUserTags);

    if (selectedSegmentEdit) {
      // insert info

      setSegmentName(selectedSegmentEdit.name);
      setSelectedUserTagList(selectedCustomerEdit.crmUserTagIdList || []);
      setSearchingUserTagText("");
    } else {
      // designed to always mounted, thus need clear manually...

      setSegmentName("");
      setSelectedUserTagList([]);
      setSearchingUserTagText("");
    }
  }, [selectedSegmentEdit, addSegmentScreen]);

  //////////////////////////////////////////////////////////////

  //////////////////////////////////// UseEffect here

  useEffect(() => {
    if (crmUserTags.length > 0) {
      setUserTagDropdownList(
        crmUserTags
          .filter((item) => {
            var isExisted = false;

            for (var i = 0; i < userTagList.length; i++) {
              if (userTagList[i].uniqueId === item.uniqueId) {
                isExisted = true;
                break;
              }
            }

            return !isExisted;
          })
          .map((item) => {
            return { name: item.name, id: item.uniqueId };
          })
      );

      console.log("here here");
      console.log(userTagDropdownList);

      // if (selectedTax === '') {
      //   setSelectedTax(currOutletTaxes[0].uniqueId);

      //   for (var i = 0; i < currOutletTaxes.length; i++) {
      //     if (currOutletTaxes[i].uniqueId === currOutletTaxes[0].uniqueId) {
      //       setSelectedTaxName(currOutletTaxes[i].name);
      //       setSelectedTaxRate(currOutletTaxes[i].rate);
      //       break;
      //     }
      //   }
      // }
    }
  }, [crmUserTags, userTagList]);

  useEffect(() => {
    setCurrentPage(1);
    setPageCount(Math.ceil(crmSegments.length / perPage));
  }, [crmSegments.length]);

  ///////// SORT //////////
  const sortSegment = (dataList, segmentSortFieldType) => {
    var dataListTemp = [...dataList];
    console.log("dataList");
    console.log(dataList);

    console.log("segmentSortFieldType");
    console.log(segmentSortFieldType);

    const segmentSortFieldTypeValue =
      SEGMENT_SORT_FIELD_TYPE_VALUE[segmentSortFieldType];
    const segmentSortFieldTypeCompare =
      REPORT_SORT_FIELD_TYPE_COMPARE[segmentSortFieldType];

    if (segmentSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (segmentSortFieldType === SEGMENT_SORT_FIELD_TYPE.SEGMENT_GROUP_ASC) {
        dataListTemp.sort((a, b) =>
          (a[segmentSortFieldTypeValue]
            ? a[segmentSortFieldTypeValue]
            : ""
          ).localeCompare(
            b[segmentSortFieldTypeValue] ? b[segmentSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[segmentSortFieldTypeValue] ? a[segmentSortFieldTypeValue] : "") -
            (b[segmentSortFieldTypeValue] ? b[segmentSortFieldTypeValue] : "")
        );
      }
    } else if (
      segmentSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
    ) {
      if (segmentSortFieldType === SEGMENT_SORT_FIELD_TYPE.SEGMENT_GROUP_DESC) {
        dataListTemp.sort((a, b) =>
          (b[segmentSortFieldTypeValue]
            ? b[segmentSortFieldTypeValue]
            : ""
          ).localeCompare(
            a[segmentSortFieldTypeValue] ? a[segmentSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[segmentSortFieldTypeValue] ? b[segmentSortFieldTypeValue] : "") -
            (a[segmentSortFieldTypeValue] ? a[segmentSortFieldTypeValue] : "")
        );
      }
    }
    return dataListTemp;
  };

  //////////////////////////////////// Page standardize pattern here

  const setState = () => {};

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View
        style={{
          width: windowWidth * 0.17,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            bottom: switchMerchant ? "2%" : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? { right: windowWidth * 0.1 }
            : {},
          windowWidth <= 768 ? { right: windowWidth * 0.12 } : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Segment
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  ///////////////////////////////////////////////////////Function here

  const nextPage = () => {
    setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
  };

  const createCRMUserTag = async () => {
    if (
      //!userTagName
      !searchingUserTagText
    ) {
      Alert.alert(
        "Info",
        "Tag name cannot be empty.",
        [
          {
            text: "OK",
            onPress: () => {
              //   navigation.goBack();
            },
          },
        ],
        { cancelable: false }
      );
    } else {
      var isExisted = false;

      for (var i = 0; i < crmUserTags.length; i++) {
        if (crmUserTags[i].name === searchingUserTagText) {
          isExisted = true;
          break;
        }
      }

      if (isExisted) {
        Alert.alert(
          "Info",
          "Tag existed",
          [
            {
              text: "OK",
              onPress: () => {
                // navigation.goBack();
              },
            },
          ],
          { cancelable: false }
        );
      } else {
        var body = {
          merchantId: merchantId,
          //tagName: userTagName,
          tagName: searchingUserTagText,
        };

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ApiClient.POST(API.createCRMUserTag, body, false).then((result) => {
          if (result && result.status === "success") {
            Alert.alert(
              "Success",
              "Tag has been created",
              [
                {
                  text: "OK",
                  onPress: () => {
                    // navigation.goBack();
                  },
                },
              ],
              { cancelable: false }
            );
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        });
      }
    }
  };

  const addUserToCRMUserTag = async () => {
    if (selectedUserTagList.length === 0) {
      Alert.alert(
        "Info",
        "Please select at least one tag to be added.",
        [
          {
            text: "OK",
            onPress: () => {
              navigation.goBack();
            },
          },
        ],
        { cancelable: false }
      );
    } else {
      var body = {
        crmUserTagIdList: selectedUserTagList,
        merchantId: merchantId,

        tokenFcm: selectedCustomerEdit.tokenFcm || "",
        email: selectedCustomerEdit.email || "",
        phone: selectedCustomerEdit.number || "",
      };

      CommonStore.update((s) => {
        s.isLoading = true;
      });

      ApiClient.POST(API.addUserToCRMUserTag, body, false).then((result) => {
        if (result && result.status === "success") {
          Alert.alert(
            "Success",
            "Tag(s) has been added to user",
            [
              {
                text: "OK",
                onPress: () => {
                  // navigation.goBack();
                },
              },
            ],
            { cancelable: false }
          );
        }

        CommonStore.update((s) => {
          s.isLoading = false;
        });
      });
    }
  };

  const createCRMUserTagOrAddUserToCRMUserTag = async () => {
    var body = {
      crmUserTagIdList: selectedUserTagList,
      merchantId: merchantId,

      tokenFcm: selectedCustomerEdit.tokenFcm || "",
      email: selectedCustomerEdit.email || "",
      phone: selectedCustomerEdit.number || "",

      searchingUserTagText: searchingUserTagText,
    };

    CommonStore.update((s) => {
      s.isLoading = true;
    });

    ApiClient.POST(API.createCRMUserTagOrAddUserToCRMUserTag, body, false).then(
      (result) => {
        if (result && result.status === "success") {
          Alert.alert(
            "Success",
            "Tag(s) has been added to user",
            [
              {
                text: "OK",
                onPress: () => {
                  // navigation.goBack();
                },
              },
            ],
            { cancelable: false }
          );
        }

        CommonStore.update((s) => {
          s.isLoading = false;
        });
      }
    );
  };

  const removeUserFromCRMUserTag = async (userTag) => {
    var body = {
      crmUserTagId: userTag.uniqueId,
      merchantId: merchantId,

      tokenFcm: selectedCustomerEdit.tokenFcm || "",
      email: selectedCustomerEdit.email || "",
      phone: selectedCustomerEdit.number || "",
    };

    CommonStore.update((s) => {
      s.isLoading = true;
    });

    ApiClient.POST(API.removeUserFromCRMUserTag, body, false).then((result) => {
      if (result && result.status === "success") {
        Alert.alert(
          "Success",
          "Tag has been removed from user",
          [
            {
              text: "OK",
              onPress: () => {
                // navigation.goBack();
              },
            },
          ],
          { cancelable: false }
        );
      }

      CommonStore.update((s) => {
        s.isLoading = false;
      });
    });
  };

  const renderUserTag = ({ item }) => {
    return (
      <View
        style={{
          borderWidth: 1,
          borderColor: "darkgreen",
          borderRadius: 5,
          padding: 5,
          marginLeft: 10,
          alignSelf: "baseline",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text style={{ fontWeight: "500", color: "green" }}>{item.name}</Text>
        <TouchableOpacity
          style={{ marginLeft: 5 }}
          onPress={() => {
            Alert.alert(
              "Info",
              `Are you sure you want to delete ${item.name} tag?`,
              [
                { text: "Cancel", onPress: () => {}, style: "cancel" },
                {
                  text: "OK",
                  onPress: () => {
                    removeUserFromCRMUserTag(item);
                  },
                },
              ],
              { cancelable: false }
            );
          }}
        >
          <AntDesign name="closecircle" color={Colors.fieldtTxtColor} />
        </TouchableOpacity>
      </View>
    );
  };

  const renderItem = ({ item, index }) => {
    return (
      <TouchableOpacity
        onPress={() => {
          console.log(item);
          CommonStore.update((s) => {
            s.selectedSegmentEdit = item;
          });
          setSegmentName(item.name);
          console.log(userTagDropdownList);
          for(let i = 0; i < userTagDropdownList.length; i++){
            for(let j = 0; j < item.crmUserTagIdList.length; j++){
              if(userTagDropdownList[i].id === item.crmUserTagIdList[j]){
                console.log('checked');
                temp.push({
                  name: userTagDropdownList[i].name,
                  id: userTagDropdownList[i].id,
                })
              }
            }
          }
          console.log(temp, 'temp');
          setSelectedUserTagList(temp);
          console.log(selectedUserTagList, 'temp2');

          setAddSegmentScreen(ADD_SEGMENT_SCREEN.ADD_SEGMENT);
        }}
      >
        <View
          style={{
            //backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
            backgroundColor: "#FFFFFF",
            paddingVertical: 15,
            paddingHorizontal: 5,
            //paddingLeft: 1,
            borderColor: "#BDBDBD",
            borderTopWidth: (index + 1) % 2 === 0 ? 0 : 0.5,
            borderBottomWidth: (index + 1) % 2 === 0 ? 0 : 0.5,
            // width: '100%',
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "30%" }}>
              <Text
                style={{
                  fontWeight: "600",
                  color: Colors.primaryColor,
                  fontSize: 20,
                  fontFamily: "NunitoSans-Regular",
                }}
              >
                {item.name}
              </Text>
            </View>
            <View
              style={{ width: "30%", marginLeft: 10, flexDirection: "row" }}
            >
              {item.crmUserTagIdList &&
                item.crmUserTagIdList.map((userTagId, i) => {
                  var tagText = "N/A";
                  console.log(userTagId);

                  if (crmUserTagsDict[userTagId]) {
                    tagText = crmUserTagsDict[userTagId].name;
                  }

                  return (
                    <View style={{ alignItems: "baseline", marginRight: 5 }}>
                      <Text
                        style={{
                          borderColor: "green",
                          borderWidth: 1,
                          borderRadius: 5,
                          fontWeight: "500",
                          color: Colors.primaryColor,
                          padding: 3,
                          paddingLeft: 4,
                          alignItems: "baseline",
                          fontSize: 20,
                          fontFamily: "NunitoSans-Regular",
                        }}
                      >
                        {tagText}
                      </Text>
                    </View>
                  );
                })}
              {/* <View style={{ alignItems: 'baseline' }}>
                <Text style={{ borderColor: 'green', borderWidth: 1, borderRadius: 5, fontWeight: '500', color: Colors.primaryColor, padding: 3, alignItems: 'baseline' }}>
                  Coffee Lover
                </Text>
              </View> */}
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  //   Create New Segment
  const createCRMSegmentOrAddTagToCRMSegment = async () => {
    if (!segmentName) {
      Alert.alert(
        "Info",
        "Segment name cannot be empty",
        [
          {
            text: "OK",
            onPress: () => {
              // navigation.goBack();
            },
          },
        ],
        { cancelable: false }
      );
    } else {
      if (selectedSegmentEdit === null) {
        var isExisted = false;

        for (var i = 0; i < crmSegments.length; i++) {
          if (crmSegments[i].name === segmentName) {
            isExisted = true;
            break;
          }
        }

        if (isExisted) {
          Alert.alert(
            "Info",
            "Segment existed already",
            [
              {
                text: "OK",
                onPress: () => {
                  //navigation.goBack();
                },
              },
            ],
            { cancelable: false }
          );
        } else {
          var body = {
            merchantId: merchantId,
            segmentName: segmentName,

            crmUserTagIdList: selectedUserTagList,

            searchingUserTagText: searchingUserTagText,
          };

          CommonStore.update((s) => {
            s.isLoading = true;
          });

          ApiClient.POST(
            API.createCRMSegmentOrAddTagToCRMSegment,
            body,
            false
          ).then((result) => {
            if (result && result.status === "success") {
              let text = "Segment has been created";
              if (window.confirm(text) == true) {
                setAddSegmentScreen(ADD_SEGMENT_SCREEN.SEGMENT_LIST);
                temp = [];
              } else {
              }
              // Alert.alert(
              //   'Success',
              //   'Segment has been created',
              //   [
              //     {
              //       text: 'OK',
              //       onPress: () => {
              //         // navigation.goBack();
              //         setAddSegmentScreen(ADD_SEGMENT_SCREEN.SEGMENT_LIST);
              //       },
              //     },
              //   ],
              //   { cancelable: false },
              // );
            }

            CommonStore.update((s) => {
              s.isLoading = false;
            });
          });
        }
      } else {
        var body = {
          merchantId: merchantId,
          segmentName: segmentName,

          crmUserTagIdList: selectedUserTagList,

          searchingUserTagText: searchingUserTagText,

          segmentId: selectedSegmentEdit.uniqueId,
        };

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ApiClient.POST(
          API.updateCRMSegmentOrAddTagToCRMSegment,
          body,
          false
        ).then((result) => {
          if (result && result.status === "success") {
            let text = "Segment has been updated";
            if (window.confirm(text) == true) {
              setAddSegmentScreen(ADD_SEGMENT_SCREEN.SEGMENT_LIST);
              temp = [];
            } else {
            }
            // Alert.alert(
            //   'Success',
            //   'Segment has been updated',
            //   [
            //     {
            //       text: 'OK',
            //       onPress: () => {
            //         // navigation.goBack();
            //         setAddSegmentScreen(ADD_SEGMENT_SCREEN.SEGMENT_LIST);
            //       },
            //     },
            //   ],
            //   { cancelable: false },
            // );
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        });
      }
    }
  };

  /////Manage Filter

  ///////////////////////////////////////////////////////

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={6} expandCRM={true} />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ width: windowWidth * 0.9 }}
          contentContainerStyle={{
            paddingBottom: windowHeight * 0.1,
          }}
        >
          <Modal
            style={{ flex: 1 }}
            visible={visible}
            transparent={true}
            animationType="slide"
          >
            <KeyboardAvoidingView
              behavior="padding"
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                minHeight: Dimensions.get("window").height,
              }}
            >
              <View
                style={[
                  styles.ManageFilterBox,
                  { borderRadius: 12, padding: 30, paddingHorizontal: 50 },
                ]}
              >
                <View
                  style={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    marginTop: -15,
                    marginRight: -35,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setVisible(false);
                    }}
                  >
                    <AntDesign
                      name={"closecircle"}
                      size={25}
                      color={"#858C94"}
                    />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    //marginTop: 10
                  }}
                >
                  <Text style={{ fontSize: 26, fontFamily: "Nunitosans-Bold" }}>
                    Manage Filter
                  </Text>
                </View>
                <View
                  style={{
                    borderColor: "#E5E5E5",
                    borderWidth: 1,
                    marginHorizontal: -20,
                    marginBottom: 15,
                  }}
                />

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    height: 35,
                    marginVertical: 10,
                  }}
                >
                  <Picker
                    // controller={instance => controller = instance}
                    controller={(instance) => setController(instance)}
                    arrowColor={"#BDBDBD"}
                    arrowSize={23}
                    arrowStyle={{ paddingVertical: 0 }}
                    style={{
                      width: 180,
                      height: 35,
                      backgroundColor: "#F2F3F7",
                      borderRadius: 6,
                      paddingVertical: 0,
                    }}
                    itemStyle={{ justifyContent: "flex-start" }}
                    placeholderStyle={{ color: "#B6B6B6" }}
                    //change value below (Eason)
                    placeholder={"Product Category"}
                    labelStyle={{ fontSize: 12.5 }}
                    onChangeItem={(selectedSort) => {
                      // setState({ sort: selectedSort }),
                      //sortingOrders(selectedSort);
                    }}
                  >
                    {[
                      { label: "A", value: 1 },
                      { label: "B", value: 2 },
                    ].map((value, index) => {
                      return (
                        <Picker.Item label={value.label} value={value.value} />
                      );
                    })}
                  </Picker>
                  <Picker
                    // controller={instance => controller = instance}
                    controller={(instance) => setController(instance)}
                    arrowColor={"#BDBDBD"}
                    arrowSize={23}
                    arrowStyle={{ paddingVertical: 0 }}
                    style={{
                      width: 90,
                      backgroundColor: "#F2F3F7",
                      borderRadius: 6,
                      marginLeft: 20,
                      paddingVertical: 0,
                    }}
                    dropDownStyle={{
                      width: 90,
                      borderRadius: 6,
                      marginLeft: 20,
                      paddingVertical: 0,
                    }}
                    itemStyle={{ justifyContent: "flex-start" }}
                    placeholderStyle={{ color: "#B6B6B6" }}
                    //change value below (Eason)
                    placeholder={"Is"}
                    labelStyle={{ fontSize: 12.5 }}
                    onChangeItem={(selectedSort) => {
                      // setState({ sort: selectedSort }),
                      //sortingOrders(selectedSort);
                    }}
                    //onOpen={() => controller1.close()}
                  >
                    {[
                      { label: "A", value: 1 },
                      { label: "B", value: 2 },
                    ].map((value, index) => {
                      return (
                        <Picker.Item label={value.label} value={value.value} />
                      );
                    })}
                  </Picker>
                </View>
                <View
                  style={{
                    marginTop: 20,
                    height: 40,
                    zIndex: -1,
                  }}
                >
                  <TextInput
                    style={{
                      borderRadius: 5,
                      borderColor: "#E5E5E5",
                      borderWidth: 1,
                      height: 35,
                      width: 200,
                      backgroundColor: Colors.fieldtBgColor,
                      paddingLeft: 5,
                    }}
                    placeholder="Enter Condition"
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    placeholderStyle={{ paddingLeft: 5 }}
                    onChangeText={() => {}}
                  />
                </View>
                {/* <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: '#E0E0E0',
                backgroundColor: '#F2F3F7',
                width: 100,
                height: 40,
                borderRadius: 6,
                marginTop: 15,
              }}>
                <Text style={{
                  fontSize: 12.5,
                  Color: '#B6B6B6',
                }}>
                  Package A
                </Text>
                <TouchableOpacity
                  onPress={() => {

                  }}
                  style={{
                    marginLeft: 5
                  }}
                >
                  <AntDesign name={"close"} size={16} color={'#B6B6B6'} />
                </TouchableOpacity>
              </View> */}

                <View
                  style={{
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                    marginVertical: 20,
                    marginHorizontal: -20,
                    zIndex: -1,
                  }}
                />

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    zIndex: -1,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      width: 110,
                      height: 40,
                      borderColor: "#4E9F7D",
                      borderRadius: 8,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      setVisible(false);
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.primaryColor,
                        fontSize: 17,
                        color: "#4E9F7D",
                        fontFamily: "Nunitosans-Bold",
                      }}
                    >
                      Cancel
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      width: 110,
                      height: 40,
                      borderColor: "#4E9F7D",
                      borderRadius: 8,
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#4E9F7D",
                      marginLeft: 10,
                    }}
                    onPress={() => {}}
                  >
                    <Text
                      style={{
                        color: Colors.primaryColor,
                        fontSize: 17,
                        color: "#FFFFFF",
                        fontFamily: "Nunitosans-Bold",
                      }}
                    >
                      Apply
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>

          <KeyboardAvoidingView
            style={
              {
                //top: Platform.OS === 'ios' && keyboardHeight > 0 ? -keyboardHeight * 1 : 0,
              }
            }
          >
            {addSegmentScreen === ADD_SEGMENT_SCREEN.SEGMENT_LIST ? (
              <View>
                {/* <View style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: Platform.OS=='ios'?windowWidth * 0.87:windowWidth * 0.9,
              margin: 10, padding: 10
            }}>
              <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 10,
                width: '100%',
              }}>
                <View style={{ justifyContent: 'center' }}>
                  <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 30 }}>
                    Segments List
                  </Text>
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                <TouchableOpacity
                   style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: '#4E9F7D',
                    borderRadius: 5,
                    //width: 160,
                    paddingHorizontal: 10,
                    height: 40,
                    alignItems: 'center',
                    shadowOffset: {
                        width: 0,
                        height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                    marginRight: 10,
                }}
                  onPress={() => {
                    CommonStore.update(s => {
                      s.selectedSegmentEdit = null;
                    });

                    setAddSegmentScreen(ADD_SEGMENT_SCREEN.ADD_SEGMENT)
                  }}>
                      <AntDesign
                        name="pluscircle"
                        size={20}
                        color='#FFFFFF'
                      />
                    <Text style={{
                        color: Colors.whiteColor,
                        marginLeft: 5,
                        fontSize: 16,
                        fontFamily: 'NunitoSans-Bold',
                    }}>
                      ADD SEGMENT
                    </Text>
                </TouchableOpacity>
                <View style={{
                  width: 250,
                  height: 40,
                  backgroundColor: 'white',
                  borderRadius: 5,
                  flexDirection: 'row',
                  alignItems: 'center',
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                  //marginTop: 10,
                  marginRight:Platform.OS=='ios'?0:'20%',
                }}>
                  <Icon name="search" size={18} color={Colors.primaryColor} style={{ marginLeft: 10 }} />
                  <TextInput
                    underlineColorAndroid={Colors.whiteColor}
                    style={{
                      width: 220,
                      fontSize: 15,
                      fontFamily: 'NunitoSans-Regular',
                      paddingLeft: 5,
                    }}
                    clearButtonMode="while-editing"
                    placeholder=' Search'
                    onChangeText={(text) => {
                      setSearch(text);
                    }}
                    defaultValue={search}
                  />
                </View>
                </View>

              </View>
            </View> */}
                {/* <View style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: Platform.OS == 'ios' ? windowWidth * 0.87 : windowWidth * 0.9,
              margin: 10, padding: 10
            }}> */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginTop: 20,
                    marginBottom: 20,
                    width: windowWidth * 0.8575,
                    //marginTop: 30,
                    alignSelf: "center",
                    alignItems: "center",
                  }}
                >
                  <View style={{ justifyContent: "center" }}>
                    <Text
                      style={{
                        fontSize: 35,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      Segments List
                    </Text>
                  </View>

                  <View
                    style={{ flexDirection: "row", justifyContent: "flex-end" }}
                  >
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        //width: 160,
                        paddingHorizontal: 10,
                        marginRight: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginRight: 15,
                      }}
                      onPress={() => {
                        CommonStore.update((s) => {
                          s.selectedSegmentEdit = null;
                        });

                        setAddSegmentScreen(ADD_SEGMENT_SCREEN.ADD_SEGMENT);
                        temp = [];
                      }}
                    >
                      <AntDesign
                        name="pluscircle"
                        size={20}
                        style={{ color: Colors.whiteColor }}
                      />
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          marginLeft: 5,
                          fontSize: 16,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        {/* ADD SEGMENT */}
                        SEGMENT
                      </Text>
                    </TouchableOpacity>

                    <View
                      style={{
                        width: 250,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: 5,
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 3,
                        borderWidth: 1,
                        borderColor: "#E5E5E5",
                      }}
                    >
                      <Icon
                        name="search"
                        size={18}
                        color={Colors.primaryColor}
                        style={{ marginLeft: 15 }}
                      />
                      <View style={{ flex: 4 }}>
                        <TextInput
                          underlineColorAndroid={Colors.whiteColor}
                          style={{
                            width: switchMerchant ? 160 : 220,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Regular",
                            paddingLeft: 5,
                            height: 45,
                          }}
                          placeholderTextColor={Platform.select({
                            ios: "#a9a9a9",
                          })}
                          clearButtonMode="while-editing"
                          placeholder=" Search"
                          onChangeText={(text) => {
                            setSearch(text);
                          }}
                          defaultValue={search}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                {/* </View> */}
                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    width: windowWidth * 0.8575,
                    minHeight: windowHeight * 0.01,
                    marginTop: 30,
                    marginHorizontal: windowWidth * 0.02,
                    marginBottom: 30,
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  }}
                >
                  <View
                    style={{
                      width: "40%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* Filter bar  */}
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        width: "38%",
                      }}
                      onPress={() => {
                        //setVisible(true);
                      }}
                    >
                      {/* <View style={{ justifyContent: 'center', width: '100%', flexDirection: 'row', borderWidth: 1, borderColor: '#4E9F7D', borderRadius: 3, height: 35, alignItems: 'center' }}>
                      <Feather name='filter' size={18} color='#4E9F7D' />
                      <Text style={{
                        color: '#4E9F7D',
                        fontSize: 14,
                        fontFamily: 'Nunitosans-bold',
                        marginLeft: 7
                      }}>
                        Manage Filter
                      </Text>
                    </View> */}
                    </TouchableOpacity>
                    {/* Type 1 Search Bar */}
                    {/* <View style={{ flexDirection: 'row', justifyContent: 'center', width: '53%', height: 40, alignItems: 'center', borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, alignSelf: 'center' }}>
                    <View style={{ flex: 3 }}>
                      <TextInput
                        placeholderTextColor='#737373'
                        style={{ marginLeft: 10, color: '#737373' }}
                        onChangeText={(text) => {
                          setSearch(text);
                        }}
                        defaultValue={search}
                      />
                    </View>
                    <View style={{ flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.primaryColor, borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5 }}>
                      <Icon name='search' size={20} color={Colors.whiteColor} />
                    </View>
                  </View> */}

                    {/* Type 2 Search Bar */}
                  </View>

                  {/****************List View Here****************/}

                  <View style={{ width: "100%", marginTop: 0 }}>
                    <View
                      style={{
                        backgroundColor: Colors.whiteColor,
                        padding: 5,
                        paddingTop: 0,
                        height: "99%",
                        paddingHorizontal: 20,
                        borderRadius: 5,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          borderBottomWidth: 1,
                          borderColor: "#E5E5E5",
                          height: 90,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            width: "30%",
                            borderRightWidth: 0,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            marginLeft: 0,
                          }}
                        >
                          <View style={{ flexDirection: "column" }}>
                            <TouchableOpacity
                              onPress={() => {
                                if (
                                  currSummarySort ===
                                  SEGMENT_SORT_FIELD_TYPE.SEGMENT_GROUP_ASC
                                ) {
                                  setCurrSummarySort(
                                    SEGMENT_SORT_FIELD_TYPE.SEGMENT_GROUP_DESC
                                  );
                                } else {
                                  setCurrSummarySort(
                                    SEGMENT_SORT_FIELD_TYPE.SEGMENT_GROUP_ASC
                                  );
                                }
                              }}
                            >
                              <Text
                                numberOfLines={1}
                                style={{
                                  paddingLeft: 5,
                                  fontSize: 20,
                                  fontFamily: "NunitoSans-Bold",
                                  color: "black",
                                  fontWeight: "600",
                                }}
                              >
                                Segment Group
                              </Text>
                              {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                            </TouchableOpacity>
                          </View>
                          <View style={{ marginLeft: "3%" }}>
                            <Entypo
                              name="triangle-up"
                              size={20}
                              color={
                                currSummarySort ===
                                SEGMENT_SORT_FIELD_TYPE.SEGMENT_GROUP_ASC
                                  ? Colors.secondaryColor
                                  : Colors.descriptionColor
                              }
                            ></Entypo>
                            <Entypo
                              name="triangle-down"
                              size={20}
                              color={
                                currSummarySort ===
                                SEGMENT_SORT_FIELD_TYPE.SEGMENT_GROUP_DESC
                                  ? Colors.secondaryColor
                                  : Colors.descriptionColor
                              }
                            ></Entypo>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "40%",
                            borderRightWidth: 0,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            marginLeft: 10,
                          }}
                        >
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              numberOfLines={1}
                              style={{
                                fontSize: 20,
                                fontFamily: "NunitoSans-Bold",
                                color: "black",
                                fontWeight: "600",
                                width: 80,
                              }}
                            >
                              Tags
                            </Text>
                            {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                          </View>
                        </View>
                      </View>
                      {list1 ? (
                        // <View style={{ borderTopWidth: 1 }}>
                        <FlatList
                          ////THIS IS FOR SEARCH////
                          //   data={crmUsers
                          //     .filter(item => {
                          //       if (search !== '') {
                          //         const searchLowerCase = search.toLowerCase();

                          //         if (item.email.toLowerCase().includes(searchLowerCase) ||
                          //           item.name.toLowerCase().includes(searchLowerCase) ||
                          //           item.uniqueName.toLowerCase().includes(searchLowerCase) ||
                          //           item.number.toLowerCase().includes(searchLowerCase)) {
                          //           return true;
                          //         }

                          //         return false;
                          //       }
                          //       else {
                          //         return true;
                          //       }
                          //     }).slice((currentPage - 1) * perPage, currentPage * perPage)
                          //   }
                          showsVerticalScrollIndicator={false}
                          data={sortSegment(
                            crmSegments,
                            currSummarySort
                          ).filter((item, i) => {
                            const searchLowerCase = search.toLowerCase();

                            if (
                              item.name.toLowerCase().includes(searchLowerCase)
                            ) {
                              return true;
                            }

                            if (item.crmUserTagIdList) {
                              for (
                                var i = 0;
                                i < item.crmUserTagIdList.length;
                                i++
                              ) {
                                var tagText = "";

                                if (crmUserTagsDict[item.crmUserTagIdList[i]]) {
                                  tagText =
                                    crmUserTagsDict[item.crmUserTagIdList[i]]
                                      .name;
                                }

                                if (
                                  tagText
                                    .toLowerCase()
                                    .includes(searchLowerCase)
                                ) {
                                  return true;
                                }
                              }
                            } else {
                              return false;
                            }
                          })}
                          renderItem={renderItem}
                          keyExtractor={(item, index) => String(index)}
                          style={{ marginTop: 0 }}
                          // initialNumToRender={4}
                        />
                      ) : // </View>

                      null}
                    </View>
                  </View>
                  {/* <View style={{
              flexDirection: 'row',
              marginTop: 10,
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-end',
              top: Platform.OS == 'ios' ? pushPagingToTop && keyboardHeight > 0 ? -keyboardHeight * 0.94 : 0 : 0,
              backgroundColor: pushPagingToTop && keyboardHeight > 0 ? Colors.highlightColor : null,
              borderWidth: pushPagingToTop && keyboardHeight > 0 ? 1 : 0,
              borderColor: pushPagingToTop && keyboardHeight > 0 ? '#E5E5E5' : null,
              borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
              paddingHorizontal: pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
              shadowOffset: {
                width: 0,
                height: 1,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 1,
            }}>
              <Text style={{ marginRight: '1%' }}>Page</Text>
              <View style={{
                width: 65,
                height: 35,
                backgroundColor: Colors.whiteColor,
                borderRadius: 10,
                justifyContent: 'center',
                paddingHorizontal: 22,
                borderWidth: 1,
                borderColor: '#E5E5E5',
              }}>
                {console.log('currentPage')}
                {console.log(currentPage)}

                <TextInput
                  onChangeText={(text) => {
                    var currentPageTemp = text.length > 0 ? parseInt(text) : 1;

                    setCurrentPage(currentPageTemp > pageCount ? pageCount : (currentPageTemp < 1 ? 1 : currentPageTemp));

                  }}
                  placeholder={currentPage.toString()}
                  placeholderTextColor={'black'}
                  style={{
                    color: Colors.fontDark,
                    fontFamily: 'NunitoSans-SemiBold',
                    fontSize: 14,
                    marginTop: Platform.OS === 'ios' ? 0 : -15,
                    marginBottom: Platform.OS === 'ios' ? 0 : -15,
                    textAlign: 'center',
                    width: '100%',
                  }}
                  value={currentPage.toString()}
                  defaultValue={currentPage.toString()}
                  keyboardType={'numeric'}
                  onFocus={() => { setPushPagingToTop(true) }}
                />
              </View>
              <Text style={{ marginLeft: '1%', marginRight: '1%' }}>of {pageCount}</Text>
              <TouchableOpacity style={{ width: 45, height: 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }}
                onPress={() => { prevPage() }}>
                <MaterialIcons name='keyboard-arrow-left' size={25} style={{ color: Colors.whiteColor }} />
              </TouchableOpacity>
              <TouchableOpacity style={{ width: 45, height: 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }} onPress={() => { nextPage() }}>
                <MaterialIcons name='keyboard-arrow-right' size={25} style={{ color: Colors.whiteColor }} />
              </TouchableOpacity>
            </View> */}
                </View>
                <View
                  style={{
                    position: "relative",
                    //left: 160,
                    flexDirection: "row",
                    marginTop: 10,
                    width: windowWidth * 0.8575,
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "flex-end",
                    // top:
                    // Platform.OS == 'ios'
                    //   ? pushPagingToTop && keyboardHeight > 0
                    //     ? -keyboardHeight * 1
                    //     : 0
                    //   : 0,
                    // backgroundColor: pushPagingToTop && keyboardHeight > 0 ? Colors.highlightColor : null,
                    // borderWidth: pushPagingToTop && keyboardHeight > 0 ? 1 : 0,
                    // borderColor: pushPagingToTop && keyboardHeight > 0 ? '#E5E5E5' : null,
                    // borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                    // paddingHorizontal:
                    //   pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                    // shadowOffset: {
                    //   width: 0,
                    //   height: 1,
                    // },
                    // shadowOpacity: 0.22,
                    // shadowRadius: 3.22,
                    // elevation: 1,
                    marginBottom: switchMerchant ? windowHeight * 0.1 : 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                    }}
                  >
                    Page
                  </Text>
                  <View
                    style={{
                      width: windowWidth * 0.05,
                      height: windowHeight * 0.04,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: 22,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                    }}
                  >
                    {console.log("currentPage")}
                    {console.log(currentPage)}

                    <TextInput
                      onChangeText={(text) => {
                        var currentPageTemp =
                          text.length > 0 ? parseInt(text) : 1;

                        setCurrentPage(
                          currentPageTemp > pageCount
                            ? pageCount
                            : currentPageTemp < 1
                            ? 1
                            : currentPageTemp
                        );
                      }}
                      placeholder={currentPage.toString()}
                      placeholderStyle={{
                        fontSize: 20,
                        fontFamily: "NunitoSans-Regular",
                      }}
                      placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                      style={{
                        color: "black",
                        fontSize: 20,
                        fontFamily: "NunitoSans-Regular",
                        marginTop: Platform.OS === "ios" ? 0 : -15,
                        marginBottom: Platform.OS === "ios" ? 0 : -15,
                        // textAlign: 'center',
                        width: windowWidth * 0.02,
                        // borderWidth: 1,
                        marginLeft: windowWidth * -0.004,
                      }}
                      value={currentPage.toString()}
                      defaultValue={currentPage.toString()}
                      keyboardType={"numeric"}
                      onFocus={() => {
                        setPushPagingToTop(true);
                      }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: 20,
                      fontFamily: "NunitoSans-Bold",
                      marginLeft: "1%",
                      marginRight: "1%",
                    }}
                  >
                    of {pageCount}
                  </Text>
                  <TouchableOpacity
                    style={{
                      width: windowWidth * 0.04,
                      height: windowHeight * 0.03,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      prevPage();
                    }}
                  >
                    {/* <MaterialIcons
                      name="keyboard-arrow-left"
                      size={30}
                      style={{ color: Colors.whiteColor }}
                    /> */}
                    <ArrowLeft />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      width: windowWidth * 0.04,
                      height: windowHeight * 0.03,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      nextPage();
                    }}
                  >
                    {/* <MaterialIcons
                      name="keyboard-arrow-right"
                      size={30}
                      style={{ color: Colors.whiteColor }}
                    /> */}
                    <ArrowRight />
                  </TouchableOpacity>
                </View>
              </View>
            ) : null}

            {/* **********************************New Segment Page*********************************** */}
            {addSegmentScreen === ADD_SEGMENT_SCREEN.ADD_SEGMENT ? (
              <View style={{ marginTop: 15 }}>
                <TouchableOpacity
                  style={{
                    // marginLeft: 61,
                    flexDirection: "row",
                    // marginHorizontal: 30,
                    alignItems: "center",
                    //width: windowWidth * 0.8575,
                  }}
                  onPress={() => {
                    setAddSegmentScreen(ADD_SEGMENT_SCREEN.SEGMENT_LIST);
                    temp = [];
                  }}
                >
                  <Icon
                    name="chevron-left"
                    size={40}
                    color={Colors.primaryColor}
                  />
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 20,
                      color: Colors.primaryColor,
                      bottom: switchMerchant
                        ? 0
                        : Platform.OS === "android"
                        ? 1
                        : 0,
                    }}
                  >
                    Back
                  </Text>
                </TouchableOpacity>
                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    paddingTop: 5,
                    paddingHorizontal: 10,
                    width: windowWidth * 0.8575,
                    height: windowHeight * 0.65,
                    marginTop: 30,
                    marginHorizontal: windowWidth * 0.03,
                    marginBottom: 30,
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  }}
                >
                  <View
                    style={{
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "Nunitosans-bold",
                        fontSize: 30,
                        position: "relative",
                        left: 10,
                      }}
                    >
                      {selectedSegmentEdit ? "Edit Segment" : "Add Segment"}
                    </Text>

                    <TouchableOpacity
                      style={{
                        position: "relative",
                        // right: 10,
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        paddingHorizontal: 20,
                        paddingVertical: 5,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      disabled={isLoading}
                      onPress={() => {
                        createCRMSegmentOrAddTagToCRMSegment();
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {isLoading ? (
                          <ActivityIndicator
                            color={Colors.whiteColor}
                            size={"small"}
                          />
                        ) : (
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: 20,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {selectedSegmentEdit ? "UPDATE" : "SAVE"}
                          </Text>
                        )}
                      </View>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      borderColor: "#E5E5E5",
                      borderWidth: 1,
                      marginVertical: 15,
                    }}
                  />

                  <View
                    style={{
                      alignItems: "center",
                      marginTop: switchMerchant ? 10 : 15,
                    }}
                  >
                    <View
                      style={{
                        width: switchMerchant
                          ? windowWidth * 0.6
                          : windowWidth * 0.49,
                        height: windowHeight * 0.4,
                        borderWidth: 1,
                        borderColor: "#E5E5E5",
                      }}
                    >
                      <View>
                        <View>
                          <View
                            style={[
                              styles.modalView,
                              {
                                //height: windowWidth * 0.3,
                                // top: Platform.OS === 'ios' && keyboardHeight > 0 ? -keyboardHeight * 0.3 : 0,
                                backgroundColor: "transparent",
                                padding: 20,
                              },
                            ]}
                          >
                            {/* <View style={{ marginBottom: 8 }}>
                              <Text style={{ fontWeight: '600', fontSize: switchMerchant ? 20 : 24 }}>
                                Segment
                              </Text>
                            </View> */}

                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginBottom: 15,
                              }}
                            >
                              <Text
                                style={{
                                  fontWeight: "500",
                                  marginRight: 10,
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: 20,
                                }}
                              >
                                Segment Name:
                              </Text>
                              <TextInput
                                placeholder={"Segment Name"}
                                style={{
                                  backgroundColor: Colors.fieldtBgColor,
                                  width: windowWidth * 0.15,
                                  height: windowHeight * 0.05,
                                  borderRadius: 5,
                                  padding: 5,
                                  marginVertical: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  paddingLeft: 10,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 20,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                onChangeText={(text) => {
                                  setSegmentName(text);
                                }}
                                defaultValue={segmentName}
                              />
                            </View>

                            <View
                              style={{
                                //backgroundColor: Colors.fieldtBgColor,
                                //width: 180,
                                height: windowHeight * 0.2,
                                borderRadius: 5,
                                paddingVertical: 3,
                                paddingLeft: 0,
                                marginVertical: 5,
                                //borderWidth: 1,
                                borderColor: "#E5E5E5",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                //width: '100%'
                                paddingTop: 10,
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                  zIndex: 1,
                                  marginLeft: 0,
                                  width: windowWidth * 0.4,
                                }}
                              >
                                <AntDesign
                                  name="tags"
                                  size={20}
                                  style={{ color: Colors.primaryColor }}
                                />
                                <Multiselect
                                  style={{maxWidth: windowWidth * 0.3}}
                                  options={userTagDropdownList}
                                  selectedValues={temp}
                                  onSelect={(items) => {
                                    let temp1 = [];
                                    for(let i = 0; i < items.length; i++){
                                      temp1.push(items[i].id)
                                    }
                                    setSelectedUserTagList(temp1);
                                    console.log(items, 'lalala');
                                    console.log(temp1, 'lalala');
                                  }}
                                  onRemove={(items) => {
                                    setSelectedUserTagList(items);
                                  }}
                                  displayValue="name"
                                />

                                {/* <Select
                                  options={userTagDropdownList}
                                  containerStyle={{
                                    height: switchMerchant ? 35 : 40,
                                    zIndex: 2,
                                    marginLeft: 5,
                                  }}
                                  arrowColor={"black"}
                                  arrowSize={20}
                                  arrowStyle={{ fontWeight: "bold" }}
                                  labelStyle={{
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: 20,
                                  }}
                                  // style={{
                                  //   width: windowWidth * 0.17,
                                  //   height: windowHeight * 0.15,
                                  //   paddingVertical: 0,
                                  //   backgroundColor: 'white',
                                  //   borderRadius: 5,
                                  //   borderWidth: 1,
                                  // }}
                                  // placeholderStyle={{
                                  //   color: Colors.fieldtTxtColor,
                                  //   fontFamily: 'NunitoSans-Regular',
                                  //   fontSize: 20,
                                  // }}
                                  // itemStyle={{
                                  //   justifyContent: 'flex-start',
                                  //   marginLeft: 5,
                                  //   zIndex: 2,
                                  //   width: 180,
                                  // }}
                                  placeholder={"Tag(s)"}
                                  // customTickIcon={(press) => (
                                  //   <Ionicon
                                  //     name={'md-checkbox'}
                                  //     color={
                                  //       press
                                  //         ? Colors.fieldtBgColor
                                  //         : Colors.primaryColor
                                  //     }
                                  //     size={30}
                                  //   />
                                  // )}
                                  onChange={(items) => {
                                    setSelectedUserTagList(items);
                                  }}
                                  isMulti
                                  textInput={{ paddingLeft: 5 }}
                                  defaultValue={selectedUserTagList}

                                  // style={{
                                  //   height: 45,
                                  //   width: 205,
                                  //   marginLeft: 5,
                                  //   paddingVertical: 0,
                                  //   borderColor: '#E5E5E5',
                                  // }}
                                  //   dropDownStyle={{ marginLeft: 5, marginTop: 0, borderWidth: 1.5, borderColor: '#E5E5E5', width: 205, }}
                                  //   arrowSize={20}
                                  //   arrowStyle={{ paddingVertical: -10 }}
                                  //   items={userTagDropdownList}
                                  //   onChangeItem={(items) => {
                                  //     setSelectedUserTagList(items);
                                  //   }}
                                  //   placeholder={'Select Tag(s)'}
                                  //   defaultValue={selectedUserTagList}
                                  //   multiple={true}
                                  //   searchable={true}
                                  //   dropDownMaxHeight={210}
                                  //   // onSearch={text => {
                                  //   //   setSearchingUserTagText(text);
                                  //   // }}
                                  //   customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                /> */}
                              </View>

                              {/* <View style={{
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            marginLeft: 5
                                            //zIndex: -1,
                                        }}>
                                            <TouchableOpacity
                                            style={{
                                                backgroundColor: Colors.primaryColor, width: 65, height: 34, justifyContent: 'center', alignItems: 'center', borderRadius: 5,

                                                flexDirection: 'row',
                                            }}
                                            disabled={isLoading}
                                            onPress={createCRMUserTag}
                                            //onPress={createCRMUserTagOrAddUserToCRMUserTag}
                                            >
                                            <Text style={{ fontSize: 17, fontWeight: '600', color: 'white' }}>
                                                {isLoading ? '' : 'Add'}
                                            </Text>

                                            {isLoading ?
                                                <ActivityIndicator color={Colors.whiteColor} size={'small'} />
                                                : <></>
                                            }
                                            </TouchableOpacity>
                                        </View> */}
                            </View>
                          </View>
                          <View
                            style={{
                              marginVertical: 12,
                              zIndex: -1,
                            }}
                          >
                            {/* <FlatList
                            data={userTagList}
                            renderItem={renderUserTag}
                            keyExtractor={(item, index) => String(index)}
                            style={{
                              paddingVertical: 5,
                              paddingHorizontal: 5,
                            }}
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                          /> */}
                            {/* <Text style={{ fontSize: 14, fontFamily: 'Nunitosans-Regular', color: "black", fontFamily: 'Nunitosans-Regular' }}>
                                {
                                    userTagList.length > 0
                                    ?
                                    userTagList.map(tag => tag.name).join(', ')
                                    :
                                    'No tags yet.'
                                }
                                </Text> */}
                            {/* <View style={{ flexDirection: 'row'}}>
                            <Text>
                              {segmentName}
                            </Text>

                            <Text>
                              {selectedUserTagList}
                            </Text>
                          </View> */}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={
                    switchMerchant
                      ? {
                          height: 60,
                        }
                      : {}
                  }
                ></View>
              </View>
            ) : null}
          </KeyboardAvoidingView>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },
  list: {
    backgroundColor: Colors.whiteColor,
    //marginTop: 30,
    marginHorizontal: 30,
    //alignSelf: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  list1: {
    backgroundColor: Colors.whiteColor,
    //marginTop: 30,
    marginHorizontal: 30,
    marginBottom: 60,
    //alignSelf: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    //width: windowWidth * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 16,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  titleList: {
    backgroundColor: "#ffffff",
    flexDirection: "row",
    paddingVertical: 20,
    paddingHorizontal: 20,
    //marginTop: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

    alignItems: "center",

    // shadowOpacity: 0,
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.22,
    // shadowRadius: 3.22,
    // elevation: 3,
  },
  textTitle: {
    fontSize: 16,
    fontFamily: "NunitoSans-Bold",
  },
  textItem: {
    fontSize: 14,
    fontFamily: "NunitoSans-Regular",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    // alignContent: 'center',
    alignItems: "center",
    backgroundColor: "white",
    padding: 20,
    paddingTop: 15,
    marginTop: 0,
    width: "100%",

    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 7,
  },
  addVoucher: {
    marginTop: 0,
    //justifyContent: 'center',
    alignItems: "center",
    //alignContent: 'center',
    backgroundColor: Colors.whiteColor,
    // marginRight: 100,

    borderRadius: 4,

    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
  textInput: {
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 20,
    width: 300,
  },
  textInput1: {
    width: 250,
    height: 40,
    backgroundColor: "white",
    borderRadius: 10,
    // marginLeft: '53%',
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  headerLeftStyle: {
    justifyContent: "center",
    alignItems: "center",
  },
  addNewView: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 10,
    alignSelf: "flex-end",
    width: "60%",
  },
  addButtonView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    backgroundColor: "#4E9F7D",
    borderRadius: 5,
    width: 160,
    height: 40,
    alignItems: "center",
    marginRight: Platform.OS == "ios" ? 15 : 0,
  },
  editButtonView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    backgroundColor: "#4E9F7D",
    borderRadius: 5,
    width: 74,
    height: 35,
    alignItems: "center",
  },
  ManageFilterBox: {
    //width: windowWidth * 0.4,
    //height: windowHeight * 0.7,
    //borderRadius: 10,
    backgroundColor: Colors.whiteColor,
    //justifyContent: 'space-between'
  },
  ManageFilterBox1: {
    //borderRadius: 10,
    backgroundColor: Colors.whiteColor,
    justifyContent: "center",
    borderColor: "#E5E5E5",
    borderWidth: 1,
    alignItems: "center",
    alignSelf: "center",
    //padding: 10,
    margin: 15,
  },
});

export default SegmentScreen;
