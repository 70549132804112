import React, { Component, useReducer, useState, useEffect } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  FlatList,
  TextInput,
  Modal,
  PermissionsAndroid,
  Platform,
  ActivityIndicator,
  useWindowDimensions,
} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import Colors from '../constant/Colors';
import Close from 'react-native-vector-icons/AntDesign';
import SideBar from './SideBar';
import Icon from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';
//import ImagePicker from 'react-native-image-picker';
import API from '../constant/API';
//import { isEnabled } from 'react-native/Libraries/Performance/Systrace';
//import DropDownPicker from 'react-native-dropdown-picker';
import * as User from '../util/User';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import ListItem, { Separator } from './ListItem';
import Styles from '../constant/Styles';
import ApiClient from '../util/ApiClient';
//import VirtualizedView from './VirtualizedView';
//import Switch from 'react-native-switch-pro'
//import RNFetchBlob from 'rn-fetch-blob'
import { CSVLink } from "react-csv";
//import DocumentPicker from 'react-native-document-picker';
//import { isTablet } from 'react-native-device-detection';
import { OutletStore } from '../store/outletStore';
import { MerchantStore } from '../store/merchantStore';
import { UserStore } from '../store/userStore';
import {
  autofitColumns,
  convertArrayToCSV,
  generateEmailReport,
  getImageFromFirebaseStorage,
  getPathForFirebaseStorageFromBlob,
  uploadFileToFirebaseStorage
} from '../util/common';
import moment from 'moment';
import { CommonStore } from '../store/commonStore';
import AsyncImage from '../components/asyncImage';
import AntDesign from 'react-native-vector-icons/AntDesign';
import XLSX from 'xlsx';
//import { zip, unzip, unzipAssets, subscribe } from 'react-native-zip-archive'
//const RNFS = require('react-native-fs');
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { EMAIL_REPORT_TYPE } from '../constant/common';
// import { useKeyboard } from '../hooks';

import { useLinkTo, useRoute } from "@react-navigation/native";
import { DataStore } from "../store/dataStore";
import { prefix } from "../constant/env";

const ProductScreen = (props) => {
  const {
    navigation,
  } = props;

  const linkTo = useLinkTo();

  const {
    height: windowHeight,
    width: windowWidth,
  } = useWindowDimensions();

  //////////////////////////////////////////////////////////////

  const currOutletId = MerchantStore.useState(s => s.currOutletId);
  const allOutlets = MerchantStore.useState(s => s.allOutlets);

  const currOutletTaxes = CommonStore.useState(s => s.currOutletTaxes);

  const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);

  //////////////////////////////////////////////////////////////

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View style={[{
        justifyContent: 'center',
        alignItems: 'center',
        // bottom: -2,
        bottom: 0,
      }, Dimensions.get('window').width <= 768 ? { right: Dimensions.get('window').width * 0.12 } : {}]}>
        <Text
          style={{
            fontSize: 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Product
        </Text>
      </View>
    ),
    headerRight: () => (
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        {outletSelectDropdownView()}
        <View style={{
          backgroundColor: 'white',
          width: 0.5,
          height: Dimensions.get('window').height * 0.025,
          opacity: 0.8,
          marginHorizontal: 15,
          bottom: -1,
        }}>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('Setting')}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View style={{
            marginRight: 30,
            width: Dimensions.get('window').height * 0.05,
            height: Dimensions.get('window').height * 0.05,
            borderRadius: Dimensions.get('window').height * 0.05 * 0.5,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}>
            <Image style={{
              width: Dimensions.get('window').height * 0.035,
              height: Dimensions.get('window').height * 0.035,
              alignSelf: 'center',
            }} source={require('../assets/image/profile-pic.jpg')} />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  //const [keyboardHeight] = useKeyboard();
  const [productList, setProductList] = useState([]);
  const [menuList, setMenuList] = useState(0);
  const [size, setSize] = useState('');
  const [addOn, setAddOn] = useState([]);
  const [addProduct, setAddProduct] = useState(false);
  const [addProductItem, setAddProductItem] = useState(true);
  const [addMenuItem, setAddMenuItem] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  //const [switchMerchant, setSwitchMerchant] = useState(isTablet ? false : true);
  const [showOption1, setShowOption1] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [outletId, setOutletId] = useState(User.getOutletId());
  const [search, setSearch] = useState('');
  const [menu, setMenu] = useState([]);
  const [modal, setModal] = useState(false);
  const [category, setCategory] = useState('');
  const [itemId, setItemId] = useState([]);
  const [menuItem, setMenuItem] = useState([]);
  const [detail, setDetail] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  // const [material, setMaterial] = useState([]);
  const [choosenMenu, setChoosenMenu] = useState([]);
  const [image, setImage] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [logo, setLogo] = useState('');
  const [materialSKU, setMaterialSKU] = useState('');
  const [materialTag, setMaterialTag] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [options, setOptions] = useState('');
  const [options1, setOptions1] = useState('');
  const [material, setMaterial] = useState('');
  const [supplier, setSupplier] = useState('');
  const [cate, setCate] = useState([]);
  const [taxInfo, setTaxInfo] = useState([]);
  const [merInfo, setMerInfo] = useState([]);
  const [myTextInput, setMyTextInput] = useState(React.createRef());
  const [lists, setLists] = useState([]);
  const [productlist1, setProductlist1] = useState(true);
  const [searchList, setSearchList] = useState(false);
  const [currentMenu, setCurrentMenu] = useState([]);
  // const [productList2, setProductList2] = useState([]);
  const [categoryOutlet, setCategoryOutlet] = useState([]);
  const [choice, setChoice] = useState(null);
  const [productList2, setProductList2] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [title, setTitle] = useState('');
  const [title1, setTitle1] = useState('');
  const [price1, setPrice1] = useState('');
  const [productOptionsState, setProductOptionsState] = useState([
    { optionId: 1, name: 'Spicy', price: 2 },
    { optionId: 2, name: 'No Spicy', price: 0 },
  ]);
  const [productOptions1, setProductOptions1] = useState([
    { optionsId: 1, name: 'Spicy', min: 1, max: 2 },
    { optionsId: 2, name: '', min: 0, max: 2 },
  ]);
  // const [refresh, setRefresh] = useState('false');
  const [loading, setLoading] = useState(false);

  // const [templateImage, setTemplateImage] = useState('');

  /////////////////////////////////////////////////////////////

  const [outletItems, setOutletItems] = useState([]);

  const [importModal, setImportModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);

  const [exportModalVisibility, setExportModalVisibility] = useState(false);
  const [exportEmail, setExportEmail] = useState('');

  const outletItemsUnsorted = OutletStore.useState(s => s.outletItems);
  const outletCategories = OutletStore.useState(s => s.outletCategories);
  const outletCategoriesDict = OutletStore.useState(s => s.outletCategoriesDict);

  const selectedOutletCategoryEdit = CommonStore.useState(s => s.selectedOutletCategoryEdit);

  const isLoading = CommonStore.useState(s => s.isLoading);
  const isLoadingExportCsv = CommonStore.useState(s => s.isLoadingExportCsv);
  const isLoadingExportExcel = CommonStore.useState(s => s.isLoadingExportExcel);

  const [exportExcelLoading, setExportExcelLoading] = useState(false);
  const [exportCSVLoading, setExportCSVLoading] = useState(false);

  const firebaseUid = UserStore.useState(s => s.firebaseUid);
  const userName = UserStore.useState(s => s.name);
  const merchantName = MerchantStore.useState(s => s.name);
  const merchantLogo = MerchantStore.useState(s => s.logo);
  const merchantId = UserStore.useState(s => s.merchantId);

  /////////////////////////////////////////////////////////////

  useEffect(() => {
    if (linkTo) {
      DataStore.update((s) => {
        s.linkToFunc = linkTo;
      });
    }
  }, [linkTo]);

  useEffect(() => {
    var outletItemsTemp = [
      ...outletItemsUnsorted.filter(item => {
        if (selectedOutletCategoryEdit === null) {
          return true;
        }
        else if (selectedOutletCategoryEdit.uniqueId === item.categoryId) {
          return true;
        }

        return false;
      }),
    ];

    outletItemsTemp.sort((a, b) => a.name.localeCompare(b.name));

    setOutletItems(outletItemsTemp);
  }, [outletItemsUnsorted, selectedOutletCategoryEdit]);

  useEffect(() => {
    requestStoragePermission();

    refresh();

    menucount();
    refreshMenu();
    setInterval(() => { refreshMenu() }, 5000)


    // ApiClient.GET(API.outlet2 + outletId).then((result) => {
    //   setState({ outletData: result.cover })
    // })
  }, []);

  const setState = () => { };

  const requestStoragePermission = async () => {
    try {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: "KooDoo Merchant Storage Permission",
          message:
            "KooDoo Merchant App needs access to your storage ",
          buttonNegative: "Cancel",
          buttonPositive: "OK"
        }
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        console.log("Storage permission granted");
      } else {
        console.log("Storage permission denied");
      }
    } catch (err) {
      console.warn(err);
    }
  }

  // const importCSV = () => {
  //   try {
  //     const res = DocumentPicker.pick({
  //       type: [DocumentPicker.types.csv],
  //     });
  //     console.log(
  //       res.uri,
  //       res.type,
  //       res.name,
  //       res.size
  //     );
  //   } catch (err) {
  //     if (DocumentPicker.isCancel(err)) {
  //       // User cancelled the picker, exit any dialogs or menus and move on
  //     } else {
  //       throw err;
  //     }
  //   }
  // }

  const convertTemplateToExcelFormat = () => {
    var excelTemplate = [];

    var allOutletsStr = allOutlets.map(item => item.name).join(';');

    var taxName = currOutletTaxes[0].name;

    var excelColumn = {
      'Name': 'Fried noodle',
      'Description': 'Hong Kong style with dark soy sauce',
      'SKU': 'FN001',
      'Price': '12.99',
      'Prepare Time (seconds)': '900',
      'Currency': 'MYR',
      'Category': 'Dry Noodles',
      'Outlet': allOutletsStr,
      'Tax': `${taxName}:${currOutletTaxes[0].rate}`,
      'Variant': 'Size:Regular/0.00|Large/3.00;Flavour:Original/0.00|Spicy/0.00',
      'Addon': 'Toppings:More fried wonton strips/0/1/2.00|More shrimps/0/1/3.00;Specials:Boiled egg/0/5/2.00',
      // 'Variant': '',
      // 'Addon': '',
      'Image Label (png, jpg, jpeg)': 'Fried_noodle',
    };
    excelTemplate.push(excelColumn);

    var excelColumn2 = {
      'Name': 'Fried rice',
      'Description': 'Special fried rice',
      'SKU': 'FN002',
      'Price': '14.99',
      'Prepare Time (seconds)': '900',
      'Currency': 'MYR',
      'Category': 'Rice',
      'Outlet': allOutletsStr,
      'Tax': `${taxName}:${currOutletTaxes[0].rate}`,
      // 'Variant': 'Size:Regular 0.00,Large 3.00;Flavour:Original 0.00,Spicy 0.00',
      // 'Addon': 'Toppings:More fried wonton strips 0 1 2.00,More shrimps 0 1 3.00;Specials:Boiled egg 0 5 2.00',
      'Variant': '',
      'Addon': '',
      'Image Label (png, jpg, jpeg)': 'Fried_rice',
    };
    excelTemplate.push(excelColumn2);

    console.log('excelTemplate');
    console.log(excelTemplate);

    return excelTemplate;
  };

  // const exportTemplate = async () => {
  //   try {
  //     const excelTemplate = convertTemplateToExcelFormat();

  //     const tempFolderName = 'koodoo-product-template';
  //     const tempFolderPath = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/${tempFolderName}`;
  //     RNFS.mkdir(tempFolderPath);

  //     const tempImageFolderName = 'images/Fried_noodle';
  //     const tempImageFolderPath = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/${tempFolderName}/${tempImageFolderName}`;
  //     RNFS.mkdir(tempImageFolderPath);

  //     const tempImageFolderName2 = 'images/Fried_rice';
  //     const tempImageFolderPath2 = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/${tempFolderName}/${tempImageFolderName2}`;
  //     RNFS.mkdir(tempImageFolderPath2);

  //     var templateImageUrl = '';

  //     // download merchant logo as example image file

  //     await new Promise((resolve, reject) => {
  //       if (merchantLogo.startsWith('http') || merchantLogo.startsWith('file')) {
  //         templateImageUrl = merchantLogo;

  //         resolve();
  //       }
  //       else {
  //         getImageFromFirebaseStorage(merchantLogo, (parsedUrl) => {
  //           templateImageUrl = parsedUrl;

  //           resolve();
  //         });
  //       }
  //     });

  //     var tempImageFileName = 'image.' + templateImageUrl.split('.').pop();
  //     tempImageFileName = tempImageFileName.split('?')[0];

  //     const tempImageFilePath = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/${tempFolderName}/${tempImageFolderName}/${tempImageFileName}`;

  //     const downloadJob = RNFS.downloadFile({
  //       fromUrl: templateImageUrl,
  //       toFile: tempImageFilePath,
  //     });

  //     await downloadJob.promise;

  //     const tempImageFilePath2 = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/${tempFolderName}/${tempImageFolderName2}/${tempImageFileName}`;

  //     const downloadJob2 = RNFS.downloadFile({
  //       fromUrl: templateImageUrl,
  //       toFile: tempImageFilePath2,
  //     });

  //     await downloadJob2.promise;

  //     // var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/koodoo-Product${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
  //     var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/${tempFolderName}/koodoo-product-template.xlsx`;
  //     var excelWorkSheet = XLSX.utils.json_to_sheet(excelTemplate);
  //     var excelWorkBook = XLSX.utils.book_new();

  //     //excelWorkSheet = autofitColumns(excelTemplate, excelWorkSheet);

  //     XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, "Product Template");

  //     const workBookData = XLSX.write(excelWorkBook, {
  //       type: 'binary',
  //       bookType: 'xlsx',
  //     });

  //     RNFS.writeFile(excelFile, workBookData, 'ascii')
  //       .then((success) => {
  //         console.log(`wrote file ${excelFile}`);

  //         // zip the folder

  //         const tempZipPath = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/${tempFolderName}.zip`;

  //         try {
  //           // try unlink zip first to make sure clean zip file

  //           RNFS.unlink(tempZipPath);
  //         }
  //         catch (ex) {
  //           console.error(ex);
  //         }

  //         zip(tempFolderPath, tempZipPath)
  //           .then((path) => {
  //             console.log(`zip completed at ${path}`)

  //             RNFS.unlink(tempFolderPath);

  //             Alert.alert(
  //               'Success',
  //               `Sent to ${tempZipPath}`,
  //               [{ text: 'OK', onPress: () => { } }],
  //               { cancelable: false },
  //             );
  //           })
  //           .catch((error) => {
  //             console.error(error)

  //             Alert.alert('Error', 'Failed to export template');
  //           });

  //       })
  //       .catch((err) => {
  //         console.log(err.message);

  //         Alert.alert('Error', 'Failed to export template');
  //       });
  //   }
  //   catch (ex) {
  //     console.error(ex);

  //     Alert.alert('Error', 'Failed to export template');
  //   }
  // }

  // const importTemplateData = async () => {
  //   CommonStore.update(s => {
  //     s.isLoading = true;
  //   });

  //   try {
  //     const res = await DocumentPicker.pick({
  //       type: [DocumentPicker.types.zip],
  //       copyTo: 'documentDirectory',
  //     });
  //     console.log('res');
  //     console.log(res);

  //     // RNFetchBlob.fs.readFile(res.uri, 'base64').then(async data => {
  //     //   // upload to firebase storage

  //     //   var referenceId = uuidv4();
  //     //   var referencePath = `/merchant/${merchantId}}/batchUploads/${referenceId}.zip`;

  //     //   await uploadFileToFirebaseStorage(data, referencePath);
  //     // });

  //     var referenceId = uuidv4();
  //     var referencePath = `/merchant/${merchantId}}/batchUploads/${referenceId}.zip`;

  //     //var translatedPath = await getPathForFirebaseStorageFromBlob(res[0]);

  //     // console.log('translatedPath');
  //     // console.log(translatedPath);

  //     // await uploadFileToFirebaseStorage(translatedPath, referencePath);

  //     const body = {
  //       zipId: referenceId,
  //       zipPath: referencePath,

  //       userId: firebaseUid,
  //       merchantId: merchantId,
  //       outletId: currOutletId,
  //     };

  //     ApiClient.POST(API.batchCreateOutletItems, body).then((result) => {
  //       if (result && result.status === 'success') {
  //         Alert.alert('Success', 'Submitted to upload queue, we will notify you once processing done');
  //       }
  //       else {
  //         Alert.alert('Error', 'Failed to import the data');
  //       }

  //       CommonStore.update(s => {
  //         s.isLoading = false;
  //       });
  //     }).catch(err => {
  //       console.log(err);

  //       Alert.alert('Error', 'Failed to import the data');

  //       CommonStore.update(s => {
  //         s.isLoading = false;
  //       });
  //     });
  //   } catch (err) {
  //     CommonStore.update(s => {
  //       s.isLoading = false;
  //     });

  //     if (DocumentPicker.isCancel(err)) {
  //       // User cancelled the picker, exit any dialogs or menus and move on
  //     } else {
  //       console.error(err);

  //       Alert.alert('Error', 'Failed to import the data');
  //     }
  //   }
  // }

  // const exportFile = () => {
  //   if (outletItems) {
  //     const csvData = convertArrayToCSV(outletItems);

  //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-product-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
  //     console.log("PATH", pathToWrite)
  //     RNFetchBlob.fs
  //       .writeFile(pathToWrite, csvData, 'utf8')
  //       .then(() => {
  //         console.log(`wrote file ${pathToWrite}`);
  //         // wrote file /storage/emulated/0/Download/data.csv
  //         Alert.alert(
  //           'Success',
  //           `Send to ${pathToWrite}`,
  //           [{ text: 'OK', onPress: () => { } }],
  //           { cancelable: false },
  //         );
  //       })
  //       .catch(error => console.error(error));
  //   }

  //   // var body = {
  //   //   data: productList
  //   // }

  //   // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
  //   //   console.log("RESULT", result)
  //   //   if (result !== null) {
  //   //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/productList.csv`;
  //   //     console.log("PATH", pathToWrite)
  //   //     RNFetchBlob.fs
  //   //       .writeFile(pathToWrite, result, 'utf8')
  //   //       .then(() => {
  //   //         console.log(`wrote file ${pathToWrite}`);
  //   //         // wrote file /storage/emulated/0/Download/data.csv
  //   //         Alert.alert(
  //   //           'Success',
  //   //           'The data had exported',
  //   //           [{ text: 'OK', onPress: () => { } }],
  //   //           { cancelable: false },
  //   //         );
  //   //       })
  //   //       .catch(error => console.error(error));
  //   //   }
  //   // });
  // }

  // const refresh1 = () => {
  //   setState({ menuItem: [], cate: [], detail: [], taxInfo: [], merInfo: [], image: '' });

  //   //myTextInput.current.clear();
  // }

  // const addSlot = () => {
  //   setState({ showInventory: true });
  // }

  // const handleChoosePhoto = () => {
  //   const option = {
  //     noData: true,
  //   };
  //   ImagePicker.showImagePicker(option, (response) => {

  //     if (response.didCancel) {
  //     } else if (response.error) {
  //       Alert.alert(response.error.toString());
  //     } else {
  //       setState({ image: response.uri });
  //     }
  //   });
  // }

  const getItemByOutletTEST = param => {
    // ApiClient.GET(API.getItemByOutletTEST + param).then(result => {
    //   setState({
    //     menuItem: result,
    //     itemName: result.name,
    //     itemDescription: result.description,
    //     itemPrice: result.price,
    //     itemTaxRate: result.category.outlet.orderTax.rate,
    //     cate: result.category,
    //     detail: result.category.outlet,
    //     taxInfo: result.category.outlet.orderTax,
    //     merInfo: result.category.outlet.merchant,
    //     image: result.category.outlet.merchant.logo,

    //   });

    //   if (result.itemAddOns.length > 0) {
    //     const addOnSingle = result.itemAddOns.filter(i => i.leastSelect == 1)
    //     const addOnMultiple = result.itemAddOns.filter(i => i.leastSelect != 1)
    //     setState({
    //       addOnSingle: addOnSingle.itemAddOns,
    //       addOnMultiple: addOnMultiple.itemAddOns,
    //     })
    //   }
    // });
  }

  const editItem = () => {
    if (!price) {
      //whenever you call state the variable has to be already declared above
      Alert.alert(
        'Error',
        'Please fill in the information',
        [{ text: 'OK', onPress: () => { } }],
        { cancelable: false },
      );
      return;
    } else {
      var body = {
        //<- this works, the database is updated
        //name: name, <- Original
        name: '',
        code: '',
        description: description,
        price: price,
        url: image,
        materialTag: materialTag,
        materialSKU: materialSKU,
        categoryId: categoryId,
        options: productOptionsState,
        //material: [{ name: material, qty: qty }], <-Original
        material: [{}],
        suppliers: [{ name: supplier }],
      };
      // ApiClient.POST(API.menuItem, body).then((result) => {

      //   if (result) {
      //     Alert.alert(
      //       'Success!',
      //       'You have inserted Success!',
      //       [
      //         {
      //           text: 'OK',
      //           onPress: () => { },
      //         },
      //       ],
      //       { cancelable: false },
      //     );
      //   }
      // });
    }
    myTextInput.current.clear();
  }

  // const _fetchResults = () => {
  //   setState({ showOption: true });
  // }

  // const _fetchResults1 = () => {
  //   setState({ showOption1: true });
  // }

  const searchBarItem = () => {
    // ApiClient.GET(
    //   API.searchBarItem +
    //   search +
    //   '&outletId=' +
    //   outletId,
    // ).then((result) => {
    //   setState({ lists: result });
    // });
  }

  const refresh1 = () => {
    setState({ menuItem: [] });
    setState({ cate: [] });
    setState({ detail: [] });
    setState({ taxInfo: [] });
    setState({ merInfo: [] });
    setState({ image: '' });
    //myTextInput.current.clear();
  }

  const addSlot = () => {
    setState({ showInventory: true });
  }

  // const handleChoosePhoto = () => {
  //   const option = {
  //     noData: true,
  //   };
  //   ImagePicker.showImagePicker(option, (response) => {
  //     if (response.didCancel) {
  //     } else if (response.error) {
  //       Alert.alert(response.error.toString());
  //     } else {
  //       setState({ image: response.uri });
  //     }
  //   });
  // }

  // const getItemByOutletTEST = param => {
  //   ApiClient.GET(API.getItemByOutletTEST + param).then((result) => {
  //     setState({ menuItem: result });
  //     setState({ cate: result.category });
  //     setState({ detail: result.category.outlet });
  //     setState({ taxInfo: result.category.outlet.orderTax });
  //     setState({ merInfo: result.category.outlet.merchant });
  //     setState({ image: result.category.outlet.merchant.logo });
  //     //setState({material: result.material});
  //     //setState({suppliers: result.suppliers});
  //   });
  // }

  // const editItem = () => {
  //   if (!price) {
  //     //whenever you call state the variable has to be already declared above
  //     Alert.alert(
  //       'Error',
  //       'Please fill up the information',
  //       [{ text: 'OK', onPress: () => { } }],
  //       { cancelable: false },
  //     );
  //     return;
  //   } else {
  //     var body = {
  //       //<- this works, the database is updated
  //       name: '',
  //       code: '',
  //       description: description,
  //       price: price,
  //       url: image,
  //       materialTag: materialTag,
  //       materialSKU: materialSKU,
  //       categoryId: categoryId,
  //       options: [
  //         {
  //           name: options,
  //           least: '1',
  //           choice: [{ label: options1, price: '1' }],
  //         },
  //       ],
  //       material: [{ name: material, qty: qty }],
  //       suppliers: [{ name: supplier }],
  //     };
  //     ApiClient.POST(API.menuItem, body, false).then((result) => {
  //       if (result) {
  //         Alert.alert(
  //           'Success',
  //           [
  //             {
  //               text: 'OK',
  //               onPress: () => { },
  //             },
  //           ],
  //           { cancelable: false },
  //         );
  //       }
  //     });
  //   }
  //   myTextInput.current.clear();
  // }

  const _fetchResults = () => {
    setState({ showOption: true });
  }

  const _fetchResults1 = () => {
    setState({ showOption1: true });
  }

  const refresh = () => {
    // ApiClient.GET(API.merchantMenu + outletId).then((result) => {
    //   if (result.length > 0) {
    //     var productListRaw = [];

    //     result.forEach((element) => {

    //       productListRaw = productListRaw.concat(element.items);
    //       const activeItem = productListRaw.filter(item => item.active == 1)
    //       setState({ productList: productListRaw, productList2: activeItem, }, () => { });
    //     });
    //   }
    // });
  }
  const refreshMenu = () => {
    // ApiClient.GET(API.activeMenu + outletId).then((result) => {
    //   if (choice != null) {
    //     const selectedCat = result.filter(i => i.name == choice,)
    //     setState({ currentMenu: selectedCat })
    //   }
    //   // }else{
    //   //   setState({ currentMenu: result });
    //   // }

    // });
  }


  const categoryFunc = () => {
    // ApiClient.GET(API.getCategory + 1).then((result) => {

    //   const tmpCategories = {};
    //   for (const category of result) {
    //     const categoryName = category.name
    //     const categoryId = category.id
    //     if (!tmpCategories[categoryName]) {
    //       tmpCategories[categoryName] = {
    //         label: categoryName,
    //         value: categoryId,
    //       };
    //     }

    //   }
    //   const categories = Object.values(tmpCategories);
    //   setState({ categoryOutlet: categories });
    // });
  }
  const changeMenu = param => {
    changeItem(param.item.id);

    var menu = currentMenu;
    menu.push(param);

    setState({ currentMenu: menu });
  }

  const menucount = () => {
    // ApiClient.GET(API.merchantMenu + outletId).then((result) => {
    //   if (result.length > 0) {
    //     var productListRaw1 = [];
    //     result.forEach((element) => {


    //       productListRaw1 = productListRaw1.concat(element.items);
    //     });
    //   }

    //   productListRaw1.forEach((element) => {


    //     const menuItemCount = productListRaw1.filter(element => element.active === 1)

    //     setState({ menuCount: menuItemCount.length })
    //   });
    // });
  }

  const setActiveMenu = param => {
    var body = {
      outletId: outletId,
      itemId: param,
    };
    // ApiClient.POST(API.setActiveMenu, body).then((result) => {
    //   refresh();

    // });
  }

  const deleteCartItem = param => {
    var list = choosenMenu;
    var newList = [];
    if (list.length > 1) {
      list.forEach((element) => {
        if (element.name != param) {
          newList.push(element);
          setState({ choosenMenu: newList });
        }
      });
    } else {
      if (list[0].name == param) {
        setState({ choosenMenu: [5] });
      }
    }
  }

  const renderMenuList = ({ item }) => (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View style={styles.content6}></View>
        <View style={{ marginLeft: 20, marginVertical: 10 }}>
          <Text style={{ fontSize: 11, fontFamily: 'NunitoSans-Bold' }}>{item.name}</Text>
          <Text style={{ fontSize: 10, color: Colors.primaryColor, fontFamily: 'NunitoSans-Regular' }}>
            RM {item.price}
          </Text>
        </View>
      </View>
      <View
        style={{
          backgroundColor: '#bbfaed',
          width: 60,
          height: 20,
          marginLeft: 60,
          marginVertical: 10,
          borderRadius: 5,
        }}>
        <TouchableOpacity
          onPress={() => {
            deleteCartItem(item.name);
          }}>
          <Text style={{ alignSelf: 'center', fontFamily: 'NunitoSans-Regular' }}>remove</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  const changeSection = data => {
    var array = choosenMenu;
    array.push(data);
    setState({ choosenMenu: array });
  }

  const renderMenu = ({ item }) => (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      {item.items.map(i => {
        return (
          <View
            style={{
              backgroundColor: '#717378',
              width: (Dimensions.get('window').width - 550) / 4,
              height: (Dimensions.get('window').width - 550) / 4,
              borderRadius: 10,
              marginLeft: 30,
              marginBottom: 30,
              zIndex: 0,
            }}>
            <View style={styles.content4}>
              <Text style={{ textAlign: 'center', fontFamily: 'NunitoSans-Bold', fontSize: 15 }}>
                RM {i.price}
              </Text>
            </View>
            <Text style={{
              color: Colors.whiteColor,
              alignSelf: 'center',
              padding: 10,
              position: 'absolute',
              fontSize: 16,
              fontFamily: 'NunitoSans-Bold',
              bottom: 5,
            }}>{i.name}</Text>

          </View>
        )
      })}
    </View>
  );

  const outletMenu = () => (
    <View
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: Dimensions.get('window').height,
      }}>
      <View style={styles.confirmMenu}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 20,
          }}>
          <View
            style={{
              justifyContent: 'center',
              width: '50%',
              flex: 1,
            }}>
            <Text
              style={{
                fontSize: 30,
                fontFamily: 'NunitoSans-Bold',
                textAlign: 'center',
              }}>
              List of Menu
            </Text>
          </View>
          <View>
            <TouchableOpacity
              onPress={() => {
                setState({ modal: false });
              }}>
              <View style={{ padding: 10 }}>
                <Close name="closecircle" size={30} />
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
          }}>
          <View
            style={{
              height: 30,
              width: '5%',
            }}>
            <Text style={{ textAlign: 'center', fontSize: 18, fontFamily: 'NunitoSans-Regular' }}>ID</Text>
          </View>
          <View style={{ height: 30, width: '50%' }}>
            <Text style={{ textAlign: 'center', fontSize: 18, fontFamily: 'NunitoSans-Regular' }}>Food Name</Text>
          </View>
          <View></View>
        </View>
        {productList2.map((elements) => {
          return (
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 20,
                }}>
                <View
                  style={{
                    height: 30,
                    width: '5%',
                  }}>
                  <Text style={{ textAlign: 'center', fontSize: 18, fontFamily: 'NunitoSans-Regular' }}>
                    {elements.id}
                  </Text>
                </View>
                <View style={{ height: 30, width: '50%' }}>
                  <Text style={{ textAlign: 'center', fontSize: 18, fontFamily: 'NunitoSans-Regular' }}>
                    {elements.name}
                  </Text>
                </View>
                <View>
                  <TouchableOpacity
                    onPress={
                      () => {
                        changeMenu(elements);
                      }
                      // setState({itemId: elements.id});
                      // addActiveMenu(elements.id);
                    }>
                    <View
                      style={{
                        alignSelf: 'flex-end',
                      }}>
                      <Close name="plus" size={28} />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );

  const changeItem = itemID => {

    var body = {
      outletId: outletId,
      itemId: itemID,
      //newCategoryId: selectedCategoryId
      // active: true
    };

    // ApiClient.POST(API.changeItem, body).then((result) => {

    //   if (result.success == true) {

    //     Alert.alert(
    //       'Success',
    //       'You have add the menu',
    //       [
    //         {
    //           text: 'OK',
    //           onPress: () => {
    //             setState({ modal: false });
    //           },
    //         },
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  }
  const deleteCurrentMenu = itemID => {
    if (!outletId || !currentMenu) {

      Alert.alert(
        'Error',
        'Please fill in the information',
        [{ text: 'OK', onPress: () => { } }],
        { cancelable: false },
      );
      return;
    } else {
      var menuID = [];
      currentMenu.forEach((element) => {
        menuID.push(element.id);
      });
    }
    var body = {
      outletId: outletId,
      itemId: itemID,
    };
    // ApiClient.POST(API.setActiveMenu, body).then((result) => {

    //   if (result == true) {
    //     refreshMenu();
    //     Alert.alert(
    //       'Success',
    //       'You have deleted the menu',
    //       [
    //         {
    //           text: 'OK',
    //           onPress: () => {
    //             // setState({modal: false});
    //           },
    //         },
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  }
  const addCurrentMenu = () => {
    if (
      //!name ||
      !category ||
      outletId != null
    ) {
      Alert.alert(
        'Error',
        'Please fill in the information',
        [{ text: 'OK', onPress: () => { } }],
        { cancelable: false },
      );
      return;
    } else {
      var body = {
        //itemId: name,
        newCategoryId: category,
        outletId: outletId,
      };
      // ApiClient.POST(API.addCurrentMenu, body).then((result) => {
      //   if (result == true) {
      //     refreshTable();
      //     Alert.alert(
      //       'Success',
      //       'You have add to the menu',
      //       [
      //         {
      //           text: 'OK',
      //           // onPress: () => {
      //           //   setState({modal: false});
      //           // },
      //         },
      //       ],
      //       { cancelable: false },
      //     );
      //   }
      // });
    }
  }

  const deleteItem = param => {
    var body = {
      itemId: param
    };

    // ApiClient.POST(API.deleteMenuItem, body).then((result) => {
    //   //console.log("result",result)
    //   if (result.success == true) {
    //     refreshMenu();
    //     Alert.alert(
    //       'Success',
    //       'The product has been deleted',
    //       [{ text: 'OK', onPress: () => { } }],
    //       { cancelable: false },
    //     );

    //   }
    //   if (result.success == false) {
    //     refreshMenu();
    //     Alert.alert(
    //       'Failed',
    //       'The product has not been deleted',
    //       [{ text: 'OK', onPress: () => { } }],
    //       { cancelable: false },
    //     );

    //   }

    // });
  }

  const switchActiveStatus = (item, value) => {
    var body = {
      outletItemId: item.uniqueId,
      isActive: value,
    };

    console.log(body);

    ApiClient.POST(API.switchOutletItemActiveStatus, body).then((result) => {
    }).catch(err => { console.log(err) });
  }

  const renderItem = ({ item }) => (
    <TouchableOpacity
      onPress={() => {
        // setState({
        //   addProductItem: false,
        //   addMenuItem: false,
        //   addProduct: true,
        // });
        // getItemByOutletTEST(item.id);

        CommonStore.update(s => {
          s.selectedProductEdit = item;

          s.timestamp = Date.now;

          // s.selectedOutletItem = item; // get the add-on list
        });

        linkTo && linkTo(`${prefix}/productadd`);
      }}>
      <View
        style={{
          backgroundColor: '#FFFFFF',
          flexDirection: 'row',
          paddingVertical: 10,
          paddingHorizontal: 5,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: '#C4C4C4',
          //minHeight: 90,
          alignItems: 'center',

        }}>
        <View style={{ width: '8.5%', paddingLeft: 10, justifyContent: 'center', alignItems: 'center' }}>
          {
            item.image
              ?
              <AsyncImage
                source={{ uri: item.image }}
                item={item}
                hideLoading={true}
                style={{ width: 45, height: 45, borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, }}
              />
              :
              <Ionicons name="fast-food-outline" size={35} />
          }
        </View>
        <Text style={{ width: '9%', fontFamily: 'NunitoSans-Regular', fontSize: 14, textAlign: 'left' }} numberOfLines={1}>{item.skuMerchant || 'N/A'}</Text>
        <View style={{ width: '33%', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Text style={{ width: '55%', fontFamily: 'NunitoSans-Regular', fontSize: 14, textAlign: 'left' }} numberOfLines={2}>{item.name}</Text>
        </View>
        {/* <Text style={{ width: '15%', fontFamily: 'NunitoSans-Regular', fontSize: 14, textAlign: 'center' }}>{outletCategoriesDict[item.categoryId] ? outletCategoriesDict[item.categoryId].name : 'N/A'}</Text> */}
        <Text style={{ width: '25%', fontFamily: 'NunitoSans-Regular', fontSize: 14, textAlign: 'left', right: Platform.OS == 'ios' ? 0 : 0 }}>{item.description}</Text>


        {/* <Switch
          style={{ padding: 3, height: 23, marginBottom: 5, width: '5%', transform: Platform.OS == 'ios' ? [{ scaleX: .6 }, { scaleY: .6 }] : [{ scaleX: .8 }, { scaleY: .8 }] }}
          value={item.active == 1 ? true : false}
          onAsyncPress={(status) => setState({ status }, () => { setActiveMenu(item.id) })}
          circleColorActive={Colors.primaryColor}
          circleColorInactive={Colors.primaryColor}
          backgroundActive={Colors.lightPrimary}
        /> */}
        <Text style={{ width: '12%', fontFamily: 'NunitoSans-Regular', fontSize: 14, textAlign: 'left' }}>RM {(item.price).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
        <View style={{
          width: '14%',
          flexDirection: 'column',
          alignItems: 'flex-start',
          //justifyContent: 'flex-start',
        }}>
          <Text style={{
            width: '55%',
            fontFamily: 'NunitoSans-Regular', fontSize: 14, textAlign: 'left', marginRight: 3,
            color: item.isActive ? Colors.primaryColor : Colors.fieldtTxtColor
          }}>
            {/* {item.active ? 'Available' : 'Out of Stock'} */}
            {item.isActive ? 'Available' : 'Disabled'}
          </Text>
          <View style={{
            width: '30%',
            marginTop: 2,
          }}>
            {/* <Switch
              value={item.isActive}
              onSyncPress={(value) => {
                // setState({ newReservationStatus: item }, () => {
                //   switchReservationStatus();
                // });
                switchActiveStatus(item, value);

              }}
              width={36}
              circleColorActive={Colors.primaryColor}
              circleColorInactive={Colors.fieldtTxtColor}
              backgroundActive='#dddddd'
            /> */}
          </View>
        </View>
        <Text style={{ width: '4%', fontFamily: 'NunitoSans-Regular', fontSize: 14, textAlign: 'center' }}>{item.code}</Text>
      </View>

    </TouchableOpacity>

  );

  const renderSearchItem = ({ item }) =>
    item.items.map((elements) => {
      return (
        <TouchableOpacity
          onPress={() => {
            setState({
              addProductItem: false,
              addMenuItem: false,
              addProduct: true,
            });
            getItemByOutletTEST(elements.id);
          }}>
          <View
            style={{
              backgroundColor: '#FFFFFF',
              flexDirection: 'row',
              paddingVertical: 20,
              paddingHorizontal: 20,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderBottomColor: '#C4C4C4',
            }}>
            <Image
              source={{
                //elements.image
                uri: elements.image,
                //'http://streetfoodaffair.co.uk/wp-content/uploads/2017/04/Polpetta.jpg',
              }}
              style={{ width: 20, height: 20 }}
            />
            <Text style={{ width: '2%', fontFamily: 'NunitoSans-Regular' }}></Text>
            <Text style={{ width: '14%', fontFamily: 'NunitoSans-Regular' }}>{elements.name}</Text>
            <Text style={{ width: '15%', fontFamily: 'NunitoSans-Regular' }}>{elements.category}</Text>
            <Text style={{ width: '8%', fontFamily: 'NunitoSans-Regular' }}>
              {elements.active ? 'Available' : 'Out of Stock'}
            </Text>
            {/* <Switch
              style={{ marginTop: 0, width: '10%' }}
              value={elements.active ? status : !status}
              onAsyncPress={(status) => setState({ status })}
              circleColorActive={Colors.primaryColor}
              circleColorInactive={Colors.fieldtTxtColor}
              backgroundActive='#dddddd'
            /> */}
            <Text style={{ width: '2%', fontFamily: 'NunitoSans-Regular' }}></Text>
            <Text style={{ width: '16%', fontFamily: 'NunitoSans-Regular' }}>{elements.price}</Text>
            <Text style={{ width: '20%', marginRight: 20, fontFamily: 'NunitoSans-Regular' }}>
              {elements.description}
            </Text>
            <Text style={{ width: '7%', fontFamily: 'NunitoSans-Regular' }}>{elements.code}</Text>
          </View>

        </TouchableOpacity>

      );
    });

  const convertDataToExcelFormat = () => {
    var excelData = [];

    for (var i = 0; i < outletItems.length; i++) {
      var excelRow = {
        'SKU': outletItems[i].skuMerchant ? outletItems[i].skuMerchant : 'N/A',
        'Product Name': outletItems[i].name ? outletItems[i].name : 'N/A',
        Price: +parseFloat(outletItems[i].price).toFixed(2),
        'Description': outletItems[i].description ? outletItems[i].description : 'N/A',
      };

      excelData.push(excelRow);
    }

    console.log('excelData');
    console.log(excelData);

    return excelData;
  };

  // function end

  const onadditem = () => {
    const productOptions = productOptionsState;

    productOptions.push({
      optionId: (productOptions.length + 1).toString(),
      name: '',
      price: 0,
    });
    setState({ productOptions })
  }
  const onadditem1 = () => {
    const productOptions1 = productOptions1;
    productOptions1.push({
      optionId: (productOptions1.length + 1).toString(),
      name: '',
      min: 0,
      max: 0,
    });
    setState({ productOptions1 });
  }

  const getName = () => {
    const productOptions = productOptionsState;
    var name = null;
    var i;
    for (i = 0; i < productOptions.length; i++) {
      if (productOptions[i].optionId != null) {
        name = productOptions[i].name;
      }
    }
    return name;
  }

  const renderOptions = () => {
    const options = [];
    const productOptions = productOptionsState;
    for (const opt of productOptions) {
      options.push(
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 10,
            alignContent: 'space-between',
            height: 50,
            width: '100%',
          }}>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              width: 120,
              padding: 10,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
              marginRight: '10%',
            }}
            placeholder="Option Name"
            onChangeText={(text) => {
              const productOptions = productOptionsState;
              const item = productOptions.find(
                (obj) => obj.id === productOptions.id,
              );
              item.name = text;
              setState({ productOptions });
            }}
            value={(value) => {
              const productOptions = productOptionsState;
              const item = productOptions.find(
                (obj) => obj.id === productOptions.id,
              );
              value = item.name;
            }}
            multiline={true}
            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
            ref={myTextInput}
          />
          <Text
            style={{
              fontSize: 20,
              fontFamily: 'NunitoSans-Bold',
              marginRight: '5%',
            }}>
            RM
          </Text>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
            style={{
              width: 60,
              padding: 10,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
              marginRight: '10%',
            }}
            placeholder="Price"
            onChangeText={(text) => {
              const productOptions = productOptionsState;
              const item = productOptions.find(
                (obj) => obj.id === productOptions.id,
              );
              item.price = text;
              setState({ productOptions });
            }}
            value={(value) => {
              const productOptions = productOptionsState;
              const item = productOptions.find(
                (obj) => obj.id === productOptions.id,
              );
              value = item.price;
            }}
            multiline={true}
            ref={myTextInput}
          />
        </View>,
      );
    }
    return options;
  }

  const renderOptions1 = () => {
    const options1 = [];
    for (const opt of productOptions1) {
      options1.push(
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 10,
            alignContent: 'space-between',
            height: 50,
            width: '100%',
          }}>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
            style={{
              width: 120,
              padding: 10,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
              marginRight: '10%',
            }}
            placeholder="Option Name"
            onChangeText={(text) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id,
              );
              item.name = text;
              setState({ productOptions1 });
            }}
            value={(value) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id,
              );
              value = item.name;
            }}
            multiline={true}
            ref={myTextInput}
          />
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              width: 60,
              padding: 10,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
            }}
            placeholder="MIN"
            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
            onChangeText={(text) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id,
              );
              item.min = text;
              setState({ productOptions1 });
            }}
            value={(value) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id,
              );
              value = item.min;
            }}
            multiline={true}
            ref={myTextInput}
          />
          <Text
            style={{
              fontSize: 20,
              fontFamily: 'NunitoSans-Bold',
              marginRight: '5%',
              marginLeft: '5%',
            }}>
            /
          </Text>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              width: 60,
              padding: 10,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
            }}
            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
            placeholder="MAX"
            onChangeText={(text) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id,
              );
              item.max = text;
              setState({ productOptions1 });
            }}
            value={(value) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id,
              );
              value = item.max;
            }}
            multiline={true}
            ref={myTextInput}
          />
        </View>,
      );
    }
    return options1;
  }

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={2} expandReport={true} />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ width: windowWidth * 0.9 }}
          contentContainerStyle={{
            paddingBottom: Dimensions.get("screen").height * 0.1,
          }}
        >
          <View style={styles.content}>

            <Modal
              style={{
                // flex: 1
              }}
              visible={importModal}
              supportedOrientations={['portrait', 'landscape']}
              transparent={true}
              animationType={'slide'}
            >
              <View style={styles.modalContainer}>
                <View style={[styles.modalView, {
                }]}>
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={() => {
                      // setState({ changeTable: false });
                      setImportModal(false);
                    }}>
                    <AntDesign name="closecircle" size={25} color={Colors.fieldtTxtColor} />
                  </TouchableOpacity>
                  <View style={styles.modalTitle}>
                    <Text style={styles.modalTitleText}>
                      Upload Options
                    </Text>
                  </View>
                  <View style={{
                    alignItems: 'center',
                    top: '10%',
                  }}>
                    {/* <TouchableOpacity
                  style={[styles.modalSaveButton, {
                    zIndex: -1.
                  }]}
                  onPress={() => { exportFile() }}>
                  <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Export to CSV</Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={[styles.modalSaveButton, {
                    zIndex: -1.
                  }]}
                  onPress={() => { exportTemplate() }}>
                  <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Export Template</Text>
                </TouchableOpacity> */}

                    {/* <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      backgroundColor: '#4E9F7D',
                      borderRadius: 5,
                      width: 130,
                      paddingHorizontal: 10,
                      height: 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,
                    }}
                    onPress={() => { importTemplateData() }}
                    disabled={isLoading}
                  > */}
                    <Text style={{
                      color: Colors.whiteColor,
                      //marginLeft: 5,
                      fontSize: 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                      {
                        isLoading ? 'Loading...' : 'Batch Upload'
                      }</Text>

                    {isLoading ?
                      <ActivityIndicator style={{
                        marginLeft: 5,
                      }} color={Colors.whiteColor} size={'small'} />
                      : <></>
                    }
                    {/* </TouchableOpacity> */}
                  </View>
                </View>
              </View>
            </Modal>

            <Modal
              style={{
                // flex: 1
              }}
              visible={exportModalVisibility}
              supportedOrientations={['portrait', 'landscape']}
              transparent={true}
              animationType={'slide'}
            >
              <View style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: 'center',
                justifyContent: 'center',
                //top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}>
                <View style={{
                  height: Dimensions.get('window').width * 0.3,
                  width: Dimensions.get('window').width * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: Dimensions.get('window').width * 0.03,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <TouchableOpacity
                    style={{
                      position: 'absolute',
                      right: Dimensions.get('window').width * 0.02,
                      top: Dimensions.get('window').width * 0.02,

                      elevation: 1000,
                      zIndex: 1000,
                    }}
                    onPress={() => {
                      setExportModalVisibility(false);
                    }}>
                    <AntDesign name="closecircle" size={25} color={Colors.fieldtTxtColor} />
                  </TouchableOpacity>
                  <View style={{
                    alignItems: 'center',
                    top: '20%',
                    position: 'absolute',
                  }}>
                    <Text style={{
                      fontFamily: 'NunitoSans-Bold',
                      textAlign: 'center',
                      fontSize: 24,
                    }}>
                      Download Report
                    </Text>
                  </View>
                  <View style={{ top: '10%', }}>
                    <Text style={{ fontSize: 20, fontFamily: 'NunitoSans-Bold' }}>Email Address:</Text>

                    <TextInput
                      underlineColorAndroid={Colors.fieldtBgColor}
                      style={{
                        fontFamily: 'NunitoSans-Regular',
                        width: 370,
                        height: 50,
                        //flex: 1,
                        backgroundColor: Colors.fieldtBgColor,
                        borderRadius: 5,
                        paddingLeft: 10,
                        marginTop: 10,
                        fontSize: 14
                      }}
                      placeholderStyle={{ padding: 5, }}
                      placeholder="Enter your email"
                      placeholderTextColor={Platform.select({
                        ios: '#a9a9a9'
                      })}
                      onChangeText={(text) => {
                        setExportEmail(text);
                      }}
                      value={exportEmail}
                    />

                    <Text style={{ fontSize: 20, fontFamily: 'NunitoSans-Bold', marginTop: 15 }}>Download As:</Text>


                    <View style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      //top: '10%',
                      flexDirection: 'row',
                      marginTop: 15,
                    }}>

                      <CSVLink
                        style={{
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          display: "inline-block",
                          flexDirection: "row",
                          textDecoration: 'none',
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          width: 100,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                        }}
                        data={convertDataToExcelFormat()}
                        filename="report.csv"
                      >
                        <View
                          style={{
                            width: "100%",
                            height: "100%",
                            alignContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: 16,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            CSV
                          </Text>
                        </View>
                      </CSVLink>

                      {/* <TouchableOpacity
                      style={[styles.modalSaveButton, {
                          zIndex: -1
                      }]}
                      onPress={() => { downloadPDF() }}>
                      <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>PDF</Text>
                  </TouchableOpacity> */}
                    </View>
                  </View>
                </View>
              </View>
            </Modal>

            <View style={{ flexDirection: 'row', marginBottom: 49, marginTop: 33 }}>
              <View
                style={{
                  flexDirection: 'row',
                  //flex: 1,
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  // width: '100%',
                  //marginLeft: '1%',

                  // marginRight: -Dimensions.get('window').width * 0.2,
                  width: Dimensions.get('window').width * 0.8825,
                }}>
                {/* <TouchableOpacity style={styles.submitText} onPress={() => { importCSV() }}>
              <View style={{ flexDirection: 'row' }}>
                <Icon name="download" size={20} color={Colors.primaryColor} />
                <Text style={{ marginLeft: 10, color: Colors.primaryColor, fontFamily: 'NunitoSans-Regular' }}>
                  Import
                </Text>
              </View>
            </TouchableOpacity> */}


                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: '#4E9F7D',
                    borderRadius: 5,
                    //width: 160,
                    paddingHorizontal: 10,
                    marginHorizontal: 10,
                    height: 40,
                    alignItems: 'center',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                  }}
                  onPress={() => { setExportModalVisibility(true) }}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Icon name="download" size={20} color={Colors.whiteColor} />
                    <Text style={{
                      color: Colors.whiteColor,
                      marginLeft: 5,
                      fontSize: 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                      DOWNLOAD
                    </Text>
                  </View>
                </TouchableOpacity>

                {/* <TouchableOpacity 
             style={{
              justifyContent: 'center',
              flexDirection: 'row',
              borderWidth: 1,
              borderColor: Colors.primaryColor,
              backgroundColor: '#4E9F7D',
              borderRadius: 5,
              //width: 160,
              paddingHorizontal: 10,
              height: 40,
              alignItems: 'center',
              shadowOffset: {
                  width: 0,
                  height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 1,
              zIndex: -1,
              marginHorizontal: 10,
            }}
            onPress={() => { setImportModal(true) }}>
              <View style={{ flexDirection: 'row' }}>
                <Icon name="upload" size={20} color={Colors.whiteColor} />
                <Text style={{
                    color: Colors.whiteColor,
                    marginLeft: 5,
                    fontSize: 16,
                    fontFamily: 'NunitoSans-Bold',
                }}>
                  BATCH UPLOAD
                </Text>
              </View>
            </TouchableOpacity> */}

                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: '#4E9F7D',
                    borderRadius: 5,
                    //width: 160,
                    paddingHorizontal: 10,
                    height: 40,
                    alignItems: 'center',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                  }}
                  onPress={() => {
                    CommonStore.update(s => {
                      s.selectedProductEdit = null;

                      s.timestamp = Date.now();
                    });

                    linkTo && linkTo(`${prefix}/productadd`);
                  }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <AntDesign
                      name="pluscircle"
                      size={20}
                      color={Colors.whiteColor}
                    />
                    <Text style={{
                      color: Colors.whiteColor,
                      marginLeft: 5,
                      fontSize: 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                      PRODUCT
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
              {/* <View style={{ flex: 2 }}></View> */}

              {/* <View
            style={{
              flex: 1,
              alignContent: 'flex-end',
              marginBottom: 10,
              flexDirection: 'row',
            }}>
            <View style={styles.searchView}>
              <Ionicons
                name="search-outline"
                size={20}
                style={styles.searchIcon}
              />

              <TextInput
                editable={loading}
                clearButtonMode="while-editing"
                style={[styles.textInput, { fontFamily: "NunitoSans-Bold", }]}
                placeholder="Search"
                onChangeText={(text) => {
                  setState({
                    search: text.trim(),
                    productlist1: false,
                    searchList: true,
                  });
                }}
                value={search}
              //onSubmitEditing={searchBarItem()}
              />

            </View>
          </View> */}

              {/*  <View
            style={[{
              // flex: 1,
              // alignContent: 'flex-end',
              // marginBottom: 10,
              // flexDirection: 'row',
              // marginLeft: 310,
              // backgroundColor: 'red',
              alignItems: 'flex-end',
              marginRight: '-25%'
              // width: '100%',
            }, !isTablet ? {
              marginLeft: 0,
            } : {}]}> */}

              {/* </View> */}
            </View>

            {/* <View
          style={{
            flexDirection: 'row',
            backgroundColor: Colors.highlightColor,
            padding: 12,
          }}>
          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate("Product")
            }}>
            <Text style={{ fontFamily: 'NunitoSans-Regular' }}>Product</Text>
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => {
              props.navigation.navigate("ProductMenu")
              categoryFunc()
            }}>
            <Text style={{ marginLeft: 30, fontFamily: 'NunitoSans-Regular' }}>Menu</Text>
          </TouchableOpacity>
        </View> */}

            {addProduct ? (
              <View
                style={{
                  backgroundColor: Colors.highlightColor,
                  height: Dimensions.get('window').height,
                }}>
                <Text
                  style={{
                    alignSelf: 'center',
                    fontFamily: 'NunitoSans-Bold',
                    marginVertical: 20,
                    fontSize: 20,
                  }}>
                  Display for user
                </Text>

                <ScrollView
                  showsVerticalScrollIndicator={false}
                >
                  <View style={{ borderBottomWidth: StyleSheet.hairlineWidth, flexDirection: 'row', padding: 30 }}>
                    <View style={{ flex: 3 }}>
                      <View style={{ flexDirection: 'row', flex: 1 }}>
                        {/* left */}
                        <View style={{}}>
                          <Text style={{ fontFamily: 'NunitoSans-Regular' }}>Product photo</Text>
                          {/* <TouchableOpacity
                          style={{ height: 30, width: 50 }}
                          onPress={() => {
                            handleChoosePhoto();
                          }}> */}
                          <Text style={{ fontSize: 13, color: 'darkgreen', fontFamily: 'NunitoSans-Regular' }}>
                            change
                          </Text>
                          {/* </TouchableOpacity> */}
                        </View>
                        <View style={{ marginLeft: 30 }}>
                          <View style={{ backgroundColor: Colors.secondaryColor }}>
                            <Image
                              source={{ uri: image }}
                              style={{ width: 120, height: 120 }}></Image>
                          </View>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', flex: 1 }}>
                        <Text style={{ fontFamily: 'NunitoSans-Regular' }}>Available at</Text>
                        <View style={{ marginLeft: 30 }}>
                          <View style={{ width: 150, height: 120 }}>
                            {/* <DropDownPicker
                            items={[
                              {
                                label: 'Petaling Jaya',
                                value: 'petalingJaya',
                                icon: () => (
                                  <Icon
                                    name="check-square"
                                    size={18}
                                    color={Colors.primaryColor}
                                  />
                                ),
                              },
                              {
                                label: 'SS19',
                                value: 'ss19',
                                icon: () => (
                                  <Icon
                                    name="check-square"
                                    size={18}
                                    color={Colors.primaryColor}
                                  />
                                ),
                              },
                            ]}
                            defaultValue={place}
                            placeholder="Choose Location"
                            containerStyle={{ height: 40 }}
                            style={{ backgroundColor: '#fafafa' }}
                            itemStyle={{
                              justifyContent: 'flex-start',
                            }}
                            dropDownStyle={{ backgroundColor: '#fafafa' }}
                            onChangeItem={(item) =>
                              setState({
                                place: item.value,
                              })
                            }
                          /> */}
                          </View>
                        </View>
                      </View>
                      <View style={{ flexDirection: 'row', flex: 1 }}>
                        <TouchableOpacity
                          style={{ height: 30, width: 100 }}
                          onPress={() => { }}>
                          <Text style={{ fontSize: 13, color: 'red', fontFamily: 'NunitoSans-Regular' }}>
                            Delete Product
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    {/* right */}
                    <View
                      style={{
                        flex: 4,
                      }}>
                      <View
                        style={{
                          width: '68%',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: 15,
                        }}>
                        <View>
                          <Text style={{ fontFamily: 'NunitoSans-Regular', marginRight: 50 }}>Name</Text>
                        </View>
                        <View>
                          <TextInput
                            underlineColorAndroid={Colors.fieldtBgColor}
                            style={[styles.textFieldInput]}
                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            placeholder={'itemName'}
                            onChangeText={(text) => {
                              setState({ itemName: text });
                            }}
                            //value={itemName}
                            multiline={true}
                            ref={myTextInput}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '68%',
                          marginBottom: 15,
                        }}>
                        <View>
                          <Text style={{ fontFamily: 'NunitoSans-Regular', marginRight: 18 }}>Description</Text>
                        </View>
                        <View>
                          <TextInput
                            underlineColorAndroid={Colors.fieldtBgColor}
                            style={[styles.textFieldInput]}
                            placeholder={'itemDescription'}
                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            onChangeText={(text) => {
                              setState({ itemDescription: text });
                            }}
                            //value={itemDescription}
                            multiline={true}
                            ref={myTextInput}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          flex: 1,
                          marginBottom: 15,
                        }}>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            flex: 1,
                          }}>
                          <View style={{ flex: 1 }}>
                            <Text>Price</Text>
                          </View>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              alignSelf: 'flex-end',
                              marginRight: 19,
                            }}>
                            <TextInput
                              underlineColorAndroid={Colors.fieldtBgColor}
                              style={styles.textPrice}
                              placeholder={'itemPrice'}
                              onChangeText={(text) => {
                                setState({ itemPrice: text });
                              }}
                              //value={itemPrice}
                              ref={myTextInput}
                              placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            />
                            <Text style={{ fontWeight: 'bold', fontFamily: 'NunitoSans-Regular', marginLeft: 5 }}>MYR</Text>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            flex: 1,
                          }}>
                          <View style={{ flex: 1 }}>
                            <Text style={{ marginLeft: '50%', fontFamily: 'NunitoSans-Regular' }}>Tax</Text>
                          </View>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              alignSelf: 'flex-end',
                            }}>
                            <TextInput
                              underlineColorAndroid={Colors.fieldtBgColor}
                              style={styles.textTax}
                              keyboardType={"number-pad"}
                              placeholder={'itemTaxRate'}
                              onChangeText={(text) => {
                                setState({ itemTaxRate: text });
                              }}
                              //value={itemTaxRate}
                              multiline={true}
                              ref={myTextInput}
                              placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            />
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          flex: 1,
                          marginBottom: 15,
                        }}>
                        <View>
                          <Text style={{ fontFamily: 'NunitoSans-Regular', marginRight: 30 }}>Category</Text>
                        </View>
                        <View style={styles.textInput4}>
                          {/* <DropDownPicker
                          items={categoryOutlet}

                          containerStyle={{ height: 40 }}
                          style={{ backgroundColor: '#fafafa' }}
                          itemStyle={{
                            justifyContent: 'flex-start',
                          }}
                          dropDownStyle={{ backgroundColor: '#fafafa' }}
                          onChangeItem={(item) =>
                            setState({
                              categoryId: item.value,
                            })
                          }
                        /> */}
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          flex: 1,
                        }}>
                        <View>
                          <Text style={{ fontFamily: 'NunitoSans-Regular' }}>Variant</Text>
                        </View>
                        <View
                          style={{
                            width: "73%",
                          }}>
                          <TouchableOpacity
                            onPress={() => {
                              setState({ visible1: true });
                            }}>
                            <View style={{ flexDirection: 'row' }}>
                              <Icon
                                name="plus-circle"
                                size={20}
                                color={Colors.primaryColor}
                              />
                              <Text
                                style={{
                                  marginLeft: 10,
                                  color: Colors.primaryColor,
                                  fontSize: 16,
                                  fontFamily: 'NunitoSans-Regular'
                                }}>
                                Add single group
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <View
                            style={{
                              flex: 1,
                            }}>
                            <View>
                              <View style={{ flexDirection: 'row' }}>
                                {/* {(addOnSingle != null && addOnSingle.length > 0) ? addOnSingle.map(i => {
                                return (
                                  <View>
                                    <View style={{ margin: 20 }}>
                                      <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: 16 }}>{i.name}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                      {i.itemAddOnChoices.length > 0 ? i.itemAddOnChoices.map(choice => {
                                        return (
                                          <View style={{ paddingVertical: 10, paddingHorizontal: 15, backgroundColor: Colors.fieldtBgColor, borderRadius: 7, marginRight: 20 }}>
                                            <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: 13 }}>{choice.name}</Text>
                                          </View>
                                        )
                                      }) : null}
                                    </View>
                                  </View>
                                )
                              }) : null} */}
                              </View>
                            </View>
                            <View>
                              <View style={{ flexDirection: 'row' }}>
                                {/* {(addOnMultiple != null && addOnMultiple.length > 0) ? addOnMultiple.map(i => {
                                return (
                                  <View>
                                    <View style={{ margin: 20 }}>
                                      <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: 16 }}>{i.name}</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row' }}>
                                      {i.itemAddOnChoices.length > 0 ? i.itemAddOnChoices.map(choice => {
                                        return (
                                          <View style={{ paddingVertical: 10, paddingHorizontal: 15, backgroundColor: Colors.fieldtBgColor, borderRadius: 7, marginRight: 20 }}>
                                            <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: 13 }}>{choice.name}</Text>
                                          </View>
                                        )
                                      }) : null}
                                    </View>
                                  </View>
                                )
                              }) : null} */}
                              </View>
                            </View>

                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{}}></View>
                  <Text
                    style={{
                      alignSelf: 'center',
                      fontFamily: 'NunitoSans-Bold',
                      marginVertical: 20,
                      fontSize: 20,
                    }}>
                    Internal info
                  </Text>
                  <View style={{ padding: 30 }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'space-around',
                      }}>
                      <View
                        style={{
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginRight: 30,
                        }}>
                        <Text
                          style={{
                            fontSize: 18,
                            marginRight: 40,
                            fontFamily: 'NunitoSans-Regular'
                          }}>
                          Supplier
                        </Text>

                        <TextInput
                          underlineColorAndroid={Colors.fieldtBgColor}
                          style={styles.textFieldInput1}
                          placeholder={suppliers.name}
                          onChangeText={(text) => {
                            setState({ supplier: text });
                          }}
                          value={supplier}
                          multiline={true}
                          ref={myTextInput}
                          placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        />
                      </View>


                      <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                        <Text
                          style={{
                            fontSize: 18,
                            marginRight: 40,
                            fontFamily: 'NunitoSans-Regular'
                          }}>
                          SKU
                        </Text>
                        <TextInput
                          underlineColorAndroid={Colors.fieldtBgColor}
                          style={styles.textFieldInput2}
                          placeholder={menuItem.materialSKU}
                          onChangeText={(text) => {
                            setState({ materialSKU: text });
                          }}
                          value={menuItem.materialSKU}
                          multiline={true}
                          ref={myTextInput}
                          placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        />
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        flex: 1,
                        marginTop: 20,
                        justifyContent: 'space-around',
                      }}>
                      <View
                        style={{
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          marginRight: 30,
                        }}>
                        <Text
                          style={{
                            fontSize: 18,
                            marginRight: 36,
                            marginLeft: 3,
                            fontFamily: 'NunitoSans-Regular'
                          }}>
                          Inventory
                        </Text>
                        <TextInput
                          underlineColorAndroid={Colors.fieldtBgColor}
                          style={styles.textInventory}
                          placeholder={material.name}
                          onChangeText={(text) => {
                            setState({ material: text });
                          }}
                          //value={mname}
                          multiline={true}
                          ref={myTextInput}
                          placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        />

                        <TouchableOpacity
                          onPress={() => {
                            addSlot();
                          }}>
                          <View
                            style={{
                              flexDirection: 'row',
                              marginTop: '3%',
                              marginLeft: 10
                            }}>
                            <Icon
                              name="plus-circle"
                              size={20}
                              color={Colors.primaryColor}
                            />
                            <Text
                              style={{
                                color: Colors.primaryColor,
                                fontFamily: 'NunitoSans-Regular'
                              }}>
                              Add Slot
                            </Text>
                          </View>
                        </TouchableOpacity></View>
                      <View
                        style={{
                          alignItems: 'center',
                          flexDirection: 'row',
                          marginRight: 10,
                          marginLeft: 19
                        }}>
                        <Text
                          style={{
                            fontSize: 18,
                            marginRight: 40,
                            fontFamily: 'NunitoSans-Regular'
                          }}>
                          Tags
                        </Text>
                        <TextInput
                          underlineColorAndroid={Colors.fieldtBgColor}
                          style={styles.textFieldInput2}
                          placeholder={menuItem.materialTag}
                          placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                          onChangeText={(text) => {
                            setState({ materialTag: text });
                          }}
                          value={menuItem.materialTag}
                          multiline={true}
                          ref={myTextInput}

                        />
                      </View>
                    </View>

                    {/* CORRECT ->2001 */}
                    <View
                      style={{
                        borderColor: 'grey',
                        borderWidth: 1,
                        width: 700,
                        padding: 16,
                        borderRadius: 10,
                        marginTop: 20,
                        marginRight: 500,
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          marginTop: 20,
                          marginBottom: 10,


                          alignItems: 'center',
                        }}>
                        <View style={{ width: 100 }}>
                          {/* <DropDownPicker
                          items={[
                            {
                              label: 'Tomato',
                              value: 'tomato',
                            },
                            {
                              label: 'Coffee Bean',
                              value: 'coffeebean',
                            },
                          ]}
                          defaultValue={material.name}
                          placeholder={material.name}
                          containerStyle={{ height: 40 }}
                          style={{ backgroundColor: 'white' }}
                          itemStyle={{
                            justifyContent: 'flex-start',
                          }}
                          dropDownStyle={{ backgroundColor: '#fafafa' }}
                          onChangeItem={(item) =>
                            setState({
                              material: item.value,
                            })
                          }
                        /> */}
                        </View>


                        <View
                          style={{
                            marginLeft: 10,
                            flex: 1,
                          }}>
                          <View style={{ flexDirection: 'row' }}>
                            <View>
                              <Text
                                style={{
                                  textAlign: 'center',
                                  fontFamily: 'NunitoSans-Bold',
                                  marginBottom: 10,
                                }}>
                                Quantity
                              </Text>
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={{
                                  width: 50,
                                  padding: 6,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  marginRight: 5
                                }}
                                placeholder={material.qty}
                                onChangeText={(text) => {
                                  setState({ qty: text });
                                }}
                                //value={qty}
                                multiline={true}
                                ref={myTextInput}
                                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                              />
                            </View>
                            <View style={{ marginRight: 150, }}>
                              <Text
                                style={{
                                  textAlign: 'center',
                                  fontFamily: 'NunitoSans-Bold',
                                  marginBottom: 10,

                                }}>
                                Unit
                              </Text>
                              <View style={{ width: 100 }}>
                                {/* <DropDownPicker
                                items={[
                                  {
                                    label: 'Carton',
                                    value: 'carton',
                                  },
                                  {
                                    label: 'KG',
                                    value: 'kg',
                                  },
                                ]}
                                defaultValue={unit}
                                placeholder="carton"
                                containerStyle={{ height: 40 }}
                                style={{ backgroundColor: '#fafafa' }}
                                itemStyle={{
                                  justifyContent: 'flex-start',
                                }}
                                dropDownStyle={{ backgroundColor: '#fafafa' }}
                                onChangeItem={(item) =>
                                  setState({
                                    unit: item.value,
                                  })
                                }
                              /> */}
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              marginTop: 10,
                              alignItems: 'center',
                              width: 100,
                            }}>
                            <Text style={{ color: 'red', alignSelf: 'center', fontFamily: 'NunitoSans-Regular' }}>
                              Stock Warning Level
                            </Text>
                          </View>
                        </View>

                        {/* second */}

                        <View
                          style={{

                            flex: 1,
                          }}>
                          <View style={{ flexDirection: 'row', marginLeft: 100 }}>
                            <View>
                              <Text
                                style={{
                                  textAlign: 'center',
                                  fontFamily: 'NunitoSans-Bold',
                                  marginBottom: 10,

                                }}>
                                Quantity
                              </Text>
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={{
                                  width: 70,
                                  padding: 6,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  marginRight: 5
                                }}
                                placeholder="Quantity"
                                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                onChangeText={(text) => {
                                  setState({ quantity1: text });
                                }}
                                //value={quantity1}
                                multiline={true}
                                ref={myTextInput}
                              />
                            </View>
                            <View>
                              <Text
                                style={{
                                  textAlign: 'center',
                                  fontFamily: 'NunitoSans-Bold',
                                  marginBottom: 10,
                                }}>
                                Unit
                              </Text>
                              <View style={{ width: 90 }}>
                                {/* <DropDownPicker
                                items={[
                                  {
                                    label: 'Carton',
                                    value: 'carton',
                                  },
                                  {
                                    label: 'KG',
                                    value: 'kg',
                                  },
                                ]}
                                defaultValue={unit2}
                                placeholder="carton"
                                containerStyle={{ height: 40 }}
                                style={{ backgroundColor: '#fafafa' }}
                                itemStyle={{
                                  justifyContent: 'flex-start',
                                }}
                                dropDownStyle={{ backgroundColor: '#fafafa' }}
                                onChangeItem={(item) =>
                                  setState({
                                    unit2: item.value,
                                  })
                                }
                              /> */}
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              marginTop: 10,
                              alignItems: 'center',
                              width: 100,
                              marginLeft: 100
                            }}>
                            <Text style={{ alignSelf: 'center', fontFamily: 'NunitoSans-Regular' }}>
                              Ideal Stock Level
                            </Text>
                          </View>
                        </View>
                        <View
                          style={{
                            flex: 2,
                          }}>
                        </View>

                      </View>




                      {showInventory ? (
                        <View
                          style={{
                            flexDirection: 'row',
                            marginTop: 20,
                            marginBottom: 10,
                            alignItems: 'center',
                          }}>
                          <View style={{ width: 50 }}>
                            {/* <DropDownPicker
                            items={[
                              {
                                label: 'Tomato',
                                value: 'tomato',
                              },
                              {
                                label: 'Coffee Bean',
                                value: 'coffeebean',
                              },
                            ]}
                            defaultValue={material.name}
                            placeholder={material.name}
                            containerStyle={{ height: 40 }}
                            style={{ backgroundColor: 'white' }}
                            itemStyle={{
                              justifyContent: 'flex-start',
                            }}
                            dropDownStyle={{ backgroundColor: '#fafafa' }}
                            onChangeItem={(item) =>
                              setState({
                                material: item.value,
                              })
                            }
                          /> */}
                          </View>
                          <View
                            style={{
                              marginLeft: 100,
                              marginRight: 100,
                              flex: 1,
                            }}>
                            <View style={{ flexDirection: 'row' }}>
                              <View>
                                <Text
                                  style={{
                                    textAlign: 'center',
                                    fontFamily: 'NunitoSans-Bold',
                                    marginBottom: 10,
                                  }}>
                                  Quantity
                                </Text>
                                <TextInput
                                  underlineColorAndroid={Colors.fieldtBgColor}
                                  style={styles.textInput1}
                                  placeholder={material.qty}
                                  onChangeText={(text) => {
                                    setState({ qty: text });
                                  }}
                                  placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                  //value={qty}
                                  multiline={true}
                                  ref={myTextInput}
                                />
                              </View>
                              <View>
                                <Text
                                  style={{
                                    textAlign: 'center',
                                    fontFamily: 'NunitoSans-Bold',
                                    marginBottom: 10,
                                  }}>
                                  Unit
                                </Text>
                                <View style={{ width: 70 }}>
                                  {/* <DropDownPicker
                                  items={[
                                    {
                                      label: 'Carton',
                                      value: 'carton',
                                    },
                                    {
                                      label: 'KG',
                                      value: 'kg',
                                    },
                                  ]}
                                  defaultValue={unit}
                                  placeholder="carton"
                                  containerStyle={{ height: 40 }}
                                  style={{ backgroundColor: '#fafafa' }}
                                  itemStyle={{
                                    justifyContent: 'flex-start',
                                  }}
                                  dropDownStyle={{ backgroundColor: '#fafafa' }}
                                  onChangeItem={(item) =>
                                    setState({
                                      unit: item.value,
                                    })
                                  }
                                /> */}
                                </View>
                              </View>
                            </View>
                            <View
                              style={{
                                marginTop: 10,
                                alignItems: 'center',
                                width: 200,
                              }}>
                              <Text style={{ color: 'red', alignSelf: 'center', fontFamily: 'NunitoSans-Regular' }}>
                                Stock Warning Level
                              </Text>
                            </View>
                          </View>
                          {/* second */}
                          <View
                            style={{

                              flex: 1,
                            }}>
                            <View style={{ flexDirection: 'row' }}>
                              <View>
                                <Text
                                  style={{
                                    textAlign: 'center',
                                    fontFamily: 'NunitoSans-Bold',
                                    marginBottom: 10,
                                    padding: 5
                                  }}>
                                  Quantity
                                </Text>
                                <View style={{ marginRight: 5 }}>
                                  <TextInput
                                    underlineColorAndroid={Colors.fieldtBgColor}
                                    style={styles.textInput1}
                                    placeholder="Quantity"
                                    onChangeText={(text) => {
                                      setState({ quantity1: text });
                                    }}
                                    placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                    //value={quantity1}
                                    multiline={true}
                                    ref={myTextInput}
                                  />
                                </View>
                              </View>
                              <View>
                                <Text
                                  style={{
                                    textAlign: 'center',
                                    fontFamily: 'NunitoSans-Bold',
                                    marginBottom: 10,
                                  }}>
                                  Unit
                                </Text>
                                <View style={{ width: 100 }}>
                                  {/* <DropDownPicker
                                  items={[
                                    {
                                      label: 'Carton',
                                      value: 'carton',
                                    },
                                    {
                                      label: 'KG',
                                      value: 'kg',
                                    },
                                  ]}
                                  defaultValue={unit2}
                                  placeholder="carton"
                                  containerStyle={{ height: 40 }}
                                  style={{ backgroundColor: '#fafafa' }}
                                  itemStyle={{
                                    justifyContent: 'flex-start',
                                  }}
                                  dropDownStyle={{ backgroundColor: '#fafafa' }}
                                  onChangeItem={(item) =>
                                    setState({
                                      unit2: item.value,
                                    })
                                  }
                                /> */}
                                </View>

                              </View>
                            </View>
                            <View
                              style={{
                                marginTop: 10,
                                alignItems: 'center',
                                width: 200,

                              }}>
                              <Text style={{ alignSelf: 'center', fontFamily: 'NunitoSans-Regular' }}>
                                Ideal Stock Level
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flex: 2,
                            }}></View>
                        </View>
                      ) : null}
                    </View>


                  </View>
                  <View
                    style={{
                      backgroundColor: Colors.primaryColor,
                      width: 200,
                      height: 40,
                      marginLeft: 20,
                      marginVertical: 15,
                      borderRadius: 5,
                      alignSelf: 'center',
                    }}>

                    <TouchableOpacity
                      onPress={() => {
                        editItem();
                      }}>
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          alignSelf: 'center',
                          marginVertical: 10,
                          fontFamily: 'NunitoSans-Regular'
                        }}>
                        Save
                      </Text>
                    </TouchableOpacity>
                  </View>
                </ScrollView>

                {/* <View
              style={{
                flexDirection: 'row',
                backgroundColor: '#ffffff',
                justifyContent: 'center',
                padding: 0,
                alignSelf: 'center'
              }}>
              <View style={{ alignItems: 'center' }}>
                <Text style={{ fontSize: 30, fontFamily: 'NunitoSans-Bold' }}>
                  {productList.length}
                </Text>
                <Text>Product</Text>
              </View>
              <View style={{ alignItems: 'center', marginLeft: 50 }}>
                <Text style={{ fontSize: 30, fontFamily: 'NunitoSans-Bold' }}>
                  {menuCount}
                </Text>
                <Text>Menu</Text>
              </View>
            </View> */}
              </View>
            ) : null}


            {addProductItem ? (
              <View style={{
                // height: Platform.OS == 'ios' ? Dimensions.get('window').height * 0.75 : Dimensions.get('window').height * 0.60,
                height: '100%',
                width: Dimensions.get('window').width * 0.877,
                // marginLeft: 5,              
              }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: -25, marginBottom: -2, marginLeft: 20, }}>
                  <TouchableOpacity style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', paddingLeft: Dimensions.get('window').width * 0.009 }}
                    onPress={() => {
                      linkTo && linkTo(`${prefix}/productcategory`);
                    }}>
                    <Feather name='chevron-left' size={30} style={{ color: Colors.primaryColor, alignSelf: 'center' }} />
                    <Text style={{ fontFamily: 'Nunitosans-Bold', color: Colors.primaryColor, fontSize: 17, marginBottom: Platform.OS === 'ios' ? 0 : 1 }}>Back</Text>
                  </TouchableOpacity>

                  <View style={{
                    marginBottom: 10,
                    //marginLeft: 10,
                    width: 250,
                    height: 40,
                    backgroundColor: 'white',
                    borderRadius: 5,
                    // marginLeft: '53%',
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',

                    marginRight: -9,

                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  }}>
                    <Icon name="search" size={18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />
                    <TextInput
                      editable={!loading}
                      underlineColorAndroid={Colors.whiteColor}
                      style={{
                        width: 220,
                        fontSize: 15,
                        fontFamily: 'NunitoSans-Regular',
                        paddingLeft: 5,
                      }}
                      clearButtonMode="while-editing"
                      placeholder=" Search"
                      placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                      onChangeText={(text) => {
                        setSearch(text.trim());
                        setProductlist1(false);
                        setSearchList(true);
                      }}
                      value={search}
                    />
                  </View>

                </View>
                <View style={{
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 10,
                  width: windowWidth * 0.8575,
                  minHeight: Dimensions.get('screen').height * 0.01,
                  marginTop: 30,
                  marginHorizontal: 48,
                  marginBottom: 30,
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}>
                  <View
                    style={{
                      backgroundColor: Colors.whiteColor,
                      flexDirection: 'row',
                      paddingVertical: 20,
                      paddingHorizontal: 5,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      borderTopLeftRadius: 8,
                      borderTopRightRadius: 8,

                      alignItems: 'center',
                    }}
                  >
                    <Text style={{ color: Colors.blackColor, width: '8.5%', alignSelf: 'center', fontFamily: 'NunitoSans-Bold', textAlign: 'left', }}>

                    </Text>
                    <Text style={{ fontSize: 14, color: Colors.blackColor, width: '15.5%', alignSelf: 'flex-start', fontFamily: 'NunitoSans-Bold', textAlign: 'left' }}>
                      SKU
                    </Text>
                    <Text style={{ fontSize: 14, color: Colors.blackColor, width: '25.5%', alignSelf: 'flex-start', fontFamily: 'NunitoSans-Bold', textAlign: 'left' }}>
                      Product Name
                    </Text>
                    {/* <Text style={{ color: Colors.blackColor, width: '15%', alignSelf: 'center', fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>
                Category
              </Text> */}
                    <Text style={{ fontSize: 14, color: Colors.blackColor, width: '22%', alignSelf: 'flex-start', fontFamily: 'NunitoSans-Bold', textAlign: 'left' }}>
                      Description
                    </Text>
                    <Text style={{ fontSize: 14, color: Colors.blackColor, width: '10.8%', alignSelf: 'flex-start', fontFamily: 'NunitoSans-Bold', textAlign: 'left' }}>
                      Price
                    </Text>
                    <Text style={{ fontSize: 14, color: Colors.blackColor, width: '14%', alignSelf: 'flex-start', fontFamily: 'NunitoSans-Bold', textAlign: 'left' }}>
                      Status
                    </Text>
                    {/* <Text style={{ color: Colors.blackColor, width: '4%', alignSelf: 'center', fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>
                Tags
              </Text> */}
                  </View>

                  <ScrollView
                  // showsVerticalScrollIndicator={false}
                  // style={{
                  //   // backgroundColor: 'red',
                  //   //paddingTop: 10,

                  //   // height: Dimensions.get('window').height * 0.63,


                  // // }} contentContainerStyle={{
                  //   //paddingBottom: 100,

                  //   // height: Dimensions.get('window').height,
                  // }}
                  >

                    <FlatList
                      data={outletItems.filter(outletItem => {
                        if (search !== '') {
                          const searchLowerCase = search.toLowerCase();

                          if (outletItem.name.toLowerCase().includes(searchLowerCase)) {
                            return true;
                          }
                          else if (outletItem.description.toLowerCase().includes(searchLowerCase)) {
                            return true;
                          }
                          else if ((outletItem.price.toString()).toLowerCase().includes(searchLowerCase)) {
                            return true;
                          }
                          else if (outletItem.skuMerchant !== undefined && outletItem.skuMerchant.toLowerCase().includes(searchLowerCase)) {
                            return true;
                          }
                          // else if (.toLowerCase().includes(searchLowerCase)) {
                          //   return true;
                          // }
                          else {
                            return false;
                          }
                        }
                        else {
                          return true;
                        }
                      })}
                      // extraData={outletItems}
                      renderItem={renderItem}
                      keyExtractor={(item, index) => String(index)}
                      initialNumToRender={6}
                    />

                  </ScrollView>
                </View>

                {/* {productlist1 ? (

              <FlatList
                data={productList}
                extraData={productList}
                renderItem={renderItem}
                keyExtractor={(item, index) => String(index)}
                initialNumToRender={6}
              />

            ) : null}


            {searchList ? (

              <FlatList
                data={lists}
                extraData={lists}
                renderItem={renderSearchItem}
                keyExtractor={(item, index) => String(index)}
              />

            ) : null} */}

                {/* <View
              style={{
                flexDirection: 'row',
                backgroundColor: '#ffffff',
                justifyContent: 'center',
                paddingBottom: 25,
              }}>
              <View style={{ alignItems: 'center' }}>
                <Text style={{ fontSize: 30, fontFamily: 'NunitoSans-Bold' }}>
                  {productList.length}
                </Text>
                <Text>Product</Text>
              </View>

            </View> */}

                {/* <View style={styles.footer}>
              <View style={{
                alignItems: 'center',
                width: '100%',
                // backgroundColor: 'red',
                justifyContent: 'center',

                marginLeft: -Dimensions.get('window').width * Styles.sideBarWidth * 0.5,
              }}>
                <Text style={{ fontSize: 36, fontFamily: 'NunitoSans-SemiBold', opacity: 0.9, marginLeft: -2 }}>
                  {outletItems.filter(outletItem => {
                    if (search !== '') {
                      const searchLowerCase = search.toLowerCase();

                      if (outletItem.name.toLowerCase().includes(searchLowerCase)) {
                        return true;
                      }
                      else {
                        return false;
                      }
                    }
                    else {
                      return true;
                    }
                  }).length}
                </Text>
                <Text style={{
                  fontSize: 14,
                  color: Colors.blackColor,
                  fontFamily: 'NunitoSans-SemiBold',
                  opacity: 0.7,
                }}>VOUCHERS</Text>
              </View>
            </View> */}
              </View>
            ) : null}




          </View>
        </ScrollView>
      </View>
      <Modal
        style={{ flex: 1 }}
        visible={visible1}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        animationType="slide">
        <View
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: Dimensions.get('window').height,
          }}>
          <View style={styles.confirmBox1}>
            <TouchableOpacity
              onPress={() => {
                setState({ visible1: false });
              }}>
              <View
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  alignSelf: 'flex-end',
                  height: 20,
                }}>
                {/* <Close name="closecircle" size={25} /> */}
                <AntDesign name="closecircle" size={25} color={Colors.fieldtTxtColor} />
              </View>
            </TouchableOpacity>
            <View
              style={{
                alignSelf: 'center',
                marginTop: 20,
                justifyContent: 'center',
                alignItems: 'center',
                width: '70%',
                height: '80%',
                alignContent: 'center',
              }}>
              <Text style={{ fontSize: 30, fontFamily: 'NunitoSans-Bold' }}>Single</Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 10,
                  alignContent: 'space-between',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 50,
                  width: '100%',
                }}>
                <Text
                  style={{
                    fontSize: 25,
                    fontWeight: 'bold',
                    marginRight: '10%',
                  }}>
                  Title
                </Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  style={{
                    width: 140,
                    padding: 10,
                    backgroundColor: Colors.fieldtBgColor,
                    borderRadius: 5,
                  }}
                  placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                  placeholder="Category"
                  onChangeText={(text) => {
                    setState({ options: text });
                  }}
                  value={options}
                  multiline={true}
                  ref={myTextInput}
                />
              </View>
              <View
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  marginLeft: '90%',
                }}>
                <TouchableOpacity
                  onPress={() => {
                    onadditem();
                  }}
                  style={{
                    backgroundColor: Colors.whiteColor,
                    alignItems: 'center',
                  }}>
                  <Icon
                    name="plus-circle"
                    size={20}
                    color={Colors.primaryColor}
                  />
                </TouchableOpacity>
              </View>
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ width: '100%', height: '50%' }}>
                {renderOptions()}
              </ScrollView>
              <View style={{ marginTop: 10, width: '100%' }}>
                <TouchableOpacity
                  disabled={loading}
                // onPress={() => {
                //   setState({
                //     productOptions,
                //     visible: false,
                //     visible1: false,
                //     visible2: false,
                //   });
                // }}
                >
                  <View style={styles.button}>
                    <Text style={{ color: '#ffffff', fontSize: 18 }}>
                      {loading ? 'LOADING...' : 'Save'}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        style={{ flex: 1 }}
        visible={visible2}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        animationType="slide">
        <View
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: Dimensions.get('window').height,
          }}>
          <View style={styles.confirmBox1}>
            <TouchableOpacity
              onPress={() => {
                setState({ visible2: false });
              }}>
              <View
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  alignSelf: 'flex-end',
                  height: 20,
                }}>
                {/* <Close name="closecircle" size={25} /> */}
                <AntDesign name="closecircle" size={25} color={Colors.fieldtTxtColor} />
              </View>
            </TouchableOpacity>
            <View
              style={{
                alignSelf: 'center',
                marginTop: 5,
                alignItems: 'center',
                width: '80%',
                height: '80%',
                alignContent: 'center',
              }}>
              <Text style={{ fontSize: 30, fontFamily: 'NunitoSans-Bold', marginTop: 20 }}>
                Multiple
              </Text>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 10,
                  alignContent: 'space-between',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 50,
                  width: '100%',
                }}>
                <Text
                  style={{
                    fontSize: 25,
                    fontFamily: 'NunitoSans-Bold',
                    marginRight: '10%',
                  }}>
                  Title
                </Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  style={{
                    width: 140,
                    padding: 10,
                    backgroundColor: Colors.fieldtBgColor,
                    borderRadius: 5,
                  }}
                  placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                  placeholder="Category"
                  onChangeText={(text) => {
                    setState({ options: text });
                  }}
                  value={options}
                  multiline={true}
                  ref={myTextInput}
                />
              </View>
              <View
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  marginLeft: '90%',
                }}>
                <TouchableOpacity
                  onPress={() => {
                    onadditem1();
                  }}
                  style={{
                    backgroundColor: Colors.whiteColor,
                    alignItems: 'center',
                  }}>
                  <Icon
                    name="plus-circle"
                    size={20}
                    color={Colors.primaryColor}
                  />
                </TouchableOpacity>
              </View>
              <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ width: '100%', height: '50%' }}>
                {renderOptions1()}
              </ScrollView>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: 10,
                  alignContent: 'space-between',
                  height: 50,
                  width: '100%',
                }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: 'NunitoSans-Bold',
                    marginRight: '7%',
                  }}>
                  Price :
                </Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  style={{
                    width: 100,
                    padding: 10,
                    backgroundColor: Colors.fieldtBgColor,
                    borderRadius: 5,
                    marginLeft: '3%',
                  }}
                  placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                  placeholder="Price"
                  onChangeText={(text) => {
                    setState({ price1: text });
                  }}
                  value={price1}
                  multiline={true}
                  ref={myTextInput}
                />
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  per toppings
                </Text>
              </View>
              <View style={{ marginTop: 10, width: '100%' }}>
                <TouchableOpacity
                  disabled={loading}
                // onPress={() => {
                //   setState({
                //     productOptions,
                //     visible: false,
                //     visible1: false,
                //     visible2: false,
                //   });
                // }}
                >
                  <View style={styles.button}>
                    <Text style={{ color: '#ffffff', fontSize: 18 }}>
                      {loading ? 'LOADING...' : 'Save'}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  confirmBox: {
    width: '30%',
    height: '30%',
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  confirmBox1: {
    width: '30%',
    height: '60%',
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Colors.highlightColor
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: 'row',
    alignItems: 'center',
  },
  list1: {
    backgroundColor: Colors.whiteColor,
    width: Dimensions.get('window').width * 0.87,
    minHeight: Dimensions.get('window').height * 0.01,
    marginTop: 10,
    marginHorizontal: 30,
    marginBottom: 30,
    alignSelf: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  list1_PhoneAdjustment: {
    backgroundColor: Colors.whiteColor,
    width: Dimensions.get('window').width * 0.79,
    minHeight: Dimensions.get('window').height * 0.01,
    marginTop: 0,
    marginHorizontal: 30,
    marginBottom: 30,
    alignSelf: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    //width: Dimensions.get('window').width * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 12,
    width: Dimensions.get('window').width * 0.8,
    // width: Dimensions.get('window').width * (1 - Styles.sideBarWidth),
  },
  content_PhoneAdjustment: {
    padding: 16,
    width: Dimensions.get('window').width * (1 - Styles.sideBarWidth),
    // width: Dimensions.get('window').width * (1 - Styles.sideBarWidth),
  },
  content1: {
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#c4c4c4',
  },
  content2: {
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  content3: {
    backgroundColor: Colors.highlightColor,
    width: 40,
    height: 40,
    borderRadius: 20,
    alignSelf: 'flex-end',
    position: 'absolute',
    right: -15,
    marginVertical: -10,
  },
  content4: {
    backgroundColor: Colors.highlightColor,
    width: 120,
    height: 20,
    borderRadius: 20,
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  content5: {
    backgroundColor: Colors.whiteColor,
    width: 140,
    height: 140,
    borderRadius: 5,
    marginLeft: 40,
    borderStyle: 'dashed',
    borderWidth: 2,
    alignItems: 'center',
    borderColor: Colors.primaryColor,
  },

  content6: {
    backgroundColor: '#717378',
    width: 40,
    height: 40,
    marginLeft: 20,
    marginVertical: 10,
    borderRadius: 5,
  },

  searchBar: {
    marginHorizontal: 16,
    backgroundColor: Colors.fieldtBgColor,
    flexDirection: 'row',
    padding: 12,
    borderRadius: 10,
    alignContent: 'center',
  },
  submitText: {
    height: 40,
    paddingHorizontal: 20,
    flexDirection: 'row',
    color: '#4cd964',
    textAlign: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  detailContainer: {
    flexDirection: 'row',
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginTop: 20,
    borderBottomColor: Colors.fieldtTxtColor,
    justifyContent: 'space-between',
  },
  tableIcon: {
    // backgroundColor: '#717378',
    width: (Dimensions.get('window').width - 550) / 4,
    height: (Dimensions.get('window').width - 550) / 4,
    borderRadius: 10,
    marginLeft: 30,
    marginBottom: 20,
    justifyContent: 'center',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: Colors.fieldtTxtColor,
  },
  category: {
    width: 150,
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoTab: {

    backgroundColor: Colors.highlightColor,
    width: Dimensions.get('window').width * 0.25,
    height: -100,
  },
  textFieldInput: {
    backgroundColor: Colors.fieldtBgColor,
    width: 250,
    height: 60,
    borderRadius: 5,
    padding: 5,
    marginVertical: 5,
    borderWidth: 1,
    borderColor: '#E5E5E5',
    paddingLeft: 10,
  },
  textFieldInput1: {
    height: 50,
    width: 200,
    paddingHorizontal: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  textInventory: {
    height: 50,
    width: 100,
    paddingHorizontal: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  textFieldInput2: {
    height: 50,
    width: 270,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 0,
  },
  textInput: {
    width: 100,
    height: 40,
    // padding:5,
    backgroundColor: Colors.whiteColor,
    borderRadius: 0,
    marginRight: '32%',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',

    shadowOffset: Platform.OS == 'ios' ? {
      width: 0,
      height: 0,
    } : {
      width: 0,
      height: 7,
    },
    shadowOpacity: Platform.OS == 'ios' ? 0 : 0.43,
    shadowRadius: Platform.OS == 'ios' ? 0 : 0.51,
    elevation: 15,
  },
  textPrice: {
    width: 100,
    padding: 10,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    paddingTop: 10,
  },
  textTax: {
    width: 100,
    padding: 10,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    paddingTop: 10,
  },
  textInput1: {
    width: 100,
    padding: 6,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  button: {
    width: '50%',
    height: 50,
    backgroundColor: Colors.primaryColor,
    borderRadius: 10,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInput4: {
    width: 200,
    height: 50,
    borderRadius: 10,
    marginRight: '41%',
  },
  itemText: {
    margin: 10,
    color: 'white',
    fontSize: 24,
    backgroundColor: 'blue',
    width: '100%',
    height: 50,
  },
  confirmMenu: {
    width: '70%',
    height: '70%',
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  searchIcon: {
    backgroundColor: Colors.whiteColor,
    height: 40,
    padding: 10,
    shadowOffset: Platform.OS == 'ios' ? {
      width: 0,
      height: 0,
    } : {
      width: 0,
      height: 7,
    },
    shadowOpacity: Platform.OS == 'ios' ? 0 : 0.43,
    shadowRadius: Platform.OS == 'ios' ? 0 : 9.51,

    elevation: 15,
  },
  searchView: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    flexDirection: 'row',
  },
  headerLogo: {
    width: 100,
    height: 25,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: 'center'
  },

  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    // alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 20,
    paddingTop: 15,
    marginTop: 0,
    width: '115%',

    marginLeft: '-5%',
    bottom: '-15%',

    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 7,
  },
  headerLeftStyle: {
    width: Dimensions.get('window').width * 0.17,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalView: {
    height: Dimensions.get('window').width * 0.2,
    width: Dimensions.get('window').width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get('window').width * 0.03,
    alignItems: 'center',
    justifyContent: 'center'
  },
  closeButton: {
    position: 'absolute',
    right: Dimensions.get('window').width * 0.02,
    top: Dimensions.get('window').width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: 'center',
    top: '20%',
    position: 'absolute',
  },
  modalBody: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'

  },
  modalTitleText: {
    fontFamily: 'NunitoSans-Bold',
    textAlign: 'center',
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 18,
    color: Colors.fieldtTxtColor
  },
  modalBodyText: {
    flex: 1,
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    width: Dimensions.get('window').width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
});
export default ProductScreen;
