import React, { Component, useReducer, useState, useEffect, useRef } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Switch,
  Modal,
  KeyboardAvoidingView,
  TextInput,
  ActivityIndicator,
  Picker,
  useWindowDimensions
} from 'react-native';
import Colors from '../constant/Colors';
import SideBar from './SideBar';
import Icon from 'react-native-vector-icons/Feather';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { ReactComponent as GCalendar } from '../assets/svg/GCalendar.svg';
import Entypo from 'react-native-vector-icons/Entypo';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";

import EvilIcons from 'react-native-vector-icons/EvilIcons';
import { FlatList } from 'react-native-gesture-handler';
import API from '../constant/API';
import ApiClient from '../util/ApiClient';
import * as User from '../util/User';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import CheckBox from 'react-native-check-box';
import moment from 'moment';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import Styles from '../constant/Styles';
// import { isTablet } from 'react-native-device-detection';
import { OutletStore } from '../store/outletStore';
import { MerchantStore } from '../store/merchantStore';
import { UserStore } from '../store/userStore';
// import Upload from '../assets/svg/Upload';
// import Download from '../assets/svg/Download';
// import DropDownPicker from 'react-native-dropdown-picker';
// import RNPickerSelect from 'react-native-picker-select';
// import RNFetchBlob from 'rn-fetch-blob';
import {
  convertArrayToCSV,
  generateEmailReport,
  sortReportDataList,
} from '../util/common';
import {
  EMAIL_REPORT_TYPE,
  OFFLINE_PAYMENT_METHOD_TYPE,
  PAYMENT_CHANNEL_NAME_PARSED,
  REPORT_SORT_FIELD_TYPE,
  TABLE_PAGE_SIZE_DROPDOWN_LIST,
  ORDER_TYPE,
} from '../constant/common';
// import { useKeyboard } from '../hooks';
import XLSX from 'xlsx';
import 'react-native-get-random-values';
import { CommonStore } from '../store/commonStore';
import AsyncImage from '../components/asyncImage';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
import { CSVLink } from "react-csv";

const { nanoid } = require('nanoid');
// const RNFS = require('react-native-fs');

const REPORT_TABLE_SECTION = {
  MAIN_PAGE: 'REPORT_TABLE_SECTION.MAIN_PAGE',
  SUMMARY_PAGE: 'REPORT_TABLE_SECTION.SUMMARY_PAGE',
  DETAILS_PAGE: 'REPORT_TABLE_SECTION.DETAILS_PAGE',
};

const ReportSalesPaymentMethod = (props) => {
  const { navigation } = props;

  const {
    width: windowWidth,
    height: windowHeight,
  } = useWindowDimensions();

  // const [keyboardHeight] = useKeyboard();
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [name, setName] = useState('Payment Method');
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [choice, setChoice] = useState('All');
  const [endDate, setEndDate] = useState(Date().toLocaleString());
  const [startDate, setStartDate] = useState(Date().toLocaleString());
  const [oriList, setOriList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [detailsPageCount, setDetailsPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDetailsPage, setCurrentDetailsPage] = useState(1);
  const [pageReturn, setPageReturn] = useState(1);
  const [day, setDay] = useState(false);
  const [pick, setPick] = useState(null);
  const [pick1, setPick1] = useState(null);
  const [group, setGroup] = useState(false);
  const [search, setSearch] = useState('');
  const [pushPagingToTop, setPushPagingToTop] = useState(false);
  const [switchMerchant, setSwitchMerchant] = useState(false);

  const [loading, setLoading] = useState(false);
  const [controller, setController] = useState({});

  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
  const [pickerMode, setPickerMode] = useState('datetime');
  const [rev_date, setRev_date] = useState(moment().subtract(6, 'days').startOf('day'));
  const [rev_date1, setRev_date1] = useState(moment().endOf(Date.now()).endOf('day'));

  const [paymentMethodSales, setPaymentMethodSales] = useState([]);

  const [expandDetailsDict, setExpandDetailsDict] = useState({});
  const crmUsers = OutletStore.useState((s) => s.crmUsers);

  const allOutletsUserOrdersDone = OutletStore.useState(
    (s) => s.allOutletsUserOrdersDone,
  );

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  const [exportEmail, setExportEmail] = useState('');
  const [CsvData, setCsvData] = useState([]);

  const [showDetails, setShowDetails] = useState(false);
  const [transactionTypeSalesDetails, setTransactionTypeSalesDetails] =
    useState([]);

  const [selectedItemSummary, setSelectedItemSummary] = useState({});
  const [selectedItemMainPage, setSelectedItemMainPage] = useState({});
  const [offlinePaymentClicked, setOfflinePaymentClicked] = useState(false);

  const [exportModalVisibility, setExportModalVisibility] = useState(false);

  const [transactionTypeSales, setTransactionTypeSales] = useState([]);

  const [currReportSummarySort, setCurrReportSummarySort] = useState('');
  const [currReportDetailsSort, setCurrReportDetailsSort] = useState('');

  const merchantId = UserStore.useState((s) => s.merchantId);
  const isLoading = CommonStore.useState((s) => s.isLoading);
  const [isCsv, setIsCsv] = useState(false);
  const [isExcel, setIsExcel] = useState(false);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView,
  );

  const [paymentMethodMainPage, setPaymentMethodMainPage] = useState('');
  const [showReportTableSection, setShowReportTableSection] = useState(
    REPORT_TABLE_SECTION.MAIN_PAGE,
  );

  useEffect(() => {
    if (currOutletId !== '' && allOutlets.length > 0) {
      var paymentMethodSalesDict = {
        // 'ONLINE_TRANSFER': {
        //     summaryId: nanoid(),
        //     paymentMethod: 'Online transfer',
        //     totalSales: 0,
        //     totalTransactions: 0,
        //     netSales: 0,
        //     detailsList: [],
        // },
        OFFLINE_PAYMENT: {
          summaryId: nanoid(),
          paymentMethod: PAYMENT_CHANNEL_NAME_PARSED['Offline-Cash'] + ' (N/A)',
          channel: 'Offline-Cash',
          // paymentMethod: 'Offline Payment',
          // channel: 'OFFLINE_PAYMENT',
          totalSales: 0,
          totalTransactions: 0,
          totalOrder: 0,
          totalDiscount: 0,
          netSales: 0,
          detailsList: [],
        },
      };

      for (var i = 0; i < allOutletsUserOrdersDone.length; i++) {
        if (
          moment(rev_date).isSameOrBefore(
            allOutletsUserOrdersDone[i].completedDate,
          ) &&
          moment(rev_date1).isAfter(allOutletsUserOrdersDone[i].completedDate)
        ) {
          if (
            allOutletsUserOrdersDone[i].paymentDetails &&
            allOutletsUserOrdersDone[i].paymentDetails.channel
          ) {
            // means is razerpay

            const channelName =
              allOutletsUserOrdersDone[i].paymentDetails.channel;

            if (paymentMethodSalesDict[channelName] === undefined) {
              // initialize first

              paymentMethodSalesDict[channelName] = {
                summaryId: nanoid(),
                paymentMethod: PAYMENT_CHANNEL_NAME_PARSED[channelName]
                  ? PAYMENT_CHANNEL_NAME_PARSED[channelName]
                  : channelName,
                channel: channelName,
                totalSales: 0,
                totalTransactions: 0,
                totalOrder: 0,
                totalDiscount: 0,
                netSales: 0,
                detailsList: [],
              };
            }

            paymentMethodSalesDict[channelName].totalTransactions += 1;
            paymentMethodSalesDict[channelName].totalOrder += 1;
            paymentMethodSalesDict[channelName].netSales +=
              allOutletsUserOrdersDone[i].finalPrice;
            paymentMethodSalesDict[channelName].totalSales +=
              allOutletsUserOrdersDone[i].totalPrice;
            paymentMethodSalesDict[channelName].totalDiscount +=
              allOutletsUserOrdersDone[i].discount;
            // paymentMethodSalesDict[channelName].netSales += allOutletsUserOrdersDone[i].totalPrice;
            // paymentMethodSalesDict['ONLINE_TRANSFER'].detailsList.push(allOutletsUserOrdersDone[i]);
            paymentMethodSalesDict[channelName].detailsList.push({
              ...allOutletsUserOrdersDone[i],
              discountPercentage: parseFloat(
                isFinite(
                  allOutletsUserOrdersDone[i].discount /
                  allOutletsUserOrdersDone[i].totalPrice,
                )
                  ? (allOutletsUserOrdersDone[i].discount /
                    allOutletsUserOrdersDone[i].totalPrice) *
                  100
                  : 0,
              ),
            });
          } else {
            paymentMethodSalesDict['OFFLINE_PAYMENT'].totalTransactions += 1;
            paymentMethodSalesDict['OFFLINE_PAYMENT'].totalOrder += 1;
            paymentMethodSalesDict['OFFLINE_PAYMENT'].netSales +=
              allOutletsUserOrdersDone[i].finalPrice;
            paymentMethodSalesDict['OFFLINE_PAYMENT'].totalSales +=
              allOutletsUserOrdersDone[i].totalPrice;
            paymentMethodSalesDict['OFFLINE_PAYMENT'].totalDiscount +=
              allOutletsUserOrdersDone[i].discount;
            // paymentMethodSalesDict['OFFLINE_PAYMENT'].detailsList.push(allOutletsUserOrdersDone[i]);
            paymentMethodSalesDict['OFFLINE_PAYMENT'].detailsList.push({
              ...allOutletsUserOrdersDone[i],
              discountPercentage: parseFloat(
                isFinite(
                  allOutletsUserOrdersDone[i].discount /
                  allOutletsUserOrdersDone[i].totalPrice,
                )
                  ? (allOutletsUserOrdersDone[i].discount /
                    allOutletsUserOrdersDone[i].totalPrice) *
                  100
                  : 0,
              ),
            });
          }
        }
      }

      const paymentMethodSalesTemp = Object.entries(paymentMethodSalesDict).map(
        ([key, value]) => ({ ...value }),
      );

      setPaymentMethodSales(paymentMethodSalesTemp);

      //setCurrentPage(1);
      setPageCount(Math.ceil(paymentMethodSalesTemp.length / perPage));

      setShowDetails(false);

      const dummyData = ['Download Test'];

      setState({ CsvData: dummyData });
    }
  }, [
    allOutletsUserOrdersDone,
    currOutletId,
    allOutlets,
    rev_date,
    rev_date1,
    perPage,
  ]);

  useEffect(() => {
    if (currOutletId !== '' && allOutlets.length > 0) {
      var paymentMethodSalesDict = {
        ONLINE_TRANSFER: {
          summaryId: nanoid(),
          mainPaymentMethod: 'Online transfer',
          totalSales: 0,
          totalTransactions: 0,
          netSales: 0,
          detailsList: [],
        },
        OFFLINE_PAYMENT: {
          summaryId: nanoid(),
          mainPaymentMethod: 'Offline payment',
          totalSales: 0,
          totalTransactions: 0,
          totalDiscount: 0,
          netSales: 0,
          detailsList: [],
        },
      };

      for (var i = 0; i < allOutletsUserOrdersDone.length; i++) {
        if (
          moment(rev_date).isSameOrBefore(
            allOutletsUserOrdersDone[i].completedDate,
          ) &&
          moment(rev_date1).isAfter(allOutletsUserOrdersDone[i].completedDate)
        ) {
          if (
            allOutletsUserOrdersDone[i].paymentDetails &&
            allOutletsUserOrdersDone[i].paymentDetails.channel &&
            !allOutletsUserOrdersDone[i].paymentDetails.channel.startsWith('Offline')
            // allOutletsUserOrdersDone[i].paymentDetails.channel !==
            // 'Offline-Credit-Card' &&
            // allOutletsUserOrdersDone[i].paymentDetails.channel !==
            // 'Offline-PayPal' &&
            // allOutletsUserOrdersDone[i].paymentDetails.channel !==
            // 'Offline-GrabPay' &&
            // allOutletsUserOrdersDone[i].paymentDetails.channel !==
            // 'Offline-Cash'
          ) {
            // means is razerpay

            paymentMethodSalesDict['ONLINE_TRANSFER'].totalTransactions += 1;
            paymentMethodSalesDict['ONLINE_TRANSFER'].totalSales +=
              allOutletsUserOrdersDone[i].finalPrice;
            paymentMethodSalesDict['ONLINE_TRANSFER'].netSales +=
              allOutletsUserOrdersDone[i].totalPrice;
            // paymentMethodSalesDict['ONLINE_TRANSFER'].detailsList.push(allOutletsUserOrdersDone[i]);
            paymentMethodSalesDict['ONLINE_TRANSFER'].detailsList.push({
              ...allOutletsUserOrdersDone[i],
              discountPercentage: parseFloat(
                isFinite(
                  allOutletsUserOrdersDone[i].discount /
                  allOutletsUserOrdersDone[i].totalPrice,
                )
                  ? (allOutletsUserOrdersDone[i].discount /
                    allOutletsUserOrdersDone[i].totalPrice) *
                  100
                  : 0,
              ),
            });
          } else {
            paymentMethodSalesDict['OFFLINE_PAYMENT'].totalTransactions += 1;
            paymentMethodSalesDict['OFFLINE_PAYMENT'].totalSales +=
              allOutletsUserOrdersDone[i].finalPrice;
            paymentMethodSalesDict['OFFLINE_PAYMENT'].netSales +=
              allOutletsUserOrdersDone[i].totalPrice;
            // paymentMethodSalesDict['OFFLINE_PAYMENT'].detailsList.push(allOutletsUserOrdersDone[i]);
            paymentMethodSalesDict['OFFLINE_PAYMENT'].detailsList.push({
              ...allOutletsUserOrdersDone[i],
              discountPercentage: parseFloat(
                isFinite(
                  allOutletsUserOrdersDone[i].discount /
                  allOutletsUserOrdersDone[i].totalPrice,
                )
                  ? (allOutletsUserOrdersDone[i].discount /
                    allOutletsUserOrdersDone[i].totalPrice) *
                  100
                  : 0,
              ),
            });
          }
        }
      }

      const paymentMethodMainPageTemp = Object.entries(
        paymentMethodSalesDict,
      ).map(([key, value]) => ({ ...value }));

      setPaymentMethodMainPage(paymentMethodMainPageTemp);
    }
  }, [
    allOutletsUserOrdersDone,
    currOutletId,
    allOutlets,
    rev_date,
    rev_date1,
    perPage,
  ]);

  useEffect(() => {
    if (
      showReportTableSection === REPORT_TABLE_SECTION.DETAILS_PAGE &&
      selectedItemSummary.detailsList
    ) {
      setTransactionTypeSalesDetails(selectedItemSummary.detailsList);

      setPageReturn(currentPage);
      console.log('currentPage value is');
      console.log(currentPage);
      setCurrentDetailsPage(1);

      setPageCount(Math.ceil(selectedItemSummary.detailsList.length / perPage));
    }
  }, [showReportTableSection, selectedItemSummary, perPage]);

  const setState = () => { };

  // navigation.dangerouslyGetParent().setOptions({
  //     tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View
        style={{
          width: windowWidth * 0.17,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            // bottom: -2,
            bottom: switchMerchant ? '2%' : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? { right: windowWidth * 0.1 }
            : {},
          windowWidth <= 768
            ? { right: windowWidth * 0.12 }
            : {},
        ]}>
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Payment Report
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: 'white',
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}></View>
        <TouchableOpacity
          onPress={() => navigation.navigate('Setting')}
          style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}>
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: 'center',
              }}
              source={require('../assets/image/profile-pic.jpg')}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // componentDidMount = () => {
  //     moment()
  // }

  /* const email = () => {
        var body = {
            email: 'wongcheehoo@yahoo.com',
            data: list
        }
        ApiClient.POST(API.emailReportPdf, body, false).then((result) => {
            try {
                if (result != null) {
                    Alert.alert(
                        'Congratulation!',
                        'You Have Successfull',
                        [
                            {
                                text: 'OK',
                                onPress: () => { setState({ visible1: false }) },
                            },
                        ],
                        { cancelable: false },
                    );
                }
            } catch (error) {
                Alert.alert('Failed', [{ text: 'OK', onPress: () => { } }], {
                    cancelable: false,
                });
            }
        })
    }

    const download = () => {
        var body = {
            data: list
        }
        ApiClient.POST(API.generateReportPDF, body, false).then((result) => {
            try {
                if (result != null) {
                    Alert.alert(
                        'Congratulation!',
                        'You Have Successfull',
                        [
                            {
                                text: 'OK',
                                onPress: () => { setState({ visible: false }) },
                            },
                        ],
                        { cancelable: false },
                    );
                }
            } catch (error) {
                Alert.alert('Failed', [{ text: 'OK', onPress: () => { } }], {
                    cancelable: false,
                });
            }
        })
    } */

  const searchBarItem = (param) => {
    ApiClient.GET(
      API.getSalesByPaymentSearchCategory + 1 + '&queryName=' + param,
    ).then((result) => {
      setState({ list: result });
    });
  };

  const add = async () => {
    if (page + 1 < pageCount) {
      await setState({ page: page + 1, currentPage: currentPage + 1 });
      console.log(page);
      var e = page;
      next(e);
    }
  };

  const next = (e) => {
    const offset = e * perPage;
    setState({ offset: offset });
    loadMoreData();
  };

  const less = async () => {
    if (page > 0) {
      await setState({ page: page - 1, currentPage: currentPage - 1 });
      console.log(page);
      var y = page;
      pre(y);
    }
  };

  const pre = (y) => {
    const offset = y * perPage;
    setState({ offset: offset });
    loadMoreData();
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
  };
  const nextDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage + 1 > pageCount
        ? currentDetailsPage
        : currentDetailsPage + 1,
    );
  };

  const prevDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1,
    );
  };
  const loadMoreData = () => {
    const data = oriList;
    const slice = data.slice(offset, offset + perPage);
    setState({ list: slice, pageCount: Math.ceil(data.length / perPage) });
  };

  // const moment = async () => {
  //     const today = new Date();
  //     const day = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
  //     await setState({ startDate: moment(day).format('YYYY-MM-DD'), endDate: moment(today).format('YYYY-MM-DD') })
  //     getDetail()
  // }

  const getDetail = () => {
    ApiClient.GET(
      API.getSalesByPayment +
      1 +
      '&startDate=' +
      startDate +
      '&endDate=' +
      endDate,
    ).then((result) => {
      var data = result;
      var slice = data.slice(offset, offset + perPage);
      setState({
        list: slice,
        oriList: data,
        pageCount: Math.ceil(data.length / perPage),
      });
    });
  };

  const decimal = (value) => {
    return value.toFixed(2);
  };

  const renderMainPage = ({ item, index }) => (
    <TouchableOpacity
      onPress={() => onItemMainPageClicked(item)}
      style={{
        backgroundColor:
          (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
        paddingVertical: 10,
        paddingHorizontal: 3,
        paddingLeft: 1,
        borderColor: '#BDBDBD',
        borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        // width: '100%',
      }}>
      <View style={{ flexDirection: 'row' }}>
        <Text
          style={{
            width: '10%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'left',
            paddingLeft: 10,
          }}>
          {index + 1}
        </Text>
        <Text
          style={{
            width: '19%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'left',
            paddingLeft: 10,
          }}>
          {item.mainPaymentMethod}
        </Text>
        <Text
          style={{
            width: '18%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'left',
            paddingLeft: 10,
          }}>
          {item.totalTransactions}
        </Text>
        <Text
          style={{
            width: '21%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'left',
            paddingLeft: 10,
          }}>
          {item.totalSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </Text>
      </View>
    </TouchableOpacity>
  );

  const renderItem = ({ item, index }) => (
    <TouchableOpacity
      onPress={() => onItemSummaryClicked(item)}
      style={{
        backgroundColor:
          (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
        paddingVertical: 10,
        paddingHorizontal: 3,
        paddingLeft: 1,
        borderColor: '#BDBDBD',
        borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        // width: '100%',
      }}>
      <View style={{ flexDirection: 'row' }}>
        <Text
          style={{
            width: '10%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'left',
            paddingLeft: 10,
          }}>
          {index + 1}
        </Text>
        <Text
          style={{
            width: '18%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'left',
            paddingLeft: 10,
          }}>
          {item.paymentMethod}
        </Text>
        <Text
          style={{
            width: '16%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'left',
            paddingLeft: 10,
          }}>
          {item.totalTransactions}
        </Text>
        <Text
          style={{
            width: '13%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'left',
            paddingLeft: 10,
          }}>
          {item.totalOrder}
        </Text>
        <Text
          style={{
            width: '14%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'left',
            paddingLeft: 10,
          }}>
          {item.totalDiscount
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </Text>
        <Text
          style={{
            width: '16%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'left',
            paddingLeft: 10,
          }}>
          {item.totalSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </Text>
        {/* <Text style={{ width: '25%', fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.netSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text> */}
        <Text
          style={{
            width: '13%',
            fontSize: switchMerchant ? 10 : 13,
            fontFamily: 'NunitoSans-Regular',
            textAlign: 'right',
            paddingRight: 20,
          }}>
          {(Math.ceil(item.netSales * 20 - 0.05) / 20)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
        </Text>
      </View>
    </TouchableOpacity>
  );

  const onClickItemDetails = (item) => {
    setExpandDetailsDict({
      ...expandDetailsDict,
      [item.uniqueId]: expandDetailsDict[item.uniqueId] ? false : true,
    });
  };

  const renderItemDetails = ({ item, index }) => {
    ///////////////////////////

    console.log('order id');
    console.log(item.orderId);

    // calculate longest

    var longestStr = 5;

    for (var i = 0; i < item.cartItems.length; i++) {
      const cartItemPriceWIthoutAddOn =
        item.cartItems[i].price -
        item.cartItems[i].addOns.reduce(
          (accum, addOn) => accum + addOn.prices[0],
          0,
        );

      if (cartItemPriceWIthoutAddOn.toFixed(0).length > longestStr) {
        longestStr = cartItemPriceWIthoutAddOn.toFixed(0).length;
      }

      for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
        if (
          item.cartItems[i].addOns[j].prices[0].toFixed(0).length > longestStr
        ) {
          longestStr = item.cartItems[i].addOns[j].prices[0].toFixed(0).length;
        }
      }
    }

    if (item.totalPrice.toFixed(0).length > longestStr) {
      longestStr = item.totalPrice.toFixed(0).length;
    }

    if (item.discount.toFixed(0).length > longestStr) {
      longestStr = item.discount.toFixed(0).length;
    }

    if (item.tax.toFixed(0).length > longestStr) {
      longestStr = item.tax.toFixed(0).length;
    }

    if (item.finalPrice.toFixed(0).length > longestStr) {
      longestStr = item.finalPrice.toFixed(0).length;
    }

    console.log(longestStr);

    ///////////////////////////

    // calculate spacing

    var cartItemPriceWIthoutAddOnSpacingList = [];
    var addOnsSpacingList = [];

    for (var i = 0; i < item.cartItems.length; i++) {
      const cartItemPriceWIthoutAddOn =
        item.cartItems[i].price -
        item.cartItems[i].addOns.reduce(
          (accum, addOn) => accum + addOn.prices[0],
          0,
        );

      cartItemPriceWIthoutAddOnSpacingList.push(
        Math.max(longestStr - cartItemPriceWIthoutAddOn.toFixed(0).length, 0) +
        1,
      );

      for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
        addOnsSpacingList.push(
          Math.max(
            longestStr -
            item.cartItems[i].addOns[j].prices[0].toFixed(0).length,
            0,
          ) + 1,
        );
      }
    }

    var totalPriceSpacing =
      Math.max(longestStr - item.totalPrice.toFixed(0).length, 0) + 1;
    var discountSpacing =
      Math.max(longestStr - item.discount.toFixed(0).length, 0) + 1;
    var taxSpacing = Math.max(longestStr - item.tax.toFixed(0).length, 0) + 1;
    var finalPriceSpacing =
      Math.max(longestStr - item.finalPrice.toFixed(0).length, 0) + 1;

    ///////////////////////////
    return (
      <TouchableOpacity
        onPress={() => onClickItemDetails(item)}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          //paddingHorizontal: 3,
          //paddingLeft: 1,
          borderColor: '#BDBDBD',
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}>
        <View style={{ flexDirection: 'row' }}>
          {/* <Text style={{ flex: 5, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.paymentMethod}</Text>
                <Text style={{ flex: 4, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{(item.totalSales).toFixed(2)}</Text>
                <Text style={{ flex: 4, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.totalTransactions}</Text>
                <Text style={{ flex: 3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{(item.netSales).toFixed(2)}</Text>
                <View style={{ flex: 6 }}></View> */}
          <Text
            style={{
              width: '6%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {index + 1}
          </Text>
          <Text
            style={{
              width: '12%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.orderId ? `#${item.orderId}` : 'N/A'}
          </Text>
          <Text
            style={{
              width: '11%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {parseFloat(item.totalPrice)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
          </Text>
          <Text
            style={{
              width: '18%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {moment(item.createdAt).format('DD MMM YY hh:mm A')}
          </Text>
          <Text
            style={{
              width: '9%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {parseFloat(item.discount)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
          </Text>
          <Text
            style={{
              width: '9%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {parseFloat(item.discountPercentage)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
          </Text>
          <Text
            style={{
              width: '9%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {parseFloat(item.tax)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
          </Text>
          <Text
            style={{
              width: '13%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {parseFloat(item.sc || 0)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
          </Text>
          {/* <Text style={{ flex: 1, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{parseFloat(0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text> */}
          {/* <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{parseFloat(item.totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text> */}
          <View
            style={{
              width: '13%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              paddingLeft: 10,
              paddingRight: switchMerchant ? '2.15%' : '1.8%',
            }}>
            <Text style={{}} />
            <Text
              style={{
                fontSize: switchMerchant ? 10 : 13,
                fontFamily: 'NunitoSans-Regular',
              }}>
              {/* <Text style={{
                            opacity: 0,
                            ...Platform.OS === 'android' && {
                                color: 'transparent',
                            },
                        }}>
                            {'0'.repeat((finalPriceSpacing * 0.6) + (item.finalPrice.toFixed(0).length === 1 ? 1 : 0))}
                        </Text> */}
              {(Math.ceil(item.finalPrice * 20 - 0.05) / 20)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
            </Text>
          </View>
        </View>

        {expandDetailsDict[item.uniqueId] == true ? (
          <View
            style={{
              minheight: windowHeight * 0.35,
              marginTop: 30,
              paddingBottom: 20,
            }}>
            {item.cartItems.map((cartItem, index) => {
              const cartItemPriceWIthoutAddOn =
                cartItem.price -
                cartItem.addOns.reduce(
                  (accum, addOn) => accum + addOn.prices[0],
                  0,
                );

              return (
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <View
                    style={{
                      width: '100%',
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginBottom: 10,
                      minHeight: 80,
                      //backgroundColor: 'yellow',
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                        //backgroundColor: 'blue',
                      }}>
                      {index == 0 ? (
                        <View
                          style={{
                            marginHorizontal: 1,
                            width: '8%',
                            //justifyContent: 'center',
                            alignItems: 'center',
                            //backgroundColor: 'blue',
                          }}>
                          <TouchableOpacity
                            style={{
                              alignItems: 'center',
                              marginTop: 0,
                            }}
                            onPress={() => {
                              var crmUser = null;

                              if (item.crmUserId !== undefined) {
                                for (var i = 0; i < crmUsers.length; i++) {
                                  if (item.crmUserId === crmUsers[i].uniqueId) {
                                    crmUser = crmUsers[i];
                                    break;
                                  }
                                }
                              }

                              if (!crmUser) {
                                for (var i = 0; i < crmUsers.length; i++) {
                                  if (item.userId === crmUsers[i].firebaseUid) {
                                    crmUser = crmUsers[i];
                                    break;
                                  }
                                }
                              }

                              if (crmUser) {
                                CommonStore.update(
                                  (s) => {
                                    s.selectedCustomerEdit = crmUser;
                                    // s.selectedCustomerEdit = userReservations[item.userId] && crmUsers[item.userId] ? crmUsers[item.userId] : null ;

                                    s.routeParams = {
                                      pageFrom: 'Reservation',
                                    };
                                  },
                                  () => {
                                    navigation.navigate('NewCustomer');
                                  },
                                );
                              }
                            }}>
                            <Image
                              style={{
                                width: switchMerchant ? 30 : 60,
                                height: switchMerchant ? 30 : 60,
                              }}
                              resizeMode="contain"
                              source={require('../assets/image/default-profile.png')}
                            />

                            <View
                              style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}>
                              <Text
                                style={[
                                  {
                                    fontFamily: 'NunitoSans-Bold',
                                    marginTop: 0,
                                    fontSize: 13,
                                    textAlign: 'center',
                                  },
                                  switchMerchant
                                    ? {
                                      fontFamily: 'NunitoSans-Bold',
                                      marginTop: 0,
                                      fontSize: 10,
                                      textAlign: 'center',
                                    }
                                    : {},
                                ]}
                                numberOfLines={1}>
                                {item.userName ? item.userName : 'Guest'}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      ) : (
                        <View
                          style={{
                            marginHorizontal: 1,
                            width: '8%',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      )}

                      <View
                        style={{
                          // flex: 0.3,
                          width: '5%',
                          //justifyContent: 'center',
                          alignItems: 'center',
                          //backgroundColor: 'red',
                          //paddingLeft: '1.2%',
                        }}>
                        <Text
                          style={[
                            {
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 13,
                            },
                            switchMerchant
                              ? {
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          {index + 1}.
                        </Text>
                      </View>

                      <View
                        style={{
                          //flex: 0.5,
                          width: '10%',
                          //backgroundColor: 'green',
                          alignItems: 'center',
                        }}>
                        <AsyncImage
                          source={{ uri: cartItem.image }}
                          item={cartItem}
                          style={{
                            width: switchMerchant ? 30 : 60,
                            height: switchMerchant ? 30 : 60,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            borderRadius: 5,
                          }}
                        />
                      </View>
                      <View style={{ width: '75%' }}>
                        <View
                          style={{
                            marginLeft: 14,
                            marginBottom: 10,
                            //backgroundColor: 'blue',
                            width: '100%',
                            flexDirection: 'row',
                          }}>
                          <View style={{ width: '69%' }}>
                            <Text
                              style={[
                                {
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 13,
                                },
                                switchMerchant
                                  ? {
                                    fontFamily: 'NunitoSans-Bold',
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              {cartItem.name}
                            </Text>
                          </View>

                          <View
                            style={{
                              width: '13%',
                            }}>
                            <View
                              style={{
                                alignItems: 'center',
                                //backgroundColor: 'yellow',
                              }}>
                              <Text
                                style={[
                                  {
                                    fontFamily: 'NunitoSans-Bold',
                                    fontSize: 13,
                                  },
                                  // Platform.OS === 'android'
                                  //   ? {
                                  //       width: '200%',
                                  //     }
                                  //   : {},
                                  switchMerchant
                                    ? {
                                      fontFamily: 'NunitoSans-Bold',
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}>
                                x{cartItem.quantity}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              width: '18.76%',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : { fontSize: 13 }
                              }>
                              RM
                            </Text>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    paddingRight: 20,
                                    fontFamily: 'NunitoSans-Regular',
                                  }
                                  : {
                                    fontSize: 13,
                                    paddingRight: 20,
                                    fontFamily: 'NunitoSans-Regular',
                                  }
                              }>
                              {cartItemPriceWIthoutAddOn
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </Text>
                          </View>
                        </View>

                        {cartItem.remarks && cartItem.remarks.length > 0 ? (
                          <View
                            style={{
                              alignItems: 'center',
                              flexDirection: 'row',
                              marginLeft: 14,
                            }}>
                            <View style={{ justifyContent: 'center' }}>
                              <Text
                                style={[
                                  {
                                    fontFamily: 'NunitoSans-SemiBold',
                                    fontSize: 13,
                                  },
                                  switchMerchant
                                    ? {
                                      fontFamily: 'NunitoSans-SemiBold',
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}>
                                {cartItem.remarks}
                              </Text>
                            </View>
                          </View>
                        ) : (
                          <></>
                        )}

                        {cartItem.addOns.map((addOnChoice, i) => {
                          return (
                            <View
                              style={{
                                flexDirection: 'row',
                                // marginLeft: -5,
                                width: '100%',
                              }}>
                              <View
                                style={{
                                  width: '69%',
                                  flexDirection: 'row',
                                  marginLeft: 14,
                                }}>
                                <Text
                                  style={[
                                    {
                                      fontFamily: 'NunitoSans-Bold',
                                      fontSize: 13,
                                      color: Colors.descriptionColor,
                                      width: '25%',
                                      // marginLeft: 5,
                                    },
                                    switchMerchant
                                      ? {
                                        fontFamily: 'NunitoSans-Bold',
                                        fontSize: 10,
                                        color: Colors.descriptionColor,
                                        width: '25%',
                                      }
                                      : {},
                                  ]}>
                                  {`${addOnChoice.name}:`}
                                </Text>
                                <Text
                                  style={[
                                    {
                                      fontFamily: 'NunitoSans-Bold',
                                      fontSize: 13,
                                      color: Colors.descriptionColor,
                                      width: '75%',
                                      // marginLeft: 5,
                                    },
                                    switchMerchant
                                      ? {
                                        fontFamily: 'NunitoSans-Bold',
                                        fontSize: 10,
                                        color: Colors.descriptionColor,
                                        width: '75%',
                                      }
                                      : {},
                                  ]}>
                                  {`${addOnChoice.choiceNames[0]}`}
                                </Text>
                              </View>

                              <View
                                style={[
                                  {
                                    width: '13%',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    //backgroundColor: 'blue',
                                  },
                                  switchMerchant
                                    ? {
                                      // borderWidth: 1
                                    }
                                    : {},
                                ]}>
                                <Text
                                  style={[
                                    {
                                      fontFamily: 'NunitoSans-Bold',
                                      fontSize: 13,
                                      color: Colors.descriptionColor,
                                      width: '28%',
                                      // right: 38,
                                      //backgroundColor: 'green',
                                      textAlign: 'center',
                                    },
                                    switchMerchant
                                      ? {
                                        fontFamily: 'NunitoSans-Bold',
                                        fontSize: 10,
                                        color: Colors.descriptionColor,
                                        width: '28%',
                                        textAlign: 'center',
                                      }
                                      : {},
                                  ]}>
                                  {`${addOnChoice.quantities
                                    ? `x${addOnChoice.quantities[0]}`
                                    : ''
                                    }`}
                                </Text>
                              </View>

                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: '18.75%',
                                  alignItems: 'center',
                                }}>
                                <Text
                                  style={[
                                    switchMerchant
                                      ? {
                                        color: Colors.descriptionColor,
                                        fontSize: 10,
                                      }
                                      : {
                                        color: Colors.descriptionColor,
                                        fontSize: 13,
                                      },
                                  ]}>
                                  RM
                                </Text>
                                <Text
                                  style={
                                    switchMerchant
                                      ? {
                                        color: Colors.descriptionColor,
                                        paddingRight: 20,
                                        fontSize: 10,
                                        fontFamily: 'NunitoSans-Regular',
                                      }
                                      : {
                                        color: Colors.descriptionColor,
                                        paddingRight: 20,
                                        fontSize: 13,
                                        fontFamily: 'NunitoSans-Regular',
                                      }
                                  }>
                                  {addOnChoice.prices[0]
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', width: '100%' }}>
                    <View style={{ width: '70%' }} />
                    <View style={{ width: 15 }} />
                    {index === item.cartItems.length - 1 ? (
                      <View
                        style={{
                          flexDirection: 'row',
                          //backgroundColor: 'yellow',
                          width: '28.65%',
                        }}>
                        <View
                          style={{
                            justifyContent: 'center',
                            width: '100%',
                          }}>
                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    width: '50.9%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.9%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Subtotal:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '49.1%',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? { fontSize: 10 }
                                    : { fontSize: 13 }
                                }>
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      paddingRight: 20,
                                      fontFamily: 'NunitoSans-Regular',
                                    }
                                    : {
                                      fontSize: 13,
                                      paddingRight: 20,
                                      fontFamily: 'NunitoSans-Regular',
                                    }
                                }>
                                {item.totalPrice
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </Text>
                            </View>
                          </View>
                          {cartItem.orderType === ORDER_TYPE.DELIVERY ? (
                            <View
                              style={{
                                flexDirection: 'row',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      width: '50.9%',
                                      fontFamily: 'Nunitosans-Bold',
                                    }
                                    : {
                                      fontSize: 13,
                                      width: '50.9%',
                                      fontFamily: 'Nunitosans-Bold',
                                    }
                                }>
                                Delivery Fee:
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: '49.1%',
                                }}>
                                <Text
                                  style={
                                    switchMerchant
                                      ? { fontSize: 10 }
                                      : { fontSize: 13 }
                                  }>
                                  RM
                                </Text>
                                <Text
                                  style={
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                        paddingRight: 20,
                                        fontFamily: 'NunitoSans-Regular',
                                      }
                                      : {
                                        fontSize: 13,
                                        paddingRight: 20,
                                        fontFamily: 'NunitoSans-Regular',
                                      }
                                  }>
                                  {item.deliveryFee
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                </Text>
                              </View>
                            </View>
                          ) : (
                            <></>
                          )}

                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    width: '50.9%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.9%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Discount:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '49.1%',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? { fontSize: 10 }
                                    : { fontSize: 13 }
                                }>
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      paddingRight: 20,
                                      fontFamily: 'NunitoSans-Regular',
                                    }
                                    : {
                                      fontSize: 13,
                                      paddingRight: 20,
                                      fontFamily: 'NunitoSans-Regular',
                                    }
                                }>
                                -{' '}
                                {item.discount
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    width: '50.9%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.9%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Tax:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '49.1%',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      paddingRight: 20,
                                    }
                                    : { fontSize: 13, paddingRight: 20 }
                                }>
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      paddingRight: 20,
                                      fontFamily: 'NunitoSans-Regular',
                                    }
                                    : {
                                      fontSize: 13,
                                      paddingRight: 20,
                                      fontFamily: 'NunitoSans-Regular',
                                    }
                                }>
                                {item.tax
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10, width: '50.85%', fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.85%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Service Charge:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: switchMerchant ? '49.15%' : '49.1%',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : { fontSize: 13, }
                                }>
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                    }
                                    : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                }>
                                {(item.sc || 0)
                                  .toFixed(2)
                                  .replace(
                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                    '$1,',
                                  )}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    width: '50.9%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.9%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Total:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: '49.1%',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? { fontSize: 10 }
                                    : { fontSize: 13 }
                                }>
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      paddingRight: 20,
                                      fontFamily: 'NunitoSans-Regular',
                                    }
                                    : {
                                      fontSize: 13,
                                      paddingRight: 20,
                                      fontFamily: 'NunitoSans-Regular',
                                    }
                                }>
                                {item.finalPrice
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}
                  </View>

                  {/* <View style={{alignItems:'flex-end'}}>
                        <View style={{ flexDirection: 'row' }}>
                          <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 16 }}>Subtotal: {(Math.ceil(cartItem.price * 20-0.05) /20).toFixed(2)}</Text>
                        </View>
                      </View> */}
                  {/* {(cartItem.remarks && cartItem.remarks.length > 0) ?
                      <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                        
                        <View style={{ flex: 1, justifyContent: 'center', }}>
                          <Text style={{ fontFamily: 'NunitoSans-SemiBold', fontSize: 15 }}>{cartItem.remarks}</Text>
                        </View>
                        
                      </View>
                      : <></>
                    } */}
                </View>
              );
            })}
          </View>
        ) : null}
      </TouchableOpacity>
    );
  };

  const onItemSummaryClicked = (item) => {
    // setTransactionTypeSalesDetails(item.detailsList);
    setSelectedItemSummary(item);
    //setShowDetails(true);
    setShowReportTableSection(REPORT_TABLE_SECTION.DETAILS_PAGE);

    // setCurrentPage(1);
    // setPageCount(Math.ceil(item.detailsList.length / perPage));

    console.log('item.detailsList');
    console.log(item.detailsList);
  };

  const onItemMainPageClicked = (item) => {
    if (item.mainPaymentMethod === 'Offline payment') {
      setOfflinePaymentClicked(true);
      setSelectedItemMainPage(item);
      setShowReportTableSection(REPORT_TABLE_SECTION.SUMMARY_PAGE);
    } else if (item.mainPaymentMethod === 'Online transfer') {
      setOfflinePaymentClicked(false);
      setSelectedItemMainPage(item);
      setShowReportTableSection(REPORT_TABLE_SECTION.SUMMARY_PAGE);
    }

    // setCurrentPage(1);
    // setPageCount(Math.ceil(item.detailsList.length / perPage));

    console.log('item.detailsList');
    console.log(item.detailsList);
  };

  // const downloadCsv = () => {
  //   //if (productSales && productSales.dataSource && productSales.dataSource.data) {
  //   //const csvData = convertArrayToCSV(productSales.dataSource.data);
  //   const csvData = convertArrayToCSV(CsvData);

  //   const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir
  //     }/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
  //   console.log('PATH', pathToWrite);
  //   RNFetchBlob.fs
  //     .writeFile(pathToWrite, csvData, 'utf8')
  //     .then(() => {
  //       console.log(`wrote file ${pathToWrite}`);
  //       // wrote file /storage/emulated/0/Download/data.csv
  //       Alert.alert(
  //         'Success',
  //         `Send to ${pathToWrite}`,
  //         [{ text: 'OK', onPress: () => { } }],
  //         { cancelable: false },
  //       );
  //     })
  //     .catch((error) => console.error(error));
  //   //}
  // };

  // const emailPaymentMethod = () => {
  //     var body = {
  //         data: CsvData,
  //         //data: convertArrayToCSV(productSales.dataSource.data),
  //         data: convertArrayToCSV(CsvData),
  //         email: exportEmail,
  //     };
  //     //API need to change
  //     ApiClient.POST(API.emailDashboard, body, false).then((result) => {
  //         if (result !== null) {
  //             Alert.alert(
  //                 'Success',
  //                 'Email sent to your inbox',
  //                 [{ text: 'OK', onPress: () => { } }],
  //                 { cancelable: false },
  //             );
  //         }
  //     });

  //     setVisible(false);
  // };

  const emailDashboard = () => {
    const excelData = convertDataToExcelFormat();

    var body = {
      // data: CsvData,
      //data: convertArrayToCSV(todaySalesChart.dataSource.data),
      data: JSON.stringify(excelData),
      //data: convertDataToExcelFormat(),
      email: exportEmail,
    };

    ApiClient.POST(API.emailDashboard, body, false).then((result) => {
      if (result !== null) {
        Alert.alert(
          'Success',
          'Email has been sent',
          [{ text: 'OK', onPress: () => { } }],
          { cancelable: false },
        );
      }
    });

    setVisible(false);
  };

  const sortingOrders = (param) => {
    if (param.value == 1) {
      //orderid
      setPaymentMethodSales(
        paymentMethodSales
          .slice(0)
          .sort((a, b) => b.paymentMethod - a.paymentMethod),
      );
    }
    if (param.value == 2) {
      //date time
      setPaymentMethodSales(
        paymentMethodSales.slice(0).sort((a, b) => b.totalSales - a.totalSales),
      );
    }
    if (param.value == 3) {
      //Name
      setPaymentMethodSales(
        paymentMethodSales
          .slice(0)
          .sort((a, b) => b.totalTransactions - a.totalTransactions),
      );
    }
    if (param.value == 4) {
      //Waiting Time
      setPaymentMethodSales(
        paymentMethodSales.slice(0).sort((a, b) => b.netSales - a.netSales),
      );
    }
  };

  // var leftSpacing = '0%';

  // if (windowWidth >= 1280) {
  //     leftSpacing = '-4%';
  // }

  // const leftSpacingScale = {
  //     marginLeft: leftSpacing,
  // };

  const convertDataToExcelFormat = () => {
    var excelData = [];

    if (showReportTableSection === REPORT_TABLE_SECTION.MAIN_PAGE) {
      for (var i = 0; i < paymentMethodMainPage.length; i++) {
        var excelRow = {
          // 'Transaction Category': transactionTypeSales[i].orderType,
          // 'Total Sales (RM)': parseFloat(transactionTypeSales[i].totalSales).toFixed(2),
          // 'Total Transaction': transactionTypeSales[i].totalTransactions,
          // 'Total Discount (RM)': parseFloat(transactionTypeSales[i].totalDiscount).toFixed(2),
          // 'Discount (%)': parseFloat(transactionTypeSales[i].discount).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSales[i].tax).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSales[i].serviceCharge).toFixed(2),
          // 'GP (%)': parseFloat(transactionTypeSales[i].gp).toFixed(2),
          // 'Net Sales (RM)': parseFloat(transactionTypeSales[i].netSales).toFixed(2),
          // 'Average Net Sales (RM)': parseFloat(transactionTypeSales[i].averageNetSales).toFixed(2),
          'Payment Method': paymentMethodMainPage[i].mainPaymentMethod,
          Order: paymentMethodMainPage[i].totalTransactions,
          'Sales (RM)': +parseFloat(paymentMethodMainPage[i].totalSales)
            .toFixed(2),
        };

        excelData.push(excelRow);
      }
    }
    if (showReportTableSection === REPORT_TABLE_SECTION.SUMMARY_PAGE) {
      for (var i = 0; i < paymentMethodSales.length; i++) {
        var excelRow = {
          // 'Transaction Category': transactionTypeSales[i].orderType,
          // 'Total Sales (RM)': parseFloat(transactionTypeSales[i].totalSales).toFixed(2),
          // 'Total Transaction': transactionTypeSales[i].totalTransactions,
          // 'Total Discount (RM)': parseFloat(transactionTypeSales[i].totalDiscount).toFixed(2),
          // 'Discount (%)': parseFloat(transactionTypeSales[i].discount).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSales[i].tax).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSales[i].serviceCharge).toFixed(2),
          // 'GP (%)': parseFloat(transactionTypeSales[i].gp).toFixed(2),
          // 'Net Sales (RM)': parseFloat(transactionTypeSales[i].netSales).toFixed(2),
          // 'Average Net Sales (RM)': parseFloat(transactionTypeSales[i].averageNetSales).toFixed(2),
          'Payment Method': paymentMethodSales[i].paymentMethod,
          'Total Transaction': paymentMethodSales[i].totalTransactions,
          'Total Order': paymentMethodSales[i].totalOrder,
          'Total Discount (RM)': +parseFloat(paymentMethodSales[i].totalDiscount)
            .toFixed(2),
          'Total Sales (RM)': +parseFloat(paymentMethodSales[i].totalSales)
            .toFixed(2),
          'Net Sales': +parseFloat(paymentMethodSales[i].netSales)
            .toFixed(2),
        };

        excelData.push(excelRow);
      }
    }
    if (showReportTableSection === REPORT_TABLE_SECTION.DETAILS_PAGE) {
      for (var i = 0; i < transactionTypeSalesDetails.length; i++) {
        var excelRow = {
          // 'Transaction Category': ORDER_TYPE_PARSED[transactionTypeSalesDetails[i].orderType],
          // 'Sales (RM)': parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2),
          // 'Transaction Date': moment(transactionTypeSalesDetails[i].createdAt).format('DD MMM hh:mma'),
          // 'Total Discount (RM)': parseFloat(transactionTypeSalesDetails[i].discount).toFixed(2),
          // 'Discount (%)': parseFloat(isFinite(transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount) ? transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount * 100 : 0).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2),
          // 'Tax (RM)': parseFloat(0).toFixed(2),
          // 'GP (%)': parseFloat(0).toFixed(2),
          // 'Net Sales (RM)': parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2),
          // 'Order ID': transactionTypeSalesDetails[i].orderId ? `#${transactionTypeSalesDetails[i].orderId}` : 'N/A',
          // 'Sales (RM)': parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2),
          // 'Transaction Date': moment(transactionTypeSalesDetails[i].completedDate).format('DD MMM hh:mma'),
          // 'Total Discount (RM)': parseFloat(transactionTypeSalesDetails[i].discount).toFixed(2),
          // 'Discount (%)': parseFloat(transactionTypeSalesDetails[i].discountPercentage).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2),
          // 'Tax (RM)': parseFloat(0).toFixed(2),
          // 'GP (%)': parseFloat(0).toFixed(2),
          // 'Net Sales (RM)': parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2),
          'Order ID': transactionTypeSalesDetails[i].orderId
            ? `#${transactionTypeSalesDetails[i].orderId}`
            : 'N/A',
          'Sales (RM)': +parseFloat(transactionTypeSalesDetails[i].totalPrice)
            .toFixed(2),
          'Transaction Date': moment(
            transactionTypeSalesDetails[i].createdAt,
          ).format('DD MMM hh:mma'),
          'Total Discount (RM)': +parseFloat(
            transactionTypeSalesDetails[i].discount,
          )
            .toFixed(2),
          'Discount (%)': +parseFloat(
            transactionTypeSalesDetails[i].discountPercentage,
          )
            .toFixed(2),
          'Tax (RM)': +parseFloat(transactionTypeSalesDetails[i].tax)
            .toFixed(2),
          'Service Charge (RM)': +parseFloat(
            transactionTypeSalesDetails[i].sc || 0,
          )
            .toFixed(2),
          //'GP (%)': parseFloat(0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          'Net Sales (RM)': +parseFloat(
            Math.ceil(transactionTypeSalesDetails[i].finalPrice * 20 - 0.05) /
              20,
          )
            .toFixed(2),
        };

        excelData.push(excelRow);
      }
    }

    console.log('excelData');
    console.log(excelData);

    return excelData;
  };

  // const downloadExcel = () => {
  //   const excelData = convertDataToExcelFormat();

  //   var excelFile = `${Platform.OS === 'ios'
  //     ? RNFS.DocumentDirectoryPath
  //     : RNFS.DownloadDirectoryPath
  //     }/koodoo-report-Product-Sales${moment().format(
  //       'YYYY-MM-DD-HH-mm-ss',
  //     )}.xlsx`;
  //   var excelWorkSheet = XLSX.utils.json_to_sheet(excelData);
  //   var excelWorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(
  //     excelWorkBook,
  //     excelWorkSheet,
  //     'Product Sales Report',
  //   );

  //   const workBookData = XLSX.write(excelWorkBook, {
  //     type: 'binary',
  //     bookType: 'xlsx',
  //   });

  //   RNFS.writeFile(excelFile, workBookData, 'ascii')
  //     .then((success) => {
  //       console.log(`wrote file ${excelFile}`);

  //       Alert.alert(
  //         'Success',
  //         `Send to ${excelFile}`,
  //         [{ text: 'OK', onPress: () => { } }],
  //         { cancelable: false },
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });

  //   // XLSX.writeFileAsync(excelFile, excelWorkBook, () => {
  //   //     Alert.alert(
  //   //         'Success',
  //   //         `Send to ${excelFile}`,
  //   //         [{ text: 'OK', onPress: () => { } }],
  //   //         { cancelable: false },
  //   //     );
  //   // });

  //   // const csvData = convertArrayToCSV(CsvData);

  //   // const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
  //   // console.log("PATH", excelFile);
  //   // RNFetchBlob.fs
  //   //     .writeFile(excelFile, excelWorkBook, 'utf8')
  //   //     .then(() => {
  //   //         console.log(`wrote file ${excelFile}`);
  //   //         Alert.alert(
  //   //             'Success',
  //   //             `Send to ${excelFile}`,
  //   //             [{ text: 'OK', onPress: () => { } }],
  //   //             { cancelable: false },
  //   //         );
  //   //     })
  //   //     .catch(error => console.error(error));
  // };

  const flatListRef = useRef();

  const ScrollToTop = () => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
  };

  const ScrollToBottom = () => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 100 });
  };

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={8} expandReport={true} />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{}}
          contentContainerStyle={{
            paddingBottom: windowHeight * 0.1,
            backgroundColor: Colors.highlightColor,
          }}>
          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={exportModalVisibility}
            transparent={true}
            animationType={'fade'}>
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: 'center',
                justifyContent: 'center',
                // top:
                // Platform.OS === 'android'
                //   ? 0
                //   : keyboardHeight > 0
                //     ? -keyboardHeight * 0.45
                //     : 0,
              }}>
              <View
                style={{
                  height: windowWidth * 0.3,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.03,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <TouchableOpacity
                  style={{
                    position: 'absolute',
                    right: windowWidth * 0.02,
                    top: windowWidth * 0.02,

                    elevation: 1000,
                    zIndex: 1000,
                  }}
                  onPress={() => {
                    setExportModalVisibility(false);
                    console.log('something here');
                  }}>
                  <AntDesign
                    name="closecircle"
                    size={switchMerchant ? 15 : 25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View
                  style={{
                    alignItems: 'center',
                    top: '20%',
                    position: 'absolute',
                  }}>
                  <Text
                    style={{
                      fontFamily: 'NunitoSans-Bold',
                      textAlign: 'center',
                      fontSize: switchMerchant ? 15 : 24,
                    }}>
                    Download Report
                  </Text>
                </View>
                <View style={{ top: switchMerchant ? '14%' : '10%' }}>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 20,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    Email Address:
                  </Text>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={{
                      backgroundColor: Colors.fieldtBgColor,
                      width: switchMerchant ? 240 : 370,
                      height: switchMerchant ? 35 : 50,
                      borderRadius: 5,
                      padding: 5,
                      marginVertical: 5,
                      borderWidth: 1,
                      borderColor: '#E5E5E5',
                      paddingLeft: 10,
                      fontSize: switchMerchant ? 10 : 14,
                    }}
                    placeholderStyle={{ padding: 5 }}
                    placeholder="Enter Your Email"
                    onChangeText={(text) => {
                      setExportEmail(text);
                    }}
                    value={exportEmail}
                  // placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                  />
                  <Text
                    style={{
                      fontSize: switchMerchant ? 12 : 20,
                      fontFamily: 'NunitoSans-Bold',
                      marginTop: 15,
                    }}>
                    Send As:
                  </Text>

                  <View
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      //top: '10%',
                      flexDirection: 'row',
                      marginTop: 10,
                    }}>
                    <CSVLink
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "inline-block",
                        flexDirection: "row",
                        textDecoration: 'none',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: switchMerchant ? 100 : 100,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      data={convertDataToExcelFormat()}
                      filename="report.csv"
                    >
                      <View
                        style={{
                          width: "100%",
                          height: "100%",
                          alignContent: "center",
                          alignItems: "center",
                          alignSelf: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          CSV
                        </Text>
                      </View>
                    </CSVLink>

                    {/* <TouchableOpacity
                                            style={[styles.modalSaveButton, {
                                                zIndex: -1
                                            }]}
                                            onPress={() => { downloadPDF() }}>
                                            <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>PDF</Text>
                                        </TouchableOpacity> */}
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <DateTimePickerModal
            isVisible={showDateTimePicker}
            mode={'date'}
            onConfirm={(text) => {
              setRev_date(moment(text).startOf('day'));
              setShowDateTimePicker(false);
              setShowReportTableSection(REPORT_TABLE_SECTION.MAIN_PAGE);
            }}
            onCancel={() => {
              setShowDateTimePicker(false);
            }}
            maximumDate={moment(rev_date1).toDate()}
          />

          <DateTimePickerModal
            isVisible={showDateTimePicker1}
            mode={'date'}
            onConfirm={(text) => {
              setRev_date1(moment(text).endOf('day'));
              setShowDateTimePicker1(false);
              setShowReportTableSection(REPORT_TABLE_SECTION.MAIN_PAGE);
            }}
            onCancel={() => {
              setShowDateTimePicker1(false);
            }}
            minimumDate={moment(rev_date).toDate()}
          />

          {/* <Modal
                style={{ flex: 1 }}
                visible={visible}
                transparent={true}
                animationType="slide">

                <KeyboardAvoidingView
                    behavior="padding"
                    style={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: Dimensions.get('window').height,
                    }}>
                    <View style={styles.confirmBox}>

                        <Text style={{ fontSize: 24, justifyContent: "center", alignSelf: "center", marginTop: 40, fontFamily: 'NunitoSans-Bold' }}>Enter your email</Text>
                        <View style={{ justifyContent: "center", alignSelf: "center", alignContent: 'center', marginTop: 20, flexDirection: 'row', width: '80%' }}>
                            <View style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                                <Text style={{ color: Colors.descriptionColor, fontSize: 20, }}>
                                    email:
                                </Text>
                            </View>
                            <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={styles.textInput8}
                                placeholder="Enter your email"
                                // style={{
                                //     // paddingLeft: 1,
                                // }}                                        
                                //defaultValue={extentionCharges}
                                onChangeText={(text) => {
                                    // setState({ exportEmail: text });
                                    setExportEmail(text);
                                }}
                                value={exportEmail}
                            />

                            <View style={{ flexDirection: 'row', marginTop: '13.5%' }}>
                                <TouchableOpacity
                                    onPress={emailPaymentMethod}
                                    style={{
                                        backgroundColor: Colors.fieldtBgColor,
                                        width: '50%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        height: 60,
                                        borderBottomLeftRadius: 30,
                                        borderRightWidth: StyleSheet.hairlineWidth,
                                        borderTopWidth: StyleSheet.hairlineWidth
                                    }}>
                                    <Text style={{ fontSize: 22, color: Colors.primaryColor, fontFamily: 'NunitoSans-SemiBold' }}>
                                        Email
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        // setState({ visible: false });
                                        setVisible(false);
                                    }}
                                    style={{
                                        backgroundColor: Colors.fieldtBgColor,
                                        width: '50%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        height: 60,
                                        borderBottomRightRadius: 30,
                                        borderTopWidth: StyleSheet.hairlineWidth
                                    }}>
                                    <Text style={{ fontSize: 22, color: Colors.descriptionColor, fontFamily: 'NunitoSans-SemiBold' }}>
                                        Cancel
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>

                    </View>
                </KeyboardAvoidingView>
            </Modal> */}

          <View
            style={[
              styles.content,
              {
                //top: Platform.OS === 'ios' && keyboardHeight > 0 ? -keyboardHeight * 1 : 0,
              },
            ]}>
            <View style={{ flex: 1 }}>
              <View
                style={{
                  flexDirection: 'row',
                  //backgroundColor: '#ffffff',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignSelf: 'center',
                  //padding: 18,
                  marginTop: 5,
                  width: windowWidth * 0.87,
                }}>
                <View>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 20 : 26,
                      fontFamily: 'NunitoSans-Bold',
                      marginTop: 10,
                    }}>
                    Sales By: {name}
                  </Text>
                  {/* <Text style={{ fontSize: 16, marginTop: 10, color: Colors.descriptionColor }}>Date last updated on {moment().format('LLLL')}</Text> */}
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                  }}>
                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      backgroundColor: '#4E9F7D',
                      borderRadius: 5,
                      //width: 140,
                      paddingHorizontal: 10,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,
                      marginRight: 10,
                    }}
                    onPress={() => {
                      setExportModalVisibility(true);
                    }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Icon
                        name="download"
                        size={switchMerchant ? 10 : 20}
                        color={Colors.whiteColor}
                      />
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: 'NunitoSans-Bold',
                        }}>
                        DOWNLOAD
                      </Text>
                    </View>
                  </TouchableOpacity>

                  <View
                    style={{
                      width: switchMerchant ? 180 : 250,
                      height: switchMerchant ? 35 : 40,
                      backgroundColor: 'white',
                      borderRadius: 5,
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      zIndex: -1,
                      shadowColor: '#000',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                      borderWidth: 1,
                      borderColor: '#E5E5E5',
                    }}>
                    <Icon
                      name="search"
                      size={switchMerchant ? 13 : 18}
                      color={Colors.primaryColor}
                      style={{ marginLeft: 15 }}
                    />
                    <TextInput
                      underlineColorAndroid={Colors.whiteColor}
                      style={{
                        width: switchMerchant ? 180 : 220,
                        fontSize: switchMerchant ? 10 : 15,
                        fontFamily: 'NunitoSans-Regular',
                        paddingLeft: 5,
                        height: 45,
                      }}
                      clearButtonMode="while-editing"
                      placeholder=" Search"
                      //placeholderTextColor={Colors.descriptionColor}
                      onChangeText={(text) => {
                        setSearch(text);
                      }}
                      // placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                      value={search}
                    />
                  </View>
                </View>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  //backgroundColor: '#ffffff',
                  justifyContent: 'space-between',
                  //padding: 18,
                  marginTop: 20,
                  width: '100%',
                  paddingLeft: switchMerchant
                    ? 0
                    : windowWidth <= 1823 &&
                      windowWidth >= 1820
                      ? '1.5%'
                      : '1%',
                  paddingRight: switchMerchant
                    ? 0
                    : windowWidth <= 1823 &&
                      windowWidth >= 1820
                      ? '1.5%'
                      : '1%',
                }}>
                {showReportTableSection ===
                  REPORT_TABLE_SECTION.DETAILS_PAGE ? (
                  <TouchableOpacity
                    style={[
                      {
                        justifyContent: 'center',
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: '#4E9F7D',
                        borderRadius: 5,
                        //width: 160,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: 'center',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,

                        opacity:
                          showReportTableSection ===
                            REPORT_TABLE_SECTION.DETAILS_PAGE
                            ? 100
                            : 0,
                      },
                    ]}
                    onPress={() => {
                      setShowReportTableSection(
                        REPORT_TABLE_SECTION.SUMMARY_PAGE,
                      );
                      setCurrentPage(pageReturn);
                      console.log('Returning to page');
                      console.log(pageReturn);
                      setPageCount(
                        Math.ceil(paymentMethodSales.length / perPage),
                      );
                      setCurrReportSummarySort('');
                      setCurrReportDetailsSort('');
                    }}
                  //disabled={!showDetails}
                  >
                    <AntDesign
                      name="arrowleft"
                      size={switchMerchant ? 10 : 20}
                      color={Colors.whiteColor}
                      style={
                        {
                          // top: -1,
                          //marginRight: -5,
                        }
                      }
                    />
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                        // marginBottom: Platform.OS === 'ios' ? 0 : 2
                      }}>
                      Summary
                    </Text>
                  </TouchableOpacity>
                ) : showReportTableSection ===
                  REPORT_TABLE_SECTION.SUMMARY_PAGE ? (
                  <TouchableOpacity
                    style={[
                      {
                        //marginRight: 10,
                        justifyContent: 'center',
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: '#4E9F7D',
                        borderRadius: 5,
                        //width: 160,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: 'center',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,

                        opacity:
                          showReportTableSection ===
                            REPORT_TABLE_SECTION.SUMMARY_PAGE
                            ? 100
                            : 0,
                      },
                    ]}
                    onPress={() => {
                      setShowReportTableSection(REPORT_TABLE_SECTION.MAIN_PAGE);
                      setCurrReportSummarySort('');
                      setCurrReportDetailsSort('');
                      // setCurrentPage(pageReturn);
                      // console.log('Returning to page')
                      // console.log(pageReturn)
                      // setPageCount(Math.ceil(paymentMethodSales.length / perPage));
                    }}
                  //disabled={!showDetails}
                  >
                    <AntDesign
                      name="arrowleft"
                      size={switchMerchant ? 10 : 20}
                      color={Colors.whiteColor}
                      style={
                        {
                          // top: -1,
                          //marginRight: -5,
                        }
                      }
                    />
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                        // marginBottom: Platform.OS === 'ios' ? 0 : 2
                      }}>
                      Summary
                    </Text>
                  </TouchableOpacity>
                ) : null}

                {/* <View style={{
                            flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.whiteColor, borderRadius: 10, height: 40, borderWidth: 1, borderColor: '#E5E5E5',
                            shadowOpacity: 0,
                            shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                        }}>
                            <Text style={{ paddingLeft: 8, fontSize: 15, fontFamily: 'NunitoSans-Bold' }}>View By</Text>
                            <DropDownPicker
                                // controller={instance => controller = instance}
                                controller={instance => setController(instance)}
                                arrowColor={Colors.primaryColor}
                                arrowSize={20}
                                arrowStyle={{ fontWeight: 'bold', alignItems: 'center', justifyContent: 'center', marginTop: -3 }}
                                style={{ width: 150, borderWidth: 0, height: 40, borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
                                itemStyle={{ justifyContent: 'flex-start' }}
                                placeholderStyle={{ color: 'black' }}
                                //change value below (Eason)
                                items={[{ label: 'Payment Method', value: 1 }, { label: 'Total Sales', value: 2 }, { label: 'Total Transactions', value: 3 }, { label: 'Net Sales', value: 4 }]}
                                placeholder={"Group by"}
                                labelStyle={{ fontSize: 12.5 }}
                                onChangeItem={selectedSort => {
                                    // setState({ sort: selectedSort }),
                                    sortingOrders(selectedSort);
                                }
                                }
                            //onOpen={() => controller1.close()}
                            />
                        </View> */}

                {/* <TouchableOpacity style={{ marginRight: 10, width: 230, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: 40, backgroundColor: Colors.whiteColor }}
                            onPress={() => { changeClick() }}>
                            <TouchableOpacity style={{ alignSelf: "center" }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                <EvilIcons name="calendar" size={25} color={Colors.primaryColor} />
                            </TouchableOpacity>
                            <Text style={{ fontFamily: "NunitoSans-Regular" }}>{moment(rev_date).format("DD MMM yyyy")} - {moment(rev_date1).format("DD MMM yyyy")}</Text>
                        </TouchableOpacity> */}
                <View
                  style={{
                    flexDirection: 'row',
                    width:
                      showReportTableSection ===
                        REPORT_TABLE_SECTION.SUMMARY_PAGE ||
                        showReportTableSection ===
                        REPORT_TABLE_SECTION.DETAILS_PAGE
                        ? '80%'
                        : '100%',
                    justifyContent: 'flex-end',
                  }}>
                  {/* <TouchableOpacity
                                style={[leftSpacingScale, {
                                    marginRight: 10,
                                    paddingHorizontal: 15,
                                    backgroundColor: Colors.whiteColor,
                                    height: 40,
                                    // width: 200, 
                                    alignItems: 'center',
                                    borderRadius: 7,
                                    flexDirection: 'row',
                                    justifyContent: 'center', shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,

                                    opacity: !showDetails ? 0 : 100,
                                }]}
                                onPress={() => {
                                    setShowDetails(false);

                                    setCurrentPage(1);
                                    setPageCount(Math.ceil(paymentMethodSales.length / perPage));
                                }}
                                disabled={!showDetails}
                            >
                                <AntDesign name="arrowleft" size={18} color={Colors.primaryColor} style={{
                                    top: 1,
                                    marginRight: -5,
                                }} />
                                <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, marginLeft: 12 }}>Back to Summary</Text>
                            </TouchableOpacity> */}

                  {/* <View style={{
                                flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.whiteColor, borderRadius: 10, height: 40, borderWidth: 1, borderColor: '#E5E5E5',
                                marginHorizontal: 10,
                                shadowOpacity: 0,
                                shadowColor: '#000',
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                            }}>
                                <Text style={{ paddingLeft: 8, fontSize: 15, fontFamily: 'NunitoSans-Bold' }}>View By</Text>
                                <DropDownPicker
                                    // controller={instance => controller = instance}
                                    controller={instance => setController(instance)}
                                    arrowColor={Colors.primaryColor}
                                    arrowSize={20}
                                    arrowStyle={{ fontWeight: 'bold', alignItems: 'center', justifyContent: 'center', marginTop: -3 }}
                                    style={{ width: 150, borderWidth: 0, height: 40, borderTopRightRadius: 10, borderBottomRightRadius: 10 }}
                                    itemStyle={{ justifyContent: 'flex-start' }}
                                    placeholderStyle={{ color: 'black' }}
                                    //change value below (Eason)
                                    items={[{ label: 'Payment Method', value: 1 }, { label: 'Sales', value: 2 }, { label: 'Total Order', value: 3 }, { label: 'Net Sales', value: 4 }]}
                                    placeholder={"Group by"}
                                    labelStyle={{ fontSize: 12.5 }}
                                    onChangeItem={selectedSort => {
                                        // setState({ sort: selectedSort }),
                                        sortingOrders(selectedSort);
                                    }
                                    }
                                //onOpen={() => controller1.close()}
                                />
                            </View> */}

                  <View
                    style={[
                      {
                        //marginRight: 10,
                        // paddingLeft: 15,
                        paddingHorizontal: 15,
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderRadius: 10,
                        paddingVertical: 10,
                        justifyContent: 'center',
                        backgroundColor: Colors.whiteColor,
                        shadowOpacity: 0,
                        shadowColor: '#000',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                      },
                    ]}>
                    <View
                      style={{ alignSelf: 'center', marginRight: 5 }}
                      onPress={() => {
                        setState({
                          pickerMode: 'date',
                          showDateTimePicker: true,
                        });
                      }}>
                      <GCalendar
                        width={switchMerchant ? 15 : 20}
                        height={switchMerchant ? 15 : 20}
                      />
                    </View>

                    <DatePicker
                      selected={rev_date.toDate()}
                      onChange={(date) => {
                        setRev_date(moment(date).startOf('day'));
                        setShowReportTableSection(REPORT_TABLE_SECTION.MAIN_PAGE);
                      }}
                      maxDate={moment(rev_date1).toDate()}
                    />

                    <Text
                      style={{
                        fontFamily: "NunitoSans-Regular",
                        paddingHorizontal: 5,
                        marginLeft: 5,
                      }}
                    >
                      -
                    </Text>

                    <DatePicker
                      selected={rev_date1.toDate()}
                      onChange={(date) => {
                        setRev_date1(moment(date).endOf('day'));
                        setShowReportTableSection(REPORT_TABLE_SECTION.MAIN_PAGE);
                      }}
                      minDate={moment(rev_date).toDate()}
                    />
                  </View>

                  {/* <TouchableOpacity
                                style={{
                                    paddingHorizontal: 15, backgroundColor: Colors.whiteColor, height: 40, width: 120, alignItems: 'center', borderRadius: 7, flexDirection: 'row', justifyContent: 'center', shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                }}
                                onPress={() => {
                                    // setState({
                                    //     visible: true
                                    // })
                                    setVisible(true);
                                }}
                            >
                                <Upload width={15} height={15} />
                                <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, marginLeft: 12 }}>Email</Text>
                            </TouchableOpacity> */}
                </View>

                {/* <View style={{ flex: 4 }}>
                            <View style={{ width: '50%' }}>
                                <TouchableOpacity onPress={() => { setState({ group: !group }) }}>
                                    <View style={{ width: '100%', height: 50, backgroundColor: Colors.whiteColor, borderRadius: 10, elevation: 2, alignItems: 'center', flexDirection: 'row' }}>
                                        <View style={{ flex: 1, borderRightWidth: StyleSheet.hairlineWidth }}>
                                            <Text style={{ textAlign: 'center', fontSize: 15 }}>Group By</Text>
                                        </View>
                                        <View style={{ flex: 1, flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                                            <Text style={{ textAlign: 'center', fontSize: 15, flex: 3 }}>{choice}</Text>
                                            <MaterialIcons name='keyboard-arrow-down' size={25} style={{ flex: 2, textAlign: 'center' }} />
                                        </View>
                                    </View>
                                </TouchableOpacity>
                                {group ?
                                    <View style={{ position: 'absolute', width: "100%", backgroundColor: Colors.whiteColor, marginTop: '20%', zIndex: 6000 }}>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.primaryColor }} onPress={() => { getDetail(), setState({ choice: 'All' }) }}>
                                            <Text style={{ color: Colors.whiteColor }}>All</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { searchBarItem('Online Banking'), setState({ choice: 'Online Banking' }) }}>
                                            <Text style={{ color: Colors.blackColor }}>Online Banking</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { searchBarItem('Credit Card'), setState({ choice: 'Credit Card' }) }}>
                                            <Text style={{ color: Colors.blackColor }}>Credit Card</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { searchBarItem('Take Away'), setState({ choice: 'All' }) }}>
                                            <Text style={{ color: Colors.blackColor }}>Take Away</Text>
                                        </TouchableOpacity>
                                    </View> : null}
                            </View>
                        </View>

                        <View style={{ flex: 6, flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <View style={{ width: '40%' }}>
                                <TouchableOpacity style={{ width: '100%' }} onPress={() => { setState({ day: !day }) }}>
                                    <View style={{ width: '100%', height: 50, backgroundColor: Colors.whiteColor, borderRadius: 10, elevation: 2, alignItems: 'center', flexDirection: 'row' }}>
                                        <EvilIcons name='calendar' size={25} color={Colors.primaryColor} style={{ marginLeft: '5%' }} />
                                        <View style={{ justifyContent: 'center', flex: 2 }}>
                                            <Text style={{ color: Colors.descriptionColor, marginLeft: '2%', fontSize: 12 }}>{moment(startDate).format('DD MMM YYYY')} - {moment(endDate).format('DD MMM YYYY')} </Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                                <DateTimePickerModal
                                    isVisible={showDateTimePicker}
                                    mode={pickerMode}
                                    onConfirm={(text) => {
                                        if (pick == 1) {
                                            var date_ob = new Date(text);
                                            let date = ("0" + date_ob.getDate()).slice(-2);
                                            let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
                                            let year = date_ob.getFullYear();
                                            setState({ startDate: year + "-" + month + "-" + date })
                                        } else {
                                            var date_ob = new Date(text);
                                            let date = ("0" + date_ob.getDate()).slice(-2);
                                            let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
                                            let year = date_ob.getFullYear();
                                            setState({ endDate: year + "-" + month + "-" + date })
                                        }

                                        setState({ showDateTimePicker: false })
                                    }}
                                    onCancel={() => {
                                        setState({ showDateTimePicker: false })
                                    }}
                                />
                                {day ?
                                    <View style={{ position: 'absolute', width: "100%", backgroundColor: Colors.whiteColor, marginTop: '20%', zIndex: 6000 }}>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.primaryColor }} onPress={() => {
                                            moment()
                                        }}>
                                            <Text style={{ color: Colors.whiteColor }}>Today</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }}
                                            onPress={() => {
                                                setState({ startDate: moment(moment(new Date()).subtract(1, 'days')).format('YYYY-MM-DD'), endDate: moment(new Date()).format('YYYY-MM-DD') })
                                            }}>
                                            <Text style={{ color: "#828282" }}>Yesterday</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }}
                                            onPress={() => {
                                                setState({ startDate: moment(moment(new Date()).subtract(7, 'days')).format('YYYY-MM-DD'), endDate: moment(new Date()).format('YYYY-MM-DD') })
                                            }}>
                                            <Text style={{ color: "#828282" }}>Last 7 days</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }}
                                            onPress={() => {
                                                setState({ startDate: moment(moment(new Date()).subtract(30, 'days')).format('YYYY-MM-DD'), endDate: moment(new Date()).format('YYYY-MM-DD') })
                                            }}>
                                            <Text style={{ color: "#828282" }}>Last 30 days</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }}
                                            onPress={() => {
                                                setState({ startDate: moment(moment(new Date()).startOf("month")).format('YYYY-MM-DD'), endDate: moment(moment(new Date()).endOf("month")).format('YYYY-MM-DD') })
                                            }}>
                                            <Text style={{ color: "#828282" }}>This month</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }}
                                            onPress={() => {
                                                setState({ startDate: moment(moment(moment(new Date()).startOf("month")).subtract(1, 'month')).format('YYYY-MM-DD'), endDate: moment(moment(moment(new Date()).endOf("month")).subtract(1, 'month')).format('YYYY-MM-DD') })
                                            }}>
                                            <Text style={{ color: "#828282" }}>Last month</Text>
                                        </TouchableOpacity>
                                        <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }}>
                                            <Text style={{ color: "#828282" }}>Custom range</Text>
                                        </TouchableOpacity>
                                        <View style={{ flexDirection: 'row' }}>
                                            <View style={{ flex: 1, marginLeft: 25 }}>
                                                <Text style={{ color: "#828282" }}>From</Text>
                                            </View>
                                            <View style={{ flex: 1 }}>
                                                <Text style={{ color: "#828282" }}>To</Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row' }}>
                                            <TouchableOpacity style={{ width: "38%", marginLeft: 25, borderWidth: 1, alignItems: "center", borderColor: "#919191", backgroundColor: Colors.fieldtBgColor }}
                                                onPress={() => { setState({ pick: 1, pick1: 0, pickerMode: 'date', showDateTimePicker: true }) }}>
                                                <Text style={{ fontSize: 12 }}>
                                                    {moment(startDate).format("DD MMM yyyy")}
                                                </Text>
                                            </TouchableOpacity>
                                            <View style={{ width: "8%" }}>
                                            </View>
                                            <TouchableOpacity style={{ width: "38%", borderWidth: 1, alignItems: "center", borderColor: "#919191", backgroundColor: Colors.fieldtBgColor }}
                                                onPress={() => { setState({ pick: 0, pick1: 1, pickerMode: 'date', showDateTimePicker: true }) }}>
                                                <Text style={{ fontSize: 12 }}>
                                                    {moment(endDate).format("DD MMM yyyy")}
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ flexDirection: 'row', marginTop: 20 }}>
                                            <TouchableOpacity style={{ width: "38%", marginLeft: 15, borderWidth: 1, alignItems: "center", borderColor: "#919191", backgroundColor: Colors.whiteColor, height: 30, borderRadius: 5, justifyContent: "center", alignItems: 'center' }}
                                                onPress={() => { setState({ day: false }) }}>
                                                <Text style={{ fontSize: 15, color: "#919191" }}>Cancel</Text>
                                            </TouchableOpacity>
                                            <View style={{ width: "8%" }}>
                                            </View>
                                            <TouchableOpacity style={{ width: "38%", borderWidth: 1, alignItems: "center", borderColor: Colors.primaryColor, backgroundColor: Colors.primaryColor, height: 30, borderRadius: 5, justifyContent: "center", alignItems: 'center' }}
                                                onPress={() => { setState({ day: false }), getDetail() }}>
                                                <Text style={{ fontSize: 15, color: Colors.whiteColor }}>Apply</Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ height: 20 }}>
                                        </View>
                                    </View>
                                    : null}
                            </View>
                            <View style={{ width: '4%' }}></View>
                            <TouchableOpacity style={{ width: '20%' }} onPress={() => { setState({ visible: true }); }}>
                                <View style={{ width: '100%', height: 50, backgroundColor: Colors.whiteColor, borderRadius: 10, elevation: 2, alignItems: 'center', flexDirection: 'row' }}>
                                    <AntDesign name='download' size={20} color={Colors.primaryColor} style={{ marginLeft: '5%' }} />
                                    <View style={{ justifyContent: 'center', flex: 2 }}>
                                        <Text style={{ color: Colors.descriptionColor, marginLeft: '5%', fontSize: 15 }}>Download</Text>
                                    </View>
                                </View>
                            </TouchableOpacity>
                            <View style={{ width: '4%' }}></View>
                            <TouchableOpacity style={{ width: '20%' }} onPress={() => { setState({ visible1: true }); }}>
                                <View style={{ width: '100%', height: 50, backgroundColor: Colors.whiteColor, borderRadius: 10, elevation: 2, alignItems: 'center', flexDirection: 'row' }}>
                                    <AntDesign name='upload' size={20} color={Colors.primaryColor} style={{ marginLeft: '5%', flex: 1 }} />
                                    <View style={{ justifyContent: 'center', flex: 2 }}>
                                        <Text style={{ color: Colors.descriptionColor, marginLeft: '2%', fontSize: 15 }}>Email</Text>
                                    </View>
                                </View>
                            </TouchableOpacity>
                        </View> */}
              </View>

              <View style={{ width: '100%', marginTop: 10, zIndex: -1 }}>
                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    width: windowWidth * 0.87,
                    height: windowHeight * 0.65,
                    marginTop: 10,
                    marginHorizontal: 30,
                    marginBottom: 10,
                    alignSelf: 'center',
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  }}>
                  {/* <View style={{ height: '88%', position: 'absolute', justifyContent: 'space-between', zIndex: showDetails ? 15 : 0, marginVertical: 0, marginTop: 80,alignSelf: 'center' }}>
                            <TouchableOpacity 
                                onPress={()=>{
                                    ScrollToTop();
                                }}
                                style={{  alignSelf: 'center', marginTop: '8%', zIndex: 10}}>
                                    <AntDesign name={'upcircle'} size={23} color={Colors.primaryColor} style={{ opacity: 0.4 }}/> 
                            </TouchableOpacity>
                            <TouchableOpacity 
                                onPress={()=>{
                                    ScrollToBottom();
                                }}
                                style={{  alignSelf: 'center', marginTop: '42%', zIndex: 10}}>
                                    <AntDesign name={'downcircle'} size={23} color={Colors.primaryColor} style={{ opacity: 0.4 }}/> 
                            </TouchableOpacity>
                            </View> */}
                  {/* <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <TouchableOpacity style={{ marginRight: 10 }}
                                    onpress={() => { setPerPage(1) }}
                                >
                                    <Text style= {{ color: Colors.descriptionColor }}>
                                        10
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ marginRight: 10 }}
                                    onpress={() => { setPerPage(2) }}
                                >
                                    <Text style= {{ color: Colors.descriptionColor }}>
                                        20
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ marginRight: 10 }}
                                    onpress={() => { setPerPage(5) }}
                                >
                                    <Text style= {{ color: Colors.descriptionColor }}>
                                        50
                                    </Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ marginRight: 10 }}
                                    onpress={() => { setPerPage(100) }}
                                >
                                    <Text style= {{ color: Colors.descriptionColor }}>
                                        100
                                    </Text>
                                </TouchableOpacity>
                            </View> */}
                  {showReportTableSection === REPORT_TABLE_SECTION.MAIN_PAGE ? (
                    <View style={{ marginTop: 10, flexDirection: 'row' }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '10%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            No.
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                        {/* <View style={{ marginLeft: '3%' }}>
                                                <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC)}>
                                                    <Entypo name='triangle-up' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                </TouchableOpacity>

                                                <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_DESC)}>
                                                    <Entypo name='triangle-down' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                </TouchableOpacity>
                                            </View> */}
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '19%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_ASC
                              ) {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_DESC,
                                );
                              } else {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              Payment Method
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}></Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '18%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                              ) {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC,
                                );
                              } else {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              Order
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              Qty
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '21%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                              ) {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC,
                                );
                              } else {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              Sales
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              RM
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                    </View>
                  ) : null}
                  {showReportTableSection ===
                    REPORT_TABLE_SECTION.SUMMARY_PAGE ? (
                    <View style={{ marginTop: 10, flexDirection: 'row' }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '10%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            No.
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                        {/* <View style={{ marginLeft: '3%' }}>
                                                <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC)}>
                                                    <Entypo name='triangle-up' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                </TouchableOpacity>

                                                <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_DESC)}>
                                                    <Entypo name='triangle-down' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                </TouchableOpacity>
                                            </View> */}
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '18%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_ASC
                              ) {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_DESC,
                                );
                              } else {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              Payment Method
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}></Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '16%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                              ) {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC,
                                );
                              } else {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              Transaction
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              Qty
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '13%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_ITEMS_SOLD_ASC
                              ) {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.TOTAL_ITEMS_SOLD_DESC,
                                );
                              } else {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.TOTAL_ITEMS_SOLD_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              Order
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              Qty
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_ITEMS_SOLD_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_ITEMS_SOLD_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '14%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC
                              ) {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC,
                                );
                              } else {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              Disc
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              RM
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '16%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                              ) {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC,
                                );
                              } else {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              Sales
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              RM
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          width: '16%',
                          borderRightWidth: 0,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.NET_SALES_ASC
                              ) {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.NET_SALES_DESC,
                                );
                              } else {
                                setCurrReportSummarySort(
                                  REPORT_SORT_FIELD_TYPE.NET_SALES_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              Net Sales
                            </Text>
                            <View style={{ flexDirection: 'row' }}>
                              <Text
                                style={{
                                  fontSize: switchMerchant ? 8 : 10,
                                  color: Colors.descriptionColor,
                                }}>
                                RM
                              </Text>
                              <Text
                                style={{
                                  fontSize: switchMerchant ? 8 : 10,
                                  color: Colors.primaryColor,
                                }}>
                                {' '}
                                *incl tax
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.NET_SALES_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportSummarySort ===
                                REPORT_SORT_FIELD_TYPE.NET_SALES_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                    </View>
                  ) : null}
                  {/* // <View style={{ marginTop: 10, flexDirection: 'row', }}>
                                    //     <View style={{ flexDirection: 'row', flex: 5, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                    //         <View style={{ flexDirection: 'column' }}>
                                    //             <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular' }}>Payment Method</Text>
                                    //             <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                    //         </View>
                                    //     </View>
                                    //     <View style={{ flexDirection: 'row', flex: 4, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                    //         <View>
                                    //             <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular' }}>Total Sales</Text>
                                    //             <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                    //         </View>
                                    //         <View style={{ marginLeft: '3%' }}>
                                    //             <Entypo name='triangle-up' size={12} color={Colors.descriptionColor}></Entypo>
                                    //             <Entypo name='triangle-down' size={12} color={Colors.descriptionColor} ></Entypo>
                                    //         </View>
                                    //     </View>
                                    //     <View style={{ flexDirection: 'row', flex: 4, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                    //         <View style={{ flexDirection: 'column' }}>
                                    //             <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular' }}>Total Transactions</Text>
                                    //             <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                    //         </View>
                                    //     </View>
                                    //     <View style={{ flexDirection: 'row', flex: 3, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                    //         <View>
                                    //             <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular' }}>Net Sales</Text>
                                    //             <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                    //         </View>
                                    //     </View>
                                    //     <View style={{ flexDirection: 'row', flex: 6, }}></View>
                                    // </View> */}
                  {showReportTableSection ===
                    REPORT_TABLE_SECTION.DETAILS_PAGE ? (
                    <View style={{ marginTop: 10, flexDirection: 'row' }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '6%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'No.\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                        {/* <View style={{ marginLeft: '3%' }}>
                                                <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC)}>
                                                    <Entypo name='triangle-up' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                </TouchableOpacity>

                                                <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_DESC)}>
                                                    <Entypo name='triangle-down' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                </TouchableOpacity>
                                            </View> */}
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '12%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC
                              ) {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_DESC,
                                );
                              } else {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {'Order ID\n'}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}></Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '11%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC
                              ) {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC,
                                );
                              } else {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {'Sales\n'}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              RM
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '18%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC
                              ) {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC,
                                );
                              } else {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {'Transaction\nDate'}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}></Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '9%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC
                              ) {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC,
                                );
                              } else {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {'Disc\n'}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              RM
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '9%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC
                              ) {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC,
                                );
                              } else {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {'Disc\n'}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              %
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '9%',
                          borderRightWidth: 1,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC
                              ) {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC,
                                );
                              } else {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {'Tax\n'}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              RM
                            </Text>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '13%',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC
                              ) {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_DESC,
                                );
                              } else {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {'Service\nCharge'}
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              RM
                            </Text>
                          </TouchableOpacity>
                        </View>

                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                      {/* <View style={{ flexDirection: 'row', flex: 1, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>GP</Text>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>%</Text>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC)}>
                                                    <Entypo name='triangle-up' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                </TouchableOpacity>

                                                <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_DESC)}>
                                                    <Entypo name='triangle-down' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                </TouchableOpacity>
                                            </View>
                                        </View> */}
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '13%',
                          borderRightWidth: 0,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingLeft: 10,
                          borderLeftWidth: 1,
                          borderLeftColor: 'lightgrey',
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <TouchableOpacity
                            onPress={() => {
                              if (
                                currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC
                              ) {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC,
                                );
                              } else {
                                setCurrReportDetailsSort(
                                  REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC,
                                );
                              }
                            }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {'Net Sales\n'}
                            </Text>
                            <View style={{ flexDirection: 'row' }}>
                              <Text
                                style={{
                                  fontSize: switchMerchant ? 8 : 10,
                                  color: Colors.descriptionColor,
                                }}>
                                RM
                              </Text>
                              <Text
                                style={{
                                  fontSize: switchMerchant ? 8 : 10,
                                  color: Colors.primaryColor,
                                }}>
                                {' '}
                                *incl tax
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        <View style={{ marginLeft: '3%' }}>
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 7 : 14}
                            color={
                              currReportDetailsSort ===
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </View>
                      </View>
                    </View>
                  ) : null}

                  {showReportTableSection === REPORT_TABLE_SECTION.MAIN_PAGE ? (
                    <FlatList
                      showsVerticalScrollIndicator={false}
                      data={paymentMethodMainPage}
                      // extraData={paymentMethodSales}
                      renderItem={renderMainPage}
                      keyExtractor={(item, index) => String(index)}
                      style={{ marginTop: 10 }}
                    />
                  ) : null}

                  {showReportTableSection ===
                    REPORT_TABLE_SECTION.SUMMARY_PAGE ? (
                    <>
                      {
                        //paymentMethodSales.length > 0 && showReportTableSection === REPORT_TABLE_SECTION.SUMMARY_PAGE && offlinePaymentClicked == true
                        paymentMethodSales.length > 0 &&
                          showReportTableSection ===
                          REPORT_TABLE_SECTION.SUMMARY_PAGE ? (
                          <FlatList
                            showsVerticalScrollIndicator={false}
                            data={sortReportDataList(
                              paymentMethodSales,
                              currReportSummarySort,
                            )
                              .filter((item) => {
                                if (search != '') {
                                  if (
                                    item.paymentMethod
                                      .toLowerCase()
                                      .includes(search.toLocaleLowerCase())
                                  ) {
                                    return true;
                                  } else if (
                                    item.totalTransactions
                                      .toString()
                                      .toLowerCase()
                                      .includes(search.toLocaleLowerCase())
                                  ) {
                                    return true;
                                  } else if (
                                    item.totalOrder
                                      .toString()
                                      .toLowerCase()
                                      .includes(search.toLocaleLowerCase())
                                  ) {
                                    return true;
                                  } else if (
                                    item.totalDiscount
                                      .toFixed(2)
                                      .toString()
                                      .toLowerCase()
                                      .includes(search.toLocaleLowerCase())
                                  ) {
                                    return true;
                                  } else if (
                                    item.totalSales
                                      .toFixed(2)
                                      .toString()
                                      .toLowerCase()
                                      .includes(search.toLocaleLowerCase())
                                  ) {
                                    return true;
                                  } else {
                                    return false;
                                  }
                                } else {
                                  return true;
                                }
                              })
                              .filter((item) => {
                                if (offlinePaymentClicked == true) {
                                  console.log('Show Offline1');
                                  // return item.paymentMethod === 'Offline-Credit-Card' ||
                                  //     item.paymentMethod === 'Offline-Paypal' ||
                                  //     item.paymentMethod === 'Offline-GrabPay' ||
                                  //     item.paymentMethod === 'Offline-Cash' ||
                                  //     item.paymentMethod === 'Offline payment';

                                  return (
                                    item.channel === undefined ||
                                    item.channel.startsWith('Offline')
                                  );
                                } else {
                                  console.log('Show Online1');
                                  // return item.paymentMethod !== 'Offline-Credit-Card' &&
                                  //     item.paymentMethod !== 'Offline-Paypal' &&
                                  //     item.paymentMethod !== 'Offline-GrabPay' &&
                                  //     item.paymentMethod !== 'Offline-Cash' &&
                                  //     item.paymentMethod !== 'Offline payment';

                                  return (
                                    item.channel &&
                                    !item.channel.startsWith('Offline')
                                  );
                                }

                                return false;
                              })
                              .slice(
                                (currentPage - 1) * perPage,
                                currentPage * perPage,
                              )}
                            // extraData={paymentMethodSales}
                            renderItem={renderItem}
                            keyExtractor={(item, index) => String(index)}
                            style={{ marginTop: 10 }}
                          />
                        ) : (
                          <View
                            style={{
                              height: windowHeight * 0.4,
                            }}>
                            <View
                              style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                              }}>
                              <Text style={{ color: Colors.descriptionColor }}>
                                - No Data Available -
                              </Text>
                            </View>
                          </View>
                        )
                      }
                    </>
                  ) : null}
                  {showReportTableSection ===
                    REPORT_TABLE_SECTION.DETAILS_PAGE ? (
                    <>
                      {transactionTypeSalesDetails.length > 0 &&
                        showReportTableSection ===
                        REPORT_TABLE_SECTION.DETAILS_PAGE ? (
                        <FlatList
                          showsVerticalScrollIndicator={false}
                          data={sortReportDataList(
                            transactionTypeSalesDetails,
                            currReportDetailsSort,
                          )
                            .filter((item) => {
                              if (search != '') {
                                if (
                                  item.orderId
                                    .toLowerCase()
                                    .includes(search.toLocaleLowerCase())
                                ) {
                                  return true;
                                } else if (
                                  item.totalPrice
                                    .toFixed(2)
                                    .toString()
                                    .toLowerCase()
                                    .includes(search.toLocaleLowerCase())
                                ) {
                                  return true;
                                } else if (
                                  item.discount
                                    .toFixed(2)
                                    .toString()
                                    .toLowerCase()
                                    .includes(search.toLocaleLowerCase())
                                ) {
                                  return true;
                                } else if (
                                  item.discountPercentage
                                    .toFixed(2)
                                    .toString()
                                    .toLowerCase()
                                    .includes(search.toLocaleLowerCase())
                                ) {
                                  return true;
                                } else if (
                                  item.tax
                                    .toFixed(2)
                                    .toString()
                                    .toLowerCase()
                                    .includes(search.toLocaleLowerCase())
                                ) {
                                  return true;
                                } else if (
                                  moment(item.createdAt)
                                    .format('DD MMM hh:mma')
                                    .toString()
                                    .toLowerCase()
                                    .includes(search.toLocaleLowerCase())
                                ) {
                                  return true;
                                } else {
                                  return false;
                                }
                              } else {
                                return true;
                              }
                            })
                            .slice(
                              (currentDetailsPage - 1) * perPage,
                              currentDetailsPage * perPage,
                            )}
                          // extraData={paymentMethodSales}
                          renderItem={renderItemDetails}
                          keyExtractor={(item, index) => String(index)}
                          style={{ marginTop: 10 }}
                        />
                      ) : (
                        <View
                          style={{
                            height: windowHeight * 0.4,
                          }}>
                          <View
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                              height: '100%',
                            }}>
                            <Text style={{ color: Colors.descriptionColor }}>
                              - No Data Available -
                            </Text>
                          </View>
                        </View>
                      )}
                    </>
                  ) : null}
                </View>

                {showReportTableSection ===
                  REPORT_TABLE_SECTION.SUMMARY_PAGE ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 10,
                      width: windowWidth * 0.87,
                      alignItems: 'center',
                      alignSelf: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Bold',
                        marginRight: '1%',
                      }}>
                      Items Showed
                    </Text>
                    <View
                      style={{
                        width: '13%', //65,
                        height: switchMerchant ? 20 : 35,
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 10,
                        justifyContent: 'center',
                        paddingHorizontal: 0,
                        //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                        // paddingTop: '-60%',
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        marginRight: '1%',
                      }}>
                      <Picker
                        style={{
                          //backgroundColor: 'red',
                          height: 35,

                          chevronContainer: {
                            display: 'none',
                          },
                          chevronDown: {
                            display: 'none',
                          },
                          chevronUp: {
                            display: 'none',
                          },
                        }}
                        onValueChange={(value, text) => {
                          setPerPage(value);
                          var currentPageTemp =
                            text.length > 0 ? parseInt(text) : 1;

                          setCurrentPage(
                            currentPageTemp > pageCount
                              ? pageCount
                              : currentPageTemp < 1
                                ? 1
                                : currentPageTemp,
                          );

                          setShowReportTableSection(REPORT_TABLE_SECTION.MAIN_PAGE);
                        }}
                      >
                        {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                          label: 'All',
                          value:
                            showReportTableSection ===
                              REPORT_TABLE_SECTION.SUMMARY_PAGE
                              ? paymentMethodSales.length
                              : transactionTypeSalesDetails.length,
                        }).map((value, index) => {
                          return (
                            <Picker.Item
                              label={value.label}
                              value={value.value}
                            />
                          )
                        })
                        }
                      </Picker>
                    </View>

                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Bold',
                        marginRight: '1%',
                      }}>
                      Page
                    </Text>
                    <View
                      style={{
                        width: switchMerchant ? 65 : 70,
                        height: switchMerchant ? 20 : 35,
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 10,
                        justifyContent: 'center',
                        paddingHorizontal: 22,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                      }}>
                      {console.log('currentPage')}
                      {console.log(currentPage)}

                      <TextInput
                        onChangeText={(text) => {
                          var currentPageTemp =
                            text.length > 0 ? parseInt(text) : 1;

                          setCurrentPage(
                            currentPageTemp > pageCount
                              ? pageCount
                              : currentPageTemp < 1
                                ? 1
                                : currentPageTemp,
                          );
                        }}
                        placeholder={
                          pageCount !== 0 ? currentPage.toString() : '0'
                        }
                        // placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        style={{
                          color: 'black',
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: 'NunitoSans-Regular',
                          marginTop: -15,
                          marginBottom: -15,
                          textAlign: 'center',
                          width: '100%',
                        }}
                        value={pageCount !== 0 ? currentPage.toString() : '0'}
                        defaultValue={
                          pageCount !== 0 ? currentPage.toString() : '0'
                        }
                        keyboardType={'numeric'}
                        onFocus={() => {
                          setPushPagingToTop(true);
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Bold',
                        marginLeft: '1%',
                        marginRight: '1%',
                      }}>
                      of {pageCount}
                    </Text>
                    <TouchableOpacity
                      style={{
                        width: switchMerchant ? 30 : 45,
                        height: switchMerchant ? 20 : 28,
                        backgroundColor: Colors.primaryColor,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={() => {
                        prevPage();
                      }}>
                      <ArrowLeft
                        color={Colors.whiteColor}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        width: switchMerchant ? 30 : 45,
                        height: switchMerchant ? 20 : 28,
                        backgroundColor: Colors.primaryColor,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={() => {
                        nextPage();
                      }}>
                      <ArrowRight
                        color={Colors.whiteColor}
                      />
                    </TouchableOpacity>
                  </View>
                ) : showReportTableSection ===
                  REPORT_TABLE_SECTION.DETAILS_PAGE ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 10,
                      width: windowWidth * 0.87,
                      alignItems: 'center',
                      alignSelf: 'center',
                      justifyContent: 'flex-end',
                    }}>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Bold',
                        marginRight: '1%',
                      }}>
                      Items Showed
                    </Text>
                    <View
                      style={{
                        width: '13%', //65,
                        height: switchMerchant ? 20 : 35,
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 10,
                        justifyContent: 'center',
                        paddingHorizontal: 0,
                        //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                        // paddingTop: '-60%',
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        marginRight: '1%',
                      }}>
                      <Picker
                        style={{
                          //backgroundColor: 'red',
                          height: 35,

                          chevronContainer: {
                            display: 'none',
                          },
                          chevronDown: {
                            display: 'none',
                          },
                          chevronUp: {
                            display: 'none',
                          },
                        }}
                        onValueChange={(value) => {
                          setPerPage(value);
                          setShowReportTableSection(REPORT_TABLE_SECTION.MAIN_PAGE);

                        }}
                      >
                        {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                          label: 'All',
                          value:
                            showReportTableSection ===
                              REPORT_TABLE_SECTION.DETAILS_PAGE
                              ? paymentMethodSales.length
                              : transactionTypeSalesDetails.length,
                        }).map((value, index) => {
                          return (
                            <Picker.Item
                              label={value.label}
                              value={value.value}
                            />
                          )
                        })
                        }
                      </Picker>
                    </View>

                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Bold',
                        marginRight: '1%',
                      }}>
                      Page
                    </Text>
                    <View
                      style={{
                        width: switchMerchant ? 65 : 70,
                        height: switchMerchant ? 20 : 35,
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 10,
                        justifyContent: 'center',
                        paddingHorizontal: 22,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                      }}>
                      {console.log('currentDetailsPage')}
                      {console.log(currentDetailsPage)}

                      <TextInput
                        onChangeText={(text) => {
                          var currentPageTemp =
                            text.length > 0 ? parseInt(text) : 1;

                          setCurrentDetailsPage(
                            currentPageTemp > pageCount
                              ? pageCount
                              : currentPageTemp < 1
                                ? 1
                                : currentPageTemp,
                          );
                        }}
                        placeholder={
                          pageCount !== 0 ? currentDetailsPage.toString() : '0'
                        }
                        // placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        style={{
                          color: 'black',
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: 'NunitoSans-Regular',
                          marginTop: -15,
                          marginBottom: -15,
                          textAlign: 'center',
                          width: '100%',
                        }}
                        value={
                          pageCount !== 0 ? currentDetailsPage.toString() : '0'
                        }
                        defaultValue={
                          pageCount !== 0 ? currentDetailsPage.toString() : '0'
                        }
                        keyboardType={'numeric'}
                        onFocus={() => {
                          setPushPagingToTop(true);
                        }}
                      />
                    </View>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Bold',
                        marginLeft: '1%',
                        marginRight: '1%',
                      }}>
                      of {pageCount}
                    </Text>
                    <TouchableOpacity
                      style={{
                        width: switchMerchant ? 30 : 45,
                        height: switchMerchant ? 20 : 28,
                        backgroundColor: Colors.primaryColor,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={() => {
                        prevDetailsPage();
                      }}>
                      <ArrowLeft
                        color={Colors.whiteColor}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        width: switchMerchant ? 30 : 45,
                        height: switchMerchant ? 20 : 28,
                        backgroundColor: Colors.primaryColor,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={() => {
                        nextDetailsPage();
                      }}>
                      <ArrowRight
                        color={Colors.whiteColor}
                      />
                    </TouchableOpacity>
                  </View>
                ) : null}
              </View>
            </View>

            <Modal
              style={{ flex: 1 }}
              visible={visible}
              transparent={true}
              animationType="slide">
              <KeyboardAvoidingView
                behavior="padding"
                style={{
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: Dimensions.get('window').height,
                }}>
                <View style={styles.confirmBox}>
                  <Text
                    style={{
                      fontSize: 24,
                      justifyContent: 'center',
                      alignSelf: 'center',
                      marginTop: 40,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    Enter your email
                  </Text>
                  <View
                    style={{
                      justifyContent: 'center',
                      alignSelf: 'center',
                      alignContent: 'center',
                      marginTop: 20,
                      flexDirection: 'row',
                      width: '80%',
                    }}>
                    <View
                      style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                      <Text
                        style={{ color: Colors.descriptionColor, fontSize: 20 }}>
                        email:
                      </Text>
                    </View>
                    <TextInput
                      underlineColorAndroid={Colors.fieldtBgColor}
                      style={[styles.textInput8, { paddingLeft: 5 }]}
                      placeholder="Enter your email"
                      // style={{
                      //     // paddingLeft: 1,
                      // }}
                      //defaultValue={extentionCharges}
                      onChangeText={(text) => {
                        // setState({ exportEmail: text });
                        setExportEmail(text);
                      }}
                      value={exportEmail}
                    // placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                    />
                  </View>

                  <View
                    style={{
                      alignSelf: 'center',
                      marginTop: 20,
                      justifyContent: 'center',
                      alignItems: 'center',
                      // width: 260,
                      width: windowWidth * 0.2,
                      height: 60,
                      alignContent: 'center',
                      flexDirection: 'row',
                      marginTop: 40,
                    }}>
                    <TouchableOpacity
                      onPress={emailDashboard}
                      style={{
                        backgroundColor: Colors.fieldtBgColor,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        height: 60,
                        borderBottomLeftRadius: 10,
                        borderRightWidth: StyleSheet.hairlineWidth,
                        borderTopWidth: StyleSheet.hairlineWidth,
                      }}>
                      <Text
                        style={{
                          fontSize: 22,
                          color: Colors.primaryColor,
                          fontFamily: 'NunitoSans-SemiBold',
                        }}>
                        Email
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        // setState({ visible: false });
                        setVisible(false);
                      }}
                      style={{
                        backgroundColor: Colors.fieldtBgColor,
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        height: 60,
                        borderBottomRightRadius: 10,
                        borderTopWidth: StyleSheet.hairlineWidth,
                      }}>
                      <Text
                        style={{
                          fontSize: 22,
                          color: Colors.descriptionColor,
                          fontFamily: 'NunitoSans-SemiBold',
                        }}>
                        Cancel
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </KeyboardAvoidingView>
            </Modal>

            <Modal
              style={{ flex: 1 }}
              visible={visible1}
              transparent={true}
              animationType="slide">
              <View
                style={{
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: Dimensions.get('window').height,
                }}>
                <View style={styles.confirmBox}>
                  <View
                    style={{
                      flex: 3,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}></View>
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <TouchableOpacity
                      style={{
                        flex: 1,
                        borderRightWidth: StyleSheet.hairlineWidth,
                        justifyContent: 'center',
                      }}
                      onPress={() => {
                        // email();
                      }}>
                      <Text
                        style={{
                          color: Colors.primaryColor,
                          fontSize: 24,
                          fontWeight: '400',
                          textAlign: 'center',
                        }}>
                        Email
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ flex: 1, justifyContent: 'center' }}
                      onPress={() => {
                        setState({ visible1: !visible1 });
                      }}>
                      <Text
                        style={{
                          color: Colors.descriptionColor,
                          fontSize: 24,
                          fontWeight: '400',
                          textAlign: 'center',
                        }}>
                        Cancel
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>
          </View>

        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    flexDirection: 'row',
  },
  sidebar: {
    width: Dimensions.get('screen').width * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 20,
    width: Dimensions.get('screen').width * (1 - Styles.sideBarWidth),
    backgroundColor: Colors.highlightColor,
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  confirmBox: {
    // width: '30%',
    // height: '30%',
    // borderRadius: 30,
    // backgroundColor: Colors.whiteColor,
    width: Dimensions.get('screen').width * 0.4,
    height: Dimensions.get('screen').height * 0.3,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: 'space-between',
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalView: {
    height: Dimensions.get('screen').width * 0.2,
    width: Dimensions.get('screen').width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get('screen').width * 0.03,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: Dimensions.get('screen').width * 0.02,
    top: Dimensions.get('screen').width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: 'center',
    top: '20%',
    position: 'absolute',
  },
  modalBody: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitleText: {
    fontFamily: 'NunitoSans-Bold',
    textAlign: 'center',
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 25,
    width: '20%',
  },
  modalSaveButton: {
    width: Dimensions.get('screen').width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  submitText: {
    height: Dimensions.get('screen').height * 0.07,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: 'row',
    color: '#4cd964',
    textAlign: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
});
export default ReportSalesPaymentMethod;
