import React, { Component, useEffect, useReducer, useState } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  TextInput,
  Dimensions,
  FlatList,
  Modal,
  PermissionsAndroid,
  Platform,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import * as User from "../util/User";
import Icon from "react-native-vector-icons/Feather";
import Icon1 from "react-native-vector-icons/FontAwesome";
import { ReactComponent as EditGreen } from "../assets/svg/EditGreen.svg";
import Icon2 from "react-native-vector-icons/EvilIcons";
import Icon3 from "react-native-vector-icons/Foundation";
import Icon4 from "react-native-vector-icons/FontAwesome5";
import DropDownPicker from "react-native-dropdown-picker";
// import { ceil } from 'react-native-reanimated';
// import CheckBox from '@react-native-community/checkbox';
import DateTimePicker from "react-native-modal-datetime-picker";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import moment from "moment";
import Close from "react-native-vector-icons/AntDesign";
import DatePicker from "react-datepicker";
import ApiClient from "../util/ApiClient";
import API from "../constant/API";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Styles from "../constant/Styles";
// import DocumentPicker from 'react-native-document-picker';
import Ionicons from "react-native-vector-icons/Ionicons";
import "react-native-get-random-values";
import { customAlphabet } from "nanoid";
import { CommonStore } from "../store/commonStore";
import { MerchantStore } from "../store/merchantStore";
import { UserStore } from "../store/userStore";
import { Picker } from "react-native";
import {
  STOCK_TRANSFER_STATUS,
  STOCK_TRANSFER_STATUS_PARSED,
  EMAIL_REPORT_TYPE,
  PURCHASE_ORDER_STATUS,
} from "../constant/common";
import { convertArrayToCSV, generateEmailReport } from "../util/common";
// import RNPickerSelect from 'react-native-picker-select';
import AntDesign from "react-native-vector-icons/AntDesign";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import { ReactComponent as GCalendarGrey } from "../assets/svg/GCalendarGrey.svg";
import { CSVLink } from "react-csv";

// const RNFS = require('react-native-fs');

const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 12);

const StockTransferScreen = (props) => {
  const { navigation } = props;

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const [stockTransfer, setStockTransfer] = useState(true);
  const [addPurchase, setAddPurchase] = useState(false);
  const [editPurchase, setEditPurchase] = useState(false);
  const [addStockTransfer, setAddStockTransfer] = useState(false);
  const [stockList, setStockList] = useState([]);
  const [stockTransferList, setStockTransferList] = useState([]);
  const [stockTakeList, setStockTakeList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [itemsToOrder, setItemsToOrder] = useState([{}, {}, {}]);
  const [itemsToOrder2, setItemsToOrder2] = useState([{}, {}, {}]);
  const [addStockTransferList, setAddStockTransferList] = useState([
    {},
    {},
    {},
  ]);
  const [addCountedStockTakeList, setAddCountedStockTakeList] = useState([
    {},
    {},
    {},
  ]);
  const [addUnCountedStockTakeList, setAddUnCountedStockTakeList] = useState([
    {},
    {},
    {},
  ]);
  const [productList, setProductList] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);
  const [isSelected3, setIsSelected3] = useState(true);
  const [isSelected4, setIsSelected4] = useState(false);
  const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
  const [date, setDate] = useState(Date.now());
  const [date1, setDate1] = useState(Date.now());
  const [createdDate, setCreatedDate] = useState(Date.now());
  const [visible, setVisible] = useState(false);
  const [Email, setEmail] = useState("");
  const [modal, setModal] = useState(false);
  // const [// outletId, set// outletId] = useState(1);
  const [outletId, setOutletId] = useState(User.getOutletId());
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");
  const [ideal, setIdeal] = useState("");
  const [minimum, setMinimum] = useState("");
  const [itemId, setItemId] = useState("");
  const [choose, setChoose] = useState(null);

  const [loading, setLoading] = useState(false);

  //////////////////////////////////////////////////////////////////////

  const [poId, setPoId] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [exportEmail, setExportEmail] = useState("");
  const [exportModal, setExportModal] = useState(false);
  const [importModal, setImportModal] = useState(false);

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);

  const [poStatus, setPoStatus] = useState(STOCK_TRANSFER_STATUS.CREATED);

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState("");

  const [selectedSourceOutletIdPrev, setSelectedSourceOutletIdPrev] =
    useState("");
  const [selectedSourceOutletId, setSelectedSourceOutletId] = useState("");

  const [outletSupplyItemDropdownList, setOutletSupplyItemDropdownList] =
    useState([]);

  const [poItems, setPoItems] = useState([
    {
      outletSupplyItemId: "",
      name: "",
      sku: "",
      unit: "",
      skuMerchant: "",
      quantity: 0,
      transferQuantity: 0,
      balance: 0,
      price: 0,
      totalPrice: 0,

      supplyItem: null,
    },
  ]);

  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);

  const [outletSupplyItems, setOutletSupplyItems] = useState([]);

  // const [supplyItems, setSupplyItems] = useState([]);
  const supplyItems = CommonStore.useState((s) => s.supplyItems);
  const supplyItemsSkuDict = CommonStore.useState((s) => s.supplyItemsSkuDict);

  const allOutletsSupplyItemsSkuDict = CommonStore.useState(
    (s) => s.allOutletsSupplyItemsSkuDict
  );
  const allOutletsSupplyItems = CommonStore.useState(
    (s) => s.allOutletsSupplyItems
  );
  const allOutletsSupplyItemsDict = CommonStore.useState(
    (s) => s.allOutletsSupplyItemsDict
  );

  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const merchantId = UserStore.useState((s) => s.merchantId);
  const stockTransfers = CommonStore.useState((s) => s.stockTransfers);

  const userName = UserStore.useState((s) => s.name);
  const userId = UserStore.useState((s) => s.firebaseUid);
  const merchantName = MerchantStore.useState((s) => s.name);

  const currOutlet = MerchantStore.useState((s) => s.currOutlet);
  const currOutletId = MerchantStore.useState((s) => s.currOutletId);

  const isLoading = CommonStore.useState((s) => s.isLoading);

  const dropDownRef = React.useRef();
  const dropDownRef1 = React.useRef();

  const selectedStockTransferEdit = CommonStore.useState(
    (s) => s.selectedStockTransferEdit
  );
  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  useEffect(() => {
    if (selectedStockTransferEdit) {
      // insert info

      setEditMode(false);

      setPoId(selectedStockTransferEdit.stId);
      setPoStatus(selectedStockTransferEdit.status);
      setSelectedSourceOutletId(selectedStockTransferEdit.sourceOutletId);
      setSelectedTargetOutletId(selectedStockTransferEdit.targetOutletId);
      setDate(selectedStockTransferEdit.estimatedArrivalDate);
      setCreatedDate(selectedStockTransferEdit.createdAt);

      if (selectedStockTransferEdit.stItems) {
        setPoItems(selectedStockTransferEdit.stItems);

        // delay the state update
        setTimeout(() => {
          setPoItems(selectedStockTransferEdit.stItems);
        }, 100);
      }
    } else {
      // designed to always mounted, thus need clear manually...

      setEditMode(false);

      if (stockTransfers.length > 0) {
        // setPoId(`ST${moment().format('MMM').toUpperCase() + moment().format('YY') + (stockTransfers.length + 1).toString().padStart(4, '0')}`);
        setPoId(`ST${(stockTransfers.length + 1).toString().padStart(4, "0")}`);
      }
      setPoStatus(STOCK_TRANSFER_STATUS.CREATED);
      if (allOutlets[0]) {
        setSelectedSourceOutletId(allOutlets[0].uniqueId);
        setSelectedTargetOutletId(allOutlets[0].uniqueId);
      }
      setDate(Date.now());

      if (outletSupplyItems.length > 0 && supplyItems.length > 0) {
        setPoItems([
          {
            outletSupplyItemId: outletSupplyItems[0].uniqueId,
            name: outletSupplyItems[0].name,
            sku: outletSupplyItems[0].sku,
            unit: outletSupplyItems[0].unit,
            skuMerchant: outletSupplyItems[0].skuMerchant,
            quantity: outletSupplyItems[0].quantity,
            transferQuantity: 0,
            balance: 0,
            price: outletSupplyItems[0].price,
            totalPrice: 0,

            supplyItem: supplyItems[0],
          },
        ]);
      }

      // if (outletSupplyItems.length > 0 && Object.keys(allOutletsSupplyItemsDict).length > 0) {
      // if (outletSupplyItems.length > 0) {
      //   setPoItems([
      //     {
      //       outletSupplyItemId: outletSupplyItems[0].uniqueId,
      //       name: outletSupplyItems[0].name,
      //       sku: outletSupplyItems[0].sku,
      //       quantity: outletSupplyItems[0].quantity,
      //       transferQuantity: 0,
      //       price: outletSupplyItems[0].price,
      //       totalPrice: 0,
      //     }
      //   ]);
      // }
      // else {
      //   setPoItems([
      //     {
      //       outletSupplyItemId: '',
      //       name: '',
      //       sku: '',
      //       quantity: 0,
      //       transferQuantity: 0,
      //       price: 0,
      //       totalPrice: 0,
      //     }
      //   ]);
      // }
    }
  }, [selectedStockTransferEdit, addStockTransfer]);

  useEffect(() => {
    if (selectedStockTransferEdit === null && stockTransfers.length > 0) {
      // setPoId(`ST${moment().format('MMM').toUpperCase() + moment().format('YY') + (stockTransfers.length + 1).toString().padStart(4, '0')}`);
      setPoId(`ST${(stockTransfers.length + 1).toString().padStart(4, "0")}`);
    }
  }, [stockTransfers]);

  useEffect(() => {
    if (outletSupplyItems.length > 0 && supplyItems.length > 0) {
      // setPoItems([
      //   {
      //     outletSupplyItemId: outletSupplyItems[0].uniqueId,
      //     name: outletSupplyItems[0].name,
      //     sku: outletSupplyItems[0].sku,
      //     skuMerchant: outletSupplyItems[0].skuMerchant,
      //     quantity: outletSupplyItems[0].quantity,
      //     transferQuantity: outletSupplyItems[0].transferQuantity,
      //     balance: outletSupplyItems[0].balance,
      //     price: outletSupplyItems[0].price,
      //     totalPrice: 0,
      //     supplyItem: supplyItems[0],
      //   }
      // ]);
      // delay the state updating
      // setTimeout(() => {
      //   setPoItems([
      //     {
      //       outletSupplyItemId: outletSupplyItems[0].uniqueId,
      //       name: outletSupplyItems[0].name,
      //       sku: outletSupplyItems[0].sku,
      //       skuMerchant: outletSupplyItems[0].skuMerchant,
      //       quantity: outletSupplyItems[0].quantity,
      //       transferQuantity: outletSupplyItems[0].transferQuantity,
      //       balance: outletSupplyItems[0].balance,
      //       price: outletSupplyItems[0].price,
      //       totalPrice: 0,
      //       supplyItem: supplyItems[0],
      //     }
      //   ]);
      // }, 500);
    } else {
      setPoItems([
        {
          outletSupplyItemId: "",
          name: "",
          sku: "",
          unit: "",
          skuMerchant: "",
          quantity: 0,
          transferQuantity: 0,
          price: 0,
          totalPrice: 0,

          supplyItem: null,
        },
      ]);
    }
  }, [selectedSourceOutletId]);

  useEffect(() => {
    setOutletSupplyItems(
      allOutletsSupplyItems.filter((outletSupplyItem) => {
        if (
          outletSupplyItem.outletId === selectedSourceOutletId &&
          outletSupplyItem.quantity > 0
        ) {
          return true;
        }
      })
    );
  }, [allOutletsSupplyItems, selectedSourceOutletId]);

  useEffect(() => {
    const outletDropdownListTemp = allOutlets.map((outlet) => ({
      label: outlet.name,
      value: outlet.uniqueId,
    }));

    setTargetOutletDropdownList(outletDropdownListTemp);

    if (selectedTargetOutletId === "" && allOutlets.length > 0) {
      setSelectedTargetOutletId(allOutlets[0].uniqueId);
      setSelectedSourceOutletId(allOutlets[0].uniqueId);
    }
  }, [allOutlets]);

  useEffect(() => {
    setOutletSupplyItemDropdownList(
      outletSupplyItems.map((outletSupplyItem) => {
        // if (selectedSupplierId === supplyItem.supplierId) {
        //   return { label: supplyItem.name, value: supplyItem.uniqueId };
        // }

        return {
          label: outletSupplyItem.name,
          value: outletSupplyItem.uniqueId,
        };
      })
    );

    if (
      outletSupplyItems.length > 0 &&
      poItems.length === 1 &&
      poItems[0].outletSupplyItemId === ""
    ) {
      setPoItems([
        {
          outletSupplyItemId: outletSupplyItems[0].uniqueId,
          name: outletSupplyItems[0].name,
          sku: outletSupplyItems[0].sku,
          unit: outletSupplyItems[0].unit,
          skuMerchant: outletSupplyItems[0].skuMerchant,
          quantity: outletSupplyItems[0].quantity,
          transferQuantity: 0,
          balance: 0,
          price: outletSupplyItems[0].price,
          totalPrice: 0,

          supplyItem: supplyItems[0],
        },
      ]);
    } else if (
      poItems[0].outletSupplyItemId !== "" &&
      Object.keys(allOutletsSupplyItemsDict).length > 0
    ) {
      if (
        selectedSourceOutletIdPrev.length > 0 &&
        selectedSourceOutletIdPrev !== selectedSourceOutletId
      ) {
        // reset current outlet supply items

        setPoItems([
          {
            outletSupplyItemId: outletSupplyItems[0].uniqueId,
            name: outletSupplyItems[0].name,
            unit: outletSupplyItems[0].unit,
            sku: outletSupplyItems[0].sku,
            skuMerchant: outletSupplyItems[0].skuMerchant,
            quantity: outletSupplyItems[0].quantity,
            transferQuantity: 0,
            balance: 0,
            price: outletSupplyItems[0].price,
            totalPrice: 0,

            supplyItem: supplyItems[0],
          },
        ]);

        // disabled first, outletSupplyItems might slow to retrieve
        // setSelectedSourceOutletIdPrev(selectedSourceOutletId);
      } else {
        var poItemsTemp = [...poItems];

        for (var i = 0; i < poItemsTemp.length; i++) {
          poItemsTemp[i] = {
            ...poItemsTemp[i],
            quantity: allOutletsSupplyItemsDict[
              poItemsTemp[i].outletSupplyItemId
            ]
              ? allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId]
                  .quantity
              : 0, // check if the supply item sku for this outlet existed | might changed in real time
            price: allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId]
              ? allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId]
                  .price
              : 0, // might changed in real time
          };
        }

        setPoItems(poItemsTemp);
      }
    }
  }, [
    outletSupplyItems,
    allOutletsSupplyItemsDict,
    supplyItems,
    selectedSourceOutletIdPrev,
  ]);

  useEffect(() => {
    console.log("balance");
    console.log(poItems.reduce((accum, poItem) => accum + poItem.balance, 0));
    setSubtotal(poItems.reduce((accum, poItem) => accum + poItem.balance, 0));
  }, [poItems]);

  useEffect(() => {
    console.log("subtotal");
    console.log(
      poItems.reduce((accum, poItem) => accum + poItem.totalPrice, 0)
    );
    setSubtotal(
      poItems.reduce((accum, poItem) => accum + poItem.totalPrice, 0)
    );
  }, [poItems]);

  // useEffect(() => {
  //   console.log('taxTotal');
  //   console.log(subtotal * selectedSupplier.taxRate);
  //   setTaxTotal(subtotal * selectedSupplier.taxRate);
  // }, [subtotal]);

  useEffect(() => {
    console.log("finalTotal");
    console.log(subtotal - discountTotal + taxTotal);
    setFinalTotal(subtotal - discountTotal + taxTotal);
  }, [subtotal, discountTotal, taxTotal]);

  useEffect(() => {
    requestStoragePermission();

    setPoId(nanoid());
  }, []);

  // useEffect(() => {
  //   if (poItems.find(poItem => poItem.outletSupplyItemId === poItem.outletSupplyItemId)){
  //     Alert.alert(
  //       'Error',
  //       'Same Supply Item.',
  //       [
  //         {
  //           text: "OK", onPress: () => {
  //           }
  //         }
  //       ],
  //     );
  //   return;
  //   }
  // }, [poItems]);

  //////////////////////////////////////////////////////////////////////

  const setState = () => {};

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            // bottom: -2,
            bottom: switchMerchant ? "2%" : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? { right: windowWidth * 0.1 }
            : {},
          windowWidth <= 768 ? { right: windowWidth * 0.12 } : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Stock Transfer
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // componentDidMount = () => {
  //   // setInterval(() => {
  //   //   getStockOrder()
  //   //   getStockTransfer()
  //   //   getLowStock()
  //   // }, 1000);
  //   getStockOrder()
  //   getStockTransfer()
  //   getLowStock()
  // }

  // async componentWillMount = () => {
  //   await requestStoragePermission()
  // }

  const requestStoragePermission = async () => {
    try {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: "KooDoo Merchant Storage Permission",
          message: "KooDoo Merchant App needs access to your storage ",
          buttonNegative: "Cancel",
          buttonPositive: "OK",
        }
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        console.log("Storage permission granted");
      } else {
        console.log("Storage permission denied");
      }
    } catch (err) {
      console.warn(err);
    }
  };

  //   const importCSV = () => {
  //     try {
  //       const res = DocumentPicker.pick({
  //         type: [DocumentPicker.types.csv],
  //       });
  //       console.log(
  //         res.uri,
  //         res.type,
  //         res.name,
  //         res.size
  //       );
  //     } catch (err) {
  //       if (DocumentPicker.isCancel(err)) {
  //         // User cancelled the picker, exit any dialogs or menus and move on
  //       } else {
  //         throw err;
  //       }
  //     }
  //   }

  const convertTemplateToExcelFormat = () => {
    var excelTemplate = [];

    for (var i = 0; i < poItems.length; i++) {
      if (i <= 5) {
        var excelColumn = {
          "Product Name": poItems[i].name,
          SKU: poItems[i].skuMerchant,
          Unit: poItems[i].unit,
          "In stock": poItems[i].quantity,
          "Transfer Qty": poItems[i].transferQuantity,
          "Balance Stock": poItems[i].balance,
          "Cost(RM)": poItems[i].price,
          "Subtotal(RM)": poItems[i].totalPrice,
        };
        excelTemplate.push(excelColumn);
      } else {
      }
    }

    console.log("excelTemplate");
    console.log(excelTemplate);

    return excelTemplate;
  };

  const importSelectFile = async () => {
    // try {
    //   const res = await DocumentPicker.pick({
    //     type: [DocumentPicker.types.xlsx],
    //   });
    //   console.log(res)
    // } catch (err) {
    //   if (DocumentPicker.isCancel(err)) {
    //   } else {
    //     throw err;
    //   }
    // }
  };

  //error show readAsArrayBuffer not implemented
  const importTemplate = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      console.log(d);
    });
  };

  //   const exportTemplate = () => {
  //     const excelTemplate = convertTemplateToExcelFormat();

  //     var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/koodoo-Inventory-Stock-Transfer${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
  //     var excelWorkSheet = XLSX.utils.json_to_sheet(excelTemplate);
  //     var excelWorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, "Stock Transfer Template");

  //     const workBookData = XLSX.write(excelWorkBook, {
  //       type: 'binary',
  //       bookType: 'xlsx',
  //     });

  //     RNFS.writeFile(excelFile, workBookData, 'ascii')
  //       .then((success) => {
  //         console.log(`wrote file ${excelFile}`);

  //         Alert.alert(
  //           'Success',
  //           `Send to ${excelFile}`,
  //           [{ text: 'OK', onPress: () => { } }],
  //           { cancelable: false },
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }

  //   const downloadCsv = () => {
  //     if (stockTransfers) {
  //       const csvData = convertArrayToCSV(stockTransfers);

  //       const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-stock-transfer-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
  //       console.log("PATH", pathToWrite)
  //       RNFetchBlob.fs
  //         .writeFile(pathToWrite, csvData, 'utf8')
  //         .then(() => {
  //           console.log(`wrote file ${pathToWrite}`);
  //           // wrote file /storage/emulated/0/Download/data.csv
  //           Alert.alert(
  //             'Success',
  //             `Send to ${pathToWrite}`,
  //             [{ text: 'OK', onPress: () => { } }],
  //             { cancelable: false },
  //           );
  //         })
  //         .catch(error => console.error(error));
  //     }

  //     // var body = {
  //     //   data: stockTransferList
  //     // }
  //     // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
  //     //   console.log("RESULT", result)
  //     //   if (result !== null) {
  //     //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/StockTransferData.csv`;
  //     //     console.log("PATH", pathToWrite)
  //     //     RNFetchBlob.fs
  //     //       .writeFile(pathToWrite, result, 'utf8')
  //     //       .then(() => {
  //     //         console.log(`wrote file ${pathToWrite}`);
  //     //         // wrote file /storage/emulated/0/Download/data.csv
  //     //         Alert.alert(
  //     //           'Success',
  //     //           'The data had exported',
  //     //           [{ text: 'OK', onPress: () => { } }],
  //     //           { cancelable: false },
  //     //         );
  //     //       })
  //     //       .catch(error => console.error(error));
  //     //   }
  //     // });
  //   }

  const convertDataToExcelFormat = () => {
    var excelData = [];

    for (var i = 0; i < stockTransfers.length; i++) {
      var excelRow = {
        "Stock Transfer ID": stockTransfers[i].stId,
        "Created Date": moment(stockTransfers[i].orderDate).format(
          "DD/MM/YYYY"
        ),
        From: stockTransfers[i].sourceOutletName,
        To: stockTransfers[i].targetOutletName,
        Status: stockTransfers[i].status,
        "Transfer Product": stockTransfers[i].stItems
          .map((item) => item.name)
          .join(","),
        Instock: stockTransfers[i].stItems
          .map((item) => item.quantity)
          .join(","),
        "Transfer Quantity": stockTransfers[i].stItems
          .map((item) => `${item.transferQuantity}`)
          .join(","),
        Balance: stockTransfers[i].stItems
          .map((item) => item.balance)
          .join(","),
      };

      excelData.push(excelRow);
    }

    console.log("excelData");
    console.log(excelData);

    return excelData;
  };

  //   const downloadExcel = () => {
  //     const excelData = convertDataToExcelFormat();

  //     var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/koodoo-Stock-Transfer${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
  //     var excelWorkSheet = XLSX.utils.json_to_sheet(excelData);
  //     var excelWorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, "Stock Transfer");

  //     const workBookData = XLSX.write(excelWorkBook, {
  //       type: 'binary',
  //       bookType: 'xlsx',
  //     });

  //     RNFS.writeFile(excelFile, workBookData, 'ascii')
  //       .then((success) => {
  //         console.log(`wrote file ${excelFile}`);

  //         Alert.alert(
  //           'Success',
  //           `Send to ${excelFile}`,
  //           [{ text: 'OK', onPress: () => { } }],
  //           { cancelable: false },
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }

  const importFunc = () => {
    var body = {
      data: stockTransferList,
    };
    // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
    //   console.log(result)
    // });

    Alert.alert(
      "Success",
      "The data has been imported",
      [{ text: "OK", onPress: () => {} }],
      { cancelable: false }
    );
  };

  const getLowStock = () => {
    // ApiClient.GET(API.getLowStock + outletId).then((result) => {
    //   setState({ stockList: result });
    // });
  };

  const getStockTransfer = () => {
    // ApiClient.GET(API.getTransfer + 1).then((result) => {
    //   setState({ stockTransferList: result, stockTakeList: result });
    // });
  };

  const getStockOrder = () => {
    // ApiClient.GET(API.getStockOrder + outletId).then((result) => {
    //   setState({ orderList: result })
    // });
  };

  // function here
  // const showDateTimePicker = () => {
  //   // setState({ isDateTimePickerVisible: true });
  //   setIsDateTimePickerVisible(true);
  // };

  // const hideDateTimePicker = () => {
  //   // setState({ isDateTimePickerVisible: false });
  //   setIsDateTimePickerVisible(false);
  // };

  // const handleDatePicked = date => {

  //   // setState({ date: date.toString() });
  //   setDate(date);
  // };

  const renderStockItem = ({ item }) =>
    item.items.map((result) => {
      return (
        <View
          style={{
            backgroundColor: "#FFFFFF",
            flexDirection: "row",
            paddingVertical: 20,
            paddingHorizontal: 20,
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: "#C4C4C4",
          }}
        >
          <Text style={{ width: "13%", color: Colors.primaryColor }}>
            {result.name}
          </Text>
          <Text style={{ width: "12%" }}>{item.type}</Text>
          <Text
            style={{
              width: "6%",
              color:
                result.itemInventory.quantity < result.itemInventory.ideal_qty
                  ? "#F51B1B"
                  : Colors.blackColor,
            }}
          >
            {result.itemInventory.quantity}
          </Text>
          <View
            style={{
              width: "12%",
              alignItems: "center",
            }}
          >
            <TextInput
              editable={!loading}
              underlineColorAndroid={Colors.whiteColor}
              clearButtonMode="while-editing"
              style={styles.textInput2}
              placeholder={result.itemInventory.ideal_qty.toString()}
              onChangeText={(text) => {
                setState({
                  ideal: text.trim(),
                  itemId: result.itemInventory.itemId,
                });
              }}
              value={email}
            />
          </View>
          <View
            style={{
              width: "12%",
              alignItems: "center",
              marginLeft: 30,
            }}
          >
            <TextInput
              editable={!loading}
              underlineColorAndroid={Colors.whiteColor}
              clearButtonMode="while-editing"
              style={styles.textInput2}
              placeholder={result.itemInventory.minimum_qty.toString()}
              onChangeText={(text) => {
                setState({
                  minimum: text.trim(),
                  itemId: result.itemInventory.itemId,
                });
              }}
              value={email}
            />
          </View>
          <Text style={{ width: "10%", marginLeft: 40 }}>
            {result.itemInventory.unit === null ? 0 : result.itemInventory.unit}
          </Text>
          <Text style={{ width: "11%" }}>15/10/2020</Text>
          <View style={{ width: "15%" }}>
            {result.supplyProducts.map((elements) => {
              return (
                <Text>{elements === null ? " " : elements.supplier.name}</Text>
              );
            })}
          </View>
        </View>
      );
    });

  const renderStockTransferItem = ({ item }) => (
    // item.stockTransferProducts.map((elements, index) => {
    //   return (
    //     <View
    //       style={{
    //         backgroundColor: '#ffffff',
    //         flexDirection: 'row',
    //         paddingVertical: 20,
    //         paddingHorizontal: 20,
    //         borderBottomWidth: StyleSheet.hairlineWidth,
    //         borderBottomColor: '#c4c4c4',
    //       }}>

    //       <Text style={{ width: '20%', color: Colors.primaryColor }}>ST{elements.stId}</Text>
    //       <Text style={{ width: '20%' }}>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
    //       <Text style={{ width: '20%' }}>
    //         {item.fromOutletId == 1 ? "MyBurgerlab (Seapark)" : item.fromOutletId == 2 ? "MyBurgerlab (OUG)" : "MyBurgerlab (Mytown)"}
    //       </Text>
    //       <Text style={{ width: '20%' }}>{item.toOutletId == 1 ? "MyBurgerlab (Seapark)" : item.toOutletId == 2 ? "MyBurgerlab (OUG)" : "MyBurgerlab (Mytown)"}</Text>
    //       <View style={{
    //         width: '20%',
    //         alignItems: "center",
    //         backgroundColor: item.status == 0 ? '#dedede' : item.status == 1 ? Colors.secondaryColor : Colors.primaryColor,
    //         borderRadius: 10,
    //         padding: 10,
    //         marginLeft: 8
    //       }}>
    //         <Text style={{
    //           color: item.status == 0 ? Colors.blackColor : item.status == 1 ? Colors.blackColor : Colors.whiteColor,
    //         }}>{item.status == 0 ? "Created" : item.status == 1 ? "Shipped" : "Completed"}</Text>
    //       </View>
    //     </View>
    //   )
    // })

    <TouchableOpacity
      style={{
        backgroundColor: "#ffffff",
        flexDirection: "row",
        paddingVertical: 20,
        paddingHorizontal: 15,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: "#c4c4c4",
        alignItems: "center",
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
      }}
      onPress={() => {
        CommonStore.update((s) => {
          s.selectedStockTransferEdit = item;
        });

        setStockTransfer(false);
        setAddStockTransfer(true);
      }}
    >
      <Text
        style={{
          fontSize: switchMerchant ? 10 : 14,
          fontFamily: "NunitoSans-Regular",
          width: "20%",
          color: Colors.primaryColor,
          marginRight: 4,
        }}
      >
        ST{item.stId}
      </Text>
      <Text
        style={{
          fontSize: switchMerchant ? 10 : 14,
          fontFamily: "NunitoSans-Regular",
          width: "15%",
          marginHorizontal: 4,
        }}
      >
        {moment(item.createdAt).format("DD MMM YYYY")}
      </Text>
      <Text
        style={{
          fontSize: switchMerchant ? 10 : 14,
          fontFamily: "NunitoSans-Regular",
          width: "21%",
          marginHorizontal: 4,
        }}
      >
        {item.sourceOutletName}
      </Text>
      <Text
        style={{
          fontSize: switchMerchant ? 10 : 14,
          fontFamily: "NunitoSans-Regular",
          width: "21%",
          marginHorizontal: 4,
        }}
      >
        {item.targetOutletName}
      </Text>
      <View
        style={{
          width: "20%",
          marginLeft: 4,
        }}
      >
        <View
          style={{
            width: switchMerchant ? 90 : 130,
            alignItems: "center",
            borderRadius: 10,
            padding: 10,
            backgroundColor:
              item.status == 0
                ? "#dedede"
                : item.status == 1
                ? Colors.secondaryColor
                : Colors.primaryColor,
          }}
        >
          <Text
            style={{
              color:
                item.status == 0
                  ? Colors.blackColor
                  : item.status == 1
                  ? Colors.blackColor
                  : Colors.whiteColor,
              fontSize: switchMerchant ? 10 : 14,
              fontFamily: "NunitoSans-Regular",
            }}
          >
            {/* {item.status == 0 ? "Created" : item.status == 1 ? "Shipped" : "Completed"} */}
            {STOCK_TRANSFER_STATUS_PARSED[item.status]}
            {/* {selectedStockTransferEdit.status} */}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  const renderStockTakeItem = ({ item }) => (
    <View
      style={{
        backgroundColor: "#ffffff",
        flexDirection: "row",
        paddingVertical: 20,
        paddingHorizontal: 20,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: "#c4c4c4",
      }}
    >
      <View style={{ width: "14%", flexDirection: "row" }}>
        <Text>{moment(item.createdAt).format("DD/MM/YYYY")}</Text>
        <Text style={{ color: "#969696", marginLeft: 3 }}>
          {moment(item.createdAt).format("hh:mm")}
        </Text>
      </View>
      <View style={{ width: "14%", flexDirection: "row" }}>
        <Text>{moment(item.updatedAt).format("DD/MM/YYYY")}</Text>
        <Text style={{ color: "#969696", marginLeft: 3 }}>
          {moment(item.updatedAt).format("hh:mm")}
        </Text>
      </View>
      <Text style={{ width: "25%" }}></Text>
      <Text style={{ width: "18%" }}>
        {item.toOutletId == 1
          ? "MyBurgerlab (Seapark)"
          : item.toOutletId == 2
          ? "MyBurgerlab (OUG)"
          : "MyBurgerlab (Mytown)"}
      </Text>
      <Text style={{ width: "15%" }}>
        {item.fromOutletId == 1
          ? "MyBurgerlab (Seapark)"
          : item.fromOutletId == 2
          ? "MyBurgerlab (OUG)"
          : "MyBurgerlab (Mytown)"}
      </Text>
      <Text style={{ width: "1%" }}></Text>
      <View
        style={{
          width: "14%",
          alignItems: "center",
          backgroundColor: item.status ? Colors.primaryColor : "#fc0000",
          borderRadius: 10,
          padding: 10,
        }}
      >
        <Text
          style={{ color: item.status ? Colors.whiteColor : Colors.whiteColor }}
        >
          {item.status == 0 ? "Cancelled" : "Completed"}
        </Text>
      </View>
    </View>
  );

  const renderOrderItem = ({ item }) =>
    item.stockOrderProducts.map((elements, index) => {
      return (
        <TouchableOpacity
          onPress={() => {
            // setState({
            //   lowStockAlert: false,
            //   purchaseOrder: false,
            //   stockTransfer: false,
            //   stockTake: false,
            //   addPurchase: false,
            //   editPurchase: true,
            //   addStockTransfer: false,
            //   addStockTake: false,
            // });
          }}
        >
          <View
            style={{
              backgroundColor: "#ffffff",
              flexDirection: "row",
              paddingVertical: 20,
              paddingHorizontal: 20,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderBottomColor: "#c4c4c4",
            }}
          >
            <Text style={{ width: "11%", color: Colors.primaryColor }}>
              PO{item.id}
            </Text>
            <Text style={{ width: "13%" }}>
              {moment(item.createdAt).format("DD/MM/YYYY")}
            </Text>
            <Text style={{ width: "15%" }}>
              {moment(item.eta).format("DD/MM/YYYY")}
            </Text>
            <Text style={{ width: "15%" }}>{item.outlet.name}</Text>
            <Text style={{ width: "17%" }}>
              {item.supplier === null ? "" : item.supplier.name}
            </Text>
            <Text style={{ width: "15%" }}>RM{elements.amount}</Text>
            <View
              style={{
                width: "12%",
                alignItems: "center",
                backgroundColor:
                  item.status == 0
                    ? "#dedede"
                    : item.status == 1
                    ? "#969696"
                    : item.status == 2
                    ? Colors.secondaryColor
                    : Colors.primaryColor,
                borderRadius: 10,
              }}
            >
              <Text
                style={{
                  color:
                    item.status == 0
                      ? Colors.blackColor
                      : item.status == 1
                      ? Colors.whiteColor
                      : item.status == 2
                      ? Colors.blackColor
                      : Colors.whiteColor,
                  borderRadius: 10,
                }}
              >
                {item.status == 0
                  ? "Fail"
                  : item.status == 1
                  ? "In Progress"
                  : item.status == 2
                  ? "Arrived"
                  : "Completed"}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    });

  //   const renderItemsToOrder = ({ item }) => (

  //     <View
  //       style={{
  //         backgroundColor: '#ffffff',
  //         flexDirection: 'row',
  //         paddingVertical: 20,
  //         paddingHorizontal: 20,
  //         borderBottomWidth: StyleSheet.hairlineWidth,
  //         borderBottomColor: '#c4c4c4',
  //       }}>

  //       <View style={{ width: "8%" }}>
  //         <CheckBox
  //           value={isSelected}
  //           onValueChange={(isSelected) => setState({ isSelected })} />
  //       </View>
  //       <Text style={{ width: '14%', color: '#8f8f8f' }}>Chicken patty</Text>
  //       <Text style={{ width: '16%', color: '#8f8f8f' }}>
  //         meat
  //       </Text>
  //       <Text style={{ width: '14%', color: '#8f8f8f' }}>50</Text>
  //       <Text style={{ width: '16%', color: '#8f8f8f' }}>50</Text>
  //       <Text style={{ width: '18%', color: '#8f8f8f' }}>RM6.00</Text>
  //       <Text style={{ width: '16%', color: '#8f8f8f' }}>RM300.00</Text>
  //     </View>

  //   );

  const renderAddPO = ({ item }) => (
    <View
      style={{
        backgroundColor: "#ffffff",
        flexDirection: "row",
        paddingVertical: 20,
        paddingHorizontal: 20,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: "#c4c4c4",
      }}
    >
      <View style={{ width: 120, color: "#969696" }}>
        <DropDownPicker
          items={[
            {
              label: "Chicken patty",
              value: "Chicken patty",
            },
            {
              label: "Beef patty",
              value: "Beef patty",
            },
            {
              label: "lamb chop",
              value: "lamb chop",
            },
          ]}
          defaultValue={""}
          placeholder=""
          containerStyle={{ height: 30 }}
          style={{ backgroundColor: "#FAFAFA" }}
          itemStyle={{
            justifyContent: "flex-start",
          }}
          dropDownStyle={{ backgroundColor: "#FAFAFA" }}
          onChangeItem={(item) =>
            setState({
              choice4: item.value,
            })
          }
        />
      </View>
      <Text style={{ width: "2%" }}></Text>
      <Text style={{ width: "16%", color: "#8f8f8f" }}>meat</Text>
      <Text style={{ width: "6%", color: "#8f8f8f" }}>13</Text>
      <View
        style={{
          width: "12%",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          borderRadius: 10,
          padding: 10,
        }}
      >
        <Text style={{ color: "#8f8f8f" }}>50</Text>
      </View>
      <Text style={{ width: "14%", marginLeft: 50, color: "#8f8f8f" }}>50</Text>
      <Text style={{ width: "18%", color: "#8f8f8f" }}>RM6.00</Text>
      <Text style={{ width: "10%", color: "#8f8f8f" }}>RM300.00</Text>
      <TouchableOpacity style={{ marginLeft: 10 }} onPress={() => {}}>
        <Icon name="trash-2" size={20} color="#eb3446" />
      </TouchableOpacity>
    </View>
  );

  const renderAddStock = ({ item, index }) => {
    return (
      <View
        style={{
          backgroundColor: "#ffffff",
          flexDirection: "row",
          paddingVertical: 20,
          paddingHorizontal: 10,
          //paddingBottom: 100,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: "#c4c4c4",
          alignItems: "center",
        }}
      >
        <View style={{ flex: 2 }}>
          <View
            style={{
              width: "85%",
              height: 35,
              borderColor: "#E5E5E5",
              borderWidth: 1,
              borderRadius: 5,
              backgroundColor: "white",
              //alignItems: 'center',
              justifyContent: "center",
              paddingLeft: 10,
              shadowOpacity: 0,
              shadowColor: "#000",
              shadowOffset: {
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 2,
            }}
          >
            {outletSupplyItemDropdownList.find(
              (dropdownItem) =>
                dropdownItem.value === poItems[index].outletSupplyItemId
            ) ? ( //    defaultValue={poItems[index].outletSupplyItemId} //    items={outletSupplyItemDropdownList} //  <DropDownPicker
              //    placeholder="Outlet Supply Items"
              //    containerStyle={{ height: 30 }}
              //    style={{ backgroundColor: '#FAFAFA' }}
              //    itemStyle={{
              //    justifyContent: 'flex-start',
              //    }}
              //    dropDownStyle={{ backgroundColor: '#FAFAFA' }}
              //    onChangeItem={(item) =>
              //      setPoItems(poItems.map((poItem, i) => (i === index ? {
              //        ...poItem,
              //        outletSupplyItemId: item.value,
              //        name: allOutletsSupplyItemsDict[item.value].name,
              //        sku: allOutletsSupplyItemsDict[item.value].sku,
              //        skuMerchant: allOutletsSupplyItemsDict[item.value].skuMerchant,
              //        quantity: allOutletsSupplyItemsDict[item.value].quantity,
              //        transferQuantity: 0,
              //        price: allOutletsSupplyItemsDict[item.value].price,
              //        totalPrice: 0,
              //      } : poItem)))
              //    }
              //    searchable={true}
              //  />
              <Picker
                disabled={
                  selectedStockTransferEdit &&
                  (selectedStockTransferEdit.status ===
                    STOCK_TRANSFER_STATUS.COMPLETED ||
                    selectedStockTransferEdit.status ===
                      STOCK_TRANSFER_STATUS.CREATED)
                }
                selectedValue={poItems[index].outletSupplyItemId}
                style={{}}
                onValueChange={(value, text) => {
                  const outletSupplyItemSku =
                    allOutletsSupplyItemsDict[value].sku;

                  const supplyItem = supplyItemsSkuDict[outletSupplyItemSku]
                    ? supplyItemsSkuDict[outletSupplyItemSku]
                    : null;

                  setPoItems(
                    poItems.map((poItem, i) =>
                      i === index
                        ? {
                            ...poItem,
                            outletSupplyItemId: value,
                            name: allOutletsSupplyItemsDict[value].name,
                            sku: outletSupplyItemSku,
                            unit: allOutletsSupplyItemsDict[value].unit,
                            quantity: allOutletsSupplyItemsDict[value].quantity,
                            transferQuantity: 0,
                            price: allOutletsSupplyItemsDict[value].price,
                            totalPrice: 0,

                            supplyItem: supplyItem,
                          }
                        : poItem
                    )
                  );
                }}
              >
                {outletSupplyItemDropdownList.map((value, index) => {
                  return (
                    <Picker.Item
                      key={index}
                      label={value.label}
                      value={value.value}
                    />
                  );
                })}
              </Picker>
            ) : (
              //   <RNPickerSelect
              //     disabled={(selectedStockTransferEdit && (selectedStockTransferEdit.status === STOCK_TRANSFER_STATUS.COMPLETED || selectedStockTransferEdit.status === STOCK_TRANSFER_STATUS.CREATED))}
              //     useNativeAndroidPickerStyle={false}
              //     //pickerProps={{ style: { height: 160, overflow: 'hidden',} }}
              //     style={{
              //       inputIOS: { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, paddingVertical: 5 },
              //       inputAndroid: { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, paddingVertical: 5 },
              //       inputAndroidContainer: {
              //         //backgroundColor: 'red',
              //         width: '100%',
              //       }
              //     }}
              //     contentContainerStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, }}
              //     items={outletSupplyItemDropdownList}
              //     value={poItems[index].outletSupplyItemId}
              //     onValueChange={(value) => {
              //       const outletSupplyItemSku = allOutletsSupplyItemsDict[value].sku;

              //       const supplyItem = supplyItemsSkuDict[outletSupplyItemSku] ? supplyItemsSkuDict[outletSupplyItemSku] : null;

              //       setPoItems(poItems.map((poItem, i) => (i === index ? {
              //         ...poItem,
              //         outletSupplyItemId: value,
              //         name: allOutletsSupplyItemsDict[value].name,
              //         sku: outletSupplyItemSku,
              //         unit: allOutletsSupplyItemsDict[value].unit,
              //         quantity: allOutletsSupplyItemsDict[value].quantity,
              //         transferQuantity: 0,
              //         price: allOutletsSupplyItemsDict[value].price,
              //         totalPrice: 0,

              //         supplyItem: supplyItem,
              //       } : poItem)));
              //     }}
              //   />
              <></>
            )}
          </View>
        </View>

        <Text
          style={{
            flex: 1,
            color: "#949494",
            marginLeft: 10,
            fontFamily: "NunitoSans-Regular",
            fontSize: switchMerchant ? 10 : 14,
          }}
        >
          {poItems[index].skuMerchant || ""}
          {/* {poItems[index].skuMerchant || poItems[index].sku ? poItems[index].sku : poItems[index].supplyItem.skuMerchant || poItems[index].supplyItem.skuMerchant ? poItems[index].supplyItem.skuMerchant : poItems[index].sku } */}
        </Text>

        <Text
          style={{
            flex: 0.8,
            color: "#949494",
            fontFamily: "NunitoSans-Regular",
            fontSize: switchMerchant ? 10 : 14,
          }}
        >
          {poItems[index].unit}
        </Text>

        <Text
          style={{
            flex: 0.8,
            color: "#949494",
            fontFamily: "NunitoSans-Regular",
            fontSize: switchMerchant ? 10 : 14,
          }}
        >
          {poItems[index].quantity}
        </Text>

        {console.log("transferQuantity")}
        {console.log(poItems)}

        <View style={{ flex: 1 }}>
          {!selectedStockTransferEdit ? (
            // || (selectedStockTransferEdit && selectedStockTransferEdit.status !== STOCK_TRANSFER_STATUS.COMPLETED)
            <TextInput
              editable={true}
              underlineColorAndroid={Colors.fieldtBgColor}
              style={{
                backgroundColor: Colors.fieldtBgColor,
                width: switchMerchant ? 55 : 80,
                height: 35,
                borderRadius: 5,
                padding: 5,
                borderWidth: 1,
                borderColor: "#E5E5E5",
                paddingLeft: 10,
                fontFamily: "NunitoSans-Regular",
                fontSize: switchMerchant ? 10 : 14,
              }}
              placeholder={"0"}
              placeholderStyle={{
                fontFamily: "NunitoSans-Regular",
                fontSize: switchMerchant ? 10 : 14,
              }}
              keyboardType={"number-pad"}
              onChangeText={(text) => {
                // setState({ itemName: text });
                setPoItems(
                  poItems.map((poItem, i) =>
                    i === index
                      ? {
                          ...poItem,
                          transferQuantity:
                            text.length > 0
                              ? parseInt(text) >= poItem.quantity
                                ? Alert.alert("Quantity is more than Stock")
                                : parseInt(text)
                              : 0, // will cause bugs, some outlet item usage consume 0.01, 0.05, etc
                          //balance: poItem.quantity - parseInt(text),
                          balance:
                            parseInt(text) < poItem.quantity
                              ? poItem.quantity - parseInt(text)
                              : 0,
                          totalPrice: parseInt(text) * poItem.price,
                        }
                      : poItem
                  )
                );
              }}
              value={
                poItems[index].transferQuantity
                  ? poItems[index].transferQuantity.toFixed(0)
                  : "0"
              }
            />
          ) : (
            <TextInput
              editable={false}
              underlineColorAndroid={Colors.fieldtBgColor}
              style={{
                backgroundColor: Colors.fieldtBgColor,
                width: switchMerchant ? 55 : 80,
                height: 35,
                borderRadius: 5,
                padding: 5,
                borderWidth: 1,
                borderColor: "#E5E5E5",
                paddingLeft: 10,
                fontFamily: "NunitoSans-Regular",
                fontSize: switchMerchant ? 10 : 14,
              }}
              placeholder={"0"}
              placeholderStyle={{
                fontFamily: "NunitoSans-Regular",
                fontSize: switchMerchant ? 10 : 14,
              }}
              keyboardType={"number-pad"}
              onChangeText={(text) => {
                // setState({ itemName: text });
                setPoItems(
                  poItems.map((poItem, i) =>
                    i === index
                      ? {
                          ...poItem,
                          transferQuantity:
                            text.length > 0
                              ? parseInt(text) >= poItem.quantity
                                ? poItem.quantity
                                : parseInt(text)
                              : 0, // will cause bugs, some outlet item usage consume 0.01, 0.05, etc
                          balance: poItem.quantity - parseInt(text),
                          totalPrice: parseInt(text) * poItem.price,
                        }
                      : poItem
                  )
                );
              }}
              value={poItems[index].transferQuantity.toFixed(0)}
            />
          )}
        </View>

        <Text
          style={{
            flex: 1,
            color: "#949494",
            fontFamily: "NunitoSans-Regular",
            fontSize: switchMerchant ? 10 : 14,
          }}
        >
          {allOutletsSupplyItemsDict[poItems[index].outletSupplyItemId]
            ? allOutletsSupplyItemsDict[poItems[index].outletSupplyItemId]
                .quantity
            : 0}
        </Text>
        <Text
          style={{
            flex: 0.9,
            color: "#949494",
            fontFamily: "NunitoSans-Regular",
            fontSize: switchMerchant ? 10 : 14,
          }}
        >
          {poItems[index].price}
        </Text>
        <Text
          style={{
            flex: 0.9,
            color: "#949494",
            fontFamily: "NunitoSans-Regular",
            fontSize: switchMerchant ? 10 : 14,
          }}
        >
          {poItems[index].totalPrice}
        </Text>

        <TouchableOpacity
          style={{ flex: 0.3 }}
          onPress={() => {
            setPoItems([
              ...poItems.slice(0, index),
              ...poItems.slice(index + 1),
            ]);
          }}
        >
          <Icon
            name="trash-2"
            size={switchMerchant ? 15 : 20}
            color="#eb3446"
          />
        </TouchableOpacity>
      </View>
    );
  };

  const renderAddCountedStockTake = ({ item }) => (
    <View
      style={{
        backgroundColor: "#ffffff",
        flexDirection: "row",
        paddingVertical: 20,
        paddingHorizontal: 20,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: "#c4c4c4",
        backgroundColor: "#ffe8e8",
      }}
    >
      <Text style={{ width: "12%", color: "#949494" }}>100 plus</Text>
      <Text style={{ width: "12%", color: "#949494" }}>soda</Text>
      <Text style={{ width: "12%", color: "#949494" }}>RM2</Text>
      <Text style={{ width: "12%", color: "#949494" }}>50</Text>
      <View
        style={{
          width: "11%",
          backgroundColor: Colors.whiteColor,
          borderRadius: 10,
          padding: 10,
        }}
      >
        <Text style={{ color: "#e02424" }}>30</Text>
      </View>
      <Text style={{ width: "12%", marginLeft: 20, color: "#949494" }}>
        -20
      </Text>
      <Text style={{ width: "12%", color: "#949494" }}>-RM40</Text>
      <TouchableOpacity style={{ width: "12%", flexDirection: "row" }}>
        <Icon2 name="redo" size={25} color={Colors.primaryColor} />
        <Text
          style={{ marginLeft: 8, color: Colors.primaryColor, fontSize: 16 }}
        >
          undo
        </Text>
      </TouchableOpacity>
    </View>
  );

  //   const renderAddUncountedStockTake = ({ item }) => (

  //     <View
  //       style={{
  //         backgroundColor: '#ffffff',
  //         flexDirection: 'row',
  //         paddingVertical: 20,
  //         paddingHorizontal: 20,
  //         borderBottomWidth: StyleSheet.hairlineWidth,
  //         borderBottomColor: '#c4c4c4',
  //       }}>

  //       <Text style={{ width: '16%', color: "#949494" }}>Mustard</Text>
  //       <Text style={{ width: '16%', color: "#949494" }}>sauce</Text>
  //       <Text style={{ width: '16%', color: "#949494" }}>12345</Text>
  //       <Text style={{ width: '16%', color: "#949494" }}>0</Text>
  //       <View style={{ width: '10%', color: "#949494", padding: 8, backgroundColor: "#f5f5f5", borderRadius: 10 }}>
  //         <Text >0</Text>
  //       </View>
  //       <View style={{ width: '16%', marginLeft: 80 }}>
  //         <TouchableOpacity style={{ flexDirection: "row" }} onPress={() => { setState({ modal: true }); }}>
  //           <CheckBox
  //             value={isSelected2}
  //             onValueChange={(isSelected2) => setState({ isSelected2 })} />
  //           <Text style={{ marginLeft: 8, color: Colors.primaryColor, fontSize: 16 }}>Confirmed</Text>
  //         </TouchableOpacity>
  //       </View>

  //     </View>
  //   );

  const email = () => {
    var body = {
      stockTransferId: 1,
      email: Email,
    };
    // ApiClient.POST(API.emailStockTransfer, body, false).then((result) => {

    //   if (result == true) {

    //     Alert.alert(
    //       'Success',
    //       'The email had sent',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  const CheckStockOrder = () => {
    var body = {
      stockOrderId: 1,
      counted: [
        {
          id: 1,
          soId: 2,
          itemId: 4,
          name: "food",
          quantity: -118,
          amount: 2,
        },
      ],
    };
    // ApiClient.POST(API.checkStockOrder, body, false).then((result) => {

    //   if (result !== null) {

    //     Alert.alert(
    //       'Success',
    //       'The quantity keeped',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  const CheckStockOrderDelete = () => {
    var body = {
      stockOrderId: 1,
      counted: [
        {
          id: 1,
          soId: 2,
          itemId: 4,
          name: "food",
          quantity: -118,
          amount: 2,
        },
      ],
    };
    // ApiClient.POST(API.checkStockOrderDelete, body, false).then((result) => {

    //   if (result !== null) {

    //     Alert.alert(
    //       'Success',
    //       'the quantity reduce to 0',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  //   const createStockOrder = () => {
  //     var body = {
  //       supplierId: choice7,
  //       outletId: choice8,
  //       eta: moment(date).format("YYYY-MM-DD" + " " + "hh:mm:ss"),
  //       items: [
  //         {
  //           id: 5,
  //           sku: "papaya",
  //           quantity: 50,
  //         },
  //         {
  //           id: 6,
  //           sku: "mango",
  //           quantity: 20,
  //         },
  //       ],
  //       remarks: "Order Stock",
  //     };
  //     // ApiClient.POST(API.createStockOrder, body).then((result) => {
  //     //   if (result !== null) {

  //     //     Alert.alert(
  //     //       'Success',
  //     //       'The order has created',
  //     //       [
  //     //         { text: "OK", onPress: () => { } }
  //     //       ],
  //     //       { cancelable: false },
  //     //     );
  //     //   }
  //     // });
  //   };

  const createStockTransfer = (
    stockTransferStatus = STOCK_TRANSFER_STATUS.CREATED
  ) => {
    // if (poItems.find(poItem => poItem.outletSupplyItemId)){
    //   for(var i = 0; i < poItems.length; i++){
    //     for(var j = 0; j < poItems.length; j++){
    //       if(poItems[i].outletSupplyItemId === poItems[j].outletSupplyItemId) {
    //         Alert.alert(
    //           'Error',
    //           'Same Supply Item.',
    //           [
    //             {
    //               text: "OK", onPress: () => {
    //               }
    //             }
    //           ],
    //         );
    //         return;
    //       }
    //     }
    //   }
    // }

    if (selectedStockTransferEdit === null) {
      var body = {
        stId: poId,
        stItems: poItems,
        tax: +parseFloat(taxTotal).toFixed(2),
        discount: +parseFloat(discountTotal).toFixed(2),
        totalPrice: +parseFloat(subtotal).toFixed(2),
        finalTotal: +parseFloat(finalTotal).toFixed(2),
        estimatedArrivalDate: moment(date).valueOf(),

        // status: STOCK_TRANSFER_STATUS.CREATED,
        status: stockTransferStatus,

        sourceOutletId: selectedSourceOutletId,
        targetOutletId: selectedTargetOutletId,
        sourceOutletName: allOutlets.find(
          (outlet) => outlet.uniqueId === selectedSourceOutletId
        ).name,
        targetOutletName: allOutlets.find(
          (outlet) => outlet.uniqueId === selectedTargetOutletId
        ).name,

        merchantId: merchantId,
        remarks: "",

        staffName: userName,
        staffId: userId,
      };

      console.log(body);

      ApiClient.POST(API.createStockTransfer, body).then((result) => {
        console.log("Result", result);
        if (result && result.status === "success") {
          Alert.alert(
            "Success",
            "Stock transfer has been created",
            [
              {
                text: "OK",
                onPress: () => {
                  // setState({ addStockTransfer: false, stockTransfer: true })
                  setAddStockTransfer(false);
                  setStockTransfer(true);
                },
              },
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert(
            "Error",
            "Failed to create Stock transfer",
            [{ text: "OK", onPress: () => {} }],
            { cancelable: false }
          );
        }
      });
    } else {
      var body = {
        stId: poId,
        stItems: poItems,
        tax: +parseFloat(taxTotal).toFixed(2),
        discount: +parseFloat(discountTotal).toFixed(2),
        totalPrice: +parseFloat(subtotal).toFixed(2),
        finalTotal: +parseFloat(finalTotal).toFixed(2),
        estimatedArrivalDate: moment(date).valueOf(),

        // status: STOCK_TRANSFER_STATUS.CREATED,
        status: stockTransferStatus,

        sourceOutletId: selectedSourceOutletId,
        targetOutletId: selectedTargetOutletId,
        sourceOutletName: allOutlets.find(
          (outlet) => outlet.uniqueId === selectedSourceOutletId
        ).name,
        targetOutletName: allOutlets.find(
          (outlet) => outlet.uniqueId === selectedTargetOutletId
        ).name,

        merchantId: merchantId,
        remarks: "",

        staffName: userName,
        staffId: userId,

        uniqueId: selectedStockTransferEdit.uniqueId,
      };

      console.log(body);

      ApiClient.POST(API.updateStockTransfer, body).then((result) => {
        console.log("Result", result);
        if (result && result.status === "success") {
          Alert.alert(
            "Success",
            "Stock transfer has been updated",
            [
              {
                text: "OK",
                onPress: () => {
                  // setState({ addStockTransfer: false, stockTransfer: true })
                  setAddStockTransfer(false);
                  setStockTransfer(true);
                },
              },
            ],
            { cancelable: false }
          );
        } else {
          Alert.alert(
            "Error",
            "Failed to update Stock transfer",
            [{ text: "OK", onPress: () => {} }],
            { cancelable: false }
          );
        }
      });
    }
  };

  const editStockOrder = () => {
    var body = {
      productId: 2,
      supplierId: 3,
      remarks: "meat",
      eta: "10-1-2020 01:46:22",
      itemId: 11,
      name: "chicken",
      quantity: 100,
    };
    ApiClient.POST(API.editStockOrder, body).then((result) => {
      if (result.success !== null) {
        Alert.alert(
          "Success",
          "Stock Order has been edited",
          [{ text: "OK", onPress: () => {} }],
          { cancelable: false }
        );
      }
    });
  };

  const editStockTransfer = () => {
    var body = {
      stProduct: 1,
      toOutlet: 2,
      email: Email,
      itemId: 61,
      quantity: 120,
      remarks: "Stock Transfer",
    };
    // ApiClient.POST(API.editStockTransfer, body).then((result) => {

    //   if (result.success !== null) {

    //     Alert.alert(
    //       'Success',
    //       'The Stock Transfer has edited',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  return (
    <View style={[styles.container]}>
      <View style={[styles.sidebar]}>
        <SideBar
          navigation={props.navigation}
          selectedTab={3}
          expandInventory={true}
        />
      </View>
      <ScrollView
        scrollEnabled={switchMerchant}
        horizontal={true}
        // contentContainerStyle={{backgroundColor: 'red'}}
        // style={{ backgroundColor: 'red'}}
      >
        <View style={styles.content}>
          <Modal
            supportedOrientations={["portrait", "landscape"]}
            style={
              {
                // flex: 1
              }
            }
            visible={exportModal}
            transparent={true}
            animationType={"fade"}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.3,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.03,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TouchableOpacity
                  disabled={isLoading}
                  style={{
                    position: "absolute",
                    right: windowWidth * 0.02,
                    top: windowWidth * 0.02,

                    elevation: 1000,
                    zIndex: 1000,
                  }}
                  onPress={() => {
                    setExportModal(false);
                  }}
                >
                  <AntDesign
                    name="closecircle"
                    size={switchMerchant ? 15 : 25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View
                  style={{
                    alignItems: "center",
                    top: "20%",
                    position: "absolute",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      textAlign: "center",
                      fontSize: switchMerchant ? 18 : 20,
                    }}
                  >
                    Download Report
                  </Text>
                </View>
                <View style={{ top: switchMerchant ? "14%" : "10%" }}>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: "NunitoSans-Bold",
                    }}
                  >
                    Email Address:
                  </Text>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={{
                      backgroundColor: Colors.fieldtBgColor,
                      width: switchMerchant ? 240 : 370,
                      height: switchMerchant ? 35 : 50,
                      borderRadius: 5,
                      padding: 5,
                      marginVertical: 5,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      paddingLeft: 10,
                      fontSize: switchMerchant ? 10 : 16,
                    }}
                    placeholderStyle={{ padding: 5 }}
                    placeholder="Enter Your Email"
                    onChangeText={(text) => {
                      setExportEmail(text);
                    }}
                    value={exportEmail}
                  />
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: "NunitoSans-Bold",
                      marginTop: 15,
                    }}
                  >
                    Send As:
                  </Text>

                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      //top: '10%',
                      flexDirection: "row",
                      marginTop: 10,
                    }}
                  >
                    {/* <TouchableOpacity
                      disabled={isLoading}
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: switchMerchant ? 100 : 120,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginRight: 15,
                      }}
                      onPress={() => {
                        if (exportEmail.length > 0) {
                          CommonStore.update((s) => {
                            s.isLoading = true;
                          });
                          setIsLoadingExcel(true);
                          const excelData = convertDataToExcelFormat();

                          generateEmailReport(
                            EMAIL_REPORT_TYPE.EXCEL,
                            excelData,
                            "KooDoo Stock Transfer Report",
                            "KooDoo Stock Transfer Report.xlsx",
                            `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                            exportEmail,
                            "KooDoo Stock Transfer Report",
                            "KooDoo Stock Transfer Report",
                            () => {
                              CommonStore.update((s) => {
                                s.isLoading = false;
                              });
                              setIsLoadingExcel(false);

                              Alert.alert(
                                "Success",
                                "Report will be sent to the email address soon"
                              );

                              setExportModal(false);
                            }
                          );
                        } else {
                          Alert.alert("Info", "Invalid email address");
                        }
                      }}
                    >
                      {isLoadingExcel ? (
                        <ActivityIndicator
                          size={"small"}
                          color={Colors.whiteColor}
                        />
                      ) : (
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          EXCEL
                        </Text>
                      )}
                    </TouchableOpacity> */}

                    <CSVLink
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "inline-block",
                        flexDirection: "row",
                        textDecoration: "none",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: switchMerchant ? 100 : 100,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      data={convertDataToExcelFormat()}
                      filename="stocktransfer.csv"
                    >
                      <View
                        style={{
                          width: "100%",
                          height: "100%",
                          alignContent: "center",
                          alignItems: "center",
                          alignSelf: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          CSV
                        </Text>
                      </View>
                    </CSVLink>
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            style={
              {
                // flex: 1
              }
            }
            supportedOrientations={["portrait", "landscape"]}
            visible={importModal}
            transparent={true}
            animationType={"fade"}
          >
            <View style={styles.modalContainer}>
              <View style={[styles.modalViewImport, {}]}>
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => {
                    // setState({ changeTable: false });
                    setImportModal(false);
                  }}
                >
                  <AntDesign
                    name="closecircle"
                    size={25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View style={{ padding: 10, margin: 30 }}>
                  <View
                    style={[
                      styles.modalTitle1,
                      { justifyContent: "center", alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.modalTitleText1,
                        { fontSize: 16, fontWeight: "500" },
                      ]}
                    >
                      Imported List
                    </Text>
                  </View>
                  {/* <View style={{
                    heigth: 70,
                    marginVertical: 10,
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    height: '80%'
                  }}>
                  <Table borderStyle={{ borderWidth: 1 }}>
                    <Row data={TableData.tableHead} flexArr={[1, 2, 1, 1]} style={{}}/>
                    <TableWrapper style={{}}>
                    <Col data={TableData.tableTitle} style={{flex: 1}} heightArr={[28, 28, 28, 28]} textStyle={{}}/>
                    <Rows data={TableData.tableData} flexArr={[1, 2, 1, 1]} style={{height: 28}} textStyle={{textAlign: 'center'}}/>
                    </TableWrapper>
                  </Table>
                  </View> */}
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: Colors.primaryColor,
                        width: 150,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: "center",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          importSelectFile();
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            alignSelf: "center",
                            marginVertical: 10,
                          }}
                        >
                          IMPORT
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          backgroundColor: Colors.whiteColor,
                          width: 150,
                          height: 40,
                          marginVertical: 15,
                          borderRadius: 5,
                          alignSelf: "center",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            // setImportTemplate(false);
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.primaryColor,
                              alignSelf: "center",
                              marginVertical: 10,
                            }}
                          >
                            CANCEL
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          backgroundColor: Colors.primaryColor,
                          width: 150,
                          height: 40,
                          marginVertical: 15,
                          borderRadius: 5,
                          alignSelf: "center",
                        }}
                      >
                        <TouchableOpacity onPress={() => {}}>
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              alignSelf: "center",
                              marginVertical: 10,
                            }}
                          >
                            SAVE
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          {/* <View style={{ flexDirection: 'row', marginBottom: Platform.OS == 'ios' ? 0 : 10, width: '100%', justifyContent: 'space-between' }} >
            <View style={{}}>
              <View style={{ flexDirection: 'row', flex: 1, }}> */}
          {/* <TouchableOpacity style={styles.submitText} onPress={() => { importCSV() }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Icon name="download" size={20} color={Colors.primaryColor} />
                    <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                      Import
                  </Text>
                  </View>
                </TouchableOpacity> */}
          {/* <TouchableOpacity style={[styles.submitText, { height: 40 }]} onPress={() => { setExportModal(true) }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Icon name="download" size={20} color={Colors.primaryColor} />
                    <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                      Download
                    </Text>
                  </View>
                </TouchableOpacity> */}
          {/* <TouchableOpacity style={styles.submitText} onPress={() => { setExportModal(true) }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Icon name="upload" size={20} color={Colors.primaryColor} />
                    <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                      Export
                  </Text>
                  </View>
                </TouchableOpacity> */}
          {/* </View>
            </View> */}
          {/* <TextInput
              editable={!loading}
              underlineColorAndroid={Colors.whiteColor}
              clearButtonMode="while-editing"
              style={styles.textInput}
              placeholder="🔍  Search"
              onChangeText={(text) => {
                setState({ search: text.trim() });
              }}
              value={email}
            /> */}

          {/* <Ionicons
              name="search-outline"
              size={20}
              style={styles.searchIcon}
            />
            <TextInput
              editable={loading}
              clearButtonMode="while-editing"
              style={[styles.textInput, { fontFamily: "NunitoSans-Bold", }]}
              placeholder="Search"
              onChangeText={(text) => {
                setState({
                  search: text.trim(),
                });
              }}
              value={email}
            /> */}
          {/* 
            <View
              style={[{
                width: '28%',
                height: 40,

              }, !isTablet ? {
                marginLeft: 0,
              } : {}]}>
              <View style={{
                width: 250,
                height: 40,
                backgroundColor: 'white',
                borderRadius: 10,
                // marginLeft: '53%',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',
                alignSelf: 'flex-end',

                // marginRight: windowWidth * Styles.sideBarWidth,

                position: 'absolute',
                //right: '35%',

                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 3,
              }}>
                <Icon name="search" size={18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />
                <TextInput
                  editable={!loading}
                  underlineColorAndroid={Colors.whiteColor}
                  style={{
                    width: 250,
                    fontSize: 15,
                    fontFamily: 'NunitoSans-Regular',
                  }}
                  clearButtonMode="while-editing"
                  placeholder=" Search"
                  onChangeText={(text) => {
                    setSearch(text.trim());
                  }}
                  value={search}
                />
              </View>
            </View> */}

          {/* </View> */}

          {/*  <View
            style={{
              flexDirection: 'row',
              backgroundColor: Colors.highlightColor,
              padding: 12,
            }}>
            <TouchableOpacity
              onPress={() => {
                setState({
                  lowStockAlert: true,
                  purchaseOrder: false,
                  stockTransfer: false,
                  stockTake: false,
                  addPurchase: false,
                  editPurchase: false,
                  addStockTransfer: false,
                  addStockTake: false,
                });
              }}>
              <Text>Low Stock Alerts</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  lowStockAlert: false,
                  purchaseOrder: true,
                  stockTransfer: false,
                  stockTake: false,
                  addPurchase: false,
                  editPurchase: false,
                  addStockTransfer: false,
                  addStockTake: false,
                });
              }}>
              <Text style={{ marginLeft: 30 }}>Purchase Order</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  lowStockAlert: false,
                  purchaseOrder: false,
                  stockTransfer: true,
                  stockTake: false,
                  addPurchase: false,
                  editPurchase: false,
                  addStockTransfer: false,
                  addStockTake: false,
                });
              }}>
              <Text style={{ marginLeft: 30 }}>Stock Transfer</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  lowStockAlert: false,
                  purchaseOrder: false,
                  stockTransfer: false,
                  stockTake: true,
                  addPurchase: false,
                  editPurchase: false,
                  addStockTransfer: false,
                  addStockTake: false,
                });
              }}>
              <Text style={{ marginLeft: 30 }}>Stock Take</Text>
            </TouchableOpacity>
          </View> */}

          {stockTransfer ? (
            // <ScrollView
            //   scrollEnabled={switchMerchant}
            //   horizontal={true}
            //   contentContainerStyle={{ paddingRight: switchMerchant ? '7%' : 0, }}
            // >
            <ScrollView
              nestedScrollEnabled={true}
              contentContainerStyle={{
                paddingBottom: switchMerchant ? "20%" : "5%",
              }}
              //style={{ backgroundColor: 'red' }}
              scrollEnabled={switchMerchant}
            >
              <View
                style={{
                  height: windowHeight * 0.73,
                  alignItems: "center",
                  marginLeft: windowWidth * 0.02,
                  // width: windowWidth * 0.87,
                }}
              >
                <View style={{ width: windowWidth * 0.87 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{ alignItems: "center", flexDirection: "row" }}
                    >
                      <Text
                        style={{
                          //fontSize: 30,
                          fontSize: switchMerchant ? 20 : 26,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        {stockTransfers.length} Stock Transfer
                      </Text>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View style={{ flexDirection: "row" }}>
                        {/* {isTablet && ( */}
                        <View style={{ alignItem: "center" }}>
                          <TouchableOpacity
                            style={{
                              justifyContent: "center",
                              flexDirection: "row",
                              borderWidth: 1,
                              borderColor: Colors.primaryColor,
                              backgroundColor: "#4E9F7D",
                              borderRadius: 5,
                              //width: 160,
                              paddingHorizontal: 10,
                              height: switchMerchant ? 35 : 40,
                              alignItems: "center",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 1,
                              zIndex: -1,
                            }}
                            onPress={() => {
                              setExportModal(true);
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Icon
                                name="download"
                                size={switchMerchant ? 10 : 20}
                                color={Colors.whiteColor}
                              />
                              <Text
                                style={{
                                  color: Colors.whiteColor,
                                  marginLeft: 5,
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                }}
                              >
                                DOWNLOAD
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {/* )} */}
                      </View>

                      <View style={{ flexDirection: "row" }}>
                        {/* {isTablet && ( */}
                        <View
                          style={{
                            alignSelf: "flex-start",
                          }}
                        >
                          <TouchableOpacity
                            style={{
                              justifyContent: "center",
                              flexDirection: "row",
                              borderWidth: 1,
                              borderColor: Colors.primaryColor,
                              backgroundColor: "#4E9F7D",
                              borderRadius: 5,
                              //width: 160,
                              paddingHorizontal: switchMerchant ? 5 : 10,
                              height: switchMerchant ? 35 : 40,
                              alignItems: "center",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 1,
                              zIndex: -1,
                              marginHorizontal: switchMerchant ? 10 : 15,
                            }}
                            onPress={() => {
                              CommonStore.update((s) => {
                                s.selectedStockTransferEdit = null;
                              });

                              setStockTransfer(false);
                              setAddStockTransfer(true);
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <AntDesign
                                name="pluscircle"
                                size={switchMerchant ? 10 : 20}
                                color={Colors.whiteColor}
                              />
                              <Text
                                style={{
                                  color: Colors.whiteColor,
                                  marginLeft: 5,
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                }}
                              >
                                STOCK TRANSFER
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {/* )} */}
                      </View>

                      <View style={{ flexDirection: "row" }}>
                        <View
                          style={[
                            {
                              height: 40,
                            },
                          ]}
                        >
                          <View
                            style={{
                              width: windowWidth <= 1024 ? 160 : 250,
                              height: switchMerchant ? 35 : 40,
                              backgroundColor: "white",
                              borderRadius: 5,
                              // marginLeft: '53%',
                              flexDirection: "row",
                              alignContent: "center",
                              alignItems: "center",
                              alignSelf: "flex-end",
                              shadowColor: "#000",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 3,
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                            }}
                          >
                            <Icon
                              name="search"
                              size={switchMerchant ? 13 : 18}
                              color={Colors.primaryColor}
                              style={{ marginLeft: 15 }}
                            />
                            <TextInput
                              editable={!loading}
                              underlineColorAndroid={Colors.whiteColor}
                              style={{
                                width: windowWidth <= 1024 ? 140 : 220,
                                fontSize: switchMerchant ? 10 : 15,
                                fontFamily: "NunitoSans-Regular",
                                paddingLeft: 5,
                                height: 45,
                              }}
                              clearButtonMode="while-editing"
                              placeholder="Search"
                              onChangeText={(text) => {
                                // setSearch(text.trim());
                                // setList1(false);
                                // setSearchList(true);
                                setSearch(text.trim());
                              }}
                              value={search}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  {/* <View
                  style={{
                    backgroundColor: '#ffffff',
                    flexDirection: 'row',
                    paddingVertical: 20,
                    paddingHorizontal: 20,
                    marginTop: 10,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    elevation: 1,
                    zIndex: 10
                  }}>
                  <Text style={{ width: '10%', alignSelf: 'center', color: '#969696' }}>
                    ID
                    </Text>
                  <Text style={{ width: '14%', alignSelf: 'center', color: '#969696' }}>
                    Created Date
                    </Text>
                  <View style={{ width: '14%', alignSelf: 'center', color: '#969696' }}>
                    <Text style={{ color: '#969696' }}>
                      From
                      </Text>
                    <View style={{ width: 140, }}>
                      <DropDownPicker
                        items={[
                          {
                            label: 'All Stores',
                            value: 'All Stores',
                          },
                          {
                            label: 'MyBurgerlab (SeaPark)',
                            value: 'MyBurgerlab (SeaPark)',
                          },
                          {
                            label: 'MyBurgerlab (OUG)',
                            value: 'MyBurgerlab (OUG)',
                          },
                          {
                            label: 'MyBurgerlab (Mytown)',
                            value: 'MyBurgerlab (Mytown)',
                          },

                        ]}
                        defaultValue={''}
                        placeholder=""
                        containerStyle={{ height: 30 }}
                        style={{ backgroundColor: '#FAFAFA' }}
                        itemStyle={{
                          justifyContent: 'flex-start',
                        }}
                        dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                        onChangeItem={(item) =>
                          setState({
                            choice6: item.value,
                          })
                        }
                      />
                    </View>
                  </View>
                  <View style={{ width: '14%', alignSelf: 'center' }}>
                    <Text style={{ marginLeft: 55, width: 120, color: '#969696' }}>To</Text>
                    <View style={{ marginLeft: 55, width: 120, }}>
                      <DropDownPicker
                        items={[
                          {
                            label: 'All Stores',
                            value: 'All Stores',
                          },
                          {
                            label: 'MyBurgerlab (SeaPark)',
                            value: 'MyBurgerlab (SeaPark)',
                          },
                          {
                            label: 'MyBurgerlab (OUG)',
                            value: 'MyBurgerlab (OUG)',
                          },
                          {
                            label: 'MyBurgerlab (Mytown)',
                            value: 'MyBurgerlab (Mytown)',
                          },

                        ]}
                        defaultValue={''}
                        placeholder=""
                        containerStyle={{ height: 30 }}
                        style={{ backgroundColor: '#FAFAFA' }}
                        itemStyle={{
                          justifyContent: 'flex-start',
                        }}
                        dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                        onChangeItem={(item) =>
                          setState({
                            choice5: item.value,
                          })
                        }
                      />
                    </View>
                  </View>
                  <View style={{ width: '14%', alignSelf: 'center' }}>
                    <Text style={{ marginLeft: 90, width: 120, color: '#969696' }}>Status </Text>
                    <View style={{ marginLeft: 90, width: 120, }}>
                      <DropDownPicker
                        items={[
                          {
                            label: 'All Stores',
                            value: 'All Stores',
                          },
                          {
                            label: 'MyBurgerlab (SeaPark)',
                            value: 'MyBurgerlab (SeaPark)',
                          },
                          {
                            label: 'MyBurgerlab (OUG)',
                            value: 'MyBurgerlab (OUG)',
                          },
                          {
                            label: 'MyBurgerlab (Mytown)',
                            value: 'MyBurgerlab (Mytown)',
                          },

                        ]}
                        defaultValue={''}
                        placeholder=""
                        containerStyle={{ height: 30 }}
                        style={{ backgroundColor: '#FAFAFA' }}
                        itemStyle={{
                          justifyContent: 'flex-start',
                        }}
                        dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                        onChangeItem={(item) =>
                          setState({
                            choice9: item.value,
                          })
                        }
                      />
                    </View>
                  </View>
                  <View style={{ width: '10%', alignSelf: 'center', marginLeft: 130 }}>
                    <Text style={{ color: '#969696' }}>Actions</Text>


                    <TextInput

                      editable={!loading}
                      underlineColorAndroid={Colors.whiteColor}
                      clearButtonMode="while-editing"
                      style={styles.textInput1}
                      placeholder="🔍 Search"
                      onChangeText={(text) => {
                        setState({ search2: text.trim() });
                      }}
                      value={email}
                    />
                  </View>
                </View> */}
                  <View
                    style={{
                      backgroundColor: Colors.whiteColor,
                      width: windowWidth * 0.87,
                      height: windowHeight * 0.75,
                      marginTop: 30,
                      marginHorizontal: 30,
                      marginBottom: 30,
                      alignSelf: "center",
                      borderRadius: 5,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                    }}
                  >
                    <View
                      style={{
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        backgroundColor: "#ffffff",
                        flexDirection: "row",
                        paddingVertical: 20,
                        paddingHorizontal: 15,
                        //marginTop: 10,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "20%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginRight: 4,
                        }}
                      >
                        ID
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "15%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 4,
                        }}
                      >
                        Created Date
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "21%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 4,
                        }}
                      >
                        From
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "21%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 4,
                        }}
                      >
                        To
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "20%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginLeft: 4,
                        }}
                      >
                        Status
                      </Text>
                    </View>

                    <FlatList
                      nestedScrollEnabled={true}
                      showsVerticalScrollIndicator={false}
                      data={stockTransfers.filter((item) => {
                        if (search !== "") {
                          const searchLowerCase = search.toLowerCase();

                          return (
                            item.sourceOutletName
                              .toLowerCase()
                              .includes(searchLowerCase) ||
                            item.targetOutletName
                              .toLowerCase()
                              .includes(searchLowerCase)
                          );
                        } else {
                          return true;
                        }
                      })}
                      extraData={stockTransfers.filter((item) => {
                        if (search !== "") {
                          const searchLowerCase = search.toLowerCase();

                          return (
                            item.sourceOutletName
                              .toLowerCase()
                              .includes(searchLowerCase) ||
                            item.targetOutletName
                              .toLowerCase()
                              .includes(searchLowerCase)
                          );
                        } else {
                          return true;
                        }
                      })}
                      renderItem={renderStockTransferItem}
                      keyExtractor={(item, index) => String(index)}
                    />
                  </View>
                </View>
              </View>
            </ScrollView>
          ) : // </ScrollView>

          null}

          {addStockTransfer ? (
            <View style={{ height: Dimensions.get("window").height }}>
              <View
                style={{
                  paddingBottom: windowHeight * 0.3,
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.32,
                  shadowRadius: 3.22,
                  elevation: 1,
                }}
                contentContainerStyle={
                  {
                    // top: Platform.OS === 'ios' ? -keyboardHeight * 0.3 : 0,
                  }
                }
              >
                {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginHorizontal: 0, }}> */}

                <View
                  style={{
                    width: switchMerchant ? null : windowWidth * 0.886,
                    alignSelf: switchMerchant ? null : "center",
                    marginBottom: switchMerchant ? 5 : 0,
                    marginTop: -5,
                    alignItems: "baseline",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      marginBottom: switchMerchant ? 0 : 10,
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: switchMerchant ? 0 : 10,
                    }}
                    onPress={() => {
                      CommonStore.update((s) => {
                        s.selectedStockTransferEdit = null;
                      });
                      setStockTransfer(true);
                      setAddStockTransfer(false);
                    }}
                  >
                    <Icon
                      name="chevron-left"
                      size={switchMerchant ? 20 : 30}
                      color={Colors.primaryColor}
                    />
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: switchMerchant ? 14 : 17,
                        color: Colors.primaryColor,
                      }}
                    >
                      Back
                    </Text>
                  </TouchableOpacity>
                </View>

                {/* <ScrollView horizontal={true}
                  scrollEnabled={switchMerchant}
                  nestedScrollEnabled={true}
                  contentContainerStyle={{
                    paddingTop: switchMerchant? 30 : 0,
                    paddingRight: switchMerchant? '5%' : 0,
                  }}
                > */}
                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    width: windowWidth * 0.87,
                    height: windowHeight * 0.8,
                    //marginTop: 10,
                    marginHorizontal: 30,
                    marginLeft: switchMerchant ? 31 : 29,
                    marginBottom: 30,
                    alignSelf: "center",
                    //borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                    borderRadius: 5,
                  }}
                >
                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={
                      {
                        // height: Dimensions.get('window').height * 0.75,
                        // backgroundColor: Colors.whiteColor,
                        // borderWidth: 1,
                        // borderColor: '#E5E5E5',
                        // borderRadius: 10,
                        //  shadowOffset: {
                        //   width: 0,
                        //   height: 2,
                        //   },
                        //   shadowOpacity: 0.42,
                        //   shadowRadius: 3.22,
                        //   elevation: 2,
                      }
                    }
                  >
                    <View style={{}}>
                      <View
                        style={{
                          position: "absolute",
                          alignSelf: "flex-end",
                          marginTop: 30,
                          zIndex: 10000,
                        }}
                      >
                        {selectedStockTransferEdit &&
                        selectedStockTransferEdit.status ===
                          STOCK_TRANSFER_STATUS.CREATED &&
                        currOutletId ===
                          selectedStockTransferEdit.targetOutletId ? (
                          <>
                            <View style={{}}>
                              <TouchableOpacity
                                style={{
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  borderWidth: 1,
                                  borderColor: Colors.primaryColor,
                                  backgroundColor: "#4E9F7D",
                                  borderRadius: 5,
                                  width: switchMerchant ? 160 : 230,
                                  paddingHorizontal: 10,
                                  height: switchMerchant ? 35 : 40,
                                  alignItems: "center",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.22,
                                  shadowRadius: 3.22,
                                  elevation: 1,
                                  zIndex: -1,
                                  marginRight: 20,
                                  marginBottom: 10,
                                }}
                                onPress={() => {
                                  createStockTransfer(
                                    STOCK_TRANSFER_STATUS.COMPLETED
                                  );
                                }}
                                disabled={
                                  selectedStockTransferEdit ? false : true
                                }
                              >
                                <Text
                                  style={{
                                    color: Colors.whiteColor,
                                    //marginLeft: 5,
                                    fontSize: switchMerchant ? 10 : 16,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"MARK COMPLETED"}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </>
                        ) : (
                          <></>
                        )}

                        {selectedStockTransferEdit &&
                        selectedStockTransferEdit.status ===
                          STOCK_TRANSFER_STATUS.COMPLETED ? (
                          <></>
                        ) : (
                          <View style={{}}>
                            <TouchableOpacity
                              style={{
                                justifyContent: "center",
                                flexDirection: "row",
                                borderWidth: 1,
                                borderColor: Colors.primaryColor,
                                backgroundColor: "#4E9F7D",
                                borderRadius: 5,
                                width:
                                  selectedStockTransferEdit &&
                                  selectedStockTransferEdit.status ===
                                    STOCK_TRANSFER_STATUS.CREATED &&
                                  currOutletId ===
                                    selectedStockTransferEdit.targetOutletId &&
                                  !switchMerchant
                                    ? 230
                                    : selectedStockTransferEdit &&
                                      selectedStockTransferEdit.status ===
                                        STOCK_TRANSFER_STATUS.CREATED &&
                                      currOutletId ===
                                        selectedStockTransferEdit.targetOutletId &&
                                      switchMerchant
                                    ? 160
                                    : 120,
                                paddingHorizontal: 10,
                                height: switchMerchant ? 35 : 40,
                                alignItems: "center",
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                                zIndex: -1,
                                marginRight: 20,
                              }}
                              onPress={() => {
                                createStockTransfer();
                              }}
                            >
                              <Text
                                style={{
                                  color: Colors.whiteColor,
                                  //marginLeft: 5,
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                }}
                              >
                                SAVE
                              </Text>
                            </TouchableOpacity>
                          </View>
                        )}
                      </View>

                      <View
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Text
                            style={{
                              alignSelf: "center",
                              marginTop: 30,
                              fontSize: switchMerchant ? 20 : 40,
                              fontWeight: "600",
                            }}
                          >
                            Stock Transfer
                          </Text>
                        </View>
                        <Text
                          style={{
                            alignSelf: "center",
                            fontSize: switchMerchant ? 10 : 16,
                            color: "#adadad",
                          }}
                        >
                          Fill In The Stock Transfer Information
                        </Text>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: 55,
                          justifyContent: "space-between",
                          width: "80%",
                          alignSelf: "center",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            width: "45%",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              width: "42%",
                              fontFamily: "NunitoSans-Bold",
                              fontSize: switchMerchant ? 10 : 14,
                              fontWeight: "400",
                            }}
                          >
                            ID
                          </Text>
                          <View
                            style={{
                              width: "58%",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <View
                              style={{
                                justifyContent: "center",
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                                height: 35,
                                width: "90%",
                                backgroundColor: Colors.fieldtBgColor,
                                paddingHorizontal: 10,
                              }}
                            >
                              {editMode ? (
                                <TextInput
                                  //editable={false}
                                  style={{
                                    // backgroundColor: Colors.fieldtBgColor,
                                    // borderRadius: 5,
                                    // padding: 5,
                                    // borderWidth: 1,
                                    // borderColor: '#E5E5E5',
                                    //paddingLeft:10,
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                  placeholder="ID (Max Length 12)"
                                  placeholderStyle={{
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                  onChangeText={(text) => {
                                    setPoId(text);
                                  }}
                                  maxLength={12}
                                  //value={`ST${poId}`}
                                />
                              ) : (
                                <Text
                                  style={{
                                    fontSize: 15,
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                >
                                  {`${poId}`}
                                </Text>
                              )}
                            </View>
                            {selectedStockTransferEdit ? (
                              <></>
                            ) : (
                              <TouchableOpacity
                                style={{ marginLeft: 5 }}
                                onPress={() => {
                                  setEditMode(!editMode);
                                }}
                              >
                                {/* <MaterialCommunityIcons name="pencil" size={20} color={Colors.primaryColor} /> */}
                                <EditGreen />
                              </TouchableOpacity>
                            )}
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "50%",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              width: "38%",
                              fontFamily: "NunitoSans-Bold",
                              fontSize: switchMerchant ? 10 : 14,
                              fontWeight: "400",
                            }}
                          >
                            Source Store
                          </Text>

                          <View style={{ width: "62%" }}>
                            {targetOutletDropdownList.find(
                              (item) => item.value === selectedSourceOutletId
                            ) ? (
                              <Picker
                                disabled={
                                  selectedStockTransferEdit ? true : false
                                }
                                selectedValue={selectedSourceOutletId}
                                style={{}}
                                onValueChange={(value, text) => {
                                  setSelectedSourceOutletIdPrev(
                                    selectedSourceOutletId
                                  );
                                  setSelectedSourceOutletId(value);
                                }}
                              >
                                {targetOutletDropdownList.map(
                                  (value, index) => {
                                    return (
                                      <Picker.Item
                                        key={index}
                                        label={value.label}
                                        value={value.value}
                                      />
                                    );
                                  }
                                )}
                              </Picker>
                            ) : (
                              //   <DropDownPicker
                              //     containerStyle={{ height: 35, zIndex: 2 }}
                              //     arrowColor={"black"}
                              //     arrowSize={20}
                              //     arrowStyle={{ fontWeight: "bold" }}
                              //     labelStyle={{
                              //       fontFamily: "NunitoSans-Regular",
                              //       fontSize: switchMerchant ? 10 : 14,
                              //     }}
                              //     style={{
                              //       width: switchMerchant ? 170 : 220,
                              //       paddingVertical: 0,
                              //       backgroundColor: Colors.fieldtBgColor,
                              //       borderRadius: 10,
                              //     }}
                              //     placeholderStyle={{
                              //       color: Colors.fieldtTxtColor,
                              //       fontFamily: "NunitoSans-Regular",
                              //       fontSize: switchMerchant ? 10 : 14,
                              //     }}
                              //     disabled={
                              //       selectedStockTransferEdit ? true : false
                              //     }
                              //     items={targetOutletDropdownList}
                              //     itemStyle={{
                              //       justifyContent: "flex-start",
                              //       marginLeft: 5,
                              //       zIndex: 2,
                              //     }}
                              //     placeholder={"Product"}
                              //     // customTickIcon={(press) => (
                              //     //   <Ionicon
                              //     //     name={"md-checkbox"}
                              //     //     color={
                              //     //       press
                              //     //         ? Colors.fieldtBgColor
                              //     //         : Colors.primaryColor
                              //     //     }
                              //     //     size={25}
                              //     //   />
                              //     // )}
                              //     onChangeItem={(item) => {
                              //       setSelectedSourceOutletIdPrev(
                              //         selectedSourceOutletId
                              //       );
                              //       setSelectedSourceOutletId(item.value);
                              //     }}
                              //     defaultValue={selectedSourceOutletId}
                              //     dropDownMaxHeight={150}
                              //     dropDownStyle={{
                              //       width: switchMerchant ? 170 : 220,
                              //       height: 80,
                              //       backgroundColor: Colors.fieldtBgColor,
                              //       borderRadius: 10,
                              //       borderWidth: 1,
                              //       textAlign: "left",
                              //       zIndex: 2,
                              //     }}
                              //     // disabled={selectedStockTransferEdit ? true : false}
                              //     // items={targetOutletDropdownList}
                              //     // defaultValue={selectedSourceOutletId}
                              //     // arrowSize={20}
                              //     // controller={(instance) => dropDownRef.current = instance}
                              //     // // placeholder="Source Store"
                              //     // containerStyle={{ height: 35, width: 220 }}
                              //     // style={{ backgroundColor: '#FAFAFA', paddingVertical: 0, }}
                              //     // itemStyle={{
                              //     //   justifyContent: 'flex-start', marginLeft: 5
                              //     // }}
                              //     // dropDownStyle={{ backgroundColor: '#FAFAFA', width: 220 }}
                              //     // onChangeItem={(item) => {
                              //     //   setSelectedSourceOutletIdPrev(selectedSourceOutletId);
                              //     //   setSelectedSourceOutletId(item.value);
                              //     // }}
                              //     // onOpen={() => { dropDownRef1.current.close() }}
                              //     // ref={dropDownRef}
                              //   />
                              <></>
                            )}
                          </View>
                          {/* <View style={{ width: '62%' }}>
                          <View style={{ width: switchMerchant ? 170 : 220, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10 }}>
                            <Text>
                              {selectedSourceOutletId}
                            </Text>
                          </View>
                        </View> */}
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: 10,
                        justifyContent: "space-between",
                        alignSelf: "center",
                        marginTop: 50,
                        zIndex: -1,
                        width: "80%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          width: "45%",
                          alignItems: "center",
                        }}
                      >
                        {/* <Text style={{ fontSize: 16, width: '40%', textAlign: 'left' }}>Current status</Text>
                        <View style={{ paddingHorizontal: 18, paddingVertical: 10, alignItems: "center", backgroundColor: '#838387', borderRadius: 10, width: '40%' }}>
                          <Text style={{ color: Colors.whiteColor }}>{PURCHASE_ORDER_STATUS_PARSED[poStatus]}</Text>
                        </View> */}

                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: "42%",
                            fontWeight: "400",
                          }}
                        >
                          Created Date
                        </Text>
                        <View style={{ width: "58%", alignItems: "baseline" }}>
                          <View
                            style={{
                              paddingHorizontal: 10,
                              borderColor: "#E5E5E5",
                              borderWidth: 1,
                              borderRadius: 5,
                              width: "90.5%",
                              height: 35,
                              justifyContent: "space-between",
                              backgroundColor: Colors.fieldtBgColor,
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <GCalendarGrey
                              width={switchMerchant ? 15 : 20}
                              height={switchMerchant ? 15 : 20}
                              style={{ marginRight: 5 }}
                            />
                            <Text
                              style={{
                                marginRight: "18%",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              {selectedStockTransferEdit
                                ? moment(createdDate).format("DD MMM YYYY")
                                : moment(date1).format("DD MMM YYYY")}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          width: "50%",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: "38%",
                            fontWeight: "400",
                          }}
                        >
                          Destination Store
                        </Text>
                        <View style={{ width: "62%" }}>
                          {targetOutletDropdownList.find(
                            (item) => item.value === selectedTargetOutletId
                          ) ? (
                            // <RNPickerSelect
                            //   disabled={selectedStockTransferEdit ? true : false}
                            //   items={targetOutletDropdownList}
                            //   defaultValue={selectedTargetOutletId}
                            //   arrowSize={20}
                            //   arrowStyle={{ paddingVertical: 0 }}
                            //   // placeholder="Target Store"
                            //   containerStyle={{ height: 35, width: 180, }}
                            //   style={{ backgroundColor: '#FAFAFA', paddingVertical: 0 }}
                            //   itemStyle={{
                            //     justifyContent: 'flex-start', marginLeft: 5
                            //   }}
                            //   dropDownStyle={{ backgroundColor: '#FAFAFA', width: 180 }}
                            //   onValueChange={(item) =>
                            //     // setState({
                            //     //   choice7: item.value,
                            //     // })
                            //     // setSelectedSupplierId(item.value)
                            //     setSelectedTargetOutletId(item.value)
                            //   }
                            // />
                            <Picker
                              disabled={
                                selectedStockTransferEdit ? true : false
                              }
                              selectedValue={selectedTargetOutletId}
                              style={{}}
                              onValueChange={(value, text) => {
                                setSelectedTargetOutletId(value);
                              }}
                            >
                              {targetOutletDropdownList.map((value, index) => {
                                return (
                                  <Picker.Item
                                    key={index}
                                    label={value.label}
                                    value={value.value}
                                  />
                                );
                              })}
                            </Picker>
                          ) : (
                            // <DropDownPicker
                            //   containerStyle={{ height: 35, zIndex: 2 }}
                            //   arrowColor={"black"}
                            //   arrowSize={20}
                            //   arrowStyle={{ fontWeight: "bold" }}
                            //   labelStyle={{
                            //     fontFamily: "NunitoSans-Regular",
                            //     fontSize: switchMerchant ? 10 : 14,
                            //   }}
                            //   style={{
                            //     width: switchMerchant ? 170 : 220,
                            //     paddingVertical: 0,
                            //     backgroundColor: Colors.fieldtBgColor,
                            //     borderRadius: 10,
                            //   }}
                            //   placeholderStyle={{
                            //     color: Colors.fieldtTxtColor,
                            //     fontFamily: "NunitoSans-Regular",
                            //     fontSize: switchMerchant ? 10 : 14,
                            //   }}
                            //   disabled={
                            //     selectedStockTransferEdit ? true : false
                            //   }
                            //   items={targetOutletDropdownList}
                            //   itemStyle={{
                            //     justifyContent: "flex-start",
                            //     marginLeft: 5,
                            //     zIndex: 2,
                            //   }}
                            //   placeholder={"Product"}
                            //   //   customTickIcon={(press) => (
                            //   //     <Ionicon
                            //   //       name={"md-checkbox"}
                            //   //       color={
                            //   //         press
                            //   //           ? Colors.fieldtBgColor
                            //   //           : Colors.primaryColor
                            //   //       }
                            //   //       size={25}
                            //   //     />
                            //   //   )}
                            //   onChangeItem={(item) =>
                            //     setSelectedTargetOutletId(item.value)
                            //   }
                            //   defaultValue={selectedTargetOutletId}
                            //   dropDownMaxHeight={150}
                            //   dropDownStyle={{
                            //     width: switchMerchant ? 170 : 220,
                            //     height: 80,
                            //     backgroundColor: Colors.fieldtBgColor,
                            //     borderRadius: 10,
                            //     borderWidth: 1,
                            //     textAlign: "left",
                            //     zIndex: 2,
                            //   }}
                            //   // disabled={selectedStockTransferEdit ? true : false}
                            //   // items={targetOutletDropdownList}
                            //   // defaultValue={selectedTargetOutletId}
                            //   // arrowSize={20}
                            //   // arrowStyle={{ paddingVertical: 0 }}
                            //   // controller={(instance) => dropDownRef1.current = instance}
                            //   // // placeholder="Target Store"
                            //   // containerStyle={{ height: 35, width: 220, }}
                            //   // style={{ backgroundColor: '#FAFAFA', paddingVertical: 0 }}
                            //   // itemStyle={{
                            //   //   justifyContent: 'flex-start', marginLeft: 5
                            //   // }}
                            //   // dropDownStyle={{ backgroundColor: '#FAFAFA', width: 220 }}
                            //   // onChangeItem={(item) =>
                            //   //   setSelectedTargetOutletId(item.value)
                            //   // }
                            //   // onOpen={() => { dropDownRef.current.close() }}
                            //   // ref={dropDownRef1}
                            // />
                            <></>
                          )}
                        </View>
                        {/* <View style={{ width: '62%' }}>
                          <View style={{ width: switchMerchant ? 170 : 220, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10 }}>
                            <Text>
                              {selectedSourceOutletId}
                            </Text>
                          </View>
                        </View> */}
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignSelf: "center",
                        marginTop: 50,
                        marginBottom: 40,
                        zIndex: -2,
                        width: "80%",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          width: "45%",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: "42%",
                            fontWeight: "400",
                          }}
                        >
                          Shipped Date
                        </Text>
                        {/* <Text style={{ color: '#adadad', marginLeft: 80, fontSize: 16, }}>8/10/2020</Text> */}
                        <View
                          style={{
                            paddingHorizontal: 0,
                            flexDirection: "row",
                            alignItems: "center",
                            width: "57%",
                          }}
                        >
                          <View
                            style={{
                              // width: 140,
                              width: "90.5%",
                              height: 35,
                              backgroundColor: Colors.fieldtBgColor,
                              paddingHorizontal: 10,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderRadius: 5,
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                            }}
                          >
                            <TouchableOpacity
                              style={{ marginRight: 5 }}
                              disabled={
                                poStatus === PURCHASE_ORDER_STATUS.COMPLETED
                              }
                              onPress={() => {
                                setIsDateTimePickerVisible(true);
                              }}
                            >
                              {poStatus === PURCHASE_ORDER_STATUS.COMPLETED ? (
                                <GCalendarGrey
                                  width={switchMerchant ? 15 : 20}
                                  height={switchMerchant ? 15 : 20}
                                />
                              ) : (
                                <GCalendar
                                  width={switchMerchant ? 15 : 20}
                                  height={switchMerchant ? 15 : 20}
                                />
                              )}
                            </TouchableOpacity>
                            <View style={{ paddingRight: "15%" }}>
                              <DatePicker
                                disabled={
                                  poStatus === PURCHASE_ORDER_STATUS.COMPLETED
                                }
                                selected={moment(date).toDate()}
                                onChange={(date) => {
                                  setDate(moment(date));
                                }}
                                minDate={moment().toDate()}
                              />
                            </View>
                          </View>

                          {/* <DateTimePickerModal
                            isVisible={isDateTimePickerVisible}
                            mode="date"
                            onConfirm={(text) => {
                              setDate(text);
                              setIsDateTimePickerVisible(false);
                            }}
                            onCancel={() => {
                              setIsDateTimePickerVisible(false);
                            }}
                          /> */}
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: "#E5E5E5",
                        zIndex: -5,
                      }}
                    />

                    {/* <ScrollView horizontal={true}
                    nestedScrollEnabled={true}
                    scrollEnabled={switchMerchant}
                    style={{ flexDirection: 'column' }}
                    contentContainerStyle={{
                      flexDirection: 'column',
                      paddingRight: switchMerchant ? 15 : 0,
                      width: switchMerchant ? windowWidth * 0.97 : windowWidth * 0.87,
                      //maxHeight: switchMerchant ? windowHeight * 0.9 : null,
                    }}
                  > */}
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        marginBottom: 10,
                        zIndex: -5,
                      }}
                    >
                      <Text
                        style={{
                          alignSelf: "center",
                          marginTop: 30,
                          fontSize: switchMerchant ? 15 : 20,
                          fontWeight: "bold",
                        }}
                      >
                        Stock to Transfer
                      </Text>
                    </View>

                    <View
                      style={{
                        backgroundColor: "#ffffff",
                        flexDirection: "row",
                        paddingVertical: 20,
                        paddingHorizontal: 10,
                        marginTop: 10,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                        zIndex: -5,
                      }}
                    >
                      <Text
                        style={{
                          flex: 2,
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          fontWeight: "500",
                        }}
                      >
                        Product Name
                      </Text>
                      <Text
                        style={{
                          flex: 1,
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          fontWeight: "500",
                          marginLeft: 10,
                        }}
                      >
                        SKU
                      </Text>
                      <Text
                        style={{
                          flex: 0.8,
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          fontWeight: "500",
                        }}
                      >
                        Unit
                      </Text>
                      <Text
                        style={{
                          flex: 0.8,
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          fontWeight: "500",
                        }}
                      >
                        In Stock
                      </Text>
                      <Text
                        style={{
                          flex: 1,
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          fontWeight: "500",
                        }}
                      >
                        Transfer Qty
                      </Text>
                      <Text
                        style={{
                          flex: 1,
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          fontWeight: "500",
                        }}
                      >
                        Balance Stock
                      </Text>
                      <Text
                        style={{
                          flex: 0.9,
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          fontWeight: "500",
                        }}
                      >
                        Cost (RM)
                      </Text>
                      <Text
                        style={{
                          flex: 0.9,
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Bold",
                          fontWeight: "500",
                        }}
                      >
                        Subtotal (RM)
                      </Text>
                      <View style={{ flex: 0.3 }} />
                    </View>
                    {outletSupplyItems.length > 0 ? (
                      <FlatList
                        nestedScrollEnabled={true}
                        showsVerticalScrollIndicator={false}
                        data={poItems}
                        extraData={poItems}
                        renderItem={renderAddStock}
                        keyExtractor={(item, index) => String(index)}
                        style={{ zIndex: -5 }}
                      />
                    ) : (
                      <View
                        style={{
                          alignItems: "center",
                          marginVertical: 20,
                          marginTop: 50,
                          zIndex: -5,
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.descriptionColor,
                            fontFamily: "NunitoSans-Regular",
                            fontSize: switchMerchant ? 10 : 14,
                          }}
                        >
                          No supply items In current store
                        </Text>
                      </View>
                    )}

                    {outletSupplyItems.length > 0 ? (
                      <View
                        style={{
                          flexDirection: "row",
                          marginBottom: 20,
                          justifyContent: "space-between",
                        }}
                      >
                        {!selectedStockTransferEdit ? (
                          <TouchableOpacity
                            style={styles.submitText2}
                            onPress={() => {
                              if (outletSupplyItems.length > 0) {
                                setPoItems([
                                  ...poItems,
                                  {
                                    // supplyItemId: '',
                                    // name: '',
                                    // sku: '',
                                    // quantity: '',
                                    // insertQuantity: 0,
                                    outletSupplyItemId:
                                      outletSupplyItems[0].uniqueId,
                                    name: outletSupplyItems[0].name,
                                    sku: outletSupplyItems[0].sku,
                                    unit: outletSupplyItems[0].unit,
                                    skuMerchant:
                                      outletSupplyItems[0].skuMerchant,
                                    quantity: outletSupplyItems[0].quantity,
                                    transferQuantity: 0,
                                    balance: 0,
                                    price: outletSupplyItems[0].price,
                                    totalPrice: 0,

                                    supplyItem: supplyItems[0],
                                  },
                                ]);
                              } else {
                                Alert.alert("Error", "No supplier items added");
                              }
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginTop: 5,
                                marginLeft: 0,
                              }}
                            >
                              <Icon1
                                name="plus-circle"
                                size={switchMerchant ? 15 : 20}
                                color={Colors.primaryColor}
                              />
                              <Text
                                style={{
                                  marginLeft: 10,
                                  color: Colors.primaryColor,
                                  marginBottom: 1,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                Add Product Slot
                              </Text>
                            </View>
                          </TouchableOpacity>
                        ) : (
                          <></>
                        )}

                        <View
                          style={{
                            alignItems: "flex-end",
                            marginTop: 5,
                            marginRight: 20,
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: switchMerchant ? 10 : 16,
                            }}
                          >
                            {poItems.totalPrice}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {/* </ScrollView> */}

                    {/* {!selectedStockTransferEdit || (selectedStockTransferEdit && selectedStockTransferEdit.status !== STOCK_TRANSFER_STATUS.COMPLETED) ?
                    <>
                      <View style={{ flexDirection: "row", alignSelf: "center", justifyContent: "space-evenly", marginTop: 20 }}>
                        

                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 200,
                            height: 40,
                            marginVertical: 15,
                            borderRadius: 5,
                            alignSelf: 'center',
                            marginLeft: 40,
                            display: selectedStockTransferEdit ? 'flex' : 'none',
                          }}>
                          <TouchableOpacity onPress={() => {
                            createStockTransfer(STOCK_TRANSFER_STATUS.COMPLETED);
                          }} disabled={selectedStockTransferEdit ? false : true}>
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                alignSelf: 'center',
                                marginVertical: 10,
                              }}>
                              {'SAVE & MARK COMPLETED'}
                            </Text>
                          </TouchableOpacity>
                          <Modal
                            style={{ flex: 1 }}
                            visible={visible}
                            transparent={true}
                            animationType="slide">
                            <View
                              style={{
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: Dimensions.get('window').height,
                              }}>
                              <View style={styles.confirmBox}>
                                <TouchableOpacity
                                  onPress={() => {
                                    setState({ visible: false });
                                  }}>
                                  <View
                                    style={{
                                      alignSelf: 'flex-end',
                                      padding: 16,
                                    }}>
                                    <Close name="closecircle" size={28} />
                                  </View>
                                </TouchableOpacity>
                                <View>
                                  <Text
                                    style={{
                                      textAlign: 'center',
                                      fontWeight: '700',
                                      fontSize: 28,
                                    }}>
                                    Stock Transfer
                                  </Text>
                                </View>
                                <View style={{ marginTop: 20 }}>
                                  <Text
                                    style={{
                                      textAlign: 'center',
                                      color: Colors.descriptionColor,
                                      fontSize: 25,
                                    }}>
                                    Fill in the email information
                                  </Text>
                                </View>
                                <View style={{ backgroundColor: 'white', alignSelf: 'center', flexDirection: "row" }}>
                                  <Text style={{ fontSize: 20, marginTop: 70 }}>
                                    Email:
                                  </Text>
                                  <View style={{ marginTop: 60, backgroundColor: '#f7f5f5', marginLeft: 10 }}>
                                    <TextInput
                                      editable={!loading}
                                      underlineColorAndroid={Colors.fieldtBgColor}
                                      clearButtonMode="while-editing"
                                      style={styles.textCapacity}
                                      placeholder="myburgerlabmytown@gmail.com"
                                      onChangeText={(text) => {
                                        setState({ Email: text });
                                      }}
                                      value={Email}
                                    />
                                  </View>
                                </View>
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    alignSelf: 'center',
                                    marginTop: 20,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '50%',
                                    alignContent: 'center',
                                    zIndex: 6000,
                                  }}>

                                </View>
                                <View
                                  style={{
                                    alignSelf: 'center',
                                    marginTop: 20,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: 250,
                                    height: 40,
                                    alignContent: 'center',
                                    flexDirection: "row",
                                    marginTop: 40
                                  }}>
                                  <TouchableOpacity
                                    onPress={() => {
                                      email(),
                                        setState({ visible: false });
                                    }}
                                    style={{
                                      backgroundColor: Colors.fieldtBgColor,
                                      width: '60%',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      alignContent: 'center',
                                      borderRadius: 10,
                                      height: 60,
                                    }}>
                                    <Text style={{ fontSize: 28, color: Colors.primaryColor }}>
                                      Send
                                    </Text>
                                  </TouchableOpacity>
                                  <TouchableOpacity
                                    onPress={() => {
                                      setState({ visible: false });
                                    }}
                                    style={{
                                      backgroundColor: Colors.fieldtBgColor,
                                      width: '60%',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      alignContent: 'center',
                                      borderRadius: 10,
                                      height: 60,
                                      marginLeft: 30
                                    }}>
                                    <Text style={{ fontSize: 28, color: Colors.primaryColor }}>
                                      No
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </Modal>
                        </View>
                      </View>

                    </>
                    :
                    <></>
                  } */}

                    {/* <View
                    style={{
                      flexDirection: 'row',
                      backgroundColor: '#ffffff',
                      justifyContent: 'center',
                      padding: 18,
                    }}>
                    <View style={{ alignItems: 'center' }}>
                      <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
                        {stockTransfers.length}
                      </Text>
                      <Text>STOCK TRANSFERS</Text>
                    </View>
                  </View> */}
                    <View style={{ height: 50 }}></View>
                  </ScrollView>
                </View>
                {/* </ScrollView> */}
              </View>
            </View>
          ) : null}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    width: useWindowDimensions.width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 16,
    width: useWindowDimensions.width * (1 - Styles.sideBarWidth),
    alignItems: "center",
  },
  submitText: {
    height: useWindowDimensions.height * 0.05,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  submitText1: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    alignSelf: "flex-end",
    marginRight: 20,
    // marginTop: 15,
    height: 40,
    width: 220,
  },
  submitText2: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    alignSelf: "flex-end",
    marginRight: 20,
    marginTop: 15,
  },
  /* textInput: {
    width: 300,
    height: '10%',
    padding: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 20,
  }, */

  textInput: {
    width: 200,
    height: 40,
    // padding:5,
    backgroundColor: Colors.whiteColor,
    borderRadius: 0,
    marginRight: "35%",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "flex-end",

    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 0.51,
    elevation: 15,
  },
  searchIcon: {
    backgroundColor: Colors.whiteColor,
    height: 40,
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,

    elevation: 15,
  },
  textInput1: {
    width: 160,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 5,
  },
  textInput2: {
    width: 100,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 5,
    textAlign: "center",
  },
  confirmBox: {
    width: 450,
    height: 450,
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  textFieldInput: {
    height: 80,
    width: 900,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  headerLeftStyle: {
    width: useWindowDimensions.width * 0.17,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    height: useWindowDimensions.width * 0.2,
    width: useWindowDimensions.width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: useWindowDimensions.width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView1: {
    //height: useWindowDimensions.width * 0.2,
    width: useWindowDimensions.width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: useWindowDimensions.width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  modalViewImport: {
    height: useWindowDimensions.width * 0.6,
    width: useWindowDimensions.width * 0.6,
    backgroundColor: Colors.whiteColor,
    borderRadius: useWindowDimensions.width * 0.03,
    padding: 20,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  closeButton: {
    position: "absolute",
    right: useWindowDimensions.width * 0.02,
    top: useWindowDimensions.width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: "center",
    top: "20%",
    position: "absolute",
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitleText: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    width: useWindowDimensions.width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
});
export default StockTransferScreen;
