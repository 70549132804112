import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Modal,
  useWindowDimensions,
} from "react-native";
import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
import AntDesign from "react-native-vector-icons/AntDesign";
import Ionicon from "react-native-vector-icons/Ionicons";
import SimpleLineIcons from "react-native-vector-icons/SimpleLineIcons";
import { TextInput, FlatList } from "react-native-gesture-handler";
import API from "../constant/API";
import ApiClient from "../util/ApiClient";
import * as User from "../util/User";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { color } from "react-native-reanimated";
import Close from "react-native-vector-icons/AntDesign";
import Styles from "../constant/Styles";
import moment, { isDate } from "moment";
import Switch from "react-switch";
import Ionicons from "react-native-vector-icons/Ionicons";
import { UserStore } from "../store/userStore";
import { MerchantStore } from "../store/merchantStore";
import { CommonStore } from "../store/commonStore";

const SettingsReservation = (props) => {
  const { navigation } = props;

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const [selfCollect, setSelfCollect] = useState(true);
  const [openHourPickerVisible, setOpenHourPickerVisible] = useState(false);
  const [closeHourPickerVisible, setCloseHourPickerVisible] = useState(false);
  const [openHour, setOpenHour] = useState("");
  const [closeHour, setCloseHour] = useState("");
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked5, setIsChecked5] = useState(false);
  const [isChecked6, setIsChecked6] = useState(false);
  const [isChecked7, setIsChecked7] = useState(false);
  const [isChecked8, setIsChecked8] = useState(false);
  const [isChecked9, setIsChecked9] = useState(false);
  const [isChecked10, setIsChecked10] = useState(false);
  const [isChecked11, setIsChecked11] = useState(false);
  const [isChecked12, setIsChecked12] = useState(false);
  const [isChecked13, setIsChecked13] = useState(false);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [amount, setAmount] = useState("");
  const [hourStart, setHourStart] = useState("");
  const [hourEnd, setHourEnd] = useState("");
  const [days, setDays] = useState(false);
  const [days1, setDays1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDistance, setShowDistance] = useState("");
  const [expiryPeriod, setExpiryPeriod] = useState("");
  const [extentionCharges, setExtentionCharges] = useState("");
  const [extentionDuration, setExtentionDuration] = useState("");
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [pickerMode, setPickerMode] = useState("datetime");
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [merchantDisplay, setMerchantDisplay] = useState(false);
  const [shift, setShift] = useState(false);
  const [tax, setTax] = useState(false);
  const [sample, setSample] = useState(false);
  const [redemption, setRedemption] = useState(false);
  const [redemptionList, setRedemptionList] = useState(false);
  const [redemptionAdd, setRedemptionAdd] = useState(false);
  const [order, setOrder] = useState(true);
  const [previousState, setPreviousState] = useState(false);
  const [receipt, setReceipt] = useState([]);
  const [detail, setDetail] = useState([]);
  const [merchantInfo, setMerchantInfo] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [outletInfo, setOutletInfo] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [merInfo, setMerInfo] = useState([]);
  // const [merchantId, setMerchantId] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [closingAmount, setClosingAmount] = useState("");
  const [options, setOptions] = useState([]);
  const [shift1, setShift1] = useState([]);
  const [status, setStatus] = useState(false);
  const [logo, setLogo] = useState("");
  const [cover, setCover] = useState("");
  const [name, setName] = useState("");
  const [tname, setTname] = useState("");
  const [rate, setRate] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [payment, setPayment] = useState("");
  const [time, setTime] = useState("");
  const [statue, setStatue] = useState("");
  const [status1, setStatus1] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [outletId, setOutletId] = useState(null);
  const [myTextInput, setMyTextInput] = useState(React.createRef());
  const [start_time, setStart_time] = useState(false);
  const [end_time, setEnd_time] = useState(false);
  const [rev_time, setRev_time] = useState("");
  const [category, setCategory] = useState("");
  const [close, setClose] = useState("Closed");
  const [showNote, setShowNote] = useState(false);
  const [expandView, setExpandView] = useState(false);
  const [value, setValue] = useState("");
  const [extendOption, setExtendOption] = useState([
    { optionId: 1, price: 20, day: 7, days: false },
  ]);
  const [redemptionInfo, setRedemptionInfo] = useState([]);
  const [alloutlet, setAlloutlet] = useState([]);
  const [discount, setDiscount] = useState("");
  const [amount1, setAmount1] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [categoryOutlet, setCategoryOutlet] = useState([]);
  const [extend, setExtend] = useState([]);
  const [outletss, setOutletss] = useState([]);
  const [redemptionDetail, setRedemptionDetail] = useState([]);
  const [outletInfo1, setOutletInfo1] = useState([]);
  const [category1, setCategory1] = useState([]);
  // const [merchantName, setMerchantName] = useState('');
  const [addOutletName, setAddOutletName] = useState("");
  const [addOutletWindow, setAddOutletWindow] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [note1, setNote1] = useState("");
  const [note2, setNote2] = useState("");
  const [note3, setNote3] = useState("");
  const [openings, setOpenings] = useState([]);
  const [editOpeningIndex, setEditOpeningIndex] = useState(0);

  //////////////////////////////////////////////////////////////////////////
  const [reservationAvailablityHour, setReservationAvailablityHour] =
    useState(0);
  const [reservationAvailablityMinute, setReservationAvailablityMinute] =
    useState(0);
  const [deposit, setDeposit] = useState(0);
  const [price, setPrice] = useState(0);
  const [termCondition, setTermCondition] = useState("");
  // toggle
  const [depositSwitch, setDepositSwitch] = useState(false);

  //////////////////////////////////////////////////////////////////////////

  const [deliveryDistance, setDeliveryDistance] = useState("1");
  const [freeDeliveyAboveAmountValue, setFreeDeliveyAboveAmountValue] =
    useState("");
  const [freeDeliveyAboveAmountFlag, setFreeDeliveyAboveAmountFlag] =
    useState(false);
  const [discountOrderAboveAmountValue, setDiscountOrderAboveAmountValue] =
    useState("");
  const [
    discountOrderAboveAmountThreshold,
    setDiscountOrderAboveAmountThreshold,
  ] = useState("");

  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [pickUpPrice, setPickUpPrice] = useState(0);

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState("");

  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const merchantId = UserStore.useState((s) => s.merchantId);

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);
  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const currOutlet = MerchantStore.useState((s) => s.currOutlet);
  const [newReservationStatus, setNewReservationStatus] = useState(false);

  const [data] = useState(["DEPOSIT", "RESERVATION FEES"]);
  const [selectedData, setSelectedData] = useState("");

  const getOutlet = () => {
    ApiClient.GET(API.outlet + User.getOutletId())
      .then((result) => {
        setState({ switchState: result[0].queueStatus });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const switchQueueStatus = (value) => {
    // self.onButtonClickHandler();

    var body = {
      // outletId: User.getOutletId()
      outletId: currOutlet.uniqueId,
      queueStatus: value,
    };

    CommonStore.update((s) => {
      s.isLoading = true;
    });

    ApiClient.POST(API.switchQueueStatus, body)
      .then((result) => {
        if (result.status) {
          setTimeout(() => {
            CommonStore.update((s) => {
              s.isLoading = false;
            });
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const selectedTargetOutlet = allOutlets.find(
      (outlet) => outlet.uniqueId === selectedTargetOutletId
    );

    if (selectedTargetOutlet) {
      setDeliveryDistance(
        selectedTargetOutlet.deliveryDistance
          ? selectedTargetOutlet.deliveryDistance.toFixed(0)
          : "1"
      );
      setFreeDeliveyAboveAmountValue(
        selectedTargetOutlet.freeDeliveyAboveAmountValue
          ? selectedTargetOutlet.freeDeliveyAboveAmountValue.toFixed(2)
          : "0"
      );
      setFreeDeliveyAboveAmountFlag(
        selectedTargetOutlet.freeDeliveyAboveAmountFlag
          ? selectedTargetOutlet.freeDeliveyAboveAmountFlag
          : false
      );
      setDiscountOrderAboveAmountValue(
        selectedTargetOutlet.discountOrderAboveAmountValue
          ? selectedTargetOutlet.discountOrderAboveAmountValue.toFixed(2)
          : "0"
      );
      setDiscountOrderAboveAmountThreshold(
        selectedTargetOutlet.discountOrderAboveAmountThreshold
          ? selectedTargetOutlet.discountOrderAboveAmountThreshold.toFixed(2)
          : "0"
      );

      setDeliveryPrice(
        selectedTargetOutlet.deliveryPrice
          ? selectedTargetOutlet.deliveryPrice.toFixed(2)
          : 0
      );
      setPickUpPrice(
        selectedTargetOutlet.pickUpPrice
          ? selectedTargetOutlet.pickUpPrice.toFixed(2)
          : 0
      );
    }
  }, [selectedTargetOutletId]);

  useEffect(() => {
    setTargetOutletDropdownList(
      allOutlets.map((outlet) => ({
        label: outlet.name,
        value: outlet.uniqueId,
      }))
    );

    if (selectedTargetOutletId === "" && allOutlets.length > 0) {
      setSelectedTargetOutletId(allOutlets[0].uniqueId);
    }
  }, [allOutlets]);

  //////////////////////////////////////////////////////////////////////////

  const setState = () => {};

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View
        style={{
          width: windowWidth * 0.17,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            // bottom: -2,
            bottom: switchMerchant ? "2%" : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? { right: windowWidth * 0.1 }
            : {},
          windowWidth <= 768 ? { right: windowWidth * 0.12 } : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Reservation Settings
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // componentDidMount = () => {

  //   ApiClient.GET(API.getOutletByMerchant + User.getMerchantId()).then((result) => {
  //     setState({ outletInfo: result });
  //     result.map((element) => {
  //       setState({
  //         outletId: element.id,
  //         outletName: element.name,
  //         merchantName: element.merchant.name
  //       });
  //     });
  //   });

  //   ApiClient.GET(API.getAllSettingRedemption + User.getMerchantId()).then((result) => {
  //     setState({ redemptionInfo: result });
  //   });

  //   outlet()
  //   ApiClient.GET(API.getOutletCategory + User.getOutletId()).then((result) => {
  //     if (result !== undefined) {
  //       setState({ categoryOutlet: result });
  //     }

  //   });
  // }

  const orderFunc = () => {
    setLoading(true);
    // var body = {
    //   merchantId: User.getMerchantId(),
    //   isAllOutlet: isChecked6 == true ? '1' : null,
    //   outletId: outletId,
    //   deliveryDistance: showDistance,
    //   deliveryFee: isChecked6 == true ? amount : amount1,
    //   deliveryHourStart: hourStart,
    //   deliveryHourEnd: hourEnd,
    //   deliveryPrice: isChecked8 == true ? value1 : 0,
    //   pickUpPrice: isChecked9 == true ? value2 : 0,
    //   fireorder: status1,
    //   category: category,
    // };

    var body = {
      merchantId: merchantId,
      outletId: selectedTargetOutletId,
      deliveryDistance: +parseFloat(deliveryDistance).toFixed(2),
      freeDeliveyAboveAmountValue: +parseFloat(
        freeDeliveyAboveAmountValue
      ).toFixed(2),
      freeDeliveyAboveAmountFlag: freeDeliveyAboveAmountFlag,
      discountOrderAboveAmountValue: +parseFloat(
        discountOrderAboveAmountValue
      ).toFixed(2),
      discountOrderAboveAmountThreshold: +parseFloat(
        discountOrderAboveAmountThreshold
      ).toFixed(2),
      deliveryPrice: deliveryPrice,
      pickUpPrice: pickUpPrice,
    };

    console.log(body);

    ApiClient.POST(API.updateOutletOrderDetails, body, false).then((result) => {
      if (result && result.status === "success") {
        Alert.alert(
          "Success",
          "Updated successfully",
          [
            {
              text: "OK",
              onPress: () => {
                setLoading(false);
              },
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  const _logout = async () => {
    await AsyncStorage.clear();
    User.setlogin(false);
    User.getRefreshMainScreen();
  };

  const addSection = () => {
    setState({ showNote: true });
  };

  // function end

  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    // <View style={styles.container}>
    //   <View style={styles.sidebar}>
    <View style={[styles.container]}>
      <View
        style={[
          styles.sidebar,
          switchMerchant
            ? {
                // width: '10%'
              }
            : {},
        ]}
      >
        <SideBar
          navigation={props.navigation}
          selectedTab={10}
          expandSettings={true}
        />
      </View>

      <ScrollView
        showsVerticalScrollIndicator={false}
        // scrollEnabled={switchMerchant}
        style={{ backgroundColor: Colors.highlightColor }}
        contentContainerStyle={{
          paddingBottom: windowHeight * 0.1,
          backgroundColor: Colors.highlightColor,
        }}
      >
        <ScrollView horizontal={true}>
          <View style={styles.content}>
            {order ? (
              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  // height: Dimensions.get('window').height - 120,
                  height: "100%",
                  width: windowWidth * 0.87,
                  alignSelf: "center",
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  // elevation: 1,
                  elevation: 3,
                  borderRadius: 5,

                  // borderRadius: 8,
                }}
              >
                <View>
                  <View
                    style={
                      {
                        // marginBottom: 15,
                        // borderBottomWidth: StyleSheet.hairlineWidth,
                      }
                    }
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        padding: 30,
                        zIndex: -3,
                        alignItems: "center",
                      }}
                    >
                      <View style={{ flex: 3 }}>
                        <View
                          style={{
                            flexDirection: "row",
                            flex: 1,
                            width: "100%",
                          }}
                        >
                          {/* left */}
                          <View
                            style={{ width: "31%", justifyContent: "center" }}
                          >
                            <View>
                              <Text
                                style={[
                                  styles.textSize,
                                  { fontSize: switchMerchant ? 10 : 16 },
                                ]}
                              >
                                Reservation Availability{" "}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              width:
                                windowWidth < 905
                                  ? "70%"
                                  : windowWidth < 1170
                                  ? "50%"
                                  : "38%",
                            }}
                          >
                            <View style={{ width: 30 }} />
                            <View
                              style={{
                                width: switchMerchant
                                  ? windowWidth * 0.1
                                  : windowWidth * 0.112,
                                height: 50,
                                justifyContent: "center",
                                flex: 2.3,
                              }}
                            >
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={[
                                  styles.textInputTakeawayCharges,
                                  { backgroundColor: Colors.fieldtBgColor },
                                  { fontSize: switchMerchant ? 10 : 16 },
                                  {},
                                ]}
                                placeholderStyle={{
                                  color: "black",
                                  height: 40,
                                }}
                                itemStyle={{
                                  justifyContent: "flex-start",
                                  marginLeft: 5,
                                }}
                                placeholder="0"
                                onChangeText={(text) => {
                                  setReservationAvailablityHour(text);
                                }}
                                value={reservationAvailablityHour}
                                keyboardType={"number-pad"}
                              />
                            </View>
                            <View
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                                flex: 1,
                              }}
                            >
                              <Text
                                style={[
                                  {
                                    fontSize: switchMerchant ? 10 : 16,
                                    fontFamily: "NunitoSans-Bold",
                                    color: "black",
                                    opacity: 1,
                                    justifyContent: "center",
                                  },
                                ]}
                              >
                                Hour
                              </Text>
                            </View>
                            <View
                              style={{
                                width: switchMerchant
                                  ? windowWidth * 0.1
                                  : windowWidth * 0.112,
                                height: 50,
                                justifyContent: "center",
                                flex: 2.3,
                              }}
                            >
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={[
                                  styles.textInputTakeawayCharges,
                                  { backgroundColor: Colors.fieldtBgColor },
                                  { fontSize: switchMerchant ? 10 : 16 },
                                  {},
                                ]}
                                placeholderStyle={{
                                  color: "black",
                                  height: 40,
                                }}
                                itemStyle={{
                                  justifyContent: "flex-start",
                                  marginLeft: 5,
                                }}
                                placeholder="0"
                                onChangeText={(text) => {
                                  setReservationAvailablityMinute(text);
                                }}
                                value={reservationAvailablityMinute}
                                keyboardType={"number-pad"}
                              />
                            </View>
                            <View
                              style={{
                                justifyContent: "center",
                                flex: 1,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                  color: "black",
                                  opacity: 1,
                                  justifyContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                Minute
                              </Text>
                            </View>
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            flex: 2,
                            marginTop: 30,
                            zIndex: -3,
                          }}
                        >
                          {/* left */}
                          <View
                            style={{
                              justifyContent: "flex-start",
                              flexDirection: "row",
                              width: "31%",
                            }}
                          >
                            <View>
                              <Text
                                style={[
                                  styles.textSize,
                                  {
                                    fontSize: switchMerchant ? 10 : 16,
                                    paddingTop: switchMerchant ? 15 : 10,
                                  },
                                ]}
                              >
                                Deposit{" "}
                              </Text>
                            </View>
                            <View
                              style={{
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                width: 45,
                                paddingLeft: 10,
                              }}
                            >
                              <Switch
                                onChange={(value) => {
                                  setDepositSwitch(!depositSwitch);
                                }}
                                checked={depositSwitch}
                                width={35}
                                height={20}
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              />
                              {/* <Switch
                                value={depositSwitch}
                                onSyncPress={(value) => {
                                  // setState({ newReservationStatus: item }, function  = () => {
                                  //   switchQueueStatus();
                                  // });
                                  // switchQueueStatus(value);
                                  setDepositSwitch(!depositSwitch);
                                }}
                                width={42}
                                circleColorActive={Colors.primaryColor}
                                circleColorInactive={Colors.fieldtTxtColor}
                                backgroundActive="#dddddd"
                              /> */}
                              <Text
                                style={[
                                  {
                                    fontSize: 16,
                                    color: currOutlet.reservationStatus
                                      ? Colors.primaryColor
                                      : Colors.fieldtTxtColor,
                                    textAlign: "center",
                                    // borderWidth: 1,
                                    // paddingLeft: windowWidth * 0.005,
                                  },
                                  switchMerchant
                                    ? {
                                        fontSize: 10,
                                      }
                                    : {},
                                ]}
                              >
                                {depositSwitch ? "ON" : "OFF"}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width:
                                windowWidth < 905
                                  ? "70%"
                                  : windowWidth < 1170
                                  ? "50%"
                                  : "38%",
                              flexDirection: "row",
                            }}
                          >
                            <View
                              style={{
                                justifyContent: "center",
                                width: 30,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                  color: "black",
                                  opacity: 1,
                                  justifyContent: "center",
                                }}
                              >
                                RM
                              </Text>
                            </View>
                            <View
                              style={{
                                height: 50,
                                justifyContent: "center",
                                flex: 4,
                              }}
                            >
                              <TextInput
                                style={[
                                  styles.textInputTakeawayCharges,
                                  { backgroundColor: Colors.fieldtBgColor },
                                  { fontSize: switchMerchant ? 10 : 16 },
                                  {},
                                ]}
                                placeholderStyle={{
                                  color: "black",
                                  height: 40,
                                }}
                                itemStyle={{
                                  justifyContent: "flex-start",
                                  marginLeft: 5,
                                }}
                                placeholder="0"
                                onChangeText={(text) => {
                                  setDeposit(text);
                                }}
                                value={deposit}
                                disabled={!depositSwitch}
                              />
                            </View>
                            <View style={{ flex: 0.72 }} />
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            flex: 2,
                            marginTop: 30,
                            zIndex: -4,
                          }}
                        >
                          {/* left */}
                          <View
                            style={{ justifyContent: "center", width: "31%" }}
                          >
                            <View>
                              <Text
                                style={[
                                  styles.textSize,
                                  { fontSize: switchMerchant ? 10 : 16 },
                                ]}
                              >
                                Price{" "}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              width:
                                windowWidth < 905
                                  ? "70%"
                                  : windowWidth < 1170
                                  ? "50%"
                                  : "38%",
                              flexDirection: "row",
                            }}
                          >
                            <View
                              style={{
                                justifyContent: "center",
                                width: 30,
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                  color: "black",
                                  opacity: 1,
                                  justifyContent: "center",
                                }}
                              >
                                RM
                              </Text>
                            </View>
                            <View
                              style={{
                                flex: 4,
                                justifyContent: "center",
                                height: 50,
                              }}
                            >
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={[
                                  styles.textInputTakeawayCharges,
                                  { backgroundColor: Colors.fieldtBgColor },
                                  { fontSize: switchMerchant ? 10 : 16 },
                                  {},
                                ]}
                                placeholderStyle={{
                                  color: "black",
                                  height: 40,
                                }}
                                itemStyle={{
                                  justifyContent: "flex-start",
                                  marginLeft: 5,
                                }}
                                placeholder="0"
                                onChangeText={(text) => {
                                  setPrice(text);
                                }}
                                value={price}
                                keyboardType={"number-pad"}
                              />
                            </View>
                            <View style={{ flex: 0.72 }} />
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            flex: 2,
                            marginTop: 30,
                            zIndex: -3,
                          }}
                        >
                          {/* left */}
                          <View
                            style={{
                              justifyContent: "center",
                              width: "31%",
                            }}
                          >
                            <Text
                              style={[
                                styles.textSize,
                                { fontSize: switchMerchant ? 10 : 16 },
                              ]}
                            >
                              Terms & Conditions{" "}
                            </Text>
                          </View>
                          <View
                            style={{
                              width:
                                windowWidth < 905
                                  ? "70%"
                                  : windowWidth < 1170
                                  ? "50%"
                                  : "38%",
                              flexDirection: "row",
                            }}
                          >
                            <View style={{ width: 30 }} />
                            <View
                              style={{
                                height: 50,
                                justifyContent: "center",
                                flex: 4,
                              }}
                            >
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={[
                                  styles.textInputTakeawayCharges,
                                  { backgroundColor: Colors.fieldtBgColor },
                                  { fontSize: switchMerchant ? 10 : 16 },
                                  {},
                                ]}
                                placeholderStyle={{
                                  color: "black",
                                  height: 40,
                                }}
                                itemStyle={{
                                  justifyContent: "flex-start",
                                  marginLeft: 5,
                                }}
                                placeholder="no T&C"
                                onChangeText={(text) => {
                                  setTermCondition(text);
                                }}
                                value={termCondition}
                              />
                            </View>
                            <View style={{ flex: 0.72 }} />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ alignItems: "center", zIndex: -5 }}>
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: 120,
                        paddingHorizontal: 10,
                        height: 35,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      disabled={loading}
                      onPress={() => {
                        orderFunc();
                      }}
                    >
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          //marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        {loading ? "LOADING..." : "SAVE"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{ marginTop: 20 }}></View>
                </View>
              </View>
            ) : null}
          </View>
        </ScrollView>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    flexDirection: "row",
    fontFamily: "NunitoSans-Regular",
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  headerLogo1: {
    width: "100%",
    height: "100%",
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  listItem: {
    fontFamily: "NunitoSans-Regular",
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    width: useWindowDimensions.width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 20,
    width: useWindowDimensions.width * (1 - Styles.sideBarWidth),
    // backgroundColor: 'lightgrey',
    backgroundColor: Colors.highlightColor,
  },
  textInputTakeawayCharges: {
    backgroundColor: Colors.fieldtBgColor,
    // width: 200,
    height: 40,
    borderRadius: 5,
    padding: 5,
    marginVertical: 5,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    paddingLeft: 10,
    //marginLeft: 190,
  },

  textInput: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 0,
    flex: 1,
    flexDirection: "row",
  },
  textInputLocation: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 100,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 10,
  },
  textInput8: {
    fontFamily: "NunitoSans-Regular",
    width: 60,
    height: 50,
    flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    paddingHorizontal: 5,
    borderColor: "#E5E5E5",
    borderWidth: 1,
  },
  textInput9: {
    fontFamily: "NunitoSans-Regular",
    width: 110,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "center",
  },
  textInput10: {
    fontFamily: "NunitoSans-Regular",
    width: 200,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "center",
  },
  textInput1: {
    fontFamily: "NunitoSans-Regular",
    width: 250,
    height: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 10,
  },
  textInput2: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginRight: 30,
  },
  textInput3: {
    fontFamily: "NunitoSans-Regular",
    width: "85%",
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    alignSelf: "center",
    paddingHorizontal: 10,
  },
  textInput4: {
    width: "85%",
    height: 70,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
  textInput5: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 50,
  },
  textInput6: {
    fontFamily: "NunitoSans-Regular",
    width: "80 %",
    padding: 16,
    backgroundColor: Colors.fieldtBgColor,
    marginRight: 30,
    borderRadius: 10,
    alignSelf: "center",
  },
  textInput7: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 80,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 7,
    marginRight: 30,
  },
  button: {
    backgroundColor: Colors.primaryColor,
    width: 150,
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 20,
  },
  button1: {
    width: "15%",
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 20,
  },
  button2: {
    backgroundColor: Colors.primaryColor,
    width: "60%",
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    marginLeft: "2%",
  },
  button3: {
    backgroundColor: Colors.primaryColor,
    width: "30%",
    height: 50,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 30,
  },
  textSize: {
    fontSize: 19,
    fontFamily: "NunitoSans-SemiBold",
  },
  viewContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 0,
    width: "100%",
    marginBottom: 15,
  },
  openHourContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginBottom: 15,
    width: "100%",
  },
  addButtonView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 200,
    height: 40,
    alignItems: "center",
  },
  addButtonView1: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: "center",
  },
  addNewView: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 65,
    marginTop: 7,
    width: "83%",
    alignSelf: "flex-end",
  },
  addNewView1: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 10,
    alignItems: "center",
  },
  merchantDisplayView: {
    flexDirection: "row",
    flex: 1,
    marginLeft: "17%",
  },
  shiftView: {
    flexDirection: "row",
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 50,
    width: 200,
    height: 60,
    alignItems: "center",
    marginTop: 30,
    alignSelf: "center",
  },
  shiftText: {
    marginLeft: "15%",
    color: Colors.primaryColor,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
  },
  closeView: {
    flexDirection: "row",
    borderRadius: 5,
    borderColor: Colors.primaryColor,
    borderWidth: 1,
    width: 200,
    height: 40,
    alignItems: "center",
    marginTop: 30,
    alignSelf: "center",
  },
  taxView: {
    flexDirection: "row",
    borderWidth: 2,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: "center",
    marginTop: 20,
    alignSelf: "flex-end",
  },
  sectionView: {
    flexDirection: "row",
    borderRadius: 5,
    padding: 16,
    alignItems: "center",
  },
  receiptView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 200,
    height: 40,
    alignItems: "center",
    alignSelf: "flex-end",
  },
  pinBtn: {
    backgroundColor: Colors.fieldtBgColor,
    width: 70,
    height: 70,
    marginBottom: 16,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  pinNo: {
    fontSize: 20,
    fontWeight: "bold",
  },
  confirmBox: {
    width: "30%",
    height: "30%",
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
});
export default SettingsReservation;
