import React, { Component, useReducer, useState, useEffect, useRef } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Switch,
  Modal,
  KeyboardAvoidingView,
  TextInput,
  ActivityIndicator,
  Platform,
  Picker,
  CheckBox,
  useWindowDimensions
} from 'react-native';
import moment, { invalid } from 'moment';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";

import Colors from '../constant/Colors';
import SideBar from './SideBar';
import Icon from 'react-native-vector-icons/Feather';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Entypo from 'react-native-vector-icons/Entypo';
import Ionicons from 'react-native-vector-icons/Ionicons';
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";

import EvilIcons from 'react-native-vector-icons/EvilIcons';
import { FlatList } from 'react-native-gesture-handler';
import API from '../constant/API';
import ApiClient from '../util/ApiClient';
import * as User from '../util/User';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import CheckBox from 'react-native-check-box';
// import PureChart from 'react-native-pure-chart';
import Styles from '../constant/Styles';
// import DropDownPicker from 'react-native-dropdown-picker';
// import RNPickerSelect from 'react-native-picker-select';
import Feather from 'react-native-vector-icons/Feather';
// import CalendarPicker from 'react-native-calendar-picker';
// import { isTablet } from 'react-native-device-detection';
import { ReactComponent as Download } from '../assets/svg/Download.svg';
// import RNFetchBlob from 'rn-fetch-blob';
import { ReactComponent as Upload } from '../assets/svg/Upload.svg';
import {
  listenToUserChangesMerchant,
  listenToMerchantIdChangesMerchant,
  listenToCurrOutletIdChangesWaiter,
  listenToAllOutletsChangesMerchant,
  listenToCommonChangesMerchant,
  listenToSelectedOutletItemChanges,
  convertArrayToCSV,
  listenToSelectedOutletTableIdChanges,
  requestNotificationsPermission,
  sortReportDataList,
  generateEmailReport,
} from '../util/common';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { OutletStore } from '../store/outletStore';

import { CSVLink } from "react-csv";

// Include the react-fusioncharts component
import FusionCharts from "react-fusioncharts";

import {
  CHART_DATA,
  CHART_DATA2,
  CHART_FIELD_COMPARE_DROPDOWN_LIST,
  CHART_FIELD_NAME_DROPDOWN_LIST,
  CHART_FIELD_TYPE,
  CHART_PERIOD,
  CHART_TYPE,
  CHART_Y_AXIS_DROPDOWN_LIST,
  FS_LIBRARY_PATH,
} from '../constant/chart';
import {
  EMAIL_REPORT_TYPE,
  REPORT_SORT_FIELD_TYPE,
  TABLE_PAGE_SIZE_DROPDOWN_LIST,
  ORDER_TYPE
} from '../constant/common';
import {
  filterChartItems,
  getDataForChartDashboardTodaySales,
  getDataForSalesLineChart,
} from '../util/chart';
// import messaging from '@react-native-firebase/messaging';
import { ReactComponent as GCalendar } from '../assets/svg/GCalendar.svg';
// import { useKeyboard } from '../hooks';
import { CommonStore } from '../store/commonStore';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import AsyncImage from '../components/asyncImage';

import XLSX from 'xlsx';
import 'react-native-get-random-values';
const { nanoid } = require('nanoid');
// const RNFS = require('react-native-fs');
// import { v4 as uuidv4 } from 'uuid';

const ReportSalesOvertime = (props) => {
  const { navigation } = props;

  setInterval(() => {
    const elements = document.querySelectorAll('[fill="#b1b2b7"]');

    if (elements && elements.length > 0) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = 'none';
      }
    }        
  }, 1000);

  const {
    width: windowWidth,
    height: windowHeight
  } = useWindowDimensions();

  // const [keyboardHeight] = useKeyboard();
  const [list, setList] = useState([]);
  const [page, setPage] = useState(0);
  const [oriList, setOriList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [detailsPageCount, setDetailsPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDetailsPage, setCurrentDetailsPage] = useState(1);
  const [pageReturn, setPageReturn] = useState(1);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isChecked1, setIsChecked1] = useState(false);
  const [search, setSearch] = useState('');
  const [search1, setSearch1] = useState('');
  // const [choice, setChoice] = useState('Daily');
  const [pick, setPick] = useState(null);
  const [pick1, setPick1] = useState(null);
  const [sort, setSort] = useState(null);
  const [day, setDay] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filter1, setFilter1] = useState(false);
  const [filter2, setFilter2] = useState(false);
  const [daily, setDaily] = useState(true);
  const [custom, setCustom] = useState(false);
  const [choice, setChoice] = useState(null);
  const [onClick1, setOnClick1] = useState(1);
  const [onClick2, setOnClick2] = useState(0);
  const [onClick3, setOnClick3] = useState(0);
  const [supplier, setSupplier] = useState('Field name');
  const [product, setProduct] = useState('is');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(
    Date().toLocaleString(),
  );
  const [extendOption, setExtendOption] = useState([]);
  const [search2, setSearch2] = useState('');
  const [field, setField] = useState(null);
  const [isOrNot, setIsOrNot] = useState(true);
  const [queryName, setQueryName] = useState(null);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
  const [showDateTimePickerFilter, setShowDateTimePickerFilter] =
    useState(false);
  const [pickerMode, setPickerMode] = useState('datetime');

  const [rev_date, setRev_date] = useState(moment().subtract(6, 'days').startOf('day'));
  const [rev_date1, setRev_date1] = useState(moment().endOf(Date.now()).endOf('day'));

  const [expandDetailsDict, setExpandDetailsDict] = useState({});
  const crmUsers = OutletStore.useState((s) => s.crmUsers);

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);

  const [dailySalesDetailsList, setDailySalesDetailsList] = useState([]);
  const allOutletsUserOrdersDone = OutletStore.useState(
    (s) => s.allOutletsUserOrdersDone,
  );
  const userOrders = OutletStore.useState((s) => s.userOrders);

  const [todaySalesChart, setTodaySalesChart] = useState({});
  const [todaySalesChartPeriod, setTodaySalesChartPeriod] = useState(
    CHART_PERIOD.TODAY,
  );
  const [salesLineChart, setSalesLineChart] = useState({});
  const [salesLineChartPeriod, setSalesLineChartPeriod] = useState(
    CHART_PERIOD.THIS_MONTH,
  );

  const [totalSales, setTotalSales] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalSold, setTotalSold] = useState(0);

  const [pushPagingToTop, setPushPagingToTop] = useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [transactionTypeSalesDetails, setTransactionTypeSalesDetails] =
    useState([]);

  const [selectedItemSummary, setSelectedItemSummary] = useState({});

  const [exportModalVisibility, setExportModalVisibility] = useState(false);

  const [transactionTypeSales, setTransactionTypeSales] = useState([]);

  const [expandSelection, setExpandSelection] = useState(
    props.expandSelection === undefined ? false : props.expandSelection,
  );
  const [filterTapped, setFilterTapped] = useState(
    props.threeDotsTapped === undefined ? 0 : props.threeDotsTapped,
  );

  const [selectedChartDropdownValue, setSelectedChartDropdownValue] = useState(
    CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value,
  );
  const [selectedChartFilterQueries, setSelectedChartFilterQueries] = useState([
    {
      fieldNameKey:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0]
          .value,
      fieldNameType:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0]
          .fieldType,
      fieldCompare:
        CHART_FIELD_COMPARE_DROPDOWN_LIST[
          CHART_TYPE.DASHBOARD_LINE_CHART_SALES
        ][0].value,
      fieldDataValue: null,
    },
  ]);
  const [
    selectedChartDropdownValueLineChart,
    setSelectedChartDropdownValueLineChart,
  ] = useState(
    CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value,
  );
  const [outletSalesData, setOutletSalesData] = useState([
    {
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
      color: (opacity = 1) => Colors.tabMint,
    },
  ]);

  const [appliedChartFilterQueries, setAppliedChartFilterQueries] = useState(
    [],
  );

  const [currReportSummarySort, setCurrReportSummarySort] = useState('');
  const [currReportDetailsSort, setCurrReportDetailsSort] = useState('');

  const merchantId = UserStore.useState((s) => s.merchantId);

  const currPageStack = CommonStore.useState((s) => s.currPageStack);
  const pageStack = CommonStore.useState((s) => s.pageStack);

  const isLoading = CommonStore.useState((s) => s.isLoading);
  const [isCsv, setIsCsv] = useState(false);
  const [isExcel, setIsExcel] = useState(false);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView,
  );

  const [detailDate, setDetailDate] = useState('');

  ////List////
  useEffect(() => {
    var dailySalesDetailsListTemp = [];

    if (currOutletId && currOutletId.length > 0) {
      // const isValid = filterChartItems(allOutletsUserOrdersDone[i], appliedChartFilterQueries);
      const allOutletsUserOrdersDoneFiltered = allOutletsUserOrdersDone.filter(
        (item) => filterChartItems(item, appliedChartFilterQueries),
      );

      for (
        var i = 0;
        i <= moment(rev_date1).diff(moment(rev_date), 'day');
        i++
      ) {
        var currDateTime = moment(rev_date).add(i, 'day');

        var record = {
          summaryId: nanoid(),
          dateTime: moment(currDateTime).format('D MMM YYYY'),
          dateTimeRaw: currDateTime,
          totalSales: 0,
          totalTransactions: 0,
          totalDiscount: 0,
          discount: 0,
          tax: 0,
          serviceCharge: 0,
          gp: 0,
          totalSalesReturn: 0,
          netSales: 0,
          averageNetSales: 0,
          detailsList: [],
        };

        for (var j = 0; j < allOutletsUserOrdersDoneFiltered.length; j++) {
          // const isValid = filterItems(allOutletsUserOrdersDone[i], appliedChartFilterQueries);

          if (
            // isValid &&
            allOutletsUserOrdersDoneFiltered[j].outletId === currOutletId &&
            moment(currDateTime).isSame(
              allOutletsUserOrdersDoneFiltered[j].completedDate,
              'day',
            )
          ) {
            record.totalSales += allOutletsUserOrdersDoneFiltered[j].finalPrice;
            record.totalTransactions += 1;
            record.totalDiscount +=
              allOutletsUserOrdersDoneFiltered[j].discount;
            record.discount += !isNaN(
              (allOutletsUserOrdersDoneFiltered[j].discount /
                allOutletsUserOrdersDoneFiltered[j].finalPrice) *
              100,
            )
              ? (allOutletsUserOrdersDoneFiltered[j].discount /
                allOutletsUserOrdersDoneFiltered[j].finalPrice) *
              100
              : 0;
            record.tax += !isNaN(
              (allOutletsUserOrdersDoneFiltered[j].tax /
                allOutletsUserOrdersDoneFiltered[j].finalPrice) *
              100,
            )
              ? (allOutletsUserOrdersDoneFiltered[j].tax /
                allOutletsUserOrdersDoneFiltered[j].finalPrice) *
              100
              : 0;
            record.serviceCharge += allOutletsUserOrdersDoneFiltered[j].sc || 0;

            if (allOutletsUserOrdersDoneFiltered[j].cartItemsCancelled) {
              for (
                var k = 0;
                k <
                allOutletsUserOrdersDoneFiltered[j].cartItemsCancelled.length;
                k++
              ) {
                record.totalSalesReturn +=
                  allOutletsUserOrdersDoneFiltered[j].cartItemsCancelled[
                    k
                  ].price;
              }
            }

            // record.totalSalesReturn += allOutletsUserOrdersDoneFiltered[j].totalPrice;
            record.netSales += allOutletsUserOrdersDoneFiltered[j].totalPrice;

            // record.detailsList.push(allOutletsUserOrdersDoneFiltered[j]);
            record.detailsList.push({
              ...allOutletsUserOrdersDoneFiltered[j],
              discountPercentage: parseFloat(
                isFinite(
                  allOutletsUserOrdersDoneFiltered[j].finalPrice /
                  allOutletsUserOrdersDoneFiltered[j].discount,
                )
                  ? (allOutletsUserOrdersDoneFiltered[j].finalPrice /
                    allOutletsUserOrdersDoneFiltered[j].discount) *
                  100
                  : 0,
              ),
            });
          }
        }

        if (record.totalTransactions > 0) {
          record.netSales = Math.ceil(record.netSales * 20 - 0.05) / 20;
          record.averageNetSales = record.netSales / record.totalTransactions;
        }

        dailySalesDetailsListTemp.push(record);
      }
    }

    setDailySalesDetailsList(dailySalesDetailsListTemp);

    //setCurrentPage(1);
    setPageCount(Math.ceil(dailySalesDetailsListTemp.length / perPage));

    setShowDetails(false);
  }, [allOutletsUserOrdersDone, currOutletId, rev_date, rev_date1, perPage]);

  useEffect(() => {
    if (showDetails && selectedItemSummary.detailsList) {
      setTransactionTypeSalesDetails(selectedItemSummary.detailsList);

      setPageReturn(currentPage);
      console.log('currentPage value is');
      console.log(currentPage);
      setCurrentDetailsPage(1);
      setPageCount(Math.ceil(selectedItemSummary.detailsList.length / perPage));
    }
  }, [showDetails, selectedItemSummary, perPage]);

  ////Line Chart////
  useEffect(() => {
    // setTodaySalesChart(getDataForChartDashboardTodaySales(allOutlets, allOutletsUserOrdersDone));

    const allOutletsUserOrdersDoneFiltered = allOutletsUserOrdersDone.filter(
      (item) => filterChartItems(item, appliedChartFilterQueries),
    );

    // const result = getDataForSalesLineChart(allOutlets, allOutletsUserOrdersDoneFiltered, salesLineChartPeriod, rev_date, rev_date1, selectedChartDropdownValue)
    const result = getDataForSalesLineChart(
      allOutlets,
      allOutletsUserOrdersDoneFiltered,
      salesLineChartPeriod,
      selectedChartDropdownValueLineChart,
      rev_date,
      rev_date1,
    );

    setSalesLineChart(result.chartData);

    // var totalSalesTemp = 0;
    // var totalTransactionsTemp = allOutletsUserOrdersDone.length;
    // var totalSoldTemp = 0;

    // for (var i = 0; i < allOutletsUserOrdersDone.length; i++) {
    //     for (var j = 0; j < allOutletsUserOrdersDone[i].cartItems.length; j++) {
    //         const cartItem = allOutletsUserOrdersDone[i].cartItems[j];

    //         totalSoldTemp += cartItem.quantity;
    //     }

    //     totalSalesTemp += allOutletsUserOrdersDone[i].finalPrice;
    // }

    // setTotalSales(totalSalesTemp);
    // setTotalTransactions(totalTransactionsTemp);
    // setTotalSold(totalSoldTemp);
  }, [
    allOutlets,
    allOutletsUserOrdersDone,
    salesLineChartPeriod,
    selectedChartDropdownValue,
    appliedChartFilterQueries,
    rev_date,
    rev_date1,
  ]);

  const setState = () => { };

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  useEffect(() => {
    var title = 'Overview Sales Report';

    if (
      pageStack.length > 1 &&
      pageStack[pageStack.length - 2] === 'Dashboard'
    ) {
      title = 'Today Sales Report';

      setSalesLineChartPeriod(CHART_PERIOD.TODAY);
      setRev_date(moment().startOf('day'));
      setRev_date1(moment().endOf('day'));
    } else {
      title = 'Overview Sales Report';

      setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH);
      setRev_date(moment(Date.now()).subtract(1, 'month').startOf('day'));
      setRev_date1(moment(Date.now()).endOf('day'));
    }

    navigation.setOptions({
      headerLeft: () => (
        <View
          style={{
            width: windowWidth * 0.17,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Image
            style={{
              width: 124,
              height: 26,
            }}
            resizeMode="contain"
            source={require('../assets/image/logo.png')}
          />
        </View>
      ),
      headerTitle: () => (
        <View
          style={[{
            justifyContent: 'center',
            alignItems: 'center',
            // bottom: -2,
            bottom: switchMerchant ? '2%' : 0,
          }, windowWidth >= 768 && switchMerchant ? { right: windowWidth * 0.1 } : {}, windowWidth <= 768 ? { right: windowWidth * 0.12 } : {}]}>
          <Text
            style={{
              fontSize: switchMerchant ? 20 : 24,
              // lineHeight: 25,
              textAlign: 'center',
              fontFamily: 'NunitoSans-Bold',
              color: Colors.whiteColor,
              opacity: 1,
            }}>
            Overview Sales Report
          </Text>
        </View>
      ),
      headerRight: () => (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          {outletSelectDropdownView()}
          <View
            style={{
              backgroundColor: 'white',
              width: 0.5,
              height: windowHeight * 0.025,
              opacity: 0.8,
              marginHorizontal: 15,
              bottom: -1,
            }}></View>
          <TouchableOpacity
            onPress={() => navigation.navigate('Setting')}
            style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text
              style={{
                fontFamily: 'NunitoSans-SemiBold',
                fontSize: 16,
                color: Colors.secondaryColor,
                marginRight: 15,
              }}>
              {userName}
            </Text>
            <View
              style={{
                marginRight: 30,
                width: windowHeight * 0.05,
                height: windowHeight * 0.05,
                borderRadius: windowHeight * 0.05 * 0.5,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'white',
              }}>
              <Image
                style={{
                  width: windowHeight * 0.035,
                  height: windowHeight * 0.035,
                  alignSelf: 'center',
                }}
                source={require('../assets/image/profile-pic.jpg')}
              />
            </View>
          </TouchableOpacity>
        </View>
      ),
    });
  }, [pageStack]);

  // componentDidMount = () => {
  //     const one = new Date();
  //     const two = new Date(one.getTime() - (7 * 24 * 60 * 60 * 1000))
  //     getDetail(two, one);
  // }

  /* const email1 = param => {
        var body = {
            data: param,
            email: "kokokk@gmail.com"
        }
        ApiClient.POST(API.emailReportPdf, body, false).then((result) => {
            console.log(result)
            if (result !== null) {

                Alert.alert(
                    'Success',
                    'The email had sent',
                    [
                        { text: "OK", onPress: () => { } }
                    ],
                    { cancelable: false },
                );
            }
        });
    }

    const download = param => {
        var body = {
            orderId: param,
        }
        ApiClient.POST(API.generateReportPDF, body, false).then((result) => {
            console.log(result)
            if (result !== null) {

                Alert.alert(
                    'Success',
                    'The data had downloaded',
                    [
                        { text: "OK", onPress: () => { } }
                    ],
                    { cancelable: false },
                );
            }
        });
    } */

  const eventsChart = {
    dataPlotClick: (e, item) => {
      console.log('test data plot');
    },
  };

  const add = async () => {
    if (page + 1 < pageCount) {
      await setState({ page: page + 1, currentPage: currentPage + 1 });
      console.log(page);
      var e = page;
      next(e);
    }
  };

  const next = (e) => {
    const offset = e * perPage;
    setState({ offset: offset });
    loadMoreData();
  };

  const less = async () => {
    if (page > 0) {
      await setState({ page: page - 1, currentPage: currentPage - 1 });
      console.log(page);
      var y = page;
      pre(y);
    }
  };

  const pre = (y) => {
    const offset = y * perPage;
    setState({ offset: offset });
    loadMoreData();
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
  };
  const nextDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage + 1 > pageCount
        ? currentDetailsPage
        : currentDetailsPage + 1,
    );
  };

  const prevDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1,
    );
  };

  const loadMoreData = () => {
    const data = oriList;
    const slice = data.slice(offset, offset + perPage);
    setState({ list: slice, pageCount: Math.ceil(data.length / perPage) });
  };

  const getStockFilter = (param, param1, param2) => {
    ApiClient.GET(
      API.salesByOvertimeFilter +
      1 +
      '&queryField=' +
      param +
      '&isOrNot=' +
      param1 +
      '&queryName=' +
      param2,
    ).then((result) => {
      var data = result;
      var slice = data.slice(offset, offset + perPage);
      setState({
        list: slice,
        oriList: data,
        pageCount: Math.ceil(data.length / perPage),
      });
      if (result.length > 0) {
        result.forEach((element) => {
          onaddoption(element.date, parseInt(element.finalPrice));
        });

        const extendOption = result.map((element) => {
          return {
            x: element.date,
            y: parseInt(element.finalPrice),
          };
        });
        setState({ extendOption });
      }
    });
  };

  const onItemSummaryClicked = (item) => {
    // setTransactionTypeSalesDetails(item.detailsList);
    setSelectedItemSummary(item);
    setShowDetails(true);
    setDetailDate(moment(item.dateTime).format('DD MMM'));

    // setCurrentPage(1);
    // setPageCount(Math.ceil(item.detailsList.length / perPage));

    console.log('item.detailsList');
    console.log(item.detailsList);
  };

  const getDetail = (param1, param2) => {
    const start = new Date(param2);
    const end = new Date(param1);
    var dateDiff = Math.round(Math.abs((start - end) / (24 * 60 * 60 * 1000)));
    ApiClient.GET(
      API.salesByTransactionCustom +
      1 +
      '&startDate=' +
      moment(param1).format('YYYY-MM-DD') +
      '&endDate=' +
      moment(param2).format('YYYY-MM-DD'),
    ).then((result) => {
      var data = result;
      var slice = data.slice(offset, offset + perPage);
      setState({
        list: slice,
        oriList: data,
        pageCount: Math.ceil(data.length / perPage),
      });
      if (result.length > 0) {
        result.forEach((element) => {
          onaddoption(element.date, parseInt(element.finalPrice));
        });

        const extendOption = result.map((element) => {
          return {
            x: element.date,
            y: parseInt(element.finalPrice),
          };
        });

        if (extendOption.length < dateDiff) {
          var lastDate = new Date(extendOption[extendOption.length - 1].x);
          var n = dateDiff - extendOption.length;
          var i;
          for (i = 0; i < n; i++) {
            const newDate = new Date(
              lastDate.getFullYear(),
              lastDate.getMonth(),
              lastDate.getDate() + (1 + i),
            );
            const newDateStr =
              newDate.getFullYear().toString() +
              '-' +
              newDate.getMonth().toString() +
              '-' +
              newDate.getDate().toString();
            extendOption.push({
              x: newDateStr,
              y: 0,
            });
          }
        }
        setState({ extendOption });
      }
    });
  };

  const onaddoption = (param1, param2) => {
    const extendOption = extendOption;
    extendOption.push({
      x: param1,
      y: param2,
    });
    setState({ extendOption });
  };

  const onClick = () => {
    if (onClick1 == 1) {
      setState({ onClick1: 0 });
    } else {
      setState({ onClick1: 1, onClick2: 0, onClick3: 0, choice: 1 });
    }
  };
  const onClick1Func = () => {
    if (onClick2 == 1) {
      setState({ onClick2: 0 });
    } else {
      setState({ onClick2: 1, onClick1: 0, onClick3: 0, choice: 2 });
    }
  };
  const onClick2Func = () => {
    if (onClick3 == 1) {
      setState({ onClick3: 0 });
    } else {
      setState({ onClick3: 1, onClick2: 0, onClick1: 0, choice: 3 });
    }
  };

  const onDateChange = (date, type) => {
    if (type === 'END_DATE') {
      setState({
        selectedEndDate: date,
      });
    } else {
      setState({
        selectedStartDate: date,
        selectedEndDate: null,
      });
    }
  };

  const changeClick = () => {
    if (day == true) {
      setState({ day: false });
    } else setState({ day: true });
  };

  const changeClick1 = () => {
    if (filter == true) {
      setState({ filter: false });
    } else setState({ filter: true });
  };

  const changeClick2 = () => {
    if (filter1 == true) {
      setState({ filter1: false });
    } else setState({ filter1: true });
  };

  const changeClick3 = () => {
    if (filter2 == true) {
      setState({ filter2: false });
    } else setState({ filter2: true });
  };

  const renderItem = ({ item, index }) => {
    var finalPriceSpacing =
      Math.max(8 - item.averageNetSales.toFixed(0).length, 0) + 1;

    return (
      <TouchableOpacity
        onPress={() => {
          onItemSummaryClicked(item);
        }}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          paddingHorizontal: 3,
          paddingLeft: 1,
          borderColor: '#BDBDBD',
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          // width: '100%',
        }}>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={{
              width: '5%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {index + 1}
          </Text>
          <Text
            style={{
              width: '12%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.dateTime}
          </Text>
          <Text
            style={{
              width: '9%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.totalSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          <Text
            style={{
              width: '8%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.totalTransactions}
          </Text>
          <Text
            style={{
              width: '8%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.totalDiscount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          <Text
            style={{
              width: '8%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.discount.toFixed(2)}
          </Text>
          <Text
            style={{
              width: '8%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.tax.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          <Text
            style={{
              width: '10%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.serviceCharge.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          {/* <Text style={{ flex: 1, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left' }}>{item.gp}</Text> */}
          <Text
            style={{
              width: '10%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.totalSalesReturn.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          <Text
            style={{
              width: '11%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.netSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          <Text
            style={{
              width: '11%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'right',
              paddingLeft: 10,
              paddingRight: 20,
            }}>
            {item.averageNetSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const onClickItemDetails = (item) => {
    setExpandDetailsDict({
      ...expandDetailsDict,
      [item.uniqueId]: expandDetailsDict[item.uniqueId] ? false : true,
    });
  };

  const renderItemDetails = ({ item, index }) => {
    ///////////////////////////

    console.log('order id');
    console.log(item.orderId);

    // calculate longest

    var longestStr = 5;

    /* for (var i = 0; i < item.cartItems.length; i++) {
            const cartItemPriceWIthoutAddOn =
                item.cartItems[i].price -
                item.cartItems[i].addOns.reduce(
                    (accum, addOn) => accum + addOn.prices[0],
                    0,
                );

            if (cartItemPriceWIthoutAddOn.toFixed(0).length > longestStr) {
                longestStr = cartItemPriceWIthoutAddOn.toFixed(0).length;
            }

            for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
                if (
                    item.cartItems[i].addOns[j].prices[0].toFixed(0).length > longestStr
                ) {
                    longestStr = item.cartItems[i].addOns[j].prices[0].toFixed(0).length;
                }
            }
        }

        if (item.totalPrice.toFixed(0).length > longestStr) {
            longestStr = item.totalPrice.toFixed(0).length;
        }

        if (item.discount.toFixed(0).length > longestStr) {
            longestStr = item.discount.toFixed(0).length;
        }

        if (item.tax.toFixed(0).length > longestStr) {
            longestStr = item.tax.toFixed(0).length;
        }

        if (item.finalPrice.toFixed(0).length > longestStr) {
            longestStr = item.finalPrice.toFixed(0).length;
        }

        console.log(longestStr); */

    ///////////////////////////

    // calculate spacing

    var cartItemPriceWIthoutAddOnSpacingList = [];
    var addOnsSpacingList = [];

    for (var i = 0; i < item.cartItems.length; i++) {
      const cartItemPriceWIthoutAddOn =
        item.cartItems[i].price -
        item.cartItems[i].addOns.reduce(
          (accum, addOn) => accum + addOn.prices[0],
          0,
        );

      cartItemPriceWIthoutAddOnSpacingList.push(
        Math.max(longestStr - cartItemPriceWIthoutAddOn.toFixed(0).length, 0) +
        1,
      );

      for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
        addOnsSpacingList.push(
          Math.max(
            longestStr -
            item.cartItems[i].addOns[j].prices[0].toFixed(0).length,
            0,
          ) + 1,
        );
      }
    }

    var totalPriceSpacing =
      Math.max(longestStr - item.totalPrice.toFixed(0).length, 0) + 1;
    var discountSpacing =
      Math.max(longestStr - item.discount.toFixed(0).length, 0) + 1;
    var taxSpacing = Math.max(longestStr - item.tax.toFixed(0).length, 0) + 1;
    var finalPriceSpacing =
      Math.max(longestStr - item.finalPrice.toFixed(0).length, 0) + 1;

    ///////////////////////////
    return (
      <TouchableOpacity
        onPress={() => onClickItemDetails(item)}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          //paddingHorizontal: 3,
          //paddingLeft: 1,
          borderColor: '#BDBDBD',
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}>
        <View style={{ flexDirection: 'row' }}>
          <Text
            style={{
              width: '6%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {index + 1}
          </Text>
          <Text
            style={{
              width: '13%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {moment(item.completedDate).format('H:MM A')}
          </Text>
          {/* <Text style={{ flex: 2.2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left' }}>{item.paymentDetails === null? item.paymentDetails.channel : '-'}</Text> */}
          {/* <Text style={{ flex: 2.2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left' }}>{item.paymentMethod}</Text> */}
          <Text
            style={{
              width: '10%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.totalPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          <Text
            style={{
              width: '9%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.discount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          <Text
            style={{
              width: '9%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.discountPercentage.toFixed(2)}
          </Text>
          <Text
            style={{
              width: '9%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.tax.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          {/* <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left' }}>{item.tax.toFixed(2)}</Text> */}
          <Text
            style={{
              width: '14%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {(item.sc || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          <Text
            style={{
              width: '16%',
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'left',
              paddingLeft: 10,
            }}>
            {item.totalPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
          </Text>
          <View style={{ width: '14%', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 10, paddingRight: switchMerchant ? '2.1%' : '1.85%' }}>
            <Text style={{}} />
            <Text style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', }}>
              {/* <Text style={{
                            opacity: 0,
                            ...Platform.OS === 'android' && {
                                color: 'transparent',
                            },
                        }}>
                            {'0'.repeat((finalPriceSpacing * 0.6) + (item.finalPrice.toFixed(0).length === 1 ? 1 : 0))}
                        </Text> */}
              {(Math.ceil(item.finalPrice * 20 - 0.05) / 20).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
            </Text>
          </View>
          {/* <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left' }}>{item.averageNetSales.toFixed(2)}</Text> */}
        </View>

        {expandDetailsDict[item.uniqueId] == true ? (
          <View
            style={{
              minheight: windowHeight * 0.35,
              marginTop: 30,
              paddingBottom: 20,
            }}>
            {item.cartItems.map((cartItem, index) => {
              const cartItemPriceWIthoutAddOn =
                cartItem.price -
                cartItem.addOns.reduce(
                  (accum, addOn) => accum + addOn.prices[0],
                  0,
                );

              return (
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <View
                    style={{
                      width: '100%',
                      alignItems: 'flex-start',
                      flexDirection: 'row',
                      marginBottom: Platform.OS == 'ios' ? 10 : 10,
                      minHeight: 80,
                      //backgroundColor: 'yellow',
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '100%',
                        //backgroundColor: 'blue',
                      }}>
                      {index == 0 ? (
                        <View
                          style={{
                            marginHorizontal: 1,
                            width: Platform.OS == 'ios' ? '8%' : '8%',
                            //justifyContent: 'center',
                            alignItems: 'center',
                            //backgroundColor: 'blue',
                          }}>
                          <TouchableOpacity
                            style={{
                              alignItems: 'center',
                              marginTop: 0,
                            }}
                            onPress={() => {
                              var crmUser = null;

                              if (item.crmUserId !== undefined) {
                                for (
                                  var i = 0;
                                  i < crmUsers.length;
                                  i++
                                ) {
                                  if (
                                    item.crmUserId ===
                                    crmUsers[i].uniqueId
                                  ) {
                                    crmUser = crmUsers[i];
                                    break;
                                  }
                                }
                              }

                              if (!crmUser) {
                                for (
                                  var i = 0;
                                  i < crmUsers.length;
                                  i++
                                ) {
                                  if (
                                    item.userId ===
                                    crmUsers[i].firebaseUid
                                  ) {
                                    crmUser = crmUsers[i];
                                    break;
                                  }
                                }
                              }

                              if (crmUser) {
                                CommonStore.update(
                                  (s) => {
                                    s.selectedCustomerEdit = crmUser;
                                    // s.selectedCustomerEdit = userReservations[item.userId] && crmUsers[item.userId] ? crmUsers[item.userId] : null ;

                                    s.routeParams = {
                                      pageFrom: 'Reservation',
                                    };
                                  },
                                  () => {
                                    navigation.navigate('NewCustomer');
                                  },
                                );
                              }
                            }}>
                            <Image
                              style={{
                                width: switchMerchant
                                  ? 30
                                  : 60,
                                height: switchMerchant
                                  ? 30
                                  : 60,
                              }}
                              resizeMode="contain"
                              source={require('../assets/image/default-profile.png')}
                            />

                            <View
                              style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}>
                              <Text
                                style={[
                                  {
                                    fontFamily: 'NunitoSans-Bold',
                                    marginTop: 0,
                                    fontSize: 13,
                                    textAlign: 'center',
                                  },
                                  switchMerchant
                                    ? {
                                      fontFamily: 'NunitoSans-Bold',
                                      marginTop: 0,
                                      fontSize: 10,
                                      textAlign: 'center',
                                    }
                                    : {},
                                ]}
                                numberOfLines={1}>
                                {item.userName ? item.userName : 'Guest'}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      ) : (
                        <View
                          style={{
                            marginHorizontal: 1,
                            width: Platform.OS == 'ios' ? '8%' : '8%',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        />
                      )}

                      <View
                        style={{
                          // flex: 0.3,
                          width: '5%',
                          //justifyContent: 'center',
                          alignItems: 'center',
                          //backgroundColor: 'red',
                          //paddingLeft: '1.2%',
                        }}>
                        <Text
                          style={[
                            {
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 13,
                            },
                            switchMerchant
                              ? {
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          {index + 1}.
                        </Text>
                      </View>

                      <View
                        style={{
                          //flex: 0.5,
                          width: '10%',
                          //backgroundColor: 'green',
                          alignItems: 'center',
                        }}>
                        {item.image ?
                          <AsyncImage
                            source={{ uri: cartItem.image }}
                            // item={cartItem}
                            style={{
                              width: switchMerchant
                                ? 30
                                : 60,
                              height: switchMerchant
                                ? 30
                                : 60,
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              borderRadius: 5,
                            }}
                          />
                          :
                          <Ionicons name="fast-food-outline" size={switchMerchant ? 25 : 35} />}
                      </View>
                      <View style={{ width: '75%' }}>
                        <View
                          style={{
                            marginLeft: Platform.OS == 'ios' ? 14 : 14,
                            marginBottom: 10,
                            //backgroundColor: 'blue',
                            width: '100%',
                            flexDirection: 'row',
                          }}>
                          <View style={{ width: '69%' }}>
                            <Text
                              style={[
                                {
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 13,
                                },
                                switchMerchant
                                  ? {
                                    fontFamily: 'NunitoSans-Bold',
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}>
                              {cartItem.name}
                            </Text>
                          </View>

                          <View
                            style={{
                              width: '12.95%',
                            }}>
                            <View
                              style={{
                                alignItems: 'center',
                                //backgroundColor: 'yellow',
                              }}>
                              <Text
                                style={[
                                  {
                                    fontFamily: 'NunitoSans-Bold',
                                    fontSize: 13,
                                  },
                                  // Platform.OS === 'android'
                                  //   ? {
                                  //       width: '200%',
                                  //     }
                                  //   : {},
                                  switchMerchant
                                    ? {
                                      fontFamily: 'NunitoSans-Bold',
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}>
                                x{cartItem.quantity}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              width: '18.75%',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : { fontSize: 13 }
                              }>
                              RM
                            </Text>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                  }
                                  : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                              }>
                              {cartItemPriceWIthoutAddOn
                                .toFixed(2)
                                .replace(
                                  /(\d)(?=(\d{3})+(?!\d))/g,
                                  '$1,',
                                )}
                            </Text>
                          </View>
                        </View>

                        {cartItem.remarks &&
                          cartItem.remarks.length > 0 ? (
                          <View
                            style={{
                              alignItems: 'center',
                              flexDirection: 'row',
                              marginLeft: Platform.OS == 'ios' ? 14 : 14,
                            }}>
                            <View style={{ justifyContent: 'center' }}>
                              <Text
                                style={[
                                  {
                                    fontFamily: 'NunitoSans-SemiBold',
                                    fontSize: 13,
                                  },
                                  switchMerchant
                                    ? {
                                      fontFamily: 'NunitoSans-SemiBold',
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}>
                                {cartItem.remarks}
                              </Text>
                            </View>
                          </View>
                        ) : (
                          <></>
                        )}

                        {cartItem.addOns.map((addOnChoice, i) => {
                          return (
                            <View
                              style={{
                                flexDirection: 'row',
                                // marginLeft: -5,
                                width: '100%',
                              }}>
                              <View
                                style={{
                                  width: '69%',
                                  flexDirection: 'row',
                                  marginLeft:
                                    Platform.OS == 'ios' ? 14 : 14,
                                }}>
                                <Text
                                  style={[
                                    {
                                      fontFamily: 'NunitoSans-Bold',
                                      fontSize: 13,
                                      color: Colors.descriptionColor,
                                      width: '25%',
                                      // marginLeft: 5,
                                    },
                                    switchMerchant
                                      ? {
                                        fontFamily: 'NunitoSans-Bold',
                                        fontSize: 10,
                                        color: Colors.descriptionColor,
                                        width: '25%',
                                      }
                                      : {},
                                  ]}>
                                  {`${addOnChoice.name}:`}
                                </Text>
                                <Text
                                  style={[
                                    {
                                      fontFamily: 'NunitoSans-Bold',
                                      fontSize: 13,
                                      color: Colors.descriptionColor,
                                      width: '75%',
                                      // marginLeft: 5,
                                    },
                                    switchMerchant
                                      ? {
                                        fontFamily: 'NunitoSans-Bold',
                                        fontSize: 10,
                                        color: Colors.descriptionColor,
                                        width: '75%',
                                      }
                                      : {},
                                  ]}>
                                  {`${addOnChoice.choiceNames[0]}`}
                                </Text>
                              </View>

                              <View
                                style={[
                                  {
                                    width: '12.95%',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    //backgroundColor: 'blue',
                                  },
                                ]}>
                                <Text
                                  style={[
                                    {
                                      fontFamily: 'NunitoSans-Bold',
                                      fontSize: 13,
                                      color: Colors.descriptionColor,
                                      width: '28%',
                                      // right: 38,
                                      //backgroundColor: 'green',
                                      textAlign: 'center',
                                    },
                                    switchMerchant
                                      ? {
                                        fontFamily: 'NunitoSans-Bold',
                                        fontSize: 10,
                                        color: Colors.descriptionColor,
                                        width: '28%',
                                        textAlign: 'center',
                                      }
                                      : {},
                                  ]}>
                                  {`${addOnChoice.quantities
                                    ? `x${addOnChoice.quantities[0]}`
                                    : ''
                                    }`}
                                </Text>
                              </View>

                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: '18.75%',
                                  alignItems: 'center',
                                }}>
                                <Text
                                  style={[
                                    switchMerchant
                                      ? {
                                        color: Colors.descriptionColor,
                                        fontSize: 10,
                                      }
                                      : {
                                        color: Colors.descriptionColor,
                                        fontSize: 13,
                                      },
                                  ]}>
                                  RM
                                </Text>
                                <Text
                                  style={
                                    switchMerchant
                                      ? {
                                        color: Colors.descriptionColor,
                                        paddingRight: 20,
                                        fontSize: 10, fontFamily: 'NunitoSans-Regular'
                                      }
                                      : {
                                        color: Colors.descriptionColor,
                                        paddingRight: 20,
                                        fontSize: 13, fontFamily: 'NunitoSans-Regular'
                                      }
                                  }>
                                  {addOnChoice.prices[0]
                                    .toFixed(2)
                                    .replace(
                                      /(\d)(?=(\d{3})+(?!\d))/g,
                                      '$1,',
                                    )}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', width: '100%' }}>
                    <View style={{ width: '70%' }} />
                    <View style={{ width: 15 }} />
                    {index === item.cartItems.length - 1 ? (
                      <View
                        style={{
                          flexDirection: 'row',
                          //backgroundColor: 'yellow',
                          width: '28.65%',
                        }}>
                        <View
                          style={{
                            justifyContent: 'center',
                            width: '100%',
                          }}>
                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    width: '50.85%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.85%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Subtotal:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: switchMerchant ? '49.15%' : '49.1%',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? { fontSize: 10 }
                                    : { fontSize: 13, }
                                }>
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                    }
                                    : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                }>
                                {item.totalPrice
                                  .toFixed(2)
                                  .replace(
                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                    '$1,',
                                  )}
                              </Text>
                            </View>
                          </View>
                          {cartItem.orderType === ORDER_TYPE.DELIVERY ? (
                            <View
                              style={{
                                flexDirection: 'row',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      width: '50.85%',
                                      fontFamily: 'Nunitosans-Bold',
                                    }
                                    : {
                                      fontSize: 13,
                                      width: '50.85%',
                                      fontFamily: 'Nunitosans-Bold',
                                    }
                                }>
                                Delivery Fee:
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  width: switchMerchant ? '49.15%' : '49.1%',
                                }}>
                                <Text
                                  style={
                                    switchMerchant
                                      ? { fontSize: 10 }
                                      : { fontSize: 13, }
                                  }>
                                  RM
                                </Text>
                                <Text
                                  style={
                                    switchMerchant
                                      ? {
                                        fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                      }
                                      : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                  }>
                                  {item.deliveryFee
                                    .toFixed(2)
                                    .replace(
                                      /(\d)(?=(\d{3})+(?!\d))/g,
                                      '$1,',
                                    )}
                                </Text>
                              </View>
                            </View>
                          ) : (
                            <></>)}

                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    width: '50.85%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.85%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Discount:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: switchMerchant ? '49.15%' : '49.1%',
                              }}>
                              <Text style={switchMerchant
                                ? { fontSize: 10 } : { fontSize: 13 }}>RM</Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                    }
                                    : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                }>
                                - {item.discount
                                  .toFixed(2)
                                  .replace(
                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                    '$1,',
                                  )}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10, width: '50.85%', fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.85%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Tax:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: switchMerchant ? '49.15%' : '49.1%',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : { fontSize: 13, }
                                }>
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                    }
                                    : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                }>
                                {item.tax
                                  .toFixed(2)
                                  .replace(
                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                    '$1,',
                                  )}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10, width: '50.85%', fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.85%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Service Charge:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: switchMerchant ? '49.15%' : '49.1%',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : { fontSize: 13, }
                                }>
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                    }
                                    : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                }>
                                {(item.sc || 0)
                                  .toFixed(2)
                                  .replace(
                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                    '$1,',
                                  )}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10, width: '50.85%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.85%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Total:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: switchMerchant ? '49.15%' : '49.1%',
                              }}>
                              <Text style={switchMerchant
                                ? { fontSize: 10 } : { fontSize: 13 }}>RM</Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                    }
                                    : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                }>
                                {item.finalPrice
                                  .toFixed(2)
                                  .replace(
                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                    '$1,',
                                  )}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}
                  </View>

                  {/* <View style={{alignItems:'flex-end'}}>
           <View style={{ flexDirection: 'row' }}>
             <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 16 }}>Subtotal: {(Math.ceil(cartItem.price * 20-0.05) /20).toFixed(2)}</Text>
           </View>
         </View> */}
                  {/* {(cartItem.remarks && cartItem.remarks.length > 0) ?
         <View style={{ alignItems: 'center', flexDirection: 'row' }}>
           
           <View style={{ flex: 1, justifyContent: 'center', }}>
             <Text style={{ fontFamily: 'NunitoSans-SemiBold', fontSize: 15 }}>{cartItem.remarks}</Text>
           </View>
           
         </View>
         : <></>
       } */}
                </View>
              );
            })}
          </View>
        ) : null}
      </TouchableOpacity>
    );
  };

  const filterPressed = (filterTapped) => {
    if (filterTapped == 1) {
      setExpandSelection(true);
    }
  };

  // const { selectedStartDate, selectedEndDate } = state;
  const today = new Date();
  const minDate = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000); // Today
  const maxDate = new Date(2017, 6, 3);
  const startDate = selectedStartDate ? selectedStartDate.toString() : minDate;
  const endDate = selectedEndDate ? selectedEndDate.toString() : today;
  const [exportEmail, setExportEmail] = useState('');
  const [CsvData, setCsvData] = useState([]);
  // const [visible, setVisible] = useState(false);

  const dummyData = ['Download Test'];

  setState({ CsvData: dummyData });

  // const downloadCsv = () => {
  //   //if (productSales && productSales.dataSource && productSales.dataSource.data) {
  //   //const csvData = convertArrayToCSV(productSales.dataSource.data);
  //   const csvData = convertArrayToCSV(CsvData);

  //   const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir
  //     }/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
  //   console.log('PATH', pathToWrite);
  //   RNFetchBlob.fs
  //     .writeFile(pathToWrite, csvData, 'utf8')
  //     .then(() => {
  //       console.log(`wrote file ${pathToWrite}`);
  //       // wrote file /storage/emulated/0/Download/data.csv
  //       Alert.alert(
  //         'Success',
  //         `Send to ${pathToWrite}`,
  //         [{ text: 'OK', onPress: () => { } }],
  //         { cancelable: false },
  //       );
  //     })
  //     .catch((error) => console.error(error));
  //   //}
  // };

  // const emailOvertimeSales = () => {
  //     var body = {
  //         //data: CsvData,
  //         //data: convertArrayToCSV(productSales.dataSource.data),
  //         data: convertArrayToCSV(CsvData),
  //         email: exportEmail,
  //     };
  //     //API need to change
  //     ApiClient.POST(API.emailDashboard, body, false).then((result) => {
  //         if (result !== null) {
  //             Alert.alert(
  //                 'Success',
  //                 'Email sent to your inbox',
  //                 [{ text: 'OK', onPress: () => { } }],
  //                 { cancelable: false },
  //             );
  //         }
  //     });

  //     setVisible(false);
  // };

  const emailOvertimeSales = () => {
    const excelData = convertDataToExcelFormat();

    var body = {
      // data: CsvData,
      //data: convertArrayToCSV(todaySalesChart.dataSource.data),
      data: JSON.stringify(excelData),
      //data: convertDataToExcelFormat(),
      email: exportEmail,
    };

    ApiClient.POST(API.emailDashboard, body, false).then((result) => {
      if (result !== null) {
        Alert.alert(
          'Success',
          'Email has been sent',
          [{ text: 'OK', onPress: () => { } }],
          { cancelable: false },
        );
      }
    });

    setVisible(false);
  };

  const sortingFunc = (param) => {
    if (param.value == 1) {
      //orderid
      setSalesLineChartPeriod(CHART_PERIOD.TODAY);
    }
    if (param.value == 2) {
      //date time
      setSalesLineChartPeriod(CHART_PERIOD.THIS_WEEK);
    }
    if (param.value == 3) {
      //Name
      setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH);
    }
    /*  if (param.value == 4) { //Waiting Time
             setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH)
         }
         if (param.value == 5) { //Payment Method
             setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH)
         }
         if (param.value == 6) { //Total
             setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH)
         } */
  };

  const calanderFilter = () => { };

  var leftSpacing = '0%';

  if (windowWidth >= 1280) {
    leftSpacing = '2%';
  }

  const leftSpacingScale = {
    marginLeft: leftSpacing,
  };

  const convertDataToExcelFormat = () => {
    var excelData = [];

    if (!showDetails) {
      for (var i = 0; i < dailySalesDetailsList.length; i++) {
        var excelRow = {
          // 'Transaction Category': transactionTypeSales[i].orderType,
          // 'Total Sales (RM)': parseFloat(transactionTypeSales[i].totalSales).toFixed(2),
          // 'Total Transaction': transactionTypeSales[i].totalTransactions,
          // 'Total Discount (RM)': parseFloat(transactionTypeSales[i].totalDiscount).toFixed(2),
          // 'Discount (%)': parseFloat(transactionTypeSales[i].discount).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSales[i].tax).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSales[i].serviceCharge).toFixed(2),
          // 'GP (%)': parseFloat(transactionTypeSales[i].gp).toFixed(2),
          // 'Net Sales (RM)': parseFloat(transactionTypeSales[i].netSales).toFixed(2),
          // 'Average Net Sales (RM)': parseFloat(transactionTypeSales[i].averageNetSales).toFixed(2),
          'Date/Time': moment(dailySalesDetailsList[i].dateTime).format(
            'DD MMM hh:mm A',
          ),
          'Total Sales (RM)': +parseFloat(
            dailySalesDetailsList[i].totalSales,
          ).toFixed(2),
          'Total Transaction': dailySalesDetailsList[i].totalTransactions,
          'Total Discount (RM)': +parseFloat(
            dailySalesDetailsList[i].totalDiscount,
          ).toFixed(2),
          'Discount (%)': +parseFloat(dailySalesDetailsList[i].discount).toFixed(
            2,
          ),
          'Tax (RM)': +parseFloat(dailySalesDetailsList[i].tax).toFixed(2),
          'Service Charge (RM)': +parseFloat(
            dailySalesDetailsList[i].serviceCharge,
          ).toFixed(2),
          //'GP (%)': parseFloat(dailySalesDetailsList[i].gp).toFixed(2),
          'Total Sales Return': +parseFloat(
            dailySalesDetailsList[i].totalSalesReturn,
          ).toFixed(2),
          'Net Sales (RM)': +parseFloat(
            dailySalesDetailsList[i].netSales,
          ).toFixed(2),
          'Average Net Sales (RM)': +parseFloat(
            dailySalesDetailsList[i].averageNetSales,
          ).toFixed(2),
        };

        excelData.push(excelRow);
      }
    } else {
      for (var i = 0; i < transactionTypeSalesDetails.length; i++) {
        var excelRow = {
          'Date/Time': moment(
            transactionTypeSalesDetails[i].completedDate,
          ).format('DD MMM hh:mm A'),
          'Sales (RM)': +parseFloat(
            transactionTypeSalesDetails[i].finalPrice,
          ).toFixed(2),
          'Total Discount (RM)': +parseFloat(
            transactionTypeSalesDetails[i].discount,
          ).toFixed(2),
          // 'Discount (%)': parseFloat(isFinite(transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount) ? transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount * 100 : 0).toFixed(2),
          'Discount (%)': +parseFloat(
            transactionTypeSalesDetails[i].discountPercentage,
          ).toFixed(2),
          'Tax (RM)': +parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2),
          'Service Charge (RM)': +parseFloat(transactionTypeSalesDetails[i].sc || 0).toFixed(2),
          //'GP (%)': parseFloat(0).toFixed(2),
          'Sales Return': +parseFloat(
            transactionTypeSalesDetails[i].totalPrice,
          ).toFixed(2),
          'Net Sales (RM)': +parseFloat(Math.ceil(transactionTypeSalesDetails[i].finalPrice * 20 - 0.05) / 20).toFixed(2),
        };

        excelData.push(excelRow);
      }
    }

    console.log('excelData');
    console.log(excelData);

    return excelData;
  };

  // const downloadExcel = () => {
  //   const excelData = convertDataToExcelFormat();

  //   var excelFile = `${Platform.OS === 'ios'
  //     ? RNFS.DocumentDirectoryPath
  //     : RNFS.DownloadDirectoryPath
  //     }/koodoo-report-Overtime-Sales${moment().format(
  //       'YYYY-MM-DD-HH-mm-ss',
  //     )}.xlsx`;
  //   var excelWorkSheet = XLSX.utils.json_to_sheet(excelData);
  //   var excelWorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(
  //     excelWorkBook,
  //     excelWorkSheet,
  //     'Overview Sales Report',
  //   );

  //   const workBookData = XLSX.write(excelWorkBook, {
  //     type: 'binary',
  //     bookType: 'xlsx',
  //   });

  //   RNFS.writeFile(excelFile, workBookData, 'ascii')
  //     .then((success) => {
  //       console.log(`wrote file ${excelFile}`);

  //       Alert.alert(
  //         'Success',
  //         `Send to ${excelFile}`,
  //         [{ text: 'OK', onPress: () => { } }],
  //         { cancelable: false },
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });

  //   // XLSX.writeFileAsync(excelFile, excelWorkBook, () => {
  //   //     Alert.alert(
  //   //         'Success',
  //   //         `Send to ${excelFile}`,
  //   //         [{ text: 'OK', onPress: () => { } }],
  //   //         { cancelable: false },
  //   //     );
  //   // });

  //   // const csvData = convertArrayToCSV(CsvData);

  //   // const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
  //   // console.log("PATH", excelFile);
  //   // RNFetchBlob.fs
  //   //     .writeFile(excelFile, excelWorkBook, 'utf8')
  //   //     .then(() => {
  //   //         console.log(`wrote file ${excelFile}`);
  //   //         Alert.alert(
  //   //             'Success',
  //   //             `Send to ${excelFile}`,
  //   //             [{ text: 'OK', onPress: () => { } }],
  //   //             { cancelable: false },
  //   //         );
  //   //     })
  //   //     .catch(error => console.error(error));
  // };

  const flatListRef = useRef();

  const ScrollToTop = () => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
  };

  const ScrollToBottom = () => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 100 });
  };

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={8} expandReport={true} />
      </View>
      <Modal
        style={{}}
        visible={exportModalVisibility}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        animationType={'fade'}>
        <View style={{
          flex: 1,
          backgroundColor: Colors.modalBgColor,
          alignItems: 'center',
          justifyContent: 'center',
          // top: Platform.OS === 'android' ? 0 : keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
        }}>
          <View style={{
            height: windowWidth * 0.3,
            width: windowWidth * 0.4,
            backgroundColor: Colors.whiteColor,
            borderRadius: 12,
            padding: windowWidth * 0.03,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <TouchableOpacity
              style={{
                position: 'absolute',
                right: windowWidth * 0.02,
                top: windowWidth * 0.02,

                elevation: 1000,
                zIndex: 1000,
              }}
              onPress={() => {
                setExportModalVisibility(false);
              }}>
              <AntDesign name="closecircle" size={switchMerchant ? 15 : 25} color={Colors.fieldtTxtColor} />
            </TouchableOpacity>
            <View style={{
              alignItems: 'center',
              top: '20%',
              position: 'absolute',
            }}>
              <Text style={{
                fontFamily: 'NunitoSans-Bold',
                textAlign: 'center',
                fontSize: switchMerchant ? 16 : 24,
              }}>
                Download Report
              </Text>
            </View>
            <View style={{ top: switchMerchant ? '14%' : '10%' }}>
              <Text style={{ fontSize: switchMerchant ? 10 : 20, fontFamily: 'NunitoSans-Bold', }}>Email Address:</Text>
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: switchMerchant ? 240 : 370,
                  height: switchMerchant ? 35 : 50,
                  borderRadius: 5,
                  padding: 5,
                  marginVertical: 5,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  paddingLeft: 10,
                  fontSize: switchMerchant ? 10 : 14,
                }}
                placeholderStyle={{ padding: 5 }}
                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                placeholder="Enter Your Email"
                onChangeText={(text) => {
                  setExportEmail(text);
                }}
                value={exportEmail}
              />
              <Text style={{ fontSize: switchMerchant ? 10 : 20, fontFamily: 'NunitoSans-Bold', marginTop: 15, }}>Download As:</Text>

              <View style={{
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                marginTop: 10,
              }}>
                <CSVLink
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    display: "inline-block",
                    flexDirection: "row",
                    textDecoration: 'none',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    width: switchMerchant ? 100 : 100,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                  }}
                  data={convertDataToExcelFormat()}
                  filename="report.csv"
                >
                  <View
                    style={{
                      width: "100%",
                      height: "100%",
                      alignContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      CSV
                    </Text>
                  </View>
                </CSVLink>
              </View>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        style={{ flex: 1 }}
        visible={visible}
        transparent={true}
        animationType="slide">
        <KeyboardAvoidingView
          //behavior="padding"
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: Dimensions.get('window').height,
            // top:
            //   Platform.OS === 'ios' && keyboardHeight > 0
            //     ? -keyboardHeight * 0.5
            //     : 0,
          }}>
          <View style={styles.confirmBox1}>
            <Text
              style={{
                fontSize: 24,
                justifyContent: 'center',
                alignSelf: 'center',
                marginTop: 40,
                fontFamily: 'NunitoSans-Bold',
              }}>
              Enter your email
            </Text>
            <View
              style={{
                justifyContent: 'center',
                alignSelf: 'center',
                alignContent: 'center',
                marginTop: 20,
                flexDirection: 'row',
                width: '80%',
              }}>
              <View style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                <Text style={{ color: Colors.descriptionColor, fontSize: 20 }}>
                  email:
                </Text>
              </View>
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={[styles.textInput8, { paddingLeft: 5 }]}
                placeholder="Enter your email"
                // style={{
                //     // paddingLeft: 1,
                // }}
                //defaultValue={extentionCharges}
                onChangeText={(text) => {
                  // setState({ exportEmail: text });
                  setExportEmail(text);
                }}
                value={exportEmail}
              />
            </View>
            <Text
              style={{
                fontSize: 20,
                fontFamily: 'NunitoSans-Bold',
                marginTop: 25,
                justifyContent: 'center',
                alignSelf: 'center',
                alignContent: 'center',
              }}>
              Share As:
            </Text>

            {/* Share file using email */}
            <View
              style={{
                justifyContent: 'space-around',
                alignSelf: 'center',
                marginTop: 10,
                flexDirection: 'row',
                width: '80%',
              }}>
              <TouchableOpacity
                style={[
                  styles.modalSaveButton1,
                  {
                    zIndex: -1,
                  },
                ]}
                onPress={() => { }}>
                <Text
                  style={[
                    styles.modalDescText,
                    { color: Colors.primaryColor },
                  ]}>
                  Excel
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={[
                  styles.modalSaveButton1,
                  {
                    zIndex: -1,
                  },
                ]}
                onPress={() => { }}>
                <Text
                  style={[
                    styles.modalDescText,
                    { color: Colors.primaryColor },
                  ]}>
                  CSV
                </Text>
              </TouchableOpacity>
              {/* <TouchableOpacity
                                style={[styles.modalSaveButton1, {
                                    zIndex: -1.
                                }]}
                                onPress={() => { }}>
                                <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>PDF</Text>
                            </TouchableOpacity> */}
            </View>
            <View
              style={{
                alignSelf: 'center',
                marginTop: 20,
                justifyContent: 'center',
                alignItems: 'center',
                // width: 260,
                width: windowWidth * 0.2,
                height: 60,
                alignContent: 'center',
                flexDirection: 'row',
                marginTop: 40,
              }}>
              <TouchableOpacity
                onPress={emailOvertimeSales}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  height: 60,
                  borderBottomLeftRadius: 10,
                  borderRightWidth: StyleSheet.hairlineWidth,
                  borderTopWidth: StyleSheet.hairlineWidth,
                }}>
                <Text
                  style={{
                    fontSize: 22,
                    color: Colors.primaryColor,
                    fontFamily: 'NunitoSans-SemiBold',
                  }}>
                  Email
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  // setState({ visible: false });
                  setVisible(false);
                }}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignContent: 'center',
                  height: 60,
                  borderBottomRightRadius: 10,
                  borderTopWidth: StyleSheet.hairlineWidth,
                }}>
                <Text
                  style={{
                    fontSize: 22,
                    color: Colors.descriptionColor,
                    fontFamily: 'NunitoSans-SemiBold',
                  }}>
                  Cancel
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>

      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ width: windowWidth * 0.9 }}
          contentContainerStyle={{
            paddingBottom: windowHeight * 0.1,
          }}
        >
          <View
            style={{
              paddingHorizontal: switchMerchant ? 0 : 0,
              paddingVertical: 30,
              marginHorizontal: switchMerchant ? 15 : 30,
            }}
          >
            {day ? (
              <View
                style={{
                  position: 'absolute',
                  width: '40%',
                  backgroundColor: Colors.whiteColor,
                  left: 440,
                  top: 170,
                  zIndex: 6000,
                }}>
                <View
                  style={{
                    padding: 10,
                    width: '95%',
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    flexDirection: 'row',
                  }}>
                  <TouchableOpacity
                    onPress={() => {
                      setState({ daily: true, custom: false });
                    }}>
                    <Text style={{ fontSize: 16 }}>Daily</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ marginLeft: 10 }}
                    onPress={() => {
                      setState({ daily: false, custom: true });
                    }}>
                    <Text style={{ fontSize: 16 }}>Custom Date</Text>
                  </TouchableOpacity>
                </View>

                {daily ? (
                  <View>
                    <View
                      style={{
                        padding: 10,
                        width: '95%',
                        flexDirection: 'row',
                        marginTop: 10,
                      }}>
                      <TouchableOpacity
                        style={{
                          width: '20%',
                          borderLeftWidth: StyleSheet.hairlineWidth,
                          borderTopWidth: StyleSheet.hairlineWidth,
                          borderBottomWidth: StyleSheet.hairlineWidth,
                          justifyContent: 'center',
                          alignItems: 'center',
                          padding: 5,
                        }}
                        onPress={() => { }}>
                        <Text style={{ fontSize: 16 }}>Year</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          width: '20%',
                          borderTopWidth: StyleSheet.hairlineWidth,
                          borderBottomWidth: StyleSheet.hairlineWidth,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onPress={() => { }}>
                        <Text style={{ fontSize: 16 }}>Quarter</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          width: '20%',
                          borderTopWidth: StyleSheet.hairlineWidth,
                          borderBottomWidth: StyleSheet.hairlineWidth,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onPress={() => { }}>
                        <Text style={{ fontSize: 16 }}>Month</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          width: '20%',
                          borderTopWidth: StyleSheet.hairlineWidth,
                          borderBottomWidth: StyleSheet.hairlineWidth,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onPress={() => { }}>
                        <Text style={{ fontSize: 16 }}>Week</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          width: '20%',
                          borderRightWidth: StyleSheet.hairlineWidth,
                          borderTopWidth: StyleSheet.hairlineWidth,
                          borderBottomWidth: StyleSheet.hairlineWidth,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onPress={() => { }}>
                        <Text style={{ fontSize: 16 }}>Day</Text>
                      </TouchableOpacity>
                    </View>

                    <TouchableOpacity
                      style={{
                        padding: 10,
                        width: '95%',
                        flexDirection: 'row',
                        marginTop: 10,
                        marginLeft: '4%',
                      }}
                      onPress={() => {
                        onClick();
                      }}>
                      <View
                        style={{
                          width: '5%',
                          borderWidth: 1,
                          borderColor:
                            onClick1 == 1
                              ? Colors.primaryColor
                              : Colors.blackColor,
                          height: 17,
                          borderRadius: 20,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <View
                          style={{
                            width: '63%',
                            backgroundColor:
                              onClick1 == 1
                                ? Colors.primaryColor
                                : Colors.whiteColor,
                            height: 10,
                            borderRadius: 20,
                          }}></View>
                      </View>
                      <Text style={{ marginLeft: '4%' }}>Month to date</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{
                        padding: 10,
                        width: '95%',
                        flexDirection: 'row',
                        marginTop: 10,
                        marginLeft: '4%',
                      }}
                      onPress={() => {
                        onClick1Func();
                      }}>
                      <View
                        style={{
                          width: '5%',
                          borderWidth: 1,
                          borderColor:
                            onClick2 == 1
                              ? Colors.primaryColor
                              : Colors.blackColor,
                          height: 17,
                          borderRadius: 20,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <View
                          style={{
                            width: '63%',
                            backgroundColor:
                              onClick2 == 1
                                ? Colors.primaryColor
                                : Colors.whiteColor,
                            height: 10,
                            borderRadius: 20,
                          }}></View>
                      </View>
                      <Text style={{ marginLeft: '4%' }}>Previous month</Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{
                        padding: 10,
                        width: '95%',
                        flexDirection: 'row',
                        marginTop: 10,
                        marginLeft: '4%',
                      }}
                      onPress={() => {
                        onClick2Func();
                      }}>
                      <View
                        style={{
                          width: '5%',
                          borderWidth: 1,
                          borderColor:
                            onClick3 == 1
                              ? Colors.primaryColor
                              : Colors.blackColor,
                          height: 17,
                          borderRadius: 20,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}>
                        <View
                          style={{
                            width: '63%',
                            backgroundColor:
                              onClick3 == 1
                                ? Colors.primaryColor
                                : Colors.whiteColor,
                            height: 10,
                            borderRadius: 20,
                          }}></View>
                      </View>
                      <View style={{ flexDirection: 'row', marginLeft: '4%' }}>
                        <Text>Last</Text>
                        {/* <TextInput
                        editable={!loading}
                        underlineColorAndroid={Colors.whiteColor}
                        clearButtonMode="while-editing"
                        style={styles.textInput1}
                        onChangeText={(text) => {
                          setState({ search1: text.trim() });
                        }}
                        value={email}
                      /> */}
                        <Text style={{ marginLeft: '4%' }}>Month</Text>
                      </View>
                    </TouchableOpacity>

                    <View style={{ padding: 10, backgroundColor: '#b1e3c7' }}>
                      <Text>
                        Show results from{' '}
                        {moment(startDate).format('DD MMM YYYY')} to{' '}
                        {moment(endDate).format('DD MMM YYYY')}
                      </Text>
                    </View>

                    <View style={{ height: 20 }}></View>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <TouchableOpacity
                        style={{
                          width: '30%',
                          height: 40,
                          marginLeft: '30%',
                          backgroundColor: Colors.whiteColor,
                          borderWidth: StyleSheet.hairlineWidth,
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 5,
                        }}
                        onPress={() => {
                          setState({ day: false });
                        }}>
                        <Text style={{ color: '#ababab', fontSize: 22 }}>
                          CANCEL
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          width: '30%',
                          height: 40,
                          backgroundColor: Colors.primaryColor,
                          marginLeft: '3%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 5,
                        }}
                        onPress={() => {
                          // getDetail(
                          //   moment(startDate).format('YYYY-MM-DD'),
                          //   moment(endDate).format('YYYY-MM-DD'),
                          // ),
                          //   setState({ day: false });
                        }}>
                        <Text style={{ color: Colors.whiteColor, fontSize: 22 }}>
                          APPLY
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ height: 50 }}></View>
                  </View>
                ) : null}

                {custom ? (
                  <View style={{ backgroundColor: Colors.whiteColor }}>
                    <View style={{ width: '95%' }}>
                      {/* <CalendarPicker
                      startFromMonday={true}
                      allowRangeSelection={true}
                      todayBackgroundColor="#f2e6ff"
                      selectedDayColor="#7300e6"
                      selectedDayTextColor="#FFFFFF"
                      onDateChange={onDateChange}
                      width={350}
                    /> */}
                    </View>
                    <View
                      style={{
                        padding: 10,
                        backgroundColor: '#b1e3c7',
                        marginTop: 10,
                      }}>
                      <Text>
                        Show results from{' '}
                        {moment(startDate).format('DD MMM YYYY')} to{' '}
                        {moment(endDate).format('DD MMM YYYY')}
                      </Text>
                    </View>
                    <View style={{ height: 20 }}></View>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <TouchableOpacity
                        style={{
                          width: '30%',
                          height: 40,
                          marginLeft: '30%',
                          backgroundColor: Colors.whiteColor,
                          borderWidth: StyleSheet.hairlineWidth,
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 5,
                        }}
                        onPress={() => {
                          setState({ day: false });
                        }}>
                        <Text style={{ color: '#ababab', fontSize: 22 }}>
                          CANCEL
                        </Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={{
                          width: '30%',
                          height: 40,
                          backgroundColor: Colors.primaryColor,
                          marginLeft: '3%',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 5,
                        }}
                        onPress={() => {
                          // getDetail(
                          //   moment(startDate).format('YYYY-MM-DD'),
                          //   moment(endDate).format('YYYY-MM-DD'),
                          // ),
                          //   setState({ day: false }),
                          //   setState({ day: false });
                        }}>
                        <Text style={{ color: Colors.whiteColor, fontSize: 22 }}>
                          APPLY
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ height: 50 }}></View>
                  </View>
                ) : null}
              </View>
            ) : null}

            {filter ? (
              <View
                style={{
                  position: 'absolute',
                  width: '50%',
                  backgroundColor: Colors.whiteColor,
                  left: 0,
                  top: 170,
                  zIndex: 6000,
                }}>
                <View
                  style={{
                    padding: 10,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                  }}>
                  <Text style={{ fontSize: 16, marginLeft: 3 }}>Manage Filter</Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <TouchableOpacity
                    style={{
                      borderWidth: StyleSheet.hairlineWidth,
                      width: '50%',
                      height: 35,
                      marginTop: 15,
                      marginLeft: 20,
                      borderRadius: 5,
                      backgroundColor: Colors.whiteColor,
                      flexDirection: 'row',
                    }}
                    onPress={() => {
                      changeClick2();
                    }}>
                    <Text
                      style={{
                        color: '#ababab',
                        marginTop: 5,
                        marginLeft: 10,
                        width: '80%',
                      }}>
                      {supplier}
                    </Text>
                    <Feather name="chevron-down" size={30} color={'#ababab'} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      borderWidth: StyleSheet.hairlineWidth,
                      width: '40%',
                      height: 35,
                      marginTop: 15,
                      marginLeft: 15,
                      borderRadius: 5,
                      backgroundColor: Colors.whiteColor,
                      flexDirection: 'row',
                    }}
                    onPress={() => {
                      changeClick3();
                    }}>
                    <Text
                      style={{
                        color: '#ababab',
                        marginTop: 5,
                        marginLeft: 10,
                        width: '75%',
                      }}>
                      {product}
                    </Text>
                    <Feather name="chevron-down" size={30} color={'#ababab'} />
                  </TouchableOpacity>
                </View>

                {/* <TextInput
                editable={!loading}
                underlineColorAndroid={Colors.whiteColor}
                clearButtonMode="while-editing"
                style={styles.textInput}
                placeholder="  Field Value"
                onChangeText={(text) => {
                  setState({ search: text.trim() });
                }}
                value={search}
              /> */}

                <TouchableOpacity
                  style={{
                    borderWidth: StyleSheet.hairlineWidth,
                    width: '40%',
                    height: 35,
                    marginTop: 20,
                    marginLeft: 20,
                    borderRadius: 5,
                    backgroundColor: Colors.whiteColor,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onPress={() => { }}>
                  <Text>Add New Filter</Text>
                </TouchableOpacity>

                <View
                  style={{
                    padding: 15,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                  }}></View>

                <View style={{ height: 40 }}></View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  <TouchableOpacity
                    style={{
                      width: '30%',
                      height: 40,
                      marginLeft: '30%',
                      backgroundColor: Colors.whiteColor,
                      borderWidth: StyleSheet.hairlineWidth,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 5,
                    }}
                    onPress={() => {
                      setState({ filter: false });
                    }}>
                    <Text style={{ color: '#ababab', fontSize: 22 }}>CANCEL</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      width: '30%',
                      height: 40,
                      backgroundColor: Colors.primaryColor,
                      marginLeft: '3%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 5,
                    }}
                    onPress={() => {
                      getStockFilter(field, isOrNot, search);
                      setState({ filter: false });
                    }}>
                    <Text style={{ color: Colors.whiteColor, fontSize: 22 }}>
                      APPLY
                    </Text>
                  </TouchableOpacity>
                </View>
                <View style={{ height: 50 }}></View>

                {filter1 ? (
                  <View
                    style={{
                      position: 'absolute',
                      width: '50%',
                      left: 22,
                      top: 100,
                      zIndex: 6000,
                    }}>
                    <View
                      style={{ padding: 10, backgroundColor: Colors.whiteColor }}>
                      <Text
                        style={{ fontSize: 12, marginLeft: 3, color: '#ababab' }}>
                        Transaction Attributes
                      </Text>
                    </View>

                    <TouchableOpacity
                      style={{ padding: 10, backgroundColor: Colors.whiteColor }}
                      onPress={() => {
                        setState({ supplier: 'Store Name', field: 'StoreName' });
                      }}>
                      <Text style={{ color: '#828282' }}>Store Name</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        backgroundColor: Colors.whiteColor,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                      }}
                      onPress={() => {
                        setState({
                          supplier: 'Transaction Channel',
                          field: 'TransactionChannel',
                        });
                      }}>
                      <Text style={{ color: '#828282' }}>Transaction Channel</Text>
                    </TouchableOpacity>

                    <View
                      style={{ padding: 10, backgroundColor: Colors.whiteColor }}>
                      <Text
                        style={{ fontSize: 12, marginLeft: 3, color: '#ababab' }}>
                        Product Attributes
                      </Text>
                    </View>

                    <TouchableOpacity
                      style={{ padding: 10, backgroundColor: Colors.whiteColor }}
                      onPress={() => {
                        setState({
                          supplier: 'Product Name',
                          field: 'ProductName',
                        });
                      }}>
                      <Text style={{ color: '#828282' }}>Product Name</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ padding: 10, backgroundColor: Colors.whiteColor }}
                      onPress={() => {
                        setState({
                          supplier: 'Product Category',
                          field: 'ProductCategory',
                        });
                      }}>
                      <Text style={{ color: '#828282' }}>Product Category</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        backgroundColor: Colors.whiteColor,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                      }}
                      onPress={() => {
                        setState({ supplier: 'Product SKU', field: 'ProductSku' });
                      }}>
                      <Text style={{ color: '#828282' }}>Product SKU</Text>
                    </TouchableOpacity>

                    <View style={{ height: 120 }}></View>
                  </View>
                ) : null}
                {filter2 ? (
                  <View
                    style={{
                      position: 'absolute',
                      width: '40%',
                      left: 260,
                      top: 100,
                      zIndex: 6000,
                    }}>
                    <TouchableOpacity
                      style={{ padding: 10, backgroundColor: Colors.whiteColor }}
                      onPress={() => {
                        setState({ product: 'is', isOrNot: true });
                      }}>
                      <Text style={{ color: '#828282' }}>is</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ padding: 10, backgroundColor: Colors.whiteColor }}
                      onPress={() => {
                        setState({ product: 'is not', isOrNot: false });
                      }}>
                      <Text style={{ color: '#828282' }}>is not</Text>
                    </TouchableOpacity>
                  </View>
                ) : null}
              </View>
            ) : null}

            <View
              style={{
                height: 70,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: switchMerchant ? 20 : 26,
                    fontFamily: "NunitoSans-Bold",
                    marginVertical: 6,
                  }}
                >
                  Sales Overview
                </Text>
              </View>

              <View style={{ flexDirection: 'row' }}>
                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={{
                      alignItem: 'center',
                      marginRight: 10,
                      // marginBottom: 10,
                    }}>
                    <TouchableOpacity
                      style={{
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: '#4E9F7D',
                        borderRadius: 5,
                        //width: 140,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: 'center',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      onPress={() => {
                        setExportModalVisibility(true);
                      }}>
                      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Icon
                          name="download"
                          size={switchMerchant ? 10 : 20}
                          color={Colors.whiteColor}
                        />
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: 'NunitoSans-Bold',
                          }}>
                          DOWNLOAD
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>

                <View style={{ flexDirection: 'row' }}>
                  <View
                    style={{
                      width: switchMerchant ? 200 : 250,
                      height: switchMerchant ? 35 : 40,
                      backgroundColor: 'white',
                      borderRadius: 5,
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      zIndex: -1,
                      shadowColor: '#000',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                      borderWidth: 1,
                      borderColor: '#E5E5E5',
                    }}>
                    <Icon
                      name="search"
                      size={switchMerchant ? 13 : 18}
                      color={Colors.primaryColor}
                      style={{ marginLeft: 15 }}
                    />
                    <TextInput
                      underlineColorAndroid={Colors.whiteColor}
                      style={{
                        width: switchMerchant ? 180 : 220,
                        fontSize: switchMerchant ? 10 : 15,
                        fontFamily: 'NunitoSans-Regular',
                        paddingLeft: 5,
                        height: 45
                      }}
                      clearButtonMode="while-editing"
                      placeholder=" Search"
                      //placeholderTextColor={Colors.descriptionColor}
                      onChangeText={(text) => {
                        setSearch2(text);
                      }}
                      value={search2}
                    />
                  </View>
                  {/* </View> */}
                </View>
              </View>
            </View>

            <View
              style={{
                flexDirection: 'row',
                marginTop: 0,
                justifyContent: 'space-between',
              }}>
              {/* <TouchableOpacity style={{ borderRadius: 10, backgroundColor: Colors.whiteColor, width: "6%", height: windowHeight * 0.040, justifyContent: "center", alignItems: "center", marginRight: 10 }}
                        onPress={() => { changeClick1() }}>
                        <Text>Filter</Text>
                    </TouchableOpacity> */}

              <TouchableOpacity>
                <Text
                  style={{
                    fontFamily: 'NunitoSans-Regular',
                    fontSize: 13,
                    marginLeft: 12,
                  }}></Text>
              </TouchableOpacity>

              {/* <View style={{ flexDirection: 'column', }}> */}
              {/* <TouchableOpacity style={{
                            justifyContent: 'center',
                            width: 90,
                            shadowOpacity: 0,
                            shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                        }}
                            onPress={() => {
                                filterPressed(1); setFilterTapped(1); setExpandSelection(!expandSelection);;
                            }}>
                            <View style={{
                                justifyContent: 'center',
                                width: '100%',
                                flexDirection: 'row',
                                borderWidth: 0,
                                borderColor: '#4E9F7D',
                                borderRadius: 8,
                                height: 40,
                                alignItems: 'center',
                                backgroundColor: '#FFFFFF',
                                position: 'relative',

                                paddingHorizontal: 15,
                                //backgroundColor: Colors.whiteColor, 
                                //height: 40, 
                                width: 120,
                                //alignItems: 'center', 
                                //borderRadius: 7, 
                                //flexDirection: 'row',
                                //justifyContent: 'center',

                                shadowOffset: {
                                    width: 0,
                                    height: 1,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                            }}>
                                <Feather name='filter' size={18} color='#27353C' />
                                <Text style={{
                                    color: '#27353C',
                                    fontSize: 14,
                                    fontFamily: 'Nunitosans-bold',
                                    marginLeft: 7
                                }}>
                                    Filter
                                </Text>
                            </View>
                        </TouchableOpacity> */}
              {/* { expandSelection ?
                <View style={[styles.ManageFilterBox]}>
                  
                <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10}}>  
                <Text style={{fontSize: 16, Colors: '#27353C', fontFamily: 'Nunitosans-bold'}}>Manage Filter</Text>
                </View>
                <View style={{borderWidth: 0.5, borderColor: '#D2D2D2', width: '100%', marginTop: 5, marginBottom: 5}}></View>
                <View style={{flexDirection: 'row', alignItems: 'center', margin: 10, height: 35}}>
                      <Picker
                            arrowColor={'#BDBDBD'}
                            arrowSize={23}
                            arrowStyle={{ fontWeight: 'bold' }}
                            style={{ width: 250, height: 35}}
                            placeholder={'Field name'}
                            placeholderStyle={{ color: 'black'}}
                            onChangeItem={selectedSort => {
                                // setState({ sort: selectedSort }),
                                //sortingFunc(selectedSort);
                            }
                            }
                        >
                          {[
                              { label: 'Daily', value: 1 },
                              { label: 'Weekly', value: 2 },
                              { label: 'Monthly', value: 3 },
                              //{ label: 'Hour of day', value: 4 }, 
                              //{ label: 'Day of week', value: 5 }
                          ].map((value, index) => {
                            return(
                              <Picker.Item
                                label={value.label}
                                value={value.value}
                              />
                            )
                          })
                          }
                        </Picker>
                    <Picker
                            arrowColor={'#BDBDBD'}
                            arrowSize={23}
                            arrowStyle={{ fontWeight: 'bold' }}
                            style={{ marginLeft:'5%' , width: 150}}
                            placeholder={'is'}
                            placeholderStyle={{ color: 'black'}}
                            onChangeItem={selectedSort => {
                                // setState({ sort: selectedSort }),
                                //sortingFunc(selectedSort);
                            }
                            }
                        >
                          {[
                              { label: 'Daily', value: 1 },
                              { label: 'Weekly', value: 2 },
                              { label: 'Monthly', value: 3 },
                              //{ label: 'Hour of day', value: 4 }, 
                              //{ label: 'Day of week', value: 5 }
                          ].map((value, index) => {
                            return(
                              <Picker.Item
                                label={value.label}
                                value={value.value}
                              />
                            )
                          })
                          }
                        </Picker>
                    </View>
                    <View style={{flexDirection: 'row', alignItems: 'center', margin: 10, height: 35}}>
                      <TextInput style={{width: 400, borderWidth: 1, borderColor: '#D2D2D2', alignItems: 'center', justifyContent: 'center', color: 'black'}} 
                      placeholder='   Field Value'
                      placeholderStyle={{color: 'black', justifyContent: 'center', alignItems: 'center'}}
                      
                      /> 
                    </View>
                    
                    <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10}}>
                    <TouchableOpacity style={{
                    justifyContent: 'center', 
                    width: '40%'
                    }}
                    onPress={() => {
                        
                    }}>
                    <View style={{ 
                        justifyContent: 'center', 
                        width: '100%', 
                        flexDirection: 'row', 
                        borderWidth: 1, 
                        borderColor: '#E0E0E0', 
                        borderRadius: 5, 
                        height: 35, 
                        alignItems: 'center',
                        backgroundColor: '#FFFFFF',
                        height: windowHeight * 0.040 
                        }}>
                    <Text style={{
                        color: '#27353C',
                        fontSize: 14,
                        fontFamily: 'Nunitosans-bold',
                        marginLeft: 7
                    }}>
                        Add New Filter   
                    </Text>
                    </View>
                    </TouchableOpacity>
                    </View>
                    <View style={{borderWidth: 0.5, borderColor: '#D2D2D2', width: '100%', marginTop: 5, marginBottom: 5}}></View>
                    <View style={{flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', margin: 10}}>
                    <TouchableOpacity style={{
                    justifyContent: 'center', 
                    width: '30%',
                    
                    }}
                    onPress={() => {
                        setExpandSelection(!expandSelection)
                    }}>
                    <View style={{ 
                        justifyContent: 'center', 
                        width: '100%', 
                        flexDirection: 'row', 
                        borderWidth: 1, 
                        borderColor: '#BDBDBD', 
                        borderRadius: 5, 
                        height: 35, 
                        alignItems: 'center',
                        backgroundColor: '#FFFFFF',
                        height: windowHeight * 0.050 
                        }}>
                    <Text style={{
                        color: '#BDBDBD',
                        fontSize: 15,
                        fontFamily: 'Nunitosans-bold',
                        marginLeft: 7
                    }}>
                        CANCEL   
                    </Text>
                    </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={{
                    justifyContent: 'center', 
                    width: '30%',
                    marginLeft: '3%',
                    }}
                    onPress={() => {
                        setExpandSelection(!expandSelection)
                    }}>
                    <View style={{ 
                        justifyContent: 'center', 
                        width: '100%', 
                        flexDirection: 'row', 
                        backgroundColor: '#4E9F7D',
                        borderRadius: 5, 
                        height: 35, 
                        alignItems: 'center',
                        height: windowHeight * 0.050,
                        }}>
                    <Text style={{
                        color: '#FFFFFF',
                        fontSize: 15,
                        fontFamily: 'Nunitosans-bold',
                        marginLeft: 7
                    }}>
                        APPLY   
                    </Text>
                    </View>
                    </TouchableOpacity>
                    </View>
                    </View>
                    :null} */}
              {/* </View> */}

              {/* <View style={{ zIndex: -1 , marginHorizontal: 5, paddingLeft: 10, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',borderRadius: 8, height: 40, backgroundColor: Colors.whiteColor,
                                    shadowOpacity: 0,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                }}>
                        <Text style={{ fontSize: 16, borderRightWidth: StyleSheet.hairlineWidth, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>View By</Text>
                        <DropDownPicker
                            arrowColor={Colors.primaryColor}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold', alignItems: 'center', justifyContent: 'center' }}
                            style={{ width: windowWidth * 0.1, height: 40, borderWidth: 0}}
                            items={[
                                { label: 'Daily', value: 1 },
                                { label: 'Weekly', value: 2 },
                                { label: 'Monthly', value: 3 },
                                //{ label: 'Hour of day', value: 4 }, 
                                //{ label: 'Day of week', value: 5 }
                            ]}
                            placeholder={'Period'}
                            placeholderStyle={{ color: 'black'}}
                            onChangeItem={selectedSort => {
                                // setState({ sort: selectedSort }),
                                sortingFunc(selectedSort);
                            }
                            }
                        />
                    </View> */}
              <View style={{ flexDirection: 'row', zIndex: -1 }}>
                {/* <TouchableOpacity
                            style={[leftSpacingScale, {
                                marginRight: 10,
                                paddingHorizontal: 15,
                                backgroundColor: Colors.whiteColor,
                                height: 40,
                                // width: 200, 
                                alignItems: 'center',
                                borderRadius: 7,
                                flexDirection: 'row',
                                justifyContent: 'center', shadowColor: '#000',
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,

                                opacity: !showDetails ? 0 : 100,
                                zIndex: -1,
                            }]}
                            onPress={() => {
                                setShowDetails(false);

                                setCurrentPage(1);
                                setPageCount(Math.ceil(dailySalesDetailsList.length / perPage));
                            }}
                            disabled={!showDetails}
                        >
                            <AntDesign name="arrowleft" size={18} color={Colors.primaryColor} style={{
                                top: 1,
                                marginRight: -5,
                            }} />
                            <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, marginLeft: 12 }}>Back to Summary</Text>
                        </TouchableOpacity> */}

                {/* <TouchableOpacity style={{
                            //justifyContent: 'center',
                            //width: 90,
                            marginHorizontal: 10,
                            shadowOpacity: 0,
                            shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 1,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 0.22,
                            elevation: 1,
                        }}
                            onPress={() => {
                                filterPressed(1); setFilterTapped(1); setExpandSelection(!expandSelection);;
                            }}>
                            <View style={{
                                justifyContent: 'center',
                                //width: '100%',
                                flexDirection: 'row',
                                borderWidth: 0,
                                borderColor: '#4E9F7D',
                                borderRadius: 8,
                                height: 40,
                                alignItems: 'center',
                                backgroundColor: '#FFFFFF',
                                //position: 'relative',

                                paddingHorizontal: 15,
                                //backgroundColor: Colors.whiteColor, 
                                //height: 40, 
                                width: 120,
                                //alignItems: 'center', 
                                //borderRadius: 7, 
                                //flexDirection: 'row',
                                //justifyContent: 'center',

                                shadowOffset: {
                                    width: 0,
                                    height: 1,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                            }}>
                                <Feather name='filter' size={18} color={Colors.primaryColor} />
                                <Text style={{
                                    color: '#27353C',
                                    fontSize: 14,
                                    fontFamily: 'Nunitosans-bold',
                                    marginLeft: 7
                                }}>
                                    Filter
                                </Text>
                            </View>
                        </TouchableOpacity> */}

                <View style={{}}>
                  <DateTimePickerModal
                    isVisible={showDateTimePicker}
                    mode={'date'}
                    onConfirm={(text) => {
                      setRev_date(moment(text).startOf('day'));
                      setShowDateTimePicker(false);

                      setSalesLineChartPeriod(CHART_PERIOD.NONE);
                    }}
                    onCancel={() => {
                      setShowDateTimePicker(false);
                    }}
                    maximumDate={moment(rev_date1).toDate()}
                  />
                  <DateTimePickerModal
                    isVisible={showDateTimePicker1}
                    mode={'date'}
                    onConfirm={(text) => {
                      setRev_date1(moment(text).endOf('day'));
                      setShowDateTimePicker1(false);

                      setSalesLineChartPeriod(CHART_PERIOD.NONE);
                    }}
                    onCancel={() => {
                      setShowDateTimePicker1(false);
                    }}
                    minimumDate={moment(rev_date).toDate()}
                  />
                  <DateTimePickerModal
                    isVisible={showDateTimePickerFilter}
                    mode={'date'}
                    onConfirm={(text) => {
                      setSelectedChartFilterQueries([
                        {
                          ...selectedChartFilterQueries[0],
                          fieldDataValue: text,
                        },
                      ]);

                      setShowDateTimePickerFilter(false);
                    }}
                    onCancel={() => {
                      setShowDateTimePickerFilter(false);
                    }}
                  />
                </View>

                {/* <TouchableOpacity style={{ marginRight: 10, width: 230, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.040, backgroundColor: Colors.whiteColor }}
                        onPress={() => { changeClick() }}>
                        <TouchableOpacity style={{ alignSelf: "center" }} onPress={() => { }}>
                            <EvilIcons name="calendar" size={30} color={Colors.primaryColor} />
                        </TouchableOpacity>
                        <Text style={{ fontFamily: "NunitoSans-Regular" }}>{moment(startDate).format("DD MMM yyyy")} - {moment(endDate).format("DD MMM yyyy")}</Text>
                    </TouchableOpacity> */}

                {/*                         <View style={[{
                            marginRight: 10,
                            // paddingLeft: 15,
                            width: 230, flexDirection: 'row',
                            alignItems: 'center', borderRadius: 10, height: 40, justifyContent: 'center',
                            backgroundColor: Colors.whiteColor,
                            shadowOpacity: 0,
                            shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                        }]}
                        >
                            <View style={{ alignSelf: "center" }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                <EvilIcons name="calendar" size={25} color={Colors.primaryColor} />
                            </View>

                            <TouchableOpacity onPress={() => {
                                setShowDateTimePicker(true);
                                setShowDateTimePicker1(false);
                            }} style={{
                                marginHorizontal: 4,
                            }}>
                                <Text style={{ fontFamily: "NunitoSans-Regular" }}>{moment(rev_date).format("DD MMM yyyy")}</Text>
                            </TouchableOpacity>

                            <Text style={{ fontFamily: "NunitoSans-Regular" }}>-</Text>

                            <TouchableOpacity onPress={() => {
                                setShowDateTimePicker(false);
                                setShowDateTimePicker1(true);
                            }} style={{
                                marginHorizontal: 4,
                            }}>
                                <Text style={{ fontFamily: "NunitoSans-Regular" }}>{moment(rev_date1).format("DD MMM yyyy")}</Text>
                            </TouchableOpacity>
                        </View> */}

                {/* <TouchableOpacity style={{ marginRight: 10, width: 120, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.040, backgroundColor: Colors.whiteColor }}
                            onPress={() => { download(list) }}>
                            <AntDesign name="download" size={20} color={Colors.primaryColor} />
                            <Text style={{ fontFamily: "NunitoSans-Regular", marginLeft: 10 }}>Download</Text>
                        </TouchableOpacity> */}

                {/* <TouchableOpacity
                            style={{
                                paddingHorizontal: 15, backgroundColor: Colors.whiteColor, height: 40, width: 120, alignItems: 'center', borderRadius: 7, flexDirection: 'row', justifyContent: 'center', shadowColor: '#000',
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                            }}
                            onPress={() => {
                                // setState({
                                //     visible: true
                                // })
                                setVisible(true);
                            }}
                        >
                            <Upload width={15} height={15} />
                            <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, marginLeft: 12 }}>Email</Text>
                        </TouchableOpacity> */}

                {/* {
                            (pageStack.length > 1 && pageStack[pageStack.length - 2] === 'Dashboard')
                                ?
                                <TouchableOpacity
                                    style={{
                                        marginLeft: 15,
                                        paddingHorizontal: 10, backgroundColor: Colors.whiteColor, height: 40, width: 140, alignItems: 'center', borderRadius: 7, flexDirection: 'row', justifyContent: 'center', shadowColor: '#000',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 1,

                                        marginBottom: 10,
                                    }}
                                    onPress={() => {
                                        navigation.navigate('Dashboard');
                                    }}
                                >
                                    <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, }}>Dashboard</Text>
                                </TouchableOpacity>
                                :
                                <></>
                        } */}
              </View>

              {/* <TouchableOpacity style={{ width: 100, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.040, backgroundColor: Colors.whiteColor }}
                            onPress={() => { email1(list) }}>
                            <AntDesign name="upload" size={20} color={Colors.primaryColor} />
                            <Text style={{ fontFamily: "NunitoSans-Regular", marginLeft: 10 }}>Email</Text>
                        </TouchableOpacity> */}
            </View>

            <View style={{ width: '100%', marginBottom: 30, zIndex: -1 }}>
              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  width: windowWidth * 0.87,
                  minHeight: windowHeight * 0.01,
                  marginTop: 10,
                  marginHorizontal: 30,
                  marginBottom: 15,
                  alignSelf: 'center',
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}>
                <View
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                    marginVertical: 10,
                    marginBottom: 0,
                    paddingRight: 20,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    // ...(pageStack.length > 1 && pageStack[pageStack.length - 2] === 'Dashboard') && {
                    //     display: 'none',
                    //     marginTop: 20,
                    // },
                  }}>
                  <View style={{ flexDirection: 'row', width: '20%' }}>
                    <View
                      style={{
                        width: '100%',
                        flexDirection: 'row',
                        marginVertical: 10,
                        marginBottom: 20,
                        alignItems: 'center',
                      }}>
                      <Picker
                        style={{
                          width: switchMerchant ? 140 : 250,
                          paddingVertical: 0,
                          backgroundColor: Colors.fieldtBgColor,
                          borderRadius: 5,
                          marginLeft: 15,
                          paddingLeft: 10,
                        }}
                        onValueChange={(item) => {
                          setSelectedChartDropdownValueLineChart(item.value);
                        }}
                        selectedValue={selectedChartDropdownValueLineChart}
                      >
                        {
                          CHART_Y_AXIS_DROPDOWN_LIST[
                            CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                          ].map((value, index) => {
                            return (
                              <Picker.Item
                                key={index}
                                label={value.label}
                                value={value.value}
                              />
                            )
                          })
                        }
                      </Picker>

                      <View
                        style={{
                          flexDirection: 'row',
                          marginLeft: switchMerchant ? 10 : 20,
                        }}>
                        <View
                          style={{
                            paddingHorizontal: 15,
                            flexDirection: 'row',
                            alignItems: 'center',
                            borderRadius: 10,
                            paddingVertical: 10,
                            justifyContent: 'center',
                            backgroundColor: Colors.whiteColor,
                            shadowOpacity: 0,
                            shadowColor: '#000',
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: 1,
                          }}>
                          <View
                            style={{ alignSelf: 'center', marginRight: 5 }}
                            onPress={() => {
                              setState({
                                pickerMode: 'date',
                                showDateTimePicker: true,
                              });
                            }}>
                            <GCalendar
                              width={switchMerchant ? 15 : 20}
                              height={switchMerchant ? 15 : 20}
                            />
                          </View>

                          <DatePicker
                            selected={rev_date.toDate()}
                            onChange={(date) => {
                              setRev_date(moment(date).startOf('day'));

                              setSalesLineChartPeriod(CHART_PERIOD.NONE);
                            }}
                            maxDate={moment(rev_date1).toDate()}
                          />

                          <Text
                            style={{
                              fontFamily: "NunitoSans-Regular",
                              paddingHorizontal: 5,
                              marginLeft: 5,
                            }}
                          >
                            -
                          </Text>

                          <DatePicker
                            selected={rev_date1.toDate()}
                            onChange={(date) => {
                              setRev_date1(moment(date).endOf('day'));

                              setSalesLineChartPeriod(CHART_PERIOD.NONE);
                            }}
                            minDate={moment(rev_date).toDate()}
                          />
                        </View>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      zIndex: -1,
                    }}>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.TODAY
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.TODAY
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.TODAY);
                        setRev_date(moment().startOf('day'));
                        setRev_date1(moment().endOf('day'));
                      }}>
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.TODAY
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.TODAY
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }>
                        TODAY
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.THIS_WEEK);
                        setRev_date(moment(Date.now()).subtract(7, 'days').startOf('day'));
                        setRev_date1(moment(Date.now()).endOf('day'));
                      }}>
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }>
                        1W
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH);
                        setRev_date(moment().startOf('month').startOf('day'));
                        setRev_date1(moment().endOf('month').endOf('day'));
                      }}>
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }>
                        1M
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.THREE_MONTHS);
                        setRev_date(moment(Date.now()).subtract(3, 'months').startOf('day'));
                        setRev_date1(moment(Date.now()).endOf('day'));
                      }}>
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod ==
                                  CHART_PERIOD.THREE_MONTHS
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod ==
                                  CHART_PERIOD.THREE_MONTHS
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }>
                        3M
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.SIX_MONTHS);
                        setRev_date(moment(Date.now()).subtract(6, 'month').startOf('day'));
                        setRev_date1(moment(Date.now()).endOf('day'));
                      }}>
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }>
                        6M
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.THIS_YEAR);
                        setRev_date(moment().startOf('year').startOf('day'));
                        setRev_date1(moment().endOf('year').endOf('date'));
                      }}>
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }>
                        1Y
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.YTD
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.YTD
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.YTD);
                        setRev_date(moment(Date.now()).subtract(12, 'months').startOf('day'));
                        setRev_date1(moment(Date.now()).endOf('day'));
                      }}>
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.YTD
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: 'NunitoSans-Bold',
                              color:
                                salesLineChartPeriod == CHART_PERIOD.YTD
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }>
                        YTD
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    alignSelf: 'flex-start',
                    marginLeft: '3%',
                    marginBottom: '1%',
                    zIndex: -1,
                  }}></View>
                {console.log('outletSalesData', outletSalesData)}
                <View
                  style={{
                    zIndex: -1,
                    paddingLeft: 5
                  }}>
                  <FusionCharts
                    {...{
                      width: "100%",
                      height: windowHeight * 0.5,
                      type: CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].type,
                      dataFormat: salesLineChart.dataFormat,
                      dataSource: salesLineChart.dataSource,
                    }
                    }
                  />
                </View>
              </View>
              {showDetails ? (
                <TouchableOpacity
                  style={{
                    marginLeft: switchMerchant ? 0 : windowWidth <= 1823 && windowWidth >= 1820 ? 24 : 10,
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: '#4E9F7D',
                    borderRadius: 5,
                    width: switchMerchant ? 90 : 120,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: 'center',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    opacity: !showDetails ? 0 : 100,
                  }}
                  onPress={() => {
                    setShowDetails(false);
                    setCurrentPage(pageReturn);
                    console.log('Returning to page');
                    console.log(pageReturn);
                    setCurrReportSummarySort('');
                    setCurrReportDetailsSort('');
                  }}
                  disabled={!showDetails}>
                  <AntDesign
                    name="arrowleft"
                    size={switchMerchant ? 10 : 20}
                    color={Colors.whiteColor}
                    style={{
                      // top: -1,
                      //marginRight: -5,
                    }}
                  />
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      marginLeft: 5,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                      marginBottom: Platform.OS === 'ios' ? 0 : 2
                    }}>
                    Summary
                  </Text>
                </TouchableOpacity>
              ) : (
                <></>
              )}
              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  width: windowWidth * 0.87,
                  height:
                    Platform.OS == 'android'
                      ? windowHeight * 0.52
                      : windowHeight * 0.66,
                  marginTop: 15,
                  marginHorizontal: 30,
                  marginBottom: 10,
                  alignSelf: 'center',
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}>
                {/* <View style={{ height: '82%', position: 'absolute', justifyContent: 'space-between', zIndex: 10, marginVertical: 0, marginTop: 80, alignSelf: 'center' }}>
                            <TouchableOpacity
                                onPress={() => {
                                    ScrollToTop();
                                }}
                                style={{ alignSelf: 'center', marginTop: '8%', zIndex: 10 }}>
                                <AntDesign name={'upcircle'} size={23} color={Colors.primaryColor} style={{ opacity: 0.4 }} />
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => {
                                    ScrollToBottom();
                                }}
                                style={{ alignSelf: 'center', marginTop: '42%', zIndex: 10 }}>
                                <AntDesign name={'downcircle'} size={23} color={Colors.primaryColor} style={{ opacity: 0.4 }} />
                            </TouchableOpacity>
                        </View> */}
                {showDetails ? (
                  <View style={{ flexDirection: 'row' }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        //justifyContent: 'flex-end',
                        // marginBottom: 5,
                        // marginTop: 5,
                        width: '100%',
                        //width: windowWidth * 0.1,
                        alignSelf: 'flex-end',
                      }}>
                      <Text
                        style={{
                          marginLeft: 10,
                          marginTop: 10,
                          fontFamily: 'NunitoSans-Regular',
                          fontSize: switchMerchant ? 16 : 22,
                          alignSelf: 'center',
                        }}>
                        {detailDate}
                      </Text>
                    </View>
                  </View>
                ) : null}
                {!showDetails ? (
                  <View
                    style={{
                      marginTop: 10,
                      flexDirection: 'row',
                      paddingHorizontal: 3,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '5%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                              textAlign: 'left',
                            }}>
                            {'No.\n'}
                          </Text>
                        </View>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 8 : 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                      {/* <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View> */}
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '12%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC,
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC,
                              );
                            }
                          }}>
                          <View
                            style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                                alignItems: 'center',
                              }}>
                              {'Date\n'}
                            </Text>
                          </View>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '9%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC,
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Sales\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '8%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC,
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                              textAlign: 'left',
                            }}>
                            {'Order\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            Qty
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '8%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC,
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                              textAlign: 'left',
                            }}>
                            {'Disc\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '8%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.DISCOUNT_DESC,
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Disc\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            %
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '8%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TAX_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TAX_DESC,
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TAX_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Tax\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TAX_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TAX_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '10%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_DESC,
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                              textAlign: 'left',
                            }}>
                            {'Service\nCharge'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    {/*  <View style={{ flexDirection: 'row', flex: 1, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <TouchableOpacity onPress={() => {
                                                if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.GP_ASC) {
                                                    setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.GP_DESC)
                                                }
                                                else {
                                                    setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.GP_ASC)
                                                }
                                            }}>
                                                <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>GP</Text>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>%</Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>
                                            <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.GP_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                            <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.GP_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                        </View>
                                    </View> */}
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '10%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_DESC,
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                              textAlign: 'left',
                            }}>
                            {'Sales\nReturn'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '11%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.NET_SALES_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.NET_SALES_DESC,
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.NET_SALES_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Net\nSales'}
                          </Text>
                          <View style={{ flexDirection: 'row' }}>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              RM
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.primaryColor,
                              }}>
                              {' '}
                              *incl tax
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.NET_SALES_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.NET_SALES_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '11%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_DESC,
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                              textAlign: 'left',
                            }}>
                            {'Avg\nNet Sales'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>

                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View style={{ marginTop: 20, flexDirection: 'row' }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '6%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <View
                          style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'No.\n'}
                          </Text>
                        </View>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 8 : 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                      {/* <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View> */}
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '13%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC,
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC,
                              );
                            }
                          }}>
                          <View
                            style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {'Time\n'}
                            </Text>
                          </View>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}></Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    {/* <View style={{ flexDirection: 'row', flex: 2.2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Payment Method</Text>
                                            <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}></Text>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_PAYMENT_METHOD_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_PAYMENT_METHOD_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View> */}
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '10%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC,
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Sales\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    {/* <View style={{ flexDirection: 'row', flex: 2.7, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Transaction Time</Text>
                                        <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                    </View>
                                </View> */}
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '9%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC,
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Disc\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '9%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC,
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Disc\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            %
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '9%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC,
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Tax\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    {/* <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Tax</Text>
                                            <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                        </View>

                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View> */}
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '14%',
                        borderRightWidth: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_DESC,
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Service\nCharge'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            %
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '16%',
                        // borderRightWidth: 1,
                        // borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_DESC,
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Sales Return\n'}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}>
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        width: '14%',
                        borderRightWidth: 0,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: 10,
                        borderLeftWidth: 1,
                        borderLeftColor: 'lightgrey',
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC,
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC,
                              );
                            }
                          }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {'Net Sales\n'}
                          </Text>
                          <View style={{ flexDirection: 'row' }}>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.descriptionColor,
                              }}>
                              RM
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 8 : 10,
                                color: Colors.primaryColor,
                              }}>
                              {' '}
                              *incl tax
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: '3%' }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}></Text>
                      </View>
                    </View>
                    {/* <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                    <View style={{ flexDirection: 'column' }}>
                                        <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Average Net</Text>
                                        <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                    </View>
                                </View> */}
                  </View>
                )}

                {!showDetails ? (
                  <>
                    {dailySalesDetailsList.length > 0 ? (
                      <FlatList
                        showsVerticalScrollIndicator={false}
                        ref={flatListRef}
                        data={sortReportDataList(
                          dailySalesDetailsList,
                          currReportSummarySort,
                        )
                          .filter((item) => {
                            const search = search2.toLowerCase();

                            if (search2 !== '') {
                              if (item.dateTime.toLowerCase().includes(search)) {
                                return true;
                              } else if (
                                item.totalSales
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(search)
                              ) {
                                return true;
                              } else if (
                                item.totalTransactions
                                  .toString()
                                  .toLowerCase()
                                  .includes(search)
                              ) {
                                return true;
                              } else if (
                                item.totalDiscount
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(search)
                              ) {
                                return true;
                              } else if (
                                item.discount
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(search)
                              ) {
                                return true;
                              } else if (
                                item.tax
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(search)
                              ) {
                                return true;
                              } else if (
                                item.serviceCharge
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(search)
                              ) {
                                return true;
                              } else if (
                                item.totalSalesReturn
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(search)
                              ) {
                                return true;
                              } else if (
                                item.netSales
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(search)
                              ) {
                                return true;
                              } else if (
                                item.averageNetSales
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(search)
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          })
                          .slice(
                            (currentPage - 1) * perPage,
                            currentPage * perPage,
                          )}
                        // extraData={renderItem}
                        renderItem={renderItem}
                        keyExtractor={(item, index) => String(index)}
                        style={{ marginTop: 10 }}
                        initialNumToRender={8}
                      />
                    ) : (
                      <View
                        style={{ height: windowHeight * 0.4 }}>
                        <View
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                          }}>
                          <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                ) : (
                  <>
                    {transactionTypeSalesDetails.length > 0 ? (
                      <FlatList
                        showsVerticalScrollIndicator={false}
                        ref={flatListRef}
                        //data and extraData using dummy data
                        data={sortReportDataList(
                          transactionTypeSalesDetails,
                          currReportDetailsSort,
                        )
                          .filter((item) => {
                            const Search = search2.toLowerCase();

                            if (search2 !== '') {
                              if (
                                item.totalPrice
                                  .toString()
                                  .toLowerCase()
                                  .includes(Search)
                              ) {
                                return true;
                              } else if (
                                item.completedDate
                                  .toString()
                                  .toLowerCase()
                                  .includes(Search)
                              ) {
                                return true;
                              } else if (
                                item.discount
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(Search)
                              ) {
                                return true;
                              } else if (
                                item.discountPercentage
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(Search)
                              ) {
                                return true;
                              } else if (
                                item.tax
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(Search)
                              ) {
                                return true;
                              } else if (
                                (Math.ceil(item.finalPrice * 20 - 0.05) / 20)
                                  .toFixed(2)
                                  .toString()
                                  .toLowerCase()
                                  .includes(Search)
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          })
                          .slice(
                            (currentDetailsPage - 1) * perPage,
                            currentDetailsPage * perPage,
                          )}
                        // extraData={renderItem}
                        renderItem={renderItemDetails}
                        keyExtractor={(item, index) => String(index)}
                        style={{ marginTop: 10 }}
                        initialNumToRender={8}
                      />
                    ) : (
                      <View
                        style={{ height: windowHeight * 0.4 }}>
                        <View
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                          }}>
                          <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                )}
              </View>

              {!showDetails ? (
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: 10,
                    width: windowWidth * 0.87,
                    alignItems: 'center', alignSelf: 'center',
                    justifyContent: 'flex-end',
                    // top:
                    //   Platform.OS == 'ios'
                    //     ? pushPagingToTop && keyboardHeight > 0
                    //       ? -keyboardHeight * 0.95
                    //       : 0
                    //     : 0,
                    // backgroundColor: pushPagingToTop && keyboardHeight > 0 ? Colors.highlightColor : null,
                    // borderWidth: pushPagingToTop && keyboardHeight > 0 ? 1 : 0,
                    // borderColor: pushPagingToTop && keyboardHeight > 0 ? '#E5E5E5' : null,
                    // borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                    // paddingHorizontal:
                    //   pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                    // shadowOffset: {
                    //     width: 0,
                    //     height: 1,
                    // },
                    // shadowOpacity: 0.22,
                    // shadowRadius: 3.22,
                    // elevation: 1,
                    paddingBottom: 15,
                  }}>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold',
                      marginRight: '1%',
                    }
                    }>
                    Items Showed
                  </Text>
                  <View style={{
                    width: Platform.OS === 'ios' ? 65 : '13%', //65,
                    height: switchMerchant ? 20 : 35,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    justifyContent: 'center',
                    paddingHorizontal: Platform.OS === 'ios' ? 0 : 0,
                    //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                    // paddingTop: '-60%',
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    marginRight: '1%',
                  }}>
                    <Picker
                      style={{
                        inputIOS: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', textAlign: 'center' },
                        inputAndroid: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', justifyContent: 'center', textAlign: 'center', height: 40, color: 'black' },
                        inputAndroidContainer: { width: '100%' },
                        //backgroundColor: 'red',
                        height: 35,

                        chevronContainer: {
                          display: "none",
                        },
                        chevronDown: {
                          display: "none",
                        },
                        chevronUp: {
                          display: "none",
                        },
                      }}
                      onValueChange={(value) => {
                        setPerPage(value);
                      }}
                    >
                      {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                        label: 'All',
                        value: !showDetails
                          ? dailySalesDetailsList.length
                          : transactionTypeSalesDetails.length,
                      }).map((value, index) => {
                        return (
                          <Picker.Item
                            label={value.label}
                            value={value.value}
                          />
                        )
                      })
                      }
                    </Picker>
                  </View>

                  <Text
                    style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%' }
                    }>
                    Page
                  </Text>
                  <View
                    style={{
                      width: switchMerchant ? 65 : 70,
                      height: switchMerchant ? 20 : 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: 'center',
                      paddingHorizontal: 22,
                      borderWidth: 1,
                      borderColor: '#E5E5E5',
                    }}>
                    {console.log('currentPage')}
                    {console.log(currentPage)}

                    <TextInput
                      onChangeText={(text) => {
                        var currentPageTemp =
                          text.length > 0 ? parseInt(text) : 1;

                        setCurrentPage(
                          currentPageTemp > pageCount
                            ? pageCount
                            : currentPageTemp < 1
                              ? 1
                              : currentPageTemp,
                        );
                      }}
                      placeholder={currentPage.toString()}
                      placeholderTextColor={Colors.descriptionColor}
                      style={{
                        color: 'black',
                        // fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular',
                        marginTop: Platform.OS === 'ios' ? 0 : -15,
                        marginBottom: Platform.OS === 'ios' ? 0 : -15,
                        textAlign: 'center',
                        width: '100%',
                      }}
                      value={currentPage.toString()}
                      defaultValue={currentPage.toString()}
                      keyboardType={'numeric'}
                      onFocus={() => {
                        setPushPagingToTop(true);
                      }}
                    />
                  </View>
                  <Text
                    style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginLeft: '1%', marginRight: '1%' }
                    }>
                    of {pageCount}
                  </Text>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onPress={() => {
                      prevPage();
                    }}>
                    {/* <MaterialIcons
                    name="keyboard-arrow-left"
                    size={switchMerchant ? 20 : 25}
                    style={{ color: Colors.whiteColor }}
                  /> */}
                    <ArrowLeft
                      color={Colors.whiteColor}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onPress={() => {
                      nextPage();
                    }}>
                    <ArrowRight
                      color={Colors.whiteColor}
                    />
                  </TouchableOpacity>
                </View>
              ) : (
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: 10,
                    width: windowWidth * 0.87,
                    alignItems: 'center', alignSelf: 'center',
                    justifyContent: 'flex-end',
                    // top:
                    //   Platform.OS == 'ios'
                    //     ? pushPagingToTop && keyboardHeight > 0
                    //       ? -keyboardHeight * 0.95
                    //       : 0
                    //     : 0,
                    // backgroundColor: pushPagingToTop && keyboardHeight > 0 ? Colors.highlightColor : null,
                    // borderWidth: pushPagingToTop && keyboardHeight > 0 ? 1 : 0,
                    // borderColor: pushPagingToTop && keyboardHeight > 0 ? '#E5E5E5' : null,
                    // borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                    // paddingHorizontal:
                    //   pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                    // shadowOffset: {
                    //     width: 0,
                    //     height: 1,
                    // },
                    // shadowOpacity: 0.22,
                    // shadowRadius: 3.22,
                    // elevation: 1,
                    paddingBottom: 15,
                  }}>
                  <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%', }}>
                    Items Showed
                  </Text>
                  <View style={{
                    width: Platform.OS === 'ios' ? 65 : '13%', //65,
                    height: switchMerchant ? 20 : 35,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    justifyContent: 'center',
                    paddingHorizontal: Platform.OS === 'ios' ? 0 : 0,
                    //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                    // paddingTop: '-60%',
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    marginRight: '1%',
                  }}>
                    <Picker
                      style={{
                        inputIOS: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', textAlign: 'center' },
                        inputAndroid: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', justifyContent: 'center', textAlign: 'center', height: 40, color: 'black' },
                        inputAndroidContainer: { width: '100%' },
                        //backgroundColor: 'red',
                        height: 35,

                        chevronContainer: {
                          display: "none",
                        },
                        chevronDown: {
                          display: "none",
                        },
                        chevronUp: {
                          display: "none",
                        },
                      }}
                      onValueChange={(value) => {
                        setPerPage(value);
                      }}
                    >
                      {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                        label: 'All',
                        value: !showDetails
                          ? dailySalesDetailsList.length
                          : transactionTypeSalesDetails.length,
                      }).map((value, index) => {
                        return (
                          <Picker.Item
                            label={value.label}
                            value={value.value}
                          />
                        )
                      })
                      }
                    </Picker>
                  </View>

                  <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%' }}>
                    Page
                  </Text>
                  <View
                    style={{
                      width: switchMerchant ? 65 : 70,
                      height: switchMerchant ? 20 : 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: 'center',
                      paddingHorizontal: 22,
                      borderWidth: 1,
                      borderColor: '#E5E5E5',
                    }}>
                    {console.log('currentDetailsPage')}
                    {console.log(currentDetailsPage)}

                    <TextInput
                      onChangeText={(text) => {
                        var currentPageTemp =
                          text.length > 0 ? parseInt(text) : 1;

                        setCurrentDetailsPage(
                          currentPageTemp > pageCount
                            ? pageCount
                            : currentPageTemp < 1
                              ? 1
                              : currentPageTemp,
                        );
                      }}
                      placeholder={currentDetailsPage.toString()}
                      placeholderTextColor={Colors.descriptionColor}
                      style={{
                        color: 'black',
                        // fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular',
                        marginTop: Platform.OS === 'ios' ? 0 : -15,
                        marginBottom: Platform.OS === 'ios' ? 0 : -15,
                        textAlign: 'center',
                        width: '100%',
                      }}
                      value={currentDetailsPage.toString()}
                      defaultValue={currentDetailsPage.toString()}
                      keyboardType={'numeric'}
                      onFocus={() => {
                        setPushPagingToTop(true);
                      }}
                    />
                  </View>
                  <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginLeft: '1%', marginRight: '1%' }}>
                    of {pageCount}
                  </Text>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onPress={() => {
                      prevDetailsPage();
                    }}>
                    <ArrowLeft
                      color={Colors.whiteColor}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onPress={() => {
                      nextDetailsPage();
                    }}>
                    <ArrowRight
                      color={Colors.whiteColor}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>

            <View style={{ marginTop: 20 }}></View>
            {/* <Modal style={{ flex: 1 }} visible={visible} transparent={true} animationType="slide">
                    <View
                        style={{
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: Dimensions.get('window').height,
                        }}>
                        <View style={styles.confirmBox}>
                            <View style={{ flex: 3, borderBottomWidth: StyleSheet.hairlineWidth, justifyContent: 'center', alignItems: 'center' }}>
                                <View style={{ flex: 1, justifyContent: 'center' }}>
                                    <Text style={{ fontWeight: '700', fontSize: 24, textAlign: 'center' }}>Select category</Text>
                                </View>
                                <View style={{ flexDirection: 'row', flex: 1, }}>
                                    <CheckBox
                                        style={{
                                            padding: 10, flex: 1, alignSelf: 'center', marginLeft: '20%'
                                        }}
                                        onClick={() => {
                                            setState({
                                                isChecked: !isChecked,
                                            });
                                        }}
                                        checkBoxColor={Colors.fieldtBgColor}
                                        uncheckedCheckBoxColor={Colors.tabGrey}
                                        checkedCheckBoxColor={Colors.primaryColor}
                                        isChecked={isChecked}
                                        rightText={'Category'}
                                        rightTextStyle={{
                                            fontSize: 15,
                                            fontWeight: '700',
                                            color: Colors.descriptionColor,
                                        }}
                                    />
                                    <CheckBox
                                        style={{
                                            padding: 10, flex: 1, alignSelf: 'center', marginLeft: '5%'
                                        }}
                                        onClick={() => {
                                            setState({
                                                isChecked1: !isChecked1,
                                            });
                                        }}
                                        checkBoxColor={Colors.fieldtBgColor}
                                        uncheckedCheckBoxColor={Colors.tabGrey}
                                        checkedCheckBoxColor={Colors.primaryColor}
                                        isChecked={isChecked1}
                                        rightText={'Tag'}
                                        rightTextStyle={{
                                            fontSize: 15,
                                            fontWeight: '700',
                                            color: Colors.descriptionColor,
                                        }}
                                    />
                                </View>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <TouchableOpacity style={{ flex: 1, borderRightWidth: StyleSheet.hairlineWidth, justifyContent: 'center' }} onPress={() => { }}>
                                    <Text style={{ color: Colors.primaryColor, fontSize: 24, fontWeight: '400', textAlign: 'center' }}>Download</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ flex: 1, justifyContent: 'center' }} onPress={() => { setState({ visible: !visible }); }}>
                                    <Text style={{ color: Colors.descriptionColor, fontSize: 24, fontWeight: '400', textAlign: 'center' }}>Cancel</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Modal> */}
            <Modal
              style={{ flex: 1 }}
              visible={visible1}
              transparent={true}
              animationType="slide">
              <View
                style={{
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: Dimensions.get('window').height,
                }}>
                <View style={styles.confirmBox}>
                  <View
                    style={{
                      flex: 3,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <View style={{ flex: 1, justifyContent: 'center' }}>
                      <Text
                        style={{
                          fontWeight: '700',
                          fontSize: 24,
                          textAlign: 'center',
                        }}>
                        Select category
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', flex: 1 }}>
                      <CheckBox
                        style={{
                          padding: 10,
                          flex: 1,
                          alignSelf: 'center',
                          marginLeft: '20%',
                        }}
                        onClick={() => {
                          setState({
                            isChecked: !isChecked,
                          });
                        }}
                        checkBoxColor={Colors.fieldtBgColor}
                        uncheckedCheckBoxColor={Colors.tabGrey}
                        checkedCheckBoxColor={Colors.primaryColor}
                        isChecked={isChecked}
                        rightText={'Category'}
                        rightTextStyle={{
                          fontSize: 15,
                          fontWeight: '700',
                          color: Colors.descriptionColor,
                        }}
                      />
                      <CheckBox
                        style={{
                          padding: 10,
                          flex: 1,
                          alignSelf: 'center',
                          marginLeft: '5%',
                        }}
                        onClick={() => {
                          setState({
                            isChecked1: !isChecked1,
                          });
                        }}
                        checkBoxColor={Colors.fieldtBgColor}
                        uncheckedCheckBoxColor={Colors.tabGrey}
                        checkedCheckBoxColor={Colors.primaryColor}
                        isChecked={isChecked1}
                        rightText={'Tag'}
                        rightTextStyle={{
                          fontSize: 15,
                          fontWeight: '700',
                          color: Colors.descriptionColor,
                        }}
                      />
                    </View>
                  </View>
                  {/* <View style={{ flex: 1, flexDirection: 'row' }}>
                                <TouchableOpacity style={{ flex: 1, borderRightWidth: StyleSheet.hairlineWidth, justifyContent: 'center' }} onPress={() => { }}>
                                    <Text style={{ color: Colors.primaryColor, fontSize: 24, fontWeight: '400', textAlign: 'center' }}>Email</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ flex: 1, justifyContent: 'center' }} onPress={() => { setState({ visible1: !visible1 }); }}>
                                    <Text style={{ color: Colors.descriptionColor, fontSize: 24, fontWeight: '400', textAlign: 'center' }}>Cancel</Text>
                                </TouchableOpacity>
                            </View> */}
                </View>
              </View>
            </Modal>
          </View>
        </ScrollView>
      </View>

      {expandSelection ? (
        <View style={[styles.ManageFilterBox]}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              margin: 10,
            }}>
            <Text
              style={{
                fontSize: 16,
                Colors: '#27353C',
                fontFamily: 'Nunitosans-bold',
              }}>
              Manage Filter
            </Text>
          </View>
          <View
            style={{
              borderWidth: 0.5,
              borderColor: '#D2D2D2',
              width: '100%',
              marginTop: 5,
              marginBottom: 5,
            }}></View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: 10,
              height: 35,
            }}>
            <Picker
              arrowColor={'#BDBDBD'}
              arrowSize={23}
              arrowStyle={{ fontWeight: 'bold' }}
              style={{ width: 250, height: 35 }}
              // placeholder={'Field name'}
              // placeholderStyle={{ color: 'black' }}
              dropDownStyle={{
                width: 250,
              }}
              itemStyle={{
                justifyContent: 'flex-start',
              }}
              globalTextStyle={{
                fontFamily: 'NunitoSans-SemiBold',
                // fontSize: 12,
                color: Colors.fontDark,
                marginLeft: 5,
              }}
              onChangeItem={(item) => {
                // setState({ sort: selectedSort }),
                //sortingFunc(selectedSort);
                setSelectedChartFilterQueries([
                  {
                    ...selectedChartFilterQueries[0],
                    fieldNameKey: item.value,
                    fieldNameType: item.fieldType,
                    fieldDataValue: null,
                  },
                ]);
              }}
              defaultValue={selectedChartFilterQueries[0].fieldNameKey}
              listMode={'SCROLLVIEW'}
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            >
              {
                CHART_FIELD_NAME_DROPDOWN_LIST[
                  CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                ].map((value, index) => {
                  return (
                    <Picker.Item
                      label={value.label}
                      value={value.value}
                    />
                  )
                })
              }
            </Picker>
            <Picker
              arrowColor={'#BDBDBD'}
              arrowSize={23}
              arrowStyle={{ fontWeight: 'bold' }}
              style={{ marginLeft: '5%', width: 150 }}
              // placeholder={'Field name'}
              // placeholderStyle={{ color: 'black' }}
              dropDownStyle={{
                width: 150,
                marginLeft: '5%',
              }}
              itemStyle={{
                justifyContent: 'flex-start',
              }}
              globalTextStyle={{
                fontFamily: 'NunitoSans-SemiBold',
                // fontSize: 12,
                color: Colors.fontDark,
                marginLeft: 5,
              }}
              onChangeItem={(item) => {
                // setState({ sort: selectedSort }),
                //sortingFunc(selectedSort);
                setSelectedChartFilterQueries([
                  {
                    ...selectedChartFilterQueries[0],
                    fieldCompare: item.value,
                    fieldDataValue: null,
                  },
                ]);
              }}
              defaultValue={selectedChartFilterQueries[0].fieldCompare}
              listMode={'SCROLLVIEW'}
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            >
              {
                CHART_FIELD_COMPARE_DROPDOWN_LIST[
                  CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                ].map((value, index) => {
                  return (
                    <Picker.Item
                      label={value.label}
                      value={value.value}
                    />
                  )
                })
              }
            </Picker>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: 10,
              height: 35,
              zIndex: -1,
            }}>
            <TextInput
              style={{
                width: 410,
                borderWidth: 1,
                borderColor: '#D2D2D2',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'black',
                fontFamily: 'NunitoSans-Regular',
                borderRadius: 8,
                paddingVertical: 3,
                paddingLeft: 15,
                height: 35,
              }}
              {...(selectedChartFilterQueries[0].fieldNameType ===
                CHART_FIELD_TYPE.DATETIME && {
                onPressIn: () => {
                  setShowDateTimePickerFilter(true);
                },
              })}
              editable={
                selectedChartFilterQueries[0].fieldNameType ===
                  CHART_FIELD_TYPE.DATETIME
                  ? false
                  : true
              }
              placeholder="Field Value"
              placeholderStyle={{
                color: 'black',
                justifyContent: 'center',
                alignItems: 'center',
                fontFamily: 'NunitoSans-Regular',
              }}
              defaultValue={
                selectedChartFilterQueries[0].fieldNameType ===
                  CHART_FIELD_TYPE.DATETIME
                  ? moment(
                    selectedChartFilterQueries[0].fieldDataValue,
                  ).format('DD/MM/YYYY')
                  : selectedChartFilterQueries[0].fieldDataValue
                    ? selectedChartFilterQueries[0].fieldDataValue
                    : ''
              }
              onChangeText={(text) => {
                setSelectedChartFilterQueries([
                  {
                    ...selectedChartFilterQueries[0],
                    fieldDataValue: text,
                  },
                ]);
              }}
              keyboardType={
                selectedChartFilterQueries[0].fieldNameType ===
                  CHART_FIELD_TYPE.STRING
                  ? 'default'
                  : 'numeric'
              }
            />
          </View>

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: 40,
              margin: 10,
              zIndex: -2,
            }}>
            {/* <TouchableOpacity style={{
                            justifyContent: 'center',
                            width: '40%'
                        }}
                            onPress={() => {
                                setAppliedChartFilterQueries(selectedChartFilterQueries);
                            }}>
                            <View style={{
                                justifyContent: 'center',
                                width: '100%',
                                flexDirection: 'row',
                                borderWidth: 1,
                                borderColor: '#E0E0E0',
                                borderRadius: 5,
                                height: 35,
                                alignItems: 'center',
                                backgroundColor: '#FFFFFF',
                                height: windowHeight * 0.040
                            }}>
                                <Text style={{
                                    color: '#27353C',
                                    fontSize: 14,
                                    fontFamily: 'Nunitosans-bold',
                                    marginLeft: 7
                                }}>
                                    Add New Filter
                                </Text>
                            </View>
                        </TouchableOpacity> */}
          </View>
          <View
            style={{
              borderWidth: 0.5,
              borderColor: '#D2D2D2',
              width: '100%',
              marginTop: 5,
              marginBottom: 5,
              zIndex: -1,
            }}></View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              margin: 10,
              zIndex: -1,
            }}>
            <TouchableOpacity
              style={{
                justifyContent: 'center',
                width: '30%',
              }}
              onPress={() => {
                setExpandSelection(false);

                setAppliedChartFilterQueries([]);
              }}>
              <View
                style={{
                  justifyContent: 'center',
                  width: '100%',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: '#BDBDBD',
                  borderRadius: 5,
                  height: 35,
                  alignItems: 'center',
                  backgroundColor: '#FFFFFF',
                  height: windowHeight * 0.05,
                }}>
                <Text
                  style={{
                    color: '#BDBDBD',
                    fontSize: 15,
                    fontFamily: 'Nunitosans-bold',
                    marginLeft: 7,
                  }}>
                  CANCEL
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                justifyContent: 'center',
                width: '30%',
                marginLeft: '3%',
              }}
              onPress={() => {
                setExpandSelection(false);

                setAppliedChartFilterQueries(selectedChartFilterQueries);
              }}>
              <View
                style={{
                  justifyContent: 'center',
                  width: '100%',
                  flexDirection: 'row',
                  backgroundColor: '#4E9F7D',
                  borderRadius: 5,
                  height: 35,
                  alignItems: 'center',
                  height: windowHeight * 0.05,
                }}>
                <Text
                  style={{
                    color: '#FFFFFF',
                    fontSize: 15,
                    fontFamily: 'Nunitosans-bold',
                    marginLeft: 7,
                  }}>
                  APPLY
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
  },
  sidebar: {
    width: Dimensions.get('screen').width * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  textInput: {
    width: 425,
    padding: 5,
    backgroundColor: Colors.whiteColor,
    borderRadius: 5,
    marginLeft: 20,
    marginTop: 10,
    borderWidth: StyleSheet.hairlineWidth,
  },
  textInput1: {
    width: 70,
    height: 10,
    backgroundColor: Colors.whiteColor,
    marginLeft: 10,
    borderWidth: StyleSheet.hairlineWidth,
  },
  content: {
    padding: 20,
    // width: Dimensions.get('window').width - 140,
    width: Dimensions.get('screen').width * (1 - Styles.sideBarWidth),
    backgroundColor: Colors.highlightColor,
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  confirmBox: {
    width: '30%',
    height: '30%',
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
  },
  confirmBox1: {
    width: Dimensions.get('screen').width * 0.4,
    height: Dimensions.get('screen').height * 0.4,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: 'space-between',
  },
  /* ManageFilterBox: {
        width: windowWidth * 0.35,
        height: windowHeight * 0.35,
        borderRadius: 10,
        backgroundColor: Colors.whiteColor,
        justifyContent: 'space-between'
    }, */
  ManageFilterBox: {
    //width: windowWidth * 0.27,
    height:
      Platform.OS === 'ios' ? 240 : Dimensions.get('screen').height * 0.25,
    width:
      Platform.OS === 'ios'
        ? Dimensions.get('screen').width * 0.42
        : Dimensions.get('screen').width * 0.33,
    position: 'absolute',
    marginTop: Platform.OS === 'ios' ? '17%' : '13%',
    marginLeft: Platform.OS === 'ios' ? '10%' : '10%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 10,
    zIndex: 1000,
    borderRadius: 10,
    borderTopLeftRadius: 0,
    backgroundColor: Colors.whiteColor,
    borderWidth: 1,
    borderColor: '#E5E5E5',
    //justifyContent: 'space-between'
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalView: {
    height: Dimensions.get('screen').width * 0.2,
    width: Dimensions.get('screen').width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get('screen').width * 0.03,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: Dimensions.get('screen').width * 0.02,
    top: Dimensions.get('screen').width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: 'center',
    top: '20%',
    position: 'absolute',
  },
  modalBody: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitleText: {
    fontFamily: 'NunitoSans-Bold',
    textAlign: 'center',
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 25,
    width: '20%',
  },
  modalSaveButton: {
    width: Dimensions.get('screen').width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  modalSaveButton1: {
    width: Dimensions.get('screen').width * 0.1,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  submitText: {
    height:
      Platform.OS == 'ios'
        ? Dimensions.get('screen').height * 0.06
        : Dimensions.get('screen').height * 0.07,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: 'row',
    color: '#4cd964',
    textAlign: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
});
export default ReportSalesOvertime;
