import React, { Component, useEffect, useState, useRef } from 'react';
import {
  View,
  Text,
  // useWindowDimensions,
  StyleSheet,
  Dimensions
} from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator, useHeaderHeight } from '@react-navigation/stack';
import NewOrderScreen from '../screen/AuthScreen';
import NewOrderGenericScreen from '../screen/NewOrderGenericScreen';
// import TakeawayScreen from '../screen/TakeawayScreen.js.bak';
import ScanScreen from '../screen/ErrorScreen';
import ErrorScreen from '../screen/ErrorScreen.takeaway';
import PaymentSuccessScreen from '../screen/PaymentSuccessScreen';
// import OutletMenuScreen from '../screen/OutletMenuScreen.js.bak';
// import MenuItemDetailsScreen from '../screen/MenuItemDetailsScreen.js.bak';
// import CartScreen from '../screen/CartScreen.js.bak';
import { prefix } from '../constant/env';
import HomeScreen from '../screen/HomeScreen';
import LoginScreen from '../screen/LoginScreen';
import DashboardScreen from '../screen/DashboardScreen';
import AllTransaction from '../screen/AllTransaction';
import ProductScreen from '../screen/ProductScreen';
import ProductCategoryScreen from '../screen/ProductCategoryScreen';
import ProductAddScreen from '../screen/ProductAddScreen';
import ProductMenuScreen from '../screen/ProductMenuScreen';
//import EmployeeScreen from '../screen/EmployeeScreen';
import EmployeeScreen from '../screen/EmployeeScreen';
import CrmScreen from '../screen/CrmScreen';
import SegmentScreen from '../screen/SegmentScreen';
import ReportSalesOvertime from '../screen/ReportSalesOvertime';
import ReportSalesProduct from '../screen/ReportSalesProduct';
import ReportSalesCategory from '../screen/ReportSalesCategory';
import ReportSalesVariant from '../screen/ReportSalesVariant';
import ReportSalesAddOns from '../screen/ReportSalesAddOns';
import ReportSalesTransaction from '../screen/ReportSalesTransaction';
import ReportSalesPaymentMethod from '../screen/ReportSalesPaymentMethod';
import ReportSaleByShift from '../screen/ReportSaleByShift';
import PreorderPackageScreen from '../screen/PreorderPackageScreen';
import NewCustomer from '../screen/NewCustomer';
import Guests from '../screen/Guests';
import CreateGuests from '../screen/CreateGuests';
import SettingsReservation from '../screen/SettingsReservationScreen';
import SettingScreen from '../screen/SettingShiftScreen';
import SettingReceiptScreen from '../screen/SettingReceiptScreen';
import SettingOrderScreen from '../screen/SettingOrderScreen';
import SettingPaymentScreen from '../screen/SettingPaymentScreen';
import NewLoyaltyCampaignScreen from '../screen/NewLoyaltyCampaignScreen';
import SettingCredit from '../screen/SettingCredit';
import LoyaltyStampScreen from '../screen/LoyaltyStampScreen';
import VoucherReport from '../screen/VoucherReport';
import SettingsScreen from '../screen/SettingsScreen';
import VoucherScreen from '../screen/VoucherScreen';
import PromotionListScreen from '../screen/PromotionListScreen';
import PromotionReport from '../screen/PromotionReport';
import LoyaltyPointsRate from '../screen/LoyaltyPointsRate';
import SettingSetCredit from '../screen/SettingSetCredit';
import RedemptionRedeemedScreen from '../screen/RedemptionRedeemedScreen';
import RedemptionExpiredScreen from '../screen/RedemptionExpiredScreen';
import RedemptionScreen from '../screen/RedemptionScreen';
import PurchaseOrderScreen from '../screen/PurchaseOrderScreen';
import SettingLoyaltyScreen from '../screen/SettingLoyaltyScreen';
import SettingRedemptionScreen from '../screen/SettingRedemptionScreen';
import StockTakeScreen from '../screen/StockTakeScreen';
import StockTransferScreen from '../screen/StockTransferScreen';
import SupplierScreen from '../screen/SupplierScreen';
import InventoryScreen from '../screen/InventoryScreen';
import NewCampaignScreen from '../screen/NewCampaignScreen';
import SettingPrinterScreen from '../screen/SettingPrinterScreen';
import KCRMAgreementScreen from '../screen/KCRMAgreementScreen';
import KCRMMerchantsScreen from '../screen/KCRMMerchantsScreen';

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

const config = {
  screens: {
    HomeStack: {
      path: `${prefix}`,
      initialRouteName: 'Login - KooDoo Statistics',
      screens: {
        'KooDoo Statistics': {
          path: '/',
        },
        'Merchants - KooDoo Statistics': {
          path: '/merchants',
        },

        'Login - KooDoo Statistics': {
          path: '/login',
        },
        'Error - KooDoo Statistics': {
          path: '/error',
        },
        'Dashboard': {
          path: '/dashboard',
        },
        'AllTransaction': {
          path: '/alltransaction',
        },
        'Product': {
          path: '/product',
        },
        'Product Add': {
          path: '/productadd',
        },
        'Product Category': {
          path: '/productcategory',
        },
        'Product Menu': {
          path: '/productmenu',
        },
        'Employee': {
          path: '/employee',
        },
        'PromotionList': {
          path: '/promotionlist'
        },
        'PromotionReport': {
          path: '/promotionreport'
        },
        'CrmScreen': {
          path: '/crmscreen',
        },
        'SegmentScreen': {
          path: '/segmentscreen',
        },
        'ReportSalesOvertime': {
          path: '/reportsalesovertime'
        },
        'ReportSalesProduct': {
          path: '/reportsalesproduct'
        },
        'ReportSalesCategory': {
          path: '/reportsalescategory'
        },
        'ReportSalesVariant': {
          path: '/reportsalesvariant'
        },
        'ReportSalesAddOns': {
          path: '/reportsalesaddons'
        },
        'ReportSalesTransaction': {
          path: '/reportsalestransaction'
        },
        'ReportSalesPaymentMethod': {
          path: '/reportsalespaymentmethod'
        },
        'ReportSaleByShift': {
          path: '/reportsalebyshift'
        },
        'PreorderPackage': {
          path: '/preorderpackage'
        },
        'NewCustomer': {
          path: '/newcustomer'
        },
        'SettingsReservation': {
          path: '/settingsreservation'
        },
        'SettingPayment': {
          path: '/settingpayment'
        },
        'SettingOrder': {
          path: '/settingorder'
        },
        'VoucherReport': {
          path: '/voucherreport'
        },
        'VoucherScreen': {
          path: '/voucherscreen'
        },
        'SettingReceiptScreen': {
          path: '/settingreceiptscreen'
        },
        'NewLoyaltyCampaignScreen': {
          path: '/newloyaltycampaignscreen'
        },
        'SettingCredit': {
          path: '/settingcredit'
        },
        'LoyaltyStampScreen': {
          path: '/loyaltystampscreen'
        },
        'Guests': {
          path: '/guests'
        },
        'SettingsReservation': {
          path: '/settingsreservation'
        },
        'SettingReceiptScreen': {
          path: '/settingreceiptscreen'
        },
        'SettingShift': {
          path: '/settingshift'
        },
        'SettingsScreen': {
          path: '/settingsscreen'
        },
        'SettingSetCredit': {
          path: '/settingsetcredit'
        },
        'LoyaltyPointsRate': {
          path: '/loyaltypointsrate'
        },
        'SettingsScreen': {
          path: '/settingsscreen'
        },
        'RedemptionRedeemed': {
          path: '/redemptionredeemed'
        },
        'RedemptionExpired': {
          path: '/redemptionexpired'
        },
        'Redemption': {
          path: '/redemption'
        },
        'NewCampaign': {
          path: '/newcampaign'
        },
        'PurchaseOrderScreen': {
          path: '/purchaseorderscreen'
        },
        'SettingLoyaltyScreen': {
          path: '/settingloyaltyscreen'
        },
        'SettingRedemptionScreen': {
          path: '/settingredemptionscreen'
        },
        'StockTakeScreen': {
          path: '/stocktakescreen'
        },
        'StockTransferScreen': {
          path: '/stocktransferscreen'
        },
        'SupplierScreen': {
          path: 'supplierscreen'
        },
        'InventoryScreen': {
          path: 'inventoryscreen'
        },
        'SettingPrinterScreen': {
          path: 'settingprinterscreen'
        },
        'KCRMAgreementScreen': {
          path: 'kcrmagreementscreen'
        }
      },
    },

    // screens: {
    //   MainScreen: {
    //     path: 'qr/:outletId/:tableId/:tableCode/:waiterId?',
    //   },
    // },
    // NewOrderStack: {
    //   path: "/web/:outletId/:tableId/:tableCode/:tablePax/:waiterId",
    //   initialRouteName: "neworder",
    //   screens: {
    //     Login: {
    //       path: 'new-order',
    //     },
    //   },
    // },
    // NewOrderStack: {
    //   path: `${prefix}`,
    //   // initialRouteName: "NewOrder",
    //   screens: {
    //     NewOrder: {
    //       path: 'new-order/:outletId?/:tableId?/:tableCode?/:tablePax?/:waiterId?',
    //     },
    //     NewOrderGeneric: {
    //       path: 'new-order-generic/:outletId?/:tableId?/:tableCode?',
    //     },
    //     Takeaway: {
    //       path: 'takeaway',
    //     },
    //     Scan: {
    //       path: 'scan',
    //     },
    //     Error: {
    //       path: 'error',
    //     },
    //     PaymentSuccess: {
    //       path: 'payment/:amount?/:appCode?/:channel?/:checksum?/:mpSecuredVerfified?/:msgType?/:orderId?/:pInstruction?/:statusCode?/:txnId?',
    //     },
    //   },
    // },
    // OrderStack: {
    //   path: `${prefix}/outlet`,
    //   initialRouteName: "OutletMenu",
    //   screens: {
    //     OutletMenu: {
    //       path: 'menu',
    //     },
    //     MenuItemDetailsScreen: {
    //       path: 'menu/item',
    //     },
    //     CartScreen: {
    //       path: 'cart',
    //     },
    //   },
    // },
  },
};

const linking = {
  prefixes: [
    /* your linking prefixes */
  ],
  config: config,
};

// const NewOrderStack = ({ navigation }) => {
//   navigation.setOptions({ tabBarVisible: false });

//   return (
//     <Stack.Navigator
//       screenOptions={{
//         headerShown: false,
//       }}
//     >
//       <Stack.Screen
//         name='NewOrder'
//         component={NewOrderScreen}
//       />

//       <Stack.Screen
//         name='NewOrderGeneric'
//         component={NewOrderGenericScreen}
//       />

//       <Stack.Screen
//         name='Takeaway'
//         component={TakeawayScreen}
//       />

//       <Stack.Screen
//         name='PaymentSuccess'
//         component={PaymentSuccessScreen}
//       />

//       <Stack.Screen
//         name='Scan'
//         component={ScanScreen}
//       />

//       <Stack.Screen
//         name='Error'
//         component={ErrorScreen}
//       />
//     </Stack.Navigator>
//   );
// }

// const OrderStack = ({ navigation }) => {
//   navigation.setOptions({ tabBarVisible: false });

//   return (
//     <Stack.Navigator
//       screenOptions={{
//         // headerShown: false,
//       }}
//     >
//       <Stack.Screen
//         name='OutletMenu'
//         component={OutletMenuScreen}
//       />
//       <Stack.Screen
//         name='MenuItemDetailsScreen'
//         component={MenuItemDetailsScreen}
//       />
//       <Stack.Screen
//         name='CartScreen'
//         component={CartScreen}
//       />
//     </Stack.Navigator>
//   );
// }

const HomeStack = ({ navigation }) => {
  navigation.setOptions({ tabBarVisible: false });

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      {/* <Stack.Screen
        name='KooDoo Statistics'
        component={HomeScreen}
      /> */}


      <Stack.Screen
        name='KooDoo Statistics'
        component={KCRMAgreementScreen}
      />

      <Stack.Screen
        name='Merchants - KooDoo Statistics'
        component={KCRMMerchantsScreen}
      />

      <Stack.Screen
        name='Login - KooDoo Statistics'
        component={LoginScreen}
      />

      <Stack.Screen
        name='Error - KooDoo Statistics'
        component={ErrorScreen}
      />

      <Stack.Screen
        name='Dashboard'
        component={DashboardScreen}
      />

      <Stack.Screen
        name='AllTransaction'
        component={AllTransaction}
      />

      <Stack.Screen
        name='Product'
        component={ProductScreen}
      />

      <Stack.Screen
        name='Product Add'
        component={ProductAddScreen}
      />

      <Stack.Screen
        name='Product Category'
        component={ProductCategoryScreen}
      />

      <Stack.Screen
        name='Product Menu'
        component={ProductMenuScreen}
      />

      <Stack.Screen
        name='Employee'
        component={EmployeeScreen}
      />

      <Stack.Screen
        name='CrmScreen'
        component={CrmScreen}
      />

      <Stack.Screen
        name='PromotionList'
        component={PromotionListScreen}
      />

      <Stack.Screen
        name='PromotionReport'
        component={PromotionReport}
      />

      <Stack.Screen
        name='SegmentScreen'
        component={SegmentScreen}
      />
      <Stack.Screen
        name='ReportSalesOvertime'
        component={ReportSalesOvertime}
      />
      <Stack.Screen
        name='ReportSalesProduct'
        component={ReportSalesProduct}
      />
      <Stack.Screen
        name='ReportSalesCategory'
        component={ReportSalesCategory}
      />
      <Stack.Screen
        name='ReportSalesVariant'
        component={ReportSalesVariant}
      />
      <Stack.Screen
        name='ReportSalesAddOns'
        component={ReportSalesAddOns}
      />
      <Stack.Screen
        name='ReportSalesTransaction'
        component={ReportSalesTransaction}
      />
      <Stack.Screen
        name='ReportSalesPaymentMethod'
        component={ReportSalesPaymentMethod}
      />
      <Stack.Screen
        name='ReportSaleByShift'
        component={ReportSaleByShift}
      />
      <Stack.Screen
        name='PreorderPackage'
        component={PreorderPackageScreen}
      />
      <Stack.Screen
        name='NewCustomer'
        component={NewCustomer}
      />
      <Stack.Screen
        name='Guests'
        component={Guests}
      />
      <Stack.Screen
        name='CreateGuests'
        component={CreateGuests}
      />
      <Stack.Screen
        name='SettingsReservation'
        component={SettingsReservation}
      />
      <Stack.Screen
        name='SettingReceiptScreen'
        component={SettingReceiptScreen}
      />
      <Stack.Screen
        name="SettingOrder"
        component={SettingOrderScreen}
      />
      <Stack.Screen
        name='SettingPayment'
        component={SettingPaymentScreen}
      />
      <Stack.Screen
        name='VoucherScreen'
        component={VoucherScreen}
      />
      <Stack.Screen
        name='NewLoyaltyCampaignScreen'
        component={NewLoyaltyCampaignScreen}
      />
      <Stack.Screen
        name='SettingCredit'
        component={SettingCredit}
      />
      <Stack.Screen
        name='LoyaltyStampScreen'
        component={LoyaltyStampScreen}
      />
      <Stack.Screen
        name='VoucherReport'
        component={VoucherReport}
      />
      <Stack.Screen
        name='SettingShift'
        component={SettingScreen}
      />
      <Stack.Screen
        name='SettingsScreen'
        component={SettingsScreen}
      />
      <Stack.Screen
        name='LoyaltyPointsRate'
        component={LoyaltyPointsRate}
      />
      <Stack.Screen
        name='SettingSetCredit'
        component={SettingSetCredit}
      />
      <Stack.Screen
        name='RedemptionRedeemed'
        component={RedemptionRedeemedScreen}
      />

      <Stack.Screen
        name='RedemptionExpired'
        component={RedemptionExpiredScreen}
      />

      <Stack.Screen
        name='Redemption'
        component={RedemptionScreen}
      />

      <Stack.Screen
        name='NewCampaign'
        component={NewCampaignScreen}
      />

      <Stack.Screen
        name='PurchaseOrderScreen'
        component={PurchaseOrderScreen}
      />

      <Stack.Screen
        name='SettingLoyaltyScreen'
        component={SettingLoyaltyScreen}
      />

      <Stack.Screen
        name='SettingRedemptionScreen'
        component={SettingRedemptionScreen}
      />

      <Stack.Screen
        name='StockTakeScreen'
        component={StockTakeScreen}
      />

      <Stack.Screen
        name='StockTransferScreen'
        component={StockTransferScreen}
      />
      <Stack.Screen
        name='Supplier'
        component={SupplierScreen}
      />
      <Stack.Screen
        name='Inventory'
        component={InventoryScreen}
      />
      <Stack.Screen
        name='SettingPrinter'
        component={SettingPrinterScreen}
      />
    </Stack.Navigator>
  );
}

const AppNavigator = () => {
  return (
    <NavigationContainer
      // ref={navigationRef}
      // style={{
      //   backgroundColor: 'red',
      // }}      
      linking={linking} fallback={<Text>Loading...</Text>}>
      <Tab.Navigator tabBar={() => null}>
        <Tab.Screen
          name='HomeStack'
          component={HomeStack}
          screenOptions={({ route }) => ({
            tabBarVisible: null,
          })}
        />

        {/* <Tab.Screen
          name='NewOrderStack'
          component={NewOrderStack}
          screenOptions={({ route }) => ({
            tabBarVisible: null,
          })}
        />

        <Tab.Screen
          name='OrderStack'
          component={OrderStack}
          screenOptions={({ route }) => ({
            tabBarVisible: null,
          })}
        /> */}
      </Tab.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  menuItemsCard: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  circleContainer: {
    width: 50,
    height: 50,
    borderRadius: 25,
    padding: 10,
  },
});

export default AppNavigator;