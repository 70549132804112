import React, {Component, useReducer, useState, useEffect} from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  TextInput,
  Dimensions,
  FlatList,
  Modal,
  PermissionsAndroid,
  ActivityIndicator,
  useWindowDimensions,
} from 'react-native';
import Colors from '../constant/Colors';
import SideBar from './SideBar';
// import Switch from 'react-native-switch-pro';
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Feather';
import Icon1 from 'react-native-vector-icons/FontAwesome';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/EvilIcons';
// import DropDownPicker from 'react-native-dropdown-picker';
// import RNPickerSelect from 'react-native-picker-select';
// import {Picker} from '@react-native-picker/picker';
// import { ceil } from 'react-native-reanimated';
// import CheckBox from '@react-native-community/checkbox';
// import DateTimePicker from 'react-native-modal-datetime-picker';
import moment from 'moment';
import Close from 'react-native-vector-icons/AntDesign';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Styles from '../constant/Styles';
// import DocumentPicker from 'react-native-document-picker';
import Ionicons from 'react-native-vector-icons/Ionicons';
// import RNFetchBlob from 'rn-fetch-blob';
// import {isTablet} from 'react-native-device-detection';
import 'react-native-get-random-values';
import {customAlphabet} from 'nanoid';
import {v4 as uuidv4} from 'uuid';
import {CommonStore} from '../store/commonStore';
import {MerchantStore} from '../store/merchantStore';
import { useLinkTo, useRoute } from "@react-navigation/native";
import {
  PURCHASE_ORDER_STATUS,
  PURCHASE_ORDER_STATUS_PARSED,
  EMAIL_REPORT_TYPE,
} from '../constant/common';
import {UserStore} from '../store/userStore';
import {
  convertArrayToCSV,
  uploadImageToFirebaseStorage,
  generateEmailReport,
} from '../util/common';
// import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
// import {useKeyboard} from '../hooks';
import AntDesign from 'react-native-vector-icons/AntDesign';
// import {
//   Table,
//   TableWrapper,
//   Row,
//   Rows,
//   Col,
//   Cols,
//   Cell,
// } from 'react-native-table-component';
import {Platform} from 'react-native';
import { prefix } from '../constant/env';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 12);

const Guests = (props) => {
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const linkTo = useLinkTo();

const dummyData = [
    {
        key: 1,
        name: 'Ah Boy',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
        key: 2,
        name: 'Becky',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
        key: 3,
        name: 'Candice',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
      key: 4,
        name: 'Zebra',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
      key: 5,
        name: 'Giraffe',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
      key: 6,
        name: 'test6',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
      key: 7,
      name: 'Ah Girl',
      contact: '012390981237',
      guestTags: '',
      guestNotes: '',
      reservationPast: 0,
      reservationNext: 0,
      lastVisit: '',
      noShow: 0,
    },
    {
      key: 8,
      name: 'Girl',
      contact: '012390981237',
      guestTags: '',
      guestNotes: '',
      reservationPast: 0,
      reservationNext: 0,
      lastVisit: '',
      noShow: 0,
    },
    {
      key: 9,
      name: 'Boy',
      contact: '012390981237',
      guestTags: '',
      guestNotes: '',
      reservationPast: 0,
      reservationNext: 0,
      lastVisit: '',
      noShow: 0,
    },
]

const dummyItems = [
	{
		label: 'Gold',
		value: 'Gold'
	},
	{
		label: 'Silver',
		value: 'Silver'
	},
	{
		label: 'Bronze',
		value: 'Bronze'
	}
]

const dummyRadio = [
	{
		label: 'Male',
		value: 'Male'
	},
	{
		label: 'Female',
		value: 'Female'
	},
	{
		label: 'Other',
		value: 'Other' 
	}
]
const renderDummyData = ({item, index}) => {
    return (
        <TouchableOpacity
        onPress={() => {
          // props.navigation.navigate('CreateGuests')
        }}>
        <View
          style={{
            backgroundColor: '#ffffff',
            flexDirection: 'row',
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: '#c4c4c4',
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
            marginLeft: 5,
            height: windowHeight * 0.1,
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <View style={{
            flex: 1.5, 
            flexDirection: 'row',
            borderRightColor: Colors.lightGrey, 
            borderRightWidth: 1
          }}>
            <Image style={{
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              alignSelf: 'center',
              }} source={require('../assets/image/profile-pic.jpg')} 
            />
            <Text
              style={{
                  color: Colors.primaryColor, 
                  // fontSize: switchMerchant ? 10 : 14, 
                  fontFamily: 'NunitoSans-Regular', 
                  }}>
              {`${item.name}\n${item.contact}`}
            </Text>
          </View>
          <View style={{
            flex: 1, 
            borderRightColor: Colors.lightGrey, 
            borderRightWidth: 1
          }}>
            <Text style={{
              // fontSize: switchMerchant ? 10 : 14, 
              fontFamily: 'NunitoSans-Regular',
              textAlign: 'center'
              }}>
              {`${item.guestTags}`}
            </Text>
          </View>
          <View style={{
            flex: 1, 
            borderRightColor: Colors.lightGrey, 
            borderRightWidth: 1
          }}>
            <Text style={{
              //   fontSize: switchMerchant ? 10 : 14, 
                fontFamily: 'NunitoSans-Regular',
                textAlign: 'center'
                }}>
              {`${item.guestNotes}`}
            </Text>
          </View>
          <View style={{
            flex: 0.7, 
            borderRightColor: Colors.lightGrey, 
            borderRightWidth: 1
          }}>
            <Text style={{
              //   fontSize: switchMerchant ? 10 : 14, 
                fontFamily: 'NunitoSans-Regular',
                textAlign: 'center'
                }}>
              {`Past: ${item.reservationPast}\nNext: ${item.reservationNext}`}
            </Text>
          </View>
          <View style={{
            flex: 0.7, 
            borderRightColor: Colors.lightGrey, 
            borderRightWidth: 1
          }}>
            <Text style={{
              //   fontSize: switchMerchant ? 10 : 14, 
                fontFamily: 'NunitoSans-Regular',
                textAlign: 'center'
                }}>
              {`${item.lastVisit}`}
            </Text>
          </View>
          <View style={{
            flex: 0.5, 
            borderRightColor: Colors.lightGrey, 
            borderRightWidth: 1
          }}>
            <Text style={{
              //   fontSize: switchMerchant ? 10 : 14, 
                fontFamily: 'NunitoSans-Regular',
                textAlign: 'center'
                }}>
              {`${item.noShow}`}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    )
}
const alphabets = [
  {alphabet: 'A'}, {alphabet:'B'}, {alphabet:'C'}, {alphabet:'D'}, {alphabet:'E'}, {alphabet:'F'}, 
  {alphabet:'G'}, {alphabet:'H'}, {alphabet:'I'}, {alphabet:'J'}, {alphabet:'K'}, {alphabet:'L'},
  {alphabet:'M'}, {alphabet:'N'}, {alphabet:'O'}, {alphabet:'P'}, {alphabet:'Q'}, {alphabet:'R'}, 
  {alphabet:'S'}, {alphabet:'T'}, {alphabet:'U'}, {alphabet:'V'}, {alphabet:'W'}, {alphabet:'X'},
  {alphabet:'Y'}, {alphabet:'Z'}]

const renderHeader = ({item, index}) => {
  var alphabet = item.alphabet.toLowerCase()
  return(
            <View>
              {/* Header */}
                <View
                    style={{
                        marginTop: 10,
                        marginLeft: 5,
                        backgroundColor: Colors.lightGrey,
                        height: 60,
                        flexDirection: 'row',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <View style={{flex: 1.5, justifyContent: 'flex-start'}}>
                      <Text
                          style={{
                          alignSelf: 'flex-start',
                          fontFamily: 'NunitoSans-Bold',
                          marginLeft: 10
                          // fontSize: switchMerchant ? 10 : 14,
                          }}>
                          {`${item.alphabet}`}
                      </Text>
                    </View>
                    <View style={{flex: 1, justifyContent: 'flex-start'}}>
                      <Text
                          style={{
                          alignSelf: 'center',
                          fontFamily: 'NunitoSans-Bold',
                          // fontSize: switchMerchant ? 10 : 14,
                          }}>
                          Guest Tags
                      </Text>
                    </View>
                    <View style={{flex: 1, justifyContent: 'flex-start'}}>
                      <Text
                          style={{
                          alignSelf: 'center',
                          fontFamily: 'NunitoSans-Bold',
                          // fontSize: switchMerchant ? 10 : 14,
                          }}>
                          Guest Notes
                      </Text>
                    </View>
                    <View style={{flex: 0.7, justifyContent: 'flex-start'}}>
                      <Text
                          style={{
                          alignSelf: 'center',
                          fontFamily: 'NunitoSans-Bold',
                          // fontSize: switchMerchant ? 10 : 14,
                          }}>
                          Reservations
                      </Text>
                    </View>
                    <View style={{flex: 0.7, justifyContent: 'flex-start'}}>
                      <Text
                          style={{
                          alignSelf: 'center',
                          fontFamily: 'NunitoSans-Bold',
                          // fontSize: switchMerchant ? 10 : 14,
                          }}>
                          Last Visit
                      </Text>
                    </View>
                    <View style={{flex: 0.5, justifyContent: 'flex-start'}}>
                      <Text
                          style={{
                          alignSelf: 'center',
                          fontFamily: 'NunitoSans-Bold',
                          // fontSize: switchMerchant ? 10 : 14,
                          }}>
                          No-Show
                      </Text>
                    </View>
                </View>
                {/* Body FlatList        */}
                <FlatList
                nestedScrollEnabled={true}
                showsVerticalScrollIndicator={false}
                renderItem={renderDummyData}
                data={dummyData.filter((item) => {
                    if (item.name[0].toLowerCase() === alphabet){
                      if (search !== '') 
                      {
                        return item.name
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      } 
                      else 
                      {
                        return true;
                      }
                    }
                })}
                extraData={dummyData.filter((item) => {
                  if (item.name[0].toLowerCase() === alphabet){
                    if (search !== '') 
                    {
                      return item.name
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    } 
                    else 
                    {
                      return true;
                    }
                  }
                })}
                />
          </View>
  )
}

  const {navigation} = props;
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [search, setSearch] = useState('');

  const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);
  const userName = UserStore.useState(s => s.name);

  navigation.setOptions({
    headerLeft: () => (
      <View style={[styles.headerLeftStyle, {width: windowWidth * 0.17,}]}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View style={[{
        justifyContent: 'center',
        alignItems: 'center',
        // bottom: -2,
        bottom: switchMerchant ? '2%' : 0,
      }, windowWidth >= 768 && switchMerchant ? { right: windowWidth * 0.1 } : {}, windowWidth <= 768 ? { right: windowWidth * 0.12 } : {}]}>
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Guests
        </Text>
      </View>
    ),
    headerRight: () => (
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        {outletSelectDropdownView()}
        <View style={{
          backgroundColor: 'white',
          width: 0.5,
          height: windowHeight * 0.025,
          opacity: 0.8,
          marginHorizontal: 15,
          bottom: -1,
        }}>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('Setting')}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View style={{
            marginRight: 30,
            width: windowHeight * 0.05,
            height: windowHeight * 0.05,
            borderRadius: windowHeight * 0.05 * 0.5,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}>
            <Image style={{
              width: windowHeight * 0.035,
              height: windowHeight * 0.035,
              alignSelf: 'center',
            }} source={require('../assets/image/profile-pic.jpg')} />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

    return (
      <View
      style={[
        styles.container,
        // !isTablet
        //   ? {
        //       transform: [{scaleX: 1}, {scaleY: 1}],
        //     }
        //   : {},
      ]}
      >
        {/* Sidebar */}
        <View
          style={[
            styles.sidebar,
            {width: windowWidth * Styles.sideBarWidth}
          ]}>
          <SideBar
            navigation={navigation}
            selectedTab={1}
            expandOperation={true}
          />
        </View>

        <View>
       {/* Search bar and Create guest button */}
          <View 
          style={[styles.topBar, 
            {
            height: windowHeight * 0.05  ,
            width: windowWidth * 0.91,
          }
          ]}
          >
            <View
              style={{
              // width: switchMerchant ? 450 : 500,
              // height: switchMerchant ? 35 : 40,
              backgroundColor: 'white',
              borderRadius: 5,
              flexDirection: 'row',
              flex:1,
              alignContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              marginLeft: 10,
            }}>
                <Icon
                name="search"
                size={switchMerchant ? 13 : 18}
                color={Colors.primaryColor}
                style={{marginLeft: 15}}
                />
                <TextInput
                // editable={!loading}
                underlineColorAndroid={Colors.whiteColor}
                style={{
                    fontFamily: 'NunitoSans-Regular',
                    paddingLeft: 5,
                    height: 45,
                }}
                clearButtonMode="while-editing"
                placeholder=" Search"
                placeholderTextColor={Platform.select({
                    ios: '#a9a9a9',
                })}
                onChangeText={(text) => {
                    setSearch(text.trim());
                }}
                value={search}
                />
              </View>
              <View style={{
                flex: 1,
                }}>
                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    marginLeft: 400,
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: '#4E9F7D',
                    borderRadius: 5,
                    //width: 160,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    // alignContent: 'flex-end',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                  }}
                  onPress={() => {
                    // props.navigation.navigate('CreateGuests');
                    linkTo && linkTo(`${prefix}/createguests`);

                  }}
                  >
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      marginLeft: 5,
                      fontSize: switchMerchant ? 10 : 16,
                      textAlign: 'center',
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    Create Guest
                  </Text>
                </TouchableOpacity>
              </View>
          </View>

          <ScrollView>
            {/* Guest FlatList */}
            <View style={{flexDirection: 'row'}}>
                {/* <View
                    style={{
                        // backgroundColor: '#ffffff',
                        height: 60,
                        width: 800,
                        flexDirection: 'row',
                        paddingVertical: 20,
                        paddingHorizontal: 15,
                        borderRadius:10,
                        //marginTop: 10,
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                    }}>
                    <Text
                        style={{
                        width: '24%',
                        alignSelf: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        marginRight: 3,
                        // fontSize: switchMerchant ? 10 : 14,
                        }}>
                        A
                    </Text>
                    <Text
                        style={{
                        width: '22%',
                        alignSelf: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        marginHorizontal: 3,
                        // fontSize: switchMerchant ? 10 : 14,
                        }}>
                        Guest Tags
                    </Text>
                    <Text
                        style={{
                        width: '25%',
                        alignSelf: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        marginHorizontal: 3,
                        // fontSize: switchMerchant ? 10 : 14,
                        }}>
                        Guest Notes
                    </Text>
                    <Text
                        style={{
                        width: '17%',
                        alignSelf: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        marginHorizontal: 3,
                        // fontSize: switchMerchant ? 10 : 14,
                        }}>
                        Reservations
                    </Text>
                    <Text
                        style={{
                        width: '17%',
                        alignSelf: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        marginHorizontal: 3,
                        // fontSize: switchMerchant ? 10 : 14,
                        }}>
                        Last Visit
                    </Text>
                    <Text
                        style={{
                        width: '17%',
                        alignSelf: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        marginHorizontal: 3,
                        // fontSize: switchMerchant ? 10 : 14,
                        }}>
                        No-Show
                    </Text>
                </View> */}
                
            </View>
            <FlatList
                nestedScrollEnabled={true}
                showsVerticalScrollIndicator={false}
                renderItem={renderHeader}
                style={{height: windowHeight * 0.77}}
                data={alphabets.filter((item) => {
                  var alphabet = item.alphabet.toLowerCase()
                  var ret = dummyData.filter((item) => {
                    if(item.name[0].toLowerCase() === alphabet){
                      return true
                    }
                  })
                  if (ret.length > 0){
                    if (search !== '') 
                    {
                      return item.alphabet
                        .toLowerCase()
                        .includes(search[0].toLowerCase());
                    } 
                    else 
                    {
                      return true;
                    }
                  }
                })}
                extraData={alphabets.filter((item) => {
                  var alphabet = item.alphabet.toLowerCase()
                  var ret = dummyData.filter((item) => {
                    if(item.name[0].toLowerCase() === alphabet){
                      return true
                    }
                  })
                  if (ret.length > 0){
                    if (search !== '') 
                    {
                      return item.alphabet
                        .toLowerCase()
                        .includes(search[0].toLowerCase());
                    } 
                    else 
                    {
                      return true;
                    }
                  }
                })}
                keyExtractor={(item, index) => String(index)}
                />
          </ScrollView>
        </View>
      </View>
    )

}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.highlightColor,
      flexDirection: 'row',
    },
    headerLogo: {
      width: 112,
      height: 25,
      marginLeft: 10,
    },
    sidebar: {
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 8,
      },
      shadowOpacity: 0.44,
      shadowRadius: 10.32,
  
      elevation: 16,
    },
    circleIcon: {
      width: 30,
      height: 30,
      // resizeMode: 'contain',
      marginRight: 10,
      alignSelf: 'center',
    },
    confirmBox: {
      borderRadius: 12,
      backgroundColor: Colors.whiteColor,
      justifyContent: 'space-between',
    },
    topBar: {
      flexDirection: 'row',
      backgroundColor: Colors.lightGrey,
      justifyContent: 'flex-start',
    },
    textInput8: {
      fontFamily: 'NunitoSans-Regular',
      width: 75,
      height: 50,
      flex: 1,
      backgroundColor: Colors.fieldtBgColor,
      borderRadius: 5,
    },
  
    textInput9: {
      fontFamily: 'NunitoSans-Regular',
      width: 350,
      height: 50,
      //flex: 1,
      backgroundColor: Colors.fieldtBgColor,
      borderRadius: 5,
    },
  
    headerLeftStyle: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContainer: {
      flex: 1,
      backgroundColor: Colors.modalBgColor,
      alignItems: 'flex-end',
    //   justifyContent: 'flex-end',
    },
    modalView: {
      backgroundColor: Colors.whiteColor,
      borderRadius: 12,
      alignItems: 'flex-start',
    //   justifyContent: 'flex-start',
    },
    closeButton: {
      position: 'absolute',
  
      elevation: 1000,
      zIndex: 1000,
    },
    modalTitle: {
      alignItems: 'center',
      top: '20%',
      position: 'absolute',
    },
    modalBody: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalTitleText: {
      fontFamily: 'NunitoSans-Bold',
      textAlign: 'center',
      fontSize: 24,
    },
    modalDescText: {
      fontFamily: 'NunitoSans-SemiBold',
      fontSize: 18,
      color: Colors.fieldtTxtColor,
    },
    modalBodyText: {
      flex: 1,
      fontFamily: 'NunitoSans-SemiBold',
      fontSize: 25,
      width: '20%',
    },
    modalSaveButton: {
      width: 130,
      backgroundColor: Colors.fieldtBgColor,
      height: 40,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
  
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 3.22,
      elevation: 1,
  
      marginVertical: 10,
    },
    ManageFilterBox: {
      //width: windowWidth * 0.27,
      //height: Platform.OS === 'ios' ?windowHeight * 0.23: windowHeight * 0.24,
      //width: Platform.OS === 'ios' ? windowWidth * 0.4 : windowWidth * 0.33,
      borderWidth: 1,
      borderColor: '#E5E5E5',
      position: 'absolute',
      marginTop: Platform.OS === 'ios' ? '13%' : '13%',
      marginLeft: Platform.OS === 'ios' ? '12%' : '12%',
      //left: Platform.OS === 'ios' ? '38%' : '0%',
      //top: Platform.OS === 'ios' ? '46%' : '0%',
      shadowColor: '#000',
      shadowOffset: {
        width: 1,
        height: 5,
      },
      shadowOpacity: 0.32,
      shadowRadius: 3.22,
      elevation: 10,
      zIndex: 1000,
      borderRadius: 10,
      //borderTopLeftRadius: 0,
      backgroundColor: Colors.whiteColor,
      //justifyContent: 'space-between'
    },
	tierDropdown: {
		flex: 1,
		paddingHorizontal: 15,
		paddingVertical: 10,
		// alignItems: 'center',
		borderRadius: 10,
		// justifyContent: 'center',
		backgroundColor: Colors.whiteColor,
		shadowOpacity: 0,
		shadowColor: '#000',
		shadowOffset: {
		  width: 0,
		  height: 2,
		},
		shadowOpacity: 0.22,
		shadowRadius: 3.22,
		elevation: 1,
		zIndex: 1,
	  },	
  });
  
export default Guests;