import React, { Component, useReducer, useState, useEffect } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  SafeAreaView,
  KeyboardAvoidingView,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  FlatList,
  TextInput,
  Modal,
  Platform,
  ActivityIndicator,
  Picker,
  Switch,
  useWindowDimensions,
} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import Colors from '../constant/Colors';
import Close from 'react-native-vector-icons/AntDesign';
import SideBar from './SideBar';
import Icon from 'react-native-vector-icons/Feather';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Entypo from 'react-native-vector-icons/Entypo';
import Ionicons from 'react-native-vector-icons/Ionicons';
// import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import API from '../constant/API';
import DropDownPicker from 'react-native-dropdown-picker';
import * as User from '../util/User';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Styles from '../constant/Styles';
import ApiClient from '../util/ApiClient';
// import Switch from 'react-native-switch-pro';
// import { isTablet } from 'react-native-device-detection';
import { OutletStore } from '../store/outletStore';
import { CommonStore } from '../store/commonStore';
import { MerchantStore } from '../store/merchantStore';
import { uploadImageToFirebaseStorage } from '../util/common';
import { UserStore } from '../store/userStore';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import AsyncImage from '../components/asyncImage';
// import { useKeyboard } from '../hooks';
// import { Picker } from '@react-native-picker/picker';
// import RNPickerSelect from 'react-native-picker-select';
import AntDesign from 'react-native-vector-icons/AntDesign';
import XLSX from 'xlsx';
import { useFilePicker } from "use-file-picker";

import moment from 'moment';
// import DocumentPicker from 'react-native-document-picker';
import AIcon from 'react-native-vector-icons/AntDesign';
import Ionicon from 'react-native-vector-icons/Ionicons';
import Select from 'react-select';
// const RNFS = require('react-native-fs');

import { useLinkTo, useRoute } from "@react-navigation/native";
import { DataStore } from "../store/dataStore";
import { prefix } from "../constant/env";

const ProductAddScreen = (props) => {
  const { navigation } = props;

  const {
    width: windowWidth,
    height: windowHeight
  } = useWindowDimensions();

  // state = {
  //     productList: [],
  //     menuList: 0,
  //     size: '',
  //     addOn: [],
  //     addProduct: false, //TODO false
  //     addProductItem: true,
  //     addMenuItem: false,
  //     showInventory: false,
  //     showOption1: false,
  //     showOption: false,
  //     // outletId: '1',
  //     outletId: User.getOutletId(),
  //     search: [],
  //     menu: [],
  //     modal: false,
  //     category: '',
  //     itemId: [],
  //     menuItem: [],
  //     detail: [],
  //     suppliers: [],
  //     material: [],
  //     choosenMenu: [],
  //     image: '',
  //     description: '',
  //     price: '',
  //     logo: '',
  //     materialSKU: '',
  //     materialTag: '',
  //     categoryId: '',
  //     options: '',
  //     options1: '',
  //     material: '',
  //     supplier: '',
  //     cate: [],
  //     taxInfo: [],
  //     merInfo: [],
  //     myTextInput: React.createRef(),
  //     lists: [],
  //     productlist1: true,
  //     searchList: false,
  //     currentMenu: [],
  //     productList2: [],
  //     categoryOutlet: [],
  //     choice: null,
  //     productList2: [],
  //     visible: false,
  //     visible1: false,
  //     visible2: false,
  //     title: '',
  //     title1: '',
  //     price1: '',
  //     productOptions: [
  //         { optionId: 1, name: 'Spicy', price: 2 },
  //         { optionId: 2, name: 'No Spicy', price: 0 },
  //     ],
  //     productOptions1: [
  //         { optionsId: 1, name: 'Spicy', min: 1, max: 2 },
  //         { optionsId: 2, name: '', min: 0, max: 2 },
  //     ],
  //     refresh: 'false'
  // };

  const [openFileSelector, { plainFiles, filesContent, loading: loadingImageInput, clear: clearImageContainer, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
  });

  useEffect(() => {
    console.log(plainFiles, filesContent, loadingImageInput)
    if(loadingImageInput)
      setLoadingModal(true)

    // display the image when it is finish loaded
    if (plainFiles.length && filesContent.length && !loadingImageInput) {
      // image in base64 filecontent
      setImage(filesContent[0].content);
      setImageType(filesContent[0].name.slice(filesContent[0].name.lastIndexOf('.')));
      setIsImageChanged(true);

      setLoadingModal(false);
    }

    if(errors.length)
      console.error(errors)

  }, [plainFiles, filesContent, loadingImageInput, errors])
  
  const [productList, setProductList] = useState([]);
  const [menuList, setMenuList] = useState(0);
  const [size, setSize] = useState('');
  const [addOn, setAddOn] = useState([]);
  const [addProduct, setAddProduct] = useState(false);
  const [addProductItem, setAddProductItem] = useState(true);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [addMenuItem, setAddMenuItem] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [showOption1, setShowOption1] = useState(false);
  const [showOption, setShowOption] = useState(false);
  const [outletId, setOutletId] = useState(User.getOutletId());
  const [search, setSearch] = useState([]);
  const [menu, setMenu] = useState([]);
  const [modal, setModal] = useState(false);
  const [category, setCategory] = useState('');
  const [itemId, setItemId] = useState([]);
  const [menuItem, setMenuItem] = useState([]);
  const [detail, setDetail] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  // const [material, setMaterial] = useState([]);
  const [choosenMenu, setChoosenMenu] = useState([]);

  const [image, setImage] = useState('');
  const [imageType, setImageType] = useState('');

  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [logo, setLogo] = useState('');
  const [materialSKU, setMaterialSKU] = useState('');
  const [materialTag, setMaterialTag] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [options, setOptions] = useState('');
  const [options1, setOptions1] = useState('');
  const [material, setMaterial] = useState('');
  const [supplier, setSupplier] = useState('');
  const [cate, setCate] = useState([]);
  const [taxInfo, setTaxInfo] = useState([]);
  const [merInfo, setMerInfo] = useState([]);
  const [myTextInput, setMyTextInput] = useState(React.createRef());
  const [lists, setLists] = useState([]);
  const [productlist1, setProductlist1] = useState(true);
  const [searchList, setSearchList] = useState(false);
  const [currentMenu, setCurrentMenu] = useState([]);
  // const [productList2, setProductList2] = useState([]);
  const [categoryOutlet, setCategoryOutlet] = useState([]);
  const [choice, setChoice] = useState(null);
  const [productList2, setProductList2] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [title, setTitle] = useState('');
  const [title1, setTitle1] = useState('');
  const [price1, setPrice1] = useState('');
  const [itemQuantity, setItemQuantity] = useState(0);
  const [productOptionsState, setProductOptionsState] = useState([
    { optionId: 1, name: 'Spicy', price: 2 },
    { optionId: 2, name: 'No Spicy', price: 0 },
  ]);
  const [productOptions1State, setProductOptions1] = useState([
    { optionsId: 1, name: 'Spicy', min: 1, max: 2 },
    { optionsId: 2, name: '', min: 0, max: 2 },
  ]);
  // const [refresh, setRefresh] = useState('false');
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [itemSKU, setItemSKU] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemPrice, setItemPrice] = useState('');
  const [itemTaxRate, setItemTaxRate] = useState('');
  const [addOnSingle, setAddOnSingle] = useState('');
  const [addOnMultiple, setAddOnMultiple] = useState('');

  const [showAddOnModal, setShowAddOnModal] = useState(false);

  const [exportModal, setExportModal] = useState(false);
  const [importModal, setImportModal] = useState(false);

  const [isSave, setIsSave] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const [userTagName, setUserTagName] = useState('');
  const [userTagList, setUserTagList] = useState([]);
  const [userTagDropdownList, setUserTagDropdownList] = useState([]);
  const [selectedUserTagList, setSelectedUserTagList] = useState([]);

  const [searchingUserTagText, setSearchingUserTagText] = useState('');

  const selectedCustomerEdit = CommonStore.useState(
    (s) => s.selectedCustomerEdit,
  );

  //////////////////////////////////////////////////////////////

  const [outletSupplyItemDropdownList, setOutletSupplyItemDropdownList] =
    useState([]);
  const [selectedOutletSupplyItemId, setSelectedOutletSupplyItemId] =
    useState('');

  const [outletDropdownList, setOutletDropdownList] = useState([]);
  const [selectedOutletId, setSelectedOutletId] = useState('');

  const [selectedOutletList, setSelectedOutletList] = useState([]); // multi-outlets

  const [outletCategoryDropdownList, setOutletCategoryDropdownList] = useState(
    [],
  );
  const [selectedOutletCategoryId, setSelectedOutletCategoryId] = useState('');

  const [stockLinkItems, setStockLinkItems] = useState([
    {
      outletSupplyItemId: '',
      skuMerchant: '',
      name: '',
      unit: '',
      quantityUsage: '',
    },
  ]);

  const [isImageChanged, setIsImageChanged] = useState(false);

  const [loadingModal, setLoadingModal] = useState(false);
  ////////////////////////////////////////////////////////////////////

  const [variantGroupList, setVariantGroupList] = useState([
    // {
    //     groupId: '',
    //     choices: [
    //         {
    //             choiceId: '',
    //             choiceName: '',
    //             choicePrice: '',
    //         }
    //     ],
    //     name: '',
    //     minSelect: 1,
    //     maxSelect: 1,
    // }
  ]);

  const [addOnGroupList, setAddOnGroupList] = useState([
    // {
    //     groupId: '',
    //     choices: [
    //         {
    //             choiceId: '',
    //             choiceName: '',
    //             choicePrice: '',
    //         }
    //     ],
    //     name: '',
    // }
  ]);

  const [variantGroupIndex, setVariantGroupIndex] = useState(0);
  const [addOnGroupIndex, setAddOnGroupIndex] = useState(0);
  const [showAddOnGroup, setShowAddOnGroup] = useState(false);
  const [showVariantGroup, setShowVariantGroup] = useState(false);
  const [tagModal, setTagModal] = useState(false);

  const [showDetails, setShowDetails] = useState(false);
  const [showVariantDetails, setShowVariantDetails] = useState(false);

  const [selectedPrinterAreaList, setSelectedPrinterAreaList] = useState([]);
  const [printerAreaDropdownList, setPrinterAreaDropdownList] = useState([]);

  ////////////////////////////////////////////////////////////////////

  const outletPrinters = OutletStore.useState((s) => s.outletPrinters);

  const outletItems = OutletStore.useState((s) => s.outletItems);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const outletSupplyItems = CommonStore.useState((s) => s.outletSupplyItems);
  const outletSupplyItemsDict = CommonStore.useState(
    (s) => s.outletSupplyItemsDict,
  );
  const outletSupplyItemsSkuDict = CommonStore.useState(
    (s) => s.outletSupplyItemsSkuDict,
  );
  const supplyItemsDict = CommonStore.useState((s) => s.supplyItemsDict);

  const allOutletsItemAddOnDict = CommonStore.useState(
    (s) => s.allOutletsItemAddOnDict,
  );
  const allOutletsItemAddOnChoiceDict = CommonStore.useState(
    (s) => s.allOutletsItemAddOnChoiceDict,
  );

  const outletsTaxDict = CommonStore.useState((s) => s.outletsTaxDict);
  const outletCategories = OutletStore.useState((s) => s.outletCategories);
  const outletCategoriesDict = OutletStore.useState(
    (s) => s.outletCategoriesDict,
  );

  const merchantId = UserStore.useState((s) => s.merchantId);

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  const selectedProductEdit = CommonStore.useState(
    (s) => s.selectedProductEdit,
  );

  const allOutletsItemsSkuDict = OutletStore.useState(
    (s) => s.allOutletsItemsSkuDict,
  );
  const allOutletsCategoriesNameDict = OutletStore.useState(
    (s) => s.allOutletsCategoriesNameDict,
  );

  const crmUserTags = OutletStore.useState((s) => s.crmUserTags);
  const crmUserTagsDict = OutletStore.useState((s) => s.crmUserTagsDict);

  const isLoading = CommonStore.useState((s) => s.isLoading);
  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView,
  );

  //////////////////////////////////////////////////////////////

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);

  //////////////////////////////////////////////////////////////

  // useEffect(() => {
  //     setOutletSupplyItemDropdownList(outletSupplyItems.map(item => ({ label: item.name, value: item.uniqueId })));

  //     if (selectedOutletSupplyItemId === '' && outletSupplyItems.length > 0) {
  //         setSelectedOutletSupplyItemId(outletSupplyItems[0].uniqueId);
  //     }
  // }, [outletSupplyItems]);

  useEffect(() => {
    if (crmUserTags.length > 0) {
      setUserTagDropdownList(
        crmUserTags
          .filter((item) => {
            var isExisted = false;

            for (var i = 0; i < userTagList.length; i++) {
              if (userTagList[i].uniqueId === item.uniqueId) {
                isExisted = true;
                break;
              }
            }

            return !isExisted;
          })
          .map((item) => {
            return { label: item.name, value: item.uniqueId };
          }),
      );
    }
  }, [crmUserTags, userTagList]);

  useEffect(() => {
    console.log('================================');
    console.log('selectedProductEdit');
    console.log(selectedProductEdit);

    if (selectedProductEdit && Object.keys(allOutletsItemsSkuDict).length > 0) {
      // insert info
      var selectedOutletListTemp = [];
      if (allOutletsItemsSkuDict[selectedProductEdit.sku]) {
        for (
          var i = 0;
          i < allOutletsItemsSkuDict[selectedProductEdit.sku].length;
          i++
        ) {
          selectedOutletListTemp.push(
            allOutletsItemsSkuDict[selectedProductEdit.sku][i].outletId,
          );
        }
      }

      setSelectedOutletList(selectedOutletListTemp);

      setSelectedPrinterAreaList(selectedProductEdit.printerAreaList || []);

      setPlace(selectedProductEdit.outletId);
      setItemSKU(selectedProductEdit.skuMerchant);
      setItemName(selectedProductEdit.name);
      setItemDescription(selectedProductEdit.description);
      setImage(selectedProductEdit.image);
      setItemPrice(selectedProductEdit.price.toFixed(2));
      setItemQuantity(selectedProductEdit.itemQuantity);

      setSelectedOutletCategoryId(selectedProductEdit.categoryId);

      if (selectedProductEdit.stockLinkItems) {
        setStockLinkItems(selectedProductEdit.stockLinkItems);
      }

      // if (selectedProductEdit.stockLinkItems > 1) {
      //     for( var i = 0; i < stockLinkItems; i++){
      //         setStockLinkItems(selectedProductEdit.stockLinkItems);

      //     }
      //     // setStockLinkItems(selectedProductEdit.stockLinkItems);
      //     // setStockLinkItems(selectedProductEdit.stockLinkItems.outletSupplyItemId);
      //     // setStockLinkItems(selectedProductEdit.stockLinkItems.skuMerchant);
      //     // setStockLinkItems(selectedProductEdit.stockLinkItems.unit);
      //     // setStockLinkItems(selectedProductEdit.stockLinkItems.quantityUsage);
      // }

      var variantGroupListTemp = [];
      var addOnGroupListTemp = [];

      if (
        Object.keys(allOutletsItemAddOnDict).length > 0 &&
        Object.keys(allOutletsItemAddOnChoiceDict)
      ) {
        if (allOutletsItemAddOnDict[selectedProductEdit.uniqueId]) {
          for (
            var i = 0;
            i < allOutletsItemAddOnDict[selectedProductEdit.uniqueId].length;
            i++
          ) {
            const currGroup =
              allOutletsItemAddOnDict[selectedProductEdit.uniqueId][i];

            if (currGroup.maxSelect) {
              // means is variant

              var variantGroup = {
                groupId: currGroup.uniqueId,
                choices: [],
                name: currGroup.name,
                minSelect: currGroup.minSelect,
                maxSelect: currGroup.maxSelect,
              };

              if (allOutletsItemAddOnChoiceDict[currGroup.uniqueId]) {
                for (
                  var j = 0;
                  j < allOutletsItemAddOnChoiceDict[currGroup.uniqueId].length;
                  j++
                ) {
                  const variantGroupChoice =
                    allOutletsItemAddOnChoiceDict[currGroup.uniqueId][j];

                  variantGroup.choices.push({
                    choiceId: variantGroupChoice.uniqueId,
                    choiceName: variantGroupChoice.name,
                    choicePrice: variantGroupChoice.price,
                  });
                }
              }

              variantGroupListTemp.push(variantGroup);
            } else {
              // means is add-on

              var addOnGroup = {
                groupId: currGroup.uniqueId,
                choices: [],
                name: currGroup.name,
              };

              if (allOutletsItemAddOnChoiceDict[currGroup.uniqueId]) {
                for (
                  var j = 0;
                  j < allOutletsItemAddOnChoiceDict[currGroup.uniqueId].length;
                  j++
                ) {
                  const addOnGroupChoice =
                    allOutletsItemAddOnChoiceDict[currGroup.uniqueId][j];

                  addOnGroup.choices.push({
                    choiceId: addOnGroupChoice.uniqueId,
                    choiceName: addOnGroupChoice.name,
                    choicePrice: addOnGroupChoice.price,
                    choiceMinSelect: addOnGroupChoice.minSelect,
                    choiceMaxSelect: addOnGroupChoice.maxSelect,
                  });
                }
              }

              addOnGroupListTemp.push(addOnGroup);
            }
          }
        }
      }

      setVariantGroupList(variantGroupListTemp);
      setVariantGroupIndex(0);
      setAddOnGroupList(addOnGroupListTemp);
      setAddOnGroupIndex(0);

      // console.log('groups from selected product edit');
      // console.log(variantGroupListTemp);
      // console.log(addOnGroupListTemp);

      setSelectedUserTagList(
        selectedProductEdit.crmUserTagIdList
          ? selectedProductEdit.crmUserTagIdList
          : [],
      );
      setSearchingUserTagText('');

      // return () => {
      //     CommonStore.update(s => {
      //         s.selectedProductEdit = null;
      //     });
      // }
    } else {
      // designed to always mounted, thus need clear manually...

      setSelectedOutletList([]);

      setSelectedPrinterAreaList([]);

      setPlace('');
      setItemSKU('');
      setItemName('');
      setItemDescription('');
      setImage('');
      clearImageContainer();
      //setImage(null);
      setItemPrice('0.00');
      setItemQuantity(0);

      if (outletSupplyItems.length > 0) {
        setStockLinkItems([
          {
            outletSupplyItemId: outletSupplyItems[0].uniqueId,
            sku: outletSupplyItems[0].sku,
            skuMerchant: outletSupplyItems[0].skuMerchant,
            name: outletSupplyItems[0].name,
            unit: outletSupplyItems[0].unit,
            quantityUsage: '',
          },
        ]);
      } else {
        setStockLinkItems([
          {
            outletSupplyItemId: '',
            sku: '',
            name: '',
            unit: '',
            quantityUsage: '',
          },
        ]);
      }

      setIsImageChanged(false);

      setVariantGroupList([]);
      setAddOnGroupList([]);

      setSelectedUserTagList([]);
      setSearchingUserTagText('');
    }
  }, [
    selectedProductEdit,
    allOutletsItemsSkuDict,
    allOutletsItemAddOnDict,
    allOutletsItemAddOnChoiceDict,
    outletSupplyItemsSkuDict,
    supplyItemsDict,
  ]);

  useEffect(() => {
    setOutletDropdownList(
      allOutlets.map((item) => ({
        label: item.name,
        value: item.uniqueId,
        // icon: () => (
        //     <Icon
        //         name="check-square"
        //         size={18}
        //         color={"lightgreen"}
        //     />
        // ),
      })),
    );

    if (selectedProductEdit === null) {
      if (selectedOutletId === '' && allOutlets.length > 0 && currOutletId) {
        // setSelectedOutletId(allOutlets[0].uniqueId);

        // setSelectedOutletList([
        //     allOutlets[0].uniqueId,
        // ]);

        setSelectedOutletId(currOutletId);

        setSelectedOutletList([currOutletId]);
      }

      if (place === '' && allOutlets.length > 0 && currOutletId) {
        // setPlace(allOutlets[0].uniqueId);
        setPlace(currOutletId);

        // setSelectedOutletList([
        //     allOutlets[0].uniqueId,
        // ]);
      }
    }
  }, [allOutlets, selectedProductEdit, currOutletId]);

  useEffect(() => {
    var uniquePrinterAreaNameList = [];
    var uniquePrinterAreaList = [];

    for (var i = 0; i < outletPrinters.length; i++) {
      var name = '';

      if (outletPrinters[i].area) {
        name = outletPrinters[i].area;
      }
      else if (outletPrinters[i].name) {
        name = outletPrinters[i].name;
      }

      if (name && !uniquePrinterAreaList.includes(name)) {
        uniquePrinterAreaNameList.push(name);
        uniquePrinterAreaList.push(outletPrinters[i]);
      }
    }

    const printerAreaDropdownListTemp = uniquePrinterAreaList.map((item) => ({
      label: item.area || item.name,
      // value: item.uniqueId,
      value: item.area || item.name,
    }));

    setPrinterAreaDropdownList(printerAreaDropdownListTemp);

    if (selectedProductEdit === null) {
      if (printerAreaDropdownListTemp.length > 0) {
        setSelectedPrinterAreaList(printerAreaDropdownListTemp[0]);
      }
      else {
        setSelectedPrinterAreaList([]);
      }
    }
    else {
      var selectedPrinterAreaListTemp = [];

      if (selectedProductEdit.printerAreaList && selectedProductEdit.printerAreaList.length > 0) {
        for (var i = 0; i < selectedProductEdit.printerAreaList.length; i++) {
          var isPrinterAreaMissing = false;

          const printerArea = selectedProductEdit.printerAreaList[i];

          for (var j = 0; j < printerAreaDropdownListTemp.length; j++) {
            if (printerAreaDropdownListTemp[j].value === printerArea) {
              isPrinterAreaMissing = true;
              break;
            }
          }

          if (!isPrinterAreaMissing) {
            // means the saved printer area deleted or renamed

            // skip the printer area
          }
          else {
            selectedPrinterAreaListTemp.push(printerArea);
          }
        }
      }

      setSelectedPrinterAreaList(selectedPrinterAreaListTemp);
    }
  }, [outletPrinters, selectedProductEdit]);

  useEffect(() => {
    setOutletCategoryDropdownList(
      outletCategories.map((item) => ({
        label: item.name,
        value: item.uniqueId,
      })),
    );

    console.log('outlet categories dropdown');
    console.log(
      outletCategories.map((item) => ({
        label: item.name,
        value: item.uniqueId,
      })),
    );

    if (selectedOutletCategoryId === '' && outletCategories.length > 0) {
      setSelectedOutletCategoryId(outletCategories[0].uniqueId);
    }
  }, [outletCategories]);

  ///////////////////////////////////////

  // in case categories haven't loaded when user edit the item

  useEffect(() => {
    if (selectedProductEdit && selectedProductEdit.categoryId) {
      setSelectedOutletCategoryId(selectedProductEdit.categoryId);
    }
  }, [outletCategoryDropdownList]);

  ///////////////////////////////////////

  useEffect(() => {
    setOutletSupplyItemDropdownList(
      outletSupplyItems.map((item) => ({
        label: item.name,
        value: item.uniqueId,
      })),
    );

    if (
      outletSupplyItems.length > 0 &&
      stockLinkItems.length === 1 &&
      stockLinkItems[0].outletSupplyItemId === ''
    ) {
      setStockLinkItems([
        {
          outletSupplyItemId: outletSupplyItems[0].uniqueId,
          skuMerchant: outletSupplyItems[0].skuMerchant,
          name: outletSupplyItems[0].name,
          unit: outletSupplyItems[0].unit,
          quantityUsage: '',
        },
      ]);
    }
    // else if (stockLinkItems[0].outletSupplyItemId !== '' &&
    //     Object.keys(outletSupplyItemsSkuDict).length > 0) {
    //     var stockLinkItemsTemp = [
    //         ...stockLinkItems,
    //     ];

    //     for (var i = 0; i < stockLinkItemsTemp.length; i++) {
    //         const outletSupplyItem = outletSupplyItemsSkuDict[stockLinkItemsTemp[i].sku];

    //         stockLinkItemsTemp[i] = {
    //             ...stockLinkItemsTemp[i],
    //             price: outletSupplyItem.price, // might changed in real time
    //         };
    //     }

    //     setStockLinkItems(stockLinkItemsTemp);
    // }
  }, [outletSupplyItems, supplyItemsDict, outletSupplyItemsSkuDict]);

  useEffect(() => {
    refresh();
    menucount();
    refreshMenu();
    categoryFunc();

    // ApiClient.GET(API.outlet2 + outletId).then((result) => {
    //   setState({ outletData: result.cover })
    // })
  }, []);

  const setState = () => { };

  // navigation.dangerouslyGetParent().setOptions({
  //     tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            // bottom: -2,
            bottom: switchMerchant ? '2%' : 0,
          },
          Dimensions.get('screen').width >= 768 && switchMerchant
            ? { right: Dimensions.get('screen').width * 0.1 }
            : {},
          Dimensions.get('screen').width <= 768
            ? { right: Dimensions.get('screen').width * 0.12 }
            : {},
        ]}>
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Add Product
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: 'white',
            width: 0.5,
            height: Dimensions.get('screen').height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}></View>
        <TouchableOpacity
          onPress={() => navigation.navigate('Setting')}
          style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get('screen').height * 0.05,
              height: Dimensions.get('screen').height * 0.05,
              borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}>
            <Image
              style={{
                width: Dimensions.get('screen').height * 0.035,
                height: Dimensions.get('screen').height * 0.035,
                alignSelf: 'center',
              }}
              source={require('../assets/image/profile-pic.jpg')}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // const searchBarItem = () => {
  //     ApiClient.GET(
  //         API.searchBarItem +
  //         search +
  //         '&outletId=' +
  //         outletId,
  //     ).then((result) => {

  //         setState({ lists: result });
  //     });
  // }

  // const refresh1 = () => {
  //     setState({ menuItem: [], cate: [], detail: [], taxInfo: [], merInfo: [], image: '' });

  //     //myTextInput.current.clear();
  // }

  // const addSlot = () => {
  //     setState({ showInventory: true });
  // }

  // const handleChoosePhoto = () => {
  //     const option = {
  //         noData: true,
  //     };
  //     ImagePicker.showImagePicker(option, (response) => {

  //         if (response.didCancel) {
  //         } else if (response.error) {
  //             Alert.alert(response.error.toString());
  //         } else {
  //             setState({ image: response.uri });
  //         }
  //     });
  // }

  const getItemByOutletTEST = (param) => {
    // ApiClient.GET(API.getItemByOutletTEST + param).then(result => {
    //     setState({
    //         menuItem: result,
    //         itemName: result.name,
    //         itemDescription: result.description,
    //         itemPrice: result.price,
    //         itemTaxRate: result.category.outlet.orderTax.rate,
    //         cate: result.category,
    //         detail: result.category.outlet,
    //         taxInfo: result.category.outlet.orderTax,
    //         merInfo: result.category.outlet.merchant,
    //         image: result.category.outlet.merchant.logo,
    //     });
    //     if (result.itemAddOns.length > 0) {
    //         const addOnSingle = result.itemAddOns.filter(i => i.leastSelect == 1)
    //         const addOnMultiple = result.itemAddOns.filter(i => i.leastSelect != 1)
    //         setState({
    //             addOnSingle: addOnSingle.itemAddOns,
    //             addOnMultiple: addOnMultiple.itemAddOns,
    //         })
    //     }
    // });
  };

  // const editItem = () => {
  //     if (!itemPrice) {
  //         //whenever you call state the variable has to be already declared above
  //         Alert.alert(
  //             'Error',
  //             'Please fill up the information',
  //             [{ text: 'OK', onPress: () => { } }],
  //             { cancelable: false },
  //         );
  //         return;
  //     } else {
  //         var body = {
  //             //<- this works, the database is updated
  //             name: itemName,
  //             code: '',
  //             description: description,
  //             price: itemPrice,
  //             url: image,
  //             materialTag: materialTag,
  //             materialSKU: materialSKU,
  //             categoryId: categoryId,
  //             options: productOptions,
  //             material: [{ name: material, qty: qty }],
  //             suppliers: [{ name: supplier }],
  //         };
  //         ApiClient.POST(API.menuItem, body).then((result) => {

  //             if (result) {
  //                 Alert.alert(
  //                     'Success!',
  //                     'You have inserted Success!',
  //                     [
  //                         {
  //                             text: 'OK',
  //                             onPress: () => { },
  //                         },
  //                     ],
  //                     { cancelable: false },
  //                 );
  //             }
  //         });
  //     }
  //     myTextInput.current.clear();
  // }

  // const _fetchResults = () => {
  //     setState({ showOption: true });
  // }

  // const _fetchResults1 = () => {
  //     setState({ showOption1: true });
  // }

  const searchBarItem = () => {
    // ApiClient.GET(
    //     API.searchBarItem +
    //     search +
    //     '&outletId=' +
    //     outletId,
    // ).then((result) => {
    //     setState({ lists: result });
    // });
  };

  const refresh1 = () => {
    setState({ menuItem: [] });
    setState({ cate: [] });
    setState({ detail: [] });
    setState({ taxInfo: [] });
    setState({ merInfo: [] });
    setState({ image: '' });
    //myTextInput.current.clear();
  };

  const addSlot = () => {
    // setState({ showInventory: true });

    if (outletSupplyItems.length > 0) {
      setStockLinkItems([
        ...stockLinkItems,
        {
          outletSupplyItemId: outletSupplyItems[0].uniqueId,
          // sku: outletSupplyItems[0].sku,
          skuMerchant: outletSupplyItems[0].skuMerchant,
          name: outletSupplyItems[0].name,
          unit: outletSupplyItems[0].unit,
          quantityUsage: '',
        },
      ]);
    } else {
      alert('Error: No inventories linked.');
    }
  };

  // const handleChoosePhoto = () => {
  //   const imagePickerOptions = {
  //     mediaType: 'photo',
  //     quality: 0.5,
  //     includeBase64: false,
  //   };

  // launchImageLibrary(imagePickerOptions, (response) => {
  //   if (response.didCancel) {
  //   } else if (response.error) {
  //     Alert.alert(response.error.toString());
  //   } else {
  //     // setState({ image: response.uri });
  //     setImage(response.uri);
  //     setImageType(response.uri.slice(response.uri.lastIndexOf('.')));

  //     setIsImageChanged(true);
  //   }
  // });
  // };

  // const getItemByOutletTEST = param => {
  //     ApiClient.GET(API.getItemByOutletTEST + param).then((result) => {
  //         setState({ menuItem: result });
  //         setState({ cate: result.category });
  //         setState({ detail: result.category.outlet });
  //         setState({ taxInfo: result.category.outlet.orderTax });
  //         setState({ merInfo: result.category.outlet.merchant });
  //         setState({ image: result.category.outlet.merchant.logo });
  //         //setState({material: result.material});
  //         //setState({suppliers: result.suppliers});
  //     });
  // }

  const editItem = () => {
    if (!itemPrice) {
      //whenever you call state the variable has to be already declared above
      alert('Error: Please fill in the information');
      return;
    } else {
      var body = {
        //<- this works, the database is updated
        name: itemName,
        code: '',
        description: description,
        price: itemPrice,
        url: image,
        itemQuantity: +parseFloat(itemQuantity).toFixed(0),
        materialTag: materialTag,
        materialSKU: materialSKU,
        categoryId: categoryId,
        options: [
          {
            name: options,
            least: '1',
            choice: [{ label: options1, price: '1' }],
          },
        ],
        material: [{ name: material, qty: material }],
        suppliers: [{ name: supplier }],
      };
      // ApiClient.POST(API.menuItem, body, false).then((result) => {
      //     console.log("menuItem", result)
      //     if (result) {
      //         Alert.alert(
      //             'Success',
      //             "Product added.",
      //             [
      //                 {
      //                     text: 'OK',
      //                     onPress: () => { },
      //                 },
      //             ],
      //             { cancelable: false },
      //         );
      //     }
      // });
    }
    // myTextInput.current.clear();
  };

  const convertTemplateToExcelFormat = () => {
    var excelTemplate = [];

    for (var i = 0; i < stockLinkItems.length; i++) {
      if (i <= 5) {
        var excelColumn = {
          SKU: stockLinkItems[i].sku,
          Product: stockLinkItems[i].outletSupplyItemId,
          Quantity: stockLinkItems[i].quantityUsage,
          Unit: stockLinkItems[i].unit,
          'Cost Price':
            outletSupplyItemsDict[stockLinkItems[i].outletSupplyItemId].price,
          'Total stock at inventory':
            outletSupplyItemsDict[stockLinkItems[i].outletSupplyItemId]
              .quantity,
        };
        excelTemplate.push(excelColumn);
      } else {
      }
    }

    console.log('excelTemplate');
    console.log(excelTemplate);

    return excelTemplate;
  };

  // const exportTemplate = () => {
  //   const excelTemplate = convertTemplateToExcelFormat();

  //   var excelFile = `${Platform.OS === 'ios'
  //     ? RNFS.DocumentDirectoryPath
  //     : RNFS.DownloadDirectoryPath
  //     }/koodoo-Inventory-Supplier-Product${moment().format(
  //       'YYYY-MM-DD-HH-mm-ss',
  //     )}.xlsx`;
  //   var excelWorkSheet = XLSX.utils.json_to_sheet(excelTemplate);
  //   var excelWorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(
  //     excelWorkBook,
  //     excelWorkSheet,
  //     'Supplier Product Template',
  //   );

  //   const workBookData = XLSX.write(excelWorkBook, {
  //     type: 'binary',
  //     bookType: 'xlsx',
  //   });

  //   RNFS.writeFile(excelFile, workBookData, 'ascii')
  //     .then((success) => {
  //       console.log(`wrote file ${excelFile}`);

  //       Alert.alert(
  //         'Success',
  //         `Sent to ${excelFile}`,
  //         [{ text: 'OK', onPress: () => { } }],
  //         { cancelable: false },
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  const createOutletItem = async () => {
    if (
      !itemPrice ||
      !selectedOutletList ||
      !itemName ||
      !selectedOutletCategoryId
      // !itemDescription ||
      // !itemSKU
    ) {
      //whenever you call state the variable has to be already declared above
      alert('Error: Please fill in the information');

      setLoadingModal(false);

      return;
    } else {
      setLoadingModal(true);

      var taxIdList = []; // for each outlet
      var categoryIdList = []; // for each outlet
      var taxName = '';
      var taxRate = 0.05;

      var categoryName = outletCategoriesDict[selectedOutletCategoryId].name;

      for (var i = 0; i < selectedOutletList.length; i++) {
        const outletIdTemp = selectedOutletList[i];

        if (outletsTaxDict[outletIdTemp]) {
          taxIdList.push(outletsTaxDict[outletIdTemp].uniqueId);

          taxName = outletsTaxDict[outletIdTemp].name;
          taxRate = outletsTaxDict[outletIdTemp].rate;
        } else {
          taxIdList.push('');
        }

        if (allOutletsCategoriesNameDict[categoryName]) {
          var isSameCategoryNameExisted = false;
          var categoryIdTemp = '';

          for (
            var j = 0;
            j < allOutletsCategoriesNameDict[categoryName].length;
            j++
          ) {
            if (
              allOutletsCategoriesNameDict[categoryName][j].outletId ===
              outletIdTemp
            ) {
              isSameCategoryNameExisted = true;
              categoryIdTemp =
                allOutletsCategoriesNameDict[categoryName][j].uniqueId;
              break;
            }
          }

          categoryIdList.push(categoryIdTemp);
        } else {
          categoryIdList.push('');
        }
      }

      if (selectedProductEdit === null) {
        // means new item

        ///////////////////////////////////
        // upload image

        var outletItemImagePath = '';
        var outletItemIdLocal = selectedProductEdit
          ? selectedProductEdit.commonId
          : uuidv4();

        if (image && imageType) {
          // outletItemIdLocal = selectedProductEdit.uniqueId;

          outletItemImagePath = await uploadImageToFirebaseStorage(
            {
              uri: image,
              type: imageType,
            },
            `/merchant/${merchantId}/outletItem/${outletItemIdLocal}/image${imageType}`,
          );
        }

        // if (image && imageType) {
        //     outletItemImagePath = await uploadImageToFirebaseStorage({
        //         uri: image,
        //         type: imageType,
        //     }, `/merchant/${merchantId}/outletItem/${outletItemIdLocal}/image${imageType}`);
        // }

        ///////////////////////////////////

        var body = {
          merchantId: merchantId,
          // outletId: place,
          name: itemName,
          // categoryId: selectedOutletCategoryId,
          code: '',
          currency: 'MYR',
          description: itemDescription || '',
          image: outletItemImagePath,
          price: +parseFloat(itemPrice).toFixed(2),
          itemQuantity: +parseFloat(itemQuantity).toFixed(0),
          // taxId: outletsTaxDict[place].uniqueId, // some outlets might not got tax at first place, need create
          isActive: true,
          isComposite: true,
          isDelivery: true,
          isSellable: true,
          isTakeaway: true,
          options: '',
          prepareTime: 600,
          // sku: itemName,
          sku: uuidv4(),

          skuMerchant: itemSKU || '',

          //stockLinkItems: stockLinkItems,

          stockLinkItems: stockLinkItems.map((item) => ({
            outletSupplyItemId: item.outletSupplyItemId,
            skuMerchant: item.skuMerchant,
            name: item.name,
            unit: item.unit,
            quantityUsage: !isNaN(item.quantityUsage) ? +parseFloat(item.quantityUsage).toFixed(2) : 0,
          })),

          outletItemIdLocal: outletItemIdLocal,
          commonId: outletItemIdLocal,

          variantGroupList: variantGroupList.map((item) => ({
            groupId: item.groupId,
            choices: item.choices.map((param) => ({
              choiceId: param.choiceId,
              choiceName: param.choiceName,
              choicePrice: !isNaN(param.choicePrice) ? +parseFloat(param.choicePrice).toFixed(2) : 0,
            })),
            name: item.name,
            minSelect: item.minSelect,
            maxSelect: item.maxSelect,
          })),

          addOnGroupList: addOnGroupList.map((item) => ({
            groupId: item.groupId,
            choices: item.choices.map((param) => ({
              choiceId: param.choiceId,
              choiceName: param.choiceName,
              choicePrice: !isNaN(param.choicePrice) ? +parseFloat(param.choicePrice).toFixed(2) : 0,
              choiceMinSelect: !isNaN(param.choiceMinSelect) ? param.choiceMinSelect : 0,
              choiceMaxSelect: !isNaN(param.choiceMaxSelect) ? param.choiceMaxSelect : 0,
            })),
            name: item.name,
          })),

          //////////////
          // multi outlet supports

          categoryIdList: categoryIdList,
          categoryName: outletCategoriesDict[selectedOutletCategoryId].name,

          outletIdList: selectedOutletList,

          taxIdList: taxIdList,
          taxName: taxName,
          taxRate: taxRate,

          crmUserTagIdList: selectedUserTagList,
          searchingUserTagText: searchingUserTagText,

          printerAreaList: selectedPrinterAreaList,
        };

        console.log(body);

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        setIsSave(true);

        ApiClient.POST(API.createOutletItem, body, false).then((result) => {
          if (result && result.status === 'success') {
            setVariantGroupList([]);
            setAddOnGroupList([]);

            if (window.confirm('Success: Product has been added')) {
              setLoadingModal(false);
              navigation.goBack();
            }
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });
          setIsSave(false);
        });
      } else {
        // means existing item
        // setLoadingModal(false);

        var outletItemImagePath = '';
        var outletItemIdLocal = selectedProductEdit.uniqueId;

        if (image && imageType && isImageChanged) {
          // outletItemIdLocal = selectedProductEdit.uniqueId;

          outletItemImagePath = await uploadImageToFirebaseStorage(
            {
              uri: image,
              type: imageType,
            },
            `/merchant/${merchantId}/outletItem/${outletItemIdLocal}/image${imageType}`,
          );
        }

        ///////////////////////////////////

        var body = {
          merchantId: merchantId,
          // outletId: place,
          name: itemName,
          // categoryId: selectedOutletCategoryId,
          code: '',
          currency: 'MYR',
          description: itemDescription || '',
          image: outletItemImagePath,
          price: +parseFloat(itemPrice).toFixed(2),
          itemQuantity: +parseFloat(itemQuantity).toFixed(0),
          // taxId: outletsTaxDict[place].uniqueId,
          isActive: true,
          isComposite: true,
          isDelivery: true,
          isSellable: true,
          isTakeaway: true,
          options: '',
          prepareTime: 600,
          sku: uuidv4(),

          skuMerchant: itemSKU || '',

          outletItemIdLocal: outletItemIdLocal,
          commonId: outletItemIdLocal,

          stockLinkItems: stockLinkItems.map((item) => ({
            outletSupplyItemId: item.outletSupplyItemId,
            skuMerchant: item.skuMerchant,
            name: item.name,
            unit: item.unit,
            quantityUsage: !isNaN(item.quantityUsage) ? +parseFloat(item.quantityUsage).toFixed(2) : 0,
          })),

          variantGroupList: variantGroupList.map((item) => ({
            groupId: item.groupId,
            choices: item.choices.map((param) => ({
              choiceId: param.choiceId,
              choiceName: param.choiceName,
              choicePrice: !isNaN(param.choicePrice) ? +parseFloat(param.choicePrice).toFixed(2) : 0,
            })),
            name: item.name,
            minSelect: item.minSelect,
            maxSelect: item.maxSelect,
          })),

          addOnGroupList: addOnGroupList.map((item) => ({
            groupId: item.groupId,
            choices: item.choices.map((param) => ({
              choiceId: param.choiceId,
              choiceName: param.choiceName,
              choicePrice: !isNaN(param.choicePrice) ? +parseFloat(param.choicePrice).toFixed(2) : 0,
              choiceMinSelect: !isNaN(param.choiceMinSelect) ? param.choiceMinSelect : 0,
              choiceMaxSelect: !isNaN(param.choiceMaxSelect) ? param.choiceMaxSelect : 0,
            })),
            name: item.name,
          })),

          //////////////
          // multi outlet supports

          categoryIdList: categoryIdList,
          categoryName: outletCategoriesDict[selectedOutletCategoryId].name,

          outletIdList: selectedOutletList,

          taxIdList: taxIdList,
          taxName: taxName,
          taxRate: taxRate,

          //////////////
          // for update purpose

          editItemId: selectedProductEdit.uniqueId,
          editItemSku: selectedProductEdit.sku,
          editItemImage: selectedProductEdit.image,

          crmUserTagIdList: selectedUserTagList,
          searchingUserTagText: searchingUserTagText,

          printerAreaList: selectedPrinterAreaList,
        };

        console.log(body);

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        setIsSave(true);

        ApiClient.POST(API.updateOutletItem, body, false).then((result) => {
          if (result && result.status === 'success') {
            if (window.confirm('Success: Product has been updated')) {
              setLoadingModal(false);
              navigation.goBack();
            }
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });

          setIsSave(false);
        });
      }
    }
  };

  const deleteOutletItem = async () => {
    // means existing item

    var outletItemImagePath = '';
    var outletItemIdLocal = selectedProductEdit.uniqueId;

    ///////////////////////////////////

    var body = {
      //<- this works, the database is updated
      // name: itemName,
      // code: '',
      // description: description,
      // price: itemPrice,
      // url: image,
      // materialTag: materialTag,
      // materialSKU: materialSKU,
      // categoryId: categoryId,
      // options: [
      //     {
      //         name: options,
      //         least: '1',
      //         choice: [{ label: options1, price: '1' }],
      //     },
      // ],
      // material: [{ name: material, qty: material }],
      // suppliers: [{ name: supplier }],

      merchantId: merchantId,
      // outletId: place,
      name: itemName,
      // categoryId: selectedOutletCategoryId,
      code: '',
      currency: 'MYR',
      description: itemDescription,
      // image: outletItemImagePath,
      price: itemPrice,
      // taxId: outletsTaxDict[place].uniqueId,
      isActive: true,
      isComposite: true,
      isDelivery: true,
      isSellable: true,
      isTakeaway: true,
      options: '',
      prepareTime: 600,
      sku: itemName,

      skuMerchant: itemSKU,

      stockLinkItems: stockLinkItems,

      // outletItemIdLocal: outletItemIdLocal,
      // commonId: outletItemIdLocal,

      variantGroupList: variantGroupList,
      addOnGroupList: addOnGroupList,

      //////////////
      // multi outlet supports

      // categoryIdList: categoryIdList,
      // categoryName: outletCategoriesDict[selectedOutletCategoryId].name,

      outletIdList: selectedOutletList,

      // taxIdList: taxIdList,
      // taxName: taxName,
      // taxRate: taxRate,

      //////////////
      // for delete purpose

      editItemId: selectedProductEdit.uniqueId,
      editItemSku: selectedProductEdit.sku,
      editItemImage: selectedProductEdit.image,

      printerAreaList: selectedPrinterAreaList,
    };

    console.log(body);

    CommonStore.update((s) => {
      s.isLoading = true;
    });

    setIsDelete(true);

    ApiClient.POST(API.deleteOutletItem, body, false).then((result) => {
      if (result && result.status === 'success') {
        if (window.confirm('Success: Product has been deleted')) {
          navigation.goBack();
        }
      }

      CommonStore.update((s) => {
        s.isLoading = false;
      });

      setIsDelete(false);
    });
  };

  //Back button for add/update product
  //When press ask user save product already?
  const goBackButton = async () => {
    if (window.confirm('Alert: Have you saved your work?'))
      props.navigation.goBack()
    return
  };

  const _fetchResults = () => {
    setState({ showOption: true });
  };

  const _fetchResults1 = () => {
    setState({ showOption1: true });
  };

  const refresh = () => {
    // ApiClient.GET(API.merchantMenu + outletId).then((result) => {
    //     if (result.length > 0) {
    //         var productListRaw = [];
    //         result.forEach((element) => {
    //             productListRaw = productListRaw.concat(element.items);
    //             const activeItem = productListRaw.filter(item => item.active == 1)
    //             setState({ productList: productListRaw, productList2: activeItem, }, () => { });
    //         });
    //     }
    // });
  };

  const refreshMenu = () => {
    // ApiClient.GET(API.activeMenu + outletId).then((result) => {
    //     if (choice != null) {
    //         const selectedCat = result.filter(i => i.name == choice,)
    //         setState({ currentMenu: selectedCat })
    //     }
    //     // }else{
    //     //   setState({ currentMenu: result });
    //     // }
    // });
  };

  const categoryFunc = () => {
    // ApiClient.GET(API.getCategory + 1).then((result) => {
    //     const tmpCategories = {};
    //     for (const category of result) {
    //         const categoryName = category.name
    //         const categoryId = category.id
    //         if (!tmpCategories[categoryName]) {
    //             tmpCategories[categoryName] = {
    //                 label: categoryName,
    //                 value: categoryId,
    //             };
    //         }
    //     }
    //     const categories = Object.values(tmpCategories);
    //     console.log("categories", categories)
    //     setState({ categoryOutlet: categories });
    // });
  };
  const changeMenu = (param) => {
    changeItem(param.item.id);

    var menu = currentMenu;
    menu.push(param);

    setState({ currentMenu: menu });
  };

  const menucount = () => {
    // ApiClient.GET(API.merchantMenu + outletId).then((result) => {
    //     if (result.length > 0) {
    //         var productListRaw1 = [];
    //         result.forEach((element) => {
    //             productListRaw1 = productListRaw1.concat(element.items);
    //         });
    //     }
    //     productListRaw1.forEach((element) => {
    //         const menuItemCount = productListRaw1.filter(element => element.active === 1)
    //         setState({ menuCount: menuItemCount.length })
    //     });
    // });
  };

  const setActiveMenu = (param) => {
    var body = {
      outletId: outletId,
      itemId: param,
    };
    // ApiClient.POST(API.setActiveMenu, body).then((result) => {
    //     refresh();

    // });
  };

  const deleteCartItem = (param) => {
    var list = choosenMenu;
    var newList = [];
    if (list.length > 1) {
      list.forEach((element) => {
        if (element.name != param) {
          newList.push(element);
          setState({ choosenMenu: newList });
        }
      });
    } else {
      if (list[0].name == param) {
        setState({ choosenMenu: [5] });
      }
    }
  };

  const renderMenuList = ({ item }) => (
    <View style={{ flexDirection: 'row' }}>
      <View style={{ flex: 1, flexDirection: 'row' }}>
        <View style={styles.content6}></View>
        <View style={{ marginLeft: 20, marginVertical: 10 }}>
          <Text style={{ fontSize: 11, fontFamily: 'NunitoSans-Bold' }}>
            {item.name}
          </Text>
          <Text
            style={{
              fontSize: 10,
              color: Colors.primaryColor,
              fontFamily: 'NunitoSans-Regular',
            }}>
            RM {item.price}
          </Text>
        </View>
      </View>
      <View
        style={{
          backgroundColor: '#bbfaed',
          width: 60,
          height: 20,
          marginLeft: 60,
          marginVertical: 10,
          borderRadius: 5,
        }}>
        <TouchableOpacity
          onPress={() => {
            deleteCartItem(item.name);
          }}>
          <Text style={{ alignSelf: 'center', fontFamily: 'NunitoSans-Regular' }}>
            remove
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  const changeSection = (data) => {
    var array = choosenMenu;
    array.push(data);
    setState({ choosenMenu: array });
  };

  const renderMenu = ({ item }) => (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      {item.items.map((i) => {
        return (
          <View
            style={{
              backgroundColor: '#717378',
              width: (Dimensions.get('window').width - 550) / 4,
              height: (Dimensions.get('window').width - 550) / 4,
              borderRadius: 10,
              marginLeft: 30,
              marginBottom: 30,
            }}>
            <View style={styles.content4}>
              <Text
                style={{
                  textAlign: 'center',
                  fontFamily: 'NunitoSans-Bold',
                  fontSize: 15,
                }}>
                RM {i.price}
              </Text>
            </View>
            <Text
              style={{
                color: Colors.whiteColor,
                alignSelf: 'center',
                padding: 10,
                position: 'absolute',
                fontSize: 16,
                fontFamily: 'NunitoSans-Bold',
                bottom: 5,
              }}>
              {i.name}
            </Text>
          </View>
        );
      })}
    </View>
  );

  const outletMenu = () => (
    <View
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: Dimensions.get('window').height,
      }}>
      <View style={styles.confirmMenu}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 20,
          }}>
          <View
            style={{
              justifyContent: 'center',
              width: '50%',
              flex: 1,
            }}>
            <Text
              style={{
                fontSize: 30,
                fontFamily: 'NunitoSans-Bold',
                textAlign: 'center',
              }}>
              List of Menu
            </Text>
          </View>
          <View>
            <TouchableOpacity
              onPress={() => {
                setState({ modal: false });
              }}>
              <View style={{ padding: 10 }}>
                <Close name="closecircle" size={30} />
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20,
          }}>
          <View
            style={{
              height: 30,
              width: '5%',
            }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 18,
                fontFamily: 'NunitoSans-Regular',
              }}>
              ID
            </Text>
          </View>
          <View style={{ height: 30, width: '50%' }}>
            <Text
              style={{
                textAlign: 'center',
                fontSize: 18,
                fontFamily: 'NunitoSans-Regular',
              }}>
              Food Name
            </Text>
          </View>
          <View></View>
        </View>
        {productList2.map((elements) => {
          return (
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: 20,
                }}>
                <View
                  style={{
                    height: 30,
                    width: '5%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: 18,
                      fontFamily: 'NunitoSans-Regular',
                    }}>
                    {elements.id}
                  </Text>
                </View>
                <View style={{ height: 30, width: '50%' }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: 18,
                      fontFamily: 'NunitoSans-Regular',
                    }}>
                    {elements.name}
                  </Text>
                </View>
                <View>
                  <TouchableOpacity
                    onPress={
                      () => {
                        changeMenu(elements);
                      }
                      // setState({itemId: elements.id});
                      // addActiveMenu(elements.id);
                    }>
                    <View
                      style={{
                        alignSelf: 'flex-end',
                      }}>
                      <Close name="plus" size={28} />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          );
        })}
      </View>
    </View>
  );

  const changeItem = (itemID) => {
    // if (!outletId || !currentMenu || !selectedCategoryId) {

    //   Alert.alert(
    //     'Error',
    //     'Please fill up the information',
    //     [{ text: 'OK', onPress: () => { } }],
    //     { cancelable: false },
    //   );
    //   return;
    // } else {
    //   var menuID = [];
    //   currentMenu.forEach((element) => {
    //     menuID.push(element.id);
    //   });
    // }
    var body = {
      outletId: outletId,
      itemId: itemID,
      // newCategoryId: selectedCategoryId,
      // active: true
    };

    // ApiClient.POST(API.changeItem, body).then((result) => {

    //     if (result.success == true) {
    //         // setState({modal: false});
    //         // refreshTable();
    //         Alert.alert(
    //             'Success',
    //             'You have add the menu',
    //             [
    //                 {
    //                     text: 'OK',
    //                     onPress: () => {
    //                         setState({ modal: false });
    //                     },
    //                 },
    //             ],
    //             { cancelable: false },
    //         );
    //     }
    // });
  };
  const deleteCurrentMenu = (itemID) => {
    if (!outletId || !currentMenu) {
      alert('Error: Please fill in the information');
      return;
    } else {
      var menuID = [];
      currentMenu.forEach((element) => {
        menuID.push(element.id);
      });
    }
    var body = {
      outletId: outletId,
      itemId: itemID,
    };
    // ApiClient.POST(API.setActiveMenu, body).then((result) => {

    //     if (result == true) {
    //         refreshMenu();
    //         Alert.alert(
    //             'Success',
    //             'You have deleted the menu',
    //             [
    //                 {
    //                     text: 'OK',
    //                     onPress: () => {
    //                         // setState({modal: false});
    //                     },
    //                 },
    //             ],
    //             { cancelable: false },
    //         );
    //     }
    // });
  };

  // const addCurrentMenu = () => {
  //   if (!name || !category || outletId != null) {
  //     Alert.alert(
  //       'Error',
  //       'Please fill in the information',
  //       [{ text: 'OK', onPress: () => { } }],
  //       { cancelable: false },
  //     );
  //     return;
  //   } else {
  //     var body = {
  //       itemId: name,
  //       newCategoryId: category,
  //       outletId: outletId,
  //     };
  //     // ApiClient.POST(API.addCurrentMenu, body).then((result) => {
  //     //     if (result == true) {
  //     //         refreshTable();
  //     //         Alert.alert(
  //     //             'Success',
  //     //             'You have add to the menu',
  //     //             [
  //     //                 {
  //     //                     text: 'OK',
  //     //                     // onPress: () => {
  //     //                     //   setState({modal: false});
  //     //                     // },
  //     //                 },
  //     //             ],
  //     //             { cancelable: false },
  //     //         );
  //     //     }
  //     // });
  //   }
  // };

  const deleteItem = (param) => {
    var body = {
      itemId: param,
    };

    // ApiClient.POST(API.deleteMenuItem, body).then((result) => {
    //     if (result.success == true) {
    //         refreshMenu();
    //         Alert.alert(
    //             'Success',
    //             'The product has been deleted',
    //             [{ text: 'OK', onPress: () => { } }],
    //             { cancelable: false },
    //         );

    //     }
    //     if (result.success == false) {
    //         refreshMenu();
    //         Alert.alert(
    //             'Failed',
    //             'The product has not been deleted',
    //             [{ text: 'OK', onPress: () => { } }],
    //             { cancelable: false },
    //         );

    //     }

    // });
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity
      onPress={() => {
        setState({
          addProductItem: false,
          addMenuItem: false,
          addProduct: true,
        });
        getItemByOutletTEST(item.id);
      }}>
      <View
        style={{
          backgroundColor: '#FFFFFF',
          flexDirection: 'row',
          paddingVertical: 20,
          paddingHorizontal: 20,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: '#C4C4C4',
        }}>
        <Image
          source={{
            //item.image
            uri: 'http://streetfoodaffair.co.uk/wp-content/uploads/2017/04/Polpetta.jpg',
          }}
          style={{ width: 20, height: 20 }}
        />
        <Text style={{ width: '2%', fontFamily: 'NunitoSans-Bold' }}></Text>
        <Text style={{ width: '14%', fontFamily: 'NunitoSans-Bold' }}>
          {item.name}
        </Text>
        <Text style={{ width: '15%', fontFamily: 'NunitoSans-Bold' }}>
          {item.category}
        </Text>
        <Text
          style={{
            width: '8%',
            fontFamily: 'NunitoSans-Regular',
            color: item.active ? Colors.primaryColor : Colors.fieldtTxtColor,
            marginLeft: 30,
          }}>
          {item.active ? 'Available' : 'Out of Stock'}
        </Text>
        <Switch
          style={{
            marginBottom: 5,
            width: '5%',
            transform:
              Platform.OS == 'ios'
                ? [{ scaleX: 0.6 }, { scaleY: 0.6 }]
                : [{ scaleX: 0.8 }, { scaleY: 0.8 }],
          }}
          value={item.active == 1 ? true : false}
          onSyncPress={(status) =>
            setState({ status }, () => {
              setActiveMenu(item.id);
            })
          }
          circleColorActive={Colors.primaryColor}
          circleColorInactive={Colors.fieldtTxtColor}
          backgroundActive="#dddddd"
        />
        <Text style={{ width: '2%', fontFamily: 'NunitoSans-Bold' }}></Text>
        <Text style={{ width: '12%', fontFamily: 'NunitoSans-Bold' }}>
          RM {item.price}
        </Text>
        <Text
          style={{
            width: '20%',
            marginRight: 20,
            fontFamily: 'NunitoSans-Regular',
          }}>
          {item.description}
        </Text>
        <Text style={{ width: '7%', fontFamily: 'NunitoSans-Bold' }}>
          {item.code}
        </Text>
      </View>
    </TouchableOpacity>
  );

  // const renderSearchItem = ({ item }) =>
  //   item.items.map((elements) => {
  //     return (
  //       <TouchableOpacity
  //         onPress={() => {
  //           setState({
  //             addProductItem: false,
  //             addMenuItem: false,
  //             addProduct: true,
  //           });
  //           getItemByOutletTEST(elements.id);
  //         }}>
  //         <View
  //           style={{
  //             backgroundColor: '#FFFFFF',
  //             flexDirection: 'row',
  //             paddingVertical: 20,
  //             paddingHorizontal: 20,
  //             borderBottomWidth: StyleSheet.hairlineWidth,
  //             borderBottomColor: '#C4C4C4',
  //           }}>
  //           <AsyncImage
  //             source={{
  //               //elements.image
  //               uri: elements.image,
  //               //'http://streetfoodaffair.co.uk/wp-content/uploads/2017/04/Polpetta.jpg',
  //             }}
  //             item={elements}
  //             style={{ width: 20, height: 20 }}
  //           />
  //           <Text
  //             style={{ width: '2%', fontFamily: 'NunitoSans-Regular' }}></Text>
  //           <Text style={{ width: '14%', fontFamily: 'NunitoSans-Regular' }}>
  //             {elements.name}
  //           </Text>
  //           <Text style={{ width: '15%', fontFamily: 'NunitoSans-Regular' }}>
  //             {elements.category}
  //           </Text>
  //           <Text style={{ width: '8%', fontFamily: 'NunitoSans-Regular' }}>
  //             {elements.active ? 'Available' : 'Out of Stock'}
  //           </Text>
  //           <Switch
  //             style={{ marginTop: 0, width: '10%' }}
  //             value={elements.active ? status : !status}
  //             onSyncPress={(status) => setState({ status })}
  //             circleColorActive={Colors.primaryColor}
  //             circleColorInactive={Colors.fieldtTxtColor}
  //             backgroundActive="#dddddd"
  //           />
  //           <Text
  //             style={{ width: '2%', fontFamily: 'NunitoSans-Regular' }}></Text>
  //           <Text style={{ width: '16%', fontFamily: 'NunitoSans-Regular' }}>
  //             {elements.price}
  //           </Text>
  //           <Text
  //             style={{
  //               width: '20%',
  //               marginRight: 20,
  //               fontFamily: 'NunitoSans-Regular',
  //             }}>
  //             {elements.description}
  //           </Text>
  //           <Text style={{ width: '7%', fontFamily: 'NunitoSans-Regular' }}>
  //             {elements.code}
  //           </Text>
  //         </View>
  //       </TouchableOpacity>
  //     );
  //   });

  const createCRMUserTagOrAddCRMUserTagToProduct = async () => {
    var body = {
      crmUserTagIdList: selectedUserTagList,
      merchantId: merchantId,

      outletItemId: selectedProductEdit.uniqueId,

      searchingUserTagText: searchingUserTagText,
    };

    // clear searching text after submit
    setSearchingUserTagText('');

    CommonStore.update((s) => {
      s.isLoading = true;
    });

    ApiClient.POST(
      API.createCRMUserTagOrAddCRMUserTagToProduct,
      body,
      false,
    ).then((result) => {
      if (result && result.status === 'success') {
        alert('Success: Tag(s) have been save to product');

        if (result.outletItem) {
          CommonStore.update((s) => {
            s.selectedProductEdit = result.outletItem;
          });
        }

        setTagModal(false);
      }

      CommonStore.update((s) => {
        s.isLoading = false;
      });
    });
  };

  // function end

  const onadditem = () => {
    const productOptions = productOptionsState;

    productOptions.push({
      optionId: (productOptions.length + 1).toString(),
      name: '',
      price: 0,
    });
    setState({ productOptions });
  };
  const onadditem1 = () => {
    const productOptions1 = productOptions1State;
    productOptions1.push({
      optionId: (productOptions1.length + 1).toString(),
      name: '',
      min: 0,
      max: 0,
    });
    setState({ productOptions1 });
  };

  // const getName = () => {
  //     const productOptions = productOptions;
  //     var name = null;
  //     var i;
  //     for (i = 0; i < productOptions.length; i++) {
  //         if (productOptions[i].optionId != null) {
  //             name = productOptions[i].name;
  //         }
  //     }
  //     return name;
  // }

  const getName = () => {
    const productOptions = productOptionsState;
    var name = null;
    var i;
    for (i = 0; i < productOptions.length; i++) {
      if (productOptions[i].optionId != null) {
        name = productOptions[i].name;
      }
    }
    return name;
  };

  const renderVariantGroupChoices = () => {
    const productOptions = variantGroupList[variantGroupIndex].choices;

    return (
      <>
        {productOptions.map((option, i) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: switchMerchant ? 0 : 10,
                alignContent: 'space-between',
                height: 50,
                width: '100%',
                // backgroundColor: 'red',
              }}>
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: switchMerchant ? 100 : 120,
                  height: switchMerchant ? 35 : 40,
                  borderRadius: 5,
                  padding: 5,
                  marginVertical: 5,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  paddingLeft: 10,
                  marginRight: '10%',
                  fontSize: switchMerchant ? 10 : 14,
                }}
                placeholder="Option Name"
                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                onChangeText={(text) => {
                  // const productOptions = productOptionsState;
                  // const item = productOptions.find(
                  //     (obj) => obj.id === productOptions.id,
                  // );
                  // item.name = text;
                  // setState({ productOptions });

                  setVariantGroupList(
                    variantGroupList.map((variantGroup, index) =>
                      index === variantGroupIndex
                        ? {
                          ...variantGroup,
                          choices: [
                            ...variantGroup.choices.map(
                              (choice, choiceIndex) =>
                                choiceIndex === i
                                  ? {
                                    ...choice,
                                    choiceName: text,
                                  }
                                  : choice,
                            ),
                          ],
                        }
                        : variantGroup,
                    ),
                  );
                }}
                // value={(value) => {
                //     const productOptions = productOptionsState;
                //     const item = productOptions.find(
                //         (obj) => obj.id === productOptions.id,
                //     );
                //     value = item.name;
                // }}
                value={
                  variantGroupList[variantGroupIndex].choices[i].choiceName
                }
                // multiline={true}
                ref={myTextInput}
              />
              <Text
                style={{
                  fontSize: switchMerchant ? 10 : 20,
                  fontFamily: 'NunitoSans-Bold',
                  marginRight: '5%',
                }}>
                RM
              </Text>
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: 60,
                  height: switchMerchant ? 35 : 40,
                  borderRadius: 5,
                  padding: 5,
                  marginVertical: 5,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  paddingLeft: 10,
                  marginRight: '10%',
                  fontSize: switchMerchant ? 10 : 14,
                }}
                placeholder="0.00"
                onChangeText={(text) => {
                  // const productOptions = productOptionsState;
                  // const item = productOptions.find(
                  //     (obj) => obj.id === productOptions.id,
                  // );
                  // item.price = text;
                  // setState({ productOptions });

                  setVariantGroupList(
                    variantGroupList.map((variantGroup, index) =>
                      index === variantGroupIndex
                        ? {
                          ...variantGroup,
                          choices: [
                            ...variantGroup.choices.map(
                              (choice, choiceIndex) =>
                                choiceIndex === i
                                  ? {
                                    ...choice,
                                    choicePrice:
                                      text.length > 0 ? text : '0.00',
                                  }
                                  : choice,
                            ),
                          ],
                        }
                        : variantGroup,
                    ),
                  );
                }}
                // value={(value) => {
                //     const productOptions = productOptionsState;
                //     const item = productOptions.find(
                //         (obj) => obj.id === productOptions.id,
                //     );
                //     value = item.price;
                // }}
                value={
                  variantGroupList[variantGroupIndex].choices[i].choicePrice
                }
                // multiline={true}
                ref={myTextInput}
                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
              />

              {i === 0 ? (
                <TouchableOpacity
                  onPress={() => {
                    // onadditem();
                    setVariantGroupList(
                      variantGroupList.map((variantGroup, index) =>
                        index === variantGroupIndex
                          ? {
                            ...variantGroup,
                            choices: [
                              ...variantGroup.choices,
                              {
                                choiceId: '',
                                choiceName: '',
                                choicePrice: '',
                              },
                            ],
                          }
                          : variantGroup,
                      ),
                    );
                  }}
                  style={{
                    backgroundColor: Colors.whiteColor,
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}>
                  <Icon
                    name="plus-circle"
                    size={switchMerchant ? 17 : 20}
                    color={Colors.primaryColor}
                  />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={{}}
                  onPress={() => {
                    // setVariantGroupList([
                    //     ...variantGroupList.slice(0, variantGroupIndex),
                    //     ...variantGroupList.slice(variantGroupIndex + 1),
                    // ]
                    // );
                    setVariantGroupList(
                      variantGroupList.map((variantGroup, index) =>
                        index === variantGroupIndex
                          ? {
                            ...variantGroup,
                            choices: [
                              ...variantGroup.choices.slice(0, i),
                              ...variantGroup.choices.slice(i + 1),
                            ],
                          }
                          : variantGroup,
                      ),
                    );
                  }}>
                  <Icon
                    name="minus-circle"
                    size={switchMerchant ? 17 : 20}
                    color="#eb3446"
                  />
                </TouchableOpacity>
              )}
            </View>
          );
        })}
      </>
    );
  };

  const renderAddOnGroupChoices = () => {
    const productOptions = addOnGroupList[addOnGroupIndex].choices;

    return (
      <>
        {productOptions.map((option, i) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-end',
                marginTop: switchMerchant ? 10 : 20,
                alignContent: 'space-between',
                height: 50,
                width: switchMerchant ? '100%' : '93%',
              }}>
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: switchMerchant ? '23%' : '27%',
                  height: switchMerchant ? 35 : 40,
                  borderRadius: 5,
                  padding: 5,
                  marginVertical: 5,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  paddingLeft: 10,
                  marginRight: switchMerchant ? '4%' : '5%',
                  marginTop: 5,
                  fontSize: switchMerchant ? 10 : 14,
                }}
                placeholder="Option Name"
                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                onChangeText={(text) => {
                  // const productOptions1 = productOptions1State;
                  // const item = productOptions1.find(
                  //     (obj) => obj.id === productOptions1.id,
                  // );
                  // item.name = text;
                  // setState({ productOptions1 });

                  setAddOnGroupList(
                    addOnGroupList.map((addOnGroup, index) =>
                      index === addOnGroupIndex
                        ? {
                          ...addOnGroup,
                          choices: [
                            ...addOnGroup.choices.map((choice, choiceIndex) =>
                              choiceIndex === i
                                ? {
                                  ...choice,
                                  choiceName: text,
                                }
                                : choice,
                            ),
                          ],
                        }
                        : addOnGroup,
                    ),
                  );
                }}
                // value={(value) => {
                //     const productOptions1 = productOptions1State;
                //     const item = productOptions1.find(
                //         (obj) => obj.id === productOptions1.id,
                //     );
                //     value = item.name;
                // }}
                value={addOnGroupList[addOnGroupIndex].choices[i].choiceName}
                //multiline={true}
                ref={myTextInput}
              />
              <View
                style={{
                  flexDirection: 'column',
                  width: '12%',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: 'NunitoSans-Bold',
                    color: Colors.fieldtTxtColor,
                    //marginRight: '1%',
                    //marginLeft: '1%',
                  }}>
                  MIN
                </Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  style={{
                    backgroundColor: Colors.fieldtBgColor,
                    width: switchMerchant ? 30 : 60,
                    height: switchMerchant ? 35 : 40,
                    borderRadius: 5,
                    padding: 5,
                    marginVertical: 5,
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    paddingLeft: 10,
                    fontSize: switchMerchant ? 10 : 14,
                  }}
                  placeholder="MIN"
                  onChangeText={(text) => {
                    // const productOptions1 = productOptions1State;
                    // const item = productOptions1.find(
                    //     (obj) => obj.id === productOptions1.id,
                    // );
                    // item.min = text;
                    // setState({ productOptions1 });

                    setAddOnGroupList(
                      addOnGroupList.map((addOnGroup, index) =>
                        index === addOnGroupIndex
                          ? {
                            ...addOnGroup,
                            choices: [
                              ...addOnGroup.choices.map(
                                (choice, choiceIndex) =>
                                  choiceIndex === i
                                    ? {
                                      ...choice,
                                      choiceMinSelect:
                                        text.length > 0
                                          ? parseInt(text)
                                          : 0,
                                    }
                                    : choice,
                              ),
                            ],
                          }
                          : addOnGroup,
                      ),
                    );
                  }}
                  // value={(value) => {
                  //     const productOptions1 = productOptions1State;
                  //     const item = productOptions1.find(
                  //         (obj) => obj.id === productOptions1.id,
                  //     );
                  //     value = item.min;
                  // }}
                  value={addOnGroupList[addOnGroupIndex].choices[
                    i
                  ].choiceMinSelect.toFixed(0)}
                  //multiline={true}
                  ref={myTextInput}
                  placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                />
              </View>
              <Text
                style={{
                  fontSize: switchMerchant ? 10 : 20,
                  fontFamily: 'NunitoSans-Bold',
                  marginRight: switchMerchant ? '2%' : '4%',
                  marginLeft: switchMerchant ? '2%' : '4%',
                  marginBottom: switchMerchant ? '5%' : '3%',
                }}>
                -
              </Text>
              <View
                style={{
                  flexDirection: 'column',
                  width: '12%',
                  alignItems: 'center',
                }}>
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: 'NunitoSans-Bold',
                    color: Colors.fieldtTxtColor,
                    //marginRight: '1%',
                    //marginLeft: '1%',
                  }}>
                  MAX
                </Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  style={{
                    backgroundColor: Colors.fieldtBgColor,
                    width: switchMerchant ? 30 : 60,
                    height: switchMerchant ? 35 : 40,
                    borderRadius: 5,
                    padding: 5,
                    marginVertical: 5,
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    paddingLeft: 10,
                    marginRight: '5%',
                    fontSize: switchMerchant ? 10 : 14,
                  }}
                  placeholder="MAX"
                  onChangeText={(text) => {
                    // const productOptions1 = productOptions1State;
                    // const item = productOptions1.find(
                    //     (obj) => obj.id === productOptions1.id,
                    // );
                    // item.max = text;
                    // setState({ productOptions1 });

                    setAddOnGroupList(
                      addOnGroupList.map((addOnGroup, index) =>
                        index === addOnGroupIndex
                          ? {
                            ...addOnGroup,
                            choices: [
                              ...addOnGroup.choices.map(
                                (choice, choiceIndex) =>
                                  choiceIndex === i
                                    ? {
                                      ...choice,
                                      choiceMaxSelect:
                                        text.length > 0
                                          ? parseInt(text)
                                          : 0,
                                    }
                                    : choice,
                              ),
                            ],
                          }
                          : addOnGroup,
                      ),
                    );
                  }}
                  // value={(value) => {
                  //     const productOptions1 = productOptions1State;
                  //     const item = productOptions1.find(
                  //         (obj) => obj.id === productOptions1.id,
                  //     );
                  //     value = item.max;
                  // }}
                  value={addOnGroupList[addOnGroupIndex].choices[
                    i
                  ].choiceMaxSelect.toFixed(0)}
                  //multiline={true}
                  ref={myTextInput}
                  placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                />
              </View>
              <Text
                style={{
                  marginBottom: switchMerchant ? '5%' : '3%',
                  fontSize: switchMerchant ? 10 : 16,
                  fontFamily: 'NunitoSans-Bold',
                  width: switchMerchant ? '10%' : '7%',
                  marginRight: switchMerchant ? '1%' : '1%',
                  marginLeft: switchMerchant ? '3%' : '2%',
                }}>
                RM
              </Text>
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: switchMerchant ? 50 : 60,
                  height: switchMerchant ? 35 : 40,
                  borderRadius: 5,
                  padding: 5,
                  marginVertical: switchMerchant ? 5 : 5,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  paddingLeft: 10,
                  marginRight: '5%',
                  fontSize: switchMerchant ? 10 : 14,
                }}
                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                placeholder="0.00"
                onChangeText={(text) => {
                  // const productOptions = productOptionsState;
                  // const item = productOptions.find(
                  //     (obj) => obj.id === productOptions.id,
                  // );
                  // item.price = text;
                  // setState({ productOptions });

                  setAddOnGroupList(
                    addOnGroupList.map((addOnGroup, index) =>
                      index === addOnGroupIndex
                        ? {
                          ...addOnGroup,
                          choices: [
                            ...addOnGroup.choices.map((choice, choiceIndex) =>
                              choiceIndex === i
                                ? {
                                  ...choice,
                                  choicePrice:
                                    text.length > 0 ? text : '0.00',
                                }
                                : choice,
                            ),
                          ],
                        }
                        : addOnGroup,
                    ),
                  );
                }}
                // value={(value) => {
                //     const productOptions = productOptionsState;
                //     const item = productOptions.find(
                //         (obj) => obj.id === productOptions.id,
                //     );
                //     value = item.price;
                // }}
                value={addOnGroupList[addOnGroupIndex].choices[i].choicePrice}
                // multiline={true}
                ref={myTextInput}
              />

              {i === 0 ? (
                <TouchableOpacity
                  onPress={() => {
                    // onadditem1();
                    setAddOnGroupList(
                      addOnGroupList.map((addOnGroup, index) =>
                        index === addOnGroupIndex
                          ? {
                            ...addOnGroup,
                            choices: [
                              ...addOnGroup.choices,
                              {
                                choiceId: '',
                                choiceName: '',
                                choicePrice: '',
                                choiceMinSelect: 0,
                                choiceMaxSelect: 0,
                              },
                            ],
                          }
                          : addOnGroup,
                      ),
                    );
                  }}
                  style={{
                    marginBottom: switchMerchant ? '4.5%' : '3%',
                    backgroundColor: Colors.whiteColor,
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}>
                  <Icon
                    name="plus-circle"
                    size={switchMerchant ? 17 : 20}
                    color={Colors.primaryColor}
                  />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity
                  style={{ marginBottom: switchMerchant ? '4.5%' : '3%' }}
                  onPress={() => {
                    setAddOnGroupList(
                      addOnGroupList.map((addOnGroup, index) =>
                        index === addOnGroupIndex
                          ? {
                            ...addOnGroup,
                            choices: [
                              ...addOnGroup.choices.slice(0, i),
                              ...addOnGroup.choices.slice(i + 1),
                            ],
                          }
                          : addOnGroup,
                      ),
                    );
                  }}>
                  <Icon
                    name="minus-circle"
                    size={switchMerchant ? 17 : 20}
                    color="#eb3446"
                  />
                </TouchableOpacity>
              )}
            </View>
          );
        })}
      </>
    );
  };

  //second here

  const renderProductTagList = ({ item, index }) => {
    return (
      <Text
        style={{
          fontSize: 14,
          fontFamily: 'Nunitosans-Regular',
          color: 'black',
          fontFamily: 'Nunitosans-Regular',
        }}>
        {selectedProductEdit &&
          selectedProductEdit.crmUserTagIdList &&
          selectedProductEdit.crmUserTagIdList.map((userTagId, i) => {
            var tagText = 'N/A';

            if (crmUserTagsDict[userTagId]) {
              tagText = crmUserTagsDict[userTagId].name;
            }
            return (
              <View style={{ alignItems: 'baseline', marginRight: 5 }}>
                <Text
                  style={{
                    borderColor: 'green',
                    borderWidth: 1,
                    borderRadius: 5,
                    fontWeight: '500',
                    color: Colors.primaryColor,
                    padding: 3,
                    alignItems: 'baseline',
                  }}>
                  {tagText}
                </Text>
              </View>
            );
          })}
      </Text>
    );
  };

  const renderStockLinkItems = () => {
    return (
      <>
        {stockLinkItems.map((item, index) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                margin: 10,
                width: '100%',
                zIndex: 10001 + stockLinkItems.length - index,
                //zIndex: -1,
                //alignItems: 'center',
              }}>
              <View
                style={{
                  flexDirection: 'column',
                  width: '14%',
                  alignItems: 'flex-start',
                  paddingLeft: 15,
                }}>
                <View>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontFamily: 'NunitoSans-Bold',
                      marginBottom: 10,
                      fontSize: switchMerchant ? 10 : 14,
                    }}>
                    SKU
                  </Text>
                </View>
                <View style={{ height: 30, justifyContent: 'center' }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: switchMerchant ? 10 : 14,
                    }}>
                    {stockLinkItems[index]["skuMerchant"] || 'N/A'}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'column',
                  width: '20%',
                  alignItems: 'flex-start',
                  zIndex: index - 1,
                }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: 'NunitoSans-Bold',
                    marginBottom: 10,
                    fontSize: switchMerchant ? 10 : 14,
                  }}>
                  Product
                </Text>

                {outletSupplyItemDropdownList.length > 0 &&
                  Object.keys(outletSupplyItemsDict).length > 0 &&
                  stockLinkItems[index].outletSupplyItemId &&
                  outletSupplyItemsDict[stockLinkItems[index].outletSupplyItemId] &&
                   (
                    <Picker
                      selectedValue={
                        stockLinkItems[index] && stockLinkItems[index].outletSupplyItemId
                          ? stockLinkItems[index].outletSupplyItemId
                          : ''}
                      style={{
                        width: switchMerchant ? 90 : 150,
                        paddingVertical: 0,
                        backgroundColor: Colors.fieldtBgColor,
                        borderRadius: 10,
                        fontSize: switchMerchant ? 11 : 14,
                      }}
                      zIndex={10000 + stockLinkItems.length - index}
                      onValueChange={(item) => {
                        setStockLinkItems(
                          stockLinkItems.map((stockLinkItem, i) =>
                            i === index
                              ? {
                                ...stockLinkItem,
                                outletSupplyItemId: item,
                                sku: outletSupplyItemsDict[item].sku,
                                skuMerchant: outletSupplyItemsDict[item].skuMerchant,
                                name: outletSupplyItemsDict[item].name,
                                unit: outletSupplyItemsDict[item].unit,
                                quantityUsage: stockLinkItem.quantityUsage,
                              }
                              : stockLinkItem,
                          ),
                        );
                      }}
                    >
                      {outletSupplyItemDropdownList.map((value, index) => {
                        return (
                          <Picker.Item
                            key={index}
                            label={value.label}
                            value={value.value}
                          />
                        );
                      })}
                    </Picker>
                  )}
              </View>

              <View
                style={{
                  flexDirection: 'column',
                  width: '18%',
                  alignItems: 'flex-start',
                }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: 'NunitoSans-Bold',
                    marginBottom: 10,
                    fontSize: switchMerchant ? 10 : 14,
                  }}>
                  Quantity
                </Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  style={{
                    backgroundColor: Colors.fieldtBgColor,
                    width: switchMerchant ? 70 : 80,
                    height: 30,
                    borderRadius: 5,
                    padding: 5,
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    paddingLeft: 10,
                    fontSize: switchMerchant ? 10 : 14,
                  }}
                  placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                  placeholder={'0.00'}
                  onChangeText={(text) => {
                    setStockLinkItems(
                      stockLinkItems.map((stockLinkItem, i) =>
                        i === index
                          ? {
                            ...stockLinkItem,
                            quantityUsage: text.length <= 0 ? '0.00' : text,
                          }
                          : stockLinkItem,
                      ),
                    );
                  }}
                  value={stockLinkItems[index]["quantityUsage"]}
                  multiline={true}
                  ref={myTextInput}
                  keyboardType={'number-pad'}
                />
              </View>

              <View
                style={{
                  flexDirection: 'column',
                  width: switchMerchant ? '11%' : '10%',
                  alignItems: 'flex-start',
                }}>
                <Text
                  style={{
                    textAlign: 'left',
                    fontFamily: 'NunitoSans-Bold',
                    marginBottom: 10,
                    fontSize: switchMerchant ? 10 : 14,
                  }}>
                  Unit
                </Text>
                <View style={{ justifyContent: 'center', height: 30 }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontFamily: 'NunitoSans-Bold',
                      color: Colors.descriptionColor,
                      fontSize: switchMerchant ? 10 : 14,
                    }}>
                    {stockLinkItems[index].unit}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'column',
                  width: '16%',
                  alignItems: 'flex-start',
                }}>
                <Text
                  style={{
                    textAlign: 'left',
                    fontFamily: 'NunitoSans-Bold',
                    marginBottom: 10.5,
                    fontSize: switchMerchant ? 10 : 14,
                  }}>
                  Cost Price
                </Text>
                <View style={{ justifyContent: 'center', height: 30 }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontFamily: 'NunitoSans-Bold',
                      color: Colors.descriptionColor,
                      fontSize: switchMerchant ? 10 : 14,
                    }}>
                    {`RM${(stockLinkItems[index].quantityUsage *
                      (outletSupplyItemsDict[
                        stockLinkItems[index].outletSupplyItemId
                      ]
                        ? Math.ceil(
                          outletSupplyItemsDict[
                            stockLinkItems[index].outletSupplyItemId
                          ].price *
                          20 -
                          0.05,
                        ) / 20
                        : 0)
                    ).toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'column',
                  width: '14%',
                  alignItems: 'flex-start',
                }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: 'NunitoSans-Bold',
                    marginBottom: 10,
                    fontSize: switchMerchant ? 10 : 14,
                  }}>
                  In Stock
                </Text>
                <View style={{ justifyContent: 'center', height: 30 }}>
                  <Text
                    style={{
                      textAlign: 'center',
                      fontFamily: 'NunitoSans-Bold',
                      color: Colors.descriptionColor,
                      fontSize: switchMerchant ? 10 : 14,
                    }}>
                    {`${(outletSupplyItemsDict[
                      stockLinkItems[index].outletSupplyItemId
                    ]
                      ? parseInt(
                        outletSupplyItemsDict[
                          stockLinkItems[index].outletSupplyItemId
                        ].quantity,
                      )
                      : 0
                    ).toFixed(0)}`}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'column',
                  width: '5%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontFamily: 'NunitoSans-Bold',
                    marginBottom: 10,
                    fontSize: switchMerchant ? 11 : 14,
                  }}></Text>

                <View
                  style={{
                    ...(switchMerchant && {
                      height: switchMerchant ? 30 : 0,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }),
                  }}>
                  <TouchableOpacity
                    onPress={() => {
                      if (window.confirm('Info: Are you sure you want to delete this item?')) {
                        setStockLinkItems([
                          ...stockLinkItems.slice(0, index),
                          ...stockLinkItems.slice(index + 1),
                        ])
                      }
                    }}>
                    <Icon
                      name="trash-2"
                      size={switchMerchant ? 17 : 20}
                      color="#eb3446"
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          );
        })}
      </>
    );
  };

  const renderAddProduct = ({ item, index }) => {
    console.log('outletSupplyItemDropdownList');
    console.log(outletSupplyItemDropdownList);

    return (
      <View
        style={{
          flexDirection: 'row',
          margin: 10,
          width: '100%',
          //zIndex: -1,
          //alignItems: 'center',
        }}>
        <View
          style={{
            flexDirection: 'column',
            width: '14%',
            alignItems: 'flex-start',
            paddingLeft: 15,
          }}>
          <View>
            <Text
              style={{
                textAlign: 'center',
                fontFamily: 'NunitoSans-Bold',
                marginBottom: 10,
                fontSize: switchMerchant ? 10 : 14,
              }}>
              SKU
            </Text>
          </View>
          <View style={{ height: 30, justifyContent: 'center' }}>
            <Text
              style={{ textAlign: 'center', fontSize: switchMerchant ? 10 : 14 }}>
              {stockLinkItems[index]["skuMerchant"] || 'N/A'}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'column',
            width: '20%',
            alignItems: 'flex-start',
          }}>
          <Text
            style={{
              textAlign: 'center',
              fontFamily: 'NunitoSans-Bold',
              marginBottom: 10,
              fontSize: switchMerchant ? 10 : 14,
            }}>
            Product
          </Text>
          <View
            style={
              switchMerchant
                ? {
                  borderColor: '#E5E5E5',
                  borderWidth: 1,
                  borderRadius: 5,
                  backgroundColor: Colors.fieldtBgColor,
                  height: 30,
                  width: 90,
                  paddingLeft: 0,
                  alignItems: 'center',
                  flexDirection: 'row',
                }
                : {
                  borderColor: '#E5E5E5',
                  borderWidth: 1,
                  borderRadius: 5,
                  backgroundColor: Colors.fieldtBgColor,
                  height: 30,
                  width: 130,
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: 5,
                }
            }>
            {switchMerchant ? (
              <Picker
                selectedValue={
                  stockLinkItems[index].outletSupplyItemId
                    ? stockLinkItems[index].outletSupplyItemId
                    : ''
                }
                style={{}}
                onValueChange={(value) =>
                  setStockLinkItems(
                    stockLinkItems.map((stockLinkItem, i) =>
                      i === index
                        ? {
                          ...stockLinkItem,
                          outletSupplyItemId: value,
                          sku: outletSupplyItemsDict[value].sku,
                          skuMerchant: outletSupplyItemsDict[value].skuMerchant,
                          quantityUsage: stockLinkItem.quantityUsage, // use previous
                          name: outletSupplyItemsDict[value].name,
                          unit: outletSupplyItemsDict[value].unit,
                        }
                        : stockLinkItem,
                    ),
                  )
                }
              >
                {outletSupplyItemDropdownList.map((value, index) => {
                  return (
                    <Picker.Item
                      key={index}
                      label={value.label}
                      value={value.value}
                    />
                  );
                })}
              </Picker>
            ) : (
              <Picker
                selectedValue={
                  stockLinkItems[index].outletSupplyItemId
                    ? stockLinkItems[index].outletSupplyItemId
                    : ''
                }
                style={{ textAlign: 'center', fontSize: 14 }}
                onValueChange={(value) =>
                  setStockLinkItems(
                    stockLinkItems.map((stockLinkItem, i) =>
                      i === index
                        ? {
                          ...stockLinkItem,
                          outletSupplyItemId: value,
                          sku: outletSupplyItemsDict[value].sku,
                          skuMerchant:
                            outletSupplyItemsDict[value].skuMerchant,
                          quantityUsage: stockLinkItem.quantityUsage, // use previous
                          name: outletSupplyItemsDict[value].name,
                          unit: outletSupplyItemsDict[value].unit,
                        }
                        : stockLinkItem,
                    ),
                  )
                }
              >
                {outletSupplyItemDropdownList.map((value, index) => {
                  return (
                    <Picker.Item
                      key={index}
                      label={value.label}
                      value={value.value}
                    />
                  );
                })}
              </Picker>
            )}
            {switchMerchant ? (
              <Feather
                name="chevron-down"
                size={15}
                style={{
                  width: '100%',
                  paddingVertical: 7,
                  paddingLeft: 70,
                  color: 'grey',
                  position: 'absolute',
                }}
              />
            ) : null}
          </View>
        </View>

        <View
          style={{
            flexDirection: 'column',
            width: '18%',
            alignItems: 'flex-start',
          }}>
          <Text
            style={{
              textAlign: 'center',
              fontFamily: 'NunitoSans-Bold',
              marginBottom: 10,
              fontSize: switchMerchant ? 10 : 14,
            }}>
            Quantity
          </Text>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              backgroundColor: Colors.fieldtBgColor,
              width: switchMerchant ? 70 : 80,
              height: 30,
              borderRadius: 5,
              padding: 5,
              borderWidth: 1,
              borderColor: '#E5E5E5',
              paddingLeft: 10,
              fontSize: switchMerchant ? 10 : 14,
            }}
            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
            placeholder={'0.00'}
            onChangeText={(text) => {
              setStockLinkItems(
                stockLinkItems.map((stockLinkItem, i) =>
                  i === index
                    ? {
                      ...stockLinkItem,
                      quantityUsage: text.length <= 0 ? '0.00' : text,
                    }
                    : stockLinkItem,
                ),
              );
            }}
            value={stockLinkItems[index].quantityUsage}
            multiline={true}
            ref={myTextInput}
            keyboardType={'number-pad'}
          />
        </View>

        <View
          style={{
            flexDirection: 'column',
            width: switchMerchant ? '11%' : '10%',
            alignItems: 'flex-start',
          }}>
          <Text
            style={{
              textAlign: 'left',
              fontFamily: 'NunitoSans-Bold',
              marginBottom: 10,
              fontSize: switchMerchant ? 10 : 14,
            }}>
            Unit
          </Text>
          <View style={{ justifyContent: 'center', height: 30 }}>
            <Text
              style={{
                textAlign: 'center',
                fontFamily: 'NunitoSans-Bold',
                color: Colors.descriptionColor,
                fontSize: switchMerchant ? 10 : 14,
              }}>
              {stockLinkItems[index].unit}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'column',
            width: '16%',
            alignItems: 'flex-start',
          }}>
          <Text
            style={{
              textAlign: 'left',
              fontFamily: 'NunitoSans-Bold',
              marginBottom: 10.5,
              fontSize: switchMerchant ? 10 : 14,
            }}>
            Cost Price
          </Text>
          <View style={{ justifyContent: 'center', height: 30 }}>
            <Text
              style={{
                textAlign: 'center',
                fontFamily: 'NunitoSans-Bold',
                color: Colors.descriptionColor,
                fontSize: switchMerchant ? 10 : 14,
              }}>
              {`RM${(
                stockLinkItems[index].quantityUsage *
                (outletSupplyItemsDict[stockLinkItems[index].outletSupplyItemId]
                  ? Math.ceil(
                    outletSupplyItemsDict[
                      stockLinkItems[index].outletSupplyItemId
                    ].price *
                    20 -
                    0.05,
                  ) / 20
                  : 0)
              )
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'column',
            width: '14%',
            alignItems: 'flex-start',
          }}>
          <Text
            style={{
              textAlign: 'center',
              fontFamily: 'NunitoSans-Bold',
              marginBottom: 10,
              fontSize: switchMerchant ? 10 : 14,
            }}>
            In Stock
          </Text>
          <View style={{ justifyContent: 'center', height: 30 }}>
            <Text
              style={{
                textAlign: 'center',
                fontFamily: 'NunitoSans-Bold',
                color: Colors.descriptionColor,
                fontSize: switchMerchant ? 10 : 14,
              }}>
              {`${(outletSupplyItemsDict[
                stockLinkItems[index].outletSupplyItemId
              ]
                ? parseInt(
                  outletSupplyItemsDict[
                    stockLinkItems[index].outletSupplyItemId
                  ].quantity,
                )
                : 0
              ).toFixed(0)}`}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'column',
            width: '5%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text
            style={{
              textAlign: 'center',
              fontFamily: 'NunitoSans-Bold',
              marginBottom: 10,
              fontSize: switchMerchant ? 11 : 14,
            }}></Text>

          <View
            style={{
              ...(switchMerchant && {
                height: switchMerchant ? 30 : 0,
                justifyContent: 'center',
                alignItems: 'center',
              }),
            }}>
            <TouchableOpacity
              onPress={() => {
                if (window.confirm('Info: Are you sure you want to delete this item?')) {
                  setStockLinkItems([
                    ...stockLinkItems.slice(0, index),
                    ...stockLinkItems.slice(index + 1),
                  ])
                }
              }}>
              <Icon
                name="trash-2"
                size={switchMerchant ? 17 : 20}
                color="#eb3446"
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  };

  // const importSelectFile = async () => {
  //   try {
  //     const res = await DocumentPicker.pick({
  //       type: [DocumentPicker.types.xlsx],
  //     });

  //     console.log(res);
  //   } catch (err) {
  //     if (DocumentPicker.isCancel(err)) {
  //     } else {
  //       throw err;
  //     }
  //   }
  // };

  //error show readAsArrayBuffer not implemented
  // const importTemplate = (file) => {
  //   const promise = new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsArrayBuffer(file);

  //     fileReader.onload = (e) => {
  //       const bufferArray = e.target.result;

  //       const wb = XLSX.read(bufferArray, { type: 'buffer' });

  //       const wsname = wb.SheetNames[0];

  //       const ws = wb.Sheets[wsname];

  //       const data = XLSX.utils.sheet_to_json(ws);

  //       resolve(data);
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });

  //   promise.then((d) => {
  //     console.log(d);
  //   });
  // };

  const renderProductTag = ({ item }) => {
    return (
      <View
        style={{
          borderWidth: 1,
          borderColor: 'darkgreen',
          borderRadius: 5,
          padding: 5,
          alignSelf: 'baseline',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: 10,
          marginBottom: 5,
        }}>
        <Text style={{ fontWeight: '500', color: 'green' }}>TESTING</Text>
        <TouchableOpacity
          style={{ marginLeft: 5 }}
          onPress={() => {
            if (window.confirm('Info: Are you sure you want to delete this tag?')) {

            }
          }}>
          <AntDesign name="closecircle" color={Colors.fieldtTxtColor} />
        </TouchableOpacity>
      </View>
    );
  };

  const renderConfirmProductTag = ({ item }) => (
    <View
      style={{
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        borderRadius: 5,
        padding: 2,
        paddingVertical: 0,
        alignSelf: 'baseline',
        alignItems: 'center',
        marginRight: 5,
        marginBottom: 5,
      }}>
      <Text
        style={{ fontWeight: '500', color: Colors.primaryColor, fontSize: 14 }}>
        TEST
      </Text>
    </View>
  );

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={2} expandReport={true} />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <Modal
          style={{ flex: 1 }}
          visible={loadingModal}
          supportedOrientations={['portrait', 'landscape']}
          transparent={true}
          animationType={'slide'}>
          <View style={styles.modalContainer}>
            <ActivityIndicator color={Colors.whiteColor} size={'large'} />
          </View>
        </Modal>

        <Modal
          style={
            {
              // flex: 1
            }
          }
          visible={exportModal}
          supportedOrientations={['portrait', 'landscape']}
          transparent={true}
          animationType={'slide'}>
          <View style={styles.modalContainer}>
            <View style={[styles.modalView, {}]}>
              <TouchableOpacity
                style={styles.closeButton}
                onPress={() => {
                  // setState({ changeTable: false });
                  setExportModal(false);
                }}>
                <AntDesign
                  name="closecircle"
                  size={25}
                  color={Colors.fieldtTxtColor}
                />
              </TouchableOpacity>
              <View style={styles.modalTitle}>
                <Text
                  style={[
                    styles.modalTitleText,
                    { fontSize: switchMerchant ? 18 : 20 },
                  ]}>
                  Export Options
                </Text>
              </View>
              <View
                style={{
                  alignItems: 'center',
                  top: '10%',
                }}>
                <TouchableOpacity
                  style={[
                    styles.modalSaveButton,
                    {
                      zIndex: -1,
                    },
                  ]}
                  onPress={() => {
                    // exportTemplate();
                  }}>
                  <Text
                    style={[
                      styles.modalDescText,
                      { color: Colors.primaryColor },
                    ]}>
                    Export Template
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>

        <Modal
          style={
            {
              // flex: 1
            }
          }
          visible={importModal}
          supportedOrientations={['portrait', 'landscape']}
          transparent={true}
          animationType={'slide'}>
          <View style={styles.modalContainer}>
            <View
              style={[
                styles.modalViewImport,
                {},
              ]}>
              <TouchableOpacity
                style={styles.closeButton}
                onPress={() => {
                  // setState({ changeTable: false });
                  setImportModal(false);
                }}>
                <AntDesign
                  name="closecircle"
                  size={25}
                  color={Colors.fieldtTxtColor}
                />
              </TouchableOpacity>
              <View style={{ padding: 10, margin: 30 }}>
                <View
                  style={[
                    styles.modalTitle1,
                    { justifyContent: 'center', alignItems: 'center' },
                  ]}>
                  <Text
                    style={[
                      styles.modalTitleText1,
                      { fontSize: 16, fontWeight: '500' },
                    ]}>
                    Imported List
                  </Text>
                </View>
                {/* <View style={{
                  heigth: 70,
                  marginVertical: 10,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  height: '80%'
                }}>
                <Table borderStyle={{ borderWidth: 1 }}>
                  <Row data={TableData.tableHead} flexArr={[1, 2, 1, 1]} style={{}}/>
                  <TableWrapper style={{}}>
                  <Col data={TableData.tableTitle} style={{flex: 1}} heightArr={[28, 28, 28, 28]} textStyle={{}}/>
                  <Rows data={TableData.tableData} flexArr={[1, 2, 1, 1]} style={{height: 28}} textStyle={{textAlign: 'center'}}/>
                  </TableWrapper>
                </Table>
                </View> */}
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <View
                    style={{
                      backgroundColor: Colors.primaryColor,
                      width: 150,
                      height: 40,
                      marginVertical: 15,
                      borderRadius: 5,
                      alignSelf: 'center',
                    }}>
                    <TouchableOpacity
                      onPress={() => {
                        // importSelectFile();
                      }}>
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          alignSelf: 'center',
                          marginVertical: 10,
                        }}>
                        IMPORT
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View style={{ flexDirection: 'row' }}>
                    <View
                      style={{
                        backgroundColor: Colors.whiteColor,
                        width: 150,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: 'center',
                      }}>
                      <TouchableOpacity
                        onPress={() => {
                          // setImportTemplate(false);
                        }}>
                        <Text
                          style={{
                            color: Colors.primaryColor,
                            alignSelf: 'center',
                            marginVertical: 10,
                          }}>
                          CANCEL
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        backgroundColor: Colors.primaryColor,
                        width: 150,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: 'center',
                      }}>
                      <TouchableOpacity onPress={() => { }}>
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            alignSelf: 'center',
                            marginVertical: 10,
                          }}>
                          SAVE
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Modal>

        <View style={{ flexDirection: 'row', marginBottom: 0 }}>
          <View style={{ flex: 2 }}></View>
          <View
            style={{
              flex: 1,
              alignContent: 'flex-end',
              marginBottom: 0,
              flexDirection: 'row',
            }}>
          </View>
        </View>

        <TouchableOpacity
          style={{ width: '10%' }}
          onPress={() => {
            goBackButton();
          }}>
          <View
            style={{
              paddingTop: 10,
              marginBottom: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginLeft: 50,
              //backgroundColor: 'blue'
            }}>
            <Feather
              name="chevron-left"
              size={switchMerchant ? 20 : 30}
              style={{ color: Colors.primaryColor, alignSelf: 'center' }}
            />
            <Text
              style={{
                fontFamily: 'Nunitosans-Bold',
                color: Colors.primaryColor,
                fontSize: switchMerchant ? 14 : 17,
                marginBottom: Platform.OS === 'ios' ? 0 : 1,
              }}>
              Back
            </Text>
          </View>
        </TouchableOpacity>

        <View
          style={{
            backgroundColor: Colors.whiteColor,
            borderRadius: 10,
            width: windowWidth * 0.8575,
            height: windowHeight * 0.8,
            marginTop: 30,
            marginHorizontal: 58,
            marginBottom: 30,
            borderRadius: 5,
            shadowOpacity: 0,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.22,
            shadowRadius: 3.22,
            elevation: 3,
          }}
        >
          <ScrollView
            showsVerticalScrollIndicator={false}
            //style={{ width: windowWidth * 0.9 }}
            contentContainerStyle={{
              paddingBottom: windowHeight * 0.1,
            }}
          >
            <View
              style={{
                alignItems: 'flex-end',
                width: '100%',
                marginTop: 15,
                marginBottom: 5,
                marginRight: 10,
                zIndex: 10000,
              }}>
              <View
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: Colors.tabRed,
                  backgroundColor: Colors.tabRed,
                  borderRadius: 5,
                  width: switchMerchant ? 100 : 130,
                  paddingHorizontal: 10,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,
                  marginBottom: 10,
                  marginRight: 10,
                }}>
                <TouchableOpacity
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: switchMerchant ? 100 : 130,
                    height: switchMerchant ? 35 : 40,
                  }}
                  disabled={isLoading}
                  onPress={() => {
                    if (window.confirm('Info: Are you sure you want to delete this item?')) {
                      deleteOutletItem()
                    }
                  }}>
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      //marginLeft: 5,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    {isLoading && isDelete ? 'LOADING...' : 'DELETE'}
                  </Text>

                  {isLoading && isDelete ? (
                    <ActivityIndicator
                      color={Colors.whiteColor}
                      size={'small'}
                    />
                  ) : (
                    <></>
                  )}
                </TouchableOpacity>
              </View>

              <View
                style={{
                  justifyContent: 'center',
                  flexDirection: 'row',
                  borderWidth: 1,
                  borderColor: Colors.primaryColor,
                  backgroundColor: '#4E9F7D',
                  borderRadius: 5,
                  width: switchMerchant ? 100 : 130,
                  paddingHorizontal: 10,
                  height: switchMerchant ? 35 : 40,
                  alignItems: 'center',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,
                  marginRight: 10,
                }}>
                <TouchableOpacity
                  style={{
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: switchMerchant ? 100 : 130,
                    height: switchMerchant ? 35 : 40,
                  }}
                  disabled={isLoading}
                  onPress={() => {
                    // editItem();
                    createOutletItem();
                    // setLoadingModal(true);
                  }}>
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      //marginLeft: 5,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    {isLoading && isSave ? 'LOADING...' : 'SAVE'}
                  </Text>

                  {isLoading && isSave ? (
                    <ActivityIndicator
                      color={Colors.whiteColor}
                      size={'small'}
                    />
                  ) : (
                    <></>
                  )}
                </TouchableOpacity>
              </View>
            </View>

            <View
              pointerEvents={'box-none'}
              style={{
                borderBottomWidth: StyleSheet.hairlineWidth,
                flexDirection: 'row',
                padding: 30,
                paddingTop: 20,
                paddingBottom: switchMerchant ? 30 : 60,
              }}>
              <View style={{ flex: 3 }}>
                <View style={{ flexDirection: 'row' }}>
                  {/* left */}
                  <View style={{}}>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 15 : 20,
                        fontFamily: 'NunitoSans-Regular',
                        // fontFamily: 'NunitoSans-Regular'
                      }}>
                      Product Photo
                    </Text>
                    {/* <TouchableOpacity
                                            style={{ height: 30, width: 50 }}
                                            onPress={() => {
                                                handleChoosePhoto();
                                            }}>
                                            <Text style={{ fontSize: 15, color: 'darkgreen', fontFamily: 'NunitoSans-Regular' }}>
                                                change
                                            </Text>
                                        </TouchableOpacity> */}

                    <TouchableOpacity
                      onPress={() => {
                        // handleChoosePhoto();
                        openFileSelector();
                      }}>
                      <View
                        style={{
                          zIndex: -2,
                          height: switchMerchant ? 180 : 250,
                          backgroundColor: Colors.fieldtBgColor,
                        }}>
                        <View
                          style={{
                            zIndex: 1,
                            borderWidth: 1,
                            borderRadius: 5,
                            borderColor: '#E5E5E5',
                          }}>
                          <AsyncImage
                            //placeholder={{uri: 'https://i.imgur.com/R5TraVR.png'}}
                            //placeholderSource
                            source={{ uri: image }}
                            style={{
                              width: switchMerchant ? 218 : 325,
                              height: switchMerchant ? 180 : 250,
                            }}
                            hideLoading={true}
                          />
                          <View
                            style={{
                              position: 'absolute',
                              bottom: 5,
                              right: 5,
                              //   backgroundColor: 'black',
                              //opacity: 0.5,
                              // width: 120,
                              // height: 120,
                              // borderRadius: 13,
                            }}>
                            <Feather
                              name="edit"
                              size={23}
                              color={Colors.primaryColor}
                            //  style={{ position: 'absolute', zIndex: -1 }}
                            />
                          </View>
                        </View>
                        <Icon
                          name="upload"
                          size={switchMerchant ? 80 : 200}
                          color="lightgrey"
                          style={{
                            top: switchMerchant ? 40 : 23,
                            position: 'absolute',
                            alignSelf: 'center',
                            zIndex: -1,
                          }}
                        />
                      </View>
                    </TouchableOpacity>
                  </View>
                  {/* <View style={{ marginLeft: 30, zIndex: -2, height: 120, backgroundColor: Colors.fieldtBgColor }}>
                                        <View style={{ zIndex: 1, borderWidth: 1, borderRadius: 5, borderColor: '#E5E5E5' }}>
                                            <AsyncImage
                                                //placeholder={{uri: 'https://i.imgur.com/R5TraVR.png'}}
                                                //placeholderSource
                                                source={{ uri: image }}
                                                style={{ width: 120, height: 120 }}
                                                hideLoading={true}
                                            />
                                        </View>
                                        <Icon name='upload' size={110} color='lightgrey' style={{ position: 'absolute', alignSelf: 'center', zIndex: -1 }} />
                                    </View> */}
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 20,
                    zIndex: 1000,
                  }}>
                  <Text
                    style={{
                      fontFamily: 'NunitoSans-Regular',
                      fontWeight: '500',
                      fontSize: switchMerchant ? 10 : 14,
                    }}>
                    Outlet Selected
                  </Text>
                  <View style={{ marginLeft: switchMerchant ? 10 : 20 }}>
                    <View
                      style={{
                        width: 210,
                        zIndex: 1000,
                      }}>
                      {/* <DropDownPicker
                        containerStyle={{
                          height: switchMerchant ? 35 : 40,
                          zIndex: 2,
                        }}
                        style={{
                          width: switchMerchant ? 130 : 210,
                          paddingVertical: 0,
                          backgroundColor: Colors.fieldtBgColor,
                          borderRadius: 10,
                        }}
                        items={outletDropdownList}
                        placeholder="Choose Outlet"
                        multipleText={'%d outlet(s) selected'}
                        customTickIcon={(press) => (
                          <Ionicon
                            name={'md-checkbox'}
                            color={
                              press ? Colors.fieldtBgColor : Colors.primaryColor
                            }
                            size={25}
                          />
                        )}
                        onChangeItem={(items) => {
                          setSelectedOutletList(items);
                          setPlace(
                            items.length > 0
                              ? items[0].uniqueId
                              : allOutlets[0].uniqueId,
                          );
                        }}
                        defaultValue={selectedOutletList}
                        multiple={true}
                        dropDownMaxHeight={150}
                        dropDownStyle={{
                          width: switchMerchant ? 130 : 210,
                          height: 90,
                          backgroundColor: Colors.fieldtBgColor,
                          borderRadius: 10,
                          borderWidth: 1,
                          textAlign: 'left',
                          zIndex: 2,
                          fontSize: switchMerchant ? 11 : 14,
                        }}
                      /> */}
                      <Select
                        defaultValue={selectedOutletList}
                        onChange={(items) => {
                          setSelectedOutletList(items);
                          setPlace(
                            items.length > 0
                              ? items[0].uniqueId
                              : allOutlets[0].uniqueId,
                          );
                        }}
                        options={outletDropdownList}
                        isMulti
                      />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    width: switchMerchant ? '50%' : '50%',
                    alignItems: 'flex-start',
                    marginLeft: 0,
                    marginVertical: switchMerchant ? 20 : 35,
                    marginBottom: 20,
                    zIndex: -1,
                    justifyContent: 'flex-start',
                  }}>
                  {/* <View style={{ width: '20%', marginLeft: 0, flexDirection: 'row', alignSelf: 'flex-start' }}>
                                        {
                                            selectedProductEdit && selectedProductEdit.crmUserTagIdList && selectedProductEdit.crmUserTagIdList.map((userTagId, i) => {
                                                var tagText = 'N/A';

                                                if (crmUserTagsDict[userTagId]) {
                                                    tagText = crmUserTagsDict[userTagId].name;
                                                }

                                                return (
                                                    <View style={{ alignItems: 'baseline', marginRight: 5 }}>
                                                        <Text style={{ borderColor: 'green', borderWidth: 1, borderRadius: 5, fontWeight: '500', color: Colors.primaryColor, padding: 3, alignItems: 'baseline' }}>
                                                            {tagText}
                                                        </Text>
                                                    </View>
                                                );
                                            })
                                        }
                                    </View> */}

                  {/* <View
                                        style={{
                                            //backgroundColor: Colors.fieldtBgColor,
                                            //width: 180,
                                            height: 45,
                                            borderRadius: 5,
                                            paddingVertical: 3,
                                            paddingLeft: 5,
                                            marginVertical: 5,
                                            //borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            //width: '100%'
                                        }}> */}
                  <View
                    style={{
                      flexDirection: 'row',
                      width: '100%',
                      maxHeight: 100,
                      alignItems: 'center',
                      marginTop: -5,
                    }}>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        color: '#9FA2B4',
                        fontWeight: '700',
                        fontFamily: 'Nunitosans-Regular',
                        textAlignVertical: 'center',
                      }}>
                      Tags:
                    </Text>
                    <View
                      style={{
                        width: switchMerchant ? '80%' : '90%',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                        paddingTop: Platform.OS === 'ios' ? 5 : 0,
                        marginLeft: 20,
                      }}>
                      {selectedProductEdit &&
                        selectedProductEdit.crmUserTagIdList &&
                        selectedProductEdit.crmUserTagIdList.length > 0 ? (
                        //If selectedProductEdit.crmUserTagIdList.length will crash => undefined .length
                        <View
                          style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            flex: 1,
                          }}>
                          {selectedProductEdit &&
                            selectedProductEdit.crmUserTagIdList &&
                            selectedProductEdit.crmUserTagIdList.map(
                              (userTagId, i) => {
                                var tagText = 'N/A';

                                if (crmUserTagsDict[userTagId.value]) {
                                  tagText = crmUserTagsDict[userTagId.value].name;
                                }
                                return (
                                  <View
                                    style={{
                                      marginHorizontal: 3,
                                    }}>
                                    <Text
                                      style={{
                                        fontSize: switchMerchant ? 10 : 14,
                                        borderColor: 'green',
                                        borderWidth: 1,
                                        borderRadius: 5,
                                        fontWeight: '500',
                                        color: Colors.blackColor,
                                        padding: 3,
                                        textAlignVertical: 'center',
                                      }}>
                                      {tagText}
                                    </Text>
                                  </View>
                                );
                              },
                            )}
                        </View>
                      ) : (
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 14,
                            width: switchMerchant ? '60%' : '50%',
                            color: Colors.descriptionColor,
                            textAlignVertical: 'center',
                          }}>
                          No Tag Yet
                        </Text>
                      )}

                      {/* </View> */}
                    </View>
                    {
                      selectedProductEdit &&
                      <TouchableOpacity
                        style={{
                          marginLeft: 8,
                          alignSelf: 'center',

                          zIndex: 999,
                        }}
                        onPress={() => {
                          setTagModal(true);
                        }}>
                        <AntDesign
                          name="tagso"
                          size={switchMerchant ? 17 : 24}
                          style={{ color: Colors.primaryColor }}
                        />
                      </TouchableOpacity>
                    }
                  </View>
                </View>

                {
                  (printerAreaDropdownList.length > 0
                    ||
                    (selectedPrinterAreaList.length > 0 && (printerAreaDropdownList.includes(selectedPrinterAreaList[0]))))
                    ?
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 20,
                        zIndex: 999,
                      }}>
                      <Text
                        style={{
                          fontFamily: 'NunitoSans-Regular',
                          fontWeight: '500',
                          fontSize: switchMerchant ? 10 : 14,
                        }}>
                        Printer Area
                      </Text>
                      <View style={{ marginLeft: switchMerchant ? 10 : 20 }}>
                        <View
                          style={{
                            width: 230,
                            zIndex: 1000,
                          }}>
                          <Select
                            defaultValue={selectedPrinterAreaList}
                            onChange={(items) => {
                              setSelectedPrinterAreaList(items);
                            }}
                            options={printerAreaDropdownList}
                            isMulti
                          />
                        </View>
                      </View>
                    </View>
                    :
                    <></>
                }

                <ScrollView
                  pointerEvents={'box-none'}
                  style={{
                    zIndex: -1,
                    maxHeight: 100,
                  }}
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator={false}>
                  <FlatList
                    data={renderConfirmProductTag}
                    numColumns={3}
                    renderItem={renderConfirmProductTag}
                    keyExtractor={(item, index) => String(index)}
                    style={{
                      paddingVertical: 5,
                      paddingHorizontal: 5,
                      //width: 170,
                    }}
                  //horizontal={true}
                  />
                </ScrollView>

                {/* <View style={{ flexDirection: 'row', flex: 1 }}>
                    <TouchableOpacity
                        style={{ height: 30, width: 100 }}
                        onPress={() => { }}>
                        <Text style={{ fontSize: 13, color: 'red', fontFamily: 'NunitoSans-Regular' }}>
                            Delete Product
                        </Text>
                    </TouchableOpacity>
                </View> */}
              </View>
              {/* right */}
              <View
                style={{
                  flex: 4,
                }}>
                <View
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: switchMerchant ? 5 : 15,
                  }}>
                  <View>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Regular',
                        fontWeight: '500',
                      }}>
                      Name
                    </Text>
                  </View>
                  <View>
                    <TextInput
                      underlineColorAndroid={Colors.fieldtBgColor}
                      style={[
                        styles.textFieldInput,
                        {
                          fontSize: switchMerchant ? 10 : 14,
                          height: switchMerchant ? 35 : 40,
                          width: switchMerchant
                            ? Dimensions.get('screen').width * 0.3
                            : Dimensions.get('screen').width * 0.35,
                        },
                      ]}
                      placeholder={itemName ? itemName : 'Braised Chicken Wing'}
                      placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                      onChangeText={(text) => {
                        // setState({ itemName: text });
                        setItemName(text);
                      }}
                      value={itemName}
                      //multiline={true}
                      ref={myTextInput}
                    />
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginBottom: switchMerchant ? 5 : 15,
                  }}>
                  <View>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Regular',
                        fontWeight: '500',
                      }}>
                      Description
                    </Text>
                  </View>
                  <View>
                    <TextInput
                      underlineColorAndroid={Colors.fieldtBgColor}
                      style={[
                        styles.textFieldInput,
                        {
                          fontSize: switchMerchant ? 10 : 14,
                          height: switchMerchant ? 35 : 40,
                          width: switchMerchant
                            ? Dimensions.get('screen').width * 0.3
                            : Dimensions.get('screen').width * 0.35,
                        },
                      ]}
                      placeholder={itemDescription ? itemDescription : 'SS10'}
                      placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                      onChangeText={(text) => {
                        // setState({ itemDescription: text });
                        setItemDescription(text);
                      }}
                      value={itemDescription}
                      //multiline={true}
                      ref={myTextInput}
                    />
                  </View>
                </View>
                <View
                  style={{
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: switchMerchant ? 5 : 15,
                  }}>
                  <View>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Regular',
                        fontWeight: '500',
                      }}>
                      SKU
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: switchMerchant ? 5 : 0,
                      //flex: 1,
                      // width: '45%',
                      // backgroundColor: 'green',
                      width: switchMerchant
                        ? Dimensions.get('screen').width * 0.3
                        : Dimensions.get('screen').width * 0.35,
                    }}>
                    <TextInput
                      underlineColorAndroid={Colors.fieldtBgColor}
                      style={[
                        styles.textFieldInput,
                        {
                          fontSize: switchMerchant ? 10 : 14,
                          height: switchMerchant ? 35 : 40,
                          width: switchMerchant
                            ? Dimensions.get('screen').width * 0.1
                            : Dimensions.get('screen').width * 0.12,
                        },
                      ]}
                      placeholder={itemSKU ? itemSKU : 'CW100'}
                      placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                      onChangeText={(text) => {
                        // setState({ itemName: text });
                        setItemSKU(text);
                      }}
                      value={itemSKU}
                      //multiline={true}
                      ref={myTextInput}
                    />
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        //justifyContent: 'flex-end',
                        // backgroundColor: 'red',
                        // width: '100%',
                      }}>
                      <View
                        style={{
                          marginRight: Dimensions.get('screen').width * 0.01,
                          marginLeft: Dimensions.get('screen').width * 0.01,
                        }}>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: 'NunitoSans-Regular',
                            fontWeight: '500',
                          }}>
                          Qty
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          alignSelf: 'flex-end',
                        }}>
                        <TextInput
                          underlineColorAndroid={Colors.fieldtBgColor}
                          style={[
                            styles.textTax,
                            {
                              fontSize: switchMerchant ? 10 : 14,
                              width: switchMerchant
                                ? Dimensions.get('screen').width * 0.1
                                : Dimensions.get('screen').width * 0.12,
                              height: switchMerchant ? 35 : 40,
                            },
                          ]}
                          keyboardType={'number-pad'}
                          placeholderTextColor={Platform.select({
                            ios: '#a9a9a9',
                          })}
                          placeholder={'0'}
                          onChangeText={(text) => {
                            // setState({ itemTaxRate: text });
                            setItemQuantity(text);
                          }}
                          value={itemQuantity}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: switchMerchant ? 5 : 15,
                    width: '100%',
                    // backgroundColor: 'red'
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      //flex: 1,
                      justifyContent: 'space-between',
                      // width: '55%',
                      // backgroundColor: 'blue',
                    }}>
                    <View
                      style={
                        {
                          // width: '70%'
                        }
                      }>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: 'NunitoSans-Regular',
                          fontWeight: '500',
                        }}>
                        Price
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: switchMerchant ? 5 : 0,
                      //flex: 1,
                      // width: '45%',
                      // backgroundColor: 'green',
                      width: switchMerchant
                        ? Dimensions.get('screen').width * 0.3
                        : Dimensions.get('screen').width * 0.35,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignSelf: 'flex-end',
                        // marginRight: 19,
                      }}>
                      <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={[
                          styles.textPrice,
                          {
                            fontSize: switchMerchant ? 10 : 14,
                            width: switchMerchant
                              ? Dimensions.get('screen').width * 0.1
                              : Dimensions.get('screen').width * 0.12,
                            height: switchMerchant ? 35 : 40,
                          },
                        ]}
                        placeholder={itemPrice}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        onChangeText={(text) => {
                          // setState({ itemPrice: text });
                          setItemPrice(text);
                        }}
                        value={itemPrice}
                        ref={myTextInput}
                        keyboardType={'number-pad'}
                      />
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          fontWeight: '500',
                          fontFamily: 'NunitoSans-Regular',
                          marginLeft: 10,
                        }}>
                        MYR
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        //justifyContent: 'flex-end',
                        // backgroundColor: 'red',
                        // width: '100%',
                      }}>
                      <View
                        style={{
                          marginRight: Dimensions.get('screen').width * 0.01,
                          marginLeft: Dimensions.get('screen').width * 0.01,
                        }}>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: 'NunitoSans-Regular',
                            fontWeight: '500',
                          }}>
                          Tax
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          alignSelf: 'flex-end',
                        }}>
                        <TextInput
                          editable={false}
                          underlineColorAndroid={Colors.fieldtBgColor}
                          style={[
                            styles.textTax,
                            {
                              fontSize: switchMerchant ? 10 : 14,
                              width: switchMerchant
                                ? Dimensions.get('screen').width * 0.1
                                : Dimensions.get('screen').width * 0.12,
                              height: switchMerchant ? 35 : 40,
                            },
                          ]}
                          keyboardType={'number-pad'}
                          placeholderTextColor={Platform.select({
                            ios: '#a9a9a9',
                          })}
                          placeholder={
                            outletsTaxDict[place]
                              ? outletsTaxDict[place].rate
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                              : '0'
                          }
                          onChangeText={(text) => {
                            // setState({ itemTaxRate: text });
                            setItemTaxRate(text);
                          }}
                          value={itemTaxRate}
                          //multiline={true}
                          ref={myTextInput}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    //flex: 1,
                    marginBottom: 15,
                    width: '100%',
                  }}>
                  <View>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Regular',
                        fontWeight: '500',
                      }}>
                      Category
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.textInput4,
                      {
                        width: switchMerchant
                          ? Dimensions.get('screen').width * 0.3
                          : Dimensions.get('screen').width * 0.35,
                        height: switchMerchant ? 35 : 40,
                        justifyContent: 'center',
                      },
                    ]}>
                    {outletCategoryDropdownList.find(
                      (o) => o.value === selectedOutletCategoryId,
                    ) ? (
                      <Picker
                        {...(outletCategoryDropdownList.find(
                          (o) => o.value === selectedOutletCategoryId,
                        ) && { selectedValue: selectedOutletCategoryId })}
                        style={{
                          width: windowWidth * 0.35,
                          backgroundColor: Colors.fieldtBgColor,
                          borderRadius: 10,
                          fontSize: 14,
                        }}
                        onValueChange={(value, text) => {
                          setSelectedOutletCategoryId(value)
                        }}
                      >
                        {outletCategoryDropdownList.map((value, index) => {
                          return (
                            <Picker.Item
                              key={index}
                              label={value.label}
                              value={value.value}
                            />
                          );
                        })}
                      </Picker>
                    ) : (
                      <></>
                    )}
                  </View>
                </View>

                {/* herks test - addons */}
                {/* //////////////////////////////////////////////////////// */}

                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    //flex: 1,
                    marginBottom: 15,
                    width: '100%',
                    //backgroundColor: 'red',
                    marginTop: 15,

                    zIndex: -1,
                  }}>
                  <View>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: 'NunitoSans-Regular',
                        fontWeight: '500',
                      }}>
                      Option
                    </Text>
                  </View>

                  <View
                    style={{
                      width: switchMerchant
                        ? Dimensions.get('screen').width * 0.3
                        : Dimensions.get('screen').width * 0.35,
                      flexDirection: 'row',
                      alignItems: 'center',

                      // justifyContent: 'space-between',
                    }}>
                    <TouchableOpacity
                      onPress={() => {
                        // setState({ visible1: true });
                        // setVisible1(true);
                        // setShowAddOn(true);

                        const lastIndex = variantGroupList.length;

                        setVariantGroupList([
                          ...variantGroupList,
                          {
                            groupId: '',
                            choices: [
                              {
                                choiceId: '',
                                choiceName: '',
                                choicePrice: '',
                              },
                            ],
                            name: '',
                            minSelect: 1,
                            maxSelect: 1,

                            isNew: true,
                          },
                        ]);

                        setVariantGroupIndex(lastIndex);

                        setShowAddOnGroup(false);
                        setShowVariantGroup(true);
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}>
                        <View
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            //paddingLeft: 1,
                            width: 18,
                            height: 18,
                            borderRadius: 9,
                            backgroundColor: Colors.primaryColor,
                          }}>
                          <Feather
                            name="plus"
                            size={10}
                            color={'#ffffff'}
                          />
                        </View>
                        <Text
                          style={{
                            marginLeft: switchMerchant ? 5 : 10,
                            color: Colors.primaryColor,
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: 'NunitoSans-Regular',

                            //top: -1,
                          }}>
                          {/* Add variant group */}
                          Variant
                          {/* Single */}
                        </Text>
                      </View>
                    </TouchableOpacity>

                    <TouchableOpacity
                      onPress={() => {
                        // setState({ visible1: true });
                        // setVisible1(true);
                        // setShowAddOn(true);

                        const lastIndex = addOnGroupList.length;

                        setAddOnGroupList([
                          ...addOnGroupList,
                          {
                            groupId: '',
                            choices: [
                              {
                                choiceId: '',
                                choiceName: '',
                                choicePrice: '',
                                choiceMinSelect: 0,
                                choiceMaxSelect: 0,
                              },
                            ],
                            name: '',

                            isNew: true,
                          },
                        ]);

                        setAddOnGroupIndex(lastIndex);

                        setShowAddOnGroup(true);
                        setShowVariantGroup(false);
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',

                          marginLeft: 25,
                        }}>
                        {/* <Icon
                                                    name="plus-circle"
                                                    size={20}
                                                    color={Colors.primaryColor}
                                                />  */}
                        <View
                          style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            //paddingLeft: 1,
                            width: 18,
                            height: 18,
                            borderRadius: 9,
                            backgroundColor: Colors.primaryColor,
                          }}>
                          <Feather
                            name="plus"
                            size={10}
                            color={'#ffffff'}
                          />
                        </View>
                        <Text
                          style={{
                            marginLeft: switchMerchant ? 5 : 10,
                            color: Colors.primaryColor,
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: 'NunitoSans-Regular',

                            //top: -1,
                          }}>
                          {/* Add add-on group */}
                          Add Ons
                          {/* Multiple */}
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    //flex: 1,
                    marginBottom: 15,
                    width: '100%',
                    //backgroundColor: 'red',
                    marginTop: 15,

                    zIndex: -1,
                  }}>
                  <View
                    style={{
                      width: Dimensions.get('screen').width * 0.35,
                    }}>
                    <View>
                      {variantGroupList.map((variantGroup, i) => {
                        return (
                          <View
                            style={{
                              marginBottom: Platform.OS === 'ios' ? 20 : 20,

                              flexDirection: 'row',
                              alignItems: 'center',
                            }}>
                            <TouchableOpacity
                              style={{
                                marginRight: 15,
                                marginTop:
                                  Platform.OS === 'ios'
                                    ? 22
                                    : switchMerchant
                                      ? 22
                                      : 0,
                              }}
                              onPress={() => {
                                setVariantGroupIndex(i);

                                setShowVariantGroup(true);
                              }}>
                              <Feather
                                name='edit'
                                size={18}
                                color={Colors.primaryColor}
                              />
                            </TouchableOpacity>

                            <View>
                              <Text
                                style={{
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: 'NunitoSans-SemiBold',
                                }}>
                                {'Variant (Single)'}
                              </Text>
                              <View
                                style={{
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                }}>
                                <ScrollView
                                  showsHorizontalScrollIndicator={false}
                                  horizontal={true}
                                  contentContainerStyle={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingVertical: 4,
                                    paddingHorizontal: 4,
                                    //backgroundColor: 'blue',
                                  }}
                                  style={{
                                    marginTop: 5,
                                    width: windowWidth * 0.4,
                                  }}>
                                  <View
                                    style={{
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginRight: 10,

                                      backgroundColor: '#F6F7F9',
                                      paddingVertical: 8,
                                      paddingHorizontal: 32,
                                      borderRadius: 12,
                                      shadowColor: '#000',
                                      shadowOffset: {
                                        width: 0,
                                        height: 2,
                                      },
                                      shadowOpacity: 0.22,
                                      shadowRadius: 3.22,
                                      elevation: 1,
                                    }}>
                                    <Text
                                      style={{
                                        fontSize: switchMerchant ? 10 : 16,
                                        fontFamily: 'NunitoSans-SemiBold',
                                        color: Colors.descriptionColor,
                                      }}>
                                      {variantGroup.name}
                                    </Text>
                                  </View>

                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    }}>
                                    {variantGroup.choices.map((choice, j) => {
                                      console.log(choice);

                                      return (
                                        <View
                                          style={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'row',
                                            marginRight: 10,

                                            backgroundColor: '#EBECF0',
                                            paddingVertical: 4,
                                            paddingHorizontal: 24,
                                            borderRadius: 12,
                                            shadowColor: '#000',
                                            shadowOffset: {
                                              width: 0,
                                              height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 1,
                                          }}>
                                          <Text
                                            style={{
                                              fontSize: switchMerchant
                                                ? 10
                                                : 14,
                                              fontFamily: 'NunitoSans-Regular',
                                            }}>
                                            {`${choice.choiceName} +(RM${choice.choicePrice})`}
                                          </Text>
                                        </View>
                                      );
                                    })}
                                  </View>
                                </ScrollView>
                                <View style={{ paddingLeft: 5 }}>
                                  <TouchableOpacity
                                    style={{}}
                                    onPress={() => {
                                      if (window.confirm('Alert: Are you sure you want to delete this variant?')) {
                                        setVariantGroupList([
                                          ...variantGroupList.slice(0, i),
                                          ...variantGroupList.slice(
                                            i + 1,
                                          ),
                                        ]);
                                      }
                                    }}>
                                    <Icon
                                      name="minus-circle"
                                      size={20}
                                      color="#eb3446"
                                    />
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      })}

                      {/* ///////////////////////////////////////////////////////// */}

                      {addOnGroupList.map((addOnGroup, i) => {
                        return (
                          <View
                            style={{
                              marginBottom: 20,

                              flexDirection: 'row',
                              alignItems: 'center',
                            }}>
                            <TouchableOpacity
                              style={{
                                marginRight: 15,
                                marginTop:
                                  Platform.OS === 'ios'
                                    ? 22
                                    : switchMerchant
                                      ? 22
                                      : 0,
                              }}
                              onPress={() => {
                                setAddOnGroupIndex(i);

                                setShowAddOnGroup(true);
                              }}>
                              <Feather
                                name='edit'
                                size={18}
                                color={Colors.primaryColor}
                              />
                            </TouchableOpacity>

                            <View>
                              <Text
                                style={{
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: 'NunitoSans-SemiBold',
                                }}>
                                {'Add-Ons (Multiple)'}
                              </Text>

                              <View
                                style={{
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                }}>
                                <ScrollView
                                  showsHorizontalScrollIndicator={false}
                                  horizontal={true}
                                  contentContainerStyle={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingVertical: 4,
                                    paddingHorizontal: 4,
                                  }}
                                  style={{
                                    marginTop: 5,
                                    width: Dimensions.get('screen').width * 0.4,
                                  }}>
                                  <View
                                    style={{
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      marginRight: 10,

                                      backgroundColor: '#F6F7F9',
                                      paddingVertical: 8,
                                      paddingHorizontal: 32,
                                      borderRadius: 12,
                                      shadowColor: '#000',
                                      shadowOffset: {
                                        width: 0,
                                        height: 2,
                                      },
                                      shadowOpacity: 0.22,
                                      shadowRadius: 3.22,
                                      elevation: 1,
                                    }}>
                                    <Text
                                      style={{
                                        fontSize: switchMerchant ? 10 : 16,
                                        fontFamily: 'NunitoSans-SemiBold',
                                        color: Colors.descriptionColor,
                                      }}>
                                      {addOnGroup.name}
                                    </Text>
                                  </View>

                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    }}>
                                    {addOnGroup.choices.map((choice, j) => {
                                      console.log(choice);

                                      return (
                                        <View
                                          style={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            flexDirection: 'row',
                                            marginRight: 10,

                                            backgroundColor: '#EBECF0',
                                            paddingVertical: 4,
                                            paddingHorizontal: 24,
                                            borderRadius: 12,
                                            shadowColor: '#000',
                                            shadowOffset: {
                                              width: 0,
                                              height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 1,
                                          }}>
                                          <Text
                                            style={{
                                              fontSize: switchMerchant
                                                ? 10
                                                : 14,
                                              fontFamily: 'NunitoSans-Regular',
                                            }}>
                                            {/* {`${choice.choiceName} (RM${choice.choicePrice.toFixed(2)})`} */}
                                            {`${choice.choiceName
                                              } (${choice.choiceMinSelect.toFixed(
                                                0,
                                              )}-${choice.choiceMaxSelect.toFixed(
                                                0,
                                              )}) +(RM${choice.choicePrice})`}
                                          </Text>
                                        </View>
                                      );
                                    })}
                                  </View>
                                </ScrollView>
                                <View style={{ paddingLeft: 5 }}>
                                  <TouchableOpacity
                                    style={{}}
                                    onPress={() => {
                                      if (window.confirm('Alert: Are you sure you want to delete this add-on?')) {
                                        setAddOnGroupList([
                                          ...addOnGroupList.slice(0, i),
                                          ...addOnGroupList.slice(i + 1),
                                        ]);
                                      }
                                    }}>
                                    <Icon
                                      name="minus-circle"
                                      size={20}
                                      color="#eb3446"
                                    />
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/* <View style={{}> */}

            {/* </View> */}

            <View
              style={{
                zIndex: -5,
              }}>
              <Text
                style={{
                  alignSelf: 'center',
                  fontFamily: 'NunitoSans-Bold',
                  // marginBottom: 40,
                  marginTop: 20,
                  fontSize: switchMerchant ? 15 : 20,
                }}>
                Inventory Info
              </Text>

              <View
                style={{
                  flexDirection: 'row',
                  flex: 1,
                  marginTop: 0,
                  justifyContent: 'space-between',
                  width: '100%',
                  paddingHorizontal: 25,

                  zIndex: -5,
                }}>
                <TouchableOpacity
                  onPress={() => {
                    addSlot();
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: '3%',
                    }}>
                    <Icon
                      name="plus-circle"
                      size={switchMerchant ? 17 : 20}
                      color={Colors.primaryColor}
                    />
                    <Text
                      style={{
                        //marginBottom: 2,
                        marginLeft: 7,
                        color: Colors.primaryColor,
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      Link Inventory
                    </Text>
                  </View>
                </TouchableOpacity>
                <View
                  style={{
                    borderRadius: 10,
                    // alignItems: 'center',
                    // justifyContent: 'center',
                    //marginLeft: '60%',
                    flexDirection: 'row',
                  }}>
                </View>
              </View>
              {/* { selectedProductEdit && stockLinkItems.length > 1 ?    */}
              <View
                style={{
                  borderColor: '#E5E5E5',
                  borderWidth: 1,
                  // width: 1100,
                  padding: 16,
                  borderRadius: 10,
                  marginTop: 20,
                  marginHorizontal: 25,
                  paddingBottom: Dimensions.get('screen').width * 0.1,
                }}>
                {renderStockLinkItems()}
              </View>
              <View style={{ height: 30 }}></View>
              {/* : null } */}
            </View>
          </ScrollView>
        </View>
      </View>
      {/* <Modal
      style={{ flex: 1 }}
      visible={visible}
      transparent={true}
      animationType="slide">
      <View
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: Dimensions.get('window').height,
        }}>
        <View style={styles.confirmBox}>
          <TouchableOpacity
            onPress={() => {
              setState({ visible: false });
            }}>
            <View
              style={{
                alignSelf: 'flex-end',
                padding: 16,
              }}>
              <Close name="closecircle" size={28} />
            </View>
          </TouchableOpacity>
          <View
            style={{
              alignSelf: 'center',
              marginTop: '10%',
              justifyContent: 'center',
              alignItems: 'center',
              width: 250,
              height: 40,
              alignContent: 'center',
            }}>
            <TouchableOpacity
              onPress={() => {
                setState({ visible1: true });
              }}
              style={{
                backgroundColor: Colors.primaryColor,
                width: '60%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                borderRadius: 10,
                height: 60,
              }}>
              <Text style={{ fontSize: 28, color: Colors.whiteColor, fontFamily: 'NunitoSans-Regular' }}>
                Single
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({ visible2: true });
              }}
              style={{
                backgroundColor: Colors.primaryColor,
                width: '60%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                borderRadius: 10,
                height: 60,
                marginTop: 10,
              }}>
              <Text style={{ fontSize: 28, color: Colors.whiteColor, fontFamily: 'NunitoSans-Regular' }}>
                Multiple
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal> */}
      <Modal
        style={{ flex: 1 }}
        visible={showVariantGroup}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        animationType="slide">
        <View>
          <View
            style={{
              backgroundColor: 'rgba(0,0,0,0.5)',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: Dimensions.get('window').height,
            }}>
            <View
              style={[
                styles.confirmBox1,
                {
                  height: switchMerchant ? '70%' : '60%',
                  width: switchMerchant ? '45%' : '40%',
                },
              ]}>
              <TouchableOpacity
                onPress={() => {
                  // setState({ visible1: false });
                  // setVisible1(false);
                  setShowVariantGroup(false);
                  setShowVariantDetails(false);

                  if (
                    variantGroupList[variantGroupIndex] &&
                    variantGroupList[variantGroupIndex].isNew
                  ) {
                    setVariantGroupList([
                      ...variantGroupList.slice(0, variantGroupIndex),
                      ...variantGroupList.slice(variantGroupIndex + 1),
                    ]);
                  }
                }}>
                <View
                  style={{
                    marginTop: switchMerchant ? 20 : 20,
                    marginRight: switchMerchant ? 10 : 20,
                    alignSelf: 'flex-end',
                    height: 26,
                    width: 26,
                  }}>
                  {/* <Close name="closecircle" size={24} /> */}
                  <AntDesign
                    name="closecircle"
                    size={switchMerchant ? 15 : 25}
                    color={Colors.fieldtTxtColor}
                  />
                </View>
              </TouchableOpacity>
              <View
                style={{
                  alignSelf: 'center',
                  marginTop: switchMerchant ? 0 : 20,
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: switchMerchant ? '90%' : '80%',
                  height: switchMerchant ? '80%' : '80%',
                  alignContent: 'center',
                }}>
                <Text
                  style={{
                    fontSize: switchMerchant ? 15 : 20,
                    fontFamily: 'NunitoSans-Bold',
                  }}>
                  Option (Single)
                </Text>

                {/* this */}

                <>
                  {variantGroupList[variantGroupIndex] ? (
                    <>
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: 10,
                          alignContent: 'space-between',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: 50,
                          width: '100%',
                        }}>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 18,
                            fontFamily: 'NunitoSans-SemiBold',
                            marginRight: 15,
                          }}>
                          Title
                          {/* yes */}
                        </Text>
                        <TextInput
                          underlineColorAndroid={Colors.fieldtBgColor}
                          style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? 120 : 140,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontSize: switchMerchant ? 10 : 14,
                          }}
                          placeholder="Category"
                          placeholderTextColor={Platform.select({
                            ios: '#a9a9a9',
                          })}
                          onChangeText={(text) => {
                            // setState({ options: text });

                            setVariantGroupList(
                              variantGroupList.map((variantGroup, index) =>
                                index === variantGroupIndex
                                  ? {
                                    ...variantGroup,
                                    name: text,
                                  }
                                  : variantGroup,
                              ),
                            );
                          }}
                          value={variantGroupList[variantGroupIndex].name}
                          //multiline={true}
                          ref={myTextInput}
                        />
                      </View>
                      <View
                        style={{
                          marginTop: switchMerchant ? 0 : 10,
                          marginBottom: switchMerchant ? 0 : 10,
                          marginLeft: '80%',
                        }}>
                        {/* <TouchableOpacity
                                                    onPress={() => {
                                                        // onadditem();

                                                        setVariantGroupList(variantGroupList.map((variantGroup, index) => (index === variantGroupIndex ? {
                                                            ...variantGroup,
                                                            choices: [
                                                                ...variantGroup.choices,
                                                                {
                                                                    choiceId: '',
                                                                    choiceName: '',
                                                                    choicePrice: 0,
                                                                }
                                                            ]
                                                        } : variantGroup)));
                                                    }}
                                                    style={{
                                                        backgroundColor: Colors.whiteColor,
                                                        alignItems: 'center',
                                                        flexDirection: 'row',
                                                    }}>
                                                    <Text style={{ color: Colors.primaryColor }}>Add Slot{' '}</Text>
                                                    <Icon
                                                        name="plus-circle"
                                                        size={30}
                                                        color={Colors.primaryColor}
                                                    />
                                                </TouchableOpacity> */}
                      </View>
                      <ScrollView
                        showsVerticalScrollIndicator={false}
                        style={{ width: '100%', height: '50%' }}>
                        {renderVariantGroupChoices()}
                        {/* {!showVariantDetails ?
                                                    <FlatList
                                                        data={variantGroupList}
                                                        extraData={variantGroupList}
                                                        renderItem={renderVariantGroupChoices}
                                                        keyExtractor={(item, index) => String(index)}
                                                    />
                                                    :
                                                    <FlatList
                                                        data={variantGroupList}
                                                        extraData={variantGroupList}
                                                        renderItem={renderVariantGroupChoicesDetails}
                                                        keyExtractor={(item, index) => String(index)}
                                                    />

                                                } */}
                      </ScrollView>
                      <View
                        style={{
                          marginTop: 10,
                          width: '100%',
                          alignItems: 'center',
                        }}>
                        <View>
                          <TouchableOpacity
                            style={{ width: 120 }}
                            disabled={loadingImageInput}
                            onPress={() => {
                              // setState({
                              //     productOptionsState,
                              //     visible: false,
                              //     visible1: false,
                              //     visible2: false,
                              // });
                              setShowVariantGroup(false);

                              setVariantGroupList(
                                variantGroupList.map((variantGroup, index) =>
                                  index === variantGroupIndex
                                    ? {
                                      ...variantGroup,
                                      isNew: false,
                                    }
                                    : variantGroup,
                                ),
                              );
                            }}>
                            <View
                              style={{
                                justifyContent: 'center',
                                flexDirection: 'row',
                                borderWidth: 1,
                                borderColor: Colors.primaryColor,
                                backgroundColor: '#4E9F7D',
                                borderRadius: 5,
                                width: switchMerchant ? 100 : 120,
                                paddingHorizontal: 10,
                                height: switchMerchant ? 35 : 40,
                                alignItems: 'center',
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                                zIndex: -1,
                              }}>
                              <Text
                                style={{
                                  color: Colors.whiteColor,
                                  //marginLeft: 5,
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: 'NunitoSans-Bold',
                                }}>
                                {isLoading ? 'LOADING...' : 'SAVE'}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        style={{ flex: 1 }}
        visible={showAddOnGroup}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        animationType="slide">
        <View
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: Dimensions.get('window').height,
          }}>
          <View
            style={[
              styles.confirmBox1,
              {
                height: switchMerchant ? '70%' : '60%',
                width: switchMerchant ? '45%' : '40%',
              },
            ]}>
            <TouchableOpacity
              onPress={() => {
                // setState({ visible2: false });
                setShowAddOnGroup(false);
                setShowDetails(false);

                if (
                  addOnGroupList[addOnGroupIndex] &&
                  addOnGroupList[addOnGroupIndex].isNew
                ) {
                  setAddOnGroupList([
                    ...addOnGroupList.slice(0, addOnGroupIndex),
                    ...addOnGroupList.slice(addOnGroupIndex + 1),
                  ]);
                }
              }}>
              <View
                style={{
                  marginTop: 20,
                  marginRight: switchMerchant ? 10 : 20,
                  alignSelf: 'flex-end',
                  height: 26,
                  width: 26,
                }}>
                {/* <Close name="closecircle" size={24} /> */}
                <AntDesign
                  name="closecircle"
                  size={switchMerchant ? 15 : 25}
                  color={Colors.fieldtTxtColor}
                />
              </View>
            </TouchableOpacity>
            <View
              style={{
                alignSelf: 'center',
                marginTop: switchMerchant ? 0 : 5,
                alignItems: 'center',
                width: switchMerchant ? '90%' : '90%',
                height: switchMerchant ? '80%' : '80%',
                alignContent: 'center',
              }}>
              <Text
                style={{
                  fontSize: switchMerchant ? 15 : 20,
                  fontFamily: 'NunitoSans-Bold',
                  marginTop: switchMerchant ? 5 : 20,
                }}>
                Options (Multiple)
              </Text>

              <>
                {addOnGroupList[addOnGroupIndex] ? (
                  <>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: switchMerchant ? 0 : 10,
                        alignContent: 'space-between',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 50,
                        width: switchMerchant ? '100%' : '120%',
                      }}>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 18,
                          fontFamily: 'NunitoSans-Bold',
                          marginRight: 15,
                        }}>
                        Title
                        {/* not */}
                      </Text>
                      <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                          backgroundColor: Colors.fieldtBgColor,
                          width: switchMerchant ? 120 : 140,
                          height: switchMerchant ? 35 : 40,
                          borderRadius: 5,
                          padding: 5,
                          marginVertical: 5,
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          paddingLeft: 10,
                          fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder="Category"
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        onChangeText={(text) => {
                          // setState({ options: text });

                          setAddOnGroupList(
                            addOnGroupList.map((addOnGroup, index) =>
                              index === addOnGroupIndex
                                ? {
                                  ...addOnGroup,
                                  name: text,
                                }
                                : addOnGroup,
                            ),
                          );
                        }}
                        value={addOnGroupList[addOnGroupIndex].name}
                        //multiline={true}
                        ref={myTextInput}
                      />
                    </View>
                    <View></View>
                    <ScrollView
                      showsVerticalScrollIndicator={false}
                      style={{ width: '100%', height: '50%' }}>
                      {/* <View
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 10,
                                                marginLeft: '80%',
                                            }}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    // onadditem1();
                                                    setAddOnGroupList(addOnGroupList.map((addOnGroup, index) => (index === addOnGroupIndex ? {
                                                        ...addOnGroup,
                                                        choices: [
                                                            ...addOnGroup.choices,
                                                            {
                                                                choiceId: '',
                                                                choiceName: '',
                                                                choicePrice: 0,
                                                                choiceMinSelect: 0,
                                                                choiceMaxSelect: 0,
                                                            }
                                                        ]
                                                    } : addOnGroup)));
                                                }}
                                                style={{
                                                    backgroundColor: Colors.whiteColor,
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                }}>
                                                <Text style={{ color: Colors.primaryColor }}>Add Slot{' '}</Text>
                                                <Icon
                                                    name="plus-circle"
                                                    size={20}
                                                    color={Colors.primaryColor}
                                                />
                                            </TouchableOpacity>
                                        </View> */}
                      {renderAddOnGroupChoices()}
                      {/* {!showDetails ?
                                                <FlatList
                                                    data={addOnGroupList}
                                                    extraData={addOnGroupList}
                                                    renderItem={renderAddOnGroupChoices}
                                                    keyExtractor={(item, index) => String(index)}
                                                />
                                                :
                                                <FlatList
                                                    data={addOnGroupList}
                                                    extraData={addOnGroupList}
                                                    renderItem={renderAddOnGroupChoicesDetails}
                                                    keyExtractor={(item, index) => String(index)}
                                                />

                                            } */}
                    </ScrollView>

                    {/* <View
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                marginTop: 10,
                                                alignContent: 'space-between',
                                                height: 50,
                                                idth: '100%',
                                            }}>

                                            <Text
                                                style={{
                                                    fontSize: 20,
                                                    fontFamily: 'NunitoSans-Bold',
                                                    marginRight: '7%',
                                                }}>
                                                Price :
              </Text>
                                            <TextInput
                                                underlineColorAndroid={Colors.fieldtBgColor}
                                                style={{
                                                    width: 100,
                                                    padding: 10,
                                                    backgroundColor: Colors.fieldtBgColor,
                                                    borderRadius: 5,
                                                    marginLeft: '3%',
                                                }}
                                                placeholder="Price"
                                                onChangeText={(text) => {
                                                    setState({ price1: text });
                                                }}
                                                value={price1}
                                                multiline={true}
                                                ref={myTextInput}
                                            />
                                            <Text
                                                style={{
                                                    fontSize: 20,
                                                    fontFamily: 'NunitoSans-Bold',
                                                }}>
                                                per toppings
              </Text>
                                        </View> */}

                    <View
                      style={{
                        marginTop: 10,
                        width: '100%',
                        alignItems: 'center',
                      }}>
                      <View>
                        <TouchableOpacity
                          style={{ width: 120 }}
                          disabled={loadingImageInput}
                          onPress={() => {
                            // setState({
                            //     productOptionsState,
                            //     visible: false,
                            //     visible1: false,
                            //     visible2: false,
                            // });
                            setShowAddOnGroup(false);

                            setAddOnGroupList(
                              addOnGroupList.map((addOnGroup, index) =>
                                index === addOnGroupIndex
                                  ? {
                                    ...addOnGroup,
                                    isNew: false,
                                  }
                                  : addOnGroup,
                              ),
                            );
                          }}>
                          <View
                            style={{
                              justifyContent: 'center',
                              flexDirection: 'row',
                              borderWidth: 1,
                              borderColor: Colors.primaryColor,
                              backgroundColor: '#4E9F7D',
                              borderRadius: 5,
                              width: switchMerchant ? 100 : 120,
                              paddingHorizontal: 10,
                              height: switchMerchant ? 35 : 40,
                              alignItems: 'center',
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 1,
                              zIndex: -1,
                            }}>
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                //marginLeft: 5,
                                fontSize: switchMerchant ? 10 : 16,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {isLoading ? 'LOADING...' : 'SAVE'}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}
              </>
            </View>
          </View>
        </View>
      </Modal>

      {tagModal &&
        <View
          style={{
            flex: 1,
            position: 'absolute',

            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.22,
            shadowRadius: 3.22,
            elevation: 3,

            borderRadius: 8,
            top: '10%',
            left: '10%',
          }}
        >
          <View
            style={[
              styles.modalContainer,
              {
                backgroundColor: 'transparent',

                backgroundColor: 'white',

                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 3,

                borderWidth: 1,
                borderRadius: 8,
              },
            ]}>
            <View
              style={[
                styles.tagModalView,
                {
                  width: 415,
                  backgroundColor: 'transparent',
                  justifyContent: 'space-between',
                },
              ]}>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  alignItems: 'center',
                  marginBottom: 10
                }}>
                <Text
                  style={{
                    fontWeight: '700',
                    fontSize: switchMerchant ? 10 : 21,
                  }}>
                  Tags
                </Text>

                <TouchableOpacity
                  style={[styles.closeButton,
                  {

                  }]}
                  onPress={() => {
                    setTagModal(false);
                  }}>
                  <AntDesign
                    name="closecircle"
                    size={25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flex: 3,
                  borderRadius: 5,
                  paddingVertical: 3,
                  paddingLeft: 5,
                  marginVertical: 5,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  width: '100%',
                }}>
                <View
                  style={{
                    flex: 6,
                    flexDirection: 'row',
                    alignItems: 'center',
                    zIndex: 1,
                  }}>
                  <AntDesign
                    name="tags"
                    size={20}
                    style={{ color: 'black', marginHorizontal: 5 }}
                  />
                  <Select
                    defaultValue={selectedUserTagList}
                    onChange={(items) => {
                      setSelectedUserTagList(items);
                    }}
                    options={userTagDropdownList}
                    isMulti
                  />
                </View>

                <View
                  style={{
                    flex: 2,
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}>
                  <TouchableOpacity
                    style={{
                      backgroundColor: Colors.primaryColor,
                      width: 70,
                      height: 35,
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 5,

                      flexDirection: 'row',
                    }}
                    disabled={isLoading}
                    onPress={() => createCRMUserTagOrAddCRMUserTagToProduct()}>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 17,
                        fontWeight: '600',
                        color: 'white',
                      }}>
                      {isLoading ? '' : 'SAVE'}
                    </Text>

                    {isLoading ? (
                      <ActivityIndicator
                        color={Colors.whiteColor}
                        size={'small'}
                      />
                    ) : (
                      <></>
                    )}
                  </TouchableOpacity>
                  {/* <TouchableOpacity
                          style={{
                            backgroundColor: 'green', width: 70, height: 35, justifyContent: 'center', alignItems: 'center',
                            borderRadius: 5,
                            // zIndex: -1,
                          }}
                          onPress={() => { }}
                        >
                          <Text style={{ fontSize: 17, fontWeight: '600', color: 'white' }}>
                            Create
                          </Text>
                        </TouchableOpacity> */}
                </View>
              </View>

              {/* <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginVertical: 5, zIndex: -1 }}>
                                    <View style={{ borderWidth: 1, borderColor: '#E5E5E5', width: '26%', height: 1 }} />
                                    <Text style={{ fontWeight: 'bold', marginHorizontal: 5, fontSize: 20 }}>
                                        Product Own Tags
                                    </Text>
                                    <View style={{ borderWidth: 1, borderColor: '#E5E5E5', width: '26%', height: 1 }} />
                                </View> */}

              {/* Suggested Tags Start */}
              {/* <View style={{ zIndex: -1 }}>
                      <TouchableOpacity
                        style={{ borderWidth: 1.5, borderColor: Colors.secondaryColor, alignSelf: 'baseline', padding: 5, paddingHorizontal: 10, borderRadius: 5 }}
                        onPress={() => { }}
                      >
                        <Text style={{ fontWeight: '600', color: Colors.tabGold }}>
                          VIP
                        </Text>
                      </TouchableOpacity>
                    </View> */}
              {/* Suggested Tags End */}
            </View>
          </View>
        </View>
      }
    </View>
  );
};

const styles = StyleSheet.create({
  confirmBox: {
    width: '30%',
    height: '30%',
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  confirmBox1: {
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
  },
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    //width: Dimensions.get('screen').width * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 20,
    width: Dimensions.get('screen').width * (1 - Styles.sideBarWidth),
    backgroundColor: Colors.highlightColor,
  },
  content1: {
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#c4c4c4',
  },
  content2: {
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
  content3: {
    backgroundColor: Colors.whiteColor,
    width: 40,
    height: 40,
    borderRadius: 20,
    alignSelf: 'flex-end',
    position: 'absolute',
    right: -15,
    marginVertical: -10,
  },
  content4: {
    backgroundColor: Colors.whiteColor,
    width: 120,
    height: 20,
    borderRadius: 20,
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  content5: {
    backgroundColor: Colors.whiteColor,
    width: 140,
    height: 140,
    borderRadius: 5,
    marginLeft: 40,
    borderStyle: 'dashed',
    borderWidth: 2,
    alignItems: 'center',
    borderColor: Colors.primaryColor,
  },

  content6: {
    backgroundColor: '#717378',
    width: 40,
    height: 40,
    marginLeft: 20,
    marginVertical: 10,
    borderRadius: 5,
  },

  searchBar: {
    marginHorizontal: 16,
    backgroundColor: Colors.fieldtBgColor,
    flexDirection: 'row',
    padding: 12,
    borderRadius: 10,
    alignContent: 'center',
  },
  submitText: {
    height: 40,
    paddingHorizontal: 20,
    flexDirection: 'row',
    color: '#4cd964',
    textAlign: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  detailContainer: {
    flexDirection: 'row',
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginTop: 20,
    borderBottomColor: Colors.fieldtTxtColor,
    justifyContent: 'space-between',
  },
  tableIcon: {
    // backgroundColor: '#717378',
    width: (Dimensions.get('window').width - 550) / 4,
    height: (Dimensions.get('window').width - 550) / 4,
    borderRadius: 10,
    marginLeft: 30,
    marginBottom: 20,
    justifyContent: 'center',
    borderStyle: 'dashed',
    borderWidth: 1,
    borderColor: Colors.fieldtTxtColor,
  },
  category: {
    width: 150,
    justifyContent: 'center',
    alignItems: 'center',
  },
  infoTab: {
    backgroundColor: Colors.highlightColor,
    width: Dimensions.get('screen').width * 0.25,
    height: -100,
  },
  textFieldInput: {
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    padding: 5,
    marginVertical: 5,
    borderWidth: 1,
    borderColor: '#E5E5E5',
    paddingLeft: 10,
  },
  textFieldInput1: {
    height: 40,
    width: 400,
    paddingHorizontal: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  textInventory: {
    height: 40,
    width: 200,
    paddingHorizontal: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  textFieldInput2: {
    height: 40,
    width: 270,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  textInput: {
    width: 250,
    height: Platform.OS == 'ios' ? 40 : 50,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: '32%',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  textPrice: {
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    padding: 5,
    marginVertical: 5,
    borderWidth: 1,
    borderColor: '#E5E5E5',
    paddingLeft: 10,
  },
  textTax: {
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    padding: 5,
    marginVertical: 5,
    borderWidth: 1,
    borderColor: '#E5E5E5',
    paddingLeft: 10,
  },
  textInput1: {
    width: 100,
    padding: 6,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  button: {
    width: '50%',
    height: 50,
    backgroundColor: Colors.primaryColor,
    borderRadius: 10,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInput4: {
    // width: 250,
    borderRadius: 10,
    //marginRight: '41%',
    // zIndex: 1000,
  },
  itemText: {
    margin: 10,
    color: 'white',
    fontSize: 24,
    backgroundColor: 'blue',
    width: '100%',
    height: 50,
  },
  confirmMenu: {
    width: '70%',
    height: '70%',
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  headerLeftStyle: {
    width: Dimensions.get('screen').width * 0.17,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
  },
  modalView: {
    height: Dimensions.get('screen').width * 0.2,
    width: Dimensions.get('screen').width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get('screen').width * 0.03,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tagModalView: {
    //height: Dimensions.get('screen').width * 0.4,
    //width: Dimensions.get('screen').width * 0.4,
    backgroundColor: Colors.whiteColor,
    borderRadius: Dimensions.get('screen').width * 0.03,
    padding: 20,
    paddingTop: 25,
    //alignItems: 'center',
    //justifyContent: 'center'
  },
  modalViewImport: {
    height: Dimensions.get('screen').width * 0.6,
    width: Dimensions.get('screen').width * 0.6,
    backgroundColor: Colors.whiteColor,
    borderRadius: Dimensions.get('screen').width * 0.03,
    padding: 20,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  closeButton: {
    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: 'center',
    top: '20%',
    position: 'absolute',
  },
  modalBody: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalTitleText: {
    fontFamily: 'NunitoSans-Bold',
    textAlign: 'center',
  },
  modalDescText: {
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 25,
    width: '20%',
  },
  modalSaveButton: {
    width: Dimensions.get('screen').width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
});
export default ProductAddScreen;
