import React, { Component, useReducer, useState, useEffect } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Alert,
  Modal,
  FlatList,
  Dimensions,
  Platform,
  useWindowDimensions,
  Picker,
} from 'react-native';
import { useLinkTo, } from "@react-navigation/native";
import { prefix } from "../constant/env";
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import SideBar from './SideBar';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import * as User from '../util/User';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
// import DropDownPicker from 'react-native-dropdown-picker';
import Select from 'react-select';
//import { Multiselect } from 'multiselect-react-dropdown';
// import { MultiSelectDropdown } from 'react-native-multiselect-dropdown-picker';
// import {TextInput} from 'react-native-gesture-handler';
// import Magnify from '../assets/svg/Magnify';
// import FIcon from 'react-native-vector-icons/Foundation';
import { ReactComponent as Edit } from '../assets/svg/Edit.svg';
import moment from 'moment';
import Feather from 'react-native-vector-icons/Feather';
// import DropDownPickerPlus from './components/DropDownPickerPlus';
// import Ionicon from 'react-native-vector-icons/Ionicons';
// import Swipeable from 'react-native-gesture-handler/Swipeable';
import Close from 'react-native-vector-icons/AntDesign';
// import {color, timing} from 'react-native-reanimated';
// import {isTablet} from 'react-native-device-detection';
// import CheckBox from 'react-native-check-box';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { OutletStore } from '../store/outletStore';
// import AsyncImage from '../components/asyncImage';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import API from '../constant/API';
import ApiClient from '../util/ApiClient';
import { CommonStore } from '../store/commonStore';
import { CART_BEER_DOCKET_ACTION } from '../constant/common';

const dummyParking = [
  {
    id: 1,
    name: 'Beer',
    total: 653,
    categoryItems: [
      {
        name: 'Tiger',
        quantity: 301,
      },
      {
        name: 'Heineken',
        quantity: 250,
      },
      {
        name: 'Carlsberg',
        quantity: 102,
      },
    ],
  },
  {
    id: 2,
    name: 'Sake',
    total: 149,
    categoryItems: [
      {
        name: 'Suntory',
        quantity: 123,
      },
      {
        name: 'Caito',
        quantity: 26,
      },
    ],
  },
  {
    id: 3,
    name: 'Soju',
    total: 294,
    categoryItems: [
      {
        name: 'Grape',
        quantity: 94,
      },
      {
        name: 'Peach',
        quantity: 200,
      },
    ],
  },
  {
    id: 4,
    name: 'Cognac',
    total: 275,
    categoryItems: [
      {
        name: 'Hennessy',
        quantity: 173,
      },
      {
        name: 'Salignac',
        quantity: 102,
      },
    ],
  },
];

const dummyUsers = [
  {
    id: 1,
    username: 'JohnDoe',
    items: [
      {
        name: 'Beer',
        categoryItems: {
          name: 'Tiger',
          quantity: 4,
        },
      },
    ],
    outlet: [
      {
        name: 'Mont Kiara',
      },
      {
        name: 'Kuchai Lama',
      },
    ],
    purchaseAt: '2020-11-30T01:12:38+00:00',
    expiredAt: '2021-12-29T01:12:38+00:00',
    waiter: 'Radius Khor',
  },
  {
    id: 2,
    username: 'Hayden',
    items: [
      {
        name: 'Soju',
        categoryItems: {
          name: 'Grape',
          quantity: 3,
        },
      },
    ],
    outlet: [
      {
        name: 'Mont Kiara',
      },
      {
        name: 'Kuchai Lama',
      },
      {
        name: 'Taman Oug',
      },
    ],
    purchaseAt: '2020-11-29T01:12:38+00:00',
    expiredAt: '2021-12-30T01:12:38+00:00',
    waiter: 'Radius Khor',
  },
];

const RedemptionScreen = (props) => {
  const { navigation } = props;

  const linkTo = useLinkTo();

  const {
    height: windowHeight,
    width: windowWidth,
  } = useWindowDimensions();

  const [search, setSearch] = useState('');
  const [parkingType, setParkingType] = useState(false);
  const [renderUserList, setRenderUserList] = useState(false);
  const [renderEdit, setRenderEdit] = useState(false);
  const [editId, setEditId] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [monthList, setMonthList] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [selectDate, setSelectDate] = useState(0);
  const [selectMonth, setSelectMonth] = useState(0);
  const [selectYear, setSelectYear] = useState(0);

  const [refArray, setRefArray] = useState([]);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [redemptionSummaryList, setRedemptionSummaryList] = useState([]);
  const [currUserBeerDocketList, setCurrUserBeerDocketList] = useState([]);

  const [selectedUserBeerDocket, setSelectedUserBeerDocket] = useState({});

  const [outletDropdownList, setOutletDropdownList] = useState([]);
  const [selectedOutletIdList, setSelectedOutletIdList] = useState([]);

  const [showExpiryDateTimePicker, setShowExpiryDateTimePicker] =
    useState(false);
  const [expiryDateTime, setExpiryDateTime] = useState(moment());

  const [showExtendedDateTimePicker, setShowExtendedDateTimePicker] =
    useState(false);
  const [extendedDateTime, setExtendedDateTime] = useState(moment());

  const [outletItemsStrList, setOutletItemsStrList] = useState([]);
  const [outletItemsImageList, setOutletItemsImageList] = useState([]);

  /////////////////////////////////////////////////////////////////

  // extend docket

  const [optionsDropdownList, setOptionsDropdownList] = useState([]);
  const [selectedOptionsId, setSelectedOptionsId] = useState('');
  const [bdRedeemQuantity, setBdRedeemQuantity] = useState(0);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDays, setTotalDays] = useState(0);

  const [customerAvatar, setCustomerAvatar] = useState('');

  const [bdCartItems, setBdCartItems] = useState([]);
  const [cartBeerDocketAction, setCartBeerDocketAction] = useState(
    CART_BEER_DOCKET_ACTION.EXTEND_DOCKET,
  );

  const [extendedDate, setExtendedDate] = useState(new Date());
  const [isExtendedDate, setIsExtendedDate] = useState(false);

  const [isOutside, setIsOutside] = useState(false);

  /////////////////////////////////////////////////////////////////

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  const beerDocketCategories = OutletStore.useState(
    (s) => s.beerDocketCategories,
  );
  const beerDockets = OutletStore.useState((s) => s.beerDockets);
  const beerDocketsDict = OutletStore.useState((s) => s.beerDocketsDict);
  const userBeerDockets = OutletStore.useState((s) => s.userBeerDockets);
  const userOrderBeerDocketUBDIdDict = OutletStore.useState(
    (s) => s.userOrderBeerDocketUBDIdDict,
  );
  const allOutletsItemsSkuDict = OutletStore.useState(
    (s) => s.allOutletsItemsSkuDict,
  );
  const allOutletsCategoriesDict = OutletStore.useState(
    (s) => s.allOutletsCategoriesDict,
  );

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView,
  );

  const setState = () => { };

  //////////////////////////////////////////////////////////////

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);

  useEffect(() => {
    setOutletDropdownList(
      allOutlets.map((item) => ({
        label: item.name,
        value: item.uniqueId,
      })),
    );
  }, [allOutlets]);

  useEffect(() => {
    var selectedOutletIdListTemp = [];

    var totalPriceTemp = 0;
    var totalDaysTemp = 0;

    if (
      selectedUserBeerDocket &&
      beerDocketsDict[selectedUserBeerDocket.beerDocketId]
    ) {
      selectedOutletIdListTemp =
        beerDocketsDict[selectedUserBeerDocket.beerDocketId].bdOutlets;

      // override
      if (selectedUserBeerDocket.bdOutlets) {
        selectedOutletIdListTemp = selectedUserBeerDocket.bdOutlets;
      }

      //////////////////////////////////////////////////////////////////////////////

      // extend docket

      const beerDocket = beerDocketsDict[selectedUserBeerDocket.beerDocketId];

      setOptionsDropdownList(
        beerDocket.bdOptions.map((bdOption) => ({
          label: `RM${bdOption.price
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} for ${bdOption.duration
            } day${bdOption.duration > 1 ? 's' : ''}`,
          value: bdOption.optionsId,
        })),
      );

      if (selectedOptionsId === '' && beerDocket.bdOptions.length > 0) {
        setSelectedOptionsId(beerDocket.bdOptions[0].optionsId);
      }

      for (var i = 0; i < beerDocket.bdOptions.length; i++) {
        if (beerDocket.bdOptions[i].optionsId === selectedOptionsId) {
          if (bdRedeemQuantity > 0) {
            totalPriceTemp = beerDocket.bdOptions[i].price * bdRedeemQuantity;
            totalDaysTemp = beerDocket.bdOptions[i].duration * bdRedeemQuantity;
          } else {
            totalPriceTemp = beerDocket.bdOptions[i].price;
            totalDaysTemp = beerDocket.bdOptions[i].duration;
          }

          break;
        }
      }
    }

    setTotalPrice(totalPriceTemp);
    setTotalDays(totalDaysTemp);

    setSelectedOutletIdList(selectedOutletIdListTemp);
  }, [
    selectedUserBeerDocket,
    beerDocketsDict,
    selectedOptionsId,
    bdRedeemQuantity,
  ]);

  useEffect(() => {
    const outletItemsStrListTemp = [];
    const outletItemsImageListTemp = [];

    if (selectedUserBeerDocket && selectedUserBeerDocket.uniqueId) {
      for (var i = 0; i < selectedUserBeerDocket.bdItems.length; i++) {
        if (
          allOutletsItemsSkuDict[
          selectedUserBeerDocket.bdItems[i].outletItemSku
          ]
        ) {
          outletItemsStrListTemp.push(
            `${allOutletsItemsSkuDict[
              selectedUserBeerDocket.bdItems[i].outletItemSku
            ][0].name
            } (x${selectedUserBeerDocket.bdItems[i].quantity})`,
          );
          outletItemsImageListTemp.push(
            allOutletsItemsSkuDict[
              selectedUserBeerDocket.bdItems[i].outletItemSku
            ][0].image,
          );
        }
      }
    }

    setOutletItemsStrList(outletItemsStrListTemp);
    setOutletItemsImageList(outletItemsImageListTemp);
  }, [selectedUserBeerDocket, allOutletsItemsSkuDict]);

  useEffect(() => {
    var redemptionSummaryCategoryNameDict = {};
    var currUserBeerDocketListTemp = [];

    if (
      Object.keys(allOutletsItemsSkuDict).length > 0 &&
      Object.keys(allOutletsCategoriesDict).length > 0
    ) {
      for (var i = 0; i < userBeerDockets.length; i++) {
        var isRedeemed = true;

        for (var j = 0; j < userBeerDockets[i].bdItems.length; j++) {
          if (userBeerDockets[i].bdItems[j].quantity > 0) {
            isRedeemed = false;
            break;
          }
        }

        if (moment().isBefore(userBeerDockets[i].expiryDate) && !isRedeemed) {
          currUserBeerDocketListTemp.push(userBeerDockets[i]);

          for (var j = 0; j < userBeerDockets[i].bdItems.length; j++) {
            const outletItem =
              allOutletsItemsSkuDict[
              userBeerDockets[i].bdItems[j].outletItemSku
              ][0];

            const outletCategory =
              allOutletsCategoriesDict[outletItem.categoryId];

            if (outletCategory) {
              if (redemptionSummaryCategoryNameDict[outletCategory.name]) {
                var redemptionSummary =
                  redemptionSummaryCategoryNameDict[outletCategory.name];

                redemptionSummary.quantity +=
                  userBeerDockets[i].bdItems[j].quantity;

                var isExisted = false;
                for (var k = 0; k < redemptionSummary.items.length; k++) {
                  if (redemptionSummary.items[k].sku === outletItem.sku) {
                    isExisted = true;
                    redemptionSummary.items[k].quantity +=
                      userBeerDockets[i].bdItems[j].quantity;
                  }
                }

                if (!isExisted) {
                  redemptionSummary.items.push({
                    uniqueId: outletItem.uniqueId,
                    sku: outletItem.sku,
                    name: outletItem.name,
                    quantity: userBeerDockets[i].bdItems[j].quantity,
                  });
                }

                redemptionSummaryCategoryNameDict[outletCategory.name] =
                  redemptionSummary;
              } else {
                var redemptionSummary = {
                  categoryId: outletCategory.uniqueId,
                  name: outletCategory.name,
                  quantity: userBeerDockets[i].bdItems[j].quantity,
                  items: [
                    {
                      uniqueId: outletItem.uniqueId,
                      sku: outletItem.sku,
                      name: outletItem.name,
                      quantity: userBeerDockets[i].bdItems[j].quantity,
                    },
                  ],
                };

                redemptionSummaryCategoryNameDict[outletCategory.name] =
                  redemptionSummary;
              }
            }
          }
        }
      }
    }

    setRedemptionSummaryList(
      Object.entries(redemptionSummaryCategoryNameDict).map(([key, value]) => ({
        ...value,
      })),
    );

    setCurrUserBeerDocketList(currUserBeerDocketListTemp);

    // var redemptionSummaryListTemp = [];

    // for (var i = 0; i < beerDocketCategories.length; i++) {
    //     var redemptionSummary = {
    //         name: beerDocketCategories[i].name,
    //         quantity: 0,
    //         items: [
    //             {
    //                 name: beerDocketCategories[i].name,
    //                 quantity: 0,
    //             }
    //         ]
    //     };

    //     for (var j = 0; j < userBeerDockets.length; j++) {
    //         if (moment().isBefore(userBeerDockets[j].expiryDate)) {
    //             var beerDocketId = userBeerDockets[j].beerDocketId;

    //             if (beerDocketsDict[beerDocketId] &&
    //                 beerDocketsDict[beerDocketId].bdCategories &&
    //                 beerDocketsDict[beerDocketId].bdCategories[0] === beerDocketCategories[i].uniqueId) {
    //                 var quantitySubtotal = 0;

    //                 // if (userBeerDockets[j].redeemHistory) {
    //                 //     for (var k = 0; k < userBeerDockets[j].redeemHistory.length; k++) {
    //                 //         quantitySubtotal += userBeerDockets[j].redeemHistory[k].redeemQuantity;
    //                 //     }
    //                 // }

    //                 quantitySubtotal += userBeerDockets[j].quantity;

    //                 redemptionSummary.quantity += quantitySubtotal;
    //                 redemptionSummary.items[0].quantity += quantitySubtotal;
    //             }
    //         }
    //     }

    //     redemptionSummaryListTemp.push(redemptionSummary);
    // }

    // setRedemptionSummaryList(redemptionSummaryListTemp);
  }, [userBeerDockets, allOutletsItemsSkuDict, allOutletsCategoriesDict]);

  //////////////////////////////////////////////////////////////

  // navigation.dangerouslyGetParent().setOptions({
  //     tabBarVisible: false
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            // bottom: -2,
            bottom: switchMerchant ? '2%' : 0,
          },
          Dimensions.get('screen').width >= 768 && switchMerchant
            ? { right: Dimensions.get('screen').width * 0.1 }
            : {},
          Dimensions.get('screen').width <= 768
            ? { right: Dimensions.get('screen').width * 0.12 }
            : {},
        ]}>
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Active Docket
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: 'white',
            width: 0.5,
            height: Dimensions.get('screen').height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}></View>
        <TouchableOpacity
          onPress={() => navigation.navigate('Setting')}
          style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get('screen').height * 0.05,
              height: Dimensions.get('screen').height * 0.05,
              borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}>
            <Image
              style={{
                width: Dimensions.get('screen').height * 0.035,
                height: Dimensions.get('screen').height * 0.035,
                alignSelf: 'center',
              }}
              source={require('../assets/image/profile-pic.jpg')}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // function here

  const proceedToCartBeerDocket = async () => {
    if (bdRedeemQuantity > 0) {
      // proceed to cart screen (now to a a custom cart screen)

      setExtendedDate(
        moment(selectedUserBeerDocket.expiryDate).add(totalDays, 'day'),
      );
      setIsExtendedDate(true);

      setBdCartItems([
        {
          beerDocketId: selectedUserBeerDocket.beerDocketId,
          userBeerDocketId: selectedUserBeerDocket.uniqueId
            ? selectedUserBeerDocket.uniqueId
            : '',
          extendQuantity: bdRedeemQuantity,
          extendOptionsId: selectedOptionsId,
          totalPrice: totalPrice,
          totalDays: totalDays,

          lastExpiryDate: selectedUserBeerDocket.expiryDate, // last tracked expiry date

          remarks: '',
          cartItemDate: Date.now(),
          image: outletItemsImageList[0],
          name: outletItemsStrList.join(', '),
          itemName: outletItemsStrList.join(', '),
          // price: singlePrice,
          price: totalPrice,
        },
      ]);

      setCartBeerDocketAction(CART_BEER_DOCKET_ACTION.EXTEND_DOCKET);

      setShowEdit(false);
    } else {
      alert('Error: Quantity must be more than 0');
    }
  };

  const updateUserBeerDocketExpiryDateByMerchant = async () => {
    var body = {
      userBeerDocketId: selectedUserBeerDocket.uniqueId,
      expiryDate: moment(expiryDateTime).valueOf(),
      extendedDate: moment(extendedDateTime).valueOf(),

      bdOutlets: selectedOutletIdList,

      bdCartItems: bdCartItems,
      cartBeerDocketAction: cartBeerDocketAction,
    };

    console.log(body);

    ApiClient.POST(
      API.updateUserBeerDocketExpiryDateByMerchant,
      body,
      false,
    ).then((result) => {
      if (result && result.status === 'success') {
        if (window.confirm('Success: User beer docket has been updated',)) {
          setRenderUserList(true);
          setRenderEdit(false);
          setIsOutside(false);
          setShowEdit(false);
        }

        // Alert.alert(
        //   'Success',
        //   'User beer docket has been updated',
        //   [
        //     {
        //       text: 'OK',
        //       onPress: () => {
        //         setRenderUserList(true);
        //         setRenderEdit(false);
        //         setIsOutside(false);
        //         setShowEdit(false);
        //       },
        //     },
        //   ],
        //   {cancelable: false},
        // );
      }
    });
  };

  // const handleChoosePhoto = () => {
  //   const imagePickerOptions = {
  //     mediaType: 'photo',
  //     quality: 0.5,
  //     includeBase64: false,
  //   };

  //   launchImageLibrary(imagePickerOptions, (response) => {
  //     if (response.didCancel) {
  //     } else if (response.error) {
  //       Alert.alert(response.error.toString());
  //     } else {
  //       // setState({ image: response.uri });
  //       setCustomerAvatar(response.uri);
  //       setImage(response.uri);
  //       setImageType(response.uri.slice(response.uri.lastIndexOf('.')));

  //       setIsImageChanged(true);
  //     }
  //   });
  // };

  const generateDateOption = async () => {
    const months = [
      { label: 'January', value: 1 },
      { label: 'February', value: 2 },
      { label: 'March', value: 3 },
      { label: 'April', value: 4 },
      { label: 'May', value: 5 },
      { label: 'June', value: 6 },
      { label: 'July', value: 7 },
      { label: 'August', value: 8 },
      { label: 'September', value: 9 },
      { label: 'October', value: 10 },
      { label: 'November', value: 11 },
      { label: 'December', value: 12 },
    ];

    const days = [];
    const years = [];

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = selectMonth == 0 ? today.getMonth() : selectMonth;
    const yearMonthString = moment(
      currentYear.toString() + '-' + (currentMonth + 1).toString(),
      'YYYY-MM',
    ).daysInMonth();
    var i;
    for (i = 0; i < yearMonthString; i++) {
      days.push({ label: (i + 1).toString(), value: i + 1 });
    }
    var j;
    for (j = 0; j < 5; j++) {
      years.push({ label: (currentYear + j).toString(), value: currentYear + j });
    }

    await setState({ monthList: months, dayList: days, yearList: years });
  };
  // function end
  const renderAlcoholCategory = ({ item }) => {
    console.log('category item');
    console.log(item);

    return (
      <View
        style={{
          width: windowWidth * 0.165,
          height: windowHeight * 0.43,
          marginRight: 20,
          borderRadius: 17,
          justifyContent: 'space-between',
        }}>
        <View
          style={[
            {
              width: '100%',
              height: '33%',
              padding: 15,
              backgroundColor: Colors.lightPrimary,
              borderRadius: 17,
              justifyContent: 'space-around',
            },
            switchMerchant
              ? {
                borderRadius: 10,
              }
              : {},
          ]}>
          <Text
            style={[
              { fontFamily: 'NunitoSans-Regular', color: 'black' },
              switchMerchant
                ? {
                  fontFamily: 'NunitoSans-Regular',
                  fontSize: 15,
                }
                : {},
            ]}
            numberOfLines={1}>
            {item.name}
          </Text>
          <View style={{ paddingVertical: switchMerchant ? 10 : 0 }} />
          <Text
            style={[
              { fontFamily: 'NunitoSans-Bold', fontSize: 40 },
              switchMerchant
                ? {
                  fontSize: 20,
                }
                : {},
            ]}>
            {item.quantity}
          </Text>
        </View>
        <View
          style={[
            {
              width: '100%',
              height: '63%',
              padding: 15,
              backgroundColor: Colors.lightGrey,
              borderRadius: 17,
            },
            switchMerchant
              ? {
                borderRadius: 10,
              }
              : {},
          ]}>
          <View
            style={{
              width: '100%',
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: Styles.fieldtTxtColor,
              paddingBottom: 8,
            }}>
            <Text
              style={[
                { fontFamily: 'NunitoSans-Regular' },
                switchMerchant
                  ? {
                    fontSize: 15,
                  }
                  : {},
              ]}>
              {item.name}
            </Text>
          </View>
          <FlatList
            showsVerticalScrollIndicator={false}
            nestedScrollEnabled={true}
            data={item.items}
            keyExtractor={(item, index) => String(index)}
            renderItem={renderCategoryItems}
          />
        </View>
      </View>
    );
  };

  const renderCategoryItems = ({ item }) => {
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginVertical: 5,
          width: '95%',
        }}>
        <Text
          style={[
            { fontFamily: 'NunitoSans-Bold', fontSize: 16, width: '92%' },
            switchMerchant
              ? {
                fontSize: 10,
              }
              : {},
          ]}
          numberOfLines={100}>
          {item.name}
        </Text>
        <Text
          style={[
            { fontFamily: 'NunitoSans-Regular', fontSize: 16 },
            switchMerchant
              ? {
                fontSize: 10,
              }
              : {},
          ]}>
          {item.quantity}
        </Text>
      </View>
    );
  };

  const rightAction = (item, index) => {
    console.log('item', item);
    return (
      <View style={styles.insideSwipe}>
        <TouchableOpacity
          onPress={() => {
            // setState({showEdit: true, editId: item});
            // for (const ref of refArray) {
            //   if (refArray.indexOf(ref) != index && ref) {
            //     ref.close();
            //   }
            // }
            setShowEdit(true);
            setIsOutside(true);
          }}>
          <View
            style={[
              styles.swipeButton,
              { backgroundColor: Colors.primaryColor },
              switchMerchant
                ? { height: windowHeight * 0.285, width: 120 }
                : {},
            ]}>
            <Icon
              name={'alarm'}
              size={switchMerchant ? 30 : 50}
              color={'white'}
            />
            <Text
              style={[
                styles.swipeFont,
                { color: 'white' },
                switchMerchant
                  ? {
                    fontSize: 10,
                  }
                  : {},
              ]}>
              Extend Expire Date
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const renderList = ({ item, index }) => {
    const beerDocket = beerDocketsDict[item.beerDocketId];

    const bdOutletItemsStrList = [];
    const bdOutletItemsImageList = [];

    if (beerDocket && beerDocket.uniqueId) {
      for (var i = 0; i < beerDocket.bdItems.length; i++) {
        if (allOutletsItemsSkuDict[beerDocket.bdItems[i].outletItemSku]) {
          bdOutletItemsStrList.push(
            `${allOutletsItemsSkuDict[beerDocket.bdItems[i].outletItemSku][0]
              .name
            } (x${beerDocket.bdItems[i].quantity})`,
          );
          bdOutletItemsImageList.push(
            allOutletsItemsSkuDict[beerDocket.bdItems[i].outletItemSku][0]
              .image,
          );
        }
      }
    }

    var bdOutletNames = beerDocket.bdOutletNames;
    if (item.bdOutlets) {
      bdOutletNames = [];

      for (var i = 0; i < beerDocket.bdOutlets.length; i++) {
        var isFound = false;

        for (var j = 0; j < item.bdOutlets.length; j++) {
          if (item.bdOutlets[j] === beerDocket.bdOutlets[i]) {
            isFound = true;
            break;
          }
        }

        if (isFound) {
          bdOutletNames.push(beerDocket.bdOutletNames[i]);
        }
      }
    }

    return (
      // <Swipeable
      //   ref={(ref) => {
      //     if (ref && !refArray.includes(ref)) {
      //       refArray[index] = ref;
      //     }
      //   }}
      //   renderRightActions={() => rightAction(item, index)}>
      <View
        style={[
          {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#FFFFFF',
            height: windowHeight * 0.185,
            marginBottom: 20,
            borderRadius: 15,
            overflow: 'hidden',
          },
          switchMerchant
            ? {
              height: windowHeight * 0.285,
              borderRadius: 10,
            }
            : {},
        ]}>
        <View
          style={{
            backgroundColor: Colors.primaryColor,
            width: 13,
            height: '100%',
            borderRadius: 18,
          }}
        />
        <View style={{ flex: 1, paddingVertical: 10 }}>
          <View
            style={{
              flexDirection: 'row',
              flex: 1.2,
              alignItems: 'center',
              paddingHorizontal: 20,
              width: '100%',
              justifyContent: 'space-between',
            }}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Text
                style={[
                  {
                    fontFamily: 'NunitoSans-Bold',
                    fontSize: 20,
                    width: windowWidth * 0.3,
                  },
                  switchMerchant
                    ? {
                      fontSize: 15,
                    }
                    : {},
                ]}>
                User Name: {item.userName}
              </Text>
              {/* <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 20, marginLeft: 15 }}>ID: #{item.id}</Text> */}
            </View>
            <View
              style={{
                backgroundColor: Colors.secondaryColor,
                justifyContent: 'center',
                alignItems: 'center',
                paddingHorizontal: 20,
                borderRadius: 4,
              }}>
              <Text
                style={[
                  {
                    fontFamily: 'NunitoSans-Bold',
                    fontSize: 15,
                    marginRight: 6,
                    color: Colors.whiteColor,
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                Expired {moment(item.expiryDate).fromNow()}
              </Text>
            </View>
          </View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-around',
              flex: 2,
              paddingVertical: 10,
            }}>
            <View
              style={{
                flex: 1.6,
                paddingHorizontal: 20,
                borderRightWidth: StyleSheet.hairlineWidth,
                borderLeftColor: Colors.tabGrey,
              }}>
              <Text
                style={[
                  { fontFamily: 'NunitoSans-Bold', fontSize: 16 },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                Items
              </Text>
              <ScrollView
                showsVerticalScrollIndicator={false}
                nestedScrollEnabled={true}>
                {beerDocket &&
                  bdOutletItemsStrList.map((categoryName) => {
                    return (
                      <View style={{ flexDirection: 'row' }}>
                        {/* <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: 15, marginRight: 6 }}>{categoryName}</Text> */}
                        <Text
                          style={[
                            { fontFamily: 'NunitoSans-Regular', fontSize: 15 },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          {categoryName}
                        </Text>
                      </View>
                    );
                  })}
              </ScrollView>
            </View>
            {/* <View style={{ flex: 0.8, paddingHorizontal: 10, borderRightWidth: StyleSheet.hairlineWidth, borderLeftColor: Colors.tabGrey }}>
                                <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 17 }}>Quantity:</Text>
                                <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: 15, marginRight: 6 }}>{item.quantity} mugs</Text>
                            </View> */}
            <View
              style={{
                flex: 1.7,
                paddingHorizontal: 10,
                borderRightWidth: StyleSheet.hairlineWidth,
                borderLeftColor: Colors.tabGrey,
              }}>
              <Text
                style={[
                  { fontFamily: 'NunitoSans-Bold', fontSize: 16 },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                Outlet
              </Text>
              <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  nestedScrollEnabled={true}>
                  {bdOutletNames.map((outletName, index) => {
                    return (
                      <View
                        style={{
                          backgroundColor: Colors.tabGrey,
                          paddingHorizontal: 8,
                          marginBottom: 2,
                          borderRadius: 2,
                          alignItems: 'center',
                          justifyContent: 'center',
                          alignSelf: 'baseline',
                        }}>
                        <Text
                          style={[
                            {
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 13,
                              marginRight: 6,
                              color: Colors.whiteColor,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          {outletName}
                        </Text>
                      </View>
                    );
                  })}
                </ScrollView>
              </View>
            </View>
            <View
              style={{
                flex: 1.5,
                paddingHorizontal: 10,
                borderRightWidth: StyleSheet.hairlineWidth,
                borderLeftColor: Colors.tabGrey,
              }}>
              <Text
                style={[
                  { fontFamily: 'NunitoSans-Bold', fontSize: 16 },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                Expired Date
              </Text>
              <Text
                style={[
                  { fontFamily: 'NunitoSans-Regular', fontSize: 15 },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {moment(item.expiryDate).format('DD MMM YYYY')}
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                paddingHorizontal: 10,
                borderRightWidth: StyleSheet.hairlineWidth,
                borderLeftColor: Colors.tabGrey,
              }}>
              <Text
                style={[
                  { fontFamily: 'NunitoSans-Bold', fontSize: 16 },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                Waiter
              </Text>
              <Text
                style={[
                  { fontFamily: 'NunitoSans-Regular', fontSize: 15 },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {item.uniqueId &&
                  userOrderBeerDocketUBDIdDict[item.uniqueId] &&
                  userOrderBeerDocketUBDIdDict[item.uniqueId].waiterName
                  ? userOrderBeerDocketUBDIdDict[item.uniqueId].waiterName
                  : 'N/A'}
              </Text>
            </View>
            <TouchableOpacity
              style={{
                flex: 0.5,
                paddingHorizontal: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={() => {
                // setState({ renderEdit: true, renderUserList: false, editId: item })

                setSelectedUserBeerDocket(item);
                setExpiryDateTime(item.expiryDate);

                setRenderEdit(true);
                setRenderUserList(false);
              }}>
              <Edit
                width={switchMerchant ? 15 : 25}
                height={switchMerchant ? 15 : 25}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
      // </Swipeable>
    );
  };

  // const sortingOrders = (param) => {
  //   if (param.value == 1) {
  //     //orderid
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = userList.sort((a, b) => a.id - b.id);
  //       setState({userList: order});
  //     } else {
  //       const order = userList.sort((a, b) => b.id - a.id);

  //       setState({userList: order});
  //     }
  //   }
  //   if (param.value == 2) {
  //     //purchase date time
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = userList.sort(
  //         (a, b) => moment(a.purchaseAt) - moment(b.purchaseAt),
  //       );
  //       setState({userList: order});
  //     } else {
  //       const order = userList.sort(
  //         (a, b) => moment(b.purchaseAt) - moment(a.purchaseAt),
  //       );
  //       setState({userList: order});
  //     }
  //   }
  //   if (param.value == 3) {
  //     //Name
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = userList.sort((a, b) =>
  //         b.username.localeCompare(a.username),
  //       );
  //       setState({userList: order});
  //     } else {
  //       const order = userList.sort((a, b) =>
  //         a.username.localeCompare(b.username),
  //       );
  //       setState({userList: order});
  //     }
  //   }
  //   // if (param.value == 4) { //Total
  //   //     if (lastSort != null ? lastSort.value == param.value : null) {
  //   //         const order = userList.sort((a, b) => a.finalPrice - b.finalPrice)
  //   //         setState({ userList: order })

  //   //     } else {
  //   //         const order = userList.sort((a, b) => b.finalPrice - a.finalPrice)
  //   //         setState({ useruserList: orderList })
  //   //     }
  //   // }
  //   //[{ label: 'Items', value: 4 }, { label: 'Quantity', value: 5 }, { label: 'Outlet', value: 6 }, { label: 'Expired Date', value: 7 }, { label: 'Waiter', value: 8 }]
  //   if (param.value == 4) {
  //     //Item Name
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = userList.sort((a, b) =>
  //         b.items[0].name.localeCompare(a.items[0].name),
  //       );
  //       setState({userList: order});
  //     } else {
  //       const order = userList.sort((a, b) =>
  //         a.items[0].name.localeCompare(b.items[0].name),
  //       );
  //       setState({userList: order});
  //     }
  //   }
  //   if (param.value == 5) {
  //     //quantity
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = userList.sort(
  //         (a, b) =>
  //           a.items[0].categoryItems.quantity -
  //           b.items[0].categoryItems.quantity,
  //       );
  //       setState({userList: order});
  //     } else {
  //       const order = userList.sort(
  //         (a, b) =>
  //           a.items[0].categoryItems.quantity -
  //           b.items[0].categoryItems.quantity,
  //       );
  //       setState({userList: order});
  //     }
  //   }
  //   if (param.value == 6) {
  //     //outlet
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = userList.sort((a, b) =>
  //         b.outlet[0].name.localeCompare(a.outlet[0].name),
  //       );
  //       setState({userList: order});
  //     } else {
  //       const order = userList.sort((a, b) =>
  //         a.outlet[0].name.localeCompare(b.outlet[0].name),
  //       );
  //       setState({userList: order});
  //     }
  //   }
  //   if (param.value == 7) {
  //     //expired date
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = userList.sort(
  //         (a, b) => moment(a.expiredAt) - moment(b.expiredAt),
  //       );
  //       setState({userList: order});
  //     } else {
  //       const order = userList.sort(
  //         (a, b) => moment(b.expiredAt) - moment(a.expiredAt),
  //       );
  //       setState({userList: order});
  //     }
  //   }
  //   if (param.value == 8) {
  //     //Waiter Name
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = userList.sort((a, b) => b.waiter.localeCompare(a.waiter));
  //       setState({userList: order});
  //     } else {
  //       const order = userList.sort((a, b) => a.waiter.localeCompare(b.waiter));
  //       setState({userList: order});
  //     }
  //   }
  //   setState({lastSort: param});
  // };

  const extendExpireDate = () => {
    const newExtend = new Date(selectYear, selectMonth - 1, selectDate);
    const today = new Date();
    const dayInMonth = moment(
      selectYear.toString() + '-' + selectMonth.toString(),
      'YYYY-MM',
    ).daysInMonth();
    console.log('new extend vs today', newExtend > today);
    console.log('days in omonth', selectDate > dayInMonth);
    if (newExtend < today || selectDate > dayInMonth) {
      alert('Invalid date: The input date is not a valid date');
    } else {
      var newEditId = editId;
      newEditId.expiredAt = newExtend;
      setState({ editId: newEditId, showEdit: false });
      alert('Success: The expiration date has been extended');
    }
  };

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar
          navigation={navigation}
          selectedTab={4}
          expandRedemption={true}
        />
      </View>

      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showEdit}
            transparent={true}
            animationType="slide">
            <View
              style={[
                styles.modalContainer,
                { backgroundColor: Colors.modalBgColor },
              ]}>
              <View
                style={[
                  styles.sectionModal,
                  switchMerchant
                    ? {
                      width: windowWidth * 0.4,
                      borderRadius: 5,
                    }
                    : {},
                ]}>
                <TouchableOpacity
                  style={{
                    position: 'absolute',
                    right: windowWidth * 0.02,
                    top: windowWidth * 0.02,
                    elevation: 100000,
                    zIndex: 100000,
                  }}
                  onPress={() => {
                    // setState({ showEdit: false });
                    setShowEdit(false);
                  }}>
                  <Close
                    name="closecircle"
                    size={switchMerchant ? 15 : 25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View
                  style={{
                    //    alignItems: 'center',
                    top: '15%',
                    //    position: 'absolute',
                    justifyContent: 'space-around',
                  }}>
                  <View
                    style={[
                      { marginBottom: 20 },
                      switchMerchant
                        ? {
                          marginBottom: 0,
                        }
                        : {},
                    ]}>
                    <Text
                      style={[
                        {
                          fontFamily: 'NunitoSans-Bold',
                          textAlign: 'center',
                          fontSize: 27,
                        },
                        switchMerchant
                          ? {
                            fontSize: 16,
                          }
                          : {},
                      ]}>
                      Extend Expiration Date
                    </Text>
                    <Text
                      style={[
                        {
                          fontFamily: 'NunitoSans-Bold',
                          textAlign: 'center',
                          color: Colors.descriptionColor,
                          fontSize: 20,
                        },
                        switchMerchant
                          ? {
                            fontSize: 13,
                          }
                          : {},
                      ]}>
                      Set your extension date
                    </Text>
                  </View>
                </View>
                {/* <View style={{ flexDirection: 'row', height: 50, justifyContent: 'space-evenly', alignContent: 'center', alignItems: 'center', marginBottom: 10, marginTop: 20, }}>
                            <DropDownPicker
                                arrowColor={Colors.darkBgColor}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                                style={{ width: windowWidth * 0.1, borderWidth: 0, backgroundColor: Colors.fieldtBgColor, }}
                                placeholderStyle={{ color: Colors.darkBgColor }}
                                items={monthList}
                                placeholder={"Month"}
                                onChangeItem={selectedSort => {
                                    setState({ selectMonth: selectedSort.value })
                                    generateDateOption()

                                }
                                }
                            />
                            <DropDownPicker
                                arrowColor={Colors.darkBgColor}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                                style={{ width: windowWidth * 0.1, borderWidth: 0, backgroundColor: Colors.fieldtBgColor, }}
                                placeholderStyle={{ color: 'black' }}
                                items={dayList}
                                placeholder={"Date"}
                                onChangeItem={selectedSort => {
                                    setState({ selectDate: selectedSort.value })

                                }
                                }
                            />
                            <DropDownPicker
                                arrowColor={Colors.darkBgColor}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                                style={{ width: windowWidth * 0.1, borderWidth: 0, backgroundColor: Colors.fieldtBgColor, }}
                                placeholderStyle={{ color: 'black' }}
                                items={yearList}
                                placeholder={"Year"}
                                onChangeItem={selectedSort => {
                                    setState({ selectYear: selectedSort.value })
                                    generateDateOption()
                                }
                                }
                            />
                        </View> */}

                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 20,
                    width: '80%',
                    top: '17%',
                    alignSelf: 'center',
                  }}>
                  {optionsDropdownList.find(
                    (o) => o.value === selectedOptionsId,
                  ) ? (
                    <Picker
                      selectedValue={selectedOptionsId}
                      onValueChange={(item) => {
                        setSelectedOptionsId(item.value);
                      }}
                      style={[
                        {
                          width: '100%',
                          height: 40,

                          borderColor: Colors.descriptionColor,

                          shadowColor: '#000',
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                        },
                      ]}
                    >
                      {optionsDropdownList.map((value, index) => {
                        return (
                          <Picker.Item
                            key={index}
                            label={value.label}
                            value={value.value}
                          />
                        );
                      })}
                    </Picker>
                  ) : (
                    <></>
                  )}
                </View>

                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 10,
                    top: '17%',
                    zIndex: -1,
                  }}>
                  <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity
                      onPress={() => {
                        setBdRedeemQuantity(
                          bdRedeemQuantity - 1 >= 0 ? bdRedeemQuantity - 1 : 0,
                        );
                      }}>
                      <View
                        style={[
                          styles.addBtn,
                          { backgroundColor: Colors.descriptionColor },
                          switchMerchant
                            ? {
                              height: 35,
                              width: 35,
                            }
                            : {},
                        ]}>
                        <Text
                          style={[
                            {
                              fontSize: 30,
                              fontWeight: '500',
                              color: Colors.whiteColor,
                            },
                            switchMerchant
                              ? {
                                height: 30,
                                fontSize: 20,
                              }
                              : {},
                          ]}>
                          -
                        </Text>
                      </View>
                    </TouchableOpacity>
                    <View
                      style={[
                        styles.addBtn,
                        {
                          backgroundColor: Colors.whiteColor,
                          borderWidth: StyleSheet.hairlineWidth,
                          borderColor: Colors.descriptionColor,
                          borderWidth: 1.5,

                          width: 50,
                        },
                        switchMerchant
                          ? {
                            height: 35,
                            width: 35,
                          }
                          : {},
                      ]}>
                      <Text
                        style={[
                          {
                            fontSize: 25,
                            fontWeight: 'bold',
                            color: Colors.primaryColor,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}>
                        {/* {quantityFunc()} */}
                        {bdRedeemQuantity}
                      </Text>
                    </View>
                    <TouchableOpacity
                      onPress={() => {
                        setBdRedeemQuantity(bdRedeemQuantity + 1);
                      }}>
                      <View
                        style={[
                          styles.addBtn,
                          {
                            backgroundColor: Colors.primaryColor,
                            left: -1,
                          },
                          switchMerchant
                            ? {
                              height: 35,
                              width: 35,
                            }
                            : {},
                        ]}>
                        <Text
                          style={[
                            {
                              fontSize: 30,
                              fontWeight: '500',
                              color: Colors.whiteColor,
                            },
                            switchMerchant
                              ? {
                                height: 30,
                                fontSize: 20,
                              }
                              : {},
                          ]}>
                          +
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 10,
                    top: '17%',
                    zIndex: -1,
                  }}>
                  <Text
                    style={[
                      {
                        fontWeight: 'bold',
                        fontSize: 15,
                        color: Colors.descriptionColor,
                      },
                      switchMerchant
                        ? {
                          fontSize: 10,
                        }
                        : {},
                    ]}>
                    {/* RM20.00 per day */}
                    {`RM${totalPrice
                      .toFixed(2)
                      .replace(
                        /(\d)(?=(\d{3})+(?!\d))/g,
                        '$1,',
                      )} for ${totalDays} day${totalDays > 1 ? 's' : ''}`}
                  </Text>
                </View>

                <View
                  style={[
                    {
                      flexDirection: 'row',
                      height: 60,
                      alignContent: 'center',
                      alignItems: 'center',
                      marginTop: '18.5%',
                      borderTopWidth: StyleSheet.hairlineWidth,
                      borderTopColor: Colors.descriptionColor,
                    },
                  ]}>
                  <View
                    style={[
                      {
                        flex: 1,
                        alignContent: 'center',
                        justifyContent: 'center',
                        height: 60,
                        borderRightWidth: StyleSheet.hairlineWidth,
                        borderRightColor: Colors.descriptionColor,
                      },
                      switchMerchant
                        ? {
                          height: 35,
                        }
                        : {},
                    ]}>
                    <TouchableOpacity
                      onPress={() => {
                        if (isOutside) {
                          updateUserBeerDocketExpiryDateByMerchant();
                        } else {
                          proceedToCartBeerDocket();
                        }

                      }}>
                      <Text
                        style={[
                          {
                            fontSize: 27,
                            fontFamily: 'NunitoSans-Regular',
                            color: Colors.primaryColor,
                            textAlign: 'center'
                          },
                        ]}>
                        Confirm
                      </Text>
                    </TouchableOpacity>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignContent: 'center',
                      justifyContent: 'center',
                      height: 70,
                    }}>
                    <TouchableOpacity
                      onPress={() => {
                        // setState({ showEdit: false });
                        setShowEdit(false);
                      }}>
                      <Text
                        style={[
                          {
                            fontSize: 27,
                            fontFamily: 'NunitoSans-Regular',
                            color: 'red',
                            textAlign: 'center'
                          },
                        ]}>
                        Cancel
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <DateTimePickerModal
            minimumDate={selectedUserBeerDocket.expiryDate}
            isVisible={showExpiryDateTimePicker}
            mode={'date'}
            onConfirm={(text) => {
              setExpiryDateTime(moment(text));
              setShowExpiryDateTimePicker(false);
            }}
            onCancel={() => {
              setShowExpiryDateTimePicker(false);
            }}
          />

          <DateTimePickerModal
            minimumDate={selectedUserBeerDocket.extendedDate}
            isVisible={showExtendedDateTimePicker}
            mode={'date'}
            onConfirm={(text) => {
              setExtendedDateTime(moment(text));
              setShowExtendedDateTimePicker(false);
            }}
            onCancel={() => {
              setShowExtendedDateTimePicker(false);
            }}
          />

          <View
            style={[
              {
                justifyContent: 'space-between',
                width: windowWidth * 0.9,
                flex: 1,
                margin: 10,
                marginHorizontal: switchMerchant ? 0 : 10,
                paddingHorizontal: 15,
                paddingTop: 10,
                //backgroundColor: 'red',
              },
              switchMerchant
                ? {
                  paddingHorizontal: 30,
                  alignSelf: 'center',
                }
                : {},
            ]}>
            {renderUserList || search !== '' ? (
              <View>
                <TouchableOpacity
                  style={{
                    marginBottom: 20,
                    flexDirection: 'row',
                    alignContent: 'center',
                    alignItems: 'center',
                    marginLeft: -windowWidth * 0.005,
                  }}
                  onPress={() => {
                    // setState({ renderUserList: false })
                    setRenderUserList(false);
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      paddingHorizontal: 0,
                      alignContent: 'center',
                      alignItems: 'center',
                    }}>
                    <View style={{ justifyContent: 'center' }}>
                      <Feather
                        name="chevron-left"
                        size={switchMerchant ? 20 : 30}
                        color={Colors.primaryColor}
                      />
                    </View>
                    <Text
                      style={[
                        {
                          fontFamily: 'Nunitosans-Bold',
                          color: Colors.primaryColor,
                          fontSize: switchMerchant ? 14 : 17,
                          marginBottom: Platform.OS === 'ios' ? 0 : 1,
                        },
                        switchMerchant
                          ? {
                            fontSize: 14,
                          }
                          : {},
                      ]}>
                      Back
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            ) : null}

            {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30 }}>

                    <View style={{ flexDirection: 'row', paddingHorizontal: 10, width: 200, height: 40, borderRadius: 5, elevation: 10, backgroundColor: Colors.whiteColor, alignItems: 'center' }}>
                        <Magnify height={20} width={20} />
                        <TextInput
                            style={{ paddingLeft: 20 }}
                            onChangeText={text => setState({ search: text, renderUserList: text !== "" ? true : false })}
                            placeholder="Search Customer"
                            placeholderTextColor={Colors.fieldtTxtColor} />

                    </View>

                    <View style={{ flexDirection: 'row' }}>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            borderRadius: 5,
                            height: 40,
                            backgroundColor: Colors.whiteColor,
                            width: 220,
                            marginRight: 30
                        }}>
                            <Text style={{ fontSize: 18, borderRightWidth: StyleSheet.hairlineWidth, paddingRight: 10, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>Sort By</Text>
                            <DropDownPicker
                                arrowColor={Colors.primaryColor}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                                style={{ width: 145, borderWidth: 0, borderRadius: 5, paddingVertical: 0 }}
                                placeholderStyle={{ color: 'black' }}
                                itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                                items={[{ label: 'Order ID', value: 1 }, { label: 'Purchase Date', value: 2 }, { label: 'Name', value: 3 },
                                    //{ label: 'Total', value: 4 }
                                ]}
                                dropDownStyle={{ width: 145, borderRadius: 10 }}
                                placeholder={"Order ID"}
                                onChangeItem={selectedSort => {
                                    setState({ sort: selectedSort })
                                    sortingOrders(selectedSort);

                                }
                                }
                            />
                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10,
                            borderRadius: 5,
                            height: 40,
                            backgroundColor: Colors.whiteColor,
                            width: 210,
                        }}>
                            <Text style={{ fontSize: 18, borderRightWidth: StyleSheet.hairlineWidth, paddingRight: 10, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>Filter</Text>
                            <DropDownPicker
                                arrowColor={Colors.primaryColor}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                                style={{ width: 145, borderWidth: 0, borderRadius: 5, paddingVertical: 0 }}
                                dropDownStyle={{ width: 145, borderRadius: 10, }}
                                placeholderStyle={{ color: 'black' }}
                                itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                                items={[{ label: 'Items', value: 4 }, { label: 'Quantity', value: 5 }, { label: 'Outlet', value: 6 }, { label: 'Expired Date', value: 7 }, { label: 'Waiter', value: 8 }]}
                                placeholder={"Items"}
                                onChangeItem={selectedSort => {
                                    setState({ sort: selectedSort })
                                    sortingOrders(selectedSort);

                                }
                                }
                            />
                        </View>
                    </View>
                </View> */}

            {renderUserList || search !== '' ? (
              <View style={{ flex: 1, zIndex: -100 }}>
                <FlatList
                  showsVerticalScrollIndicator={false}
                  nestedScrollEnabled={true}
                  // data={search === "" ? userList : userList.filter(item => item.username.toLowerCase().match(search.toLowerCase()))}
                  data={userBeerDockets}
                  keyExtractor={(item, index) => String(index)}
                  renderItem={renderList}
                />
              </View>
            ) : renderEdit == true ? (
              <View
                style={{
                  justifyContent: 'space-between',
                  zIndex: -100,
                }}>
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    //alignContent: 'center',
                    //alignItems: 'center',
                    alignSelf: 'baseline',
                    width: '100%',
                    marginLeft: switchMerchant ? -6 : -6,
                  }}
                  onPress={() => {
                    // setState({ renderUserList: true, renderEdit: false })
                    setRenderUserList(true);
                    setRenderEdit(false);
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      alignSelf: 'baseline',
                    }}>
                    <View style={{ justifyContent: 'center' }}>
                      <Feather
                        name="chevron-left"
                        size={switchMerchant ? 20 : 30}
                        color={Colors.primaryColor}
                      />
                    </View>
                    <Text
                      style={[
                        {
                          fontFamily: 'Nunitosans-Bold',
                          color: Colors.primaryColor,
                          fontSize: 17,
                          marginBottom: Platform.OS === 'ios' ? 0 : 2,
                        },
                        switchMerchant
                          ? {
                            fontSize: 14,
                            fontFamily: 'Nunitosans-Bold',
                          }
                          : {},
                      ]}>
                      Back
                    </Text>
                  </View>
                </TouchableOpacity>

                <View
                  style={[
                    {
                      height: windowHeight * 0.73,
                      backgroundColor: 'white',
                      marginTop: 15,
                      shadowColor: '#000',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.34,
                      shadowRadius: 3.32,
                      borderRadius: 5,
                      elevation: 2,
                    },
                    switchMerchant
                      ? {
                        height: windowHeight * 0.66,
                        marginTop: 10,
                        borderRadius: 5,
                      }
                      : {},
                  ]}>
                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={[
                      { paddingBottom: 100 },
                    ]}
                  // marginBottom={55}
                  >
                    <View
                      style={{
                        alignSelf: 'flex-end',
                        margin: 20,
                        position: 'absolute',
                        zIndex: 10000,
                      }}>
                      <View
                        style={{
                          justifyContent: 'center',
                          flexDirection: 'row',
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: '#4E9F7D',
                          borderRadius: 5,
                          width: 130,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: 'center',
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginTop: 10,
                          marginRight: 20,
                          marginBottom: 50,
                        }}>
                        <TouchableOpacity
                          style={[
                            {
                              width: 130,
                              height: 40,
                              alignItems: 'center',
                              justifyContent: 'center',
                            },
                            switchMerchant
                              ? {
                                width: 120,
                                height: 35,
                              }
                              : {},
                          ]}
                          onPress={() => {
                            updateUserBeerDocketExpiryDateByMerchant();
                          }}>
                          <Text
                            style={[
                              {
                                color: Colors.whiteColor,
                                //marginLeft: 5,
                                fontSize: 16,
                                fontFamily: 'NunitoSans-Bold',
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}>
                            SAVE
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>

                    <View
                      style={
                        {
                          // height: Platform.OS === 'ios'? windowHeight * 0.71 : windowHeight * 71,
                        }
                      }>
                      <View
                        style={[
                          {
                            width: Platform.OS === 'ios' ? '90%' : '90%',
                            flexDirection: 'row',
                            marginTop: 80,
                          },
                          switchMerchant
                            ? {
                              width: '95%',
                              justifyContent: 'center',
                            }
                            : {},
                        ]}>
                        <View
                          style={[
                            {
                              width: Platform.OS === 'ios' ? '35%' : '35%',
                              borderRightWidth: StyleSheet.hairlineWidth,
                              borderRightColor: Colors.descriptionColor,
                            },
                            switchMerchant
                              ? {
                                // width: '60%'
                              }
                              : {},
                          ]}>
                          <View style={{ alignItems: 'center', marginTop: 20 }}>
                            {/* {
                                    selectedUserBeerDocket.userAvatar
                                    ?
                                    <AsyncImage style={{
                                        width: 150,
                                        height: 150,
                                        alignSelf: 'center',
                                        borderRadius: 10,
                                        backgroundColor: Colors.secondaryColor,
                                        }}
                                        source={{
                                            uri: selectedUserBeerDocket.userAvatar
                                        }}
                                        hideLoading={true}
                                        placeholderSource
                                        />
                                        :
                                        <View style={{
                                            marginRight: 30,
                                            width: 150,
                                            height: 150,
                                            borderRadius: 10,
                                            alignSelf: 'center',
                                            backgroundColor: Colors.secondaryColor,
                                        }}>
                                        <AsyncImage style={{
                                                width: 150,
                                                height: 150,
                                                alignSelf: 'center',
                                                borderRadius: 10,
                                        }} source={require('../assets/image/profile-pic.jpg')}
                                        hideLoading={true}
                                        />
                                        </View>

                                    } */}
                            <View
                              style={[
                                {
                                  // marginRight: 30,
                                  width: 220,
                                  height: 200,
                                  borderRadius: 10,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  alignSelf: 'center',
                                  backgroundColor: '#F7F7F7',
                                },
                                switchMerchant
                                  ? {
                                    width: 120,
                                    height: 120,
                                  }
                                  : {},
                              ]}>
                              <Image
                                style={[
                                  {
                                    width: 135,
                                    height: 135,
                                  },
                                  switchMerchant
                                    ? {
                                      width: 75,
                                      height: 75,
                                    }
                                    : {},
                                ]}
                                source={require('../assets/image/default-profile.png')}
                                hideLoading={true}
                              />
                            </View>

                            <View style={{ alignItems: 'center', marginTop: 20 }}>
                              <Text
                                style={[
                                  {
                                    fontSize: 34,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 20,
                                    }
                                    : {},
                                ]}>
                                {selectedUserBeerDocket.userName}
                              </Text>
                            </View>
                          </View>
                        </View>

                        <View
                          style={[
                            {
                              width: Platform.OS === 'ios' ? '60%' : '60%',
                              zIndex: 5,
                            },
                            switchMerchant
                              ? {
                                width: '55%',
                              }
                              : {},
                          ]}>
                          <View
                            style={{
                              alignItems: 'flex-start',
                              //justifyContent: 'space-between',
                              marginTop: 25,
                              marginLeft: '5%',
                              zIndex: 5,
                            }}>
                            <View
                              style={[
                                {
                                  flexDirection: 'row',
                                  width: '100%',
                                  marginBottom: 40,
                                  height: 50,
                                },
                                switchMerchant
                                  ? {
                                    height: 35,
                                    marginBottom: 20,
                                  }
                                  : {},
                              ]}>
                              <View
                                style={[
                                  {
                                    justifyContent: 'center',
                                    width: '43%',
                                  },
                                  switchMerchant
                                    ? {
                                      width: '35%',
                                    }
                                    : {},
                                ]}>
                                <Text
                                  style={[
                                    styles.textSize,
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}>
                                  Items
                                </Text>
                              </View>
                              {
                                console.log('\naaaaaa', selectedUserBeerDocket, beerDocketsDict, outletItemsStrList)
                              }
                              <View style={{}}>
                                <TextInput
                                  placeholderTextColor={Platform.select({
                                    ios: '#a9a9a9',
                                  })}
                                  underlineColorAndroid={Colors.fieldtBgColor}
                                  style={[
                                    styles.textInput,
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                        height: 35,
                                        width: 210,
                                        borderRadius: 5,
                                      }
                                      : {},
                                  ]}
                                  //placeholder={editId.items[0].name}
                                  editable={false}
                                  defaultValue={
                                    selectedUserBeerDocket.beerDocketId &&
                                      beerDocketsDict[
                                      selectedUserBeerDocket.beerDocketId
                                      ]
                                      ? outletItemsStrList.join(', ')
                                      : 'N/A'
                                  }
                                />
                              </View>
                            </View>

                            {/* <View style={styles.viewContainer}>
                                            <View>
                                                <Text style={styles.textSize}>Quantity:</Text>
                                            </View>
                                            <View>
                                                <TextInput
                                                    underlineColorAndroid={Colors.fieldtBgColor}
                                                    style={styles.textInput}
                                                    //placeholder={editId.items[0].categoryItems.quantity.toString()}
                                                    editable={false}
                                                    // defaultValue={selectedUserBeerDocket.quantity.toString()}
                                                />
                                            </View>
                                        </View> */}
                            <View
                              style={[
                                {
                                  flexDirection: 'row',
                                  width: '100%',
                                  marginBottom: 40,
                                  height: 50,
                                },
                                switchMerchant
                                  ? {
                                    height: 35,
                                    marginBottom: 20,
                                  }
                                  : {},
                              ]}>
                              <View
                                style={[
                                  {
                                    justifyContent: 'center',
                                    width: '43%',
                                    justifyContent: 'center',
                                  },
                                  switchMerchant
                                    ? {
                                      width: '35%',
                                    }
                                    : {},
                                ]}>
                                <Text
                                  style={[
                                    styles.textSize,
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}>
                                  Purchase Outlet
                                </Text>
                              </View>
                              <View>
                                <TextInput
                                  placeholderTextColor={Platform.select({
                                    ios: '#a9a9a9',
                                  })}
                                  underlineColorAndroid={Colors.fieldtBgColor}
                                  style={[
                                    styles.textInput,
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                        height: 35,
                                        width: 210,
                                        borderRadius: 5,
                                      }
                                      : {},
                                  ]}
                                  //placeholder={editId.outlet[0].name}
                                  editable={false}
                                  // defaultValue={selectedUserBeerDocket.uniqueId && userOrderBeerDocketUBDIdDict[selectedUserBeerDocket.uniqueId] ? userOrderBeerDocketUBDIdDict[selectedUserBeerDocket.uniqueId].outletName : 'N/A'}
                                  defaultValue={
                                    selectedUserBeerDocket.beerDocketId
                                      ? selectedUserBeerDocket.purchasedOutletName
                                      : 'N/A'
                                  }
                                />
                              </View>
                            </View>
                            <View
                              style={[
                                {
                                  flexDirection: 'row',
                                  width: '100%',
                                  marginBottom: 40,
                                  height: 50,
                                },
                                switchMerchant
                                  ? {
                                    height: 35,
                                    marginBottom: 20,
                                  }
                                  : {},
                              ]}>
                              <View
                                style={[
                                  {
                                    justifyContent: 'center',
                                    width: '43%',
                                    justifyContent: 'center',
                                  },
                                  switchMerchant
                                    ? {
                                      width: '35%',
                                    }
                                    : {},
                                ]}>
                                <Text
                                  style={[
                                    styles.textSize,
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}>
                                  Purchase Date & Time
                                </Text>
                              </View>
                              <View>
                                <TextInput
                                  placeholderTextColor={Platform.select({
                                    ios: '#a9a9a9',
                                  })}
                                  underlineColorAndroid={Colors.fieldtBgColor}
                                  style={[
                                    styles.textInput,
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                        height: 35,
                                        width: 210,
                                        borderRadius: 5,
                                      }
                                      : {},
                                  ]}
                                  //placeholder={moment(editId.purchaseAt).format('L') + ' | ' + moment(editId.purchaseAt).format('LT')}
                                  editable={false}
                                  // defaultValue={selectedUserBeerDocket.uniqueId && userOrderBeerDocketUBDIdDict[selectedUserBeerDocket.uniqueId] ? moment(userOrderBeerDocketUBDIdDict[selectedUserBeerDocket.uniqueId].createdAt).format('DD/MM/YYYY hh:mma') : 'N/A'}
                                  defaultValue={moment(
                                    selectedUserBeerDocket.createdAt,
                                  ).format('DD MMM YYYY hh:mmA')}
                                />
                              </View>
                            </View>
                            <View
                              style={[
                                {
                                  flexDirection: 'row',
                                  width: '100%',
                                  marginBottom: 40,
                                  height: 50,
                                  zIndex: 1,
                                },
                                switchMerchant
                                  ? {
                                    height: 35,
                                    marginBottom: 20,
                                  }
                                  : {},
                              ]}>
                              <View
                                style={[
                                  {
                                    justifyContent: 'center',
                                    width: '43%',
                                    justifyContent: 'center',
                                  },
                                  switchMerchant
                                    ? {
                                      width: '35%',
                                    }
                                    : {},
                                ]}>
                                <Text
                                  style={[
                                    styles.textSize,
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}>
                                  Expired Date
                                </Text>
                                {/* <Text style={styles.textSizeDescription}>Free extension only manager can approve it</Text> */}
                                {/* <TouchableOpacity style={{
                                                    marginTop: 1,
                                                    marginLeft: 1,
                                                }}
                                                    onPress={() => {
                                                        // setState({ showEdit: true })
                                                        setShowEdit(true);
                                                        // setShowExpiryDateTimePicker(true);
                                                    }}>
                                                        <Text style={styles.textSizeDescription, {color: Colors.primaryColor}}>Extend</Text>
                                                </TouchableOpacity> */}
                              </View>
                              <View
                                style={[
                                  {
                                    flexDirection: 'row',
                                    width: '100%',
                                    marginBottom: 40,
                                    height: 50,
                                    zIndex: 10000,
                                  },
                                  switchMerchant
                                    ? {
                                      height: 35,
                                    }
                                    : {},
                                ]}>
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                  }}>
                                  <TextInput
                                    underlineColorAndroid={Colors.fieldtBgColor}
                                    style={[
                                      styles.textInput,
                                      switchMerchant
                                        ? {
                                          fontSize: 10,
                                          height: 35,
                                          width: 165,
                                          borderRadius: 5,
                                        }
                                        : {},
                                    ]}
                                    //placeholder={moment(editId.expiredAt).format('L')}
                                    editable={false}
                                    placeholderTextColor={Platform.select({
                                      ios: '#a9a9a9',
                                    })}
                                    defaultValue={moment(expiryDateTime).format(
                                      'DD MMM YYYY hh:mmA',
                                    )}
                                  />
                                  <TouchableOpacity
                                    style={[switchMerchant ? {} : {}]}
                                    onPress={() => {
                                      // setState({ showEdit: true })
                                      setShowEdit(true);
                                      setIsExtendedDate(false);
                                      setIsOutside(false);
                                      // setShowExpiryDateTimePicker(true);
                                    }}>
                                    <Text
                                      style={[
                                        switchMerchant
                                          ? {
                                            fontSize: 10,
                                            fontFamily: 'NunitoSans-SemiBold',
                                            color: Colors.primaryColor,
                                          }
                                          : {
                                            fontSize: 15,
                                            fontFamily: 'NunitoSans-SemiBold',
                                            color: Colors.primaryColor,
                                          },
                                      ]}>
                                      Extend
                                    </Text>
                                  </TouchableOpacity>
                                </View>
                              </View>
                            </View>

                            {isExtendedDate ? (
                              <View
                                style={[
                                  {
                                    flexDirection: 'row',
                                    width: '100%',
                                    marginBottom: 40,
                                    height: 50,
                                  },
                                  switchMerchant
                                    ? {
                                      height: 35,
                                      marginBottom: 20,
                                    }
                                    : {},
                                ]}>
                                <View
                                  style={[
                                    {
                                      justifyContent: 'center',
                                      width: '43%',
                                      justifyContent: 'center',
                                    },
                                    switchMerchant
                                      ? {
                                        width: '35%',
                                      }
                                      : {},
                                  ]}>
                                  <Text
                                    style={[
                                      {
                                        fontSize: 21,
                                        fontFamily: 'NunitoSans-SemiBold',
                                        color: 'black',
                                      },
                                      switchMerchant
                                        ? {
                                          fontSize: 10,
                                        }
                                        : {},
                                    ]}>
                                    Extended Date
                                  </Text>
                                </View>
                                <View
                                  style={[
                                    {
                                      flexDirection: 'row',
                                      width: '100%',
                                      marginBottom: 40,
                                      height: 50,
                                    },
                                    switchMerchant
                                      ? {
                                        height: 35,
                                      }
                                      : {},
                                  ]}>
                                  <View
                                    style={{
                                      flexDirection: 'row',
                                      alignContent: 'center',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}>
                                    <TextInput
                                      underlineColorAndroid={
                                        Colors.fieldtBgColor
                                      }
                                      style={[
                                        styles.textInput,
                                        switchMerchant
                                          ? {
                                            fontSize: 10,
                                            height: 35,
                                            width: 210,
                                            borderRadius: 5,
                                          }
                                          : {},
                                      ]}
                                      //placeholder={moment(editId.expiredAt).format('L')}
                                      editable={false}
                                      placeholderTextColor={Platform.select({
                                        ios: '#a9a9a9',
                                      })}
                                      defaultValue={`${moment(
                                        extendedDate,
                                      ).format(
                                        'DD MMM YYYY hh:mmA',
                                      )} (${totalDays} day${totalDays > 1 ? 's' : ''
                                        })`}
                                    />
                                    {/* Platform.OS === 'ios' ? 20 : 25 */}
                                    {/* <Text style = {{  marginRight:Platform.OS === 'ios' ?  50: 25, }}> {`( </Text> */}
                                  </View>
                                </View>
                              </View>
                            ) : (
                              <></>
                            )}
                            <View
                              style={[
                                {
                                  flexDirection: 'row',
                                  width: '100%',
                                  marginBottom: 40,
                                  height: 50,
                                },
                                switchMerchant
                                  ? {
                                    height: 35,
                                    marginBottom: 20,
                                  }
                                  : {},
                              ]}>
                              <View
                                style={[
                                  {
                                    justifyContent: 'center',
                                    width: '43%',
                                    justifyContent: 'center',
                                  },
                                  switchMerchant
                                    ? {
                                      width: '35%',
                                    }
                                    : {},
                                ]}>
                                <Text
                                  style={[
                                    styles.textSize,
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}>
                                  Outlet For Redemptions
                                </Text>
                              </View>

                              {console.log('test')}
                              {console.log(outletDropdownList)}
                              {console.log(selectedOutletIdList)}

                              <Select
                                defaultValue={[]}
                                onChange={(items) => {
                                  var tempData = [];
                                  items.map((item) => tempData.push(item.value))
                                  setSelectedOutletIdList(tempData);
                                }}
                                options={outletDropdownList}
                                isMulti
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </View>
            ) : (
              <View
                style={[
                  {
                    backgroundColor: Colors.whiteColor,
                    width: windowWidth * 0.87,
                    height: windowHeight * 0.74,
                    marginTop: 20,
                    //marginHorizontal: 30,
                    marginLeft: 20,
                    marginBottom: 30,
                    alignSelf: 'center',
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  },
                  switchMerchant
                    ? {
                      height: windowHeight * 0.73,
                      width: windowWidth * 0.82,
                      marginTop: 0,
                      marginLeft: 0,
                      // marginHorizontal: 30,
                      alignSelf: 'center',
                    }
                    : {},
                ]}>
                <View
                  style={[
                    { flex: 1, marginBottom: 0, zIndex: -1 },
                    ,
                    switchMerchant
                      ? {
                        // marginBottom: 50,
                      }
                      : {},
                  ]}>
                  <View
                    style={[
                      {
                        height: windowHeight * 0.07,
                        backgroundColor: Colors.lightPrimary,
                        flexDirection: 'row',
                        alignItems: 'center',
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                      },
                      switchMerchant
                        ? {
                          width: windowWidth * 0.82,
                        }
                        : {},
                    ]}>
                    <TouchableOpacity
                      style={{
                        paddingHorizontal: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={() => { }}>
                      <View
                        style={{
                          height: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderBottomWidth: parkingType == false ? 4 : 0,
                          borderBottomRightRadius: 6,
                          borderBottomLeftRadius: 6,
                          borderBottomColor: Colors.primaryColor,
                        }}>
                        <Text
                          style={[
                            {
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 15,
                              color: Colors.fieldtTxtColor,
                            },
                            switchMerchant
                              ? {
                                fontSize: 13,
                              }
                              : {},
                          ]}>
                          Active Docket
                        </Text>
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{ paddingHorizontal: 20 }}
                      onPress={() => {
                        // navigation.navigate('RedemptionExpired');
                        linkTo && linkTo(`${prefix}/redemptionexpired`);
                      }}>
                      <View
                        style={{
                          height: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderBottomWidth: true ? 0 : 0,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderBottomColor: Colors.primaryColor,
                          marginBottom: 5,
                        }}>
                        <Text
                          style={[
                            {
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 15,
                              color: Colors.fieldtTxtColor,
                            },
                            switchMerchant
                              ? {
                                fontSize: 13,
                              }
                              : {},
                          ]}>
                          Expired Docket
                        </Text>
                      </View>
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{
                        paddingHorizontal: 20,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onPress={() => {
                        // navigation.navigate('RedemptionRedeemed');
                        linkTo && linkTo(`${prefix}/redemptionredeemed`);
                      }}>
                      <View
                        style={{
                          height: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderBottomWidth: true ? 0 : 0,
                          borderBottomRightRadius: 8,
                          borderBottomLeftRadius: 8,
                          borderBottomColor: Colors.primaryColor,
                          marginBottom: 5,
                        }}>
                        <Text
                          style={[
                            {
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 15,
                              color: Colors.fieldtTxtColor,
                            },
                            switchMerchant
                              ? {
                                fontSize: 13,
                              }
                              : {},
                          ]}>
                          Redeemed Docket
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      flex: 1,
                      padding: 40,
                      paddingTop: 20,
                    }}>
                    <View style={{ flexDirection: 'row', flex: 6 }}>
                      <View style={{ flex: 1.3 }}>
                        <View
                          style={{
                            width: windowWidth * 0.175,
                          }}>
                          <Text
                            style={[
                              {
                                alignSelf: 'flex-start',
                                padding: 6,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 18,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 15,
                                }
                                : {},
                            ]}>
                            Summary
                          </Text>
                          <View
                            style={[
                              {
                                width: windowWidth * 0.175,
                                backgroundColor: Colors.primaryColor,
                                height: windowHeight * 0.43,
                                padding: 15,
                                borderRadius: 20,
                              },
                              switchMerchant
                                ? {
                                  borderRadius: 10,
                                }
                                : {},
                            ]}>
                            <Text
                              style={[
                                {
                                  fontSize: 18,
                                  fontFamily: 'NunitoSans-Regular',
                                  color: Colors.whiteColor,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 15,
                                  }
                                  : {},
                              ]}>
                              Total Docket
                            </Text>
                            <Text
                              style={[
                                {
                                  fontSize: 35,
                                  fontFamily: 'NunitoSans-Bold',
                                  color: Colors.whiteColor,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 20,
                                  }
                                  : {},
                              ]}>
                              {currUserBeerDocketList.length}
                            </Text>
                          </View>
                        </View>
                      </View>
                      <View style={{ flex: 3.13 }}>
                        <Text
                          style={[
                            {
                              alignSelf: 'flex-start',
                              padding: 6,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 18,
                              opacity:
                                currUserBeerDocketList.length > 0 ? 100 : 0,
                            },
                            switchMerchant
                              ? {
                                fontSize: 15,
                              }
                              : {},
                          ]}>
                          Category
                        </Text>
                        <View style={{ flexDirection: 'row' }}>
                          <FlatList
                            horizontal={true}
                            showsHorizontalScrollIndicator={false}
                            data={redemptionSummaryList}
                            renderItem={renderAlcoholCategory}
                            keyExtractor={(item, index) => String(index)}
                          />
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        {
                          flex: 1,
                          alignItems: 'flex-end',
                          justifyContent: 'center',
                        },
                        switchMerchant ? {} : {},
                      ]}>
                      <TouchableOpacity
                        style={[
                          {
                            height: 50,
                            width: 180,
                            backgroundColor: Colors.primaryColor,
                            borderRadius: 8,
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            flexDirection: 'row',
                            padding: 10,
                          },
                          switchMerchant
                            ? {
                              height: 35,
                              width: 110,
                            }
                            : {},
                        ]}
                        onPress={() => {
                          // setState({ renderUserList: true })
                          setRenderUserList(true);
                        }}>
                        <Feather
                          size={switchMerchant ? 15 : 30}
                          name={'list'}
                          color={Colors.whiteColor}
                        />
                        <Text
                          style={[
                            {
                              color: Colors.whiteColor,
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 20,
                            },
                            switchMerchant
                              ? {
                                fontSize: 12,
                              }
                              : {},
                          ]}>
                          USER LIST
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  sidebar: {
    width: Dimensions.get('screen').width * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: 'center',
  },
  textSize: {
    fontSize: 21,
    fontFamily: 'NunitoSans-SemiBold',
  },
  textSizeDescription: {
    fontSize: 15,
    fontFamily: 'NunitoSans-SemiBold',
    color: Colors.descriptionColor,
  },
  textInput: {
    fontFamily: 'NunitoSans-Regular',
    width: 270,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 10,
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  textInput1: {
    fontFamily: 'NunitoSans-Regular',
    width: 230,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: Platform.OS === 'ios' ? 10 : 10,
    flexDirection: 'row',
    paddingHorizontal: 10,
  },
  viewContainer: {
    marginBottom: 25,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  swipeFont: {
    fontSize: 14,
    fontFamily: 'NunitoSans-Bold',
  },
  swipeButton: {
    fontFamily: 'NunitoSans-Regular',
    height: '93%',
    justifyContent: 'center',
    alignItems: 'center',
    width: 140,
  },
  modalContainer: {
    flex: 1,
    //backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sectionModal: {
    // height: Dimensions.get('screen').width * 0.3,
    // padding: Dimensions.get('screen').width * 0.03,
    width: Dimensions.get('screen').width * 0.4,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    // height: 350,
    // width: 500,
  },
  headerLeftStyle: {
    width: Dimensions.get('screen').width * 0.17,
    justifyContent: 'center',
    alignItems: 'center',
  },

  addBtn: {
    backgroundColor: Colors.primaryColor,
    width: 40,
    height: 45,
    justifyContent: 'center',
    alignItems: 'center',

    borderColor: 'transparent',
  },
});
export default RedemptionScreen;
