import React, { Component, useReducer, useState, useRef, useEffect } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Modal,
  Dimensions,
  TextInput,
  FlatList,
  Animated,
  KeyboardAvoidingView,
  Platform,
  useWindowDimensions,
} from 'react-native';
import Colors from '../constant/Colors';
// import Styles from '../constant/Styles';
import SideBar from './SideBar';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Ionicons';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
import DropDownPicker from 'react-native-dropdown-picker';
// import AIcon from 'react-native-vector-icons/AntDesign';
// import Feather from 'react-native-vector-icons/Feather';
// import EvilIcons from 'react-native-vector-icons/EvilIcons';
import moment from 'moment';
// import DateTimePickerModal from 'react-native-modal-datetime-picker';
// import {isTablet} from 'react-native-device-detection';
// import {
//   MERCHANT_VOUCHER_CODE_FORMAT,
//   MERCHANT_VOUCHER_TYPE,
//   SEGMENT_TYPE,
// } from '../constant/common';
import { CommonStore } from '../store/commonStore';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { OutletStore } from '../store/outletStore';
// import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
// import RNPickerSelect from 'react-native-picker-select';
// import {useKeyboard} from '../hooks';
// import {get} from 'react-native/Libraries/Utilities/PixelRatio';
// import Entypo from 'react-native-vector-icons/Entypo';
import AntDesign from 'react-native-vector-icons/AntDesign';
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import AsyncImage from '../components/asyncImage';
// import Switch from 'react-native-switch-pro';
// import {NavigationContext} from '@react-navigation/native';
// import Pie from 'react-native-pie';
import Speedometer from './SpeedometerScreen';
import XLSX from 'xlsx';
// import {
//   Table,
//   TableWrapper,
//   Row,
//   Rows,
//   Col,
//   Cols,
//   Cell,
// } from 'react-native-table-component';
// import DocumentPicker from 'react-native-document-picker';
// import {writeFile, readFile, DocumentDirectoryPath} from 'react-native-fs';
// import RNFetchBlob from 'rn-fetch-blob';
// import {TARGET_USER_GROUP} from '../constant/promotions';
// import Dish from '../assets/svg/Dish';
// import Coins from '../assets/svg/Coins';
// import Hand from '../assets/svg/Hand';
// import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Feather from "react-native-vector-icons/Feather";
import { useLinkTo, useRoute } from "@react-navigation/native";
import { prefix } from "../constant/env";

//////////////////////////////////////////////////////////////////////////////////////////////////////////

const PromotionListScreen = (props) => {
  const { navigation } = props;

  const linkTo = useLinkTo();

  const {
    height: windowHeight,
    width: windowWidth,
  } = useWindowDimensions();

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  // const RNFS = require('react-native-fs');

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  const [list1, setList1] = useState(true);
  const [customerList, setCustomerList] = useState([]);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  const [visible, setVisible] = useState(false); //manage Filter modal
  const [controller, setController] = useState({});
  const [switchMerchant, setSwitchMerchant] = useState(false);

  const [exportModal, setExportModal] = useState(false);

  const [expandThreeDots, setExpandThreeDots] = useState({}); //Use to expand the view when three dots are tapped
  const [threeDotsTapped, setThreeDotsTapped] = useState(false); //when the three dots are tapped will become (true)
  const [inSightsModal, setInSightsModal] = useState(false);

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState('');

  const [search, setSearch] = useState('');

  const promotions = OutletStore.useState((s) => s.promotions);

  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const currOutletId = MerchantStore.useState((s) => s.currOutletId);

  const [gaugeValue, setGaugeValue] = useState(0);

  const accumulator = CommonStore.useState((s) => s.accumulator);

  const crmUsers = OutletStore.useState((s) => s.crmUsers);
  const selectedCustomerEdit = CommonStore.useState(
    (s) => s.selectedCustomerEdit,
  );
  const crmUserTags = OutletStore.useState((s) => s.crmUserTags);
  const crmUserTagsDict = OutletStore.useState((s) => s.crmUserTagsDict);
  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView,
  );
  const [pushPagingToTop, setPushPagingToTop] = useState(false);

  useEffect(() => {
    setTargetOutletDropdownList(
      allOutlets.map((outlet) => ({
        label: outlet.name,
        value: outlet.uniqueId,
      })),
    );

    console.log('targetOutletDropdownList');
    console.log(targetOutletDropdownList);

    if (allOutlets.length > 0) {
      setSelectedTargetOutletId(currOutletId);
    }
  }, [allOutlets, currOutletId]);

  useEffect(() => {
    setCurrentPage(1);
    setPageCount(Math.ceil(promotions.length / perPage));
  }, [promotions]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  //To remove unwanted sidebar
  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  //Header
  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            // bottom: -2,
            bottom: switchMerchant ? '2%' : 0,
          },
          Dimensions.get('screen').width >= 768 && switchMerchant
            ? { right: Dimensions.get('screen').width * 0.1 }
            : {},
          Dimensions.get('screen').width <= 768
            ? { right: Dimensions.get('screen').width * 0.12 }
            : {},
        ]}>
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Promotion
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: 'white',
            width: 0.5,
            height: Dimensions.get('screen').height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}></View>
        <TouchableOpacity
          onPress={() => navigation.navigate('Setting')}
          style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get('screen').height * 0.05,
              height: Dimensions.get('screen').height * 0.05,
              borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}>
            <Image
              style={{
                width: Dimensions.get('screen').height * 0.035,
                height: Dimensions.get('screen').height * 0.035,
                alignSelf: 'center',
              }}
              source={require('../assets/image/profile-pic.jpg')}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });
  /////////////////////////////////////////////////////////////////////////////////////////
  //Function Start Here

  const convertTemplateToExcelFormat = () => {
    var excelTemplate = [];

    var excelColumn = {
      Name: '1',
      'Contact Number': '2',
      UserName: '3',
      Gender: '4',
      'Date of Birth': '5',
      Email: '6',
      Address: '7',
    };
    excelTemplate.push(excelColumn);

    console.log('excelTemplate');
    console.log(excelTemplate);

    return excelTemplate;
  };

  // const exportTemplate = () => {
  //   const excelTemplate = convertTemplateToExcelFormat();

  //   var excelFile = `${
  //     Platform.OS === 'ios'
  //       ? RNFS.DocumentDirectoryPath
  //       : RNFS.DownloadDirectoryPath
  //   }/koodoo-CRM${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
  //   var excelWorkSheet = XLSX.utils.json_to_sheet(excelTemplate);
  //   var excelWorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, 'CRM Template');

  //   const workBookData = XLSX.write(excelWorkBook, {
  //     type: 'binary',
  //     bookType: 'xlsx',
  //   });

  //   RNFS.writeFile(excelFile, workBookData, 'ascii')
  //     .then((success) => {
  //       console.log(`wrote file ${excelFile}`);

  //       Alert.alert(
  //         'Success',
  //         `Send to ${excelFile}`,
  //         [{text: 'OK', onPress: () => {}}],
  //         {cancelable: false},
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  //const [items, setItems] = useState([]);

  // const importSelectFile = async () => {
  //   try {
  //     console.log('Select File');
  //     const res = await DocumentPicker.pick({
  //       type: [DocumentPicker.types.allFiles],
  //     });

  //     //importTemplate(res)
  //     console.log(res.name);
  //   } catch (err) {
  //     console.log(err);
  //     if (DocumentPicker.isCancel(err)) {
  //     } else {
  //       console.log(err);
  //       throw err;
  //     }
  //   }

  //   // RNFetchBlob.fetch('POST', 'https://content.dropboxapi.com/2/files/upload', {
  //   //   // dropbox upload headers
  //   //   Authorization : "Bearer access-token...",
  //   //   'Dropbox-API-Arg': JSON.stringify({
  //   //     path : '/img-from-react-native.png',
  //   //     mode : 'add',
  //   //     autorename : true,
  //   //     mute : false
  //   //   }),
  //   //   'Content-Type' : 'application/octet-stream',
  //   //   // Change BASE64 encoded data to a file path with prefix `RNFetchBlob-file://`.
  //   //   // Or simply wrap the file path with RNFetchBlob.wrap().
  //   // }, RNFetchBlob.wrap())
  //   // .then((res) => {
  //   //   console.log(res.name)
  //   // })
  //   // .catch((err) => {
  //   //   // error handling ..
  //   // })
  // };

  const importTemplate = (file) => {
    console.log('Read File');
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: 'buffer' });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      console.log(d);
    });
  };

  // const importTemplate = (file) => {
  //   const promise = new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //       fileReader.readAsArrayBuffer(file);

  //       fileReader.onload = (e) => {
  //       const bufferArray = e.target.result;

  //       const wb = XLSX.read(bufferArray, { type: "buffer" });

  //       const wsname = wb.SheetNames[0];

  //       const ws = wb.Sheets[wsname];

  //       const data = XLSX.utils.sheet_to_json(ws);

  //       resolve(data);
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });

  //   promise.then((d) => {
  //     //setItems(d);
  //     console.log(d)
  //   });
  // };

  const convertExcelFormatToTemplate = () => {
    var excelTemplate = [];
    var excelColumn = {
      Name: '1',
      'Contact Number': '2',
      UserName: '3',
      Gender: '4',
      'Date of Birth': '5',
      Email: '6',
      Address: '7',
    };
    excelTemplate.push(excelColumn);

    console.log('excelTemplate');
    console.log(excelTemplate);

    return excelTemplate;
  };

  // const add = async () => {
  //   if (page + 1 < pageCount) {
  //     await setState({page: page + 1, currentPage: currentPage + 1});
  //     console.log(page);
  //     var e = page;
  //     next(e);
  //   }
  // };

  // const next = (e) => {
  //   const offset = e * perPage;
  //   setState({offset: offset});
  //   loadMoreData();
  // };

  // const less = async () => {
  //   if (page > 0) {
  //     await setState({page: page - 1, currentPage: currentPage - 1});
  //     console.log(page);
  //     var y = page;
  //     pre(y);
  //   }
  // };

  const nextPage = () => {
    setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
  };

  // const loadMoreData = () => {
  //   const data = oriList;
  //   const slice = data.slice(offset, offset + perPage); //30
  //   setState({list: slice, pageCount: Math.ceil(data.length / perPage)});
  // };

  // const getDetail = () => {
  //   ApiClient.GET(
  //     API.salesByTransactionCategory +
  //       1 +
  //       '&startDate=' +
  //       startDate +
  //       '&endDate=' +
  //       endDate,
  //   ).then((result) => {
  //     var data = result;
  //     var slice = data.slice(offset, offset + perPage);
  //     setState({
  //       list: slice,
  //       oriList: data,
  //       pageCount: Math.ceil(data.length / perPage),
  //     });
  //   });
  // };

  const switchPromotionActiveStatus = (promotion, value) => {
    // self.onButtonClickHandler();

    var body = {
      // outletId: User.getOutletId()
      promotionId: promotion.uniqueId,
      promotionStatus: value,
    };

    ApiClient.POST(API.switchPromotionActiveStatus, body)
      .then((result) => {
        // console.log("switchState statusresult", result.reservationStatus)
        // if (result.reservationStatus === true) {
        //     //Alert.alert("Reservation is closed now")
        //     return self.setState({ switchState: false })
        // } else if (result.reservationStatus === false) {
        //     //Alert.alert("Reservation is open now")
        //     return self.setState({ switchState: true })
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const confirmDeletePromotion = (item) => {
    return Alert.alert('Delete', 'Do you want to delete this promotion?', [
      {
        text: 'YES',
        onPress: () => {
          deletePromotion(item);
        },
      },
      {
        text: 'NO',
        onPress: () => { },
      },
    ]);
  };

  const deletePromotion = (promotion) => {
    var body = {
      promotionId: promotion.uniqueId,
    };

    ApiClient.POST(API.deletePromotion, body)
      .then((result) => {
        if (result && result.status === 'success') {
          Alert.alert(
            'Success',
            'Promotion has been removed',
            [
              {
                text: 'OK',
                onPress: () => {
                  // navigation.goBack();
                },
              },
            ],
            { cancelable: false },
          );
        } else {
          Alert.alert(
            'Error',
            'Failed to remove this promotion',
            [{ text: 'OK', onPress: () => { } }],
            { cancelable: false },
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //when press three dots this function will work
  const expandThreeDotsFunc = (param) => {
    if (expandThreeDots[param.uniqueId]) {
      setExpandThreeDots({
        ...expandThreeDots,
        [param.uniqueId]: false,
      });
    } else {
      setExpandThreeDots({
        // ...expandThreeDots,
        [param.uniqueId]: true,
      });
    }
  };

  const renderItem = ({ item, index }) => {
    var startTime = moment(item.promoTimeStart);
    var startDate = moment(item.promoDateStart).set({
      hour: startTime.get('hour'),
      minute: startTime.get('minute'),
    });

    var endTime = moment(item.promoTimeEnd);
    var endDate = moment(item.promoDateEnd).set({
      hour: endTime.get('hour'),
      minute: endTime.get('minute'),
    });

    console.log(startDate.format());
    console.log(endDate.format());

    return (
      <View
        style={{
          //backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          zIndex: (index + 1) % 2 == 0 ? -2 : 1,
          backgroundColor: '#FFFFFF',
          paddingVertical: 20,
          //paddingHorizontal: 3,
          //paddingLeft: 1,
          borderColor: '#BDBDBD',
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          //height: 105,
          // width: '100%',
        }}>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={() => {
            CommonStore.update((s) => {
              s.selectedPromotionEdit = item;
            });

            // navigation.navigate('NewCampaign');
            linkTo && linkTo(`${prefix}/newcampaign`);
          }}>
          <View
            style={{
              flexDirection: 'row',
              marginTop: 0,
              marginBottom: 0,
              alignItems: 'center',
              width: '100%',
            }}>
            <View style={{ width: '13%', left: 20 }}>
              <AsyncImage
                //source={{ uri: logo }}
                source={{ uri: item.image }}
                // item={item}
                hideLoading={true}
                style={[
                  {
                    width: 75,
                    height: 75,
                    boarderWidth: 1,
                    borderRadius: 4,
                    backgroundColor: Colors.secondaryColor,
                  },
                  switchMerchant
                    ? {
                      width: 45,
                      height: 45,
                    }
                    : {},
                ]}></AsyncImage>
            </View>

            <View style={{ width: '22.5%', marginLeft: 3 }}>
              <Text
                style={[
                  {
                    fontSize: 14,
                    fontFamily: 'NunitoSans-Regular',
                    fontWeight: '500',
                    textAlign: 'left',
                    color: 'black',
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {item.campaignName}
              </Text>
            </View>

            <View
              style={{
                flexDirection: 'column',
                width: '20%',
                marginHorizontal: 5,
              }}>
              {/* <Text style={{ fontSize: 12, fontWeight: '500', textAlign: 'left' }}>May 26-30, 2020</Text> */}
              <Text
                style={[
                  {
                    fontSize: 14,
                    fontFamily: 'NunitoSans-Regular',
                    fontWeight: '500',
                    textAlign: 'left',
                    fontVariant: ['tabular-nums'],
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {`${moment(item.promoDateStart).format('DD MMM YYYY')}-${moment(
                  item.promoDateEnd,
                ).format('DD MMM YYYY')}`}
              </Text>
              {/* <Text style={{ fontSize: 11, fontWeight: '500', textAlign: 'left', color: Colors.fieldtTxtColor }}>11.00AM - 10.00PM</Text> */}
              <Text
                style={[
                  {
                    fontSize: 14,
                    fontFamily: 'NunitoSans-Regular',
                    fontWeight: '500',
                    textAlign: 'left',
                    fontVariant: ['tabular-nums'],
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {`${moment(item.promoTimeStart).format('HH:mm A')}-${moment(
                  item.promoTimeEnd,
                ).format('HH:mm A')}`}
              </Text>
            </View>

            <View style={{ width: '18.5%', marginHorizontal: 5 }}>
              <Text
                style={[
                  {
                    fontSize: 14,
                    fontFamily: 'NunitoSans-Regular',
                    fontWeight: '500',
                    textAlign: 'left',
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {'<'}
                {item.promoCode}
                {'>'}
              </Text>
            </View>

            {/* <View style={{ flex: 2, marginHorizontal: 4 }}>
            <Text style={{ fontSize: 12, fontWeight: '500', textAlign: 'left' }} numberOfLines={3}>{item.campaignDescription}</Text>
          </View> */}

            {/* <View style={{ flex: 2, marginHorizontal: 4 }}> */}
            {/* <Text style={{ fontSize: 12, fontWeight: '500', textAlign: 'left' }}>Buy 2, Get 50% OFF</Text> */}
            {/* <Text style={{ fontSize: 12, fontWeight: '500', textAlign: 'left' }} numberOfLines={3}>{item.campaignDescription}</Text>
            </View> */}

            <View style={{ width: '20%', marginHorizontal: 3 }}>
              <Text
                style={[
                  {
                    fontSize: 16,
                    fontWeight: '500',
                    textAlign: 'left',
                    marginBottom: 3,
                    //marginLeft: -2,
                    fontSize: 14,
                    fontFamily: 'NunitoSans-Regular',
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {item.outletNameList.join('\n')}
              </Text>
            </View>

            {/* {
              (startDate) > (moment()) ?
                <View style={{ flexDirection: 'column', flex: 0.6 }}>
                  <Switch
                    onSyncPress={(value) => {
                      switchPromotionActiveStatus(item, value);
                    }}
                    disabled={true}
                    style={{ transform: [{ scaleX: .9 }, { scaleY: .8 }] }}
                    circleColorActive={Colors.primaryColor}
                    circleColorInactive={Colors.fieldtTxtColor}
                    backgroundActive={'#dddddd'}
                    value={false}
                  //value={switchPromotionActiveStatus(item, value, false)}
                  />
                  <Text style={{ fontSize: 12, fontWeight: '500', color: Colors.tabRed, marginTop: 3 }}>
                    Expired
                  </Text>
                </View>
                :
                <View style={{ flexDirection: 'column', flex: 0.6 }}>
                  <Switch
                    onSyncPress={(value) => {
                      switchPromotionActiveStatus(item, value);
                    }}
                    style={{ transform: [{ scaleX: .9 }, { scaleY: .8 }] }}
                    circleColorActive={Colors.primaryColor}
                    circleColorInactive={Colors.fieldtTxtColor}
                    backgroundActive={'#dddddd'}
                    value={item.isActive}
                  />
                  <Text style={{ fontSize: 12, fontWeight: '500', marginLeft: 9, color: item.isActive ? Colors.primaryColor : Colors.fieldtTxtColor, marginTop: 3 }}>
                    {item.isActive ? 'ON' : 'OFF'}
                  </Text>
                </View>
          }*/}

            <View>
              {expandThreeDots[item.uniqueId] == true ? (
                <View
                  style={{
                    //position: 'absolute',
                    width: 110,
                    height: 67,
                    marginLeft: -110,
                    zIndex: 1,
                    flexDirection: 'column',
                    backgroundColor: '#FFFFFF',
                    borderWidth: 1,
                    //borderColor: '#E7E7E7',
                    borderColor: Colors.highlightColor,
                    borderRadius: 7,
                    shadowOpacity: 0,
                    shadowColor: '#000',
                    shadowOffset: {
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 2,
                  }}>
                  <TouchableOpacity
                    style={{
                      flexDirection: 'row',
                      height: '49%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onPress={() => {
                      CommonStore.update((s) => {
                        s.selectedPromotionEdit = item;
                      });

                      // navigation.navigate('NewCampaign');
                      linkTo && linkTo(`${prefix}/newcampaign`);
                    }}>
                    <View style={{ width: '30%', paddingLeft: 12 }}>
                      {/* <MaterialIcons name='edit' size={17} color='darkgreen' /> */}
                      <Feather
                        name="edit"
                        size={17}
                        color={Colors.primaryColor}
                      />
                    </View>
                    <View style={{ width: '70%' }}>
                      <Text style={{ marginLeft: 5 }}>Edit</Text>
                    </View>
                  </TouchableOpacity>

                  <View
                    style={{ borderWidth: 1, borderColor: Colors.fieldtBgColor }}
                  />

                  <TouchableOpacity
                    style={{
                      flexDirection: 'row',
                      height: '49%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onPress={(item) => {
                      confirmDeletePromotion(item);
                      //deletePromotion(item);
                    }}>
                    <View style={{ width: '30%', paddingLeft: 12 }}>
                      <MaterialCommunityIcons
                        name="delete-sweep"
                        size={17}
                        color="grey"
                      />
                    </View>
                    <View style={{ width: '70%' }}>
                      <Text style={{ marginLeft: 5 }}>Delete</Text>
                    </View>
                  </TouchableOpacity>

                  <View
                    style={{ borderWidth: 1, borderColor: Colors.fieldtBgColor }}
                  />

                  {/* <TouchableOpacity style={{ flexDirection: 'row', height: '32%', justifyContent: 'center', alignItems: 'center' }}
                onPress={() => { 
                  // here need calculate insights data first

                  // pending changes for multiple segment group first
                  // if (item.targetUserGroup === TARGET_USER_GROUP.EVERYONE) {
                  //   setGaugeValue(Math.floor())
                  // }

                  setInSightsModal(true);
                }}>
                <View style={{ width: '30%', paddingLeft: 12 }}>
                  <MaterialIcons name='insights' size={17} color='grey' />
                </View>
                <View style={{ width: '70%' }}>
                  <Text style={{ marginLeft: 5 }}>
                    Insights
                </Text>
                </View>
              </TouchableOpacity> */}

                  <Modal visible={inSightsModal} transparent={true}>
                    <View style={styles.modalContainer1}>
                      <View
                        style={[
                          styles.modalView,
                          {
                            borderRadius: windowWidth * 0.03,
                          },
                        ]}>
                        <TouchableOpacity
                          style={styles.closeButton}
                          onPress={() => {
                            setInSightsModal(false);
                          }}>
                          {/* <AIcon name="closecircle" size={25} color={Colors.fieldtTxtColor} /> */}
                          <AntDesign
                            name="closecircle"
                            size={25}
                            color={Colors.fieldtTxtColor}
                          />
                        </TouchableOpacity>
                        <View
                          style={{ justifyContent: 'center', marginBottom: 10 }}>
                          <Text
                            style={{
                              fontWeight: '600',
                              fontSize: 20,
                              alignSelf: 'center',
                            }}>
                            Insights
                          </Text>
                        </View>
                        <View>
                          <Text style={{ fontWeight: '700', fontSize: 16 }}>
                            Target Customer
                          </Text>
                          {/* Gauge meter, SpeedometerScreen */}
                          <Speedometer value={gaugeValue} />
                        </View>

                        <View style={{ top: -70 }}>
                          <View
                            style={{
                              borderTopWidth: 2,
                              borderBottomWidth: 2,
                              borderColor: '#E5E5E5',
                              marginVertical: 5,
                              paddingVertical: 12,
                            }}>
                            <Text style={{ color: Colors.fieldtTxtColor }}>
                              Potential Reach: 100,000
                            </Text>
                          </View>
                          <View style={{ marginBottom: 10 }}>
                            <Text style={{ fontWeight: '700', fontSize: 16 }}>
                              Estimated Daily Result
                            </Text>
                          </View>

                          <View>
                            <View style={{ marginBottom: 10 }}>
                              <Text
                                style={{ marginBottom: 5, fontWeight: '500' }}>
                                Target Customer Reach
                              </Text>
                              <Text style={{ fontWeight: '700', fontSize: 16 }}>
                                40,000
                              </Text>
                              <View>
                                <View
                                  style={{
                                    height: 10,
                                    width: 360,
                                    backgroundColor: Colors.fieldtBgColor,
                                  }}></View>
                                <View
                                  style={{
                                    height: 10,
                                    width: 300,
                                    backgroundColor: Colors.primaryColor,
                                    position: 'absolute',
                                  }}></View>
                              </View>
                            </View>
                            <View style={{ marginTop: 5 }}>
                              <Text
                                style={{ marginBottom: 5, fontWeight: '500' }}>
                                Promotion Clicks
                              </Text>
                              <Text style={{ fontWeight: '700', fontSize: 16 }}>
                                200
                              </Text>
                              <View>
                                <View
                                  style={{
                                    height: 10,
                                    width: 360,
                                    backgroundColor: Colors.fieldtBgColor,
                                  }}></View>
                                <View
                                  style={{
                                    height: 10,
                                    width: 250,
                                    backgroundColor: Colors.primaryColor,
                                    position: 'absolute',
                                  }}></View>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </Modal>
                </View>
              ) : null}
            </View>

            {/* <View style={{ flex: 0.25, flexDirection: 'row' }}>
            <TouchableOpacity style={{ marginTop: 0, alignSelf: 'flex-start', alignItems: 'flex-start' }}
              onPress={() => expandThreeDotsFunc(item)}
            >
              <Entypo name='dots-three-vertical' size={25} color={Colors.tabGrey} style={{ alignSelf: 'flex-start' }} />
            </TouchableOpacity>
          </View> */}
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  //////////////////////////////////////////////////////////////////////////////////////
  //Render start here
  return (
    <View style={[styles.container,
    {
      height: windowHeight,
      width: windowWidth,
    }]}>
      <View style={[styles.sidebar, { flex: 0.8 }]}>
        <SideBar navigation={navigation} selectedTab={11} expandPromotions={true} />
      </View>

      {/* **********Manage FIlter Modal*************** */}

      <Modal
        style={{ flex: 1 }}
        visible={visible}
        transparent={true}
        animationType="slide">
        <KeyboardAvoidingView
          behavior="padding"
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: windowHeight,
          }}>
          <View style={[styles.ManageFilterBox,
          {
            width: windowWidth * 0.4,
            height: windowHeight * 0.7,
          }]}>
            <View
              style={{
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                marginTop: 7,
                marginRight: 7,
              }}>
              <TouchableOpacity
                onPress={() => {
                  setVisible(false);
                }}>
                <AntDesign name={'closecircle'} size={25} color={'#858C94'} />
              </TouchableOpacity>
            </View>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}>
              <Text style={{ fontSize: 26, fontFamily: 'Nunitosans-bold' }}>
                Manage Filter
              </Text>
            </View>

            <View style={[styles.ManageFilterBox1, {
              width: windowWidth * 0.35,
              height: windowHeight * 0.55,
            }]}>
              <View
                style={{
                  width: '20%',
                  marginRight: '7%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 30,
                  borderRadius: 10,
                  height: windowHeight * 0.055,
                  marginTop: 10,
                }}>
                <DropDownPicker
                  // controller={instance => controller = instance}
                  controller={(instance) => setController(instance)}
                  arrowColor={'#BDBDBD'}
                  arrowSize={23}
                  arrowStyle={{ fontWeight: 'bold' }}
                  style={{
                    width: 195,
                    backgroundColor: '#F2F3F7',
                    borderRadius: 6,
                  }}
                  itemStyle={{ justifyContent: 'flex-start' }}
                  placeholderStyle={{ color: '#B6B6B6' }}
                  //change value below (Eason)
                  items={[
                    { label: 'A', value: 1 },
                    { label: 'B', value: 2 },
                  ]}
                  placeholder={'Product Category'}
                  labelStyle={{ fontSize: 16 }}
                  onChangeItem={(selectedSort) => {
                    // setState({ sort: selectedSort }),
                    //sortingOrders(selectedSort);
                  }}
                //onOpen={() => controller1.close()}
                />
                <DropDownPicker
                  // controller={instance => controller = instance}
                  controller={(instance) => setController(instance)}
                  arrowColor={'#BDBDBD'}
                  arrowSize={23}
                  arrowStyle={{ fontWeight: 'bold' }}
                  style={{
                    width: 120,
                    backgroundColor: '#F2F3F7',
                    borderRadius: 6,
                    marginLeft: 20,
                  }}
                  itemStyle={{ justifyContent: 'flex-start' }}
                  placeholderStyle={{ color: '#B6B6B6' }}
                  //change value below (Eason)
                  items={[
                    { label: 'A', value: 1 },
                    { label: 'B', value: 2 },
                  ]}
                  placeholder={'Is'}
                  labelStyle={{ fontSize: 16 }}
                  onChangeItem={(selectedSort) => {
                    // setState({ sort: selectedSort }),
                    //sortingOrders(selectedSort);
                  }}
                //onOpen={() => controller1.close()}
                />
                <MaterialCommunityIcons
                  name={'delete-sweep'}
                  size={25}
                  color={'#858C94'}
                  style={{ marginLeft: 20 }}
                />
              </View>
              <View
                style={{
                  width: '20%',
                  marginRight: '17%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 30,
                  borderRadius: 10,
                  marginTop: 15,
                  height: windowHeight * 0.055,
                }}>
                <DropDownPicker
                  // controller={instance => controller = instance}
                  controller={(instance) => setController(instance)}
                  arrowColor={'#BDBDBD'}
                  arrowSize={23}
                  arrowStyle={{ fontWeight: 'bold' }}
                  style={{
                    width: 335,
                    backgroundColor: '#F2F3F7',
                    borderRadius: 6,
                  }}
                  itemStyle={{ justifyContent: 'flex-start' }}
                  placeholderStyle={{ color: '#B6B6B6' }}
                  //change value below (Eason)
                  items={[
                    { label: 'A', value: 1 },
                    { label: 'B', value: 2 },
                  ]}
                  placeholder={'Package A'}
                  labelStyle={{ fontSize: 16 }}
                  onChangeItem={(selectedSort) => {
                    // setState({ sort: selectedSort }),
                    //sortingOrders(selectedSort);
                  }}
                //onOpen={() => controller1.close()}
                />
              </View>
              <View
                style={{
                  marginRight: '63%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 1,
                  borderColor: '#E0E0E0',
                  backgroundColor: '#F2F3F7',
                  width: 100,
                  height: windowHeight * 0.04,
                  borderRadius: 6,
                  marginTop: 15,
                  marginBottom: 10,
                }}>
                <Text
                  style={{
                    fontSize: 16,
                    Color: '#B6B6B6',
                  }}>
                  Package A
                </Text>
                <TouchableOpacity
                  onPress={() => { }}
                  style={{
                    marginLeft: 5,
                  }}>
                  <AntDesign name={'close'} size={16} color={'#B6B6B6'} />
                </TouchableOpacity>
              </View>

              <View
                style={{
                  margin: 10,
                  borderWidth: 0.3,
                  width: 380,
                  borderColor: 'grey',
                }}>
                {/* <Text style={{
                      color:'lightgrey'
                      }}>____________</Text> */}
              </View>

              <View
                style={{
                  width: '20%',
                  marginRight: '6%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 30,
                  borderRadius: 10,
                  height: windowHeight * 0.055,
                  marginTop: 10,
                }}>
                <DropDownPicker
                  // controller={instance => controller = instance}
                  controller={(instance) => setController(instance)}
                  arrowColor={'#BDBDBD'}
                  arrowSize={23}
                  arrowStyle={{ fontWeight: 'bold' }}
                  style={{
                    width: 195,
                    backgroundColor: '#F2F3F7',
                    borderRadius: 6,
                  }}
                  itemStyle={{ justifyContent: 'flex-start' }}
                  placeholderStyle={{ color: '#B6B6B6' }}
                  //change value below (Eason)
                  items={[
                    { label: 'A', value: 1 },
                    { label: 'B', value: 2 },
                  ]}
                  placeholder={'Product Category'}
                  labelStyle={{ fontSize: 16 }}
                  onChangeItem={(selectedSort) => {
                    // setState({ sort: selectedSort }),
                    //sortingOrders(selectedSort);
                  }}
                //onOpen={() => controller1.close()}
                />
                <DropDownPicker
                  // controller={instance => controller = instance}
                  controller={(instance) => setController(instance)}
                  arrowColor={'#BDBDBD'}
                  arrowSize={23}
                  arrowStyle={{ fontWeight: 'bold' }}
                  style={{
                    width: 120,
                    backgroundColor: '#F2F3F7',
                    borderRadius: 6,
                    marginLeft: 20,
                  }}
                  itemStyle={{ justifyContent: 'flex-start' }}
                  placeholderStyle={{ color: '#B6B6B6' }}
                  //change value below (Eason)
                  items={[
                    { label: 'A', value: 1 },
                    { label: 'B', value: 2 },
                  ]}
                  placeholder={'Is'}
                  labelStyle={{ fontSize: 16 }}
                  onChangeItem={(selectedSort) => {
                    // setState({ sort: selectedSort }),
                    //sortingOrders(selectedSort);
                  }}
                //onOpen={() => controller1.close()}
                />
                <MaterialCommunityIcons
                  name={'delete-sweep'}
                  size={25}
                  color={'#858C94'}
                  style={{ marginLeft: 20 }}
                />
              </View>
              <View
                style={{
                  width: '20%',
                  marginRight: '16%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 30,
                  borderRadius: 10,
                  height: windowHeight * 0.055,
                  marginTop: 15,
                }}>
                <DropDownPicker
                  // controller={instance => controller = instance}
                  controller={(instance) => setController(instance)}
                  arrowColor={'#BDBDBD'}
                  arrowSize={23}
                  arrowStyle={{ fontWeight: 'bold' }}
                  style={{
                    width: 337,
                    backgroundColor: '#F2F3F7',
                    borderRadius: 6,
                  }}
                  itemStyle={{ justifyContent: 'flex-start' }}
                  placeholderStyle={{ color: '#B6B6B6' }}
                  //change value below (Eason)
                  items={[
                    { label: 'A', value: 1 },
                    { label: 'B', value: 2 },
                  ]}
                  placeholder={'Package A'}
                  labelStyle={{ fontSize: 16 }}
                  onChangeItem={(selectedSort) => {
                    // setState({ sort: selectedSort }),
                    //sortingOrders(selectedSort);
                  }}
                //onOpen={() => controller1.close()}
                />
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderWidth: 1,
                  borderColor: '#E0E0E0',
                  backgroundColor: '#F2F3F7',
                  width: 100,
                  height: windowHeight * 0.04,
                  borderRadius: 6,
                  marginTop: 15,
                  marginRight: '63%',
                }}>
                <Text
                  style={{
                    fontSize: 16,
                    Color: '#B6B6B6',
                  }}>
                  Package A
                </Text>
                <TouchableOpacity
                  onPress={() => { }}
                  style={{
                    marginLeft: 5,
                  }}>
                  <AntDesign name={'close'} size={16} color={'#B6B6B6'} />
                </TouchableOpacity>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                  marginTop: 25,
                  marginBottom: 10,
                }}>
                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    width: 120,
                    height: windowHeight * 0.055,
                    borderColor: '#4E9F7D',
                    borderRadius: 8,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: 160,
                  }}
                  onPress={() => { }}>
                  <Text
                    style={{
                      marginLeft: 10,
                      color: Colors.primaryColor,
                      fontSize: 16,
                      color: '#4E9F7D',
                      fontFamily: 'Nunitosans-bold',
                    }}>
                    Cancel
                  </Text>
                </TouchableOpacity>

                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    width: 120,
                    height: windowHeight * 0.055,
                    borderColor: '#4E9F7D',
                    borderRadius: 8,
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#4E9F7D',
                    marginLeft: 10,
                  }}
                  onPress={() => { }}>
                  <Text
                    style={{
                      marginLeft: 10,
                      color: Colors.primaryColor,
                      fontSize: 16,
                      color: '#FFFFFF',
                      fontFamily: 'Nunitosans-bold',
                    }}>
                    Apply
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </KeyboardAvoidingView>
      </Modal>
      {/* **********Manage FIlter Modal END *************** */}

      {/* ********************** Export Modal Start ************************ */}
      <Modal visible={exportModal} transparent={true} animationType={'slide'}>
        <View style={[styles.modalContainer1, {}]}>
          <View
            style={[
              styles.modalView,
              {
                width: 300,
                height: 240,
              },
            ]}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => {
                // setState({ changeTable: false });
                setExportModal(false);
              }}>
              <AntDesign
                name="closecircle"
                size={25}
                color={Colors.fieldtTxtColor}
              />
            </TouchableOpacity>
            <View style={styles.modalTitle}>
              <Text style={[styles.modalTitleText, { fontSize: 16 }]}>
                Export Options
              </Text>
            </View>
            <View
              style={{
                alignItems: 'center',
                top: '10%',
              }}>
              <TouchableOpacity
                style={[
                  styles.modalSaveButton,
                  {
                    zIndex: -1,
                    width: windowWidth * 0.15,
                  },
                ]}
                onPress={() => {
                  // exportFile();
                }}>
                <Text
                  style={[
                    styles.modalDescText,
                    { color: Colors.primaryColor },
                  ]}>
                  Export to CSV
                </Text>
              </TouchableOpacity>

              {/* <TouchableOpacity
                        style={[styles.modalSaveButton, {
                            zIndex: -1.
                        }]}
                        onPress={() => { exportTemplate() }}>
                        <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Export Template</Text>
                    </TouchableOpacity> */}
            </View>
          </View>
        </View>
      </Modal>

      {/* ********************** Export Modal End ************************ */}
      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ height: windowHeight, width: windowWidth * 0.9, flex: 9 }}
          contentContainerStyle={{
            paddingBottom: windowHeight * 0.1,
          }}
        >
          <KeyboardAvoidingView>
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginBottom: 10,
                  margin: 20,
                  width: windowWidth * 0.87,
                  alignSelf: 'center',
                  alignItems: 'center',
                }}>
                <View style={{ justifyContent: 'flex-start' }}>
                  <Text
                    style={[
                      { fontFamily: 'NunitoSans-Bold', fontSize: 26 },
                      switchMerchant
                        ? {
                          fontSize: 20,
                        }
                        : {},
                    ]}>
                    Promotion List
                  </Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <TouchableOpacity
                    style={[
                      {
                        justifyContent: 'center',
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: '#4E9F7D',
                        borderRadius: 5,
                        //width: 160,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: 'center',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginRight: switchMerchant ? 20 : 15,
                      },
                      switchMerchant
                        ? {
                          height: 35,
                        }
                        : {},
                    ]}
                    onPress={() => {
                      CommonStore.update((s) => {
                        s.selectedPromotionEdit = null;
                      });

                      // props.navigation.navigate('NewCampaign');
                      linkTo && linkTo(`${prefix}/newcampaign`);
                    }}>
                    <AntDesign
                      name="pluscircle"
                      size={switchMerchant ? 10 : 20}
                      style={{ color: Colors.whiteColor }}
                    />
                    <Text
                      style={[
                        {
                          color: Colors.whiteColor,
                          marginLeft: 5,
                          fontSize: 16,
                          fontFamily: 'NunitoSans-Bold',
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                          }
                          : {},
                      ]}>
                      {/* ADD PROMOTION */}
                      PROMOTION
                    </Text>
                  </TouchableOpacity>

                  {/* Type 2 Search Bar */}
                  <View
                    style={[
                      {
                        width: 250,
                        height: 40,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        shadowColor: '#000',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 3,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                      },
                      switchMerchant
                        ? {
                          width: 180,
                          height: 35,
                        }
                        : {},
                    ]}>
                    <Icon
                      name="search"
                      size={switchMerchant ? 10 : 18}
                      color={Colors.primaryColor}
                      style={{ marginLeft: 15 }}
                    />

                    <View style={{ flex: 4 }}>
                      <TextInput
                        underlineColorAndroid={Colors.whiteColor}
                        style={[
                          {
                            width: 220,
                            fontSize: 16,
                            fontFamily: 'NunitoSans-Regular',
                            paddingLeft: 5,
                            height: 45,
                          },
                          switchMerchant
                            ? {
                              width: 150,
                              height: 35,
                              fontSize: 10,
                            }
                            : {},
                        ]}
                        clearButtonMode="while-editing"
                        placeholder=" Search"
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        onChangeText={(text) => {
                          setSearch(text);
                          // setSearch(text.trim());
                        }}
                      // value={search}
                      />
                    </View>
                  </View>
                </View>
              </View>

              {/* ~~~~~~~~~~~~~~~~~List View Start Here~~~~~~~~~~~~~~~~~~~~~ */}
              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  width: windowWidth * 0.87,
                  marginTop: 0,
                  marginBottom: 10,
                  marginHorizontal: 20,
                  alignSelf: 'center',
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}>
                <View style={{ width: '100%', marginTop: 0 }}>
                  <View
                    style={{
                      backgroundColor: Colors.whiteColor,
                      paddingRight: 0,
                      paddingTop: 0,
                      height: '100%',
                      borderRadius: 5,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingVertical: 10,
                        paddingTop: 20,
                      }}>
                      {/* <View style={{ flexDirection: 'row', width: '30%', borderRightWidth: 0, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', marginLeft: 0 }}> */}

                      <View
                        style={{
                          flexDirection: 'row',
                          borderRightColor: 'lightgrey',
                          width: '13%',
                          justifyContent: 'flex-start',
                          marginLeft: 0,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={{
                              fontSize: 16,
                              color: Colors.fieldtTxtColor,
                              fontFamily: 'NunitoSans-Bold',
                            }}></Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          width: '22.5%',
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={[
                              {
                                fontSize: 14,
                                color: 'black',
                                fontFamily: 'NunitoSans-Bold',
                              },
                              windowWidth <= 1823 &&
                                windowWidth >= 1820
                                ? {
                                  marginLeft: 3,
                                }
                                : {}, windowWidth === 1280 && windowHeight === 800 ? {
                                  marginLeft: 3,
                                } : {}
                            ]}>
                            Promotion Title
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          width: '20%',
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginHorizontal: 5,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={[
                              {
                                fontSize: 14,
                                color: 'black',
                                fontFamily: 'NunitoSans-Bold',
                              },
                              windowWidth <= 1823 &&
                                windowWidth >= 1820
                                ? {
                                  marginLeft: 3,
                                }
                                : {}, windowWidth === 1280 && windowHeight === 800 ? {
                                  marginLeft: 3,
                                } : {}
                            ]}>
                            Promo Date/Time
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: 'row',
                          width: '18.5%',
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginHorizontal: 5,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={[
                              {
                                fontSize: 14,
                                color: 'black',
                                fontFamily: 'NunitoSans-Bold',
                              },
                              windowWidth <= 1823 &&
                                windowWidth >= 1820
                                ? {
                                  marginLeft: 3,
                                }
                                : {}, windowWidth === 1280 && windowHeight === 800 ? {
                                  marginLeft: 3,
                                } : {}
                            ]}>
                            Promo Code
                          </Text>
                        </View>
                      </View>

                      {/* <View style={{ flexDirection: 'row', flex: 2, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', marginHorizontal: 4 }}>
                  <View style={{ flexDirection: 'column' }}>
                    <Text style={{ fontSize: 13, color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>T&C</Text>
                  </View>
                </View> */}

                      <View
                        style={{
                          flexDirection: 'row',
                          width: '20%',
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          marginHorizontal: 3,
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={[
                              {
                                fontSize: 14,
                                color: 'black',
                                fontFamily: 'NunitoSans-Bold',
                              },
                              windowWidth <= 1823 &&
                                windowWidth >= 1820
                                ? {
                                  marginLeft: 3,
                                }
                                : {}, windowWidth === 1280 && windowHeight === 800 ? {
                                  marginLeft: 3,
                                } : {}
                            ]}>
                            Applied Outlet
                          </Text>
                        </View>
                      </View>

                      {/* <View style={{ flexDirection: 'row', flex: 0.6, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <View style={{ flexDirection: 'column' }}>
                    <Text style={{ fontSize: 13, color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>Status</Text>
                  </View>
                </View> */}

                      <View
                        style={{
                          flexDirection: 'row',
                          flex: 0.25,
                          borderRightColor: 'lightgrey',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}>
                        <View style={{ flexDirection: 'column' }}>
                          <Text
                            style={{
                              fontSize: 14,
                              color: Colors.fieldtTxtColor,
                              fontFamily: 'NunitoSans-Bold',
                            }}></Text>
                        </View>
                      </View>
                    </View>
                    {list1 ? (
                      <FlatList
                        nestedScrollEnabled={true}
                        showsVerticalScrollIndicator={false}
                        data={promotions
                          .filter((promotion) => {
                            if (search !== '') {
                              const searchLowerCase = search.toLowerCase();

                              if (
                                promotion.campaignName
                                  .toLowerCase()
                                  .includes(searchLowerCase) ||
                                promotion.campaignDescription
                                  .toLowerCase()
                                  .includes(searchLowerCase)
                              ) {
                                return true;
                              }

                              for (var i = 0; i < promotion.length; i++) {
                                if (
                                  promotion.outletNameList[i]
                                    .toLowerCase()
                                    .includes(searchLowerCase)
                                ) {
                                  return true;
                                }
                              }

                              return false;
                            } else {
                              return true;
                            }
                          })
                          .slice(
                            (currentPage - 1) * perPage,
                            currentPage * perPage,
                          )}
                        //   Search Function
                        //   data={transactionTypeSalesDetails.filter(item => {
                        //     if (search !== '') {
                        //         return item.orderType.toLowerCase().includes(search.toLowerCase());
                        //     }
                        //     else {
                        //         return true;
                        //     }
                        // }).slice((currentPage - 1) * perPage, currentPage * perPage)}
                        //extraData={renderItem}
                        renderItem={renderItem}
                        keyExtractor={(item, index) => String(index)}
                        style={{ marginTop: 10 }}
                      //initialNumToRender={4}
                      />
                    ) : null}
                    {/* {searchList ? (

                  <FlatList
                      data={lists}
                      extraData={lists}
                      renderItem={renderSearchItem}
                      keyExtractor={(item, index) => String(index)}
                      initialNumToRender={8}
                  />

              ) : null} */}
                  </View>
                </View>
              </View>
              {/* ~~~~~~~~~~~~~~~~~List View END Here~~~~~~~~~~~~~~~~~~~~~ */}

              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 10,
                  width: windowWidth * 0.87,
                  alignItems: 'center',
                  alignSelf: 'center',
                  justifyContent: 'flex-end',
                  // shadowOffset: {
                  //   width: 0,
                  //   height: 1,
                  // },
                  // shadowOpacity: 0.22,
                  // shadowRadius: 3.22,
                  // elevation: 1,
                  marginBottom: switchMerchant ? 30 : 15,
                }}>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      fontFamily: 'NunitoSans-Bold',
                      marginRight: '1%',
                    },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}>
                  Page
                </Text>
                <View
                  style={[
                    {
                      width: 65,
                      height: 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: 'center',
                      paddingHorizontal: 22,
                      borderWidth: 1,
                      borderColor: '#E5E5E5',
                    },
                    switchMerchant
                      ? {
                        width: 60,
                        height: 20,
                      }
                      : {},
                  ]}>
                  {console.log('currentPage')}
                  {console.log(currentPage)}

                  <TextInput
                    onChangeText={(text) => {
                      var currentPageTemp = text.length > 0 ? parseInt(text) : 1;

                      setCurrentPage(
                        currentPageTemp > pageCount
                          ? pageCount
                          : currentPageTemp < 1
                            ? 1
                            : currentPageTemp,
                      );
                    }}
                    placeholder={currentPage.toString()}
                    placeholderStyle={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: 'NunitoSans-Regular',
                    }}
                    placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                    style={{
                      color: 'black',
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: 'NunitoSans-Regular',
                      marginTop: Platform.OS === 'ios' ? 0 : -15,
                      marginBottom: Platform.OS === 'ios' ? 0 : -15,
                      textAlign: 'center',
                      width: '100%',
                    }}
                    value={currentPage.toString()}
                    defaultValue={currentPage.toString()}
                    keyboardType={'numeric'}
                    onFocus={() => {
                      setPushPagingToTop(true);
                    }}
                  />
                </View>
                <Text
                  style={[
                    {
                      fontSize: 14,
                      fontFamily: 'NunitoSans-Bold',
                      marginLeft: '1%',
                      marginRight: '1%',
                    },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}>
                  of {pageCount}
                </Text>
                <TouchableOpacity
                  style={[
                    {
                      width: 45,
                      height: 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                    switchMerchant
                      ? {
                        width: 30,
                        height: 20,
                      }
                      : {},
                  ]}
                  onPress={() => {
                    prevPage();
                  }}>
                  <ArrowLeft
                    color={Colors.whiteColor}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    {
                      width: 45,
                      height: 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                    switchMerchant
                      ? {
                        width: 30,
                        height: 20,
                      }
                      : {},
                  ]}
                  onPress={() => {
                    nextPage();
                  }}>
                  <ArrowRight
                    color={Colors.whiteColor}
                  />
                </TouchableOpacity>
              </View>
              <View style={{ marginTop: 20 }}></View>
            </View>
          </KeyboardAvoidingView>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
  },
  list: {
    backgroundColor: Colors.whiteColor,
    width: Dimensions.get('screen').width * 0.87,
    height: Dimensions.get('screen').height * 0.78,
    marginTop: 0,
    marginBottom: 10,
    marginHorizontal: 20,
    alignSelf: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 16,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: 'center',
  },
  titleList: {
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 20,
    //marginTop: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

    alignItems: 'center',

    // shadowOpacity: 0,
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.22,
    // shadowRadius: 3.22,
    // elevation: 3,
  },
  textTitle: {
    fontSize: 16,
    fontFamily: 'NunitoSans-Bold',
  },
  textItem: {
    fontSize: 14,
    fontFamily: 'NunitoSans-Regular',
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'center',
    // alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: 20,
    paddingTop: 15,
    marginTop: 0,
    width: '100%',

    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 7,
  },
  addVoucher: {
    marginTop: 0,
    //justifyContent: 'center',
    alignItems: 'center',
    //alignContent: 'center',
    width: Dimensions.get('screen').width * 0.78,
    backgroundColor: Colors.whiteColor,
    // marginRight: 100,

    borderRadius: 4,

    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
  ManageFilterBox: {
    width: Dimensions.get('screen').width * 0.4,
    height: Dimensions.get('screen').height * 0.7,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    //justifyContent: 'space-between'
  },
  ManageFilterBox1: {
    width: Dimensions.get('screen').width * 0.35,
    height: Dimensions.get('screen').height * 0.55,
    //borderRadius: 10,
    backgroundColor: Colors.whiteColor,
    justifyContent: 'center',
    borderColor: '#E5E5E5',
    borderWidth: 1,
    alignItems: 'center',
    alignSelf: 'center',
    //padding: 10,
    margin: 15,
  },
  headerLeftStyle: {
    width: Dimensions.get('screen').width * 0.17,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    height: 500,
    width: 415,
    backgroundColor: Colors.whiteColor,
    borderRadius: Dimensions.get('screen').width * 0.03,
    padding: 20,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  modalView1: {
    height:
      Platform.OS === 'ios'
        ? Dimensions.get('screen').width * 0.7
        : Dimensions.get('screen').width * 0.6,
    width:
      Platform.OS === 'ios'
        ? Dimensions.get('screen').width * 0.7
        : Dimensions.get('screen').width * 0.6,
    backgroundColor: Colors.whiteColor,
    borderRadius: Dimensions.get('screen').width * 0.03,
    padding: 20,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  modalContainer1: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'relative',
    alignSelf: 'flex-end',
    marginRight: -10,
    marginTop: -15,

    elevation: 1000,
    zIndex: 1000,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
  },
  modalSaveButton: {
    width: Dimensions.get('screen').width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
});

export default PromotionListScreen;
