export const CODEPAGE = {
    AUTO: 'auto',
    CP936: 'cp936', // chinese
};

export const ESCPOS_CMD = {
    CP850: '\x1b\x74\x02',
    CP936: '\x1C\x21\x00',

    NEWLINE: '\x0a',
    LEFT: '\x1b\x61\x00',
    RIGHT: '\x1b\x61\x02',
    CENTER: '\x1b\x61\x01',

    BOLD_OFF: '\x1b\x45\x00',
    BOLD_ON: '\x1b\x45\x01',

    UNDERLINE_OFF: '\x1b\x2d\x00',
    UNDERLINE_ONE_DOT_ON: '\x1b\x2d\x01',
    UNDERLINE_TWO_DOT_ON: '\x1b\x2d\x02',

    CUT_PARTIAL: '\x1d\x56\x01',
    CUT_FULL: '\x1d\x56\x00',

    SIZE_NORMAL: '\x1b\x21\x00',
    SIZE_2H: '\x1b\x21\x10',
    SIZE_2W: '\x1b\x21\x20',
    SIZE_2X: '\x1b\x21\x30',

    BARCODE_TXT_OFF: '\x1d\x48\x00',
    BARCODE_EAN13: '\x1d\x6b\x02',
    BARCODE_HEIGHT: '\x1d\x68\x64',
    BARCODE_WIDTH: '\x1d\x77\x03',

    BARCODE_FONT_A: '\x1d\x66\x00',
    BARCODE_FONT_B: '\x1d\x66\x01',
};

export const PRINTER_USAGE_TYPE = {
    RECEIPT: 'RECEIPT',
    KITCHEN_DOCKET: 'KITCHEN_DOCKET',
    ORDER_SUMMARY: 'ORDER_SUMMARY',
};

export const PRINTER_USAGE_TYPE_DROPDOWN_LIST = [
    {
        label: 'Receipt',
        value: PRINTER_USAGE_TYPE.RECEIPT,
    },
    {
        label: 'Kitchen',
        value: PRINTER_USAGE_TYPE.KITCHEN_DOCKET,
    },
    {
        label: 'Summary',
        value: PRINTER_USAGE_TYPE.ORDER_SUMMARY,
    },
];
