import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Modal,
} from "react-native";
import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
import AntDesign from "react-native-vector-icons/AntDesign";
import Ionicon from "react-native-vector-icons/Ionicons";
import SimpleLineIcons from "react-native-vector-icons/SimpleLineIcons";
import { TextInput, FlatList } from "react-native-gesture-handler";
import DropDownPicker from "react-native-dropdown-picker";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import API from "../constant/API";
import ApiClient from "../util/ApiClient";
// import {launchCamera, launchImageLibrary} from 'react-native-image-picker';
// import DialogInput from 'react-native-dialog-input';
import * as User from "../util/User";
import LoginScreen from "./LoginScreen";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import CheckBox from 'react-native-check-box';
import { color } from "react-native-reanimated";
import Close from "react-native-vector-icons/AntDesign";
// import NumericInput from 'react-native-numeric-input';
import Styles from "../constant/Styles";
import moment, { isDate } from "moment";
// import Barcode from 'react-native-barcode-builder';
// import Switch from 'react-native-switch-pro';
import Ionicons from "react-native-vector-icons/Ionicons";
// import {isTablet} from 'react-native-device-detection';
import { OutletStore } from "../store/outletStore";
import { OUTLET_SHIFT_STATUS } from "../constant/common";
import { MerchantStore } from "../store/merchantStore";
import { UserStore } from "../store/userStore";
import { CommonStore } from "../store/commonStore";
// import {openCashDrawer} from '../util/printer';
import { useWindowDimensions } from "react-native";
import AsyncImage from "../components/asyncImage";

const SettingScreen = (props) => {
  const { navigation } = props;
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const [selfCollect, setSelfCollect] = useState(true);
  const [openHourPickerVisible, setOpenHourPickerVisible] = useState(false);
  const [closeHourPickerVisible, setCloseHourPickerVisible] = useState(false);
  const [openHour, setOpenHour] = useState("");
  const [closeHour, setCloseHour] = useState("");
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked5, setIsChecked5] = useState(false);
  const [isChecked6, setIsChecked6] = useState(false);
  const [isChecked7, setIsChecked7] = useState(false);
  const [isChecked8, setIsChecked8] = useState(false);
  const [isChecked9, setIsChecked9] = useState(false);
  const [isChecked10, setIsChecked10] = useState(false);
  const [isChecked11, setIsChecked11] = useState(false);
  const [isChecked12, setIsChecked12] = useState(false);
  const [isChecked13, setIsChecked13] = useState(false);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [amount, setAmount] = useState("");
  const [hourStart, setHourStart] = useState("");
  const [hourEnd, setHourEnd] = useState("");
  const [days, setDays] = useState(false);
  const [days1, setDays1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDistance, setShowDistance] = useState("");
  const [expiryPeriod, setExpiryPeriod] = useState("");
  const [extentionCharges, setExtentionCharges] = useState("");
  const [extentionDuration, setExtentionDuration] = useState("");
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  // const [pickerMode, setPickerMode] = useState('time');
  const [pickerMode, setPickerMode] = useState("datetime");
  const [merchantDisplay, setMerchantDisplay] = useState(false);
  const [shift, setShift] = useState(true);
  const [tax, setTax] = useState(false);
  const [sample, setSample] = useState(false);
  const [redemption, setRedemption] = useState(false);
  const [redemptionList, setRedemptionList] = useState(true);
  const [redemptionAdd, setRedemptionAdd] = useState(false);
  const [order, setOrder] = useState(false);
  const [previousState, setPreviousState] = useState(false);
  const [receipt, setReceipt] = useState([]);
  const [detail, setDetail] = useState([]);
  const [merchantInfo, setMerchantInfo] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [outletInfo, setOutletInfo] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [outletId, setOutletId] = useState([]);
  const [merInfo, setMerInfo] = useState([]);
  // const [merchantId, setMerchantId] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [closingAmount, setClosingAmount] = useState("");
  const [options, setOptions] = useState([]);
  const [shift1, setShift1] = useState([]);
  const [status, setStatus] = useState(false);
  const [logo, setLogo] = useState("");
  const [cover, setCover] = useState("");
  const [name, setName] = useState("");
  const [tname, setTname] = useState("");
  const [rate, setRate] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [payment, setPayment] = useState("");
  const [time, setTime] = useState("");
  const [statue, setStatue] = useState("");
  const [status1, setStatus1] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [outletId, setOutletId] = useState(null);
  const [myTextInput, setMyTextInput] = useState(React.createRef());
  const [start_time, setStart_time] = useState(false);
  const [end_time, setEnd_time] = useState(false);
  const [rev_time, setRev_time] = useState("");
  const [category, setCategory] = useState("");
  const [close, setClose] = useState("Closed");
  const [showNote, setShowNote] = useState(false);
  const [expandView, setExpandView] = useState(false);
  const [value, setValue] = useState("");
  const [extendOption, setExtendOption] = useState([
    { optionId: 1, price: 20, day: 7, days: false },
  ]);
  const [redemptionInfo, setRedemptionInfo] = useState([]);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [alloutlet, setAlloutlet] = useState([]);
  const [discount, setDiscount] = useState("");
  const [amount1, setAmount1] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [categoryOutlet, setCategoryOutlet] = useState([]);
  const [extend, setExtend] = useState([]);
  const [outletss, setOutletss] = useState([]);
  const [redemptionDetail, setRedemptionDetail] = useState([]);
  const [outletInfo1, setOutletInfo1] = useState([]);
  const [category1, setCategory1] = useState([]);
  // const [merchantName, setMerchantName] = useState('');
  const [addOutletName, setAddOutletName] = useState("");
  const [addOutletWindow, setAddOutletWindow] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [note1, setNote1] = useState("");
  const [note2, setNote2] = useState("");
  const [note3, setNote3] = useState("");
  const [openings, setOpenings] = useState([]);
  const [editOpeningIndex, setEditOpeningIndex] = useState(0);

  //////////////////////////////////////////////////////////

  const currOutletShift = OutletStore.useState((s) => s.currOutletShift);
  const currOutletShiftStatus = OutletStore.useState(
    (s) => s.currOutletShiftStatus
  );

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);

  const merchantId = UserStore.useState((s) => s.merchantId);

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);
  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const setState = () => {};

  navigation.dangerouslyGetParent().setOptions({
    tabBarVisible: false,
  });

  navigation.setOptions({
    headerLeft: () => (
      <View
        style={{
          width: Dimensions.get("screen").width * 0.17,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            bottom: 0,
          },
        ]}
      >
        <Text
          style={{
            fontSize: 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Shift Settings
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: Dimensions.get("screen").height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get("screen").height * 0.05,
              height: Dimensions.get("screen").height * 0.05,
              borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: Dimensions.get("screen").height * 0.035,
                height: Dimensions.get("screen").height * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  const getRedemptionDetail = (param) => {
    ApiClient.GET(API.getOneSettingRedemption + param).then((result) => {
      setState({
        redemptionDetail: result,
        outletInfo1: result.outlet.name,
        category1: result.category.name,
        expiryPeriod: result.expiryPeriod,
        extentionCharges: result.extentionCharges,
        extentionDuration: result.extentionDuration,
      });
    });
  };

  // componentDidMount = () => {

  //   ApiClient.GET(API.getOutletByMerchant + User.getMerchantId()).then((result) => {
  //     setState({ outletInfo: result });
  //     result.map((element) => {
  //       setState({
  //         outletId: element.id,
  //         outletName: element.name,
  //         merchantName: element.merchant.name
  //       });
  //     });
  //   });

  //   ApiClient.GET(API.getAllSettingRedemption + User.getMerchantId()).then((result) => {
  //     setState({ redemptionInfo: result });
  //   });

  //   outlet()
  //   ApiClient.GET(API.getOutletCategory + User.getOutletId()).then((result) => {
  //     if (result !== undefined) {
  //       setState({ categoryOutlet: result });
  //     }

  //   });
  // }

  // function here
  const onCloseShiftBtn = (key) => {
    var decimal = closingAmount.split(".")[1];
    if (key >= 0 || key == ".") {
      if (closingAmount.includes("."))
        if (closingAmount.length < 12 && decimal.length < 2)
          // setState({ closingAmount: closingAmount + key });
          setClosingAmount(closingAmount + key);
      if (!closingAmount.includes(".")) {
        if (closingAmount.length < 12)
          // setState({ closingAmount: closingAmount + key });
          setClosingAmount(closingAmount + key);
      }
    } else {
      if (closingAmount.length > 0)
        // setState({ closingAmount: closingAmount.slice(0, key) });
        setClosingAmount(closingAmount.slice(0, key));
    }
  };

  const getCurrentShift = (outletId) => {
    ApiClient.GET(API.getCurrentShift + outletId).then((result) => {
      setState({ shift1: result.success });

      try {
        if (result.id != null) {
          Alert.alert(
            "",
            [
              {
                text: "OK",
                onPress: () => {},
              },
            ],
            { cancelable: false }
          );
        }
      } catch (error) {}
      
    });
  };

  const getTax = (outletId) => {
    ApiClient.GET(API.merchantTax + outletId).then((result) => {
      setState({ taxList: result });
    });
  };

  const closingShift = () => {
    var body = {
      outletId: User.getOutletId(),
      amount: closingAmount,
    };
    ApiClient.POST(API.closeShift, body, false).then((result) => {
      if (result) {
        Alert.alert(
          "Success",
          "Shift has been closed",
          [
            {
              text: "OK",
              onPress: () => {
                _logout();
                setState({
                  showModal3: false,
                  show: false,
                  showModal5: false,
                });
              },
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  const getCurrentDate = () => {
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();

    return (
      (hours < 10 ? "0" + hours : hours) +
      ":" +
      (min < 10 ? "0" + min : min) +
      ":" +
      (sec < 10 ? "0" + sec : sec) +
      "  " +
      (date < 10 ? "0" + date : date) +
      "-" +
      (month < 10 ? "0" + month : month) +
      "-" +
      year
    );
  };

  const _logout = async () => {
    await AsyncStorage.clear();
    User.setlogin(false);
    User.getRefreshMainScreen();
  };

  const openShift = () => {
    var body = {
      merchantId: merchantId,
      outletId: currOutletId,
      amount: closingAmount,
      openDate: Date.now(),
    };

    ApiClient.POST(API.openOutletShift, body, false).then((result) => {
      
      if (result && result.status === "success") {
        OutletStore.update((s) => {
          s.currOutletShiftStatus = OUTLET_SHIFT_STATUS.OPENED;
        });
        if (window.confirm("Success. Shift has been opened") == true) {
          setShowModal3(false);
          setShow(false);
          setShowModal5(false);
          console.log('open show', show);
        } else {
          console.log("You canceled!");
        }
      }
    });
  };

  const closeShift = () => {
    var body = {
      merchantId: merchantId,
      outletId: currOutletId,
      amount: closingAmount,
      closeDate: Date.now(),
    };

    ApiClient.POST(API.closeOutletShift, body, false).then((result) => {
      
      if (result && result.status === "success") {
        OutletStore.update((s) => {
          s.currOutletShiftStatus = OUTLET_SHIFT_STATUS.CLOSED;
        });
        if (window.confirm("Success. Shift has been closed") == true) {
          
          setShowModal3(false);
          setShow(false);
          setShowModal5(false);
          _logout();
        } else {
          console.log("You canceled!");
        }
      }
    });
  };

  // function end

  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    // <View style={styles.container}>
    //   <View style={styles.sidebar}>
    // Whole page view
    <View
      style={[styles.container, { height: windowHeight, width: windowWidth }]}
    >
      {/* Sidebar view */}
      <View
        style={{
          // borderWidth: 1,
          // width: Dimensions.get("screen").width * 0.9,
          // height: Dimensions.get("screen").height * 0.9,
          flex: 0.8,
        }}
      >
        <SideBar navigation={navigation} selectedTab={8} />
      </View>

      {/* <View style={{height: windowHeight, flex: 9}}> */}
      <ScrollView
        showsVerticalS
        crollIndicator={false}
        scrollEnabled={switchMerchant}
        style={{ flex: 9 }}
        contentContainerStyle={{
          paddingBottom: windowHeight * 0.025,
          backgroundColor: Colors.highlightColor,
          // backgroundColor: 'black',
          height: windowHeight,
          // borderWidth:10,
        }}
      >
        <View
          style={[
            styles.content,
            {
              height: windowHeight,
              width: windowWidth * 0.91,
              // backgroundColor: "black",
            },
          ]}
        >
          <View
            style={[
              {
                height: "100%",
                width: "100%",
                backgroundColor: Colors.whiteColor,
                // borderWidth1,
                // flex: 1,
                flexShrink: 0,
                minHeight: "85%",
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 3,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: 25,
              },
            ]}
          >
            <View
              style={{
                flex: 1,
                alignSelf: "center",
                justifyContent: "center",
                marginBottom: 10,
              }}
            >
              {currOutletShiftStatus === OUTLET_SHIFT_STATUS.OPENED && (
                <View
                  style={[
                    styles.shiftView,
                    { height: switchMerchant ? 50 : 60 },
                  ]}
                >
                  <Text style={[styles.shiftText, { fontSize: 25 }]}>
                    SHIFT OPEN
                  </Text>
                </View>
              )}

              {currOutletShiftStatus === OUTLET_SHIFT_STATUS.CLOSED && (
                <View
                  style={[
                    styles.shiftView,
                    {
                      height: 60,
                      marginTop: 160,
                    },
                  ]}
                >
                  <Text style={[styles.shiftText, { fontSize: 25 }]}>
                    SHIFT CLOSED
                  </Text>
                </View>
              )}

              <View
                style={{
                  alignSelf: "center",
                  alignItems: "center",
                  width: 200,
                  height: 40,
                  marginTop: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: switchMerchant ? 20 : 30,
                    fontWeight: "bold",
                  }}
                >
                  Powered by
                </Text>
              </View>

              <View
                style={{
                  alignSelf: "center",
                  width: switchMerchant ? 200 : 250,
                  height: switchMerchant ? 60 : 80,
                  marginTop: switchMerchant ? "0.1%" : "0.1%",
                }}
              >
                <AsyncImage
                  style={styles.headerLogo1}
                  resizeMode="contain"
                  source={require("../assets/image/logo_2.png")}
                  hideLoading={true}
                  placeholderSource
                />
              </View>
              <View
                style={{
                  alignSelf: "center",
                }}
              >
                <Text
                  style={[
                    styles.logoTxt,
                    {
                      fontSize: switchMerchant ? 15 : 20,
                      marginBottom: switchMerchant ? 5 : 40,
                    },
                  ]}
                >
                  Unlimited Perks
                </Text>
              </View>
              {currOutletShiftStatus === OUTLET_SHIFT_STATUS.OPENED && (
                <>
                  <View
                    style={{
                      alignSelf: "center",
                      alignItems: "center",
                      width: 200,
                      height: 50,
                    }}
                  >
                    <Text style={{ fontSize: 20 }}>Open at</Text>
                  </View>
                  <View
                    style={{
                      alignSelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <View style={[styles.textInput5, { height: 50 }]}>
                      <Text
                        style={{
                          paddingVertical: 16,
                          alignSelf: "center",
                          fontSize: 14,
                        }}
                      >
                        {getCurrentDate()}
                      </Text>
                    </View>
                  </View>
                  <View style={{}}>
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        //width: 160,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginTop: 20,
                      }}
                      onPress={async () => {
                        // setState({ showModal5: true });

                        // await openCashDrawer();

                        setShowModal5(true);
                      }}
                    >
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        CLOSE REGISTER
                      </Text>
                    </TouchableOpacity>
                  </View>
                </>
              )}

              {currOutletShiftStatus === OUTLET_SHIFT_STATUS.CLOSED && (
                <>
                  <View
                    style={{
                      alignSelf: "center",
                      alignItems: "center",
                      width: 200,
                      height: 40,
                    }}
                  ></View>

                  <View style={{ flex: 1 }}>
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        //width: 160,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        // marginTop: 20,
                      }}
                      onPress={async () => {
                        // setState({ showModal5: true });

                        // await openCashDrawer();

                        setShowModal5(true);
                      }}
                    >
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          //marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        OPEN REGISTER
                      </Text>
                    </TouchableOpacity>
                  </View>
                </>
              )}

              <Modal
                style={{
                  backgroundColor: Colors.primaryColor,
                  padding: 20,
                  margin: 30,
                }}
                animationType="fade"
                transparent={true}
                visible={showModal5}
                onRequestClose={() => {
                  Alert.alert(
                    "Modal has been closed",
                    [{ text: "OK", onPress: () => {} }],
                    { cancelable: false }
                  );
                }}
              >
                <View
                  style={{
                    backgroundColor: "rgba(0,0,0,0.5)",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      width: 350,
                      height: 270,
                      backgroundColor: "white",
                      borderRadius: 12,
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: switchMerchant ? 16 : 20,
                          marginBottom: 20,
                        }}
                      >
                        Confirm
                      </Text>
                      <Text
                        style={{
                          color: Colors.fieldtTxtColor,
                          fontSize: switchMerchant ? 15 : 16,
                          marginBottom: 10,
                        }}
                      >
                        {`Are you sure you want to ${
                          currOutletShiftStatus === OUTLET_SHIFT_STATUS.OPENED
                            ? "close"
                            : "open"
                        }?`}
                      </Text>
                    </View>
                    <View
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <TouchableOpacity
                        onPress={async () => {
                          // setState({
                          //   show: true,
                          // });

                          // todo
                          // await openCashDrawer();

                          setClosingAmount("");

                          setShow(true);
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: !options
                              ? Colors.whiteColor
                              : Colors.primaryColor,
                            justifyContent: "center",
                            flexDirection: "row",
                            borderWidth: 1,
                            borderColor: !options
                              ? Colors.whiteColor
                              : Colors.primaryColor,
                            borderRadius: 5,
                            width: 100,
                            paddingHorizontal: 10,
                            height: 40,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                            marginRight: 15,
                          }}
                        >
                          <Text
                            style={{
                              color: !options
                                ? Colors.fieldtTxtColor
                                : Colors.whiteColor,
                              fontSize: switchMerchant ? 15 : 16,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            YES
                          </Text>
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          // setState({
                          //   show: false,
                          //   showModal5: false,
                          // });
                          setShow(false);
                          setShowModal5(false);
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: !options
                              ? Colors.primaryColor
                              : Colors.fieldtBgColor,
                            justifyContent: "center",
                            flexDirection: "row",
                            borderWidth: 1,
                            borderColor: !options
                              ? Colors.primaryColor
                              : Colors.fieldtBgColor,
                            borderRadius: 5,
                            width: 100,
                            paddingHorizontal: 10,
                            height: 40,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                          }}
                        >
                          <Text
                            style={{
                              color: options
                                ? Colors.fieldtTxtColor
                                : Colors.primaryColor,
                              // color: fieldtTxtColor,
                              fontSize: switchMerchant ? 15 : 16,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            NO
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
                <Modal
                  style={{
                    flex: 1,
                    backgroundColor: Colors.primaryColor,
                    padding: 40,
                    margin: 50,
                    borderRadius: 12,
                  }}
                  animationType="fade"
                  transparent={false}
                  visible={show}
                  onRequestClose={() => {
                    Alert.alert(
                      "Modal has been closed",
                      [{ text: "OK", onPress: () => {} }],
                      { cancelable: false }
                    );
                  }}
                >
                  {/* <ScrollView
                      showsVerticalScrollIndicator={false}
                      style={{}}
                      contentContainerStyle={{
                        paddingBottom: Dimensions.get('screen').height * 0.1,
                        backgroundColor: Colors.whiteColor,
                      }}
                    >
                      <ScrollView horizontal={true}> */}
                  <View
                    style={[
                      styles.container,
                      {
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                      },
                    ]}
                  >
                    <TouchableOpacity
                      style={{
                        // width: '10%',
                        // backgroundColor: 'red',
                        position: "absolute",
                        top: "3%",
                        left: "2%",
                      }}
                      //   onPress={() => { props.navigation.goBack(); }}>
                      onPress={() => {
                        // goBackButton();
                        setShowModal5(false);
                        setShow(false);
                      }}
                    >
                      <View
                        style={{
                          paddingTop: 10,
                          marginBottom: 10,
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Icon
                          name="chevron-left"
                          size={switchMerchant ? 20 : 30}
                          style={{
                            color: Colors.primaryColor,
                            alignSelf: "center",
                          }}
                        />
                        <Text
                          style={{
                            fontFamily: "Nunitosans-Bold",
                            color: Colors.primaryColor,
                            fontSize: 17,
                            marginBottom: 1,
                          }}
                        >
                          Back
                        </Text>
                      </View>
                    </TouchableOpacity>
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: switchMerchant ? 30 : 45,
                          fontWeight: "bold",
                          margin: switchMerchant ? 1 : 12,
                        }}
                      >
                        {`${
                          currOutletShiftStatus === OUTLET_SHIFT_STATUS.OPENED
                            ? "Close"
                            : "Open"
                        } Shift`}
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 12 : 20,
                          color: Colors.fieldtTxtColor,
                        }}
                      >
                        {`${
                          currOutletShiftStatus === OUTLET_SHIFT_STATUS.OPENED
                            ? "Closing"
                            : "Opening"
                        } Amount`}
                      </Text>
                    </View>
                    <View style={{ justifyContent: "center" }}>
                      <Text style={{ fontSize: switchMerchant ? 30 : 130 }}>
                        {closingAmount.length === 0 ? "0" : closingAmount}
                      </Text>
                    </View>
                    <View>
                      <View style={{ width: "30%", alignSelf: "center" }}>
                        <View
                          style={{
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-between",
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(1);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                1
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(2);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                2
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(3);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                3
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(4);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                4
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(5);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                5
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(6);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                6
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(7);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                7
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(8);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                8
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(9);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                9
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(".");
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                .
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(0);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Text
                                style={[
                                  styles.pinNo,
                                  { fontSize: switchMerchant ? 15 : 20 },
                                ]}
                              >
                                0
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              onCloseShiftBtn(-1);
                            }}
                          >
                            <View
                              style={[
                                styles.pinBtn,
                                {
                                  width: switchMerchant ? 40 : 70,
                                  height: switchMerchant ? 40 : 70,
                                },
                              ]}
                            >
                              <Icon
                                name="arrow-left"
                                size={switchMerchant ? 15 : 30}
                              />
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                    <View>
                      <TouchableOpacity
                        style={{
                          justifyContent: "center",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          width: 180,
                          paddingHorizontal: 10,
                          height: switchMerchant ? 35 : 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                        }}
                        onPress={() => {
                          // setState({ showModal3: true });
                          setShowModal3(true);
                          // setOptions(true);
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 15 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          ENTER
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  {/* </ScrollView>
              </ScrollView> */}

                  <Modal
                    style={{
                      backgroundColor: Colors.primaryColor,
                      padding: 20,
                      margin: 30,
                    }}
                    animationType="fade"
                    transparent={true}
                    visible={showModal3}
                    onRequestClose={() => {
                      if (window.confirm("Modal has been closed") == true) {
                        console.log("You pressed OK!: ");
                      } else {
                        console.log("You canceled!");
                      }
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "rgba(0,0,0,0.5)",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          width: 350,
                          height: 270,
                          backgroundColor: "white",
                          borderRadius: 12,
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              fontSize: switchMerchant ? 16 : 20,
                              marginBottom: 20,
                            }}
                          >
                            Confirm
                          </Text>
                          <Text
                            style={{
                              color: Colors.fieldtTxtColor,
                              fontSize: 16,
                              marginBottom: 10,
                            }}
                          >
                            Are you sure the amount is
                          </Text>
                          <Text style={{ fontWeight: "bold", fontSize: 16 }}>
                            RM{closingAmount}
                          </Text>
                        </View>
                        <View
                          style={{
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              // setState({
                              //   showModal3: false,
                              //   options: true,
                              //   show: true,
                              // }),
                              // closingShift();

                              if (!isNaN(closingAmount)) {
                                setShowModal3(false);
                                // setOptions(true);
                                setShow(true);

                                if (
                                  currOutletShiftStatus ===
                                  OUTLET_SHIFT_STATUS.OPENED
                                ) {
                                  closeShift();
                                } else {
                                  openShift();
                                }
                              } else {
                                if (
                                  window.confirm("Info. Invalid amount.") ==
                                  true
                                ) {
                                  console.log("You pressed OK!: ");
                                } else {
                                  console.log("You canceled!");
                                }
                              }
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: !options
                                  ? Colors.whiteColor
                                  : Colors.primaryColor,
                                justifyContent: "center",
                                flexDirection: "row",
                                borderWidth: 1,
                                borderColor: !options
                                  ? Colors.whiteColor
                                  : Colors.primaryColor,
                                borderRadius: 5,
                                width: 100,
                                paddingHorizontal: 10,
                                height: 40,
                                alignItems: "center",
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                                zIndex: -1,
                                marginRight: 15,
                              }}
                            >
                              <Text
                                style={{
                                  color: !options
                                    ? Colors.fieldtTxtColor
                                    : Colors.whiteColor,
                                  fontSize: switchMerchant ? 15 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                }}
                              >
                                YES
                              </Text>
                            </View>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              // setState({
                              //   showModal3: false,
                              //   options: false,
                              // });
                              setShowModal3(false);
                              // setOptions(false);
                            }}
                          >
                            <View
                              style={{
                                backgroundColor: !options
                                  ? Colors.primaryColor
                                  : Colors.fieldtBgColor,
                                justifyContent: "center",
                                flexDirection: "row",
                                borderWidth: 1,
                                borderColor: !options
                                  ? Colors.primaryColor
                                  : Colors.fieldtBgColor,
                                borderRadius: 5,
                                width: 100,
                                paddingHorizontal: 10,
                                height: 40,
                                alignItems: "center",
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                                zIndex: -1,
                              }}
                            >
                              <Text
                                style={{
                                  color: options
                                    ? Colors.fieldtTxtColor
                                    : Colors.primaryColor,
                                  fontSize: switchMerchant ? 15 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                }}
                              >
                                NO
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </Modal>
                </Modal>
              </Modal>
            </View>
          </View>
        </View>
      </ScrollView>
      {/* </View> */}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    flexDirection: "row",
    fontFamily: "NunitoSans-Regular",
  },
  iosStyle: {
    paddingHorizontal: 30,
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  headerLogo1: {
    width: "100%",
    height: "100%",
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  listItem: {
    fontFamily: "NunitoSans-Regular",
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 30,
    width: Dimensions.get("screen").width * (1 - Styles.sideBarWidth),
    // backgroundColor: 'lightgrey',
    backgroundColor: Colors.highlightColor,
    height: Dimensions.get("screen").height * 1,
  },
  textInput: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 0,
    flex: 1,
    flexDirection: "row",
  },
  textInputLocation: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 100,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 10,
  },
  textInput8: {
    fontFamily: "NunitoSans-Regular",
    width: 75,
    height: 50,
    flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },
  textInput9: {
    fontFamily: "NunitoSans-Regular",
    width: 110,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "center",
  },
  textInput10: {
    fontFamily: "NunitoSans-Regular",
    width: 200,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "center",
  },
  textInput1: {
    fontFamily: "NunitoSans-Regular",
    width: 250,
    height: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 10,
  },
  textInput2: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginRight: 30,
  },
  textInput3: {
    fontFamily: "NunitoSans-Regular",
    width: "85%",
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    alignSelf: "center",
    paddingHorizontal: 10,
  },
  textInput4: {
    width: "85%",
    height: 70,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
  textInput5: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 50,
  },
  textInput6: {
    fontFamily: "NunitoSans-Regular",
    width: "80 %",
    padding: 16,
    backgroundColor: Colors.fieldtBgColor,
    marginRight: 30,
    borderRadius: 10,
    alignSelf: "center",
  },
  textInput7: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 80,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 7,
    marginRight: 30,
  },
  button: {
    backgroundColor: Colors.primaryColor,
    width: 150,
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 20,
  },
  button1: {
    width: "15%",
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 20,
  },
  button2: {
    backgroundColor: Colors.primaryColor,
    width: "60%",
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    marginLeft: "2%",
  },
  button3: {
    backgroundColor: Colors.primaryColor,
    width: "30%",
    height: 50,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 30,
  },
  textSize: {
    fontSize: 19,
    fontFamily: "NunitoSans-SemiBold",
  },
  viewContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 0,
    width: "100%",
    marginBottom: 15,
  },
  openHourContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginBottom: 15,
    width: "100%",
  },
  addButtonView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 200,
    height: 40,
    alignItems: "center",
  },
  addButtonView1: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: "center",
  },
  addNewView: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 65,
    marginTop: 7,
    width: "83%",
    alignSelf: "flex-end",
  },
  addNewView1: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 10,
    alignItems: "center",
  },
  merchantDisplayView: {
    flexDirection: "row",
    flex: 1,
    marginLeft: "17%",
  },
  shiftView: {
    flexDirection: "row",
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 50,
    width: 200,
    height: 60,
    alignItems: "center",
    marginTop: 30,
    alignSelf: "center",
    justifyContent: "center",
  },
  shiftText: {
    // marginLeft: '15%',
    color: Colors.primaryColor,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
  },
  closeView: {
    flexDirection: "row",
    borderRadius: 5,
    borderColor: Colors.primaryColor,
    borderWidth: 1,
    width: 200,
    height: 40,
    alignItems: "center",
    marginTop: 30,
    alignSelf: "center",
  },
  taxView: {
    flexDirection: "row",
    borderWidth: 2,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: "center",
    marginTop: 20,
    alignSelf: "flex-end",
  },
  sectionView: {
    flexDirection: "row",
    borderRadius: 5,
    padding: 16,
    alignItems: "center",
  },
  receiptView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 200,
    height: 40,
    alignItems: "center",
    alignSelf: "flex-end",
  },
  pinBtn: {
    backgroundColor: Colors.fieldtBgColor,
    width: 70,
    height: 70,
    marginBottom: 16,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  pinNo: {
    fontSize: 20,
    fontWeight: "bold",
  },
  confirmBox: {
    width: "30%",
    height: "30%",
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  logoTxt: {
    color: Colors.descriptionColor,
    fontSize: 20,
    letterSpacing: 7,
    marginTop: 10,
    alignSelf: "center",
    marginBottom: 40,
  },
});
export default SettingScreen;
