import React, { useState, Component, useEffect } from 'react';
import { StyleSheet, ScrollView, Image, Text, View, TextInput, TouchableOpacity, Alert, Modal, KeyboardAvoidingView, Dimensions, ActivityIndicator, useWindowDimensions } from 'react-native';
import firebase from 'firebase';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import API from '../constant/API';
import { CommonStore } from '../store/commonStore.js';
import { UserStore } from '../store/userStore';
import ApiClient from '../util/ApiClient';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useLinkTo, useRoute } from "@react-navigation/native";

import imgLogo from '../asset/image/logo.png';
import { DataStore } from '../store/dataStore';
import { Collections } from '../constant/firebase';
import { ORDER_TYPE } from '../constant/common';
import { prefix } from '../constant/env';
import { isMobile } from '../util/common';

const AuthScreen = props => {
    const {
        route,
    } = props;

    console.log('route');
    console.log(route);

    // this.goToLoginState = this.goToLoginState.bind(this);

    const linkTo = useLinkTo();
    const windowDimensions = useWindowDimensions();

    const [showAlertLogin, setShowAlertLogin] = useState(false);

    const email = UserStore.useState(s => s.email);
    const name = UserStore.useState(s => s.name);
    const googleId = UserStore.useState(s => s.googleId);
    const imageUrl = UserStore.useState(s => s.imageUrl);
    const tokenId = UserStore.useState(s => s.tokenId);

    const isAuthenticating = CommonStore.useState(s => s.isAuthenticating);

    useEffect(() => {
        if (linkTo) {
            DataStore.update(s => {
                s.linkToFunc = linkTo;
            });
        }

        firebase.auth().signInAnonymously()
            .then((result) => {
                const firebaseUid = result.user.uid;

                ApiClient.GET(API.getTokenKWeb + firebaseUid).then(async (result) => {
                    console.log('getTokenKWeb');
                    console.log(result);

                    if (result && result.token) {
                        await AsyncStorage.setItem('accessToken', result.token);
                        await AsyncStorage.setItem('refreshToken', result.refreshToken);

                        UserStore.update(s => {
                            s.firebaseUid = result.userId;
                            s.userId = result.userId;
                            s.role = result.role;
                            s.refreshToken = result.refreshToken;
                            s.token = result.token;
                        });

                        linkTo && linkTo(`${prefix}/`);

                        // const outletSnapshot = await firebase.firestore().collection(Collections.Outlet)
                        //     .where('uniqueId', '==', route.params.outletId)
                        //     .limit(1)
                        //     .get();

                        // var outlet = {};
                        // if (!outletSnapshot.empty) {
                        //     outlet = outletSnapshot.docs[0].data();
                        // }

                        // if (outlet) {
                        //     await AsyncStorage.setItem('latestOutletId', outlet.uniqueId);

                        //     await updateUserCart(route.params, outlet, firebaseUid);

                        //     // linkTo && linkTo('/web/outlet');
                        // }
                        // else {
                        //     linkTo && linkTo(`${prefix}/scan`);
                        // }
                    }
                    else {
                        CommonStore.update(s => {
                            s.alertObj = {
                                title: 'Error',
                                message: 'Unauthorized access',
                            };

                            // s.isAuthenticating = false;
                        });

                        linkTo && linkTo(`${prefix}/error`);
                    }
                });
            });
    }, [linkTo, route]);

    useEffect(() => {
        if (linkTo) {
            DataStore.update(s => {
                s.linkToFunc = linkTo;
            });
        }
    }, [linkTo]);

    useEffect(() => {
        CommonStore.update(s => {
            s.routeName = route.name;
        });
    }, [route]);

    useEffect(() => {
        const parent = props.navigation.dangerouslyGetParent();
        parent.setOptions({
            tabBarVisible: false,
        });
        return () =>
            parent.setOptions({
                tabBarVisible: true,
            });
    }, []);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User

                console.log('auth changed!');
                console.log(user);

                var uid = user.uid;
                // ...                
            } else {
                // User is signed out
                // ...
            }
        });
    }, []);

    // function here

    const updateUserCart = async (json, outlet, firebaseUid) => {
        const body = {
            userId: firebaseUid,
            // outletId: outlet.uniqueId,
            outletId: json.outletId,
            tableId: json.tableId,
            tableCode: json.tableCode,
            tablePax: json.tablePax,
            cartItems: [],

            waiterId: json.waiterId,
        };

        ApiClient.POST(API.updateUserCart, body).then((result) => {
            if (result && result.status === 'success') {
                CommonStore.update(s => {
                    s.selectedOutlet = outlet;

                    s.scannedQrData = json;

                    s.orderType = ORDER_TYPE.DINEIN;

                    s.selectedOutletTableId = json.tableId;
                    s.selectedOutletWaiterId = json.waiterId;
                    s.selectedOutletTablePax = json.tablePax;
                    s.selectedOutletTableCode = json.tableCode;
                }, () => {
                    if (outlet && outlet.uniqueId) {
                        // navigation.navigate('OutletMenu', { 
                        //     outletData: outlet, 
                        //     orderType: 0, 
                        //     test: 1 
                        // });

                        linkTo && linkTo(`${prefix}/outlet/menu`);
                    }

                    // if (scanner && scanner.current) {
                    //     scanner.current.reactivate();
                    // }
                });
            }
        });
    };

    // function end    

    console.log('TEST LOGIN');

    return (
        <View style={{
            width: isMobile() ? windowDimensions.width : windowDimensions.width,
            height: windowDimensions.height,
        }}>

            <View style={{
                justifyContent: 'center',
                alignItems: 'center',

                width: isMobile() ? windowDimensions.width : windowDimensions.width,
                height: windowDimensions.height,
            }}>
                <Image style={{
                    width: 300,
                    height: 67,
                    alignSelf: 'center',
                    marginBottom: 50.
                }} resizeMode="contain" source={imgLogo} />

                {
                    isAuthenticating
                        ?
                        <View style={{
                            flexDirection: 'column',
                            // alignItems: 'center',
                            // backgroundColor: 'red'
                        }}>
                            <ActivityIndicator color={Colors.primaryColor} size={'large'} />

                            <Text style={{
                                fontFamily: 'NunitoSans-Bold',
                                color: Colors.darkBgColor,
                                fontSize: 16,
                                textAlign: 'center',
                                marginTop: 15,
                            }}>
                                Processing
                            </Text>
                        </View>
                        :
                        <></>
                }
            </View>
        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    },
    headerLogo: {
        width: 112,
        height: 25
    },
    logo: {
        width: 300,
        height: 67,
        alignSelf: 'center',
        marginTop: 10,
    },
    logoTxt: {
        color: Colors.descriptionColor,
        fontSize: 20,
        letterSpacing: 7,
        marginTop: 10,
        alignSelf: 'center',
        marginBottom: 40,
        fontFamily: 'NunitoSans-Regular',
    },
    loginTxt: {
        color: Colors.mainTxtColor,
        // fontWeight: "500",
        fontSize: 26,
        fontFamily: 'NunitoSans-Bold',
    },
    description: {
        color: Colors.descriptionColor,
        paddingVertical: 10,
        fontFamily: 'NunitoSans-Regular',
        fontSize: 16,
        marginBottom: 5,
        marginTop: -5,
    },
    textInput: {
        height: 50,
        paddingHorizontal: 20,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 8,
        marginTop: 20,
        fontFamily: 'NunitoSans-Regular',
        fontSize: 16,
    },
    checkBox: {
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: Colors.descriptionColor,

        width: 30,
        height: 10,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',

        // marginRight: 5,
    },
    floatbtn: {
        zIndex: 1,
        position: 'absolute',
        bottom: 30,
        right: 30,
        width: 60,
        height: 60,
        borderRadius: 60 / 2,
        backgroundColor: Colors.primaryColor,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 3
    },
    loginImg: {
        width: undefined,
        height: '100%',
        resizeMode: 'cover'
    },
    resetContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 0,
        alignSelf: 'center'
    },

    switchContainer: {
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },

    centerText: {
        padding: 10,
        fontSize: 18,
        color: Colors.descriptionColor,
        textAlign: 'center',
        fontFamily: 'NunitoSans-Regular',
    },
    scanText: {
        fontSize: 20,
        color: '#000000',
        textAlign: 'center',
        backgroundColor: '#008000'
    },
})

export default AuthScreen;