import React, { Component, useReducer, useState, useEffect } from 'react';
import {
    StyleSheet,
    ScrollView,
    Image,
    View,
    Text,
    Alert,
    TouchableOpacity,
    TextInput,
    Dimensions,
    FlatList,
    Modal,
    PermissionsAndroid,
    ActivityIndicator,
    KeyboardAvoidingView,
    Picker,
    useWindowDimensions,
} from 'react-native';
import Colors from '../constant/Colors';
import SideBar from './SideBar';
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Feather';
import Icon1 from 'react-native-vector-icons/FontAwesome';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/EvilIcons';
import DropDownPicker from 'react-native-dropdown-picker';
// import { ceil } from 'react-native-reanimated';
import DateTimePicker from 'react-native-modal-datetime-picker';
import moment from 'moment';
import Close from 'react-native-vector-icons/AntDesign';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Styles from '../constant/Styles';
import Ionicon from 'react-native-vector-icons/Ionicons';
import 'react-native-get-random-values';
import { customAlphabet } from 'nanoid';
import { v4 as uuidv4 } from 'uuid';
import { CommonStore } from '../store/commonStore';
import { MerchantStore } from '../store/merchantStore';
import {
    PURCHASE_ORDER_STATUS,
    PURCHASE_ORDER_STATUS_PARSED,
    EMAIL_REPORT_TYPE,
} from '../constant/common';
import { UserStore } from '../store/userStore';
import {
    convertArrayToCSV,
    uploadImageToFirebaseStorage,
    generateEmailReport,
} from '../util/common';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { Platform } from 'react-native';
import Autocomplete from "react-google-autocomplete";
import AsyncImage from '../components/asyncImage';
import XLSX from 'xlsx';
import { useFilePicker } from "use-file-picker";

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 12);
//const RNFS = require('react-native-fs');
//import {writeFile, readFile, DocumentDirectoryPath} from 'react-native-fs';


const SupplierScreen = (props) => {
    const { navigation } = props;

    const { height: windowHeight, width: windowWidth } = useWindowDimensions();
    const [exportModalVisibility, setExportModalVisibility] = useState();
    const [importTemplate, setImportTemplate] = useState();

    const [myTextInput, setMyTextInput] = useState(React.createRef());
    const [purchaseOrder, setPurchaseOrder] = useState(true);
    const [addStockTake, setAddStockTake] = useState(false);
    const [addPurchase, setAddPurchase] = useState(false);
    const [editPurchase, setEditPurchase] = useState(false);
    const [addStockTransfer, setAddStockTransfer] = useState(false);
    const [stockList, setStockList] = useState([]);
    const [stockTransferList, setStockTransferList] = useState([]);
    const [stockTakeList, setStockTakeList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [itemsToOrder, setItemsToOrder] = useState([{}]);
    const [itemsToOrder2, setItemsToOrder2] = useState([{}, {}, {}]);
    const [addStockTransferList, setAddStockTransferList] = useState([
        {},
        {},
        {},
    ]);
    const [addCountedStockTakeList, setAddCountedStockTakeList] = useState([
        {},
        {},
        {},
    ]);
    const [addUnCountedStockTakeList, setAddUnCountedStockTakeList] = useState([
        {},
        {},
        {},
    ]);
    const [productList, setProductList] = useState([]);
    const [isSelected, setIsSelected] = useState(false);
    const [isSelected2, setIsSelected2] = useState(false);
    const [isSelected3, setIsSelected3] = useState(true);
    const [isSelected4, setIsSelected4] = useState(false);
    const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
    const [date, setDate] = useState(Date.now());
    const [visible, setVisible] = useState(false);
    const [Email, setEmail] = useState(null);
    const [modal, setModal] = useState(false);
    const [outletId, setOutletId] = useState(User.getOutletId());
    const [search, setSearch] = useState('');
    // const [search2, setSearch2] = useState('');
    const [search3, setSearch3] = useState('');
    const [ideal, setIdeal] = useState('');
    const [minimum, setMinimum] = useState('');
    const [itemId, setItemId] = useState('');
    const [choose, setChoose] = useState(null);

    const [loading, setLoading] = useState(false);
    const [choice, setChoice] = useState('Print P.O');
    const [choice7, setChoice7] = useState('1');
    const [choice8, setChoice8] = useState('1');
    const [choice4, setChoice4] = useState('Chicken patty');

    const [controller, setController] = useState({});

    const [exportModal, setExportModal] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [exportEmail, setExportEmail] = useState('');

    const [excelData, setExcelData] = useState([]);

    const [isLoadingExcel, setIsLoadingExcel] = useState(false);
    const [isLoadingCsv, setIsLoadingCsv] = useState(false);
    const [switchMerchant, setSwitchMerchant] = useState(false);
    const [search2, setSearch2] = useState('');

    ///////////////////////////////////////////////////////////////////

    const [supplierName, setSupplierName] = useState('');
    const [supplierRegistrationName, setSupplierRegistrationName] = useState('');
    const [supplierRegistrationNumber, setSupplierRegistrationNumber] =
        useState('');
    const [supplierAddress, setSupplierAddress] = useState('');
    const [supplierAddressLat, setSupplierAddressLat] = useState(0);
    const [supplierAddressLng, setSupplierAddressLng] = useState(0);
    const [supplierEmail, setSupplierEmail] = useState('');
    const [supplierPhone, setSupplierPhone] = useState('');
    const [supplierTax, setSupplierTax] = useState(0);
    const [supplierPIC, setSupplierPIC] = useState(''); //supplier Person In Charge

    const [supplierItems, setSupplierItems] = useState([
        {
            image: '',
            supplyItemId: '',
            name: '',
            sku: '',
            skuMerchant: '',
            unit: '',
            price: 0,
        },
    ]);

    const [picItems, setPicItems] = useState([
        {
            // picId: '',
            name: '',
            phone: '',
            email: '',
        },
    ]);

    const [paymentItems, setPaymentItems] = useState([
        {
            // picId: '',
            bankAccountName: '',
            bankAccountNumber: '',
            bankName: '',
            bankBranch: '',
        },
    ]);

    ///////////////////////////////////////////////////////////////////

    const OTHER_OPTIONS = {
        PERSON_IN_CHARGE: 'OTHER_OPTIONS.PERSON_IN_CHARGE',
        SUPPLY_ITEMS: 'OTHER_OPTIONS.SUPPLY_ITEMS',
        PAYMENT_DETAILS: 'OTHER_OPTIONS.PAYMENT_DETAILS',
        HISTORY: 'OTHER_OPTIONS.HISTORY',
    };

    const [OtherOptions, setOtherOptions] = useState(
        OTHER_OPTIONS.PERSON_IN_CHARGE,
    );

    ///////////////////////////////////////////////////////////////////

    const [openFileSelector, { plainFiles, filesContent, loading: loadingImageInput, clear: clearImageContainer, errors }] = useFilePicker({
        readAs: 'DataURL',
        accept: ["image/*"],
        multiple: false,
    });
    ///////////////////////////////////////////////////////////////////

    const [poId, setPoId] = useState('');

    const [supplierDropdownList, setSupplierDropdownList] = useState([]);
    const [selectedSupplierId, setSelectedSupplierId] = useState('');

    const [poStatus, setPoStatus] = useState(PURCHASE_ORDER_STATUS.CREATED);

    const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
    const [selectedTargetOutletId, setSelectedTargetOutletId] = useState('');

    const [supplyItemDropdownList, setSupplyItemDropdownList] = useState([]);

    const [poItems, setPoItems] = useState([
        {
            supplyItemId: '',
            name: '',
            sku: '',
            skuMerchant: '',
            quantity: 0,
            orderQuantity: 0,
            receivedQuantity: 0,
            price: 0,
            totalPrice: 0,
        },
    ]);

    const [selectedSupplier, setSelectedSupplier] = useState({
        //taxRate: 0,
    });

    const [subtotal, setSubtotal] = useState(0);
    const [taxTotal, setTaxTotal] = useState(0);
    const [discountTotal, setDiscountTotal] = useState(0);
    const [finalTotal, setFinalTotal] = useState(0);

    //const [unitDropdownList, setUnitDropdownList] = useState([]);

    const [expandViewDict, setExpandViewDict] = useState({});

    const outletSupplyItemsSkuDict = CommonStore.useState(
        (s) => s.outletSupplyItemsSkuDict,
    );

    const suppliersDict = CommonStore.useState((s) => s.suppliersDict);
    const supplyItems = CommonStore.useState((s) => s.supplyItems);
    const supplyItemsDict = CommonStore.useState((s) => s.supplyItemsDict);
    const suppliers = CommonStore.useState((s) => s.suppliers);
    const allOutlets = MerchantStore.useState((s) => s.allOutlets);
    const merchantId = UserStore.useState((s) => s.merchantId);
    const purchaseOrders = CommonStore.useState((s) => s.purchaseOrders);
    const isLoading = CommonStore.useState((s) => s.isLoading);

    const userName = UserStore.useState((s) => s.name);
    const merchantName = MerchantStore.useState((s) => s.name);

    const selectedSupplierEdit = CommonStore.useState(
        (s) => s.selectedSupplierEdit,
    );

    const routeParams = CommonStore.useState((s) => s.routeParams);

    const outletSelectDropdownView = CommonStore.useState(
        (s) => s.outletSelectDropdownView,
    );

    // const [renderSelectedSupplyPO, setRenderSelectedSupplyPO] = useState([{

    // }]);

    // tab
    // const [customerDetailsSection, setCustomerDetailsSection] = useState(CUSTOMER_DETAILS_SECTION.VISIT_TIMELINE);

    const CUSTOMER_DETAILS_SECTION = {
        VISIT_TIMELINE: 'CUSTOMER_DETAILS_SECTION.VISIT_TIMELINE',
        PURCHASED_HISTORY: 'CUSTOMER_DETAILS_SECTION.PURCHASED_HISTORY',
        QUESTIONAIRE: 'CUSTOMER_DETAILS_SECTION.QUESTIONAIRE',
        CREDIT_POINTS: 'CUSTOMER_DETAILS_SECTION.CREDIT_POINTS',
        E_INDEMNITY: 'CUSTOMER_DETAILS_SECTION.E_INDEMNITY',
        REDEMPTION_HISTORY: 'CUSTOMER_DETAILS_SECTION.REDEMPTION_HISTORY',
    };

    ////////////////////////////////////////////////

    useEffect(() => {
        if (selectedSupplierEdit) {
            // insert info

            setSupplierEmail(selectedSupplierEdit.email);
            setSupplierName(selectedSupplierEdit.name);
            setSupplierPIC(selectedSupplierEdit.pic);
            setSupplierPhone(selectedSupplierEdit.phone);
            setSupplierTax(selectedSupplierEdit.taxRate);

            if (selectedSupplierEdit.uniqueId) {
                var tempSupplierItems = [];

                for (var i = 0; i < supplyItems.length; i++) {
                    if (supplyItems[i].supplierId === selectedSupplierEdit.uniqueId) {
                        tempSupplierItems.push({
                            supplyItemId: supplyItems[i].uniqueId,
                            name: supplyItems[i].name,
                            sku: supplyItems[i].sku,
                            skuMerchant: supplyItems[i].skuMerchant,
                            unit: supplyItems[i].unit,
                            price: supplyItems[i].price,
                            image: supplyItems[i].image ? supplyItems[i].image : '',
                        });
                    }
                }

                setSupplierItems(tempSupplierItems);
            }

            if (selectedSupplierEdit.picItems) {
                setPicItems(selectedSupplierEdit.picItems);
            } else {
                setPicItems([
                    {
                        // picId: '',
                        name: '',
                        phone: '',
                        email: '',
                    },
                ]);
            }

            if (selectedSupplierEdit.paymentItems) {
                setPaymentItems(selectedSupplierEdit.paymentItems);
            } else {
                setPaymentItems([
                    {
                        bankAccountName: '',
                        bankAccountNumber: '',
                        bankName: '',
                        bankBranch: '',
                    },
                ]);
            }

            setSupplierRegistrationName(
                selectedSupplierEdit.supplierRegistrationName
                    ? selectedSupplierEdit.supplierRegistrationName
                    : '',
            );
            setSupplierRegistrationNumber(
                selectedSupplierEdit.supplierRegistrationNumber
                    ? selectedSupplierEdit.supplierRegistrationNumber
                    : '',
            );
            setSupplierAddress(
                selectedSupplierEdit.supplierAddress
                    ? selectedSupplierEdit.supplierAddress
                    : '',
            );
            setSupplierAddressLat(
                selectedSupplierEdit.supplierAddressLat
                    ? selectedSupplierEdit.supplierAddressLat
                    : 0,
            );
            setSupplierAddressLng(
                selectedSupplierEdit.supplierAddressLng
                    ? selectedSupplierEdit.supplierAddressLng
                    : 0,
            );

            if (routeParams.pageFrom === 'Inventory') {
                setPurchaseOrder(false);
                setAddPurchase(true);

                CommonStore.update((s) => {
                    s.routeParams = {};
                });
            }
        } else {
            // designed to always mounted, thus need clear manually...

            setSupplierEmail('');
            setSupplierName('');
            setSupplierPIC('');
            setSupplierPhone('');
            setSupplierTax(0);

            setSupplierItems([
                {
                    supplyItemId: '',
                    name: '',
                    sku: '',
                    skuMerchant: '',
                    unit: '',
                    price: 0,
                    image: '',
                },
            ]);

            setPicItems([
                {
                    // picId: '',
                    name: '',
                    phone: '',
                    email: '',
                },
            ]);

            setPaymentItems([
                {
                    bankAccountName: '',
                    bankAccountNumber: '',
                    bankName: '',
                    bankBranch: '',
                },
            ]);

            setSupplierRegistrationName('');
            setSupplierRegistrationNumber('');
            setSupplierAddress('');
            setSupplierAddressLat(0);
            setSupplierAddressLng(0);

            // if (supplyItems.length > 0 && Object.keys(outletSupplyItemsSkuDict).length > 0) {
            //   setPoItems([
            //     {
            //       supplyItemId: supplyItems[0].uniqueId,
            //       name: supplyItems[0].name,
            //       sku: supplyItems[0].sku,
            //       quantity: outletSupplyItemsSkuDict[supplyItems[0].sku] ? outletSupplyItemsSkuDict[supplyItems[0].sku].quantity : 0, // check if the supply item sku for this outlet existed
            //       orderQuantity: 0,
            //       receivedQuantity: 0,
            //       price: supplyItems[0].price,
            //       totalPrice: 0,
            //     }
            //   ]);

            // }
            // else {
            //   setPoItems([
            //     {
            //       supplyItemId: '',
            //       name: '',
            //       sku: '',
            //       quantity: 0,
            //       orderQuantity: 0,
            //       receivedQuantity: 0,
            //       price: 0,
            //       totalPrice: 0,
            //     }
            //   ]);
            // }
        }
    }, [selectedSupplierEdit, addPurchase, routeParams, suppliers]);

    useEffect(() => {
        setSupplierDropdownList(
            suppliers.map((supplier) => ({
                label: supplier.name,
                value: supplier.uniqueId,
            })),
        );

        if (selectedSupplierId === '' && suppliers.length > 0) {
            setSelectedSupplierId(suppliers[0].uniqueId);
        }
    }, [suppliers]);

    useEffect(() => {
        setTargetOutletDropdownList(
            allOutlets.map((outlet) => ({
                label: outlet.name,
                value: outlet.uniqueId,
            })),
        );

        if (selectedTargetOutletId === '' && allOutlets.length > 0) {
            setSelectedTargetOutletId(allOutlets[0].uniqueId);
        }
    }, [allOutlets]);

    useEffect(() => {
        setSupplyItemDropdownList(
            supplyItems.map((supplyItem) => ({
                label: supplyItem.name,
                value: supplyItem.uniqueId,
            })),
        );

        if (
            supplyItems.length > 0 &&
            poItems.length === 1 &&
            poItems[0].supplyItemId === ''
        ) {
            setPoItems([
                {
                    supplyItemId: supplyItems[0].uniqueId,
                    name: supplyItems[0].name,
                    sku: supplyItems[0].sku,
                    skuMerchant: supplyItems[0].skuMerchant,
                    quantity: outletSupplyItemsSkuDict[supplyItems[0].sku]
                        ? outletSupplyItemsSkuDict[supplyItems[0].sku].quantity
                        : 0, // check if the supply item sku for this outlet existed
                    orderQuantity: 0,
                    receivedQuantity: 0,
                    price: supplyItems[0].price,
                    totalPrice: 0,
                },
            ]);
        } else if (
            poItems[0].supplyItemId !== '' &&
            Object.keys(supplyItemsDict).length > 0
        ) {
            var poItemsTemp = [...poItems];

            for (var i = 0; i < poItemsTemp.length; i++) {
                const supplyItem = supplyItemsDict[poItemsTemp[i].supplyItemId];

                poItemsTemp[i] = {
                    ...poItemsTemp[i],
                    quantity: outletSupplyItemsSkuDict[supplyItem.sku]
                        ? outletSupplyItemsSkuDict[supplyItem.sku].quantity
                        : 0, // check if the supply item sku for this outlet existed | might changed in real time
                    price: supplyItem.price, // might changed in real time
                };
            }

            setPoItems(poItemsTemp);
        }
    }, [
        supplyItems,
        supplyItemsDict,
        // outletSupplyItemsSkuDict
    ]);

    useEffect(() => {
        if (suppliers.length > 0 && selectedSupplierId !== '') {
            setSelectedSupplier(
                suppliers.find((supplier) => supplier.uniqueId === selectedSupplierId),
            );
        }
    }, [suppliers, selectedSupplierId]);

    useEffect(() => {
        console.log('subtotal');
        console.log(
            poItems.reduce((accum, poItem) => accum + poItem.totalPrice, 0),
        );
        setSubtotal(
            poItems.reduce((accum, poItem) => accum + poItem.totalPrice, 0),
        );
    }, [poItems]);

    useEffect(() => {
        console.log('taxTotal');
        console.log(subtotal * selectedSupplier.taxRate);
        setTaxTotal(subtotal * selectedSupplier.taxRate);
    }, [subtotal]);

    useEffect(() => {
        console.log('finalTotal');
        console.log(subtotal - discountTotal + taxTotal);
        setFinalTotal(subtotal - discountTotal + taxTotal);
    }, [subtotal, discountTotal, taxTotal]);

    useEffect(() => {
        requestStoragePermission();

        setPoId(nanoid());
    }, []);

    const setState = () => { };

    // navigation.dangerouslyGetParent().setOptions({
    //   tabBarVisible: false,
    // });

    navigation.setOptions({
        headerLeft: () => (
            <View style={styles.headerLeftStyle}>
                <Image
                    style={{
                        width: 124,
                        height: 26,
                    }}
                    resizeMode="contain"
                    source={require('../assets/image/logo.png')}
                />
            </View>
        ),
        headerTitle: () => (
            <View
                style={[{
                    justifyContent: 'center',
                    alignItems: 'center',
                    // bottom: -2,
                    bottom: switchMerchant ? '2%' : 0,
                }, Dimensions.get('screen').width >= 768 && switchMerchant ? { right: Dimensions.get('screen').width * 0.1 } : {}, Dimensions.get('screen').width <= 768 ? { right: Dimensions.get('screen').width * 0.12 } : {}]}>
                <Text
                    style={{
                        fontSize: switchMerchant ? 20 : 24,
                        // lineHeight: 25,
                        textAlign: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        color: Colors.whiteColor,
                        opacity: 1,
                    }}>
                    Supplier
                </Text>
            </View>
        ),
        headerRight: () => (
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                {outletSelectDropdownView()}
                <View
                    style={{
                        backgroundColor: 'white',
                        width: 0.5,
                        height: Dimensions.get('screen').height * 0.025,
                        opacity: 0.8,
                        marginHorizontal: 15,
                        bottom: -1,
                    }}></View>
                <TouchableOpacity
                    onPress={() => navigation.navigate('Setting')}
                    style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                        style={{
                            fontFamily: 'NunitoSans-SemiBold',
                            fontSize: 16,
                            color: Colors.secondaryColor,
                            marginRight: 15,
                        }}>
                        {userName}
                    </Text>
                    <View
                        style={{
                            marginRight: 30,
                            width: Dimensions.get('screen').height * 0.05,
                            height: Dimensions.get('screen').height * 0.05,
                            borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                        }}>
                        <Image
                            style={{
                                width: Dimensions.get('screen').height * 0.035,
                                height: Dimensions.get('screen').height * 0.035,
                                alignSelf: 'center',
                            }}
                            source={require('../assets/image/profile-pic.jpg')}
                        />
                    </View>
                </TouchableOpacity>
            </View>
        ),
    });

    // componentDidMount = () => {
    //   // setInterval(() => {
    //   //   getStockOrder()
    //   //   getStockTransfer()
    //   //   getLowStock()
    //   // }, 1000);
    //   getStockOrder();

    // }

    // async componentWillMount = () => {
    //   await requestStoragePermission()
    // }
    const renderSearch = (item) => {
        return (
            <View style={{ flexDirection: 'column' }}>
                <Text style={{ fontWeight: '700', fontSize: 14, marginBottom: 5 }}>
                    {item.structured_formatting.main_text}
                </Text>
                <Text style={{ fontSize: 12, color: 'grey' }}>{item.description}</Text>
            </View>
        );
    };

    const requestStoragePermission = async () => {
        try {
            const granted = await PermissionsAndroid.request(
                PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
                {
                    title: 'KooDoo Merchant Storage Permission',
                    message: 'KooDoo Merchant App needs access to your storage ',
                    buttonNegative: 'Cancel',
                    buttonPositive: 'OK',
                },
            );
            if (granted === PermissionsAndroid.RESULTS.GRANTED) {
                console.log('Storage permission granted');
            } else {
                console.log('Storage permission denied');
            }
        } catch (err) {
            console.warn(err);
        }
    };

    const importCSV = () => {
        /* try {
          const res = DocumentPicker.pick({
            type: [DocumentPicker.types.csv],
          });
          console.log(res.uri, res.type, res.name, res.size);
        } catch (err) {
          if (DocumentPicker.isCancel(err)) {
            // User cancelled the picker, exit any dialogs or menus and move on
          } else {
            throw err;
          }
        } */
    };

    const expandOrderFunc = (param) => {
        /* if (expandOrder == false) {
            // return setState({ expandOrder: true }), param.expandOrder = true;
            setExpandOrder(true);
            setExpandViewDict({
                ...expandViewDict,
                [param.uniqueId]: true,
            });
            expandViewDict;
        } else {
            // return setState({ expandOrder: false }), param.expandOrder = false;
            setExpandOrder(false);
            setExpandViewDict({
                ...expandViewDict,
                [param.uniqueId]: undefined,
            });
        } */
    };

    const convertTemplateToExcelFormat = () => {
        var excelTemplate = [];

        for (var i = 0; i < supplyItems.length; i++) {
            // if (i <= 5) {
            var excelColumn = {
                'Supplier Name': suppliersDict[supplyItems[i].supplierId].name,
                'Item Name': supplyItems[i].name,
                SKU: supplyItems[i].skuMerchant,
                Unit: supplyItems[i].unit,
                Price: supplyItems[i].price,
            };
            excelTemplate.push(excelColumn);
        }
        // for (var i = 0; i < supplyItemsDict.length; i++) {
        //   // if (i <= 5) {
        //     var excelColumn = {
        //       'Supplier Name': supplyItemsDict[supplyItems[i].supplierId].name,
        //       // 'Item Name': supplyItems[i].name,
        //       // 'SKU': supplyItems[i].skuMerchant,
        //       // 'Unit': supplyItems[i].unit,
        //       // 'Price': supplyItems[i].price,
        //     };
        //     excelTemplate.push(excelColumn);

        //    }

        console.log('excelTemplate');
        console.log(excelTemplate);

        return excelTemplate;
    };

    const downloadExcel = () => {
        /* const excelTemplate = convertTemplateToExcelFormat();
    
        var excelFile = `${
          Platform.OS === 'ios'
            ? RNFS.DocumentDirectoryPath
            : RNFS.DownloadDirectoryPath
        }/koodoo-Inventory-Supplier-Items${moment().format(
          'YYYY-MM-DD-HH-mm-ss',
        )}.xlsx`;
        var excelWorkSheet = XLSX.utils.json_to_sheet(excelTemplate);
        var excelWorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          excelWorkBook,
          excelWorkSheet,
          'Supplier Items',
        );
    
        const workBookData = XLSX.write(excelWorkBook, {
          type: 'binary',
          bookType: 'xlsx',
        });
    
        RNFS.writeFile(excelFile, workBookData, 'ascii')
          .then((success) => {
            console.log(`wrote file ${excelFile}`);
    
            Alert.alert(
              'Success',
              `Send to ${excelFile}`,
              [{text: 'OK', onPress: () => {}}],
              {cancelable: false},
            );
          })
          .catch((err) => {
            console.log(err.message);
          }); */
    };

    const downloadCsv = () => {
        /* if (suppliers) {
          const csvData = convertArrayToCSV(suppliers);
    
          const pathToWrite = `${
            RNFetchBlob.fs.dirs.DownloadDir
          }/koodoo-report-supplier-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
          console.log('PATH', pathToWrite);
          RNFetchBlob.fs
            .writeFile(pathToWrite, csvData, 'utf8')
            .then(() => {
              console.log(`wrote file ${pathToWrite}`);
              // wrote file /storage/emulated/0/Download/data.csv
              Alert.alert(
                'Success',
                `Send to ${pathToWrite}`,
                [{text: 'OK', onPress: () => {}}],
                {cancelable: false},
              );
            })
            .catch((error) => console.error(error));
        } */

        // var body = {
        //   data: orderList
        // }
        // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
        //   console.log("RESULT", result)
        //   if (result !== null) {
        //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/POData.csv`;
        //     console.log("PATH", pathToWrite)
        //     RNFetchBlob.fs
        //       .writeFile(pathToWrite, result, 'utf8')
        //       .then(() => {
        //         console.log(`wrote file ${pathToWrite}`);
        //         // wrote file /storage/emulated/0/Download/data.csv
        //         Alert.alert(
        //           'Success',
        //           'The data had exported',
        //           [{ text: 'OK', onPress: () => { } }],
        //           { cancelable: false },
        //         );
        //       })
        //       .catch(error => console.error(error));
        //   }
        // });
    };

    // //error show readAsArrayBuffer not implemented
    // const importTemplate = (file) => {
    //   const promise = new Promise((resolve, reject) => {
    //     const fileReader = new FileReader();
    //     fileReader.readAsArrayBuffer(file);

    //     fileReader.onload = (e) => {
    //       const bufferArray = e.target.result;

    //       const wb = XLSX.read(bufferArray, { type: "buffer" });

    //       const wsname = wb.SheetNames[0];

    //       const ws = wb.Sheets[wsname];

    //       const data = XLSX.utils.sheet_to_json(ws);

    //       resolve(data);
    //     };

    //     fileReader.onerror = (error) => {
    //       reject(error);
    //     };
    //   });

    //   promise.then((d) => {
    //     console.log(d);
    //   });
    // }

    // const exportFunc = () => {
    //   if (suppliers) {
    //     const csvData = convertArrayToCSV(suppliers);

    //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-supplier-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
    //     console.log("PATH", pathToWrite)
    //     RNFetchBlob.fs
    //       .writeFile(pathToWrite, csvData, 'utf8')
    //       .then(() => {
    //         console.log(`wrote file ${pathToWrite}`);
    //         // wrote file /storage/emulated/0/Download/data.csv
    //         Alert.alert(
    //           'Success',
    //           `Send to ${pathToWrite}`,
    //           [{ text: 'OK', onPress: () => { } }],
    //           { cancelable: false },
    //         );
    //       })
    //       .catch(error => console.error(error));
    //   }

    // var body = {
    //   data: orderList
    // }
    // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
    //   console.log("RESULT", result)
    //   if (result !== null) {
    //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/POData.csv`;
    //     console.log("PATH", pathToWrite)
    //     RNFetchBlob.fs
    //       .writeFile(pathToWrite, result, 'utf8')
    //       .then(() => {
    //         console.log(`wrote file ${pathToWrite}`);
    //         // wrote file /storage/emulated/0/Download/data.csv
    //         Alert.alert(
    //           'Success',
    //           'The data had exported',
    //           [{ text: 'OK', onPress: () => { } }],
    //           { cancelable: false },
    //         );
    //       })
    //       .catch(error => console.error(error));
    //   }
    // });
    // }

    const getStockOrder = () => {
        // ApiClient.GET(API.getStockOrder + outletId).then((result) => {
        //   setState({ orderList: result })
        // });
    };

    // function here
    const showDateTimePicker = () => {
        // setState({ isDateTimePickerVisible: true });
        setIsDateTimePickerVisible(true);
    };

    const hideDateTimePicker = () => {
        // setState({ isDateTimePickerVisible: false });
        setIsDateTimePickerVisible(false);
    };

    const handleDatePicked = (date) => {
        // setState({ date: date.toString() });
        setDate(date);
    };

    /*  const handleChoosePhoto = (index) => {
         const imagePickerOptions = {
             mediaType: 'photo',
             quality: 0.5,
             includeBase64: false,
         };
 
         launchImageLibrary(imagePickerOptions, (response) => {
             if (response.didCancel) {
             } else if (response.error) {
                 Alert.alert(response.error.toString());
             } else {
                 // setImage(response.uri);
                 // setImageType(response.uri.slice(response.uri.lastIndexOf('.')));
                 // setIsImageChanged(true);
 
                 setSupplierItems(
                     supplierItems.map((supplierItem, i) =>
                         i === index
                             ? {
                                 ...supplierItem,
                                 image: response.uri,
                                 imageType: response.uri.slice(response.uri.lastIndexOf('.')),
                                 isImageChanged: true,
                             }
                             : supplierItem,
                     ),
                 );
             }
         });
     }; */


    const renderOrderItem = ({ item, index }) => {
        console.log('renderOrderItem');
        console.log(item);

        return (
            <TouchableOpacity
                onPress={() => {
                    // setState({
                    //   lowStockAlert: false,
                    //   purchaseOrder: false,
                    //   stockTransfer: false,
                    //   stockTake: false,
                    //   addPurchase: false,
                    //   editPurchase: true,
                    //   addStockTransfer: false,
                    //   addStockTake: false,
                    // });

                    // disable first
                    // setEditPurchase(true);
                    // setPurchaseOrder(false);

                    CommonStore.update((s) => {
                        s.selectedSupplierEdit = item;
                    });

                    setPurchaseOrder(false);
                    setAddPurchase(true);
                }}>
                <View
                    style={{
                        backgroundColor: '#ffffff',
                        flexDirection: 'row',
                        paddingVertical: 20,
                        paddingHorizontal: 15,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                        borderBottomColor: '#c4c4c4',
                        borderBottomLeftRadius: 5,
                        borderBottomRightRadius: 5,
                    }}>
                    <Text
                        style={{ width: '24%', color: Colors.primaryColor, marginRight: 3, fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', }}>
                        {`${item.name}`}
                    </Text>
                    <Text style={{ width: '22%', marginHorizontal: 3, fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', }}>
                        {item.picItems && item.picItems.length > 0
                            ? item.picItems
                                .map((picItem, index) => {
                                    return picItem.name ? picItem.name : 'N/A';
                                })
                                .join('\n')
                            : item.pic}
                    </Text>
                    <Text style={{ width: '25%', marginHorizontal: 3, fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', }}>
                        {item.picItems && item.picItems.length > 0
                            ? item.picItems
                                .map((picItem, index) => {
                                    return picItem.email ? picItem.email : 'N/A';
                                })
                                .join('\n')
                            : item.email}
                    </Text>
                    <Text style={{ width: '17%', marginHorizontal: 3, fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', }}>
                        {item.picItems && item.picItems.length > 0
                            ? item.picItems
                                .map((picItem, index) => {
                                    return picItem.phone ? picItem.phone : 'N/A';
                                })
                                .join('\n')
                            : item.phone}
                    </Text>
                    {/* <Text style={{ width: '10%', marginLeft: 3 }}>
            {`${(item.taxRate * 100).toFixed(0)}%`}
          </Text> */}
                </View>
            </TouchableOpacity>
        );
    };

    const renderSupplierItems = ({ item, index }) => {
        return (
            <View
                style={{
                    backgroundColor: '#ffffff',
                    flexDirection: 'row',
                    paddingVertical: 20,
                    paddingHorizontal: 20,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    borderBottomColor: '#c4c4c4',
                    alignItems: 'center',
                    zIndex: 1,
                    // height: (Dimensions.get('window').width * 0.1) * 3,
                }}>
                <View style={{ width: '8%' }}>
                    <TouchableOpacity
                        style={{ width: switchMerchant ? 40 : 45, height: switchMerchant ? 40 : 45 }}
                        onPress={() => {
                            openFileSelector();
                        }}>
                        {supplierItems[index].image ? (
                            <AsyncImage
                                style={{
                                    //transform: [{scaleX: 1}, {scaleY: 1}],
                                    width: switchMerchant ? 40 : 45,
                                    height: switchMerchant ? 40 : 45,
                                    borderWidth: 1,
                                    borderColor: '#E5E5E5',
                                    borderRadius: 5,
                                }}
                                source={{ uri: supplierItems[index].image }}
                                item={supplierItems[index]}
                            />
                        ) : (
                            <View
                                style={{
                                    width: switchMerchant ? 40 : 45,
                                    height: switchMerchant ? 40 : 45,
                                    borderWidth: 1,
                                    borderColor: '#E5E5E5',
                                    borderRadius: 5,
                                    backgroundColor: Colors.fieldtBgColor,
                                    alignItem: 'center',
                                    justifyContent: 'center',
                                }}>
                                <Icon
                                    name="upload"
                                    size={switchMerchant ? 20 : 25}
                                    style={{ color: Colors.descriptionColor, alignSelf: 'center' }}
                                />
                            </View>
                        )}
                        {/* <View style={{
                  position: 'absolute',
                  bottom: 3,
                  right: 0,
                  //   backgroundColor: 'black',
                  opacity: 0.5,
                  // width: 120, 
                  // height: 120,
                  // borderRadius: 13,
              }}>

                  <FontAwesome5 name='edit' size={10} color='darkgreen'
                  //  style={{ position: 'absolute', zIndex: -1 }}
                  />

              </View> */}
                    </TouchableOpacity>
                </View>

                <View
                    style={{
                        width: switchMerchant ? '26%' : '22%',
                        // marginLeft: 50,
                        marginLeft: switchMerchant ? '1%' : 0,
                    }}>
                    <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? '90%' : 150,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder={'Item Name'}
                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        keyboardType={'default'}
                        // placeholder={itemName}
                        onChangeText={(text) => {
                            // setState({ itemName: text });
                            setSupplierItems(
                                supplierItems.map((supplierItem, i) =>
                                    i === index
                                        ? {
                                            ...supplierItem,
                                            name: text,
                                            isChanged: true,
                                        }
                                        : supplierItem,
                                ),
                            );
                        }}
                        value={supplierItems[index].name}
                    // ref={myTextInput}
                    />
                </View>

                <View
                    style={{
                        width: '20%',
                        // marginLeft: 50,
                        // backgroundColor: 'blue',
                        //marginLeft: '-1%',
                    }}>
                    <TextInput
                        editable={item.supplyItemId === ''}
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? '85%' : 150,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder={'SKU'}
                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        keyboardType={'default'}
                        // placeholder={itemName}
                        onChangeText={(text) => {
                            // setState({ itemName: text });
                            setSupplierItems(
                                supplierItems.map((supplierItem, i) =>
                                    i === index
                                        ? {
                                            ...supplierItem,
                                            skuMerchant: text,
                                            isChanged: true,
                                        }
                                        : supplierItem,
                                ),
                            );
                        }}
                        value={supplierItems[index].skuMerchant}
                    // ref={myTextInput}
                    />
                </View>

                <View
                    style={{
                        width: switchMerchant ? '23%' : '20%',
                        //height: 40,
                        //justifyContent: 'flex-start',
                        //alignItems: 'flex-start',
                        // marginLeft: 50,
                        // backgroundColor: 'blue',
                        //marginLeft: '-1%',
                    }}>
                    <View
                        style={{
                            width: switchMerchant ? '80%' : 130,
                            height: switchMerchant ? 35 : 40,
                            backgroundColor: Colors.fieldtBgColor,
                            borderRadius: 5,
                            justifyContent: 'center',
                            paddingHorizontal: 10,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                        }}>
                        <Picker //need fix
                            //useNativeAndroidPickerStyle={false}
                            //pickerProps={{ style: { height: 160, overflow: 'hidden',} }}
                            style={{
                                inputIOS: { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, color: 'black', paddingVertical: 5 },
                                inputAndroid: { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, color: 'black', paddingVertical: 5 },
                                inputAndroidContainer: {
                                    //backgroundColor: 'red',
                                    width: '100%',
                                }
                            }}
                            // contentContainerStyle={{ fontSize: switchMerchant ? 10 : 14, }}

                            placeholder={{
                                label: 'Select a Unit...',
                            }}
                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, }}
                            onValueChange={(item) => {
                                setSupplierItems(
                                    supplierItems.map((supplierItem, i) =>
                                        i === index
                                            ? {
                                                ...supplierItem,
                                                unit: item,
                                                isChanged: true,
                                            }
                                            : supplierItem,
                                    ),
                                );
                            }}
                            value={supplierItems[index].unit}
                            defaultValue={supplierItems[index].unit}
                        >
                            <Picker.Item label='Kilogram' value='Kilogram' />
                            <Picker.Item label='Gram' value='Gram' />
                            <Picker.Item label='Litre' value='Litre' />
                            <Picker.Item label='Millilitre' value='Millilitre' />
                            <Picker.Item label='Carton' value='Carton' />
                            <Picker.Item label='Box' value='Box' />
                            <Picker.Item label='Pack' value='Pack' />
                        </Picker>
                    </View>

                </View>

                <KeyboardAvoidingView behavior="padding" style={{
                    width: switchMerchant ? '17%' : '20%',
                }}
                    keyboardVerticalOffset={Platform.OS === 'ios' ? 30 : 0}>
                    <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? '85%' : 100,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder={'Price/ unit'}
                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        keyboardType={'number-pad'}
                        onChangeText={(text) => {
                            setSupplierItems(
                                supplierItems.map((supplierItem, i) =>
                                    i === index
                                        ? {
                                            ...supplierItem,
                                            price: text.length > 0 ? parseFloat(text) : 0,
                                            isChanged: true,
                                        }
                                        : supplierItem,
                                ),
                            );
                        }}
                        value={supplierItems[index].price}
                    />
                </KeyboardAvoidingView>

                <View
                    style={{
                        width: switchMerchant ? '5%' : '10%',
                        justifyContent: 'flex-end',
                    }}>
                    <TouchableOpacity
                        style={{ width: switchMerchant ? 15 : 20, height: switchMerchant ? 15 : 20, alignSelf: 'flex-end' }}
                        onPress={() => {
                            setSupplierItems([
                                ...supplierItems.slice(0, index),
                                ...supplierItems.slice(index + 1),
                            ]);
                        }}>
                        <Icon
                            name="trash-2"
                            size={switchMerchant ? 15 : 20}
                            color="#eb3446"
                            style={{ alignSelf: 'flex-end' }}
                        />
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    const renderPersonInCharge = ({ item, index }) => {
        return (
            <View
                style={{
                    backgroundColor: '#ffffff',
                    flexDirection: 'row',
                    paddingVertical: 20,
                    paddingHorizontal: 20,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    borderBottomColor: '#c4c4c4',
                    alignItems: 'center',
                    zIndex: 1,
                }}>
                <View
                    style={{
                        width: switchMerchant ? '28%' : '22%',
                    }}>
                    <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? '90%' : 170,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder={'Name'}
                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        keyboardType={'default'}
                        onChangeText={(text) => {
                            setPicItems(
                                picItems.map((picItem, i) =>
                                    i === index
                                        ? {
                                            ...picItem,
                                            name: text,
                                        }
                                        : picItem,
                                ),
                            );
                        }}
                        value={picItems[index].name}
                    />
                </View>

                <View
                    style={{
                        width: switchMerchant ? '27%' : '20%',
                    }}>
                    <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? '85%' : 160,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder={'Phone'}
                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        //keyboardType={'numeric'}
                        keyboardType={'phone-pad'}
                        onChangeText={(text) => {
                            if (/^[0-9]+$/.test(text) || text === '') {
                                setPicItems(
                                    picItems.map((picItem, i) =>
                                        i === index
                                            ? {
                                                ...picItem,
                                                phone: text,
                                            }
                                            : picItem,
                                    ),
                                );
                            } else {
                                Alert.alert('Alert', 'Numbers Only');
                            }
                        }}
                        //defaultValue={item.phone}
                        value={picItems[index].phone}
                    />
                </View>

                <View
                    style={{
                        width: switchMerchant ? '35%' : '23%',
                    }}>
                    <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? '85%' : 210,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder={'Email'}
                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        keyboardType={'email-address'}
                        onChangeText={(text) => {
                            setPicItems(
                                picItems.map((picItem, i) =>
                                    i === index
                                        ? {
                                            ...picItem,
                                            email: text,
                                        }
                                        : picItem,
                                ),
                            );
                        }}
                        // defaultValue={item.email}
                        value={picItems[index].email}
                    />
                </View>

                <View
                    style={{
                        width: switchMerchant ? '0%' : '20%',
                    }}></View>

                <View
                    style={{
                        width: switchMerchant ? '0%' : '5%',
                    }}></View>

                <View style={{ width: '10%', justifyContent: 'flex-end' }}>
                    <TouchableOpacity
                        style={{ width: switchMerchant ? 15 : 20, height: switchMerchant ? 15 : 20, alignSelf: 'flex-end' }}
                        onPress={() => {
                            setPicItems([
                                ...picItems.slice(0, index),
                                ...picItems.slice(index + 1),
                            ]);
                        }}>
                        <Icon
                            name="trash-2"
                            size={switchMerchant ? 15 : 20}
                            color="#eb3446"
                            style={{ alignSelf: 'flex-end' }}
                        />
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    const renderPaymentDetails = ({ item, index }) => {
        return (
            <View
                style={{
                    backgroundColor: '#ffffff',
                    flexDirection: 'row',
                    paddingVertical: 20,
                    paddingHorizontal: switchMerchant ? 10 : 20,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    borderBottomColor: '#c4c4c4',
                    alignItems: 'center',
                    zIndex: 1,
                }}>
                <View
                    style={{
                        width: switchMerchant ? '26%' : '23%',
                    }}>
                    <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? '90%' : 170,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder={'Bank Account Name'}
                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        keyboardType={'default'}
                        onChangeText={(text) => {
                            setPaymentItems(
                                paymentItems.map((paymentItem, i) =>
                                    i === index
                                        ? {
                                            ...paymentItem,
                                            bankAccountName: text,
                                        }
                                        : paymentItem,
                                ),
                            );
                        }}
                        // defaultValue={item.bankAccountName}
                        value={paymentItems[index].bankAccountName}
                    />
                </View>

                <View
                    style={{
                        width: switchMerchant ? '26%' : '22%',
                    }}>
                    <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? '85%' : 170,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder={'Bank Account No.'}
                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        keyboardType={'number-pad'}
                        onChangeText={(text) => {
                            setPaymentItems(
                                paymentItems.map((paymentItem, i) =>
                                    i === index
                                        ? {
                                            ...paymentItem,
                                            bankAccountNumber: text,
                                        }
                                        : paymentItem,
                                ),
                            );
                        }}
                        // defaultValue={item.bankAccountNumber}
                        value={paymentItems[index].bankAccountNumber}
                    />
                </View>

                <View
                    style={{
                        width: switchMerchant ? '20%' : '22%',
                    }}>
                    <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? '85%' : 170,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14
                        }}
                        placeholder={'Bank Name'}
                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        keyboardType={'default'}
                        onChangeText={(text) => {
                            setPaymentItems(
                                paymentItems.map((paymentItem, i) =>
                                    i === index
                                        ? {
                                            ...paymentItem,
                                            bankName: text,
                                        }
                                        : paymentItem,
                                ),
                            );
                        }}
                        // defaultValue={item.bankName}
                        value={paymentItems[index].bankName}
                    />
                </View>

                <View
                    style={{
                        width: switchMerchant ? '21%' : '23%',
                    }}>
                    <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: switchMerchant ? '85%' : 170,
                            height: switchMerchant ? 35 : 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft: 10,
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder={'Bank Branch'}
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        keyboardType={'default'}
                        onChangeText={(text) => {
                            setPaymentItems(
                                paymentItems.map((paymentItem, i) =>
                                    i === index
                                        ? {
                                            ...paymentItem,
                                            bankBranch: text,
                                        }
                                        : paymentItem,
                                ),
                            );
                        }}
                        // defaultValue={item.bankName}
                        value={paymentItems[index].bankBranch}
                    />
                </View>

                <View
                    style={{
                        width: switchMerchant ? '5%' : '10%',
                        justifyContent: 'flex-end',
                    }}>
                    <TouchableOpacity
                        style={{ width: switchMerchant ? 15 : 20, height: switchMerchant ? 15 : 20, alignSelf: 'flex-end' }}
                        onPress={() => {
                            setPaymentItems([
                                ...paymentItems.slice(0, index),
                                ...paymentItems.slice(index + 1),
                            ]);
                        }}>
                        <Icon
                            name="trash-2"
                            size={switchMerchant ? 15 : 20}
                            color="#eb3446"
                            style={{ alignSelf: 'flex-end' }}
                        />
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    const renderPurchaseOrders = ({ item }) => {
        console.log('renderPurchaseOrders');
        console.log(item);

        return (
            <TouchableOpacity
                onPress={() => {
                    // setState({
                    //   lowStockAlert: false,
                    //   purchaseOrder: false,
                    //   stockTransfer: false,
                    //   stockTake: false,
                    //   addPurchase: false,
                    //   editPurchase: true,
                    //   addStockTransfer: false,
                    //   addStockTake: false,
                    // });

                    // disable first
                    // setEditPurchase(true);
                    // setPurchaseOrder(false);

                    CommonStore.update((s) => {
                        s.selectedPurchaseOrderEditFromSupplier = item;
                    });

                    navigation.navigate('PurchaseOrder');

                    // setPurchaseOrder(false);
                    // setAddPurchase(true);
                }}>
                <View
                    style={{
                        backgroundColor: '#ffffff',
                        flexDirection: 'row',
                        paddingVertical: 20,
                        paddingHorizontal: 5,
                        paddingLeft: 15,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                        borderBottomColor: '#c4c4c4',
                        width: '100%',
                        alignItems: 'center',
                    }}>
                    <Text
                        style={{
                            flex: 1,
                            marginHorizontal: 2,
                            color: Colors.primaryColor,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: switchMerchant ? 10 : 14,
                        }}>
                        {`PO${item.poId}`}
                    </Text>
                    <Text
                        style={{
                            flex: 1,
                            marginHorizontal: 2,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: switchMerchant ? 10 : 14,
                        }}>
                        {moment(item.orderDate).format('DD MMM YYYY')}
                    </Text>
                    <Text
                        style={{
                            flex: 1,
                            marginHorizontal: 2,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: switchMerchant ? 10 : 14,
                        }}>
                        {moment(item.estimatedArrivalDate).format('DD MMM YYYY')}
                    </Text>
                    <Text
                        style={{
                            flex: 1,
                            marginHorizontal: 2,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: switchMerchant ? 10 : 14,
                        }}>
                        {item.outletName}
                    </Text>
                    <Text
                        style={{
                            flex: 1,
                            marginHorizontal: 2,
                            fontFamily: 'NunitoSans-Regular',
                            fontSize: switchMerchant ? 10 : 14,
                        }}>
                        RM
                        {item.poItems
                            .reduce((accum, poItem) => accum + poItem.totalPrice, 0)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                    </Text>
                    <View
                        style={{
                            flex: 1,
                            marginHorizontal: 2,
                        }}>
                        <View
                            style={{
                                borderRadius: 10,
                                width: switchMerchant ? '90%' : 125,
                                height: 30,
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor:
                                    item.status == 0
                                        ? '#dedede'
                                        : item.status == 1
                                            ? '#969696'
                                            : item.status == 2
                                                ? Colors.secondaryColor
                                                : Colors.primaryColor,
                            }}>
                            <Text
                                style={{
                                    color:
                                        item.status == 0
                                            ? Colors.blackColor
                                            : item.status == 1
                                                ? Colors.whiteColor
                                                : item.status == 2
                                                    ? Colors.blackColor
                                                    : Colors.whiteColor,
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: switchMerchant ? 10 : 14,
                                }}>
                                {/* {item.status == 0 ? "Fail" : item.status == 1 ? "In Progress" : item.status == 2 ? "Arrived" : "Completed"} */}
                                {PURCHASE_ORDER_STATUS_PARSED[item.status]}
                                {/* Partially Receive */}
                            </Text>
                        </View>
                    </View>
                    {/* <View style={{
            width: '2%',
            marginLeft: 2,
            // backgroundColor: 'red'
          }}>
            <View>
              {(expandThreeDots[item.uniqueId] == true) ?
                <View style={{
                  position: 'absolute',
                  width: 110,
                  //height: 50,
                  marginLeft: -110,
                  paddingVertical: 10,
                  zIndex: 1,
                  flexDirection: 'column',
                  backgroundColor: '#FFFFFF',
                  borderWidth: 1,
                  //borderColor: '#E7E7E7',
                  borderColor: Colors.highlightColor,
                  borderRadius: 7,
                  shadowOpacity: 0,
                  shadowColor: '#000',
                  shadowOffset: {
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 2,
                }}>

                  <TouchableOpacity style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                    onPress={() => {
                      duplicatePurchaseOrder(item);
                    }}>
                    <View style={{ width: '30%', paddingLeft: 10 }}>
                      <MaterialIcons name='content-copy' size={17} color='grey' />
                    </View>
                    <View style={{ width: '70%' }}>
                      <Text style={{ marginLeft: 5 }}>
                        Duplicate
                      </Text>
                    </View>
                  </TouchableOpacity>                  
                </View>
                : null}
            </View>

            <View style={{
              flexDirection: 'row',
              // backgroundColor: 'red',
            }}>
              <TouchableOpacity style={{ marginTop: 2, alignSelf: 'flex-start', alignItems: 'flex-start' }}
                onPress={() => expandThreeDotsFunc(item)}
              >
                <Entypo name='dots-three-vertical' size={25}
                  color={Colors.tabGrey} style={{ alignSelf: 'flex-start' }} />
              </TouchableOpacity>
            </View>
          </View> */}
                </View>
            </TouchableOpacity>
        );
    };

    const email = () => {
        var body = {
            stockTransferId: 1,
            email: Email,
        };
        // ApiClient.POST(API.emailStockTransfer, body, false).then((result) => {

        //   if (result == true) {

        //     Alert.alert(
        //       'Success',
        //       'The email had sent',
        //       [
        //         { text: "OK", onPress: () => { } }
        //       ],
        //       { cancelable: false },
        //     );
        //   }
        // });
    };

    // const createStockOrder = () => {
    //   var body = {
    //     poId: poId,
    //     poItems: poItems,
    //     supplierId: selectedSupplierId,
    //     status: poStatus,
    //     outletId: selectedTargetOutletId,
    //     tax: taxTotal,
    //     discount: discountTotal,
    //     totalPrice: subtotal,
    //     finalTotal: finalTotal,
    //     estimatedArrivalDate: date,

    //     merchantId: merchantId,
    //     remarks: '',
    //   };

    //   ApiClient.POST(API.createPurchaseOrder, body).then((result) => {
    //     if (result && result.uniqueId) {
    //       Alert.alert(
    //         'Success',
    //         'Purchase order created successfully',
    //         [
    //           { text: "OK", onPress: () => { props.navigation.goBack() } }
    //         ],
    //         { cancelable: false },
    //       );
    //     }
    //   });
    // }

    const createSupplier = async () => {
        console.log('on createSupplier');

        if (
            supplierName !== ''
            //&&
            //supplierEmail !== '' &&
            //supplierPhone !== '' &&
            //supplierPIC !== '' &&
            //supplierTax !== 0
        ) {
            var supplierItemsTemp = [];

            for (var i = 0; i < supplierItems.length; i++) {
                supplierItemsTemp.push({
                    supplyItemId: supplierItems[i].supplyItemId,
                    name: supplierItems[i].name,
                    sku: supplierItems[i].sku,
                    skuMerchant: supplierItems[i].skuMerchant,
                    unit: supplierItems[i].unit,
                    price: +parseFloat(supplierItems[i].price).toFixed(2),

                    image: supplierItems[i].image,
                    imageType: supplierItems[i].imageType,
                    isImageChanged: supplierItems[i].isImageChanged,
                    isChanged: supplierItems[i].isChanged,
                });
            }

            if (selectedSupplierEdit === null) {
                var body = {
                    supplierItems: supplierItems,
                    supplierName: supplierName,
                    supplierEmail: supplierEmail,
                    supplierPhone: supplierPhone,
                    supplierTax: +(supplierTax / 100).toFixed(2),
                    supplierPIC: supplierPIC,

                    merchantId: merchantId,

                    supplierRegistrationName: supplierRegistrationName,
                    supplierRegistrationNumber: supplierRegistrationNumber,
                    supplierAddress: supplierAddress,
                    supplierAddressLat: supplierAddressLat,
                    supplierAddressLng: supplierAddressLng,

                    picItems: picItems,
                    paymentItems: paymentItems,
                };

                console.log(body);

                ///////////////////////////////////////

                for (var i = 0; i < supplierItemsTemp.length; i++) {
                    if (supplierItemsTemp[i].isImageChanged) {
                        const supplierItemImagePath = await uploadImageToFirebaseStorage(
                            {
                                uri: supplierItemsTemp[i].image,
                                type: supplierItemsTemp[i].imageType,
                            },
                            `/merchant/${merchantId}/supplyItem/${supplierItemsTemp[i].supplyItemId
                                ? supplierItemsTemp[i].supplyItemId
                                : uuidv4()
                            }/image${supplierItemsTemp[i].imageType}`,
                        );

                        supplierItemsTemp[i].image = supplierItemImagePath;
                    }
                }

                body.supplierItems = supplierItemsTemp;

                ///////////////////////////////////////

                ApiClient.POST(API.createSupplier, body).then((result) => {
                    if (result && result.status === 'success') {
                        Alert.alert(
                            'Success',
                            'Supplier has been created',
                            [
                                {
                                    text: 'OK',
                                    onPress: () => {
                                        setAddPurchase(false);
                                        setPurchaseOrder(true);
                                    },
                                },
                            ],
                            { cancelable: false },
                        );
                    } else {
                        Alert.alert('Error', 'Supplier failed to create');
                    }
                });
            } else {
                var body = {
                    supplierItems: supplierItems,
                    supplierName: supplierName,
                    supplierEmail: supplierEmail,
                    supplierPhone: supplierPhone,
                    supplierTax: +(supplierTax / 100).toFixed(2),
                    supplierPIC: supplierPIC,

                    merchantId: merchantId,

                    supplierId: selectedSupplierEdit.uniqueId,

                    supplierRegistrationName: supplierRegistrationName,
                    supplierRegistrationNumber: supplierRegistrationNumber,
                    supplierAddress: supplierAddress,
                    supplierAddressLat: supplierAddressLat,
                    supplierAddressLng: supplierAddressLng,

                    picItems: picItems,
                    paymentItems: paymentItems,
                };

                console.log(body);

                ///////////////////////////////////////

                for (var i = 0; i < supplierItemsTemp.length; i++) {
                    if (supplierItemsTemp[i].isImageChanged) {
                        const supplierItemImagePath = await uploadImageToFirebaseStorage(
                            {
                                uri: supplierItemsTemp[i].image,
                                type: supplierItemsTemp[i].imageType,
                            },
                            `/merchant/${merchantId}/supplyItem/${supplierItemsTemp[i].supplyItemId
                                ? supplierItemsTemp[i].supplyItemId
                                : uuidv4()
                            }/image${supplierItemsTemp[i].imageType}`,
                        );

                        supplierItemsTemp[i].image = supplierItemImagePath;
                    }
                }

                body.supplierItems = supplierItemsTemp;

                ///////////////////////////////////////

                ApiClient.POST(API.updateSupplier, body).then((result) => {
                    if (result && result.status === 'success') {
                        Alert.alert(
                            'Success',
                            'Supplier has been updated',
                            [
                                {
                                    text: 'OK',
                                    onPress: () => {
                                        setAddPurchase(false);
                                        setPurchaseOrder(true);
                                    },
                                },
                            ],
                            { cancelable: false },
                        );
                    } else {
                        Alert.alert('Error', 'Failed to create Supplier');
                    }
                });
            }
        } else {
            Alert.alert('Error', 'Please fill in all informations');
        }
    };

    const convertDataToExcelFormat = () => {
        var excelData = [];

        for (var i = 0; i < suppliers.length; i++) {
            var excelRow = {
                // 'SKU': outletItems[i].skuMerchant ? outletItems[i].skuMerchant : 'N/A',
                // 'Product Name': outletItems[i].name ? outletItems[i].name : 'N/A',
                // 'Status': outletItems[i].isActive ? 'Enabled' : 'Disabled',
                // 'Price': parseFloat(outletItems[i].price).toFixed(2),
                // 'Description': outletItems[i].description ? outletItems[i].description : 'N/A',
                'Company Name': suppliers[i].name ? suppliers[i].name : 'N/A',
                'Person In Charge': suppliers[i].pic ? suppliers[i].pic :
                    suppliers[i].picItems ? suppliers[i].picItems.map(item => item.name).join(',') :
                        'N/A',
                'Email': suppliers[i].email ? suppliers[i].email :
                    suppliers[i].picItems ? suppliers[i].picItems.map(item => item.email).join(',') :
                        'N/A',
                'Phone': suppliers[i].phone ? suppliers[i].phone :
                    suppliers[i].picItems ? suppliers[i].picItems.map(item => item.phone).join(',') :
                        'N/A',
                // 'Person In Charge': suppliers[i].pic ? suppliers[i].pic : 'N/A',
                // 'Email': suppliers[i].email ? suppliers[i].email : 'N/A',
                // 'Phone': suppliers[i].phone ? suppliers[i].phone : 'N/A',

            };

            excelData.push(excelRow);
        }

        console.log('excelData');
        console.log(excelData);

        return excelData;
    };

    // function end

    return (
        <View
            style={[
                styles.container,
                {
                    height: windowHeight,
                    width: windowWidth,
                },
            ]}>
            <View style={{ flex: 0.8 }}>
                <SideBar navigation={props.navigation} selectedTab={3} expandInventory={true} />
            </View>
            <View style={{ height: windowHeight, flex: 9 }}>
                <ScrollView scrollEnabled={switchMerchant} nestedScrollEnabled={true}>
                    <View
                        style={[
                            styles.content,
                            {
                                // borderRadius: 5,
                                // shadowColor: '#000',
                                // shadowOffset: {
                                //   width: 0,
                                //   height: 2,
                                // },
                                // shadowOpacity: 0.22,
                                // shadowRadius: 3.22,
                                // elevation: 3,
                            },
                        ]}>
                        <Modal
                            style={{}}
                            visible={exportModal}
                            supportedOrientations={['portrait', 'landscape']}
                            transparent={true}
                            animationType={'fade'}>
                            <View
                                style={{
                                    flex: 1,
                                    backgroundColor: Colors.modalBgColor,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <View
                                    style={{
                                        height: Dimensions.get('screen').width * 0.3,
                                        width: Dimensions.get('screen').width * 0.4,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 12,
                                        padding: Dimensions.get('screen').width * 0.03,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <TouchableOpacity
                                        disabled={isLoading}
                                        style={{
                                            position: 'absolute',
                                            right: Dimensions.get('screen').width * 0.02,
                                            top: Dimensions.get('screen').width * 0.02,

                                            elevation: 1000,
                                            zIndex: 1000,
                                        }}
                                        onPress={() => {
                                            setExportModal(false);
                                        }}>
                                        <AntDesign
                                            name="closecircle"
                                            size={switchMerchant ? 15 : 25}
                                            color={Colors.fieldtTxtColor}
                                        />
                                    </TouchableOpacity>
                                    <View
                                        style={{
                                            alignItems: 'center',
                                            top: '20%',
                                            position: 'absolute',
                                        }}>
                                        <Text
                                            style={{
                                                fontFamily: 'NunitoSans-Bold',
                                                textAlign: 'center',
                                                fontSize: switchMerchant ? 18 : 20,
                                            }}>
                                            Download Report
                                        </Text>
                                    </View>
                                    <View style={{ top: switchMerchant ? '14%' : '10%' }}>
                                        <Text
                                            style={{
                                                fontSize: switchMerchant ? 10 : 16,
                                                fontFamily: 'NunitoSans-Bold',
                                            }}>
                                            Email Address:
                                        </Text>
                                        <TextInput
                                            underlineColorAndroid={Colors.fieldtBgColor}
                                            style={{
                                                backgroundColor: Colors.fieldtBgColor,
                                                width: switchMerchant ? 240 : 370,
                                                height: switchMerchant ? 35 : 50,
                                                borderRadius: 5,
                                                padding: 5,
                                                marginVertical: 5,
                                                borderWidth: 1,
                                                borderColor: '#E5E5E5',
                                                paddingLeft: 10,
                                                fontSize: switchMerchant ? 10 : 16,
                                            }}
                                            placeholderStyle={{ padding: 5 }}
                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                            placeholder="Enter Your Email"
                                            onChangeText={(text) => {
                                                setExportEmail(text);
                                            }}
                                            value={exportEmail}
                                        />
                                        <Text
                                            style={{
                                                fontSize: switchMerchant ? 10 : 16,
                                                fontFamily: 'NunitoSans-Bold',
                                                marginTop: 15,
                                            }}>
                                            Send As:
                                        </Text>

                                        <View
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                marginTop: 10,
                                            }}>
                                            <TouchableOpacity
                                                disabled={isLoading}
                                                style={{
                                                    justifyContent: 'center',
                                                    flexDirection: 'row',
                                                    borderWidth: 1,
                                                    borderColor: Colors.primaryColor,
                                                    backgroundColor: '#4E9F7D',
                                                    borderRadius: 5,
                                                    width: switchMerchant ? 100 : 120,
                                                    paddingHorizontal: 10,
                                                    height: switchMerchant ? 35 : 40,
                                                    alignItems: 'center',
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.22,
                                                    shadowRadius: 3.22,
                                                    elevation: 1,
                                                    zIndex: -1,
                                                    marginRight: 15,
                                                }}
                                                onPress={() => {
                                                    if (exportEmail.length > 0) {
                                                        CommonStore.update((s) => {
                                                            s.isLoading = true;
                                                        });
                                                        setIsLoadingExcel(true);
                                                        const excelData = convertDataToExcelFormat();

                                                        generateEmailReport(
                                                            EMAIL_REPORT_TYPE.EXCEL,
                                                            excelData,
                                                            'KooDoo Supplier Report',
                                                            'KooDoo Supplier Report.xlsx',
                                                            `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                                                            exportEmail,
                                                            'KooDoo Supplier Report',
                                                            'KooDoo Supplier Report',
                                                            () => {
                                                                CommonStore.update((s) => {
                                                                    s.isLoading = false;
                                                                });
                                                                setIsLoadingExcel(false);

                                                                Alert.alert(
                                                                    'Success',
                                                                    'Report will sent to the email address soon',
                                                                );

                                                                setExportModalVisibility(false);
                                                            },
                                                        );
                                                    } else {
                                                        Alert.alert('Info', 'Invalid email address');
                                                    }
                                                }}>
                                                {isLoadingExcel ? (
                                                    <ActivityIndicator
                                                        size={'small'}
                                                        color={Colors.whiteColor}
                                                    />
                                                ) : (
                                                    <Text
                                                        style={{
                                                            color: Colors.whiteColor,
                                                            //marginLeft: 5,
                                                            fontSize: switchMerchant ? 10 : 16,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }}>
                                                        EXCEL
                                                    </Text>
                                                )}
                                            </TouchableOpacity>

                                            <TouchableOpacity
                                                disabled={isLoading}
                                                style={{
                                                    justifyContent: 'center',
                                                    flexDirection: 'row',
                                                    borderWidth: 1,
                                                    borderColor: Colors.primaryColor,
                                                    backgroundColor: '#4E9F7D',
                                                    borderRadius: 5,
                                                    width: switchMerchant ? 100 : 120,
                                                    paddingHorizontal: 10,
                                                    height: switchMerchant ? 35 : 40,
                                                    alignItems: 'center',
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.22,
                                                    shadowRadius: 3.22,
                                                    elevation: 1,
                                                    zIndex: -1,
                                                }}
                                                onPress={() => {
                                                    if (exportEmail.length > 0) {
                                                        CommonStore.update((s) => {
                                                            s.isLoading = true;
                                                        });
                                                        setIsLoadingCsv(true);
                                                        const csvData = convertArrayToCSV(suppliers);

                                                        generateEmailReport(
                                                            EMAIL_REPORT_TYPE.CSV,
                                                            csvData,
                                                            'KooDoo Supplier Report',
                                                            'KooDoo Supplier Report.csv',
                                                            `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                                                            exportEmail,
                                                            'KooDoo Supplier Report',
                                                            'KooDoo Supplier Report',
                                                            () => {
                                                                CommonStore.update((s) => {
                                                                    s.isLoading = false;
                                                                });
                                                                setIsLoadingCsv(false);
                                                                Alert.alert(
                                                                    'Success',
                                                                    'Report will sent to the email address soon',
                                                                );

                                                                setExportModalVisibility(false);
                                                            },
                                                        );
                                                    } else {
                                                        Alert.alert('Info', 'Invalid email address');
                                                    }
                                                }}>
                                                {isLoadingCsv ? (
                                                    <ActivityIndicator
                                                        size={'small'}
                                                        color={Colors.whiteColor}
                                                    />
                                                ) : (
                                                    <Text
                                                        style={{
                                                            color: Colors.whiteColor,
                                                            //marginLeft: 5,
                                                            fontSize: switchMerchant ? 10 : 16,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }}>
                                                        CSV
                                                    </Text>
                                                )}
                                            </TouchableOpacity>

                                            {/* <TouchableOpacity
                        style={[styles.modalSaveButton, {
                            zIndex: -1
                        }]}
                        onPress={() => { downloadPDF() }}>
                        <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>PDF</Text>
                    </TouchableOpacity> */}
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Modal>
                        {/* <ScrollView style={{ marginBottom: 10, }}> */}
                        {/* <KeyboardAwareScrollView
            keyboardShouldPersistTaps="handled"
            style={{ marginBottom: 10, }}> */}
                        <View>
                            <Modal
                                style={
                                    {
                                        // flex: 1
                                    }
                                }
                                supportedOrientations={['portrait', 'landscape']}
                                visible={importModal}
                                transparent={true}
                                animationType={'fade'}>
                                <View style={styles.modalContainer}>
                                    <View
                                        style={[
                                            styles.modalViewImport,
                                            {
                                            },
                                        ]}>
                                        <TouchableOpacity
                                            style={styles.closeButton}
                                            onPress={() => {
                                                // setState({ changeTable: false });
                                                setImportModal(false);
                                            }}>
                                            <AntDesign
                                                name="closecircle"
                                                size={25}
                                                color={Colors.fieldtTxtColor}
                                            />
                                        </TouchableOpacity>
                                        <View style={{ padding: 10, margin: 30 }}>
                                            <View
                                                style={[
                                                    styles.modalTitle1,
                                                    { justifyContent: 'center', alignItems: 'center' },
                                                ]}>
                                                <Text
                                                    style={[
                                                        styles.modalTitleText1,
                                                        { fontSize: 16, fontWeight: '500' },
                                                    ]}>
                                                    Imported List
                                                </Text>
                                            </View>
                                            {/* <View style={{
                    heigth: 70,
                    marginVertical: 10,
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    height: '80%'
                  }}>
                  <Table borderStyle={{ borderWidth: 1 }}>
                    <Row data={TableData.tableHead} flexArr={[1, 2, 1, 1]} style={{}}/>
                    <TableWrapper style={{}}>
                    <Col data={TableData.tableTitle} style={{flex: 1}} heightArr={[28, 28, 28, 28]} textStyle={{}}/>
                    <Rows data={TableData.tableData} flexArr={[1, 2, 1, 1]} style={{height: 28}} textStyle={{textAlign: 'center'}}/>
                    </TableWrapper>
                  </Table>
                  </View> */}
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                }}>
                                                <View
                                                    style={{
                                                        backgroundColor: Colors.primaryColor,
                                                        width: 150,
                                                        height: 40,
                                                        marginVertical: 15,
                                                        borderRadius: 5,
                                                        alignSelf: 'center',
                                                    }}>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            //importSelectFile();  //need fix
                                                        }}>
                                                        <Text
                                                            style={{
                                                                color: Colors.whiteColor,
                                                                alignSelf: 'center',
                                                                marginVertical: 10,
                                                            }}>
                                                            IMPORT
                                                        </Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <View
                                                        style={{
                                                            backgroundColor: Colors.whiteColor,
                                                            width: 150,
                                                            height: 40,
                                                            marginVertical: 15,
                                                            borderRadius: 5,
                                                            alignSelf: 'center',
                                                        }}>
                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                setImportTemplate(false);
                                                            }}>
                                                            <Text
                                                                style={{
                                                                    color: Colors.primaryColor,
                                                                    alignSelf: 'center',
                                                                    marginVertical: 10,
                                                                }}>
                                                                CANCEL
                                                            </Text>
                                                        </TouchableOpacity>
                                                    </View>
                                                    <View
                                                        style={{
                                                            backgroundColor: Colors.primaryColor,
                                                            width: 150,
                                                            height: 40,
                                                            marginVertical: 15,
                                                            borderRadius: 5,
                                                            alignSelf: 'center',
                                                        }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <Text
                                                                style={{
                                                                    color: Colors.whiteColor,
                                                                    alignSelf: 'center',
                                                                    marginVertical: 10,
                                                                }}>
                                                                SAVE
                                                            </Text>
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </Modal>

                            {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: Platform.OS == 'ios' ? 0 : 10 }}> */}
                            {/* <View style={{ marginRight: 248, }}>
                <View style={{ flexDirection: 'row', flex: 1, }}>
                  <TouchableOpacity style={[styles.submitText, {
                  height: Dimensions.get('screen').height * 0.05,
                }]} onPress={() => { importCSV() }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Icon name="download" size={20} color={Colors.primaryColor} />
                    <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                      Import
                    </Text>
                  </View>
                </TouchableOpacity> 
                  <TouchableOpacity style={[styles.submitText, {
                    // alignItems: 'center',
                    height: 40,
                  }]} onPress={() => { setExportModal(true) }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                      <Icon name="download" size={20} color={Colors.primaryColor} />
                      <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                        Download
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View> */}
                            {/* <TextInput
                editable={!loading}
                underlineColorAndroid={Colors.whiteColor}
                clearButtonMode="while-editing"
                style={styles.textInput}
                placeholder="🔍 Search"
                onChangeText={(text) => {
                  setState({ search: text.trim() });
                }}
                value={email}
              /> */}

                            {/* <Ionicons
              name="search-outline"
              size={20}
              style={styles.searchIcon}
            />

            <TextInput
              editable={loading}
              clearButtonMode="while-editing"
              style={[styles.textInput, { fontFamily: "NunitoSans-Bold" }]}
              placeholder="Search"
              onChangeText={(text) => {
                setState({
                  search: text.trim(),
                });
              }}
              value={email}
            /> */}

                            {/* <View
                style={[{
                  // flex: 1,
                  // alignContent: 'flex-end',
                  // marginBottom: 10,
                  // flexDirection: 'row',
                  // marginRight: '-40%',
                  // marginLeft: 310,
                  // backgroundColor: 'red',
                  // alignItems: 'flex-end',
                  // right: '-50%',
                  // width: '28%',
                  height: 40,

                }, !isTablet ? {
                  marginLeft: 0,
                } : {}]}>
                <View style={{
                  width: 250,
                  height: 40,
                  backgroundColor: 'white',
                  borderRadius: 10,
                  // marginLeft: '53%',
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  zIndex: -1,
                  //marginRight: Dimensions.get('screen').width * Styles.sideBarWidth,

                  position: 'absolute',
                  right: '35%',

                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}>
                  <Icon name="search" size={18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />
                  <TextInput
                    editable={!loading}
                    underlineColorAndroid={Colors.whiteColor}
                    style={{
                      width: 250,
                      fontSize: 15,
                      fontFamily: 'NunitoSans-Regular',
                    }}
                    clearButtonMode="while-editing"
                    placeholder=" Search"
                    onChangeText={(text) => {
                      setSearch(text.trim());
                    }}
                    value={search}
                  />
                </View>
              </View> */}

                            {/* </View> */}

                            {purchaseOrder ? (
                                <View style={{ height: Dimensions.get('window').height }}>
                                    {/* <View> */}
                                    <View style={{ width: Dimensions.get('screen').width * 0.87, alignSelf: 'center' }}>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                //backgroundColor: '#ffffff',
                                                justifyContent: 'space-between',
                                                //padding: 18,
                                                marginTop: 5,
                                                width: Dimensions.get('screen').width * 0.87,
                                                marginBottom: Platform.OS == 'ios' ? 0 : 10,
                                                // paddingLeft: '1.5%',
                                                // paddingRight: '1.5%',
                                            }}>
                                            <View style={{ flexDirection: 'row' }}>
                                                <View
                                                    style={{ alignItems: 'center', flexDirection: 'row' }}>
                                                    <Text
                                                        style={{
                                                            //fontSize: 30,
                                                            fontSize: switchMerchant ? 20 : 26,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }}>
                                                        {/* {orderList.length} */}
                                                        {suppliers.length} Supply
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={{ flexDirection: 'row' }}>
                                                <View style={{ flexDirection: 'row' }}>
                                                    {/* {isTablet && ( */}
                                                    <View
                                                        style={{
                                                            alignItem: 'center',
                                                            marginRight: 10,
                                                            // marginBottom: 10,
                                                        }}>
                                                        <TouchableOpacity
                                                            style={[
                                                                styles.addButtonView,
                                                                {
                                                                    paddingHorizontal: 10,
                                                                    height: switchMerchant ? 35 : 40,
                                                                    justifyContent: 'center',
                                                                },
                                                            ]}
                                                            onPress={() => {
                                                                setExportModal(true);
                                                            }}>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                }}>
                                                                <Icon
                                                                    name="download"
                                                                    size={switchMerchant ? 10 : 20}
                                                                    color={Colors.whiteColor}
                                                                />
                                                                <Text
                                                                    style={{
                                                                        color: Colors.whiteColor,
                                                                        marginLeft: 5,
                                                                        fontSize: switchMerchant ? 10 : 16,
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                    }}>
                                                                    DOWNLOAD
                                                                </Text>
                                                            </View>
                                                        </TouchableOpacity>
                                                    </View>
                                                    {/* )} */}
                                                </View>

                                                <View style={{ flexDirection: 'row' }}>
                                                    {/* {isTablet && ( */}
                                                    <View
                                                        style={{
                                                            alignSelf: 'flex-start',
                                                            marginRight: 15,
                                                        }}>
                                                        <TouchableOpacity
                                                            style={{ height: switchMerchant ? 35 : 40 }}
                                                            onPress={() => {
                                                                CommonStore.update((s) => {
                                                                    s.selectedSupplierEdit = null;
                                                                });
                                                                setPurchaseOrder(false);
                                                                setAddPurchase(true);
                                                            }}>
                                                            <View
                                                                style={[
                                                                    styles.addButtonView,
                                                                    {
                                                                        justifyContent: 'center',
                                                                        paddingHorizontal: 10,
                                                                        height: switchMerchant ? 35 : 40
                                                                    },
                                                                ]}>
                                                                <AntDesign
                                                                    name="pluscircle"
                                                                    size={switchMerchant ? 10 : 20}
                                                                    color="#FFFFFF"
                                                                />
                                                                <Text
                                                                    style={{
                                                                        color: Colors.whiteColor,
                                                                        marginLeft: 5,
                                                                        fontSize: switchMerchant ? 10 : 16,
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                    }}>
                                                                    SUPPLY
                                                                </Text>
                                                            </View>
                                                        </TouchableOpacity>
                                                    </View>
                                                    {/* )} */}
                                                </View>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <View
                                                        style={{
                                                            width: switchMerchant ? 200 : 250,
                                                            height: switchMerchant ? 35 : 40,
                                                            backgroundColor: 'white',
                                                            borderRadius: 5,
                                                            flexDirection: 'row',
                                                            alignContent: 'center',
                                                            alignItems: 'center',
                                                            // alignSelf: 'flex-end',
                                                            zIndex: -1,
                                                            shadowColor: '#000',
                                                            shadowOffset: {
                                                                width: 0,
                                                                height: 2,
                                                            },
                                                            shadowOpacity: 0.22,
                                                            shadowRadius: 3.22,
                                                            elevation: 3,
                                                            borderWidth: 1,
                                                            borderColor: '#E5E5E5',
                                                        }}>
                                                        <Icon
                                                            name="search"
                                                            size={switchMerchant ? 13 : 18}
                                                            color={Colors.primaryColor}
                                                            style={{ marginLeft: 15 }}
                                                        />
                                                        <TextInput
                                                            editable={!loading}
                                                            underlineColorAndroid={Colors.whiteColor}
                                                            style={{
                                                                width: switchMerchant ? 180 : 220,
                                                                fontSize: switchMerchant ? 10 : 16,
                                                                fontFamily: 'NunitoSans-Regular',
                                                                paddingLeft: 5,
                                                                height: 45,
                                                            }}
                                                            clearButtonMode="while-editing"
                                                            placeholder=" Search"
                                                            placeholderTextColor={Platform.select({
                                                                ios: '#a9a9a9',
                                                            })}
                                                            onChangeText={(text) => {
                                                                setSearch(text.trim());
                                                            }}
                                                            value={search}
                                                        />
                                                    </View>
                                                </View>
                                            </View>
                                        </View>

                                        <View
                                            style={{
                                                // backgroundColor: Colors.whiteColor,
                                                // // borderRadius: 10,
                                                // borderRadius: 5,
                                                // shadowOpacity: 0,
                                                // shadowColor: '#000',
                                                // shadowOffset: {
                                                //   width: 0,
                                                //   height: 2,
                                                // },
                                                // shadowOpacity: 0.22,
                                                // shadowRadius: 3.22,
                                                // elevation: 3,
                                                // height: '81%',
                                                // marginTop: 10,
                                                backgroundColor: Colors.whiteColor,
                                                width: Dimensions.get('screen').width * 0.87,
                                                height:
                                                    Platform.OS === 'ios'
                                                        ? Dimensions.get('screen').height * 0.77
                                                        : Dimensions.get('screen').height * 0.69,
                                                marginTop: 30,
                                                marginHorizontal: 30,
                                                marginBottom: 30,
                                                alignSelf: 'center',
                                                borderRadius: 5,
                                                shadowOpacity: 0,
                                                shadowColor: '#000',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 3,
                                            }}>
                                            <View
                                                style={{
                                                    backgroundColor: '#ffffff',
                                                    flexDirection: 'row',
                                                    paddingVertical: 20,
                                                    paddingHorizontal: 15,
                                                    //marginTop: 10,
                                                    borderTopLeftRadius: 5,
                                                    borderTopRightRadius: 5,
                                                    borderBottomWidth: StyleSheet.hairlineWidth,
                                                }}>
                                                <Text
                                                    style={{
                                                        width: '24%',
                                                        alignSelf: 'center',
                                                        fontFamily: 'NunitoSans-Bold',
                                                        marginRight: 3,
                                                        fontSize: switchMerchant ? 10 : 14,
                                                    }}>
                                                    Company Name
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: '22%',
                                                        alignSelf: 'center',
                                                        fontFamily: 'NunitoSans-Bold',
                                                        marginHorizontal: 3,
                                                        fontSize: switchMerchant ? 10 : 14,
                                                    }}>
                                                    Person In Charge
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: '25%',
                                                        alignSelf: 'center',
                                                        fontFamily: 'NunitoSans-Bold',
                                                        marginHorizontal: 3,
                                                        fontSize: switchMerchant ? 10 : 14,
                                                    }}>
                                                    Email
                                                </Text>
                                                <Text
                                                    style={{
                                                        width: '17%',
                                                        alignSelf: 'center',
                                                        fontFamily: 'NunitoSans-Bold',
                                                        marginHorizontal: 3,
                                                        fontSize: switchMerchant ? 10 : 14,
                                                    }}>
                                                    Phone
                                                </Text>
                                                {/* <Text style={{ width: '10%', alignSelf: 'center', color: '#969696', marginLeft: 3 }}>
                      Tax Rate(%)
                    </Text> */}
                                            </View>
                                            <FlatList
                                                nestedScrollEnabled={true}
                                                showsVerticalScrollIndicator={false}
                                                data={suppliers.filter((item) => {
                                                    if (search !== '') {
                                                        return item.name
                                                            .toLowerCase()
                                                            .includes(search.toLowerCase());
                                                    } else {
                                                        return true;
                                                    }
                                                })}
                                                extraData={suppliers.filter((item) => {
                                                    if (search !== '') {
                                                        return item.name
                                                            .toLowerCase()
                                                            .includes(search.toLowerCase());
                                                    } else {
                                                        return true;
                                                    }
                                                })}
                                                renderItem={renderOrderItem}
                                                keyExtractor={(item, index) => String(index)}
                                            />
                                        </View>
                                    </View>
                                    {/* </View> */}
                                </View>
                                //  </View> 
                                // </View>
                            ) : null}

                            {addPurchase ?
                                <View style={{
                                    width: Dimensions.get('screen').width * 0.878,
                                    marginTop: switchMerchant ? -10 : 10,
                                    alignSelf: 'center',
                                }}>
                                    <TouchableOpacity style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center', }}
                                        onPress={() => {
                                            setPurchaseOrder(true);
                                            setAddPurchase(false);
                                            //navigation.goBack();
                                        }}>
                                        <Icon name="chevron-left" size={switchMerchant ? 20 : 30} color={Colors.primaryColor} />
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 14 : 17, color: Colors.primaryColor, marginBottom: Platform.OS === 'ios' ? 0 : 2 }}>Back</Text>
                                    </TouchableOpacity>
                                </View>

                                :
                                <></>
                            }

                            <ScrollView
                                showsVerticalScrollIndicator={false}
                                keyboardShouldPersistTaps="handled"
                                style={{ marginBottom: 10 }}>
                                {addPurchase ? (
                                    <View>
                                        {/* <TouchableOpacity style={{ width: 90, marginBottom: 10, flexDirection: 'row', alignContent: 'center', alignItems: 'center', }}
                    onPress={() => {
                      setPurchaseOrder(true);
                      setAddPurchase(false);
                      //navigation.goBack();
                    }}>
                    <Icon name="chevron-left" size={30} color={Colors.primaryColor} />
                    <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 17, color: Colors.primaryColor }}>Back</Text>
                </TouchableOpacity> */}

                                        <View
                                            style={{
                                                backgroundColor: Colors.whiteColor,
                                                width: Dimensions.get('screen').width * 0.87,
                                                minHeight: Dimensions.get('screen').height * 0.01,
                                                marginTop: 10,
                                                marginHorizontal: 30,
                                                //marginBottom: selectedSupplierEdit ? 30 : 0 ,
                                                marginBottom: 30,
                                                alignSelf: 'center',
                                                borderRadius: 5,
                                                shadowOpacity: 0,
                                                shadowColor: '#000',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 3,
                                            }}>
                                            <Modal
                                                style={{ flex: 1 }}
                                                supportedOrientations={['portrait', 'landscape']}
                                                visible={visible}
                                                transparent={true}
                                                animationType="slide">
                                                <View
                                                    style={{
                                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                                        flex: 1,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        minHeight: Dimensions.get('window').height,
                                                    }}>
                                                    <View style={styles.confirmBox}>
                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                setState({ visible: false });
                                                            }}>
                                                            <View
                                                                style={{
                                                                    alignSelf: 'flex-end',
                                                                    padding: 16,
                                                                }}>
                                                                {/* <Close name="closecircle" size={25} /> */}
                                                                <AntDesign
                                                                    name="closecircle"
                                                                    size={25}
                                                                    color={Colors.fieldtTxtColor}
                                                                />
                                                            </View>
                                                        </TouchableOpacity>
                                                        <View>
                                                            <Text
                                                                style={{
                                                                    textAlign: 'center',
                                                                    fontWeight: '700',
                                                                    fontSize: 28,
                                                                }}>
                                                                Purchase Order
                                                            </Text>
                                                        </View>
                                                        <View style={{ marginTop: 20 }}>
                                                            <Text
                                                                style={{
                                                                    textAlign: 'center',
                                                                    color: Colors.descriptionColor,
                                                                    fontSize: 25,
                                                                }}>
                                                                Fill In The Email Information
                                                            </Text>
                                                        </View>
                                                        <View
                                                            style={{
                                                                backgroundColor: 'white',
                                                                alignSelf: 'center',
                                                                flexDirection: 'row',
                                                            }}>
                                                            <Text style={{ fontSize: 20, marginTop: 70 }}>
                                                                Email:
                                                            </Text>
                                                            <View
                                                                style={{
                                                                    marginTop: 60,
                                                                    backgroundColor: '#f7f5f5',
                                                                    marginLeft: 10,
                                                                }}>
                                                                <TextInput
                                                                    editable={!loading}
                                                                    underlineColorAndroid={Colors.fieldtBgColor}
                                                                    clearButtonMode="while-editing"
                                                                    style={styles.textCapacity}
                                                                    placeholder="myburgerlabmytown@gmail.com"
                                                                    placeholderTextColor={Platform.select({
                                                                        ios: '#a9a9a9',
                                                                    })}
                                                                    onChangeText={(text) => {
                                                                        setState({ Email: text });
                                                                    }}
                                                                    value={Email}
                                                                />
                                                            </View>
                                                        </View>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                alignSelf: 'center',
                                                                marginTop: 20,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: '50%',
                                                                alignContent: 'center',
                                                                zIndex: 6000,
                                                            }}></View>
                                                        <View
                                                            style={{
                                                                alignSelf: 'center',
                                                                marginTop: 20,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: 250,
                                                                height: 40,
                                                                alignContent: 'center',
                                                                flexDirection: 'row',
                                                                marginTop: 40,
                                                            }}>
                                                            <TouchableOpacity
                                                                onPress={() => {
                                                                    //email(), setState({ visible: false }); // need fix
                                                                }}
                                                                style={{
                                                                    backgroundColor: Colors.fieldtBgColor,
                                                                    width: '60%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    alignContent: 'center',
                                                                    borderRadius: 10,
                                                                    height: 60,
                                                                }}>
                                                                <Text
                                                                    style={{
                                                                        fontSize: 28,
                                                                        color: Colors.primaryColor,
                                                                    }}>
                                                                    Send
                                                                </Text>
                                                            </TouchableOpacity>
                                                            <TouchableOpacity
                                                                onPress={() => {
                                                                    setState({ visible: false });
                                                                }}
                                                                style={{
                                                                    backgroundColor: Colors.fieldtBgColor,
                                                                    width: '60%',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    alignContent: 'center',
                                                                    borderRadius: 10,
                                                                    height: 60,
                                                                    marginLeft: 30,
                                                                }}>
                                                                <Text
                                                                    style={{
                                                                        fontSize: 28,
                                                                        color: Colors.primaryColor,
                                                                    }}>
                                                                    No
                                                                </Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>
                                                </View>
                                            </Modal>

                                            <View
                                                style={{
                                                    position: 'absolute',
                                                    alignSelf: 'flex-end',
                                                    marginTop: 30,
                                                    zIndex: 10000,
                                                }}>
                                                <TouchableOpacity
                                                    style={{
                                                        justifyContent: 'center',
                                                        //flexDirection: 'row',
                                                        borderWidth: 1,
                                                        borderColor: Colors.primaryColor,
                                                        backgroundColor: '#4E9F7D',
                                                        borderRadius: 5,
                                                        width: switchMerchant ? 100 : 120,
                                                        paddingHorizontal: 10,
                                                        height: switchMerchant ? 35 : 40,
                                                        alignItems: 'center',
                                                        shadowOffset: {
                                                            width: 0,
                                                            height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: 10000,
                                                        marginRight: 20,
                                                    }}
                                                    onPress={createSupplier}>
                                                    <Text
                                                        style={{
                                                            color: Colors.whiteColor,
                                                            //marginLeft: 5,
                                                            fontSize: switchMerchant ? 10 : 16,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }}>
                                                        {!selectedSupplierEdit ? `SAVE` : `UPDATE`}
                                                    </Text>
                                                </TouchableOpacity>
                                            </View>

                                            <View
                                                style={{
                                                    minHeight: Dimensions.get('window').height * 0.1,
                                                }}>
                                                {/* <View style={{ borderBottomWidth: StyleSheet.hairlineWidth }}> */}
                                                <View>
                                                    <Text
                                                        style={{
                                                            alignSelf: 'center',
                                                            marginTop: 30,
                                                            fontSize: switchMerchant ? 20 : 40,
                                                            fontWeight: 'bold',
                                                        }}>
                                                        {selectedSupplierEdit
                                                            ? 'Edit Supplier'
                                                            : 'Add Supplier'}
                                                    </Text>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}>
                                                        <Text
                                                            style={{
                                                                alignSelf: 'center',
                                                                fontSize: switchMerchant ? 10 : 16,
                                                                color: '#adadad',
                                                            }}>
                                                            Fill In The Supplier Information
                                                        </Text>

                                                        {/* <View style={{ marginRight: '1%' }}>
                          <View style={{ width: 140, height: 30, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, justifyContent: 'center', marginRight: 10, alignSelf: "center", }}>
                            <RNPickerSelect
                              items={[
                                {
                                  label: '🖨️  Print P.O',
                                  value: 'Print P.O',
                                },
                                {
                                  label: '📧  Email P.O',
                                  value: 'Chicken',
                                },
                                {
                                  label: '📤  Export Labels',
                                  value: 'Export Labels',
                                },
                                {
                                  label: '❌  Cancel P.O',
                                  value: 'Cancel P.O',
                                },
                                {
                                  label: '🗑️  Delete P.O',
                                  value: 'Delete P.O',
                                },
                              ]}
                              defaultValue={choice2}
                              placeholder={{}}
                              containerStyle={{ height: 30 }}
                              style={{ backgroundColor: '#FAFAFA' }}
                              itemStyle={{
                                justifyContent: 'flex-start',
                              }}
                              dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                              onValueChange={(item) =>
                                setState({
                                  choice2: item.value,
                                })
                              }
                            />
                          </View>
                        </View> */}
                                                    </View>
                                                </View>
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        marginTop: 10,
                                                        justifyContent: 'space-evenly',
                                                        marginTop: 40,
                                                        width: '93%',
                                                        alignSelf: 'center',
                                                        marginLeft:
                                                            Dimensions.get('screen').width *
                                                            Styles.sideBarWidth *
                                                            0.5,
                                                    }}>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            flex: 1,
                                                            alignItems: 'center',
                                                            // justifyContent: 'space-around',
                                                            width: '50%',
                                                        }}>
                                                        <Text
                                                            style={{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                                width: '36%',
                                                                marginRight: 8,
                                                                textAlign: 'left',
                                                            }}>
                                                            Company Name
                                                        </Text>

                                                        <TextInput
                                                            editable={true}
                                                            underlineColorAndroid={Colors.fieldtBgColor}
                                                            style={{
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                width: 100,
                                                                height: switchMerchant ? 35 : 40,
                                                                width: '57%',
                                                                borderRadius: 5,
                                                                padding: 5,
                                                                borderWidth: 1,
                                                                borderColor: '#E5E5E5',
                                                                paddingLeft: 10,
                                                                fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                            }}
                                                            // placeholder={'50'}
                                                            placeholder={'Harvest Sdn Bhd'}
                                                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            placeholderTextColor={Platform.select({
                                                                ios: '#a9a9a9',
                                                            })}
                                                            keyboardType={'default'}
                                                            onChangeText={(text) => {
                                                                setSupplierName(text);
                                                            }}
                                                            value={supplierName}
                                                        />
                                                    </View>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            flex: 1,
                                                            alignItems: 'center',
                                                            width: '50%',
                                                        }}>
                                                        <Text
                                                            style={{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                                width: '36%',
                                                                marginRight: 8,
                                                                textAlign: 'left',
                                                            }}>
                                                            Company Registration Name
                                                        </Text>

                                                        <TextInput
                                                            editable={true}
                                                            underlineColorAndroid={Colors.fieldtBgColor}
                                                            style={{
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                width: 100,
                                                                height: switchMerchant ? 35 : 40,
                                                                width: '50%',
                                                                borderRadius: 5,
                                                                padding: 5,
                                                                borderWidth: 1,
                                                                borderColor: '#E5E5E5',
                                                                paddingLeft: 10,
                                                                fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                            }}
                                                            placeholder={'Company Registration Name'}
                                                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            placeholderTextColor={Platform.select({
                                                                ios: '#a9a9a9',
                                                            })}
                                                            onChangeText={(text) => {
                                                                setSupplierRegistrationName(text);
                                                            }}
                                                            defaultValue={supplierRegistrationName}
                                                        />
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        marginTop: 10,
                                                        justifyContent: 'space-evenly',
                                                        marginTop: 40,
                                                        width: '93%',
                                                        alignSelf: 'center',
                                                        marginLeft:
                                                            Dimensions.get('screen').width *
                                                            Styles.sideBarWidth *
                                                            0.5,
                                                        alignItems: 'flex-start',
                                                    }}>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            flex: 1,
                                                            alignItems: 'center',
                                                            // justifyContent: 'space-around',
                                                            width: '50%',
                                                        }}>
                                                        <Text
                                                            style={{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                                width: '36%',
                                                                marginRight: 8,
                                                                textAlign: 'left',
                                                            }}>
                                                            Company Registration No.
                                                        </Text>

                                                        <TextInput
                                                            editable={true}
                                                            underlineColorAndroid={Colors.fieldtBgColor}
                                                            style={{
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                width: 100,
                                                                height: switchMerchant ? 35 : 40,
                                                                width: '57%',
                                                                borderRadius: 5,
                                                                padding: 5,
                                                                borderWidth: 1,
                                                                borderColor: '#E5E5E5',
                                                                paddingLeft: 10,
                                                                fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                            }}
                                                            // placeholder={'50'}
                                                            placeholder={'Supplier Registration Number'}
                                                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            placeholderTextColor={Platform.select({
                                                                ios: '#a9a9a9',
                                                            })}
                                                            keyboardType={'default'}
                                                            onChangeText={(text) => {
                                                                setSupplierRegistrationNumber(text);
                                                            }}
                                                            defaultValue={supplierRegistrationNumber}
                                                        />
                                                    </View>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            flex: 1,
                                                            alignItems: 'center',
                                                            width: '50%',
                                                        }}>
                                                        <Text
                                                            style={{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                                width: '36%',
                                                                marginRight: 8,
                                                                textAlign: 'left',
                                                            }}>
                                                            Tax Rate (%)
                                                        </Text>
                                                        <Picker //need fix
                                                            containerStyle={{
                                                                height: switchMerchant ? 35 : 40,
                                                                zIndex: 2,
                                                                width: '50%',
                                                            }}
                                                            arrowColor={'black'}
                                                            arrowSize={20}
                                                            arrowStyle={{ fontWeight: 'bold' }}
                                                            labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, }}
                                                            style={{
                                                                paddingVertical: 0,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                            }}
                                                            placeholderStyle={{ color: Colors.fieldtTxtColor }}

                                                            itemStyle={{
                                                                justifyContent: 'flex-start',
                                                                marginLeft: 5,
                                                                zIndex: 2,
                                                            }}
                                                            placeholder={'Tax Rate'}
                                                            //</View>customTickIcon={(press) => (
                                                            //   <Ionicon
                                                            //       name={'md-checkbox'}
                                                            //        color={
                                                            //            press
                                                            //</View>                ? Colors.fieldtBgColor
                                                            //                : Colors.primaryColor
                                                            //        }
                                                            //        size={25}
                                                            //    />
                                                            //)}
                                                            onValueChange={(item) => {
                                                                setSupplierTax(item.value);
                                                            }}
                                                            selectedValue={supplierTax}
                                                        //dropDownMaxHeight={150}
                                                        //dropDownStyle={{
                                                        //    height: 80,
                                                        //    backgroundColor: Colors.fieldtBgColor,
                                                        //    borderRadius: 10,
                                                        //    borderWidth: 1,
                                                        //    textAlign: 'left',
                                                        //   zIndex: 2,
                                                        //}}
                                                        >
                                                            <Picker.Item label='0%' value='0' />
                                                            <Picker.Item label='6%' value='0.06' />
                                                        </Picker>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        marginTop: 40,
                                                        width: '93%',
                                                        alignSelf: 'center',
                                                        marginLeft:
                                                            Dimensions.get('screen').width *
                                                            Styles.sideBarWidth *
                                                            0.5,
                                                        zIndex: -4,
                                                    }}>
                                                    <KeyboardAvoidingView behavior="padding" style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'flex-start',
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                        keyboardVerticalOffset={Platform.OS === 'ios' ? 80 : 0}>
                                                        <View
                                                            style={{
                                                                justifyContent: 'center',
                                                                width: '18%',
                                                                height: switchMerchant ? 35 : 40,
                                                                marginRight: 8,
                                                            }}>
                                                            <Text
                                                                style={{
                                                                    fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                                    textAlign: 'left',
                                                                }}>
                                                                Company Address
                                                            </Text>
                                                        </View>
                                                        <Autocomplete
                                                            placeholder={
                                                                supplierAddress ? supplierAddress : 'Address'
                                                            }
                                                            textInputProps={{
                                                                placeholderTextColor: supplierAddress
                                                                    ? 'black'
                                                                    : null,
                                                            }}
                                                            minLength={2} // minimum length of text to search
                                                            autoFocus={false}
                                                            returnKeyType={'search'} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
                                                            listViewDisplayed="false" // true/false/undefined
                                                            fetchDetails={true}
                                                            renderDescription={(row) => renderSearch(row)} // custom description render
                                                            onPress={(data, details = null) => {
                                                                // 'details' is provided when fetchDetails = true
                                                                console.log('data', data);
                                                                console.log('details', details);

                                                                setSupplierAddress(details.formatted_address);
                                                                setSupplierAddressLat(
                                                                    details.geometry.location.lat,
                                                                );
                                                                setSupplierAddressLng(
                                                                    details.geometry.location.lng,
                                                                );
                                                            }}
                                                            DefaultValue={supplierAddress}
                                                            apiKey={'AIzaSyAt1AOIT_1E5Ivec7PVWmylmDd8jSVm-j8'}
                                                            options={'address'}
                                                            style={{
                                                                textInputContainer: {
                                                                    width: '92.5%',
                                                                    borderWidth: 1,
                                                                    borderRightWidth: 2,
                                                                    borderRadius: 5,
                                                                    borderColor: '#E5E5E5',
                                                                },
                                                                textInput: {
                                                                    backgroundColor: Colors.fieldtBgColor,
                                                                    fontFamily: 'NunitoSans-Regular',
                                                                    fontSize: 14,
                                                                    height: switchMerchant ? 35 : 40,
                                                                    marginBottom: 0,
                                                                    color: 'black',
                                                                    fontSize: switchMerchant ? 10 : 14,
                                                                    //backgroundColor: 'red',
                                                                },
                                                                description: {
                                                                    // fontWeight: 'bold',
                                                                    fontFamily: 'NunitoSans-Regular',
                                                                    fontSize: switchMerchant ? 10 : 14,
                                                                },
                                                                predefinedPlacesDescription: {
                                                                    color: '#1faadb',
                                                                    fontFamily: 'NunitoSans-Regular',
                                                                    fontSize: switchMerchant ? 10 : 14,
                                                                },
                                                                listView: {
                                                                    //backgroundColor: 'red',
                                                                    //marginLeft: 85,
                                                                    //marginTop: 40,
                                                                    width: '92.5%',
                                                                    //alignSelf: 'center',
                                                                    borderColor: '#E5E5E5',
                                                                    borderWidth: 1,
                                                                    borderRadius: 5,
                                                                    zIndex: 10,
                                                                    fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                                    // height: 800,
                                                                    //position: 'absolute',
                                                                },
                                                                container: {
                                                                    //backgroundColor: 'red',
                                                                },
                                                                zIndex: 10,
                                                            }}
                                                            enablePoweredByContainer={false}
                                                            //currentLocation={false}
                                                            currentLocationLabel="Current location"
                                                            nearbyPlacesAPI="GooglePlacesSearch"
                                                            GoogleReverseGeocodingQuery={{}}
                                                            GooglePlacesSearchQuery={{
                                                                rankby: 'distance',
                                                            }}
                                                            filterReverseGeocodingByTypes={[
                                                                'locality',
                                                                'administrative_area_level_3',
                                                            ]}
                                                            debounce={200}
                                                            keepResultsAfterBlur={true}
                                                        />
                                                    </KeyboardAvoidingView>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-evenly',
                                                        marginTop: 40,
                                                        marginBottom: 40,
                                                        width: '93%',
                                                        alignSelf: 'center',
                                                        marginLeft:
                                                            Dimensions.get('screen').width *
                                                            Styles.sideBarWidth *
                                                            0.5,
                                                    }}>
                                                </View>




                                            </View>
                                        </View>
                                        {selectedSupplierEdit ?
                                            <View style={{ width: Dimensions.get('screen').width * 0.87, marginVertical: -10, alignSelf: 'center' }}>
                                                <Text style={{ fontSize: switchMerchant ? 15 : 20 }}>{supplierName}</Text>
                                            </View>
                                            :
                                            null
                                        }
                                    </View>
                                ) : null}
                                {addPurchase ? (
                                    <View
                                        //style={[styles.list2, { marginBottom: 10 }]}
                                        style={{
                                            backgroundColor: Colors.whiteColor,
                                            width: Dimensions.get('screen').width * 0.87,
                                            marginTop: selectedSupplierEdit ? 30 : 0,
                                            marginHorizontal: 30,
                                            marginBottom: 30,
                                            alignSelf: 'center',
                                            borderRadius: 5,
                                            shadowOpacity: 0,
                                            shadowColor: '#000',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 3,
                                        }}>
                                        <View
                                            style={
                                                switchMerchant
                                                    ? {
                                                        marginBottom: 40,
                                                        minHeight: Dimensions.get('screen').height * 0.9,
                                                    }
                                                    : {
                                                        marginBottom: 40,
                                                        minHeight:
                                                            Platform.OS === 'ios'
                                                                ? Dimensions.get('screen').height * 0.6
                                                                : Dimensions.get('screen').height * 0.65,
                                                    }
                                            }>
                                            <View
                                                style={{
                                                    borderWidth: 1,
                                                    borderColor: '#E5E5E5',
                                                    borderTopLeftRadius: 5,
                                                    borderTopRightRadius: 5,
                                                }}>
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-around',
                                                        width: '97%',
                                                        marginVertical: 20,
                                                        marginHorizontal: 15,
                                                        //borderRadius: 5,
                                                    }}>
                                                    <TouchableOpacity
                                                        Style={{}}
                                                        onPress={() => {
                                                            setOtherOptions(OTHER_OPTIONS.PERSON_IN_CHARGE);
                                                        }}>
                                                        <Text
                                                            style={{
                                                                color:
                                                                    OtherOptions ===
                                                                        OTHER_OPTIONS.PERSON_IN_CHARGE
                                                                        ? Colors.primaryColor
                                                                        : 'grey',
                                                                fontWeight: '600',
                                                                fontSize: switchMerchant ? 10 : 16,
                                                            }}>
                                                            Person In Charge
                                                        </Text>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity
                                                        Style={{}}
                                                        onPress={() => {
                                                            setOtherOptions(OTHER_OPTIONS.SUPPLY_ITEMS);
                                                        }}>
                                                        <Text
                                                            style={{
                                                                color:
                                                                    OtherOptions === OTHER_OPTIONS.SUPPLY_ITEMS
                                                                        ? Colors.primaryColor
                                                                        : 'grey',
                                                                fontWeight: '600',
                                                                fontSize: switchMerchant ? 10 : 16,
                                                            }}>
                                                            Supply Items
                                                        </Text>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity
                                                        Style={{}}
                                                        onPress={() => {
                                                            setOtherOptions(OTHER_OPTIONS.PAYMENT_DETAILS);
                                                        }}>
                                                        <Text
                                                            style={{
                                                                color:
                                                                    OtherOptions === OTHER_OPTIONS.PAYMENT_DETAILS
                                                                        ? Colors.primaryColor
                                                                        : 'grey',
                                                                fontWeight: '600',
                                                                fontSize: switchMerchant ? 10 : 16,
                                                            }}>
                                                            Payment Details
                                                        </Text>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity
                                                        Style={{}}
                                                        onPress={() => {
                                                            setOtherOptions(OTHER_OPTIONS.HISTORY);
                                                        }}>
                                                        <Text
                                                            style={{
                                                                color:
                                                                    OtherOptions === OTHER_OPTIONS.HISTORY
                                                                        ? Colors.primaryColor
                                                                        : 'grey',
                                                                fontWeight: '600',
                                                                fontSize: switchMerchant ? 10 : 16,
                                                            }}>
                                                            History
                                                        </Text>
                                                    </TouchableOpacity>
                                                </View>
                                            </View>

                                            {/* **************************PIC Tab**************************** */}
                                            {OtherOptions === OTHER_OPTIONS.PERSON_IN_CHARGE ? (
                                                <>
                                                    {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 30, marginBottom: 20 }}>
                    <Text style={{ fontSize: 25, fontWeight: 'bold', marginLeft: '42%', alignContent: 'center' }}>PIC</Text>
                  </View> */}
                                                    <View
                                                        style={{
                                                            backgroundColor: '#ffffff',
                                                            flexDirection: 'row',
                                                            paddingVertical: 20,
                                                            paddingHorizontal: 20,
                                                            marginTop: 10,
                                                            borderBottomWidth: StyleSheet.hairlineWidth,
                                                        }}>
                                                        <Text
                                                            style={{
                                                                width: switchMerchant ? '28%' : '22%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                marginLeft: 0,
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Name
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                width: switchMerchant ? '27%' : '20%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Phone
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                width: switchMerchant ? '35%' : '23%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Email
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                width: '15%',
                                                                alignSelf: 'center',
                                                            }}></Text>
                                                    </View>

                                                    <FlatList
                                                        data={picItems}
                                                        extraData={picItems}
                                                        renderItem={renderPersonInCharge}
                                                        keyExtractor={(item, index) => String(index)}
                                                    />

                                                    <View style={{ flexDirection: 'row' }}>
                                                        <View>
                                                            <TouchableOpacity
                                                                style={styles.submitText2}
                                                                onPress={() => {
                                                                    setPicItems([
                                                                        ...picItems,
                                                                        {
                                                                            name: '',
                                                                            phone: '',
                                                                            email: '',
                                                                        },
                                                                    ]);
                                                                }}>
                                                                <View
                                                                    style={{
                                                                        flexDirection: 'row',
                                                                        alignItem: 'center',
                                                                    }}>
                                                                    <Icon1
                                                                        name="plus-circle"
                                                                        size={switchMerchant ? 15 : 20}
                                                                        color={Colors.primaryColor}
                                                                    />
                                                                    <Text
                                                                        style={{
                                                                            marginLeft: 10,
                                                                            color: Colors.primaryColor,
                                                                            alignSelf: 'center',
                                                                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                                        }}>
                                                                        Add Person In Charge
                                                                    </Text>
                                                                </View>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>
                                                </>
                                            ) : null}

                                            {/* **************************Supply item Tab**************************** */}
                                            {OtherOptions === OTHER_OPTIONS.SUPPLY_ITEMS ? (
                                                <>
                                                    {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 30, marginBottom: 20 }}>
                    <Text style={{ fontSize: 25, fontWeight: 'bold', marginLeft: '42%', alignContent: 'center' }}>Supply Items</Text>
                  </View> */}

                                                    <View
                                                        style={{
                                                            backgroundColor: '#ffffff',
                                                            flexDirection: 'row',
                                                            paddingVertical: 20,
                                                            paddingHorizontal: 20,
                                                            marginTop: 10,
                                                            borderBottomWidth: StyleSheet.hairlineWidth,
                                                        }}>
                                                        <Text
                                                            style={{
                                                                width: '8%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                            }}></Text>
                                                        <Text
                                                            style={{
                                                                width: switchMerchant ? '26%' : '22%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                marginLeft: switchMerchant ? '1%' : 0,
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Item Name
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                width: '20%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            SKU
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                width: switchMerchant ? '23%' : '20%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Unit
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                width: '20%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Cost
                                                        </Text>
                                                    </View>

                                                    <FlatList
                                                        data={supplierItems}
                                                        extraData={supplierItems}
                                                        renderItem={renderSupplierItems}
                                                        keyExtractor={(item, index) => String(index)}
                                                    />

                                                    <View style={{ flexDirection: 'row' }}>
                                                        <View>
                                                            <TouchableOpacity
                                                                style={styles.submitText2}
                                                                onPress={() => {
                                                                    setSupplierItems([
                                                                        ...supplierItems,
                                                                        {
                                                                            image: '',
                                                                            supplyItemId: '',
                                                                            name: '',
                                                                            sku: '',
                                                                            skuMerchant: '',
                                                                            unit: '',
                                                                            price: 0,
                                                                            image: '',
                                                                        },
                                                                    ]);
                                                                }}>
                                                                <View style={{ flexDirection: 'row' }}>
                                                                    <Icon1
                                                                        name="plus-circle"
                                                                        size={switchMerchant ? 15 : 20}
                                                                        color={Colors.primaryColor}
                                                                    />
                                                                    <Text
                                                                        style={{
                                                                            marginLeft: 10,
                                                                            color: Colors.primaryColor,
                                                                            alignSelf: 'center',
                                                                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                                        }}>
                                                                        Add Product Slot
                                                                    </Text>
                                                                </View>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>
                                                </>
                                            ) : null}

                                            {/* **************************Payment Tab**************************** */}
                                            {OtherOptions === OTHER_OPTIONS.PAYMENT_DETAILS ? (
                                                <>
                                                    {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 30, marginBottom: 20 }}>
                    < Text style={{ fontSize: 25, fontWeight: 'bold', marginLeft: '42%', alignContent: 'center' }}>Payment Details</Text>
                  </View> */}

                                                    <View
                                                        style={{
                                                            backgroundColor: '#ffffff',
                                                            flexDirection: 'row',
                                                            paddingVertical: 20,
                                                            paddingHorizontal: switchMerchant ? 10 : 20,
                                                            marginTop: 10,
                                                            borderBottomWidth: StyleSheet.hairlineWidth,
                                                        }}>
                                                        <Text
                                                            style={{
                                                                width: switchMerchant ? '26%' : '23%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                marginLeft: 0,
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Account Name
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                width: switchMerchant ? '26%' : '22%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Bank Account No.
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                width: switchMerchant ? '20%' : '22%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Bank Name
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                width: switchMerchant ? '21%' : '23%',
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Bank Branch
                                                        </Text>
                                                    </View>

                                                    <FlatList
                                                        data={paymentItems}
                                                        // extraData={''}
                                                        renderItem={renderPaymentDetails}
                                                        keyExtractor={(item, index) => String(index)}
                                                    />

                                                    <View style={{ flexDirection: 'row' }}>
                                                        <View>
                                                            <TouchableOpacity
                                                                style={styles.submitText2}
                                                                onPress={() => {
                                                                    setPaymentItems([
                                                                        ...paymentItems,
                                                                        {
                                                                            bankAccountName: '',
                                                                            bankAccountNumber: '',
                                                                            bankName: '',
                                                                            bankBranch: '',
                                                                        },
                                                                    ]);
                                                                }}>
                                                                <View style={{ flexDirection: 'row' }}>
                                                                    <Icon1
                                                                        name="plus-circle"
                                                                        size={switchMerchant ? 15 : 20}
                                                                        color={Colors.primaryColor}
                                                                    />
                                                                    <Text
                                                                        style={{
                                                                            marginLeft: 10,
                                                                            color: Colors.primaryColor,
                                                                            alignSelf: 'center',
                                                                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                                        }}>
                                                                        Add Payment Detail
                                                                    </Text>
                                                                </View>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>
                                                </>
                                            ) : null}

                                            {/* **************************History Tab**************************** */}
                                            {OtherOptions === OTHER_OPTIONS.HISTORY ? (
                                                <>
                                                    {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: 30, marginBottom: 20 }}>
                      <Text style={{ fontSize: 25, fontWeight: 'bold', marginLeft: '42%', alignContent: 'center' }}>History</Text>
                    </View> */}
                                                    <View
                                                        style={{
                                                            backgroundColor: '#ffffff',
                                                            flexDirection: 'row',
                                                            paddingVertical: 20,
                                                            paddingHorizontal: 5,
                                                            paddingLeft: 15,
                                                            marginTop: 10,
                                                            borderBottomWidth: StyleSheet.hairlineWidth,
                                                            width: '100%',
                                                        }}>
                                                        <Text
                                                            style={{
                                                                flex: 1,
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                marginHorizontal: 2,
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            PO ID
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                flex: 1,
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                marginHorizontal: 2,
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Created Date
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                flex: 1,
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                marginHorizontal: 2,
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Est Arrival
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                flex: 1,
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                marginHorizontal: 2,
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Target Store
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                flex: 1,
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                marginHorizontal: 2,
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Amount (RM)
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                flex: 1,
                                                                alignSelf: 'center',
                                                                fontWeight: '500',
                                                                marginHorizontal: 2,
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }}>
                                                            Status
                                                        </Text>
                                                    </View>

                                                    <FlatList
                                                        showsVerticalScrollIndicator={false}
                                                        data={purchaseOrders.filter((item) => {
                                                            if (search !== '') {
                                                                const searchLowerCase = search.toLowerCase();

                                                                return (
                                                                    item.supplierName
                                                                        .toLowerCase()
                                                                        .includes(searchLowerCase) ||
                                                                    item.outletName
                                                                        .toLowerCase()
                                                                        .includes(searchLowerCase)
                                                                );
                                                            } else {
                                                                return true;
                                                            }
                                                        })}
                                                        renderItem={renderPurchaseOrders}
                                                        keyExtractor={(item, index) => String(index)}
                                                        style={{ width: '100%' }}
                                                    />
                                                </>
                                            ) : null}
                                        </View>
                                    </View>
                                ) : null}
                            </ScrollView>

                            {editPurchase ? (
                                <View style={{ height: Dimensions.get('window').height }}>
                                    <View>
                                        <ScrollView nestedScrollEnabled={true}>
                                            <View
                                                style={{
                                                    width: 140,
                                                    marginRight: 10,
                                                    marginTop: 10,
                                                    alignSelf: 'flex-end',
                                                }}>
                                                <Picker //need fix
                                                    items={[
                                                        {
                                                            icon: () => { },
                                                            label: '🖨️  Print P.O',
                                                            value: 'Print P.O',
                                                        },
                                                        {
                                                            label: '📧  Email P.O',
                                                            value: 'Chicken',
                                                        },
                                                        {
                                                            label: '📤  Export Labels',
                                                            value: 'Export Labels',
                                                        },
                                                        {
                                                            label: '❌  Cancel P.O',
                                                            value: 'Cancel P.O',
                                                        },
                                                        {
                                                            label: '🗑️  Delete P.O',
                                                            value: 'Delete P.O',
                                                        },
                                                    ]}
                                                    defaultValue={choice}
                                                    placeholder={{}}
                                                    placeholderStyle={{ color: 'black' }}
                                                    containerStyle={{ height: 30 }}
                                                    style={{ backgroundColor: '#FAFAFA' }}
                                                    itemStyle={{
                                                        justifyContent: 'flex-start',
                                                    }}
                                                    dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                                                    onValueChange={(item) =>
                                                        setState({
                                                            choice: item.value,
                                                        })
                                                    }
                                                />
                                            </View>
                                            <View
                                                style={{ borderBottomWidth: StyleSheet.hairlineWidth }}>
                                                <View>
                                                    <Text
                                                        style={{
                                                            alignSelf: 'center',
                                                            marginTop: 30,
                                                            fontSize: 40,
                                                            fontWeight: 'bold',
                                                        }}>
                                                        Edit Purchase Order
                                                    </Text>
                                                    <Text
                                                        style={{
                                                            alignSelf: 'center',
                                                            fontSize: 16,
                                                            color: '#adadad',
                                                        }}>
                                                        Edit your purchase order information
                                                    </Text>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        marginTop: 10,
                                                        justifyContent: 'space-evenly',
                                                        marginTop: 50,
                                                    }}>
                                                    <View style={{ flexDirection: 'row', flex: 1 }}>
                                                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                                                            P.O.ID
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                color: '#adadad',
                                                                marginLeft: 170,
                                                                fontSize: 16,
                                                            }}>
                                                            P.O.1134
                                                        </Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', flex: 1 }}>
                                                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                                                            Supplier
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                color: '#adadad',
                                                                marginLeft: 100,
                                                                fontSize: 16,
                                                            }}>
                                                            My Burgers Enterprise
                                                        </Text>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        marginTop: 10,
                                                        justifyContent: 'space-evenly',
                                                        marginTop: 50,
                                                    }}>
                                                    <View style={{ flexDirection: 'row', flex: 1 }}>
                                                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                                                            Current status
                                                        </Text>
                                                        <View
                                                            style={{
                                                                paddingHorizontal: 18,
                                                                paddingVertical: 10,
                                                                alignItems: 'center',
                                                                backgroundColor: '#838387',
                                                                borderRadius: 10,
                                                                marginLeft: 100,
                                                            }}>
                                                            <Text style={{ color: Colors.whiteColor }}>
                                                                Partially received
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', flex: 1 }}>
                                                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                                                            Target Store
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                color: '#adadad',
                                                                marginLeft: 70,
                                                                fontSize: 16,
                                                            }}>
                                                            MyBurgerlab (Seapark)
                                                        </Text>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        marginTop: 10,
                                                        justifyContent: 'space-evenly',
                                                        marginTop: 50,
                                                        marginBottom: 40,
                                                    }}>
                                                    <View style={{ flexDirection: 'row', flex: 1 }}>
                                                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                                                            Estimated Arrival Time
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                color: '#adadad',
                                                                marginLeft: 50,
                                                                fontSize: 16,
                                                            }}>
                                                            1/10/2020
                                                        </Text>
                                                    </View>
                                                    <View style={{ flexDirection: 'row', flex: 1 }}>
                                                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                                                            Order Date
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                color: '#adadad',
                                                                marginLeft: 80,
                                                                fontSize: 16,
                                                            }}>
                                                            5/10/2020
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>

                                            <View>
                                                <Text
                                                    style={{
                                                        alignSelf: 'center',
                                                        marginTop: 30,
                                                        fontSize: 25,
                                                        fontWeight: 'bold',
                                                    }}>
                                                    Items to order
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    backgroundColor: '#ffffff',
                                                    flexDirection: 'row',
                                                    paddingVertical: 20,
                                                    paddingHorizontal: 20,
                                                    marginTop: 10,
                                                    borderBottomWidth: StyleSheet.hairlineWidth,
                                                }}>
                                                <Text style={{ width: '8%' }}></Text>
                                                <Text style={{ width: '14%', alignSelf: 'center' }}>
                                                    Product name
                                                </Text>
                                                <Text style={{ width: '16%', alignSelf: 'center' }}>
                                                    SKU
                                                </Text>
                                                <Text style={{ width: '14%', alignSelf: 'center' }}>
                                                    Ordered qty
                                                </Text>
                                                <Text style={{ width: '16%', alignSelf: 'center' }}>
                                                    Received qty
                                                </Text>
                                                <Text style={{ width: '18%', alignSelf: 'center' }}>
                                                    Supplier Price{' '}
                                                </Text>
                                                <Text style={{ width: '16%', alignSelf: 'center' }}>
                                                    Total (RM)
                                                </Text>
                                            </View>
                                            <FlatList
                                                showsVerticalScrollIndicator={false}
                                                data={itemsToOrder}
                                                extraData={itemsToOrder}
                                                renderItem={renderOrderItem}
                                                keyExtractor={(item, index) => String(index)}
                                            />

                                            <View style={{ flexDirection: 'row' }}>
                                                <View>
                                                    <TouchableOpacity
                                                        style={styles.submitText2}
                                                        onPress={() => {
                                                            // add one row

                                                            setSupplierItems([
                                                                ...supplierItems,
                                                                {
                                                                    supplyItemId: '',
                                                                    name: '',
                                                                    sku: '',
                                                                    skuMerchant: '',
                                                                    unit: '',
                                                                    price: 0,
                                                                    image: '',
                                                                },
                                                            ]);
                                                        }}>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Icon1
                                                                name="plus-circle"
                                                                size={20}
                                                                color={Colors.primaryColor}
                                                            />
                                                            <Text
                                                                style={{
                                                                    marginLeft: 10,
                                                                    color: Colors.primaryColor,
                                                                }}>
                                                                Add supply item slot
                                                            </Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </View>

                                                {/* <View style={{ marginLeft: 480, marginTop: 10 }}>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={{ color: '#adadad' }}>Subtotal</Text>
                        <Text style={{ color: '#adadad', marginLeft: 50 }}>RM360.00</Text>
                      </View>
                      <View style={{ flexDirection: "row", marginTop: 10 }}>
                        <Text style={{ color: '#adadad' }}>Tax</Text>
                        <Text style={{ color: '#adadad', marginLeft: 80 }}>RM0.00</Text>
                      </View>
                      <View style={{ flexDirection: "row", marginTop: 10 }}>
                        <Text style={{ color: '#adadad' }}>Discount</Text>
                        <Text style={{ color: '#adadad', marginLeft: 50 }}>RM0.00</Text>
                      </View>
                      <View style={{ flexDirection: "row", marginTop: 5 }}>
                        <Text style={{ fontWeight: 'bold' }}>Total (RM)</Text>
                        <Text style={{ fontWeight: 'bold', marginLeft: 40 }}>RM360.00</Text>
                      </View>
                    </View> */}
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignSelf: 'center',
                                                    justifyContent: 'space-evenly',
                                                    marginTop: 20,
                                                }}>
                                                <View
                                                    style={{
                                                        backgroundColor: Colors.primaryColor,
                                                        width: 200,
                                                        height: 40,
                                                        marginVertical: 15,
                                                        borderRadius: 5,
                                                        alignSelf: 'center',
                                                    }}>
                                                    <TouchableOpacity
                                                        onPress={() => {
                                                            //editStockOrder(); // need fix
                                                        }}>
                                                        <Text
                                                            style={{
                                                                color: Colors.whiteColor,
                                                                alignSelf: 'center',
                                                                marginVertical: 10,
                                                            }}>
                                                            SAVE
                                                        </Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View
                                                    style={{
                                                        backgroundColor: Colors.primaryColor,
                                                        width: 200,
                                                        height: 40,
                                                        marginVertical: 15,
                                                        borderRadius: 5,
                                                        alignSelf: 'center',
                                                        marginLeft: 40,
                                                    }}>
                                                    <TouchableOpacity onPress={() => { }}>
                                                        <Text
                                                            style={{
                                                                color: Colors.whiteColor,
                                                                alignSelf: 'center',
                                                                marginVertical: 10,
                                                            }}>
                                                            SAVE & SEND
                                                        </Text>
                                                    </TouchableOpacity>
                                                </View>
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    backgroundColor: '#ffffff',
                                                    justifyContent: 'center',
                                                    padding: 18,
                                                }}>
                                                <View style={{ alignItems: 'center' }}>
                                                    <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
                                                        {/* {stockList.length} */}
                                                        {suppliers.length}
                                                    </Text>
                                                    <Text>Suppliers</Text>
                                                </View>
                                            </View>
                                        </ScrollView>
                                    </View>
                                </View>
                            ) : null}
                        </View>
                    </View>
                </ScrollView>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.highlightColor,
        flexDirection: 'row',
    },
    headerLogo: {
        width: 112,
        height: 25,
        marginLeft: 10,
    },
    list: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
    },
    listItem: {
        marginLeft: 20,
        color: Colors.descriptionColor,
        fontSize: 16,
    },
    sidebar: {
        width: Dimensions.get('screen').width * Styles.sideBarWidth,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
    content: {
        padding: 16,
        width: Dimensions.get('screen').width * (1 - Styles.sideBarWidth),
    },
    submitText: {
        height:
            Platform.OS == 'ios'
                ? Dimensions.get('screen').height * 0.06
                : Dimensions.get('screen').height * 0.07,
        paddingVertical: 5,
        paddingHorizontal: 20,
        flexDirection: 'row',
        color: '#4cd964',
        textAlign: 'center',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
    submitText1: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        flexDirection: 'row',
        color: '#4cd964',
        textAlign: 'center',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        alignSelf: 'flex-end',
        marginRight: 20,
        marginTop: 15,
    },
    submitText2: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        flexDirection: 'row',
        color: '#4cd964',
        textAlign: 'center',
        alignSelf: 'flex-end',
        marginRight: 20,
        marginTop: 15,
    },
    /* textInput: {
      width: 300,
      height: '10%',
      padding: 20,
      backgroundColor: Colors.fieldtBgColor,
      borderRadius: 5,
      borderRadius: 5,
      paddingTop: 20,
    }, */

    textInput: {
        width: 200,
        height: 40,
        // padding:5,
        backgroundColor: Colors.whiteColor,
        borderRadius: 0,
        marginRight: '35%',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'flex-end',

        shadowOffset:
            Platform.OS == 'ios'
                ? {
                    width: 0,
                    height: 0,
                }
                : {
                    width: 0,
                    height: 7,
                },
        shadowOpacity: Platform.OS == 'ios' ? 0 : 0.43,
        shadowRadius: Platform.OS == 'ios' ? 0 : 0.51,
        elevation: 15,
    },
    searchIcon: {
        backgroundColor: Colors.whiteColor,
        height: 40,
        padding: 10,
        shadowOffset:
            Platform.OS == 'ios'
                ? {
                    width: 0,
                    height: 0,
                }
                : {
                    width: 0,
                    height: 7,
                },
        shadowOpacity: Platform.OS == 'ios' ? 0 : 0.43,
        shadowRadius: Platform.OS == 'ios' ? 0 : 9.51,

        elevation: 15,
    },
    textInput1: {
        width: 160,
        padding: 5,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 5,
        borderRadius: 5,
        paddingTop: 5,
    },
    textInput2: {
        width: 100,
        padding: 5,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 5,
        borderRadius: 5,
        paddingTop: 5,
        textAlign: 'center',
    },
    confirmBox: {
        width: 450,
        height: 450,
        borderRadius: 12,
        backgroundColor: Colors.whiteColor,
    },
    textFieldInput: {
        height: 80,
        width: 900,
        paddingHorizontal: 20,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 5,
        borderRadius: 5,
    },
    circleIcon: {
        width: 30,
        height: 30,
        // resizeMode: 'contain',
        marginRight: 10,
        alignSelf: 'center',
    },
    headerLeftStyle: {
        width: Dimensions.get('screen').width * 0.17,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        flex: 1,
        backgroundColor: Colors.modalBgColor,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalView: {
        height: Dimensions.get('screen').width * 0.2,
        width: Dimensions.get('screen').width * 0.3,
        backgroundColor: Colors.whiteColor,
        borderRadius: 12,
        padding: Dimensions.get('screen').width * 0.03,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalViewImport: {
        height: Dimensions.get('screen').width * 0.6,
        width: Dimensions.get('screen').width * 0.6,
        backgroundColor: Colors.whiteColor,
        borderRadius: Dimensions.get('screen').width * 0.03,
        padding: 20,
        paddingTop: 25,
        //paddingBottom: 30,
    },
    closeButton: {
        position: 'absolute',
        right: Dimensions.get('screen').width * 0.02,
        top: Dimensions.get('screen').width * 0.02,

        elevation: 1000,
        zIndex: 1000,
    },
    modalTitle: {
        alignItems: 'center',
        top: '20%',
        position: 'absolute',
    },
    modalBody: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTitleText: {
        fontFamily: 'NunitoSans-Bold',
        textAlign: 'center',
        fontSize: 20,
    },
    modalDescText: {
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 18,
        color: Colors.fieldtTxtColor,
    },
    modalBodyText: {
        flex: 1,
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 25,
        width: '20%',
    },
    modalSaveButton: {
        width: Dimensions.get('screen').width * 0.15,
        backgroundColor: Colors.fieldtBgColor,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,

        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 1,

        marginVertical: 10,
    },
    list2: {
        // backgroundColor: 'red',
        width: Dimensions.get('screen').width * 0.895,
        minHeight: Dimensions.get('screen').height * 0.1,
        // maxHeight: Dimensions.get('screen').height * 0.825,
        borderWidth: 1,
        borderColor: '#E5E5E5',
        marginTop: 20,
        backgroundColor: Colors.whiteColor,
        // backgroundColor: 'red',
        borderRadius: 8,
    },
    addButtonView: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        backgroundColor: '#4E9F7D',
        borderRadius: 5,
        //width: 160,
        height: 40,
        alignItems: 'center',
    },
});
export default SupplierScreen;
