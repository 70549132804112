import { Store } from 'pullstate';

export const UserStore = new Store({
    avatar: '',
    dob: null,
    email: '',
    gender: '',
    name: '',
    password: '',
    number: '',
    outletId: '',    
    race: '',  
    state: '',
    uniqueName: '',
    updatedAt: null,
    reset: false,

    merchantId: '',
    role: '', // legacy
    refreshToken: '',
    firebaseUid: '',

    ///////////////////////////////////
    // Share from user app

    userAddresses: [],
    selectedUserAddress: null,
});   