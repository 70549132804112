import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Modal,
  FlatList,
  Dimensions,
  Platform,
  useWindowDimensions,
  Picker,
} from "react-native";
import { useLinkTo } from "@react-navigation/native";
import { prefix } from "../constant/env";
import Colors from "../constant/Colors";
import Styles from "../constant/Styles";
import SideBar from "./SideBar";
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import * as User from '../util/User';
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
// import DropDownPicker from "react-native-dropdown-picker";
// import {TextInput} from 'react-native-gesture-handler';
// import Magnify from '../assets/svg/Magnify';
// import FIcon from 'react-native-vector-icons/Foundation';
// import Edit from '../assets/svg/Edit';
import Close from "react-native-vector-icons/AntDesign";
import moment from "moment";
import Feather from "react-native-vector-icons/Feather";
// import DropDownPickerPlus from './components/DropDownPickerPlus';
// import Ionicon from 'react-native-vector-icons/Ionicons';
// import Swipeable from "react-native-gesture-handler/Swipeable";
// import {isTablet} from 'react-native-device-detection';
import { UserStore } from "../store/userStore";
import { MerchantStore } from "../store/merchantStore";
import { OutletStore } from "../store/outletStore";
// import AsyncImage from '../components/asyncImage';
// import DateTimePickerModal from 'react-native-modal-datetime-picker';
import { CommonStore } from "../store/commonStore";
import ApiClient from "../util/ApiClient";
import API from "../constant/API";

const dummyParking = [
  {
    id: 1,
    name: "Beer",
    total: 653,
    categoryItems: [
      {
        name: "Tiger",
        quantity: 301,
      },
      {
        name: "Heineken",
        quantity: 250,
      },
      {
        name: "Carlsberg",
        quantity: 102,
      },
    ],
  },
  {
    id: 2,
    name: "Sake",
    total: 149,
    categoryItems: [
      {
        name: "Suntory",
        quantity: 123,
      },
      {
        name: "Caito",
        quantity: 26,
      },
    ],
  },
  {
    id: 3,
    name: "Soju",
    total: 294,
    categoryItems: [
      {
        name: "Grape",
        quantity: 94,
      },
      {
        name: "Peach",
        quantity: 200,
      },
    ],
  },
  {
    id: 4,
    name: "Cognac",
    total: 275,
    categoryItems: [
      {
        name: "Hennessy",
        quantity: 173,
      },
      {
        name: "Salignac",
        quantity: 102,
      },
    ],
  },
];
const dummyUsers = [
  {
    id: 3,
    username: "FJ123",
    items: [
      {
        name: "Beer",
        categoryItems: {
          name: "Tiger",
          quantity: 4,
        },
      },
    ],
    outlet: [
      {
        name: "Mont Kiara",
      },
      {
        name: "Kuchai Lama",
      },
    ],
    purchaseAt: "2020-11-29T01:12:38+00:00",
    expiredAt: "2020-12-31T01:12:38+00:00",
    waiter: "Radius Khor",
  },
];

const RedemptionRedeemedScreen = (props) => {
  const { navigation } = props;

  const linkTo = useLinkTo();

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const [search, setSearch] = useState("");
  const [parkingType, setParkingType] = useState(false);
  const [renderUserList, setRenderUserList] = useState(false);
  const [userList, setUserList] = useState([]);

  const [redemptionSummaryList, setRedemptionSummaryList] = useState([]);
  const [currUserBeerDocketList, setCurrUserBeerDocketList] = useState([]);

  const [selectedUserBeerDocket, setSelectedUserBeerDocket] = useState({});

  const [outletItemsStrList, setOutletItemsStrList] = useState([]);
  const [outletItemsImageList, setOutletItemsImageList] = useState([]);

  const [showEdit, setShowEdit] = useState(false);
  const [isExtendedDate, setIsExtendedDate] = useState(false);
  const [optionsDropdownList, setOptionsDropdownList] = useState([]);
  const [selectedOptionsId, setSelectedOptionsId] = useState("");
  const [bdRedeemQuantity, setBdRedeemQuantity] = useState(0);
  const [extendedDateTime, setExtendedDateTime] = useState(moment());
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDays, setTotalDays] = useState(0);
  const [selectedOutletIdList, setSelectedOutletIdList] = useState([]);

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const beerDocketCategories = OutletStore.useState(
    (s) => s.beerDocketCategories
  );
  const beerDockets = OutletStore.useState((s) => s.beerDockets);
  const beerDocketsDict = OutletStore.useState((s) => s.beerDocketsDict);
  const userBeerDockets = OutletStore.useState((s) => s.userBeerDockets);
  const userOrderBeerDocketUBDIdDict = OutletStore.useState(
    (s) => s.userOrderBeerDocketUBDIdDict
  );
  const allOutletsItemsSkuDict = OutletStore.useState(
    (s) => s.allOutletsItemsSkuDict
  );
  const allOutletsCategoriesDict = OutletStore.useState(
    (s) => s.allOutletsCategoriesDict
  );

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const setState = () => { };

  //////////////////////////////////////////////////////////////

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);

  const updateUserBeerDocketExpiryDateByMerchant = async () => {
    var body = {
      userBeerDocketId: selectedUserBeerDocket.uniqueId,
      extendedDate: moment(extendedDateTime).valueOf(),
    };

    console.log(body);

    ApiClient.POST(
      API.updateUserBeerDocketExpiryDateByMerchant,
      body,
      false
    ).then((result) => {
      if (result && result.status === "success") {
        Alert.alert(
          "Success",
          "Expiry date extended",
          [
            {
              text: "OK",
              onPress: () => {
                setShowEdit(false);
              },
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  useEffect(() => {
    var selectedOutletIdListTemp = [];

    var totalPriceTemp = 0;
    var totalDaysTemp = 0;

    if (
      selectedUserBeerDocket &&
      beerDocketsDict[selectedUserBeerDocket.beerDocketId]
    ) {
      selectedOutletIdListTemp =
        beerDocketsDict[selectedUserBeerDocket.beerDocketId].bdOutlets;

      // override
      if (selectedUserBeerDocket.bdOutlets) {
        selectedOutletIdListTemp = selectedUserBeerDocket.bdOutlets;
      }

      //////////////////////////////////////////////////////////////////////////////

      // extend docket

      const beerDocket = beerDocketsDict[selectedUserBeerDocket.beerDocketId];

      setOptionsDropdownList(
        beerDocket.bdOptions.map((bdOption) => ({
          label: `RM${bdOption.price
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} for ${bdOption.duration
            } day${bdOption.duration > 1 ? "s" : ""}`,
          value: bdOption.optionsId,
        }))
      );

      if (selectedOptionsId === "" && beerDocket.bdOptions.length > 0) {
        setSelectedOptionsId(beerDocket.bdOptions[0].optionsId);
      }

      for (var i = 0; i < beerDocket.bdOptions.length; i++) {
        if (beerDocket.bdOptions[i].optionsId === selectedOptionsId) {
          if (bdRedeemQuantity > 0) {
            totalPriceTemp = beerDocket.bdOptions[i].price * bdRedeemQuantity;
            totalDaysTemp = beerDocket.bdOptions[i].duration * bdRedeemQuantity;
          } else {
            totalPriceTemp = beerDocket.bdOptions[i].price;
            totalDaysTemp = beerDocket.bdOptions[i].duration;
          }

          break;
        }
      }
    }

    setTotalPrice(totalPriceTemp);
    setTotalDays(totalDaysTemp);

    setSelectedOutletIdList(selectedOutletIdListTemp);
  }, [
    selectedUserBeerDocket,
    beerDocketsDict,
    selectedOptionsId,
    bdRedeemQuantity,
  ]);

  useEffect(() => {
    var redemptionSummaryCategoryNameDict = {};
    var currUserBeerDocketListTemp = [];

    if (
      Object.keys(allOutletsItemsSkuDict).length > 0 &&
      Object.keys(allOutletsCategoriesDict).length > 0
    ) {
      for (var i = 0; i < userBeerDockets.length; i++) {
        var isRedeemed = true;

        for (var j = 0; j < userBeerDockets[i].bdItems.length; j++) {
          if (userBeerDockets[i].bdItems[j].quantity > 0) {
            isRedeemed = false;
            break;
          }
        }

        if (isRedeemed) {
          currUserBeerDocketListTemp.push(userBeerDockets[i]);

          var beerDocket = null;

          if (beerDocketsDict[userBeerDockets[i].beerDocketId]) {
            beerDocket = beerDocketsDict[userBeerDockets[i].beerDocketId];
          }

          if (beerDocket) {
            for (var j = 0; j < beerDocket.bdItems.length; j++) {
              const outletItem =
                allOutletsItemsSkuDict[beerDocket.bdItems[j].outletItemSku][0];

              const outletCategory =
                allOutletsCategoriesDict[outletItem.categoryId];

              if (outletCategory) {
                if (redemptionSummaryCategoryNameDict[outletCategory.name]) {
                  var redemptionSummary =
                    redemptionSummaryCategoryNameDict[outletCategory.name];

                  redemptionSummary.quantity += beerDocket.bdItems[j].quantity;

                  var isExisted = false;
                  for (var k = 0; k < redemptionSummary.items.length; k++) {
                    if (redemptionSummary.items[k].sku === outletItem.sku) {
                      isExisted = true;
                      redemptionSummary.items[k].quantity +=
                        beerDocket.bdItems[j].quantity;
                    }
                  }

                  if (!isExisted) {
                    redemptionSummary.items.push({
                      uniqueId: outletItem.uniqueId,
                      sku: outletItem.sku,
                      name: outletItem.name,
                      quantity: beerDocket.bdItems[j].quantity,
                    });
                  }

                  redemptionSummaryCategoryNameDict[outletCategory.name] =
                    redemptionSummary;
                } else {
                  var redemptionSummary = {
                    categoryId: outletCategory.uniqueId,
                    name: outletCategory.name,
                    quantity: beerDocket.bdItems[j].quantity,
                    items: [
                      {
                        uniqueId: outletItem.uniqueId,
                        sku: outletItem.sku,
                        name: outletItem.name,
                        quantity: beerDocket.bdItems[j].quantity,
                      },
                    ],
                  };

                  redemptionSummaryCategoryNameDict[outletCategory.name] =
                    redemptionSummary;
                }
              }
            }
          }
        }
      }
    }

    setRedemptionSummaryList(
      Object.entries(redemptionSummaryCategoryNameDict).map(([key, value]) => ({
        ...value,
      })),
    );

    setCurrUserBeerDocketList(currUserBeerDocketListTemp);
  }, [userBeerDockets, allOutletsItemsSkuDict, allOutletsCategoriesDict]);

  //////////////////////////////////////////////////////////////

  // navigation.dangerouslyGetParent().setOptions({
  //     tabBarVisible: false
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            // bottom: -2,
            bottom: switchMerchant ? "2%" : 0,
          },
          Dimensions.get("screen").width >= 768 && switchMerchant
            ? { right: Dimensions.get("screen").width * 0.1 }
            : {},
          Dimensions.get("screen").width <= 768
            ? { right: Dimensions.get("screen").width * 0.12 }
            : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Redeemed Docket
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: Dimensions.get("screen").height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get("screen").height * 0.05,
              height: Dimensions.get("screen").height * 0.05,
              borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: Dimensions.get("screen").height * 0.035,
                height: Dimensions.get("screen").height * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // function here

  // function end

  const renderAlcoholCategory = ({ item }) => {
    return (
      <View
        style={{
          width: windowWidth * 0.165,
          height: windowHeight * 0.43,
          padding: 5,
          marginRight: 20,
          borderRadius: 17,
          justifyContent: "space-between",
        }}
      >
        <View
          style={[
            {
              width: "100%",
              height: "33%",
              padding: 15,
              backgroundColor: Colors.lightRed,
              borderRadius: 17,
              justifyContent: "space-around",
            },
            switchMerchant
              ? {
                borderRadius: 10,
              }
              : {},
          ]}
        >
          <Text
            style={[
              { fontFamily: "NunitoSans-Regular" },
              switchMerchant
                ? {
                  fontFamily: "NunitoSans-Regular",
                  fontSize: 15,
                }
                : {},
            ]}
            numberOfLines={1}
          >
            {item.name}
          </Text>
          <View style={{ marginVertical: switchMerchant ? 10 : 0 }} />
          <Text
            style={[
              { fontFamily: "NunitoSans-Bold", fontSize: 40 },
              switchMerchant
                ? {
                  fontSize: 20,
                }
                : {},
            ]}
          >
            {item.quantity}
          </Text>
        </View>
        <View
          style={[
            {
              width: "100%",
              height: "63%",
              padding: 10,
              backgroundColor: Colors.lightGrey,
              borderRadius: 17,
            },
            switchMerchant
              ? {
                borderRadius: 10,
              }
              : {},
          ]}
        >
          <View
            style={{
              width: "100%",
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderColor: Styles.fieldtTxtColor,
              paddingBottom: 8,
            }}
          >
            <Text
              style={[
                { fontFamily: "NunitoSans-Regular" },
                switchMerchant
                  ? {
                    fontSize: 15,
                  }
                  : {},
              ]}
            >
              {item.name}
            </Text>
          </View>
          <FlatList
            nestedScrollEnabled={true}
            data={item.items}
            keyExtractor={(item, index) => String(index)}
            renderItem={renderCategoryItems}
          />
        </View>
      </View>
    );
  };

  const renderCategoryItems = ({ item }) => {
    return (
      <ScrollView
        showsVerticalScrollIndicator={false}
        scrollEnabled={switchMerchant}
      // paddingBottom={50}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginVertical: 5,
            width: "95%",
          }}
        >
          <Text
            style={[
              { fontFamily: "NunitoSans-Bold", fontSize: 16, width: "92%" },
              switchMerchant
                ? {
                  fontSize: 10,
                }
                : {},
            ]}
            numberOfLines={2}
            numberOfLines={2}
          >
            {item.name}
          </Text>
          <Text
            style={[
              { fontFamily: "NunitoSans-Regular", fontSize: 16 },
              switchMerchant
                ? {
                  fontSize: 15,
                }
                : {},
            ]}
            numberOfLines={2}
          >
            {item.quantity}
          </Text>
        </View>
      </ScrollView>
    );
  };

  const rightAction = (item) => {
    return (
      <View style={styles.insideSwipe}>
        <TouchableOpacity
          onPress={() => {
            setShowEdit(true);
            setIsExtendedDate(false);
            setSelectedUserBeerDocket(item);
          }}
        >
          <View
            style={[
              styles.swipeButton,
              { backgroundColor: Colors.primaryColor },
              switchMerchant
                ? { height: windowHeight * 0.285, width: 120 }
                : {},
            ]}
          >
            <Icon
              name={"alarm"}
              size={switchMerchant ? 30 : 50}
              color={"white"}
            />
            <Text
              style={[
                styles.swipeFont,
                { color: "white" },
                switchMerchant
                  ? {
                    fontSize: 10,
                  }
                  : {},
              ]}
            >
              Extend Expire Date
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const renderList = ({ item }) => {
    const beerDocket = beerDocketsDict[item.beerDocketId];

    const bdOutletItemsStrList = [];
    const bdOutletItemsImageList = [];

    if (beerDocket && beerDocket.uniqueId) {
      for (var i = 0; i < beerDocket.bdItems.length; i++) {
        if (allOutletsItemsSkuDict[beerDocket.bdItems[i].outletItemSku]) {
          bdOutletItemsStrList.push(
            `${allOutletsItemsSkuDict[beerDocket.bdItems[i].outletItemSku][0]
              .name
            } (x${beerDocket.bdItems[i].quantity})`
          );
          bdOutletItemsImageList.push(
            allOutletsItemsSkuDict[beerDocket.bdItems[i].outletItemSku][0].image
          );
        }
      }
    }

    return (
      // <Swipeable renderRightActions={() => rightAction(item)}>
      <TouchableOpacity
        onPress={() => {
          setShowEdit(true);
          setIsExtendedDate(false);
          setSelectedUserBeerDocket(item);
        }}
      >
        <View
          style={[
            {
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: Colors.lightRed,
              height: windowHeight * 0.185,
              marginBottom: 20,
              borderRadius: 15,
              overflow: "hidden",
            },
            switchMerchant
              ? {
                height: windowHeight * 0.285,
                borderRadius: 10,
              }
              : {},
          ]}
        >
          <View
            style={{
              backgroundColor: Colors.tabRed,
              width: 13,
              height: "100%",
              borderRadius: 18,
            }}
          />
          <View style={{ flex: 1, paddingVertical: 10 }}>
            <View
              style={{
                flexDirection: "row",
                flex: 1.2,
                alignItems: "center",
                paddingHorizontal: 20,
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Text
                  style={[
                    {
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 20,
                      width: windowWidth * 0.3,
                    },
                    switchMerchant
                      ? {
                        fontSize: 15,
                      }
                      : {},
                  ]}
                >
                  User Name: {item.userName}
                </Text>
                {/* <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 20, marginLeft: 15 }}>ID: 00{item.id}</Text> */}
              </View>
              <View
                style={{
                  backgroundColor: Colors.secondaryColor,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingHorizontal: 20,
                  borderRadius: 4,
                }}
              >
                <Text
                  style={[
                    {
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 16,
                      marginRight: 6,
                      color: Colors.whiteColor,
                    },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}
                >
                  Expired {moment(item.expiryDate).fromNow()}
                </Text>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                flex: 2,
                paddingVertical: 10,
              }}
            >
              <View
                style={{
                  flex: 1.6,
                  paddingHorizontal: 20,
                  borderRightWidth: StyleSheet.hairlineWidth,
                  borderLeftColor: Colors.tabGrey,
                }}
              >
                <Text
                  style={[
                    { fontFamily: "NunitoSans-Bold", fontSize: 16 },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}
                >
                  Items
                </Text>
                <ScrollView
                  showsVerticalScrollIndicator={false}
                  nestedScrollEnabled={true}
                >
                  {beerDocket &&
                    bdOutletItemsStrList.map((categoryName) => {
                      return (
                        <View style={{ flexDirection: "row" }}>
                          {/* <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: 15, marginRight: 6 }}>{i.categoryItems.name}</Text> */}
                          <Text
                            style={[
                              {
                                fontFamily: "NunitoSans-Regular",
                                fontSize: 16,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                          >
                            {categoryName}
                          </Text>
                        </View>
                      );
                    })}
                </ScrollView>
              </View>
              {/* <View style={{ flex: 0.8, paddingHorizontal: 10, borderRightWidth: StyleSheet.hairlineWidth, borderLeftColor: Colors.tabGrey }}>
                                <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 17 }}>Quantity:</Text>                                
                                <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: 15, marginRight: 6 }}>{item.quantity} mugs</Text>
                            </View> */}
              <View
                style={{
                  flex: 1.7,
                  paddingHorizontal: 10,
                  borderRightWidth: StyleSheet.hairlineWidth,
                  borderLeftColor: Colors.tabGrey,
                }}
              >
                <Text
                  style={[
                    { fontFamily: "NunitoSans-Bold", fontSize: 16 },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}
                >
                  Outlet
                </Text>
                <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                  {/* {item.outlet.map(i => { */}
                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    nestedScrollEnabled={true}
                  >
                    {beerDocket &&
                      beerDocket.bdOutletNames.map((outletName) => {
                        return (
                          <View
                            style={{
                              backgroundColor: Colors.tabGrey,
                              paddingHorizontal: 8,
                              marginBottom: 2,
                              borderRadius: 2,
                              alignItems: "center",
                              justifyContent: "center",
                              alignSelf: "baseline",
                            }}
                          >
                            <Text
                              style={[
                                {
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 13,
                                  color: Colors.whiteColor,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                            >
                              {outletName}
                            </Text>
                          </View>
                        );
                      })}
                  </ScrollView>
                </View>
              </View>
              <View
                style={{
                  flex: 1.5,
                  paddingHorizontal: 10,
                  borderRightWidth: StyleSheet.hairlineWidth,
                  borderLeftColor: Colors.tabGrey,
                }}
              >
                <Text
                  style={[
                    { fontFamily: "NunitoSans-Bold", fontSize: 16 },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}
                >
                  Expired Date
                </Text>
                <Text
                  style={[
                    { fontFamily: "NunitoSans-Regular", fontSize: 16 },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}
                >
                  {moment(item.expiryDate).format("DD MMM YYYY")}
                </Text>
              </View>
              <View
                style={{
                  flex: 1,
                  paddingHorizontal: 10,
                  borderLeftColor: Colors.tabGrey,
                }}
              >
                <Text
                  style={[
                    { fontFamily: "NunitoSans-Bold", fontSize: 16 },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}
                >
                  Waiter
                </Text>
                <Text
                  style={[
                    { fontFamily: "NunitoSans-Regular", fontSize: 16 },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}
                >
                  {item.uniqueId &&
                    userOrderBeerDocketUBDIdDict[item.uniqueId] &&
                    userOrderBeerDocketUBDIdDict[item.uniqueId].waiterName
                    ? userOrderBeerDocketUBDIdDict[item.uniqueId].waiterName
                    : "N/A"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      // </Swipeable>
    );
  };

  // const sortingOrders = (param) => {
  //   if (param.value == 1) {
  //     //orderid
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = order.sort((a, b) => a.id - b.id);
  //       setState({order});
  //     } else {
  //       const order = order.sort((a, b) => b.id - a.id);
  //       setState({order});
  //     }
  //   }
  //   if (param.value == 2) {
  //     //date time
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = order.sort(
  //         (a, b) => moment(a.collectionTime) - moment(b.collectionTime),
  //       );
  //       setState({order});
  //     } else {
  //       const order = order.sort(
  //         (a, b) => moment(b.collectionTime) - moment(a.collectionTime),
  //       );
  //       setState({order});
  //     }
  //   }
  //   if (param.value == 3) {
  //     //Name
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = order.sort((a, b) =>
  //         b.user.name.localeCompare(a.user.name),
  //       );
  //       setState({order});
  //     } else {
  //       const order = order.sort((a, b) =>
  //         a.user.name.localeCompare(b.user.name),
  //       );
  //       setState({order});
  //     }
  //   }
  //   if (param.value == 4) {
  //     //Waiting Time
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = order.sort(
  //         (a, b) => a.totalPreparationTime - b.totalPreparationTime,
  //       );
  //       setState({order});
  //     } else {
  //       const order = order.sort(
  //         (a, b) => b.totalPreparationTime - a.totalPreparationTime,
  //       );
  //       setState({order});
  //     }
  //   }
  //   if (param.value == 5) {
  //     //Payment Method
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = order.sort((a, b) => b.status - a.status);
  //       setState({order});
  //     } else {
  //       const order = order.sort((a, b) => a.status - b.status);
  //       setState({order});
  //     }
  //   }
  //   if (param.value == 6) {
  //     //Total
  //     if (lastSort != null ? lastSort.value == param.value : null) {
  //       const order = order.sort((a, b) => a.finalPrice - b.finalPrice);
  //       setState({order});
  //     } else {
  //       const order = order.sort((a, b) => b.finalPrice - a.finalPrice);
  //       setState({order});
  //     }
  //   }
  //   setState({lastSort: param});
  // };

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar
          navigation={navigation}
          selectedTab={4}
          expandRedemption={true}
        />
      </View>

      <Modal
        style={
          {
            // flex: 1
          }
        }
        visible={showEdit}
        transparent={true}
        animationType="slide"
      >
        <View
          style={[
            styles.modalContainer,
            { backgroundColor: Colors.modalBgColor },
          ]}
        >
          <View
            style={[
              styles.sectionModal,
              {
                width: windowWidth * 0.4,
              }
            ]}
          >
            <TouchableOpacity
              style={{
                position: "absolute",
                right: windowWidth * 0.02,
                top: windowWidth * 0.02,
                elevation: 100000,
                zIndex: 100000,
              }}
              onPress={() => {
                // setState({ showEdit: false });
                setShowEdit(false);
              }}
            >
              <Close
                name="closecircle"
                size={switchMerchant ? 15 : 25}
                color={Colors.fieldtTxtColor}
              />
            </TouchableOpacity>
            <View
              style={{
                //    alignItems: 'center',
                top: "15%",
                //    position: 'absolute',
                justifyContent: "space-around",
              }}
            >
              <View
                style={[
                  { marginBottom: 20 },
                  switchMerchant
                    ? {
                      marginBottom: 0,
                    }
                    : {},
                ]}
              >
                <Text
                  style={[
                    {
                      fontFamily: "NunitoSans-Bold",
                      textAlign: "center",
                      fontSize: 27,
                    },
                    switchMerchant
                      ? {
                        fontSize: 16,
                      }
                      : {},
                  ]}
                >
                  Extend Expiration Date
                </Text>
                <Text
                  style={[
                    {
                      fontFamily: "NunitoSans-Bold",
                      textAlign: "center",
                      color: Colors.descriptionColor,
                      fontSize: 20,
                    },
                    switchMerchant
                      ? {
                        fontSize: 13,
                      }
                      : {},
                  ]}
                >
                  Set your extension date
                </Text>
              </View>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
                width: "80%",
                top: "17%",
                alignSelf: "center",
              }}
            >
              {optionsDropdownList.find(
                (o) => o.value === selectedOptionsId
              ) ? (
                <Picker
                  selectedValue={selectedOptionsId}
                  onValueChange={(item) => {
                    setSelectedOptionsId(item.value);
                  }}
                  style={[
                    {
                      width: "100%",
                      height: 40,

                      borderColor: Colors.descriptionColor,

                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                    },
                  ]}
                >
                  {optionsDropdownList.map((value, index) => {
                    return (
                      <Picker.Item
                        key={index}
                        label={value.label}
                        value={value.value}
                      />
                    );
                  })}
                </Picker>
              ) : (
                <></>
              )}
            </View>

            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
                top: "17%",
                zIndex: -1,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  onPress={() => {
                    setBdRedeemQuantity(
                      bdRedeemQuantity - 1 >= 0 ? bdRedeemQuantity - 1 : 0
                    );
                  }}
                >
                  <View
                    style={[
                      styles.addBtn,
                      { backgroundColor: Colors.descriptionColor },
                      switchMerchant
                        ? {
                          height: 35,
                          width: 35,
                        }
                        : {},
                    ]}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 30,
                          fontWeight: "500",
                          color: Colors.whiteColor,
                        },
                        switchMerchant
                          ? {
                            height: 30,
                            fontSize: 20,
                          }
                          : {},
                      ]}
                    >
                      -
                    </Text>
                  </View>
                </TouchableOpacity>
                <View
                  style={[
                    styles.addBtn,
                    {
                      backgroundColor: Colors.whiteColor,
                      borderWidth: StyleSheet.hairlineWidth,
                      borderColor: Colors.descriptionColor,
                      borderWidth: 1.5,

                      width: 50,
                    },
                    switchMerchant
                      ? {
                        height: 35,
                        width: 35,
                      }
                      : {},
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontSize: 25,
                        fontWeight: "bold",
                        color: Colors.primaryColor,
                      },
                      switchMerchant
                        ? {
                          fontSize: 10,
                        }
                        : {},
                    ]}
                  >
                    {/* {quantityFunc()} */}
                    {bdRedeemQuantity}
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    setBdRedeemQuantity(bdRedeemQuantity + 1);
                  }}
                >
                  <View
                    style={[
                      styles.addBtn,
                      {
                        backgroundColor: Colors.primaryColor,
                        left: -1,
                      },
                      switchMerchant
                        ? {
                          height: 35,
                          width: 35,
                        }
                        : {},
                    ]}
                  >
                    <Text
                      style={[
                        {
                          fontSize: 30,
                          fontWeight: "500",
                          color: Colors.whiteColor,
                        },
                        switchMerchant
                          ? {
                            height: 30,
                            fontSize: 20,
                          }
                          : {},
                      ]}
                    >
                      +
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>

            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 10,
                top: "17%",
                zIndex: -1,
              }}
            >
              <Text
                style={[
                  {
                    fontWeight: "bold",
                    fontSize: 15,
                    color: Colors.descriptionColor,
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}
              >
                {/* RM20.00 per day */}
                {`RM${totalPrice
                  .toFixed(2)
                  .replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1,"
                  )} for ${totalDays} day${totalDays > 1 ? "s" : ""}`}
              </Text>
            </View>

            <View
              style={[
                {
                  flexDirection: "row",
                  height: 60,
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: "18.5%",
                  borderTopWidth: StyleSheet.hairlineWidth,
                  borderTopColor: Colors.descriptionColor,
                },
              ]}
            >
              <View
                style={[
                  {
                    flex: 1,
                    alignContent: "center",
                    justifyContent: "center",
                    height: 60,
                    borderRightWidth: StyleSheet.hairlineWidth,
                    borderRightColor: Colors.descriptionColor,
                  },
                  switchMerchant
                    ? {
                      height: 35,
                    }
                    : {},
                ]}
              >
                <TouchableOpacity
                  onPress={() => {
                    updateUserBeerDocketExpiryDateByMerchant();
                  }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 27,
                        fontFamily: 'NunitoSans-Regular',
                        color: Colors.primaryColor,
                        textAlign: 'center'
                      },
                    ]}>
                    Confirm
                  </Text>
                </TouchableOpacity>
              </View>
              <View
                style={{
                  flex: 1,
                  alignContent: 'center',
                  justifyContent: 'center',
                  height: 70,
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    // setState({ showEdit: false });
                    setShowEdit(false);
                  }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 27,
                        fontFamily: 'NunitoSans-Regular',
                        color: 'red',
                        textAlign: 'center'
                      },
                    ]}
                  >
                    Cancel
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>

      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View
            style={[
              {
                flex: 1,
                width: windowWidth * 0.9,
                //padding: 20,
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 1,
              },
              switchMerchant ? {} : {},
            ]}
          >
            {renderUserList ? (
              <View>
                <TouchableOpacity
                  style={{
                    marginBottom: 20,
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                  onPress={() => {
                    setState({ renderUserList: false });
                  }}
                >
                  <Feather
                    name="chevron-left"
                    size={30}
                    color={Colors.primaryColor}
                  />
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: switchMerchant ? 14 : 17,
                      color: Colors.primaryColor,
                    }}
                  >
                    {" "}
                    Back{" "}
                  </Text>
                </TouchableOpacity>
              </View>
            ) : null}

            {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30, }}>
                    <View style={{ flexDirection: 'row', paddingHorizontal: 10, width: 200, height: 40, borderRadius: 5, elevation: 10, backgroundColor: Colors.whiteColor, alignItems: 'center' }}>
                        <Magnify height={20} width={20} />
                        <TextInput
                            style={{ paddingLeft: 20 }}
                            onChangeText={text => setState({ search: text })}
                            placeholder="Search Customer"
                            placeholderTextColor={Colors.fieldtTxtColor} />

                    </View>

                    <View style={{ flexDirection: 'row', }}>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10, 
                            borderRadius: 5,
                            height: 40,
                            backgroundColor: Colors.whiteColor,
                            width: 220,
                            marginRight: 30
                            }}> 
                            <Text style={{ fontSize: 18, borderRightWidth: StyleSheet.hairlineWidth, paddingRight: 10, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>Sort By</Text>
                            <DropDownPicker
                                arrowColor={Colors.primaryColor}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                                style={{ width: 145, borderWidth: 0, paddingVertical: 0 }}
                                dropDownStyle={{ width: 145 }}
                                placeholderStyle={{ color: 'black' }}
                                items={[{ label: 'Order ID', value: 1 }, { label: 'Date/Time', value: 2 }, { label: 'Name', value: 3 }, { label: 'Waiting Time', value: 4 }, { label: 'Payment Status', value: 5 }, { label: 'Total', value: 6 }]}
                                itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                                placeholder={"Order ID"}
                                onChangeItem={selectedSort => {
                                    setState({ sort: selectedSort }),
                                        sortingOrders(selectedSort);

                                }
                                }
                            />
                        </View>
                        <View style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            paddingLeft: 10, 
                            borderRadius: 5,
                            height: 40,
                            backgroundColor: Colors.whiteColor,
                            width: 210,
                            }}>
                            <Text style={{ fontSize: 18, borderRightWidth: StyleSheet.hairlineWidth, paddingRight: 10, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>Filter</Text>
                            <DropDownPicker
                                arrowColor={Colors.primaryColor}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                                style={{ width: 145, borderWidth: 0, paddingVertical: 0 }}
                                dropDownStyle={{ width: 145 }}
                                placeholderStyle={{ color: 'black' }}
                                itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                                items={[{ label: 'Items', value: 1 }, { label: 'Quantity', value: 2 }, { label: 'Outlet', value: 3 }, { label: 'Expired Date', value: 4 }, { label: 'Waiter', value: 5 }]}
                                placeholder={"Items"}
                                onChangeItem={selectedSort => {
                                    setState({ sort: selectedSort }),
                                        sortingOrders(selectedSort);

                                }
                                }
                            />
                        </View>
                    </View>
                </View> */}

            <ScrollView
              showsVerticalScrollIndicator={false}
              nestedScrollEnabled={true}
              style={[
                {
                  backgroundColor: Colors.whiteColor,
                  width: windowWidth * 0.87,
                  marginTop: 40,
                  // marginHorizontal: 30,
                  marginBottom: 30,
                  marginLeft: 40,
                  alignSelf: "center",
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                },
                switchMerchant
                  ? {
                    height: Dimensions.get("window").height * 0.73,
                    width: windowWidth * 0.82,
                    // marginHorizontal: 80,
                    marginLeft: 0,
                    marginTop: 20,
                    alignSelf: "center",
                    marginLeft: 0,
                  }
                  : {},
              ]}
            >
              <View
                style={[
                  { flex: 1, marginBottom: 20, zIndex: -1 },
                  ,
                  switchMerchant
                    ? {
                      // marginBottom: 50,
                    }
                    : {},
                ]}
              >
                <View
                  style={[
                    {
                      height: windowHeight * 0.07,
                      backgroundColor: Colors.lightPrimary,
                      flexDirection: "row",
                      alignItems: "center",
                      borderTopLeftRadius: 5,
                      borderTopRightRadius: 5,
                    },
                    switchMerchant
                      ? {
                        width: windowWidth * 0.82,
                      }
                      : {},
                  ]}
                >
                  <TouchableOpacity
                    style={{ paddingHorizontal: 20 }}
                    onPress={() => {
                      // navigation.navigate('Redemption');
                      linkTo && linkTo(`${prefix}/redemption`);
                    }}
                  >
                    <View
                      style={{
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottomWidth: parkingType == false ? 0 : 0,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderBottomColor: Colors.primaryColor,
                        marginBottom: 5,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 16,
                            color: Colors.fieldtTxtColor,
                          },
                          switchMerchant
                            ? {
                              fontSize: 13,
                            }
                            : {},
                        ]}
                      >
                        Active Docket
                      </Text>
                    </View>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{ paddingHorizontal: 20 }}
                    onPress={() => {
                      // navigation.navigate('RedemptionExpired');
                      linkTo && linkTo(`${prefix}/redemptionexpired`);
                    }}
                  >
                    <View
                      style={{
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottomWidth: true ? 0 : 0,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderBottomColor: Colors.primaryColor,
                        marginBottom: 5,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 16,
                            color: Colors.fieldtTxtColor,
                          },
                          switchMerchant
                            ? {
                              fontSize: 13,
                            }
                            : {},
                        ]}
                      >
                        Expired Docket
                      </Text>
                    </View>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{ paddingHorizontal: 20 }}
                    onPress={() => { }}
                  >
                    <View
                      style={{
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        borderBottomWidth: true ? 4 : 0,
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        borderBottomColor: Colors.primaryColor,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 16,
                            color: Colors.fieldtTxtColor,
                          },
                          switchMerchant
                            ? {
                              fontSize: 13,
                            }
                            : {},
                        ]}
                      >
                        Redeemed Docket
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    flex: 1,
                    padding: 40,
                    paddingTop: 20,
                  }}
                >
                  <View style={{ flexDirection: "row", flex: 6 }}>
                    <View style={{ flex: 1.3 }}>
                      <View style={{ width: windowWidth * 0.175 }}>
                        <Text
                          style={[
                            {
                              alignSelf: "flex-start",
                              padding: 6,
                              fontFamily: "NunitoSans-Regular",
                              fontSize: 18,
                            },
                            switchMerchant
                              ? {
                                fontSize: 16,
                              }
                              : {},
                          ]}
                        >
                          Summary
                        </Text>
                        <View
                          style={[
                            {
                              width: windowWidth * 0.175,
                              backgroundColor: Colors.tabRed,
                              height: windowHeight * 0.43,
                              padding: 15,
                              borderRadius: 20,
                            },
                            switchMerchant
                              ? {
                                borderRadius: 10,
                              }
                              : {},
                          ]}
                        >
                          <Text
                            style={[
                              {
                                fontSize: 18,
                                fontFamily: "NunitoSans-Regular",
                                color: Colors.whiteColor,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 16,
                                }
                                : {},
                            ]}
                          >
                            Total Docket
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: 35,
                                fontFamily: "NunitoSans-Bold",
                                color: Colors.whiteColor,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 20,
                                }
                                : {},
                            ]}
                          >
                            {currUserBeerDocketList.length}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={[
                        {
                          flex: 3.13,
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          opacity: currUserBeerDocketList.length > 0 ? 100 : 0,
                        },
                        switchMerchant
                          ? {
                            flex: 3,
                          }
                          : {},
                      ]}
                    >
                      <Text
                        style={[
                          {
                            alignSelf: "flex-start",
                            padding: 6,
                            fontFamily: "NunitoSans-Regular",
                            fontSize: 18,
                          },
                          switchMerchant
                            ? {
                              fontSize: 15,
                            }
                            : {},
                        ]}
                      >
                        Category
                      </Text>
                      <FlatList
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        data={redemptionSummaryList}
                        renderItem={renderAlcoholCategory}
                        keyExtractor={(item, index) => String(index)}
                      />
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    borderTopWidth: StyleSheet.hairlineWidth,
                    borderTopColor: Colors.descriptionColor,
                    marginLeft: 40,
                    marginRight: 20,
                  }}
                >
                  <Text
                    style={[
                      {
                        alignSelf: "flex-start",
                        fontFamily: "NunitoSans-Regular",
                        fontSize: 18,
                        paddingVertical: 15,
                      },
                      switchMerchant
                        ? {
                          fontSize: 20,
                        }
                        : {},
                    ]}
                  >
                    Users
                  </Text>
                  {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 30, }}>

                                <View style={{ flexDirection: 'row', paddingHorizontal: 10, width: 200, height: 40, backgroundColor: Colors.fieldtBgColor, alignItems: 'center', borderColor: '#E5E5E5', borderWidth: 1, borderRadius: 5 }}>
                                    <Magnify height={20} width={20} />
                                    <TextInput
                                        style={{ paddingLeft: 10 }}
                                        onChangeText={text => setState({ search: text })}
                                        placeholder="Search Customer"
                                        placeholderTextColor={Colors.fieldtTxtColor} />

                                </View>
                                

                                <View style={{ flexDirection: 'row', }}>
                                    <View style={{ marginRight: 0, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 10, height: 40, paddingLeft: 10, borderColor: '#E5E5E5', borderWidth: 1, backgroundColor: Colors.fieldtBgColor }}>
                                        <Text style={{ fontSize: 18, borderRightWidth: StyleSheet.hairlineWidth, paddingRight: 5, borderColor: "#D2D2D2", fontFamily: 'NunitoSans-Bold' }}>Sort By</Text>
                                        <DropDownPicker
                                            arrowColor={Colors.primaryColor}
                                            arrowSize={23}
                                            arrowStyle={{ fontWeight: 'bold' }}
                                            labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                                            style={{ width: 150, borderWidth: 0, backgroundColor: Colors.fieldtBgColor, paddingVertical: 0 }}
                                            dropDownStyle={{ width: 150 }}
                                            placeholderStyle={{ color: 'black' }}
                                            itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                                            items={[{ label: 'Order ID', value: 1 }, { label: 'Date/Time', value: 2 }, { label: 'Name', value: 3 }, { label: 'Waiting Time', value: 4 }, { label: 'Payment Status', value: 5 }, { label: 'Total', value: 6 }]}
                                            placeholder={"Order ID"}
                                            onChangeItem={selectedSort => {
                                                setState({ sort: selectedSort }),
                                                    sortingOrders(selectedSort);
                                            }
                                            }
                                        />
                                    </View>
                                </View>
                            </View> */}
                  <View
                    style={{
                      flex: 1,
                      zIndex: -100,
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                    }}
                  >
                    <FlatList
                      // data={search === "" ? userList : userList.filter(item => item.username.toLowerCase().match(search.toLowerCase()))}
                      nestedScrollEnabled={true}
                      showsVerticalScrollIndicator={false}
                      data={userBeerDockets.filter((item) =>
                        moment().isSameOrAfter(item.expiryDate)
                      )}
                      keyExtractor={(item, index) => String(index)}
                      renderItem={renderList}
                    />
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  textSize: {
    fontSize: 21,
    fontFamily: "NunitoSans-SemiBold",
  },
  textSizeDescription: {
    fontSize: 12,
    fontFamily: "NunitoSans-SemiBold",
    color: Colors.descriptionColor,
  },
  modalContainer: {
    flex: 1,
    //backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  sectionModal: {
    // height: Dimensions.get('screen').width * 0.3,
    // padding: Dimensions.get('screen').width * 0.03,
    width: Dimensions.get("screen").width * 0.4,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    // height: 350,
    // width: 500,
  },
  addBtn: {
    backgroundColor: Colors.primaryColor,
    width: 40,
    height: 45,
    justifyContent: "center",
    alignItems: "center",

    borderColor: "transparent",
  },
  textInput: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 60,
    flexDirection: "row",
  },
  viewContainer: {
    marginBottom: 25,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  swipeFont: {
    fontSize: 14,
    fontFamily: "NunitoSans-Bold",
  },
  swipeButton: {
    fontFamily: "NunitoSans-Regular",
    height: "93%",
    justifyContent: "center",
    alignItems: "center",
    width: 140,
  },
  headerLeftStyle: {
    width: Dimensions.get("screen").width * 0.17,
    justifyContent: "center",
    alignItems: "center",
  },
});
export default RedemptionRedeemedScreen;
