import React, { Component, useReducer, useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    ScrollView,
    Image,
    View,
    Text,
    Alert,
    TouchableOpacity,
    Modal,
    Dimensions,
    TextInput,
    KeyboardAvoidingView,
    ActivityIndicator,
    Platform,
    Picker,
    CheckBox,
    useWindowDimensions
} from 'react-native';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
// import Upload from '../assets/svg/Upload'
import SideBar from './SideBar';
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Feather';
import { ReactComponent as GCalendar } from '../assets/svg/GCalendar.svg'
import moment from 'moment';
// import DropDownPicker from 'react-native-dropdown-picker';
// import RNPickerSelect from 'react-native-picker-select';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import AntDesign from 'react-native-vector-icons/AntDesign';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';

import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";

import Feather from 'react-native-vector-icons/Feather';
import Entypo from 'react-native-vector-icons/Entypo';
import { FlatList } from 'react-native-gesture-handler';
// import CheckBox from '@react-native-community/checkbox';
import API from '../constant/API';
import ApiClient from '../util/ApiClient';
// import { isTablet } from 'react-native-device-detection';
import { OutletStore } from '../store/outletStore';
import { MerchantStore } from '../store/merchantStore';
import { UserStore } from '../store/userStore';
import { ORDER_TYPE, REPORT_SORT_FIELD_TYPE, TABLE_PAGE_SIZE_DROPDOWN_LIST, OFFLINE_PAYMENT_METHOD_TYPE, EMAIL_REPORT_TYPE } from '../constant/common';
import XLSX from 'xlsx';
import 'react-native-get-random-values';
// import { v4 as uuidv4 } from 'uuid';
// import RNFetchBlob from 'rn-fetch-blob';
import { convertArrayToCSV, generateEmailReport, sortReportDataList } from '../util/common';
import { CommonStore } from '../store/commonStore';
import AsyncImage from '../components/asyncImage';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
import { CSVLink } from "react-csv";

// import { useKeyboard } from '../hooks';
// import Download from '../assets/svg/Download';
const { nanoid } = require('nanoid');
// const RNFS = require('react-native-fs');


const ReportSaleByShift = props => {
    const {
        navigation,
    } = props;

    const {
        width: windowWidth,
        height: windowHeight,
    } = useWindowDimensions();

    // const [keyboardHeight] = useKeyboard();
    const [currentDateTime, setCurrentDateTime] = useState(Date().toLocaleString());
    const [sort, setSort] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [shiftlist, setShiftlist] = useState([]);
    const [list1, setList1] = useState([]);
    const [page, setPage] = useState(0);
    const [page1, setPage1] = useState(0);
    const [oriList, setOriList] = useState([]);
    const [oriList1, setOriList1] = useState([]);
    const [offset, setOffset] = useState(0);
    const [offset1, setOffset1] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [detailsPageCount, setDetailsPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentDetailsPage, setCurrentDetailsPage] = useState(1);
    const [pageReturn, setPageReturn] = useState(1);
    const [perPage1, setPerPage1] = useState(10);
    const [pageCount1, setPageCount1] = useState(0);
    const [currentPage1, setCurrentPage1] = useState(1);
    const [orderlist, setOrderlist] = useState([{}, {}, {}],);
    const [switchMerchant, setSwitchMerchant] = useState(false);
    const [showlist, setShowlist] = useState(true);
    const [showDetail, setShowDetail] = useState(false);
    const [visible, setVisible] = useState(false);
    const [day, setDay] = useState(false);
    const [pick, setPick] = useState(null);
    const [pick1, setPick1] = useState(null);
    const [general, setGeneral] = useState(true);
    const [salesSummary, setSalesSummary] = useState(false);
    const [taxSummary, setTaxSummary] = useState(false);
    const [discount, setDiscount] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const [shift, setShift] = useState(false);
    const [isSelected, setIsSelected] = useState(true);
    const [isSelected1, setIsSelected1] = useState(true);
    const [isSelected2, setIsSelected2] = useState(true);
    const [isSelected3, setIsSelected3] = useState(true);
    const [isSelected4, setIsSelected4] = useState(false);
    const [isSelected5, setIsSelected5] = useState(false);
    const [isSelected6, setIsSelected6] = useState(false);
    const [isSelected7, setIsSelected7] = useState(false);
    const [download, setDownload] = useState(0);
    const [email, setEmail] = useState(0);
    const [detail, setDetail] = useState([]);
    const [search, setSearch] = useState('');
    const [pushPagingToTop, setPushPagingToTop] = useState(false);

    const [showDateTimePicker, setShowDateTimePicker] = useState(false);
    const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
    const [pickerMode, setPickerMode] = useState('datetime');
    // const [rev_date, setRev_date] = useState(moment().startOf('week'));
    // const [rev_date1, setRev_date1] = useState(moment().endOf('week'));
    const [rev_date, setRev_date] = useState(moment().subtract(6, 'days').startOf('day'));
    const [rev_date1, setRev_date1] = useState(moment().endOf(Date.now()).endOf('day'));

    const [controller, setController] = useState({});

    const [selectedItemSummary, setSelectedItemSummary] = useState({});

    const [expandDetailsDict, setExpandDetailsDict] = useState({});
    const crmUsers = OutletStore.useState((s) => s.crmUsers);

    ////////////////////////////////////////////////////////////////

    const [shiftSales, setShiftSales] = useState([]);

    const allOutletsUserOrdersDoneRaw = OutletStore.useState(s => s.allOutletsUserOrdersDone);

    const allOutletShifts = OutletStore.useState(s => s.allOutletShifts);

    const currOutletId = MerchantStore.useState(s => s.currOutletId);
    const allOutlets = MerchantStore.useState(s => s.allOutlets);

    const userName = UserStore.useState(s => s.name);
    const merchantName = MerchantStore.useState(s => s.name);

    const [showDetails, setShowDetails] = useState(false);
    const [transactionTypeSalesDetails, setTransactionTypeSalesDetails] = useState([]);

    const [exportModalVisibility, setExportModalVisibility] = useState(false);

    const [transactionTypeSales, setTransactionTypeSales] = useState([]);
    const [CsvData, setCsvData] = useState([]);

    const [currReportSummarySort, setCurrReportSummarySort] = useState('');
    const [currReportDetailsSort, setCurrReportDetailsSort] = useState('');

    const merchantId = UserStore.useState(s => s.merchantId);
    const isLoading = CommonStore.useState(s => s.isLoading);
    const [isCsv, setIsCsv] = useState(false);
    const [isExcel, setIsExcel] = useState(false);

    const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);

    useEffect(() => {
        if (currOutletId !== '' && allOutlets.length > 0) {
            //   setHistoryOrders(allOutletsUserOrdersDone.filter(order => order.outletId === currOutletId));

            console.log('allOutletShifts');
            console.log(allOutletShifts);

            console.log('report outlet items');

            var shiftSalesTemp = [];

            const allOutletsUserOrdersDone = allOutletsUserOrdersDoneRaw.filter(order => {
                return moment(rev_date).isSameOrBefore(order.completedDate) &&
                    moment(rev_date1).isAfter(order.completedDate);
            });

            for (var i = 0; i < allOutletShifts.length; i++) {
                if (allOutletShifts[i].openDate && allOutletShifts[i].closeDate &&
                    moment(rev_date).isSameOrBefore(allOutletShifts[i].openDate) &&
                    moment(rev_date1).isAfter(allOutletShifts[i].closeDate)) {
                    var outletShiftRecord = {
                        summaryId: nanoid(),
                        openDate: allOutletShifts[i].openDate,
                        closeDate: allOutletShifts[i].closeDate,
                        outletName: allOutlets.find(outlet => outlet.uniqueId === allOutletShifts[i].outletId).name,
                        onlineTransaction: 0,
                        offlineTransaction: 0,
                        totalTransactions: 0,
                        onlineSales: 0,
                        offlineSales: 0,
                        totalSales: 0,
                        openAmount: allOutletShifts[i].openAmount === null ? 'N/A' : allOutletShifts[i].openAmount.toFixed(2),
                        closeAmount: allOutletShifts[i].closeAmount === null ? 'N/A' : allOutletShifts[i].closeAmount.toFixed(2),
                        openDate: allOutletShifts[i].openDate === null ? moment().valueOf() : allOutletShifts[i].openDate,
                        closeDate: allOutletShifts[i].closeDate === null ? moment().valueOf() : allOutletShifts[i].closeDate,
                        detailsList: [],
                    };

                    for (var j = 0; j < allOutletsUserOrdersDone.length; j++) {
                        if (allOutletsUserOrdersDone[j].outletId === allOutletShifts[i].outletId &&
                            allOutletsUserOrdersDone[j].completedDate >= allOutletShifts[i].openDate &&
                            allOutletsUserOrdersDone[j].completedDate <= allOutletShifts[i].closeDate
                            // &&
                            // moment(rev_date).isSameOrBefore(allOutletsUserOrdersDone[i].completedDate) &&
                            // moment(rev_date1).isAfter(allOutletsUserOrdersDone[i].completedDate)
                        ) {
                            outletShiftRecord.totalTransactions += 1;
                            outletShiftRecord.totalSales += allOutletsUserOrdersDone[j].finalPrice;
                            // outletShiftRecord.detailsList.push(allOutletsUserOrdersDone[j]);
                            // outletShiftRecord.detailsList.push({
                            //     ...allOutletsUserOrdersDone[j],
                            //     discountPercentage: parseFloat(isFinite(allOutletsUserOrdersDone[j].discount / allOutletsUserOrdersDone[j].totalPrice) ? allOutletsUserOrdersDone[j].discount / allOutletsUserOrdersDone[j].totalPrice * 100 : 0),
                            // });
                        }
                        //Online Sales
                        if ((allOutletsUserOrdersDone[j].outletId === allOutletShifts[i].outletId &&
                            allOutletsUserOrdersDone[j].completedDate >= allOutletShifts[i].openDate &&
                            allOutletsUserOrdersDone[j].completedDate <= allOutletShifts[i].closeDate &&
                            (allOutletsUserOrdersDone[j].orderType === ORDER_TYPE.DELIVERY || allOutletsUserOrdersDone[j].orderType === ORDER_TYPE.PICKUP))
                        ) {
                            outletShiftRecord.onlineSales += allOutletsUserOrdersDone[j].finalPrice;
                            // outletShiftRecord.detailsList.push({
                            //     ...allOutletsUserOrdersDone[j],
                            //     discountPercentage: parseFloat(isFinite(allOutletsUserOrdersDone[j].discount / allOutletsUserOrdersDone[j].totalPrice) ? allOutletsUserOrdersDone[j].discount / allOutletsUserOrdersDone[j].totalPrice * 100 : 0),
                            // });
                        }
                        //Offline Sales
                        if (allOutletsUserOrdersDone[j].outletId === allOutletShifts[i].outletId &&
                            allOutletsUserOrdersDone[j].completedDate >= allOutletShifts[i].openDate &&
                            allOutletsUserOrdersDone[j].completedDate <= allOutletShifts[i].closeDate &&
                            allOutletsUserOrdersDone[j].orderType === ORDER_TYPE.DINEIN
                        ) {
                            outletShiftRecord.offlineSales += allOutletsUserOrdersDone[j].finalPrice;
                            // outletShiftRecord.detailsList.push({
                            //     ...allOutletsUserOrdersDone[j],
                            //     discountPercentage: parseFloat(isFinite(allOutletsUserOrdersDone[j].discount / allOutletsUserOrdersDone[j].totalPrice) ? allOutletsUserOrdersDone[j].discount / allOutletsUserOrdersDone[j].totalPrice * 100 : 0),
                            // });

                            outletShiftRecord.offlineTransaction += 1;
                        }
                        //Online Transaction
                        if ((allOutletsUserOrdersDone[j].outletId === allOutletShifts[i].outletId &&
                            allOutletsUserOrdersDone[j].completedDate >= allOutletShifts[i].openDate &&
                            allOutletsUserOrdersDone[j].completedDate <= allOutletShifts[i].closeDate &&
                            (allOutletsUserOrdersDone[j].orderType === ORDER_TYPE.DELIVERY || allOutletsUserOrdersDone[j].orderType === ORDER_TYPE.PICKUP)
                        )
                        ) {
                            outletShiftRecord.onlineTransaction += 1;
                        }

                        if (allOutletsUserOrdersDone[j].outletId === allOutletShifts[i].outletId &&
                            allOutletsUserOrdersDone[j].completedDate >= allOutletShifts[i].openDate &&
                            allOutletsUserOrdersDone[j].completedDate <= allOutletShifts[i].closeDate
                        ) {
                            outletShiftRecord.detailsList.push({
                                ...allOutletsUserOrdersDone[j],
                                discountPercentage: parseFloat(isFinite(allOutletsUserOrdersDone[j].discount / allOutletsUserOrdersDone[j].totalPrice) ? allOutletsUserOrdersDone[j].discount / allOutletsUserOrdersDone[j].totalPrice * 100 : 0),
                            });
                        }

                        // outletShiftRecord.offlineTransaction = outletShiftRecord.totalTransactions - outletShiftRecord.onlineTransaction;

                        //Offline Transaction
                        // if ((allOutletsUserOrdersDone[j].outletId === allOutletShifts[i].outletId &&
                        //     allOutletsUserOrdersDone[j].completedDate >= allOutletShifts[i].openDate &&
                        //     allOutletsUserOrdersDone[j].completedDate <= allOutletShifts[i].closeDate &&
                        //     allOutletsUserOrdersDone[j].paymentDetails === null) 
                        //     ||
                        //     (allOutletsUserOrdersDone[j].outletId === allOutletShifts[i].outletId &&
                        //     allOutletsUserOrdersDone[j].completedDate >= allOutletShifts[i].openDate &&
                        //     allOutletsUserOrdersDone[j].completedDate < allOutletShifts[i].closeDate &&
                        //     `${allOutletsUserOrdersDone[j].paymentDetails === null ?  allOutletsUserOrdersDone[j].paymentDetails.channel === 'Offline-Credit-Card': ''}` 
                        //     // `${allOutletsUserOrdersDone[j].paymentDetails !== null ?  allOutletsUserOrdersDone[j].paymentDetails.channel === OFFLINE_PAYMENT_METHOD_TYPE.CREDIT_CARD : ''}` ||
                        //     // `${allOutletsUserOrdersDone[j].paymentDetails !== null ?  allOutletsUserOrdersDone[j].paymentDetails.channel === OFFLINE_PAYMENT_METHOD_TYPE.PAYPAL: ''}` ||
                        //     // `${allOutletsUserOrdersDone[j].paymentDetails !== null ?  allOutletsUserOrdersDone[j].paymentDetails.channel === OFFLINE_PAYMENT_METHOD_TYPE.GRABPAY: ''}` ||
                        //     // `${allOutletsUserOrdersDone[j].paymentDetails !== null ?  allOutletsUserOrdersDone[j].paymentDetails.channel === OFFLINE_PAYMENT_METHOD_TYPE.CASH : ''}` 
                        //     )) {
                        //     outletShiftRecord.offlineTransaction += 1;
                        //     outletShiftRecord.detailsList.push({
                        //         ...allOutletsUserOrdersDone[j],
                        //         //discountPercentage: parseFloat(isFinite(allOutletsUserOrdersDone[j].finalPrice / allOutletsUserOrdersDone[j].discount) ? allOutletsUserOrdersDone[j].finalPrice / allOutletsUserOrdersDone[j].discount * 100 : 0),
                        //     });
                        // }

                    }

                    shiftSalesTemp.push(outletShiftRecord);
                }
            }

            setShiftSales(shiftSalesTemp);

            //setCurrentPage(1);
            setPageCount(Math.ceil(shiftSalesTemp.length / perPage));
        }
    }, [allOutletsUserOrdersDoneRaw, allOutletShifts, currOutletId, allOutlets, rev_date, rev_date1, perPage]);

    useEffect(() => {
        if (showDetails && selectedItemSummary.detailsList) {
            setTransactionTypeSalesDetails(selectedItemSummary.detailsList);

            setPageReturn(currentPage)
            console.log('currentPage value is')
            console.log(currentPage)
            setCurrentDetailsPage(1)

            setPageCount(Math.ceil(selectedItemSummary.detailsList.length / perPage));
        }
    }, [
        showDetails,
        selectedItemSummary,
        perPage
    ]);

    const setState = () => { };

    // navigation.dangerouslyGetParent().setOptions({
    //     tabBarVisible: false
    // });

    navigation.setOptions({
        headerLeft: () => (
            <View style={{
                width: windowWidth * 0.17,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Image
                    style={{
                        width: 124,
                        height: 26,
                    }}
                    resizeMode="contain"
                    source={require('../assets/image/logo.png')}
                />
            </View>
        ),
        headerTitle: () => (
            <View style={[{
                justifyContent: 'center',
                alignItems: 'center',
                // bottom: -2,
                bottom: switchMerchant ? '2%' : 0,
            }, windowWidth >= 768 && switchMerchant ? { right: windowWidth * 0.1 } : {}, windowWidth <= 768 ? { right: windowWidth * 0.12 } : {}]}>
                <Text
                    style={{
                        fontSize: switchMerchant ? 20 : 24,
                        // lineHeight: 25,
                        textAlign: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        color: Colors.whiteColor,
                        opacity: 1,
                    }}>
                    Shift Report
                </Text>
            </View>
        ),
        headerRight: () => (
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                {outletSelectDropdownView()}
                <View style={{
                    backgroundColor: 'white',
                    width: 0.5,
                    height: windowHeight * 0.025,
                    opacity: 0.8,
                    marginHorizontal: 15,
                    bottom: -1,
                }}>
                </View>
                <TouchableOpacity onPress={() => navigation.navigate('Setting')}
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                    <Text
                        style={{
                            fontFamily: 'NunitoSans-SemiBold',
                            fontSize: 16,
                            color: Colors.secondaryColor,
                            marginRight: 15,
                        }}>
                        {userName}
                    </Text>
                    <View style={{
                        marginRight: 30,
                        width: windowHeight * 0.05,
                        height: windowHeight * 0.05,
                        borderRadius: windowHeight * 0.05 * 0.5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                    }}>
                        <Image style={{
                            width: windowHeight * 0.035,
                            height: windowHeight * 0.035,
                            alignSelf: 'center',
                        }} source={require('../assets/image/profile-pic.jpg')} />
                    </View>
                </TouchableOpacity>
            </View>
        ),
    });

    // componentDidMount = () => { getShift(); }

    // function here

    const onItemSummaryClicked = item => {
        // setTransactionTypeSalesDetails(item.detailsList);
        setSelectedItemSummary(item);
        setShowDetails(true);

        // setCurrentPage(1);
        // setPageCount(Math.ceil(item.detailsList.length / perPage));

        console.log('item.detailsList');
        console.log(item.detailsList);
    };


    const downloadFunc = (param) => {
        var body = {
            orderId: param,
        }
        ApiClient.POST(API.generateReportPDF, body, false).then((result) => {
            console.log(result)
            if (result !== null) {

                Alert.alert(
                    'Success',
                    'The data has been downloaded',
                    [
                        { text: "OK", onPress: () => { } }
                    ],
                    { cancelable: false },
                );
            }
        });
    }

    const emailFunc = (param) => {
        var body = {
            orderId: param,
            email: "kokokk@gmail.com"
        }
        ApiClient.POST(API.sendShiftEmail, body, false).then((result) => {
            console.log(result)
            if (result !== null) {

                Alert.alert(
                    'Success',
                    'Email has been sent',
                    [
                        { text: "OK", onPress: () => { setState({ visible: false }); } }
                    ],
                    { cancelable: false },
                );
            }
        });
    }

    const add = async () => {
        if (page + 1 < pageCount) {
            await setState({ page: page + 1, currentPage: currentPage + 1 })
            var e = page
            next(e)
        }
    }

    const add1 = async () => {
        if (page1 + 1 < pageCount1) {
            await setState({ page1: page1 + 1, currentPage1: currentPage1 + 1 })
            var e = page1
            next1(e)
        }
    }

    const next = (e) => {
        const offset = e * perPage;
        setState({ offset: offset })
        loadMoreData()
    }

    const next1 = (e) => {
        const offset1 = e * perPage1;
        setState({ offset1: offset1 })
        loadMoreData1()
    }

    const less = async () => {
        if (page > 0) {
            await setState({ page: page - 1, currentPage: currentPage - 1 })
            var y = page
            pre(y)
        }
    }

    const less1 = async () => {
        if (page1 > 0) {
            await setState({ page1: page1 - 1, currentPage1: currentPage1 - 1 })
            var y = page1
            pre1(y)
        }
    }

    const pre = (y) => {

        const offset = y * perPage;
        setState({ offset: offset })
        loadMoreData()

    }

    const pre1 = (y) => {

        const offset1 = y * perPage1;
        setState({ offset1: offset1 })
        loadMoreData1()

    }

    const nextPage = () => {
        setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
    };

    const nextDetailsPage = () => {
        setCurrentDetailsPage(currentDetailsPage + 1 > pageCount ? currentDetailsPage : currentDetailsPage + 1);
    };

    const prevDetailsPage = () => {
        setCurrentDetailsPage(currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1);
    };

    const loadMoreData = () => {
        const data = oriList;
        const slice = data.slice(offset, offset + perPage)
        setState({ list1: slice, pageCount: Math.ceil(data.length / perPage) })
    }

    const loadMoreData1 = () => {
        const data = oriList1;
        const slice = data.slice(offset1, offset1 + perPage1)
        setState({ shiftlist: slice, pageCount1: Math.ceil(data.length / perPage1) })
    }

    const getShift = () => {
        ApiClient.GET(API.getAllOutletShift + 1).then((result) => {
            const newList = []
            for (const shift of result) {
                if (shift[0].id !== null) {
                    newList.push(shift)
                }
            }
            var data = newList
            var slice = data.slice(offset1, offset1 + perPage1)
            setState({ shiftlist: slice, oriList1: data, pageCount1: Math.ceil(data.length / perPage1) })
        });
    }

    const getShiftDetail = (param) => {
        ApiClient.GET(API.getShiftTotal + param).then((result) => {
            setState({ detail: result })
        });
    }


    const getDetail = (param) => {
        ApiClient.GET(API.getShiftTransaction + param).then((result) => {
            var data = result
            var slice = data.slice(offset, offset + perPage)
            setState({ list1: slice, oriList: data, pageCount: Math.ceil(data.length / perPage) })
        });
    }

    const rendershiftList = ({ item, index }) => (
        <TouchableOpacity onPress={() => onItemSummaryClicked(item)}
            style={{
                flexDirection: "row",
                paddingVertical: 10,
                backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
            }}>
            <Text style={{ width: "5%", textAlign: 'left', fontSize: switchMerchant ? 10 : 13, paddingLeft: 10 }}>{index + 1}</Text>
            {/* <Text style={{ width: "20%", textAlign: 'left', paddingLeft: 10 }}>{item.outletName}</Text> */}
            {/* <Text style={{ width: "12%", textAlign: 'center' }}>{item.WaiterName}</Text> */}
            {/* <Text style={{ width: "7.5%", textAlign: 'center' }}>{item.totalTransactions}</Text> */}
            <View style={{ width: "35%", textAlign: 'left', paddingLeft: 10, alignItems: 'flex-start' }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ color: 'black', fontVariant: ['tabular-nums'], fontSize: switchMerchant ? 10 : 13, }} >{moment(item.openDate).format("DD MMM YY")} {moment(item.openDate).format("hh:mm A")}</Text>
                    {/* <Text style={{ fontSize: 11, color: 'black' }} > {moment(item.openDate).format("HH:MMA")}</Text> */}
                    <Text style={{ fontSize: 13, color: 'black', marginVertical: 0, alignSelf: 'center', paddingHorizontal: 10, fontSize: switchMerchant ? 10 : 13, }}>to</Text>
                    <Text style={{ color: 'black', fontVariant: ['tabular-nums'], fontSize: switchMerchant ? 10 : 13, }} >{moment(item.closeDate).format("DD MMM YY")} {moment(item.closeDate).format("hh:mm A")}</Text>
                    {/* <Text style={{ fontSize: 11, color: 'black' }} > {moment(item.closeDate).format("HH:MMA")}</Text> */}
                </View>
            </View>
            {/* <Text style={{ width: "9%", textAlign: 'center' }}>{item.totalTransactions}/{item.totalTransactions}</Text> */}
            <Text style={{ width: "12%", textAlign: 'left', paddingLeft: 10, fontSize: switchMerchant ? 10 : 13, }}>{item.onlineTransaction}/{item.offlineTransaction}</Text>
            <Text style={{ width: "12%", textAlign: 'left', paddingLeft: 10, fontSize: switchMerchant ? 10 : 13, }}>{item.totalTransactions}</Text>
            <View style={{ width: '12%', }}>
                <Text style={{ textAlign: 'left', paddingLeft: 10, fontSize: switchMerchant ? 10 : 13, }}>{item.onlineSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}/</Text>
                <Text style={{ textAlign: 'left', paddingLeft: 10, fontSize: switchMerchant ? 10 : 13, }}>{item.offlineSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}</Text>
            </View>
            <Text style={{ width: "8.5%", textAlign: 'left', paddingLeft: 10, fontSize: switchMerchant ? 10 : 13, }}>{item.totalSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}</Text>
            <View style={{ width: '13.5%' }}>
                <Text style={{ textAlign: 'right', paddingLeft: Platform.OS == 'ios' ? 10 : 0, paddingRight: 20, fontSize: switchMerchant ? 10 : 13, }}>{item.openAmount}/</Text>
                <Text style={{ textAlign: 'right', paddingLeft: Platform.OS == 'ios' ? 10 : 0, paddingRight: 20, fontSize: switchMerchant ? 10 : 13, }}>{item.closeAmount}</Text>
            </View>
        </TouchableOpacity>
        // here
        //     <View style={{ width: "15%", textAlign: 'center', flexDirection: "row", justifyContent: 'center' }}>
        //         <Text style={{ fontSize: 12, color: Colors.primaryColor }} >{moment(item.openDate).format("DD/MM/YYYY")}</Text>
        //         <Text style={{ fontSize: 12, color: Colors.primaryColor, marginLeft: 3 }} >{moment(item.openDate).format("hh:mm")}</Text>
        //     </View>
        //     <View style={{ width: "15%", textAlign: 'center', flexDirection: "row", justifyContent: 'center' }}>
        //         <Text style={{ fontSize: 12 }} >{moment(item.closeDate).format("DD/MM/YYYY")}</Text>
        //         <Text style={{ fontSize: 12, marginLeft: 3 }} >{moment(item.closeDate).format("hh:mm")}</Text>
        //     </View>
        //     <Text style={{ width: "17%", textAlign: 'center' }}>{item.outletName}</Text>
        //     <Text style={{ width: "10%", textAlign: 'center' }}>{item.totalTransactions}</Text>
        //     <Text style={{ width: "10%", textAlign: 'center' }}>{item.totalSales.toFixed(2)}</Text>

        //     {/* dummy data */}
        //     <Text style={{ width: "10%", textAlign: 'center' }}>12572</Text>
        //     <Text style={{ width: "10%", textAlign: 'center' }}>98421</Text>
        //     <Text style={{ width: "12%", textAlign: 'center' }}>James Bone</Text>
        // </TouchableOpacity>
    );

    const onClickItemDetails = (item) => {
        setExpandDetailsDict({
            ...expandDetailsDict,
            [item.uniqueId]: expandDetailsDict[item.uniqueId] ? false : true,
        });
    };

    const renderItemDetails = ({ item, index }) => {
        ///////////////////////////

        console.log('order id');
        console.log(item.orderId);

        // calculate longest

        var longestStr = 5;

        for (var i = 0; i < item.cartItems.length; i++) {
            const cartItemPriceWIthoutAddOn =
                item.cartItems[i].price -
                item.cartItems[i].addOns.reduce(
                    (accum, addOn) => accum + addOn.prices[0],
                    0,
                );

            if (cartItemPriceWIthoutAddOn.toFixed(0).length > longestStr) {
                longestStr = cartItemPriceWIthoutAddOn.toFixed(0).length;
            }

            for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
                if (
                    item.cartItems[i].addOns[j].prices[0].toFixed(0).length > longestStr
                ) {
                    longestStr = item.cartItems[i].addOns[j].prices[0].toFixed(0).length;
                }
            }
        }

        if (item.totalPrice.toFixed(0).length > longestStr) {
            longestStr = item.totalPrice.toFixed(0).length;
        }

        if (item.discount.toFixed(0).length > longestStr) {
            longestStr = item.discount.toFixed(0).length;
        }

        if (item.tax.toFixed(0).length > longestStr) {
            longestStr = item.tax.toFixed(0).length;
        }

        if (item.finalPrice.toFixed(0).length > longestStr) {
            longestStr = item.finalPrice.toFixed(0).length;
        }

        console.log(longestStr);

        ///////////////////////////

        // calculate spacing

        var cartItemPriceWIthoutAddOnSpacingList = [];
        var addOnsSpacingList = [];

        for (var i = 0; i < item.cartItems.length; i++) {
            const cartItemPriceWIthoutAddOn =
                item.cartItems[i].price -
                item.cartItems[i].addOns.reduce(
                    (accum, addOn) => accum + addOn.prices[0],
                    0,
                );

            cartItemPriceWIthoutAddOnSpacingList.push(
                Math.max(longestStr - cartItemPriceWIthoutAddOn.toFixed(0).length, 0) +
                1,
            );

            for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
                addOnsSpacingList.push(
                    Math.max(
                        longestStr -
                        item.cartItems[i].addOns[j].prices[0].toFixed(0).length,
                        0,
                    ) + 1,
                );
            }
        }

        var totalPriceSpacing =
            Math.max(longestStr - item.totalPrice.toFixed(0).length, 0) + 1;
        var discountSpacing =
            Math.max(longestStr - item.discount.toFixed(0).length, 0) + 1;
        var taxSpacing = Math.max(longestStr - item.tax.toFixed(0).length, 0) + 1;
        var finalPriceSpacing =
            Math.max(longestStr - item.finalPrice.toFixed(0).length, 0) + 1;

        ///////////////////////////
        return (
            <TouchableOpacity onPress={() => onClickItemDetails(item)} style={{
                backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
                paddingVertical: 10,
                //paddingHorizontal: 3,
                //paddingLeft: 1,
                borderColor: '#BDBDBD',
                borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                // flexDirection: 'row',
            }}>
                {/* <View style={{ marginLeft: 20, width: "17%", flexDirection: "row" }}>
                <Text style={{ fontSize: 12, color: Colors.primaryColor }} >{moment(item.openDate).format("DD/MM/YYYY")}</Text>
                <Text style={{ fontSize: 12, color: Colors.primaryColor, marginLeft: 3 }} >{moment(item.openDate).format("hh:mm")}</Text>
            </View>
            <View style={{ marginLeft: 20, width: "17%", flexDirection: "row" }}>
                <Text style={{ fontSize: 12 }} >{moment(item.closeDate).format("DD/MM/YYYY")}</Text>
                <Text style={{ fontSize: 12, marginLeft: 3 }} >{moment(item.closeDate).format("hh:mm")}</Text>
            </View>
            <Text style={{ marginLeft: 20, width: "20%" }}>{item.outletName}</Text>
            <Text style={{ marginLeft: 20, width: "20%" }}>{item.totalTransactions}</Text>
            <Text style={{ marginLeft: 20, width: "15%" }}>{item.totalSales.toFixed(2)}</Text> */}

                <View style={{ flexDirection: 'row', width: '100%' }}>
                    <Text style={{ width: '5%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{index + 1}</Text>
                    {/* <Text style={{ flex: 1.8, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.waiterName !== '' ? item.waiterName : 'N/A'}</Text> */}
                    <Text style={{ width: '18%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{moment(item.completedDate).format('DD MMM YY HH:MM A')}</Text>
                    <Text style={{ width: '12%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }} numberOfLines={1}>{item.waiterName ? item.waiterName : 'N/A'}</Text>
                    <Text style={{ width: '8%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{(Math.ceil(item.totalPrice * 20 - 0.05) / 20).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}</Text>
                    <Text style={{ width: '8%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 12 }}>{item.discount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}</Text>
                    <Text style={{ width: '8%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.discountPercentage !== undefined ? (item.discountPercentage).toFixed(2) : '0.00'}</Text>
                    <Text style={{ width: '8%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.tax.toFixed(2)}</Text>
                    <Text style={{ width: '8%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{(item.tax.toFixed(2) / item.totalPrice.toFixed(2) * 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}</Text>
                    {/* <Text style={{ flex: 1, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{(0).toFixed(2)}</Text> */}
                    <Text style={{ width: '13%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.finalPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}</Text>
                    <View style={{ width: '12%', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 10, paddingRight: switchMerchant ? '2.1%' : '1.8%' }}>
                        <Text style={{}} />
                        <Text style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', }}>
                            {/* <Text style={{
                            opacity: 0,
                            ...Platform.OS === 'android' && {
                                color: 'transparent',
                            },
                        }}>
                            {'0'.repeat((finalPriceSpacing * 0.6) + (item.finalPrice.toFixed(0).length === 1 ? 1 : 0))}
                        </Text> */}
                            {(Math.ceil(item.finalPrice * 20 - 0.05) / 20).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
                        </Text>
                    </View>
                </View>

                {expandDetailsDict[item.uniqueId] == true ? (
                    <View
                        style={{
                            minheight: windowHeight * 0.35,
                            marginTop: 30,
                            paddingBottom: 20,
                        }}>
                        {item.cartItems.map((cartItem, index) => {
                            const cartItemPriceWIthoutAddOn =
                                cartItem.price -
                                cartItem.addOns.reduce(
                                    (accum, addOn) => accum + addOn.prices[0],
                                    0,
                                );

                            return (
                                <View
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <View
                                        style={{
                                            width: '100%',
                                            alignItems: 'flex-start',
                                            flexDirection: 'row',
                                            marginBottom: Platform.OS == 'ios' ? 10 : 10,
                                            minHeight: 80,
                                            //backgroundColor: 'yellow',
                                        }}>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                width: '100%',
                                                //backgroundColor: 'blue',
                                            }}>
                                            {index == 0 ? (
                                                <View
                                                    style={{
                                                        marginHorizontal: 1,
                                                        width: Platform.OS == 'ios' ? '8%' : '8%',
                                                        //justifyContent: 'center',
                                                        alignItems: 'center',
                                                        //backgroundColor: 'blue',
                                                    }}>
                                                    <TouchableOpacity
                                                        style={{
                                                            alignItems: 'center',
                                                            marginTop: 0,
                                                        }}
                                                        onPress={() => {
                                                            var crmUser = null;

                                                            if (item.crmUserId !== undefined) {
                                                                for (
                                                                    var i = 0;
                                                                    i < crmUsers.length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        item.crmUserId ===
                                                                        crmUsers[i].uniqueId
                                                                    ) {
                                                                        crmUser = crmUsers[i];
                                                                        break;
                                                                    }
                                                                }
                                                            }

                                                            if (!crmUser) {
                                                                for (
                                                                    var i = 0;
                                                                    i < crmUsers.length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        item.userId ===
                                                                        crmUsers[i].firebaseUid
                                                                    ) {
                                                                        crmUser = crmUsers[i];
                                                                        break;
                                                                    }
                                                                }
                                                            }

                                                            if (crmUser) {
                                                                CommonStore.update(
                                                                    (s) => {
                                                                        s.selectedCustomerEdit = crmUser;
                                                                        // s.selectedCustomerEdit = userReservations[item.userId] && crmUsers[item.userId] ? crmUsers[item.userId] : null ;

                                                                        s.routeParams = {
                                                                            pageFrom: 'Reservation',
                                                                        };
                                                                    },
                                                                    () => {
                                                                        navigation.navigate('NewCustomer');
                                                                    },
                                                                );
                                                            }
                                                        }}>
                                                        <Image
                                                            style={{
                                                                width: switchMerchant
                                                                    ? 30
                                                                    : 60,
                                                                height: switchMerchant
                                                                    ? 30
                                                                    : 60,
                                                            }}
                                                            resizeMode="contain"
                                                            source={require('../assets/image/default-profile.png')}
                                                        />

                                                        <View
                                                            style={{
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}>
                                                            <Text
                                                                style={[
                                                                    {
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                        marginTop: 0,
                                                                        fontSize: 13,
                                                                        textAlign: 'center',
                                                                    },
                                                                    switchMerchant
                                                                        ? {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            marginTop: 0,
                                                                            fontSize: 10,
                                                                            textAlign: 'center',
                                                                        }
                                                                        : {},
                                                                ]}
                                                                numberOfLines={1}>
                                                                {item.userName ? item.userName : 'Guest'}
                                                            </Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </View>
                                            ) : (
                                                <View
                                                    style={{
                                                        marginHorizontal: 1,
                                                        width: Platform.OS == 'ios' ? '8%' : '8%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                />
                                            )}

                                            <View
                                                style={{
                                                    // flex: 0.3,
                                                    width: '5%',
                                                    //justifyContent: 'center',
                                                    alignItems: 'center',
                                                    //backgroundColor: 'red',
                                                    //paddingLeft: '1.2%',
                                                }}>
                                                <Text
                                                    style={[
                                                        {
                                                            fontFamily: 'NunitoSans-Bold',
                                                            fontSize: 13,
                                                        },
                                                        switchMerchant
                                                            ? {
                                                                fontFamily: 'NunitoSans-Bold', fontSize: 10,
                                                            }
                                                            : {},
                                                    ]}>
                                                    {index + 1}.
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    //flex: 0.5,
                                                    width: '10%',
                                                    //backgroundColor: 'green',
                                                    alignItems: 'center',
                                                }}>
                                                <AsyncImage
                                                    source={{ uri: cartItem.image }}
                                                    item={cartItem}
                                                    style={{
                                                        width: switchMerchant
                                                            ? 30
                                                            : 60,
                                                        height: switchMerchant
                                                            ? 30
                                                            : 60,
                                                        borderWidth: 1,
                                                        borderColor: '#E5E5E5',
                                                        borderRadius: 5,
                                                    }}
                                                />
                                            </View>
                                            <View style={{ width: '75%' }}>
                                                <View
                                                    style={{
                                                        marginLeft: Platform.OS == 'ios' ? 14 : 14,
                                                        marginBottom: 10,
                                                        //backgroundColor: 'blue',
                                                        width: '100%',
                                                        flexDirection: 'row',
                                                    }}>
                                                    <View style={{ width: '69%' }}>
                                                        <Text
                                                            style={[
                                                                {
                                                                    fontFamily: 'NunitoSans-Bold',
                                                                    fontSize: 13,
                                                                },
                                                                switchMerchant
                                                                    ? {
                                                                        fontFamily: 'NunitoSans-Bold', fontSize: 10,
                                                                    }
                                                                    : {},
                                                            ]}>
                                                            {cartItem.name}
                                                        </Text>
                                                    </View>

                                                    <View
                                                        style={{
                                                            width: '13%',
                                                        }}>
                                                        <View
                                                            style={{
                                                                alignItems: 'center',
                                                                //backgroundColor: 'yellow',
                                                            }}>
                                                            <Text
                                                                style={[
                                                                    {
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                        fontSize: 13,
                                                                    },
                                                                    switchMerchant
                                                                        ? {
                                                                            fontFamily: 'NunitoSans-Bold', fontSize: 10,
                                                                        }
                                                                        : {},
                                                                ]}>
                                                                x{cartItem.quantity}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            width: '18.71%',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10,
                                                                    }
                                                                    : { fontSize: 13 }
                                                            }>
                                                            RM
                                                        </Text>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                    }
                                                                    : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                            }>
                                                            {cartItemPriceWIthoutAddOn
                                                                .toFixed(2)
                                                                .replace(
                                                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                                                    '$1,',
                                                                )}
                                                        </Text>
                                                    </View>
                                                </View>

                                                {cartItem.remarks &&
                                                    cartItem.remarks.length > 0 ? (
                                                    <View
                                                        style={{
                                                            alignItems: 'center',
                                                            flexDirection: 'row',
                                                            marginLeft: Platform.OS == 'ios' ? 14 : 14,
                                                        }}>
                                                        <View style={{ justifyContent: 'center' }}>
                                                            <Text
                                                                style={[
                                                                    {
                                                                        fontFamily: 'NunitoSans-SemiBold',
                                                                        fontSize: 13,
                                                                    },
                                                                    switchMerchant
                                                                        ? {
                                                                            fontFamily: 'NunitoSans-SemiBold', fontSize: 10,
                                                                        }
                                                                        : {},
                                                                ]}>
                                                                {cartItem.remarks}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                ) : (
                                                    <></>
                                                )}

                                                {cartItem.addOns.map((addOnChoice, i) => {
                                                    return (
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                // marginLeft: -5,
                                                                width: '100%',
                                                            }}>
                                                            <View
                                                                style={{
                                                                    width: '69%',
                                                                    flexDirection: 'row',
                                                                    marginLeft:
                                                                        Platform.OS == 'ios' ? 14 : 14,
                                                                }}>
                                                                <Text
                                                                    style={[switchMerchant
                                                                        ? {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 10,
                                                                            color: Colors.descriptionColor,
                                                                            width: '25%',
                                                                            // marginLeft: 5,
                                                                        } :
                                                                        {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 13,
                                                                            color: Colors.descriptionColor,
                                                                            width: '25%',
                                                                            // marginLeft: 5,
                                                                        },
                                                                    ]}>
                                                                    {`${addOnChoice.name}:`}
                                                                </Text>
                                                                <Text
                                                                    style={[switchMerchant
                                                                        ? {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 10,
                                                                            color: Colors.descriptionColor,
                                                                            width: '75%',
                                                                        } : {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 13,
                                                                            color: Colors.descriptionColor,
                                                                            width: '75%',
                                                                            // marginLeft: 5,
                                                                        },
                                                                    ]}>
                                                                    {`${addOnChoice.choiceNames[0]}`}
                                                                </Text>
                                                            </View>

                                                            <View
                                                                style={[
                                                                    {
                                                                        width: '13%',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'center',
                                                                        //backgroundColor: 'blue',
                                                                    },
                                                                ]}>
                                                                <Text
                                                                    style={[switchMerchant
                                                                        ? {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 10,
                                                                            color: Colors.descriptionColor,
                                                                            width: '28%',
                                                                            textAlign: 'center',
                                                                        } : {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 13,
                                                                            color: Colors.descriptionColor,
                                                                            width: '28%',
                                                                            // right: 38,
                                                                            //backgroundColor: 'green',
                                                                            textAlign: 'center',
                                                                        },
                                                                    ]}>
                                                                    {`${addOnChoice.quantities
                                                                        ? `x${addOnChoice.quantities[0]}`
                                                                        : ''
                                                                        }`}
                                                                </Text>
                                                            </View>

                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                    width: '18.75%',
                                                                    alignItems: 'center',
                                                                }}>
                                                                <Text
                                                                    style={[
                                                                        switchMerchant
                                                                            ? { color: Colors.descriptionColor, fontSize: 10 }
                                                                            : {
                                                                                color: Colors.descriptionColor,
                                                                                fontSize: 13,
                                                                            },
                                                                    ]}>
                                                                    RM
                                                                </Text>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10, color: Colors.descriptionColor,
                                                                                paddingRight: 20,
                                                                                fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                            : {
                                                                                color: Colors.descriptionColor,
                                                                                paddingRight: 20,
                                                                                fontSize: 13, fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                    }>
                                                                    {addOnChoice.prices[0]
                                                                        .toFixed(2)
                                                                        .replace(
                                                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                                                            '$1,',
                                                                        )}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    );
                                                })}
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', width: '100%' }}>
                                        <View style={{ width: '70%' }} />
                                        <View style={{ width: 15 }} />
                                        {index === item.cartItems.length - 1 ? (
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    //backgroundColor: 'yellow',
                                                    width: '28.65%',
                                                }}>
                                                <View
                                                    style={{
                                                        justifyContent: 'center',
                                                        width: '100%',
                                                    }}>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                                    : {
                                                                        fontSize: 13,
                                                                        width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                            }>
                                                            Subtotal:
                                                        </Text>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: '49.1%',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? { fontSize: 10 }
                                                                        : { fontSize: 13, }
                                                                }>
                                                                RM
                                                            </Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                {item.totalPrice
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    {cartItem.orderType === ORDER_TYPE.DELIVERY ? (
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10,
                                                                            width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                        : {
                                                                            fontSize: 13,
                                                                            width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                }>
                                                                Delivery Fee:
                                                            </Text>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                    width: '49.1%',
                                                                }}>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? { fontSize: 10 }
                                                                            : { fontSize: 13, }
                                                                    }>
                                                                    RM
                                                                </Text>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                            : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                    }>
                                                                    {item.deliveryFee
                                                                        .toFixed(2)
                                                                        .replace(
                                                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                                                            '$1,',
                                                                        )}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    ) : (
                                                        <></>)}

                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                                    : {
                                                                        fontSize: 13,
                                                                        width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                            }>
                                                            Discount:
                                                        </Text>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: '49.1%',
                                                            }}>
                                                            <Text style={switchMerchant ? { fontSize: 10, } : { fontSize: 13 }}>RM</Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                - {item.discount
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>

                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                                    : {
                                                                        fontSize: 13,
                                                                        width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                            }>
                                                            Tax:
                                                        </Text>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: '49.1%',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20 }
                                                                }>
                                                                RM
                                                            </Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                {item.tax
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>

                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, width: '50.85%', fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                                    : {
                                                                        fontSize: 13,
                                                                        width: '50.85%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                            }>
                                                            Service Charge:
                                                        </Text>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: switchMerchant ? '49.15%' : '49.1%',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10,
                                                                        }
                                                                        : { fontSize: 13, }
                                                                }>
                                                                RM
                                                            </Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                {(item.sc || 0)
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>

                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10,
                                                                        width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                                    : {
                                                                        fontSize: 13,
                                                                        width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                            }>
                                                            Total:
                                                        </Text>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: '49.1%',
                                                            }}>
                                                            <Text style={switchMerchant ? { fontSize: 10, } : { fontSize: 13 }}>RM</Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                {item.finalPrice
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        ) : (
                                            <></>
                                        )}
                                    </View>

                                    {/* <View style={{alignItems:'flex-end'}}>
                            <View style={{ flexDirection: 'row' }}>
                              <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 16 }}>Subtotal: {(Math.ceil(cartItem.price * 20-0.05) /20).toFixed(2)}</Text>
                            </View>
                          </View> */}
                                    {/* {(cartItem.remarks && cartItem.remarks.length > 0) ?
                          <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                            
                            <View style={{ flex: 1, justifyContent: 'center', }}>
                              <Text style={{ fontFamily: 'NunitoSans-SemiBold', fontSize: 15 }}>{cartItem.remarks}</Text>
                            </View>
                            
                          </View>
                          : <></>
                        } */}
                                </View>
                            );
                        })}
                    </View>
                ) : null}
            </TouchableOpacity>
        )
    };

    // <View>
    //     {(index + 1) % 2 == 0 ? <View >
    //         {item.map((element) => {
    //             return (<TouchableOpacity onPress={() => { getShiftDetail(element.uniqueId); getDetail(element.uniqueId); setState({ showlist: false, showDetail: true }) }} style={{ flexDirection: "row", paddingVertical: 10 }}><View style={{ marginLeft: 20, width: "17%", flexDirection: "row" }}>
    //                 <Text style={{ fontSize: 12, color: Colors.primaryColor }} >{moment(element.openDate).format("DD/MM/YYYY")}</Text>
    //                 <Text style={{ fontSize: 12, color: Colors.primaryColor, marginLeft: 3 }} >{moment(element.openDate).format("hh:mm")}</Text>
    //             </View>
    //                 <View style={{ marginLeft: 20, width: "17%", flexDirection: "row" }}>
    //                     <Text style={{ fontSize: 12 }} >{moment(element.closeDate).format("DD/MM/YYYY")}</Text>
    //                     <Text style={{ fontSize: 12, marginLeft: 3 }} >{moment(element.closeDate).format("hh:mm")}</Text>
    //                 </View>
    //                 <Text style={{ marginLeft: 20, width: "20%" }}>{element.outletName}</Text>
    //                 <Text style={{ marginLeft: 20, width: "20%" }}>{ element.totalTransactions}</Text>
    //                 <Text style={{ marginLeft: 20, width: "15%" }}>{eelement.totalSales}</Text></TouchableOpacity>)
    //         })}

    //     </View> : <View style={{ backgroundColor: "#e8e8e8" }}>
    //             {item.map((element) => {
    //                 return (<TouchableOpacity onPress={() => { getShiftDetail(element.uniqueId); getDetail(element.uniqueId); setState({ showlist: false, showDetail: true }) }} style={{ flexDirection: "row", paddingVertical: 10 }}><View style={{ marginLeft: 20, width: "17%", flexDirection: "row" }}>
    //                 <Text style={{ fontSize: 12, color: Colors.primaryColor }} >{moment(element.openDate).format("DD/MM/YYYY")}</Text>
    //                 <Text style={{ fontSize: 12, color: Colors.primaryColor, marginLeft: 3 }} >{moment(element.openDate).format("hh:mm")}</Text>
    //             </View>
    //                 <View style={{ marginLeft: 20, width: "17%", flexDirection: "row" }}>
    //                     <Text style={{ fontSize: 12 }} >{moment(element.closeDate).format("DD/MM/YYYY")}</Text>
    //                     <Text style={{ fontSize: 12, marginLeft: 3 }} >{moment(element.closeDate).format("hh:mm")}</Text>
    //                 </View>
    //                 <Text style={{ marginLeft: 20, width: "20%" }}>{element.outletName}</Text>
    //                 <Text style={{ marginLeft: 20, width: "20%" }}>{element.totalTransactions}</Text>
    //                 <Text style={{ marginLeft: 20, width: "15%" }}>{element.totalSales}</Text></TouchableOpacity>)
    //             })}

    //         </View>}
    // </View>

    const renderList1 = ({ item, index }) => (
        <TouchableOpacity onPress={() => onItemSummaryClicked(item)}>
            <View style={{ flexDirection: "row", paddingVertical: 10 }}>
                <View style={{ marginLeft: 20, width: "12%", flexDirection: "row" }}>
                    <Text style={{ fontSize: 12, }} >{moment(item.order.orderedAt).format("DD/MM/YYYY")}</Text>
                    <Text style={{ fontSize: 12, marginLeft: 3 }} >{moment(item.order.orderedAt).format("hh:mm")}</Text>
                </View>
                <View style={{ marginLeft: 20, width: "11%", flexDirection: "row" }}>
                    <Text style={{ fontSize: 12 }} >{moment(item.order.collectionTime).format("DD/MM/YYYY")}</Text>
                    <Text style={{ fontSize: 12, marginLeft: 3 }} >{moment(item.order.collectionTime).format("hh:mm")}</Text>
                </View>
                <Text style={{ marginLeft: 20, width: "10%" }}>{item.order.waiterName == null ? "John Doe" : item.order.waiterName}</Text>
                <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.type}</Text>
                <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.price}</Text>
                <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.discount}</Text>
                <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.tax}</Text>
                <Text style={{ marginLeft: 20, width: "11%" }}>{item.order.finalPrice}</Text>
            </View>
        </TouchableOpacity>
    );

    const renderItemDetails1 = ({ item, index }) => (
        (index + 1) % 2 == 0 ?
            <View style={{
                backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
                paddingVertical: 10,
                paddingHorizontal: 3,
                paddingLeft: 1,
                borderColor: '#BDBDBD',
                borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
            }}>
                <View style={{ flexDirection: "row", paddingVertical: 10 }}>
                    <View style={{ marginLeft: 20, width: "12%", flexDirection: "row" }}>
                        <Text style={{ fontSize: 12, }} >{moment(item.order.orderedAt).format("DD/MM/YYYY")}</Text>
                        <Text style={{ fontSize: 12, marginLeft: 3 }} >{moment(item.order.orderedAt).format("hh:mm")}</Text>
                    </View>
                    <View style={{ marginLeft: 20, width: "11%", flexDirection: "row" }}>
                        <Text style={{ fontSize: 12 }} >{moment(item.order.collectionTime).format("DD/MM/YYYY")}</Text>
                        <Text style={{ fontSize: 12, marginLeft: 3 }} >{moment(item.order.collectionTime).format("hh:mm")}</Text>
                    </View>
                    <Text style={{ marginLeft: 20, width: "10%" }}>{item.order.waiterName == null ? "John Doe" : item.order.waiterName}</Text>
                    <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.type}</Text>
                    <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.price}</Text>
                    <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.discount}</Text>
                    <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.tax}</Text>
                    <Text style={{ marginLeft: 20, width: "11%" }}>{item.order.finalPrice}</Text>
                </View>
            </View>
            :
            <View style={{
                backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
                paddingVertical: 10,
                paddingHorizontal: 3,
                paddingLeft: 1,
                borderColor: '#BDBDBD',
                borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
            }}>
                <View style={{ flexDirection: "row", paddingVertical: 10 }}>
                    <View style={{ marginLeft: 20, width: "12%", flexDirection: "row" }}>
                        <Text style={{ fontSize: 12, color: Colors.primaryColor }} >{moment(item.order.orderedAt).format("DD/MM/YYYY")}</Text>
                        <Text style={{ fontSize: 12, color: Colors.primaryColor, marginLeft: 3 }} >{moment(item.order.orderedAt).format("hh:mm")}</Text>
                    </View>
                    <View style={{ marginLeft: 20, width: "11%", flexDirection: "row" }}>
                        <Text style={{ fontSize: 12 }} >{moment(item.order.collectionTime).format("DD/MM/YYYY")}</Text>
                        <Text style={{ fontSize: 12, marginLeft: 3 }} >{moment(item.order.collectionTime).format("hh:mm")}</Text>
                    </View>
                    <Text style={{ marginLeft: 20, width: "10%" }}>{item.order.waiterName == null ? "John Doe" : item.order.waiterName}</Text>
                    <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.type}</Text>
                    <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.price}</Text>
                    <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.discount}</Text>
                    <Text style={{ marginLeft: 20, width: "9%" }}>{item.order.tax}</Text>
                    <Text style={{ marginLeft: 20, width: "11%" }}>{item.order.finalPrice}</Text>
                </View>
            </View>
    );

    const changeClick = () => {
        if (day == true) {
            setState({ day: false })
        }
        else
            setState({ day: true })
    }

    const sortingOrders = (param) => {
        if (param.value == 1) {
            setShiftlist(shiftlist.slice(0).sort((a, b) => b.openDate.localeCompare(a.openDate)));
        }
        if (param.value == 2) {
            setShiftlist(shiftlist.slice(0).sort((a, b) => b.closeDate - a.closeDate));
        }
        if (param.value == 3) {
            setShiftlist(shiftlist.slice(0).sort((a, b) => b.outletName.localeCompare(a.outletName)));
        }
        if (param.value == 5) {
            setShiftlist(shiftlist.slice(0).sort((a, b) => b.totalTransactions.localeCompare(a.totalTransactions)));
        }
        if (param.value == 6) {
            setShiftlist(shiftlist.slice(0).sort((a, b) => b.totalSales - a.totalSales));
        }
    }
    const [exportEmail, setExportEmail] = useState('');

    const emailShiftSales = () => {
        const excelData = convertDataToExcelFormat();

        var body = {
            // data: CsvData,
            //data: convertArrayToCSV(todaySalesChart.dataSource.data),
            data: JSON.stringify(excelData),
            //data: convertDataToExcelFormat(),
            email: exportEmail,
        };

        ApiClient.POST(API.emailDashboard, body, false).then((result) => {
            if (result !== null) {
                Alert.alert(
                    'Success',
                    'Email has been sent',
                    [{ text: 'OK', onPress: () => { } }],
                    { cancelable: false },
                );
            }
        });

        setVisible(false);
    };

    const convertDataToExcelFormat = () => {
        var excelData = [];

        if (!showDetails) {
            for (var i = 0; i < transactionTypeSales.length; i++) {
                var excelRow = {
                    // 'Transaction Category': transactionTypeSales[i].orderType,
                    // 'Total Sales (RM)': parseFloat(transactionTypeSales[i].totalSales).toFixed(2),
                    // 'Total Transaction': transactionTypeSales[i].totalTransactions,
                    // 'Total Discount (RM)': parseFloat(transactionTypeSales[i].totalDiscount).toFixed(2),
                    // 'Discount (%)': parseFloat(transactionTypeSales[i].discount).toFixed(2),
                    // 'Tax (RM)': parseFloat(transactionTypeSales[i].tax).toFixed(2),
                    // 'Tax (RM)': parseFloat(transactionTypeSales[i].serviceCharge).toFixed(2),
                    // 'GP (%)': parseFloat(transactionTypeSales[i].gp).toFixed(2),
                    // 'Net Sales (RM)': parseFloat(transactionTypeSales[i].netSales).toFixed(2),
                    // 'Average Net Sales (RM)': parseFloat(transactionTypeSales[i].averageNetSales).toFixed(2),
                    'Open Date': moment(shiftSales[i].openDate).format("DD/MM/YYYY"),
                    'Open Time': moment(shiftSales[i].openDate).format("hh:mm"),
                    'Close Time': moment(shiftSales[i].closeDate).format("DD/MM/YYYY"),
                    'Outlet': shiftSales[i].outletName,
                    'Total Order': shiftSales[i].totalTransactions,
                    ' Sales (RM)': +parseFloat(shiftSales[i].totalSales).toFixed(2),
                };

                excelData.push(excelRow);
            }
        }
        else {
            for (var i = 0; i < transactionTypeSalesDetails.length; i++) {
                var excelRow = {
                    // 'Transaction Category': ORDER_TYPE_PARSED[transactionTypeSalesDetails[i].orderType],
                    // 'Sales (RM)': parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2),
                    // 'Transaction Date': moment(transactionTypeSalesDetails[i].createdAt).format('DD MMM hh:mma'),
                    // 'Total Discount (RM)': parseFloat(transactionTypeSalesDetails[i].discount).toFixed(2),
                    // 'Discount (%)': parseFloat(transactionTypeSalesDetails[i].discountPercentage).toFixed(2),
                    // 'Tax (RM)': parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2),
                    // 'Tax (RM)': parseFloat(0).toFixed(2),
                    // 'GP (%)': parseFloat(0).toFixed(2),
                    // 'Net Sales (RM)': parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2),
                    'Date/Time': moment(transactionTypeSalesDetails[i].completedDate).format('DD MMM hh:mma'),
                    'Sales (RM)': +parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2),
                    'Total Discount (RM)': +parseFloat(transactionTypeSalesDetails[i].discount).toFixed(2),
                    'Disc (%)': +parseFloat(transactionTypeSalesDetails[i].discountPercentage).toFixed(2),
                    'Tax (RM)': +parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2),
                    'Service Charge (RM)': +parseFloat(transactionTypeSalesDetails[i].sc || 0).toFixed(2),
                    'GP (%)': +parseFloat(0).toFixed(2),
                    'Sales Return (RM)': +parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2),
                    'Net Sales (RM)': +parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2),
                };

                excelData.push(excelRow);
            }
        }

        console.log('excelData');
        console.log(excelData);

        return excelData;
    };

    // const downloadExcel = () => {
    //     const excelData = convertDataToExcelFormat();

    //     var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
    //     var excelWorkSheet = XLSX.utils.json_to_sheet(excelData);
    //     var excelWorkBook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, "Product Sales Report");

    //     const workBookData = XLSX.write(excelWorkBook, {
    //         type: 'binary',
    //         bookType: 'xlsx',
    //     });

    //     RNFS.writeFile(excelFile, workBookData, 'ascii')
    //         .then((success) => {
    //             console.log(`wrote file ${excelFile}`);

    //             Alert.alert(
    //                 'Success',
    //                 `Send to ${excelFile}`,
    //                 [{ text: 'OK', onPress: () => { } }],
    //                 { cancelable: false },
    //             );
    //         })
    //         .catch((err) => {
    //             console.log(err.message);
    //         });

    //     // XLSX.writeFileAsync(excelFile, excelWorkBook, () => {
    //     //     Alert.alert(
    //     //         'Success',
    //     //         `Send to ${excelFile}`,
    //     //         [{ text: 'OK', onPress: () => { } }],
    //     //         { cancelable: false },
    //     //     );
    //     // });

    //     // const csvData = convertArrayToCSV(CsvData);

    //     // const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
    //     // console.log("PATH", excelFile);
    //     // RNFetchBlob.fs
    //     //     .writeFile(excelFile, excelWorkBook, 'utf8')
    //     //     .then(() => {
    //     //         console.log(`wrote file ${excelFile}`);
    //     //         Alert.alert(
    //     //             'Success',
    //     //             `Send to ${excelFile}`,
    //     //             [{ text: 'OK', onPress: () => { } }],
    //     //             { cancelable: false },
    //     //         );
    //     //     })
    //     //     .catch(error => console.error(error));
    // };

    // const downloadCsv = () => {
    //     //if (productSales && productSales.dataSource && productSales.dataSource.data) {
    //     //const csvData = convertArrayToCSV(productSales.dataSource.data);
    //     const csvData = convertArrayToCSV(CsvData);

    //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
    //     console.log("PATH", pathToWrite)
    //     RNFetchBlob.fs
    //         .writeFile(pathToWrite, csvData, 'utf8')
    //         .then(() => {
    //             console.log(`wrote file ${pathToWrite}`);
    //             // wrote file /storage/emulated/0/Download/data.csv
    //             Alert.alert(
    //                 'Success',
    //                 `Send to ${pathToWrite}`,
    //                 [{ text: 'OK', onPress: () => { } }],
    //                 { cancelable: false },
    //             );
    //         })
    //         .catch(error => console.error(error));
    //     //}        
    // };

    var leftSpacing = '0%';

    if (windowWidth >= 1280) {
        leftSpacing = '28%';
    }

    const leftSpacingScale = {
        marginLeft: leftSpacing,
    };

    const flatListRef = useRef();

    const ScrollToTop = () => {
        flatListRef.current.scrollToOffset({ animated: true, offset: 0 })
    }

    const ScrollToBottom = () => {
        flatListRef.current.scrollToOffset({ animated: true, offset: 100 })
    }

    // function end

    return (
        <View
            style={[
                styles.container,
                {
                    height: windowHeight,
                    width: windowWidth,
                },
            ]}
        >
            <View style={{ flex: 0.8 }}>
                <SideBar navigation={navigation} selectedTab={8} expandReport={true} />
            </View>
            <View style={{ height: windowHeight, flex: 9 }}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{}}
                    contentContainerStyle={{
                        paddingBottom: windowHeight * 0.1,
                        backgroundColor: Colors.highlightColor,
                    }}
                >

                    <Modal
                        style={{}}
                        visible={exportModalVisibility}
                        supportedOrientations={['portrait', 'landscape']}
                        transparent={true}
                        animationType={'fade'}>
                        <View style={{
                            flex: 1,
                            backgroundColor: Colors.modalBgColor,
                            alignItems: 'center',
                            justifyContent: 'center',
                            // top: Platform.OS === 'android' ? 0 : keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
                        }}>
                            <View style={{
                                height: windowWidth * 0.3,
                                width: windowWidth * 0.4,
                                backgroundColor: Colors.whiteColor,
                                borderRadius: 12,
                                padding: windowWidth * 0.03,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <TouchableOpacity
                                    style={{
                                        position: 'absolute',
                                        right: windowWidth * 0.02,
                                        top: windowWidth * 0.02,

                                        elevation: 1000,
                                        zIndex: 1000,
                                    }}
                                    onPress={() => {
                                        setExportModalVisibility(false);
                                    }}>
                                    <AntDesign name="closecircle" size={switchMerchant ? 15 : 25} color={Colors.fieldtTxtColor} />
                                </TouchableOpacity>
                                <View style={{
                                    alignItems: 'center',
                                    top: '20%',
                                    position: 'absolute',
                                }}>
                                    <Text style={{
                                        fontFamily: 'NunitoSans-Bold',
                                        textAlign: 'center',
                                        fontSize: switchMerchant ? 16 : 24,
                                    }}>
                                        Download Report
                                    </Text>
                                </View>
                                <View style={{ top: switchMerchant ? '14%' : '10%' }}>
                                    <Text style={{ fontSize: switchMerchant ? 10 : 20, fontFamily: 'NunitoSans-Bold', }}>Email Address:</Text>
                                    <TextInput
                                        underlineColorAndroid={Colors.fieldtBgColor}
                                        style={{
                                            backgroundColor: Colors.fieldtBgColor,
                                            width: switchMerchant ? 240 : 370,
                                            height: switchMerchant ? 35 : 50,
                                            borderRadius: 5,
                                            padding: 5,
                                            marginVertical: 5,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                            paddingLeft: 10,
                                            fontSize: switchMerchant ? 10 : 14,
                                        }}
                                        placeholderStyle={{ padding: 5 }}
                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                        placeholder="Enter Your Email"
                                        onChangeText={(text) => {
                                            setExportEmail(text);
                                        }}
                                        value={exportEmail}
                                    />
                                    <Text style={{ fontSize: switchMerchant ? 10 : 20, fontFamily: 'NunitoSans-Bold', marginTop: 15, }}>Send As:</Text>

                                    <View style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                        marginTop: 10,
                                    }}>
                                        <CSVLink
                                            style={{
                                                justifyContent: "center",
                                                alignContent: "center",
                                                alignItems: "center",
                                                display: "inline-block",
                                                flexDirection: "row",
                                                textDecoration: 'none',
                                                borderWidth: 1,
                                                borderColor: Colors.primaryColor,
                                                backgroundColor: "#4E9F7D",
                                                borderRadius: 5,
                                                width: switchMerchant ? 100 : 100,
                                                paddingHorizontal: 10,
                                                height: switchMerchant ? 35 : 40,
                                                alignItems: "center",
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                            }}
                                            data={convertDataToExcelFormat()}
                                            filename="report.csv"
                                        >
                                            <View
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    alignSelf: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Text
                                                    style={{
                                                        color: Colors.whiteColor,
                                                        //marginLeft: 5,
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        fontFamily: "NunitoSans-Bold",
                                                    }}
                                                >
                                                    CSV
                                                </Text>
                                            </View>
                                        </CSVLink>

                                        {/* <TouchableOpacity
                                            style={[styles.modalSaveButton, {
                                                zIndex: -1
                                            }]}
                                            onPress={() => { downloadPDF() }}>
                                            <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>PDF</Text>
                                        </TouchableOpacity> */}
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Modal>

                    <Modal
                        style={{ flex: 1 }}
                        visible={visible}
                        transparent={true}
                        animationType="slide">

                        <KeyboardAvoidingView
                            behavior="padding"
                            style={{
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                flex: 1,
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: Dimensions.get('window').height,
                            }}>
                            <View style={styles.confirmBoxEmail}>

                                <Text style={{ fontSize: 24, justifyContent: "center", alignSelf: "center", marginTop: 40, fontFamily: 'NunitoSans-Bold' }}>Enter your email</Text>
                                <View style={{ justifyContent: "center", alignSelf: "center", alignContent: 'center', marginTop: 20, flexDirection: 'row', width: '80%' }}>
                                    <View style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                                        <Text style={{ color: Colors.descriptionColor, fontSize: 20, }}>
                                            email:
                                        </Text>
                                    </View>
                                    <TextInput
                                        underlineColorAndroid={Colors.fieldtBgColor}
                                        style={[styles.textInput8, { paddingLeft: 5 }]}
                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                        placeholder="Enter your email"
                                        // style={{
                                        //     // paddingLeft: 1,
                                        // }}                                        
                                        //defaultValue={extentionCharges}
                                        onChangeText={(text) => {
                                            // setState({ exportEmail: text });
                                            setExportEmail(text);
                                        }}
                                        value={exportEmail}
                                    />
                                </View>
                                <View
                                    style={{
                                        alignSelf: 'center',
                                        marginTop: 20,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        // width: 260,
                                        width: windowWidth * 0.2,
                                        height: 60,
                                        alignContent: 'center',
                                        flexDirection: "row",
                                        marginTop: 40,
                                    }}>
                                    <TouchableOpacity
                                        onPress={emailShiftSales}
                                        style={{
                                            backgroundColor: Colors.fieldtBgColor,
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            height: 60,
                                            borderBottomLeftRadius: 10,
                                            borderRightWidth: StyleSheet.hairlineWidth,
                                            borderTopWidth: StyleSheet.hairlineWidth
                                        }}>
                                        <Text style={{ fontSize: 22, color: Colors.primaryColor, fontFamily: 'NunitoSans-SemiBold' }}>
                                            Email
                                        </Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => {
                                            // setState({ visible: false });
                                            setVisible(false);
                                        }}
                                        style={{
                                            backgroundColor: Colors.fieldtBgColor,
                                            width: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            height: 60,
                                            borderBottomRightRadius: 10,
                                            borderTopWidth: StyleSheet.hairlineWidth
                                        }}>
                                        <Text style={{ fontSize: 22, color: Colors.descriptionColor, fontFamily: 'NunitoSans-SemiBold' }}>
                                            Cancel
                                        </Text>
                                    </TouchableOpacity>
                                </View>

                            </View>
                        </KeyboardAvoidingView>
                    </Modal>

                    <View style={{
                        // backgroundColor: 'red',
                        padding: 20,
                        backgroundColor: Colors.highlightColor,
                        padding: 20,
                        width: windowWidth * (1 - Styles.sideBarWidth),

                        // alignItems: 'center'
                    }}>
                        <DateTimePickerModal
                            isVisible={showDateTimePicker}
                            mode={'date'}
                            onConfirm={(text) => {
                                setRev_date(moment(text).startOf('day'));
                                setShowDateTimePicker(false);
                            }}
                            onCancel={() => {
                                setShowDateTimePicker(false);
                            }}
                            maximumDate={moment(rev_date1).toDate()}
                        />

                        <DateTimePickerModal
                            isVisible={showDateTimePicker1}
                            mode={'date'}
                            onConfirm={(text) => {
                                setRev_date1(moment(text).endOf('day'));
                                setShowDateTimePicker1(false);
                            }}
                            onCancel={() => {
                                setShowDateTimePicker1(false);
                            }}
                            minimumDate={moment(rev_date).toDate()}
                        />

                        {day ?
                            <View style={{ position: 'absolute', width: "24%", backgroundColor: Colors.whiteColor, left: 700, top: 170, zIndex: 6000 }}>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.primaryColor }} onPress={() => { setState({ rev_date: moment(currentDateTime).format("DD MMM yyyy"), rev_date1: moment(currentDateTime).format("DD MMM yyyy") }) }}>
                                    <Text style={{ color: Colors.whiteColor }}>Today</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { setState({ rev_date: moment(moment(currentDateTime).subtract(1, "day")).format("DD MMM yyyy"), rev_date1: moment(currentDateTime).format("DD MMM yyyy") }) }}>
                                    <Text style={{ color: "#828282" }}>Yesterday</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { setState({ rev_date: moment(moment(currentDateTime).subtract(7, "day")).format("DD MMM yyyy"), rev_date1: moment(currentDateTime).format("DD MMM yyyy") }) }}>
                                    <Text style={{ color: "#828282" }}>Last 7 days</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { setState({ rev_date: moment(moment(currentDateTime).subtract(1, "month")).format("DD MMM yyyy"), rev_date1: moment(currentDateTime).format("DD MMM yyyy") }) }}>
                                    <Text style={{ color: "#828282" }}>Last 30 days</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { setState({ rev_date: moment(currentDateTime).startOf('month'), rev_date1: moment(currentDateTime).endOf("month") }) }}>
                                    <Text style={{ color: "#828282" }}>This month</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { setState({ rev_date: moment(moment(currentDateTime).subtract(1, "month")).startOf('month'), rev_date1: moment(moment(currentDateTime).subtract(1, "month")).endOf("month") }) }}>
                                    <Text style={{ color: "#828282" }}>Last month</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }}>
                                    <Text style={{ color: "#828282" }}>Custom range</Text>
                                </TouchableOpacity>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1, marginLeft: 25 }}>
                                        <Text style={{ color: "#828282" }}>From</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text style={{ color: "#828282" }}>To</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <TouchableOpacity style={{ width: "38%", marginLeft: 25, borderWidth: 1, alignItems: "center", borderColor: "#919191", backgroundColor: Colors.fieldtBgColor }}
                                        onPress={() => { setState({ pick: 1, pick1: 0, pickerMode: 'date', showDateTimePicker: true }) }}>
                                        <Text style={{ fontSize: 12 }}>{moment(rev_date).format("DD MMM yyyy")}</Text>
                                    </TouchableOpacity>
                                    <View style={{ width: "8%" }}>
                                    </View>
                                    <TouchableOpacity style={{ width: "38%", borderWidth: 1, alignItems: "center", borderColor: "#919191", backgroundColor: Colors.fieldtBgColor }}
                                        onPress={() => { setState({ pick: 0, pick1: 1, pickerMode: 'date', showDateTimePicker: true }) }}>
                                        <Text style={{ fontSize: 12 }}>{moment(rev_date1).format("DD MMM yyyy")}</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 20 }}>
                                    <TouchableOpacity style={{ width: "38%", marginLeft: 15, borderWidth: 1, alignItems: "center", borderColor: "#919191", backgroundColor: Colors.whiteColor, height: 30, borderRadius: 5, justifyContent: "center", alignItems: 'center' }}
                                        onPress={() => { setState({ day: false }) }}>
                                        <Text style={{ fontSize: 15, color: "#919191" }}>Cancel</Text>
                                    </TouchableOpacity>
                                    <View style={{ width: "8%" }}>
                                    </View>
                                    <TouchableOpacity style={{ width: "38%", borderWidth: 1, alignItems: "center", borderColor: Colors.primaryColor, backgroundColor: Colors.primaryColor, height: 30, borderRadius: 5, justifyContent: "center", alignItems: 'center' }}
                                        onPress={() => { setState({ day: false }) }}>
                                        <Text style={{ fontSize: 15, color: Colors.whiteColor }}>Apply</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ height: 20 }}>
                                </View>
                            </View>
                            : null}
                        <View style={{ marginTop: 5, justifyContent: 'space-between', width: '100%', alignSelf: 'center' }}>
                            <View style={{
                                flexDirection: 'row',
                                //backgroundColor: '#ffffff',
                                justifyContent: 'space-between',
                                alignItems: 'center', alignSelf: 'center',
                                //padding: 18,
                                width: windowWidth * 0.87,
                            }}>
                                <View>
                                    <Text style={{ fontSize: switchMerchant ? 20 : 26, fontFamily: 'NunitoSans-Bold' }}>Sales By Shift</Text>
                                    {/* <Text style={{ fontSize: 16, marginTop: 10, color: Colors.descriptionColor }}>Date last updated on {moment(currentDateTime).format("DD MMM yyyy")}, {moment(currentDateTime).format("HH:mm a")}</Text> */}
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row'
                                    }}>
                                    <TouchableOpacity
                                        style={{
                                            justifyContent: 'center',
                                            flexDirection: 'row',
                                            borderWidth: 1,
                                            borderColor: Colors.primaryColor,
                                            backgroundColor: '#4E9F7D',
                                            borderRadius: 5,
                                            //width: 140,
                                            paddingHorizontal: 10,
                                            height: switchMerchant ? 35 : 40,
                                            alignItems: 'center',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 1,
                                            zIndex: -1,
                                            marginRight: 10,
                                        }}
                                        onPress={() => { setExportModalVisibility(true) }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            <Icon name="download" size={switchMerchant ? 10 : 20} color={Colors.whiteColor} />
                                            <Text style={{
                                                color: Colors.whiteColor,
                                                marginLeft: 5,
                                                fontSize: switchMerchant ? 10 : 16,
                                                fontFamily: 'NunitoSans-Bold',
                                            }}>
                                                DOWNLOAD
                                            </Text>
                                        </View>
                                    </TouchableOpacity>

                                    <View style={{
                                        width: switchMerchant ? 200 : 250,
                                        height: switchMerchant ? 35 : 40,
                                        backgroundColor: 'white',
                                        borderRadius: 5,
                                        //marginRight: 10,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        shadowColor: '#000',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 3,
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                    }}>
                                        <Icon name="search" size={switchMerchant ? 13 : 18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />
                                        <TextInput
                                            underlineColorAndroid={Colors.whiteColor}
                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                            style={{
                                                width: switchMerchant ? 180 : 220,
                                                fontSize: switchMerchant ? 10 : 15,
                                                fontFamily: 'NunitoSans-Regular',
                                                paddingLeft: 5,
                                                height: 45
                                            }}
                                            clearButtonMode="while-editing"
                                            placeholder=" Search"
                                            //placeholderTextColor={Colors.descriptionColor}
                                            onChangeText={(text) => {
                                                setSearch(text);
                                            }}
                                            value={search}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                        {showlist ? <View>
                            <View style={{
                                flexDirection: 'row',
                                //backgroundColor: '#ffffff',
                                justifyContent: 'space-between',
                                //padding: 18,
                                marginTop: 20,
                                width: '100%',
                                paddingLeft: switchMerchant ? 0 : windowWidth <= 1823 && windowWidth >= 1820 ? '1.5%' : '1%',
                                paddingRight: switchMerchant ? 0 : windowWidth <= 1823 && windowWidth >= 1820 ? '1.5%' : '1%',
                            }}>

                                <TouchableOpacity
                                    style={[{
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                        borderWidth: 1,
                                        borderColor: Colors.primaryColor,
                                        backgroundColor: '#4E9F7D',
                                        borderRadius: 5,
                                        //width: 160,
                                        paddingHorizontal: 10,
                                        height: switchMerchant ? 35 : 40,
                                        alignItems: 'center',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 1,
                                        zIndex: -1,

                                        opacity: !showDetails ? 0 : 100,
                                    }]}
                                    onPress={() => {
                                        setShowDetails(false)
                                        setCurrentPage(pageReturn);
                                        console.log('Returning to page')
                                        console.log(pageReturn)
                                        setPageCount(Math.ceil(shiftSales.length / perPage));
                                        setCurrReportSummarySort('')
                                        setCurrReportDetailsSort('')
                                    }}
                                    disabled={!showDetails}
                                >
                                    <AntDesign name="arrowleft" size={switchMerchant ? 10 : 20} color={Colors.whiteColor} style={{
                                        //top: 1,
                                        //marginRight: -5,
                                    }} />
                                    <Text style={{
                                        color: Colors.whiteColor,
                                        marginLeft: 5,
                                        fontSize: switchMerchant ? 10 : 16,
                                        fontFamily: 'NunitoSans-Bold',
                                        marginBottom: Platform.OS === 'ios' ? 0 : 2
                                    }}>
                                        Summary</Text>
                                </TouchableOpacity>


                                {/* <TouchableOpacity style={{ marginRight: 10, width: 230, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: 40, backgroundColor: Colors.whiteColor }}
                            onPress={() => { changeClick() }}>
                            <TouchableOpacity style={{ alignSelf: "center" }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                <EvilIcons name="calendar" size={25} color={Colors.primaryColor} />
                            </TouchableOpacity>
                            <Text style={{ fontFamily: "NunitoSans-Regular" }}>{moment(rev_date).format("DD MMM yyyy")} - {moment(rev_date1).format("DD MMM yyyy")}</Text>
                        </TouchableOpacity> */}
                                <View style={{ flexDirection: 'row' }}>
                                    {/* <TouchableOpacity
                                style={[leftSpacingScale, {
                                    marginRight: 10,
                                    paddingHorizontal: 15,
                                    backgroundColor: Colors.whiteColor,
                                    height: 40,
                                    // width: 200, 
                                    alignItems: 'center',
                                    borderRadius: 7,
                                    flexDirection: 'row',
                                    justifyContent: 'center', shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,

                                    opacity: !showDetails ? 0 : 100,
                                }]}
                                onPress={() => {
                                    setShowDetails(false);

                                    setCurrentPage(1);
                                    setPageCount(Math.ceil(shiftSales.length / perPage));
                                }}
                                disabled={!showDetails}
                            >
                                <AntDesign name="arrowleft" size={18} color={Colors.primaryColor} style={{
                                    top: 1,
                                    marginRight: -5,
                                }} />
                                <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, marginLeft: 12 }}>Back to Summary</Text>
                            </TouchableOpacity> */}

                                    {/* <View style={{
                                marginRight: 13, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, borderRadius: 10, height: 40,
                                shadowOpacity: 0,
                                shadowColor: '#000',
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                            }}>
                                <View style={{ position: 'absolute', justifyContent: 'center', backgroundColor: Colors.whiteColor, width: 230, height: 40, borderRadius: 10, }} />
                                <Text style={{ fontSize: 15, borderRightWidth: StyleSheet.hairlineWidth, paddingRight: Platform.OS == 'ios' ? 10 : 10, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>View By</Text>
                                <DropDownPicker
                                    // controller={instance => controller = instance}
                                    controller={instance => setController(instance)}
                                    arrowColor={Colors.primaryColor}
                                    arrowSize={21}
                                    arrowStyle={{ fontWeight: 'bold' }}
                                    style={{ width: Platform.OS == 'ios' ? windowWidth * 0.14 : windowWidth * 0.1, borderWidth: 0 }}
                                    itemStyle={{ justifyContent: 'flex-start' }}
                                    placeholderStyle={{ color: 'black' }}
                                    //change value below (Eason)
                                    items={[{ label: 'Open Time', value: 1 }, { label: 'Close Time', value: 2 }, { label: 'Outlet', value: 3 }, { label: 'Transactions', value: 4 }, { label: 'Sales', value: 5 }]}
                                    placeholder={"All outlets"}
                                    labelStyle={{ fontSize: 12.5 }}
                                    onChangeItem={selectedSort => {
                                        // setState({ sort: selectedSort }),
                                        sortingOrders(selectedSort);
                                    }
                                    }
                                //onOpen={() => controller1.close()}
                                />
                            </View> */}



                                    <View style={[{
                                        //marginRight: Platform.OS === 'ios' ? 0 : 10,
                                        // paddingLeft: 15,
                                        paddingHorizontal: 15, flexDirection: 'row',
                                        alignItems: 'center', borderRadius: 10, paddingVertical: 10, justifyContent: 'center',
                                        backgroundColor: Colors.whiteColor,
                                        shadowOpacity: 0,
                                        shadowColor: '#000',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 1,
                                    }]}>
                                        <View style={{ alignSelf: "center", marginRight: 5 }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                            <GCalendar width={switchMerchant ? 15 : 20} height={switchMerchant ? 15 : 20} />
                                        </View>

                                        <DatePicker
                                            selected={rev_date.toDate()}
                                            onChange={(date) => {
                                                setRev_date(moment(date).startOf('day'));
                                            }}
                                            maxDate={moment(rev_date1).toDate()}
                                        />

                                        <Text
                                            style={{
                                                fontFamily: "NunitoSans-Regular",
                                                paddingHorizontal: 5,
                                                marginLeft: 5,
                                            }}
                                        >
                                            -
                                        </Text>

                                        <DatePicker
                                            selected={rev_date1.toDate()}
                                            onChange={(date) => {
                                                setRev_date1(moment(date).endOf('day'));
                                            }}
                                            minDate={moment(rev_date).toDate()}
                                        />
                                    </View>

                                    {/* <TouchableOpacity
                                style={{
                                    paddingHorizontal: 15, backgroundColor: Colors.whiteColor, height: 40, width: 120, alignItems: 'center', borderRadius: 7, flexDirection: 'row', justifyContent: 'center', shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                }}
                                onPress={() => {
                                    // setState({
                                    //     visible: true
                                    // })
                                    setVisible(true);
                                }}
                            >
                                <Upload width={15} height={15} />
                                <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, marginLeft: 12 }}>Email</Text>
                            </TouchableOpacity> */}
                                </View>

                                {/* <View style={{ marginRight: 380, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, borderRadius: 10, height: windowHeight * 0.040 }}>
                            <View style={{ position: 'absolute', backgroundColor: Colors.whiteColor, width: 200, height: windowHeight * 0.040, borderRadius: 10, }} />
                            <Text style={{ fontSize: 16, borderRightWidth: StyleSheet.hairlineWidth, paddingRight: 20, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>View By</Text>
                            <DropDownPicker
                                arrowColor={Colors.primaryColor}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                style={{ width: windowWidth * 0.1, borderWidth: 0 }}
                                items={[{ label: 'All Outlet', value: 1 }, { label: 'Date/Time', value: 2 }, { label: 'Name', value: 3 }, { label: 'Waiting Time', value: 4 }, { label: 'Payment Status', value: 5 }, { label: 'Total', value: 6 }]}
                                placeholder={"All Outlet"}
                                placeholderStyle={{ color: 'black' }}
                                onChangeItem={selectedSort => {
                                    setState({ sort: selectedSort })

                                }
                                }
                            />
                        </View>

                        <TouchableOpacity style={{ marginRight: 10, width: 230, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.040, backgroundColor: Colors.whiteColor }}
                            onPress={() => { changeClick() }}>
                            <TouchableOpacity style={{ alignSelf: "center" }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                <EvilIcons name="calendar" size={30} color={Colors.primaryColor} />
                            </TouchableOpacity>
                            <Text style={{ fontFamily: "NunitoSans-Regular" }}>{moment(rev_date).format("DD MMM yyyy")} - {moment(rev_date1).format("DD MMM yyyy")}</Text>
                        </TouchableOpacity> */ }

                            </View>

                            <View style={{
                                marginTop: 10,
                                //marginLeft: 10,
                                zIndex: -1,
                                backgroundColor: Colors.whiteColor,
                                width: windowWidth * 0.87,
                                height: Platform.OS == 'android' ? windowHeight * 0.6 : windowHeight * 0.66,
                                marginTop: 15,
                                marginHorizontal: 30,
                                marginBottom: 10,
                                alignSelf: 'center',
                                borderRadius: 5,
                                shadowOpacity: 0,
                                shadowColor: '#000',
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 3,
                            }}>
                                {/* <TouchableOpacity
                            onPress={() => {
                                ScrollToTop();
                            }}
                            style={{ opacity: '20%', position: 'absolute', alignSelf: 'center', marginTop: '8%', zIndex: 10 }}>
                            <AntDesign name={'up'} size={23} color={Colors.fieldtBgColor} />
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => {
                                ScrollToBottom();
                            }}
                            style={{ opacity: '20%', position: 'absolute', alignSelf: 'center', marginTop: '42%', zIndex: 10 }}>
                            <AntDesign name={'down'} size={23} color={Colors.fieldtBgColor} />
                        </TouchableOpacity> */}
                                {
                                    !showDetails
                                        ?
                                        <View style={{ flexDirection: "row", paddingVertical: 10, }}>
                                            <View style={{ flexDirection: 'row', width: '5%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', textAlign: 'left' }}>{'No.\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                                {/* <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View> */}
                                            </View>
                                            {/* <View style={{ flexDirection: 'row', width: '20%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <TouchableOpacity onPress={() => {
                                                if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_ASC) {
                                                    setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_DESC)
                                                }
                                                else {
                                                    setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_ASC)
                                                }
                                            }}>
                                                <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>{'Outlet\n'}</Text>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>

                                            <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                            <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                        </View>
                                    </View> */}
                                            {/* <View style={{ flexDirection: 'row', width: '12%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <TouchableOpacity onPress={() => {
                                                if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_DATE_ASC) {
                                                    setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OPEN_DATE_DESC)
                                                }
                                                else {
                                                    setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OPEN_DATE_ASC)
                                                }
                                            }}>
                                                <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Cashier</Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>

                                            <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_DATE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                            <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_DATE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                        </View>
                                    </View> */}
                                            {/* <View style={{ flexDirection: 'row', width: '10%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Open Time</Text>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OPEN_DATE_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_DATE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OPEN_DATE_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_DATE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', width: '10%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>Close Time</Text>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.CLOSE_DATE_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.CLOSE_DATE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.CLOSE_DATE_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.CLOSE_DATE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View> */}
                                            {/* <View style={{ flexDirection: 'row', width: '10%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>{'Total\nSales'}</Text>
                                            <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View> */}
                                            <View style={{ flexDirection: 'row', width: '35%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_DATE_ASC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OPEN_DATE_DESC)
                                                        }
                                                        else {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OPEN_DATE_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Operation Time\n'}</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_DATE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_DATE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '12%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                {/* <View style={{ flexDirection: 'row', width: '17%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}> */}
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.ONLINE_TRANSACTION_ASC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.ONLINE_TRANSACTION_DESC)
                                                        }
                                                        else if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.ONLINE_TRANSACTION_DESC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OFFLINE_TRANSACTION_ASC)
                                                        }
                                                        else if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.OFFLINE_TRANSACTION_ASC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OFFLINE_TRANSACTION_DESC)
                                                        }
                                                        else {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.ONLINE_TRANSACTION_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'On/Offline\nTransaction'}</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.ONLINE_TRANSACTION_ASC || currReportSummarySort === REPORT_SORT_FIELD_TYPE.OFFLINE_TRANSACTION_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.ONLINE_TRANSACTION_DESC || currReportSummarySort === REPORT_SORT_FIELD_TYPE.OFFLINE_TRANSACTION_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '12%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC)
                                                        }
                                                        else {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Transaction\n'}</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>Qty</Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%', left: Platform.OS === 'ios' ? 0 : '-10%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '12%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.ONLINE_SALES_ASC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.ONLINE_SALES_DESC)
                                                        }
                                                        else if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.ONLINE_SALES_DESC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OFFLINE_SALES_ASC)
                                                        }
                                                        else if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.OFFLINE_SALES_ASC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OFFLINE_SALES_DESC)
                                                        }
                                                        else {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.ONLINE_SALES_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'On/Offline\nSales'}</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.ONLINE_SALES_ASC || currReportSummarySort === REPORT_SORT_FIELD_TYPE.OFFLINE_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.ONLINE_SALES_DESC || currReportSummarySort === REPORT_SORT_FIELD_TYPE.OFFLINE_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '8.5%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC)
                                                        }
                                                        else {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Sales\n'}</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '13.5%', alignItems: 'center', justifyContent: 'center', left: Platform.OS === 'ios' ? 0 : windowWidth * 0.007 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_BALANCE_ASC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OPEN_BALANCE_DESC)
                                                        }
                                                        else if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_BALANCE_DESC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.CLOSE_BALANCE_ASC)
                                                        }
                                                        else if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.CLOSE_BALANCE_ASC) {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.CLOSE_BALANCE_DESC)
                                                        }
                                                        else {
                                                            setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.OPEN_BALANCE_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Opening/Closing\nBalance'}</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '2%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_BALANCE_ASC || currReportSummarySort === REPORT_SORT_FIELD_TYPE.CLOSE_BALANCE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.OPEN_BALANCE_DESC || currReportSummarySort === REPORT_SORT_FIELD_TYPE.CLOSE_BALANCE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>

                                            {/* <View style={{ flexDirection: 'row', width: '10%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>{'Closing\nBalance'}</Text>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View> */}

                                            {/* <View style={{ flexDirection: 'row', width: '12%', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>{'Staff\nName'}</Text>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View> */}

                                        </View>
                                        :
                                        <View style={{ marginVertical: 10, flexDirection: 'row' }}>
                                            <View style={{ flexDirection: 'row', width: '5%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', textAlign: 'left' }}>No.</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                                {/* <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View> */}
                                            </View>

                                            <View style={{ flexDirection: 'row', width: '18%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <TouchableOpacity onPress={() => {
                                                            if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC) {
                                                                setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC)
                                                            }
                                                            else {
                                                                setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC)
                                                            }
                                                        }}>
                                                            <Text numberOfLines={1} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>Date/Time</Text>
                                                            <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>



                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '12%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_EMPLOYEE_NAME_ASC) {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_EMPLOYEE_NAME_DESC)
                                                        }
                                                        else {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_EMPLOYEE_NAME_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>Cashier</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>



                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '8%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC) {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC)
                                                        }
                                                        else {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>Sales</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>



                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            {/* <View style={{ flexDirection: 'row', flex: 2.7, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Transaction Time</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View> */}
                                            <View style={{ flexDirection: 'row', width: '8%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC) {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC)
                                                        }
                                                        else {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>Disc</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>



                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '8%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC) {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC)
                                                        }
                                                        else {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>Disc</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>%</Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>



                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '8%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC) {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC)
                                                        }
                                                        else {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>Tax</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '8%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC) {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_DESC)
                                                        }
                                                        else {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>Tax</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>%</Text>
                                                    </TouchableOpacity>
                                                </View>

                                                <View style={{ marginLeft: '3%' }}>

                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>



                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            {/* <View style={{ flexDirection: 'row', flex: 1, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <TouchableOpacity onPress={() => {
                                                if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC) {
                                                    setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_DESC)
                                                }
                                                else {
                                                    setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC)
                                                }
                                            }}>
                                                <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>GP</Text>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>%</Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>

                                            <Entypo name='triangle-up' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                            <Entypo name='triangle-down' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                        </View>
                                    </View> */}
                                            <View style={{ flexDirection: 'row', width: '13%', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_ASC) {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_DESC)
                                                        }
                                                        else {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>Sales Return</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', width: '12%', borderRightWidth: 0, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10, borderLeftWidth: 1, borderLeftColor: 'lightgrey' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <TouchableOpacity onPress={() => {
                                                        if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC) {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC)
                                                        }
                                                        else {
                                                            setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC)
                                                        }
                                                    }}>
                                                        <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>Net Sales</Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                            <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.primaryColor }}> *incl tax</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                    <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View>
                                            {/* <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Average Net</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View> */}
                                        </View>
                                }

                                {
                                    !showDetails ?
                                        <>
                                            {shiftSales.length > 0 ?
                                                <FlatList
                                                    showsVerticalScrollIndicator={false}
                                                    ref={flatListRef}
                                                    data={sortReportDataList(shiftSales, currReportSummarySort).filter(item => {
                                                        if (search != '') {
                                                            if (item.outletName.toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if (((moment(item.openDate).format('DD MMM YY'))).toString().toLowerCase().includes(search.toLocaleLowerCase())) {
                                                                return true;
                                                            }
                                                            else if (((moment(item.closeDate).format('hh:mma'))).toString().toLowerCase().includes(search.toLocaleLowerCase())) {
                                                                return true;
                                                            }
                                                            else if (item.onlineTransaction.toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if (item.offlineTransaction.toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if ((item.onlineSales.toFixed(2)).toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if ((item.offlineSales.toFixed(2)).toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if (item.openAmount.toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if (item.closeAmount.toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else {
                                                                return false;
                                                            }

                                                        }
                                                        else {
                                                            return true;
                                                        }
                                                    }).slice((currentPage - 1) * perPage, currentPage * perPage)}
                                                    // extraData={shiftSales}
                                                    renderItem={rendershiftList}
                                                    keyExtractor={(item, index) => String(index)}
                                                />
                                                :
                                                <View style={{ height: windowHeight * 0.4 }}>
                                                    <View style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <Text style={{ color: Colors.descriptionColor }}>- No Data Available -</Text>
                                                    </View>
                                                </View>
                                            }
                                        </>
                                        :
                                        <>
                                            {transactionTypeSalesDetails.length > 0 ?
                                                <FlatList
                                                    showsVerticalScrollIndicator={false}
                                                    ref={flatListRef}
                                                    data={sortReportDataList(transactionTypeSalesDetails, currReportDetailsSort).filter(item => {
                                                        if (search != '') {
                                                            if (item.waiterName.toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if (((moment(item.completedDate).format('DD MMM hh:mma'))).toString().toLowerCase().includes(search.toLocaleLowerCase())) {
                                                                return true;
                                                            }
                                                            else if ((item.totalPrice.toFixed(2)).toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if ((item.discount.toFixed(2)).toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if (item.discountPercentage !== undefined && (item.discountPercentage.toFixed(2)).toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if ((item.tax.toFixed(2)).toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }
                                                            else if ((item.finalPrice.toFixed(2)).toString().toLowerCase().includes(search.toLowerCase())) {
                                                                return true;
                                                            }

                                                            else {
                                                                return false;
                                                            }
                                                        }
                                                        else {
                                                            return true;
                                                        }
                                                    }).slice((currentDetailsPage - 1) * perPage, currentDetailsPage * perPage)}
                                                    renderItem={renderItemDetails}
                                                    keyExtractor={(item, index) => String(index)}
                                                />
                                                :
                                                <View style={{ height: windowHeight * 0.4 }}>
                                                    <View style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                        <Text style={{ color: Colors.descriptionColor }}>- No Data Available -</Text>
                                                    </View>
                                                </View>
                                            }
                                        </>
                                }
                            </View>

                            {!showDetails ?
                                <View style={{
                                    flexDirection: 'row',
                                    marginTop: 5,
                                    width: windowWidth * 0.87,
                                    alignItems: 'center', alignSelf: 'center',
                                    justifyContent: 'flex-end',
                                    // top: Platform.OS == 'ios' ? pushPagingToTop && keyboardHeight > 0 ? -keyboardHeight * 0.93 : 0 : 0,
                                    // backgroundColor: pushPagingToTop && keyboardHeight > 0 ? Colors.highlightColor : null,
                                    // borderWidth: pushPagingToTop && keyboardHeight > 0 ? 1 : 0,
                                    // borderColor: pushPagingToTop && keyboardHeight > 0 ? '#E5E5E5' : null,
                                    // borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                                    // paddingHorizontal: pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                                    // shadowOffset: {
                                    //     width: 0,
                                    //     height: 1,
                                    // },
                                    // shadowOpacity: 0.22,
                                    // shadowRadius: 3.22,
                                    // elevation: 1,
                                    paddingBottom: 10,
                                }}>
                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%', }}>Items Showed</Text>
                                    <View style={{
                                        width: Platform.OS === 'ios' ? 65 : '13%', //65,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: Platform.OS === 'ios' ? 0 : 0,
                                        //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                                        // paddingTop: '-60%',
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                        marginRight: '1%',
                                    }}>
                                        <Picker
                                            style={{
                                                //backgroundColor: 'red',
                                                height: 35,

                                                chevronContainer: {
                                                    display: "none",
                                                },
                                                chevronDown: {
                                                    display: "none",
                                                },
                                                chevronUp: {
                                                    display: "none",
                                                },
                                            }}
                                            onValueChange={(value, text) => {
                                                setPerPage(value);
                                                var currentPageTemp = text.length > 0 ? parseInt(text) : 1;

                                                setCurrentPage(currentPageTemp > pageCount ? pageCount : (currentPageTemp < 1 ? 1 : currentPageTemp));
                                            }}
                                        >
                                            {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                                                label: 'All',
                                                value: !showDetails ? shiftSales.length : transactionTypeSalesDetails.length,
                                            }).map((value, index) => {
                                                return (
                                                    <Picker.Item
                                                        label={value.label}
                                                        value={value.value}
                                                    />
                                                )
                                            })
                                            }
                                        </Picker>
                                    </View>

                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%' }}>Page</Text>
                                    <View style={{
                                        width: switchMerchant ? 65 : 70,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: 22,
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                    }}>
                                        {console.log('currentPage')}
                                        {console.log(currentPage)}

                                        <TextInput
                                            onChangeText={(text) => {
                                                var currentPageTemp = text.length > 0 ? parseInt(text) : 1;

                                                setCurrentPage(currentPageTemp > pageCount ? pageCount : (currentPageTemp < 1 ? 1 : currentPageTemp));

                                            }}
                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                            placeholder={pageCount !== 0 ? currentPage.toString() : '0'}

                                            style={{
                                                color: 'black',
                                                fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular',
                                                marginTop: Platform.OS === 'ios' ? 0 : -15,
                                                marginBottom: Platform.OS === 'ios' ? 0 : -15,
                                                textAlign: 'center',
                                                width: '100%',
                                            }}
                                            value={pageCount !== 0 ? currentPage.toString() : '0'}
                                            defaultValue={pageCount !== 0 ? currentPage.toString() : '0'}
                                            keyboardType={'numeric'}
                                            onFocus={() => { setPushPagingToTop(true) }}
                                        />
                                    </View>
                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginLeft: '1%', marginRight: '1%' }}>of {pageCount}</Text>
                                    <TouchableOpacity style={{ width: switchMerchant ? 30 : 45, height: switchMerchant ? 20 : 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }}
                                        onPress={() => { prevPage() }}>
                                        <ArrowLeft
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ width: switchMerchant ? 30 : 45, height: switchMerchant ? 20 : 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }} onPress={() => { nextPage() }}>
                                        <ArrowRight
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                </View>
                                :
                                <View style={{
                                    flexDirection: 'row',
                                    marginTop: 5,
                                    width: windowWidth * 0.87,
                                    alignItems: 'center', alignSelf: 'center',
                                    justifyContent: 'flex-end',
                                    // top: Platform.OS == 'ios' ? pushPagingToTop && keyboardHeight > 0 ? -keyboardHeight * 0.93 : 0 : 0,
                                    // backgroundColor: pushPagingToTop && keyboardHeight > 0 ? Colors.highlightColor : null,
                                    // borderWidth: pushPagingToTop && keyboardHeight > 0 ? 1 : 0,
                                    // borderColor: pushPagingToTop && keyboardHeight > 0 ? '#E5E5E5' : null,
                                    // borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                                    // paddingHorizontal: pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                                    // shadowOffset: {
                                    //     width: 0,
                                    //     height: 1,
                                    // },
                                    // shadowOpacity: 0.22,
                                    // shadowRadius: 3.22,
                                    // elevation: 1,
                                    paddingBottom: 10,
                                }}>
                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%', }}>Items Showed</Text>
                                    <View style={{
                                        width: Platform.OS === 'ios' ? 65 : '13%', //65,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: Platform.OS === 'ios' ? 0 : 0,
                                        //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                                        // paddingTop: '-60%',
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                        marginRight: '1%',
                                    }}>
                                        <Picker
                                            style={{
                                                //backgroundColor: 'red',
                                                height: 35,

                                                chevronContainer: {
                                                    display: "none",
                                                },
                                                chevronDown: {
                                                    display: "none",
                                                },
                                                chevronUp: {
                                                    display: "none",
                                                },
                                            }}
                                            onValueChange={(value) => {
                                                setPerPage(value);
                                            }}
                                        >
                                            {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                                                label: 'All',
                                                value: !showDetails ? shiftSales.length : transactionTypeSalesDetails.length,
                                            }).map((value, index) => {
                                                return (
                                                    <Picker.Item
                                                        label={value.label}
                                                        value={value.value}
                                                    />
                                                )
                                            })
                                            }
                                        </Picker>
                                    </View>

                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%' }}>Page</Text>
                                    <View style={{
                                        width: switchMerchant ? 65 : 70,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: 22,
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                    }}>
                                        {console.log('currentDetailsPage')}
                                        {console.log(currentDetailsPage)}

                                        <TextInput
                                            onChangeText={(text) => {
                                                var currentPageTemp = text.length > 0 ? parseInt(text) : 1;

                                                setCurrentDetailsPage(currentPageTemp > pageCount ? pageCount : (currentPageTemp < 1 ? 1 : currentPageTemp));

                                            }}
                                            placeholder={pageCount !== 0 ? currentDetailsPage.toString() : '0'}
                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                            style={{
                                                color: 'black',
                                                fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular',
                                                marginTop: Platform.OS === 'ios' ? 0 : -15,
                                                marginBottom: Platform.OS === 'ios' ? 0 : -15,
                                                textAlign: 'center',
                                                width: '100%',
                                            }}
                                            value={pageCount !== 0 ? currentDetailsPage.toString() : '0'}
                                            defaultValue={pageCount !== 0 ? currentDetailsPage.toString() : '0'}
                                            keyboardType={'numeric'}
                                            onFocus={() => { setPushPagingToTop(true) }}
                                        />
                                    </View>
                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginLeft: '1%', marginRight: '1%' }}>of {pageCount}</Text>
                                    <TouchableOpacity style={{ width: switchMerchant ? 30 : 45, height: switchMerchant ? 20 : 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }}
                                        onPress={() => { prevDetailsPage() }}>
                                        <ArrowLeft
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ width: switchMerchant ? 30 : 45, height: switchMerchant ? 20 : 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }} onPress={() => { nextDetailsPage() }}>
                                        <ArrowRight
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                </View>
                            }

                        </View>
                            :
                            null}

                        {showDetail ? <View>
                            <Modal
                                style={{ flex: 1 }}
                                visible={visible}
                                transparent={true}
                                animationType="slide">
                                <View
                                    style={{
                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        minHeight: Dimensions.get('window').height,
                                    }}>
                                    <View style={styles.confirmBox}>

                                        <Text style={{ fontWeight: "700", fontSize: 20, justifyContent: "center", alignSelf: "center", marginTop: 20 }}>Select category</Text>
                                        <View style={{ flexDirection: "row", marginTop: 20 }}>

                                            <View style={{ flexDirection: "row", flex: 1, justifyContent: "center" }}>
                                                <CheckBox
                                                    value={isSelected}
                                                    onValueChange={(isSelected) => setState({ isSelected })} />
                                                <Text style={{ color: isSelected == true ? Colors.primaryColor : Colors.blackColor, marginTop: 5, marginLeft: 5 }}>General</Text>
                                            </View>

                                            <View style={{ flexDirection: "row", flex: 1, justifyContent: "center" }}>
                                                <CheckBox
                                                    value={isSelected1}
                                                    onValueChange={(isSelected1) => setState({ isSelected1 })} />
                                                <Text style={{ color: isSelected1 == true ? Colors.primaryColor : Colors.blackColor, marginTop: 5, marginLeft: 5 }}>Cash drawer Summary</Text>
                                            </View>

                                        </View>

                                        <View style={{ flexDirection: "row", marginTop: 5 }}>

                                            <View style={{ flexDirection: "row", justifyContent: "center", marginLeft: "17%" }}>
                                                <CheckBox
                                                    value={isSelected2}
                                                    onValueChange={(isSelected2) => setState({ isSelected2 })} />
                                                <Text style={{ color: isSelected2 == true ? Colors.primaryColor : Colors.blackColor, marginTop: 5, marginLeft: 5 }}>Sales Summary</Text>
                                            </View>

                                            <View style={{ flexDirection: "row", justifyContent: "center", marginLeft: "15%" }}>
                                                <CheckBox
                                                    value={isSelected3}
                                                    onValueChange={(isSelected3) => setState({ isSelected3 })} />
                                                <Text style={{ color: isSelected3 == true ? Colors.primaryColor : Colors.blackColor, marginTop: 5, marginLeft: 5 }}>Shift transaction</Text>
                                            </View>

                                        </View>

                                        <View style={{ flexDirection: "row", marginTop: 5 }}>

                                            <View style={{ flexDirection: "row", marginLeft: "17%" }}>
                                                <CheckBox
                                                    value={isSelected4}
                                                    onValueChange={(isSelected4) => setState({ isSelected4 })} />
                                                <Text style={{ color: isSelected4 == true ? Colors.primaryColor : Colors.blackColor, marginTop: 5, marginLeft: 5 }}>Tax Summary</Text>
                                            </View>

                                            <View style={{ flexDirection: "row", marginLeft: "17%" }}>
                                                <CheckBox
                                                    value={isSelected5}
                                                    onValueChange={(isSelected5) => setState({ isSelected5 })} />
                                                <Text style={{ color: isSelected5 == true ? Colors.primaryColor : Colors.blackColor, marginTop: 5, marginLeft: 5 }}>Tax Summary</Text>
                                            </View>

                                        </View>

                                        <View style={{ flexDirection: "row", marginTop: 5 }}>

                                            <View style={{ flexDirection: "row", marginLeft: "17%" }}>
                                                <CheckBox
                                                    value={isSelected6}
                                                    onValueChange={(isSelected6) => setState({ isSelected6 })} />
                                                <Text style={{ color: isSelected6 == true ? Colors.primaryColor : Colors.blackColor, marginTop: 5, marginLeft: 5 }}>Cancel/Discount summary</Text>
                                            </View>

                                            <View style={{ flexDirection: "row", marginLeft: "1.8%" }}>
                                                <CheckBox
                                                    value={isSelected7}
                                                    onValueChange={(isSelected7) => setState({ isSelected7 })} />
                                                <Text style={{ color: isSelected7 == true ? Colors.primaryColor : Colors.blackColor, marginTop: 5, marginLeft: 5 }}>Cancel/Discount summary</Text>
                                            </View>

                                        </View>
                                        {detail.map((element) => {
                                            return (<View
                                                style={{
                                                    alignSelf: 'center',
                                                    marginTop: 20,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: 260,
                                                    height: 40,
                                                    alignContent: 'center',
                                                    flexDirection: "row",
                                                    marginTop: 40,
                                                }}>
                                                <TouchableOpacity
                                                    onPress={() => { if (email == 1) { emailFunc(element.orderId) } else { downloadFunc(shiftlist) } }}
                                                    style={{
                                                        backgroundColor: Colors.fieldtBgColor,
                                                        width: '100%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        alignContent: 'center',
                                                        height: 80,
                                                        borderBottomLeftRadius: 10,
                                                        borderRightWidth: StyleSheet.hairlineWidth,
                                                        borderTopWidth: StyleSheet.hairlineWidth
                                                    }}>
                                                    <Text style={{ fontSize: 22, color: Colors.primaryColor }}>
                                                        Download/Email
                                                    </Text>
                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        setState({ visible: false });
                                                    }}
                                                    style={{
                                                        backgroundColor: Colors.fieldtBgColor,
                                                        width: '100%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        alignContent: 'center',
                                                        height: 80,
                                                        borderBottomRightRadius: 10,
                                                        borderTopWidth: StyleSheet.hairlineWidth
                                                    }}>
                                                    <Text style={{ fontSize: 22, color: Colors.descriptionColor }}>
                                                        Cancel
                                                    </Text>
                                                </TouchableOpacity>
                                            </View>)
                                        })}

                                    </View>
                                </View>
                            </Modal>
                            <View style={{ flexDirection: "row", marginTop: 20, marginLeft: 30 }}>

                                <View style={{ marginRight: 620, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, borderRadius: 10, height: windowHeight * 0.055 }}>
                                </View>

                                <TouchableOpacity style={{ marginRight: 10, width: 120, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.055, backgroundColor: Colors.whiteColor }}
                                    onPress={() => { setState({ visible: true, email: 0, download: 1 }) }}>
                                    <AntDesign name="download" size={20} color={Colors.primaryColor} />
                                    <Text style={{ fontFamily: "NunitoSans-Regular", marginLeft: 10 }}>Download</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={{ width: 100, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.055, backgroundColor: Colors.whiteColor }}
                                    onPress={() => { setState({ visible: true, email: 1, download: 0 }) }}>
                                    <AntDesign name="upload" size={20} color={Colors.primaryColor} />
                                    <Text style={{ fontFamily: "NunitoSans-Regular", marginLeft: 10 }}>Email</Text>
                                </TouchableOpacity>

                            </View>

                            <View style={{
                                padding: 20,
                                flexDirection: "row",
                                width: general ? '87%' : salesSummary ? "84%" : taxSummary ? '86.6%' : shift ? '86%' : '86.7%',
                                backgroundColor: "#cae6da",
                                marginLeft: 30,
                                marginTop: 20
                            }}>
                                <View style={{ width: "2%" }}></View>
                                <TouchableOpacity style={{ width: "13%" }}
                                    onPress={() => {
                                        setState({
                                            general: true,
                                            salesSummary: false,
                                            taxSummary: false,
                                            discount: false,
                                            drawer: false,
                                            shift: false
                                        })
                                    }}>
                                    <Text>General</Text>
                                </TouchableOpacity >
                                <TouchableOpacity style={{ width: "17%" }}
                                    onPress={() => {
                                        setState({
                                            general: false,
                                            salesSummary: true,
                                            taxSummary: false,
                                            discount: false,
                                            drawer: false,
                                            shift: false
                                        })
                                    }}>
                                    <Text>Sales Summary</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ width: "13%" }}
                                    onPress={() => {
                                        setState({
                                            general: false,
                                            salesSummary: false,
                                            taxSummary: true,
                                            discount: false,
                                            drawer: false,
                                            shift: false
                                        })
                                    }}>
                                    <Text>Tax Summary</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ width: "17%" }}
                                    onPress={() => {
                                        setState({
                                            general: false,
                                            salesSummary: false,
                                            taxSummary: false,
                                            discount: true,
                                            drawer: false,
                                            shift: false
                                        })
                                    }}>
                                    <Text>Cancels/Discount Summary</Text>
                                </TouchableOpacity >
                                <TouchableOpacity style={{ width: "18%" }}
                                    onPress={() => {
                                        setState({
                                            general: false,
                                            salesSummary: false,
                                            taxSummary: false,
                                            discount: false,
                                            drawer: true,
                                            shift: false
                                        })
                                    }}>
                                    <Text>Cash Drawer Summary</Text>
                                </TouchableOpacity>
                                <View style={{ width: "2%" }}></View>
                                <TouchableOpacity style={{ width: "15%" }}
                                    onPress={() => {
                                        setState({
                                            general: false,
                                            salesSummary: false,
                                            taxSummary: false,
                                            discount: false,
                                            drawer: false,
                                            shift: true
                                        })
                                    }}>
                                    <Text>Shift Transactions</Text>
                                </TouchableOpacity>
                            </View>

                            {general ? <View><View style={{ marginLeft: 30, backgroundColor: Colors.whiteColor, width: "87%", height: 480 }}>
                                <Text style={{ paddingVertical: 15, marginLeft: 20, color: "#a3a3a3" }}>Transaction Details</Text>
                                <View style={{ flexDirection: "row" }}>
                                    <TouchableOpacity style={{ marginRight: 430, width: 120, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.055, backgroundColor: Colors.whiteColor }}
                                        onPress={() => { setState({ showDetail: false, showlist: true }) }}>
                                        <Feather name="chevron-left" size={30} color={Colors.primaryColor} />
                                        <Text style={{ fontFamily: "NunitoSans-Regular", color: Colors.primaryColor }}>Back</Text>
                                    </TouchableOpacity>

                                </View>
                                <View style={{ alignSelf: "center", backgroundColor: Colors.secondaryColor, paddingVertical: 60, paddingHorizontal: 60, borderRadius: 100 }}></View>
                                <Text style={{ alignSelf: "center", fontWeight: "bold", fontSize: 20, marginTop: 10 }}>John Doe</Text>

                                {detail.map((element) => {
                                    return (
                                        <View style={{ flexDirection: "row", marginTop: 20 }}>
                                            <View style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}>
                                                <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Open Time:</Text>
                                                <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 70, color: "#9c9c9c" }}>{moment(element.shift.createdAt).format("DD/MM/YYYY")} {moment(element.shift.createdAt).format("hh:mm")}</Text>
                                            </View>
                                            <View style={{ flexDirection: "row", flex: 1 }}>
                                                <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Close Time:</Text>
                                                <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 50, color: "#9c9c9c" }}>{moment(element.shift.updatedAt).format("DD/MM/YYYY")} {moment(element.shift.updatedAt).format("hh:mm")}</Text>
                                            </View>
                                        </View>
                                    )
                                })}

                                {detail.map((element) => {
                                    return (
                                        <View style={{ flexDirection: "row", marginTop: 20 }}>
                                            <View style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}>
                                                <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Outlet:</Text>
                                                <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 100, color: "#9c9c9c" }}>{element.shift.outlet.name}</Text>
                                            </View>
                                            <View style={{ flexDirection: "row", flex: 1, marginLeft: 10 }}>

                                            </View>
                                        </View>
                                    )
                                })}

                                {detail.map((element) => {
                                    return (
                                        <View style={{ flexDirection: "row", marginTop: 20 }}>
                                            <View style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}>
                                                <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Total Transactions:</Text>
                                                <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 20, color: "#9c9c9c" }}>{element.order.TotalTransaction}</Text>
                                            </View>
                                            <View style={{ flexDirection: "row", flex: 1 }}>
                                                <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Total Sales:</Text>
                                                <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 50, color: "#9c9c9c" }}>{element.order.TotalSales}</Text>
                                            </View>
                                        </View>
                                    )
                                })}

                                <View style={{ flexDirection: "row", marginTop: 20 }}>
                                    <View style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}>
                                        <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Open By:</Text>
                                        <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 90, color: "#9c9c9c" }}>John Doe</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", flex: 1 }}>
                                        <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Close By:</Text>
                                        <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 65, color: "#9c9c9c" }}>John Doe</Text>
                                    </View>
                                </View>

                            </View>


                                <View style={{ height: 60 }}></View></View> : null}

                            {salesSummary ? <View>
                                <View style={{ marginLeft: 30, backgroundColor: Colors.whiteColor, width: "84%", height: 550 }}>

                                    <View style={{ flexDirection: "row", marginTop: 30, marginLeft: 20 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "45%" }}>Transactions</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "17%" }}>Amount (RM)</Text>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "42%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Cash Sales</Text>
                                        </View>
                                        <View style={{ width: "27%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>2</Text>
                                        </View>
                                        <View style={{ width: "25%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>24.50</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "42%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Cash Refunds</Text>
                                        </View>
                                        <View style={{ width: "27%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "25%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "42%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Cash Rounded</Text>
                                        </View>
                                        <View style={{ width: "27%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "25%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 20 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "62%" }}>Cash Net:</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "2%" }}>24.50</Text>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 30, marginLeft: 20 }}>
                                        <View style={{ width: "42%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Credit Card Sales</Text>
                                        </View>
                                        <View style={{ width: "27%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "25%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "42%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Credit Card  Refunds</Text>
                                        </View>
                                        <View style={{ width: "27%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "25%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 20 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "56%" }}> Credit Cash Net:</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "2%" }}>24.50</Text>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 30, marginLeft: 20 }}>
                                        <View style={{ width: "42%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Debit Card Sales</Text>
                                        </View>
                                        <View style={{ width: "27%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "25%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "42%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Debit Card  Refunds</Text>
                                        </View>
                                        <View style={{ width: "27%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "25%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 20 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "56%" }}> Debit Cash Net:</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "2%" }}>24.50</Text>
                                    </View>

                                </View>
                                <View style={{ marginTop: 20, marginLeft: "73%", flexDirection: "row" }}>
                                    <Text style={{ marginRight: 10 }}>Page</Text>
                                    <View style={{ borderWidth: 1, borderColor: Colors.blackColor, width: 60, alignItems: 'center' }}>
                                        <Text>{quantity}</Text>
                                    </View>
                                    <Text>  of 1,114</Text>
                                    <TouchableOpacity style={{ marginLeft: 10, width: 23, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }}
                                        onPress={() => { }}>
                                        <EvilIcons name="chevron-left" size={20} color={Colors.whiteColor} />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ width: 23, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }} onPress={() => { }}>
                                        <EvilIcons name="chevron-right" size={20} color={Colors.whiteColor} />
                                    </TouchableOpacity>
                                </View>
                                <View style={{ height: 20 }}></View></View> : null}

                            {taxSummary ? <View>
                                <View style={{ marginLeft: 30, backgroundColor: Colors.whiteColor, width: "86.6%", height: 550 }}>

                                    <View style={{ flexDirection: "row", marginTop: 30, marginLeft: 10 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: 30 }}>Tax Code</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "25%" }}>Tax Rate</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "25%" }}>Amount (RM)</Text>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "33%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>SR</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0%</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>


                                    <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 20 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "61%" }}>Total:</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "4%" }}>0.00</Text>
                                    </View>

                                </View>

                                <View style={{ height: 20 }}></View></View> : null}

                            {discount ? <View>
                                <View style={{ marginLeft: 30, backgroundColor: Colors.whiteColor, width: "86.6%", height: 550 }}>

                                    <View style={{ flexDirection: "row", marginTop: 30, marginLeft: 10 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "10%" }}></Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "25%" }}>Transactions</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "22%" }}>Amount (RM)</Text>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "33%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Discount</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "33%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Cancelled Transactions</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>


                                    <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 20 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "59%" }}>Total:</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "4%" }}>0.00</Text>
                                    </View>

                                </View>

                                <View style={{ height: 20 }}></View></View> : null}

                            {drawer ? <View>
                                <View style={{ marginLeft: 30, backgroundColor: Colors.whiteColor, width: "86.6%", height: 550 }}>

                                    <View style={{ flexDirection: "row", marginTop: 30, marginLeft: 10 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: 30 }}></Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "32%" }}>Quantity</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "25%" }}>Amount (RM)</Text>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "33%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Opening Account</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text></Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>500.00</Text>
                                        </View>
                                    </View>

                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "33%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Cash Sales</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>2</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>24.50</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "33%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Cash Refunds</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "33%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Cash Rounded</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text></Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "33%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Play Out</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: "row", marginTop: 5, marginLeft: 20 }}>
                                        <View style={{ width: "33%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>Play In</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0</Text>
                                        </View>
                                        <View style={{ width: "31%", backgroundColor: Colors.fieldtBgColor, padding: 10, marginRight: 10 }}>
                                            <Text>0.00</Text>
                                        </View>
                                    </View>


                                    <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 20 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "51%" }}>Expected Drawer:</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "2%" }}>525.50</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 20 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "53%" }}>Actual Drawer:</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "2%" }}>550.00</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 20 }}>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "56%" }}>Over/Short:</Text>
                                        <Text style={{ fontSize: 16, fontWeight: "700", marginLeft: "2%" }}>25.50</Text>
                                    </View>

                                </View>

                                <View style={{ height: 20 }}></View></View> : null}

                            {shift ? <View>
                                <View style={{ marginLeft: 30, backgroundColor: Colors.whiteColor, width: "86%", height: 465 }}>
                                    {
                                        !showDetails ?
                                            <View style={{ flexDirection: "row", paddingVertical: 30 }}>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "12%" }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Time</Text>
                                                </View>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "11%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Upload Time</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 8 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "10%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Employee</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 30 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "9%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Type</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 20 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "9%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Subtotal</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 25 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "9%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Discount</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 20 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>

                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "9%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Tax</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 20 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>

                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "11%" }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Total (RM)</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 20 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                            </View>
                                            :
                                            <View style={{ flexDirection: "row", paddingVertical: 30 }}>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "12%" }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Time</Text>
                                                </View>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "11%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Upload Time</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 8 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "10%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Employee</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 30 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "9%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Type</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 20 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "9%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Subtotal</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 25 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "9%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Discount</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 20 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>

                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "9%", borderRightWidth: StyleSheet.hairlineWidth }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Tax</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 20 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>

                                                <View style={{ flexDirection: "row", marginLeft: 20, width: "11%" }}>
                                                    <Text style={{ fontWeight: "700", fontSize: 10 }}>Total (RM)</Text>
                                                    <View style={{ marginTop: 2, marginLeft: 20 }}>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretup" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                        <TouchableOpacity onPress={() => { }}>
                                                            <AntDesign name="caretdown" size={6} color={Colors.descriptionColor} />
                                                        </TouchableOpacity>
                                                    </View>
                                                </View>
                                            </View>
                                    };
                                    {/* {
                            !showDetails ?  
                            <FlatList
                                data={list1}
                                renderItem={renderList1}
                                keyExtractor={(item, index) => String(index)}
                            />
                            :
                            <FlatList
                                data={list1}
                                renderItem={renderItemDetails1}
                                keyExtractor={(item, index) => String(index)}
                            />
                        }; */}
                                </View>
                            </View>
                                :
                                null
                            }


                        </View> : null}

                    </View>
                </ScrollView>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.fieldtBgColor,
        flexDirection: 'row',
        width: '100%'
    },
    headerLogo: {
        width: 112,
        height: 25,
        marginLeft: 10
    },
    list: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
    },
    listItem: {
        marginLeft: 20,
        color: Colors.descriptionColor,
        fontSize: 16,
    },
    sidebar: {
        width: Dimensions.get('screen').width * Styles.sideBarWidth,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
    content: {
        padding: 20,
        width: Dimensions.get('screen').width * 0.87,
        backgroundColor: Colors.fieldtBgColor,
    },
    confirmBox: {
        width: 520,
        height: 300,
        borderRadius: 10,
        backgroundColor: Colors.whiteColor,
    },
    confirmBoxEmail: {
        width: Dimensions.get('screen').width * 0.4,
        height: Dimensions.get('screen').height * 0.3,
        borderRadius: 10,
        backgroundColor: Colors.whiteColor,
        justifyContent: 'space-between'
    },
    circleIcon: {
        width: 30,
        height: 30,
        // resizeMode: 'contain',
        marginRight: 10,
        alignSelf: 'center'
    },
    modalContainer: {
        flex: 1,
        backgroundColor: Colors.modalBgColor,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalView: {
        height: Dimensions.get('screen').width * 0.2,
        width: Dimensions.get('screen').width * 0.3,
        backgroundColor: Colors.whiteColor,
        borderRadius: 12,
        padding: Dimensions.get('screen').width * 0.03,
        alignItems: 'center',
        justifyContent: 'center'
    },
    closeButton: {
        position: 'absolute',
        right: Dimensions.get('screen').width * 0.02,
        top: Dimensions.get('screen').width * 0.02,

        elevation: 1000,
        zIndex: 1000,
    },
    modalTitle: {
        alignItems: 'center',
        top: '20%',
        position: 'absolute',
    },
    modalBody: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'

    },
    modalTitleText: {
        fontFamily: 'NunitoSans-Bold',
        textAlign: 'center',
        fontSize: 20,
    },
    modalDescText: {
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 18,
        color: Colors.fieldtTxtColor
    },
    modalBodyText: {
        flex: 1,
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 25,
        width: "20%",
    },
    modalSaveButton: {
        width: Dimensions.get('screen').width * 0.15,
        backgroundColor: Colors.fieldtBgColor,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,

        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 1,

        marginVertical: 10,
    },
    submitText: {
        height: Platform.OS == 'ios' ? Dimensions.get('screen').height * 0.06 : Dimensions.get('screen').height * 0.07,
        paddingVertical: 5,
        paddingHorizontal: 20,
        flexDirection: 'row',
        color: '#4cd964',
        textAlign: 'center',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
});
export default ReportSaleByShift;
