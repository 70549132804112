import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Modal,
  KeyboardAvoidingView,
  ActivityIndicator,
  ViewPagerAndroidBase,
  TouchableOpacityBase,
  Linking,
  Platform,
  CheckBox,
  Picker,
  useWindowDimensions,
} from "react-native";
import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
import AntDesign from "react-native-vector-icons/AntDesign";
import AIcon from "react-native-vector-icons/AntDesign";
import Entypo from "react-native-vector-icons/Entypo";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import EvilIcons from "react-native-vector-icons/EvilIcons";
import { TextInput, FlatList } from "react-native-gesture-handler";
import API from "../constant/API";
import ApiClient from "../util/ApiClient";
import * as User from "../util/User";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Switch from "react-switch";
// import CheckBox from '@react-native-community/checkbox';
import moment from "moment";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
import TimeKeeper from "react-timekeeper";
import Styles from "../constant/Styles";
// import {isTablet} from 'react-native-device-detection';
import { OutletStore } from "../store/outletStore";
import { MerchantStore } from "../store/merchantStore";
import { UserStore } from "../store/userStore";
// import Upload from '../assets/svg/Upload';
// import Download from '../assets/svg/Download';
// import RNFetchBlob from 'rn-fetch-blob';
import {
  listenToUserChangesMerchant,
  listenToMerchantIdChangesMerchant,
  listenToCurrOutletIdChangesWaiter,
  listenToAllOutletsChangesMerchant,
  listenToCommonChangesMerchant,
  listenToSelectedOutletItemChanges,
  convertArrayToCSV,
  listenToSelectedOutletTableIdChanges,
  requestNotificationsPermission,
  uploadImageToFirebaseStorage,
} from "../util/common";
import Feather from "react-native-vector-icons/Feather";
// import DropDownPicker from 'react-native-dropdown-picker';
import Select from "react-select";
import Ionicon from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { CommonStore } from "../store/commonStore";
import AsyncImage from "../components/asyncImage";
// import {launchCamera, launchImageLibrary} from 'react-native-image-picker';
// import {GooglePlacesAutocomplete} from 'react-native-google-places-autocomplete';
import Autocomplete from "react-google-autocomplete";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
// import {Card} from 'react-native-paper';
// import {useKeyboard} from '../hooks';
import {
  ORDER_TYPE_PARSED,
  USER_POINTS_TRANSACTION_TYPE,
  ORDER_TYPE,
} from "../constant/common";
// import FusionCharts from 'react-native-fusioncharts';
import {
  CHART_DATA,
  CHART_TYPE,
  FS_LIBRARY_PATH,
  CHART_Y_AXIS_DROPDOWN_LIST,
  CHART_FIELD_COMPARE_DROPDOWN_LIST,
  CHART_FIELD_NAME_DROPDOWN_LIST,
  CHART_FIELD_TYPE,
  CHART_FIELD_COMPARE_DICT,
  CHART_PERIOD,
} from "../constant/chart";
import {
  filterChartItems,
  getDataForChartDashboardTodaySales,
  getDataForSalesLineChart,
} from "../util/chart";
// import Switch from 'react-native-switch-pro';
import { color } from "react-native-reanimated";
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {
  MERCHANT_VOUCHER_CODE_FORMAT,
  MERCHANT_VOUCHER_CODE_FORMAT_UNIQUE,
  MERCHANT_VOUCHER_STATUS,
  MERCHANT_VOUCHER_STATUS_PARSED,
  MERCHANT_VOUCHER_TYPE,
  SEGMENT_TYPE,
  PAYMENT_CHANNEL_NAME_PARSED,
} from "../constant/common";
import { useFilePicker } from "use-file-picker";

////////////////////////////////////

///////////////////////////////////

///////////////////////////////////

const CUSTOMER_DETAILS_SECTION = {
  VISIT_TIMELINE: "CUSTOMER_DETAILS_SECTION.VISIT_TIMELINE",
  PURCHASED_HISTORY: "CUSTOMER_DETAILS_SECTION.PURCHASED_HISTORY",
  QUESTIONAIRE: "CUSTOMER_DETAILS_SECTION.QUESTIONAIRE",
  CREDIT_POINTS: "CUSTOMER_DETAILS_SECTION.CREDIT_POINTS",
  E_INDEMNITY: "CUSTOMER_DETAILS_SECTION.E_INDEMNITY",
  REDEMPTION_HISTORY: "CUSTOMER_DETAILS_SECTION.REDEMPTION_HISTORY",
  VISITATION: "CUSTOMER_DETAILS_SECTION.VISITATION",
  VOUCHER: "CUSTOMER_DETAILS_SECTION.VOUCHER",
  DOCKET: "CUSTOMER_DETAILS_SECTION.DOCKET",
  CASHBACK: 'CUSTOMER_DETAILS_SECTION.CASHBACK',
  LOYALTY_VOUCHER: 'CUSTOMER_DETAILS_SECTION.LOYALTY_VOUCHER'
};

const NewCustomer = (props) => {
  const { navigation } = props;

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  ////////////////////////// UseState Here/////////////////////

  const [showRedemptionAvailability, setShowRedemptionAvailability] =
    useState(false);

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  const [list1, setList1] = useState(true);
  const [customerList, setCustomerList] = useState([]);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState("");

  const [customerDetailsSection, setCustomerDetailsSection] = useState(
    CUSTOMER_DETAILS_SECTION.PURCHASED_HISTORY
  );

  //const allOutlets = MerchantStore.useState(s => s.allOutlets);
  //const currOutletId = MerchantStore.useState(s => s.currOutletId);

  // Try
  const [isActiveMember, setIsActiveMember] = useState(false);

  /////////////////CustomerDetails (TextInput)///////////////////

  // const [customerName, setCustomerName] = useState('')
  // const [contactNumber, setContactNumber] = useState('')
  // const [customerUserName, setCustomerUserName] = useState('')
  // const [customerGender, setCustomerGender] = useState('')

  /////////////////DateTimePicker///////////////////

  const [rev_NextVisitDate, setRev_NextVisitDate] = useState(
    moment(Date.now())
  );
  const [rev_DOBdate, setRev_DOBdate] = useState(moment(Date.now()));
  const [rev_LastVisitDate, setRev_LastVisitDate] = useState(
    moment(Date.now())
  );
  const [rev_FirstVisitDate, setRev_FirstVisitDate] = useState(
    moment(Date.now())
  );
  const [rev_time, setRev_time] = useState(
    moment(Date.now())
  );
  const [nextVisit, setNextVisit] = useState("");
  const [showNextVisitPicker, setShowNextVisitPicker] = useState(false); //For Next Date Visit
  const [showNextVisitTimePicker, setShowNextVisitTimePicker] = useState(false); //For Next Time Visit
  const [DOB, setDOB] = useState("");
  const [showDOBPicker, setShowDOBPicker] = useState(false); //For DOB
  const [firstVisit, setFirstVisit] = useState("");
  const [showFirstVisitPicker, setShowFirstVisitPicker] = useState(false); //For First Visit
  const [lastVisit, setLastVisit] = useState("");
  const [showLastVisitPicker, setShowLastVisitPicker] = useState(false); //For Last Visit

  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerUsername, setCustomerUsername] = useState("");

  const [customerNextVisitDate, setCustomerNextVisitDate] = useState("");
  const [customerNextVisitTime, setCustomerNextVisitTime] = useState("");
  const [customerNextVisitDateIsChanged, setCustomerNextVisitDateIsChanged] =
    useState(false);
  const [customerNextVisitTimeIsChanged, setCustomerNextVisitTimeIsChanged] =
    useState(false);

  const [customerIsActiveMember, setCustomerIsActiveMember] = useState(true);
  const [customerTags, setCustomerTags] = useState([]);
  const [customerGender, setCustomerGender] = useState("Others");
  const [customerDob, setCustomerDob] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerRace, setCustomerRace] = useState("");

  const [customerAddress, setCustomerAddress] = useState("");
  const [customerAddressLat, setCustomerAddressLat] = useState("");
  const [customerAddressLng, setCustomerAddressLng] = useState("");

  const [customerState, setCustomerState] = useState("");
  const [customerPostcode, setCustomerPostcode] = useState("");
  const [customerFirstVisitDate, setCustomerFirstVisitDate] = useState("");
  const [customerLastVisitDate, setCustomerLastVisitDate] = useState("");
  //const [customerPhotos, setCustomerPhotos] = useState([]);

  const [customerPhoneSecond, setCustomerPhoneSecond] = useState("");
  const [customerEmailSecond, setCustomerEmailSecond] = useState("");
  const [customerAddressSecond, setCustomerAddressSecond] = useState("");
  const [customerAddressLatSecond, setCustomerAddressLatSecond] = useState("");
  const [customerAddressLngSecond, setCustomerAddressLngSecond] = useState("");
  const [switchMerchant, setSwitchMerchant] = useState(false);

  const [image, setImage] = useState("");
  const [imageType, setImageType] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [openFileSelector, { plainFiles, filesContent, loading: loadingImageInput, clear: clearImageContainer, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: ["image/*"],
    multiple: false,
  });

  const [editMode, setEditMode] = useState(false);

  const [userTagName, setUserTagName] = useState("");
  const [userTagList, setUserTagList] = useState([]);
  const [userTagDropdownList, setUserTagDropdownList] = useState([]);
  const [selectedUserTagList, setSelectedUserTagList] = useState([]);

  const [searchingUserTagText, setSearchingUserTagText] = useState("");

  const [totalOfVisitFrequency, setTotalOfVisitFrequency] = useState(0);
  const [averageSpending, setAverageSpending] = useState(0);

  ////////////////Modal/////////////////////////////////

  const [tagModal, setTagModal] = useState(false);

  ////////////////Loyalty Points/////////////////////////////////
  const [loyaltyPointsRedemptionPhoto, setLoyaltyPointsRedemptionPhoto] =
    useState("");
  const [pointsMultiplier, setPointsMultiplier] = useState("");
  const [editPointsMultiplier, setEditPointsMultiplier] = useState(false);

  const [loyaltyPointsRedeemed, setLoyaltyPointsRedeemed] = useState(0);
  const [timeOfLoyaltyPointsRedeemed, setTimeOfLoyaltyPointsRedeemed] =
    useState(0);

  ////////////////Redemption History/////////////////////////////////
  const [expandHistory, setExpandHistory] = useState(false);
  const [expandHistoryDict, setExpandHistoryDict] = useState({});

  ////////////////Purchase History/////////////////////////////////
  const [phTotalPayment, setPhTotalPayment] = useState(0);
  const [phOutstandingPayment, setPhOutstandingPayment] = useState(0);
  const [timeOfPhTotalPayment, setTimeOfPhTotalPayment] = useState(0);

  const [expandPhListDict, setExpandPhListDict] = useState({});

  const [availablePointsRedeemPackages, setAvailablePointsRedeemPackages] =
    useState([]);

  const [expandRhListDict, setExpandRhListDict] = useState({});

  ////////////////Voucher/////////////////////////////////
  const [voucherOnHand, setVoucherOnHand] = useState(0);
  const [voucherRedeemed, setVoucherRedeemed] = useState(0);
  const [voucherActive, setVoucherActive] = useState(0);
  const [voucherExpired, setVoucherExpired] = useState(0);

  ////////////////Docket/////////////////////////////////

  const beerDocketsDict = OutletStore.useState((s) => s.beerDocketsDict);
  const userBeerDockets = OutletStore.useState((s) => s.userBeerDockets);
  const userOrderBeerDocketUBDIdDict = OutletStore.useState(
    (s) => s.userOrderBeerDocketUBDIdDict
  );
  const allOutletsItemsSkuDict = OutletStore.useState(
    (s) => s.allOutletsItemsSkuDict
  );
  const allOutletsCategoriesDict = OutletStore.useState(
    (s) => s.allOutletsCategoriesDict
  );

  ///////////////Visitation////////////////////////////////

  const [totalVisit, setTotalVisit] = useState(0);
  const [lastDateTotalVisit, setLastDateTotalVisit] = useState(0);

  /////////////////////////////////////////////////////////////////////

  /////////////////////////Promotion/////////////////////////////

  const [totalPromotion, setTotalPromotion] = useState(0);
  const [ytdPromotion, setYtdPromotion] = useState(0);
  const [past3MonthsPromotion, setPast3MonthsPromotion] = useState(0);
  const [past6MonthsPromotion, setPast6MonthsPromotion] = useState(0);
  
  ///////////////////////Cashback/////////////////////////////////

  const [cashback, setCashback] = useState(0);
  const [cashbackUsed, setCashbackUsed] = useState(0);
  const [cashbackNumbers, setCashbackNumbers] = useState(0);

  ////////////////////////////////////////////////////////////////
  
  const [customerVoucherRedemptions, setCustomerVoucherRedemptions] = useState(
    []
  );
  const [overviewVoucherActive, setOverviewVoucherActive] = useState(0);
  const [overviewVoucherRedeemed, setOverviewVoucherRedeemed] = useState(0);
  const [overviewVoucherUsed, setOverviewVoucherUsed] = useState(0);
  const [overviewVoucherExpired, setOverviewVoucherExpired] = useState(0);

  /////////////////////////////////////////////////////////////////////

  const [customerBeerDockets, setCustomerBeerDockets] = useState([]);
  const [overviewBeerDocketActive, setOverviewBeerDocketActive] = useState(0);
  const [overviewBeerDocketRedeemed, setOverviewBeerDocketRedeemed] =
    useState(0);
  const [overviewBeerDocketUsed, setOverviewBeerDocketUsed] = useState(0);
  const [overviewBeerDocketExpired, setOverviewBeerDocketExpired] = useState(0);

  /////////////////////////////////////////////////////////////////////
  const userOrders = OutletStore.useState(s => s.userOrders);

  const merchantId = UserStore.useState((s) => s.merchantId);

  const merchantLogo = MerchantStore.useState((s) => s.logo);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const currOutlet = MerchantStore.useState((s) => s.currOutlet);

  const selectedCustomerOrders = OutletStore.useState(
    (s) => s.selectedCustomerOrders
  );
  const selectedCustomerDineInOrders = OutletStore.useState(
    (s) => s.selectedCustomerDineInOrders
  );
  const selectedCustomerAddresses = OutletStore.useState(
    (s) => s.selectedCustomerAddresses
  );
  const selectedCustomerPointsTransactions = OutletStore.useState(
    (s) => s.selectedCustomerPointsTransactions
  );
  const selectedCustomerPointsBalance = OutletStore.useState(
    (s) => s.selectedCustomerPointsBalance
  );
  const selectedCustomerVoucherRedemptions = OutletStore.useState(
    (s) => s.selectedCustomerVoucherRedemptions
  );
  const selectedCustomerUserBeerDockets = OutletStore.useState(
    (s) => s.selectedCustomerUserBeerDockets
  );

  const selectedCustomerReservations = OutletStore.useState(
    (s) => s.selectedCustomerReservations
  );
  const selectedCustomerLCCTransactions = OutletStore.useState(
    (s) => s.selectedCustomerLCCTransactions,
  );
  const selectedCustomerLCCBalance = OutletStore.useState(
    (s) => s.selectedCustomerLCCBalance,
  );

  // const voucherIdRedemptionList = CommonStore.useState(s => s.voucherIdRedemptionList);
  // const merchantVouchers = CommonStore.useState(s => s.merchantVouchers);

  const outletTablesDict = OutletStore.useState((s) => s.outletTablesDict);

  const crmUserTags = OutletStore.useState((s) => s.crmUserTags);
  const crmUserTagsDict = OutletStore.useState((s) => s.crmUserTagsDict);

  const pointsRedeemPackages = OutletStore.useState(
    (s) => s.pointsRedeemPackages
  );
  const pointsRedeemPackagesDict = OutletStore.useState(
    (s) => s.pointsRedeemPackagesDict
  );
  const userReservations = OutletStore.useState((s) => s.userReservations);

  const selectedCustomerEdit = CommonStore.useState(
    (s) => s.selectedCustomerEdit
  );

  const isLoading = CommonStore.useState((s) => s.isLoading);

  const pageStack = CommonStore.useState((s) => s.pageStack);

  // const [keyboardHeight] = useKeyboard();

  //const [crmBarChart, setCrmBarChart] = useState({ });

  const promotions = OutletStore.useState((s) => s.promotions);
  const merchantVouchers = CommonStore.useState((s) => s.merchantVouchers);
  const merchantVouchersDict = CommonStore.useState(
    (s) => s.merchantVouchersDict
  );

  const genderOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ];

  ////////////////////////// UseEffect Here/////////////////////

  // select image
  useEffect(() => {
    if (plainFiles.length && filesContent.length && !loadingImageInput) {
      setImage(filesContent[0].content);
      setImageType(filesContent[0].name.slice(filesContent[0].name.lastIndexOf('.')));
      setIsImageChanged(true);
    }

    if (errors.length)
      console.error(errors)

  }, [plainFiles, filesContent, loadingImageInput, errors])

  useEffect(() => {
    console.log("================================");
    console.log("selectedCustomerEdit");
    console.log(selectedCustomerEdit);

    if (selectedCustomerEdit) {
      // insert info

      setEditMode(false);

      setImage(selectedCustomerEdit.avatar);
      setCustomerName(selectedCustomerEdit.name);
      setCustomerPhone(selectedCustomerEdit.number);
      setCustomerUsername(selectedCustomerEdit.uniqueName);
      // setCustomerNextVisitDate('');
      // setCustomerNextVisitTime('');
      setCustomerNextVisitDateIsChanged(false);
      setCustomerNextVisitTimeIsChanged(false);
      setCustomerIsActiveMember(
        moment(selectedCustomerEdit).diff(moment(), "day") <= 7
      );
      setCustomerTags(selectedCustomerEdit.tags || []);
      setCustomerGender(selectedCustomerEdit.gender || "Others");
      setCustomerDob(selectedCustomerEdit.dob);
      setCustomerEmail(selectedCustomerEdit.email);
      //setCustomerRace(selectedCustomerEdit.race);
      setIsImageChanged(false);

      if (
        selectedCustomerEdit.firebaseUid &&
        selectedCustomerAddresses.length > 0
      ) {
        setCustomerAddress(selectedCustomerAddresses[0].address);
        setCustomerAddressLat(selectedCustomerAddresses[0].lat);
        setCustomerAddressLng(selectedCustomerAddresses[0].lng);
      } else {
        setCustomerAddress(selectedCustomerEdit.address);
        setCustomerAddressLat(selectedCustomerEdit.lat);
        setCustomerAddressLng(selectedCustomerEdit.lng);
      }

      setCustomerState("");
      setCustomerPostcode("");

      if (selectedCustomerDineInOrders.length > 0) {
        setCustomerFirstVisitDate(
          selectedCustomerDineInOrders[selectedCustomerDineInOrders.length - 1]
            .orderDate
        );
        setCustomerLastVisitDate(selectedCustomerDineInOrders[0].orderDate);
      }
      //setCustomerPhotos([]);

      var userTagListTemp = [];
      var selectedUserTagListTemp = [];

      if (crmUserTags.length > 0) {
        for (var i = 0; i < crmUserTags.length; i++) {
          if (
            crmUserTags[i].tokenFcmList.includes(
              selectedCustomerEdit.tokenFcm
            ) ||
            crmUserTags[i].emailList.includes(selectedCustomerEdit.email) ||
            crmUserTags[i].phoneList.includes(selectedCustomerEdit.number)
          ) {
            userTagListTemp.push(crmUserTags[i]);
            // selectedUserTagListTemp.push(crmUserTags[i].uniqueId);
          }
        }
      }

      setUserTagList(userTagListTemp);
      setSelectedUserTagList(selectedUserTagListTemp);

      setCustomerPhoneSecond(
        selectedCustomerEdit.numberSecond
          ? selectedCustomerEdit.numberSecond
          : ""
      );
      setCustomerEmailSecond(
        selectedCustomerEdit.emailSecond ? selectedCustomerEdit.emailSecond : ""
      );
      setCustomerAddressSecond(
        selectedCustomerEdit.addressSecond
          ? selectedCustomerEdit.addressSecond
          : ""
      );
      setCustomerAddressLatSecond(
        selectedCustomerEdit.latSecond ? selectedCustomerEdit.latSecond : ""
      );
      setCustomerAddressLngSecond(
        selectedCustomerEdit.lngSecond ? selectedCustomerEdit.lngSecond : ""
      );
    } else {
      // designed to always mounted, thus need clear manually...

      setEditMode(false);

      setImage("");
      clearImageContainer();
      setIsImageChanged(false);
      setCustomerName("");
      setCustomerPhone("");
      setCustomerUsername("");
      setCustomerNextVisitDate("");
      setCustomerNextVisitTime("");
      setCustomerNextVisitDateIsChanged(false);
      setCustomerNextVisitTimeIsChanged(false);
      setCustomerIsActiveMember(true);
      setCustomerTags([]);
      setCustomerGender("Others");
      setCustomerDob("");
      setCustomerEmail("");
      setCustomerAddress("");
      setCustomerState("");
      setCustomerPostcode("");
      setCustomerFirstVisitDate("");
      setCustomerLastVisitDate("");
      //setcustomerPhotos([]);
      setCustomerRace("");

      setUserTagList([]);
      setSelectedUserTagList([]);

      setCustomerPhoneSecond("");
      setCustomerEmailSecond("");
      setCustomerAddressSecond("");
      setCustomerAddressLatSecond("");
      setCustomerAddressLngSecond("");

      
      setPhTotalPayment(0);
      setPhOutstandingPayment(0);
      setTimeOfPhTotalPayment(0);

      setOverviewBeerDocketExpired(0);
      setOverviewBeerDocketUsed(0);
      setOverviewBeerDocketRedeemed(0);
      setOverviewBeerDocketActive(0);

      setTotalPromotion(0);
      setYtdPromotion(0);
      setPast3MonthsPromotion(0);
      setPast6MonthsPromotion(0);

      setOverviewVoucherActive(0);
      setOverviewVoucherRedeemed(0);
      setOverviewVoucherUsed(0);
      setOverviewVoucherExpired(0);

      setCustomerLastVisitDate('');
      setLastDateTotalVisit(0);
      setTotalVisit(0);

      setCashback(0);
      setCashbackUsed(0);
      setCashbackNumbers(0);

      setLoyaltyPointsRedeemed(0);
      setTimeOfLoyaltyPointsRedeemed(0);
    }
  }, [
    selectedCustomerEdit,
    selectedCustomerAddresses,
    selectedCustomerDineInOrders,

    crmUserTags,
  ]);

  useEffect(() => {
    var availablePointsRedeemPackagesTemp = [];

    var userGroups =
      selectedCustomerEdit && selectedCustomerEdit.userGroups
        ? selectedCustomerEdit.userGroups
        : [];

    for (var i = 0; i < pointsRedeemPackages.length; i++) {
      var isValid = false;

      if (userGroups.includes(pointsRedeemPackages[i].targetUserGroup)) {
        isValid = true;
      }

      if (selectedCustomerEdit) {
        if (crmUserTagsDict[pointsRedeemPackages[i].targetUserGroup]) {
          const currCrmUserTag =
            crmUserTagsDict[pointsRedeemPackages[i].targetUserGroup];

          if (currCrmUserTag.emailList.includes(selectedCustomerEdit.email)) {
            // means got

            isValid = true;
          }
        }
      }

      if (isValid) {
        availablePointsRedeemPackagesTemp.push(pointsRedeemPackages[i]);
      }
    }

    setAvailablePointsRedeemPackages(availablePointsRedeemPackagesTemp);
  }, [pointsRedeemPackages, selectedCustomerEdit, crmUserTagsDict]);

  useEffect(() => {
    if (crmUserTags.length > 0) {
      setUserTagDropdownList(
        crmUserTags
          .filter((item) => {
            var isExisted = false;

            for (var i = 0; i < userTagList.length; i++) {
              if (userTagList[i].uniqueId === item.uniqueId) {
                isExisted = true;
                break;
              }
            }

            return !isExisted;
          })
          .map((item) => {
            return { label: item.name, value: item.uniqueId };
          })
      );

      // if (selectedTax === '') {
      //   setSelectedTax(currOutletTaxes[0].uniqueId);

      //   for (var i = 0; i < currOutletTaxes.length; i++) {
      //     if (currOutletTaxes[i].uniqueId === currOutletTaxes[0].uniqueId) {
      //       setSelectedTaxName(currOutletTaxes[i].name);
      //       setSelectedTaxRate(currOutletTaxes[i].rate);
      //       break;
      //     }
      //   }
      // }
    }
  }, [crmUserTags, userTagList]);

  useEffect(() => {
    if (selectedCustomerEdit !== null) {
      var reservationsTemp = [];

      for (var i = 0; i < userReservations.length; i++) {
        if (userReservations[i].userEmail === selectedCustomerEdit.email) {
          reservationsTemp.push(userReservations[i]);
        }
      }

      reservationsTemp.sort((a, b) => b.reservationTime - a.reservationTime);

      OutletStore.update((s) => {
        s.selectedCustomerReservations = reservationsTemp;
      });
    }
  }, [selectedCustomerEdit, userReservations]);

  useEffect(() => {
    var customerNextVisitDateTemp = "";
    var customerNextVisitTimeTemp = "";

    for (var i = 0; i < selectedCustomerReservations.length; i++) {
      if (
        moment(selectedCustomerReservations[i].reservationTime).isAfter(
          moment()
        )
      ) {
        customerNextVisitDateTemp =
          selectedCustomerReservations[i].reservationTime;
        customerNextVisitTimeTemp =
          selectedCustomerReservations[i].reservationTime;
        break;
      }
    }

    setCustomerNextVisitDate(customerNextVisitDateTemp);
    setCustomerNextVisitTime(customerNextVisitTimeTemp);
  }, [selectedCustomerReservations]);

  useEffect(() => {
    var customerVoucherRedemptionsTemp = [];
    var overviewVoucherActiveTemp = 0;
    var overviewVoucherRedeemedTemp = 0;
    var overviewVoucherUsedTemp = 0;
    var overviewVoucherExpiredTemp = 0;

      if (Object.keys(merchantVouchersDict).length > 0) {
      for (var i = 0; i < selectedCustomerVoucherRedemptions.length; i++) {
        if (
          merchantVouchersDict[selectedCustomerVoucherRedemptions[i].voucherId]
        ) {
          const merchantVoucher =
            merchantVouchersDict[
            selectedCustomerVoucherRedemptions[i].voucherId
            ];

          customerVoucherRedemptionsTemp.push(
            selectedCustomerVoucherRedemptions[i],
          );

          if (
            selectedCustomerVoucherRedemptions[i].redeemDate &&
            selectedCustomerVoucherRedemptions[i].usageDate === null
          ) {
            if (moment().isAfter(merchantVoucher.endDate)) {
              overviewVoucherExpiredTemp += 1;
            } else {
              overviewVoucherActiveTemp += 1;
              overviewVoucherRedeemedTemp += 1;
            }
          } else if (
            selectedCustomerVoucherRedemptions[i].redeemDate &&
            selectedCustomerVoucherRedemptions[i].usageDate
          ) {
            overviewVoucherUsedTemp += 1;
          }
        }
      }
    }
  

    setCustomerVoucherRedemptions(customerVoucherRedemptionsTemp);
    setOverviewVoucherActive(overviewVoucherActiveTemp);
    setOverviewVoucherRedeemed(overviewVoucherRedeemedTemp);
    setOverviewVoucherUsed(overviewVoucherUsedTemp);
    setOverviewVoucherExpired(overviewVoucherExpiredTemp);
  }, [
    selectedCustomerEdit,
    selectedCustomerVoucherRedemptions,
    merchantVouchersDict,
  ]);

  useEffect(() => {
    var customerBeerDocketsTemp = [];
    var overviewBeerDocketActiveTemp = 0;
    var overviewBeerDocketRedeemedTemp = 0;
    var overviewBeerDocketUsedTemp = 0;
    var overviewBeerDocketExpiredTemp = 0;

      if (Object.keys(beerDocketsDict).length > 0) {
        for (var i = 0; i < selectedCustomerUserBeerDockets.length; i++) {
          if (beerDocketsDict[selectedCustomerUserBeerDockets[i].beerDocketId]) {
            const beerDocket =
              beerDocketsDict[selectedCustomerUserBeerDockets[i].beerDocketId];

            customerBeerDocketsTemp.push(selectedCustomerUserBeerDockets[i]);

            var isRedeemed = true;

            for (
              var j = 0;
              j < selectedCustomerUserBeerDockets[i].bdItems.length;
              j++
            ) {
              if (selectedCustomerUserBeerDockets[i].bdItems[j].quantity > 0) {
                isRedeemed = false;
                break;
              }
            }

            if (!isRedeemed) {
              if (
                moment().isAfter(selectedCustomerUserBeerDockets[i].expiryDate)
              ) {
                overviewBeerDocketExpiredTemp += 1;
              } else {
                overviewBeerDocketActiveTemp += 1;
              }
            } else {
              overviewBeerDocketRedeemedTemp += 1;
              overviewBeerDocketUsedTemp += 1;
            }
          }
        }
      }
    

    setCustomerBeerDockets(customerBeerDocketsTemp);
    setOverviewBeerDocketActive(overviewBeerDocketActiveTemp);
    setOverviewBeerDocketRedeemed(overviewBeerDocketRedeemedTemp);
    setOverviewBeerDocketUsed(overviewBeerDocketUsedTemp);
    setOverviewBeerDocketExpired(overviewBeerDocketExpiredTemp);
  }, [selectedCustomerEdit, selectedCustomerUserBeerDockets, beerDocketsDict]);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  //////////////////////////////////////////////////////////////
  // purchase history

  useEffect(() => {
    var phTotalPaymentTemp = 0;
    var phOutstandingPaymentTemp = 0;
    var timeOfPhTotalPayment = 0;

      for (var i = 0; i < selectedCustomerOrders.length; i++) {
        if (selectedCustomerOrders[i].completedDate) {
          phTotalPaymentTemp += selectedCustomerOrders[i].finalPrice;
        } else {
          phOutstandingPaymentTemp += selectedCustomerOrders[i].finalPrice;
        }
      }

      for (var i = 0; i < selectedCustomerOrders.length; i++) {
        if (selectedCustomerOrders[i].completedDate) {
          timeOfPhTotalPayment += 1;
        } else {
          timeOfPhTotalPayment -= 1;
        }
      }
    

    setPhTotalPayment(phTotalPaymentTemp);
    setPhOutstandingPayment(phOutstandingPaymentTemp);
    setTimeOfPhTotalPayment(timeOfPhTotalPayment);
  }, [selectedCustomerOrders, selectedCustomerEdit]);

  //Visits Frequency
  useEffect(() => {
    var totalOfVisitFrequency = 0;
    var averageSpendingTemp = 0;

      for (var i = 0; i < selectedCustomerDineInOrders.length; i++) {
        if (selectedCustomerDineInOrders[i].createdAt) {
          totalOfVisitFrequency += 1;
        }
      }

      for (var i = 0; i < selectedCustomerDineInOrders.length; i++) {
        if (selectedCustomerDineInOrders[i].completedDate) {
          averageSpendingTemp += selectedCustomerDineInOrders[i].finalPrice;
        }
      }
    

    setTotalOfVisitFrequency(totalOfVisitFrequency);
    setAverageSpending(averageSpendingTemp);
  }, [selectedCustomerDineInOrders, selectedCustomerEdit]);

  useEffect(() => {
    var totalLoyaltyPointsRedeemed = 0;
    var timesLoyaltyPointsRedeemed = 0;

      for (var i = 0; i < selectedCustomerPointsTransactions.length; i++) {
        if (
          selectedCustomerPointsTransactions[i].transactionType ===
          USER_POINTS_TRANSACTION_TYPE.REDEEM
        ) {
          totalLoyaltyPointsRedeemed +=
            selectedCustomerPointsTransactions[i].amount;
        }
      }

      for (var i = 0; i < selectedCustomerPointsTransactions.length; i++) {
        if (
          selectedCustomerPointsTransactions[i].transactionType ===
          USER_POINTS_TRANSACTION_TYPE.REDEEM
        ) {
          timesLoyaltyPointsRedeemed = 1;
        }
      }
    

    setLoyaltyPointsRedeemed(totalLoyaltyPointsRedeemed);
    setTimeOfLoyaltyPointsRedeemed(timesLoyaltyPointsRedeemed);
  }, [selectedCustomerPointsTransactions, selectedCustomerEdit]);

  // useEffect(() => {
  //   if (selectedCustomerEdit !== null) {
  //   // var totalVoucherOnHand = 0;
  //   // var redeemedVoucher = 0;
  //   // var activeVoucher = 0;
  //   // var expiredVoucher = 0;
  //   var voucherIdRedemptionListTemp = [];

  //     for (var i = 0; i < voucherIdRedemptionList.length; i++) {
  //       if (voucherIdRedemptionList[i].userId === selectedCustomerEdit.userId) {
  //         voucherIdRedemptionListTemp.push(voucherIdRedemptionList[i]);
  //       }
  //     }

  //     CommonStore.update(s => {
  //       s.voucherIdRedemptionList = voucherIdRedemptionListTemp;
  //     });
  //   }
  // }, [selectedCustomerEdit, voucherIdRedemptionList])

  // useEffect(() => {
  //   var totalVoucherOnHand = 0;
  //   var redeemedVoucher = 0;
  //   var activeVoucher = 0;
  //   var expiredVoucher = 0;

  //     for (var i = 0; i < voucherIdRedemptionList.length; i++) {
  //       console.log('Hello123')
  //       if (voucherIdRedemptionList[i].userId === selectedCustomerEdit.userId) {
  //         totalVoucherOnHand += 1;
  //       }
  //     }

  //   setVoucherOnHand(totalVoucherOnHand);

  // }, [selectedCustomerEdit, voucherIdRedemptionList, merchantVouchers])

  //Visitation
  useEffect(() => {
    var totalVisit = 0;
    var lastDateTotalVisit = 0;

      for (var i = 0; i < selectedCustomerDineInOrders.length; i++) {
        if (selectedCustomerDineInOrders[i].createdAt) {
          totalVisit += 1;
        }
      }

      for (var i = 0; i < selectedCustomerDineInOrders.length; i++) {
        if (
          moment(selectedCustomerDineInOrders[i].completedDate).isAfter(
            moment(customerLastVisitDate).subtract(30, 'day'),
          )
        ) {
          lastDateTotalVisit += 1;
        }
      }
    

    setTotalVisit(totalVisit);
    setLastDateTotalVisit(lastDateTotalVisit);
  }, [selectedCustomerDineInOrders, selectedCustomerEdit]);

  //////////////////////////////////////////////////////////////
/////////////////////////Promotion/////////////////////////////////
useEffect(() => {
  var tempTotalPromotion = 0;
  var tempYtdPromotion = 0;
  var tempPast3MonthsPromotion = 0;
  var tempPast6MonthsPromotion = 0;

  var yesterday = new Date(new Date().setDate(new Date().getDate() - 1))
  var past3mths = new Date(new Date().setMonth(new Date().getMonth() - 3))
  var past6mths = new Date(new Date().setMonth(new Date().getMonth() - 6))

  var tempPromotionIdList = []

  if(selectedCustomerEdit){
    for (var j=0; j<userOrders.length; j++){
      // If user order have same name and phone with selected customer
      if(selectedCustomerEdit.name === userOrders[j].userName && selectedCustomerEdit.number === userOrders[j].userPhone){
        // If there is promotion id list
        if(userOrders[j].promotionIdList){
          tempPromotionIdList = userOrders[j].promotionIdList
        }
      }
    }

    // Check the promotion exist for the customer
    for (var i=0; i<promotions.length; i++){
      for(var j=0; j<tempPromotionIdList.length; j++){
        if(promotions[i].uniqueId === tempPromotionIdList[j]){
          tempTotalPromotion += 1
          // Check promotion end date is past 6 months
          if(moment(promotions[0].promoDateEnd)._d > past6mths){
            tempPast6MonthsPromotion += 1
          }
          // Check promotion end date is past 3 months
          else if(moment(promotions[0].promoDateEnd)._d > past3mths){
            tempPast3MonthsPromotion += 1
          }
          // Check promotion end date is yesterday
          else if(moment(moment(promotions[0].promoDateEnd)._d.setDate(moment(promotions[0].promoDateEnd)._d.getDate() - 1)) === yesterday){
            tempYtdPromotion += 1
          }
        }
      }
    }
  }

  setTotalPromotion(tempTotalPromotion)
  setYtdPromotion(tempYtdPromotion)
  setPast3MonthsPromotion(tempPast3MonthsPromotion)
  setPast6MonthsPromotion(tempPast6MonthsPromotion)

}, [promotions, userOrders, selectedCustomerEdit]);

/////////////////////////cashback///////////////////////////
useEffect(() => {
  var tempCashback = selectedCustomerLCCBalance
  var tempCashbackUsed = 0
  var tempCashbackNumbers = 0

  for (var i=0; i<selectedCustomerLCCTransactions.length; i++){
    if (selectedCustomerLCCTransactions[i].transactionType === 'DEDUCT'){
      tempCashbackUsed += selectedCustomerLCCTransactions[i].amount
      tempCashbackNumbers += 1
    }
  }

  setCashback(tempCashback)
  setCashbackNumbers(tempCashbackNumbers)
  setCashbackUsed(tempCashbackUsed)

}, [selectedCustomerLCCBalance, selectedCustomerLCCTransactions])

////////////////////////////////////////////////////////////

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View
        style={{
          width: windowWidth * 0.17,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            bottom: switchMerchant ? "2%" : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? { right: windowWidth * 0.1 }
            : {},
          windowWidth <= 768 ? { right: windowWidth * 0.12 } : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          CRM
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: switchMerchant ? 10 : 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  //////////////////Function here/////////////

  // const renderVisitTimeLine = ({ item, index }) => (
  //   <View style={{ flexDirection: 'row', width: '100%' }}>
  //     <View style={{ width: '7%', alignItems: 'center' }}>
  //       <View style={{ backgroundColor: Colors.primaryColor, width: 22, height: 22, borderRadius: 100 }} />
  //       <View style={{ backgroundColor: '#E5F1E4', width: 3, height: 190 }} />
  //     </View>
  //     <View style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: '93%', height: 170 }}>

  //     </View>
  //   </View>
  // )


  // const handleChoosePhoto = () => {
  //   const imagePickerOptions = {
  //     mediaType: 'photo',
  //     quality: 0.5,
  //     includeBase64: false,
  //   };

  //   launchImageLibrary(imagePickerOptions, (response) => {
  //     if (response.didCancel) {
  //     } else if (response.error) {
  //       Alert.alert(response.error.toString());
  //     } else {
  //       // setState({ image: response.uri });
  //       setImage(response.uri);
  //       setImageType(response.uri.slice(response.uri.lastIndexOf('.')));

  //       setIsImageChanged(true);
  //     }
  //   });
  // };

  const createCRMUser = async (isAutoPush = false) => {
    if (
      !image ||
      // !customerName ||
      !customerPhone ||
      !customerUsername ||
      !customerEmail ||
      !customerAddress
    ) {
      console.log('customerAvatar: ', image);
      console.log('customerName: ', customerName);
      console.log('customerPhone: ', customerPhone);
      console.log('customerUsername: ', customerUsername);
      console.log('customerEmail: ', customerEmail);
      console.log('customerAddress: ', customerAddress);
      if (window.confirm("Error! Please fill in all required information:\nProfile image\nName\nContact number\nUsername\nEmail\nAddress") == true) {
        console.log("You have pressed ok!");
      } else {
        console.log("You have cancelled!");
      }
      return;
    } else {
      ///////////////////////////////////
      if (selectedCustomerEdit === null) {
        ///////////////////////////////////
        // upload image

        var profileImageImagePath = "";
        var profileImageCommonIdLocal = uuidv4();

        if (image && imageType) {
          // promotionCommonIdLocal = uuidv4();

          profileImageImagePath = await uploadImageToFirebaseStorage(
            {
              uri: image,
              type: imageType,
            },
            `/merchant/${merchantId}/crm/user/${profileImageCommonIdLocal}/image${imageType}`
          );
        }

        // means new item

        var body = {
          merchantId: merchantId,
          merchantName: merchantName,
          outletId: currOutletId,

          avatar: profileImageImagePath,
          isImageChanged: isImageChanged,
          dob: customerDob,
          email: customerEmail,
          gender: customerGender,
          name: customerName,
          number: customerPhone,
          uniqueName: customerUsername,

          address: customerAddress,
          lat: parseFloat(customerAddressLat),
          lng: parseFloat(customerAddressLng),

          emailSecond: customerEmailSecond,
          numberSecond: customerPhoneSecond,
          addressSecond: customerAddressSecond,
          latSecond: parseFloat(customerAddressLatSecond),
          lngSecond: parseFloat(customerAddressLngSecond),

          timeline: {},

          commonId: profileImageCommonIdLocal,
        };

        console.log(body);

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ApiClient.POST(API.createCRMUser, body, false).then((result) => {
          if (result && result.status === "success") {
            createCRMUserReservation(result.crmUser);

            if (window.confirm("Success! Customer has been added") == true) {
              console.log("You have pressed ok!");
              navigation.goBack();
            } else {
              console.log("You have cancelled!");
            }
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        });
      } else if (selectedCustomerEdit !== null) {
        // means existing item

        ///////////////////////////////////
        // upload image

        var profileImageImagePath = "";
        var profileImageCommonIdLocal = selectedCustomerEdit.commonId;

        if (
          image &&
          imageType &&
          selectedCustomerEdit.firebaseUid === undefined
        ) {
          // promotionCommonIdLocal = uuidv4();

          profileImageImagePath = await uploadImageToFirebaseStorage(
            {
              uri: image,
              type: imageType,
            },
            `/merchant/${merchantId}/crm/user/${profileImageCommonIdLocal}/image${imageType}`
          );
        }

        var body = {
          crmUserId: selectedCustomerEdit.uniqueId,
          firebaseUid: selectedCustomerEdit.firebaseUid,

          merchantId: merchantId,
          merchantName: merchantName,
          outletId: currOutletId,

          avatar: profileImageImagePath,
          isImageChanged: isImageChanged,
          dob: customerDob,
          email: customerEmail,
          gender: customerGender,
          name: customerName,
          number: customerPhone,
          uniqueName: customerUsername,

          address: customerAddress,
          lat: parseFloat(customerAddressLat),
          lng: parseFloat(customerAddressLng),

          emailSecond: customerEmailSecond,
          numberSecond: customerPhoneSecond,
          addressSecond: customerAddressSecond,
          latSecond: parseFloat(customerAddressLatSecond),
          lngSecond: parseFloat(customerAddressLngSecond),

          timeline: selectedCustomerEdit.timeline || {},

          commonId: profileImageCommonIdLocal,
        };

        console.log(body);

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ApiClient.POST(API.updateCRMUser, body, false).then((result) => {
          if (result && result.status === "success") {
            createCRMUserReservation(selectedCustomerEdit);

            Alert.alert(
              "Success",
              "Customer has been updated",
              [
                {
                  text: "OK",
                  onPress: () => {
                    navigation.goBack();
                  },
                },
              ],
              { cancelable: false }
            );
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        });
      }
    }
  };

  const deleteCRMUser = async () => {
    var body = {
      crmUserId: selectedCustomerEdit.uniqueId,
      merchantId: merchantId,
    };

    console.log(body);

    CommonStore.update((s) => {
      s.isLoading = true;
    });

    ApiClient.POST(API.deleteCRMUser, body, false).then((result) => {
      if (result && result.status === "success") {
        Alert.alert(
          "Success",
          "Customer has been deleted",
          [
            {
              text: "OK",
              onPress: () => {
                navigation.goBack();
              },
            },
          ],
          { cancelable: false }
        );
      }

      CommonStore.update((s) => {
        s.isLoading = false;
      });
    });
  };

  const createCRMUserReservation = async (crmUser) => {
    if (customerNextVisitDateIsChanged && customerNextVisitTimeIsChanged) {
      // const reservationTime = moment(
      //   `${moment(customerNextVisitDate).format("DD/MM/YYYY")} ${moment(
      //     customerNextVisitTime
      //   ).format("hh.mmA")}`,
      //   "DD/MM/YYYY hh.mmA"
      // );
      const reservationTime = moment(
        `${moment(customerNextVisitDate).format("DD/MM/YYYY")} ${customerNextVisitTime}`,
        "DD/MM/YYYY hh.mmA"
      );

      var body = {
        outletId: currOutletId,
        pax: 1,
        reservationTime: moment(reservationTime).valueOf(),

        merchantId: merchantId,
        outletCover: currOutlet.cover,
        merchantLogo: merchantLogo,
        outletName: currOutlet.name,
        merchantName: merchantName,

        userName: customerName,
        userPhone: customerPhone,
        userEmail: customerEmail,

        crmUserId: crmUser.uniqueId,
      };

      console.log(body);

      ApiClient.POST(API.createUserReservation, body, false).then((result) => {
        // if (result && result.status === 'success') {
        //   Alert.alert(
        //     'Success',
        //     "Customer added.",
        //     [
        //       {
        //         text: 'OK',
        //         onPress: () => {
        //           navigation.goBack();
        //         },
        //       },
        //     ],
        //     { cancelable: false },
        //   );
        // }
      });
    }
  };

  const createCRMUserTag = async () => {
    if (!userTagName) {
      Alert.alert(
        "Info",
        "Tag name cannot be empty",
        [
          {
            text: "OK",
            onPress: () => {
              navigation.goBack();
            },
          },
        ],
        { cancelable: false }
      );
    } else {
      var isExisted = false;

      for (var i = 0; i < crmUserTags.length; i++) {
        if (crmUserTags[i].name === userTagName) {
          isExisted = true;
          break;
        }
      }

      if (isExisted) {
        Alert.alert(
          "Info",
          "Tag has existed",
          [
            {
              text: "OK",
              onPress: () => {
                navigation.goBack();
              },
            },
          ],
          { cancelable: false }
        );
      } else {
        var body = {
          merchantId: merchantId,
          tagName: userTagName,
        };

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ApiClient.POST(API.createCRMUserTag, body, false).then((result) => {
          if (result && result.status === "success") {
            Alert.alert(
              "Success",
              "Tag has been created",
              [
                {
                  text: "OK",
                  onPress: () => {
                    // navigation.goBack();
                  },
                },
              ],
              { cancelable: false }
            );
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        });
      }
    }
  };

  const addUserToCRMUserTag = async () => {
    if (selectedUserTagList.length === 0) {
      Alert.alert(
        "Info",
        "Please select at least one tag to be added",
        [
          {
            text: "OK",
            onPress: () => {
              navigation.goBack();
            },
          },
        ],
        { cancelable: false }
      );
    } else {
      var body = {
        crmUserTagIdList: selectedUserTagList,
        merchantId: merchantId,

        tokenFcm: selectedCustomerEdit.tokenFcm || "",
        email: selectedCustomerEdit.email || "",
        phone: selectedCustomerEdit.number || "",
      };

      CommonStore.update((s) => {
        s.isLoading = true;
      });

      ApiClient.POST(API.addUserToCRMUserTag, body, false).then((result) => {
        if (result && result.status === "success") {
          Alert.alert(
            "Success",
            "Tag(s) has been added to user",
            [
              {
                text: "OK",
                onPress: () => {
                  // navigation.goBack();
                },
              },
            ],
            { cancelable: false }
          );
        }

        CommonStore.update((s) => {
          s.isLoading = false;
        });
      });
    }
  };

  const createCRMUserTagOrAddUserToCRMUserTag = async () => {
    var body = {
      crmUserTagIdList: selectedUserTagList,
      merchantId: merchantId,

      tokenFcm: selectedCustomerEdit.tokenFcm || "",
      email: selectedCustomerEdit.email || "",
      phone: selectedCustomerEdit.number || "",

      searchingUserTagText: searchingUserTagText,
    };

    // clear searching text after submit
    setSearchingUserTagText("");

    CommonStore.update((s) => {
      s.isLoading = true;
    });

    ApiClient.POST(API.createCRMUserTagOrAddUserToCRMUserTag, body, false).then(
      (result) => {
        if (result && result.status === "success") {
          Alert.alert(
            "Success",
            "Tag(s) has been added to user",
            [
              {
                text: "OK",
                onPress: () => {
                  // navigation.goBack();
                },
              },
            ],
            { cancelable: false }
          );
        }

        CommonStore.update((s) => {
          s.isLoading = false;
        });
      }
    );
  };

  const removeUserFromCRMUserTag = async (userTag) => {
    var body = {
      crmUserTagId: userTag.uniqueId,
      merchantId: merchantId,

      tokenFcm: selectedCustomerEdit.tokenFcm || "",
      email: selectedCustomerEdit.email || "",
      phone: selectedCustomerEdit.number || "",
    };

    CommonStore.update((s) => {
      s.isLoading = true;
    });

    ApiClient.POST(API.removeUserFromCRMUserTag, body, false).then((result) => {
      if (result && result.status === "success") {
        Alert.alert(
          "Success",
          "Tag has been removed from user",
          [
            {
              text: "OK",
              onPress: () => {
                // navigation.goBack();
              },
            },
          ],
          { cancelable: false }
        );
      }

      CommonStore.update((s) => {
        s.isLoading = false;
      });
    });
  };

  const renderUserTag = ({ item }) => {
    return (
      <View
        style={{
          borderWidth: 1,
          borderColor: "darkgreen",
          borderRadius: 5,
          padding: 5,
          alignSelf: "baseline",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          marginRight: 10,
          marginBottom: 5,
        }}
      >
        <Text
          style={{
            fontWeight: "500",
            color: "green",
            fontSize: switchMerchant ? 10 : 16,
          }}
        >
          {item.name}
        </Text>
        <TouchableOpacity
          style={{ marginLeft: 5 }}
          onPress={() => {
            Alert.alert(
              "Info",
              `Are you sure you want to delete ${item.name} tag?`,
              [
                { text: "NO", onPress: () => { }, style: "cancel" },
                {
                  text: "YES",
                  onPress: () => {
                    removeUserFromCRMUserTag(item);
                  },
                },
              ],
              { cancelable: false }
            );
          }}
        >
          <AntDesign name="closecircle" color={Colors.fieldtTxtColor} />
        </TouchableOpacity>
      </View>
    );
  };

  const renderConfirmUserTag = ({ item }) => {
    return (
      <View
        style={{
          borderWidth: 1,
          borderColor: Colors.primaryColor,
          borderRadius: 5,
          padding: 2,
          paddingVertical: 0,
          alignSelf: "baseline",
          alignItems: "center",
          marginRight: 5,
          marginBottom: 5,
        }}
      >
        <Text
          style={{
            fontWeight: "500",
            color: Colors.primaryColor,
            fontSize: switchMerchant ? 10 : 16,
          }}
        >
          {item.name}
        </Text>
      </View>
    );
  };

  const renderSearch = (item) => {
    return (
      <View style={{ flexDirection: "column" }}>
        <Text style={{ fontWeight: "700", fontSize: 14, marginBottom: 5 }}>
          {item.structured_formatting.main_text}
        </Text>
        <Text style={{ fontSize: 12, color: "grey" }}>{item.description}</Text>
      </View>
    );
  };

  const renderVisitTimeLine = ({ item, index }) => (
    <View style={{ flexDirection: "row", width: "100%" }}>
      <View style={{ width: "7%", alignItems: "center" }}>
        <View
          style={{
            backgroundColor: Colors.primaryColor,
            width: 22,
            height: 22,
            borderRadius: 100,
          }}
        />
        <View style={{ backgroundColor: "#E5F1E4", width: 3, height: 190 }} />
      </View>
      <View
        style={{
          borderWidth: 1,
          borderColor: "#E5E5E5",
          borderRadius: 5,
          width: "93%",
          height: 170,
          padding: 12,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Text
            style={{
              fontWeight: "600",
              fontSize: 16,
              color: Colors.primaryColor,
            }}
          >
            {moment(item.orderDate).format('DD MMM YYYY hh:mm A')}
          </Text>
          <Text
            style={{
              fontWeight: "600",
              fontSize: 16,
              color: Colors.primaryColor,
            }}
          >
            Table Code:{" "}
            {item.tableCode
              ? item.tableCode
              : outletTablesDict[item.tableId]
                ? outletTablesDict[item.tableId].code
                : "N/A"}
          </Text>
        </View>

        <View
          style={{ flexDirection: "row", width: "100%", marginVertical: 8 }}
        >
          <Text style={{ fontWeight: "600", width: "20%" }}>Order ID: </Text>
          <Text style={{ width: "80%", fontWeight: "500" }}>
            {item.orderId}
          </Text>
        </View>

        {item.cartItems && item.cartItems.length > 0 ? (
          <View
            style={{ flexDirection: "row", width: "100%", marginVertical: 8 }}
          >
            <Text style={{ fontWeight: "600", width: "20%" }}>
              Item Ordered:{" "}
            </Text>
            <View style={{ width: "80%" }}>
              <Text style={{ fontWeight: "500" }}>
                {item.cartItems.map((cartItem) => cartItem.itemName).join(", ")}
              </Text>
            </View>
          </View>
        ) : (
          <></>
        )}

        {item.cartItemsCancelled && item.cartItemsCancelled.length > 0 ? (
          <View
            style={{ flexDirection: "row", width: "100%", marginVertical: 8 }}
          >
            <Text style={{ fontWeight: "600", width: "20%" }}>
              Item Cancelled:{" "}
            </Text>
            <View style={{ width: "80%" }}>
              <Text style={{ fontWeight: "500" }}>
                {item.cartItemsCancelled
                  .map((cartItem) => cartItem.itemName)
                  .join(", ")}
              </Text>
            </View>
          </View>
        ) : (
          <></>
        )}

        <View
          style={{ flexDirection: "row", width: "100%", marginVertical: 8 }}
        >
          <Text style={{ fontWeight: "600", width: "20%" }}>Total Price: </Text>
          <Text style={{ width: "80%", fontWeight: "500" }}>
            RM{" "}
            {item.finalPrice
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
        </View>
      </View>
    </View>
  );

  // const expandRedemptionHistory = (param) => {
  //   if (expandHistory == false) {
  //     setExpandHistory(false);
  //     setExpandHistoryDict({
  //       ...expandHistoryDict,
  //       [param.uniqueId]: true,
  //     });
  //     expandHistoryDict;
  //   } else {
  //     setExpandHistory(false);
  //     setExpandHistoryDict({
  //       ...expandHistoryDict,
  //       [param.uniqueId]: undefined,
  //     });
  //   }
  // };

  const actionExpandPhListDict = (param) => {
    if (expandPhListDict[param.uniqueId]) {
      setExpandPhListDict({
        ...expandPhListDict,
        [param.uniqueId]: false,
      });
    } else {
      setExpandPhListDict({
        ...expandPhListDict,
        [param.uniqueId]: true,
      });
    }
  };

  const actionExpandRhListDict = (param) => {
    if (expandRhListDict[param.uniqueId]) {
      setExpandRhListDict({
        ...expandRhListDict,
        [param.uniqueId]: false,
      });
    } else {
      setExpandRhListDict({
        ...expandRhListDict,
        [param.uniqueId]: true,
      });
    }
  };

  const renderPurchasedHistory = ({ item, index }) => {
    ///////////////////////////

    console.log("order id");
    console.log(item.orderId);

    // calculate longest

    var longestStr = 5;

    for (var i = 0; i < item.cartItems.length; i++) {
      const cartItemPriceWIthoutAddOn =
        item.cartItems[i].price -
        item.cartItems[i].addOns.reduce(
          (accum, addOn) => accum + addOn.prices[0],
          0
        );

      if (cartItemPriceWIthoutAddOn.toFixed(0).length > longestStr) {
        longestStr = cartItemPriceWIthoutAddOn.toFixed(0).length;
      }

      for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
        if (
          item.cartItems[i].addOns[j].prices[0].toFixed(0).length > longestStr
        ) {
          longestStr = item.cartItems[i].addOns[j].prices[0].toFixed(0).length;
        }
      }
    }

    if (item.totalPrice.toFixed(0).length > longestStr) {
      longestStr = item.totalPrice.toFixed(0).length;
    }

    if (item.discount.toFixed(0).length > longestStr) {
      longestStr = item.discount.toFixed(0).length;
    }

    if (item.tax.toFixed(0).length > longestStr) {
      longestStr = item.tax.toFixed(0).length;
    }

    if (item.finalPrice.toFixed(0).length > longestStr) {
      longestStr = item.finalPrice.toFixed(0).length;
    }

    console.log(longestStr);

    ///////////////////////////

    // calculate spacing

    var cartItemPriceWIthoutAddOnSpacingList = [];
    var addOnsSpacingList = [];

    for (var i = 0; i < item.cartItems.length; i++) {
      const cartItemPriceWIthoutAddOn =
        item.cartItems[i].price -
        item.cartItems[i].addOns.reduce(
          (accum, addOn) => accum + addOn.prices[0],
          0
        );

      cartItemPriceWIthoutAddOnSpacingList.push(
        Math.max(longestStr - cartItemPriceWIthoutAddOn.toFixed(0).length, 0) +
        1
      );

      for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
        addOnsSpacingList.push(
          Math.max(
            longestStr -
            item.cartItems[i].addOns[j].prices[0].toFixed(0).length,
            0
          ) + 1
        );
      }
    }

    var totalPriceSpacing =
      Math.max(longestStr - item.totalPrice.toFixed(0).length, 0) + 1;
    var discountSpacing =
      Math.max(longestStr - item.discount.toFixed(0).length, 0) + 1;
    var taxSpacing = Math.max(longestStr - item.tax.toFixed(0).length, 0) + 1;
    var finalPriceSpacing =
      Math.max(longestStr - item.finalPrice.toFixed(0).length, 0) + 1;

    ///////////////////////////
    return (
      <View
        style={{
          borderWidth: 1,
          borderRadius: 5,
          //borderColor: 'green',
          borderColor: "#E5E5E5",
          marginBottom: 15,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            // setExpandHistory(!expandHistory)
            // expandRedemptionHistory(item)
            actionExpandPhListDict(item);
          }}
        >
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              padding: 10,
              // paddingBottom: 20,
              paddingVertical: 15,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 5,
              borderWidth: 1,
              //borderColor: '#E5E5E5',
              //borderColor: 'green',
              borderColor: item.completedDate ? "green" : "red",
              backgroundColor: Colors.whiteColor,
              shadowColor: "#000",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 3,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "90%",
                // borderWidth: 1,
              }}
            >
              <View style={{ width: "23%", marginHorizontal: 3 }}>
                <Text
                  style={{
                    color: Colors.fieldtTxtColor,
                    fontWeight: "600",
                    fontSize: switchMerchant ? 10 : 16,
                    marginBottom: 10,
                  }}
                >
                  Order Type:
                </Text>
                <Text
                  style={
                    switchMerchant
                      ? { fontSize: 8, fontWeight: "500" }
                      : { fontWeight: "500" }
                  }
                >
                  {ORDER_TYPE_PARSED[item.orderType]}
                </Text>
              </View>
              <View style={{ width: "23%", marginHorizontal: 3 }}>
                <Text
                  style={{
                    color: Colors.fieldtTxtColor,
                    fontWeight: "600",
                    fontSize: switchMerchant ? 10 : 16,
                    marginBottom: 10,
                  }}
                >
                  Order Date:
                </Text>
                <Text
                  style={
                    switchMerchant
                      ? { fontSize: 8, fontWeight: "500" }
                      : { fontWeight: "500" }
                  }
                >
                  {moment(item.orderDate).format("DD MMM YYYY hh:mm A")}
                </Text>
              </View>
              <View style={{ width: "23%", marginHorizontal: 3 }}>
                <Text
                  style={{
                    color: Colors.fieldtTxtColor,
                    fontWeight: "600",
                    fontSize: switchMerchant ? 10 : 16,
                    marginBottom: 10,
                  }}
                >
                  Payment Method:
                </Text>
                <Text
                  style={
                    switchMerchant
                      ? { fontSize: 8, fontWeight: "500" }
                      : { fontWeight: "500" }
                  }
                >
                  {item.paymentDetails
                    ? PAYMENT_CHANNEL_NAME_PARSED[item.paymentDetails.channel]
                      ? PAYMENT_CHANNEL_NAME_PARSED[item.paymentDetails.channel]
                      : item.paymentDetails.channel
                        ? item.paymentDetails.channel
                        : "N/A"
                    : "N/A"}
                </Text>
              </View>
              <View style={{ width: "23%", marginHorizontal: 3 }}>
                <Text
                  style={{
                    color: Colors.fieldtTxtColor,
                    fontWeight: "600",
                    fontSize: switchMerchant ? 10 : 16,
                    marginBottom: 10,
                  }}
                >
                  Payment Status:
                </Text>
                <Text
                  style={
                    switchMerchant
                      ? { fontSize: 8, fontWeight: "500" }
                      : { fontWeight: "500" }
                  }
                >
                  {item.completedDate ? "Paid" : "Outstanding"}
                </Text>
              </View>
            </View>

            <View style={{}}>
              {/* <Icon name={ expandRedemptionHistory == true ? "chevron-up" : "chevron-down"} size={25} color={Colors.tabGrey} style={{}}/> */}
              <Icon
                name={
                  expandPhListDict[item.uniqueId]
                    ? "chevron-up"
                    : "chevron-down"
                }
                size={switchMerchant ? 15 : 25}
                color={Colors.tabGrey}
                style={{}}
              />
            </View>
          </View>
        </TouchableOpacity>
        {/* {(expandHistory && expandHistoryDict[item.uniqueId] == true) ? ( */}
        {expandPhListDict[item.uniqueId] == true ? (
          <View
            style={{
              minheight: windowHeight * 0.35,
              marginTop: 30,
              paddingBottom: 20,
            }}
          >
            {item.cartItems.map((cartItem, index) => {
              const cartItemPriceWIthoutAddOn =
                cartItem.price -
                cartItem.addOns.reduce(
                  (accum, addOn) => accum + addOn.prices[0],
                  0
                );

              return (
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      marginBottom: Platform.OS == "ios" ? 10 : 10,
                      minHeight: 80,
                      //backgroundColor: 'yellow',
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        //backgroundColor: 'blue',
                      }}
                    >
                      {/* {index == 0 ? (
                      <View
                        style={{
                          marginHorizontal: 1,
                          width: Platform.OS == 'ios' ? '8%' : '8%',
                          //justifyContent: 'center',
                          alignItems: 'center',
                          //backgroundColor: 'blue',
                        }}>
                        <TouchableOpacity
                          style={{
                            alignItems: 'center',
                            marginTop: '-10%',
                          }}
                          onPress={() => {
                            var crmUser = null;

                            if (item.crmUserId !== undefined) {
                              for (
                                var i = 0;
                                i < crmUsers.length;
                                i++
                              ) {
                                if (
                                  item.crmUserId ===
                                  crmUsers[i].uniqueId
                                ) {
                                  crmUser = crmUsers[i];
                                  break;
                                }
                              }
                            }

                            if (!crmUser) {
                              for (
                                var i = 0;
                                i < crmUsers.length;
                                i++
                              ) {
                                if (
                                  item.userId ===
                                  crmUsers[i].firebaseUid
                                ) {
                                  crmUser = crmUsers[i];
                                  break;
                                }
                              }
                            }

                            if (crmUser) {
                              CommonStore.update(
                                (s) => {
                                  s.selectedCustomerEdit = crmUser;
                                  // s.selectedCustomerEdit = userReservations[item.userId] && crmUsers[item.userId] ? crmUsers[item.userId] : null ;

                                  s.routeParams = {
                                    pageFrom: 'Reservation',
                                  };
                                },
                                () => {
                                  navigation.navigate('NewCustomer');
                                },
                              );
                            }
                          }}>
                          <Image
                            style={{
                              width: switchMerchant
                                ? windowWidth * 0.12
                                : 60,
                              height: switchMerchant
                                ? windowHeight * 0.15
                                : 60,
                            }}
                            resizeMode="contain"
                            source={require('../assets/image/profile-pic.jpg')}
                          />

                          <View
                            style={{
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}>
                            <Text
                              style={[
                                {
                                  fontFamily: 'NunitoSans-Bold',
                                  marginTop: 0,
                                  fontSize: 16,
                                  textAlign: 'center',
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 8,
                                    }
                                  : {},
                              ]}
                              numberOfLines={1}>
                              {item.userName ? item.userName : 'Guest'}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View
                        style={{
                          marginHorizontal: 1,
                          width: Platform.OS == 'ios' ? '8%' : '8%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      />
                    )} */}

                      <View
                        style={{
                          // flex: 0.3,
                          width: "5%",
                          //justifyContent: 'center',
                          alignItems: "center",
                          //backgroundColor: 'red',
                          //paddingLeft: '1.2%',
                        }}
                      >
                        <Text
                          style={[
                            {
                              fontFamily: "NunitoSans-Bold",
                              fontSize: 16,
                            },
                            switchMerchant
                              ? {
                                fontSize: 8,
                              }
                              : {},
                          ]}
                        >
                          {index + 1}.
                        </Text>
                      </View>

                      <View
                        style={{
                          //flex: 0.5,
                          width: "10%",
                          //backgroundColor: 'green',
                          alignItems: "center",
                        }}
                      >
                        <AsyncImage
                          source={{ uri: cartItem.image }}
                          // item={cartItem}
                          style={{
                            width: switchMerchant ? windowHeight * 0.12 : 60,
                            height: switchMerchant ? windowHeight * 0.12 : 60,
                            borderWidth: 1,
                            borderColor: "#E5E5E5",
                            borderRadius: 5,
                          }}
                        />
                      </View>
                      <View style={{ width: "83%" }}>
                        <View
                          style={{
                            marginLeft: Platform.OS == "ios" ? 14 : 14,
                            marginBottom: 10,
                            //backgroundColor: 'blue',
                            width: "100%",
                            flexDirection: "row",
                          }}
                        >
                          <View
                            style={{ width: switchMerchant ? "64%" : "68.2%" }}
                          >
                            <Text
                              style={[
                                {
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: 16,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 8,
                                  }
                                  : {},
                              ]}
                            >
                              {cartItem.name}
                            </Text>
                          </View>

                          <View
                            style={{
                              width: "13%",
                            }}
                          >
                            <View
                              style={{
                                alignItems: "center",
                                //backgroundColor: 'yellow',
                              }}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 16,
                                  },
                                  // Platform.OS === 'android'
                                  //   ? {
                                  //       width: '200%',
                                  //     }
                                  //   : {},
                                  switchMerchant
                                    ? {
                                      fontSize: 8,
                                    }
                                    : {},
                                ]}
                              >
                                x{cartItem.quantity}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "18.65%",
                            }}
                          >
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 8,
                                  }
                                  : { fontSize: 16 }
                              }
                            >
                              RM
                            </Text>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 8,
                                  }
                                  : { fontSize: 16, paddingRight: 15 }
                              }
                            >
                              {cartItemPriceWIthoutAddOn
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Text>
                          </View>
                        </View>

                        {cartItem.remarks && cartItem.remarks.length > 0 ? (
                          <View
                            style={{
                              alignItems: "center",
                              flexDirection: "row",
                              marginLeft: Platform.OS == "ios" ? 14 : 14,
                            }}
                          >
                            <View style={{ justifyContent: "center" }}>
                              <Text
                                style={[
                                  {
                                    fontFamily: "NunitoSans-SemiBold",
                                    fontSize: 16,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 8,
                                    }
                                    : {},
                                ]}
                              >
                                {cartItem.remarks}
                              </Text>
                            </View>
                          </View>
                        ) : (
                          <></>
                        )}

                        {cartItem.addOns.map((addOnChoice, i) => {
                          return (
                            <View
                              style={{
                                flexDirection: "row",
                                // marginLeft: -5,
                                width: "100%",
                              }}
                            >
                              <View
                                style={{
                                  width: switchMerchant ? "64%" : "68.2%",
                                  flexDirection: "row",
                                  marginLeft: Platform.OS == "ios" ? 14 : 14,
                                }}
                              >
                                <Text
                                  style={[
                                    {
                                      fontFamily: "NunitoSans-Bold",
                                      fontSize: 16,
                                      color: Colors.descriptionColor,
                                      width: "25%",
                                      // marginLeft: 5,
                                    },
                                    switchMerchant
                                      ? {
                                        fontSize: 8,
                                      }
                                      : {},
                                  ]}
                                >
                                  {`${addOnChoice.name}:`}
                                </Text>
                                <Text
                                  style={[
                                    {
                                      fontFamily: "NunitoSans-Bold",
                                      fontSize: 16,
                                      color: Colors.descriptionColor,
                                      width: "83%",
                                      // marginLeft: 5,
                                    },
                                    switchMerchant
                                      ? {
                                        fontSize: 8,
                                      }
                                      : {},
                                  ]}
                                >
                                  {`${addOnChoice.choiceNames[0]}`}
                                </Text>
                              </View>

                              <View
                                style={[
                                  {
                                    width: "13%",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    //backgroundColor: 'blue',
                                  },
                                  switchMerchant
                                    ? {
                                      // borderWidth: 1
                                    }
                                    : {},
                                ]}
                              >
                                <Text
                                  style={[
                                    {
                                      fontFamily: "NunitoSans-Bold",
                                      fontSize: 16,
                                      color: Colors.descriptionColor,
                                      width: "28%",
                                      // right: 38,
                                      //backgroundColor: 'green',
                                      textAlign: "center",
                                    },
                                    switchMerchant
                                      ? {
                                        fontSize: 8,
                                        // borderWidth: 1,
                                        paddingLeft: "8%",
                                        textAlign: "left",
                                      }
                                      : {},
                                    !switchMerchant && Platform.OS === "android"
                                      ? {}
                                      : {},
                                  ]}
                                >
                                  {`${addOnChoice.quantities
                                    ? `x${addOnChoice.quantities[0]}`
                                    : ""
                                    }`}
                                </Text>
                              </View>

                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "18.65%",
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={[
                                    switchMerchant
                                      ? { fontSize: 8 }
                                      : {
                                        color: Colors.descriptionColor,
                                        fontSize: 16,
                                      },
                                  ]}
                                >
                                  RM
                                </Text>
                                <Text
                                  style={
                                    switchMerchant
                                      ? { fontSize: 8 }
                                      : {
                                        color: Colors.descriptionColor,
                                        paddingRight: 15,
                                        fontSize: 16,
                                      }
                                  }
                                >
                                  {addOnChoice.prices[0]
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View>

                    <View
                      style={[
                        {
                          width: "14%",
                          //height: '30%',
                          //justifyContent: 'space-between',
                          flexDirection: "column",
                          //backgroundColor: 'green',
                        },
                        Platform.OS === "android" ? {} : {},
                        switchMerchant
                          ? {
                            fontSize: 8,
                          }
                          : {},
                      ]}
                    >
                      {/* <View style={{ marginTop: 3 }}>
                        {cartItem.addOns.map((addOnChoice, i) => {
                          return (
                            <View
                              style={{
                                flexDirection: 'row',
                                //width: '138.5%',
                                height: 22.5,
                              }}>
                              <View
                                style={[
                                  {
                                    width: '100%',
                                    flexDirection: 'row',
                                    //backgroundColor: 'blue',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  },
                                  switchMerchant
                                    ? {
                                      // borderWidth: 1
                                    }
                                    : {},
                                ]}>
                                <Text
                                  style={[
                                    switchMerchant ? { fontSize: 8 } : {},
                                  ]}>
                                  RM
                                </Text>
                                <Text
                                  style={
                                    switchMerchant
                                      ? { fontSize: 8 }
                                      : { paddingRight: 15 }
                                  }>
                                  {addOnChoice.prices[0]
                                    .toFixed(2)
                                    .replace(
                                      /(\d)(?=(\d{3})+(?!\d))/g,
                                      '$1,',
                                    )}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      </View> */}
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <View style={{ width: "70%" }} />
                    <View style={{ width: 15 }} />
                    {index === item.cartItems.length - 1 ? (
                      <View
                        style={{
                          flexDirection: "row",
                          //backgroundColor: 'yellow',
                          width: "28%",
                        }}
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 8,
                                    width: "31.5%",
                                  }
                                  : {
                                    fontSize: 16,
                                    width: "43.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                              }
                            >
                              Subtotal:
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "55.4%",
                              }}
                            >
                              <Text
                                style={
                                  switchMerchant
                                    ? { fontSize: 8 }
                                    : { fontSize: 16 }
                                }
                              >
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 8,
                                    }
                                    : { fontSize: 16, paddingRight: 15 }
                                }
                              >
                                {item.totalPrice
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Text>
                            </View>
                          </View>
                          {cartItem.orderType === ORDER_TYPE.DELIVERY ? (
                            <View
                              style={{
                                flexDirection: "row",
                              }}
                            >
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 8,
                                      width: "31.5%",
                                    }
                                    : {
                                      fontSize: 16,
                                      width: "43.9%",
                                      fontFamily: "Nunitosans-Bold",
                                    }
                                }
                              >
                                Delivery Fee:
                              </Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "55.4%",
                                }}
                              >
                                <Text
                                  style={
                                    switchMerchant
                                      ? { fontSize: 8 }
                                      : { fontSize: 16 }
                                  }
                                >
                                  RM
                                </Text>
                                <Text
                                  style={
                                    switchMerchant
                                      ? {
                                        fontSize: 8,
                                      }
                                      : { fontSize: 16, paddingRight: 15 }
                                  }
                                >
                                  {item.deliveryFee
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                                </Text>
                              </View>
                            </View>
                          ) : (
                            <></>
                          )}

                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 8,
                                    width: "31.5%",
                                  }
                                  : {
                                    fontSize: 16,
                                    width: "43.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                              }
                            >
                              Discount:
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "55.4%",
                              }}
                            >
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 8,
                                    }
                                    : { fontSize: 16, paddingRight: 15 }
                                }
                              >
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 8,
                                    }
                                    : { fontSize: 16, paddingRight: 15 }
                                }
                              >
                                -{" "}
                                {item.discount
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 8,
                                    width: "31.5%",
                                  }
                                  : {
                                    fontSize: 16,
                                    width: "43.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                              }
                            >
                              Tax:
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "55.4%",
                              }}
                            >
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 8,
                                    }
                                    : { fontSize: 16, paddingRight: 15 }
                                }
                              >
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 8,
                                    }
                                    : { fontSize: 16, paddingRight: 15 }
                                }
                              >
                                {item.tax
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 8,
                                    width: "31.5%",
                                  }
                                  : {
                                    fontSize: 16,
                                    width: "43.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                              }
                            >
                              Total:
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "55.4%",
                              }}
                            >
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 8,
                                    }
                                    : { fontSize: 16, paddingRight: 15 }
                                }
                              >
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 8,
                                    }
                                    : { fontSize: 16, paddingRight: 15 }
                                }
                              >
                                {item.finalPrice
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}
                  </View>

                  {/* <View style={{alignItems:'flex-end'}}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 16 }}>Subtotal: {(Math.ceil(cartItem.price * 20-0.05) /20).toFixed(2)}</Text>
                  </View>
                </View> */}
                  {/* {(cartItem.remarks && cartItem.remarks.length > 0) ?
                <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                  
                  <View style={{ flex: 1, justifyContent: 'center', }}>
                    <Text style={{ fontFamily: 'NunitoSans-SemiBold', fontSize: 15 }}>{cartItem.remarks}</Text>
                  </View>
                  
                </View>
                : <></>
              } */}
                </View>
              );
            })}
          </View>
        ) : null}
      </View>
    );
  };

  const renderPromotion = ({ item, index }) => {
    var startTime = moment(item.promoTimeStart);
    var startDate = moment(item.promoDateStart).set({
      hour: startTime.get("hour"),
      minute: startTime.get("minute"),
    });

    var endTime = moment(item.promoTimeEnd);
    var endDate = moment(item.promoDateEnd).set({
      hour: endTime.get("hour"),
      minute: endTime.get("minute"),
    });

    console.log(startDate.format());
    console.log(endDate.format());

    return (
      <View
        style={{
          zIndex: (index + 1) % 2 == 0 ? -2 : 1,
          backgroundColor: "#FFFFFF",
          paddingVertical: 15,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,

          //height: 105,
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={() => {
            /*  CommonStore.update(s => {
               s.selectedPromotionEdit = item;
             });
 
             navigation.navigate('NewCampaign'); */
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
            <View style={{ flex: 1 }}>
              <AsyncImage
                //source={{ uri: logo }}
                source={{ uri: item.image }}
                item={item}
                style={{
                  width: switchMerchant ? 40 : 75,
                  height: switchMerchant ? 40 : 75,
                  boarderWidth: 1,
                  borderRadius: 4,
                  backgroundColor: Colors.secondaryColor,
                }}
              ></AsyncImage>
            </View>

            <View style={{ flex: 1.8, marginHorizontal: 2 }}>
              <Text
                style={{
                  fontSize: switchMerchant ? 8 : 12,
                  fontWeight: "500",
                  textAlign: "left",
                  width: "90%",
                  color: "black",
                }}
              >
                {item.campaignName}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "column",
                flex: 1.85,
                marginHorizontal: 2,
              }}
            >
              <Text
                style={{
                  fontSize: switchMerchant ? 8 : 12,
                  fontWeight: "500",
                  textAlign: "left",
                  fontVariant: ["tabular-nums"],
                }}
              >{`${moment(item.promoDateStart).format("DD MMM YYYY")} ${moment(
                item.promoTimeStart
              ).format("HH.mm A")}`}</Text>
              <Text
                style={{
                  fontSize: switchMerchant ? 8 : 12,
                  fontWeight: "500",
                  marginTop: 2,
                  marginBottom: 2,
                  textAlign: "left",
                  paddingLeft: "21%",
                }}
              >
                to
              </Text>
              <Text
                style={{
                  fontSize: switchMerchant ? 8 : 12,
                  fontWeight: "500",
                  textAlign: "left",
                  fontVariant: ["tabular-nums"],
                }}
              >{`${moment(item.promoDateEnd).format("DD MMM YYYY")} ${moment(
                item.promoTimeEnd
              ).format("HH.mm A")}`}</Text>
            </View>

            <View style={{ flex: 1.98, marginHorizontal: 2 }}>
              <Text
                style={{
                  fontSize: switchMerchant ? 8 : 12,
                  fontWeight: "500",
                  textAlign: "left",
                  marginBottom: 3,
                }}
              >
                {item.outletNameList.join("\n")}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const renderVoucher = (param) => {
    const voucherRedemption = param.item;
    const index = param.index;

    const item = merchantVouchersDict[voucherRedemption.voucherId] || {
      name: "N/A",
      value: 0,
      startDate: new Date(),
      endDate: new Date(),
    };

    var voucherStatus = "";

    // voucherStatus = MERCHANT_VOUCHER_STATUS_PARSED[item.status];

    // if (item.status === MERCHANT_VOUCHER_STATUS.DRAFT && item.isPublishedOnce) {
    //   voucherStatus = 'Pulled Down';
    // }

    if (voucherRedemption.redeemDate && voucherRedemption.usageDate === null) {
      if (moment().isAfter(item.endDate)) {
        voucherStatus = "Expired";
      } else {
        voucherStatus = "Active";
      }
    } else if (voucherRedemption.redeemDate && voucherRedemption.usageDate) {
      voucherStatus = "Used";
    }

    var voucherValue = "";

    if (item.type === MERCHANT_VOUCHER_TYPE.CASH_VOUCHER) {
      voucherValue = `RM${item.value.toFixed(2)}`;
    } else if (item.type === MERCHANT_VOUCHER_TYPE.PERCENTAGE_VOUCHER) {
      voucherValue = `${item.value.toFixed(0)}%`;
    } else if (item.type === MERCHANT_VOUCHER_TYPE.BUNDLE_VOUCHER) {
      voucherValue = `RM${item.bundleBuyPrice.toFixed(0)}`;
    } else if (item.type === MERCHANT_VOUCHER_TYPE.COMPLIMENTARY_VOUCHER) {
      voucherValue = `Complimentary`;
    }

    return (
      <TouchableOpacity
        onPress={() => {
          /* CommonStore.update(s => {
            s.selectedVoucherEdit = item;
          });

          setCreateVoucher(true);
          setVoucherList(false); */
        }}
        style={{
          zIndex: (index + 1) % 2 == 0 ? -2 : 1,
          backgroundColor: "#FFFFFF",
          paddingVertical: 20,
          paddingLeft: 5,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row", marginTop: 8, marginBottom: 8 }}>
          <View style={{ marginHorizontal: 2, flex: 1.8 }}>
            <Text
              style={[
                styles.textItem,
                { color: "black", fontSize: switchMerchant ? 8 : 12 },
              ]}
            >
              {item.title}
            </Text>
          </View>

          <View style={{ marginHorizontal: 2, flex: 1 }}>
            <Text
              style={([styles.textItem], { fontSize: switchMerchant ? 8 : 12 })}
            >
              {voucherValue}
            </Text>
          </View>

          <View style={{ marginHorizontal: 2, flex: 1.8 }}>
            <Text
              style={[styles.textItem, { fontSize: switchMerchant ? 8 : 12 }]}
            >
              {item.startDate == null || item.startDate == undefined
                ? "N/A"
                : moment(item.startDate).format("DD MMM YYYY")}
              -
              {item.endDate == null || item.endDate == undefined
                ? "N/A"
                : moment(item.endDate).format("DD MMM YYYY")}
            </Text>
          </View>

          <View
            style={{ marginHorizontal: 3, flex: switchMerchant ? 1.79 : 1.8 }}
          >
            <Text
              style={[
                styles.textItem,
                {
                  fontSize: switchMerchant ? 8 : 12,
                  opacity: voucherStatus == "Pulled Down" ? 0.83 : 1,
                  fontWeight: "500",
                  color:
                    voucherStatus == "Pulled Down"
                      ? Colors.tabRed
                      : voucherStatus == "Draft"
                        ? Colors.fieldtTxtColor
                        : voucherStatus == "Published"
                          ? Colors.primaryColor
                          : "black",
                },
              ]}
            >
              {voucherStatus}
            </Text>
          </View>

          <View></View>
        </View>
      </TouchableOpacity>
    );
  };

  const renderDocket = ({ item, index }) => {
    const outletItemsStrList = [];
    for (var i = 0; i < item.bdItems.length; i++) {
      if (
        allOutletsItemsSkuDict[item.bdItems[i].outletItemSku] &&
        allOutletsItemsSkuDict[item.bdItems[i].outletItemSku].length > 0
      ) {
        outletItemsStrList.push(
          `${allOutletsItemsSkuDict[item.bdItems[i].outletItemSku][0].name} x${item.bdItems[i].quantity
          }`
        );
      }
    }

    const beerDocket = beerDocketsDict[item.beerDocketId];
    var bdOutletNames = beerDocket.bdOutletNames;
    if (item.bdOutlets) {
      bdOutletNames = [];

      for (var i = 0; i < beerDocket.bdOutlets.length; i++) {
        var isFound = false;

        for (var j = 0; j < item.bdOutlets.length; j++) {
          if (item.bdOutlets[j] === beerDocket.bdOutlets[i]) {
            isFound = true;
            break;
          }
        }

        if (isFound) {
          bdOutletNames.push(beerDocket.bdOutletNames[i]);
        }
      }
    }

    var docketStatus = "N/A";

    var isRedeemed = true;

    for (var j = 0; j < item.bdItems.length; j++) {
      if (item.bdItems[j].quantity > 0) {
        isRedeemed = false;
        break;
      }
    }

    if (!isRedeemed) {
      if (moment().isAfter(item.expiryDate)) {
        docketStatus = "Expired";
      } else {
        // overviewBeerDocketActiveTemp += 1;

        docketStatus = "On Hand";
      }
    } else {
      docketStatus = "Redeemed";

      // overviewBeerDocketRedeemedTemp += 1;
      // overviewBeerDocketUsedTemp += 1;
    }

    return (
      <View
        style={{
          zIndex: (index + 1) % 2 == 0 ? -2 : 1,
          backgroundColor: "#FFFFFF",
          paddingVertical: 5,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          //height: 105,
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={() => {
            /*  CommonStore.update(s => {
               s.selectedPromotionEdit = item;
             });
 
             navigation.navigate('NewCampaign'); */
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
            <View style={{ flex: 1 }}>
              {beerDocket.image ? (
                <AsyncImage
                  //source={{ uri: logo }}
                  source={{ uri: beerDocket.image }}
                  item={beerDocket}
                  style={{
                    width: switchMerchant ? 40 : 75,
                    height: switchMerchant ? 40 : 75,
                    boarderWidth: 1,
                    borderRadius: 4,
                    //backgroundColor: Colors.secondaryColor,
                  }}
                  hideLoading={true}
                ></AsyncImage>
              ) : (
                <AsyncImage
                  source={{
                    uri: "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png?w=640",
                  }}
                  style={{
                    width: switchMerchant ? 40 : 75,
                    height: switchMerchant ? 40 : 75,
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                    borderRadius: 4,
                  }}
                />
              )}
            </View>

            <View style={{ flex: 1.8, marginHorizontal: 2 }}>
              <Text
                style={{
                  fontSize: switchMerchant ? 8 : 12,
                  fontWeight: "500",
                  textAlign: "left",
                  width: "90%",
                  color: "black",
                }}
              >
                {outletItemsStrList.length > 0
                  ? outletItemsStrList.join(",\n")
                  : "N/A"}
              </Text>
            </View>

            <View
              style={{
                flexDirection: "column",
                flex: 1.85,
                marginHorizontal: 2,
              }}
            >
              <Text
                style={{
                  fontSize: switchMerchant ? 8 : 12,
                  fontWeight: "500",
                  textAlign: "left",
                }}
              >{`${moment(beerDocket.startDate).format("DD MMM YYYY")}-${moment(
                item.expiryDate
              ).format("DD MMM YYYY")}`}</Text>
              {/* <Text style={{ fontSize: 12, fontWeight: '500', textAlign: 'left' }}>{`${moment(beerDocket.endDate).format('HH.mmA')}-${moment(item.promoTimeEnd).format('HH.mmA')}`}</Text> */}
            </View>

            <View style={{ flex: 1.98, marginHorizontal: 2 }}>
              <Text
                style={{
                  fontSize: switchMerchant ? 8 : 12,
                  fontWeight: "500",
                  textAlign: "left",
                  marginBottom: 3,
                }}
              >
                {bdOutletNames.join(",\n")}
              </Text>
            </View>

            <View style={{ flex: 1, marginHorizontal: 2 }}>
              <Text
                style={{
                  fontSize: switchMerchant ? 8 : 12,
                  fontWeight: "500",
                  textAlign: "left",
                  marginBottom: 3,
                }}
              >
                {docketStatus}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  const renderRedemptionHistory = ({ item, index }) => {
    var detailsStr = "N/A";
    var detailsList = [];
    var cartItems = [];

    const currOrder = selectedCustomerOrders.find(
      (order) => order.uniqueId === item.orderId
    );

    if (item.transactionType === USER_POINTS_TRANSACTION_TYPE.REDEEM) {
      if (currOrder && currOrder.pointsToRedeemPackageIdList) {
        for (var i = 0; i < currOrder.pointsToRedeemPackageIdList.length; i++) {
          const currPackage =
            pointsRedeemPackagesDict[currOrder.pointsToRedeemPackageIdList[i]];

          if (currPackage) {
            detailsList.push(currPackage.packageName);
          }

          cartItems = currOrder.cartItems || [];
        }
      }
    }

    if (detailsList.length > 0) {
      detailsStr = detailsList.join(", ");
    }

    return (
      <View
        style={{
          borderWidth: 1,
          borderRadius: 5,
          //borderColor: 'green',
          borderColor: "#E5E5E5",
          marginBottom: 15,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            padding: 10,
            paddingBottom: 20,
            alignItems: "flex-end",
            borderRadius: 5,
            borderWidth: 1,
            //borderColor: '#E5E5E5',
            borderColor: "green",
            backgroundColor: Colors.whiteColor,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.22,
            shadowRadius: 3.22,
            elevation: 3,
          }}
        >
          <View style={{ width: "30%", marginHorizontal: 3 }}>
            <Text
              style={{
                color: Colors.fieldtTxtColor,
                fontWeight: "600",
                fontSize: 16,
                marginBottom: 10,
              }}
            >
              Package:
            </Text>
            <Text style={{ fontWeight: "500" }}>
              {detailsList.length > 0 ? detailsList : "N/A"}
            </Text>
          </View>
          <View style={{ width: "30%", marginHorizontal: 3 }}>
            <Text
              style={{
                color: Colors.fieldtTxtColor,
                fontWeight: "600",
                fontSize: 16,
                marginBottom: 10,
              }}
            >
              Spent Points:
            </Text>
            <Text style={{ fontWeight: "500" }}>{-item.amount}</Text>
          </View>
          <View style={{ width: "30%", marginHorizontal: 3 }}>
            <Text
              style={{
                color: Colors.fieldtTxtColor,
                fontWeight: "600",
                fontSize: 16,
                marginBottom: 10,
              }}
            >
              Redeeemd on:
            </Text>
            <Text style={{ fontWeight: "500" }}>
              {moment(item.createdAt).format("D MMM YYYY")}
            </Text>
          </View>
          <View style={{}}>
            <TouchableOpacity
              onPress={() => {
                // setExpandHistory(!expandHistory)
                // expandRedemptionHistory(item)
                actionExpandRhListDict(item);
              }}
            >
              {/* <Icon name={ expandRedemptionHistory == true ? "chevron-up" : "chevron-down"} size={25} color={Colors.tabGrey} style={{}}/> */}
              <Icon
                name={
                  expandRhListDict[item.uniqueId]
                    ? "chevron-up"
                    : "chevron-down"
                }
                size={25}
                color={Colors.tabGrey}
                style={{}}
              />
            </TouchableOpacity>
          </View>
        </View>
        {/* {(expandHistory && expandHistoryDict[item.uniqueId] == true) ? ( */}
        {expandRhListDict[item.uniqueId] ? (
          <View
            style={{
              flexDirection: "column",
              margin: 10,
              marginHorizontal: 18,
            }}
          >
            {cartItems &&
              cartItems.length > 0 &&
              cartItems.map((cartItem, index) => {
                return (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginVertical: 10,
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Text style={{ fontWeight: "600", fontSize: 15 }}>
                        {index + 1}.
                      </Text>
                      <View
                        style={{
                          marginLeft: 15,
                        }}
                      >
                        <Text style={{ fontWeight: "500", fontSize: 15 }}>
                          {cartItem.itemName}
                        </Text>

                        {cartItem.remarks ? (
                          <Text
                            style={{
                              color: Colors.fieldtTxtColor,
                              fontWeight: "500",
                              marginTop: 2,
                            }}
                          >
                            Remark: {cartItem.remarks}
                          </Text>
                        ) : (
                          <></>
                        )}

                        {cartItem.cartItemPointsDeducted ? (
                          <Text
                            style={{
                              color: Colors.darkBgColor,
                              fontWeight: "500",
                              marginTop: 2,
                            }}
                          >
                            {`${cartItem.cartItemPointsDeducted.toFixed(
                              0
                            )} points/RM${cartItem.cartItemAmountDeducted
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} off`}
                          </Text>
                        ) : (
                          <></>
                        )}
                      </View>
                    </View>

                    <Text style={{ fontWeight: "500", fontSize: 15 }}>
                      RM
                      {cartItem.price
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                    </Text>
                  </View>
                );
              })}

            {/* <View style={{ flexDirection: 'row', flex: 1, marginBottom: 5 }}>
              <Text style={{ flex: 1, fontWeight: '600', fontSize: 15 }}>
                1.
              </Text>
              <View style={{ flex: 9 }}>
                <Text style={{ fontWeight: '500', fontSize: 15 }}>
                  Maggi Goreng
                </Text>
                <Text style={{ color: Colors.fieldtTxtColor, fontWeight: '500', marginTop: 2 }}>
                  Remark: Tambah Pedas
                </Text>
              </View>
            </View> */}
          </View>
        ) : null}
      </View>
    );
  };

  const updateCRMUserRedeemPackageDisableDict = (
    value,
    pointsRedeemPackage
  ) => {
    var body = {
      crmUserId: selectedCustomerEdit.uniqueId
        ? selectedCustomerEdit.uniqueId
        : "",
      firebaseUid: selectedCustomerEdit.firebaseUid
        ? selectedCustomerEdit.firebaseUid
        : "",
      email: selectedCustomerEdit.email,

      merchantId: merchantId,
      merchantName: merchantName,
      outletId: currOutletId,

      pointsRedeemPackageId: pointsRedeemPackage.uniqueId,
      disableStatus: !value, // revert
    };

    console.log(body);

    ApiClient.POST(API.updateCRMUserRedeemPackageDisableDict, body).then(
      (result) => {
        // if (result && result.status === 'success') {
        //   Alert.alert(
        //     'Success',
        //     "Customer added.",
        //     [
        //       {
        //         text: 'OK',
        //         onPress: () => {
        //           navigation.goBack();
        //         },
        //       },
        //     ],
        //     { cancelable: false },
        //   );
        // }
        // CommonStore.update(s => {
        //   s.isLoading = false;
        // });
      }
    );
  };

  const renderQuestionaire = ({ item, index }) => (
    <View style={{ margin: 10, marginLeft: 20, paddingBottom: 15, zIndex: 1 }}>
      <Text
        style={{
          fontSize: 18,
          fontFamily: "Nunitosans-Regular",
          fontWeight: "700",
          color: "#9FA2B4",
        }}
      >
        1. Do you practice sport?
      </Text>

      <Picker
        style={{
          marginTop: 5,
          borderWidth: 1,
          borderColor: "#E5E5E5",
          borderRadius: 5,
          height: 30,
          width: 200,
          paddingVertical: 0,
          marginHorizontal: 10,
        }}
        //disabled={}
        dropDownStyle={{
          borderWidth: 1,
          borderColor: "#E5E5E5",
          borderRadius: 5,
          width: 200,
          paddingVertical: 0,
          marginHorizontal: 10,
        }}
        arrowSize={17}
        arrowColor={"black"}
        arrowStyle={{ paddingVertical: 0 }}
        itemStyle={{ justifyContent: "flex-start", marginLeft: 5 }}
        placeholderStyle={{ marginLeft: 0 }}
        placeholder={" Answer"}
        items={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ]}

      //onChangeItem={(item) => {
      //  console.log(item);
      //}}
      //defaultValue={}
      ></Picker>

      <Text
        style={{
          marginTop: 50,
          fontSize: 18,
          fontFamily: "Nunitosans-Regular",
          fontWeight: "700",
          color: "#9FA2B4",
        }}
      >
        2. Are you currently breast feeding?
      </Text>

      <Picker
        style={{
          marginTop: 5,
          borderWidth: 1,
          borderColor: "#E5E5E5",
          borderRadius: 5,
          height: 30,
          width: 200,
          paddingVertical: 0,
          marginHorizontal: 10,
        }}
        //disabled={}
        dropDownStyle={{
          borderWidth: 1,
          borderColor: "#E5E5E5",
          borderRadius: 5,
          width: 200,
          paddingVertical: 0,
          marginHorizontal: 10,
        }}
        arrowSize={17}
        arrowColor={"black"}
        arrowStyle={{ paddingVertical: 0 }}
        itemStyle={{ justifyContent: "flex-start", marginLeft: 5 }}
        placeholderStyle={{ marginLeft: 0 }}
        placeholder={" Answer"}
        items={[
          { label: "Yes", value: "Yes" },
          { label: "No", value: "No" },
        ]}
      //onChangeItem={(item) => {
      //  console.log(item);
      //}}
      //defaultValue={}
      ></Picker>

      <Text
        style={{
          marginTop: 50,
          fontSize: 18,
          fontFamily: "Nunitosans-Regular",
          fontWeight: "700",
          color: "#9FA2B4",
        }}
      >
        3. Are you takig Medicine?
      </Text>

      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Picker
          style={{
            marginTop: 5,
            borderWidth: 1,
            borderColor: "#E5E5E5",
            borderRadius: 5,
            height: 30,
            width: 200,
            paddingVertical: 0,
            marginHorizontal: 10,
          }}
          //disabled={}
          dropDownStyle={{
            borderWidth: 1,
            borderColor: "#E5E5E5",
            borderRadius: 5,
            width: 200,
            paddingVertical: 0,
            marginHorizontal: 10,
          }}
          arrowSize={17}
          arrowColor={"black"}
          arrowStyle={{ paddingVertical: 0 }}
          itemStyle={{ justifyContent: "flex-start", marginLeft: 5 }}
          placeholderStyle={{ marginLeft: 0 }}
          placeholder={" Answer"}
          items={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
        //onChangeItem={(item) => {
        //  console.log(item);
        //}}
        //defaultValue={}
        ></Picker>

        <Text
          style={{
            marginLeft: 5,
            fontSize: 18,
            fontFamily: "Nunitosans-Regular",
            fontWeight: "700",
            color: "#9FA2B4",
          }}
        >
          Specify:
        </Text>

        <TextInput
          //editable={}
          style={{
            marginLeft: 7,
            alignItems: "center",
            fontSize: 16,
            color: "black",
            fontFamily: "NunitoSans-Regular",
            width: 200,
            height: 40,
            borderWidth: 0.5,
            borderColor: "#E5E5E5",
            backgroundColor: "#F2F3F7",
            borderRadius: 6,
          }}
          placeholder="  Type here..."
          placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
        //onChangeText={(text) => {
        //
        //}}
        //defaultValue={}
        //value={}
        />
      </View>

      <Text
        style={{
          marginTop: 50,
          fontSize: 18,
          fontFamily: "Nunitosans-Regular",
          fontWeight: "700",
          color: "#9FA2B4",
          zIndex: -1,
        }}
      >
        4. Have you already undergone aesthetic procedure?
      </Text>

      <View
        style={{ flexDirection: "row", alignItems: "center", marginBottom: 30 }}
      >
        <Picker
          style={{
            marginTop: 5,
            borderWidth: 1,
            borderColor: "#E5E5E5",
            borderRadius: 5,
            height: 30,
            width: 200,
            paddingVertical: 0,
            marginHorizontal: 10,
          }}
          //disabled={}
          dropDownStyle={{
            borderWidth: 1,
            borderColor: "#E5E5E5",
            borderRadius: 5,
            width: 200,
            height: 55,
            paddingVertical: 0,
            marginHorizontal: 10,
          }}
          arrowSize={17}
          arrowColor={"black"}
          arrowStyle={{ paddingVertical: 0 }}
          itemStyle={{ justifyContent: "flex-start", marginLeft: 5 }}
          placeholderStyle={{ marginLeft: 0 }}
          placeholder={" Answer"}
          items={[
            { label: "Yes", value: "Yes" },
            { label: "No", value: "No" },
          ]}
        //onChangeItem={(item) => {
        //  console.log(item);
        //}}
        //defaultValue={}
        ></Picker>

        <Text
          style={{
            marginLeft: 5,
            fontSize: 18,
            fontFamily: "Nunitosans-Regular",
            fontWeight: "700",
            color: "#9FA2B4",
          }}
        >
          Choose:
        </Text>

        {/* <CheckBox style={{ marginLeft: 10, }}
          checkBoxColor={Colors.fieldtBgColor}
          uncheckedCheckBoxColor={Colors.tabGrey}
          checkedCheckBoxColor={Colors.primaryColor}
        //isChecked={}
        />
        <Text style={{ marginLeft: 7, fontSize: 16, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: '#9FA2B4' }}>Choose:</Text> */}

        <CheckBox
          style={{ marginLeft: 10 }}
          checkBoxColor={Colors.fieldtBgColor}
          uncheckedCheckBoxColor={Colors.tabGrey}
          checkedCheckBoxColor={Colors.primaryColor}
        //isChecked={}
        />
        <Text
          style={{
            marginLeft: 7,
            fontSize: 16,
            fontFamily: "Nunitosans-Regular",
            fontWeight: "700",
            color: "#9FA2B4",
          }}
        >
          Laser
        </Text>

        <CheckBox
          style={{ marginLeft: 10 }}
          checkBoxColor={Colors.fieldtBgColor}
          uncheckedCheckBoxColor={Colors.tabGrey}
          checkedCheckBoxColor={Colors.primaryColor}
        //isChecked={}
        />
        <Text
          style={{
            marginLeft: 7,
            fontSize: 16,
            fontFamily: "Nunitosans-Regular",
            fontWeight: "700",
            color: "#9FA2B4",
          }}
        >
          Injection
        </Text>

        <CheckBox
          style={{ marginLeft: 10 }}
          checkBoxColor={Colors.fieldtBgColor}
          uncheckedCheckBoxColor={Colors.tabGrey}
          checkedCheckBoxColor={Colors.primaryColor}
        //isChecked={}
        />
        <Text
          style={{
            marginLeft: 7,
            fontSize: 16,
            fontFamily: "Nunitosans-Regular",
            fontWeight: "700",
            color: "#9FA2B4",
          }}
        >
          Peeling
        </Text>

        <CheckBox
          style={{ marginLeft: 10 }}
          checkBoxColor={Colors.fieldtBgColor}
          uncheckedCheckBoxColor={Colors.tabGrey}
          checkedCheckBoxColor={Colors.primaryColor}
        //isChecked={}
        />
        <Text
          style={{
            marginLeft: 7,
            fontSize: 16,
            fontFamily: "Nunitosans-Regular",
            fontWeight: "700",
            color: "#9FA2B4",
          }}
        >
          Others
        </Text>
      </View>
    </View>
  );

  const renderPointRedemptionList = ({ item, index }) => {
    var isChecked = true;

    if (
      selectedCustomerEdit &&
      selectedCustomerEdit.pointsRedeemPackageDisableDict
    ) {
      if (selectedCustomerEdit.pointsRedeemPackageDisableDict[item.uniqueId]) {
        isChecked = false;
      }
    }

    return (
      <View
        style={{
          flexDirection: "row",
          marginTop: 10,
          alignItems: "center",
          width: "100%",
        }}
      >
        <View style={{ width: "12%" }}>
          <CheckBox
            style={{ marginLeft: 5 }}
            checkBoxColor={Colors.fieldtBgColor}
            uncheckedCheckBoxColor={Colors.tabGrey}
            checkedCheckBoxColor={Colors.primaryColor}
            value={isChecked}
            onValueChange={(value) => {
              updateCRMUserRedeemPackageDisableDict(value, item);
            }}
          />
        </View>
        <View style={{ marginLeft: 5, alignItems: "center", width: "12%" }}>
          {item.image ? (
            <AsyncImage
              style={{
                width: 50,
                height: 50,
                alignSelf: "center",
                borderRadius: 25,
              }}
              source={{
                uri: item.image,
              }}
              item={item}
              hideLoading={true}
            />
          ) : (
            <Ionicon name="ellipse" size={50} color="#F2C94C" />
          )}
        </View>

        <View style={{ flexDirection: "column", marginLeft: 5, width: "40%" }}>
          <Text
            style={{
              marginLeft: 0,
              fontSize: 16,
              fontFamily: "Nunitosans-Regular",
              fontWeight: "500",
              color: "#9FA2B4",
            }}
          >
            {item.packageName}
          </Text>
          {/* <Text style={{ marginLeft: 7, fontSize: 16, fontFamily: 'Nunitosans-Regular', fontWeight: '500', color: '#9FA2B4' }}>RM90</Text> */}
        </View>

        <View
          style={{
            marginLeft: 5,
            //borderWidth: 1,
            borderRadius: 3,
            backgroundColor: "#4E9F7D",
            width: 140,
            height: 30,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontFamily: "Nunitosans-Regular",
              fontWeight: "500",
              color: "#FFFFFF",
            }}
          >
            {`${item.conversionPointsFrom} points to RM${item.conversionCurrencyTo}`}
          </Text>
        </View>
        {/* <View style={{ marginLeft: 15 }}>
          <TouchableOpacity>
            <MaterialCommunityIcons name={"delete-sweep"} size={25} color={'#858C94'} />
          </TouchableOpacity>
        </View> */}
      </View>
    );
  };

  //same declaration
  // const renderRedemptionHistory = ({ item, index }) => (

  //   <View style={{ borderBottomWidth: StyleSheet.hairlineWidth, flexDirection: 'row', alignSelf: 'center', margin: 20, }}>

  //     <View style={{}}>
  //       <Text style={{ fontSize: 14, color: "black", fontFamily: 'Nunitosans-Regular', marginVertical: 15, marginHorizontal: 15, }}>
  //         Package:{''}
  //       </Text>
  //       <Text style={{ fontSize: 14, fontWeight: '500', textAlign: 'center', backgroundColor: '#F2C94C', color: '#FFFFFF', marginVertical: 15, marginHorizontal: 15, width: 80, }}>
  //         Package A
  //       </Text>
  //     </View>

  //     <View style={{ marginLeft: 20 }}>
  //       <Text style={{ fontSize: 14, color: "black", fontFamily: 'Nunitosans-Regular', marginVertical: 15, marginHorizontal: 15, }}>
  //         Package:{''}
  //       </Text>
  //       <Text style={{ fontSize: 14, color: "black", fontFamily: 'Nunitosans-Regular', marginVertical: 15, marginHorizontal: 15, }}>
  //         Treatment Package
  //       </Text>
  //     </View>

  //     <View style={{ marginLeft: 20 }}>
  //       <Text style={{ fontSize: 14, color: "black", fontFamily: 'Nunitosans-Regular', marginVertical: 15, marginHorizontal: 15, }}>
  //         Redemed on:{''}</Text>
  //       <Text style={{ fontSize: 14, color: "black", fontFamily: 'Nunitosans-Regular', marginVertical: 15, marginHorizontal: 15, }}>
  //         10 Nov 2020</Text>
  //     </View>

  //   </View>

  // )

  //Flatlist for Credit Points
  const renderPointRedemptionHistory = ({ item, index }) => {
    var detailsStr = "N/A";
    var detailsList = [];

    const currOrder = selectedCustomerOrders.find(
      (order) => order.uniqueId === item.orderId
    );

    if (item.transactionType === USER_POINTS_TRANSACTION_TYPE.EARN) {
      if (currOrder && currOrder.cartItems) {
        for (var i = 0; i < currOrder.cartItems.length; i++) {
          detailsList.push(currOrder.cartItems[i].name);
        }
      }
    } else if (item.transactionType === USER_POINTS_TRANSACTION_TYPE.REDEEM) {
      if (currOrder && currOrder.pointsToRedeemPackageIdList) {
        for (var i = 0; i < currOrder.pointsToRedeemPackageIdList.length; i++) {
          const currPackage =
            pointsRedeemPackagesDict[currOrder.pointsToRedeemPackageIdList[i]];

          if (currPackage) {
            detailsList.push(currPackage.packageName);
          }
        }
      }
    }

    if (detailsList.length > 0) {
      detailsStr = detailsList.join(", ");
    }

    return (
      <View style={{ paddingVertical: 5, paddingHorizontal: 2 }}>
        <View
          style={{
            flexDirection: "row",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              width: "12.5%",
              borderRightWidth: 1,
              borderRightColor: "#E5E5E5",
            }}
          >
            <Text
              style={{
                fontWeight: "600",
                color: Colors.fieldtTxtColor,
                fontSize: switchMerchant ? 8 : 12,
              }}
            >
              {moment(item.createdAt).format("D MMM YYYY")}
            </Text>
          </View>
          <View
            style={{
              width: "92%",
              borderRightWidth: 1,
              borderRightColor: "#E5E5E5",
            }}
          >
            <Text
              style={{
                fontWeight: "600",
                color: Colors.fieldtTxtColor,
                fontSize: switchMerchant ? 8 : 12,
                textAlign: "center",
                //  marginLeft:5
              }}
            >
              {detailsStr}
            </Text>
          </View>
          <View style={{ width: "22%" }}>
            <Text
              style={{
                fontWeight: "600",
                color: item.amount >= 0 ? Colors.primaryColor : Colors.tabRed,
                fontSize: switchMerchant ? 8 : 15,
                paddingLeft: 10,
                paddingRight: 5,
              }}
            >
              {item.amount}
            </Text>
          </View>
        </View>
      </View>
    );
  };

      //Flatlist for Cashback
  const renderCashbackHistory = ({ item, index }) => {
    var detailsStr = 'N/A';
    var detailsList = [];

    const currOrder = selectedCustomerOrders.find(
      (order) => order.uniqueId === item.orderId,
    );

    if (item.transactionType === "EARN") {
      if (currOrder && currOrder.cartItems) {
        for (var i = 0; i < currOrder.cartItems.length; i++) {
          detailsList.push(currOrder.cartItems[i].name);
        }
      }
    } 
    // else if (item.transactionType === "DEDUCT") {
    //   if (currOrder && currOrder.pointsToRedeemPackageIdList) {
    //     for (var i = 0; i < currOrder.pointsToRedeemPackageIdList.length; i++) {
    //       const currPackage =
    //         pointsRedeemPackagesDict[currOrder.pointsToRedeemPackageIdList[i]];

    //       if (currPackage) {
    //         detailsList.push(currPackage.packageName);
    //       }
    //     }
    //   }
    // }
    console.log("pointsRedeempackages: ", pointsRedeemPackagesDict)
    console.log("currOrder: ", currOrder)


    if (detailsList.length > 0) {
      detailsStr = detailsList.join(', ');
    }

      return (
        <View style={{ paddingVertical: 5, paddingHorizontal: 2 }}>
          <View
            style={{
              flexDirection: 'row',
              width: '90%',
              justifyContent: 'space-between',
            }}>
            <View
              style={{
                width: '12.5%',
                borderRightWidth: 1,
                borderRightColor: '#E5E5E5',
              }}>
              <Text
                style={{
                  fontWeight: '600',
                  color: Colors.fieldtTxtColor,
                  fontSize: switchMerchant ? 8 : 12,
                }}>
                {moment(item.createdAt).format('D MMM YYYY')}
              </Text>
            </View>
            <View
              style={{
                width: '92%',
                borderRightWidth: 1,
                borderRightColor: '#E5E5E5',
              }}>
              <Text
                style={{
                  fontWeight: '600',
                  color: Colors.fieldtTxtColor,
                  fontSize: switchMerchant ? 8 : 12,
                  textAlign: 'center',
                  //  marginLeft:5
                }}>
                {detailsStr}
              </Text>
            </View>
            <View style={{ width: '22%' }}>
              <Text
                style={{
                  fontWeight: '600',
                  color: item.amount >= 0 ? Colors.primaryColor : Colors.tabRed,
                  fontSize: switchMerchant ? 8 : 15,
                  paddingLeft: 10,
                  paddingRight: 5,
                }}>
                {item.amount}
              </Text>
            </View>
          </View>
        </View>
      );
    };

  //Flatlist for Docket
  const renderRedeemedDocketList = ({ item, index }) => {
    const beerDocket = beerDocketsDict[item.beerDocketId];

    const bdOutletItemsStrList = [];
    const bdOutletItemsImageList = [];

    if (beerDocket && beerDocket.uniqueId) {
      for (var i = 0; i < beerDocket.bdItems.length; i++) {
        if (allOutletsItemsSkuDict[beerDocket.bdItems[i].outletItemSku]) {
          bdOutletItemsStrList.push(
            `${allOutletsItemsSkuDict[beerDocket.bdItems[i].outletItemSku][0]
              .name
            } (x${beerDocket.bdItems[i].quantity})`
          );
          bdOutletItemsImageList.push(
            allOutletsItemsSkuDict[beerDocket.bdItems[i].outletItemSku][0].image
          );
        }
      }
    }
    return (
      <View style={{ paddingVertical: 5, paddingHorizontal: 2 }}>
        <View
          style={{
            flexDirection: "row",
            width: "90%",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              width: "30%",
              borderRightWidth: 1,
              borderRightColor: "#E5E5E5",
            }}
          >
            <Text
              style={{
                fontWeight: "600",
                color: Colors.fieldtTxtColor,
                fontSize: 15,
                textAlign: "center",
                //  marginLeft:5
              }}
            >
              Outlet
            </Text>
            <Text
              style={{
                fontWeight: "600",
                color: Colors.fieldtTxtColor,
                fontSize: 15,
                textAlign: "center",
                //  marginLeft:5
              }}
            >
              {beerDocket &&
                beerDocket.bdOutletNames.map((outletName) => {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        backgroundColor: Colors.tabGrey,
                        paddingHorizontal: 8,
                        marginHorizontal: 4,
                        marginBottom: 2,
                        borderRadius: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Regular",
                          fontSize: 13,
                          marginRight: 6,
                          color: Colors.whiteColor,
                        }}
                      >
                        {outletName}
                      </Text>
                    </View>
                  );
                })}
            </Text>
          </View>

          <View
            style={{
              width: "50%",
              borderRightWidth: 1,
              borderRightColor: "#E5E5E5",
            }}
          >
            <Text
              style={{
                fontWeight: "600",
                color: Colors.fieldtTxtColor,
                fontSize: 15,
                textAlign: "center",
                //  marginLeft:5
              }}
            >
              Item
            </Text>
            <Text
              style={{
                fontWeight: "600",
                color: Colors.fieldtTxtColor,
                fontSize: 15,
                textAlign: "center",
                //  marginLeft:5
              }}
            >
              {beerDocket &&
                bdOutletItemsStrList.map((categoryName) => {
                  return (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Regular",
                          fontSize: 13,
                          marginRight: 6,
                          color: "black",
                        }}
                      >
                        {categoryName}
                      </Text>
                    </View>
                  );
                })}
            </Text>
          </View>
          <View
            style={{
              width: "15%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: "600",
                color: Colors.fieldtTxtColor,
                fontSize: 15,
              }}
            >
              Expired Date
            </Text>
            <Text
              style={{
                fontWeight: "600",
                color: item.amount >= 0 ? Colors.primaryColor : Colors.tabRed,
                fontSize: 15,
              }}
            >
              {moment(item.expiryDate).fromNow()}
            </Text>
          </View>
          <View
            style={{
              width: "15%",
              borderLeftWidth: 1,
              borderLeftColor: "#E5E5E5",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontWeight: "600",
                color: Colors.fieldtTxtColor,
                fontSize: 15,
              }}
            >
              Waiter Name
            </Text>
            <Text
              style={{
                fontWeight: "600",
                color: Colors.fieldtTxtColor,
                fontSize: 15,
              }}
            >
              {item.uniqueId &&
                userOrderBeerDocketUBDIdDict[item.uniqueId] &&
                userOrderBeerDocketUBDIdDict[item.uniqueId].waiterName
                ? userOrderBeerDocketUBDIdDict[item.uniqueId].waiterName
                : "N/A"}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  //Flatlist for Credit Points
  const renderRedemptionAvailability = ({ item, index }) => {
    return (
      <TouchableOpacity
        style={{
          margin: 5,
          borderWidth: 1,
          borderColor: "#E5E5E5",
          borderRadius: 5,
          height: 130,
          width: 385,
          flexDirection: "row",
          padding: 10,
        }}
        onPress={() => { }}
      >
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            margin: 10,
            marginLeft: 0,
          }}
        >
          <View
            style={{
              width: 100,
              height: 100,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              //backgroundColor: 'red',
            }}
          >
            {/* <AsyncImage style={{
                    width: 100,
                    height: 100,
                    alignSelf: 'center',
                    }}
                  source={require('../assets/image/profile-pic.jpg')}
              /> */}
            {item.image ? (
              <AsyncImage
                style={{
                  width: 100,
                  height: 100,
                  alignSelf: "center",
                  borderRadius: 10,
                }}
                source={{
                  uri: item.image,
                }}
                item={item}
                hideLoading={true}
              />
            ) : (
              <Image
                style={{
                  width: 100,
                  height: 100,
                  borderRadius: 10,
                  alignSelf: "center",
                }}
                source={require("../assets/image/profile-pic.jpg")}
                hideLoading={true}
              />
            )}
          </View>
        </View>
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: 10,
          }}
        >
          <Text
            style={{
              fontSize: 21,
              color: "#27353C",
              fontFamily: "NunitoSans-Bold",
            }}
          >
            {item.packageName}
          </Text>
          {/* <Text style={{ fontSize: 21, color: '#27353C', fontFamily: 'NunitoSans-Bold' }}>
            RM90
          </Text> */}
          <View
            style={{
              borderWidth: 1,
              borderColor: "#4E9F7D",
              backgroundColor: "#4E9F7D",
              borderRadius: 2,
              height: 35,
              alignItems: "center",
              justifyContent: "center",
              width: 150,
              marginTop: 15,
            }}
          >
            <Text
              style={{
                fontSize: 18,
                color: "#FFFFFF",
                fontFamily: "NunitoSans-Bold",
              }}
            >
              {`${item.conversionPointsFrom} points to RM${item.conversionCurrencyTo}`}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const uploadForm = () => {
    //const pathToWrite=
    // const csvData = convertArrayToCSV(CsvData);
    // const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-E-indemnity${moment().format('YYYY-MM-DD-HH-mm-ss')}.pdf`;
    // console.log("PATH", pathToWrite)
    // RNFetchBlob.fs
    //     .writeFile(pathToWrite, csvData, 'utf8')
    //     .then(() => {
    //         console.log(`wrote file ${pathToWrite}`);
    //         // wrote file /storage/emulated/0/Download/data.csv
    //         Alert.alert(
    //             'Success',
    //             `Send to ${pathToWrite}`,
    //             [{ text: 'OK', onPress: () => { } }],
    //             { cancelable: false },
    //         );
    //     })
    //     .catch(error => console.error(error));
    // RNFetchBlob.fetch('POST', 'https://content.dropboxapi.com/2/files/upload', {
    // // dropbox upload headers
    // Authorization : "Bearer access-token...",
    // 'Dropbox-API-Arg': JSON.stringify({
    //   path : '/img-from-react-native.png',
    //   mode : 'add',
    //   autorename : true,
    //   mute : false
    // }),
    // 'Content-Type' : 'application/octet-stream',
    // .then((res) => {
    //   console.log(res.text())
    // })
    // .catch((err) => {
    // })
  };

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={6} expandReport={true} />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ width: windowWidth * 0.9 }}
          contentContainerStyle={{
            paddingBottom: windowHeight * 0.1,
          }}
        >
          {/* ********Modals*********** */}
          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showNextVisitPicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.2,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      Next Visit Date
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowNextVisitPicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 18,
                      textAlign: "right",
                      marginRight: 10,
                    }}
                  >
                    Date:
                  </Text>
                  <View
                    style={{
                      borderWidth: 0.5,
                      borderRadius: 5,
                      width: 100,
                    }}
                  >
                    <DatePicker
                      // isVisible={showNextVisitPicker}
                      // mode={'date'}
                      selected={rev_NextVisitDate.toDate()}
                      onChange={(date) => {
                        setRev_NextVisitDate(moment(date));
                        setCustomerNextVisitDate(moment(date));

                        setCustomerNextVisitDateIsChanged(true);

                        // setShowNextVisitPicker(false);
                      }}
                    // onCancel={() => {
                    //   setShowNextVisitPicker(false);
                    // }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showNextVisitTimePicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.4,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      Next Visit Time
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowNextVisitTimePicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TimeKeeper
                    // isVisible={showNextVisitTimePicker}
                    // mode={'time'}
                    time={moment(rev_time).format("hh:mm a")}
                    onChange={(time) => {
                      if (rev_NextVisitDate === null) {
                        setRev_time(moment(`${moment(Date.now()).format('MM/DD/YYYY')} ${time.formatted12}`));
                      } else if (moment(rev_NextVisitDate).format('MM/DD/YYYY') === moment(Date.now()).format('MM/DD/YYYY')) {
                        setRev_time(moment(`${moment(rev_NextVisitDate).format('MM/DD/YYYY')} ${time.formatted12}`))
                      } else {
                        setRev_time(moment(`${moment(rev_NextVisitDate).format('MM/DD/YYYY')} ${time.formatted12}`));
                      }

                      setCustomerNextVisitTime(time.formatted12);

                      setCustomerNextVisitTimeIsChanged(true);

                      // setShowNextVisitTimePicker(false);
                    }}
                    onDoneClick={() => {
                      setShowNextVisitTimePicker(false);
                    }}
                  />
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showDOBPicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.2,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      Date of Birth
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowDOBPicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 18,
                      textAlign: "right",
                      marginRight: 10,
                    }}
                  >
                    Date:
                  </Text>
                  <View
                    style={{
                      borderWidth: 0.5,
                      borderRadius: 5,
                      width: 100,
                    }}
                  >
                    <DatePicker
                      // isVisible={showDOBPicker}
                      // mode={'date'}
                      selected={rev_DOBdate.toDate()}
                      onChange={(date) => {
                        setRev_DOBdate(moment(date));
                        setCustomerDob(moment(date));

                        // setShowDOBPicker(false);
                      }}
                    // onCancel={() => {
                    //   setShowDOBPicker(false);
                    // }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showFirstVisitPicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.2,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      First Visit Date
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowFirstVisitPicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 18,
                      textAlign: "right",
                      marginRight: 10,
                    }}
                  >
                    Date:
                  </Text>
                  <View
                    style={{
                      borderWidth: 0.5,
                      borderRadius: 5,
                      width: 100,
                    }}
                  >
                    <DatePicker
                      // isVisible={showFirstVisitPicker}
                      // mode={'date'}
                      selected={rev_FirstVisitDate.toDate()}
                      onChange={(date) => {
                        setRev_FirstVisitDate(moment(date));
                        setCustomerFirstVisitDate(moment(date));

                        // setShowFirstVisitPicker(false);
                      }}
                    // onCancel={() => {
                    //   setShowFirstVisitPicker(false);
                    // }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showLastVisitPicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.2,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      Last Visit Date
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowLastVisitPicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 18,
                      textAlign: "right",
                      marginRight: 10,
                    }}
                  >
                    Date:
                  </Text>
                  <View
                    style={{
                      borderWidth: 0.5,
                      borderRadius: 5,
                      width: 100,
                    }}
                  >
                    <DatePicker
                      // isVisible={showLastVisitPicker}
                      // mode={'date'}
                      selected={rev_LastVisitDate.toDate()}
                      onChange={(date) => {
                        setRev_LastVisitDate(moment(date));
                        setCustomerLastVisitDate(moment(date));

                        // setShowLastVisitPicker(false);
                      }}
                    // onCancel={() => {
                    //   setShowLastVisitPicker(false);
                    // }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          {/* ********Modals*********** */}

          {/* return button */}

          {/* return button */}

          <KeyboardAvoidingView>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: switchMerchant ? windowWidth * 0.8 : windowWidth * 0.87,
                marginHorizontal: switchMerchant ? 15 : 10,
                paddingHorizontal: switchMerchant ? 0 : 5,
                //padding: 10,
                //backgroundColor: 'red',
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "55%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TouchableOpacity
                  style={{
                    height: windowHeight * 0.055,
                    flexDirection: "row",
                    alignContent: "center",
                    alignItems: "center",
                    marginTop: 10,
                    marginLeft: 10,
                  }}
                  onPress={() => {
                    if (
                      pageStack.length > 1 &&
                      pageStack[pageStack.length - 2] === "Reservation"
                    ) {
                      props.navigation.navigate("Reservation");
                    } else {
                      props.navigation.navigate("CrmScreen");
                    }

                    setImage("");
                    clearImageContainer();
                  }}
                >
                  <Icon
                    name="chevron-left"
                    size={switchMerchant ? 20 : 30}
                    color={Colors.primaryColor}
                  />
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: switchMerchant ? 14 : 17,
                      color: Colors.primaryColor,
                      marginBottom: Platform.OS === "ios" ? 0 : 1,
                    }}
                  >
                    Back
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            {/****************List View Here****************/}
            <ScrollView
              showsVerticalScrollIndicator={false}
              keyboardShouldPersistTaps="handled"
              style={{ marginBottom: 10 }}
              contentContainerStyle={{
                paddingBottom: windowHeight * 0.1,
              }}
            >
              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  width: switchMerchant
                    ? windowWidth * 0.8
                    : windowWidth * 0.87,
                  // minHeight: windowHeight * 0.1,
                  //maxHeight: windowHeight * 0.825,
                  marginTop: 5,
                  marginHorizontal: switchMerchant ? 30 : 35,
                  //alignSelf: 'center',
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                  //paddingBottom: 20,
                  //backgroundColor: 'red'
                }}
              >
                {/* title */}
                <View
                  style={{
                    margin: 20,
                    justifyContent: "space-between",
                    flexDirection: "row",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontWeight: "700",
                      fontSize: 20,
                    }}
                  >
                    {""}
                  </Text>

                  <View
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <>
                      {isLoading ? (
                        <ActivityIndicator
                          style={
                            {
                              // marginLeft: 5,
                            }
                          }
                          color={Colors.primaryColor}
                          size={"large"}
                        />
                      ) : (
                        <>
                          <TouchableOpacity
                            style={{
                              justifyContent: "center",
                              flexDirection: "row",
                              borderWidth: 1,
                              borderColor: Colors.primaryColor,
                              backgroundColor: "#4E9F7D",
                              borderRadius: 5,
                              width: switchMerchant ? 120 : 160,
                              paddingHorizontal: 10,
                              height: switchMerchant ? 35 : 40,
                              alignItems: "center",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 1,
                              zIndex: -1,
                            }}
                            disabled={isLoading}
                            onPress={createCRMUser}
                          >
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                //marginLeft: 5,
                                fontSize: switchMerchant ? 10 : 16,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {isLoading ? "LOADING..." : "SAVE"}
                            </Text>

                            {isLoading ? (
                              <ActivityIndicator
                                style={{
                                  marginLeft: 5,
                                }}
                                color={Colors.whiteColor}
                                size={"small"}
                              />
                            ) : (
                              <></>
                            )}
                          </TouchableOpacity>

                          {selectedCustomerEdit ? (
                            <View
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <TouchableOpacity
                                style={{
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  borderWidth: 1,
                                  borderColor: Colors.primaryColor,
                                  backgroundColor: "#4E9F7D",
                                  borderRadius: 5,
                                  width: switchMerchant ? 120 : 160,
                                  paddingHorizontal: 10,
                                  height: switchMerchant ? 35 : 40,
                                  alignItems: "center",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.22,
                                  shadowRadius: 3.22,
                                  elevation: 1,
                                  zIndex: -1,
                                  marginVertical: 10,
                                }}
                                disabled={isLoading}
                                onPress={() => {
                                  // if (!selectedCustomerEdit.firebaseUid) {
                                  //   setEditMode(true);
                                  // }
                                  // else {
                                  //   Alert.alert('Error', 'Only able to edit profile of created user, not registered user.');
                                  // }

                                  setEditMode(!editMode);
                                }}
                              >
                                {/* Change to icon  */}
                                {/* <FontAwesome5 name='edit' size={23} color={Colors.primaryColor} style={{ marginLeft: 5, }} /> */}
                                <Text
                                  style={{
                                    color: Colors.whiteColor,
                                    //marginLeft: 5,
                                    fontSize: switchMerchant ? 10 : 16,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {isLoading ? "LOADING..." : "EDIT"}
                                </Text>

                                {isLoading ? (
                                  <ActivityIndicator
                                    style={{
                                      marginLeft: 5,
                                    }}
                                    color={Colors.whiteColor}
                                    size={"small"}
                                  />
                                ) : (
                                  <></>
                                )}
                              </TouchableOpacity>

                              <>
                                {selectedCustomerEdit &&
                                  selectedCustomerEdit.uniqueId ? (
                                  <TouchableOpacity
                                    onPress={() => {
                                      Alert.alert(
                                        "Info",
                                        `Are you sure you want to delete this user?`,
                                        [
                                          {
                                            text: "NO",
                                            onPress: () => { },
                                            style: "cancel",
                                          },
                                          {
                                            text: "YES",
                                            onPress: () => {
                                              deleteCRMUser();
                                            },
                                          },
                                        ],
                                        { cancelable: false }
                                      );
                                    }}
                                    style={{
                                      justifyContent: "center",
                                      flexDirection: "row",
                                      borderWidth: 1,
                                      borderColor: Colors.tabRed,
                                      backgroundColor: Colors.tabRed,
                                      borderRadius: 5,
                                      width: switchMerchant ? 120 : 160,
                                      paddingHorizontal: 10,
                                      height: switchMerchant ? 35 : 40,
                                      alignItems: "center",
                                      shadowOffset: {
                                        width: 0,
                                        height: 2,
                                      },
                                      shadowOpacity: 0.22,
                                      shadowRadius: 3.22,
                                      elevation: 1,
                                      zIndex: -1,
                                    }}
                                    disabled={isLoading}
                                  >
                                    <Text
                                      style={{
                                        color: Colors.whiteColor,
                                        //marginLeft: 5,
                                        fontSize: switchMerchant ? 10 : 16,
                                        fontFamily: "NunitoSans-Bold",
                                      }}
                                    >
                                      {isLoading ? "LOADING..." : "DELETE"}
                                    </Text>

                                    {isLoading ? (
                                      <ActivityIndicator
                                        style={{
                                          marginLeft: 5,
                                        }}
                                        color={Colors.whiteColor}
                                        size={"small"}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </TouchableOpacity>
                                ) : (
                                  <></>
                                )}
                              </>
                            </View>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  </View>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                    padding: 12,
                    paddingBottom: 30,
                    width: "100%",
                  }}
                >
                  <View style={{ width: "35%" }}>
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          width: switchMerchant ? 75 : 150,
                          height: switchMerchant ? 75 : 150,
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: Colors.fieldtBgColor,
                          zIndex: -1,
                          alignSelf: "center",
                          borderWidth: 1,
                          borderRadius: 100,
                          borderColor: "#E5E5E5",
                        }}
                      >
                        <View>
                          <TouchableOpacity onPress={openFileSelector}>
                            {image ? (
                              <View>
                                <AsyncImage
                                  style={{
                                    width: 150,
                                    height: 150,
                                    alignSelf: "center",
                                    borderRadius: 100,
                                  }}
                                  source={{
                                    uri: image,
                                  }}
                                  hideLoading={true}
                                  placeholderSource
                                />
                                <View
                                  style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: -10,
                                    //opacity: 0.5,
                                  }}
                                >
                                  <Icon
                                    name="edit"
                                    size={switchMerchant ? 12 : 23}
                                    color={Colors.primaryColor}
                                  />
                                </View>
                              </View>
                            ) : (
                              <View>
                                <Image
                                  style={{
                                    width: switchMerchant ? 75 : 150,
                                    height: switchMerchant ? 75 : 150,
                                    borderRadius: 100,
                                    alignSelf: "center",
                                  }}
                                  source={require("../assets/image/profile-pic.jpg")}
                                  hideLoading={true}
                                />
                                <View
                                  style={{
                                    position: "absolute",
                                    bottom: 5,
                                    right: -10,
                                    //opacity: 0.5,
                                  }}
                                >
                                  <Icon
                                    name="edit"
                                    size={switchMerchant ? 12 : 23}
                                    color={Colors.primaryColor}
                                  />
                                </View>
                                <Icon
                                  name="upload"
                                  size={switchMerchant ? 50 : 100}
                                  color="lightgrey"
                                  style={{
                                    top: switchMerchant ? 10 : 23,
                                    position: "absolute",
                                    alignSelf: "center",
                                    zIndex: -1,
                                  }}
                                />
                              </View>
                            )}
                          </TouchableOpacity>
                        </View>
                      </View>

                      {/* <TouchableOpacity onPress={() => {
                    handleChoosePhoto();
                  }}
                    style={{
                      marginVertical: 10,
                      marginTop: 15,
                    }}>
                    <Icon name='camera' size={20} style={{
                      // marginLeft: 5
                    }} />
                  </TouchableOpacity> */}
                    </View>

                    {/* <Text style={{ marginTop: 0,alignSelf: 'center',fontSize: 16, color: "black", fontFamily: 'NunitoSans-Bold'}}>
                      Abu Bakarlala
                    </Text> */}

                    {/* <TextInput
                  editable={selectedCustomerEdit === null || editMode}
                  style={{ marginTop: 0, alignSelf: 'center', textAlign: 'center', fontSize: 16, color: "black", fontFamily: 'NunitoSans-Bold', width: 150 }}
                  placeholder='Customer Name'
                  onChangeText={(text) => {
                    setCustomerName(text);
                  }}
                  defaultValue={customerName}
                //value={}
                /> */}

                    {/* <Text style={{ marginTop: 10,alignSelf: 'center', fontSize: 14, color: Colors.primaryColor, fontFamily: 'NunitoSans-Bold'}}>
                      +60 12 333 3333
                    </Text> */}
                    {/* {editMode ?
                  <TextInput
                    editable={selectedCustomerEdit === null || editMode}
                    style={{ marginTop: 10, alignSelf: 'center', textAlign: 'center', fontSize: 14, color: Colors.primaryColor, fontFamily: 'NunitoSans-Bold', width: 150 }}
                    placeholder='Contact Number'
                    onChangeText={(text) => {
                      if (!/^[0-9]+$/.test(text)) {
                        Alert.alert('Error', 'Please enter number only.');
                      }
                      else {
                        setCustomerPhone(text);
                      }
                    }}
                    //value={}
                    keyboardType={'number-pad'}
                    defaultValue={customerPhone}
                  />
                  :
                  <View style={{
                    marginTop: 10,
                    alignSelf: 'center', width: 150
                  }}
                  >
                    <TouchableOpacity onPress={() => { Linking.openURL(`tel:${customerPhone}`) }}>
                      <Text style={{ textAlign: 'center', fontSize: 14, color: Colors.primaryColor, fontFamily: 'NunitoSans-Bold', }}>
                        {customerPhone}
                      </Text>
                    </TouchableOpacity>
                  </View>
                } */}

                    {/* <View style={{ flexDirection: 'row', marginTop: 10, alignSelf: 'center', }}>
                  <Text style={{ fontSize: 15, color: '#9FA2B4', fontWeight: '700', fontFamily: 'Nunitosans-Regular', }}>
                    Username:
                  </Text>

                  <TextInput
                    editable={selectedCustomerEdit === null}
                    style={{ fontSize: 15, color: "black", fontWeight: '600', fontFamily: 'Nunitosans-Regular', marginLeft: 5 }}
                    placeholder='Username'
                    onChangeText={(text) => {
                      setCustomerUsername(text);
                    }}
                    defaultValue={customerUsername}
                  //value={}
                  />
                </View> */}

                    {/* <View style={{ flexDirection: 'row', marginTop: 20, width: '100%', alignItems: 'center', marginLeft: 10, }}>
                  <Text style={{ fontSize: 14, color: '#9FA2B4', fontWeight: '700', fontFamily: 'Nunitosans-Regular', width: '23%' }}>
                    Next Visit:
                  </Text>
                  <View style={{ width: '65%', marginLeft: 5 }}>
                    <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', width: 100, justifyContent: 'space-between', }}
                      disabled={selectedCustomerEdit !== null}
                      onPress={() => { setShowNextVisitPicker(true); }}
                    >
                      <Text >
                        {customerNextVisitDate ? moment(customerNextVisitDate).format('DD/MM/YYYY') : 'N/A'}
                      </Text>

                      {
                        selectedCustomerEdit === null
                          ?
                          <EvilIcons name='calendar' size={25} style={{ marginLeft: 10, color: Colors.primaryColor }} />
                          :
                          <></>
                      }
                    </TouchableOpacity>
                  </View>

                  <View style={{ width: '65%', marginLeft: 5 }}>
                    <TouchableOpacity style={{ flexDirection: 'row', alignItems: 'center', width: 100, justifyContent: 'space-between', }}
                      disabled={selectedCustomerEdit !== null}
                      onPress={() => { setShowNextVisitTimePicker(true);}}
                    >

                      <Text>{ customerNextVisitTime ? moment(customerNextVisitTime).format('hh:mm a') : 'N/A'}</Text>

                      {
                        selectedCustomerEdit === null
                          ?
                          <EvilIcons name='calendar' size={25} style={{ marginLeft: 10, color: Colors.primaryColor }} />
                          :
                          <></>
                      }
                    </TouchableOpacity>
                  </View>
                </View> */}
                    {/* flexDirection: 'row', marginTop: 20, width: '100%', alignItems: 'center', marginLeft: 10, */}
                    {/* {
                  selectedCustomerEdit
                    ?
                    <View style={{ height: '20%' }} />
                    :
                    <View style={{ height: '23.7%' }} />
                } */}
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                        marginLeft: 0,
                        marginVertical: 25,
                        marginBottom: 0,
                        zIndex: -1,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          width: "100%",
                          marginLeft: 10,
                          maxHeight: 100,
                          alignItems:
                            userTagList.length > 0 ? "flex-start" : "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 14,
                            color: "#9FA2B4",
                            fontFamily: "Nunitosans-Bold",
                            marginRight: 5,
                            marginTop: 0,
                          }}
                        >
                          Tags
                        </Text>
                        <View
                          style={{
                            width: "90%",
                            justifyContent: "space-between",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: switchMerchant
                              ? userTagList.length > 0
                                ? -windowHeight * 0.015
                                : windowHeight * 0.001
                              : userTagList.length > 0
                                ? -windowHeight * 0.007
                                : windowHeight * 0.00009,
                            // borderWidth: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 14,
                              fontFamily: "Nunitosans-Regular",
                              color: "black",
                              fontFamily: "Nunitosans-Regular",
                              flex: 9,
                            }}
                          >
                            {userTagList.length > 0 ? (
                              <View
                                maxHeight={100}
                                style={{
                                  minHeight: 40,
                                  maxHeight: 100,
                                }}
                              >
                                <ScrollView
                                  style={{
                                    zIndex: -1,
                                  }}
                                  horizontal={true}
                                  showsHorizontalScrollIndicator={false}
                                  showsVerticalScrollIndicator={false}
                                >
                                  <FlatList
                                    showsVerticalScrollIndicator={false}
                                    data={userTagList}
                                    numColumns={3}
                                    renderItem={renderConfirmUserTag}
                                    keyExtractor={(item, index) =>
                                      String(index)
                                    }
                                    style={{
                                      paddingVertical: 5,
                                      paddingHorizontal: 5,
                                      // marginHorizontal: 50
                                      //backgroundColor: 'blue',
                                      //width: 10,
                                    }}
                                  //horizontal={true}
                                  />
                                </ScrollView>
                              </View>
                            ) : (
                              <View>
                                <Text
                                  style={{
                                    fontFamily: "Nunitosans-Regular",
                                    color: "black",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                >
                                  No tags yet.
                                </Text>
                              </View>
                            )}
                          </Text>

                          {selectedCustomerEdit !== null ? (
                            <TouchableOpacity
                              style={{
                                marginLeft: windowWidth * 0.005,
                                alignSelf: "flex-start",
                                marginTop: switchMerchant
                                  ? windowHeight * 0.015
                                  : windowHeight * 0.005,
                                flex: 1,
                              }}
                              onPress={() => {
                                setTagModal(true);
                              }}
                            >
                              <AntDesign
                                name="tagso"
                                size={switchMerchant ? 12 : 24}
                                style={{ color: Colors.primaryColor }}
                              />
                            </TouchableOpacity>
                          ) : (
                            <></>
                          )}
                        </View>
                      </View>
                    </View>

                    {/* ***************Border Line**************  */}
                    {/* <View style={{ borderWidth: 0.5, borderColor: '#E5E5E5', marginTop: 15, alignItems: 'center', }} />

                <View style={{ flexDirection: 'row', marginTop: 10, marginBottom: 10, alignItems: 'center', width: '100%', marginLeft: 10 }}>
                  <Text style={{ fontSize: 14, color: '#9FA2B4', fontWeight: '700', fontFamily: 'Nunitosans-Regular', width: '23%' }}>
                    Status:
                  </Text>
                  <View style={{ width: '65%', flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{}}>
                      <Switch
                        onSyncPress={(value) => {
                          setCustomerIsActiveMember(value);
                        }}
                        style={{ transform: [{ scaleX: 0.9 }, { scaleY: 0.85 }] }}
                        value={customerIsActiveMember}
                        width={42}
                        circleColorActive={Colors.primaryColor}
                        circleColorInactive={Colors.fieldtTxtColor}
                        backgroundActive={'#dddddd'}
                      />
                    </View>
                    <Text style={{ fontSize: 13, fontWeight: '500', marginLeft: 10, textAlign: 'center', color: customerIsActiveMember ? Colors.primaryColor : Colors.fieldtTxtColor }}>
                      {customerIsActiveMember ? 'Active Member' : 'Inactive Member'}
                    </Text>
                  </View>
                </View> */}

                    {/* ***************Border Line**************  */}
                    {/* <View style={{ borderWidth: 0.5, borderColor: '#E5E5E5', marginTop: 5, marginBottom: 5 }} />

                <View style={{ flexDirection: 'row', marginTop: 10, marginBottom: 10, width: '100%' }}>
                  <Text style={{ fontSize: 14, color: '#9FA2B4', fontWeight: '700', fontFamily: 'Nunitosans-Regular', width: '20%' }}>
                    Package:
                  </Text>
                  <View style={{ width: '80%', flexDirection: 'row' }}>
                    <View style={{ marginLeft: 0 }}>
                      <Text style={{
                        fontSize: 13,
                        fontWeight: '500',
                        textAlign: 'center',
                        backgroundColor: '#F2C94C',
                        color: '#FFFFFF',
                        marginBottom: 3,
                      }}>
                        Package A
                      </Text>

                      <Text style={{
                        fontSize: 13,
                        fontWeight: '500',
                        textAlign: 'center',
                        padding: 1,
                        color: 'green',
                      }}>
                        *Instalment Plan
                      </Text>
                    </View>
                    <View style={{ marginLeft: 10 }}>
                      <Text style={{ fontSize: 13, color: "black", fontFamily: 'Nunitosans-Regular', }}>
                        Redemption:
                      </Text>
                      <Text style={{ fontSize: 13, color: "black", fontFamily: 'Nunitosans-Regular', }}>
                        05/10
                      </Text>
                    </View>


                    <View style={{ marginLeft: 10 }}>
                      <Text style={{ fontSize: 13, color: "black", fontFamily: 'Nunitosans-Regular', }}>
                        Last redemption:
                      </Text>
                      <Text style={{ fontSize: 13, color: "black", fontFamily: 'Nunitosans-Regular', }}>
                        12 Nov 2020
                      </Text>
                    </View>
                  </View>
                </View> */}

                    {/* ***************Border Line**************  */}
                    <View
                      style={{
                        borderWidth: 0.5,
                        borderColor: "#E5E5E5",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    />
                    <View
                      style={{
                        flexDirection: "column",
                        width: "100%",
                        marginLeft: 10,
                        marginVertical: 20,
                        marginBottom: 20,
                        zIndex: -1,
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          marginBottom: 20,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 14,
                            color: "#9FA2B4",
                            fontFamily: "Nunitosans-Bold",
                            width: "45%",
                          }}
                        >
                          Next Visit Date
                        </Text>
                        <TouchableOpacity
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginLeft: 5,
                            width: "50%",
                          }}
                          // disabled={selectedCustomerEdit !== null}
                          onPress={() => {
                            setShowNextVisitPicker(true);
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Regular",
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                          >
                            {rev_NextVisitDate
                              ? moment(rev_NextVisitDate).format("DD/MM/YYYY")
                              : "N/A"}
                          </Text>

                          {
                            // selectedCustomerEdit === null
                            true ? (
                              <GCalendar
                                width={switchMerchant ? 10 : 22}
                                height={switchMerchant ? 10 : 22}
                              />
                            ) : (
                              <></>
                            )
                          }
                        </TouchableOpacity>
                      </View>

                      <View style={{ width: "100%", flexDirection: "row", alignItems: 'center', }}>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 14,
                            color: "#9FA2B4",
                            fontFamily: "Nunitosans-Bold",
                            width: "45%",
                          }}
                        >
                          Next Visit Time
                        </Text>
                        <TouchableOpacity
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "50%",
                            justifyContent: "space-between",
                            marginLeft: 5,
                          }}
                          // disabled={selectedCustomerEdit !== null}
                          onPress={() => {
                            setShowNextVisitTimePicker(true);
                            // moment(rev_time).format("hh:mm a")
                            // if(rev_time === )
                            // console.log('rev_time: ', rev_time);
                            // console.log('moment(rev_time).format("hh:mm a"): ', moment(rev_time).format("hh:mm a"));
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Regular",
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                          >
                            {rev_time
                              ? moment(rev_time, "HH:mm A").format("HH:mm A")
                              : "N/A"}
                          </Text>

                          {
                            // selectedCustomerEdit === null
                            true ? (
                              <GCalendar
                                width={switchMerchant ? 10 : 22}
                                height={switchMerchant ? 10 : 22}
                              />
                            ) : (
                              <></>
                            )
                          }
                        </TouchableOpacity>
                      </View>
                    </View>

                    {/* ***************Border Line**************  */}
                    <View
                      style={{
                        borderWidth: 0.5,
                        borderColor: "#E5E5E5",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    />
                    <View
                      style={{
                        flexDirection: "column",
                        width: "100%",
                        marginLeft: 10,
                        marginVertical: 20,
                        marginBottom: 20,
                        zIndex: -1,
                      }}
                    >
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          marginBottom: 20,
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "Nunitosans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            color: "#9FA2B4",
                            width: "45%",
                          }}
                        >
                          First Visit (Duration)
                        </Text>
                        <TouchableOpacity
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "50%",
                            justifyContent: "space-between",
                            marginLeft: 5,
                          }}
                          disabled={selectedCustomerEdit !== null}
                          onPress={() => {
                            setShowFirstVisitPicker(true);
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Regular",
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                          >
                            {rev_FirstVisitDate
                              ? moment(rev_FirstVisitDate).format("DD MMM YYYY")
                              : "N/A"}
                          </Text>

                          {rev_FirstVisitDate ? (
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: 10,
                                    marginLeft: 5,
                                  }
                                  : {
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: 14,
                                    marginLeft: 5,
                                  }
                              }
                            >
                              {`(${moment().diff(
                                rev_FirstVisitDate,
                                "month"
                              )} month${moment().diff(rev_FirstVisitDate, "month") > 1
                                ? "s"
                                : ""
                                })`}
                            </Text>
                          ) : (
                            <></>
                          )}

                          {selectedCustomerEdit === null ? (
                            <GCalendar
                              width={switchMerchant ? 10 : 22}
                              height={switchMerchant ? 10 : 22}
                            />
                          ) : (
                            <></>
                          )}
                        </TouchableOpacity>
                      </View>

                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 14,
                            color: "#9FA2B4",
                            fontFamily: "Nunitosans-Bold",
                            width: "45%",
                          }}
                        >
                          Last Visit
                        </Text>
                        <TouchableOpacity
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "50%",
                            justifyContent: "space-between",
                            marginLeft: 5,
                          }}
                          disabled={selectedCustomerEdit !== null}
                          onPress={() => {
                            setShowLastVisitPicker(true);
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Regular",
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                          >
                            {rev_LastVisitDate
                              ? moment(rev_LastVisitDate).format("DD MMM YYYY")
                              : "N/A"}
                          </Text>

                          {selectedCustomerEdit === null ? (
                            <GCalendar
                              width={switchMerchant ? 10 : 22}
                              height={switchMerchant ? 10 : 22}
                            />
                          ) : (
                            <></>
                          )}
                        </TouchableOpacity>
                      </View>
                    </View>

                    <View
                      style={{
                        borderWidth: 0.5,
                        borderColor: "#E5E5E5",
                        marginTop: 5,
                        marginBottom: 5,
                      }}
                    />
                  </View>

                  <View style={{ width: "65%", marginLeft: 10 }}>
                    <View>
                      <View style={{ flexDirection: "column", marginLeft: 5 }}>
                        <View style={{ flexDirection: "row", width: "100%" }}>
                          <View
                            style={{
                              flexDirection: "row",
                              width: "38%",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontFamily: "Nunitosans-Bold",
                              }}
                            >
                              Name
                            </Text>
                            <TextInput
                              editable={selectedCustomerEdit === null}
                              style={{
                                backgroundColor: Colors.fieldtBgColor,
                                width: switchMerchant ? 100 : 160,
                                height: switchMerchant ? 30 : 35,
                                borderRadius: 5,
                                padding: 5,
                                marginVertical: 5,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                paddingLeft: 10,
                                marginLeft: 5,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder="Username"
                              placeholderStyle={{
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              onChangeText={(text) => {
                                setCustomerUsername(text);
                              }}
                              defaultValue={customerName}
                            //value={}
                            />
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              width: "30%",
                              alignItems: "center",
                              marginLeft: 5,
                              // borderWidth: 1,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontFamily: "Nunitosans-Bold",
                                marginRight: 5
                              }}
                            >
                              Gender
                            </Text>
                            {/* <Picker
                              containerStyle={{
                                height: switchMerchant ? 30 : 35,
                                zIndex: 2,
                                marginLeft: 5,
                                paddingLeft: 5,
                              }}
                              arrowColor={"black"}
                              arrowSize={switchMerchant ? 10 : 20}
                              arrowStyle={{ fontWeight: "bold" }}
                              labelStyle={{
                                fontFamily: "NunitoSans-Regular",
                                color: Colors.fieldtTxtColor,
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              style={{
                                width: switchMerchant ? 70 : 100,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                marginLeft: 10,
                                paddingLeft: 10,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderStyle={{
                                color: Colors.fieldtTxtColor,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              disabled={
                                !(
                                  selectedCustomerEdit === null ||
                                  (editMode &&
                                    selectedCustomerEdit &&
                                    selectedCustomerEdit.firebaseUid ===
                                      undefined)
                                )
                              }
                              itemStyle={{
                                justifyContent: "flex-start",
                                marginLeft: 5,
                                zIndex: 2,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder={" Gender"}
                              onValueChange={(item) => {
                                setCustomerGender(item.value);
                              }}
                              defaultValue={customerGender || "Others"}
                              dropDownMaxHeight={150}
                              dropDownStyle={{
                                fontSize: switchMerchant ? 10 : 16,
                                width: switchMerchant ? 70 : 100,
                                height: switchMerchant ? 100 : 125,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                borderWidth: 1,
                                textAlign: "left",
                                zIndex: 2,
                              }}
                              // style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 30, width: 100, paddingVertical: 0, marginHorizontal: 10, marginLeft: 5 }}
                              //   disabled={!(selectedCustomerEdit === null || (editMode && (selectedCustomerEdit && selectedCustomerEdit.firebaseUid === undefined)))}
                              //   dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 100, paddingVertical: 0, marginHorizontal: 10 }}
                              //   arrowSize={17}
                              //   arrowColor={'black'}
                              //   arrowStyle={{ paddingVertical: 0 }}
                              //   itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                              //   placeholderStyle={{ marginLeft: 0 }}
                              //   placeholder={" Gender"}
                              //   items={[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }, { label: 'Others', value: 'Others' }]}
                              //   onChangeItem={(item) => {
                              //     //console.log(item);
                              //     setCustomerGender(item.value);
                              //   }}
                              //   defaultValue={customerGender || 'Others'}
                            >
                              {genderOptions.map((value, index) => {
                                return (
                                  <Picker.Item
                                    label={value.label}
                                    value={value.value}
                                  />
                                );
                              })}
                            </Picker> */}
                            <Select
                              disabled={
                                !(
                                  selectedCustomerEdit === null ||
                                  (editMode &&
                                    selectedCustomerEdit &&
                                    selectedCustomerEdit.firebaseUid ===
                                    undefined)
                                )
                              }
                              onChange={(item) => {
                                setCustomerGender(item.value);
                              }}
                              defaultValue={customerGender || 'Others'}
                              placeholder={" Gender"}
                              options={genderOptions}
                            />
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              width: "30%",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontFamily: "Nunitosans-Bold",
                              }}
                            >
                              Race
                            </Text>
                            <TextInput
                              editable={
                                selectedCustomerEdit === null ||
                                (editMode &&
                                  selectedCustomerEdit &&
                                  selectedCustomerEdit.firebaseUid ===
                                  undefined)
                              }
                              style={{
                                backgroundColor: Colors.fieldtBgColor,
                                width: switchMerchant ? 80 : 120,
                                height: switchMerchant ? 30 : 35,
                                borderRadius: 5,
                                padding: 5,
                                marginVertical: 5,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                paddingLeft: 10,
                                marginLeft: 5,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder={"Race"}
                              placeholderStyle={{
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              onChangeText={(text) => {
                                setCustomerRace(text);
                              }}
                            />
                          </View>
                        </View>

                        {/* ***************Border Line**************  */}
                        <View
                          style={{
                            borderWidth: 0.5,
                            borderColor: "#E5E5E5",
                            marginVertical: 25,
                            zIndex: -2,
                          }}
                        />

                        <View
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            alignItems: "center",
                            marginBottom: 20,
                            zIndex: -2,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              width: "50%",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontFamily: "Nunitosans-Bold",
                              }}
                            >
                              Contact
                            </Text>
                            {editMode || !selectedCustomerEdit ? (
                              <TextInput
                                editable={
                                  selectedCustomerEdit === null ||
                                  (editMode &&
                                    selectedCustomerEdit &&
                                    selectedCustomerEdit.firebaseUid ===
                                    undefined)
                                }
                                style={{
                                  backgroundColor: Colors.fieldtBgColor,
                                  width: "55%",
                                  height: switchMerchant ? 30 : 35,
                                  borderRadius: 5,
                                  padding: 5,
                                  marginVertical: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  paddingLeft: 10,
                                  marginLeft: 5,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholder="Contact Number"
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                onChangeText={(text) => {
                                  if (!/^[0-9]+$/.test(text)) {
                                    Alert.alert(
                                      "Error",
                                      "Please enter numbers only"
                                    );
                                  } else {
                                    setCustomerPhone(text);
                                  }
                                }}
                                //value={}
                                keyboardType={"number-pad"}
                                defaultValue={customerPhone}
                              />
                            ) : (
                              <View style={{ marginLeft: 5, width: "50%" }}>
                                <TouchableOpacity
                                  onPress={() => {
                                    Linking.openURL(`tel:${customerPhone}`);
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: switchMerchant ? 10 : 14,
                                      color: Colors.primaryColor,
                                      fontFamily: "NunitoSans-Bold",
                                      marginLeft: 5,
                                    }}
                                  >
                                    {customerPhone}
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            )}
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              width: "50%",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontFamily: "Nunitosans-Bold",
                                width: "23%",
                              }}
                            >
                              DOB
                            </Text>
                            <TouchableOpacity
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                width: "70%",
                                justifyContent: "space-between",
                              }}
                              disabled={
                                !(
                                  selectedCustomerEdit === null ||
                                  (editMode &&
                                    selectedCustomerEdit &&
                                    selectedCustomerEdit.firebaseUid ===
                                    undefined)
                                )
                              }
                              onPress={() => {
                                setShowDOBPicker(true);
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                {rev_DOBdate
                                  ? moment(rev_DOBdate).format("DD/MM/YYYY")
                                  : "N/A"}
                              </Text>

                              {selectedCustomerEdit === null ||
                                (editMode &&
                                  selectedCustomerEdit &&
                                  selectedCustomerEdit.firebaseUid ===
                                  undefined) ? (
                                // <EvilIcons name='calendar' size={25} style={{ color: Colors.primaryColor }} />
                                <GCalendar
                                  width={switchMerchant ? 10 : 20}
                                  height={switchMerchant ? 10 : 20}
                                />
                              ) : (
                                <></>
                              )}
                            </TouchableOpacity>
                          </View>
                        </View>

                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontFamily: "Nunitosans-Bold",
                              }}
                            >
                              Contact
                            </Text>
                            {editMode || !selectedCustomerEdit ? (
                              <TextInput
                                editable={
                                  selectedCustomerEdit === null || editMode
                                }
                                style={{
                                  backgroundColor: Colors.fieldtBgColor,
                                  width: "55%",
                                  height: switchMerchant ? 30 : 35,
                                  borderRadius: 5,
                                  padding: 5,
                                  marginVertical: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  paddingLeft: 10,
                                  marginLeft: 5,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholder="Contact Number"
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                onChangeText={(text) => {
                                  if (!/^[0-9]+$/.test(text)) {
                                    Alert.alert(
                                      "Error",
                                      "Please enter numbers only"
                                    );
                                  } else {
                                    setCustomerPhoneSecond(text);
                                  }
                                }}
                                //value={}
                                keyboardType={"number-pad"}
                                defaultValue={customerPhoneSecond}
                              />
                            ) : (
                              <View style={{ marginLeft: 5, width: "50%" }}>
                                <TouchableOpacity
                                  onPress={() => {
                                    Linking.openURL(`tel:${customerPhone}`);
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: switchMerchant ? 10 : 14,
                                      color: Colors.primaryColor,
                                      fontFamily: "NunitoSans-Regular",
                                      marginLeft: 5,
                                    }}
                                  >
                                    {customerPhoneSecond}
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            )}
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontWeight: "700",
                                fontFamily: "Nunitosans-Bold",
                                width: "23%",
                              }}
                            >
                              Status
                            </Text>
                            <View
                              style={{
                                width: "72%",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <View style={{}}>
                                <Switch
                                  onChange={(value) => {
                                    setCustomerIsActiveMember(value);
                                  }}
                                  checked={customerIsActiveMember}
                                  width={35}
                                  height={20}
                                  handleDiameter={30}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                />
                              </View>
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontWeight: "500",
                                  marginLeft: 10,
                                  textAlign: "center",
                                  color: customerIsActiveMember
                                    ? Colors.primaryColor
                                    : Colors.fieldtTxtColor,
                                }}
                              >
                                {customerIsActiveMember ? "Active" : "Inactive"}
                              </Text>
                            </View>
                          </View>
                        </View>

                        {/* ***************Border Line**************  */}
                        <View
                          style={{
                            borderWidth: 0.5,
                            borderColor: "#E5E5E5",
                            marginVertical: 25,
                            zIndex: -1,
                          }}
                        />

                        <View
                          style={{
                            flexDirection: "row",
                            width: "60%",
                            alignItems: "center",
                            marginBottom: 20,
                            // borderWidth:1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 14,
                              color: "#9FA2B4",
                              fontFamily: "Nunitosans-Bold",
                              width: "8%",

                              // borderWidth:1,
                            }}
                          >
                            Email 1
                          </Text>
                          {!selectedCustomerEdit ||
                            (editMode &&
                              selectedCustomerEdit &&
                              selectedCustomerEdit.firebaseUid === undefined) ? (
                            <TextInput
                              //editable={selectedCustomerEdit === null}
                              style={{
                                backgroundColor: Colors.fieldtBgColor,
                                width: "65%",
                                // borderWidth: 1,
                                height: switchMerchant ? 30 : 35,
                                borderRadius: 5,
                                padding: 5,
                                marginVertical: 5,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                paddingLeft: 10,
                                marginLeft: 5,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder="email@gmail.com"
                              placeholderStyle={{
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              onChangeText={(text) => {
                                setCustomerEmail(text);
                              }}
                              defaultValue={customerEmail}
                            //value={}
                            />
                          ) : (
                            <View
                              style={{
                                paddingBottom: 2,
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  Linking.openURL(`mailto:${customerEmail}`);
                                }}
                              >
                                <Text
                                  style={switchMerchant ? { fontSize: 10 } : { fontSize: 14 }}
                                >
                                  {customerEmail}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "60%",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 14,
                              color: "#9FA2B4",
                              fontFamily: "Nunitosans-Bold",
                              width: "8%",
                            }}
                          >
                            Email 2
                          </Text>
                          {!selectedCustomerEdit || editMode ? (
                            <TextInput
                              //editable={selectedCustomerEdit === null}
                              style={{
                                backgroundColor: Colors.fieldtBgColor,
                                width: "65%",
                                height: switchMerchant ? 30 : 35,
                                borderRadius: 5,
                                padding: 5,
                                marginVertical: 5,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                paddingLeft: 10,
                                marginLeft: 5,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder="email@gmail.com"
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              onChangeText={(text) => {
                                setCustomerEmailSecond(text);
                              }}
                              defaultValue={customerEmailSecond}
                            //value={}
                            />
                          ) : (
                            <View
                              style={{ paddingBottom: 2, }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  Linking.openURL(`mailto:${customerEmail}`);
                                }}
                              >
                                <Text
                                  style={{
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                >
                                  {customerEmailSecond}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          )}
                        </View>

                        {/* ***************Border Line**************  */}
                        <View
                          style={{
                            borderWidth: 0.5,
                            borderColor: "#E5E5E5",
                            marginVertical: 25,
                            marginBottom: 25,
                            zIndex: -1,
                          }}
                        />

                        <View
                          style={{
                            flexDirection: "row",
                            marginBottom: 15,
                            alignItems:
                              selectedCustomerEdit === null ||
                                (editMode &&
                                  selectedCustomerEdit &&
                                  selectedCustomerEdit.firebaseUid === undefined)
                                ? "flex-start"
                                : "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 14,
                              color: "#9FA2B4",
                              fontFamily: "Nunitosans-Bold",
                              paddingTop:
                                selectedCustomerEdit === null ||
                                  (editMode &&
                                    selectedCustomerEdit &&
                                    selectedCustomerEdit.firebaseUid ===
                                    undefined)
                                  ? 8
                                  : 0,
                            }}
                          >
                            Address 1
                          </Text>

                          {selectedCustomerEdit === null ||
                            (editMode &&
                              selectedCustomerEdit &&
                              selectedCustomerEdit.firebaseUid === undefined) ? (
                            <Autocomplete
                              // placeholder="📍  Outlet address"
                              placeholder={customerAddress}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              minLength={2} // minimum length of text to search
                              autoFocus={false}
                              textInputProps={{ placeholderTextColor: "black" }}
                              returnKeyType={"search"} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
                              listViewDisplayed="false" // true/false/undefined
                              fetchDetails={true}
                              renderDescription={(row) => renderSearch(row)} // custom description render
                              onPlaceSelected={(
                                place
                              ) => {
                                // 'place' is provided when fetchDetails = true
                                console.log("place", place);

                                setCustomerAddress(place.formatted_address);
                                setCustomerAddressLat(
                                  place.geometry.location.lat()
                                );
                                setCustomerAddressLng(
                                  place.geometry.location.lng()
                                );
                              }}
                              options={{
                                types: ["address"],
                                componentRestrictions: { country: "my" },
                              }}
                              defaultValue={customerAddress}
                              // api cannot use
                              apiKey={"AIzaSyAt1AOIT_1E5Ivec7PVWmylmDd8jSVm-j8"}
                              style={{
                                backgroundColor: Colors.fieldtBgColor,
                                width: "80%",
                                height: switchMerchant ? 30 : 35,
                                borderRadius: 5,
                                padding: 5,
                                marginVertical: 5,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                paddingLeft: 10,
                                marginLeft: 5,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              enablePoweredByContainer={false}
                              //currentLocation={false}
                              currentLocationLabel="Current location"
                              nearbyPlacesAPI="GooglePlacesSearch"
                              GoogleReverseGeocodingQuery={{}}
                              GooglePlacesSearchQuery={{
                                rankby: "distance",
                              }}
                              filterReverseGeocodingByTypes={[
                                "locality",
                                "administrative_area_level_3",
                              ]}
                              debounce={200}
                              keepResultsAfterBlur={true}
                            />
                          ) : (
                            <TextInput
                              editable={false}
                              style={[
                                {
                                  marginLeft: 9,
                                  width: "80%",
                                  // borderWidth: 1,
                                  paddingTop: 0,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                },
                                // !switchMerchant
                                //   ? {
                                //       // backgroundColor: 'white',
                                //       paddingTop:
                                //         Platform.OS === "ios"
                                //           ? 0
                                //           : windowHeight * 0.013,
                                //     }
                                //   : {},
                              ]}
                              multiline={true}
                              placeholder="N/A"
                              placeholderStyle={{
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              onChangeText={(text) => {
                                setCustomerAddress(text);
                              }}
                              defaultValue={customerAddress}
                            />
                          )}

                          {/* <TextInput
                        editable={selectedCustomerEdit === null || editMode}
                        style={{ marginLeft: 9 }}
                        placeholder='Address'
                        onChangeText={(text) => {
                          setCustomerAddress(text);
                        }}
                        defaultValue={customerAddress}
                      //value={}
                      /> */}
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            alignItems:
                              selectedCustomerEdit === null ||
                                (editMode &&
                                  selectedCustomerEdit &&
                                  selectedCustomerEdit.firebaseUid === undefined)
                                ? "flex-start"
                                : "center",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 14,
                              color: "#9FA2B4",
                              fontFamily: "Nunitosans-Bold",
                              paddingTop:
                                selectedCustomerEdit === null ||
                                  (editMode &&
                                    selectedCustomerEdit &&
                                    selectedCustomerEdit.firebaseUid ===
                                    undefined)
                                  ? 8
                                  : 0,
                            }}
                          >
                            Address 2
                          </Text>

                          {selectedCustomerEdit === null || editMode ? (
                            <Autocomplete
                              // placeholder="📍  Outlet address"
                              placeholder={customerAddressSecond}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              minLength={2} // minimum length of text to search
                              autoFocus={false}
                              textInputProps={{ placeholderTextColor: "black" }}
                              returnKeyType={"search"} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
                              listViewDisplayed="false" // true/false/undefined
                              fetchDetails={true}
                              renderDescription={(row) => renderSearch(row)} // custom description render
                              onPlaceSelected={(
                                place
                              ) => {
                                // 'place' is provided when fetchDetails = true
                                console.log("place", place);

                                setCustomerAddressSecond(
                                  place.formatted_address
                                );
                                setCustomerAddressLatSecond(
                                  place.geometry.location.lat()
                                );
                                setCustomerAddressLngSecond(
                                  place.geometry.location.lng()
                                );
                              }}
                              options={{
                                types: ["address"],
                                componentRestrictions: { country: "my" },
                              }}
                              defaultValue={customerAddressSecond}

                              // api cannot use
                              apiKey={"AIzaSyAt1AOIT_1E5Ivec7PVWmylmDd8jSVm-j8"}
                              style={{
                                backgroundColor: Colors.fieldtBgColor,
                                width: "80%",
                                height: switchMerchant ? 30 : 35,
                                borderRadius: 5,
                                padding: 5,
                                marginVertical: 5,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                paddingLeft: 10,
                                marginLeft: 5,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              enablePoweredByContainer={false}
                              //currentLocation={false}
                              currentLocationLabel="Current location"
                              nearbyPlacesAPI="GooglePlacesSearch"
                              GoogleReverseGeocodingQuery={{}}
                              GooglePlacesSearchQuery={{
                                rankby: "distance",
                              }}
                              filterReverseGeocodingByTypes={[
                                "locality",
                                "administrative_area_level_3",
                              ]}
                              debounce={200}
                              keepResultsAfterBlur={true}
                            />
                          ) : (
                            <TextInput
                              editable={false}
                              style={[
                                {
                                  marginLeft: 9,
                                  width: "80%",
                                  paddingTop: 0,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                },
                                // !switchMerchant
                                //   ? {
                                //       // backgroundColor: 'white',
                                //       paddingTop:
                                //         Platform.OS === "ios"
                                //           ? 0
                                //           : windowHeight * 0.013,
                                //     }
                                //   : {},
                              ]}
                              multiline={true}
                              placeholder="N/A"
                              placeholderStyle={{
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              // onChangeText={(text) => {
                              //   setCustomerAddress(text);
                              // }}
                              defaultValue={customerAddressSecond}
                            />
                          )}

                          {/* <TextInput
                          editable={selectedCustomerEdit === null || editMode}
                          style={{ marginLeft: 9 }}
                          placeholder='Address'
                          onChangeText={(text) => {
                            setCustomerAddress(text);
                          }}
                          defaultValue={customerAddress}
                        //value={}
                        /> */}
                        </View>

                        {/* ***************Border Line**************  */}
                        {/* <View style={{ borderWidth: 0.5, borderColor: '#E5E5E5', marginVertical: 25 }} />

                    <View style={{ flexDirection: 'row', width: '100%' }}>
                      <View style={{ flexDirection: 'row', width: '50%', alignItems: 'center' }}>
                        <Text style={{ fontSize: 14, color: "#9FA2B4", fontFamily: 'Nunitosans-Bold', }}>
                          City&state:
                        </Text>
                        <TextInput
                          editable={selectedCustomerEdit === null || editMode}
                          style={{ marginLeft: 5 }}
                          placeholder='Selangor'
                          onChangeText={(text) => {
                            setCustomerState(text);
                          }}
                          defaultValue={customerState}
                        //value={}
                        />
                      </View>

                      <View style={{ flexDirection: 'row', width: '50%', alignItems: 'center' }}>
                        <Text style={{ fontSize: 14, color: "#9FA2B4", fontFamily: 'Nunitosans-Bold', }}>
                          Postcode:
                        </Text>
                        <TextInput
                          editable={selectedCustomerEdit === null || editMode}
                          style={{ marginLeft: 5 }}
                          placeholder='47000'
                          onChangeText={(text) => {
                            setCustomerPostcode(text);
                          }}
                          defaultValue={customerPostcode}
                        //value={}
                        />
                      </View>
                    </View> */}

                        {/* ***************Border Line**************  */}
                        <View
                          style={{
                            borderWidth: 0.5,
                            borderColor: "#E5E5E5",
                            marginVertical: 25,
                          }}
                        />

                        <View style={{ flexDirection: "row" }}>
                          <View style={{ width: "50%", flexDirection: "row" }}>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontFamily: "Nunitosans-Bold",
                                width: "40%",
                              }}
                            >
                              Visits Frequency
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "black",
                                fontFamily: "Nunitosans-Regular",
                                marginLeft: 5,
                              }}
                            >
                              {totalOfVisitFrequency}
                            </Text>
                          </View>
                          <View style={{ width: "50%", flexDirection: "row" }}>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontFamily: "Nunitosans-Bold",
                                width: "40%",
                              }}
                            >
                              Total Frequency
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "black",
                                fontFamily: "Nunitosans-Regular",
                                marginLeft: 5,
                              }}
                            >
                              {totalOfVisitFrequency}
                            </Text>
                          </View>
                        </View>
                        {/* ***************Border Line**************  */}
                        <View
                          style={{
                            borderWidth: 0.5,
                            borderColor: "#E5E5E5",
                            marginVertical: 25,
                          }}
                        />

                        <View style={{ flexDirection: "row" }}>
                          <View style={{ width: "50%", flexDirection: "row" }}>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontFamily: "Nunitosans-Bold",
                                width: "40%",
                              }}
                            >
                              Average Spending
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                fontFamily: "Nunitosans-Regular",
                                marginLeft: 5,
                              }}
                            >
                              RM{" "}
                              {selectedCustomerDineInOrders.length > 0
                                ? (
                                  (averageSpending * +1) /
                                  totalOfVisitFrequency
                                )
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                : "0"}
                            </Text>
                          </View>
                          <View style={{ width: "50%", flexDirection: "row" }}>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                color: "#9FA2B4",
                                fontFamily: "Nunitosans-Bold",
                                width: "40%",
                              }}
                            >
                              Total Spending
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 14,
                                fontFamily: "Nunitosans-Regular",
                                marginLeft: 5,
                              }}
                            >
                              RM{" "}
                              {selectedCustomerDineInOrders.length > 0
                                ? averageSpending
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                : "0"}
                            </Text>
                          </View>
                        </View>

                        {/* ***************Border Line**************  */}
                        <View
                          style={{
                            borderWidth: 0.5,
                            borderColor: "#E5E5E5",
                            marginVertical: 25,
                          }}
                        />

                        {/* <View>
                        <Text style={{marginTop: '5%', fontSize: 14, color: "#9FA2B4", fontFamily: 'Nunitosans-Bold', marginBottom: 10}}>Photo Album</Text>
                        </View>

                        <View>
                        <Text style={{marginTop: '5%', fontSize: 14, color: "#9FA2B4", fontFamily: 'Nunitosans-Bold', marginBottom: 10}}>Before:</Text>
                        </View> */}
                        {/* <View style={{ width: 150,
                        height: 150,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        alignSelf: 'center',
                                    }}>
                        <Image style={{
                          width: 150,
                          height: 150,
                          alignSelf: 'center',
                          }} source={require('../assets/image/profile-pic.jpg')}
                        />
                        </View> */}
                      </View>
                    </View>
                  </View>
                </View>
                {/* <View>
              <View>
                <FusionCharts
                  type={CHART_DATA[CHART_TYPE.DASHBOARD_TODAY_SALES].type}
                  width={windowWidth * (0.9 - Styles.sideBarWidth)}
                  height={windowHeight * 0.4}
                  dataFormat={crmBarChart.dataFormat}
                  dataSource={crmBarChart.dataSource}
                  libraryPath={FS_LIBRARY_PATH}
                  events={eventsChart}
                />
              </View>
            </View> */}
              </View>

              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  width: switchMerchant
                    ? windowWidth * 0.8
                    : windowWidth * 0.87,
                  //minHeight: windowHeight * 0.1,
                  height: windowHeight * 0.8,
                  marginTop: 5,
                  marginHorizontal: switchMerchant ? 30 : 35,
                  //alignSelf: 'center',
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                  paddingBottom: switchMerchant ? 50 : 20,
                  marginTop: 30,
                  marginBottom: switchMerchant ? 100 : 10,
                }}
              >
                <View
                  style={{
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                    borderRadius: 5,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "97%",
                      marginVertical: 20,
                      marginHorizontal: switchMerchant ? 10 : 15,
                      //borderRadius: 5,
                    }}
                  >
                    {/* <TouchableOpacity Style={{}}
                  onPress={() => { setCustomerDetailsSection(CUSTOMER_DETAILS_SECTION.VISIT_TIMELINE) }}
                >
                  <Text style={{
                    color: customerDetailsSection === CUSTOMER_DETAILS_SECTION.VISIT_TIMELINE ? Colors.primaryColor : 'grey',
                    fontWeight: '600',
                    fontSize: 15,
                  }}>
                    Visit Timeline
                  </Text>
                </TouchableOpacity> */}
                    <TouchableOpacity
                      Style={{}}
                      onPress={() => {
                        setCustomerDetailsSection(
                          CUSTOMER_DETAILS_SECTION.PURCHASED_HISTORY
                        );
                      }}
                    >
                      <Text
                        style={{
                          color:
                            customerDetailsSection ===
                              CUSTOMER_DETAILS_SECTION.PURCHASED_HISTORY
                              ? Colors.primaryColor
                              : "grey",
                          fontWeight: "600",
                          fontSize: switchMerchant ? 10 : 16,
                        }}
                      >
                        Purchased History
                      </Text>
                    </TouchableOpacity>
                    {/* <TouchableOpacity Style={{}}
                  onPress={() => { setCustomerDetailsSection(CUSTOMER_DETAILS_SECTION.QUESTIONAIRE) }}
                >
                  <Text style={{
                    color: customerDetailsSection === CUSTOMER_DETAILS_SECTION.QUESTIONAIRE ? Colors.primaryColor : 'grey',
                    fontWeight: '600',
                    fontSize: 15,
                  }}>
                    Questionaire
                  </Text>
                </TouchableOpacity> */}
                    <TouchableOpacity
                      Style={{}}
                      onPress={() => {
                        setCustomerDetailsSection(
                          CUSTOMER_DETAILS_SECTION.CREDIT_POINTS
                        );
                      }}
                    >
                      <Text
                        style={{
                          color:
                            customerDetailsSection ===
                              CUSTOMER_DETAILS_SECTION.CREDIT_POINTS
                              ? Colors.primaryColor
                              : "grey",
                          fontWeight: "600",
                          fontSize: switchMerchant ? 10 : 16,
                        }}
                      >
                        Loyalty Points
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                    Style={{}}
                    onPress={() => {
                      setCustomerDetailsSection(
                        CUSTOMER_DETAILS_SECTION.CASHBACK,
                      );
                    }}>
                    <Text
                      style={{
                        color:
                          customerDetailsSection ===
                            CUSTOMER_DETAILS_SECTION.CASHBACK
                            ? Colors.primaryColor
                            : 'grey',
                        fontWeight: '600',
                        fontSize: switchMerchant ? 10 : 16,
                      }}>
                      Cashback
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    Style={{}}
                    onPress={() => {
                      setCustomerDetailsSection(
                        CUSTOMER_DETAILS_SECTION.LOYALTY_VOUCHER,
                      );
                    }}>
                    <Text
                      style={{
                        color:
                          customerDetailsSection ===
                            CUSTOMER_DETAILS_SECTION.LOYALTY_VOUCHER
                            ? Colors.primaryColor
                            : 'grey',
                        fontWeight: '600',
                        fontSize: switchMerchant ? 10 : 16,
                      }}>
                      Loyalty Vouchers
                    </Text>
                  </TouchableOpacity>

                    {/* <TouchableOpacity Style={{}}
                  onPress={() => { setCustomerDetailsSection(CUSTOMER_DETAILS_SECTION.E_INDEMNITY) }}
                >
                  <Text style={{
                    color: customerDetailsSection === CUSTOMER_DETAILS_SECTION.E_INDEMNITY ? Colors.primaryColor : 'grey',
                    fontWeight: '600',
                    fontSize: 15,
                  }}>
                    E-indemnity
                  </Text>
                </TouchableOpacity> */}
                    {/* <TouchableOpacity Style={{}}
                  onPress={() => { setCustomerDetailsSection(CUSTOMER_DETAILS_SECTION.REDEMPTION_HISTORY) }}
                >
                  <Text style={{
                    color: customerDetailsSection === CUSTOMER_DETAILS_SECTION.REDEMPTION_HISTORY ? Colors.primaryColor : 'grey',
                    fontWeight: '600',
                    fontSize: 15,
                  }}>
                    Redemption History
                  </Text>
                </TouchableOpacity> */}

                    <TouchableOpacity
                      Style={{}}
                      onPress={() => {
                        setCustomerDetailsSection(
                          CUSTOMER_DETAILS_SECTION.VISITATION
                        );
                      }}
                    >
                      <Text
                        style={{
                          color:
                            customerDetailsSection ===
                              CUSTOMER_DETAILS_SECTION.VISITATION
                              ? Colors.primaryColor
                              : "grey",
                          fontWeight: "600",
                          fontSize: switchMerchant ? 10 : 16,
                        }}
                      >
                        Visitation
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      Style={{}}
                      onPress={() => {
                        setCustomerDetailsSection(
                          CUSTOMER_DETAILS_SECTION.VOUCHER
                        );
                      }}
                    >
                      <Text
                        style={{
                          color:
                            customerDetailsSection ===
                              CUSTOMER_DETAILS_SECTION.VOUCHER
                              ? Colors.primaryColor
                              : "grey",
                          fontWeight: "600",
                          fontSize: switchMerchant ? 10 : 16,
                        }}
                      >
                        Voucher
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      Style={{}}
                      onPress={() => {
                        setCustomerDetailsSection(
                          CUSTOMER_DETAILS_SECTION.REDEMPTION_HISTORY
                        );
                      }}
                    >
                      <Text
                        style={{
                          color:
                            customerDetailsSection ===
                              CUSTOMER_DETAILS_SECTION.REDEMPTION_HISTORY
                              ? Colors.primaryColor
                              : "grey",
                          fontWeight: "600",
                          fontSize: switchMerchant ? 10 : 16,
                        }}
                      >
                        Promotion
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      Style={{}}
                      onPress={() => {
                        setCustomerDetailsSection(
                          CUSTOMER_DETAILS_SECTION.DOCKET
                        );
                      }}
                    >
                      <Text
                        style={{
                          color:
                            customerDetailsSection ===
                              CUSTOMER_DETAILS_SECTION.DOCKET
                              ? Colors.primaryColor
                              : "grey",
                          fontWeight: "600",
                          fontSize: switchMerchant ? 10 : 16,
                        }}
                      >
                        Docket
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>

                {/* ***************************Visit Timeline ****************************/}
                {customerDetailsSection ===
                  CUSTOMER_DETAILS_SECTION.VISIT_TIMELINE ? (
                  <View
                    style={{
                      width: "72%",
                      alignSelf: "center",
                      margin: 20,
                      //backgroundColor: 'red',
                      borderRadius: 5,
                      // shadowOpacity: 0,
                      // shadowColor: "#000",
                      // shadowOffset: {
                      //   width: 0,
                      //   height: 2,
                      // },
                      // shadowOpacity: 0.22,
                      // shadowRadius: 3.22,
                      // elevation: 3,
                    }}
                  >
                    <View style={{ marginBottom: 10 }}>
                      <Text style={{ fontWeight: "500", fontSize: 25 }}>
                        Timeline
                      </Text>
                    </View>

                    <ScrollView
                      containerStyle={{}}
                      style={{
                        paddingTop: 25,
                        paddingRight: 10,
                        borderWidth: 1,
                        borderRadius: 5,
                        borderColor: "#E5E5E5",
                        height: 520,
                        marginBottom: 15,
                      }}
                    >
                      {selectedCustomerDineInOrders.length > 0 && selectedCustomerEdit ? (
                        <FlatList
                          showsVerticalScrollIndicator={false}
                          data={selectedCustomerDineInOrders}
                          // extraData={renderVisitTimeLine}
                          renderItem={renderVisitTimeLine}
                          keyExtractor={(item, index) => String(index)}
                          style={{ marginTop: -5 }}
                        />
                      ) : (
                        <View
                          style={{
                            width: "100%",
                            height: 100,
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              top: -20,
                              fontFamily: "NunitoSans-Regular",
                              fontSize: 14,
                            }}
                          >
                            No timeline for now.
                          </Text>
                        </View>
                      )}
                    </ScrollView>
                  </View>
                ) : null}

                {/* ***************************Purchase History ****************************/}

                {customerDetailsSection ===
                  CUSTOMER_DETAILS_SECTION.PURCHASED_HISTORY ? (
                  <View
                    style={{
                      backgroundColor: "#ffffff",
                      paddingVertical: 20,
                      paddingHorizontal: 20,
                      borderRadius: 5,
                      // shadowOpacity: 0,
                      // shadowColor: "#000",
                      // shadowOffset: {
                      //   width: 0,
                      //   height: 2,
                      // },
                      // shadowOpacity: 0.22,
                      // shadowRadius: 3.22,
                      // elevation: 3,
                    }}
                  >
                    <View style={{ marginBottom: 10 }}>
                      <Text
                        style={{
                          fontFamily: "Nunitosans-Bold",
                          fontSize: switchMerchant ? 16 : 25,
                        }}
                      >
                        Payment
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: Colors.tabMint,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Payment
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {`RM${phTotalPayment
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: Colors.tabYellow,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Number of Payment
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {timeOfPhTotalPayment}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: Colors.tabCyan,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Average Payment
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {phTotalPayment &&
                              timeOfPhTotalPayment &&
                              ((phTotalPayment * +1) / timeOfPhTotalPayment)
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                              ? ((phTotalPayment * +1) / timeOfPhTotalPayment)
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                              : "0.00"}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: "#ed5740",
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "24%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Outstanding Payment
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {`RM${phOutstandingPayment
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        marginTop: 30,
                        marginBottom: switchMerchant ? 40 : 15,
                        borderWidth: 1,
                        borderRadius: 5,
                        padding: 15,
                        paddingVertical: 1,
                        borderColor: Colors.highlightColor,
                        height: switchMerchant
                          ? "60%"
                          : windowWidth <= 1133
                            ? "62%"
                            : "68%",
                      }}
                    >
                      {/* <ScrollView
                  // containerStyle={{}}
                  > */}
                      {selectedCustomerOrders.length > 0 && selectedCustomerEdit ? (
                        <FlatList
                          showsVerticalScrollIndicator={false}
                          data={selectedCustomerOrders.filter(() => {
                            if(selectedCustomerEdit){
                              return true
                            }
                            else{
                              return false
                            }
                          })}                          // extraData={renderPurchasedHistory}
                          renderItem={renderPurchasedHistory}
                          keyExtractor={(item, index) => String(index)}
                          style={{}}
                        />
                      ) : (
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "71%",
                          }}
                        >
                          <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text>
                        </View>
                      )}
                      {/* </ScrollView> */}
                    </View>
                  </View>
                ) : null}

                {/* ***************************Questionaire ****************************/}
                {customerDetailsSection ===
                  CUSTOMER_DETAILS_SECTION.QUESTIONAIRE ? (
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginRight: 60,
                        margin: 20,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#FFFFFF",
                          borderRadius: 5,
                          height: 40,
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                          width: 150,
                        }}
                      >
                        <Feather
                          name={"upload"}
                          size={22}
                          color={Colors.primaryColor}
                        />
                        <Text
                          style={{
                            marginLeft: 4,
                            alignSelf: "center",
                            fontSize: 18,
                            fontFamily: "Nunitosans-Regular",
                            fontWeight: "700",
                            color: Colors.primaryColor,
                          }}
                        >
                          Upload Form
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        backgroundColor: "#FFFFFF",
                        width: "67%",
                        //height: windowHeight * 0.35,
                        marginTop: 5,
                        marginHorizontal: 20,
                        alignSelf: "center",
                        borderRadius: 5,
                        shadowOpacity: 0,
                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 3,
                      }}
                    >
                      <FlatList
                        showsVerticalScrollIndicator={false}
                        data={renderQuestionaire}
                        renderItem={renderQuestionaire}
                        keyExtractor={(item, index) => String(index)}
                        style={{ marginTop: 10 }}
                        initialNumToRender={8}
                      />
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginRight: 60,
                        marginVertical: 20,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#FFFFFF",
                          borderRadius: 5,
                          height: 40,
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                          width: 150,
                        }}
                      >
                        <Text
                          style={{
                            alignSelf: "center",
                            fontSize: 18,
                            fontFamily: "Nunitosans-Regular",
                            fontWeight: "800",
                            color: Colors.primaryColor,
                          }}
                        >
                          CANCEL
                        </Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        style={{
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: Colors.primaryColor,
                          borderRadius: 5,
                          height: 40,
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                          width: 150,
                          marginLeft: 10,
                        }}
                      >
                        <Text
                          style={{
                            alignSelf: "center",
                            fontSize: 18,
                            fontFamily: "Nunitosans-Regular",
                            fontWeight: "800",
                            color: Colors.whiteColor,
                          }}
                        >
                          SAVE
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : null}

                {/* ***************************Credit Points****************************/}
                {customerDetailsSection ===
                  CUSTOMER_DETAILS_SECTION.CREDIT_POINTS ? (
                  //   <View style={{ margin: 10, marginLeft: 35, justifyContent: 'space-between', flexDirection: 'row' }}>
                  // <Modal
                  //   style={{
                  //     // flex: 1
                  //   }}
                  //   visible={showRedemptionAvailability}
                  //   transparent={true}
                  //   animationType={'slide'}
                  // >
                  //   <View style={styles.modalContainer1}>
                  //     <View style={[styles.modalView1, {
                  //     }]}>
                  //       <View style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                  //         <TouchableOpacity
                  //           onPress={() => {
                  //             setShowRedemptionAvailability(false);
                  //           }}>
                  //           <AntDesign name={"close"} size={25} color={'#858C94'} />
                  //         </TouchableOpacity>
                  //       </View>
                  //       <Text style={{ marginTop: 15, alignSelf: 'center', fontSize: 26, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: '#000000' }}>
                  //         Point Redemption List
                  //       </Text>

                  //       <View style={{
                  //         backgroundColor: '#FFFFFF',
                  //         width: windowWidth * 0.4,
                  //         height: windowWidth * 0.38,
                  //         marginTop: 5,
                  //         marginHorizontal: 20,
                  //         alignSelf: 'center',
                  //         borderRadius: 5,
                  //         shadowOpacity: 0,
                  //         shadowColor: '#000',
                  //         shadowOffset: {
                  //           width: 0,
                  //           height: 2,
                  //         },
                  //         shadowOpacity: 0.22,
                  //         shadowRadius: 3.22,
                  //         elevation: 3,
                  //         //justifyContent: 'center',
                  //         alignItems: 'center',
                  //         marginTop: 10
                  //       }}>
                  //         <ScrollView>
                  //           <FlatList
                  //             data={renderPointRedemptionList}
                  //             renderItem={renderPointRedemptionList}
                  //             keyExtractor={(item, index) => String(index)}
                  //             style={{ marginTop: 10 }}
                  //             initialNumToRender={8}
                  //           />
                  //         </ScrollView>
                  //         <View style={{ marginBottom: 10, marginRight: 10, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', alignSelf: 'flex-end' }}>
                  //           <TouchableOpacity style={{
                  //             borderWidth: 1,
                  //             borderColor: Colors.primaryColor, backgroundColor: '#FFFFFF', borderRadius: 5, height: 40,
                  //             alignItems: 'center',
                  //             flexDirection: 'row',
                  //             justifyContent: 'center',
                  //             width: 100,
                  //           }}

                  //           >
                  //             <Text style={{ alignSelf: 'center', fontSize: 18, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: Colors.primaryColor }}>
                  //               CANCEL
                  //             </Text>
                  //           </TouchableOpacity>

                  //           <TouchableOpacity style={{
                  //             borderWidth: 1,
                  //             borderColor: Colors.primaryColor, backgroundColor: Colors.primaryColor, borderRadius: 5, height: 40,
                  //             alignItems: 'center',
                  //             flexDirection: 'row',
                  //             justifyContent: 'center',
                  //             width: 100,
                  //             marginLeft: 10
                  //           }}

                  //           >
                  //             <Text style={{ alignSelf: 'center', fontSize: 18, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: Colors.whiteColor }}>
                  //               SAVE
                  //             </Text>
                  //           </TouchableOpacity>
                  //         </View>
                  //       </View>

                  //     </View>
                  //   </View>
                  // </Modal>

                  //     <TouchableOpacity style={{
                  //       borderWidth: 1,
                  //       borderColor: Colors.primaryColor, backgroundColor: Colors.primaryColor, borderRadius: 5, height: 40,
                  //       alignItems: 'center',
                  //       flexDirection: 'row',
                  //       justifyContent: 'center',
                  //       width: 150,
                  //       marginLeft: 10
                  //     }}
                  // onPress={() => {
                  //   setShowRedemptionAvailability(true)
                  // }}
                  //     >
                  //       <Text style={{ alignSelf: 'center', fontSize: 18, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: Colors.whiteColor }}>
                  //         Show Modal
                  //       </Text>
                  //     </TouchableOpacity>

                  //     <Text style={{ fontFamily: 'Nunitosans-Bold', fontSize: 25, width: "47%" }}>
                  //       Point Redemption History:
                  //     </Text>
                  //   </View >

                  //   <View style={{ flexDirection: 'row',  marginLeft: 35, marginBottom: 10, marginRight: 10,}}>
                  //     {/* left */}
                  //     <View style={{ width: '51%', height: 270, backgroundColor: '#335E4A', borderRadius: 10, shadowColor: '#000', }}>
                  //       <View style={{ margin:25,marginTop:10, marginBottom: 10}}>
                  //       <View style={{ flexDirection: 'row', justifyContent:'space-between',}}>
                  //         <Text style={{ fontSize: 35, color: Colors.secondaryColor, fontFamily: 'NunitoSans-Bold' }}>2,025</Text>

                  //         <View style={{ marginTop:10, }}>
                  //         <TouchableOpacity>
                  //           {/* Change to icon  */}
                  //           <Icon name={'edit'} size={18} color={Colors.primaryColor} />
                  //         </TouchableOpacity>
                  //         </View>
                  //         </View>

                  //         <View style={{ flexDirection: 'row', }}>
                  //         <Text style={{ fontSize: 25, color: '#FFFFFF', fontFamily: 'NunitoSans-Bold' }}>Point Balance</Text>
                  //       </View>

                  //       <View style={{ flexDirection: 'row', justifyContent: 'flex-end',  marginTop: 120  }}>

                  //               <View style = {{borderWidth: 1,
                  //                 borderColor: '#4E9F7D',
                  //                 backgroundColor: '#FFFFFF', borderRadius: 40, height: 40,
                  //                 alignItems: 'center',
                  //                 flexDirection: 'row',
                  //                 justifyContent: 'center',
                  //                 width: 80,
                  //                 // marginTop:202, marginRight:10,
                  //                 }}>
                  //               <Text style={{ fontSize:20, color: Colors.secondaryColor,fontFamily: 'NunitoSans-Bold',   }}>
                  //                1x
                  //               </Text>
                  //                 </View>

                  //                 <Text style={{ fontSize:20, fontFamily: 'NunitoSans-Regular', color: 'gold',justifyContent: 'flex-end',  justifyContent: 'center', marginLeft:10,marginTop:8 ,}}>
                  //                   Points RM10 Spent
                  //                 </Text>
                  //               </View>
                  //       </View>
                  //     </View>

                  //     <View style={{ margin: 10, justifyContent: 'space-between', paddingHorizontal: 15, }}>
                  //       <View style={{ flexDirection: 'row', height: 20, }}>

                  //         <Text style={{ fontWeight: '400',color:'9FA2B4', fontSize: 15, paddingRight: 15, borderRightWidth: 1, borderRightColor: 'lightgrey', }}>
                  //           12 Nov 2020
                  //         </Text>

                  //         <Text style={{ fontWeight: '400', color:'9FA2B4', fontSize: 15, paddingLeft: 15, paddingRight: 15, alignSelf: 'center', borderRightWidth: 1, borderRightColor: 'lightgrey', }}>
                  //           Package A
                  //         </Text>

                  //         <Text style={{ fontWeight: '400', color:'#27AE60',fontSize: 15, paddingLeft: 15, }}>
                  //           +200
                  //         </Text>

                  //       </View>
                  //     </View>

                  //   </View>

                  //   <View style={{ margin: 25, marginLeft: 35, justifyContent: 'space-between', flexDirection: 'row', marginBottom: 10, marginRight: 10, }}>

                  //     <Text style={{ fontFamily: 'Nunitosans-Bold', fontSize: 25 }}>
                  //       Redemption Availability:
                  //     </Text>

                  //     <TouchableOpacity
                  //       style={styles.submitText}
                  //       onPress={() => {
                  //         props.navigation.navigate("ProductAdd")
                  //       }}>
                  //       <View style={{ flexDirection: 'row', borderWidth: 1, borderColor: '#4E9F7D', borderRadius: 10, height: 40, width: 90, alignItems: 'center', marginRight: 10, justifyContent: 'center', }}>
                  //         <AIcon name="pluscircle" size={25} color='#4E9F7D' />
                  //         <Text style={{ marginLeft: 10, color: Colors.primaryColor, fontFamily: 'NunitoSans-Regular' }}>
                  //           Add
                  //         </Text>
                  //       </View>
                  //     </TouchableOpacity>
                  //   </View>

                  //   <View style={{ flexDirection: 'row', marginLeft: 35, }}>

                  //     {/* left */}
                  //     <View style={{width: '40%', height: 180, backgroundColor: '#FFFFFF', flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: 45, paddingVertical: 15, shadowColor: '#000', borderWidth:1,borderColor:"#E5E5E5" }}>

                  //     <View style={{ flexDirection: 'column',  alignItems: 'center', justifyContent: 'center', margin:25}}>
                  //     <View style={{

                  //         width: windowHeight * 0.05,
                  //         height: windowHeight * 0.05,
                  //         borderRadius: windowHeight * 0.05 * 0.5,
                  //         alignItems: 'center',
                  //         justifyContent: 'center',
                  //         backgroundColor: 'white',
                  //     }}>
                  //         <Image style={{
                  //             width: windowHeight * 0.15,
                  //             height: windowHeight * 0.15,
                  //             alignSelf: 'center',
                  //         }} source={require('../assets/image/profile-pic.jpg')} />
                  //     </View>
                  //     </View>
                  //     <View style={{ flexDirection: 'column',  justifyContent: 'center', }}>
                  //     <Text style={{ fontSize:25, color: '#27353C', fontFamily: 'NunitoSans-Bold' }}>
                  //           Shiseido Shampoo
                  //         </Text>
                  //         <Text style={{ fontSize:25,color: '#27353C', fontFamily: 'NunitoSans-Bold' }}>
                  //           RM90
                  //         </Text>
                  //         <View style = {{borderWidth: 1,
                  //         borderColor: '#4E9F7D',
                  //     backgroundColor: '#4E9F7D', borderRadius: 2, height: 40,
                  //       alignItems: 'center',
                  //       flexDirection: 'row',
                  //       justifyContent: 'center',
                  //       width: 150,
                  //       }}>
                  //         <Text style={{ fontSize:20, color: '#FFFFFF',fontFamily: 'NunitoSans-Bold' }}>
                  //           100 points
                  //         </Text>

                  //     </View>
                  //     </View>
                  //     </View>

                  //      </View>

                  //      <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginRight: 60, marginTop: 20 }}>
                  //     <TouchableOpacity style={{
                  //       borderWidth: 1,
                  //       borderColor: Colors.primaryColor, backgroundColor: '#FFFFFF', borderRadius: 5, height: 40,
                  //       alignItems: 'center',
                  //       flexDirection: 'row',
                  //       justifyContent: 'center',
                  //       width: 150,
                  //     }}

                  //     >
                  //       <Text style={{ alignSelf: 'center', fontSize: 18, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: Colors.primaryColor }}>
                  //         CANCEL
                  //       </Text>
                  //     </TouchableOpacity>

                  //     <TouchableOpacity style={{
                  //       borderWidth: 1,
                  //       borderColor: Colors.primaryColor, backgroundColor: Colors.primaryColor, borderRadius: 5, height: 40,
                  //       alignItems: 'center',
                  //       flexDirection: 'row',
                  //       justifyContent: 'center',
                  //       width: 150,
                  //       marginLeft: 10
                  //     }}
                  //     >
                  //       <Text style={{ alignSelf: 'center', fontSize: 18, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: Colors.whiteColor }}>
                  //         SAVE
                  //       </Text>
                  //     </TouchableOpacity>
                  //   </View>

                  <View style={{}}>
                    <View
                      style={{
                        backgroundColor: "#ffffff",
                        paddingVertical: 20,
                        paddingHorizontal: 20,
                        borderRadius: 5,
                        // shadowOpacity: 0,
                        // shadowColor: "#000",
                        // shadowOffset: {
                        //   width: 0,
                        //   height: 2,
                        // },
                        // shadowOpacity: 0.22,
                        // shadowRadius: 3.22,
                        // elevation: 3,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <View style={{ width: "100%" }}>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 16 : 25,
                                fontFamily: "Nunitosans-Bold",
                                marginBottom: 10,
                              }}
                            >
                              Loyalty Points
                            </Text>

                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <View
                                style={{
                                  backgroundColor: Colors.tabMint,
                                  padding: switchMerchant ? 10 : 20,
                                  paddingRight: switchMerchant ? 10 : 20,
                                  borderRadius: 5,
                                  shadowColor: "#000",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.22,
                                  shadowRadius: 1.22,
                                  elevation: 1,
                                  width: "23%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    height: switchMerchant ? 30 : 20,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: Colors.primaryColor,
                                      width: 4,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontWeight: "400",
                                      fontSize: switchMerchant ? 12 : 15,
                                      marginLeft: 5,
                                      alignSelf: "center",
                                    }}
                                  >
                                    Point Balance
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    marginTop: 10,
                                    flexDirection: "row",
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: Colors.primaryColor,
                                      width: 4,
                                      opacity: 0,
                                      marginRight: 5,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: switchMerchant ? 14 : 28,
                                      fontWeight: "600",
                                    }}
                                  >
                                  {selectedCustomerEdit ? selectedCustomerPointsBalance.toFixed(0) : 0}
                                  </Text>
                                </View>
                              </View>

                              <View
                                style={{
                                  backgroundColor: Colors.tabGold,
                                  padding: switchMerchant ? 10 : 20,
                                  paddingRight: switchMerchant ? 10 : 20,
                                  borderRadius: 5,
                                  marginLeft: switchMerchant ? 15 : 20,
                                  shadowColor: "#000",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.22,
                                  shadowRadius: 1.22,
                                  elevation: 1,
                                  width: "23%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    height: switchMerchant ? 30 : 20,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: Colors.primaryColor,
                                      width: 4,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontWeight: "400",
                                      fontSize: switchMerchant ? 12 : 15,
                                      marginLeft: 5,
                                      alignSelf: "center",
                                    }}
                                  >
                                    Points Redeemed
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    marginTop: 10,
                                    flexDirection: "row",
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: Colors.primaryColor,
                                      width: 4,
                                      opacity: 0,
                                      marginRight: 5,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: switchMerchant ? 14 : 28,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {loyaltyPointsRedeemed * -1}
                                  </Text>
                                </View>
                              </View>

                              <View
                                style={{
                                  backgroundColor: Colors.tabCyan,
                                  padding: switchMerchant ? 10 : 20,
                                  paddingRight: switchMerchant ? 10 : 20,
                                  borderRadius: 5,
                                  marginLeft: switchMerchant ? 15 : 20,
                                  shadowColor: "#000",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.22,
                                  shadowRadius: 1.22,
                                  elevation: 1,
                                  width: "23%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    height: switchMerchant ? 30 : 20,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: Colors.primaryColor,
                                      width: 4,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontWeight: "400",
                                      fontSize: switchMerchant ? 12 : 15,
                                      marginLeft: 5,
                                      alignSelf: "center",
                                    }}
                                  >
                                    Numbers Redeemed
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    marginTop: 10,
                                    flexDirection: "row",
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: Colors.primaryColor,
                                      width: 4,
                                      opacity: 0,
                                      marginRight: 5,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: switchMerchant ? 14 : 28,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {timeOfLoyaltyPointsRedeemed}
                                  </Text>
                                </View>
                              </View>

                              <View
                                style={{
                                  backgroundColor: "#ed5740",
                                  padding: switchMerchant ? 10 : 20,
                                  paddingRight: switchMerchant ? 10 : 20,
                                  borderRadius: 5,
                                  marginLeft: switchMerchant ? 15 : 20,
                                  shadowColor: "#000",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.22,
                                  shadowRadius: 1.22,
                                  elevation: 1,
                                  width: "23%",
                                }}
                              >
                                <View
                                  style={{
                                    flexDirection: "row",
                                    height: switchMerchant ? 30 : 20,
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: Colors.primaryColor,
                                      width: 4,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontWeight: "400",
                                      fontSize: switchMerchant ? 12 : 15,
                                      marginLeft: 5,
                                      alignSelf: "center",
                                    }}
                                  >
                                    Average Points Redeemed
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    marginTop: 10,
                                    flexDirection: "row",
                                  }}
                                >
                                  <View
                                    style={{
                                      backgroundColor: Colors.primaryColor,
                                      width: 4,
                                      opacity: 0,
                                      marginRight: 5,
                                    }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: switchMerchant ? 14 : 28,
                                      fontWeight: "600",
                                    }}
                                  >
                                    {/* {customerLastVisitDate ? moment(customerLastVisitDate).format('DD/MM/YYYY') : 'N/A'} */}
                                    {/* {item.uniqueId && userOrderBeerDocketUBDIdDict[item.uniqueId] && userOrderBeerDocketUBDIdDict[item.uniqueId].waiterName ? userOrderBeerDocketUBDIdDict[item.uniqueId].waiterName : 'N/A'} */}
                                    {loyaltyPointsRedeemed &&
                                      timeOfLoyaltyPointsRedeemed &&
                                      (
                                        (loyaltyPointsRedeemed * -1) /
                                        timeOfLoyaltyPointsRedeemed
                                      )
                                        .toFixed(2)
                                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                                      ? (
                                        (loyaltyPointsRedeemed * -1) /
                                        timeOfLoyaltyPointsRedeemed
                                      )
                                        .toFixed(2)
                                        .replace(
                                          /(\d)(?=(\d{3})+(?!\d))/g,
                                          "$1,"
                                        )
                                      : "0"}
                                  </Text>
                                </View>
                              </View>
                            </View>
                          </View>

                          {/* <View style={{ width: '50%' }}>
                          <Text style={{ fontSize: 20, fontWeight: '500', marginBottom: 10, marginLeft: 5 }}>
                            Point Redemption History:
                          </Text>
                          <ScrollView style={{ height: 200 }}>
                            <FlatList
                              data={selectedCustomerPointsTransactions}
                              // extraData={renderPointRedemptionHistory}
                              renderItem={renderPointRedemptionHistory}
                              keyExtractor={(item, index) => String(index)}
                              style={{}}
                            />
                          </ScrollView>
                        </View> */}
                        </View>

                        {/* <View style={{ marginVertical: 30, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginBottom: 20, }}>
                        <Text style={{ fontWeight: '500', fontSize: 20 }}>
                          Redemption Packages:
                        </Text>
                        <TouchableOpacity
                          style={styles.submitText}
                          onPress={() => {
                            setShowRedemptionAvailability(true)
                          }}>
                          <View style={{ flexDirection: 'row', borderWidth: 1, borderColor: '#4E9F7D', borderRadius: 10, height: 40, width: 110, alignItems: 'center', justifyContent: 'center', }}>
                            <AIcon name="pluscircle" size={25} color='#4E9F7D' />
                            <Text style={{ marginLeft: 10, color: Colors.primaryColor, fontFamily: 'NunitoSans-Regular', fontWeight: '800' }}>
                              Manage
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View> */}
                        {/* <View style={{ marginVertical: 30, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginBottom: 20, }}>
                        <Text style={{ fontWeight: '500', fontSize: 20 }}>
                          Redemption Packages:
                        </Text>
                        <TouchableOpacity
                          style={styles.submitText}
                          onPress={() => {
                            setShowRedemptionAvailability(true)
                          }}>
                          <View style={{ flexDirection: 'row', borderWidth: 1, borderColor: '#4E9F7D', borderRadius: 10, height: 40, width: 110, alignItems: 'center', justifyContent: 'center', }}>
                            <AIcon name="pluscircle" size={25} color='#4E9F7D' />
                            <Text style={{ marginLeft: 10, color: Colors.primaryColor, fontFamily: 'NunitoSans-Regular', fontWeight: '800' }}>
                              Manage
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View> */}

                        <View style={{ width: "100%", marginTop: 15 }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 20,
                              fontWeight: "500",
                              marginBottom: 10,
                            }}
                          >
                            Points Redemption History
                          </Text>
                          <View
                            style={{
                              height: switchMerchant ? "66%" : "69%",
                              marginBottom: switchMerchant ? -10 : -25,
                            }}
                          >
                            {selectedCustomerPointsTransactions.length > 0 && selectedCustomerEdit ? (
                              <FlatList
                                showsVerticalScrollIndicator={false}
                                data={selectedCustomerPointsTransactions.filter(() => {
                                  if(selectedCustomerEdit){
                                    return true
                                  }
                                  else{
                                    return false
                                  }
                                })}
                                // extraData={renderPointRedemptionHistory}
                                renderItem={renderPointRedemptionHistory}
                                keyExtractor={(item, index) => String(index)}
                                style={{
                                  marginBottom: switchMerchant ? -10 : -70,
                                }}
                              />
                            ) : (
                              <View
                                style={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  height: switchMerchant ? "66%" : "70%",
                                  marginBottom: switchMerchant ? -10 : -25,
                                }}
                              >
                                <Text
                                  style={{ color: Colors.descriptionColor }}
                                >
                                  - No Data Available -
                                </Text>
                              </View>
                            )}
                          </View>
                        </View>

                        {/* <ScrollView
                        horizontal={true}
                      >
                        <FlatList
                          horizontal={true}
                          data={availablePointsRedeemPackages}
                          // extraData={renderRedemptionAvailability}
                          renderItem={renderRedemptionAvailability}
                          keyExtractor={(item, index) => String(index)}
                          style={{}}
                        />
                      </ScrollView> */}
                      </View>
                    </View>

                    {/* <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20, marginRight: 20 }}>
                    <TouchableOpacity style={{
                      borderWidth: 1,
                      borderColor: Colors.primaryColor, backgroundColor: '#FFFFFF', borderRadius: 5, height: 40,
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: 140,
                    }}

                    >
                      <Text style={{ alignSelf: 'center', fontSize: 18, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: Colors.primaryColor }}>
                        CANCEL
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{
                      borderWidth: 1,
                      borderColor: Colors.primaryColor, backgroundColor: Colors.primaryColor, borderRadius: 5, height: 40,
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      width: 140,
                      marginLeft: 10
                    }}
                    >
                      <Text style={{ alignSelf: 'center', fontSize: 18, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: Colors.whiteColor }}>
                        SAVE
                      </Text>
                    </TouchableOpacity>
                  </View> */}

                    {/* Show Redemption List Modal  */}
                    <Modal
                      style={
                        {
                          // flex: 1
                        }
                      }
                      visible={showRedemptionAvailability}
                      transparent={true}
                      animationType={"slide"}
                    >
                      <View style={styles.modalContainer1}>
                        <View
                          style={[
                            styles.modalView1,
                            {
                              height: windowWidth * 0.5,
                              width: windowWidth * 0.45,
                              borderRadius: windowWidth * 0.03,
                            },
                          ]}
                        >
                          <View
                            style={{
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <TouchableOpacity
                              onPress={() => {
                                setShowRedemptionAvailability(false);
                              }}
                            >
                              <AntDesign
                                name={"close"}
                                size={25}
                                color={"#858C94"}
                              />
                            </TouchableOpacity>
                          </View>
                          <Text
                            style={{
                              marginTop: 15,
                              alignSelf: "center",
                              fontSize: 26,
                              fontFamily: "Nunitosans-Regular",
                              fontWeight: "700",
                              color: "#000000",
                            }}
                          >
                            Point Redemption List
                          </Text>

                          <View
                            style={{
                              backgroundColor: "#FFFFFF",
                              width: windowWidth * 0.44,
                              height: windowWidth * 0.38,
                              marginTop: 5,
                              marginHorizontal: 20,
                              alignSelf: "center",
                              borderRadius: 5,
                              shadowOpacity: 0,
                              shadowColor: "#000",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 3,
                              //justifyContent: 'center',
                              alignItems: "center",
                              marginTop: 10,
                            }}
                          >
                            <ScrollView showsVerticalScrollIndicator={false}>
                              <FlatList
                                showsVerticalScrollIndicator={false}
                                data={availablePointsRedeemPackages}
                                renderItem={renderPointRedemptionList}
                                keyExtractor={(item, index) => String(index)}
                                style={{ marginTop: 10 }}
                                initialNumToRender={8}
                              />
                            </ScrollView>

                            {/* <View style={{ marginBottom: 10, marginRight: 10, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'flex-end', alignSelf: 'flex-end' }}>
                            <TouchableOpacity style={{
                              borderWidth: 1,
                              borderColor: Colors.primaryColor, backgroundColor: '#FFFFFF', borderRadius: 5, height: 40,
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              width: 100,
                            }}
                              onPress={() => { setShowRedemptionAvailability(false) }}
                            >
                              <Text style={{ alignSelf: 'center', fontSize: 18, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: Colors.primaryColor }}>
                                CANCEL
                              </Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={{
                              borderWidth: 1,
                              borderColor: Colors.primaryColor, backgroundColor: Colors.primaryColor, borderRadius: 5, height: 40,
                              alignItems: 'center',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              width: 100,
                              marginLeft: 10
                            }}

                            >
                              <Text style={{ alignSelf: 'center', fontSize: 18, fontFamily: 'Nunitosans-Regular', fontWeight: '700', color: Colors.whiteColor }}>
                                SAVE
                              </Text>
                            </TouchableOpacity>
                          </View> */}
                          </View>
                        </View>
                      </View>
                    </Modal>
                  </View>
                ) : null}

                {/* ***************************Cashback****************************/}
              {customerDetailsSection ===
                CUSTOMER_DETAILS_SECTION.CASHBACK ? (
                <View style={{}}>
                  <View
                    style={{
                      backgroundColor: '#ffffff',
                      paddingVertical: 20,
                      paddingHorizontal: 20,
                      borderRadius: 5,
                      shadowOpacity: 0,
                      shadowColor: '#000',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                    }}>
                    <View style={{ flexDirection: 'column' }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          width: '100%',
                          justifyContent: 'space-between',
                        }}>
                        <View style={{ width: '100%' }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 16 : 25,
                              fontFamily: 'Nunitosans-Bold',
                              marginBottom: 10,
                            }}>
                            Cashback
                          </Text>

                          <View
                            style={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}>
                            <View
                              style={{
                                backgroundColor: Colors.tabMint,
                                padding: switchMerchant ? 10 : 20,
                                paddingRight: switchMerchant ? 10 : 20,
                                borderRadius: 5,
                                shadowColor: '#000',
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 1.22,
                                elevation: 1,
                                width: '23%',
                              }}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  height: switchMerchant ? 30 : 20,
                                }}>
                                <View
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    width: 4,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontWeight: '400',
                                    fontSize: switchMerchant ? 12 : 15,
                                    marginLeft: 5,
                                    alignSelf: 'center',
                                  }}>
                                  Cashback Amount
                                </Text>
                              </View>
                              <View
                                style={{ marginTop: 10, flexDirection: 'row' }}>
                                <View
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    width: 4,
                                    opacity: 0,
                                    marginRight: 5,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 14 : 28,
                                    fontWeight: '600',
                                  }}>
                                  RM{cashback}
                                </Text>
                              </View>
                            </View>

                            <View
                              style={{
                                backgroundColor: Colors.tabGold,
                                padding: switchMerchant ? 10 : 20,
                                paddingRight: switchMerchant ? 10 : 20,
                                borderRadius: 5,
                                marginLeft: switchMerchant ? 15 : 20,
                                shadowColor: '#000',
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 1.22,
                                elevation: 1,
                                width: '23%',
                              }}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  height: switchMerchant ? 30 : 20,
                                }}>
                                <View
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    width: 4,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontWeight: '400',
                                    fontSize: switchMerchant ? 12 : 15,
                                    marginLeft: 5,
                                    alignSelf: 'center',
                                  }}>
                                  Cashback Used
                                </Text>
                              </View>
                              <View
                                style={{ marginTop: 10, flexDirection: 'row' }}>
                                <View
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    width: 4,
                                    opacity: 0,
                                    marginRight: 5,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 14 : 28,
                                    fontWeight: '600',
                                  }}>
                                  RM{cashbackUsed}
                                </Text>
                              </View>
                            </View>

                            <View
                              style={{
                                backgroundColor: Colors.tabCyan,
                                padding: switchMerchant ? 10 : 20,
                                paddingRight: switchMerchant ? 10 : 20,
                                borderRadius: 5,
                                marginLeft: switchMerchant ? 15 : 20,
                                shadowColor: '#000',
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 1.22,
                                elevation: 1,
                                width: '23%',
                              }}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  height: switchMerchant ? 30 : 20,
                                }}>
                                <View
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    width: 4,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontWeight: '400',
                                    fontSize: switchMerchant ? 12 : 15,
                                    marginLeft: 5,
                                    alignSelf: 'center',
                                  }}>
                                  Numbers Redeemed
                                </Text>
                              </View>
                              <View
                                style={{ marginTop: 10, flexDirection: 'row' }}>
                                <View
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    width: 4,
                                    opacity: 0,
                                    marginRight: 5,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 14 : 28,
                                    fontWeight: '600',
                                  }}>
                                  RM{cashbackUsed}
                                </Text>
                              </View>
                            </View>

                            <View
                              style={{
                                backgroundColor: '#ed5740',
                                padding: switchMerchant ? 10 : 20,
                                paddingRight: switchMerchant ? 10 : 20,
                                borderRadius: 5,
                                marginLeft: switchMerchant ? 15 : 20,
                                shadowColor: '#000',
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 1.22,
                                elevation: 1,
                                width: '23%',
                              }}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  height: switchMerchant ? 30 : 20,
                                }}>
                                <View
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    width: 4,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontWeight: '400',
                                    fontSize: switchMerchant ? 12 : 15,
                                    marginLeft: 5,
                                    alignSelf: 'center',
                                  }}>
                                  Average Cashback Amount
                                </Text>
                              </View>
                              <View
                                style={{ marginTop: 10, flexDirection: 'row' }}>
                                <View
                                  style={{
                                    backgroundColor: Colors.primaryColor,
                                    width: 4,
                                    opacity: 0,
                                    marginRight: 5,
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: switchMerchant ? 14 : 28,
                                    fontWeight: '600',
                                  }}>
                                  {/* {customerLastVisitDate ? moment(customerLastVisitDate).format('DD/MM/YYYY') : 'N/A'} */}
                                  {/* {item.uniqueId && userOrderBeerDocketUBDIdDict[item.uniqueId] && userOrderBeerDocketUBDIdDict[item.uniqueId].waiterName ? userOrderBeerDocketUBDIdDict[item.uniqueId].waiterName : 'N/A'} */}
                                  RM{cashbackUsed &&
                                    cashbackNumbers &&
                                    (
                                      (cashbackUsed) /
                                      cashbackNumbers
                                    )
                                      .toFixed(2)
                                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                                    ? (
                                      (cashbackUsed) /
                                      cashbackNumbers
                                    )
                                      .toFixed(2)
                                      .replace(
                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                        '$1,',
                                      )
                                    : '0'}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>

                      <View style={{ width: '100%', marginTop: 15 }}>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 14 : 20,
                            fontWeight: '500',
                            marginBottom: 10,
                          }}>
                          Cashback Transaction History
                        </Text>
                        <View
                          style={{
                            height: switchMerchant ? '66%' : '69%',
                            marginBottom: switchMerchant ? -10 : -25,
                          }}>
                          {selectedCustomerLCCTransactions.length > 0 && selectedCustomerEdit ? (
                            <FlatList
                              showsVerticalScrollIndicator={false}
                              data={selectedCustomerLCCTransactions}
                              // extraData={renderPointRedemptionHistory}
                              renderItem={renderCashbackHistory}
                              keyExtractor={(item, index) => String(index)}
                              style={{ marginBottom: switchMerchant ? -10 : -70 }}
                            />
                          ) : (
                            <View
                              style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: switchMerchant ? '66%' : '70%',
                                marginBottom: switchMerchant ? -10 : -25,
                              }}>
                              <Text style={{ color: Colors.descriptionColor }}>
                                - No Data Available -
                              </Text>
                            </View>
                          )}
                        </View>
                      </View>
                    </View>
                  </View>

                  {/* Show Redemption List Modal  */}
                  {/* <Modal
                    style={
                      {
                        // flex: 1
                      }
                    }
                    visible={showRedemptionAvailability}
                    transparent={true}
                    animationType={'slide'}>
                    <View style={styles.modalContainer1}>
                      <View style={[styles.modalView1, {}]}>
                        <View
                          style={{
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                          }}>
                          <TouchableOpacity
                            onPress={() => {
                              setShowRedemptionAvailability(false);
                            }}>
                            <AntDesign
                              name={'close'}
                              size={25}
                              color={'#858C94'}
                            />
                          </TouchableOpacity>
                        </View>
                        <Text
                          style={{
                            marginTop: 15,
                            alignSelf: 'center',
                            fontSize: 26,
                            fontFamily: 'Nunitosans-Regular',
                            fontWeight: '700',
                            color: '#000000',
                          }}>
                          Point Redemption List
                        </Text>

                        <View
                          style={{
                            backgroundColor: '#FFFFFF',
                            width: Dimensions.get('screen').width * 0.44,
                            height: Dimensions.get('screen').width * 0.38,
                            marginTop: 5,
                            marginHorizontal: 20,
                            alignSelf: 'center',
                            borderRadius: 5,
                            shadowOpacity: 0,
                            shadowColor: '#000',
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 3,
                            //justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 10,
                          }}>
                          <ScrollView showsVerticalScrollIndicator={false}>
                            <FlatList
                              showsVerticalScrollIndicator={false}
                              data={availablePointsRedeemPackages}
                              renderItem={renderPointRedemptionList}
                              keyExtractor={(item, index) => String(index)}
                              style={{ marginTop: 10 }}
                              initialNumToRender={8}
                            />
                          </ScrollView>
                      </View>
                    </View>
                    </View>
                  </Modal> */}
                </View>
              ) : null}

                {/* ***************************E-Indemnity ****************************/}
                {customerDetailsSection ===
                  CUSTOMER_DETAILS_SECTION.E_INDEMNITY ? (
                  <View>
                    <View style={{ margin: 25, marginLeft: 50 }}>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontWeight: "500",
                          fontSize: 25,
                        }}
                      >
                        {" "}
                        Customer Signature{" "}
                      </Text>
                    </View>

                    <View
                      style={{
                        backgroundColor: "#F2F3F7",
                        width: windowWidth * 0.75,
                        height: windowHeight * 0.35,
                        marginTop: 5,
                        marginHorizontal: 20,
                        alignSelf: "center",
                        borderRadius: 5,
                        shadowOpacity: 0,
                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 3,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            alignSelf: "center",
                            fontSize: 20,
                            fontFamily: "Nunitosans-Regular",
                            fontWeight: "700",
                            color: "#B6B6B6",
                          }}
                        >
                          Sign Here
                        </Text>
                        <Text
                          style={{
                            marginTop: 5,
                            alignSelf: "center",
                            fontSize: 20,
                            fontFamily: "Nunitosans-Regular",
                            fontWeight: "700",
                            color: "#B6B6B6",
                          }}
                        >
                          Or
                        </Text>
                        <TouchableOpacity
                          style={{
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: Colors.primaryColor,
                            borderRadius: 5,
                            height: 35,
                            alignItems: "center",
                            flexDirection: "row",
                            justifyContent: "center",
                            width: 200,
                            marginTop: 10,
                          }}
                          onPress={() => {
                            uploadForm();
                          }}
                        >
                          <Text
                            style={{
                              alignSelf: "center",
                              fontSize: 18,
                              fontFamily: "Nunitosans-Regular",
                              fontWeight: "700",
                              color: Colors.whiteColor,
                            }}
                          >
                            Upload Document
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        marginRight: 60,
                        marginTop: 20,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#FFFFFF",
                          borderRadius: 5,
                          height: 40,
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                          width: 150,
                        }}
                      >
                        <Text
                          style={{
                            alignSelf: "center",
                            fontSize: 18,
                            fontFamily: "Nunitosans-Regular",
                            fontWeight: "700",
                            color: Colors.primaryColor,
                          }}
                        >
                          CANCEL
                        </Text>
                      </TouchableOpacity>

                      <TouchableOpacity
                        style={{
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: Colors.primaryColor,
                          borderRadius: 5,
                          height: 40,
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                          width: 150,
                          marginLeft: 10,
                        }}
                      >
                        <Text
                          style={{
                            alignSelf: "center",
                            fontSize: 18,
                            fontFamily: "Nunitosans-Regular",
                            fontWeight: "700",
                            color: Colors.whiteColor,
                          }}
                        >
                          SAVE
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                ) : null}

                {/* ***************************Redemption History ****************************/}
                {/* {customerDetailsSection === CUSTOMER_DETAILS_SECTION.REDEMPTION_HISTORY ? (
              <View style={{ margin: 25, marginLeft: 35 }}>

                <View style={{}}>
                  <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 25 }}>
                    Redemption History
                  </Text>
                </View>

                <View style={{ alignItems: 'center', marginTop: 25, width: '60%', alignSelf: 'center' }}>
                  <ScrollView containerStyle={{ paddingBottom: 15 }}>
                    <FlatList
                      data={selectedCustomerPointsTransactions.filter(item => item.transactionType === USER_POINTS_TRANSACTION_TYPE.REDEEM)}
                      // extraData={renderRedemptionHistory}
                      renderItem={renderRedemptionHistory}
                      keyExtractor={(item, index) => String(index)}
                      style={{}}
                    />
                  </ScrollView>
                </View>

              </View>

            ) : null} */}

                {/* ***************************Promotion****************************/}
                {customerDetailsSection ===
                  CUSTOMER_DETAILS_SECTION.REDEMPTION_HISTORY ? (
                  <View
                    style={{
                      backgroundColor: "#ffffff",
                      paddingVertical: 20,
                      paddingHorizontal: 20,
                      // paddingBottom: switchMerchant ? 40 : 0,
                      // marginBottom: switchMerchant ? -30 : 0,
                      borderRadius: 5,
                      // shadowOpacity: 0,
                      // shadowColor: "#000",
                      // shadowOffset: {
                      //   width: 0,
                      //   height: 2,
                      // },
                      // shadowOpacity: 0.22,
                      // shadowRadius: 3.22,
                      // elevation: 3,
                    }}
                  >
                    <View style={{ marginBottom: 10, marginTop: -5 }}>
                      <Text
                        style={{
                          fontFamily: "Nunitosans-Bold",
                          fontSize: switchMerchant ? 16 : 25,
                        }}
                      >
                        Promotion
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: Colors.tabMint,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Total Promotion
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {totalPromotion}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: Colors.tabGold,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            YTD Promotion
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {ytdPromotion}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: Colors.tabCyan,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Past 3 Months
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {past3MonthsPromotion}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: "#ed5740",
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Past 6 Months
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {past6MonthsPromotion}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: switchMerchant ? 10 : 20,
                        paddingHorizontal: 20,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          flex: switchMerchant ? 0.96 : 0.975,
                          borderRightColor: "lightgrey",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: 13,
                              color: Colors.fieldtTxtColor,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          ></Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          flex: switchMerchant ? 1.8 : 1.82,
                          borderRightColor: "lightgrey",
                          justifyContent: "flex-start",
                          marginHorizontal: 2,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                              textAlign: "left",
                            }}
                          >
                            Promotion Title
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          flex: switchMerchant ? 1.87 : 1.86,
                          borderRightColor: "lightgrey",
                          justifyContent: "flex-start",
                          marginHorizontal: 2,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                              textAlign: "left",
                            }}
                          >
                            Promo Date/Time
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          flex: switchMerchant ? 1.95 : 1.96,
                          borderRightColor: "lightgrey",
                          justifyContent: "flex-start",
                          marginHorizontal: 2,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                              textAlign: "left",
                            }}
                          >
                            Applied Outlet
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        marginTop: 10,
                        marginBottom: switchMerchant ? 40 : 15,
                        borderWidth: 1,
                        borderRadius: 5,
                        padding: 15,
                        paddingVertical: 1,
                        borderColor: Colors.highlightColor,
                        height: switchMerchant
                          ? "60%"
                          : windowWidth <= 1133
                            ? "61%"
                            : "65%",
                      }}
                    >
                      {/* <ScrollView
                  // containerStyle={{}}
                  > */}
                      {promotions.length > 0 && selectedCustomerEdit ? (
                        <FlatList
                          showsVerticalScrollIndicator={false}
                          data={promotions.filter((item) => {
                            var tempPromotionIdList = []
                            if(selectedCustomerEdit){
                              for (var j=0; j<userOrders.length; j++){
                                // If user order have same name and phone with selected customer
                                if(selectedCustomerEdit.name === userOrders[j].userName && selectedCustomerEdit.number === userOrders[j].userPhone){
                                  // If there is promotion id list
                                  if(userOrders[j].promotionIdList){
                                    tempPromotionIdList = userOrders[j].promotionIdList
                                  }
                                }
                              }
                              
                              if(item.uniqueId === tempPromotionIdList){
                                return true
                              }else{
                                return false
                              }
                            }
                            else{
                              return false
                            }
                          })}                          // extraData={renderPurchasedHistory}
                          renderItem={renderPromotion}
                          keyExtractor={(item, index) => String(index)}
                          style={{ marginTop: 10 }}
                        />
                      ) : (
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "71%",
                          }}
                        >
                          <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text>
                        </View>
                      )}
                      {/* </ScrollView> */}
                    </View>
                  </View>
                ) : null}

                {/* ***************************Visitation ****************************/}
                {customerDetailsSection ===
                  CUSTOMER_DETAILS_SECTION.VISITATION ? (
                  <View
                    style={{
                      backgroundColor: "#ffffff",
                      paddingVertical: 20,
                      paddingHorizontal: 20,
                      paddingBottom: switchMerchant ? 50 : 0,
                      marginBottom: switchMerchant ? 55 : 0,
                      borderRadius: 5,
                      // shadowOpacity: 0,
                      // shadowColor: "#000",
                      // shadowOffset: {
                      //   width: 0,
                      //   height: 2,
                      // },
                      // shadowOpacity: 0.22,
                      // shadowRadius: 3.22,
                      // elevation: 3,
                    }}
                  >
                    <View style={{ marginBottom: 10 }}>
                      <Text
                        style={{
                          fontFamily: "Nunitosans-Bold",
                          fontSize: switchMerchant ? 16 : 25,
                        }}
                      >
                        Visitation
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: Colors.tabMint,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23.5%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Visit
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {totalVisit}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: Colors.tabGold,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: 0,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23.5%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Average Visit
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {selectedCustomerDineInOrders.length > 0
                              ? ((lastDateTotalVisit * +1) / totalVisit)
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                              : "0"}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: Colors.tabCyan,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: 0,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23.5%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Last 30 Days Visits
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {/* From today date */}
                            {/* {customerLastVisitDate ? (moment(Date.now()).subtract(20, 'days')).format('DD/MM/YYYY') : 'N/A'} */}
                            {/* From last vist date */}
                            {/* {customerLastVisitDate ? moment(customerLastVisitDate).subtract(30, 'days').format('DD/MM/YYYY') : 'N/A'} */}

                            {lastDateTotalVisit}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: "#ed5740",
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: 0,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23.5%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Last Visit
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {customerLastVisitDate
                              ? moment(customerLastVisitDate).format(
                                "DD MMM YYYY"
                              )
                              : "N/A"}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        marginTop: 30,
                        marginBottom: switchMerchant ? 50 : 15,
                        borderWidth: 1,
                        borderRadius: 5,
                        padding: 15,
                        paddingVertical: 1,
                        borderColor: Colors.highlightColor,
                        height: "67%",
                      }}
                    >
                      {selectedCustomerOrders.length > 0 && selectedCustomerEdit ? (
                        <FlatList
                          showsVerticalScrollIndicator={false}
                          data={selectedCustomerOrders.filter(() => {
                            if(selectedCustomerEdit){
                              return true
                            }
                            else{
                              return false
                            }
                          })}
                          // extraData={renderPurchasedHistory}
                          renderItem={renderPurchasedHistory}
                          keyExtractor={(item, index) => String(index)}
                          style={{}}
                        />
                      ) : (
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "71%",
                          }}
                        >
                          <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                ) : null}

                {/* ***************************Voucher ****************************/}
                {customerDetailsSection === CUSTOMER_DETAILS_SECTION.VOUCHER ? (
                  <View
                    style={{
                      backgroundColor: "#ffffff",
                      paddingVertical: 20,
                      paddingHorizontal: 20,
                      marginBottom: switchMerchant ? -30 : 0,
                      paddingBottom: switchMerchant ? 30 : 0,
                      borderRadius: 5,
                      // shadowOpacity: 0,
                      // shadowColor: "#000",
                      // shadowOffset: {
                      //   width: 0,
                      //   height: 2,
                      // },
                      // shadowOpacity: 0.22,
                      // shadowRadius: 3.22,
                      // elevation: 3,
                      // borderWidth: 1,
                    }}
                  >
                    <View style={{ marginBottom: 10 }}>
                      <Text
                        style={{
                          fontFamily: "Nunitosans-Bold",
                          fontSize: switchMerchant ? 16 : 25,
                        }}
                      >
                        Voucher
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: Colors.tabMint,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Voucher On Hand
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {overviewVoucherActive}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: Colors.tabGold,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Redeemed Voucher
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {/* {`RM${phTotalPayment.toFixed(2)}`} */}
                            {overviewVoucherRedeemed}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: Colors.tabCyan,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View style={{ flexDirection: "row", height: 20 }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Used Voucher
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {overviewVoucherUsed}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: "#ed5740",
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 20,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View style={{ flexDirection: "row", height: 20 }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Expired Voucher
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {overviewVoucherExpired}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: switchMerchant ? 10 : 20,
                        paddingHorizontal: 20,
                      }}
                    >
                      {/* <View style={{ flexDirection: 'row', flex: 0.3, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <View style={{ flexDirection: 'column' }}>
                      <Text style={{ fontSize: 13, color: 'black', fontFamily: 'NunitoSans-Bold' }}></Text>
                    </View>
                  </View> */}
                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1.8,
                          borderRightColor: "lightgrey",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginHorizontal: 2,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            Title
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1,
                          borderRightColor: "lightgrey",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginHorizontal: 2,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            Value
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1.8,
                          borderRightColor: "lightgrey",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginHorizontal: 2,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            Durations
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          flex: switchMerchant ? 1.76 : 1.78,
                          borderRightColor: "lightgrey",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginHorizontal: 3,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            Status
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        marginTop: 10,
                        marginBottom: switchMerchant ? 15 : 15,
                        borderWidth: 1,
                        borderRadius: 5,
                        padding: 15,
                        paddingVertical: 1,
                        borderColor: Colors.highlightColor,
                        height: switchMerchant ? "60%" : "65%",
                      }}
                    >
                      {customerVoucherRedemptions.length > 0 && selectedCustomerEdit ? (
                        <FlatList
                          showsVerticalScrollIndicator={false}
                          data={customerVoucherRedemptions}
                          // extraData={merchantVouchers}
                          renderItem={renderVoucher}
                          keyExtractor={(item, index) => String(index)}
                          style={{}}
                        />
                      ) : (
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: switchMerchant ? "51%" : "71%",
                            marginBottom: switchMerchant ? 50 : 15,
                          }}
                        >
                          <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                ) : null}
                 {/* ***************************Loyalty Voucher****************************/}
              {customerDetailsSection === CUSTOMER_DETAILS_SECTION.LOYALTY_VOUCHER ? (
                <View
                  style={{
                    backgroundColor: '#ffffff',
                    paddingVertical: 20,
                    paddingHorizontal: 20,
                    marginBottom: switchMerchant ? -30 : 0,
                    paddingBottom: switchMerchant ? 30 : 0,
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  }}>
                  <View style={{ marginBottom: 10 }}>
                    <Text
                      style={{
                        fontFamily: 'Nunitosans-Bold',
                        fontSize: switchMerchant ? 16 : 25,
                      }}>
                      Loyalty Vouchers
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <View
                      style={{
                        backgroundColor: Colors.tabMint,
                        padding: switchMerchant ? 10 : 20,
                        paddingRight: switchMerchant ? 10 : 20,
                        borderRadius: 5,
                        shadowColor: '#000',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 1.22,
                        elevation: 1,
                        width: '23%',
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          height: switchMerchant ? 30 : 20,
                        }}>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 4,
                          }}
                        />
                        <Text
                          style={{
                            fontWeight: '400',
                            fontSize: switchMerchant ? 12 : 15,
                            marginLeft: 5,
                            alignSelf: 'center',
                          }}>
                          Voucher On Hand
                        </Text>
                      </View>
                      <View style={{ marginTop: 10, flexDirection: 'row' }}>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 4,
                            opacity: 0,
                            marginRight: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: switchMerchant ? 14 : 28,
                            fontWeight: '600',
                          }}>
                          {overviewVoucherActive}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        backgroundColor: Colors.tabGold,
                        padding: switchMerchant ? 10 : 20,
                        paddingRight: switchMerchant ? 10 : 20,
                        borderRadius: 5,
                        marginLeft: switchMerchant ? 15 : 20,
                        shadowColor: '#000',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 1.22,
                        elevation: 1,
                        width: '23%',
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          height: switchMerchant ? 30 : 20,
                        }}>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 4,
                          }}
                        />
                        <Text
                          style={{
                            fontWeight: '400',
                            fontSize: switchMerchant ? 12 : 15,
                            marginLeft: 5,
                            alignSelf: 'center',
                          }}>
                          Redeemed Voucher
                        </Text>
                      </View>
                      <View style={{ marginTop: 10, flexDirection: 'row' }}>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 4,
                            opacity: 0,
                            marginRight: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: switchMerchant ? 14 : 28,
                            fontWeight: '600',
                          }}>
                          {/* {`RM${phTotalPayment.toFixed(2)}`} */}
                          {overviewVoucherRedeemed}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        backgroundColor: Colors.tabCyan,
                        padding: switchMerchant ? 10 : 20,
                        paddingRight: switchMerchant ? 10 : 20,
                        borderRadius: 5,
                        marginLeft: switchMerchant ? 15 : 20,
                        shadowColor: '#000',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 1.22,
                        elevation: 1,
                        width: '23%',
                      }}>
                      <View style={{ flexDirection: 'row', height: 20 }}>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 4,
                          }}
                        />
                        <Text
                          style={{
                            fontWeight: '400',
                            fontSize: switchMerchant ? 12 : 15,
                            marginLeft: 5,
                            alignSelf: 'center',
                          }}>
                          Used Voucher
                        </Text>
                      </View>
                      <View style={{ marginTop: 10, flexDirection: 'row' }}>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 4,
                            opacity: 0,
                            marginRight: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: switchMerchant ? 14 : 28,
                            fontWeight: '600',
                          }}>
                          {overviewVoucherUsed}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        backgroundColor: '#ed5740',
                        padding: switchMerchant ? 10 : 20,
                        paddingRight: switchMerchant ? 10 : 20,
                        borderRadius: 5,
                        marginLeft: switchMerchant ? 15 : 20,
                        shadowColor: '#000',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 1.22,
                        elevation: 1,
                        width: '23%',
                      }}>
                      <View style={{ flexDirection: 'row', height: 20 }}>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 4,
                          }}
                        />
                        <Text
                          style={{
                            fontWeight: '400',
                            fontSize: switchMerchant ? 12 : 15,
                            marginLeft: 5,
                            alignSelf: 'center',
                          }}>
                          Expired Voucher
                        </Text>
                      </View>
                      <View style={{ marginTop: 10, flexDirection: 'row' }}>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 4,
                            opacity: 0,
                            marginRight: 5,
                          }}
                        />
                        <Text
                          style={{
                            fontSize: switchMerchant ? 14 : 28,
                            fontWeight: '600',
                          }}>
                          {overviewVoucherExpired}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: switchMerchant ? 10 : 20,
                      paddingHorizontal: 20,
                    }}>
                    {/* <View style={{ flexDirection: 'row', flex: 0.3, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <View style={{ flexDirection: 'column' }}>
                      <Text style={{ fontSize: 13, color: 'black', fontFamily: 'NunitoSans-Bold' }}></Text>
                    </View>
                  </View> */}
                    <View
                      style={{
                        flexDirection: 'row',
                        flex: 1.8,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginHorizontal: 2,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 13,
                            color: 'black',
                            fontFamily: 'NunitoSans-Bold',
                          }}>
                          Title
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: 'row',
                        flex: 1,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginHorizontal: 2,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 13,
                            color: 'black',
                            fontFamily: 'NunitoSans-Bold',
                          }}>
                          Value
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: 'row',
                        flex: 1.8,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginHorizontal: 2,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 13,
                            color: 'black',
                            fontFamily: 'NunitoSans-Bold',
                          }}>
                          Durations
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        flex: switchMerchant ? 1.76 : 1.78,
                        borderRightColor: 'lightgrey',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginHorizontal: 3,
                      }}>
                      <View style={{ flexDirection: 'column' }}>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 10 : 13,
                            color: 'black',
                            fontFamily: 'NunitoSans-Bold',
                          }}>
                          Status
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      marginTop: 10,
                      marginBottom: switchMerchant ? 15 : 15,
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 15,
                      paddingVertical: 1,
                      borderColor: Colors.highlightColor,
                      height: switchMerchant ? '60%' : '65%',
                    }}>
                    {customerVoucherRedemptions.length > 0 && selectedCustomerEdit ? (
                      <FlatList
                        showsVerticalScrollIndicator={false}
                        data={customerVoucherRedemptions}
                        // extraData={merchantVouchers}
                        renderItem={renderVoucher}
                        keyExtractor={(item, index) => String(index)}
                        style={{}}
                      />
                    ) : (
                      <View
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: switchMerchant ? '51%' : '71%',
                          marginBottom: switchMerchant ? 50 : 15,
                        }}>
                        <Text style={{ color: Colors.descriptionColor }}>
                          - No Data Available -
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
              ) : null}

                {/* {customerDetailsSection === CUSTOMER_DETAILS_SECTION.DOCKET ? (
              <View style={{}}>
                <View style={{ marginHorizontal: 25, marginVertical: 20, }}>
                  <View style={{ flexDirection: 'column' }}>
                    <View style={{ width: '100%', marginTop: 15, }}>
                      <Text style={{ fontSize: 20, fontWeight: '500', marginBottom: 10, marginLeft: 5 }}>
                        Redeemed docket list:
                      </Text>
                      <ScrollView>
                        <FlatList
                          data={userBeerDockets.filter(item => moment().isSameOrAfter(item.expiryDate))}
                          keyExtractor={(item, index) => String(index)}
                          // extraData={renderPointRedemptionHistory}
                          renderItem={renderRedeemedDocketList}
                          keyExtractor={(item, index) => String(index)}
                          style={{}}
                        />
                      </ScrollView>
                    </View>
                  </View>
                </View>

              </View >


            ) : null} */}

                {/* ***************************Docket****************************/}
                {customerDetailsSection === CUSTOMER_DETAILS_SECTION.DOCKET ? (
                  <View
                    style={{
                      backgroundColor: "#ffffff",
                      paddingVertical: 20,
                      paddingHorizontal: 20,
                      borderRadius: 5,
                      // shadowOpacity: 0,
                      // shadowColor: "#000",
                      // shadowOffset: {
                      //   width: 0,
                      //   height: 2,
                      // },
                      // shadowOpacity: 0.22,
                      // shadowRadius: 3.22,
                      // elevation: 3,
                    }}
                  >
                    <View style={{ marginBottom: 10 }}>
                      <Text
                        style={{
                          fontFamily: "Nunitosans-Bold",
                          fontSize: switchMerchant ? 16 : 25,
                        }}
                      >
                        Docket
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: Colors.tabMint,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 30,
                          borderRadius: 5,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Docket on Hand
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {overviewBeerDocketActive}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: Colors.tabGold,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 30,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Redeemed Docket
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {overviewBeerDocketRedeemed}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: Colors.tabCyan,
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 30,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Used Docket
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {overviewBeerDocketUsed}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          backgroundColor: "#ed5740",
                          padding: switchMerchant ? 10 : 20,
                          paddingRight: switchMerchant ? 10 : 30,
                          borderRadius: 5,
                          marginLeft: switchMerchant ? 15 : 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 1.22,
                          elevation: 1,
                          width: "23%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            height: switchMerchant ? 30 : 20,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                            }}
                          />
                          <Text
                            style={{
                              fontWeight: "400",
                              fontSize: switchMerchant ? 12 : 15,
                              marginLeft: 5,
                              alignSelf: "center",
                            }}
                          >
                            Expired Docket
                          </Text>
                        </View>
                        <View style={{ marginTop: 10, flexDirection: "row" }}>
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 4,
                              opacity: 0,
                              marginRight: 5,
                            }}
                          />
                          <Text
                            style={{
                              fontSize: switchMerchant ? 14 : 28,
                              fontWeight: "600",
                            }}
                          >
                            {overviewBeerDocketExpired}
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: 20,
                        paddingHorizontal: 15,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1,
                          borderRightColor: "lightgrey",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: Colors.fieldtTxtColor,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          ></Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1.8,
                          borderRightColor: "lightgrey",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginHorizontal: 2,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            Items
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1.85,
                          borderRightColor: "lightgrey",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginHorizontal: 2,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            Docket Duration
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1.98,
                          borderRightColor: "lightgrey",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginHorizontal: 2,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            Applied Outlet
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1,
                          borderRightColor: "lightgrey",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          marginHorizontal: 2,
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              color: "black",
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            Status
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        marginTop: 10,
                        marginBottom: switchMerchant ? 30 : 15,
                        borderWidth: 1,
                        borderRadius: 5,
                        padding: 15,
                        paddingVertical: 1,
                        borderColor: Colors.highlightColor,
                        height: switchMerchant ? "55%" : "65%",
                      }}
                    >
                      {customerBeerDockets.length > 0 && selectedCustomerEdit ? (
                        <FlatList
                          showsVerticalScrollIndicator={false}
                          data={customerBeerDockets.filter(() => {
                            if(selectedCustomerEdit){
                              return true
                            }
                            else{
                              return false
                            }
                          })}
                          // extraData={renderPurchasedHistory}
                          renderItem={renderDocket}
                          keyExtractor={(item, index) => String(index)}
                          style={{}}
                        />
                      ) : (
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: switchMerchant ? "51%" : "71%",
                            marginBottom: switchMerchant ? 50 : 15,
                          }}
                        >
                          <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>
                ) : null}
              </View>

              {tagModal ? (
                <View
                  style={{
                    flex: 1,
                    position: "absolute",
                    //backgroundColor: 'white',

                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,

                    borderRadius: 8,
                    top: "5%",
                    left: "10%",
                  }}
                // visible={tagModal}
                // transparent={true}
                >
                  <View
                    style={[
                      styles.modalContainer,
                      {
                        backgroundColor: "transparent",

                        backgroundColor: "white",

                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 3,

                        borderWidth: 1,
                        borderRadius: 8,
                        // top:
                        //   Platform.OS === 'ios' && keyboardHeight > 0
                        //     ? -keyboardHeight * 0.3
                        //     : 0,
                      },
                    ]}
                  >
                    <View
                      style={[
                        styles.modalView,
                        {
                          //height: windowWidth * 0.3,
                          // top: Platform.OS === 'ios' && keyboardHeight > 0 ? -keyboardHeight * 0.3 : 0,
                          backgroundColor: "transparent",
                          borderRadius: windowWidth * 0.03,
                        },
                      ]}
                    >
                      <TouchableOpacity
                        style={styles.closeButton}
                        onPress={() => {
                          setTagModal(false);
                        }}
                      >
                        {/* <AIcon name="closecircle" size={25} color={Colors.fieldtTxtColor} /> */}
                        <AntDesign
                          name="closecircle"
                          size={25}
                          color={Colors.fieldtTxtColor}
                        />
                      </TouchableOpacity>

                      <View style={{ marginBottom: 2 }}>
                        <Text
                          style={{
                            fontWeight: "700",
                            fontSize: switchMerchant ? 16 : 21,
                          }}
                        >
                          Tags
                        </Text>
                      </View>

                      {/* <View
                      style={{
                        // backgroundColor: Colors.fieldtBgColor,
                        //width: 180,
                        height: 40,
                        borderRadius: 5,
                        paddingVertical: 3,
                        paddingLeft: 5,
                        marginVertical: 5,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}>
                      <View style={{
                        flexDirection: 'row', alignItems: 'center', width: '60%',
                        zIndex: 1,
                      }}>
                        <TextInput
                          style={{
                            marginTop: 0, alignSelf: 'center',
                            fontSize: 16, color: "black", fontFamily: 'NunitoSans-Bold',
                            width: 150,

                            marginTop: Platform.OS === 'ios' ? 0 : -15,
                            marginBottom: Platform.OS === 'ios' ? 0 : -15,
                          }}
                          placeholder='Example: VIP'
                          onChangeText={(text) => {
                            setUserTagName(text);
                          }}
                          defaultValue={userTagName}
                        //value={}
                        />
                      </View>

                      <View style={{
                        width: '30%', flexDirection: 'row', justifyContent: 'flex-end',
                        alignItems: 'center',
                        // zIndex: -1,
                        // backgroundColor: 'red',
                      }}>
                        <TouchableOpacity
                          style={{
                            backgroundColor: Colors.primaryColor, width: 70, height: 35, justifyContent: 'center', alignItems: 'center',
                            borderRadius: 5,
                            // zIndex: -1,
                          }}
                          onPress={createCRMUserTag}
                        >
                          <Text style={{ fontSize: 17, fontWeight: '600', color: 'white' }}>
                            Create
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View> */}

                      <View
                        style={{
                          backgroundColor: Colors.fieldtBgColor,
                          //width: 180,
                          height: 40,
                          borderRadius: 5,
                          paddingVertical: 3,
                          paddingLeft: 5,
                          marginVertical: 5,
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            width: "60%",
                            zIndex: 1,
                          }}
                        >
                          <AntDesign
                            name="tags"
                            size={switchMerchant ? 15 : 20}
                            style={{ color: "black" }}
                          />
                          {/* <TextInput
                                placeholder='Enter Tag(s)'
                                style={{
                                  padding: 5,
                                }}
                              /> */}
                          <Select
                            containerStyle={{
                              marginLeft: 5,
                              height: 35,
                              zIndex: 2,
                            }}
                            arrowColor={"black"}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: "bold" }}
                            labelStyle={{
                              fontFamily: "NunitoSans-Regular",
                              fontSize: switchMerchant ? 10 : 16,
                            }}
                            style={{
                              width: 250,
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                            }}
                            placeholderStyle={{ color: Colors.fieldtTxtColor }}
                            placeholder={"Select"}
                            multipleText={"%d tag(s) selected"}
                            items={userTagDropdownList}
                            itemStyle={{
                              justifyContent: "flex-start",
                              marginLeft: 5,
                              zIndex: 2,
                            }}
                            customTickIcon={(press) => (
                              <Ionicon
                                name={"md-checkbox"}
                                color={
                                  press
                                    ? Colors.fieldtBgColor
                                    : Colors.primaryColor
                                }
                                size={switchMerchant ? 15 : 25}
                              />
                            )}
                            onChangeItem={(items) => {
                              setSelectedUserTagList(items);
                            }}
                            defaultValue={selectedUserTagList}
                            multiple={true}
                            searchable={true}
                            dropDownMaxHeight={150}
                            dropDownStyle={{
                              width: 250,
                              height: 150,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              borderWidth: 1,
                              textAlign: "left",
                              zIndex: 2,
                            }}
                          // style={{
                          //   height: 35,
                          //   width: 185,
                          //   marginLeft: 5, paddingVertical: 0, borderColor: 'darkgreen'
                          // }}
                          //   dropDownStyle={{
                          //     marginLeft: 5, marginTop: 1, borderWidth: 1,
                          //     // borderColor: 'darkgreen',
                          //     width: 185
                          //   }}
                          //   arrowSize={20}
                          //   arrowStyle={{ paddingVertical: -10 }}
                          //   // items={[{ label: 'Shampoo', value: 1 }, { label: 'Conditioner', value: 2 }, { label: 'Hair mask', value: 3 }]}
                          //   items={userTagDropdownList}
                          //   placeholder={'Select'}
                          //   onChangeItem={(items) => {
                          //     setSelectedUserTagList(items);
                          //   }}
                          //   defaultValue={selectedUserTagList}
                          //   multiple={true}
                          //   searchable={true}
                          //   dropDownMaxHeight={130}
                          //   onSearch={text => {
                          //     setSearchingUserTagText(text);
                          //   }}
                          //   customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                          />
                        </View>

                        <View
                          style={{
                            width: "30%",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            //zIndex: -1,
                          }}
                        >
                          <TouchableOpacity
                            style={{
                              backgroundColor: Colors.primaryColor,
                              width: 70,
                              height: 35,
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 5,

                              flexDirection: "row",
                            }}
                            disabled={isLoading}
                            // onPress={addUserToCRMUserTag}
                            onPress={createCRMUserTagOrAddUserToCRMUserTag}
                          >
                            <Text
                              style={{
                                fontSize: switchMerchant ? 11 : 17,
                                fontWeight: "600",
                                color: "white",
                              }}
                            >
                              {isLoading ? "" : "Add"}
                            </Text>

                            {isLoading ? (
                              <ActivityIndicator
                                color={Colors.whiteColor}
                                size={"small"}
                              />
                            ) : (
                              <></>
                            )}
                          </TouchableOpacity>
                          {/* <TouchableOpacity
                          style={{
                            backgroundColor: 'green', width: 70, height: 35, justifyContent: 'center', alignItems: 'center',
                            borderRadius: 5,
                            // zIndex: -1,
                          }}
                          onPress={() => { }}
                        >
                          <Text style={{ fontSize: 17, fontWeight: '600', color: 'white' }}>
                            Create
                          </Text>
                        </TouchableOpacity> */}
                        </View>
                      </View>
                      {/* <View>
                          <DropDownPicker style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 40, width: 230, paddingVertical: 0, margin: 0 }}
                                  dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 230, paddingVertical: 0, margin: 5 }}
                                  arrowSize={20}
                                  arrowColor={'black'}
                                  arrowStyle={{ fontWeight: 'bold' }}
                                  placeholderStyle={{ marginLeft: 0, color: Colors.fieldtTxtColor }}
                                  placeholder={"Products"}
                                  items={[{ label: 'Shisheido shampoo', value: 1 }, { label: 'Shisheido conditioner', value: 2 }, { label: 'Shisheido Hair mask', value: 3 }]}
                                  //items={variationItemsDropdownList}
                                  customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                  // onChangeItem={() =>
                                    
                                  // }
                                  defaultValue={1}
                                  multiple={true}
                                  searchable={true}
                                  dropDownMaxHeight={130}
                                />
                          </View> */}

                      {/* Choosen Tags Start */}
                      <View
                        style={{ minHeight: 40, maxHeight: 140, zIndex: -1 }}
                      >
                        <ScrollView
                          style={{
                            //marginRight: 12,
                            zIndex: -1,
                            minHeight: 70,
                          }}
                          horizontal={true}
                          showsHorizontalScrollIndicator={false}
                          showsVerticalScrollIndicator={false}
                        >
                          <FlatList
                            showsVerticalScrollIndicator={false}
                            data={userTagList}
                            //numRows={2}
                            numColumns={3}
                            renderItem={renderUserTag}
                            keyExtractor={(item, index) => String(index)}
                            style={{
                              paddingVertical: 5,
                              paddingHorizontal: 5,
                            }}
                            //horizontal={true}
                            showsHorizontalScrollIndicator={false}
                          />
                        </ScrollView>
                      </View>
                      {/* Choosen Tags End */}

                      {/* <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignItems: 'center', marginVertical: 5, zIndex: -1 }}>
                      <View style={{ borderWidth: 1, borderColor: '#E5E5E5', width: '26%', height: 1 }} />
                      <Text style={{ fontWeight: 'bold', marginHorizontal: 5, fontSize: 20 }}>
                        User Own Tags
                      </Text>
                      <View style={{ borderWidth: 1, borderColor: '#E5E5E5', width: '26%', height: 1 }} />
                    </View> */}

                      {/* Suggested Tags Start */}
                      {/* <View style={{ zIndex: -1 }}>
                      <TouchableOpacity
                        style={{ borderWidth: 1.5, borderColor: Colors.secondaryColor, alignSelf: 'baseline', padding: 5, paddingHorizontal: 10, borderRadius: 5 }}
                        onPress={() => { }}
                      >
                        <Text style={{ fontWeight: '600', color: Colors.tabGold }}>
                          VIP
                        </Text>
                      </TouchableOpacity>
                    </View> */}
                      {/* Suggested Tags End */}
                    </View>
                  </View>
                </View>
              ) : (
                <></>
              )}
            </ScrollView>
          </KeyboardAvoidingView>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },

  list: {
    backgroundColor: Colors.whiteColor,
    width: Dimensions.get("screen").width * 0.85,
    height: Dimensions.get("screen").height * 0.825,
    marginTop: 5,
    marginHorizontal: 20,
    alignSelf: "center",
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  list1: {
    backgroundColor: Colors.whiteColor,
    // minHeight: windowHeight * 0.1,
    //maxHeight: windowHeight * 0.825,
    marginTop: 5,
    marginHorizontal: 35,
    alignSelf: "center",
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
    //paddingBottom: 20,
  },
  list2: {
    backgroundColor: Colors.whiteColor,
    marginTop: 5,
    marginHorizontal: 20,
    alignSelf: "center",
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
    paddingBottom: 20,
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    //width: windowWidth * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 16,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  titleList: {
    backgroundColor: "#ffffff",
    flexDirection: "row",
    paddingVertical: 20,
    paddingHorizontal: 20,
    //marginTop: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

    alignItems: "center",

    // shadowOpacity: 0,
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.22,
    // shadowRadius: 3.22,
    // elevation: 3,
  },
  textTitle: {
    fontSize: 16,
    fontFamily: "NunitoSans-Bold",
  },
  textItem: {
    fontSize: 14,
    fontFamily: "NunitoSans-Regular",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    // alignContent: 'center',
    alignItems: "center",
    backgroundColor: "white",
    padding: 20,
    paddingTop: 15,
    marginTop: 0,
    width: "100%",

    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 7,
  },
  addVoucher: {
    marginTop: 0,
    //justifyContent: 'center',
    alignItems: "center",
    //alignContent: 'center',
    backgroundColor: Colors.whiteColor,
    // marginRight: 100,

    borderRadius: 4,

    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
  textInput: {
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 20,
    width: 300,
  },
  textInput1: {
    width: 250,
    height: 40,
    backgroundColor: "white",
    borderRadius: 10,
    // marginLeft: '53%',
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  headerLeftStyle: {
    justifyContent: "center",
    alignItems: "center",
  },
  addNewView: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: -10,
    marginTop: 5,
    width: "97%",
    alignSelf: "flex-end",
  },
  addButtonView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    backgroundColor: "#4E9F7D",
    borderRadius: 5,
    width: 160,
    height: 40,
    alignItems: "center",
  },
  editButtonView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    backgroundColor: "#4E9F7D",
    borderRadius: 5,
    width: 74,
    height: 35,
    alignItems: "center",
  },
  ManageFilterBox: {
    //borderRadius: 10,
    backgroundColor: Colors.whiteColor,
    //justifyContent: 'space-between'
  },
  ManageFilterBox1: {
    //borderRadius: 10,
    backgroundColor: Colors.whiteColor,
    justifyContent: "center",
    borderColor: "#E5E5E5",
    borderWidth: 1,
    alignItems: "center",
    alignSelf: "center",
    //padding: 10,
    margin: 15,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    //height: windowWidth * 0.4,
    //width: windowWidth * 0.4,
    height: 295,
    width: 415,
    backgroundColor: Colors.whiteColor,
    padding: 20,
    paddingTop: 25,
    //alignItems: 'center',
    //justifyContent: 'center'
  },
  modalContainer1: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView1: {
    backgroundColor: Colors.whiteColor,
    padding: 20,
    paddingTop: 25,
    //alignItems: 'center',
    //justifyContent: 'center'
  },
  closeButton: {
    position: "relative",
    alignSelf: "flex-end",
    marginRight: -10,
    marginTop: -15,
    //right: windowWidth * 0.03,
    //top: windowWidth * 0.03,

    // elevation: 1000,
    // zIndex: 1000,
    // height: windowWidth * 0.5,
    // width: windowWidth * 0.45,
    // backgroundColor: Colors.whiteColor,
    // borderRadius: 12,
    // padding: windowWidth * 0.03,

    //alignItems: 'center',
    //justifyContent: 'center'
  },
});

export default NewCustomer;
