import React, { Component, useReducer, useState, useRef, useEffect } from 'react';
import { StyleSheet, ScrollView, Image, View, Text, Alert, TouchableOpacity, Modal, Dimensions, TextInput, FlatList, Animated, KeyboardAvoidingView } from 'react-native';
import Colors from '../constant/Colors';
// import Pie from 'react-native-pie';


const Speedometer = props => {
  const {
    navigation,
  } = props;

  const [gaugeValue, setGaugeValue] = useState(0);
  const GaugeAnim = useRef(new Animated.Value(0)).current;

  const fadeIn = () => {
    Animated.timing(GaugeAnim, {
      toValue: gaugeValue,
      duration: 1000
    }).start();
  };

  const spin = () => {
    return (
      GaugeAnim.interpolate({
        inputRange: [0, 180],
        outputRange: ['0deg', '180deg'],
      }))
  }


  return (
    <View style={{ paddingVertical: 10 }}>
      <View>
        <TextInput
          placeholder={'Insert Number 0-180'}
          onChangeText={(text) => {
            //fadeIn(text)
            setGaugeValue(text)
          }}
          value={gaugeValue}
        />
      </View>
      <View style={{ width: 180, height: 180, backgroundColor: 'white', marginLeft: 15, transform: [{ rotate: '-90deg' }] }}>
        <Text>Pie chart here</Text>
        {/* <Pie
          radius={90}
          innerRadius={75}
          sections={[
            {
              percentage: 10,
              color: Colors.primaryColor,
            },
            {
              percentage: 20,
              color: Colors.secondaryColor,
              //color: Colors.tabYellow,
            },
            {
              percentage: 20,
              color: Colors.tabRed,
            },
            {
              percentage: 50,
              color: 'white',
            },
          ]}
          //dividerSize={0}
          strokeCap={'butt'}
        /> */}
      </View>
      <Animated.View style={{
        marginLeft: 15, width: 180, transform: [{
          rotate: `${gaugeValue}deg`,
        }], top: -90,
      }}>
        <View style={{ width: 90, borderWidth: 2, borderBottomStartRadius: 5, borderTopStartRadius: 5, borderColor: '#1d3960', elevation: 4 }} />
      </Animated.View>
      <View style={{ flexDirection: 'row', width: 210, justifyContent: 'space-between', top: -90 }}>
        <Text style={{ textAlign: 'center', fontWeight: '500', color: Colors.fieldtTxtColor }}>
          Specific
        </Text>
        <Text style={{ textAlign: 'center', fontWeight: '500', color: Colors.fieldtTxtColor }}>
          Board
        </Text>
      </View>
    </View>

  )


}

export default Speedometer;