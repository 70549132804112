import moment from 'moment';
import { CHART_COLORS, CHART_DATA, CHART_FIELD_COMPARE_DICT, CHART_FIELD_TYPE, CHART_PERIOD, CHART_TYPE, CHART_X_AXIS_TYPE, MONTH_TO_CHART_LABEL } from '../constant/chart';
import { countWeekdayOccurrencesInMonth, weekOfMonth } from './common';

export const getDataForChartDashboardTodaySales = (allOutlets, allOutletsUserOrdersDone, chartPeriod, yAxisKey, startDate = new Date(), endDate = new Date()) => {
    // endDate = moment(endDate).add(1, 'day');

    console.log('data before chart');

    var parsedData = [];

    var yMinValue = 0;
    var yMaxValue = 0;

    // const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
    //     return moment().isSame(order.completedDate, 'day');
    // });

    for (var i = 0; i < allOutlets.length; i++) {
        var record = {
            label: allOutlets[i].name,
            value: 0,
        }

        if (chartPeriod === CHART_PERIOD.TODAY) {
            const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
                return moment().isSame(moment(order.completedDate), 'day');
            });

            for (var x = 0; x < 24; x++) {
                const currDay = moment().startOf('day').add(x, 'hour');

                for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                    if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId &&
                        moment(currDay).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'hour')) {
                        record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                        console.log('Got Loop Here1')
                    }
                }
            }

            if (record.value > yMaxValue) {
                yMaxValue = record.value;
            }

            record.value = record.value.toFixed(0);

            parsedData.push(record);
        }
        else if (chartPeriod === CHART_PERIOD.THIS_WEEK) {
            const startDate = moment().subtract(1, 'week').startOf('day');
            const endDate = moment().endOf('day');

            const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
                return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
            });

            for (var x = 0; x < moment(endDate).diff(startDate, 'day'); x++) {
                const currDay = moment(startDate).add(x, 'day');

                for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                    if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId &&
                        moment(currDay).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'day')) {
                        record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                        console.log('Got Loop Here1')
                    }
                }
            }

            // yMaxValue += record.value;
            if (record.value > yMaxValue) {
                yMaxValue = record.value;
            }

            record.value = record.value.toFixed(0);

            parsedData.push(record);
        }
        else if (chartPeriod === CHART_PERIOD.THIS_MONTH) {
            // const startDate = moment().subtract(moment(startDate).daysInMonth(), 'day').startOf('day');
            // const endDate = moment().endOf('day');

            const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
                return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
            });

            for (var x = 0; x < moment(startDate).daysInMonth(); x++) {
                const currDay = moment(startDate).add(x, 'day');

                for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                    if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId &&
                        moment(currDay).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'day')) {
                        record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                        console.log('Got Loop Here1')
                    }
                }
            }

            // yMaxValue += record.value;
            if (record.value > yMaxValue) {
                yMaxValue = record.value;
            }

            record.value = record.value.toFixed(0);

            parsedData.push(record);
        }
        else if (chartPeriod === CHART_PERIOD.THREE_MONTHS) {
            const startDate = moment().subtract(2, 'month').startOf('month');
            const endDate = moment().endOf('month');

            const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
                return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
            });

            var currWeekCount = 0;
            var prevMonth = moment(startDate);

            for (var x = 0; x < moment(endDate).diff(startDate, 'week'); x++) {
                const currWeek = moment(startDate).add(x, 'week');

                if (moment(currWeek).isSame(prevMonth, 'month')) {
                    currWeekCount++;
                }
                else {
                    prevMonth = moment(currWeek);

                    currWeekCount = 0;
                    currWeekCount++;
                }

                for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                    if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId &&
                        moment(currWeek).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'week')) {
                        record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                        console.log('Got Loop Here1')
                    }
                }
            }

            if (record.value > yMaxValue) {
                yMaxValue = record.value;
            }

            record.value = record.value.toFixed(0);

            parsedData.push(record);
        }

        else if (chartPeriod === CHART_PERIOD.SIX_MONTHS) {
            const startDate = moment().subtract(5, 'month').startOf('month');
            const endDate = moment().endOf('month');

            const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
                return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
            });

            var currWeekCount = 0;
            var prevMonth = moment(startDate);

            for (var x = 0; x < moment(endDate).diff(startDate, 'week'); x++) {
                const currWeek = moment(startDate).add(x, 'week');

                if (moment(currWeek).isSame(prevMonth, 'month')) {
                    currWeekCount++;
                }
                else {
                    prevMonth = moment(currWeek);

                    currWeekCount = 0;
                    currWeekCount++;
                }

                for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                    if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId &&
                        moment(currWeek).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'week')) {
                        record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                        console.log('Got Loop Here1')
                    }
                }
            }

            if (record.value > yMaxValue) {
                yMaxValue = record.value;
            }

            record.value = record.value.toFixed(0);

            parsedData.push(record);
        }

        else if (chartPeriod === CHART_PERIOD.THIS_YEAR) {

            const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
                return moment().isSame(moment(order.completedDate), 'year');
            });

            for (var x = 0; x < 12; x++) {
                const currMonth = moment().startOf('year').add(x, 'month');

                for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                    if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId &&
                        moment(currMonth).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'month')) {
                        record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                        console.log('Got Loop Here1')
                    }
                }
            }

            if (record.value > yMaxValue) {
                yMaxValue = record.value;
            }

            record.value = record.value.toFixed(0);

            parsedData.push(record);
        }

        else if (chartPeriod === CHART_PERIOD.YTD) {
            const startDate = moment().subtract(11, 'month').startOf('month');
            const endDate = moment().endOf('month');

            const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
                return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
            });

            for (var x = 0; x < moment(endDate).diff(startDate, 'month'); x++) {
                const currMonth = moment(startDate).add(x, 'month');

                for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                    if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId &&
                        moment(currMonth).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'month')) {
                        record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                        console.log('Got Loop Here1')
                    }
                }
            }

            if (record.value > yMaxValue) {
                yMaxValue = record.value;
            }

            record.value = record.value.toFixed(0);

            parsedData.push(record);
        }
        else if (chartPeriod === CHART_PERIOD.NONE) {
            const allOutletsUserOrdersDoneTodayRange = allOutletsUserOrdersDone.filter(order => {
                return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
            });

            if (moment(endDate).diff(startDate, 'day') <= moment(startDate).daysInMonth()) {
                endDate = moment(endDate).add(1, 'day');

                const diffDays = moment(endDate).diff(startDate, 'day');

                var prevDate = moment(startDate).add(-1, 'month');

                for (var k = 0; k < diffDays; k++) {
                    const currDate = moment(startDate).add(k, 'day');

                    // category.push({
                    //     //'label': moment(currDate).format('DD MMM'),
                    //     'label': !moment(currDate).isSame(prevDate, 'month') ? moment(currDate).format('DD MMM') : moment(currDate).format('DD')
                    //     //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))

                    // });

                    prevDate = currDate;

                    for (var j = 0; j < allOutletsUserOrdersDoneTodayRange.length; j++) {
                        if (allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
                            moment(currDate).isSame(moment(allOutletsUserOrdersDoneTodayRange[j].completedDate), 'day')) {
                            record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                        }
                    }
                }

                if (record.value > yMaxValue) {
                    yMaxValue = record.value;
                }

                record.value = record.value.toFixed(0);

                parsedData.push(record);
            }
            else if (moment(endDate).diff(startDate, 'month') <= 6) {
                var prevMonth = moment(startDate);

                const diffWeeks = moment(endDate).diff(startDate, 'week');

                for (var k = 0; k < diffWeeks; k++) {
                    const currDate = moment(startDate).add(k, 'week');

                    if (moment(currDate).isSame(prevMonth, 'month')) {
                        currWeekCount++;
                    }
                    else {
                        prevMonth = moment(currDate);

                        currWeekCount = 0;
                        currWeekCount++;
                    }

                    // category.push({
                    //     // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                    //     label: moment(currDate).format('MMM') + ' W' + countWeekdayOccurrencesInMonth(currDate),
                    // });

                    for (var j = 0; j < allOutletsUserOrdersDoneTodayRange.length; j++) {
                        if (allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
                            moment(currDate).isSame(moment(allOutletsUserOrdersDoneTodayRange[j].completedDate), 'week')) {
                            record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                        }
                    }
                }

                if (record.value > yMaxValue) {
                    yMaxValue = record.value;
                }

                record.value = record.value.toFixed(0);

                parsedData.push(record);
            }
            else
            // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
            {
                const diffYears = moment(endDate).diff(startDate, 'month');

                for (var k = 0; k < diffYears; k++) {
                    const currDate = moment(startDate).add(k, 'month');

                    // category.push({
                    //     label: moment(currDate).format('YY\' MMM'),
                    // });

                    for (var j = 0; j < allOutletsUserOrdersDoneTodayRange.length; j++) {
                        if (allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
                            moment(currDate).isSame(moment(allOutletsUserOrdersDoneTodayRange[j].completedDate), 'month')) {
                            record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                        }
                    }
                }

                if (record.value > yMaxValue) {
                    yMaxValue = record.value;
                }

                record.value = record.value.toFixed(0);

                parsedData.push(record);
            }
        }

        // else if (chartPeriod === CHART_PERIOD.DEFAULT ) {
        //     for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
        //         if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId) {
        //             record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];

        //         }
        //     }

        //     if (record.value > yMaxValue) {
        //         yMaxValue = record.value;
        //     }

        //     record.value = record.value.toFixed(0);

        //     parsedData.push(record);
        // }

        // else {
        //     for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
        //         if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId) {
        //             record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
        //         }
        //     }

        //     if (record.value > yMaxValue) {
        //         yMaxValue = record.value;
        //     }

        //     record.value = record.value.toFixed(0);

        //     parsedData.push(record);
        // }
    }

    var output = {};

    output.type = CHART_DATA[CHART_TYPE.DASHBOARD_TODAY_SALES].type;
    output.dataFormat = CHART_DATA[CHART_TYPE.DASHBOARD_TODAY_SALES].dataFormat;
    output.dataSource = {
        chart: CHART_DATA[CHART_TYPE.DASHBOARD_TODAY_SALES].chart,

        data: parsedData,
    };

    console.log('parsedData');
    console.log(parsedData);
    console.log(yMaxValue);

    if (output.type !== 'HLinearGauge') {
        // const yAxisStep = Math.pow(10, Math.min(1, (yMaxValue.toFixed(0).length - 1)));

        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        const baseNumberLength = yMaxValue.toFixed(0).length - 1;
        const baseNumber = parseInt(('1' + Array(baseNumberLength).fill().map(num => 0).join('')));
        var maxNumber = baseNumber;

        if (yMaxValue > (baseNumber * 5)) {
            maxNumber = baseNumber * 10;
        }
        else if (yMaxValue > (baseNumber * 3)) {
            maxNumber = baseNumber * 5;
        }
        else if (yMaxValue > (baseNumber * 2)) {
            maxNumber = baseNumber * 3;
        }
        else {
            maxNumber = baseNumber * 2;
        }

        maxNumber = Math.max(maxNumber, 100);

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            // showYAxisValues: 0,

            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,
            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('/////////////////////////////////////////////////')

        // console.log({
        //     yMaxValue: yMaxValue,
        //     yMaxValueParsed: yMaxValueParsed,
        //     yaxismaxvalue: yAxisMaxValue * yAxisStep,
        //     yaxisminvalue: yMinValue,
        //     adjustDiv: 0,
        //     numDivLines: numDivLines,
        // });

        // console.log('/////////////////////////////////////////////////')
    }

    // if (output.type === 'column2D') {
    //     if (type === CHART_TYPE.S_MILK) {
    //         // for (let i = 0; i < output.dataSource.data.length; i++) {
    //         //     if (output.dataSource.data[i].value.length > 0) {
    //         //         yMaxValue = parseInt(output.dataSource.data[i].value) > yMaxValue ? parseInt(output.dataSource.data[i].value) : yMaxValue;

    //         //     }
    //         // }

    //         // const yMaxValueParsed = Math.ceil(yMaxValue / 5);
    //         // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
    //         // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

    //         // output.dataSource.chart = {
    //         //     ...output.dataSource.chart,
    //         //     adjustDiv: 0,
    //         //     numDivLines: numDivLines,
    //         //     yaxismaxvalue: yAxisMaxValue * 5,
    //         //     yaxisminvalue: yMinValue,
    //         //     formatNumberScale: 0,
    //         // };
    //     }
    //     else if (type === CHART_TYPE.S_SHIT_PEE) {
    //         for (let i = 0; i < output.dataSource.data.length; i++) {
    //             if (output.dataSource.data[i].value.length > 0) {
    //                 yMaxValue = parseInt(output.dataSource.data[i].value) > yMaxValue ? parseInt(output.dataSource.data[i].value) : yMaxValue;
    //             }
    //         }

    //         output.dataSource.chart = {
    //             ...output.dataSource.chart,
    //             adjustDiv: 0,
    //             numDivLines: yMaxValue,
    //             yaxismaxvalue: yMaxValue + 1,
    //             yaxisminvalue: yMinValue,
    //             formatNumberScale: 0,
    //         };
    //     }
    // }

    console.log('chart output');
    console.log(output);

    return output;
};

///////////data for crmChart///////////////////
// export const getDataForCrmBarChart = (allOutlets, allOutletsUserOrdersDone, chartPeriod, yAxisKey) => {

//     var parsedData = [];

//     var yMinValue = 0;
//     var yMaxValue = 0;

//     if (chartPeriod === CHART_PERIOD.TODAY) {
//         const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
//             return moment().isSame(moment(order.completedDate), 'day');
//         });

//         for (var i = 0; i < 24; i++) {
//             const currHour = moment().startOf('day').add(i, 'hour');

//             var record = {
//                 label: i.toString().padStart(2, '0'),
//                 value: 0,
//             };

//             for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
//                 if (moment(currHour).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'hour')) {
//                     record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
//                 }
//             }

//             if (record.value > yMaxValue) {
//                 yMaxValue = record.value;
//             }

//             record.value = record.value.toFixed(0);

//             parsedData.push(record);
//         }

// }
// }



export const getDataForSalesLineChart = (allOutlets, allOutletsUserOrdersDone, chartPeriod, yAxisKey, startDate = new Date(), endDate = new Date()) => {
    // endDate = moment(endDate).add(1, 'day');
    // endDate = moment(endDate).endOf('day');

    console.log('data before chart');

    var parsedData = [];

    var yMinValue = 0;
    var yMaxValue = 0;

    var category = [];
    var datasetDict = {};

    var outletNameDict = {};
    for (var i = 0; i < allOutlets.length; i++) {
        outletNameDict[allOutlets[i].uniqueId] = allOutlets[i].name;
    }

    // var annotations = {
    //     "groups": [{
    //         "items": [{
    //             //Text annotation 1
    //             "type": "text",
    //             "text": 'Hello',
    //             //Define the attributes needed to create a text annotation
    //         }, {
    //             //Text annotation n
    //             "type": "text",
    //             "text": 'Hi',
    //             //Define the attributes needed to create a text annotation
    //         }]
    //     }]
    // };

    var annotations = {
        "groups": [{
            "items": [],
        }],
    };
    var annotationsItems = [];

    if (chartPeriod === CHART_PERIOD.TODAY) {
        const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
            return moment().isSame(moment(order.completedDate), 'day');
        });

        for (var i = 0; i < allOutlets.length; i++) {
            datasetDict[allOutlets[i].uniqueId] = {
                seriesname: allOutlets[i].name,
                data: Array.from(Array(24)).map(() => {
                    return { value: 0 };
                }),
            };
        }

        for (var i = 0; i < 24; i++) {
            const currHour = moment().startOf('day').add(i, 'hour');

            // var record = {
            //     label: i.toString().padStart(2, '0'),
            //     value: 0,
            // };

            category.push({
                'label': i.toString().padStart(2, '0'),
            });

            for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                if (moment(currHour).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'hour')) {
                    datasetDict[allOutletsUserOrdersDoneToday[j].outletId].data[i].value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                    // record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                }
            }

            // if (record.value > yMaxValue) {
            //     yMaxValue = record.value;
            // }

            // record.value = record.value.toFixed(0);

            // parsedData.push(record);
        }
    }
    else if (chartPeriod === CHART_PERIOD.THIS_WEEK) {
        // const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
        //     return moment().isSame(moment(order.completedDate), 'week');
        // });

        const startDate = moment().subtract(1, 'week').startOf('day');
        const endDate = moment().endOf('day');

        const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
            return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
        });

        console.log('allOutletsUserOrdersDoneToday');
        console.log(allOutletsUserOrdersDoneToday);

        for (var i = 0; i < allOutlets.length; i++) {
            datasetDict[allOutlets[i].uniqueId] = {
                seriesname: allOutlets[i].name,
                data: Array.from(Array(moment(endDate).diff(startDate, 'day'))).map(() => {
                    return { value: 0 };
                }),
            };
        }

        for (var i = 0; i < moment(endDate).diff(startDate, 'day'); i++) {
            // const currDay = moment().startOf('week').add(i, 'day');
            const currDay = moment(startDate).add(i, 'day');

            // var record = {
            //     label: moment().day(i).format('ddd'),
            //     value: 0,
            // };

            category.push({
                'label': moment().day(i).format('ddd'),
            });

            for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                if (moment(currDay).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'day')) {
                    datasetDict[allOutletsUserOrdersDoneToday[j].outletId].data[i].value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                    // record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                }
            }

            // if (record.value > yMaxValue) {
            //     yMaxValue = record.value;
            // }

            // record.value = record.value.toFixed(0);

            // parsedData.push(record);
        }
    }
    else if (chartPeriod === CHART_PERIOD.THIS_MONTH) {
        // const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
        //     return moment().isSame(moment(order.completedDate), 'month');
        // });

        // const startDate = moment().subtract(moment(startDate).daysInMonth(), 'day').startOf('day');
        // const endDate = moment().endOf('day');

        const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
            return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
        });

        for (var i = 0; i < allOutlets.length; i++) {
            datasetDict[allOutlets[i].uniqueId] = {
                seriesname: allOutlets[i].name,
                data: Array.from(Array(moment(startDate).daysInMonth())).map(() => {
                    return { value: 0 };
                }),
            };
        }

        for (var i = 0; i < moment(startDate).daysInMonth(); i++) {
            // for (var i = 0; i < 31; i++) {
            // const currDay = moment().startOf('month').add(i, 'day');
            const currDay = moment(startDate).add(i, 'day');

            // var record = {
            //     label: i.toString().padStart(2, '0'),
            //     value: 0,
            // };

            category.push({
                'label': (i + 1).toString().padStart(2, '0'),
            });

            for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                if (moment(currDay).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'day')) {
                    // need do for outlet based
                    datasetDict[allOutletsUserOrdersDoneToday[j].outletId].data[i].value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                    // record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                }
            }

            // if (record.value > yMaxValue) {
            //     yMaxValue = record.value;
            // }

            // record.value = record.value.toFixed(0);

            // parsedData.push(record);
        }
    }
    else if (chartPeriod === CHART_PERIOD.THREE_MONTHS) {
        const startDate = moment().subtract(2, 'month').startOf('month');
        const endDate = moment().endOf('month');

        const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
            return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
        });

        var currWeekCount = 0;
        var prevMonth = moment(startDate);

        for (var i = 0; i < allOutlets.length; i++) {
            datasetDict[allOutlets[i].uniqueId] = {
                seriesname: allOutlets[i].name,
                data: Array.from(Array(moment(endDate).diff(startDate, 'week'))).map(() => {
                    return { value: 0 };
                }),
            };
        }

        for (var i = 0; i < moment(endDate).diff(startDate, 'week'); i++) {
            const currWeek = moment(startDate).add(i, 'week');

            if (moment(currWeek).isSame(prevMonth, 'month')) {
                currWeekCount++;
            }
            else {
                prevMonth = moment(currWeek);

                currWeekCount = 0;
                currWeekCount++;
            }

            // var record = {
            //     label: (currWeekCount > 1 ? '' : moment(currWeek).format('MMM')) + ' W' + currWeekCount,
            //     value: 0,
            // };

            category.push({
                'label': (currWeekCount > 1 ? '' : moment(currWeek).format('MMM')) + ' W' + currWeekCount,
            });

            for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                if (moment(currWeek).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'week')) {
                    datasetDict[allOutletsUserOrdersDoneToday[j].outletId].data[i].value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                    // record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                }
            }

            // if (record.value > yMaxValue) {
            //     yMaxValue = record.value;
            // }

            // record.value = record.value.toFixed(0);

            // parsedData.push(record);
        }

    }
    else if (chartPeriod === CHART_PERIOD.SIX_MONTHS) {
        const startDate = moment().subtract(5, 'month').startOf('month');
        const endDate = moment().endOf('month');

        const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
            return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
        });

        for (var i = 0; i < allOutlets.length; i++) {
            datasetDict[allOutlets[i].uniqueId] = {
                seriesname: allOutlets[i].name,
                data: Array.from(Array(moment(endDate).diff(startDate, 'week'))).map(() => {
                    return { value: 0 };
                }),
            };
        }

        for (var i = 0; i < moment(endDate).diff(startDate, 'week'); i++) {
            const currWeek = moment(startDate).add(i, 'week');

            if (moment(currWeek).isSame(prevMonth, 'month')) {
                currWeekCount++;
            }
            else {
                prevMonth = moment(currWeek);

                currWeekCount = 0;
                currWeekCount++;
            }

            // var record = {
            //     label: 'W' + currWeekCount + ' ' + (currWeekCount > 1 ? '' : moment(currWeek).format('MMM')),
            //     value: 0,
            // };

            category.push({
                'label': 'W' + currWeekCount + ' ' + (currWeekCount > 1 ? '' : moment(currWeek).format('MMM')),
            });

            for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                if (moment(currWeek).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'week')) {
                    datasetDict[allOutletsUserOrdersDoneToday[j].outletId].data[i].value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                    // record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                }
            }

            // if (record.value > yMaxValue) {
            //     yMaxValue = record.value;
            // }

            // record.value = record.value.toFixed(0);

            // parsedData.push(record);
        }
    }
    else if (chartPeriod === CHART_PERIOD.THIS_YEAR) {
        const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
            return moment().isSame(moment(order.completedDate), 'year');
        });

        for (var i = 0; i < allOutlets.length; i++) {
            datasetDict[allOutlets[i].uniqueId] = {
                seriesname: allOutlets[i].name,
                data: Array.from(Array(12)).map(() => {
                    return { value: 0 };
                }),
            };
        }

        for (var i = 0; i < 12; i++) {
            const currMonth = moment().startOf('year').add(i, 'month');

            // var record = {
            //     label: moment(currMonth).format('MMM'),
            //     value: 0,
            // };

            category.push({
                'label': moment(currMonth).format('MMM'),
            });

            for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                if (moment(currMonth).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'month')) {
                    datasetDict[allOutletsUserOrdersDoneToday[j].outletId].data[i].value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                    // record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                }
            }

            // if (record.value > yMaxValue) {
            //     yMaxValue = record.value;
            // }

            // record.value = record.value.toFixed(0);

            // parsedData.push(record);
        }
    }
    else if (chartPeriod === CHART_PERIOD.YTD) {
        const startDate = moment().subtract(11, 'month').startOf('month');
        const endDate = moment().endOf('month');

        const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
            return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
        });

        for (var i = 0; i < allOutlets.length; i++) {
            datasetDict[allOutlets[i].uniqueId] = {
                seriesname: allOutlets[i].name,
                data: Array.from(Array(12)).map(() => {
                    return { value: 0 };
                }),
            };
        }

        for (var i = 0; i < 12; i++) {
            const currMonth = moment(startDate).add(i, 'month');

            // var record = {
            //     label: moment(currMonth).format('YY\' MMM'),
            //     value: 0,
            // };

            category.push({
                'label': moment(currMonth).format('YY\' MMM'),
            });

            for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
                if (moment(currMonth).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'month')) {
                    datasetDict[allOutletsUserOrdersDoneToday[j].outletId].data[i].value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                    // record.value += allOutletsUserOrdersDoneToday[j][yAxisKey];
                }
            }

            // if (record.value > yMaxValue) {
            //     yMaxValue = record.value;
            // }

            // record.value = record.value.toFixed(0);

            // parsedData.push(record);
        }
    }
    else if (chartPeriod === CHART_PERIOD.NONE) {
        const allOutletsUserOrdersDoneTodayRange = allOutletsUserOrdersDone.filter(order => {
            return moment(startDate).isSameOrBefore(order.completedDate) && moment(endDate).isAfter(order.completedDate);
        });

        if (moment(endDate).diff(startDate, 'day') <= moment(startDate).daysInMonth()) {
            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            for (var i = 0; i < allOutlets.length; i++) {
                datasetDict[allOutlets[i].uniqueId] = {
                    seriesname: allOutlets[i].name,
                    data: Array.from(Array(diffDays)).map(() => {
                        return { value: 0 };
                    }),
                };
            }

            for (var i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    'label': !moment(currDate).isSame(prevDate, 'month') ? moment(currDate).format('DD MMM') : moment(currDate).format('DD')
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))
                });

                prevDate = currDate;

                for (var j = 0; j < allOutletsUserOrdersDoneTodayRange.length; j++) {
                    if (
                        // allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
                        moment(currDate).isSame(moment(allOutletsUserOrdersDoneTodayRange[j].completedDate), 'day')) {
                        datasetDict[allOutletsUserOrdersDoneTodayRange[j].outletId].data[i].value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                        // record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                    }
                }

                // if (record.value > yMaxValue) {
                //     yMaxValue = record.value;
                // }

                // record.value = record.value.toFixed(0);

                // parsedData.push(record);
            }
        }
        else if (moment(endDate).diff(startDate, 'month') <= 6) {
            var prevMonth = moment(startDate);

            const diffWeeks = moment(endDate).diff(startDate, 'week');

            for (var i = 0; i < allOutlets.length; i++) {
                datasetDict[allOutlets[i].uniqueId] = {
                    seriesname: allOutlets[i].name,
                    data: Array.from(Array(diffWeeks)).map(() => {
                        return { value: 0 };
                    }),
                };
            }

            for (var i = 0; i < diffWeeks; i++) {
                const currDate = moment(startDate).add(i, 'week');

                if (moment(currDate).isSame(prevMonth, 'month')) {
                    currWeekCount++;
                }
                else {
                    prevMonth = moment(currDate);

                    currWeekCount = 0;
                    currWeekCount++;
                }

                category.push({
                    // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                    label: moment(currDate).format('MMM') + ' W' + countWeekdayOccurrencesInMonth(currDate),
                });

                for (var j = 0; j < allOutletsUserOrdersDoneTodayRange.length; j++) {
                    if (
                        // allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
                        moment(currDate).isSame(moment(allOutletsUserOrdersDoneTodayRange[j].completedDate), 'week')) {
                        datasetDict[allOutletsUserOrdersDoneTodayRange[j].outletId].data[i].value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                        // record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                    }
                }

                // if (record.value > yMaxValue) {
                //     yMaxValue = record.value;
                // }

                // record.value = record.value.toFixed(0);

                // parsedData.push(record);
            }
        }
        else
        // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
        {
            const diffYears = moment(endDate).diff(startDate, 'month');

            for (var i = 0; i < allOutlets.length; i++) {
                datasetDict[allOutlets[i].uniqueId] = {
                    seriesname: allOutlets[i].name,
                    data: Array.from(Array(diffYears)).map(() => {
                        return { value: 0 };
                    }),
                };
            }

            for (var i = 0; i < diffYears; i++) {
                const currDate = moment(startDate).add(i, 'month');

                category.push({
                    label: moment(currDate).format('YY\' MMM'),
                });

                for (var j = 0; j < allOutletsUserOrdersDoneTodayRange.length; j++) {
                    if (
                        // allOutletsUserOrdersDoneTodayRange[j].outletId === allOutlets[i].uniqueId &&
                        moment(currDate).isSame(moment(allOutletsUserOrdersDoneTodayRange[j].completedDate), 'month')) {
                        datasetDict[allOutletsUserOrdersDoneTodayRange[j].outletId].data[i].value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                        // record.value += allOutletsUserOrdersDoneTodayRange[j][yAxisKey];
                    }
                }

                // if (record.value > yMaxValue) {
                //     yMaxValue = record.value;
                // }

                // record.value = record.value.toFixed(0);

                // parsedData.push(record);
            }
        }
    }

    // const allOutletsUserOrdersDoneToday = allOutletsUserOrdersDone.filter(order => {
    //     return moment().isSame(moment(order.completedDate), 'year');
    // });

    // for (var i = 0; i < 12; i++) {
    //     var record = {
    //         label: MONTH_TO_CHART_LABEL[i],
    //         value: 0,
    //     };

    //     const currMonth = moment().startOf('year').add(i, 'month');

    //     for (var j = 0; j < allOutletsUserOrdersDoneToday.length; j++) {
    //         // if (allOutletsUserOrdersDoneToday[j].outletId === allOutlets[i].uniqueId) {
    //         //     record.value += allOutletsUserOrdersDoneToday[j].finalPrice;

    //         //     // if (allOutletsUserOrdersDoneToday[j].finalPrice > yMaxValue) {
    //         //     //     yMaxValue += allOutletsUserOrdersDoneToday[j].finalPrice;
    //         //     // }                
    //         // }

    //         if (moment(currMonth).isSame(moment(allOutletsUserOrdersDoneToday[j].completedDate), 'month')) {
    //             record.value += allOutletsUserOrdersDoneToday[j].finalPrice;
    //         }
    //     }

    //     // yMaxValue += record.value;
    //     if (record.value > yMaxValue) {
    //         yMaxValue = record.value;
    //     }

    //     record.value = record.value.toFixed(0);

    //     parsedData.push(record);
    // }

    for (var j = 0; j < allOutlets.length; j++) {
        var outletId = allOutlets[j].uniqueId;

        var currTotal = 0;

        for (var k = 0; k < datasetDict[outletId].data.length; k++) {
            // currTotal += datasetDict[outletId].data[k].value;

            currTotal = datasetDict[outletId].data[k].value;

            if (currTotal > yMaxValue) {
                yMaxValue = currTotal;
            }
        }

        // if (currTotal > yMaxValue) {
        //     yMaxValue = currTotal;
        // }
    }

    var chartLegend = [];

    const dataset = Object.entries(datasetDict).map(([key, value], index) => {
        const mappedIndex = index % CHART_COLORS[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].length; // might got more than 10 preset colors, set to infinite list instead

        chartLegend.push({
            itemName: value.seriesname,
            itemSku: value.seriesname,
            chartColor: CHART_COLORS[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][mappedIndex],
        });

        return {
            ...value,
            color: CHART_COLORS[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][mappedIndex],
        };
    });

    var output = {};

    output.type = CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].type;
    output.dataFormat = CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].dataFormat;
    output.dataSource = {
        chart: CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].chart,

        categories: [
            {
                category: category,
            }
        ],
        dataset: dataset,

        annotations: annotations,

        // data: parsedData,
    };

    // console.log('parsedData');
    // console.log(parsedData);
    console.log(yMaxValue);

    if (output.type !== 'HLinearGauge') {
        // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

        // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
        // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
        // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

        const baseNumberLength = yMaxValue.toFixed(0).length - 1;
        const baseNumber = parseInt(('1' + Array(baseNumberLength).fill().map(num => 0).join('')));
        var maxNumber = baseNumber;

        if (yMaxValue > (baseNumber * 5)) {
            maxNumber = baseNumber * 10;
        }
        else if (yMaxValue > (baseNumber * 3)) {
            maxNumber = baseNumber * 5;
        }
        else if (yMaxValue > (baseNumber * 2)) {
            maxNumber = baseNumber * 3;
        }
        else {
            maxNumber = baseNumber * 2;
        }

        maxNumber = Math.max(maxNumber, 100);

        output.dataSource.chart = {
            ...output.dataSource.chart,
            labelDisplay: 'WRAP',
            // labelStep: 2,
            bgColor: '#ffffff',
            canvasBgColor: '#ffffff',
            bgAlpha: 100,

            useEllipsesWhenOverflow: 1,

            // showYAxisValues: 0,
            // yaxismaxvalue: yAxisMaxValue * yAxisStep,
            yaxismaxvalue: maxNumber,
            yaxisminvalue: yMinValue,
            adjustDiv: 0,
            // numDivLines: numDivLines,
            numDivLines: 9,

            // yaxisminvalue: 0,
            formatNumberScale: 0,
            decimals: 0,
        };

        // console.log('/////////////////////////////////////////////////')

        // console.log({
        //     yMaxValue: yMaxValue,
        //     yMaxValueParsed: yMaxValueParsed,
        //     yaxismaxvalue: yAxisMaxValue * yAxisStep,
        //     yaxisminvalue: yMinValue,
        //     adjustDiv: 0,
        //     numDivLines: numDivLines,
        // });

        // console.log('/////////////////////////////////////////////////')

        return {
            chartData: output,
            chartLegend: chartLegend,
        };
    }

    // if (output.type === 'column2D') {
    //     if (type === CHART_TYPE.S_MILK) {
    //         // for (let i = 0; i < output.dataSource.data.length; i++) {
    //         //     if (output.dataSource.data[i].value.length > 0) {
    //         //         yMaxValue = parseInt(output.dataSource.data[i].value) > yMaxValue ? parseInt(output.dataSource.data[i].value) : yMaxValue;

    //         //     }
    //         // }

    //         // const yMaxValueParsed = Math.ceil(yMaxValue / 5);
    //         // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
    //         // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

    //         // output.dataSource.chart = {
    //         //     ...output.dataSource.chart,
    //         //     adjustDiv: 0,
    //         //     numDivLines: numDivLines,
    //         //     yaxismaxvalue: yAxisMaxValue * 5,
    //         //     yaxisminvalue: yMinValue,
    //         //     formatNumberScale: 0,
    //         // };
    //     }
    //     else if (type === CHART_TYPE.S_SHIT_PEE) {
    //         for (let i = 0; i < output.dataSource.data.length; i++) {
    //             if (output.dataSource.data[i].value.length > 0) {
    //                 yMaxValue = parseInt(output.dataSource.data[i].value) > yMaxValue ? parseInt(output.dataSource.data[i].value) : yMaxValue;
    //             }
    //         }

    //         output.dataSource.chart = {
    //             ...output.dataSource.chart,
    //             adjustDiv: 0,
    //             numDivLines: yMaxValue,
    //             yaxismaxvalue: yMaxValue + 1,
    //             yaxisminvalue: yMinValue,
    //             formatNumberScale: 0,
    //         };
    //     }
    // }

    console.log('sales line chart output');
    console.log(output);

    return output;
};

export const getDataForChartReportProductSales = (productSalesTemp, outletItems, allOutlets, startDate, endDate, yAxisKey, xAxisKey) => {
    // endDate = moment(endDate).add(1, 'day');

    console.log('data before chart');

    // // endDate = moment(endDate).add(1, 'day');

    const productSales = productSalesTemp.slice(0).sort((a, b) => b.totalSales - a.totalSales).slice(0, 10)

    var parsedData = [];

    var yMinValue = 0;
    var yMaxValue = 0;

    var category = [];
    var datasetDict = {};

    if (xAxisKey === CHART_X_AXIS_TYPE.TIME) {
        if (moment(endDate).diff(startDate, 'day') <= moment(startDate).daysInMonth()) {
            endDate = moment(endDate).add(1, 'day');

            const diffDays = moment(endDate).diff(startDate, 'day');

            var prevDate = moment(startDate).add(-1, 'month');

            for (var i = 0; i < diffDays; i++) {
                const currDate = moment(startDate).add(i, 'day');

                category.push({
                    //'label': moment(currDate).format('DD MMM'),
                    'label': !moment(currDate).isSame(prevDate, 'month') ? moment(currDate).format('DD MMM') : moment(currDate).format('DD')
                    //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))

                });

                prevDate = currDate;

                var currTotal = 0;

                for (var j = 0; j < productSales.length; j++) {
                    const currProduct = productSales[j];

                    if (
                        datasetDict[currProduct.productName]
                    ) {
                        // do nothing for now
                    }
                    else {
                        datasetDict[currProduct.productName] = {
                            seriesname: currProduct.productName,
                            data: Array.from(Array(diffDays)).map(() => {
                                return { value: 0 };
                            }),
                        }
                    }

                    for (var k = 0; k < outletItems.length; k++) {
                        const currItem = outletItems[k];

                        if (currItem.itemName === currProduct.productName &&
                            moment(currDate).isSame(currItem.orderCompletedDate, 'day')) {
                            // means same item

                            // datasetDict[currProduct.productName].data[i].value += currItem.price * currItem.quantity;
                            datasetDict[currProduct.productName].data[i].value += currItem[yAxisKey];
                        }
                    }

                    currTotal += datasetDict[currProduct.productName].data[i].value;
                    datasetDict[currProduct.productName].data[i].value = datasetDict[currProduct.productName].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }
        else if (moment(endDate).diff(startDate, 'month') <= 6) {
            var currWeekCount = 0;
            var prevMonth = moment(startDate);

            const diffWeeks = moment(endDate).diff(startDate, 'week');

            for (var i = 0; i < diffWeeks; i++) {
                const currDate = moment(startDate).add(i, 'week');

                if (moment(currDate).isSame(prevMonth, 'month')) {
                    currWeekCount++;
                }
                else {
                    prevMonth = moment(currDate);

                    currWeekCount = 0;
                    currWeekCount++;
                }

                category.push({
                    // label: moment(currDate).format('MMM') + ' W' + currWeekCount,
                    label: moment(currDate).format('MMM') + ' W' + countWeekdayOccurrencesInMonth(currDate),
                });

                var currTotal = 0;

                for (var j = 0; j < productSales.length; j++) {
                    const currProduct = productSales[j];

                    if (
                        datasetDict[currProduct.productName]
                    ) {
                        // do nothing for now
                    }
                    else {
                        datasetDict[currProduct.productName] = {
                            seriesname: currProduct.productName,
                            data: Array.from(Array(diffWeeks)).map(() => {
                                return { value: 0 };
                            }),
                        }
                    }

                    for (var k = 0; k < outletItems.length; k++) {
                        const currItem = outletItems[k];

                        if (currItem.itemName === currProduct.productName &&
                            moment(currDate).isSame(currItem.orderCompletedDate, 'week')) {
                            // means same item

                            datasetDict[currProduct.productName].data[i].value += currItem.price * currItem.quantity;
                        }
                    }

                    currTotal += datasetDict[currProduct.productName].data[i].value;
                    datasetDict[currProduct.productName].data[i].value = datasetDict[currProduct.productName].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }
        else
        // if (moment(endDate).diff(startDate, 'month') <= 6) // for now no need conditions first, for more than 6 months interval
        {
            const diffYears = moment(endDate).diff(startDate, 'month');

            for (var i = 0; i < diffYears; i++) {
                const currDate = moment(startDate).add(i, 'month');

                category.push({
                    label: moment(currDate).format('YY\' MMM'),
                });

                var currTotal = 0;

                for (var j = 0; j < productSales.length; j++) {
                    const currProduct = productSales[j];

                    if (
                        datasetDict[currProduct.productName]
                    ) {
                        // do nothing for now
                    }
                    else {
                        datasetDict[currProduct.productName] = {
                            seriesname: currProduct.productName,
                            data: Array.from(Array(diffYears)).map(() => {
                                return { value: 0 };
                            }),
                        }
                    }

                    for (var k = 0; k < outletItems.length; k++) {
                        const currItem = outletItems[k];

                        if (currItem.itemName === currProduct.productName &&
                            moment(currDate).isSame(currItem.orderCompletedDate, 'month')) {
                            // means same item

                            datasetDict[currProduct.productName].data[i].value += currItem.price * currItem.quantity;
                        }
                    }

                    currTotal += datasetDict[currProduct.productName].data[i].value;
                    datasetDict[currProduct.productName].data[i].value = datasetDict[currProduct.productName].data[i].value.toFixed(0);
                }

                if (currTotal > yMaxValue) {
                    yMaxValue = currTotal;
                }
            }
        }
    }
    else if (xAxisKey === CHART_X_AXIS_TYPE.OUTLET) {
        for (var i = 0; i < allOutlets.length; i++) {
            const currOutlet = allOutlets[i];

            category.push({
                //'label': moment(currDate).format('DD MMM'),
                'label': currOutlet.name,
                //'label': (i > 1 ? moment(currDate).format('DD') : moment(currDate).format('DD MMM'))

            });

            var currTotal = 0;

            for (var j = 0; j < productSales.length; j++) {
                const currProduct = productSales[j];

                if (
                    datasetDict[currProduct.productName]
                ) {
                    // do nothing for now
                }
                else {
                    datasetDict[currProduct.productName] = {
                        seriesname: currProduct.productName,
                        data: Array.from(Array(allOutlets.length)).map(() => {
                            return { value: 0 };
                        }),
                    }
                }

                for (var k = 0; k < outletItems.length; k++) {
                    const currItem = outletItems[k];

                    if (currItem.itemName === currProduct.productName &&
                        moment(startDate).isSameOrBefore(currItem.orderCompletedDate) &&
                        moment(endDate).isAfter(currItem.orderCompletedDate) &&
                        currOutlet.uniqueId === currItem.outletId) {
                        // means same item

                        // datasetDict[currProduct.productName].data[i].value += currItem.price * currItem.quantity;
                        datasetDict[currProduct.productName].data[i].value += currItem[yAxisKey];
                    }
                }

                currTotal += datasetDict[currProduct.productName].data[i].value;
                datasetDict[currProduct.productName].data[i].value = datasetDict[currProduct.productName].data[i].value.toFixed(0);
            }

            if (currTotal > yMaxValue) {
                yMaxValue = currTotal;
            }
        }
    }

    console.log('category');
    console.log(category);
    console.log('datasetDict');
    console.log(datasetDict);

    var chartLegend = [];

    const dataset = Object.entries(datasetDict).map(([key, value], index) => {
        chartLegend.push({
            itemName: value.seriesname,
            itemSku: value.seriesname,
            chartColor: CHART_COLORS[CHART_TYPE.REPORT_PRODUCT_SALES][index],
        });

        return {
            ...value,
            color: CHART_COLORS[CHART_TYPE.REPORT_PRODUCT_SALES][index],
        };
    });

    var output = {};

    output.type = CHART_DATA[CHART_TYPE.REPORT_PRODUCT_SALES].type;
    output.dataFormat = CHART_DATA[CHART_TYPE.REPORT_PRODUCT_SALES].dataFormat;
    output.dataSource = {
        chart: CHART_DATA[CHART_TYPE.REPORT_PRODUCT_SALES].chart,

        categories: [
            {
                category: category,
            }
        ],
        dataset: dataset,
    };

    // const yAxisStep = Math.ceil((yMaxValue / 10) / 10) * 10;

    // const yMaxValueParsed = Math.ceil(yMaxValue / yAxisStep);
    // const yAxisMaxValue = yMaxValueParsed === 1 ? 2 : yMaxValueParsed;
    // const numDivLines = yMaxValueParsed === 1 ? 1 : yMaxValueParsed - 1;

    const baseNumberLength = yMaxValue.toFixed(0).length - 1;
    const baseNumber = parseInt(('1' + Array(baseNumberLength).fill().map(num => 0).join('')));
    var maxNumber = baseNumber;

    if (yMaxValue > (baseNumber * 5)) {
        maxNumber = baseNumber * 10;
    }
    else if (yMaxValue > (baseNumber * 3)) {
        maxNumber = baseNumber * 5;
    }
    else if (yMaxValue > (baseNumber * 2)) {
        maxNumber = baseNumber * 3;
    }
    else {
        maxNumber = baseNumber * 2;
    }

    maxNumber = Math.max(maxNumber, 100);

    output.dataSource.chart = {
        ...output.dataSource.chart,
        labelDisplay: 'WRAP',
        // labelStep: 2,
        bgColor: '#ffffff',
        canvasBgColor: '#ffffff',
        bgAlpha: 100,

        // showYAxisValues: 0,
        // yaxismaxvalue: yAxisMaxValue * yAxisStep,
        yaxismaxvalue: maxNumber,
        yaxisminvalue: yMinValue,
        adjustDiv: 0,
        // numDivLines: numDivLines,
        numDivLines: 9,

        // yaxisminvalue: 0,
        formatNumberScale: 0,
        decimals: 0,
    };

    console.log('chart output');
    console.log(output);

    return {
        chartData: output,
        chartLegend: chartLegend,
    };
};

export const filterChartItems = (item, appliedChartFilterQueries) => {
    var isValid = false;

    if (appliedChartFilterQueries.length > 0) {
        var matchedFilterNum = 0;

        for (var i = 0; i < appliedChartFilterQueries.length; i++) {
            const appliedChartFilterQuery = appliedChartFilterQueries[i];

            if (appliedChartFilterQuery.fieldNameType === CHART_FIELD_TYPE.STRING) {
                // proceed with string type

                if (appliedChartFilterQuery.fieldSpecial === 'cartItems') {
                    // cartItems field

                    if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.IS) {
                        var isMatched = false;

                        for (var i = 0; i < item[appliedChartFilterQuery.fieldSpecial].length; i++) {
                            if (item[appliedChartFilterQuery.fieldSpecial][i][appliedChartFilterQuery.fieldNameKey] === appliedChartFilterQuery.fieldDataValue) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LIKE) {
                        try {
                            var isMatched = false;

                            for (var i = 0; i < item[appliedChartFilterQuery.fieldSpecial].length; i++) {
                                if (appliedChartFilterQuery.fieldDataValue.toLowerCase().includes(item[appliedChartFilterQuery.fieldSpecial][i][appliedChartFilterQuery.fieldNameKey].toLowerCase()) ||
                                    item[appliedChartFilterQuery.fieldSpecial][i][appliedChartFilterQuery.fieldNameKey].toLowerCase().includes(appliedChartFilterQuery.fieldDataValue.toLowerCase())) {
                                    matchedFilterNum++;
                                    isMatched = true;
                                    break;
                                }
                            }

                            if (isMatched) {
                                continue;
                            }
                        }
                        catch (ex) {
                            console.error(ex);
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.NOT) {
                        var isMatched = false;

                        for (var i = 0; i < item[appliedChartFilterQuery.fieldSpecial].length; i++) {
                            if (item[appliedChartFilterQuery.fieldSpecial][i][appliedChartFilterQuery.fieldNameKey] !== appliedChartFilterQuery.fieldDataValue) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.MORE_THAN) {
                        var isMatched = false;

                        for (var i = 0; i < item[appliedChartFilterQuery.fieldSpecial].length; i++) {
                            if (item[appliedChartFilterQuery.fieldSpecial][i][appliedChartFilterQuery.fieldNameKey] > appliedChartFilterQuery.fieldDataValue) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN) {
                        var isMatched = false;

                        for (var i = 0; i < item[appliedChartFilterQuery.fieldSpecial].length; i++) {
                            if (item[appliedChartFilterQuery.fieldSpecial][i][appliedChartFilterQuery.fieldNameKey] >= appliedChartFilterQuery.fieldDataValue) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LESS_THAN) {
                        var isMatched = false;

                        for (var i = 0; i < item[appliedChartFilterQuery.fieldSpecial].length; i++) {
                            if (item[appliedChartFilterQuery.fieldSpecial][i][appliedChartFilterQuery.fieldNameKey] < appliedChartFilterQuery.fieldDataValue) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN) {
                        var isMatched = false;

                        for (var i = 0; i < item[appliedChartFilterQuery.fieldSpecial].length; i++) {
                            if (item[appliedChartFilterQuery.fieldSpecial][i][appliedChartFilterQuery.fieldNameKey] <= appliedChartFilterQuery.fieldDataValue) {
                                matchedFilterNum++;
                                isMatched = true;
                                break;
                            }
                        }

                        if (isMatched) {
                            continue;
                        }
                    }
                }
                else {
                    // ordinary string field

                    if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.IS) {
                        if (item[appliedChartFilterQuery.fieldNameKey] === appliedChartFilterQuery.fieldDataValue) {
                            matchedFilterNum++;
                            continue;
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LIKE) {
                        try {
                            if (item[appliedChartFilterQuery.fieldNameKey].length > 0 &&
                                (appliedChartFilterQuery.fieldDataValue.toLowerCase().includes(item[appliedChartFilterQuery.fieldNameKey].toLowerCase())) ||
                                item[appliedChartFilterQuery.fieldNameKey].toLowerCase().includes(appliedChartFilterQuery.fieldDataValue.toLowerCase())) {
                                matchedFilterNum++;
                                continue;
                            }
                        }
                        catch (ex) {
                            console.error(ex);
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.NOT) {
                        if (item[appliedChartFilterQuery.fieldNameKey] !== appliedChartFilterQuery.fieldDataValue) {
                            matchedFilterNum++;
                            continue;
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.MORE_THAN) {
                        if (item[appliedChartFilterQuery.fieldNameKey] > appliedChartFilterQuery.fieldDataValue) {
                            matchedFilterNum++;
                            continue;
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN) {
                        if (item[appliedChartFilterQuery.fieldNameKey] >= appliedChartFilterQuery.fieldDataValue) {
                            matchedFilterNum++;
                            continue;
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LESS_THAN) {
                        if (item[appliedChartFilterQuery.fieldNameKey] < appliedChartFilterQuery.fieldDataValue) {
                            matchedFilterNum++;
                            continue;
                        }
                    }
                    else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN) {
                        if (item[appliedChartFilterQuery.fieldNameKey] <= appliedChartFilterQuery.fieldDataValue) {
                            matchedFilterNum++;
                            continue;
                        }
                    }
                }
            }
            else if (appliedChartFilterQuery.fieldNameType === CHART_FIELD_TYPE.NUMBER) {
                // proceed with string type

                if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.IS) {
                    if (item[appliedChartFilterQuery.fieldNameKey] === appliedChartFilterQuery.fieldDataValue) {
                        matchedFilterNum++;
                        continue;
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LIKE) {
                    try {
                        if (item[appliedChartFilterQuery.fieldNameKey] === appliedChartFilterQuery.fieldDataValue) {
                            matchedFilterNum++;
                            continue;
                        }
                    }
                    catch (ex) {
                        console.error(ex);
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.NOT) {
                    if (item[appliedChartFilterQuery.fieldNameKey] !== appliedChartFilterQuery.fieldDataValue) {
                        matchedFilterNum++;
                        continue;
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.MORE_THAN) {
                    if (item[appliedChartFilterQuery.fieldNameKey] > appliedChartFilterQuery.fieldDataValue) {
                        matchedFilterNum++;
                        continue;
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN) {
                    if (item[appliedChartFilterQuery.fieldNameKey] >= appliedChartFilterQuery.fieldDataValue) {
                        matchedFilterNum++;
                        continue;
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LESS_THAN) {
                    if (item[appliedChartFilterQuery.fieldNameKey] < appliedChartFilterQuery.fieldDataValue) {
                        matchedFilterNum++;
                        continue;
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN) {
                    if (item[appliedChartFilterQuery.fieldNameKey] <= appliedChartFilterQuery.fieldDataValue) {
                        matchedFilterNum++;
                        continue;
                    }
                }
            }
            else if (appliedChartFilterQuery.fieldNameType === CHART_FIELD_TYPE.DATETIME) {
                // proceed with string type

                if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.IS) {
                    if (moment(item[appliedChartFilterQuery.fieldNameKey]).isSame(appliedChartFilterQuery.fieldDataValue, 'day')) {
                        matchedFilterNum++;
                        continue;
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LIKE) {
                    try {
                        if (moment(item[appliedChartFilterQuery.fieldNameKey]).isSame(appliedChartFilterQuery.fieldDataValue, 'day')) {
                            matchedFilterNum++;
                            continue;
                        }
                    }
                    catch (ex) {
                        console.error(ex);
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.NOT) {
                    if (!moment(item[appliedChartFilterQuery.fieldNameKey]).isSame(appliedChartFilterQuery.fieldDataValue, 'day')) {
                        matchedFilterNum++;
                        continue;
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.MORE_THAN) {
                    if (moment(item[appliedChartFilterQuery.fieldNameKey]).isAfter(appliedChartFilterQuery.fieldDataValue, 'day')) {
                        matchedFilterNum++;
                        continue;
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN) {
                    if (moment(item[appliedChartFilterQuery.fieldNameKey]).isSameOrAfter(appliedChartFilterQuery.fieldDataValue, 'day')) {
                        matchedFilterNum++;
                        continue;
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LESS_THAN) {
                    if (moment(item[appliedChartFilterQuery.fieldNameKey]).isBefore(appliedChartFilterQuery.fieldDataValue, 'day')) {
                        matchedFilterNum++;
                        continue;
                    }
                }
                else if (appliedChartFilterQuery.fieldCompare === CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN) {
                    if (moment(item[appliedChartFilterQuery.fieldNameKey]).isSameOrBefore(appliedChartFilterQuery.fieldDataValue, 'day')) {
                        matchedFilterNum++;
                        continue;
                    }
                }
            }
        }

        if (matchedFilterNum === appliedChartFilterQueries.length) {
            isValid = true;
        }
    }
    else {
        isValid = true;
    }

    return isValid;
};
