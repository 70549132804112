import React, {
  Component,
  useReducer,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  FlatList,
  TextInput,
  Modal,
  Platform,
  ActivityIndicator,
  Picker,
  Switch,
  useWindowDimensions,
} from "react-native";
import { ScrollView as ScrollViewGH } from "react-native-gesture-handler";
import Feather from "react-native-vector-icons/Feather";
import Colors from "../constant/Colors";
import Close from "react-native-vector-icons/AntDesign";
import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
import Ionicons from "react-native-vector-icons/Ionicons";
// import ImagePicker from 'react-native-image-picker';
import API from "../constant/API";
// import { isEnabled } from 'react-native/Libraries/Performance/Systrace';
import DropDownPicker from "react-native-dropdown-picker";
import * as User from "../util/User";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import ListItem, {Separator} from './ListItem';
import Styles from "../constant/Styles";
import ApiClient from "../util/ApiClient";
// import Switch from 'react-native-switch-pro';
import Ionicon from "react-native-vector-icons/Ionicons";
// import {isTablet} from 'react-native-device-detection';
import { OutletStore } from "../store/outletStore";
import { MerchantStore } from "../store/merchantStore";
import { UserStore } from "../store/userStore";
import AsyncImage from "../components/asyncImage";
import { CommonStore } from "../store/commonStore";
import AIcon from "react-native-vector-icons/AntDesign";
import AntDesign from "react-native-vector-icons/AntDesign";
// import RNPickerSelect from 'react-native-picker-select';
import DraggableFlatList from "react-native-draggable-flatlist";
import { color } from "react-native-reanimated";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ProductMenuScreen = (props) => {
  const { navigation } = props;

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const scrollViewGHRef = useRef(null);

  const [productList, setProductList] = useState([]);
  const [menuList, setMenuList] = useState(0);
  const [size, setSize] = useState("");
  const [addOn, setAddOn] = useState([]);
  const [addProduct, setAddProduct] = useState(false); //TODO false);
  const [addProductItem, setAddProductItem] = useState(true);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [addMenuItem, setAddMenuItem] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const [showOption1, setShowOption1] = useState(false);
  const [showOption, setShowOption] = useState(false);
  // const [// outletId, set// outletId] = useState('1');
  const [outletId, setOutletId] = useState(User.getOutletId());
  const [search, setSearch] = useState([]);
  const [menu, setMenu] = useState([]);
  const [modal, setModal] = useState(false);
  const [category, setCategory] = useState("");
  const [itemId, setItemId] = useState([]);
  const [menuItem, setMenuItem] = useState([]);
  const [detail, setDetail] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  // const [material, setMaterial] = useState([]);
  const [choosenMenu, setChoosenMenu] = useState([]);
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [logo, setLogo] = useState("");
  const [materialSKU, setMaterialSKU] = useState("");
  const [materialTag, setMaterialTag] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [options, setOptions] = useState("");
  const [options1, setOptions1] = useState("");
  const [material, setMaterial] = useState("");
  const [supplier, setSupplier] = useState("");
  const [cate, setCate] = useState([]);
  const [taxInfo, setTaxInfo] = useState([]);
  const [merInfo, setMerInfo] = useState([]);
  const [myTextInput, setMyTextInput] = useState(React.createRef());
  const [lists, setLists] = useState([]);
  const [productlist1, setProductlist1] = useState(true);
  const [searchList, setSearchList] = useState(false);
  const [currentMenu, setCurrentMenu] = useState([]);
  const [categoryOutlet, setCategoryOutlet] = useState([]);
  const [choice, setChoice] = useState(null);
  const [productList2, setProductList2] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [title, setTitle] = useState("");
  const [title1, setTitle1] = useState("");
  const [price1, setPrice1] = useState("");
  const [productOptions, setProductOptions] = useState([
    { optionId: 1, name: "Spicy", price: 2 },
    { optionId: 2, name: "No Spicy", price: 0 },
  ]);
  const [productOptions1, setProductOptions1] = useState([
    { optionsId: 1, name: "Spicy", min: 1, max: 2 },
    { optionsId: 2, name: "", min: 0, max: 2 },
  ]);
  const [refresh, setRefresh] = useState("false");
  const [displayMenu, setDisplayMenu] = useState([]);

  const [touchEnabled, setTouchEnabled] = useState(true);

  const [loading, setLoading] = useState(false);

  const [modalCategory, setModalCategory] = useState(false);

  const [scrollDistance, setScrollDistance] = useState(100);

  const [draggingScroll, setDraggingScroll] = useState(true);

  ///////////////////////////////////////////////////////////////////

  const [outletCategoryDropdownList, setOutletCategoryDropdownList] = useState(
    []
  );
  const [selectedOutletCategoryId, setSelectedOutletCategoryId] = useState("");

  const [chosenOutletCategoryItemList, setChosenOutletCategoryItemList] =
    useState([]);

  const [selectedOutletItemCategory, setSelectedOutletItemCategory] = useState(
    {}
  );

  const [editOutletCategoryId, setEditOutletCategoryId] = useState("");

  const [sortedOutletItems, setSortedOutletItems] = useState([]);
  const [sortedOrderIndexIdDict, setSortedOrderIndexIdDict] = useState({});

  const outletCategories = OutletStore.useState((s) => s.outletCategories);
  const outletItems = OutletStore.useState((s) => s.outletItems);

  // const selectedOutletItemAddOn = CommonStore.useState(s => s.selectedOutletItemAddOn);
  // const selectedOutletItemAddOnChoice = CommonStore.useState(s => s.selectedOutletItemAddOnChoice);

  //////////////////////////////////////////////////////////////

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);

  const selectedProductEdit = CommonStore.useState(
    (s) => s.selectedProductEdit
  );

  const isLoading = CommonStore.useState((s) => s.isLoading);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  //////////////////////////////////////////////////////////////

  const currOutlet = MerchantStore.useState((s) => s.currOutlet);

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  useEffect(() => {
    var filteredOutletItems = outletItems.filter(
      (o) => o.categoryId === selectedOutletItemCategory.uniqueId
    );

    filteredOutletItems.sort((a, b) => {
      return (
        (sortedOrderIndexIdDict[a.uniqueId]
          ? sortedOrderIndexIdDict[a.uniqueId]
          : a.orderIndex
          ? a.orderIndex
          : filteredOutletItems.length) -
        (sortedOrderIndexIdDict[b.uniqueId]
          ? sortedOrderIndexIdDict[b.uniqueId]
          : b.orderIndex
          ? b.orderIndex
          : filteredOutletItems.length)
      );
    });

    setSortedOutletItems(filteredOutletItems);
  }, [outletItems, selectedOutletItemCategory, sortedOrderIndexIdDict]);

  useEffect(() => {
    setOutletCategoryDropdownList(
      outletCategories.map((item) => ({
        label: item.name,
        value: item.uniqueId,
      }))
    );

    if (selectedOutletCategoryId === "" && outletCategories.length > 0) {
      setSelectedOutletCategoryId(outletCategories[0].uniqueId);

      setSelectedOutletItemCategory(outletCategories[0]);

      setEditOutletCategoryId(outletCategories[0].uniqueId);
    }
  }, [outletCategories]);

  useEffect(() => {
    setChosenOutletCategoryItemList(
      outletItems.filter(
        (outletItem) => outletItem.categoryId === selectedOutletCategoryId
      )
    );
  }, [selectedOutletCategoryId, outletItems]);

  const setState = () => {};

  function onWheel(apiObj, ev) {
    var isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }
    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

  // for draggable flatlist
  // just copy here for the function
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      sortedOutletItems,
      result.source.index,
      result.destination.index
    );

    setSortedOutletItems(items);
  };

  // do style here if you want
  // isDraggingOver is when you drag the view
  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? Colors.lightPrimary : Colors.whiteColor,
    // height: windowHeight * 0.35,
  });

  // =================end=================

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            // bottom: -2,
            bottom: switchMerchant ? "2%" : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? { right: windowWidth * 0.1 }
            : {},
          windowWidth <= 768 ? { right: windowWidth * 0.12 } : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Product Menu
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // const searchBarItem = () => {
  //     ApiClient.GET(
  //         API.searchBarItem +
  //         search +
  //         '&outletId=' +
  //         outletId,
  //     ).then((result) => {

  //         setState({ lists: result });
  //     });
  // }

  // const getItemByOutletTEST = (param) => { {
  //     ApiClient.GET(API.getItemByOutletTEST + param).then(result => {
  //         setState({
  //             menuItem: result,
  //             itemName: result.name,
  //             itemDescription: result.description,
  //             itemPrice: result.price,
  //             itemTaxRate: result.category.outlet.orderTax.rate,
  //             cate: result.category,
  //             detail: result.category.outlet,
  //             taxInfo: result.category.outlet.orderTax,
  //             merInfo: result.category.outlet.merchant,
  //             image: result.category.outlet.merchant.logo,

  //         });

  //         if (result.itemAddOns.length > 0) {
  //             const addOnSingle = result.itemAddOns.filter(i => i.leastSelect == 1)
  //             const addOnMultiple = result.itemAddOns.filter(i => i.leastSelect != 1)
  //             setState({
  //                 addOnSingle: addOnSingle.itemAddOns,
  //                 addOnMultiple: addOnMultiple.itemAddOns,
  //             })
  //         }
  //     });
  // }

  // const refreshFunc = () => {
  //     ApiClient.GET(API.merchantMenu + outletId).then((result) => {
  //         if (result.length > 0) {
  //             var productListRaw = [];

  //             result.forEach((element) => {

  //                 productListRaw = productListRaw.concat(element.items);
  //                 const activeItem = productListRaw.filter(item => item.active == 1)
  //                 setState({ productList: productListRaw, productList2: activeItem, }, () => { });
  //             });
  //         }
  //     });
  // }

  const refreshMenu = () => {
    ApiClient.GET(API.activeMenu + outletId).then((result) => {
      const displayMenu = [];
      if (choice != null) {
        const selectedCat = result.filter((i) => i.name == choice);
        for (const i of selectedCat) {
          for (const j of i.items) {
            displayMenu.push(j);
          }
        }
        setState({ currentMenu: result });
      } else {
        for (const i of result) {
          for (const j of i.items) {
            displayMenu.push(j);
          }
        }
        setState({ currentMenu: result });
      }
      setState({ displayMenu: displayMenu });
    });
  };

  const removeOutletItemOwnCategory = (outletItem) => {
    // changeItem(param.item.id);

    // var menu = currentMenu;
    // menu.push(param.item);

    // setState({ currentMenu: menu });

    var body = {
      outletItemId: outletItem.uniqueId,
      // outletCategoryId: editOutletCategoryId,
    };

    ApiClient.POST(API.removeOutletItemOwnCategory, body).then((result) => {
      if (result && result.status === "success") {
        Alert.alert("Success", "Removed product from the menu");
      } else {
        Alert.alert("Error", "Failed to remove product from the menu.");
      }

      // setModal(false);

      setModalCategory(false);
    });
  };

  const changeMenu = (outletItem) => {
    // changeItem(param.item.id);

    // var menu = currentMenu;
    // menu.push(param.item);

    // setState({ currentMenu: menu });

    var body = {
      outletItemId: outletItem.uniqueId,
      outletCategoryId: editOutletCategoryId,
    };

    ApiClient.POST(API.changeOutletItemCategory, body).then((result) => {
      if (result && result.status === "success") {
        Alert.alert("Success", "Menu has been changed");
      } else {
        Alert.alert("Error", "Failed to update menu");
      }

      // setModal(false);

      setModalCategory(false);
    });
  };

  const updateOutletItemOrderIndex = () => {
    var sortedOrderIndexIdList = Object.entries(sortedOrderIndexIdDict).map(
      ([key, value]) => ({
        outletItemId: key,
        orderIndex: value,
      })
    );

    var body = {
      sortedOrderIndexIdList: sortedOrderIndexIdList,
    };

    console.log(body);

    CommonStore.update((s) => {
      s.isLoading = true;
    });

    ApiClient.POST(API.updateOutletItemOrderIndex, body).then((result) => {
      if (result && result.status === "success") {
        Alert.alert("Success", "Menu order has been changed");
      } else {
        Alert.alert("Error", "Failed to update Menu order");
      }

      CommonStore.update((s) => {
        s.isLoading = false;
      });
    });
  };

  const menucount = () => {
    ApiClient.GET(API.merchantMenu + outletId).then((result) => {
      if (result.length > 0) {
        var productListRaw1 = [];
        result.forEach((element) => {
          productListRaw1 = productListRaw1.concat(element.items);
        });
      }

      productListRaw1.forEach((element) => {
        const menuItemCount = productListRaw1.filter(
          (element) => element.active === 1
        );

        setState({ menuCount: menuItemCount.length });
      });
    });
  };

  const setActiveMenu = (param) => {
    var body = {
      outletId: outletId,
      itemId: param,
    };
    ApiClient.POST(API.setActiveMenu, body).then((result) => {
      // refreshFunc();
    });
  };

  const deleteCartItem = (param) => {
    var list = choosenMenu;
    var newList = [];
    if (list.length > 1) {
      list.forEach((element) => {
        if (element.name != param) {
          newList.push(element);
          setState({ choosenMenu: newList });
        }
      });
    } else {
      if (list[0].name == param) {
        setState({ choosenMenu: [5] });
      }
    }
  };

  const renderMenuList = ({ item }) => (
    <View style={{ flexDirection: "row" }}>
      <View style={{ flex: 1, flexDirection: "row" }}>
        <View style={styles.content6}></View>
        <View style={{ marginLeft: 20, marginVertical: 10 }}>
          <Text style={{ fontSize: 11, fontFamily: "NunitoSans-Bold" }}>
            {item.name}
          </Text>
          <Text
            style={{
              fontSize: 10,
              color: Colors.primaryColor,
              fontFamily: "NunitoSans-Regular",
            }}
          >
            RM {item.price}
          </Text>
        </View>
      </View>
      <View
        style={{
          backgroundColor: "#bbfaed",
          width: 60,
          height: 20,
          marginLeft: 60,
          marginVertical: 10,
          borderRadius: 5,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            deleteCartItem(item.name);
          }}
        >
          <Text
            style={{ alignSelf: "center", fontFamily: "NunitoSans-Regular" }}
          >
            remove
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  const changeSection = (data) => {
    var array = choosenMenu;
    array.push(data);
    setState({ choosenMenu: array });
  };

  const renderMenu = ({ item }) => {
    if (item.actionButton) {
      return (
        <View style={{ width: "33%", alignItems: "center", zIndex: -1000 }}>
          <TouchableOpacity
            style={{
              width: "90%",
              height: "90%",
              minHeight: (windowWidth - 550) / 4,
              minWidth: (windowHeight - 550) / 5,
              marginBottom: 30,
              borderRadius: 10,
              justifyContent: "center",
              borderStyle: "dashed",
              borderWidth: 1,
              borderColor: Colors.fieldtTxtColor,
            }}
            onPress={() => {
              // setState({ modal: true });
              setModal(true);
            }}
          >
            <Text
              style={{
                fontSize: 50,
                textAlign: "center",
                color: Colors.descriptionColor,
              }}
            >
              +
            </Text>
          </TouchableOpacity>
        </View>
      );
    } else {
      return (
        <View style={{ width: "33%", alignItems: "center", zIndex: -1000 }}>
          <TouchableOpacity
            style={{
              width: "90%",
              height: "90%",
              minHeight: (windowWidth - 550) / 3,
              minWidth: (windowHeight - 550) / 5,
              marginBottom: 30,
              borderRadius: 10,
              justifyContent: "center",
              borderStyle: "dashed",
              borderWidth: 1,
              borderColor: Colors.fieldtTxtColor,
            }}
            onPress={() => {
              CommonStore.update((s) => {
                s.selectedProductEdit = item;
              });
              setModalCategory(true);
            }}
            disabled={!touchEnabled}
          >
            <View
              style={{
                backgroundColor: "#fafafa",
                width: "90%",
                height: windowHeight * 0.25,
                borderRadius: 10,
                marginBottom: 30,
                zIndex: switchMerchant ? -1000 : -1,
                alignSelf: "center",
                justifyContent: "flex-start",
                alignContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
                shadowColor: "#000",
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 1,
              }}
            >
              {item.image ? (
                <AsyncImage
                  style={{
                    width: switchMerchant
                      ? windowWidth <= 900
                        ? windowWidth * 0.07
                        : windowWidth * 0.09
                      : (windowWidth - 550) / 5,
                    height: "50%",
                    borderRadius: 10,
                    top: 10,
                  }}
                  resizeMode="cover"
                  source={{ uri: item.image }}
                  item={item}
                />
              ) : (
                <View
                  style={{
                    width: switchMerchant
                      ? windowWidth <= 900
                        ? windowWidth * 0.07
                        : windowWidth * 0.09
                      : (windowWidth - 550) / 5,
                    height: '50%',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Ionicons name="fast-food-outline" size={windowWidth <= 900 ? 30 : 45} />
                </View>
              )}

              <View
                style={{
                  height: switchMerchant
                    ? windowWidth * 0.07
                    : (windowWidth - 553) / 7.3,
                  alignItems: "center",
                  justifyContent: "center",
                  padding: switchMerchant ? 5 : 5,
                  paddingHorizontal: 8,
                }}
              >
                <Text
                  style={{
                    color: Colors.blackColor,
                    fontSize: switchMerchant ? 10 : 16,
                    fontFamily: "NunitoSans-Bold",
                    bottom: 7,
                    textAlign: "center",
                  }}
                >
                  {item.name}
                </Text>
              </View>

              <View
                style={[
                  styles.content4,
                  {
                    //flex: 0.1,
                    width: switchMerchant ? 80 : 120,
                    backgroundColor: Colors.highlightColor,
                    justifyContent: "center",
                    marginTop: 0,
                  },
                ]}
              >
                <Text
                  style={{
                    textAlign: "center",
                    fontFamily: "NunitoSans-Bold",
                    fontSize: switchMerchant ? 10 : 16,
                    color: Colors.blackColor,
                    top: 0,
                  }}
                >
                  RM{" "}
                  {item.price
                    .toFixed(2)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        </View>
      );
    }
  };

  const disableMenuScroll = () => {
    setDraggingScroll(false);
  };

  const renderMenuUser = useCallback(({ item, index, drag, isActive }) => {
    var quantity = 0;
    //const cartItem = cartItem.find(obj => obj.itemId === item.id);

    return (
      <TouchableOpacity
        // onPress={() => {
        //   //   if (checkCartOutlet()) {
        //   //     setState({ cartWarning: true, })
        //   //   } else {
        //   //     CommonStore.update(s => {
        //   //       s.selectedOutletItem = item;
        //   //     });
        //   //     props.navigation.navigate('MenuItemDetails', { refresh: refresh.bind(this), menuItem: item, outletData: outletData })
        //   //   }
        // }}
        /* onPress={()=>{
            drag
          }} */
        onLongPress={drag}
      >
        <View
          style={{
            flexDirection: "row",
            paddingHorizontal: switchMerchant ? 5 : 20,
            paddingBottom: 15,
            paddingTop: 10,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              width: switchMerchant ? "68%" : "75%",
              display: "flex",
              justifyContent: "flex-start",
              // backgroundColor: 'blue',
            }}
          >
            <View
              style={[
                {
                  backgroundColor: Colors.secondaryColor,
                  // width: 60,
                  // height: 60,
                  width: windowWidth * 0.06,
                  height: windowWidth * 0.06,
                  borderRadius: 10,
                },
                item.image
                  ? {}
                  : {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    },
              ]}
            >
              {item.image ? (
                <AsyncImage
                  source={{ uri: item.image }}
                  item={item}
                  style={{
                    // width: 60,
                    // height: 60,
                    width: windowWidth * 0.06,
                    height: windowWidth * 0.06,
                    borderRadius: 10,
                  }}
                />
              ) : (
                <Ionicons name="fast-food-outline" size={13} />
              )}
            </View>
            <View
              style={{
                marginLeft: switchMerchant ? 10 : 15,
                // flexDirection: 'row',
                // flexShrink: 1,
                width: switchMerchant ? "55%" : "55%",
                // backgroundColor: 'red',
              }}
            >
              <Text
                numberOfLines={3}
                style={{
                  fontSize: switchMerchant ? 10 : 13,
                  textTransform: "uppercase",
                  fontFamily: "NunitoSans-Bold",
                  // flexWrap: 'wrap',
                  // flex: 1,
                  // flexShrink: 1,
                  // width: '100%',
                }}
              >
                {item.name}
              </Text>

              <Text
                style={{
                  color: Colors.primaryColor,
                  fontFamily: "NunitoSans-Bold",
                  paddingTop: 5,
                  fontSize: switchMerchant ? 10 : 13,
                }}
              >
                RM
                {parseFloat(item.price)
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              width: switchMerchant ? windowWidth * 0.5 : windowWidth * 1,
              // marginLeft: 60
            }}
          >
            <View
              style={{
                backgroundColor: "#e3e1e1",
                // width: 67,
                // height: 24,

                width: 50,
                height: 24,

                // paddingVertical: 4,
                // paddingHorizontal: 20,

                borderRadius: 10,
                justifyContent: "center",
                alignSelf: "center",
              }}
            >
              <View
              // onPress={() => {
              //   if (checkCartOutlet()) {
              //     setState({ cartWarning: true, cartProceed: item })
              //   } else {
              //     CommonStore.update(s => {
              //       s.selectedOutletItem = item;
              //     });
              //     props.navigation.navigate('MenuItemDetails', { refresh: refresh.bind(this), menuItem: item, outletData: outletData })
              //   }
              // }}
              >
                <Text
                  style={{
                    alignSelf: "center",
                    color: "#8f8f8f",
                    fontSize: switchMerchant ? 10 : 13,
                    fontFamily: "NunitoSans-Bold",
                  }}
                >
                  {quantity > 0 ? quantity : "ADD"}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  });

  const outletMenu = ({ item }) => {
    console.log("render outletMenu");
    console.log(item);

    return (
      //<View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 15,
          width: "100%",
        }}
      >
        {/* <View
          style={{
            height: 50,
            width: '15%',
          }}>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 18,
              fontFamily: 'NunitoSans-Regular',
            }}>
            {item.uniqueId.slice(0, 8)}
          </Text>
        </View> */}
        {item.image ? (
          <AsyncImage
            source={{ uri: item.image }}
            item={item}
            style={{
              // width: 60,
              // height: 60,
              width: switchMerchant ? windowWidth * 0.06 : windowWidth * 0.06,
              height: switchMerchant ? windowWidth * 0.06 : windowWidth * 0.06,
              borderRadius: 10,
            }}
          />
        ) : (
          <View
            style={{
              width: switchMerchant ? windowWidth * 0.06 : windowWidth * 0.06,
              justifyContent: "center",
              alignItems: "center",
              height: switchMerchant ? windowWidth * 0.06 : windowWidth * 0.06,
            }}
          >
            <Ionicons name="fast-food-outline" size={40} />
          </View>
        )}
        <View
          style={{
            height: switchMerchant ? 30 : 50,
            width: "58%",
            marginLeft: 5,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontSize: switchMerchant ? 10 : 16,
              fontFamily: "NunitoSans-Regular",
            }}
            numberOfLines={switchMerchant ? 2 : 2}
          >
            {item.name}
          </Text>
        </View>
        <View style={{ height: switchMerchant ? 30 : 50, width: "8%" }}>
          <TouchableOpacity
            onPress={
              () => {
                changeMenu(item);
              }
              // setState({itemId: elements.id});
              // addActiveMenu(elements.id);
            }
          >
            <View
              style={{
                alignSelf: "flex-end",
              }}
            >
              <Close name="plus" size={switchMerchant ? 20 : 28} />
            </View>
          </TouchableOpacity>
        </View>
      </View>
      //</View>
    );
  };

  const changeItem = (itemID) => {
    // if (!outletId || !currentMenu || !selectedCategoryId) {

    //   Alert.alert(
    //     'Error',
    //     'Please fill up the information',
    //     [{ text: 'OK', onPress: () => { } }],
    //     { cancelable: false },
    //   );
    //   return;
    // } else {
    //   var menuID = [];
    //   currentMenu.forEach((element) => {
    //     menuID.push(element.id);
    //   });
    // }
    var body = {
      outletId: outletId,
      itemId: itemID,
      // newCategoryId: selectedCategoryId,
      // active: true
    };

    ApiClient.POST(API.changeItem, body).then((result) => {
      if (result.success == true) {
        // setState({modal: false});
        // refreshTable();
        Alert.alert(
          "Success",
          "You have added the menu",
          [
            {
              text: "OK",
              onPress: () => {
                setState({ modal: false });
              },
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  const deleteCurrentMenu = (itemID) => {
    if (!outletId || !currentMenu) {
      Alert.alert(
        "Error",
        "Please fill in the information",
        [{ text: "OK", onPress: () => {} }],
        { cancelable: false }
      );
      return;
    } else {
      var menuID = [];
      currentMenu.forEach((element) => {
        menuID.push(element.id);
      });
    }
    var body = {
      outletId: outletId,
      itemId: itemID,
    };
    ApiClient.POST(API.setActiveMenu, body).then((result) => {
      if (result == true) {
        refreshMenu();
        Alert.alert(
          "Success",
          "You have deleted the menu",
          [
            {
              text: "OK",
              onPress: () => {
                // setState({modal: false});
              },
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  // const addCurrentMenu = () => {
  //   if (!name || !category || outletId != null) {
  //     Alert.alert(
  //       'Error',
  //       'Please fill in the information',
  //       [{ text: 'OK', onPress: () => { } }],
  //       { cancelable: false },
  //     );
  //     return;
  //   } else {
  //     var body = {
  //       itemId: name,
  //       newCategoryId: category,
  //       outletId: outletId,
  //     };
  //     ApiClient.POST(API.addCurrentMenu, body).then((result) => {
  //       if (result == true) {
  //         refreshTable();
  //         Alert.alert(
  //           'Success',
  //           'You have added to the menu',
  //           [
  //             {
  //               text: 'OK',
  //               // onPress: () => {
  //               //   setState({modal: false});
  //               // },
  //             },
  //           ],
  //           { cancelable: false },
  //         );
  //       }
  //     });
  //   }
  // };

  const deleteItem = (param) => {
    var body = {
      itemId: param,
    };

    ApiClient.POST(API.deleteMenuItem, body).then((result) => {
      if (result.success == true) {
        refreshMenu();
        Alert.alert(
          "Success",
          "The product has been deleted",
          [{ text: "OK", onPress: () => {} }],
          { cancelable: false }
        );
      }
      if (result.success == false) {
        refreshMenu();
        Alert.alert(
          "Failed",
          "The product has not been deleted",
          [{ text: "OK", onPress: () => {} }],
          { cancelable: false }
        );
      }
    });
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity
      onPress={() => {
        setState({
          addProductItem: false,
          addMenuItem: false,
          addProduct: true,
        });
        // getItemByOutletTEST(item.id);
      }}
    >
      <View
        style={{
          backgroundColor: "#FFFFFF",
          flexDirection: "row",
          paddingVertical: 20,
          paddingHorizontal: 20,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: "#C4C4C4",
        }}
      >
        <Image
          source={{
            //item.image
            uri: "http://streetfoodaffair.co.uk/wp-content/uploads/2017/04/Polpetta.jpg",
          }}
          style={{ width: 20, height: 20 }}
        />
        <Text style={{ width: "2%", fontFamily: "NunitoSans-Bold" }}></Text>
        <Text style={{ width: "14%", fontFamily: "NunitoSans-Bold" }}>
          {item.name}
        </Text>
        <Text style={{ width: "15%", fontFamily: "NunitoSans-Bold" }}>
          {item.category}
        </Text>
        <Text
          style={{
            width: "8%",
            fontFamily: "NunitoSans-Regular",
            color: item.active ? Colors.primaryColor : Colors.fieldtTxtColor,
            marginLeft: 30,
          }}
        >
          {item.active ? "Available" : "Out of Stock"}
        </Text>
        <Switch
          style={{
            marginBottom: 5,
            width: "5%",
            transform:
              Platform.OS == "ios"
                ? [{ scaleX: 0.6 }, { scaleY: 0.6 }]
                : [{ scaleX: 0.8 }, { scaleY: 0.8 }],
          }}
          value={item.active == 1 ? true : false}
          onSyncPress={(status) =>
            setState({ status }, () => {
              setActiveMenu(item.id);
            })
          }
          circleColorActive={Colors.primaryColor}
          circleColorInactive={Colors.fieldtTxtColor}
          backgroundActive="#dddddd"
        />
        <Text style={{ width: "2%", fontFamily: "NunitoSans-Bold" }}></Text>
        <Text style={{ width: "12%", fontFamily: "NunitoSans-Bold" }}>
          RM {item.price}
        </Text>
        <Text
          style={{
            width: "20%",
            marginRight: 20,
            fontFamily: "NunitoSans-Regular",
          }}
        >
          {item.description}
        </Text>
        <Text style={{ width: "7%", fontFamily: "NunitoSans-Bold" }}>
          {item.code}
        </Text>
      </View>
    </TouchableOpacity>
  );

  const renderSearchItem = ({ item }) =>
    item.items.map((elements) => {
      return (
        <TouchableOpacity
          onPress={() => {
            setState({
              addProductItem: false,
              addMenuItem: false,
              addProduct: true,
            });
            // getItemByOutletTEST(elements.id);
          }}
        >
          <View
            style={{
              backgroundColor: "#FFFFFF",
              flexDirection: "row",
              paddingVertical: 20,
              paddingHorizontal: 20,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderBottomColor: "#C4C4C4",
            }}
          >
            <AsyncImage
              source={{
                //elements.image
                uri: elements.image,
                //'http://streetfoodaffair.co.uk/wp-content/uploads/2017/04/Polpetta.jpg',
              }}
              item={elements}
              style={{ width: 20, height: 20 }}
            />
            <Text
              style={{ width: "2%", fontFamily: "NunitoSans-Regular" }}
            ></Text>
            <Text style={{ width: "14%", fontFamily: "NunitoSans-Regular" }}>
              {elements.name}
            </Text>
            <Text style={{ width: "15%", fontFamily: "NunitoSans-Regular" }}>
              {elements.category}
            </Text>
            <Text style={{ width: "8%", fontFamily: "NunitoSans-Regular" }}>
              {elements.active ? "Available" : "Out of Stock"}
            </Text>
            <Switch
              style={{ marginTop: 0, width: "10%" }}
              // value={elements.active ? status : !status}
              onSyncPress={(status) => setState({ status })}
              circleColorActive={Colors.primaryColor}
              circleColorInactive={Colors.fieldtTxtColor}
              backgroundActive="#dddddd"
            />
            <Text
              style={{ width: "2%", fontFamily: "NunitoSans-Regular" }}
            ></Text>
            <Text style={{ width: "16%", fontFamily: "NunitoSans-Regular" }}>
              {elements.price}
            </Text>
            <Text
              style={{
                width: "20%",
                marginRight: 20,
                fontFamily: "NunitoSans-Regular",
              }}
            >
              {elements.description}
            </Text>
            <Text style={{ width: "7%", fontFamily: "NunitoSans-Regular" }}>
              {elements.code}
            </Text>
          </View>
        </TouchableOpacity>
      );
    });
  // function end

  const onadditem = () => {
    // const productOptions = productOptions;

    productOptions.push({
      optionId: (productOptions.length + 1).toString(),
      name: "",
      price: 0,
    });
    setState({ productOptions });
  };
  const onadditem1 = () => {
    const productOptions1 = productOptions1;
    productOptions1.push({
      optionId: (productOptions1.length + 1).toString(),
      name: "",
      min: 0,
      max: 0,
    });
    setState({ productOptions1 });
  };

  const getName = () => {
    // const productOptions = productOptions;
    var name = null;
    var i;
    for (i = 0; i < productOptions.length; i++) {
      if (productOptions[i].optionId != null) {
        name = productOptions[i].name;
      }
    }
    return name;
  };

  const renderOptions = () => {
    const options = [];
    // const productOptions = productOptions;
    for (const opt of productOptions) {
      options.push(
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
            alignContent: "space-between",
            height: 50,
            width: "100%",
          }}
        >
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              width: 120,
              padding: 10,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
              marginRight: "10%",
            }}
            placeholder="Option Name"
            onChangeText={(text) => {
              // const productOptions = productOptions;
              const item = productOptions.find(
                (obj) => obj.id === productOptions.id
              );
              item.name = text;
              setState({ productOptions });
            }}
            value={(value) => {
              // const productOptions = productOptions;
              const item = productOptions.find(
                (obj) => obj.id === productOptions.id
              );
              value = item.name;
            }}
            multiline={true}
            ref={myTextInput}
            placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
          />
          <Text
            style={{
              fontSize: 20,
              fontFamily: "NunitoSans-Bold",
              marginRight: "5%",
            }}
          >
            RM
          </Text>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              width: 60,
              padding: 10,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
              marginRight: "10%",
            }}
            placeholder="Price"
            placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
            onChangeText={(text) => {
              // const productOptions = productOptions;
              const item = productOptions.find(
                (obj) => obj.id === productOptions.id
              );
              item.price = text;
              setState({ productOptions });
            }}
            value={(value) => {
              // const productOptions = productOptions;
              const item = productOptions.find(
                (obj) => obj.id === productOptions.id
              );
              value = item.price;
            }}
            multiline={true}
            ref={myTextInput}
          />
        </View>
      );
    }
    return options;
  };

  const renderOptions1 = () => {
    const options1 = [];
    for (const opt of productOptions1) {
      options1.push(
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 10,
            alignContent: "space-between",
            height: 50,
            width: "100%",
          }}
        >
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
            style={{
              width: 120,
              padding: 10,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
              marginRight: "10%",
            }}
            placeholder="Option Name"
            onChangeText={(text) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id
              );
              item.name = text;
              setState({ productOptions1 });
            }}
            value={(value) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id
              );
              value = item.name;
            }}
            multiline={true}
            ref={myTextInput}
          />
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
            style={{
              width: 60,
              padding: 10,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
            }}
            placeholder="MIN"
            onChangeText={(text) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id
              );
              item.min = text;
              setState({ productOptions1 });
            }}
            value={(value) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id
              );
              value = item.min;
            }}
            multiline={true}
            ref={myTextInput}
          />
          <Text
            style={{
              fontSize: 20,
              fontFamily: "NunitoSans-Bold",
              marginRight: "5%",
              marginLeft: "5%",
            }}
          >
            /
          </Text>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
            style={{
              width: 60,
              padding: 10,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
            }}
            placeholder="MAX"
            onChangeText={(text) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id
              );
              item.max = text;
              setState({ productOptions1 });
            }}
            value={(value) => {
              const productOptions1 = productOptions1;
              const item = productOptions1.find(
                (obj) => obj.id === productOptions1.id
              );
              value = item.max;
            }}
            multiline={true}
            ref={myTextInput}
          />
        </View>
      );
    }
    return options1;
  };

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={2} expandReport={true} />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <Modal animationType="fade" visible={modalCategory} transparent={true}>
          <View
            style={{
              backgroundColor: Colors.modalBgColor,
              flex: 1,
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: "40%",
                height: windowWidth * 0.25,
                backgroundColor: "white",
                borderRadius: 12,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                style={{
                  position: "absolute",
                  top: "8%",
                  right: "5%",
                }}
                onPress={() => setModalCategory(false)}
              >
                <AIcon
                  name="closecircle"
                  size={switchMerchant ? 15 : 25}
                  color={Colors.fieldtTxtColor}
                />
              </TouchableOpacity>
              <View style={{ padding: 13 }}>
                <Text
                  style={{
                    fontFamily: "NunitoSans-SemiBold",
                    fontSize: switchMerchant ? 16 : 24,
                  }}
                >
                  Edit Product Category
                </Text>
              </View>
              <View style={{ padding: 13 }}>
                <Text
                  style={{
                    fontFamily: "NunitoSans-SemiBold",
                    fontSize: switchMerchant ? 10 : 20,
                  }}
                >
                  Choose Category
                </Text>
              </View>
              {/* drop down list here */}
              <View
                style={{
                  width: switchMerchant ? "90%" : 250,
                  height: switchMerchant ? 35 : "20%",
                  backgroundColor: "white",
                  borderRadius: 10,
                  marginBottom: 15,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: "90%",
                  }}
                >
                  <Picker
                    selectedValue={editOutletCategoryId}
                    style={{}}
                    onValueChange={(value) => {
                      // setSelectedOutletCategoryId(value);

                      setEditOutletCategoryId(value);
                    }}
                  >
                    {outletCategoryDropdownList.map((value, index) => {
                      return (
                        <Picker.Item
                          key={index}
                          label={value.label}
                          value={value.value}
                        />
                      );
                    })}
                  </Picker>
                </View>
              </View>
              <View
                style={{
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    width: switchMerchant ? 80 : 100,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                    marginRight: Dimensions.get("screen").width * 0.05,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      // change category function here

                      changeMenu(selectedProductEdit);
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      SAVE
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.tabRed,
                    backgroundColor: Colors.tabRed,
                    borderRadius: 5,
                    width: switchMerchant ? 80 : 100,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      // delete function here
                      if (
                        window.confirm(
                          "Info: Are you sure you want to remove this item from the menu?"
                        )
                      ) {
                        removeOutletItemOwnCategory(selectedProductEdit);
                      }
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      REMOVE
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </Modal>
        {/* <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                    <View style={{ flex: 2 }}></View>
                    <View
                        style={{
                            flex: 1,
                            alignContent: 'flex-end',
                            marginBottom: 10,
                            flexDirection: 'row',
                        }}>
                        <View style={styles.textInput}>
                            <Icon name="search" size={18} color={Colors.fieldtTxtColor} />
                            <TextInput
                                editable={!loading}
                                underlineColorAndroid={Colors.whiteColor}
                                style={{ width: 250 }}
                                clearButtonMode="while-editing"
                                placeholder=" Search"
                                onChangeText={(text) => {
                                    setState({
                                        search: text.trim(),
                                        productlist1: false,
                                        searchList: true,
                                    });
                                }}
                                value={search}
                            //onSubmitEditing={searchBarItem}
                            />
                        </View>
                    </View>
                </View> */}
        {/* <View
                    style={{
                        flexDirection: 'row',
                        backgroundColor: Colors.highlightColor,
                        padding: 12,
                    }}>
                    <TouchableOpacity
                        onPress={() => {
                            //props.navigation.navigate("ProductMenu")
                            //category()
                        }}>
                        <Text style={{ marginLeft: 30, fontFamily: 'NunitoSans-Regular' }}>Menu</Text>
                    </TouchableOpacity>
                </View> */}
        <View
          style={{
            backgroundColor: Colors.whiteColor,
            borderRadius: 10,
            width: "97.5%",
            height: windowHeight * 0.9,
            marginHorizontal: 20,
            marginVertical: 15,
            borderRadius: 5,
            shadowOpacity: 0,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.22,
            shadowRadius: 3.22,
            elevation: 3,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              // backgroundColor: Colors.whiteColor,
            }}
          >
            <View
              style={{
                flex: 4,
                borderRightWidth: StyleSheet.hairlineWidth,
              }}
            >
              <View
                style={[
                  styles.content2,
                  {
                    justifyContent: "space-between",
                    paddingVertical: switchMerchant ? 10 : 20,
                    zIndex: 1000,
                  },
                ]}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    zIndex: 1000,
                    width: windowWidth * 0.35,
                  }}
                >
                  <Text
                    style={{
                      marginLeft: 5,
                      fontFamily: "NunitoSans-Bold",
                      fontSize: switchMerchant ? 10 : 16,
                    }}
                  >
                    Choose Category
                  </Text>
                  <View
                    style={[
                      {
                        width: switchMerchant ? 150 : 250,
                        marginLeft: switchMerchant ? 10 : 20,
                        zIndex: 6000,
                      },
                    ]}
                  >
                    <View style={{ zIndex: 10000 }}>
                      <Picker
                        style={{
                          width: switchMerchant ? 150 : 250,
                          paddingVertical: 0,
                          backgroundColor: Colors.fieldtBgColor,
                          borderRadius: 10,
                          zIndex: 1000,
                        }}
                        onValueChange={(value, text) => {
                          setSelectedOutletCategoryId(value);
                          setEditOutletCategoryId(value);
                        }}
                      >
                        {outletCategoryDropdownList.map((value, index) => {
                          return (
                            <Picker.Item
                              key={index}
                              label={value.label}
                              value={value.value}
                            />
                          );
                        })}
                      </Picker>
                    </View>
                  </View>
                </View>
                <View style={{}}>
                  <View
                    style={{
                      justifyContent: "center",
                      flexDirection: "row",
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      backgroundColor: "#4E9F7D",
                      borderRadius: 5,
                      width: switchMerchant ? 80 : 120,
                      paddingHorizontal: 10,
                      height: switchMerchant ? 35 : 40,
                      alignItems: "center",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      // zIndex: 1000,
                    }}
                  >
                    <TouchableOpacity
                      disabled={isLoading}
                      onPress={() => {
                        updateOutletItemOrderIndex();
                      }}
                    >
                      {isLoading ? (
                        <ActivityIndicator
                          color={Colors.whiteColor}
                          size={"small"}
                        />
                      ) : (
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          SAVE
                        </Text>
                      )}
                    </TouchableOpacity>
                  </View>
                </View>
              </View>

              {/* menuList */}

              <View
                style={{
                  zIndex: -1000,
                  height: windowHeight * 0.75,
                }}
              >
                <FlatList
                  nestedScrollEnabled={true}
                  showsVerticalScrollIndicator={false}
                  style={{
                    marginTop: switchMerchant ? 10 : 20,
                    paddingBottom: switchMerchant ? 50 : 0,
                    zIndex: -1000,
                  }}
                  contentContainerStyle={{
                    paddingBottom: switchMerchant ? 150 : 0,
                  }}
                  numColumns={3}
                  data={chosenOutletCategoryItemList.concat({
                    actionButton: true,
                  })}
                  extraData={chosenOutletCategoryItemList.concat({
                    actionButton: true,
                  })}
                  renderItem={renderMenu}
                  keyExtractor={(item, index) => item.uniqueId}
                  // ListFooterComponent={
                  //     <TouchableOpacity
                  //         style={styles.tableIcon}
                  //         onPress={() => {
                  //             setState({ modal: true });
                  //         }}>
                  //         <Text
                  //             style={{
                  //                 fontSize: 50,
                  //                 textAlign: 'center',
                  //                 color: Colors.descriptionColor,
                  //             }}>
                  //             +
                  //         </Text>
                  //     </TouchableOpacity>
                  // }
                />
              </View>

              {/* <View
                  style={{
                    alignItems: 'center',
                }}>
                  <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    flexDirection: 'row',
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: '#4E9F7D',
                    borderRadius: 5,
                    width: 120,
                    paddingHorizontal: 10,
                    height: 40,
                    alignItems: 'center',
                    shadowOffset: {
                        width: 0,
                        height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: 1000,
                }}
                    onPress={() => {
                      // addActiveMenu()
                    }}>
                    <Text style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: 16,
                        fontFamily: 'NunitoSans-Bold',
                    }}>
                      SAVE
                    </Text>
                  </TouchableOpacity>
                </View> */}
            </View>
            {/* right */}
            <View style={{ flex: 2, alignItems: "center", paddingBottom: 10 }}>
              <View style={styles.content22}>
                <Text
                  style={{
                    alignSelf: "center",
                    fontFamily: "NunitoSans-Regular",
                    fontSize: switchMerchant ? 10 : 16,
                  }}
                >
                  Customer's Perspective
                </Text>
              </View>

              {/* <ScrollView
                  showsHorizontalScrollIndicator={false}
                  stickyHeaderIndices={[1]}> */}
              <AsyncImage
                source={{ uri: currOutlet.cover }}
                item={currOutlet}
                style={styles.outletCover}
              />

              <View
                style={{
                  width: "100%",
                }}
              >
                {/* <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', position: 'absolute', zIndex: 2, marginTop: 0, }}>
                      <AIcon name='left' size={switchMerchant ? 20 : 23} style={{ height: switchMerchant ? 40 : 45, color: Colors.primaryColor, zIndex: 2, opacity: 0.8, backgroundColor: Colors.highlightColor, paddingTop: 10 }} />
                      <AIcon name='right' size={switchMerchant ? 20 : 23} style={{ height: switchMerchant ? 40 : 45, color: Colors.primaryColor, zIndex: 2, alignSelf: 'flex-end', opacity: 0.8, backgroundColor: Colors.highlightColor, paddingTop: 10 }} />
                    </View> */}
                <View
                  style={{
                    flexDirection: "row",
                    width: windowWidth * 0.298,
                    // borderWidth: 1,
                    overflowY: "hidden",
                    overflowX: "scroll",
                  }}
                >
                  {/* <AIcon
                    name="left"
                    size={switchMerchant ? 17 : 23}
                    style={{
                      height: switchMerchant ? 35 : 45,
                      color: Colors.primaryColor,
                      zIndex: 2,
                      opacity: 0.8,
                      backgroundColor: Colors.highlightColor,
                      paddingTop: 10,
                    }}
                  /> */}
                  <ScrollMenu
                    // LeftArrow={
                    //   <AIcon
                    //     name="left"
                    //     size={switchMerchant ? 17 : 23}
                    //     style={{
                    //       height: switchMerchant ? 35 : 45,
                    //       color: Colors.primaryColor,
                    //       zIndex: 2,
                    //       opacity: 0.8,
                    //       backgroundColor: Colors.highlightColor,
                    //       paddingTop: 10,
                    //     }}
                    //   />
                    // }
                    // RightArrow={
                    //   <AIcon
                    //     name="right"
                    //     size={switchMerchant ? 17 : 23}
                    //     style={{
                    //       height: switchMerchant ? 35 : 45,
                    //       color: Colors.primaryColor,
                    //       zIndex: 2,
                    //       opacity: 0.8,
                    //       backgroundColor: Colors.highlightColor,
                    //       paddingTop: 10,
                    //     }}
                    //   />
                    // }
                    onWheel={onWheel}
                  >
                    {outletCategories.map((item, index) => {
                      return (
                        <TouchableOpacity
                          onPress={() => {
                            // CommonStore.update(s => {
                            //     s.selectedOutletItemCategory = item;
                            // });
                            setSelectedOutletItemCategory(item);
                          }}
                        >
                          <View style={[styles.categoryUser, { width: 215 }]}>
                            <View
                              style={{
                                borderBottomColor:
                                  selectedOutletItemCategory.name == item.name
                                    ? Colors.primaryColor
                                    : null,
                                borderBottomWidth:
                                  selectedOutletItemCategory.name == item.name
                                    ? 3
                                    : 0,
                              }}
                            >
                              <Text
                                style={{
                                  textTransform: "capitalize",
                                  paddingVertical: switchMerchant ? 8 : 12,
                                  fontFamily:
                                    selectedOutletItemCategory.name == item.name
                                      ? "NunitoSans-Bold"
                                      : "NunitoSans-Regular",
                                  color:
                                    selectedOutletItemCategory.name == item.name
                                      ? Colors.primaryColor
                                      : Colors.mainTxtColor,
                                  fontSize: switchMerchant ? 10 : 16,
                                }}
                              >
                                {item.name}
                              </Text>
                            </View>
                          </View>
                        </TouchableOpacity>
                      );
                    })}
                  </ScrollMenu>
                </View>
              </View>

              <View
                style={{
                  flex: 1,
                }}
              >
                {/* <ScrollViewGH
                  nestedScrollEnabled={true}
                  ref={scrollViewGHRef}
                  scrollEnabled={draggingScroll}
                  //scrollEnabled={false}
                > */}
                {/* web view draggable */}
                <View
                  style={{
                    height: windowHeight * 0.34,
                    // borderWidth: 1,
                    width: "100%",
                    overflowX: 'hidden',
                    overflowY: 'scroll',
                  }}
                >
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {sortedOutletItems.map((item, index) => (
                            <Draggable
                              key={item.uniqueId}
                              draggableId={item.uniqueId}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // style={getItemStyle(
                                  //   snapshot.isDragging,
                                  //   provided.draggableProps.style
                                  // )}
                                >
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      paddingHorizontal: switchMerchant
                                        ? 5
                                        : 20,
                                      paddingBottom: 15,
                                      paddingTop: 10,
                                      display: "flex",
                                      justifyContent: "space-between",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        alignContent: "center",
                                        alignItems: "center",
                                        width: switchMerchant ? "68%" : "75%",
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        // backgroundColor: 'blue',
                                      }}
                                    >
                                      <View
                                        style={[
                                          {
                                            backgroundColor:
                                              Colors.secondaryColor,
                                            // width: 60,
                                            // height: 60,
                                            width: windowWidth * 0.06,
                                            height: windowWidth * 0.06,
                                            borderRadius: 10,
                                          },
                                          item.image
                                            ? {}
                                            : {
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              },
                                        ]}
                                      >
                                        {item.image ? (
                                          <AsyncImage
                                            source={{ uri: item.image }}
                                            item={item}
                                            style={{
                                              // width: 60,
                                              // height: 60,
                                              width: windowWidth * 0.06,
                                              height: windowWidth * 0.06,
                                              borderRadius: 10,
                                            }}
                                          />
                                        ) : (
                                          <Ionicons
                                            name="fast-food-outline"
                                            size={25}
                                          />
                                        )}
                                      </View>
                                      <View
                                        style={{
                                          marginLeft: switchMerchant ? 10 : 15,
                                          // flexDirection: 'row',
                                          // flexShrink: 1,
                                          width: switchMerchant ? "55%" : "55%",
                                          // backgroundColor: 'red',
                                        }}
                                      >
                                        <Text
                                          numberOfLines={3}
                                          style={{
                                            fontSize: switchMerchant ? 10 : 13,
                                            textTransform: "uppercase",
                                            fontFamily: "NunitoSans-Bold",
                                            // flexWrap: 'wrap',
                                            // flex: 1,
                                            // flexShrink: 1,
                                            // width: '100%',
                                          }}
                                        >
                                          {item.name}
                                        </Text>

                                        <Text
                                          style={{
                                            color: Colors.primaryColor,
                                            fontFamily: "NunitoSans-Bold",
                                            paddingTop: 5,
                                            fontSize: switchMerchant ? 10 : 13,
                                          }}
                                        >
                                          RM
                                          {parseFloat(item.price)
                                            .toFixed(2)
                                            .replace(
                                              /(\d)(?=(\d{3})+(?!\d))/g,
                                              "$1,"
                                            )}
                                        </Text>
                                      </View>
                                    </View>
                                  </View>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </View>

                {/* <DraggableFlatList
                    simultaneousHandlers={scrollViewGHRef}
                    nestedScrollEnabled={true}
                    scrollEnabled={draggingScroll}
                    // autoscrollThreshold={scrollViewGHRef}
                    // scrollEnabled={true}
                    data={sortedOutletItems}
                    // extraData={outletItems}
                    renderItem={renderMenuUser}
                    keyExtractor={(item, index) =>
                      `draggable-item-${item.uniqueId}`
                    }
                    contentContainerStyle={{
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 10,
                    }}
                    // onDragBegin={() => {
                    //   setDraggingScroll(false);
                    // }}
                    onDragEnd={({ data, from, to }) => {
                      console.log('on drag');
                      console.log(data);
                      console.log(from);
                      console.log(to);

                      var sortedOrderIndexIdDictTemp = {
                        ...sortedOrderIndexIdDict,
                      };

                      for (var i = 0; i < data.length; i++) {
                        sortedOrderIndexIdDictTemp = {
                          ...sortedOrderIndexIdDictTemp,
                          [data[i].uniqueId]: i + 1,
                        };
                      }

                      setSortedOrderIndexIdDict(sortedOrderIndexIdDictTemp);
                      setDraggingScroll(true);

                      // setSortedOutletItems(data.sort((a, b) => {
                      //   return (a.orderIndex ? a.orderIndex : data.length) - (b.orderIndex ? b.orderIndex : data.length);
                      // }));
                    }}
                  /> */}
                {/* </ScrollViewGH> */}
              </View>
              {/*  <View style={{ minHeight: switchMerchant? 50 : 100 }} /> */}
              {/* </ScrollView> */}

              {/* <View style={{ width: Dimensions.get('screen').width * 0.25, backgroundColor: 'red' }}>
                                    <Image
                                        style={{ width: Dimensions.get('screen').width * 0.25, height: Dimensions.get('screen').height * 0.2 }}
                                        resizeMode="cover"
                                        source={{ uri: '' }}
                                    />
                                </View>
                                <View style={{ height: 40 }}>
                                    <ScrollView
                                        showsHorizontalScrollIndicator={false}
                                        alwaysBounceHorizontal={true}
                                        horizontal={true}
                                        style={styles.infoTab}>
                                        {categoryOutlet.map((item, index) => {
                                            return (
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        setState({
                                                            category: item.label,
                                                            menu: productList[index].items,
                                                        },
                                                            () => {
                                                                refreshMenu();
                                                                console.log("thissatate", category)
                                                            });
                                                    }}>
                                                    <View
                                                        style={[
                                                            styles.category,
                                                            {
                                                                borderBottomColor:
                                                                    item.label == category
                                                                        ? Colors.primaryColor
                                                                        : null,
                                                                borderBottomWidth:
                                                                    item.label == category
                                                                        ? 10
                                                                        : 0,
                                                            },
                                                        ]}>
                                                        <Text
                                                            style={{
                                                                textTransform: 'capitalize',
                                                                paddingVertical: 12,
                                                                fontFamily: 'NunitoSans-Bold',
                                                                color: category == item.label
                                                                    ? Colors.primaryColor
                                                                    : Colors.blackColor,
                                                            }}>
                                                            {item.label}
                                                        </Text>
                                                    </View>
                                                </TouchableOpacity>
                                            );
                                        })}
                                    </ScrollView>
                                </View>
                                <View
                                    style={{
                                        width: 300,
                                        height: Dimensions.get('window').height + 250,
                                        flex: 1,
                                    }}>
                                    <FlatList
                                        style={{
                                            height: Dimensions.get('window').height * 2,
                                            marginBottom: 10
                                        }}
                                        data={currentMenu.filter(i => i.name == category)}
                                        extraData={currentMenu.filter(i => i.name == category)}
                                        // deleteCurrentMenu = {deleteCurrentMenu.bind(this)}
                                        // renderItem={renderCurrentMenu}
                                        renderItem={({ item }) => (
                                            <ListItem
                                                {...item}
                                                onSwipeFromLeft={() => alert('swiped from left!')}
                                                onRightPress={() => { deleteCurrentMenu(item.id) }}
                                            // onRightPress={() => { deleteItem(item.id) }}
                                            />
                                        )}
                                        ItemSeparatorComponent={() => <Separator />}
                                        keyExtractor={(item, index) => index}
                                    />

                                </View> */}
            </View>
          </View>

          {/* <View
            style={{
              flexDirection: 'row',
              backgroundColor: '#ffffff',
              justifyContent: 'center',
              padding: 18,
            }}>
          </View> */}
          <Modal
            visible={modal}
            transparent={true}
            animationType="slide"
            supportedOrientations={["portrait", "landscape"]}
          >
            <View>
              <View
                style={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: windowHeight,
                }}
              >
                <View
                  style={[
                    styles.confirmMenu,
                    {
                      width: windowWidth * 0.7,
                      height: windowHeight * 0.7,
                    },
                  ]}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      marginBottom: 20,
                    }}
                  >
                    <View
                      style={{
                        justifyContent: "center",
                        width: "50%",
                        paddingLeft: "8%",
                        flex: 1.5,
                        marginTop: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: switchMerchant ? 15 : 30,
                          fontFamily: "NunitoSans-Bold",
                          textAlign: "center",
                        }}
                      >
                        List of Menu
                      </Text>
                    </View>
                    <View>
                      <TouchableOpacity
                        onPress={() => {
                          // setState({ modal: false });
                          setModal(false);
                        }}
                      >
                        <View style={{ padding: 10 }}>
                          {/* <Close name="closecircle" size={25} /> */}
                          <AntDesign
                            name="closecircle"
                            size={switchMerchant ? 15 : 23}
                            color={Colors.fieldtTxtColor}
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: switchMerchant ? 10 : 20,
                    }}
                  >
                    <View
                      style={{
                        height: 30,
                        width: "1%",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          fontSize: 18,
                          fontFamily: "NunitoSans-Regular",
                        }}
                      ></Text>
                    </View>
                    <View style={{ height: 30, width: "58%" }}>
                      <Text
                        style={{
                          textAlign: "center",
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: "NunitoSans-Regular",
                        }}
                      >
                        Food Name
                      </Text>
                    </View>
                  </View>
                  <FlatList
                    showsVerticalScrollIndicator={false}
                    // data={productList2}
                    data={outletItems}
                    extraData={outletItems}
                    renderItem={outletMenu}
                    keyExtractor={(item, index) => item.uniqueId}
                  />
                </View>
              </View>
            </View>
          </Modal>
        </View>
        {/* </ScrollView> */}
      </View>
      {/* <Modal
      style={{ flex: 1 }}
      visible={visible}
      transparent={true}
      animationType="slide">
      <View
        style={{
          backgroundColor: 'rgba(0,0,0,0.5)',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: Dimensions.get('window').height,
        }}>
        <View style={styles.confirmBox}>
          <TouchableOpacity
            onPress={() => {
              setState({ visible: false });
            }}>
            <View
              style={{
                alignSelf: 'flex-end',
                padding: 16,
              }}>
              <Close name="closecircle" size={28} />
            </View>
          </TouchableOpacity>
          <View
            style={{
              alignSelf: 'center',
              marginTop: '10%',
              justifyContent: 'center',
              alignItems: 'center',
              width: 250,
              height: 40,
              alignContent: 'center',
            }}>
            <TouchableOpacity
              onPress={() => {
                setState({ visible1: true });
              }}
              style={{
                backgroundColor: Colors.primaryColor,
                width: '60%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                borderRadius: 10,
                height: 60,
              }}>
              <Text style={{ fontSize: 28, color: Colors.whiteColor, fontFamily: 'NunitoSans-Regular' }}>
                Single
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({ visible2: true });
              }}
              style={{
                backgroundColor: Colors.primaryColor,
                width: '60%',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                borderRadius: 10,
                height: 60,
                marginTop: 10,
              }}>
              <Text style={{ fontSize: 28, color: Colors.whiteColor, fontFamily: 'NunitoSans-Regular' }}>
                Multiple
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal> */}
      <Modal
        style={{}}
        visible={visible1}
        transparent={true}
        animationType="slide"
      >
        <View
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            minHeight: windowHeight,
          }}
        >
          <View style={styles.confirmBox1}>
            <TouchableOpacity
              onPress={() => {
                setState({ visible1: false });
              }}
            >
              <View
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  alignSelf: "flex-end",
                  height: 20,
                }}
              >
                {/* <Close name="closecircle" size={25} /> */}
                <AntDesign
                  name="closecircle"
                  size={25}
                  color={Colors.fieldtTxtColor}
                />
              </View>
            </TouchableOpacity>
            <View
              style={{
                alignSelf: "center",
                marginTop: 20,
                justifyContent: "center",
                alignItems: "center",
                width: "70%",
                height: "80%",
                alignContent: "center",
              }}
            >
              <Text style={{ fontSize: 30, fontFamily: "NunitoSans-Bold" }}>
                Single
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                  alignContent: "space-between",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 50,
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    fontWeight: "bold",
                    marginRight: "10%",
                  }}
                >
                  Title
                </Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                  style={{
                    width: 140,
                    padding: 10,
                    backgroundColor: Colors.fieldtBgColor,
                    borderRadius: 5,
                  }}
                  placeholder="Category"
                  onChangeText={(text) => {
                    setState({ options: text });
                  }}
                  value={options}
                  multiline={true}
                  ref={myTextInput}
                />
              </View>
              <View
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  marginLeft: "90%",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    onadditem();
                  }}
                  style={{
                    backgroundColor: Colors.whiteColor,
                    alignItems: "center",
                  }}
                >
                  <Icon
                    name="plus-circle"
                    size={20}
                    color={Colors.primaryColor}
                  />
                </TouchableOpacity>
              </View>
              <ScrollView style={{ width: "100%", height: "50%" }}>
                {renderOptions()}
              </ScrollView>
              <View style={{ marginTop: 10, width: "100%" }}>
                <TouchableOpacity
                  disabled={loading}
                  onPress={() => {
                    setState({
                      productOptions,
                      visible: false,
                      visible1: false,
                      visible2: false,
                    });
                  }}
                >
                  <View style={styles.button}>
                    <Text style={{ color: "#ffffff", fontSize: 18 }}>
                      {loading ? "LOADING..." : "Save"}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        style={{}}
        visible={visible2}
        transparent={true}
        animationType="slide"
      >
        <View
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            minHeight: windowHeight,
          }}
        >
          <View style={styles.confirmBox1}>
            <TouchableOpacity
              onPress={() => {
                setState({ visible2: false });
              }}
            >
              <View
                style={{
                  marginTop: 10,
                  marginRight: 10,
                  alignSelf: "flex-end",
                  height: 20,
                }}
              >
                {/* <Close name="closecircle" size={25} /> */}
                <AntDesign
                  name="closecircle"
                  size={25}
                  color={Colors.fieldtTxtColor}
                />
              </View>
            </TouchableOpacity>
            <View
              style={{
                alignSelf: "center",
                marginTop: 5,
                alignItems: "center",
                width: "80%",
                height: "80%",
                alignContent: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 30,
                  fontFamily: "NunitoSans-Bold",
                  marginTop: 20,
                }}
              >
                Multiple
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                  alignContent: "space-between",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 50,
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "10%",
                  }}
                >
                  Title
                </Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                  style={{
                    width: 140,
                    padding: 10,
                    backgroundColor: Colors.fieldtBgColor,
                    borderRadius: 5,
                  }}
                  placeholder="Category"
                  onChangeText={(text) => {
                    setState({ options: text });
                  }}
                  value={options}
                  multiline={true}
                  ref={myTextInput}
                />
              </View>
              <View
                style={{
                  marginTop: 10,
                  marginBottom: 10,
                  marginLeft: "90%",
                }}
              >
                <TouchableOpacity
                  onPress={() => {
                    onadditem1();
                  }}
                  style={{
                    backgroundColor: Colors.whiteColor,
                    alignItems: "center",
                  }}
                >
                  <Icon
                    name="plus-circle"
                    size={20}
                    color={Colors.primaryColor}
                  />
                </TouchableOpacity>
              </View>
              <ScrollView style={{ width: "100%", height: "50%" }}>
                {renderOptions1()}
              </ScrollView>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 10,
                  alignContent: "space-between",
                  height: 50,
                  width: "100%",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "7%",
                  }}
                >
                  Price :
                </Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                  style={{
                    width: 100,
                    padding: 10,
                    backgroundColor: Colors.fieldtBgColor,
                    borderRadius: 5,
                    marginLeft: "3%",
                  }}
                  placeholder="Price"
                  onChangeText={(text) => {
                    setState({ price1: text });
                  }}
                  value={price1}
                  multiline={true}
                  ref={myTextInput}
                />
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "NunitoSans-Bold",
                  }}
                >
                  per toppings
                </Text>
              </View>
              <View style={{ marginTop: 10, width: "100%" }}>
                <TouchableOpacity
                  disabled={loading}
                  onPress={() => {
                    setState({
                      productOptions,
                      visible: false,
                      visible1: false,
                      visible2: false,
                    });
                  }}
                >
                  <View style={styles.button}>
                    <Text style={{ color: "#ffffff", fontSize: 18 }}>
                      {loading ? "LOADING..." : "Save"}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  confirmBox: {
    width: "30%",
    height: "30%",
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  confirmBox1: {
    width: "30%",
    height: "60%",
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
  },
  container: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: Colors.highlightColor,
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  list1: {
    backgroundColor: Colors.whiteColor,
    // marginTop: 30,
    // marginHorizontal: 30,
    // marginBottom: 30
    // paddingBottom: 30,
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  list1_PhoneScreen: {
    backgroundColor: Colors.whiteColor,
    //paddingBottom: 50,
    //marginTop: 30,
    //marginHorizontal: 30,
    //marginBottom: 60,
    //alignSelf: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 20,
  },
  content1: {
    backgroundColor: "#ffffff",
    flexDirection: "row",
    paddingVertical: 20,
    paddingHorizontal: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: "#c4c4c4",
  },
  content2: {
    flexDirection: "row",
    paddingHorizontal: 20,
    alignContent: "center",

    alignItems: "center",
  },
  content22: {
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 20,
    alignContent: "center",

    alignItems: "center",
  },
  content3: {
    backgroundColor: Colors.whiteColor,
    width: 40,
    height: 40,
    borderRadius: 20,
    alignSelf: "flex-end",
    position: "absolute",
    right: -15,
    marginVertical: -10,
  },
  content4: {
    backgroundColor: Colors.whiteColor,

    height: 25,
    borderRadius: 20,
    alignSelf: "center",
    alignItems: "center",
    bottom: 10,
  },
  content5: {
    backgroundColor: Colors.whiteColor,
    width: 140,
    height: 140,
    borderRadius: 5,
    marginLeft: 40,
    borderStyle: "dashed",
    borderWidth: 2,
    alignItems: "center",
    borderColor: Colors.primaryColor,
  },

  content6: {
    backgroundColor: "#717378",
    width: 40,
    height: 40,
    marginLeft: 20,
    marginVertical: 10,
    borderRadius: 5,
  },

  searchBar: {
    marginHorizontal: 16,
    backgroundColor: Colors.fieldtBgColor,
    flexDirection: "row",
    padding: 12,
    borderRadius: 10,
    alignContent: "center",
  },
  submitText: {
    height: 40,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  detailContainer: {
    flexDirection: "row",
    borderBottomWidth: StyleSheet.hairlineWidth,
    marginTop: 20,
    borderBottomColor: Colors.fieldtTxtColor,
    justifyContent: "space-between",
  },
  tableIcon: {
    // backgroundColor: '#717378',
    width: (Dimensions.get("window").width - 550) / 4,
    height: (Dimensions.get("window").width - 550) / 4,
    borderRadius: 10,
    marginLeft: 30,
    marginBottom: 20,
    justifyContent: "center",
    borderStyle: "dashed",
    borderWidth: 1,
    borderColor: Colors.fieldtTxtColor,
  },
  category: {
    width: 150,
    justifyContent: "center",
    alignItems: "center",
  },
  infoTab: {
    backgroundColor: Colors.highlightColor,
    width: Dimensions.get("screen").width * 0.25,
    height: -100,
  },
  textFieldInput: {
    height: 60,
    width: 250,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  textFieldInput1: {
    height: 50,
    width: 400,
    paddingHorizontal: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  textInventory: {
    height: 50,
    width: 200,
    paddingHorizontal: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  textFieldInput2: {
    height: 50,
    width: 270,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  textInput: {
    width: 250,
    height: Platform.OS == "ios" ? 40 : 50,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: "32%",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  textPrice: {
    width: 100,
    padding: 10,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    paddingTop: 10,
  },
  textTax: {
    width: 100,
    padding: 10,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    paddingTop: 10,
  },
  textInput1: {
    width: 100,
    padding: 6,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
  },
  button: {
    width: "50%",
    height: 50,
    backgroundColor: Colors.primaryColor,
    borderRadius: 10,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  textInput4: {
    width: 200,
    height: 50,
    borderRadius: 10,
    marginRight: "41%",
  },
  itemText: {
    margin: 10,
    color: "white",
    fontSize: 24,
    backgroundColor: "blue",
    width: "100%",
    height: 50,
  },
  confirmMenu: {
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
  },

  outletCover: {
    width: "100%",
    alignSelf: "center",
    height: undefined,
    aspectRatio: 2,
    //borderRadius: 5,
  },
  infoTabUser: {
    backgroundColor: Colors.fieldtBgColor,
  },
  categoryUser: {
    // width: 150,
    paddingHorizontal: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  headerLeftStyle: {
    width: Dimensions.get("screen").width * 0.17,
    justifyContent: "center",
    alignItems: "center",
  },
});
export default ProductMenuScreen;
