import { Store } from 'pullstate';

export const OutletStore = new Store({
    outletItems: [],
    outletItemsDict: {},
    outletCategories: [],
    outletCategoriesDict: {},

    allOutletsItems: [],
    allOutletsItemsSkuDict: {},
    allOutletsCategories: [],
    allOutletsCategoriesNameDict: {},
    allOutletsCategoriesDict: {},

    allOutletsCategoriesUnique: [],

    outletSections: [],
    outletSectionsDict: {},

    outletTables: [],
    outletTablesDict: {},
    outletTablesCodeDict: {},

    userOrders: [],
    userOrdersDict: {}, // { user_order_id -> user_order, ... }
    userOrdersTableDict: {}, // { outlet_table_id -> [ user_order, .... ], ... }

    userOrdersAllStatus: [],

    userReservations: [],
    userReservationsDict: {},
    userReservationsUserIdDict: {},
    userQueues: [],
    userQueuesDict: {},
    userRings: [],
    userRingsDict: {},

    allOutletsUserOrdersDone: [],

    userOrdersExpandedDict: {}, // { user_order_id -> true, ... } | used for KitchenOrder
    // userORdersExpandedFooterDict: {}, // { user_order_id -> true, ... } | used for KitchenScreen, footer

    currOutletShift: {},
    currOutletShiftStatus: 'CLOSED',

    allOutletShifts: [],

    allOutletsEmployees: [],

    outletsOpeningDict: {},

    beerDocketCategories: [],
    beerDocketCategoriesDict: {},

    beerDockets: [],   
    beerDocketsDict: {},

    userBeerDockets: [],

    userOrderBeerDocketUBDIdDict: {},

    promotions: [],

    linkedMerchantIdUsers: [],
    linkedOutletFavoriteUsers: [],
    favoriteMerchantIdUsers: [],
    crmUsersRaw: [],
    crmUsers: [],
    crmUsersDict: {},
    crmUserTags: [],
    crmUserTagsDict: {},
    crmSegments: [],

    selectedCustomerOrders: [],
    selectedCustomerDineInOrders: [],
    selectedCustomerAddresses: [],
    selectedCustomerPointsTransactions: [],
    selectedCustomerPointsBalance: 0,
    selectedCustomerReservations: [],
    //selectedCustomerVouchers: [],
    selectedCustomerVoucherRedemptions: [],
    selectedCustomerUserBeerDockets: [],

    selectedCustomerLCCTransactions: [], // loyalty campaign credit transactions
    selectedCustomerLCCBalance: 0, // loyalty campaign credit balance

    preorderPackages: [],

    pointsRedeemPackages: [],
    pointsRedeemPackagesDict: {},

    outletPrinters: [],

    outletPaymentMethods: [],

    loyaltyStamps: [],

    loyaltyCampaigns: [],
});   