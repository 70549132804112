import React, {
  Component,
  useReducer,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  StyleSheet,
  Image,
  View,
  Text,
  Alert,
  Dimensions,
  TouchableOpacity,
  Switch,
  FlatList,
  Modal,
  TextInput,
  KeyboardAvoidingView,
  PermissionsAndroid,
  Platform,
  ActivityIndicator,
  PlatformColor,
  Picker,
  ScrollView,
} from "react-native";

import Colors from "../constant/Colors";
import firebase from "firebase";
import SideBar from "./SideBar";
import Styles from "../constant/Styles";

import Feather from "react-native-vector-icons/Feather";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Entypo from "react-native-vector-icons/Entypo";
import Ionicon from "react-native-vector-icons/Ionicons";
import Icon1 from "react-native-vector-icons/Feather";
import AntDesign from "react-native-vector-icons/AntDesign";
import EvilIcons from "react-native-vector-icons/EvilIcons";

import ApiClient from "../util/ApiClient";
import API from "../constant/API";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as User from "../util/User";
import DropDownPicker from "react-native-dropdown-picker";
// import RNPickerSelect from 'react-native-picker-select';

import moment, { now } from "moment";
//import moment from "moment-timezone";

import { ReactComponent as Upload } from "../assets/svg/Upload.svg";
import { ReactComponent as Download } from "../assets/svg/Download.svg";
import { ReactComponent as Dish } from "../assets/svg/Dish.svg";
import { ReactComponent as Coins } from "../assets/svg/Coins.svg";
import { ReactComponent as Hand } from "../assets/svg/Hand.svg";
import { ReactComponent as GCoin } from "../assets/svg/GCoin.svg";
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import { ReactComponent as Magnify } from "../assets/svg/Magnify.svg";

//   import { LineChart, BarChart } from 'react-native-chart-kit';
//   import { Rect, Text as TextSVG, Svg } from 'react-native-svg';

// import RNFetchBlob from "rn-fetch-blob";
import { CommonStore } from "../store/commonStore";
//   import auth from '@react-native-firebase/auth';
import {
  listenToUserChangesMerchant,
  listenToMerchantIdChangesMerchant,
  listenToCurrOutletIdChangesWaiter,
  listenToAllOutletsChangesMerchant,
  listenToCommonChangesMerchant,
  listenToSelectedOutletItemChanges,
  convertArrayToCSV,
  listenToSelectedOutletTableIdChanges,
  requestNotificationsPermission,
  sortReportDataList,
  listenToSelectedCustomerChangesMerchant,
  generateEmailReport,
  listenToSelectedOrderToPayUserIdChanges,
  getCachedUrlContent,
} from "../util/common";
import { UserStore } from "../store/userStore";
import { MerchantStore } from "../store/merchantStore";
import {
  NOTIFICATIONS_CHANNEL,
  TIMEZONE,
  NOTIFICATIONS_ID,
  CHART_HOURLY_LABEL_LIST,
  ROLE_TYPE,
  ORDER_TYPE,
  ORDER_TYPE_PARSED,
  NOTIFICATIONS_TYPE,
  REPORT_SORT_FIELD_TYPE,
  TABLE_PAGE_SIZE_DROPDOWN_LIST,
  EMAIL_REPORT_TYPE,
  ROLE_TYPE_PARSED,
} from "../constant/common";
import { OutletStore } from "../store/outletStore";
import {
  CHART_DATA,
  CHART_TYPE,
  // FS_LIBRARY_PATH,
  CHART_Y_AXIS_DROPDOWN_LIST,
  CHART_FIELD_COMPARE_DROPDOWN_LIST,
  CHART_FIELD_NAME_DROPDOWN_LIST,
  CHART_FIELD_TYPE,
  CHART_FIELD_COMPARE_DICT,
  CHART_PERIOD,
} from "../constant/chart";
import {
  filterChartItems,
  getDataForChartDashboardTodaySales,
  getDataForSalesLineChart,
} from "../util/chart";

import Canvas, { Image as CanvasImage } from "react-native-canvas";
import { parseMessages } from "../util/notifications";
//   import PushNotification from 'react-native-push-notification';
import { NotificationStore } from "../store/notificationStore";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import AsyncImage from "../components/asyncImage";
import {
  // USBPrinter,
  NetPrinter,
  // BLEPrinter,
} from "react-native-thermal-receipt-printer-image-qr";
import XLSX from "xlsx";
import "react-native-get-random-values";
import { useLinkTo, useRoute } from "@react-navigation/native";

import imgLogo from "../asset/image/logo.png";
import { isMobile } from "../util/common";
import { DataStore } from "../store/dataStore";
import { prefix } from "../constant/env";

// import {launchCamera, launchImageLibrary} from 'react-native-image-picker';
// import {isTablet} from 'react-native-device-detection';
import { uploadImageToFirebaseStorage } from "../util/common";
import { v4 as uuidv4 } from "uuid";
// import RNFetchBlob from 'rn-fetch-blob';
import { useWindowDimensions } from "react-native";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
import { ReactComponent as Time } from "../assets/svg/Times.svg";
import TimeKeeper from "react-timekeeper";
import { useFilePicker } from "use-file-picker";
import { file } from "jszip";

const EmployeeScreen = (props) => {
  // useState for navigation
  const { navigation } = props;
  const linkTo = useLinkTo();
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  // useState for employee screen
  // const [keyboardHeight] = useKeyboard();
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeListLength, setEmployeeListLength] = useState(0);
  const [lists, setLists] = useState([]);
  const [list1, setList1] = useState(true);
  const [searchList, setSearchList] = useState(false);
  const [addEmployee, setAddEmployee] = useState(false);
  const [addEmployeeItem, setAddEmployeeItem] = useState(true);
  const [position, setPosition] = useState("");
  const [outletId, setOutletId] = useState(User.getOutletId());
  const [waiter, setWaiter] = useState("");
  const [search, setSearch] = useState("");
  const [countries, setCountries] = useState(["uk"]);
  const [showDetail, setShowDetail] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showEmployee, setShowEmployee] = useState([]);
  // const [switchMerchant, setSwitchMerchant] = useState(isTablet ? false : true);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("frontliner");
  const [pin, setPin] = useState("");
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");

  const [image, setImage] = useState("");
  const [imageType, setImageType] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [openFileSelector, { plainFiles, filesContent, loading: loadingImageInput, clear: clearImageContainer , errors  }] = useFilePicker({
    readAs: 'DataURL',
    accept: ["image/*"],
    multiple: false,
  });

  //////////////////////////// Test clocked in time ////////////////////////////
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [pickDate, setPickDate] = useState("");
  const [dayOfWeek, setDayOfWeek] = useState("");

  const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
  const [pickTime, setPickTime] = useState("");
  const [regularTime, setRegularTime] = useState("");
  const [overTime, setOverTime] = useState("");

  //////////////////////////////////////////////////////////////

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const allOutletsDict = MerchantStore.useState((s) => s.allOutletsDict);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const selectedOutletEmployeeEdit = CommonStore.useState(
    (s) => s.selectedOutletEmployeeEdit
  );

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState("");
  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const [exportModalVisibility, setExportModalVisibility] = useState(false);
  const [exportEmail, setExportEmail] = useState("");
  const isLoading = CommonStore.useState((s) => s.isLoading);
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);

  const [clockInModal, setClockInModal] = useState(false);
  const [clockOutModal, setClockOutModal] = useState(false);
  const [showShiftDatePicker, setShowShiftDatePicker] = useState(false);
  const [showShiftTimePicker, setShowShiftTimePicker] = useState(false);
  const [shiftDate, setShiftDate] = useState(moment());
  const [shiftTime, setShiftTime] = useState(moment());

  const [currEditEmployee, setCurrEditEmployee] = useState({});

  // new date picker
  const [rev_date, setRev_date] = useState(moment(Date.now()));
  const [rev_time, setRev_time] = useState(
    moment(Date.now()).format("hh:mm A")
  );
  const [clockInTimePicker, setClockInTimePicker] = useState(false);
  const [clockOutTimePicker, setClockOutTimePicker] = useState(false);
  const [clockTimePicker, setClockTimePicker] = useState(false);

  // useEffect for navigation
  useEffect(() => {
    if (linkTo) {
      DataStore.update((s) => {
        s.linkToFunc = linkTo;
      });
    }
  }, [linkTo]);

  // useEffect for employee screen
  useEffect(() => {
    setTargetOutletDropdownList(
      allOutlets.map((outlet) => ({
        label: outlet.name,
        value: outlet.uniqueId,
      }))
    );

    console.log("targetOutletDropdownList");
    console.log(targetOutletDropdownList);

    if (allOutlets.length > 0) {
      setSelectedTargetOutletId(currOutletId);
    }
  }, [allOutlets, currOutletId]);


  useEffect(()=>{
    if (plainFiles.length && filesContent.length && !loadingImageInput) {
      setImage(filesContent[0].content);
      setImageType(filesContent[0].name.slice(filesContent[0].name.lastIndexOf('.')));
      setIsImageChanged(true);
    }

    if(errors.length)
      console.error(errors)
    
  }, [plainFiles, filesContent, loadingImageInput, errors])
  

  //////////////////////////////////////////////////////////////

  // dummyOutletDropDownlist
  const dummyOutletDropDownlist = [
    { label: "dummy1", value: "dummy1" },
    { label: "dummy2", value: "dummy2" },
  ];
  const [outletDropdownList, setOutletDropdownList] = useState([]);
  const [selectedOutletId, setSelectedOutletId] = useState("");

  const merchantId = UserStore.useState((s) => s.merchantId);

  const allOutletsEmployees = OutletStore.useState(
    (s) => s.allOutletsEmployees
  );
  const allOutletsEmployees2 = [
    {
      avatar: "https://ui-avatars.com/api/?name=Timmy",
      createdAt: 1623141997435,
      deletedAt: null,
      dob: "",
      email: "timmy90@gmail.com",
      employeeId: "",
      favoriteMerchantIdList: ["018a0354-8012-4785-b7a5-ba4ac5313d47"],
      favoriteOutletIdList: [],
      firebaseUid: "6xlR6buT3HfxCuzWWTGFENPDqWr1",
      gender: "",
      image: "https://ui-avatars.com/api/?name=Timmy",
      lastLoginAt: 1639935456935,
      lastLogoutAt: 1639561950214,
      inkedMerchantIdList: [
        "a3ec6e53-a62e-4cf3-9d23-97567d5146f9",
        "018a0354-8012-4785-b7a5-ba4ac5313d47",
      ],
      linkedOutletIdList: [
        "aeb5fa3f-67ca-48c4-9666-aa919509e386",
        "b8edae70-30b4-4356-aaa4-f8dc0d664818",
        "b422c1d9-d30b-4de7-ad49-2e601d950919",
      ],
      merchantId: "018a0354-8012-4785-b7a5-ba4ac5313d47",
      name: "Timmy",
      number: "0156633567",
      outletId: "b422c1d9-d30b-4de7-ad49-2e601d950919",
      race: "",
      refreshToken: "64fb7128-ce74-44d3-a381-17a1e427770c",
      role: "admin",
      state: "",
      tokenFcm:
        "fztYyloyS2K7RzEY4OHtqq:APA91bGSIRtghTiTyMKKLjIiTviC6DnltopYDvOPCvoWiNmBmUQiYrmpOXe9soRq6SfAhLzEUpLOP0KMWbIB9dkX_Df0a-PnWChzOIKdVkcymLptfE2tiHsGSLm3WJhujABkctgCtX7U",
      uniqueName: "timmy90",
      updatedAt: 1639935456935,
      userGroups: ["EVERYONE"],
    },
    {
      avatar: "https://ui-avatars.com/api/?name=Kelly",
      createdAt: 1630045372276,
      deletedAt: null,
      dob: "",
      email: "kelly@gmail.com",
      employeeId: "",
      favoriteMerchantIdList: ["018a0354-8012-4785-b7a5-ba4ac5313d47"],
      favoriteOutletIdList: [],
      firebaseUid: "9LY5QFDI8KMRCVaekyf5XpSZ5SQ2",
      gender: "",
      image: "https://ui-avatars.com/api/?name=Kelly",
      lastLoginAt: 1639935456935,
      lastLogoutAt: 1639561950214,
      inkedMerchantIdList: [
        "a3ec6e53-a62e-4cf3-9d23-97567d5146f9",
        "018a0354-8012-4785-b7a5-ba4ac5313d47",
      ],
      linkedOutletIdList: [
        "aeb5fa3f-67ca-48c4-9666-aa919509e386",
        "b8edae70-30b4-4356-aaa4-f8dc0d664818",
        "b422c1d9-d30b-4de7-ad49-2e601d950919",
      ],
      merchantId: "018a0354-8012-4785-b7a5-ba4ac5313d47",
      name: "Kelly",
      number: "0175974332",
      outletId: "b422c1d9-d30b-4de7-ad49-2e601d950919",
      race: "",
      refreshToken: "64fb7128-ce74-44d3-a381-17a1e427770c",
      role: "frontliner",
      state: "",
      tokenFcm:
        "fztYyloyS2K7RzEY4OHtqq:APA91bGSIRtghTiTyMKKLjIiTviC6DnltopYDvOPCvoWiNmBmUQiYrmpOXe9soRq6SfAhLzEUpLOP0KMWbIB9dkX_Df0a-PnWChzOIKdVkcymLptfE2tiHsGSLm3WJhujABkctgCtX7U",
      uniqueName: "kelly",
      updatedAt: 1630049112833,
      userGroups: ["EVERYONE"],
    },
  ];

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  useEffect(() => {
    setOutletDropdownList(
      allOutlets.map((item) => ({ label: item.name, value: item.uniqueId }))
    );

    if (selectedOutletId === "" && allOutlets.length > 0) {
      setSelectedOutletId(allOutlets[0].uniqueId);
    }
  }, [allOutlets]);

  useEffect(() => {
    if (selectedOutletEmployeeEdit) {
      setName(selectedOutletEmployeeEdit.name);
      setUsername(selectedOutletEmployeeEdit.uniqueName);
      setRole(selectedOutletEmployeeEdit.role);
      // setSelectedOutletId(selectedOutletEmployeeEdit.selectedOutletId);
      setNumber(selectedOutletEmployeeEdit.number);
      setEmail(selectedOutletEmployeeEdit.email);
      // setPassword(selectedOutletEmployeeEdit.password);
      setPassword("password");
      setImage(selectedOutletEmployeeEdit.image);
      setIsImageChanged(false);
    } else {
      setName("");
      setUsername("");
      setRole("frontliner");
      //setSelectedOutletId('');
      setNumber("");
      setEmail("");
      setPassword("");
      setImage("");
      clearImageContainer();
      setIsImageChanged(false);
    }
  }, [selectedOutletEmployeeEdit, addEmployee, addEmployeeItem]);

  const setState = () => { };

  navigation.setOptions({
    headerLeft: () => (
      <View
        style={{
          width: Dimensions.get("screen").width * 0.17,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            bottom: 0,
          },
        ]}
      >
        <Text
          style={{
            fontSize: 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Employee
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: Dimensions.get("screen").height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get("screen").height * 0.05,
              height: Dimensions.get("screen").height * 0.05,
              borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: Dimensions.get("screen").height * 0.035,
                height: Dimensions.get("screen").height * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // functions used for employee screen
  const renderItem = ({ item }) => (
    <TouchableOpacity
      onPress={() => {
        CommonStore.update((s) => {
          s.selectedOutletEmployeeEdit = item;
        });
        setAddEmployee(true);
        setAddEmployeeItem(false);
        console.log("PRESSWS");
      }}
    >
      <View
        style={{
          backgroundColor: "#ffffff",
          flexDirection: "row",
          paddingVertical: 20,
          paddingHorizontal: 10,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: "#c4c4c4",
          //justifyContent: 'space-between'
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
        }}
      >
        <AsyncImage
          source={{ uri: item.avatar }}
          style={{ width: 35, height: 35, borderRadius: 100, marginRight: 15 }}
        />
        <Text
          style={{
            width: "12%",
            marginRight: 5,
            fontFamily: "NunitoSans-Regular",
            fontSize: 14,
          }}
        >
          {item.name}
        </Text>
        <Text
          style={{
            width: "15%",
            marginRight: 5,
            fontFamily: "NunitoSans-Regular",
            fontSize: 14,
          }}
        >
          {allOutletsDict[outletId].name}
        </Text>
        <Text
          style={{
            width: "14%",
            marginRight: 5,
            fontFamily: "NunitoSans-Regular",
            fontSize: 14,
          }}
        >
          {ROLE_TYPE_PARSED[item.role]}
        </Text>
        <Text
          style={{
            width: "10%",
            marginRight: 5,
            fontFamily: "NunitoSans-Regular",
            fontSize: 14,
          }}
        >
          {"Active"}
        </Text>
        <View style={{ width: "10%" }}>
          {!item.lastLoginAt ? (
            <Text
              style={{
                fontVariant: ["tabular-nums"],
                fontFamily: "NunitoSans-Regular",
                fontSize: 14,
              }}
            >
              {"N/A"}
            </Text>
          ) : (
            <>
              <Text
                style={{
                  fontVariant: ["tabular-nums"],
                  fontFamily: "NunitoSans-Regular",
                  fontSize: 14,
                }}
              >
                {moment(item.lastLoginAt).format("DD MMM YYYY")}
              </Text>
              <Text
                style={{
                  fontVariant: ["tabular-nums"],
                  fontFamily: "NunitoSans-Regular",
                  fontSize: 14,
                }}
              >
                {moment(item.lastLoginAt).format("hh:mm A")}
              </Text>
            </>
          )}
        </View>

        <View style={{ width: "10%" }}>
          {!item.lastLogoutAt ? (
            <Text
              style={{
                fontVariant: ["tabular-nums"],
                fontFamily: "NunitoSans-Regular",
                fontSize: 14,
              }}
            >
              {"N/A"}
            </Text>
          ) : (
            <>
              <Text
                style={{
                  fontVariant: ["tabular-nums"],
                  fontFamily: "NunitoSans-Regular",
                  fontSize: 14,
                }}
              >
                {moment(item.lastLogoutAt).format("DD MMM YYYY")}
              </Text>
              <Text
                style={{
                  fontVariant: ["tabular-nums"],
                  fontFamily: "NunitoSans-Regular",
                  fontSize: 14,
                }}
              >
                {moment(item.lastLogoutAt).format("hh:mm A")}
              </Text>
            </>
          )}
        </View>
        <View style={{ width: "12%" }}>
          <TouchableOpacity
            style={{
              justifyContent: "center",
              flexDirection: "row",
              borderWidth: 1,
              borderColor: Colors.primaryColor,
              backgroundColor: "#4E9F7D",
              borderRadius: 5,
              //width: 200,
              paddingHorizontal: 10,
              height: 40,
              alignItems: "center",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 1,
              zIndex: -1,
              marginRight: 15,
              paddingVertical: "5%",
            }}
            onPress={() => {
              setCurrEditEmployee(item);

              setClockInModal(true);
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  color: "white",
                  fontSize: 14,
                  fontFamily: "NunitoSans-Bold",
                  textAlign: "center",
                  //paddingVertical: '5%'
                }}
              >
                CLOCK IN
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View style={{ width: "12%" }}>
          <TouchableOpacity
            style={{
              justifyContent: "center",
              flexDirection: "row",
              borderWidth: 1,
              borderColor: Colors.primaryColor,
              backgroundColor: "#4E9F7D",
              borderRadius: 5,
              //width: 200,
              paddingHorizontal: 10,
              height: 40,
              alignItems: "center",
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 1,
              zIndex: -1,
              marginRight: 15,
              paddingVertical: "5%",
            }}
            onPress={() => {
              setCurrEditEmployee(item);

              setClockOutModal(true);
            }}
          >
            <View style={{ flexDirection: "row" }}>
              <Text
                style={{
                  color: "white",
                  fontSize: 14,
                  fontFamily: "NunitoSans-Bold",
                  textAlign: "center",
                  //paddingVertical: '5%'
                }}
              >
                CLOCK OUT
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </TouchableOpacity>
  );

  // function here

  const getEmployeeList = () => {
    ApiClient.GET(API.getEmployee + User.getOutletId()).then((results) => {
      //console.log('employee', results)
      // need to double check
      // setState({ employeeList: results, employeeListLength: results.length });
      setEmployeeList(results);
      setEmployeeListLength(results.length);
    });
  };

  const addEmployeeFunc = async () => {
    if (
      !name ||
      !email ||
      !outletId ||
      !role ||
      !password ||
      !number
      // !dayOfWeek ||
      // !regularTime ||
      // !overTime
    ) {
      if (window.confirm("Please fill in all information") == true) {
        console.log("You pressed OK!: ");
      } else {
        console.log("You canceled!");
      }
      return;
    } else {
      if (
        selectedOutletEmployeeEdit &&
        selectedOutletEmployeeEdit.firebaseUid
      ) {
        var employeeImagePath = "";
        var uniqueIdLocal = selectedOutletEmployeeEdit.employeeId;

        if (image && imageType && isImageChanged) {
          uniqueIdLocal = uuidv4();

          employeeImagePath = await uploadImageToFirebaseStorage(
            {
              uri: image,
              type: imageType,
            },
            `/merchant/${merchantId}/employee/${uniqueIdLocal}/image${imageType}`
          );
        }

        if (!isImageChanged) {
          employeeImagePath = selectedOutletEmployeeEdit.avatar;
        }

        var body = {
          name: name,
          email: email,
          outletId: outletId,
          role: role,
          // password: password,
          number: number,
          merchantId: merchantId,
          uniqueName: username,
          image: employeeImagePath,
          // dayOfWeek: moment(dayOfWeek).valueOf(),
          // regularTime: moment(regularTime).valueOf(),
          // overTime: moment(overTime).valueOf(),

          uniqueIdLocal: uniqueIdLocal,

          outletEmployeeId: selectedOutletEmployeeEdit.firebaseUid,
        };
        console.log("BODY", body);

        ApiClient.POST(API.updateOutletEmployee, body).then((result) => {
          console.log("RESULT", result);

          // if (result.email.toLowerCase() == email.toLowerCase()) {
          if (result && result.status === "success") {
            // emailFunc(email);
            if (window.confirm("Success. Employee has been updated.") == true) {
              setEmail("");
              setPassword("");
              setName("");
              setNumber("");
              setUsername("");
              // setRole('');
              setImage("");
              clearImageContainer();
              setImageType("");
              setDayOfWeek("");
              setRegularTime("");
              setOverTime("");

              setAddEmployee(false);
              setAddEmployeeItem(true);
            } else {
              console.log("You canceled!");
            }
          } else {
            if (window.confirm("Error. Failed to update employee.") == true) {
              setEmail("");
              setPassword("");
              setName("");
              setNumber("");
              setUsername("");
              // setRole('');
              setImage("");
              clearImageContainer();
              setImageType("");
              setDayOfWeek("");
              setRegularTime("");
              setOverTime("");

              setAddEmployee(false);
              setAddEmployeeItem(true);
            } else {
              console.log("You canceled!");
            }
          }
        });
      } else {
        var employeeImagePath = "";
        var uniqueIdLocal = "";

        if (image && imageType && isImageChanged) {
          uniqueIdLocal = uuidv4();

          employeeImagePath = await uploadImageToFirebaseStorage(
            {
              uri: image,
              type: imageType,
            },
            `/merchant/${merchantId}/employee/${uniqueIdLocal}/image${imageType}`
          );
        }

        var body = {
          //name: name,
          name: "",
          email: email,
          outletId: outletId,
          role: role,
          password: password,
          number: number,
          merchantId: merchantId,
          uniqueName: username,
          image: employeeImagePath,
          // dayOfWeek: moment(dayOfWeek).valueOf(),
          // regularTime: moment(regularTime).valueOf(),
          // overTime: moment(overTime).valueOf(),

          uniqueIdLocal: uniqueIdLocal,
        };
        console.log("BODY", body);

        ApiClient.POST(API.createOutletEmployee, body).then((result) => {
          console.log("RESULT", result);

          // if (result.email.toLowerCase() == email.toLowerCase()) {
          if (result && result.status === "success") {
            // emailFunc(email);
            if (window.confirm("Success. Employee has been added.") == true) {
              setEmail("");
              setPassword("");
              setUsername("");
              setName("");
              setNumber("");
              // setRole('');
              setImage("");
              clearImageContainer();
              setImageType("");
              setDayOfWeek("");
              setRegularTime("");
              setOverTime("");

              setAddEmployee(false);
              setAddEmployeeItem(true);
            } else {
              console.log("You canceled!");
            }
          } else {
            if (window.confirm("Error. Failed to add employee.") == true) {
              setEmail("");
              setPassword("");
              setUsername("");
              setName("");
              setNumber("");
              // setRole('');
              setImage("");
              clearImageContainer();
              setImageType("");
              setDayOfWeek("");
              setRegularTime("");
              setOverTime("");

              setAddEmployee(false);
              setAddEmployeeItem(true);
            } else {
              console.log("You canceled!");
            }
          }
        });
      }
    }
  };

  const clockInEmployeeDateTime = async () => {
    // console.log('rev_date: ', rev_date);
    // console.log('rev_time: ', moment(`${moment(rev_date).format('MM/DD/YYYY')} ${rev_time}`));

    var body = {
      clockInDate: moment(rev_date).valueOf(),
      
      // need to double check
      clockInTime: moment(`${moment(rev_date).format('MM/DD/YYYY')} ${rev_time}`).valueOf(),
      
      employeeUserId: currEditEmployee.firebaseUid,
    };

    ApiClient.POST(API.clockInEmployeeDateTime, body).then((result) => {
      setClockInModal(false);
    });
  };

  const clockOutEmployeeDateTime = async () => {
    var body = {
      clockOutDate: moment(rev_date).valueOf(),
      // need to double check
      clockOutTime: moment(`${moment(rev_date).format('MM/DD/YYYY')} ${rev_time}`).valueOf(),
      employeeUserId: currEditEmployee.firebaseUid,
    };

    ApiClient.POST(API.clockOutEmployeeDateTime, body).then((result) => {
      setClockOutModal(false);
    });
  };

  const emailFunc = (email) => {
    var body = {
      data: `Your employee account has been created.\n The password is ${password}`,
      email: email,
    };
    ApiClient.POST(API.emailEmployee, body, false).then((result) => {
      if (result == true) {
        if (window.confirm("Success! The email has been sent.") == true) {
          console.log("You have pressed ok!");
        } else {
          console.log("You have cancelled!");
        }
      }
    });
  };

  const removeEmployee = (employeeId) => {
    var body = {
      role: "user",
    };
    ApiClient.POST(API.updateRole + employeeId + "/role", body).then(
      (result) => {
        if (window.confirm("Success! The email has been sent.") == true) {
          console.log("You have pressed ok!");
        } else {
          console.log("You have cancelled!");
        }

        getEmployeeList();
        // need to double check
        // setState({ showDetail: false, addEmployeeItem: true });
        setShowDetail(false);
        setAddEmployeeItem(true);
      }
    );
  };

  const searchBarItem = () => {
    ApiClient.GET(
      API.searchBarEmployee + search + "&outletId=" + outletId
    ).then((result) => {
      // need to double check
      // setState({ lists: result });
      setLists(result);
    });
  };

  const renderSearchItem = ({ elements }) => {
    return (
      <View
        style={{
          backgroundColor: "#ffffff",
          flexDirection: "row",
          paddingVertical: 20,
          paddingHorizontal: 20,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: "#c4c4c4",
        }}
      >
        <TouchableOpacity>
          <AsyncImage
            source={{ uri: elements.avatar }}
            item={elements}
            style={{ width: 20, height: 20, borderRadius: 10 }}
          />
          <Text
            style={{
              width: "2%",
              fontFamily: "NunitoSans-Regular",
              fontSize: 14,
            }}
          ></Text>
          <Text
            style={{
              width: "14%",
              fontFamily: "NunitoSans-Regular",
              fontSize: 14,
            }}
          >
            {elements.name}
          </Text>
          <Text
            style={{
              width: "15%",
              fontFamily: "NunitoSans-Regular",
              fontSize: 14,
            }}
          >
            {elements.role}
          </Text>
          <Text
            style={{
              width: "13%",
              fontFamily: "NunitoSans-Regular",
              fontSize: 14,
            }}
          >
            {elements.role}
          </Text>
          {/* <Text style={{ width: '15%' }}>{elements.number}</Text>
          <Text style={{ width: '16%', marginRight: 20 }}>{elements.email}</Text> */}
          <Text
            style={{
              width: "10%",
              fontFamily: "NunitoSans-Regular",
              fontSize: 14,
            }}
          >
            ******
          </Text>
          <Text
            style={{
              width: "7%",
              fontFamily: "NunitoSans-Regular",
              fontSize: 14,
            }}
          >
            ****
          </Text>
          <Text
            style={{
              width: "9%",
              fontFamily: "NunitoSans-Regular",
              fontSize: 14,
            }}
          >
            {elements.dayOfWeek}
          </Text>
          <Text
            style={{
              width: "9%",
              fontFamily: "NunitoSans-Regular",
              fontSize: 14,
            }}
          >
            {elements.regularTime}
          </Text>
          <Text
            style={{
              width: "9%",
              fontFamily: "NunitoSans-Regular",
              fontSize: 14,
            }}
          >
            {elements.overTime}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const exportFunc = () => {
    // var body = {
    //   data: orderList
    // }

    if (allOutletsEmployees) {
      const csvData = convertArrayToCSV(allOutletsEmployees);
      // need to double check (this function not being used)

      // const pathToWrite = `${
      //   RNFetchBlob.fs.dirs.DownloadDir
      // }/koodoo-report-employee-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;

      // console.log('PATH', pathToWrite);

      // RNFetchBlob.fs
      //   .writeFile(pathToWrite, csvData, 'utf8')
      //   .then(() => {
      //     console.log(`wrote file ${pathToWrite}`);
      //     // wrote file /storage/emulated/0/Download/data.csv
      //     if (window.confirm(`Success. Sent to ${pathToWrite}.`) == true) {
      //       console.log("You pressed ok!");
      //     } else {
      //       console.log("You canceled!");
      //     }
      //   })
      //   .catch((error) => console.error(error));
    }
  };

  const handleChoosePhoto = () => {
    openFileSelector();
  };

  const convertDataToExcelFormat = () => {
    var excelData = [];

    for (var i = 0; i < allOutletsEmployees.length; i++) {
      var excelRow = {
        Name: allOutletsEmployees[i].name ? allOutletsEmployees[i].name : "N/A",
        // 'Outlet': outletSupplyItems[i].skuMerchant ? outletSupplyItems[i].skuMerchant : 'N/A',
        // 'Staff': parseFloat(outletSupplyItems[i].quantity).toFixed(2),
        // 'Status': outletSupplyItems[i].unit ? outletSupplyItems[i].unit : 'N/A',
        // 'Clock In': parseFloat(outletSupplyItems[i].stockIdealQuantity).toFixed(2),
      };

      excelData.push(excelRow);
    }

    console.log("excelData");
    console.log(excelData);

    return excelData;
  };

  // function end

  // Main return
  return (
    // Whole page view
    <View
      style={[styles.container, { height: windowHeight, width: windowWidth }]}
    >
      {/* Sidebar view */}
      <View
        style={{
          // borderWidth: 1,
          // width: Dimensions.get("screen").width * 0.9,
          // height: Dimensions.get("screen").height * 0.9,
          flex: 0.8,
        }}
      >
        <SideBar navigation={navigation} selectedTab={0} />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        {/* Full page */}
        <ScrollView
          style={{
            backgroundColor: Colors.highlightColor,
            // borderWidth: 1,
            width: "100%",
            height: "100%",
          }}
          showsVerticalScrollIndicator={true}
          contentContainerStyle={{}}
        >
          {/* Date picker modal */}
          <DateTimePickerModal
            date={new Date()}
            minimumDate={new Date()}
            isVisible={showDateTimePicker}
            mode={"date"}
            //display={"default"} //for iOS to use minuteInterval
            onConfirm={(text) => {
              var date_ob = new Date(text);
              let date = ("0" + date_ob.getDate()).slice(-2);
              let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
              let year = date_ob.getFullYear();
              if (pickDate == "dayOfWeek") {
                // setState({ startDate: year + "-" + month + "-" + date })
                setDayOfWeek(year + "-" + month + "-" + date);
              }
              // setState({ showDateTimePicker: false })
              setShowDateTimePicker(false);
            }}
            onCancel={() => {
              // setState({ showDateTimePicker: false })
              setShowDateTimePicker(false);
            }}
          />

          {/* Time picker modal */}
          <DateTimePickerModal
            isVisible={showDateTimePicker1}
            mode={"time"}
            onConfirm={(text) => {
              if (pickTime == "regularTime") {
                setRegularTime(text);
              } else {
                setOverTime(text);
              }
              setShowDateTimePicker1(false);
            }}
            onCancel={() => {
              setShowDateTimePicker1(false);
            }}
          />
          {/* Content */}
          <View
            style={{
              padding: 20,
              width: "100%",
              height: "100%",
              backgroundColor: Colors.highlightColor,
              marginHorizontal: 0,
              // borderWidth: 1,
            }}
          >
            {/* Modal start */}

            {/* Remove employee modal */}
            <Modal
              style={{ flex: 1 }}
              visible={visible}
              transparent={true}
              animationType="slide"
            >
              <View
                style={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: Dimensions.get("window").height,
                }}
              >
                <View style={styles.confirmBoxRemove}>
                  <Text
                    style={{
                      fontWeight: "700",
                      fontSize: 24,
                      justifyContent: "center",
                      alignSelf: "center",
                      marginTop: 40,
                    }}
                  >
                    Remove employee
                  </Text>
                  <View
                    style={{
                      justifyContent: "center",
                      alignSelf: "center",
                      alignContent: "center",
                      marginTop: 20,
                      flexDirection: "row",
                      width: "80%",
                    }}
                  >
                    <View
                      style={{ justifyContent: "center", marginHorizontal: 5 }}
                    >
                      <Text
                        style={{
                          color: Colors.descriptionColor,
                          fontSize: 20,
                          marginBottom: 5,
                        }}
                      >
                        Are you sure you want to remove this employee?
                      </Text>
                      <Text
                        style={{ color: Colors.descriptionColor, fontSize: 16 }}
                      >
                        Name: {showEmployee.name}
                      </Text>
                      <Text
                        style={{ color: Colors.descriptionColor, fontSize: 16 }}
                      >
                        Position: {showEmployee.role}
                      </Text>
                    </View>
                  </View>

                  <View
                    style={{
                      alignSelf: "center",
                      marginTop: 20,
                      justifyContent: "center",
                      alignItems: "center",
                      width: 260,
                      height: 40,
                      alignContent: "center",
                      flexDirection: "row",
                      marginTop: 40,
                    }}
                  >
                    <TouchableOpacity
                      onPress={() => {
                        removeEmployee(showEmployee.id);
                        setState({ visible: false });
                      }}
                      style={{
                        backgroundColor: Colors.fieldtBgColor,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        height: 80,
                        borderBottomLeftRadius: 10,
                        borderRightWidth: StyleSheet.hairlineWidth,
                        borderTopWidth: StyleSheet.hairlineWidth,
                      }}
                    >
                      <Text
                        style={{ fontSize: 22, color: Colors.primaryColor }}
                      >
                        Confirm
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => {
                        setState({ visible: false });
                      }}
                      style={{
                        backgroundColor: Colors.fieldtBgColor,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "center",
                        height: 80,
                        borderBottomRightRadius: 10,
                        borderTopWidth: StyleSheet.hairlineWidth,
                      }}
                    >
                      <Text
                        style={{ fontSize: 22, color: Colors.descriptionColor }}
                      >
                        Cancel
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>

            {/* Download modal */}
            <Modal
              style={
                {
                  // flex: 1
                }
              }
              visible={exportModalVisibility}
              transparent={true}
              animationType={"slide"}
              supportedOrientations={["portrait", "landscape"]}
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: Colors.modalBgColor,
                  alignItems: "center",
                  justifyContent: "center",
                  // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
                }}
              >
                <View
                  style={{
                    height: Dimensions.get("screen").width * 0.3,
                    width: Dimensions.get("screen").width * 0.4,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 12,
                    padding: Dimensions.get("screen").width * 0.03,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      position: "absolute",
                      right: Dimensions.get("screen").width * 0.02,
                      top: Dimensions.get("screen").width * 0.02,

                      elevation: 1000,
                      zIndex: 1000,
                    }}
                    onPress={() => {
                      setExportModalVisibility(false);
                    }}
                  >
                    <AntDesign
                      name="closecircle"
                      size={25}
                      color={Colors.fieldtTxtColor}
                    />
                  </TouchableOpacity>
                  <View
                    style={{
                      alignItems: "center",
                      top: "20%",
                      position: "absolute",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        textAlign: "center",
                        fontSize: 24,
                      }}
                    >
                      Download Report
                    </Text>
                  </View>
                  <View style={{ top: "10%" }}>
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      Email Address:
                    </Text>
                    <TextInput
                      style={{
                        backgroundColor: Colors.fieldtBgColor,
                        width: 370,
                        height: 50,
                        borderRadius: 5,
                        padding: 5,
                        marginVertical: 5,
                        borderWidth: 1,
                        borderColor: "#E5E5E5",
                        paddingLeft: 10,
                      }}
                      placeholderStyle={{ padding: 5 }}
                      placeholder="Enter Your Email"
                      placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                      onChangeText={(text) => {
                        setExportEmail(text);
                      }}
                      value={exportEmail}
                    />
                    <Text
                      style={{
                        fontSize: 20,
                        fontFamily: "NunitoSans-Bold",
                        marginTop: 15,
                      }}
                    >
                      Send As:
                    </Text>

                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        //top: '10%',
                        flexDirection: "row",
                        marginTop: 15,
                        // borderWidth:1,
                      }}
                    >
                      {/* <View>
                        <CSVLink
                          style={{
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            display: "inline-block",
                            flexDirection: "row",
                            textDecoration: "none",
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: "#4E9F7D",
                            borderRadius: 5,
                            width: 100,
                            paddingHorizontal: 10,
                            height: 40,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                          }}
                          data={convertDataToExcelFormat()}
                          filename="report.xslx"
                        >
                          <View
                            style={{
                              width: "100%",
                              height: "100%",
                              alignContent: "center",
                              alignItems: "center",
                              alignSelf: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                //marginLeft: 5,
                                fontSize: 16,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              Excel
                            </Text>
                          </View>
                        </CSVLink>
                      </View> */}

                      <View style={{ marginHorizontal: 10 }}></View>

                      <View>
                        <CSVLink
                          style={{
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                            display: "inline-block",
                            flexDirection: "row",
                            textDecoration: "none",
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: "#4E9F7D",
                            borderRadius: 5,
                            width: 100,
                            paddingHorizontal: 10,
                            height: 40,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                          }}
                          data={convertArrayToCSV(allOutletsEmployees)}
                          filename="report.csv"
                        >
                          <View
                            style={{
                              width: "100%",
                              height: "100%",
                              alignContent: "center",
                              alignItems: "center",
                              alignSelf: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                //marginLeft: 5,
                                fontSize: 16,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              CSV
                            </Text>
                          </View>
                        </CSVLink>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </Modal>

            {/* Clock in modal */}
            <Modal
              style={
                {
                  // flex: 1
                }
              }
              visible={clockInModal}
              transparent={true}
              animationType={"slide"}
              supportedOrientations={["portrait", "landscape"]}
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: Colors.modalBgColor,
                  alignItems: "center",
                  justifyContent: "center",
                  // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
                }}
              >
                <View
                  style={{
                    height: Dimensions.get("screen").width * 0.2,
                    width: Dimensions.get("screen").width * 0.4,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 12,
                    padding: Dimensions.get("screen").width * 0.05,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      position: "absolute",
                      right: Dimensions.get("screen").width * 0.02,
                      top: Dimensions.get("screen").width * 0.02,

                      elevation: 1000,
                      zIndex: 1000,
                    }}
                    onPress={() => {
                      setClockInModal(false);
                      setClockTimePicker(false);
                    }}
                  >
                    <AntDesign
                      name="closecircle"
                      size={25}
                      color={Colors.fieldtTxtColor}
                    />
                  </TouchableOpacity>
                  <View
                    style={{
                      alignItems: "center",
                      top: "20%",
                      position: "absolute",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        textAlign: "center",
                        fontSize: 24,
                      }}
                    >
                      Clock In
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexDirection: "row",
                      marginTop: 15,
                      justifyContent: "center",
                      // borderWidth:1,
                      zIndex: 100000,
                    }}
                  >
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontFamily: "NunitoSans-Regular",
                          fontWeight: "500",
                        }}
                      >
                        Date
                      </Text>
                      <View
                        style={{
                          borderWidth: 1,
                          width: 120,
                          borderRadius: 5,
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <GCalendar
                          width={20}
                          height={20}
                          //style={{marginRight: 5}}
                        />
                        <DatePicker
                          selected={rev_date.toDate()}
                          onChange={(date) => {
                            setRev_date(moment(date));
                          }}
                        />
                      </View>
                    </View>

                    <View style={{ flexDirection: "column", marginLeft: 15 }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontFamily: "NunitoSans-Regular",
                          fontWeight: "500",
                        }}
                      >
                        Time
                      </Text>
                      <TouchableOpacity
                        style={{
                          borderWidth: 1,
                          width: 120,
                          borderRadius: 5,
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                        onPress={() => {
                          setClockTimePicker(!clockTimePicker);
                        }}
                      >
                        <Time />
                        <View>
                          <Text>{rev_time}</Text>
                        </View>
                      </TouchableOpacity>
                      {clockTimePicker ? (
                        <View
                          style={{
                            // borderWidth:10
                            zIndex: 100,
                          }}
                        >
                          <View
                            style={{
                              position: "absolute",
                              // borderWidth: 1,
                            }}
                          >
                            <TimeKeeper
                              time={rev_time}
                              onChange={(time) => setRev_time(time.formatted12)}
                              onDoneClick={() => {
                                setClockTimePicker(false);
                              }}
                            ></TimeKeeper>
                          </View>
                        </View>
                      ) : null}
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      flexDirection: "row",
                      marginTop: "5%",
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        //width: 200,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginRight: 15,
                      }}
                      onPress={() => {
                        clockInEmployeeDateTime();
                        setClockTimePicker(false);
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 16,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        UPDATE
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>

            {/* Clock out */}
            <Modal
              style={
                {
                  // flex: 1
                }
              }
              visible={clockOutModal}
              transparent={true}
              animationType={"slide"}
              supportedOrientations={["portrait", "landscape"]}
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: Colors.modalBgColor,
                  alignItems: "center",
                  justifyContent: "center",
                  // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
                }}
              >
                <View
                  style={{
                    height: Dimensions.get("screen").width * 0.2,
                    width: Dimensions.get("screen").width * 0.4,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 12,
                    padding: Dimensions.get("screen").width * 0.05,
                    alignItems: "center",
                    justifyContent: "center",
                    // borderWidth:1
                  }}
                >
                  <TouchableOpacity
                    style={{
                      position: "absolute",
                      right: Dimensions.get("screen").width * 0.02,
                      top: Dimensions.get("screen").width * 0.02,

                      elevation: 1000,
                      zIndex: 1000,
                    }}
                    onPress={() => {
                      setClockOutModal(false);
                      setClockTimePicker(false);
                    }}
                  >
                    <AntDesign
                      name="closecircle"
                      size={25}
                      color={Colors.fieldtTxtColor}
                    />
                  </TouchableOpacity>
                  <View
                    style={{
                      alignItems: "center",
                      top: "20%",
                      position: "absolute",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        textAlign: "center",
                        fontSize: 24,
                      }}
                    >
                      Clock Out
                    </Text>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 15,
                      justifyContent: "center",
                      // borderWidth:1,
                      zIndex: 100000,
                    }}
                  >
                    <View style={{ flexDirection: "column" }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontFamily: "NunitoSans-Regular",
                          fontWeight: "500",
                        }}
                      >
                        Date
                      </Text>
                      <View
                        style={{
                          borderWidth: 1,
                          width: 120,
                          borderRadius: 5,
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <GCalendar
                          width={20}
                          height={20}
                          //style={{marginRight: 5}}
                        />

                        <DatePicker
                          selected={rev_date.toDate()}
                          onChange={(date) => {
                            setRev_date(moment(date));
                          }}
                        />
                      </View>
                    </View>

                    <View style={{ flexDirection: "column", marginLeft: 22 }}>
                      <Text
                        style={{
                          fontSize: 14,
                          fontFamily: "NunitoSans-Regular",
                          fontWeight: "500",
                        }}
                      >
                        Time
                      </Text>
                      <TouchableOpacity
                        style={{
                          borderWidth: 1,
                          width: 120,
                          borderRadius: 5,
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                        onPress={() => {
                          setClockTimePicker(!clockTimePicker);
                        }}
                      >
                        <Time />
                        <View>
                          <Text>{rev_time}</Text>
                        </View>
                      </TouchableOpacity>
                      {clockTimePicker ? (
                        <View
                          style={{
                            // borderWidth:10
                            zIndex: 100,
                          }}
                        >
                          <View
                            style={{
                              position: "absolute",
                              // borderWidth: 1,
                            }}
                          >
                            <TimeKeeper
                              time={rev_time}
                              onChange={(time) => {
                                setRev_time(time.formatted12);
                                // console.log('time.formatted12: ', time.formatted12)
                              }}
                              onDoneClick={() => {
                                setClockTimePicker(false);
                              }}
                            ></TimeKeeper>
                          </View>
                        </View>
                      ) : null}
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      flexDirection: "row",
                      marginTop: 15,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        //width: 200,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginRight: 15,
                      }}
                      onPress={() => {
                        clockOutEmployeeDateTime();
                        setClockTimePicker(false);
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 16,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        UPDATE
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>

            {/* Modal end */}

            {/* button container */}
            {addEmployeeItem ? (
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 0,
                  alignSelf: "center",
                  width: windowWidth * 0.8575,
                  marginHorizontal: 0,
                  // borderWidth:1,
                  //paddingHorizontal: switchMerchant ? 30 : 0,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    marginLeft: 10,
                    // borderWidth:1,
                  }}
                >
                  {/* Employee header */}
                  <View
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      //paddingRight: 5,
                      // borderWidth: 1,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 26,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      {allOutletsEmployees.length}
                    </Text>
                    <Text
                      style={{
                        fontSize: 26,
                        fontFamily: "NunitoSans-Bold",
                        marginLeft: 10,
                      }}
                    >
                      {allOutletsEmployees.length > 1
                        ? "Employees"
                        : "Employee"}
                    </Text>
                  </View>
                  {/* download button, add employee button and search text view */}
                  <View
                    style={{
                      flexDirection: "row",
                      // borderWidth: 1,
                    }}
                  >
                    {/* download button */}
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        //width: 200,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginRight: 15,
                      }}
                      onPress={() => {
                        setExportModalVisibility(true);
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ marginTop: 0 }}>
                          <Feather
                            name="download"
                            size={20}
                            color={Colors.whiteColor}
                          />
                        </View>
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            marginLeft: 5,
                            fontSize: 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          DOWNLOAD
                        </Text>
                      </View>
                    </TouchableOpacity>
                    {/* add employee button */}
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        //width: 200,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginRight: 15,
                      }}
                      onPress={() => {
                        CommonStore.update((s) => {
                          s.selectedOutletEmployeeEdit = null;
                        });
                        setAddEmployee(true);
                        setAddEmployeeItem(false);
                      }}
                    >
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <AntDesign
                          name="pluscircle"
                          size={20}
                          color={Colors.whiteColor}
                        />
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            marginLeft: 5,
                            fontSize: 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          EMPLOYEE
                        </Text>
                      </View>
                    </TouchableOpacity>
                    {/* Search text view */}
                    <View
                      style={{
                        width: 250,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: 5,
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 3,
                        borderWidth: 1,
                        borderColor: "#E5E5E5",
                      }}
                    >
                      <Feather
                        name="search"
                        size={18}
                        color={Colors.primaryColor}
                        style={{ marginLeft: 15 }}
                      />
                      <TextInput
                        editable={!loading}
                        underlineColorAndroid={Colors.whiteColor}
                        style={{
                          width: 220,
                          fontSize: 16,
                          fontFamily: "NunitoSans-Regular",
                          paddingLeft: 5,
                          height: 45,
                        }}
                        clearButtonMode="while-editing"
                        placeholder=" Search"
                        onChangeText={(text) => {
                          // setSearch(text.trim());
                          // setList1(false);
                          // setSearchList(true);
                          setSearch(text.trim());
                        }}
                        placeholderTextColor={Platform.select({
                          ios: "#a9a9a9",
                        })}
                        value={search}
                      />
                    </View>
                  </View>
                </View>
              </View>
            ) : (
              <></>
            )}

            {/* Flat list container */}
            {addEmployeeItem ? (
              // White container
              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 10,
                  width: windowWidth * 0.8575,
                  height: Dimensions.get('screen').height * 0.8,
                  marginTop: 30,
                  marginHorizontal: 40,
                  marginBottom: 30,
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}
              >
                {/* Column header */}
                <View
                  style={[
                    styles.titleList,
                    {
                      paddingHorizontal: 10,
                      marginTop: 0,
                      borderRadius: 5,
                      // borderWidth: 1,
                      // flexDirection: "row",
                    },
                  ]}
                >
                  {/* For profile pic */}
                  <Text
                    style={{
                      width: 35,
                      marginRight: 15,
                      alignSelf: "center",
                    }}
                  ></Text>
                  {/* Name */}
                  <Text
                    style={{
                      width: "12%",
                      alignSelf: "center",
                      marginRight: 5,
                      fontFamily: "NunitoSans-Regular",
                      fontSize: 14,
                    }}
                  >
                    Name
                  </Text>
                  {/* Outlet */}
                  <Text
                    style={{
                      width: "15%",
                      alignSelf: "center",
                      marginRight: 5,
                      fontFamily: "NunitoSans-Regular",
                      fontSize: 14,
                    }}
                  >
                    Outlet
                  </Text>
                  {/* Staff */}
                  <Text
                    style={{
                      width: "14%",
                      alignSelf: "center",
                      marginRight: 5,
                      fontFamily: "NunitoSans-Regular",
                      fontSize: 14,
                    }}
                  >
                    Staff
                  </Text>
                  {/* Status */}
                  <Text
                    style={{
                      width: "10%",
                      alignSelf: "center",
                      marginRight: 5,
                      fontFamily: "NunitoSans-Regular",
                      fontSize: 14,
                    }}
                  >
                    Status
                  </Text>
                  {/* Clock in */}
                  <Text
                    style={{
                      width: "11%",
                      alignSelf: "center",
                      fontFamily: "NunitoSans-Regular",
                      fontSize: 14,
                    }}
                  >
                    Clock In
                  </Text>
                  {/* Clock out */}
                  <Text
                    style={{
                      width: "11%",
                      alignSelf: "center",
                      fontFamily: "NunitoSans-Regular",
                      fontSize: 14,
                    }}
                  >
                    Clock Out
                  </Text>
                </View>

                {/* Flat list for employee */}
                <View style={{ flex: 1 }}>
                  {list1 ? (
                    <FlatList
                      nestedScrollEnabled={true}
                      showsVerticalScrollIndicator={false}
                      data={allOutletsEmployees.filter((item) => {
                        if (search !== "") {
                          const searchLowerCase = search.toLowerCase();

                          return (
                            item.email
                              .toLowerCase()
                              .includes(searchLowerCase) ||
                            item.name.toLowerCase().includes(searchLowerCase)
                          );
                        } else {
                          return true;
                        }
                      })}
                      extraData={allOutletsEmployees.filter((item) => {
                        if (search !== "") {
                          const searchLowerCase = search.toLowerCase();

                          return (
                            item.email
                              .toLowerCase()
                              .includes(searchLowerCase) ||
                            item.name.toLowerCase().includes(searchLowerCase)
                          );
                        } else {
                          return true;
                        }
                      })}
                      // data={allOutletsEmployees2}
                      renderItem={renderItem}
                      keyExtractor={(item, index) => String(index)}
                    />
                  ) : // </ScrollView>
                    null}
                  {searchList ? (
                    <ScrollView>
                      <FlatList
                        nestedScrollEnabled={true}
                        showsVerticalScrollIndicator={false}
                        data={lists}
                        extraData={lists}
                        renderItem={renderSearchItem}
                        keyExtractor={(item, index) => String(index)}
                      />
                    </ScrollView>
                  ) : null}
                </View>
              </View>
            ) : null}

            {/* Add employee view */}
            {addEmployee == true ? (
              <View
                style={{
                  //alignItems: 'center',
                  // shadowColor: "#000",
                  // shadowOffset: {
                  //   width: 0,
                  //   height: 1,
                  // },
                  // shadowOpacity: 0.34,
                  // shadowRadius: 3.22,
                  // elevation: 2,
                  width: Dimensions.get("window").width * 0.87,
                  marginHorizontal: 15,
                  // marginBottom: -130,
                  // borderWidth:1,
                }}
              >
                {/* Back button view */}
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: windowWidth * 0.8575,
                    marginTop: 0,
                    //marginLeft: -25,
                    // borderWidth:1,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      width: 90,
                      flexDirection: "row",
                      alignSelf: "flex-start",
                      alignItems: "center",
                      paddingLeft: 15,
                      borderRadius: 10,
                      marginHorizontal: 0,
                    }}
                    onPress={() => {
                      // setState({ addEmployee: false, addEmployeeItem: true })
                      setAddEmployee(false);
                      setAddEmployeeItem(true);
                    }}
                  >
                    <Feather
                      name="chevron-left"
                      size={30}
                      color={Colors.primaryColor}
                    />
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        color: Colors.primaryColor,
                        fontSize: 17,
                        marginBottom: Platform.OS === "ios" ? 0 : 1,
                      }}
                    >
                      Back
                    </Text>
                  </TouchableOpacity>
                </View>

                {/* White container */}
                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    width: windowWidth * 0.8575,
                    //minHeight: Dimensions.get('screen').height * 0.01,
                    marginTop: 30,
                    marginHorizontal: 25,
                    marginBottom: 30,
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  }}
                >
                  {/* Top container */}
                  <View
                    style={{
                      alignItems: "center",
                      marginBottom: 30,
                      marginTop: 20,
                      // marginRight: 80
                      width: "100%",
                      // borderWidth:1,
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 40,
                        fontWeight: "bold",
                      }}
                    >
                      {selectedOutletEmployeeEdit
                        ? "Edit Employee"
                        : "Add Employee"}
                    </Text>
                    <Text
                      style={{
                        color: Colors.descriptionColor,
                        //marginTop: 10,
                        fontSize: 16,
                      }}
                    >
                      Fill In Employees Information
                    </Text>

                    <View
                      style={{
                        alignSelf: "flex-end",
                        position: "absolute",
                        marginTop: 10,
                        zIndex: 10000,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          justifyContent: "center",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          width: 120,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginRight: 20,
                        }}
                        onPress={() => {
                          addEmployeeFunc();
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          {selectedOutletEmployeeEdit ? "UPDATE" : "SAVE"}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                  {/* Bottom container */}
                  <View
                    style={{
                      flexDirection: "row",
                      paddingBottom: 30,
                      width: "90%",
                      // borderWidth:1,
                    }}
                  >
                    {/* Left container */}
                    <View style={[{ width: "40%" }]}>
                      {/* left top */}
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          // borderWidth:1,
                        }}
                      >
                        {/* picture */}
                        <View>
                          <TouchableOpacity onPress={handleChoosePhoto}>
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                // borderWidth:1,
                              }}
                            >
                              {image !== "" ? (
                                <View style={{ marginRight: "10%" }}>
                                  <View
                                    style={[
                                      {
                                        alignItems: "center",
                                        // justifyContent: 'center',
                                        // backgroundColor: '#F7F7F7',
                                        borderRadius: 5,
                                        width: 200,
                                        height: 200,
                                        alignSelf: "center",
                                      },
                                    ]}
                                  >
                                    <img style={{
                                      width: 148 + 'px',
                                      height: 148 + 'px',
                                      alignSelf: 'center',
                                      borderRadius: 999 + 'px',
                                    }} alt={imageType} src={image}></img>
                                    {/* <AsyncImage
                                      source={{
                                        uri:
                                          image ||
                                          (selectedOutletEmployeeEdit
                                            ? selectedOutletEmployeeEdit.avatar
                                            : ""),
                                      }}
                                      item={
                                        selectedOutletEmployeeEdit
                                          ? selectedOutletEmployeeEdit
                                          : null
                                      }
                                      style={[
                                        {
                                          width: 148,
                                          height: 148,
                                          alignSelf: "center",
                                          borderRadius: 100,
                                        },
                                      ]}
                                    ></AsyncImage> */}
                                    <View
                                      style={{
                                        position: "absolute",
                                        bottom: 60,
                                        right: 15,
                                        //opacity: 0.5,
                                      }}
                                    >
                                      <Feather
                                        name="edit"
                                        size={23}
                                        color={Colors.primaryColor}
                                      />
                                    </View>
                                  </View>
                                </View>
                              ) : (
                                <View
                                  style={[
                                    {
                                      alignItems: "center",
                                      // justifyContent: 'center',
                                      // backgroundColor: '#F7F7F7',
                                      borderRadius: 5,
                                      width: 200,
                                      height: 200,
                                      alignSelf: "center",
                                      paddingRight: 200,
                                    },
                                  ]}
                                >
                                  <Image
                                    style={[
                                      {
                                        width: 148,
                                        height: 148,
                                        borderRadius: 100,
                                        alignSelf: "center",
                                        borderWidth: 1,
                                      },
                                    ]}
                                    source={require("../assets/image/default-profile.png")}
                                  />
                                  <View
                                    style={{
                                      position: "absolute",
                                      bottom: 60,
                                      right: 115,
                                      //opacity: 0.5,
                                    }}
                                  >
                                    <Feather
                                      name="edit"
                                      size={23}
                                      color={Colors.primaryColor}
                                    />
                                  </View>
                                </View>
                              )}




                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          width: "50%",
                          marginTop: 30,
                        }}
                      ></View>

                      {/* ***************Border Line**************  */}
                      {/* <View style={{ borderWidth: 0.5, borderColor: '#E5E5E5', marginVertical: 25 }} /> */}

                      <View style={{ flexDirection: "column" ,marginLeft:windowWidth * 0.02}}>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "90%",
                            alignItems: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Nunitosans-Bold",
                              fontSize: 14,
                              color: "black",
                              width: "30%",
                              fontWeight: "500",
                              textAlign: "left",
                            }}
                          >
                            Role
                          </Text>
                          <Picker
                            selectedValue={role}
                            style={{
                              height: 40,
                              width: 200,
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              zIndex: 1000,
                            }}
                            onValueChange={(item) => {
                              setRole(item);
                            }}
                          >
                            {[
                              {
                                label: "Staff",
                                value: "frontliner",
                              },
                              {
                                label: "Store Manager",
                                value: "store_manager",
                              },
                              {
                                label: "Super Admin",
                                value: "admin",
                              },
                            ].map((value, index) => {
                              return (
                                <Picker.Item
                                  key={index}
                                  label={value.label}
                                  value={value.value}
                                />
                              );
                            })}
                          </Picker>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "90%",
                            alignItems: "center",
                            zIndex: -1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 14,
                              color: "black",
                              fontFamily: "Nunitosans-Bold",
                              width: "30%",
                            }}
                          >
                            Outlet
                          </Text>
                          <Picker
                            selectedValue={selectedOutletId}
                            style={{
                              height: 40,
                              width: 200,
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              zIndex: -1,
                            }}
                            onValueChange={(item) => {
                              setSelectedOutletId(item);
                            }}
                          >
                            {outletDropdownList.map((value, index) => {
                              return (
                                <Picker.Item
                                  key={index}
                                  label={value.label}
                                  value={value.value}
                                />
                              );
                            })}
                          </Picker>
                        </View>
                      </View>

                      {/* ***************Border Line**************  */}
                      <View
                        style={{
                          borderWidth: 0,
                          borderColor: "#E5E5E5",
                          marginVertical: 25,
                          zIndex: -2,
                        }}
                      />
                    </View>

                    {/* Right Side */}
                    <View style={[{ width: "60%", paddingLeft: 10 }]}>
                      <View>
                        <View
                          style={{ flexDirection: "column", marginLeft: 5 }}
                        >
                          <View style={{ flexDirection: "row" }}>
                            <View
                              style={{
                                flexDirection: "row",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "black",
                                  fontFamily: "Nunitosans-Bold",
                                  width: "30%",
                                }}
                              >
                                Name
                              </Text>
                              <TextInput
                                // editable={!loading}
                                editable={true}
                                // underlineColorAndroid={Colors.whiteColor}
                                clearButtonMode="while-editing"
                                style={{
                                  backgroundColor: Colors.fieldtBgColor,
                                  width: Dimensions.get("screen").width * 0.12,
                                  height: 40,
                                  borderRadius: 5,
                                  padding: 5,
                                  marginVertical: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  paddingLeft: 10,
                                  fontSize: 14,
                                  color: "black",
                                  fontFamily: "Nunitosans-Regular",
                                }}
                                placeholder="Name"
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                multiline={false}
                                onChangeText={(text) => {
                                  // setState({ name: text });
                                  setName(text);
                                }}
                                value={name}
                              />
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                width: "50%",
                                alignItems: "center",
                                // borderWidth:1
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "black",
                                  fontFamily: "Nunitosans-Bold",
                                  width: "37%",
                                }}
                              >
                                Username
                              </Text>
                              <View
                                style={{
                                  width: "75%",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TextInput
                                  editable={!loading}
                                  //underlineColorAndroid={Colors.whiteColor}
                                  clearButtonMode="while-editing"
                                  style={{
                                    backgroundColor: Colors.fieldtBgColor,
                                    width:
                                      Dimensions.get("screen").width * 0.12,
                                    height: 40,
                                    borderRadius: 5,
                                    padding: 5,
                                    marginVertical: 5,
                                    borderWidth: 1,
                                    borderColor: "#E5E5E5",
                                    paddingLeft: 10,
                                    fontSize: 14,
                                    color: "black",
                                    fontFamily: "Nunitosans-Regular",
                                  }}
                                  placeholder="Username"
                                  placeholderStyle={{
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: 14,
                                  }}
                                  placeholderTextColor={Platform.select({
                                    ios: "#a9a9a9",
                                  })}
                                  multiline={false}
                                  onChangeText={(text) => {
                                    // setState({ name: text });
                                    setUsername(text);
                                  }}
                                  value={username}
                                />
                              </View>
                            </View>
                          </View>

                          {/* ***************Border Line**************  */}
                          <View
                            style={{
                              borderWidth: 0.5,
                              borderColor: "#E5E5E5",
                              marginVertical: 21,
                            }}
                          />

                          <View style={{ flexDirection: "row" }}>
                            <View
                              style={{
                                flexDirection: "row",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "black",
                                  fontFamily: "Nunitosans-Bold",
                                  width: "30%",
                                }}
                              >
                                Email
                              </Text>
                              <TextInput
                                editable={
                                  !loading && !selectedOutletEmployeeEdit
                                }
                                // underlineColorAndroid={Colors.whiteColor}
                                clearButtonMode="while-editing"
                                style={{
                                  backgroundColor: Colors.fieldtBgColor,
                                  width: Dimensions.get("screen").width * 0.12,
                                  height: 40,
                                  borderRadius: 5,
                                  padding: 5,
                                  marginVertical: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  paddingLeft: 10,
                                  fontSize: 14,
                                  color: "black",
                                  fontFamily: "Nunitosans-Regular",
                                }}
                                placeholder="Email"
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                onChangeText={(text) => {
                                  // setState({ email: text });
                                  setEmail(text);
                                }}
                                value={email}
                              />
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                width: "50%",
                                alignItems: "center",
                              }}
                            ></View>
                          </View>

                          {/* ***************Border Line**************  */}
                          <View
                            style={{
                              borderWidth: 0.5,
                              borderColor: "#E5E5E5",
                              marginVertical: 21,
                            }}
                          />

                          <View style={{ flexDirection: "row" }}>
                            <View
                              style={{
                                flexDirection: "row",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "black",
                                  fontFamily: "Nunitosans-Bold",
                                  width: "30%",
                                }}
                              >
                                Number
                              </Text>
                              <TextInput
                                // editable={!loading}
                                editable={true}
                                // underlineColorAndroid={Colors.whiteColor}
                                clearButtonMode="while-editing"
                                style={{
                                  backgroundColor: Colors.fieldtBgColor,
                                  width: Dimensions.get("screen").width * 0.12,
                                  height: 40,
                                  borderRadius: 5,
                                  padding: 5,
                                  marginVertical: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  paddingLeft: 10,
                                  fontSize: 14,
                                  color: "black",
                                  fontFamily: "Nunitosans-Regular",
                                }}
                                placeholder="Contact Number"
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                multiline={false}
                                onChangeText={(text) => {
                                  // setState({ number: text });
                                  setNumber(text);
                                }}
                                value={number}
                              />
                            </View>

                            <View
                              style={{
                                flexDirection: "row",
                                width: "50%",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: "black",
                                  fontFamily: "Nunitosans-Bold",
                                  width: "37%",
                                }}
                              >
                                Password
                              </Text>
                              <View
                                style={{
                                  width: "75%",
                                  justifyContent: "space-between",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                {!selectedOutletEmployeeEdit ? (
                                  <TextInput
                                    editable={!loading}
                                    //underlineColorAndroid={Colors.whiteColor}
                                    clearButtonMode="while-editing"
                                    style={{
                                      backgroundColor: Colors.fieldtBgColor,
                                      width:
                                        Dimensions.get("screen").width * 0.12,
                                      height: 40,
                                      borderRadius: 5,
                                      padding: 5,
                                      marginVertical: 5,
                                      borderWidth: 1,
                                      borderColor: "#E5E5E5",
                                      paddingLeft: 10,
                                      fontSize: 14,
                                      color: "black",
                                      fontFamily: "Nunitosans-Regular",
                                    }}
                                    placeholder="Password"
                                    placeholderStyle={{
                                      fontFamily: "NunitoSans-Regular",
                                      fontSize: 14,
                                    }}
                                    placeholderTextColor={Platform.select({
                                      ios: "#a9a9a9",
                                    })}
                                    //placeholderStyle={{paddingTop: switchMerchant ? 0 : 5}}
                                    secureTextEntry={true}
                                    onChangeText={(text) => {
                                      // setState({ password: text });
                                      setPassword(text);
                                    }}
                                    value={password}
                                  />
                                ) : (
                                  <Text
                                    style={{
                                      marginLeft: 5,
                                      fontFamily: "NunitoSans-Regular",
                                      fontSize: 14,
                                    }}
                                  >
                                    ********
                                  </Text>
                                )}
                              </View>
                            </View>
                          </View>

                          {/* ***************Border Line**************  */}
                          <View
                            style={{
                              borderWidth: 0.5,
                              borderColor: "#E5E5E5",
                              marginVertical: 21,
                              zIndex: -1,
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ height: 120 }}></View>
              </View>
            ) : null}

            {showDetail ? (
              <View
                style={{
                  marginTop: 20,
                  marginLeft: 30,
                  backgroundColor: Colors.whiteColor,
                  width: "87%",
                  height: 480,
                  elevation: 5,
                  shadowColor: Colors.blackColor,
                  shadowOffset: 1,
                  shadowOpacity: 10,
                }}
              >
                <Text
                  style={{
                    paddingVertical: 15,
                    marginLeft: 20,
                    color: "#a3a3a3",
                  }}
                >
                  Employee Details
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      marginRight: 430,
                      width: 120,
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: 15,
                      borderRadius: 10,
                      height: Dimensions.get("screen").height * 0.055,
                      backgroundColor: Colors.whiteColor,
                    }}
                    onPress={() => {
                      setState({ showDetail: false, addEmployeeItem: true });
                    }}
                  >
                    <Feather
                      name="chevron-left"
                      size={30}
                      color={Colors.primaryColor}
                    />
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Regular",
                        color: Colors.primaryColor,
                        marginBottom: Platform.OS === "ios" ? 0 : 1,
                      }}
                    >
                      Back
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setState({ visible: true });
                    }}
                    style={{
                      marginHorizontal: 30,
                      flexDirection: "row",
                      alignSelf: "center",
                      alignItems: "center",
                    }}
                  >
                    <Feather name="trash-2" size={20} color="#eb3446" />
                    <Text style={{ color: "#eb3446" }}>
                      Remove this employee
                    </Text>
                  </TouchableOpacity>
                </View>

                <AsyncImage
                  source={{ uri: showEmployee.avatar }}
                  item={showEmployee}
                  style={{
                    alignSelf: "center",
                    backgroundColor: Colors.secondaryColor,
                    paddingVertical: 60,
                    paddingHorizontal: 60,
                    borderRadius: 100,
                  }}
                />

                <Text
                  style={{
                    alignSelf: "center",
                    fontWeight: "bold",
                    fontSize: 20,
                    marginTop: 10,
                  }}
                >
                  {/* {Employee} */}
                </Text>
                <View style={{ flexDirection: "row", marginTop: 20 }}>
                  <View
                    style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}
                  >
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "700",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      Name:
                    </Text>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 15,
                        marginTop: 10,
                        marginLeft: 48,
                        color: "#9c9c9c",
                      }}
                    >
                      {showEmployee.name}
                    </Text>
                  </View>
                  <View
                    style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}
                  >
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "700",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      Contact No.:
                    </Text>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 15,
                        marginTop: 10,
                        marginLeft: 27,
                        color: "#9c9c9c",
                      }}
                    >
                      {showEmployee.number}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 20 }}>
                  <View
                    style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}
                  >
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "700",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      Status:
                    </Text>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 15,
                        marginTop: 10,
                        marginLeft: 45,
                        color: "#9c9c9c",
                      }}
                    >
                      {showEmployee.role}
                    </Text>
                  </View>
                  <View
                    style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}
                  >
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "700",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      Email:
                    </Text>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 15,
                        marginTop: 10,
                        marginLeft: 70,
                        color: "#9c9c9c",
                      }}
                    >
                      {showEmployee.email}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 20 }}>
                  <View
                    style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}
                  >
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "700",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      Position:
                    </Text>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 15,
                        marginTop: 10,
                        marginLeft: 33,
                        color: "#9c9c9c",
                      }}
                    >
                      {showEmployee.role}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 20 }}>
                  <View
                    style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}
                  >
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "700",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      Day of Week:
                    </Text>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 15,
                        marginTop: 10,
                        marginLeft: 33,
                        color: "#9c9c9c",
                      }}
                    >
                      {showEmployee.dayOfWeek}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 20 }}>
                  <View
                    style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}
                  >
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "700",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      Regular Time:
                    </Text>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 15,
                        marginTop: 10,
                        marginLeft: 33,
                        color: "#9c9c9c",
                      }}
                    >
                      {showEmployee.regularTime}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: "row", marginTop: 20 }}>
                  <View
                    style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}
                  >
                    <Text
                      style={{
                        alignSelf: "center",
                        fontWeight: "700",
                        fontSize: 15,
                        marginTop: 10,
                      }}
                    >
                      Over Time:
                    </Text>
                    <Text
                      style={{
                        alignSelf: "center",
                        fontSize: 15,
                        marginTop: 10,
                        marginLeft: 33,
                        color: "#9c9c9c",
                      }}
                    >
                      {showEmployee.overTime}
                    </Text>
                  </View>
                </View>
              </View>
            ) : null}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 20,
    width: Dimensions.get("screen").width * (1 - Styles.sideBarWidth),
    backgroundColor: Colors.highlightColor,
  },
  textInput: {
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 20,
    width: 300,
  },
  dropDown: {
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: "red",
    borderRadius: 5,
    marginBottom: 20,
    width: 300,
  },
  addEmployee: {
    marginTop: 20,
    justifyContent: "center",
    alignItems: "center",
    width: Dimensions.get("window").width - 170,
    backgroundColor: Colors.whiteColor,
    borderRadius: 5,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  submitText: {
    height:
      Platform.OS == "ios"
        ? Dimensions.get("screen").height * 0.06
        : Dimensions.get("screen").height * 0.05,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  titleList: {
    backgroundColor: "#ffffff",
    flexDirection: "row",
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginTop: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  textInput1: {
    width: 250,
    height: 40,
    backgroundColor: "white",

    borderRadius: 20,
    marginRight: "25%",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  circleIcon2: {
    width: 60,
    height: 60,
    marginRight: 10,
    alignSelf: "center",
  },
  confirmBox: {
    width: 350,
    height: 260,
    borderRadius: 10,
    backgroundColor: Colors.whiteColor,
    justifyContent: "space-between",
  },
  confirmBoxRemove: {
    width: 520,
    height: 300,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: "space-between",
  },
  textInput8: {
    fontFamily: "NunitoSans-Regular",
    width: 75,
    height: 50,
    flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },

  textInput9: {
    fontFamily: "NunitoSans-Regular",
    width: 350,
    height: 50,
    //flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },

  headerLeftStyle: {
    width: Dimensions.get("screen").width * 0.17,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    height: Dimensions.get("screen").width * 0.3,
    width: Dimensions.get("screen").width * 0.4,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get("screen").width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: Dimensions.get("screen").width * 0.02,
    top: Dimensions.get("screen").width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: "center",
    top: "20%",
    position: "absolute",
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitleText: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
    fontSize: 24,
  },
  modalDescText: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    width: 130,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  ManageFilterBox: {
    //width: Dimensions.get('screen').width * 0.27,
    //height: Platform.OS === 'ios' ?Dimensions.get('screen').height * 0.23: Dimensions.get('screen').height * 0.24,
    //width: Platform.OS === 'ios' ? Dimensions.get('screen').width * 0.4 : Dimensions.get('screen').width * 0.33,
    height:
      Platform.OS === "ios" ? 180 : Dimensions.get("screen").height * 0.24,
    width: Platform.OS === "ios" ? 400 : Dimensions.get("screen").width * 0.33,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    position: "absolute",
    marginTop: Platform.OS === "ios" ? "13%" : "13%",
    marginLeft: Platform.OS === "ios" ? "12%" : "12%",
    //left: Platform.OS === 'ios' ? '38%' : '0%',
    //top: Platform.OS === 'ios' ? '46%' : '0%',
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 5,
    },
    shadowOpacity: 0.32,
    shadowRadius: 3.22,
    elevation: 10,
    zIndex: 1000,
    borderRadius: 10,
    //borderTopLeftRadius: 0,
    backgroundColor: Colors.whiteColor,
    //justifyContent: 'space-between'
  },
});

export default EmployeeScreen;
