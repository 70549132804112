import React, { Component, useReducer, useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    ScrollView,
    Image,
    View,
    Text,
    Alert,
    TouchableOpacity,
    Modal,
    Dimensions,
    TextInput,
    ActivityIndicator,
    Platform,
    useWindowDimensions,
    Picker
} from 'react-native';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import SideBar from './SideBar';
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Feather';
import moment from 'moment';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Entypo from 'react-native-vector-icons/Entypo';
import Feather from 'react-native-vector-icons/Feather';
import { FlatList } from 'react-native-gesture-handler';
import API from '../constant/API';
import ApiClient from '../util/ApiClient';
import { ReactComponent as GCalendar } from '../assets/svg/GCalendar.svg';
import {
    EMAIL_REPORT_TYPE,
    ORDER_TYPE_PARSED,
    REPORT_SORT_FIELD_TYPE,
    TABLE_PAGE_SIZE_DROPDOWN_LIST,
    ORDER_TYPE
} from '../constant/common';
import { OutletStore } from '../store/outletStore';
import { MerchantStore } from '../store/merchantStore';
import { UserStore } from '../store/userStore';
import {
  convertArrayToCSV,
//   generateEmailReport,
  sortReportDataList,
} from "../util/common";
// import RNFetchBlob from 'rn-fetch-blob';
import XLSX from 'xlsx';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
// import { useKeyboard } from '../hooks';
import { CommonStore } from '../store/commonStore';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import AsyncImage from '../components/asyncImage';

import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";
import { CSVLink } from "react-csv";

const { nanoid } = require('nanoid');

// const RNFS = require('react-native-fs');

const AllTransaction = props => {
    const {
        navigation,
    } = props;

    const {
        height: windowHeight,
        width: windowWidth,
    } = useWindowDimensions();

    const [currentDateTime, setCurrentDateTime] = useState(Date().toLocaleString());
    const [dailySalesDetailsList, setDailySalesDetailsList] = useState([]);
    const [sort, setSort] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [oriList, setOriList] = useState([]);
    const [offset, setOffset] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [detailsPageCount, setDetailsPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentDetailsPage, setCurrentDetailsPage] = useState(1);
    const [switchMerchant, setSwitchMerchant] = useState(false);
    const [pageReturn, setPageReturn] = useState(1);
    const [orderlist, setOrderlist] = useState([]);
    const [showlist, setShowlist] = useState(true);
    const [showDetail, setShowDetail] = useState(false);
    const [visible, setVisible] = useState(false);
    const [day, setDay] = useState(false);
    const [pick, setPick] = useState(null);
    const [pick1, setPick1] = useState(null);
    const [detail, setDetail] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [transaction, setTransaction] = useState(null);
    const [TimeOrdered, setTimeOrdered] = useState(null);
    const [outlet, setOutlet] = useState(null);
    const [register, setRegister] = useState(null);
    const [Employee, setEmployee] = useState(null);
    const [price, setPrice] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [tax, setTax] = useState(null);
    const [final, setFinal] = useState(null);
    const [deleteState, setDeleteState] = useState(null);
    const [allTransaction, setAllTransaction] = useState(true);

    const [showDateTimePicker, setShowDateTimePicker] = useState(false);
    const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
    const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
    const [pickerMode, setPickerMode] = useState('datetime');
    const [rev_date, setRev_date] = useState(moment().subtract(6, 'days').startOf('day'));
    const [rev_date1, setRev_date1] = useState(moment().endOf(Date.now()).endOf('day'));

    /////////////////////////////////////////////////////////////////////////

    const [historyOrders, setHistoryOrders] = useState([]);

    const allOutletsUserOrdersDone = OutletStore.useState(s => s.allOutletsUserOrdersDone);
    const currOutletId = MerchantStore.useState(s => s.currOutletId);
    const allOutlets = MerchantStore.useState(s => s.allOutlets);

    const userOrders = OutletStore.useState(s => s.userOrders)
    const userOrdersDict = OutletStore.useState(s => s.userOrdersDict)

    const userName = UserStore.useState(s => s.name);
    const merchantName = MerchantStore.useState(s => s.name);

    const [allTransactions, setAllTransactions] = useState([]);
    const [expandDetailsDict, setExpandDetailsDict] = useState({});

    const [showDetails, setShowDetails] = useState(false);
    const [transactionTypeSalesDetails, setTransactionTypeSalesDetails] = useState([]);

    const [selectedItemSummary, setSelectedItemSummary] = useState({});

    const [exportModalVisibility, setExportModalVisibility] = useState(false);

    const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
    const [selectedTargetOutletId, setSelectedTargetOutletId] = useState('');

    const [currReportSummarySort, setCurrReportSummarySort] = useState('');
    const [currReportDetailsSort, setCurrReportDetailsSort] = useState('');

    const [exportEmail, setExportEmail] = useState('');
    const [search, setSearch] = useState('');

    const [pushPagingToTop, setPushPagingToTop] = useState(false);



    const merchantId = UserStore.useState(s => s.merchantId);
    const isLoading = CommonStore.useState(s => s.isLoading);
    const [isExcel, setIsExcel] = useState(false);
    const [isCsv, setIsCsv] = useState(false);

    const currPageStack = CommonStore.useState(s => s.currPageStack);
    const pageStack = CommonStore.useState(s => s.pageStack);

    const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);

    useEffect(() => {
        setTargetOutletDropdownList(allOutlets.map(outlet => ({ label: outlet.name, value: outlet.uniqueId })));

        console.log('targetOutletDropdownList');
        console.log(targetOutletDropdownList);

        if (allOutlets.length > 0) {
            setSelectedTargetOutletId(currOutletId);
        }
    }, [allOutlets, currOutletId]);



    useEffect(() => {
        if (currOutletId !== '' && allOutlets.length > 0 && allOutletsUserOrdersDone.length > 0) {
            var allTransactionsTemp = [];

            for (var i = 0; i < allOutletsUserOrdersDone.length; i++) {
                if (allOutletsUserOrdersDone[i].outletId === currOutletId &&
                    moment(rev_date).isSameOrBefore(allOutletsUserOrdersDone[i].completedDate) &&
                    moment(rev_date1).isAfter(allOutletsUserOrdersDone[i].completedDate)) {
                    allTransactionsTemp.push(allOutletsUserOrdersDone[i]);
                }
            }

            allTransactionsTemp.sort((a, b) => a.completedDate - b.completedDate);

            setAllTransactions(allTransactionsTemp);
            //setCurrentPage(1);
            setPageCount(Math.ceil(allTransactionsTemp.length / perPage));

            setShowDetails(false);
        }

    }, [allOutletsUserOrdersDone, currOutletId, rev_date, rev_date1, perPage]);

    useEffect(() => {
        if (showDetails && selectedItemSummary.cartItems) {
            setTransactionTypeSalesDetails(selectedItemSummary.cartItems);

            setPageReturn(currentPage)
            console.log('currentPage value is')
            console.log(currentPage)
            setCurrentDetailsPage(1)
            setPageCount(Math.ceil(selectedItemSummary.cartItems.length / perPage));
        }
    }, [
        showDetails,
        selectedItemSummary,
        perPage
    ]);

    const setState = () => { };

    // navigation.dangerouslyGetParent().setOptions({
    //     tabBarVisible: false
    // });

    useEffect(() => {
        var title = 'All Transactions';

        if (pageStack.length > 1 && pageStack[pageStack.length - 2] === 'Dashboard') {
            title = 'Today Transactions';

            // setSalesLineChartPeriod(CHART_PERIOD.TODAY);
            setRev_date(moment().startOf('day'));
            setRev_date1(moment().endOf('day'));
        }
        else {
            title = 'All Transactions';

            // setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH);
            setRev_date(moment(Date.now()).subtract(1, 'week').startOf('day'));
            setRev_date1(moment(Date.now()).endOf('day'));
        }

        navigation.setOptions({
            headerLeft: () => (
                <View style={{
                    width: windowWidth * 0.17,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Image
                        style={{
                            width: 124,
                            height: 26,
                        }}
                        resizeMode="contain"
                        source={require('../assets/image/logo.png')}
                    />
                </View>
            ),
            headerTitle: () => (
                <View style={[{
                    justifyContent: 'center',
                    alignItems: 'center',
                    // bottom: -2,
                    bottom: switchMerchant ? '2%' : 0,
                }, windowWidth >= 768 && switchMerchant ? { right: windowWidth * 0.1 } : {}, windowWidth <= 768 ? { right: windowWidth * 0.12 } : {}]}>
                    <Text
                        style={{
                            fontSize: switchMerchant ? 20 : 24,
                            // lineHeight: 25,
                            textAlign: 'center',
                            fontFamily: 'NunitoSans-Bold',
                            color: Colors.whiteColor,
                            opacity: 1,
                        }}>
                        All Transactions
                    </Text>
                </View>
            ),
            headerRight: () => (
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                    {outletSelectDropdownView()}
                    <View style={{
                        backgroundColor: 'white',
                        width: 0.5,
                        height: windowHeight * 0.025,
                        opacity: 0.8,
                        marginHorizontal: 15,
                        bottom: -1,
                    }}>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('Setting')}
                        style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                        <Text
                            style={{
                                fontFamily: 'NunitoSans-SemiBold',
                                fontSize: 16,
                                color: Colors.secondaryColor,
                                marginRight: 15,
                            }}>
                            {userName}
                        </Text>
                        <View style={{
                            marginRight: 30,
                            width: windowHeight * 0.05,
                            height: windowHeight * 0.05,
                            borderRadius: windowHeight * 0.05 * 0.5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                        }}>
                            <Image style={{
                                width: windowHeight * 0.035,
                                height: windowHeight * 0.035,
                                alignSelf: 'center',
                            }} source={require('../assets/image/profile-pic.jpg')} />
                        </View>
                    </TouchableOpacity>
                </View>
            ),
        });
    }, [pageStack]);

    // componentDidMount() {
    //     const one = new Date();
    //     const two = new Date(one.getTime() - (7 * 24 * 60 * 60 * 1000))
    //     getDetail(two, one);
    // }

    // function here

    // const showDateTimePicker = () => {
    //     setState({ isDateTimePickerVisible: true });
    // };

    const hideDateTimePicker = () => {
        setState({ isDateTimePickerVisible: false });
    };

    const getTransactionDetail = (param) => {
        ApiClient.GET(API.getOrderDetails + param).then((result) => {
            console.log("##########")
            console.log(result.receiptNo)
            setState({
                detail: result.receiptNo,
                orderlist: result.orderItems,
                customer: result.type,
                transaction: result.paymentMethod,
                TimeOrdered: result.orderedAt,
                outlet: result.outlet.name,
                register: 1,
                Employee: result.waiterName == null ? "John Doe" : result.waiterName,
                price: result.price,
                discount: result.discount,
                tax: result.tax,
                final: result.finalPrice,
                delete: result.id
            });
        }).catch(err => console.log(err))

    }

    const email = (param) => {
        var body = {
            orderId: param,
            email: "kokokk@gmail.com"
        }

        Alert.alert(
            'Success',
            'The email has been sent',
            [
                { text: "OK", onPress: () => { setState({ visible: false }); } }
            ],
            { cancelable: false },
        );

        // ApiClient.POST(API.sendShiftEmail, body, false).then((result) => {
        //     console.log(result)
        //     if (result !== null) {

        //         Alert.alert(
        //             'Success',
        //             'The email had sent',
        //             [
        //                 { text: "OK", onPress: () => { setState({ visible: false }); } }
        //             ],
        //             { cancelable: false },
        //         );
        //     }
        // }).catch(err => console.log(err))
    }

    const email1 = (param) => {
        var body = {
            data: param,
            email: "kokokk@gmail.com"
        }
        ApiClient.POST(API.emailReportPdf, body, false).then((result) => {
            console.log(result)
            if (result !== null) {

                Alert.alert(
                    'Success',
                    'The email has been sent',
                    [
                        { text: "OK", onPress: () => { } }
                    ],
                    { cancelable: false },
                );
            }
        }).catch(err => console.log(err))
    }

    // const download = (param) => {
    //     if (allOutletsUserOrdersDone) {
    //         const csvData = convertArrayToCSV(allOutletsUserOrdersDone);

    //         const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-all-transactions-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
    //         console.log("PATH", pathToWrite)
    //         RNFetchBlob.fs
    //             .writeFile(pathToWrite, csvData, 'utf8')
    //             .then(() => {
    //                 console.log(`wrote file ${pathToWrite}`);
    //                 // wrote file /storage/emulated/0/Download/data.csv
    //                 Alert.alert(
    //                     'Success',
    //                     `Sent to ${pathToWrite}`,
    //                     [{ text: 'OK', onPress: () => { } }],
    //                     { cancelable: false },
    //                 );
    //             })
    //             .catch(error => console.error(error));
    //     }

    //     // var body = {
    //     //     orderId: param,
    //     // }

    //     // ApiClient.POST(API.generateReportPDF, body, false).then((result) => {
    //     //     console.log(result)
    //     //     if (result !== null) {

    //     //         Alert.alert(
    //     //             'Success',
    //     //             'The data had downloaded',
    //     //             [
    //     //                 { text: "OK", onPress: () => { } }
    //     //             ],
    //     //             { cancelable: false },
    //     //         );
    //     //     }
    //     // }).catch(err => console.log(err))
    // }

    // const add = async () => {
    //     if (page + 1 < pageCount) {
    //         await setState({ page: page + 1, currentPage: currentPage + 1 })
    //         console.log(page)
    //         var e = page
    //         next(e)
    //     }
    // }

    // const next = (e) => {
    //     const offset = e * perPage;
    //     setState({ offset: offset })
    //     loadMoreData()
    // }

    // const less = async () => {
    //     if (page > 0) {
    //         await setState({ page: page - 1, currentPage: currentPage - 1 })
    //         console.log(page)
    //         var y = page
    //         pre(y)
    //     }
    // }

    // const pre = (y) => {

    //     const offset = y * perPage;
    //     setState({ offset: offset })
    //     loadMoreData()

    // }

    const nextPage = () => {
        setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
    };

    const nextDetailsPage = () => {
        setCurrentDetailsPage(currentDetailsPage + 1 > pageCount ? currentDetailsPage : currentDetailsPage + 1);
    };

    const prevDetailsPage = () => {
        setCurrentDetailsPage(currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1);
    };


    const loadMoreData = () => {
        const data = oriList;
        const slice = data.slice(offset, offset + perPage)
        setState({ list: slice, pageCount: Math.ceil(data.length / perPage) })
    }


    const getDetail = (param1, param2) => {
        ApiClient.GET(API.allTransactionSort + 1 + '&startDate=' + moment(param1).format("YYYY-MM-DD") + '&endDate=' + moment(param2).format("YYYY-MM-DD")).then((result) => {
            // ApiClient.GET(API.allTransactionSort + 1 + '&startDate=' + startDate + '&endDate=' + endDate).then((result) => {
            var data = result
            var slice = data.slice(offset, offset + perPage)
            setState({ list: slice, oriList: data, pageCount: Math.ceil(data.length / perPage) })
        });
    }

    const cancelTransaction = (param) => {
        var body = {
            orderId: param
        }
        ApiClient.POST(API.cancelTransaction, body, false).then((result) => {
            console.log(result)
            if (result.success == true) {

                Alert.alert(
                    'Success',
                    'The transaction has been deleted',
                    [
                        { text: "OK", onPress: () => { getDetail(moment(rev_date).format("YYYY-MM-DD"), moment(rev_date1).format("YYYY-MM-DD")); setState({ visible: false, showlist: true }) } }
                    ],
                    { cancelable: false },
                );
            }
        }).catch(err => console.log(err))
    }

    // const sortingOrders = (param) => {

    //     console.log("sate.saet", lastSort)
    //     if (param.value == 1) { //productname
    //         if (lastSort != null ? lastSort.value == param.value : null) {
    //             const list = list.sort((a, b) => b.datetime - a.datetime)
    //             setState({ list })

    //         } else {
    //             const list = list.sort((a, b) => a.datetime - b.datetime)
    //             setState({ list })
    //         }
    //     }
    //     if (param.value == 2) { //SKU
    //         if (lastSort != null ? lastSort.value == param.value : null) {
    //             const list = list.sort((a, b) => b.outletName.localeCompare(a.outletName))
    //             setState({ list })

    //         } else {
    //             const list = list.sort((a, b) => a.outletName.localeCompare(b.outletName))
    //             setState({ list })
    //         }
    //     }
    //     if (param.value == 3) { //quantity
    //         // if (lastSort != null ? lastSort.value == param.value : null) {
    //         //     const list = list.sort((a, b) => b.employee.localeCompare(a.employee==null?"Rose":a.type))
    //         //     setState({ list })

    //         //   } else {
    //         //     const list = list.sort((a, b) => a.employee.localeCompare(b.employee==null?"Rose":a.type))
    //         //     setState({ list })
    //         //   }
    //     }
    //     if (param.value == 4) { //cost
    //         if (lastSort != null ? lastSort.value == param.value : null) {
    //             const list = list.sort((a, b) => b.type.localeCompare(a.type))
    //             setState({ list })

    //         } else {
    //             const list = list.sort((a, b) => a.type.localeCompare(b.type == null))
    //             setState({ list })
    //         }
    //     }
    //     if (param.value == 5) { //price
    //         if (lastSort != null ? lastSort.value == param.value : null) {
    //             const list = list.sort((a, b) => b.subtotal - a.subtotal)
    //             setState({ list })

    //         } else {
    //             const list = list.sort((a, b) => a.subtotal - b.subtotal)
    //             setState({ list })
    //         }
    //     }
    //     if (param.value == 6) { //price
    //         if (lastSort != null ? lastSort.value == param.value : null) {
    //             const list = list.sort((a, b) => b.discount - a.discount)
    //             setState({ list })

    //         } else {
    //             const list = list.sort((a, b) => a.discount - b.discount)
    //             setState({ list })
    //         }
    //     }
    //     if (param.value == 7) { //price
    //         if (lastSort != null ? lastSort.value == param.value : null) {
    //             const list = list.sort((a, b) => b.tax - a.tax)
    //             setState({ list })

    //         } else {
    //             const list = list.sort((a, b) => a.tax - b.tax)
    //             setState({ list })
    //         }
    //     }
    //     if (param.value == 8) { //price
    //         if (lastSort != null ? lastSort.value == param.value : null) {
    //             const list = list.sort((a, b) => b.netSale - a.netSale)
    //             setState({ list })

    //         } else {
    //             const list = list.sort((a, b) => a.netSale - b.netSale)
    //             setState({ list })
    //         }
    //     }
    //     setState({ lastSort: param })
    // }

    const renderList = ({ item, index }) => {
        console.log('order id');
        console.log(item.orderId);
        return (
            <TouchableOpacity onPress={() => { onItemSummaryClicked(item) }}>

                <View style={{
                    backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
                    paddingVertical: 10,
                    //paddingHorizontal: 3,
                    //paddingLeft: 1,
                    borderColor: '#BDBDBD',
                    borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                    borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                    borderBottomRightRadius: 10,
                    borderBottomLeftRadius: 10,
                }}>
                    <View style={{ flexDirection: "row", paddingVertical: 10, }}>
                        <Text style={{ width: switchMerchant ? '4.5%' : '5%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{index + 1}</Text>
                        <Text style={{ width: switchMerchant ? '11%' : '11%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{moment(item.completedDate).format('DD MMM YYYY')}</Text>
                        <Text style={{ width: switchMerchant ? '10.6%' : '13%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.outletName}</Text>
                        <Text style={{ width: switchMerchant ? '10.75%' : '10%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.waiterName !== '' ? item.waiterName : 'N/A'}</Text>
                        <Text style={{ width: switchMerchant ? '10.75%' : '10%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.userName !== '' ? item.userName : 'Guest'}</Text>
                        <Text style={{ width: switchMerchant ? '9%' : '9%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{ORDER_TYPE_PARSED[item.orderType]}</Text>
                        <Text style={{ width: switchMerchant ? '9.5%' : '10%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.courierCode ? item.courierCode : 'N/A'}</Text>
                        <Text style={{ width: switchMerchant ? '9.75%' : '9%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{parseFloat(item.totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}</Text>
                        <Text style={{ width: switchMerchant ? '7%' : '7%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{parseFloat(item.discount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}</Text>
                        <Text style={{ width: switchMerchant ? '6.75%' : '7%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{parseFloat(item.tax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}</Text>
                        <View style={{ width: switchMerchant ? '10.4%' : '9%', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 10, paddingRight: switchMerchant ? '2.15%' : '1.8%' }}>
                            <Text style={{}} />
                            <Text style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', }}>
                                {(Math.ceil(item.finalPrice * 20 - 0.05) / 20).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
                            </Text>
                        </View>
                    </View>
                    {expandDetailsDict[item.uniqueId] == true ? (
                        <View
                            style={{
                                minheight: windowHeight * 0.35,
                                marginTop: 30,
                                paddingBottom: 20,
                            }}>
                            {item.cartItems.map((cartItem, index) => {
                                const cartItemPriceWIthoutAddOn =
                                    cartItem.price -
                                    cartItem.addOns.reduce(
                                        (accum, addOn) => accum + addOn.prices[0],
                                        0,
                                    );

                                return (
                                    <View
                                        style={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}>
                                        <View
                                            style={{
                                                width: '100%',
                                                alignItems: 'flex-start',
                                                flexDirection: 'row',
                                                marginBottom: Platform.OS == 'ios' ? 10 : 10,
                                                minHeight: 80,
                                                //backgroundColor: 'yellow',
                                            }}>
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    width: '100%',
                                                    //backgroundColor: 'blue',
                                                }}>
                                                {index == 0 ? (
                                                    <View
                                                        style={{
                                                            marginHorizontal: 1,
                                                            width: Platform.OS == 'ios' ? '8%' : '8%',
                                                            //justifyContent: 'center',
                                                            alignItems: 'center',
                                                            //backgroundColor: 'blue',
                                                        }}>
                                                        <TouchableOpacity
                                                            style={{
                                                                alignItems: 'center',
                                                                marginTop: 0,
                                                            }}
                                                            onPress={() => {
                                                                var crmUser = null;

                                                                if (item.crmUserId !== undefined) {
                                                                    // for (
                                                                    //     var i = 0;
                                                                    //     i < crmUsers.length;
                                                                    //     i++
                                                                    // ) {
                                                                    //     if (
                                                                    //         item.crmUserId ===
                                                                    //         crmUsers[i].uniqueId
                                                                    //     ) {
                                                                    //         crmUser = crmUsers[i];
                                                                    //         break;
                                                                    //     }
                                                                    // }
                                                                }

                                                                if (!crmUser) {
                                                                    // for (
                                                                    //     var i = 0;
                                                                    //     i < crmUsers.length;
                                                                    //     i++
                                                                    // ) {
                                                                    //     if (
                                                                    //         item.userId ===
                                                                    //         crmUsers[i].firebaseUid
                                                                    //     ) {
                                                                    //         crmUser = crmUsers[i];
                                                                    //         break;
                                                                    //     }
                                                                    // }
                                                                }

                                                                if (crmUser) {
                                                                    CommonStore.update(
                                                                        (s) => {
                                                                            s.selectedCustomerEdit = crmUser;
                                                                            // s.selectedCustomerEdit = userReservations[item.userId] && crmUsers[item.userId] ? crmUsers[item.userId] : null ;

                                                                            s.routeParams = {
                                                                                pageFrom: 'Reservation',
                                                                            };
                                                                        },
                                                                        () => {
                                                                            navigation.navigate('NewCustomer');
                                                                        },
                                                                    );
                                                                }
                                                            }}>
                                                            <Image
                                                                style={{
                                                                    width: switchMerchant
                                                                        ? 30
                                                                        : 60,
                                                                    height: switchMerchant
                                                                        ? 30
                                                                        : 60,
                                                                }}
                                                                resizeMode="contain"
                                                                source={require('../assets/image/default-profile.png')}
                                                            />

                                                            <View
                                                                style={{
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}>
                                                                <Text
                                                                    style={[
                                                                        {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            marginTop: 0,
                                                                            fontSize: 13,
                                                                            textAlign: 'center',
                                                                        },
                                                                        switchMerchant
                                                                            ? {
                                                                                fontFamily: 'NunitoSans-Bold',
                                                                                marginTop: 0,
                                                                                fontSize: 10,
                                                                                textAlign: 'center',
                                                                            }
                                                                            : {},
                                                                    ]}
                                                                    numberOfLines={1}>
                                                                    {item.userName ? item.userName : 'Guest'}
                                                                </Text>
                                                            </View>
                                                        </TouchableOpacity>
                                                    </View>
                                                ) : (
                                                    <View
                                                        style={{
                                                            marginHorizontal: 1,
                                                            width: Platform.OS == 'ios' ? '8%' : '8%',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    />
                                                )}

                                                <View
                                                    style={{
                                                        // flex: 0.3,
                                                        width: '5%',
                                                        //justifyContent: 'center',
                                                        alignItems: 'center',
                                                        //backgroundColor: 'red',
                                                        //paddingLeft: '1.2%',
                                                    }}>
                                                    <Text
                                                        style={[
                                                            {
                                                                fontFamily: 'NunitoSans-Bold',
                                                                fontSize: 13,
                                                            },
                                                            switchMerchant
                                                                ? {
                                                                    fontFamily: 'NunitoSans-Bold', fontSize: 10,
                                                                }
                                                                : {},
                                                        ]}>
                                                        {index + 1}.
                                                    </Text>
                                                </View>

                                                <View
                                                    style={{
                                                        //flex: 0.5,
                                                        width: '10%',
                                                        //backgroundColor: 'green',
                                                        alignItems: 'center',
                                                    }}>
                                                    <AsyncImage
                                                        source={{ uri: cartItem.image }}
                                                        item={cartItem}
                                                        style={{
                                                            width: switchMerchant
                                                                ? 30
                                                                : 60,
                                                            height: switchMerchant
                                                                ? 30
                                                                : 60,
                                                            borderWidth: 1,
                                                            borderColor: '#E5E5E5',
                                                            borderRadius: 5,
                                                        }}
                                                    />
                                                </View>
                                                <View style={{ width: '75%' }}>
                                                    <View
                                                        style={{
                                                            marginLeft: Platform.OS == 'ios' ? 14 : 14,
                                                            marginBottom: 10,
                                                            //backgroundColor: 'blue',
                                                            width: '100%',
                                                            flexDirection: 'row',
                                                        }}>
                                                        <View style={{ width: '69%' }}>
                                                            <Text
                                                                style={[
                                                                    {
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                        fontSize: 13,
                                                                    },
                                                                    switchMerchant
                                                                        ? {
                                                                            fontFamily: 'NunitoSans-Bold', fontSize: 10,
                                                                        }
                                                                        : {},
                                                                ]}>
                                                                {cartItem.name}
                                                            </Text>
                                                        </View>

                                                        <View
                                                            style={{
                                                                width: '13%',
                                                            }}>
                                                            <View
                                                                style={{
                                                                    alignItems: 'center',
                                                                    //backgroundColor: 'yellow',
                                                                }}>
                                                                <Text
                                                                    style={[
                                                                        {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 13,
                                                                        },
                                                                        switchMerchant
                                                                            ? {
                                                                                fontFamily: 'NunitoSans-Bold', fontSize: 10,
                                                                            }
                                                                            : {},
                                                                    ]}>
                                                                    x{cartItem.quantity}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: '18.71%',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10,
                                                                        }
                                                                        : { fontSize: 13 }
                                                                }>
                                                                RM
                                                            </Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                {cartItemPriceWIthoutAddOn
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>

                                                    {cartItem.remarks &&
                                                        cartItem.remarks.length > 0 ? (
                                                        <View
                                                            style={{
                                                                alignItems: 'center',
                                                                flexDirection: 'row',
                                                                marginLeft: Platform.OS == 'ios' ? 14 : 14,
                                                            }}>
                                                            <View style={{ justifyContent: 'center' }}>
                                                                <Text
                                                                    style={[
                                                                        {
                                                                            fontFamily: 'NunitoSans-SemiBold',
                                                                            fontSize: 13,
                                                                        },
                                                                        switchMerchant
                                                                            ? {
                                                                                fontFamily: 'NunitoSans-SemiBold', fontSize: 10,
                                                                            }
                                                                            : {},
                                                                    ]}>
                                                                    {cartItem.remarks}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {cartItem.addOns.map((addOnChoice, i) => {
                                                        return (
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    // marginLeft: -5,
                                                                    width: '100%',
                                                                }}>
                                                                <View
                                                                    style={{
                                                                        width: '69%',
                                                                        flexDirection: 'row',
                                                                        marginLeft:
                                                                            Platform.OS == 'ios' ? 14 : 14,
                                                                    }}>
                                                                    <Text
                                                                        style={[switchMerchant
                                                                            ? {
                                                                                fontFamily: 'NunitoSans-Bold',
                                                                                fontSize: 10,
                                                                                color: Colors.descriptionColor,
                                                                                width: '25%',
                                                                                // marginLeft: 5,
                                                                            } :
                                                                            {
                                                                                fontFamily: 'NunitoSans-Bold',
                                                                                fontSize: 13,
                                                                                color: Colors.descriptionColor,
                                                                                width: '25%',
                                                                                // marginLeft: 5,
                                                                            },
                                                                        ]}>
                                                                        {`${addOnChoice.name}:`}
                                                                    </Text>
                                                                    <Text
                                                                        style={[switchMerchant
                                                                            ? {
                                                                                fontFamily: 'NunitoSans-Bold',
                                                                                fontSize: 10,
                                                                                color: Colors.descriptionColor,
                                                                                width: '75%',
                                                                            } : {
                                                                                fontFamily: 'NunitoSans-Bold',
                                                                                fontSize: 13,
                                                                                color: Colors.descriptionColor,
                                                                                width: '75%',
                                                                                // marginLeft: 5,
                                                                            },
                                                                        ]}>
                                                                        {`${addOnChoice.choiceNames[0]}`}
                                                                    </Text>
                                                                </View>

                                                                <View
                                                                    style={[
                                                                        {
                                                                            width: '13%',
                                                                            flexDirection: 'row',
                                                                            justifyContent: 'center',
                                                                            //backgroundColor: 'blue',
                                                                        },
                                                                    ]}>
                                                                    <Text
                                                                        style={[switchMerchant
                                                                            ? {
                                                                                fontFamily: 'NunitoSans-Bold',
                                                                                fontSize: 10,
                                                                                color: Colors.descriptionColor,
                                                                                width: '28%',
                                                                                textAlign: 'center',
                                                                            } : {
                                                                                fontFamily: 'NunitoSans-Bold',
                                                                                fontSize: 13,
                                                                                color: Colors.descriptionColor,
                                                                                width: '28%',
                                                                                // right: 38,
                                                                                //backgroundColor: 'green',
                                                                                textAlign: 'center',
                                                                            },
                                                                        ]}>
                                                                        {`${addOnChoice.quantities
                                                                            ? `x${addOnChoice.quantities[0]}`
                                                                            : ''
                                                                            }`}
                                                                    </Text>
                                                                </View>

                                                                <View
                                                                    style={{
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'space-between',
                                                                        width: '18.75%',
                                                                        alignItems: 'center',
                                                                    }}>
                                                                    <Text
                                                                        style={[
                                                                            switchMerchant
                                                                                ? { color: Colors.descriptionColor, fontSize: 10 }
                                                                                : {
                                                                                    color: Colors.descriptionColor,
                                                                                    fontSize: 13,
                                                                                },
                                                                        ]}>
                                                                        RM
                                                                    </Text>
                                                                    <Text
                                                                        style={
                                                                            switchMerchant
                                                                                ? {
                                                                                    fontSize: 10, color: Colors.descriptionColor,
                                                                                    paddingRight: 20,
                                                                                    fontFamily: 'NunitoSans-Regular'
                                                                                }
                                                                                : {
                                                                                    color: Colors.descriptionColor,
                                                                                    paddingRight: 20,
                                                                                    fontSize: 13, fontFamily: 'NunitoSans-Regular'
                                                                                }
                                                                        }>
                                                                        {addOnChoice.prices[0]
                                                                            .toFixed(2)
                                                                            .replace(
                                                                                /(\d)(?=(\d{3})+(?!\d))/g,
                                                                                '$1,',
                                                                            )}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        );
                                                    })}
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '100%' }}>
                                            <View style={{ width: '70%' }} />
                                            <View style={{ width: 15 }} />
                                            {index === item.cartItems.length - 1 ? (
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        //backgroundColor: 'yellow',
                                                        width: '28.65%',
                                                    }}>
                                                    <View
                                                        style={{
                                                            justifyContent: 'center',
                                                            width: '100%',
                                                        }}>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                        : {
                                                                            fontSize: 13,
                                                                            width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                }>
                                                                Subtotal:
                                                            </Text>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                    width: '49.1%',
                                                                }}>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? { fontSize: 10 }
                                                                            : { fontSize: 13, }
                                                                    }>
                                                                    RM
                                                                </Text>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                            : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                    }>
                                                                    {item.totalPrice
                                                                        .toFixed(2)
                                                                        .replace(
                                                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                                                            '$1,',
                                                                        )}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                        {cartItem.orderType === ORDER_TYPE.DELIVERY ? (
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                }}>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10,
                                                                                width: '50.9%',
                                                                                fontFamily: 'Nunitosans-Bold',
                                                                            }
                                                                            : {
                                                                                fontSize: 13,
                                                                                width: '50.9%',
                                                                                fontFamily: 'Nunitosans-Bold',
                                                                            }
                                                                    }>
                                                                    Delivery Fee:
                                                                </Text>
                                                                <View
                                                                    style={{
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'space-between',
                                                                        width: '49.1%',
                                                                    }}>
                                                                    <Text
                                                                        style={
                                                                            switchMerchant
                                                                                ? { fontSize: 10 }
                                                                                : { fontSize: 13, }
                                                                        }>
                                                                        RM
                                                                    </Text>
                                                                    <Text
                                                                        style={
                                                                            switchMerchant
                                                                                ? {
                                                                                    fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                                }
                                                                                : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                        }>
                                                                        {item.deliveryFee
                                                                            .toFixed(2)
                                                                            .replace(
                                                                                /(\d)(?=(\d{3})+(?!\d))/g,
                                                                                '$1,',
                                                                            )}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        ) : (
                                                            <></>)}

                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                        : {
                                                                            fontSize: 13,
                                                                            width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                }>
                                                                Discount:
                                                            </Text>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                    width: '49.1%',
                                                                }}>
                                                                <Text style={switchMerchant ? { fontSize: 10, } : { fontSize: 13 }}>RM</Text>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                            : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                    }>
                                                                    - {item.discount
                                                                        .toFixed(2)
                                                                        .replace(
                                                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                                                            '$1,',
                                                                        )}
                                                                </Text>
                                                            </View>
                                                        </View>

                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                        : {
                                                                            fontSize: 13,
                                                                            width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                }>
                                                                Tax:
                                                            </Text>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                    width: '49.1%',
                                                                }}>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10, paddingRight: 20
                                                                            }
                                                                            : { fontSize: 13, paddingRight: 20 }
                                                                    }>
                                                                    RM
                                                                </Text>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                            : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                    }>
                                                                    {item.tax
                                                                        .toFixed(2)
                                                                        .replace(
                                                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                                                            '$1,',
                                                                        )}
                                                                </Text>
                                                            </View>
                                                        </View>

                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, width: '50.85%', fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                        : {
                                                                            fontSize: 13,
                                                                            width: '50.85%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                }>
                                                                Service Charge:
                                                            </Text>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                    width: switchMerchant ? '49.15%' : '49.1%',
                                                                }}>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10,
                                                                            }
                                                                            : { fontSize: 13, }
                                                                    }>
                                                                    RM
                                                                </Text>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                            : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                    }>
                                                                    {(item.sc || 0)
                                                                        .toFixed(2)
                                                                        .replace(
                                                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                                                            '$1,',
                                                                        )}
                                                                </Text>
                                                            </View>
                                                        </View>


                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10,
                                                                            width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                        : {
                                                                            fontSize: 13,
                                                                            width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                }>
                                                                Total:
                                                            </Text>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                    width: '49.1%',
                                                                }}>
                                                                <Text style={switchMerchant ? { fontSize: 10, } : { fontSize: 13 }}>RM</Text>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                            : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                    }>
                                                                    {item.finalPrice
                                                                        .toFixed(2)
                                                                        .replace(
                                                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                                                            '$1,',
                                                                        )}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>
                                            ) : (
                                                <></>
                                            )}
                                        </View>

                                        {/* <View style={{alignItems:'flex-end'}}>
                            <View style={{ flexDirection: 'row' }}>
                              <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 16 }}>Subtotal: {(Math.ceil(cartItem.price * 20-0.05) /20).toFixed(2)}</Text>
                            </View>
                          </View> */}
                                        {/* {(cartItem.remarks && cartItem.remarks.length > 0) ?
                          <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                            
                            <View style={{ flex: 1, justifyContent: 'center', }}>
                              <Text style={{ fontFamily: 'NunitoSans-SemiBold', fontSize: 15 }}>{cartItem.remarks}</Text>
                            </View>
                            
                          </View>
                          : <></>
                        } */}
                                    </View>
                                );
                            })}
                        </View>
                    ) : null}
                </View>
            </TouchableOpacity>
        )
    };

    const renderItemOrdered = ({ item, index }) => (
        <View style={{ flexDirection: "row", padding: 10 }}>
            <View style={{ width: '3%' }}>
            </View>
            <View style={{ width: "20%", backgroundColor: Colors.fieldtBgColor, padding: 10, justifyContent: "center" }}>
                <Text style={{ fontSize: 10 }} >{item.name}</Text>
                <Text style={{ fontSize: 8, marginLeft: 20, color: "#9c9c9c" }}>{item.item.category.name}</Text>
            </View>
            <Text style={{ width: "1%" }}></Text>
            <View style={{ width: "19%", backgroundColor: Colors.fieldtBgColor, padding: 10, justifyContent: "center" }}>
                <Text >{item.item.name}</Text>
            </View>
            <Text style={{ width: "1%" }}></Text>
            <View style={{ width: "5%", backgroundColor: Colors.fieldtBgColor, padding: 10, justifyContent: "center" }}>
                <Text >{item.quantity}</Text>
            </View>
            <Text style={{ width: "1%" }}></Text>
            <View style={{ width: "15%", backgroundColor: Colors.fieldtBgColor, padding: 10, justifyContent: "center" }}>
                <Text >0.00</Text>
            </View>
            <Text style={{ width: "1%" }}></Text>
            <View style={{ width: "15%", backgroundColor: Colors.fieldtBgColor, padding: 10, justifyContent: "center" }}>
                <Text >{parseFloat(item.price).toFixed(2)}</Text>
            </View>
            <Text style={{ width: "1%" }}></Text>
            <View style={{ width: "13%", backgroundColor: Colors.fieldtBgColor, padding: 10, justifyContent: "center" }}>
                <Text >{parseFloat((item.price * item.quantity) / 100 * 10).toFixed(2)}</Text>
            </View>
        </View>
    );

    const renderItemDetails = ({ item, index }) => (
        (index + 1) % 2 == 0 ?
            <View style={{
                backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
                paddingVertical: switchMerchant ? 5 : 10,
                //paddingHorizontal: 3,
                //paddingLeft: 1,
                borderColor: '#BDBDBD',
                borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
            }}>
                <View style={{ flexDirection: "row", paddingVertical: 10 }}>
                    <Text style={{ width: '10%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{index + 1}</Text>
                    <Text style={{ width: '35%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.itemName}</Text>
                    <Text style={{ width: '10%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{ORDER_TYPE_PARSED[item.orderType]}</Text>
                    <Text style={{ width: '20%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.remarks ? item.remarks : 'N/A'}</Text>
                    <Text style={{ width: '10%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.quantity.toFixed(0)}</Text>
                    <Text style={{ width: '15%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'right', paddingLeft: 10, paddingRight: 15 }}>{item.price.toFixed(2)}</Text>
                </View>
            </View>
            : <View style={{
                backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
                paddingVertical: switchMerchant ? 5 : 10,
                //paddingHorizontal: 3,
                //paddingLeft: 1,
                borderColor: '#BDBDBD',
                borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
            }}>
                <View style={{ flexDirection: "row", paddingVertical: 10 }}>
                    <Text style={{ width: '10%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{index + 1}</Text>
                    <Text style={{ width: '35%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.itemName}</Text>
                    <Text style={{ width: '10%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{ORDER_TYPE_PARSED[item.orderType]}</Text>
                    <Text style={{ width: '20%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.remarks ? item.remarks : 'N/A'}</Text>
                    <Text style={{ width: '10%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.quantity.toFixed(0)}</Text>
                    <Text style={{ width: '15%', fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular', textAlign: 'right', paddingLeft: 10, paddingRight: 15 }}>{item.price.toFixed(2)}</Text>
                </View>
            </View>
    )

    const onItemSummaryClicked = item => {
        // setTransactionTypeSalesDetails(item.cartItems);
        //setSelectedItemSummary(item);
        //setShowDetails(true);

        // setCurrentPage(1);
        // setPageCount(Math.ceil(item.cartItems.length / perPage));

        //console.log('item.cartItems');
        //console.log(item.cartItems);
        setExpandDetailsDict({
            ...expandDetailsDict,
            [item.uniqueId]: expandDetailsDict[item.uniqueId] ? false : true,
        });
    };

    const changeClick = () => {
        if (day == true) {
            setState({ day: false })
        }
        else
            setState({ day: true })
    }

    const convertDataToExcelFormat = () => {
        var excelData = [];

        if (!showDetails) {
            for (var i = 0; i < allTransactions.length; i++) {
                var excelRow = {
                    // 'Transaction Category': transactionTypeSales[i].orderType,
                    // 'Total Sales (RM)': parseFloat(transactionTypeSales[i].totalSales).toFixed(2),
                    // 'Total Transaction': transactionTypeSales[i].totalTransactions,
                    // 'Total Discount (RM)': parseFloat(transactionTypeSales[i].totalDiscount).toFixed(2),
                    // 'Discount (%)': parseFloat(transactionTypeSales[i].discount).toFixed(2),
                    // 'Tax (RM)': parseFloat(transactionTypeSales[i].tax).toFixed(2),
                    // 'Tax (RM)': parseFloat(transactionTypeSales[i].serviceCharge).toFixed(2),
                    // 'GP (%)': parseFloat(transactionTypeSales[i].gp).toFixed(2),
                    // 'Net Sales (RM)': parseFloat(transactionTypeSales[i].netSales).toFixed(2),
                    // 'Average Net Sales (RM)': parseFloat(transactionTypeSales[i].averageNetSales).toFixed(2),

                    'Transaction Date': moment(allTransactions[i].createdAt).format('DD MMM hh:mm A'),
                    'Outlet': allTransactions[i].outletName,
                    'Employee': allTransactions[i].waiterName,
                    'Customer': allTransactions[i].userName,
                    'Transaction Category': ORDER_TYPE_PARSED[allTransactions[i].orderType],
                    'Courier': allTransactions.courierCode ? allTransactions.courierCode : 'N/A',
                    'Discount (%)': +parseFloat(allTransactions[i].discount).toFixed(2),
                    'Tax (RM)': +parseFloat(allTransactions[i].tax || 0).toFixed(2),
                    // 'Net Sales (RM)': parseFloat(allTransactions[i].netSales).toFixed(2),
                    'Net Sales (RM)': +parseFloat(allTransactions[i].finalPrice).toFixed(2),
                };

                excelData.push(excelRow);
            }
        }
        else {
            for (var i = 0; i < transactionTypeSalesDetails.length; i++) {
                var excelRow = {
                    'Transaction Category': ORDER_TYPE_PARSED[transactionTypeSalesDetails[i].orderType],
                    'Sales (RM)': +parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2),
                    'Transaction Date': moment(transactionTypeSalesDetails[i].createdAt).format('DD MMM hh:mma'),
                    'Total Discount (RM)': +parseFloat(transactionTypeSalesDetails[i].discount).toFixed(2),
                    'Discount (%)': +parseFloat(isFinite(transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount) ? transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount * 100 : 0).toFixed(2),
                    'Tax (RM)': +parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2),
                    'Service Charge (RM)': +parseFloat(transactionTypeSalesDetails[i].sc || 0).toFixed(2),
                    'GP (%)': +parseFloat(0).toFixed(2),
                    'Net Sales (RM)': +parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2),
                };

                excelData.push(excelRow);
            }
        }

        console.log('excelData');
        console.log(excelData);

        return excelData;
    };

    // const downloadExcel = () => {
    //     const excelData = convertDataToExcelFormat();

    //     var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
    //     var excelWorkSheet = XLSX.utils.json_to_sheet(excelData);
    //     var excelWorkBook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, "Product Sales Report");

    //     const workBookData = XLSX.write(excelWorkBook, {
    //         type: 'binary',
    //         bookType: 'xlsx',
    //     });

    //     RNFS.writeFile(excelFile, workBookData, 'ascii')
    //         .then((success) => {
    //             console.log(`wrote file ${excelFile}`);

    //             Alert.alert(
    //                 'Success',
    //                 `Sent to ${excelFile}`,
    //                 [{ text: 'OK', onPress: () => { } }],
    //                 { cancelable: false },
    //             );
    //         })
    //         .catch((err) => {
    //             console.log(err.message);
    //         });

    //     // XLSX.writeFileAsync(excelFile, excelWorkBook, () => {
    //     //     Alert.alert(
    //     //         'Success',
    //     //         `Send to ${excelFile}`,
    //     //         [{ text: 'OK', onPress: () => { } }],
    //     //         { cancelable: false },
    //     //     );
    //     // });

    //     // const csvData = convertArrayToCSV(CsvData);

    //     // const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
    //     // console.log("PATH", excelFile);
    //     // RNFetchBlob.fs
    //     //     .writeFile(excelFile, excelWorkBook, 'utf8')
    //     //     .then(() => {
    //     //         console.log(`wrote file ${excelFile}`);
    //     //         Alert.alert(
    //     //             'Success',
    //     //             `Send to ${excelFile}`,
    //     //             [{ text: 'OK', onPress: () => { } }],
    //     //             { cancelable: false },
    //     //         );
    //     //     })
    //     //     .catch(error => console.error(error));
    // };

    var leftSpacing = '0%';

    if (windowWidth >= 1280) {
        leftSpacing = '0%';
    }

    const leftSpacingScale = {
        marginLeft: leftSpacing,
    };

    const flatListRef = useRef();

    const ScrollToTop = () => {
        flatListRef.current.scrollToOffset({ animated: true, offset: 0 })
    }

    const ScrollToBottom = () => {
        flatListRef.current.scrollToOffset({ animated: true, offset: 100 })
    }

    // function end

    return (
        // <View style={styles.container}>
        //     <View style={styles.sidebar}>
        <View style={[styles.container,
            {
              height: windowHeight,
              width: windowWidth,
            }]}>
            <View style={[styles.sidebar, { flex: 0.8}]}>
                <SideBar navigation={props.navigation} selectedTab={7} expandTransactions={true} />
            </View>
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={{ height: windowHeight, width: windowWidth * 0.9, flex: 9 }}
                contentContainerStyle={{
                    paddingBottom: windowHeight * 0.1,
                }}
            >
                    <Modal
                        style={{}}
                        visible={exportModalVisibility}
                        supportedOrientations={['portrait', 'landscape']}
                        transparent={true}
                        animationType={'fade'}>
                        <View style={{
                            flex: 1,
                            backgroundColor: Colors.modalBgColor,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <View style={{
                                height: windowHeight * 0.3,
                                width: windowWidth * 0.4,
                                backgroundColor: Colors.whiteColor,
                                borderRadius: 12,
                                padding: windowWidth * 0.03,
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <TouchableOpacity
                                    disabled={isLoading}
                                    style={{
                                        position: 'absolute',
                                        right: windowWidth * 0.02,
                                        top: windowWidth * 0.02,

                                        elevation: 1000,
                                        zIndex: 1000,
                                    }}
                                    onPress={() => {
                                        setExportModalVisibility(false);
                                    }}>
                                    <AntDesign name="closecircle" size={switchMerchant ? 15 : 25} color={Colors.fieldtTxtColor} />
                                </TouchableOpacity>
                                <View style={{
                                    alignItems: 'center',
                                    top: '20%',
                                    position: 'absolute',
                                }}>
                                    <Text style={{
                                        fontFamily: 'NunitoSans-Bold',
                                        textAlign: 'center',
                                        fontSize: switchMerchant ? 18 : 20,
                                    }}>
                                        Download Report
                                    </Text>
                                </View>
                                <View style={{ top: switchMerchant ? '14%' : '10%' }}>
                                    <Text style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Email Address:</Text>
                                    <TextInput
                                        underlineColorAndroid={Colors.fieldtBgColor}
                                        style={{
                                            backgroundColor: Colors.fieldtBgColor,
                                            width: switchMerchant ? 240 : 370,
                                            height: switchMerchant ? 35 : 50,
                                            borderRadius: 5,
                                            padding: 5,
                                            marginVertical: 5,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                            paddingLeft: 10,
                                            fontSize: switchMerchant ? 10 : 16,
                                        }}
                                        placeholderStyle={{ padding: 5 }}
                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                        placeholder="Enter Your Email"
                                        onChangeText={(text) => {
                                            setExportEmail(text);
                                        }}
                                        value={exportEmail}
                                    />
                                    <Text style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', marginTop: 15, }}>Send As:</Text>

                                    <View style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                        marginTop: 10,
                                    }}>
                                        {/* <TouchableOpacity
                                            disabled={isLoading}
                                            style={{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 1,
                                                borderColor: Colors.primaryColor,
                                                backgroundColor: '#4E9F7D',
                                                borderRadius: 5,
                                                width: switchMerchant ? 100 : 100,
                                                paddingHorizontal: 10,
                                                height: switchMerchant ? 35 : 40,
                                                alignItems: 'center',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                                marginRight: 15,
                                            }}
                                            onPress={() => {
                                                if (exportEmail.length > 0) {
                                                    CommonStore.update(s => {
                                                        s.isLoading = true;
                                                    });

                                                    setIsExcel(true);

                                                    const excelData = convertDataToExcelFormat();

                                                    // generateEmailReport(
                                                    //     EMAIL_REPORT_TYPE.EXCEL,
                                                    //     excelData,
                                                    //     'KooDoo Transactions Report',
                                                    //     'KooDoo Transactions Report.xlsx',
                                                    //     `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                                                    //     exportEmail,
                                                    //     'KooDoo Transactions Report',
                                                    //     'KooDoo Transactions Report',
                                                    //     () => {
                                                    //         CommonStore.update(s => {
                                                    //             s.isLoading = false;
                                                    //         });

                                                    //         setIsExcel(false);

                                                    //         Alert.alert('Success', 'Report will be sent to the email address soon.');

                                                    //         setExportModalVisibility(false);
                                                    //     },
                                                    // );
                                                }
                                                else {
                                                    Alert.alert('Info', 'Invalid email address.');
                                                }
                                            }}>
                                            {
                                                (isLoading && isExcel)
                                                    ?
                                                    <ActivityIndicator
                                                        size={'small'}
                                                        color={Colors.whiteColor}
                                                    />
                                                    :
                                                    <Text style={{
                                                        color: Colors.whiteColor,
                                                        //marginLeft: 5,
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        fontFamily: 'NunitoSans-Bold',
                                                    }}>
                                                        EXCEL</Text>

                                            }
                                        </TouchableOpacity>

                                        <TouchableOpacity
                                            style={{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 1,
                                                borderColor: Colors.primaryColor,
                                                backgroundColor: '#4E9F7D',
                                                borderRadius: 5,
                                                width: switchMerchant ? 100 : 100,
                                                paddingHorizontal: 10,
                                                height: switchMerchant ? 35 : 40,
                                                alignItems: 'center',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                            }}
                                            onPress={() => {
                                                if (exportEmail.length > 0) {
                                                    CommonStore.update(s => {
                                                        s.isLoading = true;
                                                    });

                                                    setIsCsv(true);

                                                    const csvData = convertArrayToCSV(allTransactions);

                                                    // generateEmailReport(
                                                    //     EMAIL_REPORT_TYPE.CSV,
                                                    //     csvData,
                                                    //     'KooDoo Transactions Report',
                                                    //     'KooDoo Transactions Report.csv',
                                                    //     `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                                                    //     exportEmail,
                                                    //     'KooDoo Transactions Report',
                                                    //     'KooDoo Transactions Report',
                                                    //     () => {
                                                    //         CommonStore.update(s => {
                                                    //             s.isLoading = false;
                                                    //         });

                                                    //         setIsCsv(false);

                                                    //         Alert.alert('Success', 'Report will be sent to the email address soon.');

                                                    //         setExportModalVisibility(false);
                                                    //     },
                                                    // );
                                                }
                                                else {
                                                    Alert.alert('Info', 'Invalid email address.');
                                                }
                                            }}>
                                            {
                                                (isLoading && isCsv)
                                                    ?
                                                    <ActivityIndicator
                                                        size={'small'}
                                                        color={Colors.whiteColor}
                                                    />
                                                    :
                                                    <Text style={{
                                                        color: Colors.whiteColor,
                                                        //marginLeft: 5,
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        fontFamily: 'NunitoSans-Bold',
                                                    }}>
                                                        CSV</Text>

                                            }
                                        </TouchableOpacity> */}

                                    <CSVLink
                                        style={{
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            display: "inline-block",
                                            flexDirection: "row",
                                            textDecoration: 'none',
                                            borderWidth: 1,
                                            borderColor: Colors.primaryColor,
                                            backgroundColor: "#4E9F7D",
                                            borderRadius: 5,
                                            width: switchMerchant ? 100 : 100,
                                            paddingHorizontal: 10,
                                            height: switchMerchant ? 35 : 40,
                                            alignItems: "center",
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 1,
                                            zIndex: -1,
                                        }}
                                        data={convertDataToExcelFormat()}
                                        filename="report.csv"
                                    >
                                        <View
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                alignContent: "center",
                                                alignItems: "center",
                                                alignSelf: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: Colors.whiteColor,
                                                    //marginLeft: 5,
                                                    fontSize: switchMerchant ? 10 : 16,
                                                    fontFamily: "NunitoSans-Bold",
                                                }}
                                            >
                                                CSV
                                            </Text>
                                        </View>
                                    </CSVLink>

                                        {/* <TouchableOpacity
                                            style={[styles.modalSaveButton, {
                                                zIndex: -1
                                            }]}
                                            onPress={() => { downloadPDF() }}>
                                            <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>PDF</Text>
                                        </TouchableOpacity> */}
                                    </View>
                                </View>
                            </View>
                        </View>
                    </Modal>

                    <View
                        style={{
                            // backgroundColor: 'red',
                            padding: 20,
                            width: windowWidth * (1 - Styles.sideBarWidth),
                            // alignItems: 'center',
                        }}>
                        <DateTimePickerModal
                            isVisible={showDateTimePicker}
                            mode={'date'}
                            onConfirm={(text) => {
                                setRev_date(moment(text).startOf('day'));
                                setShowDateTimePicker(false);
                            }}
                            onCancel={() => {
                                setShowDateTimePicker(false);
                            }}
                            maximumDate={moment(rev_date1).toDate()}
                        />

                        <DateTimePickerModal
                            isVisible={showDateTimePicker1}
                            mode={'date'}
                            onConfirm={(text) => {
                                setRev_date1(moment(text).endOf('day'));
                                setShowDateTimePicker1(false);
                            }}
                            onCancel={() => {
                                setShowDateTimePicker1(false);
                            }}
                            minimumDate={moment(rev_date).toDate()}
                        />
                        {day ?
                            <View style={{ position: 'absolute', width: "24%", backgroundColor: Colors.whiteColor, left: 440, top: 170, zIndex: 6000 }}>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.primaryColor }} onPress={() => { setState({ rev_date: moment(currentDateTime).format("DD MMM yyyy"), rev_date1: moment(currentDateTime).format("DD MMM yyyy") }) }}>
                                    <Text style={{ color: Colors.whiteColor }}>Today</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { setState({ rev_date: moment(moment(currentDateTime).subtract(1, "day")).format("DD MMM yyyy"), rev_date1: moment(currentDateTime).format("DD MMM yyyy") }) }}>
                                    <Text style={{ color: "#828282" }}>Yesterday</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { setState({ rev_date: moment(moment(currentDateTime).subtract(7, "day")).format("DD MMM yyyy"), rev_date1: moment(currentDateTime).format("DD MMM yyyy") }) }}>
                                    <Text style={{ color: "#828282" }}>Last 7 days</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { setState({ rev_date: moment(moment(currentDateTime).subtract(1, "month")).format("DD MMM yyyy"), rev_date1: moment(currentDateTime).format("DD MMM yyyy") }) }}>
                                    <Text style={{ color: "#828282" }}>Last 30 days</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { setState({ rev_date: moment(currentDateTime).startOf('month'), rev_date1: moment(currentDateTime).endOf("month") }) }}>
                                    <Text style={{ color: "#828282" }}>This month</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }} onPress={() => { setState({ rev_date: moment(moment(currentDateTime).subtract(1, "month")).startOf('month'), rev_date1: moment(moment(currentDateTime).subtract(1, "month")).endOf("month") }) }}>
                                    <Text style={{ color: "#828282" }}>Last month</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ padding: 10, backgroundColor: Colors.whiteColor }}>
                                    <Text style={{ color: "#828282" }}>Custom range</Text>
                                </TouchableOpacity>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ flex: 1, marginLeft: 25 }}>
                                        <Text style={{ color: "#828282" }}>From</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text style={{ color: "#828282" }}>To</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <TouchableOpacity style={{ width: "38%", marginLeft: 25, borderWidth: 1, alignItems: "center", borderColor: "#919191", backgroundColor: Colors.fieldtBgColor }}
                                        onPress={() => { setState({ pick: 1, pick1: 0, pickerMode: 'date', showDateTimePicker: true }) }}>
                                        <Text style={{ fontSize: 12 }}>{moment(rev_date).format("DD MMM yyyy")}</Text>
                                    </TouchableOpacity>
                                    <View style={{ width: "8%" }}>
                                    </View>
                                    <TouchableOpacity style={{ width: "38%", borderWidth: 1, alignItems: "center", borderColor: "#919191", backgroundColor: Colors.fieldtBgColor }}
                                        onPress={() => { setState({ pick: 0, pick1: 1, pickerMode: 'date', showDateTimePicker: true }) }}>
                                        <Text style={{ fontSize: 12 }}>{moment(rev_date1).format("DD MMM yyyy")}</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ flexDirection: 'row', marginTop: 20 }}>
                                    <TouchableOpacity style={{ width: "38%", marginLeft: 15, borderWidth: 1, alignItems: "center", borderColor: "#919191", backgroundColor: Colors.whiteColor, height: 30, borderRadius: 5, justifyContent: "center", alignItems: 'center' }}
                                        onPress={() => { setState({ day: false }) }}>
                                        <Text style={{ fontSize: 15, color: "#919191" }}>Cancel</Text>
                                    </TouchableOpacity>
                                    <View style={{ width: "8%" }}>
                                    </View>
                                    <TouchableOpacity style={{ width: "38%", borderWidth: 1, alignItems: "center", borderColor: Colors.primaryColor, backgroundColor: Colors.primaryColor, height: 30, borderRadius: 5, justifyContent: "center", alignItems: 'center' }}
                                        onPress={() => { getDetail(moment(rev_date).format("YYYY-MM-DD"), moment(rev_date1).format("YYYY-MM-DD")); setState({ day: false }) }}>
                                        <Text style={{ fontSize: 15, color: Colors.whiteColor }}>Apply</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ height: 20 }}>
                                </View>
                            </View>
                            : null}
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginHorizontal: switchMerchant ? 0 : 10, alignItems: 'center', width: windowWidth * 0.87, alignSelf: 'center' }}>
                            <Text style={{ marginTop: 10, marginLeft: 0, fontSize: switchMerchant ? 20 : 26, fontFamily: 'NunitoSans-Bold' }}>{(pageStack.length > 1 && pageStack[pageStack.length - 2] === 'Dashboard') ? 'Today Transaction' : 'All Transactions'}</Text>
                            <View
                                style={{
                                    alignItem: 'center',
                                    flexDirection: 'row',
                                    // backgroundColor: 'red',
                                    justifyContent: 'flex-end',
                                    //marginLeft: (windowWidth * 0.75),
                                }}>
                                <TouchableOpacity
                                    style={{
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                        borderWidth: 1,
                                        borderColor: Colors.primaryColor,
                                        backgroundColor: '#4E9F7D',
                                        borderRadius: 5,
                                        //width: 160,
                                        paddingHorizontal: 10,
                                        height: switchMerchant ? 35 : 40,
                                        alignItems: 'center',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 1,
                                        zIndex: -1,
                                    }}
                                    onPress={() => { setExportModalVisibility(true) }}>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                        <Icon name="download" size={switchMerchant ? 10 : 20} color={Colors.whiteColor} />
                                        <Text style={{
                                            color: Colors.whiteColor,
                                            marginLeft: 5,
                                            fontSize: switchMerchant ? 10 : 16,
                                            fontFamily: 'NunitoSans-Bold',
                                        }}>
                                            DOWNLOAD
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                                <View style={{
                                    paddingHorizontal: 15, flexDirection: 'row',
                                    alignItems: 'center', borderRadius: 10, paddingVertical: 10, justifyContent: 'center',
                                    backgroundColor: Colors.whiteColor,
                                    shadowOpacity: 0,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                    zIndex: 1,
                                    marginLeft: '5%',
                                    /* ...(pageStack.length > 1 && pageStack[pageStack.length - 2] === 'Dashboard') && {
                                        display: 'none',
                                    }, */
                                }}
                                >
                                    <View style={{ alignSelf: "center", marginRight: 5 }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                        <GCalendar width={switchMerchant ? 15 : 20} height={switchMerchant ? 15 : 20} />
                                    </View>

                                    {/* <TouchableOpacity onPress={() => {
                                        setShowDateTimePicker(true);
                                        setShowDateTimePicker1(false);
                                    }} style={{
                                        marginHorizontal: 4,
                                    }}>
                                        <Text style={switchMerchant ? { fontSize: 10, fontFamily: 'NunitoSans-Regular' } : { fontFamily: "NunitoSans-Regular" }}>{moment(rev_date).format("DD MMM yyyy")}</Text>
                                    </TouchableOpacity> */}

                                <DatePicker
                                    selected={rev_date.toDate()}
                                    onChange={(date) => {
                                        setRev_date(moment(date).startOf('day'));
                                    }}
                                    maxDate={moment(rev_date1).toDate()}
                                />

                                <Text
                                    style={{
                                        fontFamily: "NunitoSans-Regular",
                                        paddingHorizontal: 5,
                                        marginLeft: 5,
                                    }}
                                >
                                    -
                                </Text>

                                <DatePicker
                                    selected={rev_date1.toDate()}
                                    onChange={(date) => {
                                        setRev_date1(moment(date).endOf('day'));
                                    }}
                                    minDate={moment(rev_date).toDate()}
                                />

                                    {/* <TouchableOpacity onPress={() => {
                                        setShowDateTimePicker(false);
                                        setShowDateTimePicker1(true);
                                    }} style={{
                                        marginHorizontal: 4,
                                    }}>
                                        <Text style={switchMerchant ? { fontSize: 10, fontFamily: 'NunitoSans-Regular' } : { fontFamily: "NunitoSans-Regular" }}>{moment(rev_date1).format("DD MMM yyyy")}</Text>
                                    </TouchableOpacity> */}


                                </View>
                            </View>
                        </View>
                        {/* <Text style={{ marginTop: 10, marginLeft: 30, fontSize: 15, color: "#bababa", fontFamily: 'NunitoSans-Bold' }}>Date last updated on {moment(currentDateTime).format("DD MMM yyyy")}, {moment(currentDateTime).format("HH:mm a")}</Text> */}
                        {showlist ?
                            <View>
                                <View style={{
                                    flexDirection: "row",
                                    marginTop: 15,
                                    justifyContent: 'space-between',
                                    shadowOpacity: 0,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                    marginHorizontal: switchMerchant ? 0 : 10,
                                    marginBottom: 10
                                }}>

                                    <TouchableOpacity
                                        style={[leftSpacingScale, {
                                            justifyContent: 'center',
                                            flexDirection: 'row',
                                            borderWidth: 1,
                                            borderColor: Colors.primaryColor,
                                            backgroundColor: '#4E9F7D',
                                            borderRadius: 5,
                                            //width: 160,
                                            paddingHorizontal: 10,
                                            height: switchMerchant ? 30 : 40,
                                            alignItems: 'center',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 1,
                                            zIndex: -1,
                                            opacity: !showDetails ? 0 : 100,
                                        }]}
                                        onPress={() => {
                                            setShowDetails(false);
                                            setCurrentPage(pageReturn);
                                            console.log('Returning to page')
                                            console.log(pageReturn)
                                            setPageCount(Math.ceil(allTransactions.length / perPage));
                                            setCurrReportSummarySort('')
                                            setCurrReportDetailsSort('')
                                        }}
                                        disabled={!showDetails}
                                    >
                                        <AntDesign name="arrowleft" size={switchMerchant ? 10 : 20} color={Colors.whiteColor} style={{
                                            top: -1,
                                            //marginRight: -5,
                                        }} />
                                        <Text style={{
                                            color: Colors.whiteColor,
                                            marginLeft: 5,
                                            fontSize: switchMerchant ? 10 : 16,
                                            fontFamily: 'NunitoSans-Bold',
                                        }}>
                                            Summary</Text>
                                    </TouchableOpacity>

                                    <View style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        {/* <TouchableOpacity style={{ marginRight: 10, width: 230, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.040, backgroundColor: Colors.whiteColor }}
                                        onPress={() => { changeClick() }}>
                                        <TouchableOpacity style={{ alignSelf: "center" }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                            <EvilIcons name="calendar" size={25} color={Colors.primaryColor} />
                                        </TouchableOpacity>
                                        <Text style={{ fontFamily: "NunitoSans-Regular" }}>{moment(rev_date).format("DD MMM yyyy")} - {moment(rev_date1).format("DD MMM yyyy")}</Text>
                                    </TouchableOpacity> */}

                                        {/* <View style={{
                                    flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 10, paddingLeft: 10, marginHorizontal: 10, height: 40, backgroundColor: Colors.whiteColor, shadowOpacity: 0,
                                    shadowOpacity: 0,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                }}>                                    
                                    <Text style={{ fontSize: 16, borderRightWidth: StyleSheet.hairlineWidth, width: 70, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold', }}>View By</Text>
                                    <DropDownPicker
                                        arrowColor={Colors.primaryColor}
                                        arrowSize={19}
                                        style={{ width: windowWidth * 0.125, borderTopRightRadius: 10, borderBottomRightRadius: 10, borderColor: Colors.whiteColor }}
                                        items={[{ label: 'Time', value: 1 }, { label: 'Outlet', value: 2 }, { label: 'Employee', value: 3 }, { label: 'Type', value: 4 }, { label: 'Subtotal', value: 5 }, { label: 'Discount', value: 6 }, { label: 'Tax', value: 7 }, { label: 'Net Sales', value: 8 }]}
                                        placeholder={"All Outlet"}
                                        placeholderStyle={{ color: 'black' }}
                                        onChangeItem={selectedSort => {
                                            setState({ sort: selectedSort }),
                                                sortingOrders(selectedSort);

                                        }
                                        }
                                    />
                                </View> */}


                                        {/* <View style={{
                                            paddingHorizontal:15, flexDirection: 'row',
                                            alignItems: 'center', borderRadius: 10, paddingVertical:10, justifyContent: 'center',
                                            backgroundColor: Colors.whiteColor,
                                            shadowOpacity: 0,
                                            shadowColor: '#000',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 1,
                                            zIndex: 1
                                            
                                        }}
                                        >
                                            <View style={{ alignSelf: "center", marginRight: 5 }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                                <GCalendar width={switchMerchant ? 10 : 20} height={switchMerchant ? 10 : 20} />
                                            </View>

                                            <TouchableOpacity onPress={() => {
                                                setShowDateTimePicker(true);
                                                setShowDateTimePicker1(false);
                                            }} style={{
                                                marginHorizontal: 4,
                                            }}>
                                                <Text style={switchMerchant ? { fontSize: 10, fontFamily: 'NunitoSans-Regular' } : { fontFamily: "NunitoSans-Regular" }}>{moment(rev_date).format("DD MMM yyyy")}</Text>
                                            </TouchableOpacity>

                                            <Text style={switchMerchant ? { fontSize: 10, fontFamily: 'NunitoSans-Regular' } : { fontFamily: "NunitoSans-Regular" }}>-</Text>

                                            <TouchableOpacity onPress={() => {
                                                setShowDateTimePicker(false);
                                                setShowDateTimePicker1(true);
                                            }} style={{
                                                marginHorizontal: 4,
                                            }}>
                                                <Text style={switchMerchant ? { fontSize: 10, fontFamily: 'NunitoSans-Regular' } : { fontFamily: "NunitoSans-Regular" }}>{moment(rev_date1).format("DD MMM yyyy")}</Text>
                                            </TouchableOpacity>
                                        </View> */}


                                        {/* <TouchableOpacity style={{
                                    width: 100, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: 40, backgroundColor: Colors.whiteColor,
                                    shadowOpacity: 0,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                }}
                                    onPress={() => { email1(list) }}>
                                    <AntDesign name="upload" size={20} color={Colors.primaryColor} />
                                    <Text style={{ fontFamily: "NunitoSans-Regular", marginLeft: 10 }}>Email</Text>
                                </TouchableOpacity> */}

                                        {/* {
                                        (pageStack.length > 1 && pageStack[pageStack.length - 2] === 'Dashboard')
                                            ?
                                            <TouchableOpacity
                                                style={{
                                                    marginLeft: 10,
                                                    paddingHorizontal: 10, backgroundColor: Colors.whiteColor, height: 40, width: 140, alignItems: 'center', borderRadius: 7, flexDirection: 'row', justifyContent: 'center', shadowColor: '#000',
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.22,
                                                    shadowRadius: 3.22,
                                                    elevation: 1,
                                                }}
                                                onPress={() => {
                                                    navigation.navigate('Dashboard');
                                                }}
                                            >
                                                <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, }}>Dashboard</Text>
                                            </TouchableOpacity>
                                            :
                                            <></>
                                    } */}
                                    </View>

                                </View>

                                <View style={{
                                    backgroundColor: Colors.whiteColor,
                                    width: windowWidth * 0.87,
                                    height: windowHeight * 0.67,
                                    //marginTop: 30,
                                    marginHorizontal: 30,
                                    marginBottom: 15,
                                    alignSelf: 'center',
                                    borderRadius: 5,
                                    shadowOpacity: 0,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 3,

                                }}>
                                    <View style={{ paddingVertical: 10, flexDirection: 'row', }}>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '4.5%' : '5%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Text numberOfLines={1} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>No.</Text>
                                                </View>
                                                <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                            {/* <View style={{ marginLeft: 5 }}>
                                                <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC)}>
                                                    <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                </TouchableOpacity>

                                                <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC)}>
                                                    <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                </TouchableOpacity>
                                            </View> */}
                                        </View>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '11%' : '11%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC)
                                                    }
                                                }}>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Text numberOfLines={1} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Date</Text>
                                                    </View>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: 5 }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '10.6%' : '13%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Outlet</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor, }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: 5 }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '10.75%' : '10%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_EMPLOYEE_NAME_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_EMPLOYEE_NAME_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_EMPLOYEE_NAME_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Employee</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor, }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: 5 }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_EMPLOYEE_NAME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_EMPLOYEE_NAME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '10.75%' : '10%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_USER_NAME_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_USER_NAME_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_USER_NAME_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Customer</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor, }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: 5 }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_USER_NAME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_USER_NAME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '9%' : '9%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TYPE_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TYPE_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TYPE_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Type</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor, }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: 5 }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TYPE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TYPE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor, }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '9.5%' : '10%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_COURIER_NAME_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_COURIER_NAME_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_COURIER_NAME_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Courier</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor, }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: 5 }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_COURIER_NAME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_COURIER_NAME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '9.75%' : '9%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Subtotal</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor, }}>RM</Text>
                                                </TouchableOpacity>
                                            </View>

                                            <View style={{ marginLeft: 5 }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor, }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '7%' : '7%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Disc</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor, }}>%</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: 5 }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor, }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '6.75%' : '7%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Tax</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor, }}>RM</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: 5 }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor, }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', paddingLeft: 10, width: switchMerchant ? '10.4%' : '9%' }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Net Sales</Text>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.primaryColor }}> *incl tax</Text>
                                                    </View>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: 5 }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor, }}></Text>
                                            </View>
                                        </View>
                                    </View>

                                    {allTransactions.length > 0 ?
                                        <FlatList
                                            showsVerticalScrollIndicator={false}
                                            ref={flatListRef}
                                            // data={list}
                                            data={sortReportDataList(allTransactions, currReportSummarySort).filter(item => {
                                                const Search = search.toLocaleLowerCase();

                                                if (search !== '') {
                                                    if (item.waiterName !== '') {
                                                        if (item.waiterName.toLowerCase().includes(Search)) {
                                                            return true;
                                                        }
                                                    }
                                                }
                                                else {
                                                    return true;
                                                }

                                            }).slice((currentPage - 1) * perPage, currentPage * perPage)}
                                            renderItem={renderList}
                                            keyExtractor={(item, index) => String(index)}
                                        />
                                        :
                                        <View style={{ alignItems: 'center', justifyContent: 'center', height: '71%' }}>
                                            <Text style={{ color: Colors.descriptionColor }}>- No Data Available -</Text>
                                        </View>
                                    }

                                </View>

                                <View style={{
                                    flexDirection: 'row',
                                    width: windowWidth * 0.87,
                                    alignItems: 'center', alignSelf: 'center',
                                    justifyContent: 'flex-end',
                                    paddingBottom: 20,
                                }}>
                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%'
                                    }}>Items Showed</Text>
                                    <View style={{
                                        width: Platform.OS === 'ios' ? 65 : '13%', //65,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: Platform.OS === 'ios' ? 0 : 0,
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                        marginRight: '1%',
                                    }}>
                                    <Picker
                                        selectedValue={perPage}
                                        style={{
                                            inputIOS: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', textAlign: 'center' },
                                                inputAndroid: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', justifyContent: 'center', textAlign: 'center', height: 40, color: 'black' },
                                                inputAndroidContainer: { width: '100%' },
                                                height: 35,
                                                borderRadius: 5,

                                                chevronContainer: {
                                                    display: "none",
                                                },
                                                chevronDown: {
                                                    display: "none",
                                                },
                                                chevronUp: {
                                                    display: "none",
                                                },
                                        }}
                                        onValueChange={(value, text) => {
                                            setPerPage(value);
                                        }}
                                    >
                                        {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                                            label: 'All',
                                            value: !showDetails ? allTransactions.length : transactionTypeSalesDetails.length,
                                        }).map((value, index) => {
                                            return (
                                                <Picker.Item
                                                    key={index}
                                                    label={value.label}
                                                    value={value.value}
                                                />
                                            );
                                        })}
                                    </Picker>
                                    </View>

                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%' }}>Page</Text>
                                    <View style={{
                                        width: switchMerchant ? 65 : 70,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: 22,
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                    }}>
                                        {console.log('currentPage')}
                                        {console.log(currentPage)}

                                        <TextInput
                                            onChangeText={(text) => {
                                                // setState({ exportEmail: text });
                                                var currentPageTemp = text.length > 0 ? parseInt(text) : 1;

                                                setCurrentPage(currentPageTemp > pageCount ? pageCount : (currentPageTemp < 1 ? 1 : currentPageTemp));

                                            }}
                                            placeholder={pageCount !== 0 ? currentPage.toString() : '0'}
                                            placeholderTextColor={Platform.select({
                                                ios: '#a9a9a9'
                                            })}
                                            style={{
                                                color: 'black',
                                                fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular',
                                                marginTop: Platform.OS === 'ios' ? 0 : -15,
                                                marginBottom: Platform.OS === 'ios' ? 0 : -15,
                                                textAlign: 'center',
                                                width: '100%',
                                            }}
                                            value={pageCount !== 0 ? currentPage.toString() : '0'}
                                            defaultValue={pageCount !== 0 ? currentPage.toString() : '0'}
                                            keyboardType={'numeric'}
                                            onFocus={() => { setPushPagingToTop(true) }}
                                        />
                                    </View>
                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginLeft: '1%', marginRight: '1%' }}>of {pageCount}</Text>
                                    <TouchableOpacity style={{ width: switchMerchant ? 30 : 45, height: switchMerchant ? 20 : 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }}
                                        onPress={() => { prevPage() }}>
                                        {/* <MaterialIcons name='keyboard-arrow-left' size={switchMerchant ? 20 : 25} style={{ color: Colors.whiteColor }} /> */}
                                        <ArrowLeft color={Colors.whiteColor} />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ width: switchMerchant ? 30 : 45, height: switchMerchant ? 20 : 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }} onPress={() => { nextPage() }}>
                                        {/* <MaterialIcons name='keyboard-arrow-right' size={switchMerchant ? 20 : 25} style={{ color: Colors.whiteColor }} /> */}
                                        <ArrowRight color={Colors.whiteColor} />
                                    </TouchableOpacity>
                                </View>

                            </View>
                            : null}

                        {showDetail ? <ScrollView>
                            <Modal
                                style={{ flex: 1 }}
                                visible={visible}
                                transparent={true}
                                animationType="slide">
                                <View
                                    style={{
                                        backgroundColor: 'rgba(0,0,0,0.5)',
                                        flex: 1,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        minHeight: Dimensions.get('window').height,
                                    }}>
                                    <View style={styles.confirmBox}>
                                        <View style={{ marginTop: 40 }}>
                                            <Text
                                                style={{
                                                    textAlign: 'center',
                                                    fontWeight: '700',
                                                    fontSize: 18,
                                                }}>
                                                Cancel Transaction
                                            </Text>
                                        </View>
                                        <View style={{ marginTop: 20 }}>
                                            <Text
                                                style={{
                                                    textAlign: 'center',
                                                    color: Colors.descriptionColor,
                                                    fontSize: 25,
                                                    width: "80%",
                                                    alignSelf: "center"
                                                }}>
                                                Cancel Transaction with
                                            </Text>
                                        </View>
                                        <View style={{ marginTop: 10 }}>
                                            <Text
                                                style={{
                                                    textAlign: 'center',
                                                    color: "#696969",
                                                    fontSize: 20,
                                                    width: "90%",
                                                    alignSelf: "center"
                                                }}>
                                                Receipt Number: {detail}
                                            </Text>
                                        </View>
                                        <View style={{ height: 5 }}></View>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                alignSelf: 'center',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '50%',
                                                alignContent: 'center',
                                                zIndex: 6000,
                                            }}>

                                        </View>
                                        <View
                                            style={{
                                                alignSelf: 'center',
                                                marginTop: 20,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: 250,
                                                height: 40,
                                                alignContent: 'center',
                                                flexDirection: "row",
                                                marginTop: 40,
                                            }}>
                                            <TouchableOpacity
                                                onPress={() => { cancelTransaction(deleteState) }}
                                                style={{
                                                    backgroundColor: Colors.fieldtBgColor,
                                                    width: '80%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    alignContent: 'center',
                                                    height: 80,
                                                    borderBottomLeftRadius: 10,
                                                    borderRightWidth: StyleSheet.hairlineWidth,
                                                    borderTopWidth: StyleSheet.hairlineWidth
                                                }}>
                                                <Text style={{ fontSize: 22, color: Colors.primaryColor }}>
                                                    Confirm
                                                </Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    setState({ visible: false });
                                                }}
                                                style={{
                                                    backgroundColor: Colors.fieldtBgColor,
                                                    width: '80%',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    alignContent: 'center',
                                                    height: 80,
                                                    borderBottomRightRadius: 10,
                                                    borderTopWidth: StyleSheet.hairlineWidth
                                                }}>
                                                <Text style={{ fontSize: 22, color: Colors.descriptionColor }}>
                                                    Cancel
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                </View>
                            </Modal>
                            <View style={{ flexDirection: "row", marginTop: 20, marginLeft: 30 }}>

                                <View style={{ marginRight: 500, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingLeft: 30, borderRadius: 10, height: windowHeight * 0.055 }}>
                                </View>
                                <TouchableOpacity style={{ marginRight: 10, width: 230, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.055, backgroundColor: Colors.whiteColor }}
                                    onPress={() => { changeClick() }}>
                                    <TouchableOpacity style={{ alignSelf: "center", marginRight: 5 }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                        <GCalendar width={20} height={20} />
                                    </TouchableOpacity>
                                    <Text style={{ fontFamily: "NunitoSans-Regular" }}>{moment(currentDateTime).format("DD MMM yyyy")} - {moment(rev_date).format("DD MMM yyyy")}</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={{ marginRight: 10, width: 120, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.055, backgroundColor: Colors.whiteColor }}>
                                    <AntDesign name="download" size={20} color={Colors.primaryColor} />
                                    <Text style={{ fontFamily: "NunitoSans-Regular", marginLeft: 10 }}>Download</Text>
                                </TouchableOpacity>


                            </View>

                            <View style={{ marginTop: 20, marginLeft: 30, backgroundColor: Colors.whiteColor, width: "87%", height: 480, elevation: 5, shadowColor: Colors.blackColor, shadowOffset: 1, shadowOpacity: 10 }}>
                                <Text style={{ paddingVertical: 15, marginLeft: 20, color: "#a3a3a3" }}>Transaction Details</Text>
                                <View style={{ flexDirection: "row" }}>
                                    <TouchableOpacity style={{ marginRight: 430, width: 120, flexDirection: 'row', alignItems: 'center', paddingLeft: 15, borderRadius: 10, height: windowHeight * 0.055, backgroundColor: Colors.whiteColor }}
                                        onPress={() => { setState({ showDetail: false, showlist: true }) }}>
                                        <Feather name="chevron-left" size={30} color={Colors.primaryColor} />
                                        <Text style={{ fontFamily: "NunitoSans-Regular", color: Colors.primaryColor }}>Back</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ marginRight: 10, width: 150, flexDirection: 'row', alignItems: 'center', borderRadius: 5, height: windowHeight * 0.045, backgroundColor: Colors.primaryColor, justifyContent: "center" }}
                                        onPress={() => { email(deleteState) }}>
                                        <Text style={{ fontFamily: "NunitoSans-Regular", color: Colors.whiteColor }}>Email Receipt</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity style={{ marginRight: 10, width: 150, flexDirection: 'row', alignItems: 'center', borderRadius: 5, height: windowHeight * 0.045, backgroundColor: Colors.primaryColor, justifyContent: "center" }}
                                        onPress={() => { setState({ visible: true }) }}>
                                        <Text style={{ fontFamily: "NunitoSans-Regular", color: Colors.whiteColor }}>Cancel Transaction</Text>
                                    </TouchableOpacity>

                                </View>
                                <View style={{ alignSelf: "center", backgroundColor: Colors.secondaryColor, paddingVertical: 60, paddingHorizontal: 60, borderRadius: 100 }}></View>
                                <Text style={{ alignSelf: "center", fontWeight: "bold", fontSize: 20, marginTop: 10 }}>
                                    {Employee}
                                </Text>
                                <View style={{ flexDirection: "row", marginTop: 20 }}>
                                    <View style={{ flexDirection: "row", flex: 1, marginLeft: 100 }}>
                                        <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Receipt Number:</Text>
                                        <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 50, color: "#9c9c9c" }}>{detail}</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", flex: 1, marginLeft: 50 }}>
                                        <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Customer:</Text>
                                        <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 50, color: "#9c9c9c" }}>{customer}</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 20 }}>
                                    <View style={{ flexDirection: "row", flex: 1, marginLeft: 90 }}>
                                        <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Transaction Type:</Text>
                                        <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 50, color: "#9c9c9c" }}>{transaction}</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", flex: 1, marginLeft: 10 }}>
                                        <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Time Ordered:</Text>
                                        {/* <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 50, color: "#9c9c9c" }}>{moment(orderedAt).format("DD/MM/YYYY")} {moment(orderedAt).format("hh:mm")}</Text> */}
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 20 }}>
                                    <View style={{ flexDirection: "row", flex: 1, marginLeft: 170 }}>
                                        <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Oulet:</Text>
                                        <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 50, color: "#9c9c9c" }}>{outlet}</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", flex: 1 }}>
                                        <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Register:</Text>
                                        <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 50, color: "#9c9c9c" }}>{register}</Text>
                                    </View>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 20 }}>
                                    <View style={{ flexDirection: "row", flex: 1, marginLeft: 140 }}>
                                        <Text style={{ alignSelf: "center", fontWeight: "700", fontSize: 15, marginTop: 10 }}>Employee:</Text>
                                        <Text style={{ alignSelf: "center", fontSize: 15, marginTop: 10, marginLeft: 50, color: "#9c9c9c" }}>{Employee}</Text>
                                    </View>
                                    <View style={{ flexDirection: "row", flex: 1 }}>

                                    </View>
                                </View>
                            </View>

                            <View style={{ height: 20 }}></View>

                            <View style={{ marginTop: 20, marginLeft: 30, backgroundColor: Colors.whiteColor, width: "87%", height: 480, elevation: 5, shadowColor: Colors.blackColor, shadowOffset: 1, shadowOpacity: 10 }}>
                                <Text style={{ paddingVertical: 15, marginLeft: 20, color: "#a3a3a3" }}>Items Ordered</Text>
                                <View style={{ flexDirection: "row" }}>
                                    <Text style={{ width: "5%" }}></Text>
                                    <Text style={{ width: "21%" }}>Name</Text>
                                    <Text style={{ width: "19%" }}>SKU</Text>
                                    <Text style={{ width: "6%" }}>Qty</Text>
                                    <Text style={{ width: "16%" }}>Discount</Text>
                                    <Text style={{ width: "15%" }}>Subtotal</Text>
                                    <Text style={{ width: "10%" }}>Tax</Text>
                                </View>
                                <FlatList
                                    showsVerticalScrollIndicator={false}
                                    data={orderlist}
                                    renderItem={renderItemOrdered}
                                    keyExtractor={(item, index) => String(index)}
                                />
                                <View style={{ flexDirection: "row", marginLeft: 680 }}>
                                    <Text style={{ fontSize: 17, fontWeight: "bold" }}>Subtotal:</Text>
                                    <Text style={{ fontSize: 17, fontWeight: "bold", marginLeft: 10 }}>{parseFloat(price).toFixed(2)}</Text>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 635 }}>
                                    <Text style={{ fontSize: 17, fontWeight: "bold" }}>Total Discount:</Text>
                                    <Text style={{ fontSize: 17, fontWeight: "bold", marginLeft: 10 }}>{parseFloat(discount).toFixed(2)}</Text>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 715 }}>
                                    <Text style={{ fontSize: 17, fontWeight: "bold" }}>Tax:</Text>
                                    <Text style={{ fontSize: 17, fontWeight: "bold", marginLeft: 10 }}>{parseFloat(tax).toFixed(2)}</Text>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 705 }}>
                                    <Text style={{ fontSize: 17, fontWeight: "bold" }}>Total:</Text>
                                    <Text style={{ fontSize: 17, fontWeight: "bold", marginLeft: 10 }}>{parseFloat(final).toFixed(2)}</Text>
                                </View>
                                <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 705 }}>
                                    <Text style={{ fontSize: 17, fontWeight: "bold" }}>Cash:</Text>
                                    <Text style={{ fontSize: 17, fontWeight: "bold", marginLeft: 10 }}>{parseFloat(final).toFixed(2)}</Text>
                                </View>
                                <View style={{ flexDirection: "row", height: 40 }}></View>
                            </View>
                            <View style={{ height: 120 }}></View>
                        </ScrollView> : null}

                    </View>
                
            </ScrollView>
        </View>

    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.highlightColor,
        flexDirection: 'row'
    },
    headerLogo: {
        width: 112,
        height: 25,
        marginLeft: 10
    },
    list: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
    },
    listItem: {
        marginLeft: 20,
        color: Colors.descriptionColor,
        fontSize: 16,
    },
    sidebar: {
        width: Dimensions.get('screen').width * Styles.sideBarWidth,
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
    content: {
        padding: 10,
    },
    confirmBox: {
        width: 400,
        height: 250,
        borderRadius: 10,
        backgroundColor: Colors.whiteColor,
    },
    circleIcon: {
        width: 30,
        height: 30,
        // resizeMode: 'contain',
        marginRight: 10,
        alignSelf: 'center'
    },
    headerLeftStyle: {
        width: Dimensions.get('screen').width * 0.17,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContainer: {
        flex: 1,
        backgroundColor: Colors.modalBgColor,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalView: {
        height: Dimensions.get('screen').width * 0.2,
        width: Dimensions.get('screen').width * 0.3,
        backgroundColor: Colors.whiteColor,
        borderRadius: 12,
        padding: Dimensions.get('screen').width * 0.03,
        alignItems: 'center',
        justifyContent: 'center'
    },
    closeButton: {
        position: 'absolute',
        right: Dimensions.get('screen').width * 0.02,
        top: Dimensions.get('screen').width * 0.02,

        elevation: 1000,
        zIndex: 1000,
    },
    modalTitle: {
        alignItems: 'center',
        top: '20%',
        position: 'absolute',
    },
    modalBody: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'

    },
    modalTitleText: {
        fontFamily: 'NunitoSans-Bold',
        textAlign: 'center',
        fontSize: 20,
    },
    modalDescText: {
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 18,
        color: Colors.fieldtTxtColor
    },
    modalBodyText: {
        flex: 1,
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 25,
        width: "20%",
    },
    modalSaveButton: {
        width: Dimensions.get('screen').width * 0.15,
        backgroundColor: Colors.fieldtBgColor,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,

        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 1,

        marginVertical: 10,
    },
    submitText: {
        height: Platform.OS == 'ios' ? Dimensions.get('screen').height * 0.06 : Dimensions.get('screen').height * 0.07,
        paddingVertical: 5,
        paddingHorizontal: 20,
        flexDirection: 'row',
        color: '#4cd964',
        textAlign: 'center',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
});
export default AllTransaction;
