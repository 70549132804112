import React, { Component, useReducer, useState, useEffect } from 'react';
import { useWindowDimensions, Picker, StyleSheet, ScrollView, Image, View, Text, Alert, TouchableOpacity, Modal, Dimensions, TextInput, FlatList, KeyboardAvoidingView, ActivityIndicator } from 'react-native';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import SideBar from './SideBar';
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Ionicons';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
// import DropDownPicker from 'react-native-dropdown-picker';
import Feather from 'react-native-vector-icons/Feather';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import moment from 'moment';
// import DateTimePickerModal from "react-native-modal-datetime-picker";
// import { isTablet } from 'react-native-device-detection';
import { MERCHANT_VOUCHER_CODE_FORMAT, MERCHANT_VOUCHER_TYPE, SEGMENT_TYPE } from '../constant/common';
import { CommonStore } from '../store/commonStore';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { OutletStore } from '../store/outletStore';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
// import RNPickerSelect from 'react-native-picker-select';
// import { useKeyboard } from '../hooks';
// import { get } from 'react-native/Libraries/Utilities/PixelRatio';
import AsyncImage from '../components/asyncImage';
import Entypo from 'react-native-vector-icons/Entypo';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { useLinkTo, useRoute } from "@react-navigation/native";
import { prefix } from '../constant/env';
import {Platform} from 'react-native';

const LoyaltyPointsRate = props => {
    const {
        navigation,
    } = props;
    const { height: windowHeight, width: windowWidth } = useWindowDimensions();
    const linkTo = useLinkTo();

    //////////////////////////////////////////////////////////////////////////////////////////////////////////  

    const [rateInput, setRateInput] = useState('');

    const [outletRateList, setOutletRateList] = useState([]);

    const userName = UserStore.useState(s => s.name);
    const merchantName = MerchantStore.useState(s => s.name);
    const pointsRedeemPackages = OutletStore.useState(s => s.pointsRedeemPackages);

    const allOutlets = MerchantStore.useState(s => s.allOutlets);

    const isLoading = CommonStore.useState(s => s.isLoading);
    const [switchMerchant, setSwitchMerchant] = useState(false);
    const allOutletsItemsSkuDict = OutletStore.useState(s => s.allOutletsItemsSkuDict);
    const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);

    useEffect(() => {
        var outletRateListTemp = allOutlets.map(item => ({
            uniqueId: item.uniqueId,
            name: item.name,
            loyaltyPointsRate: item.loyaltyPointsRate ? item.loyaltyPointsRate : 1,
            updatedAt: item.updatedAt,
        }));

        setOutletRateList(outletRateListTemp);
    }, [allOutlets]);



    //////////////////////////////////////////////////////////////////////////////////////////////////////////


    //To remove unwanted sidebar
    // navigation.dangerouslyGetParent().setOptions({
    //     tabBarVisible: false,
    // });

    //////////////////////////////////////////////////
    //Header
    navigation.setOptions({
        headerLeft: () => (
            <View style={styles.headerLeftStyle}>
                <Image
                    style={{
                        width: 124,
                        height: 26,
                    }}
                    resizeMode="contain"
                    source={require('../assets/image/logo.png')}
                />
            </View>
        ),
        headerTitle: () => (
            <View style={[{
                justifyContent: 'center',
                alignItems: 'center',
                // bottom: -2,
                bottom: switchMerchant ? '2%' : 0,
            }, windowWidth >= 768 && switchMerchant ? { right: windowWidth * 0.1 } : {}, windowWidth <= 768?{right: windowWidth * 0.12}:{}]}>
                <Text
                    style={{
                        fontSize: switchMerchant ? 20 : 24,
                        // lineHeight: 25,
                        textAlign: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        color: Colors.whiteColor,
                        opacity: 1,
                    }}>
                    Rate Setting
                </Text>
            </View>
        ),
        headerRight: () => (
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                {outletSelectDropdownView()}
                <View style={{
                    backgroundColor: 'white',
                    width: 0.5,
                    height: windowHeight * 0.025,
                    opacity: 0.8,
                    marginHorizontal: 15,
                    bottom: -1,
                }}>
                </View>
                <TouchableOpacity onPress={() => navigation.navigate('Setting')}
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                    <Text
                        style={{
                            fontFamily: 'NunitoSans-SemiBold',
                            fontSize: 16,
                            color: Colors.secondaryColor,
                            marginRight: 15,
                        }}>
                        {userName}
                    </Text>
                    <View style={{
                        marginRight: 30,
                        width: windowHeight * 0.05,
                        height: windowHeight * 0.05,
                        borderRadius: windowHeight * 0.05 * 0.5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                    }}>
                        <Image style={{
                            width: windowHeight * 0.035,
                            height: windowHeight * 0.035,
                            alignSelf: 'center',
                        }} source={require('../assets/image/profile-pic.jpg')} />
                    </View>
                </TouchableOpacity>
            </View>
        ),
    });
    //////////////////////////////////////////////////////////////////

    const renderPointRate = ({ item, index }) => {
        return (
            <View>
                <View
                    style={{
                        flexDirection: 'row',
                        paddingVertical: switchMerchant ? 0 : 15,
                        //borderBottomWidth: styles.hairlineWidth,
                        borderBottomWidth: 0.7,
                        borderColor: '#E5E5E5',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <Text style={{ width: '31%', fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', marginRight: 3, }} numberOfLines={1}>
                        {item.name}
                    </Text>
                    <View style={{ width: '28%', marginHorizontal: 3, flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', marginRight: 6 }}>
                            RM 1 =
                        </Text>
                        <TextInput
                            style={{
                                backgroundColor: Colors.fieldtBgColor,
                                width: 80,
                                height: 40,
                                borderRadius: 5,
                                padding: 5,
                                marginVertical: 5,
                                borderWidth: 1,
                                borderColor: '#E5E5E5',
                                paddingLeft: 10,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14
                            }}
                            placeholder='Rate'
                            placeholderStyle={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular' }}
                            keyboardType='numeric'
                            onChangeText={(text) => {
                                // setRateInput(text);

                                setOutletRateList(outletRateList.map((outletRate, i) => (i === index ? {
                                    ...outletRate,
                                    loyaltyPointsRate: text.length > 0 ? parseFloat(text) : 0,
                                } : outletRate)));
                            }}
                            //defaultValue={}
                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            defaultValue={item.loyaltyPointsRate.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        />
                    </View>
                    <Text style={{ width: '22%', fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', marginHorizontal: 3 }}>
                        {moment(item.updatedAt).format('DD MMM YYYY')}
                    </Text>
                    <View style={{ width: '16%', alignItems: 'flex-end', marginLeft: 3, }}>
                        <TouchableOpacity
                            style={{
                                justifyContent: 'center',
                                flexDirection: 'row',
                                borderWidth: 1,
                                borderColor: Colors.primaryColor,
                                backgroundColor: '#4E9F7D',
                                borderRadius: 5,
                                width: 100,
                                paddingHorizontal: 10,
                                height: 40,
                                alignItems: 'center',
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                                zIndex: -1,
                            }}
                            disabled={isLoading}
                            onPress={() => {
                                updateOutletLoyaltyPointsRate(item);
                            }}
                        >
                            <View style={{ flexDirection: 'row', justifyContent: 'center', paddingTop: 0, paddingBottom: 0 }}>
                                <Text style={{
                                    color: Colors.whiteColor,
                                    //marginLeft: 5,
                                    fontSize: switchMerchant ? 10 : 16,
                                    fontFamily: 'NunitoSans-Bold',
                                }}>
                                    {isLoading ? 'LOADING' : 'SAVE'}
                                </Text>

                                {isLoading ?
                                    <ActivityIndicator color={Colors.whiteColor} size={'small'} />
                                    : <></>
                                }

                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    };

    const updateOutletLoyaltyPointsRate = async (item) => {


        const body = {
            outletId: item.uniqueId,
            loyaltyPointsRate: item.loyaltyPointsRate,
        };

        CommonStore.update(s => {
            s.isLoading = true;
        });

        ApiClient.POST(API.updateOutletLoyaltyPointsRate, body).then((result) => {
            if (result && result.status === 'success') {
                CommonStore.update(s => {
                    s.isLoading = false;
                });
                Alert.alert(
                    'Success',
                    'Loylaty Points rate(s) has been updated',
                );


            }


            else {
                CommonStore.update(s => {
                    s.isLoading = false;
                });
                Alert.alert(
                    'Error',
                    'Failed to update Loyalty Points rate(s) ',
                );

            }

        });

    }


    //Render Start Here
    return (
        <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={6} expandCRM={true} />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ width: windowWidth * 0.9 }}
          contentContainerStyle={{
            paddingBottom: windowHeight * 0.1,
          }}
        >
                <View>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: switchMerchant ? windowWidth * 0.8 : windowWidth * 0.9,
                        margin: 10,
                        paddingHorizontal: switchMerchant ? 0 : 15,
                        paddingTop: 10,
                        marginHorizontal: switchMerchant ? 15 : 0,
                        //marginBottom: switchMerchant ? 0 : 50
                    }}>

                        <TouchableOpacity style={{ width: 90, height: 35, justifyContent: 'center', marginLeft: switchMerchant ? -windowWidth * 0.003 : -windowWidth * 0.005 }}
                            onPress={() => { props.navigation.goBack() }}
                        >
                            <View style={{ flexDirection: 'row', paddingHorizontal: 10, alignContent: 'center', alignItems: 'center' }}>
                                <View style={{ justifyContent: 'center' }}>
                                    <Feather name='chevron-left' size={switchMerchant ? 20 : 30} style={{ color: Colors.primaryColor, alignSelf: 'center' }} />
                                </View>
                                <View style={{}}>
                                    <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 14 : 17, color: Colors.primaryColor, marginBottom: Platform.OS === 'ios' ? 0 : 1 }}>
                                        Back
                                    </Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={{
                        backgroundColor: Colors.whiteColor,
                        width: switchMerchant ? windowWidth * 0.8 : windowWidth * 0.87,
                        height: switchMerchant ? windowHeight * 0.6 : windowHeight * 0.66,
                        marginTop: switchMerchant ? 0 : 10,
                        marginHorizontal: 30,
                        //alignSelf: 'center',
                        //justifyContent: 'center',
                        borderRadius: 5,
                        shadowOpacity: 0,
                        shadowColor: '#000',
                        shadowOffset: {
                            width: 0,
                            height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 3,
                        marginBottom: switchMerchant ? 30 : 0,
                    }}>
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            paddingBottom={10}
                        >
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 30,
                                    marginTop: 30,
                                }}>
                                <Text
                                    style={{
                                        // fontWeight: 'bold',
                                        fontSize: switchMerchant ? 20 : 26,
                                        fontFamily: 'NunitoSans-Bold',
                                        opacity: 0.9,
                                    }}>
                                    Loyalty Points Rate
                                </Text>
                            </View>

                            {/* <KeyboardAwareScrollView
                        style={{
                            marginBottom: 45,
                        }}> */}
                            <View style={{
                                borderWidth: 1,
                                borderColor: '#c4c4c4',
                                borderRadius: 3,
                                alignSelf: 'center',
                                width: windowWidth * 0.7,
                                height: switchMerchant ? windowHeight * 0.5 : windowHeight * 0.45,
                                padding: 15,
                                //paddingLeft: 10,
                                paddingBottom: 30,
                                marginBottom: 50,
                            }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        borderBottomWidth: 1.5,
                                        borderColor: '#E5E5E5',
                                        paddingVertical: 8,
                                        //paddingTop: 5,
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <Text style={{ width: '31%', fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: 3, }}>
                                        Outlet(s)
                                    </Text>
                                    <View style={{ width: '28%', marginHorizontal: 3, flexDirection: 'row', alignItems: 'center' }}>
                                        <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', }}>
                                            Rate
                                        </Text>
                                        {/* <Text style={{ fontSize: 15, fontFamily: 'NunitoSans-SemiBold', marginLeft: 5, color: Colors.fieldtTxtColor }}>
                                    (RM1 to Per Points)
                                </Text> */}
                                    </View>
                                    <Text style={{ width: '22%', fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginHorizontal: 3, }}>
                                        Updated Date
                                    </Text>
                                    <Text style={{ width: '16%', fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginLeft: 3, }}></Text>
                                </View>
                                <ScrollView>
                                    {allOutlets && allOutlets.length > 0 &&
                                        <FlatList
                                            nestedScrollEnabled={true}
                                            data={outletRateList}
                                            //extraData={renderPointRate}
                                            renderItem={renderPointRate}
                                            keyExtractor={(item, index) => String(index)}
                                        />
                                    }
                                </ScrollView>

                            </View>
                        </ScrollView>
                    </View>
                </View>
                {/* </ScrollView>*/}
            </ScrollView>
        </View>
    </View>
    )

};


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.highlightColor,
        flexDirection: 'row',
        fontFamily: 'NunitoSans-Regular',
    },
    list: {
        backgroundColor: Colors.whiteColor,
        // width: windowWidth * 0.87,
        // height: windowHeight * 0.66,
        marginTop: 10,
        marginHorizontal: 30,
        //alignSelf: 'center',
        //justifyContent: 'center',
        borderRadius: 5,
        shadowOpacity: 0,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
    },
    listItem: {
        fontFamily: 'NunitoSans-Regular',
        marginLeft: 20,
        color: Colors.descriptionColor,
        fontSize: 16,
    },
    sidebar: {
        // width: windowWidth * Styles.sideBarWidth,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,
        elevation: 16,
    },
    content: {
        padding: 20,
        // width: windowWidth * (1 - Styles.sideBarWidth),
        // backgroundColor: 'lightgrey',
        backgroundColor: Colors.fieldtBgColor,
    },
    textInput: {
        fontFamily: 'NunitoSans-Regular',
        width: 300,
        height: 50,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 10,
        marginRight: 0,
        flex: 1,
        flexDirection: 'row',
    },
    textInputLocation: {
        fontFamily: 'NunitoSans-Regular',
        width: 300,
        height: 100,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 10,
        marginRight: 10,
    },
    textSize: {
        fontSize: 19,
        fontFamily: 'NunitoSans-SemiBold'
    },
    merchantDisplayView: {
        flexDirection: 'row',
        flex: 1,
        marginLeft: '17%'
    },
    shiftText: {
        marginLeft: '15%',
        color: Colors.primaryColor,
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 25,
    },
    confirmBox: {
        width: '30%',
        height: '30%',
        borderRadius: 30,
        backgroundColor: Colors.whiteColor,
    },
});

export default LoyaltyPointsRate;
