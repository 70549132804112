import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  Dimensions,
  TouchableOpacity,
  Switch,
  FlatList,
  Modal,
  TextInput,
  KeyboardAvoidingView,
  PermissionsAndroid,
  Platform,
  ActivityIndicator,
  useWindowDimensions,
  Picker,
} from "react-native";
import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import Feather from "react-native-vector-icons/Feather";

import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";

import ApiClient from "../util/ApiClient";
import API from "../constant/API";
import * as User from "../util/User";
import DropDownPicker from "react-native-dropdown-picker";
import Entypo from "react-native-vector-icons/Entypo";
import Styles from "../constant/Styles";
import moment, { now } from "moment";

import { ReactComponent as Coins } from "../assets/svg/Coins.svg";
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import { ReactComponent as Magnify } from "../assets/svg/Magnify.svg";

import { CSVLink } from "react-csv";

import AntDesign from "react-native-vector-icons/AntDesign";
import { CommonStore } from "../store/commonStore";
import {
  listenToUserChangesMerchant,
  listenToMerchantIdChangesMerchant,
  listenToCurrOutletIdChangesWaiter,
  listenToAllOutletsChangesMerchant,
  listenToCommonChangesMerchant,
  listenToSelectedOutletItemChanges,
  convertArrayToCSV,
  listenToSelectedOutletTableIdChanges,
  requestNotificationsPermission,
  sortReportDataList,
  listenToSelectedCustomerChangesMerchant,
  generateEmailReport,
} from "../util/common";
import { UserStore } from "../store/userStore";
import { MerchantStore } from "../store/merchantStore";
import {
  NOTIFICATIONS_CHANNEL,
  TIMEZONE,
  NOTIFICATIONS_ID,
  CHART_HOURLY_LABEL_LIST,
  ROLE_TYPE,
  ORDER_TYPE,
  ORDER_TYPE_PARSED,
  NOTIFICATIONS_TYPE,
  REPORT_SORT_FIELD_TYPE,
  TABLE_PAGE_SIZE_DROPDOWN_LIST,
  EMAIL_REPORT_TYPE,
} from "../constant/common";
import { OutletStore } from "../store/outletStore";

import {
  CHART_DATA,
  CHART_TYPE,
  FS_LIBRARY_PATH,
  CHART_Y_AXIS_DROPDOWN_LIST,
  CHART_FIELD_COMPARE_DROPDOWN_LIST,
  CHART_FIELD_NAME_DROPDOWN_LIST,
  CHART_FIELD_TYPE,
  CHART_FIELD_COMPARE_DICT,
  CHART_PERIOD,
} from "../constant/chart";
import DateTimePickerModal from "react-native-modal-datetime-picker";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
import "react-native-get-random-values";
import { PROMOTION_TYPE_PARSED } from "../constant/promotions";

const { nanoid } = require("nanoid");


const PromotionReportScreen = (props) => {
  const { navigation } = props;

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  // const [keyboardHeight] = useKeyboard();
  const [tempData, setTempData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [yearlyData, setYearlyData] = useState([]);
  const [months, setMonths] = useState([]);
  const [test, setTest] = useState([]);
  const [yearlyData1, setYearlyData1] = useState([]);
  const [months1, setMonths1] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [test1, setTest1] = useState([]);
  const [displayTodayData, setDisplayTodayData] = useState([]);
  const [displayTodayLabel, setDisplayTodayLabel] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [currentOutletTotalSales, setCurrentOutletTotalSales] = useState(0);
  const [allDaySalesDetails, setAllDaySalesDetails] = useState([]);
  const [allDayTotalTransaction, setAllDayTotalTransaction] = useState(0);
  const [allDayTotalSold, setAllDayTotalSold] = useState(0);
  const [totalSold, setTotalSold] = useState(0);
  const [selectedTotalSales, setSelectedTotalSales] = useState(0);
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [outletLabel, setOutletLabel] = useState([]);
  const [outletSalesData, setOutletSalesData] = useState([
    {
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
      color: (opacity = 1) => Colors.tabMint,
    },
  ]);
  const [viewDownload, setvViewDownload] = useState(0);
  const [viewEmail, setViewEmail] = useState(0);
  const [CsvData, setCsvData] = useState([]);
  const [exportEmail, setExportEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const [switchMerchant, setSwitchMerchant] = useState(false);

  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
  const [
    showDateTimePickerFilterLineChart,
    setShowDateTimePickerFilterLineChart,
  ] = useState(false);
  const [
    showDateTimePickerFilterBarChart,
    setShowDateTimePickerFilterBarChart,
  ] = useState(false);

  const [rev_date, setRev_date] = useState(moment().subtract(6, "days").startOf('day'));
  const [rev_date1, setRev_date1] = useState(moment().endOf(Date.now()).endOf('day'));

  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDetailsPage, setCurrentDetailsPage] = useState(1);

  const [search, setSearch] = useState("");

  const [expandDetailsDict, setExpandDetailsDict] = useState({});

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);

  //////////////////////////////////////////////////////////////

  const [todaySalesChart, setTodaySalesChart] = useState({});

  const [todaySalesChartPeriod, setTodaySalesChartPeriod] = useState(
    CHART_PERIOD.TODAY
  );

  const [salesLineChart, setSalesLineChart] = useState({});

  const [salesLineChartPeriod, setSalesLineChartPeriod] = useState(
    CHART_PERIOD.THIS_MONTH
  );
  const [salesBarChartPeriod, setSalesBarChartPeriod] = useState(
    CHART_PERIOD.THIS_MONTH
  );

  const [dailySalesDetailsList, setDailySalesDetailsList] = useState([]);

  const [selectedItemSummary, setSelectedItemSummary] = useState({});

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  const merchantId = UserStore.useState((s) => s.merchantId);
  const role = UserStore.useState((s) => s.role);
  const firebaseUid = UserStore.useState((s) => s.firebaseUid);

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const currOutlet = MerchantStore.useState((s) => s.currOutlet);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  // const outletsTaxDict = CommonStore.useState(s => s.outletsTaxDict);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const [list1, setList1] = useState(true);

  const [showDetails, setShowDetails] = useState(false);
  const [transactionTypeSalesDetails, setTransactionTypeSalesDetails] =
    useState([]);

  const [exportModalVisibility, setExportModalVisibility] = useState(false);

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState("");

  const [expandBarSelection, setExpandBarSelection] = useState(
    props.expandBarSelection === undefined ? false : props.expandBarSelection
  );
  const [barFilterTapped, setBarFilterTapped] = useState(
    props.barFilterTapped === undefined ? 0 : props.barFilterTapped
  );
  const [expandLineSelection, setExpandLineSelection] = useState(false);
  const [lineFilterTapped, setLineFilterTapped] = useState(
    props.lineFilterTapped === undefined ? 0 : props.lineFilterTapped
  );

  const [
    selectedChartDropdownValueLineChart,
    setSelectedChartDropdownValueLineChart,
  ] = useState(
    CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value
  );
  const [
    selectedChartFilterQueriesLineChart,
    setSelectedChartFilterQueriesLineChart,
  ] = useState([
    {
      fieldNameKey:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0]
          .value,
      fieldNameType:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0]
          .fieldType,
      fieldCompare:
        CHART_FIELD_COMPARE_DROPDOWN_LIST[
          CHART_TYPE.DASHBOARD_LINE_CHART_SALES
        ][0].value,
      fieldDataValue: null,
    },
  ]);
  const [
    appliedChartFilterQueriesLineChart,
    setAppliedChartFilterQueriesLineChart,
  ] = useState([]);

  const [
    selectedChartDropdownValueBarChart,
    setSelectedChartDropdownValueBarChart,
  ] = useState(
    CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0].value
  );
  const [
    selectedChartFilterQueriesBarChart,
    setSelectedChartFilterQueriesBarChart,
  ] = useState([
    {
      fieldNameKey:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .value,
      fieldNameType:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .fieldType,
      fieldCompare:
        CHART_FIELD_COMPARE_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .value,
      fieldDataValue: null,
    },
  ]);
  const [
    appliedChartFilterQueriesBarChart,
    setAppliedChartFilterQueriesBarChart,
  ] = useState([]);

  const [currReportSummarySort, setCurrReportSummarySort] = useState("");
  const [currReportDetailsSort, setCurrReportDetailsSort] = useState("");

  const [overviewAmountTotal, setOverviewAmountTotal] = useState(0);
  const [overviewAmountYTD, setOverviewAmountYTD] = useState(0);
  const [overviewAmount3Months, setOverviewAmount3Months] = useState(0);
  const [overviewAmount6Months, setOverviewAmount6Months] = useState(0);

  const outletsUserOrdersDone = CommonStore.useState(
    (s) => s.outletsUserOrdersDone
  );

  const promotions = OutletStore.useState((s) => s.promotions);
  const [pushPagingToTop, setPushPagingToTop] = useState(false);

  /////////////////////////////////////////////////////////////////////////////////////

  // useEffect(() => {
  //     //console.log(moment().utc().tz(TIMEZONE.KUALA_LUMPUR).format());
  //     //console.log('haloooo');
  //     //console.log(moment(moment(Date.now)));
  //     // setTargetOutletDropdownList(allOutlets.map(outlet => ({ label: outlet.name, value: outlet.uniqueId })));

  //     // console.log('targetOutletDropdownList');
  //     // console.log(targetOutletDropdownList);

  //     // if (allOutlets.length > 0) {
  //     //     setSelectedTargetOutletId(currOutletId);
  //     // }

  //     var targetOutletDropdownListTemp = allOutlets.map(outlet => ({ label: outlet.name, value: outlet.uniqueId }));

  //     setExpandLineSelection(false);

  //     // CommonStore.update(s => {
  //     //     s.outletSelectDropdownView = () => {
  //     //         return (
  //     //             <>
  //     //                 {
  //     //                     currOutletId.length > 0 && allOutlets.find(item => item.uniqueId === currOutletId)
  //     //                         ?
  //     //                         <DropDownPicker
  //     //                             containerStyle={{ height: 40, paddingVertical: 0 }}
  //     //                             style={{ backgroundColor: '#345F4A', borderWidth: 0, paddingVertical: 0 }}
  //     //                             arrowSize={20}
  //     //                             arrowColor={'#ffffff'}
  //     //                             arrowStyle={{ fontWeight: 'bold', marginLeft: 5 }}
  //     //                             //itemStyle={{ justifyContent: 'flex-start' }}
  //     //                             placeholder='Choose outlet'
  //     //                             placeholderStyle={{ color: Colors.whiteColor, fontWeight: '600' }}
  //     //                             labelStyle={{ color: Colors.whiteColor, fontWeight: '600' }}
  //     //                             dropDownStyle={{ backgroundColor: '#345F4A', borderRadius: 5, zIndex: 1 }}
  //     //                             items={targetOutletDropdownListTemp}
  //     //                             onChangeItem={(item) => {
  //     //                                 MerchantStore.update(s => {
  //     //                                     s.currOutletId = item.value;
  //     //                                 });
  //     //                             }}
  //     //                             defaultValue={currOutletId}
  //     //                         />
  //     //                         :
  //     //                         <></>
  //     //                 }
  //     //             </>
  //     //         );
  //     //     };
  //     // });
  // }, [allOutlets, currOutletId]);

  /////////////////////////////////////////////////////////////////////////////////////

  const allOutletsUserOrdersDone = OutletStore.useState(
    (s) => s.allOutletsUserOrdersDone
  );

  // useEffect(() => {
  //     var totalHourlyDataListTemp = Array(24).fill(0);

  //     for (var i = 0; i < allOutletsUserOrdersDone.length; i++) {
  //         if (moment(allOutletsUserOrdersDone[i].completedDate).isSame(moment(), 'day')) {
  //             totalHourlyDataListTemp[moment(allOutletsUserOrdersDone[i].completedDate)] += allOutletsUserOrdersDone[i].finalPrice;
  //         }
  //     }

  //     // setTotalHourlyDataDict(totalHourlyDataListTemp);
  // }, [allOutletsUserOrdersDone]);

  // useEffect(() => {
  //     var outletIdTemp = localSelectedOutletId ? localSelectedOutletId : currOutletId;

  //     if (outletIdTemp !== '') {
  //         var singleHourlyDataListTemp = Array(24).fill(0);

  //         for (var i = 0; i < allOutletsUserOrdersDone.length; i++) {
  //             if (allOutletsUserOrdersDone[i].outletId === outletIdTemp &&
  //                 moment(allOutletsUserOrdersDone[i].completedDate).isSame(moment(), 'day')) {
  //                 singleHourlyDataListTemp[moment(allOutletsUserOrdersDone[i].completedDate)] += allOutletsUserOrdersDone[i].finalPrice;
  //             }
  //         }

  //         // setSingleHourlyDataDict(singleHourlyDataListTemp);
  //     }
  // }, [currOutletId, localSelectedOutletId, allOutletsUserOrdersDone]);

  //////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    //////////////////////////////////////////////////////////////////////////////////////

    var dailySalesDetailsListTemp = [];

    var overviewAmountTotalTemp = 0;
    var overviewAmountYTDTemp = 0;
    var overviewAmount3MonthsTemp = 0;
    var overviewAmount6MonthsTemp = 0;

    var promotionSalesDict = {};

    if (currOutletId && currOutletId.length > 0) {
      var currOutletUserOrdersDone = allOutletsUserOrdersDone.filter(
        (order) =>
          order.outletId === currOutletId &&
          order.promotionIdList &&
          order.promotionIdList.length > 0
      );

      for (var index = 0; index < promotions.length; index++) {
        if (promotions[index].outletIdList.includes(currOutletId)) {
          // means current outlet's promotion

          var record = {
            summaryId: nanoid(),
            promotionName: promotions[index].campaignName || "N/A",
            promotionType:
              PROMOTION_TYPE_PARSED[promotions[index].promotionType] || "N/A",
            // addOnChoices: 'N/A',
            // totalItemsSold: 0,
            totalOrders: 0,
            totalSales: 0,
            // totalSalesReturn: 0,
            discountAmount: 0,
            discountPercentage: 0,
            // averageCost: 0,
            // itemNetSales: 0,
            // averageNetSales: 0,
            // gp: 0,
            detailsList: [],

            startDate: promotions[index].promoDateStart,
            endDate: promotions[index].promoDateEnd,
            startTime: promotions[index].promoTimeStart,
            endTime: promotions[index].promoTimeEnd,
          };

          promotionSalesDict[record.summaryId] = record;

          // will optimize this flow in future....
          for (var i = 0; i < currOutletUserOrdersDone.length; i++) {
            if (
              currOutletUserOrdersDone[i].promotionIdList.includes(
                record.uniqueId
              ) &&
              moment(rev_date).isSameOrBefore(
                currOutletUserOrdersDone[i].completedDate
              ) &&
              moment(rev_date1).isAfter(
                currOutletUserOrdersDone[i].completedDate
              )
            ) {
              var promotionDetailsIndex = currOutletUserOrdersDone[
                i
              ].promotionDetailsList.findIndex((details) => {
                return details.promotionId === record.uniqueId;
              });

              const promotionDetails =
                currOutletUserOrdersDone[i].promotionDetailsList[
                  promotionDetailsIndex
                ];

              if (promotionDetails) {
                promotionSalesDict[record.summaryId].totalOrders += 1;
                promotionSalesDict[record.summaryId].totalSales +=
                  currOutletUserOrdersDone[i].finalPrice;
                promotionSalesDict[record.summaryId].discountAmount +=
                  promotionDetails.discount;
                promotionSalesDict[record.summaryId].discountPercentage += +(
                  (promotionDetails.discount /
                    currOutletUserOrdersDone[i].finalPrice) *
                  100
                )
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
                promotionSalesDict[record.summaryId].detailsList.push(
                  currOutletUserOrdersDone[i]
                );

                overviewAmountTotalTemp += promotionDetails.discount;

                if (
                  moment(currOutletUserOrdersDone[i].completedDate).diff(
                    moment(),
                    "month"
                  ) <= 12
                ) {
                  overviewAmountYTDTemp += promotionDetails.discount;

                  if (
                    moment(currOutletUserOrdersDone[i].completedDate).diff(
                      moment(),
                      "month"
                    ) <= 6
                  ) {
                    overviewAmount6MonthsTemp += promotionDetails.discount;

                    if (
                      moment(currOutletUserOrdersDone[i].completedDate).diff(
                        moment(),
                        "month"
                      ) <= 3
                    ) {
                      overviewAmount3MonthsTemp += promotionDetails.discount;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    setOverviewAmountTotal(overviewAmountTotalTemp);
    setOverviewAmountYTD(overviewAmountYTDTemp);
    setOverviewAmount6Months(overviewAmount6MonthsTemp);
    setOverviewAmount3Months(overviewAmount3MonthsTemp);

    // const promotionSalesTemp = Object.entries(promotionSalesDict).map(([key, value]) => ({ ...value }));

    // the above "will optimize this flow in future...." FOR LOOP does nothing because the lenght is 0
    // filter promotion sales list date here
    const promotionSalesTemp = Object.entries(promotionSalesDict)
      .filter(([index, value]) => {
        if (
          moment(value.startDate).isBetween(rev_date, rev_date1, "D", "[]") ||
          moment(value.endDate).isBetween(rev_date, rev_date1, "D", "[]") ||
          (moment(value.startDate).isSameOrBefore(rev_date) &&
            moment(value.endDate).isAfter(rev_date1))
        )
          return true;
      })
      .map(([key, value]) => ({ ...value }));

    setDailySalesDetailsList(promotionSalesTemp);

    setCurrentPage(1);
    setPageCount(Math.ceil(promotionSalesTemp.length / perPage));

    setShowDetails(false);
  }, [
    allOutlets,
    allOutletsUserOrdersDone,
    currOutletId,
    rev_date,
    rev_date1,
    perPage,
  ]);

  useEffect(() => {
    if (showDetails && selectedItemSummary.detailsList) {
      setTransactionTypeSalesDetails(selectedItemSummary.detailsList);

      setCurrentPage(1);
      setPageCount(Math.ceil(selectedItemSummary.detailsList.length / perPage));
    }
  }, [showDetails, selectedItemSummary, perPage]);

  //////////////////////////////////////////////////////////////////////////////////

  // navigation.dangerouslyGetParent().setOptions({
  //     tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            bottom: switchMerchant ? "2%" : 0,
            // bottom: -2,
          },
          Dimensions.get("screen").width >= 768 && switchMerchant
            ? { right: Dimensions.get("screen").width * 0.1 }
            : {},
          Dimensions.get("screen").width <= 768
            ? { right: Dimensions.get("screen").width * 0.12 }
            : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Promotion Report
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: Dimensions.get("screen").height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get("screen").height * 0.05,
              height: Dimensions.get("screen").height * 0.05,
              borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: Dimensions.get("screen").height * 0.035,
                height: Dimensions.get("screen").height * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  useEffect(() => {
    requestStoragePermission();

    getTotalSales(0);
    getSalesSummary();
    getTodaySales();
    getOutlets();
  }, []);

  // useEffect(() => {
  //     if (firebaseUid !== '' && merchantId !== '') {
  //         listenToFirestoreChanges(firebaseUid, merchantId, currOutletId);
  //     }
  // }, [merchantId, firebaseUid, currOutletId]);

  const setState = () => {};

  const requestStoragePermission = async () => {
    try {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: "KooDoo Merchant Storage Permission",
          message: "KooDoo Merchant App needs access to your storage ",
          buttonNegative: "Cancel",
          buttonPositive: "OK",
        }
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        console.log("Storage permission granted");
      } else {
        console.log("Storage permission denied");
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const eventsChart = {
    dataPlotClick: (e, item) => {
      console.log("test data plot");
    },
  };

  const getOutlets = async () => {
    // ApiClient.GET(API.dashboardSaleToday + User.getMerchantId()).then(result => {
    //     setState({ outlets: result })
    // })
  };

  const getSalesSummary = async () => {
    // ApiClient.GET(API.dashboardSaleTodayCalculation + User.getMerchantId()).then(result => {
    //     setState({ totalSales: result.totalSales.toFixed(2), totalTransactions: result.totalTransactions, totalSold: result.totalSold })
    // })
  };

  const getTodaySales = () => {
    // ApiClient.GET(API.dashboardSaleToday + User.getMerchantId()).then(result => {
    //     const displayData = [];
    //     const displayLabel = [];
    //     for (const entries of result) {
    //         displayLabel.push(entries.outletName)
    //     }
    //     for (const entries of result) {
    //         displayData.push(Math.trunc(entries.finalPrice))
    //     }
    //     setState({ displayTodayLabel: displayLabel, displayTodayData: displayData, outlets: result })
    //     const dummyData = [
    //         {
    //             "month": 1,
    //             "monthName": "January",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[0]
    //         },
    //         {
    //             "month": 2,
    //             "monthName": "February",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[1]
    //         },
    //         {
    //             "month": 3,
    //             "monthName": "March",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[2]
    //         },
    //         {
    //             "month": 4,
    //             "monthName": "April",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[3]
    //         },
    //         {
    //             "month": 5,
    //             "monthName": "May",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[4]
    //         },
    //         {
    //             "month": 6,
    //             "monthName": "June",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[5]
    //         },
    //         {
    //             "month": 7,
    //             "monthName": "July",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[6]
    //         },
    //         {
    //             "month": 8,
    //             "monthName": "August",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[7]
    //         },
    //         {
    //             "month": 9,
    //             "monthName": "September",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[8]
    //         },
    //         {
    //             "month": 10,
    //             "monthName": "October",
    //             "year": 2020,
    //             "totalPrice": displayTodayData[9]
    //         },
    //         {
    //             "month": 11,
    //             "monthName": "November",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[10]
    //         },
    //         {
    //             "month": 12,
    //             "monthName": "December",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[11]
    //         },
    //     ]
    //     setState({ CsvData: dummyData })
    // })
  };

  const getTotalSales = async (type) => {
    const outletStat = [];
    const outletLabel = [];
    const colorList = [
      Colors.tabGold,
      Colors.tabMint,
      Colors.tabGrey,
      Colors.tabYellow,
      Colors.tabCyan,
      Colors.fieldtBgColor,
      Colors.primaryColor,
      Colors.secondaryColor,
      Colors.darkBgColor,
      Colors.highlightColor,
      Colors.lightRed,
      Colors.blackColor,
    ];
    // linechart data set format
    // {
    //     data: outletSalesData.length == 0 ? tempData : outletSalesData[0],
    //     color: (opacity = 1) => Colors.tabMint,
    // }
    if (type == 0) {
      for (const outlets of selectedOutlets) {
        console.log("outlets", outlets);

        const price = [];
        const label = [];
        ApiClient.GET(
          API.dashboardSaleOutletToday +
            "?merchantId=" +
            User.getMerchantId() +
            "&outletId=" +
            outlets
        ).then((result) => {
          console.log("reuslt", result);
          var i = selectedOutlets.indexOf(outlets);
          console.log("initial i", i);
          for (const entries of result) {
            price.push(Number(entries.totalPrice));
            label.push(entries.hour.toString());
          }
          outletStat.push({
            data: price,
            color: (opacity = 1) => colorList[i],
          });
          outletLabel.push(label);
          console.log("outletStat", outletStat);
          setState({
            outletSalesData:
              outletStat.length == 0
                ? [
                    {
                      data: [
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                        0, 0, 0, 0, 0,
                      ],
                      color: (opacity = 1) => Colors.tabMint,
                    },
                  ]
                : outletStat,
            outletLabel: outletLabel[0],
          });
        });
      }
    }

    const dummyYear = [
      {
        month: 1,
        monthName: "January",
        year: 2021,
        totalPrice: displayTodayData[0],
      },
      {
        month: 2,
        monthName: "February",
        year: 2021,
        totalPrice: displayTodayData[1],
      },
      {
        month: 3,
        monthName: "March",
        year: 2021,
        totalPrice: displayTodayData[2],
      },
      {
        month: 4,
        monthName: "April",
        year: 2021,
        totalPrice: displayTodayData[3],
      },
      {
        month: 5,
        monthName: "May",
        year: 2021,
        totalPrice: displayTodayData[4],
      },
      {
        month: 6,
        monthName: "June",
        year: 2021,
        totalPrice: displayTodayData[5],
      },
      {
        month: 7,
        monthName: "July",
        year: 2021,
        totalPrice: displayTodayData[6],
      },
      {
        month: 8,
        monthName: "August",
        year: 2021,
        totalPrice: displayTodayData[7],
      },
      {
        month: 9,
        monthName: "September",
        year: 2021,
        totalPrice: displayTodayData[8],
      },
      {
        month: 10,
        monthName: "October",
        year: 2020,
        totalPrice: displayTodayData[9],
      },
      {
        month: 11,
        monthName: "November",
        year: 2021,
        totalPrice: displayTodayData[10],
      },
      {
        month: 12,
        monthName: "December",
        year: 2021,
        totalPrice: displayTodayData[11],
      },
    ];

    // setState({ CsvData: dummyYear})

    const dummyYear1 = [
      {
        month: 1,
        monthName: "January",
        year: 2020,
        totalPrice: "212.61",
      },
      {
        month: 2,
        monthName: "February",
        year: 2020,
        totalPrice: "157.90",
      },
      {
        month: 3,
        monthName: "March",
        year: 2020,
        totalPrice: "199.08",
      },
      {
        month: 4,
        monthName: "April",
        year: 2020,
        totalPrice: "332.61",
      },
      {
        month: 5,
        monthName: "May",
        year: 2020,
        totalPrice: "544.90",
      },
      {
        month: 6,
        monthName: "June",
        year: 2020,
        totalPrice: "873.50",
      },
      {
        month: 7,
        monthName: "July",
        year: 2020,
        totalPrice: "843.00",
      },
      {
        month: 8,
        monthName: "August",
        year: 2020,
        totalPrice: "632.30",
      },
      {
        month: 9,
        monthName: "September",
        year: 2020,
        totalPrice: "500.50",
      },
      {
        month: 10,
        monthName: "October",
        year: 2020,
        totalPrice: "340.00",
      },
      {
        month: 11,
        monthName: "November",
        year: 2020,
        totalPrice: "229.50",
      },
      {
        month: 12,
        monthName: "December",
        year: 2020,
        totalPrice: "792.80",
      },
    ];

    const months = [];
    const yearlyData = [];
    const test = [];
    for (const entries of dummyYear) {
      months.push(entries.monthName);
      yearlyData.push(entries.month);
      test.push(parseFloat(entries.totalPrice));
    }
    const months1 = [];
    const yearlyData1 = [];
    const test1 = [];
    for (const entries of dummyYear1) {
      months1.push(entries.monthName);
      yearlyData1.push(entries.month);
      test1.push(parseFloat(entries.totalPrice));
    }
    setState({
      yearlyData: yearlyData,
      months: months,
      test: test,
      test1: test1,
      months1: months1,
      yearlyData1: yearlyData1,
    });
  };

  // const downloadCsv = () => {
  //     if (todaySalesChart && todaySalesChart.dataSource && todaySalesChart.dataSource.data) {
  //         const csvData = convertArrayToCSV(todaySalesChart.dataSource.data);

  //         const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-dashboard-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
  //         console.log("PATH", pathToWrite)
  //         RNFetchBlob.fs
  //             .writeFile(pathToWrite, csvData, 'utf8')
  //             .then(() => {
  //                 console.log(`wrote file ${pathToWrite}`);
  //                 // wrote file /storage/emulated/0/Download/data.csv
  //                 Alert.alert(
  //                     'Success',
  //                     `Send to ${pathToWrite}`,
  //                     [{ text: 'OK', onPress: () => { } }],
  //                     { cancelable: false },
  //                 );
  //             })
  //             .catch(error => console.error(error));
  //     }

  //     // var body = {
  //     //     data: CsvData
  //     // };

  //     // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
  //     //     console.log("RESULT", result)
  //     //     if (result !== null) {
  //     //         const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/dashboardData.csv`;
  //     //         console.log("PATH", pathToWrite)
  //     //         RNFetchBlob.fs
  //     //             .writeFile(pathToWrite, result, 'utf8')
  //     //             .then(() => {
  //     //                 console.log(`wrote file ${pathToWrite}`);
  //     //                 // wrote file /storage/emulated/0/Download/data.csv
  //     //                 Alert.alert(
  //     //                     'Success',
  //     //                     'Check Your Folder',
  //     //                     [{ text: 'OK', onPress: () => { } }],
  //     //                     { cancelable: false },
  //     //                 );
  //     //             })
  //     //             .catch(error => console.error(error));
  //     //     }
  //     // });
  // }

  const downloadPDF = () => {};

  const emailDashboard = () => {
    const excelData = convertDataToExcelFormat();

    var body = {
      // data: CsvData,
      //data: convertArrayToCSV(todaySalesChart.dataSource.data),
      data: JSON.stringify(excelData),
      //data: convertDataToExcelFormat(),
      email: exportEmail,
    };

    ApiClient.POST(API.emailDashboard, body, false).then((result) => {
      if (result !== null) {
        Alert.alert(
          "Success",
          "Email has been sent",
          [{ text: "OK", onPress: () => {} }],
          { cancelable: false }
        );
      }
    });

    setVisible(false);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
  };
  const nextDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage + 1 > pageCount
        ? currentDetailsPage
        : currentDetailsPage + 1
    );
  };

  const prevDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1
    );
  };

  const renderLabel = (item) => {
    return (
      // <View style={{ elevation: 10, backgroundColor: category.active ? Colors.primaryColor : Colors.whiteColor, height: Dimensions.get('screen').width * 0.03, alignItems: 'center', justifyContent: 'center', borderRadius: 9, marginHorizontal: 1 }}>
      //<Text style={{ fontFamily: 'NunitoSans-Bold', marginHorizontal: 30, fontFamily: 'NunitoSans-Bold', color: category.active ? Colors.whiteColor : Colors.primaryColor }}>{category.label}</Text>
      //</View>
      <View
        style={{
          flexDirection: "row",
          backgroundColor: Colors.tabGrey,
          height: Dimensions.get("screen").width * 0.04,
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 6,
          marginHorizontal: 1,
        }}
      >
        {/* <Text style={{ marginHorizontal: 5, color: 'white', fontWeight: 'bold' }}>{item.label}</Text> */}
        <Text
          style={{ marginHorizontal: 5, color: "white", fontWeight: "bold" }}
        >
          asdfasdfasdf
        </Text>
        <TouchableOpacity
          style={{ marginRight: 3 }}
          onPress={() => {
            //select(item)
          }}
        >
          <AntDesign name={"close"} size={15} color={"#FFFFFF"} />
        </TouchableOpacity>
      </View>
    );
  };

  const onItemSummaryClicked = (item) => {
    // setTransactionTypeSalesDetails(item.detailsList);
    setSelectedItemSummary(item);
    setShowDetails(true);

    // setCurrentPage(1);
    // setPageCount(Math.ceil(item.detailsList.length / perPage));

    console.log("item.detailsList");
    console.log(item.detailsList);
  };

  //Daily Sales Report
  //Insert Dummy Data first
  //Text below is Dummy Data
  const renderItem = ({ item, index }) => (
    // (index + 1) % 2 == 0 ?
    <TouchableOpacity
      onPress={() => onItemSummaryClicked(item)}
      style={{
        backgroundColor:
          (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
        paddingVertical: 10,
        paddingHorizontal: 3,
        paddingLeft: 1,
        borderColor: "#BDBDBD",
        borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        // width: '100%',
      }}
    >
      <View style={{ flexDirection: "row" }}>
        {/* <Text style={{ flex: 0.8, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{index + 1}</Text> */}

        {/* <Text style={{ flex: Platform.OS == 'ios' ? 2.2 : 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', left: Platform.OS == 'ios' ? 10 : 10}}>{item.promotionName}</Text>
                <Text style={{ flex: 1.3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left' , right: Platform.OS == 'ios' ? 0 : 0}}>{item.promotionType}</Text>
                <View style={{ flex: 1, textAlign: 'left', justifyContent: 'center', alignItems: 'center' , right: Platform.OS == 'ios' ? 0 : 10}}>
                    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular', color: Colors.blackColor }} >{moment(item.startDate).format("DD MMM YY")}</Text>
                        <Text style={{ fontSize: 11, color: Colors.blackColor }} > {moment(item.startTime).format("hh:mma")}</Text>
                    </View>
                    <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular', color: Colors.blackColor, marginVertical: -2 }}>to</Text>
                    <View style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                        <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular', color: Colors.blackColor }} >{moment(item.endDate).format("DD MMM YY")}</Text>
                        <Text style={{ fontSize: 11, color: Colors.blackColor }} > {moment(item.endTime).format("hh:mma")}</Text>
                    </View>
                </View>
                <Text style={{ flex: 0.8, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left' , left: Platform.OS == 'ios' ? 40 : 60}}>{item.totalOrders}</Text>
                <Text style={{ flex: 0.8, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left' , left: Platform.OS == 'ios' ? 40 : 40}}>{item.discountAmount.toFixed(2)}</Text>
                <Text style={{ flex: 0.8, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left' , left: Platform.OS == 'ios' ? 40 : 40}}>{item.discountPercentage.toFixed(2)}</Text>
                <Text style={{ flex: 0.8, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left' , left: Platform.OS == 'ios' ? 40 : 40}}>{item.totalSales.toFixed(2)}</Text> */}

        <Text
          style={[
            {
              flex: Platform.OS == "ios" ? 2.2 : 2,
              fontSize: 16,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.promotionName}
        </Text>
        <Text
          style={[
            {
              flex: 1.3,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.promotionType}
        </Text>
        <View style={{ flex: 1.5, marginHorizontal: 3 }}>
          <View style={{ alignItems: "center", alignSelf: "baseline" }}>
            <Text
              style={[
                {
                  fontSize: 14,
                  fontFamily: "NunitoSans-Regular",
                  color: Colors.blackColor,
                  fontVariant: ["tabular-nums"],
                },
                switchMerchant ? { fontSize: 10 } : {},
              ]}
            >
              {moment(item.startDate).format("DD MMM YYYY")}{" "}
              {moment(item.startTime).format("hh:mm A")}
            </Text>
            <Text
              style={[
                {
                  fontSize: 14,
                  fontFamily: "NunitoSans-Regular",
                  color: Colors.blackColor,
                  marginVertical: -2,
                  fontVariant: ["tabular-nums"],
                },
                switchMerchant ? { fontSize: 10 } : {},
              ]}
            >
              to
            </Text>
            <Text
              style={[
                {
                  fontSize: 14,
                  fontFamily: "NunitoSans-Regular",
                  color: Colors.blackColor,
                  fontVariant: ["tabular-nums"],
                },
                switchMerchant ? { fontSize: 10 } : {},
              ]}
            >
              {moment(item.endDate).format("DD MMM YYYY")}{" "}
              {moment(item.endTime).format("hh:mm A")}
            </Text>
          </View>
        </View>
        <Text
          style={[
            {
              flex: 0.6,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.totalOrders}
        </Text>
        <Text
          style={[
            {
              flex: 0.8,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.discountAmount
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </Text>
        <Text
          style={[
            {
              flex: 0.8,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.discountPercentage.toFixed(2)}
        </Text>
        <Text
          style={[
            {
              flex: 0.8,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.totalSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </Text>

        {/* <Text style={{ flex: 1.3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.totalTransactions}</Text> */}
        {/* <Text style={{ flex: 1.8, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.totalDiscount.toFixed(2)}</Text>
                <Text style={{ flex: 1.3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.discount.toFixed(2)}</Text> */}
        {/* <Text style={{ flex: 1.2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.tax.toFixed(2)}</Text>
                <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.serviceCharge.toFixed(2)}</Text> */}
        {/* <Text style={{ flex: 1, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.gp}</Text> */}
        {/* <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.totalSalesReturn.toFixed(2)}</Text>
                <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.netSales.toFixed(2)}</Text>
                <Text style={{ flex: 1.95, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.averageNetSales.toFixed(2)}</Text> */}
      </View>
    </TouchableOpacity>
  );

  const onClickItemDetails = (item) => {
    setExpandDetailsDict({
      ...expandDetailsDict,
      [item.uniqueId]: expandDetailsDict[item.uniqueId] ? false : true,
    });
  };

  const renderItemDetails = ({ item, index }) => (
    <TouchableOpacity
      onPress={() => onClickItemDetails(item)}
      style={{
        backgroundColor:
          (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
        paddingVertical: 10,
        paddingHorizontal: 3,
        paddingLeft: 1,
        borderColor: "#BDBDBD",
        borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <Text
          style={[
            {
              flex: 0.8,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {index + 1}
        </Text>
        <Text
          style={[
            {
              flex: 2.3,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {moment(item.completedDate).format("DD MMM HH:MMA")}
        </Text>
        <Text
          style={[
            {
              flex: 2.5,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.totalPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </Text>
        <Text
          style={[
            {
              flex: 1.8,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.discount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </Text>
        <Text
          style={[
            {
              flex: 1.6,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.discountPercentage.toFixed(2)}
        </Text>
        <Text
          style={[
            {
              flex: 1.7,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.tax.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </Text>
        <Text
          style={[
            {
              flex: 2,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {(0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </Text>
        {/* <Text style={{ flex: 1, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{(0).toFixed(2)}</Text> */}
        <Text
          style={[
            {
              flex: 2,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.finalPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </Text>
        <Text
          style={[
            {
              flex: 2,
              fontSize: 14,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              marginHorizontal: 3,
            },
            switchMerchant ? { fontSize: 10 } : {},
          ]}
        >
          {item.finalPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
        </Text>
        {/* <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.averageNetSales.toFixed(2)}</Text> */}
      </View>

      {expandDetailsDict[item.uniqueId] ? (
        <TouchableOpacity
          onPress={() => onClickItemDetails(item)}
          style={{
            width: "100%",
            marginTop: 20,
          }}
        >
          {item.cartItems.map((cartItem, index) => {
            return (
              <View
                style={{
                  width: "100%",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexDirection: "row",
                  marginVertical: 10,
                }}
              >
                <View
                  style={{
                    flex: 2.3,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{ fontFamily: "NunitoSans-Regular", fontSize: 16 }}
                  >
                    {index + 1}.
                  </Text>
                </View>
                <View style={{ flex: 10.9, justifyContent: "center" }}>
                  <Text
                    style={{ fontFamily: "NunitoSans-Regular", fontSize: 16 }}
                  >
                    {cartItem.name}
                  </Text>
                  {cartItem.addOns.map((addOnChoice, i) => {
                    return (
                      <View>
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Regular",
                            color: Colors.fieldtTxtColor,
                          }}
                        >
                          {`${addOnChoice.quantities ? "Add On" : "Variant"}, ${
                            addOnChoice.name
                          }: ${addOnChoice.choiceNames[0]}`}
                          {`${
                            addOnChoice.quantities
                              ? ` x${addOnChoice.quantities[0]}`
                              : ""
                          }`}
                          {` (RM${addOnChoice.prices[0]
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")})`}
                        </Text>
                      </View>
                    );
                  })}
                </View>
                <View
                  style={{
                    flex: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{ fontFamily: "NunitoSans-Regular", fontSize: 16 }}
                  >
                    x{cartItem.quantity}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{ fontFamily: "NunitoSans-Regular", fontSize: 16 }}
                  >
                    {cartItem.price
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </Text>
                </View>
              </View>
            );
          })}
        </TouchableOpacity>
      ) : (
        <></>
      )}
    </TouchableOpacity>
  );

  const convertDataToExcelFormat = () => {
    var excelData = [];

    if (!showDetails) {
      //List
      for (var i = 0; i < dailySalesDetailsList.length; i++) {
        var excelRow = {
          // 'Transaction Category': transactionTypeSales[i].orderType,
          // 'Total Sales (RM)': parseFloat(transactionTypeSales[i].totalSales).toFixed(2),
          // 'Total Transaction': transactionTypeSales[i].totalTransactions,
          // 'Total Discount (RM)': parseFloat(transactionTypeSales[i].totalDiscount).toFixed(2),
          // 'Discount (%)': parseFloat(transactionTypeSales[i].discount).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSales[i].tax).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSales[i].serviceCharge).toFixed(2),
          // 'GP (%)': parseFloat(transactionTypeSales[i].gp).toFixed(2),
          // 'Net Sales (RM)': parseFloat(transactionTypeSales[i].netSales).toFixed(2),
          // 'Average Net Sales (RM)': parseFloat(transactionTypeSales[i].averageNetSales).toFixed(2),
          Title: dailySalesDetailsList[i].promotionName,
          Type: dailySalesDetailsList[i].promotionType,
          Duration: `${moment(dailySalesDetailsList[i].startDate).format(
            "DD MMM YYYY"
          )} ${moment(dailySalesDetailsList[i].startTime).format(
            "hh:mm A"
          )} to ${moment(dailySalesDetailsList[i].endDate).format(
            "DD MMM YYYY"
          )} ${moment(dailySalesDetailsList[i].endTime).format("hh:mm A")}`,
          Order: dailySalesDetailsList[i].totalOrders,
          'Disc (RM)': +dailySalesDetailsList[i].discountAmount
            .toFixed(2),
          'Disc (%)': +dailySalesDetailsList[i].discountPercentage.toFixed(2),
          'Sales (RM)': +dailySalesDetailsList[i].totalSales
            .toFixed(2),
        };

        excelData.push(excelRow);
      }
    } else {
      //Detail List
      for (var i = 0; i < transactionTypeSalesDetails.length; i++) {
        var excelRow = {
          // 'Transaction Category': ORDER_TYPE_PARSED[transactionTypeSalesDetails[i].orderType],
          // 'Sales (RM)': parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2),
          // 'Transaction Date': moment(transactionTypeSalesDetails[i].createdAt).format('DD MMM hh:mma'),
          // 'Total Discount (RM)': parseFloat(transactionTypeSalesDetails[i].discount).toFixed(2),
          // 'Discount (%)': parseFloat(isFinite(transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount) ? transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount * 100 : 0).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2),
          // 'Tax (RM)': parseFloat(0).toFixed(2),
          // 'GP (%)': parseFloat(0).toFixed(2),
          // 'Net Sales (RM)': parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2),
          // 'Date/Time': moment(transactionTypeSalesDetails[i].completedDate).format('DD MMM hh:mma'),
          // 'Sales (RM)': parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          // 'Total Discount (RM)': parseFloat(transactionTypeSalesDetails[i].discount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          // 'Discount (%)': parseFloat(transactionTypeSalesDetails[i].discountPercentage).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          // 'Tax (RM)': parseFloat(0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          // 'GP (%)': parseFloat(0).toFixed(2),
          // 'Sales Return': parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          // 'Net Sales (RM)': parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
        };

        excelData.push(excelRow);
      }
    }

    console.log("excelData");
    console.log(excelData);

    return excelData;
  };

  // const downloadExcel = () => {
  //     const excelData = convertDataToExcelFormat();

  //     var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
  //     var excelWorkSheet = XLSX.utils.json_to_sheet(excelData);
  //     var excelWorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, "Product Sales Report");

  //     const workBookData = XLSX.write(excelWorkBook, {
  //         type: 'binary',
  //         bookType: 'xlsx',
  //     });

  //     RNFS.writeFile(excelFile, workBookData, 'ascii')
  //         .then((success) => {
  //             console.log(`wrote file ${excelFile}`);

  //             Alert.alert(
  //                 'Success',
  //                 `Send to ${excelFile}`,
  //                 [{ text: 'OK', onPress: () => { } }],
  //                 { cancelable: false },
  //             );
  //         })
  //         .catch((err) => {
  //             console.log(err.message);
  //         });

  //     // XLSX.writeFileAsync(excelFile, excelWorkBook, () => {
  //     //     Alert.alert(
  //     //         'Success',
  //     //         `Send to ${excelFile}`,
  //     //         [{ text: 'OK', onPress: () => { } }],
  //     //         { cancelable: false },
  //     //     );
  //     // });

  //     // const csvData = convertArrayToCSV(CsvData);

  //     // const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
  //     // console.log("PATH", excelFile);
  //     // RNFetchBlob.fs
  //     //     .writeFile(excelFile, excelWorkBook, 'utf8')
  //     //     .then(() => {
  //     //         console.log(`wrote file ${excelFile}`);
  //     //         Alert.alert(
  //     //             'Success',
  //     //             `Send to ${excelFile}`,
  //     //             [{ text: 'OK', onPress: () => { } }],
  //     //             { cancelable: false },
  //     //         );
  //     //     })
  //     //     .catch(error => console.error(error));
  // }

  const barFilterPressed = (barFilterTapped) => {
    setExpandBarSelection(true);
  };

  const lineFilterPressed = (lineFilterTapped) => {
    setExpandLineSelection(true);
  };

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar
          navigation={navigation}
          selectedTab={11}
          expandPromotions={true}
        />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView showsVerticalScrollIndicator={false} style={{}}>
          <DateTimePickerModal
            isVisible={showDateTimePicker}
            mode={"date"}
            onConfirm={(text) => {
              setRev_date(moment(text).startOf('day'));
              setShowDateTimePicker(false);
            }}
            onCancel={() => {
              setShowDateTimePicker(false);
            }}
            maximumDate={moment(rev_date1).toDate()}
          />

          <DateTimePickerModal
            isVisible={showDateTimePickerFilterLineChart}
            mode={"date"}
            onConfirm={(text) => {
              setSelectedChartFilterQueriesLineChart([
                {
                  ...selectedChartFilterQueriesLineChart[0],
                  fieldDataValue: text,
                },
              ]);

              setShowDateTimePickerFilterLineChart(false);
            }}
            onCancel={() => {
              setShowDateTimePickerFilterLineChart(false);
            }}
          />

          <DateTimePickerModal
            isVisible={showDateTimePickerFilterBarChart}
            mode={"date"}
            onConfirm={(text) => {
              setSelectedChartFilterQueriesBarChart([
                {
                  ...selectedChartFilterQueriesBarChart[0],
                  fieldDataValue: text,
                },
              ]);

              setShowDateTimePickerFilterBarChart(false);
            }}
            onCancel={() => {
              setShowDateTimePickerFilterBarChart(false);
            }}
          />

          {/* <Modal
                        style={{
                            // flex: 1
                        }}
                        visible={exportModalVisibility}
                        transparent={true}
                        animationType={'slide'}
                    >
                        <View style={styles.modalContainer}>
                            <View style={[styles.modalView, {
                            }]}>
                                <TouchableOpacity
                                    style={styles.closeButton}
                                    onPress={() => {
                                        // setState({ changeTable: false });
                                        setExportModalVisibility(false);
                                    }}>
                                    <AntDesign name="closecircle" size={25} color={Colors.fieldtTxtColor} />
                                </TouchableOpacity>
                                <View style={styles.modalTitle}>
                                    <Text style={styles.modalTitleText}>
                                        Download Options
                                    </Text>
                                </View>

                                <View style={{
                                    alignItems: 'center',
                                    top: '10%',
                                }}>
                                    <TouchableOpacity
                                        style={[styles.modalSaveButton, {
                                            zIndex: -1.
                                        }]}
                                        onPress={() => { downloadExcel() }}>
                                        <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Export to Excel</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity
                                        style={[styles.modalSaveButton, {
                                            zIndex: -1.
                                        }]}
                                        onPress={() => { downloadCsv() }}>
                                        <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Export to CSV</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </Modal> */}

          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={exportModalVisibility}
            transparent={true}
            animationType={"slide"}
          >
            <View style={styles.modalContainer}>
              <View
                style={[
                  styles.modalView,
                  switchMerchant
                    ? {
                        height: Dimensions.get("screen").width * 0.25,
                        width: Dimensions.get("screen").width * 0.35,
                      }
                    : {},
                ]}
              >
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => {
                    setExportModalVisibility(false);
                  }}
                >
                  <AntDesign
                    name="closecircle"
                    size={switchMerchant ? 15 : 25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View style={styles.modalTitle}>
                  <Text
                    style={[
                      styles.modalTitleText,
                      switchMerchant ? { fontSize: 18 } : {},
                    ]}
                  >
                    Download Report
                  </Text>
                </View>
                <View style={{ top: "10%" }}>
                  <Text
                    style={[
                      { fontSize: 16, fontFamily: "NunitoSans-Bold" },
                      switchMerchant ? { fontSize: 10 } : {},
                    ]}
                  >
                    Email Address:
                  </Text>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={[
                      {
                        backgroundColor: Colors.fieldtBgColor,
                        width: 370,
                        height: 50,
                        borderRadius: 5,
                        padding: 5,
                        marginVertical: 5,
                        borderWidth: 1,
                        borderColor: "#E5E5E5",
                        paddingLeft: 10,
                      },
                      switchMerchant
                        ? { width: 260, height: 35, fontSize: 10 }
                        : {},
                    ]}
                    placeholderStyle={{ padding: 5 }}
                    placeholder="Enter Your Email"
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    onChangeText={(text) => {
                      setExportEmail(text);
                    }}
                    value={exportEmail}
                  />
                  <Text
                    style={[
                      {
                        fontSize: 16,
                        fontFamily: "NunitoSans-Bold",
                        marginTop: 15,
                      },
                      switchMerchant ? { fontSize: 10 } : {},
                    ]}
                  >
                    Download As:
                  </Text>

                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      //top: '10%',
                      flexDirection: "row",
                      marginTop: 15,
                    }}
                  >
                    {/* <TouchableOpacity
                        style={[
                          {
                            justifyContent: "center",
                            flexDirection: "row",
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: "#4E9F7D",
                            borderRadius: 5,
                            width: 120,
                            paddingHorizontal: 10,
                            height: 40,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                          },
                          switchMerchant ? { width: 80, height: 20 } : {},
                        ]}
                        onPress={() => {
                          if (exportEmail.length > 0) {
                            CommonStore.update((s) => {
                              s.isLoading = true;
                            });

                            setIsLoadingExcel(true);

                            const excelData = convertDataToExcelFormat();

                            generateEmailReport(
                              EMAIL_REPORT_TYPE.EXCEL,
                              excelData,
                              "KooDoo Promotion Report",
                              "KooDoo Promotion Report.xlsx",
                              `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                              exportEmail,
                              "KooDoo Promotion Report",
                              "KooDoo Promotion Report",
                              () => {
                                CommonStore.update((s) => {
                                  s.isLoading = false;
                                });

                                setIsLoadingExcel(false);

                                Alert.alert(
                                  "Success",
                                  "Report will be sent to the email address soon."
                                );

                                setExportModalVisibility(false);
                              }
                            );
                          } else {
                            Alert.alert("Info", "Invalid email address.");
                          }
                        }}
                      >
                        {isLoadingExcel ? (
                          <ActivityIndicator
                            size={"small"}
                            color={Colors.whiteColor}
                          />
                        ) : (
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 10,
                              fontSize: switchMerchant ? 10 : 16,
                              color: "#FFFFFF",
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            EXCEL
                          </Text>
                        )}
                      </TouchableOpacity> */}

                    <CSVLink
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "inline-block",
                        flexDirection: "row",
                        textDecoration: "none",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: switchMerchant ? 100 : 100,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      data={convertDataToExcelFormat()}
                      filename="report.csv"
                    >
                      <View
                        style={{
                          width: "100%",
                          height: "100%",
                          alignContent: "center",
                          alignItems: "center",
                          alignSelf: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          CSV
                        </Text>
                      </View>
                    </CSVLink>

                    {/* <TouchableOpacity
                        style={[
                          {
                            justifyContent: "center",
                            flexDirection: "row",
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: "#4E9F7D",
                            borderRadius: 5,
                            width: 120,
                            paddingHorizontal: 10,
                            height: 40,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                            marginLeft: 15,
                          },
                          switchMerchant ? { width: 80, height: 20 } : {},
                        ]}
                        onPress={() => {
                          if (exportEmail.length > 0) {
                            CommonStore.update((s) => {
                              s.isLoading = true;
                            });

                            setIsLoadingCsv(true);

                            // const csvData = convertArrayToCSV(todaySalesChart.dataSource.data);
                            const csvData = convertArrayToCSV(
                              dailySalesDetailsList
                            );

                            generateEmailReport(
                              EMAIL_REPORT_TYPE.CSV,
                              csvData,
                              "KooDoo Promotion Report",
                              "KooDoo Promotion Report.csv",
                              `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                              exportEmail,
                              "KooDoo Promotion Report",
                              "KooDoo Promotion Report",
                              () => {
                                CommonStore.update((s) => {
                                  s.isLoading = false;
                                });

                                setIsLoadingCsv(false);

                                Alert.alert(
                                  "Success",
                                  "Report will be sent to the email address soon."
                                );

                                setExportModalVisibility(false);
                              }
                            );
                          } else {
                            Alert.alert("Info", "Invalid email address.");
                          }
                        }}
                      >
                        {isLoadingCsv ? (
                          <ActivityIndicator
                            size={"small"}
                            color={Colors.whiteColor}
                          />
                        ) : (
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 10,
                              fontSize: switchMerchant ? 10 : 16,
                              color: "#FFFFFF",
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            CSV
                          </Text>
                        )}
                      </TouchableOpacity> */}

                    {/* <TouchableOpacity
                                            style={{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 1,
                                                borderColor: Colors.primaryColor,
                                                backgroundColor: '#4E9F7D',
                                                borderRadius: 5,
                                                width: 120,
                                                paddingHorizontal: 10,
                                                height: 40,
                                                alignItems: 'center',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                            }}
                                            onPress={() => { downloadPDF() }}>
                                            <Text style={{
                                                color: Colors.whiteColor,
                                                //marginLeft: 5,
                                                fontSize: 16,
                                                fontFamily: 'NunitoSans-Bold',
                                            }}>
                                            PDF</Text>
                                        </TouchableOpacity> */}
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <DateTimePickerModal
            isVisible={showDateTimePicker1}
            mode={"date"}
            onConfirm={(text) => {
              setRev_date1(moment(text).endOf('day'));
              setShowDateTimePicker1(false);
            }}
            onCancel={() => {
              setShowDateTimePicker1(false);
            }}
            minimumDate={moment(rev_date).toDate()}
          />

          <Modal
            style={{ flex: 1 }}
            visible={visible}
            transparent={true}
            animationType="slide"
          >
            <KeyboardAvoidingView
              behavior="padding"
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                minHeight: Dimensions.get("window").height,
              }}
            >
              <View style={styles.confirmBox}>
                <Text
                  style={{
                    fontSize: 24,
                    justifyContent: "center",
                    alignSelf: "center",
                    marginTop: 40,
                    fontFamily: "NunitoSans-Bold",
                  }}
                >
                  Enter your email
                </Text>
                <View
                  style={{
                    justifyContent: "center",
                    alignSelf: "center",
                    alignContent: "center",
                    marginTop: 20,
                    flexDirection: "row",
                    width: "80%",
                  }}
                >
                  <View
                    style={{ justifyContent: "center", marginHorizontal: 5 }}
                  >
                    <Text
                      style={{ color: Colors.descriptionColor, fontSize: 16 }}
                    >
                      Email:
                    </Text>
                  </View>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={[styles.textInput8, { paddingLeft: 5 }]}
                    placeholder="Enter your email"
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    // style={{
                    //     // paddingLeft: 1,
                    // }}
                    //defaultValue={extentionCharges}
                    onChangeText={(text) => {
                      // setState({ exportEmail: text });
                      setExportEmail(text);
                    }}
                    value={exportEmail}
                  />
                </View>

                <View
                  style={{
                    alignSelf: "center",
                    marginTop: 20,
                    justifyContent: "center",
                    alignItems: "center",
                    // width: 260,
                    width: Dimensions.get("screen").width * 0.2,
                    height: 60,
                    alignContent: "center",
                    flexDirection: "row",
                    marginTop: 40,
                  }}
                >
                  <TouchableOpacity
                    onPress={emailDashboard}
                    style={{
                      backgroundColor: Colors.fieldtBgColor,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: 60,
                      borderBottomLeftRadius: 10,
                      borderRightWidth: StyleSheet.hairlineWidth,
                      borderTopWidth: StyleSheet.hairlineWidth,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 22,
                        color: Colors.primaryColor,
                        fontFamily: "NunitoSans-SemiBold",
                      }}
                    >
                      Email
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      // setState({ visible: false });
                      setVisible(false);
                    }}
                    style={{
                      backgroundColor: Colors.fieldtBgColor,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: 60,
                      borderBottomRightRadius: 10,
                      borderTopWidth: StyleSheet.hairlineWidth,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 22,
                        color: Colors.descriptionColor,
                        fontFamily: "NunitoSans-SemiBold",
                      }}
                    >
                      Cancel
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>
          <View
            style={{
              marginHorizontal: switchMerchant ? 30 : 0,
              paddingHorizontal: switchMerchant ? 0 : 35,
              paddingVertical: 30,
              paddingTop: 5,
              width: switchMerchant
                ? Dimensions.get("screen").width * 0.8
                : Dimensions.get("screen").width * (1 - Styles.sideBarWidth),
              //top: Platform.OS === 'ios' && keyboardHeight > 0 ? -keyboardHeight * 1 : 0,
            }}
          >
            <View
              style={{
                height: 60,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "1%",
              }}
            >
              <View style={{ marginLeft: switchMerchant ? -5 : -10 }}>
                <Text
                  style={[
                    {
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 26,
                      marginVertical: 6,
                    },
                    switchMerchant
                      ? {
                          fontSize: 20,
                        }
                      : {},
                  ]}
                >
                  {" "}
                  Promotion Report
                </Text>
                {/* <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: 15, color: Colors.descriptionColor }}>Data last updated on {moment().format('LLLL')}</Text> */}
              </View>
              <View style={{ flexDirection: "row" }}>
                {/* <TouchableOpacity
                                
                                    style={{
                                        marginRight: 15, paddingHorizontal: 15, backgroundColor: Colors.whiteColor, height: 40, width: 120, alignItems: 'center', borderRadius: 7, flexDirection: 'row', justifyContent: 'center', shadowColor: '#000',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 1,
                                    }}
                                    onPress={() => { downloadCsv() }}>
                                    <Download width={15} height={15} />
                                    <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, marginLeft: 12 }}>Download</Text>
                                </TouchableOpacity> */}

                <TouchableOpacity
                  style={[
                    {
                      justifyContent: "center",
                      flexDirection: "row",
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      backgroundColor: "#4E9F7D",
                      borderRadius: 5,
                      //width: 160,
                      paddingHorizontal: 10,
                      height: 40,
                      alignItems: "center",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,
                    },
                    switchMerchant
                      ? {
                          height: 35,
                        }
                      : {},
                  ]}
                  // onPress={() => { downloadCsv() }}
                  onPress={() => {
                    setExportModalVisibility(true);
                  }}
                >
                  <Feather
                    name="download"
                    size={switchMerchant ? 10 : 20}
                    style={{ color: Colors.whiteColor }}
                  />
                  <Text
                    style={[
                      {
                        color: Colors.whiteColor,
                        marginLeft: 5,
                        fontSize: 16,
                        fontFamily: "NunitoSans-Bold",
                      },
                      switchMerchant
                        ? {
                            fontSize: 10,
                          }
                        : {},
                    ]}
                  >
                    DOWNLOAD
                  </Text>
                </TouchableOpacity>

                {/* <TouchableOpacity
                                    style={{
                                        paddingHorizontal: 15, backgroundColor: Colors.whiteColor, height: 40, width: 120, alignItems: 'center', borderRadius: 7, flexDirection: 'row', justifyContent: 'center', shadowColor: '#000',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 1,
                                    }}
                                    onPress={() => {
                                        // setState({
                                        //     visible: true
                                        // })
                                        setVisible(true);
                                    }}
                                >
                                    <Upload width={15} height={15} />
                                    <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, marginLeft: 12 }}>Email</Text>
                                </TouchableOpacity> */}
                {/* {viewEmail == 1  ? <Modal transparent={true}>
                                <View style={{width:'100%', height:'100%', backgroundColor:Colors.modalBgColor, alignItems:'center', justifyContent:'center'}}>
                                    <View style={{width:'30%', height:'30%', backgroundColor:Colors.fieldtBgColor}}></View>
                                </View>
                            </Modal>:null} */}
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <View
                style={[
                  {
                    width: "23%",
                    height: 90,
                    backgroundColor: Colors.tabMint,
                    borderRadius: 10,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 30,
                    paddingVertical: 15,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  },
                  switchMerchant
                    ? {
                        paddingVertical: 0,
                        height: 60,
                      }
                    : {},
                ]}
              >
                {/* <View style={{ flex: 1 }}>
                                    <View style={{ flex: 1, justifyContent: 'center' }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 20, }}>RM {totalSales}</Text>
                                    </View>
                                    <View style={{ flex: 0.8, }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.whiteColor, fontSize: 13, paddingTop: 10 }}>Total Sales</Text>
                                    </View>
                                </View> */}
                <View
                  style={[
                    {
                      marginTop: -15,
                    },
                    switchMerchant
                      ? {
                          marginTop: 15,
                          height: 60,
                        }
                      : {},
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontFamily: "NunitoSans-Bold",
                        color: Colors.whiteColor,
                        fontSize: 28,
                      },
                      switchMerchant
                        ? {
                            fontSize: 20,
                          }
                        : {},
                    ]}
                  >
                    RM {overviewAmountTotal.toFixed(0)}
                  </Text>
                  <Text
                    style={[
                      {
                        fontFamily: "NunitoSans-Regular",
                        color: Colors.whiteColor,
                        fontSize: 13,
                      },
                      switchMerchant
                        ? {
                            fontSize: 10,
                          }
                        : {},
                    ]}
                  >
                    Total Promotion
                  </Text>
                </View>
                <View>
                  <Coins
                    height={switchMerchant ? 30 : 56}
                    width={switchMerchant ? 40 : 56}
                  />
                </View>
              </View>
              <View
                style={[
                  {
                    width: "23%",
                    height: 90,
                    backgroundColor: Colors.tabGold,
                    borderRadius: 10,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 30,
                    paddingVertical: 15,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  },
                  switchMerchant
                    ? {
                        paddingVertical: 0,
                        height: 60,
                      }
                    : {},
                ]}
              >
                <View
                  style={[
                    { marginTop: -15 },
                    switchMerchant
                      ? {
                          marginTop: 15,
                          height: 60,
                        }
                      : {},
                  ]}
                >
                  <Text
                    style={[
                      {
                        fontFamily: "NunitoSans-Bold",
                        color: Colors.whiteColor,
                        fontSize: 28,
                      },
                      switchMerchant
                        ? {
                            fontSize: 20,
                          }
                        : {},
                    ]}
                  >
                    RM {overviewAmountYTD.toFixed(0)}
                  </Text>
                  <Text
                    style={[
                      {
                        fontFamily: "NunitoSans-Regular",
                        color: Colors.whiteColor,
                        fontSize: 13,
                      },
                      switchMerchant
                        ? {
                            fontSize: 10,
                          }
                        : {},
                    ]}
                  >
                    YTD Promotion
                  </Text>
                </View>
                <View>
                  <AntDesign
                    name={"calendar"}
                    size={switchMerchant ? 30 : 65}
                    style={[{ opacity: 0.6, color: "#F7F7F7", bottom: 5 }]}
                  />
                </View>
              </View>
              <View
                style={[
                  {
                    width: "23%",
                    height: 90,
                    backgroundColor: Colors.tabCyan,
                    borderRadius: 10,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 30,
                    paddingVertical: 15,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  },
                  switchMerchant
                    ? {
                        paddingVertical: 0,
                        height: 60,
                      }
                    : {},
                ]}
              >
                <View
                  style={[
                    { marginTop: -15 },
                    switchMerchant
                      ? {
                          marginTop: 15,
                          height: 60,
                        }
                      : {},
                  ]}
                >
                  {/* <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28 }}>{allDayTotalSold}</Text> */}
                  <Text
                    style={[
                      {
                        fontFamily: "NunitoSans-Bold",
                        color: Colors.whiteColor,
                        fontSize: 28,
                      },
                      switchMerchant
                        ? {
                            fontSize: 20,
                          }
                        : {},
                    ]}
                  >
                    RM {overviewAmount3Months.toFixed(0)}
                  </Text>
                  <Text
                    style={[
                      {
                        fontFamily: "NunitoSans-Regular",
                        color: Colors.whiteColor,
                        fontSize: 13,
                      },
                      switchMerchant
                        ? {
                            fontSize: 10,
                          }
                        : {},
                    ]}
                  >
                    Past 3 Months
                  </Text>
                </View>
                <View>
                  <AntDesign
                    name={"calendar"}
                    size={switchMerchant ? 30 : 65}
                    style={{ opacity: 0.6, color: "#F7F7F7", bottom: 5 }}
                  />
                </View>
              </View>
              <View
                style={[
                  {
                    width: "23%",
                    height: 90,
                    backgroundColor: Colors.tabCyan,
                    borderRadius: 10,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingHorizontal: 30,
                    paddingVertical: 15,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  },
                  switchMerchant
                    ? {
                        paddingVertical: 0,
                        height: 60,
                      }
                    : {},
                ]}
              >
                <View
                  style={[
                    { marginTop: -15 },
                    switchMerchant
                      ? {
                          marginTop: 15,
                          height: 60,
                        }
                      : {},
                  ]}
                >
                  {/* <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28 }}>{allDayTotalSold}</Text> */}
                  <Text
                    style={[
                      {
                        fontFamily: "NunitoSans-Bold",
                        color: Colors.whiteColor,
                        fontSize: 28,
                      },
                      switchMerchant
                        ? {
                            fontSize: 20,
                          }
                        : {},
                    ]}
                  >
                    RM {overviewAmount6Months.toFixed(0)}
                  </Text>
                  <Text
                    style={[
                      {
                        fontFamily: "NunitoSans-Regular",
                        color: Colors.whiteColor,
                        fontSize: 13,
                      },
                      switchMerchant
                        ? {
                            fontSize: 10,
                          }
                        : {},
                    ]}
                  >
                    Past 6 Months
                  </Text>
                </View>
                <View>
                  <AntDesign
                    name={"calendar"}
                    size={switchMerchant ? 30 : 65}
                    style={{ opacity: 0.6, color: "#F7F7F7", bottom: 5 }}
                  />
                </View>
              </View>
            </View>

            {/* LINE CHART SALES */}
            {/* <View style={{
                            backgroundColor: Colors.whiteColor,
                            // borderRadius: 10, 
                            borderRadius: 5,
                            marginTop: 20, padding: 10, alignItems: 'center', justifyContent: 'center',
                            shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            // elevation: 1,
                            elevation: 3,
                            zIndex: -1,
                        }}>
                            <View style={{ width: '100%', flexDirection: 'row', marginVertical: 10, marginBottom: 0, paddingRight: 20, alignItems: 'center', justifyContent: 'space-between', }}>
                                <View style={{ flexDirection: 'row', width: '20%' }}>
                                    <View style={{ width: '100%', flexDirection: 'row', marginVertical: 10, marginBottom: 20, alignItems: 'center' }}>
                                        <View style={{ marginHorizontal: 5 }}>
                                            <GCalendar width={22} height={22} />
                                        </View> */}
            {/* <Text style={{
                                            fontFamily: 'NunitoSans-Bold',
                                            color: Colors.primaryColor, fontSize: 18, marginHorizontal: 10, alignSelf: 'flex-start',
                                            // backgroundColor: 'red',
                                            width: Dimensions.get('screen').width * 0.3,
                                        }}>
                                            Total Sales (All Outlets) */}
            {/* <>
                                                {
                                                    salesLineChartPeriod === CHART_PERIOD.TODAY ? 'Total Today Sales (All Outlets)' : ''
                                                }
                                            </>
                                            <>
                                                {
                                                    salesLineChartPeriod === CHART_PERIOD.THIS_WEEK ? 'Total One Week Sales (All Outlets)' : ''
                                                }
                                            </>
                                            <>
                                                {
                                                    salesLineChartPeriod === CHART_PERIOD.THIS_MONTH ? 'Total One Month Sales (All Outlets)' : ''
                                                }
                                            </>
                                            <>
                                                {
                                                    salesLineChartPeriod === CHART_PERIOD.THREE_MONTHS ? 'Total Three Months Sales (All Outlets)' : ''
                                                }
                                            </>
                                            <>
                                                {
                                                    salesLineChartPeriod === CHART_PERIOD.SIX_MONTHS ? 'Total Six Months Sales (All Outlets)' : ''
                                                }
                                            </>
                                            <>
                                                {
                                                    salesLineChartPeriod === CHART_PERIOD.THIS_YEAR ? 'Total One Year Sales (All Outlets)' : ''
                                                }
                                            </>
                                            <>
                                                {
                                                    salesLineChartPeriod === CHART_PERIOD.YTD ? 'Total Year To Date Sales (All Outlets)' : ''
                                                }
                                            </> */}
            {/* </Text> */}

            {/* <DropDownPicker
                                            arrowColor={'#BDBDBD'}
                                            arrowSize={23}
                                            arrowStyle={{
                                                fontWeight: 'bold',
                                                bottom: 1,
                                            }}
                                            style={{ width: 210, height: 45, marginLeft: 10 }}
                                            dropDownStyle={{ width: 210, marginLeft: 10 }}
                                            items={CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES]}
                                            // labelStyle={{ color: Colors.primaryColor, fontSize: 17, fontFamily: 'NunitoSans-Bold' }}
                                            dropDownStyle={{
                                                width: 210,
                                                marginLeft: 10,
                                            }}
                                            itemStyle={{
                                                justifyContent: 'flex-start',
                                            }}
                                            globalTextStyle={{
                                                fontFamily: 'NunitoSans-SemiBold',
                                                fontSize: 14,
                                                color: Colors.fontDark,
                                                marginLeft: 5,
                                            }}
                                            onChangeItem={item => {
                                                // setState({ sort: selectedSort }),
                                                //sortingFunc(selectedSort);
                                                setSelectedChartDropdownValueLineChart(item.value);
                                            }}
                                            defaultValue={selectedChartDropdownValueLineChart}
                                        />

                                        <View style={{ flexDirection: "row", marginLeft: 20, }}>

                                            <View style={{ flexDirection: 'row', zIndex: 1 }}> */}

            {/* <TouchableOpacity style={{
                                                    justifyContent: 'center',
                                                    width: 100,
                                                }}
                                                    onPress={() => {
                                                        // lineFilterPressed(1);
                                                        // setLineFilterTapped(1);
                                                        setExpandLineSelection(!expandLineSelection);;
                                                    }}>
                                                    <View style={{
                                                        justifyContent: 'center',
                                                        flexDirection: 'row',
                                                        borderWidth: 0,
                                                        borderColor: '#4E9F7D',
                                                        borderRadius: 8,
                                                        alignItems: 'center',
                                                        backgroundColor: '#FFFFFF',

                                                        position: 'relative',

                                                        paddingHorizontal: 15, backgroundColor: Colors.whiteColor, height: 40, width: 120, alignItems: 'center', borderRadius: 7, flexDirection: 'row', justifyContent: 'center', shadowColor: '#000',
                                                        shadowOffset: {
                                                            width: 0,
                                                            height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                    }}>
                                                        <Feather name='filter' size={18} color={Colors.primaryColor} />
                                                        <Text style={{
                                                            color: Colors.primaryColor,
                                                            fontSize: 14,
                                                            fontFamily: 'Nunitosans-bold',
                                                            marginLeft: 7
                                                        }}>
                                                            Filter
                                                        </Text>
                                                    </View>
                                                </TouchableOpacity> */}

            {/* <View style={{ flexDirection: 'row', marginLeft: 25 }}>
                                                    <View style={{ alignSelf: "center" }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                                        <EvilIcons name="calendar" size={25} color={Colors.primaryColor} />
                                                    </View>

                                                    <TouchableOpacity onPress={() => {
                                                        setShowDateTimePicker(true);
                                                        setShowDateTimePicker1(false);
                                                    }} style={{
                                                        marginHorizontal: 4,
                                                        marginVertical: 10
                                                    }}>
                                                        <Text style={{ fontFamily: "NunitoSans-Regular" }}>{moment(rev_date).format("DD MMM yyyy")}</Text>
                                                    </TouchableOpacity>

                                                    <Text style={{ fontFamily: "NunitoSans-Regular" }}>-</Text>

                                                    <TouchableOpacity onPress={() => {
                                                        setShowDateTimePicker(false);
                                                        setShowDateTimePicker1(true);
                                                    }} style={{
                                                        marginHorizontal: 4,
                                                        marginVertical: 10
                                                    }}>
                                                        <Text style={{ fontFamily: "NunitoSans-Regular" }}>{moment(rev_date1).format("DD MMM yyyy")}</Text>
                                                    </TouchableOpacity>
                                                </View> */}

            {/* {expandLineSelection ?
                                                    <View style={[styles.ManageFilterBox]}>
                                                    
                                                    <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10}}>  
                                                        <Text style={{fontSize: 16, Colors: '#27353C', fontFamily: 'Nunitosans-bold'}}>Manage Filter</Text>
                                                    </View>
                                                    <View style={{borderWidth: 0.5, borderColor: '#D2D2D2', width: '100%', marginTop: 5, marginBottom: 5}}></View>
                                                    <View style={{flexDirection: 'row', alignItems: 'center', margin: 10, height: 35}}>
                                                        <DropDownPicker
                                                                arrowColor={'#BDBDBD'}
                                                                arrowSize={23}
                                                                arrowStyle={{ fontWeight: 'bold' }}
                                                                style={{ width: 250, height: 35}}
                                                                items={[
                                                                    { label: 'Daily', value: 1 },
                                                                    { label: 'Weekly', value: 2 },
                                                                    { label: 'Monthly', value: 3 },
                                                                    //{ label: 'Hour of day', value: 4 }, 
                                                                    //{ label: 'Day of week', value: 5 }
                                                                ]}
                                                                placeholder={'Field name'}
                                                                placeholderStyle={{ color: 'black'}}
                                                                onChangeItem={selectedSort => {
                                                                    // setState({ sort: selectedSort }),
                                                                    //sortingFunc(selectedSort);
                                                                }
                                                                }
                                                            />
                                                        <DropDownPicker
                                                                arrowColor={'#BDBDBD'}
                                                                arrowSize={23}
                                                                arrowStyle={{ fontWeight: 'bold' }}
                                                                style={{ marginLeft:'5%' , width: 150}}
                                                                dropDownStyle={{ marginLeft:'5%' , width: 150 }}
                                                                items={[
                                                                    { label: 'Daily', value: 1 },
                                                                    { label: 'Weekly', value: 2 },
                                                                    { label: 'Monthly', value: 3 },
                                                                    //{ label: 'Hour of day', value: 4 }, 
                                                                    //{ label: 'Day of week', value: 5 }
                                                                ]}
                                                                placeholder={'is'}
                                                                placeholderStyle={{ color: 'black'}}
                                                                onChangeItem={selectedSort => {
                                                                    // setState({ sort: selectedSort }),
                                                                    //sortingFunc(selectedSort);
                                                                }
                                                                }
                                                            />
                                                        </View>
                                                        <View style={{flexDirection: 'row', alignItems: 'center', margin: 10, height: 35, zIndex: -1 }}>
                                                        <TextInput style={{width: 400, borderWidth: 1, borderColor: '#D2D2D2', alignItems: 'center', justifyContent: 'center', color: 'black'}} 
                                                        placeholder='   Field Value'
                                                        placeholderStyle={{color: 'black', justifyContent: 'center', alignItems: 'center'}}
                                                        
                                                        /> 
                                                        </View>
                                                        
                                                        <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10, zIndex: -1 }}>
                                                        <TouchableOpacity style={{
                                                        justifyContent: 'center', 
                                                        width: '40%'
                                                        }}
                                                        onPress={() => {
                                                            
                                                        }}>
                                                        <View style={{ 
                                                            justifyContent: 'center', 
                                                            width: '100%', 
                                                            flexDirection: 'row', 
                                                            borderWidth: 1, 
                                                            borderColor: '#E0E0E0', 
                                                            borderRadius: 5, 
                                                            height: 35, 
                                                            alignItems: 'center',
                                                            backgroundColor: '#FFFFFF',
                                                            height: Dimensions.get('screen').height * 0.040 
                                                            }}>
                                                        <Text style={{
                                                            color: '#27353C',
                                                            fontSize: 14,
                                                            fontFamily: 'Nunitosans-bold',
                                                            marginLeft: 7
                                                        }}>
                                                            Add New Filter   
                                                        </Text>
                                                        </View>
                                                        </TouchableOpacity>
                                                        </View>
                                                        <View style={{borderWidth: 0.5, borderColor: '#D2D2D2', width: '100%', marginTop: 5, marginBottom: 5}}></View>
                                                        <View style={{flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', margin: 10, zIndex: -1 }}>
                                                        <TouchableOpacity style={{
                                                        justifyContent: 'center', 
                                                        width: '30%',
                                                        
                                                        }}
                                                        onPress={() => {
                                                            setExpandLineSelection(!expandLineSelection)
                                                        }}>
                                                        <View style={{ 
                                                            justifyContent: 'center', 
                                                            width: '100%', 
                                                            flexDirection: 'row', 
                                                            borderWidth: 1, 
                                                            borderColor: '#BDBDBD', 
                                                            borderRadius: 5, 
                                                            height: 35, 
                                                            alignItems: 'center',
                                                            backgroundColor: '#FFFFFF',
                                                            height: Dimensions.get('screen').height * 0.050 
                                                            }}>
                                                        <Text style={{
                                                            color: '#BDBDBD',
                                                            fontSize: 15,
                                                            fontFamily: 'Nunitosans-bold',
                                                            marginLeft: 7
                                                        }}>
                                                            CANCEL   
                                                        </Text>
                                                        </View>
                                                        </TouchableOpacity>
                                                        <TouchableOpacity style={{
                                                        justifyContent: 'center', 
                                                        width: '30%',
                                                        marginLeft: '3%',
                                                        }}
                                                        onPress={() => {
                                                            setExpandLineSelection(!expandLineSelection)
                                                        }}>
                                                        <View style={{ 
                                                            justifyContent: 'center', 
                                                            width: '100%', 
                                                            flexDirection: 'row', 
                                                            backgroundColor: '#4E9F7D',
                                                            borderRadius: 5, 
                                                            height: 35, 
                                                            alignItems: 'center',
                                                            height: Dimensions.get('screen').height * 0.050,
                                                            }}>
                                                        <Text style={{
                                                            color: '#FFFFFF',
                                                            fontSize: 15,
                                                            fontFamily: 'Nunitosans-bold',
                                                            marginLeft: 7
                                                        }}>
                                                            APPLY   
                                                        </Text>
                                                        </View>
                                                        </TouchableOpacity>
                                                        </View>
                                                        </View>
                                                    :null} */}
            {/* </View>
                                        </View>

                                    </View> */}

            {/* <DropDownPicker
                                        //containerStyle={{flexDirection: 'row', justifyContent: 'center'}
                                        placeholder={"Select an Outlet"}
                                        customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                        //customTickIcon={(press) => <View style={{backgroundColor: Colors.primaryColor}}><Icon1 name={"check"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} /></View>}
                                        customTickIcon1={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.primaryColor : Colors.fieldtBgColor} size={25} />}
                                        style={{ width: Dimensions.get('screen').width * 0.2 }}
                                        labelStyle={{
                                            fontSize: 15,
                                            color: '#4E9F7D'
                                        }}
                                        placeholderStyle={{ color: 'black' }}
                                        itemStyle={{ color: '#4E9F7D' }}
                                        globalTextStyle={{ color: '#4E9F7D' }}
                                        arrowColor={Colors.primaryColor}
                                        arrowSize={25}
                                        multiple={true}
                                        multipleText="%d outlets have been selected."
                                        extraLabel={true}
                                        labelStyle={{ fontFamily: 'NunitoSans-Bold' }}
                                        items={outlets ? outlets.map(o => {
                                            return (
                                                {
                                                    label: o.outletName !== null ? o.outletName : 'Not Found',
                                                    value: o.outletId !== null ? o.outletId : 'Not Found',
                                                }
                                            )
                                        }) : []}
                                        onChangeItem={(data) => {
                                            console.log("data", data)
                                            setState({ selectedOutlets: data }, () => { getTotalSales(selectedTotalSales) })

                                        }}
                                    /> */}
            {/* </View>

                                <View style={{ flexDirection: 'row', zIndex: -1 }}> */}
            {/* <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesBarChartPeriod == CHART_PERIOD.DEFAULT ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesBarChartPeriod == CHART_PERIOD.DEFAULT ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesBarChartPeriod(CHART_PERIOD.DEFAULT) }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.DEFAULT ? Colors.blackColor : Colors.descriptionColor }}>DEFAULT</Text>
                                    </TouchableOpacity> */}
            {/* <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesLineChartPeriod == CHART_PERIOD.TODAY ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesLineChartPeriod == CHART_PERIOD.TODAY ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesLineChartPeriod(CHART_PERIOD.TODAY); setSalesBarChartPeriod(CHART_PERIOD.TODAY); setRev_date(moment(Date.now())); setRev_date1(moment(Date.now())); }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', color: salesLineChartPeriod == CHART_PERIOD.TODAY ? Colors.blackColor : Colors.descriptionColor }}>TODAY</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesLineChartPeriod == CHART_PERIOD.THIS_WEEK ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesLineChartPeriod == CHART_PERIOD.THIS_WEEK ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesLineChartPeriod(CHART_PERIOD.THIS_WEEK); setSalesBarChartPeriod(CHART_PERIOD.THIS_WEEK); setRev_date(moment(Date.now()).subtract(7, 'days')); setRev_date1(moment(Date.now())); }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', color: salesLineChartPeriod == CHART_PERIOD.THIS_WEEK ? Colors.blackColor : Colors.descriptionColor }}>1W</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesLineChartPeriod == CHART_PERIOD.THIS_MONTH ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesLineChartPeriod == CHART_PERIOD.THIS_MONTH ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH); setSalesBarChartPeriod(CHART_PERIOD.THIS_MONTH); setRev_date(moment(Date.now()).subtract(31, 'days')); setRev_date1(moment(Date.now())); }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', color: salesLineChartPeriod == CHART_PERIOD.THIS_MONTH ? Colors.blackColor : Colors.descriptionColor }}>1M</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesLineChartPeriod(CHART_PERIOD.THREE_MONTHS); setSalesBarChartPeriod(CHART_PERIOD.THREE_MONTHS); setRev_date(moment(Date.now()).subtract(3, 'months')); setRev_date1(moment(Date.now())); }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', color: salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS ? Colors.blackColor : Colors.descriptionColor }}>3M</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesLineChartPeriod(CHART_PERIOD.SIX_MONTHS); setSalesBarChartPeriod(CHART_PERIOD.SIX_MONTHS); setRev_date(moment(Date.now()).subtract(6, 'month')); setRev_date1(moment(Date.now())); }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', color: salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS ? Colors.blackColor : Colors.descriptionColor }}>6M</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesLineChartPeriod == CHART_PERIOD.THIS_YEAR ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesLineChartPeriod == CHART_PERIOD.THIS_YEAR ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesLineChartPeriod(CHART_PERIOD.THIS_YEAR); setSalesBarChartPeriod(CHART_PERIOD.THIS_YEAR); setRev_date(moment().startOf('year')); setRev_date1(moment().endOf('year')); }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', color: salesLineChartPeriod == CHART_PERIOD.THIS_YEAR ? Colors.blackColor : Colors.descriptionColor }}>1Y</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesLineChartPeriod == CHART_PERIOD.YTD ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesLineChartPeriod == CHART_PERIOD.YTD ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesLineChartPeriod(CHART_PERIOD.YTD); setSalesBarChartPeriod(CHART_PERIOD.YTD); setRev_date(moment(Date.now()).subtract(12, 'months')); setRev_date1(moment(Date.now())); }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', color: salesLineChartPeriod == CHART_PERIOD.YTD ? Colors.blackColor : Colors.descriptionColor }}>YTD</Text>
                                    </TouchableOpacity>
                                </View> */}

            {/* </View>
                            <View style={{
                                // width: Dimensions.get('screen').width * 0.65
                                // left: 0,
                                // backgroundColor: 'red',
                                alignSelf: 'flex-start',
                                marginLeft: '3%',
                                marginBottom: '1%',
                                zIndex: -1,
                            }}>
                                {/* <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.descriptionColor, fontSize: 16 }}>
                                    RM
                            </Text> */}
            {/* </View>
                            {console.log("outletSalesData", outletSalesData)} */}
            {/* <LineChart
                                data={
                                    {
                                        labels: outletLabel,
                                        datasets: outletSalesData

                                        // labels: CHART_HOURLY_LABEL_LIST,
                                        // datasets: [
                                        //     {
                                        //         data: totalHourlyDataDict,
                                        //     }
                                        // ]

                                        // datasets:
                                        //     [
                                        //         {
                                        //             data: outletSalesData.data.length == 0 ? tempData : outletSalesData[0],
                                        //             color: (opacity = 1) => Colors.tabMint,
                                        //         },
                                        //         {
                                        //             data: outletSalesData.length == 0 ? tempData : outletSalesData[0],
                                        //             color: (opacity = 1) => Colors.tabGold,
                                        //         },
                                        //         // {
                                        //         //     data: [0, 20, 30, 80, 99, 0],
                                        //         //     // data: outletSalesData.length == 0 ? tempData : outletSalesData[0],
                                        //         //     color: (opacity = 1) => Colors.tabMint,
                                        //         // },
                                        //         // {
                                        //         //     data: [0, 90, 50, 80, 80, 0],
                                        //         //     //data: outletSalesData.length == 0 ? tempData : outletSalesData[0],
                                        //         //     color: (opacity = 1) => Colors.tabGold,
                                        //         // },
                                        //         // {
                                        //         //     data: [200, 100, 70, 50, 60, 200],
                                        //         //     //data: outletSalesData.length == 0 ? tempData : outletSalesData[0],
                                        //         //     color: (opacity = 1) => Colors.tabGrey,
                                        //         // },


                                        //     ]                                        
                                    }
                                }
                                width={Dimensions.get('screen').width * 0.7} // from react-native
                                height={350}
                                fromZero={true}
                                yAxisInterval={1} // optional, defaults to 1

                                segments={6}
                                chartConfig={{
                                    backgroundGradientFrom: '#fff',
                                    backgroundGradientTo: '#fff',
                                    color: (opacity = 1) => `#fff`,
                                    labelColor: (opacity = 1) => `rgba(0, 0, 0, 1)`,
                                    strokeWidth: 2, // optional, default 3
                                    propsForLabels: { fontFamily: 'NunitoSans-Bold', fontSize: 14 },
                                    formatYLabel: { marginRight: 30 },
                                    decimalPlaces: 0,
                                    propsForBackgroundLines: {
                                        strokeWidth: 1,
                                        stroke: '#efefef',
                                        strokeDasharray: '0',
                                    },
                                    scrollableDotFill: Colors.tabGold,
                                    propsForDots: {

                                        r: "4",
                                        strokeWidth: "2",
                                    }
                                }}

                                style={{
                                    fontFamily: 'NunitoSans-Bold',
                                    marginVertical: 10,
                                }} 

                            />*/}

            {/* Line Chart */}

            {/* <View style={{
                                // backgroundColor: 'red'
                            }}>
                                <FusionCharts
                                    type={CHART_DATA2[CHART_TYPE.DASHBOARD_TODAY_SALES].type}
                                    width={Dimensions.get('screen').width * (0.9 - Styles.sideBarWidth)}
                                    height={Dimensions.get('screen').height * 0.4}
                                    //dataFormat={todaySalesChart.dataFormat}
                                    //dataSource={todaySalesChart.dataSource}
                                    libraryPath={FS_LIBRARY_PATH}
                                    //events={eventsChart}
                                />
                            </View> */}

            {/* <View style={{
                                // backgroundColor: 'red'
                                zIndex: -1,
                            }}>
                                {/* <FusionCharts
                                    type={CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].type}
                                    width={Dimensions.get('screen').width * (0.9 - Styles.sideBarWidth)}
                                    height={Dimensions.get('screen').height * 0.5}
                                    dataFormat={salesLineChart.dataFormat}
                                    dataSource={salesLineChart.dataSource}
                                    libraryPath={FS_LIBRARY_PATH}
                                    events={eventsChart}
                                /> */}
            {/* </View>
                        </View> 

                        <View style={{
                            backgroundColor: Colors.whiteColor, 
                            // borderRadius: 10, 
                            borderRadius: 5,
                            marginTop: 20, padding: 10, alignItems: 'center', shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            // elevation: 1,
                            elevation: 3,
                            zIndex: -1,
                        }}>
                            <View style={{ width: '100%', flexDirection: 'row', marginVertical: 10, marginBottom: 20, alignItems: 'center' }}>
                                <View style={{ marginHorizontal: 5 }}>
                                    <GCalendar width={22} height={22} />
                                </View>
                                {/* <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.primaryColor, fontSize: 18, marginHorizontal: 10, alignSelf: 'flex-start' }}>Overall Sales</Text> */}
            {/* <DropDownPicker
                                    arrowColor={'#BDBDBD'}
                                    arrowSize={23}
                                    arrowStyle={{
                                        fontWeight: 'bold',
                                        bottom: 1,
                                    }}
                                    style={{ width: 210, height: 45, marginLeft: 10 }}
                                    dropDownStyle={{ width: 210, marginLeft: 10 }}
                                    items={CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES]}
                                    // labelStyle={{ color: Colors.primaryColor, fontSize: 17, fontFamily: 'NunitoSans-Bold' }}
                                    dropDownStyle={{
                                        width: 210,
                                        marginLeft: 10,
                                    }}
                                    itemStyle={{
                                        justifyContent: 'flex-start',
                                    }}
                                    globalTextStyle={{
                                        fontFamily: 'NunitoSans-SemiBold',
                                        fontSize: 14,
                                        color: Colors.fontDark,
                                        marginLeft: 5,
                                    }}
                                    onChangeItem={item => {
                                        // setState({ sort: selectedSort }),
                                        //sortingFunc(selectedSort);
                                        setSelectedChartDropdownValueBarChart(item.value);
                                    }}
                                    defaultValue={selectedChartDropdownValueBarChart}
                                />

                                <View style={{ flexDirection: "row", marginLeft: 15 }}>
                                    <View style={{ flexDirection: 'column', zIndex: 1 }}> */}
            {/* <TouchableOpacity style={{
                                            justifyContent: 'center',
                                            width: 100,
                                            marginLeft: 5,
                                        }}
                                            onPress={() => {
                                                barFilterPressed(1); setBarFilterTapped(1); setExpandBarSelection(!expandBarSelection);;
                                            }}>
                                            <View style={{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 0,
                                                borderColor: '#4E9F7D',
                                                borderRadius: 8,
                                                alignItems: 'center',
                                                backgroundColor: '#FFFFFF',

                                                position: 'relative',

                                                paddingHorizontal: 15, backgroundColor: Colors.whiteColor, height: 40, width: 120, alignItems: 'center', borderRadius: 7, flexDirection: 'row', justifyContent: 'center', shadowColor: '#000',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                            }}>
                                                <Feather name='filter' size={18} color={Colors.primaryColor} />
                                                <Text style={{
                                                    color: Colors.primaryColor,
                                                    fontSize: 14,
                                                    fontFamily: 'Nunitosans-bold',
                                                    marginLeft: 7
                                                }}>
                                                    Filter
                                                </Text>
                                            </View>
                                        </TouchableOpacity> */}
            {/* {expandBarSelection ?
                                <View style={styles.ManageFilterBox}>
                                
                                <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10}}>  
                                    <Text style={{fontSize: 16, Colors: '#27353C', fontFamily: 'Nunitosans-bold'}}>Manage Filter</Text>
                                </View>
                                <View style={{borderWidth: 0.5, borderColor: '#D2D2D2', width: '100%', marginTop: 5, marginBottom: 5}}></View>
                                <View style={{flexDirection: 'row', alignItems: 'center', margin: 10, height: 35}}>
                                    <DropDownPicker
                                            arrowColor={'#BDBDBD'}
                                            arrowSize={23}
                                            arrowStyle={{ fontWeight: 'bold' }}
                                            style={{ width: 250, height: 35}}
                                            items={[
                                                { label: 'Daily', value: 1 },
                                                { label: 'Weekly', value: 2 },
                                                { label: 'Monthly', value: 3 },
                                                //{ label: 'Hour of day', value: 4 }, 
                                                //{ label: 'Day of week', value: 5 }
                                            ]}
                                            placeholder={'Field name'}
                                            placeholderStyle={{ color: 'black'}}
                                            onChangeItem={selectedSort => {
                                                // setState({ sort: selectedSort }),
                                                //sortingFunc(selectedSort);
                                            }
                                            }
                                        />
                                    <DropDownPicker
                                            arrowColor={'#BDBDBD'}
                                            arrowSize={23}
                                            arrowStyle={{ fontWeight: 'bold' }}
                                            style={{ marginLeft:'5%' , width: 150}}
                                            dropDownStyle={{ marginLeft:'5%' , width: 150 }}
                                            items={[
                                                { label: 'Daily', value: 1 },
                                                { label: 'Weekly', value: 2 },
                                                { label: 'Monthly', value: 3 },
                                                //{ label: 'Hour of day', value: 4 }, 
                                                //{ label: 'Day of week', value: 5 }
                                            ]}
                                            placeholder={'is'}
                                            placeholderStyle={{ color: 'black'}}
                                            onChangeItem={selectedSort => {
                                                // setState({ sort: selectedSort }),
                                                //sortingFunc(selectedSort);
                                            }
                                            }
                                        />
                                    </View>
                                    <View style={{flexDirection: 'row', alignItems: 'center', margin: 10, height: 35, zIndex: -1 }}>
                                    <TextInput style={{width: 400, borderWidth: 1, borderColor: '#D2D2D2', alignItems: 'center', justifyContent: 'center', color: 'black'}} 
                                    placeholder='   Field Value'
                                    placeholderStyle={{color: 'black', justifyContent: 'center', alignItems: 'center'}}
                                    
                                    /> 
                                    </View>
                                    
                                    <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10, zIndex: -1 }}>
                                    <TouchableOpacity style={{
                                    justifyContent: 'center', 
                                    width: '40%'
                                    }}
                                    onPress={() => {
                                        
                                    }}>
                                    <View style={{ 
                                        justifyContent: 'center', 
                                        width: '100%', 
                                        flexDirection: 'row', 
                                        borderWidth: 1, 
                                        borderColor: '#E0E0E0', 
                                        borderRadius: 5, 
                                        height: 35, 
                                        alignItems: 'center',
                                        backgroundColor: '#FFFFFF',
                                        height: Dimensions.get('screen').height * 0.040 
                                        }}>
                                    <Text style={{
                                        color: '#27353C',
                                        fontSize: 14,
                                        fontFamily: 'Nunitosans-bold',
                                        marginLeft: 7
                                    }}>
                                        Add new filter   
                                    </Text>
                                    </View>
                                    </TouchableOpacity>
                                    </View>
                                    <View style={{borderWidth: 0.5, borderColor: '#D2D2D2', width: '100%', marginTop: 5, marginBottom: 5}}></View>
                                    <View style={{flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', margin: 10, zIndex: -1 }}>
                                    <TouchableOpacity style={{
                                    justifyContent: 'center', 
                                    width: '30%',
                                    
                                    }}
                                    onPress={() => {
                                        setExpandBarSelection(!expandBarSelection)
                                    }}>
                                    <View style={{ 
                                        justifyContent: 'center', 
                                        width: '100%', 
                                        flexDirection: 'row', 
                                        borderWidth: 1, 
                                        borderColor: '#BDBDBD', 
                                        borderRadius: 5, 
                                        height: 35, 
                                        alignItems: 'center',
                                        backgroundColor: '#FFFFFF',
                                        height: Dimensions.get('screen').height * 0.050 
                                        }}>
                                    <Text style={{
                                        color: '#BDBDBD',
                                        fontSize: 15,
                                        fontFamily: 'Nunitosans-bold',
                                        marginLeft: 7
                                    }}>
                                        CANCEL   
                                    </Text>
                                    </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{
                                    justifyContent: 'center', 
                                    width: '30%',
                                    marginLeft: '3%',
                                    }}
                                    onPress={() => {
                                        setExpandBarSelection(!expandBarSelection)
                                    }}>
                                    <View style={{ 
                                        justifyContent: 'center', 
                                        width: '100%', 
                                        flexDirection: 'row', 
                                        backgroundColor: '#4E9F7D',
                                        borderRadius: 5, 
                                        height: 35, 
                                        alignItems: 'center',
                                        height: Dimensions.get('screen').height * 0.050,
                                        }}>
                                    <Text style={{
                                        color: '#FFFFFF',
                                        fontSize: 15,
                                        fontFamily: 'Nunitosans-bold',
                                        marginLeft: 7
                                    }}>
                                        APPLY   
                                    </Text>
                                    </View>
                                    </TouchableOpacity>
                                    </View>
                                    </View>
                                    :null} */}
            {/* </View>
                                </View> */}

            {/* </View> */}
            {/* <View style={{ alignSelf: 'flex-start', marginLeft: '3%', marginBottom: '1%', flexDirection: 'row', zIndex: -1 }}> */}
            {/* <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.descriptionColor, fontSize: 16 }}>
                                    RM
                                </Text> */}
            {/* </View> */}

            {/* <ScrollView
                                horizontal={true}
                                contentContainerStyle={{ height: 400 }}
                                style={{ scaleX: 1 }}
                                showsHorizontalScrollIndicator={false}>

                                <View style={{ flexDirection: 'row' }}>
                                    <BarChart
                                        data={
                                            {
                                                // labels: CHART_HOURLY_LABEL_LIST,
                                                // datasets: [
                                                //     {
                                                //         data: singleHourlyDataDict,
                                                //         color: (opacity = 1) => `rgba(30, 25, 240, ${opacity})`,
                                                //     }
                                                // ],
                                                labels: displayTodayLabel,
                                                datasets: [
                                                    {
                                                        data: displayTodayData,

                                                        color: (opacity = 1) => `rgba(30, 25, 240, ${opacity})`,
                                                    },

                                                ],
                                                barColors: ['#dfe4ea', '#ced6e0', '#a4b0be'],

                                            }
                                        }
                                        width={Dimensions.get('screen').width * 1.2} // from react-native
                                        height={350}
                                        fromZero={true}
                                        showValuesOnTopOfBars={true}
                                        segments={6}
                                        yAxisInterval={100} // optional, defaults to 1
                                        chartConfig={{
                                            barPercentage: 10 / displayTodayData.length,
                                            fillShadowGradient: Colors.tabGold,
                                            fillShadowGradientOpacity: 1,
                                            backgroundGradientFrom: '#fff',
                                            backgroundGradientTo: '#fff',
                                            fontFamily: 'NunitoSans-Bold',




                                            decimalPlaces: 0,
                                            color: (opacity = 1) => Colors.tabGold,
                                            labelColor: (opacity = 1) => `rgba(0, 0, 0, 1)`,
                                            strokeWidth: 2, // optional, default 3
                                            propsForLabels: { fontFamily: 'NunitoSans-Bold', fontSize: 10 },
                                            propsForBackgroundLines: {
                                                strokeWidth: 1,
                                                stroke: '#efefef',
                                                strokeDasharray: '0',
                                            },
                                            style: {
                                                borderRadius: 16
                                            },
                                            barRadius: 16
                                        }}
                                        style={{
                                            fontFamily: 'NunitoSans-Bold',
                                            marginTop: 10,
                                            paddingTop: 33,
                                            borderRadius: 16
                                        }}

                                    />
                                </View></ScrollView> */}

            {/* <View style={{
                                // backgroundColor: 'red'
                                zIndex: -1,
                            }}> */}
            {/* <FusionCharts
                                    type={CHART_DATA[CHART_TYPE.DASHBOARD_TODAY_SALES].type}
                                    width={Dimensions.get('screen').width * (0.9 - Styles.sideBarWidth)}
                                    height={Dimensions.get('screen').height * 0.5}
                                    dataFormat={todaySalesChart.dataFormat}
                                    dataSource={todaySalesChart.dataSource}
                                    libraryPath={FS_LIBRARY_PATH}
                                    events={eventsChart}
                                />
                            </View>
                        </View> */}

            {/* Daily Sales Report */}
            <View style={{ width: "100%", marginTop: 20 }}>
              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  padding: switchMerchant ? 0 : 10,
                  paddingHorizontal: 5,
                  // height: '82%',
                  // borderRadius: 10,
                  borderRadius: 5,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  // elevation: 1,
                  elevation: 3,

                  // marginBottom: '15%',
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    padding: switchMerchant ? 0 : 10,
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    marginHorizontal: switchMerchant ? 5 : 0,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom:
                          Platform.OS == "android"
                            ? switchMerchant
                              ? 1
                              : 0
                            : 0,
                      }}
                    >
                      <Magnify
                        width={switchMerchant ? 10 : 20}
                        height={switchMerchant ? 10 : 20}
                      />
                      <Text
                        style={[
                          {
                            fontFamily: "NunitoSans-Bold",
                            color: Colors.primaryColor,
                            fontSize: 18,
                            marginHorizontal: 10,
                            marginBottom: Platform.OS === "ios" ? 0 : 1,
                          },
                          switchMerchant
                            ? {
                                fontSize: 14,
                              }
                            : {},
                        ]}
                      >
                        Promotion Details
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <TouchableOpacity
                        style={[
                          {
                            justifyContent: "center",
                            flexDirection: "row",
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: "#4E9F7D",
                            borderRadius: 5,
                            //width: 160,
                            paddingHorizontal: 10,
                            height: 40,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                            marginRight: 10,

                            opacity: !showDetails ? 0 : 100,
                          },
                          switchMerchant
                            ? {
                                height: 35,
                              }
                            : {},
                        ]}
                        onPress={() => {
                          setShowDetails(false);

                          setCurrentPage(1);
                          setPageCount(
                            Math.ceil(dailySalesDetailsList.length / perPage)
                          );
                        }}
                        disabled={!showDetails}
                      >
                        <AntDesign
                          name="arrowleft"
                          size={switchMerchant ? 10 : 20}
                          color={Colors.whiteColor}
                          style={
                            {
                              //top: -1,
                              //marginRight: -5,
                            }
                          }
                        />
                        <Text
                          style={[
                            {
                              color: Colors.whiteColor,
                              marginLeft: 5,
                              fontSize: 16,
                              fontFamily: "NunitoSans-Bold",
                            },
                            switchMerchant
                              ? {
                                  fontSize: 10,
                                }
                              : {},
                          ]}
                        >
                          Summary
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 15,
                      justifyContent: "space-between",
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      marginHorizontal: 10,
                      marginBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        paddingHorizontal: 15,
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: 10,
                        paddingVertical: 10,
                        justifyContent: "center",
                        backgroundColor: Colors.whiteColor,
                        shadowOpacity: 0,
                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: 1,
                      }}
                    >
                      <View
                        style={{ alignSelf: "center", marginRight: 5 }}
                        onPress={() => {
                          setState({
                            pickerMode: "date",
                            showDateTimePicker: true,
                          });
                        }}
                      >
                        <GCalendar
                          width={switchMerchant ? 15 : 20}
                          height={switchMerchant ? 15 : 20}
                        />
                      </View>

                      <DatePicker
                        selected={rev_date.toDate()}
                        onChange={(date) => {
                          setRev_date(moment(date).startOf('day'));
                        }}
                        maxDate={moment(rev_date1).toDate()}
                      />

                      <Text
                        style={{
                          fontFamily: "NunitoSans-Regular",
                          paddingHorizontal: 5,
                        }}
                      >
                        -
                      </Text>

                      <DatePicker
                        selected={rev_date1.toDate()}
                        onChange={(date) => {
                          setRev_date1(moment(date).endOf('day'));
                        }}
                        minDate={moment(rev_date).toDate()}
                      />
                    </View>
                  </View>
                </View>

                {!showDetails ? (
                  <View
                    style={{
                      marginTop: 20,
                      paddingHorizontal: 3,
                      flexDirection: "row",
                    }}
                  >
                    {/* <View style={{ flexDirection: 'row', flex: 0.8, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}> */}
                    {/* <View style={{ flexDirection: 'column' }}>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                        <Text numberOfLines={1} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>No.</Text>
                                                        <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                    </View>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View> */}
                    {/* <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View> */}
                    {/* </View> */}
                    <View
                      style={{
                        flexDirection: "row",
                        flex: Platform.OS == "ios" ? 2.2 : 2,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <TouchableOpacity
                        style={{ flexDirection: "row" }}
                        onPress={() => {
                          if (
                            currReportSummarySort ===
                            REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_ASC
                          ) {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_DESC
                            );
                          } else {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_ASC
                            );
                          }
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            top: Platform.OS == "ios" ? 5 : 5,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              numberOfLines={1}
                              style={[
                                {
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Bold",
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 10,
                                    }
                                  : {},
                              ]}
                            >
                              Title
                            </Text>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View
                          style={[
                            { marginLeft: "3%" },
                            switchMerchant ? { justifyContent: "center" } : {},
                          ]}
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 1.3,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <TouchableOpacity
                        style={{ flexDirection: "row" }}
                        onPress={() => {
                          if (
                            currReportSummarySort ===
                            REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_ASC
                          ) {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_DESC
                            );
                          } else {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_ASC
                            );
                          }
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              top: Platform.OS == "ios" ? 5 : 5,
                            }}
                          >
                            <Text
                              numberOfLines={1}
                              style={[
                                {
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Bold",
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 10,
                                    }
                                  : {},
                              ]}
                            >
                              Type
                            </Text>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View
                          style={[
                            { marginLeft: "3%" },
                            switchMerchant ? { justifyContent: "center" } : {},
                          ]}
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 1.5,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <TouchableOpacity
                        style={{ flexDirection: "row" }}
                        onPress={() => {
                          if (
                            currReportSummarySort ===
                            REPORT_SORT_FIELD_TYPE.PROMOTION_START_DATE_ASC
                          ) {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.PROMOTION_START_DATE_DESC
                            );
                          } else {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.PROMOTION_START_DATE_ASC
                            );
                          }
                        }}
                      >
                        <View
                          style={[
                            {
                              flexDirection: "column",
                              top: Platform.OS == "ios" ? 5 : 5,
                            },
                          ]}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              numberOfLines={1}
                              style={[
                                {
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Bold",
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 10,
                                    }
                                  : {},
                              ]}
                            >
                              Duration
                            </Text>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View
                          style={[
                            { marginLeft: "3%" },
                            switchMerchant ? { justifyContent: "center" } : {},
                          ]}
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.PROMOTION_START_DATE_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.PROMOTION_START_DATE_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </View>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        flex: Platform.OS == "ios" ? 0.6 : 0.6,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <TouchableOpacity
                        style={{ flexDirection: "row" }}
                        onPress={() => {
                          if (
                            currReportSummarySort ===
                            REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                          ) {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC
                            );
                          } else {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                            );
                          }
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            top: Platform.OS == "ios" ? 5 : 5,
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={[
                              { fontSize: 14, fontFamily: "NunitoSans-Bold" },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            {"Order"}
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 8,
                                  }
                                : {},
                            ]}
                          >
                            Qty
                          </Text>
                        </View>
                        <View
                          style={[
                            { marginLeft: "3%" },
                            switchMerchant ? { justifyContent: "center" } : {},
                          ]}
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 0.8,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <TouchableOpacity
                        style={{ flexDirection: "row" }}
                        onPress={() => {
                          if (
                            currReportSummarySort ===
                            REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC
                          ) {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC
                            );
                          } else {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC
                            );
                          }
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            top: Platform.OS == "ios" ? 5 : 5,
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={[
                              {
                                fontSize: 16,
                                fontFamily: "NunitoSans-Bold",
                                textAlign: "left",
                              },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            Disc
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 8,
                                  }
                                : {},
                            ]}
                          >
                            RM
                          </Text>
                        </View>
                        <View
                          style={[
                            { marginLeft: "3%" },
                            switchMerchant ? { justifyContent: "center" } : {},
                          ]}
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 0.8,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <TouchableOpacity
                        style={{ flexDirection: "row" }}
                        onPress={() => {
                          if (
                            currReportSummarySort ===
                            REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC
                          ) {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_DESC
                            );
                          } else {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC
                            );
                          }
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            top: Platform.OS == "ios" ? 5 : 5,
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={[
                              { fontSize: 14, fontFamily: "NunitoSans-Bold" },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            Disc
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 8,
                                  }
                                : {},
                            ]}
                          >
                            %
                          </Text>
                        </View>
                        <View
                          style={[
                            { marginLeft: "3%" },
                            switchMerchant ? { justifyContent: "center" } : {},
                          ]}
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 0.8,
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <TouchableOpacity
                        style={{ flexDirection: "row" }}
                        onPress={() => {
                          if (
                            currReportSummarySort ===
                            REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                          ) {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC
                            );
                          } else {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                            );
                          }
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            top: Platform.OS == "ios" ? 5 : 5,
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={[
                              { fontSize: 14, fontFamily: "NunitoSans-Bold" },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            Sales
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 8,
                                  }
                                : {},
                            ]}
                          >
                            RM
                          </Text>
                        </View>
                        <View
                          style={[
                            { marginLeft: "3%" },
                            switchMerchant ? { justifyContent: "center" } : {},
                          ]}
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>

                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </View>
                      </TouchableOpacity>
                    </View>

                    {/* <View style={{ flexDirection: 'row', flex: 1.2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Tax</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TAX_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TAX_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TAX_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TAX_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>{'Service\nCharge'}</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                    {/* <View style={{ flexDirection: 'row', width: '5%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>GP</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>%</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.GP_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.GP_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.GP_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.GP_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                    {/* <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>Sales Return</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                    {/* <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Net Sales</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.NET_SALES_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.NET_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.NET_SALES_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.NET_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                    {/* <View style={{ flexDirection: 'row', flex: 1.95, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Average Net</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                  </View>
                ) : (
                  <View style={{ marginTop: 20, flexDirection: "row" }}>
                    {/* <View style={{ flexDirection: 'row', flex: 0.8, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}> */}
                    {/* <View style={{ flexDirection: 'column' }}>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                        <Text numberOfLines={1} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>No.</Text>
                                                        <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                    </View>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View> */}
                    {/* <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View> */}
                    {/* </View> */}
                    <View
                      style={{
                        flexDirection: "row",
                        flex: Platform.OS == "ios" ? 1.5 : 2,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <TouchableOpacity
                        style={{ flexDirection: "row" }}
                        onPress={() => {
                          if (
                            currReportSummarySort ===
                            REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_ASC
                          ) {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_DESC
                            );
                          } else {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_ASC
                            );
                          }
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "column",
                            top: Platform.OS == "ios" ? 5 : 5,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              numberOfLines={1}
                              style={[
                                {
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Bold",
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 10,
                                    }
                                  : {},
                              ]}
                            >
                              Title
                            </Text>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View
                          style={[
                            { marginLeft: "3%" },
                            switchMerchant ? { justifyContent: "center" } : {},
                          ]}
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 1.3,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <TouchableOpacity
                        style={{ flexDirection: "row" }}
                        onPress={() => {
                          if (
                            currReportSummarySort ===
                            REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_ASC
                          ) {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_DESC
                            );
                          } else {
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_ASC
                            );
                          }
                        }}
                      >
                        <View style={{ flexDirection: "column" }}>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              top: Platform.OS == "ios" ? 5 : 5,
                            }}
                          >
                            <Text
                              numberOfLines={1}
                              style={[
                                {
                                  fontSize: 16,
                                  fontFamily: "NunitoSans-Bold",
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 10,
                                    }
                                  : {},
                              ]}
                            >
                              Type
                            </Text>
                            <Text
                              style={{
                                fontSize: 10,
                                color: Colors.descriptionColor,
                              }}
                            ></Text>
                          </View>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <View
                          style={[
                            { marginLeft: "3%" },
                            switchMerchant ? { justifyContent: "center" } : {},
                          ]}
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </View>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 1.3,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          top: Platform.OS == "ios" ? 5 : 5,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            numberOfLines={1}
                            style={[
                              { fontSize: 14, fontFamily: "NunitoSans-Bold" },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            Date/Time
                          </Text>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </View>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                      <View
                        style={[
                          { marginLeft: "3%" },
                          switchMerchant ? { justifyContent: "center" } : {},
                        ]}
                      >
                        <TouchableOpacity
                          onPress={() =>
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC
                            )
                          }
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() =>
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC
                            )
                          }
                        >
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: Platform.OS == "ios" ? 0.8 : 0.8,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          top: Platform.OS == "ios" ? 5 : 5,
                        }}
                      >
                        <Text
                          numberOfLines={2}
                          style={[
                            { fontSize: 14, fontFamily: "NunitoSans-Bold" },
                            switchMerchant
                              ? {
                                  fontSize: 10,
                                }
                              : {},
                          ]}
                        >
                          {"Order"}
                        </Text>
                        <Text
                          style={[
                            { fontSize: 10, color: Colors.descriptionColor },
                            switchMerchant
                              ? {
                                  fontSize: 8,
                                }
                              : {},
                          ]}
                        >
                          Qty
                        </Text>
                      </View>
                      <View
                        style={[
                          { marginLeft: "3%" },
                          switchMerchant ? { justifyContent: "center" } : {},
                        ]}
                      >
                        <TouchableOpacity
                          onPress={() =>
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                            )
                          }
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() =>
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC
                            )
                          }
                        >
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 0.8,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          top: Platform.OS == "ios" ? 5 : 5,
                        }}
                      >
                        <Text
                          numberOfLines={2}
                          style={[
                            {
                              fontSize: 14,
                              fontFamily: "NunitoSans-Bold",
                              textAlign: "center",
                            },
                            switchMerchant
                              ? {
                                  fontSize: 10,
                                }
                              : {},
                          ]}
                        >
                          Disc
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        >
                          RM
                        </Text>
                      </View>
                      <View
                        style={[
                          { marginLeft: "3%" },
                          switchMerchant ? { justifyContent: "center" } : {},
                        ]}
                      >
                        <TouchableOpacity
                          onPress={() =>
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC
                            )
                          }
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() =>
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC
                            )
                          }
                        >
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 0.8,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          top: Platform.OS == "ios" ? 5 : 5,
                        }}
                      >
                        <Text
                          numberOfLines={2}
                          style={[
                            { fontSize: 14, fontFamily: "NunitoSans-Bold" },
                            switchMerchant
                              ? {
                                  fontSize: 10,
                                }
                              : {},
                          ]}
                        >
                          Disc
                        </Text>
                        <Text
                          style={[
                            { fontSize: 10, color: Colors.descriptionColor },
                            switchMerchant
                              ? {
                                  fontSize: 8,
                                }
                              : {},
                          ]}
                        >
                          %
                        </Text>
                      </View>
                      <View
                        style={[
                          { marginLeft: "3%" },
                          switchMerchant ? { justifyContent: "center" } : {},
                        ]}
                      >
                        <TouchableOpacity
                          onPress={() =>
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC
                            )
                          }
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() =>
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_DESC
                            )
                          }
                        >
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        flex: 0.8,
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        marginHorizontal: 3,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          top: Platform.OS == "ios" ? 5 : 5,
                        }}
                      >
                        <Text
                          numberOfLines={2}
                          style={[
                            { fontSize: 14, fontFamily: "NunitoSans-Bold" },
                            switchMerchant
                              ? {
                                  fontSize: 10,
                                }
                              : {},
                          ]}
                        >
                          Sales
                        </Text>
                        <Text
                          style={[
                            { fontSize: 10, color: Colors.descriptionColor },
                            switchMerchant
                              ? {
                                  fontSize: 8,
                                }
                              : {},
                          ]}
                        >
                          RM
                        </Text>
                      </View>
                      <View
                        style={[
                          { marginLeft: "3%" },
                          switchMerchant ? { justifyContent: "center" } : {},
                        ]}
                      >
                        <TouchableOpacity
                          onPress={() =>
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                            )
                          }
                        >
                          <Entypo
                            name="triangle-up"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </TouchableOpacity>

                        <TouchableOpacity
                          onPress={() =>
                            setCurrReportSummarySort(
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC
                            )
                          }
                        >
                          <Entypo
                            name="triangle-down"
                            size={switchMerchant ? 10 : 14}
                            color={
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }
                          ></Entypo>
                        </TouchableOpacity>
                      </View>
                    </View>

                    {/* <View style={{ flexDirection: 'row', flex: 1.2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Tax</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TAX_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TAX_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TAX_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TAX_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View>
                                            <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>{'Service\nCharge'}</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                    {/* <View style={{ flexDirection: 'row', width: '5%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>GP</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>%</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.GP_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.GP_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.GP_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.GP_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                    {/* <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>Sales Return</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                    {/* <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Net Sales</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.NET_SALES_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.NET_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.NET_SALES_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.NET_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                    {/* <View style={{ flexDirection: 'row', flex: 1.95, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Average Net</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                  </View>
                )}

                {!showDetails ? (
                  <>
                    {dailySalesDetailsList.length > 0 ? (
                      <FlatList
                        //data and extraData using dummy data
                        data={sortReportDataList(
                          dailySalesDetailsList,
                          currReportSummarySort
                        ).slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        )}
                        // extraData={renderItem}
                        renderItem={renderItem}
                        keyExtractor={(item, index) => String(index)}
                        style={{ marginTop: 10 }}
                        initialNumToRender={8}
                      />
                    ) : (
                      <View
                        style={{
                          height: Dimensions.get("screen").height * 0.4,
                        }}
                      >
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <Text
                            style={[
                              { color: Colors.descriptionColor },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            - No Data Available -
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                ) : (
                  <>
                    {transactionTypeSalesDetails.length > 0 ? (
                      <FlatList
                        data={sortReportDataList(
                          transactionTypeSalesDetails,
                          currReportDetailsSort
                        ).slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        )}
                        // extraData={transactionTypeSales}
                        renderItem={renderItemDetails}
                        keyExtractor={(item, index) => String(index)}
                        style={{ marginTop: 10 }}
                      />
                    ) : (
                      <View
                        style={{
                          height: Dimensions.get("screen").height * 0.4,
                        }}
                      >
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <Text
                            style={[
                              { color: Colors.descriptionColor },
                              switchMerchant ? { fontSize: 10 } : {},
                            ]}
                          >
                            - No Data Available -
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                )}

                {/* {searchList ? (

                                <FlatList
                                    data={lists}
                                    extraData={lists}
                                    renderItem={renderSearchItem}
                                    keyExtractor={(item, index) => String(index)}
                                />

                                ) : null} */}

                {/* <View style={{ marginLeft: '77%', flexDirection: 'row', marginTop: 30, marginBottom: 10, width: '100%' }}>
                                    <Text style={{ marginRight: '1%' }}>Page</Text>
                                    <View style={{ borderWidth: 1, borderColor: Colors.blackColor, width: '6%', height: 20, alignItems: 'center', backgroundColor: Colors.whiteColor }}>
                                        <Text>{currentPage}</Text>
                                    </View>
                                    <Text style={{ marginLeft: '1%', marginRight: '1%' }}>of {pageCount}</Text>
                                    <TouchableOpacity style={{ width: '3%', height: 20, backgroundColor: Colors.primaryColor, borderRadius: 2, justifyContent: 'center', alignItems: 'center' }} onPress={() => { prevPage() }}>
                                        <MaterialIcons name='keyboard-arrow-left' size={16} style={{ color: Colors.whiteColor }} />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ width: '3%', height: 20, backgroundColor: Colors.primaryColor, borderRadius: 2, justifyContent: 'center', alignItems: 'center' }} onPress={() => { nextPage() }}>
                                        <MaterialIcons name='keyboard-arrow-right' size={16} style={{ color: Colors.whiteColor }} />
                                    </TouchableOpacity>
                                </View> */}
              </View>
            </View>
            {!showDetails ? (
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 10,
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginBottom: 10,
                }}
              >
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "1%",
                  }}
                >
                  Items Showed
                </Text>
                <View
                  style={{
                    width: Platform.OS === "ios" ? 65 : "13%", //65,
                    height: switchMerchant ? 20 : 35,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    justifyContent: "center",
                    paddingHorizontal: Platform.OS === "ios" ? 0 : 0,
                    //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                    // paddingTop: '-60%',
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                    marginRight: "1%",
                  }}
                >
                  <Picker
                    style={{
                      inputIOS: {
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: "NunitoSans-Regular",
                      },
                      inputAndroid: {
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: "NunitoSans-Regular",
                        justifyContent: "center",
                        textAlign: "center",
                        height: 40,
                        color: "black",
                      },
                      inputAndroidContainer: { width: "100%" },
                      //backgroundColor: 'red',
                      height: 35,

                      chevronContainer: {
                        display: "none",
                      },
                      chevronDown: {
                        display: "none",
                      },
                      chevronUp: {
                        display: "none",
                      },
                    }}
                    selectedValue={perPage}
                    onValueChange={(value) => {
                      setPerPage(value);
                    }}
                  >
                    {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                      label: "All",
                      value: !showDetails
                        ? dailySalesDetailsList.length
                        : transactionTypeSalesDetails.length,
                    }).map((value, index) => {
                      return (
                        <Picker.Item
                          key={index}
                          label={value.label}
                          value={value.value}
                        />
                      );
                    })}
                  </Picker>
                </View>

                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "1%",
                  }}
                >
                  Page
                </Text>
                <View
                  style={[
                    {
                      width: 65,
                      height: 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: 22,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                    },
                    switchMerchant
                      ? { width: 60, height: 20, borderRadius: 5 }
                      : {},
                  ]}
                >
                  {console.log("currentPage")}
                  {console.log(currentPage)}

                  <TextInput
                    onChangeText={(text) => {
                      var currentPageTemp =
                        text.length > 0 ? parseInt(text) : 1;

                      setCurrentPage(
                        currentPageTemp > pageCount
                          ? pageCount
                          : currentPageTemp < 1
                          ? 1
                          : currentPageTemp
                      );
                    }}
                    placeholder={currentPage.toString()}
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    style={[
                      {
                        color: "black",
                        fontSize: 14,
                        fontFamily: "NunitoSans-Regular",
                        marginTop: Platform.OS === "ios" ? 0 : -15,
                        marginBottom: Platform.OS === "ios" ? 0 : -15,
                        textAlign: "center",
                        width: "100%",
                      },
                      switchMerchant
                        ? {
                            fontSize: 10,
                          }
                        : {},
                    ]}
                    value={currentPage.toString()}
                    defaultValue={currentPage.toString()}
                    keyboardType={"numeric"}
                    onFocus={() => {
                      setPushPagingToTop(true);
                    }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "1%",
                    marginLeft: "1%",
                  }}
                >
                  of {pageCount}
                </Text>
                <TouchableOpacity
                  style={[
                    {
                      width: 45,
                      height: 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    switchMerchant ? { width: 30, height: 20 } : {},
                  ]}
                  onPress={() => {
                    prevPage();
                  }}
                >
                  <ArrowLeft color={Colors.whiteColor} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    {
                      width: 45,
                      height: 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    switchMerchant ? { width: 30, height: 20 } : {},
                  ]}
                  onPress={() => {
                    nextPage();
                  }}
                >
                  <ArrowRight color={Colors.whiteColor} />
                </TouchableOpacity>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 10,
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  // shadowOffset: {
                  //     width: 0,
                  //     height: 1,
                  // },
                  // shadowOpacity: 0.22,
                  // shadowRadius: 3.22,
                  // elevation: 1,
                }}
              >
                <Text
                  style={[
                    {
                      marginRight: "1%",
                    },
                    switchMerchant
                      ? {
                          fontSize: 10,
                        }
                      : {},
                  ]}
                >
                  Items Showed
                </Text>
                <View
                  style={{
                    width: Platform.OS === "ios" ? 65 : "13%", //65,
                    height: switchMerchant ? 20 : 35,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    justifyContent: "center",
                    paddingHorizontal: Platform.OS === "ios" ? 0 : 0,
                    //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                    // paddingTop: '-60%',
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                    marginRight: "1%",
                  }}
                >
                  <Picker
                    style={{
                      inputIOS: {
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: "NunitoSans-Regular",
                      },
                      inputAndroid: {
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: "NunitoSans-Regular",
                        justifyContent: "center",
                        textAlign: "center",
                        height: 40,
                        color: "black",
                      },
                      inputAndroidContainer: { width: "100%" },
                      //backgroundColor: 'red',
                      height: 35,

                      chevronContainer: {
                        display: "none",
                      },
                      chevronDown: {
                        display: "none",
                      },
                      chevronUp: {
                        display: "none",
                      },
                    }}
                    selectedValue={perPage}
                    onValueChange={(value) => {
                      setPerPage(value);
                    }}
                  >
                    {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                      label: "All",
                      value: !showDetails
                        ? dailySalesDetailsList.length
                        : transactionTypeSalesDetails.length,
                    }).map((value, index) => {
                      return (
                        <Picker.Item
                          key={index}
                          label={value.label}
                          value={value.value}
                        />
                      );
                    })}
                  </Picker>
                </View>

                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "1%",
                  }}
                >
                  Page
                </Text>
                <View
                  style={[
                    {
                      width: 65,
                      height: 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: 22,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                    },
                    switchMerchant
                      ? { width: 60, height: 20, borderRadius: 5 }
                      : {},
                  ]}
                >
                  {console.log("currentDetailsPage")}
                  {console.log(currentDetailsPage)}
                  <TextInput
                    onChangeText={(text) => {
                      var currentPageTemp =
                        text.length > 0 ? parseInt(text) : 1;

                      setCurrentDetailsPage(
                        currentPageTemp > pageCount
                          ? pageCount
                          : currentPageTemp < 1
                          ? 1
                          : currentPageTemp
                      );
                    }}
                    placeholder={currentDetailsPage.toString()}
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    style={[
                      {
                        color: "black",
                        // fontFamily: 'NunitoSans-Regular',
                        fontSize: 14,
                        fontFamily: "NunitoSans-Regular",
                        marginTop: Platform.OS === "ios" ? 0 : -15,
                        marginBottom: Platform.OS === "ios" ? 0 : -15,
                        textAlign: "center",
                        width: "100%",
                      },
                      switchMerchant
                        ? {
                            fontSize: 10,
                          }
                        : {},
                    ]}
                    value={currentDetailsPage.toString()}
                    defaultValue={currentDetailsPage.toString()}
                    keyboardType={"numeric"}
                    onFocus={() => {
                      setPushPagingToTop(true);
                    }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Bold",
                    marginRight: "1%",
                    marginLeft: "1%",
                  }}
                >
                  of {pageCount}
                </Text>
                <TouchableOpacity
                  style={[
                    {
                      width: 45,
                      height: 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    switchMerchant ? { width: 30, height: 20 } : {},
                  ]}
                  onPress={() => {
                    prevDetailsPage();
                  }}
                >
                  <ArrowLeft color={Colors.whiteColor} />
                </TouchableOpacity>
                <TouchableOpacity
                  style={[
                    {
                      width: 45,
                      height: 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    },
                    switchMerchant ? { width: 30, height: 20 } : {},
                  ]}
                  onPress={() => {
                    nextDetailsPage();
                  }}
                >
                  <ArrowRight color={Colors.whiteColor} />
                </TouchableOpacity>
              </View>
            )}
          </View>
        </ScrollView>
      </View>

      {expandBarSelection ? (
        <View style={styles.ManageFilterBox}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: 10,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                Colors: "#27353C",
                fontFamily: "Nunitosans-bold",
              }}
            >
              Manage Filter (Bar Chart)
            </Text>
          </View>
          <View
            style={{
              borderWidth: 0.5,
              borderColor: "#D2D2D2",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 10,
              height: 35,
            }}
          >
            <DropDownPicker
              arrowColor={"#BDBDBD"}
              arrowSize={23}
              arrowStyle={{ fontWeight: "bold" }}
              style={{ width: 250, height: 35 }}
              items={
                CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES]
              }
              // placeholder={'Field name'}
              // placeholderStyle={{ color: 'black' }}
              dropDownStyle={{
                width: 250,
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              globalTextStyle={{
                fontFamily: "NunitoSans-SemiBold",
                // fontSize: 12,
                color: Colors.fontDark,
                marginLeft: 5,
              }}
              onChangeItem={(item) => {
                // setState({ sort: selectedSort }),
                //sortingFunc(selectedSort);
                setSelectedChartFilterQueriesBarChart([
                  {
                    ...selectedChartFilterQueriesBarChart[0],
                    fieldNameKey: item.value,
                    fieldNameType: item.fieldType,
                  },
                ]);
              }}
              defaultValue={selectedChartFilterQueriesBarChart[0].fieldNameKey}
              listMode={"SCROLLVIEW"}
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            />
            <DropDownPicker
              arrowColor={"#BDBDBD"}
              arrowSize={23}
              arrowStyle={{ fontWeight: "bold" }}
              style={{ marginLeft: "5%", width: 150 }}
              dropDownStyle={{ marginLeft: "5%", width: 150 }}
              items={
                CHART_FIELD_COMPARE_DROPDOWN_LIST[
                  CHART_TYPE.DASHBOARD_TODAY_SALES
                ]
              }
              // placeholder={'Field name'}
              // placeholderStyle={{ color: 'black' }}
              dropDownStyle={{
                width: 150,
                marginLeft: "5%",
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              globalTextStyle={{
                fontFamily: "NunitoSans-SemiBold",
                // fontSize: 12,
                color: Colors.fontDark,
                marginLeft: 5,
              }}
              onChangeItem={(item) => {
                // setState({ sort: selectedSort }),
                //sortingFunc(selectedSort);
                setSelectedChartFilterQueriesBarChart([
                  {
                    ...selectedChartFilterQueriesBarChart[0],
                    fieldCompare: item.value,
                  },
                ]);
              }}
              defaultValue={selectedChartFilterQueriesBarChart[0].fieldCompare}
              listMode={"SCROLLVIEW"}
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 10,
              height: 35,
              zIndex: -1,
            }}
          >
            <TextInput
              style={{
                width: 410,
                borderWidth: 1,
                borderColor: "#D2D2D2",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
                fontFamily: "NunitoSans-Regular",
                borderRadius: 8,
                paddingVertical: 3,
                paddingLeft: 15,
              }}
              {...(selectedChartFilterQueriesBarChart[0].fieldNameType ===
                CHART_FIELD_TYPE.DATETIME && {
                onPressIn: () => {
                  setShowDateTimePickerFilterBarChart(true);
                },
              })}
              editable={
                selectedChartFilterQueriesBarChart[0].fieldNameType ===
                CHART_FIELD_TYPE.DATETIME
                  ? false
                  : true
              }
              placeholder="   Field Value"
              placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
              placeholderStyle={{
                color: "black",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "NunitoSans-Regular",
              }}
              defaultValue={
                selectedChartFilterQueriesBarChart[0].fieldNameType ===
                CHART_FIELD_TYPE.DATETIME
                  ? moment(
                      selectedChartFilterQueriesBarChart[0].fieldDataValue
                    ).format("DD/MM/YYYY")
                  : selectedChartFilterQueriesBarChart[0].fieldDataValue
                  ? selectedChartFilterQueriesBarChart[0].fieldDataValue
                  : ""
              }
              onChangeText={(text) => {
                setSelectedChartFilterQueriesBarChart([
                  {
                    ...selectedChartFilterQueriesBarChart[0],
                    fieldDataValue: text,
                  },
                ]);
              }}
              keyboardType={
                selectedChartFilterQueriesBarChart[0].fieldNameType ===
                CHART_FIELD_TYPE.STRING
                  ? "default"
                  : "numeric"
              }
            />
          </View>

          {/* <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10, zIndex: -1 }}>
                        <TouchableOpacity style={{
                            justifyContent: 'center',
                            width: '40%'
                        }}
                            onPress={() => {
                                setAppliedChartFilterQueriesBarChart(selectedChartFilterQueriesBarChart);
                            }}>
                            <View style={{
                                justifyContent: 'center',
                                width: '100%',
                                flexDirection: 'row',
                                borderWidth: 1,
                                borderColor: '#E0E0E0',
                                borderRadius: 5,
                                height: 35,
                                alignItems: 'center',
                                backgroundColor: '#FFFFFF',
                                height: Dimensions.get('screen').height * 0.040
                            }}>
                                <Text style={{
                                    color: '#27353C',
                                    fontSize: 14,
                                    fontFamily: 'Nunitosans-bold',
                                    marginLeft: 7
                                }}>
                                    Add new filter
                                </Text>
                            </View>
                        </TouchableOpacity>
                    </View> */}
          <View
            style={{
              borderWidth: 0.5,
              borderColor: "#D2D2D2",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: 10,
              zIndex: -1,
            }}
          >
            <TouchableOpacity
              style={{
                justifyContent: "center",
                width: 130,
              }}
              onPress={() => {
                // setExpandBarSelection(!expandBarSelection)

                setExpandBarSelection(false);

                setAppliedChartFilterQueriesBarChart([]);
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: "#BDBDBD",
                  borderRadius: 5,
                  height: 40,
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Text
                  style={{
                    color: "#BDBDBD",
                    fontSize: 16,
                    fontFamily: "Nunitosans-bold",
                    marginLeft: 7,
                  }}
                >
                  CANCEL
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                justifyContent: "center",
                width: 130,
                marginLeft: "3%",
              }}
              onPress={() => {
                // setExpandBarSelection(!expandBarSelection)

                setExpandBarSelection(false);

                setAppliedChartFilterQueriesBarChart(
                  selectedChartFilterQueriesBarChart
                );
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "row",
                  backgroundColor: "#4E9F7D",
                  borderRadius: 5,
                  height: 40,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 16,
                    fontFamily: "Nunitosans-bold",
                    marginLeft: 7,
                  }}
                >
                  APPLY
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      ) : null}

      {expandLineSelection ? (
        <View style={[styles.ManageFilterBox]}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: 10,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                Colors: "#27353C",
                fontFamily: "Nunitosans-bold",
              }}
            >
              Manage Filter
            </Text>
          </View>
          <View
            style={{
              borderWidth: 0.5,
              borderColor: "#D2D2D2",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 10,
              height: 35,
            }}
          >
            <DropDownPicker
              arrowColor={"#BDBDBD"}
              arrowSize={23}
              arrowStyle={{ fontWeight: "bold" }}
              style={{ width: 250, height: 35 }}
              items={
                CHART_FIELD_NAME_DROPDOWN_LIST[
                  CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                ]
              }
              // placeholder={'Field name'}
              // placeholderStyle={{ color: 'black' }}
              dropDownStyle={{
                width: 250,
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              globalTextStyle={{
                fontFamily: "NunitoSans-SemiBold",
                // fontSize: 12,
                color: Colors.fontDark,
                marginLeft: 5,
              }}
              onChangeItem={(item) => {
                // setState({ sort: selectedSort }),
                //sortingFunc(selectedSort);
                setSelectedChartFilterQueriesLineChart([
                  {
                    ...selectedChartFilterQueriesLineChart[0],
                    fieldNameKey: item.value,
                    fieldNameType: item.fieldType,
                  },
                ]);
              }}
              defaultValue={selectedChartFilterQueriesLineChart[0].fieldNameKey}
              listMode={"SCROLLVIEW"}
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            />
            <DropDownPicker
              arrowColor={"#BDBDBD"}
              arrowSize={23}
              arrowStyle={{ fontWeight: "bold" }}
              style={{ marginLeft: "5%", width: 150 }}
              dropDownStyle={{ marginLeft: "5%", width: 150 }}
              items={
                CHART_FIELD_COMPARE_DROPDOWN_LIST[
                  CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                ]
              }
              // placeholder={'Field name'}
              // placeholderStyle={{ color: 'black' }}
              dropDownStyle={{
                width: 150,
                marginLeft: "5%",
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              globalTextStyle={{
                fontFamily: "NunitoSans-SemiBold",
                // fontSize: 12,
                color: Colors.fontDark,
                marginLeft: 5,
              }}
              onChangeItem={(item) => {
                // setState({ sort: selectedSort }),
                //sortingFunc(selectedSort);
                setSelectedChartFilterQueriesLineChart([
                  {
                    ...selectedChartFilterQueriesLineChart[0],
                    fieldCompare: item.value,
                  },
                ]);
              }}
              defaultValue={selectedChartFilterQueriesLineChart[0].fieldCompare}
              listMode={"SCROLLVIEW"}
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 10,
              height: 35,
              zIndex: -1,
            }}
          >
            <TextInput
              style={{
                width: 410,
                borderWidth: 1,
                borderColor: "#D2D2D2",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
                fontFamily: "NunitoSans-Regular",
                borderRadius: 8,
                paddingVertical: 3,
                paddingLeft: 15,
              }}
              {...(selectedChartFilterQueriesLineChart[0].fieldNameType ===
                CHART_FIELD_TYPE.DATETIME && {
                onPressIn: () => {
                  setShowDateTimePickerFilterLineChart(true);
                },
              })}
              editable={
                selectedChartFilterQueriesLineChart[0].fieldNameType ===
                CHART_FIELD_TYPE.DATETIME
                  ? false
                  : true
              }
              placeholder="   Field Value"
              placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
              placeholderStyle={{
                color: "black",
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "NunitoSans-Regular",
              }}
              defaultValue={
                selectedChartFilterQueriesLineChart[0].fieldNameType ===
                CHART_FIELD_TYPE.DATETIME
                  ? moment(
                      selectedChartFilterQueriesLineChart[0].fieldDataValue
                    ).format("DD/MM/YYYY")
                  : selectedChartFilterQueriesLineChart[0].fieldDataValue
                  ? selectedChartFilterQueriesLineChart[0].fieldDataValue
                  : ""
              }
              onChangeText={(text) => {
                setSelectedChartFilterQueriesLineChart([
                  {
                    ...selectedChartFilterQueriesLineChart[0],
                    fieldDataValue: text,
                  },
                ]);
              }}
              keyboardType={
                selectedChartFilterQueriesLineChart[0].fieldNameType ===
                CHART_FIELD_TYPE.STRING
                  ? "default"
                  : "numeric"
              }
            />
          </View>

          {/* <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10, zIndex: -1 }}>
                        <TouchableOpacity style={{
                            justifyContent: 'center',
                            width: '40%'
                        }}
                            onPress={() => {
                                setAppliedChartFilterQueriesLineChart(selectedChartFilterQueriesLineChart);
                            }}>
                            <View style={{
                                justifyContent: 'center',
                                width: '100%',
                                flexDirection: 'row',
                                borderWidth: 1,
                                borderColor: '#E0E0E0',
                                borderRadius: 5,
                                height: 35,
                                alignItems: 'center',
                                backgroundColor: '#FFFFFF',
                                height: Dimensions.get('screen').height * 0.040
                            }}>
                                <Text style={{
                                    color: '#27353C',
                                    fontSize: 14,
                                    fontFamily: 'Nunitosans-bold',
                                    marginLeft: 7
                                }}>
                                    Add New Filter
                                </Text>
                            </View>
                        </TouchableOpacity>
                    </View> */}
          <View
            style={{
              borderWidth: 0.5,
              borderColor: "#D2D2D2",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: 10,
              zIndex: -1,
            }}
          >
            <TouchableOpacity
              style={{
                justifyContent: "center",
                width: 130,
              }}
              onPress={() => {
                // setExpandLineSelection(!expandLineSelection)

                setExpandLineSelection(false);

                setAppliedChartFilterQueriesLineChart([]);
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: "#BDBDBD",
                  borderRadius: 5,
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  width: 130,
                  height: 40,
                }}
              >
                <Text
                  style={{
                    color: "#BDBDBD",
                    fontSize: 16,
                    fontFamily: "Nunitosans-bold",
                    marginLeft: 7,
                  }}
                >
                  CANCEL
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                justifyContent: "center",
                width: 130,
                marginLeft: "3%",
              }}
              onPress={() => {
                // setExpandLineSelection(!expandLineSelection)

                setExpandLineSelection(false);

                setAppliedChartFilterQueriesLineChart(
                  selectedChartFilterQueriesLineChart
                );
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "row",
                  backgroundColor: "#4E9F7D",
                  borderRadius: 5,
                  height: 40,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 16,
                    fontFamily: "Nunitosans-bold",
                    marginLeft: 7,
                  }}
                >
                  APPLY
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  confirmBox: {
    width: Dimensions.get("screen").width * 0.4,
    height: Dimensions.get("screen").height * 0.3,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: "space-between",
  },
  textInput8: {
    fontFamily: "NunitoSans-Regular",
    width: 75,
    height: 50,
    flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },

  textInput9: {
    fontFamily: "NunitoSans-Regular",
    width: 430,
    height: 50,
    //flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },

  headerLeftStyle: {
    width: Dimensions.get("screen").width * 0.17,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    height: Dimensions.get("screen").width * 0.3,
    width: Dimensions.get("screen").width * 0.4,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get("screen").width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: Dimensions.get("screen").width * 0.02,
    top: Dimensions.get("screen").width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: "center",
    top: "20%",
    position: "absolute",
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitleText: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    width: 130,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  ManageFilterBox: {
    //width: Dimensions.get('screen').width * 0.27,
    //height: Platform.OS === 'ios' ?Dimensions.get('screen').height * 0.23: Dimensions.get('screen').height * 0.24,
    //width: Platform.OS === 'ios' ? Dimensions.get('screen').width * 0.4 : Dimensions.get('screen').width * 0.33,
    height:
      Platform.OS === "ios" ? 180 : Dimensions.get("screen").height * 0.24,
    width: Platform.OS === "ios" ? 400 : Dimensions.get("screen").width * 0.33,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    position: "absolute",
    marginTop: Platform.OS === "ios" ? "13%" : "13%",
    marginLeft: Platform.OS === "ios" ? "12%" : "12%",
    //left: Platform.OS === 'ios' ? '38%' : '0%',
    //top: Platform.OS === 'ios' ? '46%' : '0%',
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 5,
    },
    shadowOpacity: 0.32,
    shadowRadius: 3.22,
    elevation: 10,
    zIndex: 1000,
    borderRadius: 10,
    //borderTopLeftRadius: 0,
    backgroundColor: Colors.whiteColor,
    //justifyContent: 'space-between'
  },
});

export default PromotionReportScreen;
