
import React, { useState, useEffect } from 'react';
import {
    View,
    Image,
    ActivityIndicator,
    Text,
} from 'react-native';
import Colors from '../../constant/Colors';
import { getCachedUrlContent, getImageFromFirebaseStorage } from '../../util/common';

const AsyncImage = props => {
    const {
        source,
        style,
        item,
    } = props;

    const [url, setUrl] = useState('');

    // useEffect(() => {
    //     if (source && source.uri) {
    //         if (source.uri.startsWith('http')) {
    //             setUrl(source.uri);
    //         }
    //         else {
    //             getImageFromFirebaseStorage(source.uri, (parsedUrl) => {
    //                 setUrl(parsedUrl);
    //             });

    //             // const urlTemp = retrieveImageFromFirebase(source);

    //             // setUrl(urlTemp);
    //         }
    //     }
    // }, [source]);

    // const retrieveImageFromFirebase = async (source) => {
    //     const urlTemp = await getImageFromFirebaseStorage(source.uri);

    //     return urlTemp;
    // };

    useEffect(() => {
        // if (source && source.uri) {
        //     if (source.uri.startsWith('http')) {
        //         setUrl(source.uri);
        //     }
        //     else {
        //         getImageFromFirebaseStorage(source.uri, (parsedUrl) => {
        //             setUrl(parsedUrl);
        //         });

        //         // const urlTemp = retrieveImageFromFirebase(source);

        //         // setUrl(urlTemp);
        //     }
        // }

        loadCachedImage(source, item);
    }, [source, item]);

    const loadCachedImage = async (source, item) => {
        // console.log(source)
        if (source && source.uri) {
            if (source.uri.startsWith('http') || source.uri.startsWith('file:///') || source.uri.startsWith('data:')) {
                setUrl(source.uri);
            }
            else {
                const cachedObj = await getCachedUrlContent(source.uri, 43800, item);

                if (cachedObj && cachedObj.parsedUrl) {
                    setUrl(cachedObj.parsedUrl);
                }

                // if (cachedImgUrl) {
                //     setUrl(cachedImgUrl);
                // }   
                // else {
                //     getImageFromFirebaseStorage(source.uri, async (parsedUrl) => {
                //         setUrl(parsedUrl);
                //     });
                // }
            }
        }
    }

    return (
        <>
            {url !== '' ?
                <Image
                    style={style}
                    source={{
                        uri: url
                    }}
                />
                : 
                <View style={[{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                }, style]}>
                    <ActivityIndicator color={Colors.primaryColor} size={"large"} />
                </View>
            }
        </>
    );
};

export default AsyncImage;