import { Store } from 'pullstate';

export const NotificationStore = new Store({
    nUserOrder: {},
    nUserRing: {},
    nWaiterKitchenReadyOrder: {},

    nUserReservation: {},
    nUserQueue: {},    
    nOutletSupplyItemLow: {},
});   
