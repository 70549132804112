import {
    Platform,
    Dimensions,
} from 'react-native';
import Colors from './Colors';
// import { isTablet } from 'react-native-device-detection';

export const CHART_TYPE = {
    DASHBOARD_TODAY_SALES: 'DASHBOARD_TODAY_SALES',
    DASHBOARD_LINE_CHART_SALES: 'DASHBOARD_LINE_CHART_SALES',
    REPORT_PRODUCT_SALES: 'REPORT_PRODUCT_SALES',
    REPORT_OVERTIME_SALES: 'REPORT_OVERTIME_SALES',
};

export const CHART_PERIOD = {
    NONE: 'NONE',
    TODAY: 'TODAY',
    THIS_WEEK: 'THIS_WEEK',
    THIS_MONTH: 'THIS_MONTH',
    THREE_MONTHS: 'THREE_MONTHS',
    SIX_MONTHS: 'SIX_MONTHS',
    THIS_YEAR: 'THIS_YEAR',
    YTD: 'YTD',
    //DEFAULT: 'DEFAULT',
};

export const CHART_PERIOD_TODAY = [
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
];

export const CHART_SIZE = {
    SMALL: 0,
    LARGE: 1,
};

// export const FS_LIBRARY_PATH = Platform.select({
//     ios: require('../assets/fusioncharts.html'),
//     android: { uri: 'file:///android_asset/fusioncharts.html' }
// });

export const CHART_DATA = {
    [CHART_TYPE.DASHBOARD_TODAY_SALES]: {
        type: 'column2d',
        dataFormat: 'json',
        chart: {
            palettecolors: "#F2C94C",
            lineColor: "#F2C94C",
            anchorbgcolor: "#F2C94C",
            anchorBorderThickness: 0,
            anchorAlpha: 100,
            anchorBgAlpha: 100,

            valueBorderRadius: 20,

            // caption: "Dangerous Pose",
            caption: '',
            anchorradius: "2",
            yaxisname: "",
            // plottooltext: "Dangerous Pose at $label is <b>$dataValue</b>",
            plottooltext: '',
            showhovereffect: "1",
            showvalues: "0",
            numbersuffix: "",
            theme: "fint",
            valueFontColor: "#ffffff",
            divLineAlpha: 0,
            showXAxisLine: 0,
            // showShadow: 1,


            captionAlignment: 'left',
            captionFont: 'NunitoSans-Bold',
            captionFontSize: Platform.OS === 'ios' ? 14 : 16,
            captionFontColor: '#414d55',
            captionHorizontalPadding: 0,

            labelFont: 'NunitoSans-Bold',
            labelFontSize: Platform.OS === 'ios' ? 14 : 16,
            labelFontColor: '#414d55',
            yAxisNameFont: 'NunitoSans-Bold',
            yAxisNameFontSize: Platform.OS === 'ios' ? 14 : 16,
            yAxisNameFontColor: '#414d55',
            baseFontSize: Platform.OS === 'ios' ? 14 : 16,

            numDivLines: 8,
            // divLineColor: '#a4b0bd',
            divLineColor: '#BDBDBD',
            divLineThickness: 1,
            divLineAlpha: 50,
            divLineDashGap: 0,

            chartLeftMargin: 16,
            chartRightMargin: 8,
            chartBottomMargin: 16,
            chartTopMargin: 16,

            labelPadding: 15,
            valuePadding: 15,
        },
    },
    [CHART_TYPE.DASHBOARD_LINE_CHART_SALES]: {
        type: 'msline',
        dataFormat: 'json',
        chart: {
            showToolTip: 1,
            showYAsisValues: 1,

            palettecolors: "#F2C94C",
            lineColor: "#F2C94C",
            anchorbgcolor: "#F2C94C",
            anchorBorderThickness: 0,
            anchorAlpha: 100,
            anchorBgAlpha: 100,

            // valueBorderRadius: 20,

            // caption: "Dangerous Pose",
            caption: '',
            anchorradius: "2",
            yaxisname: "",
            // plottooltext: "Dangerous Pose at $label is <b>$dataValue</b>",
            plottooltext: '',
            showhovereffect: "1",
            showvalues: "0",
            numbersuffix: "",
            theme: "fint",
            valueFontColor: "#ffffff",
            divLineAlpha: 0,
            showXAxisLine: 0,
            // showShadow: 1,

            //outCnvBaseFont: "Arial",
            outCnvBaseFontSize: Platform.OS === 'ios' ? 14 : 16,
            //outCnvBaseFontColor: "#633563",

            captionAlignment: 'left',
            captionFont: 'NunitoSans-Bold',
            captionFontSize: 16,
            captionFontColor: '#414d55',
            captionHorizontalPadding: 0,

            labelFont: 'NunitoSans-Bold',
            labelFontSize: 16,
            labelFontColor: '#414d55',
            yAxisNameFont: 'NunitoSans-Bold',
            yAxisNameFontSize: 16,
            yAxisNameFontColor: '#414d55',
            baseFontSize: 16,

            numDivLines: 8,
            // divLineColor: '#a4b0bd',
            divLineColor: '#BDBDBD',
            divLineThickness: 1,
            divLineAlpha: 50,
            divLineDashGap: 0,

            chartLeftMargin: 16,
            chartRightMargin: Dimensions.get('screen').width <= 1133 ? 17 : 16,
            chartBottomMargin: 16,
            chartTopMargin: 16,

            labelPadding: 15,
            valuePadding: 15,
        },
    },
    [CHART_TYPE.REPORT_PRODUCT_SALES]: {
        type: 'stackedcolumn2d',
        dataFormat: 'json',
        chart: {
            palettecolors: "#F2C94C",
            lineColor: "#F2C94C",
            anchorbgcolor: "#F2C94C",
            anchorBorderThickness: 0,
            anchorAlpha: 100,
            anchorBgAlpha: 100,

            valueBorderRadius: 20,

            // caption: "Dangerous Pose",
            caption: '',
            anchorradius: "2",
            yaxisname: "",
            // plottooltext: "Dangerous Pose at $label is <b>$dataValue</b>",
            plottooltext: '',
            showhovereffect: "1",
            showvalues: "0",
            numbersuffix: "",
            theme: "fint",
            valueFontColor: "#ffffff",
            divLineAlpha: 0,
            showXAxisLine: 0,
            // showShadow: 1,

            //outCnvBaseFont: "Arial",
            outCnvBaseFontSize:Platform.OS === 'ios' ? 13 : 16,
            //outCnvBaseFontColor: "#633563",

            captionAlignment: 'left',
            captionFont: 'NunitoSans-Bold',
            captionFontSize: 16,
            captionFontColor: '#414d55',
            captionHorizontalPadding: 0,

            labelFont: 'NunitoSans-Bold',
            labelFontSize: 16,
            labelFontColor: '#414d55',
            yAxisNameFont: 'NunitoSans-Bold',
            yAxisNameFontSize: 16,
            yAxisNameFontColor: '#414d55',
            baseFontSize: 16,

            numDivLines: 8,
            // divLineColor: '#a4b0bd',
            divLineColor: '#BDBDBD',
            divLineThickness: 1,
            divLineAlpha: 50,
            divLineDashGap: 0,

            chartLeftMargin: 16,
            chartRightMargin: 8,
            chartBottomMargin: 16,
            chartTopMargin: 16,

            labelPadding: 15,
            valuePadding: 15,
        },
    },
}

export const CHART_COLORS = {
    [CHART_TYPE.REPORT_PRODUCT_SALES]: [        
        Colors.tabCyan,
        '#d2332c',
        Colors.tabGold,
        '#13e092',
        '#BBFAED',
        '#717398',
        '#C2C1C0',
        '#9b7a67',
        '#093b62',        
        Colors.primaryColor,
    ],
    [CHART_TYPE.DASHBOARD_LINE_CHART_SALES]: [
        Colors.tabCyan, 
        Colors.primaryColor,       
        '#d2332c',
        Colors.tabGold,
        '#13e092',
        '#BBFAED',
        '#717398',
        '#C2C1C0',
        '#9b7a67',
        '#093b62',         
    ],
};

export const CHART_Y_AXIS_DROPDOWN_LIST = {
    [CHART_TYPE.REPORT_PRODUCT_SALES]: [
        {
            // label: 'Total Sales (RM)',
            label: 'Sales (RM)',
            value: 'totalSales',
        },
        {
            // label: 'Total Items',
            label: 'Items (Qty)',
            value: 'totalItems',
        },
        // {
        //     label: 'Total Discount (RM)',
        //     value: 'totalDiscount',
        // },
        // {
        //     label: 'Discount (%)',
        //     value: 'discount',
        // },
        {
            // label: 'Total Sale Returned (RM)',
            label: 'Sale Returned (RM)',
            value: 'totalSalesReturn',
        },
        {
            label: 'Net Sales (RM)',
            value: 'itemNetSales',
        },
    ],
    [CHART_TYPE.DASHBOARD_LINE_CHART_SALES]: [
        {
            label: 'Gross Profit (RM)',
            value: 'finalPrice',
        },
        // {
        //     label: 'Total Discount (RM)',
        //     value: 'discount',
        // },
        // {
        //     label: 'Discount (%)',
        //     value: 'discount',
        // },
        {
            label: 'Total Delivery Fee (RM)',
            value: 'deliveryFee',
        },
        {
            label: 'Total Product Cost (RM)',
            value: 'totalPrice',
        },
    ],
    [CHART_TYPE.DASHBOARD_TODAY_SALES]: [
        {
            label: 'Total Sales (RM)',
            value: 'finalPrice',
        },
        // {
        //     label: 'Total Discount (RM)',
        //     value: 'discount',
        // },
        // {
        //     label: 'Discount (%)',
        //     value: 'discount',
        // },
        {
            label: 'Total Delivery Fee (RM)',
            value: 'deliveryFee',
        },
        {
            label: 'Total Product Cost (RM)',
            value: 'totalPrice',
        },
    ],
};

export const CHART_X_AXIS_TYPE = {
    TIME: 'TIME',
    OUTLET: 'OUTLET',
};

export const CHART_X_AXIS_DROPDOWN_LIST = {
    [CHART_TYPE.REPORT_PRODUCT_SALES]: [
        {
            // label: 'Total Sales (RM)',
            label: 'Time',
            value: CHART_X_AXIS_TYPE.TIME,
        },
        {
            // label: 'Total Items',
            label: 'Outlet',
            value: CHART_X_AXIS_TYPE.OUTLET,
        },
    ],
};

export const CHART_FIELD_TYPE = {
    NUMBER: 'NUMBER',
    DATETIME: 'DATETIME',
    STRING: 'STRING',
};

export const CHART_FIELD_COMPARE_DICT = {
    IS: 'IS',
    LIKE: 'LIKE',
    NOT: 'NOT',
    MORE_THAN: 'MORE_THAN',
    MORE_OR_EQUAL_THAN: 'MORE_OR_EQUAL_THAN',
    LESS_THAN: 'LESS_THAN',
    LESS_OR_EQUAL_THAN: 'LESS_OR_EQUAL_THAN',
};

export const CHART_FIELD_COMPARE_DROPDOWN_LIST = {
    [CHART_TYPE.REPORT_PRODUCT_SALES]: [
        {
            label: 'is',
            value: CHART_FIELD_COMPARE_DICT.IS,
        },
        {
            label: 'like',
            value: CHART_FIELD_COMPARE_DICT.LIKE,
        },
        {
            label: 'not',
            value: CHART_FIELD_COMPARE_DICT.NOT,
        },
        {
            label: '>',
            value: CHART_FIELD_COMPARE_DICT.MORE_THAN,
        },
        {
            label: '>=',
            value: CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN,
        },
        {
            label: '<',
            value: CHART_FIELD_COMPARE_DICT.LESS_THAN,
        },
        {
            label: '<=',
            value: CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN,
        },
    ],
    [CHART_TYPE.DASHBOARD_LINE_CHART_SALES]: [
        {
            label: 'is',
            value: CHART_FIELD_COMPARE_DICT.IS,
        },
        {
            label: 'like',
            value: CHART_FIELD_COMPARE_DICT.LIKE,
        },
        {
            label: 'not',
            value: CHART_FIELD_COMPARE_DICT.NOT,
        },
        {
            label: '>',
            value: CHART_FIELD_COMPARE_DICT.MORE_THAN,
        },
        {
            label: '>=',
            value: CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN,
        },
        {
            label: '<',
            value: CHART_FIELD_COMPARE_DICT.LESS_THAN,
        },
        {
            label: '<=',
            value: CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN,
        },
    ],
    [CHART_TYPE.DASHBOARD_TODAY_SALES]: [
        {
            label: 'is',
            value: CHART_FIELD_COMPARE_DICT.IS,
        },
        {
            label: 'like',
            value: CHART_FIELD_COMPARE_DICT.LIKE,
        },
        {
            label: 'not',
            value: CHART_FIELD_COMPARE_DICT.NOT,
        },
        {
            label: '>',
            value: CHART_FIELD_COMPARE_DICT.MORE_THAN,
        },
        {
            label: '>=',
            value: CHART_FIELD_COMPARE_DICT.MORE_OR_EQUAL_THAN,
        },
        {
            label: '<',
            value: CHART_FIELD_COMPARE_DICT.LESS_THAN,
        },
        {
            label: '<=',
            value: CHART_FIELD_COMPARE_DICT.LESS_OR_EQUAL_THAN,
        },
    ],
};

export const CHART_FIELD_NAME_DROPDOWN_LIST = {
    [CHART_TYPE.REPORT_PRODUCT_SALES]: [
        {
            label: 'Product Name',
            value: 'name',
            fieldType: CHART_FIELD_TYPE.STRING,
            special: 'cartItems',
        },
        {
            label: 'Store Name',
            value: 'outletName',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Customer Name',
            value: 'userName',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Customer Phone',
            value: 'userPhone',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Customer Address',
            value: 'userAddress',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Waiter Phone',
            value: 'waiterName',
            fieldType: CHART_FIELD_TYPE.STRING,
        },    
        {
            label: 'Order ID',
            value: 'orderId',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Order Price',
            value: 'finalPrice',
            fieldType: CHART_FIELD_TYPE.NUMBER,
        },
        {
            label: 'Order Tax',
            value: 'tax',
            fieldType: CHART_FIELD_TYPE.NUMBER,
        },
        {
            label: 'Order Discount',
            value: 'discount',
            fieldType: CHART_FIELD_TYPE.NUMBER,
        },
        // {
        //     label: 'Order Date',
        //     value: 'orderDate',
        //     fieldType: CHART_FIELD_TYPE.DATETIME,
        // },
        // {
        //     label: 'Completed Date',
        //     value: 'completedDate',
        //     fieldType: CHART_FIELD_TYPE.DATETIME,
        // },
    ],
    [CHART_TYPE.DASHBOARD_LINE_CHART_SALES]: [
        {
            label: 'Store Name',
            value: 'outletName',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Customer Name',
            value: 'userName',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Customer Phone',
            value: 'userPhone',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Customer Address',
            value: 'userAddress',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Waiter Phone',
            value: 'waiterName',
            fieldType: CHART_FIELD_TYPE.STRING,
        },    
        {
            label: 'Order ID',
            value: 'orderId',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Order Price',
            value: 'finalPrice',
            fieldType: CHART_FIELD_TYPE.NUMBER,
        },
        {
            label: 'Order Tax',
            value: 'tax',
            fieldType: CHART_FIELD_TYPE.NUMBER,
        },
        {
            label: 'Order Discount',
            value: 'discount',
            fieldType: CHART_FIELD_TYPE.NUMBER,
        },
        // {
        //     label: 'Order Date',
        //     value: 'orderDate',
        //     fieldType: CHART_FIELD_TYPE.DATETIME,
        // },
        // {
        //     label: 'Completed Date',
        //     value: 'completedDate',
        //     fieldType: CHART_FIELD_TYPE.DATETIME,
        // },
    ],
    [CHART_TYPE.DASHBOARD_TODAY_SALES]: [
        {
            label: 'Store Name',
            value: 'outletName',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Customer Name',
            value: 'userName',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Customer Phone',
            value: 'userPhone',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Customer Address',
            value: 'userAddress',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Waiter Phone',
            value: 'waiterName',
            fieldType: CHART_FIELD_TYPE.STRING,
        },    
        {
            label: 'Order ID',
            value: 'orderId',
            fieldType: CHART_FIELD_TYPE.STRING,
        },
        {
            label: 'Order Price',
            value: 'finalPrice',
            fieldType: CHART_FIELD_TYPE.NUMBER,
        },
        {
            label: 'Order Tax',
            value: 'tax',
            fieldType: CHART_FIELD_TYPE.NUMBER,
        },
        {
            label: 'Order Discount',
            value: 'discount',
            fieldType: CHART_FIELD_TYPE.NUMBER,
        },
        // {
        //     label: 'Order Date',
        //     value: 'orderDate',
        //     fieldType: CHART_FIELD_TYPE.DATETIME,
        // },
        // {
        //     label: 'Completed Date',
        //     value: 'completedDate',
        //     fieldType: CHART_FIELD_TYPE.DATETIME,
        // },
    ],
};

//Line chart
export const CHART_DATA2 = {
    [CHART_TYPE.DASHBOARD_TODAY_SALES]: {
        type: 'multiaxisline',
        //renderAt: 'chart-container',
        //width: '600',
        //height: '350',
        //dataFormat: 'json',


    },
};

export const MONTH_TO_CHART_LABEL = {
    0: '01',
    1: '02',
    2: '03',
    3: '04',
    4: '05',
    5: '06',
    6: '07',
    7: '08',
    8: '09',
    9: '10',
    10: '11',
    11: '12',
};
