import React, {Component, useReducer, useState, useEffect} from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Modal,
  Dimensions,
  TextInput,
  FlatList,
  // KeyboardAvoidingView,
  Picker,
  useWindowDimensions
} from 'react-native';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import SideBar from './SideBar';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Ionicons';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
// import DropDownPicker from 'react-native-dropdown-picker';
import Feather from 'react-native-vector-icons/Feather';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import moment from 'moment';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
// import {isTablet} from 'react-native-device-detection';
import {
  MERCHANT_VOUCHER_CODE_FORMAT,
  MERCHANT_VOUCHER_TYPE,
  SEGMENT_TYPE,
} from '../constant/common';
import {CommonStore} from '../store/commonStore';
import {UserStore} from '../store/userStore';
import {MerchantStore} from '../store/merchantStore';
import {OutletStore} from '../store/outletStore';
// import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
// import RNPickerSelect from 'react-native-picker-select';
// import {useKeyboard} from '../hooks';
// import {get} from 'react-native/Libraries/Utilities/PixelRatio';
import AsyncImage from '../components/asyncImage';
import Entypo from 'react-native-vector-icons/Entypo';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {PROMOTION_TYPE_VARIATION} from '../constant/promotions';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { useLinkTo, useRoute } from "@react-navigation/native";
import { prefix } from '../constant/env';
import {Platform} from 'react-native';

/////////////////////////////////////////////////////////////////////////////////////

const SettingCredit = (props) => {
  const {navigation} = props;
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const linkTo = useLinkTo();

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [expandThreeDots, setExpandThreeDots] = useState({}); //Use to expand the view when three dots are tapped
  const [threeDotsTapped, setThreeDotsTapped] = useState(false); //when the three dots are tapped will become (true)

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);
  const pointsRedeemPackages = OutletStore.useState(
    (s) => s.pointsRedeemPackages,
  );

  const allOutletsItemsSkuDict = OutletStore.useState(
    (s) => s.allOutletsItemsSkuDict,
  );

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView,
  );
  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  //To remove unwanted sidebar
  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  //////////////////////////////////////////////////
  //Header
  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            // bottom: -2,
            bottom: switchMerchant ? '2%' : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? {right: windowWidth * 0.1}
            : {},
          windowWidth <= 768
            ? {right: windowWidth * 0.12}
            : {},
        ]}>
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Loyalty Points
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: 'white',
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}></View>
        <TouchableOpacity
          onPress={() => navigation.navigate('Setting')}
          style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}>
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: 'center',
              }}
              source={require('../assets/image/profile-pic.jpg')}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });
  //////////////////////////////////////////////////////////////////

  const expandThreeDotsFunc = (param) => {
    if (expandThreeDots[param.uniqueId]) {
      setExpandThreeDots({
        ...expandThreeDots,
        [param.uniqueId]: false,
      });
    } else {
      setExpandThreeDots({
        // ...expandThreeDots,
        [param.uniqueId]: true,
      });
    }
  };

  //////////////////////////////////////////////////////////////////
  //With three dots
  // const renderRedemptionPackages = ({ item }) => {
  //   var productOrCategoryNameList = [];

  //   if (item.variation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
  //     for (var i = 0; i < item.variationItemsSku.length; i++) {
  //       if (allOutletsItemsSkuDict[item.variationItemsSku[i]] && allOutletsItemsSkuDict[item.variationItemsSku[i]].length > 0) {
  //         productOrCategoryNameList.push(allOutletsItemsSkuDict[item.variationItemsSku[i]][0].name);
  //       }
  //     }
  //   }
  //   else if (item.variation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
  //     for (var i = 0; i < item.variationItemsSku.length; i++) {
  //       productOrCategoryNameList.push(item.variationItemsSku[i]);
  //     }
  //   }

  //   const startDate = moment(item.createdAt);
  //   const endDate = moment(item.createdAt).add(item.validPeriod, 'day');

  //   return (
  //     <View style={{ flexDirection: 'row', borderBottomWidth: StyleSheet.hairlineWidth, borderBottomColor: '#c4c4c4', padding: 10 }}>
  //       <View style={{ width: 80, marginRight: 1 }}>
  //         <AsyncImage style={{
  //           width: 60,
  //           height: 60,
  //           alignSelf: 'center',
  //           borderRadius: 100,
  //           borderColor: '#E5E5E5',
  //           borderWidth:0.7,
  //           marginLeft: 5,
  //         }} source={{
  //           uri: item.image,
  //         }}
  //           hideLoading={true}
  //         />
  //       </View>
  //       <Text style={{ fontSize: 14, fontWeight: '500', fontFamily: 'NunitoSans-Regular', color: '#252733', width: '26%', marginHorizontal: 1 }}>{productOrCategoryNameList.length > 0 ? productOrCategoryNameList.join(', ') : 'N/A'}</Text>
  //       <View style={{width: '24%'}}>
  //       {item.outletNameList.length > 0 ?
  //       <View>
  //        {(item.outletNameList.map(( outletNameList ) => {

  //          return(
  //        <View>
  //          <Text style={{ fontSize: 14, fontWeight: '500', fontFamily: 'NunitoSans-Regular', color: '#252733' }}>
  //            {outletNameList}
  //          </Text>
  //        </View>
  //          )
  //       }))}
  //       </View>
  //       : null }
  //       </View>
  //       <Text style={{ fontSize: 14, fontWeight: '500', fontFamily: 'NunitoSans-Regular', color: '#252733', width: '14%', marginHorizontal: 1 }}>{item.conversionPointsFrom} points</Text>
  //       <Text style={{ fontSize: 14, fontWeight: '500', fontFamily: 'NunitoSans-Regular', color: '#252733', width: '21%', marginHorizontal: 1 }}>{`${moment(startDate).format('DD/MM/YYYY')}-${moment(endDate).format('DD/MM/YYYY')}`}</Text>

  //       <View style={{ flexDirection: 'row', width: '3%', marginLeft: 1 }}>
  //         <TouchableOpacity style={{ marginTop: 10,  }}
  //           onPress={() => expandThreeDotsFunc(item)}
  //         >
  //           <Entypo name='dots-three-vertical' size={28} color={Colors.tabGrey} />
  //         </TouchableOpacity>
  //       </View>
  //       {threeDotsTapped ?
  //         <View style={{
  //           width: 110,
  //           height: 97,
  //           marginLeft: -130,
  //           zIndex: 1,
  //           flexDirection: 'column',
  //           backgroundColor: '#FFFFFF',
  //           borderWidth: 1,
  //           borderColor: Colors.highlightColor,
  //           borderRadius: 7,
  //           shadowOpacity: 0,
  //           shadowColor: '#000',
  //           shadowOffset: {
  //             height: 2,
  //           },
  //           shadowOpacity: 0.22,
  //           shadowRadius: 3.22,
  //           elevation: 2,
  //         }}>

  //           <TouchableOpacity style={{ flexDirection: 'row', height: '32%', justifyContent: 'center', alignItems: 'center' }}
  //             onPress={() => {
  //               CommonStore.update(s => {
  //                 s.selectedPointsRedeemPackageEdit = item;
  //               });

  //               navigation.navigate('SettingSetCredit');
  //             }}
  //           >
  //             <View style={{ width: '30%', paddingLeft: 12 }}>
  //               <MaterialIcons name='edit' size={17} color='grey' />
  //             </View>
  //             <View style={{ width: '70%' }}>
  //               <Text style={{ marginLeft: 5 }}>
  //                 Edit
  //               </Text>
  //             </View>
  //           </TouchableOpacity>

  //           <View style={{ borderWidth: 1, borderColor: Colors.fieldtBgColor }} />

  //           <TouchableOpacity style={{ flexDirection: 'row', height: '32%', justifyContent: 'center', alignItems: 'center' }}
  //             onPress={() => {
  //             }}>
  //             <View style={{ width: '30%', paddingLeft: 12 }}>
  //               <MaterialCommunityIcons name='delete-sweep' size={17} color='grey' />
  //             </View>
  //             <View style={{ width: '70%' }}>
  //               <Text style={{ marginLeft: 5 }}>
  //                 Delete
  //               </Text>
  //             </View>
  //           </TouchableOpacity>

  //           <View style={{ borderWidth: 1, borderColor: Colors.fieldtBgColor }} />

  //           <TouchableOpacity style={{ flexDirection: 'row', height: '32%', justifyContent: 'center', alignItems: 'center' }}
  //             onPress={() => {

  //             }}>
  //             <View style={{ width: '30%', paddingLeft: 12 }}>
  //               <MaterialIcons name='insights' size={17} color='grey' />
  //             </View>
  //             <View style={{ width: '70%' }}>
  //               <Text style={{ marginLeft: 5 }}>
  //                 Insights
  //               </Text>
  //             </View>
  //           </TouchableOpacity>

  //         </View>
  //         : null}

  //     </View>
  //   )
  // };

  //////////////////////////////////////////////////////////////////
  //Without threedots
  const renderRedemptionPackages = ({item}) => {
    var productOrCategoryNameList = [];

    if (item.variation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
      for (var i = 0; i < item.variationItemsSku.length; i++) {
        if (
          allOutletsItemsSkuDict[item.variationItemsSku[i]] &&
          allOutletsItemsSkuDict[item.variationItemsSku[i]].length > 0
        ) {
          productOrCategoryNameList.push(
            allOutletsItemsSkuDict[item.variationItemsSku[i]][0].name,
          );
        }
      }
    } else if (
      item.variation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
    ) {
      for (var i = 0; i < item.variationItemsSku.length; i++) {
        productOrCategoryNameList.push(item.variationItemsSku[i]);
      }
    }

    // const startDate = moment(item.createdAt);
    // const endDate = moment(item.createdAt).add(item.validPeriod, 'day');

    const startDate = moment(item.startDate);
    const endDate = moment(item.endDate);

    return (
      <TouchableOpacity
        onPress={() => {
          CommonStore.update((s) => {
            s.selectedPointsRedeemPackageEdit = item;
            linkTo && linkTo(`${prefix}/settingsetcredit`);
          });
          // navigation.navigate('SettingSetCredit');
        }}
        style={{
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: '#c4c4c4'
        }}>
        <View style={{flexDirection:'row'}}>
          <View style={{flex: 0.27, justifyContent: 'center', alignItems: 'flex-start'}}>
            <AsyncImage
              style={{
                width: switchMerchant ? 40 : 60,
                height: switchMerchant ? 40 : 60,
                // alignSelf: 'center',
                borderRadius: 100,
                borderColor: '#E5E5E5',
                borderWidth: 0.7,
                // marginLeft: 5,
              }}
              source={{
                uri: item.image,
              }}
              item={item}
              hideLoading={true}
            />
          </View>
          
          <View style={{flex: 1, justifyContent: 'center', alignItems: 'flex-start',}}>
            <Text
              style={[
                {
                  fontSize: switchMerchant ? 10 : 16,
                  fontWeight: '500',
                  fontFamily: 'NunitoSans-Regular',
                  color: '#252733',
                }
              ]}>
              {productOrCategoryNameList.length > 0
                ? productOrCategoryNameList.join(', ')
                : 'N/A'}
            </Text>
          </View>
          <View style={{flex:1, justifyContent: 'center', alignItems: 'flex-start'}}>
            {item.outletNameList.length > 0 ? (
              <View>
                {item.outletNameList.map((outletNameList) => {
                  return (
                    <View>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 16,
                          fontWeight: '500',
                          fontFamily: 'NunitoSans-Regular',
                          color: '#252733',
                        }}>
                        {' '}
                        {outletNameList}
                      </Text>
                    </View>
                  );
                })}
              </View>
            ) : null}
          </View>

          <View
            style={[
              {
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flex: 1
              }
            ]}>
              <Text
                style={{
                  fontSize: switchMerchant ? 10 : 16,
                  fontWeight: '500',
                  fontFamily: 'NunitoSans-Regular',
                  color: '#252733',
                  textAlign: 'left'
                }}>
                {`${item.conversionPointsFrom} Points : RM ${item.conversionCurrencyTo}`}
              </Text>
          </View>
          
          <View style={{ flex: 1}}>
            <Text
              style={[
                {
                  fontSize: switchMerchant ? 10 : 16,
                  fontWeight: '500',
                  fontFamily: 'NunitoSans-Regular',
                  color: '#252733',
                }
              ]}>
              {`${moment(startDate).format('DD MMM YYYY')}`}
            </Text>
            <Text
              style={[
                {
                  fontSize: switchMerchant ? 10 : 16,
                  fontWeight: '500',
                  fontFamily: 'NunitoSans-Regular',
                  color: '#252733',
                  marginLeft: 30
                }
              ]}>
              to
            </Text>
            <Text
              style={[
                {
                  fontSize: switchMerchant ? 10 : 16,
                  fontWeight: '500',
                  fontFamily: 'NunitoSans-Regular',
                  color: '#252733',
                },
              ]}>
              {`${moment(endDate).format('DD MMM YYYY')}`}
            </Text>
          </View>
          </View>
        {/* <Text style={{ fontSize: 14, fontWeight: '500', fontFamily: 'NunitoSans-Regular', color: '#252733', width: '21.5%', marginHorizontal: 1 }}>{`${moment(startDate).format('DD/MM/YYYY')}-${moment(endDate).format('DD/MM/YYYY')}`}</Text> */}
      </TouchableOpacity>
    );
  };

  //////////////////////////////////////////////////////////////////
  //Render Start Here
  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={0} />
      </View>

      <View style={{ height: windowHeight, flex: 9 }}>
      <ScrollView horizontal={true} scrollEnabled={switchMerchant}>
        <View
          style={{
            padding: switchMerchant ? 0 : 16,
            paddingTop: switchMerchant ? 30 : 6,

            //margin: 20,
            //marginBottom: 50,
            width: switchMerchant
              ? windowWidth * 0.8
              : windowWidth * (1 - Styles.sideBarWidth),
            // backgroundColor: 'lightgrey',
            backgroundColor: Colors.highlightColor,
            marginHorizontal: switchMerchant ? 30 : 0,
          }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: switchMerchant ? 0 : 15,
              marginBottom: switchMerchant ? 30 : 10,
              marginTop: 0,
              //backgroundColor: 'red',
              height: '10%',
              // borderBottomWidth: StyleSheet.hairlineWidth,
              borderBottomColor: '#c4c4c4',
              //borderRadius: 5,
              //width: '100%',
              //paddingHorizontal: 10,
              paddingRight: switchMerchant
                ? 0
                : windowWidth * 0.002,
              //paddingBottom: switchMerchant ? 10 : 0,
            }}>
            <View style={{justifyContent: 'center'}}>
              {/* fontSize: 26, fontFamily: 'NunitoSans-Bold' */}
              <Text
                style={{
                  fontSize: switchMerchant ? 20 : 26,
                  marginRight: 30,
                  fontFamily: 'NunitoSans-Bold',
                }}>
                Summary
                {/* List of Created Redemption Packages */}
              </Text>
            </View>

            <View style={{flexDirection: 'row'}}>
              <View style={{flexDirection: 'row'}}>
                {/* {isTablet && ( */}
                <View
                  style={{
                    alignItem: 'center',
                    marginRight: switchMerchant ? 18 : 10,
                  }}>
                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      backgroundColor: '#4E9F7D',
                      borderRadius: 5,
                      //width: 160,
                      paddingHorizontal: 10,
                      height: 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,
                    }}
                    onPress={() => {
                      // props.navigation.navigate('LoyaltyPointsRate');
                      linkTo && linkTo(`${prefix}/loyaltypointsrate`);
                    }}>
                    <View
                      style={{flexDirection: 'row', justifyContent: 'center'}}>
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          //marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: 'NunitoSans-Bold',
                        }}>
                        RATE SETTINGS
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                {/* )} */}
              </View>

              <View style={{flexDirection: 'row'}}>
                {/* {isTablet && ( */}
                <View
                  style={{
                    alignSelf: 'flex-start',
                    marginRight: switchMerchant ? 18 : 10,
                  }}>
                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      backgroundColor: '#4E9F7D',
                      borderRadius: 5,
                      //width: 160,
                      paddingHorizontal: 10,
                      height: 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,
                    }}
                    onPress={() => {
                      CommonStore.update((s) => {
                        s.selectedPointsRedeemPackageEdit = null;
                      });

                      // navigation.navigate('SettingSetCredit');
                      linkTo && linkTo(`${prefix}/settingsetcredit`);

                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                      }}>
                      <AntDesign
                        name="pluscircle"
                        size={switchMerchant ? 11 : 20}
                        style={{color: Colors.whiteColor, alignSelf: 'center'}}
                      />
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: 'NunitoSans-Bold',
                        }}>
                        {/* Set Credit */}
                        PACKAGE
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
                {/* )} */}
              </View>

              <View style={{flexDirection: 'row'}}>
                <View
                  style={[
                    {
                      height: 40,
                    },
                  ]}>
                  <View
                    style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <View
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 5,
                        flexDirection: 'row',
                        width: 250,
                        height: 40,
                        alignContent: 'center',
                        alignItems: 'center',
                        shadowColor: '#000',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 3,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                      }}>
                      <Icon
                        name="search"
                        size={switchMerchant ? 11 : 25}
                        color={Colors.primaryColor}
                        style={{marginLeft: 15}}
                      />
                      <View style={{flex: 3}}>
                        <TextInput
                          underlineColorAndroid={Colors.whiteColor}
                          style={{
                            width: 220,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: 'NunitoSans-Regular',
                            paddingLeft: 5,
                            height: 45,
                          }}
                          placeholderTextColor={Platform.select({
                            ios: '#a9a9a9',
                          })}
                          clearButtonMode="while-editing"
                          placeholder=" Search"
                          /* onChangeText={(text) => {
                          setSearch(text);
                        }}
                        defaultValue={search} */
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              backgroundColor: Colors.whiteColor,
              width: switchMerchant
                ? windowWidth * 0.8
                : windowWidth * 0.87,
              height: windowHeight * 0.7,
              // marginTop: 30,
              marginHorizontal: switchMerchant ? 15 : 30,
              marginBottom: 30,
              alignSelf: 'center',
              borderRadius: 5,
              shadowOpacity: 0,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 3,
            }}>
            {/* Type 1 Search Bar */}
            {/* <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginHorizontal: 40, }}>
          <View style={{
            flexDirection: 'row',
            justifyContent: 'center',
            width: '25%',
            height: 40,
            alignItems: 'center',
            borderWidth: 1,
            borderRadius: 5,
            borderColor: '#E5E5E5',
            alignSelf: 'center'
          }}>
            <View style={{ flex: 3 }}>
              <TextInput placeholder='Search'
                style={{ marginLeft: 10 }}>
              </TextInput>
            </View>
            <View style={{ flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.primaryColor, borderRadius: 5 }}>
              <Icon name='search' size={25} color={Colors.whiteColor} />
            </View>
          </View>
        </View> */}

            {/* Type 2 Search Bar */}

            <View
              style={{
                height: 60,
                flexDirection: 'row',
                borderBottomWidth: StyleSheet.hairlineWidth,
                borderBottomColor: '#c4c4c4',
                justifyContent: 'center',
                alignItems: 'flex-end'
              }}>
              <View style={{flex:0.27}}></View>
              <View style={{flex:1, justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                <Text
                  style={[
                    {
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                      color: Colors.blackColor
                  }]}>
                  Product/Category
                </Text>
              </View>
              <View style={{flex:1, justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                <Text
                  style={[
                    {
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                      color: Colors.blackColor,
                    }
                  ]}>
                  Available Outlet(s)
                </Text>
              </View>
              <View style={{flex:1, justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                      color: Colors.blackColor,
                      textAlign: 'center',
                    }}>
                    Points : RM
                  </Text>
              </View>
              <View style={{flex: 1, justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                <Text
                  style={[
                    {
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                      color: Colors.blackColor
                    }
                  ]}>
                  Durations
                </Text>
              </View>
            </View>
            <ScrollView>
              <FlatList
                data={pointsRedeemPackages}
                //extradata={renderRedemptionPackages}
                renderItem={renderRedemptionPackages}
                keyExtractor={(item, index) => String(index)}
                style={{}}
              />
            </ScrollView>
          </View>
        </View>
      </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
    fontFamily: 'NunitoSans-Regular',
  },
  list: {
    backgroundColor: Colors.whiteColor,
    // width: windowWidth * 0.87,
    // height: windowHeight * 0.7,
    // marginTop: 30,
    marginHorizontal: 30,
    marginBottom: 30,
    alignSelf: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  listItem: {
    fontFamily: 'NunitoSans-Regular',
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    // width: windowWidth * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,
    elevation: 16,
  },
  content: {
    padding: 16,
    paddingTop: 6,
    // width: windowWidth * (1 - Styles.sideBarWidth),
    // backgroundColor: 'lightgrey',
    backgroundColor: Colors.highlightColor,
  },
  textInput: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 0,
    flex: 1,
    flexDirection: 'row',
  },
  textInputLocation: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 100,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 10,
  },
  textSize: {
    fontSize: 19,
    fontFamily: 'NunitoSans-SemiBold',
  },
  merchantDisplayView: {
    flexDirection: 'row',
    flex: 1,
    marginLeft: '17%',
  },
  shiftText: {
    marginLeft: '15%',
    color: Colors.primaryColor,
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 25,
  },
  confirmBox: {
    width: '30%',
    height: '30%',
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
});
export default SettingCredit;
