import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  TextInput,
  Dimensions,
  FlatList,
  Modal,
  PermissionsAndroid,
  Platform,
  useWindowDimensions,
  ActivityIndicator,
  KeyboardAvoidingView,
} from "react-native";
import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import * as User from "../util/User";
import Icon from "react-native-vector-icons/Feather";
import Icon1 from "react-native-vector-icons/FontAwesome";
import Icon2 from "react-native-vector-icons/EvilIcons";
import Icon3 from "react-native-vector-icons/Foundation";
import Icon4 from "react-native-vector-icons/FontAwesome5";
import DropDownPicker from "react-native-dropdown-picker";
// import { ceil } from 'react-native-reanimated';
// import CheckBox from '@react-native-community/checkbox';
import DateTimePicker from "react-native-modal-datetime-picker";
import moment from "moment";
import Close from "react-native-vector-icons/AntDesign";
import ApiClient from "../util/ApiClient";
import API from "../constant/API";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Styles from "../constant/Styles";
// import DocumentPicker from "react-native-document-picker";
import Ionicons from "react-native-vector-icons/Ionicons";
// import RNFetchBlob from "rn-fetch-blob";
// import { isTablet } from "react-native-device-detection";
import "react-native-get-random-values";
import { customAlphabet } from "nanoid";
import {
  STOCK_TAKE_STATUS,
  STOCK_TAKE_STATUS_PARSED,
  EMAIL_REPORT_TYPE,
} from "../constant/common";
import { CommonStore } from "../store/commonStore";
import { MerchantStore } from "../store/merchantStore";
import { UserStore } from "../store/userStore";
import { convertArrayToCSV, generateEmailReport } from "../util/common";
// import {
//   KeyboardAwareFlatList,
//   KeyboardAwareScrollView,
// } from "react-native-keyboard-aware-scroll-view";
// import RNPickerSelect from "react-native-picker-select";
// import { useKeyboard } from '../hooks';
import AntDesign from "react-native-vector-icons/AntDesign";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import XLSX from "xlsx";
import { v4 as uuidv4 } from "uuid";
// import GCalendar from '../assets/svg/GCalendar';
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
// import GCalendarGrey from '../assets/svg/GCalendarGrey';
import { ReactComponent as GCalendarGrey } from "../assets/svg/GCalendarGrey.svg";
import { Picker } from "react-native";
import { CSVLink } from "react-csv";

const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 12);

const StockTakeScreen = (props) => {
  const { navigation } = props;

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  //   const [keyboardHeight] = useKeyboard();

  const [selectedOutletSupplyItem, setSelectedOutletSupplyItem] = useState({});

  const [stockTake, setStockTake] = useState(true);
  const [addStockTake, setAddStockTake] = useState(false);
  const [addPurchase, setAddPurchase] = useState(false);
  const [editPurchase, setEditPurchase] = useState(false);
  const [addStockTransfer, setAddStockTransfer] = useState(false);
  const [stockList, setStockList] = useState([]);
  const [stockTransferList, setStockTransferList] = useState([]);
  const [stockTakeList, setStockTakeList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [itemsToOrder, setItemsToOrder] = useState([{}, {}, {}]);
  const [itemsToOrder2, setItemsToOrder2] = useState([{}, {}, {}]);
  const [addStockTransferList, setAddStockTransferList] = useState([
    {},
    {},
    {},
  ]);
  const [addUnCountedStockTakeList, setAddUnCountedStockTakeList] = useState([
    {},
  ]);
  const [productList, setProductList] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);
  const [isSelected3, setIsSelected3] = useState(true);
  const [isSelected4, setIsSelected4] = useState(false);
  const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
  const [date, setDate] = useState("");
  const [completedDate, setCompletedDate] = useState("");
  const [visible, setVisible] = useState(false);
  const [Email, setEmail] = useState(null);
  const [modal, setModal] = useState(false);
  // const [// outletId, set// outletId] = useState(1);
  const [outletId, setOutletId] = useState(User.getOutletId());
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");
  const [ideal, setIdeal] = useState("");
  const [minimum, setMinimum] = useState("");
  const [itemId, setItemId] = useState("");
  const [choose, setChoose] = useState(null);
  const [addCountedStockTakeList, setAddCountedStockTakeList] = useState([
    {
      name: "100 plus",
      sku: "soda",
      cost: 2,
      expectedQty: 50,
      countedQty: 0,
    },
    {
      name: "Coke",
      sku: "soda",
      cost: 2,
      expectedQty: 50,
      countedQty: 0,
    },
    {
      name: "Beer",
      sku: "soda",
      cost: 10,
      expectedQty: 20,
      countedQty: 0,
    },
  ]);

  const [loading, setLoading] = useState(false);
  const [remark, setRemark] = useState("");
  const [remark2, setRemark2] = useState("");
  const [addStock, setAddStock] = useState(false);

  //////////////////////////////////////////////////////////////////////

  const [startDate, setStartDate] = useState(Date.now());

  const [poId, setPoId] = useState("");
  const [editMode, setEditMode] = useState(false);

  const [exportEmail, setExportEmail] = useState("");
  const [exportModal, setExportModal] = useState(false);
  const [importModal, setImportModal] = useState(false);

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);

  const [remarkModal, setRemarkModal] = useState(false);

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState("");

  // const [supplierDropdownList, setSupplierDropdownList] = useState([{ label: 'All', value: 'ALL' }]);
  const [supplierDropdownList, setSupplierDropdownList] = useState([]);

  const [selectedSupplierId, setSelectedSupplierId] = useState("");

  const [poStatus, setPoStatus] = useState(STOCK_TAKE_STATUS.CREATED);

  const [outletSupplyItemDropdownList, setOutletSupplyItemDropdownList] =
    useState([]);

  const [poItems, setPoItems] = useState([
    {
      outletSupplyItemId: "",
      name: "",
      sku: "",
      skuMerchant: "",
      unit: "",
      quantity: 0,
      countedQuantity: 0,
      diffQuantity: 0,
      diffCost: 0,
      price: 0,
      totalPrice: 0,
      remarks: "",
    },
  ]);

  const [selectedPoItemIndex, setSelectedPoItemIndex] = useState(0);

  const [diffQuantityTotal, setDiffQuantityTotal] = useState(0);
  const [diffCostTotal, setDiffCostTotal] = useState(0);

  const [outletSupplyItems, setOutletSupplyItems] = useState([]);

  const allOutletsSupplyItems = CommonStore.useState(
    (s) => s.allOutletsSupplyItems
  );
  const allOutletsSupplyItemsDict = CommonStore.useState(
    (s) => s.allOutletsSupplyItemsDict
  );

  const suppliers = CommonStore.useState((s) => s.suppliers);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const merchantId = UserStore.useState((s) => s.merchantId);
  const stockTakes = CommonStore.useState((s) => s.stockTakes);

  const isLoading = CommonStore.useState((s) => s.isLoading);

  const userName = UserStore.useState((s) => s.name);
  const userId = UserStore.useState((s) => s.firebaseUid);
  const merchantName = MerchantStore.useState((s) => s.name);

  const selectedStockTakeEdit = CommonStore.useState(
    (s) => s.selectedStockTakeEdit
  );

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );
  useEffect(() => {
    if (selectedStockTakeEdit) {
      // insert info

      setPoId(selectedStockTakeEdit.stId);
      setPoStatus(selectedStockTakeEdit.status);
      setSelectedTargetOutletId(selectedStockTakeEdit.outletId);
      setSelectedSupplierId(selectedStockTakeEdit.supplierId);
      setDate(selectedStockTakeEdit.createdAt);
      setCompletedDate(selectedStockTakeEdit.completedDate);
      setRemark(selectedStockTakeEdit.remarks);

      console.log("1");

      if (selectedStockTakeEdit.stItems) {
        setPoItems(selectedStockTakeEdit.stItems);
      }
    } else {
      // designed to always mounted, thus need clear manually...

      // setPoId(nanoid());
      setPoId(`STA${(stockTakes.length + 1).toString().padStart(4, "0")}`);
      setPoStatus(STOCK_TAKE_STATUS.CREATED);
      setSelectedTargetOutletId(currOutletId);
      //setSelectedTargetOutletId(allOutlets.find(allOutlet => allOutlet.uniqueId === currOutletId))
      suppliers.length > 0 && setSelectedSupplierId(suppliers[0].uniqueId);
      setDate(Date.now());
      setRemark("");

      console.log("2");

      // if (outletSupplyItems.length > 0 && Object.keys(allOutletsSupplyItemsDict).length > 0) {
      // if (outletSupplyItems.length > 0) {
      //   setPoItems([
      //     {
      //       outletSupplyItemId: outletSupplyItems[0].uniqueId,
      //       name: outletSupplyItems[0].name,
      //       sku: outletSupplyItems[0].sku,
      //       quantity: outletSupplyItems[0].quantity,
      //       transferQuantity: 0,
      //       price: outletSupplyItems[0].price,
      //       totalPrice: 0,
      //     }
      //   ]);
      // }
      // else {
      //   setPoItems([
      //     {
      //       outletSupplyItemId: '',
      //       name: '',
      //       sku: '',
      //       quantity: 0,
      //       transferQuantity: 0,
      //       price: 0,
      //       totalPrice: 0,
      //     }
      //   ]);
      // }
    }
  }, [selectedStockTakeEdit, addStockTake]);

  useEffect(() => {
    setPoItems([
      {
        outletSupplyItemId: "",
        name: "",
        sku: "",
        skuMerchant: "",
        unit: "",
        quantity: 0,
        countedQuantity: 0,
        diffQuantity: 0,
        diffCost: 0,
        price: 0,
        totalPrice: 0,
        remarks: "",
      },
    ]);
  }, [selectedTargetOutletId]);

  useEffect(() => {
    setOutletSupplyItems(
      allOutletsSupplyItems.filter((outletSupplyItem) => {
        if (
          (outletSupplyItem.outletId === selectedTargetOutletId &&
            outletSupplyItem.supplierId === selectedSupplierId) ||
          selectedSupplierId === "ALL"
        ) {
          return true;
        }
        //return true;
      })
    );
  }, [allOutletsSupplyItems, selectedTargetOutletId, selectedSupplierId]);

  useEffect(() => {
    const outletDropdownListTemp = allOutlets.map((outlet) => ({
      label: outlet.name,
      value: outlet.uniqueId,
    }));

    setTargetOutletDropdownList(outletDropdownListTemp);

    if (selectedTargetOutletId === "" && allOutlets.length > 0) {
      setSelectedTargetOutletId(currOutletId);
      //setSelectedTargetOutletId(allOutlets.find(allOutlet => allOutlet.uniqueId === currOutletId ))
    }
  }, [allOutlets]);

  useEffect(() => {
    setSupplierDropdownList(
      suppliers.map((supplier) => ({
        label: supplier.name,
        value: supplier.uniqueId,
      }))
    );

    if (selectedSupplierId === "" && suppliers.length > 0) {
      setSelectedSupplierId(suppliers[0].uniqueId);
    }
    console.log("1");
  }, [suppliers]);

  useEffect(() => {
    setOutletSupplyItemDropdownList(
      outletSupplyItems.map((outletSupplyItem) => {
        // if (selectedSupplierId === supplyItem.supplierId) {
        //   return { label: supplyItem.name, value: supplyItem.uniqueId };
        // }

        return {
          label: outletSupplyItem.name,
          value: outletSupplyItem.uniqueId,
        };
      })
    );

    if (
      outletSupplyItems.length > 0 &&
      Object.keys(allOutletsSupplyItemsDict).length > 0
    ) {
      var poItemsTemp = [];

      for (var i = 0; i < outletSupplyItems.length; i++) {
        // const quantity = allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId] ? allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId].quantity : 0;
        // const price = allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId] ? allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId].price : 0;

        const prevRecord = poItems.find(
          (poItem) =>
            poItem.outletSupplyItemId === outletSupplyItems[i].uniqueId
        );

        var record = {};

        if (prevRecord) {
          record = {
            ...prevRecord, // inherit current user edited changes
            quantity: outletSupplyItems[i].quantity, // check if the supply item sku for this outlet existed | might changed in real time
            price: outletSupplyItems[i].price, // might changed in real time
            diffQuantity:
              prevRecord.countedQuantity - outletSupplyItems[i].quantity,
            diffCost:
              (prevRecord.countedQuantity - outletSupplyItems[i].quantity) *
              outletSupplyItems[i].price,
            totalPrice: prevRecord.countedQuantity * outletSupplyItems[i].price, // need check in future, whether used stock quantity or counted quantity
            // remarks: outletSupplyItems[i].remark2,
          };
        } else {
          record = {
            outletSupplyItemId: outletSupplyItems[i].uniqueId,
            name: outletSupplyItems[i].name,
            unit: outletSupplyItems[i].unit,
            sku: outletSupplyItems[i].sku,
            skuMerchant: outletSupplyItems[i].skuMerchant,
            quantity: outletSupplyItems[i].quantity, // check if the supply item sku for this outlet existed | might changed in real time
            countedQuantity: outletSupplyItems[i].quantity,
            diffQuantity: 0,
            diffCost: 0,
            price: outletSupplyItems[i].price, // might changed in real time
            totalPrice:
              outletSupplyItems[i].quantity * outletSupplyItems[i].price, // need check in future, whether used stock quantity or counted quantity
            remarks: "",
          };
        }

        poItemsTemp.push(record);
      }
      console.log("poItemsTemp: " + poItemsTemp);
      console.log(
        " OutletSupplyItemDropdownList: " + outletSupplyItemDropdownList
      );
      setPoItems(poItemsTemp);
    }

    // if (outletSupplyItems.length > 0 &&
    //   poItems.length === 1 &&
    //   poItems[0].outletSupplyItemId === '') {
    //   setPoItems([
    //     {
    //       outletSupplyItemId: outletSupplyItems[0].uniqueId,
    //       name: outletSupplyItems[0].name,
    //       sku: outletSupplyItems[0].sku,
    //       quantity: outletSupplyItems[0].quantity,
    //       countedQuantity: outletSupplyItems[0].quantity,
    //       diffQuantity: 0,
    //       diffCost: 0,
    //       price: outletSupplyItems[0].price,
    //       totalPrice: 0,
    //     }
    //   ]);
    // }
    // else if (poItems[0].outletSupplyItemId !== '' &&
    //   Object.keys(allOutletsSupplyItemsDict).length > 0) {
    //   var poItemsTemp = [
    //     ...poItems,
    //   ];

    //   for (var i = 0; i < poItemsTemp.length; i++) {
    //     const quantity = allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId] ? allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId].quantity : 0;
    //     const price = allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId] ? allOutletsSupplyItemsDict[poItemsTemp[i].outletSupplyItemId].price : 0;

    //     poItemsTemp[i] = {
    //       ...poItemsTemp[i],
    //       quantity: quantity, // check if the supply item sku for this outlet existed | might changed in real time
    //       price: price, // might changed in real time
    //       diffQuantity: poItemsTemp[i].countedQuantity - quantity,
    //       diffCost: (poItemsTemp[i].countedQuantity - quantity) * price,
    //       totalPrice: quantity * price, // need check in future, whether used stock quantity or counted quantity
    //     };
    //   }

    //   setPoItems(poItemsTemp);
    // }
  }, [outletSupplyItems, allOutletsSupplyItemsDict]);

  useEffect(() => {
    setDiffQuantityTotal(
      poItems.reduce((accum, poItem) => accum + poItem.diffQuantity, 0)
    );
    setDiffCostTotal(
      poItems.reduce((accum, poItem) => accum + poItem.diffCost, 0)
    );
  }, [poItems]);

  useEffect(() => {
    requestStoragePermission();

    setPoId(nanoid());
    // setPoId(`STA${(stockTakes.length + 1).toString().padStart(4, '0')}`);
  }, []);

  useEffect(() => {
    if (selectedStockTakeEdit === null && stockTakes.length > 0) {
      // setPoId(`ST${moment().format('MMM').toUpperCase() + moment().format('YY') + (stockTransfers.length + 1).toString().padStart(4, '0')}`);
      setPoId(`STA${(stockTakes.length + 1).toString().padStart(4, "0")}`);
    }
  }, [stockTakes]);

  ////////////////////////////////////////////////////////////////

  const setState = () => {};

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            // bottom: -2,
            bottom: switchMerchant ? "2%" : 0,
          },
          Dimensions.get("screen").width >= 768 && switchMerchant
            ? { right: Dimensions.get("screen").width * 0.1 }
            : {},
          Dimensions.get("screen").width <= 768
            ? { right: Dimensions.get("screen").width * 0.12 }
            : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Stock Take
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: Dimensions.get("screen").height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get("screen").height * 0.05,
              height: Dimensions.get("screen").height * 0.05,
              borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: Dimensions.get("screen").height * 0.035,
                height: Dimensions.get("screen").height * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // componentDidMount = () => {
  //   // setInterval(() => {
  //   //   getStockOrder()
  //   //   getStockTransfer()
  //   //   getLowStock()
  //   // }, 1000);
  //   getStockOrder()

  // }

  // async componentWillMount = () => {
  //   await requestStoragePermission()
  // }

  const requestStoragePermission = async () => {
    try {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: "KooDoo Merchant Storage Permission",
          message: "KooDoo Merchant App needs access to your storage ",
          buttonNegative: "Cancel",
          buttonPositive: "OK",
        }
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        console.log("Storage permission granted");
      } else {
        console.log("Storage permission denied");
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const convertTemplateToExcelFormat = () => {
    var excelTemplate = [];

    for (var i = 0; i < poItems.length; i++) {
      var excelColumn = {
        "Product name": poItems[i].name,
        SKU: poItems[i].skuMerchant,
        Unit: poItems[i].unit,
        "Cost(RM)": poItems[i].price,
        "In stock": poItems[i].quantity,
        Counted: poItems[i].countedQuantity,
        "Balance(Qty)": poItems[i].diffQuantity,
        "Balance(RM)": poItems[i].diffCost,
      };
      excelTemplate.push(excelColumn);
    }

    console.log("excelTemplate");
    console.log(excelTemplate);

    return excelTemplate;
  };

  const importSelectFile = async () => {
    //   todo
    // try {
    //   const res = await DocumentPicker.pick({
    //     type: [DocumentPicker.types.xlsx],
    //   });
    //   console.log(res);
    // } catch (err) {
    //   if (DocumentPicker.isCancel(err)) {
    //   } else {
    //     throw err;
    //   }
    // }
  };

  //error show readAsArrayBuffer not implemented
  const importTemplate = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });

        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      console.log(d);
    });
  };

  const convertDataToExcelFormat = () => {
    var excelData = [];

    for (var i = 0; i < stockTakes.length; i++) {
      var excelRow = {
        "Stock Take ID": stockTakes[i].stId,
        "Created Date": moment(stockTakes[i].startDate).format("DD/MM/YYYY"),
        "Completed Date": moment(stockTakes[i].completedDate).format(
          "DD/MM/YYYY"
        ),
        Description: stockTakes[i].remark,
        Store: stockTakes[i].outletName,
        Supplier: stockTakes[i].supplierName,
        Status: stockTakes[i].status,
        "Product Name": stockTakes[i].stItems
          .map((item) => `${item.name}`)
          .join(","),
        Instock: stockTakes[i].stItems.map((item) => item.quantity).join(","),
        Counted: stockTakes[i].stItems
          .map((item) => item.countedQuantity)
          .join(","),
        Balance: stockTakes[i].stItems
          .map((item) => item.diffQuantity)
          .join(","),
      };

      excelData.push(excelRow);
    }

    console.log("excelData");
    console.log(excelData);

    return excelData;
  };

  const importFunc = () => {
    var body = {
      data: stockTakeList,
    };
    // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
    //   console.log(result)
    // });

    Alert.alert(
      "Success",
      "The data has been imported",
      [{ text: "OK", onPress: () => {} }],
      { cancelable: false }
    );
  };

  const getStockOrder = () => {
    // ApiClient.GET(API.getStockOrder + outletId).then((result) => {
    //   setState({ orderList: result })
    // });
  };

  // function here
  const showDateTimePicker = () => {
    setState({ isDateTimePickerVisible: true });
  };

  const hideDateTimePicker = () => {
    setState({ isDateTimePickerVisible: false });
  };

  const handleDatePicked = (date) => {
    setState({ date: date.toString() });
  };

  const renderStockItem = ({ item }) =>
    item.items.map((result) => {
      return (
        <View
          style={{
            backgroundColor: "#FFFFFF",
            flexDirection: "row",
            paddingVertical: 20,
            paddingHorizontal: 20,
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: "#C4C4C4",
          }}
        >
          <Text style={{ width: "13%", color: Colors.primaryColor }}>
            {result.name}
          </Text>
          <Text style={{ width: "12%" }}>{item.type}</Text>
          <Text
            style={{
              width: "6%",
              color:
                result.itemInventory.quantity < result.itemInventory.ideal_qty
                  ? "#F51B1B"
                  : Colors.blackColor,
            }}
          >
            {result.itemInventory.quantity}
          </Text>
          <View
            style={{
              width: "12%",
              alignItems: "center",
            }}
          >
            <TextInput
              editable={!loading}
              underlineColorAndroid={Colors.whiteColor}
              clearButtonMode="while-editing"
              style={styles.textInput2}
              placeholder={result.itemInventory.ideal_qty.toString()}
              placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
              onChangeText={(text) => {
                setState({
                  ideal: text.trim(),
                  itemId: result.itemInventory.itemId,
                });
              }}
              value={email}
            />
          </View>
          <View
            style={{
              width: "12%",
              alignItems: "center",
              marginLeft: 30,
            }}
          >
            <TextInput
              editable={!loading}
              underlineColorAndroid={Colors.whiteColor}
              clearButtonMode="while-editing"
              style={styles.textInput2}
              placeholder={result.itemInventory.minimum_qty.toString()}
              placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
              onChangeText={(text) => {
                setState({
                  minimum: text.trim(),
                  itemId: result.itemInventory.itemId,
                });
              }}
              value={email}
            />
          </View>
          <Text style={{ width: "10%", marginLeft: 40 }}>
            {result.itemInventory.unit === null ? 0 : result.itemInventory.unit}
          </Text>
          <Text style={{ width: "11%" }}>15/10/2020</Text>
          <View style={{ width: "15%" }}>
            {result.supplyProducts.map((elements) => {
              return (
                <Text>{elements === null ? " " : elements.supplier.name}</Text>
              );
            })}
          </View>
        </View>
      );
    });

  const renderStockTakeItem = ({ item }) => (
    <TouchableOpacity
      style={{
        backgroundColor: "#ffffff",
        flexDirection: "row",
        paddingVertical: 20,
        paddingLeft: 8,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: "#c4c4c4",
        alignItems: "center",
        width: "100%",
      }}
      onPress={() => {
        CommonStore.update((s) => {
          s.selectedStockTakeEdit = item;
        });

        setStockTake(false);
        setAddStockTake(true);
      }}
    >
      {/*<Text style={{ width: '12%', marginLeft: '-1%' }}>{item.stId}</Text>
      // <View style={{ width: "14%", flexDirection: 'row' }}>
      //   <Text>{moment(item.startDate).format('DD/MM/YYYY HH:mm')}</Text>
      // </View>
      // <View style={{ width: "14%", flexDirection: 'row' }}>
      //   <Text>{moment(item.updatedAt).format('DD/MM/YYYY HH:mm')}</Text>
      // </View>
      // <Text style={{ width: '12%', marginLeft: '3%' }}>
      //   {item.remarks}
      // </Text>
      // <Text style={{ width: '21%', marginLeft: '2%' }}>{item.outletName}</Text>
      // <Text style={{ width: '17%', marginLeft: '-3%' }}>{item.supplierName}</Text>
      // <View style={{
      //   width: '12%',
      //   alignItems: "center",
      //   backgroundColor: item.status ? Colors.primaryColor : '#fc0000',
      //   borderRadius: 10,
      //   padding: 10,
      //   marginLeft: '-3%'
      // }}>
    //   <Text style={{ color: item.status ? Colors.whiteColor : Colors.whiteColor }}>{STOCK_TAKE_STATUS_PARSED[item.status]}</Text>*/}
      <View
        style={{ flexDirection: "row", width: "100%", alignItems: "center" }}
      >
        {/* <View style={{ flexDirection: 'row' }}> */}
        <View style={{ width: "12%", marginRight: 2 }}>
          <Text
            style={{
              color: Colors.primaryColor,
              fontSize: switchMerchant ? 10 : 14,
              fontFamily: "NunitoSans-Regular",
            }}
          >
            {item.stId}
          </Text>
        </View>
        <View style={{ width: "10%", marginRight: 2 }}>
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 14,
              fontFamily: "NunitoSans-Regular",
            }}
          >
            {moment(item.createdAt).format("DD MMM YYYY")}
          </Text>
          {/* <Text style={{ fontSize: 13, }}>
            {moment(item.startDate).format('HH:mmA')}
          </Text> */}
        </View>

        <View style={{ width: "12%", marginHorizontal: 2 }}>
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 14,
              fontFamily: "NunitoSans-Regular",
            }}
          >
            {item.status !== STOCK_TAKE_STATUS.COMPLETED
              ? "Ongoing"
              : moment(item.completedDate).format("DD MMM YYYY")}
          </Text>
          {/* <Text style={{ fontSize: 13, }}>
            {moment(item.updatedAt).format('HH:mmA')}
          </Text> */}
        </View>

        <Text
          style={{
            width: "12%",
            marginHorizontal: 2,
            fontSize: switchMerchant ? 10 : 14,
            fontFamily: "NunitoSans-Regular",
          }}
          numberOfLines={2}
        >
          {item.staffName ? item.staffName : "N/A"}
        </Text>

        <Text
          style={{
            width: "19%",
            marginHorizontal: 2,
            fontSize: switchMerchant ? 10 : 14,
            fontFamily: "NunitoSans-Regular",
          }}
        >
          {item.outletName}
        </Text>

        <Text
          style={{
            width: "17%",
            marginHorizontal: 2,
            fontSize: switchMerchant ? 10 : 14,
            fontFamily: "NunitoSans-Regular",
          }}
        >
          {item.supplierName}
        </Text>
        {/* </View> */}
        <View style={{ width: "14%", alignItems: "baseline", marginLeft: 2 }}>
          <View
            style={
              switchMerchant
                ? {
                    alignItems: "center",
                    backgroundColor: item.status
                      ? Colors.primaryColor
                      : "#fc0000",
                    borderRadius: 10,
                    padding: 10,
                    paddingHorizontal: 10,
                    width: 72,
                  }
                : {
                    alignItems: "center",
                    backgroundColor: item.status
                      ? Colors.primaryColor
                      : "#fc0000",
                    borderRadius: 10,
                    padding: 10,
                    paddingHorizontal: 10,
                    width: Dimensions.get("screen").width <= 1024 ? 125 : 140,
                  }
            }
          >
            <Text
              style={{
                color: item.status ? Colors.whiteColor : Colors.whiteColor,
                fontWeight: "500",
                fontSize: switchMerchant ? 10 : 14,
                fontFamily: "NunitoSans-Regular",
              }}
            >
              {STOCK_TAKE_STATUS_PARSED[item.status]}
              {/* Partially Received */}
            </Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );

  const renderOrderItem = ({ item }) =>
    item.stockOrderProducts.map((elements, index) => {
      return (
        <TouchableOpacity
          onPress={() => {
            setState({
              lowStockAlert: false,
              purchaseOrder: false,
              stockTransfer: false,
              stockTake: false,
              addPurchase: false,
              editPurchase: true,
              addStockTransfer: false,
              addStockTake: false,
            });
          }}
        >
          <View
            style={{
              backgroundColor: "#ffffff",
              flexDirection: "row",
              paddingVertical: 20,
              paddingHorizontal: 20,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderBottomColor: "#c4c4c4",
            }}
          >
            <Text style={{ width: "11%", color: Colors.primaryColor }}>
              PO{item.id}
            </Text>
            <Text style={{ width: "13%" }}>
              {moment(item.createdAt).format("DD/MM/YYYY")}
            </Text>
            <Text style={{ width: "15%" }}>
              {moment(item.eta).format("DD/MM/YYYY")}
            </Text>
            <Text style={{ width: "15%" }}>{item.outlet.name}</Text>
            <Text style={{ width: "17%" }}>
              {item.supplier === null ? "" : item.supplier.name}
            </Text>
            <Text style={{ width: "15%" }}>RM{elements.amount}</Text>
            <View
              style={{
                width: "12%",
                alignItems: "center",
                backgroundColor:
                  item.status == 0
                    ? "#dedede"
                    : item.status == 1
                    ? "#969696"
                    : item.status == 2
                    ? Colors.secondaryColor
                    : Colors.primaryColor,
                borderRadius: 10,
              }}
            >
              <Text
                style={{
                  color:
                    item.status == 0
                      ? Colors.blackColor
                      : item.status == 1
                      ? Colors.whiteColor
                      : item.status == 2
                      ? Colors.blackColor
                      : Colors.whiteColor,
                  borderRadius: 10,
                }}
              >
                {item.status == 0
                  ? "Fail"
                  : item.status == 1
                  ? "In Progress"
                  : item.status == 2
                  ? "Arrived"
                  : "Completed"}
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      );
    });

  const renderItemsToOrder = ({ item }) => (
    <View
      style={{
        backgroundColor: "#ffffff",
        flexDirection: "row",
        paddingVertical: 20,
        paddingHorizontal: 20,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: "#c4c4c4",
      }}
    >
      <View style={{ width: "8%" }}>
        <input
          defaultValue={isSelected}
          onChange={(isSelected) => setState({ isSelected })}
          style={
            {
              // alignSelf: "center",
              // borderRadius: 15,
              // paddingBottom: 3,
            }
          }
          type={"checkbox"}
          //   checked={allOutlets.length === selectedOutletList.length}
        />
        {/* <CheckBox
          value={isSelected}
          onValueChange={(isSelected) => setState({ isSelected })}
        /> */}
      </View>
      <Text style={{ width: "14%", color: "#8f8f8f" }}>Chicken patty</Text>
      <Text style={{ width: "16%", color: "#8f8f8f" }}>meat</Text>
      <Text style={{ width: "14%", color: "#8f8f8f" }}>50</Text>
      <Text style={{ width: "16%", color: "#8f8f8f" }}>50</Text>
      <Text style={{ width: "18%", color: "#8f8f8f" }}>RM6.00</Text>
      <Text style={{ width: "16%", color: "#8f8f8f" }}>RM300.00</Text>
    </View>
  );

  //   const renderAddPO = ({ item }) => (
  //     <View
  //       style={{
  //         backgroundColor: "#ffffff",
  //         flexDirection: "row",
  //         paddingVertical: 20,
  //         paddingHorizontal: 20,
  //         borderBottomWidth: StyleSheet.hairlineWidth,
  //         borderBottomColor: "#c4c4c4",
  //       }}
  //     >
  //       <View style={{ width: 120, color: "#969696" }}>
  //         <DropDownPicker
  //           items={[
  //             {
  //               label: "Chicken patty",
  //               value: "Chicken patty",
  //             },
  //             {
  //               label: "Beef patty",
  //               value: "Beef patty",
  //             },
  //             {
  //               label: "lamb chop",
  //               value: "lamb chop",
  //             },
  //           ]}
  //           defaultValue={""}
  //           placeholder=""
  //           containerStyle={{ height: 30 }}
  //           style={{ backgroundColor: "#FAFAFA" }}
  //           itemStyle={{
  //             justifyContent: "flex-start",
  //           }}
  //           dropDownStyle={{ backgroundColor: "#FAFAFA" }}
  //           onChangeItem={(item) =>
  //             setState({
  //               choice4: item.value,
  //             })
  //           }
  //         />
  //       </View>
  //       <Text style={{ width: "2%" }}></Text>
  //       <Text style={{ width: "16%", color: "#8f8f8f" }}>meat</Text>
  //       <Text style={{ width: "6%", color: "#8f8f8f" }}>13</Text>
  //       <View
  //         style={{
  //           width: "12%",
  //           alignItems: "center",
  //           backgroundColor: "#f5f5f5",
  //           borderRadius: 10,
  //           padding: 10,
  //         }}
  //       >
  //         <Text style={{ color: "#8f8f8f" }}>50</Text>
  //       </View>
  //       <Text style={{ width: "14%", marginLeft: 50, color: "#8f8f8f" }}>50</Text>
  //       <Text style={{ width: "18%", color: "#8f8f8f" }}>RM6.00</Text>
  //       <Text style={{ width: "10%", color: "#8f8f8f" }}>RM300.00</Text>
  //       <TouchableOpacity style={{ marginLeft: 10 }} onPress={() => {}}>
  //         <Icon name="trash-2" size={20} color="#eb3446" />
  //       </TouchableOpacity>
  //     </View>
  //   );

  //   const renderAddStock = ({ item }) => (
  //     <View
  //       style={{
  //         backgroundColor: "#ffffff",
  //         flexDirection: "row",
  //         paddingVertical: 20,
  //         paddingHorizontal: 20,
  //         borderBottomWidth: StyleSheet.hairlineWidth,
  //         borderBottomColor: "#c4c4c4",
  //       }}
  //     >
  //       <View style={{ width: 120 }}>
  //         <DropDownPicker
  //           items={[
  //             {
  //               label: "Chicken patty",
  //               value: "Chicken patty",
  //             },
  //             {
  //               label: "Beef patty",
  //               value: "Beef patty",
  //             },
  //             {
  //               label: "lamb chop",
  //               value: "lamb chop",
  //             },
  //           ]}
  //           defaultValue={choice4}
  //           placeholder=""
  //           containerStyle={{ height: 30 }}
  //           style={{ backgroundColor: "#FAFAFA" }}
  //           itemStyle={{
  //             justifyContent: "flex-start",
  //           }}
  //           dropDownStyle={{ backgroundColor: "#FAFAFA" }}
  //           onChangeItem={(item) =>
  //             setState({
  //               choice4: item.value,
  //             })
  //           }
  //         />
  //       </View>
  //       <Text style={{ width: "6%" }}></Text>
  //       <Text style={{ width: "17%", color: "#949494" }}>meat</Text>
  //       <Text style={{ width: "17%", color: "#949494" }}>50</Text>
  //       <View
  //         style={{
  //           width: "9%",
  //           backgroundColor: "#f0f0f0",
  //           borderRadius: 10,
  //           padding: 10,
  //         }}
  //       >
  //         <Text style={{ color: "#949494" }}>50</Text>
  //       </View>
  //       <Text style={{ width: "8%" }}></Text>
  //       <Text style={{ width: "17%", color: "#949494" }}>RM6.00</Text>
  //       <Text style={{ width: "17%", color: "#949494" }}>RM300.00</Text>
  //     </View>
  //   );

  const renderAddCountedStockTake = ({ item, index }) => (
    <View
      style={{
        backgroundColor: "#ffffff",
        flexDirection: "row",
        paddingVertical: 20,
        paddingHorizontal: 20,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: "#c4c4c4",
        backgroundColor:
          item.quantity > item.countedQuantity
            ? "#ffe8e8"
            : item.quantity == item.countedQuantity
            ? Colors.fieldtBgColor
            : Colors.lightPrimary,
        //alignContent: 'center',
        alignItems: "center",
        //justifyContent: 'center',
        //backgroundColor: 'blue',
      }}
    >
      <Text
        style={{
          width: "12%",
          color: "#949494",
          marginRight: 2,
          fontSize: switchMerchant ? 10 : 14,
          fontFamily: "NunitoSans-Regular",
        }}
      >
        {item.name}
      </Text>

      {/* <View style={{ width: '12%' }}>
        <DropDownPicker
          // items={[
          //   {
          //     label: 'Chicken patty',
          //     value: 'Chicken patty',
          //   },
          //   {
          //     label: 'Beef patty',
          //     value: 'Beef patty',
          //   },
          //   {
          //     label: 'lamb chop',
          //     value: 'lamb chop',
          //   },

          // ]}
          items={outletSupplyItemDropdownList}
          defaultValue={poItems[index].outletSupplyItemId}
          placeholder="Outlet Supply Items"
          containerStyle={{ height: 30 }}
          style={{ backgroundColor: '#FAFAFA' }}
          itemStyle={{
            justifyContent: 'flex-start',
          }}
          dropDownStyle={{ backgroundColor: '#FAFAFA' }}
          onChangeItem={(item) =>
            // setState({
            //   choice4: item.value,
            // })
            setPoItems(poItems.map((poItem, i) => (i === index ? {
              ...poItem,
              outletSupplyItemId: item.value,
              name: allOutletsSupplyItemsDict[item.value].name,
              sku: allOutletsSupplyItemsDict[item.value].sku,
              quantity: allOutletsSupplyItemsDict[item.value].quantity,
              countedQuantity: allOutletsSupplyItemsDict[item.value].quantity,
              diffQuantity: 0,
              diffCost: 0,
              price: allOutletsSupplyItemsDict[item.value].price,
              totalPrice: allOutletsSupplyItemsDict[item.value].quantity * allOutletsSupplyItemsDict[item.value].price,
            } : poItem)))
          }
        />
      </View> */}

      {/* <View style={{ width: '1%', alignSelf: 'center' }}></View> */}

      <Text
        style={{
          width: "11%",
          color: "#949494",
          marginHorizontal: 2,
          fontFamily: "NunitoSans-Regular",
          fontSize: switchMerchant ? 10 : 14,
        }}
      >
        {item.skuMerchant || item.sku}
      </Text>
      <Text
        style={{
          width: "11%",
          color: "#949494",
          marginHorizontal: 2,
          fontFamily: "NunitoSans-Regular",
          fontSize: switchMerchant ? 10 : 14,
        }}
      >
        RM{item.price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
      </Text>
      <Text
        style={{
          width: "12%",
          color: "#949494",
          marginHorizontal: 2,
          fontFamily: "NunitoSans-Regular",
          fontSize: switchMerchant ? 10 : 14,
        }}
      >
        {item.unit}
      </Text>
      <Text
        style={{
          width: "11%",
          color: "#949494",
          marginHorizontal: 2,
          fontFamily: "NunitoSans-Regular",
          fontSize: switchMerchant ? 10 : 14,
        }}
      >
        {item.quantity.toFixed(2)}
      </Text>

      <View
        style={{
          width: "13%",
          marginHorizontal: 2,
          // backgroundColor: Colors.whiteColor,
          // borderRadius: 10,
          // padding: 10,
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.22,
          shadowRadius: 3.22,
          elevation: 1,
        }}
      >
        <KeyboardAvoidingView
          behavior="padding"
          style={{}}
          keyboardVerticalOffset={Platform.OS === "ios" ? 80 : 0}
        >
          <TextInput
            editable={
              !selectedStockTakeEdit ||
              (selectedStockTakeEdit &&
                selectedStockTakeEdit.status !== STOCK_TAKE_STATUS.COMPLETED)
                ? true
                : false
            }
            style={{
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 5,
              marginVertical: 5,
              borderWidth: 1,
              borderColor: "#E5E5E5",
              paddingLeft: 10,
              color:
                item.quantity > item.countedQuantity
                  ? "#e02424"
                  : Colors.blackColor,
              marginTop: Platform.OS === "ios" ? 0 : -15,
              marginBottom: Platform.OS === "ios" ? 0 : -15,
              width: switchMerchant ? 55 : 80,
              padding: 5,
              fontFamily: "NunitoSans-Regular",
              fontSize: switchMerchant ? 10 : 14,
            }}
            // placeholder="0"
            value={item.countedQuantity.toFixed(0)}
            onChangeText={(text) => {
              // setState({ itemName: text });

              const value = text.length > 0 ? parseFloat(text) : 0;

              setPoItems(
                poItems.map((poItem, i) =>
                  i === index
                    ? {
                        ...poItem,
                        countedQuantity: value,
                        diffQuantity: value - poItem.quantity,
                        diffCost: (value - poItem.quantity) * poItem.price,
                        totalPrice:
                          value * poItem.price < 0 ? 0 : value * poItem.price, // total price should be positive
                      }
                    : poItem
                )
              );
            }}
            keyboardType={"number-pad"}
            placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
          />
        </KeyboardAvoidingView>
      </View>

      <Text
        style={{
          width: "13%",
          color: "#949494",
          marginHorizontal: 2,
          fontFamily: "NunitoSans-Regular",
          fontSize: switchMerchant ? 10 : 14,
          padding: 0,
        }}
      >
        {item.diffQuantity.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
      </Text>
      <Text
        style={{
          width: "14%",
          color: "#949494",
          marginLeft: 2,
          fontFamily: "NunitoSans-Regular",
          fontSize: switchMerchant ? 10 : 14,
          padding: 0,
        }}
      >
        {item.diffCost.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
      </Text>
      {/* <TouchableOpacity style={{ width: '12%', flexDirection: "row" }}> */}
      {/* <Icon2 name="redo" size={25} color={Colors.primaryColor} />
        <Text style={{ marginLeft: 8, color: Colors.primaryColor, fontSize: 16 }}>undo</Text> */}
      {/* </TouchableOpacity> */}
      <TouchableOpacity
        style={{
          right: switchMerchant ? 25 : 30,
        }}
        onPress={() => {
          setSelectedPoItemIndex(index);
          setRemarkModal(true);
        }}
      >
        {/* <Icon3 name="clipboard-pencil" size={20} color="#000" /> */}
        <Icon
          name="clipboard"
          size={switchMerchant ? 15 : 20}
          color={Colors.secondaryColor}
        />
      </TouchableOpacity>
    </View>
  );

  const renderAddUncountedStockTake = ({ item }) => (
    <View
      style={{
        backgroundColor: "#ffffff",
        flexDirection: "row",
        paddingVertical: 20,
        paddingHorizontal: 20,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: "#c4c4c4",
        marginTop: 10,
      }}
    >
      <Text style={{ width: "16%", color: "#949494" }}>Mustard</Text>
      <Text style={{ width: "16%", color: "#949494" }}>sauce</Text>
      <Text style={{ width: "16%", color: "#949494" }}>12345</Text>
      <Text style={{ width: "16%", color: "#949494" }}>0</Text>
      <View
        style={{
          width: "10%",
          color: "#949494",
          backgroundColor: "#f5f5f5",
          borderRadius: 10,
        }}
      >
        <Text style={{ padding: 8 }}>0</Text>
      </View>
      <View style={{ width: "16%", marginLeft: 60 }}>
        <TouchableOpacity
          style={{ flexDirection: "row" }}
          onPress={() => {
            setState({ modal: true });
          }}
        >
          <input
            defaultValue={isSelected2}
            onChange={(isSelected2) => setState({ isSelected2 })}
            style={
              {
                // alignSelf: "center",
                // borderRadius: 15,
                // paddingBottom: 3,
              }
            }
            type={"checkbox"}
            //   checked={allOutlets.length === selectedOutletList.length}
          />
          {/* <CheckBox
            value={isSelected2}
            onValueChange={(isSelected2) => setState({ isSelected2 })}
          /> */}
          <Text
            style={{ marginLeft: 4, color: Colors.primaryColor, fontSize: 18 }}
          >
            Confirmed
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  const email = () => {
    var body = {
      stockTransferId: 1,
      email: Email,
    };
    // ApiClient.POST(API.emailStockTransfer, body, false).then((result) => {

    //   if (result == true) {

    //     Alert.alert(
    //       'Success',
    //       'The email had sent',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  const CheckStockOrder = () => {
    var body = {
      stockOrderId: 1,
      counted: [
        {
          id: 1,
          soId: 2,
          itemId: 4,
          name: "food",
          quantity: -118,
          amount: 2,
        },
      ],
    };
    // ApiClient.POST(API.checkStockOrder, body, false).then((result) => {

    //   if (result !== null) {

    //     Alert.alert(
    //       'Success',
    //       'The quantity keeped',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  const CheckStockOrderDelete = () => {
    var body = {
      stockOrderId: 1,
      counted: [
        {
          id: 1,
          soId: 2,
          itemId: 4,
          name: "food",
          quantity: -118,
          amount: 2,
        },
      ],
    };
    // ApiClient.POST(API.checkStockOrderDelete, body, false).then((result) => {

    //   if (result !== null) {

    //     Alert.alert(
    //       'Success',
    //       'the quantity reduce to 0',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  //   const createStockOrder = () => {
  //     var body = {
  //       supplierId: choice7,
  //       outletId: choice8,
  //       eta: moment(date).format("YYYY-MM-DD" + " " + "hh:mm:ss"),
  //       items: [
  //         {
  //           id: 5,
  //           sku: "papaya",
  //           quantity: 50,
  //         },
  //         {
  //           id: 6,
  //           sku: "mango",
  //           quantity: 20,
  //         },
  //       ],
  //       remarks: "Order Stock",
  //     };
  //     // ApiClient.POST(API.createStockOrder, body).then((result) => {
  //     //   if (result !== null) {

  //     //     Alert.alert(
  //     //       'Success',
  //     //       'The order has created',
  //     //       [
  //     //         { text: "OK", onPress: () => { } }
  //     //       ],
  //     //       { cancelable: false },
  //     //     );
  //     //   }
  //     // });
  //   };

  //   const createStockTransfer = () => {
  //     var body = {
  //       fromOutlet: choice1,
  //       toOutlet: choice3,
  //       email: Email,
  //       items: [
  //         {
  //           id: 11,
  //           quantity: 20,
  //         },
  //         {
  //           id: 12,
  //           quantity: 30,
  //         },
  //       ],
  //       remarks: "Stock Transfer",
  //     };
  //     // ApiClient.POST(API.createStockTransfer, body).then((result) => {

  //     //   if (result.success !== null) {

  //     //     Alert.alert(
  //     //       'Success',
  //     //       'The Stock Transfer has created',
  //     //       [
  //     //         { text: "OK", onPress: () => { } }
  //     //       ],
  //     //       { cancelable: false },
  //     //     );
  //     //   }
  //     // });
  //   };

  const editStockOrder = () => {
    var body = {
      productId: 2,
      supplierId: 3,
      remarks: "meat",
      eta: "10-1-2020 01:46:22",
      itemId: 11,
      name: "chicken",
      quantity: 100,
    };
    // ApiClient.POST(API.editStockOrder, body).then((result) => {

    //   if (result.success !== null) {

    //     Alert.alert(
    //       'Success',
    //       'The Stock Order has edited',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  const editStockTransfer = () => {
    var body = {
      stProduct: 1,
      toOutlet: 2,
      email: Email,
      itemId: 61,
      quantity: 120,
      remarks: "Stock Transfer",
    };
    // ApiClient.POST(API.editStockTransfer, body).then((result) => {

    //   if (result.success !== null) {

    //     Alert.alert(
    //       'Success',
    //       'The Stock Transfer has edited',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  const settingLowStock = () => {
    var body = {
      itemId: itemId,
      ideal: ideal,
      minimum: minimum,
    };
    // ApiClient.POST(API.settingLowStock, body).then((result) => {

    //   if (result.success !== null) {

    //     Alert.alert(
    //       'Success',
    //       'The low stock has been setted',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  const createStockTake = (stockTakeStatus = STOCK_TAKE_STATUS.CREATED) => {
    if (
      allOutlets.find((outlet) => outlet.uniqueId === selectedTargetOutletId) &&
      (suppliers.find((supplier) => supplier.uniqueId === selectedSupplierId) ||
        selectedSupplierId === "ALL")
    ) {
      if (selectedStockTakeEdit === null) {
        var body = {
          stId: poId,
          stItems: poItems,
          diffQuantityTotal: +parseFloat(diffQuantityTotal).toFixed(2),
          diffCostTotal: +parseFloat(diffCostTotal).toFixed(2),
          completedDate: moment().valueOf(), // will change to editable in future

          status: stockTakeStatus,

          outletId: selectedTargetOutletId,
          outletName: allOutlets.find(
            (outlet) => outlet.uniqueId === selectedTargetOutletId
          ).name,

          supplierId: selectedSupplierId,
          supplierName:
            selectedSupplierId === "ALL"
              ? "All"
              : suppliers.find(
                  (supplier) => supplier.uniqueId === selectedSupplierId
                ).name,

          startDate: startDate,

          merchantId: merchantId,
          remarks: remark,

          staffName: userName,
          staffId: userId,
        };

        console.log("checking", body);

        ApiClient.POST(API.createStockTake, body).then((result) => {
          console.log("result", result);

          if (result && result.status === "success") {
            if (window.confirm("Success. Stock take has been saved") == true) {
              CommonStore.update((s) => {
                s.selectedStockTakeEdit = null;
              });

              setStockTake(true);
              setAddStockTake(false);
              setAddStock(false);
            } else {
              console.log("You canceled!");
            }
          } else {
            if (window.confirm("Error. Failed to create Stock take") == true) {
              CommonStore.update((s) => {
                s.selectedStockTakeEdit = null;
              });

              setStockTake(true);
              setAddStockTake(false);
              setAddStock(false);
            } else {
              console.log("You canceled!");
            }
          }
        });
      } else {
        var body = {
          stId: poId,
          stItems: poItems,
          diffQuantityTotal: +parseFloat(diffQuantityTotal).toFixed(2),
          diffCostTotal: +parseFloat(diffCostTotal).toFixed(2),
          completedDate: moment().valueOf(), // will change to editable in future

          status: stockTakeStatus,

          outletId: selectedTargetOutletId,
          outletName: allOutlets.find(
            (outlet) => outlet.uniqueId === selectedTargetOutletId
          ).name,

          supplierId: selectedSupplierId,
          supplierName:
            selectedSupplierId === "ALL"
              ? "All"
              : suppliers.find(
                  (supplier) => supplier.uniqueId === selectedSupplierId
                ).name,

          startDate: startDate,

          merchantId: merchantId,
          remarks: remark,

          stockTakeId: selectedStockTakeEdit.uniqueId,
        };
        console.log("checking", body);
        console.log(body);

        ApiClient.POST(API.updateStockTake, body).then((result) => {
          console.log("result", result);

          if (result && result.status === "success") {
            if (window.confirm("Success. Stock take has been saved") == true) {
              CommonStore.update((s) => {
                s.selectedStockTakeEdit = null;
              });

              setStockTake(true);
              setAddStockTake(false);
              setAddStock(false);
            } else {
              console.log("You canceled!");
            }
          } else {
            if (window.confirm("Error. Failed to create Stock take") == true) {
              CommonStore.update((s) => {
                s.selectedStockTakeEdit = null;
              });

              setStockTake(true);
              setAddStockTake(false);
              setAddStock(false);
            } else {
              console.log("You canceled!");
            }
          }
        });
      }
    } else {
      if (window.confirm("Info. Please select supplier and outlet") == true) {
        console.log("You pressed OK!");
      } else {
        console.log("You canceled!");
      }
    }
  };

  // function end

  return (
    // <View style={styles.container}>
    //   <View style={styles.sidebar}>
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={6} expandReport={true} />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView>
          {/* <View style={styles.content}> */}

          <Modal
            supportedOrientations={["portrait", "landscape"]}
            style={
              {
                // flex: 1
              }
            }
            visible={exportModal}
            transparent={true}
            animationType={"fade"}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  height: Dimensions.get("screen").width * 0.3,
                  width: Dimensions.get("screen").width * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: Dimensions.get("screen").width * 0.03,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TouchableOpacity
                  style={{
                    position: "absolute",
                    right: Dimensions.get("screen").width * 0.02,
                    top: Dimensions.get("screen").width * 0.02,

                    elevation: 1000,
                    zIndex: 1000,
                  }}
                  onPress={() => {
                    setExportModal(false);
                  }}
                >
                  <AntDesign
                    name="closecircle"
                    size={switchMerchant ? 15 : 25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View
                  style={{
                    alignItems: "center",
                    top: "20%",
                    position: "absolute",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      textAlign: "center",
                      fontSize: switchMerchant ? 18 : 20,
                    }}
                  >
                    Download Report
                  </Text>
                </View>
                <View style={{ top: switchMerchant ? "14%" : "10%" }}>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: "NunitoSans-Bold",
                    }}
                  >
                    Email Address:
                  </Text>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={{
                      backgroundColor: Colors.fieldtBgColor,
                      width: switchMerchant ? 240 : 370,
                      height: switchMerchant ? 35 : 50,
                      borderRadius: 5,
                      padding: 5,
                      marginVertical: 5,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      paddingLeft: 10,
                      fontSize: switchMerchant ? 10 : 16,
                    }}
                    placeholderStyle={{ padding: 5 }}
                    placeholder="Enter Your Email"
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    onChangeText={(text) => {
                      setExportEmail(text);
                    }}
                    value={exportEmail}
                  />
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: "NunitoSans-Bold",
                      marginTop: 15,
                    }}
                  >
                    Send As:
                  </Text>

                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      //top: '10%',
                      flexDirection: "row",
                      //   borderWidth:1,
                      marginTop: 5,
                    }}
                  >
                    {/* <View>
                      <CSVLink
                        style={{
                          //     justifyContent: "center",
                          // flexDirection: "row",
                          // borderWidth: 1,
                          // borderColor: Colors.primaryColor,
                          // backgroundColor: "#4E9F7D",
                          // borderRadius: 5,
                          // width: 120,
                          // paddingHorizontal: 10,
                          // height: 40,
                          // alignItems: "center",
                          // shadowOffset: {
                          //   width: 0,
                          //   height: 2,
                          // },
                          // shadowOpacity: 0.22,
                          // shadowRadius: 3.22,
                          // elevation: 1,
                          // zIndex: -1,
                          // marginRight: 15,
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          display: "inline-block",
                          flexDirection: "row",
                          textDecoration: "none",
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          width: 100,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginRight: 15,
                        }}
                        onClick={() => {
                          setExportModal(false);
                        }}
                        data={convertDataToExcelFormat()}
                        filename="KooDoo Stock Take Report.xlsx"
                      >
                        <View
                          style={{
                            width: "100%",
                            height: "100%",
                            alignContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: 16,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            EXCEL
                          </Text>
                        </View>
                      </CSVLink>
                    </View> */}

                    {/* <TouchableOpacity
                      disabled={isLoading}
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: switchMerchant ? 100 : 120,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginRight: 15,
                      }}
                      onPress={() => {
                        if (exportEmail.length > 0) {
                          CommonStore.update((s) => {
                            s.isLoading = true;
                          });
                          setIsLoadingExcel(true);

                          const excelData = convertDataToExcelFormat();

                          generateEmailReport(
                            EMAIL_REPORT_TYPE.EXCEL,
                            excelData,
                            "KooDoo Stock Take Report",
                            "KooDoo Stock Take Report.xlsx",
                            `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                            exportEmail,
                            "KooDoo Stock Take Report",
                            "KooDoo Stock Take Report",
                            () => {
                              CommonStore.update((s) => {
                                s.isLoading = false;
                              });
                              setIsLoadingExcel(false);

                              Alert.alert(
                                "Success",
                                "Report will be sent to the email address soon"
                              );

                              setExportModal(false);
                            }
                          );
                        } else {
                          Alert.alert("Info", "Invalid email address");
                        }
                      }}
                    >
                      {isLoadingExcel ? (
                        <ActivityIndicator
                          size={"small"}
                          color={Colors.whiteColor}
                        />
                      ) : (
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          EXCEL
                        </Text>
                      )}
                    </TouchableOpacity> */}

                    <View>
                      <CSVLink
                        style={{
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          display: "inline-block",
                          flexDirection: "row",
                          textDecoration: "none",
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          width: 100,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                        }}
                        onClick={() => {
                          setExportModal(false);
                        }}
                        data={convertArrayToCSV(stockTakes)}
                        filename="KooDoo Stock Take Report.csv"
                      >
                        <View
                          style={{
                            width: "100%",
                            height: "100%",
                            alignContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: 16,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            CSV
                          </Text>
                        </View>
                      </CSVLink>
                    </View>

                    {/* <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: switchMerchant ? 100 : 120,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      onPress={() => {
                        if (exportEmail.length > 0) {
                          CommonStore.update((s) => {
                            s.isLoading = true;
                          });
                          setIsLoadingCsv(true);

                          const csvData = convertArrayToCSV(stockTakes);

                          generateEmailReport(
                            EMAIL_REPORT_TYPE.CSV,
                            csvData,
                            "KooDoo Stock Take Report",
                            "KooDoo Stock Take Report.csv",
                            `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                            exportEmail,
                            "KooDoo Stock Take Report",
                            "KooDoo Stock Take Report",
                            () => {
                              CommonStore.update((s) => {
                                s.isLoading = false;
                              });
                              setIsLoadingCsv(false);
                              Alert.alert(
                                "Success",
                                "Report will be sent to the email address soon"
                              );

                              setExportModal(false);
                            }
                          );
                        } else {
                          Alert.alert("Info", "Invalid email address");
                        }
                      }}
                    >
                      {isLoadingCsv ? (
                        <ActivityIndicator
                          size={"small"}
                          color={Colors.whiteColor}
                        />
                      ) : (
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          CSV
                        </Text>
                      )}
                    </TouchableOpacity> */}
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            supportedOrientations={["portrait", "landscape"]}
            style={
              {
                // flex: 1
              }
            }
            visible={importModal}
            transparent={true}
            animationType={"fade"}
          >
            <View style={styles.modalContainer}>
              <View style={[styles.modalViewImport]}>
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => {
                    // setState({ changeTable: false });
                    setImportModal(false);
                  }}
                >
                  <AntDesign
                    name="closecircle"
                    size={25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View style={{ padding: 10, margin: 30 }}>
                  <View
                    style={[
                      styles.modalTitle1,
                      { justifyContent: "center", alignItems: "center" },
                    ]}
                  >
                    <Text
                      style={[
                        styles.modalTitleText1,
                        { fontSize: 16, fontWeight: "500" },
                      ]}
                    >
                      Imported List
                    </Text>
                  </View>
                  {/* <View style={{
                  heigth: 70,
                  marginVertical: 10,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  height: '80%'
                }}>
                <Table borderStyle={{ borderWidth: 1 }}>
                  <Row data={TableData.tableHead} flexArr={[1, 2, 1, 1]} style={{}}/>
                  <TableWrapper style={{}}>
                  <Col data={TableData.tableTitle} style={{flex: 1}} heightArr={[28, 28, 28, 28]} textStyle={{}}/>
                  <Rows data={TableData.tableData} flexArr={[1, 2, 1, 1]} style={{height: 28}} textStyle={{textAlign: 'center'}}/>
                  </TableWrapper>
                </Table>
                </View> */}
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: Colors.primaryColor,
                        width: 150,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: "center",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          importSelectFile();
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            alignSelf: "center",
                            marginVertical: 10,
                          }}
                        >
                          IMPORT
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          backgroundColor: Colors.whiteColor,
                          width: 150,
                          height: 40,
                          marginVertical: 15,
                          borderRadius: 5,
                          alignSelf: "center",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            //   todo
                            // setImportTemplate(false);
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.primaryColor,
                              alignSelf: "center",
                              marginVertical: 10,
                            }}
                          >
                            CANCEL
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          backgroundColor: Colors.primaryColor,
                          width: 150,
                          height: 40,
                          marginVertical: 15,
                          borderRadius: 5,
                          alignSelf: "center",
                        }}
                      >
                        <TouchableOpacity onPress={() => {}}>
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              alignSelf: "center",
                              marginVertical: 10,
                            }}
                          >
                            SAVE
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <View
            style={[styles.content, { paddingTop: switchMerchant ? 0 : 10 }]}
          >
            <Modal
              supportedOrientations={["portrait", "landscape"]}
              style={
                {
                  // flex: 1
                }
              }
              visible={remarkModal}
              transparent={true}
              animationType={"fade"}
            >
              <View style={styles.modalContainer}>
                <View
                  style={[
                    styles.modalView,
                    {
                      height: Dimensions.get("screen").width * 0.3,
                    },
                  ]}
                >
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={() => {
                      setRemarkModal(false);
                    }}
                  >
                    <AntDesign
                      name="closecircle"
                      size={switchMerchant ? 15 : 25}
                      color={Colors.fieldtTxtColor}
                    />
                  </TouchableOpacity>
                  <View
                    style={
                      (styles.modalTitle, { top: "20%", position: "absolute" })
                    }
                  >
                    <Text style={styles.modalTitleText}>Remarks</Text>
                  </View>
                  <View style={{ top: "10%" }}>
                    <View
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <TextInput
                        // underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                          marginTop: "2%",
                          padding: 5,
                          backgroundColor: Colors.fieldtBgColor,
                          width: Dimensions.get("screen").width * 0.3,
                          height: Dimensions.get("screen").height * 0.22,
                          borderRadius: 5,
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          marginVertical: 5,
                          paddingTop: Platform.OS == "ios" ? 10 : 0,
                          paddingLeft: 10,
                          fontFamily: "NunitoSans-Regular",
                          fontSize: switchMerchant ? 10 : 14,
                          textAlignVertical: "top",
                          paddingTop: switchMerchant ? 5 : "2%",
                        }}
                        placeholder="Remarks"
                        placeholderStyle={{
                          fontFamily: "NunitoSans-Regular",
                          fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholderTextColor={Platform.select({
                          ios: "#a9a9a9",
                        })}
                        onChangeText={(text) => {
                          // setRemark2(text);

                          setPoItems(
                            poItems.map((poItem, i) =>
                              i === selectedPoItemIndex
                                ? {
                                    ...poItem,
                                    remarks: text,
                                  }
                                : poItem
                            )
                          );
                        }}
                        value={poItems[selectedPoItemIndex].remarks}
                        multiline={true}
                      />
                    </View>
                    <View
                      style={{
                        alignItems: "center",
                        // top: '7%',
                        //marginTop: 10,
                        marginTop: 20,
                      }}
                    >
                      <TouchableOpacity
                        style={{
                          justifyContent: "center",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          width: 100,
                          paddingHorizontal: 10,
                          height: switchMerchant ? 35 : 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                        }}
                        //onPress={() => { setRemarkModal(false) }}>
                        onPress={() => {
                          setRemarkModal(false);
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          DONE
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>
              </View>
            </Modal>
          </View>

          {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: Platform.OS == 'ios' ? 0 : 10 }} >
          <View style={{ marginRight: 248, }}>
            <View style={{ flexDirection: 'row', flex: 1 }}>
              <TouchableOpacity style={styles.submitText} onPress={() => { importCSV() }}>
                <View style={{ flexDirection: 'row' }}>
                  <Icon name="download" size={20} color={Colors.primaryColor} />
                  <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                    Import
                </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity style={[styles.submitText, { height: 40 }]} onPress={() => { setExportModal(true) }}>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Icon name="download" size={20} color={Colors.primaryColor} />
                  <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                    Download
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View> */}
          {/* <TextInput
            editable={!loading}
            underlineColorAndroid={Colors.whiteColor}
            clearButtonMode="while-editing"
            style={styles.textInput}
            placeholder="🔍  Search"
            onChangeText={(text) => {
              setState({ search: text.trim() });
            }}
            value={email}
          /> */}

          {/* <Ionicons
            name="search-outline"
            size={20}
            style={styles.searchIcon}
          />

          <TextInput
            editable={loading}
            clearButtonMode="while-editing"
            style={[styles.textInput, { fontFamily: "NunitoSans-Bold" }]}
            placeholder="Search"
            onChangeText={(text) => {
              setState({
                search: text.trim(),
              });
            }}
            value={email}
          /> */}

          {/* <View
            style={[{
              // flex: 1,
              // alignContent: 'flex-end',
              // marginBottom: 10,
              // flexDirection: 'row',
              // marginRight: '-40%',
              // marginLeft: 310,
              // backgroundColor: 'red',
              // alignItems: 'flex-end',
              // right: '-50%',
              // width: '28%',
              height: 40,

            }, !isTablet ? {
              marginLeft: 0,
            } : {}]}>
            <View style={{
              width: 250,
              height: 40,
              backgroundColor: 'white',
              borderRadius: 10,
              // marginLeft: '53%',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',

              // marginRight: Dimensions.get('screen').width * Styles.sideBarWidth,

              position: 'absolute',
              right: '35%',

              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 3,
            }}>
              <Icon name="search" size={18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />
              <TextInput
                editable={!loading}
                // underlineColorAndroid={Colors.primaryColor}
                style={{
                  width: 250,
                  fontSize: 15,
                  fontFamily: 'NunitoSans-Regular',
                }}
                clearButtonMode="while-editing"
                placeholder=" Search"
                onChangeText={(text) => {
                  // setSearch(text.trim());
                  // setList1(false);
                  // setSearchList(true);
                  setSearch(text.trim());
                }}
                value={search}
              />
            </View>
          </View>

        </View> */}

          {/*  <View
          style={{
            flexDirection: 'row',
            backgroundColor: Colors.highlightColor,
            padding: 12,
          }}>
          <TouchableOpacity
            onPress={() => {
              setState({
                lowStockAlert: true,
                purchaseOrder: false,
                stockTransfer: false,
                stockTake: false,
                addPurchase: false,
                editPurchase: false,
                addStockTransfer: false,
                addStockTake: false,
              });
            }}>
            <Text>Low Stock Alerts</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setState({
                lowStockAlert: false,
                purchaseOrder: true,
                stockTransfer: false,
                stockTake: false,
                addPurchase: false,
                editPurchase: false,
                addStockTransfer: false,
                addStockTake: false,
              });
            }}>
            <Text style={{ marginLeft: 30 }}>Purchase Order</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setState({
                lowStockAlert: false,
                purchaseOrder: false,
                stockTransfer: true,
                stockTake: false,
                addPurchase: false,
                editPurchase: false,
                addStockTransfer: false,
                addStockTake: false,
              });
            }}>
            <Text style={{ marginLeft: 30 }}>Stock Transfer</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              setState({
                lowStockAlert: false,
                purchaseOrder: false,
                stockTransfer: false,
                stockTake: true,
                addPurchase: false,
                editPurchase: false,
                addStockTransfer: false,
                addStockTake: false,
              });
            }}>
            <Text style={{ marginLeft: 30 }}>Stock Take</Text>
          </TouchableOpacity>
        </View> */}

          {stockTake ? (
            // <ScrollView
            //   scrollEnabled={switchMerchant}
            //   horizontal={true}
            //   contentContainerStyle={{ paddingRight: '5%' }}
            // >
            <ScrollView
              contentContainerStyle={{
                paddingBottom: switchMerchant ? 20 : 5,
              }}
              //style={{ backgroundColor: 'red'}}
              scrollEnabled={switchMerchant}
            >
              <View style={{}}>
                <View
                  style={{
                    width: Dimensions.get("screen").width * 0.87,
                    alignSelf: "center",
                  }}
                >
                  <View
                    style={{
                      alignItems: "center",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        //fontSize: 30,
                        fontSize: switchMerchant ? 20 : 26,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      {stockTakes.length} Stock Take
                    </Text>

                    <View style={{ flexDirection: "row" }}>
                      <View style={{ flexDirection: "row" }}>
                        {/* {isTablet && ( */}
                        <View
                          style={{
                            alignItem: "center",
                          }}
                        >
                          <TouchableOpacity
                            style={{
                              justifyContent: "center",
                              flexDirection: "row",
                              borderWidth: 1,
                              borderColor: Colors.primaryColor,
                              backgroundColor: "#4E9F7D",
                              borderRadius: 5,
                              //width: 160,
                              paddingHorizontal: 10,
                              height: switchMerchant ? 35 : 40,
                              alignItems: "center",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 1,
                              zIndex: -1,
                            }}
                            onPress={() => {
                              setExportModal(true);
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Icon
                                name="download"
                                size={switchMerchant ? 10 : 20}
                                color={Colors.whiteColor}
                              />
                              <Text
                                style={{
                                  color: Colors.whiteColor,
                                  marginLeft: 5,
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                }}
                              >
                                DOWNLOAD
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {/* )} */}
                      </View>

                      <View style={{ flexDirection: "row" }}>
                        {/* {isTablet && ( */}
                        <View
                          style={{
                            alignSelf: "flex-end",
                          }}
                        >
                          <TouchableOpacity
                            style={{
                              justifyContent: "center",
                              flexDirection: "row",
                              borderWidth: 1,
                              borderColor: Colors.primaryColor,
                              backgroundColor: "#4E9F7D",
                              borderRadius: 5,
                              //width: 160,
                              paddingHorizontal: switchMerchant ? 5 : 10,
                              height: switchMerchant ? 35 : 40,
                              alignItems: "center",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 1,
                              zIndex: -1,
                              marginHorizontal: switchMerchant ? 10 : 15,
                            }}
                            onPress={() => {
                              CommonStore.update((s) => {
                                s.selectedStockTakeEdit = null;
                              });
                              setStockTake(false);
                              setAddStockTake(true);
                              setAddStock(true);
                              console.log("Hello123");
                              console.log(currOutletId);
                              // console.log(allOutlets[1].uniqueId);
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <AntDesign
                                name="pluscircle"
                                size={switchMerchant ? 10 : 20}
                                color={Colors.whiteColor}
                              />
                              <Text
                                style={{
                                  color: Colors.whiteColor,
                                  marginLeft: 5,
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                }}
                              >
                                STOCK TAKE
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {/* )} */}
                      </View>

                      <View style={{ flexDirection: "row" }}>
                        <View
                          style={[
                            {
                              height: switchMerchant ? 35 : 40,
                            },
                          ]}
                        >
                          <View
                            style={{
                              width: switchMerchant ? 200 : 250,
                              height: switchMerchant ? 35 : 40,
                              backgroundColor: "white",
                              borderRadius: 5,
                              // marginLeft: '53%',
                              flexDirection: "row",
                              alignContent: "center",
                              alignItems: "center",
                              shadowColor: "#000",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 3,
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                            }}
                          >
                            <Icon
                              name="search"
                              size={switchMerchant ? 13 : 18}
                              color={Colors.primaryColor}
                              style={{ marginLeft: 15 }}
                            />
                            <TextInput
                              editable={!loading}
                              // underlineColorAndroid={Colors.primaryColor}
                              style={{
                                width: switchMerchant ? 180 : 220,
                                fontSize: 15,
                                fontFamily: "NunitoSans-Regular",
                                paddingLeft: 5,
                                fontSize: switchMerchant ? 10 : 16,
                                height: 45,
                              }}
                              clearButtonMode="while-editing"
                              placeholder=" Search"
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              onChangeText={(text) => {
                                setSearch(text.trim());
                              }}
                              value={search}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>

                {/* <View style={{
                    shadowOpacity: 0,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    borderRadius: 10,
                    backgroundColor: Colors.whiteColor,
                  }}> */}

                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    width: Dimensions.get("screen").width * 0.87,
                    height: Dimensions.get("screen").height * 0.7,
                    marginTop: 30,
                    marginHorizontal: 30,
                    marginBottom: 30,
                    alignSelf: "center",
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  }}
                >
                  <ScrollView
                    horizontal={true}
                    scrollEnabled={switchMerchant}
                    contentContainerStyle={
                      switchMerchant
                        ? {
                            flexDirection: "column",
                            width: Dimensions.get("screen").width * 0.87,
                            height: Dimensions.get("screen").height * 0.7,
                          }
                        : {
                            flexDirection: "column",
                            width: Dimensions.get("screen").width * 0.87,
                            height:
                              Platform.OS === "ios"
                                ? Dimensions.get("screen").height * 0.78
                                : Dimensions.get("screen").height * 0.66,
                          }
                    }
                  >
                    <View
                      style={{
                        backgroundColor: "#ffffff",
                        flexDirection: "row",
                        paddingVertical: 20,
                        paddingLeft: 8,
                        paddingRight: 5,
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        //marginTop: 5,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                        width: "100%",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "12%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginRight: 2,
                        }}
                      >
                        ID
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "10%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginRight: 2,
                        }}
                      >
                        Created Date
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "12%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 2,
                        }}
                      >
                        Completed Date
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "12%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 2,
                        }}
                      >
                        Staff
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "19%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 2,
                        }}
                      >
                        Store
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "17%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 2,
                        }}
                      >
                        Supplier
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "14%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginLeft: 2,
                        }}
                      >
                        Status
                      </Text>
                    </View>
                    <FlatList
                      nestedScrollEnabled={true}
                      showsVerticalScrollIndicator={false}
                      data={stockTakes.filter((item) => {
                        if (search !== "") {
                          const searchLowerCase = search.toLowerCase();

                          return (
                            item.outletName
                              .toLowerCase()
                              .includes(searchLowerCase) ||
                            item.supplierName
                              .toLowerCase()
                              .includes(searchLowerCase)
                          );
                        } else {
                          return true;
                        }
                      })}
                      extraData={stockTakes.filter((item) => {
                        if (search !== "") {
                          const searchLowerCase = search.toLowerCase();

                          return (
                            item.outletName
                              .toLowerCase()
                              .includes(searchLowerCase) ||
                            item.supplierName
                              .toLowerCase()
                              .includes(searchLowerCase)
                          );
                        } else {
                          return true;
                        }
                      })}
                      renderItem={renderStockTakeItem}
                      keyExtractor={(item, index) => String(index)}
                      contentContainerStyle={{
                        paddingRight: 5,
                        paddingBottom: switchMerchant ? 0 : 80,
                      }}
                    />
                  </ScrollView>
                </View>
              </View>
            </ScrollView>
          ) : // </ScrollView>

          null}

          {addStockTake ? (
            <View style={{ height: Dimensions.get("window").height }}>
              <View
                style={
                  {
                    //   shadowColor: "#000",
                    //   shadowOffset: {
                    //     width: 0,
                    //     height: 4,
                    //   },
                    //   shadowOpacity: 0.32,
                    //   shadowRadius: 3.22,
                    //   elevation: 2,
                    //   borderWidth: 1,
                  }
                }
              >
                <View
                  style={{
                    width: switchMerchant
                      ? Dimensions.get("screen").width * 0.886
                      : Dimensions.get("screen").width * 0.886,
                    alignSelf: switchMerchant ? "center" : "center",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      marginLeft: 0,
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      // setState({
                      //   lowStockAlert: false,
                      //   purchaseOrder: false,
                      //   stockTransfer: false,
                      //   stockTake: true,
                      //   addPurchase: false,
                      //   editPurchase: false,
                      //   addStockTransfer: false,
                      //   addStockTake: false,
                      // })

                      CommonStore.update((s) => {
                        s.selectedStockTakeEdit = null;
                      });

                      setStockTake(true);
                      setAddStockTake(false);
                      setAddStock(false);
                    }}
                  >
                    <Icon
                      name="chevron-left"
                      size={switchMerchant ? 20 : 30}
                      color={Colors.primaryColor}
                    />
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: switchMerchant ? 14 : 17,
                        color: Colors.primaryColor,
                        marginBottom: Platform.OS === "ios" ? 0 : 2,
                      }}
                    >
                      Back
                    </Text>
                  </TouchableOpacity>
                </View>

                {/* <ScrollView
                scrollEnabled={switchMerchant}
                horizontal={true}
                contentContainerStyle={{ paddingRight: '8%' }}
              > */}
                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    width: Dimensions.get("screen").width * 0.87,
                    height:
                      Platform.OS === "ios"
                        ? Dimensions.get("screen").height * 0.82
                        : Dimensions.get("screen").height * 0.71,
                    marginTop: switchMerchant ? 5 : 10,
                    marginHorizontal: switchMerchant ? 0 : 30,
                    //marginBottom: 30,
                    alignSelf: "center",
                    //borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                    borderRadius: 5,
                  }}
                >
                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{
                      // height: Dimensions.get('window').height * 0.75,
                      // backgroundColor: Colors.whiteColor,
                      // borderRadius: 10,
                      // marginTop: 10,
                      paddingTop: 30,
                      padding: 10,
                      //   borderWidth: 1,
                      // shadowColor: '#000',
                      // shadowOffset: {
                      //   width: 0,
                      //   height: 4,
                      // },
                      // shadowOpacity: 0.22,
                      // shadowRadius: 3.22,
                      // elevation: 1,
                      // height: '90%',
                    }}
                    contentContainerStyle={
                      {
                        //top: Platform.OS === 'ios' ? -keyboardHeight * 0.3 : 0,
                      }
                    }
                  >
                    <View
                      style={{
                        borderBottomWidth: StyleSheet.hairlineWidth,
                      }}
                    >
                      <View
                        style={{
                          marginBottom: 20,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              style={{
                                marginTop: 20,
                                fontSize: switchMerchant ? 20 : 40,
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              Stock Take
                            </Text>
                            <Text
                              style={{
                                fontSize: switchMerchant ? 10 : 16,
                                color: "#adadad",
                                textAlign: "center",
                              }}
                            >
                              Fill In The Stock Take Information
                            </Text>
                          </View>
                          {/* <View style={{ flexDirection: 'column', justifyContent: 'flex-end', marginRight: '1%' }}>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 200,
                            height: 40,
                            //marginVertical: 7,
                            borderRadius: 5,
                            alignSelf: 'center',
                            //marginLeft: 40
                          }}>
                          <TouchableOpacity onPress={() => {
                            createStockTake();
                          }}>
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                alignSelf: 'center',
                                marginVertical: 10,
                              }}>
                              SAVE
                            </Text>
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 200,
                            height: 40,
                            marginVertical: 7,
                            borderRadius: 5,
                            alignSelf: 'center',
                            //marginLeft: 40
                          }}>
                          <TouchableOpacity onPress={() => {
                            createStockTake(STOCK_TAKE_STATUS.COMPLETED);
                          }}>
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                alignSelf: 'center',
                                marginVertical: 10,
                              }}>
                              SAVE & MARK COMPLETED
                            </Text>
                          </TouchableOpacity>
                        </View>

                        {
                          (selectedStockTakeEdit === null || selectedStockTakeEdit.status !== STOCK_TAKE_STATUS.COMPLETED)
                            ?
                            <View
                              style={{
                                backgroundColor: "#f73131",
                                width: 200,
                                height: 40,
                                //marginVertical: 7,
                                borderRadius: 5,
                                alignSelf: 'center',
                                //marginLeft: 40
                              }}>
                              <TouchableOpacity onPress={() => {
                                var body = {
                                  stId: selectedStockTakeEdit.uniqueId,
                                };

                                ApiClient.POST(API.deleteStockTake, body).then((result) => {
                                  console.log("result", result)

                                  if (result && result.status === 'success') {
                                    Alert.alert(
                                      'Success',
                                      'The stock take had been canceled',
                                      [{
                                        text: 'OK', onPress: () => {
                                          // setState({
                                          //   lowStockAlert: false,
                                          //   purchaseOrder: false,
                                          //   stockTransfer: false,
                                          //   stockTake: true,
                                          //   addPurchase: false,
                                          //   editPurchase: false,
                                          //   addStockTransfer: false,
                                          //   addStockTake: false,
                                          // })

                                          CommonStore.update(s => {
                                            s.selectedStockTakeEdit = null;
                                          });

                                          setStockTake(true);
                                          setAddStockTake(false);
                                        }
                                      }],
                                      { cancelable: false },
                                    );
                                  }
                                  else {
                                    Alert.alert(
                                      'Error',
                                      'Fail to cancel stock take',
                                      [
                                        { text: "OK", onPress: () => { } }
                                      ],
                                      { cancelable: false },
                                    );
                                  }
                                });


                              }}>
                                <Text
                                  style={{
                                    color: Colors.whiteColor,
                                    alignSelf: 'center',
                                    marginVertical: 10,
                                  }}>
                                  CANCEL STOCK TAKE
                                </Text>
                              </TouchableOpacity>
                            </View>
                            :
                            <></>
                        }
                      </View> */}
                        </View>

                        {selectedStockTakeEdit === null ||
                        selectedStockTakeEdit.status !==
                          STOCK_TAKE_STATUS.COMPLETED ? (
                          <View
                            style={{
                              flexDirection: "column",
                              justifyContent: "flex-end",
                              alignSelf: "flex-end",
                              position: "absolute",
                            }}
                          >
                            <View style={{}}>
                              <TouchableOpacity
                                style={{
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  borderWidth: 1,
                                  borderColor: Colors.primaryColor,
                                  backgroundColor: "#4E9F7D",
                                  borderRadius: 5,
                                  width: switchMerchant ? 120 : 170,
                                  paddingHorizontal: 10,
                                  height: switchMerchant ? 35 : 40,
                                  alignItems: "center",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.22,
                                  shadowRadius: 3.22,
                                  elevation: 1,
                                  zIndex: -1,
                                  marginRight: 10,
                                }}
                                onPress={() => {
                                  createStockTake();
                                }}
                              >
                                <Text
                                  style={{
                                    color: Colors.whiteColor,
                                    //marginLeft: 5,
                                    fontSize: switchMerchant ? 10 : 16,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  SAVE
                                </Text>
                              </TouchableOpacity>
                            </View>

                            <View style={{}}>
                              <TouchableOpacity
                                style={{
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  borderWidth: 1,
                                  borderColor: Colors.primaryColor,
                                  backgroundColor: "#4E9F7D",
                                  borderRadius: 5,
                                  width: switchMerchant ? 120 : 170,
                                  paddingHorizontal: 10,
                                  height: switchMerchant ? 35 : 40,
                                  alignItems: "center",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.22,
                                  shadowRadius: 3.22,
                                  elevation: 1,
                                  zIndex: -1,
                                  marginVertical: 10,
                                }}
                                onPress={() => {
                                  createStockTake(STOCK_TAKE_STATUS.COMPLETED);
                                }}
                              >
                                <Text
                                  style={{
                                    color: Colors.whiteColor,
                                    //marginLeft: 5,
                                    fontSize: switchMerchant ? 10 : 16,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  COMPLETE
                                </Text>
                              </TouchableOpacity>
                            </View>

                            {!addStock ? (
                              <View style={{}}>
                                <TouchableOpacity
                                  style={{
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    borderWidth: 1,
                                    borderColor: Colors.tabRed,
                                    backgroundColor: Colors.tabRed,
                                    borderRadius: 5,
                                    width: switchMerchant ? 120 : 170,
                                    paddingHorizontal: 5,
                                    height: switchMerchant ? 35 : 40,
                                    alignItems: "center",
                                    shadowOffset: {
                                      width: 0,
                                      height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                    zIndex: -1,
                                  }}
                                  onPress={() => {
                                    var body = {
                                      stId: selectedStockTakeEdit.uniqueId,
                                    };

                                    ApiClient.POST(
                                      API.deleteStockTake,
                                      body
                                    ).then((result) => {
                                      console.log("result", result);

                                      if (
                                        result &&
                                        result.status === "success"
                                      ) {
                                        if (
                                          window.confirm(
                                            "Success. The stock take has been canceled"
                                          ) == true
                                        ) {
                                          CommonStore.update((s) => {
                                            s.selectedStockTakeEdit = null;
                                          });

                                          setStockTake(true);
                                          setAddStockTake(false);
                                          setAddStock(false);
                                        } else {
                                          console.log("You canceled!");
                                        }
                                      } else {
                                        if (
                                          window.confirm(
                                            "Error. Failed to cancel Stock take"
                                          ) == true
                                        ) {
                                          console.log("You pressed ok!");
                                        } else {
                                          console.log("You canceled!");
                                        }
                                      }
                                    });
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: Colors.whiteColor,
                                      //marginLeft: 5,
                                      fontSize: switchMerchant ? 10 : 16,
                                      fontFamily: "NunitoSans-Bold",
                                    }}
                                  >
                                    CANCEL
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            ) : (
                              <></>
                            )}
                          </View>
                        ) : (
                          <></>
                        )}
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: 60,
                          justifyContent: "space-between",
                          width: "96%",
                          alignSelf: "center",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            width: switchMerchant ? "53%" : "51%",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Bold",
                              fontSize: switchMerchant ? 10 : 14,
                              width: "29%",
                            }}
                          >
                            ID
                          </Text>
                          <View
                            style={{
                              width: "50%",
                              justifyContent: "space-between",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            {editMode ? (
                              <TextInput
                                editable={true}
                                style={{
                                  backgroundColor: Colors.fieldtBgColor,
                                  width: switchMerchant ? "85%" : 200,
                                  height: 35,
                                  borderRadius: 5,
                                  padding: 5,
                                  //marginVertical: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  paddingLeft: 10,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholder={"Max 12 Character"}
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                maxLength={12}
                                onChangeText={(text) => {
                                  setPoId(text);
                                }}
                              />
                            ) : (
                              <View
                                style={{
                                  borderRadius: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  height: 35,
                                  width: switchMerchant ? "85%" : 200,
                                  justifyContent: "center",
                                  paddingHorizontal: 10,
                                  backgroundColor: Colors.fieldtBgColor,
                                }}
                              >
                                <Text
                                  style={{
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                >
                                  {`${poId}`}
                                </Text>
                              </View>
                            )}
                            {!selectedStockTakeEdit ? (
                              <View style={{ marginLeft: 10 }}>
                                <TouchableOpacity
                                  onPress={() => {
                                    setEditMode(!editMode);
                                  }}
                                >
                                  {/* <MaterialCommunityIcons name="pencil" size={20} color={Colors.primaryColor} /> */}
                                  <Icon
                                    name="edit"
                                    size={switchMerchant ? 15 : 20}
                                    color={Colors.primaryColor}
                                  />
                                </TouchableOpacity>
                              </View>
                            ) : (
                              <></>
                            )}
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            width: "47%",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Bold",
                              fontSize: switchMerchant ? 10 : 14,
                              width: switchMerchant ? "38%" : "40%",
                            }}
                          >
                            Target Supplier
                          </Text>

                          <View
                            style={{
                              width: switchMerchant ? "60%" : 250,
                              height: 30,
                              backgroundColor: Colors.whiteColor,
                              borderRadius: 10,
                              justifyContent: "center",
                              alignSelf: "center",
                              zIndex: 5,
                            }}
                          >
                            {supplierDropdownList.find(
                              (item) =>
                                item.value === selectedSupplierId ||
                                selectedSupplierId === "ALL"
                            ) && (
                              // <RNPickerSelect
                              //   arrowSize={20}
                              //   disabled={selectedStockTakeEdit ? true : false}
                              //   items={supplierDropdownList}
                              //   defaultValue={selectedSupplierId}
                              //   style={{ backgroundColor: '#FAFAFA', paddingVertical: 0, width: 230 }}
                              //   containerStyle={{ height: 35 }}
                              //   itemStyle={{
                              //     justifyContent: 'flex-start', marginLeft: 5,
                              //   }}
                              //   dropDownStyle={{ backgroundColor: '#FAFAFA', width: 230 }}
                              //   onValueChange={(item) =>{
                              //     setSelectedSupplierId(item.value)
                              //   }}
                              // />
                              //   <DropDownPicker
                              //     arrowSize={20}
                              //     arrowStyle={{ fontWeight: "bold" }}
                              //     disabled={selectedStockTakeEdit ? true : false}
                              //     items={[{ label: "All", value: "ALL" }].concat(
                              //       supplierDropdownList
                              //     )}
                              //     defaultValue={selectedSupplierId}
                              //     labelStyle={{
                              //       fontFamily: "NunitoSans-Regular",
                              //       fontSize: switchMerchant ? 10 : 14,
                              //     }}
                              //     itemStyle={{
                              //       justifyContent: "flex-start",
                              //       marginLeft: 5,
                              //       zIndex: 2,
                              //     }}
                              //     //defaultValue={'ALL'}
                              //     style={{
                              //       width: switchMerchant ? "100%" : 250,
                              //       paddingVertical: 0,
                              //       backgroundColor: Colors.fieldtBgColor,
                              //       borderRadius: 10,
                              //     }}
                              //     containerStyle={{ height: 35 }}
                              //     itemStyle={{
                              //       justifyContent: "flex-start",
                              //       marginLeft: 5,
                              //     }}
                              //     dropDownMaxHeight={150}
                              //     dropDownStyle={{
                              //       width: switchMerchant ? "100%" : 250,
                              //       height: 150,
                              //       backgroundColor: Colors.fieldtBgColor,
                              //       borderRadius: 10,
                              //       borderWidth: 1,
                              //       textAlign: "left",
                              //       zIndex: 2,
                              //     }}
                              //     onChangeItem={(item) => {
                              //       setSelectedSupplierId(item.value);
                              //       console.log(
                              //         "selectedSupplierId" + selectedSupplierId
                              //       );
                              //     }}
                              //   />

                              <Picker
                                selectedValue={selectedSupplierId}
                                style={{
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                }}
                                onValueChange={(item) => {
                                  setSelectedSupplierId(item);
                                  console.log(
                                    "selectedSupplierId" + selectedSupplierId
                                  );
                                }}
                              >
                                {[{ label: "All", value: "ALL" }]
                                  .concat(supplierDropdownList)
                                  .map((value, index) => {
                                    return (
                                      <Picker.Item
                                        key={index}
                                        label={value.label}
                                        value={value.value}
                                      />
                                    );
                                  })}
                              </Picker>
                            )}

                            {/* <DropDownPicker
                          containerStyle={{ height: 35, zIndex: 2 }}
                          arrowColor={'black'}
                          arrowSize={20}
                          arrowStyle={{ fontWeight: 'bold' }}
                          labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, }}
                          style={{ width: switchMerchant ? '100%' : 250, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10 }}
                          placeholderStyle={{ color: Colors.fieldtTxtColor }}
                          disabled={selectedStockTakeEdit ? true : false}
                          items={supplierDropdownList}
                          itemStyle={{ justifyContent: 'flex-start', marginLeft: 5, zIndex: 2 }}
                          placeholder={"Product"}
                          customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                          onChangeItem={(item) => {
                            setSelectedSupplierId(item.value)
                          }}
                          defaultValue={'ALL'}
                          dropDownMaxHeight={150}
                          dropDownStyle={{ width: switchMerchant ? '100%' : 250, height: 40, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1, textAlign: 'left', zIndex: 2 }}
                        // arrowSize={20}
                        // disabled={selectedStockTakeEdit ? true : false}
                        // items={supplierDropdownList}
                        // // defaultValue={selectedSupplierId}
                        // defaultValue={'ALL'}
                        // style={{ backgroundColor: '#FAFAFA', paddingVertical: 0, width: 230 }}
                        // containerStyle={{ height: 35 }}
                        // itemStyle={{
                        //   justifyContent: 'flex-start', marginLeft: 5,
                        // }}
                        // dropDownStyle={{ backgroundColor: '#FAFAFA', width: 230 }}
                        // onChangeItem={(item) => {
                        //   setSelectedSupplierId(item.value)
                        // }}
                        /> */}
                          </View>
                        </View>
                      </View>

                      {/* <View style={{ flexDirection: "row", marginTop: 50, justifyContent: 'space-between', width: '90%', alignSelf: 'center' }}>
                    <View style={{ flexDirection: "row", width: switchMerchant ? '53%' : '51%', alignItems: 'center' }}>
                      <Text style={{ fontSize: 16, width: '29%' }}>Target Store</Text>

                      <View style={{ width: '71%', }}>
                        <DropDownPicker
                          disabled={selectedStockTakeEdit ? true : false}
                          items={targetOutletDropdownList}
                          defaultValue={selectedTargetOutletId}
                          arrowSize={20}
                          containerStyle={{ height: 35 }}
                          style={{ backgroundColor: '#FAFAFA', paddingVertical: 0, width: 230 }}
                          itemStyle={{
                            justifyContent: 'flex-start', marginLeft: 5,
                          }}
                          dropDownStyle={{ backgroundColor: '#FAFAFA', width: 230 }}
                          onChangeItem={(item) =>
                            // setState({
                            //   choice7: item.value,
                            // })
                            // setSelectedSupplierId(item.value)
                            setSelectedTargetOutletId(item.value)
                          }
                        />
                      </View>
                    </View>
                  </View> */}

                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: 10,
                          justifyContent: "space-between",
                          width: "96%",
                          alignSelf: "center",
                          marginTop: 45,
                          zIndex: -1,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            width: switchMerchant ? "53%" : "51%",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Bold",
                              fontSize: switchMerchant ? 10 : 14,
                              width: "29%",
                            }}
                          >
                            Created Date
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              width: switchMerchant ? "50%" : "71%",
                              marginTop: 0,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                backgroundColor: Colors.fieldtBgColor,
                                width: switchMerchant ? "85%" : 200,
                                height: 35,
                                borderRadius: 5,
                                padding: 5,
                                //marginVertical: 5,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                paddingLeft: 10,
                                alignItems: "center",
                                //justifyContent: 'space-between'
                              }}
                            >
                              <GCalendarGrey
                                height={switchMerchant ? 15 : 20}
                                width={switchMerchant ? 15 : 20}
                              />
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                  marginLeft: 15,
                                }}
                              >
                                {moment(date).format("DD MMM YYYY")}
                                {/* {moment(startDate).format('HH:mm')} */}
                              </Text>
                              {/* <Text style={{ color: 'black', marginLeft: 10, fontSize: 16 }}>{moment(startDate).format('HH:mm')}</Text> */}
                            </View>
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "47%",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Bold",
                              fontSize: switchMerchant ? 10 : 14,
                              width: switchMerchant ? "38%" : "40%",
                            }}
                          >
                            Completed Date
                          </Text>
                          <View style={{ width: "60%", flexDirection: "row" }}>
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              {selectedStockTakeEdit === null ||
                              selectedStockTakeEdit.status !==
                                STOCK_TAKE_STATUS.COMPLETED
                                ? "Ongoing"
                                : moment(completedDate).format("DD MMM YYYY")}
                            </Text>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginTop: 45,
                          marginBottom: 40,
                          zIndex: -1,
                          width: "96%",
                          alignSelf: "center",
                          alignItem: "center",
                        }}
                      >
                        <View
                          style={{
                            width: switchMerchant ? "53%" : "51%",
                            justifyContent: "center",
                          }}
                        >
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                                width: "29%",
                              }}
                            >
                              Summary
                            </Text>
                            <View style={{ width: "71%" }}>
                              {/* <View style={{ flexDirection: "row", }}>
                            <Text style={{ fontSize: 15, width: '62%' }}>Total quantity difference:</Text>
                            <Text style={{
                              color: diffQuantityTotal < 0 ? '#f71616' : (diffQuantityTotal > 0 ? Colors.primaryColor : Colors.blackColor),
                              fontSize: 16,
                              width: '38%'
                            }}>
                              {diffQuantityTotal}
                            </Text>
                          </View> */}

                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={{
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: switchMerchant ? 10 : 14,
                                    width: "62%",
                                  }}
                                >
                                  Total Cost Difference:
                                </Text>
                                <Text
                                  style={{
                                    color:
                                      diffQuantityTotal < 0
                                        ? "#f71616"
                                        : diffQuantityTotal > 0
                                        ? Colors.primaryColor
                                        : Colors.blackColor,
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                    width: "38%",
                                  }}
                                >
                                  RM
                                  {diffCostTotal
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                                </Text>
                              </View>
                            </View>
                          </View>
                          {/* <View style={{ flexDirection: "row" }}>
                        <Text style={{ fontSize: 16 }}>
                          Total cost difference:
                        </Text>
                        <Text style={{
                          color: diffQuantityTotal < 0 ? '#f71616' : (diffQuantityTotal > 0 ? Colors.primaryColor : Colors.blackColor),
                          fontSize: 16
                        }}>
                          RM{diffCostTotal.toFixed(2)}
                        </Text>
                      </View> */}
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            width: "47%",
                            alignItems: "center",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Bold",
                              fontSize: switchMerchant ? 10 : 14,
                              width: switchMerchant ? "38%" : "40%",
                            }}
                          >
                            Current Status
                          </Text>
                          <View
                            style={{
                              width: switchMerchant ? "60%" : 250,
                              alignItems: "center",
                              backgroundColor: Colors.secondaryColor,
                              borderRadius: 10,
                              paddingVertical: 10,
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              {selectedStockTakeEdit === null ||
                              selectedStockTakeEdit.status !==
                                STOCK_TAKE_STATUS.COMPLETED
                                ? "Created"
                                : "Completed"}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        backgroundColor: "#ffffff",
                        flexDirection: "row",
                        paddingVertical: 20,
                        paddingHorizontal: 20,
                        marginTop: 10,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                        //backgroundColor: 'red'
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 10 : 14,
                          width: "12%",
                          alignSelf: "center",
                          marginRight: 2,
                        }}
                      >
                        Product Name
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 10 : 14,
                          width: "11%",
                          alignSelf: "center",
                          marginHorizontal: 2,
                        }}
                      >
                        SKU
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 10 : 14,
                          width: "11%",
                          alignSelf: "center",
                          marginHorizontal: 2,
                        }}
                      >
                        Cost
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 10 : 14,
                          width: "12%",
                          alignSelf: "center",
                          marginHorizontal: 2,
                        }}
                      >
                        Unit
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 10 : 14,
                          width: "11%",
                          alignSelf: "center",
                          marginHorizontal: 2,
                        }}
                      >
                        In Stock
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 10 : 14,
                          width: "13%",
                          alignSelf: "center",
                          marginHorizontal: 2,
                        }}
                      >
                        Counted
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 10 : 14,
                          width: "13%",
                          alignSelf: "center",
                          marginHorizontal: 2,
                        }}
                      >
                        Balance(Qty)
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 10 : 14,
                          width: "14%",
                          alignSelf: "center",
                          marginLeft: 2,
                        }}
                      >
                        Balance(RM)
                      </Text>
                      {/* <Text style={{ width: '12%', alignSelf: 'center', marginLeft: 2 }}>
                    Remarks
                  </Text> */}
                      {/* <Text style={{ width: '12%', alignSelf: 'center' }}>Action</Text> */}
                    </View>

                    {poItems.length > 0 ? (
                      <FlatList
                        nestedScrollEnabled={true}
                        showsVerticalScrollIndicator={false}
                        data={poItems}
                        extraData={poItems}
                        renderItem={renderAddCountedStockTake}
                        keyExtractor={(item, index) => String(index)}
                      />
                    ) : (
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          height: "17.5%",
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.descriptionColor,
                            fontFamily: "NunitoSans-Regular",
                            fontSize: switchMerchant ? 10 : 14,
                          }}
                        >
                          N/A
                        </Text>
                      </View>
                    )}

                    {poItems.length > 0 ? (
                      <View
                        style={{
                          marginTop: 30,
                          width: "99.6%",
                          marginLeft: "0.2%",
                        }}
                      >
                        <View>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Bold",
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                          >
                            Stock Take Summary
                          </Text>
                        </View>

                        <View style={{ marginTop: 10 }}>
                          <TextInput
                            underlineColorAndroid={Colors.fieldtBgColor}
                            style={{
                              padding: 5,
                              backgroundColor: Colors.fieldtBgColor,
                              height: 120,
                              borderRadius: 5,
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                              marginVertical: 5,
                              paddingTop: Platform.OS == "ios" ? 10 : 10,
                              paddingLeft: 10,
                              textAlignVertical: "top",
                              fontFamily: "NunitoSans-Regular",
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            // placeholder="Counted Items description here"
                            placeholder="Remarks"
                            placeholderStyle={{
                              fontFamily: "NunitoSans-Regular",
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            placeholderTextColor={Platform.select({
                              ios: "#a9a9a9",
                            })}
                            onChangeText={(text) => {
                              // setState({ remark: text });
                              setRemark(text);
                            }}
                            value={remark}
                            multiline={true}
                          />
                        </View>
                      </View>
                    ) : (
                      <View
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          height: "17.5%",
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.descriptionColor,
                            fontFamily: "NunitoSans-Regular",
                            fontSize: switchMerchant ? 10 : 14,
                          }}
                        >
                          N/A
                        </Text>
                      </View>
                    )}

                    {/* <View style={{ marginTop: 50, flexDirection: "row" }}>
                  <Text style={{ fontSize: 25, fontWeight: 'bold', marginLeft: 280 }}>Uncounted Items</Text>
                  <View style={{ marginLeft: 100 }}>
                    <TextInput
                      editable={!loading}
                      underlineColorAndroid={Colors.whiteColor}
                      clearButtonMode="while-editing"
                      style={styles.textInput1}
                      placeholder=" 🔍  search"
                      onChangeText={(text) => {
                        setState({ search3: text.trim() });
                      }}
                      value={email}
                    />
                  </View>
                </View> */}

                    {/* <View
                  style={{
                    backgroundColor: '#ffffff',
                    flexDirection: 'row',
                    paddingVertical: 20,
                    paddingHorizontal: 20,
                    marginTop: 10,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                  }}>
                  <Text style={{ width: '16%', alignSelf: 'center' }}>Product name</Text>
                  <Text style={{ width: '16%', alignSelf: 'center' }}>SKU</Text>
                  <Text style={{ width: '16%', alignSelf: 'center' }}>Barcode</Text>
                  <Text style={{ width: '16%', alignSelf: 'center' }}>Expected qty</Text>
                  <Text style={{ width: '20%', alignSelf: 'center' }}>Counted qty</Text>
                  <Text style={{ width: '16%', alignSelf: 'center' }}>Action</Text>
                </View>
                <FlatList
                  data={addUnCountedStockTakeList}
                  extraData={addUnCountedStockTakeList}
                  renderItem={renderAddUncountedStockTake}
                  keyExtractor={(item, index) => String(index)}
                /> */}

                    {/* <View style={{ marginTop: 40 }}>
                  <View >
                    <Text style={{ width: '12%', fontSize: 16 }}>Description</Text>
                  </View>

                  <View style={{ marginTop: 10 }}>
                    <TextInput
                      underlineColorAndroid={Colors.fieldtBgColor}
                      style={[styles.textFieldInput]}
                      placeholder="Uncounted Items description here"
                      onChangeText={(text) => {
                        setState({ remark: text });
                      }}
                      value={''}
                      multiline={true}
                    />
                  </View>

                </View> */}

                    {!selectedStockTakeEdit ||
                    (selectedStockTakeEdit &&
                      selectedStockTakeEdit.status !==
                        STOCK_TAKE_STATUS.COMPLETED) ? (
                      <View
                        style={{
                          flexDirection: "row",
                          alignSelf: "center",
                          justifyContent: "space-evenly",
                          marginTop: 20,
                        }}
                      >
                        {/* <View
                    style={{
                      backgroundColor: Colors.primaryColor,
                      width: 200,
                      height: 40,
                      marginVertical: 15,
                      borderRadius: 5,
                      alignSelf: 'center',
                    }}>
                    <TouchableOpacity onPress={() => {
                      Alert.alert(
                        'Success',
                        'The stock take had been saved',
                        [{
                          text: 'OK', onPress: () => {
                            setState({
                              lowStockAlert: false,
                              purchaseOrder: false,
                              stockTransfer: false,
                              stockTake: true,
                              addPurchase: false,
                              editPurchase: false,
                              addStockTransfer: false,
                              addStockTake: false,
                            })
                          }
                        }],
                        { cancelable: false },
                      );
                    }}>
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          alignSelf: 'center',
                          marginVertical: 10,
                        }}>
                        SAVE
                      </Text>
                    </TouchableOpacity>
                  </View> */}

                        {/* <View
                      style={{
                        backgroundColor: Colors.primaryColor,
                        width: 200,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: 'center',
                        marginLeft: 40
                      }}>
                      <TouchableOpacity onPress={() => {
                        createStockTake();
                      }}>
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            alignSelf: 'center',
                            marginVertical: 10,
                          }}>
                          SAVE
                      </Text>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        backgroundColor: Colors.primaryColor,
                        width: 200,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: 'center',
                        marginLeft: 40
                      }}>
                      <TouchableOpacity onPress={() => {
                        createStockTake(STOCK_TAKE_STATUS.COMPLETED);
                      }}>
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            alignSelf: 'center',
                            marginVertical: 10,
                          }}>
                          SAVE & MARK COMPLETED
                      </Text>
                      </TouchableOpacity>
                    </View>

                    <View
                      style={{
                        backgroundColor: "#f73131",
                        width: 200,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: 'center',
                        marginLeft: 40
                      }}>
                      <TouchableOpacity onPress={() => {
                        Alert.alert(
                          'Canceled',
                          'The stock take had been canceled',
                          [{
                            text: 'OK', onPress: () => {
                              // setState({
                              //   lowStockAlert: false,
                              //   purchaseOrder: false,
                              //   stockTransfer: false,
                              //   stockTake: true,
                              //   addPurchase: false,
                              //   editPurchase: false,
                              //   addStockTransfer: false,
                              //   addStockTake: false,
                              // })

                              CommonStore.update(s => {
                                s.selectedStockTakeEdit = null;
                              });

                              setStockTake(true);
                              setAddStockTake(false);
                            }
                          }],
                          { cancelable: false },
                        );
                      }}>
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            alignSelf: 'center',
                            marginVertical: 10,
                          }}>
                          CANCEL STOCK TAKE
                      </Text>
                      </TouchableOpacity>
                    </View> */}
                        <Modal
                          supportedOrientations={["portrait", "landscape"]}
                          style={{ flex: 1 }}
                          visible={modal}
                          transparent={true}
                          animationType="slide"
                        >
                          <View
                            style={{
                              backgroundColor: "rgba(0,0,0,0.5)",
                              flex: 1,
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: Dimensions.get("window").height,
                            }}
                          >
                            <View style={styles.confirmBox}>
                              <TouchableOpacity
                                onPress={() => {
                                  setState({ modal: false });
                                }}
                              >
                                <View
                                  style={{
                                    alignSelf: "flex-end",
                                    padding: 16,
                                  }}
                                >
                                  {/* <Close name="closecircle" size={25} /> */}
                                  <AntDesign
                                    name="closecircle"
                                    size={25}
                                    color={Colors.fieldtTxtColor}
                                  />
                                </View>
                              </TouchableOpacity>
                              <View>
                                <Text
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "700",
                                    fontSize: 28,
                                  }}
                                >
                                  Uncounted Items
                                </Text>
                              </View>
                              <View style={{ marginTop: 20 }}>
                                <Text
                                  style={{
                                    textAlign: "center",
                                    color: Colors.descriptionColor,
                                    fontSize: 25,
                                  }}
                                >
                                  Select the action for uncounted items
                                </Text>
                              </View>
                              <View
                                style={{
                                  backgroundColor: "white",
                                  marginLeft: 55,
                                  flexDirection: "row",
                                  marginTop: 30,
                                }}
                              >
                                <input
                                  defaultValue={isSelected3}
                                  onChange={(isSelected3) =>
                                    setState({
                                      isSelected3,
                                      isSelected4: false,
                                      choose: 1,
                                    })
                                  }
                                  style={
                                    {
                                      // alignSelf: "center",
                                      // borderRadius: 15,
                                      // paddingBottom: 3,
                                    }
                                  }
                                  type={"checkbox"}
                                  //   checked={allOutlets.length === selectedOutletList.length}
                                />
                                {/* <CheckBox
                                  value={isSelected3}
                                  onValueChange={(isSelected3) =>
                                    setState({
                                      isSelected3,
                                      isSelected4: false,
                                      choose: 1,
                                    })
                                  }
                                /> */}
                                <Text
                                  style={{
                                    marginLeft: 10,
                                    color: Colors.primaryColor,
                                    fontSize: 18,
                                  }}
                                >
                                  Keep the expected quantity
                                </Text>
                              </View>
                              <View
                                style={{
                                  backgroundColor: "white",
                                  marginLeft: 55,
                                  flexDirection: "row",
                                  marginTop: 20,
                                }}
                              >
                                <input
                                  defaultValue={isSelected4}
                                  onChange={(isSelected4) =>
                                    setState({
                                      isSelected4,
                                      isSelected3: false,
                                      choose: 2,
                                    })
                                  }
                                  style={
                                    {
                                      // alignSelf: "center",
                                      // borderRadius: 15,
                                      // paddingBottom: 3,
                                    }
                                  }
                                  type={"checkbox"}
                                  //   checked={allOutlets.length === selectedOutletList.length}
                                />
                                {/* <CheckBox
                                  value={isSelected4}
                                  onValueChange={(isSelected4) =>
                                    setState({
                                      isSelected4,
                                      isSelected3: false,
                                      choose: 2,
                                    })
                                  }
                                /> */}
                                <Text style={{ marginLeft: 10, fontSize: 18 }}>
                                  Reduce quantity to 0
                                </Text>
                              </View>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignSelf: "center",
                                  marginTop: 20,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "50%",
                                  alignContent: "center",
                                  zIndex: 6000,
                                }}
                              ></View>
                              <View
                                style={{
                                  alignSelf: "center",
                                  marginTop: 20,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: 250,
                                  height: 40,
                                  alignContent: "center",
                                  flexDirection: "row",
                                  marginTop: 40,
                                }}
                              >
                                <TouchableOpacity
                                  onPress={() => {
                                    if (choose == 1) {
                                      CheckStockOrder();
                                    } else if (choose == 2) {
                                      CheckStockOrderDelete();
                                    }
                                    setState({ modal: false });
                                  }}
                                  style={{
                                    backgroundColor: Colors.fieldtBgColor,
                                    width: "60%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    borderRadius: 10,
                                    height: 60,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 28,
                                      color: Colors.primaryColor,
                                    }}
                                  >
                                    Continue
                                  </Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                  onPress={() => {
                                    setState({ modal: false });
                                  }}
                                  style={{
                                    backgroundColor: Colors.fieldtBgColor,
                                    width: "60%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    borderRadius: 10,
                                    height: 60,
                                    marginLeft: 30,
                                  }}
                                >
                                  <Text
                                    style={{ fontSize: 28, color: "#cccccc" }}
                                  >
                                    Cancel
                                  </Text>
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </Modal>
                      </View>
                    ) : (
                      <></>
                    )}

                    <View
                      style={{
                        height: 30,
                      }}
                    ></View>
                    {/* <View
                  style={{
                    flexDirection: 'row',
                    backgroundColor: '#ffffff',
                    justifyContent: 'center',
                    padding: 18,
                  }}>
                  <View style={{ alignItems: 'center' }}>
                    <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
                      {stockTakes.length}
                    </Text>
                    <Text>STOCK TAKES</Text>
                  </View>
                </View> */}
                  </ScrollView>
                </View>
                {/* </ScrollView> */}
              </View>
            </View>
          ) : null}
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    paddingTop: 10,
    paddingLeft: 11,
    paddingRight: 11,
    width: Dimensions.get("screen").width * (1 - Styles.sideBarWidth),
  },
  submitText: {
    height:
      Platform.OS == "ios"
        ? Dimensions.get("screen").height * 0.06
        : Dimensions.get("screen").height * 0.05,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  submitText1: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    alignSelf: "flex-end",
    marginRight: 20,
    // marginTop: 15
    height: 40,
    width: 195,
  },
  submitText2: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    alignSelf: "flex-end",
    marginRight: 20,
    marginTop: 15,
  },
  /* textInput: {
    width: 300,
    height: '10%',
    padding: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 20,
  }, */

  textInput: {
    width: 200,
    height: 40,
    // padding:5,
    backgroundColor: Colors.whiteColor,
    borderRadius: 0,
    marginRight: "35%",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "flex-end",

    shadowOffset:
      Platform.OS == "ios"
        ? {
            width: 0,
            height: 0,
          }
        : {
            width: 0,
            height: 7,
          },
    shadowOpacity: Platform.OS == "ios" ? 0 : 0.43,
    shadowRadius: Platform.OS == "ios" ? 0 : 0.51,
    elevation: 15,
  },
  searchIcon: {
    backgroundColor: Colors.whiteColor,
    height: 40,
    padding: 10,
    shadowOffset:
      Platform.OS == "ios"
        ? {
            width: 0,
            height: 0,
          }
        : {
            width: 0,
            height: 7,
          },
    shadowOpacity: Platform.OS == "ios" ? 0 : 0.43,
    shadowRadius: Platform.OS == "ios" ? 0 : 9.51,

    elevation: 15,
  },
  textInput1: {
    width: 160,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 5,
  },
  textInput2: {
    width: 100,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 5,
    textAlign: "center",
  },
  confirmBox: {
    width: 450,
    height: 450,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
  },
  textFieldInput: {
    height: 120,
    //width: 850,
    paddingHorizontal: 10,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
  },
  RemarkInput: {
    height: 110,
    width: 300,
    paddingHorizontal: 10,
    marginVertical: 10,
    //backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  headerLeftStyle: {
    width: Dimensions.get("screen").width * 0.17,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    height: Dimensions.get("screen").width * 0.25,
    width: Dimensions.get("screen").width * 0.35,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get("screen").width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView1: {
    //height: Dimensions.get('screen').width * 0.2,
    width: Dimensions.get("screen").width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get("screen").width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  modalViewImport: {
    height: Dimensions.get("screen").width * 0.6,
    width: Dimensions.get("screen").width * 0.6,
    backgroundColor: Colors.whiteColor,
    borderRadius: Dimensions.get("screen").width * 0.03,
    padding: 20,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  closeButton: {
    position: "absolute",
    right: Dimensions.get("screen").width * 0.02,
    top: Dimensions.get("screen").width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: "center",
    //top: '20%',
    //position: 'absolute',
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitleText: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    width: Dimensions.get("screen").width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
});
export default StockTakeScreen;
