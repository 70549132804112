import React, {Component, useReducer, useState, useEffect} from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Modal,
  Dimensions,
  TextInput,
  FlatList,
  KeyboardAvoidingView,
  ActivityIndicator,
  useWindowDimensions,
  Picker
} from 'react-native';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import SideBar from './SideBar';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Ionicons';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
// import DropDownPicker from 'react-native-dropdown-picker';
import Select from 'react-select';
import Multiselect from 'multiselect-react-dropdown'
import Feather from 'react-native-vector-icons/Feather';
import {ReactComponent as GCalendar} from '../assets/svg/GCalendar.svg';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import moment from 'moment';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
import TimeKeeper from "react-timekeeper";
import { ReactComponent as Clock } from '../assets/svg/Clock.svg'
// import {isTablet} from 'react-native-device-detection';
import {
  MERCHANT_VOUCHER_CODE_FORMAT,
  MERCHANT_VOUCHER_TYPE,
  SEGMENT_TYPE,
} from '../constant/common';
import {CommonStore} from '../store/commonStore';
import {UserStore} from '../store/userStore';
import {MerchantStore} from '../store/merchantStore';
import {OutletStore} from '../store/outletStore';
// import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
// import RNPickerSelect from 'react-native-picker-select';
// import {useKeyboard} from '../hooks';
// import {get} from 'react-native/Libraries/Utilities/PixelRatio';
import AsyncImage from '../components/asyncImage';
import Entypo from 'react-native-vector-icons/Entypo';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  PROMOTION_TYPE,
  PROMOTION_TYPE_VARIATION,
  PROMOTION_TYPE_VARIATION_DROPDOWN_LIST,
  CRM_SEGMENT_DROPDOWN_LIST,
} from '../constant/promotions';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Icon1 from 'react-native-vector-icons/Feather';
import Icon2 from 'react-native-vector-icons/FontAwesome';
import Ionicon from 'react-native-vector-icons/Ionicons';
import {Platform} from 'react-native';
import { useLinkTo, useRoute } from "@react-navigation/native";

/////////////////////////////////////////////////////////////////////////////////////

const ADD_LOYALTY_STAMP_SCREEN = {
  LOYALTY_STAMP_LIST: 'ADD_LOYALTY_STAMP_SCREEN.LOYALTY_STAMP_LIST',
  ADD_LOYALTY_STAMP: 'ADD_LOYALTY_STAMP_SCREEN.ADD_LOYALTY_STAMP',
};

const LoyaltyStampScreen = (props) => {
  const {navigation} = props;
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const linkTo = useLinkTo();
  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  // const [keyboardHeight] = useKeyboard();
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [expandThreeDots, setExpandThreeDots] = useState({}); //Use to expand the view when three dots are tapped
  const [threeDotsTapped, setThreeDotsTapped] = useState(false); //when the three dots are tapped will become (true)

  const userName = UserStore.useState((s) => s.name);
  const merchantId = UserStore.useState((s) => s.merchantId);
  const merchantName = MerchantStore.useState((s) => s.name);
  const merchantLogo = MerchantStore.useState((s) => s.logo);
  const pointsRedeemPackages = OutletStore.useState(
    (s) => s.pointsRedeemPackages,
  );
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const currOutlet = MerchantStore.useState((s) => s.currOutlet);
  const outletItemsUnsorted = OutletStore.useState((s) => s.outletItems);
  const outletCategoriesUnsorted = OutletStore.useState(
    (s) => s.outletCategories,
  );
  const isLoading = CommonStore.useState((s) => s.isLoading);
  const allOutletsCategoriesUnique = OutletStore.useState(
    (s) => s.allOutletsCategoriesUnique,
  );

  const allOutletsItemsSkuDict = OutletStore.useState(
    (s) => s.allOutletsItemsSkuDict,
  );

  /////////ADD LOYALTY STAMP SCREEN/ LOYALTY STAMP LIST////////////////////////
  const [outletItems, setOutletItems] = useState([]);
  const [variationItemsDropdownList, setVariationItemsDropdownList] = useState(
    [],
  );

  const [addLoyaltyStamp, setAddLoyaltyStamp] = useState(
    ADD_LOYALTY_STAMP_SCREEN.LOYALTY_STAMP_LIST,
  );
  const [stampItemsModal, setStampItemsModal] = useState(false);
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [rev_PickDate, setRev_PickDate] = useState(
    moment(Date.now())
  );
  const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
  const [rev_PickTime, setRev_PickTime] = useState(
    moment(Date.now())
  );
  const [stampName, setStampName] = useState('');
  const [pickDate, setPickDate] = useState('');
  const [pickTime, setPickTime] = useState('');
  const [startDate, setStartDate] = useState(moment().valueOf());
  const [endDate, setEndDate] = useState(moment().valueOf());
  const [startTime, setStartTime] = useState(moment().valueOf());
  const [endTime, setEndTime] = useState(moment().valueOf());
  const [terms, setTerms] = useState('');

  // const [stmpItems, setStmpItems] = useState([]);
  const [targetUsers, setTargetUsers] = useState([
    CRM_SEGMENT_DROPDOWN_LIST[0].value,
  ]);
  const [totalNumberOfStamp, setTotalNumberOfStamp] = useState('0');
  const [numberOfStamp, setNumberOfStamp] = useState('0');
  const [ordinals, setOrdinals] = useState('');
  const [quantityGet, setQuantityGet] = useState('0');
  const [selectedVariationItems, setSelectedVariationItems] = useState([
    {
      noOfStamp: '',
      quantity: 0,
      productName: '',
    },
  ]);

  const [selectedVariation, setSelectedVariation] = useState(
    PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
  );
  const [crmSegmentsDropdownList, setCrmSegmentsDropdownList] = useState([]);
  const crmSegments = OutletStore.useState((s) => s.crmSegments);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView,
  );
  const [loading, setLoading] = useState(false);

  const [pId, setPId] = useState('');
  // const [pItems, setPItems] = useState([
  //   {
  //     pItemId: '',
  //     outletItemSku: '',
  //     variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
  //     isActive: true,
  //     noOfStamp: '0',
  //     quantity: '0',
  //     // productName: '',
  //     tc: '',

  //     itemName: '',
  //     itemDescription: '',
  //     itemImage: '',
  //   }
  // ]);

  const [lsId, setLsId] = useState('');

  const [lsItemsBuy, setLsItemsBuy] = useState([
    {
      lsItemId: '',
      outletItemSku: '',
      variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
      isActive: true,
      noOfStamp: '0',
      quantity: '0',
      // productName: '',
      tc: '',

      itemName: '',
      itemDescription: '',
      itemImage: '',
    },
  ]);

  const [lsItems, setLsItems] = useState([
    {
      lsItemId: '',
      outletItemSku: '',
      variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
      isActive: true,
      noOfStamp: '0',
      quantity: '0',
      // productName: '',
      // tc: '',

      itemName: '',
      itemDescription: '',
      itemImage: '',
    },
  ]);

  const selectedLoyaltyStampEdit = CommonStore.useState(
    (s) => s.selectedLoyaltyStampEdit,
  );

  const loyaltyStamps = OutletStore.useState((s) => s.loyaltyStamps);

  const [variationItemsProducts, setVariationItemsProducts] = useState([]);
  const [variationItemsCategories, setVariationItemsCategories] = useState([]);

  /////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    // setVariationItemsProducts(outletItems.map(item => ({ label: item.name, value: item.uniqueId })));
    // setVariationItemsCategories(allOutletsCategoriesUnique.map(item => ({ label: item.name, value: item.uniqueId })));

    // setVariationItemsProducts(outletItems.map(item => ({ label: item.name, value: item.uniqueId, name: item.name, description: item.description, image: item.image })));
    setVariationItemsProducts(
      outletItems.map((item) => ({
        label: item.name,
        value: item.sku,
        name: item.name,
        description: item.description,
        image: item.image,
      })),
    );
    setVariationItemsCategories(
      allOutletsCategoriesUnique.map((item) => ({
        label: item.name,
        value: item.uniqueId,
        name: item.name,
        description: '',
        image: '',
      })),
    );
  }, [
    outletItems,
    // outletCategories,
    allOutletsCategoriesUnique,

    selectedLoyaltyStampEdit,
  ]);

  // useEffect(() => {
  //   setVariationItemsDropdownList(outletItems.map(item => ({ label: item.name, value: item.sku, name: item.name, description: item.description, image: item.image })));

  // }, [
  //   outletItems,
  // ]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    setCrmSegmentsDropdownList(
      crmSegments.map((segment) => ({
        label: segment.name,
        value: segment.uniqueId,
      })),
    );
  }, [crmSegments]);

  useEffect(() => {
    var outletItemsTemp = [...outletItemsUnsorted];

    outletItemsTemp.sort((a, b) => a.name.localeCompare(b.name));

    setOutletItems(outletItemsTemp);
  }, [outletItemsUnsorted]);

  useEffect(() => {
    if (variationItemsProducts.length > 0) {
      if (
        lsItems.length > 0 &&
        !lsItems[0].lsItemId &&
        !lsItems[0].outletItemSku
      ) {
        setLsItems([
          {
            lsItemId: '',
            outletItemSku: variationItemsProducts[0].value,
            variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
            isActive: true,
            noOfStamp: '0',
            quantity: '0',

            itemName: variationItemsProducts[0].name,
            itemDescription: variationItemsProducts[0].description,
            itemImage: variationItemsProducts[0].image,
          },
        ]);
      }

      if (
        lsItemsBuy.length > 0 &&
        !lsItemsBuy[0].lsItemId &&
        !lsItemsBuy[0].outletItemSku
      ) {
        setLsItemsBuy([
          {
            lsItemId: '',
            outletItemSku: variationItemsProducts[0].value,
            variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
            isActive: true,
            noOfStamp: '0',
            quantity: '0',

            itemName: variationItemsProducts[0].name,
            itemDescription: variationItemsProducts[0].description,
            itemImage: variationItemsProducts[0].image,
          },
        ]);
      }
    }
  }, [
    variationItemsProducts,
    // lsItems,
  ]);

  useEffect(() => {
    console.log('================================');
    console.log('selectedLoyaltyStampEdit');
    console.log(selectedLoyaltyStampEdit);

    if (selectedLoyaltyStampEdit) {
      // insert info

      setLsId(selectedLoyaltyStampEdit.uniqueId);
      setLsItems(
        selectedLoyaltyStampEdit.lsItems.map((item) => ({
          lsItemId: item.lsItemId,
          outletItemSku: item.outletItemSku,
          variation: item.variation,
          isActive: item.isActive,
          noOfStamp: item.noOfStamp.toString(),
          quantity: item.quantity.toString(),

          itemName: item.itemName,
          itemDescription: item.itemDescription,
          itemImage: item.itemImage,
        })),
      );
      setLsItemsBuy(
        selectedLoyaltyStampEdit.lsItemsBuy.map((item) => ({
          lsItemId: item.lsItemId,
          outletItemSku: item.outletItemSku,
          variation: item.variation,
          isActive: item.isActive,
          noOfStamp: item.noOfStamp.toString(),
          quantity: item.quantity.toString(),

          itemName: item.itemName,
          itemDescription: item.itemDescription,
          itemImage: item.itemImage,
        })),
      );
      // setBdOptions(selectedLoyaltyStampEdit.bdOptions);
      // setDocketPrice(selectedLoyaltyStampEdit.price.toFixed(2));

      setStampName(selectedLoyaltyStampEdit.name);
      // setSelectedTargetSegmentGroupList([CRM_SEGMENT_DROPDOWN_LIST[0].value]);
      setTotalNumberOfStamp(
        selectedLoyaltyStampEdit.totalNumberOfStamp.toString(),
      );
      setStartDate(selectedLoyaltyStampEdit.startDate);
      setEndDate(selectedLoyaltyStampEdit.endDate);
      setStartTime(selectedLoyaltyStampEdit.startTime);
      setEndTime(selectedLoyaltyStampEdit.endTime);
      setTerms(selectedLoyaltyStampEdit.terms);

      setTargetUsers(
        selectedLoyaltyStampEdit.targetSegments || [
          CRM_SEGMENT_DROPDOWN_LIST[0].value,
        ],
      );

      // setPurchaseDate(selectedLoyaltyStampEdit.purchaseDate ? selectedLoyaltyStampEdit.purchaseDate : moment().valueOf());
      // setPurchaseEndDate(selectedLoyaltyStampEdit.purchaseEndDate ? selectedLoyaltyStampEdit.purchaseEndDate : moment().valueOf());

      // setPurchaseTime(selectedLoyaltyStampEdit.purchaseTime ? selectedLoyaltyStampEdit.purchaseTime : moment().valueOf());
      // setPurchaseEndTime(selectedLoyaltyStampEdit.purchaseEndTime ? selectedLoyaltyStampEdit.purchaseEndTime : moment().valueOf());

      // setRedeemDate(selectedLoyaltyStampEdit.redeemDate ? selectedLoyaltyStampEdit.redeemDate : moment().valueOf());
      // setRedeemEndDate(selectedLoyaltyStampEdit.redeemEndDate ? selectedLoyaltyStampEdit.redeemEndDate : moment().valueOf());

      // setRedeemTime(selectedLoyaltyStampEdit.redeemTime ? selectedLoyaltyStampEdit.redeemTime : moment().valueOf());
      // setRedeemEndTime(selectedLoyaltyStampEdit.redeemEndTime ? selectedLoyaltyStampEdit.redeemEndTime : moment().valueOf());

      // setExpiredDate(selectedLoyaltyStampEdit.expiredDate ? selectedLoyaltyStampEdit.expiredDate : moment().valueOf());
      // setExpiredTime(selectedLoyaltyStampEdit.expiredTime ? selectedLoyaltyStampEdit.expiredTime : moment().valueOf());

      // setTermsConditions(selectedLoyaltyStampEdit.tnc);
      // setSelectedOutletList(selectedLoyaltyStampEdit.bdOutlets);

      // setImage(selectedLoyaltyStampEdit.image ? selectedLoyaltyStampEdit.image : '');
      // setIsImageChanged(false);

      // setChargesOfExtensionDateFormatType(selectedLoyaltyStampEdit.chargesOfExtensionDateFormatType ? selectedLoyaltyStampEdit.chargesOfExtensionDateFormatType : DATE_FORMAT_DROPDOWN_LIST[0].value);

      // setRedemptionQuantityLimitValue(selectedLoyaltyStampEdit.redemptionQuantityLimitValue ? selectedLoyaltyStampEdit.redemptionQuantityLimitValue.toFixed(2) : '0.00');
      // setRedemptionQuantityLimitDateFormatValue(selectedLoyaltyStampEdit.redemptionQuantityLimitDateFormatValue ? selectedLoyaltyStampEdit.redemptionQuantityLimitDateFormatValue.toFixed(2) : '0.00');
      // setRedemptionQuantityLimitDateFormatType(selectedLoyaltyStampEdit.redemptionQuantityLimitDateFormatType ? selectedLoyaltyStampEdit.redemptionQuantityLimitDateFormatType : DATE_FORMAT_DROPDOWN_LIST[0].value);
    } else {
      // designed to always mounted, thus need clear manually...

      setLsId('');
      if (variationItemsProducts > 0) {
        setLsItems([
          {
            lsItemId: '',
            outletItemSku:
              variationItemsProducts > 0 ? variationItemsProducts[0].value : '',
            variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
            isActive: true,
            noOfStamp: '0',
            quantity: '0',

            itemName: variationItemsProducts[0].name,
            itemDescription: variationItemsProducts[0].description,
            itemImage: variationItemsProducts[0].image,
          },
        ]);

        setLsItemsBuy([
          {
            lsItemId: '',
            outletItemSku:
              variationItemsProducts > 0 ? variationItemsProducts[0].value : '',
            variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
            isActive: true,
            noOfStamp: '0',
            quantity: '0',

            itemName: variationItemsProducts[0].name,
            itemDescription: variationItemsProducts[0].description,
            itemImage: variationItemsProducts[0].image,
          },
        ]);
      }

      setStampName('');
      setTotalNumberOfStamp('0');
      setStartDate(moment().valueOf());
      setEndDate(moment().valueOf());
      setStartTime(moment().valueOf());
      setEndTime(moment().valueOf());
      setTerms('');
      setTargetUsers([]);

      // setBdOptions([
      //   {
      //     optionsId: '',
      //     isActive: true,
      //     duration: 0,
      //     price: 0,
      //     unit: 'day',
      //   }
      // ]);
      // setDocketPrice('0.00');

      // setStartDate(moment());
      // setEndDate(moment());

      // setPurchaseDate(moment());
      // setPurchaseEndDate(moment());

      // setPurchaseTime(moment());
      // setPurchaseEndTime(moment());

      // setExpiredDate(moment());
      // setExpiredTime(moment());

      // setTermsConditions('');
      // setSelectedOutletList([]);

      // setImage('');
      // setIsImageChanged(false);

      // setChargesOfExtensionDateFormatType(DATE_FORMAT_DROPDOWN_LIST[0].value);

      // setRedemptionQuantityLimitValue('0.00');
      // setRedemptionQuantityLimitDateFormatValue('0.00');
      // setRedemptionQuantityLimitDateFormatType(DATE_FORMAT_DROPDOWN_LIST[0].value);
    }
  }, [selectedLoyaltyStampEdit]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  //To remove unwanted sidebar
  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  //////////////////////////////////////////////////
  //Header
  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: 'center',
            alignItems: 'center',
            // bottom: -2,
            bottom: switchMerchant ? '2%' : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? {right: windowWidth * 0.1}
            : {},
          windowWidth <= 768
            ? {right: windowWidth * 0.12}
            : {},
        ]}>
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Loyalty Stamp
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: 'white',
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}></View>
        <TouchableOpacity
          onPress={() => navigation.navigate('Setting')}
          style={{flexDirection: 'row', alignItems: 'center'}}>
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}>
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: 'center',
              }}
              source={require('../assets/image/profile-pic.jpg')}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });
  //////////////////////////////////////////////////////////////////

  const renderLoyaltyStamp = ({item, index}) => {
    return (
      <TouchableOpacity
        style={{
          flexDirection: 'row',
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: '#c4c4c4',
          padding: 10,
          paddingVertical: 20,
        }}
        onPress={() => {
          CommonStore.update((s) => {
            s.selectedLoyaltyStampEdit = item;
          });

          setAddLoyaltyStamp(ADD_LOYALTY_STAMP_SCREEN.ADD_LOYALTY_STAMP);
        }}>
        <Text
          style={{
            fontSize: switchMerchant ? 10 : 16,
            fontWeight: '500',
            fontFamily: 'NunitoSans-Regular',
            color: Colors.primaryColor,
            width: '26%',
            marginLeft: 5,
          }}>
          {/* {stampName} */}
          {item.name}
        </Text>
        <Text
          style={{
            fontSize: switchMerchant ? 10 : 16,
            fontWeight: '500',
            fontFamily: 'NunitoSans-Regular',
            width: '18%',
            marginHorizontal: 5,
          }}>
          {/* {totalNumberOfStamp} */}
          {item.totalNumberOfStamp}
        </Text>
        <View style={{width: '26%', marginHorizontal: 5}}>
          {/* {item.outletNameList.length > 0 ?
        <View>
         {(item.outletNameList.map(( outletNameList ) => {
           return(
         <View>
           <Text style={{ fontSize: 14, fontWeight: '500', fontFamily: 'NunitoSans-Regular',  }}>
             {outletNameList}
           </Text>
         </View>
           )
        }))}
        </View>
        : null } */}
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 16,
              fontWeight: '500',
              fontFamily: 'NunitoSans-Regular',
            }}>
            {item.outletNameList.join('\n')}
          </Text>
        </View>
        <View style={{width: '21%', marginLeft: 5}}>
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 16,
              fontWeight: '500',
              fontFamily: 'NunitoSans-Regular',
            }}>
            {moment(item.startDate).format('DD/MM/YYYY')} to{' '}
            {moment(item.endDate).format('DD/MM/YYYY')}
            {/* 21/7/2021 to 21/8/2021 */}
          </Text>
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 16,
              fontWeight: '500',
              fontFamily: 'NunitoSans-Regular',
              color: Colors.fieldtTxtColor,
            }}>
            {moment(item.startTime).format('hh:mmA')} to{' '}
            {moment(item.endTime).format('hh:mmA')}
            {/* 8:00 a.m. to 9:00 p.m. */}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const addProduct = () => {
    if (outletItemsUnsorted.length > 0) {
      // setSelectedVariationItems([
      //   ...selectedVariationItems,
      //   {
      //     noOfStamp: selectedVariationItems[0].number,
      //     quantity: selectedVariationItems[0].quantity,
      //     productName: selectedVariationItems[0].pName,
      //   }
      // ]);

      setLsItemsBuy([
        ...lsItemsBuy,
        {
          lsItemId: '',
          outletItemSku:
            variationItemsProducts.length > 0
              ? variationItemsProducts[0].value
              : '',
          variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
          isActive: true,
          noOfStamp: '0',
          quantity: '0',

          itemName: variationItemsProducts[0].name,
          itemDescription: variationItemsProducts[0].description,
          itemImage: variationItemsProducts[0].image,
        },
      ]);
    } else {
      Alert.alert('Error', 'Unable to add new product.');
    }
  };

  const addStamp = () => {
    if (outletItemsUnsorted.length > 0) {
      // setSelectedVariationItems([
      //   ...selectedVariationItems,
      //   {
      //     noOfStamp: selectedVariationItems[0].number,
      //     quantity: selectedVariationItems[0].quantity,
      //     productName: selectedVariationItems[0].pName,
      //   }
      // ]);

      setLsItems([
        ...lsItems,
        {
          lsItemId: '',
          outletItemSku:
            variationItemsProducts.length > 0
              ? variationItemsProducts[0].value
              : '',
          variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
          isActive: true,
          noOfStamp: '0',
          quantity: '0',

          itemName: variationItemsProducts[0].name,
          itemDescription: variationItemsProducts[0].description,
          itemImage: variationItemsProducts[0].image,
        },
      ]);
    } else {
      Alert.alert('Error', 'Unable to add new stamp item.');
    }
  };

  const renderProductEligible = () => {
    return (
      <>
        {lsItemsBuy.map((item, index) => {
          return (
            <View
              style={{
                position: 'relative',
                //backgroundColor: '#ffffff',
                flexDirection: 'row',
                paddingVertical: 10,
                marginLeft: switchMerchant ? '3.5%' : 0,

                // borderBottomWidth: StyleSheet.hairlineWidth,
                // borderBottomColor: '#c4c4c4',
                alignItems: 'center',
                width: '100%',
                zIndex: 10001 + lsItemsBuy.length - index,
              }}>
              <View style={{flexDirection: 'column', width: '90%'}}>
                <View
                  style={{
                    flexDirection: 'row',
                    position: 'relative',
                    alignItems: 'center',
                    paddingHorizontal: switchMerchant ? 0 : 20,
                  }}>
                  <View
                    style={{
                      width: switchMerchant ? '3%' : '5%',
                      marginHorizontal: 5,
                    }}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      Buy
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      width: switchMerchant ? '7%' : '7%',
                      marginHorizontal: 5,
                    }}>
                    {/* <Text style={{ fontWeight: '500', height: 28, paddingTop: 5, marginRight: 2, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}>
            {numberOfStamp}10
          </Text>
          <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, alignSelf: 'flex-start' }}>
            {ordinals}th
          </Text> */}
                    <TextInput
                      placeholder="No."
                      placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                      placeholderStyle={{
                        color: 'grey',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 14,
                      }}
                      style={[
                        {
                          backgroundColor: Colors.fieldtBgColor,
                          width: 50,
                          height: 40,
                          borderRadius: 5,
                          padding: 5,
                          marginVertical: 5,
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          paddingLeft: 10,
                          fontFamily: 'NunitoSans-Regular',
                          fontSize: 14,
                        },
                        switchMerchant
                          ? {
                              fontSize: 10,
                              width: '100%',
                              height: 35,
                            }
                          : {},
                      ]}
                      //   onChangeText={(text) => {
                      //     setNumberOfStamp(text)
                      //   }}
                      //   defaultValue={numberOfStamp ? numberOfStamp : 'No.'}
                      // //value={numberOfStamp}
                      keyboardType={'number-pad'}
                      onChangeText={(text) => {
                        // setSelectedVariationItems(selectedVariationItems.map((stampItem, i) => (i === index ? {
                        //   ...stampItem,
                        //   noOfStamp: text,

                        // } : stampItem)))
                        setLsItemsBuy(
                          lsItemsBuy.map((lsItem, i) =>
                            i === index
                              ? {
                                  ...lsItem,
                                  // noOfStamp: text,
                                  quantity: text,
                                }
                              : lsItem,
                          ),
                        );
                      }}
                      defaultValue={lsItemsBuy[index].quantity}
                    />
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '3%' : '3%',
                      marginHorizontal: 5,
                    }}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      of
                    </Text>
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '23%' : '23%',
                      marginHorizontal: 5,
                    }}>
                    <View
                      style={{
                        zIndex: 10001 + lsItemsBuy.length - index,
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          //paddingBottom: 10,
                          //marginBottom: 15,
                          alignItems: 'center',
                        }}>
                        <View
                          style={{
                            //width: '40%', marginHorizontal: 5,
                            height: switchMerchant ? 35 : 40,
                            justifyContent: 'center',
                            //alignItems: 'flex-end',
                            alignSelf: 'flex-end',
                            width: switchMerchant ? '100%' : '100%',
                          }}>
                          <Picker
                            containerStyle={{
                              height: switchMerchant ? 35 : 40,
                              zIndex: 2,
                            }}
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{fontWeight: 'bold'}}
                            labelStyle={{
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            style={{
                              width: switchMerchant ? '100%' : '75%',
                              paddingVertical: 0,
                              borderRadius: 10,
                            }}
                            placeholderStyle={{
                              color: Colors.fieldtTxtColor,
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            placeholder={'Select a Product'}
                            zIndex={10000 + lsItemsBuy.length - index}
                            itemStyle={{
                              justifyContent: 'flex-start',
                              marginLeft: 5,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              zIndex: 2,
                            }}
                            customTickIcon={(press) => (
                              <Ionicon
                                name={'md-checkbox'}
                                color={
                                  press
                                    ? Colors.fieldtBgColor
                                    : Colors.primaryColor
                                }
                                size={25}
                              />
                            )}
                            onValueChange={(item) => {
                              // setSelectedVariationItems(
                              //   selectedVariationItems.map((varItem, i) =>
                              //     i === index ? {
                              //       ...varItem,
                              //       noOfStamp: '',
                              //       productName: '',
                              //       quantity: 0,
                              //     } : varItem)
                              // )
                              // setLsItemsBuy(lsItemsBuy.map((lsItem, i) => (i === index ? {
                              //   ...lsItem,
                              //   outletItemSku: item.value,
                              //   itemImage: item.image,
                              //   itemName: item.name,
                              //   itemDescription: item.description,
                              // } : lsItem)))

                              if (lsItemsBuy[index].variation !== item) {
                                setLsItemsBuy(
                                  lsItemsBuy.map((lsItem, i) =>
                                    i === index
                                      ? {
                                          ...lsItem,
                                          variation: item,

                                          outletItemSku:
                                            item ===
                                            PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
                                              ? variationItemsProducts[0].value
                                              : variationItemsCategories[0]
                                                  .value,
                                          itemName:
                                            item ===
                                            PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
                                              ? variationItemsProducts[0].name
                                              : variationItemsCategories[0]
                                                  .name,
                                          itemDescription:
                                            item ===
                                            PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
                                              ? variationItemsProducts[0]
                                                  .description
                                              : variationItemsCategories[0]
                                                  .description,
                                          itemImage:
                                            item ===
                                            PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
                                              ? variationItemsProducts[0].image
                                              : variationItemsCategories[0]
                                                  .image,
                                        }
                                      : lsItem,
                                  ),
                                );
                              } else {
                                setLsItemsBuy(
                                  lsItemsBuy.map((lsItem, i) =>
                                    i === index
                                      ? {
                                          ...lsItem,
                                          variation: item,
                                        }
                                      : lsItem,
                                  ),
                                );
                              }
                            }}
                            defaultValue={lsItemsBuy[index].variation}
                            // value={lsItems[index].productName}
                            //multiple={true}
                            zIndex={1000}
                            // searchable={true}
                            // seachableStyle={{ backgroundColor: 'red' }}
                            dropDownMaxHeight={190}
                            dropDownStyle={{
                              width: switchMerchant ? '100%' : '75%',
                              height: 80,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              borderWidth: 1,
                              zIndex: 2,
                            }}
                            globalTextStyle={{
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                          >
                            {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                              return(
                                <Picker.Item
                                key={index}
                                value={value.value}
                                label={value.label}
                                />
                              )
                            })}
                          </Picker>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '23%' : '23%',
                      marginHorizontal: 5,
                    }}>
                    <View
                      style={{
                        zIndex: 10001 + lsItemsBuy.length - index,
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          //paddingBottom: 10,
                          //marginBottom: 15,
                          alignItems: 'center',
                        }}>
                        <View
                          style={{
                            //width: '40%', marginHorizontal: 5,
                            height: switchMerchant ? 35 : 40,
                            justifyContent: 'center',
                            //alignItems: 'flex-end',
                            alignSelf: 'flex-end',
                            width: switchMerchant ? '100%' : '100%',
                          }}>
                          {
                            // (variationItemsDropdownList.find(variationItem => variationItem.value === item.outletItemSku) || item.outletItemSku === '')
                            (
                              lsItemsBuy[index].variation ===
                              PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                ? variationItemsCategories.length > 0 &&
                                  variationItemsCategories.find(
                                    (item) =>
                                      item.value ===
                                      lsItemsBuy[index].outletItemSku,
                                  )
                                : variationItemsProducts.length > 0 &&
                                  variationItemsProducts.find(
                                    (item) =>
                                      item.value ===
                                      lsItemsBuy[index].outletItemSku,
                                  )
                            ) ? (
                              <Picker
                                containerStyle={{
                                  height: switchMerchant ? 35 : 40,
                                  zIndex: 2,
                                }}
                                arrowColor={'black'}
                                arrowSize={20}
                                arrowStyle={{fontWeight: 'bold'}}
                                labelStyle={{
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                style={{
                                  width: switchMerchant ? '100%' : '75%',
                                  paddingVertical: 0,
                                  borderRadius: 10,
                                }}
                                placeholderStyle={{
                                  color: Colors.fieldtTxtColor,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholder={'Select a Product'}
                                zIndex={10000 + lsItemsBuy.length - index}
                                // items={variationItemsDropdownList}
                                itemStyle={{
                                  justifyContent: 'flex-start',
                                  marginLeft: 5,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                  zIndex: 2,
                                }}
                                customTickIcon={(press) => (
                                  <Ionicon
                                    name={'md-checkbox'}
                                    color={
                                      press
                                        ? Colors.fieldtBgColor
                                        : Colors.primaryColor
                                    }
                                    size={25}
                                  />
                                )}
                                onValueChange={(item) => {
                                  // setSelectedVariationItems(
                                  //   selectedVariationItems.map((varItem, i) =>
                                  //     i === index ? {
                                  //       ...varItem,
                                  //       noOfStamp: '',
                                  //       productName: '',
                                  //       quantity: 0,
                                  //     } : varItem)
                                  // )
                                  setLsItemsBuy(
                                    lsItemsBuy.map((lsItem, i) =>
                                      i === index
                                        ? {
                                            ...lsItem,
                                            outletItemSku: item,
                                            itemImage: item.image,
                                            itemName: item.name,
                                            itemDescription: item.description,
                                          }
                                        : lsItem,
                                    ),
                                  );
                                }}
                                defaultValue={lsItemsBuy[index].outletItemSku}
                                // value={lsItems[index].productName}
                                //multiple={true}
                                zIndex={1000}
                                searchable={true}
                                seachableStyle={{backgroundColor: 'red'}}
                                dropDownMaxHeight={190}
                                dropDownStyle={{
                                  width: switchMerchant ? '100%' : '75%',
                                  height: 170,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  zIndex: 2,
                                }}
                                globalTextStyle={{
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                {lsItemsBuy[index].variation ===
                                  PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                    ? variationItemsCategories.map((value, index) => {
                                      return (
                                        <Picker.Item
                                        key={index}
                                        value={value.value}
                                        label={value.label}
                                        />
                                      )
                                    })
                                    : variationItemsProducts.map((value, index) => {
                                      return (
                                        <Picker.Item
                                        key={index}
                                        value={value.value}
                                        label={value.label}
                                        />
                                      )
                                    })
                                    }
                              </Picker>
                            ) : (
                              <></>
                            )
                          }
                        </View>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '4%' : '7%',
                      marginLeft: 3,
                      marginRight: 5,
                    }}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      to get
                    </Text>
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '7%' : '7%',
                      marginRight: 5,
                      marginLeft: 7,
                    }}>
                    {/* <Text style={{ fontWeight: '500', fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}>
                    {quantityGet}10
                  </Text> */}
                    <TextInput
                      placeholder="0"
                      placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                      placeholderStyle={{
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 14,
                      }}
                      style={[
                        {
                          backgroundColor: Colors.fieldtBgColor,
                          width: 50,
                          height: 40,
                          borderRadius: 5,
                          padding: 5,
                          marginVertical: 5,
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          paddingLeft: 10,
                          fontFamily: 'NunitoSans-Regular',
                          fontSize: 14,
                        },
                        switchMerchant
                          ? {
                              fontSize: 10,
                              width: '100%',
                              height: 35,
                            }
                          : {},
                      ]}
                      keyboardType={'number-pad'}
                      // onChangeText={(text) => {
                      //   setQuantityGet(text)
                      // }}
                      // defaultValue={quantityGet ? quantityGet : '0'}
                      ////value={quantityGet}
                      onChangeText={(text) => {
                        // setSelectedVariationItems(selectedVariationItems.map((stampItem, i) => (i === index ? {
                        //   ...stampItem,
                        //   quantity: text,

                        // } : stampItem)))
                        setLsItemsBuy(
                          lsItemsBuy.map((lsItem, i) =>
                            i === index
                              ? {
                                  ...lsItem,
                                  // quantity: text,
                                  noOfStamp: text,
                                }
                              : lsItem,
                          ),
                        );
                      }}
                      defaultValue={lsItemsBuy[index].noOfStamp}
                    />
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '8%' : '10%',
                      marginHorizontal: 5,
                    }}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      of Stamps
                    </Text>
                  </View>
                </View>
                {/* <View style={{ flexDirection: 'row', position: 'relative', alignItems: 'center', paddingHorizontal: switchMerchant ? 0 : 20, }}>
                  <View style={{ width: switchMerchant ? '6%' : '5%', marginHorizontal: 5 }}>
                    <Text style={{ fontWeight: '500', fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}>
                      Criteria
                    </Text>
                  </View>
                  <View style={{ width: switchMerchant ? '80%' : '80%', marginHorizontal: 5 }}>
                    <TextInput
                      placeholder='Criteria'
                      placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                      placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: 14 }}
                      style={[{
                        backgroundColor: Colors.fieldtBgColor,
                        width: '100%',
                        height: 40,
                        borderRadius: 5,
                        padding: 5,
                        marginVertical: 5,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        paddingLeft: 10,
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 14,
                      }, switchMerchant ? {
                        fontSize: 10,
                        width: '100%',
                        height: 35,
                      } : {}]}
                      //keyboardType={'number-pad'}
                      // onChangeText={(text) => {
                      //   setQuantityGet(text)
                      // }}
                      // defaultValue={quantityGet ? quantityGet : '0'}
                      ////value={quantityGet}
                      onChangeText={(text) => {
                        // setSelectedVariationItems(selectedVariationItems.map((stampItem, i) => (i === index ? {
                        //   ...stampItem,
                        //   quantity: text,

                        // } : stampItem)))
                        setLsItems(lsItems.map((lsItem, i) => (i === index ? {
                          ...lsItem,
                          tc: text,
                        } : lsItem)))
                      }}
                      defaultValue={lsItems[index].tc}
                    />
                  </View>
                </View> */}
              </View>
              <View style={{flexDirection: 'column', width: '3%'}}>
                <View style={{width: '100%'}}>
                  <TouchableOpacity
                    style={{alignSelf: 'flex-start'}}
                    onPress={() => {
                      setLsItemsBuy([
                        ...lsItemsBuy.slice(0, index),
                        ...lsItemsBuy.slice(index + 1),
                      ]);
                    }}>
                    <Feather
                      name="trash-2"
                      size={switchMerchant ? 15 : 20}
                      color="#eb3446"
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          );
        })}
      </>
    );
  };

  const renderStampItems = () => {
    return (
      <>
        {lsItems.map((item, index) => {
          return (
            <View
              style={{
                position: 'relative',
                //backgroundColor: '#ffffff',
                flexDirection: 'row',
                paddingVertical: 10,
                marginLeft: switchMerchant ? '3.5%' : 0,

                // borderBottomWidth: StyleSheet.hairlineWidth,
                // borderBottomColor: '#c4c4c4',
                alignItems: 'center',
                width: '100%',
                zIndex: 10001 + lsItems.length - index,
              }}>
              <View style={{flexDirection: 'column', width: '90%'}}>
                <View
                  style={{
                    flexDirection: 'row',
                    position: 'relative',
                    alignItems: 'center',
                    paddingHorizontal: switchMerchant ? 0 : 20,
                  }}>
                  <View
                    style={{
                      width: switchMerchant ? '3%' : '5%',
                      marginHorizontal: 5,
                      justifyContent: 'center',
                    }}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                        textAlignVertical: 'center',
                      }}>
                      Get
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      width: switchMerchant ? '7%' : '7%',
                      marginHorizontal: 5,
                    }}>
                    {/* <Text style={{ fontWeight: '500', height: 28, paddingTop: 5, marginRight: 2, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}>
            {numberOfStamp}10
          </Text>
          <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, alignSelf: 'flex-start' }}>
            {ordinals}th
          </Text> */}
                    <TextInput
                      placeholder="No."
                      placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                      placeholderStyle={{
                        color: 'grey',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 14,
                      }}
                      style={[
                        {
                          backgroundColor: Colors.fieldtBgColor,
                          width: 50,
                          height: 40,
                          borderRadius: 5,
                          padding: 5,
                          marginVertical: 5,
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          paddingLeft: 10,
                          fontFamily: 'NunitoSans-Regular',
                          fontSize: 14,
                        },
                        switchMerchant
                          ? {
                              fontSize: 10,
                              width: '100%',
                              height: 35,
                            }
                          : {},
                      ]}
                      //   onChangeText={(text) => {
                      //     setNumberOfStamp(text)
                      //   }}
                      //   defaultValue={numberOfStamp ? numberOfStamp : 'No.'}
                      // //value={numberOfStamp}
                      keyboardType={'number-pad'}
                      onChangeText={(text) => {
                        // setSelectedVariationItems(selectedVariationItems.map((stampItem, i) => (i === index ? {
                        //   ...stampItem,
                        //   noOfStamp: text,

                        // } : stampItem)))
                        setLsItems(
                          lsItems.map((lsItem, i) =>
                            i === index
                              ? {
                                  ...lsItem,
                                  // noOfStamp: text,
                                  quantity: text,
                                }
                              : lsItem,
                          ),
                        );
                      }}
                      defaultValue={lsItems[index].quantity}
                    />
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '3%' : '3%',
                      marginHorizontal: 5,
                    }}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      of
                    </Text>
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '23%' : '23%',
                      marginHorizontal: 5,
                    }}>
                    <View
                      style={{
                        zIndex: 10001 + lsItems.length - index,
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          //paddingBottom: 10,
                          //marginBottom: 15,
                          alignItems: 'center',
                        }}>
                        <View
                          style={{
                            //width: '40%', marginHorizontal: 5,
                            height: switchMerchant ? 35 : 40,
                            justifyContent: 'center',
                            //alignItems: 'flex-end',
                            alignSelf: 'flex-end',
                            width: switchMerchant ? '100%' : '100%',
                          }}>
                          <Picker
                            containerStyle={{
                              height: switchMerchant ? 35 : 40,
                              zIndex: 2,
                            }}
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{fontWeight: 'bold'}}
                            labelStyle={{
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            style={{
                              width: switchMerchant ? '100%' : '75%',
                              paddingVertical: 0,
                              borderRadius: 10,
                            }}
                            placeholderStyle={{
                              color: Colors.fieldtTxtColor,
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                            placeholder={'Select a Product'}
                            zIndex={10000 + lsItems.length - index}
                            // disabled={true}
                            itemStyle={{
                              justifyContent: 'flex-start',
                              marginLeft: 5,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 14,
                              zIndex: 2,
                            }}
                            customTickIcon={(press) => (
                              <Ionicon
                                name={'md-checkbox'}
                                color={
                                  press
                                    ? Colors.fieldtBgColor
                                    : Colors.primaryColor
                                }
                                size={25}
                              />
                            )}
                            onValueChange={(item) => {
                              // setSelectedVariationItems(
                              //   selectedVariationItems.map((varItem, i) =>
                              //     i === index ? {
                              //       ...varItem,
                              //       noOfStamp: '',
                              //       productName: '',
                              //       quantity: 0,
                              //     } : varItem)
                              // )
                              // setLsItems(lsItems.map((lsItem, i) => (i === index ? {
                              //   ...lsItem,
                              //   variation: item.value,
                              // } : lsItem)))

                              if (lsItems[index].variation !== item) {
                                setLsItems(
                                  lsItems.map((lsItem, i) =>
                                    i === index
                                      ? {
                                          ...lsItem,
                                          variation: item,

                                          outletItemSku:
                                            item ===
                                            PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
                                              ? variationItemsProducts[0].value
                                              : variationItemsCategories[0]
                                                  .value,
                                          itemName:
                                            item ===
                                            PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
                                              ? variationItemsProducts[0].name
                                              : variationItemsCategories[0]
                                                  .name,
                                          itemDescription:
                                            item ===
                                            PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
                                              ? variationItemsProducts[0]
                                                  .description
                                              : variationItemsCategories[0]
                                                  .description,
                                          itemImage:
                                            item ===
                                            PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
                                              ? variationItemsProducts[0].image
                                              : variationItemsCategories[0]
                                                  .image,
                                        }
                                      : lsItem,
                                  ),
                                );
                              } else {
                                setLsItems(
                                  lsItems.map((lsItem, i) =>
                                    i === index
                                      ? {
                                          ...lsItem,
                                          variation: item,
                                        }
                                      : lsItem,
                                  ),
                                );
                              }
                            }}
                            defaultValue={lsItems[index].variation}
                            // value={lsItems[index].productName}
                            //multiple={true}
                            zIndex={1000}
                            // searchable={true}
                            // seachableStyle={{ backgroundColor: 'red' }}
                            dropDownMaxHeight={190}
                            dropDownStyle={{
                              width: switchMerchant ? '100%' : '75%',
                              height: 80,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                              borderWidth: 1,
                              zIndex: 2,
                            }}
                            globalTextStyle={{
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                          >
                            {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                              return(
                                <Picker.Item
                                key={index}
                                value={value.value}
                                label={value.label}
                                />
                              )
                            })}
                          </Picker>
                        </View>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '23%' : '23%',
                      marginHorizontal: 5,
                    }}>
                    <View
                      style={{
                        zIndex: 10001 + lsItems.length - index,
                      }}>
                      <View
                        style={{
                          flexDirection: 'row',
                          //paddingBottom: 10,
                          //marginBottom: 15,
                          alignItems: 'center',
                        }}>
                        <View
                          style={{
                            //width: '40%', marginHorizontal: 5,
                            height: switchMerchant ? 35 : 40,
                            justifyContent: 'center',
                            //alignItems: 'flex-end',
                            alignSelf: 'flex-end',
                            width: switchMerchant ? '100%' : '100%',
                          }}>
                          {
                            // (variationItemsDropdownList.find(variationItem => variationItem.value === item.outletItemSku) || item.outletItemSku === '')
                            (
                              lsItems[index].variation ===
                              PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                ? variationItemsCategories.length > 0 &&
                                  variationItemsCategories.find(
                                    (item) =>
                                      item.value ===
                                      lsItems[index].outletItemSku,
                                  )
                                : variationItemsProducts.length > 0 &&
                                  variationItemsProducts.find(
                                    (item) =>
                                      item.value ===
                                      lsItems[index].outletItemSku,
                                  )
                            ) ? (
                              <Picker
                                containerStyle={{
                                  height: switchMerchant ? 35 : 40,
                                  zIndex: 2,
                                }}
                                arrowColor={'black'}
                                arrowSize={20}
                                arrowStyle={{fontWeight: 'bold'}}
                                labelStyle={{
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                style={{
                                  width: switchMerchant ? '100%' : '75%',
                                  paddingVertical: 0,
                                  borderRadius: 10,
                                }}
                                placeholderStyle={{
                                  color: Colors.fieldtTxtColor,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholder={'Select a Product'}
                                zIndex={10000 + lsItems.length - index}
                                // items={variationItemsDropdownList}
                                itemStyle={{
                                  justifyContent: 'flex-start',
                                  marginLeft: 5,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: switchMerchant ? 10 : 14,
                                  zIndex: 2,
                                }}
                                customTickIcon={(press) => (
                                  <Ionicon
                                    name={'md-checkbox'}
                                    color={
                                      press
                                        ? Colors.fieldtBgColor
                                        : Colors.primaryColor
                                    }
                                    size={25}
                                  />
                                )}
                                onValueChange={(item) => {
                                  // setSelectedVariationItems(
                                  //   selectedVariationItems.map((varItem, i) =>
                                  //     i === index ? {
                                  //       ...varItem,
                                  //       noOfStamp: '',
                                  //       productName: '',
                                  //       quantity: 0,
                                  //     } : varItem)
                                  // )
                                  setLsItems(
                                    lsItems.map((lsItem, i) =>
                                      i === index
                                        ? {
                                            ...lsItem,
                                            outletItemSku: item,
                                            itemImage: item.image,
                                            itemName: item.name,
                                            itemDescription: item.description,
                                          }
                                        : lsItem,
                                    ),
                                  );
                                }}
                                defaultValue={lsItems[index].outletItemSku}
                                // value={lsItems[index].productName}
                                //multiple={true}
                                zIndex={1000}
                                searchable={true}
                                seachableStyle={{backgroundColor: 'red'}}
                                dropDownMaxHeight={190}
                                dropDownStyle={{
                                  width: switchMerchant ? '100%' : '75%',
                                  height: 165,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  zIndex: 2,
                                }}
                                globalTextStyle={{
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                {lsItems[index].variation ===
                                  PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                    ? variationItemsCategories.map((value, index) => {
                                      return(
                                        <Picker.Item
                                          key={index}
                                          value={value.value}
                                          label={value.label}
                                        />
                                      )
                                    })
                                    : variationItemsProducts.map((value, index) => {
                                      return(
                                        <Picker.Item
                                          key={index}
                                          value={value.value}
                                          label={value.label}
                                        />
                                      )
                                    })}
                              </Picker>
                            ) : (
                              <></>
                            )
                          }
                        </View>
                      </View>
                    </View>
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '4%' : '4%',
                      marginHorizontal: 5,
                    }}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      with
                    </Text>
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '7%' : '7%',
                      marginHorizontal: 5,
                    }}>
                    {/* <Text style={{ fontWeight: '500', fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}>
                    {quantityGet}10
                  </Text> */}
                    <TextInput
                      placeholder="0"
                      placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                      placeholderStyle={{
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 14,
                      }}
                      style={[
                        {
                          backgroundColor: Colors.fieldtBgColor,
                          width: 50,
                          height: 40,
                          borderRadius: 5,
                          padding: 5,
                          marginVertical: 5,
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          paddingLeft: 10,
                          fontFamily: 'NunitoSans-Regular',
                          fontSize: 14,
                        },
                        switchMerchant
                          ? {
                              fontSize: 10,
                              width: '100%',
                              height: 35,
                            }
                          : {},
                      ]}
                      keyboardType={'number-pad'}
                      // onChangeText={(text) => {
                      //   setQuantityGet(text)
                      // }}
                      // defaultValue={quantityGet ? quantityGet : '0'}
                      ////value={quantityGet}
                      onChangeText={(text) => {
                        // setSelectedVariationItems(selectedVariationItems.map((stampItem, i) => (i === index ? {
                        //   ...stampItem,
                        //   quantity: text,

                        // } : stampItem)))
                        setLsItems(
                          lsItems.map((lsItem, i) =>
                            i === index
                              ? {
                                  ...lsItem,
                                  noOfStamp: text,
                                }
                              : lsItem,
                          ),
                        );
                      }}
                      defaultValue={lsItems[index].noOfStamp}
                    />
                  </View>

                  <View
                    style={{
                      width: switchMerchant ? '8%' : '10%',
                      marginHorizontal: 5,
                    }}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      of Stamps
                    </Text>
                  </View>
                </View>
                {/* <View style={{ flexDirection: 'row', position: 'relative', alignItems: 'center', paddingHorizontal: switchMerchant ? 0 : 20, }}>
                  <View style={{ width: switchMerchant ? '6%' : '5%', marginHorizontal: 5 }}>
                    <Text style={{ fontWeight: '500', fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}>
                      Criteria
                    </Text>
                  </View>
                  <View style={{ width: switchMerchant ? '80%' : '80%', marginHorizontal: 5 }}>
                    <TextInput
                      placeholder='Criteria'
                      placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                      placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: 14 }}
                      style={[{
                        backgroundColor: Colors.fieldtBgColor,
                        width: '100%',
                        height: 40,
                        borderRadius: 5,
                        padding: 5,
                        marginVertical: 5,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        paddingLeft: 10,
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 14,
                      }, switchMerchant ? {
                        fontSize: 10,
                        width: '100%',
                        height: 35,
                      } : {}]}
                      //keyboardType={'number-pad'}
                      // onChangeText={(text) => {
                      //   setQuantityGet(text)
                      // }}
                      // defaultValue={quantityGet ? quantityGet : '0'}
                      ////value={quantityGet}
                      onChangeText={(text) => {
                        // setSelectedVariationItems(selectedVariationItems.map((stampItem, i) => (i === index ? {
                        //   ...stampItem,
                        //   quantity: text,

                        // } : stampItem)))
                        setLsItems(lsItems.map((lsItem, i) => (i === index ? {
                          ...lsItem,
                          tc: text,
                        } : lsItem)))
                      }}
                      defaultValue={lsItems[index].tc}
                    />
                  </View>
                </View> */}
              </View>
              <View style={{flexDirection: 'column', width: '10%'}}>
                <View style={{width: '100%'}}>
                  <TouchableOpacity
                    style={{alignSelf: 'flex-start'}}
                    onPress={() => {
                      setLsItems([
                        ...lsItems.slice(0, index),
                        ...lsItems.slice(index + 1),
                      ]);
                    }}>
                    <Feather
                      name="trash-2"
                      size={switchMerchant ? 15 : 20}
                      color="#eb3446"
                    />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          );
        })}
      </>
    );
  };

  const createLoyaltyStamp = async () => {
    if (
      !stampName ||
      !totalNumberOfStamp ||
      !startDate ||
      !endDate ||
      !startTime ||
      !endTime ||
      !terms ||
      lsItems.length < 1
    ) {
      Alert.alert(
        'Error',
        'Please fill in the information',
        [{text: 'OK', onPress: () => {}}],
        {cancelable: false},
      );
      return;
    } else {
      var body = {
        name: stampName,
        totalNumberOfStamp: parseInt(totalNumberOfStamp),
        startDate: moment(startDate).valueOf(),
        endDate: moment(endDate).valueOf(),
        startTime: moment(startTime).valueOf(),
        endTime: moment(endTime).valueOf(),
        terms: terms,

        lsItems: lsItems.map((item) => ({
          lsItemId: item.lsItemId,
          outletItemSku: item.outletItemSku,
          variation: item.variation,
          isActive: item.isActive,
          noOfStamp: parseInt(item.noOfStamp),
          quantity: parseInt(item.quantity),

          itemName: item.itemName,
          itemDescription: item.itemDescription,
          itemImage: item.itemImage,
        })),

        lsItemsBuy: lsItemsBuy.map((item) => ({
          lsItemId: item.lsItemId,
          outletItemSku: item.outletItemSku,
          variation: item.variation,
          isActive: item.isActive,
          noOfStamp: parseInt(item.noOfStamp),
          quantity: parseInt(item.quantity),

          itemName: item.itemName,
          itemDescription: item.itemDescription,
          itemImage: item.itemImage,
        })),

        outletIdList: [currOutlet.uniqueId],
        outletNameList: [currOutlet.name],

        outletId: currOutlet.uniqueId,

        merchantId: merchantId,
        merchantName: merchantName,
        merchantLogo: merchantLogo,

        targetSegments: targetUsers,
      };

      console.log('BODY', body);

      CommonStore.update((s) => {
        s.isLoading = true;
      });

      ApiClient.POST(API.createLoyaltyStamp, body).then((result) => {
        console.log('RESULT', result);

        if (result && result.status === 'success') {
          Alert.alert(
            'Success',
            'Loyalty stamp has been created',
            [
              {
                text: 'OK',
                onPress: () => {
                  setStampName('');
                  setTotalNumberOfStamp('0');
                  setStartDate(moment().valueOf());
                  setStartTime(moment().valueOf());
                  setEndDate(moment().valueOf());
                  setEndTime(moment().valueOf());
                  setTerms('');

                  setAddLoyaltyStamp(
                    ADD_LOYALTY_STAMP_SCREEN.LOYALTY_STAMP_LIST,
                  );
                },
              },
            ],
            {cancelable: false},
          );
          CommonStore.update((s) => {
            s.isLoading = false;
          });
        } else {
          Alert.alert(
            'Error',
            'Failed to create loyalty stamp',
            [
              {
                text: 'OK',
                onPress: () => {},
              },
            ],
            {cancelable: false},
          );

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        }
      });
    }
  };

  const updateLoyaltyStamp = async () => {
    if (
      !stampName ||
      !totalNumberOfStamp ||
      !startDate ||
      !endDate ||
      !startTime ||
      !endTime ||
      !terms ||
      lsItems.length < 1
    ) {
      Alert.alert(
        'Error',
        'Please fill in the information',
        [{text: 'OK', onPress: () => {}}],
        {cancelable: false},
      );
      return;
    } else {
      var body = {
        name: stampName,
        totalNumberOfStamp: parseInt(totalNumberOfStamp),
        startDate: moment(startDate).valueOf(),
        endDate: moment(endDate).valueOf(),
        startTime: moment(startTime).valueOf(),
        endTime: moment(endTime).valueOf(),
        terms: terms,

        lsItems: lsItems.map((item) => ({
          lsItemId: item.lsItemId,
          outletItemSku: item.outletItemSku,
          variation: item.variation,
          isActive: item.isActive,
          noOfStamp: parseInt(item.noOfStamp),
          quantity: parseInt(item.quantity),

          itemName: item.itemName,
          itemDescription: item.itemDescription,
          itemImage: item.itemImage,
        })),

        lsItemsBuy: lsItemsBuy.map((item) => ({
          lsItemId: item.lsItemId,
          outletItemSku: item.outletItemSku,
          variation: item.variation,
          isActive: item.isActive,
          noOfStamp: parseInt(item.noOfStamp),
          quantity: parseInt(item.quantity),

          itemName: item.itemName,
          itemDescription: item.itemDescription,
          itemImage: item.itemImage,
        })),

        outletIdList: [currOutlet.uniqueId],
        outletNameList: [currOutlet.name],

        outletId: currOutlet.uniqueId,

        merchantId: merchantId,
        merchantName: merchantName,
        merchantLogo: merchantLogo,

        targetSegments: targetUsers,

        lsId: lsId,
      };

      console.log('BODY', body);

      CommonStore.update((s) => {
        s.isLoading = true;
      });

      ApiClient.POST(API.updateLoyaltyStamp, body).then((result) => {
        console.log('RESULT', result);

        if (result && result.status === 'success') {
          Alert.alert(
            'Success',
            'Loyalty stamp has been updated',
            [
              {
                text: 'OK',
                onPress: () => {
                  setStampName('');
                  setTotalNumberOfStamp('0');
                  setStartDate(moment().valueOf());
                  setStartTime(moment().valueOf());
                  setEndDate(moment().valueOf());
                  setEndTime(moment().valueOf());
                  setTerms('');

                  setAddLoyaltyStamp(
                    ADD_LOYALTY_STAMP_SCREEN.LOYALTY_STAMP_LIST,
                  );
                },
              },
            ],
            {cancelable: false},
          );

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        } else {
          Alert.alert(
            'Error',
            'Failed to update loyalty stamp',
            [
              {
                text: 'OK',
                onPress: () => {},
              },
            ],
            {cancelable: false},
          );

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        }
      });
    }
  };

  const renderItems = ({item, index}) => {
    return (
      <View
        style={{
          position: 'relative',
          //backgroundColor: '#ffffff',
          flexDirection: 'row',
          paddingVertical: 20,
          paddingHorizontal: 20,
          // borderBottomWidth: StyleSheet.hairlineWidth,
          // borderBottomColor: '#c4c4c4',
          alignItems: 'center',
          zIndex: 1,
        }}>
        <View
          style={{
            flexDirection: 'row',
            width: switchMerchant ? '20%' : '10%',
            marginRight: 5,
            marginTop: 1,
          }}>
          {/* <Text style={{ fontWeight: '500', height: 28, paddingTop: 5, marginRight: 2, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}>
            {numberOfStamp}10
          </Text>
          <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, alignSelf: 'flex-start' }}>
            {ordinals}th
          </Text> */}
          <TextInput
            placeholder="No."
            placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
            placeholderStyle={{
              color: 'grey',
              fontFamily: 'NunitoSans-Regular',
              fontSize: 14,
            }}
            style={[
              {
                backgroundColor: Colors.fieldtBgColor,
                width: 100,
                height: 40,
                borderRadius: 5,
                padding: 5,
                marginVertical: 5,
                borderWidth: 1,
                borderColor: '#E5E5E5',
                paddingLeft: 10,
                fontFamily: 'NunitoSans-Regular',
                fontSize: 14,
              },
              switchMerchant
                ? {
                    fontSize: 10,
                    width: '85%',
                    height: 35,
                  }
                : {},
            ]}
            //   onChangeText={(text) => {
            //     setNumberOfStamp(text)
            //   }}
            //   defaultValue={numberOfStamp ? numberOfStamp : 'No.'}
            // //value={numberOfStamp}
            onChangeText={(text) => {
              setLsItems(
                lsItems.map((stampItem, i) =>
                  i === index
                    ? {
                        ...stampItem,
                        noOfStamp: parseInt(text),
                      }
                    : stampItem,
                ),
              );
            }}
            defaultValue={lsItems[index].noOfStamp}
          />
        </View>

        <View style={{width: '10%', marginHorizontal: 5}}>
          <Text
            style={{
              fontWeight: '500',
              fontFamily: 'NunitoSans-Regular',
              fontSize: switchMerchant ? 10 : 14,
            }}>
            Stamp will get
          </Text>
        </View>

        <View
          style={{width: switchMerchant ? '20%' : '15%', marginHorizontal: 5}}>
          {/* <Text style={{ fontWeight: '500', fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}>
            {quantityGet}10
          </Text> */}
          <TextInput
            placeholder="0"
            placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
            placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: 14}}
            style={[
              {
                backgroundColor: Colors.fieldtBgColor,
                width: 100,
                height: 40,
                borderRadius: 5,
                padding: 5,
                marginVertical: 5,
                borderWidth: 1,
                borderColor: '#E5E5E5',
                paddingLeft: 10,
                fontFamily: 'NunitoSans-Regular',
                fontSize: 14,
              },
              switchMerchant
                ? {
                    fontSize: 10,
                    width: '85%',
                    height: 35,
                  }
                : {},
            ]}
            keyboardType={'number-pad'}
            // onChangeText={(text) => {
            //   setQuantityGet(text)
            // }}
            // defaultValue={quantityGet ? quantityGet : '0'}
            ////value={quantityGet}
            onChangeText={(text) => {
              setLsItems(
                lsItems.map((stampItem, i) =>
                  i === index
                    ? {
                        ...stampItem,
                        quantity: parseInt(text),
                      }
                    : stampItem,
                ),
              );
            }}
            defaultValue={lsItems[index].quantity}
          />
        </View>

        <View style={{width: '40%', marginHorizontal: 5}}>
          {/* <Text style={{ fontWeight: '500', fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}> */}
          {/* {selectedVariationItems}Chicken Soup */}
          {/* {renderProducts()} */}
          {/* <DropDownPicker
                  containerStyle={{ height: 40 }}
                  arrowColor={'black'}
                  arrowSize={20}
                  arrowStyle={{ fontWeight: 'bold' }}
                  labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, }}
                  style={{ width: 200, paddingVertical: 0, borderRadius: 10 }}
                  placeholderStyle={{ color: Colors.fieldtTxtColor, alignItems: 'center', justifyContent: 'center', fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                  placeholder={"Products"}
                  items={variationItemsDropdownList}
                  itemStyle={{ justifyContent: 'flex-start', marginLeft: 5, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, zIndex: 1 }}
                  customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                  onChangeItem={(value) =>{
                    setSelectedVariationItems(
                      selectedVariationItems.map((varItem, i) =>
                      i === index ? {
                          ...varItem,
                          noOfStamp: '',
                          productName: '',
                          quantity: 0,
                      } : varItem)
                      )
                    }
                  }
                  //multiple={true}
                  zIndex={1000}
                  searchable={true}
                  dropDownMaxHeight={190}
                  dropDownStyle={{ width: 200, height: 190, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1, zIndex: 1}}
                /> */}
          {/* </Text>  */}
          {/* <View style={{
                              width: 200, 
                              //paddingLeft: 10, 
                              //alignSelf: 'center', 
                              height: switchMerchant ? 35 : 40, 
                              backgroundColor: Colors.fieldtBgColor, 
                              borderRadius: 5, 
                              borderWidth: 1, 
                              borderColor: '#E5E5E5',
                              justifyContent: 'center',
                              //opacity: 0,
                            }}>
          <RNPickerSelect
                  useNativeAndroidPickerStyle={false}
                  style={{
                    inputIOS: { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, paddingVertical: 5, color: 'black' },
                    inputAndroid: { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, paddingVertical: 5, color: 'black' },
                    inputAndroidContainer: {
                      //backgroundColor: 'red',
                      width: '100%',
                    }
                  }}
                  //contentContainerStyle={{ fontSize: switchMerchant ? 10 : 14, }}
                  items={variationItemsDropdownList}
                  value={selectedVariationItems}
                  //placeholder='Select a Product'
                  onValueChange={(value) =>
                    setSelectedVariationItems(value)
                  }
                />
</View> */}
        </View>

        <View
          style={{
            width: switchMerchant ? '10%' : '19%',
            alignItems: 'baseline',
          }}>
          <TouchableOpacity
            style={{
              marginLeft: 10,
              alignSelf: switchMerchant ? 'flex-start' : 'flex-end',
            }}
            onPress={() => {
              setLsItems([
                ...lsItems.slice(0, index),
                ...lsItems.slice(index + 1),
              ]);
            }}>
            <Feather name="trash-2" size={20} color="#eb3446" />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  //////////////////////////////////////////////////////////////////
  //Render Start Here
  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={0} />
      </View>

      <View style={{ height: windowHeight, flex: 9 }}>
      <ScrollView
        scrollEnabled={switchMerchant}
        horizontal={true}
        nestedScrollEnabled={true}>
          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={stampItemsModal}
            transparent={true}
            animationType={'slide'}>
            <View style={styles.modalContainer}>
              <View style={[styles.modalView, {}]}>
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => {
                    setStampItemsModal(false);
                  }}>
                  <AntDesign
                    name="closecircle"
                    size={25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View style={{}}>
                  <Text
                    style={{
                      fontFamily: 'NunitoSans-Bold',
                      fontSize: switchMerchant ? 10 : 16,
                      fontWeight: '600',
                      marginBottom: 0,
                    }}>
                    Add Stamp Items
                  </Text>
                </View>
                <View
                  style={{
                    borderColor: '#E5E5E5',
                    borderWidth: 1,
                    marginVertical: 15,
                  }}
                />
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      The
                    </Text>
                    <View style={{flexDirection: 'row', marginLeft: 6}}>
                      <TextInput
                        placeholder={'0'}
                        placeholderStyle={{
                          fontFamily: 'NunitoSans-Regular',
                          fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                        onChangeText={(text) => {
                          if (text == 1) {
                            setOrdinals('st');
                          } else if (text == 2) {
                            setOrdinals('nd');
                          } else if (text == 3) {
                            setOrdinals('rd');
                          } else {
                            setOrdinals('th');
                          }
                          setNumberOfStamp(text);
                        }}
                        style={{
                          borderRadius: 5,
                          borderColor: '#E5E5E5',
                          borderWidth: 1,
                          width: 40,
                          height: 40,
                          textAlign: 'center',
                        }}
                      />
                      <Text
                        style={{
                          fontFamily: 'NunitoSans-Regular',
                          fontSize: switchMerchant ? 10 : 14,
                          marginLeft: 1,
                          marginRight: 5,
                        }}>
                        {ordinals}
                      </Text>
                    </View>

                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      Stamp
                    </Text>
                  </View>

                  <View style={{marginHorizontal: 6}}>
                    <Text
                      style={{
                        fontWeight: '500',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}>
                      Gets
                    </Text>
                  </View>

                  <View style={{marginRight: 6}}>
                    <TextInput
                      style={{
                        borderRadius: 5,
                        borderColor: '#E5E5E5',
                        borderWidth: 1,
                        width: 45,
                        height: 40,
                        textAlign: 'center',
                      }}
                      placeholder={'0'}
                      placeholderStyle={{
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}
                      placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                      onChangeText={(text) => {
                        setQuantityGet(text);
                      }}
                      value={quantityGet}
                    />
                  </View>

                  <View style={{marginLeft: 6}}>
                    <Picker
                      containerStyle={{height: 40}}
                      arrowColor={'black'}
                      arrowSize={20}
                      arrowStyle={{fontWeight: 'bold'}}
                      labelStyle={{
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}
                      style={{width: 200, paddingVertical: 0, borderRadius: 10}}
                      placeholderStyle={{
                        color: Colors.fieldtTxtColor,
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}
                      placeholder={'Products'}
                      itemStyle={{
                        justifyContent: 'flex-start',
                        marginLeft: 5,
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}
                      customTickIcon={(press) => (
                        <Ionicon
                          name={'md-checkbox'}
                          color={
                            press ? Colors.fieldtBgColor : Colors.primaryColor
                          }
                          size={25}
                        />
                      )}
                      onValueChange={(items) => setSelectedVariationItems(items)}
                      //multiple={true}
                      searchable={true}
                      dropDownMaxHeight={190}
                      dropDownStyle={{
                        width: 200,
                        height: 190,
                        backgroundColor: Colors.fieldtBgColor,
                        borderRadius: 10,
                        borderWidth: 1,
                        zIndex: 1,
                      }}
                    >
                      {variationItemsDropdownList.map((value, index) => {
                        return( 
                          <Picker.Item
                          key={index}
                          value={value.value}
                          label={value.label}
                          />
                        )
                      })}
                    </Picker>
                  </View>
                </View>
                <View
                  style={{alignItems: 'flex-end', marginTop: 30, zIndex: -1}}>
                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      backgroundColor: '#4E9F7D',
                      borderRadius: 5,
                      width: switchMerchant ? 100 : 120,
                      paddingHorizontal: 10,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,
                    }}>
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      {isLoading ? 'Loading...' : 'Add'}
                    </Text>

                    {/* {isLoading ?
                  <ActivityIndicator style={{
                    marginLeft: 5,
                  }} color={Colors.primaryColor} size={'small'} />
                  : <></>
                } */}
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>
          {addLoyaltyStamp === ADD_LOYALTY_STAMP_SCREEN.LOYALTY_STAMP_LIST ? (
            <>
              <View style={{paddingTop: 30}}>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: switchMerchant ? 0 : 15,
                    marginBottom: switchMerchant ? 30 : 10,
                    marginHorizontal: 30,
                    marginTop: 0,
                    //backgroundColor: 'red',
                    height: '10%',
                    // borderBottomWidth: StyleSheet.hairlineWidth,
                    borderBottomColor: '#c4c4c4',
                    //borderRadius: 5,
                    //width: '100%',
                    //paddingHorizontal: 10,
                    paddingRight: switchMerchant
                      ? 0
                      : windowWidth * 0.002,
                    //paddingBottom: switchMerchant ? 10 : 0,
                  }}>
                  <View style={{justifyContent: 'center'}}>
                    {/* fontSize: 26, fontFamily: 'NunitoSans-Bold' */}
                    <Text
                      style={{
                        fontSize: switchMerchant ? 20 : 26,
                        marginRight: 30,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                      Summary
                      {/* List of Created Redemption Packages */}
                    </Text>
                  </View>

                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection: 'row'}}>
                      {/* {isTablet && ( */}
                      <View
                        style={{
                          alignSelf: 'flex-start',
                          marginRight: switchMerchant ? 18 : 10,
                        }}>
                        <TouchableOpacity
                          style={{
                            justifyContent: 'center',
                            flexDirection: 'row',
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: '#4E9F7D',
                            borderRadius: 5,
                            //width: 160,
                            paddingHorizontal: 10,
                            height: 40,
                            alignItems: 'center',
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                          }}
                          onPress={() => {
                            CommonStore.update((s) => {
                              s.selectedLoyaltyStampEdit = null;
                            });

                            setAddLoyaltyStamp(
                              ADD_LOYALTY_STAMP_SCREEN.ADD_LOYALTY_STAMP,
                            );
                          }}>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignContent: 'center',
                              alignItems: 'center',
                            }}>
                            <AntDesign
                              name="pluscircle"
                              size={switchMerchant ? 11 : 20}
                              style={{
                                color: Colors.whiteColor,
                                alignSelf: 'center',
                              }}
                            />
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                marginLeft: 5,
                                fontSize: switchMerchant ? 10 : 16,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                              {/* Set Credit */}
                              LOYALTY STAMP
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                      {/* )} */}
                    </View>

                    <View style={{flexDirection: 'row'}}>
                      <View
                        style={[
                          {
                            height: 40,
                          },
                        ]}>
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                          }}>
                          <View
                            style={{
                              backgroundColor: 'white',
                              borderRadius: 5,
                              flexDirection: 'row',
                              width: 250,
                              height: 40,
                              alignContent: 'center',
                              alignItems: 'center',
                              shadowColor: '#000',
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 3,
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                            }}>
                            <Icon
                              name="search"
                              size={switchMerchant ? 13 : 25}
                              color={Colors.primaryColor}
                              style={{marginLeft: 15}}
                            />
                            <View style={{flex: 3}}>
                              <TextInput
                                underlineColorAndroid={Colors.whiteColor}
                                style={{
                                  width: 220,
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: 'NunitoSans-Regular',
                                  paddingLeft: 5,
                                  height: 45,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: '#a9a9a9',
                                })}
                                clearButtonMode="while-editing"
                                placeholder=" Search"
                                /* onChangeText={(text) => {
                                setSearch(text);
                              }}
                              defaultValue={search} */
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    width: switchMerchant
                      ? windowWidth * 0.87
                      : windowWidth * 0.87,
                    height:
                      Platform.OS === 'ios'
                        ? windowHeight * 0.8
                        : windowHeight * 0.7,
                    marginTop: switchMerchant ? 5 : 10,
                    marginHorizontal: 30,
                    marginBottom: 30,
                    alignSelf: 'center',
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                    borderRadius: 5,
                  }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginTop: 20,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      borderBottomColor: '#c4c4c4',
                      padding: 10,
                    }}>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                        color: 'black',
                        fontWeight: '600',
                        width: '26%',
                        marginLeft: 5,
                      }}>
                      Stamp Name
                    </Text>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                        color: 'black',
                        fontWeight: '600',
                        width: '18%',
                        marginHorizontal: 5,
                      }}>
                      Total Stamp
                    </Text>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                        color: 'black',
                        fontWeight: '600',
                        width: '26%',
                        marginHorizontal: 5,
                      }}>
                      Available Outlet(s)
                    </Text>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                        color: 'black',
                        fontWeight: '600',
                        width: '21%',
                        marginLeft: 5,
                      }}>
                      Durations
                    </Text>
                  </View>

                  <FlatList
                    data={loyaltyStamps}
                    renderItem={renderLoyaltyStamp}
                    nestedScrollEnabled={true}
                    keyExtractor={(item, index) => String(index)}
                    style={{}}
                  />
                </View>
              </View>
            </>
          ) : null}

          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showDateTimePicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.2,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      {pickDate === 'startDate' ? 'Start Date Picker' : 'End Date Picker'}
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowDateTimePicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 18,
                      textAlign: "right",
                      marginRight: 10,
                    }}
                  >
                    Date:
                  </Text>
                  <View
                    style={{
                      borderWidth: 0.5,
                      borderRadius: 5,
                      width: 100,
                    }}
                  >
                    <DatePicker
                      // isVisible={showDateTimePicker}
                      // mode={'date'}
                      selected={rev_PickDate.toDate()}
                      onChange={(date) => {
                        if (pickDate === 'startDate') {
                          setStartDate(moment(date));
                          setRev_PickDate(moment(date))
                        } else {
                          setEndDate(moment(date));
                          setRev_PickDate(moment(date))
                        }
                        // setShowDateTimePicker(false);
                      }}
                      // onCancel={() => {
                      //   setShowDateTimePicker(false);
                      // }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Modal>
      
          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showDateTimePicker1}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.4,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      {pickTime === 'startTime' ? 'Start Time Picker' : 'End Time Picker'}
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowDateTimePicker1(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TimeKeeper
                    // isVisible={showDateTimePicker1}
                    // mode={'time'}
                    time={moment(rev_PickTime).format("hh:mm a")}
                    onChange={(time) => {
                      if (pickTime === 'startTime') {
                        if(rev_PickTime === null){
                          setRev_PickTime(moment(`${moment(Date.now()).format('MM/DD/YYYY')} ${time.formatted12}`));
                        }else if(moment(rev_PickTime).format('MM/DD/YYYY') === moment(Date.now()).format('MM/DD/YYYY')){
                          setRev_PickTime(moment(`${moment(rev_PickTime).format('MM/DD/YYYY')} ${time.formatted12}`))
                        }else{
                          setRev_PickTime(moment(`${moment(rev_PickTime).format('MM/DD/YYYY')} ${time.formatted12}`));
                        }
                        setStartTime(time);
                      } else {
                        if(rev_PickTime === null){
                          setRev_PickTime(moment(`${moment(Date.now()).format('MM/DD/YYYY')} ${time.formatted12}`));
                        }else if(moment(rev_PickTime).format('MM/DD/YYYY') === moment(Date.now()).format('MM/DD/YYYY')){
                          setRev_PickTime(moment(`${moment(rev_PickTime).format('MM/DD/YYYY')} ${time.formatted12}`))
                        }else{
                          setRev_PickTime(moment(`${moment(rev_PickTime).format('MM/DD/YYYY')} ${time.formatted12}`));
                        }
                        setEndTime(time);
                      }
                      // setShowDateTimePicker1(false);
                    }}
                    onDoneClick={() => {
                      setShowDateTimePicker1(false);
                    }}
                  />
                </View>
              </View>
            </View>
          </Modal>

          {addLoyaltyStamp === ADD_LOYALTY_STAMP_SCREEN.ADD_LOYALTY_STAMP ? (
            <>
              <View style={{paddingTop: switchMerchant ? 30 : 0}}>
                <View
                  style={{
                    width: windowWidth * 0.886,
                    marginTop: switchMerchant ? -10 : 10,
                    alignSelf: 'center',
                  }}>
                  <TouchableOpacity
                    style={{
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                    onPress={() => {
                      setAddLoyaltyStamp(
                        ADD_LOYALTY_STAMP_SCREEN.LOYALTY_STAMP_LIST,
                      );
                    }}>
                    <Icon1
                      name="chevron-left"
                      size={switchMerchant ? 20 : 30}
                      color={Colors.primaryColor}
                    />
                    <Text
                      style={{
                        fontFamily: 'NunitoSans-Bold',
                        fontSize: switchMerchant ? 15 : 17,
                        color: Colors.primaryColor,
                        marginBottom: Platform.OS === 'ios' ? 0 : 2,
                      }}>
                      Back
                    </Text>
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    width: switchMerchant
                      ? windowWidth * 0.87
                      : windowWidth * 0.87,
                    height:
                      Platform.OS === 'ios'
                        ? windowHeight * 0.8
                        : windowHeight * 0.7,
                    marginTop: switchMerchant ? 5 : 10,
                    marginHorizontal: 30,
                    marginBottom: 30,
                    alignSelf: 'center',
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                    borderRadius: 5,
                  }}>
                  <ScrollView
                    nestedScrollEnabled={true}
                    contentContainerStyle={{
                      //paddingBottom: windowHeight * 0.25,
                      //paddingBottom: 50,
                      // backgroundColor: 'red',
                      // top: Platform.OS === 'ios' ? -keyboardHeight * 0.3 : 0,
                    }}>
                    <View
                      style={{
                        marginTop: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        // width: switchMerchant ? windowWidth * 0.8 : windowWidth * 0.87,
                        // backgroundColor: Colors.whiteColor,
                        // borderRadius: 5,
                        // shadowColor: '#000',
                        // shadowOffset: {
                        //   width: 0,
                        //   height: 2,
                        // },
                        // shadowOpacity: 0.22,
                        // shadowRadius: 3.22,
                        // elevation: 3,
                        // paddingHorizontal : -30
                      }}>
                      <View
                        style={{
                          justifyContent: 'flex-end',
                          padding: 10,
                          paddingVertical: 15,
                          flexDirection: 'row',
                          width: switchMerchant
                            ? windowWidth * 0.87
                            : windowWidth * 0.87,
                        }}>
                        <TouchableOpacity
                          style={{
                            justifyContent: 'center',
                            flexDirection: 'row',
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: '#4E9F7D',
                            borderRadius: 5,
                            width: switchMerchant ? 120 : 160,
                            paddingHorizontal: 10,
                            height: 40,
                            alignItems: 'center',
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                          }}
                          disabled={loading}
                          onPress={() => {
                            if (selectedLoyaltyStampEdit) {
                              updateLoyaltyStamp();
                            } else {
                              createLoyaltyStamp();
                            }
                          }}>
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: switchMerchant ? 10 : 16,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                            {loading ? 'LOADING...' : 'SAVE'}
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          borderBottomWidth: StyleSheet.hairlineWidth,
                          width: windowWidth * 0.87,
                        }}>
                        <View>
                          <Text
                            style={{
                              alignSelf: 'center',
                              marginTop: 30,
                              fontSize: switchMerchant ? 20 : 40,
                              fontWeight: 'bold',
                            }}>
                            {selectedLoyaltyStampEdit
                              ? selectedLoyaltyStampEdit.name
                              : 'Add Loyalty Stamp'}
                          </Text>

                          <Text
                            style={{
                              alignSelf: 'center',
                              fontSize: switchMerchant ? 10 : 16,
                              color: '#adadad',
                            }}>
                            Fill in the information
                          </Text>
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            marginTop: 40,
                            width: '90%',
                            alignSelf: 'center',
                            marginLeft:
                              windowWidth *
                              Styles.sideBarWidth *
                              0.5,
                          }}>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              width: '50%',
                            }}>
                            <Text
                              style={{
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: switchMerchant ? 10 : 14,
                                width: '33%',
                                textAlign: 'left',
                              }}>
                              Stamp Name
                            </Text>
                            <TextInput
                              placeholder="Name"
                              placeholderTextColor={Platform.select({
                                ios: '#a9a9a9',
                              })}
                              placeholderStyle={{
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: 14,
                              }}
                              style={[
                                {
                                  backgroundColor: Colors.fieldtBgColor,
                                  width: 250,
                                  height: 40,
                                  borderRadius: 5,
                                  padding: 5,
                                  marginVertical: 5,
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  paddingLeft: 10,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 10,
                                      width: 200,
                                      height: 35,
                                    }
                                  : {},
                              ]}
                              onChangeText={(text) => {
                                setStampName(text);
                              }}
                              defaultValue={stampName}
                            />
                          </View>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              width: '50%',
                            }}>
                            <Text
                              style={{
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: switchMerchant ? 10 : 14,
                                width: '35%',
                                textAlign: 'left',
                              }}>
                              Total Stamp
                            </Text>
                            <TextInput
                              placeholder={'0'}
                              placeholderStyle={{
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholderTextColor={Platform.select({
                                ios: '#a9a9a9',
                              })}
                              style={[
                                {
                                  backgroundColor: Colors.fieldtBgColor,
                                  // width: 60,
                                  width: 250,
                                  height: 40,
                                  borderRadius: 5,
                                  padding: 5,
                                  marginVertical: 5,
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                  paddingLeft: 10,
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 10,
                                      width: 200,
                                      height: 35,
                                    }
                                  : {},
                              ]}
                              keyboardType={'number-pad'}
                              onChangeText={(text) => {
                                setTotalNumberOfStamp(text);
                              }}
                              defaultValue={totalNumberOfStamp}
                            />
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            marginTop: 30,
                            width: '90%',
                            alignSelf: 'center',
                            marginLeft:
                              windowWidth *
                              Styles.sideBarWidth *
                              0.5,
                              zIndex: 1000,
                          }}>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              width: '50%',
                            }}>
                            <Text
                              style={{
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: switchMerchant ? 10 : 14,
                                width: '33%',
                                textAlign: 'left',
                              }}>
                              Target Segment(s)
                            </Text>
                            <Multiselect
                              style={{
                                width: 250,
                                paddingVertical: 0,
                                borderRadius: 10,
                              }}
                              //zIndex={10000 + lsItemsBuy.length - index}
                              options={CRM_SEGMENT_DROPDOWN_LIST.concat(
                                crmSegmentsDropdownList,
                              )}
                              onChange={(items) => {
                                setTargetUsers(items);
                              }}
                              onRemove={(items) => {
                                setTargetUsers(items);
                              }}
                              selectedValues={targetUsers}
                              displayValue='label'
                            />
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            marginTop: 40,
                            width: '90%',
                            alignSelf: 'center',
                            marginLeft:
                              windowWidth *
                              Styles.sideBarWidth *
                              0.5,
                          }}>
                          <View
                            style={{
                              flexDirection: 'row',
                              flex: 1,
                              alignItems: 'center',
                              width: '50%',
                            }}>
                            <Text
                              style={{
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: switchMerchant ? 10 : 14,
                                width: '33%',
                                textAlign: 'left',
                              }}>
                              Start Date
                            </Text>
                            <View
                              style={
                                {
                                  // height: 35,
                                  // paddingHorizontal: 10,
                                  // width: 160,
                                  // flexDirection: 'row',
                                  // alignItems: 'center',
                                  // justifyContent: "center",
                                  //paddingLeft: 15,
                                  // borderWidth: 1,
                                  // borderColor: '#E5E5E5',
                                  // borderRadius: 5,
                                }
                              }>
                              <TouchableOpacity
                                onPress={() => {
                                  setPickDate('startDate');
                                  setShowDateTimePicker(true);
                                }}
                                style={[
                                  {
                                    // height: 50,
                                    height: switchMerchant ? 35 : 40,
                                    paddingHorizontal: 20,
                                    backgroundColor: Colors.fieldtBgColor,
                                    // width: 160,
                                    width: 250,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    alignContent: 'center',
                                    borderColor: '#E5E5E5',
                                    borderWidth: 1,
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: switchMerchant ? 10 : 14,
                                    borderRadius: 5,
                                    //marginTop: 10,
                                  },
                                  switchMerchant
                                    ? {
                                        width: 200,
                                        height: 40,
                                      }
                                    : {},
                                ]}>
                                <View
                                  style={{alignSelf: 'center'}}
                                  onPress={() => {
                                    // setState({
                                    //   pickerMode: 'date',
                                    //   showDateTimePicker: true,
                                    // });
                                  }}>
                                  <GCalendar
                                    width={switchMerchant ? 15 : 20}
                                    height={switchMerchant ? 15 : 20}
                                  />
                                </View>
                                <Text
                                  style={[
                                    {
                                      // marginRight: 0,
                                      marginRight: '20%',
                                      //color: '#B6B6B6',
                                      fontSize: 14,
                                      fontFamily: 'NunitoSans-Regular',
                                      fontVariant: ['tabular-nums'],
                                    },
                                    switchMerchant
                                      ? {
                                          fontSize: 10,
                                        }
                                      : {},
                                  ]}>
                                  {startDate
                                    ? moment(startDate).format('DD MMM YYYY')
                                    : 'Start Date'}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: 'row',
                              flex: 1,
                              alignItems: 'center',
                              width: '50%',
                            }}>
                            <Text
                              style={{
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: switchMerchant ? 10 : 14,
                                width: '35%',
                                textAlign: 'left',
                              }}>
                              End Date
                            </Text>
                            <View
                              style={
                                {
                                  // height: 35,
                                  // paddingHorizontal: 10,
                                  // width: 160,
                                  // flexDirection: 'row',
                                  // alignItems: 'center',
                                  // justifyContent: "center",
                                  // paddingLeft: 15,
                                  // borderWidth: 1,
                                  // borderColor: '#E5E5E5',
                                  // borderRadius: 5,
                                }
                              }>
                              <TouchableOpacity
                                onPress={() => {
                                  setPickDate('endDate');
                                  setShowDateTimePicker(true);
                                }}
                                style={[
                                  {
                                    // height: 50,
                                    height: switchMerchant ? 35 : 40,
                                    paddingHorizontal: 20,
                                    backgroundColor: Colors.fieldtBgColor,
                                    //marginBottom: 20,
                                    // width: 160,
                                    width: 250,
                                    //marginHorizontal: 10,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    alignContent: 'center',
                                    borderColor: '#E5E5E5',
                                    borderWidth: 1,
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: switchMerchant ? 10 : 14,
                                    borderRadius: 5,
                                    //marginTop: 10,
                                  },
                                  switchMerchant
                                    ? {
                                        width: 200,
                                        height: 40,
                                      }
                                    : {},
                                ]}>
                                <View
                                  style={{alignSelf: 'center'}}
                                  onPress={() => {
                                    // setState({
                                    //   pickerMode: 'date',
                                    //   showDateTimePicker: true,
                                    // });
                                  }}>
                                  <GCalendar
                                    width={switchMerchant ? 15 : 20}
                                    height={switchMerchant ? 15 : 20}
                                  />
                                </View>
                                <Text
                                  style={[
                                    {
                                      // marginRight: 0,
                                      marginRight: '20%',
                                      //color: '#B6B6B6',
                                      fontSize: 14,
                                      fontFamily: 'NunitoSans-Regular',
                                      fontVariant: ['tabular-nums'],
                                    },
                                    switchMerchant
                                      ? {
                                          fontSize: 10,
                                        }
                                      : {},
                                  ]}>
                                  {endDate
                                    ? moment(endDate).format('DD MMM YYYY')
                                    : 'End Date'}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                            marginTop: 40,
                            width: '90%',
                            alignSelf: 'center',
                            marginLeft:
                              windowWidth *
                              Styles.sideBarWidth *
                              0.5,
                          }}>
                          <View
                            style={{
                              flexDirection: 'row',
                              flex: 1,
                              alignItems: 'center',
                              width: '50%',
                            }}>
                            <Text
                              style={{
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: switchMerchant ? 10 : 14,
                                width: '33%',
                                textAlign: 'left',
                              }}>
                              Start Time
                            </Text>
                            <View
                              style={
                                {
                                  // height: 35,
                                  // paddingHorizontal: 10,
                                  // width: 160,
                                  // flexDirection: 'row',
                                  // alignItems: 'center',
                                  // justifyContent: "center",
                                  // paddingLeft: 15,
                                  // borderWidth: 1,
                                  // borderColor: '#E5E5E5',
                                  // borderRadius: 5,
                                }
                              }>
                              <TouchableOpacity
                                onPress={() => {
                                  setPickTime('startTime');
                                  setShowDateTimePicker1(true);
                                }}
                                style={[
                                  {
                                    // height: 50,
                                    height: switchMerchant ? 35 : 40,
                                    paddingHorizontal: 20,
                                    backgroundColor: Colors.fieldtBgColor,
                                    //marginBottom: 20,
                                    // width: 160,
                                    width: 250,
                                    //marginHorizontal: 10,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    alignContent: 'center',
                                    borderColor: '#E5E5E5',
                                    borderWidth: 1,
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: switchMerchant ? 10 : 14,
                                    borderRadius: 5,
                                    //marginTop: 10,
                                  },
                                  switchMerchant
                                    ? {
                                        width: 200,
                                        height: 40,
                                      }
                                    : {},
                                ]}>
                                <Clock
                                  size={switchMerchant ? 20 : 25}
                                  color={Colors.primaryColor}
                                  style={{marginLeft: 0}}
                                />

                                <Text
                                  style={[
                                    {
                                      // marginRight: 0,
                                      marginRight: '20%',
                                      //color: '#B6B6B6',
                                      fontSize: 14,
                                      fontFamily: 'NunitoSans-Regular',
                                      fontVariant: ['tabular-nums'],
                                    },
                                    switchMerchant
                                      ? {
                                          fontSize: 10,
                                        }
                                      : {},
                                  ]}>
                                  {startTime
                                    ? moment(startTime).format('hh:mmA')
                                    : 'Start Time'}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: 'row',
                              flex: 1,
                              alignItems: 'center',
                              width: '50%',
                            }}>
                            <Text
                              style={{
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: switchMerchant ? 10 : 14,
                                width: '35%',
                                textAlign: 'left',
                              }}>
                              End Time
                            </Text>
                            <View
                              style={
                                {
                                  // height: 35,
                                  // paddingHorizontal: 10,
                                  // width: 160,
                                  // flexDirection: 'row',
                                  // alignItems: 'center',
                                  // justifyContent: "center",
                                  // paddingLeft: 15,
                                  // borderWidth: 1,
                                  // borderColor: '#E5E5E5',
                                  // borderRadius: 5,
                                }
                              }>
                              <TouchableOpacity
                                onPress={() => {
                                  setPickTime('endTime');
                                  setShowDateTimePicker1(true);
                                }}
                                style={[
                                  {
                                    // height: 50,
                                    height: switchMerchant ? 35 : 40,
                                    paddingHorizontal: 20,
                                    backgroundColor: Colors.fieldtBgColor,
                                    //marginBottom: 20,
                                    // width: 160,
                                    width: 250,
                                    //marginHorizontal: 10,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    alignContent: 'center',
                                    borderColor: '#E5E5E5',
                                    borderWidth: 1,
                                    fontFamily: 'NunitoSans-Regular',
                                    fontSize: switchMerchant ? 10 : 14,
                                    borderRadius: 5,
                                    //marginTop: 10,
                                  },
                                  switchMerchant
                                    ? {
                                        width: 200,
                                        height: 35,
                                      }
                                    : {},
                                ]}>
                                <Clock
                                  size={switchMerchant ? 20 : 25}
                                  color={Colors.primaryColor}
                                  style={{marginLeft: 0}}
                                />

                                <Text
                                  style={[
                                    {
                                      // marginRight: 0,
                                      marginRight: '20%',
                                      //color: '#B6B6B6',
                                      fontSize: 14,
                                      fontFamily: 'NunitoSans-Regular',
                                      fontVariant: ['tabular-nums'],
                                    },
                                    switchMerchant
                                      ? {
                                          fontSize: 10,
                                        }
                                      : {},
                                  ]}>
                                  {endTime
                                    ? moment(endTime).format('hh:mmA')
                                    : 'End Time'}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: 'row',
                            marginTop: 40,
                            marginBottom: 40,
                            width: '90%',
                            alignSelf: 'center',
                            marginLeft:
                              windowWidth *
                              Styles.sideBarWidth *
                              0.5,
                          }}>
                          <View style={{flexDirection: 'row', width: '50%'}}>
                            <Text
                              style={{
                                fontFamily: 'NunitoSans-Bold',
                                fontSize: switchMerchant ? 10 : 14,
                                width: '33%',
                                textAlign: 'left',
                              }}>
                              Terms & Conditions
                            </Text>
                            <TextInput
                              //style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 100, width: 400, paddingLeft: 5 }}
                              style={[
                                {
                                  borderColor: '#E5E5E5',
                                  borderRadius: 5,
                                  borderWidth: 1,
                                  backgroundColor: Colors.fieldtBgColor,
                                  height: 100,
                                  width: switchMerchant ? 350 : '150%',
                                  //padding: 5,
                                  paddingLeft: 10,
                                  textAlignVertical: 'top',
                                  fontFamily: 'NunitoSans-Regular',
                                  fontSize: 14,
                                  marginTop: -10,
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 10,
                                    }
                                  : {},
                              ]}
                              placeholderStyle={{
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder={'Terms & Conditions'}
                              multiline={true}
                              placeholderTextColor={Platform.select({
                                ios: '#a9a9a9',
                              })}
                              defaultValue={terms}
                              onChangeText={(text) => {
                                setTerms(text);
                              }}
                            />
                          </View>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 30,
                        //marginBottom: 20
                      }}>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 20 : 25,
                          fontWeight: 'bold',
                          marginLeft: '42%',
                        }}>
                        Product Eligible
                      </Text>
                    </View>

                    <View
                      style={
                        switchMerchant
                          ? {
                              //marginBottom: 40,
                              minHeight: windowHeight * 0.9,
                            }
                          : {
                              //marginBottom: 40,
                              minHeight:
                                Platform.OS === 'ios'
                                  ? windowHeight * 0.3
                                  : windowHeight * 0.35,
                            }
                      }>
                      {/* <View
                        style={{
                          backgroundColor: '#ffffff',
                          flexDirection: 'row',
                          paddingVertical: 10,
                          paddingHorizontal: 20,
                          marginTop: 10,
                          //borderBottomWidth: StyleSheet.hairlineWidth,
                          marginLeft: '3.5%'
                        }}>
                        <Text style={{ width: switchMerchant ? '15%' : '13%', marginRight: 5, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                          No. of Stamp
                        </Text>
                        <Text style={{ width: switchMerchant ? '15%' : '6.2%', marginHorizontal: 5, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                        </Text>
                        <Text style={{ width: switchMerchant ? '15%' : '14.5%', marginHorizontal: 5, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                          Quantity
                        </Text>
                        <Text style={{ width: switchMerchant ? '30%' : '19%', marginHorizontal: 5, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                          Items
                        </Text>
                      </View> */}
                      {/* <View style={{ borderBottomWidth: StyleSheet.hairlineWidth, }} /> */}
                      <View
                        style={{
                          borderColor: '#E5E5E5',
                          borderWidth: 1,
                          // width: 1100,
                          padding: switchMerchant ? 0 : 16,
                          paddingTop: switchMerchant ? 16 : 0,
                          borderRadius: 10,
                          marginTop: 20,
                          marginHorizontal: switchMerchant ? 20 : 25,
                          paddingBottom: windowWidth * 0.1,
                        }}>
                        {renderProductEligible()}
                      </View>
                      {/* <FlatList
                        data={lsItems}
                        extraData={lsItems}
                        renderItem={renderItems}
                        keyExtractor={(item, index) => String(index)}
                        //contentContainerStyle={{ borderRadius: 5 }}
                        style={{ zIndex: 2 }}
                      /> */}

                      {/* contentContainerStyle={{ borderRadius: 5, paddingBottom: 40}}
                        style={{ zIndex: 2 }}
                      /> */}

                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          padding: 10,
                          zIndex: -1,
                        }}>
                        <TouchableOpacity
                          style={{
                            paddingVertical: 5,
                            paddingHorizontal: 20,
                            flexDirection: 'row',
                            color: '#4cd964',
                            textAlign: 'center',
                            alignSelf: 'flex-end',
                            marginRight: 20,
                            //marginTop: 10

                            // justifyContent:'flex-start',
                            // // marginRight: 20,
                            // marginTop: 10,

                            // borderColor: Colors.primaryColor,
                            // borderRadius: 5,
                            // borderWidth: 1,
                            // padding: 5,
                          }}
                          onPress={() => {
                            //setStampItemsModal(true)
                            addProduct();
                            // height : 810
                            // width : 1080
                          }}>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}>
                            <Icon2
                              name="plus-circle"
                              size={switchMerchant ? 15 : 20}
                              color={Colors.primaryColor}
                            />
                            <Text
                              style={{
                                marginLeft: 5,
                                color: Colors.primaryColor,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}>
                              Add Product
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{borderBottomWidth: StyleSheet.hairlineWidth}}
                    />

                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: 30,
                        //marginBottom: 20
                      }}>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 20 : 25,
                          fontWeight: 'bold',
                          marginLeft: '42%',
                        }}>
                        Stamp Items
                      </Text>
                    </View>

                    <View
                      style={
                        switchMerchant
                          ? {
                              //marginBottom: 40,
                              minHeight: windowHeight * 0.9,
                            }
                          : {
                              //marginBottom: 40,
                              minHeight:
                                Platform.OS === 'ios'
                                  ? windowHeight * 0.3
                                  : windowHeight * 0.35,
                            }
                      }>
                      {/* <View
                        style={{
                          backgroundColor: '#ffffff',
                          flexDirection: 'row',
                          paddingVertical: 10,
                          paddingHorizontal: 20,
                          marginTop: 10,
                          //borderBottomWidth: StyleSheet.hairlineWidth,
                          marginLeft: '3.5%'
                        }}> */}
                      {/* <Text style={{ width: switchMerchant ? '15%' : '13%', marginRight: 5, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                          No. of Stamp
                        </Text>
                        <Text style={{ width: switchMerchant ? '15%' : '6.2%', marginHorizontal: 5, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                        </Text>
                        <Text style={{ width: switchMerchant ? '15%' : '14.5%', marginHorizontal: 5, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}> */}
                      {/* <Text style={{ width: switchMerchant ? '10%' : '7%', marginHorizontal: 5, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                  </Text>
                  <Text style={{ width: switchMerchant ? '20%' : '15%', marginHorizontal: 5, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}> */}
                      {/* Quantity
                        </Text>
                        <Text style={{ width: switchMerchant ? '30%' : '19%', marginHorizontal: 5, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                          Items
                        </Text> */}
                      {/* </View> */}
                      <View
                        style={{
                          borderColor: '#E5E5E5',
                          borderWidth: 1,
                          // width: 1100,
                          padding: switchMerchant ? 0 : 16,
                          paddingTop: switchMerchant ? 16 : 0,
                          borderRadius: 10,
                          marginTop: 20,
                          marginHorizontal: switchMerchant ? 20 : 25,
                          paddingBottom: windowWidth * 0.1,
                        }}>
                        {renderStampItems()}
                      </View>
                      {/* <FlatList
                        data={lsItems}
                        extraData={lsItems}
                        renderItem={renderItems}
                        keyExtractor={(item, index) => String(index)}
                        //contentContainerStyle={{ borderRadius: 5 }}
                        style={{ zIndex: 2 }}
                      /> */}

                      {/* contentContainerStyle={{ borderRadius: 5, paddingBottom: 40}}
                        style={{ zIndex: 2 }}
                      /> */}

                      <View
                        style={{
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          padding: 10,
                          zIndex: -1,
                        }}>
                        <TouchableOpacity
                          style={{
                            paddingVertical: 5,
                            paddingHorizontal: 20,
                            flexDirection: 'row',
                            color: '#4cd964',
                            textAlign: 'center',
                            alignSelf: 'flex-end',
                            marginRight: 20,
                            //marginTop: 10

                            // justifyContent:'flex-start',
                            // // marginRight: 20,
                            // marginTop: 10,

                            // borderColor: Colors.primaryColor,
                            // borderRadius: 5,
                            // borderWidth: 1,
                            // padding: 5,
                          }}
                          onPress={() => {
                            //setStampItemsModal(true)
                            addStamp();
                          }}>
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                            }}>
                            <Icon2
                              name="plus-circle"
                              size={switchMerchant ? 15 : 20}
                              color={Colors.primaryColor}
                            />
                            <Text
                              style={{
                                marginLeft: 5,
                                color: Colors.primaryColor,
                                fontFamily: 'NunitoSans-Regular',
                                fontSize: switchMerchant ? 10 : 14,
                              }}>
                              Add Stamp Item
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </View>
            </>
          ) : null}
      </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
    fontFamily: 'NunitoSans-Regular',
  },
  list: {
    backgroundColor: Colors.whiteColor,
    // width: windowWidth * 0.85,
    // height: windowHeight,
    marginTop: 40,
    marginHorizontal: 30,
    //alignSelf: 'center',
    //justifyContent: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  listItem: {
    fontFamily: 'NunitoSans-Regular',
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    // width: windowWidth * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,
    elevation: 16,
  },
  content: {
    padding: 20,
    // width: windowWidth * (1 - Styles.sideBarWidth),
    // backgroundColor: 'lightgrey',
    backgroundColor: Colors.fieldtBgColor,
  },
  textInput: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 0,
    flex: 1,
    flexDirection: 'row',
  },
  textInputLocation: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 100,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 10,
  },
  textSize: {
    fontSize: 19,
    fontFamily: 'NunitoSans-SemiBold',
  },
  merchantDisplayView: {
    flexDirection: 'row',
    flex: 1,
    marginLeft: '17%',
  },
  shiftText: {
    marginLeft: '15%',
    color: Colors.primaryColor,
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 25,
  },
  confirmBox: {
    width: '30%',
    height: '30%',
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
    elevation: 1000,
    zIndex: 1000,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalView: {
    height: 200,
    width: 500,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: 25,
    //alignItems: 'center',
    //justifyContent: 'center'
  },
});
export default LoyaltyStampScreen;
