import React, { Component, useReducer, useState, useEffect } from 'react';
import { StyleSheet, ScrollView, Image, View, Text, Alert, TouchableOpacity, Modal, Dimensions, TextInput, FlatList, KeyboardAvoidingView, ActivityIndicator, Platform, useWindowDimensions, Picker } from 'react-native';
import Colors from '../constant/Colors';
import { ReactComponent as GCalendar } from '../assets/svg/GCalendar.svg';
import Styles from '../constant/Styles';
import SideBar from './SideBar';
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Ionicons';
import Ionicon from 'react-native-vector-icons/Ionicons';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
// import DropDownPicker from 'react-native-dropdown-picker';
import Multiselect from 'multiselect-react-dropdown';
import Feather from 'react-native-vector-icons/Feather';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import moment from 'moment';
import DateTimePickerModal from "react-native-modal-datetime-picker";
import DatePicker from "react-datepicker";
// import { isTablet } from 'react-native-device-detection';
import AntDesign from "react-native-vector-icons/AntDesign";
import { MERCHANT_VOUCHER_CODE_FORMAT, MERCHANT_VOUCHER_TYPE, SEGMENT_TYPE } from '../constant/common';
import { CommonStore } from '../store/commonStore';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { OutletStore } from '../store/outletStore';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
// import RNPickerSelect from 'react-native-picker-select';
// import { useKeyboard } from '../hooks';
// import { get } from 'react-native/Libraries/Utilities/PixelRatio';
import Icon1 from 'react-native-vector-icons/FontAwesome';
import { TARGET_USER_GROUP_DROPDOWN_LIST, PROMOTION_TYPE_DROPDOWN_LIST, PROMOTION_TYPE_VARIATION, PROMOTION_TYPE_VARIATION_DROPDOWN_LIST, DATE_FORMAT_DROPDOWN_LIST } from '../constant/promotions';
import AsyncImage from '../components/asyncImage';
import { uploadImageToFirebaseStorage } from '../util/common';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { ReactComponent as Edit } from '../assets/svg/Edit.svg';
// import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { useLinkTo, useRoute } from "@react-navigation/native";
import { prefix } from '../constant/env';
import { useFilePicker } from "use-file-picker";

/////////////////////////////////////////////////////////////////////////////////////

const SettingSetCredit = props => {
  const {
    navigation,
  } = props;
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  const linkTo = useLinkTo();

  //////////////////////////////////////////////////////////////////////////////////////////////////////////

  const userName = UserStore.useState(s => s.name);
  const merchantName = MerchantStore.useState(s => s.name);
  const crmUserTags = OutletStore.useState(s => s.crmUserTags);
  const selectedPointsRedeemPackageEdit = CommonStore.useState(s => s.selectedPointsRedeemPackageEdit);
  const allOutletsCategoriesUnique = OutletStore.useState(s => s.allOutletsCategoriesUnique);
  const outletItemsUnsorted = OutletStore.useState(s => s.outletItems);

  //const [packagePhoto, setPackagePhoto] = useState('');
  const [image, setImage] = useState('');
  const [imageType, setImageType] = useState('');
  const [isImageChanged, setIsImageChanged] = useState(false);

  const [packageName, setPackageName] = useState('');

  const [limitRedemptionPerUser, setLimitRedemptionPerUser] = useState('0');
  const [limitRedemptionPerUserDateFormatValue, setLimitRedemptionPerUserDateFormatValue] = useState('0');
  const [limitRedemptionPerUserDateFormatType, setLimitRedemptionPerUserDateFormatType] = useState(DATE_FORMAT_DROPDOWN_LIST[0].value);

  const [validPeriod, setValidPeriod] = useState('');
  const [rmRedemption, setRMRedemption] = useState('1');
  const [loyaltyPoints, setLoyaltyPoints] = useState('1');
  const [period, setPeriod] = useState('');

  const [outletDropdownList, setOutletDropdownList] = useState([]);
  const [selectedOutletList, setSelectedOutletList] = useState([]); // multi-outlets

  const [selectedTargetUserGroup, setSelectedTargetUserGroup] = useState(TARGET_USER_GROUP_DROPDOWN_LIST[0].value);
  const [crmUserTagsDropdownList, setCrmUserTagsDropdownList] = useState([]);

  const [selectedVariation, setSelectedVariation] = useState(PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS);
  const [variationItemsDropdownList, setVariationItemsDropdownList] = useState([]);
  const [selectedVariationItems, setSelectedVariationItems] = useState([]);
  const [outletItems, setOutletItems] = useState([]);
  const [selectedVariationItemsSku, setSelectedVariationItemsSku] = useState([]); // for multi-outlets
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const merchantId = UserStore.useState(s => s.merchantId);
  const isLoading = CommonStore.useState(s => s.isLoading);
  const allOutlets = MerchantStore.useState(s => s.allOutlets);
  const currOutletId = MerchantStore.useState(s => s.currOutletId);

  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [pickDate, setPickDate] = useState('');
  const [rev_PickDate, setRev_PickDate] = useState(
    moment(Date.now())
  );
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  const [userType, setUserType] = useState('');
  const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);

  const [dayDate, setDayDate] = useState(['day', 'week', 'month', 'year']);
  const [dayDateId, setDayDateId] = useState("");
  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  var tempSelectedOutletList = [];
  var tempOutLetDropdownList = [];

  useEffect(() => {
    console.log('================================');
    console.log('selectedPointsRedeemPackageEdit');
    console.log(selectedPointsRedeemPackageEdit);

    if (
      selectedPointsRedeemPackageEdit && selectedPointsRedeemPackageEdit.uniqueId
    ) {
      // insert info
      setPackageName(selectedPointsRedeemPackageEdit.packageName);
      setSelectedTargetUserGroup(selectedPointsRedeemPackageEdit.targetUserGroup);
      setImage(selectedPointsRedeemPackageEdit.image);
      setIsImageChanged(false);
      setLimitRedemptionPerUser(selectedPointsRedeemPackageEdit.limitRedemptionPerUser.toString());
      setSelectedVariation(selectedPointsRedeemPackageEdit.variation);
      setSelectedVariationItems(selectedPointsRedeemPackageEdit.variationItems);
      setSelectedVariationItemsSku(selectedPointsRedeemPackageEdit.variationItemsSku);
      // setValidPeriod(selectedPointsRedeemPackageEdit.validPeriod.toString());
      setStartDate(selectedPointsRedeemPackageEdit.startDate ? selectedPointsRedeemPackageEdit.startDate : Date.now());
      setEndDate(selectedPointsRedeemPackageEdit.endDate ? selectedPointsRedeemPackageEdit.endDate : Date.now());
      setSelectedOutletList(selectedPointsRedeemPackageEdit.outletIdList);
      setRMRedemption(selectedPointsRedeemPackageEdit.conversionCurrencyTo.toString());
      setLoyaltyPoints(selectedPointsRedeemPackageEdit.conversionPointsFrom.toString());

      setLimitRedemptionPerUserDateFormatValue(selectedPointsRedeemPackageEdit.limitRedemptionPerUserDateFormatValue ? selectedPointsRedeemPackageEdit.limitRedemptionPerUserDateFormatValue.toFixed(0) : '0');
      setLimitRedemptionPerUserDateFormatType(selectedPointsRedeemPackageEdit.limitRedemptionPerUserDateFormatType ? selectedPointsRedeemPackageEdit.limitRedemptionPerUserDateFormatType : DATE_FORMAT_DROPDOWN_LIST[0].value);
    
      var checkSelectExist = false; 
      for (let i=0; i<outletDropdownList.length; i++){
        for (let j=0; j<selectedOutletList.length; j++){
          if(selectedOutletList[j] === outletDropdownList[i].value){
            checkSelectExist = true
          }
        }
        if(!checkSelectExist){
          tempSelectedOutletList.push(outletDropdownList[i])
        }else{
          tempOutLetDropdownList.push(outletDropdownList[i])
        }
      }
    }
    else {
      // designed to always mounted, thus need clear manually...

      setPackageName('');
      setSelectedTargetUserGroup(TARGET_USER_GROUP_DROPDOWN_LIST[0].value);
      setImage('');
      clearImageContainer();
      setIsImageChanged(false);
      setLimitRedemptionPerUser('0');
      // setSelectedVariation(selectedPointsRedeemPackageEdit.variation);
      // setSelectedVariationItems(selectedPointsRedeemPackageEdit.variationItems);
      // setSelectedVariationItemsSku(selectedPointsRedeemPackageEdit.variationItemsSku);
      // setValidPeriod('');
      setStartDate(Date.now());
      setEndDate(Date.now());
      setSelectedOutletList([]);
      setRMRedemption('1');
      setLoyaltyPoints('1');

      setLimitRedemptionPerUserDateFormatValue('0');
      setLimitRedemptionPerUserDateFormatType(DATE_FORMAT_DROPDOWN_LIST[0].value);
    }
  }, [
    selectedPointsRedeemPackageEdit,
  ]);

  useEffect(() => {
    console.log('crmUserTagsDropdownList');
    console.log(TARGET_USER_GROUP_DROPDOWN_LIST.concat(crmUserTags.map(item => ({ label: item.name, value: item.uniqueId }))));

    setCrmUserTagsDropdownList(TARGET_USER_GROUP_DROPDOWN_LIST.concat(crmUserTags.map(item => ({ label: item.name, value: item.uniqueId }))));
  }, [
    crmUserTags,
  ]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    setOutletDropdownList(allOutlets.map(item => ({
      label: item.name, value: item.uniqueId,
    })));
  }, [allOutlets]);

  // To sort product
  useEffect(() => {
    var outletItemsTemp = [
      ...outletItemsUnsorted,
    ];

    outletItemsTemp.sort((a, b) => a.name.localeCompare(b.name));

    setOutletItems(outletItemsTemp);
  }, [outletItemsUnsorted]);

  useEffect(() => {
    if (selectedVariation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
      setVariationItemsDropdownList(allOutletsCategoriesUnique.map(item => ({ label: item.name, value: item.uniqueId })));

      if (allOutletsCategoriesUnique.length > 0 && !selectedPointsRedeemPackageEdit) {
        // setSelectedVariationItems([allOutletsCategoriesUnique[0].uniqueId]);
      }
      else {
        setSelectedVariationItems([]);
      }
    }
    else if (selectedVariation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
      setVariationItemsDropdownList(outletItems.map(item => ({ label: item.name, value: item.uniqueId })));

      if (outletItems.length > 0 && !selectedPointsRedeemPackageEdit) {
        // setSelectedVariationItems([outletItems[0].uniqueId]);
      }
      else {
        setSelectedVariationItems([]);
      }
    }
  }, [
    outletItems,
    allOutletsCategoriesUnique,
    selectedVariation,
    selectedPointsRedeemPackageEdit
  ]);

  useEffect(() => {
    var selectedVariationItemsSkuTemp = [];

    if (selectedVariation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
      for (var i = 0; i < outletItems.length; i++) {
        if (selectedVariationItems.includes(outletItems[i].uniqueId)) {
          selectedVariationItemsSkuTemp.push(outletItems[i].sku);
        }
      }
    }
    else if (selectedVariation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
      for (var i = 0; i < allOutletsCategoriesUnique.length; i++) {
        if (selectedVariationItems.includes(allOutletsCategoriesUnique[i].uniqueId)) {
          selectedVariationItemsSkuTemp.push(allOutletsCategoriesUnique[i].name);
        }
      }
    }

    setSelectedVariationItemsSku(selectedVariationItemsSkuTemp);
  }, [
    selectedVariation,

    selectedVariationItems,
    outletItems,

    allOutletsCategoriesUnique,
  ]);

  ////////////////////////////////////////////////////////////

  //To remove unwanted sidebar
  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });


  //Header
  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View style={[{
        justifyContent: 'center',
        alignItems: 'center',
        // bottom: -2,
        bottom: switchMerchant ? '2%' : 0,
        // right: switchMerchant ? '10%' : 0,
      }, windowWidth >= 768 && switchMerchant ? { right: windowWidth * 0.1 } : {}, windowWidth <= 768?{right: windowWidth * 0.12}:{}]}>
        <Text
          style={{
            fontSize: switchMerchant ? 18 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Add/Edit Redemption Package
        </Text>
      </View>
    ),
    headerRight: () => (
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        {outletSelectDropdownView()}
        <View style={{
          backgroundColor: 'white',
          width: 0.5,
          height: windowHeight * 0.025,
          opacity: 0.8,
          marginHorizontal: 15,
          bottom: -1,
        }}>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('Setting')}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View style={{
            marginRight: 30,
            width: windowHeight * 0.05,
            height: windowHeight * 0.05,
            borderRadius: windowHeight * 0.05 * 0.5,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}>
            <Image style={{
              width: windowHeight * 0.035,
              height: windowHeight * 0.035,
              alignSelf: 'center',
            }} source={require('../assets/image/profile-pic.jpg')} />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  //////////////////////////////////////////////////////////////////////////////////
  const [openFileSelector, { plainFiles, filesContent, loading: loadingImageInput, clear: clearImageContainer, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
  });

  useEffect(() => {
    console.log(plainFiles, filesContent, loadingImageInput)
    
    // display the image when it is finish loaded
    if (plainFiles.length && filesContent.length && !loadingImageInput) {
      // image in base64 filecontent
      setImage(filesContent[0].content);
      setImageType(filesContent[0].name.slice(filesContent[0].name.lastIndexOf('.')));
      setIsImageChanged(true);

    }

    if(errors.length)
      console.error(errors)

  }, [plainFiles, filesContent, loadingImageInput, errors])

  // const handleChoosePhoto = () => {
  //   const imagePickerOptions = {
  //     mediaType: 'photo',
  //     quality: 0.5,
  //     includeBase64: false,
  //   };

  //   launchImageLibrary(imagePickerOptions, (response) => {
  //     if (response.didCancel) {
  //     } else if (response.error) {
  //       window.confirm(response.error.toString());
  //     } else {
  //       // setState({ image: response.uri });
  //       setImage(response.uri);
  //       setImageType(response.uri.slice(response.uri.lastIndexOf('.')));

  //       setIsImageChanged(true);
  //     }
  //   });
  // }

  const createPointsRedeemPackage = async (isAutoPush = false) => {

    var message = '';

    if (!packageName) {
        message += 'Package name must be filled\n'
    }
    if (limitRedemptionPerUser.length === 0){
      message += 'Limit redemption per user must be filled\n'
    }
    if (!selectedTargetUserGroup){
      message += 'Target user group must be selected\n'
    } 
    if (selectedVariationItems.length === 0){
      message += 'Item(s) variation must be selected\n'
    } 
    if (selectedOutletList.length === 0){
      message += 'Outlet(s) must be selected\n'
    } 
    if (rmRedemption.length === 0){
      message += 'RM/Point(s) Conversion Rate\n'
    } 
    if (loyaltyPoints.length === 0){
      message += 'Point(s) must be filled\n'
    }  
    
    if(message.length > 0){
      window.confirm('Info\n' + message)
    }
    else {

      var outletNames = [];

      for (var i = 0; i < allOutlets.length; i++) {
        for (var j = 0; j < selectedOutletList.length; j++) {
          if (selectedOutletList[j].includes(allOutlets[i].uniqueId)) {
            outletNames.push(allOutlets[i].name);
            break;
          }
        }
      }

      ///////////////////////////////////
      // upload image

      var pointsRedeemPackageImagePath = '';
      var pointsRedeemPackageCommonIdLocal = selectedPointsRedeemPackageEdit ? selectedPointsRedeemPackageEdit.commonId : uuidv4();

      if (image && imageType) {
        // promotionCommonIdLocal = uuidv4();

        pointsRedeemPackageImagePath = await uploadImageToFirebaseStorage({
          uri: image,
          type: imageType,
        }, `/merchant/${merchantId}/pointsRedeemPackage/${pointsRedeemPackageCommonIdLocal}/image${imageType}`);
      }

      ///////////////////////////////////

      if (selectedPointsRedeemPackageEdit === null) {
        // means new item

        var body = {
          merchantId: merchantId,
          merchantName: merchantName,

          packageName: packageName,
          limitRedemptionPerUser: parseInt(limitRedemptionPerUser),

          targetUserGroup: selectedTargetUserGroup,

          image: pointsRedeemPackageImagePath,
          commonId: pointsRedeemPackageCommonIdLocal,

          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,

          validPeriod: parseInt(validPeriod),

          outletIdList: selectedOutletList,
          outletNameList: outletNames,
          currOutletId: currOutletId,

          conversionCurrencyTo: parseInt(rmRedemption),
          conversionPointsFrom: parseInt(loyaltyPoints),
          conversionCurrency: 'MYR',

          startDate: moment(startDate).valueOf(),
          endDate: moment(endDate).valueOf(),

          limitRedemptionPerUserDateFormatValue: parseInt(limitRedemptionPerUserDateFormatValue),
          limitRedemptionPerUserDateFormatType: limitRedemptionPerUserDateFormatType,
        };

        console.log(body);

        CommonStore.update(s => {
          s.isLoading = true;
        });

        ApiClient.POST(API.createPointsRedeemPackage, body, false).then((result) => {
          if (result && result.status === 'success') {
            window.confirm(
              'Success',
              "Redemption package has been added",
              [
                {
                  text: 'OK',
                  onPress: () => {
                    navigation.navigate('SettingCredit');
                  },
                },
              ],
              { cancelable: false },
            );

          }

          CommonStore.update(s => {
            s.isLoading = false;
          });
        });
      }
      else if (selectedPointsRedeemPackageEdit !== null) {
        // means existing item

        var body = {
          pointsRedeemPackageId: selectedPointsRedeemPackageEdit.uniqueId,

          merchantId: merchantId,
          merchantName: merchantName,

          packageName: packageName,
          limitRedemptionPerUser: parseInt(limitRedemptionPerUser),

          targetUserGroup: selectedTargetUserGroup,

          image: isImageChanged ? pointsRedeemPackageImagePath : selectedPointsRedeemPackageEdit.image,
          commonId: pointsRedeemPackageCommonIdLocal,

          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,

          validPeriod: parseInt(validPeriod),

          outletIdList: selectedOutletList,
          outletNameList: outletNames,
          currOutletId: currOutletId,

          conversionCurrencyTo: parseInt(rmRedemption),
          conversionPointsFrom: parseInt(loyaltyPoints),
          conversionCurrency: 'MYR',

          startDate: moment(startDate).valueOf(),
          endDate: moment(endDate).valueOf(),

          limitRedemptionPerUserDateFormatValue: parseInt(limitRedemptionPerUserDateFormatValue),
          limitRedemptionPerUserDateFormatType: limitRedemptionPerUserDateFormatType,
        };

        console.log(body);

        CommonStore.update(s => {
          s.isLoading = true;
        });

        ApiClient.POST(API.updatePointsRedeemPackage, body, false).then((result) => {
          if (result && result.status === 'success') {
            window.confirm(
              'Success',
              "Redemption package has been updated",
              [
                {
                  text: 'OK',
                  onPress: () => {
                    navigation.navigate('SettingCredit');
                  },
                },
              ],
              { cancelable: false },
            );
          }

          CommonStore.update(s => {
            s.isLoading = false;
          });
        });
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////////

  //Render Start Here
  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={6} expandCRM={true} />
      </View>

      <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={showDateTimePicker}
            transparent={true}
            animationType={"slide"}
            supportedOrientations={["portrait", "landscape"]}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: Colors.modalBgColor,
                alignItems: "center",
                justifyContent: "center",
                // top: keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
              }}
            >
              <View
                style={{
                  height: windowWidth * 0.2,
                  width: windowWidth * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 12,
                  padding: windowWidth * 0.02,
                  // borderWidth:1
                }}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1.8 }}>
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 20,
                        textAlign: "right",
                      }}
                    >
                      {pickDate === 'startDate' ? 'Start Date Picker' : 'End Date Picker'}
                    </Text>
                  </View>
                  <View style={{ flex: 1.2 }}>
                    <TouchableOpacity
                      style={{
                        elevation: 1000,
                        zIndex: 1000,
                        alignSelf: "flex-end",
                      }}
                      onPress={() => {
                        setShowDateTimePicker(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    marginTop: 60,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 18,
                      textAlign: "right",
                      marginRight: 10,
                    }}
                  >
                    Date:
                  </Text>
                  <View
                    style={{
                      borderWidth: 0.5,
                      borderRadius: 5,
                      width: 100,
                    }}
                  >
                    <DatePicker
                      // isVisible={showDateTimePicker}
                      // mode={'date'}
                      selected={rev_PickDate.toDate()}
                      onChange={(date) => {
                        if (pickDate === 'startDate') {
                          setStartDate(moment(date));
                          setRev_PickDate(moment(date));
                        } else {
                          setEndDate(moment(date));
                          setRev_PickDate(moment(date));
                        }
                        // setShowDateTimePicker(false);
                      }}
                      // onCancel={() => {
                      //   setShowDateTimePicker(false);
                      // }}
                    />
                  </View>
                </View>
              </View>
            </View>
          </Modal>
      

      <View>
        <View style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: windowWidth * 0.9,
          margin: 10,
          paddingHorizontal: switchMerchant ? 10 : 10,
          paddingTop: 10,
        }}>

          <TouchableOpacity style={{ width: 90, height: 35, justifyContent: 'center', marginLeft: -windowWidth * 0.006 }}
            onPress={() => {
              props.navigation.goBack();
            }}>
            <View style={{ flexDirection: 'row', paddingHorizontal: 10, alignContent: 'center', alignItems: 'center', marginBottom: switchMerchant ? -15 : 0 }}>
              <View style={{ justifyContent: 'center' }}>
                <Feather
                  name="chevron-left"
                  size={switchMerchant ? 20 : 30}
                  color={Colors.primaryColor}
                />
              </View>
              <Text style={{ fontFamily: 'Nunitosans-Bold', color: Colors.primaryColor, fontSize: switchMerchant ? 14 : 17, marginBottom: Platform.OS === 'ios' ? 0 : 2 }}>
                Back
              </Text>
            </View>
          </TouchableOpacity>
        </View>

        <ScrollView style={[styles.list, { 
          width: windowWidth * 0.87,
          height: windowHeight * 0.9,
          paddingBottom: 0, 
          marginTop: 5, 
        }, 
        switchMerchant ? {
          width: windowWidth * 0.80,
          paddingLeft: 10,
          marginBottom: 50
        } : {}]}>

          <View style={{ alignSelf: "flex-end", margin: 20, position: 'absolute', zIndex: 10000, }}>
            <View
              style={{
                justifyContent: 'center',
                flexDirection: 'row',
                borderWidth: 1,
                borderColor: Colors.primaryColor,
                backgroundColor: '#4E9F7D',
                borderRadius: 5,
                width: 130,
                paddingHorizontal: 10,
                height: 40,
                alignItems: 'center',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 1,
                zIndex: -1,
                marginTop: 10,
                marginRight: 20,
              }}>

              <TouchableOpacity
                style={{ width: 130, height: 40, alignItems: 'center', justifyContent: 'center' }}
                disabled={isLoading}
                onPress={() => {
                  createPointsRedeemPackage();
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    //paddingHorizontal: 10,
                    justifyContent: 'center',
                  }}>
                  {isLoading ? (
                    <ActivityIndicator color={Colors.whiteColor} size={'small'} />
                  ) : (
                    <Text style={{
                      color: Colors.whiteColor,
                      marginLeft: 5,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                      SAVE
                    </Text>
                  )}
                </View>
              </TouchableOpacity>
            </View>
          </View>

          <View style={{}}>
            <Text style={[{ alignSelf: "center", marginTop: 30, fontSize: 40, fontWeight: 'bold' }, switchMerchant ? { fontSize: 20 } : {}]}>
              Add/Edit Redemption Package
            </Text>
          </View>


          <View style={{}}>
            {/* <View
            style={{
              borderWidth: 1,
              borderColor: '#c4c4c4',
              borderRadius: 3,
              alignSelf: 'center',
              width: windowWidth * 0.55,
              padding: 15,
              paddingLeft: 10,
              paddingBottom: 30,
            }}> */}
            {/* Left Column */}

            <View style={[{ flexDirection: 'row', width: '100%', margin: 20, }, switchMerchant ? {
              margin: 5
            } : {}]}>
              <View style={[{ width: '38%' }, switchMerchant ? {
                width: '36%'
              } : {}]}>
                {/* left */}

                <View style={{ width: '100%', marginVertical: 20, marginBottom: 10, zIndex: -1, marginLeft: 10 }}>

                  <Text style={{
                    fontSize: switchMerchant ? 15 : 20,
                    fontFamily: 'NunitoSans-Bold',
                    color: 'black'
                  }}>
                    Package Photo</Text>

                  <View style={[{ marginTop: 10, height: 250, width: 250, zIndex: -1 }, switchMerchant ? {
                    height: 190
                  } : {}]}>
                    {image ? (
                      <View
                        style={[{
                          zIndex: -2,
                          width: 325, height: 250,
                          backgroundColor: Colors.fieldtBgColor,
                        }, switchMerchant ? { width: 200, height: 200, } : {}]}>
                        <TouchableOpacity
                          onPress={() => {
                            // handleChoosePhoto();
                            openFileSelector();
                          }}>
                          <View style={{ zIndex: -2, backgroundColor: Colors.fieldtBgColor, borderRadius: 5 }}>
                            <View style={[{ zIndex: 1, width: 327, borderWidth: 1, borderRadius: 5, borderColor: '#E5E5E5' }, switchMerchant ? { width: 202, } : {}]}>
                              <AsyncImage
                                source={{ uri: image }}
                                style={[{ width: 325, height: 250, borderRadius: 5 }, switchMerchant ? { width: 200, height: 200, } : {}]}
                                hideLoading={true}
                              />
                              <View style={{
                                position: 'absolute',
                                bottom: 5,
                                right: 5,
                                //   backgroundColor: 'black',
                                opacity: 0.5,
                                // width: 120, 
                                // height: 120,
                                // borderRadius: 13,
                              }}>

                                <Edit size={23} color={Colors.primaryColor}
                                //  style={{ position: 'absolute', zIndex: -1 }}
                                />

                              </View>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <View
                        style={[{
                          zIndex: -2,
                          width: Platform.OS === 'android' ? '100%' : 325, height: Platform.OS === 'android' ? '100%' : 250,
                          backgroundColor: Colors.fieldtBgColor,
                          // borderWidth: 1
                        }, switchMerchant ? { width: 200, height: 200, } : {}]}>
                        <TouchableOpacity
                          onPress={() => {
                            // handleChoosePhoto();
                            openFileSelector();
                          }}>
                          <View style={[{ zIndex: -2, width: Platform.OS === 'android' ? '120%' : 325, height: Platform.OS === 'android' ? '100%' : 250, backgroundColor: Colors.fieldtBgColor, }, switchMerchant ? { width: 200, height: 190, } : {}]}>
                            <View style={[{ zIndex: 1, width: Platform.OS === 'android' ? '100%' : 327, borderWidth: 1, borderRadius: 5, borderColor: '#E5E5E5' }, switchMerchant ? { width: 200, height: 200, } : {}]}>
                              <AsyncImage
                                source={require('../assets/image/profile-pic.jpg')}
                                style={[{
                                  width: '100%' //325
                                  , height: '100%' //250
                                  , borderRadius: 5
                                }, switchMerchant ? { width: 200, height: 200, } : {}]}
                                hideLoading={true}
                              />
                              <View style={{
                                position: 'absolute',
                                bottom: 5,
                                right: 5,
                                //   backgroundColor: 'black',
                                opacity: 0.5,
                                // width: 120, 
                                // height: 120,
                                // borderRadius: 13,
                              }}>

                                <Edit size={23} color={Colors.primaryColor}
                                //  style={{ position: 'absolute', zIndex: -1 }}
                                />

                              </View>
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View>

                    )}
                  </View>
                </View>

                <View style={[{ marginVertical: 20, marginBottom: 10, zIndex: 2 }, switchMerchant ? {
                  marginVertical: 0, marginBottom: 10, marginLeft: 10, marginTop: 10
                } : {}]}>
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'center',
                      //   justifyContent: 'space-between',
                    }}>
                    <View style={[{ width: '35%' }, switchMerchant ? {
                      width: '59%'
                    } : {}]}>
                      <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14, }}>
                        Apply to Outlet
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                      {/* <View style={{
                                width: 210,
                              }}> */}
                      <Multiselect
                        style={{ width: 200, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, }}
                        options={outletDropdownList}
                        selectedValues={selectedOutletList}
                        onSelect={(items) => {
                          setSelectedOutletList(items);
                          console.log(selectedPointsRedeemPackageEdit)
                        }}
                        onRemove={(items) => {
                          setSelectedOutletList(items);
                        }}
                        displayValue='label'
                      // style={{
                      //   borderWidth: 1,
                      //   borderColor: '#E5E5E5',
                      //   borderRadius: 5,
                      //   height: 35,
                      //   width: 200,
                      //   paddingVertical: 0,
                      //   marginLeft: 0,
                      // }}
                      // dropDownStyle={{
                      //   borderWidth: 1,
                      //   borderColor: '#E5E5E5',
                      //   borderRadius: 5,
                      //   width: 200,
                      //   paddingVertical: 0,
                      //   marginLeft: 0,
                      // }}
                      // containerStyle={{ height: 35 }}
                      // arrowColor={'black'}
                      // arrowSize={20}
                      // arrowStyle={{ fontWeight: 'bold' }}
                      // labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                      // placeholderStyle={{ color: Colors.fieldtTxtColor }}
                      // // items={[{ label: 'Kota Damansara', value: 1 }, { label: 'Petaling Jaya', value: 2 }, { label: 'Loke Yo', value: 3 }, { label: 'Puchong', value: 4 }, { label: 'Daigong', value: 5 }, { label: 'KL', value: 6 }]}
                      // items={outletDropdownList}
                      // defaultValue={selectedOutletList}
                      // placeholder={'Select'}
                      // multipleText={'%d outlets selected'}
                      // multiple={true}
                      // customTickIcon={(press) => (
                      //   <Ionicon
                      //     name={'md-checkbox'}
                      //     color={
                      //       press ? Colors.fieldtBgColor : Colors.primaryColor
                      //     }
                      //     size={25}
                      //   />
                      // )}
                      // onChangeItem={(items) => {
                      //   setSelectedOutletList(items);
                      // }}
                      // dropDownMaxHeight={100}
                      />
                      {/* </View> */}
                    </View>
                  </View>
                </View>
                <View style={[{ marginVertical: 20, marginBottom: 10, zIndex: -1, width: '300%', }, switchMerchant ? {
                  marginVertical: 0, marginBottom: 10, marginLeft: 10,
                } : {}]}>
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'center',
                      //   justifyContent: 'space-between',
                    }}>
                    <View style={[{ width: '11.7%' }, switchMerchant ? {
                      width: '19%'
                    } : {}]}>
                      <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14, }}>
                        Product
                      </Text>
                    </View>
                    {/* <Text
                          style={{
                            marginTop: -3,
                            marginBottom: 0,
                            color: Colors.primaryColor,
                            fontSize: 12,
                          }}>
                          *Empty means never ends
                        </Text> */}
                    <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                      <Picker
                        containerStyle={{ height: 40, zIndex: 2 }}
                        arrowColor={'black'}
                        arrowSize={20}
                        arrowStyle={{ fontWeight: 'bold' }}
                        labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        style={[{ width: 200, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }, switchMerchant ? {
                          width: 200
                        } : {}]}
                        placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        itemStyle={{ justifyContent: 'flex-start', marginLeft: 5, zIndex: 2, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        placeholder={'Product of Category'}
                        onValueChange={item => { setSelectedVariation(item) }}
                        defaultValue={selectedVariation}
                        dropDownMaxHeight={150}
                        dropDownStyle={{ width: 200, height: 90, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1, textAlign: 'left', zIndex: 2, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                      // style={{
                      //   borderWidth: 1,
                      //   borderColor: '#E5E5E5',
                      //   borderRadius: 5,
                      //   height: 35,
                      //   width: 200,
                      //   paddingVertical: 0,
                      //   marginLeft: 0,
                      //   backgroundColor:Colors.whiteColor
                      // }}
                      // dropDownStyle={{
                      //   borderWidth: 1,
                      //   borderColor: '#E5E5E5',
                      //   borderRadius: 5,
                      //   width: 200,
                      //   paddingVertical: 0,
                      //   marginLeft: 0,
                      //   zIndex: -3,
                      //   backgroundColor:Colors.whiteColor,
                      // }}
                      // arrowSize={20}
                      // arrowColor={'black'}
                      // arrowStyle={{ fontWeight: 'bold' }}
                      // placeholderStyle={{
                      // marginLeft: 0,
                      // color: Colors.fieldtTxtColor,
                      // }}
                      // placeholder={'Product of Category'}
                      // items={PROMOTION_TYPE_VARIATION_DROPDOWN_LIST}
                      // itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                      // customTickIcon={(press) => (
                      //   <Ionicon
                      //     name={'md-checkbox'}
                      //     color={press ? Colors.fieldtBgColor : Colors.primaryColor}
                      //     size={25}
                      //   />
                      // )}
                      // onChangeItem={(item) => setSelectedVariation(item.value)}
                      // defaultValue={selectedVariation}
                      // dropDownMaxHeight={130}
                      >
                        {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                          return (
                            <Picker.Item
                            key={index}
                            value={value.value}
                            label={value.label}
                            />
                          )
                        })}
                      </Picker>
                      <View style={{ width: switchMerchant ? '17.2%' : '15.8%', }}>
                        <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, alignItems: 'center', textAlign: 'center', }}>
                          +
                        </Text>
                      </View>
                      {/* <TextInput placeholder='Product'
                                style={{
                                  marginTop: 10,
                                  marginLeft:10,
                                  color:'black',
                                  width: 150,
                                  height: 35,
                                  borderRadius: 5,
                                  paddingHorizontal: 10,
                                  borderWidth: 1,
                                  borderColor: '#E5E5E5',
                                }}/> */}
                      {/* {
                                  variationItemsDropdownList.length > 0
                                    ? */}
                      <Multiselect 
                        style={[{
                          width: 200,
                          paddingVertical: 0,
                          backgroundColor: Colors.fieldtBgColor,
                          borderRadius: 10,
                          fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                        }, switchMerchant ? {
                          width: 200
                        } : {}]}
                        options={variationItemsDropdownList}
                        selectedValues={selectedVariationItems}
                        onSelect={(items) => setSelectedVariationItems(items)}
                        onRemove={(items) => setSelectedVariationItems(items)}
                        displayValue='label'
                      // style={{
                      //   borderWidth: 1,
                      //   borderColor: '#E5E5E5',
                      //   borderRadius: 5,
                      //   height: 35,
                      //   width: 200,
                      //   paddingVertical: 0,
                      // }}
                      // dropDownStyle={{
                      //   borderWidth: 1,
                      //   borderColor: '#E5E5E5',
                      //   borderRadius: 5,
                      //   width: 200,
                      //   paddingVertical: 0,
                      // }}
                      // arrowSize={20}
                      // arrowColor={'black'}
                      // arrowStyle={{ fontWeight: 'bold' }}
                      // customTickIcon={(press) => (
                      //   <Ionicon
                      //     name={'md-checkbox'}
                      //     color={Colors.primaryColor}
                      //     size={25}
                      //     style={{ width: 30, justifyContent: 'flex-end' }}
                      //   />
                      // )}
                      // placeholderStyle={{
                      //   marginLeft: 0,
                      //   color: Colors.fieldtTxtColor,
                      // }}
                      // placeholder={'Products'}
                      // multipleText={'%d products selected'}
                      // items={variationItemsDropdownList}
                      // itemStyle={{
                      //   justifyContent: 'flex-start',
                      //   marginLeft: 5,
                      //   width: 180,
                      // }}
                      // onChangeItem={(items) => setSelectedVariationItems(items)}
                      // defaultValue={selectedVariationItems}
                      // multiple={true}
                      // searchable={true}
                      // dropDownMaxHeight={180}
                      />
                    </View>
                  </View>
                </View>

                <View style={[{ marginVertical: 20, marginBottom: 10, zIndex: -3, width: '300%' }, switchMerchant ? {
                  marginVertical: 0, marginBottom: 10, marginLeft: 10,
                } : {}]}>
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                      alignItems: 'center',

                      //   justifyContent: 'space-between',
                    }}>
                    <View style={[{ width: '11.7%' }, switchMerchant ? {
                      width: '19%'
                    } : {}]}>
                      <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14, }}>
                        Valid Period
                      </Text>
                    </View>
                    {/* <Text
                    style={{
                      marginTop: -3,
                      marginBottom: 0,
                      color: Colors.primaryColor,
                      fontSize: 12,
                    }}>
                    *Empty means never ends
                  </Text> */}
                    <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                      {/* <TextInput placeholder='0' style={{
                    marginTop: 2, color: 'black', width: 70, height: 35,
                    borderRadius: 5, paddingHorizontal: 10, borderWidth: 1, borderColor: '#E5E5E5'
                  }}
                    onChangeText={(text) => {
                      setValidPeriod(text)
                    }}
                    defaultValue={validPeriod}
                    keyboardType={'number-pad'}
                  /> */}

                      {/* <Text style={{ marginTop: 0, marginLeft: 10, color: Colors.blackColor, fontFamily: 'NunitoSans-Regular', fontSize: 16 }}>
                    Day(s)
                  </Text> */}

                      {/* <DropDownPicker style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 10, width: 120, paddingVertical: 0, marginHorizontal: 15, }}
                  dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 120, paddingVertical: 0, marginHorizontal: 15, zIndex: 1 }}
                  itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                  arrowSize={17}
                  arrowColor={'black'}
                  placeholder={"Day(s)"}
                  items={[{ label: 'Day(s)', value: 'Day' }, { label: 'Week(s)', value: 'Week' }, { label: 'Month(s)', value: 'Month' }]}
                  onChangeItem={(item) => {
                    setPeriod(item.value);
                  }}
                  defaultValue={period}
                /> */}
                      <View
                        style={{
                          // height: 50,
                          height: 40,
                          paddingHorizontal: 20,
                          backgroundColor: Colors.fieldtBgColor,
                          //marginBottom: 20,
                          width: 200,
                          //marginHorizontal: 10,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          alignContent: 'center',
                          borderColor: '#E5E5E5',
                          borderWidth: 1,
                          borderRadius: 12,
                        }}>
                        <TouchableOpacity
                          style={{
                          }}
                          onPress={() => {
                            setPickDate('startDate');
                            setShowDateTimePicker(true);
                          }}>
                          <GCalendar width={22} height={22} />
                        </TouchableOpacity>
                        <View style={{ width: '5%' }} />
                        <Text
                          style={{
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                            color: startDate ? 'black' : Colors.descriptionColor,
                          }}>
                          {/* {startDate == '' ? "Start Date" : startDate} */}
                          {startDate
                            ? moment(startDate).format('DD MMM YYYY')
                            : 'Start Date'}
                        </Text>
                      </View>

                      <View style={{ width: switchMerchant ? '17.2%' : '15.8%', }}>
                        <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, alignItems: 'center', textAlign: 'center', color: 'black', }}>
                          To
                        </Text>
                      </View>


                      <View
                        style={{
                          // height: 50,
                          height: 40,
                          paddingHorizontal: 20,
                          backgroundColor: Colors.fieldtBgColor,
                          //marginBottom: 20,
                          width: 200,
                          //marginHorizontal: 10,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          alignContent: 'center',
                          borderColor: '#E5E5E5',
                          borderWidth: 1,
                          borderRadius: 12,
                        }}>
                        <TouchableOpacity
                          style={{
                          }}
                          onPress={() => {
                            setPickDate('endDate');
                            setShowDateTimePicker(true);
                          }}>
                          <GCalendar width={22} height={22} />
                        </TouchableOpacity>
                        <View style={{ width: '5%' }} />
                        <Text
                          style={{
                            fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                            color: endDate ? 'black' : Colors.descriptionColor,
                          }}>
                          {/* {endDate == '' ? "End Date" : endDate} */}
                          {endDate
                            ? moment(endDate).format('DD MMM YYYY')
                            : 'End Date'}
                        </Text>

                        {/* </View> */}
                      </View>
                    </View>
                  </View>
                </View>
              </View>


              {/* right */}
              <View style={{ width: '60%', zIndex: -5, marginTop: 55 }}>

                <View style={{ marginBottom: 15, zIndex: -3 }}>
                  <View style={{ flexDirection: 'row', width: '100%', }}>
                    <View style={[{
                      justifyContent: 'center',
                      alignSelf: 'center',
                      width: windowWidth <= 1024 ? '27%' : '25%',
                    }, switchMerchant ? {
                      width: '33%',
                    } : {}]}>
                      <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14, }}>
                        Package Name
                      </Text>
                    </View>
                    <TextInput
                      underlineColorAndroid={Colors.fieldtBgColor}
                      style={[styles.textFieldInput,{fontFamily: 'NunitoSans-Regular', fontSize: 14, width: windowWidth * 0.35}, switchMerchant ? {
                        width: 243,
                        fontSize: 10,
                      } : {}]}
                      placeholder="Enter a name"
                      placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14}}
                      placeholderTextColor={Platform.select({
                        ios: '#a9a9a9'
                      })}
                      onChangeText={(text) => {
                        setPackageName(text);
                      }}
                      value={packageName}
                      keyboardType={'default'}
                    />
                  </View>
                </View>

                <View style={{ marginVertical: switchMerchant ? 0 : 20, marginBottom: 10, zIndex: 2 }}>
                  <View style={{ flexDirection: 'row', width: '100%', }}>
                    <View style={[{
                      justifyContent: 'center',
                      width: windowWidth <= 1024 ? '27%' : '25%',
                    }, switchMerchant ? {
                      width: '33%',
                    } : {}]}>
                      <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14, }}>
                        Target Customer
                      </Text>
                    </View>
                    <View style={{}}>
                      {crmUserTagsDropdownList.find(
                        (item) => item.value === selectedTargetUserGroup,
                      ) ? (
                        //  <DropDownPicker style={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, height: 35, width: 200, paddingVertical: 0, marginLeft: 0 }}
                        //   dropDownStyle={{ borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, width: 200, paddingVertical: 0, marginLeft: 0 }}
                        //   arrowSize={20}
                        //   arrowColor={'black'}
                        //   arrowStyle={{ fontWeight: 'bold' }}
                        //   placeholderStyle={{ marginLeft: 0, color: Colors.fieldtTxtColor }}
                        //   placeholder={"User"}
                        //   items={[{ label: 'Customer', value: 'Customer' }, { label: 'Non-customer', value: 'Non-customer' }]}
                        //   itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                        //   onChangeItem={(item) =>
                        //     setUserType(item.value)
                        //   }
                        //   defaultValue={userType}
                        //   dropDownMaxHeight={130}
                        // />

                        // <Text style={[styles.textSize, { marginTop: 4, marginHorizontal: 10 }]}>+</Text>

                        <Picker
                          containerStyle={{ height: 40 }}
                          arrowColor={'black'}
                          arrowSize={20}
                          arrowStyle={{ fontWeight: 'bold' }}
                          labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, }}
                          style={[{ width: windowWidth * 0.35, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, fontFamily: 'NunitoSans-Regular', fontSize: 14 }, switchMerchant ? {
                            width: 243,
                            fontSize: 10,
                          } : {}]}
                          placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                          // items={[{ label: 'Kota Damansara', value: 1 }, { label: 'Petaling Jaya', value: 2 }, { label: 'Loke Yo', value: 3 }, { label: 'Puchong', value: 4 }, { label: 'Daigong', value: 5 }, { label: 'KL', value: 6 }]}
                          itemStyle={{ justifyContent: 'flex-start', marginLeft: 5, zIndex: 2, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, }}
                          defaultValue={selectedTargetUserGroup}
                          placeholder={"Select target(s)"}
                          multipleText={'%d target(s) selected'}
                          // multiple={true}
                          searchable={true}
                          customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                          onValueChange={(item) => {
                            setSelectedTargetUserGroup(item);
                          }}
                          dropDownMaxHeight={230}
                          dropDownStyle={[{ width: windowWidth * 0.35, height: 150, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1 }, switchMerchant ? {
                            width: 243,
                            fontSize: switchMerchant ? 10 : 16,
                          } : {}]}
                        // containerStyle={{ height: 35 }}
                        // arrowColor={'black'}
                        // arrowSize={20}
                        // arrowStyle={{ fontWeight: 'bold' }}
                        // labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                        // style={{
                        //   marginTop: 0,
                        //   width: 215,
                        //   paddingVertical: 0,
                        //   borderRadius: 10,
                        // }}
                        // placeholderStyle={{ color: Colors.fieldtTxtColor }}
                        // items={crmUserTagsDropdownList}
                        // itemStyle={{
                        //   justifyContent: 'flex-start',
                        //   marginLeft: 5,
                        // }}
                        // customTickIcon={(press) => (
                        //   <Ionicon
                        //     name={'md-checkbox'}
                        //     color={
                        //     press ? Colors.fieldtBgColor : Colors.primaryColor
                        //   }
                        //   size={25}
                        //   />
                        // )}
                        //   onChangeItem={(item) => {
                        //     setSelectedTargetUserGroup(item.value);
                        //   }}
                        //     defaultValue={selectedTargetUserGroup}
                        //     searchable={true}
                        //     dropDownMaxHeight={150}
                        //     dropDownStyle={{
                        //       width: 190,
                        //       height: 150,
                        //       borderRadius: 10,
                        //       borderWidth: 1,
                        //     }}
                        >
                          {crmUserTagsDropdownList.map((value, index) => {
                            return (
                              <Picker.Item
                              key={index}
                              value={value.value}
                              label={value.label}
                              />
                            )
                          })}
                        </Picker>
                      ) : (
                        <></>
                      )}
                    </View>
                  </View>
                </View>

                <View style={{ marginVertical: switchMerchant ? 0 : 20, marginBottom: 10, zIndex: -3 }}>
                  <View style={{ flexDirection: 'row', width: '100%', }}>
                    <View style={[{
                      justifyContent: 'center',
                      width: windowWidth <= 1024 ? '27%' : '25%',
                      justifyContent: 'center'
                    }, switchMerchant ? {
                      width: '33%',
                    } : {}]}>
                      <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14, }}>
                        Limit Redemption
                      </Text>
                    </View>
                    <View style={{ flexDirection: 'column', }}>
                      <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>
                        <TextInput
                          style={[styles.productBox, {fontFamily: 'NunitoSans-Regular', fontSize: 14}, switchMerchant ? {
                            width: 50, paddingHorizontal: 15, fontSize: 10
                          } : {}]}
                          placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                          placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14}}
                          underlineColorAndroid={Colors.fieldtBgColor}
                          placeholder="e.g. 0"
                          onChangeText={(text) => {
                            setLimitRedemptionPerUser(text);
                          }}
                          value={limitRedemptionPerUser}
                        />
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14, color: 'black', }}>
                            Per User
                          </Text>
                          <View style={{ width: '3%' }}>
                          </View>
                          <TextInput
                            style={[styles.productBox,{fontFamily: 'NunitoSans-Regular', fontSize: 14}, switchMerchant ? {
                              width: 50, paddingHorizontal: 15, fontSize: 10
                            } : {}]}
                            underlineColorAndroid={Colors.fieldtBgColor}
                            placeholder={'Days'}
                            placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14}}
                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            onChangeText={(text) => {
                              setLimitRedemptionPerUserDateFormatValue(text);
                            }}
                            defaultValue={limitRedemptionPerUserDateFormatValue}
                            keyboardType={'number-pad'}
                          />

                          <View style={{ width: '3%' }}>
                          </View>
                          <Picker
                            containerStyle={{ height: 40, }}
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold' }}
                            labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                            style={[{ width: 90, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, }, switchMerchant ? {
                              width: 78
                            } : {}]}
                            placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                            itemStyle={{ justifyContent: 'flex-start', marginLeft: 5, zIndex: 2 }}
                            placeholder={"Select"}
                            //multiple={true}
                            customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                            onValueChange={item => {
                              setLimitRedemptionPerUserDateFormatType(item.value);
                            }}
                            defaultValue={limitRedemptionPerUserDateFormatType}
                            dropDownMaxHeight={150}
                            dropDownStyle={[{ width: 90, height: 120, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1, textAlign: 'left', fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, }, switchMerchant ? {
                              width: 78
                            } : {}]}
                          // containerStyle={{ height: 35, zIndex: 2 }}
                          // arrowColor={'black'}
                          // arrowSize={20}
                          // arrowStyle={{ fontWeight: 'bold' }}
                          // labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                          // style={{
                          //   width: 100,
                          //   height: 35,
                          //   paddingVertical: 0,
                          //   backgroundColor: Colors.whiteColor,
                          //   borderRadius: 10,
                          // }}
                          // placeholderStyle={{ color: Colors.whiteColor }}
                          // items={DATE_FORMAT_DROPDOWN_LIST}
                          // itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                          // placeholder={'Day'}
                          // onChangeItem={(item) => {
                          //   setLimitRedemptionPerUserDateFormatType(item.value);
                          // }}
                          // defaultValue={limitRedemptionPerUserDateFormatType}
                          // dropDownMaxHeight={150}
                          // dropDownStyle={{
                          //   width: 100,
                          //   height: 120,
                          //   backgroundColor: Colors.whiteColor,
                          //   borderRadius: 10,
                          //   borderWidth: 1,
                          // }}
                          >
                            {DATE_FORMAT_DROPDOWN_LIST.map((value, index) => {
                              return (
                                <Picker.Item 
                                key={index}
                                value={value.value}
                                label={value.label}
                                />
                              )
                            })}
                          </Picker>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ marginVertical: switchMerchant ? 0 : 20, marginBottom: 10, zIndex: -3 }}>
                  <View style={{ flexDirection: 'row', width: '100%', }}>
                    <View style={[{
                      justifyContent: 'center',
                      width: windowWidth <= 1024 ? '27%' : '25%',
                      justifyContent: 'center'
                    }, switchMerchant ? {
                      width: '33%',
                    } : {}]}>
                      <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14, }}>
                        Points
                      </Text>
                    </View>
                    <TextInput
                      style={{
                        backgroundColor: Colors.fieldtBgColor,
                        width: 80,
                        height: 35,
                        borderRadius: 5,
                        padding: 5,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        paddingLeft: 10,
                      }}
                      placeholder="e.g. 0"
                      style={[styles.productBox,{fontFamily: 'NunitoSans-Regular', fontSize: 14}, switchMerchant ? {
                        width: 50, paddingHorizontal: 15, fontSize: 10
                      } : {}]}
                      underlineColorAndroid={Colors.fieldtBgColor}
                      onChangeText={(text) => {
                        setLoyaltyPoints(text);
                      }}
                      defaultValue={loyaltyPoints}
                      keyboardType={'number-pad'}
                      placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14}}
                      placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                    />
                  </View>
                </View>
              </View>
            </View>
            {/*  */}
            {/* </View> */}
          </View>
          <View style={[{ height: 120, }]}></View>
        </ScrollView>

      </View>
    </View>
  );

};


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
    fontFamily: 'NunitoSans-Regular',
  },
  list: {
    backgroundColor: Colors.whiteColor,
    
    marginTop: 30,
    marginHorizontal: 30,
    marginBottom: 30,
    // alignSelf: 'center',
    // justifyContent: 'center',
    // alignItems:'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  listItem: {
    fontFamily: 'NunitoSans-Regular',
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    // width: windowWidth * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,
    elevation: 16,
  },
  content: {
    padding: 20,
    // width: windowWidth * (1 - Styles.sideBarWidth),
    // backgroundColor: 'lightgrey',
    backgroundColor: Colors.fieldtBgColor,
  },
  textInput: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 0,
    flex: 1,
    flexDirection: 'row',
  },
  textInputLocation: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 100,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 10,
  },
  textSize: {
    fontSize: 19,
    fontFamily: 'NunitoSans-SemiBold'
  },
  merchantDisplayView: {
    flexDirection: 'row',
    flex: 1,
    marginLeft: '17%'
  },
  shiftText: {
    marginLeft: '15%',
    color: Colors.primaryColor,
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 25,
  },
  confirmBox: {
    width: '30%',
    height: '30%',
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  textFieldInput: {
    height: 40,
    // width: 500,
    // width: '100%',
    paddingHorizontal: 10,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    // paddingTop: 10,
    borderWidth: 1,
    borderColor: "#E5E5E5",
  },
  productBox: {
    height: 40,
    // width: Platform.OS === 'ios' ? windowWidth * 0.074 : windowWidth * 0.084,
    width: 80,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    // paddingTop: 10,
    borderWidth: 1,
    borderColor: '#E5E5E5',
  },
  submitText1: {
    paddingVertical: 7,
    paddingHorizontal: 10,
    flexDirection: 'row',
    color: '#4cd964',
    textAlign: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    alignSelf: "flex-end",
    marginRight: 10,
  },
});
export default SettingSetCredit;
