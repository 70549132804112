import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Modal,
  Platform,
  useWindowDimensions,
  CheckBox,
  Picker,
} from "react-native";
import Select from "react-select";

import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
import AntDesign from "react-native-vector-icons/AntDesign";
import Ionicon from "react-native-vector-icons/Ionicons";
import AIcon from "react-native-vector-icons/AntDesign";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import SimpleLineIcons from "react-native-vector-icons/SimpleLineIcons";
// import GCalendar from '../assets/svg/GCalendar';
// todo
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import { TextInput, FlatList } from "react-native-gesture-handler";
import DropDownPicker from "react-native-dropdown-picker";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import API from "../constant/API";
import ApiClient from "../util/ApiClient";
// import {launchCamera, launchImageLibrary} from 'react-native-image-picker';
// import DialogInput from 'react-native-dialog-input';
import * as User from "../util/User";
import LoginScreen from "./LoginScreen";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import CheckBox from 'react-native-check-box';
import { color } from "react-native-reanimated";
import Close from "react-native-vector-icons/AntDesign";
// import NumericInput from 'react-native-numeric-input';
import Styles from "../constant/Styles";
import moment, { isDate } from "moment";
// import Barcode from 'react-native-barcode-builder';
// import Switch from 'react-native-switch-pro';
import Ionicons from "react-native-vector-icons/Ionicons";
import Feather from "react-native-vector-icons/Feather";
// import Dish from '../assets/svg/Dish';
// import Hand from '../assets/svg/Hand';
import { ReactComponent as Hand } from "../assets/svg/Hand.svg";

// import Coins from '../assets/svg/Coins';
import { ReactComponent as Coins } from "../assets/svg/Coins.svg";
// import {isTablet} from 'react-native-device-detection';
import { UserStore } from "../store/userStore";
import { MerchantStore } from "../store/merchantStore";
import { OutletStore } from "../store/outletStore";
// import QRCode from 'react-native-qrcode-svg';
import { CommonStore } from "../store/commonStore";
// import {
//   KeyboardAwareFlatList,
//   KeyboardAwareScrollView,
// } from 'react-native-keyboard-aware-scroll-view';
import EvilIcons from "react-native-vector-icons/EvilIcons";
import {
  DATE_FORMAT_DROPDOWN_LIST,
  PROMOTION_TYPE_VARIATION,
} from "../constant/promotions";
import AsyncImage from "../components/asyncImage";
import { uploadImageToFirebaseStorage } from "../util/common";
import "react-native-get-random-values";
import { v4 as uuidv4 } from "uuid";
import DatePicker from "react-datepicker";
import TimeKeeper from "react-timekeeper";
import { useFilePicker } from "use-file-picker";

// import {useKeyboard} from '../hooks';

const SettingScreen = (props) => {
  const { navigation } = props;

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const [selfCollect, setSelfCollect] = useState(true);
  const [openHourPickerVisible, setOpenHourPickerVisible] = useState(false);
  const [closeHourPickerVisible, setCloseHourPickerVisible] = useState(false);
  const [openHour, setOpenHour] = useState("");
  const [closeHour, setCloseHour] = useState("");
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked5, setIsChecked5] = useState(false);
  const [isChecked6, setIsChecked6] = useState(false);
  const [isChecked7, setIsChecked7] = useState(false);
  const [isChecked8, setIsChecked8] = useState(false);
  const [isChecked9, setIsChecked9] = useState(false);
  const [isChecked10, setIsChecked10] = useState(false);
  const [isChecked11, setIsChecked11] = useState(false);
  const [isChecked12, setIsChecked12] = useState(false);
  const [isChecked13, setIsChecked13] = useState(false);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [amount, setAmount] = useState("");
  const [hourStart, setHourStart] = useState("");
  const [hourEnd, setHourEnd] = useState("");
  const [days, setDays] = useState(false);
  const [days1, setDays1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDistance, setShowDistance] = useState("");
  const [expiryPeriod, setExpiryPeriod] = useState(0);
  const [extentionCharges, setExtentionCharges] = useState("");
  const [extentionDuration, setExtentionDuration] = useState("");
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  // const [pickerMode, setPickerMode] = useState('time');
  const [pickerMode, setPickerMode] = useState("datetime");
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [merchantDisplay, setMerchantDisplay] = useState(false);
  const [shift, setShift] = useState(false);
  const [tax, setTax] = useState(false);
  const [sample, setSample] = useState(false);
  const [redemption, setRedemption] = useState(true);
  const [redemptionList, setRedemptionList] = useState(true);
  const [redemptionAdd, setRedemptionAdd] = useState(false);
  const [order, setOrder] = useState(false);
  const [previousState, setPreviousState] = useState(false);
  const [receipt, setReceipt] = useState([]);
  const [detail, setDetail] = useState([]);
  const [merchantInfo, setMerchantInfo] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [outletInfo, setOutletInfo] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [outletId, setOutletId] = useState([]);
  const [merInfo, setMerInfo] = useState([]);
  // const [merchantId, setMerchantId] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [closingAmount, setClosingAmount] = useState("");
  const [options, setOptions] = useState([]);
  const [shift1, setShift1] = useState([]);
  const [status, setStatus] = useState(false);
  const [logo, setLogo] = useState("");
  const [cover, setCover] = useState("");
  const [name, setName] = useState("");
  const [tname, setTname] = useState("");
  const [rate, setRate] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [payment, setPayment] = useState("");
  const [time, setTime] = useState("");
  const [statue, setStatue] = useState("");
  const [status1, setStatus1] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [outletId, setOutletId] = useState(null);
  const [myTextInput, setMyTextInput] = useState(React.createRef());
  const [start_time, setStart_time] = useState(false);
  const [end_time, setEnd_time] = useState(false);
  const [rev_time, setRev_time] = useState("");
  const [category, setCategory] = useState("");
  const [close, setClose] = useState("Closed");
  const [showNote, setShowNote] = useState(false);
  const [expandView, setExpandView] = useState(false);
  const [value, setValue] = useState("");
  // const [extendOption, setExtendOption] = [{ optionId: 1, price: 20, day: 7, days: false }];
  const [extendOption, setExtendOption] = [];
  const [redemptionInfo, setRedemptionInfo] = useState([]);
  const [alloutlet, setAlloutlet] = useState([]);
  const [discount, setDiscount] = useState("");
  const [amount1, setAmount1] = useState("");
  // const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [categoryOutlet, setCategoryOutlet] = useState([]);
  const [extend, setExtend] = useState([]);
  const [outletss, setOutletss] = useState([]);
  const [redemptionDetail, setRedemptionDetail] = useState([]);
  const [outletInfo1, setOutletInfo1] = useState([]);
  const [category1, setCategory1] = useState([]);
  // const [merchantName, setMerchantName] = useState('');
  const [addOutletName, setAddOutletName] = useState("");
  const [addOutletWindow, setAddOutletWindow] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [note1, setNote1] = useState("");
  const [note2, setNote2] = useState("");
  const [note3, setNote3] = useState("");
  const [openings, setOpenings] = useState([]);
  const [editOpeningIndex, setEditOpeningIndex] = useState(0);
  const [searchText, setSearchText] = useState("");

  const [variationItemsDropdownList, setVariationItemsDropdownList] = useState(
    []
  );
  const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);

  const [pickDate, setPickDate] = useState(moment());

  //Docket start end date
  const [startDate, setStartDate] = useState(moment(Date.now()));
  const [endDate, setEndDate] = useState(moment(Date.now()));
  const [startDateModal, setStartDateModal] = useState(false);
  const [endDateModal, setEndDateModal] = useState(false);

  // Purchase start date and Purchase end date
  const [purchaseDate, setPurchaseDate] = useState(moment(Date.now()));
  const [purchaseEndDate, setPurchaseEndDate] = useState(moment(Date.now()));
  const [purchaseDateModal, setPurchaseDateModal] = useState(false);
  const [purchaseEndDateModal, setPurchaseEndDateModal] = useState(false);

  const [showPurchaseDatePicker, setShowPurchaseDatePicker] = useState(false);
  const [showPurchaseEndDatePicker, setShowPurchaseEndDatePicker] =
    useState(false);

  // Purchase start time and Purchase end time
  const [purchaseTime, setPurchaseTime] = useState(moment());
  const [purchaseEndTime, setPurchaseEndTime] = useState(moment());
  const [purchaseTimeModal, setPurchaseTimeModal] = useState(false);
  const [purchaseEndTimeModal, setPurchaseEndTimeModal] = useState(false);

  const [showPurchaseTimePicker, setShowPurchaseTimePicker] = useState(false);
  const [showPurchaseEndTimePicker, setShowPurchaseEndTimePicker] =
    useState(false);

  // Redeem start date and redeem time
  const [redeemDate, setRedeemDate] = useState(moment(Date.now()));
  const [redeemEndDate, setRedeemEndDate] = useState(moment(Date.now()));
  const [redeemDateModal, setRedeemDateModal] = useState(false);
  const [redeemEndEndDateModal, setRedeemEndDateModal] = useState(false);

  const [showRedeemDatePicker, setShowRedeemDatePicker] = useState(false);
  const [showRedeemEndDatePicker, setShowRedeemEndDatePicker] = useState(false);

  // Redeem start time and end time
  const [redeemTime, setRedeemTime] = useState(moment());
  const [redeemTimeModal, setRedeemTimeModal] = useState(false);

  const [showRedeemTimePicker, setShowRedeemTimePicker] = useState(false);

  const [redeemEndTime, setRedeemEndTime] = useState(moment());
  const [redeemEndTimeModal, setRedeemEndTimeModal] = useState(false);

  const [showRedeemEndTimePicker, setShowRedeemEndTimePicker] = useState(false);

  // Expired date and expired time
  const [expiredDate, setExpiredDate] = useState(moment());
  const [expiredDateModal, setExpiredDateModal] = useState(false);
  const [showExpiredDatePicker, setShowExpiredDatePicker] = useState(false);

  const [expiredTime, setExpiredTime] = useState(moment());
  const [expiredTimeModal, setExpiredTimeModal] = useState(false);

  const [showExpiredTimePicker, setShowExpiredTimePicker] = useState(false);

  const [termsConditions, setTermsConditions] = useState("");
  const [docketPrice, setDocketPrice] = useState("");
  const [bdProducts, setBdProducts] = useState([
    {
      productsId: "",
      productsName: "",
      quantity: 0,
    },
  ]);

  ///////////////////////////////////////////////////////////////////////
  const [image, setImage] = useState("");
  const [imageType, setImageType] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);

  const [
    openFileSelector,
    {
      plainFiles,
      filesContent,
      loading: loadingImageInput,
      clear: clearImageContainer,
      errors,
    },
  ] = useFilePicker({
    readAs: "DataURL",
    accept: ["image/*"],
    multiple: false,
  });

  // select image
  useEffect(() => {
    if (plainFiles.length && filesContent.length && !loadingImageInput) {
      setImage(filesContent[0].content);
      setImageType(
        filesContent[0].name.slice(filesContent[0].name.lastIndexOf("."))
      );
      setIsImageChanged(true);
    }

    if (errors.length) console.error(errors);
  }, [plainFiles, filesContent, loadingImageInput, errors]);

  const [bdId, setBdId] = useState("");

  const [bdOptions, setBdOptions] = useState([
    {
      optionsId: "",
      isActive: true,
      duration: 0,
      price: 0,
      unit: "day",
    },
  ]);

  const [bdItems, setBdItems] = useState([
    {
      bdItemId: "",
      outletItemSku: "",
      variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
      isActive: true,
      quantity: 0,
    },
  ]);

  const [categoryDropdownList, setCategoryDropdownList] = useState([]);
  const [selectedCategoryList, setSelectedCategoryList] = useState([]);

  const [outletDropdownList, setOutletDropdownList] = useState([]);
  const [selectedOutletList, setSelectedOutletList] = useState([]);

  const [displayQrModal, setDisplayQrModal] = useState(false);
  const [selectedBeerDocket, setSelectedBeerDocket] = useState({});

  const [bdSummaryDict, setBdSummaryDict] = useState({});

  const [
    chargesOfExtensionDateFormatType,
    setChargesOfExtensionDateFormatType,
  ] = useState(DATE_FORMAT_DROPDOWN_LIST[0].value);

  const [redemptionQuantityLimitValue, setRedemptionQuantityLimitValue] =
    useState("");
  const [
    redemptionQuantityLimitDateFormatValue,
    setRedemptionQuantityLimitDateFormatValue,
  ] = useState("");
  const [
    redemptionQuantityLimitDateFormatType,
    setRedemptionQuantityLimitDateFormatType,
  ] = useState(DATE_FORMAT_DROPDOWN_LIST[0].value);

  ///////////////////////////////////////////////////////////////////////

  const merchantId = UserStore.useState((s) => s.merchantId);
  const userName = UserStore.useState((s) => s.name);
  const userId = UserStore.useState((s) => s.firebaseUid);
  const merchantName = MerchantStore.useState((s) => s.name);
  const merchantLogo = MerchantStore.useState((s) => s.logo);

  const beerDocketCategories = OutletStore.useState(
    (s) => s.beerDocketCategories
  );
  const beerDocketCategoriesDict = OutletStore.useState(
    (s) => s.beerDocketCategoriesDict
  );
  const beerDockets = OutletStore.useState((s) => s.beerDockets);
  const userBeerDockets = OutletStore.useState((s) => s.userBeerDockets);

  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const allOutletsDict = MerchantStore.useState((s) => s.allOutletsDict);
  const outletItemsUnsorted = OutletStore.useState((s) => s.outletItems);
  const [outletItems, setOutletItems] = useState([]);

  const selectedBeerDocketEdit = CommonStore.useState(
    (s) => s.selectedBeerDocketEdit
  );
  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const allOutletsItemsSkuDict = OutletStore.useState(
    (s) => s.allOutletsItemsSkuDict
  );

  // extension
  const [showOptionsDropdownList, setShowOptionsDropdownList] = useState(false);
  const [optionsDropdownList, setOptionsDropdownList] = useState([]);
  const [selectedOptionsId, setSelectedOptionsId] = useState("");
  const selectedUserBeerDocket = CommonStore.useState(
    (s) => s.selectedUserBeerDocket
  );

  const [overviewTotalActive, setOverviewTotalActive] = useState(0);
  const [overviewTotalRedeemed, setOverviewTotalRedeemed] = useState(0);
  const [overviewTotalExpired, setOverviewTotalExpired] = useState(0);

  //   const [keyboardHeight] = useKeyboard();

  /////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    var bdSummaryDictTemp = {};

    for (var i = 0; i < beerDockets.length; i++) {
      var bdSummary = {
        activeCount: 0,
        redeemedCount: 0,
        expiredCount: 0,
      };

      for (var j = 0; j < userBeerDockets.length; j++) {
        if (userBeerDockets[j].beerDocketId === beerDockets[i].uniqueId) {
          var totalQuantity = userBeerDockets[j].bdItems.reduce(
            (accum, bd) => accum + bd.quantity,
            0
          );

          if (totalQuantity > 0) {
            if (moment().isBefore(userBeerDockets[j].expiryDate)) {
              bdSummary.activeCount += 1;
            } else {
              bdSummary.expiredCount += 1;
            }
          } else {
            bdSummary.redeemedCount += 1;
          }
        }
      }

      bdSummaryDictTemp[beerDockets[i].uniqueId] = bdSummary;
    }

    setBdSummaryDict(bdSummaryDictTemp);
  }, [beerDockets, userBeerDockets]);

  //Testing tab
  useEffect(() => {
    var overviewTotalActiveTemp = 0;
    var overviewTotalRedeemedTemp = 0;
    var overviewTotalExpiredTemp = 0;

    var bdSummaryDictTemp = {};

    for (var i = 0; i < beerDockets.length; i++) {
      var bdSummary = {
        activeCount: 0,
        redeemedCount: 0,
        expiredCount: 0,
      };

      for (var j = 0; j < userBeerDockets.length; j++) {
        if (userBeerDockets[j].beerDocketId === beerDockets[i].uniqueId) {
          var totalQuantity = userBeerDockets[j].bdItems.reduce(
            (accum, bd) => accum + bd.quantity,
            0
          );

          if (totalQuantity > 0) {
            if (moment().isBefore(userBeerDockets[j].expiryDate)) {
              overviewTotalActiveTemp += bdSummary.activeCount;
            } else {
              overviewTotalExpiredTemp += bdSummary.expiredCount;
            }
          } else {
            overviewTotalRedeemedTemp += bdSummary.redeemedCount;
          }
        }
      }

      bdSummaryDictTemp[beerDockets[i].uniqueId] = bdSummary;
    }

    setBdSummaryDict(bdSummaryDictTemp);
    setOverviewTotalActive(overviewTotalActiveTemp);
    setOverviewTotalRedeemed(overviewTotalRedeemedTemp);
    setOverviewTotalExpired(overviewTotalExpiredTemp);
  }, [beerDockets, userBeerDockets]);

  useEffect(() => {
    setVariationItemsDropdownList(
      outletItems.map((item) => ({ label: item.name, value: item.sku }))
    );
  }, [outletItems]);

  useEffect(() => {
    var outletItemsTemp = [...outletItemsUnsorted];

    outletItemsTemp.sort((a, b) => a.name.localeCompare(b.name));

    setOutletItems(outletItemsTemp);
  }, [outletItemsUnsorted]);

  useEffect(() => {
    if (variationItemsDropdownList.length > 0) {
      if (
        bdItems.length > 0 &&
        !bdItems[0].bdItemId &&
        !bdItems[0].outletItemSku
      ) {
        setBdItems([
          {
            bdItemId: "",
            outletItemSku: variationItemsDropdownList[0].value,
            variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
            isActive: true,
            quantity: 0,
          },
        ]);
      }
    }
  }, [
    variationItemsDropdownList,
    // bdItems,
  ]);

  useEffect(() => {
    console.log("================================");
    console.log("selectedBeerDocketEdit");
    console.log(selectedBeerDocketEdit);

    if (selectedBeerDocketEdit) {
      // insert info

      setBdId(selectedBeerDocketEdit.uniqueId);
      setBdItems(selectedBeerDocketEdit.bdItems);
      setBdOptions(selectedBeerDocketEdit.bdOptions);
      setDocketPrice(selectedBeerDocketEdit.price.toFixed(2));

      setStartDate(selectedBeerDocketEdit.startDate);
      setEndDate(selectedBeerDocketEdit.endDate);

      setPurchaseDate(
        selectedBeerDocketEdit.purchaseDate
          ? selectedBeerDocketEdit.purchaseDate
          : moment().valueOf()
      );
      setPurchaseEndDate(
        selectedBeerDocketEdit.purchaseEndDate
          ? selectedBeerDocketEdit.purchaseEndDate
          : moment().valueOf()
      );

      setPurchaseTime(
        selectedBeerDocketEdit.purchaseTime
          ? selectedBeerDocketEdit.purchaseTime
          : moment().valueOf()
      );
      setPurchaseEndTime(
        selectedBeerDocketEdit.purchaseEndTime
          ? selectedBeerDocketEdit.purchaseEndTime
          : moment().valueOf()
      );

      setRedeemDate(
        selectedBeerDocketEdit.redeemDate
          ? selectedBeerDocketEdit.redeemDate
          : moment().valueOf()
      );
      setRedeemEndDate(
        selectedBeerDocketEdit.redeemEndDate
          ? selectedBeerDocketEdit.redeemEndDate
          : moment().valueOf()
      );

      setRedeemTime(
        selectedBeerDocketEdit.redeemTime
          ? selectedBeerDocketEdit.redeemTime
          : moment().valueOf()
      );
      setRedeemEndTime(
        selectedBeerDocketEdit.redeemEndTime
          ? selectedBeerDocketEdit.redeemEndTime
          : moment().valueOf()
      );

      setExpiredDate(
        selectedBeerDocketEdit.expiredDate
          ? selectedBeerDocketEdit.expiredDate
          : moment().valueOf()
      );
      setExpiredTime(
        selectedBeerDocketEdit.expiredTime
          ? selectedBeerDocketEdit.expiredTime
          : moment().valueOf()
      );

      setTermsConditions(selectedBeerDocketEdit.tnc);
      setSelectedOutletList(selectedBeerDocketEdit.bdOutlets);

      setImage(
        selectedBeerDocketEdit.image ? selectedBeerDocketEdit.image : ""
      );
      setIsImageChanged(false);

      setChargesOfExtensionDateFormatType(
        selectedBeerDocketEdit.chargesOfExtensionDateFormatType
          ? selectedBeerDocketEdit.chargesOfExtensionDateFormatType
          : DATE_FORMAT_DROPDOWN_LIST[0].value
      );

      setRedemptionQuantityLimitValue(
        selectedBeerDocketEdit.redemptionQuantityLimitValue
          ? selectedBeerDocketEdit.redemptionQuantityLimitValue.toFixed(2)
          : "0.00"
      );
      setRedemptionQuantityLimitDateFormatValue(
        selectedBeerDocketEdit.redemptionQuantityLimitDateFormatValue
          ? selectedBeerDocketEdit.redemptionQuantityLimitDateFormatValue.toFixed(
              2
            )
          : "0"
      );
      setRedemptionQuantityLimitDateFormatType(
        selectedBeerDocketEdit.redemptionQuantityLimitDateFormatType
          ? selectedBeerDocketEdit.redemptionQuantityLimitDateFormatType
          : DATE_FORMAT_DROPDOWN_LIST[0].value
      );
    } else {
      // designed to always mounted, thus need clear manually...

      setBdId("");
      if (variationItemsDropdownList > 0) {
        setBdItems([
          {
            bdItemId: "",
            outletItemSku:
              variationItemsDropdownList > 0
                ? variationItemsDropdownList[0].value
                : "",
            variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
            isActive: true,
            quantity: 0,
          },
        ]);
      }
      setBdOptions([
        {
          optionsId: "",
          isActive: true,
          duration: 0,
          price: 0,
          unit: "day",
        },
      ]);
      setDocketPrice("0.00");

      setStartDate(moment());
      setEndDate(moment());

      setPurchaseDate(moment());
      setPurchaseEndDate(moment());

      setPurchaseTime(moment());
      setPurchaseEndTime(moment());

      setExpiredDate(moment());
      setExpiredTime(moment());

      setTermsConditions("");
      setSelectedOutletList([]);

      setImage("");
      setIsImageChanged(false);

      setChargesOfExtensionDateFormatType(DATE_FORMAT_DROPDOWN_LIST[0].value);

      setRedemptionQuantityLimitValue("0.00");
      setRedemptionQuantityLimitDateFormatValue("0.00");
      setRedemptionQuantityLimitDateFormatType(
        DATE_FORMAT_DROPDOWN_LIST[0].value
      );
    }
  }, [selectedBeerDocketEdit]);

  useEffect(() => {
    setCategoryDropdownList(
      beerDocketCategories.map((beerDocketCategory) => ({
        label: beerDocketCategory.name,
        value: beerDocketCategory.uniqueId,
      }))
    );

    // if (selectedCategoryId === '' && beerDocketCategories.length > 0) {
    //   setSelectedCategoryId(beerDocketCategories[0].uniqueId);
    // }
  }, [beerDocketCategories]);

  useEffect(() => {
    setOutletDropdownList(
      allOutlets.map((outlet) => ({
        label: outlet.name,
        value: outlet.uniqueId,
      }))
    );

    if (redemptionList) {
      if (selectedOutletList.length === 0 && allOutlets.length > 0) {
        setSelectedOutletList(allOutlets.map((outlet) => outlet.uniqueId));
      }
    }
  }, [allOutlets, redemptionList]);

  // extension
  useEffect(() => {
    if (selectedUserBeerDocket && selectedUserBeerDocket.uniqueId) {
      setOptionsDropdownList(
        selectedUserBeerDocket.bdOptions.map((bdOption) => ({
          label: `RM${bdOption.price
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")} for ${
            bdOption.duration
          } day${bdOption.duration > 1 ? "s" : ""}`,
          value: bdOption.optionsId,
        }))
      );

      if (
        selectedOptionsId === "" &&
        selectedUserBeerDocket.bdOptions.length > 0
      ) {
        setSelectedOptionsId(selectedUserBeerDocket.bdOptions[0].optionsId);
      }
    }

    return () => {
      CommonStore.update((s) => {
        // s.scannedQRBeerDocketResult = null;
        s.bdRedeemQuantity = 0;
      });
    };
  }, [selectedUserBeerDocket]);

  /////////////////////////////////////////////////////////////////////////

  const setState = () => {};

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View
        style={{
          width: Dimensions.get("screen").width * 0.17,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            // bottom: -2,
            bottom: switchMerchant ? "2%" : 0,
          },
          Dimensions.get("screen").width >= 768 && switchMerchant
            ? { right: Dimensions.get("screen").width * 0.1 }
            : {},
          Dimensions.get("screen").width <= 768
            ? { right: Dimensions.get("screen").width * 0.12 }
            : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Manage Docket
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: Dimensions.get("screen").height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get("screen").height * 0.05,
              height: Dimensions.get("screen").height * 0.05,
              borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: Dimensions.get("screen").height * 0.035,
                height: Dimensions.get("screen").height * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  const getRedemptionDetail = (param) => {
    /* ApiClient.GET(API.getOneSettingRedemption + param).then((result) => {
      setState({
        redemptionDetail: result,
        outletInfo1: result.outlet.name,
        category1: result.category.name,
        expiryPeriod: result.expiryPeriod,
        extentionCharges: result.extentionCharges,
        extentionDuration: result.extentionDuration,
      });

    }); */
  };

  const resetRedemptionDetail = () => {
    setState({
      redemptionDetail: [],
      outletInfo1: [],
      category1: [],
      expiryPeriod: "",
      extentionCharges: "",
      extentionDuration: "",
    });
  };

  // const componentDidMount = () => {

  //   /* ApiClient.GET(API.getOutletByMerchant + User.getMerchantId()).then((result) => {
  //     setState({ outletInfo: result });
  //     result.map((element) => {
  //       setState({
  //         outletId: element.id,
  //         outletName: element.name,
  //         merchantName: element.merchant.name
  //       });
  //     });
  //   });

  //   ApiClient.GET(API.getAllSettingRedemption + User.getMerchantId()).then((result) => {
  //     setState({ redemptionInfo: result });
  //   });

  //   outlet()
  //   ApiClient.GET(API.getOutletCategory + User.getOutletId()).then((result) => {
  //     if (result !== undefined) {
  //       setState({ categoryOutlet: result });
  //     }

  //   }); */
  // }

  const pushArray1 = (param) => {
    const push = outletss;
    push.push({
      outletId: param,
    });
  };

  const removePush1 = (param) => {
    const items = outletss;
    const filterArray = items.filter((item) => item.id !== param);
    setState({ alloutlet: filterArray });
  };

  const check3 = (param) => {
    if (isChecked12 == false) {
      setState({ isChecked12: true });
      removePush1(param);
    } else {
      setState({ isChecked12: false });
      pushArray1(param);
    }
  };

  const filter = (param) => {
    /* ApiClient.GET(API.getAllSettingRedemption + User.getMerchantId()).then((result) => {
      const result1 = result.filter((item) => item.outletId == param)
      setState({ redemptionInfo: result1 });
    }); */
  };

  const check1 = (price, duration) => {
    if (isChecked11 == false) {
      setState({
        isChecked11: true,
        extentionCharges: "0",
        extentionDuration: "0",
      });
    } else {
      setState({
        isChecked11: false,
        extentionCharges: price,
        extentionDuration: duration,
      });
    }
  };

  const outletFunc = () => {
    ApiClient.GET(API.getOutletByMerchant).then((result) => {
      const tmpCategories = {};
      for (const category of result) {
        const outletName = category.merchant.name;
        const outletId = category.id;
        if (!tmpCategories[outletName]) {
          tmpCategories[outletName] = {
            label: outletName,
            value: outletId,
          };
        }
      }
      const categories = Object.values(tmpCategories);
      setState({ outlets: categories });
    });
  };

  const onaddoption = () => {
    const extendOption = extendOption;
    extendOption.push({
      optionId: (extendOption.length + 1).toString(),
      price: "",
      day: "",
      days: days,
    });
    setState({ extendOption });
  };

  const renderProducts = () => {
    return (
      <>
        {bdItems.map((item, index) => {
          return (
            <View style={{ zIndex: 10001 + bdItems.length - index }}>
              <View
                style={{
                  flexDirection: "row",
                  //paddingBottom: 10,
                  // marginBottom: 15,
                  alignItems: "center",
                  //zIndex: 10001 + bdItems.length - index,
                }}
              >
                <View
                  style={{
                    // width: 260,
                    // height: 40,
                    // justifyContent: 'center',
                    // //alignItems: 'flex-end',
                    alignSelf: "flex-end",
                    marginRight: "4.5%",
                  }}
                >
                  {/* <DropDownPicker
                    items={outletItems}
                    containerStyle={{ height: 40 }}
                    placeholder={"Choose categories"}
                    placeholderStyle={{ color: 'black' }}
                    style={{ backgroundColor: '#fafafa', width: 250 }}
                    itemStyle={{
                      justifyContent: 'flex-start', marginLeft: 5,
                    }}
                    searchable={true}
                    dropDownStyle={{
                      backgroundColor: '#fafafa',
                      width: 250,
                    }}
                    onChangeItem={(item) => {
                      // setState({
                      //   category: item.value
                      // });
                      // console.log(item);
                       setSelectedCategoryList(item)

                      // setBdProducts(bdProducts.map((bdProduct, i) => (i === index ? {
                      //   ...bdProduct,
                      //   productsName: item.length > 0 ? (item) : 0,
                      // } : bdProduct)))
                    }}
                    //defaultValue={item.productsName}
                    defaultValue={selectedCategoryList}
                  /> */}
                  {variationItemsDropdownList.find(
                    (variationItem) =>
                      variationItem.value === item.outletItemSku
                  ) ? (
                    <View style={{ zIndex: index - 1 }}>
                      <Picker
                        selectedValue={item.outletItemSku}
                        style={{}}
                        onValueChange={(variationItem) => {
                          setBdItems(
                            bdItems.map((bdItem, i) =>
                              i === index
                                ? {
                                    ...bdItem,
                                    outletItemSku: variationItem,
                                  }
                                : bdItem
                            )
                          );
                        }}
                      >
                        {variationItemsDropdownList.map((value, index) => {
                          return (
                            <Picker.Item
                              key={index}
                              label={value.label}
                              value={value.value}
                            />
                          );
                        })}
                      </Picker>
                      {/* <DropDownPicker
                        // items={categoryOutlet.map((item) => ({
                        //   label: item.categoryName, //<= after hayden change you need to change it to item.name
                        //   value: item.categoryId,
                        // }))}
                        //items={outletItems}
                        containerStyle={[
                          { height: 40 },
                        ]}
                        arrowColor={"black"}
                        arrowSize={20}
                        arrowStyle={{ fontWeight: "bold" }}
                        labelStyle={{
                          fontFamily: "NunitoSans-Regular",
                          fontSize: switchMerchant ? 10 : 14,
                        }}
                        style={[
                          {
                            width: 263,
                            paddingVertical: 0,
                            backgroundColor: Colors.fieldtBgColor,
                            borderRadius: 10,
                          },
                          switchMerchant
                            ? {
                                width: 200,
                              }
                            : {},
                        ]}
                        placeholderStyle={{
                          color: Colors.fieldtTxtColor,
                          fontFamily: "NunitoSans-Regular",
                          fontSize: switchMerchant ? 10 : 14,
                        }}
                        items={variationItemsDropdownList}
                        itemStyle={{
                          justifyContent: "flex-start",
                          marginLeft: 5,
                          zIndex: 2,
                        }}
                        placeholder={"Choose items"}
                        // here
                        onChangeItem={(variationItem) => {
                          setBdItems(
                            bdItems.map((bdItem, i) =>
                              i === index
                                ? {
                                    ...bdItem,
                                    outletItemSku: variationItem.value,
                                  }
                                : bdItem
                            )
                          );
                        }}
                        zIndex={10000 + bdItems.length - index}
                        defaultValue={item.outletItemSku}
                        searchable={true}
                        dropDownMaxHeight={350}
                        dropDownStyle={[
                          {
                            width: 263,
                            height: 150,
                            backgroundColor: Colors.fieldtBgColor,
                            borderRadius: 10,
                            borderWidth: 1,
                            textAlign: "left",
                            zIndex: 2,
                          },
                          switchMerchant
                            ? {
                                width: 200,
                              }
                            : {},
                        ]}
                        globalTextStyle={{
                          fontSize: switchMerchant ? 10 : 16,
                        }}

                        // items={variationItemsDropdownList}
                        // containerStyle={{ height: 40 }}
                        // placeholder={"Choose items"}
                        // placeholderStyle={{ color: 'black' }}
                        // style={{ backgroundColor: '#fafafa', width: 260 }}
                        // itemStyle={{
                        //   justifyContent: 'flex-start',
                        // }}
                        // // defaultValue={category1}
                        // //defaultValue={selectedCategoryList}
                        // searchable={true}
                        // dropDownStyle={{
                        //   backgroundColor: '#fafafa',
                        //   width: 265,
                        // }}
                        // onChangeItem={(variationItem) => {
                        //   // setState({
                        //   //   category: item.value
                        //   // });
                        //   // console.log(item);
                        //   // setSelectedCategoryList(item)
                        //   setBdItems(bdItems.map((bdItem, i) => (i === index ? {
                        //     ...bdItem,
                        //     outletItemSku: variationItem.value,
                        //   } : bdItem)))
                        // }}
                        // defaultValue={item.outletItemSku}
                      /> */}
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                <View style={{}}>
                  <Text
                    style={[
                      {
                        fontFamily: "NunitoSans-Bold",
                        fontSize: 14,
                        color: Colors.fieldtTxtColor,
                        fontWeight: "500",
                        paddingTop: 5,
                      },
                      switchMerchant
                        ? {
                            fontSize: 10,
                          }
                        : {},
                    ]}
                  >
                    Quantity
                  </Text>
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onPress={() => {
                        setBdItems(
                          bdItems.map((bdItem, i) =>
                            i === index
                              ? {
                                  ...bdItem,
                                  quantity:
                                    bdItem.quantity - 1 >= 0
                                      ? bdItem.quantity - 1
                                      : 0,
                                }
                              : bdItem
                          )
                        );
                      }}
                    >
                      <View
                        style={[
                          {
                            flex: 1,
                            backgroundColor: Colors.primaryColor,
                            width: 30,
                            height: 40,
                            justifyContent: "center",
                            alignItems: "center",
                            borderColor: "transparent",
                          },
                          switchMerchant
                            ? {
                                transform: [{ scaleY: 0.85 }],
                              }
                            : {},
                        ]}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 30,
                              height: 40,
                              fontWeight: "500",
                              color: Colors.whiteColor,
                              marginBottom: Platform.OS === "ios" ? 0 : 6,
                            },
                            switchMerchant
                              ? {
                                  height: 35,
                                  textAlign: "center",
                                }
                              : {},
                          ]}
                        >
                          -
                        </Text>
                      </View>
                    </TouchableOpacity>

                    <View
                      style={[
                        {
                          backgroundColor: Colors.whiteColor,
                          borderWidth: StyleSheet.hairlineWidth,
                          borderColor: Colors.descriptionColor,
                          borderWidth: 1.5,
                          width: 40,
                          justifyContent: "center",
                          alignItems: "center",
                        },
                        switchMerchant
                          ? {
                              transform: [{ scaleY: 0.85 }],
                            }
                          : {},
                      ]}
                    >
                      <Text>{item.quantity}</Text>
                      {/* <TextInput
                        style={[
                          {
                            borderColor: "#E5E5E5",
                            borderWidth: 1,
                            // borderRadius: 5,
                            backgroundColor: Colors.fieldtBgColor,
                            height: 40,
                            alignItem: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            marginTop: 0,
                            marginBottom: 0,
                            marginVertical: 0,
                            paddingTop: 10,
                            fontFamily: "NunitoSans-Regular",
                            fontSize: switchMerchant ? 10 : 14,
                          },
                          switchMerchant
                            ? {
                                transform: [{ scaleY: 0.95 }],
                              }
                            : {},
                        ]}
                        placeholderTextColor={Platform.select({
                          ios: "#a9a9a9",
                        })}
                        placeholderStyle={{
                          fontFamily: "NunitoSans-Regular",
                          fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder={"0"}
                        multiline={true}
                        onChangeText={(text) => {
                          // setBdProducts(bdProducts.map((bdProduct, i) => (i === index ? {
                          //   ...bdProduct,
                          //   quantity: text.length > 0 ? (text) : 0,
                          // } : bdProduct)))

                          setBdItems(
                            bdItems.map((bdItem, i) =>
                              i === index
                                ? {
                                    ...bdItem,
                                    quantity: text.length > 0 ? text : "0.00",
                                  }
                                : bdItem
                            )
                          );
                        }}
                        defaultValue={item.quantity.toString()}
                        // defaultValue={item.quantity}
                        keyboardType={"number-pad"}
                        // value={item.quantity}
                      /> */}
                    </View>

                    <TouchableOpacity
                      onPress={() => {
                        setBdItems(
                          bdItems.map((bdItem, i) =>
                            i === index
                              ? {
                                  ...bdItem,
                                  quantity: bdItem.quantity + 1,
                                }
                              : bdItem
                          )
                        );
                      }}
                    >
                      <View
                        style={[
                          {
                            flex: 1,
                            backgroundColor: Colors.primaryColor,
                            width: 30,
                            height: 40,
                            justifyContent: "center",
                            alignItems: "center",
                            borderColor: "transparent",
                          },
                          switchMerchant
                            ? {
                                // height: 35,
                                transform: [{ scaleY: 0.85 }],
                              }
                            : {},
                        ]}
                      >
                        <Text
                          style={[
                            {
                              fontSize: 30,
                              height: 40,
                              // fontWeight: "500",
                              color: Colors.whiteColor,
                              marginBottom: Platform.OS === "ios" ? 0 : 5,
                            },
                          ]}
                        >
                          +
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={[
                    {
                      //justifyContent: 'flex-end',
                      alignSelf: "flex-end",
                      alignItems: "flex-end",
                      height: 40,
                      zIndex: 3,
                      //marginTop: 5,
                    },
                    switchMerchant
                      ? {
                          height: 35,
                        }
                      : {},
                  ]}
                >
                  {index === 0 ? (
                    <TouchableOpacity
                      onPress={() => {
                        // onadditem1();
                        setBdItems([
                          ...bdItems,
                          {
                            bdItemId: "",
                            outletItemSku: variationItemsDropdownList[0].value,
                            variation:
                              PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
                            isActive: true,
                            quantity: 0,
                          },
                        ]);
                      }}
                      style={[
                        {
                          flex: 1,
                          marginBottom: "15%",
                          marginLeft: Platform.OS == "ios" ? 9 : 9,
                          backgroundColor: Colors.whiteColor,
                          alignItems: "center",
                          flexDirection: "row",
                          height: "100%",
                          paddingBottom: "5%",
                          // borderWidth:1
                        },
                        switchMerchant
                          ? {
                              // paddingTop:'6.2%',alignSelf:'center', justifyContent:'center'
                            }
                          : {},
                      ]}
                    >
                      <Icon
                        name="plus-circle"
                        size={switchMerchant ? 17 : 20}
                        color={Colors.primaryColor}
                      />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={[
                        {
                          flex: 1,
                          // paddingTop: '5.2%',
                          marginLeft: Platform.OS == "ios" ? 9 : 9,
                          backgroundColor: Colors.whiteColor,
                          alignItems: "center",
                          // justifyContent:'center',
                          flexDirection: "row",
                          height: "100%",
                          marginBottom: "15%",
                          paddingBottom: "5%",
                        },
                        switchMerchant
                          ? {
                              // paddingTop:'6.2%',alignSelf:'center', justifyContent:'center'
                            }
                          : {},
                      ]}
                      onPress={() => {
                        setBdItems([
                          ...bdItems.slice(0, index),
                          ...bdItems.slice(index + 1),
                        ]);
                      }}
                    >
                      <Icon
                        name="minus-circle"
                        size={switchMerchant ? 17 : 20}
                        color="#eb3446"
                      />
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            </View>
          );
        })}
      </>
    );
  };

  const renderOptions = () => {
    const options = [];

    return (
      <>
        {bdOptions.map((item, index) => {
          return (
            <View
              style={{
                zIndex: 10001 + bdOptions.length - index,
                // height: 64,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  zIndex: 10001 + bdOptions.length - index,
                }}
              >
                <View
                  style={
                    {
                      //justifyContent: 'flex-end',
                      //alignItems: 'center',
                    }
                  }
                >
                  <DateTimePickerModal
                    date={new Date()}
                    minimumDate={new Date()}
                    isVisible={showDateTimePicker1}
                    mode={"date"}
                    //display={"default"} //for iOS to use minuteInterval
                    onConfirm={(text) => {
                      if (pickDate == "startDate") {
                        setStartDate(text);
                      } else {
                        setEndDate(text);
                      }
                      setShowDateTimePicker1(false);
                    }}
                    onCancel={() => {
                      setShowDateTimePicker1(false);
                    }}
                  />

                  <DateTimePickerModal
                    date={new Date()}
                    minimumDate={new Date()}
                    isVisible={showPurchaseDatePicker}
                    mode={"date"}
                    onConfirm={(text) => {
                      setPurchaseDate(moment(text));

                      setShowPurchaseDatePicker(false);
                    }}
                    onCancel={() => {
                      setShowPurchaseDatePicker(false);
                    }}
                  />

                  <DateTimePickerModal
                    date={new Date()}
                    minimumDate={new Date()}
                    isVisible={showPurchaseEndDatePicker}
                    mode={"date"}
                    onConfirm={(text) => {
                      setPurchaseEndDate(moment(text));

                      setShowPurchaseEndDatePicker(false);
                    }}
                    onCancel={() => {
                      setShowPurchaseEndDatePicker(false);
                    }}
                  />

                  <DateTimePickerModal
                    isVisible={showPurchaseTimePicker}
                    mode={"time"}
                    onConfirm={(text) => {
                      setPurchaseTime(moment(text));

                      setShowPurchaseTimePicker(false);
                    }}
                    onCancel={() => {
                      setShowPurchaseTimePicker(false);
                    }}
                  />

                  <DateTimePickerModal
                    isVisible={showPurchaseEndTimePicker}
                    mode={"time"}
                    onConfirm={(text) => {
                      setPurchaseEndTime(moment(text));

                      setShowPurchaseEndTimePicker(false);
                    }}
                    onCancel={() => {
                      setShowPurchaseEndTimePicker(false);
                    }}
                  />

                  <DateTimePickerModal
                    date={new Date()}
                    minimumDate={new Date()}
                    isVisible={showRedeemDatePicker}
                    mode={"date"}
                    onConfirm={(text) => {
                      setRedeemDate(moment(text));

                      setShowRedeemDatePicker(false);
                    }}
                    onCancel={() => {
                      setShowRedeemDatePicker(false);
                    }}
                  />

                  <DateTimePickerModal
                    date={new Date()}
                    minimumDate={new Date()}
                    isVisible={showRedeemEndDatePicker}
                    mode={"date"}
                    onConfirm={(text) => {
                      setRedeemEndDate(moment(text));

                      setShowRedeemEndDatePicker(false);
                    }}
                    onCancel={() => {
                      setShowRedeemEndDatePicker(false);
                    }}
                  />

                  <DateTimePickerModal
                    isVisible={showRedeemTimePicker}
                    mode={"time"}
                    onConfirm={(text) => {
                      setRedeemTime(moment(text));

                      setShowRedeemTimePicker(false);
                    }}
                    onCancel={() => {
                      setShowRedeemTimePicker(false);
                    }}
                  />

                  <DateTimePickerModal
                    isVisible={showRedeemEndTimePicker}
                    mode={"time"}
                    onConfirm={(text) => {
                      setRedeemEndTime(moment(text));

                      setShowRedeemEndTimePicker(false);
                    }}
                    onCancel={() => {
                      setShowRedeemEndTimePicker(false);
                    }}
                  />

                  <DateTimePickerModal
                    date={new Date()}
                    minimumDate={new Date()}
                    isVisible={showExpiredDatePicker}
                    mode={"date"}
                    onConfirm={(text) => {
                      setExpiredDate(moment(text));

                      setShowExpiredDatePicker(false);
                    }}
                    onCancel={() => {
                      setShowExpiredDatePicker(false);
                    }}
                  />

                  <DateTimePickerModal
                    isVisible={showExpiredTimePicker}
                    mode={"time"}
                    onConfirm={(text) => {
                      setExpiredTime(moment(text));

                      setShowExpiredTimePicker(false);
                    }}
                    onCancel={() => {
                      setShowExpiredTimePicker(false);
                    }}
                  />

                  {/* <CheckBox
                    style={[
                      {
                        padding: 0,
                        marginRight: 10,
                        //flexDirection: 'row',
                      },
                      switchMerchant
                        ? {
                            transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
                          }
                        : {},
                    ]}
                    onClick={() => {
                      // setState({
                      //   isChecked11: !isChecked11,
                      // });
                      // check1(extentionCharges, extentionDuration)

                      setBdOptions(
                        bdOptions.map((bdOption, i) =>
                          i === index
                            ? {
                                ...bdOption,
                                isActive: !bdOption.isActive,
                              }
                            : bdOption
                        )
                      );
                    }}
                    checkBoxColor={Colors.fieldtBgColor}
                    uncheckedCheckBoxColor={Colors.tabGrey}
                    checkedCheckBoxColor={Colors.primaryColor}
                    isChecked={item.isActive}
                  /> */}

                  <input
                    onChange={() => {
                      setBdOptions(
                        bdOptions.map((bdOption, i) =>
                          i === index
                            ? {
                                ...bdOption,
                                isActive: !bdOption.isActive,
                              }
                            : bdOption
                        )
                      );
                    }}
                    style={{
                      marginRight: 10,
                    }}
                    type={"checkbox"}
                    checked={item.isActive}
                  />
                </View>
                <View
                  style={{
                    marginHorizontal: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    //marginTop: 12,
                  }}
                >
                  <Text
                    style={[
                      { fontFamily: "NunitoSans-Regular", fontSize: 14 },
                      switchMerchant
                        ? {
                            fontSize: 10,
                          }
                        : {},
                    ]}
                  >
                    RM
                  </Text>
                </View>
                <View
                  style={[
                    styles.textInput8,
                    {
                      // borderWidth: 1,
                      borderRadius: 5,
                      justifyContent: "center",
                    },
                  ]}
                >
                  {/* <View style={{ justifyContent: "center" }}> */}
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={[
                      {
                        paddingLeft: 5,
                        borderWidth: 1,
                        borderColor: "#E5E5E5",
                        fontFamily: "NunitoSans-Regular",
                        fontSize: 14,
                        borderRadius: 5,
                        height: "100%",
                      },
                    ]}
                    placeholderStyle={{
                      alignContent: "center",
                      fontFamily: "NunitoSans-Regular",
                      fontSize: switchMerchant ? 10 : 14,
                    }}
                    placeholder="Amount"
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    defaultValue={item.price
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                    onChangeText={(text) => {
                      setBdOptions(
                        bdOptions.map((bdOption, i) =>
                          i === index
                            ? {
                                ...bdOption,
                                price: text.length > 0 ? parseFloat(text) : 0,
                              }
                            : bdOption
                        )
                      );
                    }}
                    value={item.price}
                    ref={myTextInput}
                  />
                </View>
                {/* </View> */}
                <View
                  style={{
                    marginHorizontal: 15,
                    justifyContent: "center",
                    alignItems: "center",
                    //marginTop: 12,
                  }}
                >
                  <Text
                    style={[
                      { fontFamily: "NunitoSans-Regular", fontSize: 14 },
                      switchMerchant
                        ? {
                            fontSize: 10,
                          }
                        : {},
                    ]}
                  >
                    For
                  </Text>
                </View>
                <View style={{ marginRight: "3%", marginLeft: "0%" }}>
                  <View
                    style={[
                      styles.textInput10,
                      {
                        borderColor: "#E5E5E5",
                        borderWidth: 1,
                        // top:
                        //   Platform.OS === 'android'
                        //     ? 0
                        //     : keyboardHeight > 0
                        //     ? -keyboardHeight * 0.1
                        //     : 0,
                      },
                    ]}
                  >
                    <View style={{ justifyContent: "center" }}>
                      <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={[
                          {
                            paddingLeft: 5,
                            fontFamily: "NunitoSans-Regular",
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                                fontSize: 10,
                              }
                            : {},
                        ]}
                        placeholderTextColor={Platform.select({
                          ios: "#a9a9a9",
                        })}
                        placeholderStyle={{
                          fontFamily: "NunitoSans-Regular",
                          fontSize: switchMerchant ? 10 : 14,
                        }}
                        placeholder="Period"
                        defaultValue={item.duration}
                        onChangeText={(text) => {
                          // const extendOption = extendOption;
                          // const item = extendOption.find(
                          //   (obj) => obj.id === extendOption.id,
                          // );
                          // item.day = text;
                          // setState({ extendOption, extentionDuration: text });

                          setBdOptions(
                            bdOptions.map((bdOption, i) =>
                              i === index
                                ? {
                                    ...bdOption,
                                    duration: text.length > 0 ? text : "0.00",
                                  }
                                : bdOption
                            )
                          );
                        }}
                        // value={(value) => {
                        //   const extendOption = extendOption;
                        //   const item = extendOption.find(
                        //     (obj) => obj.id === extendOption.id,
                        //   );
                        //   value = item.day;
                        // }}
                        value={item.duration}
                        ref={myTextInput}
                        keyboardType={"number-pad"}
                      />
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    marginHorizontal: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    //marginTop: 16,
                    zIndex: index - 1,
                  }}
                >
                  <Picker
                    selectedValue={chargesOfExtensionDateFormatType}
                    style={{
                      width: 100,
                      paddingVertical: 0,
                      backgroundColor: Colors.fieldtBgColor,
                      borderRadius: 10,
                    }}
                    onValueChange={(item) => {
                      setChargesOfExtensionDateFormatType(item);
                    }}
                  >
                    {DATE_FORMAT_DROPDOWN_LIST
                      // .concat({
                      //   label: "All",
                      //   value: !showDetails
                      //     ? dailySalesDetailsList.length
                      //     : transactionTypeSalesDetails.length,
                      // })
                      .map((value, index) => {
                        return (
                          <Picker.Item
                            key={index}
                            label={value.label}
                            value={value.value}
                          />
                        );
                      })}
                  </Picker>
                  {/* <DropDownPicker
                    containerStyle={[
                      { height: 40 },
                      switchMerchant
                        ? {
                            height: 35,
                          }
                        : {},
                    ]}
                    arrowColor={"black"}
                    arrowSize={20}
                    arrowStyle={{ fontWeight: "bold" }}
                    labelStyle={{
                      fontFamily: "NunitoSans-Regular",
                      fontSize: switchMerchant ? 10 : 14,
                    }}
                    style={{
                      width: 100,
                      paddingVertical: 0,
                      backgroundColor: Colors.fieldtBgColor,
                      borderRadius: 10,
                    }}
                    placeholderStyle={{
                      color: Colors.fielFdtTxtColor,
                      fontFamily: "NunitoSans-Regular",
                      fontSize: switchMerchant ? 10 : 14,
                    }}
                    items={DATE_FORMAT_DROPDOWN_LIST}
                    itemStyle={{
                      justifyContent: "flex-start",
                      marginLeft: 5,
                      zIndex: 2,
                    }}
                    placeholder={"Day"}
                    onChangeItem={(item) => {
                      setChargesOfExtensionDateFormatType(item.value);
                    }}
                    zIndex={10000 + bdOptions.length - index}
                    defaultValue={chargesOfExtensionDateFormatType}
                    dropDownMaxHeight={150}
                    dropDownStyle={{
                      width: 100,
                      height: 120,
                      backgroundColor: Colors.fieldtBgColor,
                      borderRadius: 10,
                      borderWidth: 1,
                      textAlign: "left",
                      zIndex: 2,
                    }}
                    globalTextStyle={{
                      fontSize: switchMerchant ? 10 : 14,
                    }}

                    // containerStyle={{ height: 40 }}
                    //   arrowColor={'black'}
                    //   arrowSize={20}
                    //   arrowStyle={{ fontWeight: 'bold' }}
                    //   labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                    //   style={{ width: 100, height: 40, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, zIndex: -1, }}
                    //   placeholderStyle={{ color: 'black' }}
                    //   items={DATE_FORMAT_DROPDOWN_LIST}
                    //   itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                    //   placeholder={"Day"}
                    //   onChangeItem={item => {
                    //     setChargesOfExtensionDateFormatType(item.value);
                    //   }}

                    //   defaultValue={chargesOfExtensionDateFormatType}
                    //   dropDownMaxHeight={150}
                    //   dropDownStyle={{ width: 100, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1 }}
                  /> */}
                </View>

                <View
                  style={{
                    //justifyContent: 'flex-end',
                    alignSelf: "flex-end",
                    alignItems: "flex-end",
                    height: 45,
                    zIndex: 3,
                    //marginTop: 5,
                  }}
                >
                  {index === 0 ? (
                    <TouchableOpacity
                      onPress={() => {
                        // onaddoption()
                        setBdOptions([
                          ...bdOptions,
                          {
                            optionsId: "",
                            isActive: true,
                            duration: 0,
                            price: 0,
                            unit: "day",
                          },
                        ]);
                      }}
                      style={[
                        {
                          // marginTop: 20,
                          marginLeft: 5,
                          backgroundColor: Colors.whiteColor,
                          alignItems: "center",
                          flexDirection: "row",
                          height: "100%",
                        },
                        switchMerchant
                          ? {
                              justifyContent: "center",
                            }
                          : {},
                      ]}
                    >
                      <Icon
                        name="plus-circle"
                        size={switchMerchant ? 17 : 20}
                        color={Colors.primaryColor}
                      />
                    </TouchableOpacity>
                  ) : (
                    <TouchableOpacity
                      style={{
                        // marginTop: 20,
                        marginLeft: 5,
                        backgroundColor: Colors.whiteColor,
                        alignItems: "center",
                        flexDirection: "row",
                        height: "100%",
                      }}
                      onPress={() => {
                        setBdOptions([
                          ...bdOptions.slice(0, index),
                          ...bdOptions.slice(index + 1),
                        ]);
                      }}
                    >
                      <Icon
                        name="minus-circle"
                        size={switchMerchant ? 17 : 20}
                        color="#eb3446"
                      />
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            </View>
          );
        })}
      </>
    );
  };

  const orderFunc = () => {
    var body = {
      merchantId: User.getMerchantId(),
      isAllOutlet: isChecked6 == true ? "1" : null,
      outletId: outletId,
      deliveryDistance: showDistance,
      deliveryFee: isChecked6 == true ? amount : amount1,
      deliveryHourStart: hourStart,
      deliveryHourEnd: hourEnd,
      deliveryPrice: isChecked8 == true ? value1 : 0,
      pickUpPrice: isChecked9 == true ? value2 : 0,
      fireorder: status1,
      category: category,
    };
    ApiClient.POST(API.createSettingOrder, body, false).then((result) => {
      if (result.outletId != null) {
        Alert.alert(
          "Congratulation!",
          "You have successfully created",
          [
            {
              text: "OK",
              onPress: () => {},
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  const renderItem = ({ item }) => {
    // var categoryNames = [];
    // for (var i = 0; i < item.bdCategories.length; i++) {
    //   if (beerDocketCategoriesDict[item.bdCategories[i]]) {
    //     categoryNames.push(beerDocketCategoriesDict[item.bdCategories[i]].name);
    //   }
    // }

    // var outletNames = [];
    // for (var i = 0; i < item.bdOutlets.length; i++) {
    //   if (allOutletsDict[item.bdOutlets[i]]) {
    //     outletNames.push(allOutletsDict[item.bdOutlets[i]].name);
    //   }
    // }

    const outletItemsStrList = [];
    for (var i = 0; i < item.bdItems.length; i++) {
      if (
        allOutletsItemsSkuDict[item.bdItems[i].outletItemSku] &&
        allOutletsItemsSkuDict[item.bdItems[i].outletItemSku].length > 0
      ) {
        outletItemsStrList.push(
          `${allOutletsItemsSkuDict[item.bdItems[i].outletItemSku][0].name} x${
            item.bdItems[i].quantity
          }`
        );
      }
    }

    return (
      <>
        <View
          style={{
            backgroundColor: "#ffffff",
            // borderRadius: 5,
            flexDirection: "row",
            paddingVertical: 20,
            paddingHorizontal: 10,
            borderTopWidth: StyleSheet.hairlineWidth,
            borderTopColor: "#c4c4c4",
            //width: Dimensions.get('screen').width * 0.888,
            //width: Dimensions.get('screen').width * 0.85,
          }}
        >
          {/* <Text
                style={{
                  paddingLeft: 15,
                  flex: 2,
                  marginLeft: 15,
                  alignSelf: 'center',
                  fontFamily: 'NunitoSans-SemiBold',
                  fontSize: 18,
                }}>
                {item.outlet.name}
              </Text> */}
          <View
            style={[
              {
                marginRight: 9,
                width: "35%",
                //backgroundColor: 'red',
              },
              switchMerchant ? {} : {},
            ]}
          >
            <Text
              style={[
                {
                  fontSize: 14,
                  fontFamily: "NunitoSans-Regular",
                  //marginRight: '20%',
                },
                switchMerchant ? { fontSize: 10 } : {},
              ]}
            >
              {/* {item.bdCategoryNames ? item.bdCategoryNames.join(', ') : ''} */}
              {outletItemsStrList.length > 0
                ? outletItemsStrList.join(",\n")
                : "N/A"}
            </Text>
          </View>
          <View
            style={[
              {
                marginRight: 8,
                width: "27%",
                //alignItems: 'center',
                //alignSelf: 'center',
              },
              switchMerchant
                ? {
                    // width: '32.5%'
                  }
                : {},
            ]}
          >
            <View style={{ alignSelf: "baseline" }}>
              <Text
                style={[
                  {
                    //alignSelf: 'center',
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  },
                  switchMerchant ? { fontSize: 10 } : {},
                ]}
              >
                {`${moment(item.startDate).format("DD MMM YYYY")} - ${moment(
                  item.endDate
                ).format("DD MMM YYYY")}`}
              </Text>
              {/* <Text
                style={{
                  alignSelf: 'center',
                  fontSize: 15,
                  fontFamily: 'NunitoSans-SemiBold',
                  marginVertical: -5,
                }}>
                -
              </Text>
              <Text
                style={{
                  //alignSelf: 'center',
                  fontSize: 15,
                  fontFamily: 'NunitoSans-SemiBold'
                }}>
                {moment(item.endDate).format('DD MMM YYYY')}
              </Text> */}
            </View>
          </View>
          <View
            style={[
              {
                marginRight: 8,
                width: "9%",
              },
              switchMerchant
                ? {
                    // width: '7%'
                  }
                : {},
            ]}
          >
            <Text
              style={[
                {
                  fontSize: 14,
                  fontFamily: "NunitoSans-Regular",
                },
                switchMerchant ? { fontSize: 10 } : {},
              ]}
            >
              {bdSummaryDict[item.uniqueId]
                ? bdSummaryDict[item.uniqueId].activeCount
                : 0}
            </Text>
          </View>
          <View
            style={[
              {
                marginRight: 8,
                width: "9%",
              },
              switchMerchant
                ? {
                    // width: '8%'
                  }
                : {},
            ]}
          >
            <Text
              style={[
                {
                  fontSize: 14,
                  fontFamily: "NunitoSans-Regular",
                },
                switchMerchant ? { fontSize: 10 } : {},
              ]}
            >
              {bdSummaryDict[item.uniqueId]
                ? bdSummaryDict[item.uniqueId].expiredCount
                : 0}
            </Text>
          </View>
          <View
            style={[
              {
                marginRight: 8,
                width: "10%",
              },
              switchMerchant
                ? {
                    width: "9%",
                  }
                : {},
            ]}
          >
            <Text
              style={[
                {
                  fontSize: 14,
                  fontFamily: "NunitoSans-Regular",
                },
                switchMerchant ? { fontSize: 10 } : {},
              ]}
            >
              {bdSummaryDict[item.uniqueId]
                ? bdSummaryDict[item.uniqueId].redeemedCount
                : 0}
            </Text>
          </View>
          <View
            style={[
              {
                alignItems: "center",
                width: "5%",
                justifyContent: "space-between",
              },
              switchMerchant ? {} : {},
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                CommonStore.update((s) => {
                  s.selectedBeerDocketEdit = item;
                });

                setRedemptionList(false);
              }}
            >
              <Icon
                name="edit"
                size={switchMerchant ? 15 : 23}
                color={Colors.primaryColor}
              />
            </TouchableOpacity>

            {/* <TouchableOpacity
              onPress={() => {
                setSelectedBeerDocket(item);

                setDisplayQrModal(true)
              }}
              style={{
                //position: 'absolute',
                // right: Dimensions.get('screen').width * 0.03,
                //bottom: 0,
                marginTop: 10,
              }}>
              <Image style={{ height: 25, width: 25 }} resizeMode="contain" source={require('../assets/image/qr.png')} />
            </TouchableOpacity> */}
          </View>
        </View>

        <Modal style={{ flex: 1 }} visible={displayQrModal} transparent={true}>
          <View style={styles.modalContainer}>
            <View style={styles.modalView}>
              <TouchableOpacity
                style={styles.closeButton}
                onPress={() => {
                  setDisplayQrModal(false);
                }}
              >
                <AIcon
                  name="closecircle"
                  size={25}
                  color={Colors.fieldtTxtColor}
                />
              </TouchableOpacity>

              <View style={styles.modalBody}>
                {/* todo */}
                {/* <QRCode
                  // value={JSON.stringify(qrData)}
                  // value={selectedOutletTable.uniqueId ? selectedOutletTable.uniqueId : ''}
                  value={
                    selectedBeerDocket.uniqueId
                      ? JSON.stringify({
                          uniqueId: selectedBeerDocket.uniqueId,
                          // outletId: selectedOutletTable.outletId, // skip outlet first
                          // code: selectedOutletTable.code,

                          waiterId: userId,
                          waiterName: userName,
                        })
                      : ''
                  }
                  size={Dimensions.get('screen').width * 0.2}
                  logoBackgroundColor="transparent"
                /> */}

                <View
                  style={[
                    styles.modalTitle,
                    {
                      marginTop: 30,
                    },
                  ]}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 22,
                    }}
                  >
                    Scan the QR code to redeem.
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </Modal>
      </>
    );
  };

  // const redemptionFunc = () => {
  //   var body = {
  //     merchantId: User.getMerchantId(),
  //     isAllOutlet: isChecked13 == true ? '1' : null,
  //     outletId: outletId,
  //     category: category,
  //     expiryPeriod: expiryPeriod,
  //     extentionCharges: extentionCharges,
  //     extentionDuration: extentionDuration,
  //     redemptionOutletId: outletId,
  //   };
  //   ApiClient.POST(API.createSettingRedemption, body, false).then((result) => {
  //     if (result.outletId != null) {
  //       Alert.alert(
  //         'Congratulation!',
  //         'Successfully',
  //         [
  //           {
  //             text: 'OK',
  //             onPress: () => { },
  //           },
  //         ],
  //         { cancelable: false },
  //       );
  //     }
  //   });
  // }

  const createBeerDocket = async () => {
    // var body = {
    //   merchantId: User.getMerchantId(),
    //   isAllOutlet: isChecked13 == true ? '1' : null,
    //   outletId: outletId,
    //   category: category,
    //   expiryPeriod: expiryPeriod,
    //   extentionCharges: extentionCharges,
    //   extentionDuration: extentionDuration,
    //   redemptionOutletId: outletId,
    // };

    var body = {
      bdId: bdId,
      bdItems: bdItems,
      bdOptions: bdOptions,
      price: +parseFloat(docketPrice).toFixed(2),

      startDate: moment(startDate).valueOf(),
      endDate: moment(endDate).valueOf(),

      purchaseDate: moment(purchaseDate).valueOf(),
      purchaseEndDate: moment(purchaseEndDate).valueOf(),

      purchaseTime: moment(purchaseTime).valueOf(),
      purchaseEndTime: moment(purchaseEndTime).valueOf(),

      redeemDate: moment(redeemDate).valueOf(),
      redeemEndDate: moment(redeemEndDate).valueOf(),

      redeemTime: moment(redeemTime).valueOf(),
      redeemEndTime: moment(redeemEndTime).valueOf(),

      expiredDate: moment(expiredDate).valueOf(),
      expiredTime: moment(expiredTime).valueOf(),

      expiryDate: moment(expiredDate)
        .set({
          hour: moment(expiredTime).hour(),
          minute: moment(expiredTime).minute(),
        })
        .valueOf(),

      tnc: termsConditions,
      // bdCategories: selectedCategoryList,
      // duration: expiryPeriod,
      bdOutlets: selectedOutletList,
      merchantId: merchantId,
      merchantName: merchantName,
      merchantLogo: merchantLogo,

      // bdCategoryNames: [],
      bdOutletNames: [],
      bdOutletCovers: [],
      bdOutletAddresses: [],

      chargesOfExtensionDateFormatType: chargesOfExtensionDateFormatType,

      redemptionQuantityLimitValue: +parseInt(
        redemptionQuantityLimitValue
      ).toFixed(2),
      redemptionQuantityLimitDateFormatValue: +parseInt(
        redemptionQuantityLimitDateFormatValue
      ).toFixed(2),
      redemptionQuantityLimitDateFormatType:
        redemptionQuantityLimitDateFormatType,

      // quantity: 10,
    };

    // for (var i = 0; i < selectedCategoryList.length; i++) {
    //   if (beerDocketCategoriesDict[selectedCategoryList[i]]) {
    //     body.bdCategoryNames.push(beerDocketCategoriesDict[selectedCategoryList[i]].name);
    //   }
    // }

    for (var i = 0; i < selectedOutletList.length; i++) {
      if (allOutletsDict[selectedOutletList[i]]) {
        body.bdOutletNames.push(allOutletsDict[selectedOutletList[i]].name);
        body.bdOutletCovers.push(allOutletsDict[selectedOutletList[i]].cover);
        body.bdOutletAddresses.push(
          allOutletsDict[selectedOutletList[i]].address
        );
      }
    }

    var beerDocketImagePath = "";
    var beerDocketCommonIdLocal = selectedBeerDocketEdit
      ? selectedBeerDocketEdit.uniqueId
      : uuidv4();

    if (image && imageType) {
      // promotionCommonIdLocal = uuidv4();

      beerDocketImagePath = await uploadImageToFirebaseStorage(
        {
          uri: image,
          type: imageType,
        },
        `/merchant/${merchantId}/beerDocket/${beerDocketCommonIdLocal}/image${imageType}`
      );
    }

    if (selectedBeerDocketEdit === null) {
      body.image = beerDocketImagePath;

      ApiClient.POST(API.createBeerDocket, body, false).then((result) => {
        if (result && result.status === "success") {
          Alert.alert(
            "Success",
            "Docket has been created",
            [
              {
                text: "OK",
                onPress: () => {
                  setRedemptionList(true);
                },
              },
            ],
            { cancelable: false }
          );
        }
      });
    } else {
      // todo
      //   (body.image = isImageChanged
      //     ? beerDocketImagePath
      //     : selectedBeerDocketEdit.image),
      //     ApiClient.POST(API.updateBeerDocket, body, false).then((result) => {
      //       if (result && result.status === 'success') {
      //         Alert.alert(
      //           'Success',
      //           'Docket has been updated',
      //           [
      //             {
      //               text: 'OK',
      //               onPress: () => {
      //                 setRedemptionList(true);
      //               },
      //             },
      //           ],
      //           {cancelable: false},
      //         );
      //       }
      //     });
    }
  };

  const addOutlet = () => {
    var body = {
      name: addOutletName,
      merchantId: User.getMerchantId(),
    };
    ApiClient.POST(API.addOutlet, body, false).then((result) => {
      if (result) {
        Alert.alert(
          "Congratulation!",
          "Outlet Added successfully",
          [
            {
              text: "OK",
              onPress: () => {},
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  const renderTax = ({ item }) => (
    <View
      style={{
        backgroundColor: "#FFFFFF",
        flexDirection: "row",
        paddingVertical: 20,
        paddingHorizontal: 20,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: "#C4C4C4",
      }}
    >
      <Text style={{ width: "23%", color: Colors.primaryColor }}>
        {item.name}
      </Text>
      <Text style={{ width: "52%" }}>{item.rate}%</Text>
      <TouchableOpacity
        onPress={() => {
          deleteTax(item.id);
        }}
        style={{ width: "40%" }}
      >
        <Icon name="trash-2" size={20} color="#eb3446" />
      </TouchableOpacity>
    </View>
  );

  // function here
  const onCloseShiftBtn = (key) => {
    var decimal = closingAmount.split(".")[1];
    if (key >= 0 || key == ".") {
      if (closingAmount.includes("."))
        if (closingAmount.length < 12 && decimal.length < 2)
          setState({ closingAmount: closingAmount + key });
      if (!closingAmount.includes(".")) {
        if (closingAmount.length < 12)
          setState({ closingAmount: closingAmount + key });
      }
    } else {
      if (closingAmount.length > 0)
        setState({ closingAmount: closingAmount.slice(0, key) });
    }
  };

  const getCurrentShift = (outletId) => {
    /* ApiClient.GET(API.getCurrentShift + outletId).then((result) => {
      setState({ shift1: result.success });
    });
    try {
      if (result.id != null) {
        Alert.alert(
          '',
          [
            {
              text: 'OK',
              onPress: () => { },
            },
          ],
          { cancelable: false },
        );
      }
    } catch (error) { } */
  };

  const getTax = (outletId) => {
    /* ApiClient.GET(API.merchantTax + outletId).then((result) => {
      setState({ taxList: result })
    }); */
  };

  const checkTaxName = (tname) => {
    for (const tax of taxList) {
      if (tname.toLowerCase() == tax.name.toLowerCase()) {
        return true;
      }
    }
    return false;
  };

  const createTax = () => {
    if (!tname) {
      Alert.alert(
        "Error",
        "Please fill in the information",
        [{ text: "OK", onPress: () => {} }],
        { cancelable: false }
      );
      return;
    } else if (!Number.isInteger(Number(rate))) {
      Alert.alert(
        "Error",
        "Tax is only in numeric",
        [{ text: "OK", onPress: () => {} }],
        { cancelable: false }
      );
      return;
    } else if (checkTaxName(tname)) {
      Alert.alert(
        "Error",
        "This name has been used",
        [{ text: "OK", onPress: () => {} }],
        { cancelable: false }
      );
      return;
    } else {
      var body = {
        name: tname,
        rate: rate,
        desciption: "",
        outletId: User.getOutletId(),
      };
      ApiClient.POST(API.taxes, body, false).then((result) => {
        try {
          if (result) {
            Alert.alert(
              "Congratulation!",
              "You Have Successfully Inserted",
              [
                {
                  text: "OK",
                  onPress: () => {},
                },
              ],
              { cancelable: false }
            );
            getTax(User.getOutletId());
          }
        } catch (error) {
          Alert.alert("Failed", [{ text: "OK", onPress: () => {} }], {
            cancelable: false,
          });
        }
      });
    }
  };

  const deleteTax = (taxId) => {
    /* console.log("TAX ID", taxId)
    var body = {
      taxId: taxId,
    };
    ApiClient.POST(API.deleteTax, body, false).then((result) => {
      try {
        console.log("RESULT", result)
        if (result) {
          Alert.alert(
            'Successfully!',
            'You Have Successfully Deleted',
            [
              {
                text: 'OK',
                onPress: () => { },
              },
            ],
            { cancelable: false },
          );
          getTax(User.getOutletId())
        }
      } catch (error) {
        Alert.alert('Failed', [{ text: 'OK', onPress: () => { } }], {
          cancelable: false,
        });
      }
    }); */
  };

  const outletById = (param) => {
    //<= outletId is not used in the param
    /* ApiClient.GET(API.outlet2 + param).then((result) => {
      //<= all you need to do is add parameter here
      console.log("RESULT OUTLET", result)
      setState({ outlet: result, openings: result.openings });
      console.log("openings", openings)
      setState({ status: result.status });
      setState({ merInfo: result.merchant[0] });
      setState({ cover: result.cover });
      setState({ logo: result.merchant[0].logo });
      setState({ openings: result.openings });
  
      myTextInput.current.clear();
    }); */
  };

  //   const editOutlet = (param) => {
  //     var body = {
  //       outletId: param,
  //       coverUrl: cover,
  //       logoUrl: logo,
  //       address: address,
  //       name: name,
  //       latlng: '',
  //       phone: phone,
  //       taxId: '',
  //       status: '1',
  //       isBusy: '1',
  //       // reservationStatus: return(),
  //       openings: [
  //         {
  //           week: 'Monday',
  //           startTime: s_time,
  //           endTime: e_time,
  //         },
  //         {
  //           week: 'Tuesday',
  //           startTime: s_time1,
  //           endTime: e_time1,
  //         },
  //         {
  //           week: 'Wednesday',
  //           startTime: s_time2,
  //           endTime: e_time2,
  //         },
  //         {
  //           week: 'Thursday',
  //           startTime: s_time3,
  //           endTime: e_time3,
  //         },
  //         {
  //           week: 'Friday',
  //           startTime: s_time4,
  //           endTime: e_time4,
  //         },
  //         {
  //           week: 'Saturday',
  //           startTime: s_time5,
  //           endTime: e_time5,
  //         },
  //         {
  //           week: 'Sunday',
  //           startTime: s_time6,
  //           endTime: e_time6,
  //         },
  //       ],
  //       payments: [
  //         {
  //           name: payment,
  //         },
  //       ],
  //     };
  //     ApiClient.PATCH(API.editOutlet, body, false).then((result) => {
  //       if (result.id != null) {
  //         Alert.alert(
  //           'Congratulation!',
  //           'You Have Successfully Inserted',
  //           [
  //             {
  //               text: 'OK',
  //               onPress: () => {},
  //             },
  //           ],
  //           {cancelable: false},
  //         );
  //       }
  //     });
  //     outletById(param);
  //     myTextInput.current.clear();
  //   };

  // const handleChoosePhoto = () => {
  //   // const imagePickerOptions = {
  //   //   mediaType: "photo",
  //   //   quality: 0.5,
  //   //   includeBase64: false,
  //   // };
  //   console.log("handleChoosePhoto");

  //   // todo
  //   // launchImageLibrary(imagePickerOptions, (response) => {
  //   //   if (response.didCancel) {
  //   //   } else if (response.error) {
  //   //     Alert.alert(response.error.toString());
  //   //   } else {
  //   //     // setState({ image: response.uri });
  //   //     setImage(response.uri);
  //   //     setImageType(response.uri.slice(response.uri.lastIndexOf('.')));

  //   //     setIsImageChanged(true);
  //   //   }
  //   // });
  // };

  // const handleChoosePhoto1 = () => {
  //   const options = {
  //     mediaType: "photo",
  //     maxWidth: 2000,
  //     maxHeight: 2000,
  //     includeBase64: false,
  //   };

  //   // todo
  //   // launchImageLibrary(options, (response) => {
  //   //   if (response.didCancel) {
  //   //   } else if (response.error) {
  //   //     Alert.alert(response.error.toString());
  //   //   } else {
  //   //     setState({cover: response.uri});
  //   //   }
  //   // });
  // };

  const closingShift = () => {
    var body = {
      outletId: User.getOutletId(),
      amount: closingAmount,
    };
    ApiClient.POST(API.closeShift, body, false).then((result) => {
      if (result) {
        Alert.alert(
          "Success",
          "Close successfully",
          [
            {
              text: "OK",
              onPress: () => {
                _logout();
                setState({
                  showModal3: false,
                  show: false,
                  showModal5: false,
                });
              },
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  const getCurrentDate = () => {
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();

    return (
      hours + "-" + min + "-" + sec + "  " + date + "-" + month + "-" + year
    );
  };

  const _logout = async () => {
    await AsyncStorage.clear();
    User.setlogin(false);
    User.getRefreshMainScreen();
  };

  const addSection = () => {
    setState({ showNote: true });
  };

  // function end

  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    // <View style={styles.container}>
    //   <View style={styles.sidebar}>
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={6} expandReport={true} />
      </View>

      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={true}
          contentContainerStyle={
            {
              // paddingBottom: 30,
            }
          }
        >
          <View style={[styles.content, {}]}>
            {/* <ScrollView
                showsVerticalScrollIndicator={false}
                style={{}}
             
            > */}
            {/* <ScrollView  //affect tablet cant scroll
      showsVerticalScrollIndicator={false} 
      showsHorizontalScrollIndicator={false} 
      style = {{}}> */}

            {/* <View style={{flexDirection: 'row', marginBottom: 10}}>
            <View style={{flexDirection: 'row', flex: 1}}></View>
            <TextInput
              editable={!loading}
              underlineColorAndroid={Colors.whiteColor}
              clearButtonMode="while-editing"
              style={styles.textInput}
              placeholder="search"
              onChangeText={(text) => {
                setState({search: text.trim()});
              }}
              value={email}
            />
          </View> */}
            {/* <View
            style={{
              flexDirection: 'row',
              backgroundColor: Colors.highlightColor,
              padding: 12,
            }}>
            <TouchableOpacity
              onPress={() => {
                setState({
                  merchantDisplay: true,
                  shift: false,
                  tax: false,
                  sample: false,
                  redemption: false,
                  order: false,
                });
              }}>
              <Text>General</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  shift: true,
                  merchantDisplay: false,
                  tax: false,
                  sample: false,
                  redemption: false,
                  order: false,
                });
                getCurrentShift(User.getOutletId());
              }}>
              <Text style={{ marginLeft: 30 }}>Shift</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  tax: true,
                  merchantDisplay: false,
                  shift: false,
                  sample: false,
                  redemption: false,
                  order: false,
                });
                getTax(User.getOutletId())
              }}>
              <Text style={{ marginLeft: 30 }}>Tax</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  sample: true,
                  tax: false,
                  merchantDisplay: false,
                  shift: false,
                  redemption: false,
                  order: false,
                });
                setState({
                  showNote: false,
                });
              }}>
              <Text style={{ marginLeft: 30 }}>Sample Receipt</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  merchantDisplay: false,
                  shift: false,
                  tax: false,
                  sample: false,
                  redemption: false,
                  order: true,
                });
              }}>
              <Text style={{ marginLeft: 30 }}>Order</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  merchantDisplay: false,
                  shift: false,
                  tax: false,
                  sample: false,
                  redemption: true,
                  order: false,
                  redemptionList: true,
                });
              }}>
              <Text style={{ marginLeft: 30 }}>Redemption</Text>
            </TouchableOpacity>
          </View> */}

            {redemption ? (
              <View
                style={[
                  Dimensions.get("screen").width <= 1823 &&
                  Dimensions.get("screen").width >= 1820
                    ? { marginRight: 18 }
                    : {},
                ]}
              >
                {/* <ScrollView
                showsVerticalScrollIndicator={false}
                style={{}}
                contentContainerStyle={{
                    paddingBottom: Dimensions.get('screen').height * 0.1,
                }}
            > */}
                {redemptionList ? (
                  // <View style={{ flexDirection: "row", justifyContent: 'space-evenly', marginBottom: 15, width: '90%', alignSelf: 'center', }}>
                  //   <TouchableOpacity style={{
                  //     width: '23%', height: 100, backgroundColor: Colors.tabMint, borderRadius: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, paddingVertical: 15, shadowColor: '#000',
                  //     shadowOffset: {
                  //       width: 0,
                  //       height: 2,
                  //     },
                  //     shadowOpacity: 0.22,
                  //     shadowRadius: 3.22,
                  //     elevation: 3,
                  //   }} onPress={() => {

                  //   }}>
                  //     <View style={{
                  //       marginTop: -15,
                  //     }}>
                  //       <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28, }}></Text>
                  //       <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.whiteColor, fontSize: 13 }}>Total</Text>
                  //     </View>
                  //     <View>
                  //       <Coins height={70} width={70} opacity={0} />
                  //     </View>
                  //   </TouchableOpacity>

                  //   <TouchableOpacity style={{
                  //     width: '23%', height: 100, backgroundColor: Colors.tabGold, borderRadius: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, paddingVertical: 15, shadowColor: '#000',
                  //     shadowOffset: {
                  //       width: 0,
                  //       height: 2,
                  //     },
                  //     shadowOpacity: 0.22,
                  //     shadowRadius: 3.22,
                  //     elevation: 3,
                  //   }} onPress={() => {

                  //   }}>
                  //     <View style={{
                  //       marginTop: -15,
                  //     }}>
                  //       <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28, }}></Text>
                  //       <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.whiteColor, fontSize: 13 }}>Active</Text>
                  //     </View>
                  //     <View>
                  //       <Coins height={70} width={70} opacity={0} />
                  //     </View>
                  //   </TouchableOpacity>

                  //   <TouchableOpacity style={{
                  //     width: '23%', height: 100, backgroundColor: Colors.tabCyan, borderRadius: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, paddingVertical: 15, shadowColor: '#000',
                  //     shadowOffset: {
                  //       width: 0,
                  //       height: 2,
                  //     },
                  //     shadowOpacity: 0.22,
                  //     shadowRadius: 3.22,
                  //     elevation: 3,
                  //   }} onPress={() => {

                  //   }}>
                  //     <View style={{
                  //       marginTop: -15,
                  //     }}>
                  //       <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28, }}></Text>
                  //       <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.whiteColor, fontSize: 13 }}>Expired</Text>
                  //     </View>
                  //     <View>
                  //       <Coins height={70} width={70} opacity={0} />
                  //     </View>
                  //   </TouchableOpacity><TouchableOpacity style={{
                  //     width: '23%', height: 100, backgroundColor: Colors.tabCyan, borderRadius: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, paddingVertical: 15, shadowColor: '#000',
                  //     shadowOffset: {
                  //       width: 0,
                  //       height: 2,
                  //     },
                  //     shadowOpacity: 0.22,
                  //     shadowRadius: 3.22,
                  //     elevation: 3,
                  //   }} onPress={() => {

                  //   }}>
                  //     <View style={{
                  //       marginTop: -15,
                  //     }}>
                  //       <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28, }}></Text>
                  //       <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.whiteColor, fontSize: 13 }}>Redeemed</Text>
                  //     </View>
                  //     <View>
                  //       <Coins height={70} width={70} opacity={0} />
                  //     </View>
                  //   </TouchableOpacity>
                  // </View>

                  // <View style={{ flexDirection: "row", justifyContent: 'space-evenly', marginBottom: 15, width: '90%', alignSelf: 'center', }}>
                  //   <TouchableOpacity style={{
                  //     width: '23%', height: 100, backgroundColor: Colors.tabMint, borderRadius: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, paddingVertical: 15, shadowColor: '#000',
                  //     shadowOffset: {
                  //       width: 0,
                  //       height: 2,
                  //     },
                  //     shadowOpacity: 0.22,
                  //     shadowRadius: 3.22,
                  //     elevation: 3,
                  //   }} onPress={() => {
                  //   }}>
                  //     <View style={{
                  //       marginTop: -15,
                  //     }}>
                  //       <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28, }}></Text>
                  //       <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.whiteColor, fontSize: 13 }}>Total</Text>
                  //     </View>
                  //     <View>
                  //       <Coins height={70} width={70} opacity={0} />
                  //     </View>
                  //   </TouchableOpacity>
                  //   <TouchableOpacity style={{
                  //     width: '23%', height: 100, backgroundColor: Colors.tabGold, borderRadius: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, paddingVertical: 15, shadowColor: '#000',
                  //     shadowOffset: {
                  //       width: 0,
                  //       height: 2,
                  //     },
                  //     shadowOpacity: 0.22,
                  //     shadowRadius: 3.22,
                  //     elevation: 3,
                  //   }} onPress={() => {
                  //   }}>
                  //     <View style={{
                  //       marginTop: -15,
                  //     }}>
                  //       <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28, }}></Text>
                  //       <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.whiteColor, fontSize: 13 }}>Active</Text>
                  //     </View>
                  //     <View>
                  //       <Coins height={70} width={70} opacity={0} />
                  //     </View>
                  //   </TouchableOpacity>
                  //   <TouchableOpacity style={{
                  //     width: '23%', height: 100, backgroundColor: Colors.tabCyan, borderRadius: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, paddingVertical: 15, shadowColor: '#000',
                  //     shadowOffset: {
                  //       width: 0,
                  //       height: 2,
                  //     },
                  //     shadowOpacity: 0.22,
                  //     shadowRadius: 3.22,
                  //     elevation: 3,
                  //   }} onPress={() => {
                  //   }}>
                  //     <View style={{
                  //       marginTop: -15,
                  //     }}>
                  //       <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28, }}></Text>
                  //       <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.whiteColor, fontSize: 13 }}>Expired</Text>
                  //     </View>
                  //     <View>
                  //       <Coins height={70} width={70} opacity={0} />
                  //     </View>
                  //   </TouchableOpacity><TouchableOpacity style={{
                  //     width: '23%', height: 100, backgroundColor: Colors.tabCyan, borderRadius: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 30, paddingVertical: 15, shadowColor: '#000',
                  //     shadowOffset: {
                  //       width: 0,
                  //       height: 2,
                  //     },
                  //     shadowOpacity: 0.22,
                  //     shadowRadius: 3.22,
                  //     elevation: 3,
                  //   }} onPress={() => {
                  //   }}>
                  //     <View style={{
                  //       marginTop: -15,
                  //     }}>
                  //       <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28, }}></Text>
                  //       <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.whiteColor, fontSize: 13 }}>Redeemed</Text>
                  //     </View>
                  //     <View>
                  //       <Coins height={70} width={70} opacity={0} />
                  //     </View>
                  //   </TouchableOpacity>
                  // </View>
                  <>
                    {/* <View style={{flexDirection:'row'}}>

                    {isTablet && (
                  <View
                    style={{alignItem: 'center',
                            // marginRight: 100,
                            // marginBottom: 10,
                          }}>
                        <TouchableOpacity
                          style={styles.addNewView}
                          onPress={() => {
                            CommonStore.update(s => {
                              s.selectedBeerDocketEdit = null;
                            });

                            setRedemptionList(false);
                          }}>
                          <View style={styles.addButtonView}>
                            <View style={{}}>
                              <AntDesign
                                name="pluscircle"
                                size={20}
                                color={Colors.primaryColor}
                              />
                            </View>
                            <Text
                              style={{
                                marginLeft: 10,
                                color: Colors.primaryColor,
                                fontWeight: '600'
                              }}>
                              Add Docket
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                      )}

                      <View
                    style={[{
                      // flex: 1,
                      // alignContent: 'flex-end',
                      // marginBottom: 10,
                      // flexDirection: 'row',
                      // marginRight: '-40%',
                      // marginLeft: 310,
                      // backgroundColor: 'red',
                      // alignItems: 'flex-end',
                      // right: '-50%',
                      // width: '28%',
                      height: 40,

                      }, !isTablet ? {
                        marginLeft: 0,
                      } : {}]}>
                <View style={{
                  width: 200,
                  height: 40,
                  backgroundColor: 'white',
                  borderRadius: 10,
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginBottom: 20,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                  alignSelf: 'flex-end',
                }}>
                  <Icon name="search" size={18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    clearButtonMode='while-editing'
                    placeholder="Search"
                    style={{
                      height: 45,
                      paddingHorizontal: 10,
                      // backgroundColor: Colors.fieldtBgColor,
                      borderRadius: 10,

                      color: Colors.fieldtTxtColor,
                      fontFamily: "NunitoSans-Regular",
                      fontSize: 14,
                      //lineHeight: 20,
                      // backgroundColor: 'blue',
                      //width: '92%',
                    }}
                    onChangeText={text => {
                      setSearchText(text);
                    } }
                    value={searchText}
                    returnKeyType='search' />
                </View>
                </View>
                </View> */}
                    <View
                      style={[
                        { flexDirection: "row", marginBottom: 20 },
                        switchMerchant
                          ? {
                              alignSelf: "center",
                            }
                          : {},
                      ]}
                    >
                      <View
                        style={[
                          {
                            flexDirection: "row",
                            justifyContent: "space-between",
                            width: "100%",
                            // marginBottom: Platform.OS == 'ios' ? 10 : 10
                          },
                        ]}
                      >
                        <View
                          style={{ alignItems: "center", flexDirection: "row" }}
                        >
                          <Text
                            style={[
                              {
                                //fontSize: 30,
                                fontSize: 26,
                                fontFamily: "NunitoSans-Bold",
                              },
                              switchMerchant
                                ? {
                                    fontSize: 20,
                                  }
                                : {},
                            ]}
                          >
                            {beerDockets.length}
                          </Text>
                          <Text
                            style={[
                              {
                                fontSize: 26,
                                fontFamily: "NunitoSans-Bold",
                                marginLeft: 5,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 20,
                                  }
                                : {},
                            ]}
                          >
                            {beerDockets.length > 1 ? "Dockets" : "Docket"}
                          </Text>
                        </View>

                        <View style={{ flexDirection: "row" }}>
                          <TouchableOpacity
                            style={[
                              {
                                // alignSelf: 'flex-end',
                                justifyContent: "center",
                                flexDirection: "row",
                                borderWidth: 1,
                                borderColor: Colors.primaryColor,
                                backgroundColor: "#4E9F7D",
                                borderRadius: 5,
                                // width: 130,
                                paddingHorizontal: 10,
                                height: 40,
                                alignItems: "center",
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 1,
                                zIndex: -1,
                                marginRight: 15,
                              },
                              switchMerchant ? { height: 35 } : {},
                            ]}
                            onPress={() => {
                              CommonStore.update((s) => {
                                s.selectedBeerDocketEdit = null;
                              });

                              setRedemptionList(false);
                            }}
                          >
                            <View style={{}}>
                              <AntDesign
                                name="pluscircle"
                                size={switchMerchant ? 15 : 20}
                                color={Colors.whiteColor}
                              />
                            </View>
                            <Text
                              style={[
                                {
                                  color: Colors.whiteColor,
                                  marginLeft: 5,
                                  fontSize: 16,
                                  fontFamily: "NunitoSans-Bold",
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 12,
                                    }
                                  : {},
                              ]}
                            >
                              DOCKET
                            </Text>
                          </TouchableOpacity>

                          <View
                            style={[
                              {
                                width: 200,
                                height: 40,
                                backgroundColor: "white",
                                borderRadius: 5,
                                flexDirection: "row",
                                alignContent: "center",
                                alignItems: "center",
                                // marginBottom: 20,
                                shadowColor: "#000",
                                shadowOffset: {
                                  width: 0,
                                  height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 3,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                // alignSelf: 'flex-end',
                              },
                              switchMerchant
                                ? {
                                    height: 35,
                                  }
                                : {},
                            ]}
                          >
                            <Icon
                              name="search"
                              size={switchMerchant ? 15 : 18}
                              color={Colors.primaryColor}
                              style={{ marginLeft: 15 }}
                            />
                            <TextInput
                              underlineColorAndroid={Colors.fieldtBgColor}
                              clearButtonMode="while-editing"
                              placeholder="Search"
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              style={[
                                {
                                  height: 45,
                                  paddingHorizontal: 10,
                                  // backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 5,
                                  color: Colors.fieldtTxtColor,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 16,
                                  //lineHeight: 20,
                                  // backgroundColor: 'blue',
                                  //width: '92%',
                                },
                                switchMerchant
                                  ? {
                                      fontSize: 12,
                                      width: 180,
                                    }
                                  : {},
                              ]}
                              onChangeText={(text) => {
                                setSearchText(text);
                              }}
                              value={searchText}
                              returnKeyType="search"
                            />
                          </View>
                        </View>
                      </View>
                    </View>

                    <View
                      style={[
                        {
                          flexDirection: "row",
                          justifyContent: "space-between",
                          width: "100%",
                        },
                        switchMerchant
                          ? {
                              width: Dimensions.get("screen").width * 0.84,
                              alignSelf: "center",
                            }
                          : {},
                      ]}
                    >
                      <TouchableOpacity
                        style={[
                          {
                            width: "23%",
                            height: 100,
                            backgroundColor: Colors.tabMint,
                            borderRadius: 10,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingHorizontal: 30,
                            paddingVertical: 15,
                            shadowColor: "#000",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 3,
                          },
                          switchMerchant
                            ? {
                                width: "22%",
                                height: 60,
                              }
                            : {},
                        ]}
                        onPress={() => {
                          navigation.navigate("ReportSalesOvertime");
                        }}
                      >
                        <View
                          style={[
                            {
                              marginTop: -15,
                            },
                            switchMerchant
                              ? {
                                  marginTop: 15,
                                  height: 60,
                                }
                              : {},
                          ]}
                        >
                          <Text
                            style={[
                              {
                                fontFamily: "NunitoSans-Bold",
                                color: Colors.whiteColor,
                                fontSize: 28,
                              },
                              switchMerchant ? { fontSize: 20 } : {},
                            ]}
                          >
                            {beerDockets.length}
                          </Text>
                          <Text
                            style={[
                              {
                                fontFamily: "NunitoSans-Regular",
                                color: Colors.whiteColor,
                                fontSize: 13,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            Total
                          </Text>
                        </View>
                        <View>
                          <Coins
                            height={switchMerchant ? 30 : 70}
                            width={switchMerchant ? 30 : 70}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          {
                            width: "23%",
                            height: 100,
                            backgroundColor: Colors.tabGold,
                            borderRadius: 10,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingHorizontal: 30,
                            paddingVertical: 15,
                            shadowColor: "#000",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 3,
                          },
                          switchMerchant
                            ? {
                                width: "22%",
                                height: 60,
                              }
                            : {},
                        ]}
                        onPress={() => {
                          // navigation.navigate('ReportSalesTransaction');

                          navigation.navigate("Redemption");
                        }}
                      >
                        <View
                          style={[
                            {
                              marginTop: -15,
                            },
                            switchMerchant
                              ? {
                                  marginTop: 15,
                                  height: 60,
                                }
                              : {},
                          ]}
                        >
                          <Text
                            style={[
                              {
                                fontFamily: "NunitoSans-Bold",
                                color: Colors.whiteColor,
                                fontSize: 28,
                              },
                              switchMerchant ? { fontSize: 20 } : {},
                            ]}
                          >
                            {overviewTotalActive}
                          </Text>
                          <Text
                            style={[
                              {
                                fontFamily: "NunitoSans-Regular",
                                color: Colors.whiteColor,
                                fontSize: 13,
                              },
                              switchMerchant ? { fontSize: 10 } : {},
                            ]}
                          >
                            Active
                          </Text>
                        </View>
                        <View>
                          <Hand
                            height={switchMerchant ? 30 : 70}
                            width={switchMerchant ? 30 : 70}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          {
                            width: "23%",
                            height: 100,
                            backgroundColor: Colors.tabCyan,
                            borderRadius: 10,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingHorizontal: 30,
                            paddingVertical: 15,
                            shadowColor: "#000",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 3,
                          },
                          switchMerchant
                            ? {
                                width: "22%",
                                height: 60,
                              }
                            : {},
                        ]}
                        onPress={() => {
                          navigation.navigate("RedemptionRedeemed");
                        }}
                      >
                        <View
                          style={[
                            {
                              marginTop: -15,
                            },
                            switchMerchant
                              ? {
                                  marginTop: 15,
                                  height: 60,
                                }
                              : {},
                          ]}
                        >
                          {/* <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28 }}>{allDayTotalSold}</Text> */}
                          <Text
                            style={[
                              {
                                fontFamily: "NunitoSans-Bold",
                                color: Colors.whiteColor,
                                fontSize: 28,
                              },
                              switchMerchant ? { fontSize: 20 } : {},
                            ]}
                          >
                            {overviewTotalRedeemed}
                          </Text>
                          <Text
                            style={[
                              {
                                fontFamily: "NunitoSans-Regular",
                                color: Colors.whiteColor,
                                fontSize: 13,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            Redeemed
                          </Text>
                        </View>
                        <View>
                          <Ionicon
                            name="trash-bin-outline"
                            color={"#F7F7F7"}
                            size={switchMerchant ? 30 : 65}
                            style={{ opacity: 0.6, paddingLeft: 2 }}
                          />
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[
                          {
                            width: "23%",
                            height: 100,
                            backgroundColor: "#d90000",
                            borderRadius: 10,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingHorizontal: 30,
                            paddingVertical: 15,
                            shadowColor: "#000",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 3,
                          },
                          switchMerchant
                            ? {
                                width: "22%",
                                height: 60,
                              }
                            : {},
                        ]}
                        onPress={() => {
                          navigation.navigate("RedemptionExpired");
                        }}
                      >
                        <View
                          style={[
                            {
                              marginTop: -15,
                            },
                            switchMerchant
                              ? {
                                  marginTop: 15,
                                  height: 60,
                                }
                              : {},
                          ]}
                        >
                          {/* <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28 }}>{allDayTotalSold}</Text> */}
                          <Text
                            style={[
                              {
                                fontFamily: "NunitoSans-Bold",
                                color: Colors.whiteColor,
                                fontSize: 28,
                              },
                              switchMerchant ? { fontSize: 20 } : {},
                            ]}
                          >
                            {overviewTotalExpired}
                          </Text>
                          <Text
                            style={[
                              {
                                fontFamily: "NunitoSans-Regular",
                                color: Colors.whiteColor,
                                fontSize: 13,
                              },
                              switchMerchant ? { fontSize: 10 } : {},
                            ]}
                          >
                            Expired
                          </Text>
                        </View>
                        <View>
                          <Ionicon
                            name="timer-outline"
                            color={"#F7F7F7"}
                            size={switchMerchant ? 30 : 65}
                            style={{ opacity: 0.6, paddingLeft: 2 }}
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                  </>
                ) : null}

                {redemptionList == true ? (
                  <View
                    style={[
                      styles.container,
                      {
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 5,
                        height: Dimensions.get("window").height - 100,
                        marginTop: 20,
                        marginBottom: 50,

                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        // elevation: 1,
                        elevation: 3,
                        borderRadius: 5,
                        //backgroundColor: 'red',

                        width: windowWidth * 0.888,
                      },
                    ]}
                  >
                    <View
                      style={[
                        {
                          height: Platform.OS == "ios" ? "77%" : "80%",
                          width: "100%",
                          //   borderWidth: 1,
                        },
                      ]}
                    >
                      <View
                        style={{
                          marginBottom: 15,
                          zIndex: 2,
                          //   padding:20
                          // borderWidth:1
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            padding: 10,
                            marginTop: 10,
                            height: 60,

                            //alignItems: 'center',
                            justifyContent: "space-between",
                          }}
                        >
                          <View style={{ flexDirection: "row" }}>
                            {/* left */}
                            <View style={{ justifyContent: "center" }}>
                              <Text
                                style={[styles.textSize, { marginRight: 10 }]}
                              >
                                Outlet{" "}
                              </Text>
                            </View>

                            <View
                              style={{
                                // borderWidth: 1,
                                maxWidth: 500,
                                height: 40,
                                minWidth: 250,
                              }}
                            >
                              <Select
                                defaultValue={selectedOutletList}
                                styles={{}}
                                onChange={(item) => {
                                  console.log(item);
                                  setSelectedOutletList(item);
                                }}
                                options={outletDropdownList}
                                placeholder="Choose outlet"
                                isMulti
                              />
                              {/* <DropDownPicker
                                // controller={instance => controller = instance}
                                //controller={instance => setController(instance)}
                                containerStyle={[
                                  { marginLeft: 10, height: 40, zIndex: 2 },
                                  switchMerchant ? { height: 35 } : {},
                                ]}
                                arrowColor={"black"}
                                arrowSize={switchMerchant ? 15 : 20}
                                arrowStyle={{ fontWeight: "bold" }}
                                labelStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                }}
                                style={[
                                  {
                                    width: 250,
                                    paddingVertical: 0,
                                    borderRadius: 10,
                                  },
                                  switchMerchant ? { borderRadius: 5 } : {},
                                ]}
                                placeholderTextColor={Colors.descriptionColor}
                                items={outletDropdownList}
                                customTickIcon={(press) => (
                                  <Ionicon
                                    name={"md-checkbox"}
                                    color={
                                      press
                                        ? Colors.fieldtBgColor
                                        : Colors.primaryColor
                                    }
                                    paddingRight={5}
                                    size={20}
                                  />
                                )}
                                itemStyle={{
                                  justifyContent: "flex-start",
                                  marginLeft: 5,
                                  zIndex: 2,
                                }}
                                placeholder={"Choose Outlet"}
                                multipleText={"%d outlet(s) selected"}
                                onChangeItem={(item) => {
                                  console.log(item);
                                  setSelectedOutletList(item);
                                }}
                                multiple={true}
                                defaultValue={selectedOutletList}
                                dropDownMaxHeight={150}
                                dropDownStyle={{
                                  width: 250,
                                  height: 90,
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  textAlign: "left",
                                  zIndex: 2,
                                }}
                                globalTextStyle={{
                                  fontSize: switchMerchant ? 10 : 16,
                                }}
                              /> */}
                            </View>

                            <View
                              style={[
                                {
                                  justifyContent: "flex-start",
                                  marginLeft: 10,
                                  width: 150,
                                  //zIndex: -1,
                                  //   borderWidth: 1,
                                  flexDirection: "row",
                                  //   justifyContent: "center",
                                  alignItems: "center",
                                },
                                switchMerchant ? { marginLeft: 0 } : {},
                              ]}
                            >
                              {/* <CheckBox
                                style={[
                                  {
                                    padding: 0,
                                  },
                                  switchMerchant
                                    ? {
                                        transform: [
                                          { scaleX: 0.8 },
                                          { scaleY: 0.8 },
                                        ],
                                      }
                                    : {},
                                ]}
                                onClick={() => {
                                  if (
                                    allOutlets.length ===
                                    selectedOutletList.length
                                  ) {
                                    setSelectedOutletList([]);
                                  } else {
                                    setSelectedOutletList(
                                      allOutlets.map(
                                        (outlet) => outlet.uniqueId
                                      )
                                    );
                                  }
                                }}
                                checkBoxColor={Colors.fieldtBgColor}
                                uncheckedCheckBoxColor={Colors.tabGrey}
                                checkedCheckBoxColor={Colors.primaryColor}
                                isChecked={
                                  allOutlets.length ===
                                  selectedOutletList.length
                                }
                                rightText={"All Outlets"}
                                rightTextStyle={[
                                  {
                                    fontWeight: "bold",
                                    marginLeft: 5,
                                    color:
                                      allOutlets.length ===
                                      selectedOutletList.length
                                        ? Colors.primaryColor
                                        : Colors.descriptionColor,
                                  },
                                  switchMerchant ? { fontSize: 10 } : {},
                                ]}
                              /> */}
                              <input
                                onChange={() => {
                                  if (
                                    allOutlets.length ===
                                    selectedOutletList.length
                                  ) {
                                    setSelectedOutletList([]);
                                  } else {
                                    setSelectedOutletList(
                                      allOutlets.map(
                                        (outlet) => outlet.uniqueId
                                      )
                                    );
                                  }
                                }}
                                style={{
                                  alignSelf: "center",
                                  borderRadius: 15,
                                  paddingBottom: 3,
                                }}
                                type={"checkbox"}
                                checked={
                                  allOutlets.length ===
                                  selectedOutletList.length
                                }
                              />
                              <Text>All Outlets</Text>
                            </View>
                          </View>
                        </View>
                        <View
                          style={[
                            {
                              flexDirection: "row",
                              paddingVertical: 20,
                              paddingHorizontal: 10,
                              marginTop: 10,
                              zIndex: -2,
                              // justifyContent: 'center'
                              //width: Dimensions.get('screen').width * 0.888,
                            },
                            switchMerchant ? { paddingVertical: 0 } : {},
                          ]}
                        >
                          {/* <Text
                      style={{
                        flex: 2,
                        alignSelf: 'center',
                        color: '#757575',
                        paddingLeft: '4%',
                      }}>
                      Outlet
                      </Text> */}
                          <Text
                            style={[
                              {
                                width: "35%",
                                //alignSelf: 'center',
                                // color: '#757575',
                                marginRight: 9,
                                color: "black",
                                fontSize: 14,
                                fontFamily: "NunitoSans-Bold",
                              },
                              switchMerchant ? { fontSize: 10 } : {},
                            ]}
                          >
                            Products
                          </Text>
                          <Text
                            style={[
                              {
                                width: "27%",
                                alignSelf: "center",
                                color: "black",
                                fontFamily: "NunitoSans-Bold",
                                marginRight: 8,
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            Duration
                          </Text>
                          <Text
                            style={[
                              {
                                width: "9%",
                                alignSelf: "center",
                                color: "black",
                                fontFamily: "NunitoSans-Bold",
                                marginRight: 8,
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            Active
                          </Text>
                          <Text
                            style={[
                              {
                                width: "9%",
                                alignSelf: "center",
                                color: "black",
                                fontFamily: "NunitoSans-Bold",
                                marginRight: 8,
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            Expired
                          </Text>
                          <Text
                            style={[
                              {
                                width: "10%",
                                alignSelf: "center",
                                color: "black",
                                fontFamily: "NunitoSans-Bold",
                                marginRight: 8,
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                    width: "9%",
                                    fontSize: 10,
                                  }
                                : {},
                            ]}
                          >
                            Redeemed
                          </Text>
                          {/* <Text
                        style={{
                          width: '25%',
                          alignSelf: 'center',
                          color:'black',
                          fontFamily: 'NunitoSans-Bold',
                          marginRight: 5,
                        }}>
                        Redemption outlet(s)
                      </Text> */}
                          <Text
                            style={[
                              {
                                width: "5%",
                                alignSelf: "center",
                                color: "#757575",
                              },
                              switchMerchant ? {} : {},
                            ]}
                          >
                            {" "}
                          </Text>
                        </View>
                        {/* <View style={{ marginBottom: 0,zIndex: -1 }}> */}
                        {/* <KeyboardAwareScrollView
                        style={[{ zIndex: -4,}, switchMerchant ? { paddingBottom:110 } : {}]}
                        showsVerticalScrollIndicator={false} */}
                        {/* > */}
                        <FlatList
                          nestedScrollEnabled={true}
                          showsVerticalScrollIndicator={false}
                          style={
                            switchMerchant
                              ? {
                                  height:
                                    Platform.OS === "ios"
                                      ? Dimensions.get("screen").height * 0.77
                                      : Dimensions.get("screen").height * 0.69,
                                }
                              : {}
                          }
                          data={beerDockets.filter((item) => {
                            var isValid = false;

                            for (var i = 0; i < item.bdOutlets.length; i++) {
                              for (
                                var j = 0;
                                j < selectedOutletList.length;
                                j++
                              ) {
                                if (
                                  item.bdOutlets[i] === selectedOutletList[j]
                                ) {
                                  isValid = true;
                                  break;
                                }
                              }
                            }
                            return isValid;
                          })}
                          // extraData={beerDockets}
                          renderItem={renderItem}
                          keyExtractor={(item, index) => String(index)}
                        />
                        {/* </KeyboardAwareScrollView> */}
                        {/* <View style={{ borderWidth: 1, borderColor: '#E5E5E5' }} /> */}
                      </View>

                      {/* </View> */}
                      {/* <View style={{marginTop:10}}></View> */}
                    </View>
                  </View>
                ) : (
                  <ScrollView
                    showsVerticalScrollIndicator={false}
                    keyboardShouldPersistTaps="handled"
                    style={{ marginBottom: 0 }}
                  >
                    <View
                      style={
                        switchMerchant
                          ? { flexDirection: "row", alignSelf: "center" }
                          : {}
                      }
                    >
                      <View
                        style={
                          switchMerchant
                            ? {
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: Dimensions.get("screen").width * 0.84,
                              }
                            : {}
                        }
                      >
                        <TouchableOpacity
                          style={{
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            marginBottom: -10,
                            alignSelf: "baseline",
                            marginLeft: -Dimensions.get("screen").width * 0.005,
                          }}
                          onPress={() => {
                            // setState({ redemptionList: true })
                            // resetRedemptionDetail()

                            setRedemptionList(true);
                          }}
                        >
                          <Feather
                            name="chevron-left"
                            size={switchMerchant ? 20 : 30}
                            color={Colors.primaryColor}
                          />
                          <Text
                            style={[
                              {
                                fontFamily: "NunitoSans-Bold",
                                fontSize: 17,
                                color: Colors.primaryColor,
                                marginBottom: Platform.OS === "ios" ? 0 : 2,
                                marginLeft: -5,
                              },
                              switchMerchant
                                ? {
                                    fontSize: 14,
                                  }
                                : {},
                            ]}
                          >
                            {" "}
                            Back
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.container,
                        {
                          backgroundColor: Colors.whiteColor,
                          borderRadius: 5,
                          // height: Dimensions.get('window').height - 100,
                          marginTop: 20,
                          // marginBottom: 20,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          // elevation: 1,
                          elevation: 3,

                          borderRadius: 5,
                          width: Dimensions.get("screen").width * 0.888,
                        },
                        switchMerchant
                          ? {
                              width: Dimensions.get("screen").width * 0.84,
                              alignSelf: "center",
                            }
                          : {},
                      ]}
                    >
                      <ScrollView showsVerticalScrollIndicator={false}>
                        <View
                          style={
                            switchMerchant
                              ? {
                                  //height: '75%'
                                }
                              : {
                                  height:
                                    Platform.OS == "ios"
                                      ? Dimensions.get("screen").height * 0.82
                                      : Dimensions.get("screen").height * 0.74,
                                }
                          }
                        >
                          <View style={{}}>
                            <Text
                              style={[
                                {
                                  alignSelf: "center",
                                  marginTop: 20,
                                  fontSize: 30,
                                  fontFamily: "NunitoSans-Bold",
                                },
                                switchMerchant ? { fontSize: 20 } : {},
                              ]}
                            >
                              Manage Docket
                            </Text>
                          </View>

                          <View
                            style={[
                              {
                                alignSelf: "flex-end",
                                marginTop: 10,
                                paddingRight: 20,
                                position: "absolute",
                                zIndex: 10000,
                              },
                              switchMerchant
                                ? {
                                    paddingRight: 25,
                                  }
                                : {},
                            ]}
                          >
                            <TouchableOpacity
                              style={[
                                {
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  borderWidth: 1,
                                  borderColor: Colors.primaryColor,
                                  backgroundColor: "#4E9F7D",
                                  borderRadius: 5,
                                  width: 130,
                                  paddingHorizontal: 10,
                                  height: 40,
                                  alignItems: "center",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.22,
                                  shadowRadius: 3.22,
                                  elevation: 1,
                                  zIndex: -1,
                                  marginTop: 10,
                                  marginRight: 20,
                                },
                                switchMerchant
                                  ? {
                                      width: 100,
                                      height: 35,
                                    }
                                  : {},
                              ]}
                              // style={{ width:130, height: 40, alignItems: 'center', justifyContent: 'center' }}
                              disabled={loading}
                              onPress={() => {
                                // setState({
                                //   redemptionList: !redemptionList,
                                // });
                                // redemptionFunc()
                                createBeerDocket();
                              }}
                            >
                              <Text
                                style={[
                                  {
                                    color: Colors.whiteColor,
                                    //marginLeft: 5,
                                    fontSize: 16,
                                    fontFamily: "NunitoSans-Bold",
                                  },
                                  switchMerchant
                                    ? {
                                        fontSize: 10,
                                      }
                                    : {},
                                ]}
                              >
                                {loading ? "LOADING..." : "SAVE"}
                              </Text>
                            </TouchableOpacity>
                            {/* </View> */}
                          </View>

                          {/* <View style={{ borderWidth: 1, borderColor: '#E5E5E5' }} /> */}

                          <View
                            style={{
                              flexDirection: "row",
                              padding: 30,
                              paddingTop: 10,
                            }}
                          >
                            <ScrollView
                              showsVerticalScrollIndicator={false}
                              containerStyle={{ paddingBottom: 150 }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  // padding: 30,
                                  paddingTop: 10,
                                  width: "100%",
                                }}
                              >
                                <View style={{ width: "100%" }}>
                                  <View
                                    style={{
                                      flexDirection: "row",
                                      //flex: 1,
                                      // marginTop: 50,
                                      // zIndex: -10,
                                      //alignItems: 'center'
                                    }}
                                  >
                                    <View
                                      style={{
                                        justifyContent: "flex-start",
                                        flexDirection: "row",
                                        marginRight:
                                          Dimensions.get("screen").width <= 1024
                                            ? "3%"
                                            : "5%",
                                      }}
                                    >
                                      <TouchableOpacity
                                        onPress={openFileSelector}
                                      >
                                        {image ? (
                                          <View>
                                            <AsyncImage
                                              source={{ uri: image }}
                                              style={[
                                                {
                                                  width: 250,
                                                  height: 250,
                                                  borderRadius: 5,
                                                },
                                              ]}
                                              hideLoading={true}
                                            />

                                            <View
                                              style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                //   backgroundColor: 'black',
                                                opacity: 0.5,
                                                // width: 120,
                                                // height: 120,
                                                // borderRadius: 13,
                                              }}
                                            >
                                              <Icon
                                                name="edit"
                                                size={switchMerchant ? 20 : 23}
                                                color={Colors.primaryColor}
                                                //  style={{ position: 'absolute', zIndex: -1 }}
                                              />
                                            </View>
                                          </View>
                                        ) : (
                                          <View>
                                            <View
                                              style={[
                                                {
                                                  width: 250,
                                                  height: 250,
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  backgroundColor:
                                                    Colors.fieldtBgColor,
                                                  zIndex: -1,
                                                  alignSelf: "center",
                                                  borderWidth: 1,
                                                  borderRadius: 5,
                                                  borderColor: "#E5E5E5",
                                                },
                                              ]}
                                            >
                                              <View
                                                style={{
                                                  position: "absolute",
                                                  bottom: 5,
                                                  right: 5,
                                                  opacity: 0.5,
                                                }}
                                              >
                                                <Icon
                                                  name="edit"
                                                  size={23}
                                                  color={Colors.primaryColor}
                                                />
                                              </View>
                                              <Icon
                                                name="upload"
                                                size={200}
                                                color="lightgrey"
                                                style={{
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  zIndex: -1,
                                                }}
                                              />
                                            </View>
                                          </View>
                                        )}
                                      </TouchableOpacity>
                                    </View>

                                    {/* <TouchableOpacity onPress={() => {
                                    handleChoosePhoto();
                                  }}>
                                    <Icon name='camera' size={20} style={{ marginLeft: 5, color: Colors.primaryColor }} />
                                </TouchableOpacity> */}

                                    <View
                                      style={[{ width: "62%", marginTop: 10 }]}
                                    >
                                      <View>
                                        <View
                                          style={{
                                            flexDirection: "column",
                                            width: "100%",
                                          }}
                                        >
                                          <View
                                            style={{
                                              flexDirection: "row",
                                              //flex: 1,
                                              zIndex: -10,
                                              //alignItems: 'center'
                                            }}
                                          >
                                            <View
                                              style={[
                                                {
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                              ]}
                                            >
                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Start Date
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  paddingLeft: 5,
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      height: 35,
                                                      width: 100,
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <View
                                                //disabled={!(!loading && (!selectedVoucherEdit || !selectedVoucherEdit.isPublishedOnce))}
                                                style={[
                                                  {
                                                    marginRight: 5,
                                                    justifyContent:
                                                      "space-between",
                                                    paddingRight: 5,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        paddingRight: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                <GCalendar
                                                  width={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                  height={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                />
                                              </View>
                                              {/* <View
                                                style={{
                                                  zIndex: 10000000,
                                                }}
                                              >
                                                <DatePicker
                                                  selected={moment(startDate).toDate()}
                                                  // styles = {{backgroundColor:'black'}}
                                                  onChange={(date) => {
                                                    console.log("date: ", date);
                                                    setStartDate(moment(date));
                                                  }}
                                                  // className = 'red-border'
                                                />
                                              </View> */}

                                              <TouchableOpacity
                                                style={{
                                                  zIndex: 10,
                                                }}
                                                onPress={() => {
                                                  setStartDateModal(true);
                                                }}
                                              >
                                                <Text>
                                                  {startDate.format(
                                                    "DD/MM/YYYY"
                                                  )}
                                                </Text>
                                                {/* <DatePicker
                                                  selected={moment(
                                                    startDate
                                                  ).toDate()}
                                                  // styles = {{backgroundColor:'black'}}
                                                  onChange={(date) => {
                                                    console.log("date: ", date);
                                                    setStartDate(moment(date));
                                                  }}
                                                  // className = 'red-border'
                                                /> */}
                                              </TouchableOpacity>
                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={startDateModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {/* <View>
                                                      <Text>Start Date</Text>
                                                    </View> */}
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        top: "20%",
                                                        position: "absolute",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Start Date
                                                      </Text>
                                                    </View>
                                                    {/* <TouchableOpacity> */}
                                                    <View
                                                      style={{
                                                        borderWidth: 1,
                                                        width:
                                                          windowWidth * 0.05,
                                                      }}
                                                    >
                                                      <DatePicker
                                                        selected={moment(
                                                          startDate
                                                        ).toDate()}
                                                        // styles = {{backgroundColor:'black'}}
                                                        onChange={(date) => {
                                                          // console.log(
                                                          //   "date: ",
                                                          //   date
                                                          // );
                                                          setStartDate(
                                                            moment(date)
                                                          );
                                                          setStartDateModal(
                                                            false
                                                          );
                                                        }}
                                                        // className = 'red-border'
                                                      />
                                                    </View>
                                                    {/* </TouchableOpacity> */}

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setStartDateModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>

                                              {/* <Text
                                                style={[
                                                  {
                                                    //width: "90%",
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: startDate
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                    marginRight: 5,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {startDate
                                                  ? moment(startDate).format(
                                                      "DD MMM YYYY"
                                                    )
                                                  : "Start Date"}
                                              </Text> */}
                                            </View>

                                            <View
                                              style={[
                                                {
                                                  marginLeft: 35,
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                End Date
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  paddingLeft: 5,
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      height: 35,
                                                      width: 100,
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <View
                                                //disabled={!(!loading && (!selectedVoucherEdit || !selectedVoucherEdit.isPublishedOnce))}
                                                style={[
                                                  {
                                                    marginRight: 5,
                                                    justifyContent:
                                                      "space-between",
                                                    paddingRight: 5,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        paddingRight: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                <GCalendar
                                                  width={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                  height={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                />
                                              </View>
                                              <TouchableOpacity
                                                style={{
                                                  zIndex: 10,
                                                }}
                                                onPress={() => {
                                                  setEndDateModal(true);
                                                }}
                                              >
                                                <Text>
                                                  {endDate.format("DD/MM/YYYY")}
                                                </Text>
                                              </TouchableOpacity>
                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={endDateModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {/* <View>
                                                      <Text>Start Date</Text>
                                                    </View> */}
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        top: "20%",
                                                        position: "absolute",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        End Date
                                                      </Text>
                                                    </View>
                                                    {/* <TouchableOpacity> */}
                                                    <View
                                                      style={{
                                                        borderWidth: 1,
                                                        width:
                                                          windowWidth * 0.05,
                                                      }}
                                                    >
                                                      <DatePicker
                                                        selected={moment(
                                                          endDate
                                                        ).toDate()}
                                                        // styles = {{backgroundColor:'black'}}
                                                        onChange={(date) => {
                                                          // console.log(
                                                          //   "date: ",
                                                          //   date
                                                          // );
                                                          setEndDate(
                                                            moment(date)
                                                          );
                                                          setEndDateModal(
                                                            false
                                                          );
                                                        }}
                                                        // className = 'red-border'
                                                      />
                                                    </View>
                                                    {/* </TouchableOpacity> */}

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setEndDateModal(false);
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>

                                              {/* <View
                                                style={{
                                                  zIndex: 100,
                                                }}
                                              >
                                                <DatePicker
                                                  selected={moment(
                                                    endDate
                                                  ).toDate()}
                                                  onChange={(date) => {
                                                    console.log("date: ", date);
                                                    setEndDate(moment(date));
                                                  }}
                                                />
                                                
                                              </View> */}

                                              {/* <Text
                                                style={[
                                                  {
                                                    //width: "90%",
                                                    marginRight: 5,
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: endDate
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {endDate
                                                  ? moment(endDate).format(
                                                      "DD MMM YYYY"
                                                    )
                                                  : "End Date"}
                                              </Text> */}
                                            </View>
                                          </View>

                                          <View
                                            style={{
                                              flexDirection: "row",
                                              //flex: 1,
                                              marginTop: 22,
                                              zIndex: -10,
                                              //alignItems: 'center'
                                            }}
                                          >
                                            <View
                                              style={[
                                                {
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              {/* <Text style={styles.textSize}>Start Date:</Text> */}
                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Purchase Start Date
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  paddingLeft: 5,
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      height: 35,
                                                      width: 100,
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <View
                                                //disabled={!(!loading && (!selectedVoucherEdit || !selectedVoucherEdit.isPublishedOnce))}
                                                style={[
                                                  {
                                                    marginRight: 5,
                                                    justifyContent:
                                                      "space-between",
                                                    paddingRight: 5,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        paddingRight: 10,
                                                      }
                                                    : {},
                                                ]}
                                                onPress={() => {
                                                  // setPickDate('purchaseDate');
                                                  // setShowDateTimePicker1(true);
                                                  setShowPurchaseDatePicker(
                                                    true
                                                  );
                                                }}
                                              >
                                                <GCalendar
                                                  width={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                  height={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                />
                                              </View>
                                              <TouchableOpacity
                                                style={{
                                                  zIndex: 10,
                                                }}
                                                onPress={() => {
                                                  setPurchaseDateModal(true);
                                                }}
                                              >
                                                <Text>
                                                  {purchaseDate.format(
                                                    "DD/MM/YYYY"
                                                  )}
                                                </Text>
                                              </TouchableOpacity>
                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={purchaseDateModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {/* <View>
                                                      <Text>Start Date</Text>
                                                    </View> */}
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        top: "20%",
                                                        position: "absolute",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Purchase Date
                                                      </Text>
                                                    </View>
                                                    {/* <TouchableOpacity> */}
                                                    <View
                                                      style={{
                                                        borderWidth: 1,
                                                        width:
                                                          windowWidth * 0.05,
                                                      }}
                                                    >
                                                      <DatePicker
                                                        selected={moment(
                                                          purchaseDate
                                                        ).toDate()}
                                                        // styles = {{backgroundColor:'black'}}
                                                        onChange={(date) => {
                                                          // console.log(
                                                          //   "date: ",
                                                          //   date
                                                          // );
                                                          setPurchaseDate(
                                                            moment(date)
                                                          );
                                                          setPurchaseDateModal(
                                                            false
                                                          );
                                                        }}
                                                        // className = 'red-border'
                                                      />
                                                    </View>
                                                    {/* </TouchableOpacity> */}

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setPurchaseDateModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>

                                              {/* <View
                                                style={{
                                                  zIndex: 100,
                                                }}
                                              >
                                                <DatePicker
                                                  selected={moment(
                                                    purchaseDate
                                                  ).toDate()}
                                                  onChange={(date) => {
                                                    console.log("date: ", date);
                                                    setPurchaseDate(
                                                      moment(date)
                                                    );
                                                  }}
                                                />
                                              </View> */}

                                              {/* <Text
                                                style={[
                                                  {
                                                    //width: "90%",
                                                    marginRight: 5,
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: purchaseDate
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {purchaseDate
                                                  ? moment(purchaseDate).format(
                                                      "DD MMM YYYY"
                                                    )
                                                  : "Start Purchase"}
                                              </Text> */}
                                            </View>

                                            <View
                                              style={[
                                                {
                                                  marginLeft: 35,
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              {/* <Text style={styles.textSize}>End Date:</Text> */}
                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Purchase End Date
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  paddingLeft: 5,
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      height: 35,
                                                      width: 100,
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <View
                                                style={[
                                                  {
                                                    marginRight: 5,
                                                    justifyContent:
                                                      "space-between",
                                                    paddingRight: 5,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        paddingRight: 10,
                                                      }
                                                    : {},
                                                ]}
                                                onPress={() => {
                                                  setShowPurchaseEndDatePicker(
                                                    true
                                                  );
                                                }}
                                              >
                                                <GCalendar
                                                  width={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                  height={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                />
                                              </View>

                                              <TouchableOpacity
                                                style={{
                                                  zIndex: 10,
                                                }}
                                                onPress={() => {
                                                  setPurchaseEndDateModal(true);
                                                }}
                                              >
                                                <Text>
                                                  {purchaseEndDate.format(
                                                    "DD/MM/YYYY"
                                                  )}
                                                </Text>
                                              </TouchableOpacity>
                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={purchaseEndDateModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {/* <View>
                                                      <Text>Start Date</Text>
                                                    </View> */}
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        top: "20%",
                                                        position: "absolute",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Purchase End Date
                                                      </Text>
                                                    </View>
                                                    {/* <TouchableOpacity> */}
                                                    <View
                                                      style={{
                                                        borderWidth: 1,
                                                        width:
                                                          windowWidth * 0.05,
                                                      }}
                                                    >
                                                      <DatePicker
                                                        selected={moment(
                                                          purchaseEndDate
                                                        ).toDate()}
                                                        // styles = {{backgroundColor:'black'}}
                                                        onChange={(date) => {
                                                          // console.log(
                                                          //   "date: ",
                                                          //   date
                                                          // );
                                                          setPurchaseEndDate(
                                                            moment(date)
                                                          );
                                                          setPurchaseEndDateModal(
                                                            false
                                                          );
                                                        }}
                                                        // className = 'red-border'
                                                      />
                                                    </View>
                                                    {/* </TouchableOpacity> */}

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setPurchaseEndDateModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>

                                              {/* <View
                                                style={{
                                                  zIndex: 100,
                                                }}
                                              >
                                                <DatePicker
                                                  selected={moment(
                                                    purchaseDate
                                                  ).toDate()}
                                                  onChange={(date) => {
                                                    console.log("date: ", date);
                                                    setPurchaseDate(
                                                      moment(date)
                                                    );
                                                  }}
                                                />
                                              </View> */}

                                              {/* <Text
                                                style={[
                                                  {
                                                    marginRight: 5,
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: purchaseEndDate
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {purchaseEndDate
                                                  ? moment(
                                                      purchaseEndDate
                                                    ).format("DD MMM YYYY")
                                                  : "End Purchase"}
                                              </Text> */}
                                            </View>
                                          </View>

                                          <View
                                            style={{
                                              flexDirection: "row",
                                              //flex: 1,
                                              marginTop: 22,
                                              zIndex: -10,
                                              //alignItems: 'center'
                                            }}
                                          >
                                            <View
                                              style={[
                                                {
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              {/* <Text style={styles.textSize}>Start Date:</Text> */}
                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Purchase Start Time
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  // paddingLeft: 5,
                                                },
                                              ]}
                                            >
                                              <View
                                                style={{
                                                  marginRight: 5,
                                                  justifyContent:
                                                    "space-between",
                                                  // paddingRight: 20,
                                                }}
                                                onPress={() => {
                                                  // setPickDate('purchaseTime)');
                                                  // setShowDateTimePicker1(true);
                                                  setShowPurchaseTimePicker(
                                                    !showPurchaseTimePicker
                                                  );
                                                }}
                                              >
                                                <Icon
                                                  name="clock"
                                                  size={
                                                    switchMerchant ? 20 : 25
                                                  }
                                                  color={Colors.primaryColor}
                                                />
                                              </View>

                                              <TouchableOpacity
                                                onPress={() => {
                                                  setPurchaseTimeModal(true);
                                                  // console.log('dasd');
                                                }}
                                              >
                                                <Text
                                                  style={[
                                                    {
                                                      //width: "90%",
                                                      // marginRight: 5,
                                                      fontFamily:
                                                        "NunitoSans-Regular",
                                                      fontSize: 14,
                                                      color: purchaseTime
                                                        ? "black"
                                                        : Colors.descriptionColor,
                                                    },
                                                  ]}
                                                >
                                                  {moment(purchaseTime).format(
                                                    "hh:mm A"
                                                  )}
                                                </Text>
                                              </TouchableOpacity>

                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={purchaseTimeModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        marginBottom: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Purchase Start Time
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={
                                                        {
                                                        }
                                                      }
                                                    >
                                                      <TimeKeeper
                                                        time={moment(
                                                          purchaseTime
                                                        ).format("hh:mm a")}
                                                        onChange={(time) => {
                                                          setPurchaseTime(
                                                            moment(
                                                              `${moment(
                                                                purchaseTime
                                                              ).format(
                                                                "MM/DD/YYYY"
                                                              )} ${
                                                                time.formatted12
                                                              }`
                                                            )
                                                          );
                                                        }}
                                                      ></TimeKeeper>

                                                    </View>

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setPurchaseTimeModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>
                                            </View>

                                            <View
                                              style={[
                                                {
                                                  marginLeft: 35,
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              {/* <Text style={styles.textSize}>End Date:</Text> */}
                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Purchase End Time
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  // paddingLeft: 5,
                                                },
                                              ]}
                                            >
                                              <View
                                                style={{
                                                  marginRight: 5,
                                                  justifyContent:
                                                    "space-between",
                                                  // paddingRight: 10,
                                                }}
                                                onPress={() => {
                                                  setShowPurchaseEndDatePicker(
                                                    !showPurchaseEndDatePicker
                                                  );
                                                }}
                                              >
                                                <Icon
                                                  name="clock"
                                                  size={25
                                                  }
                                                  // style={{marginRight:10}}
                                                  color={Colors.primaryColor}
                                                />
                                              </View>

                                              <TouchableOpacity
                                                onPress={() => {
                                                  setPurchaseEndTimeModal(true);
                                                  // console.log('dasd');
                                                }}
                                              >
                                                <Text
                                                  style={[
                                                    {
                                                      //width: "90%",
                                                      // marginRight: 5,
                                                      fontFamily:
                                                        "NunitoSans-Regular",
                                                      fontSize: 14,
                                                      color: purchaseTime
                                                        ? "black"
                                                        : Colors.descriptionColor,
                                                    },
                                                  ]}
                                                >
                                                  {moment(purchaseEndTime).format(
                                                    "hh:mm A"
                                                  )}
                                                </Text>
                                              </TouchableOpacity>

                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={purchaseEndTimeModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        marginBottom: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Purchase End Time
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={
                                                        {
                                                        }
                                                      }
                                                    >
                                                      <TimeKeeper
                                                        time={moment(
                                                          purchaseEndTime
                                                        ).format("hh:mm a")}
                                                        onChange={(time) => {
                                                          setPurchaseEndTime(
                                                            moment(
                                                              `${moment(
                                                                purchaseEndTime
                                                              ).format(
                                                                "MM/DD/YYYY"
                                                              )} ${
                                                                time.formatted12
                                                              }`
                                                            )
                                                          );
                                                        }}
                                                      ></TimeKeeper>

                                                    </View>

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setPurchaseEndTimeModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>


                                              {/* <View
                                                style={{
                                                  width: switchMerchant ? 5 : 0,
                                                }}
                                              /> */}
                                              {/* <Text
                                                style={[
                                                  {
                                                    //width: "90%",
                                                    marginRight: 5,
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: purchaseEndTime
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {purchaseEndTime
                                                  ? moment(
                                                      purchaseEndTime
                                                    ).format("hh:mm A")
                                                  : "End Purchase"}
                                              </Text> */}
                                            </View>
                                            {showPurchaseEndDatePicker ? (
                                              <View
                                                style={{
                                                  zIndex: 10000000,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    position: "absolute",
                                                  }}
                                                >
                                                  <TimeKeeper
                                                    time={moment(
                                                      purchaseEndTime
                                                    ).format("hh:mm a")}
                                                    onChange={(time) => {
                                                      setPurchaseEndTime(
                                                        moment(
                                                          `${moment(
                                                            purchaseEndTime
                                                          ).format(
                                                            "MM/DD/YYYY"
                                                          )} ${
                                                            time.formatted12
                                                          }`
                                                        )
                                                      );
                                                    }}
                                                    onDoneClick={() => {
                                                      setShowPurchaseEndDatePicker(
                                                        false
                                                      );
                                                    }}
                                                  ></TimeKeeper>
                                                </View>
                                              </View>
                                            ) : null}
                                          </View>

                                          <View
                                            style={{
                                              flexDirection: "row",
                                              //flex: 1,
                                              marginTop: 22,
                                              zIndex: -10,
                                              //alignItems: 'center'
                                            }}
                                          >
                                            <View
                                              style={[
                                                {
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              {/* <Text style={styles.textSize}>Start Date:</Text> */}
                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Redeem Start Date
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  paddingLeft: 5,
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      height: 35,
                                                      width: 100,
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <View
                                                //disabled={!(!loading && (!selectedVoucherEdit || !selectedVoucherEdit.isPublishedOnce))}
                                                style={[
                                                  {
                                                    marginRight: 5,
                                                    justifyContent:
                                                      "space-between",
                                                    paddingRight: 5,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        paddingRight: 10,
                                                      }
                                                    : {},
                                                ]}
                                                onPress={() => {
                                                  // setPickDate('purchaseDate');
                                                  // setShowDateTimePicker1(true);
                                                  setShowRedeemDatePicker(true);
                                                }}
                                              >
                                                <GCalendar
                                                  width={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                  height={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                />
                                              </View>

                                              <TouchableOpacity
                                                style={{
                                                  zIndex: 10,
                                                }}
                                                onPress={() => {
                                                  setRedeemDateModal(true);
                                                }}
                                              >
                                                <Text>
                                                  {redeemDate.format(
                                                    "DD/MM/YYYY"
                                                  )}
                                                </Text>
                                              </TouchableOpacity>
                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={redeemDateModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {/* <View>
                                                      <Text>Start Date</Text>
                                                    </View> */}
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        top: "20%",
                                                        position: "absolute",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Redeem Date
                                                      </Text>
                                                    </View>
                                                    {/* <TouchableOpacity> */}
                                                    <View
                                                      style={{
                                                        borderWidth: 1,
                                                        width:
                                                          windowWidth * 0.05,
                                                      }}
                                                    >
                                                      <DatePicker
                                                        selected={moment(
                                                          redeemDate
                                                        ).toDate()}
                                                        // styles = {{backgroundColor:'black'}}
                                                        onChange={(date) => {
                                                          // console.log(
                                                          //   "date: ",
                                                          //   date
                                                          // );
                                                          setRedeemDate(
                                                            moment(date)
                                                          );
                                                          setRedeemDateModal(
                                                            false
                                                          );
                                                        }}
                                                        // className = 'red-border'
                                                      />
                                                    </View>
                                                    {/* </TouchableOpacity> */}

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setRedeemDateModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>

                                              {/* <View
                                                style={{
                                                  zIndex: 100,
                                                }}
                                              >
                                                <DatePicker
                                                  selected={moment(
                                                    redeemDate
                                                  ).toDate()}
                                                  // styles = {{backgroundColor:'black'}}
                                                  onChange={(date) => {
                                                    console.log("date: ", date);
                                                    setRedeemDate(moment(date));
                                                  }}
                                                  // className = 'red-border'
                                                />
                                              </View> */}

                                              {/* <Text
                                                style={[
                                                  {
                                                    //width: "90%",
                                                    marginRight: 5,
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: purchaseDate
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {redeemDate
                                                  ? moment(redeemDate).format(
                                                      "DD MMM YYYY"
                                                    )
                                                  : "Start Redeem"}
                                              </Text> */}
                                            </View>

                                            <View
                                              style={[
                                                {
                                                  marginLeft: 35,
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              {/* <Text style={styles.textSize}>End Date:</Text> */}
                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Redeem End Date
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  paddingLeft: 5,
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      height: 35,
                                                      width: 100,
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <View
                                                style={[
                                                  {
                                                    marginRight: 5,
                                                    justifyContent:
                                                      "space-between",
                                                    paddingRight: 5,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        paddingRight: 10,
                                                      }
                                                    : {},
                                                ]}
                                                onPress={() => {
                                                  setShowRedeemEndDatePicker(
                                                    true
                                                  );
                                                }}
                                              >
                                                <GCalendar
                                                  width={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                  height={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                />
                                              </View>

                                              <TouchableOpacity
                                                style={{
                                                  zIndex: 10,
                                                }}
                                                onPress={() => {
                                                  setRedeemEndDateModal(true);
                                                }}
                                              >
                                                <Text>
                                                  {redeemEndDate.format(
                                                    "DD/MM/YYYY"
                                                  )}
                                                </Text>
                                              </TouchableOpacity>
                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={redeemEndEndDateModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {/* <View>
                                                      <Text>Start Date</Text>
                                                    </View> */}
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        top: "20%",
                                                        position: "absolute",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Redeem End Date
                                                      </Text>
                                                    </View>
                                                    {/* <TouchableOpacity> */}
                                                    <View
                                                      style={{
                                                        borderWidth: 1,
                                                        width:
                                                          windowWidth * 0.05,
                                                      }}
                                                    >
                                                      <DatePicker
                                                        selected={moment(
                                                          redeemEndDate
                                                        ).toDate()}
                                                        // styles = {{backgroundColor:'black'}}
                                                        onChange={(date) => {
                                                          // console.log(
                                                          //   "date: ",
                                                          //   date
                                                          // );
                                                          setRedeemEndDate(
                                                            moment(date)
                                                          );
                                                          setRedeemEndDateModal(
                                                            false
                                                          );
                                                        }}
                                                        // className = 'red-border'
                                                      />
                                                    </View>
                                                    {/* </TouchableOpacity> */}

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setRedeemEndDateModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>

                                              {/* <View
                                                style={{
                                                  zIndex: 100,
                                                }}
                                              >
                                                <DatePicker
                                                  selected={moment(
                                                    redeemEndDate
                                                  ).toDate()}
                                                  // styles = {{backgroundColor:'black'}}
                                                  onChange={(date) => {
                                                    console.log("date: ", date);
                                                    setRedeemEndDate(
                                                      moment(date)
                                                    );
                                                  }}
                                                  // className = 'red-border'
                                                />
                                              </View> */}

                                              {/* <Text
                                                style={[
                                                  {
                                                    marginRight: 5,
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: purchaseEndDate
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {redeemEndDate
                                                  ? moment(
                                                      redeemEndDate
                                                    ).format("DD MMM YYYY")
                                                  : "End Redeem"}
                                              </Text> */}
                                            </View>
                                          </View>

                                          <View
                                            style={{
                                              flexDirection: "row",
                                              //flex: 1,
                                              marginTop: 22,
                                              zIndex: -10,
                                              //alignItems: 'center'
                                            }}
                                          >
                                            <View
                                              style={[
                                                {
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              {/* <Text style={styles.textSize}>Start Date:</Text> */}

                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Redeem Start Time
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  // paddingLeft: 5,
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      height: 35,
                                                      width: 100,
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <View
                                                style={{
                                                  marginRight: 5,
                                                  justifyContent:
                                                    "space-between",
                                                  // paddingRight: 20,
                                                }}
                                                onPress={() => {
                                                  // setPickDate('expiredTime');
                                                  // setShowDateTimePicker1(true);
                                                  setShowRedeemTimePicker(
                                                    !showRedeemTimePicker
                                                  );
                                                }}
                                              >
                                                <Icon
                                                  name="clock"
                                                  size={ 25
                                                  }
                                                  color={Colors.primaryColor}
                                                />
                                              </View>

                                              <TouchableOpacity
                                                onPress={() => {
                                                  setRedeemTimeModal(true);
                                                  // console.log('dasd');
                                                }}
                                              >
                                                <Text
                                                  style={[
                                                    {
                                                      //width: "90%",
                                                      // marginRight: 5,
                                                      fontFamily:
                                                        "NunitoSans-Regular",
                                                      fontSize: 14,
                                                      color: redeemTime
                                                        ? "black"
                                                        : Colors.descriptionColor,
                                                    },
                                                  ]}
                                                >
                                                  {moment(redeemTime).format(
                                                    "hh:mm A"
                                                  )}
                                                </Text>
                                              </TouchableOpacity>

                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={redeemTimeModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        marginBottom: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Redeem Time
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={
                                                        {
                                                        }
                                                      }
                                                    >
                                                      <TimeKeeper
                                                        time={moment(
                                                          redeemTime
                                                        ).format("hh:mm a")}
                                                        onChange={(time) => {
                                                          setRedeemTime(
                                                            moment(
                                                              `${moment(
                                                                redeemTime
                                                              ).format(
                                                                "MM/DD/YYYY"
                                                              )} ${
                                                                time.formatted12
                                                              }`
                                                            )
                                                          );
                                                        }}
                                                      ></TimeKeeper>

                                                    </View>

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setRedeemTimeModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>

                                              {/* <View
                                                style={{
                                                  width: switchMerchant ? 5 : 0,
                                                }}
                                              />
                                              <Text
                                                style={[
                                                  {
                                                    //width: "90%",
                                                    marginRight: 5,
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: redeemTime
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        marginLeft: 0,
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {redeemTime
                                                  ? moment(redeemTime).format(
                                                      "hh:mm A"
                                                    )
                                                  : "Start Redeem"}
                                              </Text> */}
                                            </View>

                                            {showRedeemTimePicker ? (
                                              <View
                                                style={{
                                                  zIndex: 10000000,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    position: "absolute",
                                                  }}
                                                >
                                                  <TimeKeeper
                                                    time={moment(
                                                      redeemTime
                                                    ).format("hh:mm a")}
                                                    onChange={(time) => {
                                                      setRedeemTime(
                                                        moment(
                                                          `${moment(
                                                            redeemTime
                                                          ).format(
                                                            "MM/DD/YYYY"
                                                          )} ${
                                                            time.formatted12
                                                          }`
                                                        )
                                                      );
                                                    }}
                                                    onDoneClick={() => {
                                                      setShowRedeemTimePicker(
                                                        false
                                                      );
                                                    }}
                                                  ></TimeKeeper>
                                                </View>
                                              </View>
                                            ) : null}

                                            <View
                                              style={[
                                                {
                                                  marginLeft: 35,
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              {/* <Text style={styles.textSize}>End Date:</Text> */}
                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Redeem End Time
                                              </Text>
                                            </View>

                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  // paddingLeft: 5,
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      height: 35,
                                                      width: 100,
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <View
                                                style={{
                                                  marginRight: 5,
                                                  justifyContent:
                                                    "space-between",
                                                  // paddingRight: 20,
                                                }}
                                                onPress={() => {
                                                  // setPickDate('expiredTime');
                                                  // setShowDateTimePicker1(true);
                                                  setShowRedeemEndTimePicker(
                                                    !showRedeemEndTimePicker
                                                  );
                                                }}
                                              >
                                                <Icon
                                                  name="clock"
                                                  size={
                                                    switchMerchant ? 20 : 25
                                                  }
                                                  color={Colors.primaryColor}
                                                />
                                              </View>

                                              <TouchableOpacity
                                                onPress={() => {
                                                  setRedeemEndTimeModal(true);
                                                  // console.log('dasd');
                                                }}
                                              >
                                                <Text
                                                  style={[
                                                    {
                                                      //width: "90%",
                                                      // marginRight: 5,
                                                      fontFamily:
                                                        "NunitoSans-Regular",
                                                      fontSize: 14,
                                                      color: redeemEndTime
                                                        ? "black"
                                                        : Colors.descriptionColor,
                                                    },
                                                  ]}
                                                >
                                                  {moment(redeemEndTime).format(
                                                    "hh:mm A"
                                                  )}
                                                </Text>
                                              </TouchableOpacity>

                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={redeemEndTimeModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        marginBottom: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Redeem End Time
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={
                                                        {
                                                        }
                                                      }
                                                    >
                                                      <TimeKeeper
                                                        time={moment(
                                                          redeemEndTime
                                                        ).format("hh:mm a")}
                                                        onChange={(time) => {
                                                          setRedeemEndTime(
                                                            moment(
                                                              `${moment(
                                                                redeemEndTime
                                                              ).format(
                                                                "MM/DD/YYYY"
                                                              )} ${
                                                                time.formatted12
                                                              }`
                                                            )
                                                          );
                                                        }}
                                                      ></TimeKeeper>

                                                    </View>

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setRedeemEndTimeModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>


                                              {/* <View
                                                style={{
                                                  width: switchMerchant ? 5 : 0,
                                                }}
                                              />
                                              <Text
                                                style={[
                                                  {
                                                    marginRight: 5,
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: redeemEndTime
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        marginLeft: 0,
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {redeemEndTime
                                                  ? moment(
                                                      redeemEndTime
                                                    ).format("hh:mm A")
                                                  : "End Redeem"}
                                              </Text> */}
                                            </View>
                                            {/* {showRedeemEndTimePicker ? (
                                              <View
                                                style={{
                                                  zIndex: 10000000,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    position: "absolute",
                                                  }}
                                                >
                                                  <TimeKeeper
                                                    time={moment(
                                                      redeemEndTime
                                                    ).format("hh:mm a")}
                                                    onChange={(time) => {
                                                      setRedeemEndTime(
                                                        moment(
                                                          `${moment(
                                                            redeemEndTime
                                                          ).format(
                                                            "MM/DD/YYYY"
                                                          )} ${
                                                            time.formatted12
                                                          }`
                                                        )
                                                      );
                                                    }}
                                                    onDoneClick={() => {
                                                      setShowRedeemEndTimePicker(
                                                        false
                                                      );
                                                    }}
                                                  ></TimeKeeper>
                                                </View>
                                              </View>
                                            ) : null} */}
                                          </View>

                                          <View
                                            style={{
                                              flexDirection: "row",
                                              //flex: 1,
                                              marginTop: 22,
                                              zIndex: -10,
                                              //alignItems: 'center'
                                            }}
                                          >
                                            <View
                                              style={[
                                                {
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              {/* <Text style={styles.textSize}>Start Date:</Text> */}

                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Expired Date
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  height: 40,
                                                  width: 135,
                                                  justifyContent: "center",
                                                  paddingLeft: 5,
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      height: 35,
                                                      width: 100,
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <View
                                                //disabled={!(!loading && (!selectedVoucherEdit || !selectedVoucherEdit.isPublishedOnce))}
                                                style={[
                                                  {
                                                    marginRight: 5,
                                                    justifyContent:
                                                      "space-between",
                                                    paddingRight: 5,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        paddingRight: 10,
                                                      }
                                                    : {},
                                                ]}
                                                onPress={() => {
                                                  // setPickDate('expiredDate');
                                                  // setShowDateTimePicker1(true);
                                                  setShowExpiredDatePicker(
                                                    true
                                                  );
                                                }}
                                              >
                                                <GCalendar
                                                  width={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                  height={
                                                    switchMerchant ? 15 : 22
                                                  }
                                                />
                                              </View>
                                              <TouchableOpacity
                                                style={{
                                                  zIndex: 10,
                                                }}
                                                onPress={() => {
                                                  setExpiredDateModal(true);
                                                }}
                                              >
                                                <Text>
                                                  {expiredDate.format(
                                                    "DD/MM/YYYY"
                                                  )}
                                                </Text>
                                              </TouchableOpacity>
                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={expiredDateModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    {/* <View>
                                                      <Text>Start Date</Text>
                                                    </View> */}
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        top: "20%",
                                                        position: "absolute",
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Expired Date
                                                      </Text>
                                                    </View>
                                                    {/* <TouchableOpacity> */}
                                                    <View
                                                      style={{
                                                        borderWidth: 1,
                                                        width:
                                                          windowWidth * 0.05,
                                                      }}
                                                    >
                                                      <DatePicker
                                                        selected={moment(
                                                          expiredDate
                                                        ).toDate()}
                                                        // styles = {{backgroundColor:'black'}}
                                                        onChange={(date) => {
                                                          // console.log(
                                                          //   "date: ",
                                                          //   date
                                                          // );
                                                          setExpiredDate(
                                                            moment(date)
                                                          );
                                                          setExpiredDateModal(
                                                            false
                                                          );
                                                        }}
                                                        // className = 'red-border'
                                                      />
                                                    </View>
                                                    {/* </TouchableOpacity> */}

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                      }}
                                                      onPress={() => {
                                                        setExpiredDateModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>

                                              {/* <View
                                                style={{
                                                  zIndex: 100,
                                                }}
                                              >
                                                <DatePicker
                                                  selected={moment(
                                                    expiredDate
                                                  ).toDate()}
                                                  // styles = {{backgroundColor:'black'}}
                                                  onChange={(date) => {
                                                    console.log("date: ", date);
                                                    setExpiredDate(
                                                      moment(date)
                                                    );
                                                  }}
                                                  // className = 'red-border'
                                                />
                                              </View> */}

                                              {/* <Text
                                                style={[
                                                  {
                                                    //width: "90%",
                                                    marginRight: 5,
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: expiredDate
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {expiredDate
                                                  ? moment(expiredDate).format(
                                                      "DD MMM YYYY"
                                                    )
                                                  : "Expired Date"}
                                              </Text> */}
                                            </View>

                                            <View
                                              style={[
                                                {
                                                  marginLeft: 35,
                                                  width: "25%",
                                                  //justifyContent: 'flex-end',
                                                  justifyContent: "center",
                                                },
                                                switchMerchant
                                                  ? {
                                                      width: "20%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              {/* <Text style={styles.textSize}>End Date:</Text> */}
                                              <Text
                                                style={[
                                                  styles.textSize,
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                Expired Time
                                              </Text>
                                            </View>
                                            <View
                                              style={[
                                                {
                                                  flexDirection: "row",
                                                  alignItems: "center",
                                                  //marginLeft: 5,
                                                  borderRadius: 5,
                                                  borderWidth: 1,
                                                  borderColor: "#E5E5E5",
                                                  //marginLeft:'6%',
                                                  height: 40,
                                                  width: 135,
                                                  //marginTop: -15,
                                                  justifyContent: "center",
                                                  // paddingLeft: 5,
                                                },
                                                switchMerchant
                                                  ? {
                                                      justifyContent: "center",
                                                      height: 35,
                                                      width: 100,
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              <View
                                                style={{
                                                  marginRight: 5,
                                                  justifyContent:
                                                    "space-between",
                                                  // paddingRight: 20,
                                                }}
                                                onPress={() => {
                                                  // setPickDate('expiredTime');
                                                  // setShowDateTimePicker1(true);
                                                  setShowExpiredTimePicker(
                                                    !showExpiredTimePicker
                                                  );
                                                  console.log(
                                                    Dimensions.get("screen")
                                                      .width
                                                  );
                                                }}
                                              >
                                                <Icon
                                                  name="clock"
                                                  size={
                                                    switchMerchant ? 20 : 25
                                                  }
                                                  color={Colors.primaryColor}
                                                />
                                              </View>

                                              <TouchableOpacity
                                                onPress={() => {
                                                  setExpiredTimeModal(true);
                                                  // console.log('dasd');
                                                }}
                                              >
                                                <Text
                                                  style={[
                                                    {
                                                      //width: "90%",
                                                      // marginRight: 5,
                                                      fontFamily:
                                                        "NunitoSans-Regular",
                                                      fontSize: 14,
                                                      color: expiredTime
                                                        ? "black"
                                                        : Colors.descriptionColor,
                                                    },
                                                  ]}
                                                >
                                                  {moment(expiredTime).format(
                                                    "hh:mm A"
                                                  )}
                                                </Text>
                                              </TouchableOpacity>

                                              <Modal
                                                style={{ flex: 1 }}
                                                visible={expiredTimeModal}
                                                transparent={true}
                                                animationType="slide"
                                              >
                                                <View
                                                  style={{
                                                    flex: 1,
                                                    backgroundColor:
                                                      Colors.modalBgColor,
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <View
                                                    style={{
                                                      backgroundColor:
                                                        Colors.whiteColor,
                                                      borderRadius: 12,
                                                      padding:
                                                        windowWidth * 0.05,
                                                      alignItems: "center",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <View
                                                      style={{
                                                        alignItems: "center",
                                                        marginBottom: 10,
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                          textAlign: "center",
                                                          fontSize: 24,
                                                        }}
                                                      >
                                                        Expired Time
                                                      </Text>
                                                    </View>
                                                    <View
                                                      style={
                                                        {
                                                        }
                                                      }
                                                    >
                                                      <TimeKeeper
                                                        time={moment(
                                                          expiredTime                                                        ).format("hh:mm a")}
                                                        onChange={(time) => {
                                                          setExpiredTime(
                                                            moment(
                                                              `${moment(
                                                                expiredTime
                                                              ).format(
                                                                "MM/DD/YYYY"
                                                              )} ${
                                                                time.formatted12
                                                              }`
                                                            )
                                                          );
                                                        }}
                                                      ></TimeKeeper>

                                                    </View>

                                                    <TouchableOpacity
                                                      style={{
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "row",
                                                        borderWidth: 1,
                                                        borderColor:
                                                          Colors.primaryColor,
                                                        backgroundColor:
                                                          "#4E9F7D",
                                                        borderRadius: 5,
                                                        //width: 200,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: "center",
                                                        shadowOffset: {
                                                          width: 0,
                                                          height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                        marginTop: 20,
                                                        
                                                      }}
                                                      onPress={() => {
                                                        setExpiredTimeModal(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      <Text
                                                        style={{
                                                          color: "white",
                                                          fontSize: 16,
                                                          fontFamily:
                                                            "NunitoSans-Bold",
                                                        }}
                                                      >
                                                        Cancel
                                                      </Text>
                                                    </TouchableOpacity>
                                                  </View>
                                                </View>
                                              </Modal>
                                              {/* <View
                                                style={{
                                                  width: switchMerchant ? 5 : 0,
                                                }}
                                              />
                                              <Text
                                                style={[
                                                  {
                                                    //width: "90%",
                                                    fontFamily:
                                                      "NunitoSans-Regular",
                                                    fontSize: 14,
                                                    color: expiredTime
                                                      ? "black"
                                                      : Colors.descriptionColor,
                                                    marginRight: 5,
                                                  },
                                                  switchMerchant
                                                    ? {
                                                        fontSize: 10,
                                                      }
                                                    : {},
                                                ]}
                                              >
                                                {expiredTime
                                                  ? moment(expiredTime).format(
                                                      "hh:mm A"
                                                    )
                                                  : "Expired Time"}
                                              </Text> */}
                                            </View>
                                            {showExpiredTimePicker ? (
                                              <View
                                                style={{
                                                  zIndex: 100000,
                                                }}
                                              >
                                                <View
                                                  style={{
                                                    position: "absolute",
                                                  }}
                                                >
                                                  <TimeKeeper
                                                    time={moment(
                                                      expiredTime
                                                    ).format("hh:mm a")}
                                                    onChange={(time) => {
                                                      setExpiredTime(
                                                        moment(
                                                          `${moment(
                                                            expiredTime
                                                          ).format(
                                                            "MM/DD/YYYY"
                                                          )} ${
                                                            time.formatted12
                                                          }`
                                                        )
                                                      );
                                                    }}
                                                    onDoneClick={() => {
                                                      setShowExpiredTimePicker(
                                                        false
                                                      );
                                                    }}
                                                  ></TimeKeeper>
                                                </View>
                                              </View>
                                            ) : null}
                                          </View>
                                        </View>
                                      </View>
                                    </View>
                                  </View>

                                  <View style={{ width: "100%" }}>
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        marginTop: 0,
                                        justifyContent: "space-between",
                                        width: "100%",
                                      }}
                                    >
                                      <View
                                        style={[
                                          {
                                            flexDirection: "row",
                                            width: "100%",
                                          },
                                          switchMerchant ? {} : {},
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            {
                                              fontFamily: "NunitoSans-Bold",
                                              fontSize: 14,
                                              width: "23%",
                                              paddingTop: "3.4%",
                                            },
                                            switchMerchant
                                              ? {
                                                  paddingTop: 35,
                                                  fontSize: 10,
                                                  width: "25%",
                                                }
                                              : {},
                                          ]}
                                        >
                                          Products
                                        </Text>
                                        <View
                                          style={[
                                            { width: "77%", marginTop: 0 },
                                            switchMerchant
                                              ? {
                                                  width: "75%",
                                                }
                                              : {},
                                          ]}
                                        >
                                          {renderProducts()}
                                        </View>
                                      </View>
                                    </View>

                                    <View
                                      style={{
                                        flexDirection: "row",
                                        marginTop: 22,
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignSelf: "center",
                                        zIndex: -3,
                                      }}
                                    >
                                      <View
                                        style={[
                                          {
                                            flexDirection: "row",
                                            width: "100%",
                                            alignItems: "center",
                                          },
                                          switchMerchant ? {} : {},
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            {
                                              fontFamily: "NunitoSans-Bold",
                                              fontSize: 14,
                                              width: "23%",
                                            },
                                            switchMerchant
                                              ? {
                                                  fontSize: 10,
                                                  width: "25%",
                                                }
                                              : {},
                                          ]}
                                        >
                                          Docket Price (RM)
                                        </Text>
                                        <View
                                          style={[
                                            {
                                              flexDirection: "row",
                                              width: "77%",
                                              marginTop: 0,
                                            },
                                            switchMerchant
                                              ? {
                                                  width: "75%",
                                                }
                                              : {},
                                          ]}
                                        >
                                          <TextInput
                                            style={[
                                              {
                                                borderColor: "#E5E5E5",
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                backgroundColor:
                                                  Colors.fieldtBgColor,
                                                paddingLeft: 5,
                                                // marginLeft: Dimensions.get('screen').width <= 1133 ? '16%' : 10,
                                                height: 40,
                                                width: 100,
                                                fontFamily:
                                                  "NunitoSans-Regular",
                                                fontSize: 14,
                                                //right: Platform.OS == 'ios' ? 8 : 0,
                                                // marginTop:20
                                              },
                                              switchMerchant
                                                ? {
                                                    fontSize: 10,
                                                    height: 35,
                                                  }
                                                : {},
                                            ]}
                                            placeholderTextColor={Platform.select(
                                              { ios: "#a9a9a9" }
                                            )}
                                            placeholderStyle={{
                                              fontFamily: "NunitoSans-Regular",
                                              fontSize: switchMerchant
                                                ? 10
                                                : 14,
                                            }}
                                            placeholder={"RM"}
                                            onChangeText={(text) => {
                                              setDocketPrice(text);
                                            }}
                                            defaultValue={docketPrice}
                                            keyboardType={"number-pad"}
                                          />
                                        </View>
                                      </View>
                                    </View>

                                    <View
                                      style={{
                                        flexDirection: "row",
                                        marginTop: 22,
                                        justifyContent: "space-between",
                                        width: "100%",
                                        alignSelf: "center",
                                        zIndex: -3,
                                      }}
                                    >
                                      <View
                                        style={[
                                          {
                                            flexDirection: "row",
                                            width: "100%",
                                            alignItems: "center",
                                            // top:
                                            //   Platform.OS === 'android'
                                            //     ? 0
                                            //     : keyboardHeight > 0
                                            //     ? -keyboardHeight * 0.5
                                            //     : 0,
                                          },
                                          switchMerchant ? {} : {},
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            {
                                              fontFamily: "NunitoSans-Bold",
                                              fontSize: 14,
                                              width: "23%",
                                            },
                                            switchMerchant
                                              ? {
                                                  fontSize: 10,
                                                  width: "25%",
                                                }
                                              : {},
                                          ]}
                                        >
                                          Redemption Quantity Limit
                                        </Text>
                                        <View
                                          style={[
                                            {
                                              flexDirection: "row",
                                              width: "77%",
                                              marginTop: 0,
                                            },
                                            switchMerchant
                                              ? {
                                                  width: "75%",
                                                }
                                              : {},
                                          ]}
                                        >
                                          <View
                                            style={[
                                              {
                                                marginRight: 15,
                                                justifyContent: "center",
                                                alignItems: "center",
                                                // marginTop: 12,
                                              },
                                              switchMerchant ? {} : {},
                                            ]}
                                          >
                                            <Text
                                              style={[
                                                {
                                                  fontFamily:
                                                    "NunitoSans-Regular",
                                                  fontSize: 14,
                                                },
                                                switchMerchant
                                                  ? {
                                                      fontSize: 10,
                                                      marginRight: "1%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              RM
                                            </Text>
                                          </View>
                                          <TextInput
                                            style={[
                                              {
                                                borderColor: "#E5E5E5",
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                backgroundColor:
                                                  Colors.fieldtBgColor,
                                                paddingLeft: 5,
                                                height: 40,
                                                width: 100,

                                                // marginRight: 10
                                              },
                                              switchMerchant
                                                ? {
                                                    fontSize: 10,
                                                    height: 35,
                                                  }
                                                : {},
                                            ]}
                                            placeholder={"Quantity"}
                                            placeholderStyle={{
                                              fontFamily: "NunitoSans-Regular",
                                              fontSize: switchMerchant
                                                ? 10
                                                : 14,
                                            }}
                                            placeholderTextColor={Platform.select(
                                              { ios: "#a9a9a9" }
                                            )}
                                            onChangeText={(text) => {
                                              setRedemptionQuantityLimitValue(
                                                text
                                              );
                                            }}
                                            defaultValue={
                                              redemptionQuantityLimitValue
                                            }
                                            keyboardType={"number-pad"}
                                          />
                                          <View
                                            style={[
                                              {
                                                marginHorizontal: 15,
                                                justifyContent: "center",
                                                alignItems: "center",
                                                // marginTop: 12,
                                              },
                                              switchMerchant ? {} : {},
                                            ]}
                                          >
                                            <Text
                                              style={[
                                                {
                                                  fontFamily:
                                                    "NunitoSans-Regular",
                                                  fontSize: 14,
                                                },
                                                switchMerchant
                                                  ? {
                                                      fontSize: 10,
                                                      marginRight: "1%",
                                                    }
                                                  : {},
                                              ]}
                                            >
                                              For
                                            </Text>
                                          </View>
                                          <TextInput
                                            style={[
                                              {
                                                borderColor: "#E5E5E5",
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                backgroundColor:
                                                  Colors.fieldtBgColor,
                                                paddingLeft: 5,
                                                height: 40,
                                                width: 100,
                                                marginRight: "3%",
                                                fontFamily:
                                                  "NunitoSans-Regular",
                                                fontSize: 14,
                                              },
                                            ]}
                                            placeholder={"Per"}
                                            placeholderStyle={{
                                              fontFamily: "NunitoSans-Regular",
                                              fontSize: 14,
                                            }}
                                            placeholderTextColor={Platform.select(
                                              { ios: "#a9a9a9" }
                                            )}
                                            onChangeText={(text) => {
                                              setRedemptionQuantityLimitDateFormatValue(
                                                text
                                              );
                                            }}
                                            defaultValue={
                                              redemptionQuantityLimitDateFormatValue
                                            }
                                          />
                                          <View
                                            style={[
                                              {
                                                marginLeft: 5,
                                                align: "center",
                                                // borderWidth: 1,
                                                justifyContent: "center",
                                              },
                                            ]}
                                          >
                                            <Picker
                                              selectedValue={
                                                redemptionQuantityLimitDateFormatType
                                              }
                                              style={{
                                                width: 100,
                                                paddingVertical: 0,
                                                backgroundColor:
                                                  Colors.fieldtBgColor,
                                                borderRadius: 10,
                                              }}
                                              onValueChange={(item) => {
                                                setRedemptionQuantityLimitDateFormatType(
                                                  item.value
                                                );
                                              }}
                                            >
                                              {DATE_FORMAT_DROPDOWN_LIST
                                                // .concat({
                                                //   label: "All",
                                                //   value: !showDetails
                                                //     ? dailySalesDetailsList.length
                                                //     : transactionTypeSalesDetails.length,
                                                // })
                                                .map((value, index) => {
                                                  return (
                                                    <Picker.Item
                                                      key={index}
                                                      label={value.label}
                                                      value={value.value}
                                                    />
                                                  );
                                                })}
                                            </Picker>
                                            {/* <DropDownPicker
                                              containerStyle={[
                                                { height: 40, zIndex: 2 },
                                              ]}
                                              arrowColor={"black"}
                                              arrowSize={20}
                                              arrowStyle={{
                                                fontWeight: "bold",
                                              }}
                                              labelStyle={{
                                                fontFamily:
                                                  "NunitoSans-Regular",
                                                fontSize: 14,
                                              }}
                                              style={{
                                                width: 100,
                                                paddingVertical: 0,
                                                backgroundColor:
                                                  Colors.fieldtBgColor,
                                                borderRadius: 10,
                                              }}
                                              placeholderStyle={{
                                                color: Colors.fieldtTxtColor,
                                                fontFamily:
                                                  "NunitoSans-Regular",
                                                fontSize: switchMerchant
                                                  ? 10
                                                  : 14,
                                              }}
                                              items={DATE_FORMAT_DROPDOWN_LIST}
                                              itemStyle={[
                                                {
                                                  justifyContent: "flex-start",
                                                  marginLeft: 5,
                                                  zIndex: 2,
                                                },
                                              ]}
                                              placeholder={"Day"}
                                              onChangeItem={(item) => {
                                                setRedemptionQuantityLimitDateFormatType(
                                                  item.value
                                                );
                                              }}
                                              defaultValue={
                                                redemptionQuantityLimitDateFormatType
                                              }
                                              dropDownMaxHeight={150}
                                              dropDownStyle={{
                                                width: 100,
                                                height: 120,
                                                backgroundColor:
                                                  Colors.fieldtBgColor,
                                                borderRadius: 10,
                                                borderWidth: 1,
                                                textAlign: "left",
                                                zIndex: 2,
                                              }}
                                              globalTextStyle={{
                                                fontSize: switchMerchant
                                                  ? 10
                                                  : 16,
                                              }}

                                              // containerStyle={{ height: 40, }}
                                              //   arrowColor={'black'}
                                              //   arrowSize={20}
                                              //   arrowStyle={{ fontWeight: 'bold' }}
                                              //   labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                                              //   style={{ width: 100, height: 40, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, }}
                                              //   placeholderStyle={{ color: 'black' }}
                                              //   items={DATE_FORMAT_DROPDOWN_LIST}
                                              //   itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                                              //   placeholder={"Day"}
                                              //   onChangeItem={item => {
                                              //     setRedemptionQuantityLimitDateFormatType(item.value);
                                              //   }}
                                              //   defaultValue={redemptionQuantityLimitDateFormatType}
                                              //   dropDownMaxHeight={150}
                                              //   dropDownStyle={{ width: 100, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1, }}
                                            /> */}
                                          </View>
                                        </View>
                                      </View>
                                    </View>

                                    <View
                                      style={[
                                        {
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          zIndex: -15,
                                          marginTop: 20,
                                        },
                                      ]}
                                    >
                                      <View
                                        style={[
                                          {
                                            flexDirection: "row",
                                            width: "100%",
                                          },
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            {
                                              fontFamily: "NunitoSans-Bold",
                                              fontSize: 14,
                                              width: "23%",
                                              alignItems: "center",
                                              paddingTop: "1.5%",
                                            },
                                            switchMerchant
                                              ? {
                                                  fontSize: 10,
                                                  paddingTop: 0,
                                                  width: "25%",
                                                  paddingTop: "2.4%",
                                                }
                                              : {},
                                          ]}
                                        >
                                          Charges of Extension{" "}
                                        </Text>
                                        <View
                                          style={[
                                            { width: "77%", marginTop: 0 },
                                          ]}
                                        >
                                          {renderOptions()}
                                        </View>
                                      </View>
                                    </View>

                                    <View
                                      style={[
                                        {
                                          flexDirection: "row",
                                          marginTop: 22,
                                          justifyContent: "space-between",
                                          width: "100%",
                                          alignSelf: "center",
                                          zIndex: -30,
                                        },
                                      ]}
                                    >
                                      <View
                                        style={[
                                          {
                                            flexDirection: "row",
                                            width: "100%",
                                            alignItems: "center",
                                          },
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            {
                                              fontFamily: "NunitoSans-Bold",
                                              fontSize: 14,
                                              width: "23%",
                                            },
                                            switchMerchant
                                              ? {
                                                  fontSize: 10,
                                                  width: "25%",
                                                }
                                              : {},
                                          ]}
                                        >
                                          Redemption Outlet{" "}
                                        </Text>
                                        <View
                                          style={[
                                            {
                                              flexDirection: "row",
                                              width: "77%",
                                              marginTop: 0,
                                              alignItems: "center",
                                            },
                                          ]}
                                        >
                                          {/* <CheckBox
                                            style={[
                                              {
                                                padding: 0,
                                                marginRight: 10,
                                                //flexDirection: 'row',
                                              },
                                            ]}
                                            onClick={() => {
                                              if (
                                                allOutlets.length ===
                                                selectedOutletList.length
                                              ) {
                                                setSelectedOutletList([]);
                                              } else {
                                                setSelectedOutletList(
                                                  allOutlets.map(
                                                    (outlet) => outlet.uniqueId
                                                  )
                                                );
                                              }
                                            }}
                                            checkBoxColor={Colors.fieldtBgColor}
                                            uncheckedCheckBoxColor={
                                              Colors.tabGrey
                                            }
                                            checkedCheckBoxColor={
                                              Colors.primaryColor
                                            }
                                            isChecked={
                                              allOutlets.length ===
                                              selectedOutletList.length
                                            }
                                          /> */}
                                          <input
                                            onChange={() => {
                                              if (
                                                allOutlets.length ===
                                                selectedOutletList.length
                                              ) {
                                                setSelectedOutletList([]);
                                              } else {
                                                setSelectedOutletList(
                                                  allOutlets.map(
                                                    (outlet) => outlet.uniqueId
                                                  )
                                                );
                                              }
                                            }}
                                            style={{
                                              marginRight: 10,
                                            }}
                                            type={"checkbox"}
                                            checked={
                                              allOutlets.length ===
                                              selectedOutletList.length
                                            }
                                          />
                                          <Text
                                            style={[
                                              {
                                                marginRight: 10,
                                                fontFamily:
                                                  "NunitoSans-Regular",
                                                fontSize: 14,
                                                color:
                                                  allOutlets.length ===
                                                  selectedOutletList.length
                                                    ? Colors.primaryColor
                                                    : Colors.descriptionColor,
                                              },
                                              switchMerchant
                                                ? {
                                                    fontSize: 10,
                                                  }
                                                : {},
                                            ]}
                                          >
                                            All Outlets
                                          </Text>
                                          <View style={{ zIndex: -3 }}>
                                            {/* <Picker
                                              selectedValue={
                                                selectedOutletList
                                              }
                                              style={{
                                                width: 100,
                                                paddingVertical: 0,
                                                backgroundColor:
                                                  Colors.fieldtBgColor,
                                                borderRadius: 10,
                                              }}
                                              onValueChange={(item) => {
                                                setSelectedOutletList(item);
                                              }}
                                            >
                                              {outletDropdownList
                                                // .concat({
                                                //   label: "All",
                                                //   value: !showDetails
                                                //     ? dailySalesDetailsList.length
                                                //     : transactionTypeSalesDetails.length,
                                                // })
                                                .map((value, index) => {
                                                  return (
                                                    <Picker.Item
                                                      key={index}
                                                      label={value.label}
                                                      value={value.value}
                                                    />
                                                  );
                                                })}
                                            </Picker> */}
                                            <Select
                                              defaultValue={selectedOutletList}
                                              styles={{}}
                                              onChange={(item) => {
                                                console.log(item);
                                                setSelectedOutletList(item);
                                              }}
                                              options={outletDropdownList}
                                              placeholder="Choose outlet"
                                              isMulti
                                            />
                                            {/* <DropDownPicker
                                              containerStyle={[
                                                {
                                                  height: 40,
                                                  zIndex: 3,
                                                  marginLeft: 35,
                                                },
                                                switchMerchant
                                                  ? {
                                                      height: 35,
                                                      marginLeft: 15,
                                                    }
                                                  : {},
                                              ]}
                                              arrowColor={"black"}
                                              arrowSize={20}
                                              arrowStyle={{
                                                fontWeight: "bold",
                                              }}
                                              labelStyle={{
                                                fontFamily:
                                                  "NunitoSans-Regular",
                                                fontSize: switchMerchant
                                                  ? 10
                                                  : 14,
                                              }}
                                              style={[
                                                {
                                                  width: 235,
                                                  paddingVertical: 0,
                                                  backgroundColor:
                                                    Colors.fieldtBgColor,
                                                  borderRadius: 10,
                                                },
                                                switchMerchant
                                                  ? {
                                                      width: 176,
                                                    }
                                                  : {},
                                              ]}
                                              placeholderStyle={{
                                                color: Colors.fieldtTxtColor,
                                                fontFamily:
                                                  "NunitoSans-Regular",
                                                fontSize: switchMerchant
                                                  ? 10
                                                  : 14,
                                              }}
                                              items={outletDropdownList}
                                              itemStyle={{
                                                justifyContent: "flex-start",
                                                marginLeft: 5,
                                                zIndex: 3,
                                              }}
                                              placeholder={"Outlets"}
                                              multipleText={
                                                "%d outlet(s) selected"
                                              }
                                              customTickIcon={(press) => (
                                                <Ionicon
                                                  name={"md-checkbox"}
                                                  color={
                                                    press
                                                      ? Colors.fieldtBgColor
                                                      : Colors.primaryColor
                                                  }
                                                  size={25}
                                                />
                                              )}
                                              onChangeItem={(item) => {
                                                console.log(item);
                                                setSelectedOutletList(item);
                                              }}
                                              multiple={true}
                                              defaultValue={selectedOutletList}
                                              dropDownMaxHeight={150}
                                              dropDownStyle={[
                                                {
                                                  width: 237,
                                                  height: 90,
                                                  backgroundColor:
                                                    Colors.fieldtBgColor,
                                                  borderRadius: 10,
                                                  borderWidth: 1,
                                                  textAlign: "left",
                                                  zIndex: 2,
                                                },
                                                switchMerchant
                                                  ? {
                                                      width: 176,
                                                    }
                                                  : {},
                                              ]}
                                              globalTextStyle={{
                                                fontSize: switchMerchant
                                                  ? 10
                                                  : 14,
                                              }}

                                              // items={outletDropdownList}
                                              // containerStyle={{ height: 40 }}
                                              // arrowSize={22}
                                              // placeholder={"Outlets"}
                                              // placeholderStyle={{ color: 'black' }}
                                              // style={{ backgroundColor: '#fafafa', width: 250, paddingVertical: 0 }}
                                              // itemStyle={{
                                              //   justifyContent: 'flex-start',
                                              //   //flexDirection: 'row-reverse'
                                              // }}
                                              // // defaultValue={category1}
                                              // defaultValue={selectedOutletList}
                                              // multiple={true}
                                              // customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                                              // dropDownStyle={{ backgroundColor: '#fafafa', width: 250 }}
                                              // onChangeItem={(item) => {
                                              //   console.log(item);
                                              //   setSelectedOutletList(item)
                                              // }}
                                            /> */}
                                          </View>
                                        </View>
                                      </View>
                                    </View>

                                    <View
                                      style={[
                                        {
                                          flexDirection: "row",
                                          marginTop: 22,
                                          justifyContent: "space-between",
                                          width: "100%",
                                          zIndex: -60,
                                          //   bottom:
                                          //     Platform.OS === 'android'
                                          //       ? 0
                                          //       : keyboardHeight > 0
                                          //       ? keyboardHeight * 0.45
                                          //       : 0,
                                        },
                                        switchMerchant
                                          ? {
                                              // zIndex: -15
                                            }
                                          : {},
                                      ]}
                                    >
                                      <View
                                        style={[
                                          {
                                            flexDirection: "row",
                                            width: "100%",
                                          },
                                          switchMerchant ? {} : {},
                                        ]}
                                      >
                                        <Text
                                          style={[
                                            {
                                              fontFamily: "NunitoSans-Bold",
                                              fontSize: 14,
                                              width: "23%",
                                              alignItems: "flex-start",
                                              paddingTop: 4,
                                            },
                                            switchMerchant
                                              ? {
                                                  fontSize: 10,
                                                  width: "25%",
                                                }
                                              : {},
                                          ]}
                                        >
                                          Terms & Conditions
                                        </Text>
                                        <View
                                          style={[
                                            {
                                              flexDirection: "row",
                                              width: "77%",
                                              marginTop: 0,
                                            },
                                            switchMerchant
                                              ? {
                                                  width: "75%",
                                                }
                                              : {},
                                          ]}
                                        >
                                          <TextInput
                                            style={[
                                              {
                                                borderColor: "#E5E5E5",
                                                borderRadius: 5,
                                                borderWidth: 1,
                                                backgroundColor:
                                                  Colors.fieldtBgColor,
                                                height: 100,
                                                width: switchMerchant
                                                  ? 350
                                                  : 500,
                                                padding: 5,
                                                textAlignVertical: "top",
                                                fontFamily:
                                                  "NunitoSans-Regular",
                                                fontSize: 14,
                                              },
                                              switchMerchant
                                                ? {
                                                    fontSize: 10,
                                                  }
                                                : {},
                                            ]}
                                            multiline={true}
                                            placeholderTextColor={Platform.select(
                                              { ios: "#a9a9a9" }
                                            )}
                                            placeholderStyle={{
                                              fontFamily: "NunitoSans-Regular",
                                              fontSize: switchMerchant
                                                ? 10
                                                : 14,
                                            }}
                                            placeholder={"Terms & Conditions"}
                                            onChangeText={(text) => {
                                              setTermsConditions(text);
                                            }}
                                            defaultValue={termsConditions}
                                          />
                                        </View>
                                      </View>
                                    </View>
                                  </View>
                                  {/* This View is to push the t&c to top or not will be blocked */}
                                  <View
                                    style={{
                                      height:
                                        Dimensions.get("screen").width <= 1133
                                          ? 90
                                          : 60,
                                    }}
                                  />

                                  {/* <View
                                          style={{
                                            //height: 40,
                                            marginBottom: 15,
                                            alignSelf: 'flex-end',
                                          }}>
                                          <TouchableOpacity
                                            style={{

                                            }}
                                            onPress={() => {
                                              // onaddoption()
                                              // setBdProducts([
                                              //   ...bdProducts,
                                              //   {
                                              //     productsId: '',
                                              //     productsName: '',
                                              //     quantity: 0,
                                              //   },
                                              // ]);

                                              setBdItems([
                                                ...bdItems,
                                                {
                                                  bdItemId: '',
                                                  outletItemSku: variationItemsDropdownList[0].value,
                                                  variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
                                                  isActive: true,
                                                  quantity: 0,
                                                }
                                              ]);
                                            }}>
                                            <View style={[styles.addButtonView, { width: 90, height: 40, marginLeft: 10, }]}>
                                              <View style={{}}>
                                                <AntDesign
                                                  name="pluscircle"
                                                  size={20}
                                                  color={Colors.primaryColor}

                                                />
                                              </View>
                                              <Text
                                                style={{
                                                  marginLeft: 10,
                                                  color: Colors.primaryColor,
                                                }}>
                                                Add
                                              </Text>
                                            </View>
                                          </TouchableOpacity>
                                        </View> */}

                                  {/* <View 
                          style={{
                            flexDirection: 'row',
                            //flex: 1,
                            marginTop: 50,
                            zIndex: -1,
                            //alignItems: 'center'
                          }}>
                          <View 
                            style={{
                              width: '23%', 
                              //justifyContent: 'flex-end',
                              justifyContent: 'center',
                          }}>
                            <Text style={styles.textSize}>Expiry Period: </Text>
                            <Text
                              style={{
                                fontSize: 13,
                                color: Colors.descriptionColor,
                              }}>
                              MINIMUM 7 DAYS
                            </Text>
                          </View>
                          <View style={{}}>
                            <View>
                              <Text
                                style={{
                                  color: Colors.descriptionColor,
                                  fontSize: 15,
                                }}>
                                Durations:
                              </Text>
                              <TouchableOpacity
                                style={{ borderColor: '#E5E5E5', borderWidth: 1, borderRadius: 5, height: 42 }}
                                onClick={() => { }}>
                                <View style={[styles.textInput10, { height: 40 }]}>
                                  <View
                                    style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                    <TextInput
                                      //style={{ width:100 }}
                                      underlineColorAndroid={Colors.fieldtBgColor}
                                      placeholder="Period"
                                      // defaultValue={expiryPeriod}
                                      onChangeText={(text) => {
                                        // setState({ expiryPeriod: text });
                                        setExpiryPeriod(text.length > 0 ? parseFloat(text) : 0);
                                      }}
                                      value={expiryPeriod.toFixed(0)}
                                      ref={myTextInput}
                                      keyboardType={'number-pad'}
                                    />
                                  </View>
                                  <View
                                    style={{
                                      flex: 1,
                                      // flexDirection: 'row',
                                      // borderLeftWidth: StyleSheet.hairlineWidth,
                                      // justifyContent: 'center',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}>
                                    <Text
                                      style={{
                                        fontSize: 14,
                                        // marginTop: 15,
                                        // marginLeft: '5%',
                                        color: Colors.descriptionColor,
                                        alignItems: 'center',
                                      }}>
                                      {days == false ? 'Days' : 'Months'}
                                    </Text>

                                    <TouchableOpacity onPress={() => {
                                      // setState({ days: !days });
                                      setDays(!days);
                                    }}>
                                      <View
                                        style={{
                                          marginLeft: '30%',
                                          marginTop: 18,
                                        }}>
                                        <SimpleLineIcons
                                          name="arrow-down"
                                          size={12}
                                        />
                                      </View>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>  */}

                                  {/* <View
                          style={{
                            flexDirection: 'row',
                            flex: 1,
                            marginTop: 50,
                            zIndex: -10,
                            alignItems: 'center',
                          }}>
                          <View style={{
                            //justifyContent: 'flex-end',
                            width: '23%',
                            //marginTop: 22,
                          }}>
                            <Text style={styles.textSize}>
                            Extension:{' '}
                            </Text>
                            // <Text
                            //   style={{
                            //     fontSize: 13,
                            //     color: Colors.descriptionColor,
                            //   }}>
                            //   MINIMUM 7 DAYS
                            // </Text> 
                          </View>
                          <View
                            style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <View>
                            <DropDownPicker 
                             open={showOptionsDropdownList}
                             value={selectedOptionsId}
                             items={optionsDropdownList}
                             setOpen={setShowOptionsDropdownList}
                             setValue={setSelectedOptionsId}
                             setItems={setOptionsDropdownList}
                            containerStyle={{ height: 40 }}
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold' }}
                            labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                            style={{ width: 180, height: 40, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10 }}
                            placeholderStyle={{ color: 'black' }}
                            // items={[{label: `RM${bdOptions.price.toFixed(2)} for ${bdOptions.duration} day${bdOptions.duration > 1 ? 's' : ''}`, value: bdOptions.optionsId}]}
                            items={[{label: `RM 41 for 31 days`, value: bdOptions.optionsId}]}

                            itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                            placeholder={"Day"}
                            onChangeItem={item => { }}
                            dropDownMaxHeight={150}
                            dropDownStyle={{ width: 180, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1 }}
                          />
                            </View>
                            <View
                              style={{
                                //justifyContent: 'flex-end',
                                alignSelf: 'flex-end',
                                alignItems: 'flex-end',
                                height: 45,
                                //marginTop: 5,
                              }}>
                            </View>
                          </View>
                        </View> */}

                                  {/* <CheckBox
                              style={{
                                padding: 10,
                              }}
                              onClick={() => {
                                // setState({
                                //   isChecked12: !isChecked12,
                                // });
                                // check3()
                              }}
                              checkBoxColor={Colors.fieldtBgColor}
                              uncheckedCheckBoxColor={Colors.tabGrey}
                              checkedCheckBoxColor={Colors.primaryColor}
                              isChecked={isChecked12}
                            />
                            <Text
                              style={{
                                fontSize: 15,
                                color:
                                  isChecked12 == true
                                    ? Colors.primaryColor
                                    : Colors.descriptionColor,
                                marginRight: '5%',
                              }}>
                              Single Outlet
                            </Text> */}

                                  {/* <View style={{ flex: 1 }}></View> */}
                                </View>
                                {/* <View style={{ flexDirection: 'row', padding: 30, paddingTop: 10 }}> */}
                              </View>
                            </ScrollView>
                            {/* <View style={{ zIndex: -1 }}>
                      <TouchableOpacity
                        disabled={loading}
                        onPress={() => {
                          // setState({
                          //   redemptionList: !redemptionList,
                          // });
                          // redemptionFunc()

                          createBeerDocket();
                        }}>
                        <View
                          style={[
                            styles.button3,
                            { marginTop: 30, justifyContent: 'center' },
                          ]}>
                          <Text style={{ color: '#ffffff', fontSize: 20 }}>
                            {loading ? 'LOADING...' : 'SAVE'}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View> */}
                            {/* <View style={{ marginVertical: 20, height: 200 }}></View> */}
                          </View>

                          {/* <View style={{ flex: 3, }}> */}
                          {/* <View style={{ flexDirection: 'row', flex: 1 }}> */}
                          {/* <View style={{ flexDirection: 'row' }}>
                          <View style={{ justifyContent: 'center' }}>
                            <Text style={styles.textSize}>Outlet: </Text>
                          </View>

                          <TextInput
                            underlineColorAndroid={Colors.fieldtBgColor}
                            style={{ fontFamily: 'NunitoSans-Regular', color: Colors.descriptionColor, paddingHorizontal: 10, fontSize: 16, }}
                            placeholder={merchantName == null ? ' Not Found ' : merchantName}
                            onChangeText={(text) => {
                              // setState({ merchantName: text });
                              setMerchantName(text);
                            }}
                            value={merchantName}
                            ref={myTextInput}
                            spellCheck={false}
                          />
                        </View> */}

                          {/* <View
                            style={{
                              //flexDirection: 'row',
                              //flex: 1,
                              marginTop: 20,
                              //alignItems: 'center',
                              //zIndex: -1,
                            }}> */}
                        </View>
                      </ScrollView>
                    </View>
                  </ScrollView>
                )}
                {/* </ScrollView> */}
              </View>
            ) : null}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
    fontFamily: "NunitoSans-Regular",
  },
  iosStyle: {
    paddingHorizontal: Platform.OS !== "ios" ? 0 : 30,
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  headerLogo1: {
    width: "100%",
    height: "100%",
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  listItem: {
    fontFamily: "NunitoSans-Regular",
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 20,
    //paddingTop: 80,
    width: Dimensions.get("screen").width * (1 - Styles.sideBarWidth),
    backgroundColor: "lightgrey",
    // backgroundColor: Colors.fieldtBgColor,
    backgroundColor: Colors.highlightColor,
    // backgroundColor: '#E5E5E5',
  },
  textInput: {
    fontFamily: "NunitoSans-Regul`ar",
    width: 300,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 0,
    flex: 1,
    flexDirection: "row",
  },
  textInputLocation: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 100,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 10,
  },
  textInput8: {
    fontFamily: "NunitoSans-Regular",
    width: 100,
    height: 40,
    // flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },
  textInput9: {
    fontFamily: "NunitoSans-Regular",
    width: 110,
    height: Platform.OS == "ios" ? 30 : 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "center",
  },
  textInput10: {
    fontFamily: "NunitoSans-Regular",
    width: 100,
    height: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    flexDirection: "row",
    // justifyContent: 'center',
  },
  textInput1: {
    fontFamily: "NunitoSans-Regular",
    width: 250,
    height: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 10,
  },
  textInput2: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginRight: 30,
  },
  textInput3: {
    fontFamily: "NunitoSans-Regular",
    width: "85%",
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    alignSelf: "center",
    paddingHorizontal: 10,
  },
  textInput4: {
    width: "85%",
    height: 70,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
  textInput5: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 50,
  },
  textInput6: {
    fontFamily: "NunitoSans-Regular",
    width: "80 %",
    padding: 16,
    backgroundColor: Colors.fieldtBgColor,
    marginRight: 30,
    borderRadius: 10,
    alignSelf: "center",
  },
  textInput7: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 80,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 7,
    marginRight: 30,
  },
  button: {
    backgroundColor: Colors.primaryColor,
    width: 150,
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 20,
  },
  button1: {
    width: "15%",
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 20,
  },
  button2: {
    backgroundColor: Colors.primaryColor,
    width: "60%",
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    marginLeft: "2%",
  },
  button3: {
    backgroundColor: Colors.primaryColor,
    //width: '30%',
    height: 35,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    //marginBottom: 30,
  },
  textSize: {
    fontSize: 14,
    fontFamily: "NunitoSans-Bold",
  },
  viewContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 0,
    width: "100%",
    marginBottom: 15,
  },
  openHourContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
    marginBottom: 15,
    width: "100%",
  },
  addButtonView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 160,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  addButtonView1: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: "center",
  },
  addNewView: {
    flexDirection: "row",
    justifyContent: "flex-start",
    //marginBottom: 65,
    //marginTop: 7,
    //width: '83%',
    alignSelf: "flex-end",
  },
  addNewView1: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 10,
    alignItems: "center",
  },
  merchantDisplayView: {
    flexDirection: "row",
    flex: 1,
    marginLeft: "17%",
  },
  shiftView: {
    flexDirection: "row",
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 50,
    width: 200,
    height: 60,
    alignItems: "center",
    marginTop: 30,
    alignSelf: "center",
  },
  shiftText: {
    marginLeft: "15%",
    color: Colors.primaryColor,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
  },
  closeView: {
    flexDirection: "row",
    borderRadius: 5,
    borderColor: Colors.primaryColor,
    borderWidth: 1,
    width: 200,
    height: 40,
    alignItems: "center",
    marginTop: 30,
    alignSelf: "center",
  },
  taxView: {
    flexDirection: "row",
    borderWidth: 2,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: "center",
    marginTop: 20,
    alignSelf: "flex-end",
  },
  sectionView: {
    flexDirection: "row",
    borderRadius: 5,
    padding: 16,
    alignItems: "center",
  },
  receiptView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 200,
    height: 40,
    alignItems: "center",
    alignSelf: "flex-end",
  },
  pinBtn: {
    backgroundColor: Colors.fieldtBgColor,
    width: 70,
    height: 70,
    marginBottom: 16,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  pinNo: {
    fontSize: 20,
    fontWeight: "bold",
  },
  confirmBox: {
    width: "30%",
    height: "30%",
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },

  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    height: Dimensions.get("screen").width * 0.4,
    width: Dimensions.get("screen").width * 0.4,
    backgroundColor: Colors.whiteColor,
    borderRadius: Dimensions.get("screen").width * 0.03,
    padding: Dimensions.get("screen").width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: Dimensions.get("screen").width * 0.04,
    top: Dimensions.get("screen").width * 0.04,

    elevation: 1000,
    zIndex: 1000,
  },
});
export default SettingScreen;
