import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Modal,
  KeyboardAvoidingView,
  Platform,
  ActivityIndicator,
  Picker,
  Switch,
  useWindowDimensions,
} from "react-native";
import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
import AntDesign from "react-native-vector-icons/AntDesign";
import Entypo from "react-native-vector-icons/Entypo";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import EvilIcons from "react-native-vector-icons/EvilIcons";
import { TextInput, FlatList, Directions } from "react-native-gesture-handler";
import API from "../constant/API";
import ApiClient from "../util/ApiClient";
import * as User from "../util/User";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import CheckBox from '@react-native-community/checkbox';
import moment from "moment";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import Styles from "../constant/Styles";
// import {isTablet} from 'react-native-device-detection';
import { OutletStore } from "../store/outletStore";
import { MerchantStore } from "../store/merchantStore";
import { UserStore } from "../store/userStore";
import { ReactComponent as Upload } from "../assets/svg/Upload.svg";
import { ReactComponent as Download } from "../assets/svg/Download.svg";
// import RNFetchBlob from 'rn-fetch-blob';
import { CSVLink } from "react-csv";
import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";
// import DocumentPicker from 'react-native-document-picker';
import {
  listenToUserChangesMerchant,
  listenToMerchantIdChangesMerchant,
  listenToCurrOutletIdChangesWaiter,
  listenToAllOutletsChangesMerchant,
  listenToCommonChangesMerchant,
  listenToSelectedOutletItemChanges,
  convertArrayToCSV,
  listenToSelectedOutletTableIdChanges,
  requestNotificationsPermission,
  autofitColumns,
  getImageFromFirebaseStorage,
  getPathForFirebaseStorageFromBlob,
  uploadFileToFirebaseStorage,
} from "../util/common";
import Feather from "react-native-vector-icons/Feather";
import DropDownPicker from "react-native-dropdown-picker";
import Ionicon from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { CommonStore } from "../store/commonStore";
import AsyncImage from "../components/asyncImage";
// import {useKeyboard} from '../hooks';
import XLSX from "xlsx";
// import {zip, unzip, unzipAssets, subscribe} from 'react-native-zip-archive';
// const RNFS = require('react-native-fs');
import "react-native-get-random-values";
// import {v4 as uuidv4} from 'uuid';
// import Switch from 'react-native-switch-pro';
import {
  USER_SORT_FIELD_TYPE,
  REPORT_SORT_FIELD_TYPE_COMPARE,
  USER_SORT_FIELD_TYPE_VALUE,
  REPORT_SORT_COMPARE_OPERATOR,
  EMAIL_REPORT_TYPE,
} from "../constant/common";
import {
  ORDER_TYPE_PARSED,
  USER_POINTS_TRANSACTION_TYPE,
} from "../constant/common";
import { ReactComponent as Coins } from "../assets/svg/Coins.svg";
import { v4 as uuidv4 } from "uuid";
import { prefix } from "../constant/env";
import { useFilePicker } from "use-file-picker";
import Promise from "bluebird";
import JsZip from "jszip";
import FileSaver from "file-saver";

////////////////////////////////////

///////////////////////////////////

///////////////////////////////////

const CrmScreen = (props) => {
  const { navigation } = props;

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const [
    openFileSelector,
    {
      plainFiles,
      filesContent,
      loading: loadingFileInput,
      clear: clearAllFiles,
    },
  ] = useFilePicker({
    accept: ".zip",
  });

  useEffect(() => {
    console.log(plainFiles, filesContent, loadingFileInput);

    // Upload the batch template zip to firebase when the file is finish loaded
    if (plainFiles.length && filesContent.length && !loadingFileInput) {
      importTemplateData();
    }
  }, [plainFiles, filesContent, loadingFileInput]);

  //////////////////////////////////// UseState Here
  // const [keyboardHeight] = useKeyboard();
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const firebaseUid = UserStore.useState((s) => s.firebaseUid);
  const userName = UserStore.useState((s) => s.name);
  const merchantId = UserStore.useState((s) => s.merchantId);
  const merchantName = MerchantStore.useState((s) => s.name);
  const merchantLogo = MerchantStore.useState((s) => s.logo);

  const [list1, setList1] = useState(true);
  const [customerList, setCustomerList] = useState([]);

  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  const [search, setSearch] = useState("");

  const [expandViewDict, setExpandViewDict] = useState({});
  const [expandOrder, setExpandOrder] = useState(false);

  const [visible, setVisible] = useState(false);

  const [controller, setController] = useState({});

  const [oriList, setOriList] = useState([]);
  const [offset, setOffset] = useState(0);

  /////////////////////////////////////////////

  const [headerSorting, setHeaderSorting] = useState([]);

  //////////////////////////////////////////////////////////////

  const [customerFirstVisitDate, setCustomerFirstVisitDate] = useState("");
  const [customerLastVisitDate, setCustomerLastVisitDate] = useState("");

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState("");

  const [expandTimeline, setExpandTimeline] = useState(false);

  const [isActiveMember, setIsActiveMember] = useState(true);

  const [usersExpandedDict, setUsersExpandedDict] = useState({});

  const [importModal, setImportModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);

  const crmUsers = OutletStore.useState((s) => s.crmUsers);

  const selectedCustomerDineInOrders = OutletStore.useState(
    (s) => s.selectedCustomerDineInOrders
  );
  const isLoading = CommonStore.useState((s) => s.isLoading);

  const currOutletTaxes = CommonStore.useState((s) => s.currOutletTaxes);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );
  const selectedCustomerOrders = OutletStore.useState(
    (s) => s.selectedCustomerOrders
  );
  const outletTablesDict = OutletStore.useState((s) => s.outletTablesDict);

  const [phTotalPayment, setPhTotalPayment] = useState(0);

  const [exportEmail, setExportEmail] = useState("");
  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);

  //////////////////////////////////////////////////////////////

  const [overviewCustomersPast30Days, setOverviewCustomersPast30Days] =
    useState(0);
  const [totalCustomersSpending, setTotalCustomersSpending] = useState(0);

  const [
    averageSpendingPer30DaysEmailDict,
    setAverageSpendingPer30DaysEmailDict,
  ] = useState({});

  const allOutletsUserOrdersDone = OutletStore.useState(
    (s) => s.allOutletsUserOrdersDone
  );
  const [pushPagingToTop, setPushPagingToTop] = useState(false);

  //////////////////////////////////////////////////////////////

  //////////////////////////////////// UseEffect here

  useEffect(() => {
    var overviewCustomersPast30DaysTemp = 0;
    var totalCustomersSpendingTemp = 0;

    var averageSpendingPer30DaysEmailDictTemp = {};

    if (currOutletId && currOutletId.length > 0) {
      var currOutletUserOrdersDone = allOutletsUserOrdersDone.filter(
        (order) => order.outletId === currOutletId
      );

      for (var i = 0; i < crmUsers.length; i++) {
        averageSpendingPer30DaysEmailDictTemp[crmUsers[i].email] = 0;

        for (var j = 0; j < currOutletUserOrdersDone.length; j++) {
          const finalPriceRoundedUp =
            Math.ceil(currOutletUserOrdersDone[j].finalPrice * 20 - 0.05) / 20;

          if (
            currOutletUserOrdersDone[j].crmUserId &&
            currOutletUserOrdersDone[j].crmUserId === crmUsers[i].uniqueId
          ) {
            totalCustomersSpendingTemp += finalPriceRoundedUp;

            if (
              moment(currOutletUserOrdersDone[j].completedDate).diff(
                moment(),
                "day"
              ) <= 30
            ) {
              averageSpendingPer30DaysEmailDictTemp[crmUsers[i].email] +=
                finalPriceRoundedUp;
            }

            continue;
          } else if (
            currOutletUserOrdersDone[j].userId === crmUsers[i].userId
          ) {
            totalCustomersSpendingTemp += finalPriceRoundedUp;

            if (
              moment(currOutletUserOrdersDone[j].completedDate).diff(
                moment(),
                "day"
              ) <= 30
            ) {
              averageSpendingPer30DaysEmailDictTemp[crmUsers[i].email] +=
                finalPriceRoundedUp;
            }

            continue;
          } else if (
            currOutletUserOrdersDone[j].userPhone === crmUsers[i].number
          ) {
            // check phone
            totalCustomersSpendingTemp += finalPriceRoundedUp;

            if (
              moment(currOutletUserOrdersDone[j].completedDate).diff(
                moment(),
                "day"
              ) <= 30
            ) {
              averageSpendingPer30DaysEmailDictTemp[crmUsers[i].email] +=
                finalPriceRoundedUp;
            }

            continue;
          }
        }
      }
    }

    overviewCustomersPast30DaysTemp = Object.entries(
      averageSpendingPer30DaysEmailDictTemp
    )
      .map(([key, value]) => value)
      .filter((spent) => spent > 0).length;

    setAverageSpendingPer30DaysEmailDict(averageSpendingPer30DaysEmailDictTemp);

    setTotalCustomersSpending(totalCustomersSpendingTemp);
  }, [crmUsers, allOutletsUserOrdersDone, currOutletId]);

  useEffect(() => {
    {
      /* console.log('outletCategoriesDict');
      console.log(outletCategoriesDict);

      console.log('report outlet items'); */

      var customerListDict = {};

      //const customerListTemp = Object.entries(productSalesDict).map(([key, value]) => ({ ...value }));

      //setCustomerList(customerListTemp);
      setHeaderSorting(crmUsers);
    }
  }, [crmUsers]);

  useEffect(() => {
    setCurrentPage(1);
    setPageCount(Math.ceil(crmUsers.length / perPage));
  }, [crmUsers.length]);

  useEffect(() => {
    var phTotalPaymentTemp = 0;

    for (var i = 0; i < selectedCustomerOrders.length; i++) {
      if (selectedCustomerOrders[i].completedDate) {
        phTotalPaymentTemp +=
          Math.ceil(selectedCustomerOrders[i].finalPrice * 20 - 0.05) / 20;
      }
    }

    setPhTotalPayment(phTotalPaymentTemp);
  }, [selectedCustomerOrders]);

  ////////////////////////////////////

  const convertDataToExcelFormat = () => {
    var excelData = [];

    for (var i = 0; i < crmUsers.length; i++) {
      var excelRow = {
        Name: crmUsers[i].name ? crmUsers[i].name : "N/A",
        DOB: crmUsers[i].dob
          ? moment(crmUsers[i].dob).format("DD/MM/YYYY")
          : "N/A",
        Gender: crmUsers[i].gender ? crmUsers[i].gender : "N/A",
        Email: crmUsers[i].email ? crmUsers[i].email : "N/A",
        Phone: crmUsers[i].number ? crmUsers[i].number : "N/A",
        "User ID": crmUsers[i].uniqueName ? crmUsers[i].uniqueName : "N/A",
      };

      excelData.push(excelRow);
    }

    return excelData;
  };

  const downloadPdf = () => {};

  // const downloadExcel = () => {
  //   const excelData = convertDataToExcelFormat();

  //   var excelFile = `${
  //     Platform.OS === 'ios'
  //       ? RNFS.DocumentDirectoryPath
  //       : RNFS.DownloadDirectoryPath
  //   }/koodoo-report-Product-Sales${moment().format(
  //     'YYYY-MM-DD-HH-mm-ss',
  //   )}.xlsx`;
  //   var excelWorkSheet = XLSX.utils.json_to_sheet(excelData);
  //   var excelWorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(
  //     excelWorkBook,
  //     excelWorkSheet,
  //     'Product Sales Report',
  //   );

  //   const workBookData = XLSX.write(excelWorkBook, {
  //     type: 'binary',
  //     bookType: 'xlsx',
  //   });

  //   RNFS.writeFile(excelFile, workBookData, 'ascii')
  //     .then((success) => {
  //       console.log(`wrote file ${excelFile}`);

  //       Alert.alert(
  //         'Success',
  //         `Sent to ${excelFile}`,
  //         [{text: 'OK', onPress: () => {}}],
  //         {cancelable: false},
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  //////////////////////////////////// Page standardize pattern here

  const setState = () => {};

  const [sort, setSort] = useState("");

  //Start Here Sorting

  const sortCRMUsers = (dataList, userSortFieldType) => {
    var dataListTemp = [...dataList];

    const userSortFieldTypeValue =
      USER_SORT_FIELD_TYPE_VALUE[userSortFieldType];

    const userSortFieldTypeCompare =
      REPORT_SORT_FIELD_TYPE_COMPARE[userSortFieldType];

    //NAME
    if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.NAME_ASC) {
        dataListTemp.sort((a, b) =>
          (a[userSortFieldTypeValue]
            ? a[userSortFieldTypeValue]
            : ""
          ).localeCompare(
            b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "") -
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "")
        );
      }
    } else if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.NAME_DESC) {
        dataListTemp.sort((a, b) =>
          (b[userSortFieldTypeValue]
            ? b[userSortFieldTypeValue]
            : ""
          ).localeCompare(
            a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "") -
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "")
        );
      }
    }

    //NUMBER
    if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.NUMBER_ASC) {
        dataListTemp.sort((a, b) =>
          (a[userSortFieldTypeValue]
            ? a[userSortFieldTypeValue]
            : ""
          ).localeCompare(
            b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "") -
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "")
        );
      }
    } else if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.NUMBER_DESC) {
        dataListTemp.sort((a, b) =>
          (b[userSortFieldTypeValue]
            ? b[userSortFieldTypeValue]
            : ""
          ).localeCompare(
            a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "") -
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "")
        );
      }
    }

    //GENDER
    if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.GENDER_ASC) {
        dataListTemp.sort((a, b) =>
          (a[userSortFieldTypeValue]
            ? a[userSortFieldTypeValue]
            : ""
          ).localeCompare(
            b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "") -
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "")
        );
      }
    } else if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.GENDER_DESC) {
        dataListTemp.sort((a, b) =>
          (b[userSortFieldTypeValue]
            ? b[userSortFieldTypeValue]
            : ""
          ).localeCompare(
            a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "") -
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "")
        );
      }
    }

    //DOB
    if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.DOB_ASC) {
        dataListTemp.sort(
          (a, b) =>
            (moment(a[userSortFieldTypeValue]).valueOf()
              ? moment(a[userSortFieldTypeValue]).valueOf()
              : "") -
            (moment(b[userSortFieldTypeValue]).valueOf()
              ? moment(b[userSortFieldTypeValue]).valueOf()
              : "")
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "") -
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "")
        );
      }
    } else if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.DOB_DESC) {
        dataListTemp.sort(
          (a, b) =>
            (moment(b[userSortFieldTypeValue]).valueOf()
              ? moment(b[userSortFieldTypeValue]).valueOf()
              : "") -
            (moment(a[userSortFieldTypeValue]).valueOf()
              ? moment(a[userSortFieldTypeValue]).valueOf()
              : "")
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "") -
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "")
        );
      }
    }

    //RACE
    if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.RACE_ASC) {
        dataListTemp.sort((a, b) =>
          (a[userSortFieldTypeValue]
            ? a[userSortFieldTypeValue]
            : ""
          ).localeCompare(
            b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "") -
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "")
        );
      }
    } else if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.RACE_DESC) {
        // dataListTemp.sort((a, b) => (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : '') - (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : ''));
        dataListTemp.sort((a, b) =>
          (b[userSortFieldTypeValue]
            ? b[userSortFieldTypeValue]
            : ""
          ).localeCompare(
            a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "") -
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "")
        );
      }
    }

    //STATUS
    if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.STATUS_ASC) {
        dataListTemp.sort((a, b) =>
          (a[userSortFieldTypeValue]
            ? a[userSortFieldTypeValue]
            : ""
          ).localeCompare(
            b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "") -
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "")
        );
      }
    } else if (userSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC) {
      if (userSortFieldType === USER_SORT_FIELD_TYPE.STATUS_DESC) {
        dataListTemp.sort((a, b) =>
          (b[userSortFieldTypeValue]
            ? b[userSortFieldTypeValue]
            : ""
          ).localeCompare(
            a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : ""
          )
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[userSortFieldTypeValue] ? b[userSortFieldTypeValue] : "") -
            (a[userSortFieldTypeValue] ? a[userSortFieldTypeValue] : "")
        );
      }
    }

    return dataListTemp;
  };

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View
        style={{
          width: windowWidth * 0.17,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            bottom: switchMerchant ? "2%" : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? { right: windowWidth * 0.1 }
            : {},
          windowWidth <= 768 ? { right: windowWidth * 0.12 } : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          CRM
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  const convertTemplateToExcelFormat = () => {
    var excelTemplate = [];

    var allOutletsStr = allOutlets
      .map(
        (item) =>
          `${item.name}:${Math.floor(Math.random() * (100 - 0 + 1)) + 0}`
      )
      .join(";");

    var taxName = currOutletTaxes[0].name;

    var excelColumn = {
      Name: "Kylie Campbell",
      DOB: "1994-04-13",
      Email: "kylie.campbell@gmail.com",
      Gender: "Female",
      Phone: "6018-2988415",
      "User ID": "kylie.campbell",
      Address: "41 Jln Usj 4/9G Taman Seafield Jaya 47600 Petaling Jaya",
      "Outlets Points": allOutletsStr,
      "Image Label (png, jpg, jpeg)": "Kylie_Campbell",
    };
    excelTemplate.push(excelColumn);

    var excelColumn2 = {
      Name: "Roy Cruz",
      DOB: "1989-08-21",
      Email: "roy.cruz@gmail.com",
      Gender: "Male",
      Phone: "6012-7138233",
      "User ID": "roy.cruz",
      Address: "35 Menara Promet Jln Sultan Ismail 50250 Kuala Lumpur",
      "Outlets Points": allOutletsStr,
      "Image Label (png, jpg, jpeg)": "Roy_Cruz",
    };
    excelTemplate.push(excelColumn2);

    console.log("excelTemplate");
    console.log(excelTemplate);

    return excelTemplate;
  };

  const download = async (url) => {
    return fetch(url).then((resp) => resp.blob());
  };

  const downloadByGroup = (urls, files_per_group = 5) => {
    return Promise.map(
      urls,
      async (url) => {
        return await download(url);
      },
      { concurrency: files_per_group }
    );
  };

  const downloadAndZip = async (urls, extensions, extra) => {
    return downloadByGroup(urls, 5).then((blobs) =>
      exportZip(blobs, extensions, extra)
    );
  };

  const exportZip = (blobs, extensions, extra) => {
    const zip = JsZip();

    blobs.forEach((blob, i) => {
      // hard coded file name
      zip.folder(`folder-${i}`).file(`file-${i}.${extensions[i]}`, blob);
    });

    // download extra blob
    if (extra) zip.file(`testxx.xlsx`, extra);

    zip.generateAsync({ type: "blob" }).then((zipFile) => {
      const currentDate = new Date().getTime();
      const fileName = `export-template-${currentDate}.zip`;
      return FileSaver.saveAs(zipFile, fileName);
    });
  };

  // convert base64 string to array buffer
  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const exportTemplate = async () => {
    try {
      const excelTemplate = convertTemplateToExcelFormat();

      var templateImageUrl = "";

      // download merchant logo as example image file

      await new Promise((resolve, reject) => {
        if (
          merchantLogo.startsWith("http") ||
          merchantLogo.startsWith("file")
        ) {
          templateImageUrl = merchantLogo;

          resolve();
        } else {
          getImageFromFirebaseStorage(merchantLogo, (parsedUrl) => {
            templateImageUrl = parsedUrl;

            resolve();
          });
        }
      });

      var tempImageFileName = "image." + templateImageUrl.split(".").pop();
      tempImageFileName = tempImageFileName.split("?")[0];

      var excelWorkSheet = XLSX.utils.json_to_sheet(excelTemplate);
      var excelWorkBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(
        excelWorkBook,
        excelWorkSheet,
        "CRM User Template"
      );

      const workBookData = XLSX.write(excelWorkBook, {
        type: "base64",
        bookType: "xlsx",
      });

      // create a blob and url
      var excelBlob = new Blob([s2ab(atob(workBookData))], {
        type: "",
      });
      var excelUrl = URL.createObjectURL(excelBlob);

      // hard coded extensions for files downloaded from urls
      var extensions = ["jpg", "jpg", "xlsx"];
      var urls = [templateImageUrl, templateImageUrl, excelUrl];
      await downloadAndZip(urls, extensions, excelBlob);
    } catch (ex) {
      console.error(ex);

      alert("Error: Failed to export template");
    }
  };

  const importTemplateData = async () => {
    CommonStore.update((s) => {
      s.isLoading = true;
    });

    try {
      var referenceId = uuidv4();
      var referencePath = `/merchant/${merchantId}}/batchUploads/${referenceId}.zip`;

      await uploadFileToFirebaseStorage(plainFiles[0], referencePath);

      clearAllFiles();

      const body = {
        zipId: referenceId,
        zipPath: referencePath,

        userId: firebaseUid,
        merchantId: merchantId,
        merchantName: merchantName,
        outletId: currOutletId,
      };

      ApiClient.POST(API.batchCreateCRMUsers, body)
        .then((result) => {
          if (result && result.status === "success") {
            Alert.alert(
              "Success",
              "Submitted to upload queue, we will notify you once processing is done"
            );
          } else {
            Alert.alert("Error", "Failed to import data");
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        })
        .catch((err) => {
          console.log(err);

          Alert.alert("Error", "Failed to import data");

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        });
    } catch (err) {
      CommonStore.update((s) => {
        s.isLoading = false;
      });

      console.error(err);

      Alert.alert("Error", "Failed to import data");
    }
  };

  const actionUsersExpandedDict = (item) => {
    // for now set to one open only

    if (usersExpandedDict[item.email]) {
      // setUsersExpandedDict({
      //   ...usersExpandedDict,
      //   [item.email]: false,
      // });
      setUsersExpandedDict({
        [item.email]: false,
      });
    } else {
      // setUsersExpandedDict({
      //   ...usersExpandedDict,
      //   [item.email]: true,
      // });
      setUsersExpandedDict({
        [item.email]: true,
      });
    }
  };

  // const expandOrderFunc = (param) => {
  //   if (expandOrder == false) {
  //     setExpandOrder(true);
  //     setExpandViewDict({
  //       ...expandViewDict,
  //       //[param.uniqueId]: true,
  //     });
  //     expandViewDict;
  //   } else {
  //     setExpandOrder(false);
  //     setExpandViewDict({
  //       ...expandViewDict,
  //       //[param.uniqueId]: undefined,
  //     });
  //   }
  // };
  // const next = (e) => {
  //   const offset = e * perPage;
  //   setState({ offset: offset })
  //   loadMoreData()
  // }

  // const less = async () => {
  //   if (page > 0) {
  //     await setState({ page: page - 1, currentPage: currentPage - 1 })
  //     console.log(page)
  //     var y = page
  //     pre(y)
  //   }
  // }

  const nextPage = () => {
    setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
  };

  //   const loadMoreData = () => {
  //     const data = oriList;
  //     const slice = data.slice(offset, offset + perPage)
  //     setState({ list: slice, pageCount: Math.ceil(data.length / perPage) })
  // }

  // const add = async () => {
  //   if (page + 1 < pageCount) {
  //     await setState({page: page + 1, currentPage: currentPage + 1});
  //     console.log(page);
  //     var e = page;
  //     next(e);
  //   }
  // };

  const renderItem = ({ item, index }) => {
    return (
      <View
        style={{
          //backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          backgroundColor: "#FFFFFF",
          paddingVertical: 10,
          //paddingHorizontal: 3,
          //paddingLeft: 1,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 === 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 === 0 ? 0 : 0.5,
          // width: '100%',
        }}
      >
        <TouchableOpacity
          onPress={() => {
            CommonStore.update(
              (s) => {
                s.selectedCustomerEdit = item;

                s.timestamp = Date.now();
              },
              () => {
                navigation.navigate("NewCustomer");

                CommonStore.update((s) => {
                  s.selectedCustomerEdit = item;
                });
              }
            );

            // CommonStore.update(s => {
            //   s.selectedCustomerEdit = item;
            // });

            // navigation.navigate('NewCustomer');
          }}
        >
          <View
            style={{
              flexDirection: "row",
              marginTop: 10,
              marginBottom: 10,
              alignItems: "center",
              // borderWidth: 1,
              borderBottomWidth:
                expandViewDict === true ? StyleSheet.hairlineWidth : null,
            }}
          >
            <View style={{ flex: 2, flexDirection: "row" }}>
              <View
                style={{
                  flex: 0.5,
                }}
              >
                {item.avatar ? (
                  <AsyncImage
                    style={{
                      width: windowWidth * 0.02,
                      height: windowHeight * 0.04,
                      alignSelf: "flex-start",
                      borderRadius: 100,
                      marginLeft: 5,
                    }}
                    source={{
                      uri: item.avatar,
                    }}
                    item={item}
                    hideLoading={true}
                  />
                ) : (
                  <Image
                    style={{
                      width: windowWidth * 0.02,
                      height: windowHeight * 0.04,
                      alignSelf: "flex-start",
                      borderRadius: 100,
                      marginLeft: 5,
                    }}
                    source={require("../assets/image/profile-pic.jpg")}
                    hideLoading={true}
                  />
                )}
              </View>
              <View style={{ flex: 1.5, flexDirection: "column" }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "NunitoSans-Bold",
                    textAlign: "left",
                    marginBottom: 5,
                  }}
                >
                  {item.name}
                </Text>
                <Text
                  style={{
                    fontSize: 18,
                    fontFamily: "NunitoSans-Regular",
                    textAlign: "left",
                    color: Colors.fieldtTxtColor,
                  }}
                >
                  {item.uniqueName}
                </Text>
              </View>
            </View>

            <View style={{ flex: 1.5 }}>
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "NunitoSans-Regular",
                    fontWeight: "500",
                    textAlign: "left",
                    marginBottom: 5,
                  }}
                >
                  {item.number}
                </Text>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "NunitoSans-Regular",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  {item.email}
                </Text>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "NunitoSans-Regular",
                    fontWeight: "500",
                    marginLeft: 39,
                  }}
                >
                  {item.gender === "Male" ? (
                    <Ionicon name="male" color={"blue"} size={22} />
                  ) : item.gender === "Female" ? (
                    <Ionicon name="female" color={"red"} size={22} />
                  ) : (
                    "-"
                  )}
                </Text>
                {/* <Text style={{ flex: 1, fontSize: 13, fontWeight: '500', textAlign: 'center' }}></Text> */}
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "NunitoSans-Regular",
                    fontWeight: "500",
                    marginLeft: 12,
                  }}
                >
                  {item.dob ? moment(item.dob).format("DD/MM/YYYY") : "      -"}
                </Text>
              </View>
            </View>

            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "column" }}>
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "NunitoSans-Regular",
                    fontWeight: "500",

                    marginLeft: 35,
                  }}
                >
                  {item.race ? item.race : "N/A"}
                </Text>
              </View>
            </View>

            <View style={{ flex: 1,flexDirection: "row",justifyContent:'space-between',alignItems: 'center'}}>
              <View style={{ flexDirection: "column" }}>
                {moment().diff(moment(item.updatedAt), "day") <= 7 ? (
                  <Switch
                    style={{
                      transform: [{ scaleX: 0.9 }, { scaleY: 0.85 }],
                      marginLeft: 50,
                    }}
                    // onSyncPress={(value) => {
                    //   setIsActiveMember(item, value)
                    // }}
                    value={true}
                    width={42}
                    circleColorActive={Colors.primaryColor}
                    circleColorInactive={Colors.fieldtTxtColor}
                    backgroundActive={"#dddddd"}
                  />
                ) : (
                  <Switch
                    style={{
                      transform: [{ scaleX: 0.9 }, { scaleY: 0.85 }],
                      marginLeft: 50,
                    }}
                    // onSyncPress={(value) => {
                    //   // if(moment().diff(moment(item.updatedAt), 'day') <= 7){
                    //   //   setIsActiveMember(false);
                    //   // }
                    //   // else{
                    //   //   setIsActiveMember(true);
                    //   // }
                    //   setIsActiveMember(item, value)
                    // }}
                    value={false}
                    width={42}
                    circleColorActive={Colors.primaryColor}
                    circleColorInactive={Colors.fieldtTxtColor}
                    backgroundActive={"#dddddd"}
                  />
                )}
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: "NunitoSans-Regular",
                    fontWeight: "500",
                    marginLeft: 50,
                  }}
                >
                  {averageSpendingPer30DaysEmailDict[item.email]
                    ? averageSpendingPer30DaysEmailDict[item.email]
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                    : "0.00"}
                </Text>
              </View>

              <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  // setExpandTimeline(!expandTimeline)

                  CommonStore.update((s) => {
                    s.selectedCustomerEdit = item;
                  });

                  actionUsersExpandedDict(item);
                }}
              >
                <Icon
                  name={
                    usersExpandedDict[item.email]
                      ? "chevron-up"
                      : "chevron-down"
                  }
                  size={switchMerchant ? 20 : 30}
                  color={Colors.tabGrey}
                />
              </TouchableOpacity>
            </View>
            </View>

            {/* <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                onPress={() => {
                  // setExpandTimeline(!expandTimeline)

                  CommonStore.update((s) => {
                    s.selectedCustomerEdit = item;
                  });

                  actionUsersExpandedDict(item);
                }}
              >
                <Icon
                  name={
                    usersExpandedDict[item.email]
                      ? "chevron-up"
                      : "chevron-down"
                  }
                  size={switchMerchant ? 20 : 30}
                  color={Colors.tabGrey}
                />
              </TouchableOpacity>
            </View> */}
          </View>
        </TouchableOpacity>

        {usersExpandedDict[item.email] && (
          <>
            {isLoading ? (
              <View
                style={{
                  width: "100%",
                  height: 100,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ActivityIndicator
                  style={{}}
                  color={Colors.primaryColor}
                  size={"large"}
                />
              </View>
            ) : (
              <>
                {selectedCustomerDineInOrders.length > 0 ? (
                  // <View style={{ borderTopWidth: 1, borderColor: '#E5E5E5', }}>
                  <ScrollView
                  // showsVerticalScrollIndicator={false}
                  // style={{ height: 350, borderWidth: 1, padding: 10, margin: 10, marginHorizontal: 10, borderRadius: 5, borderColor: '#E5E5E5' }}
                  >
                    {/* <FlatList
                      showsVerticalScrollIndicator={false}
                      data={selectedCustomerDineInOrders}
                      renderItem={renderTimelineItem}
                      keyExtractor={(item, index) => String(index)}
                      style={{marginTop: 10, paddingTop: 10}}
                      //initialNumToRender={4}
                    /> */}
                  </ScrollView>
                ) : (
                  // </View>
                  <View
                    style={{
                      width: "100%",
                      height: 100,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        top: -20,
                        fontFamily: "NunitoSans-Regular",
                        fontSize: switchMerchant ? 10 : 16,
                      }}
                    >
                      No timeline for now.
                    </Text>
                  </View>
                )}
              </>
            )}
          </>
        )}
      </View>
    );
  };

  // const renderTimelineItem = ({item, index}) => {
  //   var detailsStr = 'N/A';
  //   var detailsList = [];

  //   const currOrder = selectedCustomerOrders.find(
  //     (order) => order.uniqueId === item.orderId,
  //   );

  //   if (item.transactionType === USER_POINTS_TRANSACTION_TYPE.EARN) {
  //     if (currOrder && currOrder.cartItems) {
  //       for (var i = 0; i < currOrder.cartItems.length; i++) {
  //         detailsList.push(currOrder.cartItems[i].name);
  //       }
  //     }
  //   } else if (item.transactionType === USER_POINTS_TRANSACTION_TYPE.REDEEM) {
  //     if (currOrder && currOrder.pointsToRedeemPackageIdList) {
  //       for (var i = 0; i < currOrder.pointsToRedeemPackageIdList.length; i++) {
  //         const currPackage =
  //           pointsRedeemPackagesDict[currOrder.pointsToRedeemPackageIdList[i]];

  //         if (currPackage) {
  //           detailsList.push(currPackage.packageName);
  //         }
  //       }
  //     }
  //   }

  //   if (detailsList.length > 0) {
  //     detailsStr = detailsList.join(', ');
  //   }

  //   return (
  //     <ScrollView showsVerticalScrollIndicator={false}>
  //       <View
  //         style={{
  //           width: '100%',
  //           alignItems: 'center',
  //           justifyContent: 'center',
  //         }}>
  //         <View
  //           style={{
  //             flexDirection: 'row',
  //             width: '66%',
  //             marginTop: 0,
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //           }}>
  //           <View style={{flexDirection: 'column'}}>
  //             {/* <Ionicon name='ellipse' size={26} color='#4E9F7D' style={{ alignSelf: 'center', marginTop: -5 }} /> */}
  //             <View
  //               style={{
  //                 alignSelf: 'center',
  //                 borderWidth: 0,
  //                 borderColor: '#E5F1EC',
  //                 // height: switchMerchant ? 140 : 160,
  //                 width: 1,
  //                 marginTop: -10,
  //                 zIndex: -1,
  //               }}
  //             />
  //           </View>
  //           <View
  //             style={{
  //               borderWidth: 1,
  //               borderRadius: 6,
  //               borderColor: '#E5E5E5',
  //               // height: switchMerchant ? 120 : 160,
  //               marginLeft: 10,
  //               padding: 10,
  //               // width: switchMerchant ? 400 : 500,
  //             }}>
  //             <View style={{marginLeft: 10, marginVertical: 5}}>
  //               {/* <Text style={{ fontSize: 16, fontFamily: 'Nunitosans-Bold' }}> */}
  //               <View
  //                 style={{
  //                   flexDirection: 'row',
  //                   justifyContent: 'space-between',
  //                 }}>
  //                 <Text
  //                   style={{
  //                     fontWeight: '600',
  //                     // fontSize: switchMerchant ? 10 : 16,
  //                     color: Colors.primaryColor,
  //                   }}>
  //                   {moment(item.orderDate).format('DD MMM YYYY HH:MM A')}
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     fontWeight: '600',
  //                     // fontSize: switchMerchant ? 10 : 16,
  //                     color: Colors.primaryColor,
  //                   }}>
  //                   Table Code:{' '}
  //                   {item.tableCode
  //                     ? item.tableCode
  //                     : outletTablesDict[item.tableId]
  //                     ? outletTablesDict[item.tableId].code
  //                     : 'N/A'}
  //                 </Text>
  //               </View>
  //             </View>

  //             <View style={{flexDirection: 'column', marginLeft: 10}}>
  //               <View
  //                 style={{flexDirection: 'row', width: '100%', marginTop: 5}}>
  //                 <Text
  //                   style={{}
  //                     // switchMerchant
  //                     //   ? {fontSize: 10, fontWeight: '600', width: '20%'}
  //                     //   : {fontWeight: '600', width: '20%'}
  //                   }>
  //                   Order ID:{' '}
  //                 </Text>
  //                 <TouchableOpacity
  //                   style={{
  //                     width: '80%',
  //                   }}
  //                   onPress={() => {
  //                     navigation.navigate('History');
  //                   }}>
  //                   <Text
  //                     style={{
  //                       // fontSize: switchMerchant ? 10 : 16,
  //                       fontFamily: 'NunitoSans-Regular',
  //                       fontWeight: '700',
  //                     }}>
  //                     #{item.orderId}
  //                   </Text>
  //                 </TouchableOpacity>
  //               </View>

  //               <View
  //                 style={{flexDirection: 'row', width: '100%', marginTop: 5}}>
  //                 <Text
  //                   style={{
  //                     // fontSize: switchMerchant ? 10 : 16,
  //                     fontFamily: 'NunitoSans-Regular',
  //                     fontWeight: '500',
  //                     width: '20%',
  //                   }}>
  //                   {/* Remarks:: */}
  //                   Item Ordered:
  //                 </Text>
  //                 {item.cartItems && item.cartItems.length > 0 ? (
  //                   <View style={{flexDirection: 'row', width: '100%'}}>
  //                     <Text
  //                       style={{
  //                         // fontSize: switchMerchant ? 10 : 16,
  //                         fontFamily: 'NunitoSans-Regular',
  //                         fontWeight: '700',
  //                         width: '80%',
  //                       }}
  //                       numberOfLines={3}>
  //                       {item.cartItems
  //                         .map((cartItem) => cartItem.itemName)
  //                         .join(', ')}
  //                     </Text>
  //                   </View>
  //                 ) : (
  //                   <></>
  //                 )}
  //               </View>

  //               {item.cartItemsCancelled &&
  //               item.cartItemsCancelled.length > 0 ? (
  //                 <View
  //                   style={{flexDirection: 'row', width: '100%', marginTop: 5}}>
  //                   <Text
  //                     style={{
  //                       // fontSize: switchMerchant ? 10 : 16,
  //                       fontFamily: 'NunitoSans-Regular',
  //                       fontWeight: '500',
  //                       width: '20%',
  //                     }}>
  //                     {/* Remarks: */}
  //                     Item Cancelled:
  //                   </Text>
  //                   <View style={{flexDirection: 'row', width: '100%'}}>
  //                     <Text
  //                       style={{
  //                         // fontSize: switchMerchant ? 10 : 16,
  //                         fontFamily: 'NunitoSans-Regular',
  //                         fontWeight: '700',
  //                         width: '80%',
  //                       }}>
  //                       {item.cartItemsCancelled
  //                         .map((cartItem) => cartItem.itemName)
  //                         .join(', ')}
  //                     </Text>
  //                   </View>
  //                 </View>
  //               ) : (
  //                 <></>
  //               )}

  //               <View
  //                 style={{flexDirection: 'row', width: '100%', marginTop: 5}}>
  //                 <Text
  //                   style={{
  //                     // fontSize: switchMerchant ? 10 : 16,
  //                     fontFamily: 'NunitoSans-Regular',
  //                     fontWeight: '500',
  //                     width: '20%',
  //                   }}>
  //                   Total Price:
  //                 </Text>
  //                 <Text
  //                   style={{
  //                     // fontSize: switchMerchant ? 10 : 16,
  //                     fontFamily: 'NunitoSans-Regular',
  //                     fontWeight: '700',
  //                     width: '80%',
  //                   }}>
  //                   {/* {item.remarks ? item.remarks : 'N/A'} */}
  //                   RM{' '}
  //                   {item.finalPrice
  //                     .toFixed(2)
  //                     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
  //                 </Text>
  //               </View>
  //             </View>
  //           </View>
  //         </View>
  //       </View>
  //     </ScrollView>
  //   );
  // };

  /////Manage Filter

  ///////////////////////////////////////////////////////

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={6} expandCRM={true} />
      </View>

      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{}}
          contentContainerStyle={{
            paddingBottom: windowHeight * 0.1,
          }}
        >
          <Modal
            style={{ flex: 1 }}
            visible={visible}
            transparent={true}
            animationType="slide"
          >
            <KeyboardAvoidingView
              behavior="padding"
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                minHeight: Dimensions.get("window").height,
              }}
            >
              <View
                style={[
                  styles.ManageFilterBox,
                  { borderRadius: 12, padding: 30, paddingHorizontal: 50 },
                ]}
              >
                <View
                  style={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    marginTop: -15,
                    marginRight: -35,
                  }}
                >
                  <TouchableOpacity
                    onPress={() => {
                      setVisible(false);
                    }}
                  >
                    <AntDesign
                      name={"closecircle"}
                      size={25}
                      color={"#858C94"}
                    />
                  </TouchableOpacity>
                </View>
                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    //marginTop: 10
                  }}
                >
                  <Text style={{ fontSize: 26, fontFamily: "NunitoSans-Bold" }}>
                    Manage Filter
                  </Text>
                </View>
                <View
                  style={{
                    borderColor: "#E5E5E5",
                    borderWidth: 1,
                    marginHorizontal: -20,
                    marginBottom: 15,
                  }}
                />

                <View
                  style={{
                    flexDirection: "row",
                    //justifyContent: 'center',
                    alignItems: "center",
                    //paddingLeft: 30,
                    //borderRadius: 10,
                    height: 35,
                    marginVertical: 10,
                  }}
                >
                  <Picker
                    // controller={instance => controller = instance}
                    controller={(instance) => setController(instance)}
                    arrowColor={"#BDBDBD"}
                    arrowSize={23}
                    arrowStyle={{ paddingVertical: 0 }}
                    style={{
                      width: 180,
                      height: 35,
                      backgroundColor: "#F2F3F7",
                      borderRadius: 6,
                      paddingVertical: 0,
                    }}
                    itemStyle={{ justifyContent: "flex-start" }}
                    placeholderStyle={{ color: "#B6B6B6" }}
                    //change value below (Eason)
                    placeholder={"Product Category"}
                    labelStyle={{ fontSize: 12.5 }}
                    onChangeItem={(selectedSort) => {
                      // setState({ sort: selectedSort }),
                      //sortingOrders(selectedSort);
                    }}
                  >
                    {[
                      { label: "A", value: 1 },
                      { label: "B", value: 2 },
                    ].map((value, index) => {
                      return (
                        <Picker.Item label={value.label} value={value.value} />
                      );
                    })}
                  </Picker>
                  <Picker
                    // controller={instance => controller = instance}
                    controller={(instance) => setController(instance)}
                    arrowColor={"#BDBDBD"}
                    arrowSize={23}
                    arrowStyle={{ paddingVertical: 0 }}
                    style={{
                      width: 90,
                      backgroundColor: "#F2F3F7",
                      borderRadius: 6,
                      marginLeft: 20,
                      paddingVertical: 0,
                    }}
                    dropDownStyle={{
                      width: 90,
                      borderRadius: 6,
                      marginLeft: 20,
                      paddingVertical: 0,
                    }}
                    itemStyle={{ justifyContent: "flex-start" }}
                    placeholderStyle={{ color: "#B6B6B6" }}
                    //change value below (Eason)
                    placeholder={"Is"}
                    labelStyle={{ fontSize: 12.5 }}
                    onChangeItem={(selectedSort) => {
                      // setState({ sort: selectedSort }),
                      //sortingOrders(selectedSort);
                    }}
                    //onOpen={() => controller1.close()}
                  >
                    {[
                      { label: "A", value: 1 },
                      { label: "B", value: 2 },
                    ].map((value, index) => {
                      return (
                        <Picker.Item label={value.label} value={value.value} />
                      );
                    })}
                  </Picker>
                </View>
                <View
                  style={{
                    //marginRight: '33%',
                    //flexDirection: 'row',
                    //justifyContent: 'center',
                    //alignItems: 'center',
                    //paddingLeft: 30,
                    //borderRadius: 10,
                    marginTop: 20,
                    height: 40,
                    zIndex: -1,
                  }}
                >
                  <TextInput
                    style={{
                      borderRadius: 5,
                      borderColor: "#E5E5E5",
                      borderWidth: 1,
                      height: 35,
                      width: 200,
                      backgroundColor: Colors.fieldtBgColor,
                      paddingLeft: 5,
                    }}
                    placeholder="Enter Condition"
                    placeholderStyle={{ paddingLeft: 5 }}
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    onChangeText={() => {}}
                  />
                </View>
                {/* <View style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderWidth: 1,
                borderColor: '#E0E0E0',
                backgroundColor: '#F2F3F7',
                width: 100,
                height: 40,
                borderRadius: 6,
                marginTop: 15,
              }}>
                <Text style={{
                  fontSize: 12.5,
                  Color: '#B6B6B6',
                }}>
                  Package A
                </Text>
                <TouchableOpacity
                  onPress={() => {

                  }}
                  style={{
                    marginLeft: 5
                  }}
                >
                  <AntDesign name={"close"} size={16} color={'#B6B6B6'} />
                </TouchableOpacity>
              </View> */}

                <View
                  style={{
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                    marginVertical: 20,
                    marginHorizontal: -20,
                    zIndex: -1,
                  }}
                />

                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    zIndex: -1,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      width: 110,
                      height: 40,
                      borderColor: "#4E9F7D",
                      borderRadius: 8,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      setVisible(false);
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.primaryColor,
                        fontSize: 17,
                        color: "#4E9F7D",
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      Cancel
                    </Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      width: 110,
                      height: 40,
                      borderColor: "#4E9F7D",
                      borderRadius: 8,
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#4E9F7D",
                      marginLeft: 10,
                    }}
                    onPress={() => {}}
                  >
                    <Text
                      style={{
                        color: Colors.primaryColor,
                        fontSize: 17,
                        color: "#FFFFFF",
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      Apply
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>

          <Modal
            style={
              {
                // flex: 1
              }
            }
            visible={importModal}
            supportedOrientations={["portrait", "landscape"]}
            transparent={true}
            animationType={"slide"}
          >
            <View style={styles.modalContainer}>
              <View
                style={[
                  styles.modalView1,
                  {
                    minHeight: windowWidth * 0.2,
                    minWidth: windowWidth * 0.4,
                    padding: windowWidth * 0.03,
                    flexDirection: "column",
                    // borderWidth: 1,
                  },
                ]}
              >
                <View style={{ flexDirection: "row" }}>
                  <View style={{ flex: 1 }}></View>
                  <View style={styles.modalTitle}>
                    <Text style={[{ fontSize: 20 }, styles.modalTitleText]}>
                      Download Report
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <TouchableOpacity
                      style={(styles.closeButton, {})}
                      onPress={() => {
                        // setState({ changeTable: false });
                        setImportModal(false);
                      }}
                    >
                      <AntDesign
                        name="closecircle"
                        size={25}
                        color={Colors.fieldtTxtColor}
                      />
                    </TouchableOpacity>
                  </View>
                </View>

                <View
                  style={{
                    alignItems: "center",
                    //top: '15%',
                  }}
                >
                  <View style={{}}>
                    <Text
                      style={{
                        fontSize: 16,
                        fontFamily: "NunitoSans-Bold",
                        marginTop: 70,
                        textAlign: "center",
                      }}
                    >
                      Email Address:
                    </Text>
                    <TextInput
                      placeholder="Enter Your Email"
                      placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                      style={{
                        backgroundColor: Colors.fieldtBgColor,
                        width: 450,
                        height: 70,
                        borderRadius: 5,
                        padding: 5,
                        marginVertical: 5,
                        borderWidth: 1,
                        borderColor: "#E5E5E5",
                        paddingLeft: 10,
                        fontSize: 16,
                      }}
                      onChangeText={(text) => {
                        setExportEmail(text);
                      }}
                      value={exportEmail}
                    />
                    <Text
                      style={{
                        fontSize: 16,
                        fontFamily: "NunitoSans-Bold",
                        marginTop: 15,
                        textAlign: "center",
                      }}
                    >
                      Download As:
                    </Text>
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        //top: '10%',
                        flexDirection: "row",
                        marginTop: 10,
                      }}
                    >
                      <CSVLink
                        style={{
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          display: "inline-block",
                          flexDirection: "row",
                          textDecoration: "none",
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          marginLeft: 20,
                          width: 100,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                        }}
                        data={convertDataToExcelFormat()}
                        filename="report.csv"
                      >
                        <View
                          style={{
                            width: "100%",
                            height: "100%",
                            alignContent: "center",
                            alignItems: "center",
                            alignSelf: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: 16,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            CSV
                          </Text>
                        </View>
                      </CSVLink>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <KeyboardAvoidingView
            style={
              {
                // top:
                // Platform.OS === 'ios' && keyboardHeight > 0
                //   ? -keyboardHeight * 1
                //   : 0,
              }
            }
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                width: windowWidth * 0.87,
                margin: 10,
                padding: 10,
                paddingRight: 0,
                alignItems: "center",
                alignSelf: "center",
                //paddingRight: windowWidth * 0.015,
              }}
            >
              <View style={{ marginRight: 15, flexDirection: "row" }}>
                <TouchableOpacity
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    //width: 160,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                  }}
                  onPress={() => {
                    setImportModal(true);
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Icon name="download" size={20} color={Colors.whiteColor} />
                    <Text
                      style={{
                        color: Colors.whiteColor,
                        marginLeft: 5,
                        fontSize: 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      DOWNLOAD
                    </Text>
                  </View>
                </TouchableOpacity>

                <Modal
                  style={
                    {
                      // flex: 1
                    }
                  }
                  visible={exportModal}
                  supportedOrientations={["portrait", "landscape"]}
                  transparent={true}
                  animationType={"slide"}
                >
                  <View style={styles.modalContainer}>
                    <View
                      style={[
                        styles.modalView1,
                        {
                          minHeight: windowWidth * 0.18,
                          minWidth: windowWidth * 0.3,
                          padding: windowWidth * 0.03,
                        },
                      ]}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 1 }}></View>
                        <View style={styles.modalTitle}>
                          <Text
                            style={[{ fontSize: 16 }, styles.modalTitleText]}
                          >
                            Upload Options
                          </Text>
                        </View>
                        <View style={{ flex: 1 }}>
                          <TouchableOpacity
                            style={(styles.closeButton, {})}
                            onPress={() => {
                              // setState({ changeTable: false });
                              setExportModal(false);
                            }}
                          >
                            <AntDesign
                              name="closecircle"
                              size={25}
                              color={Colors.fieldtTxtColor}
                            />
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View
                        style={{
                          alignItems: "center",
                          marginTop: 80,
                          // top: switchMerchant ? '20%' : '10%',
                        }}
                      >
                        {/* <TouchableOpacity
                    style={[styles.modalSaveButton, {
                      zIndex: -1.
                    }]}
                    onPress={() => { exportFile() }}>
                    <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Export to CSV</Text>
                  </TouchableOpacity> */}

                        {/* <TouchableOpacity
                    style={[styles.modalSaveButton, {
                      zIndex: -1.
                    }]}
                    onPress={() => { exportTemplate() }}>
                    <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Download Batch Template</Text>
                  </TouchableOpacity> */}

                        <TouchableOpacity
                          style={{
                            justifyContent: "center",
                            flexDirection: "row",
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: "#4E9F7D",
                            borderRadius: 5,
                            width: 240,
                            // paddingHorizontal: 10,
                            height: 45,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                            marginBottom: 10,
                          }}
                          onPress={() => {
                            openFileSelector();
                          }}
                          disabled={isLoading}
                        >
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: 16,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {isLoading ? "LOADING..." : "UPLOAD BATCH TEMPLATE"}
                          </Text>

                          {isLoading ? (
                            <ActivityIndicator
                              style={{
                                marginLeft: 5,
                              }}
                              color={Colors.whiteColor}
                              size={"small"}
                            />
                          ) : (
                            <></>
                          )}
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={{
                            justifyContent: "center",
                            flexDirection: "row",
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: "#4E9F7D",
                            borderRadius: 5,
                            width: 240,
                            // paddingHorizontal: 10,
                            height: 45,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                          }}
                          onPress={() => {
                            exportTemplate();
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: 16,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            EXPORT TEMPLATE
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </Modal>

                <TouchableOpacity
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    //width: 160,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                    marginLeft: 15,
                  }}
                  onPress={() => {
                    setExportModal(true);
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View style={{ flex: 0.2 }}>
                      <Icon
                        name="upload"
                        size={20}
                        color={Colors.whiteColor}
                        style={{ alignSelf: "flex-end" }}
                      />
                    </View>
                    <View style={{ flex: 1.8 }}>
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          marginLeft: 5,
                          fontSize: 16,
                          fontFamily: "NunitoSans-Bold",
                          alignSelf: "flex-start",
                        }}
                      >
                        BATCH UPLOAD
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              </View>

              <TouchableOpacity
                style={{
                  justifyContent: "center",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: Colors.primaryColor,
                  backgroundColor: "#4E9F7D",
                  borderRadius: 5,
                  //width: 160,
                  paddingHorizontal: 10,
                  height: switchMerchant ? 35 : 40,
                  alignItems: "center",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 1,
                  zIndex: -1,
                  marginRight: 15,
                  // display: 'none',
                }}
                onPress={() => {
                  CommonStore.update((s) => {
                    s.selectedCustomerEdit = null;
                  });

                  navigation.navigate("NewCustomer");
                }}
              >
                <View style={{}}>
                  <AntDesign name="pluscircle" size={20} color="#FFFFFF" />
                </View>
                <Text
                  style={{
                    marginLeft: 5,
                    color: Colors.primaryColor,
                    fontSize: 16,
                    color: "#FFFFFF",
                    fontFamily: "NunitoSans-Bold",
                  }}
                >
                  {/* ADD CUSTOMER */}
                  CUSTOMER
                </Text>
              </TouchableOpacity>
              <View
                style={{
                  width: 250,
                  height: 40,
                  backgroundColor: "white",
                  borderRadius: 5,
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                  borderWidth: 1,
                  borderColor: "#E5E5E5",
                }}
              >
                <Icon
                  name="search"
                  size={18}
                  color={Colors.primaryColor}
                  style={{ marginLeft: 15 }}
                />

                <View style={{ flex: 4 }}>
                  <TextInput
                    underlineColorAndroid={Colors.whiteColor}
                    style={{
                      fontFamily: "NunitoSans-Regular",
                      paddingLeft: 5,
                      height: 45,
                      width: switchMerchant ? 180 : 220,
                      fontSize: switchMerchant ? 10 : 16,
                    }}
                    clearButtonMode="while-editing"
                    placeholder=" Search"
                    placeholderStyle={{ fontSize: 20 }}
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    onChangeText={(text) => {
                      setSearch(text);
                      // setSearch(text.trim());
                    }}
                    // value={search}
                  />
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                width: windowWidth * 0.9,
                marginBottom: 10,
                justifyContent: "center",
                // borderWidth: 1,
              }}
            >
              <View
                style={{
                  width: "40%",
                  height: 100,
                  backgroundColor: Colors.tabCyan,
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: switchMerchant ? 25 : 30,
                  paddingVertical: 15,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                  marginRight: 10,
                }}
              >
                <View
                  style={{
                    justifyContent: "center",
                    height: 60,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      color: Colors.whiteColor,
                      fontSize: 28,
                    }}
                  >
                    {overviewCustomersPast30Days}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Regular",
                      color: Colors.whiteColor,
                      fontSize: 13,
                    }}
                  >
                    Past 30 Days Customers
                  </Text>
                </View>
                <View>
                  <Ionicon
                    name="people-outline"
                    color={"#F7F7F7"}
                    size={60}
                    style={{ opacity: 0.6, paddingLeft: 2 }}
                  />
                </View>
              </View>

              <View
                style={{
                  width: "40%",
                  height: 100,
                  backgroundColor: Colors.tabGold,
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: switchMerchant ? 25 : 30,
                  paddingVertical: 15,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                  // borderWidth: 1,
                }}
              >
                <View
                  style={{
                    justifyContent: "center",
                    height: 60,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      color: Colors.whiteColor,
                      fontSize: 28,
                    }}
                  >
                    RM{" "}
                    {(!isNaN(totalCustomersSpending)
                      ? totalCustomersSpending
                      : 0
                    )
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Regular",
                      color: Colors.whiteColor,
                      fontSize: 13,
                    }}
                  >
                    Total Customers Spending
                  </Text>
                </View>
                <View>
                  <Coins
                    height={switchMerchant ? 30 : 55}
                    width={switchMerchant ? 30 : 55}
                  />
                </View>
              </View>

              {/* <View style={{
              backgroundColor: Colors.tabCyan, padding: 20, paddingRight: 30, borderRadius: 5,
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 1.22,
              elevation: 1,
              width: '40%'
            }}>
              <View style={{ flexDirection: 'row' }}>
                <View style={{ flexDirection: 'column', flex: 2.5 }}>
                  <View style={{ flexDirection: 'row', height: 20, }}>
                    <View style={{ backgroundColor: Colors.primaryColor, width: 4, }} />
                    <Text style={{ fontWeight: '400', fontSize: 15, marginLeft: 5, alignSelf: 'center' }}>
                      Past 30 Days Customers
                    </Text>
                  </View>
                  <View style={{ marginTop: 10 }}>
                    <Text style={{ fontSize: 28, fontWeight: '600' }}>
                      {overviewCustomersPast30Days}
                    </Text>
                  </View>
                </View>
                <View style={{ flexDirection: 'column', flex: 1 }}>
                  <Ionicon name="people-outline" color={'#F7F7F7'} size={65} style={{ opacity: 0.6, paddingLeft: 2 }} />
                </View>
              </View>
            </View> */}
            </View>

            <View
              style={
                (styles.list1,
                {
                  width: windowWidth * 0.87,
                  minHeight: windowHeight * 0.75,
                  // borderWidth: 1,
                })
              }
            >
              {/* <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // margin: 10,
                  marginBottom: 10,
                  marginTop: 12,
                  marginHorizontal:75,
                  //marginLeft: 20,
                  //marginRight: 20,
                  //backgroundColor: 'red',
                  //height: '10%'
                  // borderWidth: 1,
                }}
              >
                <View style={{ width: "55%", justifyContent: "center" }}>
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      fontSize: switchMerchant ? 14 : 18,
                      letterSpacing: 0.4,
                      marginLeft: 20,
                    }}
                  >
                    Customer List
                  </Text>
                </View>
                <View
                  style={{
                    width: "40%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                > */}
              {/* Filter bar  */}
              {/* <TouchableOpacity style={{

                justifyContent: 'center',
                width: '38%'
              }}
                onPress={() => {
                  setVisible(true);
                }}>
                <View style={{ justifyContent: 'center', width: '100%', flexDirection: 'row', borderWidth: 1, borderColor: '#4E9F7D', borderRadius: 3, height: 35, alignItems: 'center' }}>
                  <Feather name='filter' size={18} color='#4E9F7D' />
                  <Text style={{
                    color: '#4E9F7D',
                    fontSize: 14,
                    fontFamily: 'Nunitosans-Bold',
                    marginLeft: 7
                  }}>
                    Manage Filter
                  </Text>
                </View>
              </TouchableOpacity> */}
              {/* <View style={{}} /> */}
              {/* Type 1 Search Bar */}
              {/* <View style={{ flexDirection: 'row', justifyContent: 'center', width: '53%', height: 40, alignItems: 'center', borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5, alignSelf: 'center' }}>
                <View style={{ flex: 3 }}>
                  <TextInput
                    placeholderTextColor='#737373'
                    style={{ marginLeft: 10, color: '#737373' }}
                    onChangeText={(text) => {
                      setSearch(text);
                    }}
                    defaultValue={search}
                  />
                </View>
                <View style={{ flex: 1, height: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.primaryColor, borderWidth: 1, borderColor: '#E5E5E5', borderRadius: 5 }}>
                  <Icon name='search' size={20} color={Colors.whiteColor} />
                </View>
              </View> */}

              {/* Type 2 Search Bar */}
              {/* <View style={{
                  marginTop: 10,
                  width: 250,
                  height: 40,
                  backgroundColor: 'white',
                  borderRadius: 5,
                  flexDirection: 'row',
                  alignContent: 'center',
                  alignItems: 'center',
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}>
                  <Icon name="search" size={18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />

                  <View style={{ flex: 4 }}>
                    <TextInput
                      underlineColorAndroid={Colors.whiteColor}
                      style={{
                        width: 220,
                        fontSize: 15,
                        fontFamily: 'NunitoSans-Regular',
                        paddingLeft: 5,
                      }}
                      clearButtonMode="while-editing"
                      placeholder=" Search"
                      onChangeText={(text) => {
                        setSearch(text);
                        // setSearch(text.trim());
                      }}
                    // value={search}
                    />
                  </View>
                </View> */}
              {/* </View>
              </View> */}

              {/****************List View Here****************/}

              <View style={{ width: "100%", marginTop: 0, borderRadius: 5 }}>
                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    width: windowWidth * 0.8575,
                    minHeight: Dimensions.get("screen").height * 0.01,
                    marginTop: 30,
                    marginHorizontal: 75,
                    marginBottom: 30,
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                    paddingHorizontal: 20,
                    // borderWidth: 1,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      // margin: 10,
                      // marginBottom: 10,
                      marginTop: 14,
                      // marginHorizontal:75,
                      //marginLeft: 20,
                      //marginRight: 20,
                      //backgroundColor: 'red',
                      //height: '10%'
                      // borderWidth: 1,
                    }}
                  >
                    <View style={{ width: "55%", justifyContent: "center" }}>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 14 : 20,
                          letterSpacing: 0.4,
                          // marginLeft: 20,
                        }}
                      >
                        Customer List
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      marginTop: 0,
                      flexDirection: "row",
                      borderBottomWidth: 1,
                      borderColor: "#E5E5E5",
                      height: windowHeight * 0.08,
                      // borderWidth:1,
                    }}
                  >
                    <View
                      style={{
                        flex: 2,
                        borderRightWidth: 0,
                        borderRightColor: "lightgrey",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        // borderWidth: 1,
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 2.8 }}>
                          {/* <TouchableOpacity onPress={() => { 
                        // sorting(setSortName(!sortName))
                          //setSortName(CRM_SCREEN_SECTION.SORT_NAME)
                          setSort(USER_SORT_FIELD_TYPE.NAME_ASC)
                        }}> */}
                          <Text
                            numberOfLines={2}
                            style={{
                              paddingLeft: 5,
                              fontSize: switchMerchant ? 10 : 14,
                              fontFamily: "NunitoSans-Bold",
                              marginLeft: Platform.OS == "ios" ? 5 : 0,
                            }}
                          >
                            {"Name & User ID\n"}
                          </Text>
                          {/* </TouchableOpacity>                     */}
                        </View>
                        <View style={{ flex: 0.2 }}>
                          <TouchableOpacity
                            onPress={() => {
                              // sorting(setSortName(!sortName))
                              //setSortName(CRM_SCREEN_SECTION.SORT_NAME)
                              setSort(USER_SORT_FIELD_TYPE.NAME_ASC);
                            }}
                          >
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 8 : 12}
                              color={Colors.descriptionColor}
                            ></Entypo>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              // sorting(setSortName(!sortName))
                              //setSortName(CRM_SCREEN_SECTION.SORT_NAME)
                              setSort(USER_SORT_FIELD_TYPE.NAME_DESC);
                            }}
                          >
                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 8 : 12}
                              color={Colors.descriptionColor}
                            ></Entypo>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flex: 1.5,
                        borderRightWidth: 0,
                        borderRightColor: "lightgrey",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        // borderWidth: 1,
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <Text
                          numberOfLines={2}
                          style={{
                            fontSize: switchMerchant ? 10 : 14,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          {"Contact Info\n"}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        borderRightWidth: 0,
                        borderRightColor: "lightgrey",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        // borderWidth: 1,
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 2.8 }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 14,
                              fontFamily: "NunitoSans-Bold",
                              marginLeft:windowWidth * 0.01,
                            }}
                          >
                            {"Gender\n"}
                          </Text>
                        </View>
                        <View style={{ flex: 0.2 }}>
                          <TouchableOpacity
                            onPress={() => {
                              setSort(USER_SORT_FIELD_TYPE.GENDER_ASC);
                            }}
                          >
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 8 : 12}
                              color={Colors.descriptionColor}
                            ></Entypo>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              setSort(USER_SORT_FIELD_TYPE.GENDER_DESC);
                            }}
                          >
                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 8 : 12}
                              color={Colors.descriptionColor}
                            ></Entypo>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        borderRightWidth: 0,
                        borderRightColor: "lightgrey",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        // borderWidth: 1,
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 2.8 }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 14,
                              fontFamily: "NunitoSans-Bold",
                              marginLeft:windowWidth * 0.015
                            }}
                          >
                            {"DOB\n"}
                          </Text>
                        </View>
                        <View style={{ flex: 0.2 }}>
                          <TouchableOpacity
                            onPress={() => {
                              setSort(USER_SORT_FIELD_TYPE.DOB_ASC);
                            }}
                          >
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 8 : 12}
                              color={Colors.descriptionColor}
                            ></Entypo>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              setSort(USER_SORT_FIELD_TYPE.DOB_DESC);
                            }}
                          >
                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 8 : 12}
                              color={Colors.descriptionColor}
                            ></Entypo>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        borderRightWidth: 0,
                        borderRightColor: "lightgrey",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        // paddingLeft:'8%',
                        // borderWidth:1,
                      }}
                    >
                      <View style={{ flexDirection: "row"}}>
                        <View style={{ flex: 2.8 }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 14,
                              fontFamily: "NunitoSans-Bold",
                              // paddingLeft:'8%',
                              marginLeft:windowWidth * 0.017
                            }}
                          >
                            {"Race\n"}
                          </Text>
                        </View>
                        <View style={{ flex: 0.2 }}>
                          <TouchableOpacity
                            onPress={() => {
                              setSort(USER_SORT_FIELD_TYPE.RACE_ASC);
                            }}
                          >
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 8 : 12}
                              color={Colors.descriptionColor}
                            ></Entypo>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              setSort(USER_SORT_FIELD_TYPE.RACE_DESC);
                            }}
                          >
                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 8 : 12}
                              color={Colors.descriptionColor}
                            ></Entypo>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        borderRightWidth: 0,
                        borderRightColor: "lightgrey",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <View style={{ flex: 2.8 }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 14,
                              fontFamily: "NunitoSans-Bold",
                              marginLeft:windowWidth * 0.01
                            }}
                          >
                            {"Avg Spending \nPer 30days\n"}
                          </Text>
                        </View>
                        <View style={{ flex: 0.2 }}>
                          <TouchableOpacity
                            onPress={() => {
                              setSort(USER_SORT_FIELD_TYPE.STATUS_ASC);
                            }}
                          >
                            <Entypo
                              name="triangle-up"
                              size={switchMerchant ? 8 : 12}
                              color={Colors.descriptionColor}
                            ></Entypo>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              setSort(USER_SORT_FIELD_TYPE.STATUS_DESC);
                            }}
                          >
                            <Entypo
                              name="triangle-down"
                              size={switchMerchant ? 8 : 12}
                              color={Colors.descriptionColor}
                            ></Entypo>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </View>
                  {list1 ? (
                    // <View style={{ borderTopWidth: 1 }}>
                    <FlatList
                      ////THIS IS FOR SEARCH////
                      showsVerticalScrollIndicator={false}
                      data={sortCRMUsers(
                        headerSorting.filter((item) => {
                          if (search !== "") {
                            const searchLowerCase = search.toLowerCase();

                            if (
                              item.email
                                .toLowerCase()
                                .includes(searchLowerCase) ||
                              item.name
                                .toLowerCase()
                                .includes(searchLowerCase) ||
                              item.uniqueName
                                .toLowerCase()
                                .includes(searchLowerCase) ||
                              item.number
                                .toLowerCase()
                                .includes(searchLowerCase)
                            ) {
                              return true;
                            }

                            return false;
                          } else {
                            return true;
                          }
                        }),
                        sort
                      ).slice(
                        (currentPage - 1) * perPage,
                        currentPage * perPage
                      )}
                      // extraData={renderItem}
                      renderItem={renderItem}
                      keyExtractor={(item, index) => String(index)}
                      style={{ marginTop: 0 }}
                      // initialNumToRender={4}
                    />
                  ) : // </View>

                  null}
                  {/* {searchList ? (

                                <FlatList
                                    data={lists}
                                    extraData={lists}
                                    renderItem={renderSearchItem}
                                    keyExtractor={(item, index) => String(index)}
                                    initialNumToRender={8}
                                />

                            ) : null} */}
                </View>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
                //marginTop: 10,
                // borderwidth: 10,
                // width: windowWidth * 0.87,
                // alignItems: 'center',
                // alignSelf: 'center',
                justifyContent: "flex-end",
                // top:
                //   Platform.OS == 'ios'
                //     ? pushPagingToTop && keyboardHeight > 0
                //       ? -keyboardHeight * 0.94
                //       : 0
                //     : 0,
                // backgroundColor: pushPagingToTop && keyboardHeight > 0 ? Colors.highlightColor : null,
                // borderWidth: pushPagingToTop && keyboardHeight > 0 ? 1 : 0,
                // borderColor: pushPagingToTop && keyboardHeight > 0 ? '#E5E5E5' : null,
                // borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                // paddingHorizontal:
                // pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                // shadowOffset: {
                //   width: 0,
                //   height: 1,
                // },
                // shadowOpacity: 0.22,
                // shadowRadius: 3.22,
                // elevation: 1,
                marginHorizontal: 60,
                marginBottom: 20,
                // marginTop: switchMerchant ? 5 : 0,
              }}
            >
              <Text
                style={{
                  // fontSize: switchMerchant ? 10 : 14,
                  fontFamily: "NunitoSans-Bold",
                  marginRight: "1%",
                  fontSize: 20,
                }}
              >
                Page
              </Text>
              <View
                style={{
                  // width: switchMerchant ? 65 : 70,
                  // height: switchMerchant ? 20 : 35,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 10,
                  justifyContent: "center",
                  paddingHorizontal: 22,
                  borderWidth: 1,
                  borderColor: "#E5E5E5",
                }}
              >
                {console.log("currentPage")}
                {console.log(currentPage)}

                <TextInput
                  onChangeText={(text) => {
                    var currentPageTemp = text.length > 0 ? parseInt(text) : 1;

                    setCurrentPage(
                      currentPageTemp > pageCount
                        ? pageCount
                        : currentPageTemp < 1
                        ? 1
                        : currentPageTemp
                    );
                  }}
                  placeholder={currentPage.toString()}
                  placeholderStyle={{
                    // fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                  placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                  style={{
                    color: "black",
                    // fontFamily: 'NunitoSans-Regular',
                    // fontSize: switchMerchant ? 10 : 14,
                    fontFamily: "NunitoSans-Regular",
                    marginTop: Platform.OS === "ios" ? 0 : -15,
                    marginBottom: Platform.OS === "ios" ? 0 : -15,
                    textAlign: "center",
                    width: "100%",
                    fontSize: 20,
                  }}
                  value={currentPage.toString()}
                  defaultValue={currentPage.toString()}
                  keyboardType={"numeric"}
                  onFocus={() => {
                    setPushPagingToTop(true);
                  }}
                />
              </View>
              <Text
                style={{
                  // fontSize: switchMerchant ? 10 : 14,
                  fontFamily: "NunitoSans-Regular",
                  marginLeft: "1%",
                  marginRight: "1%",
                  fontSize: 20,
                }}
              >
                of {pageCount}
              </Text>
              <TouchableOpacity
                style={{
                  // width: switchMerchant ? 30 : 45,
                  // height: switchMerchant ? 20 : 28,
                  backgroundColor: Colors.primaryColor,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onPress={() => {
                  prevPage();
                }}
              >
                {/* <MaterialIcons
                  name="keyboard-arrow-left"
                  // size={switchMerchant ? 20 : 25}
                  style={{ color: Colors.whiteColor }}
                  size={20}
                /> */}
                <ArrowLeft />
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  // width: switchMerchant ? 30 : 45,
                  // height: switchMerchant ? 20 : 28,
                  backgroundColor: Colors.primaryColor,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onPress={() => {
                  nextPage();
                }}
              >
                {/* <MaterialIcons
                  name="keyboard-arrow-right"
                  // size={switchMerchant ? 20 : 25}
                  style={{ color: Colors.whiteColor }}
                  size={20}
                /> */}
                <ArrowRight />
              </TouchableOpacity>
            </View>
          </KeyboardAvoidingView>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },
  list: {
    backgroundColor: Colors.whiteColor,
    marginTop: 5,
    marginHorizontal: 20,
    alignSelf: "center",
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
    marginBottom: 20,
  },
  list1: {
    backgroundColor: Colors.whiteColor,
    marginTop: 15,
    marginHorizontal: 35,
    marginBottom: 20,
    alignSelf: "center",
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    //width: windowWidth * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 16,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  titleList: {
    backgroundColor: "#ffffff",
    flexDirection: "row",
    paddingVertical: 20,
    paddingHorizontal: 20,
    //marginTop: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

    alignItems: "center",

    // shadowOpacity: 0,
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.22,
    // shadowRadius: 3.22,
    // elevation: 3,
  },
  textTitle: {
    fontSize: 16,
    fontFamily: "NunitoSans-Bold",
  },
  textItem: {
    fontSize: 14,
    fontFamily: "NunitoSans-Regular",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    // alignContent: 'center',
    alignItems: "center",
    backgroundColor: "white",
    padding: 20,
    paddingTop: 15,
    marginTop: 0,
    width: "100%",

    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 7,
  },
  addVoucher: {
    marginTop: 0,
    //justifyContent: 'center',
    alignItems: "center",
    //alignContent: 'center',
    backgroundColor: Colors.whiteColor,
    // marginRight: 100,

    borderRadius: 4,

    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
  textInput: {
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 20,
    width: 300,
  },
  textInput1: {
    width: 250,
    height: 40,
    backgroundColor: "white",
    borderRadius: 10,
    // marginLeft: '53%',
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  headerLeftStyle: {
    justifyContent: "center",
    alignItems: "center",
  },
  addNewView: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: -10,
    marginTop: 5,
    width: "97%",
    alignSelf: "flex-end",
  },
  addButtonView: {
    justifyContent: "center",
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    backgroundColor: "#4E9F7D",
    borderRadius: 5,
    width: 155,
    height: 40,
    alignItems: "center",
  },
  editButtonView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    backgroundColor: "#4E9F7D",
    borderRadius: 5,
    width: 74,
    height: 35,
    alignItems: "center",
  },
  ManageFilterBox: {
    //width: windowWidth * 0.4,
    //height: windowHeight * 0.7,
    //borderRadius: 10,
    backgroundColor: Colors.whiteColor,
    //justifyContent: 'space-between'
  },
  ManageFilterBox1: {
    //borderRadius: 10,
    backgroundColor: Colors.whiteColor,
    justifyContent: "center",
    borderColor: "#E5E5E5",
    borderWidth: 1,
    alignItems: "center",
    alignSelf: "center",
    //padding: 10,
    margin: 15,
  },

  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView1: {
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
  },
  closeButton: {
    elevation: 1000,
    zIndex: 1000,
    alignSelf: "flex-end",
  },
  modalTitle: {
    flex: 2,
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitleText: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
  },
  modalTitleText1: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
    fontSize: 18,
  },
  modalDescText: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },

  submitText: {
    height: 40,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  textInput9: {
    fontFamily: "NunitoSans-Regular",
    width: 430,
    height: 50,
    //flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },
});

export default CrmScreen;
