import React, { Component, useReducer, useState, useEffect } from "react";
import {
  Platform,
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  TextInput,
  Dimensions,
  FlatList,
  Modal,
  PermissionsAndroid,
  useWindowDimensions,
  ActivityIndicator,
} from "react-native";
import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import * as User from "../util/User";
import Icon from "react-native-vector-icons/Feather";
import Icon1 from "react-native-vector-icons/FontAwesome";
import Icon2 from "react-native-vector-icons/EvilIcons";
import Select from "react-select";
import Icon3 from "react-native-vector-icons/Foundation";
import Ionicon from "react-native-vector-icons/Ionicons";
import Icon4 from "react-native-vector-icons/FontAwesome5";
// import DropDownPicker from "react-native-dropdown-picker";
// import { ceil } from 'react-native-reanimated';
import DateTimePicker from "react-native-modal-datetime-picker";
import moment from "moment";
import Close from "react-native-vector-icons/AntDesign";
import ApiClient from "../util/ApiClient";
import API from "../constant/API";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Styles from "../constant/Styles";
// import DocumentPicker from 'react-native-document-picker';
import Ionicons from "react-native-vector-icons/Ionicons";
// import RNFetchBlob from "rn-fetch-blob";
import "react-native-get-random-values";
import { customAlphabet } from "nanoid";
import { CommonStore } from "../store/commonStore";
import { MerchantStore } from "../store/merchantStore";
import { CSVLink } from "react-csv";
import {
  PURCHASE_ORDER_STATUS,
  PURCHASE_ORDER_STATUS_PARSED,
  EMAIL_REPORT_TYPE,
} from "../constant/common";
import { UserStore } from "../store/userStore";
import {
  convertArrayToCSV,
  generateEmailReport,
  getPathForFirebaseStorageFromBlob,
} from "../util/common";
// import RNPickerSelect from "react-native-picker-select";
import { Picker } from "react-native";
import AsyncImage from "../components/asyncImage";
import AntDesign from "react-native-vector-icons/AntDesign";
import Entypo from "react-native-vector-icons/Entypo";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import XLSX from "xlsx";
import { ReactComponent as Coins } from "../assets/svg/Coins.svg";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as Dish } from "../assets/svg/Dish.svg";
import { ReactComponent as Hand } from "../assets/svg/Hand.svg";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import { ReactComponent as GCalendarGrey } from "../assets/svg/GCalendarGrey.svg";
import { ReactComponent as EditGreen } from "../assets/svg/EditGreen.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
//import StyleSheet from 'react-native-media-query';

// const RNFS = require('react-native-fs');

const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 12);

const FILTER_PURCHASE_ORDER = {
  ALL_PO: "FILTER_PURCHASE_ORDER.ALL_PO",
  PO_TODAY: "FILTER_PURCHASE_ORDER.PO_TODAY",
  PENDING_PO: "FILTER_PURCHASE_ORDER.PENDING_PO",
  COMPLETED_PO: "FILTER_PURCHASE_ORDER.COMPLETED_PO",
  CANCELLED_PO: "FILTER_PURCHASE_ORDER.CANCELLED_PO",
};

const PurchaseOrderScreen = (props) => {
  const { navigation } = props;

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const [filterPurchaseOrder, setFilterPurchaseOrder] = useState(
    FILTER_PURCHASE_ORDER.ALL_PO
  );
  const [purchaseOrder, setPurchaseOrder] = useState(true);
  const [addStockTake, setAddStockTake] = useState(false);
  const [addPurchase, setAddPurchase] = useState(false);
  const [editPurchase, setEditPurchase] = useState(false);
  const [addStockTransfer, setAddStockTransfer] = useState(false);
  const [stockList, setStockList] = useState([]);
  const [stockTransferList, setStockTransferList] = useState([]);
  const [stockTakeList, setStockTakeList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [itemsToOrder, setItemsToOrder] = useState([{}]);
  const [itemsToOrder2, setItemsToOrder2] = useState([{}, {}, {}]);
  const [addStockTransferList, setAddStockTransferList] = useState([
    {},
    {},
    {},
  ]);
  const [addCountedStockTakeList, setAddCountedStockTakeList] = useState([
    {},
    {},
    {},
  ]);
  const [addUnCountedStockTakeList, setAddUnCountedStockTakeList] = useState([
    {},
    {},
    {},
  ]);
  const [productList, setProductList] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);
  const [isSelected3, setIsSelected3] = useState(true);
  const [isSelected4, setIsSelected4] = useState(false);
  const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
  const [date, setDate] = useState(Date.now());
  const [visible, setVisible] = useState(false);
  const [Email, setEmail] = useState(null);
  const [modal, setModal] = useState(false);
  const [outletId, setOutletId] = useState(User.getOutletId());
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");
  const [ideal, setIdeal] = useState("");
  const [minimum, setMinimum] = useState("");
  const [itemId, setItemId] = useState("");
  const [choose, setChoose] = useState(null);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [loading, setLoading] = useState(false);
  const [choice, setChoice] = useState("Print P.O");
  const [choice2, setChoice2] = useState("Print P.O");
  const [choice7, setChoice7] = useState("1");
  const [choice8, setChoice8] = useState("1");
  const [choice4, setChoice4] = useState("Chicken patty");

  const [image, setImage] = useState("");

  const [exportModal, setExportModal] = useState(false);
  const [importModal, setImportModal] = useState(false);

  const [expandThreeDots, setExpandThreeDots] = useState({}); //Use to expand the view when three dots are tapped
  const [threeDotsTapped, setThreeDotsTapped] = useState(false); //when the three dots are tapped will become (true)

  const [purchaseOrderToday, setPurchaseOrderToday] = useState(0);
  const [purchaseOrderPending, setPurchaseOrderPending] = useState(0);
  const [purchaseOrderCompleted, setPurchaseOrderCompleted] = useState(0);
  const [purchaseOrderCancelled, setPurchaseOrderCancelled] = useState(0);

  const [exportEmail, setExportEmail] = useState("");

  const [isLoadingExcel, setIsLoadingExcel] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isLoadingSaveAndSend, setIsLoadingSaveAndSend] = useState(false);

  ///////////////////////////////////////////////////////////////////

  const [poId, setPoId] = useState("");
  const [editMode, setEditMode] = useState(false);

  const [supplierDropdownList, setSupplierDropdownList] = useState([]);
  const [selectedSupplierId, setSelectedSupplierId] = useState("");

  const [poStatus, setPoStatus] = useState(PURCHASE_ORDER_STATUS.CREATED);

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState("");

  const [supplyItemDropdownList, setSupplyItemDropdownList] = useState([]);

  const [poItems, setPoItems] = useState([
    {
      supplyItemId: "",
      image: "",
      name: "",
      sku: "",
      skuMerchant: "",
      quantity: 0,
      orderQuantity: 0,
      receivedQuantity: 0,
      price: 0,
      totalPrice: 0,
      unit: "",
      lastReceivedQuantity: 0,
      supplyItem: null,
    },
  ]);

  const [dropdownItems, setDropdownItems] = useState([
    {
      label: "🖨️  Print P.O",
      value: "Print P.O",
    },
    {
      label: "📧  Email P.O",
      value: "Chicken",
    },
    {
      label: "📤  Export Labels",
      value: "Export Labels",
    },
    {
      label: "❌  Cancel P.O",
      value: "Cancel P.O",
    },
    {
      label: "🗑️  Delete P.O",
      value: "Delete P.O",
    },
  ]);

  const [selectedSupplier, setSelectedSupplier] = useState({
    taxRate: 0,
  });

  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);

  const [expandViewDict, setExpandViewDict] = useState({});

  const outletSupplyItemsSkuDict = CommonStore.useState(
    (s) => s.outletSupplyItemsSkuDict
  );

  // const supplyItems = CommonStore.useState(s => s.supplyItems);
  const [supplyItems, setSupplyItems] = useState([]);
  const supplyItemsAll = CommonStore.useState((s) => s.supplyItems);

  const supplyItemsDict = CommonStore.useState((s) => s.supplyItemsDict);
  const suppliers = CommonStore.useState((s) => s.suppliers);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const merchantId = UserStore.useState((s) => s.merchantId);
  const purchaseOrders = CommonStore.useState((s) => s.purchaseOrders);

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);
  const poNumber = MerchantStore.useState((s) => s.poNumber);

  const isLoading = CommonStore.useState((s) => s.isLoading);
  const selectedPurchaseOrderEdit = CommonStore.useState(
    (s) => s.selectedPurchaseOrderEdit
  );
  const selectedPurchaseOrderEditFromSupplier = CommonStore.useState(
    (s) => s.selectedPurchaseOrderEditFromSupplier
  );

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const createPO = "Create Purchase Order";
  const editPO = "Edit Purchase Order";

  useEffect(() => {
    if (selectedPurchaseOrderEditFromSupplier !== null) {
      CommonStore.update((s) => {
        s.selectedPurchaseOrderEdit = selectedPurchaseOrderEditFromSupplier;
        s.selectedPurchaseOrderEditFromSupplier = null;
      });

      setPurchaseOrder(false);
      setAddPurchase(true);
    }
  }, [selectedPurchaseOrderEditFromSupplier]);

  useEffect(() => {
    if (selectedPurchaseOrderEdit) {
      // insert info

      setPoId(selectedPurchaseOrderEdit.poId);
      setSelectedSupplierId(selectedPurchaseOrderEdit.supplierId);
      setPoStatus(selectedPurchaseOrderEdit.status);
      setSelectedTargetOutletId(selectedPurchaseOrderEdit.outletId);
      setDate(selectedPurchaseOrderEdit.estimatedArrivalDate);

      if (selectedPurchaseOrderEdit.poItems) {
        setPoItems(selectedPurchaseOrderEdit.poItems);
      }
    } else {
      // designed to always mounted, thus need clear manually...

      // setPoId(nanoid());
      // setPoId(`PO${moment().format('MMM').slice(0, 2).toUpperCase() + moment().format('YY') + poNumber.toString().padStart(4, '0')}`);
      setPoId(
        `PO${
          moment().format("MMM").toUpperCase() +
          moment().format("YY") +
          (poNumber + 1).toString().padStart(4, "0")
        }`
      );
      suppliers.length > 0 && setSelectedSupplierId(suppliers[0].uniqueId);
      setPoStatus(PURCHASE_ORDER_STATUS.CREATED);
      if (allOutlets[0])
        setSelectedTargetOutletId(allOutlets[0].uniqueId);
      setDate(Date.now());

      // if (supplyItems.length > 0 && Object.keys(outletSupplyItemsSkuDict).length > 0) {
      //   setPoItems([
      //     {
      //       supplyItemId: supplyItems[0].uniqueId,
      //       name: supplyItems[0].name,
      //       sku: supplyItems[0].sku,
      //       quantity: outletSupplyItemsSkuDict[supplyItems[0].sku] ? outletSupplyItemsSkuDict[supplyItems[0].sku].quantity : 0, // check if the supply item sku for this outlet existed
      //       orderQuantity: 0,
      //       receivedQuantity: 0,
      //       price: supplyItems[0].price,
      //       totalPrice: 0,
      //     }
      //   ]);

      // }
      // else {
      //   setPoItems([
      //     {
      //       supplyItemId: '',
      //       name: '',
      //       sku: '',
      //       quantity: 0,
      //       orderQuantity: 0,
      //       receivedQuantity: 0,
      //       price: 0,
      //       totalPrice: 0,
      //     }
      //   ]);
      // }
    }
  }, [selectedPurchaseOrderEdit, addPurchase, poNumber]);

  useEffect(() => {
    setPoId(
      `PO${
        moment().format("MMM").toUpperCase() +
        moment().format("YY") +
        (poNumber + 1).toString().padStart(4, "0")
      }`
    );
  }, [poNumber]);

  useEffect(() => {
    const supplyItemsTemp = supplyItemsAll.filter((supplyItem) => {
      if (supplyItem.supplierId === selectedSupplierId) {
        return true;
      }
    });

    ///////////////////////////
    // reset poItems when supplierId changed

    ///////////////////////////

    setSupplyItems(supplyItemsTemp);
  }, [supplyItemsAll, selectedSupplierId]);

  useEffect(() => {
    setSupplierDropdownList(
      suppliers.map((supplier) => ({
        label: supplier.name,
        value: supplier.uniqueId,
      }))
    );

    if (selectedSupplierId === "" && suppliers.length > 0) {
      setSelectedSupplierId(suppliers[0].uniqueId);
    }
  }, [suppliers]);

  useEffect(() => {
    setTargetOutletDropdownList(
      allOutlets.map((outlet) => ({
        label: outlet.name,
        value: outlet.uniqueId,
      }))
    );

    if (selectedTargetOutletId === "" && allOutlets.length > 0) {
      setSelectedTargetOutletId(allOutlets[0].uniqueId);
    }
  }, [allOutlets]);

  useEffect(() => {
    setSupplyItemDropdownList(
      supplyItems.map((supplyItem) => {
        // if (selectedSupplierId === supplyItem.supplierId) {
        //   return { label: supplyItem.name, value: supplyItem.uniqueId };
        // }

        return { label: supplyItem.name, value: supplyItem.uniqueId };
      })
    );

    if (
      supplyItems.length > 0 &&
      poItems.length === 1 &&
      poItems[0].supplyItemId === ""
    ) {
      setPoItems([
        {
          supplyItemId: supplyItems[0].uniqueId,
          name: supplyItems[0].name,
          sku: supplyItems[0].sku,
          skuMerchant: supplyItems[0].skuMerchant,
          quantity: outletSupplyItemsSkuDict[supplyItems[0].sku]
            ? outletSupplyItemsSkuDict[supplyItems[0].sku].quantity
            : 0, // check if the supply item sku for this outlet existed
          orderQuantity: 0,
          unit: supplyItems[0].unit,
          receivedQuantity: 0,

          price: supplyItems[0].price,
          totalPrice: 0,

          lastReceivedQuantity: 0,

          supplyItem: supplyItems[0],
        },
      ]);
    } else if (
      poItems[0].supplyItemId !== "" &&
      Object.keys(supplyItemsDict).length > 0
    ) {
      var poItemsTemp = [...poItems];

      for (var i = 0; i < poItemsTemp.length; i++) {
        const supplyItem = supplyItemsDict[poItemsTemp[i].supplyItemId];

        if (supplyItem) {
          poItemsTemp[i] = {
            ...poItemsTemp[i],
            quantity: outletSupplyItemsSkuDict[supplyItem.sku]
              ? outletSupplyItemsSkuDict[supplyItem.sku].quantity
              : 0, // check if the supply item sku for this outlet existed | might changed in real time
            price: supplyItem.price, // might changed in real time
          };
        }
        // else {
        //   poItemsTemp[i] = {
        //     ...poItemsTemp[i],
        //     quantity: 0,
        //     // price: supplyItem.price, // might changed in real time
        //   };
        // }
      }

      setPoItems(poItemsTemp);
    }
  }, [supplyItems, supplyItemsDict, outletSupplyItemsSkuDict]);

  useEffect(() => {
    if (suppliers.length > 0 && selectedSupplierId !== "") {
      setSelectedSupplier(
        suppliers.find((supplier) => supplier.uniqueId === selectedSupplierId)
      );
    }
  }, [suppliers, selectedSupplierId]);

  useEffect(() => {
    console.log("subtotal");
    console.log(
      poItems.reduce(
        (accum, poItem) =>
          accum +
          (poItem.supplyItem &&
          poItem.supplyItem.supplierId === selectedSupplierId
            ? poItem.totalPrice
            : 0),
        0
      )
    );
    setSubtotal(
      poItems.reduce(
        (accum, poItem) =>
          accum +
          (poItem.supplyItem &&
          poItem.supplyItem.supplierId === selectedSupplierId
            ? poItem.totalPrice
            : 0),
        0
      )
    );
  }, [poItems, selectedSupplierId]);

  useEffect(() => {
    console.log("taxTotal");
    console.log(
      subtotal * (selectedSupplier ? selectedSupplier.taxRate : 0.06)
    );
    setTaxTotal(
      subtotal * (selectedSupplier ? selectedSupplier.taxRate : 0.06)
    );
  }, [subtotal]);

  useEffect(() => {
    console.log("finalTotal");
    console.log(subtotal - discountTotal + taxTotal);
    setFinalTotal(subtotal - discountTotal + taxTotal);
  }, [subtotal, discountTotal, taxTotal]);

  useEffect(() => {
    requestStoragePermission();

    // if (poId === '') {
    //   setPoId(nanoid());
    // }
    // setPoId(nanoid());
  }, []);

  useEffect(() => {
    var poToday = 0;
    var poPending = 0;
    var poCompleted = 0;
    var poCancelled = 0;
    var currDay = moment(Date.now());

    for (var i = 0; i < purchaseOrders.length; i++) {
      console.log(moment(Date.now()));
      console.log("hello");
      if (moment(currDay).isSame(purchaseOrders[i].orderDate, "day")) {
        console.log("hello123");
        poToday += 1;
      }
      if (
        purchaseOrders[i].status === PURCHASE_ORDER_STATUS.CREATED ||
        purchaseOrders[i].status === PURCHASE_ORDER_STATUS.PARTIALLY_RECEIVED
      ) {
        poPending += 1;
      }
      if (purchaseOrders[i].status === PURCHASE_ORDER_STATUS.COMPLETED) {
        poCompleted += 1;
      }
      if (
        purchaseOrders[i].status !== PURCHASE_ORDER_STATUS.CREATED &&
        purchaseOrders[i].status !== PURCHASE_ORDER_STATUS.COMPLETED &&
        purchaseOrders[i].status !== PURCHASE_ORDER_STATUS.PARTIALLY_RECEIVED &&
        purchaseOrders[i].status !== PURCHASE_ORDER_STATUS.ORDERED
      ) {
        poCancelled += 1;
      }
    }

    setPurchaseOrderToday(poToday);
    setPurchaseOrderPending(poPending);
    setPurchaseOrderCompleted(poCompleted);
    setPurchaseOrderCancelled(poCancelled);
  }, [purchaseOrders]);

  const setState = () => {};

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            // bottom: -2,
            bottom: switchMerchant ? "2%" : 0,
          },
          windowWidth >= 768 ? { right: windowWidth * 0.05 } : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Purchase Order
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // componentDidMount = () => {
  //   // setInterval(() => {
  //   //   getStockOrder()
  //   //   getStockTransfer()
  //   //   getLowStock()
  //   // }, 1000);
  //   getStockOrder();

  // }

  // async componentWillMount = () => {
  //   await requestStoragePermission()
  // }

  const requestStoragePermission = async () => {
    try {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: "KooDoo Merchant Storage Permission",
          message: "KooDoo Merchant App needs access to your storage ",
          buttonNegative: "Cancel",
          buttonPositive: "OK",
        }
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        console.log("Storage permission granted");
      } else {
        console.log("Storage permission denied");
      }
    } catch (err) {
      console.warn(err);
    }
  };

  //when press three dots this function will work
  const expandThreeDotsFunc = (param) => {
    if (expandThreeDots[param.uniqueId]) {
      setExpandThreeDots({
        ...expandThreeDots,
        [param.uniqueId]: false,
      });
    } else {
      setExpandThreeDots({
        // ...expandThreeDots,
        [param.uniqueId]: true,
      });
    }
  };

  //   const importCSV = () => {
  //     try {
  //       const res = DocumentPicker.pick({
  //         type: [DocumentPicker.types.csv],
  //       });
  //       console.log(
  //         res.uri,
  //         res.type,
  //         res.name,
  //         res.size
  //       );
  //     } catch (err) {
  //       if (DocumentPicker.isCancel(err)) {
  //         // User cancelled the picker, exit any dialogs or menus and move on
  //       } else {
  //         throw err;
  //       }
  //     }
  //   }

  //   const expandOrderFunc = (param) => {
  //     if (expandOrder == false) {
  //       // return setState({ expandOrder: true }), param.expandOrder = true;
  //       setExpandOrder(true);
  //       setExpandViewDict({
  //         ...expandViewDict,
  //         [param.uniqueId]: true,
  //       });
  //       expandViewDict;
  //     } else {
  //       // return setState({ expandOrder: false }), param.expandOrder = false;
  //       setExpandOrder(false);
  //       setExpandViewDict({
  //         ...expandViewDict,
  //         [param.uniqueId]: undefined,
  //       });
  //     }
  //   };

  const convertTemplateToExcelFormat = () => {
    var excelTemplate = [];

    for (var i = 0; i < poItems.length; i++) {
      var excelColumn = {
        "Product Name": poItems[i].name,
        SKU: poItems[i].skuMerchant,
        "On hand": poItems[i].receivedQuantity,
        "Order Qty": poItems[i].orderQuantity,
        Unit: poItems[i].unit,
        "Received Qty": poItems[i].lastReceivedQuantity,
        "Supplier Price": poItems[i].price,
        "Total(RM)": poItems[i].totalPrice,
      };
      excelTemplate.push(excelColumn);
    }

    console.log("excelTemplate");
    console.log(excelTemplate);

    return excelTemplate;
  };

  //   const exportTemplate = () => {
  //     const excelTemplate = convertTemplateToExcelFormat();

  //     var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/koodoo-Inventory-Purchase-Order${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
  //     var excelWorkSheet = XLSX.utils.json_to_sheet(excelTemplate);
  //     var excelWorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, "Purchase Order Template");

  //     const workBookData = XLSX.write(excelWorkBook, {
  //       type: 'binary',
  //       bookType: 'xlsx',
  //     });

  //     RNFS.writeFile(excelFile, workBookData, 'ascii')
  //       .then((success) => {
  //         console.log(`wrote file ${excelFile}`);

  //         Alert.alert(
  //           'Success',
  //           `Send to ${excelFile}`,
  //           [{ text: 'OK', onPress: () => { } }],
  //           { cancelable: false },
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   };

  //   const importTemplateData = async () => {
  //     CommonStore.update(s => {
  //       s.isLoading = true;
  //     });

  //     try {
  //       const res = await DocumentPicker.pick({
  //         type: [DocumentPicker.types.zip],
  //         copyTo: 'documentDirectory',
  //       });
  //       console.log('res');
  //       console.log(res);

  //       var referenceId = uuidv4();
  //       var referencePath = `/merchant/${merchantId}}/batchUploads/${referenceId}.zip`;

  //       var translatedPath = await getPathForFirebaseStorageFromBlob(res[0]);

  //       console.log('translatedPath');
  //       console.log(translatedPath);

  //       await uploadFileToFirebaseStorage(translatedPath, referencePath);

  //       const body = {
  //         zipId: referenceId,
  //         zipPath: referencePath,

  //         userId: firebaseUid,
  //         merchantId: merchantId,
  //         outletId: currOutletId,
  //       };

  //       ApiClient.POST(API.batchCreateOutletSupplyItems, body).then((result) => {
  //         if (result && result.status === 'success') {
  //           Alert.alert('Success', 'Submitted to upload queue, we will notify you once processing done');
  //         }
  //         else {
  //           Alert.alert('Error', 'Failed to import the data');
  //         }

  //         CommonStore.update(s => {
  //           s.isLoading = false;
  //         });
  //       }).catch(err => {
  //         console.log(err);

  //         Alert.alert('Error', 'Failed to import the data.');

  //         CommonStore.update(s => {
  //           s.isLoading = false;
  //         });
  //       });
  //     } catch (err) {
  //       CommonStore.update(s => {
  //         s.isLoading = false;
  //       });

  //       if (DocumentPicker.isCancel(err)) {
  //         // User cancelled the picker, exit any dialogs or menus and move on
  //       } else {
  //         console.error(err);

  //         Alert.alert('Error', 'Failed to import the data');
  //       }
  //     }
  //   }

  //error show readAsArrayBuffer not implemented
  // const importTemplate = (file) => {
  //   const promise = new Promise((resolve, reject) => {
  //     const fileReader = new FileReader();
  //     fileReader.readAsArrayBuffer(file);

  //     fileReader.onload = (e) => {
  //       const bufferArray = e.target.result;

  //       const wb = XLSX.read(bufferArray, { type: "buffer" });

  //       const wsname = wb.SheetNames[0];

  //       const ws = wb.Sheets[wsname];

  //       const data = XLSX.utils.sheet_to_json(ws);

  //       resolve(data);
  //     };

  //     fileReader.onerror = (error) => {
  //       reject(error);
  //     };
  //   });

  //   promise.then((d) => {
  //     console.log(d);
  //   });
  // }

  //   const downloadCsv = () => {
  //     if (purchaseOrders) {
  //       const csvData = convertArrayToCSV(purchaseOrders);

  //       const pathToWrite = `${
  //         RNFetchBlob.fs.dirs.DownloadDir
  //       }/koodoo-report-purchase-order-${moment().format(
  //         "YYYY-MM-DD-HH-mm-ss"
  //       )}.csv`;
  //       console.log("PATH", pathToWrite);
  //       RNFetchBlob.fs
  //         .writeFile(pathToWrite, csvData, "utf8")
  //         .then(() => {
  //           console.log(`wrote file ${pathToWrite}`);
  //           // wrote file /storage/emulated/0/Download/data.csv
  //           Alert.alert(
  //             "Success",
  //             `Send to ${pathToWrite}`,
  //             [{ text: "OK", onPress: () => {} }],
  //             { cancelable: false }
  //           );
  //         })
  //         .catch((error) => console.error(error));
  //     }
  //   };

  const convertDataToExcelFormat = () => {
    var excelData = [];

    //var stringPoItems = poItems.toString();

    for (var i = 0; i < purchaseOrders.length; i++) {
      var excelRow = {
        "Purchase Order ID": purchaseOrders[i].poId,
        "Created Date": moment(purchaseOrders[i].orderDate).format(
          "DD/MM/YYYY"
        ),
        "Estimated Time": moment(purchaseOrders[i].estimatedArrivalDate).format(
          "DD/MM/YYYY"
        ),
        "Target Store": purchaseOrders[i].outletName,
        Supplier: purchaseOrders[i].supplierName,
        'Total Purchase Amount': +parseFloat(purchaseOrders[i].totalPrice).toFixed(2),
        Status: purchaseOrders[i].status,
        "Purchase Item": purchaseOrders[i].poItems
          .map((item) => `${item.name}`)
          .join(","),
        "Current Quantity": purchaseOrders[i].poItems
          .map((item) => item.quantity)
          .join(","),
        "Ordered Quantity": purchaseOrders[i].poItems
          .map((item) => item.orderQuantity)
          .join(","),
          'Total Item Price': +parseFloat(purchaseOrders[i].poItems.map(item => item.totalPrice).join(',')).toFixed(2),
      };

      excelData.push(excelRow);
    }

    console.log("excelData");
    console.log(excelData);

    return excelData;
  };

  //   const downloadExcel = () => {
  //     const excelData = convertDataToExcelFormat();

  //     var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/koodoo-Purchase-Orders${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
  //     var excelWorkSheet = XLSX.utils.json_to_sheet(excelData);
  //     var excelWorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, "Purchase Orders");

  //     const workBookData = XLSX.write(excelWorkBook, {
  //       type: 'binary',
  //       bookType: 'xlsx',
  //     });

  //     RNFS.writeFile(excelFile, workBookData, 'ascii')
  //       .then((success) => {
  //         console.log(`wrote file ${excelFile}`);

  //         Alert.alert(
  //           'Success',
  //           `Send to ${excelFile}`,
  //           [{ text: 'OK', onPress: () => { } }],
  //           { cancelable: false },
  //         );
  //       })
  //       .catch((err) => {
  //         console.log(err.message);
  //       });
  //   }

  const getStockOrder = () => {
    // ApiClient.GET(API.getStockOrder + outletId).then((result) => {
    //   setState({ orderList: result })
    // });
  };

  // function here
  const showDateTimePicker = () => {
    // setState({ isDateTimePickerVisible: true });
    setIsDateTimePickerVisible(true);
  };

  const hideDateTimePicker = () => {
    // setState({ isDateTimePickerVisible: false });
    setIsDateTimePickerVisible(false);
  };

  const handleDatePicked = (date) => {
    // setState({ date: date.toString() });
    setDate(date);
  };

  const renderOrderItem = ({ item }) => {
    console.log("renderOrderItem");
    console.log(item);

    return (
      <TouchableOpacity
        onPress={() => {
          // setState({
          //   lowStockAlert: false,
          //   purchaseOrder: false,
          //   stockTransfer: false,
          //   stockTake: false,
          //   addPurchase: false,
          //   editPurchase: true,
          //   addStockTransfer: false,
          //   addStockTake: false,
          // });

          // disable first
          // setEditPurchase(true);
          // setPurchaseOrder(false);

          CommonStore.update((s) => {
            s.selectedPurchaseOrderEdit = item;
          });

          setPurchaseOrder(false);
          setAddPurchase(true);
        }}
      >
        <View
          style={{
            backgroundColor: "#ffffff",
            flexDirection: "row",
            paddingVertical: 20,
            paddingHorizontal: 7,
            paddingRight: 2,
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: "#c4c4c4",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 14,
              fontFamily: "NunitoSans-Regular",
              width: "16%",
              marginRight: 2,
              color: Colors.primaryColor,
              left: 20,
            }}
          >
            {`PO${item.poId}`}
          </Text>
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 14,
              fontFamily: "NunitoSans-Regular",
              width: "11%",
              marginHorizontal: 2,
            }}
          >
            {moment(item.orderDate).format("DD MMM YYYY")}
          </Text>
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 14,
              fontFamily: "NunitoSans-Regular",
              width: "11%",
              marginHorizontal: 2,
            }}
          >
            {moment(item.estimatedArrivalDate).format("DD MMM YYYY")}
          </Text>
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 14,
              fontFamily: "NunitoSans-Regular",
              width: "16%",
              marginHorizontal: 2,
            }}
          >
            {item.outletName}
          </Text>
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 14,
              fontFamily: "NunitoSans-Regular",
              width: "15.5%",
              marginHorizontal: 4,
            }}
          >
            {item.supplierName}
          </Text>
          <Text
            style={{
              fontSize: switchMerchant ? 10 : 14,
              fontFamily: "NunitoSans-Regular",
              width: "10.5%",
              marginHorizontal: 2,
            }}
          >
            RM
            {item.poItems
              .reduce((accum, poItem) => accum + poItem.totalPrice, 0)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          <View
            style={{
              width: switchMerchant ? "12%" : "14%",
              marginLeft: 2,
            }}
          >
            <View
              style={{
                borderRadius: 10,
                //borderWidth: 1,
                //borderColor: '#E5E5E5',
                width: switchMerchant ? 70 : 120,
                height: 30,
                alignItems: "center",
                justifyContent: "center",
                //paddingHorizontal: 10,
                backgroundColor:
                  item.status == 0
                    ? "#dedede"
                    : item.status == 1
                    ? "#969696"
                    : item.status == 2
                    ? Colors.secondaryColor
                    : Colors.primaryColor,
              }}
            >
              <Text
                style={{
                  fontSize: switchMerchant ? 10 : 14,
                  fontFamily: "NunitoSans-Regular",
                  color:
                    item.status == 0
                      ? Colors.blackColor
                      : item.status == 1
                      ? Colors.whiteColor
                      : item.status == 2
                      ? Colors.blackColor
                      : Colors.whiteColor,
                }}
              >
                {/* {item.status == 0 ? "Fail" : item.status == 1 ? "In Progress" : item.status == 2 ? "Arrived" : "Completed"} */}
                {PURCHASE_ORDER_STATUS_PARSED[item.status]}
                {/* Partially */}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "2%",
              marginLeft: 2,
              // backgroundColor: 'red'
            }}
          >
            <View>
              {expandThreeDots[item.uniqueId] == true ? (
                <View
                  style={{
                    position: "absolute",
                    width: 110,
                    //height: 50,
                    marginLeft: -110,
                    paddingVertical: 10,
                    zIndex: 1,
                    flexDirection: "column",
                    backgroundColor: "#FFFFFF",
                    borderWidth: 1,
                    //borderColor: '#E7E7E7',
                    borderColor: Colors.highlightColor,
                    borderRadius: 7,
                    shadowOpacity: 0,
                    shadowColor: "#000",
                    shadowOffset: {
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 2,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      duplicatePurchaseOrder(item);
                    }}
                  >
                    <View style={{ width: "30%", paddingLeft: 10 }}>
                      <MaterialIcons
                        name="content-copy"
                        size={switchMerchant ? 15 : 17}
                        color="grey"
                      />
                    </View>
                    <View style={{ width: "70%" }}>
                      <Text
                        style={{
                          marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                        }}
                      >
                        Duplicate
                      </Text>
                    </View>
                  </TouchableOpacity>

                  {/* <View style={{ borderWidth: 1, borderColor: Colors.fieldtBgColor }} /> */}
                </View>
              ) : null}
            </View>

            <View
              style={{
                flexDirection: "row",
                // backgroundColor: 'red',
              }}
            >
              <TouchableOpacity
                style={{
                  marginTop: 2,
                  alignSelf: "flex-start",
                  alignItems: "flex-start",
                }}
                onPress={() => expandThreeDotsFunc(item)}
              >
                <Entypo
                  name="dots-three-vertical"
                  size={switchMerchant ? 20 : 25}
                  color={Colors.tabGrey}
                  style={{ alignSelf: "flex-start" }}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const renderAddPO = ({ item, index }) => {
    // console.log('poItems[index]');
    // console.log(poItems[index]);
    // console.log('supplyItemDropdownList');
    // console.log(supplyItemDropdownList);

    var isValidToRender = false;

    for (var i = 0; i < supplyItemDropdownList.length; i++) {
      if (supplyItemDropdownList[i].value === item.supplyItemId) {
        isValidToRender = true;
        break;
      }
    }

    // becoz poitems don't store supplier id
    if (
      isValidToRender
      // supplyItemsDict[poItems[index].supplyItemId].supplierId === selectedSupplierId
      // true
    ) {
      return (
        <View
          style={{
            backgroundColor: "#ffffff",
            flexDirection: "row",
            paddingVertical: 20,
            paddingLeft: 10,
            paddingRight: 5,
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: "#c4c4c4",
            alignItems: "center",
            width: "100%",
            zIndex: -1,
            // height: (Dimensions.get('window').width * 0.1) * 3,
          }}
        >
          {supplyItemsDict[item.supplyItemId] &&
          supplyItemsDict[item.supplyItemId].image ? (
            <AsyncImage
              source={{ uri: supplyItemsDict[item.supplyItemId].image }}
              item={supplyItemsDict[item.supplyItemId]}
              // hideLoading={true}
              style={{
                width: switchMerchant ? 40 : 45,
                height: switchMerchant ? 40 : 45,
                borderWidth: 1,
                borderColor: "#E5E5E5",
                borderRadius: 5,
              }}
            />
          ) : (
            <AsyncImage
              source={{
                uri: "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png?w=640",
              }}
              style={{
                width: switchMerchant ? 40 : 45,
                height: switchMerchant ? 40 : 45,
                borderWidth: 1,
                borderColor: "#E5E5E5",
                borderRadius: 5,
              }}
            />
          )}
          {/* <Text style={{width: '1%'}}></Text> */}
          <View
            style={{ width: "16%", zIndex: 1, marginLeft: 10, marginRight: 1 }}
          >
            <View
              style={
                selectedPurchaseOrderEdit
                  ? {
                      width: "80%",
                      zIndex: 1,
                      height: 35,
                      //borderRadius: 5,
                      justifyContent: "center",
                      backgroundColor: "white",
                      //paddingHorizontal: 10,
                      //borderWidth: 1,
                      //borderColor: '#E5E5E5',
                    }
                  : {
                      width: "80%",
                      zIndex: 1,
                      height: 35,
                      borderRadius: 5,
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingHorizontal: 10,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 2,
                    }
              }
            >
              {
                !selectedPurchaseOrderEdit ? (
                  <Picker
                    selectedValue={
                      poItems[index].supplyItemId
                        ? poItems[index].supplyItemId
                        : ""
                    }
                    style={{}}
                    onValueChange={(value, text) => {
                      setPoItems(
                        poItems.map((poItem, i) =>
                          i === index
                            ? {
                                ...poItem,
                                supplyItemId: value,
                                image: supplyItemsDict[value].image,
                                sku: supplyItemsDict[value].sku,
                                quantity: outletSupplyItemsSkuDict[
                                  supplyItemsDict[value].sku
                                ]
                                  ? outletSupplyItemsSkuDict[
                                      supplyItemsDict[value].sku
                                    ].quantity
                                  : 0,
                                orderQuantity: 0,
                                unit: supplyItemsDict[value].unit,
                                receivedQuantity: 0,
                                price: supplyItemsDict[value].price,
                                totalPrice: 0,

                                lastReceivedQuantity: 0,
                                supplyItem: supplyItemsDict[value],
                              }
                            : poItem
                        )
                      );
                    }}
                  >
                    {supplyItemDropdownList.map((value, index) => {
                      return (
                        <Picker.Item
                          key={index}
                          label={value.label}
                          value={value.value}
                        />
                      );
                    })}
                  </Picker>
                ) : (
                  // <RNPickerSelect
                  //   disabled={selectedPurchaseOrderEdit ? true : false}
                  //   useNativeAndroidPickerStyle={false}
                  //   //pickerProps={{ style: { height: 160, overflow: 'hidden',} }}
                  //   style={{
                  //     inputIOS: { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, paddingVertical: 5 },
                  //     inputAndroid: { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14, paddingVertical: 5 },
                  //     inputAndroidContainer: {
                  //       //backgroundColor: 'red',
                  //       width: '100%',
                  //     }
                  //   }}
                  //   //contentContainerStyle={{ fontSize: switchMerchant ? 10 : 14, }}
                  //   items={supplyItemDropdownList}
                  //   value={
                  //     poItems[index].supplyItemId ? poItems[index].supplyItemId : ''
                  //   }
                  //   onValueChange={(value) =>
                  //     setPoItems(poItems.map((poItem, i) => (i === index ? {
                  //       ...poItem,
                  //       supplyItemId: value,
                  //       image: supplyItemsDict[value].image,
                  //       sku: supplyItemsDict[value].sku,
                  //       quantity: outletSupplyItemsSkuDict[supplyItemsDict[value].sku] ? outletSupplyItemsSkuDict[supplyItemsDict[value].sku].quantity : 0,
                  //       orderQuantity: 0,
                  //       unit: supplyItemsDict[value].unit,
                  //       receivedQuantity: 0,
                  //       price: supplyItemsDict[value].price,
                  //       totalPrice: 0,

                  //       lastReceivedQuantity: 0,
                  //       supplyItem: supplyItemsDict[value],
                  //     } : poItem)))
                  //   }
                  // />
                  <Text
                    style={{
                      color: "#8f8f8f",
                      marginLeft: 0,
                      marginRight: 0,
                      fontFamily: "NunitoSans-Regular",
                      fontSize: switchMerchant ? 10 : 14,
                    }}
                  >
                    {supplyItemsDict[poItems[index].supplyItemId]
                      ? supplyItemsDict[poItems[index].supplyItemId].name
                      : "N/A"}
                  </Text>
                )
                // <RNPickerSelect
                //   disabled={selectedPurchaseOrderEdit ? true : false}
                //   items={supplyItemDropdownList}
                //   textInputProps={{ color: Colors.descriptionColor }}
                //   // useNativeAndroidPickerStyle={false}
                //   style={Styles.rnPickerSelectStyle}
                //   value={
                //     poItems[index].supplyItemId ? poItems[index].supplyItemId : ''
                //   }
                //   onValueChange={(value) =>
                //     setPoItems(poItems.map((poItem, i) => (i === index ? {
                //       ...poItem,
                //       supplyItemId: value,
                //       image: supplyItemsDict[value].image,
                //       sku: supplyItemsDict[value].sku,
                //       quantity: outletSupplyItemsSkuDict[supplyItemsDict[value].sku] ? outletSupplyItemsSkuDict[supplyItemsDict[value].sku].quantity : 0,
                //       orderQuantity: 0,
                //       unit: supplyItemsDict[value].unit,
                //       receivedQuantity: 0,
                //       price: supplyItemsDict[value].price,
                //       totalPrice: 0,

                //       lastReceivedQuantity: 0,
                //       supplyItem: supplyItemsDict[value],
                //     } : poItem)))
                //   }
                // />
              }
            </View>
          </View>
          {/* <Text style={{ width: '2%', color: '#8f8f8f' }}></Text> */}
          <Text
            style={{
              width: "10%",
              color: "#8f8f8f",
              marginLeft: 5,
              marginRight: 1,
              fontFamily: "NunitoSans-Regular",
              fontSize: switchMerchant ? 10 : 14,
            }}
          >
            {poItems[index].skuMerchant || poItems[index].sku}
          </Text>
          <Text
            style={{
              width: "9%",
              color: "#8f8f8f",
              marginHorizontal: 3,
              fontFamily: "NunitoSans-Regular",
              fontSize: switchMerchant ? 10 : 14,
            }}
          >
            {/* {poItems[index].quantity} */}
            {outletSupplyItemsSkuDict[poItems[index].sku]
              ? outletSupplyItemsSkuDict[poItems[index].sku].quantity
              : 0}
          </Text>

          <View
            style={[
              {
                width: "11%",
                marginHorizontal: 3,
                // marginLeft: 50,
                // backgroundColor: 'blue',
              },
              windowWidth === 1280 && windowHeight === 800
                ? {
                    right: 5,
                  }
                : {},
            ]}
          >
            {!selectedPurchaseOrderEdit ? (
              <TextInput
                editable={selectedPurchaseOrderEdit ? false : true}
                underlineColorAndroid={Colors.fieldtBgColor}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: switchMerchant ? 50 : 60,
                  height: 35,
                  borderRadius: 5,
                  padding: 5,
                  marginVertical: 5,
                  borderWidth: 1,
                  borderColor: "#E5E5E5",
                  paddingLeft: 10,
                  fontFamily: "NunitoSans-Regular",
                  fontSize: switchMerchant ? 10 : 14,
                }}
                placeholder={"0"}
                placeholderStyle={{
                  fontFamily: "NunitoSans-Regular",
                  fontSize: switchMerchant ? 10 : 14,
                }}
                placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                keyboardType={"number-pad"}
                onChangeText={(text) => {
                  const amount = text.length > 0 ? parseInt(text) : 0;

                  setPoItems(
                    poItems.map((poItem, i) =>
                      i === index
                        ? {
                            ...poItem,
                            orderQuantity: amount,
                            totalPrice: amount * poItem.price,
                          }
                        : poItem
                    )
                  );
                }}
                defaultValue={
                  selectedPurchaseOrderEdit
                    ? poItems[index].orderQuantity.toFixed(0)
                    : 0
                }
              />
            ) : (
              <Text
                style={{
                  //backgroundColor: Colors.fieldtBgColor,
                  width: switchMerchant ? 50 : 60,
                  //height: 35,
                  borderRadius: 5,
                  padding: 5,
                  marginVertical: 5,
                  //borderWidth: 1,
                  //borderColor: '#E5E5E5',
                  //paddingLeft:10,
                  color: "#8f8f8f",
                  fontFamily: "NunitoSans-Regular",
                  fontSize: switchMerchant ? 10 : 14,
                }}
              >
                {poItems[index].orderQuantity.toFixed(0)}
              </Text>
            )}
          </View>
          <Text
            style={{
              width: "7%",
              color: "#8f8f8f",
              marginHorizontal: 3,
              fontFamily: "NunitoSans-Regular",
              fontSize: switchMerchant ? 10 : 14,
            }}
          >
            {poItems[index].unit}
          </Text>

          {/* <Text style={{ width: '14%', marginLeft: 50, color: '#8f8f8f' }}>50</Text> */}

          {/* <View style={{
          width: '14%',
          marginLeft: 30,
          marginRight: 30,
          // backgroundColor: 'red',
        }}>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              height: 40,
              width: 100,
              paddingHorizontal: 20,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 10,
            }}
            placeholder={'50'}
            keyboardType={'number-pad'}
            // placeholder={itemName}
            // onChangeText={(text) => {
            //   // setState({ itemName: text });
            //   setPoItems(poItems.map((poItem, i) => (i === index ? {
            //     ...poItem,
            //     orderQuantity: parseInt(text),
            //     totalPrice: parseInt(text) * poItem.price,
            //   } : poItem)))
            // }}
            value={poItems[index].receivedQuantity}
          // ref={myTextInput}
          />
        </View> */}

          {!selectedPurchaseOrderEdit ||
          poStatus === PURCHASE_ORDER_STATUS.COMPLETED ? (
            <Text
              style={{
                width: "11%",
                marginHorizontal: 3,
                color: "#8f8f8f",
                fontFamily: "NunitoSans-Regular",
                fontSize: switchMerchant ? 10 : 14,
              }}
            >
              {poItems[index].orderQuantity}
            </Text>
          ) : (
            <View
              style={{
                width: "11%",
                marginHorizontal: 3,
              }}
            >
              <TextInput
                underlineColorAndroid={Colors.fieldtBgColor}
                style={{
                  backgroundColor: Colors.fieldtBgColor,
                  width: 60,
                  height: 35,
                  borderRadius: 5,
                  padding: 5,
                  marginVertical: 5,
                  borderWidth: 1,
                  borderColor: "#E5E5E5",
                  paddingLeft: 10,
                  fontFamily: "NunitoSans-Regular",
                  fontSize: switchMerchant ? 10 : 14,
                }}
                placeholder={"50"}
                placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                placeholderStyle={{
                  fontFamily: "NunitoSans-Regular",
                  fontSize: switchMerchant ? 10 : 14,
                }}
                keyboardType={"number-pad"}
                // placeholder={itemName}
                onChangeText={(text) => {
                  // setState({ itemName: text });
                  setPoItems(
                    poItems.map((poItem, i) =>
                      i === index
                        ? {
                            ...poItem,
                            receivedQuantity:
                              text.length > 0 ? parseInt(text) : 0,
                          }
                        : poItem
                    )
                  );
                }}
                defaultValue={poItems[index].receivedQuantity.toFixed(0)}
                // ref={myTextInput}
              />
            </View>
          )}
          <View
            style={{
              width: switchMerchant ? "8%" : "8%",
              flexDirection: "row",
              justifyContent: "space-between",
              marginHorizontal: 3,
            }}
          >
            <Text
              style={{
                color: "#8f8f8f",
                fontFamily: "NunitoSans-Regular",
                fontSize: switchMerchant ? 10 : 14,
              }}
            >{`RM `}</Text>
            <Text
              style={{
                color: "#8f8f8f",
                fontFamily: "NunitoSans-Regular",
                fontSize: switchMerchant ? 10 : 14,
                paddingRight: "2%",
              }}
            >
              {poItems[index].price
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Text>
          </View>
          <View style={{ width: switchMerchant ? "3.5%" : "4.8%" }} />
          {/* <Text style={{ width: switchMerchant ? '12%' : '13%', color: '#8f8f8f', marginHorizontal: 3, fontSize: switchMerchant ? 10 : 14 }}>{`RM${poItems[index].price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</Text> */}
          <View
            style={{
              width: switchMerchant ? "8%" : "8%",
              flexDirection: "row",
              justifyContent: "space-between",
              marginHorizontal: 3,
            }}
          >
            <Text
              style={{
                color: "#8f8f8f",
                fontFamily: "NunitoSans-Regular",
                fontSize: switchMerchant ? 10 : 14,
              }}
            >{`RM `}</Text>
            <Text
              style={{
                color: "#8f8f8f",
                fontFamily: "NunitoSans-Regular",
                fontSize: switchMerchant ? 10 : 14,
                paddingRight: "2%",
              }}
            >
              {poItems[index].totalPrice
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Text>
          </View>
          {/* {!selectedPurchaseOrderEdit ?
            <TouchableOpacity style={{ marginLeft: 10 }}
              onPress={() => {
                setPoItems([
                  ...poItems.slice(0, index),
                  ...poItems.slice(index + 1),
                ]);
              }}>
              <Icon name="trash-2" size={20} color="#eb3446" />
            </TouchableOpacity>
            : <></>
          } */}
          {selectedPurchaseOrderEdit ? (
            <></>
          ) : (
            <TouchableOpacity
              style={{ marginLeft: switchMerchant ? 5 : 10 }}
              disabled={poStatus === PURCHASE_ORDER_STATUS.COMPLETED}
              onPress={() => {
                setPoItems([
                  ...poItems.slice(0, index),
                  ...poItems.slice(index + 1),
                ]);
              }}
            >
              <Icon
                name="trash-2"
                size={switchMerchant ? 15 : 20}
                color="#eb3446"
              />
            </TouchableOpacity>
          )}
        </View>
      );
    }
  };

  const email = () => {
    var body = {
      stockTransferId: 1,
      email: Email,
    };
    // ApiClient.POST(API.emailStockTransfer, body, false).then((result) => {

    //   if (result == true) {

    //     Alert.alert(
    //       'Success',
    //       'The email had sent',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  // const createStockOrder = () => {
  //   var body = {
  //     poId: poId,
  //     poItems: poItems,
  //     supplierId: selectedSupplierId,
  //     status: poStatus,
  //     outletId: selectedTargetOutletId,
  //     tax: taxTotal,
  //     discount: discountTotal,
  //     totalPrice: subtotal,
  //     finalTotal: finalTotal,
  //     estimatedArrivalDate: date,

  //     merchantId: merchantId,
  //     remarks: '',
  //   };

  //   ApiClient.POST(API.createPurchaseOrder, body).then((result) => {
  //     if (result && result.uniqueId) {
  //       Alert.alert(
  //         'Success',
  //         'Purchase order created successfully',
  //         [
  //           { text: "OK", onPress: () => { props.navigation.goBack() } }
  //         ],
  //         { cancelable: false },
  //       );
  //     }
  //   });
  // }

  const removePurchaseOrder = () => {
    for (var i = 0; i < poItems.length; i++) {
      var currItem = poItems[i];
    }
    console.log(currItem);
  };

  const createPurchaseOrder = () => {
    console.log("on createPurchaseOrder");

    CommonStore.update((s) => {
      s.isLoading = true;
    });

    const errorString = poItems
      .filter(
        (poItem) =>
          poItem.orderQuantity <= 0 &&
          poItem.supplyItem &&
          poItem.supplyItem.supplierId === selectedSupplierId
      )
      .map((poItem) => `${poItem.name.trim()}: ${poItem.orderQuantity}`)
      .join("\n");

    if (errorString.length > 0) {
      alert(`Following items amount are invalid: \n\n${errorString}`);
      CommonStore.update((s) => {
        s.isLoading = false;
      });
      setIsLoadingSave(false);
      setIsLoadingSaveAndSend(false);
      //   window.confirm(
      //     "Error",
      //     `Following items amount are invalid: \n\n${errorString}`,
      //     [
      //       {
      //         text: "OK",
      //         onPress: () => {
      //           setIsLoadingSave(false);
      //           setIsLoadingSaveAndSend(false);
      //         },
      //       },
      //     ]
      //   );
      return;
    }

    if (selectedPurchaseOrderEdit === null) {
      var poItemsValid = [];

      for (var index = 0; index < poItems.length; index++) {
        for (var i = 0; i < supplyItemDropdownList.length; i++) {
          if (
            supplyItemDropdownList[i].value === poItems[index].supplyItemId &&
            poItems[index].orderQuantity !== 0 &&
            poItems[index].supplyItem &&
            poItems[index].supplyItem.supplierId === selectedSupplierId
          ) {
            poItemsValid.push(poItems[index]);
            break;
          }
        }
      }

      if (poItemsValid.length > 0) {
        var body = {
          poId: poId,
          poItems: poItemsValid,
          supplierId: selectedSupplierId,
          status: poStatus,
          outletId: selectedTargetOutletId,
          tax: +parseFloat(taxTotal).toFixed(2),
          discount: +parseFloat(discountTotal).toFixed(2),
          totalPrice: +parseFloat(subtotal).toFixed(2),
          finalTotal: +parseFloat(finalTotal).toFixed(2),
          estimatedArrivalDate: moment(date).valueOf(),

          outletName: allOutlets.find(
            (outlet) => outlet.uniqueId === selectedTargetOutletId
          ).name,
          supplierName: suppliers.find(
            (supplier) => supplier.uniqueId === selectedSupplierId
          ).name,

          merchantId: merchantId,
          remarks: "",
        };

        console.log(body);

        //  for( var index = 0; index < poItems.length; index++) {
        //   for (var i = 0; i < supplyItemDropdownList.length; i++) {
        //     if( poItems[index].orderQuantity !== 2 ) {

        ApiClient.POST(API.createPurchaseOrder, body).then((result) => {
          if (result && result.status === "success") {
            let text = `Purchase order has been created`;
            if (window.confirm(text) == true) {
              CommonStore.update((s) => {
                s.isLoading = false;
              });
              setPurchaseOrder(true);
              setAddPurchase(false);
              setIsLoadingSave(false);
              setIsLoadingSaveAndSend(false);
            } else {
            }
            // Alert.alert(
            //   "Success",
            //   "Purchase order has been created",
            //   [
            //     {
            //       text: "OK",
            //       onPress: () => {
            //         setPurchaseOrder(true);
            //         setAddPurchase(false);
            //         setIsLoadingSave(false);
            //         setIsLoadingSaveAndSend(false);
            //       },
            //     },
            //   ],
            //   { cancelable: false }
            // );
          } else {
            // let text = `Failed to create Purchase order`;
            // if (window.confirm(text) == true) {
            //     CommonStore.update((s) => {
            //         s.isLoading = false;
            //       });
            //       setIsLoadingSave(false);
            //       setIsLoadingSaveAndSend(false);
            // } else {
            // }
            alert("Error", "Failed to create Purchase order");
            CommonStore.update((s) => {
              s.isLoading = false;
            });
            setIsLoadingSave(false);
            setIsLoadingSaveAndSend(false);
          }
        });
      } else {
        alert("Error", "Please add at least one supply item.");
        setIsLoadingSave(false);
        setIsLoadingSaveAndSend(false);

        CommonStore.update((s) => {
          s.isLoading = false;
        });
      }
    } else {
      var body = {
        poId: poId,
        poItems: poItems,
        supplierId: selectedSupplierId,
        status: poStatus,
        outletId: selectedTargetOutletId,
        tax: +parseFloat(taxTotal).toFixed(2),
        discount: +parseFloat(discountTotal).toFixed(2),
        totalPrice: +parseFloat(subtotal).toFixed(2),
        finalTotal: +parseFloat(finalTotal).toFixed(2),
        estimatedArrivalDate: moment(date).valueOf(),

        outletName: allOutlets.find(
          (outlet) => outlet.uniqueId === selectedTargetOutletId
        ).name,
        supplierName: suppliers.find(
          (supplier) => supplier.uniqueId === selectedSupplierId
        ).name,

        merchantId: merchantId,
        remarks: "",

        uniqueId: selectedPurchaseOrderEdit.uniqueId,
      };

      console.log(body);

      var isValidReceivedQuantity = true;

      // check if the inputed received quantity of poItems valid or not

      for (var i = 0; i < poItems.length; i++) {
        if (
          poItems[i].receivedQuantity > poItems[i].lastReceivedQuantity &&
          poItems[i].receivedQuantity <= poItems[i].orderQuantity
        ) {
          // means valid
        } else if (poItems[i].receivedQuantity === 0) {
          // means valid
        } else {
          isValidReceivedQuantity = false;
          break;
        }
      }

      if (isValidReceivedQuantity) {
        ApiClient.POST(API.updatePurchaseOrder, body).then((result) => {
          if (result && result.status === "success") {
            let text = `Purchase order has been updated`;
            if (window.confirm(text) == true) {
              CommonStore.update((s) => {
                s.isLoading = false;
              });
              setPurchaseOrder(true);
              setAddPurchase(false);
              setIsLoadingSave(false);
              setIsLoadingSaveAndSend(false);
            } else {
            }
            // Alert.alert(
            //   "Success",
            //   "Purchase order has been updated",
            //   [
            //     {
            //       text: "OK",
            //       onPress: () => {
            //         setPurchaseOrder(true);
            //         setAddPurchase(false);
            //         setIsLoadingSave(false);
            //         setIsLoadingSaveAndSend(false);

            //         // props.navigation.goBack();
            //       },
            //     },
            //   ],
            //   { cancelable: false }
            // );
          } else {
            alert("Error", "Failed to update Purchase order");
            CommonStore.update((s) => {
              s.isLoading = false;
            });
            setIsLoadingSave(false);
            setIsLoadingSaveAndSend(false);
          }
        });
      } else {
        alert("Invalid received quantity");

        CommonStore.update((s) => {
          s.isLoading = false;
        });
        setIsLoadingSave(false);
        setIsLoadingSaveAndSend(false);
      }
    }
  };

  const duplicatePurchaseOrder = async (item) => {
    const body = {
      purchaseOrderId: item.uniqueId,
    };

    ApiClient.POST(API.duplicatePurchaseOrder, body).then((result) => {
      if (result && result.status === "success") {
        alert("Purchase order has been duplicated");

        expandThreeDotsFunc(item);
        setPurchaseOrder(false);
        setAddPurchase(true);

        CommonStore.update((s) => {
          s.selectedPurchaseOrderEdit = item;
        });
      } else {
        alert("Error", "Failed to duplicate Purchase order");
      }
    });
  };

  var titleName = selectedPurchaseOrderEdit ? editPO : createPO;
  // function end

  return (
    // <View style={styles.container}>
    //   <View style={styles.sidebar}>
    <View style={[styles.container]}>
      <View style={[styles.sidebar]}>
        <SideBar
          navigation={props.navigation}
          selectedTab={3}
          expandInventory={true}
        />
      </View>
      <ScrollView scrollEnabled={switchMerchant} horizontal={true}>
        <ScrollView
          contentContainerStyle={{
            //height: windowHeight,
            paddingBottom: switchMerchant && purchaseOrder ? 190 : 10,
          }}
          scrollEnabled={switchMerchant}
        >
          <View style={[styles.content, {}]}>
            <Modal
              style={
                {
                  // flex: 1
                }
              }
              supportedOrientations={["portrait", "landscape"]}
              visible={exportModal}
              transparent={true}
              animationType={"fade"}
            >
              <View
                style={{
                  flex: 1,
                  backgroundColor: Colors.modalBgColor,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    height: windowWidth * 0.3,
                    width: windowWidth * 0.4,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 12,
                    padding: windowWidth * 0.03,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TouchableOpacity
                    disabled={isLoading}
                    style={{
                      position: "absolute",
                      right: windowWidth * 0.02,
                      top: windowWidth * 0.02,

                      elevation: 1000,
                      zIndex: 1000,
                    }}
                    onPress={() => {
                      setExportModal(false);
                    }}
                  >
                    <AntDesign
                      name="closecircle"
                      size={25}
                      color={Colors.fieldtTxtColor}
                    />
                  </TouchableOpacity>
                  <View
                    style={{
                      alignItems: "center",
                      top: "20%",
                      position: "absolute",
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: "NunitoSans-Bold",
                        textAlign: "center",
                        fontSize: switchMerchant ? 18 : 20,
                      }}
                    >
                      Download Report
                    </Text>
                  </View>
                  <View style={{ top: switchMerchant ? "14%" : "10%" }}>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                      }}
                    >
                      Email Address:
                    </Text>
                    <TextInput
                      style={{
                        backgroundColor: Colors.fieldtBgColor,
                        width: switchMerchant ? 240 : 370,
                        height: switchMerchant ? 35 : 50,
                        borderRadius: 5,
                        padding: 5,
                        marginVertical: 5,
                        borderWidth: 1,
                        borderColor: "#E5E5E5",
                        paddingLeft: 10,
                        fontSize: switchMerchant ? 10 : 16,
                      }}
                      placeholderStyle={{ padding: 5 }}
                      placeholder="Enter Your Email"
                      placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                      onChangeText={(text) => {
                        setExportEmail(text);
                      }}
                      value={exportEmail}
                    />
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: "NunitoSans-Bold",
                        marginTop: 15,
                      }}
                    >
                      Send As:
                    </Text>

                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        //top: '10%',
                        flexDirection: "row",
                        marginTop: 15,
                      }}
                    >
                      {/* <TouchableOpacity
                        disabled={isLoading}
                        style={{
                          justifyContent: "center",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          width: switchMerchant ? 100 : 120,
                          paddingHorizontal: 10,
                          height: switchMerchant ? 35 : 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                        }}
                        onPress={() => {
                          if (exportEmail.length > 0) {
                            CommonStore.update((s) => {
                              s.isLoading = true;
                            });
                            setIsLoadingExcel(true);

                            const excelData = convertDataToExcelFormat();

                            generateEmailReport(
                              EMAIL_REPORT_TYPE.EXCEL,
                              excelData,
                              "KooDoo Purchase Order Report",
                              "KooDoo Purchase Order Report.xlsx",
                              `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                              exportEmail,
                              "KooDoo Purchase Order Report",
                              "KooDoo Purchase Order Report",
                              () => {
                                CommonStore.update((s) => {
                                  s.isLoading = false;
                                });
                                setIsLoadingExcel(false);
                                Alert.alert(
                                  "Success",
                                  "Report will be sent to the email address soon"
                                );

                                setExportModal(false);
                              }
                            );
                          } else {
                            Alert.alert("Info", "Invalid email address");
                          }
                        }}
                      >
                        {isLoadingExcel ? (
                          <ActivityIndicator
                            size={"small"}
                            color={Colors.whiteColor}
                          />
                        ) : (
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: switchMerchant ? 10 : 16,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            EXCEL
                          </Text>
                        )}
                      </TouchableOpacity> */}

<CSVLink
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "inline-block",
                        flexDirection: "row",
                        textDecoration: 'none',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: switchMerchant ? 100 : 100,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      data={convertDataToExcelFormat()}
                      filename="purchaseorder.csv"
                    >
                      <View
                        style={{
                          width: "100%",
                          height: "100%",
                          alignContent: "center",
                          alignItems: "center",
                          alignSelf: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          CSV
                        </Text>
                      </View>
                    </CSVLink>

                      {/* <TouchableOpacity
                        disabled={isLoading}
                        style={{
                          justifyContent: "center",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          width: switchMerchant ? 100 : 120,
                          paddingHorizontal: 10,
                          height: switchMerchant ? 35 : 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginLeft: 15,
                        }}
                        onPress={() => {
                          if (exportEmail.length > 0) {
                            CommonStore.update((s) => {
                              s.isLoading = true;
                            });
                            setIsLoadingCsv(true);
                            const csvData = convertArrayToCSV(purchaseOrders);

                            generateEmailReport(
                              EMAIL_REPORT_TYPE.CSV,
                              csvData,
                              "KooDoo Purchase Order Report",
                              "KooDoo Purchase Order Report.csv",
                              `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                              exportEmail,
                              "KooDoo Purchase Order Report",
                              "KooDoo Purchase Order Report",
                              () => {
                                CommonStore.update((s) => {
                                  s.isLoading = false;
                                });
                                setIsLoadingCsv(false);
                                Alert.alert(
                                  "Success",
                                  "Report will be sent to the email address soon"
                                );

                                setExportModal(false);
                              }
                            );
                          } else {
                            Alert.alert("Info", "Invalid email address");
                          }
                        }}
                      >
                        {isLoadingCsv ? (
                          <ActivityIndicator
                            size={"small"}
                            color={Colors.whiteColor}
                          />
                        ) : (
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              marginLeft: 5,
                              fontSize: switchMerchant ? 10 : 16,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            CSV
                          </Text>
                        )}
                      </TouchableOpacity> */}
                    </View>
                  </View>
                </View>
              </View>
            </Modal>

            <Modal
              style={
                {
                  // flex: 1
                }
              }
              supportedOrientations={["portrait", "landscape"]}
              visible={importModal}
              transparent={true}
              animationType={"fade"}
            >
              <View style={styles.modalContainer}>
                <View style={[styles.modalView, {}]}>
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={() => {
                      // setState({ changeTable: false });
                      setImportModal(false);
                    }}
                  >
                    <AntDesign
                      name="closecircle"
                      size={25}
                      color={Colors.fieldtTxtColor}
                    />
                  </TouchableOpacity>
                  <View style={styles.modalTitle}>
                    <Text style={styles.modalTitleText}>Upload Options</Text>
                  </View>
                  <View
                    style={{
                      alignItems: "center",
                      top: "10%",
                    }}
                  >
                    <TouchableOpacity
                      style={[
                        styles.modalSaveButton,
                        {
                          zIndex: -1,
                          flexDirection: "row",
                        },
                      ]}
                      //   onPress={() => { importTemplateData() }}
                      disabled={isLoading}
                    >
                      <Text
                        style={[
                          styles.modalDescText,
                          { color: Colors.primaryColor },
                        ]}
                      >
                        {isLoading ? "Loading..." : "Batch Upload"}
                      </Text>

                      {isLoading ? (
                        <ActivityIndicator
                          style={{
                            marginLeft: 5,
                          }}
                          color={Colors.primaryColor}
                          size={"small"}
                        />
                      ) : (
                        <></>
                      )}
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>

            {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: Platform.OS == 'ios' ? 0 : 10 }} >
            <View style={{ marginRight: 248, }}>
              <View style={{ flexDirection: 'row', flex: 1 }}>
                <TouchableOpacity style={[styles.submitText, {
                  height: windowHeight * 0.05,
                }]} onPress={() => { importCSV() }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Icon name="download" size={20} color={Colors.primaryColor} />
                    <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                      Import
                    </Text>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity style={[styles.submitText, {
                  height: 40,
                }]} onPress={() => { setExportModal(true) }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Icon name="download" size={20} color={Colors.primaryColor} />
                    <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                      Download
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View> */}
            {/* <TextInput
                editable={!loading}
                underlineColorAndroid={Colors.whiteColor}
                clearButtonMode="while-editing"
                style={styles.textInput}
                placeholder="🔍  Search"
                onChangeText={(text) => {
                  setState({ search: text.trim() });
                }}
                value={email}
              /> */}

            {/* <Ionicons
              name="search-outline"
              size={20}
              style={styles.searchIcon}
            />

            <TextInput
              editable={loading}
              clearButtonMode="while-editing"
              style={[styles.textInput, { fontFamily: "NunitoSans-Bold" }]}
              placeholder="Search"
              onChangeText={(text) => {
                setState({
                  search: text.trim(),
                });
              }}
              value={email}
            /> */}

            {/* <View
              style={[{
                // flex: 1,
                // alignContent: 'flex-end',
                // marginBottom: 10,
                // flexDirection: 'row',
                // marginRight: '-40%',
                // marginLeft: 310,
                // backgroundColor: 'red',
                // alignItems: 'flex-end',
                // right: '-50%',
                // width: '28%',
                height: 40,

              }, !isTablet ? {
                marginLeft: 0,
              } : {}]}>
              <View style={{
                width: 250,
                height: 40,
                backgroundColor: 'white',
                borderRadius: 10,
                // marginLeft: '53%',
                flexDirection: 'row',
                alignContent: 'center',
                alignItems: 'center',

                //marginRight: windowWidth * Styles.sideBarWidth,

                position: 'absolute',
                right: '35%',

                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 3,
              }}>
                <Icon name="search" size={18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />
                <TextInput
                  editable={!loading}
                  underlineColorAndroid={Colors.whiteColor}
                  style={{
                    width: 250,
                    fontSize: 15,
                    fontFamily: 'NunitoSans-Regular',
                  }}
                  clearButtonMode="while-editing"
                  placeholder=" Search"
                  onChangeText={(text) => {
                    setSearch(text.trim());
                    // setList1(false);
                    // setSearchList(true);
                  }}
                  value={search}
                />
              </View>
            </View> */}

            {/* </View> */}

            {purchaseOrder ? (
              // <ScrollView
              //   scrollEnabled={switchMerchant}
              //   horizontal={true}>
              // <ScrollView scrollEnabled={switchMerchant}>
              <View
                style={{
                  height: windowHeight * 0.73,
                }}
              >
                {/* <View> */}
                <View style={{}}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: windowWidth * 0.87,
                      alignItem: "center",
                      alignSelf: "center",
                      marginBottom: 10,
                      // paddingLeft: '1.5%',
                      // paddingRight: '1.5%',
                    }}
                  >
                    <View style={{ flexDirection: "column" }}>
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Text
                          style={{
                            //fontSize: 30,
                            fontSize: switchMerchant ? 20 : 26,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          {/* {stockList.length} */}
                          {purchaseOrders.length} Purchase Order
                        </Text>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row" }}>
                      <View style={{ flexDirection: "row" }}>
                        {/* {isTablet && ( */}
                        <View style={{ alignItem: "center" }}>
                          <TouchableOpacity
                            style={{
                              justifyContent: "center",
                              flexDirection: "row",
                              borderWidth: 1,
                              borderColor: Colors.primaryColor,
                              backgroundColor: "#4E9F7D",
                              borderRadius: 5,
                              //width: 160,
                              paddingHorizontal: 10,
                              height: switchMerchant ? 35 : 40,
                              alignItems: "center",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 1,
                              zIndex: -1,
                            }}
                            onPress={() => {
                              setExportModal(true);
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Icon
                                name="download"
                                size={switchMerchant ? 10 : 20}
                                color={Colors.whiteColor}
                              />
                              <Text
                                style={{
                                  color: Colors.whiteColor,
                                  marginLeft: 5,
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                }}
                              >
                                DOWNLOAD
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {/* )} */}
                      </View>

                      <View style={{ flexDirection: "row" }}>
                        {/* {isTablet && ( */}
                        <View style={{ alignSelf: "flex-start" }}>
                          <TouchableOpacity
                            style={{
                              justifyContent: "center",
                              flexDirection: "row",
                              borderWidth: 1,
                              borderColor: Colors.primaryColor,
                              backgroundColor: "#4E9F7D",
                              borderRadius: 5,
                              //width: 160,
                              paddingHorizontal: switchMerchant ? 5 : 10,
                              height: switchMerchant ? 35 : 40,
                              alignItems: "center",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 1,
                              zIndex: -1,
                              marginHorizontal: switchMerchant ? 10 : 15,
                            }}
                            onPress={() => {
                              //console.log(windowWidth)
                              console.log("NEW PO");
                              //console.log('hello123')
                              CommonStore.update((s) => {
                                s.selectedPurchaseOrderEdit = null;
                              });

                              setPurchaseOrder(false);
                              setAddPurchase(true);
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <AntDesign
                                name="pluscircle"
                                size={switchMerchant ? 10 : 20}
                                color={Colors.whiteColor}
                              />
                              <Text
                                style={{
                                  color: Colors.whiteColor,
                                  marginLeft: 5,
                                  fontSize: switchMerchant ? 10 : 16,
                                  fontFamily: "NunitoSans-Bold",
                                }}
                              >
                                PURCHASE ORDER
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                        {/* )} */}
                      </View>

                      <View style={{ flexDirection: "row" }}>
                        <View
                          style={[
                            {
                              height: switchMerchant ? 35 : 40,
                            },
                          ]}
                        >
                          <View
                            style={{
                              width: windowWidth <= 1024 ? 140 : 250,
                              height: switchMerchant ? 35 : 40,
                              backgroundColor: "white",
                              borderRadius: 5,
                              // marginLeft: '53%',
                              flexDirection: "row",
                              alignContent: "center",
                              alignItems: "center",
                              shadowColor: "#000",
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 3,
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                            }}
                          >
                            <Icon
                              name="search"
                              size={switchMerchant ? 13 : 18}
                              color={Colors.primaryColor}
                              style={{ marginLeft: 15 }}
                            />
                            <TextInput
                              editable={!loading}
                              underlineColorAndroid={Colors.whiteColor}
                              style={{
                                width: windowWidth <= 1024 ? 110 : 220,
                                fontSize: switchMerchant ? 10 : 16,
                                fontFamily: "NunitoSans-Regular",
                                paddingLeft: 5,
                                height: 45,
                              }}
                              clearButtonMode="while-editing"
                              placeholder="Search"
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              onChangeText={(text) => {
                                setSearch(text.trim());
                                // setList1(false);
                                // setSearchList(true);
                              }}
                              value={search}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>

                  {renderOrderItem ? (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 15,
                        width: windowWidth * 0.87,
                        alignSelf: "center",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          setFilterPurchaseOrder(
                            FILTER_PURCHASE_ORDER.PO_TODAY
                          );
                        }}
                        style={{
                          width: "24%",
                          height: switchMerchant ? 80 : 100,
                          backgroundColor: Colors.tabMint,
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingHorizontal: 30,
                          paddingVertical: 15,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 3,
                        }}
                      >
                        <View
                          style={{
                            marginTop: -15,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Bold",
                              color: Colors.whiteColor,
                              fontSize: switchMerchant ? 25 : 28,
                            }}
                          >
                            {purchaseOrderToday}
                          </Text>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Regular",
                              color: Colors.whiteColor,
                              fontSize: switchMerchant ? 10 : 13,
                              fontWeight: "500",
                            }}
                          >
                            PO Today
                          </Text>
                        </View>
                        <View>
                          <AntDesign
                            name="filetext1"
                            size={switchMerchant ? 42 : 50}
                            style={{ color: "#F7F7F7", opacity: 0.68 }}
                          />
                        </View>
                      </TouchableOpacity>

                      <TouchableOpacity
                        onPress={() => {
                          setFilterPurchaseOrder(
                            FILTER_PURCHASE_ORDER.PENDING_PO
                          );
                        }}
                        style={{
                          width: "24%",
                          height: switchMerchant ? 80 : 100,
                          backgroundColor: Colors.tabGold,
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingHorizontal: 30,
                          paddingVertical: 15,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 3,
                        }}
                      >
                        <View
                          style={{
                            marginTop: -15,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Bold",
                              color: Colors.whiteColor,
                              fontSize: switchMerchant ? 25 : 28,
                            }}
                          >
                            {purchaseOrderPending}
                          </Text>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Regular",
                              color: Colors.whiteColor,
                              fontSize: switchMerchant ? 10 : 13,
                              fontWeight: "500",
                            }}
                          >
                            Pending PO
                          </Text>
                        </View>
                        <View>
                          {/* <Coins height={70} width={70} opacity={1} /> */}
                          <AntDesign
                            name="file1"
                            size={switchMerchant ? 42 : 50}
                            style={{ color: "#F7F7F7", opacity: 0.68 }}
                          />
                          <Icon
                            name="loader"
                            size={switchMerchant ? 13 : 20}
                            style={{
                              color: "#F7F7F7",
                              opacity: 0.68,
                              position: "absolute",
                              top: 24,
                              left: 17,
                            }}
                          />
                        </View>
                      </TouchableOpacity>

                      <TouchableOpacity
                        onPress={() => {
                          setFilterPurchaseOrder(
                            FILTER_PURCHASE_ORDER.COMPLETED_PO
                          );
                        }}
                        style={{
                          width: "24%",
                          height: switchMerchant ? 80 : 100,
                          backgroundColor: Colors.tabCyan,
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingHorizontal: 30,
                          paddingVertical: 15,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 3,
                        }}
                      >
                        <View
                          style={{
                            marginTop: -15,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Bold",
                              color: Colors.whiteColor,
                              fontSize: switchMerchant ? 25 : 28,
                            }}
                          >
                            {purchaseOrderCompleted}
                          </Text>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Regular",
                              color: Colors.whiteColor,
                              fontSize: switchMerchant ? 10 : 13,
                              fontWeight: "500",
                            }}
                          >
                            Completed PO
                          </Text>
                        </View>
                        <View>
                          <AntDesign
                            name="file1"
                            size={switchMerchant ? 42 : 50}
                            style={{ color: "#F7F7F7", opacity: 0.68 }}
                          />
                          <Icon
                            name="check"
                            size={switchMerchant ? 17 : 23}
                            style={{
                              color: "#F7F7F7",
                              opacity: 0.68,
                              position: "absolute",
                              top: 24,
                              left: 17,
                            }}
                          />
                        </View>
                      </TouchableOpacity>

                      <TouchableOpacity
                        onPress={() => {
                          setFilterPurchaseOrder(
                            FILTER_PURCHASE_ORDER.CANCELLED_PO
                          );
                        }}
                        style={{
                          width: "24%",
                          height: switchMerchant ? 80 : 100,
                          backgroundColor: Colors.tabRed,
                          borderRadius: 10,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingHorizontal: 30,
                          paddingVertical: 15,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 3,
                        }}
                      >
                        <View
                          style={{
                            marginTop: -15,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Bold",
                              color: Colors.whiteColor,
                              fontSize: switchMerchant ? 25 : 28,
                            }}
                          >
                            {purchaseOrderCancelled}
                          </Text>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-Regular",
                              color: Colors.whiteColor,
                              fontSize: switchMerchant ? 10 : 13,
                              fontWeight: "500",
                            }}
                          >
                            Cancelled PO
                          </Text>
                        </View>
                        <View>
                          <AntDesign
                            name="file1"
                            size={switchMerchant ? 42 : 50}
                            style={{ color: "#F7F7F7", opacity: 0.68 }}
                          />
                          <Icon
                            name="x"
                            size={switchMerchant ? 17 : 23}
                            style={{
                              color: "#F7F7F7",
                              opacity: 0.6,
                              position: "absolute",
                              top: 24,
                              left: 17,
                            }}
                          />
                        </View>
                      </TouchableOpacity>
                    </View>
                  ) : null}

                  <View
                    style={
                      switchMerchant
                        ? {
                            backgroundColor: Colors.whiteColor,
                            width: windowWidth * 0.87,
                            height: windowHeight * 0.75,
                            marginTop: 20,
                            marginHorizontal: 30,
                            marginBottom: 30,
                            alignSelf: "center",
                            //borderRadius: 5,
                            shadowOpacity: 0,
                            shadowColor: "#000",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 3,
                            borderRadius: 5,
                          }
                        : {
                            backgroundColor: Colors.whiteColor,
                            //backgroundColor: 'red',
                            width: windowWidth * 0.87,
                            height: windowHeight * 0.6,
                            paddingBottom: 5,
                            marginTop: 20,
                            marginHorizontal: 30,
                            marginBottom: 30,
                            alignSelf: "center",
                            //borderRadius: 5,
                            shadowOpacity: 0,
                            shadowColor: "#000",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 3,
                            borderRadius: 5,
                          }
                    }
                  >
                    {filterPurchaseOrder ===
                    FILTER_PURCHASE_ORDER.ALL_PO ? null : (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                          marginLeft: 10,
                        }}
                      >
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 16 : 18,
                          }}
                        >
                          {filterPurchaseOrder ===
                          FILTER_PURCHASE_ORDER.PO_TODAY
                            ? "Purchase Order Today"
                            : filterPurchaseOrder ===
                              FILTER_PURCHASE_ORDER.PENDING_PO
                            ? "Pending Purchase Order"
                            : filterPurchaseOrder ===
                              FILTER_PURCHASE_ORDER.COMPLETED_PO
                            ? "Completed Purchase Order"
                            : filterPurchaseOrder ===
                              FILTER_PURCHASE_ORDER.CANCELLED_PO
                            ? "Cancelled Purchase Order"
                            : null}
                        </Text>
                        <TouchableOpacity
                          style={{
                            //marginTop: 10,
                            marginLeft: 10,
                            justifyContent: "center",
                            flexDirection: "row",
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: "#4E9F7D",
                            borderRadius: 5,
                            width: switchMerchant ? 90 : 120,
                            paddingHorizontal: 10,
                            height: switchMerchant ? 35 : 40,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                          }}
                          onPress={() => {
                            setFilterPurchaseOrder(
                              FILTER_PURCHASE_ORDER.ALL_PO
                            );
                          }}
                        >
                          <AntDesign
                            name="arrowleft"
                            size={switchMerchant ? 10 : 20}
                            color={Colors.whiteColor}
                          />
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              marginLeft: 5,
                              fontSize: switchMerchant ? 10 : 16,
                              fontFamily: "NunitoSans-Bold",
                              marginBottom: 2,
                            }}
                          >
                            Summary
                          </Text>
                        </TouchableOpacity>
                      </View>
                    )}

                    <View
                      style={{
                        backgroundColor: "#ffffff",
                        flexDirection: "row",
                        paddingVertical: 20,
                        //paddingTop: 10,
                        paddingHorizontal: 7,
                        paddingRight: 2,
                        //marginTop: 10,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                        width: "100%",
                        borderTopLeftRadius: 5,
                        borderTopRightRadius: 5,
                        borderRadius: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "16%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginRight: 2,
                          left: 20,
                        }}
                      >
                        PO ID
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "11%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 2,
                        }}
                      >
                        Created Date
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "11%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 2,
                        }}
                      >
                        Est Arrival
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "16%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 2,
                        }}
                      >
                        Target Store
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "15.5%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 4,
                        }}
                      >
                        Supplier
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "10.5%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginHorizontal: 2,
                        }}
                      >
                        Amount
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: switchMerchant ? "12%" : "14%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginLeft: 2,
                        }}
                      >
                        Status
                      </Text>
                      <Text
                        style={{
                          fontSize: switchMerchant ? 10 : 14,
                          width: "2%",
                          alignSelf: "center",
                          fontFamily: "NunitoSans-Bold",
                          marginLeft: 2,
                        }}
                      ></Text>
                    </View>
                    <FlatList
                      nestedScrollEnabled={true}
                      showsVerticalScrollIndicator={false}
                      data={purchaseOrders.filter((item) => {
                        if (search !== "") {
                          const searchLowerCase = search.toLowerCase();

                          return (
                            item.supplierName
                              .toLowerCase()
                              .includes(searchLowerCase) ||
                            item.outletName
                              .toLowerCase()
                              .includes(searchLowerCase)
                          );
                        } else if (
                          filterPurchaseOrder === FILTER_PURCHASE_ORDER.PO_TODAY
                        ) {
                          var currDay = moment(Date.now());

                          return moment(currDay).isSame(item.orderDate, "day");
                        } else if (
                          filterPurchaseOrder ===
                          FILTER_PURCHASE_ORDER.PENDING_PO
                        ) {
                          return (
                            item.status !== PURCHASE_ORDER_STATUS.COMPLETED
                          );
                        } else if (
                          filterPurchaseOrder ===
                          FILTER_PURCHASE_ORDER.COMPLETED_PO
                        ) {
                          return (
                            item.status === PURCHASE_ORDER_STATUS.COMPLETED
                          );
                        } else {
                          return true;
                        }
                      })}
                      // extraData={purchaseOrders.filter(item => {
                      //   if (search !== '') {
                      //     const searchLowerCase = search.toLowerCase();

                      //     return item.supplierName.toLowerCase().includes(searchLowerCase) || item.outletName.toLowerCase().includes(searchLowerCase);
                      //   }
                      //   else {
                      //     return true;
                      //   }
                      // })}
                      renderItem={renderOrderItem}
                      keyExtractor={(item, index) => String(index)}
                      style={{ width: "100%", borderRadius: 5 }}
                      contentContainerStyle={{ borderRadius: 5 }}
                    />
                  </View>
                </View>
              </View>
            ) : // </ScrollView>
            // </ScrollView>
            null}

            {addPurchase ? (
              <View
                style={{
                  height: windowHeight * 0.76,
                }}
              >
                <View
                  style={{
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 1,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                  }}
                >
                  <Modal
                    supportedOrientations={["portrait", "landscape"]}
                    style={{ flex: 1 }}
                    visible={visible}
                    transparent={true}
                    animationType="slide"
                  >
                    <View
                      style={{
                        backgroundColor: "rgba(0,0,0,0.5)",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: Dimensions.get("window").height,
                      }}
                    >
                      <View style={styles.confirmBox}>
                        <TouchableOpacity
                          onPress={() => {
                            setState({ visible: false });
                          }}
                        >
                          <View
                            style={{
                              alignSelf: "flex-end",
                              padding: 16,
                            }}
                          >
                            {/* <Close name="closecircle" size={25} /> */}
                            <AntDesign
                              name="closecircle"
                              size={25}
                              color={Colors.fieldtTxtColor}
                            />
                          </View>
                        </TouchableOpacity>
                        <View>
                          <Text
                            style={{
                              textAlign: "center",
                              fontWeight: "700",
                              fontSize: 28,
                            }}
                          >
                            Purchase Order
                          </Text>
                        </View>
                        <View style={{ marginTop: 20 }}>
                          <Text
                            style={{
                              textAlign: "center",
                              color: Colors.descriptionColor,
                              fontSize: 25,
                            }}
                          >
                            Fill in the email information
                          </Text>
                        </View>
                        <View
                          style={{
                            backgroundColor: "white",
                            alignSelf: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Text style={{ fontSize: 20, marginTop: 70 }}>
                            Email:
                          </Text>
                          <View
                            style={{
                              marginTop: 60,
                              backgroundColor: "#f7f5f5",
                              marginLeft: 10,
                            }}
                          >
                            <TextInput
                              editable={!loading}
                              underlineColorAndroid={Colors.fieldtBgColor}
                              clearButtonMode="while-editing"
                              style={styles.textCapacity}
                              placeholder="myburgerlabmytown@gmail.com"
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              onChangeText={(text) => {
                                setState({ Email: text });
                              }}
                              value={Email}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            alignSelf: "center",
                            marginTop: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            width: "50%",
                            alignContent: "center",
                            zIndex: 6000,
                          }}
                        ></View>
                        <View
                          style={{
                            alignSelf: "center",
                            marginTop: 20,
                            justifyContent: "center",
                            alignItems: "center",
                            width: 250,
                            height: 40,
                            alignContent: "center",
                            flexDirection: "row",
                            marginTop: 40,
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              email();
                              setState({ visible: false });
                            }}
                            style={{
                              backgroundColor: Colors.fieldtBgColor,
                              width: "60%",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                              borderRadius: 10,
                              height: 60,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 28,
                                color: Colors.primaryColor,
                              }}
                            >
                              Send
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              setState({ visible: false });
                            }}
                            style={{
                              backgroundColor: Colors.fieldtBgColor,
                              width: "60%",
                              justifyContent: "center",
                              alignItems: "center",
                              alignContent: "center",
                              borderRadius: 10,
                              height: 60,
                              marginLeft: 30,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 28,
                                color: Colors.primaryColor,
                              }}
                            >
                              No
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </Modal>

                  <View
                    style={{
                      width: windowWidth * 0.886,
                      marginTop: switchMerchant ? -10 : 10,
                      alignSelf: "center",
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                      onPress={() => {
                        setPurchaseOrder(true);
                        setAddPurchase(false);
                      }}
                    >
                      <Icon
                        name="chevron-left"
                        size={switchMerchant ? 20 : 30}
                        color={Colors.primaryColor}
                      />
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 15 : 17,
                          color: Colors.primaryColor,
                          marginBottom: 2,
                        }}
                      >
                        Back
                      </Text>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={{
                      backgroundColor: Colors.whiteColor,
                      width: windowWidth * 0.87,
                      height: windowHeight * 0.85,
                      marginTop: switchMerchant ? 5 : 10,
                      marginHorizontal: 30,
                      marginBottom: 30,
                      alignSelf: "center",
                      //borderRadius: 5,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                      borderRadius: 5,
                    }}
                  >
                    {/* <ScrollView horizontal={true}
                  contentContainerStyle={{
                    width: switchMerchant ? windowWidth * 1 : windowWidth * 0.87,
                  }}
                > */}
                    <ScrollView
                      nestedScrollEnabled={true}
                      showsVerticalScrollIndicator={false}
                      style={{
                        // backgroundColor: 'red',
                        // borderRadius: 10,
                        // marginTop: 10,
                        paddingTop: 20,
                      }}
                      // contentContainerStyle={{
                      //   top: Platform.OS === 'ios' ? -keyboardHeight * 0.3 : 0,
                      // }}
                    >
                      {/* <View style={{ width: 140, marginRight: 10, marginTop: 10, alignSelf: "flex-end" }}>
                    <DropDownPicker
                      items={[
                        {
                          label: '🖨️  Print P.O',
                          value: 'Print P.O',
                        },
                        {
                          label: '📧  Email P.O',
                          value: 'Chicken',
                        },
                        {
                          label: '📤  Export Labels',
                          value: 'Export Labels',
                        },
                        {
                          label: '❌  Cancel P.O',
                          value: 'Cancel P.O',
                        },
                        {
                          label: '🗑️  Delete P.O',
                          value: 'Delete P.O',
                        },
                      ]}
                      defaultValue={choice2}
                      placeholder=""
                      containerStyle={{ height: 30 }}
                      style={{ backgroundColor: '#FAFAFA' }}
                      itemStyle={{
                        justifyContent: 'flex-start',
                      }}
                      dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                      onChangeItem={(item) =>
                        setState({
                          choice2: item.value,
                        })
                      }
                    />
                  </View> */}
                      <View
                        style={{ borderBottomWidth: StyleSheet.hairlineWidth }}
                      >
                        <View style={{ zIndex: -1 }}>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              zIndex: -1,
                            }}
                          >
                            <View
                              style={{
                                width: 140,
                                paddingLeft: 10,
                                alignSelf: "center",
                                height: 35,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                justifyContent: "center",
                                opacity: 0,
                              }}
                            >
                              <Picker
                                selectedValue={choice2}
                                style={{}}
                                onValueChange={(value, text) => {
                                  setState({
                                    choice2: value.value,
                                  });
                                }}
                              >
                                {dropdownItems.map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker>

                              {/* <RNPickerSelect
                                items={[
                                  {
                                    label: "🖨️  Print P.O",
                                    value: "Print P.O",
                                  },
                                  {
                                    label: "📧  Email P.O",
                                    value: "Chicken",
                                  },
                                  {
                                    label: "📤  Export Labels",
                                    value: "Export Labels",
                                  },
                                  {
                                    label: "❌  Cancel P.O",
                                    value: "Cancel P.O",
                                  },
                                  {
                                    label: "🗑️  Delete P.O",
                                    value: "Delete P.O",
                                  },
                                ]}
                                defaultValue={choice2}
                                placeholder={{}}
                                containerStyle={{ height: 30 }}
                                style={{
                                  backgroundColor: "#FAFAFA",
                                }}
                                itemStyle={{
                                  justifyContent: "flex-start",
                                }}
                                dropDownStyle={{ backgroundColor: "#FAFAFA" }}
                                onValueChange={(item) =>
                                  setState({
                                    choice2: item.value,
                                  })
                                }
                              /> */}
                            </View>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={{
                                  alignSelf: "center",
                                  marginTop: 20,
                                  fontSize: switchMerchant ? 20 : 40,
                                  fontWeight: "bold",
                                }}
                              >
                                {titleName}
                              </Text>
                              <Text
                                style={{
                                  alignSelf: "center",
                                  fontSize: switchMerchant ? 10 : 16,
                                  color: "#adadad",
                                }}
                              >
                                Fill In The Purchase Order Information
                              </Text>
                            </View>
                            <View style={{ marginRight: 10, marginTop: 20 }}>
                              {selectedPurchaseOrderEdit ? (
                                <View
                                  style={{
                                    justifyContent: "center",
                                    flexDirection: "row",
                                    borderWidth: 1,
                                    borderColor: Colors.primaryColor,
                                    backgroundColor: "#4E9F7D",
                                    borderRadius: 5,
                                    width: switchMerchant ? 120 : 140,
                                    paddingHorizontal: 10,
                                    height: switchMerchant ? 35 : 40,
                                    alignItems: "center",
                                    shadowOffset: {
                                      width: 0,
                                      height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                    zIndex: -1,
                                    opacity:
                                      poStatus ===
                                      PURCHASE_ORDER_STATUS.COMPLETED
                                        ? 0
                                        : 100,
                                  }}
                                >
                                  <TouchableOpacity
                                    disabled={
                                      poStatus ===
                                      PURCHASE_ORDER_STATUS.COMPLETED
                                    }
                                    style={{
                                      width: switchMerchant ? 120 : 140,
                                      alignItems: "center",
                                    }}
                                    onPress={createPurchaseOrder}
                                  >
                                    {isLoading ? (
                                      <>
                                        <ActivityIndicator
                                          color={Colors.whiteColor}
                                          size={"small"}
                                        />
                                      </>
                                    ) : (
                                      <Text
                                        style={{
                                          color: Colors.whiteColor,
                                          //marginLeft: 5,
                                          fontSize: switchMerchant ? 10 : 16,
                                          fontFamily: "NunitoSans-Bold",
                                        }}
                                      >
                                        UPDATE
                                      </Text>
                                    )}
                                  </TouchableOpacity>
                                </View>
                              ) : (
                                <>
                                  <View
                                    style={{
                                      justifyContent: "center",
                                      flexDirection: "row",
                                      borderWidth: 1,
                                      borderColor: Colors.primaryColor,
                                      backgroundColor: "#4E9F7D",
                                      borderRadius: 5,
                                      width: switchMerchant ? 120 : 140,
                                      paddingHorizontal: 10,
                                      height: switchMerchant ? 35 : 40,
                                      alignItems: "center",
                                      shadowOffset: {
                                        width: 0,
                                        height: 2,
                                      },
                                      shadowOpacity: 0.22,
                                      shadowRadius: 3.22,
                                      elevation: 1,
                                      zIndex: -1,
                                    }}
                                  >
                                    <TouchableOpacity
                                      disabled={isLoading}
                                      style={{
                                        width: switchMerchant ? 120 : 140,
                                        alignItems: "center",
                                      }}
                                      onPress={() => {
                                        createPurchaseOrder();
                                        setIsLoadingSave(true);
                                      }}
                                    >
                                      {isLoadingSave ? (
                                        <>
                                          <ActivityIndicator
                                            color={Colors.whiteColor}
                                            size={"small"}
                                          />
                                        </>
                                      ) : (
                                        <Text
                                          style={{
                                            color: Colors.whiteColor,
                                            //marginLeft: 5,
                                            fontSize: switchMerchant ? 10 : 16,
                                            fontFamily: "NunitoSans-Bold",
                                          }}
                                        >
                                          SAVE
                                        </Text>
                                      )}
                                    </TouchableOpacity>
                                  </View>
                                  <View
                                    style={{
                                      justifyContent: "center",
                                      flexDirection: "row",
                                      borderWidth: 1,
                                      borderColor: Colors.primaryColor,
                                      backgroundColor: "#4E9F7D",
                                      borderRadius: 5,
                                      width: switchMerchant ? 120 : 140,
                                      paddingHorizontal: 10,
                                      height: switchMerchant ? 35 : 40,
                                      alignItems: "center",
                                      shadowOffset: {
                                        width: 0,
                                        height: 2,
                                      },
                                      shadowOpacity: 0.22,
                                      shadowRadius: 3.22,
                                      elevation: 1,
                                      zIndex: -1,
                                      marginTop: 10,
                                    }}
                                  >
                                    <TouchableOpacity
                                      disabled={isLoading}
                                      style={{
                                        width: switchMerchant ? 120 : 140,
                                        alignItems: "center",
                                      }}
                                      onPress={() => {
                                        createPurchaseOrder();
                                        setIsLoadingSaveAndSend(true);
                                      }}
                                    >
                                      {isLoadingSaveAndSend ? (
                                        <>
                                          <ActivityIndicator
                                            color={Colors.whiteColor}
                                            size={"small"}
                                          />
                                        </>
                                      ) : (
                                        <Text
                                          style={{
                                            color: Colors.whiteColor,
                                            //marginLeft: 5,
                                            fontSize: switchMerchant ? 10 : 16,
                                            fontFamily: "NunitoSans-Bold",
                                          }}
                                        >
                                          SAVE & SEND
                                        </Text>
                                      )}
                                    </TouchableOpacity>
                                  </View>
                                </>
                              )}
                            </View>
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: 10,
                            justifyContent: "space-evenly",
                            marginTop: 50,
                            width: "90%",
                            alignSelf: "center",
                            marginLeft: windowWidth * Styles.sideBarWidth * 0.5,
                            zIndex: -1,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                                width: "40%",
                                textAlign: "left",
                              }}
                            >
                              Purchase Order ID
                            </Text>

                            {titleName == createPO ? (
                              <TextInput
                                editable={true}
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={{
                                  backgroundColor: Colors.fieldtBgColor,
                                  width: 100,
                                  width: "40%",
                                  height: switchMerchant ? 35 : 40,
                                  borderRadius: 10,
                                  padding: 5,
                                  //marginVertical: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  paddingLeft: 10,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                onChangeText={(text) => {
                                  setPoId(text);
                                }}
                                placeholder={"Max 12 Character"}
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                keyboardType={"default"}
                                maxLength={12}
                              />
                            ) : (
                              <View
                                style={{
                                  height: switchMerchant ? 35 : 40,
                                  width: 100,
                                  // paddingHorizontal: 20,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  width: "40%",
                                  borderColor: "#E5E5E5",
                                  borderWidth: 1,
                                  justifyContent: "center",
                                  paddingLeft: 10,
                                }}
                              >
                                <Text
                                  style={{
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                >
                                  {poId}
                                </Text>
                              </View>
                            )}
                            {selectedPurchaseOrderEdit ? (
                              <></>
                            ) : (
                              <View style={{ marginLeft: 5 }}>
                                {/* <AntDesign name="edit" size={20} color={Colors.primaryColor}/> */}
                                <TouchableOpacity
                                  onPress={() => {
                                    setEditMode(!editMode);
                                  }}
                                >
                                  {/* <MaterialCommunityIcons name="pencil" size={20} color={Colors.primaryColor} /> */}
                                  {/* <Icon3 name="clipboard-pencil" size={20} color={Colors.primaryColor} /> */}
                                  <EditGreen />
                                </TouchableOpacity>
                              </View>
                            )}
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                                // marginLeft: 60,
                                width: switchMerchant ? "33%" : "40%",
                                textAlign: "left",
                              }}
                            >
                              Supplier
                            </Text>

                            <View
                              style={{
                                width: switchMerchant ? "60%" : "50%",
                                height: switchMerchant ? 35 : 40,
                                backgroundColor: Colors.whiteColor,
                                borderRadius: 10,
                                justifyContent: "center",
                                alignSelf: "center",
                                zIndex: 1000,
                              }}
                            >
                              {
                                supplierDropdownList.find(
                                  (item) => item.value === selectedSupplierId
                                ) && (
                                  <Picker
                                    disabled={
                                      selectedPurchaseOrderEdit ? true : false
                                    }
                                    selectedValue={selectedSupplierId}
                                    style={{}}
                                    onValueChange={(value, text) => {
                                      console.log(value);
                                      setSelectedSupplierId(value);
                                    }}
                                  >
                                    {supplierDropdownList.map(
                                      (value, index) => {
                                        return (
                                          <Picker.Item
                                            key={index}
                                            label={value.label}
                                            value={value.value}
                                          />
                                        );
                                      }
                                    )}
                                  </Picker>
                                  //   <DropDownPicker
                                  //     containerStyle={{ height: 35, zIndex: 2 }}
                                  //     arrowColor={"black"}
                                  //     arrowSize={20}
                                  //     arrowStyle={{ fontWeight: "bold" }}
                                  //     labelStyle={{
                                  //       fontFamily: "NunitoSans-Regular",
                                  //       fontSize: switchMerchant ? 10 : 14,
                                  //     }}
                                  //     style={{
                                  //       width: switchMerchant ? "100%" : 220,
                                  //       paddingVertical: 0,
                                  //       backgroundColor: Colors.fieldtBgColor,
                                  //       borderRadius: 10,
                                  //     }}
                                  //     placeholderStyle={{
                                  //       color: Colors.fieldtTxtColor,
                                  //       fontFamily: "NunitoSans-Regular",
                                  //       fontSize: switchMerchant ? 10 : 14,
                                  //     }}
                                  //     disabled={
                                  //       selectedPurchaseOrderEdit ? true : false
                                  //     }
                                  //     items={supplierDropdownList}
                                  //     itemStyle={{
                                  //       justifyContent: "flex-start",
                                  //       marginLeft: 5,
                                  //       zIndex: 2,
                                  //       fontFamily: "NunitoSans-Regular",
                                  //       fontSize: switchMerchant ? 10 : 14,
                                  //     }}
                                  //     placeholder="Supplier"
                                  //     customTickIcon={(press) => (
                                  //       <Ionicon
                                  //         name={"md-checkbox"}
                                  //         color={
                                  //           press
                                  //             ? Colors.fieldtBgColor
                                  //             : Colors.primaryColor
                                  //         }
                                  //         size={25}
                                  //       />
                                  //     )}
                                  //     onChangeItem={(item) => {
                                  //       setSelectedSupplierId(item.value);
                                  //     }}
                                  //     searchable={true}
                                  //     defaultValue={selectedSupplierId}
                                  //     dropDownMaxHeight={150}
                                  //     dropDownStyle={{
                                  //       width: switchMerchant ? "100%" : 220,
                                  //       height: 150,
                                  //       backgroundColor: Colors.fieldtBgColor,
                                  //       borderRadius: 10,
                                  //       borderWidth: 1,
                                  //       textAlign: "left",
                                  //       zIndex: 5,
                                  //     }}
                                  //     // disabled={selectedPurchaseOrderEdit ? true : false}
                                  //     // items={supplierDropdownList}
                                  //     // defaultValue={selectedSupplierId}
                                  //     // placeholder="Supplier"
                                  //     // arrowSize={20}
                                  //     // containerStyle={{ height: 35, width: 220 }}
                                  //     // style={{ backgroundColor: '#FAFAFA', paddingVertical: 0 }}
                                  //     // itemStyle={{
                                  //     //   justifyContent: 'flex-start', marginLeft: 5
                                  //     // }}
                                  //     // dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                                  //     // onChangeItem={(item) => {
                                  //     //   setSelectedSupplierId(item.value);
                                  //     // }}
                                  //     // searchable={true}
                                  //   />
                                )
                                // <RNPickerSelect
                                //     disabled={selectedPurchaseOrderEdit ? true : false}
                                //     items={supplierDropdownList}
                                //     defaultValue={selectedSupplierId}
                                //     placeholder="Supplier"
                                //     containerStyle={{ height: 30 }}
                                //     style={{ backgroundColor: '#FAFAFA' }}
                                //     itemStyle={{
                                //       justifyContent: 'flex-start',
                                //     }}
                                //     dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                                //     onValueChange={(item) => {
                                //       setSelectedSupplierId(item.value);
                                //     }}
                                // />
                              }
                            </View>
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: 10,
                            justifyContent: "space-evenly",
                            marginTop: 50,
                            width: "90%",
                            alignSelf: "center",
                            marginLeft: windowWidth * Styles.sideBarWidth * 0.5,
                            zIndex: -2,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                                width: "40%",
                                textAlign: "left",
                              }}
                            >
                              Order Date
                            </Text>
                            {/* <TextInput
                          editable={false}
                          underlineColorAndroid={Colors.fieldtBgColor}
                          style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: '51%',
                            height: 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft:10,
                          }}
                          // placeholder={'50'}
                          value={moment().format('DD MMM YYYY')}
                          keyboardType={'default'}
                        /> */}

                            <View
                              style={{
                                width: 140,
                                backgroundColor: "#f2f2f2",
                                padding: 10,
                                paddingHorizontal: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                width: "40%",
                                height: switchMerchant ? 35 : 40,
                                justifyContent: "space-between",
                                borderRadius: 10,
                                borderColor: "#E5E5E5",
                                borderWidth: 1,
                                // paddingLeft: '2%',
                              }}
                            >
                              {/* <View style={{ width: '100%' }}> */}
                              <TouchableOpacity
                                style={{ width: "25%" }}
                                disabled={true}
                                onPress={showDateTimePicker}
                              >
                                {/* <TouchableOpacity style={{ alignSelf: "flex-end" }} onPress={showDateTimePicker}> */}
                                <GCalendarGrey
                                  width={switchMerchant ? 15 : 20}
                                  height={switchMerchant ? 15 : 20}
                                />
                              </TouchableOpacity>
                              <View
                                style={{
                                  justifyContent: "center",
                                  width: "75%",
                                  alignItems: "center",
                                }}
                              >
                                <Text
                                  style={[
                                    {
                                      textAlign: "center",
                                      fontFamily: "NunitoSans-Regular",
                                      fontSize: switchMerchant ? 10 : 14,
                                    },
                                    {
                                      // paddingTop: '10%',
                                      height: "200%",
                                      // borderWidth: 1,
                                    },
                                  ]}
                                >
                                  {moment().format("DD MMM YYYY")}
                                </Text>
                              </View>

                              {/* </View> */}
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              width: "50%",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                                width: switchMerchant ? "33%" : "40%",
                                textAlign: "left",
                              }}
                            >
                              Target Store
                            </Text>
                            <View
                              style={{
                                width: switchMerchant ? "60%" : "50%",
                                height: 40,
                                backgroundColor: Colors.whiteColor,
                                borderRadius: 10,
                                justifyContent: "center",
                                alignSelf: "center",
                              }}
                            >
                              {targetOutletDropdownList.find(
                                (item) => item.value === selectedTargetOutletId
                              ) && (
                                <Picker
                                  disabled={
                                    selectedPurchaseOrderEdit ? true : false
                                  }
                                  selectedValue={selectedTargetOutletId}
                                  style={{}}
                                  onValueChange={(value, text) => {
                                    setSelectedTargetOutletId(value);
                                  }}
                                >
                                  {targetOutletDropdownList.map(
                                    (value, index) => {
                                      return (
                                        <Picker.Item
                                          key={index}
                                          label={value.label}
                                          value={value.value}
                                        />
                                      );
                                    }
                                  )}
                                </Picker>
                                // <DropDownPicker
                                //   containerStyle={{ height: 35, zIndex: 2 }}
                                //   arrowColor={"black"}
                                //   arrowSize={20}
                                //   arrowStyle={{ fontWeight: "bold" }}
                                //   labelStyle={{
                                //     fontFamily: "NunitoSans-Regular",
                                //     fontSize: switchMerchant ? 10 : 14,
                                //   }}
                                //   style={{
                                //     width: switchMerchant ? "100%" : 220,
                                //     paddingVertical: 0,
                                //     backgroundColor: Colors.fieldtBgColor,
                                //     borderRadius: 10,
                                //   }}
                                //   placeholderStyle={{
                                //     color: Colors.fieldtTxtColor,
                                //     fontFamily: "NunitoSans-Regular",
                                //     fontSize: switchMerchant ? 10 : 14,
                                //   }}
                                //   disabled={
                                //     selectedPurchaseOrderEdit ? true : false
                                //   }
                                //   items={targetOutletDropdownList}
                                //   itemStyle={{
                                //     justifyContent: "flex-start",
                                //     marginLeft: 5,
                                //     zIndex: 2,
                                //   }}
                                //   placeholder="Target Store"
                                //   customTickIcon={(press) => (
                                //     <Ionicon
                                //       name={"md-checkbox"}
                                //       color={
                                //         press
                                //           ? Colors.fieldtBgColor
                                //           : Colors.primaryColor
                                //       }
                                //       size={25}
                                //     />
                                //   )}
                                //   onChangeItem={(item) =>
                                //     setSelectedTargetOutletId(item.value)
                                //   }
                                //   searchable={true}
                                //   defaultValue={selectedTargetOutletId}
                                //   dropDownMaxHeight={150}
                                //   dropDownStyle={{
                                //     width: switchMerchant ? "100%" : 220,
                                //     height: 150,
                                //     backgroundColor: Colors.fieldtBgColor,
                                //     borderRadius: 10,
                                //     borderWidth: 1,
                                //     textAlign: "left",
                                //     zIndex: 2,
                                //   }}
                                //   // disabled={selectedPurchaseOrderEdit ? true : false}
                                //   // items={targetOutletDropdownList}
                                //   // defaultValue={selectedTargetOutletId}
                                //   // placeholder={''}
                                //   // arrowSize={20}
                                //   // containerStyle={{ height: 35, width: 220 }}
                                //   // style={{ backgroundColor: '#FAFAFA', paddingVertical: 0, }}
                                //   // itemStyle={{
                                //   //   justifyContent: 'flex-start', marginLeft: 5
                                //   // }}
                                //   // searchable={true}
                                //   // dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                                //   // onChangeItem={(item) =>
                                //   //   setSelectedTargetOutletId(item.value)
                                //   // }
                                // />
                              )}
                            </View>
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: 10,
                            justifyContent: "space-evenly",
                            marginTop: 50,
                            marginBottom: 40,
                            width: "90%",
                            alignSelf: "center",
                            marginLeft: windowWidth * Styles.sideBarWidth * 0.5,
                            zIndex: -3,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                                width: "40%",
                                textAlign: "left",
                              }}
                            >
                              Estimated Arrival
                            </Text>
                            <View
                              style={{
                                width: 140,
                                backgroundColor: "#f2f2f2",
                                padding: 10,
                                paddingHorizontal: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                width: "40%",
                                height: switchMerchant ? 35 : 40,
                                justifyContent: "space-between",
                                borderRadius: 10,
                                borderColor: "#E5E5E5",
                                borderWidth: 1,
                              }}
                            >
                              {/* <View style={{ width: '100%' }}> */}
                              <TouchableOpacity
                                style={{ width: "25%" }}
                                disabled={
                                  poStatus === PURCHASE_ORDER_STATUS.COMPLETED
                                }
                                onPress={showDateTimePicker}
                              >
                                {/* <TouchableOpacity style={{ alignSelf: "flex-end" }} onPress={showDateTimePicker}> */}
                                {poStatus ===
                                PURCHASE_ORDER_STATUS.COMPLETED ? (
                                  <GCalendarGrey
                                    width={switchMerchant ? 15 : 20}
                                    height={switchMerchant ? 15 : 20}
                                  />
                                ) : (
                                  <GCalendar
                                    width={switchMerchant ? 15 : 20}
                                    height={switchMerchant ? 15 : 20}
                                  />
                                )}
                              </TouchableOpacity>
                              <View
                                style={{
                                  justifyContent: "center",
                                  width: "55%",
                                  // paddingLeft: "%",
                                }}
                              >
                                <DatePicker
                                  selected={moment(date).toDate()}
                                  onChange={(date) => {
                                    setDate(moment(date));
                                  }}
                                  minDate={moment().toDate()}
                                />
                                {/* <Text
                                  style={[
                                    {
                                      textAlign: "center",
                                      fontFamily: "NunitoSans-Regular",
                                      fontSize: switchMerchant ? 10 : 14,
                                    }, {
                                          // paddingTop: '10%',
                                          height: "200%",
                                          // borderWidth: 1,
                                        },
                                  ]}
                                >
                                  {moment(date).format("DD MMM YYYY")}
                                </Text> */}
                              </View>

                              {/* </View> */}
                            </View>

                            <DateTimePickerModal
                              isVisible={isDateTimePickerVisible}
                              // onConfirm={handleDatePicked}
                              // onCancel={hideDateTimePicker}
                              mode={"date"}
                              onConfirm={(text) => {
                                setDate(moment(text));

                                setIsDateTimePickerVisible(false);
                              }}
                              onCancel={() => {
                                setIsDateTimePickerVisible(false);
                              }}
                            />
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                              width: "50%",
                            }}
                          >
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                                width: switchMerchant ? "33%" : "40%",
                                textAlign: "left",
                              }}
                            >
                              Current Status
                            </Text>
                            <View
                              style={{
                                paddingHorizontal: 18,
                                paddingVertical: 10,
                                alignItems: "center",
                                backgroundColor: Colors.secondaryColor,
                                borderRadius: 10,
                                width: switchMerchant ? "60%" : 220,
                              }}
                            >
                              <Text
                                style={{
                                  color: Colors.whiteColor,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                {PURCHASE_ORDER_STATUS_PARSED[poStatus]}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>

                      {/* <ScrollView horizontal={true}
                      nestedScrollEnabled={true}
                      scrollEnabled={switchMerchant}
                      style={{ flexDirection: 'column' }}
                      contentContainerStyle={{
                        flexDirection: 'column',
                        width: switchMerchant ? windowWidth * 1.1 : windowWidth * 0.87,
                        // maxHeight: switchMerchant ? windowHeight * 0.9 : null, 
                      }}
                    > */}
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "center",
                          zIndex: -5,
                        }}
                      >
                        {/* <View
                        style={{
                          flexDirection: 'row',
                          //backgroundColor: '#ffffff',
                          justifyContent: 'center',
                          padding: 18,
                        }}>
                        <View style={{ alignItems: 'center' }}>
                          <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
                            {purchaseOrders.length}
                          </Text>
                          <Text>PURCHASE ORDERS</Text>
                        </View>
                      </View> */}
                        <Text
                          style={{
                            alignSelf: "center",
                            marginTop: 30,
                            marginBottom: 15,
                            fontSize: switchMerchant ? 15 : 20,
                            fontWeight: "bold",
                          }}
                        >
                          Items Ordered
                        </Text>
                        {/* <View
                        style={{
                          //backgroundColor: Colors.primaryColor,
                          width: 150,
                          height: 40,
                          borderRadius: 5,
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: 15,
                          marginTop: 30,
                        }}>
                        Hide This Button First
                        <TouchableOpacity onPress={() => { setImportModal(true) }}>
                          <View style={{ flexDirection: 'row' }}>
                            <Icon name='upload' size={20} color={Colors.whiteColor} />
                            <Text
                              style={{
                                marginLeft: 10,
                                color: Colors.whiteColor,
                                alignSelf: 'center',
                              }}>
                              Batch-Upload
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View> */}
                      </View>

                      <View
                        style={{
                          backgroundColor: "#ffffff",
                          flexDirection: "row",
                          paddingVertical: 20,
                          paddingHorizontal: 10,
                          paddingRight: 5,
                          marginTop: 10,
                          borderBottomWidth: StyleSheet.hairlineWidth,
                          width: "100%",
                          zIndex: -1,
                        }}
                      >
                        <Text
                          style={{ width: switchMerchant ? 40 : 45 }}
                        ></Text>
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: "16%",
                            fontWeight: "500",
                            marginLeft: 10,
                            marginRight: 1,
                          }}
                        >
                          Product Name
                        </Text>
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: "10%",
                            fontWeight: "500",
                            marginLeft: 5,
                            marginRight: 1,
                          }}
                        >
                          SKU
                        </Text>
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: "9%",
                            fontWeight: "500",
                            marginHorizontal: 3,
                          }}
                        >
                          In Stock
                        </Text>
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: "11%",
                            fontWeight: "500",
                            marginHorizontal: 3,
                          }}
                        >
                          Ordered Qty
                        </Text>
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: "7%",
                            fontWeight: "500",
                            marginHorizontal: 3,
                          }}
                        >
                          Unit
                        </Text>
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: "11%",
                            fontWeight: "500",
                            marginHorizontal: 3,
                          }}
                        >
                          Received Qty
                        </Text>
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: switchMerchant ? "12%" : "13%",
                            fontWeight: "500",
                            marginHorizontal: 3,
                          }}
                        >
                          Supplier Price
                        </Text>
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                            width: switchMerchant ? "8%" : "8%",
                            fontWeight: "500",
                            marginHorizontal: 3,
                            marginLeft: 0,
                          }}
                        >
                          Total (RM)
                        </Text>
                        <Text style={{ width: 22 }}></Text>
                      </View>

                      {((selectedPurchaseOrderEdit &&
                        selectedPurchaseOrderEdit.supplierId ===
                          selectedSupplierId &&
                        supplyItemsDict[poItems[0].supplyItemId]) ||
                        selectedPurchaseOrderEdit === null) &&
                        supplyItemDropdownList.length > 0 &&
                        Object.keys(supplyItemsDict).length > 0 &&
                        Object.keys(outletSupplyItemsSkuDict).length > 0 && (
                          <View
                            style={{
                              shadowOpacity: 0,
                              shadowColor: "#000",
                              shadowOffset: {
                                width: 0,
                                height: 1,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 1.22,
                              elevation: 1,
                              zIndex: -1,
                              //maxHeight: switchMerchant ? windowHeight * 0.6 : null,
                            }}
                          >
                            <FlatList
                              nestedScrollEnabled={true}
                              //horizontal={true}
                              data={poItems}
                              extraData={poItems}
                              renderItem={renderAddPO}
                              keyExtractor={(item, index) => String(index)}
                            />
                          </View>
                        )}

                      {/* <ScrollView>
                      {
                        // itemsToOrder2 && itemsToOrder2.map((item, i) => {
                        //   return renderAddPO(item);
                        // })
                        poItems && poItems.map((item, i) => {
                          return renderAddPO(item, i);
                        })
                      }
                    </ScrollView> */}

                      <View
                        style={{
                          flexDirection: "row",
                          zIndex: -1,
                          paddingLeft: 10,
                          paddingRight: 5,
                        }}
                      >
                        {!selectedPurchaseOrderEdit ? (
                          <View
                            style={{ width: switchMerchant ? "76%" : "77%" }}
                          >
                            <TouchableOpacity
                              style={[
                                styles.submitText2,
                                { alignSelf: "flex-start" },
                              ]}
                              onPress={() => {
                                if (supplyItems.length > 0) {
                                  setPoItems([
                                    ...poItems,
                                    {
                                      supplyItemId: supplyItems[0].uniqueId,
                                      image: supplyItems[0].image,
                                      name: supplyItems[0].name,
                                      sku: supplyItems[0].sku,
                                      skuMerchant: supplyItems[0].skuMerchant,
                                      quantity: outletSupplyItemsSkuDict[
                                        supplyItems[0].sku
                                      ]
                                        ? outletSupplyItemsSkuDict[
                                            supplyItems[0].sku
                                          ].quantity
                                        : 0, // check if the supply item sku for this outlet existed
                                      orderQuantity: 0,
                                      unit: supplyItems[0].unit,
                                      receivedQuantity: 0,
                                      price: supplyItems[0].price,
                                      totalPrice: 0,

                                      lastReceivedQuantity: 0,
                                      supplyItem: supplyItems[0],
                                    },
                                  ]);
                                } else {
                                  Alert.alert(
                                    "Error",
                                    "No supplier items purchased"
                                  );
                                }
                              }}
                            >
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Icon1
                                  name="plus-circle"
                                  size={switchMerchant ? 15 : 20}
                                  color={Colors.primaryColor}
                                />
                                <Text
                                  style={{
                                    marginLeft: switchMerchant ? 5 : 10,
                                    color: Colors.primaryColor,
                                    marginBottom: 1,
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                >
                                  Add Product Slot
                                </Text>
                              </View>
                            </TouchableOpacity>
                          </View>
                        ) : (
                          <View
                            style={{ width: switchMerchant ? "76%" : "77%" }}
                          ></View>
                        )}

                        {/* {selectedPurchaseOrderEdit ?
                        <View>
                          <Text></Text>
                        </View>
                        : <></>
                      } */}
                        <View
                          style={[
                            {
                              marginTop: 10,
                              width: switchMerchant ? 87.5 : 92.5,
                            },
                          ]}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              bottom: 0,
                            }}
                          >
                            <Text
                              style={{
                                color: "#adadad",
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              Subtotal
                            </Text>
                          </View>
                          <View
                            style={[
                              {
                                flexDirection: "row",
                                marginTop: 10,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              },
                            ]}
                          >
                            <Text
                              style={{
                                color: "#adadad",
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              Tax
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: "bold",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              Total (RM)
                            </Text>
                          </View>
                        </View>

                        <View style={{ marginTop: 10, width: "8%" }}>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {/* <Text style={{ color: '#adadad', fontWeight: '500', width: '52%' }}>Subtotal</Text> */}
                            <Text
                              style={{
                                color: "#adadad",
                                fontSize: switchMerchant ? 10 : 14,
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Regular",
                              }}
                            >{`RM`}</Text>
                            <Text
                              style={{
                                color: "#adadad",
                                fontSize: switchMerchant ? 10 : 14,
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Regular",
                                paddingRight: "2%",
                              }}
                            >
                              {subtotal
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: 10,
                            }}
                          >
                            {/* <Text style={{ color: '#adadad', fontWeight: '500', width: '52%' }}>Tax</Text> */}
                            <Text
                              style={{
                                color: "#adadad",
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >{`RM`}</Text>
                            <Text
                              style={{
                                color: "#adadad",
                                fontSize: switchMerchant ? 10 : 14,
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Regular",
                                paddingRight: "2%",
                              }}
                            >
                              {taxTotal
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: 10,
                              marginBottom: 15,
                            }}
                          >
                            {/* <Text style={{ fontWeight: 'bold', width: '52%' }}>Total (RM)</Text> */}
                            <Text
                              style={{
                                fontWeight: "bold",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >{`RM`}</Text>
                            <Text
                              style={{
                                color: "#adadad",
                                fontSize: switchMerchant ? 10 : 14,
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Bold",
                                paddingRight: "2%",
                              }}
                            >
                              {finalTotal.toFixed(2)}
                            </Text>
                          </View>
                        </View>
                      </View>
                      {/* </ScrollView> */}

                      {/* Purpose of this view is To Push the scrollview up */}
                      <View style={{ height: 40 }} />

                      {/* <View style={{ flexDirection: "row", alignSelf: "center", justifyContent: "space-evenly", marginTop: 20 }}>
                    {selectedPurchaseOrderEdit ?
                      <View
                        style={{
                          backgroundColor: Colors.primaryColor,
                          width: 200,
                          height: 40,
                          marginVertical: 15,
                          borderRadius: 5,
                          alignSelf: 'center',
                        }}>
                        <TouchableOpacity onPress={createPurchaseOrder}>
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              alignSelf: 'center',
                              marginVertical: 10,
                            }}>
                            UPDATE
                        </Text>
                        </TouchableOpacity>
                      </View>
                      :
                      <>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 200,
                            height: 40,
                            marginVertical: 15,
                            borderRadius: 5,
                            alignSelf: 'center',
                          }}>
                          <TouchableOpacity onPress={createPurchaseOrder}>
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                alignSelf: 'center',
                                marginVertical: 10,
                              }}>
                              SAVE
                        </Text>
                          </TouchableOpacity>
                        </View>
                        <View
                          style={{
                            backgroundColor: Colors.primaryColor,
                            width: 200,
                            height: 40,
                            marginVertical: 15,
                            borderRadius: 5,
                            alignSelf: 'center',
                            marginLeft: 40
                          }}>
                          <TouchableOpacity onPress={createPurchaseOrder}>
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                alignSelf: 'center',
                                marginVertical: 10,
                              }}>
                              SAVE & SEND
                        </Text>
                          </TouchableOpacity>
                        </View>
                      </>
                    }
                  </View> */}
                    </ScrollView>
                    {/* </ScrollView> */}
                  </View>
                </View>
              </View>
            ) : null}

            {editPurchase ? (
              <View style={{ height: Dimensions.get("window").height - 200 }}>
                <View>
                  <ScrollView>
                    <View
                      style={{
                        width: 140,
                        marginRight: 10,
                        marginTop: 10,
                        alignSelf: "flex-end",
                        height: 40,
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 10,
                        justifyContent: "center",
                      }}
                    >
                      <Select
                        defaultValue={choice}
                        onChange={(items) => {
                          setState({
                            choice: items.value,
                          });
                        }}
                        options={dropdownItems}
                        isMulti
                      />
                      {/* <DropDownPicker
                        items={[
                          {
                            icon: () => {},
                            label: "🖨️  Print P.O",
                            value: "Print P.O",
                          },
                          {
                            label: "📧  Email P.O",
                            value: "Chicken",
                          },
                          {
                            label: "📤  Export Labels",
                            value: "Export Labels",
                          },
                          {
                            label: "❌  Cancel P.O",
                            value: "Cancel P.O",
                          },
                          {
                            label: "🗑️  Delete P.O",
                            value: "Delete P.O",
                          },
                        ]}
                        defaultValue={choice}
                        placeholder=""
                        placeholderStyle={{ color: "black" }}
                        containerStyle={{ height: 30 }}
                        style={{ backgroundColor: "#FAFAFA" }}
                        itemStyle={{}}
                        dropDownStyle={{ backgroundColor: "#FAFAFA" }}
                        onChangeItem={(item) =>
                          setState({
                            choice: item.value,
                          })
                        }
                      /> */}
                    </View>
                    <View
                      style={{ borderBottomWidth: StyleSheet.hairlineWidth }}
                    >
                      <View>
                        <Text
                          style={{
                            alignSelf: "center",
                            marginTop: 30,
                            fontSize: 40,
                            fontWeight: "bold",
                          }}
                        >
                          Edit Purchase Order
                        </Text>
                        <Text
                          style={{
                            alignSelf: "center",
                            fontSize: 16,
                            color: "#adadad",
                          }}
                        >
                          Edit your purchase order information
                        </Text>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: 10,
                          justifyContent: "space-evenly",
                          marginTop: 50,
                        }}
                      >
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text style={{ fontSize: 16, marginLeft: 80 }}>
                            Purchase Order ID
                          </Text>
                          <Text
                            style={{
                              color: "#adadad",
                              marginLeft: 170,
                              fontSize: 16,
                              fontFamily: "NunitoSans-Regular",
                            }}
                          >
                            P.O.1134
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text style={{ fontSize: 16, marginLeft: 80 }}>
                            Supplier
                          </Text>
                          <Text
                            style={{
                              color: "#adadad",
                              marginLeft: 100,
                              fontSize: 16,
                              fontFamily: "NunitoSans-Regular",
                            }}
                          >
                            My Burgers Enterprise
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: 10,
                          justifyContent: "space-evenly",
                          marginTop: 50,
                        }}
                      >
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 16,
                              marginLeft: 80,
                              fontFamily: "NunitoSans-Regular",
                            }}
                          >
                            Current status
                          </Text>
                          <View
                            style={{
                              paddingHorizontal: 18,
                              paddingVertical: 10,
                              alignItems: "center",
                              backgroundColor: "#838387",
                              borderRadius: 10,
                              marginLeft: 100,
                            }}
                          >
                            <Text style={{ color: Colors.whiteColor }}>
                              Partially received
                            </Text>
                          </View>
                        </View>
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 16,
                              marginLeft: 80,
                              fontFamily: "NunitoSans-Regular",
                            }}
                          >
                            Target Store
                          </Text>
                          <Text
                            style={{
                              color: "#adadad",
                              marginLeft: 70,
                              fontSize: 16,
                            }}
                          >
                            MyBurgerlab (Seapark)
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: 10,
                          justifyContent: "space-evenly",
                          marginTop: 50,
                          marginBottom: 40,
                        }}
                      >
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 16,
                              marginLeft: 80,
                              fontFamily: "NunitoSans-Regular",
                            }}
                          >
                            Estimated Arrival
                          </Text>
                          <Text
                            style={{
                              color: "#adadad",
                              marginLeft: 50,
                              fontSize: 16,
                            }}
                          >
                            1/10/2020
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 16,
                              marginLeft: 80,
                              fontFamily: "NunitoSans-Regular",
                            }}
                          >
                            Order Date
                          </Text>
                          <Text
                            style={{
                              color: "#adadad",
                              marginLeft: 80,
                              fontSize: 16,
                            }}
                          >
                            5/10/2020
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View>
                      <Text
                        style={{
                          alignSelf: "center",
                          marginTop: 30,
                          marginBottom: 15,
                          fontSize: 25,
                          fontWeight: "bold",
                          zIndex: -1000,
                        }}
                      >
                        {/* Items to Order */}
                        Items Ordered
                      </Text>
                    </View>

                    <View
                      style={{
                        backgroundColor: "#ffffff",
                        flexDirection: "row",
                        paddingVertical: 20,
                        paddingHorizontal: 20,
                        marginTop: 10,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                      }}
                    >
                      <Text style={{ width: "8%" }}></Text>
                      <Text
                        style={{
                          width: "14%",
                          alignSelf: "center",
                          fontSize: 14,
                          fontFamily: "NunitoSans-Regular",
                        }}
                      >
                        Product Name
                      </Text>
                      <Text
                        style={{
                          width: "16%",
                          alignSelf: "center",
                          fontSize: 14,
                          fontFamily: "NunitoSans-Regular",
                        }}
                      >
                        SKU
                      </Text>
                      <Text
                        style={{
                          width: "14%",
                          alignSelf: "center",
                          fontSize: 14,
                          fontFamily: "NunitoSans-Regular",
                        }}
                      >
                        Ordered qty
                      </Text>
                      <Text
                        style={{
                          width: "16%",
                          alignSelf: "center",
                          fontSize: 14,
                          fontFamily: "NunitoSans-Regular",
                        }}
                      >
                        Received qty
                      </Text>
                      <Text
                        style={{
                          width: "18%",
                          alignSelf: "center",
                          fontSize: 14,
                          fontFamily: "NunitoSans-Regular",
                        }}
                      >
                        Supplier Price{" "}
                      </Text>
                      <Text
                        style={{
                          width: "16%",
                          alignSelf: "center",
                          fontSize: 14,
                          fontFamily: "NunitoSans-Regular",
                        }}
                      >
                        Total (RM)
                      </Text>
                    </View>
                    {/* <FlatList
                      nestedScrollEnabled={true}
                      data={itemsToOrder}
                      extraData={itemsToOrder}
                      renderItem={renderItemsToOrder}
                      keyExtractor={(item, index) => String(index)}
                    /> */}

                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <TouchableOpacity
                          style={styles.submitText2}
                          onPress={() => {}}
                        >
                          <View style={{ flexDirection: "row" }}>
                            <Icon1
                              name="plus-circle"
                              size={20}
                              color={Colors.primaryColor}
                            />
                            <Text
                              style={{
                                marginLeft: 10,
                                color: Colors.primaryColor,
                              }}
                            >
                              Add product slot
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>

                      <View style={{ marginRight: 30, marginTop: 10 }}>
                        <View style={{ flexDirection: "row" }}>
                          <Text style={{ color: "#adadad" }}>Subtotal</Text>
                          <Text style={{ color: "#adadad", marginLeft: 50 }}>
                            RM360.00
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 10 }}>
                          <Text style={{ color: "#adadad" }}>Tax</Text>
                          <Text style={{ color: "#adadad", marginLeft: 80 }}>
                            RM0.00
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 10 }}>
                          <Text style={{ color: "#adadad" }}>Discount</Text>
                          <Text style={{ color: "#adadad", marginLeft: 50 }}>
                            RM0.00
                          </Text>
                        </View>
                        <View style={{ flexDirection: "row", marginTop: 5 }}>
                          <Text style={{ fontWeight: "bold" }}>Total (RM)</Text>
                          <Text style={{ fontWeight: "bold", marginLeft: 40 }}>
                            RM360.00
                          </Text>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        alignSelf: "center",
                        justifyContent: "space-evenly",
                        marginTop: 20,
                      }}
                    >
                      <View
                        style={{
                          backgroundColor: Colors.primaryColor,
                          width: 200,
                          height: 40,
                          marginVertical: 15,
                          borderRadius: 5,
                          alignSelf: "center",
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            // editStockOrder();
                          }}
                        >
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              alignSelf: "center",
                              marginVertical: 10,
                            }}
                          >
                            SAVE
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          backgroundColor: Colors.primaryColor,
                          width: 200,
                          height: 40,
                          marginVertical: 15,
                          borderRadius: 5,
                          alignSelf: "center",
                          marginLeft: 40,
                        }}
                      >
                        <TouchableOpacity onPress={() => {}}>
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              alignSelf: "center",
                              marginVertical: 10,
                            }}
                          >
                            SAVE & SEND
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        backgroundColor: "#ffffff",
                        justifyContent: "center",
                        padding: 18,
                      }}
                    >
                      <View style={{ alignItems: "center" }}>
                        <Text
                          style={{
                            fontSize: 26,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          {/* {stockList.length} */}
                          {purchaseOrders.length}
                        </Text>
                        <Text>PURCHASE ORDERS</Text>
                      </View>
                    </View>
                  </ScrollView>
                </View>
              </View>
            ) : null}
          </View>
          {/* <View style={{ height: windowHeight * 0.1 }}></View> */}
        </ScrollView>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    // '@media (max-width:2048px) and (min-width: 1024px)' : {
    //   backgroundColor: 'red',
    // },
    // '@media (min-width: 1024px)' : {
    //   backgroundColor: 'blue',
    // },
    flexDirection: "row",
    zIndex: -1,
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    width: useWindowDimensions.width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 16,
    width: useWindowDimensions.width * (1 - Styles.sideBarWidth),
  },
  submitText: {
    height: useWindowDimensions.height * 0.07,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  submitText1: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    alignSelf: "flex-end",
    marginRight: 20,
    height: 40,
    // marginTop: 15,
    width: 220,
  },
  submitText2: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    alignSelf: "flex-end",
    marginRight: 20,
    marginTop: 15,
  },
  /* textInput: {
    width: 300,
    height: '10%',
    padding: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 20,
  }, */

  textInput: {
    width: 200,
    height: 40,
    // padding:5,
    backgroundColor: Colors.whiteColor,
    borderRadius: 0,
    marginRight: "35%",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "flex-end",

    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 0.51,
    elevation: 15,
  },
  searchIcon: {
    backgroundColor: Colors.whiteColor,
    height: 40,
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,

    elevation: 15,
  },
  textInput1: {
    width: 160,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 5,
  },
  textInput2: {
    width: 100,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 5,
    textAlign: "center",
  },
  confirmBox: {
    width: 450,
    height: 450,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
  },
  textFieldInput: {
    height: 80,
    width: 900,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  headerLeftStyle: {
    width: useWindowDimensions.width * 0.17,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    height: useWindowDimensions.width * 0.2,
    width: useWindowDimensions.width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: useWindowDimensions.width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView1: {
    //height: windowWidth * 0.2,
    width: useWindowDimensions.width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: useWindowDimensions.width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  modalViewImport: {
    height: useWindowDimensions.width * 0.6,
    width: useWindowDimensions.width * 0.6,
    backgroundColor: Colors.whiteColor,
    borderRadius: useWindowDimensions.width * 0.03,
    padding: 20,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  closeButton: {
    position: "absolute",
    right: useWindowDimensions.width * 0.02,
    top: useWindowDimensions.width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: "center",
    top: "20%",
    position: "absolute",
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitleText: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    width: useWindowDimensions.width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
});
export default PurchaseOrderScreen;
