import React, { Component, useReducer, useState, useEffect } from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Modal,
  Dimensions,
  TextInput,
  FlatList,
  KeyboardAvoidingView,
  ActivityIndicator,
  useWindowDimensions,
  Platform,
  Picker,
} from "react-native";
// import CheckBox from "react-native-check-box";
// import CheckBox from '@react-native-community/checkbox';
import Colors from "../constant/Colors";
import Styles from "../constant/Styles";
import SideBar from "./SideBar";
// import AsyncStorage from "@react-native-async-storage/async-storage";
// import * as User from "../util/User";
import Icon from "react-native-vector-icons/Ionicons";
import Icon1 from "react-native-vector-icons/Feather";
// import Ionicon from "react-native-vector-icons/Ionicons";
//import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import ApiClient from "../util/ApiClient";
import API from "../constant/API";
// import DropDownPicker from "react-native-dropdown-picker";
import Select from 'react-select';

import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";

import Feather from "react-native-vector-icons/Feather";
import EvilIcons from "react-native-vector-icons/EvilIcons";
import moment from "moment";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import TimeKeeper from "react-timekeeper";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";

// import { isTablet } from "react-native-device-detection";
import {
  MERCHANT_VOUCHER_CODE_FORMAT,
  MERCHANT_VOUCHER_TYPE,
  SEGMENT_TYPE,
  ORDER_TYPE,
  ORDER_TYPE_PARSED,
  ORDER_TYPE_DROP_DOWN_LIST,
} from "../constant/common";
import { CommonStore } from "../store/commonStore";
import { UserStore } from "../store/userStore";
import { MerchantStore } from "../store/merchantStore";
import { OutletStore } from "../store/outletStore";
// import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
// import RNPickerSelect from "react-native-picker-select";
// import { useKeyboard } from "../hooks";
// import { get } from "react-native/Libraries/Utilities/PixelRatio";
// import { launchCamera, launchImageLibrary } from "react-native-image-picker";
import { useFilePicker } from "use-file-picker";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
// import Switch from "react-native-switch-pro";
import {
  EFFECTIVE_DAY_DROPDOWN_LIST,
  EFFECTIVE_DAY_DROPDOWN_LIST2,
  TARGET_USER_GROUP_DROPDOWN_LIST,
  PROMOTION_TYPE_DROPDOWN_LIST,
  PROMOTION_TYPE,
  PROMOTION_TYPE_VARIATION,
  PROMOTION_TYPE_VARIATION_DROPDOWN_LIST,
  EFFECTIVE_TYPE,
  CRM_SEGMENT_DROPDOWN_LIST,
} from "../constant/promotions";
import { areArraysEqual, uploadImageToFirebaseStorage } from "../util/common";
import AsyncImage from "../components/asyncImage";
import { v4 as uuidv4 } from "uuid";
import AntDesign from "react-native-vector-icons/AntDesign";

import { useLinkTo, useRoute } from "@react-navigation/native";
import { prefix } from "../constant/env";

// import NumericInput from "react-native-numeric-input";
// import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
// import { getAllExternalFilesDirs } from "react-native-fs";

//////////////////////////////////////////////////////////////////////////////////////////////////////////

const NewCampaignScreen = (props) => {
  const { navigation } = props;

  const linkTo = useLinkTo();

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [campaignName, setCampaignName] = useState("");
  const [isEnableSellOnline, setIsEnableSellOnline] = useState(false);

  const [selectedTargetUserGroup, setSelectedTargetUserGroup] = useState(
    TARGET_USER_GROUP_DROPDOWN_LIST[0].value
  );
  const [selectedTargetSegmentGroupList, setSelectedTargetSegmentGroupList] =
    useState([CRM_SEGMENT_DROPDOWN_LIST[0].value]);
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [isPromoCodeUsageLimit, setIsPromoCodeUsageLimit] = useState(false);
  const [promoCodeUsageLimit, setPromoCodeUsageLimit] = useState("");
  const [promoCodeUsageLimitTicked, setPromoCodeUsageLimitTicked] =
    useState(false);

  const [selectedEffectiveType, setSelectedEffectiveType] = useState(
    EFFECTIVE_TYPE.DAY
  );
  const [selectedEffectiveTypeOptions, setSelectedEffectiveTypeOptions] =
    useState([EFFECTIVE_DAY_DROPDOWN_LIST[0].value]);
  const [selectedEffectiveDay, setSelectedEffectiveDay] = useState(
    EFFECTIVE_DAY_DROPDOWN_LIST[0].value
  );
  const [showEffectiveTimeStartPicker, setShowEffectiveTimeStartPicker] =
    useState(false);
  const [showEffectiveTimeEndPicker, setShowEffectiveTimeEndPicker] =
    useState(false);
  const [effectiveTimeStart, setEffectiveTimeStart] = useState(moment());
  const [effectiveTimeEnd, setEffectiveTimeEnd] = useState(moment());

  const [showPromoDateStartPicker, setShowPromoDateStartPicker] =
    useState(false);
  const [showPromoDateEndPicker, setShowPromoDateEndPicker] = useState(false);
  const [showPromoTimeStartPicker, setShowPromoTimeStartPicker] =
    useState(false);
  const [showPromoTimeEndPicker, setShowPromoTimeEndPicker] = useState(false);

  const [promoDateStart, setPromoDateStart] = useState(moment().startOf('month').startOf('day'));
  const [promoDateEnd, setPromoDateEnd] = useState(moment().endOf("month").endOf('day'));
  const [promoTimeStart, setPromoTimeStart] = useState(moment(Date.now()).format("hh:mm A"));
  const [promoTimeEnd, setPromoTimeEnd] = useState(moment(Date.now()).format("hh:mm A"));

  const [promoClockTimePicker, setPromoClockTimePicker] = useState(false);
  const [promoClockTimePicker1, setPromoClockTimePicker1] = useState(false);

  const [clockTimePicker, setClockTimePicker] = useState(false);

  const [campaignDescription, setCampaignDescription] = useState("");

  const [selectedPromotionType, setSelectedPromotionType] = useState(
    PROMOTION_TYPE_DROPDOWN_LIST[0].value
  );

  const [
    isRequireSpecificProductPurchase,
    setIsRequireSpecificProductPurchase,
  ] = useState(false);

  const [image, setImage] = useState("");
  const [imageType, setImageType] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [openFileSelector, { plainFiles, filesContent, loading: loadingImageInput, clear: clearImageContainer, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: ["image/*"],
    multiple: false,
  });

  // select image
  useEffect(() => {
    if (plainFiles.length && filesContent.length && !loadingImageInput) {
      setImage(filesContent[0].content);
      setImageType(filesContent[0].name.slice(filesContent[0].name.lastIndexOf('.')));
      setIsImageChanged(true);
    }

    if (errors.length)
      console.error(errors)

  }, [plainFiles, filesContent, loadingImageInput, errors])


  const [outletDropdownList, setOutletDropdownList] = useState([]);
  const [selectedOutletList, setSelectedOutletList] = useState([]); // multi-outlets

  //const [availabilityDropdownList, setAvailabilityDropdownList] = useState([]); // DropDownList for Dine in, TakeAway, Delivery
  const [selectedAvailability, setSelectedAvailability] = useState([
    ORDER_TYPE_DROP_DOWN_LIST[0].value,
  ]); // Enable Promotion for Dine in, TakeAway, Delivery

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////

  const [isChecked, setIsChecked] = useState(false);
  const [showDateTimePicker_Date, setShowDateTimePicker_Date] = useState(false);
  const [showDateTimePicker_Time, setShowDateTimePicker_Time] = useState(false);
  //const [isLoading1, setIsLoading1] = useState(false);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);
  const merchantLogo = MerchantStore.useState((s) => s.logo);

  ////////////////////////////////Combo Set/Package/Bundle Function//////////////////////////////////////////

  const [packageItemPrice, setPackageItemPrice] = useState("0");
  const [packageMinQuantity, setPackageMinQuantity] = useState("0");
  const [packagePriceFinal, setPackagePriceFinal] = useState(0);

  const [packageTaxDropdownList, setPackageTaxDropdownList] = useState([]);
  const [packageTax, setPackageTax] = useState("");

  const [packageProductDropdownList, setPackageProductDropdownList] = useState(
    []
  );
  const [packageselectedProductList, setPackageSelectedProductList] = useState(
    []
  );

  const [packageCategoryDropdownList, setPackageCategoryDropdownList] =
    useState([]);
  const [packageSelectedCategoryList, setPackageSelectedCategoryList] =
    useState([]);

  ////////////////////////////////Override Function//////////////////////////////////////////

  const [overrideItemPrice, setOverrideItemPrice] = useState("0");
  const [overridePriceFinal, setOverridePriceFinal] = useState(0);
  // const [overrideMinQuantity, setOverrideMinQuantity] = useState('');

  const [overrideTaxDropdownList, setOverrideTaxDropdownList] = useState([]);
  const [overrideTax, setOverrideTax] = useState("");

  const [overrideProductDropdownList, setOverrideProductDropdownList] =
    useState([]);
  const [overrideSelectedProductList, setOverrideSelectedProductList] =
    useState([]);

  const [overrideCategoryDropdownList, setOverrideCategoryDropdownList] =
    useState([]);
  const [overrideSelectedCategoryList, setOverrideSelectedCategoryList] =
    useState([]);

  ///////////////////////////////////////////////////////////////////////////////////////////

  // Override in array form

  const [overrideItems, setOverrideItems] = useState([
    {
      priceBeforeTax: 0,
      variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
      variationItems: [],
      variationItemsSku: [],
    },
  ]);

  const [variationItemsProducts, setVariationItemsProducts] = useState([]);
  const [variationItemsCategories, setVariationItemsCategories] = useState([]);

  ///////////////////////////////Cashback Function////////////////////////////////////////////

  const [cashbackPercentage, setCashbackPercentage] = useState("");
  const [cashbackMinQuantity, setCashbackMinQuantity] = useState("");
  const [cashbackExpired, setCashbackExpired] = useState("");

  const [cashbackExpiredDropdownList, setCashbackExpiredDropdownList] =
    useState([]);
  const [cashbackSelectedExpiredList, setCashbackSelectedExpiredList] =
    useState([]);

  const [cashbackProductDropdownList, setCashbackProductDropdownList] =
    useState([]);
  const [cashbackSelectedProductList, setCashbackSelectedProductList] =
    useState([]);

  const [cashbackCategoryDropdownList, setCashbackCategoryDropdownList] =
    useState([]);
  const [cashbackSelectedCategoryList, setCashbackSelectedCategoryList] =
    useState([]);

  ///////////////////////////////Buy 1 Free 1 Function////////////////////////////////////////////

  const [buyInput, setBuyInput] = useState("");
  const [getInput, setGetInput] = useState("");
  const [getPriceInput, setGetPriceInput] = useState("");

  const [buy1Free1ProductDropdownList, setBuy1Free1ProductDropdownList] =
    useState([]);
  const [buy1Free1SelectedProductList, setBuy1Free1SelectedProductList] =
    useState([]);

  const [selectedVariationB1F1, setSelectedVariationB1F1] = useState(
    PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
  );

  const [selectedVariationItemsB1F1, setSelectedVariationItemsB1F1] = useState(
    []
  );

  const [selectedVariationItemsSkuB1F1, setSelectedVariationItemsSkuB1F1] =
    useState([]); // for multi-outlets

  const [variationItemsB1F1DropdownList, setVariationItemsB1F1DropdownList] =
    useState([]);

  ///////////////////////////////Take amount off////////////////////////////////////////////

  const [amountOffPrice, setAmountOffPrice] = useState("0");
  const [amountOffMinQuantity, setAmountOffMinQuantity] = useState("0");
  const [amountOffMaxQuantity, setAmountOffMaxQuantity] = useState("0");

  const [amountOffProductDropdownList, setAmountOffProductDropdownList] =
    useState([]);
  const [amountOffSelectedProductList, setAmountOffSelectedProductList] =
    useState([]);

  const [amountOffCategoryDropdownList, setAmountOffCategoryDropdownList] =
    useState([]);
  const [amountOffSelectedCategoryList, setAmountOffSelectedCategoryList] =
    useState([]);

  ///////////////////////////////Take percentage off////////////////////////////////////////////

  const [percentageOffPrice, setPercentageOffPrice] = useState("0");
  const [percentageOffMinQuantity, setPercentageOffMinQuantity] = useState("0");
  const [percentageOffMaxQuantity, setPercentageOffMaxQuantity] = useState("0");

  const [
    percentageOffProductDropdownList,
    setPercentageOffProductDropdownList,
  ] = useState([]);
  const [
    percentageOffSelectedProductList,
    setPercentageOffSelectedProductList,
  ] = useState([]);

  const [
    percentageOffCategoryDropdownList,
    setPercentageOffCategoryDropdownList,
  ] = useState([]);
  const [
    percentageOffSelectedCategoryList,
    setPercentageOffSelectedCategoryList,
  ] = useState([]);

  ///////////////////////////////Buy A Get B For % Discount////////////////////////////////////////////

  const [percentageDiscount, setPercentageDiscount] = useState("0");

  ///////////////////////////////Buy AB Get Voucher////////////////////////////////////////////

  const [selectedVoucher, setSelectedVoucher] = useState([]);
  const [selectedVoucherItems, setSelectedVoucherItems] = useState([]);
  const merchantVouchers = CommonStore.useState((s) => s.merchantVouchers);

  ///////////////////////////////Delivery////////////////////////////////////////////

  const [myTextInput, setMyTextInput] = useState(React.createRef());
  const [deliveryFreeFlag, setDeliveryFreeFlag] = useState(false);
  const [deliveryFreeAboveAmount, setDeliveryFreeAboveAmount] = useState("0");
  const [deliveryDiscountAmount, setDeliveryDiscountAmount] = useState("0");
  const [deliveryDiscountAboveAmount, setDeliveryDiscountAboveAmount] =
    useState("0");

  ///////////////////////////////////Takeaway////////////////////////////////////////////////////////

  const [takeawayFreeFlag, setTakeawayFreeFlag] = useState(false);
  const [takeawayFreeAboveAmount, setTakeawayFreeAboveAmount] = useState("0");
  const [takeawayDiscountAmount, setTakeawayDiscountAmount] = useState("0");
  const [takeawayDiscountAboveAmount, setTakeawayDiscountAboveAmount] =
    useState("0");

  // common

  const [minSpend, setMinSpend] = useState("0");

  ///////////////////////////////////////////////////////////////////////////////////////////

  // Notification

  const [isEditNotification, setIsEditNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [isPushToSMS, setIsPushToSMS] = useState(false);
  const [isPushToApp, setIsPushToApp] = useState(true);
  const [isPushToEmail, setIsPushToEmail] = useState(false);
  const [notificationDescription, setNotificationDescription] = useState();
  const [showNotificationDatePicker, setShowNotificationDatePicker] =
    useState(false);
  const [showNotificationTimePicker, setShowNotificationTimePicker] =
    useState(false);
  const [notificationDate, setNotificationDate] = useState(moment());
  const [notificationTime, setNotificationTime] = useState(moment());
  const [notificationPromoCode, setNotificationPromoCode] = useState("");
  const [wordCount, setWordCount] = useState();

  ///////////////////////////////////////////////////////////////////////////////////////////

  // const [variationDropdownList, setVariationDropdownList] = useState(PROMOTION_TYPE_VARIATION_DROPDOWN_LIST);
  const [selectedVariation, setSelectedVariation] = useState(
    PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
  );

  const [variationItemsDropdownList, setVariationItemsDropdownList] = useState(
    []
  );
  const [selectedVariationItems, setSelectedVariationItems] = useState([]);

  const [selectedVariationItemsSku, setSelectedVariationItemsSku] = useState(
    []
  ); // for multi-outlets

  const [taxDropdownList, setTaxDropdownList] = useState([]);
  const [selectedTax, setSelectedTax] = useState("");
  const [selectedTaxName, setSelectedTaxName] = useState("");
  const [selectedTaxRate, setSelectedTaxRate] = useState(0);

  const [crmUserTagsDropdownList, setCrmUserTagsDropdownList] = useState([]);
  const [crmSegmentsDropdownList, setCrmSegmentsDropdownList] = useState([]);

  const [priceMin, setPriceMin] = useState("");
  const [priceMax, setPriceMax] = useState("");
  const [quantityMin, setQuantityMin] = useState("");
  const [quantityMax, setQuantityMax] = useState("");

  ///////////////////////////////////////////////////////////////////////////////////////////

  const [outletItems, setOutletItems] = useState([]);
  const [outletCategories, setOutletCategories] = useState([]);

  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const outletItemsUnsorted = OutletStore.useState((s) => s.outletItems);
  const outletCategoriesUnsorted = OutletStore.useState(
    (s) => s.outletCategories
  );
  const outletCategoriesDict = OutletStore.useState(
    (s) => s.outletCategoriesDict
  );
  const outletsTaxDict = OutletStore.useState((s) => s.outletsTaxDict);
  const currOutletTaxes = CommonStore.useState((s) => s.currOutletTaxes);
  const isLoading = CommonStore.useState((s) => s.isLoading);

  const selectedPromotionEdit = CommonStore.useState(
    (s) => s.selectedPromotionEdit
  );

  const merchantId = UserStore.useState((s) => s.merchantId);

  const allOutletsCategoriesUnique = OutletStore.useState(
    (s) => s.allOutletsCategoriesUnique
  );

  const crmUserTags = OutletStore.useState((s) => s.crmUserTags);
  const crmSegments = OutletStore.useState((s) => s.crmSegments);

  ///////////////////////////////////////////////////////////////////////////////////////////
  //Push Button
  const [promotionsNotifications, setPromotionsNotifications] = useState([]);
  const promotions = OutletStore.useState((s) => s.promotions);
  //const [isAutoPush, setIsAutoPush] = useState(false);
  //const [isLoading1, setIsLoading1] = useState(false);
  const [promotionPushed, setPromotionPushed] = useState(false);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const [importModal, setImportModal] = useState(false);
  const [showImportListModal, setShowImportListModal] = useState(false);
  const [importSmsModal, setImportSmsModal] = useState(false);
  //////////////////////////////

  const setState = () => { };

  // useEffect(() => {
  //   if (outletsTaxDict[currOutletId] && outletsTaxDict[currOutletId].length > 0) {
  //     setTaxDropdownList(outletsTaxDict[currOutletId].map(item => ({ label: item.name, value: item.uniqueId })));

  //     if (selectedTax === '') {
  //       setSelectedTax(outletsTaxDict[currOutletId][0]);
  //     }
  //   }
  // }, [outletsTaxDict, currOutletId]);

  /////////////////////////////////////////////////////////////
  const confirmDeletePromotion = (item) => {
    if (window.confirm("Delete: Are you sure you want to delete this promotion?") == true) {
      if (item) {
        deletePromotion(item);
      } else {
        alert('Delete empty item');
        linkTo && linkTo(`${prefix}/promotionlist`);
      }
    }
    return
  };

  const deletePromotion = (promotion) => {
    var body = {
      promotionId: promotion.uniqueId,
    };

    ApiClient.POST(API.deletePromotion, body)
      .then((result) => {
        if (result && result.status === "success") {
          if (window.confirm("Success: Promotion has been removed") == true) {
            linkTo && linkTo(`${prefix}/promotionlist`);
          }
        } else {
          alert("Error: Failed to remove this promotion");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /////////////////////////////////////////////////////////////

  useEffect(() => {
    console.log("================================");
    console.log("selectedPromotionEdit");
    console.log(selectedPromotionEdit);

    if (
      selectedPromotionEdit &&
      currOutletTaxes.length > 0 &&
      // && variationItemsDropdownList.length > 0
      taxDropdownList.length > 0
    ) {
      // insert info

      setCampaignName(selectedPromotionEdit.campaignName);
      setCampaignDescription(selectedPromotionEdit.campaignDescription);
      setIsEnableSellOnline(selectedPromotionEdit.isEnableSellOnline);
      setSelectedAvailability(
        selectedPromotionEdit.orderTypes || [ORDER_TYPE_DROP_DOWN_LIST[0].value]
      );
      setSelectedTargetUserGroup(selectedPromotionEdit.targetUserGroup);
      if (selectedPromotionEdit.targetSegmentGroupList) {
        setSelectedTargetSegmentGroupList(
          selectedPromotionEdit.targetSegmentGroupList
        );
      }
      if (selectedPromotionEdit.effectiveType) {
        setSelectedEffectiveType(selectedPromotionEdit.effectiveType);
      } else {
        setSelectedEffectiveType(EFFECTIVE_TYPE.DAY);
      }
      if (selectedPromotionEdit.effectiveTypeOptions) {
        setSelectedEffectiveTypeOptions(
          selectedPromotionEdit.effectiveTypeOptions
        );
      }
      setSelectedEffectiveDay(selectedPromotionEdit.effectiveDay);
      setEffectiveTimeStart(
        selectedPromotionEdit.effectiveTimeStart
          ? selectedPromotionEdit.effectiveTimeStart
          : moment()
      );
      setEffectiveTimeEnd(
        selectedPromotionEdit.effectiveTimeEnd
          ? selectedPromotionEdit.effectiveTimeEnd
          : moment()
      );

      setMinSpend(
        selectedPromotionEdit.minSpend
          ? selectedPromotionEdit.minSpend.toFixed(2)
          : "0"
      );

      setPromoCode(selectedPromotionEdit.promoCode);
      setIsPromoCodeUsageLimit(selectedPromotionEdit.isPromoCodeUsageLimit);
      if (selectedPromotionEdit.promoCodeUsageLimit) {
        setPromoCodeUsageLimit(
          selectedPromotionEdit.promoCodeUsageLimit.toFixed(0)
        );
      }
      setPromoDateStart(moment(selectedPromotionEdit.promoDateStart).toDate());
      setPromoDateEnd(moment(selectedPromotionEdit.promoDateEnd).toDate());
      setPromoTimeStart(moment(selectedPromotionEdit.promoTimeStart));
      setPromoTimeEnd(moment(selectedPromotionEdit.promoTimeEnd));
      setImage(selectedPromotionEdit.image);
      setIsImageChanged(false);
      setSelectedOutletList(selectedPromotionEdit.outletIdList);

      setSelectedPromotionType(selectedPromotionEdit.promotionType);

      if (
        selectedPromotionEdit.promotionType ===
        PROMOTION_TYPE.COMBO_SET_OR_PACKAGE_OR_BUNDLE
      ) {
        for (var i = 0; i < selectedPromotionEdit.criteriaList.length; i++) {
          const criteria = selectedPromotionEdit.criteriaList[i];

          setPackageItemPrice(criteria.priceBeforeTax.toFixed(2));
          setPackageMinQuantity(criteria.minQuantity.toFixed(0));
          setPackagePriceFinal(criteria.priceAfterTax);

          var currOutletTax = null;
          for (var j = 0; j < currOutletTaxes.length; j++) {
            if (criteria.taxId === currOutletTaxes[j].uniqueId) {
              currOutletTax = currOutletTaxes[j];
            }
          }

          if (!currOutletTax) {
            currOutletTax = currOutletTaxes[0];
          }

          setPackageTax(currOutletTax.uniqueId);

          setSelectedTax(currOutletTax.uniqueId);
          setSelectedTaxName(currOutletTax.name);
          setSelectedTaxRate(currOutletTax.rate);

          setSelectedVariation(criteria.variation);
          setSelectedVariationItems(criteria.variationItems);
          setSelectedVariationItemsSku(criteria.variationItemsSku);
        }
      } else if (
        selectedPromotionEdit.promotionType ===
        PROMOTION_TYPE.BUY_A_GET_B_FOR_FREE
      ) {
        for (var i = 0; i < selectedPromotionEdit.criteriaList.length; i++) {
          const criteria = selectedPromotionEdit.criteriaList[i];

          setBuyInput(criteria.buyAmount.toFixed(0));
          setGetInput(criteria.getAmount.toFixed(0));
          setGetPriceInput(criteria.getPrice.toFixed(2));

          setSelectedVariation(criteria.buyVariation);
          setSelectedVariationItems(criteria.buyVariationItems);
          setSelectedVariationItemsSku(criteria.buyVariationItemsSku);
          setSelectedVariationB1F1(criteria.getVariation);
          setSelectedVariationItemsB1F1(criteria.getVariationItems);
          setSelectedVariationItemsSkuB1F1(criteria.getVariationItemsSku);

          console.log("set items db");
        }
      } else if (
        selectedPromotionEdit.promotionType ===
        PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE
      ) {
        // for (var i = 0; i < selectedPromotionEdit.criteriaList.length; i++) {
        //   const criteria = selectedPromotionEdit.criteriaList[i];

        //   setOverrideItemPrice(criteria.priceBeforeTax.toFixed(2));
        //   setOverridePriceFinal(criteria.priceAfterTax);

        //   var currOutletTax = null;
        //   for (var j = 0; j < currOutletTaxes.length; j++) {
        //     if (criteria.taxId === currOutletTaxes[j].uniqueId) {
        //       currOutletTax = currOutletTaxes[j];
        //     }
        //   }

        //   if (!currOutletTax) {
        //     currOutletTax = currOutletTaxes[0];
        //   }

        //   setOverrideTax(currOutletTax.uniqueId);

        //   setSelectedTax(currOutletTax.uniqueId);
        //   setSelectedTaxName(currOutletTax.name);
        //   setSelectedTaxRate(currOutletTax.rate);

        //   setSelectedVariation(criteria.variation);
        //   setSelectedVariationItems(criteria.variationItems);
        //   setSelectedVariationItemsSku(criteria.variationItemsSku);
        // }

        setOverrideItems(selectedPromotionEdit.criteriaList);
      } else if (
        selectedPromotionEdit.promotionType === PROMOTION_TYPE.TAKE_AMOUNT_OFF
      ) {
        for (var i = 0; i < selectedPromotionEdit.criteriaList.length; i++) {
          const criteria = selectedPromotionEdit.criteriaList[i];

          setAmountOffPrice(criteria.amountOff.toFixed(2));
          setAmountOffMinQuantity(criteria.minQuantity.toFixed(0));
          setAmountOffMaxQuantity(criteria.maxQuantity.toFixed(0));

          setSelectedVariation(criteria.variation);
          setSelectedVariationItems(criteria.variationItems);
          setSelectedVariationItemsSku(criteria.variationItemsSku);

          setPriceMin(criteria.priceMin ? criteria.priceMin.toFixed(2) : "");
          setPriceMax(criteria.priceMax ? criteria.priceMax.toFixed(2) : "");
          setQuantityMin(
            criteria.quantityMin ? criteria.quantityMin.toFixed(0) : ""
          );
          setQuantityMax(
            criteria.quantityMax ? criteria.quantityMax.toFixed(0) : ""
          );
        }
      } else if (
        selectedPromotionEdit.promotionType ===
        PROMOTION_TYPE.TAKE_PERCENTAGE_OFF
      ) {
        for (var i = 0; i < selectedPromotionEdit.criteriaList.length; i++) {
          const criteria = selectedPromotionEdit.criteriaList[i];

          setPercentageOffPrice(criteria.percentageOff.toFixed(2));
          setPercentageOffMinQuantity(criteria.minQuantity.toFixed(0));
          setPercentageOffMaxQuantity(criteria.maxQuantity.toFixed(0));

          setSelectedVariation(criteria.variation);
          setSelectedVariationItems(criteria.variationItems);
          setSelectedVariationItemsSku(criteria.variationItemsSku);

          setPriceMin(criteria.priceMin ? criteria.priceMin.toFixed(2) : "");
          setPriceMax(criteria.priceMax ? criteria.priceMax.toFixed(2) : "");
          setQuantityMin(
            criteria.quantityMin ? criteria.quantityMin.toFixed(0) : ""
          );
          setQuantityMax(
            criteria.quantityMax ? criteria.quantityMax.toFixed(0) : ""
          );
        }
      } else if (
        selectedPromotionEdit.promotionType === PROMOTION_TYPE.DELIVERY
      ) {
        for (var i = 0; i < selectedPromotionEdit.criteriaList.length; i++) {
          const criteria = selectedPromotionEdit.criteriaList[i];

          setDeliveryFreeFlag(criteria.deliveryFreeFlag);
          setDeliveryFreeAboveAmount(
            criteria.deliveryFreeAboveAmount.toFixed(0)
          );
          setDeliveryDiscountAmount(criteria.deliveryDiscountAmount.toFixed(0));
          setDeliveryDiscountAboveAmount(
            criteria.deliveryDiscountAboveAmount.toFixed(0)
          );
        }
      } else if (
        selectedPromotionEdit.promotionType === PROMOTION_TYPE.TAKEAWAY
      ) {
        for (var i = 0; i < selectedPromotionEdit.criteriaList.length; i++) {
          const criteria = selectedPromotionEdit.criteriaList[i];

          setTakeawayFreeFlag(criteria.takeawayFreeFlag);
          setTakeawayFreeAboveAmount(
            criteria.takeawayFreeAboveAmount.toFixed(0)
          );
          setTakeawayDiscountAmount(criteria.takeawayDiscountAmount.toFixed(0));
          setTakeawayDiscountAboveAmount(
            criteria.takeawayDiscountAboveAmount.toFixed(0)
          );

          setSelectedVariation(criteria.variation);
          setSelectedVariationItems(criteria.variationItems);
          setSelectedVariationItemsSku(criteria.variationItemsSku);
        }
      }

      setIsEditNotification(selectedPromotionEdit.isEditNotification);

      if (selectedPromotionEdit.notification) {
        setNotificationTitle(selectedPromotionEdit.notification.title);
        setIsPushToSMS(selectedPromotionEdit.notification.isPushToSMS);
        setIsPushToApp(selectedPromotionEdit.notification.isPushToApp);
        setIsPushToEmail(selectedPromotionEdit.notification.isPushToEmail);
        setNotificationDescription(
          selectedPromotionEdit.notification.description
        );
        setNotificationDate(selectedPromotionEdit.notification.date);
        setNotificationTime(selectedPromotionEdit.notification.time);
        setNotificationPromoCode(selectedPromotionEdit.notification.promoCode);
      } else {
        setNotificationTitle("");
        setIsPushToSMS(false);
        setIsPushToApp(true);
        setIsPushToEmail(false);
        setNotificationDescription("");
        setNotificationDate(moment());
        setNotificationTime(moment());
        setNotificationPromoCode("");
      }

      //////////////////////////////////////////////////////////////////

      // var selectedOutletListTemp = [];
      // if (allOutletsItemsSkuDict[selectedPromotionEdit.sku]) {
      //   for (var i = 0; i < allOutletsItemsSkuDict[selectedPromotionEdit.sku].length; i++) {
      //     selectedOutletListTemp.push(allOutletsItemsSkuDict[selectedPromotionEdit.sku][i].outletId);
      //   }
      // }
      // setSelectedOutletList(selectedOutletListTemp);

      // setPlace(selectedPromotionEdit.outletId);
      // setItemName(selectedPromotionEdit.name);
      // setItemDescription(selectedPromotionEdit.description);
      // setImage(selectedPromotionEdit.image);
      // setItemPrice(selectedPromotionEdit.price.toFixed(2));

      // setSelectedOutletCategoryId(selectedPromotionEdit.categoryId);

      // if (selectedPromotionEdit.stockLinkItems) {
      //   setStockLinkItems(selectedPromotionEdit.stockLinkItems);
      // }
    } else {
      // designed to always mounted, thus need clear manually...

      setCampaignName("");
      setCampaignDescription("");
      setIsEnableSellOnline(false);
      setSelectedAvailability([ORDER_TYPE_DROP_DOWN_LIST[0].value]);
      setSelectedTargetUserGroup(TARGET_USER_GROUP_DROPDOWN_LIST[0].value);
      setSelectedTargetSegmentGroupList([CRM_SEGMENT_DROPDOWN_LIST[0].value]);
      setSelectedEffectiveType(EFFECTIVE_TYPE.DAY);
      setSelectedEffectiveTypeOptions([EFFECTIVE_DAY_DROPDOWN_LIST[0].value]);
      setSelectedEffectiveDay(EFFECTIVE_DAY_DROPDOWN_LIST[0].value);
      setPromoCode("");
      setIsPromoCodeUsageLimit(false);
      setPromoCodeUsageLimit("");
      setPromoDateStart(moment());
      setPromoDateEnd(moment());
      setPromoTimeStart(moment());
      setPromoTimeEnd(moment());
      setImage("");
      setIsImageChanged(false);
      clearImageContainer();
      setSelectedOutletList([]);

      setSelectedPromotionType(PROMOTION_TYPE_DROPDOWN_LIST[0].value);

      setPackageItemPrice("0");
      setPackageMinQuantity("0");
      setPackagePriceFinal(0);

      setOverrideItemPrice("0");
      setOverridePriceFinal(0);

      if (currOutletTaxes.length > 0) {
        setSelectedTax(currOutletTaxes[0].uniqueId);
        setSelectedTaxName(currOutletTaxes[0].name);
        setSelectedTaxRate(currOutletTaxes[0].rate);

        setPackageTax(currOutletTaxes[0].rate);
        setOverrideTax(currOutletTaxes[0].rate);
      } else {
        setSelectedTax("");
        setSelectedTaxName("");
        setSelectedTaxRate(0);

        setPackageTax("");
        setOverrideTax("");
      }

      setPriceMin("");
      setPriceMax("");
      setQuantityMin("");
      setQuantityMax("");

      // setSelectedVariation(PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS);
      // setSelectedVariationItems([]);
      // setSelectedVariationItemsSku([]);

      setOverrideItems([
        {
          priceBeforeTax: 0,
          variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
          variationItems: [],
          variationItemsSku: [],
        },
      ]);

      //////////////////////////////////////////////

      setSelectedVariationB1F1(PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS);
      setSelectedVariationItemsB1F1([]);
      setSelectedVariationItemsSkuB1F1([]);

      //////////////////////////////////////////////

      setIsEditNotification(false);
      setNotificationTitle("");
      setIsPushToSMS(false);
      setIsPushToApp(true);
      setIsPushToEmail(false);
      setNotificationDescription("");
      setNotificationDate(moment());
      setNotificationTime(moment());
      setNotificationPromoCode("");
    }
  }, [
    selectedPromotionEdit,
    currOutletTaxes,
    // variationItemsDropdownList,
    taxDropdownList,
  ]);

  useEffect(() => {
    console.log("crmUserTagsDropdownList");
    console.log(
      TARGET_USER_GROUP_DROPDOWN_LIST.concat(
        crmUserTags.map((item) => ({ label: item.name, value: item.uniqueId }))
      )
    );

    setCrmUserTagsDropdownList(
      TARGET_USER_GROUP_DROPDOWN_LIST.concat(
        crmUserTags.map((item) => ({ label: item.name, value: item.uniqueId }))
      )
    );
  }, [crmUserTags]);

  useEffect(() => {
    setCrmSegmentsDropdownList(
      crmSegments.map((segment) => ({
        label: segment.name,
        value: segment.uniqueId,
      }))
    );
  }, [crmSegments]);

  useEffect(() => {
    if (
      selectedPromotionType === PROMOTION_TYPE.COMBO_SET_OR_PACKAGE_OR_BUNDLE &&
      packageItemPrice.length > 0 &&
      parseFloat(packageItemPrice) >= 0
    ) {
      const packageItemPriceFloat = parseFloat(packageItemPrice);

      setPackagePriceFinal(
        packageItemPriceFloat + packageItemPriceFloat * selectedTaxRate
      );
    } else if (
      selectedPromotionType === PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE &&
      overrideItemPrice.length > 0 &&
      parseFloat(overrideItemPrice) >= 0
    ) {
      const overrideItemPriceFloat = parseFloat(overrideItemPrice);

      setOverridePriceFinal(
        overrideItemPriceFloat + overrideItemPriceFloat * selectedTaxRate
      );
    }
  }, [
    selectedPromotionType,

    packageItemPrice,
    overrideItemPrice,

    selectedTaxRate,
  ]);

  /////////////////////////////////////////////////////////////

  useEffect(() => {
    if (currOutletTaxes.length > 0) {
      setTaxDropdownList(
        currOutletTaxes.map((item) => ({
          label: item.name,
          value: item.uniqueId,
        }))
      );

      if (selectedTax === "") {
        setSelectedTax(currOutletTaxes[0].uniqueId);

        for (var i = 0; i < currOutletTaxes.length; i++) {
          if (currOutletTaxes[i].uniqueId === currOutletTaxes[0].uniqueId) {
            setSelectedTaxName(currOutletTaxes[i].name);
            setSelectedTaxRate(currOutletTaxes[i].rate);
            break;
          }
        }
      }
    }
  }, [currOutletTaxes, currOutletId]);

  ////////////////////////////////////////

  // change when pick better

  useEffect(() => {
    // var overrideItemsSkuArrayChangesList = [];

    var isDiff = false;

    var overrideItemsTemp = [];

    for (var index = 0; index < overrideItems.length; index++) {
      var skuArray = [];

      if (
        overrideItems[index].variation ===
        PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
      ) {
        for (var i = 0; i < outletItems.length; i++) {
          if (
            overrideItems[index].variationItems.includes(
              outletItems[i].uniqueId
            )
          ) {
            skuArray.push(outletItems[i].sku);
          }
        }
      } else if (
        overrideItems[index].variation ===
        PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
      ) {
        for (var i = 0; i < allOutletsCategoriesUnique.length; i++) {
          if (
            overrideItems[index].variationItems.includes(
              allOutletsCategoriesUnique[i].uniqueId
            )
          ) {
            skuArray.push(allOutletsCategoriesUnique[i].name);
          }
        }
      }

      isDiff = !areArraysEqual(
        skuArray,
        overrideItems[index].variationItemsSku
      );

      var overrideItemTemp = {
        priceBeforeTax: overrideItems[index].priceBeforeTax,
        variation: overrideItems[index].variation,
        variationItems: overrideItems[index].variationItems,
        variationItemsSku: skuArray,
      };

      overrideItemsTemp.push(overrideItemTemp);

      // if (isDiff) {

      //   // setOverrideItems(overrideItems.map((overrideItem, j) => (j === i ? {
      //   //   ...overrideItem,
      //   //   variationItemsSku: skuArray,
      //   // } : overrideItem)));

      //   // break;
      // }
    }

    if (isDiff) {
      setOverrideItems(overrideItemsTemp);
    }

    // setSelectedVariationItemsSku(selectedVariationItemsSkuTemp);
  }, [
    // selectedVariation,

    // selectedVariationItems,

    overrideItems,

    outletItems,

    allOutletsCategoriesUnique,
  ]);

  useEffect(() => {
    var selectedVariationItemsSkuTemp = [];

    if (selectedVariation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
      for (var i = 0; i < outletItems.length; i++) {
        if (selectedVariationItems.includes(outletItems[i].uniqueId)) {
          selectedVariationItemsSkuTemp.push(outletItems[i].sku);
        }
      }
    } else if (
      selectedVariation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
    ) {
      for (var i = 0; i < allOutletsCategoriesUnique.length; i++) {
        if (
          selectedVariationItems.includes(
            allOutletsCategoriesUnique[i].uniqueId
          )
        ) {
          selectedVariationItemsSkuTemp.push(
            allOutletsCategoriesUnique[i].name
          );
        }
      }
    }

    setSelectedVariationItemsSku(selectedVariationItemsSkuTemp);
  }, [
    selectedVariation,

    selectedVariationItems,
    outletItems,

    allOutletsCategoriesUnique,
  ]);

  useEffect(() => {
    var selectedVariationItemsSkuB1F1Temp = [];

    if (selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
      for (var i = 0; i < outletItems.length; i++) {
        if (selectedVariationItemsB1F1.includes(outletItems[i].uniqueId)) {
          selectedVariationItemsSkuB1F1Temp.push(outletItems[i].sku);
        }
      }
    } else if (
      selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
    ) {
      for (var i = 0; i < allOutletsCategoriesUnique.length; i++) {
        if (
          selectedVariationItemsB1F1.includes(
            allOutletsCategoriesUnique[i].uniqueId
          )
        ) {
          selectedVariationItemsSkuB1F1Temp.push(
            allOutletsCategoriesUnique[i].name
          );
        }
      }
    }

    setSelectedVariationItemsSkuB1F1(selectedVariationItemsSkuB1F1Temp);
  }, [
    selectedVariationB1F1,

    selectedVariationItemsB1F1,
    outletItems,

    allOutletsCategoriesUnique,
  ]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    if (selectedVariation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
      setVariationItemsDropdownList(
        allOutletsCategoriesUnique.map((item) => ({
          label: item.name,
          value: item.uniqueId,
        }))
      );

      if (allOutletsCategoriesUnique.length > 0 && !selectedPromotionEdit) {
        // setSelectedVariationItems([allOutletsCategoriesUnique[0].uniqueId]);
      } else {
        // if (selectedVariationItems.length > 0 && variationItemsDropdownList.find(item => item.value === selectedVariationItems[0]) === null) {
        //   console.log('clear items');
        //   setSelectedVariationItems([]);
        // }
      }
    } else if (
      selectedVariation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
    ) {
      setVariationItemsDropdownList(
        outletItems.map((item) => ({ label: item.name, value: item.uniqueId }))
      );

      if (outletItems.length > 0 && !selectedPromotionEdit) {
        // setSelectedVariationItems([outletItems[0].uniqueId]);
      } else {
        // if (selectedVariationItems.length > 0 && variationItemsDropdownList.find(item => item.value === selectedVariationItems[0]) === null) {
        //   setSelectedVariationItems([]);
        // }
      }
    }

    if (
      selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
    ) {
      setVariationItemsB1F1DropdownList(
        allOutletsCategoriesUnique.map((item) => ({
          label: item.name,
          value: item.uniqueId,
        }))
      );

      if (allOutletsCategoriesUnique.length > 0 && !selectedPromotionEdit) {
        // setSelectedVariationItemsB1F1([allOutletsCategoriesUnique[0].uniqueId]);
      } else {
        // if (selectedVariationItemsB1F1.length > 0 && variationItemsB1F1DropdownList.find(item => item.value === selectedVariationItemsB1F1[0]) === null) {
        //   setSelectedVariationItemsB1F1([]);
        // }
      }
    } else if (
      selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
    ) {
      setVariationItemsB1F1DropdownList(
        outletItems.map((item) => ({ label: item.name, value: item.uniqueId }))
      );

      if (outletItems.length > 0 && !selectedPromotionEdit) {
        // setSelectedVariationItemsB1F1([outletItems[0].uniqueId]);
      } else {
        // if (selectedVariationItemsB1F1.length > 0 && variationItemsB1F1DropdownList.find(item => item.value === selectedVariationItemsB1F1[0]) === null) {
        //   setSelectedVariationItemsB1F1([]);
        // }
      }
    }

    setVariationItemsProducts(
      outletItems.map((item) => ({ label: item.name, value: item.uniqueId }))
    );
    setVariationItemsCategories(
      allOutletsCategoriesUnique.map((item) => ({
        label: item.name,
        value: item.uniqueId,
      }))
    );
  }, [
    outletItems,
    // outletCategories,
    allOutletsCategoriesUnique,

    selectedVariation,
    selectedVariationB1F1,

    selectedVariationItems,
    selectedVariationItemsB1F1,

    selectedPromotionEdit,
  ]);

  ////////////////////////////////////////////////////////

  // to solve product not show

  useEffect(() => {
    if (
      selectedVariationItemsB1F1.length > 0 &&
      variationItemsB1F1DropdownList.find(
        (item) => item.value === selectedVariationItemsB1F1[0]
      ) === undefined
    ) {
      // setSelectedVariationItemsB1F1(variationItemsB1F1DropdownList.length > 0 ? [variationItemsB1F1DropdownList[0].uniqueId] : []);
      setSelectedVariationItemsB1F1([]);
    }

    console.log("condition");
    console.log(selectedVariationItems.length > 0);
    console.log(
      variationItemsDropdownList.find(
        (item) => item.value === selectedVariationItems[0]
      )
    );

    if (
      selectedVariationItems.length > 0 &&
      variationItemsDropdownList.find(
        (item) => item.value === selectedVariationItems[0]
      ) === undefined
    ) {
      // setSelectedVariationItems(variationItemsDropdownList.length > 0 ? [variationItemsDropdownList[0].uniqueId] : []);
      setSelectedVariationItems([]);
    }

    var overrideItemsTemp = [];

    for (var i = 0; i < overrideItems.length; i++) {
      var overrideItemTemp = {
        priceBeforeTax: overrideItems[i].priceBeforeTax,
        variation: overrideItems[i].variation,
        variationItems: overrideItems[i].variationItems,
        variationItemsSku: overrideItems[i].variationItemsSku,
      };

      if (
        overrideItems[i].variationItems.length > 0 &&
        variationItemsDropdownList.find(
          (item) => item.value === overrideItems[i].variationItems[0]
        ) === undefined
      ) {
        overrideItemTemp.variationItems = [];
      }

      overrideItemsTemp.push(overrideItemTemp);
    }

    setOverrideItems(overrideItemsTemp);
  }, [variationItemsDropdownList, variationItemsB1F1DropdownList]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    var outletItemsTemp = [...outletItemsUnsorted];

    outletItemsTemp.sort((a, b) => a.name.localeCompare(b.name));

    setOutletItems(outletItemsTemp);
  }, [outletItemsUnsorted]);

  useEffect(() => {
    var outletCategoriesTemp = [...outletCategoriesUnsorted];

    outletCategoriesTemp.sort((a, b) => a.name.localeCompare(b.name));

    setOutletCategories(outletCategoriesTemp);
  }, [outletCategoriesUnsorted]);

  useEffect(() => {
    setOutletDropdownList(
      allOutlets.map((item) => ({
        label: item.name,
        value: item.uniqueId,
      }))
    );
  }, [allOutlets]);

  // useEffect(() => {
  //   setAvailabilityDropdownList(ORDER_TYPE.map(item => ({
  //     label: item.name, value: item.uniqueId,
  //   })))
  // }, [ORDER_TYPE])

  useEffect(() => {
    setPackageTaxDropdownList();
  }, []);

  useEffect(() => {
    setPackageProductDropdownList();
  }, []);

  useEffect(() => {
    setPackageCategoryDropdownList();
  }, []);

  /////////

  useEffect(() => {
    setOverrideTaxDropdownList();
  }, []);

  useEffect(() => {
    setOverrideProductDropdownList();
  }, []);

  useEffect(() => {
    setOverrideCategoryDropdownList();
  }, []);

  ////////

  useEffect(() => {
    setCashbackExpiredDropdownList();
  }, []);

  useEffect(() => {
    setCashbackProductDropdownList();
  }, []);

  useEffect(() => {
    setCashbackCategoryDropdownList();
  }, []);

  ////////

  useEffect(() => {
    setBuy1Free1ProductDropdownList();
  }, []);

  ///////

  useEffect(() => {
    setAmountOffProductDropdownList();
  }, []);

  useEffect(() => {
    setAmountOffCategoryDropdownList();
  }, []);

  ////////

  useEffect(() => {
    setPercentageOffProductDropdownList();
  }, []);

  useEffect(() => {
    setPercentageOffCategoryDropdownList();
  }, []);

  ///////

  useEffect(() => {
    var promotionsNotificationsTemp = [];

    for (var i = 0; i < promotions.length; i++) {
      if (promotions[i].notification) {
        var pushToTargetArr = [];

        if (promotions[i].notification.isPushToSMS) {
          pushToTargetArr.push("SMS");
        }

        // if (promotions[i].notification.isPushToEmail) {
        //   pushToTargetArr.push('Email');
        // }

        if (promotions[i].notification.isPushToApp) {
          pushToTargetArr.push("App");
        }

        promotionsNotificationsTemp.push({
          ...promotions[i],
          notification: {
            ...promotions[i].notification,
            pushTargets: pushToTargetArr.join("/"),
          },
        });
      }
    }

    setPromotionsNotifications(promotionsNotificationsTemp);

    // setCurrentPage(1);
    // setPageCount(Math.ceil(promotionsNotificationsTemp.length / perPage));
  }, [promotions]);

  ///////

  //To remove unwanted sidebar
  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  //Header
  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            // bottom: -2,
            bottom: switchMerchant ? "2%" : 0,
          },
          Dimensions.get("screen").width >= 768 && switchMerchant
            ? { right: Dimensions.get("screen").width * 0.1 }
            : {},
          Dimensions.get("screen").width <= 768
            ? { right: Dimensions.get("screen").width * 0.12 }
            : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Promotion
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: Dimensions.get("screen").height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get("screen").height * 0.05,
              height: Dimensions.get("screen").height * 0.05,
              borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: Dimensions.get("screen").height * 0.035,
                height: Dimensions.get("screen").height * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  /////////////////////////////////////////////////

  const canOnlyPushOnce = () => {
    if (window.confirm("Notice: Each promotion can only be pushed once. Are you sure you want to proceed?") == true) {
      createPromotion(true);
    }
  };

  const createPromotion = async (isAutoPush = false) => {
    var message = "";

    if (!campaignName) {
      message += "Campaign name must be filled\n";
    }
    // if (!promoCode) {
    //   message += 'Promo code must be filled\n'
    // }
    if (selectedAvailability.length <= 0) {
      message += "Availability must be selected\n";
    }
    if (!campaignDescription) {
      message += "Campaign description must be filled\n";
    }
    // if (!image) {
    //   message += 'Campaign image must be selected\n'
    // }
    if (selectedOutletList.length <= 0) {
      message += "Outlet(s) must be selected\n";
    }

    if (message.length > 0) {
      alert(`Info: \n${message}`);

      return;
    } else {
      if (isPromoCodeUsageLimit) {
        if (!promoCodeUsageLimit) {
          alert("Error: Please fill in promo code usage");
          return;
        } else if (parseInt(promoCodeUsageLimit) <= 0) {
          alert("Error: Promo code usage must be more than 0");
          return;
        }
      }

      if (moment(promoDateEnd).isSameOrBefore(moment(promoDateStart))) {
        alert("Error: Promo start date must be before the Promo end date");
        return;
      } else if (moment(promoDateEnd).isSame(moment(promoDateStart), "day")) {
        alert("Error: Promo start date and Promo end date cannot be on the same day");
        return;
      }

      var notification = {};

      if (isEditNotification) {
        var atLeastOnePush = false;

        if (isPushToSMS || isPushToApp || isPushToEmail) {
          atLeastOnePush = true;
        }

        if (!atLeastOnePush) {
          alert("Error: Please select at least one push to target");
          return;
        }

        if (
          !notificationTitle ||
          !notificationDescription
          // !notificationPromoCode
        ) {
          alert("Error: Please fill in all required information:\nNotification Title\nNotification Description");
          return;
        }

        notification = {
          title: notificationTitle,
          description: notificationDescription,
          // promoCode: notificationPromoCode,
          isPushToApp: isPushToApp,
          isPushToEmail: isPushToEmail,
          isPushToSMS: isPushToSMS,
          date: moment(notificationDate).valueOf(),
          time: moment(notificationTime).valueOf(),
          isAutoPush: false,
        };
      }

      ////////////////////////////////////////////////////////////////

      var criteriaList = [];

      if (
        selectedPromotionType === PROMOTION_TYPE.COMBO_SET_OR_PACKAGE_OR_BUNDLE
      ) {
        if (!packageItemPrice || !packageMinQuantity) {
          alert("Error: Please fill in all required information:\nPackage Item Price\nPackage Min Quantity");
          return;
        }

        if (
          parseFloat(packageItemPrice) <= 0 ||
          parseInt(packageMinQuantity) <= 0
        ) {
          alert("Error: Package Item Price and Package Min Quantity must be more than 0");
          return;
        }

        if (selectedVariationItems.length <= 0) {
          alert("Error: Please select at least one product");
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          priceBeforeTax: +parseFloat(packageItemPrice).toFixed(2),
          minQuantity: parseInt(packageMinQuantity),
          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,
          priceAfterTax: +parseFloat(packagePriceFinal).toFixed(2),
          taxId: packageTax,
        });
      } else if (
        selectedPromotionType === PROMOTION_TYPE.BUY_A_GET_B_FOR_FREE
      ) {
        if (!buyInput || !getInput || getPriceInput === "") {
          alert("Error: Please fill in all required information:\nBuy amount\nGet amount\nGet price");
          return;
        }

        if (parseInt(buyInput) <= 0 || parseInt(getInput) <= 0) {
          alert("Error: Buy amount and Get amount must be more than 0");
          return;
        }

        if (parseFloat(getPriceInput) < 0) {
          alert("Error: Get price must be more or equal to 0");
          return;
        }

        if (
          selectedVariationItems.length <= 0 ||
          selectedVariationItemsB1F1.length <= 0
        ) {
          alert("Error: Please select at least one product for Buy and Get");
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          // buyAmount: parseFloat(buyInput),
          buyAmount: parseInt(buyInput),
          getAmount: parseInt(getInput),
          getPrice: parseFloat(getPriceInput),
          buyVariation: selectedVariation,
          buyVariationItems: selectedVariationItems,
          buyVariationItemsSku: selectedVariationItemsSku,
          getVariation: selectedVariationB1F1,
          getVariationItems: selectedVariationItemsB1F1,
          getVariationItemsSku: selectedVariationItemsSkuB1F1,
        });
      } else if (
        selectedPromotionType === PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE
      ) {
        criteriaList = overrideItems;
      } else if (selectedPromotionType === PROMOTION_TYPE.TAKE_AMOUNT_OFF) {
        if (
          !amountOffPrice ||
          // !amountOffMinQuantity ||
          // !amountOffMaxQuantity
          quantityMin.length === 0 ||
          quantityMax.length === 0 ||
          priceMin.length === 0
          // priceMax.length === 0
        ) {
          alert("Error: Please fill in all required information:\nOff Amount\nMin Quantity\nMax Quantity\nMin Amount");
          return;
        }

        if (
          parseFloat(amountOffPrice) <= 0 ||
          parseInt(quantityMin) <= 0 ||
          parseInt(quantityMax) <= 0
        ) {
          alert("Error: Off amount, Min quantity, and Max quantity must be more than 0");
          return;
        }

        if (selectedVariationItems.length <= 0) {
          alert("Error: Please select at least one product");
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          amountOff: +parseFloat(amountOffPrice).toFixed(2),
          minQuantity: parseInt(amountOffMinQuantity),
          maxQuantity: parseInt(amountOffMaxQuantity),
          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,

          quantityMin: quantityMin.length > 0 ? parseInt(quantityMin) : null,
          quantityMax: quantityMax.length > 0 ? parseInt(quantityMax) : null,
          priceMin: priceMin.length > 0 ? parseFloat(priceMin) : null,
          priceMax: priceMax.length > 0 ? parseFloat(priceMax) : null,
        });
      } else if (selectedPromotionType === PROMOTION_TYPE.TAKE_PERCENTAGE_OFF) {
        if (
          !percentageOffPrice ||
          // !percentageOffMinQuantity ||
          // !percentageOffMaxQuantity
          quantityMin.length === 0 ||
          quantityMax.length === 0 ||
          priceMin.length === 0
          // priceMax.length === 0
        ) {
          alert("Error: Please fill up all required information:\nOff Percentage\nMin Quantity\nMax Quantity\nMin Amount");
          return;
        }

        if (
          parseFloat(percentageOffPrice) <= 0 ||
          parseInt(quantityMin) <= 0 ||
          parseInt(quantityMax) <= 0
        ) {
          alert("Error: Off percentage, Min quantity, and Max quantity must be more than 0");
          return;
        }

        if (selectedVariationItems.length <= 0) {
          alert("Error: Please select at least one product");
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          percentageOff: +parseFloat(percentageOffPrice).toFixed(0),
          minQuantity: parseInt(percentageOffMinQuantity),
          maxQuantity: parseInt(percentageOffMaxQuantity),
          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,

          quantityMin: quantityMin.length > 0 ? parseInt(quantityMin) : null,
          quantityMax: quantityMax.length > 0 ? parseInt(quantityMax) : null,
          priceMin: priceMin.length > 0 ? parseFloat(priceMin) : null,
          priceMax: priceMax.length > 0 ? parseFloat(priceMax) : null,
        });
      } else if (selectedPromotionType === PROMOTION_TYPE.DELIVERY) {

        if (
          parseFloat(deliveryFreeAboveAmount) < 0 ||
          parseInt(deliveryDiscountAmount) < 0 ||
          parseInt(deliveryDiscountAboveAmount) < 0
        ) {
          alert("Error: Free delivery above, Discount, and Order above must be more or equal to 0");
          return;
        }

        if (selectedVariationItems.length <= 0) {
          alert("Error: Please select at least one product");
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          deliveryFreeFlag: deliveryFreeFlag,
          deliveryFreeAboveAmount: +parseFloat(deliveryFreeAboveAmount).toFixed(
            2
          ),
          deliveryDiscountAmount: +parseFloat(deliveryDiscountAmount).toFixed(
            2
          ),
          deliveryDiscountAboveAmount: +parseFloat(
            deliveryDiscountAboveAmount
          ).toFixed(2),
          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,
        });
      } else if (selectedPromotionType === PROMOTION_TYPE.TAKEAWAY) {

        if (
          parseFloat(takeawayFreeAboveAmount) < 0 ||
          parseInt(takeawayDiscountAmount) < 0 ||
          parseInt(takeawayDiscountAboveAmount) < 0
        ) {
          alert("Error: Free takeaway above, Discount, and Order above must be more or equal to 0");
          return;
        }

        if (selectedVariationItems.length <= 0) {
          alert("Error: Please select at least one product");
          return;
        }

        // maybe can add in item info in future to save bandwidth, for displaying purpose?

        criteriaList.push({
          takeawayFreeFlag: takeawayFreeFlag,
          takeawayFreeAboveAmount: +parseFloat(takeawayFreeAboveAmount).toFixed(
            2
          ),
          takeawayDiscountAmount: +parseFloat(takeawayDiscountAmount).toFixed(
            2
          ),
          takeawayDiscountAboveAmount: +parseFloat(
            takeawayDiscountAboveAmount
          ).toFixed(2),
          variation: selectedVariation,
          variationItems: selectedVariationItems,
          variationItemsSku: selectedVariationItemsSku,
        });
      }

      ////////////////////////////////////////////////////////////////

      var taxIdList = []; // for each outlet
      var categoryIdList = []; // for each outlet

      var taxName = "";
      var taxRate = 0.05;

      for (var i = 0; i < currOutletTaxes.length; i++) {
        if (currOutletTaxes[i].uniqueId === selectedTax) {
          taxName = currOutletTaxes[i].name;
          taxRate = currOutletTaxes[i].rate;
          break;
        }
      }

      var outletNames = [];

      for (var i = 0; i < allOutlets.length; i++) {
        for (var j = 0; j < selectedOutletList.length; j++) {
          if (selectedOutletList[j].includes(allOutlets[i].uniqueId)) {
            outletNames.push(allOutlets[i].name);
            break;
          }
        }
      }

      ///////////////////////////////////
      // upload image

      var promotionImagePath = "";
      var promotionCommonIdLocal = selectedPromotionEdit
        ? selectedPromotionEdit.commonId
        : uuidv4();

      if (image && imageType) {
        // promotionCommonIdLocal = uuidv4();

        promotionImagePath = await uploadImageToFirebaseStorage(
          {
            uri: image,
            type: imageType,
          },
          `/merchant/${merchantId}/promotion/${promotionCommonIdLocal}/image${imageType}`
        );
      }

      ///////////////////////////////////

      if (selectedPromotionEdit === null) {
        // means new item

        var body = {
          merchantId: merchantId,
          merchantName: merchantName,

          campaignName: campaignName,
          isEnableSellOnline: isEnableSellOnline,
          orderTypes: selectedAvailability,
          targetUserGroup: selectedTargetUserGroup,
          targetSegmentGroupList: selectedTargetSegmentGroupList,
          promoCode: promoCode || "",
          isPromoCodeUsageLimit: isPromoCodeUsageLimit,
          promoCodeUsageLimit: parseInt(promoCodeUsageLimit),
          image: promotionImagePath || merchantLogo,
          commonId: promotionCommonIdLocal,
          effectiveType: selectedEffectiveType,
          effectiveTypeOptions: selectedEffectiveTypeOptions,
          effectiveDay: selectedEffectiveDay,
          effectiveTimeStart: moment(effectiveTimeStart).valueOf(),
          effectiveTimeEnd: moment(effectiveTimeEnd).valueOf(),

          minSpend: parseFloat(minSpend),

          promoDateStart: moment(promoDateStart).valueOf(),
          promoDateEnd: moment(promoDateEnd).valueOf(),
          promoTimeStart: moment(promoTimeStart).valueOf(),
          promoTimeEnd: moment(promoTimeEnd).valueOf(),

          campaignDescription: campaignDescription,

          promotionType: selectedPromotionType,

          criteriaList: criteriaList,

          isEditNotification: isEditNotification,
          notification: isEditNotification ? notification : null,

          //////////////
          // multi outlet supports

          outletIdList: selectedOutletList,
          outletNameList: outletNames,

          currOutletTaxName: taxName,
          currOutletTaxRate: taxRate,
          currOutletTaxId: selectedTax,
          currOutletId: currOutletId,
        };

        console.log(body);

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ApiClient.POST(API.createPromotion, body, false).then((result) => {
          if (result && result.status === "success") {
            if (isAutoPush) {
              if (isEditNotification) {
                ApiClient.POST(API.switchPromotionNotificationAutoPushStatus, {
                  promotionId: result.promotion.uniqueId,
                  notificationAutoPushStatus: true,
                })
                  .then((result) => {
                    if (result && result.status === "success") {
                      if (window.confirm("Success: Promotion auto-push scheduled.") == true) {
                        linkTo && linkTo(`${prefix}/promotionlist`);
                      }
                    }
                    CommonStore.update((s) => {
                      s.isLoading = false;
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                ApiClient.POST(API.pushPromotionNotificationManual, body)
                  .then((result) => {
                    if (result && result.status === "success") {
                      if (window.confirm("Success: Promotion pushed") == true) {
                        linkTo && linkTo(`${prefix}/promotionlist`);
                      }
                    }
                    CommonStore.update((s) => {
                      s.isLoading = false;
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
            if (window.confirm("Success: Promotion added") == true) {
              linkTo && linkTo(`${prefix}/promotionlist`);
            }
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        });
      } else if (selectedPromotionEdit !== null) {
        // means existing item

        var body = {
          promotionId: selectedPromotionEdit.uniqueId,

          merchantId: merchantId,
          merchantName: merchantName,

          campaignName: campaignName,
          isEnableSellOnline: isEnableSellOnline,
          orderTypes: selectedAvailability,
          targetUserGroup: selectedTargetUserGroup,
          targetSegmentGroupList: selectedTargetSegmentGroupList,
          promoCode: promoCode || "",
          isPromoCodeUsageLimit: isPromoCodeUsageLimit,
          promoCodeUsageLimit: parseInt(promoCodeUsageLimit),

          image: promotionImagePath || merchantLogo,
          commonId: promotionCommonIdLocal,
          isImageChanged: isImageChanged,

          effectiveType: selectedEffectiveType,
          effectiveTypeOptions: selectedEffectiveTypeOptions,
          effectiveDay: selectedEffectiveDay,
          effectiveTimeStart: moment(effectiveTimeStart).valueOf(),
          effectiveTimeEnd: moment(effectiveTimeEnd).valueOf(),

          minSpend: parseFloat(minSpend),

          promoDateStart: moment(promoDateStart).valueOf(),
          promoDateEnd: moment(promoDateEnd).valueOf(),
          promoTimeStart: moment(promoTimeStart).valueOf(),
          promoTimeEnd: moment(promoTimeEnd).valueOf(),

          campaignDescription: campaignDescription,

          promotionType: selectedPromotionType,

          criteriaList: criteriaList,

          isEditNotification: isEditNotification,
          notification: isEditNotification ? notification : null,

          //////////////
          // multi outlet supports

          outletIdList: selectedOutletList,
          outletNameList: outletNames,

          currOutletTaxName: taxName,
          currOutletTaxRate: taxRate,
          currOutletTaxId: selectedTax,
          currOutletId: currOutletId,
        };

        console.log(body);

        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ApiClient.POST(API.updatePromotion, body, false).then((result) => {
          if (result && result.status === "success") {

            if (isAutoPush) {
              if (selectedPromotionEdit.sendQuota > 0) {
                if (isEditNotification) {
                  ApiClient.POST(
                    API.switchPromotionNotificationAutoPushStatus,
                    {
                      promotionId: selectedPromotionEdit.uniqueId,
                      notificationAutoPushStatus: true,
                    }
                  )
                    .then((result) => {
                      if (result && result.status === "success") {
                        if (window.confirm("Success: Promotion auto-push scheduled.") == true) {
                          linkTo && linkTo(`${prefix}/promotionlist`);
                        }
                      }
                      CommonStore.update((s) => {
                        s.isLoading = false;
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else {
                  ApiClient.POST(API.pushPromotionNotificationManual, body)
                    .then((result) => {
                      if (result && result.status === "success") {
                        if (window.confirm("Success: Promotion pushed.") == true) {
                          linkTo && linkTo(`${prefix}/promotionlist`);
                        }
                      }
                      CommonStore.update((s) => {
                        s.isLoading = false;
                      });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              } else {
                alert("Error: This promotion has been pushed before.");
              }
            }

            if (window.confirm("Success: Promotion has been updated.") == true) {
              linkTo && linkTo(`${prefix}/promotionlist`);
            }
          }

          CommonStore.update((s) => {
            s.isLoading = false;
          });
        });
      }
    }
  };

  /////////////////////////////////////////////////

  //Render start here
  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={11} expandPromotions={true} />
      </View>

      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView >
          {/* Modals */}

          <DateTimePickerModal
            isVisible={showPromoDateStartPicker}
            mode={"date"}
            onConfirm={(text) => {
              setPromoDateStart(moment(text));

              setShowPromoDateStartPicker(false);
            }}
            onCancel={() => {
              setShowPromoDateStartPicker(false);
            }}
          />

          <DateTimePickerModal
            isVisible={showPromoDateEndPicker}
            mode={"date"}
            onConfirm={(text) => {
              setPromoDateEnd(moment(text));

              setShowPromoDateEndPicker(false);
            }}
            onCancel={() => {
              setShowPromoDateEndPicker(false);
            }}
          />

          <DateTimePickerModal
            isVisible={showPromoTimeStartPicker}
            mode={"time"}
            onConfirm={(text) => {
              setPromoTimeStart(moment(text));

              setShowPromoTimeStartPicker(false);
            }}
            onCancel={() => {
              setShowPromoTimeStartPicker(false);
            }}
          />

          <DateTimePickerModal
            isVisible={showPromoTimeEndPicker}
            mode={"time"}
            onConfirm={(text) => {
              setPromoTimeEnd(moment(text));

              setShowPromoTimeEndPicker(false);
            }}
            onCancel={() => {
              setShowPromoTimeEndPicker(false);
            }}
          />

          <DateTimePickerModal
            isVisible={showEffectiveTimeStartPicker}
            mode={"time"}
            onConfirm={(text) => {
              setEffectiveTimeStart(moment(text));

              setShowEffectiveTimeStartPicker(false);
            }}
            onCancel={() => {
              setShowEffectiveTimeStartPicker(false);
            }}
          />

          <DateTimePickerModal
            isVisible={showEffectiveTimeEndPicker}
            mode={"time"}
            onConfirm={(text) => {
              setEffectiveTimeEnd(moment(text));

              setShowEffectiveTimeEndPicker(false);
            }}
            onCancel={() => {
              setShowEffectiveTimeEndPicker(false);
            }}
          />

          <DateTimePickerModal
            isVisible={showNotificationDatePicker}
            mode={"date"}
            onConfirm={(text) => {
              setNotificationDate(moment(text));

              setShowNotificationDatePicker(false);
            }}
            onCancel={() => {
              setShowNotificationDatePicker(false);
            }}
          />

          <DateTimePickerModal
            isVisible={showNotificationTimePicker}
            mode={"time"}
            onConfirm={(text) => {
              setNotificationTime(moment(text));

              setShowNotificationTimePicker(false);
            }}
            onCancel={() => {
              setShowNotificationTimePicker(false);
            }}
          />

          {/* Modals */}
          {/* ********************** Import Modal Start ************************ */}
          <Modal visible={importModal} transparent={true} animationType={"slide"}>
            <View style={[styles.modalContainer1, {}]}>
              <View
                style={[
                  styles.modalView,
                  {
                    width: 300,
                    height: 240,
                  },
                ]}
              >
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => {
                    // setState({ changeTable: false });
                    setImportModal(false);
                  }}
                >
                  <AntDesign
                    name="closecircle"
                    size={25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View style={styles.modalTitle}>
                  <Text style={[styles.modalTitleText, { fontSize: 16 }]}>
                    {/* Import Options */}
                    Import
                  </Text>
                </View>
                <View
                  style={{
                    alignItems: "center",
                    top: "10%",
                  }}
                >
                  <TouchableOpacity
                    style={[
                      styles.modalSaveButton,
                      {
                        zIndex: -1,
                      },
                    ]}
                    onPress={() => {
                      setShowImportListModal(true);
                    }}
                  >
                    <Text
                      style={[
                        styles.modalDescText,
                        { color: Colors.primaryColor },
                      ]}
                    >
                      Import Template (Excel)
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            visible={showImportListModal}
            transparent={true}
            animationType={"slide"}
          >
            <View style={[styles.modalContainer1, {}]}>
              <View
                style={[
                  styles.modalView1,
                  {
                    //width: 400, height: 300,
                  },
                ]}
              >
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => {
                    setShowImportListModal(false);
                  }}
                >
                  <AntDesign
                    name="closecircle"
                    size={25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View style={styles.modalTitle}>
                  <Text
                    style={[
                      styles.modalTitleText,
                      { fontSize: 16, fontWeight: "500" },
                    ]}
                  >
                    Imported List
                  </Text>
                </View>
                <View
                  style={{
                    //backgroundColor:'red',
                    height: 70,
                    marginVertical: 10,
                    marginTop: 15,
                    borderWidth: 1,
                    borderColor: "#E5E5E5",
                    height:
                      Platform.OS === "ios"
                        ? windowWidth * 0.57
                        : windowWidth * 0.47,
                    width:
                      Platform.OS === "ios"
                        ? windowWidth * 0.66
                        : windowWidth * 0.56,
                  }}
                >
                  <View style={{ width: "100%", marginTop: 0 }}>
                    <View
                      style={{
                        backgroundColor: Colors.whiteColor,
                        padding: 7,
                        paddingTop: 0,
                        height: "82%",
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <View
                          style={{
                            flexDirection: "row",
                            flex: 1.5,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              style={{
                                fontSize: 15,
                                color: Colors.fieldtTxtColor,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              Name
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            flex: 1.8,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              style={{
                                fontSize: 15,
                                color: Colors.fieldtTxtColor,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              Email
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            flex: 1.2,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              style={{
                                fontSize: 15,
                                color: Colors.fieldtTxtColor,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              Phone Number
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            flex: 0.8,
                            borderRightColor: "lightgrey",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <View style={{ flexDirection: "column" }}>
                            <Text
                              style={{
                                fontSize: 15,
                                color: Colors.fieldtTxtColor,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            ></Text>
                          </View>
                        </View>
                      </View>

                      <FlatList />
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableOpacity
                    style={{
                      borderWidth: 1,
                      borderRadius: 10,
                      padding: 5,
                      alignItems: "center",
                      height: 35,
                      width: 85,
                      borderColor: Colors.primaryColor,
                      backgroundColor: Colors.primaryColor,
                    }}
                    onPress={() => {
                      //importSelectFile()
                      //importTemplate()
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "600",
                        fontSize: 18,
                        textAlign: "center",
                        color: Colors.whiteColor,
                      }}
                    >
                      Import
                    </Text>
                  </TouchableOpacity>
                  <View
                    style={{ flexDirection: "row", justifyContent: "flex-end" }}
                  >
                    <TouchableOpacity
                      style={{
                        borderWidth: 1,
                        borderRadius: 10,
                        padding: 5,
                        alignItems: "center",
                        height: 35,
                        width: 85,
                        borderColor: Colors.primaryColor,
                        backgroundColor: Colors.whiteColor,
                        marginRight: 10,
                      }}
                      onPress={() => {
                        setImportModal(false);
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "600",
                          fontSize: 18,
                          textAlign: "center",
                          color: Colors.primaryColor,
                        }}
                      >
                        Cancel
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        borderWidth: 1,
                        borderRadius: 10,
                        padding: 5,
                        alignItems: "center",
                        height: 35,
                        width: 85,
                        borderColor: Colors.primaryColor,
                        backgroundColor: Colors.primaryColor,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "600",
                          fontSize: 18,
                          textAlign: "center",
                          color: "white",
                        }}
                      >
                        Submit
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            visible={importSmsModal}
            transparent={true}
            animationType={"slide"}
          >
            <View style={[styles.modalContainer1, {}]}>
              <View
                style={[
                  styles.modalView2,
                  {
                    //width: 400, height: 300,
                  },
                ]}
              >
                <TouchableOpacity
                  style={styles.closeButton}
                  onPress={() => {
                    // setState({ changeTable: false });
                    setImportSmsModal(false);
                  }}
                >
                  <AntDesign
                    name="closecircle"
                    size={25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View style={{}}>
                  <View style={{ height: windowHeight * 0.1 }}>
                    <Text
                      style={{
                        textAlign: "center",
                        fontWeight: "700",
                        fontSize: 30,
                      }}
                    >
                      Insufficient Credit!
                    </Text>
                  </View>

                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: windowHeight * 0.15,
                    }}
                  >
                    <Text
                      style={{
                        textAlign: "center",
                        color: Colors.descriptionColor,
                        fontSize: 25,
                        width: "100%",
                        alignSelf: "center",
                      }}
                    >
                      Please contact your account manager
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Modal>
          {/* ********************** Import Modal End ************************ */}

          <KeyboardAvoidingView>
            <View
              style={
                switchMerchant
                  ? {
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: windowWidth * 0.8,
                    marginHorizontal: 15,
                    //alignSelf: 'center',
                    margin: 10,
                    paddingHorizontal: 0,
                    paddingTop: 10,
                  }
                  : {
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: windowWidth * 0.9,
                    marginHorizontal: 15,
                    alignSelf: "center",
                    margin: 10,
                    paddingHorizontal: 0,
                    paddingTop: 10,
                  }
              }
            >
              <TouchableOpacity
                style={{ width: 90, height: 35, justifyContent: "center" }}
                onPress={() => {
                  linkTo && linkTo(`${prefix}/promotionlist`);
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    paddingHorizontal: "10%",
                    alignContent: "center",
                    alignItems: "center",
                    marginTop: switchMerchant ? 10 : 0,
                  }}
                >
                  <View style={{ justifyContent: "center" }}>
                    <Feather
                      name="chevron-left"
                      size={switchMerchant ? 20 : 30}
                      style={{ color: Colors.primaryColor, alignSelf: "center" }}
                    />
                  </View>
                  <Text
                    style={[
                      {
                        fontSize: 17,
                        color: Colors.primaryColor,
                        fontWeight: "600",
                        marginBottom: Platform.OS === "ios" ? 0 : 1,
                      },
                      switchMerchant
                        ? {
                          fontSize: 14,
                        }
                        : {},
                    ]}
                  >
                    Back
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

            <ScrollView
              showsVerticalScrollIndicator={false}
              nestedScrollEnabled={true}
              style={{
                backgroundColor: Colors.whiteColor,
                width: switchMerchant
                  ? windowWidth * 0.87
                  : windowWidth * 0.87,
                height: windowHeight * 0.825,
                marginTop: 0,
                marginHorizontal: switchMerchant ? 30 : 30,
                //alignSelf: 'center',
                borderRadius: 5,
                shadowOpacity: 0,
                shadowColor: "#000",
                shadowOffset: {
                  width: 1,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 3,

                marginBottom: switchMerchant ? 50 : 50,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    //flexDirection: 'row',
                    margin: 20,
                    marginBottom: 10,
                    width: "70%",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={[
                        { fontFamily: "NunitoSans-Bold", fontSize: 30 },
                        switchMerchant
                          ? {
                            fontSize: 20,
                          }
                          : {},
                      ]}
                    >
                      {campaignName.length > 0 ? campaignName : "New Campaign"}
                    </Text>
                  </View>

                  {campaignDescription.length > 0 ? (
                    <View
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        marginTop: 5,
                      }}
                    >
                      <Text
                        style={{ fontFamily: "NunitoSans-Regular", fontSize: 17 }}
                      >
                        {campaignDescription}
                      </Text>
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
                <View
                  style={{
                    margin: 20,
                    marginBottom: 10,
                  }}
                >
                  <View style={{}}>
                    <TouchableOpacity
                      style={[
                        {
                          justifyContent: "center",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          width: 130,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginBottom: 10,
                        },
                        switchMerchant
                          ? {
                            height: 35,
                            width: 120,
                          }
                          : {},
                      ]}
                      disabled={isLoading}
                      onPress={() => createPromotion()}
                    >
                      <Text
                        style={[
                          {
                            color: Colors.whiteColor,
                            fontSize: 16,
                            fontFamily: "NunitoSans-Bold",
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}
                      >
                        {isLoading ? "LOADING..." : "SAVE"}
                      </Text>

                      {isLoading ? (
                        <ActivityIndicator
                          color={Colors.whiteColor}
                          size={"small"}
                        />
                      ) : (
                        <></>
                      )}
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={[
                        {
                          justifyContent: "center",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderColor: Colors.tabRed,
                          backgroundColor: Colors.tabRed,
                          borderRadius: 5,
                          width: 130,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginBottom: 10,
                        },
                        switchMerchant
                          ? {
                            height: 35,
                            width: 120,
                          }
                          : {},
                      ]}
                      onPress={() => {
                        confirmDeletePromotion(selectedPromotionEdit);
                      }}
                    >
                      <Text
                        style={[
                          {
                            color: Colors.whiteColor,
                            fontSize: 16,
                            fontFamily: "NunitoSans-Bold",
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}
                      >
                        DELETE
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={[
                        {
                          justifyContent: "center",
                          flexDirection: "row",
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: "#4E9F7D",
                          borderRadius: 5,
                          width: 130,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: "center",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginBottom: 10,
                        },
                        switchMerchant
                          ? {
                            height: 35,
                            width: 120,
                          }
                          : {},
                      ]}
                      onPress={() => {
                        canOnlyPushOnce();
                      }}
                    >
                      <Text
                        style={[
                          {
                            color: Colors.whiteColor,
                            fontSize: 16,
                            fontFamily: "NunitoSans-Bold",
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}
                      >
                        {isLoading ? "LOADING..." : "PUBLISH"}
                      </Text>

                      {isLoading ? (
                        <ActivityIndicator
                          color={Colors.whiteColor}
                          size={"small"}
                        />
                      ) : (
                        <></>
                      )}
                    </TouchableOpacity>
                  </View>
                </View>
              </View>

              <View
                style={{
                  flexDirection: "column",
                  borderWidth: 1,
                  borderColor: "#c4c4c4",
                  width: "95%",
                  alignSelf: "center",
                  flex: 1,
                  paddingBottom: 30,
                }}
              >
                <View
                  style={{ flexDirection: "row", flex: 1, alignSelf: "center" }}
                >
                  <View
                    style={{
                      flex: 4,
                      flexDirection: "column",
                      marginVertical: 20,
                    }}
                  >
                    <View style={{ flexDirection: "column", marginLeft: 20 }}>
                      <TouchableOpacity
                        onPress={openFileSelector}
                      >
                        <View style={{ flexDirection: "row", zIndex: -2 }}>
                          {image ? (
                            <View
                              style={{
                                backgroundColor: "#F7F7F7",
                                borderRadius: 5,
                                zIndex: 1,
                              }}
                            >
                              <AsyncImage
                                source={{ uri: image }}
                                style={[
                                  { width: 260, height: 200, borderRadius: 5 },
                                  switchMerchant
                                    ? {
                                      width: 200,
                                      height: 160,
                                    }
                                    : {},
                                ]}
                                hideLoading={true}
                              />
                              <View
                                style={{
                                  position: "absolute",
                                  bottom: 5,
                                  right: 5,
                                  //opacity: 0.5,
                                }}
                              >
                                <Feather
                                  name="edit"
                                  size={switchMerchant ? 10 : 23}
                                  color={Colors.primaryColor}
                                />
                              </View>
                            </View>
                          ) : (
                            <View
                              style={[
                                {
                                  backgroundColor: "#F7F7F7",
                                  borderRadius: 5,
                                  width: 260,
                                  height: 200,
                                  alignItems: "center",
                                  justifyContent: "center",
                                },
                                switchMerchant
                                  ? {
                                    width: 200,
                                    height: 160,
                                  }
                                  : {},
                              ]}
                            >
                              <Icon1
                                name="upload"
                                size={switchMerchant ? 100 : 150}
                                color="lightgrey"
                                style={{ zIndex: -1 }}
                              />
                              <View
                                style={{
                                  position: "absolute",
                                  bottom: 5,
                                  right: 5,
                                  //opacity: 0.5,
                                }}
                              >
                                <Feather
                                  name="edit"
                                  size={switchMerchant ? 10 : 23}
                                  color={Colors.primaryColor}
                                />
                              </View>
                            </View>
                          )}
                        </View>
                      </TouchableOpacity>

                      <Text
                        style={[
                          {
                            fontWeight: "500",
                            marginTop: 10,
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}
                      >
                        Campaign Description
                      </Text>
                      <TextInput
                        style={[
                          {
                            marginTop: "2%",
                            padding: 5,
                            backgroundColor: Colors.fieldtBgColor,
                            width: Platform.OS == "ios" ? "90%" : "85%",
                            height: Platform.OS == "ios" ? 100 : 117,
                            borderRadius: 5,
                            borderWidth: 1,
                            borderColor: "#E5E5E5",
                            paddingTop: Platform.OS == "ios" ? 10 : 10,
                            paddingLeft: 10,
                            fontFamily: "NunitoSans-Regular",
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                              width: "92%",
                              height: 97,
                            }
                            : {},
                        ]}
                        textAlignVertical={"top"}
                        placeholderStyle={{
                          fontFamily: "NunitoSans-Regular",
                          fontSize: 14,
                        }}
                        placeholder="Description..."
                        placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                        defaultValue={campaignDescription}
                        multiline={true}
                        onChangeText={(text) => {
                          setCampaignDescription(text);
                        }}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      flex: 7,
                      marginVertical: 20,
                      marginHorizontal: 20,
                      marginLeft: 10,
                    }}
                  >
                    <View style={{ flexDirection: "row", flex: 1, zIndex: 1 }}>
                      <View
                        style={{
                          flex: 1,
                          marginRight: switchMerchant
                            ? "5%"
                            : windowWidth <= 1024
                              ? "3%"
                              : "2%",
                        }}
                      >
                        <Text
                          style={[
                            {
                              alignSelf: "flex-start",
                              fontFamily: "NunitoSans-Bold",
                              fontSize: 14,
                              fontWeight: "500",
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}
                        >
                          Campaign Name
                        </Text>
                        <TextInput
                          placeholder="Campaign Name"
                          placeholderTextColor={Platform.select({
                            ios: "#a9a9a9",
                          })}
                          placeholderStyle={{
                            fontFamily: "NunitoSans-Regular",
                            fontSize: 14,
                          }}
                          style={[
                            {
                              backgroundColor: Colors.fieldtBgColor,
                              width: 250,
                              height: 40,
                              borderRadius: 5,
                              padding: 5,
                              marginVertical: 5,
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                              paddingLeft: 10,
                              fontFamily: "NunitoSans-Regular",
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                                width: 200,
                                height: 35,
                              }
                              : {},
                          ]}
                          onChangeText={(text) => {
                            setCampaignName(text);
                          }}
                          defaultValue={campaignName}
                        />
                        {/************************Got Two Types of CHeckBox/ If want change CheckBox remember change the imported CheckBox *************************/}
                        <View style={{ marginTop: 0, zIndex: 1 }}>
                          <Text
                            style={[
                              {
                                alignSelf: "flex-start",
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Bold",
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                          >
                            Promo Date
                          </Text>
                          <View
                            style={{ flexDirection: "row", alignItems: "center" }}
                          >
                            <DatePicker
                              selected={moment(promoDateStart).toDate()}
                              onChange={(date) => {
                                setPromoDateStart(moment(date));
                              }}
                              maxDate={moment(promoDateEnd).toDate()}
                            />
                            <Text
                              style={[
                                {
                                  marginLeft: "1%",
                                  marginRight: "1%",
                                  fontWeight: "500",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                            >
                              to
                            </Text>
                            <DatePicker
                              selected={moment(promoDateEnd).toDate()}
                              onChange={(date) => {
                                setPromoDateEnd(moment(date));
                              }}
                              minDate={moment(promoDateStart).toDate()}
                            />
                          </View>
                        </View>
                        <Text
                          style={[
                            {
                              alignSelf: "flex-start",
                              fontWeight: "500",
                              fontFamily: "NunitoSans-Bold",
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}
                        >
                          Effective On Every
                        </Text>
                        <View style={{ marginTop: "2%" }}>
                          {[{ label: "Day", value: "DAY" }].find(
                            (item) => item.value === selectedEffectiveType
                          ) ? (
                            <Picker
                              style={[
                                {
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                },
                              ]}
                              placeholder={"Day"}
                              defaultValue={selectedEffectiveType}
                              disabled={true}
                            >
                              {[{ label: "Day", value: "DAY" }].map((value, index) => {
                                return (
                                  <Picker.Item
                                    key={index}
                                    label={value.label}
                                    value={value.value}
                                  />
                                );
                              })}
                            </Picker>
                          ) : null}
                        </View>
                        <View style={{ marginTop: "2%", zIndex: -2 }}>
                          <Text
                            style={[
                              {
                                alignSelf: "flex-start",
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Bold",
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                          >
                            Availability
                          </Text>
                          <View style={{ marginTop: "2%" }}>
                            <Select
                              style={[
                                {
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                },
                              ]}
                              defaultValue={selectedAvailability}
                              onChange={(items) => {
                                setSelectedAvailability(items);
                              }}
                              options={ORDER_TYPE_DROP_DOWN_LIST}
                              isMulti
                            />

                          </View>
                        </View>
                        <Text
                          style={[
                            {
                              fontWeight: "500",
                              zIndex: -3,
                              marginTop: 5,
                              fontFamily: "NunitoSans-Bold",
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}
                        >
                          Promotion Type
                        </Text>
                        <View style={{ marginTop: "2%", zIndex: -3 }}>
                          <Select
                            defaultValue={selectedPromotionType}
                            options={PROMOTION_TYPE_DROPDOWN_LIST}
                            onChange={(item) => {
                              setSelectedPromotionType(item.value);
                            }}
                            placeholder={"Select"}
                          />
                          {/* <Picker
                            selectedValue={selectedPromotionType}
                            style={{
                              width: 250,
                              paddingVertical: 0,
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 10,
                            }}
                            onValueChange={(item) => {
                              setSelectedPromotionType(item.value);
                            }}
                          >
                            {PROMOTION_TYPE_DROPDOWN_LIST.map((value, index) => {
                              return (
                                <Picker.Item
                                  key={index}
                                  label={value.label}
                                  value={value.value}
                                />
                              );
                            })}
                          </Picker> */}
                        </View>
                      </View>
                      <View style={{ flex: 1, zIndex: -1, marginTop: 1 }}>
                        <Text
                          style={[
                            {
                              fontWeight: "500",
                              fontFamily: "NunitoSans-Bold",
                              fontSize: 14,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}
                        >
                          Apply To Outlets
                        </Text>
                        <View style={{ marginTop: 4 }}>
                          <Select
                            style={[
                              {
                                width: "100%",
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              },
                            ]}
                            defaultValue={selectedOutletList}
                            onChange={(items) => {
                              setSelectedOutletList(items);
                            }}
                            options={outletDropdownList}
                            isMulti
                          />
                        </View>

                        <View style={{ marginTop: "2%", zIndex: -1 }}>
                          <Text
                            style={[
                              {
                                alignSelf: "flex-start",
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Bold",
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                          >
                            Promo Time
                          </Text>

                          <View
                            style={{ flexDirection: "row", alignItems: "center", }}
                          >
                            <TouchableOpacity
                              style={{
                                borderWidth: 1,
                                width: 120,
                                borderRadius: 5,
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                              onPress={() => {
                                setPromoClockTimePicker(!promoClockTimePicker);
                                setPromoClockTimePicker1(false);
                              }}
                            >
                              <Text>{moment(promoTimeStart).format("hh:mm A")}</Text>
                            </TouchableOpacity>
                            {promoClockTimePicker ? (
                              <View
                                style={{
                                  // borderWidth:10
                                }}
                              >
                                <View
                                  style={{
                                    position: "absolute",
                                    left: -130,
                                    top: 15,
                                    zIndex: 100,
                                  }}
                                >
                                  <TimeKeeper
                                    time={moment(promoTimeStart).format("hh:mm A")}
                                    onChange={(time) => {
                                      setPromoTimeStart(moment(`${moment(promoTimeStart).format("MM/DD/YYYY")} ${time.formatted12}`))
                                    }}
                                    onDoneClick={() => {
                                      setPromoClockTimePicker(false);
                                    }}
                                  ></TimeKeeper>
                                </View>
                              </View>
                            ) : null}

                            <Text
                              style={[
                                {
                                  width: "10%",
                                  fontWeight: "500",
                                  textAlign: "center",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 14,
                                },
                              ]}
                            >
                              to
                            </Text>

                            <TouchableOpacity
                              style={{
                                borderWidth: 1,
                                width: 120,
                                borderRadius: 5,
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                              onPress={() => {
                                setPromoClockTimePicker1(!promoClockTimePicker1);
                                setPromoClockTimePicker(false);
                              }}
                            >
                              <Text>{moment(promoTimeEnd).format("hh:mm A")}</Text>
                            </TouchableOpacity>
                            {promoClockTimePicker1 ? (
                              <View
                                style={{
                                  // borderWidth:10
                                }}
                              >
                                <View
                                  style={{
                                    position: "absolute",
                                    left: -130,
                                    top: 15,
                                    zIndex: 100,
                                  }}
                                >
                                  <TimeKeeper
                                    time={moment(promoTimeEnd).format("hh:mm A")}
                                    onChange={(time) => {
                                      setPromoTimeStart(moment(`${moment(promoTimeEnd).format("MM/DD/YYYY")} ${time.formatted12}`))
                                    }}
                                    onDoneClick={() => {
                                      setPromoClockTimePicker1(false);
                                    }}
                                  ></TimeKeeper>
                                </View>
                              </View>
                            ) : null}
                          </View>
                        </View>

                        <View style={{ flexDirection: "row", zIndex: -2 }}>
                          <Text
                            style={[
                              {
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Bold",
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                          >
                            Effective
                          </Text>
                          <Text
                            style={[
                              {
                                marginLeft: 5,
                                fontWeight: "500",
                                color: "red",
                                fontFamily: "NunitoSans-Bold",
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                          >
                            Options
                          </Text>
                        </View>

                        <View style={{ marginTop: "2%", zIndex: -2 }}>
                          <Select
                            style={[
                              {
                                width: "100%",
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              },
                            ]}
                            defaultValue={selectedEffectiveTypeOptions}
                            onChange={(items) => {
                              setSelectedEffectiveTypeOptions(items);
                            }}
                            options={EFFECTIVE_DAY_DROPDOWN_LIST}
                            isMulti
                          />
                        </View>
                        <View style={{ zIndex: 2, marginTop: "2%", zIndex: -3 }}>
                          <Text
                            style={[
                              {
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Bold",
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                          >
                            Target Segment(s)
                          </Text>

                          {CRM_SEGMENT_DROPDOWN_LIST.concat(
                            crmSegmentsDropdownList
                          ).find((item) =>
                            selectedTargetSegmentGroupList.includes(item.value)
                          ) ? (
                            <View style={{ marginTop: "2%" }}>
                              <Select
                                style={[
                                  {
                                    width: "100%",
                                    paddingVertical: 0,
                                    backgroundColor: Colors.fieldtBgColor,
                                    borderRadius: 10,
                                  },
                                ]}
                                defaultValue={selectedTargetSegmentGroupList}
                                onChange={(items) => {
                                  setSelectedTargetSegmentGroupList(items);
                                }}
                                options={CRM_SEGMENT_DROPDOWN_LIST.concat(
                                  crmSegmentsDropdownList
                                )}
                                isMulti
                              />
                            </View>
                          ) : (
                            <></>
                          )}
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: "2%",
                            zIndex: -4,
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              width: "60%",
                              zIndex: -1,
                            }}
                          >
                            <Text
                              style={[
                                {
                                  textAlign: "left",
                                  fontWeight: "500",
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                            >
                              Promo Code
                            </Text>
                          </View>

                          <View
                            style={{
                              width: "5%",
                            }}
                          ></View>

                          <View
                            style={{
                              width: "35%",
                            }}
                          >
                            <Text
                              style={[
                                {
                                  textAlign: "left",
                                  color: "black",
                                  fontWeight: "500",
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                            >
                              Usage Limit
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            marginTop: 5,
                            zIndex: -4,
                          }}
                        >
                          <View
                            style={{
                              width: "60%",
                              zIndex: -1,
                            }}
                          >
                            <TextInput
                              placeholder="Promo Code"
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{
                                fontFamily: "NunitoSans-Regular",
                                fontSize: 14,
                              }}
                              style={[
                                {
                                  backgroundColor: Colors.fieldtBgColor,
                                  // width: 160,
                                  height: 40,
                                  borderRadius: 5,
                                  padding: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  paddingLeft: 10,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    height: 35,
                                  }
                                  : {},
                              ]}
                              onChangeText={(text) => {
                                setPromoCode(text);
                              }}
                              defaultValue={promoCode}
                            />
                          </View>

                          <View
                            style={{
                              width: "5%",
                            }}
                          ></View>

                          <View
                            style={{
                              width: "35%",
                            }}
                          >
                            <TextInput
                              placeholder="100"
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{
                                fontFamily: "NunitoSans-Regular",
                                fontSize: 14,
                              }}
                              style={[
                                {
                                  backgroundColor: Colors.fieldtBgColor,
                                  // width: 80,
                                  height: 40,
                                  borderRadius: 5,
                                  padding: 5,
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  paddingLeft: 27,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    height: 35,
                                  }
                                  : {},
                              ]}
                              onChangeText={(text) => {
                                setPromoCodeUsageLimit(text);
                              }}
                              defaultValue={promoCodeUsageLimit}
                              keyboardType={"number-pad"}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={{ flexDirection: "column", marginTop: "5%" }}>

                    </View>
                  </View>
                </View>
                {/*//////////////////////////////////START////////////////////////////////////////*/}
                {/*//////////////////////////////////Combo Set/Package/Bundle/////////////////////*/}
                {/* here */}
                {selectedPromotionType ===
                  PROMOTION_TYPE.COMBO_SET_OR_PACKAGE_OR_BUNDLE ? (
                  <>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginBottom: 20,
                        marginRight: 20,
                        zIndex: -1,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontWeight: "500",
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}
                      >
                        Criteria
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "80%",
                          borderRadius: 3,
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <Text
                            style={{
                              fontWeight: "500",
                              fontFamily: "NunitoSans-Regular",
                              fontSize: 14,
                            }}
                          >
                            Package Price
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              marginTop: "1%",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: "1%",
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: 14,
                              }}
                            >
                              (RM)
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                //marginLeft: '1%',
                                height: 40,
                                width: 70,
                                textAlign: "center",
                                borderRadius: 5,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder={"0"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{
                                justifyContent: "center",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                setPackageItemPrice(text);
                              }}
                              defaultValue={packageItemPrice}
                              keyboardType={"number-pad"}
                            />
                            <Text
                              style={{
                                alignItems: "center",
                                marginLeft: "2%",
                                marginRight: "2%",
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              +
                            </Text>
                            <Picker
                              selectedValue={selectedTax}
                              style={{
                                width: 120,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              }}
                              onValueChange={(item) => {
                                setPackageTax(item.value);

                                setSelectedTax(item.value);

                                for (var i = 0; i < currOutletTaxes.length; i++) {
                                  if (
                                    currOutletTaxes[i].uniqueId === item.value
                                  ) {
                                    setSelectedTaxName(currOutletTaxes[i].name);
                                    setSelectedTaxRate(currOutletTaxes[i].rate);
                                    break;
                                  }
                                }
                              }}
                            >
                              {taxDropdownList.map((value, index) => {
                                return (
                                  <Picker.Item
                                    key={index}
                                    label={value.label}
                                    value={value.value}
                                  />
                                );
                              })}
                            </Picker>

                            <View
                              style={{
                                flexDirection: "column",
                                alignItems: "center",
                                marginLeft: "2%",
                              }}
                            >
                              <View style={{ flexDirection: "row" }}>
                                <Text
                                  style={{
                                    alignItems: "center",
                                    fontWeight: "500",
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                >
                                  =
                                </Text>
                                <Text
                                  style={{
                                    alignItems: "center",
                                    marginLeft: "3%",
                                    fontWeight: "500",
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                >
                                  (RM)
                                </Text>
                              </View>
                              <Text
                                style={{
                                  alignItems: "center",
                                  color: "#4E9F7D",
                                  fontSize: 10,
                                  fontStyle: "italic",
                                }}
                              >
                                *tax-inclusive
                              </Text>
                            </View>
                            <View
                              style={{
                                marginLeft: "2%",
                                justifyContent: "center",
                                width: 80,
                                height: 40,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingRight: 2,
                                borderRadius: 5,
                              }}
                            >
                              <Text
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {packagePriceFinal
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                              width: "100%",
                              marginTop: "3%",
                              marginBottom: "3%",
                            }}
                          />
                          <View style={{ flexDirection: "row" }}>
                            <Text style={{ fontWeight: "500" }}>Product</Text>
                            <Text
                              style={{ marginLeft: "67%", fontWeight: "500" }}
                            >
                              Min. Quantity
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginTop: "1%",
                            }}
                          >
                            <Picker
                              selectedValue={selectedVariation}
                              style={{
                                width: 190,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                                marginTop: "2%",
                              }}
                              onValueChange={(item) => {
                                setSelectedVariation(item.value)
                              }}
                            >
                              {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                return (
                                  <Picker.Item
                                    key={index}
                                    label={value.label}
                                    value={value.value}
                                  />
                                );
                              })}
                            </Picker>

                            <Text
                              style={{
                                alignItems: "center",
                                marginLeft: "2%",
                                marginRight: "2%",
                                fontWeight: "500",
                              }}
                            >
                              +
                            </Text>

                            {variationItemsDropdownList.length > 0 &&
                              (variationItemsDropdownList.find(
                                (item) => item.value === selectedVariationItems
                              ) ||
                                selectedVariationItems.length === 0) ? (
                              <Select
                                style={{
                                  width: 190,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  marginTop: "2%",
                                }}
                                options={variationItemsDropdownList}
                                onChange={(items) =>
                                  setSelectedVariationItems(items)
                                }
                                defaultValue={selectedVariationItems}
                                isMulti
                              />
                            ) : (
                              <></>
                            )}

                            <Text
                              style={{
                                alignItems: "center",
                                marginLeft: "2%",
                                marginRight: "2%",
                                fontWeight: "500",
                              }}
                            >
                              +
                            </Text>

                            <TextInput
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                marginLeft: "2%",
                                height: 40,
                                width: 70,
                                textAlign: "center",
                                borderRadius: 5,
                              }}
                              placeholder={"0"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{ justifyContent: "center" }}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                setPackageMinQuantity(text);
                              }}
                              defaultValue={packageMinQuantity}
                              keyboardType={"number-pad"}
                            />
                          </View>
                        </View>
                      </View>

                      <View style={{ marginTop: 15, width: "80%" }}>
                        <TouchableOpacity
                          style={{
                            alignSelf: "flex-end",
                            flexDirection: "row",
                            justifyContent: "space-around",
                            alignItems: "center",
                            borderRadius: 10,
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            width: 125,
                            height: 35,
                          }}
                          onPress={() => { }}
                        >
                          <View
                            style={{
                              backgroundColor: Colors.primaryColor,
                              alignItems: "center",
                              paddingLeft: 1.5,
                              borderRadius: 200,
                              width: 20,
                              height: 20,
                            }}
                          >
                            <Icon name="add" color="white" size={18} />
                          </View>
                          <View style={{}}>
                            <Text
                              style={{
                                alignSelf: "center",
                                color: Colors.primaryColor,
                              }}
                            >
                              Add Criteria
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -1,
                      }}
                    >
                      <Text style={{ fontWeight: "500" }}>
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "79%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Regular",
                                  fontWeight: "500",
                                }}
                              >
                                Send Date
                              </Text>
                              <GCalendar
                                width={20}
                                height={20}
                                style={{ marginRight: 5 }}
                              />
                              <DatePicker
                                selected={moment(notificationDate).toDate()}
                                onChange={(date) => {
                                  setNotificationDate(moment(date));
                                }}
                                minDate={moment(Date.now()).toDate()}
                              />
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Regular",
                                  fontWeight: "500",
                                }}
                              >
                                Send Time
                              </Text>

                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                {/*///////////////////////////////////Buy A Get B For Free////////////////////////*/}

                {selectedPromotionType === PROMOTION_TYPE.BUY_A_GET_B_FOR_FREE ? (
                  <>
                    <View
                      style={{
                        marginHorizontal: 20,
                        marginBottom: 20,
                        marginTop: 10,
                        zIndex: -1,
                      }}
                    >
                      <View style={{ width: "100%" }}>
                        <View style={{ marginBottom: 5 }}>
                          <Text
                            style={{
                              fontWeight: "500",
                              fontFamily: "NunitoSans-Bold",
                              fontSize: switchMerchant ? 10 : 14,
                            }}
                          >
                            Criteria
                          </Text>
                        </View>

                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: "#E5E5E5",
                            borderRadius: 3,
                            padding: 13,
                            paddingTop: 10,
                            width: 690,
                            alignItems: "flex-start",
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", alignItems: "center" }}
                          >
                            <Text
                              style={{
                                fontWeight: "500",
                                marginRight: 2,
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              Buy
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                                height: switchMerchant ? 35 : 40,
                                width: 40,
                                justifyContent: "center",
                                textAlign: "center",
                                margin: 6,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder={"0"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{
                                justifyContent: "center",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                setBuyInput(text);
                              }}
                              defaultValue={buyInput}
                              keyboardType={"number-pad"}
                            />
                            <View style={{ marginRight: 5 }}>
                              <Picker
                                selectedValue={selectedVariation}
                                style={{
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                onValueChange={(item) => {
                                  setSelectedVariation(item.value);
                                }}
                              >
                                {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker>
                            </View>

                            {variationItemsDropdownList.length > 0 &&
                              (variationItemsDropdownList.find(
                                (item) => item.value === selectedVariationItems[0]
                              ) ||
                                selectedVariationItems.length === 0) ? (
                              <Select
                                style={{
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                options={variationItemsDropdownList}
                                onChange={(items) =>
                                  setSelectedVariationItems(items)
                                }
                                defaultValue={selectedVariationItems}
                                isMulti
                              />
                            ) : (
                              <></>
                            )}
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              zIndex: -1,
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: "500",
                                marginRight: 4,
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              Get
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                                height: switchMerchant ? 35 : 40,
                                width: 40,
                                justifyContent: "center",
                                textAlign: "center",
                                margin: 6,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder={"0"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{
                                justifyContent: "center",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                setGetInput(text);
                              }}
                              defaultValue={getInput}
                              keyboardType={"number-pad"}
                            />

                            <View style={{ marginRight: 5 }}>
                              <Picker
                                selectedValue={selectedVariationB1F1}
                                style={{
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                onValueChange={(item) => {
                                  setSelectedVariationB1F1(item.value);
                                }}
                              >
                                {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.slice(1).map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker>
                            </View>

                            {variationItemsB1F1DropdownList.length > 0 &&
                              (variationItemsB1F1DropdownList.find(
                                (item) =>
                                  item.value === selectedVariationItemsB1F1[0]
                              ) ||
                                selectedVariationItemsB1F1.length === 0) ? (
                              <Select
                                style={{
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                }}
                                options={variationItemsB1F1DropdownList}
                                onChange={(items) =>
                                  setSelectedVariationItemsB1F1(items)
                                }
                                defaultValue={selectedVariationItemsB1F1}
                                isMulti
                              />
                            ) : (
                              <></>
                            )}
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              zIndex: -2,
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: "500",
                                marginRight: 5,
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              Item(s) for
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                                height: switchMerchant ? 35 : 40,
                                width: 80,
                                justifyContent: "center",
                                textAlign: "center",
                                margin: 5,
                                width: "20%",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              placeholder={"0.00"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{
                                justifyContent: "center",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                setGetPriceInput(text);
                              }}
                              defaultValue={getPriceInput}
                              keyboardType={"number-pad"}
                            />

                            <Text
                              style={{
                                fontWeight: "500",
                                marginRight: 5,
                                marginLeft: 15,
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              Min Spend Amount (RM)
                            </Text>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                                height: switchMerchant ? 35 : 40,
                                width: 80,
                                justifyContent: "center",
                                textAlign: "center",
                                margin: 5,
                                width: "20%",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                                //left: 8
                              }}
                              placeholder={"0.00"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{
                                justifyContent: "center",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              onChangeText={(text) => {
                                setMinSpend(text);
                              }}
                              defaultValue={minSpend}
                              keyboardType={"number-pad"}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -3,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "500",
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 10 : 14,
                        }}
                      >
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "79%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontWeight: "500",
                                }}
                              >
                                Send Date
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  width: 120
                                }}>

                                <GCalendar
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 5 }}
                                />
                                <DatePicker
                                  selected={moment(notificationDate).toDate()}
                                  onChange={(date) => {
                                    setNotificationDate(moment(date));
                                  }}
                                  minDate={moment(Date.now()).toDate()}
                                />
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontWeight: "500",
                                }}
                              >
                                Send Time
                              </Text>
                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}
                {/*///////////////////////////////////Override Existing Price/////////////////////*/}

                {console.log("==========================================")}
                {console.log(selectedVariation)}
                {console.log(variationItemsDropdownList)}
                {console.log(selectedVariationItems)}

                {selectedPromotionType ===
                  PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE ? (
                  <>
                    <View
                      style={{
                        marginHorizontal: 20,
                        marginTop: 10,
                        marginBottom: 20,
                        zIndex: -1,
                      }}
                    >
                      <View style={{ width: "90%" }}>
                        <View style={{ marginBottom: 5 }}>
                          <Text
                            style={[
                              {
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Bold",
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                          >
                            Criteria
                          </Text>
                        </View>

                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: "#E5E5E5",
                            borderRadius: 3,
                            padding: 13,
                            paddingTop: 10,
                            zIndex: -1,
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", alignItems: "center" }}
                          >
                            <Text
                              style={[
                                {
                                  fontWeight: "500",
                                  marginRight: 15,
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                            >
                              Min Spend Amount (RM)
                            </Text>
                            <TextInput
                              style={[
                                {
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  borderRadius: 5,
                                  height: switchMerchant ? 35 : 40,
                                  width: 80,
                                  justifyContent: "center",
                                  textAlign: "center",
                                  margin: 5,
                                  width: "20%",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    height: 35,
                                    width: 50,
                                  }
                                  : {},
                              ]}
                              placeholder={"0.00"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{
                                justifyContent: "center",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              onChangeText={(text) => {
                                setMinSpend(text);
                              }}
                              defaultValue={minSpend}
                              keyboardType={"number-pad"}
                            />
                          </View>
                          {/* ~~~~~~~BorderLine~~~~~~~~~~ */}
                          <View
                            style={{
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                              marginHorizontal: -13,
                              marginVertical: 12,
                              zIndex: -1,
                            }}
                          />

                          <View style={{ flexDirection: "row", zIndex: -1 }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  {
                                    fontWeight: "500",
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Product
                              </Text>

                              <View>
                                {overrideItems.map((overrideItem, index) => {
                                  return (
                                    <View
                                      style={{
                                        flexDirection: "row",
                                        marginTop: 5,
                                        zIndex:
                                          10001 + overrideItems.length - index,
                                      }}
                                    >
                                      <View style={{ marginRight: 10 }}>
                                        {overrideItem.variation ? (
                                          <Picker
                                            style={[
                                              {
                                                width: 250,
                                                paddingVertical: 0,
                                                backgroundColor:
                                                  Colors.fieldtBgColor,
                                                borderRadius: 10,
                                                fontSize: switchMerchant
                                                  ? 10
                                                  : 14,
                                              },
                                            ]}
                                            onValueChange={(itemDropdown) => {
                                              setOverrideItems(
                                                overrideItems.map((item, i) =>
                                                  i === index
                                                    ? {
                                                      ...item,
                                                      variation:
                                                        itemDropdown.value,
                                                    }
                                                    : item
                                                )
                                              );
                                            }}
                                            selectedValue={overrideItem.variation}
                                          >
                                            {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                              return (
                                                <Picker.Item
                                                  key={index}
                                                  label={value.label}
                                                  value={value.value}
                                                />
                                              );
                                            })}
                                          </Picker>
                                        ) : null}
                                      </View>

                                      {/* {console.log()} */}

                                      {(
                                        overrideItem.variation ===
                                          PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                          ? variationItemsCategories.length > 0 &&
                                          (variationItemsCategories.find(
                                            (item) =>
                                              item.value ===
                                              overrideItem.variationItems[0]
                                          ) ||
                                            overrideItem.variationItems
                                              .length === 0)
                                          : variationItemsProducts.length > 0 &&
                                          (variationItemsProducts.find(
                                            (item) =>
                                              item.value ===
                                              overrideItem.variationItems[0]
                                          ) ||
                                            overrideItem.variationItems
                                              .length === 0)
                                      ) ? (
                                        // variationItemsDropdownList.length > 0
                                        //   && (variationItemsDropdownList.find(item => item.value === overrideItem.variationItems[0]) || overrideItem.variationItems.length === 0)
                                        <Select
                                          style={[
                                            {
                                              width: 250,
                                              paddingVertical: 0,
                                              backgroundColor:
                                                Colors.fieldtBgColor,
                                              borderRadius: 10,
                                              fontFamily: "NunitoSans-Regular",
                                              fontSize: switchMerchant ? 10 : 14,
                                            },
                                          ]}
                                          options={
                                            overrideItem.variation ===
                                              PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
                                              ? variationItemsCategories
                                              : variationItemsProducts
                                          }
                                          onChange={(items) => {
                                            setOverrideItems(
                                              overrideItems.map((item, i) =>
                                                i === index
                                                  ? {
                                                    ...item,
                                                    variationItems: items,
                                                  }
                                                  : item
                                              )
                                            );
                                          }}
                                          defaultValue={overrideItem.variationItems}
                                          isMulti
                                        />
                                      ) : (
                                        <></>
                                      )}

                                      <View
                                        style={{
                                          flexDirection: "row",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Text
                                          style={[
                                            {
                                              fontWeight: "500",
                                              marginRight: 5,
                                              marginLeft: 15,
                                              bottom: 5,
                                              fontFamily: "NunitoSans-Regular",
                                              fontSize: 14,
                                            },
                                            switchMerchant
                                              ? {
                                                fontSize: 10,
                                              }
                                              : {},
                                          ]}
                                        >
                                          RM
                                        </Text>
                                        <TextInput
                                          style={[
                                            {
                                              borderWidth: 1,
                                              borderColor: "#E5E5E5",
                                              borderRadius: 5,
                                              height: switchMerchant ? 35 : 40,
                                              width: 80,
                                              justifyContent: "center",
                                              textAlign: "center",
                                              margin: 5,
                                              bottom: 5,
                                              fontFamily: "NunitoSans-Regular",
                                              fontSize: 14,
                                              // paddingTop: 2,
                                            },
                                            switchMerchant
                                              ? {
                                                fontSize: 10,
                                                height: 35,
                                                width: 50,
                                              }
                                              : {},
                                          ]}
                                          placeholder={"0"}
                                          placeholderTextColor={Platform.select({
                                            ios: "#a9a9a9",
                                          })}
                                          placeholderStyle={{
                                            justifyContent: "center",
                                            fontFamily: "NunitoSans-Regular",
                                            fontSize: switchMerchant ? 10 : 14,
                                          }}
                                          onChangeText={(text) => {
                                            // setState({ itemPrice: text });
                                            // setOverrideItemPrice(text);

                                            setOverrideItems(
                                              overrideItems.map((item, i) =>
                                                i === index
                                                  ? {
                                                    ...item,
                                                    priceBeforeTax:
                                                      text.length > 0
                                                        ? parseFloat(text)
                                                        : 0,
                                                  }
                                                  : item
                                              )
                                            );
                                          }}
                                          defaultValue={overrideItem.priceBeforeTax
                                            .toFixed(2)
                                            .replace(
                                              /(\d)(?=(\d{3})+(?!\d))/g,
                                              "$1,"
                                            )}
                                          keyboardType={"number-pad"}
                                        />

                                        <View
                                          style={{
                                            marginLeft: 10,
                                            bottom: 5,
                                          }}
                                        >
                                          {index === 0 ? (
                                            <TouchableOpacity
                                              onPress={() => {
                                                // onadditem1();

                                                setOverrideItems([
                                                  ...overrideItems,
                                                  {
                                                    priceBeforeTax: 0,
                                                    variation:
                                                      PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
                                                    variationItems: [],
                                                    variationItemsSku: [],
                                                  },
                                                ]);
                                              }}
                                              style={{
                                                marginBottom: "3%",
                                                backgroundColor:
                                                  Colors.whiteColor,
                                                alignItems: "center",
                                                flexDirection: "row",
                                              }}
                                            >
                                              <Feather
                                                name="plus-circle"
                                                size={switchMerchant ? 17 : 20}
                                                color={Colors.primaryColor}
                                              />
                                            </TouchableOpacity>
                                          ) : (
                                            <TouchableOpacity
                                              style={{ marginBottom: "3%" }}
                                              onPress={() => {
                                                setOverrideItems([
                                                  ...overrideItems.slice(
                                                    0,
                                                    index
                                                  ),
                                                  ...overrideItems.slice(
                                                    index + 1
                                                  ),
                                                ]);
                                              }}
                                            >
                                              <Feather
                                                name="minus-circle"
                                                size={switchMerchant ? 17 : 20}
                                                color="#eb3446"
                                              />
                                            </TouchableOpacity>
                                          )}
                                        </View>
                                      </View>
                                    </View>
                                  );
                                })}
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -3,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontWeight: "500",
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}
                      >
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "90%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: "NunitoSans-Bold",
                                    fontWeight: "500",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Send Date
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  width: 120
                                }}>

                                <GCalendar
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 5 }}
                                />
                                <DatePicker
                                  selected={moment(notificationDate).toDate()}
                                  onChange={(date) => {
                                    setNotificationDate(moment(date));
                                  }}
                                  minDate={moment(Date.now()).toDate()}
                                />
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: "NunitoSans-Bold",
                                    fontWeight: "500",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Send Time
                              </Text>
                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                {/*///////////////////////////////////PWP/////////////////////////////////////////*/}

                {selectedPromotionType === PROMOTION_TYPE.PWP ? (
                  <>
                    <View
                      style={{
                        marginHorizontal: 20,
                        marginTop: 10,
                        marginBottom: 20,
                        zIndex: -1,
                      }}
                    >
                      <View style={{ width: "85%" }}>
                        <Text style={{ alignSelf: "flex-end" }}>
                          RM(tax-exclusive price)+(tax) = RM(tax-inclusive price)
                        </Text>
                      </View>
                      <View style={{ marginBottom: 5, marginTop: 0 }}>
                        <Text style={{ fontWeight: "500" }}>Criteria</Text>
                      </View>
                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          borderRadius: 3,
                          width: "85%",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            margin: 15,
                            marginBottom: 0,
                          }}
                        >
                          <View style={{ marginRight: 10 }}>
                            <Text style={{ marginBottom: 5, fontWeight: "500" }}>
                              Buy
                            </Text>
                            <Picker
                              style={{
                                width: 110,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              }}
                              onValueChange={(item) => { }}
                            >
                              {[
                                { label: "Coca Cola", value: 1 },
                                { label: "100 Plus", value: 2 },
                                { label: "Kari Cintan", value: 3 },
                                { label: "KitKat", value: 4 },
                              ].map((value, index) => {
                                return (
                                  <Picker.Item
                                    key={index}
                                    label={value.label}
                                    value={value.value}
                                  />
                                );
                              })}
                            </Picker>
                          </View>

                          <View style={{ marginHorizontal: 10 }}>
                            <Text style={{ marginBottom: 5, fontWeight: "500" }}>
                              Get
                            </Text>
                            <Picker
                              style={{
                                width: 110,
                                paddingVertical: 0,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 10,
                              }}
                              onValueChange={(item) => { }}
                            >
                              {[
                                { label: "Coca Cola", value: 1 },
                                { label: "100 Plus", value: 2 },
                                { label: "Kari Cintan", value: 3 },
                                { label: "KitKat", value: 4 },
                              ].map((value, index) => {
                                return (
                                  <Picker.Item
                                    key={index}
                                    label={value.label}
                                    value={value.value}
                                  />
                                );
                              })}
                            </Picker>
                          </View>

                          <View style={{ marginHorizontal: 10 }}>
                            <View
                              style={{ flexDirection: "row", marginBottom: 5 }}
                            >
                              <Text style={{ fontWeight: "500" }}>RM</Text>
                              <Text
                                style={{
                                  color: Colors.primaryColor,
                                  fontSize: 10,
                                }}
                              >
                                *tax-exclusive
                              </Text>
                            </View>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                                width: 90,
                                height: 35,
                                paddingLeft: 10,
                              }}
                              placeholder="0"
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              keyboardType="number-pad"
                            />
                          </View>

                          <Text style={{ alignSelf: "center", paddingTop: 15 }}>
                            {" "}
                            +{" "}
                          </Text>

                          <View style={{ marginHorizontal: 10 }}>
                            <View
                              style={{ flexDirection: "row", marginBottom: 5 }}
                            >
                              <Text style={{ fontWeight: "500" }}>RM</Text>
                              <Text
                                style={{
                                  color: Colors.primaryColor,
                                  fontSize: 10,
                                }}
                              >
                                *tax
                              </Text>
                            </View>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                                width: 90,
                                height: 35,
                                paddingLeft: 10,
                              }}
                              placeholder="0"
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              keyboardType="number-pad"
                            />
                          </View>

                          <Text style={{ alignSelf: "center", paddingTop: 15 }}>
                            {" "}
                            ={" "}
                          </Text>

                          <View style={{ marginHorizontal: 10 }}>
                            <View
                              style={{ flexDirection: "row", marginBottom: 5 }}
                            >
                              <Text style={{ fontWeight: "500" }}>RM</Text>
                              <Text
                                style={{
                                  color: Colors.primaryColor,
                                  fontSize: 10,
                                }}
                              >
                                *tax-inclusive price
                              </Text>
                            </View>
                            <TextInput
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                                width: 90,
                                height: 35,
                                paddingLeft: 10,
                              }}
                              placeholder="0"
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              keyboardType="number-pad"
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            margin: 15,
                            marginTop: 25,
                            zIndex: -1,
                            alignItems: "center",
                          }}
                        >
                          <Text style={{ marginRight: 5, fontWeight: "500" }}>
                            Min. Quantity
                          </Text>
                          <TextInput
                            style={{
                              marginRight: 15,
                              borderWidth: 1,
                              borderRadius: 5,
                              borderColor: "#E5E5E5",
                              width: 80,
                              height: 35,
                              padding: 5,
                            }}
                            placeholder="e.g. 1"
                            placeholderTextColor={Platform.select({
                              ios: "#a9a9a9",
                            })}
                            keyboardType="number-pad"
                          />
                          <Text style={{ marginRight: 5, fontWeight: "500" }}>
                            Max. Quantity
                          </Text>
                          <TextInput
                            style={{
                              borderWidth: 1,
                              borderRadius: 5,
                              borderColor: "#E5E5E5",
                              width: 80,
                              height: 35,
                              padding: 5,
                            }}
                            placeholder="e.g. 2"
                            placeholderTextColor={Platform.select({
                              ios: "#a9a9a9",
                            })}
                            keyboardType="number-pad"
                          />
                        </View>
                      </View>
                      <View>
                        <View style={{ width: "85%", marginVertical: 15 }}>
                          <TouchableOpacity
                            style={{
                              alignSelf: "flex-end",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignItems: "center",
                              borderRadius: 10,
                              borderWidth: 1,
                              borderColor: Colors.primaryColor,
                              width: 125,
                              height: 35,
                            }}
                            onPress={() => { }}
                          >
                            <View
                              style={{
                                backgroundColor: Colors.primaryColor,
                                alignItems: "center",
                                paddingLeft: 1.5,
                                borderRadius: 200,
                                width: 20,
                                height: 20,
                              }}
                            >
                              <Icon name="add" color="white" size={18} />
                            </View>
                            <View style={{}}>
                              <Text
                                style={{
                                  alignSelf: "center",
                                  color: Colors.primaryColor,
                                }}
                              >
                                Add Criteria
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -3,
                      }}
                    >
                      <Text style={{ fontWeight: "500" }}>
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "79%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Regular",
                                  fontWeight: "500",
                                }}
                              >
                                Send Date
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  width: 120
                                }}>

                                <GCalendar
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 5 }}
                                />
                                <DatePicker
                                  selected={moment(notificationDate).toDate()}
                                  onChange={(date) => {
                                    setNotificationDate(moment(date));
                                  }}
                                  minDate={moment(Date.now()).toDate()}
                                />
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Regular",
                                  fontWeight: "500",
                                }}
                              >
                                Send Time
                              </Text>
                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                {/*///////////////////////////////////Cashback////////////////////////////////////*/}

                {selectedPromotionType === PROMOTION_TYPE.CASHBACK ? (
                  <>
                    <View
                      style={{
                        marginHorizontal: 20,
                        marginTop: 10,
                        marginBottom: 20,
                        zIndex: -1,
                      }}
                    >
                      <View style={{ width: "65%" }}>
                        <View style={{ marginBottom: 5 }}>
                          <Text style={{ fontWeight: "500" }}>Criteria</Text>
                        </View>

                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: "#E5E5E5",
                            borderRadius: 3,
                            padding: 13,
                            paddingTop: 13,
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", alignItems: "center" }}
                          >
                            <Text style={{ fontWeight: "500" }}>Cashback</Text>
                            <TextInput
                              style={{
                                marginHorizontal: 5,
                                borderWidth: 1,
                                borderRadius: 5,
                                borderColor: "#E5E5E5",
                                width: 40,
                                height: 35,
                                textAlign: "center",
                              }}
                              placeholder={"0"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{ justifyContent: "center" }}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                setCashbackPercentage(text);
                              }}
                              value={cashbackPercentage}
                              keyboardType={"number-pad"}
                            />
                            <Text style={{ fontWeight: "500" }}>(%)</Text>
                          </View>

                          {/* ~~~~~~~~~~~~~~~~ Border Line ~~~~~~~~~~~~~~~~~~~~ */}
                          <View
                            style={{
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                              marginHorizontal: -13,
                              marginVertical: 12,
                            }}
                          />

                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text style={{ fontWeight: "500" }}>Product</Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Picker
                                  style={{
                                    width: switchMerchant ? 140 : 250,
                                    paddingVertical: 0,
                                    backgroundColor: Colors.fieldtBgColor,
                                    borderRadius: 5,
                                  }}
                                  onValueChange={(item) => {

                                  }}
                                >
                                  {[
                                    { label: "Product of Category", value: 1 },
                                    { label: "Product of Tags", value: 2 },
                                    { label: "Specific Products", value: 3 },
                                  ].map((value, index) => {
                                    return (
                                      <Picker.Item
                                        key={index}
                                        label={value.label}
                                        value={value.value}
                                      />
                                    );
                                  })}
                                </Picker>
                                <Text style={{ fontWeight: "500", margin: 3 }}>
                                  +
                                </Text>
                                <Picker
                                  style={{
                                    width: switchMerchant ? 140 : 250,
                                    paddingVertical: 0,
                                    backgroundColor: Colors.fieldtBgColor,
                                    borderRadius: 5,
                                  }}
                                  onValueChange={(item) => {

                                  }}
                                >
                                  {[
                                    { label: "Shisheido shampoo", value: 1 },
                                    { label: "Shisheido conditioner", value: 2 },
                                    { label: "Shisheido Hair mask", value: 3 },
                                  ].map((value, index) => {
                                    return (
                                      <Picker.Item
                                        key={index}
                                        label={value.label}
                                        value={value.value}
                                      />
                                    );
                                  })}
                                </Picker>
                                <Text style={{ fontWeight: "500", margin: 3 }}>
                                  +
                                </Text>
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 5 }}
                            >
                              <Text style={{ fontWeight: "500" }}>
                                Min. Quantity
                              </Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TextInput
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "#E5E5E5",
                                    borderRadius: 5,
                                    width: 80,
                                    height: 35,
                                    marginTop: 5,
                                    textAlign: "center",
                                  }}
                                  placeholder={"0"}
                                  placeholderTextColor={Platform.select({
                                    ios: "#a9a9a9",
                                  })}
                                  placeholderStyle={{ justifyContent: "center" }}
                                  onChangeText={(text) => {
                                    // setState({ itemPrice: text });
                                    setCashbackMinQuantity(text);
                                  }}
                                  value={cashbackMinQuantity}
                                  keyboardType={"number-pad"}
                                />
                              </View>
                            </View>
                          </View>

                          <View style={{ marginVertical: 20, zIndex: -1 }}>
                            <TouchableOpacity
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-around",
                                alignItems: "center",
                                borderRadius: 10,
                                borderWidth: 1,
                                borderColor: Colors.primaryColor,
                                width: 125,
                                height: 35,
                              }}
                              onPress={() => { }}
                            >
                              <View
                                style={{
                                  backgroundColor: Colors.primaryColor,
                                  alignItems: "center",
                                  paddingLeft: 1.5,
                                  borderRadius: 200,
                                  width: 20,
                                  height: 20,
                                }}
                              >
                                <Icon name="add" color="white" size={18} />
                              </View>
                              <View style={{}}>
                                <Text
                                  style={{
                                    alignSelf: "center",
                                    color: Colors.primaryColor,
                                    fontWeight: "400",
                                  }}
                                >
                                  Add Product
                                </Text>
                              </View>
                            </TouchableOpacity>
                          </View>

                          <View style={{ marginTop: 7, zIndex: -1 }}>
                            <Text style={{ fontWeight: "500" }}>
                              Cashback expired in
                            </Text>
                            <View style={{ flexDirection: "row" }}>
                              <TextInput
                                style={{
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  borderRadius: 5,
                                  width: 80,
                                  height: 35,
                                  marginTop: 5,
                                  marginRight: 5,
                                  textAlign: "center",
                                }}
                                placeholder={"0"}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                placeholderStyle={{ justifyContent: "center" }}
                                onChangeText={(text) => {
                                  // setState({ itemPrice: text });
                                  setCashbackExpired(text);
                                }}
                                value={cashbackExpired}
                                keyboardType={"number-pad"}
                              />
                              <Picker
                                style={{
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  borderRadius: 5,
                                  height: 35,
                                  width: 140,
                                  paddingVertical: 0,
                                  margin: 5,
                                }}
                                onValueChange={(item) => {

                                }}
                              >
                                {[
                                  { label: "Day(s)", value: 1 },
                                  { label: "Week(s)", value: 2 },
                                  { label: "Month(s)", value: 3 },
                                  { label: "Year(s)", value: 4 },
                                ].map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker>
                            </View>
                          </View>
                        </View>

                        <View style={{ marginVertical: 15 }}>
                          <TouchableOpacity
                            style={{
                              alignSelf: "flex-end",
                              flexDirection: "row",
                              justifyContent: "space-around",
                              alignItems: "center",
                              borderRadius: 10,
                              borderWidth: 1,
                              borderColor: Colors.primaryColor,
                              width: 125,
                              height: 35,
                            }}
                            onPress={() => { }}
                          >
                            <View
                              style={{
                                backgroundColor: Colors.primaryColor,
                                alignItems: "center",
                                paddingLeft: 1.5,
                                borderRadius: 200,
                                width: 20,
                                height: 20,
                              }}
                            >
                              <Icon name="add" color="white" size={18} />
                            </View>
                            <View style={{}}>
                              <Text
                                style={{
                                  alignSelf: "center",
                                  color: Colors.primaryColor,
                                }}
                              >
                                Add Criteria
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -3,
                      }}
                    >
                      <Text style={{ fontWeight: "500" }}>
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "79%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Regular",
                                  fontWeight: "500",
                                }}
                              >
                                Send Date
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  width: 120
                                }}>

                                <GCalendar
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 5 }}
                                />
                                <DatePicker
                                  selected={moment(notificationDate).toDate()}
                                  onChange={(date) => {
                                    setNotificationDate(moment(date));
                                  }}
                                  minDate={moment(Date.now()).toDate()}
                                />
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Regular",
                                  fontWeight: "500",
                                }}
                              >
                                Send Time
                              </Text>
                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                {/*////////////////////////////////////Take Amount Off////////////////////////////*/}
                {/* here */}
                {selectedPromotionType === PROMOTION_TYPE.TAKE_AMOUNT_OFF ? (
                  <>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -1,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontWeight: "500",
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}
                      >
                        Criteria
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "100%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          {/* *******************Border Line******************* */}
                          <View
                            style={{ flexDirection: "row", alignItems: "center" }}
                          >
                            <Text
                              style={[
                                {
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginRight: 5,
                                  fontWeight: "500",
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                            >
                              Amount Off (RM)
                            </Text>
                            <TextInput
                              style={[
                                {
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  borderRadius: 5,
                                  height: switchMerchant ? 35 : 40,
                                  width: 80,
                                  textAlign: "center",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                              placeholder={"0"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{
                                justifyContent: "center",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: 14,
                              }}
                              onChangeText={(text) => {
                                // setMinSpend(text);
                                setPriceMin(text);
                              }}
                              // defaultValue={minSpend}
                              defaultValue={priceMin}
                              keyboardType={"number-pad"}
                            />
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                marginLeft: 20,
                              }}
                            >
                              <Text
                                style={[
                                  {
                                    fontWeight: "500",
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Min Spend Amount (RM)
                              </Text>
                              <TextInput
                                style={[
                                  {
                                    borderWidth: 1,
                                    borderColor: "#E5E5E5",
                                    borderRadius: 5,
                                    height: switchMerchant ? 35 : 40,
                                    width: 80,
                                    justifyContent: "center",
                                    textAlign: "center",
                                    margin: 5,
                                    width: "20%",
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder={"0.00"}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                placeholderStyle={{
                                  justifyContent: "center",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                onChangeText={(text) => {
                                  // setMinSpend(text);
                                  setPriceMin(text);
                                }}
                                // defaultValue={minSpend}
                                defaultValue={priceMin}
                                keyboardType={"number-pad"}
                              />
                            </View>
                          </View>

                          <View
                            style={{
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                              marginVertical: 12,
                              marginHorizontal: -12,
                              zIndex: -3,
                            }}
                          />
                          <View style={{ flexDirection: "row" }}>
                            <View
                              style={{ flexDirection: "column", marginBottom: 5 }}
                            >
                              <Text
                                style={[
                                  {
                                    fontWeight: "500",
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Apply To
                              </Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  marginTop: 10,
                                  marginRight: 5,
                                  width: "85%",
                                }}
                              >
                                <Picker
                                  selectedValue={selectedVariation}
                                  style={{
                                    width: switchMerchant ? 200 : 250,
                                    paddingVertical: 0,
                                    backgroundColor: Colors.fieldtBgColor,
                                    borderRadius: 10,
                                  }}
                                  onValueChange={(item) => {
                                    setSelectedVariation(item.value)
                                  }}
                                >
                                  {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                    return (
                                      <Picker.Item
                                        key={index}
                                        label={value.label}
                                        value={value.value}
                                      />
                                    );
                                  })}
                                </Picker>

                                <Text
                                  style={[
                                    {
                                      alignItems: "center",
                                      marginLeft: "2%",
                                      marginRight: "2%",
                                      alignSelf: "center",
                                      fontFamily: "NunitoSans-Regular",
                                      fontSize: 14,
                                    },
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}
                                >
                                  +
                                </Text>

                                {variationItemsDropdownList.length > 0 &&
                                  (variationItemsDropdownList.find(
                                    (item) =>
                                      item.value === selectedVariationItems[0]
                                  ) ||
                                    selectedVariationItems.length === 0) ? (
                                  <Select
                                    style={{
                                      width: switchMerchant ? 200 : 250,
                                      paddingVertical: 0,
                                      backgroundColor: Colors.fieldtBgColor,
                                      borderRadius: 10,
                                    }}
                                    options={variationItemsDropdownList}
                                    onChange={(items) =>
                                      setSelectedVariationItems(items)
                                    }
                                    defaultValue={selectedVariationItems}
                                    isMulti
                                  />
                                ) : (
                                  <></>
                                )}
                              </View>
                            </View>

                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  {
                                    marginLeft: 10,
                                    fontWeight: "500",
                                    marginBottom: 10,
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: switchMerchant ? 10 : 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Min. Quantity
                              </Text>
                              <TextInput
                                style={[
                                  {
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: 14,
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    borderColor: "#E5E5E5",
                                    marginLeft: 10,
                                    height: switchMerchant ? 35 : 40,
                                    width: 60,
                                    textAlign: "center",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder={"0"}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                placeholderStyle={{
                                  justifyContent: "center",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                onChangeText={(text) => {
                                  // setState({ itemPrice: text });
                                  // setAmountOffMinQuantity(text);
                                  setQuantityMin(text);
                                }}
                                // defaultValue={amountOffMinQuantity}
                                defaultValue={quantityMin}
                                keyboardType={"number-pad"}
                              />
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 5 }}
                            >
                              <Text
                                style={[
                                  {
                                    marginLeft: 10,
                                    fontWeight: "500",
                                    marginBottom: 10,
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Max. Quantity
                              </Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TextInput
                                  style={[
                                    {
                                      borderWidth: 1,
                                      borderRadius: 5,
                                      borderColor: "#E5E5E5",
                                      marginLeft: "9%",
                                      height: switchMerchant ? 35 : 40,
                                      width: 60,
                                      textAlign: "center",
                                      fontFamily: "NunitoSans-Regular",
                                      fontSize: 14,
                                    },
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}
                                  placeholder={"0"}
                                  placeholderTextColor={Platform.select({
                                    ios: "#a9a9a9",
                                  })}
                                  placeholderStyle={{
                                    justifyContent: "center",
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                  onChangeText={(text) => {
                                    // setState({ itemPrice: text });
                                    // setAmountOffMaxQuantity(text);
                                    setQuantityMax(text);
                                  }}
                                  // defaultValue={amountOffMaxQuantity}
                                  defaultValue={quantityMax}
                                  keyboardType={"number-pad"}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -3,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontWeight: "500",
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}
                      >
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "79%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: "NunitoSans-Bold",
                                    fontWeight: "500",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Send Date
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  width: 120
                                }}>

                                <GCalendar
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 5 }}
                                />
                                <DatePicker
                                  selected={moment(notificationDate).toDate()}
                                  onChange={(date) => {
                                    setNotificationDate(moment(date));
                                  }}
                                  minDate={moment(Date.now()).toDate()}
                                />
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: "NunitoSans-Bold",
                                    fontWeight: "500",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Send Time
                              </Text>
                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                {/*////////////////////////////////////Take Percentage Off////////////////////////*/}
                {/* here */}
                {selectedPromotionType === PROMOTION_TYPE.TAKE_PERCENTAGE_OFF ? (
                  <>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        zIndex: -1,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "500",
                          fontFamily: "NunitoSans-Bold",
                          fontSize: switchMerchant ? 10 : 14,
                        }}
                      >
                        Criteria
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          borderRadius: 5,
                          width: "100%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          {/* *******************Border Line******************* */}
                          <View
                            style={{ flexDirection: "row", alignItems: "center" }}
                          >
                            <Text
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                                marginRight: "1%",
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              Discount (%)
                            </Text>
                            <TextInput
                              style={[
                                {
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  marginLeft: "2%",
                                  height: switchMerchant ? 35 : 40,
                                  width: 80,
                                  borderRadius: 5,
                                  textAlign: "center",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: 14,
                                },
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                              placeholder={"0"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              placeholderStyle={{
                                justifyContent: "center",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                              onChangeText={(text) => {
                                // setState({ itemPrice: text });
                                setPercentageOffPrice(text);
                              }}
                              defaultValue={percentageOffPrice}
                              keyboardType={"number-pad"}
                            />
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={[
                                  {
                                    fontWeight: "500",
                                    marginRight: "1%",
                                    marginLeft: "2%",
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: switchMerchant ? 10 : 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Min Spend Amount (RM)
                              </Text>
                              <TextInput
                                style={[
                                  {
                                    borderWidth: 1,
                                    borderColor: "#E5E5E5",
                                    borderRadius: 5,
                                    height: switchMerchant ? 35 : 40,
                                    width: 80,
                                    justifyContent: "center",
                                    textAlign: "center",
                                    margin: 5,
                                    width: "20%",
                                    marginLeft: 20,
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder={"0.00"}
                                placeholderStyle={{
                                  justifyContent: "center",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                onChangeText={(text) => {
                                  // setMinSpend(text);
                                  setPriceMin(text);
                                }}
                                // defaultValue={minSpend}
                                defaultValue={priceMin}
                                keyboardType={"number-pad"}
                              />
                            </View>
                          </View>
                          <View
                            style={{
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                              width: "100%",
                              marginTop: "3%",
                              marginBottom: "3%",
                            }}
                          />
                          <View style={{ flexDirection: "row" }}>
                            <View
                              style={{ flexDirection: "column", marginBottom: 5 }}
                            >
                              <Text
                                style={[
                                  {
                                    fontWeight: "500",
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Apply To
                              </Text>
                              <View
                                style={{ flexDirection: "row", marginTop: 10 }}
                              >
                                <Picker
                                  selectedValue={selectedVariation}
                                  style={{
                                    width: switchMerchant ? 200 : 250,
                                    paddingVertical: 0,
                                    backgroundColor: Colors.fieldtBgColor,
                                    borderRadius: 10,
                                  }}
                                  onValueChange={(item) => {
                                    setSelectedVariation(item.value)
                                  }}
                                >
                                  {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                    return (
                                      <Picker.Item
                                        key={index}
                                        label={value.label}
                                        value={value.value}
                                      />
                                    );
                                  })}
                                </Picker>

                                <Text
                                  style={{
                                    alignItems: "center",
                                    marginLeft: "2%",
                                    marginRight: "2%",
                                    alignSelf: "center",
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                >
                                  +
                                </Text>

                                {variationItemsDropdownList.length > 0 &&
                                  (variationItemsDropdownList.find(
                                    (item) =>
                                      item.value === selectedVariationItems[0]
                                  ) ||
                                    selectedVariationItems.length === 0) ? (
                                  <Select
                                    style={{
                                      width: switchMerchant ? 200 : 250,
                                      paddingVertical: 0,
                                      backgroundColor: Colors.fieldtBgColor,
                                      borderRadius: 10,
                                      fontFamily: "NunitoSans-Regular",
                                      fontSize: switchMerchant ? 10 : 14,
                                    }}
                                    options={variationItemsDropdownList}
                                    onChange={(items) =>
                                      setSelectedVariationItems(items)
                                    }
                                    defaultValue={selectedVariationItems}
                                    isMulti
                                  />
                                ) : (
                                  <></>
                                )}
                              </View>
                            </View>

                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  {
                                    marginLeft: 10,
                                    fontWeight: "500",
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Min. Quantity
                              </Text>
                              <TextInput
                                style={[
                                  {
                                    borderWidth: 1,
                                    borderColor: "#E5E5E5",
                                    marginLeft: 10,
                                    height: switchMerchant ? 35 : 40,
                                    width: 60,
                                    borderRadius: 5,
                                    marginTop: 10,
                                    textAlign: "center",
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder={"0"}
                                placeholderStyle={{
                                  justifyContent: "center",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                onChangeText={(text) => {
                                  // setState({ itemPrice: text });
                                  // setAmountOffMinQuantity(text);
                                  setQuantityMin(text);
                                }}
                                // defaultValue={amountOffMinQuantity}
                                defaultValue={quantityMin}
                                keyboardType={"number-pad"}
                              />
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 5 }}
                            >
                              <Text
                                style={[
                                  {
                                    marginLeft: 10,
                                    fontWeight: "500",
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Max. Quantity
                              </Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TextInput
                                  style={[
                                    {
                                      borderWidth: 1,
                                      borderColor: "#E5E5E5",
                                      marginLeft: 10,
                                      height: switchMerchant ? 35 : 40,
                                      width: 60,
                                      borderRadius: 5,
                                      marginTop: 10,
                                      textAlign: "center",
                                      fontFamily: "NunitoSans-Regular",
                                      fontSize: 14,
                                    },
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}
                                  placeholder={"0"}
                                  placeholderStyle={{
                                    justifyContent: "center",
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                  placeholderTextColor={Platform.select({
                                    ios: "#a9a9a9",
                                  })}
                                  onChangeText={(text) => {
                                    // setState({ itemPrice: text });
                                    // setAmountOffMaxQuantity(text);
                                    setQuantityMax(text);
                                  }}
                                  // defaultValue={amountOffMaxQuantity}
                                  defaultValue={quantityMax}
                                  keyboardType={"number-pad"}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -3,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontWeight: "500",
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}
                      >
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "79%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  {
                                    fontWeight: "500",
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Send Date
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  width: 120
                                }}>

                                <GCalendar
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 5 }}
                                />
                                <DatePicker
                                  selected={moment(notificationDate).toDate()}
                                  onChange={(date) => {
                                    setNotificationDate(moment(date));
                                  }}
                                  minDate={moment(Date.now()).toDate()}
                                />
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 14,
                                    fontWeight: "500",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Send Time
                              </Text>
                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                {/*//////////////////////Require specific product purchase/////////////////////////*/}

                {isRequireSpecificProductPurchase ? (
                  <>
                    <View
                      style={{
                        marginHorizontal: 20,
                        marginTop: 20,
                        marginBottom: 0,
                        zIndex: -1,
                      }}
                    >
                      <View style={{ width: "65%" }}>
                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: "#E5E5E5",
                            borderRadius: 3,
                            padding: 15,
                            paddingTop: 13,
                          }}
                        >
                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text style={{ fontWeight: "500" }}>Product</Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <Picker
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "#E5E5E5",
                                    borderRadius: 5,
                                    height: 35,
                                    width: 130,
                                    paddingVertical: 0,
                                    margin: 5,
                                    marginLeft: 0,
                                  }}
                                  onValueChange={(item) => {

                                  }}
                                >
                                  {[
                                    { label: "Coke", value: 1 },
                                    { label: "HL", value: 2 },
                                  ].map((value, index) => {
                                    return (
                                      <Picker.Item
                                        key={index}
                                        label={value.label}
                                        value={value.value}
                                      />
                                    );
                                  })}
                                </Picker>
                                <Text style={{ fontWeight: "500", margin: 3 }}>
                                  +
                                </Text>
                                <Picker
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "#E5E5E5",
                                    borderRadius: 5,
                                    height: 35,
                                    width: 130,
                                    paddingVertical: 0,
                                    margin: 5,
                                  }}
                                  onValueChange={(item) => {

                                  }}
                                >
                                  {[
                                    { label: "KFC", value: 1 },
                                    { label: "MCD", value: 2 },
                                  ].map((value, index) => {
                                    return (
                                      <Picker.Item
                                        key={index}
                                        label={value.label}
                                        value={value.value}
                                      />
                                    );
                                  })}
                                </Picker>
                                <Text style={{ fontWeight: "500", margin: 3 }}>
                                  +
                                </Text>
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 5 }}
                            >
                              <Text style={{ fontWeight: "500" }}>
                                Min. Quantity
                              </Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <TextInput
                                  style={{
                                    borderWidth: 1,
                                    borderColor: "#E5E5E5",
                                    borderRadius: 5,
                                    width: 80,
                                    height: 35,
                                    marginTop: 5,
                                    textAlign: "center",
                                  }}
                                  placeholder="0"
                                  placeholderTextColor={Platform.select({
                                    ios: "#a9a9a9",
                                  })}
                                  keyboardType="number-pad"
                                />
                                <TouchableOpacity
                                  style={{ marginLeft: 25 }}
                                  onPress={() => { }}
                                >
                                  <MaterialCommunityIcons
                                    name="delete-sweep"
                                    color={"red"}
                                    size={20}
                                  />
                                </TouchableOpacity>
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -3,
                      }}
                    >
                      <Text style={{ fontWeight: "500" }}>
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "79%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>

                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Regular",
                                  fontWeight: "500",
                                }}
                              >
                                Send Date
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  width: 120
                                }}>

                                <GCalendar
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 5 }}
                                />
                                <DatePicker
                                  selected={moment(notificationDate).toDate()}
                                  onChange={(date) => {
                                    setNotificationDate(moment(date));
                                  }}
                                  minDate={moment(Date.now()).toDate()}
                                />
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Regular",
                                  fontWeight: "500",
                                }}
                              >
                                Send Time
                              </Text>
                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                {/*/////////////////////////////////Delivery/////////////////////////////////*/}
                {/* here */}
                {selectedPromotionType === PROMOTION_TYPE.DELIVERY ? (
                  <>
                    <View
                      style={{
                        marginHorizontal: 20,
                        marginBottom: 20,
                        marginTop: 10,
                        zIndex: -1,
                      }}
                    >
                      <View style={{ width: "100%" }}>
                        <View style={{ marginBottom: 5 }}>
                          <Text
                            style={[
                              {
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Bold",
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                          >
                            Criteria
                          </Text>
                        </View>

                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: "#E5E5E5",
                            borderRadius: 3,
                            padding: 13,
                            paddingTop: 10,
                            width: 600,
                            alignItems: "flex-start",
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", alignItems: "center" }}
                          >
                            <View style={{ marginRight: 5, marginBottom: 5 }}>
                              <Picker
                                selectedValue={selectedVariation}
                                style={{
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                onValueChange={(item) => {
                                  setSelectedVariation(item.value);
                                }}
                              >
                                {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker>
                            </View>

                            {variationItemsDropdownList.length > 0 &&
                              (variationItemsDropdownList.find(
                                (item) => item.value === selectedVariationItems[0]
                              ) ||
                                selectedVariationItems.length === 0) ? (
                              <View style={{ marginRight: 5, marginBottom: 5 }}>
                                <Select
                                  style={{
                                    width: 250,
                                    paddingVertical: 0,
                                    backgroundColor: Colors.fieldtBgColor,
                                    borderRadius: 10,
                                  }}
                                  options={variationItemsDropdownList}
                                  onChange={(items) =>
                                    setSelectedVariationItems(items)
                                  }
                                  defaultValue={selectedVariationItems}
                                  isMulti
                                />
                              </View>
                            ) : (
                              <></>
                            )}
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                              zIndex: -2,
                              marginTop: "1%",
                            }}
                          >
                            <View style={{ alignItems: "flex-start" }}>
                              <Text
                                style={[
                                  {
                                    color: "black",
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 14,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Discount (RM)
                              </Text>
                            </View>
                            <View
                              style={{
                                height: switchMerchant ? 35 : 40,
                                paddingHorizontal: 5,
                              }}
                            >
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={[
                                  styles.textInput8,
                                  { textAlign: "center", fontSize: 14 },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder="0"
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                onChangeText={(text) => {
                                  setDeliveryDiscountAmount(text);
                                }}
                                value={deliveryDiscountAmount}
                                // ref={myTextInput}
                                keyboardType={"number-pad"}
                              />
                            </View>

                            <View style={{ marginLeft: 15 }}>
                              <Text
                                style={[
                                  {
                                    color: "black",
                                    fontSize: 14,
                                    fontFamily: "NunitoSans-Bold",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Order Above (RM)
                              </Text>
                            </View>

                            <View
                              style={{
                                height: switchMerchant ? 35 : 40,
                                paddingHorizontal: 5,
                              }}
                            >
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={[
                                  styles.textInput8,
                                  { textAlign: "center", fontSize: 14 },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                                placeholder="0"
                                placeholderTextColor={Platform.select({
                                  ios: "#a9a9a9",
                                })}
                                onChangeText={(text) => {
                                  setDeliveryDiscountAboveAmount(text);
                                }}
                                value={deliveryDiscountAboveAmount}
                                // ref={myTextInput}
                                keyboardType={"number-pad"}
                              />
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -3,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontWeight: "500",
                            fontFamily: "NunitoSans-Bold",
                            fontSize: switchMerchant ? 10 : 14,
                          },
                          switchMerchant ? {} : {},
                        ]}
                      >
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "79%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>

                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: "NunitoSans-Bold",
                                    fontWeight: "500",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Send Date
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  width: 120
                                }}>

                                <GCalendar
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 5 }}
                                />
                                <DatePicker
                                  selected={moment(notificationDate).toDate()}
                                  onChange={(date) => {
                                    setNotificationDate(moment(date));
                                  }}
                                  minDate={moment(Date.now()).toDate()}
                                />
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: "NunitoSans-Bold",
                                    fontWeight: "500",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Send Time
                              </Text>
                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                {/*/////////////////////////////////Takeaway/////////////////////////////////*/}
                {/* here */}
                {selectedPromotionType === PROMOTION_TYPE.TAKEAWAY ? (
                  <>
                    <View
                      style={{
                        marginHorizontal: 20,
                        marginBottom: 20,
                        marginTop: 10,
                        zIndex: -1,
                      }}
                    >
                      <View style={{ width: "100%" }}>
                        <View style={{ marginBottom: 5 }}>
                          <Text
                            style={[
                              {
                                fontWeight: "500",
                                fontFamily: "NunitoSans-Regular",
                                fontSize: 14,
                              },
                              switchMerchant
                                ? {
                                  fontSize: 10,
                                }
                                : {},
                            ]}
                          >
                            Criteria
                          </Text>
                        </View>

                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: "#E5E5E5",
                            borderRadius: 3,
                            padding: 13,
                            paddingTop: 10,
                            width: 600,
                            alignItems: "flex-start",
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", alignItems: "center" }}
                          >
                            <View style={{ marginRight: 5, marginBottom: 5 }}>
                              <Picker
                                selectedValue={selectedVariation}
                                style={{
                                  width: 250,
                                  paddingVertical: 0,
                                  backgroundColor: Colors.fieldtBgColor,
                                  borderRadius: 10,
                                }}
                                onValueChange={(item) => {
                                  setSelectedVariation(item.value);
                                }}
                              >
                                {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker>
                            </View>

                            {variationItemsDropdownList.length > 0 &&
                              (variationItemsDropdownList.find(
                                (item) => item.value === selectedVariationItems[0]
                              ) ||
                                selectedVariationItems.length === 0) ? (
                              <View style={{ marginRight: 5, marginBottom: 5 }}>
                                <Select
                                  style={{
                                    width: 250,
                                    paddingVertical: 0,
                                    backgroundColor: Colors.fieldtBgColor,
                                    borderRadius: 10,
                                  }}
                                  options={variationItemsDropdownList}
                                  onChange={(items) =>
                                    setSelectedVariationItems(items)
                                  }
                                  defaultValue={selectedVariationItems}
                                  isMulti
                                />
                              </View>
                            ) : (
                              <></>
                            )}
                          </View>

                          <View style={{ flexDirection: "row", zIndex: -2 }}>
                            <View style={{ justifyContent: "center", flex: 1 }}>
                              <Text
                                style={[
                                  { fontFamily: "NunitoSans-Bold", fontSize: 14 },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Delivery Fee{" "}
                              </Text>
                            </View>
                            <View
                              style={{
                                flex: 3.5,
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: "NunitoSans-Bold",
                                    color: "black",
                                    paddingVertical: 15,
                                    marginRight: 20,
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Free Takeaway Above RM
                              </Text>

                              <View
                                style={{
                                  height: switchMerchant ? 35 : 40,
                                  alignSelf: "center",
                                }}
                              >
                                <TextInput
                                  underlineColorAndroid={Colors.fieldtBgColor}
                                  style={[
                                    styles.textInput8,
                                    { textAlign: "center", fontSize: 14 },
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}
                                  placeholder="0"
                                  placeholderStyle={{
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                  placeholderTextColor={Platform.select({
                                    ios: "#a9a9a9",
                                  })}
                                  onChangeText={(text) => {
                                    setTakeawayFreeAboveAmount(text);
                                  }}
                                  defaultValue={takeawayFreeAboveAmount}
                                  // ref={myTextInput}
                                  keyboardType={"number-pad"}
                                />
                              </View>
                              <input
                                style={{
                                  paddingVertical: 10,
                                  marginLeft: "4%",
                                  zIndex: 1,
                                }}
                                onChange={() => {
                                  setTakeawayFreeFlag(!takeawayFreeFlag);
                                }}
                                type={'checkbox'}
                                chcked={takeawayFreeFlag}
                              />
                            </View>

                            {/* <View style={{ flex: 1 }}></View> */}
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              zIndex: -2,
                            }}
                          >
                            <View style={{ flex: 1 }}></View>
                            <View
                              style={{
                                flex: 3.5,
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <View style={{}}>
                                <Text
                                  style={[
                                    {
                                      color: "black",
                                      fontFamily: "NunitoSans-Bold",
                                      fontSize: 14,
                                    },
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}
                                >
                                  Discount (RM)
                                </Text>
                              </View>
                              <View
                                style={{
                                  height: switchMerchant ? 35 : 40,
                                  paddingHorizontal: 5,
                                }}
                              >
                                <TextInput
                                  underlineColorAndroid={Colors.fieldtBgColor}
                                  style={[
                                    styles.textInput8,
                                    { textAlign: "center", fontSize: 14 },
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}
                                  placeholder="0"
                                  placeholderStyle={{
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                  onChangeText={(text) => {
                                    setTakeawayDiscountAmount(text);
                                  }}
                                  placeholderTextColor={Platform.select({
                                    ios: "#a9a9a9",
                                  })}
                                  value={takeawayDiscountAmount}
                                  // ref={myTextInput}
                                  keyboardType={"number-pad"}
                                />
                              </View>

                              <View style={{ marginLeft: 15 }}>
                                <Text
                                  style={[
                                    {
                                      color: "black",
                                      fontSize: 14,
                                      fontFamily: "NunitoSans-Bold",
                                    },
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}
                                >
                                  Order Above (RM)
                                </Text>
                              </View>

                              <View
                                style={{
                                  height: switchMerchant ? 35 : 40,
                                  paddingHorizontal: 5,
                                }}
                              >
                                <TextInput
                                  underlineColorAndroid={Colors.fieldtBgColor}
                                  style={[
                                    styles.textInput8,
                                    { textAlign: "center", fontSize: 14 },
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                      }
                                      : {},
                                  ]}
                                  placeholder="0"
                                  placeholderStyle={{
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  }}
                                  onChangeText={(text) => {
                                    setTakeawayDiscountAboveAmount(text);
                                  }}
                                  placeholderTextColor={Platform.select({
                                    ios: "#a9a9a9",
                                  })}
                                  value={takeawayDiscountAboveAmount}
                                  // ref={myTextInput}
                                  keyboardType={"number-pad"}
                                />
                              </View>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -3,
                      }}
                    >
                      <Text
                        style={[
                          {
                            fontWeight: "500",
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 14,
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}
                      >
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "79%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: "NunitoSans-Bold",
                                    fontWeight: "500",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Send Date
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  width: 120
                                }}>

                                <GCalendar
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 5 }}
                                />
                                <DatePicker
                                  selected={moment(notificationDate).toDate()}
                                  onChange={(date) => {
                                    setNotificationDate(moment(date));
                                  }}
                                  minDate={moment(Date.now()).toDate()}
                                />
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={[
                                  {
                                    fontSize: 14,
                                    fontFamily: "NunitoSans-Bold",
                                    fontWeight: "500",
                                  },
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                Send Time
                              </Text>
                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                {/*//////////////////////Buy A Get B For % Discount/////////////////////////*/}

                {selectedPromotionType ===
                  PROMOTION_TYPE.BUY_A_GET_X_FOR_DISCOUNT ? (
                  <>
                    <View
                      style={{
                        marginHorizontal: 20,
                        marginBottom: 20,
                        marginTop: 10,
                        zIndex: -1,
                      }}
                    >
                      <View style={{ width: "100%" }}>
                        <View style={{ marginBottom: 5 }}>
                          <Text style={{ fontWeight: "500" }}>Criteria</Text>
                        </View>

                        <View
                          style={{
                            borderWidth: 1,
                            borderColor: "#E5E5E5",
                            borderRadius: 3,
                            padding: 13,
                            paddingTop: 10,
                            width: 500,
                            alignItems: "flex-start",
                          }}
                        >
                          <View
                            style={{ flexDirection: "row", alignItems: "center" }}
                          >
                            <Text style={{ fontWeight: "500", marginRight: 2 }}>
                              Buy
                            </Text>
                            <View>
                              <Picker
                                selectedValue={selectedVariation}
                                style={{
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  borderRadius: 5,
                                  height: 35,
                                  width: 150,
                                  paddingVertical: 0,
                                  margin: 6,
                                }}
                                onValueChange={(item) => {
                                  setSelectedVariation(item.value);
                                }}
                              >
                                {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker>
                            </View>

                            {variationItemsDropdownList.length > 0 &&
                              (variationItemsDropdownList.find(
                                (item) => item.value === selectedVariationItems[0]
                              ) ||
                                selectedVariationItems.length === 0) ? (
                              <Select
                                style={{
                                  width: 210,
                                  paddingVertical: 0,
                                  borderRadius: 10,
                                }}
                                options={variationItemsDropdownList}
                                onChange={(items) =>
                                  setSelectedVariationItems(items)
                                }
                                defaultValue={selectedVariationItems}
                                isMulti
                              />
                            ) : (
                              <></>
                            )}
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              zIndex: -1,
                            }}
                          >
                            <Text style={{ fontWeight: "500", marginRight: 4 }}>
                              Get
                            </Text>
                            <View>
                              <Picker
                                selectedValue={selectedVariationB1F1}
                                style={{
                                  borderWidth: 1,
                                  borderColor: "#E5E5E5",
                                  borderRadius: 5,
                                  height: 35,
                                  width: 150,
                                  paddingVertical: 0,
                                  margin: 6,
                                }}
                                onValueChange={(item) => {
                                  setSelectedVariationB1F1(item.value);
                                }}
                              >
                                {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.slice(1).map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker>
                            </View>

                            {variationItemsB1F1DropdownList.length > 0 &&
                              (variationItemsB1F1DropdownList.find(
                                (item) =>
                                  item.value === selectedVariationItemsB1F1[0]
                              ) ||
                                selectedVariationItemsB1F1.length === 0) ? (
                              <Select
                                style={{
                                  width: 210,
                                  paddingVertical: 0,
                                  borderRadius: 10,
                                }}
                                options={variationItemsB1F1DropdownList}
                                onChange={(items) =>
                                  setSelectedVariationItemsB1F1(items)
                                }
                                defaultValue={selectedVariationItemsB1F1}
                                isMulti
                              />
                            ) : (
                              <></>
                            )}
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              zIndex: -2,
                              marginTop: 4,
                            }}
                          >
                            <Text style={{ fontWeight: "500", marginRight: 12 }}>
                              For
                            </Text>
                            <TextInput
                              style={{
                                borderColor: "#E5E5E5",
                                borderWidth: 1,
                                borderRadius: 5,
                                width: 40,
                                height: 30,
                                textAlign: "center",
                              }}
                              placeholder={"%"}
                              placeholderTextColor={Platform.select({
                                ios: "#a9a9a9",
                              })}
                              onChangeText={(text) => {
                                setPercentageDiscount(text);
                              }}
                              value={percentageDiscount}
                            />
                            <Text style={{ fontWeight: "500", marginLeft: 5 }}>
                              % Discount
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        marginTop: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        marginBottom: 20,
                        zIndex: -3,
                      }}
                    >
                      <Text style={{ fontWeight: "500" }}>
                        Blasting Notification
                      </Text>
                      <View
                        style={{
                          marginTop: "1%",
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          width: "79%",
                        }}
                      >
                        <View style={{ margin: "2%" }}>
                          <View style={{ flexDirection: "row" }}>
                            <View style={{ flexDirection: "column" }}>
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Regular",
                                  fontWeight: "500",
                                }}
                              >
                                Send Date
                              </Text>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  justifyContent: 'center',
                                  alignContent: 'center',
                                  alignItems: 'center',
                                  width: 120
                                }}>

                                <GCalendar
                                  width={20}
                                  height={20}
                                  style={{ marginRight: 5 }}
                                />
                                <DatePicker
                                  selected={moment(notificationDate).toDate()}
                                  onChange={(date) => {
                                    setNotificationDate(moment(date));
                                  }}
                                  minDate={moment(Date.now()).toDate()}
                                />
                              </View>
                            </View>

                            <View
                              style={{ flexDirection: "column", marginLeft: 15 }}
                            >
                              <Text
                                style={{
                                  fontSize: 14,
                                  fontFamily: "NunitoSans-Regular",
                                  fontWeight: "500",
                                }}
                              >
                                Send Time
                              </Text>
                              <TouchableOpacity
                                style={{
                                  borderWidth: 1,
                                  width: 120,
                                  borderRadius: 5,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                                onPress={() => {
                                  setClockTimePicker(!clockTimePicker);
                                }}
                              >
                                <Feather
                                  name="clock"
                                  size={25}
                                  color={Colors.primaryColor}
                                  style={{ marginRight: 5 }}
                                />
                                <Text>{moment(notificationTime).format("hh:mm A")}</Text>
                              </TouchableOpacity>
                              {clockTimePicker ? (
                                <View
                                  style={{
                                    // borderWidth:10
                                  }}
                                >
                                  <View
                                    style={{
                                      position: "absolute",
                                      left: -130,
                                      top: 15,
                                      zIndex: 100,
                                    }}
                                  >
                                    <TimeKeeper
                                      time={moment(notificationTime).format("hh:mm A")}
                                      onChange={(time) => {
                                        setNotificationTime(moment(`${moment(notificationDate).format("MM/DD/YYYY")} ${time.formatted12}`))
                                      }}
                                      onDoneClick={() => {
                                        setClockTimePicker(false);
                                      }}
                                    ></TimeKeeper>
                                  </View>
                                </View>
                              ) : null}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  </>
                ) : (
                  <></>
                )}

                {/*//////////////////////Buy AB Get Voucher/////////////////////////*/}

                {selectedPromotionType === PROMOTION_TYPE.BUY_AB_GET_VOUCHER ? (
                  <View
                    style={{
                      marginHorizontal: 20,
                      marginBottom: 20,
                      marginTop: 10,
                      zIndex: -1,
                    }}
                  >
                    <View style={{ width: "100%" }}>
                      <View style={{ marginBottom: 5 }}>
                        <Text style={{ fontWeight: "500" }}>Criteria</Text>
                      </View>

                      <View
                        style={{
                          borderWidth: 1,
                          borderColor: "#E5E5E5",
                          borderRadius: 3,
                          padding: 13,
                          paddingTop: 10,
                          width: 500,
                          alignItems: "flex-start",
                        }}
                      >
                        <View
                          style={{ flexDirection: "row", alignItems: "center" }}
                        >
                          <Text style={{ fontWeight: "500", marginRight: 2 }}>
                            Buy
                          </Text>
                          <View>
                            <Picker
                              selectedValue={selectedVariation}
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                                height: 35,
                                width: 150,
                                paddingVertical: 0,
                                margin: 6,
                              }}
                              onValueChange={(item) => {
                                setSelectedVariation(item.value);
                              }}
                            >
                              {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.map((value, index) => {
                                return (
                                  <Picker.Item
                                    key={index}
                                    label={value.label}
                                    value={value.value}
                                  />
                                );
                              })}
                            </Picker>
                          </View>

                          {variationItemsDropdownList.length > 0 &&
                            (variationItemsDropdownList.find(
                              (item) => item.value === selectedVariationItems[0]
                            ) ||
                              selectedVariationItems.length === 0) ? (
                            <Select
                              style={{
                                width: 210,
                                paddingVertical: 0,
                                borderRadius: 10,
                              }}
                              options={variationItemsDropdownList}
                              onChangeItem={(items) =>
                                setSelectedVariationItems(items)
                              }
                              defaultValue={selectedVariationItems}
                              isMulti
                            />
                          ) : (
                            <></>
                          )}
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            zIndex: -1,
                          }}
                        >
                          <Text style={{ fontWeight: "500", marginRight: 4 }}>
                            Get
                          </Text>

                          <View>
                            <Picker
                              selectedValue={selectedVariationB1F1}
                              style={{
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                                height: 35,
                                width: 200,
                                paddingVertical: 0,
                                margin: 6,
                              }}
                              onValueChange={(item) => {
                                setSelectedVariationB1F1(item.value);
                              }}
                            >
                              {PROMOTION_TYPE_VARIATION_DROPDOWN_LIST.slice(
                                1
                              ).map((value, index) => {
                                return (
                                  <Picker.Item
                                    key={index}
                                    label={value.label}
                                    value={value.value}
                                  />
                                );
                              })}
                            </Picker>
                          </View>
                          <Text style={{ fontWeight: "500", marginLeft: 4 }}>
                            Voucher
                          </Text>
                        </View>
                      </View>
                    </View>
                  </View>
                ) : (
                  <></>
                )}

                {/*//////////////////////////////////END HERE//////////////////////////////////////*/}

              </View>

              {/* *************NOTIFICATION******************** */}

              <View
                style={{
                  marginTop: 35,
                  marginBottom: 15,
                  marginLeft: 23,
                  flexDirection: "row",
                  alignItems: "center",
                  zIndex: -1,
                }}
              >
              </View>

              <View style={{ height: 60 }}></View>
            </ScrollView>
          </KeyboardAvoidingView>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },
  list: {
    backgroundColor: Colors.whiteColor,
    width: Dimensions.get("screen").width * 0.87,
    height: Dimensions.get("screen").height * 0.825,
    marginTop: 0,
    marginHorizontal: 20,
    alignSelf: "center",
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    //width: Dimensions.get('screen').width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 16,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  titleList: {
    backgroundColor: "#ffffff",
    flexDirection: "row",
    paddingVertical: 20,
    paddingHorizontal: 20,
    //marginTop: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

    alignItems: "center",

    // shadowOpacity: 0,
    // shadowColor: '#000',
    // shadowOffset: {
    //   width: 0,
    //   height: 2,
    // },
    // shadowOpacity: 0.22,
    // shadowRadius: 3.22,
    // elevation: 3,
  },
  textTitle: {
    fontSize: 16,
    fontFamily: "NunitoSans-Bold",
  },
  textItem: {
    fontSize: 14,
    fontFamily: "NunitoSans-Regular",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    // alignContent: 'center',
    alignItems: "center",
    backgroundColor: "white",
    padding: 20,
    paddingTop: 15,
    marginTop: 0,
    width: "100%",

    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 7,
  },
  addVoucher: {
    marginTop: 0,
    //justifyContent: 'center',
    alignItems: "center",
    //alignContent: 'center',
    width: Dimensions.get("screen").width * 0.78,
    backgroundColor: Colors.whiteColor,
    // marginRight: 100,

    borderRadius: 4,

    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
  textInput: {
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 20,
    width: 300,
  },
  textInput1: {
    width: 250,
    height: 40,
    backgroundColor: "white",
    borderRadius: 10,
    // marginLeft: '53%',
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",

    marginRight: Dimensions.get("screen").width * Styles.sideBarWidth,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  headerLeftStyle: {
    width: Dimensions.get("screen").width * 0.17,
    justifyContent: "center",
    alignItems: "center",
  },
  textFieldInput: {
    height: 140,
    width: 650,
    //paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
  },
  modalView: {
    height: 500,
    width: 415,
    backgroundColor: Colors.whiteColor,
    borderRadius: Dimensions.get("screen").width * 0.03,
    padding: 12,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  modalView1: {
    height:
      Platform.OS === "ios"
        ? Dimensions.get("screen").width * 0.7
        : Dimensions.get("screen").width * 0.6,
    width:
      Platform.OS === "ios"
        ? Dimensions.get("screen").width * 0.7
        : Dimensions.get("screen").width * 0.6,
    backgroundColor: Colors.whiteColor,
    borderRadius: Dimensions.get("screen").width * 0.03,
    padding: 20,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  modalView2: {
    height:
      Platform.OS === "ios"
        ? Dimensions.get("screen").width * 0.35
        : Dimensions.get("screen").width * 0.25,
    width:
      Platform.OS === "ios"
        ? Dimensions.get("screen").width * 0.5
        : Dimensions.get("screen").width * 0.4,
    backgroundColor: Colors.whiteColor,
    borderRadius: Dimensions.get("screen").width * 0.03,
    padding: 20,
    paddingTop: 25,
    //paddingBottom: 30,
  },
  modalContainer1: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "relative",
    alignSelf: "flex-end",
    marginRight: -10,
    marginTop: -15,

    elevation: 1000,
    zIndex: 1000,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
  },
  modalSaveButton: {
    width: Dimensions.get("screen").width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  textInput8: {
    fontFamily: "NunitoSans-Regular",
    width: 60,
    height: 40,
    flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    paddingHorizontal: 5,
    borderColor: "#E5E5E5",
    borderWidth: 1,
  },
});

export default NewCampaignScreen;
