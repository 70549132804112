// not compatible with web - ncw
import React, {
  Component,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  TextInput,
  Dimensions,
  FlatList,
  Modal,
  PermissionsAndroid,
  Platform,
  KeyboardAvoidingView,
  Switch,
  Picker,
} from "react-native";
import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import * as User from "../util/User";
import Icon from "react-native-vector-icons/Feather";
import Icon1 from "react-native-vector-icons/FontAwesome";
import EvilIcons from "react-native-vector-icons/EvilIcons";
import AntDesign from "react-native-vector-icons/AntDesign";
import TimeKeeper from "react-timekeeper";
import DropDownPicker from "react-native-dropdown-picker";
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
// import { ceil } from 'react-native-reanimated';
import DateTimePicker from "react-native-modal-datetime-picker";
import moment from "moment";
import Close from "react-native-vector-icons/AntDesign";
import ApiClient from "../util/ApiClient";
import API from "../constant/API";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Styles from "../constant/Styles";
// ncw
// import DocumentPicker from "react-native-document-picker";
import Ionicons from "react-native-vector-icons/Ionicons";
// ncw
// import RNFetchBlob from "rn-fetch-blob";
import { CSVLink } from "react-csv";
import "react-native-get-random-values";
import { customAlphabet } from "nanoid";
import { CommonStore } from "../store/commonStore";
import { MerchantStore } from "../store/merchantStore";
import {
  PURCHASE_ORDER_STATUS,
  PURCHASE_ORDER_STATUS_PARSED,
} from "../constant/common";
import { UserStore } from "../store/userStore";
import { OutletStore } from "../store/outletStore";
import {
  convertArrayToCSV,
  listenToCurrOutletIdChangesWaiter,
} from "../util/common";
// ncw
import DateTimePickerModal from "react-native-modal-datetime-picker";
import AsyncImage from "../components/asyncImage";
// ncw
// import Switch from "react-native-switch-pro";
import { PROMOTION_TYPE_VARIATION } from "../constant/promotions";
import { ReactComponent as Coins } from "../assets/svg/Coins.svg";
import { ReactComponent as Dish } from "../assets/svg/Dish.svg";
import { ReactComponent as Hand } from "../assets/svg/Hand.svg";
import { ReactComponent as Time } from "../assets/svg/Times.svg";
// import { useKeyboard } from '../hooks';
import { useWindowDimensions } from "react-native";
import { useLinkTo, useRoute } from "@react-navigation/native";
import { DataStore } from "../store/dataStore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";

const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 12);

const PreorderPackageScreen = (props) => {
  const { navigation } = props;
  const linkTo = useLinkTo();
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const [purchaseOrder, setPurchaseOrder] = useState(true);
  const [addStockTake, setAddStockTake] = useState(false);
  const [addPurchase, setAddPurchase] = useState(false);
  const [editPurchase, setEditPurchase] = useState(false);
  const [addStockTransfer, setAddStockTransfer] = useState(false);
  const [expandOrder, setExpandOrder] = useState(false);
  const [stockList, setStockList] = useState([]);
  const [stockTransferList, setStockTransferList] = useState([]);
  const [stockTakeList, setStockTakeList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [itemsToOrder, setItemsToOrder] = useState([{}]);
  const [itemsToOrder2, setItemsToOrder2] = useState([{}, {}, {}]);
  const [addStockTransferList, setAddStockTransferList] = useState([
    {},
    {},
    {},
  ]);
  const [addCountedStockTakeList, setAddCountedStockTakeList] = useState([
    {},
    {},
    {},
  ]);
  const [addUnCountedStockTakeList, setAddUnCountedStockTakeList] = useState([
    {},
    {},
    {},
  ]);
  const [productList, setProductList] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);
  const [isSelected3, setIsSelected3] = useState(true);
  const [isSelected4, setIsSelected4] = useState(false);
  const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
  const [date, setDate] = useState(Date.now());
  const [visible, setVisible] = useState(false);
  const [Email, setEmail] = useState(null);
  const [modal, setModal] = useState(false);
  const [outletId, setOutletId] = useState(User.getOutletId());
  const [search, setSearch] = useState("");
  const [search2, setSearch2] = useState("");
  const [search3, setSearch3] = useState("");
  const [ideal, setIdeal] = useState("");
  const [minimum, setMinimum] = useState("");
  const [itemId, setItemId] = useState("");
  const [choose, setChoose] = useState(null);
  const [preOrderExpandDict, setPreOrderExpandDict] = useState({});

  const [loading, setLoading] = useState(false);
  const [choice2, setChoice2] = useState("Print P.O");
  const [choice7, setChoice7] = useState("1");
  const [choice8, setChoice8] = useState("1");
  const [choice4, setChoice4] = useState("Chicken patty");

  ///////////////////////////////////////////////////////////////////

  const [categoryName, setCategoryName] = useState("");

  const [supplierName, setSupplierName] = useState("");
  const [supplierEmail, setSupplierEmail] = useState("");
  const [supplierPhone, setSupplierPhone] = useState("");
  const [supplierTax, setSupplierTax] = useState("");

  const [supplierItems, setSupplierItems] = useState([
    {
      supplyItemId: "",
      name: "",
      sku: "",
      unit: "",
      price: 0,
    },
  ]);

  ///////////////////////////////////////////////////////////////////

  const [poId, setPoId] = useState("");

  const [tempStartTime, setTempStartTime] = useState('');
  const [tempEndTime, setTempEndTime] = useState('');
  const [tempRedemptionStartTime, setTempRedemptionStartTime] = useState('');
  const [tempRedemptionEndTime, setTempRedemptionEndTime] = useState('');

  const [supplierDropdownList, setSupplierDropdownList] = useState([]);
  const [selectedSupplierId, setSelectedSupplierId] = useState("");

  const [poStatus, setPoStatus] = useState(PURCHASE_ORDER_STATUS.CREATED);

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState("");

  const [supplyItemDropdownList, setSupplyItemDropdownList] = useState([]);

  const [poItems, setPoItems] = useState([
    {
      supplyItemId: "",
      name: "",
      sku: "",
      quantity: 0,
      orderQuantity: 0,
      receivedQuantity: 0,
      price: 0,
      totalPrice: 0,
    },
  ]);

  const [selectedSupplier, setSelectedSupplier] = useState({
    taxRate: 0,
  });

  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);

  const [packagePrice, setPackagePrice] = useState("");
  const [packageQuantity, setPackageQuantity] = useState(0);

  const [expandViewDict, setExpandViewDict] = useState({});

  const [outletItems, setOutletItems] = useState([]);
  const [outletItemsDropdownList, setOutletItemsDropdownList] = useState([]);
  const [selectedOutletItems, setSelectedOutletItems] = useState([]);
  const [selectedOutletItemsSku, setSelectedOutletItemsSku] = useState([]);

  // const [showPreorderDatePicker, setShowPreorderDatePicker] = useState(false);
  // const [showPreorderTimePicker, setShowPreorderTimePicker] = useState(false);
  // const [preorderDate, setPreorderDate] = useState(moment());
  // const [preorderTime, setPreorderTime] = useState(moment());

  {
    /* *************************** Test Start End date and Time ****************************/
  }
  const [showStartDatePicker, setShowStartDatePicker] = useState(false);
  const [showStartTimePicker, setShowStartTimePicker] = useState(false);
  const [startDate, setStartDate] = useState(moment());
  const [startTime, setStartTime] = useState(moment());

  const [showRedemptionStartDatePicker, setShowRedemptionStartDatePicker] =
    useState(false);
  const [showRedemptionStartTimePicker, setShowRedemptionStartTimePicker] =
    useState(false);
  const [redemptionStartDate, setRedemptionStartDate] = useState(moment());
  const [redemptionStartTime, setRedemptionStartTime] = useState(moment());

  const [showEndDatePicker, setShowEndDatePicker] = useState(false);
  const [showEndTimePicker, setShowEndTimePicker] = useState(false);
  const [endDate, setEndDate] = useState(moment());
  const [endTime, setEndTime] = useState(moment());

  const [showRedemptionEndDatePicker, setShowRedemptionEndDatePicker] =
    useState(false);
  const [showRedemptionEndTimePicker, setShowRedemptionEndTimePicker] =
    useState(false);
  const [redemptionEndDate, setRedemptionEndDate] = useState(moment());
  const [redemptionEndTime, setRedemptionEndTime] = useState(moment());

  const [variationItemsDropdownList, setVariationItemsDropdownList] = useState(
    []
  );

  const [preorderPackageDetails, setPreorderPackageDetails] = useState([]);

  const [bdItems, setBdItems] = useState([
    {
      bdItemId: "",
      outletItemSku: "",
      outletItemId: "",
      variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
      isActive: true,
      quantity: 0,
    },
  ]);

  console.log("bdItems");
  console.log(bdItems);

  //////////////////////////////////////////////////////////////////////

  const [qtySold, setQtySold] = useState(0);
  const [qtyClaimed, setQtyClaimed] = useState(0);
  const [qtyActive, setQtyActive] = useState(0);
  const [qtyExpired, setQtyExpired] = useState(0);

  const FILTER_PREORDER_PACKAGE = {
    ALL_PACKAGES: "FILTER_PREORDER_PACKAGE.ALL_PACKAGES",
    PACKAGES_SOLD: "FILTER_PREORDER_PACKAGE.PACKAGES_SOLD",
    PACKAGES_CLAIMED: "FILTER_PREORDER_PACKAGE.PACKAGES_CLAIMED",
    PACKAGES_ACTIVE: "FILTER_PREORDER_PACKAGE.PACKAGES_ACTIVE",
    PACKAGES_EXPIRED: "FILTER_PREORDER_PACKAGE.PACKAGES_EXPIRED",
  };

  const [filterPreorderPackages, setFilterPreorderPackages] = useState(
    FILTER_PREORDER_PACKAGE.ALL_PACKAGES
  );

  //////////////////////////////////////////////////////////////////////

  const outletSupplyItemsSkuDict = CommonStore.useState(
    (s) => s.outletSupplyItemsSkuDict
  );

  const supplyItems = CommonStore.useState((s) => s.supplyItems);
  const supplyItemsDict = CommonStore.useState((s) => s.supplyItemsDict);
  const suppliers = CommonStore.useState((s) => s.suppliers);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const merchantId = UserStore.useState((s) => s.merchantId);
  const purchaseOrders = CommonStore.useState((s) => s.purchaseOrders);

  const userName = UserStore.useState((s) => s.name);
  const merchantName = MerchantStore.useState((s) => s.name);

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const currOutlet = MerchantStore.useState((s) => s.currOutlet);

  // const outletCategories = OutletStore.useState(s => s.outletCategories);
  const outletItemsUnsorted = OutletStore.useState((s) => s.outletItems);
  const allOutletsItemsSkuDict = OutletStore.useState(
    (s) => s.allOutletsItemsSkuDict
  );

  const preorderPackages = OutletStore.useState((s) => s.preorderPackages);

  const selectedPreorderPackageEdit = CommonStore.useState(
    (s) => s.selectedPreorderPackageEdit
  );
  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const allOutletsUserOrdersDone = OutletStore.useState(
    (s) => s.allOutletsUserOrdersDone
  );

  const userOrdersAllStatus = OutletStore.useState(
    (s) => s.userOrdersAllStatus
  );

  const RefDropdown = useRef(0);

  // ncw
  // const [keyboardHeight] = useKeyboard();

  // useEffect for navigation
  useEffect(() => {
    if (linkTo) {
      DataStore.update((s) => {
        s.linkToFunc = linkTo;
      });
    }
  }, [linkTo]);

  useEffect(() => {
    if (selectedPreorderPackageEdit) {
      // insert info

      setCategoryName(selectedPreorderPackageEdit.name);
      // setSelectedOutletItems(selectedPreorderPackageEdit.outletItemIdList);
      // setPreorderDate(selectedPreorderPackageEdit.releaseDate);
      // setPreorderTime(selectedPreorderPackageEdit.releaseTime);

      setStartDate(selectedPreorderPackageEdit.startDate);
      setStartTime(selectedPreorderPackageEdit.startTime);
      setEndDate(selectedPreorderPackageEdit.endDate);
      setEndTime(selectedPreorderPackageEdit.endTime);

      setRedemptionStartDate(selectedPreorderPackageEdit.redemptionStartDate);
      setRedemptionStartTime(selectedPreorderPackageEdit.redemptionStartTime);
      setRedemptionEndDate(selectedPreorderPackageEdit.redemptionEndDate);
      setRedemptionEndTime(selectedPreorderPackageEdit.redemptionEndTime);

      setPackageQuantity(selectedPreorderPackageEdit.packageQuantity);
      setPackagePrice(selectedPreorderPackageEdit.packagePrice);

      setBdItems(
        selectedPreorderPackageEdit.bdItems
          ? selectedPreorderPackageEdit.bdItems
          : [
              {
                bdItemId: "",
                outletItemSku: variationItemsDropdownList[0].value,
                outletItemId: variationItemsDropdownList[0].itemId,
                variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
                isActive: true,
                quantity: 0,
              },
            ]
      );
    } else {
      // designed to always mounted, thus need clear manually...

      setCategoryName("");
      // setSelectedOutletItems([]);
      // setPreorderDate(moment());
      // setPreorderTime(moment());

      setStartDate(moment());
      setStartTime(moment());
      setEndDate(moment());
      setEndTime(moment());

      setRedemptionStartDate(moment());
      setRedemptionStartTime(moment());
      setRedemptionEndDate(moment());
      setRedemptionEndTime(moment());

      setPackageQuantity(0);
      setPackagePrice("");

      if (variationItemsDropdownList.length > 0) {
        setBdItems([
          {
            bdItemId: "",
            outletItemSku:
              variationItemsDropdownList.length > 0
                ? variationItemsDropdownList[0].value
                : "",
            outletItemId:
              variationItemsDropdownList.length > 0
                ? variationItemsDropdownList[0].itemId
                : "",
            variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
            isActive: true,
            quantity: 0,
          },
        ]);
      }
    }
  }, [selectedPreorderPackageEdit]);

  useEffect(() => {
    if (variationItemsDropdownList.length > 0) {
      if (
        bdItems.length > 0 &&
        !bdItems[0].bdItemId &&
        !bdItems[0].outletItemSku
      ) {
        setBdItems([
          {
            bdItemId: "",
            outletItemSku: variationItemsDropdownList[0].value,
            outletItemId: variationItemsDropdownList[0].itemId,
            variation: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
            isActive: true,
            quantity: 0,
          },
        ]);
      }
    }
  }, [
    variationItemsDropdownList,
    // bdItems,
  ]);

  useEffect(() => {
    setSupplierDropdownList(
      suppliers.map((supplier) => ({
        label: supplier.name,
        value: supplier.uniqueId,
      }))
    );

    if (selectedSupplierId === "" && suppliers.length > 0) {
      setSelectedSupplierId(suppliers[0].uniqueId);
    }
  }, [suppliers]);

  useEffect(() => {
    setTargetOutletDropdownList(
      allOutlets.map((outlet) => ({
        label: outlet.name,
        value: outlet.uniqueId,
      }))
    );

    if (selectedTargetOutletId === "" && allOutlets.length > 0) {
      setSelectedTargetOutletId(allOutlets[0].uniqueId);
    }
  }, [allOutlets]);

  useEffect(() => {
    setSupplyItemDropdownList(
      supplyItems.map((supplyItem) => ({
        label: supplyItem.name,
        value: supplyItem.uniqueId,
      }))
    );

    if (
      supplyItems.length > 0 &&
      poItems.length === 1 &&
      poItems[0].supplyItemId === ""
    ) {
      setPoItems([
        {
          supplyItemId: supplyItems[0].uniqueId,
          name: supplyItems[0].name,
          sku: supplyItems[0].sku,
          quantity: outletSupplyItemsSkuDict[supplyItems[0].sku]
            ? outletSupplyItemsSkuDict[supplyItems[0].sku].quantity
            : 0, // check if the supply item sku for this outlet existed
          orderQuantity: 0,
          receivedQuantity: 0,
          price: supplyItems[0].price,
          totalPrice: 0,
        },
      ]);
    } else if (
      poItems[0].supplyItemId !== "" &&
      Object.keys(supplyItemsDict).length > 0
    ) {
      var poItemsTemp = [...poItems];

      for (var i = 0; i < poItemsTemp.length; i++) {
        const supplyItem = supplyItemsDict[poItemsTemp[i].supplyItemId];

        poItemsTemp[i] = {
          ...poItemsTemp[i],
          quantity: outletSupplyItemsSkuDict[supplyItem.sku]
            ? outletSupplyItemsSkuDict[supplyItem.sku].quantity
            : 0, // check if the supply item sku for this outlet existed | might changed in real time
          price: supplyItem.price, // might changed in real time
        };
      }

      setPoItems(poItemsTemp);
    }
  }, [
    supplyItems,
    supplyItemsDict,
    // outletSupplyItemsSkuDict
  ]);

  useEffect(() => {
    if (suppliers.length > 0 && selectedSupplierId !== "") {
      setSelectedSupplier(
        suppliers.find((supplier) => supplier.uniqueId === selectedSupplierId)
      );
    }
  }, [suppliers, selectedSupplierId]);

  useEffect(() => {
    console.log("subtotal");
    console.log(
      poItems.reduce((accum, poItem) => accum + poItem.totalPrice, 0)
    );
    setSubtotal(
      poItems.reduce((accum, poItem) => accum + poItem.totalPrice, 0)
    );
  }, [poItems]);

  useEffect(() => {
    console.log("taxTotal");
    console.log(subtotal * selectedSupplier.taxRate);
    setTaxTotal(subtotal * selectedSupplier.taxRate);
  }, [subtotal]);

  useEffect(() => {
    console.log("finalTotal");
    console.log(subtotal - discountTotal + taxTotal);
    setFinalTotal(subtotal - discountTotal + taxTotal);
  }, [subtotal, discountTotal, taxTotal]);

  useEffect(() => {
    var outletItemsTemp = [...outletItemsUnsorted];

    outletItemsTemp.sort((a, b) => a.name.localeCompare(b.name));

    setOutletItems(outletItemsTemp);
  }, [outletItemsUnsorted]);

  useEffect(() => {
    setOutletItemsDropdownList(
      outletItems.map((item) => ({ label: item.name, value: item.uniqueId }))
    );

    setVariationItemsDropdownList(
      outletItems.map((item) => ({
        label: item.name,
        value: item.sku,
        itemId: item.uniqueId,
      }))
    );
  }, [outletItems]);

  useEffect(() => {
    var selectedOutletItemsSkuTemp = [];

    for (var i = 0; i < outletItems.length; i++) {
      if (selectedOutletItems.includes(outletItems[i].uniqueId)) {
        selectedOutletItemsSkuTemp.push(outletItems[i].sku);
      }
    }

    setSelectedOutletItemsSku(selectedOutletItemsSkuTemp);
  }, [selectedOutletItems, outletItems]);

  useEffect(() => {
    requestStoragePermission();

    setPoId(nanoid());
  }, []);

  useEffect(() => {
    var qtySoldTemp = 0;
    var qtyClaimedTemp = 0;
    var qtyActiveTemp = 0;
    var qtyExpiredTemp = 0;

    preorderPackages.map((preorderPackage) => {
      var relatedOrders = userOrdersAllStatus.filter(
        (order) => order.preorderPackageId === preorderPackage.uniqueId // [{}, {uniqueId, ...}].uniqueId is undefined
      );

      for (var i = 0; i < relatedOrders.length; i++) {
        if (
          relatedOrders[i].paymentDetails &&
          relatedOrders[i].paymentDetails.channel
        ) {
          qtySoldTemp += 1;
        }

        if (relatedOrders[i].completedDate) {
          qtyClaimedTemp += 1;
        } else {
          // either active or expired

          if (preorderPackage.redemptionEndDate) {
            if (moment(preorderPackage.redemptionEndDate).isAfter(moment())) {
              qtyActiveTemp += 1;
            } else {
              qtyExpiredTemp += 1;
            }
          } else {
            qtyExpiredTemp += 1;
          }
        }
      }
    });

    setQtySold(qtySoldTemp);
    setQtyClaimed(qtyClaimedTemp);
    setQtyActive(qtyActiveTemp);
    setQtyExpired(qtyExpiredTemp);
  }, [preorderPackages, userOrdersAllStatus]);

  /* useEffect(() => {

    var preOrderTempDetails = [];

    if (currOutletId && currOutletId.length > 0) {

    var record = {
      soldQty = 0,
      claimedQty = 0,
      activeQty = 0,
      expiredQty = 0,
    }


      
      for (var j = 0; j < allOutletsUserOrdersDone.length; j++) {
        if (allOutletsUserOrdersDone[j].outletId === currOutletId) {
            record.soldQty += allOutletsUserOrdersDone.price
            };
        }

      }



  }, []); */

  const setState = () => {};

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  // navigation.setOptions({
  //   headerLeft: () => (
  //     <View style={styles.headerLeftStyle}>
  //       <Image
  //         style={{
  //           width: 124,
  //           height: 26,
  //         }}
  //         resizeMode="contain"
  //         source={require('../assets/image/logo.png')}
  //       />
  //     </View>
  //   ),
  //   headerTitle: () => (
  //     <View
  //       style={[{
  //         justifyContent: 'center',
  //         alignItems: 'center',
  //         // bottom: -2,
  //         bottom: switchMerchant? '2%': 0,
  //       }, Dimensions.get('screen').width >= 768 && switchMerchant ? { right: Dimensions.get('screen').width * 0.1 } : {}, Dimensions.get('screen').width <= 768?{right: Dimensions.get('screen').width * 0.12}:{}]}>
  //       <Text
  //         style={{
  //           fontSize:  24,
  //           // lineHeight: 25,
  //           textAlign: 'center',
  //           fontFamily: 'NunitoSans-Bold',
  //           color: Colors.whiteColor,
  //           opacity: 1,
  //         }}>
  //         Manage Preorder
  //       </Text>
  //     </View>
  //   ),
  //   headerRight: () => (
  //     <View
  //       style={{
  //         flexDirection: 'row',
  //         alignItems: 'center',
  //         justifyContent: 'space-between',
  //       }}>
  //       {outletSelectDropdownView()}
  //       <View
  //         style={{
  //           backgroundColor: 'white',
  //           width: 0.5,
  //           height: Styles.height * 0.025,
  //           opacity: 0.8,
  //           marginHorizontal: 15,
  //           bottom: -1,
  //         }}></View>
  //       <TouchableOpacity
  //         onPress={() => navigation.navigate('Setting')}
  //         style={{flexDirection: 'row', alignItems: 'center'}}>
  //         <Text
  //           style={{
  //             fontFamily: 'NunitoSans-SemiBold',
  //             fontSize: 16,
  //             color: Colors.secondaryColor,
  //             marginRight: 15,
  //           }}>
  //           {userName}
  //         </Text>
  //         <View
  //           style={{
  //             marginRight: 30,
  //             width: Styles.height * 0.05,
  //             height: Styles.height * 0.05,
  //             borderRadius: Styles.height * 0.05 * 0.5,
  //             alignItems: 'center',
  //             justifyContent: 'center',
  //             backgroundColor: 'white',
  //           }}>
  //           <Image
  //             style={{
  //               width: Styles.height * 0.035,
  //               height: Styles.height * 0.035,
  //               alignSelf: 'center',
  //             }}
  //             source={require('../assets/image/profile-pic.jpg')}
  //           />
  //         </View>
  //       </TouchableOpacity>
  //     </View>
  //   ),
  // });

  // componentDidMount = () => {
  //   // setInterval(() => {
  //   //   getStockOrder()
  //   //   getStockTransfer()
  //   //   getLowStock()
  //   // }, 1000);
  //   getStockOrder();

  // }

  // async componentWillMount = () => {
  //   await requestStoragePermission()
  // }

  const requestStoragePermission = async () => {
    try {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: "KooDoo Merchant Storage Permission",
          message: "KooDoo Merchant App needs access to your storage ",
          buttonNegative: "Cancel",
          buttonPositive: "OK",
        }
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        console.log("Storage permission granted");
      } else {
        console.log("Storage permission denied");
      }
    } catch (err) {
      console.warn(err);
    }
  };

  // ncw
  // const importCSV = () => {
  //   try {
  //     const res = DocumentPicker.pick({
  //       type: [DocumentPicker.types.csv],
  //     });
  //     console.log(res.uri, res.type, res.name, res.size);
  //   } catch (err) {
  //     if (DocumentPicker.isCancel(err)) {
  //       // User cancelled the picker, exit any dialogs or menus and move on
  //     } else {
  //       throw err;
  //     }
  //   }
  // };

  const expandOrderFunc = (param) => {
    if (expandOrder == false) {
      // return setState({ expandOrder: true }), param.expandOrder = true;
      setExpandOrder(true);
      setExpandViewDict({
        ...expandViewDict,
        [param.uniqueId]: true,
      });
      // ncw
      // expandViewDict;
    } else {
      // return setState({ expandOrder: false }), param.expandOrder = false;
      setExpandOrder(false);
      setExpandViewDict({
        ...expandViewDict,
        [param.uniqueId]: undefined,
      });
    }
  };

  // ncw
  // Change to CSVLink if want use back this function
  // const exportFunc = () => {
  //   // var body = {
  //   //   data: orderList
  //   // }

  //   if (preorderPackages) {
  //     const csvData = convertArrayToCSV(preorderPackages);

  //     const pathToWrite = `${
  //       RNFetchBlob.fs.dirs.DownloadDir
  //     }/koodoo-report-product-category-${moment().format(
  //       "YYYY-MM-DD-HH-mm-ss"
  //     )}.csv`;
  //     console.log("PATH", pathToWrite);
  //     RNFetchBlob.fs
  //       .writeFile(pathToWrite, csvData, "utf8")
  //       .then(() => {
  //         console.log(`wrote file ${pathToWrite}`);
  //         // wrote file /storage/emulated/0/Download/data.csv
  //         Alert.alert(
  //           "Success",
  //           `Sent to ${pathToWrite}`,
  //           [{ text: "OK", onPress: () => {} }],
  //           { cancelable: false }
  //         );
  //       })
  //       .catch((error) => console.error(error));
  //   }

  //   // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
  //   //   console.log("RESULT", result)
  //   //   if (result !== null) {
  //   //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/POData.csv`;
  //   //     console.log("PATH", pathToWrite)
  //   //     RNFetchBlob.fs
  //   //       .writeFile(pathToWrite, result, 'utf8')
  //   //       .then(() => {
  //   //         console.log(`wrote file ${pathToWrite}`);
  //   //         // wrote file /storage/emulated/0/Download/data.csv
  //   //         Alert.alert(
  //   //           'Success',
  //   //           'The data had exported',
  //   //           [{ text: 'OK', onPress: () => { } }],
  //   //           { cancelable: false },
  //   //         );
  //   //       })
  //   //       .catch(error => console.error(error));
  //   //   }
  //   // });
  // };

  const importFunc = () => {
    var body = {
      data: orderList,
    };
    // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
    //   console.log(result)
    // });

    Alert.alert(
      "Success",
      "The data has been imported",
      [{ text: "OK", onPress: () => {} }],
      { cancelable: false }
    );
  };

  const getStockOrder = () => {
    // ApiClient.GET(API.getStockOrder + outletId).then((result) => {
    //   setState({ orderList: result })
    // });
  };

  // function here
  const showDateTimePicker = () => {
    // setState({ isDateTimePickerVisible: true });
    setIsDateTimePickerVisible(true);
  };

  const hideDateTimePicker = () => {
    // setState({ isDateTimePickerVisible: false });
    setIsDateTimePickerVisible(false);
  };

  const handleDatePicked = (date) => {
    // setState({ date: date.toString() });
    setDate(date);
  };

  const expandPreOrderFunc = (item) => {
    if (preOrderExpandDict[item.uniqueId]) {
      setPreOrderExpandDict({
        [item.uniqueId]: false,
      });
    } else {
      setPreOrderExpandDict({
        [item.uniqueId]: true,
      });
    }
  };

  const renderOrderItem = ({ item, index }) => {
    console.log("renderOrderItem");
    console.log(item);

    var outletItemImageList = [];
    var outletItemNameList = [];
    var outletItemPriceList = [];
    var outletItemQuantity = [];
    var outletItemDiscountAmount = [];
    var outletItemNormalSellingPrice = [];
    var outletItemSubtotalPrice = [];

    var normalPriceList = Array.from(Array(item.bdItems.length)).map(() => {
      return 0;
    });
    var subtotalPriceList = Array.from(Array(item.bdItems.length)).map(() => {
      return 0;
    });
    var discountAmountList = Array.from(Array(item.bdItems.length)).map(() => {
      return 0;
    });
    var totalPriceList = Array.from(Array(item.bdItems.length)).map(() => {
      return 0;
    });

    var normalSellingPrice = 0;

    for (var i = 0; i < item.bdItems.length; i++) {
      if (allOutletsItemsSkuDict[item.bdItems[i].outletItemSku]) {
        outletItemImageList.push(
          allOutletsItemsSkuDict[item.bdItems[i].outletItemSku][0].image
        );
        outletItemNameList.push(
          allOutletsItemsSkuDict[item.bdItems[i].outletItemSku][0].name
        );
        outletItemPriceList.push(
          allOutletsItemsSkuDict[item.bdItems[i].outletItemSku][0].price
        );

        normalPriceList[i] =
          allOutletsItemsSkuDict[item.bdItems[i].outletItemSku][0].price;
        subtotalPriceList[i] =
          item.bdItems[i].quantity *
          allOutletsItemsSkuDict[item.bdItems[i].outletItemSku][0].price;
        discountAmountList[i] = subtotalPriceList[i] - item.packagePrice;
        totalPriceList[i] = item.packagePrice;
      }
    }

    return (
      <View>
        <TouchableOpacity
          onPress={() => {
            // setState({
            //   lowStockAlert: false,
            //   purchaseOrder: false,
            //   stockTransfer: false,
            //   stockTake: false,
            //   addPurchase: false,
            //   editPurchase: true,
            //   addStockTransfer: false,
            //   addStockTake: false,
            // });

            // disable first
            // setEditPurchase(true);
            // setPurchaseOrder(false);

            CommonStore.update((s) => {
              s.selectedPreorderPackageEdit = item;
            });
            // OutletStore.update(s => {
            //   s.allOutletsItemsSkuDict = items;
            // })
            console.log("something");
            console.log(selectedPreorderPackageEdit);

            setPurchaseOrder(false);
            setAddPurchase(true);
          }}
        >
          <View
            style={{
              backgroundColor: "#ffffff",
              flexDirection: "row",
              paddingVertical: 20,
              paddingLeft: 15,
              paddingRight: 10,
              borderBottomWidth: StyleSheet.hairlineWidth,
              borderBottomColor: "#c4c4c4",
            }}
          >
            <Text
              style={{
                flex: 1,
                color: Colors.primaryColor,
                alignSelf: "flex-start",
                fontSize: 14,
                fontFamily: "NunitoSans-Regular",
              }}
            >{`${item.name}`}</Text>
            {/* <Text style={{ width: '58%' }}>{outletItemNameList.length > 0 ? outletItemNameList.join(', ') : 'N/A'}</Text> */}
            {/* <Text style={{ width: '11%' }}>{item.releaseDate ? moment(item.releaseDate).format('DD/MM/YYYY') : 'N/A'}</Text>
          <Text style={{ width: '11%' }}>{item.releaseTime ? moment(item.releaseTime).format('h:mma') : 'N/A'}</Text> */}

            {/* *************************** Test Start date and Time ****************************/}
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontVariant: ["tabular-nums"],
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                >
                  {moment(item.startDate).format("DD MMM YYYY")}{" "}
                </Text>
                <Text
                  style={{
                    fontVariant: ["tabular-nums"],
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                >
                  {moment(item.startTime).format("hh:mm A")}
                </Text>
              </View>
              <View style={{ width: "55%", justifyContent: "center" }}>
                <Text
                  style={{
                    //fontWeight: '200',
                    marginTop: 2,
                    marginBottom: 2,
                    alignSelf: "center",
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                >
                  {" "}
                  to{" "}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontVariant: ["tabular-nums"],
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                >
                  {moment(item.endDate).format("DD MMM YYYY")}{" "}
                </Text>
                <Text
                  style={{
                    fontVariant: ["tabular-nums"],
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                >
                  {moment(item.endTime).format("hh:mm A")}
                </Text>
              </View>
            </View>

            {/* Dummy data */}
            {/* Redemption Period */}
            <View
              style={{
                flexDirection: "column",
                flex: 1,
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontVariant: ["tabular-nums"],
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                >
                  {moment(item.redemptionStartDate).format("DD MMM YYYY")}{" "}
                </Text>
                <Text
                  style={{
                    fontVariant: ["tabular-nums"],
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                >
                  {moment(item.redemptionStartTime).format("hh:mm A")}
                </Text>
              </View>
              <View style={{ width: "55%", justifyContent: "center" }}>
                <Text
                  style={{
                    //fontWeight: '200',
                    marginTop: 2,
                    marginBottom: 2,
                    alignSelf: "center",
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                >
                  {" "}
                  to{" "}
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontVariant: ["tabular-nums"],
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                >
                  {moment(item.redemptionEndDate).format("DD MMM YYYY")}{" "}
                </Text>
                <Text
                  style={{
                    fontVariant: ["tabular-nums"],
                    fontSize: 14,
                    fontFamily: "NunitoSans-Regular",
                  }}
                >
                  {moment(item.redemptioneEndTime).format("hh:mm A")}
                </Text>
              </View>
            </View>

            {/* Status */}
            <View
              style={{
                flexDirection: "row",
                flex: 1,
                alignItems: "flex-start",
              }}
            >
              <View
                style={{
                  width: "65%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <View style={{}}>
                  <Switch
                    onSyncPress={(value) => {
                      // setCustomerIsActiveMember(value);

                      switchPreorderPackageStatus(item, value);
                    }}
                    style={{ transform: [{ scaleX: 0.9 }, { scaleY: 0.85 }] }}
                    // value={customerIsActiveMember}
                    width={42}
                    circleColorActive={Colors.primaryColor}
                    circleColorInactive={Colors.fieldtTxtColor}
                    backgroundActive={"#dddddd"}
                    value={item.isActive}
                  />
                </View>
                {/* <Text style={{ fontSize: 13, fontWeight: '500', marginLeft: 10, textAlign: 'center', color: customerIsActiveMember ? Colors.primaryColor : Colors.fieldtTxtColor }}>
                      {customerIsActiveMember ? 'Active Member' : 'Inactive Member'}
                    </Text> */}
              </View>
            </View>

            <View
              style={{
                flex: 0.5,
                alignItems: "flex-start",
                //backgroundColor: 'red'
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  expandPreOrderFunc(item);
                }}
              >
                <Icon
                  name={
                    preOrderExpandDict[item.uniqueId] == true
                      ? "chevron-up"
                      : "chevron-down"
                  }
                  size={30}
                  color={Colors.tabGrey}
                />
              </TouchableOpacity>
            </View>
          </View>
        </TouchableOpacity>

        {preOrderExpandDict[item.uniqueId] ? (
          <View style={{ marginVertical: 5 }}>
            {outletItemNameList.map((outletItemName, index) => {
              return (
                // <View style={{ flexDirection: 'row', width: '100%', marginBottom: 5, padding: 5, marginHorizontal: 10, alignItems: 'center', }}>
                //   <Text style={{ fontFamily: 'NunitoSans-Regular', fontWeight: '500', fontSize: 15, width: '3%' }}>
                //     {index + 1}.
                //   </Text>
                //   <View style={{ width: '10%' }}>
                //     <AsyncImage source={{ uri: outletItemImageList[index] }} style={{ width: 60, height: 55, borderRadius: 10, borderWidth: 1, borderColor: '#E5E5E5' }} />
                //   </View>
                //   <Text style={{ fontFamily: 'NunitoSans-Regular', fontWeight: '500', fontSize: 15, width: '70%' }}>
                //     {outletItemName}
                //   </Text>
                //   <Text style={{ fontFamily: 'NunitoSans-Regular', fontWeight: '500', fontSize: 15, width: '15%' }}>
                //     RM {(outletItemPriceList[index]).toFixed(2)}
                //   </Text>

                // </View>

                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    marginBottom: 5,
                    padding: 5,
                    marginHorizontal: 10,
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      alignItems: "flex-start",
                      flexDirection: "row",
                      width: "80%",
                    }}
                  >
                    <View
                      style={{
                        flex: 0.3,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Text
                        style={{ fontFamily: "NunitoSans-Bold", fontSize: 16 }}
                      >
                        {index + 1}.
                      </Text>
                    </View>
                    <View style={{ flex: 0.5 }}>
                      <AsyncImage
                        source={{ uri: outletItemImageList[index] }}
                        style={{
                          width: 60,
                          height: 60,
                          borderRadius: 10,
                          borderColor: "#E5E5E5",
                        }}
                      />
                    </View>
                    <View style={{ flex: 3, flexDirection: "column" }}>
                      <View style={{ justifyContent: "center" }}>
                        <Text
                          style={{
                            fontFamily: "NunitoSans-Bold",
                            fontSize: 16,
                          }}
                        >
                          {outletItemName}
                        </Text>
                      </View>
                      <View
                        style={{ alignItems: "center", flexDirection: "row" }}
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            width: windowWidth * 0.4045,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                            }}
                          >
                            Normal Selling:
                          </Text>
                        </View>
                        <View style={{ width: "8%" }}>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                              textAlign: "left",
                            }}
                          >
                            RM
                          </Text>
                        </View>
                        <View style={{ width: "15%" }}>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                              textAlign: "right",
                            }}
                          >
                            {normalPriceList[index]
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{ alignItems: "center", flexDirection: "row" }}
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            width: windowWidth * 0.4045,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                            }}
                          >
                            Subtotal Price:
                          </Text>
                        </View>
                        <View style={{ width: "8%" }}>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                              textAlign: "left",
                            }}
                          >
                            RM
                          </Text>
                        </View>
                        <View style={{ width: "15%" }}>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                              textAlign: "right",
                            }}
                          >
                            {subtotalPriceList[index]
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{ alignItems: "center", flexDirection: "row" }}
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            width: windowWidth * 0.4045,
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                            }}
                          >
                            Discount Amount:
                          </Text>
                        </View>
                        <View style={{ width: "8%" }}>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                              textAlign: "left",
                            }}
                          >
                            RM
                          </Text>
                        </View>
                        <View style={{ width: "15%" }}>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                              textAlign: "right",
                            }}
                          >
                            {discountAmountList[index]
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </Text>
                        </View>
                      </View>
                      <View
                        style={{
                          justifyContent: "center",
                          width: windowWidth * 0.4045,
                        }}
                      >
                        <View style={{ justifyContent: "center" }}>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                            }}
                          >
                            Final Price:
                          </Text>
                        </View>
                        <View style={{ width: "8%" }}>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                              textAlign: "left",
                            }}
                          >
                            RM
                          </Text>
                        </View>
                        <View style={{ width: "15%" }}>
                          <Text
                            style={{
                              fontFamily: "NunitoSans-SemiBold",
                              color: Colors.fieldtTxtColor,
                              fontSize: 15,
                              textAlign: "right",
                            }}
                          >
                            {totalPriceList[index]
                              .toFixed(2)
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                          </Text>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        flex: 2.4,
                        justifyContent: "center",
                        alignItems: "center",
                        opacity: index === 0 ? 100 : 0,
                      }}
                    >
                      <Text
                        style={{ fontFamily: "NunitoSans-Bold", fontSize: 16 }}
                      >
                        {/* RM {outletItemPriceList[index].toFixed(2)} */}
                        RM{" "}
                        {item.packagePrice
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
        ) : null}
        <View style={{ borderBottomWidth: 1, borderColor: "#E5E5E5" }} />
      </View>
    );
  };

  const renderSupplierItems = ({ item, index }) => {
    return (
      <View
        style={{
          backgroundColor: "#ffffff",
          flexDirection: "row",
          paddingVertical: 20,
          paddingHorizontal: 20,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: "#c4c4c4",
          alignItems: "center",
          // height: (Dimensions.get('window').width * 0.1) * 3,
        }}
      >
        <View
          style={{
            width: "20%",
            // marginLeft: 50,
            marginLeft: "-1%",
          }}
        >
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              height: 40,
              width: 150,
              paddingHorizontal: 20,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 10,
            }}
            placeholder={"Item name"}
            placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
            keyboardType={"default"}
            // placeholder={itemName}
            onChangeText={(text) => {
              // setState({ itemName: text });
              setSupplierItems(
                supplierItems.map((supplierItem, i) =>
                  i === index
                    ? {
                        ...supplierItem,
                        name: text,
                        isChanged: true,
                      }
                    : supplierItem
                )
              );
            }}
            value={supplierItems[index].name}
            // ref={myTextInput}
          />
        </View>

        <View
          style={{
            width: "20%",
            // marginLeft: 50,
            // backgroundColor: 'blue',
            marginLeft: "-1%",
          }}
        >
          <TextInput
            editable={item.supplyItemId === ""}
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              height: 40,
              width: 150,
              paddingHorizontal: 20,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 10,
            }}
            placeholder={"SKU"}
            placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
            keyboardType={"default"}
            // placeholder={itemName}
            onChangeText={(text) => {
              // setState({ itemName: text });
              setSupplierItems(
                supplierItems.map((supplierItem, i) =>
                  i === index
                    ? {
                        ...supplierItem,
                        sku: text,
                        isChanged: true,
                      }
                    : supplierItem
                )
              );
            }}
            value={supplierItems[index].sku}
            // ref={myTextInput}
          />
        </View>

        <View
          style={{
            width: "20%",
            // marginLeft: 50,
            // backgroundColor: 'blue',
            // marginLeft: '-1%',
          }}
        >
          <TextInput
            editable={item.supplyItemId === ""}
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              height: 40,
              width: 100,
              paddingHorizontal: 20,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 10,
            }}
            placeholder={"Unit"}
            placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
            keyboardType={"default"}
            // placeholder={itemName}
            onChangeText={(text) => {
              // setState({ itemName: text });
              setSupplierItems(
                supplierItems.map((supplierItem, i) =>
                  i === index
                    ? {
                        ...supplierItem,
                        unit: text,
                        isChanged: true,
                      }
                    : supplierItem
                )
              );
            }}
            value={supplierItems[index].unit}
            // ref={myTextInput}
          />
        </View>

        <View
          style={{
            width: "20%",
            // marginLeft: 50,
            // backgroundColor: 'blue',
            // marginLeft: '-1%',
          }}
        >
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              height: 40,
              width: 100,
              paddingHorizontal: 20,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 10,
            }}
            placeholder={"Price"}
            placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
            keyboardType={"number-pad"}
            // placeholder={itemName}
            onChangeText={(text) => {
              // setState({ itemName: text });
              setSupplierItems(
                supplierItems.map((supplierItem, i) =>
                  i === index
                    ? {
                        ...supplierItem,
                        price: text,
                        isChanged: true,
                      }
                    : supplierItem
                )
              );
            }}
            value={supplierItems[index].price}
            // ref={myTextInput}
          />
        </View>

        <View
          style={{
            width: "10%",
            // marginLeft: 50,
            // backgroundColor: 'blue',
          }}
        ></View>

        <TouchableOpacity
          style={{ marginLeft: 10 }}
          onPress={() => {
            setSupplierItems([
              ...supplierItems.slice(0, index),
              ...supplierItems.slice(index + 1),
            ]);
          }}
        >
          <Icon name="trash-2" size={20} color="#eb3446" />
        </TouchableOpacity>
      </View>
    );
  };

  const email = () => {
    var body = {
      stockTransferId: 1,
      email: Email,
    };
    // ApiClient.POST(API.emailStockTransfer, body, false).then((result) => {

    //   if (result == true) {

    //     Alert.alert(
    //       'Success',
    //       'The email had sent',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  // const createStockOrder = () => {
  //   var body = {
  //     poId: poId,
  //     poItems: poItems,
  //     supplierId: selectedSupplierId,
  //     status: poStatus,
  //     outletId: selectedTargetOutletId,
  //     tax: taxTotal,
  //     discount: discountTotal,
  //     totalPrice: subtotal,
  //     finalTotal: finalTotal,
  //     estimatedArrivalDate: date,

  //     merchantId: merchantId,
  //     remarks: '',
  //   };

  //   ApiClient.POST(API.createPurchaseOrder, body).then((result) => {
  //     if (result && result.uniqueId) {
  //       Alert.alert(
  //         'Success',
  //         'Purchase order created successfully',
  //         [
  //           { text: "OK", onPress: () => { props.navigation.goBack() } }
  //         ],
  //         { cancelable: false },
  //       );
  //     }
  //   });
  // }

  const createPreorderPackage = () => {
    console.log("on createPreorderPackage");
    console.log(categoryName);
    console.log(packageQuantity);
    console.log(packagePrice);

    if (
      categoryName !== "" &&
      // && selectedOutletItems.length > 0
      packageQuantity > 0 &&
      packagePrice !== ""
    ) {
      if (selectedPreorderPackageEdit === null) {
        var body = {
          preorderName: categoryName,
          outletId: currOutletId,
          merchantId: merchantId,
          merchantName: merchantName,
          outletName: currOutlet.name,

          // outletItemIdList: selectedOutletItems,
          // outletItemSkuList: selectedOutletItemsSku,

          // preorderDate: moment(preorderDate).valueOf(),
          // preorderTime: moment(preorderTime).valueOf(),

          packageQuantity: packageQuantity,
          packagePrice: +parseFloat(packagePrice).toFixed(2),

          bdItems: bdItems,

          startDate: moment(startDate).valueOf(),
          startTime: moment(startTime).valueOf(),
          endDate: moment(endDate).valueOf(),
          endTime: moment(endTime).valueOf(),

          redemptionStartDate: moment(redemptionStartDate).valueOf(),
          redemptionStartTime: moment(redemptionStartTime).valueOf(),
          redemptionEndDate: moment(redemptionEndDate).valueOf(),
          redemptionEndTime: moment(redemptionEndTime).valueOf(),
        };

        console.log(body);

        // CommonStore.update(s => {
        //   s.isLoading = true;
        // });

        ApiClient.POST(API.createPreorderPackage, body).then((result) => {
          if (result && result.status === "success") {
            // window.confirm(
            //   "Preorder package has been created",
            //   [
            //     {
            //       text: "OK",
            //       onPress: () => {
            //         // props.navigation.goBack()
            //         setEditPurchase(false);
            //         setAddPurchase(false);
            //         setPurchaseOrder(true);
            //       },
            //     },
            //   ],
            //   { cancelable: false }
            // );
            let text = "Preorder package has been created";
            if (window.confirm(text) == true) {
              setEditPurchase(false);
              setAddPurchase(false);
              setPurchaseOrder(true);
            } else {
            }
          } else {
            alert("Failed to create Preorder package");
          }
        });
      } else {
        var body = {
          preorderName: categoryName,
          outletId: currOutletId,
          merchantId: merchantId,
          merchantName: merchantName,
          outletName: currOutlet.name,

          // outletItemIdList: selectedOutletItems,
          // outletItemSkuList: selectedOutletItemsSku,

          // preorderDate: moment(preorderDate).valueOf(),
          // preorderTime: moment(preorderTime).valueOf(),

          packageQuantity: packageQuantity,
          packagePrice: +parseFloat(packagePrice).toFixed(2),

          bdItems: bdItems,

          startDate: moment(startDate).valueOf(),
          startTime: moment(startTime).valueOf(),
          endDate: moment(endDate).valueOf(),
          endTime: moment(endTime).valueOf(),

          redemptionStartDate: moment(redemptionStartDate).valueOf(),
          redemptionStartTime: moment(redemptionStartTime).valueOf(),
          redemptionEndDate: moment(redemptionEndDate).valueOf(),
          redemptionEndTime: moment(redemptionEndTime).valueOf(),

          uniqueId: selectedPreorderPackageEdit.uniqueId,
        };

        console.log(body);

        ApiClient.POST(API.updatePreorderPackage, body).then((result) => {
          if (result && result.status === "success") {
            // Alert.alert(
            //   "Success",
            //   "Preorder package has been updated",
            //   [
            //     {
            //       text: "OK",
            //       onPress: () => {
            //         // props.navigation.goBack()
            //         setEditPurchase(false);
            //         setAddPurchase(false);
            //         setPurchaseOrder(true);
            //       },
            //     },
            //   ],
            //   { cancelable: false }
            // );
            let text = "Preorder package has been updated";
            if (window.confirm(text) == true) {
              setEditPurchase(false);
              setAddPurchase(false);
              setPurchaseOrder(true);
            } else {
            }
          } else {
            alert("Failed to update Preorder package");
          }
        });
      }
    } else {
      alert("Please fill in all the informations");
    }
  };

  const switchPreorderPackageStatus = (preorderPackage, status) => {
    var body = {
      isActive: status,

      uniqueId: preorderPackage.uniqueId,
    };

    console.log(body);

    ApiClient.POST(API.switchPreorderPackageStatus, body).then((result) => {
      if (result && result.status === "success") {
        Alert.alert(
          "Success",
          `Preorder package has been ${status ? "enabled" : "disabled"}`,
          [
            {
              text: "OK",
              onPress: () => {
                // props.navigation.goBack()
                // setEditPurchase(false);
                // setAddPurchase(false);
                // setPurchaseOrder(true);
              },
            },
          ],
          { cancelable: false }
        );
      } else {
        alert("Error", "Failed to create Preorder package");
      }
    });
  };

  const renderProducts = () => {
    return (
      <>
        {bdItems.map((item, index) => {
          return (
            <View
              style={{
                zIndex: 10001 + bdItems.length - index,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  //paddingBottom: 10,
                  marginBottom: 15,
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: 260,
                    height: 40,
                    justifyContent: "center",
                    //alignItems: 'flex-end',
                    alignSelf: "flex-end",
                  }}
                >
                  {/* <DropDownPicker
                    items={outletItems}
                    containerStyle={{ height: 40 }}
                    placeholder={"Choose categories"}
                    placeholderStyle={{ color: 'black' }}
                    style={{ backgroundColor: '#fafafa', width: 250 }}
                    itemStyle={{
                      justifyContent: 'flex-start', marginLeft: 5,
                    }}
                    searchable={true}
                    dropDownStyle={{
                      backgroundColor: '#fafafa',
                      width: 250,
                    }}
                    onChangeItem={(item) => {
                      // setState({
                      //   category: item.value
                      // });
                      // console.log(item);
                       setSelectedCategoryList(item)

                      // setBdProducts(bdProducts.map((bdProduct, i) => (i === index ? {
                      //   ...bdProduct,
                      //   productsName: item.length > 0 ? (item) : 0,
                      // } : bdProduct)))
                    }}
                    //defaultValue={item.productsName}
                    defaultValue={selectedCategoryList}
                  /> */}
                  {variationItemsDropdownList.find(
                    (variationItem) =>
                      variationItem.value === item.outletItemSku
                  ) ? (
                    <View style={{ zIndex: index - 1 }}>
                      <Picker
                        selectedValue={item.outletItemSku}
                        // style={{ }}
                        onValueChange={(variationItem) => {
                          setBdItems(
                            bdItems.map((bdItem, i) =>
                              i === index
                                ? {
                                    ...bdItem,
                                    outletItemSku: variationItem.value,
                                    outletItemId: variationItem.itemId,
                                  }
                                : bdItem
                            )
                          );
                        }}
                      >
                        {variationItemsDropdownList.map((value, index) => {
                          return (
                            <Picker.Item
                              key={index}
                              label={value.label}
                              value={value.value}
                            />
                          );
                        })}
                      </Picker>
                      {/* <DropDownPicker
                        // items={categoryOutlet.map((item) => ({
                        //   label: item.categoryName, //<= after hayden change you need to change it to item.name
                        //   value: item.categoryId,
                        // }))}
                        //items={outletItems}
                        globalTextStyle={{
                          fontSize: 14,
                        }}
                        containerStyle={{ height: 40 }}
                        arrowColor={"black"}
                        arrowSize={20}
                        arrowStyle={{ fontWeight: "bold" }}
                        labelStyle={{
                          fontFamily: "NunitoSans-Regular",
                          fontSize: 14,
                        }}
                        style={{
                          width: 250,
                          paddingVertical: 0,
                          backgroundColor: Colors.fieldtBgColor,
                          borderRadius: 10,
                          //zIndex: index ++ ? 1 : 0,
                          //elevation: 1000
                        }}
                        placeholderStyle={{ color: Colors.fieldtTxtColor }}
                        placeholder={"Select a Product"}
                        items={variationItemsDropdownList}
                        itemStyle={{
                          justifyContent: "flex-start",
                          //marginLeft: 5,
                          paddingHorizontal:
                            Dimensions.get("screen").width * 0.0079,
                          zIndex: 2,
                        }}
                        zIndex={10000 + bdItems.length - index}
                        // elevation={10000 + bdItems.length - index}
                        onChangeItem={(variationItem) => {
                          setBdItems(
                            bdItems.map((bdItem, i) =>
                              i === index
                                ? {
                                    ...bdItem,
                                    outletItemSku: variationItem.value,
                                    outletItemId: variationItem.itemId,
                                  }
                                : bdItem
                            )
                          );
                        }}
                        searchable={true}
                        searchableStyle={{
                          paddingHorizontal:
                            Dimensions.get("screen").width * 0.0079,
                        }}
                        defaultValue={item.outletItemSku}
                        dropDownMaxHeight={150}
                        dropDownStyle={{
                          width: 250,
                          height: 150,
                          backgroundColor: Colors.fieldtBgColor,
                          borderRadius: 10,
                          borderWidth: 1,
                          textAlign: "left",
                          zIndex: 2,
                        }}
                        // items={variationItemsDropdownList}
                        // containerStyle={{ height: 40 }}
                        // placeholder={"Choose items"}
                        // placeholderStyle={{ color: 'black' }}
                        // style={{ backgroundColor: '#fafafa', width: 250 }}
                        // itemStyle={{
                        //   justifyContent: 'flex-start',
                        // }}
                        // // defaultValue={category1}
                        // //defaultValue={selectedCategoryList}
                        // searchable={true}
                        // dropDownStyle={{
                        //   backgroundColor: '#fafafa',
                        //   width: 250,
                        // }}
                        // onChangeItem={(variationItem) => {
                        //   // setState({
                        //   //   category: item.value
                        //   // });
                        //   // console.log(item);
                        //   // setSelectedCategoryList(item)
                        //   setBdItems(bdItems.map((bdItem, i) => (i === index ? {
                        //     ...bdItem,
                        //     outletItemSku: variationItem.value,
                        //     outletItemId: variationItem.itemId,
                        //   } : bdItem)))
                        // }}
                        // defaultValue={item.outletItemSku}
                      /> */}
                    </View>
                  ) : (
                    <View style={{ zIndex: index - 1 }}>
                      <Picker
                        selectedValue={item.outletItemSku}
                        // style={{ }}
                        onValueChange={(variationItem) => {
                          setBdItems(
                            bdItems.map((bdItem, i) =>
                              i === index
                                ? {
                                    ...bdItem,
                                    outletItemSku: variationItem.value,
                                    outletItemId: variationItem.itemId,
                                  }
                                : bdItem
                            )
                          );
                        }}
                      >
                        {variationItemsDropdownList.map((value, index) => {
                          return (
                            <Picker.Item
                              key={index}
                              label={value.label}
                              value={value.value}
                            />
                          );
                        })}
                      </Picker>
                    </View>
                  )}
                </View>
                <View style={{ marginLeft: 10 }}>
                  <Text
                    style={{
                      fontSize: 14,
                      color: Colors.fieldtTxtColor,
                      fontWeight: "500",
                    }}
                  >
                    Quantity:
                  </Text>
                  <TextInput
                    style={{
                      backgroundColor: Colors.fieldtBgColor,
                      width: 80,
                      height: 40,
                      borderRadius: 5,
                      padding: 5,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      paddingLeft: 10,
                      fontSize: 14,
                    }}
                    placeholder={"Quantity"}
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    onChangeText={(text) => {
                      // setBdProducts(bdProducts.map((bdProduct, i) => (i === index ? {
                      //   ...bdProduct,
                      //   quantity: text.length > 0 ? (text) : 0,
                      // } : bdProduct)))

                      setBdItems(
                        bdItems.map((bdItem, i) =>
                          i === index
                            ? {
                                ...bdItem,
                                quantity: text.length > 0 ? parseInt(text) : 0,
                              }
                            : bdItem
                        )
                      );
                    }}
                    defaultValue={item.quantity.toString()}
                    keyboardType={"number-pad"}
                    // value={item.quantity}
                  />
                </View>
                {index > 0 ? (
                  <View
                    style={{
                      //height: 40,
                      //marginBottom: 15,
                      alignSelf: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        backgroundColor: Colors.whiteColor,
                      }}
                      onPress={() => {
                        setBdItems([
                          ...bdItems.slice(0, index),
                          ...bdItems.slice(index + 1),
                        ]);
                      }}
                    >
                      <View
                        style={{
                          marginLeft: 10,
                          padding: 8,
                        }}
                      >
                        <View style={{}}>
                          <Icon name="minus-circle" size={20} color="#eb3446" />
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <></>
                )}
                {index === 0 ? (
                  <View
                    style={{
                      //height: 40,
                      //marginBottom: 14.5,
                      alignSelf: "flex-end",
                    }}
                  >
                    <TouchableOpacity
                      style={{}}
                      onPress={() => {
                        setBdItems([
                          ...bdItems,
                          {
                            bdItemId: "",
                            outletItemSku: variationItemsDropdownList[0].value,
                            outletItemId: variationItemsDropdownList[0].itemId,
                            variation:
                              PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
                            isActive: true,
                            quantity: 0,
                          },
                        ]);
                      }}
                    >
                      <View style={{}}>
                        <View
                          style={{
                            marginLeft: 10,
                            padding: 8,
                          }}
                        >
                          <Icon
                            name="plus-circle"
                            size={20}
                            color={Colors.primaryColor}
                          />
                        </View>
                      </View>
                    </TouchableOpacity>
                  </View>
                ) : (
                  <></>
                )}
              </View>
            </View>
          );
        })}
      </>
    );
  };

  // function end

  return (
    // <View style={styles.container}>
    //   <View style={styles.sidebar}>
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View
        style={{
          // borderWidth: 1,
          // width: Dimensions.get("screen").width * 0.9,
          // height: Dimensions.get("screen").height * 0.9,
          flex: 0.8,
        }}
      >
        <SideBar
          navigation={navigation}
          selectedTab={2}
          expandInventory={true}
        />
      </View>
      <ScrollView style={{ flex: 9, height: windowHeight }}>
        <View
          style={[
            styles.content,
            {
              // shadowColor: "#000",
              // shadowOffset: {
              //   width: 0,
              //   height: 2,
              // },
              // shadowOpacity: 0.22,
              // shadowRadius: 3.22,
              // elevation: 3,
            },
          ]}
        >
          <View
            style={{
              flexDirection: "row",
              marginBottom: Platform.OS == "ios" ? 0 : 10,
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <View style={{ marginRight: 248 }}>
              <View style={{ flexDirection: "row", flex: 1 }}>
                {/* <TouchableOpacity style={[styles.submitText, {
                height: Styles.height * 0.05,
              }]} onPress={() => { importCSV() }}>
                <View style={{ flexDirection: 'row' }}>
                  <Icon name="download" size={20} color={Colors.primaryColor} />
                  <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                    Import
                  </Text>
                </View>
              </TouchableOpacity> */}
                {/* <TouchableOpacity style={[styles.submitText, {
                height: Styles.height * 0.05,
              }]} onPress={() => { exportFunc() }}>
                <View style={{ flexDirection: 'row' }}>
                  <Icon name="download" size={20} color={Colors.primaryColor} />
                  <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                    Download
                  </Text>
                </View>
              </TouchableOpacity> */}
              </View>
            </View>

            {/* <View
            style={[{
              // flex: 1,
              // alignContent: 'flex-end',
              // marginBottom: 10,
              // flexDirection: 'row',
              // marginRight: '-40%',
              // marginLeft: 310,
              // backgroundColor: 'red',
              // alignItems: 'flex-end',
              // right: '-50%',
              //width: '100%',
              height: 40,

            }, !isTablet ? {
              marginLeft: 0,
            } : {}]}>
            <View style={{
              width: 250,
              height: 40,
              backgroundColor: 'white',
              borderRadius: 10,
              // marginLeft: '53%',
              flexDirection: 'row',
              alignContent: 'center',
              alignItems: 'center',

              // marginRight: Styles.width * Styles.sideBarWidth,

              position: 'absolute',
              right: '35%',

              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 3,
            }}>
              <Icon name="search" size={18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />
              <TextInput
                editable={!loading}
                underlineColorAndroid={Colors.whiteColor}
                style={{
                  width: 250,
                  fontSize: 15,
                  fontFamily: 'NunitoSans-Regular',
                }}
                clearButtonMode="while-editing"
                placeholder=" Search"
                onChangeText={(text) => {
                  setSearch(text);
                }}
                value={search}
              />
            </View>
          </View> */}
          </View>

          {purchaseOrder ? (
            <View
              style={{
                height: Dimensions.get("window").height,
                //backgroundColor: 'red',
              }}
            >
              {/* <View> */}
              {/* <ScrollView style={{
              // backgroundColor: 'red',
              height: Dimensions.get('screen').height,
            }} contentContainerStyle={{
              paddingBottom: 100,
              // height: Dimensions.get('screen').height,
            }}> */}

              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  //paddingVertical: 15,
                  //marginBottom: 5,
                  width: windowWidth * 0.869,
                  alignSelf: "center",
                }}
              >
                <View
                  style={{
                    alignSelf: "flex-start",
                    // marginBottom: 10,
                  }}
                >
                  <View
                    style={{
                      //backgroundColor: '#ffffff',
                      justifyContent: "center",
                      //alignSelf: 'center',
                      // padding: 1,
                      marginLeft: 20,
                    }}
                  >
                    <View style={{ alignItems: "center" }}>
                      <Text
                        style={{ fontSize: 26, fontFamily: "NunitoSans-Bold" }}
                      >
                        {/* {stockList.length} */}
                        {preorderPackages.length}{" "}
                        {preorderPackages.length > 1
                          ? "Preorder Packages"
                          : "Preorder Package"}
                      </Text>
                      {/* <Text>PREORDER PACKAGES</Text> */}
                    </View>
                  </View>
                </View>

                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View
                    style={{
                      alignSelf: "flex-start",
                      marginRight: 10,
                      // marginBottom: 10,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        //width: 160,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginRight: 5,
                      }}
                      onPress={() => {
                        CommonStore.update((s) => {
                          s.selectedPreorderPackageEdit = null;
                        });

                        setPurchaseOrder(false);
                        setAddPurchase(true);
                      }}
                    >
                      <View
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <AntDesign
                          name="pluscircle"
                          size={20}
                          color={Colors.whiteColor}
                        />
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            marginLeft: 5,
                            fontSize: 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          {/* NEW PREORDER */}
                          PREORDER
                        </Text>
                      </View>
                    </TouchableOpacity>
                  </View>

                  <View
                    style={[
                      {
                        // flex: 1,
                        // alignContent: 'flex-end',
                        // marginBottom: 10,
                        // flexDirection: 'row',
                        // marginRight: '-40%',
                        // marginLeft: 310,
                        // backgroundColor: 'red',
                        // alignItems: 'flex-end',
                        // right: '-50%',
                        //width: '100%',
                        alignContent: "flex-end",
                        flexDirection: "column",
                        height: 40,
                      },
                    ]}
                  >
                    <View
                      style={{
                        width: 250,
                        height: 40,
                        backgroundColor: "white",
                        borderRadius: 5,
                        // marginLeft: '53%',
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",

                        // marginRight: Styles.width * Styles.sideBarWidth,

                        //position: 'absolute',
                        //right: '25%',

                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 3,
                        borderWidth: 1,
                        borderColor: "#E5E5E5",
                      }}
                    >
                      <Icon
                        name="search"
                        size={18}
                        color={Colors.primaryColor}
                        style={{ marginLeft: 15 }}
                      />
                      <TextInput
                        editable={!loading}
                        underlineColorAndroid={Colors.whiteColor}
                        style={{
                          width: 220,
                          fontSize: 15,
                          fontFamily: "NunitoSans-Regular",
                          paddingLeft: 5,
                          height: 45,
                        }}
                        clearButtonMode="while-editing"
                        placeholder=" Search"
                        placeholderTextColor={Platform.select({
                          ios: "#a9a9a9",
                        })}
                        onChangeText={(text) => {
                          setSearch(text);
                        }}
                        value={search}
                      />
                    </View>
                  </View>
                </View>
              </View>

              <View style={{ flexDirection: "column" }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    paddingVertical: 15,
                    marginBottom: 5,
                    width: windowWidth * 0.875,
                    marginHorizontal: 27,
                    //alignSelf: "center",
                    //backgroundColor: 'blue'
                  }}
                >
                  <TouchableOpacity
                    style={{
                      width: "23%",
                      height: 100,
                      backgroundColor: Colors.tabMint,
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 30,
                      paddingVertical: 15,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                    }}
                    onPress={() => {
                      setFilterPreorderPackages(
                        FILTER_PREORDER_PACKAGE.PACKAGES_SOLD
                      );
                    }}
                  >
                    <View
                      style={{
                        marginTop: -15,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.whiteColor,
                          fontSize: 28,
                        }}
                      >
                        {qtySold}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Regular",
                          color: Colors.whiteColor,
                          fontSize: 13,
                        }}
                      >
                        Sold Qty
                      </Text>
                    </View>
                    <View>
                      <Dish height={65} width={65} style={{ paddingLeft: 2 }} />
                    </View>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      width: "23%",
                      height: 100,
                      backgroundColor: Colors.tabGold,
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 30,
                      paddingVertical: 15,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                      marginLeft: 16,
                    }}
                    onPress={() => {
                      setFilterPreorderPackages(
                        FILTER_PREORDER_PACKAGE.PACKAGES_CLAIMED
                      );
                    }}
                  >
                    <View
                      style={{
                        marginTop: -15,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.whiteColor,
                          fontSize: 28,
                        }}
                      >
                        {qtyClaimed}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Regular",
                          color: Colors.whiteColor,
                          fontSize: 13,
                        }}
                      >
                        Claimed Qty
                      </Text>
                    </View>
                    <View>
                      <Hand height={65} width={65} style={{ paddingLeft: 2 }} />
                    </View>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      width: "23%",
                      height: 100,
                      backgroundColor: Colors.tabCyan,
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 30,
                      paddingVertical: 15,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                      marginLeft: 16,
                    }}
                    onPress={() => {
                      setFilterPreorderPackages(
                        FILTER_PREORDER_PACKAGE.PACKAGES_ACTIVE
                      );
                    }}
                  >
                    <View
                      style={{
                        marginTop: -15,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.whiteColor,
                          fontSize: 28,
                        }}
                      >
                        {qtyActive}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Regular",
                          color: Colors.whiteColor,
                          fontSize: 13,
                        }}
                      >
                        Active Qty
                      </Text>
                    </View>
                    <View>
                      <Coins
                        height={65}
                        width={65}
                        style={{ paddingLeft: 5 }}
                      />
                    </View>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      width: "23%",
                      height: 100,
                      backgroundColor: Colors.tabRed,
                      borderRadius: 10,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingHorizontal: 30,
                      paddingVertical: 15,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                      marginLeft: 16,
                    }}
                    onPress={() => {
                      setFilterPreorderPackages(
                        FILTER_PREORDER_PACKAGE.PACKAGES_EXPIRED
                      );
                    }}
                  >
                    <View style={{ marginTop: -15 }}>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.whiteColor,
                          fontSize: 28,
                        }}
                      >
                        {qtyExpired}
                      </Text>
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Regular",
                          color: Colors.whiteColor,
                          fontSize: 13,
                        }}
                      >
                        Expired Qty
                      </Text>
                    </View>
                    <View style={{}}>
                      <Ionicons
                        name="timer-outline"
                        color={"#F7F7F7"}
                        size={65}
                        style={{ opacity: 0.6, paddingLeft: 2 }}
                      />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 10,
                  width: windowWidth * 0.8575,
                  minHeight: Dimensions.get("screen").height * 0.01,
                  marginTop: 30,
                  marginHorizontal: 45,
                  marginBottom: 30,
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}
              >
                {filterPreorderPackages ===
                FILTER_PREORDER_PACKAGE.ALL_PACKAGES ? null : (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 5,
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{ fontFamily: "NunitoSans-Bold", fontSize: 18 }}
                    >
                      {filterPreorderPackages ===
                      FILTER_PREORDER_PACKAGE.PACKAGES_SOLD
                        ? "Packages Sold"
                        : filterPreorderPackages ===
                          FILTER_PREORDER_PACKAGE.PACKAGES_CLAIMED
                        ? "Packages Claimed"
                        : filterPreorderPackages ===
                          FILTER_PREORDER_PACKAGE.PACKAGES_ACTIVE
                        ? "Packages Active"
                        : filterPreorderPackages ===
                          FILTER_PREORDER_PACKAGE.PACKAGES_EXPIRED
                        ? "Packages Expired"
                        : null}
                    </Text>
                    <TouchableOpacity
                      style={{
                        //marginTop: 10,
                        marginLeft: 10,
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: 120,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      onPress={() => {
                        setFilterPreorderPackages(
                          FILTER_PREORDER_PACKAGE.ALL_PACKAGES
                        );
                      }}
                    >
                      <AntDesign
                        name="arrowleft"
                        size={20}
                        color={Colors.whiteColor}
                      />
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          marginLeft: 5,
                          fontSize: 16,
                          fontFamily: "NunitoSans-Bold",
                          marginBottom: Platform.OS === "ios" ? 0 : 2,
                        }}
                      >
                        Summary
                      </Text>
                    </TouchableOpacity>
                  </View>
                )}

                <View
                  style={{
                    backgroundColor: "#ffffff",
                    flexDirection: "row",
                    paddingVertical: 20,
                    paddingLeft: 15,
                    paddingRight: 10,
                    marginTop: 0,
                    borderBottomWidth: StyleSheet.hairlineWidth,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                >
                  <Text
                    style={{
                      flex: 1,
                      alignSelf: "center",
                      color: Colors.blackColor,
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 14,
                    }}
                  >
                    {/* Preorder Title */}
                    Title
                  </Text>
                  {/* <Text style={{ width: '11%', alignSelf: 'center', color: '#969696' }}>
                    Release Date
                  </Text>
                  <Text style={{ width: '11%', alignSelf: 'center', color: '#969696' }}>
                    Release Time
                  </Text> */}
                  <Text
                    style={{
                      flex: 1,
                      alignSelf: "flex-start",
                      color: Colors.blackColor,
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 14,
                    }}
                  >
                    Promotion Period
                  </Text>
                  <Text
                    style={{
                      flex: 1,
                      alignSelf: "flex-start",
                      color: Colors.blackColor,
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 14,
                    }}
                  >
                    Redemption Period
                  </Text>
                  <Text
                    style={{
                      flex: 1,
                      alignSelf: "flex-start",
                      color: Colors.blackColor,
                      fontFamily: "NunitoSans-Bold",
                      fontSize: 14,
                    }}
                  >
                    Status
                  </Text>
                  <Text style={{ flex: 0.5 }}></Text>
                </View>
                <ScrollView
                  style={{
                    height: Dimensions.get("screen").height - 280,
                  }}
                  contentContainerStyle={{
                    paddingBottom: 100,
                  }}
                >
                  <FlatList
                    data={preorderPackages.filter((item) => {
                      var relatedOrders = userOrdersAllStatus.filter(
                        (order) => order.preorderPackageId === item.uniqueId // [{}, {uniqueId, ...}].uniqueId is undefined
                      );

                      if (search !== "") {
                        return item.name
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      } else if (
                        filterPreorderPackages ===
                        FILTER_PREORDER_PACKAGE.PACKAGES_SOLD
                      ) {
                        for (var i = 0; i < relatedOrders.length; i++) {
                          if (
                            relatedOrders[i].paymentDetails &&
                            relatedOrders[i].paymentDetails.channel
                          ) {
                            return true;
                          }
                        }
                      } else if (
                        filterPreorderPackages ===
                        FILTER_PREORDER_PACKAGE.PACKAGES_CLAIMED
                      ) {
                        for (var i = 0; i < relatedOrders.length; i++) {
                          if (relatedOrders[i].completedDate) {
                            return true;
                          }
                        }
                      } else if (
                        filterPreorderPackages ===
                        FILTER_PREORDER_PACKAGE.PACKAGES_ACTIVE
                      ) {
                        for (var i = 0; i < relatedOrders.length; i++) {
                          if (relatedOrders[i].completedDate) {
                            return true;
                          } else {
                            // either active or expired

                            if (item.redemptionEndDate) {
                              if (
                                moment(item.redemptionEndDate).isAfter(moment())
                              ) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return false;
                            }
                          }
                        }
                      } else if (
                        filterPreorderPackages ===
                        FILTER_PREORDER_PACKAGE.PACKAGES_EXPIRED
                      ) {
                        for (var i = 0; i < relatedOrders.length; i++) {
                          if (relatedOrders[i].completedDate) {
                            return false;
                          } else {
                            // either active or expired

                            if (item.redemptionEndDate) {
                              if (
                                moment(item.redemptionEndDate).isAfter(moment())
                              ) {
                                return false;
                              } else {
                                return true;
                              }
                            } else {
                              return true;
                            }
                          }
                        }
                      } else {
                        return true;
                      }
                    })}
                    /* extraData={preorderPackages.filter((item) => {
                    if (search !== '') {
                      return item.name
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    } else {
                      return true;
                    }
                  })} */
                    renderItem={renderOrderItem}
                    keyExtractor={(item, index) => String(index)}
                  />
                  {}
                </ScrollView>
                {/* <View
                  style={{
                    flexDirection: 'row',
                    backgroundColor: '#ffffff',
                    justifyContent: 'center',
                    padding: 3,
                    alignSelf: 'center',
                  }}>
                  <View style={{ alignItems: 'center', }}>
                    <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
                      {preorderPackages.length}
                    </Text>
                    <Text>PREORDER PACKAGES</Text>
                  </View>
                </View> */}
              </View>
              {/* </View> */}
            </View>
          ) : null}

          {addPurchase ? (
            <View
              style={{
                // paddingBottom: 30
                height: Dimensions.get("screen").height * 0.7,
              }}
            >
              <View>
                <Modal
                  style={{ flex: 1 }}
                  visible={visible}
                  supportedOrientations={["portrait", "landscape"]}
                  transparent={true}
                  animationType="slide"
                >
                  <View
                    style={{
                      backgroundColor: "rgba(0,0,0,0.5)",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: Dimensions.get("window").height,
                    }}
                  >
                    <View style={styles.confirmBox}>
                      <TouchableOpacity
                        onPress={() => {
                          setState({ visible: false });
                        }}
                      >
                        <View
                          style={{
                            alignSelf: "flex-end",
                            padding: 16,
                          }}
                        >
                          {/* <Close name="closecircle" size={28} /> */}
                          <AntDesign
                            name="closecircle"
                            size={25}
                            color={Colors.fieldtTxtColor}
                          />
                        </View>
                      </TouchableOpacity>
                      <View>
                        <Text
                          style={{
                            textAlign: "center",
                            fontWeight: "700",
                            fontSize: 28,
                          }}
                        >
                          Purchase Order
                        </Text>
                      </View>
                      <View style={{ marginTop: 20 }}>
                        <Text
                          style={{
                            textAlign: "center",
                            color: Colors.descriptionColor,
                            fontSize: 25,
                          }}
                        >
                          Fill in the email information
                        </Text>
                      </View>
                      <View
                        style={{
                          backgroundColor: "white",
                          alignSelf: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Text style={{ fontSize: 20, marginTop: 70 }}>
                          Email:
                        </Text>
                        <View
                          style={{
                            marginTop: 60,
                            backgroundColor: "#f7f5f5",
                            marginLeft: 10,
                          }}
                        >
                          <TextInput
                            editable={!loading}
                            underlineColorAndroid={Colors.fieldtBgColor}
                            clearButtonMode="while-editing"
                            style={styles.textCapacity}
                            placeholder="myburgerlabmytown@gmail.com"
                            placeholderTextColor={Platform.select({
                              ios: "#a9a9a9",
                            })}
                            onChangeText={(text) => {
                              setState({ Email: text });
                            }}
                            value={Email}
                          />
                        </View>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          alignSelf: "center",
                          marginTop: 20,
                          justifyContent: "center",
                          alignItems: "center",
                          width: "50%",
                          alignContent: "center",
                          zIndex: 6000,
                        }}
                      ></View>
                      <View
                        style={{
                          alignSelf: "center",
                          marginTop: 20,
                          justifyContent: "center",
                          alignItems: "center",
                          width: 250,
                          height: 40,
                          alignContent: "center",
                          flexDirection: "row",
                          marginTop: 40,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => {
                            // email(), setState({ visible: false });
                          }}
                          style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: "60%",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            borderRadius: 10,
                            height: 60,
                          }}
                        >
                          <Text
                            style={{ fontSize: 28, color: Colors.primaryColor }}
                          >
                            Send
                          </Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          onPress={() => {
                            setState({ visible: false });
                          }}
                          style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: "60%",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            borderRadius: 10,
                            height: 60,
                            marginLeft: 30,
                          }}
                        >
                          <Text
                            style={{ fontSize: 28, color: Colors.primaryColor }}
                          >
                            No
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                  </View>
                </Modal>

                {/* <DateTimePickerModal
                isVisible={showPreorderDatePicker}
                mode={'date'}
                onConfirm={(text) => {
                  setPreorderDate(moment(text));

                  setShowPreorderDatePicker(false);
                }}
                onCancel={() => {
                  setShowPreorderDatePicker(false);
                }}
              />

              <DateTimePickerModal
                isVisible={showPreorderTimePicker}
                mode={'time'}
                onConfirm={(text) => {
                  setPreorderTime(moment(text));

                  setShowPreorderTimePicker(false);
                }}
                onCancel={() => {
                  setShowPreorderTimePicker(false);
                }}
              /> */}

                {/* *************************** Test Start date and Time ****************************/}
                {/* <DateTimePickerModal
                  isVisible={showStartDatePicker}
                  supportedOrientations={["portrait", "landscape"]}
                  mode={"date"}
                  onConfirm={(text) => {
                    setStartDate(moment(text).local());

                    setShowStartDatePicker(false);
                  }}
                  onCancel={() => {
                    setShowStartDatePicker(false);
                  }}
                /> */}
                <Modal
                  style={{ flex: 1 }}
                  visible={showStartTimePicker}
                  transparent={true}
                  animationType="slide"
                >
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: Colors.modalBgColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 12,
                        padding: Dimensions.get("screen").width * 0.05,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TimeKeeper
                        time={moment(startTime).format("hh:mm A")}
                        onChange={(dt) => {
                          // let hour = "";
                          // let minute = "";
                          // if (dt.hour < 10) {
                          //   hour = "0" + dt.hour;
                          // } else {
                          //   hour = dt.hour;
                          // }
                          // if (dt.minute < 10) {
                          //   minute = "0" + dt.minute;
                          // } else {
                          //   minute = dt.minute;
                          // }
                          setTempStartTime(dt.formatted12);
                        }}
                        onDoneClick={() => {
                          setStartTime(moment(tempStartTime, 'hh:mm A'));
                          setShowStartTimePicker(false)
                        }}
                        switchToMinuteOnHourSelect
                      />
                    </View>
                  </View>
                </Modal>
                {/* <DateTimePickerModal
                  isVisible={showStartTimePicker}
                  supportedOrientations={["portrait", "landscape"]}
                  mode={"time"}
                  onConfirm={(text) => {
                    setStartTime(moment(text).local());

                    setShowStartTimePicker(false);
                  }}
                  onCancel={() => {
                    setShowStartTimePicker(false);
                  }}
                /> */}

                {/* *************************** Test End date and Time ****************************/}
                {/* <DateTimePickerModal
                  isVisible={showEndDatePicker}
                  supportedOrientations={["portrait", "landscape"]}
                  mode={"date"}
                  onConfirm={(text) => {
                    setEndDate(moment(text));

                    setShowEndDatePicker(false);
                  }}
                  onCancel={() => {
                    setShowEndDatePicker(false);
                  }}
                /> */}
                <Modal
                  style={{ flex: 1 }}
                  visible={showEndTimePicker}
                  transparent={true}
                  animationType="slide"
                >
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: Colors.modalBgColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 12,
                        padding: Dimensions.get("screen").width * 0.05,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TimeKeeper
                        time={moment(endTime).format("hh:mm A")}
                        onChange={(dt) => {
                          // let hour = "";
                          // let minute = "";
                          // if (dt.hour < 10) {
                          //   hour = "0" + dt.hour;
                          // } else {
                          //   hour = dt.hour;
                          // }
                          // if (dt.minute < 10) {
                          //   minute = "0" + dt.minute;
                          // } else {
                          //   minute = dt.minute;
                          // }
                          setTempEndTime(dt.formatted12);
                        }}
                        onDoneClick={() => {
                          setEndTime(moment(tempEndTime, 'hh:mm A'));
                          setShowEndTimePicker(false)
                        }}
                        switchToMinuteOnHourSelect
                      />
                    </View>
                  </View>
                </Modal>
                {/* <DateTimePickerModal
                  isVisible={showEndTimePicker}
                  supportedOrientations={["portrait", "landscape"]}
                  mode={"time"}
                  onConfirm={(text) => {
                    setEndTime(moment(text));

                    setShowEndTimePicker(false);
                  }}
                  onCancel={() => {
                    setShowEndTimePicker(false);
                  }}
                /> */}

                {/* /////////////////////////////////////////////////////////// */}

                {/* <DateTimePickerModal
                  isVisible={showRedemptionStartDatePicker}
                  supportedOrientations={["portrait", "landscape"]}
                  mode={"date"}
                  onConfirm={(text) => {
                    setRedemptionStartDate(moment(text).local());
                  }}
                  onCancel={() => {
                    setShowRedemptionStartDatePicker(false);
                  }}
                /> */}

                <Modal
                  style={{ flex: 1 }}
                  visible={showRedemptionStartTimePicker}
                  transparent={true}
                  animationType="slide"
                >
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: Colors.modalBgColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 12,
                        padding: Dimensions.get("screen").width * 0.05,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TimeKeeper
                        time={moment(redemptionStartTime).format("hh:mm A")}
                        onChange={(dt) => {
                          // let hour = "";
                          // let minute = "";
                          // if (dt.hour < 10) {
                          //   hour = "0" + dt.hour;
                          // } else {
                          //   hour = dt.hour;
                          // }
                          // if (dt.minute < 10) {
                          //   minute = "0" + dt.minute;
                          // } else {
                          //   minute = dt.minute;
                          // }
                          setTempRedemptionStartTime(dt.formatted12);
                        }}
                        onDoneClick={() => {
                          setRedemptionStartTime(moment(tempRedemptionStartTime, 'hh:mm A'));
                          setShowRedemptionStartTimePicker(false);
                          console.log(redemptionStartTime);
                        }}
                        switchToMinuteOnHourSelect
                      />
                    </View>
                  </View>
                </Modal>
                {/* <DateTimePickerModal
                  isVisible={showRedemptionStartTimePicker}
                  supportedOrientations={["portrait", "landscape"]}
                  mode={"time"}
                  onConfirm={(text) => {
                    setRedemptionStartTime(moment(text).local());

                    setShowRedemptionStartTimePicker(false);
                  }}
                  onCancel={() => {
                    setShowRedemptionStartTimePicker(false);
                  }}
                /> */}

                {/* *************************** Test End date and Time ****************************/}
                {/* <DateTimePickerModal
                  isVisible={showRedemptionEndDatePicker}
                  supportedOrientations={["portrait", "landscape"]}
                  mode={"date"}
                  onConfirm={(text) => {
                    setRedemptionEndDate(moment(text));

                    setShowRedemptionEndDatePicker(false);
                  }}
                  onCancel={() => {
                    setShowRedemptionEndDatePicker(false);
                  }}
                /> */}
                <Modal
                  style={{ flex: 1 }}
                  visible={showRedemptionEndTimePicker}
                  transparent={true}
                  animationType="slide"
                >
                  <View
                    style={{
                      flex: 1,
                      backgroundColor: Colors.modalBgColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 12,
                        padding: Dimensions.get("screen").width * 0.05,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TimeKeeper
                        time={moment(redemptionEndTime).format("hh:mm A")}
                        onChange={(dt) => {
                          // let hour = "";
                          // let minute = "";
                          // if (dt.hour < 10) {
                          //   hour = "0" + dt.hour;
                          // } else {
                          //   hour = dt.hour;
                          // }
                          // if (dt.minute < 10) {
                          //   minute = "0" + dt.minute;
                          // } else {
                          //   minute = dt.minute;
                          // }
                          setTempRedemptionEndTime(dt.formatted12);
                        }}
                        onDoneClick={() =>{
                          setRedemptionEndTime(moment(tempRedemptionEndTime, 'hh:mm A'));
                          setShowRedemptionEndTimePicker(false)
                        }}
                        switchToMinuteOnHourSelect
                      />
                    </View>
                  </View>
                </Modal>

                {/* <DateTimePickerModal
                  isVisible={showRedemptionEndTimePicker}
                  supportedOrientations={["portrait", "landscape"]}
                  mode={"time"}
                  onConfirm={(text) => {
                    setRedemptionEndTime(moment(text));

                    setShowRedemptionEndTimePicker(false);
                  }}
                  onCancel={() => {
                    setShowRedemptionEndTimePicker(false);
                  }}
                /> */}
                {/* *************************** Finished ****************************/}

                {/* <TouchableOpacity style={{ marginBottom: 20, flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginTop: 20 }}
                onPress={() => {
                  // setState({
                  //   lowStockAlert: false,
                  //   purchaseOrder: true,
                  //   stockTransfer: false,
                  //   stockTake: false,
                  //   addPurchase: false,
                  //   editPurchase: false,
                  //   addStockTransfer: false,
                  //   addStockTake: false,
                  // })
                  setPurchaseOrder(true);
                  setAddPurchase(false);
                }}>
                <Icon name="chevron-left" size={30} color={Colors.primaryColor} />
                <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 17, color: Colors.primaryColor }}> Back </Text>
              </TouchableOpacity> */}

                <View
                  style={{
                    flexDirection: "row",
                    //justifyContent: 'space-between',
                    width: windowWidth * 0.877,
                    //marginTop: -25, marginBottom: -2,
                    //marginLeft: 20,
                    alignSelf: "center",
                    //marginHorizontal: 30,
                    //marginBottom: 30,
                    // paddingHorizontal: 15,
                    // paddingTop: 10,
                    //backgroundColor: 'blue',
                    marginRight: 20,
                  }}
                >
                  <TouchableOpacity
                    style={{
                      marginBottom: 0,
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      marginTop: 5,
                      paddingLeft: "1.2%",
                    }}
                    onPress={() => {
                      setPurchaseOrder(true);
                      setAddPurchase(false);
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <View style={{ justifyContent: "center" }}>
                        <Icon
                          name="chevron-left"
                          size={30}
                          style={{
                            color: Colors.primaryColor,
                            alignSelf: "center",
                          }}
                        />
                      </View>
                      <Text
                        style={{
                          fontFamily: "Nunitosans-Bold",
                          color: Colors.primaryColor,
                          fontSize: 17,
                          marginBottom: Platform.OS === "ios" ? 0 : 1,
                        }}
                      >
                        Back
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>

                <ScrollView
                  nestedScrollEnabled={true}
                  showsVerticalScrollIndicator={false}
                  keyboardShouldPersistTaps="handled"
                >
                  <View
                    style={{
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      width: windowWidth * 0.8575,
                      minHeight: Dimensions.get("screen").height * 0.7,
                      marginTop: 30,
                      marginHorizontal: 45,
                      marginBottom: 30,
                      borderRadius: 5,
                      shadowOpacity: 0,
                      shadowColor: "#000",
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                    }}
                  >
                    <ScrollView
                      nestedScrollEnabled={true}
                      //keyboardShouldPersistTaps="handled"
                      // showsVerticalScrollIndicator={false}
                    >
                      {/* <View style={{ width: 140, marginRight: 10, marginTop: 10, alignSelf: "flex-end" }}>
                  <DropDownPicker
                    items={[
                      {
                        label: '🖨️  Print P.O',
                        value: 'Print P.O',
                      },
                      {
                        label: '📧  Email P.O',
                        value: 'Chicken',
                      },
                      {
                        label: '📤  Export Labels',
                        value: 'Export Labels',
                      },
                      {
                        label: '❌  Cancel P.O',
                        value: 'Cancel P.O',
                      },
                      {
                        label: '🗑️  Delete P.O',
                        value: 'Delete P.O',
                      },
                    ]}
                    defaultValue={choice2}
                    placeholder=""
                    containerStyle={{ height: 30 }}
                    style={{ backgroundColor: '#FAFAFA' }}
                    itemStyle={{
                      justifyContent: 'flex-start',
                    }}
                    dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                    onChangeItem={(item) =>
                      setState({
                        choice2: item.value,
                      })
                    }
                  />
                </View> */}

                      <View
                        style={{
                          alignSelf: "flex-end",
                          position: "absolute",
                          marginTop: 30,
                          zIndex: 10000,
                        }}
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            flexDirection: "row",
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: "#4E9F7D",
                            borderRadius: 5,
                            width: 120,
                            paddingHorizontal: 10,
                            height: 40,
                            alignItems: "center",
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                            marginRight: 20,
                          }}
                        >
                          <TouchableOpacity
                            style={{
                              alignItems: "center",
                              flexDirection: "row",
                              justifyContent: "center",
                              width: 120,
                              height: 40,
                            }}
                            onPress={() => {
                              createPreorderPackage();
                            }}
                          >
                            <Text
                              style={{
                                color: Colors.whiteColor,
                                //marginLeft: 5,
                                fontSize: 16,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              SAVE
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View>
                        <Text
                          style={{
                            alignSelf: "center",
                            marginTop: 30,
                            fontSize: 35,
                            fontWeight: "bold",
                          }}
                        >
                          {selectedPreorderPackageEdit === null
                            ? "New Package"
                            : selectedPreorderPackageEdit.name}
                        </Text>
                        {/* {selectedPreorderPackageEdit.name !==  null ? <Text>New Package</Text>:  } */}
                        {/* <Text style={{ alignSelf: "center", fontSize: 16, color: '#adadad' }}>Fill in the preorder package information</Text> */}
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          marginTop: 40,
                          marginBottom: 10,
                          width: "90%",
                          alignSelf: "center",
                          zIndex: -1,
                          marginLeft: Styles.width * Styles.sideBarWidth * 0.5,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "20.5%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 16,
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            Preorder Title
                          </Text>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "flex-start",
                            // justifyContent: 'space-around',
                            width: "80%",
                          }}
                        >
                          <TextInput
                            editable={true}
                            //underlineColorAndroid={Colors.fieldtBgColor}
                            style={{
                              backgroundColor: Colors.fieldtBgColor,
                              width: "30%",
                              height: 40,
                              borderRadius: 5,
                              padding: 5,
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                              paddingLeft: 10,
                              fontSize: 14,
                            }}
                            // placeholder={'50'}
                            placeholder={"Christmas Special Set"}
                            placeholderTextColor={Platform.select({
                              ios: "#a9a9a9",
                            })}
                            keyboardType={"default"}
                            onChangeText={(text) => {
                              setCategoryName(text);
                            }}
                            value={categoryName}
                          />
                        </View>
                      </View>

                      {/* <View style={{ flexDirection: "row", justifyContent: 'space-evenly', marginTop: 30, marginBottom: 40, width: '90%', alignSelf: 'center', zIndex: -1, marginLeft: Styles.width * Styles.sideBarWidth * 0.5 }}>
                    <View style={{
                      flexDirection: "row",
                      alignItems: 'center',
                      // justifyContent: 'space-around',
                      width: '45%',
                    }}>
                      <Text style={{ fontSize: 16, width: '45%', textAlign: 'left' }}>Preorder Date</Text>

                      <TouchableOpacity onPress={() => {
                        setShowPreorderDatePicker(true)
                      }} style={{
                        //marginHorizontal: 4,
                        flexDirection: 'row',
                        alignItems: 'center',
                        //marginLeft: 5,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        //marginLeft:'6%', 
                        height: 40,
                        width: 125,
                        //marginTop: 10,
                        justifyContent: 'space-between'
                      }}>
                        <Text style={{
                          marginLeft: 15,
                          color: '#B6B6B6',
                          fontSize: 14
                        }}
                        >
                          {moment(preorderDate).format('DD/MM/YYYY')}
                        </Text>

                        <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} />
                      </TouchableOpacity>
                    </View>

                    <View style={{ flexDirection: "row", flex: 1, alignItems: 'center', width: '50%', zIndex: -1, }}>
                      <Text style={{ fontSize: 16, width: '35%', textAlign: 'left' }}>Preorder Time</Text>

                      <TouchableOpacity onPress={() => {
                        setShowPreorderTimePicker(true)
                      }} style={{
                        //marginHorizontal: 4,
                        flexDirection: 'row',
                        alignItems: 'center',
                        //marginLeft: 5,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        //marginLeft:'6%', 
                        height: 40,
                        width: 125,
                        //marginTop: 10,
                        justifyContent: 'space-between',
                        zIndex: -1,
                      }}>
                        <Text style={{
                          marginLeft: 15,
                          color: '#B6B6B6',
                          fontSize: 14
                        }}
                        >
                          {moment(preorderTime).format('hh:mm a')}
                        </Text>

                        <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} />
                      </TouchableOpacity>
                    </View>
                  </View> */}

                      {/* *************************** Start Test *************************************/}
                      {/* *************************** Start Date and End Date ****************************/}
                      {/* </View> */}
                      {/* <View style={{ flexDirection: "row", justifyContent: 'space-evenly', marginTop: 20, marginBottom: 40, width: '90%', alignSelf: 'center', zIndex: -1, marginLeft: Styles.width * Styles.sideBarWidth * 0.5 }}>
                    <View style={{
                      flexDirection: "row",
                      alignItems: 'center',
                      // justifyContent: 'space-around',
                      width: '45%',
                    }}>
                      <Text style={{ fontSize: 16, width: '45%', textAlign: 'left' }}>Start Date</Text>

                      <TouchableOpacity onPress={() => {
                        setShowStartDatePicker(true)
                      }} style={{
                        //marginHorizontal: 4,
                        flexDirection: 'row',
                        alignItems: 'center',
                        //marginLeft: 5,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        //marginLeft:'6%', 
                        height: 40,
                        width: 125,
                        //marginTop: 10,
                        justifyContent: 'space-between',
                        paddingLeft: 5,
                      }}>
                        <Text style={{
                          //marginLeft: 15,
                          color: 'black',
                          fontSize: 14
                        }}
                        >
                          {moment(startDate).format('DD/MM/YYYY')}
                        </Text>

                        <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} />
                      </TouchableOpacity>
                    </View> */}

                      {/* *************************** TouchableOpacity can't function ****************************/}
                      {/* <View style={{ flexDirection: "row", flex: 1, alignItems: 'center', width: '50%', zIndex: -1, }}>
                      <Text style={{ fontSize: 16, width: '35%', textAlign: 'left' }}>End Date</Text>
                        <TouchableOpacity onPress={() => {
                          setShowEndDatePicker(true)
                        }} style={{
                          //marginHorizontal: 4,
                          flexDirection: 'row',
                          alignItems: 'center',
                          //marginLeft: 5,
                          borderRadius: 5,
                          borderWidth: 1,
                          borderColor: '#E5E5E5',
                          //marginLeft:'6%', 
                          height: 40,
                          width: 125,
                          //marginTop: 10,
                          justifyContent: 'space-between',
                          paddingLeft: 5,
                        }}>
                          <Text style={{
                            //marginLeft: 15,
                            color: 'black',
                            fontSize: 14
                          }}
                          >
                            {moment(endDate).format('DD/MM/YYYY')}
                          </Text>

                          <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} />
                        </TouchableOpacity>
                    </View>
                  </View> */}

                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: 20,
                          width: "90%",
                          alignSelf: "center",
                          zIndex: -1,
                          marginLeft: Styles.width * Styles.sideBarWidth * 0.5,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            // justifyContent: 'space-around',
                            width: "45%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 16,
                              width: Platform.OS === "ios" ? "80%" : "80%",
                              textAlign: "left",
                            }}
                          >
                            Purchase Start Date & End Date
                          </Text>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            flex: 1,
                            alignItems: "center",
                            width: "50%",
                            zIndex: -1,
                            // marginTop: -10,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 16,
                              width: Platform.OS === "ios" ? "80%" : "80%",
                              textAlign: "left",
                            }}
                          >
                            Purchase Start Time & End Time
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginBottom: 20,
                          width: "90%",
                          alignSelf: "center",
                          zIndex: 2,
                          marginLeft: Styles.width * Styles.sideBarWidth * 0.5,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            // justifyContent: 'space-around',
                            width: "45%",
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              setShowStartDatePicker(true);
                            }}
                            style={{
                              // height: 50,
                              height: 40,
                              paddingHorizontal: 20,
                              backgroundColor: Colors.fieldtBgColor,
                              //marginBottom: 20,
                              width: 160,
                              //marginHorizontal: 10,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              alignContent: "center",
                              borderColor: "#E5E5E5",
                              borderWidth: 1,
                              fontFamily: "NunitoSans-Regular",
                              fontSize: 14,
                              borderRadius: 12,
                            }}
                          >
                            <GCalendar
                              width={20}
                              height={20}
                              //style={{marginRight: 5}}
                            />
                            <DatePicker
                              selected={moment(startDate).toDate()}
                              onChange={(date) => {
                                setStartDate(moment(date));
                              }}
                              maxDate={moment(endDate).toDate()}
                            />
                            {/* <Text
                              style={{
                                //marginLeft: 15,
                                color: "black",
                                fontSize: 14,
                                textAlign: "center",
                              }}
                            >
                              {moment(startDate).format("DD MMM YYYY")}
                            </Text> */}

                            {/* <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} /> */}
                          </TouchableOpacity>

                          <Text
                            style={{
                              fontSize: 16,
                              marginLeft: 20,
                              marginRight: 20,
                              alignItems: "center",
                            }}
                          >
                            to
                          </Text>

                          <TouchableOpacity
                            onPress={() => {
                              setShowEndDatePicker(true);
                            }}
                            style={{
                              // height: 50,
                              height: 40,
                              paddingHorizontal: 20,
                              backgroundColor: Colors.fieldtBgColor,
                              //marginBottom: 20,
                              width: 160,
                              //marginHorizontal: 10,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              alignContent: "center",
                              borderColor: "#E5E5E5",
                              borderWidth: 1,
                              fontFamily: "NunitoSans-Regular",
                              fontSize: 14,
                              borderRadius: 12,
                            }}
                          >
                            <GCalendar
                              width={20}
                              height={20}
                              //style={{marginRight: 5}}
                            />
                            <DatePicker
                              selected={moment(endDate).toDate()}
                              onChange={(date) => {
                                setEndDate(moment(date));
                              }}
                              minDate={moment(startDate).toDate()}
                            />

                            {/* <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} /> */}
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            flex: 1,
                            alignItems: "center",
                            width: "50%",
                            marginTop: 20,
                            marginBottom: 10,
                            zIndex: -1,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              // justifyContent: 'space-around',
                              width: "50%",
                              marginTop: -12,
                            }}
                          >
                            <TouchableOpacity
                              onPress={() => {
                                setShowStartTimePicker(true);
                              }}
                              style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                alignContent: "center",
                                borderColor: "#E5E5E5",
                                borderWidth: 1,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: 14,
                                borderRadius: 12,
                              }}
                            >
                              <Time />

                              <Text
                                style={{
                                  //marginLeft: 15,
                                  color: "black",
                                  fontSize: 14,
                                }}
                              >
                                {moment(startTime).format("hh:mm A")}
                              </Text>
                            </TouchableOpacity>

                            <Text
                              style={{
                                fontSize: 16,
                                marginLeft: 20,
                                marginRight: 20,
                                alignItems: "center",
                                marginTop: 3,
                              }}
                            >
                              to
                            </Text>

                            <TouchableOpacity
                              onPress={() => {
                                setShowEndTimePicker(true);
                              }}
                              style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                alignContent: "center",
                                borderColor: "#E5E5E5",
                                borderWidth: 1,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: 14,
                                borderRadius: 12,
                              }}
                            >
                              <Time />

                              <Text
                                style={{
                                  //marginLeft: 15,
                                  color: "black",
                                  fontSize: 14,
                                }}
                              >
                                {moment(endTime).format("hh:mm A")}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>

                      {/* *************************** TouchableOpacity can't function ****************************/}
                      {/* <View style={{ flexDirection: "row", justifyContent: 'space-evenly', marginTop: 20, marginBottom: 40, width: '90%', alignSelf: 'center', zIndex: -1, marginLeft: Styles.width * Styles.sideBarWidth * 0.5 }}> */}
                      {/* <View style={{
                      flexDirection: "row",
                      alignItems: 'center',
                      // justifyContent: 'space-around',
                      width: '45%',
                    }}>
                      <Text style={{ fontSize: 16, width: '45%', textAlign: 'left' }}>Start Time</Text>
                      <TouchableOpacity onPress={() => {
                        setShowStartTimePicker(true)
                      }} style={{
                        //marginHorizontal: 4,
                        flexDirection: 'row',
                        alignItems: 'center',
                        //marginLeft: 5,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        //marginLeft:'6%', 
                        height: 40,
                        width: 125,
                        //marginTop: 10,
                        justifyContent: 'space-between',
                        paddingLeft: 5,
                      }}>
                        <Text style={{
                          //marginLeft: 15,
                          color: 'black',
                          fontSize: 14
                        }}
                        >
                          {moment(startTime).format('hh:mm a')}
                        </Text>

                        <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} />
                      </TouchableOpacity>
                      
                    </View> */}

                      {/* <View style={{ flexDirection: "row", flex: 1, alignItems: 'center', width: '50%', zIndex: -1, }}>
                      <Text style={{ fontSize: 16, width: '35%', textAlign: 'left' }}>End Time</Text>

                      <TouchableOpacity onPress={() => {
                        setShowEndTimePicker(true)
                      }} style={{
                        //marginHorizontal: 4,
                        flexDirection: 'row',
                        alignItems: 'center',
                        //marginLeft: 5,
                        borderRadius: 5,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                        //marginLeft:'6%', 
                        height: 40,
                        width: 125,
                        //marginTop: 10,
                        justifyContent: 'space-between',
                        zIndex: -1,
                        paddingLeft: 5,
                      }}>
                        <Text style={{
                          //marginLeft: 15,
                          color: 'black',
                          fontSize: 14
                        }}
                        >
                          {moment(endTime).format('hh:mm a')}
                        </Text>

                        <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} />
                      </TouchableOpacity>
                    </View>
                  </View> */}

                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: 0,
                          width: "90%",
                          alignSelf: "center",
                          zIndex: -1,
                          marginLeft: Styles.width * Styles.sideBarWidth * 0.5,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            // justifyContent: 'space-around',
                            width: "45%",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 16,
                              width: Platform.OS === "ios" ? "80%" : "80%",
                              textAlign: "left",
                            }}
                          >
                            Redemption Start Date & End Date
                          </Text>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            flex: 1,
                            alignItems: "center",
                            width: "50%",
                            zIndex: -1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 16,
                              width: Platform.OS === "ios" ? "80%" : "80%",
                              textAlign: "left",
                            }}
                          >
                            Redemption Start Time & End Time
                          </Text>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginBottom: 15,
                          width: "90%",
                          alignSelf: "center",
                          // zIndex: -1,
                          marginLeft: Styles.width * Styles.sideBarWidth * 0.5,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            // justifyContent: 'space-around',
                            width: "45%",
                          }}
                        >
                          <TouchableOpacity
                            onPress={() => {
                              // setShowStartDatePicker(true)
                              setShowRedemptionStartDatePicker(true);
                            }}
                            style={{
                              // height: 50,
                              height: 40,
                              paddingHorizontal: 20,
                              backgroundColor: Colors.fieldtBgColor,
                              //marginBottom: 20,
                              width: 160,
                              //marginHorizontal: 10,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              alignContent: "center",
                              borderColor: "#E5E5E5",
                              borderWidth: 1,
                              fontFamily: "NunitoSans-Regular",
                              fontSize: 14,
                              borderRadius: 12,
                            }}
                          >
                            <GCalendar
                              width={20}
                              height={20}
                              //style={{marginRight: 5}}
                            />
                            <DatePicker
                              selected={moment(redemptionStartDate).toDate()}
                              onChange={(date) => {
                                setRedemptionStartDate(moment(date));
                              }}
                              maxDate={moment(redemptionEndDate).toDate()}
                            />

                            {/* <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} /> */}
                          </TouchableOpacity>

                          <Text
                            style={{
                              fontSize: 16,
                              marginLeft: 20,
                              marginRight: 20,
                              alignItems: "center",
                            }}
                          >
                            to
                          </Text>

                          <TouchableOpacity
                            onPress={() => {
                              // setShowStartTimePicker(true)

                              setShowRedemptionEndDatePicker(true);
                            }}
                            style={{
                              // height: 50,
                              height: 40,
                              paddingHorizontal: 20,
                              backgroundColor: Colors.fieldtBgColor,
                              //marginBottom: 20,
                              width: 160,
                              //marginHorizontal: 10,
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              alignContent: "center",
                              borderColor: "#E5E5E5",
                              borderWidth: 1,
                              fontFamily: "NunitoSans-Regular",
                              fontSize: 14,
                              borderRadius: 12,
                            }}
                          >
                            <GCalendar
                              width={20}
                              height={20}
                              //style={{marginRight: 5}}
                            />

                            <DatePicker
                              selected={moment(redemptionEndDate).toDate()}
                              onChange={(date) => {
                                setRedemptionEndDate(moment(date));
                              }}
                              minDate={moment(redemptionStartDate).toDate()}
                            />

                            {/* <EvilIcons name="calendar" size={25} color={Colors.primaryColor} style={{ marginRight: 5 }} /> */}
                          </TouchableOpacity>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            flex: 1,
                            alignItems: "center",
                            width: "50%",
                            marginTop: 20,
                            marginBottom: 10,
                            zIndex: -1,
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              // justifyContent: 'space-around',
                              width: "45%",
                              marginTop: -12,
                            }}
                          >
                            <TouchableOpacity
                              onPress={() => {
                                // setShowEndDatePicker(true)

                                setShowRedemptionStartTimePicker(true);
                              }}
                              style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                alignContent: "center",
                                borderColor: "#E5E5E5",
                                borderWidth: 1,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: 14,
                                borderRadius: 12,
                              }}
                            >
                              <Time />

                              <Text
                                style={{
                                  //marginLeft: 15,
                                  color: "black",
                                  fontSize: 14,
                                }}
                              >
                                {moment(redemptionStartTime).format("hh:mm A")}
                              </Text>
                            </TouchableOpacity>

                            <Text
                              style={{
                                fontSize: 16,
                                marginLeft: 20,
                                marginRight: 20,
                                alignItems: "center",
                                marginTop: 3,
                              }}
                            >
                              to
                            </Text>

                            <TouchableOpacity
                              onPress={() => {
                                // setShowEndTimePicker(true)

                                setShowRedemptionEndTimePicker(true);
                              }}
                              style={{
                                // height: 50,
                                height: 40,
                                paddingHorizontal: 20,
                                backgroundColor: Colors.fieldtBgColor,
                                //marginBottom: 20,
                                width: 160,
                                //marginHorizontal: 10,
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                alignContent: "center",
                                borderColor: "#E5E5E5",
                                borderWidth: 1,
                                fontFamily: "NunitoSans-Regular",
                                fontSize: 14,
                                borderRadius: 12,
                              }}
                            >
                              <Time />

                              <Text
                                style={{
                                  //marginLeft: 15,
                                  color: "black",
                                  fontSize: 14,
                                }}
                              >
                                {moment(redemptionEndTime).format("hh:mm A")}
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: 0,
                          marginBottom: 15,
                          width: "90%",
                          alignSelf: "center",
                          //alignItems: 'center',
                          zIndex: -1,
                          // zIndex: 100,
                          marginLeft: Styles.width * Styles.sideBarWidth * 0.5,
                          alignItems: "flex-start",
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            // justifyContent: 'space-around',
                            width: "20%",
                            marginTop: "3%",
                            //marginTop: '2.5%',
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 16,
                              width: "100%",
                              textAlign: "left",
                            }}
                          >
                            Preorder Products
                          </Text>
                        </View>

                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            // justifyContent: 'space-around',
                            width: "80%",
                            zIndex: 100,
                            //marginBottom: 10,
                          }}
                        >
                          {/* {
                        outletItemsDropdownList.length > 0
                          // && variationItemsDropdownList.find(item => item.value === selectedVariationItems)
                          ?
                          <DropDownPicker
                            containerStyle={{ height: 40 }}
                            arrowColor={'black'}
                            arrowSize={20}
                            arrowStyle={{ fontWeight: 'bold' }}
                            labelStyle={{ fontFamily: 'NunitoSans-Regular', width: 200 }}
                            style={{ width: 240, paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, }}
                            placeholderStyle={{ color: Colors.fieldtTxtColor, alignItems: 'center', justifyContent: 'center' }}
                            placeholder={"Products"}
                            items={outletItemsDropdownList}
                            //defaultValue={packageSelectedCategoryList}                              
                            customTickIcon={(press) => <Ionicons name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} style={{ width: 30 }} />}
                            onChangeItem={(items) =>
                              // console.log(item)
                              //setPackageSelectedCategoryList(item)
                              setSelectedOutletItems(items)
                            }
                            defaultValue={selectedOutletItems}
                            multiple={true}
                            searchable={true}
                            dropDownMaxHeight={190}
                            dropDownStyle={{ width: 250, height: 200, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1, borderColor: '#E5E5E5', zIndex: 1, }}
                          />
                          :
                          <></>
                      }
                      <View style={{
                        marginLeft: 19, marginBottom: 16
                      }}>
                        <Text style={{ fontSize: 14, color: Colors.fieldtTxtColor, }}>
                          Qty Limit:
                        </Text>
                        <TextInput
                          style={{
                            borderColor: '#E5E5E5',
                            borderWidth: 1,
                            borderRadius: 5,
                            backgroundColor: Colors.fieldtBgColor,
                            paddingLeft: 5,
                            width: 80,
                            height: 40,
                          }}
                          placeholder={'10'}
                          onChangeText={(text) => {

                            // setBdItems(bdItems.map((bdItem, i) => (i === index ? {
                            //   ...bdItem,
                            //   quantity: text.length > 0 ? parseInt(text) : 0,
                            // } : bdItem)))
                          }}
                          // defaultValue={item.quantity.toString()}
                          keyboardType={'number-pad'}
                        />
                      </View> */}

                          <View style={{}}>{renderProducts()}</View>
                          {/* <View
                        style={{
                          //height: 40,
                          marginBottom: 14.5,
                          alignSelf: 'flex-end',
                        }}>
                        <TouchableOpacity
                          style={{}}
                          onPress={() => {
                            // onaddoption()
                            // setBdProducts([
                            //   ...bdProducts,
                            //   {
                            //     productsId: '',
                            //     productsName: '',
                            //     quantity: 0,
                            //   },
                            // ]);

                            setBdItems([
                              ...bdItems,
                              {
                                bdItemId: '',
                                outletItemSku:
                                  variationItemsDropdownList[0].value,
                                outletItemId:
                                  variationItemsDropdownList[0].itemId,
                                variation:
                                  PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
                                isActive: true,
                                quantity: 0,
                              },
                            ]);
                          }}>
                          <View
                            style={
                              {
                                //width: 100,
                                //height: 40,
                                //marginLeft: 20,
                              }
                            }>
                            <View
                              style={{
                                marginLeft: 0,
                                padding: 8,
                              }}>
                              <Icon
                                name="plus-circle"
                                size={20}
                                color={Colors.primaryColor}
                              />
                            </View>
                          </View>
                        </TouchableOpacity>
                      </View> */}
                        </View>
                      </View>

                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                          marginTop: 5,
                          marginBottom: 20,
                          width: "90%",
                          alignSelf: "center",
                          zIndex: -1,
                          marginLeft: Styles.width * Styles.sideBarWidth * 0.5,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            // justifyContent: 'space-around',
                            width: "45%",
                          }}
                        >
                          {/* enable merchant to select how many product qty in the preorder */}
                          <Text
                            style={{
                              fontSize: 16,
                              width: "45%",
                              textAlign: "left",
                            }}
                          >
                            Package Quantity
                          </Text>

                          <TextInput
                            style={{
                              backgroundColor: Colors.fieldtBgColor,
                              width: 80,
                              height: 40,
                              borderRadius: 5,
                              padding: 5,
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                              paddingLeft: 10,
                              zIndex: 10,
                              fontSize: 14,
                            }}
                            placeholder={"0"}
                            placeholderTextColor={Platform.select({
                              ios: "#a9a9a9",
                            })}
                            onChangeText={(text) => {
                              // setBdItems(bdItems.map((bdItem, i) => (i === index ? {
                              //   ...bdItem,
                              //   quantity: text.length > 0 ? parseInt(text) : 0,
                              // } : bdItem)))

                              setPackageQuantity(
                                text.length > 0 ? parseInt(text) : 0
                              );
                              console.log(packageQuantity);
                            }}
                            defaultValue={packageQuantity.toFixed(0)}
                            keyboardType={"number-pad"}
                            // value={item.quantity}
                          />
                        </View>

                        {/* *************************** TouchableOpacity can't function ****************************/}
                        <View
                          style={{
                            flexDirection: "row",
                            flex: 1,
                            alignItems: "center",
                            width: "50%",
                            zIndex: 10,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 16,
                              width: "35%",
                              textAlign: "left",
                            }}
                          >
                            Discounted Price
                          </Text>

                          <TextInput
                            style={{
                              backgroundColor: Colors.fieldtBgColor,
                              width: 100,
                              height: 40,
                              borderRadius: 5,
                              padding: 5,
                              borderWidth: 1,
                              borderColor: "#E5E5E5",
                              paddingLeft: 10,
                              fontSize: 14,
                            }}
                            placeholder={"0.00"}
                            placeholderTextColor={Platform.select({
                              ios: "#a9a9a9",
                            })}
                            onChangeText={(text) => {
                              // setDocketPrice(text);

                              setPackagePrice(text);
                            }}
                            value={packagePrice.toString()}
                            keyboardType={"number-pad"}
                          />
                        </View>
                        {/* *************************** End Test ****************************/}
                      </View>

                      {/* <View
                  style={{
                    flexDirection: 'row',
                    backgroundColor: '#ffffff',
                    justifyContent: 'center',
                    padding: 18,
                  }}>
                  <View style={{ alignItems: 'center' }}>
                    <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
                      {preorderPackages.length}
                    </Text>
                    <Text>PREORDER PACKAGES</Text>
                  </View>
                </View> */}
                    </ScrollView>
                  </View>
                  <View style={{ height: 30 }}></View>
                </ScrollView>
              </View>
            </View>
          ) : null}

          {editPurchase ? (
            <View style={{ height: Dimensions.get("window").height - 200 }}>
              <View>
                <ScrollView>
                  {/* <View style={{ width: 140, marginRight: 10, marginTop: 10, alignSelf: "flex-end" }}>
                  <DropDownPicker
                    items={[
                      {
                        icon: () => { },
                        label: '🖨️  Print P.O',
                        value: 'Print P.O',
                      },
                      {
                        label: '📧  Email P.O',
                        value: 'Chicken',
                      },
                      {
                        label: '📤  Export Labels',
                        value: 'Export Labels',
                      },
                      {
                        label: '❌  Cancel P.O',
                        value: 'Cancel P.O',
                      },
                      {
                        label: '🗑️  Delete P.O',
                        value: 'Delete P.O',
                      },
                    ]}
                    defaultValue={choice}
                    placeholder=""
                    placeholderStyle={{ color: 'black' }}
                    containerStyle={{ height: 30 }}
                    style={{ backgroundColor: '#FAFAFA' }}
                    itemStyle={{
                      justifyContent: 'flex-start',
                    }}
                    dropDownStyle={{ backgroundColor: '#FAFAFA' }}
                    onChangeItem={(item) =>
                      setState({
                        choice: item.value,
                      })
                    }
                  />
                </View> */}
                  <View style={{ borderBottomWidth: StyleSheet.hairlineWidth }}>
                    <View>
                      <Text
                        style={{
                          alignSelf: "center",
                          marginTop: 30,
                          fontSize: 40,
                          fontWeight: "bold",
                        }}
                      >
                        Edit Purchase Order
                      </Text>
                      <Text
                        style={{
                          alignSelf: "center",
                          fontSize: 16,
                          color: "#adadad",
                        }}
                      >
                        Edit your purchase order information
                      </Text>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: 10,
                        justifyContent: "space-evenly",
                        marginTop: 50,
                      }}
                    >
                      <View style={{ flexDirection: "row", flex: 1 }}>
                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                          P.O.ID
                        </Text>
                        <Text
                          style={{
                            color: "#adadad",
                            marginLeft: 170,
                            fontSize: 16,
                          }}
                        >
                          P.O.1134
                        </Text>
                      </View>
                      <View style={{ flexDirection: "row", flex: 1 }}>
                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                          Supplier
                        </Text>
                        <Text
                          style={{
                            color: "#adadad",
                            marginLeft: 100,
                            fontSize: 16,
                          }}
                        >
                          My Burgers Enterprise
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: 10,
                        justifyContent: "space-evenly",
                        marginTop: 50,
                      }}
                    >
                      <View style={{ flexDirection: "row", flex: 1 }}>
                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                          Current status
                        </Text>
                        <View
                          style={{
                            paddingHorizontal: 18,
                            paddingVertical: 10,
                            alignItems: "center",
                            backgroundColor: "#838387",
                            borderRadius: 10,
                            marginLeft: 100,
                          }}
                        >
                          <Text style={{ color: Colors.whiteColor }}>
                            Partially received
                          </Text>
                        </View>
                      </View>
                      <View style={{ flexDirection: "row", flex: 1 }}>
                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                          Target Store
                        </Text>
                        <Text
                          style={{
                            color: "#adadad",
                            marginLeft: 70,
                            fontSize: 16,
                          }}
                        >
                          MyBurgerlab (Seapark)
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: 10,
                        justifyContent: "space-evenly",
                        marginTop: 50,
                        marginBottom: 40,
                      }}
                    >
                      <View style={{ flexDirection: "row", flex: 1 }}>
                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                          Estimated Arrival Time
                        </Text>
                        <Text
                          style={{
                            color: "#adadad",
                            marginLeft: 50,
                            fontSize: 16,
                          }}
                        >
                          1/10/2020
                        </Text>
                      </View>
                      <View style={{ flexDirection: "row", flex: 1 }}>
                        <Text style={{ fontSize: 16, marginLeft: 80 }}>
                          Order Date
                        </Text>
                        <Text
                          style={{
                            color: "#adadad",
                            marginLeft: 80,
                            fontSize: 16,
                          }}
                        >
                          5/10/2020
                        </Text>
                      </View>
                    </View>
                  </View>

                  <View>
                    <Text
                      style={{
                        alignSelf: "center",
                        marginTop: 30,
                        fontSize: 25,
                        fontWeight: "bold",
                      }}
                    >
                      Items to order
                    </Text>
                  </View>

                  <View
                    style={{
                      backgroundColor: "#ffffff",
                      flexDirection: "row",
                      paddingVertical: 20,
                      paddingHorizontal: 20,
                      marginTop: 10,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                    }}
                  >
                    <Text style={{ width: "8%" }}></Text>
                    <Text style={{ width: "14%", alignSelf: "center" }}>
                      Product name
                    </Text>
                    <Text style={{ width: "16%", alignSelf: "center" }}>
                      SKU
                    </Text>
                    <Text style={{ width: "14%", alignSelf: "center" }}>
                      Ordered qty
                    </Text>
                    <Text style={{ width: "16%", alignSelf: "center" }}>
                      Received qty
                    </Text>
                    <Text style={{ width: "18%", alignSelf: "center" }}>
                      Supplier Price{" "}
                    </Text>
                    <Text style={{ width: "16%", alignSelf: "center" }}>
                      Total (RM)
                    </Text>
                  </View>
                  {/* <FlatList
                    showsVerticalScrollIndicator={false}
                    data={itemsToOrder}
                    extraData={itemsToOrder}
                    renderItem={renderItemsToOrder}
                    keyExtractor={(item, index) => String(index)}
                  /> */}

                  <View style={{ flexDirection: "row" }}>
                    <View>
                      <TouchableOpacity
                        style={styles.submitText2}
                        onPress={() => {
                          // add one row

                          setSupplierItems([
                            ...supplierItems,
                            {
                              supplyItemId: "",
                              name: "",
                              sku: "",
                              unit: "",
                              price: 0,
                            },
                          ]);
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <Icon1
                            name="plus-circle"
                            size={20}
                            color={Colors.primaryColor}
                          />
                          <Text
                            style={{
                              marginLeft: 10,
                              color: Colors.primaryColor,
                            }}
                          >
                            Add supply item slot
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>

                    {/* <View style={{ marginLeft: 480, marginTop: 10 }}>
                    <View style={{ flexDirection: "row" }}>
                      <Text style={{ color: '#adadad' }}>Subtotal</Text>
                      <Text style={{ color: '#adadad', marginLeft: 50 }}>RM360.00</Text>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 10 }}>
                      <Text style={{ color: '#adadad' }}>Tax</Text>
                      <Text style={{ color: '#adadad', marginLeft: 80 }}>RM0.00</Text>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 10 }}>
                      <Text style={{ color: '#adadad' }}>Discount</Text>
                      <Text style={{ color: '#adadad', marginLeft: 50 }}>RM0.00</Text>
                    </View>
                    <View style={{ flexDirection: "row", marginTop: 5 }}>
                      <Text style={{ fontWeight: 'bold' }}>Total (RM)</Text>
                      <Text style={{ fontWeight: 'bold', marginLeft: 40 }}>RM360.00</Text>
                    </View>
                  </View> */}
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      alignSelf: "center",
                      justifyContent: "space-evenly",
                      marginTop: 20,
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: Colors.primaryColor,
                        width: 200,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: "center",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          // editStockOrder();
                          createPreorderPackage();
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            alignSelf: "center",
                            marginVertical: 10,
                          }}
                        >
                          SAVE
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View
                      style={{
                        backgroundColor: Colors.primaryColor,
                        width: 200,
                        height: 40,
                        marginVertical: 15,
                        borderRadius: 5,
                        alignSelf: "center",
                        marginLeft: 40,
                      }}
                    >
                      <TouchableOpacity onPress={() => {}}>
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            alignSelf: "center",
                            marginVertical: 10,
                          }}
                        >
                          SAVE & SEND
                        </Text>
                      </TouchableOpacity>
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      backgroundColor: "#ffffff",
                      justifyContent: "center",
                      padding: 18,
                    }}
                  >
                    <View style={{ alignItems: "center" }}>
                      <Text style={{ fontSize: 30, fontWeight: "bold" }}>
                        {/* {stockList.length} */}
                        {preorderPackages.length}
                      </Text>
                      <Text>PREORDER PACKAGES</Text>
                    </View>
                  </View>
                </ScrollView>
              </View>
            </View>
          ) : null}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  list1: {
    backgroundColor: Colors.whiteColor,
    width: Dimensions.get("screen").width * 0.87,
    height:
      Platform.OS === "ios"
        ? Dimensions.get("screen").height * 0.65
        : Dimensions.get("screen").height * 0.55,
    // marginTop: 30,
    marginHorizontal: 30,
    marginBottom: 30,
    alignSelf: "center",
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  list1_PhoneScreen: {
    backgroundColor: Colors.whiteColor,
    width: Dimensions.get("screen").width * 0.79,
    //height: Platform.OS === 'ios'? Dimensions.get('screen').height * 0.65: Dimensions.get('screen').height * 0.55,
    //marginTop: 30,
    //marginHorizontal: 30,
    //marginBottom: 30,
    //alignSelf: 'center',
    //paddingBottom: 30,
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  list2: {
    backgroundColor: Colors.whiteColor,
    width: Dimensions.get("screen").width * 0.87,
    height:
      Platform.OS === "ios"
        ? Dimensions.get("screen").height * 0.65
        : Dimensions.get("screen").height * 0.55,
    //marginTop: 30,
    marginHorizontal: 30,
    marginBottom: 30,
    alignSelf: "center",
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 16,
    width: Dimensions.get("screen").width * (1 - Styles.sideBarWidth),
  },
  submitText: {
    height: Platform.OS == "ios" ? Styles.height * 0.06 : Styles.height * 0.07,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    marginRight: 10,
  },
  submitText1: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    // alignSelf: "flex-end",
    // marginRight: 20,
    // marginTop: 15
  },
  submitText2: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: "row",
    color: "#4cd964",
    textAlign: "center",
    alignSelf: "flex-end",
    marginRight: 20,
    marginTop: 15,
  },
  /* textInput: {
    width: 300,
    height: '10%',
    padding: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 20,
  }, */

  textInput: {
    width: 200,
    height: 40,
    // padding:5,
    backgroundColor: Colors.whiteColor,
    borderRadius: 0,
    marginRight: "35%",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "flex-end",

    shadowOffset:
      Platform.OS == "ios"
        ? {
            width: 0,
            height: 0,
          }
        : {
            width: 0,
            height: 7,
          },
    shadowOpacity: Platform.OS == "ios" ? 0 : 0.43,
    shadowRadius: Platform.OS == "ios" ? 0 : 0.51,
    elevation: 15,
  },
  searchIcon: {
    backgroundColor: Colors.whiteColor,
    height: 40,
    padding: 10,
    shadowOffset:
      Platform.OS == "ios"
        ? {
            width: 0,
            height: 0,
          }
        : {
            width: 0,
            height: 7,
          },
    shadowOpacity: Platform.OS == "ios" ? 0 : 0.43,
    shadowRadius: Platform.OS == "ios" ? 0 : 9.51,

    elevation: 15,
  },
  textInput1: {
    width: 160,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 5,
  },
  textInput2: {
    width: 100,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 5,
    textAlign: "center",
  },
  confirmBox: {
    width: 450,
    height: 450,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
  },
  textFieldInput: {
    height: 80,
    width: 900,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  headerLeftStyle: {
    width: Styles.width * 0.17,
    justifyContent: "center",
    alignItems: "center",
  },

  addButtonView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 160,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
});
export default PreorderPackageScreen;
