import React, {
  Component,
  useReducer,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Modal,
  Text,
  Alert,
  Dimensions,
  TouchableOpacity,
  FlatList,
  Linking,
  ActivityIndicator,
  Platform,
  KeyboardAvoidingView,
  useWindowDimensions,
} from 'react-native';
import Colors from '../constant/Colors';
import SideBar from './SideBar';
// import FontAwesome from 'react-native-vector-icons/FontAwesome';
// import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Entypo from 'react-native-vector-icons/Entypo';
import Icon from 'react-native-vector-icons/Feather';
import Icon3 from 'react-native-vector-icons/EvilIcons';
// import Swipeout from 'react-native-swipeout';
// import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
// import Icon1 from 'react-native-vector-icons/AntDesign';
import AntDesign from 'react-native-vector-icons/AntDesign';
// import Icon2 from 'react-native-vector-icons/Ionicons';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import DropDownPicker from 'react-native-dropdown-picker';
// import * as User from '../util/User';
import Styles from '../constant/Styles';
import moment from 'moment';
// import MIcon from 'react-native-vector-icons/MaterialCommunityIcons';
// import Switch from 'react-native-switch-pro';
import Switch from 'react-switch'
// import Swipeable from 'react-native-gesture-handler/Swipeable';
import { OutletStore } from '../store/outletStore';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { TextInput } from 'react-native-gesture-handler';
// import RNPickerSelect from 'react-native-picker-select';
import { CommonStore } from '../store/commonStore';
import AsyncImage from '../components/asyncImage';
import Feather from 'react-native-vector-icons/Feather';
import { USER_QUEUE_STATUS, USER_QUEUE_STATUS_PARSED } from '../constant/common';
// import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import {
  QUEUE_SORT_FIELD_TYPE,
  REPORT_SORT_FIELD_TYPE_COMPARE,
  QUEUE_SORT_FIELD_TYPE_VALUE,
  REPORT_SORT_COMPARE_OPERATOR,
  PAYMENT_SORT_FIELD_TYPE,
  PAYMENT_SORT_FIELD_TYPE_VALUE,
  PAYMENT_SORT_FILED_TYPE_COMPARE,
} from '../constant/common';
// import { color } from 'react-native-reanimated';
import { uploadImageToFirebaseStorage, sortPaymentDataList } from '../util/common';
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { useFilePicker } from "use-file-picker";

const SettingPaymentScreen = (props) => {
  const { navigation } = props;

  const {
    height: windowHeight,
    width: windowWidth,
  } = useWindowDimensions();

  const [visible, setVisible] = useState(false);

  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState(false);
  const [confirmQueueModal, setConfirmQueueModal] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});

  const [image, setImage] = useState('');
  const [imageType, setImageType] = useState('');
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [openFileSelector, { plainFiles, filesContent, loading: loadingImageInput, clear: clearImageContainer, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: ["image/*"],
    multiple: false,
  });

  const [paymentName, setPaymentName] = useState('');

  const [table, setTable] = useState([]);
  const [queue, setQueue] = useState([]);
  const [newReservationStatus, setNewReservationStatus] = useState(false);

  const [filterType, setFilterType] = useState(0);

  const [userQueues, setUserQueues] = useState([]);

  const [switchMerchant, setSwitchMerchant] = useState(false);

  const [loadingDict, setLoadingDict] = useState({});

  const userQueuesRaw = OutletStore.useState((s) => s.userQueues);
  const outletPaymentMethods = OutletStore.useState((s) => s.outletPaymentMethods);

  const currOutlet = MerchantStore.useState((s) => s.currOutlet);

  const userName = UserStore.useState((s) => s.name);
  const userId = UserStore.useState((s) => s.firebaseUid);
  const userEmail = UserStore.useState((s) => s.email);
  const merchantId = UserStore.useState((s) => s.merchantId);
  const merchantName = MerchantStore.useState((s) => s.name);
  const merchantLogo = MerchantStore.useState((s) => s.logo);

  const isLoading = CommonStore.useState((s) => s.isLoading);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView,
  );

  //New UX
  const [controller1, setController1] = useState({});

  // select image
  useEffect(() => {
    if (plainFiles.length && filesContent.length && !loadingImageInput) {
      setImage(filesContent[0].content);
      setImageType(filesContent[0].name.slice(filesContent[0].name.lastIndexOf('.')));
      setIsImageChanged(true);
    }

    if (errors.length)
      console.error(errors)

  }, [plainFiles, filesContent, loadingImageInput, errors])

  useEffect(() => {
    var userQueuesTemp = [];

    if (filterType == 0) {
      //Prioritize
      userQueuesTemp = userQueuesRaw;
    }
    if (filterType == 1) {
      //Prioritize
      userQueuesTemp = userQueuesRaw.filter(
        (order) => order.status === USER_QUEUE_STATUS.PENDING,
      );
    }
    if (filterType == 2) {
      //orderid
      userQueuesTemp = userQueuesRaw.filter(
        (order) => order.status === USER_QUEUE_STATUS.ACCEPTED,
      );
    }
    if (filterType == 3) {
      //date time
      userQueuesTemp = userQueuesRaw.filter(
        (order) => order.status === USER_QUEUE_STATUS.SEATED,
      );
    }
    if (filterType == 4) {
      //Name
      userQueuesTemp = userQueuesRaw.filter(
        (order) => order.status === USER_QUEUE_STATUS.SERVED,
      );
    }
    if (filterType == 5) {
      //Waiting Time
      userQueuesTemp = userQueuesRaw.filter(
        (order) => order.status === USER_QUEUE_STATUS.CANCELED,
      );
    }
    if (filterType == 6) {
      //Waiting Time
      userQueuesTemp = userQueuesRaw.filter(
        (order) => order.status === USER_QUEUE_STATUS.NO_SHOW,
      );
    }

    setUserQueues(userQueuesTemp);
  }, [filterType, userQueuesRaw]);


  const createPaymentMethod = async () => {
    if (
      !image ||
      paymentName.length < 0
    ) {
      alert('Info: Please fill in the informations');
      return;
    } else {
      var isExisted = false;

      for (var i = 0; i < outletPaymentMethods.length; i++) {
        if (outletPaymentMethods[i].name === paymentName) {
          isExisted = true;
          break;
        }
      }

      if (isExisted) {
        alert('Info: Payment method with the same name exists');
        return;
      }
      else {
        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ///UPLOAD IMAGE///

        var outletItemImagePath = '';
        // var outletItemIdLocal = selectedPaymentMethod ? selectedPaymentMethod.uniqueId : uuidv4();
        var outletItemIdLocal = uuidv4();

        if (image && imageType) {

          outletItemImagePath = await uploadImageToFirebaseStorage({
            uri: image,
            type: imageType,
          }, `/merchant/${merchantId}/paymentMethod/${outletItemIdLocal}/image${imageType}`);
        }

        ////////////////

        var body = {
          outletId: currOutlet.uniqueId,
          merchantId: merchantId,
          name: paymentName,
          image: outletItemImagePath,
        };

        ApiClient.POST(API.createOutletPaymentMethod, body, false)
          .then((result) => {
            if (result.status) {

              // setSelectedPaymentMethod(result.userQueue);

              if (Platform.OS === 'ios') {
                alert('Success: Payment method has been created');
              } else {
                alert('Success: Payment method has been created');
              }

              setAddPaymentMethodModal(false);

              CommonStore.update((s) => {
                s.isLoading = false;
              });
            }
          })
          .catch((err) => {
            console.log(err);

            CommonStore.update((s) => {
              s.isLoading = false;
            });
          });
      }
    }
  };

  const updatePaymentMethod = async () => {
    if (
      !image ||
      paymentName.length < 0
    ) {
      alert('Info: Please fill in the informations');
      return;
    } else {
      var isExisted = false;

      for (var i = 0; i < outletPaymentMethods.length; i++) {
        if (outletPaymentMethods[i].name === paymentName && outletPaymentMethods[i].uniqueId !== selectedPaymentMethod.uniqueId) {
          isExisted = true;
          break;
        }
      }

      if (isExisted) {
        alert('Info: Payment method with the same name exists');
        return;
      }
      else {
        CommonStore.update((s) => {
          s.isLoading = true;
        });

        ///UPLOAD IMAGE///

        var outletItemImagePath = '';
        var outletItemIdLocal = selectedPaymentMethod ? selectedPaymentMethod.uniqueId : uuidv4();
        // var outletItemIdLocal = uuidv4();

        if (image && imageType) {

          outletItemImagePath = await uploadImageToFirebaseStorage({
            uri: image,
            type: imageType,
          }, `/merchant/${merchantId}/paymentMethod/${outletItemIdLocal}/image${imageType}`);
        }

        ////////////////

        var body = {
          outletId: currOutlet.uniqueId,
          merchantId: merchantId,
          name: paymentName,
          image: outletItemImagePath || image,

          paymentMethodId: selectedPaymentMethod.uniqueId,
        };

        ApiClient.POST(API.updateOutletPaymentMethod, body, false)
          .then((result) => {
            if (result.status) {

              // setSelectedPaymentMethod(result.userQueue);

              alert('Success: Payment method has been updated successfully')

              setAddPaymentMethodModal(false);

              CommonStore.update((s) => {
                s.isLoading = false;
              });
            }
          })
          .catch((err) => {
            console.log(err);

            CommonStore.update((s) => {
              s.isLoading = false;
            });
          });
      }
    }
  };

  const deletePaymentMethod = async (paymentMethodId) => {
    // means existing item

    ///////////////////////////////////

    var body = {
      paymentMethodId: paymentMethodId,
    };

    console.log(body);

    CommonStore.update(s => {
      s.isLoading = true;
    });

    ApiClient.POST(API.deleteOutletPaymentMethod, body, false).then((result) => {
      if (result && result.status === 'success') {
        alert('Success: Payment method has been deleted');
      }

      CommonStore.update(s => {
        s.isLoading = false;
      });
    });
  }

  const togglePaymentMethod = async (paymentMethodId, status) => {
    // means existing item

    ///////////////////////////////////

    setLoadingDict({
      ...loadingDict,
      [paymentMethodId]: true,
    });

    var body = {
      paymentMethodId: paymentMethodId,
      status: status,
    };

    console.log(body);

    CommonStore.update(s => {
      s.isLoading = true;
    });

    ApiClient.POST(API.toggleOutletPaymentMethodStatus, body, false).then((result) => {
      if (result && result.status === 'success') {
        // Alert.alert(
        //   'Success',
        //   "Payment method deleted successfully.",
        //   [
        //     {
        //       text: 'OK',
        //       onPress: () => {

        //       },
        //     },
        //   ],
        //   { cancelable: false },
        // );
      }

      CommonStore.update(s => {
        s.isLoading = false;
      });

      setLoadingDict({
        ...loadingDict,
        [paymentMethodId]: false,
      });
    });
  }

  const [queueOrders, setQueueOrders] = useState([]);
  console.log('USER_QUEUE_STATUS_PARSED.SEATED');
  console.log(setQueueOrders);

  const [sort, setSort] = useState('');
  const [search, setSearch] = useState('');

  //Start Here Sorting

  const sortOperationQueue = (dataList, queueSortFieldType) => {
    var dataListTemp = [...dataList];
    console.log('dataList');
    console.log(dataList);

    console.log('queueSortFieldType');
    console.log(queueSortFieldType);

    const queueSortFieldTypeValue =
      QUEUE_SORT_FIELD_TYPE_VALUE[queueSortFieldType];

    const queueSortFieldTypeCompare =
      REPORT_SORT_FIELD_TYPE_COMPARE[queueSortFieldType];

    //QUEUE_ID
    if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.QUEUE_ID_ASC) {
        dataListTemp.sort(
          (a, b) =>
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
        );
      }
    } else if (
      queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
    ) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.QUEUE_ID_DESC) {
        dataListTemp.sort(
          (a, b) =>
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
        );
      }
    }

    //NAME
    if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.NAME_ASC) {
        dataListTemp.sort((a, b) =>
          (a[queueSortFieldTypeValue]
            ? a[queueSortFieldTypeValue]
            : ''
          ).localeCompare(
            b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '',
          ),
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
        );
      }
    } else if (
      queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
    ) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.NAME_DESC) {
        dataListTemp.sort((a, b) =>
          (b[queueSortFieldTypeValue]
            ? b[queueSortFieldTypeValue]
            : ''
          ).localeCompare(
            a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '',
          ),
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
        );
      }
    }

    //DATE_TIME
    if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.DATE_TIME_ASC) {
        dataListTemp.sort(
          (a, b) =>
            (moment(a[queueSortFieldTypeValue]).valueOf()
              ? moment(a[queueSortFieldTypeValue]).valueOf()
              : '') -
            (moment(b[queueSortFieldTypeValue]).valueOf()
              ? moment(b[queueSortFieldTypeValue]).valueOf()
              : ''),
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
        );
      }
    } else if (
      queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
    ) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.DATE_TIME_DESC) {
        dataListTemp.sort(
          (a, b) =>
            (moment(b[queueSortFieldTypeValue]).valueOf()
              ? moment(b[queueSortFieldTypeValue]).valueOf()
              : '') -
            (moment(a[queueSortFieldTypeValue]).valueOf()
              ? moment(a[queueSortFieldTypeValue]).valueOf()
              : ''),
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
        );
      }
    }

    //CAPACITY
    if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.CAPACITY_ASC) {
        dataListTemp.sort(
          (a, b) =>
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
        );
      }
    } else if (
      queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
    ) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.CAPACITY_DESC) {
        dataListTemp.sort(
          (a, b) =>
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
        );
      }
    }

    //WAITING_TIME
    if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.WAITING_TIME_ASC) {
        dataListTemp.sort(
          (a, b) =>
            (moment(a[queueSortFieldTypeValue]).valueOf()
              ? moment(a[queueSortFieldTypeValue]).valueOf()
              : '') -
            (moment(b[queueSortFieldTypeValue]).valueOf()
              ? moment(b[queueSortFieldTypeValue]).valueOf()
              : ''),
        );
      }
    } else if (
      queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
    ) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.WAITING_TIME_DESC) {
        dataListTemp.sort(
          (a, b) =>
            (moment(b[queueSortFieldTypeValue]).valueOf()
              ? moment(b[queueSortFieldTypeValue]).valueOf()
              : '') -
            (moment(a[queueSortFieldTypeValue]).valueOf()
              ? moment(a[queueSortFieldTypeValue]).valueOf()
              : ''),
        );
      }
    }

    //STATUS
    if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.STATUS_ASC) {
        dataListTemp.sort((a, b) =>
          (a[queueSortFieldTypeValue]
            ? a[queueSortFieldTypeValue]
            : ''
          ).localeCompare(
            b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '',
          ),
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
        );
      }
    } else if (
      queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
    ) {
      if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.STATUS_DESC) {
        dataListTemp.sort((a, b) =>
          (b[queueSortFieldTypeValue]
            ? b[queueSortFieldTypeValue]
            : ''
          ).localeCompare(
            a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '',
          ),
        );
      } else {
        dataListTemp.sort(
          (a, b) =>
            (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
            (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
        );
      }
    }

    return dataListTemp;
  };

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[{
          justifyContent: 'center',
          alignItems: 'center',
          // bottom: -2,
          bottom: switchMerchant ? '2%' : 0,
        }, Dimensions.get('screen').width >= 768 && switchMerchant ? { right: Dimensions.get('screen').width * 0.1 } : {}, Dimensions.get('screen').width <= 768 ? { right: Dimensions.get('screen').width * 0.12 } : {}]}>
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Payment Settings
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: 'white',
            width: 0.5,
            height: Dimensions.get('screen').height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}></View>
        <TouchableOpacity
          onPress={() => navigation.navigate('Setting')}
          style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get('screen').height * 0.05,
              height: Dimensions.get('screen').height * 0.05,
              borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}>
            <Image
              style={{
                width: Dimensions.get('screen').height * 0.035,
                height: Dimensions.get('screen').height * 0.035,
                alignSelf: 'center',
              }}
              source={require('../assets/image/profile-pic.jpg')}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  const renderPaymentMethod = ({ item, index }) => {

    return (
      <View
        style={{
          paddingVertical: 5,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.22,
          shadowRadius: 3.22,
          elevation: 1,
        }}>
        <View
          style={[
            { elevation: 1, borderRadius: 7, backgroundColor: 'white' },
            switchMerchant
              ? {
                width: Dimensions.get('screen').width * 0.8,
              }
              : {},
          ]}>
          <View
            style={[
              {
                width: '100%',
                flexDirection: 'row',
                height: Dimensions.get('screen').height * 0.1,
                alignItems: 'center',
                borderBottomColor: Colors.fieldtT,
                // borderBottomWidth: expandViewDict[item.uniqueId] == true ? StyleSheet.hairlineWidth : null
              },
              switchMerchant
                ? {
                  width: Dimensions.get('screen').width * 0.82,
                  // height: Dimensions.get('screen').height * 0.1,
                }
                : {},
            ]}>
            <View
              style={[
                {
                  width: '8%',
                  marginLeft: '2.5%',
                  //marginHorizontal: 0.5,
                  alignItems: 'flex-start',
                },
                switchMerchant
                  ? {
                    width: '8%',
                    left: '10%',
                  }
                  : {},
              ]}>
              <AsyncImage
                style={[
                  {
                    width: 30,
                    height: 30,
                    marginLeft: 0,
                  },
                  switchMerchant
                    ? {
                      width: Dimensions.get('screen').width * 0.03,
                      height: Dimensions.get('screen').height * 0.05,
                    }
                    : {},
                ]}
                resizeMode="contain"
                // source={require('../assets/image/dineinGrey.png')}
                source={{
                  uri: item.image
                }}
                item={item}
              />
            </View>

            <View
              style={[
                {
                  width: '18%',
                  flexDirection: 'row',
                  //marginHorizontal: 0.5,
                  alignItems: 'flex-start',
                },
                switchMerchant
                  ? {
                    width: '17.1%',
                    alignItems: 'flex-start',
                    // left: '10%'
                  }
                  : {},
              ]}>
              <View
                style={{
                  flexDirection: 'row',
                  //marginHorizontal: 0.5,
                  width: Platform.OS == 'ios' ? '60%' : '60%',
                }}>
                <Text
                  style={[
                    {
                      color: Colors.fontDark,
                      fontSize: 16,
                      fontFamily: 'NunitoSans-Bold',
                      textAlign: 'left',
                    },
                    switchMerchant
                      ? {
                        fontSize: 10,
                      }
                      : {},
                  ]}>
                  {item.name}
                </Text>
              </View>
            </View>

            <View
              style={[
                {
                  width: '18%',
                  //marginHorizontal: 0.5,
                  textAlign: 'left',
                },
                switchMerchant
                  ? {
                    width: '17.6%',
                    // left: '10%'
                  }
                  : {},
              ]}>

              <Text
                style={[
                  {
                    color: Colors.fontDark,
                    fontSize: 16,
                    fontFamily: 'NunitoSans-Bold',
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {moment(item.createdAt).format('DD MMM YYYY')}
              </Text>
              <Text
                style={[
                  {
                    color: Colors.fontDark,
                    fontSize: 16,
                    fontFamily: 'NunitoSans-Bold',
                    marginTop: 2,
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {moment(item.createdAt).format('hh:mm A')}
              </Text>
            </View>

            <View
              style={[
                {
                  width: '18%',
                  //marginHorizontal: 0.5,
                  textAlign: 'left',
                },
                switchMerchant
                  ? {
                    width: '17.4%',
                    // left: '10%'
                  }
                  : {},
              ]}>

              <Text
                style={[
                  {
                    color: Colors.fontDark,
                    fontSize: 16,
                    fontFamily: 'NunitoSans-Bold',
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {moment(item.updatedAt).format('DD MMM YYYY')}
              </Text>
              <Text
                style={[
                  {
                    color: Colors.fontDark,
                    fontSize: 16,
                    fontFamily: 'NunitoSans-Bold',
                    marginTop: 2,
                  },
                  switchMerchant
                    ? {
                      fontSize: 10,
                    }
                    : {},
                ]}>
                {moment(item.updatedAt).format('hh:mm A')}
              </Text>
            </View>

            <View
              style={[
                {
                  width: '24%',
                  //marginHorizontal: 0.5,
                  textAlign: 'left',
                },
                switchMerchant
                  ? {
                    width: '24%',
                    // left: '10%'
                  }
                  : {},
              ]}>
              {
                !loadingDict[item.uniqueId]
                  ?
                  <>
                    <Switch
                      onChange={(value) => {
                        togglePaymentMethod(item.uniqueId, value);
                      }}
                      checked={item.isActive}
                      width={35}
                      height={20}
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}

                    />
                    <Text
                      style={[
                        {
                          color: item.isActive ? Colors.primaryColor : Colors.tabRed,
                          fontSize: 16,
                          fontFamily: 'NunitoSans-Bold',
                          marginTop: 2,
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                          }
                          : {},
                      ]}>
                      {item.isActive ? 'Active' : 'Disabled'}
                    </Text>
                  </>
                  :
                  <View style={{
                    // backgroundColor: 'blue',
                    alignItems: 'flex-start',
                  }}>
                    <ActivityIndicator color={Colors.primaryColor} size={switchMerchant ? 'small' : 'large'} />
                  </View>
              }
            </View>

            <View
              style={{
                width: '5%',
                marginHorizontal: 0.5,
                alignItems: 'flex-start',
              }}>
              <View
                style={switchMerchant ? {
                  width: '100%',
                  flexDirection: 'row',
                  marginHorizontal: 0.5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: Dimensions.get('screen').width * 0.015
                } : {
                  width: '100%',
                  flexDirection: 'row',
                  marginHorizontal: 0.5,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <TouchableOpacity
                  onPress={() => {
                    setSelectedPaymentMethod(item);

                    setImage(item.image);
                    setImageType('');
                    setIsImageChanged(false);
                    clearImageContainer();

                    setPaymentName(item.name);

                    setAddPaymentMethodModal(true);
                  }}>
                    <Feather
                      name="edit"
                      size={23}
                      color={Colors.primaryColor}
                    />
                </TouchableOpacity>
              </View>
            </View>
            <View
              style={{
                width: '5%',
                marginHorizontal: 0.5,
                alignItems: 'flex-start',
              }}>
              <View
                style={switchMerchant ? {
                  width: '100%',
                  flexDirection: 'row',
                  marginHorizontal: 0.5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  right: Dimensions.get('screen').width * 0.015
                } : {
                  width: '100%',
                  flexDirection: 'row',
                  marginHorizontal: 0.5,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <TouchableOpacity
                  onPress={() => {
                    if(window.confirm('Info: Are you sure you want to delete this payment method?')) {
                      deletePaymentMethod(item.uniqueId);
                    }

                  }}>
                  {switchMerchant ? (
                    <Icon
                      name="trash-2"
                      size={13}
                      color="#eb3446"
                    />
                  ) : (
                    <Icon
                      name="trash-2"
                      size={23}
                      color="#eb3446"
                    />
                  )}
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };


  // function end

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={1} expandOperation={true} />
      </View>

      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          // scrollEnabled={switchMerchant}
          style={{ backgroundColor: Colors.highlightColor }}
          contentContainerStyle={{
            paddingBottom: Dimensions.get('screen').height * 0.1,
            backgroundColor: Colors.highlightColor,
          }}>
          <View style={styles.content}>
            <View
              style={[{
                flexDirection: 'row',
                alignItems: 'center',
                padding: switchMerchant ? 0 : 2,
                width: switchMerchant ? Dimensions.get('screen').width * 0.79 : '100%',
                justifyContent: 'space-between',
              }, switchMerchant ? {
                marginBottom: Dimensions.get('screen').height * 0.03,
                // borderWidth: 1,
                marginTop: Dimensions.get('screen').height * -0.03
              } : {}]}>
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text
                  style={[
                    { fontSize: 26, fontFamily: 'NunitoSans-Bold' },
                    switchMerchant
                      ? {
                        // fontSize: 15,
                        //follow dashboard
                        fontSize: 20,
                        // borderWidth: 1,
                        // top: Dimensions.get('screen').height * -0.05,
                      }
                      : {},
                  ]}>
                  {outletPaymentMethods.length} Payment Method
                </Text>
              </View>
              <View
                style={[
                  { flexDirection: 'row', alignItems: 'center' },
                  switchMerchant
                    ? {
                      //left: Dimensions.get('screen').width * -0.05,
                    }
                    : {},
                ]}>
                <TouchableOpacity
                  style={[
                    styles.submitText,
                    {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: 10,
                      height: 40,
                      left: 0,
                      backgroundColor: '#4E9F7D',
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                    },
                    switchMerchant
                      ? {
                        // top: Dimensions.get('screen').height * -0.046,
                        height: 35,
                        //width: Dimensions.get('screen').width * 0.12,
                      }
                      : {},
                  ]}
                  onPress={() => {
                    setSelectedPaymentMethod({});

                    setImage('');
                    setImageType('');
                    setIsImageChanged(false);
                    clearImageContainer();

                    setPaymentName('');

                    setAddPaymentMethodModal(true);
                  }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    {switchMerchant ? (
                      <AntDesign name="pluscircle" size={10} color="#FFFFFF" />
                    ) : (
                      <AntDesign name="pluscircle" size={20} color="#FFFFFF" />
                    )}

                    <Text
                      style={[
                        {
                          marginLeft: 5,
                          color: Colors.primaryColor,
                          fontSize: 16,
                          color: '#FFFFFF',
                          fontFamily: 'NunitoSans-Bold',
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                            // paddingLeft: '10%',
                          }
                          : {},
                      ]}>
                      PAYMENT METHOD
                    </Text>
                  </View>
                </TouchableOpacity>

                {/* <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 10, height: 40, }}>
              
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 10,
                    borderRadius: 5,
                    height: 40,
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    backgroundColor: 'white',
                    marginRight: 15,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
  
                  }}>
                    <Text style={{ fontSize: 16, paddingRight: Platform.OS == 'ios' ? 20 : 20, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>
                      Filter
                    </Text>
                    <DropDownPicker
                      controller={instance => setController1(instance)}
                      arrowColor={Colors.primaryColor}
                      arrowSize={23}
                      arrowStyle={{ fontWeight: 'bold' }}
                      labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                      itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                      placeholderStyle={{ color: 'black' }}
                      style={{ width: 140, borderWidth: 0, paddingHorizontal: 5, paddingVertical: 0, borderRadius: 5, borderColor: '#E5E5E5', borderWidth: 0, borderLeftWidth: 0, }}
                      items={[{ label: 'Pending', value: 0 }, { label: 'Accepted', value: 1 }, { label: 'Seated', value: 2 }, { label: 'Served', value: 3 }, { label: 'Rejected', value: 4 }, { label: 'No Show', value: 5 }]} //Awaiting Authorization
                      placeholder={"Pending"}
                      onChangeItem={selectedFilter => {
                        filterOrders(selectedFilter);
                      }
                      }
                    />
                  </View>
                </View> */}

                {/* <View
                style={[
                  {
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 10,
                    borderRadius: 5,
                    height: 40,
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#E5E5E5',
                    backgroundColor: 'white',
                    marginRight: 15,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    // elevation: 3,
                  },
                  switchMerchant
                    ? {
                        // top: Dimensions.get('screen').height * -0.046,
                        width: Dimensions.get('screen').width * 0.21,
                        height: 35,
                        marginRight: '5%',
                        // left: Dimensions.get('screen').width * -0.12,
                      }
                    : {},
                ]}>
                <Text
                  style={[
                    {
                      fontSize: 16,
                      paddingLeft: '1%',
                      paddingRight: Platform.OS == 'ios' ? 20 : 20,
                      borderColor: Colors.fieldtTxtColor,
                      fontFamily: 'NunitoSans-Bold',
                    },
                    switchMerchant
                      ? {
                          fontSize: 10,
                          // left: Dimensions.get('screen').width * -0.015,
                          left: '30%',
                          // backgroundColor: 'white',
                          // height: '100%',
                          // left: '-20%',
                          // borderRadius: 5,
                          // textAlignVertical: 'center'
                        }
                      : {},
                  ]}>
                  Filter
                </Text>
                <DropDownPicker
                  // controller={instance => setController1(instance)}
                  arrowColor={Colors.primaryColor}
                  arrowSize={switchMerchant? 13 : 23}
                  arrowStyle={[
                    {fontWeight: 'bold'},
                    switchMerchant
                      ? {
                          top: Dimensions.get('screen').height * -0.005,
                          height: '200%',
                          // borderWidth: 1
                        }
                      : {},
                  ]}
                  labelStyle={[
                    {fontFamily: 'NunitoSans-Regular'},
                    switchMerchant
                      ? {
                          fontSize: 10,
                        }
                      : {},
                  ]}
                  itemStyle={[
                    {justifyContent: 'flex-start', marginLeft: 6},
                    switchMerchant
                      ? {
                          fontSize: 10,
                        }
                      : {},
                  ]}
                  placeholderStyle={[
                    {color: 'black'},
                    switchMerchant
                      ? {
                          fontSize: 10,
                        }
                      : {},
                  ]}
                  style={[
                    {
                      width: 140,
                      borderWidth: 0,
                      paddingHorizontal: 5,
                      paddingVertical: 0,
                      borderRadius: 5,
                      borderColor: '#E5E5E5',
                      borderWidth: 0,
                      borderLeftWidth: 0,
                    },
                    switchMerchant
                      ? {
                          fontSize: 10,
                          // width: Dimensions.get('screen').width * 0.12,
                        }
                      : {},
                  ]}
                  items={[
                    {label: 'All', value: 0},
                    {label: 'Pending', value: 1},
                    {label: 'Seated', value: 3},
                    {label: 'Served', value: 4},
                    {label: 'No Show', value: 6},
                  ]} //Awaiting Authorization
                  // placeholder={"Pending"}
                  defaultValue={filterType}
                  onChangeItem={(selectedFilter) => {
                    // filterOrders(selectedFilter);
                    setFilterType(selectedFilter.value);
                  }}
                  //onOpen={() => controller.close()}
                />
              </View> */}

                <View
                  style={[
                    {
                      height: switchMerchant ? 35 : 40,
                    },
                  ]}>
                  <View
                    style={[
                      {
                        width: 250,
                        height: 40,
                        backgroundColor: 'white',
                        borderRadius: 5,
                        flexDirection: 'row',
                        alignContent: 'center',
                        alignItems: 'center',
                        shadowColor: '#000',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 3,
                        borderWidth: 1,
                        borderColor: '#E5E5E5',
                      },
                      switchMerchant
                        ? {
                          height: 35,
                          width: 200,
                          // left: Dimensions.get('screen').width * -0.05,
                          // top: Dimensions.get('screen').height * -0.03,
                        }
                        : {},
                    ]}>
                    {switchMerchant ? (
                      <Icon
                        name="search"
                        size={13}
                        color={Colors.primaryColor}
                        style={{ marginLeft: 15 }}
                      />
                    ) : (
                      <Icon
                        name="search"
                        size={18}
                        color={Colors.primaryColor}
                        style={{ marginLeft: 15 }}
                      />
                    )}
                    {switchMerchant ? (
                      <TextInput
                        // underlineColorAndroid={Colors.whiteColor}
                        style={[
                          {
                            width: 170,
                            fontSize: 15,
                            fontFamily: 'NunitoSans-Regular',
                            paddingLeft: 5,
                            height: 45
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                              // borderWidth:1,
                              // width: Dimensions.get('screen').width * 0.17,
                              height: 35,
                            }
                            : {},
                        ]}
                        clearButtonMode="while-editing"
                        placeholder=" Search"
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        onChangeText={(text) => {
                          setSearch(text);
                          // setSearch(text.trim());
                        }}
                      // value={search}
                      />
                    ) : (
                      <TextInput
                        underlineColorAndroid={Colors.whiteColor}
                        style={{
                          width: 220,
                          fontSize: 15,
                          fontFamily: 'NunitoSans-Regular',
                          paddingLeft: 5,
                          height: 45
                        }}
                        clearButtonMode="while-editing"
                        placeholder=" Search"
                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        onChangeText={(text) => {
                          setSearch(text);
                          // setSearch(text.trim());
                        }}
                      // value={search}
                      />
                    )}
                  </View>
                </View>
              </View>
            </View>
            <View
              style={[
                { marginTop: 30, marginBottom: 100, zIndex: -1 },
                switchMerchant
                  ? {
                    // borderWidth: 1,
                    width: Dimensions.get('screen').width * 0.79,
                    marginTop: 5,
                    marginBottom: Dimensions.get('screen').height * 0.23,
                  }
                  : {},
              ]}>
              <View
                style={{ width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                <View
                  style={{
                    marginLeft: '2.5%',
                    width: '8%',
                    alignItems: 'flex-start',
                  }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ marginHorizontal: 0.5 }}>
                      <TouchableOpacity
                        onPress={() => {
                          if (sort === QUEUE_SORT_FIELD_TYPE.QUEUE_ID_ASC) {
                            setSort(QUEUE_SORT_FIELD_TYPE.QUEUE_ID_DESC);
                          } else {
                            setSort(QUEUE_SORT_FIELD_TYPE.QUEUE_ID_ASC);
                          }
                        }}>
                        <Text
                          style={[
                            {
                              color: 'black',
                              fontFamily: 'NunitoSans-Regular',
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>

                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{ opacity: 0 }}>
                      <TouchableOpacity
                        onPress={() => {
                          setSort(QUEUE_SORT_FIELD_TYPE.QUEUE_ID_DESC);
                        }}>
                        {switchMerchant ? (
                          <Entypo
                            name="triangle-up"
                            size={8}
                            color={
                              sort === QUEUE_SORT_FIELD_TYPE.QUEUE_ID_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        ) : (
                          <Entypo
                            name="triangle-up"
                            size={14}
                            color={
                              sort === QUEUE_SORT_FIELD_TYPE.QUEUE_ID_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        )}
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setSort(QUEUE_SORT_FIELD_TYPE.QUEUE_ID_ASC);
                        }}>
                        {switchMerchant ? (
                          <Entypo
                            name="triangle-down"
                            size={8}
                            color={
                              sort === QUEUE_SORT_FIELD_TYPE.QUEUE_ID_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        ) : (
                          <Entypo
                            name="triangle-down"
                            size={14}
                            color={
                              sort === QUEUE_SORT_FIELD_TYPE.QUEUE_ID_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>

                {/* ///////////////////////////Name/////////////////////// */}
                <View style={{ width: '18%', alignItems: 'flex-start' }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ marginHorizontal: 0.5 }}>
                      <TouchableOpacity
                        onPress={() => {
                          if (sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_NAME_ASC) {
                            setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_NAME_DESC);
                          } else {
                            setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_NAME_ASC);
                          }
                        }}>
                        <Text
                          style={[
                            {
                              color: 'black',
                              fontFamily: 'NunitoSans-Regular',
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Name
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{}}>
                      <TouchableOpacity
                        onPress={() => {
                          setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_NAME_DESC);
                        }}>
                        {switchMerchant ? (
                          <Entypo
                            name="triangle-up"
                            size={8}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_NAME_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        ) : (
                          <Entypo
                            name="triangle-up"
                            size={14}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_NAME_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        )}
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setSort(QUEUE_SORT_FIELD_TYPE.NAME_ASC);
                        }}>
                        {switchMerchant ? (
                          <Entypo
                            name="triangle-down"
                            size={8}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_NAME_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        ) : (
                          <Entypo
                            name="triangle-down"
                            size={14}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_NAME_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>

                {/* ///////////////////////////Date Time/////////////////////// */}
                <View style={{ width: '18%', alignItems: 'flex-start' }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View
                      style={{
                        marginHorizontal: 0.5,
                        left: Platform.OS === 'android' ? '1%' : 0,
                      }}>
                      <TouchableOpacity
                        onPress={() => {
                          if (sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_ASC) {
                            setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_DESC);
                          } else {
                            setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_ASC);
                          }
                        }}>
                        <Text
                          style={[
                            {
                              color: 'black',
                              fontFamily: 'NunitoSans-Regular',
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Created Date
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{}}>
                      <TouchableOpacity
                        onPress={() => {
                          setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_ASC);
                        }}>
                        {switchMerchant ? (
                          <Entypo
                            name="triangle-up"
                            size={8}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        ) : (
                          <Entypo
                            name="triangle-up"
                            size={14}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        )}
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_ASC);
                        }}>
                        {switchMerchant ? (
                          <Entypo
                            name="triangle-down"
                            size={8}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        ) : (
                          <Entypo
                            name="triangle-down"
                            size={14}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>

                <View style={{ width: '18%', alignItems: 'flex-start' }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View
                      style={{
                        marginHorizontal: 0.5,
                        left: Platform.OS === 'android' ? '1%' : 0,
                      }}>
                      <TouchableOpacity
                        onPress={() => {
                          if (sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_ASC) {
                            setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_DESC);
                          } else {
                            setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_ASC);
                          }
                        }}>
                        <Text
                          style={[
                            {
                              color: 'black',
                              fontFamily: 'NunitoSans-Regular',
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Modified Date
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{}}>
                      <TouchableOpacity
                        onPress={() => {
                          setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_DESC);
                        }}>
                        {switchMerchant ? (
                          <Entypo
                            name="triangle-up"
                            size={8}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        ) : (
                          <Entypo
                            name="triangle-up"
                            size={14}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        )}
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_ASC);
                        }}>
                        {switchMerchant ? (
                          <Entypo
                            name="triangle-down"
                            size={8}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        ) : (
                          <Entypo
                            name="triangle-down"
                            size={14}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>

                <View style={{ width: '16%', alignItems: 'flex-start' }}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View
                      style={{
                        marginHorizontal: 0.5,
                        left: Platform.OS === 'android' ? '1%' : 0,
                      }}>
                      <TouchableOpacity
                        onPress={() => {
                          if (sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_ASC) {
                            setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_DESC);
                          } else {
                            setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_ASC);
                          }
                        }}>
                        <Text
                          style={[
                            {
                              color: 'black',
                              fontFamily: 'NunitoSans-Regular',
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Status
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={{}}>
                      <TouchableOpacity
                        onPress={() => {
                          setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_DESC);
                        }}>
                        {switchMerchant ? (
                          <Entypo
                            name="triangle-up"
                            size={8}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        ) : (
                          <Entypo
                            name="triangle-up"
                            size={14}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_ASC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        )}
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          setSort(PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_ASC);
                        }}>
                        {switchMerchant ? (
                          <Entypo
                            name="triangle-down"
                            size={8}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        ) : (
                          <Entypo
                            name="triangle-down"
                            size={14}
                            color={
                              sort === PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_DESC
                                ? Colors.secondaryColor
                                : Colors.descriptionColor
                            }></Entypo>
                        )}
                      </TouchableOpacity>
                    </View>
                  </View>
                </View>


              </View>
              <FlatList
                showsVerticalScrollIndicator={false}
                // data={sortOperationQueue(userQueues, sort).filter((item) => {
                //   if (search !== '') {
                //     const searchLowerCase = search.toLowerCase();
                //     if (
                //       item.number
                //         .toString()
                //         .toLowerCase()
                //         .includes(searchLowerCase) ||
                //       item.userName.toLowerCase().includes(searchLowerCase) ||
                //       item.createdAt
                //         .toString()
                //         .toLowerCase()
                //         .includes(searchLowerCase) ||
                //       item.pax.toString().toLowerCase().includes(searchLowerCase) ||
                //       item.updatedAt
                //         .toString()
                //         .toLowerCase()
                //         .includes(searchLowerCase) ||
                //       item.status.toLowerCase().includes(searchLowerCase)
                //     ) {
                //       return true;
                //     } else {
                //       return false;
                //     }
                //   } else {
                //     return true;
                //   }
                // })}
                data={sortPaymentDataList(outletPaymentMethods, sort).filter((item) => {
                  if (search !== '') {
                    const searchLowerCase = search.toLowerCase();
                    if (
                      item.name.toLowerCase().includes(searchLowerCase)
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return true;
                  }
                })}
                renderItem={renderPaymentMethod}
                keyExtractor={(item, index) => String(index)}
              />
            </View>
          </View>
          {/* </ScrollView> */}


          {/* add modal */}
          <Modal supportedOrientations={['landscape', 'portrait']} style={{ flex: 1 }} visible={addPaymentMethodModal} transparent={true}>
            <KeyboardAvoidingView behavior={'padding'} style={styles.modalContainer}>
              <View
                style={[
                  {
                    width: windowWidth <= 1133 ? windowWidth * 0.45 : windowWidth * 0.4,
                    Height: windowHeight * 0.47,
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 12,
                    padding: windowWidth * 0.04,
                    paddingBottom: 0,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  },
                ]}>
                <View style={[{ justifyContent: 'space-between', }, switchMerchant ? {
                  height: '100%'
                } : {}]}>
                  <View style={{}}>
                    <Text
                      style={[
                        {
                          fontSize: 24,
                          justifyContent: 'center',
                          alignSelf: 'center',
                          fontFamily: 'NunitoSans-Bold',
                        },
                        switchMerchant
                          ? {
                            fontSize: 16,
                          }
                          : {},
                      ]}>
                      {selectedPaymentMethod ? 'Payment Method Info' : 'Add Payment Method'}
                    </Text>
                    <Text
                      style={[
                        {
                          fontSize: 20,
                          justifyContent: 'center',
                          alignSelf: 'center',
                          marginTop: 5,
                          color: Colors.descriptionColor,
                          fontFamily: 'NunitoSans-Regular',
                        },
                        switchMerchant
                          ? {
                            fontSize: 10,
                          }
                          : {},
                      ]}>{`There are currently ${userQueues.length} payment methods${userQueues.length > 1 ? 's' : ''}.`}</Text>
                  </View>
                  <View style={[{ justifyContent: 'center', }, switchMerchant ? {
                    // borderWidth: 1,
                    // marginTop: '-30%'
                    bottom: Dimensions.get('screen').height * 0.11
                  } : {}]}>
                    <View
                      style={[{
                        justifyContent: 'center',
                        alignSelf: 'center',
                        alignContent: 'center',
                        marginTop: 30,
                        flexDirection: 'row',
                        width: '100%',
                      }, switchMerchant ? {
                        marginTop: '3%',
                        // borderWidth: 1
                      } : {}]}>
                      <View style={{ justifyContent: 'center', width: switchMerchant ? '80%' : '100%' }}>
                        <TouchableOpacity
                          onPress={openFileSelector}>
                          <View style={{ height: switchMerchant ? 135 : 325, backgroundColor: Colors.fieldtBgColor, }}>
                            <View style={{ zIndex: 1, borderWidth: 1, borderRadius: 5, borderColor: '#E5E5E5' }}>
                              <AsyncImage
                                //placeholder={{uri: 'https://i.imgur.com/R5TraVR.png'}}
                                //placeholderSource
                                source={{ uri: image }}
                                style={{ width: switchMerchant ? '100%' : '100%', height: switchMerchant ? 135 : 325 }}
                                hideLoading={true}
                              />
                              <View style={{
                                position: 'absolute',
                                bottom: 5,
                                right: 5,
                                //   backgroundColor: 'black',
                                //opacity: 0.5,
                                // width: 120, 
                                // height: 120,
                                // borderRadius: 13,
                              }}>

                                <Feather name='edit' size={switchMerchant ? 17 : 23} color={Colors.primaryColor}
                                //  style={{ position: 'absolute', zIndex: -1 }}
                                />

                              </View>

                            </View>
                            <Icon name='upload' size={switchMerchant ? 80 : 200} color='lightgrey' style={{ top: switchMerchant ? 27 : 40, position: 'absolute', alignSelf: 'center', zIndex: -1 }} />

                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>

                    <View
                      style={{
                        justifyContent: 'center',
                        alignSelf: 'center',
                        alignContent: 'center',
                        marginTop: switchMerchant ? 10 : 20,
                        flexDirection: 'row',
                        width: '100%',
                      }}>
                      <View style={{ justifyContent: 'center', width: '40%' }}>
                        <Text
                          style={[
                            {
                              color: 'black',
                              fontFamily: 'NunitoSans-Bold',
                              fontSize: 20,
                            }
                          ]}>
                          Payment Name
                        </Text>
                      </View>
                      <View style={{ justifyContent: 'center', width: '60%', marginLeft: 10, }}>
                        <TextInput
                          placeholder="Cash-on Delivery"
                          placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                          style={[
                            {
                              backgroundColor: Colors.fieldtBgColor,
                              width: '100%',
                              height: 40,
                              borderRadius: 5,
                              padding: 5,
                              marginVertical: 5,
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              paddingLeft: 10,
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                                width: Dimensions.get('screen').width * 0.16,
                                height: Dimensions.get('screen').height * 0.06,
                                padding: 0,
                                margin: 0,
                              }
                              : {},
                          ]}
                          onChangeText={(text) => {
                            setPaymentName(text);
                          }}
                          keyboardType={'default'}
                          defaultValue={paymentName}
                        />
                      </View>
                    </View>
                  </View>

                  <View
                    style={[{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 35,
                    }, switchMerchant ? {
                      // marginTop: Dimensions.get('screen').height * 0.067,
                      // borderWidth: 1,
                      position: 'absolute',
                      bottom: 0,
                      width: '100%'
                    } : {}]}>
                    <TouchableOpacity
                      disabled={isLoading}
                      onPress={() => {
                        // setConfirmQueueModal(true)
                        // setAddPaymentMethodModal(false)

                        if (selectedPaymentMethod.uniqueId === undefined) {
                          createPaymentMethod();
                        } else {
                          updatePaymentMethod();
                        }
                      }}
                      style={[{
                        backgroundColor: Colors.fieldtBgColor,
                        width: Dimensions.get('screen').width <= 1133 ? '56%' : '57.8%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        height: 60,
                        borderBottomLeftRadius: switchMerchant ? Dimensions.get('screen').width * 0.25 : 35,
                        borderRightWidth: StyleSheet.hairlineWidth,
                        borderTopWidth: StyleSheet.hairlineWidth,
                      }, switchMerchant ? {
                        height: 35,
                        width: '51%'
                      } : {}]}>
                      {isLoading ? (
                        <>
                          {switchMerchant ? <ActivityIndicator
                            size={'small'}
                            color={Colors.primaryColor}
                          /> : <ActivityIndicator
                            size={'large'}
                            color={Colors.primaryColor}
                          />}

                        </>
                      ) : (
                        <Text
                          style={[
                            {
                              fontSize: 22,
                              color: Colors.primaryColor,
                              fontFamily: 'NunitoSans-SemiBold',
                            },
                            switchMerchant
                              ? {
                                fontSize: 10,
                              }
                              : {},
                          ]}>
                          Confirm
                        </Text>
                      )}
                    </TouchableOpacity>
                    <TouchableOpacity
                      disabled={isLoading}
                      onPress={() => {
                        // setState({ visible: false });
                        setAddPaymentMethodModal(false);
                      }}
                      style={[{
                        backgroundColor: Colors.fieldtBgColor,
                        width: '57.8%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        height: 60,
                        borderBottomRightRadius: switchMerchant ? Dimensions.get('screen').width * 0.03 : 35,
                        borderTopWidth: StyleSheet.hairlineWidth,
                      }, switchMerchant ? {
                        height: 35,
                        width: '51%'
                      } : {}]}>
                      <Text
                        style={[
                          {
                            fontSize: 22,
                            color: Colors.descriptionColor,
                            fontFamily: 'NunitoSans-SemiBold',
                          },
                          switchMerchant
                            ? {
                              fontSize: 10,
                            }
                            : {},
                        ]}>
                        Cancel
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>

          {/* confirm modal */}
          <Modal supportedOrientations={['landscape', 'portrait']} style={{ flex: 1 }} visible={confirmQueueModal} transparent={true}>
            <View style={styles.modalContainer}>
              <View
                style={[{
                  width: Dimensions.get('screen').width * 0.4,
                  height: Dimensions.get('screen').height * 0.4,
                  backgroundColor: Colors.whiteColor,
                  borderRadius: Dimensions.get('screen').width * 0.03,
                  padding: Dimensions.get('screen').width * 0.04,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                },
                switchMerchant
                  ? {
                    padding: Dimensions.get('screen').width * 0.03,
                    height: Dimensions.get('screen').height * 0.7,
                  }
                  : {},
                ]}>
                <View style={{}}>
                  <View style={{ height: Dimensions.get('screen').height * 0.1 }}>
                    <Text
                      style={[{
                        textAlign: 'center',
                        fontWeight: '700',
                        fontSize: 30,
                      },
                      switchMerchant
                        ? {
                          fontSize: 22,
                        }
                        : {},
                      ]}>
                      Done!
                    </Text>
                  </View>

                  <View
                    style={[{
                      justifyContent: 'center',
                      alignItems: 'center',
                      alignContent: 'center',
                      alignSelf: 'center',
                    }, switchMerchant ? { marginTop: Dimensions.get('screen').height * 0.02, } : {}]}>
                    <Text
                      style={[{
                        textAlign: 'center',
                        color: Colors.descriptionColor,
                        fontSize: 25,
                        width: '80%',
                        alignSelf: 'center',
                      },
                      switchMerchant
                        ? {
                          fontSize: 16,
                        }
                        : {},
                      ]}>
                      You’ve added queue
                    </Text>
                    <Text
                      style={[{
                        textAlign: 'center',
                        color: Colors.descriptionColor,
                        fontSize: 25,
                        width: '80%',
                        alignSelf: 'center',
                      },
                      switchMerchant
                        ? {
                          fontSize: 18,
                        }
                        : {},
                      ]}>
                      successfully with number:{' '}
                      {selectedPaymentMethod ? `#${selectedPaymentMethod.number}` : 'N/A'}
                      {/* {item.number} */}
                    </Text>
                  </View>

                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: Dimensions.get('screen').height * 0.25,
                      width: Dimensions.get('screen').width * 0.4,
                    }}>
                    <TouchableOpacity
                      onPress={() => {
                        setConfirmQueueModal(false);
                      }}
                      style={[{
                        backgroundColor: Colors.fieldtBgColor,
                        width: Dimensions.get('screen').width * 0.4,
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        height: 60,
                        borderBottomLeftRadius: 35,
                        borderBottomRightRadius: 35,
                        borderTopWidth: StyleSheet.hairlineWidth,
                      }, switchMerchant ? { marginTop: Dimensions.get('screen').height * 0.23, } : {}]}>
                      <Text
                        style={[{
                          fontSize: 22,
                          color: Colors.primaryColor,
                          fontFamily: 'NunitoSans-SemiBold',
                        },
                        switchMerchant
                          ? {
                            fontSize: 16,

                          }
                          : {},
                        ]}>
                        Confirm
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </Modal>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  content7: {
    backgroundColor: '#e4e8eb',
    width: 800,
    height: 120,
    marginLeft: 50,
    marginVertical: 30,
    borderRadius: 20,
  },

  content8: {
    flex: 3,
    backgroundColor: '#e4e8eb',

    height: 120,
  },
  content9: {
    flex: 1,
    backgroundColor: Colors.primaryColor,

    height: 120,
  },
  content10: {
    flex: 1,
    backgroundColor: Colors.secondaryColor,

    height: 120,
  },
  content11: {
    flex: 1,
    backgroundColor: '#848f96',

    height: 120,
  },
  content6: {
    backgroundColor: Colors.whiteColor,
    width: 120,
    shadowColor: '#000',
    shadowOffset: {
      width: 8,
      height: 8,
    },
    shadowOpacity: 0.55,
    shadowRadius: 10.32,
    width: 120,
    height: 120,
    marginLeft: 50,
    marginVertical: 15,
    borderRadius: 5,
  },

  sidebar: {
    width: Dimensions.get('screen').width * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    width: Dimensions.get('screen').width * (1 - Styles.sideBarWidth),
    padding: 16,
  },
  headerLeftStyle: {
    width: Dimensions.get('screen').width * 0.17,
    justifyContent: 'center',
    alignItems: 'center',
  },
  submitText: {
    height:
      Platform.OS == 'ios'
        ? Dimensions.get('screen').height * 0.06
        : Dimensions.get('screen').height * 0.05,
    left: 295,
    paddingVertical: 5,
    paddingHorizontal: 20,
    flexDirection: 'row',
    color: '#4cd964',
    textAlign: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
export default SettingPaymentScreen;
