import React, { Component, useReducer, useState, useEffect, useRef } from 'react';
import {
    StyleSheet,
    ScrollView,
    Image,
    View,
    Text,
    Alert,
    TouchableOpacity,
    Dimensions,
    Switch,
    Modal,
    KeyboardAvoidingView,
    Platform,
    TextInput,
    ActivityIndicator,
    Picker,
    useWindowDimensions
} from 'react-native';
import Colors from '../constant/Colors';
import SideBar from './SideBar';
import Icon from 'react-native-vector-icons/Feather';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Entypo from 'react-native-vector-icons/Entypo';
import EvilIcons from 'react-native-vector-icons/EvilIcons';

import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";

import {
    FlatList,
} from 'react-native-gesture-handler';
import API from '../constant/API';
import ApiClient from '../util/ApiClient';
import * as User from '../util/User';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import CheckBox from 'react-native-check-box';
import moment from 'moment'
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import DatePicker from "react-datepicker";
import Styles from '../constant/Styles';

import { CSVLink } from "react-csv";

// Include the react-fusioncharts component
import FusionCharts from "react-fusioncharts";

// import { isTablet } from 'react-native-device-detection';
import { OutletStore } from '../store/outletStore';
import { MerchantStore } from '../store/merchantStore';
import { UserStore } from '../store/userStore';
// import Upload from '../assets/svg/Upload.svg'
import { ReactComponent as Download } from '../assets/svg/Download.svg'
import { ReactComponent as GCalendar } from '../assets/svg/GCalendar.svg';
// import RNFetchBlob from 'rn-fetch-blob'
import { listenToUserChangesMerchant, listenToMerchantIdChangesMerchant, listenToCurrOutletIdChangesWaiter, listenToAllOutletsChangesMerchant, listenToCommonChangesMerchant, listenToSelectedOutletItemChanges, convertArrayToCSV, listenToSelectedOutletTableIdChanges, requestNotificationsPermission, sortReportDataList, generateEmailReport } from '../util/common';
import { filterChartItems, getDataForChartReportProductSales } from '../util/chart';
import { CHART_DATA, CHART_TYPE, FS_LIBRARY_PATH, CHART_Y_AXIS_DROPDOWN_LIST, CHART_FIELD_COMPARE_DROPDOWN_LIST, CHART_FIELD_NAME_DROPDOWN_LIST, CHART_FIELD_TYPE, CHART_FIELD_COMPARE_DICT, CHART_PERIOD, CHART_X_AXIS_DROPDOWN_LIST } from '../constant/chart';
import { EMAIL_REPORT_TYPE, REPORT_SORT_FIELD_TYPE, TABLE_PAGE_SIZE_DROPDOWN_LIST, ORDER_TYPE } from '../constant/common';
import XLSX from 'xlsx';
import 'react-native-get-random-values';
import Feather from 'react-native-vector-icons/Feather';
// import { useKeyboard } from '../hooks';
import { CommonStore } from '../store/commonStore';
import AsyncImage from '../components/asyncImage';

const { nanoid } = require('nanoid');
// import { v4 as uuidv4 } from 'uuid';
// const RNFS = require('react-native-fs');
// import DropDownPicker from 'react-native-dropdown-picker';
// import RNPickerSelect from 'react-native-picker-select';


const ReportSalesProduct = props => {
    const {
        navigation,
    } = props;

    setInterval(() => {
        const elements = document.querySelectorAll('[fill="#b1b2b7"]');

        if (elements && elements.length > 0) {
            for (var i = 0; i < elements.length; i++) {
                elements[i].style.display = 'none';
            }
        }
    }, 1000);

    const {
        width: windowWidth,
        height: windowHeight,
    } = useWindowDimensions();

    // const [keyboardHeight] = useKeyboard();
    const [list, setList] = useState([]);
    const [page, setPage] = useState(0);
    const [name, setName] = useState('Product');
    const [visible, setVisible] = useState(false);
    const [visible1, setVisible1] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [isChecked1, setIsChecked1] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [oriList, setOriList] = useState([]);
    const [offset, setOffset] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [detailsPageCount, setDetailsPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentDetailsPage, setCurrentDetailsPage] = useState(1);
    const [pageReturn, setPageReturn] = useState(1);
    const [day, setDay] = useState(false);
    const [pick, setPick] = useState(null);
    const [pick1, setPick1] = useState(null);
    const [search, setSearch] = useState('');
    const [lists, setLists] = useState([]);
    const [list1, setList1] = useState(true);
    const [searchList, setSearchList] = useState(false);

    const [pushPagingToTop, setPushPagingToTop] = useState(false);

    const [loading, setLoading] = useState(false);
    const [switchMerchant, setSwitchMerchant] = useState(false);
    const [showDateTimePicker, setShowDateTimePicker] = useState(false);
    const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
    const [showDateTimePickerFilter, setShowDateTimePickerFilter] = useState(false);
    const [pickerMode, setPickerMode] = useState('datetime');
    const [rev_date, setRev_date] = useState(moment().subtract(6, 'days').startOf('day'));
    const [rev_date1, setRev_date1] = useState(moment().endOf(Date.now()).endOf('day'));

    const [productSales, setProductSales] = useState([]);
    const [productSalesSku, setProductSalesSku] = useState([]);

    const [productSalesChart, setProductSalesChart] = useState({

    });

    const [filteredOutletItems, setFilteredOutletItems] = useState([]);

    const [selectedItemSummary, setSelectedItemSummary] = useState({});

    const [expandDetailsDict, setExpandDetailsDict] = useState({});
    const crmUsers = OutletStore.useState((s) => s.crmUsers);

    const allOutletsUserOrdersDone = OutletStore.useState(s => s.allOutletsUserOrdersDone);
    const outletItems = OutletStore.useState(s => s.outletItems);
    const outletCategories = OutletStore.useState(s => s.outletCategories);
    const outletCategoriesDict = OutletStore.useState(s => s.outletCategoriesDict);

    const currOutletId = MerchantStore.useState(s => s.currOutletId);
    const allOutlets = MerchantStore.useState(s => s.allOutlets);

    const userName = UserStore.useState(s => s.name);
    const merchantName = MerchantStore.useState(s => s.name);

    const [exportEmail, setExportEmail] = useState('');
    const [CsvData, setCsvData] = useState([]);

    const [showDetails, setShowDetails] = useState(false);
    const [productSalesDetails, setProductSalesDetails] = useState([]);

    const [exportModalVisibility, setExportModalVisibility] = useState(false);
    const [detailsTitle, setDetailsTitle] = useState("");

    const [expandSelection, setExpandSelection] = useState(props.expandSelection === undefined ? false : props.expandSelection);
    const [filterTapped, setFilterTapped] = useState(props.threeDotsTapped === undefined ? 0 : props.threeDotsTapped);

    const [expandGroupBy, setExpandGroupBy] = useState(props.expandGroupBy === undefined ? false : props.expandGroupBy);
    const [groupByTapped, setGroupByTapped] = useState(props.threeDotsTapped === undefined ? 0 : props.threeDotsTapped);

    const [selectedChartDropdownValue, setSelectedChartDropdownValue] = useState(CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES][0].value);
    const [selectedChartFilterQueries, setSelectedChartFilterQueries] = useState([
        {
            fieldNameKey: CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES][0].value,
            fieldNameType: CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES][0].fieldType,
            fieldCompare: CHART_FIELD_COMPARE_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES][0].value,
            fieldDataValue: null,
            fieldSpecial: CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES][0].special,
        },
    ]);
    const [salesBarChartPeriod, setSalesBarChartPeriod] = useState(CHART_PERIOD.THIS_WEEK);

    const [selectedChartDropdownValueX, setSelectedChartDropdownValueX] = useState(CHART_X_AXIS_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES][0].value);

    const [appliedChartFilterQueries, setAppliedChartFilterQueries] = useState([]);

    const [currReportSummarySort, setCurrReportSummarySort] = useState('');
    const [currReportDetailsSort, setCurrReportDetailsSort] = useState('');

    const currPageStack = CommonStore.useState(s => s.currPageStack);
    const pageStack = CommonStore.useState(s => s.pageStack);

    const merchantId = UserStore.useState(s => s.merchantId);
    const isLoading = CommonStore.useState(s => s.isLoading);
    const [isCsv, setIsCsv] = useState(false);
    const [isExcel, setIsExcel] = useState(false);

    const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);

    ////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        // const result = getDataForChartReportProductSales(allOutlets, allOutletsUserOrdersDone, rev_date, rev_date1);
        const result = getDataForChartReportProductSales(productSalesSku, filteredOutletItems, allOutlets, rev_date, rev_date1, selectedChartDropdownValue, selectedChartDropdownValueX);

        setProductSalesChart(result.chartData);
    }, [
        // allOutlets, 
        // allOutletsUserOrdersDone, 
        productSalesSku,
        filteredOutletItems,
        rev_date,
        rev_date1,
        selectedChartDropdownValue,
        selectedChartDropdownValueX,
    ]);

    useEffect(() => {
        if (
            currOutletId !== '' &&
            allOutlets.length > 0 &&
            outletCategories.length > 0 &&
            outletItems.length > 0 &&
            Object.keys(outletCategoriesDict).length > 0
        ) {
            //   setHistoryOrders(allOutletsUserOrdersDone.filter(order => order.outletId === currOutletId));

            console.log('outletCategoriesDict');
            console.log(outletCategoriesDict);

            console.log('report outlet items');

            var productSalesDict = {};
            var productSalesSkuDict = {};

            var processedCartItemDict = {};

            var filteredOutletItemsTemp = [];

            for (var i = 0; i < outletItems.length; i++) {
                console.log(outletItems[i].categoryId);
                console.log(outletCategoriesDict[outletItems[i].categoryId]);

                if (outletCategoriesDict[outletItems[i].categoryId]) {
                    var productSaleRecord = {
                        productId: outletItems[i].uniqueId,
                        summaryId: nanoid(),
                        productName: outletItems[i].name,
                        productCategory:
                            outletCategoriesDict[outletItems[i].categoryId].name,
                        // productCategory: '',
                        // productSku: outletItems[i].sku,
                        productSku: outletItems[i].skuMerchant || 'N/A',
                        totalItems: 0,
                        totalSales: 0,
                        totalSalesReturn: 0,
                        totalDiscount: 0,
                        discount: 0,
                        itemNetSales: 0,
                        detailsList: [],
                    };

                    productSalesDict[outletItems[i].uniqueId] = productSaleRecord;
                    productSalesSkuDict[outletItems[i].name] = productSaleRecord; // change to sku in future
                }
            }

            for (var i = 0; i < allOutletsUserOrdersDone.length; i++) {

                if (
                    (moment(rev_date).isSameOrBefore(
                        allOutletsUserOrdersDone[i].completedDate,
                    ) &&
                        moment(rev_date1).isAfter(
                            allOutletsUserOrdersDone[i].completedDate,
                        ))
                ) {
                    /////////////////////////////////////////
                    // apply filter here

                    const isValid = filterChartItems(
                        allOutletsUserOrdersDone[i],
                        appliedChartFilterQueries,
                    );

                    /////////////////////////////////////////

                    if (
                        isValid &&
                        allOutletsUserOrdersDone[i].outletId === currOutletId &&
                        ((moment(rev_date).isSameOrBefore(
                            allOutletsUserOrdersDone[i].completedDate,
                        ) &&
                            moment(rev_date1).isAfter(
                                allOutletsUserOrdersDone[i].completedDate,
                            )) ||
                            test)
                    ) {
                        for (
                            var j = 0;
                            j < allOutletsUserOrdersDone[i].cartItems.length;
                            j++
                        ) {
                            const cartItem = allOutletsUserOrdersDone[i].cartItems[j];

                            if (productSalesDict[cartItem.itemId]) {
                                // productSalesDict[cartItem.itemId].detailsList.push(allOutletsUserOrdersDone[i]);

                                if (
                                    // !productSalesDict[cartItem.itemId].detailsList.find(
                                    //   (order) =>
                                    //     order.uniqueId === allOutletsUserOrdersDone[i].uniqueId,
                                    // )
                                    !processedCartItemDict[cartItem.itemId + cartItem.cartItemDate]
                                ) {

                                    // add if not existed

                                    processedCartItemDict[cartItem.itemId + cartItem.cartItemDate] = true;

                                    productSalesDict[cartItem.itemId].totalItems +=
                                        cartItem.quantity;
                                    productSalesDict[cartItem.itemId].totalSales +=
                                        cartItem.price;
                                    // productSalesDict[cartItem.itemId].totalSalesReturn += cartItem.price * cartItem.quantity;
                                    productSalesDict[cartItem.itemId].itemNetSales +=
                                        cartItem.price;

                                    // need change to sku in future
                                    // productSalesSkuDict[cartItem.itemName].totalItems += cartItem.price * cartItem.quantity;
                                    // productSalesSkuDict[cartItem.itemName].totalSales += cartItem.price;
                                    // productSalesSkuDict[cartItem.itemName].totalSalesReturn += cartItem.price * cartItem.quantity;
                                    // productSalesSkuDict[cartItem.itemName].itemNetSales += cartItem.price * cartItem.quantity;

                                    filteredOutletItemsTemp.push({
                                        ...cartItem,
                                        orderCompletedDate:
                                            allOutletsUserOrdersDone[i].completedDate,
                                        outletId: allOutletsUserOrdersDone[i].outletId,

                                        totalItems: cartItem.quantity,
                                        totalSales: cartItem.price,
                                        // totalSalesReturn: cartItem.price * cartItem.quantity,
                                        itemNetSales: cartItem.price,
                                        discount: 0,
                                        totalDiscount: 0,
                                    });

                                    productSalesDict[cartItem.itemId].detailsList.push({
                                        ...allOutletsUserOrdersDone[i],
                                        discountPercentage: parseFloat(
                                            isFinite(
                                                allOutletsUserOrdersDone[i].discount /
                                                allOutletsUserOrdersDone[i].finalPrice,
                                            )
                                                ? (allOutletsUserOrdersDone[i].discount /
                                                    allOutletsUserOrdersDone[i].finalPrice) *
                                                100
                                                : 0,
                                        ),
                                    });
                                }
                            }
                        }

                        if (allOutletsUserOrdersDone[i].cartItemsCancelled) {
                            for (
                                var k = 0;
                                k < allOutletsUserOrdersDone[i].cartItemsCancelled.length;
                                k++
                            ) {
                                const cartItem =
                                    allOutletsUserOrdersDone[i].cartItemsCancelled[k];

                                if (productSalesDict[cartItem.itemId]) {
                                    productSalesDict[cartItem.itemId].totalSalesReturn +=
                                        cartItem.price;
                                }
                            }
                        }
                    }
                }
            }

            const productSalesTemp = Object.entries(productSalesDict).map(
                ([key, value]) => ({ ...value }),
            );
            const productSalesSkuTemp = Object.entries(productSalesSkuDict).map(
                ([key, value]) => ({ ...value }),
            );

            setProductSales(productSalesTemp);
            setProductSalesSku(productSalesSkuTemp);

            //setCurrentPage(1);
            setPageCount(Math.ceil(productSalesTemp.length / perPage));

            setFilteredOutletItems(filteredOutletItemsTemp);

            setShowDetails(false);
        }
    }, [
        allOutletsUserOrdersDone,
        outletItems,
        outletCategoriesDict,
        outletCategories,
        currOutletId,
        allOutlets,
        rev_date,
        rev_date1,
        appliedChartFilterQueries,
        perPage,
    ]);

    useEffect(() => {
        if (showDetails && selectedItemSummary.detailsList) {
            setProductSalesDetails(selectedItemSummary.detailsList);

            setPageReturn(currentPage)
            console.log('currentPage value is')
            console.log(currentPage)
            setCurrentDetailsPage(1)
            setDetailsPageCount(Math.ceil(selectedItemSummary.detailsList.length / perPage));
        }
    }, [
        showDetails,
        selectedItemSummary,
        perPage
    ]);

    const setState = () => { };

    // navigation.dangerouslyGetParent().setOptions({
    //     tabBarVisible: false,
    // });

    useEffect(() => {
        var title = 'Product Sales Report';

        if (pageStack.length > 1 && pageStack[pageStack.length - 2] === 'Dashboard') {
            title = 'Today Product Sales Report';

            setSalesBarChartPeriod(CHART_PERIOD.TODAY);
            setRev_date(moment().startOf('day'));
            setRev_date1(moment().endOf('day'));
        }
        else {
            title = 'Product Sales Report';

            setSalesBarChartPeriod(CHART_PERIOD.THIS_MONTH);
            setRev_date(moment().subtract(1, 'month').startOf('day'));
            setRev_date1(moment().endOf('day'));
        }

        navigation.setOptions({
            headerLeft: () => (
                <View style={{
                    width: windowWidth * 0.17,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Image
                        style={{
                            width: 124,
                            height: 26,
                        }}
                        resizeMode="contain"
                        source={require('../assets/image/logo.png')}
                    />
                </View>
            ),
            headerTitle: () => (
                <View style={[{
                    justifyContent: 'center',
                    alignItems: 'center',
                    // bottom: -2,
                    bottom: switchMerchant ? '2%' : 0,
                }, windowWidth >= 768 && switchMerchant ? { right: windowWidth * 0.1 } : {}, windowWidth <= 768 ? { right: windowWidth * 0.12 } : {}]}>
                    <Text
                        style={{
                            fontSize: switchMerchant ? 20 : 24,
                            // lineHeight: 25,
                            textAlign: 'center',
                            fontFamily: 'NunitoSans-Bold',
                            color: Colors.whiteColor,
                            opacity: 1,
                        }}>
                        Product Sales Report
                    </Text>
                </View>
            ),
            headerRight: () => (
                <View style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                    {outletSelectDropdownView()}
                    <View style={{
                        backgroundColor: 'white',
                        width: 0.5,
                        height: windowHeight * 0.025,
                        opacity: 0.8,
                        marginHorizontal: 15,
                        bottom: -1,
                    }}>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('Setting')}
                        style={{ flexDirection: 'row', alignItems: 'center' }}
                    >
                        <Text
                            style={{
                                fontFamily: 'NunitoSans-SemiBold',
                                fontSize: 16,
                                color: Colors.secondaryColor,
                                marginRight: 15,
                            }}>
                            {userName}
                        </Text>
                        <View style={{
                            marginRight: 30,
                            width: windowHeight * 0.05,
                            height: windowHeight * 0.05,
                            borderRadius: windowHeight * 0.05 * 0.5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                        }}>
                            <Image style={{
                                width: windowHeight * 0.035,
                                height: windowHeight * 0.035,
                                alignSelf: 'center',
                            }} source={require('../assets/image/profile-pic.jpg')} />
                        </View>
                    </TouchableOpacity>
                </View>
            ),
        });
    }, [pageStack]);

    // componentDidMount = () => {
    //     moment()
    // }

    /* const email = () => {
        var body = {
            email: 'wongcheehoo@yahoo.com',
            data: list
        }
        ApiClient.POST(API.emailReportPdf, body, false).then((result) => {
            try {
                if (result != null) {
                    Alert.alert(
                        'Congratulation!',
                        'You Have Successfull',
                        [
                            {
                                text: 'OK',
                                onPress: () => { setState({ visible1: false }) },
                            },
                        ],
                        { cancelable: false },
                    );
                }
            } catch (error) {
                Alert.alert('Failed', [{ text: 'OK', onPress: () => { } }], {
                    cancelable: false,
                });
            }
        })
    } */

    /* const download = () => {
        var body = {
            data: list
        }
        ApiClient.POST(API.generateReportPDF, body, false).then((result) => {
            try {
                if (result != null) {
                    Alert.alert(
                        'Congratulation!',
                        'You Have Successfull',
                        [
                            {
                                text: 'OK',
                                onPress: () => { setState({ visible: false }) },
                            },
                        ],
                        { cancelable: false },
                    );
                }
            } catch (error) {
                Alert.alert('Failed', [{ text: 'OK', onPress: () => { } }], {
                    cancelable: false,
                });
            }
        })
    } */

    // const add = async () => {
    //     if (page + 1 < pageCount) {
    //         await setState({ page: page + 1, currentPage: currentPage + 1 })
    //         console.log(page)
    //         var e = page
    //         next(e)
    //     }
    // }

    // const next = (e) => {
    //     const offset = e * perPage;
    //     setState({ offset: offset })
    //     loadMoreData()
    // }

    // const less = async () => {
    //     if (page > 0) {
    //         await setState({ page: page - 1, currentPage: currentPage - 1 })
    //         console.log(page)
    //         var y = page
    //         pre(y)
    //     }
    // }

    // const pre = (y) => {

    //     const offset = y * perPage;
    //     setState({ offset: offset })
    //     loadMoreData()

    // }

    const nextPage = () => {
        setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
    };

    const prevPage = () => {
        setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
    };

    const nextDetailsPage = () => {
        setCurrentDetailsPage(currentDetailsPage + 1 > detailsPageCount ? currentDetailsPage : currentDetailsPage + 1);
    };

    const prevDetailsPage = () => {
        setCurrentDetailsPage(currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1);
    };

    const loadMoreData = () => {
        const data = oriList;
        const slice = data.slice(offset, offset + perPage)
        setState({ list: slice, pageCount: Math.ceil(data.length / perPage) })
    }

    // moment = async () => {
    //     const today = new Date();
    //     const day = new Date(today.getTime() - (7 * 24 * 60 * 60 * 1000));
    //     await setState({ startDate: moment(day).format('YYYY-MM-DD'), endDate: moment(today).format('YYYY-MM-DD') })
    //     getDetail()
    // }

    const getDetail = () => {
        ApiClient.GET(API.getSalesBySku + 1 + '&startDate=' + startDate + '&endDate=' + endDate).then((result) => {
            var data = result
            var slice = data.slice(offset, offset + perPage)
            setState({ list: slice, oriList: data, pageCount: Math.ceil(data.length / perPage) })
        });
    }

    const decimal = (value) => {
        return value.toFixed(2);
    }

    const renderSearchItem = ({ item, index }) => (
        (index + 1) % 2 == 0 ? <View style={{ backgroundColor: Colors.whiteColor, padding: 12 }}>
            <View style={{ flexDirection: 'row', }}>
                <Text style={{ flex: 3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.productName}</Text>
                <Text style={{ flex: 3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.category}</Text>
                <View style={{ flex: 3 }}>
                    <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.productName}</Text>
                    <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>-</Text>
                </View>
                <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.quantity}</Text>
                <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.totalPrice}</Text>
                <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>0.00</Text>
                <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.totalDiscount}</Text>
                <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.discount}</Text>
                <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.netSale}</Text>
            </View>
        </View>
            : <View style={{ backgroundColor: Colors.fieldtBgColor, padding: 12 }}>
                <View style={{ flexDirection: 'row', }}>
                    <Text style={{ flex: 3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.productName}</Text>
                    <Text style={{ flex: 3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.category}</Text>
                    <View style={{ flex: 3 }}>
                        <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.productName}</Text>
                        <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>-</Text>
                    </View>
                    <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.quantity}</Text>
                    <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.totalPrice}</Text>
                    <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>0.00 ({item.totalDiscount}%)</Text>
                    {/* <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.totalDiscount}</Text> */}
                    <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.discount}</Text>
                    <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.netSale}</Text>
                </View>
            </View>
    )

    const onItemSummaryClicked = item => {
        // setProductSalesDetails(item.detailsList);
        setSelectedItemSummary(item);
        setShowDetails(true);

        // setCurrentPage(1);
        // setPageCount(Math.ceil(item.detailsList.length / perPage));

        console.log('item.detailsList');
        console.log(item.detailsList);
    };

    const renderItem = ({ item, index }) => (
        // (index + 1) % 2 == 0 ? 
        <TouchableOpacity onPress={() => {
            onItemSummaryClicked(item)
            setDetailsTitle(item.productName)
        }}
            style={{
                backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
                paddingVertical: 10,
                //paddingHorizontal: 3,
                //paddingLeft: 1,
                borderColor: '#BDBDBD',
                borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                // width: '100%',
            }}>
            {/* <View style={{ backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.fieldtBgColor, padding: 12 }}> */}
            <View style={{ flexDirection: 'row', }}>
                <Text style={{ width: '5%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{index + 1}</Text>
                <Text style={{ width: '19%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.productName}</Text>
                <Text style={{ width: '11%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.productCategory}</Text>
                <Text style={{ width: '9%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.productSku}</Text>
                <Text style={{ width: '9%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.totalItems}</Text>
                <Text style={{ width: '9%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{(item.totalSales).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                {/* <Text style={{ flex: 1.6, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{(item.totalSalesReturn).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text> */}
                <Text style={{ width: '8%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{(item.totalDiscount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                <Text style={{ width: '8%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.discount}</Text>
                <Text style={{ width: '11%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>FPX</Text>
                {/* <Text style={{ flex: 1.6, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.discount}</Text> */}
                {/* <Text style={{ flex: 1.6, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{(item.itemNetSales).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text> */}
                <Text style={{ width: '11%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'right', paddingRight: 20 }}>{(Math.ceil(item.itemNetSales * 20 - 0.05) / 20).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
            </View>
        </TouchableOpacity>
        // : <View style={{ backgroundColor: Colors.fieldtBgColor, padding: 12 }}>
        //     <View style={{ flexDirection: 'row', }}>
        //         <Text style={{ flex: 3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.productName}</Text>
        //         <Text style={{ flex: 3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.category}</Text>
        //         <View style={{ flex: 3 }}>
        //             <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.productName}</Text>
        //             <Text style={{ fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>-</Text>
        //         </View>
        //         <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.quantity}</Text>
        //         <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.totalPrice}</Text>
        //         <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>0.00</Text>
        //         <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.totalDiscount}</Text>
        //         <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.discount}</Text>
        //         <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.netSale}</Text>
        //     </View>
        // </View>
    )

    const onClickItemDetails = (item) => {
        setExpandDetailsDict({
            ...expandDetailsDict,
            [item.uniqueId]: expandDetailsDict[item.uniqueId] ? false : true,
        });
    };

    const renderItemDetails = ({ item, index }) => {
        ///////////////////////////

        console.log('order id');
        console.log(item.orderId);

        // calculate longest

        var longestStr = 5;

        // for (var i = 0; i < item.cartItems.length; i++) {
        //     const cartItemPriceWIthoutAddOn =
        //         item.cartItems[i].price -
        //         item.cartItems[i].addOns.reduce(
        //             (accum, addOn) => accum + addOn.prices[0],
        //             0,
        //         );

        //     if (cartItemPriceWIthoutAddOn.toFixed(0).length > longestStr) {
        //         longestStr = cartItemPriceWIthoutAddOn.toFixed(0).length;
        //     }

        //     for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
        //         if (
        //             item.cartItems[i].addOns[j].prices[0].toFixed(0).length > longestStr
        //         ) {
        //             longestStr = item.cartItems[i].addOns[j].prices[0].toFixed(0).length;
        //         }
        //     }
        // }

        // if (item.totalPrice.toFixed(0).length > longestStr) {
        //     longestStr = item.totalPrice.toFixed(0).length;
        // }

        // if (item.discount.toFixed(0).length > longestStr) {
        //     longestStr = item.discount.toFixed(0).length;
        // }

        // if (item.tax.toFixed(0).length > longestStr) {
        //     longestStr = item.tax.toFixed(0).length;
        // }

        // if (item.finalPrice.toFixed(0).length > longestStr) {
        //     longestStr = item.finalPrice.toFixed(0).length;
        // }

        // console.log(longestStr);

        ///////////////////////////

        // calculate spacing

        var cartItemPriceWIthoutAddOnSpacingList = [];
        var addOnsSpacingList = [];

        for (var i = 0; i < item.cartItems.length; i++) {
            const cartItemPriceWIthoutAddOn =
                item.cartItems[i].price -
                item.cartItems[i].addOns.reduce(
                    (accum, addOn) => accum + addOn.prices[0],
                    0,
                );

            cartItemPriceWIthoutAddOnSpacingList.push(
                Math.max(longestStr - cartItemPriceWIthoutAddOn.toFixed(0).length, 0) +
                1,
            );

            for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
                addOnsSpacingList.push(
                    Math.max(
                        longestStr -
                        item.cartItems[i].addOns[j].prices[0].toFixed(0).length,
                        0,
                    ) + 1,
                );
            }
        }

        var totalPriceSpacing =
            Math.max(longestStr - item.totalPrice.toFixed(0).length, 0) + 1;
        var discountSpacing =
            Math.max(longestStr - item.discount.toFixed(0).length, 0) + 1;
        var taxSpacing = Math.max(longestStr - item.tax.toFixed(0).length, 0) + 1;
        var finalPriceSpacing =
            Math.max(longestStr - item.finalPrice.toFixed(0).length, 0) + 1;

        ///////////////////////////
        return (
            <TouchableOpacity onPress={() => onClickItemDetails(item)} style={{
                backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
                paddingVertical: 10,
                //paddingHorizontal: 3,
                //paddingLeft: 1,
                borderColor: '#BDBDBD',
                borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
            }}>
                <View style={{ flexDirection: 'row', }}>
                    <Text style={{ width: '6%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{index + 1}</Text>
                    <Text style={{ width: '16%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{item.orderId ? `#${item.orderId}` : 'N/A'}</Text>
                    <Text style={{ width: '20%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{moment(item.createdAt).format('DD MMM YY hh:mm A')}</Text>
                    <Text style={{ width: '11%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{parseFloat(item.totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                    <Text style={{ width: '12%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{parseFloat(item.discount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}({parseFloat(item.discountPercentage).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')})</Text>
                    {/* <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{parseFloat(item.discountPercentage).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text> */}
                    <Text style={{ width: '8%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10 }}>{parseFloat(item.tax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                    <Text style={{ width: '13%', fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', textAlign: 'left', paddingLeft: 10, }}>{parseFloat(item.sc || 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</Text>
                    {/* <Text style={{ flex: 1, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{parseFloat(0).toFixed(2)}</Text> */}
                    {/* <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{parseFloat(item.finalPrice).toFixed(2)}</Text> */}
                    <View style={{ width: '14%', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: 10, paddingRight: switchMerchant ? '2.05%' : '1.75%' }}>
                        <Text style={{}} />
                        <Text style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Regular', }}>
                            {/* <Text style={{
                            opacity: 0,
                            ...Platform.OS === 'android' && {
                                color: 'transparent',
                            },
                        }}>
                            {'0'.repeat((finalPriceSpacing * 0.6) + (item.finalPrice.toFixed(0).length === 1 ? 1 : 0))}
                        </Text> */}
                            {(Math.ceil(item.finalPrice * 20 - 0.05) / 20).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,',)}
                        </Text>
                    </View>
                    {/* <Text style={{ flex: 3, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{parseFloat(item.totalPrice).toFixed(2)}</Text> */}
                </View>

                {expandDetailsDict[item.uniqueId] == true ? (
                    <View
                        style={{
                            minheight: windowHeight * 0.35,
                            marginTop: 30,
                            paddingBottom: 20,
                        }}>
                        {item.cartItems.map((cartItem, index) => {
                            const cartItemPriceWIthoutAddOn =
                                cartItem.price -
                                cartItem.addOns.reduce(
                                    (accum, addOn) => accum + addOn.prices[0],
                                    0,
                                );

                            return (
                                <View
                                    style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    <View
                                        style={{
                                            width: '100%',
                                            alignItems: 'flex-start',
                                            flexDirection: 'row',
                                            marginBottom: Platform.OS == 'ios' ? 10 : 10,
                                            minHeight: 80,
                                            //backgroundColor: 'yellow',
                                        }}>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                width: '100%',
                                                //backgroundColor: 'blue',
                                            }}>
                                            {index == 0 ? (
                                                <View
                                                    style={{
                                                        marginHorizontal: 1,
                                                        width: Platform.OS == 'ios' ? '8%' : '8%',
                                                        //justifyContent: 'center',
                                                        alignItems: 'center',
                                                        //backgroundColor: 'blue',
                                                    }}>
                                                    <TouchableOpacity
                                                        style={{
                                                            alignItems: 'center',
                                                            marginTop: 0,
                                                        }}
                                                        onPress={() => {
                                                            var crmUser = null;

                                                            if (item.crmUserId !== undefined) {
                                                                for (
                                                                    var i = 0;
                                                                    i < crmUsers.length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        item.crmUserId ===
                                                                        crmUsers[i].uniqueId
                                                                    ) {
                                                                        crmUser = crmUsers[i];
                                                                        break;
                                                                    }
                                                                }
                                                            }

                                                            if (!crmUser) {
                                                                for (
                                                                    var i = 0;
                                                                    i < crmUsers.length;
                                                                    i++
                                                                ) {
                                                                    if (
                                                                        item.userId ===
                                                                        crmUsers[i].firebaseUid
                                                                    ) {
                                                                        crmUser = crmUsers[i];
                                                                        break;
                                                                    }
                                                                }
                                                            }

                                                            if (crmUser) {
                                                                CommonStore.update(
                                                                    (s) => {
                                                                        s.selectedCustomerEdit = crmUser;
                                                                        // s.selectedCustomerEdit = userReservations[item.userId] && crmUsers[item.userId] ? crmUsers[item.userId] : null ;

                                                                        s.routeParams = {
                                                                            pageFrom: 'Reservation',
                                                                        };
                                                                    },
                                                                    () => {
                                                                        navigation.navigate('NewCustomer');
                                                                    },
                                                                );
                                                            }
                                                        }}>
                                                        <Image
                                                            style={{
                                                                width: switchMerchant
                                                                    ? 30
                                                                    : 60,
                                                                height: switchMerchant
                                                                    ? 30
                                                                    : 60,
                                                            }}
                                                            resizeMode="contain"
                                                            source={require('../assets/image/default-profile.png')}
                                                        />

                                                        <View
                                                            style={{
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}>
                                                            <Text
                                                                style={[
                                                                    {
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                        marginTop: 0,
                                                                        fontSize: 13,
                                                                        textAlign: 'center',
                                                                    },
                                                                    switchMerchant
                                                                        ? {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            marginTop: 0,
                                                                            fontSize: 10,
                                                                            textAlign: 'center',
                                                                        }
                                                                        : {},
                                                                ]}
                                                                numberOfLines={1}>
                                                                {item.userName ? item.userName : 'Guest'}
                                                            </Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </View>
                                            ) : (
                                                <View
                                                    style={{
                                                        marginHorizontal: 1,
                                                        width: Platform.OS == 'ios' ? '8%' : '8%',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                />
                                            )}

                                            <View
                                                style={{
                                                    // flex: 0.3,
                                                    width: '5%',
                                                    //justifyContent: 'center',
                                                    alignItems: 'center',
                                                    //backgroundColor: 'red',
                                                    //paddingLeft: '1.2%',
                                                }}>
                                                <Text
                                                    style={[
                                                        {
                                                            fontFamily: 'NunitoSans-Bold',
                                                            fontSize: 13,
                                                        },
                                                        switchMerchant
                                                            ? {
                                                                fontFamily: 'NunitoSans-Bold',
                                                                fontSize: 10,
                                                            }
                                                            : {},
                                                    ]}>
                                                    {index + 1}.
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    //flex: 0.5,
                                                    width: '10%',
                                                    //backgroundColor: 'green',
                                                    alignItems: 'center',
                                                }}>
                                                <AsyncImage
                                                    source={{ uri: cartItem.image }}
                                                    item={cartItem}
                                                    style={{
                                                        width: switchMerchant
                                                            ? 30
                                                            : 60,
                                                        height: switchMerchant
                                                            ? 30
                                                            : 60,
                                                        borderWidth: 1,
                                                        borderColor: '#E5E5E5',
                                                        borderRadius: 5,
                                                    }}
                                                />
                                            </View>
                                            <View style={{ width: '75%' }}>
                                                <View
                                                    style={{
                                                        marginLeft: Platform.OS == 'ios' ? 14 : 14,
                                                        marginBottom: 10,
                                                        //backgroundColor: 'blue',
                                                        width: '100%',
                                                        flexDirection: 'row',
                                                    }}>
                                                    <View style={{ width: '69%' }}>
                                                        <Text
                                                            style={[
                                                                {
                                                                    fontFamily: 'NunitoSans-Bold',
                                                                    fontSize: 13,
                                                                },
                                                                switchMerchant
                                                                    ? {
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                        fontSize: 10,
                                                                    }
                                                                    : {},
                                                            ]}>
                                                            {cartItem.name}
                                                        </Text>
                                                    </View>

                                                    <View
                                                        style={{
                                                            width: '13%',
                                                        }}>
                                                        <View
                                                            style={{
                                                                alignItems: 'center',
                                                                //backgroundColor: 'yellow',
                                                            }}>
                                                            <Text
                                                                style={[
                                                                    {
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                        fontSize: 13,
                                                                    },
                                                                    // Platform.OS === 'android'
                                                                    //   ? {
                                                                    //       width: '200%',
                                                                    //     }
                                                                    //   : {},
                                                                    switchMerchant
                                                                        ? {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 10,
                                                                        }
                                                                        : {},
                                                                ]}>
                                                                x{cartItem.quantity}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            justifyContent: 'space-between',
                                                            width: '18.8%',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10,
                                                                    }
                                                                    : { fontSize: 13 }
                                                            }>
                                                            RM
                                                        </Text>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                    }
                                                                    : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                            }>
                                                            {cartItemPriceWIthoutAddOn
                                                                .toFixed(2)
                                                                .replace(
                                                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                                                    '$1,',
                                                                )}
                                                        </Text>
                                                    </View>
                                                </View>

                                                {cartItem.remarks &&
                                                    cartItem.remarks.length > 0 ? (
                                                    <View
                                                        style={{
                                                            alignItems: 'center',
                                                            flexDirection: 'row',
                                                            marginLeft: Platform.OS == 'ios' ? 14 : 14,
                                                        }}>
                                                        <View style={{ justifyContent: 'center' }}>
                                                            <Text
                                                                style={[
                                                                    {
                                                                        fontFamily: 'NunitoSans-SemiBold',
                                                                        fontSize: 13,
                                                                    },
                                                                    switchMerchant
                                                                        ? {
                                                                            fontFamily: 'NunitoSans-SemiBold',
                                                                            fontSize: 10,
                                                                        }
                                                                        : {},
                                                                ]}>
                                                                {cartItem.remarks}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                ) : (
                                                    <></>
                                                )}

                                                {cartItem.addOns.map((addOnChoice, i) => {
                                                    return (
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                // marginLeft: -5,
                                                                width: '100%',
                                                            }}>
                                                            <View
                                                                style={{
                                                                    width: '69%',
                                                                    flexDirection: 'row',
                                                                    marginLeft:
                                                                        Platform.OS == 'ios' ? 14 : 14,
                                                                }}>
                                                                <Text
                                                                    style={[
                                                                        {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 13,
                                                                            color: Colors.descriptionColor,
                                                                            width: '25%',
                                                                            // marginLeft: 5,
                                                                        },
                                                                        switchMerchant
                                                                            ? {
                                                                                fontFamily: 'NunitoSans-Bold',
                                                                                fontSize: 10,
                                                                                color: Colors.descriptionColor,
                                                                                width: '25%',
                                                                            }
                                                                            : {},
                                                                    ]}>
                                                                    {`${addOnChoice.name}:`}
                                                                </Text>
                                                                <Text
                                                                    style={[
                                                                        {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 13,
                                                                            color: Colors.descriptionColor,
                                                                            width: '75%',
                                                                            // marginLeft: 5,
                                                                        },
                                                                        switchMerchant
                                                                            ? {
                                                                                fontFamily: 'NunitoSans-Bold',
                                                                                fontSize: 10,
                                                                                color: Colors.descriptionColor,
                                                                                width: '75%',
                                                                            }
                                                                            : {},
                                                                    ]}>
                                                                    {`${addOnChoice.choiceNames[0]}`}
                                                                </Text>
                                                            </View>

                                                            <View
                                                                style={[
                                                                    {
                                                                        width: '13%',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'center',
                                                                        //backgroundColor: 'blue',
                                                                    },
                                                                ]}>
                                                                <Text
                                                                    style={[
                                                                        {
                                                                            fontFamily: 'NunitoSans-Bold',
                                                                            fontSize: 13,
                                                                            color: Colors.descriptionColor,
                                                                            width: '28%',
                                                                            // right: 38,
                                                                            //backgroundColor: 'green',
                                                                            textAlign: 'center',
                                                                        },
                                                                        switchMerchant
                                                                            ? {
                                                                                fontFamily: 'NunitoSans-Bold',
                                                                                fontSize: 10,
                                                                                color: Colors.descriptionColor,
                                                                                width: '28%',
                                                                                textAlign: 'center',
                                                                            }
                                                                            : {},
                                                                    ]}>
                                                                    {`${addOnChoice.quantities
                                                                        ? `x${addOnChoice.quantities[0]}`
                                                                        : ''
                                                                        }`}
                                                                </Text>
                                                            </View>

                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                    width: '18.8%',
                                                                    alignItems: 'center',
                                                                }}>
                                                                <Text
                                                                    style={[
                                                                        switchMerchant
                                                                            ? {
                                                                                color: Colors.descriptionColor,
                                                                                fontSize: 10,
                                                                            }
                                                                            : {
                                                                                color: Colors.descriptionColor,
                                                                                fontSize: 13,
                                                                            },
                                                                    ]}>
                                                                    RM
                                                                </Text>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                color: Colors.descriptionColor,
                                                                                paddingRight: 20,
                                                                                fontSize: 10, fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                            : {
                                                                                color: Colors.descriptionColor,
                                                                                paddingRight: 20,
                                                                                fontSize: 13, fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                    }>
                                                                    {addOnChoice.prices[0]
                                                                        .toFixed(2)
                                                                        .replace(
                                                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                                                            '$1,',
                                                                        )}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    );
                                                })}
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ flexDirection: 'row', width: '100%' }}>
                                        <View style={{ width: '70%' }} />
                                        <View style={{ width: 15 }} />
                                        {index === item.cartItems.length - 1 ? (
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    //backgroundColor: 'yellow',
                                                    width: '28.65%',
                                                }}>
                                                <View
                                                    style={{
                                                        justifyContent: 'center',
                                                        width: '100%',
                                                    }}>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                                    : {
                                                                        fontSize: 13,
                                                                        width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                            }>
                                                            Subtotal:
                                                        </Text>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: '49.2%',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? { fontSize: 10 }
                                                                        : { fontSize: 13, }
                                                                }>
                                                                RM
                                                            </Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                {item.totalPrice
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    {cartItem.orderType === ORDER_TYPE.DELIVERY ? (
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10,
                                                                            width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                        : {
                                                                            fontSize: 13,
                                                                            width: '50.9%',
                                                                            fontFamily: 'Nunitosans-Bold',
                                                                        }
                                                                }>
                                                                Delivery Fee:
                                                            </Text>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    justifyContent: 'space-between',
                                                                    width: '49.2%',
                                                                }}>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? { fontSize: 10 }
                                                                            : { fontSize: 13, }
                                                                    }>
                                                                    RM
                                                                </Text>
                                                                <Text
                                                                    style={
                                                                        switchMerchant
                                                                            ? {
                                                                                fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                            }
                                                                            : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                    }>
                                                                    {item.deliveryFee
                                                                        .toFixed(2)
                                                                        .replace(
                                                                            /(\d)(?=(\d{3})+(?!\d))/g,
                                                                            '$1,',
                                                                        )}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    ) : (
                                                        <></>)}

                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                                    : {
                                                                        fontSize: 13,
                                                                        width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                            }>
                                                            Discount:
                                                        </Text>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: '49.2%',
                                                            }}>
                                                            <Text style={{
                                                                fontSize: switchMerchant
                                                                    ? 10 : 13
                                                            }}>RM</Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                - {item.discount
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>

                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                                    : {
                                                                        fontSize: 13,
                                                                        width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                            }>
                                                            Tax:
                                                        </Text>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: '49.2%',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20 }
                                                                }>
                                                                RM
                                                            </Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                {item.tax
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>

                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, width: '50.85%', fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                                    : {
                                                                        fontSize: 13,
                                                                        width: '50.85%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                            }>
                                                            Service Charge:
                                                        </Text>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: switchMerchant ? '49.15%' : '49.1%',
                                                            }}>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10,
                                                                        }
                                                                        : { fontSize: 13, }
                                                                }>
                                                                RM
                                                            </Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                {(item.sc || 0)
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>

                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                        }}>
                                                        <Text
                                                            style={
                                                                switchMerchant
                                                                    ? {
                                                                        fontSize: 10, width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                                    : {
                                                                        fontSize: 13,
                                                                        width: '50.9%',
                                                                        fontFamily: 'Nunitosans-Bold',
                                                                    }
                                                            }>
                                                            Total:
                                                        </Text>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                justifyContent: 'space-between',
                                                                width: '49.2%',
                                                            }}>
                                                            <Text style={{
                                                                fontSize: switchMerchant
                                                                    ? 10 : 13
                                                            }}>RM</Text>
                                                            <Text
                                                                style={
                                                                    switchMerchant
                                                                        ? {
                                                                            fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                                                        }
                                                                        : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                                                }>
                                                                {item.finalPrice
                                                                    .toFixed(2)
                                                                    .replace(
                                                                        /(\d)(?=(\d{3})+(?!\d))/g,
                                                                        '$1,',
                                                                    )}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </View>
                                        ) : (
                                            <></>
                                        )}
                                    </View>

                                    {/* <View style={{alignItems:'flex-end'}}>
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 16 }}>Subtotal: {(Math.ceil(cartItem.price * 20-0.05) /20).toFixed(2)}</Text>
                    </View>
                  </View> */}
                                    {/* {(cartItem.remarks && cartItem.remarks.length > 0) ?
                  <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                    
                    <View style={{ flex: 1, justifyContent: 'center', }}>
                      <Text style={{ fontFamily: 'NunitoSans-SemiBold', fontSize: 15 }}>{cartItem.remarks}</Text>
                    </View>
                    
                  </View>
                  : <></>
                } */}
                                </View>
                            );
                        })}
                    </View>
                ) : null}
            </TouchableOpacity>
        )
    };


    // const downloadCsv = () => {
    //     //if (productSales && productSales.dataSource && productSales.dataSource.data) {
    //     //const csvData = convertArrayToCSV(productSales.dataSource.data);
    //     const csvData = convertArrayToCSV(CsvData);

    //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
    //     console.log("PATH", pathToWrite)
    //     RNFetchBlob.fs
    //         .writeFile(pathToWrite, csvData, 'utf8')
    //         .then(() => {
    //             console.log(`wrote file ${pathToWrite}`);
    //             // wrote file /storage/emulated/0/Download/data.csv
    //             Alert.alert(
    //                 'Success',
    //                 `Send to ${pathToWrite}`,
    //                 [{ text: 'OK', onPress: () => { } }],
    //                 { cancelable: false },
    //             );
    //         })
    //         .catch(error => console.error(error));
    //     //}        
    // }

    const convertDataToExcelFormat = () => {
        var excelData = [];
    
        if (!showDetails) {
          for (var i = 0; i < productSales.length; i++) {
            var excelRow = {
              'Product Name': productSales[i].productName,
              'Product Category': productSales[i].productCategory,
              'Product SKU': productSales[i].productSku,
              'Total Items': +parseFloat(productSales[i].totalItems)
                .toFixed(2),
              'Total Sales': +parseFloat(productSales[i].totalSales)
                .toFixed(2),
              'Total Sales Return': +parseFloat(productSales[i].totalSalesReturn)
                .toFixed(2),
              'Total Discount': +parseFloat(productSales[i].totalDiscount)
                .toFixed(2),
              Discount: +parseFloat(productSales[i].discount)
                .toFixed(2),
              'Item Net Sales': +parseFloat(productSales[i].itemNetSales)
                .toFixed(2),
            };
    
            excelData.push(excelRow);
          }
        } else {
          for (var i = 0; i < productSalesDetails.length; i++) {
            var excelRow = {
              //Have to change to product sales details
              // 'Transaction Category': ORDER_TYPE_PARSED[transactionTypeSalesDetails[i].orderType],
              // 'Sales (RM)': parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              // 'Transaction Date': moment(transactionTypeSalesDetails[i].createdAt).format('DD MMM hh:mma'),
              // 'Total Discount (RM)': parseFloat(transactionTypeSalesDetails[i].discount).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              // 'Discount (%)': parseFloat(isFinite(transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount) ? transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount * 100 : 0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              // 'Tax (RM)': parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              // 'Tax (RM)': parseFloat(0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              // 'GP (%)': parseFloat(0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              // 'Net Sales (RM)': parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              'Order ID': productSalesDetails[i].orderId
                ? `#${productSalesDetails[i].orderId}`
                : 'N/A',
              'Transaction Date': moment(
                productSalesDetails[i].completedDate,
              ).format('DD MMM hh:mm A'),
              'Sales (RM)': parseFloat(productSalesDetails[i].finalPrice)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              'Total Discount (RM)': parseFloat(productSalesDetails[i].discount)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              'Discount (%)': parseFloat(
                isFinite(
                  productSalesDetails[i].finalPrice /
                    productSalesDetails[i].discount,
                )
                  ? (productSalesDetails[i].finalPrice /
                      productSalesDetails[i].discount) *
                      100
                  : 0,
              )
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              'Tax (RM)': parseFloat(productSalesDetails[i].tax)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              'Service Chage (RM)': parseFloat(productSalesDetails[i].sc || 0)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              // 'GP (%)': parseFloat(0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
              'Net Sales (RM)': parseFloat(productSalesDetails[i].totalPrice)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
            };
    
            excelData.push(excelRow);
          }
        }
    
        console.log('excelData');
        console.log(excelData);
    
        return excelData;
      };

    // const downloadExcel = () => {
    //     const excelData = convertDataToExcelFormat();

    //     var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`;
    //     var excelWorkSheet = XLSX.utils.json_to_sheet(excelData);
    //     var excelWorkBook = XLSX.utils.book_new();
    //     XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, "Product Sales Report");

    //     const workBookData = XLSX.write(excelWorkBook, {
    //         type: 'binary',
    //         bookType: 'xlsx',
    //     });

    //     RNFS.writeFile(excelFile, workBookData, 'ascii')
    //         .then((success) => {
    //             console.log(`wrote file ${excelFile}`);

    //             Alert.alert(
    //                 'Success',
    //                 `Send to ${excelFile}`,
    //                 [{ text: 'OK', onPress: () => { } }],
    //                 { cancelable: false },
    //             );
    //         })
    //         .catch((err) => {
    //             console.log(err.message);
    //         });


    //     // XLSX.writeFileAsync(excelFile, excelWorkBook, () => {
    //     //     Alert.alert(
    //     //         'Success',
    //     //         `Send to ${excelFile}`,
    //     //         [{ text: 'OK', onPress: () => { } }],
    //     //         { cancelable: false },
    //     //     );
    //     // });

    //     // const csvData = convertArrayToCSV(CsvData);

    //     // const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-Product-Sales${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
    //     // console.log("PATH", excelFile);
    //     // RNFetchBlob.fs
    //     //     .writeFile(excelFile, excelWorkBook, 'utf8')
    //     //     .then(() => {
    //     //         console.log(`wrote file ${excelFile}`);
    //     //         Alert.alert(
    //     //             'Success',
    //     //             `Send to ${excelFile}`,
    //     //             [{ text: 'OK', onPress: () => { } }],
    //     //             { cancelable: false },
    //     //         );
    //     //     })
    //     //     .catch(error => console.error(error));
    // }


    // const emailProductSales = () => {
    //     var body = {
    //         data: CsvData,
    //         //data: convertArrayToCSV(productSales.dataSource.data),
    //         data: convertArrayToCSV(CsvData),
    //         email: exportEmail,
    //     };
    //     //API need to change
    //     ApiClient.POST(API.emailDashboard, body, false).then((result) => {
    //         if (result !== null) {
    //             Alert.alert(
    //                 'Success',
    //                 'Email sent to your inbox',
    //                 [{ text: 'OK', onPress: () => { } }],
    //                 { cancelable: false },
    //             );
    //         }
    //     });

    //     setVisible(false);
    // };

    // Test Email
    const emailProductSales = () => {
        const excelData = convertDataToExcelFormat();

        var body = {
            // data: CsvData,
            //data: convertArrayToCSV(todaySalesChart.dataSource.data),
            data: JSON.stringify(excelData),
            //data: convertDataToExcelFormat(),
            email: exportEmail,
        };

        ApiClient.POST(API.emailDashboard, body, false).then((result) => {
            if (result !== null) {
                Alert.alert(
                    'Success',
                    'Email has been sent',
                    [{ text: 'OK', onPress: () => { } }],
                    { cancelable: false },
                );
            }
        });

        setVisible(false);
    };

    const eventsChart = {
        dataPlotClick: (e, item) => {
            console.log('test data plot');
        }
    }

    const changeClick = () => {
        if (day == true) {
            setState({ day: false })
        }
        else
            setState({ day: true })
    }

    var leftSpacing = '0%';

    if (windowWidth >= 1280) {
        leftSpacing = '10%';
    }

    const leftSpacingScale = {
        marginLeft: leftSpacing,
    };

    const filterPressed = (filterTapped) => {
        if (filterTapped == 1) {
            setExpandSelection(true)
        }
    }

    const groupByPressed = (groupByTapped) => {
        if (groupByTapped == 1) {
            setExpandGroupBy(true)
        }
    }

    const flatListRef = useRef();

    const ScrollToTop = () => {
        flatListRef.current.scrollToOffset({ animated: true, offset: 0 })
    }

    const ScrollToBottom = () => {
        flatListRef.current.scrollToOffset({ animated: true, offset: 100 })
    }

    return (
        <View
            style={[
                styles.container,
                {
                    height: windowHeight,
                    width: windowWidth,
                },
            ]}
        >
            <View style={{ flex: 0.8 }}>
                <SideBar navigation={navigation} selectedTab={8} expandReport={true} />
            </View>

            <Modal
                style={{}}
                visible={exportModalVisibility}
                supportedOrientations={['portrait', 'landscape']}
                transparent={true}
                animationType={'fade'}>
                <View style={{
                    flex: 1,
                    backgroundColor: Colors.modalBgColor,
                    alignItems: 'center',
                    justifyContent: 'center',
                    // top: Platform.OS === 'android' ? 0 : keyboardHeight > 0 ? -keyboardHeight * 0.45 : 0,
                }}>
                    <View style={{
                        height: windowWidth * 0.3,
                        width: windowWidth * 0.4,
                        backgroundColor: Colors.whiteColor,
                        borderRadius: 12,
                        padding: windowWidth * 0.03,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <TouchableOpacity
                            style={{
                                position: 'absolute',
                                right: windowWidth * 0.02,
                                top: windowWidth * 0.02,

                                elevation: 1000,
                                zIndex: 1000,
                            }}
                            onPress={() => {
                                setExportModalVisibility(false);
                            }}>
                            <AntDesign name="closecircle" size={switchMerchant ? 15 : 25} color={Colors.fieldtTxtColor} />
                        </TouchableOpacity>
                        <View style={{
                            alignItems: 'center',
                            top: '20%',
                            position: 'absolute',
                        }}>
                            <Text style={{
                                fontFamily: 'NunitoSans-Bold',
                                textAlign: 'center',
                                fontSize: switchMerchant ? 16 : 24,
                            }}>
                                Download Report
                            </Text>
                        </View>
                        <View style={{ top: switchMerchant ? '14%' : '10%' }}>
                            <Text style={{ fontSize: switchMerchant ? 10 : 20, fontFamily: 'NunitoSans-Bold', }}>Email Address:</Text>
                            <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={{
                                    backgroundColor: Colors.fieldtBgColor,
                                    width: switchMerchant ? 240 : 370,
                                    height: switchMerchant ? 35 : 50,
                                    borderRadius: 5,
                                    padding: 5,
                                    marginVertical: 5,
                                    borderWidth: 1,
                                    borderColor: '#E5E5E5',
                                    paddingLeft: 10,
                                    fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderStyle={{ padding: 5 }}
                                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                placeholder="Enter Your Email"
                                onChangeText={(text) => {
                                    setExportEmail(text);
                                }}
                                value={exportEmail}
                            />
                            <Text style={{ fontSize: switchMerchant ? 10 : 20, fontFamily: 'NunitoSans-Bold', marginTop: 15, }}>Download As:</Text>

                            <View style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                marginTop: 10,
                            }}>
                                <CSVLink
                                    style={{
                                        justifyContent: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                        display: "inline-block",
                                        flexDirection: "row",
                                        textDecoration: 'none',
                                        borderWidth: 1,
                                        borderColor: Colors.primaryColor,
                                        backgroundColor: "#4E9F7D",
                                        borderRadius: 5,
                                        width: switchMerchant ? 100 : 100,
                                        paddingHorizontal: 10,
                                        height: switchMerchant ? 35 : 40,
                                        alignItems: "center",
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 1,
                                        zIndex: -1,
                                    }}
                                    data={convertDataToExcelFormat()}
                                    filename="report.csv"
                                >
                                    <View
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            alignContent: "center",
                                            alignItems: "center",
                                            alignSelf: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                color: Colors.whiteColor,
                                                //marginLeft: 5,
                                                fontSize: switchMerchant ? 10 : 16,
                                                fontFamily: "NunitoSans-Bold",
                                            }}
                                        >
                                            CSV
                                        </Text>
                                    </View>
                                </CSVLink>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>

            <DateTimePickerModal
                isVisible={showDateTimePicker}
                mode={'date'}
                onConfirm={(text) => {
                    setRev_date(moment(text).startOf('day'));
                    setShowDateTimePicker(false);
                }}
                onCancel={() => {
                    setShowDateTimePicker(false);
                }}
                maximumDate={moment(rev_date1).toDate()}
            />

            <DateTimePickerModal
                isVisible={showDateTimePicker1}
                mode={'date'}
                onConfirm={(text) => {
                    setRev_date1(moment(text).endOf('day'));
                    setShowDateTimePicker1(false);
                }}
                onCancel={() => {
                    setShowDateTimePicker1(false);
                }}
                minimumDate={moment(rev_date).toDate()}
            />

            <DateTimePickerModal
                isVisible={showDateTimePickerFilter}
                mode={'date'}
                onConfirm={(text) => {
                    setSelectedChartFilterQueries([
                        {
                            ...selectedChartFilterQueries[0],
                            fieldDataValue: text,
                        },
                    ]);

                    setShowDateTimePickerFilter(false);
                }}
                onCancel={() => {
                    setShowDateTimePickerFilter(false);
                }}
            />

            <Modal
                style={{ flex: 1 }}
                visible={visible}
                transparent={true}
                animationType="slide">

                <KeyboardAvoidingView
                    behavior="padding"
                    style={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: Dimensions.get('window').height,
                    }}>
                    <View style={styles.confirmBox}>

                        <Text style={{ fontSize: 24, justifyContent: "center", alignSelf: "center", marginTop: 40, fontFamily: 'NunitoSans-Bold' }}>Enter your email</Text>
                        <View style={{ justifyContent: "center", alignSelf: "center", alignContent: 'center', marginTop: 20, flexDirection: 'row', width: '80%' }}>
                            <View style={{ justifyContent: 'center', marginHorizontal: 5 }}>
                                <Text style={{ color: Colors.descriptionColor, fontSize: 20, }}>
                                    email:
                                </Text>
                            </View>
                            <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={[styles.textInput8, { paddingLeft: 5 }]}
                                placeholder="Enter your email"
                                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                // style={{
                                //     // paddingLeft: 1,
                                // }}                                        
                                //defaultValue={extentionCharges}
                                onChangeText={(text) => {
                                    // setState({ exportEmail: text });
                                    setExportEmail(text);
                                }}
                                value={exportEmail}
                            />
                        </View>
                        <Text style={{ fontSize: 20, fontFamily: 'NunitoSans-Bold', marginTop: 25, justifyContent: 'center', alignSelf: "center", alignContent: 'center', }}>Share As:</Text>

                        {/* Share file using email */}
                        <View style={{
                            justifyContent: 'space-between', alignSelf: "center", marginTop: 10, flexDirection: 'row', width: '80%'
                        }}>
                            <TouchableOpacity
                                style={[styles.modalSaveButton1, {
                                    zIndex: -1.
                                }]}
                                onPress={() => { }}>
                                <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>Excel</Text>
                            </TouchableOpacity>

                            <TouchableOpacity
                                style={[styles.modalSaveButton1, {
                                    zIndex: -1.
                                }]}
                                onPress={() => { }}>
                                <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>CSV</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={[styles.modalSaveButton1, {
                                    zIndex: -1.
                                }]}
                                onPress={() => { }}>
                                <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>PDF</Text>
                            </TouchableOpacity>
                        </View>



                        <View
                            style={{
                                alignSelf: 'center',
                                marginTop: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                                // width: 260,
                                width: windowWidth * 0.2,
                                height: 60,
                                alignContent: 'center',
                                flexDirection: "row",
                                marginTop: 40,
                            }}>
                            <TouchableOpacity
                                onPress={emailProductSales}
                                style={{
                                    backgroundColor: Colors.fieldtBgColor,
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    height: 60,
                                    borderBottomLeftRadius: 10,
                                    borderRightWidth: StyleSheet.hairlineWidth,
                                    borderTopWidth: StyleSheet.hairlineWidth
                                }}>
                                <Text style={{ fontSize: 22, color: Colors.primaryColor, fontFamily: 'NunitoSans-SemiBold' }}>
                                    Email
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => {
                                    // setState({ visible: false });
                                    setVisible(false);
                                }}
                                style={{
                                    backgroundColor: Colors.fieldtBgColor,
                                    width: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    height: 60,
                                    borderBottomRightRadius: 10,
                                    borderTopWidth: StyleSheet.hairlineWidth
                                }}>
                                <Text style={{ fontSize: 22, color: Colors.descriptionColor, fontFamily: 'NunitoSans-SemiBold' }}>
                                    Cancel
                                </Text>
                            </TouchableOpacity>
                        </View>

                    </View>
                </KeyboardAvoidingView>
            </Modal>

            <View style={{ height: windowHeight, flex: 9 }}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{ width: windowWidth * 0.9 }}
                    contentContainerStyle={{
                        paddingBottom: Dimensions.get("screen").height * 0.1,
                    }}
                >
                    <View
                        style={{
                            paddingHorizontal: switchMerchant ? 0 : 0,
                            paddingVertical: 30,
                            marginHorizontal: switchMerchant ? 15 : 30,
                        }}
                    >

                        <View style={{ paddingBottom: 10 }}>
                            <View style={{
                                height: 70,
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}>
                                <View>
                                    <Text style={{
                                        fontSize: switchMerchant ? 20 : 26,
                                        fontFamily: "NunitoSans-Bold",
                                        marginVertical: 6,
                                    }}>Sales By {name}</Text>
                                    <View
                                        style={{
                                            justifyContent: 'center',
                                        }}>
                                        <View style={{ alignItems: 'center', flexDirection: 'row', }}>
                                            <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 20 : 26, }}>
                                                {!showDetails ? productSales.length : productSalesDetails.length} Product Sales
                                            </Text>

                                        </View>
                                    </View>
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                    }}>
                                    <TouchableOpacity
                                        style={{
                                            justifyContent: 'center',
                                            flexDirection: 'row',
                                            borderWidth: 1,
                                            borderColor: Colors.primaryColor,
                                            backgroundColor: '#4E9F7D',
                                            borderRadius: 5,
                                            //width: 140,
                                            paddingHorizontal: 10,
                                            height: switchMerchant ? 35 : 40,
                                            alignItems: 'center',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 1,
                                            zIndex: -1,
                                            marginRight: 10,
                                        }}
                                        onPress={() => { setExportModalVisibility(true) }}>
                                        <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                            <Icon name="download" size={switchMerchant ? 10 : 20} color={Colors.whiteColor} />
                                            <Text style={{
                                                color: Colors.whiteColor,
                                                marginLeft: 5,
                                                fontSize: switchMerchant ? 10 : 16,
                                                fontFamily: 'NunitoSans-Bold',
                                            }}>
                                                DOWNLOAD
                                            </Text>
                                        </View>
                                    </TouchableOpacity>

                                    <View style={{
                                        width: switchMerchant ? 200 : 250,
                                        height: switchMerchant ? 35 : 40,
                                        backgroundColor: 'white',
                                        borderRadius: 5,
                                        flexDirection: 'row',
                                        alignContent: 'center',
                                        alignItems: 'center',

                                        shadowColor: '#000',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 3,
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                    }}>
                                        <Icon name="search" size={switchMerchant ? 13 : 18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />
                                        <TextInput
                                            editable={!loading}
                                            underlineColorAndroid={Colors.whiteColor}
                                            style={{
                                                width: switchMerchant ? 180 : 250,
                                                fontSize: switchMerchant ? 10 : 15,
                                                fontFamily: 'NunitoSans-Regular',
                                                paddingLeft: 5,
                                                height: 45
                                            }}
                                            clearButtonMode="while-editing"
                                            placeholder=" Search"
                                            onChangeText={(text) => {
                                                setSearch(text);
                                                // setList1(false);
                                                // setSearchList(true);
                                            }}
                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                            value={search}
                                        />
                                    </View>

                                </View>

                            </View>
                        </View>

                        <View style={{
                            backgroundColor: Colors.whiteColor,
                            width: windowWidth * 0.87,
                            minHeight: windowHeight * 0.01,
                            marginTop: 10,
                            marginHorizontal: 30,
                            marginBottom: 15,
                            alignSelf: 'center',
                            borderRadius: 5,
                            shadowOpacity: 0,
                            shadowColor: '#000',
                            shadowOffset: {
                                width: 0,
                                height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 3,
                        }}>
                            <View style={{ width: '100%', flexDirection: 'row', marginVertical: 20, marginBottom: 0, paddingRight: 20, alignItems: 'center', justifyContent: 'space-between' }}>
                                <View style={{ flexDirection: 'row', width: '20%' }}>
                                    <View
                                        style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            marginVertical: 10,
                                            marginBottom: 20,
                                            alignItems: 'center',
                                        }}>
                                        <Picker
                                            style={{
                                                width: switchMerchant ? 140 : 250,
                                                paddingVertical: 0,
                                                backgroundColor: Colors.fieldtBgColor,
                                                borderRadius: 5,
                                                marginLeft: 15,
                                                paddingLeft: 10,
                                            }}
                                            onValueChange={item => {
                                                setSelectedChartDropdownValue(item.value);
                                            }}
                                            selectedValue={selectedChartDropdownValue}
                                        >
                                            {CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES]
                                                .map((value, index) => {
                                                    return (
                                                        <Picker.Item
                                                            key={index}
                                                            label={value.label}
                                                            value={value.value}
                                                        />
                                                    )
                                                })
                                            }
                                        </Picker>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                marginLeft: switchMerchant ? 10 : 20,
                                            }}>
                                            <View
                                                style={{
                                                    paddingHorizontal: 15,
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    borderRadius: 10,
                                                    paddingVertical: 10,
                                                    justifyContent: 'center',
                                                    backgroundColor: Colors.whiteColor,
                                                    shadowOpacity: 0,
                                                    shadowColor: '#000',
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.22,
                                                    shadowRadius: 3.22,
                                                    elevation: 1,
                                                    zIndex: 1,
                                                }}>

                                                <View style={{ alignSelf: "center", marginRight: 5 }} onPress={() => { setState({ pickerMode: 'date', showDateTimePicker: true }) }}>
                                                    <GCalendar width={switchMerchant ? 15 : 20} height={switchMerchant ? 15 : 20} />
                                                </View>


                                                <DatePicker
                                                    selected={rev_date.toDate()}
                                                    onChange={(date) => {
                                                        setRev_date(moment(date).startOf('day'));
                                                    }}
                                                    maxDate={moment(rev_date1).toDate()}
                                                />

                                                <Text
                                                    style={{
                                                        fontFamily: "NunitoSans-Regular",
                                                        paddingHorizontal: 5,
                                                        marginLeft: 5,
                                                    }}
                                                >
                                                    -
                                                </Text>

                                                <DatePicker
                                                    selected={rev_date1.toDate()}
                                                    onChange={(date) => {
                                                        setRev_date1(moment(date).endOf('day'));
                                                    }}
                                                    minDate={moment(rev_date).toDate()}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{
                                    flexDirection: 'row', zIndex: -1, marginLeft: Platform.OS === 'ios' ? '5%' : 0,
                                }}>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesBarChartPeriod == CHART_PERIOD.TODAY ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesBarChartPeriod == CHART_PERIOD.TODAY ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesBarChartPeriod(CHART_PERIOD.TODAY); setRev_date(moment(Date.now()).startOf('day')); setRev_date1(moment(Date.now()).endOf('day')); }}>
                                        <Text style={switchMerchant ? { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.TODAY ? Colors.blackColor : Colors.descriptionColor, fontSize: 10, }
                                            : { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.TODAY ? Colors.blackColor : Colors.descriptionColor }}>TODAY</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesBarChartPeriod == CHART_PERIOD.THIS_WEEK ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesBarChartPeriod == CHART_PERIOD.THIS_WEEK ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesBarChartPeriod(CHART_PERIOD.THIS_WEEK); setRev_date(moment(Date.now()).subtract(7, 'days').startOf('day')); setRev_date1(moment(Date.now()).endOf('day')); }}>
                                        <Text style={switchMerchant ? { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.THIS_WEEK ? Colors.blackColor : Colors.descriptionColor, fontSize: 10, }
                                            : { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.THIS_WEEK ? Colors.blackColor : Colors.descriptionColor }}>1W</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesBarChartPeriod == CHART_PERIOD.THIS_MONTH ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesBarChartPeriod == CHART_PERIOD.THIS_MONTH ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesBarChartPeriod(CHART_PERIOD.THIS_MONTH); setRev_date(moment(Date.now()).subtract(31, 'days').startOf('day')); setRev_date1(moment(Date.now()).endOf('day')); }}>
                                        <Text style={switchMerchant ? { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.THIS_MONTH ? Colors.blackColor : Colors.descriptionColor, fontSize: 10, }
                                            : { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.THIS_MONTH ? Colors.blackColor : Colors.descriptionColor }}>1M</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesBarChartPeriod == CHART_PERIOD.THREE_MONTHS ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesBarChartPeriod == CHART_PERIOD.THREE_MONTHS ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesBarChartPeriod(CHART_PERIOD.THREE_MONTHS); setRev_date(moment(Date.now()).subtract(3, 'months').startOf('day')); setRev_date1(moment(Date.now()).endOf('day')); }}>
                                        <Text style={switchMerchant ? { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.THREE_MONTHS ? Colors.blackColor : Colors.descriptionColor, fontSize: 10, }
                                            : { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.THREE_MONTHS ? Colors.blackColor : Colors.descriptionColor }}>3M</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesBarChartPeriod == CHART_PERIOD.SIX_MONTHS ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesBarChartPeriod == CHART_PERIOD.SIX_MONTHS ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesBarChartPeriod(CHART_PERIOD.SIX_MONTHS); setRev_date(moment(Date.now()).subtract(6, 'month').startOf('day')); setRev_date1(moment(Date.now()).endOf('day')); }}>
                                        <Text style={switchMerchant ? { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.SIX_MONTHS ? Colors.blackColor : Colors.descriptionColor, fontSize: 10, }
                                            : { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.SIX_MONTHS ? Colors.blackColor : Colors.descriptionColor }}>6M</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesBarChartPeriod == CHART_PERIOD.THIS_YEAR ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesBarChartPeriod == CHART_PERIOD.THIS_YEAR ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesBarChartPeriod(CHART_PERIOD.THIS_YEAR); setRev_date(moment().startOf('year').startOf('day')); setRev_date1(moment().endOf('year').endOf('day')); }}>
                                        <Text style={switchMerchant ? { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.THIS_YEAR ? Colors.blackColor : Colors.descriptionColor, fontSize: 10, }
                                            : { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.THIS_YEAR ? Colors.blackColor : Colors.descriptionColor }}>1Y</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesBarChartPeriod == CHART_PERIOD.YTD ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesBarChartPeriod == CHART_PERIOD.YTD ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesBarChartPeriod(CHART_PERIOD.YTD); setRev_date(moment(Date.now()).subtract(12, 'months').startOf('day')); setRev_date1(moment(Date.now()).endOf('day')); }}>
                                        <Text style={switchMerchant ? { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.YTD ? Colors.blackColor : Colors.descriptionColor, fontSize: 10, }
                                            : { fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.YTD ? Colors.blackColor : Colors.descriptionColor }}>YTD</Text>
                                    </TouchableOpacity>
                                </View>

                            </View>

                            <View style={{
                                flexDirection: 'row',
                                width: '100%'
                            }}>
                                <View style={{
                                    zIndex: -1,
                                    paddingHorizontal: 5,
                                    justifyContent: 'space-between',
                                    width: '85%'
                                }}>
                                    <FusionCharts
                                        {...{
                                            // width: windowWidth * (0.75),
                                            width: '100%',
                                            height: windowHeight * 0.5,
                                            type: CHART_DATA[CHART_TYPE.REPORT_PRODUCT_SALES].type,
                                            dataFormat: productSalesChart.dataFormat,
                                            dataSource: productSalesChart.dataSource,
                                        }}
                                    />
                                </View>

                                <View style={{ width: '15%', justifyContent: 'center', flexDirection: 'row' }}>
                                    <View style={{
                                        width: '50%',
                                        marginTop: switchMerchant ? windowHeight * 0.75 : Platform.OS === 'ios' ? windowHeight * 0.40 : windowHeight * 0.45,
                                    }}>

                                        <Picker
                                            arrowColor={'black'}
                                            arrowSize={switchMerchant ? 10 : 20}
                                            arrowStyle={{ fontWeight: 'bold' }}
                                            style={{
                                                // width: Platform.OS === 'ios' ? windowWidth * (0.17 - Styles.sideBarWidth) : windowWidth * (0.16 - Styles.sideBarWidth),
                                                marginLeft: Platform.OS === 'ios' ? 0 : 10,
                                                borderRadius: 10,
                                                backgroundColor: Colors.fieldtBgColor,
                                            }}
                                            placeholderStyle={{ color: Colors.fieldtTxtColor }}
                                            itemStyle={{ justifyContent: 'flex-start', zIndex: 2 }}
                                            placeholder={"Select"}
                                            onChangeItem={item => {
                                                setSelectedChartDropdownValueX(item.value);
                                            }}
                                            defaultValue={selectedChartDropdownValueX}
                                            dropDownMaxHeight={150}
                                            dropDownStyle={{
                                                // width: Platform.OS === 'ios' ? windowWidth * (0.17 - Styles.sideBarWidth) : windowWidth * (0.16 - Styles.sideBarWidth),
                                                marginLeft: Platform.OS === 'ios' ? 0 : 10,
                                                backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1
                                            }}
                                            globalTextStyle={{
                                                fontFamily: 'NunitoSans-SemiBold',
                                                fontSize: switchMerchant ? 10 : 14,
                                                color: Colors.fontDark,
                                                marginLeft: 5,
                                            }}
                                        >
                                            {CHART_X_AXIS_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES]
                                                .map((value, index) => {
                                                    return (
                                                        <Picker.Item
                                                            label={value.label}
                                                            value={value.value}
                                                        />
                                                    )
                                                })
                                            }
                                        </Picker>
                                    </View>
                                </View>
                            </View>
                        </View>
                        {showDetails ?
                            <TouchableOpacity
                                style={{
                                    marginLeft: switchMerchant ? 0 : windowWidth <= 1823 && windowWidth >= 1820 ? 24 : 10,
                                    justifyContent: 'center',
                                    flexDirection: 'row',
                                    borderWidth: 1,
                                    borderColor: Colors.primaryColor,
                                    backgroundColor: '#4E9F7D',
                                    borderRadius: 5,
                                    width: switchMerchant ? 90 : 120,
                                    paddingHorizontal: 10,
                                    height: switchMerchant ? 35 : 40,
                                    alignItems: 'center',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                    zIndex: -1,
                                    opacity: !showDetails ? 0 : 100,
                                }}
                                onPress={() => {
                                    setShowDetails(false);
                                    setCurrentPage(pageReturn);
                                    console.log('Returning to page')
                                    console.log(pageReturn)
                                    setPageCount(Math.ceil(productSales.length / perPage));
                                    setCurrReportSummarySort('')
                                    setCurrReportDetailsSort('')
                                }}
                                disabled={!showDetails}
                            >
                                <AntDesign name="arrowleft" size={switchMerchant ? 10 : 20} color={Colors.whiteColor} style={{
                                    // top: -1,
                                    //marginRight: -5,
                                }} />
                                <Text style={{
                                    color: Colors.whiteColor,
                                    marginLeft: 5,
                                    fontSize: switchMerchant ? 10 : 16,
                                    fontFamily: 'NunitoSans-Bold',
                                    marginBottom: Platform.OS === 'ios' ? 0 : 2
                                }}>
                                    Summary</Text>
                            </TouchableOpacity>
                            : <></>}


                        <View style={{ width: '100%', marginTop: 20 }}>

                            <View style={{
                                backgroundColor: Colors.whiteColor,
                                width: windowWidth * 0.87,
                                height: Platform.OS == 'android' ? windowHeight * 0.6 : windowHeight * 0.66,
                                //marginTop: 5,
                                marginHorizontal: 30,
                                marginBottom: 10,
                                alignSelf: 'center',
                                borderRadius: 5,
                                shadowOpacity: 0,
                                shadowColor: '#000',
                                shadowOffset: {
                                    width: 0,
                                    height: 2,
                                },
                                shadowOpacity: 0.22,
                                shadowRadius: 3.22,
                                elevation: 3,
                            }}>

                                {/* Left Button */}
                                {/* <View style={{ flexDirection: "row"}}>
                                    <View style={{ flexDirection: 'row', zIndex: 1 }}>
                                        <TouchableOpacity
                                                    style={ {
                                                        marginRight: 10,
                                                        paddingHorizontal: 15,
                                                        backgroundColor: Colors.whiteColor,
                                                        height: 40,
                                                        width: 200, 
                                                        alignItems: 'center',
                                                        borderRadius: 7,
                                                        flexDirection: 'row',
                                                        justifyContent: 'center', shadowColor: '#000',
                                                        shadowOffset: {
                                                            width: 0,
                                                            height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,

                                                        opacity: !showDetails ? 0 : 100,
                                                    }}
                                                    onPress={() => {
                                                        setShowDetails(false);

                                                        setCurrentPage(1);
                                                        setPageCount(Math.ceil(productSales.length / perPage));
                                                    }}
                                                    disabled={!showDetails}
                                                >
                                                    <AntDesign name="arrowleft" size={18} color={Colors.primaryColor} style={{
                                                        top: 1,
                                                        marginRight: -5,
                                                    }} />
                                                    <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, marginLeft: 12 }}>Back to Summary</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View> */}

                                {/* Right Button */}
                                {showDetails ?
                                    <View style={{ flexDirection: "row" }}>
                                        <View style={{
                                            flexDirection: 'row',
                                            //justifyContent: 'flex-end',
                                            // marginBottom: 5,
                                            // marginTop: 5,
                                            width: "100%",
                                            width: windowWidth * 0.875,
                                            alignSelf: 'flex-end',
                                        }}>
                                            <Text style={{ marginLeft: 20, marginTop: 10, fontFamily: "NunitoSans-Regular", fontSize: switchMerchant ? 14 : 22, alignSelf: 'center', }}>{detailsTitle}</Text>
                                        </View>
                                        {/* <Text style={{ fontFamily: "NunitoSans-Regular", fontSize: 13, marginLeft: 12 }}>{!showDetails ? productName : productName } </Text> */}
                                    </View>
                                    :
                                    null
                                }
                                {/* <View style={{ height: '88%', position: 'absolute', justifyContent: 'space-between', zIndex: 10, marginVertical: 0, marginTop: 80, alignSelf: 'center' }}>
                                <TouchableOpacity
                                    onPress={() => {
                                        ScrollToTop();
                                    }}
                                    style={{ alignSelf: 'center', marginTop: '8%', zIndex: 10 }}>
                                    <AntDesign name={'upcircle'} size={23} color={Colors.primaryColor} style={{ opacity: 0.4 }} />
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        ScrollToBottom();
                                    }}
                                    style={{ alignSelf: 'center', marginTop: '42%', zIndex: 10 }}>
                                    <AntDesign name={'downcircle'} size={23} color={Colors.primaryColor} style={{ opacity: 0.4 }} />
                                </TouchableOpacity>
                            </View> */}
                                {!showDetails
                                    ?
                                    <View style={{ marginTop: 10, flexDirection: 'row' }}>
                                        <View style={{ flexDirection: 'row', width: '5%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <View style={{ flexDirection: 'column', alignItems: 'center' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'No.\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                </View>


                                                {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                                            </View>
                                            {/* <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View> */}
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '19%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_NAME_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.PRODUCT_NAME_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.PRODUCT_NAME_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'Product\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_NAME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_NAME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '11%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'Category\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '9%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_SKU_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.PRODUCT_SKU_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.PRODUCT_SKU_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'SKU\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_SKU_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.PRODUCT_SKU_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '9%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_ITEMS_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_ITEMS_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_ITEMS_ASC)
                                                    }
                                                }}>
                                                    {/* <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>{'Total\nItems'}</Text> */}
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'Items\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>Qty</Text>
                                                    {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_ITEMS_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_ITEMS_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '9%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC)
                                                    }
                                                }}>
                                                    {/* <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>{'Total\nSales'}</Text> */}
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'Sales\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        {/* <View style={{ flexDirection: 'row', flex: 1.6, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>{'Total\nSales Return'}</Text>
                                            <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>RM</Text>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View> */}
                                        <View style={{ flexDirection: 'row', width: '8%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC)
                                                    }
                                                }}>
                                                    {/* <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>{'Total\nDiscount'}</Text> */}
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'Disc\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '8%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC) {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC)
                                                    }
                                                    else {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC)
                                                    }
                                                }}>

                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'Disc\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>%</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '11%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', textAlign: 'left' }}>{'Payment\nType'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%', left: Platform.OS === 'ios' ? 0 : '-10%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        {/* <View style={{ flexDirection: 'row', flex: 1.6, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold', textAlign: 'center' }}>Discount</Text>
                                            <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>%</Text>
                                        </View>

                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DISCOUNT_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DISCOUNT_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View> */}
                                        <View style={{ flexDirection: 'row', width: '11%', borderRightWidth: 0, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportSummarySort === REPORT_SORT_FIELD_TYPE.NET_SALES_ASC) {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.NET_SALES_DESC)
                                                    }
                                                    else {
                                                        setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.NET_SALES_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'Net Sales\n'}</Text>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.primaryColor }}> *incl tax</Text>
                                                    </View>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.NET_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.NET_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                    </View>
                                    :
                                    <View style={{ marginTop: 10, flexDirection: 'row', }}>
                                        <View style={{ flexDirection: 'row', width: '6%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold', }}>{'No.\n'}</Text>
                                                    {/* <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text> */}
                                                </View>
                                                <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>

                                            </View>
                                            {/* <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View> */}
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '16%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC) {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_DESC)
                                                    }
                                                    else {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Order ID\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_ID_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '20%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC) {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC)
                                                    }
                                                    else {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Transaction Date\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}></Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '11%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC) {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC)
                                                    }
                                                    else {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Sales\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '12%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC) {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC)
                                                    }
                                                    else {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC)
                                                    }
                                                }}>
                                                    {/* <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>{'Total\nDiscount'}</Text> */}
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Disc\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM/%</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        {/* <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Discount</Text>
                                            <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>%</Text>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View> */}
                                        <View style={{ flexDirection: 'row', width: '8%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC) {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC)
                                                    }
                                                    else {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Tax\n'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        <View style={{ flexDirection: 'row', width: '13%', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC) {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_DESC)
                                                    }
                                                    else {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Service\nCharge'}</Text>
                                                    <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                </TouchableOpacity>
                                            </View>

                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                        {/* <View style={{ flexDirection: 'row', flex: 1, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                        <View style={{ flexDirection: 'column' }}>
                                            <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>GP</Text>
                                            <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>%</Text>
                                        </View>
                                        <View style={{ marginLeft: '3%' }}>
                                            <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC)}>
                                                <Entypo name='triangle-up' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>

                                            <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_DESC)}>
                                                <Entypo name='triangle-down' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                            </TouchableOpacity>
                                        </View>
                                    </View> */}
                                        <View style={{ flexDirection: 'row', width: '14%', borderRightWidth: 0, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: 10, borderLeftWidth: 1, borderLeftColor: 'lightgrey', left: 3 }}>
                                            <View style={{ flexDirection: 'column' }}>
                                                <TouchableOpacity onPress={() => {
                                                    if (currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC) {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC)
                                                    }
                                                    else {
                                                        setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC)
                                                    }
                                                }}>
                                                    <Text numberOfLines={2} style={{ fontSize: switchMerchant ? 10 : 13, fontFamily: 'NunitoSans-Bold' }}>{'Net Sales\n'}</Text>
                                                    <View style={{ flexDirection: 'row' }}>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.descriptionColor }}>RM</Text>
                                                        <Text style={{ fontSize: switchMerchant ? 8 : 10, color: Colors.primaryColor }}> *incl tax</Text>
                                                    </View>
                                                </TouchableOpacity>
                                            </View>
                                            <View style={{ marginLeft: '3%' }}>
                                                <Entypo name='triangle-up' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>

                                                <Entypo name='triangle-down' size={switchMerchant ? 7 : 14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                            </View>
                                        </View>
                                    </View>
                                }

                                {!showDetails
                                    ?
                                    <>
                                        {productSales.length > 0 ?
                                            <FlatList
                                                showsVerticalScrollIndicator={false}
                                                ref={flatListRef}
                                                data={sortReportDataList(productSales.filter(item => {
                                                    if (search !== '') {
                                                        if (item.productName.toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else if (item.productCategory.toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else if (item.productSku.toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else if ((item.totalItems.toString()).toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else if ((item.totalSales.toFixed(2).toString()).toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else if ((item.totalDiscount.toFixed(2).toString()).toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else {
                                                            return false;
                                                        }
                                                    }
                                                    else {
                                                        return true;
                                                    }
                                                }), currReportSummarySort).slice((currentPage - 1) * perPage, currentPage * perPage)}
                                                // extraData={productSales}
                                                renderItem={renderItem}
                                                keyExtractor={(item, index) => String(index)}
                                                style={{ marginTop: 10 }}
                                                initialNumToRender={8}
                                            />
                                            :
                                            <View style={{ alignItems: 'center', justifyContent: 'center', height: '71%' }}>
                                                <Text style={{ color: Colors.descriptionColor }}>- No Data Available -</Text>
                                            </View>
                                        }
                                    </>
                                    :
                                    <>
                                        {productSalesDetails.length > 0 ?
                                            <FlatList
                                                showsVerticalScrollIndicator={false}
                                                ref={flatListRef}
                                                data={sortReportDataList(productSalesDetails.filter(item => {
                                                    if (search !== '') {
                                                        if (item.orderId.toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else if ((item.totalPrice.toFixed(2).toString()).toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else if ((item.discount.toFixed(2).toString()).toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else if ((item.tax.toFixed(2).toString()).toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else if ((item.createdAt.toString()).toLowerCase().includes(search.toLowerCase())) {
                                                            return true;
                                                        }
                                                        else {
                                                            return false;
                                                        }
                                                    }
                                                    else {
                                                        return true;
                                                    }
                                                }), currReportDetailsSort).slice((currentDetailsPage - 1) * perPage, currentDetailsPage * perPage)}
                                                // extraData={transactionTypeSales}
                                                renderItem={renderItemDetails}
                                                keyExtractor={(item, index) => String(index)}
                                                style={{ marginTop: 10 }}
                                            />
                                            :
                                            <View style={{ height: windowHeight * 0.4 }}>
                                                <View style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                    <Text style={{ color: Colors.descriptionColor }}>- No Data Available -</Text>
                                                </View>
                                            </View>
                                        }
                                    </>
                                }
                            </View>

                            {!showDetails ?
                                <View style={{
                                    flexDirection: 'row',
                                    marginTop: 10,
                                    width: windowWidth * 0.87,
                                    alignItems: 'center', alignSelf: 'center',
                                    justifyContent: 'flex-end',
                                    // top: Platform.OS == 'ios' ? pushPagingToTop && keyboardHeight > 0 ? -keyboardHeight * 0.9 : 0 : 0,
                                    // backgroundColor: pushPagingToTop && keyboardHeight > 0 ? Colors.highlightColor : null,
                                    // borderWidth: pushPagingToTop && keyboardHeight > 0 ? 1 : 0,
                                    // borderColor: pushPagingToTop && keyboardHeight > 0 ? '#E5E5E5' : null,
                                    // borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                                    // paddingHorizontal: pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                                    // shadowOffset: {
                                    //     width: 0,
                                    //     height: 1,
                                    // },
                                    // shadowOpacity: 0.22,
                                    // shadowRadius: 3.22,
                                    // elevation: 1,
                                    paddingBottom: 15,
                                }}>
                                    <Text style={{
                                        fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%'
                                    }}>Items Showed</Text>
                                    <View style={{
                                        width: Platform.OS === 'ios' ? 65 : '13%', //65,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: Platform.OS === 'ios' ? 0 : 0,
                                        //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                                        // paddingTop: '-60%',
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                        marginRight: '1%',
                                    }}>
                                        <Picker
                                            style={{
                                                inputIOS: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', textAlign: 'center' },
                                                inputAndroid: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', justifyContent: 'center', textAlign: 'center', height: 40, color: 'black' },
                                                inputAndroidContainer: { width: '100%' },
                                                //backgroundColor: 'red',
                                                height: 35,

                                                chevronContainer: {
                                                    display: "none",
                                                },
                                                chevronDown: {
                                                    display: "none",
                                                },
                                                chevronUp: {
                                                    display: "none",
                                                },
                                            }}

                                            onValueChange={(value) => {
                                                setPerPage(value);
                                            }}
                                        >
                                            {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                                                label: 'All',
                                                value: !showDetails ? productSales.length : productSalesDetails.length,
                                            }).map((value, index) => {
                                                return (
                                                    <Picker.Item
                                                        label={value.label}
                                                        value={value.value}
                                                    />
                                                )
                                            })
                                            }
                                        </Picker>
                                    </View>

                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%' }}>Page</Text>
                                    <View style={{
                                        width: switchMerchant ? 65 : 70,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: 22,
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                    }}>
                                        {console.log('currentPage')}
                                        {console.log(currentPage)}

                                        <TextInput
                                            onChangeText={(text) => {
                                                var currentPageTemp = text.length > 0 ? parseInt(text) : 1;

                                                setCurrentPage(currentPageTemp > pageCount ? pageCount : (currentPageTemp < 1 ? 1 : currentPageTemp));

                                            }}
                                            placeholder={currentPage.toString()}
                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                            style={{
                                                color: 'black',
                                                // fontFamily: 'NunitoSans-Regular',
                                                fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular',
                                                marginTop: Platform.OS === 'ios' ? 0 : -15,
                                                marginBottom: Platform.OS === 'ios' ? 0 : -15,
                                                textAlign: 'center',
                                                width: '100%',
                                            }}
                                            value={currentPage.toString()}
                                            defaultValue={currentPage.toString()}
                                            keyboardType={'numeric'}
                                            onFocus={() => { setPushPagingToTop(true) }}
                                        />
                                    </View>
                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginLeft: '1%', marginRight: '1%' }}>of {pageCount}</Text>
                                    <TouchableOpacity style={{ width: switchMerchant ? 30 : 45, height: switchMerchant ? 20 : 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }}
                                        onPress={() => { prevPage() }}>
                                        <ArrowLeft
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ width: switchMerchant ? 30 : 45, height: switchMerchant ? 20 : 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }} onPress={() => { nextPage() }}>
                                        <ArrowRight
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                </View>
                                :
                                <View style={{
                                    flexDirection: 'row',
                                    marginTop: 10,
                                    width: windowWidth * 0.87,
                                    alignItems: 'center', alignSelf: 'center',
                                    justifyContent: 'flex-end',
                                    // top: Platform.OS == 'ios' ? pushPagingToTop && keyboardHeight > 0 ? -keyboardHeight * 0.9 : 0 : 0,
                                    // backgroundColor: pushPagingToTop && keyboardHeight > 0 ? Colors.highlightColor : null,
                                    // borderWidth: pushPagingToTop && keyboardHeight > 0 ? 1 : 0,
                                    // borderColor: pushPagingToTop && keyboardHeight > 0 ? '#E5E5E5' : null,
                                    // borderRadius: pushPagingToTop && keyboardHeight > 0 ? 8 : 0,
                                    // paddingHorizontal: pushPagingToTop && keyboardHeight > 0 ? 10 : 0,
                                    // shadowOffset: {
                                    //     width: 0,
                                    //     height: 1,
                                    // },
                                    // shadowOpacity: 0.22,
                                    // shadowRadius: 3.22,
                                    // elevation: 1,
                                    paddingBottom: 15,
                                }}>
                                    <Text style={{
                                        fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%'
                                    }}>Items Showed</Text>
                                    <View style={{
                                        width: Platform.OS === 'ios' ? 65 : '13%', //65,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: Platform.OS === 'ios' ? 0 : 0,
                                        //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                                        // paddingTop: '-60%',
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                        marginRight: '1%',
                                    }}>
                                        <Picker
                                            style={{
                                                inputIOS: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', textAlign: 'center' },
                                                inputAndroid: { fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular', justifyContent: 'center', textAlign: 'center', height: 40, color: 'black' },
                                                inputAndroidContainer: { width: '100%' },
                                                //backgroundColor: 'red',
                                                height: 35,

                                                chevronContainer: {
                                                    display: "none",
                                                },
                                                chevronDown: {
                                                    display: "none",
                                                },
                                                chevronUp: {
                                                    display: "none",
                                                },
                                            }}
                                            onValueChange={(value) => {
                                                setPerPage(value);
                                            }}
                                        >
                                            {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                                                label: 'All',
                                                value: !showDetails ? productSales.length : productSalesDetails.length,
                                            }).map((value, index) => {
                                                return (
                                                    <Picker.Item
                                                        label={value.label}
                                                        value={value.value}
                                                    />
                                                )
                                            })
                                            }
                                        </Picker>
                                    </View>

                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginRight: '1%' }}>Page</Text>
                                    <View style={{
                                        width: switchMerchant ? 65 : 70,
                                        height: switchMerchant ? 20 : 35,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 10,
                                        justifyContent: 'center',
                                        paddingHorizontal: 22,
                                        borderWidth: 1,
                                        borderColor: '#E5E5E5',
                                    }}>
                                        {console.log('currentDetailsPage')}
                                        {console.log(currentDetailsPage)}

                                        <TextInput
                                            onChangeText={(text) => {
                                                var currentPageTemp = text.length > 0 ? parseInt(text) : 1;

                                                setCurrentDetailsPage(currentPageTemp > detailsPageCount ? detailsPageCount : (currentPageTemp < 1 ? 1 : currentPageTemp));

                                            }}
                                            placeholder={currentDetailsPage.toString()}
                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                            style={{
                                                color: 'black',
                                                // fontFamily: 'NunitoSans-Regular',
                                                fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Regular',
                                                marginTop: Platform.OS === 'ios' ? 0 : -15,
                                                marginBottom: Platform.OS === 'ios' ? 0 : -15,
                                                textAlign: 'center',
                                                width: '100%',
                                            }}
                                            value={currentDetailsPage.toString()}
                                            defaultValue={currentDetailsPage.toString()}
                                            keyboardType={'numeric'}
                                            onFocus={() => { setPushPagingToTop(true) }}
                                        />
                                    </View>
                                    <Text style={{ fontSize: switchMerchant ? 10 : 14, fontFamily: 'NunitoSans-Bold', marginLeft: '1%', marginRight: '1%' }}>of {detailsPageCount}</Text>
                                    <TouchableOpacity style={{ width: switchMerchant ? 30 : 45, height: switchMerchant ? 20 : 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }}
                                        onPress={() => { prevDetailsPage() }}>
                                        <ArrowLeft
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ width: switchMerchant ? 30 : 45, height: switchMerchant ? 20 : 28, backgroundColor: Colors.primaryColor, alignItems: 'center', justifyContent: "center" }} onPress={() => { nextDetailsPage() }}>
                                        <ArrowRight
                                            color={Colors.whiteColor}
                                        />
                                    </TouchableOpacity>
                                </View>
                            }

                            {/* </View> */}
                        </View>

                    </View></ScrollView>

                {expandGroupBy ?
                    <View style={styles.ManageFilterBox1}>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10 }}>
                            <Text style={{ fontSize: 13, color: '#27353C', fontFamily: 'Nunitosans-Bold', }}>Group By</Text>
                        </View>
                        <View style={{ borderWidth: 0.5, borderColor: '#D2D2D2', width: '100%', marginTop: 5, marginBottom: 5 }}></View>
                        <View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', margin: 10, height: 45, elevation: 1, zIndex: 1, }}>
                            <Text style={{ fontSize: 16, Colors: '#27353C', fontFamily: 'Nunitosans-bold' }}>Select Group: </Text>
                            <Picker
                                arrowColor={'#BDBDBD'}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                style={{ width: 300, alignItems: 'center', justifyContent: 'center' }}
                                //items={CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES]}
                                dropDownStyle={{
                                    width: 300,
                                }}
                                itemStyle={{
                                    justifyContent: 'flex-start',
                                }}
                                globalTextStyle={{
                                    fontFamily: 'NunitoSans-SemiBold',
                                    color: Colors.fontDark,
                                    marginLeft: 5,
                                }}
                                placeholder={'select a group'}
                                //onChangeItem={item => {
                                //    setSelectedChartFilterQueries([
                                //        {
                                //            ...selectedChartFilterQueries[0],
                                //            fieldNameKey: item.value,
                                //            fieldNameType: item.fieldType,
                                //        },
                                //    ]);
                                //}}
                                //defaultValue={selectedChartFilterQueries[0].fieldNameKey}
                                listMode={'SCROLLVIEW'}
                                scrollViewProps={{
                                    nestedScrollEnabled: true,
                                }}
                            >
                                {[
                                    { label: 'Product Name', value: 1 },
                                    { label: 'Category', value: 2 },
                                    { label: 'Tag', value: 3 },
                                    { label: 'Sku', value: 4 }
                                ].map((value, index) => {
                                    return (
                                        <Picker.Item
                                            label={value.label}
                                            value={value.value}
                                        />
                                    )
                                })
                                }
                            </Picker>
                        </View>
                        <View style={{ borderWidth: 0.5, borderColor: '#D2D2D2', width: '100%', marginTop: 5, marginBottom: 5 }}></View>
                        <View style={{ justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', margin: 10, height: 45, elevation: 1, zIndex: -1, }}>
                            <Text style={{ fontSize: 16, Colors: '#27353C', fontFamily: 'Nunitosans-bold' }}>Select SubItems: </Text>
                            <Picker
                                arrowColor={'#BDBDBD'}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                style={{ width: 300, alignItems: 'center', justifyContent: 'center' }}
                                //items={CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES]}
                                dropDownStyle={{
                                    width: 300,
                                }}
                                itemStyle={{
                                    justifyContent: 'flex-start',
                                }}
                                globalTextStyle={{
                                    fontFamily: 'NunitoSans-SemiBold',
                                    color: Colors.fontDark,
                                    marginLeft: 5,
                                }}
                                placeholder={'select a group'}
                                //onChangeItem={item => {
                                //    setSelectedChartFilterQueries([
                                //        {
                                //            ...selectedChartFilterQueries[0],
                                //            fieldNameKey: item.value,
                                //            fieldNameType: item.fieldType,
                                //        },
                                //    ]);
                                //}}
                                //defaultValue={selectedChartFilterQueries[0].fieldNameKey}
                                listMode={'SCROLLVIEW'}
                                scrollViewProps={{
                                    nestedScrollEnabled: true,
                                }}
                            >
                                {[
                                    { label: 'Product Name', value: 1 },
                                    { label: 'Category', value: 2 },
                                    { label: 'Tag', value: 3 },
                                    { label: 'Sku', value: 4 }
                                ].map((value, index) => {
                                    return (
                                        <Picker.Item
                                            label={value.label}
                                            value={value.value}
                                        />
                                    )
                                })
                                }
                            </Picker>
                        </View>
                    </View>
                    : null}

                {expandSelection ?
                    <View style={styles.ManageFilterBox}>
                        {/* <ScrollViewGH showsVerticalScrollIndicator={false}> */}
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10 }}>
                            <Text style={{ fontSize: 16, Colors: '#27353C', fontFamily: 'Nunitosans-bold' }}>Manage Filter</Text>
                        </View>
                        <View style={{ borderWidth: 0.5, borderColor: '#D2D2D2', width: '100%', marginTop: 5, marginBottom: 5 }}></View>
                        <View style={{ flexDirection: 'row', alignItems: 'center', margin: 10, height: 35, elevation: 1, zIndex: 1 }}>
                            <Picker
                                arrowColor={'#BDBDBD'}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                style={{ width: 250, height: 35 }}
                                // items={[
                                //     { label: 'Daily', value: 1 },
                                //     { label: 'Weekly', value: 2 },
                                //     { label: 'Monthly', value: 3 },
                                //     //{ label: 'Hour of day', value: 4 }, 
                                //     //{ label: 'Day of week', value: 5 }
                                // ]}
                                // placeholder={'Field name'}
                                // placeholderStyle={{ color: 'black' }}
                                dropDownStyle={{
                                    width: 250,
                                }}
                                itemStyle={{
                                    justifyContent: 'flex-start',
                                }}
                                globalTextStyle={{
                                    fontFamily: 'NunitoSans-SemiBold',
                                    // fontSize: 12,
                                    color: Colors.fontDark,
                                    marginLeft: 5,
                                }}
                                onChangeItem={item => {
                                    // setState({ sort: selectedSort }),
                                    //sortingFunc(selectedSort);

                                    console.log('test queries');
                                    console.log([
                                        {
                                            ...selectedChartFilterQueries[0],
                                            fieldNameKey: item.value,
                                            fieldNameType: item.fieldType,
                                            fieldDataValue: null,
                                            fieldSpecial: item.special ? item.special : null,
                                        },
                                    ]);

                                    setSelectedChartFilterQueries([
                                        {
                                            ...selectedChartFilterQueries[0],
                                            fieldNameKey: item.value,
                                            fieldNameType: item.fieldType,
                                            fieldDataValue: null,
                                            fieldSpecial: item.special ? item.special : null,
                                        },
                                    ]);
                                }}
                                defaultValue={selectedChartFilterQueries[0].fieldNameKey}
                                listMode={'SCROLLVIEW'}
                                scrollViewProps={{
                                    nestedScrollEnabled: true,
                                }}
                            >

                                {CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES]
                                    .map((value, index) => {
                                        return (
                                            <Picker.Item
                                                label={value.label}
                                                value={value.value}
                                            />
                                        )
                                    })
                                }
                            </Picker>

                            <Picker
                                arrowColor={'#BDBDBD'}
                                arrowSize={23}
                                arrowStyle={{ fontWeight: 'bold' }}
                                style={{ marginLeft: '5%', width: 150 }}
                                // items={[
                                //     { label: 'Daily', value: 1 },
                                //     { label: 'Weekly', value: 2 },
                                //     { label: 'Monthly', value: 3 },
                                //     //{ label: 'Hour of day', value: 4 }, 
                                //     //{ label: 'Day of week', value: 5 }
                                // ]}
                                // placeholder={'Field name'}
                                // placeholderStyle={{ color: 'black' }}
                                dropDownStyle={{
                                    width: 150,
                                    marginLeft: '5%',
                                }}
                                itemStyle={{
                                    justifyContent: 'flex-start',
                                }}
                                globalTextStyle={{
                                    fontFamily: 'NunitoSans-SemiBold',
                                    // fontSize: 12,
                                    color: Colors.fontDark,
                                    marginLeft: 5,
                                }}
                                onChangeItem={item => {
                                    // setState({ sort: selectedSort }),
                                    //sortingFunc(selectedSort);
                                    setSelectedChartFilterQueries([
                                        {
                                            ...selectedChartFilterQueries[0],
                                            fieldCompare: item.value,
                                            fieldDataValue: null,
                                        },
                                    ]);
                                }}
                                defaultValue={selectedChartFilterQueries[0].fieldCompare}
                                listMode={'SCROLLVIEW'}
                                scrollViewProps={{
                                    nestedScrollEnabled: true,
                                }}
                            >
                                {CHART_FIELD_COMPARE_DROPDOWN_LIST[CHART_TYPE.REPORT_PRODUCT_SALES]
                                    .map((value, index) => {
                                        return (
                                            <Picker.Item
                                                label={value.label}
                                                value={value.value}
                                            />
                                        )
                                    })
                                }
                            </Picker>
                        </View>
                        <View style={{
                            flexDirection: 'row', alignItems: 'center', margin: 10,
                            marginVertical: 5,
                            // height: 35,
                        }}>
                            <TextInput
                                {...selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.DATETIME && {
                                    onPressIn: () => {
                                        setShowDateTimePickerFilter(true);
                                    },
                                }}
                                editable={selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.DATETIME ? false : true}
                                style={{
                                    width: 410, borderWidth: 1, borderColor: '#D2D2D2', alignItems: 'center', justifyContent: 'center', color: 'black', fontFamily: 'NunitoSans-Regular', borderRadius: 8,
                                    paddingVertical: 3, paddingLeft: 15, height: 35,
                                }}
                                placeholder='Field Value'
                                placeholderStyle={{ color: 'black', justifyContent: 'center', alignItems: 'center', fontFamily: 'NunitoSans-Regular' }}
                                defaultValue={
                                    selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.DATETIME ?
                                        moment(selectedChartFilterQueries[0].fieldDataValue).format('DD/MM/YYYY') :
                                        selectedChartFilterQueries[0].fieldDataValue ? selectedChartFilterQueries[0].fieldDataValue : ''
                                }
                                onChangeText={(text) => {
                                    setSelectedChartFilterQueries([
                                        {
                                            ...selectedChartFilterQueries[0],
                                            fieldDataValue: text,
                                        },
                                    ]);
                                }}
                                keyboardType={selectedChartFilterQueries[0].fieldNameType === CHART_FIELD_TYPE.STRING ? 'default' : 'numeric'}
                                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            />
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 10, height: 40, zIndex: -1 }}>

                        </View>
                        <View style={{ borderWidth: 0.5, borderColor: '#D2D2D2', width: '100%', marginTop: 5, marginBottom: 5 }}></View>
                        <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', margin: 10 }}>
                            <TouchableOpacity style={{
                                justifyContent: 'center',
                                width: '30%',

                            }}
                                onPress={() => {
                                    setExpandSelection(false);

                                    setAppliedChartFilterQueries([]);
                                }}>
                                <View style={{
                                    justifyContent: 'center',
                                    width: '100%',
                                    flexDirection: 'row',
                                    borderWidth: 1,
                                    borderColor: '#BDBDBD',
                                    borderRadius: 5,
                                    height: 35,
                                    alignItems: 'center',
                                    backgroundColor: '#FFFFFF',
                                    height: windowHeight * 0.050,
                                }}>
                                    <Text style={{
                                        color: '#BDBDBD',
                                        fontSize: 15,
                                        fontFamily: 'Nunitosans-bold',
                                        marginLeft: 7
                                    }}>
                                        CANCEL
                                    </Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity style={{
                                justifyContent: 'center',
                                width: '30%',
                                marginLeft: '3%',
                            }}
                                onPress={() => {
                                    setExpandSelection(false);

                                    setAppliedChartFilterQueries(selectedChartFilterQueries);
                                }}>
                                <View style={{
                                    justifyContent: 'center',
                                    width: '100%',
                                    flexDirection: 'row',
                                    backgroundColor: '#4E9F7D',
                                    borderRadius: 5,
                                    height: 35,
                                    alignItems: 'center',
                                    height: windowHeight * 0.050,
                                }}>
                                    <Text style={{
                                        color: '#FFFFFF',
                                        fontSize: 15,
                                        fontFamily: 'Nunitosans-bold',
                                        marginLeft: 7
                                    }}>
                                        APPLY
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        {/* </ScrollViewGH> */}
                    </View>
                    : null}

                {/* <Modal style={{ flex: 1 }} visible={visible} transparent={true} animationType="slide">
                    <View
                        style={{
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: Dimensions.get('window').height,
                        }}>
                        <View style={styles.confirmBox}>
                            <View style={{ flex: 3, borderBottomWidth: StyleSheet.hairlineWidth, justifyContent: 'center', alignItems: 'center' }}>

                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <TouchableOpacity style={{ flex: 1, borderRightWidth: StyleSheet.hairlineWidth, justifyContent: 'center' }} onPress={() => { download() }}>
                                    <Text style={{ color: Colors.primaryColor, fontSize: 24, fontFamily: 'NunitoSans-SemiBold', textAlign: 'center' }}>Download</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ flex: 1, justifyContent: 'center' }} onPress={() => { setState({ visible: !visible }); }}>
                                    <Text style={{ color: Colors.descriptionColor, fontSize: 24, fontFamily: 'NunitoSans-SemiBold', textAlign: 'center' }}>Cancel</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Modal> */}
                {/* <Modal style={{ flex: 1 }} visible={visible1} transparent={true} animationType="slide">
                    <View
                        style={{
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: Dimensions.get('window').height,
                        }}>
                        <View style={styles.confirmBox}>
                            <View style={{ flex: 3, borderBottomWidth: StyleSheet.hairlineWidth, justifyContent: 'center', alignItems: 'center' }}>

                            </View>
                            <View style={{ flex: 1, flexDirection: 'row' }}>
                                <TouchableOpacity style={{ flex: 1, borderRightWidth: StyleSheet.hairlineWidth, justifyContent: 'center' }} onPress={() => { email() }}>
                                    <Text style={{ color: Colors.primaryColor, fontSize: 24, fontWeight: '400', textAlign: 'center' }}>Email</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ flex: 1, justifyContent: 'center' }} onPress={() => { setState({ visible1: !visible1 }); }}>
                                    <Text style={{ color: Colors.descriptionColor, fontSize: 24, fontWeight: '400', textAlign: 'center' }}>Cancel</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </Modal> */}

            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.highlightColor,
        flexDirection: 'row',
    },
    sidebar: {
        width: Dimensions.get('screen').width * Styles.sideBarWidth,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
    content: {
        padding: 20,
        width: Dimensions.get('screen').width * (1 - Styles.sideBarWidth),
        backgroundColor: Colors.highlightColor,
    },
    headerLogo: {
        width: 112,
        height: 25,
        marginLeft: 10,
    },
    confirmBox: {
        // width: '30%',
        // height: '30%',
        // borderRadius: 30,
        // backgroundColor: Colors.whiteColor,
        width: Dimensions.get('screen').width * 0.4,
        height: Dimensions.get('screen').height * 0.4,
        borderRadius: 12,
        backgroundColor: Colors.whiteColor,
        justifyContent: 'space-between'
    },
    modalContainer: {
        flex: 1,
        backgroundColor: Colors.modalBgColor,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalView: {
        height: Dimensions.get('screen').width * 0.2,
        width: Dimensions.get('screen').width * 0.3,
        backgroundColor: Colors.whiteColor,
        borderRadius: 12,
        padding: Dimensions.get('screen').width * 0.03,
        alignItems: 'center',
        justifyContent: 'center'
    },
    closeButton: {
        position: 'absolute',
        right: Dimensions.get('screen').width * 0.02,
        top: Dimensions.get('screen').width * 0.02,

        elevation: 1000,
        zIndex: 1000,
    },
    modalTitle: {
        alignItems: 'center',
        top: '20%',
        position: 'absolute',
    },
    modalBody: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'

    },
    modalTitleText: {
        fontFamily: 'NunitoSans-Bold',
        textAlign: 'center',
        fontSize: 20,
    },
    modalDescText: {
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 18,
        color: Colors.fieldtTxtColor
    },
    modalBodyText: {
        flex: 1,
        fontFamily: 'NunitoSans-SemiBold',
        fontSize: 25,
        width: "20%",
    },
    modalSaveButton: {
        width: Dimensions.get('screen').width * 0.15,
        backgroundColor: Colors.fieldtBgColor,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,

        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 1,

        marginVertical: 10,
    },
    modalSaveButton1: {
        width: Dimensions.get('screen').width * 0.1,
        backgroundColor: Colors.fieldtBgColor,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,

        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 1,

        marginVertical: 10,
    },
    ManageFilterBox: {
        //width: windowWidth * 0.33,
        height: Dimensions.get('screen').height * 0.25,
        width: Platform.OS === 'ios' ? Dimensions.get('screen').width * 0.42 : Dimensions.get('screen').width * 0.33,
        position: 'absolute',
        marginTop: '18.5%',
        marginLeft: '2%',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 10,
        zIndex: 1000,
        borderRadius: 10,
        borderTopLeftRadius: 0,
        borderWidth: 1,
        borderColor: '#E5E5E5',
        backgroundColor: Colors.whiteColor,
        //justifyContent: 'space-between'
    },
    ManageFilterBox1: {
        //width: windowWidth * 0.33,
        //height: windowHeight * 0.25,
        width: Platform.OS === 'ios' ? Dimensions.get('screen').width * 0.42 : Dimensions.get('screen').width * 0.33,
        position: 'absolute',
        marginTop: '18.5%',
        marginLeft: '2%',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 10,
        zIndex: 1000,
        borderRadius: 10,
        borderTopLeftRadius: 0,
        borderWidth: 1,
        borderColor: '#E5E5E5',
        backgroundColor: Colors.whiteColor,
        //justifyContent: 'space-between'
    },
    submitText: {
        height: Platform.OS == 'ios' ? Dimensions.get('screen').height * 0.06 : Dimensions.get('screen').height * 0.07,
        paddingVertical: 5,
        paddingHorizontal: 20,
        flexDirection: 'row',
        color: '#4cd964',
        textAlign: 'center',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
});

export default ReportSalesProduct;
