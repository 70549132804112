// export const WEEK = {
//     0: 'sunday',
//     1: 'monday',
//     2: 'tuesday',
//     3: 'wednesday',
//     4: 'thursday',
//     5: 'friday',
//     6: 'saturday',
// };

// export const USER_ORDER_STATUS = {
//     ORDER_RECEIVED: 'ORDER_RECEIVED',

//     ORDER_AUTHORIZED: 'ORDER_AUTHORIZED',

//     ORDER_PREPARING: 'ORDER_PREPARING',
//     ORDER_PREPARED: 'ORDER_PREPARED',
//     ORDER_DELIVERED: 'ORDER_DELIVERED',
//     ORDER_COMPLETED: 'ORDER_COMPLETED',

//     ORDER_CANCELLED_BY_MERCHANT: 'ORDER_CANCELLED_BY_MERCHANT',
//     ORDER_CANCELLED_BY_USER: 'ORDER_CANCELLED_BY_USER',

//     ORDER_SENDER_REJECTED: 'ORDER_SENDER_REJECTED',
//     ORDER_SENDER_CANCELED: 'ORDER_SENDER_CANCELED',
//     ORDER_SENDER_EXPIRED: 'ORDER_SENDER_EXPIRED',
// };

// export const ORDER_TYPE = {
//     DINEIN: 'DINEIN',
//     DELIVERY: 'DELIVERY',
//     PICKUP: 'PICKUP',
//     BEER_DOCKET: 'BEER_DOCKET',
// };

// export const ORDER_TYPE_PARSED = {
//     DINEIN: 'Dine In',
//     DELIVERY: 'Delivery',
//     PICKUP: 'Takeaway',
//     BEER_DOCKET: 'Beer Docket',
// };

// export const ADDRESS_TYPE = {
//     HOME: 'HOME',
//     WORK: 'WORK',
//     OTHER: 'OTHER',
// };

// export const USER_RESERVATION_STATUS = {
//     PENDING: 'PENDING',
//     ACCEPTED: 'ACCEPTED',
//     CANCELED: 'CANCELED',
//     SEATED: 'SEATED',
// };

// export const USER_QUEUE_STATUS = {
//     PENDING: 'PENDING',
//     ACCEPTED: 'ACCEPTED',
//     CANCELED: 'CANCELED',
//     SEATED: 'SEATED',
// };

// export const USER_RING_STATUS = {
//     PENDING: 'PENDING',
//     ACCEPTED: 'ACCEPTED',
//     CANCELED: 'CANCELED',
//     ATTENDED: 'ATTENDED',
// };

// export const COURIER_CODE = {
//     LALAMOVE: 'LALAMOVE',
//     MRSPEEDY: 'MRSPEEDY',
//     TELEPORT: 'TELEPORT',
// };

// export const SENDER_DROPDOWN_LIST = [
//     {
//         label: 'Lalamove',
//         value: COURIER_CODE.LALAMOVE,
//         img: require('../asset/image/courier-lalamove.png'),
//     },
//     {
//         label: 'MrSpeedy',
//         value: COURIER_CODE.MRSPEEDY,
//         img: require('../asset/image/courier-mrspeedy.png'),
//     },
//     {
//         label: 'Teleport',
//         value: COURIER_CODE.TELEPORT,
//         img: require('../asset/image/courier-teleport.png'),
//     },
// ];

// export const COURIER_INFO_DICT = {
//     [COURIER_CODE.LALAMOVE]: {
//         name: 'Lalamove',
//         img: require('../asset/image/courier-lalamove.png'),
//     },
//     [COURIER_CODE.MRSPEEDY]: {
//         name: 'MrSpeedy',
//         img: require('../asset/image/courier-mrspeedy.png'),
//     },
//     [COURIER_CODE.TELEPORT]: {
//         name: 'Teleport',
//         img: require('../asset/image/courier-teleport.png'),
//     },
// }

// export const CREDIT_CARD_TYPE = {
//     VISA: 'Visa',
//     MASTER_CARD: 'MasterCard',
// };

// export const MERCHANT_VOUCHER_TYPE = {
//     CASH_VOUCHER: 'CASH_VOUCHER',
// };

// export const MERCHANT_VOUCHER_STATUS = {
//     ACTIVE: 'ACTIVE',
//     DISABLED: 'DISABLED',
//     EXPIRED: 'EXPIRED',
// };

// export const LALAMOVE_STATUS = {
//     ASSIGNING_DRIVER: 'ASSIGNING_DRIVER',
//     ON_GOING: 'ON_GOING',
//     PICKED_UP: 'PICKED_UP',
//     COMPLETED: 'COMPLETED',
//     REJECTED: 'REJECTED',
//     CANCELED: 'CANCELED',
//     EXPIRED: 'EXPIRED',
// };

// export const MRSPEEDY_STATUS = {
//     new: 'new',
//     available: 'available',
//     active: 'active',
//     completed: 'completed',
//     reactivated: 'reactivated',
//     draft: 'draft',
//     canceled: 'canceled',
//     delayed: 'delayed',
// };

// export const TELEPORT_STATUS = {
//     new: 'new',
//     available: 'available',
//     active: 'active',
//     completed: 'completed',
//     reactivated: 'reactivated',
//     draft: 'draft',
//     canceled: 'canceled',
//     delayed: 'delayed',
// };

// export const VERIFALIA_STATUS = {
//     DELIVERABLE: 'DELIVERABLE',

//     API_NOT_AVAILABLE: 'API_NOT_AVAILABLE',
// };

// export const USER_ORDER_PAYMENT_OPTIONS = {
//     ONLINE: 'ONLINE',
//     OFFLINE: 'OFFLINE',
//     ALL: 'ALL',
// };

// export const QR_SCANNING_TYPE = {
//     DINEIN: 'DINEIN',
//     BEER_DOCKET: 'BEER_DOCKET',
// };

// export const NOTIFICATIONS_TYPE = {
//     USER_ORDER: 'USER_ORDER',
//     USER_RING: 'USER_RING',
//     PROMOTION_NOTIFICATION_MANUAL: 'PROMOTION_NOTIFICATION_MANUAL',
//     PROMOTION_NOTIFICATION_AUTO: 'PROMOTION_NOTIFICATION_AUTO',
//     USER_ORDER_COURIER_ACTION: 'USER_ORDER_COURIER_ACTION',
// };


// export const NOTIFICATIONS_ID = {
//     USER_ORDER: '1',
//     USER_RING: '2',
//     PROMOTION_NOTIFICATION_MANUAL: '3',
//     PROMOTION_NOTIFICATION_AUTO: '4',
//     USER_ORDER_COURIER_ACTION: '5',
// };

// const NOTIFICATIONS_CHANNELS_VERSIONS = 'v1.01'; // v3

// export const NOTIFICATIONS_CHANNEL = {
//     USER_ORDER: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_ORDER}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
//     USER_RING: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_RING}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
//     PROMOTION_NOTIFICATION_MANUAL: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_MANUAL}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
//     PROMOTION_NOTIFICATION_AUTO: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_AUTO}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
//     USER_ORDER_COURIER_ACTION: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_ORDER_COURIER_ACTION}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
// };

// export const NOTIFICATIONS_CHANNEL_LIST = [
//     {
//         channelId: NOTIFICATIONS_CHANNEL.USER_ORDER,
//         channelName: 'User Order',
//         id: NOTIFICATIONS_ID.USER_ORDER,
//     },
//     {
//         channelId: NOTIFICATIONS_CHANNEL.USER_RING,
//         channelName: 'User Ring',
//         id: NOTIFICATIONS_ID.USER_RING,
//     },
//     {
//         channelId: NOTIFICATIONS_CHANNEL.PROMOTION_NOTIFICATION_MANUAL,
//         channelName: 'Promotion Notification Manual',
//         id: NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_MANUAL,
//     },
//     {
//         channelId: NOTIFICATIONS_CHANNEL.PROMOTION_NOTIFICATION_AUTO,
//         channelName: 'Promotion Notification Auto',
//         id: NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_AUTO,
//     },
//     {
//         channelId: NOTIFICATIONS_CHANNEL.USER_ORDER_COURIER_ACTION,
//         channelName: 'User Order Courier Action',
//         id: NOTIFICATIONS_ID.USER_ORDER_COURIER_ACTION,
//     },
// ];

export const MPS_CHANNEL = {
    fpx_amb: 'fpx_amb',
    fpx_bimb: 'fpx_bimb',
    fpx_cimbclicks: 'fpx_cimbclicks',
    fpx_hlb: 'fpx_hlb',
    fpx_mb2u: 'fpx_mb2u',
    fpx_pbb: 'fpx_pbb',
    fpx_rhb: 'fpx_rhb',
    FPX_OCBC: 'FPX_OCBC',
    FPX_SCB: 'FPX_SCB',
    FPX_ABB: 'FPX_ABB',
    FPX_BKRM: 'FPX_BKRM',
    FPX_BMMB: 'FPX_BMMB',
    FPX_KFH: 'FPX_KFH',
    FPX_BSN: 'FPX_BSN',
    FPX_ABMB: 'FPX_ABMB',
    FPX_UOB: 'FPX_UOB',
    credit: 'credit',
};

export const MPS_CHANNEL_PARSED = {
    fpx_amb: 'FPX Am Bank (Am Online)',
    fpx_bimb: 'FPX Bank Islam',
    fpx_cimbclicks: 'FPX CIMB Bank(CIMB Clicks)',
    fpx_hlb: 'FPX Hong Leong Bank(HLB Connect)',
    fpx_mb2u: 'FPX Maybank(Maybank2u)',
    fpx_pbb: 'FPX PublicBank (PBB Online)',
    fpx_rhb: 'FPX RHB Bank(RHB Now)',
    FPX_OCBC: 'FPX OCBC Bank',
    FPX_SCB: 'FPX Standard Chartered Bank',
    FPX_ABB: 'FPX Affin Bank Berhad',
    FPX_BKRM: 'FPX Bank Kerjasama Rakyat Malaysia Berhad',
    FPX_BMMB: 'FPX Bank Muamalat',
    FPX_KFH: 'FPX Kuwait Finance House',
    FPX_BSN: 'FPX Bank Simpanan Nasional',
    FPX_ABMB: 'FPX Alliance Bank Malaysia Berhad',
    FPX_UOB: 'FPX United Overseas Bank',
    credit: 'Credit Card/ Debit Card',
};

export const MPS_CHANNEL_LIST = [
    {
        label: MPS_CHANNEL_PARSED.fpx_amb,
        value: MPS_CHANNEL.fpx_amb,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_bimb,
        value: MPS_CHANNEL.fpx_bimb,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_cimbclicks,
        value: MPS_CHANNEL.fpx_cimbclicks,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_hlb,
        value: MPS_CHANNEL.fpx_hlb,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_mb2u,
        value: MPS_CHANNEL.fpx_mb2u,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_pbb,
        value: MPS_CHANNEL.fpx_pbb,
    },
    {
        label: MPS_CHANNEL_PARSED.fpx_rhb,
        value: MPS_CHANNEL.fpx_rhb,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_OCBC,
        value: MPS_CHANNEL.FPX_OCBC,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_SCB,
        value: MPS_CHANNEL.FPX_SCB,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_ABB,
        value: MPS_CHANNEL.FPX_ABB,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_BKRM,
        value: MPS_CHANNEL.FPX_BKRM,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_BMMB,
        value: MPS_CHANNEL.FPX_BMMB,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_KFH,
        value: MPS_CHANNEL.FPX_KFH,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_BSN,
        value: MPS_CHANNEL.FPX_BSN,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_ABMB,
        value: MPS_CHANNEL.FPX_ABMB,
    },
    {
        label: MPS_CHANNEL_PARSED.FPX_UOB,
        value: MPS_CHANNEL.FPX_UOB,
    },
    {
        label: MPS_CHANNEL_PARSED.credit,
        value: MPS_CHANNEL.credit,
    },
];

export const DELAY_LONG_PRESS_TIME = 2000;

export const ACCUMULATOR_ID = {
    GLOBAL: 'a7336454-dc5e-4ab7-9f9a-c9268116a8f1',
};

export const ACCUMULATOR_TYPE = {
    GLOBAL: 'GLOBAL',
};

export const APP_TYPE = {
    MERCHANT: 'MERCHANT',
    WAITER: 'WAITER',
    USER: 'USER',
};

export const ROLE_TYPE = {
    ADMIN: 'admin',
    LEGACY: 'legacy',
    FRONTLINER: 'frontliner',
    STORE_MANAGER: 'store_manager',
};

export const ROLE_TYPE_PARSED = {
    admin: 'Admin',
    legacy: 'Legacy',
    frontliner: 'Frontliner',
    store_manager: 'Store Manager',
};

export const USER_POINTS_TRANSACTION_TYPE = {
    EARN: 'EARN',
    REDEEM: 'REDEEM',
    EXPIRED: 'EXPIRED',
    INVALID: 'INVALID',
};

export const USER_ORDER_STATUS = {
    ORDER_RECEIVED: 'ORDER_RECEIVED',

    ORDER_AUTHORIZED: 'ORDER_AUTHORIZED',

    ORDER_PREPARING: 'ORDER_PREPARING',
    ORDER_PREPARED: 'ORDER_PREPARED',
    ORDER_DELIVERED: 'ORDER_DELIVERED',
    ORDER_COMPLETED: 'ORDER_COMPLETED',

    ORDER_REJECTED_BY_MERCHANT: 'ORDER_REJECTED_BY_MERCHANT',

    ORDER_CANCELLED_BY_MERCHANT: 'ORDER_CANCELLED_BY_MERCHANT',
    ORDER_CANCELLED_BY_USER: 'ORDER_CANCELLED_BY_USER',

    ORDER_SENDER_REJECTED: 'ORDER_SENDER_REJECTED',
    ORDER_SENDER_CANCELED: 'ORDER_SENDER_CANCELED',
    ORDER_SENDER_EXPIRED: 'ORDER_SENDER_EXPIRED',
};

export const USER_ORDER_STATUS_PARSED = {
    ORDER_RECEIVED: 'Received',
    ORDER_PREPARING: 'Preparing',
    ORDER_PREPARED: 'Prepared',
    ORDER_DELIVERED: 'Delivered',
    ORDER_COMPLETED: 'Completed',

    ORDER_CANCELLED_BY_MERCHANT: 'Cancelled',
    ORDER_CANCELLED_BY_USER: 'Cancelled',
};

export const ORDER_TYPE = {
    DINEIN: 'DINEIN',
    DELIVERY: 'DELIVERY',
    PICKUP: 'PICKUP',
    PRE_ORDER: 'PRE_ORDER',
};

export const ORDER_TYPE_PARSED = {
    DINEIN: 'Dine In',
    DELIVERY: 'Delivery',
    PICKUP: 'Takeaway',
    PRE_ORDER: 'Pre-Order',
};

export const ORDER_TYPE_DROP_DOWN_LIST = [
    {
        label: 'Dine In',
        value: ORDER_TYPE.DINEIN,
    },
    {
        label: 'Takeaway',
        value: ORDER_TYPE.PICKUP,
    },
    {
        label: 'Delivery',
        value: ORDER_TYPE.DELIVERY,
    },
];

export const RING_TOP_BAR = {
    RING_SCREEN: 'RING_SCREEN',
}

export const RING_TOP_BAR_PARSED = {
    RING_SCREEN: 'Ring Screen',
}

export const RING_TOP_BAR_SORT = {
    RING_SCREEN: RING_TOP_BAR.RING_SCREEN,
}

export const USER_RESERVATION_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    CANCELED: 'CANCELED',
    SEATED: 'SEATED',

    NO_SHOW: 'NO_SHOW',
};

export const USER_RESERVATION_STATUS_PARSED = {
    PENDING: 'Pending',
    ACCEPTED: 'Accepted',
    CANCELED: 'Cancelled',
    SEATED: 'Seated',

    NO_SHOW: 'No Show',
};

export const USER_QUEUE_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    CANCELED: 'CANCELED',
    SEATED: 'SEATED',
    SERVED: 'SERVED',

    NO_SHOW: 'NO_SHOW',
    NOTIFIED: 'NOTIFIED',
};

export const USER_QUEUE_STATUS_PARSED = {
    PENDING: 'Waiting',
    ACCEPTED: 'Accepted',
    CANCELED: 'Cancelled',
    SEATED: 'Seated',
    SERVED: 'Served',

    NO_SHOW: 'No Show',
    NOTIFIED: 'Notified',
};

export const USER_RING_STATUS = {
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    CANCELED: 'CANCELED',
    ATTENDED: 'ATTENDED',
};

export const USER_ORDER_PRIORITY = {
    NORMAL: 0,
    HIGH: 1,
};


export const COURIER_CODE = {
    LALAMOVE: 'LALAMOVE',
    MRSPEEDY: 'MRSPEEDY',
    IN_HOUSE: 'IN-HOUSE',
};

export const LALAMOVE_STATUS = {
    ASSIGNING_DRIVER: 'ASSIGNING_DRIVER',
    ON_GOING: 'ON_GOING',
    PICKED_UP: 'PICKED_UP',
    COMPLETED: 'COMPLETED',
    REJECTED: 'REJECTED',
    CANCELED: 'CANCELED',
    EXPIRED: 'EXPIRED',
};

export const LALAMOVE_STATUS_PARSED = {
    ASSIGNING_DRIVER: 'Assigning',
    ON_GOING: 'On the way',
    PICKED_UP: 'Delivering',
    COMPLETED: 'Delivered',
    REJECTED: 'Rejected',
    CANCELED: 'Cancelled',
    EXPIRED: 'Expired',
};

export const MRSPEEDY_STATUS = {
    new: 'new',
    available: 'available',
    active: 'active',
    completed: 'completed',
    reactivated: 'reactivated',
    draft: 'draft',
    canceled: 'canceled',
    delayed: 'delayed',
};

export const MRSPEEDY_STATUS_PARSED = {
    new: 'New',
    available: 'Available',
    active: 'Active',
    completed: 'Completed',
    reactivated: 'Reactivated',
    draft: 'Draft',
    canceled: 'Cancelled',
    delayed: 'Delayed',
};

export const COURIER_INFO_DICT = {
    [COURIER_CODE.LALAMOVE]: {
        name: 'Lalamove',
        img: require('../assets/image/courier-lalamove.png'),
    },
    [COURIER_CODE.MRSPEEDY]: {
        name: 'MrSpeedy',
        img: require('../assets/image/courier-mrspeedy.png'),
    },
}

export const COURIER_DROPDOWN_LIST = [
    {
        label: 'Lalamove',
        value: COURIER_CODE.LALAMOVE,
    },
    {
        label: 'MrSpeedy',
        value: COURIER_CODE.MRSPEEDY,
    },
    // {
    //     label: 'In-House',
    //     value: COURIER_CODE.IN_HOUSE,
    // }
];

export const MERCHANT_VOUCHER_STATUS = {
    DRAFT: 'DRAFT',
    ACTIVE: 'ACTIVE',
};

export const MERCHANT_VOUCHER_STATUS_PARSED = {
    DRAFT: 'Draft',
    ACTIVE: 'Published',
};

export const MERCHANT_VOUCHER_CODE_FORMAT = {
    AUTO_GENERATED: 'AUTO_GENERATED',
    GENERIC: 'GENERIC',
    UNIQUE: 'UNIQUE',
};

export const MERCHANT_VOUCHER_CODE_FORMAT_UNIQUE = {
    ALPHA_NUMERICAL: 'ALPHA_NUMERICAL',
    ALPHA: 'ALPHA',
    NUMERIC: 'NUMERIC',
};

export const MERCHANT_VOUCHER_TYPE = {
    CASH_VOUCHER: 'CASH_VOUCHER',
    PERCENTAGE_VOUCHER: 'PERCENTAGE_VOUCHER',
    BUNDLE_VOUCHER: 'BUNDLE_VOUCHER',
    COMPLIMENTARY_VOUCHER: 'COMPLIMENTARY_VOUCHER',
};

export const MERCHANT_VOUCHER_TYPE_PARSED = {
    CASH_VOUCHER: 'Cash Voucher',
    PERCENTAGE_VOUCHER: 'Percentage Voucher',
    BUNDLE_VOUCHER: 'Bundle Voucher',
    COMPLIMENTARY_VOUCHER: 'Complimentary Voucher',
};

export const SEGMENT_TYPE = {
    NONE: 'NONE',
};

export const PURCHASE_ORDER_STATUS = {
    CREATED: 'CREATED',
    ORDERED: 'ORDERED',
    PARTIALLY_RECEIVED: 'PARTIALLY_RECEIVED',
    COMPLETED: 'COMPLETED',
};

export const PURCHASE_ORDER_STATUS_PARSED = {
    CREATED: 'Created',
    ORDERED: 'Ordered',
    PARTIALLY_RECEIVED: 'Partially',
    COMPLETED: 'Completed',
};

export const STOCK_TRANSFER_STATUS = {
    CREATED: 'CREATED',
    ORDERED: 'ORDERED',
    PARTIALLY_RECEIVED: 'PARTIALLY_RECEIVED',
    COMPLETED: 'COMPLETED',
};

export const STOCK_TRANSFER_STATUS_PARSED = {
    CREATED: 'Pending',
    ORDERED: 'Ordered',
    PARTIALLY_RECEIVED: 'Partially',
    COMPLETED: 'Completed',
};

export const STOCK_TAKE_STATUS = {
    CREATED: 'CREATED',
    ORDERED: 'ORDERED',
    PARTIALLY_RECEIVED: 'PARTIALLY_RECEIVED',
    COMPLETED: 'COMPLETED',
};

export const STOCK_TAKE_STATUS_PARSED = {
    CREATED: 'Created',
    ORDERED: 'Ordered',
    PARTIALLY_RECEIVED: 'Partially',
    COMPLETED: 'Completed',
};

export const OUTLET_SHIFT_STATUS = {
    OPENED: 'OPENED',
    CLOSED: 'CLOSED',
};

export const USER_ORDER_PAYMENT_OPTIONS = {
    ONLINE: 'ONLINE',
    OFFLINE: 'OFFLINE',
    ALL: 'ALL',
};

export const USER_ORDER_PAYMENT_OPTIONS_PARSED = {
    ONLINE: 'Online Banking',
    OFFLINE: 'Offline',
    ALL: 'All',
};

export const TIMEZONE = {
    KUALA_LUMPUR: 'Asia/Kuala_Lumpur',
};

//////////////////////////////////////////

export const CHART_HOURLY_LABEL_LIST = [
    '00',
    '01',
    '02',
    '03',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
];

export const WEEK = {    
    0: 'monday',
    1: 'tuesday',
    2: 'wednesday',
    3: 'thursday',
    4: 'friday',
    5: 'saturday',
    6: 'sunday',
};

export const NOTIFICATIONS_TYPE = {
    USER_ORDER: 'USER_ORDER',
    USER_RING: 'USER_RING',
    PROMOTION_NOTIFICATION_MANUAL: 'PROMOTION_NOTIFICATION_MANUAL',
    PROMOTION_NOTIFICATION_AUTO: 'PROMOTION_NOTIFICATION_AUTO',
    USER_ORDER_COURIER_ACTION: 'USER_ORDER_COURIER_ACTION',

    MERCHANT_BATCH_UPLOAD_PRODUCTS: 'MERCHANT_BATCH_UPLOAD_PRODUCTS',
    MERCHANT_BATCH_UPLOAD_CRM_USERS: 'MERCHANT_BATCH_UPLOAD_CRM_USERS',

    WAITER_KITCHEN_READY: 'WAITER_KITCHEN_READY',

    MERCHANT_BATCH_UPLOAD_INVENTORIES: 'MERCHANT_BATCH_UPLOAD_INVENTORIES',

    MERCHANT_LOW_STOCK_ALERT: 'MERCHANT_LOW_STOCK_ALERT',
    MERCHANT_RESERVATION_REMINDER: 'MERCHANT_RESERVATION_REMINDER',

    PROMOTION_NOTIFICATION_LOCATION_BASED: 'PROMOTION_NOTIFICATION_LOCATION_BASED',

    MERCHANT_QUEUE_REMINDER: 'MERCHANT_QUEUE_REMINDER',

    USER_RESERVATION_INFORM: 'USER_RESERVATION_INFORM',
    USER_QUEUE_INFORM: 'USER_QUEUE_INFORM',
};


export const NOTIFICATIONS_ID = {
    USER_ORDER: '1',
    USER_RING: '2',
    PROMOTION_NOTIFICATION_MANUAL: '3',
    PROMOTION_NOTIFICATION_AUTO: '4',
    USER_ORDER_COURIER_ACTION: '5',

    MERCHANT_BATCH_UPLOAD_PRODUCTS: '6',
    MERCHANT_BATCH_UPLOAD_CRM_USERS: '7',

    WAITER_KITCHEN_READY: '8',

    MERCHANT_BATCH_UPLOAD_INVENTORIES: '9',

    MERCHANT_LOW_STOCK_ALERT: '10',

    MERCHANT_RESERVATION_REMINDER: '11',

    PROMOTION_NOTIFICATION_LOCATION_BASED: '12',

    MERCHANT_QUEUE_REMINDER: '13',

    USER_RESERVATION_INFORM: '14',
    USER_QUEUE_INFORM: '15',
};

const NOTIFICATIONS_CHANNELS_VERSIONS = 'v1.03'; // v3

export const NOTIFICATIONS_CHANNEL = {
    USER_ORDER: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_ORDER}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
    USER_RING: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_RING}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
    PROMOTION_NOTIFICATION_MANUAL: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_MANUAL}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
    PROMOTION_NOTIFICATION_AUTO: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_AUTO}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
    USER_ORDER_COURIER_ACTION: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_ORDER_COURIER_ACTION}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,

    MERCHANT_BATCH_UPLOAD_PRODUCTS: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.MERCHANT_BATCH_UPLOAD_PRODUCTS}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
    MERCHANT_BATCH_UPLOAD_CRM_USERS: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.MERCHANT_BATCH_UPLOAD_CRM_USERS}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,

    WAITER_KITCHEN_READY: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.WAITER_KITCHEN_READY}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,

    MERCHANT_BATCH_UPLOAD_INVENTORIES: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.MERCHANT_BATCH_UPLOAD_INVENTORIES}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,

    MERCHANT_LOW_STOCK_ALERT: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.MERCHANT_LOW_STOCK_ALERT}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,

    MERCHANT_RESERVATION_REMINDER: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.MERCHANT_RESERVATION_REMINDER}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,

    PROMOTION_NOTIFICATION_LOCATION_BASED: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_LOCATION_BASED}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,

    MERCHANT_QUEUE_REMINDER: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.MERCHANT_QUEUE_REMINDER}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,

    USER_RESERVATION_INFORM: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_RESERVATION_INFORM}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
    USER_QUEUE_INFORM: `com.mykoodoo.merchant.${NOTIFICATIONS_ID.USER_QUEUE_INFORM}.${NOTIFICATIONS_CHANNELS_VERSIONS}`,
};

export const NOTIFICATIONS_CHANNEL_LIST = [
    {
        channelId: NOTIFICATIONS_CHANNEL.USER_ORDER,
        channelName: 'User Order',
        id: NOTIFICATIONS_ID.USER_ORDER,
    },
    {
        channelId: NOTIFICATIONS_CHANNEL.USER_RING,
        channelName: 'User Ring',
        id: NOTIFICATIONS_ID.USER_RING,
    },
    {
        channelId: NOTIFICATIONS_CHANNEL.PROMOTION_NOTIFICATION_MANUAL,
        channelName: 'Promotion Notification Manual',
        id: NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_MANUAL,
    },
    {
        channelId: NOTIFICATIONS_CHANNEL.PROMOTION_NOTIFICATION_AUTO,
        channelName: 'Promotion Notification Auto',
        id: NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_AUTO,
    },
    {
        channelId: NOTIFICATIONS_CHANNEL.USER_ORDER_COURIER_ACTION,
        channelName: 'User Order Courier Action',
        id: NOTIFICATIONS_ID.USER_ORDER_COURIER_ACTION,
    },

    {
        channelId: NOTIFICATIONS_CHANNEL.MERCHANT_BATCH_UPLOAD_PRODUCTS,
        channelName: 'Merchant Batch Upload Products',
        id: NOTIFICATIONS_ID.MERCHANT_BATCH_UPLOAD_PRODUCTS,
    },
    {
        channelId: NOTIFICATIONS_CHANNEL.MERCHANT_BATCH_UPLOAD_CRM_USERS,
        channelName: 'Merchant Batch Upload CRM Users',
        id: NOTIFICATIONS_ID.MERCHANT_BATCH_UPLOAD_CRM_USERS,
    },

    {
        channelId: NOTIFICATIONS_CHANNEL.WAITER_KITCHEN_READY,
        channelName: 'Waiter Kitchen Ready',
        id: NOTIFICATIONS_ID.WAITER_KITCHEN_READY,
    },

    {
        channelId: NOTIFICATIONS_CHANNEL.MERCHANT_BATCH_UPLOAD_INVENTORIES,
        channelName: 'Merchant Batch Upload Inventories',
        id: NOTIFICATIONS_ID.MERCHANT_BATCH_UPLOAD_INVENTORIES,
    },

    {
        channelId: NOTIFICATIONS_CHANNEL.MERCHANT_LOW_STOCK_ALERT,
        channelName: 'Merchant Low Stock Alert',
        id: NOTIFICATIONS_ID.MERCHANT_LOW_STOCK_ALERT,
    },

    {
        channelId: NOTIFICATIONS_CHANNEL.MERCHANT_RESERVATION_REMINDER,
        channelName: 'Merchant Reservation Reminder',
        id: NOTIFICATIONS_ID.MERCHANT_RESERVATION_REMINDER,
    },

    {
        channelId: NOTIFICATIONS_CHANNEL.PROMOTION_NOTIFICATION_LOCATION_BASED,
        channelName: 'Promotion Notification Location Based',
        id: NOTIFICATIONS_ID.PROMOTION_NOTIFICATION_LOCATION_BASED,
    },

    {
        channelId: NOTIFICATIONS_CHANNEL.MERCHANT_QUEUE_REMINDER,
        channelName: 'Merchant Queue Reminder',
        id: NOTIFICATIONS_ID.MERCHANT_QUEUE_REMINDER,
    },

    {
        channelId: NOTIFICATIONS_CHANNEL.USER_RESERVATION_INFORM,
        channelName: 'User Reservation Inform',
        id: NOTIFICATIONS_ID.USER_RESERVATION_INFORM,
    },
    {
        channelId: NOTIFICATIONS_CHANNEL.USER_QUEUE_INFORM,
        channelName: 'User Queue Inform',
        id: NOTIFICATIONS_ID.USER_QUEUE_INFORM,
    },
];


export const USER_SORT_FIELD_TYPE = {
    NAME_ASC: 'NAME_ASC',
    NAME_DESC: 'NAME_DESC',
    NUMBER_ASC: ' NUMBER_ASC',
    NUMBER_DESC: ' NUMBER_DESC',
    GENDER_ASC: 'GENDER_ASC',
    GENDER_DESC: 'GENDER_DESC',
    DOB_ASC: 'DOB_ASC',
    DOB_DESC: 'DOB_DESC',
    RACE_ASC: 'RACE_ASC',
    RACE_DESC: 'RACE_DESC',
    STATUS_ASC: 'STATUS_ASC',
    STATUS_DESC: 'STATUS_DESC',
}

export const USER_SORT_FIELD_TYPE_VALUE = {
    NAME_ASC: 'name',
    NAME_DESC: 'name',
    NUMBER_ASC: 'number',
    NUMBER_DESC: 'number',
    GENDER_ASC: 'gender',
    GENDER_DESC: 'gender',
    DOB_ASC: 'dob',
    DOB_DESC: 'dob',
    RACE_ASC: 'race',
    RACE_DESC: 'race',
    STATUS_ASC: 'status',
    STATUS_DESC: 'status',

}

export const PAYMENT_SORT_FIELD_TYPE = {
    PAYMENT_NAME_ASC: 'PAYMENT_NAME_ASC',
    PAYMENT_NAME_DESC: 'PAYMENT_NAME_DESC',
    PAYMENT_CREATED_DATE_ASC: 'PAYMENT_CREATED_DATE_ASC',
    PAYMENT_CREATED_DATE_DESC: 'PAYMENT_CREATED_DATE_DESC',
    PAYMENT_UPDATED_DATE_ASC: 'PAYMENT_UPDATED_DATE_ASC',
    PAYMENT_UPDATED_DATE_DESC: 'PAYMENT_UPDATED_DATE_DESC',
    PAYMENT_STATUS_ASC: 'PAYMENT_STATUS_ASC',
    PAYMENT_STATUS_DESC: 'PAYMENT_STATUS_DESC',
}

export const PAYMENT_SORT_FIELD_TYPE_VALUE = {
    PAYMENT_NAME_ASC: 'name',
    PAYMENT_NAME_DESC: 'name',
    PAYMENT_CREATED_DATE_ASC: 'createdAt',
    PAYMENT_CREATED_DATE_DESC: 'createdAt',
    PAYMENT_UPDATED_DATE_ASC: 'updatedAt',
    PAYMENT_UPDATED_DATE_DESC: 'updatedAt',
    PAYMENT_STATUS_ASC: 'isActive',
    PAYMENT_STATUS_DESC: 'isActive',
}

export const QUEUE_SORT_FIELD_TYPE = {
    QUEUE_ID_ASC: 'QUEUE_ID_ASC',
    QUEUE_ID_DESC: 'QUEUE_ID_DESC',
    NAME_ASC: 'NAME_ASC',
    NAME_DESC: 'NAME_DESC',
    DATE_TIME_ASC: 'DATE_TIME_ASC',
    DATE_TIME_DESC: 'DATE_TIME_DESC',
    CAPACITY_ASC: 'CAPACITY_ASC',
    CAPACITY_DESC: 'CAPACITY_DESC',
    WAITING_TIME_ASC: 'WAITING_TIME_ASC',
    WAITING_TIME_DESC: 'WAITING_TIME_DESC',
    STATUS_ASC: 'STATUS_ASC',
    STATUS_DESC: 'STATUS_DESC',
}

export const QUEUE_SORT_FIELD_TYPE_VALUE = {
    QUEUE_ID_ASC: 'number',
    QUEUE_ID_DESC: 'number',
    NAME_ASC: 'userName',
    NAME_DESC: 'userName',
    DATE_TIME_ASC: 'createdAt',
    DATE_TIME_DESC: 'createdAt',
    CAPACITY_ASC: 'pax',
    CAPACITY_DESC: 'pax',
    WAITING_TIME_ASC: 'updatedAt',
    WAITING_TIME_DESC: 'updatedAt',
    STATUS_ASC: 'status',
    STATUS_DESC: 'status',
}

export const DINE_IN_SORT_FIELD_TYPE = {
    TABLE_CODE_ASC: 'TABLE_CODE_ASC',
    TABLE_CODE_DESC: 'TABLE_CODE_DESC',
    ORDER_ID_ASC: 'ORDER_ID_ASC',
    ORDER_ID_DESC: 'ORDER_ID_DESC',
    ORDER_DATE_ASC: 'ORDER_DATE_ASC',
    ORDER_DATE_DESC: 'ORDER_DATE_DESC',
    WAITER_NAME_ASC: 'WAITER_NAME_ASC',
    WAITER_NAME_DESC: 'WAITER_NAME_DESC',
    WAITING_TIME_ASC: 'WAITING_TIME_ASC',
    WAITING_TIME_DESC: 'WAITING_TIME_DESC',
    PAYMENT_DETAILS_ASC: 'PAYMENT_DETAILS_ASC',
    PAYMENT_DETAILS_DESC: 'PAYMENT_DETAILS_DESC',
    FINAL_PRICE_ASC: 'FINAL_PRICE_ASC',
    FINAL_PRICE_DESC: 'FINAL_PRICE_DESC',
}

export const DINE_IN_SORT_FIELD_TYPE_VALUE = {
    TABLE_CODE_ASC: 'tableCode',
    TABLE_CODE_DESC: 'tableCode',
    ORDER_ID_ASC: 'orderId',
    ORDER_ID_DESC: 'orderId',
    ORDER_DATE_ASC: 'orderDate',
    ORDER_DATE_DESC: 'orderDate',
    WAITER_NAME_ASC: 'waiterName',
    WAITER_NAME_DESC: 'waiterName',
    WAITING_TIME_ASC: 'updatedAt',
    WAITING_TIME_DESC: 'updatedAt',
    PAYMENT_DETAILS_ASC: 'paymentDetails',
    PAYMENT_DETAILS_DESC: 'paymentDetails',
    FINAL_PRICE_ASC: 'finalPrice',
    FINAL_PRICE_DESC: 'finalPrice',
}

export const SEGMENT_SORT_FIELD_TYPE = {
    SEGMENT_GROUP_ASC: 'SEGMENT_GROUP_ASC',
    SEGMENT_GROUP_DESC: 'SEGMENT_GROUP_DESC'
}

export const SEGMENT_SORT_FIELD_TYPE_VALUE = {
    SEGMENT_GROUP_ASC: 'name',
    SEGMENT_GROUP_DESC: 'name',
}

// export const USER_SORT_FIELD_TYPE_COMPARE = {
//     Permanently placed
//     NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
//     NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
//     NUMBER_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
//     NUMBER_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
//     GENDER_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
//     GENDER_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
//     DOB_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
//     DOB_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
//     RACE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
//     RACE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
//     STATUS_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
//     STATUS_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
// }

export const REPORT_SORT_COMPARE_OPERATOR = {
    ASC: 'ASC',
    DESC: 'DESC',
}

export const PAYMENT_SORT_COMPARE_OPERATOR = {
    ASC: 'ASC',
    DESC: 'DESC',
}

export const REPORT_SORT_FIELD_TYPE = {
    DATE_TIME_ASC: 'DATE_TIME_ASC',
    DATE_TIME_DESC: 'DATE_TIME_DESC',
    TOTAL_SALES_ASC: 'TOTAL_SALES_ASC',
    TOTAL_SALES_DESC: 'TOTAL_SALES_DESC',
    TOTAL_SALES_RETURN_ASC: 'TOTAL_SALES_RETURN_ASC',
    TOTAL_SALES_RETURN_DESC: 'TOTAL_SALES_RETURN_DESC',
    TOTAL_TRANSACTION_ASC: 'TOTAL_TRANSACTION_ASC',
    TOTAL_TRANSACTION_DESC: 'TOTAL_TRANSACTION_DESC',
    TOTAL_DISCOUNT_ASC: 'TOTAL_DISCOUNT_ASC',
    TOTAL_DISCOUNT_DESC: 'TOTAL_DISCOUNT_DESC',
    DISCOUNT_ASC: 'DISCOUNT_ASC',
    DISCOUNT_DESC: 'DISCOUNT_DESC',
    TAX_ASC: 'TAX_ASC',
    TAX_DESC: 'TAX_DESC',
    SERVICE_CHARGE_ASC: 'SERVICE_CHARGE_ASC',
    SERVICE_CHARGE_DESC: 'SERVICE_CHARGE_DESC',
    GP_ASC: 'GP_ASC',
    GP_DESC: 'GP_ASC',
    NET_SALES_ASC: 'NET_SALES_ASC',
    NET_SALES_DESC: 'NET_SALES_DESC',
    AVERAGE_NET_SALES_ASC: 'AVERAGE_NET_SALES_ASC',
    AVERAGE_NET_SALES_DESC: 'AVERAGE_NET_SALES_DESC',

    USER_ORDER_ID_ASC: 'USER_ORDER_ID_ASC',
    USER_ORDER_ID_DESC: 'USER_ORDER_ID_DESC',
    USER_ORDER_DATE_TIME_ASC: 'USER_ORDER_DATE_TIME_ASC',
    USER_ORDER_DATE_TIME_DESC: 'USER_ORDER_DATE_TIME_DESC',
    USER_ORDER_SALES_ASC: 'USER_ORDER_SALES_ASC',
    USER_ORDER_SALES_DESC: 'USER_ORDER_SALES_DESC',
    USER_ORDER_TOTAL_DISCOUNT_ASC: 'USER_ORDER_TOTAL_DISCOUNT_ASC',
    USER_ORDER_TOTAL_DISCOUNT_DESC: 'USER_ORDER_TOTAL_DISCOUNT_DESC',
    USER_ORDER_DISCOUNT_ASC: 'USER_ORDER_DISCOUNT_ASC',
    USER_ORDER_DISCOUNT_DESC: 'USER_ORDER_DISCOUNT_DESC',
    USER_ORDER_TAX_ASC: 'USER_ORDER_TAX_ASC',
    USER_ORDER_TAX_DESC: 'USER_ORDER_TAX_DESC',
    USER_ORDER_SERVICE_CHARGE_ASC: 'USER_ORDER_SERVICE_CHARGE_ASC',
    USER_ORDER_SERVICE_CHARGE_DESC: 'USER_ORDER_SERVICE_CHARGE_DESC',
    USER_ORDER_GP_ASC: 'USER_ORDER_GP_ASC',
    USER_ORDER_GP_DESC: 'USER_ORDER_GP_DESC',
    USER_ORDER_SALES_RETURN_ASC: 'USER_ORDER_SALES_RETURN_ASC',
    USER_ORDER_SALES_RETURN_DESC: 'USER_ORDER_SALES_RETURN_DESC',
    USER_ORDER_NET_SALES_ASC: 'USER_ORDER_NET_SALES_ASC',
    USER_ORDER_NET_SALES_DESC: 'USER_ORDER_NET_SALES_DESC',
    USER_ORDER_OUTLET_NAME_ASC: 'USER_ORDER_OUTLET_NAME_ASC',
    USER_ORDER_OUTLET_NAME_DESC: 'USER_ORDER_OUTLET_NAME_DESC',
    USER_ORDER_EMPLOYEE_NAME_ASC: 'USER_ORDER_EMPLOYEE_NAME_ASC',
    USER_ORDER_EMPLOYEE_NAME_DESC: 'USER_ORDER_EMPLOYEE_NAME_DESC',
    USER_ORDER_USER_NAME_ASC: 'USER_ORDER_USER_NAME_ASC',
    USER_ORDER_USER_NAME_DESC: 'USER_ORDER_USER_NAME_DESC',
    USER_ORDER_TYPE_ASC: 'USER_ORDER_TYPE_ASC',
    USER_ORDER_TYPE_DESC: 'USER_ORDER_TYPE_DESC',
    USER_ORDER_COURIER_NAME_ASC: 'USER_ORDER_COURIER_NAME_ASC',
    USER_ORDER_COURIER_NAME_DESC: 'USER_ORDER_COURIER_NAME_DESC',

    CART_ITEM_NAME_ASC: 'CART_ITEM_NAME_ASC',
    CART_ITEM_NAME_DESC: 'CART_ITEM_NAME_DESC',
    CART_ITEM_ORDER_TYPE_ASC: 'CART_ITEM_ORDER_TYPE_ASC',
    CART_ITEM_ORDER_TYPE_DESC: 'CART_ITEM_ORDER_TYPE_DESC',
    CART_ITEM_REMARKS_ASC: 'CART_ITEM_REMARKS_ASC',
    CART_ITEM_REMARKS_DESC: 'CART_ITEM_REMARKS_DESC',
    CART_ITEM_QUANTITY_ASC: 'CART_ITEM_QUANTITY_ASC',
    CART_ITEM_QUANTITY_DESC: 'CART_ITEM_QUANTITY_DESC',
    CART_ITEM_PRICE_ASC: 'CART_ITEM_PRICE_ASC',
    CART_ITEM_PRICE_DESC: 'CART_ITEM_PRICE_DESC',

    OPEN_DATE_ASC: 'OPEN_DATE_ASC',
    OPEN_DATE_DESC: 'OPEN_DATE_DESC',
    CLOSE_DATE_ASC: 'CLOSE_DATE_ASC',
    CLOSE_DATE_DESC: 'CLOSE_DATE_DESC',

    PRODUCT_CATEGORY_ASC: 'PRODUCT_CATEGORY_ASC',
    PRODUCT_CATEGORY_DESC: 'PRODUCT_CATEGORY_DESC',
    TOTAL_ITEMS_SOLD_ASC: 'TOTAL_ITEMS_SOLD_ASC',
    TOTAL_ITEMS_SOLD_DESC: 'TOTAL_ITEMS_SOLD_DESC',
    AVERAGE_COST_ASC: 'AVERAGE_COST_ASC',
    AVERAGE_COST_DESC: 'AVERAGE_COST_DESC',

    PAYMENT_METHOD_ASC: 'PAYMENT_METHOD_ASC',
    PAYMENT_METHOD_DESC: 'PAYMENT_METHOD_DESC',

    PRODUCT_NAME_ASC: 'PRODUCT_NAME_ASC',
    PRODUCT_NAME_DESC: 'PRODUCT_NAME_DESC',
    PRODUCT_CATEGORY_ASC: 'PRODUCT_CATEGORY_ASC',
    PRODUCT_CATEGORY_DESC: 'PRODUCT_CATEGORY_DESC',
    PRODUCT_SKU_ASC: 'PRODUCT_SKU_ASC',
    PRODUCT_SKU_DESC: 'PRODUCT_SKU_DESC',
    TOTAL_ITEMS_ASC: 'TOTAL_ITEMS_ASC',
    TOTAL_ITEMS_DESC: 'TOTAL_ITEMS_DESC',

    ADD_ON_NAME_ASC: 'ADD_ON_NAME_ASC',
    ADD_ON_NAME_DESC: 'ADD_ON_NAME_DESC',
    ADD_ON_CHOICES_ASC: 'ADD_ON_CHOICES_ASC',
    ADD_ON_CHOICES_DESC: 'ADD_ON_CHOICES_DESC',
    ITEM_NET_SALES_ASC: 'ITEM_NET_SALES_ASC',
    ITEM_NET_SALES_DESC: 'ITEM_NET_SALES_DESC',

    ONLINE_TRANSACTION_ASC: 'ONLINE_TRANSACTION_ASC',
    ONLINE_TRANSACTION_DESC: 'ONLINE_TRANSACTION_DESC',
    OFFLINE_TRANSACTION_ASC: 'OFFLINE_TRANSACTION_ASC',
    OFFLINE_TRANSACTION_DESC: 'OFFLINE_TRANSACTION_DESC',

    ONLINE_SALES_ASC: 'ONLINE_SALES_ASC',
    ONLINE_SALES_DESC: 'ONLINE_SALES_DESC',
    OFFLINE_SALES_ASC: 'OFFLINE_SALES_ASC',
    OFFLINE_SALES_DESC: 'OFFLINE_SALES_DESC',

    OPEN_BALANCE_ASC: 'OPEN_BALANCE_ASC',
    OPEN_BALANCE_DESC: 'OPEN_BALANCE_DESC',
    CLOSE_BALANCE_ASC: 'CLOSE_BALANCE_ASC',
    CLOSE_BALANCE_DESC: 'CLOSE_BALANCE_DESC',


    VOUCHER_NAME_ASC: 'VOUCHER_NAME_ASC',
    VOUCHER_NAME_DESC: 'VOUCHER_NAME_DESC',
    VOUCHER_TYPE_ASC: 'VOUCHER_TYPE_ASC',
    VOUCHER_TYPE_DESC: 'VOUCHER_TYPE_DESC',
    VOUCHER_VIEWED_ASC: 'VOUCHER_VIEWED_ASC',
    VOUCHER_VIEWED_DESC: 'VOUCHER_VIEWED_DESC',
    VOUCHER_REDEMPTION_ASC: 'VOUCHER_REDEMPTION_ASC',
    VOUCHER_REDEMPTION_DESC: 'VOUCHER_REDEMPTION_DESC',
    VOUCHER_USED_ASC: 'VOUCHER_USED_ASC',
    VOUCHER_USED_DESC: 'VOUCHER_USED_DESC',
    VOUCHER_USERNAME_ASC: 'VOUCHER_USERNAME_ASC',
    VOUCHER_USERNAME_DESC: 'VOUCHER_USERNAME_DESC',
    VOUCHER_REDEEMED_DATE_ASC: 'VOUCHER_REDEEMED_DATE_ASC',
    VOUCHER_REDEEMED_DATE_DESC: 'VOUCHER_REDEEMED_DATE_DESC',
    VOUCHER_USED_DATE_ASC: 'VOUCHER_USED_DATE_ASC',
    VOUCHER_USED_DATE_DESC: 'VOUCHER_USED_DATE_DESC',


    PROMOTION_TITLE_ASC: 'PROMOTION_TITLE_ASC',
    PROMOTION_TITLE_DESC: 'PROMOTION_TITLE_DESC',
    PROMOTION_TYPE_ASC: 'PROMOTION_TYPE_ASC',
    PROMOTION_TYPE_DESC: 'PROMOTION_TYPE_DESC',
    PROMOTION_START_DATE_ASC: 'PROMOTION_START_DATE_ASC',
    PROMOTION_START_DATE_DESC: 'PROMOTION_START_DATE_DESC',


};

export const REPORT_SORT_FIELD_TYPE_VALUE = {
    DATE_TIME_ASC: 'dateTimeRaw',
    DATE_TIME_DESC: 'dateTimeRaw',
    TOTAL_SALES_ASC: 'totalSales',
    TOTAL_SALES_DESC: 'totalSales',
    TOTAL_SALES_RETURN_ASC: 'totalSalesReturn',
    TOTAL_SALES_RETURN_DESC: 'totalSalesReturn',
    TOTAL_TRANSACTION_ASC: 'totalTransactions',
    TOTAL_TRANSACTION_DESC: 'totalTransactions',
    TOTAL_DISCOUNT_ASC: 'totalDiscount',
    TOTAL_DISCOUNT_DESC: 'totalDiscount',
    DISCOUNT_ASC: 'discount',
    DISCOUNT_DESC: 'discount',
    TAX_ASC: 'tax',
    TAX_DESC: 'discount',
    SERVICE_CHARGE_ASC: 'serviceCharge',
    SERVICE_CHARGE_DESC: 'serviceCharge',
    GP_ASC: 'gp',
    GP_DESC: 'gp',
    NET_SALES_ASC: 'netSales',
    NET_SALES_DESC: 'netSales',
    AVERAGE_NET_SALES_ASC: 'averageNetSales',
    AVERAGE_NET_SALES_DESC: 'averageNetSales',

    USER_ORDER_ID_ASC: 'orderId',
    USER_ORDER_ID_DESC: 'orderId',
    USER_ORDER_DATE_TIME_ASC: 'completedDate',
    USER_ORDER_DATE_TIME_DESC: 'completedDate',
    USER_ORDER_SALES_ASC: 'finalPrice',
    USER_ORDER_SALES_DESC: 'finalPrice',
    USER_ORDER_TOTAL_DISCOUNT_ASC: 'discount',
    USER_ORDER_TOTAL_DISCOUNT_DESC: 'discount',
    USER_ORDER_DISCOUNT_ASC: 'discountPercentage',
    USER_ORDER_DISCOUNT_DESC: 'discountPercentage',
    USER_ORDER_TAX_ASC: 'tax',
    USER_ORDER_TAX_DESC: 'tax',
    USER_ORDER_SERVICE_CHARGE_ASC: 'serviceCharge',
    USER_ORDER_SERVICE_CHARGE_DESC: 'serviceCharge',
    USER_ORDER_GP_ASC: 'gp',
    USER_ORDER_GP_DESC: 'gp',
    USER_ORDER_SALES_RETURN_ASC: 'totalPrice',
    USER_ORDER_SALES_RETURN_DESC: 'totalPrice',
    USER_ORDER_NET_SALES_ASC: 'totalPrice',
    USER_ORDER_NET_SALES_DESC: 'totalPrice',
    USER_ORDER_OUTLET_NAME_ASC: 'outletName',
    USER_ORDER_OUTLET_NAME_DESC: 'outletName',
    USER_ORDER_EMPLOYEE_NAME_ASC: 'waiterName',
    USER_ORDER_EMPLOYEE_NAME_DESC: 'waiterName',
    USER_ORDER_USER_NAME_ASC: 'userName',
    USER_ORDER_USER_NAME_DESC: 'userName',
    USER_ORDER_TYPE_ASC: 'orderType',
    USER_ORDER_TYPE_DESC: 'orderType',
    USER_ORDER_COURIER_NAME_ASC: 'courierCode',
    USER_ORDER_COURIER_NAME_DESC: 'courierCode',

    CART_ITEM_NAME_ASC: 'itemName',
    CART_ITEM_NAME_DESC: 'itemName',
    CART_ITEM_ORDER_TYPE_ASC: 'orderType',
    CART_ITEM_ORDER_TYPE_DESC: 'orderType',
    CART_ITEM_REMARKS_ASC: 'remarks',
    CART_ITEM_REMARKS_DESC: 'remarks',
    CART_ITEM_QUANTITY_ASC: 'quantity',
    CART_ITEM_QUANTITY_DESC: 'quantity',
    CART_ITEM_PRICE_ASC: 'price',
    CART_ITEM_PRICE_DESC: 'price',

    OPEN_DATE_ASC: 'openDate',
    OPEN_DATE_DESC: 'openDate',
    CLOSE_DATE_ASC: 'closeDate',
    CLOSE_DATE_DESC: 'closeDate',

    PRODUCT_CATEGORY_ASC: 'productCategory',
    PRODUCT_CATEGORY_DESC: 'productCategory',
    TOTAL_ITEMS_SOLD_ASC: 'totalItemsSold',
    TOTAL_ITEMS_SOLD_DESC: 'totalItemsSold',
    AVERAGE_COST_ASC: 'averageCost',
    AVERAGE_COST_DESC: 'averageCost',

    PAYMENT_METHOD_ASC: 'paymentMethod',
    PAYMENT_METHOD_DESC: 'paymentMethod',

    PRODUCT_NAME_ASC: 'productName',
    PRODUCT_NAME_DESC: 'productName',
    PRODUCT_CATEGORY_ASC: 'productCategory',
    PRODUCT_CATEGORY_DESC: 'productCategory',
    PRODUCT_SKU_ASC: 'productSku',
    PRODUCT_SKU_DESC: 'productSku',
    TOTAL_ITEMS_ASC: 'totalItems',
    TOTAL_ITEMS_DESC: 'totalItems',

    ADD_ON_NAME_ASC: 'addOnName',
    ADD_ON_NAME_DESC: 'addOnName',
    ADD_ON_CHOICES_ASC: 'addOnChoices',
    ADD_ON_CHOICES_DESC: 'addOnChoices',
    ITEM_NET_SALES_ASC: 'itemNetSales',
    ITEM_NET_SALES_DESC: 'itemNetSales',

    ONLINE_TRANSACTION_ASC: 'onlineTransaction',
    ONLINE_TRANSACTION_DESC: 'onlineTransaction',
    OFFLINE_TRANSACTION_ASC: 'offlineTransaction',
    OFFLINE_TRANSACTION_DESC: 'offlineTransaction',

    ONLINE_SALES_ASC: 'onlineSales',
    ONLINE_SALES_DESC: 'onlineSales',
    OFFLINE_SALES_ASC: 'offlineSales',
    OFFLINE_SALES_DESC: 'offlineSales',

    OPEN_BALANCE_ASC: 'openAmount',
    OPEN_BALANCE_DESC: 'openAmount',
    CLOSE_BALANCE_ASC: 'closeAmount',
    CLOSE_BALANCE_DESC: 'closeAmount',

    VOUCHER_NAME_ASC: 'voucherName',
    VOUCHER_NAME_DESC: 'voucherName',
    VOUCHER_TYPE_ASC: 'voucherType',
    VOUCHER_TYPE_DESC: 'voucherType',
    VOUCHER_VIEWED_ASC: 'totalViews',
    VOUCHER_VIEWED_DESC: 'totalViews',
    VOUCHER_REDEMPTION_ASC: 'totalRedeems',
    VOUCHER_REDEMPTION_DESC: 'totalRedeems',
    VOUCHER_USED_ASC: 'totalUses',
    VOUCHER_USED_DESC: 'totalUses',
    VOUCHER_USERNAME_ASC: 'userName',
    VOUCHER_USERNAME_DESC: 'userName',
    VOUCHER_REDEEMED_DATE_ASC: 'redeemDate',
    VOUCHER_REDEEMED_DATE_DESC: 'redeemDate',
    VOUCHER_USED_DATE_ASC: 'usedDate',
    VOUCHER_USED_DATE_DESC: 'usedDate',

    PROMOTION_TITLE_ASC: 'promotionName',
    PROMOTION_TITLE_DESC: 'promotionName',
    PROMOTION_TYPE_ASC: 'promotionType',
    PROMOTION_TYPE_DESC: 'promotionType',
    PROMOTION_START_DATE_ASC: 'startDate',
    PROMOTION_START_DATE_DESC: 'startDate',

};

export const PAYMENT_SORT_FIELD_TYPE_COMPARE ={
    PAYMENT_NAME_ASC: PAYMENT_SORT_COMPARE_OPERATOR.ASC,
    PAYMENT_NAME_DESC: PAYMENT_SORT_COMPARE_OPERATOR.DESC,
    PAYMENT_CREATED_DATE_ASC: PAYMENT_SORT_COMPARE_OPERATOR.ASC,
    PAYMENT_CREATED_DATE_DESC: PAYMENT_SORT_COMPARE_OPERATOR.DESC,
    PAYMENT_UPDATED_DATE_ASC: PAYMENT_SORT_COMPARE_OPERATOR.ASC,
    PAYMENT_UPDATED_DATE_DESC: PAYMENT_SORT_COMPARE_OPERATOR.DESC,
    PAYMENT_STATUS_ASC: PAYMENT_SORT_COMPARE_OPERATOR.ASC,
    PAYMENT_STATUS_DESC: PAYMENT_SORT_COMPARE_OPERATOR.DESC,
}

export const REPORT_SORT_FIELD_TYPE_COMPARE = {
    DATE_TIME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    DATE_TIME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    TOTAL_SALES_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    TOTAL_SALES_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    TOTAL_SALES_RETURN_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    TOTAL_SALES_RETURN_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    TOTAL_TRANSACTION_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    TOTAL_TRANSACTION_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    TOTAL_DISCOUNT_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    TOTAL_DISCOUNT_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    DISCOUNT_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    DISCOUNT_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    TAX_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    TAX_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    SERVICE_CHARGE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    SERVICE_CHARGE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    GP_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    GP_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    NET_SALES_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    NET_SALES_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    AVERAGE_NET_SALES_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    AVERAGE_NET_SALES_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    USER_ORDER_ID_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_ID_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_DATE_TIME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_DATE_TIME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_SALES_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_SALES_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_TOTAL_DISCOUNT_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_TOTAL_DISCOUNT_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_DISCOUNT_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_DISCOUNT_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_TAX_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_TAX_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_SERVICE_CHARGE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_SERVICE_CHARGE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_GP_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_GP_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_SALES_RETURN_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_SALES_RETURN_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_NET_SALES_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_NET_SALES_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_OUTLET_NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_OUTLET_NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_EMPLOYEE_NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_EMPLOYEE_NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_USER_NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_USER_NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_TYPE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_TYPE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    USER_ORDER_COURIER_NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    USER_ORDER_COURIER_NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    CART_ITEM_NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    CART_ITEM_NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    CART_ITEM_ORDER_TYPE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    CART_ITEM_ORDER_TYPE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    CART_ITEM_REMARKS_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    CART_ITEM_REMARKS_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    CART_ITEM_QUANTITY_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    CART_ITEM_QUANTITY_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    CART_ITEM_PRICE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    CART_ITEM_PRICE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    OPEN_DATE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    OPEN_DATE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    CLOSE_DATE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    CLOSE_DATE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    PRODUCT_CATEGORY_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    PRODUCT_CATEGORY_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    TOTAL_ITEMS_SOLD_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    TOTAL_ITEMS_SOLD_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    AVERAGE_COST_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    AVERAGE_COST_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    PAYMENT_METHOD_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    PAYMENT_METHOD_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    PRODUCT_NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    PRODUCT_NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    PRODUCT_CATEGORY_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    PRODUCT_CATEGORY_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    PRODUCT_SKU_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    PRODUCT_SKU_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    TOTAL_ITEMS_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    TOTAL_ITEMS_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    ADD_ON_NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    ADD_ON_NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    ADD_ON_CHOICES_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    ADD_ON_CHOICES_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    ITEM_NET_SALES_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    ITEM_NET_SALES_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    ONLINE_TRANSACTION_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    ONLINE_TRANSACTION_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    OFFLINE_TRANSACTION_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    OFFLINE_TRANSACTION_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    ONLINE_SALES_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    ONLINE_SALES_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    OFFLINE_SALES_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    OFFLINE_SALES_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    OPEN_BALANCE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    OPEN_BALANCE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    CLOSE_BALANCE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    CLOSE_BALANCE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    //Temporary placed
    NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    NUMBER_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    NUMBER_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    GENDER_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    GENDER_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    DOB_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    DOB_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    RACE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    RACE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    STATUS_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    STATUS_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    QUEUE_ID_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    QUEUE_ID_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    DATE_TIME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    DATE_TIME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    CAPACITY_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    CAPACITY_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    WAITING_TIME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    WAITING_TIME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    STATUS_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    STATUS_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    SEGMENT_GROUP_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    SEGMENT_GROUP_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    //DINE_IN_SORT
    TABLE_CODE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    TABLE_CODE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    ORDER_ID_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    ORDER_ID_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    ORDER_DATE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    ORDER_DATE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    WAITER_NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    WAITER_NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    WAITING_TIME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    WAITING_TIME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    PAYMENT_DETAILS_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    PAYMENT_DETAILS_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    FINAL_PRICE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    FINAL_PRICE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,


    VOUCHER_NAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    VOUCHER_NAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    VOUCHER_TYPE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    VOUCHER_TYPE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    VOUCHER_VIEWED_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    VOUCHER_VIEWED_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    VOUCHER_REDEMPTION_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    VOUCHER_REDEMPTION_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    VOUCHER_USED_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    VOUCHER_USED_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    VOUCHER_USERNAME_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    VOUCHER_USERNAME_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    VOUCHER_REDEEMED_DATE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    VOUCHER_REDEEMED_DATE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    VOUCHER_USED_DATE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    VOUCHER_USED_DATE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,

    PROMOTION_TITLE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    PROMOTION_TITLE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    PROMOTION_TYPE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    PROMOTION_TYPE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
    PROMOTION_START_DATE_ASC: REPORT_SORT_COMPARE_OPERATOR.ASC,
    PROMOTION_START_DATE_DESC: REPORT_SORT_COMPARE_OPERATOR.DESC,
};

// const PAYMENT_CHANNEL_NAME_PARSED = {
//     'FPX-TPA': 'PayNet',
// };

const TABLE_PAGE_SIZE = {
    TEN: 10,
    TWENTY: 20,
    THIRTY: 30,
    FIFTY: 50,
    ALL: 'ALL',
};

export const TABLE_PAGE_SIZE_DROPDOWN_LIST = [
    {
        label: '10',
        value: 10,
    },
    {
        label: '20',
        value: 20,
    },
    {
        label: '30',
        value: 30,
    },
    {
        label: '50',
        value: 50,
    },
    {
        label: '100',
        value: 100,
    },
];

export const MODE_ADD_CART = {
    NORMAL: 'NORMAL',
    PAYMENT_SUMMARY_EDIT: 'PAYMENT_SUMMARY_EDIT',
    PAYMENT_SUMMARY_ADD: 'PAYMENT_SUMMARY_ADD',
};

export const OFFLINE_PAYMENT_METHOD_TYPE = {
    CREDIT_CARD: {
        name: 'Credit Card',
        channel: 'Offline-Credit-Card',
    },
    PAYPAL: {
        name: 'PayPal',
        channel: 'Offline-PayPal',
    },
    GRABPAY: {
        name: 'GrabPay',
        channel: 'Offline-GrabPay',
    },
    CASH: {
        name: 'Cash',
        channel: 'Offline-Cash',
    },
    BANK_TRANSFER: {
        name: 'Bank Transfer',
        channel: 'Offline-Bank-Transfer',
    },
    ALIPAY: {
        name: 'Alipay',
        channel: 'Offline-Alipay',
    },
    BOOST: {
        name: 'Boost',
        channel: 'Offline-Boost',
    },
    FAVEPAY: {
        name: 'favePAY',
        channel: 'Offline-favePAY',
    },
    TOUCHNGO_EWALLET: {
        name: 'TouchnGo',
        channel: 'Offline-TouchnGo-eWallet',
    },
    WECHAT_PAY: {
        name: 'WeChat Pay',
        channel: 'Offline-WeChat-Pay',
    },
    AMEX: {
        name: 'Credit Card',
        channel: 'Offline-Credit-Amex',
    },
    BCARD_POINTS: {
        name: 'BCard',
        channel: 'Offline-Bcard-Points',
    },
    EPAY: {
        name: 'e-pay',
        channel: 'Offline-e-pay',
    },
    MAYBANK_QRPAY: {
        name: 'Maybank',
        channel: 'Offline-Maybank-QRPAY',
    },
    RAZER_CASH: {
        name: 'RAZER CASH',
        channel: 'Offline-RAZER-CASH',
    },
    RAZER_PAY: {
        name: 'RAZER PAY',
        channel: 'Offline-RAZER-PAY',
    },
    WEBCASH: {
        name: 'WEBCASH',
        channel: 'Offline-WEBCASH',
    },
    DEBIT_CARD: {
        name: 'Debit Card',
        channel: 'Offline-Debit-Card',
    },
    VISA: {
        name: 'Credit Card',
        channel: 'Offline-Credit-VISA',
    },
    MASTERCARD: {
        name: 'Credit Card',
        channel: 'Offline-Credit-MasterCard',
    },
};

export const OFFLINE_PAYMENT_METHOD_DROPDOWN_LIST = [
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.CASH.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.CASH,
        image: require('../assets/image/coin.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.BANK_TRANSFER.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.BANK_TRANSFER,
        image: require('../assets/image/LeftRight.png'),        
    },
    // {
    //     name: OFFLINE_PAYMENT_METHOD_TYPE.CREDIT_CARD.name,
    //     value: OFFLINE_PAYMENT_METHOD_TYPE.CREDIT_CARD,
    //     image: require('../assets/image/offline_payment_method_credit_card.png'),
    // },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.DEBIT_CARD.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.DEBIT_CARD,
        image: require('../assets/image/offline_payment_method_visa_mastercard_amex.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.VISA.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.VISA,
        image: require('../assets/image/offline_payment_method_visa.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.MASTERCARD.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.MASTERCARD,
        image: require('../assets/image/offline_payment_method_mastercard.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.AMEX.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.AMEX,
        image: require('../assets/image/offline_payment_method_amex.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.PAYPAL.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.PAYPAL,
        image: require('../assets/image/offline_payment_method_paypal.png'),

    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.GRABPAY.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.GRABPAY,
        image: require('../assets/image/offline_payment_method_grabpay.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.ALIPAY.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.ALIPAY,
        image: require('../assets/image/offline_payment_method_alipay.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.BOOST.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.BOOST,
        image: require('../assets/image/offline_payment_method_boost.png'),
    },
    // {
    //     name: OFFLINE_PAYMENT_METHOD_TYPE.FAVEPAY.name,
    //     value: OFFLINE_PAYMENT_METHOD_TYPE.FAVEPAY,
    //     image: require('../assets/image/offline_payment_method_fave_pay.png'),
    // },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.TOUCHNGO_EWALLET.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.TOUCHNGO_EWALLET,
        image: require('../assets/image/offline_payment_method_touchngo_ewallet.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.WECHAT_PAY.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.WECHAT_PAY,
        image: require('../assets/image/offline_payment_method_wechat_pay.png'),
    },

    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.BCARD_POINTS.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.BCARD_POINTS,
        image: require('../assets/image/offline_payment_method_bcard_points.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.EPAY.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.EPAY,
        image: require('../assets/image/offline_payment_method_epay.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.MAYBANK_QRPAY.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.MAYBANK_QRPAY,
        image: require('../assets/image/offline_payment_method_maybank_qrpay.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.RAZER_PAY.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.RAZER_PAY,
        image: require('../assets/image/offline_payment_method_razerpay.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.RAZER_CASH.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.RAZER_CASH,
        image: require('../assets/image/offline_payment_method_razer_cash.png'),
    },
    {
        name: OFFLINE_PAYMENT_METHOD_TYPE.WEBCASH.name,
        value: OFFLINE_PAYMENT_METHOD_TYPE.WEBCASH,
        image: require('../assets/image/offline_payment_method_webcash.png'),
    },
];

export const OFFLINE_BILL_TYPE = {
    SUMMARY: 'SUMMARY',
    INDIVIDUAL: 'INDIVIDUAL',
    PRODUCT: 'PRODUCT',
};

export const TICK_LIST_TYPE = {
    UNTICK: 'UNTICK',
    NORMAL: 'NORMAL',
    DELIVERED: 'DELIVERED',
    CANCELLED: 'CANCELLED',
};

export const CART_BEER_DOCKET_ACTION = {
    PURCHASE_DOCKET: 'PURCHASE_DOCKET',
    EXTEND_DOCKET: 'EXTEND_DOCKET',
    REDEEM_DOCKET: 'REDEEM_DOCKET',
};

export const EMAIL_REPORT_TYPE = {
    EXCEL: 'EXCEL',
    CSV: 'CSV',
};

export const PAYMENT_CHANNEL_NAME_PARSED = {
    'CIMB-Clicks': 'CIMB',
    'HLB-ONL': 'HLB',
    'FPX-TPA': 'FPX',
    'FPX_BIMB': 'BIMB',
    'FPX_PBB': 'PBB',
    'RHB-ONL': 'RHB',
    'ALB-Paymex': 'ALB',
    'MB2u': 'MAYBANK',
    'Affin-EPG': 'Affin',
    'Cash-epay': 'epay',
    'Offline-Cash': 'Cash',
    'Offline-Credit-Card': 'Credit Card',
    'Offline-Bank-Transfer': 'Bank Transfer',
    'Offline-Alipay': 'Alipay',
    'Offline-Boost': 'Boost',
    'Offline-favePAY': 'favePAY',
    'Offline-TouchnGo-eWallet': 'TouchnGo eWallet',
    'Offline-WeChat-Pay': 'WeChat Pay',
    'Offline-Credit-Amex': 'Amex',
    'Offline-Bcard-Points': 'Bcard Points',
    'Offline-e-pay': 'e-pay',
    'Offline-Maybank-QRPAY': 'Maybank QRPAY',
    'Offline-RAZER-CASH': 'Razer Cash',
    'Offline-RAZER-PAY': 'RazerPay',
    'Offline-WEBCASH': 'WebCash',
    'Offline-Debit-Card': 'Debit Card',
    'Offline-Credit-VISA': 'VISA',
    'Offline-Credit-MasterCard': 'MasterCard',
};

export const EXPAND_TAB_TYPE = {
    DASHBOARD: 'DASHBOARD',
    OPERATION: 'OPERATION',
    PRODUCT: 'PRODUCT',
    INVENTORY: 'INVENTORY',
    DOCKET: 'DOCKET',
    VOUCHER: 'VOUCHER',
    PROMOTION: 'PROMOTION',
    CRM: 'CRM',
    LOYALTY: 'LOYALTY',
    TRANSACTIONS: 'TRANSACTIONS',
    REPORT: 'REPORT',
    EMPLOYEES: 'EMPLOYEES',
    SETTINGS: 'SETTINGS',
    LOGOUT: 'LOGOUT',
    RESERVATIONS: 'RESERVATIONS',
};

export const SENDER_DROPDOWN_LIST = [
    {
        label: 'Lalamove',
        value: COURIER_CODE.LALAMOVE,
        img: require('../asset/image/courier-lalamove.png'),
    },
    {
        label: 'MrSpeedy',
        value: COURIER_CODE.MRSPEEDY,
        img: require('../asset/image/courier-mrspeedy.png'),
    },
    {
        label: 'Teleport',
        value: COURIER_CODE.TELEPORT,
        img: require('../asset/image/courier-teleport.png'),
    },
];

export const RESET_DATA_TYPE = {
    USER_ORDER: 'USER_ORDER',
    CRM_USER: 'CRM_USER',
};

export const MERCHANT_DATA_FILTER = {
    ALL: 'ALL',
    ACTIVE_24H: 'ACTIVE_24H',
    ACTIVE_7D: 'ACTIVE_7D',
    ACTIVE_1M: 'ACTIVE_1M',
    ACTIVE_3M: 'ACTIVE_3M',
};
