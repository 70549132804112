export const LOYALTY_CAMPAIGN_TYPE = {    
    FIRST_VISIT: 'FIRST_VISIT',
    AT_RISK: 'AT_RISK',
    BIRTHDAY: 'BIRTHDAY',
    GROWTH: 'GROWTH',
    BIG_SPENDER: 'BIG_SPENDER',
    SIGN_UP: 'SIGN_UP',
};

export const LOYALTY_CAMPAIGN_TYPE_PARSED = {    
    FIRST_VISIT: '1st Visit',
    AT_RISK: 'At Risk',
    BIRTHDAY: 'Birthday',
    GROWTH: 'Growth',
    BIG_SPENDER: 'Big Spender',
    SIGN_UP: 'Sign Up',
};

export const LOYALTY_CAMPAIGN_DROPDOWN_LIST = [
    {
        label: '1st Visit',
        value: LOYALTY_CAMPAIGN_TYPE.FIRST_VISIT,
    },
    {
        label: 'At Risk',
        value: LOYALTY_CAMPAIGN_TYPE.AT_RISK,
    },
    {
        label: 'Birthday',
        value: LOYALTY_CAMPAIGN_TYPE.BIRTHDAY,
    },
    {
        label: 'Growth',
        value: LOYALTY_CAMPAIGN_TYPE.GROWTH,
    },
    {
        label: 'Big Spender',
        value: LOYALTY_CAMPAIGN_TYPE.BIG_SPENDER,
    },
    {
        label: 'Sign Up',
        value: LOYALTY_CAMPAIGN_TYPE.SIGN_UP,
    },
];

export const LOYALTY_PROMOTION_TYPE = {    
    OVERRIDE_EXISTING_PRICE: 'OVERRIDE_EXISTING_PRICE',
    BUY_A_GET_B_FOR_FREE: 'BUY_A_GET_B_FOR_FREE',    
    PWP: 'PWP',    
    TAKE_AMOUNT_OFF: 'TAKE_AMOUNT_OFF',
    TAKE_PERCENTAGE_OFF: 'TAKE_PERCENTAGE_OFF',
    CASHBACK: 'CASHBACK',
    COMBO_SET_OR_PACKAGE_OR_BUNDLE: 'COMBO_SET_OR_PACKAGE_OR_BUNDLE',
    BUY_A_GET_X_FOR_DISCOUNT: 'BUY_A_GET_B_FOR_%_DISCOUNT',
    BUY_AB_GET_VOUCHER: 'BUY_AB_GET_VOUCHER',
    DELIVERY: 'DELIVERY',
    TAKEAWAY: 'TAKEAWAY',
};

export const LOYALTY_PROMOTION_TYPE_PARSED = {    
    OVERRIDE_EXISTING_PRICE: 'Override Existing Price',
    BUY_A_GET_B_FOR_FREE: 'Bundle Deal',
    PWP: 'Purchase With Purchase',    
    TAKE_AMOUNT_OFF: 'Take Amount Off',
    TAKE_PERCENTAGE_OFF: 'Take Percentage Off',
    CASHBACK: 'Cashback',
    COMBO_SET_OR_PACKAGE_OR_BUNDLE: 'Combo Set / Package / Bundle',
    BUY_A_GET_X_FOR_DISCOUNT: 'Buy A Get X For Discount',
    BUY_AB_GET_VOUCHER: 'Buy AB Get Voucher',
    DELIVERY: 'Delivery',
    TAKEAWAY: 'Takeaway',
};

export const LOYALTY_PROMOTION_TYPE_DROPDOWN_LIST = [
    // {
    //     label: 'Cashback',
    //     value: LOYALTY_PROMOTION_TYPE.CASHBACK,
    // },
    {
        label: 'Override Existing Price',
        value: LOYALTY_PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE,
    },
    {
        label: 'Bundle Deal',
        value: LOYALTY_PROMOTION_TYPE.BUY_A_GET_B_FOR_FREE,
    },
    // {
    //     label: 'PWP',
    //     value: LOYALTY_PROMOTION_TYPE.PWP,
    // },    
    {
        label: 'Take Amount Off',
        value: LOYALTY_PROMOTION_TYPE.TAKE_AMOUNT_OFF,
    },
    {
        label: 'Take Percentage Off',
        value: LOYALTY_PROMOTION_TYPE.TAKE_PERCENTAGE_OFF,
    },
    {
        label: 'Delivery',
        value: LOYALTY_PROMOTION_TYPE.DELIVERY,
    },
    {
        label: 'Takeaway',
        value: LOYALTY_PROMOTION_TYPE.TAKEAWAY,
    },    
    // {
    //     label: 'Combo Set/Package/Bundle',
    //     value: LOYALTY_PROMOTION_TYPE.COMBO_SET_OR_PACKAGE_OR_BUNDLE,
    // },
    // {
    //     label: 'Buy A Get B For % Discount',
    //     value: LOYALTY_PROMOTION_TYPE.BUY_A_GET_X_FOR_DISCOUNT,
    // },
    // {
    //     label: 'Buy AB Get Voucher',
    //     value: LOYALTY_PROMOTION_TYPE.BUY_AB_GET_VOUCHER,
    // }
];

export const LOYALTY_CAMPAIGN_CREDIT_TRANSACTION_TYPE = {
    EARN: 'EARN',
    DEDUCT: 'DEDUCT',
    REDEEM: 'REDEEM',
    EXPIRED: 'EXPIRED',
    INVALID: 'INVALID',
};

export const LOYALTY_CAMPAIGN_CREDIT_TRANSACTION_TYPE_PARSED = {
    EARN: 'Earn',
    DEDUCT: 'Deduct',
    REDEEM: 'Redeem',
    EXPIRED: 'Expired',
    INVALID: 'Invalid',
};
