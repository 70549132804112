import React, { Component, useReducer, useState, useEffect } from 'react';
import {
    StyleSheet,
    ScrollView,
    Image,
    View,
    Text,
    Alert,
    TouchableOpacity,
    Dimensions,
    Modal,
    useWindowDimensions,
} from 'react-native';
import Colors from '../constant/Colors';
import SideBar from './SideBar';
import Icon from 'react-native-vector-icons/Feather';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Ionicon from 'react-native-vector-icons/Ionicons';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
import { TextInput, FlatList } from 'react-native-gesture-handler';
import { Picker } from "react-native";
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import API from '../constant/API';
import ApiClient from '../util/ApiClient';
import * as User from '../util/User';
import LoginScreen from './LoginScreen';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { color } from 'react-native-reanimated';
import Close from 'react-native-vector-icons/AntDesign';
import Styles from '../constant/Styles';
import moment, { isDate } from 'moment';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { CommonStore } from '../store/commonStore';
import Select from 'react-select';


const SettingScreen = props => {
    const {
        navigation,
    } = props;

    const [loading, setLoading] = useState(false);
    const [switchMerchant, setSwitchMerchant] = useState(false);
    const [order, setOrder] = useState(true);
    const { height: windowHeight, width: windowWidth } = useWindowDimensions();

    //////////////////////////////////////////////////////////////////////////

    const [deliveryDistance, setDeliveryDistance] = useState('1');
    const [freeDeliveyAboveAmountValue, setFreeDeliveyAboveAmountValue] = useState('');
    const [freeDeliveyAboveAmountFlag, setFreeDeliveyAboveAmountFlag] = useState(false);
    const [discountOrderAboveAmountValue, setDiscountOrderAboveAmountValue] = useState('');
    const [discountOrderAboveAmountThreshold, setDiscountOrderAboveAmountThreshold] = useState('');

    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [pickUpPrice, setPickUpPrice] = useState(0);

    const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
    const [selectedTargetOutletId, setSelectedTargetOutletId] = useState('');

    const allOutlets = MerchantStore.useState(s => s.allOutlets);
    const merchantId = UserStore.useState(s => s.merchantId);

    const userName = UserStore.useState(s => s.name);
    const merchantName = MerchantStore.useState(s => s.name);
    const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);

    useEffect(() => {
        const selectedTargetOutlet = allOutlets.find(outlet => outlet.uniqueId === selectedTargetOutletId);

        if (selectedTargetOutlet) {
            setDeliveryDistance(selectedTargetOutlet.deliveryDistance ? selectedTargetOutlet.deliveryDistance.toFixed(0) : '1');
            setFreeDeliveyAboveAmountValue(selectedTargetOutlet.freeDeliveyAboveAmountValue ? selectedTargetOutlet.freeDeliveyAboveAmountValue.toFixed(2) : '0');
            setFreeDeliveyAboveAmountFlag(selectedTargetOutlet.freeDeliveyAboveAmountFlag ? selectedTargetOutlet.freeDeliveyAboveAmountFlag : false);
            setDiscountOrderAboveAmountValue(selectedTargetOutlet.discountOrderAboveAmountValue ? selectedTargetOutlet.discountOrderAboveAmountValue.toFixed(2) : '0');
            setDiscountOrderAboveAmountThreshold(selectedTargetOutlet.discountOrderAboveAmountThreshold ? selectedTargetOutlet.discountOrderAboveAmountThreshold.toFixed(2) : '0');

            setDeliveryPrice(selectedTargetOutlet.deliveryPrice ? selectedTargetOutlet.deliveryPrice.toFixed(2) : '0');
            setPickUpPrice(selectedTargetOutlet.pickUpPrice ? selectedTargetOutlet.pickUpPrice.toFixed(2) : '0');
        }
    }, [selectedTargetOutletId]);

    useEffect(() => {
        setTargetOutletDropdownList(allOutlets.map(outlet => ({ label: outlet.name, value: outlet.uniqueId })));

        if (selectedTargetOutletId === '' && allOutlets.length > 0) {
            setSelectedTargetOutletId(allOutlets[0].uniqueId);
        }
    }, [allOutlets]);

    //////////////////////////////////////////////////////////////////////////

    const setState = () => { };

    navigation.setOptions({
        headerLeft: () => (
            <View style={{
                width: Dimensions.get('screen').width * 0.17,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Image
                    style={{
                        width: 124,
                        height: 26,
                    }}
                    resizeMode="contain"
                    source={require('../assets/image/logo.png')}
                />
            </View>
        ),
        headerTitle: () => (
            <View style={[{
                justifyContent: 'center',
                alignItems: 'center',
                // bottom: -2,
                bottom: switchMerchant ? '2%' : 0,
            }, Dimensions.get('screen').width >= 768 && switchMerchant ? { right: Dimensions.get('screen').width * 0.1 } : {}, Dimensions.get('screen').width <= 768 ? { right: Dimensions.get('screen').width * 0.12 } : {}]}>
                <Text
                    style={{
                        fontSize: switchMerchant ? 20 : 24,
                        // lineHeight: 25,
                        textAlign: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        color: Colors.whiteColor,
                        opacity: 1,
                    }}>
                    Order Settings
                </Text>
            </View>
        ),
        headerRight: () => (
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}>
                {outletSelectDropdownView()}
                <View style={{
                    backgroundColor: 'white',
                    width: 0.5,
                    height: Dimensions.get('screen').height * 0.025,
                    opacity: 0.8,
                    marginHorizontal: 15,
                    bottom: -1,
                }}>
                </View>
                <TouchableOpacity onPress={() => navigation.navigate('Setting')}
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                >
                    <Text
                        style={{
                            fontFamily: 'NunitoSans-SemiBold',
                            fontSize: 16,
                            color: Colors.secondaryColor,
                            marginRight: 15,
                        }}>
                        {userName}
                    </Text>
                    <View style={{
                        marginRight: 30,
                        width: Dimensions.get('screen').height * 0.05,
                        height: Dimensions.get('screen').height * 0.05,
                        borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                    }}>
                        <Image style={{
                            width: Dimensions.get('screen').height * 0.035,
                            height: Dimensions.get('screen').height * 0.035,
                            alignSelf: 'center',
                        }} source={require('../assets/image/profile-pic.jpg')} />
                    </View>
                </TouchableOpacity>
            </View>
        ),
    });

    const orderFunc = () => {

        var body = {
            merchantId: merchantId,
            outletId: selectedTargetOutletId,
            deliveryDistance: +(parseFloat(deliveryDistance).toFixed(2)),
            freeDeliveyAboveAmountValue: +(parseFloat(freeDeliveyAboveAmountValue).toFixed(2)),
            freeDeliveyAboveAmountFlag: freeDeliveyAboveAmountFlag,
            discountOrderAboveAmountValue: +(parseFloat(discountOrderAboveAmountValue).toFixed(2)),
            discountOrderAboveAmountThreshold: +(parseFloat(discountOrderAboveAmountThreshold).toFixed(2)),
            deliveryPrice: +(parseFloat(deliveryPrice).toFixed(2)),
            pickUpPrice: +(parseFloat(pickUpPrice).toFixed(2)),
        };

        console.log(body);

        ApiClient.POST(API.updateOutletOrderDetails, body, false).then((result) => {
            setLoading(true)
            if (result && result.status === 'success') {
                Alert.alert(
                    'Success',
                    'Updated successfully',
                    [
                        {
                            text: 'OK',
                            onPress: () => {
                                setLoading(false)
                            },
                        },
                    ],
                    { cancelable: false },
                );
            }
        });
    }

    const _logout = async () => {
        await AsyncStorage.clear();
        User.setlogin(false);
        User.getRefreshMainScreen();
    };

    const addSection = () => {
        setState({ showNote: true });
    }

    // function end

    const week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    return (
        <View
            style={[
                styles.container,
                {
                    height: windowHeight,
                    width: windowWidth,
                },
            ]}>
            <View style={{ flex: 0.8 }}>
                <SideBar navigation={props.navigation} selectedTab={10} expandSettings={true} />
            </View>

            <View style={{ height: windowHeight, flex: 9 }}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    // scrollEnabled={switchMerchant}
                    style={{ backgroundColor: Colors.highlightColor }}
                    contentContainerStyle={{
                        paddingBottom: Dimensions.get('screen').height * 0.1,
                        backgroundColor: Colors.highlightColor,
                    }}>
                    <View style={styles.content}>

                        {order ? (
                            <View
                                style={{
                                    backgroundColor: Colors.whiteColor,
                                    height: '100%',
                                    width: windowWidth * 0.87,
                                    alignSelf: 'center',

                                    shadowColor: '#000',
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    // elevation: 1,
                                    elevation: 3,
                                    borderRadius: 5,

                                    // borderRadius: 8,
                                }}>
                                <ScrollView
                                    contentContainerStyle={{}}>
                                    <View
                                        style={{
                                        }}>
                                        <View style={{ flexDirection: 'row', padding: 30, zIndex: -3 }}>
                                            <View style={{ flex: 3 }}>
                                                <View style={{ flexDirection: 'row', flex: 1, width: '100%', alignItems: 'center' }}>
                                                    {/* left */}
                                                    <View style={{ justifyContent: 'center', flex: 1 }}>
                                                        <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>Choose Outlet </Text>
                                                    </View>
                                                    <View style={{ flex: 1 }}>
                                                        <View
                                                            style={{
                                                                width: switchMerchant ? Dimensions.get('screen').width * 0.24 : Dimensions.get('screen').width * 0.26,
                                                                height: 50,
                                                                justifyContent: 'center',
                                                                marginLeft: '5.3%',

                                                            }}>
                                                            {/* <Picker
                                                                labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                containerStyle={{ height: 40 }}
                                                                defaultValue={selectedTargetOutletId}
                                                                placeholderStyle={{ color: 'black' }}
                                                                style={{ backgroundColor: '#fafafa' }}
                                                                itemStyle={{
                                                                    justifyContent: 'flex-start', marginLeft: 5,
                                                                }}
                                                                dropDownStyle={{ backgroundColor: '#fafafa' }}
                                                                onChangeItem={(item) => {
                                                                    setSelectedTargetOutletId(item.value)
                                                                }}
                                                            >
                                                                {allOutlets.map((value, index) => {
                                                                    return (
                                                                        <Picker.Item
                                                                            key={index}
                                                                            label={value.name}
                                                                            value={value.uniqueId}
                                                                        />
                                                                    );
                                                                })}
                                                            </Picker> */}
                                                            <Select
                                                                defaultValue={selectedTargetOutletId}
                                                                style={{backgroundColor: '#fafafa'}}
                                                                onChange={(item) => {
                                                                    setSelectedTargetOutletId(item.value);
                                                                }}
                                                                options={targetOutletDropdownList}
                                                            />
                                                        </View>
                                                    </View>

                                                    <View
                                                        style={{
                                                            justifyContent: 'center',
                                                            flex: 1,
                                                            marginLeft: '5%',
                                                        }}>
                                                    </View>
                                                </View>
                                                <View
                                                    style={{ flexDirection: 'row', flex: 2, marginTop: 30, zIndex: -3 }}>
                                                    {/* left */}
                                                    <View style={{ justifyContent: 'center', flex: 1 }}>
                                                        <View>
                                                            <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                                                                Delivery Max Distance{' '}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ flex: 1, flexDirection: 'row' }}>
                                                        <View
                                                            style={{
                                                                width: switchMerchant ? Dimensions.get('screen').width * 0.24 : Dimensions.get('screen').width * 0.26,
                                                                height: 50,
                                                                justifyContent: 'center',
                                                            }}>
                                                            <TextInput
                                                                underlineColorAndroid={Colors.fieldtBgColor}
                                                                style={[styles.textInputTakeawayCharges, { backgroundColor: Colors.fieldtBgColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }, { width: switchMerchant ? Dimensions.get('screen').width * 0.24 : Dimensions.get('screen').width * 0.26 }]}
                                                                placeholderStyle={{ color: 'black', height: 40, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                                                                keyboardType={'number-pad'}
                                                                placeholder="0"
                                                                defaultValue={pickUpPrice}
                                                                onChangeText={text => {
                                                                    setDeliveryDistance(text);
                                                                }}
                                                            />
                                                        </View>
                                                        <View
                                                            style={{
                                                                marginLeft: switchMerchant ? 5 : 5,
                                                                justifyContent: 'center',
                                                            }}>
                                                            <Text
                                                                style={{
                                                                    fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                                    color: 'black',
                                                                    opacity: 1,
                                                                    justifyContent: 'center'
                                                                }}>
                                                                KM
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ flex: 1 }}></View>
                                                </View>

                                                <View
                                                    style={{ flexDirection: 'row', flex: 2, marginTop: 30, zIndex: -3 }}>
                                                    {/* left */}
                                                    <View style={{ justifyContent: 'center', flex: 1 }}>
                                                        <View>
                                                            <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                                                                Takeaway Charges (RM){' '}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ flex: 1, }}>
                                                        <View
                                                            style={{
                                                                width: switchMerchant ? Dimensions.get('screen').width * 0.26 : Dimensions.get('screen').width * 0.26,
                                                                height: 50,
                                                                justifyContent: 'center',
                                                            }}>
                                                            <TextInput
                                                                underlineColorAndroid={Colors.fieldtBgColor}
                                                                style={[styles.textInputTakeawayCharges, { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }, { width: switchMerchant ? Dimensions.get('screen').width * 0.24 : Dimensions.get('screen').width * 0.26 }]}
                                                                placeholderStyle={{ color: 'black', fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                itemStyle={{ justifyContent: 'flex-start', marginLeft: 10, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                keyboardType={'number-pad'}
                                                                placeholder="0"
                                                                containerStyle={{ height: 40 }}
                                                                itemStyle={{
                                                                    justifyContent: 'flex-start', marginLeft: 5,
                                                                }}
                                                                defaultValue={pickUpPrice}
                                                                onChangeText={text => {
                                                                    setPickUpPrice(text);
                                                                }}
                                                            />
                                                        </View>
                                                    </View>

                                                    <View style={{ flex: 1 }}></View>
                                                </View>

                                                <View
                                                    style={{ flexDirection: 'row', flex: 2, marginTop: 30, zIndex: -3 }}>
                                                    {/* left */}
                                                    <View style={{ justifyContent: 'center', flex: 1 }}>
                                                        <View>
                                                            <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                                                                Delivery Charges (RM){' '}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                    <View style={{ flex: 1, }}>
                                                        <View
                                                            style={{
                                                                width: switchMerchant ? Dimensions.get('screen').width * 0.26 : Dimensions.get('screen').width * 0.26,
                                                                height: 50,
                                                                justifyContent: 'center',
                                                            }}>
                                                            <TextInput
                                                                underlineColorAndroid={Colors.fieldtBgColor}
                                                                style={[styles.textInputTakeawayCharges, { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }, { width: switchMerchant ? Dimensions.get('screen').width * 0.24 : Dimensions.get('screen').width * 0.26 }]}
                                                                placeholderStyle={{ color: 'black', fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                itemStyle={{ justifyContent: 'flex-start', marginLeft: 10, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                keyboardType={'number-pad'}
                                                                placeholder="0"
                                                                containerStyle={{ height: 40 }}
                                                                itemStyle={{
                                                                    justifyContent: 'flex-start', marginLeft: 5,
                                                                }}
                                                                defaultValue={deliveryPrice}
                                                                onChangeText={text => {
                                                                    setDeliveryPrice(text);
                                                                }}
                                                            />
                                                        </View>
                                                    </View>

                                                    <View style={{ flex: 1 }}></View>
                                                </View>

                                            </View>

                                        </View>
                                    </View>

                                    <View style={{ alignItems: 'center' }}>
                                        <TouchableOpacity
                                            style={{
                                                justifyContent: 'center',
                                                flexDirection: 'row',
                                                borderWidth: 1,
                                                borderColor: Colors.primaryColor,
                                                backgroundColor: '#4E9F7D',
                                                borderRadius: 5,
                                                width: 120,
                                                paddingHorizontal: 10,
                                                height: 35,
                                                alignItems: 'center',
                                                shadowOffset: {
                                                    width: 0,
                                                    height: 2,
                                                },
                                                shadowOpacity: 0.22,
                                                shadowRadius: 3.22,
                                                elevation: 1,
                                                zIndex: -1,
                                            }}
                                            disabled={loading}
                                            onPress={() => { orderFunc() }}>
                                            <Text style={{
                                                color: Colors.whiteColor,
                                                //marginLeft: 5,
                                                fontSize: switchMerchant ? 10 : 16,
                                                fontFamily: 'NunitoSans-Bold',
                                            }}>
                                                {loading ? 'LOADING...' : 'SAVE'}
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={{ marginTop: 20 }}></View>
                                </ScrollView>
                            </View>
                        ) : null}
                    </View>
                </ScrollView>
            </View>
        </View>
    );

}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#ffffff',
        flexDirection: 'row',
        fontFamily: 'NunitoSans-Regular',
    },
    headerLogo: {
        width: 112,
        height: 25,
        marginLeft: 10,
    },
    headerLogo1: {
        width: '100%',
        height: '100%',
    },
    list: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
    },
    listItem: {
        fontFamily: 'NunitoSans-Regular',
        marginLeft: 20,
        color: Colors.descriptionColor,
        fontSize: 16,
    },
    sidebar: {
        width: Dimensions.get('screen').width * Styles.sideBarWidth,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
    content: {
        padding: 20,
        width: Dimensions.get('screen').width * (1 - Styles.sideBarWidth),
        // backgroundColor: 'lightgrey',
        backgroundColor: Colors.highlightColor,
    },
    textInputTakeawayCharges: {
        backgroundColor: Colors.fieldtBgColor,
        width: 200,
        height: 40,
        borderRadius: 5,
        padding: 5,
        marginVertical: 5,
        borderWidth: 1,
        borderColor: '#E5E5E5',
        paddingLeft: 10,
        //marginLeft: 190,
    },

    textInput: {
        fontFamily: 'NunitoSans-Regular',
        width: 300,
        height: 50,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 10,
        marginRight: 0,
        flex: 1,
        flexDirection: 'row',
    },
    textInput1: {
        fontFamily: 'NunitoSans-Regular',
        width: 250,
        height: 40,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 5,
        marginBottom: 10,
    },
    button: {
        backgroundColor: Colors.primaryColor,
        width: 150,
        padding: 8,
        borderRadius: 10,
        alignItems: 'center',
        alignSelf: 'center',
        marginBottom: 20,
    },
    addButtonView: {
        flexDirection: 'row',
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        borderRadius: 5,
        width: 200,
        height: 40,
        alignItems: 'center',
    },
    addNewView: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginBottom: 65,
        marginTop: 7,
        width: '83%',
        alignSelf: 'flex-end',
    },
});
export default SettingScreen;