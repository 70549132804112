var logined = false;
var userData = null;
var name = "Keith";
var userId = null;
var refreshToken = null;
var refreshMainScreen = null;
var refreshCurrentAction = null;
var outletId = null;
var merchantId = null;
var refreshToken = null;

export function islogined() {
    return logined;
}

export function setlogin(param) {
    logined = param;
}

export function getRefreshCurrentAction() {
    return refreshCurrentAction();
}

export function setRefreshCurrentAction(param) {
    refreshCurrentAction = param;
}

export function setRefreshMainScreen(func) {
    refreshMainScreen = func;
}

export function getRefreshMainScreen() {
    return refreshMainScreen();
}

export function setName(param) {
    name = param;
}

export function getName() {
    return name;
}

export function setUserData(param) {
    userData = param;
}

export function getUserData() {
    return userData;
}

export function getUserId() {
    return userId
}

export function setUserId(param) {
    userId = param;
}

export function getOutletId() {
    return outletId;
}

export function setOutletId(param) {
    outletId = param;
}

export function getRefreshToken() {
    return refreshToken;
}

export function setRefreshToken(param) {
    refreshToken = param;
}

export function getMerchantId() {
    return merchantId;
}

export function setMerchantId(param) {
    merchantId = param;
}