import React, {
  Component,
  useReducer,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  Dimensions,
  TouchableOpacity,
  Switch,
  FlatList,
  Modal,
  TextInput,
  KeyboardAvoidingView,
  PermissionsAndroid,
  Platform,
  ActivityIndicator,
  PlatformColor,
  Picker,
  useWindowDimensions,
} from "react-native";
import Colors from "../constant/Colors";
import firebase from "firebase";
import SideBar from "./SideBar";
import Styles from "../constant/Styles";

import Feather from "react-native-vector-icons/Feather";
import Icon from "react-native-vector-icons/FontAwesome5";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Entypo from "react-native-vector-icons/Entypo";
import Ionicon from "react-native-vector-icons/Ionicons";
import Ionicons from 'react-native-vector-icons/Ionicons';
import AntDesign from "react-native-vector-icons/AntDesign";
import EvilIcons from "react-native-vector-icons/EvilIcons";

import ApiClient from "../util/ApiClient";
import API from "../constant/API";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as User from "../util/User";
import DropDownPicker from "react-native-dropdown-picker";
// import RNPickerSelect from 'react-native-picker-select';

import moment, { now } from "moment";
//import moment from "moment-timezone";

import { ReactComponent as Upload } from "../assets/svg/Upload.svg";
import { ReactComponent as Download } from "../assets/svg/Download.svg";
import { ReactComponent as Dish } from "../assets/svg/Dish.svg";
import { ReactComponent as Coins } from "../assets/svg/Coins.svg";
import { ReactComponent as Hand } from "../assets/svg/Hand.svg";
import { ReactComponent as GCoin } from "../assets/svg/GCoin.svg";
import { ReactComponent as GCalendar } from "../assets/svg/GCalendar.svg";
import { ReactComponent as Magnify } from "../assets/svg/Magnify.svg";
import { ReactComponent as ArrowLeft } from "../assets/svg/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "../assets/svg/ArrowRight.svg";
import { ReactComponent as SupportAgent } from "../assets/svg/SupportAgent.svg";

//   import { LineChart, BarChart } from 'react-native-chart-kit';
//   import { Rect, Text as TextSVG, Svg } from 'react-native-svg';
//   import DropDownPickerPlus from './components/DropDownPickerPlus';

// import RNFetchBlob from "rn-fetch-blob";
import { CSVLink } from "react-csv";
import { CommonStore } from "../store/commonStore";
//   import { isTablet } from 'react-native-device-detection';
//   import auth from '@react-native-firebase/auth';
import {
  listenToUserChangesMerchant,
  listenToMerchantIdChangesMerchant,
  listenToCurrOutletIdChangesWaiter,
  listenToAllOutletsChangesMerchant,
  listenToCommonChangesMerchant,
  listenToSelectedOutletItemChanges,
  convertArrayToCSV,
  listenToSelectedOutletTableIdChanges,
  // requestNotificationsPermission,
  sortReportDataList,
  listenToSelectedCustomerChangesMerchant,
  generateEmailReport,
  listenToSelectedOrderToPayUserIdChanges,
  getCachedUrlContent,
} from "../util/common";
import { UserStore } from "../store/userStore";
import { MerchantStore } from "../store/merchantStore";
import {
  NOTIFICATIONS_CHANNEL,
  TIMEZONE,
  NOTIFICATIONS_ID,
  CHART_HOURLY_LABEL_LIST,
  ROLE_TYPE,
  ORDER_TYPE,
  ORDER_TYPE_PARSED,
  NOTIFICATIONS_TYPE,
  REPORT_SORT_FIELD_TYPE,
  TABLE_PAGE_SIZE_DROPDOWN_LIST,
  EMAIL_REPORT_TYPE,
} from "../constant/common";
import { OutletStore } from "../store/outletStore";

import {
  CHART_DATA,
  CHART_TYPE,
  // FS_LIBRARY_PATH,
  CHART_Y_AXIS_DROPDOWN_LIST,
  CHART_FIELD_COMPARE_DROPDOWN_LIST,
  CHART_FIELD_NAME_DROPDOWN_LIST,
  CHART_FIELD_TYPE,
  CHART_FIELD_COMPARE_DICT,
  CHART_PERIOD,
} from "../constant/chart";
import {
  filterChartItems,
  getDataForChartDashboardTodaySales,
  getDataForSalesLineChart,
} from "../util/chart";

import Canvas, { Image as CanvasImage } from "react-native-canvas";
import { parseMessages } from "../util/notifications";
//   import PushNotification from 'react-native-push-notification';
import { NotificationStore } from "../store/notificationStore";

import DateTimePickerModal from "react-native-modal-datetime-picker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";

import AsyncImage from "../components/asyncImage";
import {
  // USBPrinter,
  NetPrinter,
  // BLEPrinter,
} from "react-native-thermal-receipt-printer-image-qr";
import XLSX from "xlsx";
import "react-native-get-random-values";

// import FusionCharts from 'react-native-fusioncharts';

// Include the react-fusioncharts component, library, chart type, theme
import FusionCharts from "react-fusioncharts";
import FC from "fusioncharts";
import Column2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

import { useLinkTo, useRoute } from "@react-navigation/native";
import { prefix } from "../constant/env";

import { v4 as uuidv4 } from "uuid";
import Select from 'react-select';

const { nanoid } = require("nanoid");

// const RNFS = require('react-native-fs');

// import { NativeModules } from 'react-native';
// import { findPrinters } from "../util/printer";
// const { RNFlashPOSModule } = NativeModules;
// import { GiftedChat } from 'react-native-gifted-chat';

// Adding the chart and theme as dependency to the core fusioncharts
FusionCharts.fcRoot(FC, Column2D, FusionTheme);

const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"],
];

const DashboardScreen = (props) => {
  const { navigation } = props;

  setInterval(() => {
    const elements = document.querySelectorAll('[fill="#b1b2b7"]');

    if (elements && elements.length > 0) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = 'none';
      }
    }
  }, 1000);

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  // RNFlashPOSModule.createCalendarEvent('test', 'test3');
  // console.log('RNFlashPOSModule');
  // console.log(RNFlashPOSModule);
  // RNFlashPOSModule.connectToPrinterByNet('192.168.1.254', (e) => {
  //     console.log(e);
  //     console.log('printer callback!');
  // });

  // useEffect(() => {
  //     console.log('connect printer');

  //     try {
  //         NetPrinter.connectPrinter('192.168.1.254', 9100).then(
  //             (printer) => {
  //                 console.log('printer status');
  //                 console.log(printer);
  //             },
  //             error => {
  //                 console.log('printer error');
  //                 console.log(error);
  //             });
  //     }
  //     catch (ex) {
  //         console.log(ex);
  //     }
  // }, []);

  const linkTo = useLinkTo();

  const canvasImage = useRef(null);
  const [startDate, setStartDate] = useState(new Date());

  const [messages, setMessages] = useState([
    {
      _id: 1,
      text: "Hello developer",
      createdAt: new Date(),
      user: {
        _id: 2,
        name: "React Native",
        avatar: "https://placeimg.com/140/140/any",
      },
    },
  ]);

  const [displayTodayData, setDisplayTodayData] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [currentOutletTotalSales, setCurrentOutletTotalSales] = useState(0);
  const [allDaySalesDetails, setAllDaySalesDetails] = useState([]);
  const [allDayTotalTransaction, setAllDayTotalTransaction] = useState(0);
  const [totalSold, setTotalSold] = useState(0);
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [outletSalesData, setOutletSalesData] = useState([
    {
      data: [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ],
      color: (opacity = 1) => Colors.tabMint,
    },
  ]);
  const [exportEmail, setExportEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const [switchMerchant, setSwitchMerchant] = useState(false);

  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
  const [
    showDateTimePickerFilterLineChart,
    setShowDateTimePickerFilterLineChart,
  ] = useState(false);
  const [
    showDateTimePickerFilterBarChart,
    setShowDateTimePickerFilterBarChart,
  ] = useState(false);

  const [rev_date, setRev_date] = useState(moment().startOf("month").startOf('day'));
  const [rev_date1, setRev_date1] = useState(moment().endOf("month").endOf('day'));

  const [perPage, setPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDetailsPage, setCurrentDetailsPage] = useState(1);
  const [pageReturn, setPageReturn] = useState(1);

  const [pushPagingToTop, setPushPagingToTop] = useState(false);

  const [expandDetailsDict, setExpandDetailsDict] = useState({});

  const [isCsv, setIsCsv] = useState(false);
  const [isExcel, setIsExcel] = useState(false);

  //////////////////////////////////////////////////////////////

  const [todaySalesChart, setTodaySalesChart] = useState({});

  const [salesLineChart, setSalesLineChart] = useState({});

  const [salesLineChartPeriod, setSalesLineChartPeriod] = useState(
    CHART_PERIOD.THIS_MONTH
  );
  const [salesBarChartPeriod, setSalesBarChartPeriod] = useState(
    CHART_PERIOD.THIS_MONTH
  );

  const [dailySalesDetailsList, setDailySalesDetailsList] = useState([]);

  const [selectedItemSummary, setSelectedItemSummary] = useState({});

  const userName = UserStore.useState((s) => s.name);

  const merchantId = UserStore.useState((s) => s.merchantId);
  const role = UserStore.useState((s) => s.role);
  const firebaseUid = UserStore.useState((s) => s.firebaseUid);

  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const allOutlets = MerchantStore.useState((s) => s.allOutlets);

  const selectedOutletItem = CommonStore.useState((s) => s.selectedOutletItem);
  const selectedOutletTable = CommonStore.useState(
    (s) => s.selectedOutletTable
  );

  const crmUsers = OutletStore.useState((s) => s.crmUsers);
  const crmUsersRaw = OutletStore.useState((s) => s.crmUsersRaw);
  const linkedMerchantIdUsers = OutletStore.useState(
    (s) => s.linkedMerchantIdUsers
  );
  const favoriteMerchantIdUsers = OutletStore.useState(
    (s) => s.favoriteMerchantIdUsers
  );

  // const nUserOrder = NotificationStore.useState((s) => s.nUserOrder);
  // const nUserReservation = NotificationStore.useState(
  //   (s) => s.nUserReservation
  // );
  // const nUserQueue = NotificationStore.useState((s) => s.nUserQueue);
  // const nOutletSupplyItemLow = NotificationStore.useState(
  //   (s) => s.nOutletSupplyItemLow
  // );

  const selectedCustomerEdit = CommonStore.useState(
    (s) => s.selectedCustomerEdit
  );

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  const outletSupplyItems = CommonStore.useState((s) => s.outletSupplyItems);
  const userReservations = OutletStore.useState((s) => s.userReservations);
  const userQueues = OutletStore.useState((s) => s.userQueues);

  const [showDetails, setShowDetails] = useState(false);
  const [transactionTypeSalesDetails, setTransactionTypeSalesDetails] =
    useState([]);

  const [exportModalVisibility, setExportModalVisibility] = useState(false);

  const [expandBarSelection, setExpandBarSelection] = useState(
    props.expandBarSelection === undefined ? false : props.expandBarSelection
  );
  const [expandLineSelection, setExpandLineSelection] = useState(false);

  const [
    selectedChartDropdownValueLineChart,
    setSelectedChartDropdownValueLineChart,
  ] = useState(
    CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value
  );
  const [
    selectedChartFilterQueriesLineChart,
    setSelectedChartFilterQueriesLineChart,
  ] = useState([
    {
      fieldNameKey:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0]
          .value,
      fieldNameType:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0]
          .fieldType,
      fieldCompare:
        CHART_FIELD_COMPARE_DROPDOWN_LIST[
          CHART_TYPE.DASHBOARD_LINE_CHART_SALES
        ][0].value,
      fieldDataValue: null,
    },
  ]);
  const [
    appliedChartFilterQueriesLineChart,
    setAppliedChartFilterQueriesLineChart,
  ] = useState([]);

  const [
    selectedChartDropdownValueBarChart,
    setSelectedChartDropdownValueBarChart,
  ] = useState(
    CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0].value
  );
  const [
    selectedChartFilterQueriesBarChart,
    setSelectedChartFilterQueriesBarChart,
  ] = useState([
    {
      fieldNameKey:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .value,
      fieldNameType:
        CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .fieldType,
      fieldCompare:
        CHART_FIELD_COMPARE_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0]
          .value,
      fieldDataValue: null,
    },
  ]);
  const [
    appliedChartFilterQueriesBarChart,
    setAppliedChartFilterQueriesBarChart,
  ] = useState([]);

  const [currReportSummarySort, setCurrReportSummarySort] = useState("");
  const [currReportDetailsSort, setCurrReportDetailsSort] = useState("");

  const isLoading = CommonStore.useState((s) => s.isLoading);

  const selectedOrderToPayUserId = CommonStore.useState(
    (s) => s.selectedOrderToPayUserId
  );

  const merchantLogo = MerchantStore.useState((s) => s.logo);

  /////////////////////////////////////////////////////////////////////////////////////

  //   useEffect(() => {
  //     NetPrinter.init();
  //   }, []);

  console.log("sidebar width");
  console.log(Styles.sideBarWidth);

  useEffect(() => {
    ////////////////////////////////////////////////////////

    var targetOutletDropdownListTemp = allOutlets.map((outlet) => ({
      label: outlet.name,
      value: outlet.uniqueId,
    }));

    setExpandLineSelection(false);

    CommonStore.update((s) => {
      s.outletSelectDropdownView = () => {
        return (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{
                // backgroundColor: 'red',
                marginRight: 10,
              }}
              onPress={() => {
                CommonStore.update((s) => {
                  s.chatbotModalVisibility = true;
                });
              }}
            >
              {/* <MaterialIcons
                name="support-agent"
                size={switchMerchant ? 20 : 25}
                style={{ color: Colors.whiteColor }}
              /> */}
              <SupportAgent
                // width={
                //   switchMerchant
                //     ? Dimensions.get("screen").width * 0.02
                //     : Dimensions.get("screen").width * iconSize
                // }
                // height={
                //   switchMerchant
                //     ? Dimensions.get("screen").width * 0.02
                //     : Dimensions.get("screen").width * iconSize
                // }
                color={Colors.whiteColor}
              />
            </TouchableOpacity>

            {currOutletId.length > 0 &&
              allOutlets.find((item) => item.uniqueId === currOutletId) ? (
              <DropDownPicker
                disabled={isLoading}
                containerStyle={[
                  { height: 40, paddingVertical: 0 },
                  switchMerchant
                    ? {
                      height: "100%",
                      paddingVertical: 0,
                      // borderWidth: 1
                      // width: '100%'
                    }
                    : {},
                ]}
                style={{
                  backgroundColor: "#345F4A",
                  borderWidth: 0,
                  paddingVertical: 0,
                }}
                arrowSize={switchMerchant ? 15 : 20}
                arrowColor={"#ffffff"}
                arrowStyle={{
                  fontWeight: "bold",
                  marginLeft: 5,
                  height: "700%",
                }}
                //itemStyle={{ justifyContent: 'flex-start' }}
                placeholder="Choose outlet"
                placeholderStyle={{
                  color: Colors.whiteColor,
                  fontWeight: "600",
                }}
                labelStyle={{ color: Colors.whiteColor, fontWeight: "600" }}
                dropDownStyle={{
                  backgroundColor: "#345F4A",
                  borderRadius: 5,
                  zIndex: 1,
                }}
                items={targetOutletDropdownListTemp}
                onChangeItem={(item) => {
                  MerchantStore.update((s) => {
                    s.currOutletId = item.value;
                    s.currOutlet = allOutlets.find(
                      (outlet) => outlet.uniqueId === item.value
                    );
                  });
                }}
                defaultValue={currOutletId}
              />
            ) : (
              <ActivityIndicator size={"small"} color={Colors.whiteColor} />
            )}
          </View>
        );
      };
    });
  }, [allOutlets, currOutletId, isLoading]);

  /////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (firebaseUid !== "") {
      if (
        role === ROLE_TYPE.ADMIN ||
        role === ROLE_TYPE.LEGACY ||
        role === ROLE_TYPE.STORE_MANAGER
      ) {
        listenToUserChangesMerchant(firebaseUid, currOutletId);
      }
    }
  }, [firebaseUid, role]);

  useEffect(() => {
    if (merchantId !== "" && currOutletId !== "") {
      if (
        role === ROLE_TYPE.ADMIN ||
        role === ROLE_TYPE.LEGACY ||
        role === ROLE_TYPE.STORE_MANAGER
      ) {
        listenToMerchantIdChangesMerchant(merchantId, currOutletId);
      }
    }
  }, [merchantId, currOutletId, role]);

  useEffect(() => {
    if (currOutletId !== "") {
      if (
        role === ROLE_TYPE.ADMIN ||
        role === ROLE_TYPE.LEGACY ||
        role === ROLE_TYPE.STORE_MANAGER
      ) {
        // can use the waiter one
        listenToCurrOutletIdChangesWaiter(role, currOutletId);
      }
    }
  }, [currOutletId, role]);

  useEffect(() => {
    if (allOutlets.length > 0) {
      if (
        role === ROLE_TYPE.ADMIN ||
        role === ROLE_TYPE.LEGACY ||
        role === ROLE_TYPE.STORE_MANAGER
      ) {
        listenToAllOutletsChangesMerchant(allOutlets);
      }
    }
  }, [allOutlets.length, role]);

  useEffect(() => {
    if (
      selectedOutletItem !== null &&
      selectedOutletItem !== undefined &&
      selectedOutletItem.uniqueId
    ) {
      listenToSelectedOutletItemChanges(selectedOutletItem);
    }
  }, [selectedOutletItem, role]);

  useEffect(() => {
    if (selectedOrderToPayUserId !== "") {
      listenToSelectedOrderToPayUserIdChanges(selectedOrderToPayUserId);
    }
  }, [selectedOrderToPayUserId]);

  useEffect(() => {
    for (let i = 0; i <= 20; i++) {
      console.log("screen height" + Dimensions.get("screen").height);
      console.log("screen width" + Dimensions.get("screen").width);
    }

    listenToCommonChangesMerchant();

    // requestNotificationsPermission();

    // firebase.messaging().onMessage(async (msg) => {
    //   console.log("message from foreground!");
    //   console.log(msg);

    //   parseMessages(msg);
    // });
  }, []);

  useEffect(() => {
    if (
      firebaseUid &&
      firebaseUid.length > 0 &&
      selectedOutletTable &&
      selectedOutletTable.uniqueId &&
      currOutletId
    ) {
      listenToSelectedOutletTableIdChanges(
        firebaseUid,
        selectedOutletTable.uniqueId,
        currOutletId
      );
    }
  }, [firebaseUid, selectedOutletTable, currOutletId]);

  useEffect(() => {
    if (selectedCustomerEdit) {
      listenToSelectedCustomerChangesMerchant(selectedCustomerEdit);
    } else {
      OutletStore.update((s) => {
        s.selectedCustomerDineInOrders = [];
      });
    }
  }, [selectedCustomerEdit]);

  ////////////////////////////////////////////////////////

  useEffect(() => {
    if (
      firebaseUid &&
      firebaseUid.length > 0 &&
      currOutletId.length > 0 &&
      merchantId.length > 0 &&
      role.length > 0
    ) {
      // firebase
      //   .messaging()
      //   .getToken()
      //   .then(async (tokenFcm) => {
      //     await AsyncStorage.setItem("tokenFcm", tokenFcm);
      //     await updateTokenFcm();
      //   });
      // firebase.messaging().onTokenRefresh(async (tokenFcm) => {
      //   await AsyncStorage.setItem("tokenFcm", tokenFcm);
      //   await updateTokenFcm();
      // });
    }
  }, [firebaseUid, currOutletId, merchantId, role]);

  const updateTokenFcm = async () => {
    const tokenFcm = await AsyncStorage.getItem("tokenFcm");

    if (tokenFcm) {
      const body = {
        tokenFcm: tokenFcm,
        userId: firebaseUid,
        outletId: currOutletId,
        merchantId: merchantId,
        role: role,
      };

      ApiClient.POST(API.updateTokenFcm, body).then((result) => {
        console.log("updated token fcm");
      });
    }
  };

  ////////////////////////////////////////////////////////

  // from notifications

  // useEffect(() => {
  //   if (nUserOrder && nUserOrder.type) {
  //     if (nUserOrder.orderType === ORDER_TYPE.DINEIN) {
  //       navigation.navigate("Order");
  //     } else {
  //       navigation.navigate("Takeaway");
  //     }
  //   }
  // }, [nUserOrder]);

  // useEffect(() => {
  //   if (nUserReservation && nUserReservation.type) {
  //     navigation.navigate("Reservation");
  //   }
  // }, [nUserReservation]);

  // useEffect(() => {
  //   if (nUserQueue && nUserQueue.type) {
  //     navigation.navigate("Queue");
  //   }
  // }, [nUserQueue]);

  // useEffect(() => {
  //   if (nOutletSupplyItemLow && nOutletSupplyItemLow.type) {
  //     navigation.navigate("Inventory");
  //   }
  // }, [nOutletSupplyItemLow]);

  ////////////////////////////////////////////////////////

  // from crm

  useEffect(() => {
    var crmUsersTemp = [];
    var crmUsersRawTemp = [...crmUsersRaw];

    for (var i = 0; i < favoriteMerchantIdUsers.length; i++) {
      var record = {
        ...favoriteMerchantIdUsers[i],
      };

      for (var j = 0; j < crmUsersRawTemp.length; j++) {
        if (record.email === crmUsersRawTemp[j].email) {
          record = {
            ...record,
            ...crmUsersRawTemp[j],
          };

          crmUsersRawTemp.splice(j, 1);

          break;
        }
      }

      crmUsersTemp.push(record);
    }

    for (var i = 0; i < linkedMerchantIdUsers.length; i++) {
      var isExisted = false;

      for (var index = 0; index < favoriteMerchantIdUsers.length; index++) {
        if (
          favoriteMerchantIdUsers[index].email ===
          linkedMerchantIdUsers[i].email
        ) {
          isExisted = true;
          break;
        }
      }

      if (!isExisted) {
        var record = {
          ...linkedMerchantIdUsers[i],
        };

        for (var j = 0; j < crmUsersRawTemp.length; j++) {
          if (record.email === crmUsersRawTemp[j].email) {
            record = {
              ...record,
              ...crmUsersRawTemp[j],
            };

            crmUsersRawTemp.splice(j, 1);

            break;
          }
        }

        crmUsersTemp.push(record);
      }
    }

    for (var i = 0; i < crmUsersRawTemp.length; i++) {
      var foundUser = crmUsersTemp.find(
        (user) => user.email === crmUsersRawTemp[i].email
      );

      if (!foundUser) {
        crmUsersTemp.push(crmUsersRawTemp[i]);
      }
    }

    OutletStore.update((s) => {
      s.crmUsers = crmUsersTemp;
    });

    console.log("crmUsers");
    console.log(crmUsersTemp);
  }, [crmUsersRaw, linkedMerchantIdUsers, favoriteMerchantIdUsers]);

  useEffect(() => {
    if (selectedCustomerEdit) {
      for (var i = 0; i < crmUsers.length; i++) {
        if (crmUsers[i].email === selectedCustomerEdit.email) {
          CommonStore.update((s) => {
            s.selectedCustomerEdit = crmUsers[i];
          });

          break;
        }
      }
    }
  }, [crmUsers]);

  //   useEffect(() => {
  //     if (outletSupplyItems.length > 0) {
  //       for (var i = 0; i < outletSupplyItems.length; i++) {
  //         if (
  //           outletSupplyItems[i].quantity <=
  //           outletSupplyItems[i].stockWarningQuantity
  //         ) {
  //           // alert

  //           PushNotification.localNotification({
  //             id: NOTIFICATIONS_ID.MERCHANT_LOW_STOCK_ALERT,

  //             channelId: NOTIFICATIONS_CHANNEL.MERCHANT_LOW_STOCK_ALERT,

  //             title: `${outletSupplyItems[i].name} (${outletSupplyItems[i].skuMerchant}) running in low`,
  //             message: `Stock left: ${outletSupplyItems[i].quantity} ${outletSupplyItems[i].unit}`,
  //             data: {
  //               type: NOTIFICATIONS_TYPE.MERCHANT_LOW_STOCK_ALERT,
  //             },
  //             userInfo: {
  //               type: NOTIFICATIONS_TYPE.MERCHANT_LOW_STOCK_ALERT,
  //             },

  //             group: NOTIFICATIONS_TYPE.MERCHANT_LOW_STOCK_ALERT,
  //             groupSummary: true,
  //           });
  //         }
  //       }
  //     }
  //   }, [outletSupplyItems]);

  //   useEffect(() => {
  //     if (userReservations.length > 0) {

  //       PushNotification.localNotification({
  //         id: NOTIFICATIONS_ID.MERCHANT_RESERVATION_REMINDER,

  //         channelId: NOTIFICATIONS_CHANNEL.MERCHANT_RESERVATION_REMINDER,

  //         title: `${userReservations[0].pax} pax reservation at ${moment(
  //           userReservations[0].reservationTime
  //         ).format("Do MMM YY, h:mma")}`,
  //         message: `Name: ${userReservations[0].userName}`,
  //         // \nPhone: ${userReservations[0].userPhone}\nEmail: ${userReservations[0].userEmail}
  //         data: {
  //           type: NOTIFICATIONS_TYPE.MERCHANT_RESERVATION_REMINDER,
  //         },
  //         userInfo: {
  //           type: NOTIFICATIONS_TYPE.MERCHANT_RESERVATION_REMINDER,
  //         },

  //         group: NOTIFICATIONS_TYPE.MERCHANT_RESERVATION_REMINDER,
  //         groupSummary: true,
  //       });
  //     }
  //   }, [userReservations]);

  //   useEffect(() => {
  //     if (userQueues.length > 0) {
  //       PushNotification.localNotification({
  //         id: NOTIFICATIONS_ID.MERCHANT_QUEUE_REMINDER,

  //         channelId: NOTIFICATIONS_CHANNEL.MERCHANT_QUEUE_REMINDER,

  //         title: `${userQueues[0].pax} pax queue at number ${userQueues[0].number}`,
  //         message: `Name: ${userQueues[0].userName}`,
  //         // \nPhone: ${userQueues[0].userPhone}\nEmail: ${userQueues[0].userEmail}
  //         data: {
  //           type: NOTIFICATIONS_TYPE.MERCHANT_QUEUE_REMINDER,
  //         },
  //         userInfo: {
  //           type: NOTIFICATIONS_TYPE.MERCHANT_QUEUE_REMINDER,
  //         },

  //         group: NOTIFICATIONS_TYPE.MERCHANT_QUEUE_REMINDER,
  //         groupSummary: true,
  //       });
  //     }
  //   }, [userQueues]);

  useEffect(() => {
    saveMerchantLogoToBase64(merchantLogo);
  }, [merchantLogo]);

  const saveMerchantLogoToBase64 = async (merchantLogoUrl) => {
    if (merchantLogoUrl) {
      var merchantLogoUrlParsed = "";

      if (
        merchantLogoUrl.startsWith("http") ||
        merchantLogoUrl.startsWith("file:///")
      ) {
        merchantLogoUrlParsed = merchantLogoUrl;
      } else {
        const cachedObj = await getCachedUrlContent(
          merchantLogoUrl,
          43800,
          null
        );

        if (cachedObj && cachedObj.parsedUrl) {
          merchantLogoUrlParsed = cachedObj.parsedUrl;
        }
      }

      await AsyncStorage.setItem("merchantLogoUrl", merchantLogoUrlParsed);

      const cachedObjKoodooLogo = await getCachedUrlContent(
        "/mykoodoo/logo_2_small.png",
        43800,
        null
      );

      var koodooLogoUrlParsed = "";
      if (cachedObjKoodooLogo && cachedObjKoodooLogo.parsedUrl) {
        koodooLogoUrlParsed = cachedObjKoodooLogo.parsedUrl;
      }

      await AsyncStorage.setItem("koodooLogoUrl", koodooLogoUrlParsed);
    }
  };

  ////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////

  const allOutletsUserOrdersDone = OutletStore.useState(
    (s) => s.allOutletsUserOrdersDone
  );

  //////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const allOutletsUserOrdersDoneFilteredBarChart =
      allOutletsUserOrdersDone.filter((item) =>
        filterChartItems(item, appliedChartFilterQueriesBarChart),
      );
    const allOutletsUserOrdersDoneFilteredLineChart =
      allOutletsUserOrdersDone.filter((item) =>
        filterChartItems(item, appliedChartFilterQueriesLineChart),
      );
    console.log('------------------------------here----------------------------------')
    console.log(allOutletsUserOrdersDoneFilteredBarChart);

    const dataChartDashboardTodaySales = getDataForChartDashboardTodaySales(
      allOutlets,
      allOutletsUserOrdersDoneFilteredBarChart,
      salesBarChartPeriod,
      selectedChartDropdownValueBarChart,
      rev_date,
      rev_date1,
    );

    if (dataChartDashboardTodaySales) {
      setTodaySalesChart(dataChartDashboardTodaySales);
    }


    // setSalesLineChart(
    //   getDataForSalesLineChart(
    //     allOutlets,
    //     allOutletsUserOrdersDoneFilteredLineChart,
    //     salesLineChartPeriod,
    //     selectedChartDropdownValueLineChart
    //   )
    // );
    setExpandLineSelection(false);

    if (allOutlets.length > 0) {
      const result = getDataForSalesLineChart(
        allOutlets,
        allOutletsUserOrdersDoneFilteredLineChart,
        salesLineChartPeriod,
        selectedChartDropdownValueLineChart,
        rev_date,
        rev_date1,
      );

      if (result) {
        setSalesLineChart(result.chartData);
        setExpandLineSelection(false);
      }
    }
  }, [
    allOutlets,
    allOutletsUserOrdersDone,
    salesLineChartPeriod,
    salesBarChartPeriod,
    selectedChartDropdownValueBarChart,
    selectedChartDropdownValueLineChart,
    appliedChartFilterQueriesBarChart,
    appliedChartFilterQueriesLineChart,
    rev_date,
    rev_date1,
  ]);

  useEffect(() => {
    var totalSalesTemp = 0;
    var totalTransactionsTemp = allOutletsUserOrdersDone.length;
    var totalSoldTemp = 0;
    var currDate = moment(Date.now());

    setExpandLineSelection(false);

    for (var i = 0; i < allOutletsUserOrdersDone.length; i++) {
      if (
        currOutletId === allOutletsUserOrdersDone[i].outletId &&
        moment(currDate).isSame(
          allOutletsUserOrdersDone[i].completedDate,
          "day"
        )
      ) {
        for (var j = 0; j < allOutletsUserOrdersDone[i].cartItems.length; j++) {
          const cartItem = allOutletsUserOrdersDone[i].cartItems[j];

          totalSoldTemp += cartItem.quantity;
        }

        totalSalesTemp += allOutletsUserOrdersDone[i].finalPrice;
      }
    }

    setTotalSales(totalSalesTemp);
    setTotalTransactions(totalTransactionsTemp);
    setTotalSold(totalSoldTemp);
  }, [
    allOutlets,
    allOutletsUserOrdersDone,
    currOutletId,
    rev_date,
    rev_date1,
    perPage,
  ]);

  useEffect(() => {
    //////////////////////////////////////////////////////////////////////////////////////

    var dailySalesDetailsListTemp = [];

    if (currOutletId && currOutletId.length > 0) {
      for (
        var i = 0;
        i <= moment(rev_date1).diff(moment(rev_date), "day");
        i++
      ) {
        var currDateTime = moment(rev_date).add(i, "day");

        var record = {
          summaryId: nanoid(),
          dateTime: moment(currDateTime).format("D MMM YYYY"),
          dateTimeRaw: currDateTime,
          totalSales: 0,
          totalTransactions: 0,
          totalDiscount: 0,
          discount: 0,
          tax: 0,
          serviceCharge: 0,
          gp: 0,
          totalSalesReturn: 0,
          netSales: 0,
          averageNetSales: 0,
          detailsList: [],
        };

        for (var j = 0; j < allOutletsUserOrdersDone.length; j++) {
          if (
            allOutletsUserOrdersDone[j].outletId === currOutletId &&
            moment(currDateTime).isSame(
              allOutletsUserOrdersDone[j].completedDate,
              "day"
            )
          ) {
            record.totalSales += allOutletsUserOrdersDone[j].finalPrice;
            record.totalTransactions += 1;
            record.totalDiscount += allOutletsUserOrdersDone[j].discount;
            record.discount += !isNaN(
              (allOutletsUserOrdersDone[j].discount /
                allOutletsUserOrdersDone[j].finalPrice) *
              100
            )
              ? (allOutletsUserOrdersDone[j].discount /
                allOutletsUserOrdersDone[j].finalPrice) *
              100
              : 0;
            record.tax += !isNaN(
              (allOutletsUserOrdersDone[j].tax /
                allOutletsUserOrdersDone[j].finalPrice) *
              100
            )
              ? (allOutletsUserOrdersDone[j].tax /
                allOutletsUserOrdersDone[j].finalPrice) *
              100
              : 0;
            record.serviceCharge += allOutletsUserOrdersDone[j].sc || 0;
            // record.totalSalesReturn += 0;
            if (allOutletsUserOrdersDone[j].cartItemsCancelled) {
              for (
                var k = 0;
                k <
                allOutletsUserOrdersDone[j].cartItemsCancelled.length;
                k++
              ) {
                record.totalSalesReturn +=
                  allOutletsUserOrdersDone[j].cartItemsCancelled[
                    k
                  ].price;
              }
            }

            record.netSales +=
              Math.ceil(allOutletsUserOrdersDone[j].totalPrice * 20 - 0.05) /
              20;
            record.detailsList.push({
              ...allOutletsUserOrdersDone[j],
              discountPercentage: parseFloat(
                isFinite(
                  allOutletsUserOrdersDone[j].finalPrice /
                  allOutletsUserOrdersDone[j].discount
                )
                  ? (allOutletsUserOrdersDone[j].finalPrice /
                    allOutletsUserOrdersDone[j].discount) *
                  100
                  : 0
              ),
            });
          }
        }

        if (record.totalTransactions > 0) {
          record.averageNetSales = record.netSales / record.totalTransactions;
        }

        dailySalesDetailsListTemp.push(record);
      }
    }

    setDailySalesDetailsList(dailySalesDetailsListTemp);

    setPageCount(Math.ceil(dailySalesDetailsListTemp.length / perPage));

    setShowDetails(false);
  }, [
    allOutlets,
    allOutletsUserOrdersDone,
    currOutletId,
    rev_date,
    rev_date1,
    perPage,
  ]);

  useEffect(() => {
    if (showDetails && selectedItemSummary.detailsList) {
      setTransactionTypeSalesDetails(selectedItemSummary.detailsList);

      setPageReturn(currentPage);
      console.log("currentPage value is");
      console.log(currentPage);
      setCurrentDetailsPage(1);
      setPageCount(Math.ceil(selectedItemSummary.detailsList.length / perPage));
    }
  }, [showDetails, selectedItemSummary, perPage]);

  useEffect(() => {
    var total = 0;
    var totalSales = 0;
    var total1 = 0;
    var totalTransaction = 0;

    for (var i = 0; i < allDaySalesDetails.length; i++) {
      total = allDaySalesDetails[i].totalSales;
      totalSales += total;
    }
    for (var i = 0; i < allDaySalesDetails.length; i++) {
      total1 = allDaySalesDetails[i].totalTransactions;
      totalTransaction += total1;
    }

    setAllDayTotalTransaction(totalTransaction);
    setCurrentOutletTotalSales(totalSales);
  }, [
    allDaySalesDetails,
    allOutlets,
    allOutletsUserOrdersDone,
    currOutletId,
    rev_date,
    rev_date1,
  ]);

  useEffect(() => {
    var dailySalesDetailsListTemp = [];
    var currDate = moment(Date.now());

    if (currOutletId && currOutletId.length > 0) {
      var record = {
        summaryId: nanoid(),
        totalSales: 0,
        totalTransactions: 0,
        totalDiscount: 0,
        discount: 0,
        tax: 0,
        serviceCharge: 0,
        gp: 0,
        totalSalesReturn: 0,
        netSales: 0,
        averageNetSales: 0,
        detailsList: [],
      };

      for (var j = 0; j < allOutletsUserOrdersDone.length; j++) {
        if (
          allOutletsUserOrdersDone[j].outletId === currOutletId &&
          moment(currDate).isSame(
            allOutletsUserOrdersDone[j].completedDate,
            "day"
          )
        ) {
          record.totalSales += allOutletsUserOrdersDone[j].finalPrice;
          record.totalTransactions += 1;
          record.totalDiscount += allOutletsUserOrdersDone[j].discount;
          record.discount +=
            (allOutletsUserOrdersDone[j].discount /
              allOutletsUserOrdersDone[j].finalPrice) *
            100;
          record.tax +=
            (allOutletsUserOrdersDone[j].tax /
              allOutletsUserOrdersDone[j].finalPrice) *
            100;
          record.serviceCharge += allOutletsUserOrdersDone[j].sc || 0;
          // record.totalSalesReturn += allOutletsUserOrdersDone[j].totalPrice;
          if (allOutletsUserOrdersDone[j].cartItemsCancelled) {
            for (
              var k = 0;
              k <
              allOutletsUserOrdersDone[j].cartItemsCancelled.length;
              k++
            ) {
              record.totalSalesReturn +=
                allOutletsUserOrdersDone[j].cartItemsCancelled[
                  k
                ].price;
            }
          }

          record.netSales += allOutletsUserOrdersDone[j].totalPrice;
          record.detailsList.push({
            ...allOutletsUserOrdersDone[j],
            discountPercentage: parseFloat(
              isFinite(
                allOutletsUserOrdersDone[j].finalPrice /
                allOutletsUserOrdersDone[j].discount
              )
                ? (allOutletsUserOrdersDone[j].finalPrice /
                  allOutletsUserOrdersDone[j].discount) *
                100
                : 0
            ),
          });
        }
      }

      if (record.totalTransactions > 0) {
        record.averageNetSales = record.netSales / record.totalTransactions;
      }

      dailySalesDetailsListTemp.push(record);
    }

    setAllDaySalesDetails(dailySalesDetailsListTemp);
  }, [allOutlets, allOutletsUserOrdersDone, currOutletId, rev_date, rev_date1]);

  //////////////////////////////////////////////////////////////////////////////////

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            bottom: "2%",
          },
          Dimensions.get("screen").width >= 768 && switchMerchant
            ? { right: Dimensions.get("screen").width * 0.1 }
            : {},
          Dimensions.get("screen").width <= 768
            ? { right: Dimensions.get("screen").width * 0.12 }
            : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          Dashboard
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: Dimensions.get("screen").height * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
            // borderWidth: 1
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: Dimensions.get("screen").height * 0.05,
              height: Dimensions.get("screen").height * 0.05,
              borderRadius: Dimensions.get("screen").height * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: Dimensions.get("screen").height * 0.035,
                height: Dimensions.get("screen").height * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  useEffect(() => {
    getTotalSales(0);
    getSalesSummary();
    getTodaySales();
    getOutlets();
  }, []);

  const setState = () => { };

  const eventsChart = {
    dataPlotClick: (e, item) => {
      console.log("test data plot");
    },
  };

  const getOutlets = async () => {
    // ApiClient.GET(API.dashboardSaleToday + User.getMerchantId()).then(result => {
    //     setState({ outlets: result })
    // })
  };

  const getSalesSummary = async () => {
    // ApiClient.GET(API.dashboardSaleTodayCalculation + User.getMerchantId()).then(result => {
    //     setState({ totalSales: result.totalSales.toFixed(2), totalTransactions: result.totalTransactions, totalSold: result.totalSold })
    // })
  };

  const getTodaySales = () => {
    // ApiClient.GET(API.dashboardSaleToday + User.getMerchantId()).then(result => {
    //     const displayData = [];
    //     const displayLabel = [];
    //     for (const entries of result) {
    //         displayLabel.push(entries.outletName)
    //     }
    //     for (const entries of result) {
    //         displayData.push(Math.trunc(entries.finalPrice))
    //     }
    //     setState({ displayTodayLabel: displayLabel, displayTodayData: displayData, outlets: result })
    //     const dummyData = [
    //         {
    //             "month": 1,
    //             "monthName": "January",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[0]
    //         },
    //         {
    //             "month": 2,
    //             "monthName": "February",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[1]
    //         },
    //         {
    //             "month": 3,
    //             "monthName": "March",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[2]
    //         },
    //         {
    //             "month": 4,
    //             "monthName": "April",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[3]
    //         },
    //         {
    //             "month": 5,
    //             "monthName": "May",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[4]
    //         },
    //         {
    //             "month": 6,
    //             "monthName": "June",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[5]
    //         },
    //         {
    //             "month": 7,
    //             "monthName": "July",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[6]
    //         },
    //         {
    //             "month": 8,
    //             "monthName": "August",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[7]
    //         },
    //         {
    //             "month": 9,
    //             "monthName": "September",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[8]
    //         },
    //         {
    //             "month": 10,
    //             "monthName": "October",
    //             "year": 2020,
    //             "totalPrice": displayTodayData[9]
    //         },
    //         {
    //             "month": 11,
    //             "monthName": "November",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[10]
    //         },
    //         {
    //             "month": 12,
    //             "monthName": "December",
    //             "year": 2021,
    //             "totalPrice": displayTodayData[11]
    //         },
    //     ]
    //     setState({ CsvData: dummyData })
    // })
  };

  const getTotalSales = async (type) => {
    const outletStat = [];
    const outletLabel = [];
    const colorList = [
      Colors.tabGold,
      Colors.tabMint,
      Colors.tabGrey,
      Colors.tabYellow,
      Colors.tabCyan,
      Colors.fieldtBgColor,
      Colors.primaryColor,
      Colors.secondaryColor,
      Colors.darkBgColor,
      Colors.highlightColor,
      Colors.lightRed,
      Colors.blackColor,
    ];
    // linechart data set format
    // {
    //     data: outletSalesData.length == 0 ? tempData : outletSalesData[0],
    //     color: (opacity = 1) => Colors.tabMint,
    // }
    if (type == 0) {
      for (const outlets of selectedOutlets) {
        console.log("outlets", outlets);

        const price = [];
        const label = [];
        ApiClient.GET(
          API.dashboardSaleOutletToday +
          "?merchantId=" +
          User.getMerchantId() +
          "&outletId=" +
          outlets
        ).then((result) => {
          console.log("reuslt", result);
          var i = selectedOutlets.indexOf(outlets);
          console.log("initial i", i);
          for (const entries of result) {
            price.push(Number(entries.totalPrice));
            label.push(entries.hour.toString());
          }
          outletStat.push({
            data: price,
            color: (opacity = 1) => colorList[i],
          });
          outletLabel.push(label);
          console.log("outletStat", outletStat);
          setState({
            outletSalesData:
              outletStat.length == 0
                ? [
                  {
                    data: [
                      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
                      0, 0, 0, 0, 0,
                    ],
                    color: (opacity = 1) => Colors.tabMint,
                  },
                ]
                : outletStat,
            outletLabel: outletLabel[0],
          });
        });
      }
    }

    const dummyYear = [
      {
        month: 1,
        monthName: "January",
        year: 2021,
        totalPrice: displayTodayData[0],
      },
      {
        month: 2,
        monthName: "February",
        year: 2021,
        totalPrice: displayTodayData[1],
      },
      {
        month: 3,
        monthName: "March",
        year: 2021,
        totalPrice: displayTodayData[2],
      },
      {
        month: 4,
        monthName: "April",
        year: 2021,
        totalPrice: displayTodayData[3],
      },
      {
        month: 5,
        monthName: "May",
        year: 2021,
        totalPrice: displayTodayData[4],
      },
      {
        month: 6,
        monthName: "June",
        year: 2021,
        totalPrice: displayTodayData[5],
      },
      {
        month: 7,
        monthName: "July",
        year: 2021,
        totalPrice: displayTodayData[6],
      },
      {
        month: 8,
        monthName: "August",
        year: 2021,
        totalPrice: displayTodayData[7],
      },
      {
        month: 9,
        monthName: "September",
        year: 2021,
        totalPrice: displayTodayData[8],
      },
      {
        month: 10,
        monthName: "October",
        year: 2020,
        totalPrice: displayTodayData[9],
      },
      {
        month: 11,
        monthName: "November",
        year: 2021,
        totalPrice: displayTodayData[10],
      },
      {
        month: 12,
        monthName: "December",
        year: 2021,
        totalPrice: displayTodayData[11],
      },
    ];

    // setState({ CsvData: dummyYear})

    const dummyYear1 = [
      {
        month: 1,
        monthName: "January",
        year: 2020,
        totalPrice: "212.61",
      },
      {
        month: 2,
        monthName: "February",
        year: 2020,
        totalPrice: "157.90",
      },
      {
        month: 3,
        monthName: "March",
        year: 2020,
        totalPrice: "199.08",
      },
      {
        month: 4,
        monthName: "April",
        year: 2020,
        totalPrice: "332.61",
      },
      {
        month: 5,
        monthName: "May",
        year: 2020,
        totalPrice: "544.90",
      },
      {
        month: 6,
        monthName: "June",
        year: 2020,
        totalPrice: "873.50",
      },
      {
        month: 7,
        monthName: "July",
        year: 2020,
        totalPrice: "843.00",
      },
      {
        month: 8,
        monthName: "August",
        year: 2020,
        totalPrice: "632.30",
      },
      {
        month: 9,
        monthName: "September",
        year: 2020,
        totalPrice: "500.50",
      },
      {
        month: 10,
        monthName: "October",
        year: 2020,
        totalPrice: "340.00",
      },
      {
        month: 11,
        monthName: "November",
        year: 2020,
        totalPrice: "229.50",
      },
      {
        month: 12,
        monthName: "December",
        year: 2020,
        totalPrice: "792.80",
      },
    ];

    const months = [];
    const yearlyData = [];
    const test = [];
    for (const entries of dummyYear) {
      months.push(entries.monthName);
      yearlyData.push(entries.month);
      test.push(parseFloat(entries.totalPrice));
    }
    const months1 = [];
    const yearlyData1 = [];
    const test1 = [];
    for (const entries of dummyYear1) {
      months1.push(entries.monthName);
      yearlyData1.push(entries.month);
      test1.push(parseFloat(entries.totalPrice));
    }
    setState({
      yearlyData: yearlyData,
      months: months,
      test: test,
      test1: test1,
      months1: months1,
      yearlyData1: yearlyData1,
    });
  };

  // const downloadCsv = () => {
  //   if (
  //     todaySalesChart &&
  //     todaySalesChart.dataSource &&
  //     todaySalesChart.dataSource.data
  //   ) {
  //     const csvData = convertArrayToCSV(todaySalesChart.dataSource.data);

  //     const pathToWrite = `${
  //       RNFetchBlob.fs.dirs.DownloadDir
  //     }/koodoo-report-dashboard-${moment().format("YYYY-MM-DD-HH-mm-ss")}.csv`;
  //     console.log("PATH", pathToWrite);
  //     RNFetchBlob.fs
  //       .writeFile(pathToWrite, csvData, "utf8")
  //       .then(() => {
  //         console.log(`wrote file ${pathToWrite}`);
  //         // wrote file /storage/emulated/0/Download/data.csv
  //         alert(
  //           "Success",
  //           `Sent to ${pathToWrite}`,
  //           [{ text: "OK", onPress: () => {} }],
  //           { cancelable: false }
  //         );
  //       })
  //       .catch((error) => console.error(error));
  //   }
  // };

  const downloadPDF = () => { };

  const emailDashboard = () => {
    const excelData = convertDataToExcelFormat();

    var body = {
      data: JSON.stringify(excelData),
      email: exportEmail,
    };

    ApiClient.POST(API.emailDashboard, body, false).then((result) => {
      if (result !== null) {
        alert(
          "Success",
          "Email has been sent",
          [{ text: "OK", onPress: () => { } }],
          { cancelable: false }
        );
      }
    });

    setVisible(false);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1 > pageCount ? currentPage : currentPage + 1);
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1 < 1 ? currentPage : currentPage - 1);
  };

  const nextDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage + 1 > pageCount
        ? currentDetailsPage
        : currentDetailsPage + 1
    );
  };

  const prevDetailsPage = () => {
    setCurrentDetailsPage(
      currentDetailsPage - 1 < 1 ? currentDetailsPage : currentDetailsPage - 1
    );
  };

  const renderLabel = (item) => {
    return (
      <View
        style={{
          flexDirection: "row",
          backgroundColor: Colors.tabGrey,
          height: Dimensions.get("screen").width * 0.04,
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 6,
          marginHorizontal: 1,
        }}
      >
        <Text
          style={{ marginHorizontal: 5, color: "white", fontWeight: "bold" }}
        >
          asdfasdfasdf
        </Text>
        <TouchableOpacity
          style={{ marginRight: 3 }}
          onPress={() => {
            //select(item)
          }}
        >
          <AntDesign name={"close"} size={15} color={"#FFFFFF"} />
        </TouchableOpacity>
      </View>
    );
  };

  const onItemSummaryClicked = (item) => {
    setSelectedItemSummary(item);
    setShowDetails(true);

    console.log("item.detailsList");
    console.log(item.detailsList);
  };

  //Daily Sales Report
  //Insert Dummy Data first
  //Text below is Dummy Data
  const renderItem = ({ item, index }) => {
    var finalPriceSpacing =
      Math.max(8 - item.averageNetSales.toFixed(0).length, 0) + 1;

    return (
      // (index + 1) % 2 == 0 ?
      <TouchableOpacity
        onPress={() => onItemSummaryClicked(item)}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          //paddingHorizontal: 3,
          //paddingLeft: 1,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          // width: '100%',
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "5%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {index + 1}
          </Text>
          <Text
            style={{
              width: "12%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.dateTime}
          </Text>
          <Text
            style={{
              width: "9%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.totalSales
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          <Text
            style={{
              width: "9%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.totalTransactions}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.totalDiscount
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.discount.toFixed(2)}
          </Text>
          <Text
            style={{
              width: "8%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.tax.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.serviceCharge
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          {/* <Text style={{ flex: 1, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.gp}</Text> */}
          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.totalSalesReturn
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.netSales.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          <Text
            style={{
              width: "11%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "right",
              paddingRight: 20,
              fontVariant: ["tabular-nums"],
            }}
          >
            {item.averageNetSales
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const onClickItemDetails = (item) => {
    setExpandDetailsDict({
      ...expandDetailsDict,
      [item.uniqueId]: expandDetailsDict[item.uniqueId] ? false : true,
    });
  };

  const renderItemDetails = ({ item, index }) => {
    ///////////////////////////

    console.log("order id");
    console.log(item.orderId);

    // calculate longest

    var longestStr = 8;

    ///////////////////////////

    // calculate spacing

    var cartItemPriceWIthoutAddOnSpacingList = [];
    var addOnsSpacingList = [];

    for (var i = 0; i < item.cartItems.length; i++) {
      const cartItemPriceWIthoutAddOn =
        item.cartItems[i].price -
        item.cartItems[i].addOns.reduce(
          (accum, addOn) => accum + addOn.prices[0],
          0
        );

      cartItemPriceWIthoutAddOnSpacingList.push(
        Math.max(longestStr - cartItemPriceWIthoutAddOn.toFixed(0).length, 0) +
        1
      );

      for (var j = 0; j < item.cartItems[i].addOns.length; j++) {
        addOnsSpacingList.push(
          Math.max(
            longestStr -
            item.cartItems[i].addOns[j].prices[0].toFixed(0).length,
            0
          ) + 1
        );
      }
    }

    var totalPriceSpacing =
      Math.max(longestStr - item.totalPrice.toFixed(0).length, 0) + 1;
    var discountSpacing =
      Math.max(longestStr - item.discount.toFixed(0).length, 0) + 1;
    var taxSpacing = Math.max(longestStr - item.tax.toFixed(0).length, 0) + 1;
    var finalPriceSpacing =
      Math.max(longestStr - item.finalPrice.toFixed(0).length, 0) + 1;

    ///////////////////////////
    return (
      <TouchableOpacity
        onPress={() => onClickItemDetails(item)}
        style={{
          backgroundColor:
            (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
          paddingVertical: 10,
          //paddingHorizontal: 3,
          //paddingLeft: 1,
          borderColor: "#BDBDBD",
          borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
          borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
        }}
      >
        <View style={{ flexDirection: "row" }}>
          <Text
            style={{
              width: "6%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {index + 1}
          </Text>
          <Text
            style={{
              width: "16%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {moment(item.completedDate).format('DD MMM hh:mm A')}
          </Text>
          <Text
            style={{
              width: "11%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.totalPrice
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.discount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.discountPercentage.toFixed(2)}
          </Text>
          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {item.tax.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          <Text
            style={{
              width: "10%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {(item.sc || 0).toFixed(2)}
          </Text>
          {/* <Text style={{ flex: 1, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{(0).toFixed(2)}</Text> */}
          <Text
            style={{
              width: "13%",
              fontSize: switchMerchant ? 10 : 13,
              fontFamily: "NunitoSans-Regular",
              textAlign: "left",
              paddingLeft: 10,
            }}
          >
            {(Math.ceil(item.finalPrice * 20 - 0.05) / 20)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
          </Text>
          <View
            style={{
              width: "14%",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingLeft: 10,
              paddingRight: switchMerchant ? "2.25%" : "1.75%",
            }}
          >
            <Text style={{}} />
            <Text
              style={{
                fontSize: switchMerchant ? 10 : 13,
                fontFamily: "NunitoSans-Regular",
              }}
            >
              {/* <Text style={{
                              opacity: 0,
                              ...Platform.OS === 'android' && {
                                  color: 'transparent',
                              },
                          }}>
                              {'0'.repeat((finalPriceSpacing * 0.6) + (item.finalPrice.toFixed(0).length === 1 ? 1 : 0))}
                          </Text> */}
              {(Math.ceil(item.finalPrice * 20 - 0.05) / 20)
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
            </Text>
          </View>
          {/* <Text style={{ flex: 2, fontSize: 13, fontFamily: 'NunitoSans-Regular', textAlign: 'center' }}>{item.averageNetSales.toFixed(2)}</Text> */}
        </View>

        {expandDetailsDict[item.uniqueId] == true ? (
          <View
            style={{
              minHeight: Dimensions.get('screen').height * 0.35,
              marginTop: 30,
              paddingBottom: 20,
            }}
          >
            {item.cartItems.map((cartItem, index) => {
              const cartItemPriceWIthoutAddOn =
                cartItem.price -
                cartItem.addOns.reduce(
                  (accum, addOn) => accum + addOn.prices[0],
                  0
                );

              return (
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      alignItems: "flex-start",
                      flexDirection: "row",
                      marginBottom: Platform.OS == "ios" ? 10 : 10,
                      minHeight: 80,
                      //backgroundColor: 'yellow',
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        //backgroundColor: 'blue',
                      }}
                    >
                      {index == 0 ? (
                        <View
                          style={{
                            marginHorizontal: 1,
                            width: Platform.OS == "ios" ? "8%" : "8%",
                            //justifyContent: 'center',
                            alignItems: "center",
                            //backgroundColor: 'blue',
                          }}
                        >
                          <TouchableOpacity
                            style={{
                              alignItems: "center",
                              marginTop: 0,
                            }}
                            onPress={() => {
                              var crmUser = null;

                              if (item.crmUserId !== undefined) {
                                for (var i = 0; i < crmUsers.length; i++) {
                                  if (item.crmUserId === crmUsers[i].uniqueId) {
                                    crmUser = crmUsers[i];
                                    break;
                                  }
                                }
                              }

                              if (!crmUser) {
                                for (var i = 0; i < crmUsers.length; i++) {
                                  if (item.userId === crmUsers[i].firebaseUid) {
                                    crmUser = crmUsers[i];
                                    break;
                                  }
                                }
                              }

                              if (crmUser) {
                                CommonStore.update(
                                  (s) => {
                                    s.selectedCustomerEdit = crmUser;
                                    // s.selectedCustomerEdit = userReservations[item.userId] && crmUsers[item.userId] ? crmUsers[item.userId] : null ;

                                    s.routeParams = {
                                      pageFrom: "Reservation",
                                    };
                                  },
                                  () => {
                                    navigation.navigate("NewCustomer");
                                  }
                                );
                              }
                            }}
                          >
                            <Image
                              style={{
                                width: switchMerchant ? 30 : 60,
                                height: switchMerchant ? 30 : 60,
                              }}
                              resizeMode="contain"
                              source={require("../assets/image/default-profile.png")}
                            />

                            <View
                              style={{
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "NunitoSans-Bold",
                                    marginTop: 0,
                                    fontSize: 13,
                                    textAlign: "center",
                                  },
                                  switchMerchant
                                    ? {
                                      fontFamily: "NunitoSans-Bold",
                                      marginTop: 0,
                                      fontSize: 10,
                                      textAlign: "center",
                                    }
                                    : {},
                                ]}
                                numberOfLines={1}
                              >
                                {item.userName ? item.userName : "Guest"}
                              </Text>
                            </View>
                          </TouchableOpacity>
                        </View>
                      ) : (
                        <View
                          style={{
                            marginHorizontal: 1,
                            width: Platform.OS == "ios" ? "8%" : "8%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        />
                      )}

                      <View
                        style={{
                          // flex: 0.3,
                          width: "5%",
                          //justifyContent: 'center',
                          alignItems: "center",
                        }}
                      >
                        <Text
                          style={[
                            {
                              fontFamily: "NunitoSans-Bold",
                              fontSize: 13,
                            },
                            switchMerchant
                              ? {
                                fontFamily: "NunitoSans-Bold",
                                fontSize: 10,
                              }
                              : {},
                          ]}
                        >
                          {index + 1}.
                        </Text>
                      </View>

                      <View
                        style={{
                          //flex: 0.5,
                          width: "10%",
                          //backgroundColor: 'green',
                          alignItems: "center",
                        }}
                      >
                        {
                          cartItem.image
                            ?
                            <AsyncImage
                              source={{ uri: cartItem.image }}
                              item={cartItem}
                              style={{
                                width: switchMerchant ? 30 : 60,
                                height: switchMerchant ? 30 : 60,
                                borderWidth: 1,
                                borderColor: "#E5E5E5",
                                borderRadius: 5,
                              }}
                            />
                            :
                            <Ionicons name="fast-food-outline" size={30} />
                        }
                      </View>
                      <View style={{ width: "75%" }}>
                        <View
                          style={{
                            marginLeft: Platform.OS == "ios" ? 14 : 14,
                            marginBottom: 10,
                            //backgroundColor: 'blue',
                            width: "100%",
                            flexDirection: "row",
                          }}
                        >
                          <View style={{ width: "69%" }}>
                            <Text
                              style={[
                                {
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: 13,
                                },
                                switchMerchant
                                  ? {
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 10,
                                  }
                                  : {},
                              ]}
                            >
                              {cartItem.name}
                            </Text>
                          </View>

                          <View
                            style={{
                              width: switchMerchant ? "12.8%" : "13%",
                            }}
                          >
                            <View
                              style={{
                                alignItems: "center",
                                //backgroundColor: 'yellow',
                              }}
                            >
                              <Text
                                style={[
                                  {
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: 13,
                                  },
                                  switchMerchant
                                    ? {
                                      fontFamily: "NunitoSans-Bold",
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                x{cartItem.quantity}
                              </Text>
                            </View>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              width: "18.8%",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                  }
                                  : { fontSize: 13 }
                              }
                            >
                              RM
                            </Text>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    paddingRight: 20,
                                    fontFamily: "NunitoSans-Regular",
                                  }
                                  : {
                                    fontSize: 13,
                                    paddingRight: 20,
                                    fontFamily: "NunitoSans-Regular",
                                  }
                              }
                            >
                              {cartItemPriceWIthoutAddOn
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                            </Text>
                          </View>
                        </View>

                        {cartItem.remarks && cartItem.remarks.length > 0 ? (
                          <View
                            style={{
                              alignItems: "center",
                              flexDirection: "row",
                              marginLeft: Platform.OS == "ios" ? 14 : 14,
                            }}
                          >
                            <View style={{ justifyContent: "center" }}>
                              <Text
                                style={[
                                  {
                                    fontFamily: "NunitoSans-SemiBold",
                                    fontSize: 13,
                                  },
                                  switchMerchant
                                    ? {
                                      fontFamily: "NunitoSans-SemiBold",
                                      fontSize: 10,
                                    }
                                    : {},
                                ]}
                              >
                                {cartItem.remarks}
                              </Text>
                            </View>
                          </View>
                        ) : (
                          <></>
                        )}

                        {cartItem.addOns.map((addOnChoice, i) => {
                          return (
                            <View
                              style={{
                                flexDirection: "row",
                                // marginLeft: -5,
                                width: "100%",
                              }}
                            >
                              <View
                                style={{
                                  width: "69%",
                                  flexDirection: "row",
                                  marginLeft: Platform.OS == "ios" ? 14 : 14,
                                }}
                              >
                                <Text
                                  style={[
                                    {
                                      fontFamily: "NunitoSans-Bold",
                                      fontSize: 13,
                                      color: Colors.descriptionColor,
                                      width: "25%",
                                      // marginLeft: 5,
                                    },
                                    switchMerchant
                                      ? {
                                        fontFamily: "NunitoSans-Bold",
                                        fontSize: 10,
                                        color: Colors.descriptionColor,
                                        width: "25%",
                                      }
                                      : {},
                                  ]}
                                >
                                  {`${addOnChoice.name}:`}
                                </Text>
                                <Text
                                  style={[
                                    {
                                      fontFamily: "NunitoSans-Bold",
                                      fontSize: 13,
                                      color: Colors.descriptionColor,
                                      width: "75%",
                                      // marginLeft: 5,
                                    },
                                    switchMerchant
                                      ? {
                                        fontSize: 8,
                                      }
                                      : {},
                                  ]}
                                >
                                  {`${addOnChoice.choiceNames[0]}`}
                                </Text>
                              </View>

                              <View
                                style={[
                                  {
                                    width: switchMerchant ? "12.8%" : "13%",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    //backgroundColor: 'blue',
                                  },
                                ]}
                              >
                                <Text
                                  style={[
                                    {
                                      fontFamily: "NunitoSans-Bold",
                                      fontSize: 13,
                                      color: Colors.descriptionColor,
                                      width: "28%",
                                      // right: 38,
                                      //backgroundColor: 'green',
                                      textAlign: "center",
                                    },
                                    switchMerchant
                                      ? {
                                        ontFamily: "NunitoSans-Bold",
                                        fontSize: 10,
                                        color: Colors.descriptionColor,
                                        width: "28%",
                                        textAlign: "center",
                                      }
                                      : {},
                                  ]}
                                >
                                  {`${addOnChoice.quantities
                                    ? `x${addOnChoice.quantities[0]}`
                                    : ""
                                    }`}
                                </Text>
                              </View>

                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "18.8%",
                                  alignItems: "center",
                                  //backgroundColor: 'red',
                                }}
                              >
                                <Text
                                  style={[
                                    switchMerchant
                                      ? {
                                        color: Colors.descriptionColor,
                                        fontSize: 10,
                                      }
                                      : {
                                        color: Colors.descriptionColor,
                                        fontSize: 13,
                                      },
                                  ]}
                                >
                                  RM
                                </Text>
                                <Text
                                  style={
                                    switchMerchant
                                      ? {
                                        color: Colors.descriptionColor,
                                        paddingRight: 20,
                                        fontSize: 10,
                                        fontFamily: "NunitoSans-Regular",
                                      }
                                      : {
                                        color: Colors.descriptionColor,
                                        paddingRight: 20,
                                        fontSize: 13,
                                        fontFamily: "NunitoSans-Regular",
                                      }
                                  }
                                >
                                  {addOnChoice.prices[0]
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: "row", width: "100%" }}>
                    <View style={{ width: "70%" }} />
                    <View style={{ width: 15 }} />
                    {index === item.cartItems.length - 1 ? (
                      <View
                        style={{
                          flexDirection: "row",
                          //backgroundColor: 'yellow',
                          width: "28.65%",
                        }}
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    width: "50.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                                  : {
                                    fontSize: 13,
                                    width: "50.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                              }
                            >
                              Subtotal:
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "49.2%",
                              }}
                            >
                              <Text
                                style={
                                  switchMerchant
                                    ? { fontSize: 10 }
                                    : { fontSize: 13 }
                                }
                              >
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      paddingRight: 20,
                                      fontFamily: "NunitoSans-Regular",
                                    }
                                    : {
                                      fontSize: 13,
                                      paddingRight: 20,
                                      fontFamily: "NunitoSans-Regular",
                                    }
                                }
                              >
                                {item.totalPrice
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Text>
                            </View>
                          </View>
                          {cartItem.orderType === ORDER_TYPE.DELIVERY ? (
                            <View
                              style={{
                                flexDirection: "row",
                              }}
                            >
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      width: "50.9%",
                                      fontFamily: "Nunitosans-Bold",
                                    }
                                    : {
                                      fontSize: 13,
                                      width: "50.9%",
                                      fontFamily: "Nunitosans-Bold",
                                    }
                                }
                              >
                                Delivery Fee:
                              </Text>
                              <View
                                style={{
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "49.2%",
                                }}
                              >
                                <Text
                                  style={
                                    switchMerchant
                                      ? { fontSize: 10 }
                                      : { fontSize: 13 }
                                  }
                                >
                                  RM
                                </Text>
                                <Text
                                  style={
                                    switchMerchant
                                      ? {
                                        fontSize: 10,
                                        paddingRight: 20,
                                        fontFamily: "NunitoSans-Regular",
                                      }
                                      : {
                                        fontSize: 13,
                                        paddingRight: 20,
                                        fontFamily: "NunitoSans-Regular",
                                      }
                                  }
                                >
                                  {item.deliveryFee
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                                </Text>
                              </View>
                            </View>
                          ) : (
                            <></>
                          )}

                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    width: "50.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                                  : {
                                    fontSize: 13,
                                    width: "50.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                              }
                            >
                              Discount:
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "49.2%",
                              }}
                            >
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : { fontSize: 13 }
                                }
                              >
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      paddingRight: 20,
                                      fontFamily: "NunitoSans-Regular",
                                    }
                                    : {
                                      fontSize: 13,
                                      paddingRight: 20,
                                      fontFamily: "NunitoSans-Regular",
                                    }
                                }
                              >
                                -{" "}
                                {item.discount
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    width: "50.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                                  : {
                                    fontSize: 13,
                                    width: "50.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                              }
                            >
                              Tax:
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "49.2%",
                              }}
                            >
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      paddingRight: 20,
                                    }
                                    : { fontSize: 13, paddingRight: 20 }
                                }
                              >
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      paddingRight: 20,
                                      fontFamily: "NunitoSans-Regular",
                                    }
                                    : {
                                      fontSize: 13,
                                      paddingRight: 20,
                                      fontFamily: "NunitoSans-Regular",
                                    }
                                }
                              >
                                {item.tax
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: 'row',
                            }}>
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10, width: '50.85%', fontFamily: 'Nunitosans-Bold',
                                  }
                                  : {
                                    fontSize: 13,
                                    width: '50.85%',
                                    fontFamily: 'Nunitosans-Bold',
                                  }
                              }>
                              Service Charge:
                            </Text>
                            <View
                              style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                width: switchMerchant ? '49.15%' : '49.1%',
                              }}>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : { fontSize: 13, }
                                }>
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10, paddingRight: 20, fontFamily: 'NunitoSans-Regular'
                                    }
                                    : { fontSize: 13, paddingRight: 20, fontFamily: 'NunitoSans-Regular' }
                                }>
                                {(item.sc || 0)
                                  .toFixed(2)
                                  .replace(
                                    /(\d)(?=(\d{3})+(?!\d))/g,
                                    '$1,',
                                  )}
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                            }}
                          >
                            <Text
                              style={
                                switchMerchant
                                  ? {
                                    fontSize: 10,
                                    width: "50.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                                  : {
                                    fontSize: 13,
                                    width: "50.9%",
                                    fontFamily: "Nunitosans-Bold",
                                  }
                              }
                            >
                              Total:
                            </Text>
                            <View
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "49.2%",
                              }}
                            >
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                    }
                                    : { fontSize: 13 }
                                }
                              >
                                RM
                              </Text>
                              <Text
                                style={
                                  switchMerchant
                                    ? {
                                      fontSize: 10,
                                      paddingRight: 20,
                                      fontFamily: "NunitoSans-Regular",
                                    }
                                    : {
                                      fontSize: 13,
                                      paddingRight: 20,
                                      fontFamily: "NunitoSans-Regular",
                                    }
                                }
                              >
                                {item.finalPrice
                                  .toFixed(2)
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    ) : (
                      <></>
                    )}
                  </View>
                </View>
              );
            })}
          </View>
        ) : null}
      </TouchableOpacity>
    );
  };

  const convertDataToExcelFormat = () => {
    var excelData = [];

    if (!showDetails) {
      //List
      for (var i = 0; i < dailySalesDetailsList.length; i++) {
        var excelRow = {
          // 'Transaction Category': transactionTypeSales[i].orderType,
          // 'Total Sales (RM)': parseFloat(transactionTypeSales[i].totalSales).toFixed(2),
          // 'Total Transaction': transactionTypeSales[i].totalTransactions,
          // 'Total Discount (RM)': parseFloat(transactionTypeSales[i].totalDiscount).toFixed(2),
          // 'Discount (%)': parseFloat(transactionTypeSales[i].discount).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSales[i].tax).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSales[i].serviceCharge).toFixed(2),
          // 'GP (%)': parseFloat(transactionTypeSales[i].gp).toFixed(2),
          // 'Net Sales (RM)': parseFloat(transactionTypeSales[i].netSales).toFixed(2),
          // 'Average Net Sales (RM)': parseFloat(transactionTypeSales[i].averageNetSales).toFixed(2),
          Date: moment(dailySalesDetailsList[i].dateTime).format('DD MMM'),
          'Total Sales (RM)': +parseFloat(dailySalesDetailsList[i].totalSales)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          'Total Transaction': dailySalesDetailsList[i].totalTransactions,
          'Total Discount (RM)': +parseFloat(
            dailySalesDetailsList[i].totalDiscount,
          )
            .toFixed(2),
          'Discount (%)': +parseFloat(dailySalesDetailsList[i].discount).toFixed(
            2,
          ),
          'Tax (RM)': +parseFloat(dailySalesDetailsList[i].tax)
            .toFixed(2),
          'Service Charge (RM)': +parseFloat(
            dailySalesDetailsList[i].serviceCharge,
          ).toFixed(2),
          'GP (%)': +parseFloat(dailySalesDetailsList[i].gp).toFixed(2),
          'Total Sales Return': +parseFloat(
            dailySalesDetailsList[i].totalSalesReturn,
          )
            .toFixed(2),
          'Net Sales (RM)': +parseFloat(dailySalesDetailsList[i].netSales)
            .toFixed(2),
          'Average Net Sales (RM)': +parseFloat(
            dailySalesDetailsList[i].averageNetSales,
          )
            .toFixed(2),
        };

        excelData.push(excelRow);
      }
    } else {
      //Detail List
      for (var i = 0; i < transactionTypeSalesDetails.length; i++) {
        var excelRow = {
          // 'Transaction Category': ORDER_TYPE_PARSED[transactionTypeSalesDetails[i].orderType],
          // 'Sales (RM)': parseFloat(transactionTypeSalesDetails[i].finalPrice).toFixed(2),
          // 'Transaction Date': moment(transactionTypeSalesDetails[i].createdAt).format('DD MMM hh:mma'),
          // 'Total Discount (RM)': parseFloat(transactionTypeSalesDetails[i].discount).toFixed(2),
          // 'Discount (%)': parseFloat(isFinite(transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount) ? transactionTypeSalesDetails[i].finalPrice / transactionTypeSalesDetails[i].discount * 100 : 0).toFixed(2),
          // 'Tax (RM)': parseFloat(transactionTypeSalesDetails[i].tax).toFixed(2),
          // 'Tax (RM)': parseFloat(0).toFixed(2),
          // 'GP (%)': parseFloat(0).toFixed(2),
          // 'Net Sales (RM)': parseFloat(transactionTypeSalesDetails[i].totalPrice).toFixed(2),
          'Date/Time': moment(
            transactionTypeSalesDetails[i].completedDate,
          ).format('DD MMM hh:mma'),
          'Sales (RM)': parseFloat(transactionTypeSalesDetails[i].finalPrice)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          'Total Discount (RM)': parseFloat(
            transactionTypeSalesDetails[i].discount,
          )
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          'Discount (%)': parseFloat(
            transactionTypeSalesDetails[i].discountPercentage,
          ).toFixed(2),
          'Tax (RM)': parseFloat(transactionTypeSalesDetails[i].tax)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          'Service Charge (RM)': parseFloat(
            transactionTypeSalesDetails[i].sc,
          ).toFixed(2),
          'GP (%)': parseFloat(0).toFixed(2),
          'Sales Return': parseFloat(transactionTypeSalesDetails[i].totalPrice)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          'Net Sales (RM)': parseFloat(
            transactionTypeSalesDetails[i].totalPrice,
          )
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
        };

        excelData.push(excelRow);
      }
    }

    console.log('excelData');
    console.log(excelData);

    return excelData;
  };

  // const downloadExcel = () => {
  //   const excelData = convertDataToExcelFormat();

  //   var excelFile = `${Platform.OS === 'ios'
  //       ? RNFS.DocumentDirectoryPath
  //       : RNFS.DownloadDirectoryPath
  //     }/koodoo-report-Product-Sales${moment().format(
  //       'YYYY-MM-DD-HH-mm-ss',
  //     )}.xlsx`;
  //   var excelWorkSheet = XLSX.utils.json_to_sheet(excelData);
  //   var excelWorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(
  //     excelWorkBook,
  //     excelWorkSheet,
  //     'Product Sales Report',
  //   );

  //   const workBookData = XLSX.write(excelWorkBook, {
  //     type: 'binary',
  //     bookType: 'xlsx',
  //   });

  //   RNFS.writeFile(excelFile, workBookData, 'ascii')
  //     .then((success) => {
  //       console.log(`wrote file ${excelFile}`);

  //       alert(
  //         'Success',
  //         `Sent to ${excelFile}`,
  //         [{ text: 'OK', onPress: () => { } }],
  //         { cancelable: false },
  //       );
  //     })
  //     .catch((err) => {
  //       console.log(err.message);
  //     });
  // };

  const barFilterPressed = (barFilterTapped) => {
    setExpandBarSelection(true);
  };

  const lineFilterPressed = (lineFilterTapped) => {
    setExpandLineSelection(true);
  };

  const flatListRef = useRef();

  const ScrollToTop = () => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 0 });
  };

  const ScrollToBottom = () => {
    flatListRef.current.scrollToOffset({ animated: true, offset: 100 });
  };

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={0} />
      </View>

      <View
        style={{
          position: "absolute",
          width: 128,
          height: 128,

          zIndex: -9999,

          opacity: 0,

          left: "10%",
        }}
      >
        <Canvas ref={canvasImage} />
      </View>

      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ width: windowWidth * 0.9 }}
          contentContainerStyle={{
            paddingBottom: Dimensions.get("screen").height * 0.1,
          }}
        >
          <DateTimePickerModal
            isVisible={showDateTimePicker}
            mode={"date"}
            onConfirm={(text) => {
              setRev_date(moment(text).startOf('day'));
              setShowDateTimePicker(false);

              setSalesLineChartPeriod(CHART_PERIOD.NONE);
              setSalesBarChartPeriod(CHART_PERIOD.NONE);
            }}
            onCancel={() => {
              setShowDateTimePicker(false);
            }}
            maximumDate={moment(rev_date1).toDate()}
          />

          <DateTimePickerModal
            isVisible={showDateTimePicker1}
            mode={"date"}
            onConfirm={(text) => {
              setRev_date1(moment(text).endOf('day'));
              setShowDateTimePicker1(false);

              setSalesLineChartPeriod(CHART_PERIOD.NONE);
              setSalesBarChartPeriod(CHART_PERIOD.NONE);
            }}
            onCancel={() => {
              setShowDateTimePicker1(false);
            }}
            minimumDate={moment(rev_date).toDate()}
          />

          <DateTimePickerModal
            isVisible={showDateTimePickerFilterLineChart}
            mode={"date"}
            onConfirm={(text) => {
              setSelectedChartFilterQueriesLineChart([
                {
                  ...selectedChartFilterQueriesLineChart[0],
                  fieldDataValue: text,
                },
              ]);

              setShowDateTimePickerFilterLineChart(false);
            }}
            onCancel={() => {
              setShowDateTimePickerFilterLineChart(false);
            }}
          />

          <DateTimePickerModal
            isVisible={showDateTimePickerFilterBarChart}
            mode={"date"}
            onConfirm={(text) => {
              setSelectedChartFilterQueriesBarChart([
                {
                  ...selectedChartFilterQueriesBarChart[0],
                  fieldDataValue: text,
                },
              ]);

              setShowDateTimePickerFilterBarChart(false);
            }}
            onCancel={() => {
              setShowDateTimePickerFilterBarChart(false);
            }}
          />

          <Modal
            style={{}}
            visible={exportModalVisibility}
            supportedOrientations={["portrait", "landscape"]}
            transparent={true}
            animationType={"fade"}
          >
            <View
              style={[
                styles.modalContainer,
                {
                  top: Platform.OS === "android" ? 0 : 0,
                },
              ]}
            >
              <View style={[styles.modalView, {}]}>
                <TouchableOpacity
                  disabled={isLoading}
                  style={styles.closeButton}
                  onPress={() => {
                    setExportModalVisibility(false);
                  }}
                >
                  <AntDesign
                    name="closecircle"
                    size={switchMerchant ? 15 : 25}
                    color={Colors.fieldtTxtColor}
                  />
                </TouchableOpacity>
                <View style={styles.modalTitle}>
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      textAlign: "center",
                      fontSize: switchMerchant ? 16 : 24,
                    }}
                  >
                    Download Report
                  </Text>
                </View>
                <View style={{ top: switchMerchant ? "14%" : "10%" }}>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: "NunitoSans-Bold",
                    }}
                  >
                    Email Address:
                  </Text>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={{
                      backgroundColor: Colors.fieldtBgColor,
                      width: switchMerchant ? 240 : 370,
                      height: switchMerchant ? 35 : 50,
                      borderRadius: 5,
                      padding: 5,
                      marginVertical: 5,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      paddingLeft: 10,
                      fontSize: switchMerchant ? 10 : 16,
                    }}
                    placeholderStyle={{ padding: 5 }}
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    placeholder="Enter Your Email"
                    onChangeText={(text) => {
                      setExportEmail(text);
                    }}
                    value={exportEmail}
                  />
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: "NunitoSans-Bold",
                      marginTop: 15,
                    }}
                  >
                    Download As:
                  </Text>

                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      //top: '10%',
                      flexDirection: "row",
                      marginTop: 15,
                    }}
                  >
                    {/* <TouchableOpacity
                      disabled={isLoading}
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: switchMerchant ? 100 : 100,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginRight: 15,
                      }}
                      onPress={() => {
                        if (exportEmail.length > 0) {
                          CommonStore.update((s) => {
                            s.isLoading = true;
                          });

                          setIsExcel(true);

                          const excelData = convertDataToExcelFormat();

                          generateEmailReport(
                            EMAIL_REPORT_TYPE.EXCEL,
                            excelData,
                            'KooDoo Dashboard Report',
                            'KooDoo Dashboard Report.xlsx',
                            `/merchant/${merchantId}/reports/${uuidv4()}.xlsx`,
                            exportEmail,
                            'KooDoo Dashboard Report',
                            'KooDoo Dashboard Report',
                            () => {
                              CommonStore.update((s) => {
                                s.isLoading = false;
                              });

                              setIsExcel(true);

                              alert(
                                'Success',
                                'Report will be sent to the email address soon',
                              );

                              setExportModalVisibility(false);
                            },
                          );
                        } else {
                          alert("Info: Invalid email address");
                        }
                      }}
                    >
                      {isLoading && isExcel ? (
                        <ActivityIndicator
                          size={"small"}
                          color={Colors.whiteColor}
                        />
                      ) : (
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          EXCEL
                        </Text>
                      )}
                    </TouchableOpacity>

                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: switchMerchant ? 100 : 100,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      onPress={() => {
                        if (exportEmail.length > 0) {
                          CommonStore.update((s) => {
                            s.isLoading = true;
                          });

                          setIsCsv(true);

                          // const csvData = convertArrayToCSV(todaySalesChart.dataSource.data);
                          const csvData = convertArrayToCSV(
                            dailySalesDetailsList
                          );

                          generateEmailReport(
                            EMAIL_REPORT_TYPE.CSV,
                            csvData,
                            'KooDoo Dashboard Report',
                            'KooDoo Dashboard Report.csv',
                            `/merchant/${merchantId}/reports/${uuidv4()}.csv`,
                            exportEmail,
                            'KooDoo Dashboard Report',
                            'KooDoo Dashboard Report',
                            () => {
                              CommonStore.update((s) => {
                                s.isLoading = false;
                              });

                              setIsCsv(false);

                              alert(
                                'Success',
                                'Report will be sent to the email address soon',
                              );

                              setExportModalVisibility(false);
                            },
                          );
                        } else {
                          alert("Info: Invalid email address");
                        }
                      }}
                    >
                      {isLoading && isCsv ? (
                        <ActivityIndicator
                          size={"small"}
                          color={Colors.whiteColor}
                        />
                      ) : (
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          CSV
                        </Text>
                      )}
                    </TouchableOpacity> */}

                    <CSVLink
                      style={{
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                        display: "inline-block",
                        flexDirection: "row",
                        textDecoration: 'none',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: switchMerchant ? 100 : 100,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      data={convertDataToExcelFormat()}
                      filename="report.csv"
                    >
                      <View
                        style={{
                          width: "100%",
                          height: "100%",
                          alignContent: "center",
                          alignItems: "center",
                          alignSelf: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: switchMerchant ? 10 : 16,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          CSV
                        </Text>
                      </View>
                    </CSVLink>

                    {/* <TouchableOpacity
                          style={[styles.modalSaveButton, {
                              zIndex: -1
                          }]}
                          onPress={() => { downloadPDF() }}>
                          <Text style={[styles.modalDescText, { color: Colors.primaryColor }]}>PDF</Text>
                      </TouchableOpacity> */}
                  </View>
                </View>
              </View>
            </View>
          </Modal>

          <Modal
            style={{ flex: 1 }}
            visible={visible}
            transparent={true}
            animationType="slide"
          >
            <KeyboardAvoidingView
              behavior="padding"
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                minHeight: Dimensions.get("window").height,
              }}
            >
              <View style={styles.confirmBox}>
                <Text
                  style={{
                    fontSize: 24,
                    justifyContent: "center",
                    alignSelf: "center",
                    marginTop: 40,
                    fontFamily: "NunitoSans-Bold",
                  }}
                >
                  Enter your email
                </Text>
                <View
                  style={{
                    justifyContent: "center",
                    alignSelf: "center",
                    alignContent: "center",
                    marginTop: 20,
                    flexDirection: "row",
                    width: "80%",
                  }}
                >
                  <View
                    style={{ justifyContent: "center", marginHorizontal: 5 }}
                  >
                    <Text
                      style={{ color: Colors.descriptionColor, fontSize: 20 }}
                    >
                      email:
                    </Text>
                  </View>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={[styles.textInput8, { paddingLeft: 5 }]}
                    placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
                    placeholder="Enter your email"
                    // style={{
                    //     // paddingLeft: 1,
                    // }}
                    //defaultValue={extentionCharges}
                    onChangeText={(text) => {
                      // setState({ exportEmail: text });
                      setExportEmail(text);
                    }}
                    value={exportEmail}
                  />
                </View>

                <View
                  style={{
                    alignSelf: "center",
                    marginTop: 20,
                    justifyContent: "center",
                    alignItems: "center",
                    // width: 260,
                    width: Dimensions.get("screen").width * 0.2,
                    height: 60,
                    alignContent: "center",
                    flexDirection: "row",
                    marginTop: 40,
                  }}
                >
                  <TouchableOpacity
                    onPress={emailDashboard}
                    style={{
                      backgroundColor: Colors.fieldtBgColor,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: 60,
                      borderBottomLeftRadius: 10,
                      borderRightWidth: StyleSheet.hairlineWidth,
                      borderTopWidth: StyleSheet.hairlineWidth,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 22,
                        color: Colors.primaryColor,
                        fontFamily: "NunitoSans-SemiBold",
                      }}
                    >
                      Email
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      // setState({ visible: false });
                      setVisible(false);
                    }}
                    style={{
                      backgroundColor: Colors.fieldtBgColor,
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: 60,
                      borderBottomRightRadius: 10,
                      borderTopWidth: StyleSheet.hairlineWidth,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 22,
                        color: Colors.descriptionColor,
                        fontFamily: "NunitoSans-SemiBold",
                      }}
                    >
                      Cancel
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
            </KeyboardAvoidingView>
          </Modal>

          <View
            style={{
              paddingHorizontal: switchMerchant ? 0 : 0,
              paddingVertical: 30,
              marginHorizontal: 30,
            }}
          >
            <View
              style={{
                height: 70,
                flexDirection: "row",
                justifyContent: "space-between",
                marginHorizontal: 30,
                width: windowWidth * 0.8575,
              }}
            >
              <View>
                <Text
                  style={{
                    fontSize: switchMerchant ? 20 : 26,
                    fontFamily: "NunitoSans-Bold",
                    marginVertical: 6,
                  }}
                >
                  Dashboard
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <TouchableOpacity
                  style={{
                    justifyContent: "center",
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    //width: 160,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    alignItems: "center",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                  }}
                  onPress={() => {
                    setExportModalVisibility(true);
                  }}
                >
                  <Feather
                    name="download"
                    size={switchMerchant ? 10 : 20}
                    color={Colors.whiteColor}
                  />
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      marginLeft: 5,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: "NunitoSans-Bold",
                    }}
                  >
                    DOWNLOAD
                  </Text>
                </TouchableOpacity>
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                justifyContent: 'space-evenly',
                width: windowWidth * 0.875,
                marginHorizontal: 13,
              }}
            >
              <TouchableOpacity
                style={{
                  width: "32%",
                  height: switchMerchant ? 80 : 100,
                  backgroundColor: Colors.tabMint,
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: 30,
                  paddingVertical: 15,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}
                onPress={() => {
                  navigation.navigate("ReportSalesOvertime");
                }}
              >
                <View
                  style={{
                    marginTop: -15,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 20 : 28,
                    }}
                  >
                    RM {currentOutletTotalSales.toFixed(0)}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Regular",
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 10 : 13,
                    }}
                  >
                    Today Sales
                  </Text>
                </View>
                <View>
                  <Coins
                    height={switchMerchant ? 40 : 60}
                    width={switchMerchant ? 40 : 60}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  width: "32%",
                  height: switchMerchant ? 80 : 100,
                  backgroundColor: Colors.tabGold,
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: switchMerchant ? 10 : 30,
                  paddingVertical: 15,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}
                onPress={() => {
                  // navigation.navigate("AllTransaction");
                  linkTo && linkTo(`${prefix}/alltransaction`);
                  CommonStore.update((s) => {
                    s.expandTab = 'TRANSACTIONS';
                  });
                }}
              >
                <View style={{ marginTop: -15 }}>
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 20 : 28,
                    }}
                  >
                    {allDayTotalTransaction}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Regular",
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 10 : 13,
                    }}
                  >
                    {switchMerchant
                      ? "Today\nTransactions"
                      : "Today Transactions"}
                  </Text>
                </View>
                <View>
                  <Hand
                    height={switchMerchant ? 40 : 60}
                    width={switchMerchant ? 40 : 60}
                  />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  width: "32%",
                  height: switchMerchant ? 80 : 100,
                  backgroundColor: Colors.tabCyan,
                  borderRadius: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingHorizontal: switchMerchant ? 10 : 30,
                  paddingVertical: 15,
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}
                onPress={() => {
                  navigation.navigate("ReportSalesProduct");
                }}
              >
                <View style={{ marginTop: -15 }}>
                  {/* <Text style={{ fontFamily: 'NunitoSans-Bold', color: Colors.whiteColor, fontSize: 28 }}>{allDayTotalSold}</Text> */}
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Bold",
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 20 : 28,
                    }}
                  >
                    {totalSold}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "NunitoSans-Regular",
                      color: Colors.whiteColor,
                      fontSize: switchMerchant ? 10 : 13,
                    }}
                  >
                    {switchMerchant
                      ? "Today\nProduct Sold"
                      : "Today Product Sold"}
                  </Text>
                </View>
                <View>
                  <Dish
                    height={switchMerchant ? 40 : 60}
                    width={switchMerchant ? 40 : 60}
                  />
                </View>
              </TouchableOpacity>
            </View>

            {/* LINE CHART SALES */}
            <View
              style={{
                backgroundColor: Colors.whiteColor,
                borderRadius: 10,
                width: windowWidth * 0.8575,
                minHeight: Dimensions.get('screen').height * 0.01,
                marginTop: 30,
                marginHorizontal: 30,
                marginBottom: 30,
                borderRadius: 5,
                shadowOpacity: 0,
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 3,
              }}
            >
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  marginVertical: 10,
                  marginBottom: 0,
                  paddingRight: 20,
                  alignItems: "center",
                  justifyContent: "space-between",
                  zIndex: 10000,
                }}
              >
                <View style={{ flexDirection: "row", width: "20%" }}>
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      marginVertical: 10,
                      marginBottom: 20,
                      alignItems: "center",
                    }}
                  >
                    <View style={{ marginHorizontal: 0, opacity: 0 }}>
                      <GCalendar width={22} height={22} />
                    </View>

                    {/* <Text style={{
                          fontFamily: 'NunitoSans-Bold',
                          color: Colors.primaryColor, fontSize: 18, marginHorizontal: 10, alignSelf: 'flex-start',
                          // backgroundColor: 'red',
                          width: Dimensions.get('screen').width * 0.3,
                      }}>
                          Total Sales (All Outlets) */}
                    {/* <>
                        {
                            salesLineChartPeriod === CHART_PERIOD.TODAY ? 'Total Today Sales (All Outlets)' : ''
                        }
                    </>
                    <>
                        {
                            salesLineChartPeriod === CHART_PERIOD.THIS_WEEK ? 'Total One Week Sales (All Outlets)' : ''
                        }
                    </>
                    <>
                        {
                            salesLineChartPeriod === CHART_PERIOD.THIS_MONTH ? 'Total One Month Sales (All Outlets)' : ''
                        }
                    </>
                    <>
                        {
                            salesLineChartPeriod === CHART_PERIOD.THREE_MONTHS ? 'Total Three Months Sales (All Outlets)' : ''
                        }
                    </>
                    <>
                        {
                            salesLineChartPeriod === CHART_PERIOD.SIX_MONTHS ? 'Total Six Months Sales (All Outlets)' : ''
                        }
                    </>
                    <>
                        {
                            salesLineChartPeriod === CHART_PERIOD.THIS_YEAR ? 'Total One Year Sales (All Outlets)' : ''
                        }
                    </>
                    <>
                        {
                            salesLineChartPeriod === CHART_PERIOD.YTD ? 'Total Year To Date Sales (All Outlets)' : ''
                        }
                    </> */}
                    {/* </Text> */}

                    {/* <DropDownPicker
                      arrowColor={"black"}
                      arrowSize={switchMerchant ? 10 : 20}
                      arrowStyle={{ fontWeight: "bold" }}
                      style={{
                        width: switchMerchant ? 140 : 250,
                        paddingVertical: 0,
                        backgroundColor: Colors.fieldtBgColor,
                        borderRadius: 10,
                      }}
                      placeholderStyle={{ color: Colors.fieldtTxtColor }}
                      items={
                        CHART_Y_AXIS_DROPDOWN_LIST[
                          CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                        ]
                      }
                      itemStyle={{ justifyContent: "flex-start", zIndex: 2 }}
                      placeholder={"Select"}
                      onChangeItem={(item) => {
                        setSelectedChartDropdownValueLineChart(item.value);
                      }}
                      defaultValue={selectedChartDropdownValueLineChart}
                      dropDownMaxHeight={150}
                      dropDownStyle={{
                        width: switchMerchant ? 140 : 250,
                        height: switchMerchant ? 100 : 120,
                        backgroundColor: Colors.fieldtBgColor,
                        borderRadius: 10,
                        borderWidth: 1,
                        textAlign: "left",
                        zIndex: 2,
                      }}
                      globalTextStyle={{
                        fontFamily: "NunitoSans-SemiBold",
                        fontSize: switchMerchant ? 10 : 14,
                        color: Colors.fontDark,
                        marginLeft: 5,
                      }}
                    /> */}
                    {/* <Picker
                      selectedValue={selectedChartDropdownValueLineChart}
                      style={{
                        width: switchMerchant ? 140 : 250,
                        paddingVertical: 0,
                        backgroundColor: Colors.fieldtBgColor,
                        borderRadius: 5,
                      }}
                      onValueChange={(item) => {
                        setSelectedChartDropdownValueLineChart(item.value);
                      }}
                    >
                      {CHART_Y_AXIS_DROPDOWN_LIST[
                        CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                      ].map((value, index) => {
                        return (
                          <Picker.Item
                            key={index}
                            label={value.label}
                            value={value.value}
                          />
                        );
                      })}
                    </Picker> */}
                    <View style={{ width: 240 }}>
                      <Select
                        placeholder={CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].label}
                        placeholderTextColor={'black'}
                        defaultValue={CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_LINE_CHART_SALES][0].value}
                        onChange={(item) => {
                          setSelectedChartDropdownValueLineChart(item.value);
                        }}
                        options={CHART_Y_AXIS_DROPDOWN_LIST[
                          CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                        ]}
                      />
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        marginLeft: switchMerchant ? 10 : 20,
                      }}
                    >
                      <View
                        style={{
                          paddingHorizontal: 15,
                          flexDirection: "row",
                          alignItems: "center",
                          borderRadius: 10,
                          paddingVertical: 10,
                          justifyContent: "center",
                          backgroundColor: Colors.whiteColor,
                          shadowOpacity: 0,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: 1,
                        }}
                      >
                        <View
                          style={{ alignSelf: "center", marginRight: 5 }}
                          onPress={() => {
                            setState({
                              pickerMode: "date",
                              showDateTimePicker: true,
                            });
                          }}
                        >
                          {/* <EvilIcons name="calendar" size={25} color={Colors.primaryColor} /> */}
                          <GCalendar
                            width={switchMerchant ? 10 : 20}
                            height={switchMerchant ? 10 : 20}
                          />
                        </View>

                        {/* <TouchableOpacity
                          onPress={() => {
                            setShowDateTimePicker(true);
                            setShowDateTimePicker1(false);
                          }}
                          style={{
                            marginHorizontal: 4,
                          }}
                        >
                          <Text
                            style={
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                    fontFamily: "NunitoSans-Regular",
                                  }
                                : { fontFamily: "NunitoSans-Regular" }
                            }
                          >
                            {moment(rev_date).format("DD MMM yyyy")}
                          </Text>
                        </TouchableOpacity> */}
                        <DatePicker
                          selected={rev_date.toDate()}
                          onChange={(date) => {
                            setRev_date(moment(date).startOf('day'));

                            setSalesLineChartPeriod(CHART_PERIOD.NONE);
                            setSalesBarChartPeriod(CHART_PERIOD.NONE);
                          }}
                          maxDate={moment(rev_date1).toDate()}
                        />

                        <Text
                          style={{
                            fontFamily: "NunitoSans-Regular",
                            paddingHorizontal: 5,
                            marginLeft: 5,
                          }}
                        >
                          -
                        </Text>

                        {/* <TouchableOpacity
                          onPress={() => {
                            setShowDateTimePicker(false);
                            setShowDateTimePicker1(true);
                          }}
                          style={{
                            marginHorizontal: 4,
                          }}
                        >
                          <Text
                            style={
                              switchMerchant
                                ? {
                                    fontSize: 10,
                                    fontFamily: "NunitoSans-Regular",
                                  }
                                : { fontFamily: "NunitoSans-Regular" }
                            }
                          >
                            {moment(rev_date1).format("DD MMM yyyy")}
                          </Text>
                        </TouchableOpacity> */}
                        <DatePicker
                          selected={rev_date1.toDate()}
                          onChange={(date) => {
                            setRev_date1(moment(date).endOf('day'));

                            setSalesLineChartPeriod(CHART_PERIOD.NONE);
                            setSalesBarChartPeriod(CHART_PERIOD.NONE);
                          }}
                          minDate={moment(rev_date).toDate()}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ flexDirection: "row", zIndex: -1, bottom: 5 }}>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.TODAY
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.TODAY
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.TODAY);
                      setSalesBarChartPeriod(CHART_PERIOD.TODAY);
                      setRev_date(moment().startOf("day"));
                      setRev_date1(moment().endOf("day"));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.TODAY
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.TODAY
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      TODAY
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.THIS_WEEK);
                      setSalesBarChartPeriod(CHART_PERIOD.THIS_WEEK);
                      setRev_date(moment(Date.now()).subtract(7, "days").startOf('day'));
                      setRev_date1(moment(Date.now()).startOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      1W
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH);
                      setSalesBarChartPeriod(CHART_PERIOD.THIS_MONTH);
                      setRev_date(moment().startOf("month").startOf('day'));
                      setRev_date1(moment().endOf("month").endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      1M
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.THREE_MONTHS);
                      setSalesBarChartPeriod(CHART_PERIOD.THREE_MONTHS);
                      setRev_date(moment(Date.now()).subtract(3, "months").startOf('day'));
                      setRev_date1(moment(Date.now()).endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod ==
                                CHART_PERIOD.THREE_MONTHS
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod ==
                                CHART_PERIOD.THREE_MONTHS
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      3M
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.SIX_MONTHS);
                      setSalesBarChartPeriod(CHART_PERIOD.SIX_MONTHS);
                      setRev_date(moment(Date.now()).subtract(6, "month").startOf('day'));
                      setRev_date1(moment(Date.now()).endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      6M
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.THIS_YEAR);
                      setSalesBarChartPeriod(CHART_PERIOD.THIS_YEAR);
                      setRev_date(moment().startOf("year").startOf('day'));
                      setRev_date1(moment().endOf("year").endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      1Y
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.YTD
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.YTD
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.YTD);
                      setSalesBarChartPeriod(CHART_PERIOD.YTD);
                      setRev_date(moment(Date.now()).subtract(12, "months").startOf('day'));
                      setRev_date1(moment(Date.now()).endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.YTD
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.YTD
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      YTD
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View
                style={{
                  // width: Dimensions.get('screen').width * 0.65
                  // left: 0,
                  // backgroundColor: 'red',
                  alignSelf: "flex-start",
                  marginLeft: "3%",
                  marginBottom: "1%",
                  zIndex: -1,
                }}
              >
                {/* <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.descriptionColor, fontSize: 16 }}>
                                    RM
                            </Text> */}
              </View>
              {/* <LineChart
                                data={
                                    {
                                        labels: outletLabel,
                                        datasets: outletSalesData

                                        // labels: CHART_HOURLY_LABEL_LIST,
                                        // datasets: [
                                        //     {
                                        //         data: totalHourlyDataDict,
                                        //     }
                                        // ]

                                        // datasets:
                                        //     [
                                        //         {
                                        //             data: outletSalesData.data.length == 0 ? tempData : outletSalesData[0],
                                        //             color: (opacity = 1) => Colors.tabMint,
                                        //         },
                                        //         {
                                        //             data: outletSalesData.length == 0 ? tempData : outletSalesData[0],
                                        //             color: (opacity = 1) => Colors.tabGold,
                                        //         },
                                        //         // {
                                        //         //     data: [0, 20, 30, 80, 99, 0],
                                        //         //     // data: outletSalesData.length == 0 ? tempData : outletSalesData[0],
                                        //         //     color: (opacity = 1) => Colors.tabMint,
                                        //         // },
                                        //         // {
                                        //         //     data: [0, 90, 50, 80, 80, 0],
                                        //         //     //data: outletSalesData.length == 0 ? tempData : outletSalesData[0],
                                        //         //     color: (opacity = 1) => Colors.tabGold,
                                        //         // },
                                        //         // {
                                        //         //     data: [200, 100, 70, 50, 60, 200],
                                        //         //     //data: outletSalesData.length == 0 ? tempData : outletSalesData[0],
                                        //         //     color: (opacity = 1) => Colors.tabGrey,
                                        //         // },


                                        //     ]                                        
                                    }
                                }
                                width={Dimensions.get('screen').width * 0.7} // from react-native
                                height={350}
                                fromZero={true}
                                yAxisInterval={1} // optional, defaults to 1

                                segments={6}
                                chartConfig={{
                                    backgroundGradientFrom: '#fff',
                                    backgroundGradientTo: '#fff',
                                    color: (opacity = 1) => `#fff`,
                                    labelColor: (opacity = 1) => `rgba(0, 0, 0, 1)`,
                                    strokeWidth: 2, // optional, default 3
                                    propsForLabels: { fontFamily: 'NunitoSans-Bold', fontSize: 14 },
                                    formatYLabel: { marginRight: 30 },
                                    decimalPlaces: 0,
                                    propsForBackgroundLines: {
                                        strokeWidth: 1,
                                        stroke: '#efefef',
                                        strokeDasharray: '0',
                                    },
                                    scrollableDotFill: Colors.tabGold,
                                    propsForDots: {

                                        r: "4",
                                        strokeWidth: "2",
                                    }
                                }}

                                style={{
                                    fontFamily: 'NunitoSans-Bold',
                                    marginVertical: 10,
                                }} 

                            />*/}

              {/* Line Chart */}

              {/* <View style={{
                                // backgroundColor: 'red'
                            }}>
                                <FusionCharts
                                    type={CHART_DATA2[CHART_TYPE.DASHBOARD_TODAY_SALES].type}
                                    width={Dimensions.get('screen').width * (0.9 - Styles.sideBarWidth)}
                                    height={Dimensions.get('screen').height * 0.4}
                                    //dataFormat={todaySalesChart.dataFormat}
                                    //dataSource={todaySalesChart.dataSource}
                                    libraryPath={FS_LIBRARY_PATH}
                                    //events={eventsChart}
                                />
                            </View> */}

              <View
                style={{
                  // backgroundColor: 'red'
                  zIndex: 2,
                  paddingLeft: 5,
                }}
              >
                {/* <FusionCharts
                  type={CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].type}
                  width={
                    Dimensions.get('screen').width <= 1133
                      ? switchMerchant
                        ? Dimensions.get('screen').width * 0.8
                        : Dimensions.get('screen').width *
                        (0.9 - Styles.sideBarWidth)
                      : Dimensions.get('screen').width *
                      (0.9 - Styles.sideBarWidth)
                  }
                  height={
                    switchMerchant
                      ? Dimensions.get('screen').height * 0.9
                      : Dimensions.get('screen').height * 0.5
                  }
                  dataFormat={salesLineChart.dataFormat}
                  dataSource={salesLineChart.dataSource}
                  libraryPath={FS_LIBRARY_PATH}
                  events={eventsChart}
                /> */}
                <FusionCharts
                  {...{
                    width: "100%",
                    height: windowHeight * 0.5,
                    type: CHART_DATA[CHART_TYPE.DASHBOARD_LINE_CHART_SALES].type, //msline
                    dataFormat: salesLineChart.dataFormat, //json
                    dataSource: salesLineChart.dataSource,
                  }}
                />
              </View>
            </View>

            <View
              style={{
                backgroundColor: Colors.whiteColor,
                borderRadius: 10,
                width: windowWidth * 0.8575,
                minHeight: Dimensions.get('screen').height * 0.01,
                marginTop: 30,
                marginHorizontal: 30,
                //marginBottom: 30,
                borderRadius: 5,
                shadowOpacity: 0,
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 2,
                },
                shadowOpacity: 0.22,
                shadowRadius: 3.22,
                elevation: 3,
              }}
            >
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  marginVertical: 10,
                  marginBottom: 0,
                  paddingRight: 20,
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ flexDirection: "row", width: "20%" }}>
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      marginVertical: 10,
                      marginBottom: 20,
                      alignItems: "center",
                    }}
                  >
                    <View style={{ marginHorizontal: 0, opacity: 0 }}>
                      <GCalendar width={22} height={22} />
                    </View>
                    {/* <DropDownPicker
                      arrowColor={"black"}
                      arrowSize={switchMerchant ? 10 : 20}
                      arrowStyle={{ fontWeight: "bold" }}
                      style={{
                        width: switchMerchant ? 140 : 250,
                        paddingVertical: 0,
                        backgroundColor: Colors.fieldtBgColor,
                        borderRadius: 10,
                      }}
                      placeholderStyle={{ color: Colors.fieldtTxtColor }}
                      items={
                        CHART_Y_AXIS_DROPDOWN_LIST[
                          CHART_TYPE.DASHBOARD_TODAY_SALES
                        ]
                      }
                      itemStyle={{ justifyContent: "flex-start", zIndex: 2 }}
                      placeholder={"Select"}
                      onChangeItem={(item) => {
                        setSelectedChartDropdownValueBarChart(item.value);
                      }}
                      defaultValue={selectedChartDropdownValueBarChart}
                      dropDownMaxHeight={150}
                      dropDownStyle={{
                        width: switchMerchant ? 140 : 250,
                        height: switchMerchant ? 100 : 120,
                        backgroundColor: Colors.fieldtBgColor,
                        borderRadius: 10,
                        borderWidth: 1,
                        textAlign: "left",
                        zIndex: 2,
                      }}
                      globalTextStyle={{
                        fontFamily: "NunitoSans-SemiBold",
                        fontSize: switchMerchant ? 10 : 14,
                        color: Colors.fontDark,
                        marginLeft: 5,
                      }}
                    /> */}
                    {/* <Picker
                      selectedValue={selectedChartDropdownValueBarChart}
                      style={{
                        width: switchMerchant ? 140 : 250,
                        paddingVertical: 0,
                        backgroundColor: Colors.fieldtBgColor,
                        borderRadius: 5,
                      }}
                      onValueChange={(item) => {
                        setSelectedChartDropdownValueBarChart(item.value);
                      }}
                    >
                      {CHART_Y_AXIS_DROPDOWN_LIST[
                        CHART_TYPE.DASHBOARD_TODAY_SALES
                      ].map((value, index) => {
                        return (
                          <Picker.Item
                            key={index}
                            label={value.label}
                            value={value.value}
                          />
                        );
                      })}
                    </Picker> */}
                    <View style={{ width: 240 }}>
                      <Select
                        style={{ zIndex: 1000 }}
                        placeholder={CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0].label}
                        placeholderTextColor={'black'}
                        defaultValue={CHART_Y_AXIS_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES][0].value}
                        onChange={(item) => {
                          setSelectedChartDropdownValueBarChart(item.value);
                        }}
                        options={CHART_Y_AXIS_DROPDOWN_LIST[
                          CHART_TYPE.DASHBOARD_TODAY_SALES
                        ]}
                      />
                    </View>

                    <View
                      style={{
                        flexDirection: "row",
                        marginLeft: switchMerchant ? 10 : 20,
                      }}
                    >
                      <View
                        style={{
                          paddingHorizontal: 15,
                          flexDirection: "row",
                          alignItems: "center",
                          borderRadius: 10,
                          paddingVertical: 10,
                          justifyContent: "center",
                          backgroundColor: Colors.whiteColor,
                          shadowOpacity: 0,
                          shadowColor: "#000",
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: 1,
                        }}
                      >
                        <View
                          style={{ alignSelf: "center", marginRight: 5 }}
                          onPress={() => {
                            setState({
                              pickerMode: "date",
                              showDateTimePicker: true,
                            });
                          }}
                        >
                          {/* <EvilIcons name="calendar" size={25} color={Colors.primaryColor} /> */}
                          <GCalendar
                            width={switchMerchant ? 10 : 20}
                            height={switchMerchant ? 10 : 20}
                          />
                        </View>

                        <DatePicker
                          selected={rev_date.toDate()}
                          onChange={(date) => {
                            setRev_date(moment(date).startOf('day'));

                            setSalesLineChartPeriod(CHART_PERIOD.NONE);
                            setSalesBarChartPeriod(CHART_PERIOD.NONE);
                          }}
                          maxDate={moment(rev_date1).toDate()}
                        />

                        <Text
                          style={{
                            fontFamily: "NunitoSans-Regular",
                            paddingHorizontal: 5,
                            marginLeft: 5,
                          }}
                        >
                          -
                        </Text>

                        <DatePicker
                          selected={rev_date1.toDate()}
                          onChange={(date) => {
                            setRev_date1(moment(date).endOf('day'));

                            setSalesLineChartPeriod(CHART_PERIOD.NONE);
                            setSalesBarChartPeriod(CHART_PERIOD.NONE);
                          }}
                          style={{ width: 50 }}
                          minDate={moment(rev_date).toDate()}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                <View style={{ flexDirection: "row", zIndex: -1, bottom: 5 }}>
                  {/* <TouchableOpacity style={{ padding: 10, borderBottomWidth: salesBarChartPeriod == CHART_PERIOD.DEFAULT ? 2 : StyleSheet.hairlineWidth, borderBottomColor: salesBarChartPeriod == CHART_PERIOD.DEFAULT ? Colors.primaryColor : Colors.lightPrimary }}
                                        onPress={() => { setSalesBarChartPeriod(CHART_PERIOD.DEFAULT) }}>
                                        <Text style={{ fontFamily: 'NunitoSans-Bold', color: salesBarChartPeriod == CHART_PERIOD.DEFAULT ? Colors.blackColor : Colors.descriptionColor }}>DEFAULT</Text>
                                    </TouchableOpacity> */}
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.TODAY
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.TODAY
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.TODAY);
                      setSalesBarChartPeriod(CHART_PERIOD.TODAY);
                      setRev_date(moment().startOf("day").startOf('day'));
                      setRev_date1(moment().endOf("day").endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.TODAY
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.TODAY
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      TODAY
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.THIS_WEEK);
                      setSalesBarChartPeriod(CHART_PERIOD.THIS_WEEK);
                      setRev_date(moment(Date.now()).subtract(7, "days").startOf('day'));
                      setRev_date1(moment(Date.now()).endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      1W
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH);
                      setSalesBarChartPeriod(CHART_PERIOD.THIS_MONTH);
                      setRev_date(moment().startOf("month").startOf('day'));
                      setRev_date1(moment().endOf("month").endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      1M
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.THREE_MONTHS);
                      setSalesBarChartPeriod(CHART_PERIOD.THREE_MONTHS);
                      setRev_date(moment(Date.now()).subtract(3, "months").startOf('day'));
                      setRev_date1(moment(Date.now()).endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod ==
                                CHART_PERIOD.THREE_MONTHS
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod ==
                                CHART_PERIOD.THREE_MONTHS
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      3M
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.SIX_MONTHS);
                      setSalesBarChartPeriod(CHART_PERIOD.SIX_MONTHS);
                      setRev_date(moment(Date.now()).subtract(6, "month").startOf('day'));
                      setRev_date1(moment(Date.now()).endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      6M
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.THIS_YEAR);
                      setSalesBarChartPeriod(CHART_PERIOD.THIS_YEAR);
                      setRev_date(moment().startOf("year").startOf('day'));
                      setRev_date1(moment().endOf("year").endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      1Y
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      padding: 10,
                      borderBottomWidth:
                        salesLineChartPeriod == CHART_PERIOD.YTD
                          ? 2
                          : StyleSheet.hairlineWidth,
                      borderBottomColor:
                        salesLineChartPeriod == CHART_PERIOD.YTD
                          ? Colors.primaryColor
                          : Colors.lightPrimary,
                    }}
                    onPress={() => {
                      setSalesLineChartPeriod(CHART_PERIOD.YTD);
                      setSalesBarChartPeriod(CHART_PERIOD.YTD);
                      setRev_date(moment(Date.now()).subtract(12, "months").startOf('day'));
                      setRev_date1(moment(Date.now()).endOf('day'));
                    }}
                  >
                    <Text
                      style={
                        switchMerchant
                          ? {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.YTD
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                            fontSize: 10,
                          }
                          : {
                            fontFamily: "NunitoSans-Bold",
                            color:
                              salesLineChartPeriod == CHART_PERIOD.YTD
                                ? Colors.blackColor
                                : Colors.descriptionColor,
                          }
                      }
                    >
                      YTD
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>

              <View
                style={{
                  alignSelf: "flex-start",
                  marginLeft: "3%",
                  marginBottom: "1%",
                  flexDirection: "row",
                  zIndex: -1,
                }}
              >
                {/* <Text style={{ fontFamily: 'NunitoSans-Regular', color: Colors.descriptionColor, fontSize: 16 }}>
                                    RM
                                </Text> */}
              </View>

              {/* <ScrollView
                                horizontal={true}
                                contentContainerStyle={{ height: 400 }}
                                style={{ scaleX: 1 }}
                                showsHorizontalScrollIndicator={false}>

                                <View style={{ flexDirection: 'row' }}>
                                    <BarChart
                                        data={
                                            {
                                                // labels: CHART_HOURLY_LABEL_LIST,
                                                // datasets: [
                                                //     {
                                                //         data: singleHourlyDataDict,
                                                //         color: (opacity = 1) => `rgba(30, 25, 240, ${opacity})`,
                                                //     }
                                                // ],
                                                labels: displayTodayLabel,
                                                datasets: [
                                                    {
                                                        data: displayTodayData,

                                                        color: (opacity = 1) => `rgba(30, 25, 240, ${opacity})`,
                                                    },

                                                ],
                                                barColors: ['#dfe4ea', '#ced6e0', '#a4b0be'],

                                            }
                                        }
                                        width={Dimensions.get('screen').width * 1.2} // from react-native
                                        height={350}
                                        fromZero={true}
                                        showValuesOnTopOfBars={true}
                                        segments={6}
                                        yAxisInterval={100} // optional, defaults to 1
                                        chartConfig={{
                                            barPercentage: 10 / displayTodayData.length,
                                            fillShadowGradient: Colors.tabGold,
                                            fillShadowGradientOpacity: 1,
                                            backgroundGradientFrom: '#fff',
                                            backgroundGradientTo: '#fff',
                                            fontFamily: 'NunitoSans-Bold',




                                            decimalPlaces: 0,
                                            color: (opacity = 1) => Colors.tabGold,
                                            labelColor: (opacity = 1) => `rgba(0, 0, 0, 1)`,
                                            strokeWidth: 2, // optional, default 3
                                            propsForLabels: { fontFamily: 'NunitoSans-Bold', fontSize: 10 },
                                            propsForBackgroundLines: {
                                                strokeWidth: 1,
                                                stroke: '#efefef',
                                                strokeDasharray: '0',
                                            },
                                            style: {
                                                borderRadius: 16
                                            },
                                            barRadius: 16
                                        }}
                                        style={{
                                            fontFamily: 'NunitoSans-Bold',
                                            marginTop: 10,
                                            paddingTop: 33,
                                            borderRadius: 16
                                        }}

                                    />
                                </View></ScrollView> */}

              <View
                style={{
                  // backgroundColor: 'red'
                  zIndex: 2,
                  paddingLeft: 5,
                  paddingBottom: 5,
                }}
              >
                {/* <FusionCharts
                  type={CHART_DATA[CHART_TYPE.DASHBOARD_TODAY_SALES].type}
                  width={
                    Dimensions.get('screen').width <= 1133
                      ? switchMerchant
                        ? Dimensions.get('screen').width * 0.8
                        : Dimensions.get('screen').width *
                        (0.9 - Styles.sideBarWidth)
                      : Dimensions.get('screen').width *
                      (0.9 - Styles.sideBarWidth)
                  }
                  height={
                    switchMerchant
                      ? Dimensions.get('screen').height * 0.9
                      : Dimensions.get('screen').height * 0.5
                  }
                  dataFormat={todaySalesChart.dataFormat}
                  dataSource={todaySalesChart.dataSource}
                  libraryPath={FS_LIBRARY_PATH}
                  events={eventsChart}
                /> */}
                <FusionCharts
                  {...{
                    width: "100%",
                    height: windowHeight * 0.5,
                    type: CHART_DATA[CHART_TYPE.DASHBOARD_TODAY_SALES].type, //column2d
                    dataFormat: todaySalesChart.dataFormat,  // json
                    dataSource: todaySalesChart.dataSource,
                  }}
                />
              </View>
            </View>

            <View
              style={{
                width: windowWidth * 0.877,
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                alignSelf: "center",
                //backgroundColor: 'blue',
                marginLeft: 75,
                //paddingTop: 45,
                top: 20,
              }}
            >
              <TouchableOpacity
                style={[
                  {
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: "#4E9F7D",
                    borderRadius: 5,
                    //width: 160,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,

                    opacity: !showDetails ? 0 : 100,
                  },
                ]}
                onPress={() => {
                  setShowDetails(false);
                  setCurrentPage(pageReturn);
                  console.log("Returning to page");
                  console.log(pageReturn);
                  setPageCount(
                    Math.ceil(dailySalesDetailsList.length / perPage)
                  );
                  setCurrReportSummarySort("");
                  setCurrReportDetailsSort("");
                }}
                disabled={!showDetails}
              >
                <View
                  style={{
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                  }}
                >
                  <AntDesign
                    name="arrowleft"
                    size={switchMerchant ? 10 : 20}
                    color={Colors.whiteColor}
                    style={{
                      top: switchMerchant ? 0 : 0.5,
                    }}
                  />
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      marginLeft: 5,
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: "NunitoSans-Bold",
                    }}
                  >
                    Summary
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

            {/* Daily Sales Report */}
            <View style={{ width: "100%", marginTop: 10 }}>
              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  borderRadius: 10,
                  width: windowWidth * 0.8575,
                  minHeight: Dimensions.get('screen').height * 0.01,
                  marginTop: 30,
                  marginHorizontal: 30,
                  marginBottom: 30,
                  borderRadius: 5,
                  shadowOpacity: 0,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    zIndex: 10000,
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      padding: 10,
                      alignItems: "center",
                      //width: '100%',
                      // height: '100%',
                      justifyContent: "space-between",
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Magnify
                        width={switchMerchant ? 10 : 20}
                        height={switchMerchant ? 10 : 20}
                      />
                      <Text
                        style={{
                          fontFamily: "NunitoSans-Bold",
                          color: Colors.primaryColor,
                          fontSize: switchMerchant ? 9 : 18,
                          marginHorizontal: 10,
                          alignSelf: "flex-start",
                        }}
                      >
                        Sales Details
                      </Text>
                    </View>
                    <View
                      style={{
                        marginRight: switchMerchant ? 5 : 10,
                        marginBottom: 1.5,
                        paddingHorizontal: 15,
                        flexDirection: "row",
                        alignItems: "center",
                        borderRadius: 10,
                        paddingVertical: 10,
                        justifyContent: "center",
                        backgroundColor: Colors.whiteColor,
                        shadowOpacity: 0,
                        shadowColor: "#000",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                      }}
                    >
                      <View
                        style={{ alignSelf: "center", marginRight: 5 }}
                        onPress={() => {
                          setState({
                            pickerMode: "date",
                            showDateTimePicker: true,
                          });
                        }}
                      >
                        {/* <EvilIcons name="calendar" size={25} color={Colors.primaryColor} /> */}
                        <GCalendar
                          width={switchMerchant ? 10 : 20}
                          height={switchMerchant ? 10 : 20}
                        />
                      </View>

                      <DatePicker
                        selected={rev_date.toDate()}
                        onChange={(date) => {
                          setRev_date(moment(date).startOf('day'));

                          setSalesLineChartPeriod(CHART_PERIOD.NONE);
                          setSalesBarChartPeriod(CHART_PERIOD.NONE);
                        }}
                        maxDate={moment(rev_date1).toDate()}
                      />

                      <Text
                        style={{
                          fontFamily: "NunitoSans-Regular",
                          paddingHorizontal: 5,
                          marginLeft: 5,
                        }}
                      >
                        -
                      </Text>

                      <DatePicker
                        selected={rev_date1.toDate()}
                        onChange={(date) => {
                          setRev_date1(moment(date).endOf('day'));

                          setSalesLineChartPeriod(CHART_PERIOD.NONE);
                          setSalesBarChartPeriod(CHART_PERIOD.NONE);
                        }}
                        style={{ width: 50 }}
                        minDate={moment(rev_date).toDate()}
                      />
                    </View>
                  </View>

                  <View
                    style={[
                      { flexDirection: "row", zIndex: -1 },
                      {
                        //marginLeft: Platform.OS === 'ios' ? '3.5%' : Dimensions.get('screen').width * 0.03,
                        paddingRight: Platform.OS == "android" ? 20 : 0,
                        //right: switchMerchant ? '3.5%' : 0,
                        // borderWidth: 2
                      },
                    ]}
                  >
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.TODAY
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.TODAY
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.TODAY);
                        setSalesBarChartPeriod(CHART_PERIOD.TODAY);
                        setRev_date(moment().startOf("day").startOf('day'));
                        setRev_date1(moment().endOf("day").endOf('day'));
                      }}
                    >
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod == CHART_PERIOD.TODAY
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod == CHART_PERIOD.TODAY
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }
                      >
                        TODAY
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.THIS_WEEK);
                        setSalesBarChartPeriod(CHART_PERIOD.THIS_WEEK);
                        setRev_date(moment(Date.now()).subtract(7, "days").startOf('day'));
                        setRev_date1(moment(Date.now()).endOf('day'));
                      }}
                    >
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod == CHART_PERIOD.THIS_WEEK
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }
                      >
                        1W
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.THIS_MONTH
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.THIS_MONTH);
                        setSalesBarChartPeriod(CHART_PERIOD.THIS_MONTH);
                        setRev_date(moment().startOf("month").startOf('day'));
                        setRev_date1(moment().endOf("month").endOf('day'));
                      }}
                    >
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod ==
                                  CHART_PERIOD.THIS_MONTH
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod ==
                                  CHART_PERIOD.THIS_MONTH
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }
                      >
                        1M
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.THREE_MONTHS
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.THREE_MONTHS);
                        setSalesBarChartPeriod(CHART_PERIOD.THREE_MONTHS);
                        setRev_date(moment(Date.now()).subtract(3, "months").startOf('day'));
                        setRev_date1(moment(Date.now()).endOf('day'));
                      }}
                    >
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod ==
                                  CHART_PERIOD.THREE_MONTHS
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod ==
                                  CHART_PERIOD.THREE_MONTHS
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }
                      >
                        3M
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.SIX_MONTHS
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.SIX_MONTHS);
                        setSalesBarChartPeriod(CHART_PERIOD.SIX_MONTHS);
                        setRev_date(moment(Date.now()).subtract(6, "month").startOf('day'));
                        setRev_date1(moment(Date.now()).endOf('day'));
                      }}
                    >
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod ==
                                  CHART_PERIOD.SIX_MONTHS
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod ==
                                  CHART_PERIOD.SIX_MONTHS
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }
                      >
                        6M
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.THIS_YEAR);
                        setSalesBarChartPeriod(CHART_PERIOD.THIS_YEAR);
                        setRev_date(moment().startOf("year").startOf('day'));
                        setRev_date1(moment().endOf("year").endOf('day'));
                      }}
                    >
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod == CHART_PERIOD.THIS_YEAR
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }
                      >
                        1Y
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        padding: 10,
                        borderBottomWidth:
                          salesLineChartPeriod == CHART_PERIOD.YTD
                            ? 2
                            : StyleSheet.hairlineWidth,
                        borderBottomColor:
                          salesLineChartPeriod == CHART_PERIOD.YTD
                            ? Colors.primaryColor
                            : Colors.lightPrimary,
                      }}
                      onPress={() => {
                        setSalesLineChartPeriod(CHART_PERIOD.YTD);
                        setSalesBarChartPeriod(CHART_PERIOD.YTD);
                        setRev_date(moment(Date.now()).subtract(12, "months").startOf('day'));
                        setRev_date1(moment(Date.now()).endOf('day'));
                      }}
                    >
                      <Text
                        style={
                          switchMerchant
                            ? {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod == CHART_PERIOD.YTD
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                              fontSize: 10,
                            }
                            : {
                              fontFamily: "NunitoSans-Bold",
                              color:
                                salesLineChartPeriod == CHART_PERIOD.YTD
                                  ? Colors.blackColor
                                  : Colors.descriptionColor,
                            }
                        }
                      >
                        YTD
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
                {!showDetails ? (
                  <View style={{ marginTop: 20, flexDirection: "row" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "5%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <Text
                          numberOfLines={2}
                          style={{
                            fontSize: switchMerchant ? 10 : 13,
                            fontFamily: "NunitoSans-Bold",
                          }}
                        >
                          {"No.\n"}
                        </Text>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "12%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Date\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "9%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Sales\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "9%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Order\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            Qty
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_TRANSACTION_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Disc\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_DISCOUNT_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.DISCOUNT_DESC
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Disc\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            %
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.DISCOUNT_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "8%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TAX_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TAX_DESC
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TAX_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Tax\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TAX_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TAX_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "10%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_DESC
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Service\nCharge"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.SERVICE_CHARGE_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    {/* <View style={{ flexDirection: 'row', width: '5%', borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>GP</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>%</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.GP_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.GP_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportSummarySort(REPORT_SORT_FIELD_TYPE.GP_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportSummarySort === REPORT_SORT_FIELD_TYPE.GP_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                    <View
                      style={{
                        flexDirection: "row",
                        width: "10%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_DESC
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                              textAlign: "left",
                            }}
                          >
                            {"Sales\nReturn"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.TOTAL_SALES_RETURN_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "10%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.NET_SALES_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.NET_SALES_DESC
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.NET_SALES_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Net\nSales"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.NET_SALES_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.NET_SALES_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "11%",
                        borderRightWidth: 0,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_ASC
                            ) {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_DESC
                              );
                            } else {
                              setCurrReportSummarySort(
                                REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Avg\nNet Sales"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportSummarySort ===
                              REPORT_SORT_FIELD_TYPE.AVERAGE_NET_SALES_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                        <Text
                          style={{
                            fontSize: 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                  </View>
                ) : (
                  <View style={{ marginTop: 20, flexDirection: "row" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "6%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <View style={{ flexDirection: "row" }}>
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"No.\n"}
                          </Text>
                        </View>
                        <Text
                          style={{
                            fontSize: switchMerchant ? 8 : 10,
                            color: Colors.descriptionColor,
                          }}
                        ></Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "16%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC
                              );
                            }
                          }}
                        >
                          <View style={{ flexDirection: "row" }}>
                            <Text
                              numberOfLines={2}
                              style={{
                                fontSize: switchMerchant ? 10 : 13,
                                fontFamily: "NunitoSans-Bold",
                              }}
                            >
                              {"Date/Time\n"}
                            </Text>
                          </View>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          ></Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DATE_TIME_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "11%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Sales\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                      </View>
                    </View>
                    {/* <View style={{ flexDirection: 'row', flex: 2.7, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Transaction Time</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View> */}
                    <View
                      style={{
                        flexDirection: "row",
                        width: "10%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Disc\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TOTAL_DISCOUNT_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "10%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Disc\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            %
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_DISCOUNT_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "10%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Tax\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            %
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_TAX_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "10%",
                        borderRightWidth: 1,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_DESC
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Tax\n"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>

                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SERVICE_CHARGE_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                      </View>
                    </View>
                    {/* <View style={{ flexDirection: 'row', flex: 1, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>GP</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}>%</Text>
                                                </View>
                                                <View style={{ marginLeft: '3%' }}>
                                                    <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC)}>
                                                        <Entypo name='triangle-up' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>

                                                    <TouchableOpacity onPress={() => setCurrReportDetailsSort(REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_DESC)}>
                                                        <Entypo name='triangle-down' size={14} color={currReportDetailsSort === REPORT_SORT_FIELD_TYPE.USER_ORDER_GP_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                                                    </TouchableOpacity>
                                                </View>
                                            </View> */}
                    <View
                      style={{
                        flexDirection: "row",
                        width: "13%",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_DESC
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Sales\nReturn"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_SALES_RETURN_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        width: "14%",
                        borderRightWidth: 0,
                        borderRightColor: "lightgrey",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        borderLeftWidth: 1,
                        borderLeftColor: "lightgrey",
                        paddingLeft: 10,
                      }}
                    >
                      <View style={{ flexDirection: "column" }}>
                        <TouchableOpacity
                          onPress={() => {
                            if (
                              currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC
                            ) {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC
                              );
                            } else {
                              setCurrReportDetailsSort(
                                REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC
                              );
                            }
                          }}
                        >
                          <Text
                            numberOfLines={2}
                            style={{
                              fontSize: switchMerchant ? 10 : 13,
                              fontFamily: "NunitoSans-Bold",
                            }}
                          >
                            {"Net\nSales"}
                          </Text>
                          <Text
                            style={{
                              fontSize: switchMerchant ? 8 : 10,
                              color: Colors.descriptionColor,
                            }}
                          >
                            RM
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: "3%" }}>
                        <Entypo
                          name="triangle-up"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_ASC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>

                        <Entypo
                          name="triangle-down"
                          size={switchMerchant ? 7 : 14}
                          color={
                            currReportDetailsSort ===
                              REPORT_SORT_FIELD_TYPE.USER_ORDER_NET_SALES_DESC
                              ? Colors.secondaryColor
                              : Colors.descriptionColor
                          }
                        ></Entypo>
                      </View>
                    </View>
                    {/* <View style={{ flexDirection: 'row', flex: 2, borderRightWidth: 1, borderRightColor: 'lightgrey', alignItems: 'center', justifyContent: 'center' }}>
                                                <View style={{ flexDirection: 'column' }}>
                                                    <Text numberOfLines={2} style={{ fontSize: 13, fontFamily: 'NunitoSans-Bold' }}>Average Net</Text>
                                                    <Text style={{ fontSize: 10, color: Colors.descriptionColor }}></Text>
                                                </View>
                                            </View> */}
                  </View>
                )}

                {!showDetails ? (
                  <>
                    {dailySalesDetailsList.length > 0 ? (
                      <FlatList
                        ref={flatListRef}
                        //data and extraData using dummy data
                        data={sortReportDataList(
                          dailySalesDetailsList,
                          currReportSummarySort
                        ).slice(
                          (currentPage - 1) * perPage,
                          currentPage * perPage
                        )}
                        // extraData={renderItem}
                        renderItem={renderItem}
                        keyExtractor={(item, index) => String(index)}
                        style={{ marginTop: 10 }}
                        initialNumToRender={8}
                        nestedScrollEnabled={true}
                        contentContainerStyle={
                          {
                            //paddingHorizontal: 5,
                          }
                        }
                      />
                    ) : (
                      <View
                        style={{
                          height: Dimensions.get("screen").height * 0.4,
                        }}
                      >
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                ) : (
                  <>
                    {transactionTypeSalesDetails.length > 0 ? (
                      <FlatList
                        ref={flatListRef}
                        data={sortReportDataList(
                          transactionTypeSalesDetails,
                          currReportDetailsSort
                        ).slice(
                          (currentDetailsPage - 1) * perPage,
                          currentDetailsPage * perPage
                        )}
                        // extraData={transactionTypeSales}
                        renderItem={renderItemDetails}
                        keyExtractor={(item, index) => String(index)}
                        style={{ marginTop: 10 }}
                        nestedScrollEnabled={true}
                      />
                    ) : (
                      <View
                        style={{
                          height: Dimensions.get("screen").height * 0.4,
                        }}
                      >
                        <View
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                          }}
                        >
                          <Text style={{ color: Colors.descriptionColor }}>
                            - No Data Available -
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                )}
              </View>

              {!showDetails ? (
                <View
                  style={{
                    flexDirection: "row",
                    width: windowWidth * 0.877,
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "flex-end",
                    top: Platform.OS == "ios" ? 0 : 0,
                    borderRadius: 4,
                    //paddingHorizontal: 5,
                    //marginHorizontal: 30,
                    //backgroundColor: 'blue'
                  }}
                >
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                    }}
                  >
                    Items Showed
                  </Text>
                  <View
                    style={{
                      width: Platform.OS === "ios" ? 65 : "13%", //65,
                      height: switchMerchant ? 20 : 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: Platform.OS === "ios" ? 0 : 0,
                      //paddingLeft:switchMerchant ? '4.5%': Platform.OS === 'ios' ? '2%' : '4%',
                      // paddingTop: '-60%',
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      marginRight: "1%",
                    }}
                  >
                    {/* <RNPickerSelect
                      useNativeAndroidPickerStyle={false}
                      style={{
                        inputIOS: {
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: 'NunitoSans-Regular',
                          textAlign: 'center',
                        },
                        inputAndroid: {
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: 'NunitoSans-Regular',
                          justifyContent: 'center',
                          textAlign: 'center',
                          height: 40,
                          color: 'black',
                        },
                        inputAndroidContainer: { width: '100%' },
                        //backgroundColor: 'red',
                        height: 35,

                        chevronContainer: {
                          display: 'none',
                        },
                        chevronDown: {
                          display: 'none',
                        },
                        chevronUp: {
                          display: 'none',
                        },
                      }}
                      items={TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                        label: 'All',
                        value: !showDetails
                          ? dailySalesDetailsList.length
                          : transactionTypeSalesDetails.length,
                      })}
                      value={perPage}
                      onValueChange={(value, text) => {
                        setPerPage(value);
                        var currentPageTemp =
                          text.length > 0 ? parseInt(text) : 1;

                        setCurrentPage(
                          currentPageTemp > pageCount
                            ? pageCount
                            : currentPageTemp < 1
                              ? 1
                              : currentPageTemp,
                        );
                      }}
                    /> */}
                    <Picker
                      selectedValue={perPage}
                      style={{
                        inputIOS: {
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "center",
                        },
                        inputAndroid: {
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Regular",
                          justifyContent: "center",
                          textAlign: "center",
                          height: 40,
                          color: "black",
                        },
                        inputAndroidContainer: { width: "100%" },
                        //backgroundColor: 'red',
                        height: 35,
                        borderRadius: 5,

                        chevronContainer: {
                          display: "none",
                        },
                        chevronDown: {
                          display: "none",
                        },
                        chevronUp: {
                          display: "none",
                        },
                      }}
                      onValueChange={(value, text) => {
                        setPerPage(value);
                        var currentPageTemp =
                          text.length > 0 ? parseInt(text) : 1;

                        setCurrentPage(
                          currentPageTemp > pageCount
                            ? pageCount
                            : currentPageTemp < 1
                              ? 1
                              : currentPageTemp
                        );
                      }}
                    >
                      {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                        label: "All",
                        value: !showDetails
                          ? dailySalesDetailsList.length
                          : transactionTypeSalesDetails.length,
                      }).map((value, index) => {
                        return (
                          <Picker.Item
                            key={index}
                            label={value.label}
                            value={value.value}
                          />
                        );
                      })}
                    </Picker>
                  </View>

                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                    }}
                  >
                    Page
                  </Text>
                  <View
                    style={{
                      width: switchMerchant ? 65 : 70,
                      height: switchMerchant ? 20 : 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: 22,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                    }}
                  >
                    {console.log("currentPage")}
                    {console.log(currentPage)}

                    <TextInput
                      onChangeText={(text) => {
                        var currentPageTemp =
                          text.length > 0 ? parseInt(text) : 1;

                        setCurrentPage(
                          currentPageTemp > pageCount
                            ? pageCount
                            : currentPageTemp < 1
                              ? 1
                              : currentPageTemp
                        );
                      }}
                      placeholder={
                        pageCount !== 0 ? currentPage.toString() : "0"
                      }
                      placeholderTextColor={Platform.select({
                        ios: "#a9a9a9",
                      })}
                      style={{
                        color: "black",
                        // fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: "NunitoSans-Regular",
                        marginTop: Platform.OS === "ios" ? 0 : -15,
                        marginBottom: Platform.OS === "ios" ? 0 : -15,
                        textAlign: "center",
                        width: "100%",
                      }}
                      value={pageCount !== 0 ? currentPage.toString() : "0"}
                      defaultValue={
                        pageCount !== 0 ? currentPage.toString() : "0"
                      }
                      keyboardType={"numeric"}
                      onFocus={() => {
                        setPushPagingToTop(true);
                      }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                      marginLeft: "1%",
                    }}
                  >
                    of {pageCount}
                  </Text>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      prevPage();
                    }}
                  >
                    {/* <MaterialIcons
                      name="keyboard-arrow-left"
                      size={switchMerchant ? 20 : 25}
                      style={{ color: Colors.whiteColor }}
                    /> */}
                    <ArrowLeft
                      // width={
                      //   switchMerchant
                      //     ? Dimensions.get("screen").width * 0.02
                      //     : Dimensions.get("screen").width * iconSize
                      // }
                      // height={
                      //   switchMerchant
                      //     ? Dimensions.get("screen").width * 0.02
                      //     : Dimensions.get("screen").width * iconSize
                      // }
                      color={Colors.whiteColor}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      nextPage();
                    }}
                  >
                    <ArrowRight
                      // width={
                      //   switchMerchant
                      //     ? Dimensions.get("screen").width * 0.02
                      //     : Dimensions.get("screen").width * iconSize
                      // }
                      // height={
                      //   switchMerchant
                      //     ? Dimensions.get("screen").width * 0.02
                      //     : Dimensions.get("screen").width * iconSize
                      // }
                      color={Colors.whiteColor}
                    />
                  </TouchableOpacity>
                </View>
              ) : (
                <View
                  style={{
                    flexDirection: "row",
                    width: windowWidth * 0.877,
                    alignItems: "center",
                    alignSelf: "center",
                    justifyContent: "flex-end",
                    top: Platform.OS == "ios" ? 0 : 0,
                    borderRadius: 4,
                    //paddingHorizontal: 5,
                    //marginHorizontal: 30,
                    //backgroundColor: 'blue'
                  }}
                >
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                    }}
                  >
                    Items Showed
                  </Text>
                  <View
                    style={{
                      height: switchMerchant ? 20 : 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: Platform.OS === "ios" ? 0 : 0,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                      marginRight: "1%",
                    }}
                  >
                    {/* <RNPickerSelect
                      useNativeAndroidPickerStyle={false}
                      style={{
                        inputIOS: {
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: 'NunitoSans-Regular',
                          textAlign: 'center',
                        },
                        inputAndroid: {
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: 'NunitoSans-Regular',
                          justifyContent: 'center',
                          textAlign: 'center',
                          height: 40,
                          color: 'black',
                        },
                        inputAndroidContainer: { width: '100%' },
                        //backgroundColor: 'red',
                        height: 35,

                        chevronContainer: {
                          display: 'none',
                        },
                        chevronDown: {
                          display: 'none',
                        },
                        chevronUp: {
                          display: 'none',
                        },
                      }}
                      items={TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                        label: 'All',
                        value: !showDetails
                          ? dailySalesDetailsList.length
                          : transactionTypeSalesDetails.length,
                      })}
                      value={perPage}
                      onValueChange={(value) => {
                        setPerPage(value);
                      }}
                    /> */}
                    <Picker
                      selectedValue={perPage}
                      style={{
                        inputIOS: {
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Regular",
                          textAlign: "center",
                        },
                        inputAndroid: {
                          fontSize: switchMerchant ? 10 : 14,
                          fontFamily: "NunitoSans-Regular",
                          justifyContent: "center",
                          textAlign: "center",
                          height: 40,
                          color: "black",
                        },
                        inputAndroidContainer: { width: "100%" },
                        //backgroundColor: 'red',
                        height: 35,
                        borderRadius: 5,

                        chevronContainer: {
                          display: "none",
                        },
                        chevronDown: {
                          display: "none",
                        },
                        chevronUp: {
                          display: "none",
                        },
                      }}
                      onValueChange={(value) => {
                        setPerPage(value);
                      }}
                    >
                      {TABLE_PAGE_SIZE_DROPDOWN_LIST.concat({
                        label: "All",
                        value: !showDetails
                          ? dailySalesDetailsList.length
                          : transactionTypeSalesDetails.length,
                      }).map((value, index) => {
                        return (
                          <Picker.Item
                            key={index}
                            label={value.label}
                            value={value.value}
                          />
                        );
                      })}
                    </Picker>
                  </View>

                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                    }}
                  >
                    Page
                  </Text>
                  <View
                    style={{
                      width: switchMerchant ? 65 : 70,
                      height: switchMerchant ? 20 : 35,
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      justifyContent: "center",
                      paddingHorizontal: 22,
                      borderWidth: 1,
                      borderColor: "#E5E5E5",
                    }}
                  >
                    {console.log("currentDetailsPage")}
                    {console.log(currentDetailsPage)}

                    <TextInput
                      onChangeText={(text) => {
                        var currentPageTemp =
                          text.length > 0 ? parseInt(text) : 1;

                        setCurrentDetailsPage(
                          currentPageTemp > pageCount
                            ? pageCount
                            : currentPageTemp < 1
                              ? 1
                              : currentPageTemp
                        );
                      }}
                      placeholder={
                        pageCount !== 0 ? currentDetailsPage.toString() : "0"
                      }
                      placeholderTextColor={Platform.select({
                        ios: "#a9a9a9",
                      })}
                      style={{
                        color: "black",
                        // fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                        fontFamily: "NunitoSans-Regular",
                        marginTop: Platform.OS === "ios" ? 0 : -15,
                        marginBottom: Platform.OS === "ios" ? 0 : -15,
                        textAlign: "center",
                        width: "100%",
                      }}
                      value={
                        pageCount !== 0 ? currentDetailsPage.toString() : "0"
                      }
                      defaultValue={
                        pageCount !== 0 ? currentDetailsPage.toString() : "0"
                      }
                      keyboardType={"numeric"}
                      onFocus={() => {
                        setPushPagingToTop(true);
                      }}
                    />
                  </View>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 14,
                      fontFamily: "NunitoSans-Bold",
                      marginRight: "1%",
                      marginLeft: "1%",
                    }}
                  >
                    of {pageCount}
                  </Text>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      prevDetailsPage();
                    }}
                  >
                    <ArrowLeft
                      // width={
                      //   switchMerchant
                      //     ? Dimensions.get("screen").width * 0.02
                      //     : Dimensions.get("screen").width * iconSize
                      // }
                      // height={
                      //   switchMerchant
                      //     ? Dimensions.get("screen").width * 0.02
                      //     : Dimensions.get("screen").width * iconSize
                      // }
                      color={Colors.whiteColor}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{
                      width: switchMerchant ? 30 : 45,
                      height: switchMerchant ? 20 : 28,
                      backgroundColor: Colors.primaryColor,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onPress={() => {
                      nextDetailsPage();
                    }}
                  >
                    <ArrowRight
                      // width={
                      //   switchMerchant
                      //     ? Dimensions.get("screen").width * 0.02
                      //     : Dimensions.get("screen").width * iconSize
                      // }
                      // height={
                      //   switchMerchant
                      //     ? Dimensions.get("screen").width * 0.02
                      //     : Dimensions.get("screen").width * iconSize
                      // }
                      color={Colors.whiteColor}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      </View>

      {expandBarSelection ? (
        <View style={styles.ManageFilterBox}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: 10,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                Colors: "#27353C",
                fontFamily: "Nunitosans-bold",
              }}
            >
              Manage Filter (Bar Chart)
            </Text>
          </View>
          <View
            style={{
              borderWidth: 0.5,
              borderColor: "#D2D2D2",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 10,
              height: 35,
            }}
          >
            <DropDownPicker
              arrowColor={"#BDBDBD"}
              arrowSize={23}
              arrowStyle={{ fontWeight: "bold" }}
              style={{ width: 250, height: 35 }}
              items={
                CHART_FIELD_NAME_DROPDOWN_LIST[CHART_TYPE.DASHBOARD_TODAY_SALES]
              }
              // placeholder={'Field name'}
              // placeholderStyle={{ color: 'black' }}
              dropDownStyle={{
                width: 250,
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              globalTextStyle={{
                fontFamily: "NunitoSans-SemiBold",
                // fontSize: 12,
                color: Colors.fontDark,
                marginLeft: 5,
              }}
              onChangeItem={(item) => {
                // setState({ sort: selectedSort }),
                //sortingFunc(selectedSort);
                setSelectedChartFilterQueriesBarChart([
                  {
                    ...selectedChartFilterQueriesBarChart[0],
                    fieldNameKey: item.value,
                    fieldNameType: item.fieldType,
                  },
                ]);
              }}
              defaultValue={selectedChartFilterQueriesBarChart[0].fieldNameKey}
              listMode={"SCROLLVIEW"}
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            />
            <DropDownPicker
              arrowColor={"#BDBDBD"}
              arrowSize={23}
              arrowStyle={{ fontWeight: "bold" }}
              style={{ marginLeft: "5%", width: 150 }}
              dropDownStyle={{ marginLeft: "5%", width: 150 }}
              items={
                CHART_FIELD_COMPARE_DROPDOWN_LIST[
                CHART_TYPE.DASHBOARD_TODAY_SALES
                ]
              }
              // placeholder={'Field name'}
              // placeholderStyle={{ color: 'black' }}
              dropDownStyle={{
                width: 150,
                marginLeft: "5%",
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              globalTextStyle={{
                fontFamily: "NunitoSans-SemiBold",
                // fontSize: 12,
                color: Colors.fontDark,
                marginLeft: 5,
              }}
              onChangeItem={(item) => {
                // setState({ sort: selectedSort }),
                //sortingFunc(selectedSort);
                setSelectedChartFilterQueriesBarChart([
                  {
                    ...selectedChartFilterQueriesBarChart[0],
                    fieldCompare: item.value,
                  },
                ]);
              }}
              defaultValue={selectedChartFilterQueriesBarChart[0].fieldCompare}
              listMode={"SCROLLVIEW"}
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 10,
              height: 35,
              zIndex: -1,
            }}
          >
            <TextInput
              style={{
                width: 410,
                borderWidth: 1,
                borderColor: "#D2D2D2",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
                fontFamily: "NunitoSans-Regular",
                borderRadius: 8,
                paddingVertical: 3,
                paddingLeft: 15,
              }}
              {...(selectedChartFilterQueriesBarChart[0].fieldNameType ===
                CHART_FIELD_TYPE.DATETIME && {
                onPressIn: () => {
                  setShowDateTimePickerFilterBarChart(true);
                },
              })}
              editable={
                selectedChartFilterQueriesBarChart[0].fieldNameType ===
                  CHART_FIELD_TYPE.DATETIME
                  ? false
                  : true
              }
              placeholder="   Field Value"
              placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
              placeholderStyle={{
                color: Colors.descriptionColor,
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "NunitoSans-Regular",
              }}
              defaultValue={
                selectedChartFilterQueriesBarChart[0].fieldNameType ===
                  CHART_FIELD_TYPE.DATETIME
                  ? moment(
                    selectedChartFilterQueriesBarChart[0].fieldDataValue
                  ).format("DD/MM/YYYY")
                  : selectedChartFilterQueriesBarChart[0].fieldDataValue
                    ? selectedChartFilterQueriesBarChart[0].fieldDataValue
                    : ""
              }
              onChangeText={(text) => {
                setSelectedChartFilterQueriesBarChart([
                  {
                    ...selectedChartFilterQueriesBarChart[0],
                    fieldDataValue: text,
                  },
                ]);
              }}
              keyboardType={
                selectedChartFilterQueriesBarChart[0].fieldNameType ===
                  CHART_FIELD_TYPE.STRING
                  ? "default"
                  : "numeric"
              }
            />
          </View>

          <View
            style={{
              borderWidth: 0.5,
              borderColor: "#D2D2D2",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: 10,
              zIndex: -1,
            }}
          >
            <TouchableOpacity
              style={{
                justifyContent: "center",
                width: 130,
              }}
              onPress={() => {
                setExpandBarSelection(false);

                setAppliedChartFilterQueriesBarChart([]);
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: "#BDBDBD",
                  borderRadius: 5,
                  height: 40,
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Text
                  style={{
                    color: "#BDBDBD",
                    fontSize: 15,
                    fontFamily: "Nunitosans-bold",
                    marginLeft: 7,
                  }}
                >
                  CANCEL
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                justifyContent: "center",
                width: 130,
                marginLeft: "3%",
              }}
              onPress={() => {
                setExpandBarSelection(false);

                setAppliedChartFilterQueriesBarChart(
                  selectedChartFilterQueriesBarChart
                );
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "row",
                  backgroundColor: "#4E9F7D",
                  borderRadius: 5,
                  height: 40,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 15,
                    fontFamily: "Nunitosans-bold",
                    marginLeft: 7,
                  }}
                >
                  APPLY
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      ) : null}

      {expandLineSelection ? (
        <View style={[styles.ManageFilterBox]}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              margin: 10,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                Colors: "#27353C",
                fontFamily: "Nunitosans-bold",
              }}
            >
              Manage Filter
            </Text>
          </View>
          <View
            style={{
              borderWidth: 0.5,
              borderColor: "#D2D2D2",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 10,
              height: 35,
            }}
          >
            <DropDownPicker
              arrowColor={"#BDBDBD"}
              arrowSize={23}
              arrowStyle={{ fontWeight: "bold" }}
              style={{ width: 250, height: 35 }}
              items={
                CHART_FIELD_NAME_DROPDOWN_LIST[
                CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                ]
              }
              // placeholder={'Field name'}
              // placeholderStyle={{ color: 'black' }}
              dropDownStyle={{
                width: 250,
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              globalTextStyle={{
                fontFamily: "NunitoSans-SemiBold",
                // fontSize: 12,
                color: Colors.fontDark,
                marginLeft: 5,
              }}
              onChangeItem={(item) => {
                // setState({ sort: selectedSort }),
                //sortingFunc(selectedSort);
                setSelectedChartFilterQueriesLineChart([
                  {
                    ...selectedChartFilterQueriesLineChart[0],
                    fieldNameKey: item.value,
                    fieldNameType: item.fieldType,
                  },
                ]);
              }}
              defaultValue={selectedChartFilterQueriesLineChart[0].fieldNameKey}
              listMode={"SCROLLVIEW"}
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            />
            <DropDownPicker
              arrowColor={"#BDBDBD"}
              arrowSize={23}
              arrowStyle={{ fontWeight: "bold" }}
              style={{ marginLeft: "5%", width: 150 }}
              dropDownStyle={{ marginLeft: "5%", width: 150 }}
              items={
                CHART_FIELD_COMPARE_DROPDOWN_LIST[
                CHART_TYPE.DASHBOARD_LINE_CHART_SALES
                ]
              }
              // placeholder={'Field name'}
              // placeholderStyle={{ color: 'black' }}
              dropDownStyle={{
                width: 150,
                marginLeft: "5%",
              }}
              itemStyle={{
                justifyContent: "flex-start",
              }}
              globalTextStyle={{
                fontFamily: "NunitoSans-SemiBold",
                // fontSize: 12,
                color: Colors.fontDark,
                marginLeft: 5,
              }}
              onChangeItem={(item) => {
                // setState({ sort: selectedSort }),
                //sortingFunc(selectedSort);
                setSelectedChartFilterQueriesLineChart([
                  {
                    ...selectedChartFilterQueriesLineChart[0],
                    fieldCompare: item.value,
                  },
                ]);
              }}
              defaultValue={selectedChartFilterQueriesLineChart[0].fieldCompare}
              listMode={"SCROLLVIEW"}
              scrollViewProps={{
                nestedScrollEnabled: true,
              }}
            />
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              margin: 10,
              height: 35,
              zIndex: -1,
            }}
          >
            <TextInput
              style={{
                width: 410,
                borderWidth: 1,
                borderColor: "#D2D2D2",
                alignItems: "center",
                justifyContent: "center",
                color: "black",
                fontFamily: "NunitoSans-Regular",
                borderRadius: 8,
                paddingVertical: 3,
                paddingLeft: 15,
              }}
              {...(selectedChartFilterQueriesLineChart[0].fieldNameType ===
                CHART_FIELD_TYPE.DATETIME && {
                onPressIn: () => {
                  setShowDateTimePickerFilterLineChart(true);
                },
              })}
              editable={
                selectedChartFilterQueriesLineChart[0].fieldNameType ===
                  CHART_FIELD_TYPE.DATETIME
                  ? false
                  : true
              }
              placeholder="   Field Value"
              placeholderTextColor={Platform.select({ ios: "#a9a9a9" })}
              placeholderStyle={{
                color: Colors.descriptionColor,
                justifyContent: "center",
                alignItems: "center",
                fontFamily: "NunitoSans-Regular",
              }}
              defaultValue={
                selectedChartFilterQueriesLineChart[0].fieldNameType ===
                  CHART_FIELD_TYPE.DATETIME
                  ? moment(
                    selectedChartFilterQueriesLineChart[0].fieldDataValue
                  ).format("DD/MM/YYYY")
                  : selectedChartFilterQueriesLineChart[0].fieldDataValue
                    ? selectedChartFilterQueriesLineChart[0].fieldDataValue
                    : ""
              }
              onChangeText={(text) => {
                setSelectedChartFilterQueriesLineChart([
                  {
                    ...selectedChartFilterQueriesLineChart[0],
                    fieldDataValue: text,
                  },
                ]);
              }}
              keyboardType={
                selectedChartFilterQueriesLineChart[0].fieldNameType ===
                  CHART_FIELD_TYPE.STRING
                  ? "default"
                  : "numeric"
              }
            />
          </View>

          <View
            style={{
              borderWidth: 0.5,
              borderColor: "#D2D2D2",
              width: "100%",
              marginTop: 5,
              marginBottom: 5,
            }}
          ></View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: 10,
              zIndex: -1,
            }}
          >
            <TouchableOpacity
              style={{
                justifyContent: "center",
                width: 130,
              }}
              onPress={() => {
                setExpandLineSelection(false);

                setAppliedChartFilterQueriesLineChart([]);
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  flexDirection: "row",
                  borderWidth: 1,
                  borderColor: "#BDBDBD",
                  borderRadius: 5,
                  alignItems: "center",
                  backgroundColor: "#FFFFFF",
                  width: 130,
                  height: 40,
                }}
              >
                <Text
                  style={{
                    color: "#BDBDBD",
                    fontSize: 15,
                    fontFamily: "Nunitosans-bold",
                    marginLeft: 7,
                  }}
                >
                  CANCEL
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                justifyContent: "center",
                width: 130,
                marginLeft: "3%",
              }}
              onPress={() => {
                setExpandLineSelection(false);

                setAppliedChartFilterQueriesLineChart(
                  selectedChartFilterQueriesLineChart
                );
              }}
            >
              <View
                style={{
                  justifyContent: "center",
                  width: "100%",
                  flexDirection: "row",
                  backgroundColor: "#4E9F7D",
                  borderRadius: 5,
                  height: 40,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 15,
                    fontFamily: "Nunitosans-bold",
                    marginLeft: 7,
                  }}
                >
                  APPLY
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: "row",
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  sidebar: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: "center",
  },
  confirmBox: {
    width: Dimensions.get("screen").width * 0.4,
    height: Dimensions.get("screen").height * 0.3,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
    justifyContent: "space-between",
  },
  textInput8: {
    fontFamily: "NunitoSans-Regular",
    width: 75,
    height: 50,
    flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },

  textInput9: {
    fontFamily: "NunitoSans-Regular",
    width: 350,
    height: 50,
    //flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },

  headerLeftStyle: {
    width: Dimensions.get("screen").width * 0.17,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  modalView: {
    height: Dimensions.get("screen").width * 0.3,
    width: Dimensions.get("screen").width * 0.4,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get("screen").width * 0.03,
    alignItems: "center",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    right: Dimensions.get("screen").width * 0.02,
    top: Dimensions.get("screen").width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: "center",
    top: "20%",
    position: "absolute",
  },
  modalBody: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalTitleText: {
    fontFamily: "NunitoSans-Bold",
    textAlign: "center",
    fontSize: 24,
  },
  modalDescText: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 18,
    color: Colors.fieldtTxtColor,
  },
  modalBodyText: {
    flex: 1,
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 25,
    width: "20%",
  },
  modalSaveButton: {
    width: 130,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
  ManageFilterBox: {
    //width: Dimensions.get('screen').width * 0.27,
    //height: Platform.OS === 'ios' ?Dimensions.get('screen').height * 0.23: Dimensions.get('screen').height * 0.24,
    //width: Platform.OS === 'ios' ? Dimensions.get('screen').width * 0.4 : Dimensions.get('screen').width * 0.33,
    height:
      Platform.OS === "ios" ? 180 : Dimensions.get("screen").height * 0.24,
    width: Platform.OS === "ios" ? 400 : Dimensions.get("screen").width * 0.33,
    borderWidth: 1,
    borderColor: "#E5E5E5",
    position: "absolute",
    marginTop: Platform.OS === "ios" ? "13%" : "13%",
    marginLeft: Platform.OS === "ios" ? "12%" : "12%",
    //left: Platform.OS === 'ios' ? '38%' : '0%',
    //top: Platform.OS === 'ios' ? '46%' : '0%',
    shadowColor: "#000",
    shadowOffset: {
      width: 1,
      height: 5,
    },
    shadowOpacity: 0.32,
    shadowRadius: 3.22,
    elevation: 10,
    zIndex: 1000,
    borderRadius: 10,
    //borderTopLeftRadius: 0,
    backgroundColor: Colors.whiteColor,
    //justifyContent: 'space-between'
  },
});

export default DashboardScreen;
