import React, { Component, useReducer, useState, useEffect } from 'react';
import {
    StyleSheet,
    ScrollView,
    Image,
    View,
    Text,
    Alert,
    TouchableOpacity,
    Modal,
    Dimensions,
    TextInput,
    FlatList,
    KeyboardAvoidingView,
    ActivityIndicator,
    Platform,
    useWindowDimensions,
} from 'react-native';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import { ReactComponent as GCalendar } from '../assets/svg/GCalendar.svg';
import { ReactComponent as Copy } from '../assets/svg/Copy.svg';
import { ReactComponent as Clock } from '../assets/svg/Clock.svg';
import SideBar from './SideBar';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Ionicons';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
import DropDownPicker from 'react-native-dropdown-picker';
import Feather from 'react-native-vector-icons/Feather';
import EvilIcons from 'react-native-vector-icons/EvilIcons';
import moment from 'moment';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../constant/datePicker.css";
import {
    MERCHANT_VOUCHER_CODE_FORMAT,
    MERCHANT_VOUCHER_CODE_FORMAT_UNIQUE,
    MERCHANT_VOUCHER_STATUS,
    MERCHANT_VOUCHER_STATUS_PARSED,
    MERCHANT_VOUCHER_TYPE,
    SEGMENT_TYPE,
} from '../constant/common';
import { CommonStore } from '../store/commonStore';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { OutletStore } from '../store/outletStore';
import { createIconSetFromFontello } from 'react-native-vector-icons';
import { CRM_SEGMENT_DROPDOWN_LIST, DATE_FORMAT_DROPDOWN_LIST, DATE_FORMAT_TYPE, PROMOTION_TYPE_VARIATION, PROMOTION_TYPE_VARIATION_DROPDOWN_LIST, } from '../constant/promotions';
import Entypo from 'react-native-vector-icons/Entypo';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import AntDesign from 'react-native-vector-icons/AntDesign';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Ionicon from 'react-native-vector-icons/Ionicons';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Select from 'react-select';
import TimeKeeper from "react-timekeeper";


const VoucherScreen = (props) => {
    const { navigation } = props;


    const {
        height: windowHeight,
        width: windowWidth,
    } = useWindowDimensions();
    const [createVoucher, setCreateVoucher] = useState(false);
    const [voucherList, setVoucherList] = useState(true);
    const [voucher, setVoucher] = useState([]);
    const [voucherLength, setVoucherLength] = useState(0);
    const [list1, setList1] = useState(true);
    const [searchList, setSearchList] = useState(false);
    const [search, setSearch] = useState('');
    const [showDateTimePicker, setShowDateTimePicker] = useState(false);
    const [pickDate, setPickDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showDateTimePicker1, setShowDateTimePicker1] = useState(false);
    const [pickTime, setPickTime] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState(moment(Date.now()).format("hh:mm A"));
    const [isLoadingPublish, setIsloadingPublish] = useState(false);

    const [clockTimePicker, setClockTimePicker] = useState(false);
    const [clockTimePicker1, setClockTimePicker1] = useState(false);


    const [validityDateFormatValue, setValidityDateFormatValue] = useState('');
    const [validityDateFormatType, setValidityDateFormatType] = useState(
        DATE_FORMAT_TYPE.DAY,
    );

    const [maxClaimValue, setMaxClaimValue] = useState('');
    const [maxClaimDateFormatValue, setMaxClaimDateFormatValue] = useState('');
    const [maxClaimDateFormatType, setMaxClaimDateFormatType] = useState(
        DATE_FORMAT_TYPE.DAY,
    );

    const [maxRedeemValue, setMaxRedeemValue] = useState('');
    const [maxRedeemDateFormatValue, setMaxRedeemDateFormatValue] = useState('');
    const [maxRedeemDateFormatType, setMaxRedeemDateFormatType] = useState(
        DATE_FORMAT_TYPE.DAY,
    );

    // const [segments, setSegments] = useState([]);
    const [segmentValue, setSegmentValue] = useState([
        CRM_SEGMENT_DROPDOWN_LIST[0].value,
    ]);
    const [switchMerchant, setSwitchMerchant] = useState(false);

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [quantity, setQuantity] = useState('');
    const [description, setDescription] = useState('');
    const [voucherCodeFormat, setVoucherCodeFormat] = useState(
        MERCHANT_VOUCHER_CODE_FORMAT.UNIQUE,
    );
    const [voucherType, setVoucherType] = useState(
        MERCHANT_VOUCHER_TYPE.CASH_VOUCHER,
    );

    const [voucherValue, setVoucherValue] = useState('');
    const [voucherPercentage, setVoucherPercentage] = useState('');
    const [voucherTypeMinSpend, setVoucherTypeMinSpend] = useState('');
    const [voucherTypeMaxSpend, setVoucherTypeMaxSpend] = useState('');
    const [maxVoucherToUse, setMaxVoucherToUse] = useState('1');
    const [showMaxClaim, setShowMaxClaim] = useState(false);

    const [combineVoucherUse, setCombineVoucherUse] = useState(false);
    const [combineUseWithVoucher, setCombineUseWithVoucher] = useState(false);
    const [combineUseWithPromotion, setCombineUseWithPromotion] = useState(false);

    const [voucherTerms, setVoucherTerms] = useState('');
    const [voucherCodeFormatUnique, setVoucherCodeFormatUnique] = useState(
        MERCHANT_VOUCHER_CODE_FORMAT_UNIQUE.ALPHA_NUMERICAL,
    );
    const [uniqueVoucherCodeLength, setUniqueVoucherCodeLength] = useState('7');
    const [uniqueVoucherCodePrefix, setUniqueVoucherCodePrefix] = useState('');
    const [genericVoucherCodeFixed, setGenericVoucherCodeFixed] = useState('');
    const [randomVoucherCode, setRandomVoucherCode] = useState('');

    const [myTextInput, setMyTextInput] = useState(React.createRef());
    const [combineVoucher, setCombineVoucher] = useState(false);
    const [promotionVoucher, setPromotionVoucher] = useState(false);

    const [dropdownSegments, setDropdownSegments] = useState([]);

    const [deliveryFreeFlag, setDeliveryFreeFlag] = useState(false);
    const [deliveryFreeAboveAmount, setDeliveryFreeAboveAmount] = useState('0');
    const [deliveryDiscountAmount, setDeliveryDiscountAmount] = useState('');
    const [deliveryDiscountAboveAmount, setDeliveryDiscountAboveAmount] = useState('');

    const [bundleBuyNumber, setBundleBuyNumber] = useState('');
    const [bundleBuyPrice, setBundleBuyPrice] = useState('');

    const [selectedVariation, setSelectedVariation] = useState(
        PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
    );
    const [variationItemsDropdownList, setVariationItemsDropdownList] = useState(
        [],
    );
    const [selectedVariationItems, setSelectedVariationItems] = useState([]);
    const [selectedVariationItemsSku, setSelectedVariationItemsSku] = useState(
        [],
    ); // for multi-outlets

    const [selectedVariationB1F1, setSelectedVariationB1F1] = useState(
        PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
    );
    const [selectedVariationItemsB1F1, setSelectedVariationItemsB1F1] = useState(
        [],
    );
    const [variationItemsB1F1DropdownList, setVariationItemsB1F1DropdownList] =
        useState([]);
    const [selectedVariationItemsSkuB1F1, setSelectedVariationItemsSkuB1F1] =
        useState([]); // for multi-outlets

    const [outletItems, setOutletItems] = useState([]);
    // const [outletCategories, setOutletCategories] = useState([]);

    const outletItemsUnsorted = OutletStore.useState((s) => s.outletItems);
    const outletCategoriesUnsorted = OutletStore.useState(
        (s) => s.outletCategories,
    );

    const allOutletsCategoriesUnique = OutletStore.useState(
        (s) => s.allOutletsCategoriesUnique,
    );

    const segments = CommonStore.useState((s) => s.segments);
    const merchantVouchers = CommonStore.useState((s) => s.merchantVouchers);

    const isLoading = CommonStore.useState((s) => s.isLoading);

    const [isOpenVoucherDropdown, setIsOpenVoucherDropdown] = useState(false);
    const [isOpenPromotionDropdown, setIsOpenPromotionDropdown] = useState(false);
    const [isOpenOutletDropdown, setIsOpenOutletDropdown] = useState(false);

    const userName = UserStore.useState((s) => s.name);
    const merchantName = MerchantStore.useState((s) => s.name);

    //////////////////////////////////////////////////////////////

    const currOutletId = MerchantStore.useState((s) => s.currOutletId);
    const allOutlets = MerchantStore.useState((s) => s.allOutlets);

    const crmSegments = OutletStore.useState((s) => s.crmSegments);

    const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
    const [selectedTargetOutletId, setSelectedTargetOutletId] = useState('');
    const [outletCategoryDropdownList, setOutletCategoryDropdownList] = useState(
        [],
    );
    const [outletDropdownList, setOutletDropdownList] = useState([]);
    const [selectedOutletList, setSelectedOutletList] = useState([]); // multi-outlets
    const [outletCategories, setOutletCategories] = useState([]);
    const [place, setPlace] = useState('');
    const [selectedOutletId, setSelectedOutletId] = useState('');

    const [expandThreeDots, setExpandThreeDots] = useState({}); //Use to expand the view when three dots are tapped
    const [threeDotsTapped, setThreeDotsTapped] = useState(false); //when the three dots are tapped will become (true)

    const [selectAll, setSelectAll] = useState(false);

    const outletSelectDropdownView = CommonStore.useState(
        (s) => s.outletSelectDropdownView,
    );

    const selectedVoucherEdit = CommonStore.useState(
        (s) => s.selectedVoucherEdit,
    );

    ///////////////////////////////////Testing Outlet///////////////////////////////////////////

    useEffect(() => {
        var outletItemsTemp = [...outletItemsUnsorted];

        outletItemsTemp.sort((a, b) => a.name.localeCompare(b.name));

        setOutletItems(outletItemsTemp);
    }, [outletItemsUnsorted]);

    useEffect(() => {
        var outletCategoriesTemp = [...outletCategoriesUnsorted];

        outletCategoriesTemp.sort((a, b) => a.name.localeCompare(b.name));

        setOutletCategories(outletCategoriesTemp);
    }, [outletCategoriesUnsorted]);

    useEffect(() => {
        var selectedVariationItemsSkuTemp = [];

        if (selectedVariation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
            for (var i = 0; i < outletItems.length; i++) {
                if (selectedVariationItems.includes(outletItems[i].uniqueId)) {
                    selectedVariationItemsSkuTemp.push(outletItems[i].sku);
                }
            }
        } else if (
            selectedVariation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
        ) {
            for (var i = 0; i < allOutletsCategoriesUnique.length; i++) {
                if (
                    selectedVariationItems.includes(
                        allOutletsCategoriesUnique[i].uniqueId,
                    )
                ) {
                    selectedVariationItemsSkuTemp.push(
                        allOutletsCategoriesUnique[i].name,
                    );
                }
            }
        }

        setSelectedVariationItemsSku(selectedVariationItemsSkuTemp);
    }, [
        selectedVariation,

        selectedVariationItems,
        outletItems,

        allOutletsCategoriesUnique,
    ]);

    useEffect(() => {
        var selectedVariationItemsSkuB1F1Temp = [];

        if (selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS) {
            for (var i = 0; i < outletItems.length; i++) {
                if (selectedVariationItemsB1F1.includes(outletItems[i].uniqueId)) {
                    selectedVariationItemsSkuB1F1Temp.push(outletItems[i].sku);
                }
            }
        } else if (
            selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
        ) {
            for (var i = 0; i < allOutletsCategoriesUnique.length; i++) {
                if (
                    selectedVariationItemsB1F1.includes(
                        allOutletsCategoriesUnique[i].uniqueId,
                    )
                ) {
                    selectedVariationItemsSkuB1F1Temp.push(
                        allOutletsCategoriesUnique[i].name,
                    );
                }
            }
        }

        setSelectedVariationItemsSkuB1F1(selectedVariationItemsSkuB1F1Temp);
    }, [
        selectedVariationB1F1,

        selectedVariationItemsB1F1,
        outletItems,

        allOutletsCategoriesUnique,
    ]);

    ////////////////////////////////////////////////////////

    useEffect(() => {
        if (selectedVariation === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY) {
            setVariationItemsDropdownList(
                allOutletsCategoriesUnique.map((item) => ({
                    label: item.name,
                    value: item.uniqueId,
                })),
            );

            if (allOutletsCategoriesUnique.length > 0 && !selectedVoucherEdit) {
                // setSelectedVariationItems([allOutletsCategoriesUnique[0].uniqueId]);
            } else {
                // if (selectedVariationItems.length > 0 && variationItemsDropdownList.find(item => item.value === selectedVariationItems[0]) === null) {
                //   console.log('clear items');
                //   setSelectedVariationItems([]);
                // }
            }
        } else if (
            selectedVariation === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
        ) {
            setVariationItemsDropdownList(
                outletItems.map((item) => ({ label: item.name, value: item.uniqueId })),
            );

            if (outletItems.length > 0 && !selectedVoucherEdit) {
                // setSelectedVariationItems([outletItems[0].uniqueId]);
            } else {
                // if (selectedVariationItems.length > 0 && variationItemsDropdownList.find(item => item.value === selectedVariationItems[0]) === null) {
                //   setSelectedVariationItems([]);
                // }
            }
        }

        if (
            selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY
        ) {
            setVariationItemsB1F1DropdownList(
                allOutletsCategoriesUnique.map((item) => ({
                    label: item.name,
                    value: item.uniqueId,
                })),
            );

            if (allOutletsCategoriesUnique.length > 0 && !selectedVoucherEdit) {
                // setSelectedVariationItemsB1F1([allOutletsCategoriesUnique[0].uniqueId]);
            } else {
                // if (selectedVariationItemsB1F1.length > 0 && variationItemsB1F1DropdownList.find(item => item.value === selectedVariationItemsB1F1[0]) === null) {
                //   setSelectedVariationItemsB1F1([]);
                // }
            }
        } else if (
            selectedVariationB1F1 === PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS
        ) {
            setVariationItemsB1F1DropdownList(
                outletItems.map((item) => ({ label: item.name, value: item.uniqueId })),
            );

            if (outletItems.length > 0 && !selectedVoucherEdit) {
                // setSelectedVariationItemsB1F1([outletItems[0].uniqueId]);
            } else {
                // if (selectedVariationItemsB1F1.length > 0 && variationItemsB1F1DropdownList.find(item => item.value === selectedVariationItemsB1F1[0]) === null) {
                //   setSelectedVariationItemsB1F1([]);
                // }
            }
        }
    }, [
        outletItems,
        allOutletsCategoriesUnique,

        selectedVariation,
        selectedVariationB1F1,

        selectedVariationItems,
        selectedVariationItemsB1F1,

        selectedVoucherEdit,
    ]);

    ////////////////////////////////////////////////////////

    // to solve product not show

    useEffect(() => {
        if (
            selectedVariationItemsB1F1.length > 0 &&
            variationItemsB1F1DropdownList.find(
                (item) => item.value === selectedVariationItemsB1F1[0],
            ) === undefined
        ) {
            // setSelectedVariationItemsB1F1(variationItemsB1F1DropdownList.length > 0 ? [variationItemsB1F1DropdownList[0].uniqueId] : []);
            setSelectedVariationItemsB1F1([]);
        }

        console.log('condition');
        console.log(selectedVariationItems.length > 0);
        console.log(
            variationItemsDropdownList.find(
                (item) => item.value === selectedVariationItems[0],
            ),
        );

        if (
            selectedVariationItems.length > 0 &&
            variationItemsDropdownList.find(
                (item) => item.value === selectedVariationItems[0],
            ) === undefined
        ) {
            // setSelectedVariationItems(variationItemsDropdownList.length > 0 ? [variationItemsDropdownList[0].uniqueId] : []);
            setSelectedVariationItems([]);
        }
    }, [variationItemsDropdownList, variationItemsB1F1DropdownList]);

    useEffect(() => {
        setOutletDropdownList(
            allOutlets
                // .filter(o => o.uniqueId === currOutletId)
                .map((item) => ({
                    label: item.name,
                    value: item.uniqueId,
                })),
        );

        if (selectedVoucherEdit === null) {
            if (selectedOutletId === '' && allOutlets.length > 0) {
                setSelectedOutletId(allOutlets[0].uniqueId);

                setSelectedOutletList([allOutlets[0].uniqueId]);
            }

            if (place === '' && allOutlets.length > 0) {
                setPlace(allOutlets[0].uniqueId);
            }
        }
    }, [allOutlets, selectedVoucherEdit, currOutletId]);

    //   useEffect(() => {
    //     setOutletCategoryDropdownList(outletCategories.map(item => ({ label: item.name, value: item.uniqueId })));

    //     console.log('outlet categories dropdown');
    //     console.log(outletCategories.map(item => ({ label: item.name, value: item.uniqueId })));

    //     if (selectedOutletCategoryId === '' && outletCategories.length > 0) {
    //         setSelectedOutletCategoryId(outletCategories[0].uniqueId);
    //     }
    // }, [outletCategories]);

    useEffect(() => {
        setTargetOutletDropdownList(
            allOutlets.map((outlet) => ({
                label: outlet.name,
                value: outlet.uniqueId,
            })),
        );

        console.log('targetOutletDropdownList');
        console.log(targetOutletDropdownList);

        if (allOutlets.length > 0) {
            setSelectedTargetOutletId(currOutletId);
        }
    }, [allOutlets, currOutletId]);

    /////////////////////////////////////Testing Outlet////////////////////////////////////////////////

    useEffect(() => {
        if (
            voucherCodeFormatUnique ===
            MERCHANT_VOUCHER_CODE_FORMAT_UNIQUE.ALPHA_NUMERICAL
        ) {
            var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
            var result = '';
            // if (uniqueVoucherCodeLength < 6 || uniqueVoucherCodeLength > 10) {
            //   Alert.alert(
            //     'Warning',
            //     'Voucher code length limit must stay in 6 ~ 10.',
            //     // {
            //     //   Text: 'OK',
            //     //   onPress: () => { },
            //     // },
            //   );

            //   setUniqueVoucherCodeLength(6);
            // }
            for (var i = 0; i < uniqueVoucherCodeLength; i++) {
                result += randomChars.charAt(
                    Math.floor(Math.random() * randomChars.length),
                );
            }
            setRandomVoucherCode(uniqueVoucherCodePrefix + result);
        }
        if (voucherCodeFormatUnique === MERCHANT_VOUCHER_CODE_FORMAT_UNIQUE.ALPHA) {
            var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            var result = '';
            for (var i = 0; i < uniqueVoucherCodeLength; i++) {
                result += randomChars.charAt(
                    Math.floor(Math.random() * randomChars.length),
                );
            }
            setRandomVoucherCode(uniqueVoucherCodePrefix + result);
        }
        if (
            voucherCodeFormatUnique === MERCHANT_VOUCHER_CODE_FORMAT_UNIQUE.NUMERIC
        ) {
            var randomChars = '0123456789';
            var result = '';
            for (var i = 0; i < uniqueVoucherCodeLength; i++) {
                result += randomChars.charAt(
                    Math.floor(Math.random() * randomChars.length),
                );
            }
            setRandomVoucherCode(uniqueVoucherCodePrefix + result);
        }
    }, [
        uniqueVoucherCodeLength,
        voucherCodeFormatUnique,
        uniqueVoucherCodePrefix,
    ]);

    //////////////////////////////////////////////////////////////



    useEffect(() => {
        console.log('================================');
        console.log('selectedVoucherEdit');
        console.log(selectedVoucherEdit);

        if (selectedVoucherEdit) {
            // insert info

            setTitle(selectedVoucherEdit.title);
            //setQuantity(selectedVoucherEdit.quantity);
            setQuantity(+parseFloat(selectedVoucherEdit.quantity).toFixed(2));
            setDescription(selectedVoucherEdit.description);
            setStartDate(selectedVoucherEdit.startDate);
            setEndDate(selectedVoucherEdit.endDate);
            setStartTime(selectedVoucherEdit.startTime);
            setEndTime(selectedVoucherEdit.endTime);

            setVoucherCodeFormat(selectedVoucherEdit.format);
            setVoucherCodeFormatUnique(selectedVoucherEdit.formatUniqueType);
            setUniqueVoucherCodeLength(
                selectedVoucherEdit.formatUniqueLength.toString(),
            );
            setUniqueVoucherCodePrefix(selectedVoucherEdit.formatUniquePrefix);

            setGenericVoucherCodeFixed(selectedVoucherEdit.formatGenericCode);

            setVoucherType(selectedVoucherEdit.type);
            setVoucherValue(selectedVoucherEdit.value.toString());
            setVoucherTypeMinSpend(selectedVoucherEdit.minSpend.toString());
            setVoucherTypeMaxSpend(
                selectedVoucherEdit.maxSpend
                    ? selectedVoucherEdit.maxSpend.toString()
                    : '0',
            );

            setVoucherTerms(selectedVoucherEdit.terms);

            setPlace(selectedVoucherEdit.outletId);
            setSelectedOutletList(selectedVoucherEdit.outletIdList);

            setMaxVoucherToUse(
                selectedVoucherEdit.maxClaim
                    ? selectedVoucherEdit.maxClaim.toString()
                    : '1',
            );

            if (selectedVoucherEdit.maxClaim >= 1) {
                setShowMaxClaim(true);
            }

            if (
                selectedVoucherEdit.segments &&
                selectedVoucherEdit.segments.length > 0
            ) {
                setSegmentValue([selectedVoucherEdit.segments[0]]); // assume only got one
            }

            setValidityDateFormatValue(
                selectedVoucherEdit.validityDateFormatValue
                    ? selectedVoucherEdit.validityDateFormatValue.toFixed(0)
                    : '0.00',
            );
            setValidityDateFormatType(
                selectedVoucherEdit.validityDateFormatType
                    ? selectedVoucherEdit.validityDateFormatType
                    : DATE_FORMAT_DROPDOWN_LIST[0].value,
            );

            setMaxClaimValue(
                selectedVoucherEdit.maxClaimValue
                    ? selectedVoucherEdit.maxClaimValue.toFixed(0)
                    : '0.00',
            );
            setMaxClaimDateFormatValue(
                selectedVoucherEdit.maxClaimDateFormatValue
                    ? selectedVoucherEdit.maxClaimDateFormatValue.toFixed(0)
                    : '0.00',
            );
            setMaxClaimDateFormatType(
                selectedVoucherEdit.maxClaimDateFormatType
                    ? selectedVoucherEdit.maxClaimDateFormatType
                    : DATE_FORMAT_DROPDOWN_LIST[0].value,
            );

            setMaxRedeemValue(
                selectedVoucherEdit.maxRedeemValue
                    ? selectedVoucherEdit.maxRedeemValue.toFixed(0)
                    : '0.00',
            );
            setMaxRedeemDateFormatValue(
                selectedVoucherEdit.maxRedeemDateFormatValue
                    ? selectedVoucherEdit.maxRedeemDateFormatValue.toFixed(0)
                    : '0.00',
            );
            setMaxRedeemDateFormatType(
                selectedVoucherEdit.maxRedeemDateFormatType
                    ? selectedVoucherEdit.maxRedeemDateFormatType
                    : DATE_FORMAT_DROPDOWN_LIST[0].value,
            );

            setCombineVoucher(
                selectedVoucherEdit.combineVoucherUse
                    ? selectedVoucherEdit.combineVoucherUse
                    : false,
            );
            setCombineUseWithVoucher(
                selectedVoucherEdit.combineUseWithVoucher
                    ? selectedVoucherEdit.combineUseWithVoucher
                    : false,
            );
            setCombineUseWithPromotion(
                selectedVoucherEdit.combineUseWithPromotion
                    ? selectedVoucherEdit.combineUseWithPromotion
                    : false,
            );

            setDeliveryFreeFlag(
                selectedVoucherEdit.deliveryFreeFlag
                    ? selectedVoucherEdit.deliveryFreeFlag
                    : false,
            );
            setDeliveryFreeAboveAmount(
                selectedVoucherEdit.deliveryFreeAboveAmount
                    ? selectedVoucherEdit.deliveryFreeAboveAmount.toFixed(0)
                    : '0.00',
            );
            setDeliveryDiscountAmount(
                selectedVoucherEdit.deliveryDiscountAmount
                    ? selectedVoucherEdit.deliveryDiscountAmount.toFixed(0)
                    : 0,
            );
            setDeliveryDiscountAboveAmount(
                selectedVoucherEdit.deliveryDiscountAboveAmount
                    ? selectedVoucherEdit.deliveryDiscountAboveAmount.toFixed(0)
                    : '0.00',
            );

            setBundleBuyNumber(
                selectedVoucherEdit.bundleBuyNumber
                    ? selectedVoucherEdit.bundleBuyNumber.toFixed(0)
                    : '0.00',
            );
            setBundleBuyPrice(
                selectedVoucherEdit.bundleBuyPrice
                    ? selectedVoucherEdit.bundleBuyPrice.toFixed(0)
                    : '0.00',
            );

            if (selectedVoucherEdit.variation) {
                setSelectedVariation(selectedVoucherEdit.variation);
                setSelectedVariationItems(selectedVoucherEdit.variationItems);
                setSelectedVariationItemsSku(selectedVoucherEdit.variationItemsSku);
            }
        } else {
            // designed to always mounted, thus need clear manually...

            setTitle('');
            setQuantity('0.00');
            setDescription('');
            setStartDate(Date.now());
            setEndDate(Date.now());
            setStartTime(Date.now());
            setEndTime(Date.now());

            setVoucherCodeFormat(MERCHANT_VOUCHER_CODE_FORMAT.UNIQUE);
            setVoucherCodeFormatUnique(
                MERCHANT_VOUCHER_CODE_FORMAT_UNIQUE.ALPHA_NUMERICAL,
            );
            setUniqueVoucherCodeLength('7');
            setUniqueVoucherCodePrefix('');

            setGenericVoucherCodeFixed('');

            setVoucherType(MERCHANT_VOUCHER_TYPE.CASH_VOUCHER);
            setVoucherValue('0.00');
            setVoucherTypeMinSpend('0.00');
            setVoucherTypeMaxSpend('0');

            setVoucherTerms('');

            setSelectedOutletList([]);
            setPlace('');

            setMaxVoucherToUse('1');

            setShowMaxClaim(false);

            // if (selectedVoucherEdit.segments && selectedVoucherEdit.segments.length > 0) {
            //   setSegmentValue(selectedVoucherEdit.segments[0]); // assume only got one
            // }

            setValidityDateFormatValue('0.00');
            setValidityDateFormatType(DATE_FORMAT_DROPDOWN_LIST[0].value);

            setMaxClaimValue('0.00');
            setMaxClaimDateFormatValue('0.00');
            setMaxClaimDateFormatType(DATE_FORMAT_DROPDOWN_LIST[0].value);

            setMaxRedeemValue('0.00');
            setMaxRedeemDateFormatValue('0.00');
            setMaxRedeemDateFormatType(DATE_FORMAT_DROPDOWN_LIST[0].value);

            setCombineVoucher(false);
            setCombineUseWithVoucher(false);
            setCombineUseWithPromotion(false);

            setDeliveryFreeFlag(false);
            setDeliveryFreeAboveAmount('0');
            setDeliveryDiscountAmount('0.00');
            setDeliveryDiscountAboveAmount('0.00');

            setBundleBuyNumber('0.00');
            setBundleBuyPrice('0.00');
        }
    }, [selectedVoucherEdit]);

    // useEffect(() => {
    //   setDropdownSegments(segments.map(segment => ({ label: segment.name, value: segment.uniqueId })));
    // }, [segments]);

    useEffect(() => {
        setDropdownSegments(
            crmSegments.map((segment) => ({
                label: segment.name,
                value: segment.uniqueId,
            })),
        );
    }, [crmSegments]);

    const setState = () => { };

    // componentDidMount() {
    //   getVoucher();

    //   getSegments();

    //   // retrieveAsyncStorage();
    // }

    // navigation.dangerouslyGetParent().setOptions({
    //   tabBarVisible: false,
    // });

    navigation.setOptions({
        headerLeft: () => (
            <View style={styles.headerLeftStyle}>
                <Image
                    style={{
                        width: 124,
                        height: 26,
                    }}
                    resizeMode="contain"
                    source={require('../assets/image/logo.png')}
                />
            </View>
        ),
        headerTitle: () => (
            <View
                style={[{
                    justifyContent: 'center',
                    alignItems: 'center',
                    // bottom: -2,
                    bottom: switchMerchant ? '2%' : 0,
                }, Dimensions.get('screen').width >= 768 && switchMerchant ? { right: Dimensions.get('screen').width * 0.1 } : {}, Dimensions.get('screen').width <= 768 ? { right: Dimensions.get('screen').width * 0.12 } : {}]}>
                <Text
                    style={{
                        fontSize: switchMerchant ? 20 : 24,
                        // lineHeight: 25,
                        textAlign: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        color: Colors.whiteColor,
                        opacity: 1,
                    }}>
                    Voucher
                </Text>
            </View>
        ),
        headerRight: () => (
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                {outletSelectDropdownView()}
                <View
                    style={{
                        backgroundColor: 'white',
                        width: 0.5,
                        height: Dimensions.get('screen').height * 0.025,
                        opacity: 0.8,
                        marginHorizontal: 15,
                        bottom: -1,
                    }}></View>
                <TouchableOpacity
                    onPress={() => navigation.navigate('Setting')}
                    style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                        style={{
                            fontFamily: 'NunitoSans-SemiBold',
                            fontSize: 16,
                            color: Colors.secondaryColor,
                            marginRight: 15,
                        }}>
                        {userName}
                    </Text>
                    <View
                        style={{
                            marginRight: 30,
                            width: Dimensions.get('screen').height * 0.05,
                            height: Dimensions.get('screen').height * 0.05,
                            borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                        }}>
                        <Image
                            style={{
                                width: Dimensions.get('screen').height * 0.035,
                                height: Dimensions.get('screen').height * 0.035,
                                alignSelf: 'center',
                            }}
                            source={require('../assets/image/profile-pic.jpg')}
                        />
                    </View>
                </TouchableOpacity>
            </View>
        ),
    });

    const retrieveAsyncStorage = async () => {
        const switchMerchant = await AsyncStorage.getItem('switchMerchant');

        if (switchMerchant === '1') {
            setState({
                switchMerchant: true,
            });
        }
    };

    // function here
    const getVoucher = () => {
        ApiClient.GET(API.getVoucher + User.getMerchantId()).then((result) => {
            setState({ voucher: result, voucherLength: result.length });
        });
    };

    const getSegments = () => {
        ApiClient.GET(API.getAllSegments).then((result) => {
            console.log(result);

            if (result) {
                setState({
                    segments: result.map((segment) => {
                        return { label: segment.name, value: segment.legacyId };
                    }),
                });
            }
        });
    };

    const createVoucherFunc = async () => {
        console.log('test fields');
        console.log(title);
        console.log(quantity);
        console.log(description);
        console.log(startDate);
        console.log(endDate);
        console.log(startTime);
        console.log(endTime);
        console.log(voucherValue);
        console.log(voucherTypeMinSpend);
        console.log(voucherTypeMaxSpend);
        console.log(selectedOutletList);
        console.log(voucherTerms);

        var message = '';

        if (title.length === 0) {
            message += 'Voucher title must be filled.\n';
        }
        if (quantity.length === 0) {
            message += 'Voucher quantity must be filled.\n';
        }
        if (description.length === 0) {
            message += 'Voucher description must be filled.\n';
        }
        if (!startDate) {
            message += 'Voucher start date must be selected.\n';
        }
        if (!endDate) {
            message += 'Voucher end date must be selected.\n';
        }
        if (!startTime) {
            message += 'Voucher start time must be selected.\n';
        }
        if (!endTime) {
            message += 'Voucher end time must be selected.\n';
        }
        if (selectedOutletList.length <= 0) {
            message += 'Outlet must be selected.\n';
        }
        if (!voucherTerms) {
            message += 'Voucher terms and conditions must be selected.\n';
        }

        if (message.length > 0) {
            Alert.alert('Info', message);
        }
        else {
            if (parseInt(quantity).length < 1 || parseInt(quantity).length > 10000) {
                Alert.alert(
                    'Error',
                    'Quantity must be between 1 and 10000.',
                    [{ text: 'OK', onPress: () => { } }],
                    { cancelable: false },
                );
                return;
            }

            if (voucherCodeFormat === MERCHANT_VOUCHER_CODE_FORMAT.UNIQUE) {
                if (uniqueVoucherCodeLength.length === 0) {
                    // Alert.alert(
                    //   'Error',
                    //   'Unique voucher code length cannot be empty.',
                    //   [{ text: 'OK', onPress: () => { } }],
                    //   { cancelable: false },
                    // );
                    return;
                } else {
                    const uniqueVoucherCodeLengthParsed = parseInt(
                        uniqueVoucherCodeLength,
                    );

                    if (
                        uniqueVoucherCodeLengthParsed.length < 6 ||
                        uniqueVoucherCodeLengthParsed.length > 10
                    ) {
                        Alert.alert(
                            'Error',
                            'Unique voucher code length must be within 6 to 10 characters',
                            [{ text: 'OK', onPress: () => { } }],
                            { cancelable: false },
                        );
                        return;
                    }
                }
            } else if (voucherCodeFormat === MERCHANT_VOUCHER_CODE_FORMAT.GENERIC) {
                // if (genericVoucherCodeFixed.length === 0) {
                //   Alert.alert(
                //     'Error',
                //     'Generic voucher code length cannot be empty.',
                //     [{ text: 'OK', onPress: () => { } }],
                //     { cancelable: false },
                //   );
                // return;
                // }
            }

            var outletNames = [];

            for (var i = 0; i < allOutlets.length; i++) {
                for (var j = 0; j < selectedOutletList.length; j++) {
                    if (selectedOutletList[j].includes(allOutlets[i].uniqueId)) {
                        outletNames.push(allOutlets[i].name);
                        break;
                    }
                }
            }

            CommonStore.update((s) => {
                s.isLoading = true;
            });

            //////////////////////////////////////previous data removable///////////////////////////////////////////
            var segmentArr = [];
            // if (segmentValue !== SEGMENT_TYPE.NONE) {
            //   segmentArr = [segmentValue];
            // }

            // segmentArr = [segmentValue];

            var body = {
                title: title,
                quantity: parseInt(quantity),
                description: description,
                startDate: moment(startDate).valueOf(),
                endDate: moment(endDate).valueOf(),
                startTime: moment(startTime).valueOf(),
                endTime: moment(endTime).valueOf(),

                format: voucherCodeFormat,
                formatUniqueType: voucherCodeFormatUnique,
                formatUniqueLength: parseInt(uniqueVoucherCodeLength),
                formatUniquePrefix: uniqueVoucherCodePrefix,

                formatGenericCode: genericVoucherCodeFixed,

                type: voucherType,
                value: parseInt(voucherValue),
                minSpend: parseInt(voucherTypeMinSpend),
                // maxSpend: parseInt(voucherTypeMaxSpend),

                // segments: JSON.stringify(segmentArr),
                // segments: segmentArr,
                segments: segmentValue,
                terms: voucherTerms,

                outletIdList: selectedOutletList,

                // maxClaim: maxVoucherToUse.length > 0 ? parseInt(maxVoucherToUse) : 1,

                ///////////////////////////////////////////////////////////////

                // new implementation

                validityDateFormatValue: parseInt(validityDateFormatValue),
                validityDateFormatType: validityDateFormatType,

                maxClaimValue: parseInt(maxClaimValue),
                maxClaimDateFormatValue: parseInt(maxClaimDateFormatValue),
                maxClaimDateFormatType: maxClaimDateFormatType,

                maxRedeemValue: parseInt(maxRedeemValue),
                maxRedeemDateFormatValue: parseInt(maxRedeemDateFormatValue),
                maxRedeemDateFormatType: maxRedeemDateFormatType,

                combineVoucherUse: combineVoucherUse,
                combineUseWithVoucher: combineUseWithVoucher,
                combineUseWithPromotion: combineUseWithPromotion,

                deliveryFreeFlag: deliveryFreeFlag,
                deliveryFreeAboveAmount: parseFloat(deliveryFreeAboveAmount),
                deliveryDiscountAmount: +parseFloat(deliveryDiscountAmount).toFixed(2),
                deliveryDiscountAboveAmount: +parseFloat(deliveryDiscountAboveAmount).toFixed(2),

                bundleBuyNumber: parseInt(bundleBuyNumber),
                bundleBuyPrice: +parseFloat(bundleBuyPrice).toFixed(2),
                variation: selectedVariation,
                variationItems: selectedVariationItems,
                variationItemsSku: selectedVariationItemsSku,
            };

            console.log(body);

            if (selectedVoucherEdit === null) {
                // ApiClient.POST(API.createVoucher, body).then((result) => {
                // ApiClient.POST(API.createGiftCardApplication, body).then((result) => {
                ApiClient.POST(API.createMerchantVoucher, body).then((result) => {
                    if (result) {
                        Alert.alert(
                            'Success',
                            'New voucher has been created',
                            [
                                {
                                    text: 'OK',
                                    onPress: () => {
                                        // setState({ createVoucher: false, voucherList: true })
                                        // getVoucher()

                                        setCreateVoucher(false);
                                        setVoucherList(true);
                                    },
                                },
                            ],
                            { cancelable: false },
                        );
                    } else {
                        Alert.alert(
                            'Error',
                            'Failed to be create Voucher',
                            [
                                {
                                    text: 'OK',
                                    onPress: () => { },
                                },
                            ],
                            { cancelable: false },
                        );
                    }

                    CommonStore.update((s) => {
                        s.isLoading = false;
                    });
                });
            } else if (selectedVoucherEdit) {
                body.voucherId = selectedVoucherEdit.uniqueId;

                // ApiClient.POST(API.createVoucher, body).then((result) => {
                // ApiClient.POST(API.createGiftCardApplication, body).then((result) => {
                ApiClient.POST(API.updateMerchantVoucher, body).then((result) => {
                    if (result.status === 'success') {
                        Alert.alert(
                            'Success',
                            'Voucher has been updated',
                            [
                                {
                                    text: 'OK',
                                    onPress: () => {
                                        // setState({ createVoucher: false, voucherList: true })
                                        // getVoucher()

                                        setCreateVoucher(false);
                                        setVoucherList(true);
                                    },
                                },
                            ],
                            { cancelable: false },
                        );
                    } else {
                        Alert.alert(
                            'Error',
                            'Failed to update Voucher',
                            [
                                {
                                    text: 'OK',
                                    onPress: () => { },
                                },
                            ],
                            { cancelable: false },
                        );
                    }
                    CommonStore.update((s) => {
                        s.isLoading = false;
                    });
                });
            }
        }
    };

    const duplicateMerchantVoucher = async (item) => {
        const body = {
            voucherId: item.uniqueId,
        };

        ApiClient.POST(API.duplicateMerchantVoucher, body).then((result) => {
            if (result && result.status === 'success') {
                Alert.alert('Success', 'Voucher has been duplicated');
                CommonStore.update((s) => {
                    s.selectedVoucherEdit = item;
                });
                setCreateVoucher(true);
                setVoucherList(false);
                expandThreeDotsFunc(item);
            } else {
                Alert.alert('Error', 'Failed to duplicate Voucher');
            }
        });
    };

    const updateVoucherStatus = () => {
        setIsloadingPublish(true);

        const currStatus = selectedVoucherEdit.status;

        var body = {
            status:
                currStatus === MERCHANT_VOUCHER_STATUS.DRAFT
                    ? MERCHANT_VOUCHER_STATUS.ACTIVE
                    : MERCHANT_VOUCHER_STATUS.DRAFT,
            voucherId: selectedVoucherEdit.uniqueId,
        };

        ApiClient.POST(API.updateMerchantVoucherStatus, body).then((result) => {
            if (result) {
                Alert.alert(
                    'Success',
                    currStatus === MERCHANT_VOUCHER_STATUS.DRAFT
                        ? 'Voucher has been published'
                        : 'Voucher has been pulled down',
                    [
                        {
                            text: 'OK',
                            onPress: () => {
                                // setState({ createVoucher: false, voucherList: true })
                                // getVoucher()

                                setCreateVoucher(false);
                                setVoucherList(true);
                            },
                        },
                    ],
                    { cancelable: false },
                );
            } else {
                Alert.alert(
                    'Error',
                    'Failed to publish Voucher',
                    [
                        {
                            text: 'OK',
                            onPress: () => { },
                        },
                    ],
                    { cancelable: false },
                );
            }

            setIsloadingPublish(false)
        });
    };

    const expandThreeDotsFunc = (param) => {
        if (expandThreeDots[param.uniqueId]) {
            setExpandThreeDots({
                ...expandThreeDots,
                [param.uniqueId]: false,
            });
        } else {
            setExpandThreeDots({
                // ...expandThreeDots,
                [param.uniqueId]: true,
            });
        }
    };

    const renderItem = ({ item, index }) => {
        var voucherStatus = '';

        voucherStatus = MERCHANT_VOUCHER_STATUS_PARSED[item.status];

        if (item.status === MERCHANT_VOUCHER_STATUS.DRAFT && item.isPublishedOnce) {
            voucherStatus = 'Pulled Down';
        }

        var voucherValue = '';

        if (item.type === MERCHANT_VOUCHER_TYPE.CASH_VOUCHER) {
            voucherValue = `RM${item.value
                .toFixed(2)
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
        } else if (item.type === MERCHANT_VOUCHER_TYPE.PERCENTAGE_VOUCHER) {
            voucherValue = `${item.value.toFixed(0)}%`;
        } else if (item.type === MERCHANT_VOUCHER_TYPE.BUNDLE_VOUCHER) {
            voucherValue = `RM${item.bundleBuyPrice.toFixed(0)}`;
        } else if (item.type === MERCHANT_VOUCHER_TYPE.COMPLIMENTARY_VOUCHER) {
            voucherValue = `Complimentary`;
        }

        return (
            <TouchableOpacity
                onPress={() => {
                    CommonStore.update((s) => {
                        s.selectedVoucherEdit = item;
                    });

                    setCreateVoucher(true);
                    setVoucherList(false);
                }}
                style={[{
                    //backgroundColor: (index + 1) % 2 == 0 ? Colors.whiteColor : Colors.highlightColor,
                    zIndex: (index + 1) % 2 == 0 ? -2 : 1,
                    backgroundColor: '#FFFFFF',
                    paddingVertical: 20,
                    //paddingTop: 20,
                    //paddingHorizontal: 3,
                    paddingLeft: 10,
                    borderColor: '#BDBDBD',
                    borderTopWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                    borderBottomWidth: (index + 1) % 2 == 0 ? 0 : 0.5,
                    //height: 100,
                    //width: '100%',
                    //alignItems: 'center',
                }, switchMerchant ? { paddingVertical: 10, paddingLeft: 7 } : {}]}>
                <View style={{ flexDirection: 'row', marginTop: 8, marginBottom: 8 }}>
                    <View style={{ marginHorizontal: 2, width: '15%' }}>
                        <Text style={[styles.textItem, { color: 'black' }, switchMerchant ? { fontSize: 10, } : {}]}>
                            {item.title}
                        </Text>
                    </View>

                    <View style={[{ marginHorizontal: 8, marginRight: 5, width: '20%' }, switchMerchant ? { width: '18%' } : {}]}>
                        <Text style={[styles.textItem, { paddingRight: 5, }, switchMerchant ? { fontSize: 10, } : {}]} numberOfLines={2}>
                            {item.description == null ||
                                item.description == undefined ||
                                item.description == ''
                                ? 'N/A'
                                : item.description}
                        </Text>
                    </View>

                    <View style={{ marginHorizontal: 4, width: '8%' }}>
                        <Text style={[styles.textItem, switchMerchant ? { fontSize: 10, } : {}]}>{voucherValue}</Text>
                    </View>

                    <View style={[{ marginHorizontal: 4, marginRight: 8, width: '17.5%' }, switchMerchant ? { fontSize: 10, width: '16.5%' } : {}]}>
                        <Text style={[styles.textItem, switchMerchant ? { fontSize: 10, } : {}]} numberOfLines={2}>
                            {item.terms == null || item.terms == undefined || item.terms == ''
                                ? 'N/A'
                                : item.terms}
                        </Text>
                    </View>

                    <View style={[{ marginHorizontal: 2, width: '10%' }, switchMerchant ? { width: '9%' } : {}]}>
                        <Text style={[styles.textItem, switchMerchant ? { fontSize: 10, } : {}]}>{item.quantity} left</Text>
                    </View>

                    <View
                        style={[{ marginHorizontal: 2, width: '12%', alignItems: 'baseline' },
                        switchMerchant ? { width: '11%' } : {}]}>
                        <View style={{ alignItems: 'center' }}>
                            <Text style={[styles.textItem, { fontVariant: ['tabular-nums'] }, switchMerchant ? { fontSize: 10, } : {}]}>
                                {item.startDate == null || item.startDate == undefined
                                    ? 'N/A'
                                    : moment(item.startDate).format('DD MMM YYYY')}
                            </Text>
                            <Text
                                style={[
                                    styles.textItem,
                                    { fontVariant: ['tabular-nums'], marginVertical: -5 },
                                    , switchMerchant ? { fontSize: 10, } : {}]}>
                                to
                            </Text>
                            <Text style={[styles.textItem, { fontVariant: ['tabular-nums'] }, switchMerchant ? { fontSize: 10, } : {}]}>
                                {item.endDate == null || item.endDate == undefined
                                    ? 'N/A'
                                    : moment(item.endDate).format('DD MMM YYYY')}
                            </Text>
                        </View>
                    </View>

                    <View style={{ marginLeft: 2, width: switchMerchant ? '11%' : '9%' }}>
                        <Text
                            style={[
                                styles.textItem,
                                {
                                    opacity: voucherStatus == 'Pulled Down' ? 0.83 : 1,
                                    fontWeight: '500',
                                    color:
                                        voucherStatus == 'Pulled Down'
                                            ? Colors.tabRed
                                            : voucherStatus == 'Draft'
                                                ? Colors.fieldtTxtColor
                                                : voucherStatus == 'Published'
                                                    ? Colors.primaryColor
                                                    : 'black',
                                },
                                switchMerchant ? { fontSize: 10, } : {}]}>
                            {voucherStatus}
                        </Text>
                    </View>

                    <View>
                        {expandThreeDots[item.uniqueId] == true ? (
                            <View
                                style={[{
                                    //position: 'absolute',
                                    width: 110,
                                    height: 45,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginLeft: -110,
                                    zIndex: 1,
                                    flexDirection: 'column',
                                    backgroundColor: '#FFFFFF',
                                    borderWidth: 1,
                                    //borderColor: '#E7E7E7',
                                    borderColor: Colors.highlightColor,
                                    borderRadius: 5,
                                    shadowOpacity: 0,
                                    shadowColor: '#000',
                                    shadowOffset: {
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 2,
                                    // backgroundColor: 'blue'
                                }, switchMerchant ? { height: 25, } : {}]}>
                                <TouchableOpacity
                                    style={[{
                                        flexDirection: 'row',
                                        width: 110,
                                        height: '70%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        //backgroundColor: 'red',
                                    }, switchMerchant ? {} : {}]}
                                    onPress={() => {
                                        // CommonStore.update((s) => {
                                        //   s.selectedVoucherEdit = item;
                                        // });
                                        // setCreateVoucher(true);
                                        // setVoucherList(false);
                                        duplicateMerchantVoucher(item);
                                    }}>
                                    <View
                                        style={[{
                                            width: '30%',
                                            paddingLeft: 12,
                                            justifyContent: 'center',
                                        }, switchMerchant ? {} : {}]}>
                                        {/* <MaterialCommunityIcons name='content-duplicate' size={17} color='grey' /> */}
                                        <Copy
                                            size={
                                                switchMerchant ? (13)
                                                    :
                                                    (Platform.OS == 'ios' ? 17 : 16)
                                            }
                                            color="grey"
                                        />
                                    </View>
                                    <View style={[{ width: '70%' }, switchMerchant ? {} : {}]}>
                                        <Text style={[{ marginLeft: 5, justifyContent: 'center' }, switchMerchant ? { fontSize: 10, } : {}]}>
                                            Duplicate
                                        </Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        ) : null}
                    </View>

                    <View style={{ width: '3%', flexDirection: 'row' }}>
                        <TouchableOpacity
                            style={{
                                marginTop: 0,
                                alignSelf: 'flex-start',
                                alignItems: 'flex-start',
                            }}
                            onPress={() => expandThreeDotsFunc(item)}>
                            <Entypo
                                name="dots-three-vertical"
                                size={switchMerchant ? 15 : 25}
                                color={Colors.tabGrey}
                                style={{ alignSelf: 'flex-start' }}
                            />
                        </TouchableOpacity>
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    // function end

    return (
        <View
            style={[
                styles.container,
                {
                    height: windowHeight,
                    width: windowWidth,
                },
            ]}>
            <View style={{ flex: 0.8 }}>
                <SideBar navigation={props.navigation} selectedTab={5} expandVoucher={true} />
            </View>
            <View style={{ height: windowHeight, flex: 9 }}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{}}
                    contentContainerStyle={{
                        paddingBottom: Dimensions.get('screen').height * 0.1,
                    }}
                >

                    {/*Add Voucher*/}

                    {voucherList ? (
                        <>
                            <View
                                style={switchMerchant ?
                                    {
                                        flexDirection: 'row',
                                        paddingTop: 15,
                                        paddingBottom: 10,
                                        marginHorizontal: 30,
                                        justifyContent: 'space-between',
                                        width: Dimensions.get('screen').width * 0.81,
                                    }
                                    :
                                    {
                                        flexDirection: 'row',
                                        paddingTop: 15,
                                        paddingBottom: 10,
                                        marginHorizontal: 30,
                                        alignSelf: 'center',
                                        justifyContent: 'space-between',
                                        width: Dimensions.get('screen').width * 0.87,
                                    }}>
                                <View
                                    style={{
                                        alignSelf: 'flex-start',
                                    }}>
                                    <View
                                        style={{
                                            alignItems: 'center',
                                            //marginRight: 20,
                                            width: '100%',
                                        }}>
                                        <Text style={[{ fontSize: 26, fontFamily: 'NunitoSans-Bold' }, switchMerchant ? { fontSize: 20, } : {}]}>
                                            {`${merchantVouchers.filter((item) =>
                                                item.title.toLowerCase().match(search.toLowerCase()),
                                            ).length
                                                } ${merchantVouchers.filter((item) =>
                                                    item.title.toLowerCase().match(search.toLowerCase()),
                                                ).length > 1 ? 'Vouchers' : 'Voucher'
                                                }`}
                                        </Text>
                                    </View>
                                </View>
                                {/* )} */}

                                <View
                                    style={{
                                        flexDirection: 'row',
                                    }}>
                                    {
                                        <View
                                            style={{
                                                alignSelf: 'flex-start',
                                                marginRight: 20,
                                                marginBottom: 10,
                                            }}>
                                            <View>
                                                <TouchableOpacity
                                                    style={[{
                                                        justifyContent: 'center',
                                                        flexDirection: 'row',
                                                        borderWidth: 1,
                                                        borderColor: Colors.primaryColor,
                                                        backgroundColor: '#4E9F7D',
                                                        borderRadius: 5,
                                                        //width: 160,
                                                        paddingHorizontal: 10,
                                                        height: 40,
                                                        alignItems: 'center',
                                                        shadowOffset: {
                                                            width: 0,
                                                            height: 2,
                                                        },
                                                        shadowOpacity: 0.22,
                                                        shadowRadius: 3.22,
                                                        elevation: 1,
                                                        zIndex: -1,
                                                    }, switchMerchant ? { height: 35, } : {}]}
                                                    onPress={() => {
                                                        // setState({
                                                        //   createVoucher: true,
                                                        //   voucherList: false
                                                        // })
                                                        CommonStore.update((s) => {
                                                            s.selectedVoucherEdit = null;
                                                        });

                                                        setCreateVoucher(true);
                                                        setVoucherList(false);
                                                    }}>
                                                    <AntDesign
                                                        name="pluscircle"
                                                        size={switchMerchant ? 10 : 20}
                                                        color={Colors.whiteColor}
                                                    />
                                                    <Text
                                                        style={[{
                                                            color: Colors.whiteColor,
                                                            marginLeft: 5,
                                                            fontSize: 16,
                                                            fontFamily: 'NunitoSans-Bold',
                                                        }, switchMerchant ? {
                                                            fontSize: 10,
                                                        } : {}]}>
                                                        VOUCHER
                                                    </Text>
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                        // )
                                        // </View>
                                    }

                                    <View
                                        style={[
                                            {
                                                // flex: 1,
                                                alignContent: 'flex-end',
                                                // marginBottom: 10,
                                                flexDirection: 'column',
                                                // marginLeft: 310,
                                            },
                                        ]}>
                                        <View style={[styles.textInput1, { marginRight: 0, }, switchMerchant ? { width: 180, height: 35, } : {}]}>
                                            <Icon
                                                name="search"
                                                size={switchMerchant ? 13 : 18}
                                                color={Colors.primaryColor}
                                                style={{ marginLeft: 15 }}
                                            />
                                            <TextInput
                                                editable={!loading}
                                                // underlineColorAndroid={Colors.whiteColor}
                                                style={[{
                                                    width: 220,
                                                    fontSize: 16,
                                                    fontFamily: 'NunitoSans-Regular',
                                                    paddingLeft: 5,
                                                    height: 45,
                                                }, switchMerchant ? { width: 180, height: 35, fontSize: 10 } : {}]}
                                                clearButtonMode="while-editing"
                                                placeholder=" Search"
                                                placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                                onChangeText={(text) => {
                                                    setSearch(text.trim());
                                                    setList1(false);
                                                    setSearchList(true);
                                                }}
                                                value={search}
                                            />
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View
                                style={switchMerchant ?
                                    {
                                        flex: 1,
                                        width: Dimensions.get('screen').width * 0.81,
                                        height:
                                            Platform.OS === 'ios'
                                                ? Dimensions.get('screen').height * 0.77
                                                : Dimensions.get('screen').height * 0.69,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 5,
                                        marginHorizontal: 30,
                                        marginTop: 0,
                                        marginBottom: 30,
                                        shadowOpacity: 0,
                                        shadowColor: '#000',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 3,
                                    }
                                    :
                                    {
                                        flex: 1,
                                        width: Dimensions.get('screen').width * 0.87,
                                        height:
                                            Platform.OS === 'ios'
                                                ? Dimensions.get('screen').height * 0.77
                                                : Dimensions.get('screen').height * 0.69,
                                        backgroundColor: Colors.whiteColor,
                                        borderRadius: 5,
                                        alignSelf: 'center',
                                        marginHorizontal: 30,
                                        marginTop: 10,
                                        marginBottom: 30,
                                        shadowOpacity: 0,
                                        shadowColor: '#000',
                                        shadowOffset: {
                                            width: 0,
                                            height: 2,
                                        },
                                        shadowOpacity: 0.22,
                                        shadowRadius: 3.22,
                                        elevation: 3,
                                    }}>
                                <View style={[styles.titleList,
                                switchMerchant ? { paddingVertical: 10, paddingLeft: 7 } : {}]}>
                                    {/* <Text style={{ width: '4%' }}></Text> */}
                                    <Text
                                        style={[
                                            styles.textTitle,
                                            { marginHorizontal: 2, width: '15%', alignSelf: 'center' },
                                            switchMerchant ? { fontSize: 10, } : {}]}>
                                        Title
                                    </Text>
                                    <Text
                                        style={[
                                            styles.textTitle,
                                            {
                                                marginHorizontal: 8,
                                                width: '20%',
                                                marginRight: 5,
                                                alignSelf: 'center',
                                            }, switchMerchant ? { fontSize: 10, width: '18%' } : {}]}>
                                        Description
                                    </Text>
                                    <Text
                                        style={[
                                            styles.textTitle,
                                            {
                                                marginHorizontal: 4, width: '8%', alignSelf: 'center'
                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                        Value
                                    </Text>
                                    {/* <Text style={{ marginHorizontal: 2, width: '12%', alignSelf: 'center' }}>Price</Text> */}
                                    <Text
                                        style={[
                                            styles.textTitle,
                                            {
                                                marginHorizontal: 4,
                                                marginRight: 8,
                                                width: '17.5%',
                                                alignSelf: 'center',
                                            },
                                            switchMerchant ? { fontSize: 10, width: '16.5%' } : {}]}>
                                        Terms
                                    </Text>
                                    <Text
                                        style={[
                                            styles.textTitle,
                                            { marginHorizontal: 2, width: '10%', alignSelf: 'center' }, switchMerchant ? { fontSize: 10, width: '9%' } : {}]}>
                                        Quantity
                                    </Text>
                                    <Text
                                        style={[
                                            styles.textTitle,
                                            { marginHorizontal: 2, width: '12%', alignSelf: 'center' },
                                            switchMerchant ? { fontSize: 10, width: '11%' } : {}]}>
                                        Durations
                                    </Text>
                                    {/* <Text style={[styles.textTitle, { marginHorizontal: 2, width: '9.6%', alignSelf: 'center' }]}>End at</Text> */}
                                    <Text
                                        style={[
                                            styles.textTitle,
                                            { marginLeft: 2, width: '9%', alignSelf: 'center' },
                                            switchMerchant ? { fontSize: 10, } : {}]}>
                                        Status
                                    </Text>
                                    <Text
                                        style={[
                                            styles.textTitle,
                                            { marginLeft: 2, width: '3%', alignSelf: 'center' },
                                        ]}></Text>
                                </View>
                                {list1 ? (
                                    <FlatList
                                        nestedScrollEnabled={true}
                                        showsVerticalScrollIndicator={false}
                                        data={merchantVouchers}
                                        extraData={merchantVouchers}
                                        renderItem={renderItem}
                                        keyExtractor={(item, index) => String(index)}
                                    />
                                ) : null}
                                {searchList ? (
                                    <FlatList
                                        nestedScrollEnabled={true}
                                        showsVerticalScrollIndicator={false}
                                        style={{ flex: 1 }}
                                        data={merchantVouchers.filter((item) =>
                                            item.title.toLowerCase().match(search.toLowerCase()),
                                        )}
                                        extraData={merchantVouchers}
                                        renderItem={renderItem}
                                        keyExtractor={(item, index) => String(index)}
                                    />
                                ) : null}
                            </View>
                            {/* </ScrollView> */}
                        </>
                    ) : null}

                    {createVoucher ? (
                        <View>
                            <ScrollView
                                showsVerticalScrollIndicator={false}
                                style={{}}
                                contentContainerStyle={{
                                    //paddingBottom: Dimensions.get('screen').height * 0.1,
                                }}>

                                <View style={switchMerchant ?
                                    {
                                        width: Dimensions.get('screen').width * 0.83,
                                        marginHorizontal: 0,
                                        paddingTop: 20,
                                        justifyContent: 'center',
                                    }
                                    :
                                    {
                                        // flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        width: Dimensions.get('screen').width * 1,
                                        paddingTop: 10,
                                        // paddingHorizontal:25
                                    }}

                                >
                                    <DateTimePickerModal
                                        date={new Date()}
                                        minimumDate={new Date()}
                                        maximumDate={pickDate == 'startDate' ? new Date(endDate) : new Date(2300, 10, 20)}
                                        isVisible={showDateTimePicker}
                                        mode={'date'}
                                        //display={"default"} //for iOS to use minuteInterval
                                        onConfirm={(text) => {
                                            var date_ob = new Date(text);
                                            let date = ('0' + date_ob.getDate()).slice(-2);
                                            let month = ('0' + (date_ob.getMonth() + 1)).slice(-2);
                                            let year = date_ob.getFullYear();

                                            setShowDateTimePicker(false);

                                            if (pickDate == 'startDate') {
                                                // setState({ startDate: year + "-" + month + "-" + date })
                                                setStartDate(year + '-' + month + '-' + date);
                                            } else {
                                                // setState({ endDate: year + "-" + month + "-" + date })
                                                setEndDate(year + '-' + month + '-' + date);
                                            }
                                        }}
                                        onCancel={() => {
                                            // setState({ showDateTimePicker: false })
                                            setShowDateTimePicker(false);
                                        }}
                                    />
                                    <DateTimePickerModal
                                        isVisible={showDateTimePicker1}
                                        mode={'time'}
                                        onConfirm={(text) => {
                                            if (pickTime == 'startTime') {
                                                setStartTime(text);
                                            } else {
                                                setEndTime(text);
                                            }
                                            setShowDateTimePicker1(false);
                                        }}
                                        onCancel={() => {
                                            setShowDateTimePicker1(false);
                                        }}
                                    />
                                    <View
                                        style={[{
                                            alignItems: 'flex-start',
                                            alignSelf: 'flex-start',
                                            paddingBottom: 5,
                                            paddingHorizontal: 20,
                                            flexDirection: 'row',
                                            justifyCoentent: 'space-between',
                                        },
                                        switchMerchant ? { paddingHorizontal: 5, } : {}]}>
                                        <TouchableOpacity
                                            style={{ flexDirection: 'row', alignItems: 'center' }}
                                            onPress={() => {
                                                setCreateVoucher(false);
                                                setVoucherList(true);
                                            }}>
                                            <Icon
                                                name="chevron-back"
                                                size={switchMerchant ? 20 : 28}
                                                color={Colors.primaryColor}
                                            />
                                            <Text
                                                style={[{
                                                    fontSize: 17,
                                                    color: Colors.primaryColor,
                                                    fontWeight: '600',
                                                    marginBottom: Platform.OS === 'ios' ? 0 : 1,
                                                }, switchMerchant ? {
                                                    fontSize: 14
                                                } : {}]}>
                                                Back
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                    <ScrollView
                                        contentContainerStyle={[{
                                            paddingTop: 5,
                                        }, switchMerchant ? {
                                            paddingHorizontal: 10, paddingBottom: 0
                                        } : {}]}>
                                        <View
                                            style={[
                                                styles.addVoucher,
                                                { paddingTop: 20, },
                                                switchMerchant ? { width: Dimensions.get('screen').width * 0.81, marginHorizontal: 0, } : {}]}>
                                            <View
                                                style={{
                                                    width: '85%',
                                                }}>
                                                <View
                                                    style={{
                                                        // position: 'absolute',
                                                        alignSelf: 'flex-end',
                                                        alignItems: 'center',
                                                        marginTop: 20,
                                                        zIndex: 1000,
                                                    }}>
                                                    {!selectedVoucherEdit ||
                                                        !selectedVoucherEdit.isPublishedOnce ? (
                                                        <View>
                                                            <TouchableOpacity
                                                                style={{
                                                                    justifyContent: 'center',
                                                                    flexDirection: 'row',
                                                                    borderWidth: 1,
                                                                    borderColor: Colors.primaryColor,
                                                                    backgroundColor: '#4E9F7D',
                                                                    borderRadius: 5,
                                                                    width: 130,
                                                                    paddingHorizontal: 10,
                                                                    height: 40,
                                                                    alignItems: 'center',
                                                                    shadowOffset: {
                                                                        width: 0,
                                                                        height: 2,
                                                                    },
                                                                    shadowOpacity: 0.22,
                                                                    shadowRadius: 3.22,
                                                                    elevation: 1,
                                                                    zIndex: -1,
                                                                    marginBottom: 10,
                                                                }}
                                                                disabled={isLoading || isLoadingPublish}
                                                                onPress={() => {
                                                                    //setState({ createVoucher: false, voucherList: true })
                                                                    createVoucherFunc();
                                                                }}>
                                                                <Text
                                                                    style={[{
                                                                        color: Colors.whiteColor,
                                                                        //marginLeft: 5,
                                                                        fontSize: 16,
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                    }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                    {isLoading ? 'LOADING...' : 'SAVE'}
                                                                </Text>

                                                                {isLoading ? (
                                                                    <ActivityIndicator
                                                                        style={{
                                                                            marginLeft: 8,
                                                                        }}
                                                                        color={Colors.whiteColor}
                                                                        size={'small'}
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </TouchableOpacity>
                                                        </View>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {selectedVoucherEdit ? (
                                                        <View>
                                                            <TouchableOpacity
                                                                style={{
                                                                    justifyContent: 'center',
                                                                    flexDirection: 'row',
                                                                    borderWidth: 1,
                                                                    borderColor:
                                                                        selectedVoucherEdit.status ===
                                                                            MERCHANT_VOUCHER_STATUS.DRAFT
                                                                            ? Colors.primaryColor
                                                                            : Colors.tabRed,
                                                                    backgroundColor:
                                                                        selectedVoucherEdit.status ===
                                                                            MERCHANT_VOUCHER_STATUS.DRAFT
                                                                            ? Colors.primaryColor
                                                                            : Colors.tabRed,
                                                                    borderRadius: 5,
                                                                    width: 130,
                                                                    paddingHorizontal: 10,
                                                                    height: 40,
                                                                    alignItems: 'center',
                                                                    shadowOffset: {
                                                                        width: 0,
                                                                        height: 2,
                                                                    },
                                                                    shadowOpacity: 0.22,
                                                                    shadowRadius: 3.22,
                                                                    elevation: 1,
                                                                    zIndex: -1,
                                                                    marginBottom: 10,
                                                                }}
                                                                disabled={isLoading || isLoadingPublish}
                                                                onPress={() => {
                                                                    updateVoucherStatus();
                                                                }}>
                                                                <Text
                                                                    style={[{
                                                                        color: Colors.whiteColor,
                                                                        //marginLeft: 5,
                                                                        fontSize: 16,
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                    }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                    {isLoadingPublish
                                                                        ? 'LOADING...'
                                                                        : selectedVoucherEdit.status ===
                                                                            MERCHANT_VOUCHER_STATUS.DRAFT
                                                                            ? 'PUBLISH'
                                                                            : 'PULL DOWN'}
                                                                </Text>

                                                                {isLoadingPublish ? (
                                                                    <ActivityIndicator
                                                                        style={{
                                                                            marginLeft: 8,
                                                                        }}
                                                                        color={Colors.whiteColor}
                                                                        size={'small'}
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </TouchableOpacity>
                                                        </View>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </View>

                                                <View
                                                    style={[{
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        marginBottom: 30,
                                                        marginTop: 10,
                                                    }, switchMerchant ? { marginBottom: 30, width: Dimensions.get('screen').width * 0.87, alignSelf: 'center', } : {}]}>
                                                    <Text
                                                        style={[{
                                                            // fontWeight: 'bold',
                                                            fontSize: 40,
                                                            fontFamily: 'NunitoSans-Bold',
                                                            opacity: 0.9,
                                                        }, switchMerchant ? { fontSize: 20, } : {}]}>
                                                        {`${selectedVoucherEdit ? 'Update' : 'Create'} Vouchers`}
                                                    </Text>
                                                    <Text
                                                        style={[{
                                                            color: Colors.descriptionColor,
                                                            //marginTop: 10,
                                                            fontSize: 16,
                                                            fontFamily: 'NunitoSans-SemiBold',
                                                            marginBottom: 10,
                                                        }, switchMerchant ? {
                                                            fontSize: 10,
                                                        } : {}]}>
                                                        Fill In Voucher Information
                                                    </Text>
                                                </View>

                                                <View
                                                    style={[{
                                                        flexDirection: 'row',
                                                        //justifyContent: 'space-between',
                                                        //alignItems: 'flex-start',
                                                        //alignContent: 'center',
                                                        //flex: 1
                                                        zIndex: -2,
                                                        bottom: 5,
                                                    }, switchMerchant ? { bottom: 5 } : {}]}>
                                                    <View style={{ height: 50, width: '15%', marginTop: 20 }}>
                                                        <Text
                                                            style={[{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>

                                                            Voucher Type
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            marginLeft: 10,
                                                        }}>
                                                        <View
                                                            style={{
                                                                minHeight: Dimensions.get('window').height * 0.2,
                                                                minWidth: 100,
                                                                backgroundColor: Colors.whiteColor,
                                                                borderRadius: 5,
                                                                marginBottom: 20,
                                                                borderWidth: 2,
                                                                borderColor: Colors.fieldtBgColor,
                                                                borderColor: '#E5E5E5',
                                                                borderWidth: 1,
                                                            }}>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    marginTop: 10,
                                                                }}>
                                                                {/* <DropDownPicker
                                                                    onOpen={() => {
                                                                        setIsOpenVoucherDropdown(true)
                                                                    }}
                                                                    onClose={() => {
                                                                        setIsOpenVoucherDropdown(false)
                                                                    }}
                                                                    containerStyle={[{
                                                                        height: 40,
                                                                        zIndex: 2,
                                                                    }, switchMerchant ? {
                                                                        height: 35,
                                                                    } : {}]}
                                                                    arrowColor={'black'}
                                                                    arrowSize={20}
                                                                    arrowStyle={{ fontWeight: 'bold' }}
                                                                    labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                    style={{
                                                                        width: 240,
                                                                        paddingVertical: 0,
                                                                        backgroundColor: Colors.fieldtBgColor,
                                                                        borderRadius: 10,
                                                                        marginLeft: 10,
                                                                    }}
                                                                    placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                    itemStyle={{
                                                                        justifyContent: 'flex-start',
                                                                        marginLeft: 5,
                                                                        zIndex: 2,
                                                                    }}
                                                                    disabled={
                                                                        !(
                                                                            !loading &&
                                                                            (!selectedVoucherEdit ||
                                                                                !selectedVoucherEdit.isPublishedOnce)
                                                                        )
                                                                    }
                                                                    items={[
                                                                        {
                                                                            label: 'Cash Voucher',
                                                                            value: MERCHANT_VOUCHER_TYPE.CASH_VOUCHER,
                                                                        },
                                                                        {
                                                                            label: 'Percentage Voucher',
                                                                            value: MERCHANT_VOUCHER_TYPE.PERCENTAGE_VOUCHER,
                                                                        },
                                                                        {
                                                                            label: 'Bundle Voucher',
                                                                            value: MERCHANT_VOUCHER_TYPE.BUNDLE_VOUCHER,
                                                                        },
                                                                        {
                                                                            label: 'Complimentary Voucher',
                                                                            value:
                                                                                MERCHANT_VOUCHER_TYPE.COMPLIMENTARY_VOUCHER,
                                                                        },
                                                                    ]}
                                                                    placeholder="Cash Voucher"
                                                                    onChangeItem={(item) => {
                                                                        setVoucherType(item.value);
                                                                    }}
                                                                    value={voucherType}
                                                                    defaultValue={voucherType}
                                                                    dropDownMaxHeight={150}
                                                                    dropDownStyle={{
                                                                        width: 240,
                                                                        height: 150,
                                                                        backgroundColor: Colors.fieldtBgColor,
                                                                        borderRadius: 10,
                                                                        borderWidth: 1,
                                                                        textAlign: 'left',
                                                                        zIndex: 2,
                                                                        marginLeft: 10,
                                                                    }}
                                                                    globalTextStyle={{
                                                                        fontSize: switchMerchant ? 10 : 14,
                                                                    }}
                                                                /> */}
                                                                <View style={{ marginLeft: 10, width: 240 }}>
                                                                    <Select
                                                                        disabled={
                                                                            !(
                                                                                !loading &&
                                                                                (!selectedVoucherEdit ||
                                                                                    !selectedVoucherEdit.isPublishedOnce)
                                                                            )
                                                                        }
                                                                        options={[
                                                                            {
                                                                                label: 'Cash Voucher',
                                                                                value: MERCHANT_VOUCHER_TYPE.CASH_VOUCHER,
                                                                            },
                                                                            {
                                                                                label: 'Percentage Voucher',
                                                                                value: MERCHANT_VOUCHER_TYPE.PERCENTAGE_VOUCHER,
                                                                            },
                                                                            {
                                                                                label: 'Bundle Voucher',
                                                                                value: MERCHANT_VOUCHER_TYPE.BUNDLE_VOUCHER,
                                                                            },
                                                                            {
                                                                                label: 'Complimentary Voucher',
                                                                                value:
                                                                                    MERCHANT_VOUCHER_TYPE.COMPLIMENTARY_VOUCHER,
                                                                            },
                                                                        ]}
                                                                        placeholder="Cash Voucher"
                                                                        placeholderTextColor={'black'}
                                                                        onChange={(item) => {
                                                                            setVoucherType(item.value);
                                                                        }}
                                                                        defaultValue={voucherType}
                                                                    />
                                                                </View>
                                                            </View>

                                                            {voucherType === MERCHANT_VOUCHER_TYPE.CASH_VOUCHER ? (
                                                                <View
                                                                    style={{
                                                                        flexDirection: 'row',
                                                                        margin: 10,
                                                                        alignItems: 'center',
                                                                        marginTop: 30,
                                                                        zIndex: -1,
                                                                    }}>
                                                                    <View
                                                                        style={{
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            marginRight: 0,
                                                                        }}>
                                                                        <Text style={[{ fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                            Value
                                                                        </Text>
                                                                        <TextInput
                                                                            editable={
                                                                                !loading &&
                                                                                (!selectedVoucherEdit ||
                                                                                    !selectedVoucherEdit.isPublishedOnce) &&
                                                                                !isOpenVoucherDropdown
                                                                            }
                                                                            underlineColorAndroid={Colors.fieldtBgColor}
                                                                            clearButtonMode="while-editing"
                                                                            style={[{
                                                                                backgroundColor: Colors.fieldtBgColor,
                                                                                width: 80,
                                                                                // height: 50,
                                                                                height: 40,
                                                                                borderRadius: 5,
                                                                                padding: 5,
                                                                                borderWidth: 1,
                                                                                borderColor: '#E5E5E5',
                                                                                paddingLeft: 10,
                                                                                marginHorizontal: 10,
                                                                                fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                                            }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                                            placeholder="RM5"
                                                                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                            placeholderTextColor={Platform.select({
                                                                                ios: '#a9a9a9',
                                                                            })}
                                                                            keyboardType="number-pad"
                                                                            onChangeText={(text) => {
                                                                                // setState({ voucherValue: text });
                                                                                setVoucherValue(text);
                                                                            }}
                                                                            defaultValue={voucherValue}
                                                                        />
                                                                    </View>

                                                                    <View
                                                                        style={{
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                        }}>
                                                                        <Text style={[{ fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? { fontSize: 10 } : {}]}>
                                                                            Min. Spend
                                                                        </Text>
                                                                        <TextInput
                                                                            editable={
                                                                                !loading &&
                                                                                (!selectedVoucherEdit ||
                                                                                    !selectedVoucherEdit.isPublishedOnce) &&
                                                                                !isOpenVoucherDropdown
                                                                            }
                                                                            style={[{
                                                                                backgroundColor: Colors.fieldtBgColor,
                                                                                width: 60,
                                                                                // height: 50,
                                                                                height: 40,
                                                                                borderRadius: 5,
                                                                                padding: 5,
                                                                                borderWidth: 1,
                                                                                borderColor: '#E5E5E5',
                                                                                paddingLeft: 10,
                                                                                marginHorizontal: 10,
                                                                                fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                                            }, switchMerchant ? { height: 35, fontSize: 10, } : {}]}
                                                                            placeholder={'RM5'}
                                                                            placeholderTextColor={Platform.select({
                                                                                ios: '#a9a9a9',
                                                                            })}
                                                                            keyboardType="number-pad"
                                                                            onChangeText={(text) => {
                                                                                // setState({ voucherValue: text });
                                                                                setVoucherTypeMinSpend(text);
                                                                            }}
                                                                            defaultValue={voucherTypeMinSpend}
                                                                        />
                                                                    </View>
                                                                </View>
                                                            ) : null}

                                                            {voucherType ===
                                                                MERCHANT_VOUCHER_TYPE.PERCENTAGE_VOUCHER ? (
                                                                <View
                                                                    style={{
                                                                        flexDirection: 'row',
                                                                        margin: 10,
                                                                        alignItems: 'center',
                                                                        marginTop: 30,
                                                                        zIndex: -1,
                                                                    }}>
                                                                    <View
                                                                        style={{
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            marginRight: 0,
                                                                        }}>
                                                                        <Text style={[{ fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                            Percentage (%)
                                                                        </Text>
                                                                        <TextInput
                                                                            editable={
                                                                                !loading &&
                                                                                (!selectedVoucherEdit ||
                                                                                    !selectedVoucherEdit.isPublishedOnce) &&
                                                                                !isOpenVoucherDropdown
                                                                            }
                                                                            underlineColorAndroid={Colors.fieldtBgColor}
                                                                            clearButtonMode="while-editing"
                                                                            style={[{
                                                                                backgroundColor: Colors.fieldtBgColor,
                                                                                width: 60,
                                                                                // height: 50,
                                                                                height: 40,
                                                                                borderRadius: 5,
                                                                                padding: 5,
                                                                                borderWidth: 1,
                                                                                borderColor: '#E5E5E5',
                                                                                paddingLeft: 10,
                                                                                marginHorizontal: 10,
                                                                                fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                                            }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                                            placeholder="5%"
                                                                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                            placeholderTextColor={Platform.select({
                                                                                ios: '#a9a9a9',
                                                                            })}
                                                                            keyboardType="number-pad"
                                                                            onChangeText={(text) => {
                                                                                // setState({ voucherValue: text });
                                                                                setVoucherValue(text);
                                                                            }}
                                                                            value={voucherValue}
                                                                        />
                                                                    </View>

                                                                    <View
                                                                        style={{
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                        }}>
                                                                        <Text style={[{ fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                            Min. Spend
                                                                        </Text>
                                                                        <TextInput
                                                                            editable={
                                                                                !loading &&
                                                                                (!selectedVoucherEdit ||
                                                                                    !selectedVoucherEdit.isPublishedOnce)
                                                                            }
                                                                            style={[{
                                                                                backgroundColor: Colors.fieldtBgColor,
                                                                                width: 60,
                                                                                // height: 50,
                                                                                height: 40,
                                                                                borderRadius: 5,
                                                                                padding: 5,
                                                                                borderWidth: 1,
                                                                                borderColor: '#E5E5E5',
                                                                                paddingLeft: 10,
                                                                                marginHorizontal: 10,
                                                                                fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                                            }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                                            placeholder={'RM5'}
                                                                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                            onChangeText={(text) => {
                                                                                // setState({ voucherValue: text });
                                                                                setVoucherTypeMinSpend(text);
                                                                            }}
                                                                            placeholderTextColor={Platform.select({
                                                                                ios: '#a9a9a9',
                                                                            })}
                                                                            defaultValue={voucherTypeMinSpend}
                                                                        />
                                                                    </View>
                                                                </View>
                                                            ) : null}

                                                            {voucherType ===
                                                                MERCHANT_VOUCHER_TYPE.BUNDLE_VOUCHER ? (
                                                                <View style={{ margin: 10, marginTop: 20, zIndex: -1 }}>
                                                                    <View
                                                                        style={{
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                            zIndex: -1,
                                                                        }}>
                                                                        <Text style={[{ fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? {
                                                                            fontSize: 10
                                                                        } : {}]}>
                                                                            Buy
                                                                        </Text>
                                                                        <TextInput
                                                                            editable={
                                                                                !loading &&
                                                                                (!selectedVoucherEdit ||
                                                                                    !selectedVoucherEdit.isPublishedOnce) &&
                                                                                !isOpenVoucherDropdown
                                                                            }
                                                                            style={[{
                                                                                backgroundColor: Colors.fieldtBgColor,
                                                                                width: 60,
                                                                                // height: 50,
                                                                                height: 40,
                                                                                borderRadius: 5,
                                                                                padding: 5,
                                                                                borderWidth: 1,
                                                                                borderColor: '#E5E5E5',
                                                                                paddingLeft: 10,
                                                                                marginHorizontal: 10,
                                                                                fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                                            }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                                            placeholder={'0'}
                                                                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                            placeholderTextColor={Platform.select({
                                                                                ios: '#a9a9a9',
                                                                            })}
                                                                            onChangeText={(text) => {
                                                                                setBundleBuyNumber(text);
                                                                            }}
                                                                            defaultValue={bundleBuyNumber}
                                                                            keyboardType={'number-pad'}
                                                                        />
                                                                        <View>

                                                                            {/* <DropDownPicker
                                                                                onOpen={() => {
                                                                                    setIsOpenPromotionDropdown(true)
                                                                                }}
                                                                                onClose={() => {
                                                                                    setIsOpenPromotionDropdown(false)
                                                                                }}
                                                                                disabled={
                                                                                    !(
                                                                                        !loading &&
                                                                                        (!selectedVoucherEdit ||
                                                                                            !selectedVoucherEdit.isPublishedOnce) &&
                                                                                        !isOpenVoucherDropdown
                                                                                    )
                                                                                }
                                                                                containerStyle={[{
                                                                                    // height: 50,
                                                                                    height: 40,
                                                                                    zIndex: 2,
                                                                                }, switchMerchant ? {
                                                                                    height: 35
                                                                                } : {}]}
                                                                                arrowColor={'black'}
                                                                                arrowSize={20}
                                                                                arrowStyle={{ fontWeight: 'bold' }}
                                                                                labelStyle={{
                                                                                    fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                                                }}
                                                                                style={[{
                                                                                    width: 240,
                                                                                    paddingVertical: 0,
                                                                                    backgroundColor: Colors.fieldtBgColor,
                                                                                    borderRadius: 10,
                                                                                }, switchMerchant ? {
                                                                                    width: 150
                                                                                } : {}]}
                                                                                placeholderStyle={{
                                                                                    color: Colors.fieldtTxtColor,
                                                                                    fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14
                                                                                }}
                                                                                itemStyle={{
                                                                                    justifyContent: 'flex-start',
                                                                                    marginLeft: 5,
                                                                                }}
                                                                                items={PROMOTION_TYPE_VARIATION_DROPDOWN_LIST}
                                                                                placeholder={'Products'}
                                                                                onChangeItem={(item) =>
                                                                                    // console.log(item);
                                                                                    setSelectedVariation(item.value)
                                                                                }
                                                                                defaultValue={selectedVariation}
                                                                                dropDownMaxHeight={150}
                                                                                dropDownStyle={[{
                                                                                    width: 240,
                                                                                    height: 100,
                                                                                    backgroundColor: Colors.fieldtBgColor,
                                                                                    borderRadius: 10,
                                                                                    borderWidth: 1,
                                                                                    zIndex: 2,
                                                                                }, switchMerchant ? {
                                                                                    width: 150
                                                                                } : {}]}
                                                                                globalTextStyle={[{
                                                                                    fontSize: 14
                                                                                }, switchMerchant ? {
                                                                                    fontSize: 10
                                                                                } : {}]}
                                                                            /> */}
                                                                            <View style={{ width: 240 }}>
                                                                                <Select //blocked
                                                                                    disabled={
                                                                                        !(
                                                                                            !loading &&
                                                                                            (!selectedVoucherEdit ||
                                                                                                !selectedVoucherEdit.isPublishedOnce) &&
                                                                                            !isOpenVoucherDropdown
                                                                                        )
                                                                                    }
                                                                                    options={PROMOTION_TYPE_VARIATION_DROPDOWN_LIST}
                                                                                    placeholder={'Products'}
                                                                                    placeholderTextColor={'black'}
                                                                                    onChange={(item) =>
                                                                                        setSelectedVariation(item.value)
                                                                                    }
                                                                                    defaultValue={selectedVariation}
                                                                                />
                                                                            </View>
                                                                        </View>

                                                                        {variationItemsDropdownList.length > 0 &&
                                                                            (variationItemsDropdownList.find(
                                                                                (item) =>
                                                                                    item.value === selectedVariationItems[0],
                                                                            ) ||
                                                                                selectedVariationItems.length === 0) ? (
                                                                            <View style={{ margin: 10 }}>
                                                                                {/* <DropDownPicker
                                                                                    disabled={
                                                                                        !(
                                                                                            !loading &&
                                                                                            (!selectedVoucherEdit ||
                                                                                                !selectedVoucherEdit.isPublishedOnce)
                                                                                        )
                                                                                    }
                                                                                    containerStyle={[{
                                                                                        // height: 50,
                                                                                        height: 40,
                                                                                        zIndex: 2,
                                                                                    }, switchMerchant ? {
                                                                                        height: 35
                                                                                    } : {}]}
                                                                                    arrowColor={'black'}
                                                                                    arrowSize={20}
                                                                                    arrowStyle={{ fontWeight: 'bold' }}
                                                                                    labelStyle={{
                                                                                        fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                                                    }}
                                                                                    style={[{
                                                                                        width: 240,
                                                                                        paddingVertical: 0,
                                                                                        backgroundColor: Colors.fieldtBgColor,
                                                                                        borderRadius: 10,
                                                                                    }, switchMerchant ? {
                                                                                        width: 150
                                                                                    } : {}]}
                                                                                    placeholderStyle={{
                                                                                        color: Colors.fieldtTxtColor,
                                                                                        fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14
                                                                                    }}
                                                                                    itemStyle={{
                                                                                        justifyContent: 'flex-start',
                                                                                        marginLeft: 5,
                                                                                    }}
                                                                                    items={variationItemsDropdownList}
                                                                                    placeholder={'Select a Product'}
                                                                                    multiple={true}
                                                                                    multipleText={'%d product(s) selected'}
                                                                                    customTickIcon={(press) => (
                                                                                        <Ionicon
                                                                                            name={'md-checkbox'}
                                                                                            color={
                                                                                                press
                                                                                                    ? Colors.fieldtBgColor
                                                                                                    : Colors.primaryColor
                                                                                            }
                                                                                            size={25}
                                                                                        />
                                                                                    )}
                                                                                    onChangeItem={(items) =>
                                                                                        setSelectedVariationItems(items)
                                                                                    }
                                                                                    defaultValue={selectedVariationItems}
                                                                                    multiple={true}
                                                                                    searchable={true}
                                                                                    dropDownMaxHeight={150}
                                                                                    dropDownStyle={[{
                                                                                        width: 240,
                                                                                        height: 140,
                                                                                        backgroundColor: Colors.fieldtBgColor,
                                                                                        borderRadius: 10,
                                                                                        borderWidth: 1,
                                                                                    }, switchMerchant ? {
                                                                                        width: 150
                                                                                    } : {}]}
                                                                                    globalTextStyle={[{
                                                                                        fontSize: 14
                                                                                    }, switchMerchant ? {
                                                                                        fontSize: 10
                                                                                    } : {}]}
                                                                                /> */}
                                                                                <View style={{ width: 240 }}>
                                                                                    <Select //blocked
                                                                                        disabled={
                                                                                            !(
                                                                                                !loading &&
                                                                                                (!selectedVoucherEdit ||
                                                                                                    !selectedVoucherEdit.isPublishedOnce)
                                                                                            )
                                                                                        }
                                                                                        onChange={(items) =>
                                                                                            setSelectedVariationItems(items)
                                                                                        }
                                                                                        defaultValue={selectedVariationItems}
                                                                                        options={variationItemsDropdownList}
                                                                                        placeholder={'Select a Product'}
                                                                                        placeholderTextColor={'black'}
                                                                                        isSearchable
                                                                                        isMulti
                                                                                    />
                                                                                </View>
                                                                            </View>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </View>

                                                                    <View
                                                                        style={{
                                                                            flexDirection: 'row',
                                                                            alignItems: 'center',
                                                                        }}>
                                                                        <Text
                                                                            style={[{
                                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                                                marginRight: 10,
                                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                            Min. Spend
                                                                        </Text>
                                                                        <TextInput
                                                                            editable={
                                                                                !loading &&
                                                                                (!selectedVoucherEdit ||
                                                                                    !selectedVoucherEdit.isPublishedOnce) &&
                                                                                !isOpenVoucherDropdown &&
                                                                                !isOpenPromotionDropdown
                                                                            }
                                                                            style={[{
                                                                                backgroundColor: Colors.fieldtBgColor,
                                                                                width: 60,
                                                                                // height: 50,
                                                                                height: 40,
                                                                                borderRadius: 5,
                                                                                padding: 5,
                                                                                borderWidth: 1,
                                                                                borderColor: '#E5E5E5',
                                                                                paddingLeft: 10,
                                                                                marginRight: 10,
                                                                                zIndex: -2,
                                                                            }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                                            placeholder={'0.00'}
                                                                            placeholderTextColor={Platform.select({
                                                                                ios: '#a9a9a9',
                                                                            })}
                                                                            onChangeText={(text) => {
                                                                                // setState({ voucherValue: text });
                                                                                setVoucherTypeMinSpend(text);
                                                                            }}
                                                                            defaultValue={voucherTypeMinSpend}
                                                                            keyboardType={'number-pad'}
                                                                        />

                                                                        <Text
                                                                            style={[{
                                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                                                zIndex: -2,
                                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                            Item(s) for
                                                                        </Text>

                                                                        <TextInput
                                                                            editable={
                                                                                !loading &&
                                                                                (!selectedVoucherEdit ||
                                                                                    !selectedVoucherEdit.isPublishedOnce) &&
                                                                                !isOpenPromotionDropdown
                                                                            }
                                                                            style={[{
                                                                                backgroundColor: Colors.fieldtBgColor,
                                                                                width: 60,
                                                                                // height: 50,
                                                                                height: 40,
                                                                                borderRadius: 5,
                                                                                padding: 5,
                                                                                borderWidth: 1,
                                                                                borderColor: '#E5E5E5',
                                                                                paddingLeft: 10,
                                                                                marginHorizontal: 10,
                                                                                zIndex: -2,
                                                                                fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                                            }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                                            placeholder={'0'}
                                                                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                            placeholderTextColor={Platform.select({
                                                                                ios: '#a9a9a9',
                                                                            })}
                                                                            // placeholderStyle={{ justifyContent: 'center' }}
                                                                            onChangeText={(text) => {
                                                                                setBundleBuyPrice(text);
                                                                            }}
                                                                            defaultValue={bundleBuyPrice}
                                                                            keyboardType={'number-pad'}
                                                                        />
                                                                    </View>
                                                                </View>
                                                            ) : null}

                                                            {voucherType ===
                                                                MERCHANT_VOUCHER_TYPE.COMPLIMENTARY_VOUCHER ? (
                                                                <View
                                                                    style={{
                                                                        margin: 10,
                                                                        marginTop: 40,
                                                                        zIndex: -1,
                                                                        // backgroundColor: 'blue',
                                                                        width: Dimensions.get('screen').width * 0.5,
                                                                    }}>
                                                                    <View
                                                                        style={{
                                                                            flexDirection: 'row',
                                                                            zIndex: -2,
                                                                            // backgroundColor: 'red'
                                                                        }}>
                                                                        <View
                                                                            style={{ justifyContent: 'center', }}>
                                                                            <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>
                                                                                {' '}
                                                                                Delivery Fee{' '}
                                                                            </Text>
                                                                        </View>
                                                                        <View
                                                                            style={{
                                                                                flexDirection: 'row',
                                                                                alignItems: 'center',
                                                                                zIndex: -2,
                                                                            }}>
                                                                            <View
                                                                                style={{
                                                                                    flexDirection: 'row',
                                                                                    alignItems: 'center',
                                                                                }}>
                                                                                <View style={{ marginLeft: 15 }}>
                                                                                    <Text
                                                                                        style={[{
                                                                                            color: Colors.descriptionColor,
                                                                                            fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                                                        }, switchMerchant ? { fontSize: 10 } : {}]}>
                                                                                        Discount (RM)
                                                                                    </Text>
                                                                                </View>
                                                                                <View
                                                                                    style={[{
                                                                                        // height: 35,
                                                                                        // height: 40,
                                                                                        paddingHorizontal: 5,
                                                                                        // bottom: 7,
                                                                                    }, switchMerchant ? { height: 35 } : {}]}>
                                                                                    <TextInput
                                                                                        editable={!isOpenVoucherDropdown}
                                                                                        underlineColorAndroid={
                                                                                            Colors.fieldtBgColor
                                                                                        }
                                                                                        style={[styles.textInput8, { fontFamily: 'NunitoSans-Regular', fontSize: 14 }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                                                        placeholder={'0'}
                                                                                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                                        placeholderTextColor={Platform.select({
                                                                                            ios: '#a9a9a9',
                                                                                        })}
                                                                                        onChangeText={(text) => {
                                                                                            setDeliveryDiscountAmount(text);
                                                                                        }}
                                                                                        value={deliveryDiscountAmount}
                                                                                        // ref={myTextInput}
                                                                                        keyboardType={'number-pad'}
                                                                                    />
                                                                                </View>

                                                                                <View style={{ marginLeft: 15 }}>
                                                                                    <Text
                                                                                        style={[{
                                                                                            color: Colors.descriptionColor,
                                                                                            fontSize: 14,
                                                                                            fontFamily: 'NunitoSans-Bold',
                                                                                        }, switchMerchant ? { fontSize: 10 } : {}]}>
                                                                                        For Order Above (RM)
                                                                                    </Text>
                                                                                </View>

                                                                                <View
                                                                                    style={[{
                                                                                        // height: 35,
                                                                                        // height: 40,
                                                                                        paddingHorizontal: 5,
                                                                                        // bottom: 7,
                                                                                    }, switchMerchant ? { height: 35 } : {}]}>
                                                                                    <TextInput
                                                                                        underlineColorAndroid={
                                                                                            Colors.fieldtBgColor
                                                                                        }
                                                                                        style={[styles.textInput8, { fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                                                        placeholder={'0'}
                                                                                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                                                        placeholderTextColor={Platform.select({
                                                                                            ios: '#a9a9a9',
                                                                                        })}
                                                                                        //placeholderTextColor={'red'}
                                                                                        onChangeText={(text) => {
                                                                                            setDeliveryDiscountAboveAmount(text);
                                                                                        }}
                                                                                        value={deliveryDiscountAboveAmount}
                                                                                        // ref={myTextInput}
                                                                                        keyboardType={'number-pad'}
                                                                                    />
                                                                                </View>

                                                                            </View>
                                                                            <View style={{ flex: 1 }}></View>
                                                                        </View>
                                                                        <View style={{ flex: 1 }}></View>
                                                                    </View>
                                                                </View>
                                                            ) : null}
                                                        </View>
                                                    </View>
                                                </View>

                                                <View
                                                    style={[{
                                                        flexDirection: 'row',
                                                        //marginLeft: '16%',
                                                        alignItems: 'flex-start',
                                                        marginRight: 10,
                                                        marginBottom: '2%',
                                                        marginTop: '1%',
                                                        zIndex: -1000,
                                                    }, switchMerchant ? { marginTop: '1%' } : {}]}>
                                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                        <input
                                                            onChange={() => {
                                                                setCombineVoucherUse(!combineVoucherUse);
                                                            }}
                                                            Checked={combineVoucherUse}
                                                            style={{ justifyContent: 'flex-start', zIndex: -1 }}
                                                            disabled={
                                                                !(
                                                                    !loading &&
                                                                    (!selectedVoucherEdit ||
                                                                        !selectedVoucherEdit.isPublishedOnce)
                                                                )
                                                            }
                                                            type={'checkbox'}
                                                        />
                                                        <Text
                                                            style={[{
                                                                marginLeft: 10,
                                                                fontWeight: '500',
                                                                zIndex: -1,
                                                                alignContent: 'center',
                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                                marginBottom: Platform.OS === 'ios' ? 0 : 1,
                                                            }, switchMerchant ? { marginLeft: 5, fontSize: 10, } : {}]}>
                                                            Combine Voucher Use
                                                        </Text>
                                                    </View>
                                                    {!combineVoucherUse ? (
                                                        <></>
                                                    ) : (
                                                        <View style={{ flexDirection: 'column', marginLeft: '1%' }}>
                                                            <View
                                                                style={{
                                                                    flexDirection: 'row',
                                                                    //marginBottom: '1%',
                                                                    alignItems: 'center',
                                                                }}>
                                                                <input
                                                                    type={'checkbox'}
                                                                    onClick={() => {
                                                                        setCombineUseWithVoucher(!combineUseWithVoucher);
                                                                    }}
                                                                    checkBoxColor={Colors.fieldtBgColor}
                                                                    uncheckedCheckBoxColor={Colors.tabGrey}
                                                                    checkedCheckBoxColor={Colors.primaryColor}
                                                                    Checked={combineUseWithVoucher}
                                                                />
                                                                <Text
                                                                    style={[{
                                                                        fontWeight: '500',
                                                                        marginLeft: 10,
                                                                        alignContent: 'center',
                                                                        //marginTop: 3,
                                                                        marginBottom: Platform.OS === 'ios' ? 0 : 1,
                                                                        fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                                    }, switchMerchant ? { marginLeft: 5, fontSize: 10, } : {}]}>
                                                                    With Voucher In Single Receipt
                                                                </Text>
                                                            </View>
                                                            <View
                                                                style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                                <input
                                                                    type={'checkbox'}
                                                                    onClick={() => {
                                                                        setCombineUseWithPromotion(
                                                                            !combineUseWithPromotion,
                                                                        );
                                                                    }}
                                                                    checkBoxColor={Colors.fieldtBgColor}
                                                                    uncheckedCheckBoxColor={Colors.tabGrey}
                                                                    checkedCheckBoxColor={Colors.primaryColor}
                                                                    Checked={combineUseWithPromotion}
                                                                />
                                                                <Text
                                                                    style={[{
                                                                        fontWeight: '500',
                                                                        marginLeft: 10,
                                                                        alignContent: 'center',
                                                                        //marginTop: 3,
                                                                        marginBottom: Platform.OS === 'ios' ? 0 : 1,
                                                                        fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                                    }, switchMerchant ? { marginLeft: 5, fontSize: 10, } : {}]}>
                                                                    With Promotion In Single Receipt
                                                                </Text>
                                                            </View>
                                                        </View>
                                                    )}
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-around',
                                                        zIndex: -1,
                                                    }}>
                                                    <TextInput
                                                        editable={
                                                            !loading &&
                                                            (!selectedVoucherEdit ||
                                                                !selectedVoucherEdit.isPublishedOnce)
                                                        }
                                                        underlineColorAndroid={Colors.fieldtBgColor}
                                                        clearButtonMode="while-editing"
                                                        style={[{
                                                            backgroundColor: Colors.fieldtBgColor,
                                                            width: '70%',
                                                            // height: 50,
                                                            height: 40,
                                                            borderRadius: 5,
                                                            padding: 5,
                                                            marginVertical: 5,
                                                            borderWidth: 1,
                                                            borderColor: '#E5E5E5',
                                                            paddingLeft: 10,
                                                            marginBottom: 20,
                                                            zIndex: -1,
                                                            fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                        }, switchMerchant ? { fontSize: 10, } : {}]}
                                                        placeholder="Voucher Title"
                                                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                                        // multiline={true}
                                                        onChangeText={(text) => {
                                                            // setState({ title: text });
                                                            setTitle(text);
                                                        }}
                                                        value={title}
                                                    />
                                                    <View
                                                        style={{
                                                            alignContent: 'center',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            height: 40,
                                                            width: '15%',
                                                            top: '0.5%',
                                                        }}>
                                                        <Text
                                                            style={[{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                            Quantity
                                                        </Text>
                                                    </View>
                                                    <TextInput
                                                        editable={
                                                            !loading &&
                                                            (!selectedVoucherEdit ||
                                                                !selectedVoucherEdit.isPublishedOnce)
                                                        }
                                                        underlineColorAndroid={Colors.fieldtBgColor}
                                                        clearButtonMode="while-editing"
                                                        keyboardType="numeric"
                                                        style={[{
                                                            backgroundColor: Colors.fieldtBgColor,
                                                            width: '15%',
                                                            // height: 50,
                                                            height: 40,
                                                            borderRadius: 5,
                                                            padding: 5,
                                                            marginVertical: 5,
                                                            borderWidth: 1,
                                                            borderColor: '#E5E5E5',
                                                            paddingLeft: 10,
                                                            marginBottom: 20,
                                                            fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                        }, switchMerchant ? { fontSize: 10, } : {}]}
                                                        placeholder={'100'}
                                                        placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                        // multiline={true}
                                                        onChangeText={(text) => {
                                                            // setState({ quantity: text });
                                                            setQuantity(text);
                                                        }}
                                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                                        value={quantity}
                                                    />
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-around',
                                                        //alignItems: 'center',
                                                        //alignContent: 'center',
                                                        //flex: 1
                                                    }}>
                                                    <TextInput
                                                        editable={
                                                            !loading &&
                                                            (!selectedVoucherEdit ||
                                                                !selectedVoucherEdit.isPublishedOnce)
                                                        }
                                                        underlineColorAndroid={Colors.fieldtBgColor}
                                                        clearButtonMode="while-editing"
                                                        style={[{
                                                            padding: 5,
                                                            backgroundColor: Colors.fieldtBgColor,
                                                            width: '100%',
                                                            height: 100,
                                                            borderRadius: 5,
                                                            borderWidth: 1,
                                                            borderColor: '#E5E5E5',
                                                            paddingTop: Platform.OS == 'ios' ? 10 : 10,
                                                            //paddingTop:Platform.OS=='android'?10:0,
                                                            paddingLeft: 10,
                                                            marginVertical: 10,
                                                            marginBottom: 20,
                                                            textAlignVertical: 'top',
                                                            fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                        }, switchMerchant ? { fontSize: 10, } : {}]}
                                                        placeholder="Voucher Description"
                                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                        multiline={true}
                                                        onChangeText={(text) => {
                                                            // setState({ description: text });
                                                            setDescription(text);
                                                        }}
                                                        value={description}
                                                    />
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        //justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        //alignContent: 'center',
                                                        //flex: 1
                                                    }}>
                                                    <View style={{ flexDirection: 'column', width: '50%' }}>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View
                                                                style={{
                                                                    justifyContent: 'center',
                                                                    // height: 50,
                                                                    height: 40,
                                                                    width: '48%',
                                                                }}>
                                                                <Text
                                                                    style={[{
                                                                        fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                                    }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                    Start & End Date
                                                                </Text>
                                                            </View>

                                                            {/* <TouchableOpacity
                                                                disabled={
                                                                    !(
                                                                        !loading &&
                                                                        (!selectedVoucherEdit ||
                                                                            !selectedVoucherEdit.isPublishedOnce)
                                                                    )
                                                                }
                                                                style={{
                                                                    alignItems: 'center',
                                                                }}
                                                                onPress={() => {
                                                                    setPickDate('startDate');
                                                                    setShowDateTimePicker(true);
                                                                }}>
                                                                <View
                                                                    style={[{
                                                                        // height: 50,
                                                                        height: 40,
                                                                        paddingHorizontal: 20,
                                                                        backgroundColor: Colors.fieldtBgColor,
                                                                        marginBottom: 20,
                                                                        width: 200,
                                                                        marginHorizontal: 10,
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                        alignContent: 'center',
                                                                        borderColor: '#E5E5E5',
                                                                        borderWidth: 1,
                                                                        fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                                        borderRadius: 12,
                                                                    }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}>

                                                                    <GCalendar width={switchMerchant ? 15 : 20} height={switchMerchant ? 15 : 20} />

                                                                    <Text
                                                                        style={[{
                                                                            width: '70%',
                                                                            fontFamily: 'NunitoSans-Regular', fontSize: 14,
                                                                            color: startDate
                                                                                ? 'black'
                                                                                : Colors.descriptionColor,
                                                                            //paddingLeft:15,
                                                                            textAlign: 'center',
                                                                        }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                        {startDate
                                                                            ? moment(startDate).format('DD MMM yyyy')
                                                                            : 'Start Date'}
                                                                    </Text>
                                                                </View>
                                                            </TouchableOpacity> */}
                                                            <View //blocked
                                                                disabled={
                                                                    !(
                                                                        !loading &&
                                                                        (!selectedVoucherEdit ||
                                                                            !selectedVoucherEdit.isPublishedOnce)
                                                                    )
                                                                }
                                                                style={[{
                                                                    height: 40,
                                                                    paddingHorizontal: 20,
                                                                    backgroundColor: Colors.fieldtBgColor,
                                                                    marginBottom: 20,
                                                                    width: 200,
                                                                    marginHorizontal: 10,
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    alignContent: 'center',
                                                                    borderColor: '#E5E5E5',
                                                                    borderWidth: 1,
                                                                    fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                                    borderRadius: 12,
                                                                    zIndex: 1000,
                                                                },]}>
                                                                <GCalendar width={switchMerchant ? 15 : 20} height={switchMerchant ? 15 : 20} />
                                                                <DatePicker
                                                                    selected={startDate}
                                                                    onChange={(startdate) => {
                                                                        setStartDate(moment(startdate));
                                                                    }}
                                                                    maxDate={moment(endDate).toDate()}
                                                                />
                                                            </View>

                                                            <View
                                                                style={{
                                                                    justifyContent: 'center',
                                                                    height: 40,
                                                                    width: '5%',
                                                                }}>
                                                                <Text
                                                                    style={[{
                                                                        fontFamily: 'NunitoSans-Regular', fontSize: 14,
                                                                    }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                    to
                                                                </Text>
                                                            </View>

                                                            {/* <TouchableOpacity
                                                                disabled={
                                                                    !(
                                                                        !loading &&
                                                                        (!selectedVoucherEdit ||
                                                                            !selectedVoucherEdit.isPublishedOnce)
                                                                    )
                                                                }
                                                                style={{
                                                                    alignItems: 'center',
                                                                }}
                                                                onPress={() => {
                                                                    setPickDate('endDate');
                                                                    setShowDateTimePicker(true);
                                                                }}>
                                                                <View
                                                                    style={[{
                                                                        // height: 50,
                                                                        height: 40,
                                                                        paddingHorizontal: 20,
                                                                        backgroundColor: Colors.fieldtBgColor,
                                                                        marginBottom: 20,
                                                                        width: 200,
                                                                        marginHorizontal: 10,
                                                                        flexDirection: 'row',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'space-between',
                                                                        alignContent: 'center',
                                                                        borderColor: '#E5E5E5',
                                                                        borderWidth: 1,
                                                                        fontFamily: 'NunitoSans-Regular',
                                                                        fontSize: 16,
                                                                        borderRadius: 12,
                                                                    }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}>

                                                                    <GCalendar
                                                                        width={switchMerchant ? 15 : 22}
                                                                        height={switchMerchant ? 15 : 22}
                                                                        size={40}
                                                                        color={Colors.primaryColor}
                                                                    />

                                                                    <Text
                                                                        style={[{
                                                                            width: '70%',
                                                                            fontFamily: 'NunitoSans-Regular', fontSize: 14,
                                                                            color: endDate
                                                                                ? 'black'
                                                                                : Colors.descriptionColor,
                                                                            //paddingLeft:15
                                                                            textAlign: 'center',
                                                                        }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                        {endDate
                                                                            ? moment(endDate).format('DD MMM yyyy')
                                                                            : 'End Date'}
                                                                    </Text>
                                                                </View>
                                                            </TouchableOpacity> */}
                                                            <View //blocked
                                                                disabled={
                                                                    !(
                                                                        !loading &&
                                                                        (!selectedVoucherEdit ||
                                                                            !selectedVoucherEdit.isPublishedOnce)
                                                                    )
                                                                }
                                                                style={[{
                                                                    height: 40,
                                                                    paddingHorizontal: 20,
                                                                    backgroundColor: Colors.fieldtBgColor,
                                                                    marginBottom: 20,
                                                                    width: 200,
                                                                    marginHorizontal: 10,
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    alignContent: 'center',
                                                                    borderColor: '#E5E5E5',
                                                                    borderWidth: 1,
                                                                    fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14,
                                                                    borderRadius: 12,
                                                                    zIndex: 1000,
                                                                },]}>
                                                                <GCalendar width={switchMerchant ? 15 : 20} height={switchMerchant ? 15 : 20} />
                                                                <DatePicker
                                                                    selected={endDate}
                                                                    onChange={(enddate) => {
                                                                        setEndDate(moment(enddate));
                                                                    }}
                                                                    minDate={moment(startDate).toDate()}
                                                                />
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        //justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        //alignContent: 'center',
                                                        //flex: 1
                                                    }}>
                                                    <View style={{ flexDirection: 'column', width: '50%' }}>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            <View
                                                                style={{
                                                                    justifyContent: 'center',
                                                                    // height: 50,
                                                                    height: 40,
                                                                    width: '48%',
                                                                }}>
                                                                <Text
                                                                    style={[{
                                                                        fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                                        // bottom: 10,
                                                                    }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                    Start & End Time
                                                                </Text>
                                                            </View>
                                                            <View
                                                                style={[{
                                                                    // height: 50,
                                                                    height: 40,
                                                                    paddingHorizontal: 20,
                                                                    backgroundColor: Colors.fieldtBgColor,
                                                                    borderRadius: 5,
                                                                    marginBottom: 20,
                                                                    width: 200,
                                                                    marginHorizontal: 10,
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    borderColor: '#E5E5E5',
                                                                    borderWidth: 1,
                                                                    alignContent: 'center',
                                                                    fontFamily: 'NunitoSans-Regular', fontSize: 14,
                                                                    borderRadius: 12,
                                                                    //paddingLeft: 30,
                                                                }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}>
                                                                <TouchableOpacity
                                                                    disabled={
                                                                        !(
                                                                            !loading &&
                                                                            (!selectedVoucherEdit ||
                                                                                !selectedVoucherEdit.isPublishedOnce)
                                                                        )
                                                                    }
                                                                    style={{
                                                                        alignItems: 'center',
                                                                    }}
                                                                    onPress={() => {
                                                                        //setPickTime('startTime');
                                                                        //setShowDateTimePicker1(true);
                                                                        setClockTimePicker(!clockTimePicker);
                                                                        setClockTimePicker1(false);
                                                                    }}>
                                                                    <Clock
                                                                        color={Colors.primaryColor}
                                                                    />
                                                                </TouchableOpacity>
                                                                <Text
                                                                    style={[{
                                                                        width: '70%',
                                                                        fontFamily: 'NunitoSans-Regular', fontSize: 14,
                                                                        color: startTime
                                                                            ? 'black'
                                                                            : Colors.descriptionColor,
                                                                        //paddingLeft:30,
                                                                        textAlign: 'center',
                                                                    }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                    {/* {startTime == '' ? "Start Time" : startTime} */}
                                                                    {moment(startTime).format('hh:mm A')}
                                                                </Text>
                                                                {clockTimePicker ? (
                                                                    <View
                                                                        style={{ //blocked
                                                                            zIndex: 10000,
                                                                        }}
                                                                    >
                                                                        <View
                                                                            style={{
                                                                                position: "absolute",
                                                                            }}
                                                                        >
                                                                            <TimeKeeper
                                                                                time={moment(startTime).format('hh:mm A')}
                                                                                onChange={(time) => setStartTime(moment(`${moment(startTime).format("MM/DD/YYYY")} ${time.formatted12}`))}
                                                                                onDoneClick={() => {
                                                                                    setClockTimePicker(false);
                                                                                }}
                                                                            ></TimeKeeper>
                                                                        </View>
                                                                    </View>
                                                                ) : null}
                                                            </View>

                                                            <View
                                                                style={{
                                                                    justifyContent: 'center',
                                                                    height: 40,
                                                                    width: '5%',
                                                                }}>
                                                                <Text
                                                                    style={[{
                                                                        fontFamily: 'NunitoSans-Regular', fontSize: 14,
                                                                    }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                    to
                                                                </Text>
                                                            </View>
                                                            <View
                                                                style={[{
                                                                    // height: 50,
                                                                    height: 40,
                                                                    paddingHorizontal: 20,
                                                                    backgroundColor: Colors.fieldtBgColor,
                                                                    borderRadius: 5,
                                                                    marginBottom: 20,
                                                                    width: 200,
                                                                    marginHorizontal: 10,
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    borderColor: '#E5E5E5',
                                                                    borderWidth: 1,
                                                                    alignContent: 'center',
                                                                    fontFamily: 'NunitoSans-Regular', fontSize: 14,
                                                                    borderRadius: 12,
                                                                    //paddingLeft: 30,
                                                                }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}>
                                                                <TouchableOpacity
                                                                    disabled={
                                                                        !(
                                                                            !loading &&
                                                                            (!selectedVoucherEdit ||
                                                                                !selectedVoucherEdit.isPublishedOnce)
                                                                        )
                                                                    }
                                                                    style={{
                                                                        alignItems: 'center',
                                                                    }}
                                                                    onPress={() => {
                                                                        //setPickTime('endTime');
                                                                        //setShowDateTimePicker1(true);
                                                                        setClockTimePicker1(!clockTimePicker1);
                                                                        setClockTimePicker(false);
                                                                    }}>
                                                                    <Clock
                                                                        color={Colors.primaryColor}
                                                                    />
                                                                </TouchableOpacity>
                                                                <Text
                                                                    style={[{
                                                                        width: '70%',
                                                                        fontFamily: 'NunitoSans-Regular', fontSize: 14,
                                                                        color: endTime
                                                                            ? 'black'
                                                                            : Colors.descriptionColor,
                                                                        //paddingLeft:30,
                                                                        textAlign: 'center',
                                                                    }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                                    {moment(endTime).format('hh:mm A')}
                                                                </Text>
                                                                {clockTimePicker1 ? (
                                                                    <View
                                                                        style={{
                                                                            zIndex: 100,
                                                                        }}
                                                                    >
                                                                        <View
                                                                            style={{
                                                                                position: "absolute",
                                                                            }}
                                                                        >
                                                                            <TimeKeeper
                                                                                time={moment(endTime).format('hh:mm A')}
                                                                                onChange={(time) => setEndTime(moment(`${moment(endTime).format("MM/DD/YYYY")} ${time.formatted12}`))}
                                                                                onDoneClick={() => {
                                                                                    setClockTimePicker1(false);
                                                                                }}
                                                                            ></TimeKeeper>
                                                                        </View>
                                                                    </View>
                                                                ) : null}
                                                            </View>
                                                        </View>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        //justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        marginBottom: 20,
                                                    }}>
                                                    <View
                                                        style={[{
                                                            justifyContent: 'center',
                                                            // height: 50,
                                                            height: 40,
                                                            width: '24%',
                                                        }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}>
                                                        <Text
                                                            style={[{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                                // bottom: 10,
                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                            Validity
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            marginLeft: 10,
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                        }}>
                                                        <TextInput
                                                            style={[{
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                width: 100,
                                                                // height: 50,
                                                                height: 40,
                                                                borderRadius: 5,
                                                                padding: 5,
                                                                borderWidth: 1,
                                                                borderColor: '#E5E5E5',
                                                                paddingLeft: 10,
                                                                marginRight: 15,
                                                                fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                            }, switchMerchant ? { height: 35, fontSize: 10, } : {}]}
                                                            placeholder={'Days'}
                                                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                                            onChangeText={(text) => {
                                                                setValidityDateFormatValue(text);
                                                            }}
                                                            defaultValue={validityDateFormatValue}
                                                            keyboardType={'number-pad'}
                                                            editable={
                                                                !loading &&
                                                                (!selectedVoucherEdit ||
                                                                    !selectedVoucherEdit.isPublishedOnce)
                                                            }
                                                        />

                                                        {/* <DropDownPicker
                                                            containerStyle={[{
                                                                // height: 50,
                                                                height: 40,
                                                                zIndex: 2,
                                                            }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}

                                                            arrowColor={'black'}
                                                            arrowSize={20}
                                                            arrowStyle={{ fontWeight: 'bold' }}
                                                            labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            style={{
                                                                width: 100,
                                                                paddingVertical: 0,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                            }}
                                                            placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            itemStyle={{
                                                                justifyContent: 'flex-start',
                                                                marginLeft: 5,
                                                                zIndex: 2,
                                                            }}
                                                            disabled={
                                                                !(
                                                                    !loading &&
                                                                    (!selectedVoucherEdit ||
                                                                        !selectedVoucherEdit.isPublishedOnce)
                                                                )
                                                            }
                                                            items={DATE_FORMAT_DROPDOWN_LIST}
                                                            placeholder={'Day'}
                                                            onChangeItem={(item) => {
                                                                setValidityDateFormatType(item.value);
                                                            }}
                                                            defaultValue={validityDateFormatType}
                                                            dropDownMaxHeight={150}
                                                            dropDownStyle={{
                                                                width: 100,
                                                                height: 120,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                                borderWidth: 1,
                                                                textAlign: 'left',
                                                                zIndex: 2,
                                                            }}
                                                            globalTextStyle={{
                                                                fontSize: switchMerchant ? 10 : 14,
                                                            }}
                                                        /> */}
                                                        <View style={{ width: 120 }}>
                                                            <Select
                                                                disabled={
                                                                    !(
                                                                        !loading &&
                                                                        (!selectedVoucherEdit ||
                                                                            !selectedVoucherEdit.isPublishedOnce)
                                                                    )
                                                                }
                                                                onChange={(item) => {
                                                                    setValidityDateFormatType(item.value);
                                                                }}
                                                                placeholder={'Day'}
                                                                placeholderTextColor={'black'}
                                                                options={DATE_FORMAT_DROPDOWN_LIST}
                                                                defaultValue={validityDateFormatType}
                                                            />
                                                        </View>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        //justifyContent: 'space-between',
                                                        alignItems: 'center',
                                                        //alignContent: 'center',
                                                        //flex: 1
                                                        zIndex: -1,
                                                        marginBottom: 20,
                                                    }}>
                                                    <View
                                                        style={{
                                                            justifyContent: 'center',
                                                            // height: 50,
                                                            height: 40,
                                                            width: '24%',
                                                        }}>
                                                        <Text
                                                            style={[{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                                // bottom: 10,
                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                            Voucher Code Format
                                                        </Text>
                                                        {/* <Text>{voucherCodeFormat}</Text> */}
                                                    </View>
                                                    <View style={{ marginLeft: 10 }}>
                                                        {/* <DropDownPicker
                                                            containerStyle={[{
                                                                // height: 50,
                                                                height: 40,
                                                                zIndex: -2,
                                                            }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}

                                                            arrowColor={'black'}
                                                            arrowSize={20}
                                                            arrowStyle={{ fontWeight: 'bold' }}
                                                            labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            style={{
                                                                width: 250,
                                                                paddingVertical: 0,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                            }}
                                                            placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            itemStyle={{
                                                                justifyContent: 'flex-start',
                                                                marginLeft: 5,
                                                                zIndex: 2,
                                                            }}
                                                            disabled={
                                                                !(
                                                                    !loading &&
                                                                    (!selectedVoucherEdit ||
                                                                        !selectedVoucherEdit.isPublishedOnce)
                                                                )
                                                            }
                                                            items={[
                                                                {
                                                                    label: 'Generic',
                                                                    value: MERCHANT_VOUCHER_CODE_FORMAT.GENERIC,
                                                                },
                                                                {
                                                                    label: 'Unique',
                                                                    value: MERCHANT_VOUCHER_CODE_FORMAT.UNIQUE,
                                                                },
                                                            ]}
                                                            placeholder="Unique code"
                                                            onChangeItem={(item) => {
                                                                setVoucherCodeFormat(item.value.toUpperCase());
                                                            }}
                                                            defaultValue={voucherCodeFormat}
                                                            dropDownMaxHeight={150}
                                                            dropDownStyle={{
                                                                width: 250,
                                                                height: 90,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                                borderWidth: 1,
                                                                textAlign: 'left',
                                                                zIndex: 2,
                                                            }}
                                                            globalTextStyle={{
                                                                fontSize: switchMerchant ? 10 : 14,
                                                            }}
                                                        /> */}
                                                        <View style={{ width: 250 }}>
                                                            <Select
                                                                disabled={
                                                                    !(
                                                                        !loading &&
                                                                        (!selectedVoucherEdit ||
                                                                            !selectedVoucherEdit.isPublishedOnce)
                                                                    )
                                                                }
                                                                options={[
                                                                    {
                                                                        label: 'Generic',
                                                                        value: MERCHANT_VOUCHER_CODE_FORMAT.GENERIC,
                                                                    },
                                                                    {
                                                                        label: 'Unique',
                                                                        value: MERCHANT_VOUCHER_CODE_FORMAT.UNIQUE,
                                                                    },
                                                                ]}
                                                                onChange={(item) => {
                                                                    setVoucherCodeFormat(item.value.toUpperCase());
                                                                }}
                                                                defaultValue={voucherCodeFormat}
                                                                placeholderTextColor={'black'}
                                                                placeholder="Unique code"
                                                            />
                                                        </View>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        zIndex: -2,
                                                    }}>
                                                    <View
                                                        style={[{
                                                            justifyContent: 'center',
                                                            // height: 50,
                                                            height: 40,
                                                            width: '24%',
                                                            marginBottom: 20,
                                                        }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}>
                                                        <Text
                                                            style={[{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                            Segment
                                                        </Text>
                                                    </View>
                                                    <View style={{ marginLeft: 10, zIndex: 5000 }}>
                                                        {/* <DropDownPicker
                                                            containerStyle={[{
                                                                // height: 50,
                                                                height: 40,
                                                                zIndex: 2,
                                                            }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}
                                                            arrowColor={'black'}
                                                            arrowSize={20}
                                                            arrowStyle={{ fontWeight: 'bold' }}
                                                            labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            style={{
                                                                width: 250,
                                                                paddingVertical: 0,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                            }}
                                                            placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            itemStyle={{
                                                                justifyContent: 'flex-start',
                                                                marginLeft: 5,
                                                                zIndex: 2,
                                                            }}
                                                            disabled={
                                                                !(
                                                                    !loading &&
                                                                    (!selectedVoucherEdit ||
                                                                        !selectedVoucherEdit.isPublishedOnce)
                                                                )
                                                            }
                                                            placeholder={'Segment'}
                                                            multipleText={'%d segment(s) selected'}
                                                            //items={variationItemsB1F1DropdownList}
                                                            items={CRM_SEGMENT_DROPDOWN_LIST.concat(
                                                                dropdownSegments,
                                                            )}
                                                            customTickIcon={(press) => (
                                                                <Ionicon
                                                                    name={'md-checkbox'}
                                                                    color={
                                                                        press ? Colors.fieldtBgColor : Colors.primaryColor
                                                                    }
                                                                    size={25}
                                                                />
                                                            )}
                                                            onChangeItem={(item) => {
                                                                setSegmentValue(item.value);
                                                            }}
                                                            defaultValue={segmentValue}
                                                            multiple={true}
                                                            searchable={true}
                                                            dropDownMaxHeight={150}
                                                            dropDownStyle={[{
                                                                width: 250,
                                                                height: 150,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                                borderWidth: 1,
                                                                textAlign: 'left',
                                                                zIndex: 2,
                                                            }, switchMerchant ? {} : {}]}
                                                            globalTextStyle={{
                                                                fontSize: switchMerchant ? 10 : 14,
                                                            }}
                                                        /> */}
                                                        <View style={{ width: 250, zIndex: 3000 }}> {/* blocked */}
                                                            <Select
                                                                disabled={
                                                                    !(
                                                                        !loading &&
                                                                        (!selectedVoucherEdit ||
                                                                            !selectedVoucherEdit.isPublishedOnce)
                                                                    )
                                                                }
                                                                options={CRM_SEGMENT_DROPDOWN_LIST.concat(
                                                                    dropdownSegments,
                                                                )}
                                                                onChange={(item) => {
                                                                    setSegmentValue(item.value);
                                                                }}
                                                                //defaultValue={segmentValue}
                                                                placeholder={'Segment'}
                                                                placeholderTextColor={'black'}
                                                                isMulti
                                                            />
                                                        </View>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        zIndex: -3,
                                                    }}>
                                                    <View style={[{ justifyContent: 'center', width: '25%' }, switchMerchant ? { width: '25.5%' } : {}]}>
                                                        <Text
                                                            style={[{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14,
                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                            Maximum Claim{' '}
                                                        </Text>
                                                    </View>
                                                    <TextInput
                                                        editable={
                                                            !loading &&
                                                            (!selectedVoucherEdit ||
                                                                !selectedVoucherEdit.isPublishedOnce)
                                                        }
                                                        style={[{
                                                            backgroundColor: Colors.fieldtBgColor,
                                                            width: 100,
                                                            // height: 50,
                                                            height: 40,
                                                            borderRadius: 5,
                                                            padding: 5,
                                                            borderWidth: 1,
                                                            borderColor: '#E5E5E5',
                                                            paddingLeft: 10,
                                                            fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                            // marginRight: 11,
                                                        }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                        placeholder={'Claim limit'}
                                                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                                        onChangeText={(text) => {
                                                            setMaxClaimValue(text);
                                                        }}
                                                        defaultValue={maxClaimValue}
                                                        keyboardType={'number-pad'}
                                                    />
                                                    <View
                                                        style={[{
                                                            justifyContent: 'center',
                                                            height: 50,
                                                            width: '14%',
                                                            // marginRight: 11,
                                                        }, switchMerchant ? { width: '13%', } : {}]}>
                                                        <Text
                                                            style={[{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                                textAlign: 'center',
                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                            Per Receipt
                                                        </Text>
                                                    </View>
                                                    <TextInput
                                                        editable={
                                                            !loading &&
                                                            (!selectedVoucherEdit ||
                                                                !selectedVoucherEdit.isPublishedOnce)
                                                        }
                                                        style={[{
                                                            backgroundColor: Colors.fieldtBgColor,
                                                            width: 100,
                                                            // height: 50,
                                                            height: 40,
                                                            borderRadius: 5,
                                                            padding: 5,
                                                            borderWidth: 1,
                                                            borderColor: '#E5E5E5',
                                                            paddingLeft: 10,
                                                            marginRight: 15,
                                                        }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                        placeholder={'Days'}
                                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                                        onChangeText={(text) => {
                                                            setMaxClaimDateFormatValue(text);
                                                        }}
                                                        defaultValue={maxClaimDateFormatValue}
                                                        keyboardType={'number-pad'}
                                                    />
                                                    <View style={{ zIndex: 1000 }}>
                                                        {/* <DropDownPicker
                                                            containerStyle={[{
                                                                // height: 50,
                                                                height: 40,
                                                                zIndex: 2,
                                                            }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}

                                                            arrowColor={'black'}
                                                            arrowSize={20}
                                                            arrowStyle={{ fontWeight: 'bold' }}
                                                            labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            style={{
                                                                width: 100,
                                                                paddingVertical: 0,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                            }}
                                                            placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            itemStyle={{
                                                                justifyContent: 'flex-start',
                                                                marginLeft: 5,
                                                                zIndex: 2,
                                                            }}
                                                            disabled={
                                                                !(
                                                                    !loading &&
                                                                    (!selectedVoucherEdit ||
                                                                        !selectedVoucherEdit.isPublishedOnce)
                                                                )
                                                            }
                                                            items={DATE_FORMAT_DROPDOWN_LIST}
                                                            placeholder={'Day'}
                                                            multipleText={'%d product(s) selected'}
                                                            onChangeItem={(item) => {
                                                                setMaxClaimDateFormatType(item.value);
                                                            }}
                                                            defaultValue={maxClaimDateFormatType}
                                                            dropDownMaxHeight={150}
                                                            dropDownStyle={[{
                                                                width: 100,
                                                                height: 120,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                                borderWidth: 1,
                                                                textAlign: 'left',
                                                                zIndex: 2,
                                                            }, switchMerchant ? {} : {}]}
                                                            globalTextStyle={{
                                                                fontSize: switchMerchant ? 10 : 14,
                                                            }}
                                                        /> */}
                                                        <View style={{ width: 120 }}>
                                                            <Select
                                                                disabled={
                                                                    !(
                                                                        !loading &&
                                                                        (!selectedVoucherEdit ||
                                                                            !selectedVoucherEdit.isPublishedOnce)
                                                                    )
                                                                }
                                                                options={DATE_FORMAT_DROPDOWN_LIST}
                                                                onChange={(item) => {
                                                                    setMaxClaimDateFormatType(item.value);
                                                                }}
                                                                defaultValue={maxClaimDateFormatType}
                                                                placeholder={'Day'}
                                                                placeholderTextColor={'black'}
                                                            />
                                                        </View>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        marginTop: 20,
                                                        marginBottom: 20,
                                                        zIndex: -4,
                                                    }}>
                                                    <View style={[{ justifyContent: 'center', width: '25%' }, switchMerchant ? { width: '25.5%' } : {}]}>
                                                        <Text
                                                            style={[{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                            Maximum Redeem{' '}
                                                        </Text>
                                                    </View>
                                                    <TextInput
                                                        editable={
                                                            !loading &&
                                                            (!selectedVoucherEdit ||
                                                                !selectedVoucherEdit.isPublishedOnce)
                                                        }
                                                        style={[{
                                                            backgroundColor: Colors.fieldtBgColor,
                                                            width: 100,
                                                            // height: 50,
                                                            height: 40,
                                                            borderRadius: 5,
                                                            padding: 5,
                                                            borderWidth: 1,
                                                            borderColor: '#E5E5E5',
                                                            paddingLeft: 10,
                                                            fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                            // marginRight: 11,
                                                        }, switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                        placeholder={'Redeem limit'}
                                                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                                        onChangeText={(text) => {
                                                            setMaxRedeemValue(text);
                                                        }}
                                                        defaultValue={maxRedeemValue}
                                                        keyboardType={'number-pad'}
                                                    />
                                                    <View
                                                        style={[{
                                                            justifyContent: 'center',
                                                            height: 50,
                                                            width: '14%',
                                                            // marginRight: 11,
                                                        }, switchMerchant ? { width: '13%', } : {}]}>
                                                        <Text
                                                            style={[{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                                textAlign: 'center',
                                                                // right: Platform.OS == 'ios' ? 5 : 5,
                                                                // right: Platform.OS == 'android' ? 5 : 5,
                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                            Per User
                                                        </Text>
                                                    </View>
                                                    <TextInput
                                                        editable={
                                                            !loading &&
                                                            (!selectedVoucherEdit ||
                                                                !selectedVoucherEdit.isPublishedOnce)
                                                        }
                                                        style={[{
                                                            backgroundColor: Colors.fieldtBgColor,
                                                            width: 100,
                                                            // height: 50,
                                                            height: 40,
                                                            borderRadius: 5,
                                                            padding: 5,
                                                            borderWidth: 1,
                                                            borderColor: '#E5E5E5',
                                                            paddingLeft: 10,
                                                            marginRight: 15,
                                                            fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                        }
                                                            , switchMerchant ? { fontSize: 10, height: 35 } : {}]}
                                                        placeholder={'Days'}
                                                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                                        onChangeText={(text) => {
                                                            setMaxRedeemDateFormatValue(text);
                                                        }}
                                                        defaultValue={maxRedeemDateFormatValue}
                                                        keyboardType={'number-pad'}
                                                    />
                                                    <View style={{ zIndex: -1 }}>
                                                        {/* <DropDownPicker
                                                            disabled={
                                                                !(
                                                                    !loading &&
                                                                    (!selectedVoucherEdit ||
                                                                        !selectedVoucherEdit.isPublishedOnce)
                                                                )
                                                            }
                                                            containerStyle={[{
                                                                // height: 50,
                                                                height: 40,
                                                                zIndex: 2,
                                                            }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}
                                                            arrowColor={'black'}
                                                            arrowSize={20}
                                                            arrowStyle={{ fontWeight: 'bold' }}
                                                            labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            style={{
                                                                width: 100,
                                                                paddingVertical: 0,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                            }}
                                                            placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            itemStyle={{
                                                                justifyContent: 'flex-start',
                                                                marginLeft: 5,
                                                                zIndex: 2,
                                                            }}
                                                            items={DATE_FORMAT_DROPDOWN_LIST}
                                                            placeholder={'Day'}
                                                            onChangeItem={(item) => {
                                                                setMaxRedeemDateFormatType(item.value);
                                                            }}
                                                            defaultValue={maxRedeemDateFormatType}
                                                            dropDownMaxHeight={150}
                                                            dropDownStyle={[{
                                                                width: 100,
                                                                height: 120,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                                borderWidth: 1,
                                                                textAlign: 'left',
                                                                zIndex: 2,
                                                            }, switchMerchant ? {} : {}]}
                                                            globalTextStyle={{
                                                                fontSize: switchMerchant ? 10 : 14,
                                                            }}
                                                        /> */}
                                                        <View style={{ width: 120 }}>
                                                            <Select //blocked
                                                                disabled={
                                                                    !(
                                                                        !loading &&
                                                                        (!selectedVoucherEdit ||
                                                                            !selectedVoucherEdit.isPublishedOnce)
                                                                    )
                                                                }
                                                                options={DATE_FORMAT_DROPDOWN_LIST}
                                                                onChange={(item) => {
                                                                    setMaxRedeemDateFormatType(item.value);
                                                                }}
                                                                defaultValue={maxRedeemDateFormatType}
                                                                placeholder={'Day'}
                                                                placeholderTextColor={'black'}
                                                            />
                                                        </View>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        zIndex: -1,
                                                    }}>
                                                    <View
                                                        style={[{
                                                            justifyContent: 'center',
                                                            // height: 50,
                                                            height: 40,
                                                            width: '24%',
                                                        }, switchMerchant ? {} : {}]}>
                                                        <Text
                                                            style={[{
                                                                fontFamily: 'NunitoSans-Bold', fontSize: 14,
                                                            }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                            Outlet(s){' '}
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            marginLeft: 10,
                                                            zIndex: -2,
                                                            right: Platform.OS == 'ios' ? 3 : 0,
                                                        }}>
                                                        {/* <DropDownPicker
                                                            onOpen={() => {
                                                                setIsOpenOutletDropdown(true)
                                                            }}
                                                            onClose={() => {
                                                                setIsOpenOutletDropdown(false)
                                                            }}
                                                            containerStyle={[{
                                                                // height: 50,
                                                                height: 40,
                                                                zIndex: 2,
                                                            }, switchMerchant ? { height: 35, borderRadius: 5 } : {}]}
                                                            arrowColor={'black'}
                                                            arrowSize={20}
                                                            arrowStyle={{ fontWeight: 'bold' }}
                                                            labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            style={{
                                                                width: 250,
                                                                paddingVertical: 0,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                            }}
                                                            placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                            items={outletDropdownList}
                                                            itemStyle={{
                                                                justifyContent: 'flex-start',
                                                                marginLeft: 5,
                                                                zIndex: 2,
                                                                padding: 3,
                                                            }}
                                                            defaultValue={selectedOutletList}
                                                            placeholder={'Outlet'}
                                                            multipleText={'%d outlet(s) selected'}
                                                            multiple={true}
                                                            customTickIcon={(press) => (
                                                                <Ionicon
                                                                    name={'md-checkbox'}
                                                                    color={
                                                                        press ? Colors.fieldtBgColor : Colors.primaryColor
                                                                    }
                                                                    size={25}
                                                                />
                                                            )}
                                                            onChangeItem={(items) => {
                                                                setSelectedOutletList(items);
                                                            }}
                                                            dropDownMaxHeight={150}
                                                            dropDownStyle={[{
                                                                width: 250,
                                                                height: 80,
                                                                backgroundColor: Colors.fieldtBgColor,
                                                                borderRadius: 10,
                                                                borderWidth: 1,
                                                                zIndex: 2,
                                                            }, switchMerchant ? { height: 70 } : {}]}
                                                            globalTextStyle={{
                                                                fontSize: switchMerchant ? 10 : 14,
                                                            }}

                                                        /> */}
                                                        <View style={{ width: 250 }}>
                                                            <Select
                                                                disabled={
                                                                    !(
                                                                        !loading &&
                                                                        (!selectedVoucherEdit ||
                                                                            !selectedVoucherEdit.isPublishedOnce)
                                                                    )
                                                                }
                                                                onChange={(items) => {
                                                                    setSelectedOutletList(items);
                                                                }}
                                                                options={outletDropdownList}
                                                                defaultValue={selectedOutletList}
                                                                placeholder={'Outlet'}
                                                                placeholderTextColor={'black'}
                                                                isMulti
                                                            />
                                                        </View>
                                                    </View>
                                                </View>

                                                <View
                                                    style={{ marginTop: 10, paddingTop: 10, justifyContent: 'center', height: 50, zIndex: -5 }}>
                                                    <Text
                                                        style={[{ fontFamily: 'NunitoSans-Bold', fontSize: 14 }, switchMerchant ? { fontSize: 10, } : {}]}>
                                                        Terms & Conditions
                                                    </Text>
                                                </View>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-around',
                                                        //alignItems: 'center',
                                                        //alignContent: 'center',
                                                        //flex: 1
                                                        zIndex: -5,
                                                        paddingTop: 5,
                                                        marginTop: 5,
                                                    }}>
                                                    <TextInput
                                                        editable={
                                                            !loading &&
                                                            (!selectedVoucherEdit ||
                                                                !selectedVoucherEdit.isPublishedOnce) &&
                                                            !isOpenOutletDropdown
                                                        }
                                                        underlineColorAndroid={Colors.fieldtBgColor}
                                                        clearButtonMode="while-editing"
                                                        style={[{
                                                            padding: 5,
                                                            backgroundColor: Colors.fieldtBgColor,
                                                            width: '100%',
                                                            height: 100,
                                                            borderRadius: 5,
                                                            borderWidth: 1,
                                                            borderColor: '#E5E5E5',
                                                            paddingTop: Platform.OS == 'ios' ? 10 : 10,
                                                            //paddingTop:Platform.OS=='android'?10:0,
                                                            paddingLeft: 10,
                                                            paddingBottom: 70,
                                                            zIndex: -1,
                                                            marginBottom: 20,
                                                            fontFamily: 'NunitoSans-Regular', fontSize: 14
                                                        }, switchMerchant ? { fontSize: 10, } : {}]}
                                                        placeholder="Write Your Terms"
                                                        placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                                        multiline={true}
                                                        onChangeText={(text) => {
                                                            // setState({ voucherTerms: text });
                                                            setVoucherTerms(text);
                                                        }}
                                                        value={voucherTerms}
                                                    />
                                                </View>
                                            </View>
                                            <View style={{ height: 50 }}></View>
                                        </View>
                                    </ScrollView>
                                </View>
                            </ScrollView>
                        </View>
                    ) : null}
                </ScrollView>
            </View>
        </View>

    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.highlightColor,
        flexDirection: 'row',
    },
    headerLogo: {
        width: 112,
        height: 25,
        marginLeft: 10,
    },
    list: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
    },
    listItem: {
        marginLeft: 20,
        color: Colors.descriptionColor,
        fontSize: 16,
    },
    sidebar: {
        width: Dimensions.get('screen').width * Styles.sideBarWidth,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
    content: {
        padding: 16,
    },
    circleIcon: {
        width: 30,
        height: 30,
        // resizeMode: 'contain',
        marginRight: 10,
        alignSelf: 'center',
    },
    titleList: {
        backgroundColor: '#ffffff',
        flexDirection: 'row',
        paddingVertical: 20,
        // paddingHorizontal: 3,
        paddingLeft: 10,
        //marginTop: 20,
        borderBottomWidth: StyleSheet.hairlineWidth,
        // borderTopLeftRadius: 8,
        // borderTopRightRadius: 8,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,

        alignItems: 'center',

        // shadowOpacity: 0,
        // shadowColor: '#000',
        // shadowOffset: {
        //   width: 0,
        //   height: 2,
        // },
        // shadowOpacity: 0.22,
        // shadowRadius: 3.22,
        // elevation: 3,
    },
    textTitle: {
        fontSize: 14,
        fontFamily: 'NunitoSans-Bold',
    },
    textItem: {
        fontSize: 14,
        fontFamily: 'NunitoSans-Regular',
    },
    footer: {
        flexDirection: 'row',
        justifyContent: 'center',
        // alignContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: 20,
        paddingTop: 15,
        marginTop: 0,
        width: '100%',

        shadowOpacity: 0,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 7,
    },
    addVoucher: {
        marginTop: 0,
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        width: Dimensions.get('screen').width * 0.87,
        backgroundColor: Colors.whiteColor,
        borderRadius: 5,
        marginHorizontal: 30,
        marginBottom: 30,
        shadowOpacity: 0,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 3,
    },
    textInput: {
        height: 50,
        paddingHorizontal: 20,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 5,
        marginBottom: 20,
        width: 300,
    },
    textInput1: {
        width: 250,
        height: 40,
        backgroundColor: 'white',
        borderRadius: 5,
        // marginLeft: '53%',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',

        marginRight: Dimensions.get('screen').width * Styles.sideBarWidth,

        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 3,
        borderWidth: 1,
        borderColor: '#E5E5E5',
    },
    headerLeftStyle: {
        width: Dimensions.get('screen').width * 0.17,
        justifyContent: 'center',
        alignItems: 'center',
    },
    textInput8: {
        backgroundColor: Colors.fieldtBgColor,
        width: 60,
        height: 40,
        borderRadius: 5,
        padding: 5,
        marginVertical: 5,
        borderWidth: 1,
        borderColor: '#E5E5E5',
        paddingLeft: 10,
    },
});

export default VoucherScreen;
