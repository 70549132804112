import React, { useState, Component, useEffect } from 'react';
import { StyleSheet, ScrollView, Image, Text, View, TextInput, TouchableOpacity, Alert, Modal, KeyboardAvoidingView, Dimensions, ActivityIndicator, useWindowDimensions } from 'react-native';
import firebase from 'firebase';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import API from '../constant/API';
import { CommonStore } from '../store/commonStore';
import { MerchantStore } from '../store/merchantStore';
import { UserStore } from '../store/userStore';
import ApiClient from '../util/ApiClient';
import { GoogleLogin } from 'react-google-login';
import AwesomeAlert from 'react-native-awesome-alerts';
import { useLinkTo, useRoute } from "@react-navigation/native";

import imgLogo from '../asset/image/logo_2.png';

const LoginScreen = props => {
    const {
        route,
        navigation,
    } = props;

    console.log('route');
    console.log(route);

    // this.goToLoginState = this.goToLoginState.bind(this);

    const linkTo = useLinkTo();
    const windowDimensions = useWindowDimensions();

    const [showAlertLogin, setShowAlertLogin] = useState(false);

    const email = UserStore.useState(s => s.email);
    const name = UserStore.useState(s => s.name);
    const googleId = UserStore.useState(s => s.googleId);
    const imageUrl = UserStore.useState(s => s.imageUrl);
    const tokenId = UserStore.useState(s => s.tokenId);

    const isAuthenticating = CommonStore.useState(s => s.isAuthenticating);

    useEffect(() => {
        CommonStore.update(s => {
            s.linkToFunc = linkTo;
        });
    }, [linkTo]);

    useEffect(() => {
        CommonStore.update(s => {
            s.routeName = route.name;
        });
    }, [route]);

    useEffect(() => {
        const parent = props.navigation.dangerouslyGetParent();
        parent.setOptions({
            tabBarVisible: false,
        });
        return () =>
            parent.setOptions({
                tabBarVisible: true,
            });
    }, []);

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User

                console.log('auth changed!');
                console.log(user);

                var uid = user.uid;
                // ...                
            } else {
                // User is signed out
                // ...
            }
        });
    }, []);

    // function here

    const responseGoogle = e => {
        console.log(e);

        if (e && e.profileObj && e.profileObj.email) {
            const domainName = e.profileObj.email.slice(e.profileObj.email.indexOf('@') + 1);

            console.log(domainName);

            if (
                // domainName === 'mykoodoo.com' ||
                // || domainName === 'perksense.com'
                e.profileObj.email === 'herks@perksense.com'
                // || e.profileObj.email === 'ryan@mykoodoo.com'
                || e.profileObj.email === 'ng.vincent@mykoodoo.com'
                // || e.profileObj.email === 'gregksw96@gmail.com'
                // || e.profileObj.email === 'yokehee0728@gmail.com'
                // || e.profileObj.email === 'tinghonggoh0314@gmail.com'
            ) {
                console.log('valid');

                CommonStore.update(s => {
                    s.isAuthenticating = true;
                });

                UserStore.update(s => {
                    s.email = e.profileObj.email;
                    s.name = e.profileObj.name;
                    s.googleId = e.profileObj.googleId;
                    s.imageUrl = e.profileObj.imageUrl;

                    s.tokenId = e.tokenId;
                });

                firebase.auth().signInAnonymously()
                    .then((result) => {
                        // Signed in..

                        console.log('signed in!');
                        console.log(result);

                        var body = {
                            email: e.profileObj.email,
                            name: e.profileObj.name,
                            googleId: e.profileObj.googleId,
                            imageUrl: e.profileObj.imageUrl,
                            tokenId: e.profileObj.tokenId,

                            firebaseUid: result.user.uid,
                        };

                        ApiClient.POST(API.loginKoodooCRMByGoogleAccount, body).then(async result => {
                            // if (result === true)
                            // getOrderHistory()
                            //console.log('getOrderHistory');
                            //console.log(getOrderHistory);

                            console.log('loginKoodooCRMByGoogleAccount');
                            console.log(result);

                            if (result && result.customToken) {
                                var firebaseToken = result.idToken;

                                try {
                                    result = await firebase.auth().signInWithCustomToken(result.customToken);
                                }
                                catch (error) {
                                    console.log('Failed to login with custom token');

                                    CommonStore.update(s => {
                                        s.alertObj = {
                                            title: 'Error',
                                            message: 'Unauthorized access',
                                        };

                                        s.isAuthenticating = false;
                                    });
                                }

                                // ApiClient.GET(API.getTokenKCRM + firebaseToken).then(async (result) => {
                                ApiClient.GET(API.getTokenKCRM + e.profileObj.email).then(async (result) => {
                                    console.log('getTokenKCRM');
                                    console.log(result);

                                    if (result && result.token) {
                                        await AsyncStorage.setItem('accessToken', result.token);
                                        await AsyncStorage.setItem('refreshToken', result.refreshToken);

                                        UserStore.update(s => {
                                            s.userId = result.userId;
                                            s.role = result.role;
                                            s.refreshToken = result.refreshToken;
                                            s.token = result.token;
                                        });

                                        CommonStore.update(s => {
                                            s.isAuthenticating = false;
                                        });

                                        // linkTo('/genagmt/dashboard');

                                        navigation.navigate('Merchants - KooDoo Statistics');
                                    }
                                    else {
                                        CommonStore.update(s => {
                                            s.alertObj = {
                                                title: 'Error',
                                                message: 'Unauthorized access',
                                            };

                                            s.isAuthenticating = false;
                                        });
                                    }
                                });
                            }
                        }).catch(err => {
                            console.error(err);

                            // setShowAlertLogin(false);

                            CommonStore.update(s => {
                                s.alertObj = {
                                    title: 'Error',
                                    message: 'Unauthorized access',
                                };

                                s.isAuthenticating = false;
                            });
                        });
                    })
                    .catch((error) => {
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        // ...

                        CommonStore.update(s => {
                            s.alertObj = {
                                title: 'Error',
                                message: 'Unauthorized access',
                            };

                            s.isAuthenticating = false;
                        });
                    });
            }
            else {
                console.log('invalid');

                // setShowAlertLogin(true);

                CommonStore.update(s => {
                    s.alertObj = {
                        title: 'Error',
                        message: 'Unauthorized access',
                    };
                });
            }
        }
        else {
            console.log('invalid');
        }
    };

    // function end    

    console.log('TEST LOGIN');

    return (
        <View style={{
            width: windowDimensions.width,
            height: windowDimensions.height,
        }}>

            <View style={{
                justifyContent: 'center',
                alignItems: 'center',

                width: windowDimensions.width,
                height: windowDimensions.height,
            }}>
                <Image style={{
                    width: 300,
                    height: 67,
                    alignSelf: 'center',
                    marginBottom: 50.
                }} resizeMode="contain" source={imgLogo} />

                {
                    isAuthenticating
                        ?
                        <View style={{
                            flexDirection: 'column',
                            // alignItems: 'center',
                            // backgroundColor: 'red'
                        }}>
                            <ActivityIndicator color={Colors.primaryColor} size={'large'} />

                            <Text style={{
                                fontFamily: 'NunitoSans-Bold',
                                color: Colors.darkBgColor,
                                fontSize: 16,
                                textAlign: 'center',
                                marginTop: 15,
                            }}>
                                Logging In
                            </Text>
                        </View>
                        :
                        <GoogleLogin
                            clientId="704833318771-em5d8cqg1dgabirj09hp4ml8m7uo0ble.apps.googleusercontent.com"
                            buttonText="Login to KooDoo Statistics"
                            onSuccess={(responseGoogle)}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            // render={renderProps => (
                            //     <button onClick={renderProps.onClick} disabled={renderProps.disabled}>Login to Koodoo CRM</button>
                            // )}
                            style={{
                                paddingTop: 10,
                                backgroundColor: 'red'
                            }}
                        >
                            {/* <FontAwesome
                        name='google'
                    />
                    <span> Login with Google</span> */}
                        </GoogleLogin>
                }
            </View>
        </View >
    );
};

const styles = StyleSheet.create({
    container: {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    },
    headerLogo: {
        width: 112,
        height: 25
    },
    logo: {
        width: 300,
        height: 67,
        alignSelf: 'center',
        marginTop: 10,
    },
    logoTxt: {
        color: Colors.descriptionColor,
        fontSize: 20,
        letterSpacing: 7,
        marginTop: 10,
        alignSelf: 'center',
        marginBottom: 40,
        fontFamily: 'NunitoSans-Regular',
    },
    loginTxt: {
        color: Colors.mainTxtColor,
        // fontWeight: "500",
        fontSize: 26,
        fontFamily: 'NunitoSans-Bold',
    },
    description: {
        color: Colors.descriptionColor,
        paddingVertical: 10,
        fontFamily: 'NunitoSans-Regular',
        fontSize: 16,
        marginBottom: 5,
        marginTop: -5,
    },
    textInput: {
        height: 50,
        paddingHorizontal: 20,
        backgroundColor: Colors.fieldtBgColor,
        borderRadius: 8,
        marginTop: 20,
        fontFamily: 'NunitoSans-Regular',
        fontSize: 16,
    },
    checkBox: {
        borderWidth: StyleSheet.hairlineWidth,
        borderColor: Colors.descriptionColor,

        width: 30,
        height: 10,
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',

        // marginRight: 5,
    },
    floatbtn: {
        zIndex: 1,
        position: 'absolute',
        bottom: 30,
        right: 30,
        width: 60,
        height: 60,
        borderRadius: 60 / 2,
        backgroundColor: Colors.primaryColor,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 3.22,
        elevation: 3
    },
    loginImg: {
        width: undefined,
        height: '100%',
        resizeMode: 'cover'
    },
    resetContainer: {
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: 0,
        alignSelf: 'center'
    },

    switchContainer: {
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
})

export default LoginScreen;