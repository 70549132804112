import { Store } from 'pullstate';

export const MerchantStore = new Store({
    description: '',
    name: '',
    shortcode: '',
    logo: '',

    allOutlets: [],
    allOutletsDict: {},

    currOutletId: '',
    currOutlet: {},

    poNumber: 0,
    poNumberUpdatedAt: null,
});   