import React, { Component, useEffect, useState, useRef } from 'react';
import {
    View,
    Text,
    // useWindowDimensions,
    Dimensions,
    Platform,
} from 'react-native';
import firebase from 'firebase';
import { Collections } from '../constant/firebase';
import { UserStore } from '../store/userStore';
import { CommonStore } from '../store/commonStore.js';
import { MerchantStore } from '../store/merchantStore';
import { OutletStore } from '../store/outletStore';
import * as User from './User';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import EncryptedStorage from 'react-native-encrypted-storage';
import * as geofire from 'geofire-common';
import moment from 'moment';
import { OUTLET_SHIFT_STATUS, ROLE_TYPE, USER_ORDER_STATUS, USER_QUEUE_STATUS, USER_RESERVATION_STATUS, USER_RING_STATUS, REPORT_SORT_FIELD_TYPE_COMPARE, REPORT_SORT_FIELD_TYPE_VALUE, REPORT_SORT_COMPARE_OPERATOR, REPORT_SORT_FIELD_TYPE, ORDER_TYPE, ACCUMULATOR_ID, EMAIL_REPORT_TYPE, PAYMENT_SORT_FIELD_TYPE, PAYMENT_SORT_FIELD_TYPE_VALUE, PAYMENT_SORT_COMPARE_OPERATOR, PAYMENT_SORT_FIELD_TYPE_COMPARE } from '../constant/common';
import ApiClient from './ApiClient';
import API from '../constant/API';
import { decode } from 'base64-arraybuffer';
import AWS from 'aws-sdk';
import { awsBucket, awsId, awsSecret } from '../constant/env';

var routeFrom = null;
var navFrom = null;

export function getRouteFrom() {
    return routeFrom;
};

export function setRouteFrom(param) {
    routeFrom = param;
};

export function getNavFrom() {
    return navFrom;
};

export function setNavFrom(param) {
    navFrom = param;
};

export const uploadFileToFirebaseStorage = async (selectedFile, referencePath) => {
    if (selectedFile) {
        // referencePath = `/clients/profile_image/${uniqueId}/profile_image${selectedImage.type}`;
        console.log(referencePath);
        const reference = firebase.storage().ref(referencePath);
        await reference.put(selectedFile);
        return referencePath;
    }
    else {
        return referencePath;
    }
};

export const convertArrayToCSV = objArray => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
            if (line != "") line += ",";

            line += array[i][index];
        }

        str += line + "\r\n";
    }

    return str;
};

export const generateEmailReport = async (
    reportType,
    data,
    reportSheetName,
    reportName,
    reportUrl,
    emailAddress,
    subject,
    text,
    callback,
) => {
    if (reportType === EMAIL_REPORT_TYPE.EXCEL) {
        // var excelFile = `${Platform.OS === 'ios' ? RNFS.DocumentDirectoryPath : RNFS.DownloadDirectoryPath}/temp.xlsx`;
        // var excelWorkSheet = XLSX.utils.json_to_sheet(data);
        // var excelWorkBook = XLSX.utils.book_new();

        // // excelWorkSheet = autofitColumns(data, excelWorkSheet);
        // var wscols = _autoFitColumns(data, excelWorkSheet);
        // excelWorkSheet['!cols'] = wscols;

        // XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, reportSheetName);

        // const workBookData = XLSX.write(excelWorkBook, {
        //     type: 'binary',
        //     bookType: 'xlsx',
        // });

        // RNFS.writeFile(excelFile, workBookData, 'ascii')
        //     .then(async (success) => {
        //         console.log(`wrote file ${excelFile}`);

        //         // const fileData = await RNFS.readFile(excelFile, 'base64');

        //         uploadEmailReport(
        //             reportType,
        //             excelFile,
        //             reportName,
        //             reportUrl,
        //             emailAddress,
        //             subject,
        //             text,
        //             callback,
        //         );
        //     })
        //     .catch((err) => {
        //         console.log(err.message);
        //     });
    }
    else if (reportType === EMAIL_REPORT_TYPE.CSV) {
        // const csvFile = `${RNFetchBlob.fs.dirs.DownloadDir}/temp.csv`;

        // RNFetchBlob.fs
        //     .writeFile(csvFile, data, 'utf8')
        //     .then(async () => {
        //         console.log(`wrote file ${csvFile}`);

        //         const filePath = await getPathForFirebaseStorageFromBlob(csvFile);

        //         uploadEmailReport(
        //             reportType,
        //             filePath,
        //             reportName,
        //             reportUrl,
        //             emailAddress,
        //             subject,
        //             text,
        //             callback,
        //         );
        //     })
        //     .catch(error => console.error(error));
    }
};

export const uploadEmailReport = async (
    reportType,
    filePath,
    reportName,
    reportUrl,
    emailAddress,
    subject,
    text,
    callback,
) => {
    console.log('reportUrl');
    console.log(reportUrl);

    const reference = firebase.storage().ref(reportUrl);
    await reference.putFile(filePath);

    var body = {
        fileUrlList: [
            reportUrl,
        ],
        fileNameList: [
            reportName,
        ],
        email: emailAddress,
        subject: subject,
        text: text,
    };

    console.log(body);

    ApiClient.POST(API.emailReport, body).then((result) => {
        callback && callback();
    });
};


export const uploadFileToFirebaseStorageWithCallback = async (selectedFile, referencePath, callback) => {
    if (selectedFile) {
        // referencePath = `/clients/profile_image/${uniqueId}/profile_image${selectedImage.type}`;
        console.log(referencePath);
        const reference = firebase.storage().ref(referencePath);
        await reference.putFile(selectedFile);
        // return referencePath;
    }
    else {
        // return referencePath;
    }

    callback && callback(referencePath);
};

export const uploadImageToFirebaseStorage = async (selectedImage, referencePath) => {
    if (selectedImage && selectedImage.uri) {
        // referencePath = `/clients/profile_image/${uniqueId}/profile_image${selectedImage.type}`;
        console.log(referencePath);
        const reference = firebase.storage().ref(referencePath);
        await reference.putFile(selectedImage.uri, {
            // cacheControl: 'max-age=31536000',
        });
        return referencePath;
    }
    else {
        return referencePath;
    }

    // if (selectedImage && selectedImage.uri) {
    //     const s3bucket = new AWS.S3({
    //       accessKeyId: awsId,
    //       secretAccessKey: awsSecret,
    //       Bucket: awsBucket,
    //       signatureVersion: 'v4',
    //     });

    //     const contentType = `image/${selectedImage.type.slice(1)}`;
    //     const contentDeposition = `inline;filename="${referencePath.slice(1)}"`;
    //     const base64 = selectedImage.uri;
    //     const arrayBuffer = decode(base64);

    //     return await new Promise((resolve, reject) => {
    //       s3bucket.createBucket(() => {
    //         const params = {
    //           Bucket: awsBucket,
    //           Key: referencePath.slice(1),
    //           Body: arrayBuffer,
    //           ContentDisposition: contentDeposition,
    //           ContentType: contentType,
    //           ACL: 'bucket-owner-full-control'
    //         };
    //         s3bucket.upload(params, (error, data) => {
    //           if (error) {
    //             console.log('image upload error');
    //             console.log(error);
    //             // reject(getApiError(error));
    //             resolve('');
    //           } else {
    //             console.log('image upload success');
    //             console.log(data);
    //             // resolve(data);
    //             resolve(data.Location);
    //           }
    //         });
    //       });
    //     });
    //   }
};

export const getImageFromFirebaseStorage = async (imageUrl, callback) => {
    try {
        // const url = await firebase.storage()
        //     .ref(imageUrl)
        //     .getDownloadURL()
        //     .then(url => {
        //         console.log(url);

        //         // return url;
        //         callback && callback(url);
        //     });

        const ref = await firebase.storage().ref(imageUrl);

        const url = await ref.getDownloadURL()
            .then(url => {
                console.log(url);

                // return url;
                callback && callback(url);
            }).catch(ex => {
                console.error('=========inner error url===========');
                console.error(imageUrl);

                console.error(ex);

                return '';
            });
    }
    catch (ex) {
        console.error('=========error url===========');
        console.error(imageUrl);

        console.error(ex);

        return '';
    }
};

// const getValueFromInfiniteList = (list, index) => {
//     const mappedIndex = index % list;

//     if (index >= list.length) {
//         return list[index]
//     }
// };

// const deleteUserCart = async (userCartId) => {
//     const body = {
//         userCartId: userCartId,
//     };

//     ApiClient.POST(API.deleteUserCart, body).then((result) => {
//         if (result && result.status === 'success') {
//             console.log('ok');
//         }
//     });
// };

// export const listenToFirestoreChanges = async (firebaseUid, merchantId, currOutletId) => {
//     // const firebaseUid = await AsyncStorage.getItem('firebaseUid');
//     // const merchantId = await AsyncStorage.getItem('merchantId');
//     // const role = await AsyncStorage.getItem('role');

//     // console.log(firebaseUid);
//     // console.log(merchantId);
//     // console.log(role);

//     // Get user details
//     firebase.firestore()
//         .collection(Collections.User)
//         .where('firebaseUid', '==', firebaseUid)
//         .onSnapshot(snapshot => {
//             console.log(`${Collections.User} changed!`);

//             if (!snapshot.empty) {
//                 const record = snapshot.docs[0].data();

//                 UserStore.update(s => {
//                     s.avatar = record.avatar;
//                     s.dob = record.dob;
//                     s.email = record.email;
//                     s.gender = record.gender;
//                     s.name = record.name;
//                     s.number = record.number;
//                     s.outletId = record.outledId;
//                     s.race = record.race;
//                     s.state = record.state;
//                     s.uniqueName = record.uniqueName;
//                     s.updatedAt = record.updatedAt;
//                 });
//             }
//         });

//     // Get merchant info
//     firebase.firestore()
//         .collection(Collections.Merchant)
//         .where('uniqueId', '==', merchantId)
//         .onSnapshot(snapshot => {
//             console.log(`${Collections.Merchant} changed!`);

//             if (!snapshot.empty) {
//                 const record = snapshot.docs[0].data();

//                 MerchantStore.update(s => {
//                     s.description = record.description;
//                     s.name = record.name;
//                     s.shortcode = record.shortcode;
//                 });
//             }
//         });

//     // Get outlets info
//     firebase.firestore()
//         .collection(Collections.Outlet)
//         .where('merchantId', '==', merchantId)
//         .onSnapshot(snapshot => {
//             console.log(`${Collections.Outlet} changed!`);

//             if (!snapshot.empty) {
//                 var outlets = [];

//                 for (var i = 0; i < snapshot.size; i++) {
//                     const record = snapshot.docs[i].data();

//                     outlets.push(record);
//                 }

//                 MerchantStore.update(s => {
//                     s.allOutlets = outlets;
//                 });

//                 if (currOutletId === '') {
//                     var currOutletIdTemp = '';
//                     var currOutletTemp = {};

//                     if (outlets.length > 0) {
//                         currOutletIdTemp = outlets[0].uniqueId;
//                         currOutletTemp = outlets[0];
//                     }

//                     MerchantStore.update(s => {
//                         s.currOutletId = currOutletIdTemp;
//                         s.currOutlet = currOutletTemp;
//                     });
//                 }
//             }
//         });

//     // Get outlets info
//     // firebase.firestore()
//     //     .collection(Collections.Outlet)
//     //     .where('merchantId', '==', merchantId)
//     //     .onSnapshot(snapshot => {
//     //         console.log(`${Collections.Outlet} changed!`);

//     //         if (!snapshot.empty) {
//     //             var outlets = [];

//     //             for (var i = 0; i < snapshot.size; i++) {
//     //                 const record = snapshot.docs[i].data();

//     //                 outlets.push(record);
//     //             }

//     //             MerchantStore.update(s => {
//     //                 s.allOutlets = outlets;
//     //             });

//     //             if (currOutletId === '') {
//     //                 var currOutletIdTemp = '';

//     //                 if (outlets.length > 0) {
//     //                     currOutletIdTemp = outlets[0].uniqueId;
//     //                 }

//     //                 MerchantStore.update(s => {
//     //                     s.currOutletId = currOutletId;
//     //                 });
//     //             }
//     //         }
//     //     });

//     if (currOutletId !== '') {
//         // Get current outlet info
//         firebase.firestore()
//             .collection(Collections.OutletItem)
//             .where('outletId', '==', outletId)
//             .onSnapshot(snapshot => {
//                 console.log(`${Collections.OutletItem} changed!`);

//                 if (!snapshot.empty) {
//                     var outletItems = [];

//                     for (var i = 0; i < snapshot.size; i++) {
//                         const record = snapshot.docs[i].data();

//                         outletItems.push(record);
//                     }

//                     OutletStore.update(s => {
//                         s.outletItems = outletItems;
//                     });
//                 }
//             });

//         firebase.firestore()
//             .collection(Collections.OutletCategory)
//             .where('outletId', '==', outletId)
//             .onSnapshot(snapshot => {
//                 console.log(`${Collections.OutletCategory} changed!`);

//                 if (!snapshot.empty) {
//                     var outletCategories = [];
//                     var outletCategoriesDict = {};

//                     for (var i = 0; i < snapshot.size; i++) {
//                         const record = snapshot.docs[i].data();

//                         outletCategories.push(record);
//                         outletCategoriesDict[record.uniqueId] = record;
//                     }

//                     OutletStore.update(s => {
//                         s.outletCategories = outletCategories;
//                         s.outletCategoriesDict = outletCategoriesDict;
//                     });
//                 }
//             });
//     }
// };

////////////////////////////////////////////////////////////////////////////////////


export const listenToUserChangesMerchant = async (firebaseUid, currOutletId) => {
    // Get user details
    firebase.firestore()
        .collection(Collections.User)
        .where('firebaseUid', '==', firebaseUid)
        .onSnapshot(snapshot => {
            console.log(`${Collections.User} changed!`);

            if (!snapshot.empty) {
                const record = snapshot.docs[0].data();

                var merchantId = record.merchantId;

                UserStore.update(s => {
                    s.avatar = record.avatar;
                    s.dob = record.dob;
                    s.email = record.email;
                    s.gender = record.gender;
                    s.name = record.name;
                    s.number = record.number;
                    s.outletId = record.outledId;
                    s.race = record.race;
                    s.state = record.state;
                    s.uniqueName = record.uniqueName;
                    s.updatedAt = record.updatedAt;

                    s.merchantId = record.merchantId;
                });

                // if (currOutletId === '') {
                //     MerchantStore.update(s => {
                //         s.currOutletId = record.outletId;
                //     });
                // }                

                MerchantStore.update(s => {
                    s.currOutletId = record.outletId;
                });
            }
        });
};

export const listenToSelectedOrderToPayUserIdChanges = (firebaseUid, selectedOrderToPayUserId) => {
    firebase.firestore()
        .collection(Collections.UserVoucherRedemption)
        // .where('startAt', '<=', moment().valueOf())
        // .where('endAt', '>', moment().valueOf())
        .where('userId', '==', selectedOrderToPayUserId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.UserVoucherRedemption} changed!`);

            var voucherIdRedemptionList = [];

            if (!snapshot.empty) {
                const userVoucherRedemptionSnapshot = await firebase.firestore().collection(Collections.UserVoucherRedemption)
                    .where('userId', '==', firebaseUid)
                    .limit(1)
                    .get();

                if (!userVoucherRedemptionSnapshot.empty) {
                    const userVoucherRedemption = userVoucherRedemptionSnapshot.docs[0].data();

                    voucherIdRedemptionList = userVoucherRedemption.redemptions;
                }
            }

            CommonStore.update(s => {
                s.selectedOrderToPayUserIdVoucherIdRedemptionList = voucherIdRedemptionList;
            });
        });
};

export const listenToMerchantIdChangesMerchant = (merchantId, currOutletId) => {
    // Get merchant info
    firebase.firestore()
        .collection(Collections.Merchant)
        .where('uniqueId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.Merchant} changed!`);

            if (!snapshot.empty) {
                const record = snapshot.docs[0].data();

                MerchantStore.update(s => {
                    s.description = record.description;
                    s.name = record.name;
                    s.shortcode = record.shortcode;
                    s.logo = record.logo;

                    s.poNumber = record.poNumber;
                    s.poNumberUpdatedAt = record.poNumberUpdatedAt;
                });
            }
        });

    // Get outlets info
    firebase.firestore()
        .collection(Collections.Outlet)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.Outlet} changed!`);

            if (!snapshot.empty) {
                var outlets = [];
                var outletsDict = {};

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outlets.push(record);
                    outletsDict[record.uniqueId] = record;
                }

                MerchantStore.update(s => {
                    s.allOutlets = outlets;
                    s.allOutletsDict = outletsDict;
                });

                if (currOutletId !== '') {
                    var currOutletIdTemp = '';
                    var currOutletTemp = {};

                    if (outlets.length > 0) {
                        // var firstOutlet = null;
                        // var oldestDate = moment();

                        // for (var i = 0; i < outlets.length; i++) {
                        //     if (moment(outlets[i].createdAt).isBefore(oldestDate)) {
                        //         oldestDate = outlets[i].createdAt;
                        //         firstOutlet = outlets[i];
                        //     }
                        // }

                        // currOutletIdTemp = outlets.uniqueId;
                        currOutletTemp = outlets.find(outlet => outlet.uniqueId === currOutletId);
                    }

                    MerchantStore.update(s => {
                        // s.currOutletId = currOutletIdTemp;
                        s.currOutlet = currOutletTemp;
                    });
                }
            }
        });

    // Get outlets info
    firebase.firestore()
        .collection(Collections.OutletItemAddOn)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.OutletItemAddOn} changed!`);

            var allOutletsItemAddOn = [];
            var allOutletsItemAddOnDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    allOutletsItemAddOn.push(record);

                    if (allOutletsItemAddOnDict[record.outletItemId]) {
                        allOutletsItemAddOnDict[record.outletItemId].push(record);
                    }
                    else {
                        allOutletsItemAddOnDict[record.outletItemId] = [record];
                    }
                }
            }

            CommonStore.update(s => {
                s.allOutletsItemAddOn = allOutletsItemAddOn;
                s.allOutletsItemAddOnDict = allOutletsItemAddOnDict;
            });
        });

    firebase.firestore()
        .collection(Collections.OutletItemAddOnChoice)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.OutletItemAddOnChoice} changed!`);

            var allOutletsItemAddOnChoiceDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    if (allOutletsItemAddOnChoiceDict[record.outletItemAddOnId]) {
                        allOutletsItemAddOnChoiceDict[record.outletItemAddOnId].push(record);
                    }
                    else {
                        allOutletsItemAddOnChoiceDict[record.outletItemAddOnId] = [record];
                    }
                }
            }

            CommonStore.update(s => {
                s.allOutletsItemAddOnChoiceDict = allOutletsItemAddOnChoiceDict;
            });
        });

    firebase.firestore()
        .collection(Collections.OutletOpening)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.OutletOpening} changed!`);

            if (!snapshot.empty) {
                var outletsOpeningDict = {};

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outletsOpeningDict[record.outletId] = record;
                }

                OutletStore.update(s => {
                    s.outletsOpeningDict = outletsOpeningDict;
                });
            }
        });

    firebase.firestore()
        .collection(Collections.MerchantVoucher)
        .where('merchantId', '==', merchantId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.MerchantVoucher} changed!`);

            var merchantVouchers = [];
            var merchantVouchersDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    merchantVouchers.push(record);
                    merchantVouchersDict[record.uniqueId] = record;
                }
            }

            merchantVouchers.sort((a, b) => b.updatedAt - a.updatedAt);

            CommonStore.update(s => {
                s.merchantVouchers = merchantVouchers;
                s.merchantVouchersDict = merchantVouchersDict;
            });
        });

    firebase.firestore()
        .collection(Collections.MerchantVoucherReport)
        .where('merchantId', '==', merchantId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.MerchantVoucherReport} changed!`);

            var merchantVoucherReportsVoucherIdDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    merchantVoucherReportsVoucherIdDict[record.voucherId] = record;
                }
            }

            CommonStore.update(s => {
                s.merchantVoucherReportsVoucherIdDict = merchantVoucherReportsVoucherIdDict;
            });
        });

    firebase.firestore()
        .collection(Collections.OutletTax)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.OutletTax} changed!`);

            var outletsTaxDict = {};
            var currOutletTaxes = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outletsTaxDict[record.outletId] = record;

                    if (record.outletId === currOutletId) {
                        currOutletTaxes.push(record);
                    }
                }
            }

            CommonStore.update(s => {
                s.outletsTaxDict = outletsTaxDict;
                s.currOutletTaxes = currOutletTaxes;
            });
        });

    firebase.firestore()
        .collection(Collections.User)
        .where('merchantId', '==', merchantId)
        // .orderBy('createdAt') // invalid query, need fix this in future
        .onSnapshot(snapshot => {
            console.log(`${Collections.User} changed!`);

            var allOutletsEmployees = [];

            if (snapshot && !snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    allOutletsEmployees.push(record);
                }
            }

            OutletStore.update(s => {
                s.allOutletsEmployees = allOutletsEmployees;
            });
        });

    firebase.firestore()
        .collection(Collections.UserOrder)
        .where('merchantId', '==', merchantId)
        .where('orderStatus', '==', USER_ORDER_STATUS.ORDER_COMPLETED)
        // .orderBy('createdAt') // invalid query, need fix this in future
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserOrder} changed!`);

            if (snapshot && !snapshot.empty) {
                var userOrders = [];

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    userOrders.push(record);
                }

                OutletStore.update(s => {
                    s.allOutletsUserOrdersDone = userOrders;
                });
            }
        });

    firebase.firestore()
        .collection(Collections.OutletItem)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.OutletItem} changed!`);

            var outletItems = [];
            var outletItemsDict = {};

            var allOutletsItems = [];
            var allOutletsItemsSkuDict = {};

            if (!snapshot.empty) {

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    if (record.outletId === currOutletId) {
                        outletItems.push(record);
                        outletItemsDict[record.uniqueId] = record;
                    }

                    allOutletsItems.push(record);

                    if (allOutletsItemsSkuDict[record.sku]) {
                        allOutletsItemsSkuDict[record.sku].push(record);
                    }
                    else {
                        allOutletsItemsSkuDict[record.sku] = [record];
                    }
                }
            }

            if (
                currOutletId
                // true
            ) {
                OutletStore.update(s => {
                    s.outletItems = outletItems;
                    s.outletItemsDict = outletItemsDict;

                    s.allOutletsItems = allOutletsItems;

                    s.allOutletsItemsSkuDict = allOutletsItemsSkuDict;
                });
            }
        });

    firebase.firestore()
        .collection(Collections.OutletItemCategory)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.OutletItemCategory} changed!`);

            var outletCategories = [];
            var outletCategoriesDict = {};

            var allOutletsCategories = [];
            var allOutletsCategoriesNameDict = {};
            var allOutletsCategoriesDict = {};

            var allOutletsCategoriesUnique = [];

            if (!snapshot.empty) {

                // var selectedOutletItemCategory = {};

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    if (record.outletId === currOutletId) {
                        outletCategories.push(record);
                        outletCategoriesDict[record.uniqueId] = record;
                    }

                    allOutletsCategories.push(record);

                    if (allOutletsCategoriesNameDict[record.name]) {
                        allOutletsCategoriesNameDict[record.name].push(record);
                    }
                    else {
                        allOutletsCategoriesNameDict[record.name] = [record];
                    }

                    if (allOutletsCategoriesDict[record.uniqueId]) {
                        allOutletsCategoriesDict[record.uniqueId] = record;
                    }
                    else {
                        allOutletsCategoriesDict[record.uniqueId] = record;
                    }

                    var isExisted = false;

                    for (var j = 0; j < allOutletsCategoriesUnique.length; j++) {
                        if (allOutletsCategoriesUnique[j].name === record.name) {
                            isExisted = true;
                            break;
                        }
                    }

                    if (!isExisted) {
                        allOutletsCategoriesUnique.push(record);
                    }
                }

                allOutletsCategoriesUnique.sort((a, b) => a.name.localeCompare(b.name));

                OutletStore.update(s => {
                    s.outletCategories = outletCategories;
                    s.outletCategoriesDict = outletCategoriesDict;

                    s.allOutletsCategories = allOutletsCategories;
                    s.allOutletsCategoriesNameDict = allOutletsCategoriesNameDict;
                    s.allOutletsCategoriesDict = allOutletsCategoriesDict;

                    s.allOutletsCategoriesUnique = allOutletsCategoriesUnique;
                });

                CommonStore.update(s => {
                    s.selectedOutletItemCategory = outletCategories[0];
                });
            }
        });

    firebase.firestore()
        .collection(Collections.PreorderPackage)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.PreorderPackage} changed!`);

            var preorderPackages = [];

            if (!snapshot.empty) {

                // var selectedOutletItemCategory = {};

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    preorderPackages.push(record);
                }

                preorderPackages.sort((a, b) => a.name.localeCompare(b.name));
            }

            OutletStore.update(s => {
                s.preorderPackages = preorderPackages;
            });
        });

    firebase.firestore()
        .collection(Collections.PointsRedeemPackage)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.PointsRedeemPackage} changed!`);

            var pointsRedeemPackages = [];
            var pointsRedeemPackagesDict = {};

            if (!snapshot.empty) {

                // var selectedOutletItemCategory = {};

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    pointsRedeemPackages.push(record);
                    pointsRedeemPackagesDict[record.uniqueId] = record;
                }

                pointsRedeemPackages.sort((a, b) => (a.packageName ? a.packageName : '').localeCompare((b.packageName ? b.packageName : '')));
            }

            OutletStore.update(s => {
                s.pointsRedeemPackages = pointsRedeemPackages;
                s.pointsRedeemPackagesDict = pointsRedeemPackagesDict;
            });
        });

    /////////////////////////////////////////

    firebase.firestore()
        .collection(Collections.Supplier)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.Supplier} changed!`);

            if (!snapshot.empty) {
                var suppliers = [];
                var suppliersDict = {};

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    suppliers.push(record);
                    suppliersDict[record.uniqueId] = record;
                }

                suppliers.sort((a, b) => b.updatedAt - a.updatedAt);

                CommonStore.update(s => {
                    s.suppliers = suppliers;
                    s.suppliersDict = suppliersDict;
                });
            }
        });

    firebase.firestore()
        .collection(Collections.SupplyItem)
        .where('merchantId', '==', merchantId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.SupplyItem} changed!`);

            var supplyItems = [];
            var supplyItemsDict = {};
            var supplyItemsSkuDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    supplyItems.push(record);
                    supplyItemsDict[record.uniqueId] = record;
                    supplyItemsSkuDict[record.sku] = record;
                }
            }

            CommonStore.update(s => {
                s.supplyItems = supplyItems;
                s.supplyItemsDict = supplyItemsDict;
                s.supplyItemsSkuDict = supplyItemsSkuDict;
            });
        });

    firebase.firestore()
        .collection(Collections.OutletSupplyItem)
        .where('outletId', '==', currOutletId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.OutletSupplyItem} changed!`);

            var outletSupplyItems = [];
            var outletSupplyItemsDict = {};
            var outletSupplyItemsSkuDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outletSupplyItems.push(record);
                    outletSupplyItemsDict[record.uniqueId] = record;
                    outletSupplyItemsSkuDict[record.sku] = record;
                }
            }

            CommonStore.update(s => {
                s.outletSupplyItems = outletSupplyItems;
                s.outletSupplyItemsDict = outletSupplyItemsDict;
                s.outletSupplyItemsSkuDict = outletSupplyItemsSkuDict;
            });
        });

    firebase.firestore()
        .collection(Collections.OutletSupplyItem)
        .where('merchantId', '==', merchantId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.OutletSupplyItem} changed!`);

            var allOutletsSupplyItems = [];
            var allOutletsSupplyItemsDict = {};
            var allOutletsSupplyItemsSkuDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    allOutletsSupplyItems.push(record);
                    allOutletsSupplyItemsDict[record.uniqueId] = record;

                    if (allOutletsSupplyItemsSkuDict[record.sku] === undefined) {
                        allOutletsSupplyItemsSkuDict[record.sku] = [record];
                    }
                    else {
                        allOutletsSupplyItemsSkuDict[record.sku].push(record);
                    }
                }
            }

            CommonStore.update(s => {
                s.allOutletsSupplyItems = allOutletsSupplyItems;
                s.allOutletsSupplyItemsDict = allOutletsSupplyItemsDict;
                s.allOutletsSupplyItemsSkuDict = allOutletsSupplyItemsSkuDict;
            });
        });

    firebase.firestore()
        .collection(Collections.StockTransfer)
        .where('merchantId', '==', merchantId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.StockTransfer} changed!`);

            var stockTransfers = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    stockTransfers.push(record);
                }
            }

            stockTransfers.sort((a, b) => b.updatedAt - a.updatedAt);

            CommonStore.update(s => {
                s.stockTransfers = stockTransfers;
            });
        });

    firebase.firestore()
        .collection(Collections.StockTake)
        .where('merchantId', '==', merchantId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.StockTake} changed!`);

            var stockTakes = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    stockTakes.push(record);
                }
            }

            stockTakes.sort((a, b) => b.updatedAt - a.updatedAt);

            CommonStore.update(s => {
                s.stockTakes = stockTakes;
            });
        });

    firebase.firestore()
        .collection(Collections.PurchaseOrder)
        .where('outletId', '==', currOutletId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.PurchaseOrder} changed!`);

            var purchaseOrders = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    purchaseOrders.push(record);
                }
            }

            purchaseOrders.sort((a, b) => b.updatedAt - a.updatedAt);

            CommonStore.update(s => {
                s.purchaseOrders = purchaseOrders;
            });
        });

    firebase.firestore()
        .collection(Collections.OutletShift)
        .where('outletId', '==', currOutletId)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.OutletShift} changed!`);

            var currOutletShift = {};
            var currOutletShiftStatus = OUTLET_SHIFT_STATUS.CLOSED;

            if (snapshot && !snapshot.empty) {
                currOutletShift = snapshot.docs[0].data();

                if (currOutletShift.closeDate === null) {
                    currOutletShiftStatus = OUTLET_SHIFT_STATUS.OPENED;
                }
                else {
                    currOutletShiftStatus = OUTLET_SHIFT_STATUS.CLOSED;
                }
            }

            OutletStore.update(s => {
                s.currOutletShift = currOutletShift;
                s.currOutletShiftStatus = currOutletShiftStatus;
            });
        });

    firebase.firestore()
        .collection(Collections.OutletShift)
        .where('merchantId', '==', merchantId)
        .orderBy('createdAt', 'desc')
        .onSnapshot(async snapshot => {
            console.log(`${Collections.OutletShift} changed!`);

            var allOutletShifts = [];

            if (snapshot && !snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    allOutletShifts.push(record);
                }
            }

            OutletStore.update(s => {
                s.allOutletShifts = allOutletShifts;
            });
        });

    firebase.firestore()
        .collection(Collections.BeerDocketCategory)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.BeerDocketCategory} changed!`);

            var beerDocketCategories = [];
            var beerDocketCategoriesDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    beerDocketCategories.push(record);
                    beerDocketCategoriesDict[record.uniqueId] = record;
                }
            }

            OutletStore.update(s => {
                s.beerDocketCategories = beerDocketCategories;
                s.beerDocketCategoriesDict = beerDocketCategoriesDict;
            });
        });

    firebase.firestore()
        .collection(Collections.BeerDocket)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.BeerDocket} changed!`);

            var beerDockets = [];
            var beerDocketsDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    beerDockets.push(record);
                    beerDocketsDict[record.uniqueId] = record;
                }
            }

            OutletStore.update(s => {
                s.beerDockets = beerDockets;
                s.beerDocketsDict = beerDocketsDict;
            });
        });

    firebase.firestore()
        .collection(Collections.UserBeerDocket)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserBeerDocket} changed!`);

            var userBeerDockets = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    userBeerDockets.push(record);
                }
            }

            OutletStore.update(s => {
                s.userBeerDockets = userBeerDockets;
            });
        });

    firebase.firestore()
        .collection(Collections.UserOrderBeerDocket)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserOrderBeerDocket} changed!`);

            var userOrderBeerDocketUBDIdDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    userOrderBeerDocketUBDIdDict[record.bdCartItems[0].userBeerDocketId] = record;
                }
            }

            OutletStore.update(s => {
                s.userOrderBeerDocketUBDIdDict = userOrderBeerDocketUBDIdDict;
            });
        });

    firebase.firestore()
        .collection(Collections.Promotion)
        .where('merchantId', '==', merchantId)
        .where('deletedAt', '==', null)
        .onSnapshot(snapshot => {
            console.log(`${Collections.Promotion} changed!`);

            var promotions = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    promotions.push(record);
                }
            }

            //promotions.sort((a, b) => b.updatedAt - a.updatedAt);
            promotions.sort((a, b) => b.createAt - a.createAt);

            OutletStore.update(s => {
                s.promotions = promotions;
            });
        });

    firebase.firestore()
        .collection(Collections.LoyaltyCampaign)
        .where('merchantId', '==', merchantId)
        .where('deletedAt', '==', null)
        .onSnapshot(snapshot => {
            console.log(`${Collections.LoyaltyCampaign} changed!`);

            var loyaltyCampaigns = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    loyaltyCampaigns.push(record);
                }
            }

            //loyaltyCampaigns.sort((a, b) => b.updatedAt - a.updatedAt);
            loyaltyCampaigns.sort((a, b) => b.createAt - a.createAt);

            OutletStore.update(s => {
                s.loyaltyCampaigns = loyaltyCampaigns;
            });
        });

    firebase.firestore()
        .collection(Collections.User)
        .where('favoriteMerchantIdList', 'array-contains', merchantId)
        .where('deletedAt', '==', null)
        .onSnapshot(snapshot => {
            console.log(`${Collections.User} changed!`);

            var favoriteMerchantIdUsers = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    favoriteMerchantIdUsers.push(record);
                }
            }

            OutletStore.update(s => {
                s.favoriteMerchantIdUsers = favoriteMerchantIdUsers;
            });
        });

    firebase.firestore()
        .collection(Collections.User)
        .where('linkedMerchantIdList', 'array-contains', merchantId)
        .where('deletedAt', '==', null)
        .onSnapshot(snapshot => {
            console.log(`${Collections.User} changed!`);

            var linkedMerchantIdUsers = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    linkedMerchantIdUsers.push(record);
                }
            }

            OutletStore.update(s => {
                s.linkedMerchantIdUsers = linkedMerchantIdUsers;
            });
        });

    // need change to more cost-effective
    // firebase.firestore()
    //     .collection(Collections.UserFavoriteOutlet)
    //     .where('linkedMerchantIdList', 'array-contains', merchantId)
    //     .where('deletedAt', '==', null)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.User} changed!`);

    //         var linkedMerchantIdUsers = [];

    //         if (!snapshot.empty) {
    //             for (var i = 0; i < snapshot.size; i++) {
    //                 const record = snapshot.docs[i].data();

    //                 linkedMerchantIdUsers.push(record);
    //             }
    //         }

    //         OutletStore.update(s => {
    //             s.linkedMerchantIdUsers = linkedMerchantIdUsers;
    //         });
    //     });

    firebase.firestore()
        .collection(Collections.CRMUser)
        .where('merchantId', '==', merchantId)
        .where('deletedAt', '==', null)
        .onSnapshot(snapshot => {
            console.log(`${Collections.CRMUser} changed!`);

            var crmUsersRaw = [];
            //var crmUsersDict = {};

            if (!snapshot.empty) {
                var crmUsersDict = {};

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    crmUsersRaw.push(record);
                    crmUsersDict[record.uniqueId] = record;
                }
            }

            OutletStore.update(s => {
                s.crmUsersRaw = crmUsersRaw;
                s.crmUsersDict = crmUsersDict;
            });
        });

    firebase.firestore()
        .collection(Collections.CRMUserTag)
        .where('merchantId', '==', merchantId)
        .where('deletedAt', '==', null)
        .onSnapshot(snapshot => {
            console.log(`${Collections.CRMUserTag} changed!`);

            var crmUserTags = [];
            var crmUserTagsDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    crmUserTags.push(record);
                    crmUserTagsDict[record.uniqueId] = record;
                }
            }

            crmUserTags.sort((a, b) => a.name.localeCompare(b.name));

            OutletStore.update(s => {
                s.crmUserTags = crmUserTags;
                s.crmUserTagsDict = crmUserTagsDict;
            });
        });

    firebase.firestore()
        .collection(Collections.CRMSegment)
        .where('merchantId', '==', merchantId)
        .where('deletedAt', '==', null)
        .onSnapshot(snapshot => {
            console.log(`${Collections.CRMSegment} changed!`);

            var crmSegments = [];
            var crmSegmentsDict = {};

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    crmSegments.push(record);
                    crmSegmentsDict[record.uniqueId] = record;
                }
            }

            crmSegments.sort((a, b) => a.name.localeCompare(b.name));

            OutletStore.update(s => {
                s.crmSegments = crmSegments;
                s.crmSegmentsDict = crmSegmentsDict;
            });
        });

    firebase.firestore()
        .collection(Collections.OutletPrinter)
        .where('outletId', '==', currOutletId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.OutletPrinter} changed!`);

            var outletPrinters = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outletPrinters.push(record);
                }
            }

            OutletStore.update(s => {
                s.outletPrinters = outletPrinters;
            });
        });

    firebase.firestore()
        .collection(Collections.OutletPaymentMethod)
        .where('merchantId', '==', merchantId)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.OutletPaymentMethod} changed!`);

            var outletPaymentMethods = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outletPaymentMethods.push(record);
                }
            }

            OutletStore.update(s => {
                s.outletPaymentMethods = outletPaymentMethods;
            });
        });

    firebase.firestore()
        .collection(Collections.LoyaltyStamp)
        .where('merchantId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.LoyaltyStamp} changed!`);

            var loyaltyStamps = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    loyaltyStamps.push(record);
                }
            }

            OutletStore.update(s => {
                s.loyaltyStamps = loyaltyStamps;
            });
        });
};

export const listenToAllOutletsChangesMerchant = async (allOutlets) => {
    // var batchIndex = 0;
    // var batchLength = 10;

    // var promisesOutletTax = [];
    // var promisesUserOrder = [];

    // var batchOutletIdList = allOutlets.slice(batchIndex, batchLength).map(outlet => outlet.uniqueId);

    // while (batchOutletIdList.length > 0) {
    //     console.log(batchOutletIdList);

    //     promisesOutletTax.push(new Promise(async (resolve, reject) => {
    //         firebase.firestore()
    //             .collection(Collections.OutletTax)
    //             .where('outletId', 'in', batchOutletIdList)
    //             .onSnapshot(snapshot => {
    //                 console.log(`${Collections.OutletTax} changed!`);

    //                 if (!snapshot.empty) {
    //                     var outletsTaxDict = {};

    //                     for (var i = 0; i < snapshot.size; i++) {
    //                         const record = snapshot.docs[i].data();

    //                         outletsTaxDict[record.outletId] = record;
    //                     }

    //                     resolve(outletsTaxDict);
    //                 }
    //                 else {
    //                     reject({});
    //                 }
    //             });
    //     }));

    //     promisesUserOrder.push(new Promise(async (resolve, reject) => {
    //         firebase.firestore()
    //             .collection(Collections.UserOrder)
    //             .where('outletId', 'in', batchOutletIdList)
    //             .where('orderStatus', '==', USER_ORDER_STATUS.ORDER_COMPLETED)
    //             // .orderBy('createdAt') // invalid query, need fix this in future
    //             .onSnapshot(snapshot => {
    //                 console.log(`${Collections.UserOrder} changed!`);

    //                 if (snapshot && !snapshot.empty) {
    //                     var userOrders = [];

    //                     for (var i = 0; i < snapshot.size; i++) {
    //                         const record = snapshot.docs[i].data();

    //                         userOrders.push(record);
    //                     }

    //                     resolve(userOrders);
    //                 }
    //                 else {
    //                     reject([]);
    //                 }
    //             });
    //     }));

    //     batchIndex++;
    //     batchOutletIdList = allOutlets.slice(batchIndex * batchLength, (batchIndex * batchLength) + batchLength).map(outlet => outlet.uniqueId);
    // }

    // await Promise.all(promisesOutletTax).then(result => {
    //     console.log('promise outlet tax');
    //     console.log(result);

    //     if (result) {
    //         CommonStore.update(s => {
    //             s.outletsTaxDict = result.reduce((objDict, obj) => {
    //                 for (var key in obj) {
    //                     objDict[key] = obj[key];
    //                 }

    //                 return objDict;
    //             });
    //         });
    //     }
    // });

    // await Promise.all(promisesUserOrder).then(result => {
    //     console.log('promise user order');
    //     console.log(result);

    //     if (result) {
    //         OutletStore.update(s => {
    //             s.allOutletsUserOrdersDone = result.reduce((allArr, arr) => allArr.concat(arr));
    //         });
    //     }
    // });
};

export const listenToSelectedCustomerChangesMerchant = (customer) => {
    CommonStore.update(s => {
        s.isLoading = true;
    });

    if (customer.firebaseUid) {
        firebase.firestore()
            .collection(Collections.UserOrder)
            .where('userId', '==', customer.firebaseUid)
            // .where('orderType', '==', ORDER_TYPE.DINEIN)
            .where('deletedAt', '==', null)
            .onSnapshot(snapshot => {
                console.log(`${Collections.UserOrder} changed!`);

                var selectedCustomerOrders = [];
                var selectedCustomerDineInOrders = [];

                if (!snapshot.empty) {
                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        if (record.orderStatus !== USER_ORDER_STATUS.ORDER_CANCELLED_BY_MERCHANT &&
                            record.orderStatus !== USER_ORDER_STATUS.ORDER_CANCELLED_BY_USER) {
                            if (record.orderType === ORDER_TYPE.DINEIN) {
                                selectedCustomerDineInOrders.push(record);
                            }

                            selectedCustomerOrders.push(record);
                        }
                    }
                }

                selectedCustomerDineInOrders.sort((a, b) => b.updateAt - a.updateAt);
                selectedCustomerOrders.sort((a, b) => b.orderDate - a.orderDate);

                OutletStore.update(s => {
                    s.selectedCustomerDineInOrders = selectedCustomerDineInOrders;
                    s.selectedCustomerOrders = selectedCustomerOrders;
                });

                CommonStore.update(s => {
                    s.isLoading = false;
                });
            });

        firebase.firestore()
            .collection(Collections.UserAddress)
            .where('userId', '==', customer.firebaseUid)
            .where('deletedAt', '==', null)
            .limit(1)
            .onSnapshot(snapshot => {
                console.log(`${Collections.UserAddress} changed!`);

                var selectedCustomerAddresses = [];

                if (!snapshot.empty) {
                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        selectedCustomerAddresses.push(record);
                    }
                }

                OutletStore.update(s => {
                    s.selectedCustomerAddresses = selectedCustomerAddresses;
                });
            });

        firebase.firestore()
            .collection(Collections.UserPointsTransaction)
            .where('userId', '==', customer.firebaseUid)
            .where('deletedAt', '==', null)
            .onSnapshot(snapshot => {
                console.log(`${Collections.UserPointsTransaction} changed!`);

                var selectedCustomerPointsTransactions = [];
                var selectedCustomerPointsBalance = 0;

                if (!snapshot.empty) {
                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        selectedCustomerPointsTransactions.push(record);
                        selectedCustomerPointsBalance += record.amount;
                    }
                }

                selectedCustomerPointsTransactions.sort((a, b) => b.createdAt - a.createdAt);

                OutletStore.update(s => {
                    s.selectedCustomerPointsTransactions = selectedCustomerPointsTransactions;
                    s.selectedCustomerPointsBalance = selectedCustomerPointsBalance;
                });
            });

        firebase.firestore()
            .collection(Collections.UserVoucherRedemption)
            .where('userId', '==', customer.firebaseUid)
            .where('deletedAt', '==', null)
            .limit(1)
            .onSnapshot(snapshot => {
                console.log(`${Collections.UserAddress} changed!`);

                var selectedCustomerVoucherRedemptions = [];

                if (!snapshot.empty) {
                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        selectedCustomerVoucherRedemptions = record.redemptions;
                    }
                }

                OutletStore.update(s => {
                    s.selectedCustomerVoucherRedemptions = selectedCustomerVoucherRedemptions;
                });
            });

        firebase.firestore()
            .collection(Collections.UserBeerDocket)
            .where('userId', '==', customer.firebaseUid)
            .where('deletedAt', '==', null)
            .onSnapshot(snapshot => {
                console.log(`${Collections.UserBeerDocket} changed!`);

                var selectedCustomerUserBeerDockets = [];

                if (!snapshot.empty) {
                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        selectedCustomerUserBeerDockets.push(record);
                    }
                }

                selectedCustomerUserBeerDockets.sort((a, b) => b.createdAt - a.createdAt);

                OutletStore.update(s => {
                    s.selectedCustomerUserBeerDockets = selectedCustomerUserBeerDockets;
                });
            });
    }
    else {
        firebase.firestore()
            .collection(Collections.UserOrder)
            .where('userPhone', '==', customer.number)
            // .where('orderType', '==', ORDER_TYPE.DINEIN)
            .where('deletedAt', '==', null)
            .onSnapshot(snapshot => {
                console.log(`${Collections.UserOrder} changed!`);

                var selectedCustomerOrders = [];
                var selectedCustomerDineInOrders = [];

                if (!snapshot.empty) {
                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        if (record.orderStatus !== USER_ORDER_STATUS.ORDER_CANCELLED_BY_MERCHANT &&
                            record.orderStatus !== USER_ORDER_STATUS.ORDER_CANCELLED_BY_USER) {
                            if (record.orderType === ORDER_TYPE.DINEIN) {
                                selectedCustomerDineInOrders.push(record);
                            }

                            selectedCustomerOrders.push(record);
                        }
                    }
                }

                selectedCustomerDineInOrders.sort((a, b) => b.updateAt - a.updateAt);
                selectedCustomerOrders.sort((a, b) => b.orderDate - a.orderDate);

                OutletStore.update(s => {
                    s.selectedCustomerDineInOrders = selectedCustomerDineInOrders;
                    s.selectedCustomerOrders = selectedCustomerOrders;
                });

                CommonStore.update(s => {
                    s.isLoading = false;
                });
            });
    }

    firebase.firestore()
        .collection(Collections.LoyaltyCampaignCreditTransaction)
        .where('email', '==', customer.email)
        .where('deletedAt', '==', null)
        .onSnapshot(snapshot => {
            console.log(`${Collections.LoyaltyCampaignCreditTransaction} changed!`);

            var selectedCustomerLCCTransactions = [];
            var selectedCustomerLCCBalance = 0;

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    selectedCustomerLCCTransactions.push(record);
                    selectedCustomerLCCBalance += record.amount;
                }
            }

            selectedCustomerLCCTransactions.sort((a, b) => b.createdAt - a.createdAt);

            OutletStore.update(s => {
                s.selectedCustomerLCCTransactions = selectedCustomerLCCTransactions;
                s.selectedCustomerLCCBalance = selectedCustomerLCCBalance;
            });
        });
};

// export const listenToSelectedOrderToPayUserIdChanges = (selectedOrderToPayUserId) => {
//     firebase.firestore()
//         .collection(Collections.UserVoucherRedemption)
//         // .where('startAt', '<=', moment().valueOf())
//         // .where('endAt', '>', moment().valueOf())
//         .where('userId', '==', selectedOrderToPayUserId)
//         .onSnapshot(async snapshot => {
//             console.log(`${Collections.UserVoucherRedemption} changed!`);

//             var voucherIdRedemptionList = [];

//             if (!snapshot.empty) {
//                 const userVoucherRedemptionSnapshot = await firebase.firestore().collection(Collections.UserVoucherRedemption)
//                     .where('userId', '==', firebaseUid)
//                     .limit(1)
//                     .get();

//                 if (!userVoucherRedemptionSnapshot.empty) {
//                     const userVoucherRedemption = userVoucherRedemptionSnapshot.docs[0].data();

//                     voucherIdRedemptionList = userVoucherRedemption.redemptions;
//                 }
//             }

//             CommonStore.update(s => {
//                 s.selectedOrderToPayUserIdVoucherIdRedemptionList = voucherIdRedemptionList;
//             });
//         });
// };

export const listenToCommonChangesMerchant = () => {
    firebase.firestore()
        .collection(Collections.Merchant)
        // .where('isRealOutlet', '==', true)
        .onSnapshot(snapshot => {
            console.log(`${Collections.Merchant} changed!`);

            var merchantsOnboarded = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    merchantsOnboarded.push(record);
                }
            }

            CommonStore.update(s => {
                s.merchantsOnboarded = merchantsOnboarded;
            });
        });

    firebase.firestore()
        .collection(Collections.Outlet)
        .where('realMerchant', '==', true)
        .onSnapshot(snapshot => {
            console.log(`${Collections.Outlet} changed!`);

            var outletsOnboarded = [];

            if (!snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outletsOnboarded.push(record);
                }
            }

            CommonStore.update(s => {
                s.outletsOnboarded = outletsOnboarded;
            });
        });

    // firebase.firestore()
    //     .collection(Collections.EmployeeClock)
    //     // .where('realMerchant', '==', true)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.EmployeeClock} changed!`);

    //         var employeeClocks = [];

    //         if (!snapshot.empty) {
    //             for (var i = 0; i < snapshot.size; i++) {
    //                 const record = snapshot.docs[i].data();

    //                 employeeClocks.push(record);
    //             }
    //         }

    //         employeeClocks.sort((a, b) => b.updatedAt - a.updatedAt);

    //         CommonStore.update(s => {
    //             s.employeeClocks = employeeClocks;
    //         });
    //     });

    // firebase.firestore()
    //     .collection(Collections.UserAction)
    //     // .where('realMerchant', '==', true)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.UserAction} changed!`);

    //         var userActions = [];

    //         if (!snapshot.empty) {
    //             for (var i = 0; i < snapshot.size; i++) {
    //                 const record = snapshot.docs[i].data();

    //                 userActions.push(record);
    //             }
    //         }

    //         userActions.sort((a, b) => b.updatedAt - a.updatedAt);

    //         CommonStore.update(s => {
    //             s.userActions = userActions;
    //         });
    //     });

    // firebase.firestore()
    //     .collection(Collections.Segment)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.Segment} changed!`);

    //         if (!snapshot.empty) {
    //             var segments = [];

    //             for (var i = 0; i < snapshot.size; i++) {
    //                 const record = snapshot.docs[i].data();

    //                 segments.push(record);
    //             }

    //             CommonStore.update(s => {
    //                 s.segments = segments;
    //             });
    //         }
    //     });

    // firebase.firestore()
    //     .collection(Collections.Accumulator)
    //     .where('uniqueId', '==', ACCUMULATOR_ID.GLOBAL)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.Accumulator} changed!`);

    //         if (!snapshot.empty) {
    //             const record = snapshot.docs[0].data();

    //             CommonStore.update(s => {
    //                 s.accumulator = record;
    //             });
    //         }
    //     });
};

////////////////////////////////////////////////////////////////////////////////////

export const listenToUserChangesWaiter = (firebaseUid) => {
    // const firebaseUid = await AsyncStorage.getItem('firebaseUid');
    // const merchantId = await AsyncStorage.getItem('merchantId');
    // const role = await AsyncStorage.getItem('role');

    // console.log(firebaseUid);
    // console.log(merchantId);
    // console.log(role);

    var merchantId = '';

    // Get user details
    firebase.firestore()
        .collection(Collections.User)
        .where('firebaseUid', '==', firebaseUid)
        .onSnapshot(snapshot => {
            console.log(`${Collections.User} changed!`);

            if (!snapshot.empty) {
                const record = snapshot.docs[0].data();

                merchantId = record.merchantId;

                UserStore.update(s => {
                    s.avatar = record.avatar;
                    s.dob = record.dob;
                    s.email = record.email;
                    s.gender = record.gender;
                    s.name = record.name;
                    s.number = record.number;
                    s.outletId = record.outledId;
                    s.race = record.race;
                    s.state = record.state;
                    s.uniqueName = record.uniqueName;
                    s.updatedAt = record.updatedAt;

                    // s.role = record.role;
                    s.merchantId = record.merchantId;
                })

                MerchantStore.update(s => {
                    s.currOutletId = record.outletId;
                });
            }
        });

    // Get merchant info
    firebase.firestore()
        .collection(Collections.Merchant)
        .where('uniqueId', '==', merchantId)
        .onSnapshot(snapshot => {
            console.log(`${Collections.Merchant} changed!`);

            if (!snapshot.empty) {
                const record = snapshot.docs[0].data();

                MerchantStore.update(s => {
                    s.description = record.description;
                    s.name = record.name;
                    s.shortcode = record.shortcode;
                    s.logo = record.logo;

                    s.poNumber = record.poNumber;
                    s.poNumberUpdatedAt = record.poNumberUpdatedAt;
                });
            }
        });
};

export const listenToCurrOutletIdChangesWaiter = async (role, currOutletId, isWaiterApp = false) => {
    // Get outlets info

    // if (role !== ROLE_TYPE.STORE_MANAGER && role !== ROLE_TYPE.ADMIN && role !== ROLE_TYPE.LEGACY) {
    if (isWaiterApp) {
        firebase.firestore()
            .collection(Collections.Outlet)
            .where('uniqueId', '==', currOutletId)
            .limit(1)
            .onSnapshot(snapshot => {
                console.log(`${Collections.Outlet} changed!`);

                var allOutlets = [];
                var currOutlet = {};

                if (!snapshot.empty) {


                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        allOutlets.push(record);
                    }

                    currOutlet = allOutlets[0];

                    MerchantStore.update(s => {
                        s.allOutlets = allOutlets;
                        s.currOutletId = currOutlet.uniqueId;
                        s.currOutlet = currOutlet;
                    });
                }
            });

        firebase.firestore()
            .collection(Collections.OutletItem)
            .where('outletId', '==', currOutletId)
            .onSnapshot(snapshot => {
                console.log(`${Collections.OutletItem} changed!`);

                var outletItems = [];
                var outletItemsDict = {};

                if (!snapshot.empty) {

                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        outletItems.push(record);
                        outletItemsDict[record.uniqueId] = record;
                    }
                }

                OutletStore.update(s => {
                    s.outletItems = outletItems;
                    s.outletItemsDict = outletItemsDict;
                });
            });

        firebase.firestore()
            .collection(Collections.OutletItemCategory)
            .where('outletId', '==', currOutletId)
            .onSnapshot(snapshot => {
                console.log(`${Collections.OutletItemCategory} changed!`);

                var outletCategories = [];
                var outletCategoriesDict = {};

                if (!snapshot.empty) {

                    // var selectedOutletItemCategory = {};

                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        outletCategories.push(record);
                        outletCategoriesDict[record.uniqueId] = record;
                    }

                    OutletStore.update(s => {
                        s.outletCategories = outletCategories;
                        s.outletCategoriesDict = outletCategoriesDict;
                    });

                    CommonStore.update(s => {
                        s.selectedOutletItemCategory = outletCategories[0];
                    });
                }
            });

        firebase.firestore()
            .collection(Collections.OutletTax)
            .where('outletId', '==', currOutletId)
            .limit(1)
            .onSnapshot(snapshot => {
                console.log(`${Collections.OutletTax} changed!`);

                var outletsTaxDict = {};

                if (!snapshot.empty) {

                    for (var i = 0; i < snapshot.size; i++) {
                        const record = snapshot.docs[i].data();

                        outletsTaxDict[record.outletId] = record;
                    }

                    CommonStore.update(s => {
                        s.outletsTaxDict = outletsTaxDict;
                    });
                }
            });
    }

    firebase.firestore()
        .collection(Collections.OutletSection)
        .where('outletId', '==', currOutletId)
        .orderBy('createdAt')
        .onSnapshot(snapshot => {
            console.log(`${Collections.OutletSection} changed!`);

            var outletSections = [];
            var outletSectionsDict = {};

            if (snapshot && !snapshot.empty) {


                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outletSections.push(record);
                    outletSectionsDict[record.uniqueId] = record;
                }

                console.log('outletSections');
                console.log(outletSections);
                console.log('outletSectionsDict');
                console.log(outletSectionsDict);
            }

            OutletStore.update(s => {
                s.outletSections = outletSections;
                s.outletSectionsDict = outletSectionsDict;
            });
        });

    firebase.firestore()
        .collection(Collections.OutletTable)
        .where('outletId', '==', currOutletId)
        .orderBy('createdAt')
        .onSnapshot(snapshot => {
            console.log(`${Collections.OutletTable} changed!`);

            var outletTables = [];
            var outletTablesDict = {};
            var outletTablesCodeDict = {};

            if (snapshot && !snapshot.empty) {

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    outletTables.push(record);
                    outletTablesDict[record.uniqueId] = record;
                    outletTablesCodeDict[record.code] = record;
                }

                console.log('outletTables');
                console.log(outletTables);
                console.log('outletTablesDict');
                console.log(outletTablesDict);
            }

            OutletStore.update(s => {
                s.outletTables = outletTables;
                s.outletTablesDict = outletTablesDict;
                s.outletTablesCodeDict = outletTablesCodeDict;
            });
        });

    // Get current active orders, include completed
    firebase.firestore()
        .collection(Collections.UserOrder)
        .where('outletId', '==', currOutletId)
        // .where('orderStatus', '!=', USER_ORDER_STATUS.ORDER_COMPLETED)
        // .orderBy('createdAt') // invalid query, need fix this in future
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserOrder} changed!`);

            // var userOrders = [];
            // var userOrdersDict = {};
            // var userOrdersTableDict = {};

            var userOrdersAllStatus = [];

            if (snapshot && !snapshot.empty) {

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    // currently filtered out cancelled orders
                    // if (record.orderStatus !== USER_ORDER_STATUS.ORDER_COMPLETED &&
                    //     record.orderStatus !== USER_ORDER_STATUS.ORDER_CANCELLED_BY_MERCHANT &&
                    //     record.orderStatus !== USER_ORDER_STATUS.ORDER_CANCELLED_BY_USER) {
                    //     userOrders.push(record);
                    //     userOrdersDict[record.uniqueId] = record;

                    //     if (record.tableId && record.tableId.length > 0) {
                    //         if (userOrdersTableDict[record.tableId] === undefined) {
                    //             userOrdersTableDict[record.tableId] = [record];
                    //         }
                    //         else {
                    //             userOrdersTableDict[record.tableId] = [
                    //                 ...userOrdersTableDict[record.tableId],
                    //                 record,
                    //             ];
                    //         }
                    //     }
                    // }

                    userOrdersAllStatus.push(record);
                }

                // console.log('userOrders');
                // console.log(userOrders);
                // console.log('userOrdersTableDict');
                // console.log(userOrdersTableDict);
            }

            userOrdersAllStatus.sort((a, b) => b.updatedAt - a.updatedAt);

            OutletStore.update(s => {
                // s.userOrders = userOrders;
                // s.userOrdersDict = userOrdersDict;
                // s.userOrdersTableDict = userOrdersTableDict;

                s.userOrdersAllStatus = userOrdersAllStatus;
            });
        });

    // Get current active orders only
    firebase.firestore()
        .collection(Collections.UserOrder)
        .where('outletId', '==', currOutletId)
        .where('orderStatus', '!=', USER_ORDER_STATUS.ORDER_COMPLETED)
        // .orderBy('createdAt') // invalid query, need fix this in future
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserOrder} changed!`);

            var userOrders = [];
            var userOrdersDict = {};
            var userOrdersTableDict = {};

            if (snapshot && !snapshot.empty) {

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    // currently filtered out cancelled orders
                    if (record.orderStatus !== USER_ORDER_STATUS.ORDER_CANCELLED_BY_MERCHANT &&
                        record.orderStatus !== USER_ORDER_STATUS.ORDER_CANCELLED_BY_USER) {
                        userOrders.push(record);
                        userOrdersDict[record.uniqueId] = record;

                        if (record.tableId && record.tableId.length > 0) {
                            if (userOrdersTableDict[record.tableId] === undefined) {
                                userOrdersTableDict[record.tableId] = [record];
                            }
                            else {
                                userOrdersTableDict[record.tableId] = [
                                    ...userOrdersTableDict[record.tableId],
                                    record,
                                ];
                            }
                        }
                    }
                }
            }

            OutletStore.update(s => {
                s.userOrders = userOrders;
                s.userOrdersDict = userOrdersDict;
                s.userOrdersTableDict = userOrdersTableDict;
            });
        });

    firebase.firestore()
        .collection(Collections.UserReservation)
        .where('outletId', '==', currOutletId)
        // .where('status', '!=', USER_RESERVATION_STATUS.SEATED)
        // .where('status', '!=', USER_RESERVATION_STATUS.SEATED)
        // .orderBy('createdAt')
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserReservation} changed!`);

            var userReservations = [];
            var userReservationsDict = {};
            var userReservationsUserIdDict = {};

            if (snapshot && !snapshot.empty) {

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    // if (record.status !== USER_RESERVATION_STATUS.CANCELED) {
                    //     userReservations.push(record);
                    //     userReservationsDict[record.uniqueId] = record;

                    //     if (userReservationsUserIdDict[record.userId]) {
                    //         userReservationsUserIdDict[record.userId].push(record);
                    //     }
                    //     else {
                    //         userReservationsUserIdDict[record.userId] = [
                    //             record,
                    //         ];
                    //     }
                    // }

                    userReservations.push(record);
                    userReservationsDict[record.uniqueId] = record;

                    if (userReservationsUserIdDict[record.userId]) {
                        userReservationsUserIdDict[record.userId].push(record);
                    }
                    else {
                        userReservationsUserIdDict[record.userId] = [
                            record,
                        ];
                    }
                }

                console.log('userReservations');
                console.log(userReservations);
                console.log('userReservationsDict');
                console.log(userReservationsDict);
            }

            userReservations.sort((a, b) => b.reservationTime - a.reservationTime);

            OutletStore.update(s => {
                s.userReservations = userReservations;
                s.userReservationsDict = userReservationsDict;
                s.userReservationsUserIdDict = userReservationsUserIdDict;
            });
        });

    firebase.firestore()
        .collection(Collections.UserQueue)
        .where('outletId', '==', currOutletId)
        // .where('status', '!=', USER_QUEUE_STATUS.SERVED)
        // .where('status', '!=', USER_QUEUE_STATUS.SEATED)
        // .orderBy('createdAt')
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserQueue} changed!`);

            var userQueues = [];
            var userQueuesDict = {};

            if (snapshot && !snapshot.empty) {
                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    // if (record.status !== USER_QUEUE_STATUS.CANCELED &&
                    //     record.status !== USER_QUEUE_STATUS.NO_SHOW) {
                    //     userQueues.push(record);
                    //     userQueuesDict[record.uniqueId] = record;
                    // }

                    userQueues.push(record);
                    userQueuesDict[record.uniqueId] = record;
                }

                console.log('userQueues');
                console.log(userQueues);
                console.log('userQueuesDict');
                console.log(userQueuesDict);
            }

            userQueues.sort((a, b) => a.createdAt - b.createdAt);

            OutletStore.update(s => {
                s.userQueues = userQueues;
                s.userQueuesDict = userQueuesDict;
            });
        });

    firebase.firestore()
        .collection(Collections.UserRing)
        .where('outletId', '==', currOutletId)
        // .where('status', '!=', USER_RING_STATUS.CANCELED)
        // .where('status', '!=', USER_RING_STATUS.ATTENDED)
        // .orderBy('createdAt')
        .onSnapshot(snapshot => {
            console.log(`${Collections.UserRing} changed!`);

            var userRings = [];
            var userRingsDict = {};

            if (snapshot && !snapshot.empty) {

                for (var i = 0; i < snapshot.size; i++) {
                    const record = snapshot.docs[i].data();

                    userRings.push(record);
                    userRingsDict[record.uniqueId] = record;
                }

                console.log('userRings');
                console.log(userRings);
                console.log('userRingsDict');
                console.log(userRingsDict);

            }

            userRings.sort((a, b) => b.updatedAt - a.updatedAt);

            OutletStore.update(s => {
                s.userRings = userRings;
                s.userRingsDict = userRingsDict;
            });
        });

    // firebase.firestore()
    //     .collection(Collections.OutletItem)
    //     .where('outletId', '==', currOutletId)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.OutletItem} changed!`);

    //         var selectedOutletItems = [];

    //         if (snapshot && !snapshot.empty) {
    //             for (var i = 0; i < snapshot.size; i++) {
    //                 const record = snapshot.docs[i].data();

    //                 selectedOutletItems.push(record);
    //             }
    //         }

    //         CommonStore.update(s => {
    //             s.selectedOutletItems = selectedOutletItems;
    //         });
    //     });

    // firebase.firestore()
    //     .collection(Collections.OutletItemCategory)
    //     .where('outletId', '==', currOutletId)
    //     .onSnapshot(snapshot => {
    //         console.log(`${Collections.OutletItemCategory} changed!`);

    //         var selectedOutletItemCategories = [];
    //         var selectedOutletItemCategory = {};

    //         if (snapshot && !snapshot.empty) {
    //             for (var i = 0; i < snapshot.size; i++) {
    //                 const record = snapshot.docs[i].data();

    //                 selectedOutletItemCategories.push(record);
    //             }

    //             selectedOutletItemCategory = selectedOutletItemCategories[0];
    //         }

    //         CommonStore.update(s => {
    //             s.selectedOutletItemCategories = selectedOutletItemCategories;
    //             s.selectedOutletItemCategory = selectedOutletItemCategory;
    //         });
    //     });

    /////////////////////////////////////////////////////
    // Share from user app

    // disabled, as not in snapshot
    // var selectedOutletItems = [];
    // var selectedOutletItemCategories = [];
    // var selectedOutletItemCategory = {};

    // const outletItemSnapshot = await firebase.firestore().collection(Collections.OutletItem)
    //     .where('outletId', '==', currOutletId)
    //     .get();

    // if (!outletItemSnapshot.empty) {
    //     for (var i = 0; i < outletItemSnapshot.size; i++) {
    //         selectedOutletItems.push(outletItemSnapshot.docs[i].data());
    //     }
    // }

    // const outletItemCategorySnapshot = await firebase.firestore().collection(Collections.OutletItemCategory)
    //     .where('outletId', '==', currOutletId)
    //     .get();

    // if (!outletItemCategorySnapshot.empty) {
    //     for (var i = 0; i < outletItemCategorySnapshot.size; i++) {
    //         selectedOutletItemCategories.push(outletItemCategorySnapshot.docs[i].data());
    //     }

    //     selectedOutletItemCategory = selectedOutletItemCategories[0];
    // }

    // CommonStore.update(s => {
    //     s.selectedOutletItems = selectedOutletItems;
    //     s.selectedOutletItemCategories = selectedOutletItemCategories;
    //     s.selectedOutletItemCategory = selectedOutletItemCategory;
    // });
};

// Share from user app
export const listenToSelectedOutletItemChanges = async (item) => {
    // var outletsItemAddOnDict = {};
    // var outletsItemAddOnChoiceDict = {};

    // CommonStore.update(s => {
    //     // outletsItemAddOnDict = {
    //     //     ...s.outletsItemAddOnDict,
    //     // };

    //     // outletsItemAddOnChoiceDict = {
    //     //     ...s.outletsItemAddOnChoiceDict,
    //     // };

    //     s.isLoading = true;
    // });

    // if (outletsItemAddOnDict[item.uniqueId] === undefined) {
    //     console.log('item');
    //     console.log(item);

    //     const outletItemAddOnSnapshot = await firebase.firestore().collection(Collections.OutletItemAddOn)
    //         .where('outletItemId', '==', item.uniqueId)
    //         .get();

    //     if (!outletItemAddOnSnapshot.empty) {
    //         var tempOutletItemAddOnList = [];

    //         for (var i = 0; i < outletItemAddOnSnapshot.size; i++) {
    //             tempOutletItemAddOnList.push(outletItemAddOnSnapshot.docs[i].data());

    //             outletsItemAddOnDict[item.uniqueId] = tempOutletItemAddOnList;
    //         }
    //     }
    // }

    // if (outletsItemAddOnDict[item.uniqueId] !== undefined) {
    //     const tempOutletItemAddOnList = outletsItemAddOnDict[item.uniqueId];

    //     for (var i = 0; i < tempOutletItemAddOnList.length; i++) {
    //         if (outletsItemAddOnChoiceDict[tempOutletItemAddOnList[i].uniqueId] === undefined) {
    //             console.log('tempOutletItemAddOnList[i].uniqueId');
    //             console.log(tempOutletItemAddOnList[i].uniqueId);

    //             const outletItemAddOnChoiceSnapshot = await firebase.firestore().collection(Collections.OutletItemAddOnChoice)
    //                 .where('outletItemAddOnId', '==', tempOutletItemAddOnList[i].uniqueId)
    //                 .get();

    //             if (!outletItemAddOnChoiceSnapshot.empty)
    //                 var tempOutletItemAddOnChoiceList = [];

    //             for (var j = 0; j < outletItemAddOnChoiceSnapshot.size; j++) {
    //                 tempOutletItemAddOnChoiceList.push(outletItemAddOnChoiceSnapshot.docs[j].data());

    //                 outletsItemAddOnChoiceDict[tempOutletItemAddOnList[i].uniqueId] = tempOutletItemAddOnChoiceList;
    //             }
    //         }
    //     }
    // }

    // CommonStore.update(s => {
    //     // s.outletsItemAddOnDict = {
    //     //     ...s.outletsItemAddOnDict,
    //     //     ...outletsItemAddOnDict,
    //     // };

    //     // s.outletsItemAddOnChoiceDict = {
    //     //     ...s.outletsItemAddOnChoiceDict,
    //     //     ...outletsItemAddOnChoiceDict,
    //     // };

    //     s.outletsItemAddOnDict = outletsItemAddOnDict;
    //     s.outletsItemAddOnChoiceDict = outletsItemAddOnChoiceDict;

    //     s.isLoading = false;
    // });
};

export const listenToSelectedOutletTableIdChanges = async (userId, selectedOutletTableId, selectedOutletId) => {
    // Get outlets

    console.log('listenToSelectedOutletTableIdChanges');
    console.log(selectedOutletTableId);
    console.log(selectedOutletId);
    console.log(userId);

    firebase.firestore()
        .collection(Collections.UserCart)
        .where('tableId', '==', selectedOutletTableId)
        .where('outletId', '==', selectedOutletId)
        .where('waiterId', '==', userId)
        // .where('userId', '==', userId)
        .limit(1)
        .onSnapshot(async snapshot => {
            console.log(`${Collections.UserCart} changed!`);
            console.log(snapshot);

            var userCart = {};
            var cartItems = [];

            if (!snapshot.empty) {
                const record = snapshot.docs[0].data();

                userCart = record;

                cartItems = record.cartItems;

                // console.log(moment().diff(moment(userCart.updatedAt), 'minute') >= 1);

                // if (moment().diff(moment(userCart.updatedAt), 'minute') >= 1) {
                //     await deleteUserCart(userCart.uniqueId);
                // }
            }

            if (userCart && userCart.userId) {
                await AsyncStorage.setItem(`${userId}.cartItems`, JSON.stringify(cartItems));
                // await AsyncStorage.setItem(`${userId}.cartOutletId`, selectedOutletId);                              
            }
            else {
                await AsyncStorage.removeItem(`${userId}.cartItems`);
                // await AsyncStorage.removeItem(`${userId}.cartOutletId`);
            }

            CommonStore.update(s => {
                s.userCart = userCart;
                s.cartItems = cartItems;
            });
        });
};

// export const listenToSelectedOrderToPayUserIdChanges = async (firebaseUid) => {
//     firebase.firestore()
//         .collection(Collections.UserVoucherRedemption)
//         // .where('startAt', '<=', moment().valueOf())
//         // .where('endAt', '>', moment().valueOf())
//         .where('userId', '==', firebaseUid)
//         .onSnapshot(async snapshot => {
//             console.log(`${Collections.UserVoucherRedemption} changed!`);

//             var voucherIdRedemptionList = [];
//             //var selectedCustomerVouchers = [];

//             if (!snapshot.empty) {
//                 const userVoucherRedemptionSnapshot = await firebase.firestore().collection(Collections.UserVoucherRedemption)
//                     .where('userId', '==', firebaseUid)
//                     .limit(1)
//                     .get();

//                 if (!userVoucherRedemptionSnapshot.empty) {
//                     const userVoucherRedemption = userVoucherRedemptionSnapshot.docs[0].data();

//                     voucherIdRedemptionList = userVoucherRedemption.redemptions;
//                 }
//             }

//             CommonStore.update(s => {
//                 s.orderToPayUserVoucherIdRedemptionList = voucherIdRedemptionList;
//                 // s.voucherIdRedemptionList = voucherIdRedemptionList;

//             });
//         });
// };

// export const requestNotificationsPermission = async () => {
//     const authStatus = await firebase.messaging().requestPermission();
//     // const enabled =
//     //     authStatus === firebase.messaging.AuthorizationStatus.AUTHORIZED ||
//     //     authStatus === firebase.messaging.AuthorizationStatus.PROVISIONAL;

//     // if (enabled) {
//     //     console.log('Authorization status:', authStatus);
//     // }
// };

const pad = (n) => { return ("00000000" + n).substr(-8); };
const naturalExpand = (a) => { return a.replace(/\d+/g, pad) };
export const naturalCompare = (a, b) => {
    return naturalExpand(a).localeCompare(naturalExpand(b));
};

export const sortPaymentDataList = (dataList, paymentSortFieldType) => {
    var dataListTemp = [
        ...dataList,
    ];

    const paymentSortFieldTypeValue = PAYMENT_SORT_FIELD_TYPE_VALUE[paymentSortFieldType];

    const paymentSortFieldTypeCompare = PAYMENT_SORT_FIELD_TYPE_COMPARE[paymentSortFieldType];

    if (paymentSortFieldTypeCompare === PAYMENT_SORT_COMPARE_OPERATOR.ASC) {
        if (paymentSortFieldType === PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_ASC ||
            paymentSortFieldType === PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_ASC) {
            dataListTemp.sort((a, b) => moment(a[paymentSortFieldTypeValue]).valueOf() - moment(b[paymentSortFieldTypeValue]).valueOf());
        }
        else if (paymentSortFieldType === PAYMENT_SORT_FIELD_TYPE.PAYMENT_NAME_ASC) {
            dataListTemp.sort((a, b) => (a[paymentSortFieldTypeValue] ? a[paymentSortFieldTypeValue] : '').localeCompare((b[paymentSortFieldTypeValue] ? b[paymentSortFieldTypeValue] : '')));
        }
        else if (paymentSortFieldType === PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_ASC) {
            dataListTemp.sort((a, b) => a[paymentSortFieldTypeValue] - b[paymentSortFieldTypeValue]);
        }
        else {
            dataListTemp.sort((a, b) => a[paymentSortFieldTypeValue] - b[paymentSortFieldTypeValue]);
        }
    }
    else if (paymentSortFieldTypeCompare === PAYMENT_SORT_COMPARE_OPERATOR.DESC) {
        if (paymentSortFieldType === PAYMENT_SORT_FIELD_TYPE.PAYMENT_CREATED_DATE_DESC ||
            paymentSortFieldType === PAYMENT_SORT_FIELD_TYPE.PAYMENT_UPDATED_DATE_DESC) {
            dataListTemp.sort((a, b) => b[paymentSortFieldTypeValue] - a[paymentSortFieldTypeValue]);

        }
        else if (paymentSortFieldType === PAYMENT_SORT_FIELD_TYPE.PAYMENT_NAME_DESC) {
            dataListTemp.sort((a, b) => (b[paymentSortFieldTypeValue] ? b[paymentSortFieldTypeValue] : '').localeCompare((a[paymentSortFieldTypeValue] ? a[paymentSortFieldTypeValue] : '')));
        }
        else if (paymentSortFieldType === PAYMENT_SORT_FIELD_TYPE.PAYMENT_STATUS_DESC) {
            dataListTemp.sort((a, b) => b[paymentSortFieldTypeValue] - a[paymentSortFieldTypeValue]);
        }
        else {
            dataListTemp.sort((a, b) => moment(b[paymentSortFieldTypeValue]).valueOf() - moment(a[paymentSortFieldTypeValue]).valueOf());
        }
    }

    return dataListTemp;
};

export const sortReportDataList = (dataList, reportSortFieldType) => {
    var dataListTemp = [
        ...dataList,
    ];

    const reportSortFieldTypeValue = REPORT_SORT_FIELD_TYPE_VALUE[reportSortFieldType];

    const reportSortFieldTypeCompare = REPORT_SORT_FIELD_TYPE_COMPARE[reportSortFieldType];

    if (reportSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
        if (reportSortFieldType === REPORT_SORT_FIELD_TYPE.DATE_TIME_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.VOUCHER_REDEEMED_DATE_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.VOUCHER_USED_DATE_ASC) {
            dataListTemp.sort((a, b) => moment(a[reportSortFieldTypeValue]).valueOf() - moment(b[reportSortFieldTypeValue]).valueOf());
        }
        else if (reportSortFieldType === REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.USER_ORDER_EMPLOYEE_NAME_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.USER_ORDER_USER_NAME_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.USER_ORDER_TYPE_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.USER_ORDER_COURIER_NAME_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.CART_ITEM_NAME_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.CART_ITEM_ORDER_TYPE_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.CART_ITEM_REMARKS_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PRODUCT_NAME_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PRODUCT_SKU_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.VOUCHER_NAME_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.VOUCHER_TYPE_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.VOUCHER_USERNAME_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_ASC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_ASC) {
            dataListTemp.sort((a, b) => (a[reportSortFieldTypeValue] ? a[reportSortFieldTypeValue] : '').localeCompare((b[reportSortFieldTypeValue] ? b[reportSortFieldTypeValue] : '')));
        }
        else {
            dataListTemp.sort((a, b) => a[reportSortFieldTypeValue] - b[reportSortFieldTypeValue]);
        }
    }
    else if (reportSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC) {
        if (reportSortFieldType === REPORT_SORT_FIELD_TYPE.DATE_TIME_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.VOUCHER_REDEEMED_DATE_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.VOUCHER_USED_DATE_DESC) {
            dataListTemp.sort((a, b) => b[reportSortFieldTypeValue] - a[reportSortFieldTypeValue]);

        }
        else if (reportSortFieldType === REPORT_SORT_FIELD_TYPE.USER_ORDER_OUTLET_NAME_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.USER_ORDER_EMPLOYEE_NAME_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.USER_ORDER_USER_NAME_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.USER_ORDER_TYPE_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.USER_ORDER_COURIER_NAME_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.CART_ITEM_NAME_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.CART_ITEM_ORDER_TYPE_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.CART_ITEM_REMARKS_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PAYMENT_METHOD_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PRODUCT_NAME_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PRODUCT_CATEGORY_DESC ||

            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PRODUCT_SKU_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.VOUCHER_NAME_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.VOUCHER_TYPE_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.VOUCHER_USERNAME_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PRODUCT_SKU_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PROMOTION_TITLE_DESC ||
            reportSortFieldType === REPORT_SORT_FIELD_TYPE.PROMOTION_TYPE_DESC) {

            dataListTemp.sort((a, b) => (b[reportSortFieldTypeValue] ? b[reportSortFieldTypeValue] : '').localeCompare((a[reportSortFieldTypeValue] ? a[reportSortFieldTypeValue] : '')));
        }
        else {
            dataListTemp.sort((a, b) => moment(b[reportSortFieldTypeValue]).valueOf() - moment(a[reportSortFieldTypeValue]).valueOf());
        }
    }

    return dataListTemp;
};

export const removeOrdinalFromDate = (date) => {
    return moment(date).format('Do').replace(/\D/g, '');
};

export const getOrdinalFromDate = (date) => {
    return moment(date).format('Do').replace(/[0-9]/g, '');
};

export const subText = (base, exponent) => {
    return <View style={{ flexDirection: 'row' }}>
        <View style={{ alignItems: 'flex-end' }}>
            <Text style={{ fontSize: 13 }}>{base}</Text>
        </View>
        <View style={{ alignItems: 'flex-start' }}>
            <Text style={{ fontSize: 10 }}>{exponent}</Text>
        </View>
    </View>
};

// export const autofitColumns = (json, worksheet) => {
//     var objectMaxLength = [];

//     json.map(jsonData => {
//         Object.entries(jsonData)
//             .map(([, v], idx) => {
//                 const columnValue = v;
//                 objectMaxLength[idx] = objectMaxLength[idx] >= columnValue.length ? objectMaxLength[idx] : columnValue.length;
//             });
//     })

//     const wscols = objectMaxLength.map((w) => ({ width: w }));
//     worksheet["!cols"] = wscols;

//     return worksheet;
// };

// export const _autoFitColumns = (json, worksheet, header) => {
//     const jsonKeys = header || Object.keys(json[0])

//     const objectMaxLength = []
//     jsonKeys.forEach((key) => {
//         objectMaxLength.push(
//             pixelWidth(key, {
//                 size: 5,
//             })
//         )
//     })

//     json.forEach((data, i) => {
//         const value = json[i]
//         jsonKeys.forEach((key, j) => {
//             const l = value[jsonKeys[j]]
//                 ? pixelWidth(value[jsonKeys[j]], {
//                     size: 5,
//                 })
//                 : 0
//             objectMaxLength[j] = objectMaxLength[j] >= l ? objectMaxLength[j] : l
//         })
//     })

//     return objectMaxLength.map((w) => {
//         return { width: w }
//     })
// };

export const getPathForFirebaseStorageFromBlob = async (res) => {
    if (Platform.OS === 'ios') {
        return res.uri;
    }
    else {
        if (res.uri.startsWith('content://')) {
            // const uriComponents = uri.split('/')
            // const fileNameAndExtension = uriComponents[uriComponents.length - 1]
            // const destPath = `${RNFS.TemporaryDirectoryPath}/${fileNameAndExtension}`
            // await RNFS.copyFile(uri, 'file://' + destPath);
            // return 'file://' + destPath;

            return `file://${decodeURIComponent(res.fileCopyUri)}`;
        }
        else {
            // const stat = await RNFetchBlob.fs.stat(res.uri);
            // return stat.path;
        }
    }
};

export const weekOfMonth = (input = moment()) => {
    const firstDayOfMonth = input.clone().startOf('month');
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

    return Math.ceil((input.date() + offset) / 7);
};

export const countWeekdayOccurrencesInMonth = (date) => {

    var m = moment(date),
        weekDay = m.day(),
        yearDay = m.dayOfYear(),
        count = 0;

    m.startOf('month');
    while (m.dayOfYear() <= yearDay) {
        if (m.day() == weekDay) {
            count++;
        }
        m.add('days', 1);
    }

    return count;
};

export const areArraysEqual = (a1, a2) => {
    /* WARNING: arrays must not contain {objects} or behavior may be undefined */
    return JSON.stringify(a1) === JSON.stringify(a2);
};

export const getObjectDiff = (a, b) => Object.entries(a).reduce((ac, [k, v]) => b[k] && b[k] !== v ? (ac[k] = b[k], ac) : ac, {});

export const isObjectEqual = (value, other) => {
    // Get the value type
    var type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) {
        return false;
    }

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
        return false;
    }

    // Compare the length of the length of the two items
    var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) {
        return false;
    }
    // Compare two items
    var compare = function (item1, item2) {

        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!isObjectEqual(item1, item2)) {
                return false;
            }
        }

        // Otherwise, do a simple comparison
        else {

            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) return false;

            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) return false;
            } else {
                if (item1 !== item2) return false;
            }

        }
    };

    // Compare properties
    if (type === '[object Array]') {
        for (var i = 0; i < valueLen; i++) {
            if (compare(value[i], other[i]) === false) return false;
        }
    } else {
        for (var key in value) {
            if (value.hasOwnProperty(key)) {
                if (compare(value[key], other[key]) === false) return false;
            }
        }
    }

    // If nothing failed, return true
    return true;
};

export const convertCartItemForKitchenSummary = (cartItem, userOrder) => {
    var addOnParsedList = [];
    for (var i = 0; i < cartItem.addOns.length; i++) {
        addOnParsedList.push({
            name: `${cartItem.addOns[i].name}: ${cartItem.addOns[i].choiceNames[0]}`,
            ...cartItem.addOns[i].quantities && {
                quantity: cartItem.addOns[i].quantities[0],
            },
        });
    }

    addOnParsedList.sort((a, b) => a.name > b.name ? 1 : -1);

    return {
        key: cartItem.itemId + cartItem.quantity.toString() + addOnParsedList.map(addOn => addOn.name + (addOn.quantity ? addOn.quantity : 0).toString()).join(''),
        value: {
            name: cartItem.name,
            quantity: cartItem.quantity,
            addOnParsedList: addOnParsedList,

            userOrderId: userOrder.uniqueId,
            itemId: cartItem.itemId,
            cartItemDate: cartItem.cartItemDate,
        },
    };
};

export const getWidth = (percentage) => {
    if (Dimensions.get('window').width > Dimensions.get('window').height) {
        return (Dimensions.get('window').height * percentage) / 100;
    }
    return (Dimensions.get('window').width * percentage) / 100;
};

export const getHeight = (percentage) => {
    if (Dimensions.get('window').width > Dimensions.get('window').height) {
        return (Dimensions.get('window').width * percentage) / 100;
    }
    return (Dimensions.get('window').height * percentage) / 100;
};

export const fixRNDimensions = async (orientation) => {
    const windowDim = Dimensions.get('window')
    const screenDim = Dimensions.get('screen')
    if (
        (orientation.match(/LANDSCAPE/i) && windowDim.width < windowDim.height) ||
        (orientation.match(/PORTRAIT/i) && windowDim.width > windowDim.height)
    ) {
        console.log(orientation);
        console.log('fixing dimensions after rotation', windowDim)
        Dimensions.set({
            screen: {
                ...screenDim,
                width: screenDim.height,
                height: screenDim.width,
            },
            window: {
                ...windowDim,
                width: windowDim.height,
                height: windowDim.width,
            },
        });
    }
}

// export const checkRNReload = async () => {
//     const reloadApp = await AsyncStorage.getItem('reloadApp');

//     console.log('check refresh');
//     console.log(reloadApp);

//     if (!reloadApp) {
//         console.log('refresh');
//         console.log(reloadApp);

//         await saveRNReload();

//         DevSettings.reload();

//         // setTimeout(() => {
//         //     DevSettings.reload();
//         // }, 20000);
//     }
// };

// export const saveRNReload = async () => {
//     await AsyncStorage.setItem('reloadApp', '1');

//     console.log('save refresh');
// };

// export const removeRNReload = async () => {
//     await AsyncStorage.removeItem('reloadApp');

//     console.log('remove refresh');
// };

export const getCachedUrlContent = async (urlAsKey, expireInMinutes = 43800, object) => {
    var updatedAt = Date.now();
    if (object && object.updatedAt) {
        updatedAt = object.updatedAt;
    }

    let data = null;

    await AsyncStorage.getItem(urlAsKey, async (err, value) => {

        data = (JSON.parse(value));

        // there is data in cache && cache is expired
        if (data !== null && data['expireAt'] &&
            new Date(data.expireAt) < (new Date())) {

            //clear cache
            AsyncStorage.removeItem(urlAsKey);


            //update res to be null
            data = null;
        } else {

            console.log('read data from cache  ');

        }
    });

    //update cache + set expire at date
    if (data === null || (data && data.updatedAt && data.updatedAt !== updatedAt)) {
        console.log('cache new Date ');

        //fetch data

        await getImageFromFirebaseStorage(urlAsKey, async (parsedUrl) => {
            var apiRes = {};

            apiRes.parsedUrl = parsedUrl;
            apiRes.updatedAt = updatedAt;

            //set expire at
            apiRes.expireAt = getExpireDate(expireInMinutes);

            //stringify object
            const objectToStore = JSON.stringify(apiRes);

            //store object
            AsyncStorage.setItem(urlAsKey, objectToStore);

            console.log(apiRes.expireAt);

            return apiRes;
        });


        // data = fetch(urlAsKey).then((response) => response.json())
        //     .then(apiRes => {

        //         //set expire at
        //         apiRes.expireAt = getExpireDate(expireInMinutes);

        //         //stringify object
        //         const objectToStore = JSON.stringify(apiRes);

        //         //store object
        //         AsyncStorage.setItem(urlAsKey, objectToStore);


        //         console.log(apiRes.expireAt);
        //         return apiRes;
        //     });

    }

    return data;
};

/**
 *
 * @param expireInMinutes
 * @returns {Date}
 */
const getExpireDate = (expireInMinutes) => {
    const now = new Date();
    let expireTime = new Date(now);
    expireTime.setMinutes(now.getMinutes() + expireInMinutes);
    return expireTime;
};

export const isMobile = () => {
    // credit to Timothy Huang for this regex test:
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    }
    else {
        return false
    };
};

(function () {
    'use strict';

    var DEFAULT_MAX_DEPTH = 6;
    var DEFAULT_ARRAY_MAX_LENGTH = 50;
    var seen; // Same variable used for all stringifications

    Date.prototype.toPrunedJSON = Date.prototype.toJSON;
    String.prototype.toPrunedJSON = String.prototype.toJSON;

    var cx =
        /[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
        escapable =
            /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
        meta = {
            // table of character substitutions
            '\b': '\\b',
            '\t': '\\t',
            '\n': '\\n',
            '\f': '\\f',
            '\r': '\\r',
            '"': '\\"',
            '\\': '\\\\',
        };

    function quote(string) {
        escapable.lastIndex = 0;
        return escapable.test(string)
            ? '"' +
            string.replace(escapable, function (a) {
                var c = meta[a];
                return typeof c === 'string'
                    ? c
                    : '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
            }) +
            '"'
            : '"' + string + '"';
    }

    function str(key, holder, depthDecr, arrayMaxLength) {
        var i, // The loop counter.
            k, // The member key.
            v, // The member value.
            length,
            partial,
            value = holder[key];
        if (
            value &&
            typeof value === 'object' &&
            typeof value.toPrunedJSON === 'function'
        ) {
            value = value.toPrunedJSON(key);
        }

        switch (typeof value) {
            case 'string':
                return quote(value);
            case 'number':
                return isFinite(value) ? String(value) : 'null';
            case 'boolean':
            case 'null':
                return String(value);
            case 'object':
                if (!value) {
                    return 'null';
                }
                if (depthDecr <= 0 || seen.indexOf(value) !== -1) {
                    return '"-pruned-"';
                }
                seen.push(value);
                partial = [];
                if (Object.prototype.toString.apply(value) === '[object Array]') {
                    length = Math.min(value.length, arrayMaxLength);
                    for (i = 0; i < length; i += 1) {
                        partial[i] = str(i, value, depthDecr - 1, arrayMaxLength) || 'null';
                    }
                    v = partial.length === 0 ? '[]' : '[' + partial.join(',') + ']';
                    return v;
                }
                for (k in value) {
                    if (Object.prototype.hasOwnProperty.call(value, k)) {
                        try {
                            v = str(k, value, depthDecr - 1, arrayMaxLength);
                            if (v) partial.push(quote(k) + ':' + v);
                        } catch (e) {
                            // this try/catch due to some "Accessing selectionEnd on an input element that cannot have a selection." on Chrome
                        }
                    }
                }
                v = partial.length === 0 ? '{}' : '{' + partial.join(',') + '}';
                return v;
        }
    }

    JSON.prune = function (value, depthDecr, arrayMaxLength) {
        seen = [];
        depthDecr = depthDecr || DEFAULT_MAX_DEPTH;
        arrayMaxLength = arrayMaxLength || DEFAULT_ARRAY_MAX_LENGTH;
        return str('', { '': value }, depthDecr, arrayMaxLength);
    };
})();