import React, { Component, useReducer, useState, useEffect } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  TextInput,
  Dimensions,
  FlatList,
  Modal,
  Picker,
  PermissionsAndroid,
  ActivityIndicator,
  useWindowDimensions,
} from "react-native";
import { useLinkTo, } from "@react-navigation/native";
import Colors from '../constant/Colors';
import SideBar from './SideBar';
import * as User from '../util/User';
import AIcon from 'react-native-vector-icons/AntDesign';
import Icon from 'react-native-vector-icons/Feather';
import Icon1 from 'react-native-vector-icons/FontAwesome';
import Icon2 from 'react-native-vector-icons/EvilIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Entypo from 'react-native-vector-icons/Entypo';
import DropDownPicker from 'react-native-dropdown-picker';
// import { ceil } from 'react-native-reanimated';
//import CheckBox from '@react-native-community/checkbox';
import DateTimePicker from "react-native-modal-datetime-picker";
import moment from 'moment';
import Close from 'react-native-vector-icons/AntDesign';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Styles from '../constant/Styles'
// import DocumentPicker from 'react-native-document-picker';
import Ionicons from 'react-native-vector-icons/Ionicons';
//import RNFetchBlob from 'rn-fetch-blob';
//import { isTablet } from 'react-native-device-detection';
import 'react-native-get-random-values';
import { customAlphabet } from 'nanoid';
import { CommonStore } from '../store/commonStore';
import { MerchantStore } from '../store/merchantStore';
import { PURCHASE_ORDER_STATUS, PURCHASE_ORDER_STATUS_PARSED } from '../constant/common';
import { UserStore } from '../store/userStore';
import { OutletStore } from '../store/outletStore';
//import { KeyboardAwareFlatList, KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { PROMOTION_TYPE_VARIATION } from '../constant/promotions';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import {
  autofitColumns,
  convertArrayToCSV,
  generateEmailReport,
  getImageFromFirebaseStorage,
  getPathForFirebaseStorageFromBlob,
  uploadFileToFirebaseStorage
} from '../util/common';
import XLSX from 'xlsx';
//import { zip, unzip, unzipAssets, subscribe } from 'react-native-zip-archive'
// const RNFS = require('react-native-fs');
import 'react-native-get-random-values';
import { v4 as uuidv4 } from 'uuid';
import { EMAIL_REPORT_TYPE } from '../constant/common';
import { prefix } from "../constant/env";
import { useFilePicker } from "use-file-picker";
import Promise from 'bluebird';
import JsZip from 'jszip';
import FileSaver from 'file-saver';

import { ReactComponent as Edit } from "../assets/svg/Edit.svg";

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 12);

const ProductCategoryScreen = props => {
  const {
    navigation,
  } = props;

  const linkTo = useLinkTo();

  const {
    width: windowWidth,
    height: windowHeight
  } = useWindowDimensions();

  const [openFileSelector, { plainFiles, filesContent, loading: loadingFileInput, clear: clearAllFiles }] = useFilePicker({
    accept: ".zip"
  });

  useEffect(() => {
    console.log(plainFiles, filesContent, loadingFileInput)

    // Upload the batch template zip to firebase when the file is finish loaded
    if (plainFiles.length && filesContent.length && !loadingFileInput) {
      importTemplateData();
    }
  }, [plainFiles, filesContent, loadingFileInput])

  const [purchaseOrder, setPurchaseOrder] = useState(true);
  const [addStockTake, setAddStockTake] = useState(false);
  const [addPurchase, setAddPurchase] = useState(false);
  const [editPurchase, setEditPurchase] = useState(false);
  const [addStockTransfer, setAddStockTransfer] = useState(false);
  const [stockList, setStockList] = useState([]);
  const [stockTransferList, setStockTransferList] = useState([]);
  const [stockTakeList, setStockTakeList] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [itemsToOrder, setItemsToOrder] = useState([{}]);
  const [itemsToOrder2, setItemsToOrder2] = useState([{}, {}, {}]);
  const [addStockTransferList, setAddStockTransferList] = useState([{}, {}, {}]);
  const [addCountedStockTakeList, setAddCountedStockTakeList] = useState([{}, {}, {}]);
  const [addUnCountedStockTakeList, setAddUnCountedStockTakeList] = useState([{}, {}, {}]);
  const [productList, setProductList] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);
  const [isSelected3, setIsSelected3] = useState(true);
  const [isSelected4, setIsSelected4] = useState(false);
  const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
  const [date, setDate] = useState(Date.now());
  const [visible, setVisible] = useState(false);
  const [Email, setEmail] = useState(null);
  const [modal, setModal] = useState(false);
  const [outletId, setOutletId] = useState(User.getOutletId());
  //const [switchMerchant, setSwitchMerchant] = useState(isTablet ? false : true);
  const [search, setSearch] = useState('');
  const [search2, setSearch2] = useState('');
  const [search3, setSearch3] = useState('');
  const [ideal, setIdeal] = useState('');
  const [minimum, setMinimum] = useState('');
  const [itemId, setItemId] = useState('');
  const [choose, setChoose] = useState(null);

  const [loading, setLoading] = useState(false);
  const [choice2, setChoice2] = useState('Print P.O');
  const [choice7, setChoice7] = useState('1');
  const [choice8, setChoice8] = useState('1');
  const [choice4, setChoice4] = useState('Chicken patty');

  const [deleteModal, setDeleteModal] = useState(false);

  const [editCategory, setEditCategory] = useState(false);
  ///////////////////////////////////////////////////////////////////

  const [categoryName, setCategoryName] = useState('');
  const [newCategoryName, setNewCategoryName] = useState('');

  const [supplierName, setSupplierName] = useState('');
  const [supplierEmail, setSupplierEmail] = useState('');
  const [supplierPhone, setSupplierPhone] = useState('');
  const [supplierTax, setSupplierTax] = useState('');

  const [supplierItems, setSupplierItems] = useState([
    {
      supplyItemId: '',
      name: '',
      sku: '',
      unit: '',
      price: 0,
    }
  ]);

  ///////////////////////////////////////////////////////////////////

  const [poId, setPoId] = useState('');

  const [supplierDropdownList, setSupplierDropdownList] = useState([]);
  const [selectedSupplierId, setSelectedSupplierId] = useState('');

  const [poStatus, setPoStatus] = useState(PURCHASE_ORDER_STATUS.CREATED);

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState('');

  const [supplyItemDropdownList, setSupplyItemDropdownList] = useState([]);

  const [poItems, setPoItems] = useState([
    {
      supplyItemId: '',
      name: '',
      sku: '',
      quantity: 0,
      orderQuantity: 0,
      receivedQuantity: 0,
      price: 0,
      totalPrice: 0,
    }
  ]);

  const [selectedSupplier, setSelectedSupplier] = useState({
    taxRate: 0,
  });

  const [subtotal, setSubtotal] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const [discountTotal, setDiscountTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);

  const [categoryProductQuantity, setCategoryProductQuantity] = useState(0);

  const [expandViewDict, setExpandViewDict] = useState({});

  const [expandThreeDots, setExpandThreeDots] = useState({}); //Use to expand the view when three dots are tapped

  const outletSupplyItemsSkuDict = CommonStore.useState(s => s.outletSupplyItemsSkuDict);

  const supplyItems = CommonStore.useState(s => s.supplyItems);
  const supplyItemsDict = CommonStore.useState(s => s.supplyItemsDict);
  const suppliers = CommonStore.useState(s => s.suppliers);
  const allOutlets = MerchantStore.useState(s => s.allOutlets);
  const merchantId = UserStore.useState(s => s.merchantId);
  const purchaseOrders = CommonStore.useState(s => s.purchaseOrders);

  const [importModal, setImportModal] = useState(false);
  const isLoading = CommonStore.useState(s => s.isLoading);

  const userName = UserStore.useState(s => s.name);
  const merchantName = MerchantStore.useState(s => s.name);
  const merchantLogo = MerchantStore.useState(s => s.logo);

  const currOutletId = MerchantStore.useState(s => s.currOutletId);

  const outletItems = OutletStore.useState(s => s.outletItems);
  const outletItemsDict = OutletStore.useState(s => s.outletItemsDict);
  const outletCategories = OutletStore.useState(s => s.outletCategories);

  const currOutletTaxes = CommonStore.useState(s => s.currOutletTaxes);

  const firebaseUid = UserStore.useState(s => s.firebaseUid);

  const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);
  const selectedOutletCategoryEdit = CommonStore.useState(s => s.selectedOutletCategoryEdit);
  useEffect(() => {
    if (selectedOutletCategoryEdit) {
      // insert info

      setCategoryName(selectedOutletCategoryEdit.name);
    }
    else {
      // designed to always mounted, thus need clear manually...

      setCategoryName('');
    }
  }, [selectedOutletCategoryEdit]);

  useEffect(() => {
    setSupplierDropdownList(suppliers.map(supplier => ({ label: supplier.name, value: supplier.uniqueId })));

    if (selectedSupplierId === '' && suppliers.length > 0) {
      setSelectedSupplierId(suppliers[0].uniqueId);
    }
  }, [suppliers]);

  useEffect(() => {
    setTargetOutletDropdownList(allOutlets.map(outlet => ({ label: outlet.name, value: outlet.uniqueId })));

    if (selectedTargetOutletId === '' && allOutlets.length > 0) {
      setSelectedTargetOutletId(allOutlets[0].uniqueId);
    }
  }, [allOutlets]);

  useEffect(() => {
    setSupplyItemDropdownList(supplyItems.map(supplyItem => ({ label: supplyItem.name, value: supplyItem.uniqueId })));

    if (supplyItems.length > 0 &&
      poItems.length === 1 &&
      poItems[0].supplyItemId === '') {
      setPoItems([
        {
          supplyItemId: supplyItems[0].uniqueId,
          name: supplyItems[0].name,
          sku: supplyItems[0].sku,
          quantity: outletSupplyItemsSkuDict[supplyItems[0].sku] ? outletSupplyItemsSkuDict[supplyItems[0].sku].quantity : 0, // check if the supply item sku for this outlet existed
          orderQuantity: 0,
          receivedQuantity: 0,
          price: supplyItems[0].price,
          totalPrice: 0,

        }
      ]);
    }
    else if (poItems[0].supplyItemId !== '' &&
      Object.keys(supplyItemsDict).length > 0) {
      var poItemsTemp = [
        ...poItems,
      ];

      for (var i = 0; i < poItemsTemp.length; i++) {
        const supplyItem = supplyItemsDict[poItemsTemp[i].supplyItemId];

        poItemsTemp[i] = {
          ...poItemsTemp[i],
          quantity: outletSupplyItemsSkuDict[supplyItem.sku] ? outletSupplyItemsSkuDict[supplyItem.sku].quantity : 0, // check if the supply item sku for this outlet existed | might changed in real time
          price: supplyItem.price, // might changed in real time
        };
      }

      setPoItems(poItemsTemp);
    }
  }, [
    supplyItems,
    supplyItemsDict,
    // outletSupplyItemsSkuDict
  ]);

  useEffect(() => {
    if (suppliers.length > 0 && selectedSupplierId !== '') {
      setSelectedSupplier(suppliers.find(supplier => supplier.uniqueId === selectedSupplierId));
    }
  }, [suppliers, selectedSupplierId]);

  useEffect(() => {
    console.log('subtotal');
    console.log(poItems.reduce((accum, poItem) => accum + poItem.totalPrice, 0));
    setSubtotal(poItems.reduce((accum, poItem) => accum + poItem.totalPrice, 0));
  }, [poItems]);

  useEffect(() => {
    console.log('taxTotal');
    console.log(subtotal * selectedSupplier.taxRate);
    setTaxTotal(subtotal * selectedSupplier.taxRate);
  }, [subtotal]);

  useEffect(() => {
    console.log('finalTotal');
    console.log((subtotal - discountTotal) + taxTotal);
    setFinalTotal((subtotal - discountTotal) + taxTotal);
  }, [subtotal, discountTotal, taxTotal]);

  useEffect(() => {
    requestStoragePermission();

    setPoId(nanoid());
  }, []);

  const setState = () => { };


  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={styles.headerLeftStyle}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View style={[{
        justifyContent: 'center',
        alignItems: 'center',
        // bottom: -2,
        bottom: 0,
      }, Dimensions.get('screen').width <= 768 ? { right: Dimensions.get('screen').width * 0.12 } : {}]}>
        <Text
          style={{
            fontSize: 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Manage Product
        </Text>
      </View>
    ),
    headerRight: () => (
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        {outletSelectDropdownView()}
        <View style={{
          backgroundColor: 'white',
          width: 0.5,
          height: Dimensions.get('screen').height * 0.025,
          opacity: 0.8,
          marginHorizontal: 15,
          bottom: -1,
        }}>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('Setting')}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View style={{
            marginRight: 30,
            width: Dimensions.get('screen').height * 0.05,
            height: Dimensions.get('screen').height * 0.05,
            borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}>
            <Image style={{
              width: Dimensions.get('screen').height * 0.035,
              height: Dimensions.get('screen').height * 0.035,
              alignSelf: 'center',
            }} source={require('../assets/image/profile-pic.jpg')} />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // componentDidMount = () => {
  //   // setInterval(() => {
  //   //   getStockOrder()
  //   //   getStockTransfer()
  //   //   getLowStock()
  //   // }, 1000);
  //   getStockOrder();

  // }

  // async componentWillMount = () => {
  //   await requestStoragePermission()
  // }

  const requestStoragePermission = async () => {
    try {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
        {
          title: "KooDoo Merchant Storage Permission",
          message:
            "KooDoo Merchant App needs access to your storage ",
          buttonNegative: "Cancel",
          buttonPositive: "OK"
        }
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        console.log("Storage permission granted");
      } else {
        console.log("Storage permission denied");
      }
    } catch (err) {
      console.warn(err);
    }
  }

  const expandThreeDotsFunc = (param) => {
    if (expandThreeDots[param.uniqueId]) {
      setExpandThreeDots({
        ...expandThreeDots,
        [param.uniqueId]: false,
      });
    } else {
      setExpandThreeDots({
        // ...expandThreeDots,
        [param.uniqueId]: true,
      });
    }
  }

  // const importCSV = () => {
  //   try {
  //     const res = DocumentPicker.pick({
  //       type: [DocumentPicker.types.csv],
  //     });
  //     console.log(
  //       res.uri,
  //       res.type,
  //       res.name,
  //       res.size
  //     );
  //   } catch (err) {
  //     if (DocumentPicker.isCancel(err)) {
  //       // User cancelled the picker, exit any dialogs or menus and move on
  //     } else {
  //       throw err;
  //     }
  //   }
  // }

  // const expandOrderFunc = (param) => {
  //   if (expandOrder == false) {
  //     // return setState({ expandOrder: true }), param.expandOrder = true;
  //     setExpandOrder(true);
  //     setExpandViewDict({
  //       ...expandViewDict,
  //       [param.uniqueId]: true,
  //     });
  //     expandViewDict;
  //   } else {
  //     // return setState({ expandOrder: false }), param.expandOrder = false;
  //     setExpandOrder(false);
  //     setExpandViewDict({
  //       ...expandViewDict,
  //       [param.uniqueId]: undefined,
  //     });
  //   }
  // }

  // const exportFunc = () => {
  //   // var body = {
  //   //   data: orderList
  //   // }

  //   if (outletCategories) {
  //     const csvData = convertArrayToCSV(outletCategories);

  //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/koodoo-report-product-category-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`;
  //     console.log("PATH", pathToWrite)
  //     RNFetchBlob.fs
  //       .writeFile(pathToWrite, csvData, 'utf8')
  //       .then(() => {
  //         console.log(`wrote file ${pathToWrite}`);
  //         // wrote file /storage/emulated/0/Download/data.csv
  //         Alert.alert(
  //           'Success',
  //           `Sent to ${pathToWrite}`,
  //           [{ text: 'OK', onPress: () => { } }],
  //           { cancelable: false },
  //         );
  //       })
  //       .catch(error => console.error(error));
  //   }

  //   // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
  //   //   console.log("RESULT", result)
  //   //   if (result !== null) {
  //   //     const pathToWrite = `${RNFetchBlob.fs.dirs.DownloadDir}/POData.csv`;
  //   //     console.log("PATH", pathToWrite)
  //   //     RNFetchBlob.fs
  //   //       .writeFile(pathToWrite, result, 'utf8')
  //   //       .then(() => {
  //   //         console.log(`wrote file ${pathToWrite}`);
  //   //         // wrote file /storage/emulated/0/Download/data.csv
  //   //         Alert.alert(
  //   //           'Success',
  //   //           'The data had exported',
  //   //           [{ text: 'OK', onPress: () => { } }],
  //   //           { cancelable: false },
  //   //         );
  //   //       })
  //   //       .catch(error => console.error(error));
  //   //   }
  //   // });
  // }

  const importFunc = () => {
    var body = {
      data: orderList
    }
    // ApiClient.POST(API.exportDataCSV, body, false).then((result) => {
    //   console.log(result)
    // });

    alert('Success: The data has imported');
  }



  const getStockOrder = () => {
    // ApiClient.GET(API.getStockOrder + outletId).then((result) => {

    //   setState({ orderList: result })

    // });
  }

  // function here
  const showDateTimePicker = () => {
    // setState({ isDateTimePickerVisible: true });
    setIsDateTimePickerVisible(true);
  };

  const hideDateTimePicker = () => {
    // setState({ isDateTimePickerVisible: false });
    setIsDateTimePickerVisible(false);
  };

  const handleDatePicked = date => {

    // setState({ date: date.toString() });
    setDate(date);
  };

  const renderOrderItem = ({ item, index }) => {
    // console.log('renderOrderItem');
    // console.log(item);

    //var quantity = 0;
    var totalQuantity = 0;

    // for ( var i = 0; i < outletItems.length; i++ ) {
    //   if (outletItems[i].categoryId === item.categoryId){
    //     for ( var j= 0; j<outletItems[i].categoryId; j++ ){
    //      const quantity = outletItems[i].categoryId[j];

    //     totalQuantity += quantity
    //     }
    //   }
    // }

    for (var i = 0; i < outletItems.length; i++) {
      //console.log('hello')
      if (outletItems[i].categoryId === item.uniqueId) {
        //console.log('123hihi')
        totalQuantity += 1
      }
    }
    setCategoryProductQuantity(totalQuantity);

    return (
      <TouchableOpacity onPress={() => {
        // CommonStore.update(s => {
        //   s.selectedOutletCategoryEdit = item;
        // });

        // setPurchaseOrder(false);
        // setAddPurchase(true);

        /* CommonStore.update(s => {
          s.selectedOutletCategoryEdit = item;

          s.timestamp = Date.now;
        }, () => {
          navigation.navigate('Product');
        }); */

        CommonStore.update(s => {
          s.selectedOutletCategoryEdit = item;

          s.timestamp = Date.now;
        });
        navigation.navigate('Product');

      }}>
        <View
          style={{
            backgroundColor: '#ffffff',
            flexDirection: 'row',
            paddingVertical: 20,
            paddingHorizontal: 20,
            borderBottomWidth: StyleSheet.hairlineWidth,
            borderBottomColor: '#c4c4c4',
          }}>
          <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', width: '42%', color: Colors.primaryColor }}>{`${item.name}`}</Text>
          <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', width: '18%', color: Colors.primaryColor }}>{totalQuantity}</Text>
          <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', width: '18%' }}>{item.createdAt ? moment(item.createdAt).format('DD MMM YYYY') : 'N/A'}</Text>
          <Text style={{ fontSize: 14, fontFamily: 'NunitoSans-Regular', width: '18%' }}>{item.updatedAt ? moment(item.updatedAt).format('DD MMM YYYY') : 'N/A'}</Text>

          {(expandThreeDots[item.uniqueId] == true) ?
            <View style={{
              //position: 'absolute',
              width: 110,
              height: 33,
              marginLeft: -110,
              zIndex: 1,
              flexDirection: 'column',
              backgroundColor: '#FFFFFF',
              borderWidth: 1,
              //borderColor: '#E7E7E7',
              borderColor: Colors.highlightColor,
              borderRadius: 7,
              shadowOpacity: 0,
              shadowColor: '#000',
              shadowOffset: {
                height: 2,
              },
              shadowOpacity: 0.22,
              shadowRadius: 3.22,
              elevation: 2,
            }}>

              <TouchableOpacity style={{ flexDirection: 'row', height: '100%', justifyContent: 'center', alignItems: 'center' }}
                onPress={() => {
                  // CommonStore.update(s => {
                  //   s.selectedPromotionEdit = item;
                  // });

                  // navigation.navigate('NewCampaign');

                  CommonStore.update(s => {
                    s.selectedOutletCategoryEdit = item;

                    s.timestamp = Date.now;
                  });

                  setPurchaseOrder(false);
                  setAddPurchase(true);


                  // expandThreeDotsFunc(item);
                }}>
                <View style={{ width: '30%', paddingLeft: 12 }}>
                  <Edit size={17} color={Colors.primaryColor} />
                </View>
                <View style={{ width: '70%' }}>
                  <Text style={{ marginLeft: 5 }}>
                    Edit
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
            : null}

          <View style={{
            width: '4%',
            flexDirection: 'row',
            // paddingRight: 20, 
            // backgroundColor: 'red'
          }}>
            <TouchableOpacity style={{ marginTop: 0, alignSelf: 'flex-start', alignItems: 'flex-start' }}
              onPress={() => {
                // expandThreeDotsFunc(item)

                CommonStore.update(s => {
                  s.selectedOutletCategoryEdit = item;
                });

                setPurchaseOrder(false);
                setAddPurchase(true);
                setEditCategory(true);
              }}
            >
              <Edit size={17} color={Colors.primaryColor} />
            </TouchableOpacity>
          </View>
        </View>
      </TouchableOpacity>
    );
  }

  const renderSupplierItems = ({ item, index }) => {
    return (
      <View
        style={{
          backgroundColor: '#ffffff',
          flexDirection: 'row',
          paddingVertical: 20,
          paddingHorizontal: 20,
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: '#c4c4c4',
          alignItems: 'center',
          // height: (Dimensions.get('window').width * 0.1) * 3,
        }}>
        <View style={{
          width: '20%',
          // marginLeft: 50,
          marginLeft: '-1%',
        }}>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              height: 40,
              width: 150,
              paddingHorizontal: 20,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 10,
            }}
            placeholder={'Item name'}
            //placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
            keyboardType={'default'}
            // placeholder={itemName}
            onChangeText={(text) => {
              // setState({ itemName: text });
              setSupplierItems(supplierItems.map((supplierItem, i) => (i === index ? {
                ...supplierItem,
                name: text,
                isChanged: true,
              } : supplierItem)))
            }}
            value={supplierItems[index].name}
          // ref={myTextInput}
          />
        </View>

        <View style={{
          width: '20%',
          // marginLeft: 50,
          // backgroundColor: 'blue',
          marginLeft: '-1%',
        }}>
          <TextInput
            editable={item.supplyItemId === ''}
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              height: 40,
              width: 150,
              paddingHorizontal: 20,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 10,
            }}
            placeholder={'SKU'}
            //placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
            keyboardType={'default'}
            // placeholder={itemName}
            onChangeText={(text) => {
              // setState({ itemName: text });
              setSupplierItems(supplierItems.map((supplierItem, i) => (i === index ? {
                ...supplierItem,
                sku: text,
                isChanged: true,
              } : supplierItem)))
            }}
            value={supplierItems[index].sku}
          // ref={myTextInput}
          />
        </View>

        <View style={{
          width: '20%',
          // marginLeft: 50,
          // backgroundColor: 'blue',
          // marginLeft: '-1%',
        }}>
          <TextInput
            editable={item.supplyItemId === ''}
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              height: 40,
              width: 100,
              paddingHorizontal: 20,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 10,
            }}
            placeholder={'Unit'}
            //placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
            keyboardType={'default'}
            // placeholder={itemName}
            onChangeText={(text) => {
              // setState({ itemName: text });
              setSupplierItems(supplierItems.map((supplierItem, i) => (i === index ? {
                ...supplierItem,
                unit: text,
                isChanged: true,
              } : supplierItem)))
            }}
            value={supplierItems[index].unit}
          // ref={myTextInput}
          />
        </View>

        <View style={{
          width: '20%',
          // marginLeft: 50,
          // backgroundColor: 'blue',
          // marginLeft: '-1%',
        }}>
          <TextInput
            underlineColorAndroid={Colors.fieldtBgColor}
            style={{
              height: 40,
              width: 100,
              paddingHorizontal: 20,
              backgroundColor: Colors.fieldtBgColor,
              borderRadius: 10,
            }}
            placeholder={'Price'}
            //placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
            keyboardType={'number-pad'}
            // placeholder={itemName}
            onChangeText={(text) => {
              // setState({ itemName: text });
              setSupplierItems(supplierItems.map((supplierItem, i) => (i === index ? {
                ...supplierItem,
                price: text,
                isChanged: true,
              } : supplierItem)))
            }}
            value={supplierItems[index].price}
          // ref={myTextInput}
          />
        </View>

        <View style={{
          width: '10%',
          // marginLeft: 50,
          // backgroundColor: 'blue',
        }}>

        </View>

        <TouchableOpacity style={{ marginLeft: 10 }}
          onPress={() => {
            setSupplierItems([
              ...supplierItems.slice(0, index),
              ...supplierItems.slice(index + 1),
            ]);
          }}>
          <Icon name="trash-2" size={20} color="#eb3446" />
        </TouchableOpacity>
      </View>

    );
  };

  const email = () => {
    var body = {
      stockTransferId: 1,
      email: Email
    }
    // ApiClient.POST(API.emailStockTransfer, body, false).then((result) => {

    //   if (result == true) {

    //     Alert.alert(
    //       'Success',
    //       'The email had sent',
    //       [
    //         { text: "OK", onPress: () => { } }
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  }

  // const createStockOrder = () => {
  //   var body = {
  //     poId: poId,
  //     poItems: poItems,
  //     supplierId: selectedSupplierId,
  //     status: poStatus,
  //     outletId: selectedTargetOutletId,
  //     tax: taxTotal,
  //     discount: discountTotal,
  //     totalPrice: subtotal,
  //     finalTotal: finalTotal,
  //     estimatedArrivalDate: date,

  //     merchantId: merchantId,
  //     remarks: '',
  //   };

  //   ApiClient.POST(API.createPurchaseOrder, body).then((result) => {
  //     if (result && result.uniqueId) {
  //       Alert.alert(
  //         'Success',
  //         'Purchase order created successfully',
  //         [
  //           { text: "OK", onPress: () => { props.navigation.goBack() } }
  //         ],
  //         { cancelable: false },
  //       );
  //     }
  //   });
  // }

  const createOutletItemCategory = () => {
    console.log('on createOutletItemCategory');

    if (categoryName !== '') {
      if (selectedOutletCategoryEdit === null) {
        var body = {
          categoryName: categoryName,
          outletId: currOutletId,
          merchantId: merchantId,
        };

        console.log(body);

        ApiClient.POST(API.createOutletItemCategory, body).then((result) => {
          if (result && result.status === 'success') {
            if (window.confirm('Success: Product category has been created')) {
              // props.navigation.goBack()
              setEditPurchase(false);
              setAddPurchase(false);
              setPurchaseOrder(true);
            }
          }
          else {
            alert('Error: Failed to create Product Category');
          }
        });
      }
      else {
        var body = {
          categoryName: categoryName,
          outletId: currOutletId,

          uniqueId: selectedOutletCategoryEdit.uniqueId
        };

        console.log(body);

        ApiClient.POST(API.updateOutletItemCategory, body).then((result) => {
          if (result && result.status === 'success') {
            if (window.confirm('Success: Product category has been updated')) {
              // props.navigation.goBack()
              setEditPurchase(false);
              setAddPurchase(false);
              setPurchaseOrder(true);
            }
          }
          else {
            alert('Error: Failed to update Product Category');
          }
        });
      }
    }
    else {
      alert('Error: Please fill in all informations');
    }
  }

  const convertTemplateToExcelFormat = () => {
    var excelTemplate = [];

    var allOutletsStr = allOutlets.map(item => item.name).join(';');

    var taxName = currOutletTaxes[0].name;

    var excelColumn = {
      'Name': 'Fried noodle',
      'Description': 'Hong Kong style with dark soy sauce',
      'SKU': 'FN001',
      'Price': '12.99',
      'Prepare Time (seconds)': '900',
      'Currency': 'MYR',
      'Category': 'Dry Noodles',
      'Outlet': allOutletsStr,
      'Tax': `${taxName}:${currOutletTaxes[0].rate}`,
      'Variant': 'Size:Regular/0.00|Large/3.00;Flavour:Original/0.00|Spicy/0.00',
      'Addon': 'Toppings:More fried wonton strips/0/1/2.00|More shrimps/0/1/3.00;Specials:Boiled egg/0/5/2.00',
      // 'Variant': '',
      // 'Addon': '',
      'Image Label (png, jpg, jpeg)': 'Fried_noodle',
      'Printers': 'K1',
    };
    excelTemplate.push(excelColumn);

    var excelColumn2 = {
      'Name': 'Fried rice',
      'Description': 'Special fried rice',
      'SKU': 'FN002',
      'Price': '14.99',
      'Prepare Time (seconds)': '900',
      'Currency': 'MYR',
      'Category': 'Rice',
      'Outlet': allOutletsStr,
      'Tax': `${taxName}:${currOutletTaxes[0].rate}`,
      // 'Variant': 'Size:Regular 0.00,Large 3.00;Flavour:Original 0.00,Spicy 0.00',
      // 'Addon': 'Toppings:More fried wonton strips 0 1 2.00,More shrimps 0 1 3.00;Specials:Boiled egg 0 5 2.00',
      'Variant': '',
      'Addon': '',
      'Image Label (png, jpg, jpeg)': 'Fried_rice',
      'Printers': 'K2,Cashier',
    };
    excelTemplate.push(excelColumn2);

    console.log('excelTemplate');
    console.log(excelTemplate);

    return excelTemplate;
  };

  const download = async url => {
    return fetch(url).then(resp => resp.blob());
  };

  const downloadByGroup = (urls, files_per_group = 5) => {
    return Promise.map(
      urls,
      async url => {
        return await download(url);
      },
      { concurrency: files_per_group }
    );
  }

  const exportZip = (blobs, filenames, extensions, extra) => {
    const zip = JsZip();

    blobs.forEach((blob, i) => {
      // hard coded file name
      zip.folder(`images`).folder(`${filenames[i]}`).file(`image.${extensions[i]}`, blob);
    });

    // download extra blob
    if (extra)
      zip.file(`koodoo-product-template.xlsx`, extra)

    zip.generateAsync({ type: 'blob' }).then(zipFile => {
      const fileName = `koodoo-product-template.zip`;
      return FileSaver.saveAs(zipFile, fileName);
    });
  }

  const downloadAndZip = async (urls, filenames, extensions, extra) => {
    if(extensions) {
      let isInvalid = false;
      extensions.forEach((item) => {
        if (!['jpg', 'jpeg', 'png'].includes(item)) {
          isInvalid = true;
          alert(`Unsupported extesion type \n  Extension type: ${item} is not supported`)
        } 
      })

      if (isInvalid)
        return

      return downloadByGroup(urls, 5).then(blobs => exportZip(blobs, filenames, extensions, extra));
    }
  }

  // convert base64 string to array buffer
  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  const exportTemplate = async () => {
    try {
      const excelTemplate = convertTemplateToExcelFormat();

      var templateImageUrl = '';

      // download merchant logo as example image file

      await new Promise((resolve, reject) => {
        if (merchantLogo.startsWith('http') || merchantLogo.startsWith('file')) {
          templateImageUrl = merchantLogo;

          resolve();
        }
        else {
          getImageFromFirebaseStorage(merchantLogo, (parsedUrl) => {
            templateImageUrl = parsedUrl;

            resolve();
          });
        }
      });

      var tempImageFileName = 'image.' + templateImageUrl.split('.').pop();
      tempImageFileName = tempImageFileName.split('?')[0];

      var excelWorkSheet = XLSX.utils.json_to_sheet(excelTemplate);
      var excelWorkBook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(excelWorkBook, excelWorkSheet, "Product Template");

      // write in base64
      const workBookData = XLSX.write(excelWorkBook, {
        type: 'base64',
        bookType: 'xlsx',
      });

      // create a blob and url
      var excelBlob = new Blob([s2ab(atob(workBookData))], {
        type: ''
      });
      var excelUrl = URL.createObjectURL(excelBlob);

      // hard coded extensions for files downloaded from urls
      var filenames = ['Fried_noodle','Fried_rice']
      var extensions = ['jpg', 'jpg']
      var urls = [templateImageUrl, templateImageUrl]
      await downloadAndZip(urls, filenames, extensions, excelBlob)

    }
    catch (ex) {
      console.error(ex);

      alert('Error: Failed to export template');
    }
  }

  const importTemplateData = async () => {
    CommonStore.update(s => {
      s.isLoading = true;
    });

    try {
      var referenceId = uuidv4();
      var referencePath = `/merchant/${merchantId}}/batchUploads/${referenceId}.zip`;

      await uploadFileToFirebaseStorage(plainFiles[0], referencePath);

      clearAllFiles();

      const body = {
        zipId: referenceId,
        zipPath: referencePath,

        userId: firebaseUid,
        merchantId: merchantId,
        outletId: currOutletId,
      };

      ApiClient.POST(API.batchCreateOutletItems, body).then((result) => {
        if (result && result.status === 'success') {
          alert('Success: Submitted to upload queue, we will notify you once processing done');
        }
        else {
          alert('Error: Failed to import the data');
        }

        CommonStore.update(s => {
          s.isLoading = false;
        });
      }).catch(err => {
        console.log(err);

        alert('Error: Failed to import the data');

        CommonStore.update(s => {
          s.isLoading = false;
        });
      });

    } catch (err) {
      CommonStore.update(s => {
        s.isLoading = false;
      });

      console.error(err);

      alert('Error: Failed to import the data');
    }
  }

  const deleteOutletItemCategory = () => {
    var body = {
      categoryName: categoryName,
      outletId: currOutletId,

      uniqueId: selectedOutletCategoryEdit.uniqueId
    };

    console.log(body);

    ApiClient.POST(API.deleteOutletItemCategory, body).then((result) => {
      if (result && result.status === 'success') {
        if (window.confirm('Success: Product category has been deleted')) {
          // props.navigation.goBack()
          // setEditPurchase(false);
          // setAddPurchase(false);
          // setPurchaseOrder(true);
        }

        setDeleteModal(false);
        setPurchaseOrder(true);
        setEditPurchase(false);
        setAddPurchase(false);
      }
      else {
        alert('Error: Failed to delete Product category');
      }
    });
  }

  // function end

  return (
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar navigation={navigation} selectedTab={2} expandReport={true} />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{ width: windowWidth * 0.9 }}
          contentContainerStyle={{
            paddingBottom: windowHeight * 0.1,
          }}
        >
          <View
            style={{
              paddingVertical: 30,
              marginHorizontal: 30,
            }}>

            <Modal
              style={{
              }}
              visible={importModal}
              supportedOrientations={['portrait', 'landscape']}
              transparent={true}
              animationType={'slide'}
            >
              <View style={styles.modalContainer}>
                <View style={[styles.modalView, {
                }]}>
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={() => {
                      // setState({ changeTable: false });
                      setImportModal(false);
                    }}>
                    <AntDesign name="closecircle" size={25} color={Colors.fieldtTxtColor} />
                  </TouchableOpacity>
                  <View style={styles.modalTitle}>
                    <Text style={[styles.modalTitleText]}>
                      Upload Options
                    </Text>
                  </View>
                  <View style={{
                    alignItems: 'center',
                    top: '10%',
                  }}>

                    <TouchableOpacity
                      style={{
                        justifyContent: 'center',
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: '#4E9F7D',
                        borderRadius: 5,
                        width: 240,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: 'center',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                        marginBottom: 10,
                      }}
                      onPress={() => { openFileSelector() }}
                      disabled={isLoading}
                    >
                      <Text style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>{
                          isLoading ? 'Loading...' : 'UPLOAD BATCH TEMPLATE'
                        }</Text>

                      {isLoading ?
                        <ActivityIndicator style={{
                          marginLeft: 5,
                        }} color={Colors.whiteColor} size={'small'} />
                        : <></>
                      }
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        justifyContent: 'center',
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: '#4E9F7D',
                        borderRadius: 5,
                        width: 240,
                        paddingHorizontal: 10,
                        height: 40,
                        alignItems: 'center',
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -1,
                      }}
                      onPress={() => { exportTemplate() }}
                    >
                      <Text style={{
                        color: Colors.whiteColor,
                        //marginLeft: 5,
                        fontSize: 16,
                        fontFamily: 'NunitoSans-Bold',
                      }}>
                        EXPORT TEMPLATE</Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </Modal>


            {!addPurchase ?
              <View style={{}}>
                <View style={{
                  flexDirection: 'row',
                  marginBottom: 5,
                  justifyContent: 'flex-end',
                  width: windowWidth * 0.877,
                  alignSelf: 'center',
                  marginHorizontal: 30,
                }}>

                  <View
                    style={{
                      height: 70,
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ flexDirection: 'row', alignItems: 'center', }}>

                      <TouchableOpacity
                        style={{
                          justifyContent: 'center',
                          flexDirection: 'row',
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: '#4E9F7D',
                          borderRadius: 5,
                          //width: 160,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: 'center',
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          //marginHorizontal: 10,
                        }}
                        onPress={() => {
                          setImportModal(true)
                        }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <Icon name="upload" size={20} color={Colors.whiteColor} />
                          <Text style={{
                            color: Colors.whiteColor,
                            marginLeft: 5,
                            fontSize: 16,
                            fontFamily: 'NunitoSans-Bold',
                          }}>
                            BATCH UPLOAD
                          </Text>
                        </View>
                      </TouchableOpacity>

                      <TouchableOpacity
                        style={{
                          justifyContent: 'center',
                          flexDirection: 'row',
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: '#4E9F7D',
                          borderRadius: 5,
                          //width: 160,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: 'center',
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginHorizontal: 10,
                        }}
                        onPress={() => {
                          CommonStore.update(s => {
                            s.selectedProductEdit = null;

                            s.timestamp = Date.now();
                          });

                          linkTo && linkTo(`${prefix}/productadd`);
                        }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <AntDesign
                            name="pluscircle"
                            size={20}
                            color={Colors.whiteColor}
                          />
                          <Text style={{
                            color: Colors.whiteColor,
                            marginLeft: 5,
                            fontSize: 16,
                            fontFamily: 'NunitoSans-Bold',
                          }}>
                            PRODUCT
                          </Text>
                        </View>
                      </TouchableOpacity>

                      <TouchableOpacity
                        style={{
                          justifyContent: 'center',
                          flexDirection: 'row',
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: '#4E9F7D',
                          borderRadius: 5,
                          //width: 160,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: 'center',
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginRight: 10,

                        }}
                        onPress={() => {
                          CommonStore.update(s => {
                            s.selectedOutletCategoryEdit = null;
                          })
                          setPurchaseOrder(false);
                          setAddPurchase(true);
                          setEditCategory(false);
                          //setNewCategoryName('');
                        }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          <AntDesign
                            name="pluscircle"
                            size={20}
                            color={Colors.whiteColor}
                          />
                          <Text style={{
                            color: Colors.whiteColor,
                            marginLeft: 5,
                            fontSize: 16,
                            fontFamily: 'NunitoSans-Bold',
                          }}>
                            CATEGORY
                          </Text>
                        </View>
                      </TouchableOpacity>

                      <TouchableOpacity
                        style={{
                          justifyContent: 'center',
                          flexDirection: 'row',
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: '#4E9F7D',
                          borderRadius: 5,
                          //width: 160,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: 'center',
                          shadowOffset: {
                            width: 0,
                            height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                        }}
                        onPress={() => {

                          CommonStore.update(s => {
                            s.selectedOutletCategoryEdit = null;

                            s.timestamp = Date.now;
                          });

                          navigation.navigate('Product');
                        }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                          {/* <Icon1 name="plus-circle" size={20} color={Colors.primaryColor} /> */}
                          <Text style={{
                            color: Colors.whiteColor,
                            //marginLeft: 5,
                            fontSize: 16,
                            fontFamily: 'NunitoSans-Bold',
                          }}>
                            ALL PRODUCTS
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>

                  </View>
                </View>
                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  //marginTop: 10, 
                  width: windowWidth * 0.877,
                  alignSelf: 'center',
                  marginHorizontal: 30,
                  //backgroundColor: 'blue',
                }}>

                  <View style={{ flexDirection: 'row', alignItems: 'center', paddingTop: 10, marginLeft: 35 }}>
                    <Text style={{ fontSize: 26, fontFamily: 'NunitoSans-Bold' }}>
                      {/* {orderList.length} */}
                      {outletCategories.length}
                    </Text>
                    <Text style={{ fontSize: 26, fontFamily: 'NunitoSans-Bold', marginLeft: Dimensions.get('screen').width * 0.008 }}>{outletCategories.length > 1 ? 'Categories' : 'Category'}</Text>
                  </View>
                  <View
                    style={[
                      //   {
                      // }, !isTablet ? {
                      //   marginLeft: 0,
                      // } : {}
                    ]}>
                    <View style={{
                      width: 250,
                      height: 40,
                      backgroundColor: 'white',
                      borderRadius: 5,
                      flexDirection: 'row',
                      alignContent: 'center',
                      alignItems: 'center',
                      shadowColor: '#000',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                      borderWidth: 1,
                      borderColor: '#E5E5E5',
                      //marginRight: switchMerchant ? 0 : 0,
                    }}>
                      <Icon name="search" size={18} color={Colors.primaryColor} style={{ marginLeft: 15 }} />
                      <TextInput
                        editable={!loading}
                        underlineColorAndroid={Colors.whiteColor}
                        style={{
                          width: 220,
                          fontSize: 16,
                          fontFamily: 'NunitoSans-Regular',
                          height: 45,
                        }}
                        clearButtonMode="while-editing"
                        placeholder=" Search"
                        //placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                        onChangeText={(text) => {
                          setSearch(text);
                        }}
                        value={search}
                      />
                    </View>
                  </View>
                </View>
              </View>
              :
              <></>
            }

            {purchaseOrder ? (
              <View
                style={{
                  flexDirection: 'row',
                  marginTop: 0,
                  justifyContent: 'space-between',
                }}>
                <View
                  style={{
                    backgroundColor: Colors.whiteColor,
                    borderRadius: 10,
                    width: windowWidth * 0.8575,
                    minHeight: Dimensions.get('screen').height * 0.01,
                    marginTop: 30,
                    marginHorizontal: 30,
                    marginBottom: 30,
                    borderRadius: 5,
                    shadowOpacity: 0,
                    shadowColor: '#000',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 3,
                  }}
                >
                  <View
                    style={{
                      backgroundColor: Colors.whiteColor,
                      flexDirection: 'row',
                      paddingVertical: 20,
                      paddingHorizontal: 20,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      borderRadius: 5,
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                    }}>
                    <Text style={{ fontSize: 14, width: '42%', alignSelf: 'center', color: Colors.blackColor, fontFamily: 'NunitoSans-Bold' }}>
                      Category Name
                    </Text>
                    <Text style={{ fontSize: 14, width: '18%', alignSelf: 'center', color: Colors.blackColor, fontFamily: 'NunitoSans-Bold' }}>
                      Product Quantity
                    </Text>
                    <Text style={{ fontSize: 14, width: '18%', alignSelf: 'center', color: Colors.blackColor, fontFamily: 'NunitoSans-Bold' }}>
                      Created Date
                    </Text>
                    <Text style={{ fontSize: 14, width: '19%', alignSelf: 'center', color: Colors.blackColor, fontFamily: 'NunitoSans-Bold' }}>
                      Modified Date
                    </Text>
                  </View>

                  <ScrollView
                    //scrollEnabled={!switchMerchant}
                    nestedScrollEnabled={true}
                  >
                    <FlatList
                      data={outletCategories.filter(item => {
                        var totalQuantity = 0;

                        for (var i = 0; i < outletItems.length; i++) {
                          //console.log('hello')
                          if (outletItems[i].categoryId === item.uniqueId) {
                            //console.log('123hihi')
                            totalQuantity += 1;
                          }
                        }

                        if (search !== '') {
                          const searchLowerCase = search.toLowerCase();

                          if (item.name.toLowerCase().includes(searchLowerCase)) {
                            return true;
                          }
                          else if ((totalQuantity.toString()).includes(searchLowerCase)) {
                            return true;
                          }
                          else {
                            return false;
                          }
                        }
                        else {
                          return true;
                        }
                      })}
                      nestedScrollEnabled={true}
                      renderItem={renderOrderItem}
                      keyExtractor={(item, index) => String(index)}
                    />
                    <View style={{ height: 100 }}></View>
                  </ScrollView>
                </View>
              </View>
            ) : null}

            {addPurchase ? (
              <View style={{
                height: Dimensions.get('window').height - 200,
                width: Dimensions.get('window').width * 0.89
              }}>
                <View>
                  <Modal
                    style={{ flex: 1 }}
                    visible={deleteModal}
                    transparent={true}
                    animationType="slide"
                  >
                    <View style={styles.modalContainer}>
                      <View style={[styles.modalView1, {
                        height: Dimensions.get('screen').height * 0.25,
                      }]}>
                        <TouchableOpacity
                          style={styles.closeButton}
                          onPress={() => {
                            setDeleteModal(false);
                          }}>
                          <AIcon
                            name="closecircle"
                            size={25}
                            color={Colors.fieldtTxtColor}
                          />
                        </TouchableOpacity>
                        <View style={{}}>
                          <Text style={{ fontWeight: '700', fontFamily: 'Nunitosans-Regular', fontSize: 18, }}>
                            {/* Please Key In The Authorization Code */}
                            {`Are you sure you want to delete this category ${selectedOutletCategoryEdit ? selectedOutletCategoryEdit.name : ''} ?`}
                          </Text>
                        </View>
                        <View style={{}}>
                          <TouchableOpacity
                            style={{
                              borderRadius: 5,
                              backgroundColor: Colors.tabRed,
                              height: 35,
                              width: 90,
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            onPress={() => {
                              deleteOutletItemCategory();
                            }}
                          >
                            <Text style={{ fontSize: 15, color: 'white', fontWeight: '500', }}>
                              DELETE
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </Modal>
                  <Modal
                    style={{ flex: 1 }}
                    visible={visible}
                    transparent={true}
                    animationType="slide">
                    <View
                      style={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: Dimensions.get('window').height,
                      }}>
                      <View style={styles.confirmBox}>
                        <TouchableOpacity
                          onPress={() => {
                            setState({ visible: false });
                          }}>
                          <View
                            style={{
                              alignSelf: 'flex-end',
                              padding: 16,
                            }}>
                            {/* <Close name="closecircle" size={25} /> */}
                            <AntDesign name="closecircle" size={25} color={Colors.fieldtTxtColor} />
                          </View>
                        </TouchableOpacity>
                        <View>
                          <Text
                            style={{
                              textAlign: 'center',
                              fontWeight: '700',
                              fontSize: 28,
                            }}>
                            Purchase Order
                          </Text>
                        </View>
                        <View style={{ marginTop: 20 }}>
                          <Text
                            style={{
                              textAlign: 'center',
                              color: Colors.descriptionColor,
                              fontSize: 25,
                            }}>
                            Fill In The Email Information
                          </Text>
                        </View>
                        <View style={{ backgroundColor: 'white', alignSelf: 'center', flexDirection: "row" }}>
                          <Text style={{ fontSize: 20, marginTop: 70 }}>
                            Email:
                          </Text>
                          <View style={{ marginTop: 60, backgroundColor: '#f7f5f5', marginLeft: 10 }}>
                            <TextInput
                              editable={!loading}
                              underlineColorAndroid={Colors.fieldtBgColor}
                              clearButtonMode="while-editing"
                              style={styles.textCapacity}
                              placeholder="myburgerlabmytown@gmail.com"
                              //placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                              onChangeText={(text) => {
                                setState({ Email: text });
                              }}
                              value={Email}
                            />
                          </View>
                        </View>
                        <View
                          style={{
                            flexDirection: 'row',
                            alignSelf: 'center',
                            marginTop: 20,
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '50%',
                            alignContent: 'center',
                            zIndex: 6000,
                          }}>

                        </View>
                        <View
                          style={{
                            alignSelf: 'center',
                            marginTop: 20,
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: 250,
                            height: 40,
                            alignContent: 'center',
                            flexDirection: "row",
                            marginTop: 40
                          }}>
                          <TouchableOpacity
                            // onPress={() => {
                            //   email(),
                            //     setState({ visible: false });
                            // }}
                            style={{
                              backgroundColor: Colors.fieldtBgColor,
                              width: '60%',
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignContent: 'center',
                              borderRadius: 10,
                              height: 60,
                            }}>
                            <Text style={{ fontSize: 28, color: Colors.primaryColor }}>
                              Send
                            </Text>
                          </TouchableOpacity>
                          <TouchableOpacity
                            onPress={() => {
                              setState({ visible: false });
                            }}
                            style={{
                              backgroundColor: Colors.fieldtBgColor,
                              width: '60%',
                              justifyContent: 'center',
                              alignItems: 'center',
                              alignContent: 'center',
                              borderRadius: 10,
                              height: 60,
                              marginLeft: 30
                            }}>
                            <Text style={{ fontSize: 28, color: Colors.primaryColor }}>
                              No
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                    </View>
                  </Modal>

                  <TouchableOpacity style={{ marginBottom: 0, flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginTop: 5, paddingLeft: '1.2%' }}
                    onPress={() => {
                      setPurchaseOrder(true);
                      setAddPurchase(false);
                    }}>
                    <Icon name="chevron-left" size={30} color={Colors.primaryColor} />
                    <Text style={{ fontFamily: 'NunitoSans-Bold', fontSize: 17, color: Colors.primaryColor, marginBottom: 2, marginLeft: '-0.5%' }}> Back </Text>
                  </TouchableOpacity>

                  <ScrollView
                    nestedScrollEnabled={true}
                    contentContainerStyle={{
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    style={{
                      backgroundColor: Colors.whiteColor,
                      borderRadius: 10,
                      minHeight: Dimensions.get('screen').height * 0.01,
                      marginTop: 30,
                      marginHorizontal: 30,
                      marginBottom: 30,
                      borderRadius: 5,
                      shadowOpacity: 0,
                      shadowColor: '#000',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
                    }}
                  >
                    <View style={{ width: windowWidth * 0.825 }}>
                      <View style={{ alignSelf: "flex-end", marginTop: 20, position: 'absolute', zIndex: 10000 }}>
                        {editCategory ?
                          <View
                            style={{
                              justifyContent: 'center',
                              flexDirection: 'row',
                              borderWidth: 1,
                              borderColor: Colors.tabRed,
                              backgroundColor: Colors.tabRed,
                              borderRadius: 5,
                              width: 130,
                              paddingHorizontal: 10,
                              height: 40,
                              alignItems: 'center',
                              shadowOffset: {
                                width: 0,
                                height: 2,
                              },
                              shadowOpacity: 0.22,
                              shadowRadius: 3.22,
                              elevation: 1,
                              zIndex: -1,
                            }}>
                            <TouchableOpacity
                              style={{ width: 130, height: 40, alignItems: 'center', justifyContent: 'center' }}
                              onPress={() => {

                                setDeleteModal(true)
                              }}>
                              <Text style={{
                                color: Colors.whiteColor,
                                marginLeft: 5,
                                fontSize: 16,
                                fontFamily: 'NunitoSans-Bold',
                              }}>
                                DELETE
                              </Text>
                            </TouchableOpacity>
                          </View>
                          :
                          <></>
                        }

                        <View
                          style={{
                            justifyContent: 'center',
                            flexDirection: 'row',
                            borderWidth: 1,
                            borderColor: Colors.primaryColor,
                            backgroundColor: '#4E9F7D',
                            borderRadius: 5,
                            width: 130,
                            paddingHorizontal: 20,
                            height: 40,
                            alignItems: 'center',
                            shadowOffset: {
                              width: 0,
                              height: 2,
                            },
                            shadowOpacity: 0.22,
                            shadowRadius: 3.22,
                            elevation: 1,
                            zIndex: -1,
                            marginTop: 10,
                          }}>
                          <TouchableOpacity
                            style={{ width: 130, height: 40, alignItems: 'center', justifyContent: 'center' }}
                            onPress={createOutletItemCategory}>
                            <Text style={{
                              color: Colors.whiteColor,
                              marginLeft: 5,
                              fontSize: 16,
                              fontFamily: 'NunitoSans-Bold',
                            }}>
                              {editCategory ? 'SAVE' : 'ADD'}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      </View>
                      <View>
                        <Text style={{ alignSelf: "center", marginTop: 30, fontSize: 40, fontWeight: 'bold' }}>{editCategory ? 'Edit Product Category' : 'Add Product Category'}</Text>
                        <Text style={{ alignSelf: "center", fontSize: 16, color: '#adadad' }}>Fill In The Product Category Information</Text>
                      </View>

                      <View style={{ flexDirection: "row", marginTop: 10, justifyContent: 'space-evenly', marginTop: 50, marginBottom: 40, width: '90%', alignSelf: 'center', marginLeft: Dimensions.get('screen').width * Styles.sideBarWidth * 0.5 }}>
                        <View style={{
                          flexDirection: "row",
                          flex: 1,
                          alignItems: 'center',
                          justifyContent: 'center',
                          //width: '50%',
                        }}>
                          <Text style={{ fontFamily: 'NunitoSans-Regular', fontSize: 14, textAlign: 'left' }}>Category Name</Text>

                          <TextInput
                            editable={true}
                            underlineColorAndroid={Colors.fieldtBgColor}
                            style={{
                              backgroundColor: Colors.fieldtBgColor,
                              width: 200,
                              height: 40,
                              borderRadius: 5,
                              padding: 5,
                              marginVertical: 5,
                              borderWidth: 1,
                              borderColor: '#E5E5E5',
                              paddingLeft: 10,
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: 14,
                              marginLeft: 30,
                            }}
                            // placeholder={'50'}
                            placeholder={'Category Name'}
                            placeholderStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: 14 }}
                            //placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                            keyboardType={'default'}
                            onChangeText={text => {
                              setCategoryName(text);
                            }}
                            value={categoryName}
                          />
                        </View>

                        {/* <View style={{ flexDirection: "row", flex: 1, alignItems: 'center', width: '50%' }}> */}

                        {/* </View> */}
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        backgroundColor: '#ffffff',
                        justifyContent: 'center',
                        padding: 18,
                        height: 100,
                      }}>
                    </View>
                  </ScrollView>
                </View>
              </View>
            ) : null}

            {editPurchase ? (
              <View style={{ height: Dimensions.get('window').height - 200 }}>

                <View>
                  <ScrollView>
                    <View style={{ borderBottomWidth: StyleSheet.hairlineWidth }}>
                      <View>
                        <Text style={{ alignSelf: "center", marginTop: 30, fontSize: 40, fontWeight: 'bold' }}>Edit Purchase Order</Text>
                        <Text style={{ alignSelf: "center", fontSize: 16, color: '#adadad' }}>Edit your purchase order information</Text>
                      </View>

                      <View style={{ flexDirection: "row", marginTop: 10, justifyContent: 'space-evenly', marginTop: 50 }}>
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text style={{ fontSize: 16, marginLeft: 80 }}>P.O.ID</Text>
                          <Text style={{ color: '#adadad', marginLeft: 170, fontSize: 16, }}>P.O.1134</Text>
                        </View>
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text style={{ fontSize: 16, marginLeft: 80 }}>Supplier</Text>
                          <Text style={{ color: '#adadad', marginLeft: 100, fontSize: 16, }}>My Burgers Enterprise</Text>
                        </View>
                      </View>

                      <View style={{ flexDirection: "row", marginTop: 10, justifyContent: 'space-evenly', marginTop: 50 }}>
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text style={{ fontSize: 16, marginLeft: 80 }}>Current status</Text>
                          <View style={{ paddingHorizontal: 18, paddingVertical: 10, alignItems: "center", backgroundColor: '#838387', borderRadius: 10, marginLeft: 100 }}>
                            <Text style={{ color: Colors.whiteColor }}>Partially received</Text>
                          </View>

                        </View>
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text style={{ fontSize: 16, marginLeft: 80 }}>Target Store</Text>
                          <Text style={{ color: '#adadad', marginLeft: 70, fontSize: 16, }}>MyBurgerlab (Seapark)</Text>
                        </View>
                      </View>

                      <View style={{ flexDirection: "row", marginTop: 10, justifyContent: "space-evenly", marginTop: 50, marginBottom: 40 }}>
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text style={{ fontSize: 16, marginLeft: 80 }}>Estimated Arrival Time</Text>
                          <Text style={{ color: '#adadad', marginLeft: 50, fontSize: 16, }}>1/10/2020</Text>
                        </View>
                        <View style={{ flexDirection: "row", flex: 1 }}>
                          <Text style={{ fontSize: 16, marginLeft: 80 }}>Order Date</Text>
                          <Text style={{ color: '#adadad', marginLeft: 80, fontSize: 16, }}>5/10/2020</Text>
                        </View>
                      </View>
                    </View>

                    <View>
                      <Text style={{ alignSelf: "center", marginTop: 30, fontSize: 25, fontWeight: 'bold' }}>Items to order</Text>
                    </View>

                    <View
                      style={{
                        backgroundColor: '#ffffff',
                        flexDirection: 'row',
                        paddingVertical: 20,
                        paddingHorizontal: 20,
                        marginTop: 10,
                        borderBottomWidth: StyleSheet.hairlineWidth,
                      }}>
                      <Text style={{ width: '8%' }}></Text>
                      <Text style={{ width: '14%', alignSelf: 'center' }}>
                        Product name
                      </Text>
                      <Text style={{ width: '16%', alignSelf: 'center' }}>
                        SKU
                      </Text>
                      <Text style={{ width: '14%', alignSelf: 'center' }}>
                        Ordered qty
                      </Text>
                      <Text style={{ width: '16%', alignSelf: 'center' }}>Received qty</Text>
                      <Text style={{ width: '18%', alignSelf: 'center' }}>Supplier Price </Text>
                      <Text style={{ width: '16%', alignSelf: 'center' }}>Total (RM)</Text>
                    </View>
                    <FlatList
                      data={itemsToOrder}
                      extraData={itemsToOrder}
                      //renderItem={renderItemsToOrder}
                      keyExtractor={(item, index) => String(index)}
                    />

                    <View style={{ flexDirection: 'row' }}>
                      <View >
                        <TouchableOpacity style={styles.submitText2} onPress={() => {
                          // add one row

                          setSupplierItems([
                            ...supplierItems,
                            {
                              supplyItemId: '',
                              name: '',
                              sku: '',
                              unit: '',
                              price: 0,
                            }
                          ]);
                        }}>
                          <View style={{ flexDirection: 'row' }}>
                            <Icon1 name="plus-circle" size={20} color={Colors.primaryColor} />
                            <Text style={{ marginLeft: 10, color: Colors.primaryColor }}>
                              Add supply item slot
                            </Text>
                          </View>
                        </TouchableOpacity>
                      </View>
                    </View>

                    <View style={{ flexDirection: "row", alignSelf: "center", justifyContent: "space-evenly", marginTop: 20 }}>
                      <View
                        style={{
                          backgroundColor: Colors.primaryColor,
                          width: 200,
                          height: 40,
                          marginVertical: 15,
                          borderRadius: 5,
                          alignSelf: 'center',
                        }}>
                        <TouchableOpacity
                        //onPress={() => { editStockOrder() }}
                        >
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              alignSelf: 'center',
                              marginVertical: 10,
                            }}>
                            SAVE
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View
                        style={{
                          backgroundColor: Colors.primaryColor,
                          width: 200,
                          height: 40,
                          marginVertical: 15,
                          borderRadius: 5,
                          alignSelf: 'center',
                          marginLeft: 40
                        }}>
                        <TouchableOpacity onPress={() => { }}>
                          <Text
                            style={{
                              color: Colors.whiteColor,
                              alignSelf: 'center',
                              marginVertical: 10,
                            }}>
                            SAVE & SEND
                          </Text>
                        </TouchableOpacity>
                      </View>
                    </View>


                    <View
                      style={{
                        flexDirection: 'row',
                        backgroundColor: '#ffffff',
                        justifyContent: 'center',
                        padding: 18,
                      }}>
                      <View style={{ alignItems: 'center' }}>
                        <Text style={{ fontSize: 30, fontWeight: 'bold' }}>
                          {outletCategories.length}
                        </Text>
                        <Text>CATEGORIES</Text>
                      </View>
                    </View>
                  </ScrollView>
                </View>

              </View>
            ) : null}
          </View>
        </ScrollView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.highlightColor,
    flexDirection: 'row',
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10
  },
  list1: {
    backgroundColor: Colors.whiteColor,
    height: Dimensions.get('screen').height * 0.7,
    width: Dimensions.get('screen').width * 0.87,
    marginTop: 30,
    marginHorizontal: 30,
    marginBottom: 30,
    alignSelf: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  list1_PhoneAdjustment: {
    backgroundColor: Colors.whiteColor,
    minHeight: Dimensions.get('screen').height * 0.01,
    width: Dimensions.get('screen').width * 0.79,
    marginTop: 30,
    //marginHorizontal: 30,
    marginBottom: 30,
    //alignSelf: 'center',
    borderRadius: 5,
    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
  },
  ContainerList: {
    backgroundColor: Colors.whiteColor,
    width: Dimensions.get('screen').width * 0.87,
    minHeight: Dimensions.get('screen').height * 0.01,
    borderRadius: 5,
    //padding: Dimensions.get('screen').width * 0.03,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    marginHorizontal: 30,
    marginBottom: 30,
    shadowOpacity: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItem: {
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    width: Dimensions.get('screen').width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 16,
    width: Dimensions.get('screen').width * (1 - Styles.sideBarWidth),
  },
  submitText: {
    height: 40,
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: 'row',
    color: '#4cd964',
    textAlign: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: 10,
  },
  submitText1: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    color: '#4cd964',
    textAlign: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    alignSelf: "flex-end",
    marginRight: 20,
    marginTop: 15
  },
  submitText2: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    color: '#4cd964',
    textAlign: 'center',
    alignSelf: "flex-end",
    marginRight: 20,
    marginTop: 15
  },
  /* textInput: {
    width: 300,
    height: '10%',
    padding: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 20,
  }, */

  textInput: {
    width: 200,
    height: 40,
    // padding:5,
    backgroundColor: Colors.whiteColor,
    borderRadius: 0,
    marginRight: '35%',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'flex-end',

    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 0.51,
    elevation: 15,
  },
  searchIcon: {
    backgroundColor: Colors.whiteColor,
    height: 40,
    padding: 10,
    shadowOffset: {
      width: 0,
      height: 7,
    },
    shadowOpacity: 0.43,
    shadowRadius: 9.51,

    elevation: 15,
  },
  textInput1: {
    width: 160,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 5,
  },
  textInput2: {
    width: 100,
    padding: 5,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
    paddingTop: 5,
    textAlign: 'center'
  },
  confirmBox: {
    width: 450,
    height: 450,
    borderRadius: 12,
    backgroundColor: Colors.whiteColor,
  },
  textFieldInput: {
    height: 80,
    width: 900,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    borderRadius: 5,
  },
  circleIcon: {
    width: 30,
    height: 30,
    // resizeMode: 'contain',
    marginRight: 10,
    alignSelf: 'center'
  },
  headerLeftStyle: {
    width: Dimensions.get('screen').width * 0.17,
    justifyContent: 'center',
    alignItems: 'center',
  },
  // modalView: {
  //   height: Dimensions.get('screen').width * 0.22,
  //   width: Dimensions.get('screen').width * 0.48,
  //   backgroundColor: Colors.whiteColor,
  //   borderRadius: Dimensions.get('screen').width * 0.03,
  //   padding: Dimensions.get('screen').width * 0.03,
  //   paddingHorizontal: Dimensions.get('screen').width * 0.02,
  //   alignItems: 'center',
  //   justifyContent: 'space-between',
  modalContainer: {
    flex: 1,
    backgroundColor: Colors.modalBgColor,
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalView: {
    height: Dimensions.get('screen').width * 0.2,
    width: Dimensions.get('screen').width * 0.3,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get('screen').width * 0.03,
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalView1: {
    height: Dimensions.get('screen').width * 0.25,
    width: Dimensions.get('screen').width * 0.4,
    backgroundColor: Colors.whiteColor,
    borderRadius: 12,
    padding: Dimensions.get('screen').width * 0.035,
    paddingTop: Dimensions.get('screen').width * 0.05,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  closeButton: {
    position: 'absolute',
    right: Dimensions.get('screen').width * 0.02,
    top: Dimensions.get('screen').width * 0.02,

    elevation: 1000,
    zIndex: 1000,
  },
  modalTitle: {
    alignItems: 'center',
    top: '20%',
    position: 'absolute',
  },
  modalTitleText: {
    fontFamily: 'NunitoSans-Bold',
    textAlign: 'center',
    fontSize: 20,
  },
  modalDescText: {
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 18,
    color: Colors.fieldtTxtColor
  },
  modalSaveButton: {
    width: Dimensions.get('screen').width * 0.15,
    backgroundColor: Colors.fieldtBgColor,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,

    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,

    marginVertical: 10,
  },
});
export default ProductCategoryScreen;
