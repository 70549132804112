const getToken = 'token?ctoken=FIREBASE:'
// const getToken = 'token'
const getAllOutletTags = 'getAllOutletTags'
const getOutletByTags = 'getOutletByTags?tagId='
const outletSearch = 'outletSearch/'
const outlet2 = 'outlet2/'
const merchantMenu = 'merchantMenu/'
const getItemAddOnChoice = 'getItemAddOnChoice/?itemId='
const getTakeAway = 'getTakeAway'
const orderHistory2 = 'orderHistory2'
const order2 = 'order2/'
const createOrder = 'order2'
const allOrder = 'getAllOrder?userId='
const outletSearchByName = 'outletSearch?text='
const userAdmin = 'userAdmin'
const initialRegister = 'initialRegister'
const getAllUserQueue = 'getAllUserQueue/'
const getReservationList = 'reservations/getReservationList/'
const requestReservation = 'reservations/requestReservation'
const getCurrentOrder = 'getCurrentOrder/'
const queue = 'queue'
const createUserQueueByMerchant = 'createUserQueueByMerchant'
const updateUserQueueByMerchant = 'updateUserQueueByMerchant'
const cancel = 'cancel'
// const cancelReservation = 'reservations/cancelReservation/'
const cancelReservation = 'cancelReservation'
const getPopular = 'getPopular'
const addTable = 'table'
const getTable = "tables/"
const getItemByOutletTEST = 'getItemByOutletTEST?itemId='
const taxes = 'taxes/name'
const deleteTax = 'taxes/id/delete'
const addOutlet = 'outlet'
const getOutletByMerchant = 'getOutletByMerchant?merchantId='
const getAllOutletQueue = 'getAllOutletQueue?outletId='
const notifyQueue = 'notifyQueue'
const switchQueueStatus = "switchQueueStatus"
const getUserPhone = 'userPhone?queueId='
const acceptQueue = 'acceptQueue'
const servedQueue = 'servedQueue'
const seatedQueue = 'seatedQueue'
const noShowQueue = 'noShowQueue'
const cancelQueue = 'cancelQueue'
const getOutletReservation = 'getOutletReservation?outletId='
const switchReservationStatus = 'switchReservationStatus'
const acceptReservation = 'acceptReservation'
const seatedReservation = 'seatedReservation'
const noShowReservation = 'noShowReservation'
const undoReservation = 'undoReservation'
const getCurrentTakeAwayOrder = 'getCurrentTakeAwayOrder?outletId='
const getPreparationTimes = 'preparationTime?orderId='
const getUserReservationPhone = 'userReservationPhone?reservationId='
const registerQueue = 'queue'
const deleteMenuItem = 'deleteMenuItem'
const addMenu = 'changeItemCategory'
const menuItem = 'menuItem'
const addCustomer = "customerInTable"
const deleteCustomer = "customerOutTable"
const openShift = "openShift"
const getCurrentShift = "getCurrentShift?outletId="
const addCurrentMenu = "changeItemCategory"
const editOutlet = "editOutlet"
const searchBarItem = "searchBarItem?queryName="
const searchBarEmployee = "searchBarEmployee?queryName="
const getCurrentOutletOrder = 'getCurrentOutletOrder?outletId='
// const acceptQueue = "accept"
const getEmployee = 'employees?outletId='
const addEmployee = "employee"
const closeShift = "closeShift"
const getLeftItem = "leftItem?outletId="
const activeMenu = "merchantActiveMenu?outletId="
const setActiveMenu = "setActiveMenu"
const tableSection = "getTableSection?outletId="
const sectionTable = "getSectionTable"
const postSection = "tableSection"
const postTable = "addSectionTable"
const createVoucher = "createVoucher"
const getPreperationTime = "preperationTime?orderId="
const getCurrentOrderItem = "getCurrentOrderItem?orderId="
const getVoucher = "getVoucher?merchantId="
const getVoucherCount = "getVoucherCount?merchantId="
const editVoucher = "editVoucher"
const getCheckItem = "getCheckItem?orderItemId="
const checkItemPreparation = "checkItemPreparation"
const getOutletOrder = "getCurrentTakeAwayOrder?outletId="
const tableOrder = "getTableOrder?tableId="
const createStockOrder = 'createStockOrder'
const getStockOrder = 'getStockOrder?outletId='
const editStockOrder = 'editStockOrder'
const editStockTransfer = 'editStockTransfer'
const confirmStockOrder = 'confirmStockOrder'
const getLowStock = 'getLowStock?outletId='
const settingLowStock = 'settingLowStock'
const getTransfer = 'getTransfer?fromOutlet='
const getOrderDetails = 'order1/orderDetails2?orderId='
const orderDone = "orderDone"
const qrTableGenerate = "qrTableGenerate"
const getCategory = 'findOutletCategory?outletId='
const changeItem = "changeItemCategory"
const deleteSectionTable = 'deleteSectionTable'
const getDefaultPayment = 'getDefaultPayment'
const getReceipt = 'order2/'
const orderDonePayment = 'orderDonePayment'
const userResetPassword = 'userResetPassword'
const createUserParking = 'createUserParking'
const createSettingRedemption = 'createSettingRedemption'
const editSettingOrder = 'editSettingOrder'
const getSettingOrder = 'getSettingOrder?merchantId='
const editSettingRedemption = 'editSettingRedemption'
const getSettingRedemption = 'getSettingRedemption?merchantId='
const getAllSettingRedemption = 'getAllSettingRedemption?merchantId='
const createSettingOrder = 'createSettingOrder'
const prioritizeOrder = 'prioritizeOrder';
const checkStockOrder = "checkStockOrder"
const checkStockOrderDelete = "checkStockOrderDelete"
const emailStockTransfer = "emailStockTransfer"
const deleteTable = 'table/'
const getTableDetails = 'table/'
const getOutletRing = 'getOutletRing?outletId='
const getOutletRingList = 'getOutletRingList?outletId='
const userRingOutlet = 'userRingOutlet'
const notifiedOutletRing = 'notifiedOutletRing'
const attendOutletRing = 'attendOutletRing'
const waiterCheckOrder = 'waiterCheckOrder'
const salesByTransactionCustom = 'salesByTransactionCustom?outletId='
const salesByTransactionCategory = 'salesByTransactionCategory?outletId='
const salesByCategoryAll = 'salesByCategoryAll?outletId='
const salesByTransactionChannelSearchBar = 'salesByTransactionChannelSearchBar?outletId='
const getSalesBySku = 'getSalesBySku?outletId='
const salesBySkuSearchBar = 'salesBySkuSearchBar?outletId='
const getSalesByPayment = 'getSalesByPayment?outletId='
const getSalesByPaymentSearchCategory = 'getSalesByPaymentSearchCategory?outletId='
const salesByCategorySearchBar = 'salesByCategorySearchBar?outletId='
const getSalesByVariants = 'getSalesByVariants?outletId='
const getSalesByVariantsSearchBar = 'getSalesByVariantsSearchBar?outletId='
const generateReportPDF = 'generateReportPDF'
const emailReportPdf = 'emailReportPdf'
const getOutletCategory = 'getOutletCategory?outletId='
const getOneSettingRedemption = 'getOneSettingRedemption?settingId='
const allTransactionSort = 'allTransactionsSort?merchantId='
const getStockSearch = 'getStockSearch?outletId='
const getShiftTransaction = "getShiftTransaction?shiftId="
const sendShiftEmail = "sendShiftEmail"
const cancelTransaction = "cancelTransaction?orderId="
const getAllOutletShift = 'getAllOutletShift?outletId='
const getShiftTotal = "getShiftTotal?shiftId="
const getStockFilter = 'getStockFilter?outletId='
const salesByOvertimeFilter = 'salesByOvertimeFilter?merchantId='
const exportDataCSV = "exportDataCSV"
const dashboardSaleTodayCalculation = 'dashboardSaleTodayCalculation?merchantId='
const dashboardSaleToday = 'dashboardSaleToday?merchantId='
const dashboardSaleOutletToday = 'dashboardSaleOutletToday'
const emailDashboard = 'emailDashboard'
const orderDeliver = 'orderDeliver/'
const orderDeliverMultiple = 'orderDeliverMultiple/';
const orderDeliverMultipleSummary = 'orderDeliverMultipleSummary/';
const tableExchange = 'tableExchange'
const merchantTax = 'merchantTax/findoutletId?findoutletId='
const orderDeliverUndo = 'orderDeliverUndo/'
const orderDeliverUndoMultiple = 'orderDeliverUndoMultiple/';
const orderDeliverUndoMultipleSummary = 'orderDeliverUndoMultipleSummary/';
const getAllSectionTable = 'getAllSectionTable'
const updateOrder = 'updateOrder'
const updateRole = 'user/'
const emailEmployee = 'emailEmployee'

const getAllSegments = 'getAllSegments';
const createGiftCardApplication = 'createGiftCardApplication';

///////////////////////////////////////////////////////

const legacyLogin = 'legacyLogin';

const createOutletSection = 'createOutletSection';
const deleteOutletSection = 'deleteOutletSection';

const createOutletTable = 'createOutletTable';
const updateOutletTable = 'updateOutletTable';
const joinOutletTable = 'joinOutletTable';
const splitOutletTable = 'splitOutletTable';
const deleteOutletTable = 'deleteOutletTable';

const createUserOrder = 'createUserOrder';
const splitUserOrderBill = 'splitUserOrderBill';
const splitAndJoinUserOrder = 'splitAndJoinUserOrder';

const checkoutOutletTable = 'checkoutOutletTable';
const checkoutOutletTableV2 = 'checkoutOutletTableV2';

const createPurchaseOrder = 'createPurchaseOrder';
const updatePurchaseOrder = 'updatePurchaseOrder';
const duplicatePurchaseOrder = 'duplicatePurchaseOrder';

const changeOutletItemCategory = 'changeOutletItemCategory';

const updateStockIdealWarning = 'updateStockIdealWarning';

const openOutletShift = 'openOutletShift';
const closeOutletShift = 'closeOutletShift';

const createOutletTax = 'taxes/createOutletTax';
const deleteOutletTax = 'taxes/deleteOutletTax';

const createOutletItem = 'createOutletItem';
const updateOutletItem = 'updateOutletItem';
const deleteOutletItem = 'deleteOutletItem';
const batchCreateOutletItems = 'batchCreateOutletItems';

const createOutletEmployee = 'createOutletEmployee';
const updateOutletEmployee = 'updateOutletEmployee';

const orderDeliverAll = 'orderDeliverAll/';

const createOutlet = 'createOutlet';

const createSupplier = 'createSupplier';
const updateSupplier = 'updateSupplier';
const batchCreateOutletSupplyItems = 'batchCreateOutletSupplyItems';

const createOutletItemCategory = 'createOutletItemCategory';
const updateOutletItemCategory = 'updateOutletItemCategory';
const deleteOutletItemCategory = 'deleteOutletItemCategory';

const createStockTransfer = 'createStockTransfer';
const updateStockTransfer = 'updateStockTransfer';

const createStockTake = 'createStockTake';
const updateStockTake = 'updateStockTake';
const deleteStockTake = 'deleteStockTake';

const updateOutletOrderDetails = 'updateOutletOrderDetails';

const cancelUserOrderByMerchant = 'cancelUserOrderByMerchant';
const authorizeUserOrderByMerchant = 'authorizeUserOrderByMerchant';

const updateOutletDetails = 'updateOutletDetails';

const insertInventory = 'insertInventory';
const removeOutletSupplyItem = 'removeOutletSupplyItem';

const updateUserCart = 'updateUserCart';
const deleteUserCart = 'deleteUserCart';

const cancelUserOrderItem = 'cancelUserOrderItem';
const undoUserOrderItemCancelled = 'undoUserOrderItemCancelled';
const cancelUserOrderItemSummary = 'cancelUserOrderItemSummary';
const undoUserOrderItemCancelledSummary = 'undoUserOrderItemCancelledSummary';

const updateTokenFcm = 'updateTokenFcm';
 
const createBeerDocket = 'createBeerDocket';
const updateBeerDocket = 'updateBeerDocket';
const updateUserBeerDocketExpiryDateByMerchant = 'updateUserBeerDocketExpiryDateByMerchant';

const switchOutletItemActiveStatus = 'switchOutletItemActiveStatus';

const restoreUserOrder = 'restoreUserOrder';

const createPromotion = 'createPromotion';
const updatePromotion = 'updatePromotion';
const deletePromotion = 'deletePromotion';
const switchPromotionActiveStatus = 'switchPromotionActiveStatus';
const pushPromotionNotificationManual = 'pushPromotionNotificationManual';
const switchPromotionNotificationAutoPushStatus = 'switchPromotionNotificationAutoPushStatus';
const switchPromotionNotificationLocationBasedStatus = 'switchPromotionNotificationLocationBasedStatus';

const createCRMUser = 'createCRMUser';
const deleteCRMUser = 'deleteCRMUser';
const batchCreateCRMUsers = 'batchCreateCRMUsers';
const updateCRMUser = 'updateCRMUser';
const createCRMUserTag = 'createCRMUserTag';
const addUserToCRMUserTag = 'addUserToCRMUserTag';
const removeUserFromCRMUserTag = 'removeUserFromCRMUserTag';
const createCRMUserTagOrAddUserToCRMUserTag = 'createCRMUserTagOrAddUserToCRMUserTag';
const createCRMUserTagOrAddCRMUserTagToProduct = 'createCRMUserTagOrAddCRMUserTagToProduct';
const createCRMSegment = 'createCRMSegment';
const createCRMSegmentOrAddTagToCRMSegment = 'createCRMSegmentOrAddTagToCRMSegment';
const updateCRMSegmentOrAddTagToCRMSegment = 'updateCRMSegmentOrAddTagToCRMSegment';

const createPreorderPackage = 'createPreorderPackage';
const updatePreorderPackage = 'updatePreorderPackage';
const switchPreorderPackageStatus = 'switchPreorderPackageStatus';

const lalamoveQuotation = 'lalamoveQuotation';
const mrSpeedyCalculateOrder = 'mrSpeedyCalculateOrder';

const updateUserOrderCourier = 'updateUserOrderCourier';
const notifyUserOrderCourierAction = 'notifyUserOrderCourierAction';

const createPointsRedeemPackage = 'createPointsRedeemPackage';
const updatePointsRedeemPackage = 'updatePointsRedeemPackage';
const deletePointsRedeemPackage = 'deletePointsRedeemPackage';

const updateCRMUserRedeemPackageDisableDict = 'updateCRMUserRedeemPackageDisableDict';

const createMerchantVoucher = 'createMerchantVoucher';
const updateMerchantVoucher = 'updateMerchantVoucher';
const updateMerchantVoucherStatus = 'updateMerchantVoucherStatus';
const duplicateMerchantVoucher = 'duplicateMerchantVoucher';

const updateOutletLoyaltyPointsRate = 'updateOutletLoyaltyPointsRate';

const emailReport = 'emailReport';

const createUserReservation = 'createUserReservation';
const createUserReservationByMerchant = 'createUserReservationByMerchant';

const updateOutletItemOrderIndex = 'updateOutletItemOrderIndex';

const createOutletPrinter = 'createOutletPrinter';
const updateOutletPrinter = 'updateOutletPrinter';
const deleteOutletPrinter = 'deleteOutletPrinter';

const removeOutletItemOwnCategory = 'removeOutletItemOwnCategory';

const clockInEmployeeDateTime = 'clockInEmployeeDateTime';
const clockOutEmployeeDateTime = 'clockOutEmployeeDateTime';

const createOutletPaymentMethod = 'createOutletPaymentMethod';
const updateOutletPaymentMethod = 'updateOutletPaymentMethod';
const toggleOutletPaymentMethodStatus = 'toggleOutletPaymentMethodStatus';
const deleteOutletPaymentMethod = 'deleteOutletPaymentMethod';

const createSupportTicket = 'createSupportTicket';

const createLoyaltyStamp = 'createLoyaltyStamp';
const updateLoyaltyStamp = 'updateLoyaltyStamp';

const createLoyaltyCampaign = 'createLoyaltyCampaign';
const updateLoyaltyCampaign = 'updateLoyaltyCampaign';
const deleteLoyaltyCampaign = 'deleteLoyaltyCampaign';
const switchLoyaltyCampaignActiveStatus = 'switchLoyaltyCampaignActiveStatus';
// const pushPromotionNotificationManual = 'pushPromotionNotificationManual';
// const switchPromotionNotificationAutoPushStatus = 'switchPromotionNotificationAutoPushStatus';
// const switchPromotionNotificationLocationBasedStatus = 'switchPromotionNotificationLocationBasedStatus';

const loyaltyCampaignAddCashback = 'loyaltyCampaignAddCashback';
const loyaltyCampaignAddCredit = 'loyaltyCampaignAddCredit';

const logoutUser = 'logoutUser';

const getTokenKWeb = 'getTokenKWeb?firebaseUid=';

const loginKoodooCRMByGoogleAccount = 'loginKoodooCRMByGoogleAccount';
const getTokenKCRM = 'getTokenKCRM?email=';

///////////////////////////////////////////////////////

const API = {
  emailDashboard,
  emailEmployee,
  waiterCheckOrder,
  notifiedOutletRing,
  attendOutletRing,
  userRingOutlet,
  getOutletRingList,
  getOutletRing,
  orderDonePayment,
  getCategory,
  changeItem,
  deleteSectionTable,
  getDefaultPayment,
  getReceipt,
  getToken,
  getAllOutletTags,
  getOutletByTags,
  outletSearch,
  outlet2,
  merchantMenu,
  getItemAddOnChoice,
  getTakeAway,
  getAllUserQueue,
  orderHistory2,
  order2,
  allOrder,
  outletSearchByName,
  userAdmin,
  initialRegister,
  getReservationList,
  getCurrentOrder,
  acceptReservation,
  requestReservation,
  queue,
  createUserQueueByMerchant,
  updateUserQueueByMerchant,
  cancel,
  cancelReservation,
  getPopular,
  createOrder,
  addTable,
  getTable,
  getAllOutletQueue,
  notifyQueue,
  switchQueueStatus,
  getUserPhone,
  acceptQueue,
  servedQueue,
  seatedQueue,
  noShowQueue,
  cancelQueue,
  getOutletReservation,
  switchReservationStatus,
  seatedReservation,
  noShowReservation,
  undoReservation,
  getCurrentTakeAwayOrder,
  getPreparationTimes,
  getUserReservationPhone,
  registerQueue,
  deleteMenuItem,
  addMenu,
  menuItem,
  getItemByOutletTEST,
  taxes,
  deleteTax,
  addOutlet,
  getOutletByMerchant,
  addCustomer,
  deleteCustomer,
  openShift,
  getCurrentShift,
  closeShift,
  addCurrentMenu,
  editOutlet,
  searchBarItem,
  searchBarEmployee,
  getCurrentOutletOrder,
  acceptQueue,
  getEmployee,
  addEmployee,
  getLeftItem,
  activeMenu,
  tableSection,
  sectionTable,
  postSection,
  postTable,
  createVoucher,
  getPreperationTime,
  getCurrentOrderItem,
  getVoucher,
  getVoucherCount,
  editVoucher,
  getCheckItem,
  checkItemPreparation,
  getOutletOrder,
  tableOrder,
  createStockOrder,
  getStockOrder,
  editStockOrder,
  editStockTransfer,
  confirmStockOrder,
  getLowStock,
  settingLowStock,
  setActiveMenu,
  getTransfer,
  getOrderDetails,
  orderDone,
  qrTableGenerate,
  userResetPassword,
  createUserParking,
  editSettingOrder,
  getSettingOrder,
  editSettingRedemption,
  getSettingRedemption,
  getAllSettingRedemption,
  createSettingRedemption,
  createSettingOrder,
  prioritizeOrder,
  checkStockOrder,
  checkStockOrderDelete,
  emailStockTransfer,
  deleteTable,
  getTableDetails,
  salesByTransactionCustom,
  salesByTransactionCategory,
  salesByCategoryAll,
  salesByTransactionChannelSearchBar,
  getSalesBySku,
  salesBySkuSearchBar,
  getSalesByPayment,
  getSalesByPaymentSearchCategory,
  salesByCategorySearchBar,
  getSalesByVariants,
  getSalesByVariantsSearchBar,
  generateReportPDF,
  emailReportPdf,
  getOutletCategory,
  getOneSettingRedemption,
  allTransactionSort,
  getStockSearch,
  getShiftTransaction,
  sendShiftEmail,
  cancelTransaction,
  getAllOutletShift,
  getShiftTotal,
  getStockFilter,
  salesByOvertimeFilter,
  exportDataCSV,
  dashboardSaleTodayCalculation,
  dashboardSaleToday,
  dashboardSaleOutletToday,
  orderDeliver,
  orderDeliverMultiple,
  orderDeliverMultipleSummary,
  tableExchange,
  orderDeliverUndo,
  orderDeliverUndoMultiple,
  orderDeliverUndoMultipleSummary,
  getAllSectionTable,
  updateOrder,
  merchantTax,
  updateRole,

  getAllSegments,
  createGiftCardApplication,

  legacyLogin,

  createOutletSection,
  deleteOutletSection,

  createOutletTable,
  updateOutletTable,
  joinOutletTable,
  splitOutletTable,
  deleteOutletTable,

  createUserOrder,
  splitUserOrderBill,
  splitAndJoinUserOrder,

  checkoutOutletTable,
  checkoutOutletTableV2,

  createPurchaseOrder,
  updatePurchaseOrder,
  duplicatePurchaseOrder,

  changeOutletItemCategory,

  updateStockIdealWarning,

  openOutletShift,
  closeOutletShift,

  createOutletTax,
  deleteOutletTax,

  createOutletItem,
  updateOutletItem,
  deleteOutletItem,
  batchCreateOutletItems,

  createOutletEmployee,
  updateOutletEmployee,

  orderDeliverAll,

  createOutlet,

  createSupplier,
  updateSupplier,
  batchCreateOutletSupplyItems,

  createOutletItemCategory,
  updateOutletItemCategory,
  deleteOutletItemCategory,

  createStockTransfer,
  updateStockTransfer,

  createStockTake,
  updateStockTake,
  deleteStockTake,

  updateOutletOrderDetails,

  cancelUserOrderByMerchant,
  authorizeUserOrderByMerchant,

  updateOutletDetails,

  insertInventory,
  removeOutletSupplyItem,

  updateUserCart,
  deleteUserCart,

  cancelUserOrderItem,
  undoUserOrderItemCancelled,
  cancelUserOrderItemSummary,
  undoUserOrderItemCancelledSummary,

  updateTokenFcm,

  createBeerDocket,
  updateBeerDocket,
  updateUserBeerDocketExpiryDateByMerchant,

  switchOutletItemActiveStatus,

  restoreUserOrder,

  createPromotion,
  updatePromotion,
  deletePromotion,
  switchPromotionActiveStatus,
  pushPromotionNotificationManual,
  switchPromotionNotificationAutoPushStatus,
  switchPromotionNotificationLocationBasedStatus,

  createCRMUser,
  deleteCRMUser,
  batchCreateCRMUsers,
  updateCRMUser,
  createCRMUserTag,
  addUserToCRMUserTag,
  removeUserFromCRMUserTag,
  createCRMUserTagOrAddUserToCRMUserTag,
  createCRMUserTagOrAddCRMUserTagToProduct,
  createCRMSegment,
  createCRMSegmentOrAddTagToCRMSegment,
  updateCRMSegmentOrAddTagToCRMSegment,

  createPreorderPackage,
  updatePreorderPackage,
  switchPreorderPackageStatus,

  lalamoveQuotation,
  mrSpeedyCalculateOrder,

  updateUserOrderCourier,
  notifyUserOrderCourierAction,

  createPointsRedeemPackage,
  updatePointsRedeemPackage,
  deletePointsRedeemPackage,

  updateCRMUserRedeemPackageDisableDict,

  createMerchantVoucher,
  updateMerchantVoucher,
  updateMerchantVoucherStatus,
  duplicateMerchantVoucher,

  updateOutletLoyaltyPointsRate,

  emailReport,

  createUserReservation,
  createUserReservationByMerchant,

  updateOutletItemOrderIndex,

  createOutletPrinter,
  updateOutletPrinter,
  deleteOutletPrinter,

  removeOutletItemOwnCategory,

  clockInEmployeeDateTime,
  clockOutEmployeeDateTime,

  createOutletPaymentMethod,
  updateOutletPaymentMethod,
  toggleOutletPaymentMethodStatus,
  deleteOutletPaymentMethod,

  createSupportTicket,

  createLoyaltyStamp,
  updateLoyaltyStamp,

  createLoyaltyCampaign,
  updateLoyaltyCampaign,
  deleteLoyaltyCampaign,
  switchLoyaltyCampaignActiveStatus,

  loyaltyCampaignAddCashback,
  loyaltyCampaignAddCredit,

  logoutUser,

  getTokenKWeb,

  loginKoodooCRMByGoogleAccount,
  getTokenKCRM,
};

export default API;
