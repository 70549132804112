import React, {
  Component,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Modal,
  useWindowDimensions,
} from "react-native";
import Colors from "../constant/Colors";
import SideBar from "./SideBar";
import Icon from "react-native-vector-icons/Feather";
import AntDesign from "react-native-vector-icons/AntDesign";
import Ionicon from "react-native-vector-icons/Ionicons";
import SimpleLineIcons from "react-native-vector-icons/SimpleLineIcons";
import { TextInput, FlatList } from "react-native-gesture-handler";
import DropDownPicker from "react-native-dropdown-picker";
import { Picker } from "react-native";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import API from "../constant/API";
import ApiClient from "../util/ApiClient";
import TimeKeeper from "react-timekeeper";
// image picker need solve
// import { launchImageLibrary } from "react-native-image-picker";
import * as User from "../util/User";
import LoginScreen from "./LoginScreen";
import AsyncStorage from "@react-native-async-storage/async-storage";
// import CheckBox from 'react-native-check-box';
// import { color } from 'react-native-reanimated';
import AIcon from "react-native-vector-icons/AntDesign";
import Styles from "../constant/Styles";
import moment, { isDate } from "moment";
import Switch from "react-switch";
import Ionicons from "react-native-vector-icons/Ionicons";
import { UserStore } from "../store/userStore";
import { MerchantStore } from "../store/merchantStore";
import { OutletStore } from "../store/outletStore";
import Select from "react-select";
import {
  RESET_DATA_TYPE,
  USER_ORDER_PAYMENT_OPTIONS,
  WEEK,
} from "../constant/common";
// import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import Autocomplete from "react-google-autocomplete";
import AsyncImage from "../components/asyncImage";
import { uploadImageToFirebaseStorage } from "../util/common";
import { CommonStore } from "../store/commonStore";
import {
  // USBPrinter,
  NetPrinter,
  BLEPrinter,
} from "react-native-thermal-receipt-printer-image-qr";
import { CODEPAGE, ESCPOS_CMD } from "../constant/printer";
import {
  connectToPrinter,
  convertUtf8ArrayToStr,
  printBarcode,
} from "../util/printer";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
// import DeviceInfo from 'react-native-device-info';
import { useFilePicker } from "use-file-picker";


const SettingsScreen = (props) => {
  const { navigation } = props;

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  const [selfCollect, setSelfCollect] = useState(true);
  const [openHourPickerVisible, setOpenHourPickerVisible] = useState(false);
  const [closeHourPickerVisible, setCloseHourPickerVisible] = useState(false);
  const [openHour, setOpenHour] = useState("");
  const [closeHour, setCloseHour] = useState("");
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked5, setIsChecked5] = useState(false);
  const [isChecked6, setIsChecked6] = useState(false);
  const [isChecked7, setIsChecked7] = useState(false);
  const [isChecked8, setIsChecked8] = useState(false);
  const [isChecked9, setIsChecked9] = useState(false);
  const [isChecked10, setIsChecked10] = useState(false);
  const [isChecked11, setIsChecked11] = useState(false);
  const [isChecked12, setIsChecked12] = useState(false);
  const [isChecked13, setIsChecked13] = useState(false);
  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [amount, setAmount] = useState("");
  const [hourStart, setHourStart] = useState("");
  const [hourEnd, setHourEnd] = useState("");
  const [days, setDays] = useState(false);
  const [days1, setDays1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDistance, setShowDistance] = useState("");
  const [expiryPeriod, setExpiryPeriod] = useState("");
  const [extentionCharges, setExtentionCharges] = useState("");
  const [extentionDuration, setExtentionDuration] = useState("");
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [pickerMode, setPickerMode] = useState("datetime");
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [merchantDisplay, setMerchantDisplay] = useState(true);
  const [shift, setShift] = useState(false);
  const [tax, setTax] = useState(false);
  const [sample, setSample] = useState(false);
  const [redemption, setRedemption] = useState(false);
  const [redemptionList, setRedemptionList] = useState(true);
  const [redemptionAdd, setRedemptionAdd] = useState(false);
  const [order, setOrder] = useState(false);
  const [previousState, setPreviousState] = useState(false);
  const [receipt, setReceipt] = useState([]);
  const [detail, setDetail] = useState([]);
  const [number, setNumber] = useState([]);
  const [merchantInfo, setMerchantInfo] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [outletInfo, setOutletInfo] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const [outletId, setOutletId] = useState([]);
  const [merInfo, setMerInfo] = useState([]);
  // const [merchantId, setMerchantId] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [closingAmount, setClosingAmount] = useState("");
  const [options, setOptions] = useState([]);
  const [shift1, setShift1] = useState([]);
  const [status, setStatus] = useState(false);
  const [statusHalal, setStatusHalal] = useState(false);

  const [logo, setLogo] = useState("");
  const [logoType, setLogoType] = useState("");
  const [logoSelected, setLogoSelected] = useState(false);
  const [cover, setCover] = useState("");
  const [coverType, setCoverType] = useState("");
  const [coverSelected, setCoverSelected] = useState(false);

  const [openFileSelector, { plainFiles, filesContent, loading: loadingImageInput, clear: clearImageContainer, errors }] = useFilePicker({
    readAs: 'DataURL',
    accept: ["image/*"],
    multiple: false,
  });

  // select image
  useEffect(() => {
    if (plainFiles.length && filesContent.length && !loadingImageInput) {
      if (logoSelected === true) {
        setLogo(filesContent[0].content);
        setLogoType(filesContent[0].name.slice(filesContent[0].name.lastIndexOf('.')));
        setLogoSelected(false);
      } else {
        setCover(filesContent[0].content);
        setCoverType(filesContent[0].name.slice(filesContent[0].name.lastIndexOf('.')));
        setCoverSelected(false);
      }

    }

    if (errors.length)
      console.error(errors)

  }, [plainFiles, filesContent, loadingImageInput, errors])

  const [name, setName] = useState("");
  const [tname, setTname] = useState("");
  const [rate, setRate] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [payment, setPayment] = useState("");
  const [time, setTime] = useState("");
  const [statue, setStatue] = useState("");
  const [status1, setStatus1] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [outletId, setOutletId] = useState(null);
  const [myTextInput, setMyTextInput] = useState(React.createRef());
  const [start_time, setStart_time] = useState(false);
  const [end_time, setEnd_time] = useState(false);
  const [rev_time, setRev_time] = useState("");
  const [category, setCategory] = useState("");
  const [close, setClose] = useState("Closed");
  const [showNote, setShowNote] = useState(false);
  const [expandView, setExpandView] = useState(false);
  const [value, setValue] = useState("");
  const [extendOption, setExtendOption] = useState([
    { optionId: 1, price: 20, day: 7, days: false },
  ]);
  const [redemptionInfo, setRedemptionInfo] = useState([]);
  const [alloutlet, setAlloutlet] = useState([]);
  const [discount, setDiscount] = useState("");
  const [amount1, setAmount1] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [categoryOutlet, setCategoryOutlet] = useState([]);
  const [extend, setExtend] = useState([]);
  const [outletss, setOutletss] = useState([]);
  const [redemptionDetail, setRedemptionDetail] = useState([]);
  const [outletInfo1, setOutletInfo1] = useState([]);
  const [category1, setCategory1] = useState([]);
  const [merchantName, setMerchantName] = useState("");
  const [addOutletName, setAddOutletName] = useState("");
  const [addOutletWindow, setAddOutletWindow] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [note1, setNote1] = useState("");
  const [note2, setNote2] = useState("");
  const [note3, setNote3] = useState("");
  const [openings, setOpenings] = useState([]);
  const [editOpeningIndex, setEditOpeningIndex] = useState(0);

  const [editOpeningType, setEditOpeningType] = useState("start");
  const [outletLat, setOutletLat] = useState(0);
  const [outletLng, setOutletLng] = useState(0);

  const inputAcRef = useRef(null);

  const [isLogoChanged, setIsLogoChanged] = useState(false);
  const [isCoverChanged, setIsCoverChanged] = useState(false);

  const [dropDownResetList, setDropDownResetList] = useState([]);

  const [deliveryPaymentDropDown, setDeliveryPaymentDropDown] = useState([
    {
      label: "Online",
      value: USER_ORDER_PAYMENT_OPTIONS.ONLINE,
    },
    {
      label: "Offline",
      value: USER_ORDER_PAYMENT_OPTIONS.OFFLINE,
    },
    {
      label: "All",
      value: USER_ORDER_PAYMENT_OPTIONS.ALL,
    },
  ]);
  const [resetDropDown, setResetDropDown] = useState([
    {
      label: "Order",
      value: RESET_DATA_TYPE.USER_ORDER,
    },
    {
      label: "Customer",
      value: RESET_DATA_TYPE.CRM_USER,
    },
  ]);

  // halal toggle and pork free toggle useState
  const [isEnabled, setIsEnabled] = useState(false);
  const toggleSwitch = () => setIsEnabled((previousState) => !previousState);

  const [printerName, setPrinterName] = useState("Printer Name");
  const [printerIP, setPrinterIP] = useState("192.168.1.254");

  const [sstRate, setSstRate] = useState("6");
  const [scRate, setScRate] = useState("5");
  const [sstActive, setSstActive] = useState(true);
  const [scActive, setScActive] = useState(false);

  /////////////////////////////////////////////////////////////////////

  const [pickupPaymentOptions, setPickupPaymentOptions] = useState(
    USER_ORDER_PAYMENT_OPTIONS.ONLINE
  );
  const [deliveryPaymentOptions, setDeliveryPaymentOptions] = useState(
    USER_ORDER_PAYMENT_OPTIONS.ONLINE
  );
  const [dineinPaymentOptions, setDineinPaymentOptions] = useState(
    USER_ORDER_PAYMENT_OPTIONS.OFFLINE
  );

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState("");

  const [currOutletOpening, setCurrOutletOpening] = useState({});

  const userName = UserStore.useState((s) => s.name);
  const merchantNameHeader = MerchantStore.useState((s) => s.name);

  const allOutlets = MerchantStore.useState((s) => s.allOutlets);
  const currOutlet = MerchantStore.useState((s) => s.currOutlet);
  const currOutletId = MerchantStore.useState((s) => s.currOutletId);
  const merchantLogo = MerchantStore.useState((s) => s.logo);
  const merchantShortcode = MerchantStore.useState((s) => s.shortcode);

  const outletsOpeningDict = OutletStore.useState((s) => s.outletsOpeningDict);

  const merchantId = UserStore.useState((s) => s.merchantId);

  const setState = () => { };

  useEffect(() => {
    setMerchantName(merchantNameHeader);
    setLogo(merchantLogo);
  }, [merchantNameHeader, merchantLogo]);

  useEffect(() => {
    if (
      currOutlet &&
      currOutlet.uniqueId &&
      currOutletId === currOutlet.uniqueId
    ) {
      setAddress(currOutlet.address);
      setPhone(currOutlet.phone ? currOutlet.phone.toString() : "");
      setEmail(currOutlet.email ? currOutlet.email.toString() : "");

      setStatus(currOutlet.isPickupAccepted);
      setStatus1(currOutlet.isDeliveryAccepted);
      setStatusHalal(currOutlet.isHalal ? currOutlet.isHalal : false);

      setCover(currOutlet.cover);

      setOutletLat(currOutlet.lat);
      setOutletLng(currOutlet.lng);

      inputAcRef &&
        inputAcRef.current &&
        inputAcRef.current.setAddressText(currOutlet.address);

      if (outletsOpeningDict[currOutletId]) {
        setCurrOutletOpening(outletsOpeningDict[currOutletId]);
      }

      setPickupPaymentOptions(currOutlet.pickupPaymentOptions);
      setDeliveryPaymentOptions(currOutlet.deliveryPaymentOptions);
      setDineinPaymentOptions(currOutlet.dineinPaymentOptions);

      setSstRate((currOutlet.taxRate * 100).toFixed(0));
      setScRate((currOutlet.scRate * 100).toFixed(0));
      setSstActive(currOutlet.taxActive);
      setScActive(currOutlet.scActive);
    }
  }, [currOutlet, currOutletId, outletsOpeningDict, inputAcRef]);

  const outletSelectDropdownView = CommonStore.useState(
    (s) => s.outletSelectDropdownView
  );

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View
        style={{
          width: windowWidth * 0.17,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require("../assets/image/logo.png")}
        />
      </View>
    ),
    headerTitle: () => (
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            // bottom: -2,
            bottom: switchMerchant ? "2%" : 0,
          },
          windowWidth >= 768 && switchMerchant
            ? { right: windowWidth * 0.1 }
            : {},
          windowWidth <= 768 ? { right: windowWidth * 0.12 } : {},
        ]}
      >
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: "center",
            fontFamily: "NunitoSans-Bold",
            color: Colors.whiteColor,
            opacity: 1,
          }}
        >
          General Settings
        </Text>
      </View>
    ),
    headerRight: () => (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {outletSelectDropdownView()}
        <View
          style={{
            backgroundColor: "white",
            width: 0.5,
            height: windowHeight * 0.025,
            opacity: 0.8,
            marginHorizontal: 15,
            bottom: -1,
          }}
        ></View>
        <TouchableOpacity
          onPress={() => navigation.navigate("Setting")}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <Text
            style={{
              fontFamily: "NunitoSans-SemiBold",
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}
          >
            {userName}
          </Text>
          <View
            style={{
              marginRight: 30,
              width: windowHeight * 0.05,
              height: windowHeight * 0.05,
              borderRadius: windowHeight * 0.05 * 0.5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
            }}
          >
            <Image
              style={{
                width: windowHeight * 0.035,
                height: windowHeight * 0.035,
                alignSelf: "center",
              }}
              source={require("../assets/image/profile-pic.jpg")}
            />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  useEffect(() => {
    loadFromAsyncStorage();
  }, []);

  const loadFromAsyncStorage = async () => {
    const printerIPRaw = await AsyncStorage.getItem("printerIP");

    if (printerIPRaw) {
      setPrinterIP(printerIPRaw);
    }
  };

  const getRedemptionDetail = (param) => {
    // ApiClient.GET(API.getOneSettingRedemption + param).then((result) => {
    //   setState({
    //     redemptionDetail: result,
    //     outletInfo1: result.outlet.name,
    //     category1: result.category.name,
    //     expiryPeriod: result.expiryPeriod,
    //     extentionCharges: result.extentionCharges,
    //     extentionDuration: result.extentionDuration,
    //   });
    // });
  };

  // componentDidMount = () => {

  //   ApiClient.GET(API.getOutletByMerchant + User.getMerchantId()).then((result) => {
  //     setState({ outletInfo: result });
  //     result.map((element) => {
  //       setState({
  //         outletId: element.id,
  //         outletName: element.name,
  //         merchantName: element.merchant.name
  //       });
  //     });
  //   });

  //   ApiClient.GET(API.getAllSettingRedemption + User.getMerchantId()).then((result) => {
  //     setState({ redemptionInfo: result });
  //   });

  //   outletFunc()
  //   ApiClient.GET(API.getOutletCategory + User.getOutletId()).then((result) => {
  //     if (result !== undefined) {
  //       setState({ categoryOutlet: result });
  //     }

  //   });
  // }

  //   let version = DeviceInfo.getVersion();

  const pushArray1 = (param) => {
    const push = outletss;
    push.push({
      outletId: param,
    });
  };

  const removePush1 = (param) => {
    const items = outletss;
    const filterArray = items.filter((item) => item.id !== param);
    setState({ alloutlet: filterArray });
  };

  const check3 = (param) => {
    if (isChecked12 == false) {
      setState({ isChecked12: true });
      removePush1(param);
    } else {
      setState({ isChecked12: false });
      pushArray1(param);
    }
  };

  const filter = (param) => {
    // ApiClient.GET(API.getAllSettingRedemption + User.getMerchantId()).then((result) => {
    //   const result1 = items.filter((item) => item.outletId !== param)
    //   setState({ redemptionInfo: result1 });
    // });
  };

  const check1 = (price, duration) => {
    if (isChecked11 == false) {
      setState({
        isChecked11: true,
        extentionCharges: "0",
        extentionDuration: "0",
      });
    } else {
      setState({
        isChecked11: false,
        extentionCharges: price,
        extentionDuration: duration,
      });
    }
  };

  const outletFunc = () => {
    // ApiClient.GET(API.getOutletByMerchant).then((result) => {
    //   const tmpCategories = {};
    //   for (const category of result) {
    //     const outletName = category.merchant.name
    //     const outletId = category.id
    //     if (!tmpCategories[outletName]) {
    //       tmpCategories[outletName] = {
    //         label: outletName,
    //         value: outletId,
    //       };
    //     }
    //   }
    //   const categories = Object.values(tmpCategories);
    //   setState({ outlets: categories });
    // });
  };

  const onaddoption = () => {
    const extendOption = extendOption;
    extendOption.push({
      optionId: (extendOption.length + 1).toString(),
      price: "",
      day: "",
      days: days,
    });
    setState({ extendOption });
  };

  const renderOptions = () => {
    const options = [];
    const extendOption = extendOption;
    for (const opt of extendOption) {
      options.push(
        <View>
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <CheckBox
                style={{
                  padding: 10,
                }}
                onClick={() => {
                  setState({
                    isChecked11: !isChecked11,
                  });
                  check1(extentionCharges, extentionDuration);
                }}
                checkBoxColor={Colors.fieldtBgColor}
                uncheckedCheckBoxColor={Colors.tabGrey}
                checkedCheckBoxColor={Colors.primaryColor}
                isChecked={isChecked11}
              /> */}
            </View>
            <View>
              <View>
                <Text style={{ color: Colors.descriptionColor }}>RM:</Text>
                <TextInput
                  underlineColorAndroid={Colors.fieldtBgColor}
                  style={styles.textInput8}
                  placeholder="Amount"
                  defaultValue={extentionCharges}
                  onChangeText={(text) => {
                    const extendOption = extendOption;
                    const item = extendOption.find(
                      (obj) => obj.id === extendOption.id
                    );
                    item.price = text;
                    setState({ extendOption, extentionCharges: text });
                  }}
                  value={(value) => {
                    const extendOption = extendOption;
                    const item = extendOption.find(
                      (obj) => obj.id === extendOption.id
                    );
                    value = item.price;
                  }}
                  ref={myTextInput}
                />
              </View>
            </View>
            <View
              style={{
                width: "6%",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "3%",
              }}
            >
              <Text style={{ fontSize: 15 }}>For</Text>
            </View>
            <View style={{ marginLeft: "5%" }}>
              <Text
                style={{
                  color: Colors.descriptionColor,
                  fontSize: 15,
                }}
              >
                Durations:
              </Text>
              <View style={styles.textInput10}>
                <View style={{ flex: 1 }}>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={{ alignSelf: "center" }}
                    placeholder="Period"
                    defaultValue={extentionDuration}
                    onChangeText={(text) => {
                      const extendOption = extendOption;
                      const item = extendOption.find(
                        (obj) => obj.id === extendOption.id
                      );
                      item.day = text;
                      setState({ extendOption, extentionDuration: text });
                    }}
                    value={(value) => {
                      const extendOption = extendOption;
                      const item = extendOption.find(
                        (obj) => obj.id === extendOption.id
                      );
                      value = item.day;
                    }}
                    ref={myTextInput}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    borderLeftWidth: StyleSheet.hairlineWidth,
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      marginTop: 15,
                      marginLeft: "5%",
                      color: Colors.descriptionColor,
                    }}
                  >
                    {days1 == false ? "Days" : "Months"}
                  </Text>
                  <TouchableOpacity
                    onPress={() => {
                      setState({ days1: !days1 });
                    }}
                  >
                    <View
                      style={{
                        marginLeft: "30%",
                        marginTop: 18,
                      }}
                    >
                      <SimpleLineIcons name="arrow-down" size={12} />
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>
        </View>
      );
    }
    return options;
  };

  const orderFunc = () => {
    var body = {
      merchantId: User.getMerchantId(),
      isAllOutlet: isChecked6 == true ? "1" : null,
      outletId: outletId,
      deliveryDistance: showDistance,
      deliveryFee: isChecked6 == true ? amount : amount1,
      deliveryHourStart: hourStart,
      deliveryHourEnd: hourEnd,
      deliveryPrice: isChecked8 == true ? value1 : 0,
      pickUpPrice: isChecked9 == true ? value2 : 0,
      fireorder: status1,
      category: category,
    };
    // ApiClient.POST(API.createSettingOrder, body, false).then((result) => {
    //   if (result.outletId != null) {
    //     Alert.alert(
    //       'Congratulation!',
    //       'You Have Successfully Created',
    //       [
    //         {
    //           text: 'OK',
    //           onPress: () => { },
    //         },
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  const renderItem = ({ item }) => (
    <View
      style={{
        backgroundColor: "#ffffff",
        borderRadius: 5,
        flexDirection: "row",
        paddingVertical: 20,
        paddingHorizontal: 20,
        borderTopWidth: StyleSheet.hairlineWidth,
        borderTopColor: "#c4c4c4",
      }}
    >
      <Text
        style={{
          paddingLeft: 15,
          flex: 2,
          alignSelf: "center",
          fontFamily: "NunitoSans-SemiBold",
          fontSize: 18,
        }}
      >
        {item.outlet.name}
      </Text>
      <View
        style={{
          flex: 2,
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        <Text
          style={{
            fontSize: 16,
            fontFamily: "NunitoSans-SemiBold",
            marginRight: "20%",
          }}
        >
          {item.category == null ? "Unknown" : item.category.name}
        </Text>
      </View>
      <View
        style={{
          flex: 2,
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            alignSelf: "center",
            fontSize: 16,
            fontFamily: "NunitoSans-SemiBold",
          }}
        >
          {item.expiryPeriod} Days
        </Text>
      </View>
      <View
        style={{
          flex: 4,
        }}
      >
        <View
          style={{
            height: 50,
            width: "80%",
            backgroundColor: Colors.fontDark,
            justifyContent: "center",
            borderRadius: 5,
            flexDirection: "row",
            alignItems: "center",
            marginLeft: "5%",
          }}
        >
          <Text
            style={{
              color: Colors.whiteColor,
              fontSize: 15,
              textAlign: "center",
              fontFamily: "NunitoSans-Bold",
            }}
          >
            {item.outlet.name}
          </Text>
          <View style={{ alignItems: "flex-end" }}>
            <AntDesign name="close" size={15} color={Colors.whiteColor} />
          </View>
        </View>
      </View>
      <View style={{ flex: 1, alignItems: "center" }}>
        <TouchableOpacity
          onPress={() => {
            // setState({ redemptionList: !redemptionList })
            //   getRedemptionDetail(item.id);
          }}
        >
          <FontAwesome5 name="edit" size={23} color={Colors.primaryColor} />
        </TouchableOpacity>
      </View>
    </View>
  );

  const redemptionFunc = () => {
    var body = {
      merchantId: User.getMerchantId(),
      isAllOutlet: isChecked13 == true ? "1" : null,
      outletId: outletId,
      category: category,
      expiryPeriod: expiryPeriod,
      extentionCharges: extentionCharges,
      extentionDuration: extentionDuration,
      redemptionOutletId: outletId,
    };
    // ApiClient.POST(API.createSettingRedemption, body, false).then((result) => {
    //   if (result.outletId != null) {
    //     Alert.alert(
    //       'Congratulation!',
    //       'Successfully',
    //       [
    //         {
    //           text: 'OK',
    //           onPress: () => { },
    //         },
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  const addOutlet = () => {
    var body = {
      outletName: addOutletName,
      merchantId: User.getMerchantId(),
      merchantShortcode: merchantShortcode,
    };

    ApiClient.POST(API.createOutlet, body, false).then((result) => {
      if (result && result.status === "success") {
        Alert.alert(
          "Success!",
          "Outlet has been added",
          [
            {
              text: "OK",
              onPress: () => { },
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  const renderTax = ({ item }) => (
    <View
      style={{
        backgroundColor: "#FFFFFF",
        flexDirection: "row",
        paddingVertical: 20,
        paddingHorizontal: 20,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: "#C4C4C4",
      }}
    >
      <Text style={{ width: "23%", color: Colors.primaryColor }}>
        {item.name}
      </Text>
      <Text style={{ width: "52%" }}>{item.rate}%</Text>
      <TouchableOpacity
        onPress={() => {
          deleteTax(item.id);
        }}
        style={{ width: "40%" }}
      >
        <Icon name="trash-2" size={20} color="#eb3446" />
      </TouchableOpacity>
    </View>
  );

  // function here
  const onCloseShiftBtn = (key) => {
    var decimal = closingAmount.split(".")[1];
    if (key >= 0 || key == ".") {
      if (closingAmount.includes("."))
        if (closingAmount.length < 12 && decimal.length < 2)
          setState({ closingAmount: closingAmount + key });
      if (!closingAmount.includes(".")) {
        if (closingAmount.length < 12)
          setState({ closingAmount: closingAmount + key });
      }
    } else {
      if (closingAmount.length > 0)
        setState({ closingAmount: closingAmount.slice(0, key) });
    }
  };

  const getCurrentShift = (outletId) => {
    // ApiClient.GET(API.getCurrentShift + outletId).then((result) => {
    //   setState({ shift1: result.success });
    // });
    // try {
    //   if (result.id != null) {
    //     Alert.alert(
    //       '',
    //       [
    //         {
    //           text: 'OK',
    //           onPress: () => { },
    //         },
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // } catch (error) { }
  };

  const getTax = (outletId) => {
    ApiClient.GET(API.merchantTax + outletId).then((result) => {
      setState({ taxList: result });
    });
  };

  const checkTaxName = (tname) => {
    for (const tax of taxList) {
      if (tname.toLowerCase() == tax.name.toLowerCase()) {
        return true;
      }
    }
    return false;
  };

  const createTax = () => {
    if (!tname) {
      Alert.alert(
        "Error",
        "Please fill in the information",
        [{ text: "OK", onPress: () => { } }],
        { cancelable: false }
      );
      return;
    } else if (!Number.isInteger(Number(rate))) {
      Alert.alert(
        "Error",
        "Tax is only in numeric",
        [{ text: "OK", onPress: () => { } }],
        { cancelable: false }
      );
      return;
    } else if (checkTaxName(tname)) {
      Alert.alert(
        "Error",
        "This name has been used",
        [{ text: "OK", onPress: () => { } }],
        { cancelable: false }
      );
      return;
    } else {
      var body = {
        name: tname,
        rate: rate,
        desciption: "",
        outletId: User.getOutletId(),
      };
      // ApiClient.POST(API.taxes, body, false).then((result) => {
      //   try {
      //     if (result) {
      //       Alert.alert(
      //         'Congratulation!',
      //         'You Have Successfully Inserted',
      //         [
      //           {
      //             text: 'OK',
      //             onPress: () => { },
      //           },
      //         ],
      //         { cancelable: false },
      //       );
      //       getTax(User.getOutletId())
      //     }
      //   } catch (error) {
      //     Alert.alert('Failed', [{ text: 'OK', onPress: () => { } }], {
      //       cancelable: false,
      //     });
      //   }
      // });
    }
  };

  const deleteTax = (taxId) => {
    console.log("TAX ID", taxId);
    var body = {
      taxId: taxId,
    };
    // ApiClient.POST(API.deleteTax, body, false).then((result) => {
    //   try {
    //     console.log("RESULT", result)
    //     if (result) {
    //       Alert.alert(
    //         'Successfully!',
    //         'You Have Successfully Deleted',
    //         [
    //           {
    //             text: 'OK',
    //             onPress: () => { },
    //           },
    //         ],
    //         { cancelable: false },
    //       );
    //       getTax(User.getOutletId())
    //     }
    //   } catch (error) {
    //     Alert.alert('Failed', [{ text: 'OK', onPress: () => { } }], {
    //       cancelable: false,
    //     });
    //   }
    // });
  };

  const renderSearch = (item) => {
    return (
      <View style={{ flexDirection: "column" }}>
        <Text style={{ fontWeight: "700", fontSize: 14, marginBottom: 5 }}>
          {item.structured_formatting.main_text}
        </Text>

        <Text style={{ fontSize: 12, color: "grey" }}>{item.description}</Text>
      </View>
    );
  };

  const outletById = (param) => {
    //<= outletId is not used in the param
    ApiClient.GET(API.outlet2 + param).then((result) => {
      //<= all you need to do is add parameter here
      console.log("RESULT OUTLET", result);
      setState({ outlet: result, openings: result.openings });
      console.log("openings", openings);
      setState({ status: result.status });
      setState({ merInfo: result.merchant[0] });
      setState({ cover: result.cover });
      setState({ logo: result.merchant[0].logo });
      setState({ openings: result.openings });

      myTextInput.current.clear();
    });
  };

  const editOutlet = (param) => {
    var body = {
      outletId: currOutletId,
      cover: cover,

      merchantLogo: logo,

      address: address,
      name: name,
      latlng: "",
      phone: phone,
      email: email,
      taxId: "",
      status: "1",
      isBusy: "1",
      reservationStatus: true,
      openings: [
        {
          week: "Monday",
          startTime: openings[0] ? openings[0].startTime : null,
          endTime: openings[0] ? openings[0].endTime : null,
        },
        {
          week: "Tuesday",
          startTime: openings[1] ? openings[1].startTime : null,
          endTime: openings[1] ? openings[1].endTime : null,
        },
        {
          week: "Wednesday",
          startTime: openings[2] ? openings[2].startTime : null,
          endTime: openings[2] ? openings[2].endTime : null,
        },
        {
          week: "Thursday",
          startTime: openings[3] ? openings[3].startTime : null,
          endTime: openings[3] ? openings[3].endTime : null,
        },
        {
          week: "Friday",
          startTime: openings[4] ? openings[4].startTime : null,
          endTime: openings[4] ? openings[4].endTime : null,
        },
        {
          week: "Saturday",
          startTime: openings[5] ? openings[5].startTime : null,
          endTime: openings[5] ? openings[5].endTime : null,
        },
        {
          week: "Sunday",
          startTime: openings[6] ? openings[6].startTime : null,
          endTime: openings[6] ? openings[6].endTime : null,
        },
      ],
      payments: [
        {
          name: payment,
        },
      ],
    };

    console.log("body", body);

    // ApiClient.PATCH(API.editOutlet, body, false).then((result) => {
    //   if (result.id != null) {
    //     Alert.alert(
    //       'Congratulation!',
    //       'You Have Successfully Inserted',
    //       [
    //         {
    //           text: 'OK',
    //           onPress: () => { },
    //         },
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
    // outletById(param);
    // myTextInput.current.clear();
  };

  const updateOutletDetails = async (param) => {
    setLoading(true);
    var merchantLogoPath = logo;
    if (logo && logoType && isLogoChanged) {
      // outletItemIdLocal = selectedProductEdit.uniqueId;

      merchantLogoPath = await uploadImageToFirebaseStorage(
        {
          uri: logo,
          type: logoType,
        },
        `/merchant/${merchantId}/logo${logoType}`
      );
    }

    var outletCoverPath = cover;
    if (cover && coverType && isCoverChanged) {
      // outletItemIdLocal = selectedProductEdit.uniqueId;

      outletCoverPath = await uploadImageToFirebaseStorage(
        {
          uri: cover,
          type: coverType,
        },
        `/merchant/${merchantId}/outlet/${currOutletId}/cover${coverType}`
      );
    }

    var body = {
      outletId: currOutletId,
      cover: outletCoverPath,

      merchantId: merchantId,
      merchantLogo: merchantLogoPath,

      address: address,
      merchantName: merchantName,

      lat: outletLat,
      lng: outletLng,

      phone: phone,
      email: email,

      isPickupAccepted: status,
      isDeliveryAccepted: status1,
      isHalal: statusHalal,

      outletOpeningDetails: currOutletOpening,

      pickupPaymentOptions: pickupPaymentOptions,
      deliveryPaymentOptions: deliveryPaymentOptions,
      dineinPaymentOptions: dineinPaymentOptions,

      taxRate: !isNaN(sstRate) ? +(sstRate / 100).toFixed(2) : 0.06,
      scRate: !isNaN(scRate) ? +(scRate / 100).toFixed(2) : 0.05,
      taxActive: sstActive,
      scActive: scActive,

      // taxId: '',
      // status: '1',
      // isBusy: '1',
      // reservationStatus: true,
    };

    console.log("body", body);

    ApiClient.POST(API.updateOutletDetails, body, false).then((result) => {
      if (result && result.status === "success") {
        Alert.alert(
          "Success",
          "Outlet details has been updated",
          [
            {
              text: "OK",
              onPress: () => {
                setLoading(false);
              },
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  const resetOutletData = async () => {
    setLoading(true);

    var body = {
      resetDataTypes: dropDownResetList,

      outletId: currOutletId,
      merchantId: merchantId,
    };

    console.log("body", body);

    ApiClient.POST(API.resetOutletData, body, false).then((result) => {
      if (result && result.status === "success") {
        Alert.alert(
          "Success",
          "Outlet data has been reset successfully.",
          [
            {
              text: "OK",
              onPress: () => {
                setLoading(false);
              },
            },
          ],
          { cancelable: false }
        );
      }
    });
  };

  const handleChoosePhotoCover = () => {
    const imagePickerOptions = {
      mediaType: "photo",
      quality: 0.5,
      includeBase64: false,
    };

    // launchImageLibrary(imagePickerOptions, (response) => {
    //   if (response.didCancel) {
    //   } else if (response.error) {
    //     Alert.alert(response.error.toString());
    //   } else {
    //     // setState({ image: response.uri });
    //     setCover(response.uri);
    //     setCoverType(response.uri.slice(response.uri.lastIndexOf(".")));

    //     setIsCoverChanged(true);
    //   }
    // });
  };

  const handleChoosePhotoLogo = () => {
    const imagePickerOptions = {
      mediaType: "photo",
      quality: 0.5,
      includeBase64: false,
    };

    // launchImageLibrary(imagePickerOptions, (response) => {
    //   if (response.didCancel) {
    //   } else if (response.error) {
    //     Alert.alert(response.error.toString());
    //   } else {
    //     // setState({ image: response.uri });
    //     setLogo(response.uri);
    //     setLogoType(response.uri.slice(response.uri.lastIndexOf(".")));

    //     setIsLogoChanged(true);
    //   }
    // });
  };

  const closingShift = () => {
    var body = {
      outletId: User.getOutletId(),
      amount: closingAmount,
    };
    // ApiClient.POST(API.closeShift, body, false).then((result) => {
    //   if (result) {
    //     Alert.alert(
    //       'Successfully',
    //       'Close Success',
    //       [
    //         {
    //           text: 'OK',
    //           onPress: () => {
    //             _logout();
    //             setState({
    //               showModal3: false,
    //               show: false,
    //               showModal5: false,
    //             });
    //           },
    //         },
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });
  };

  const getCurrentDate = () => {
    var date = new Date().getDate();
    var month = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    var hours = new Date().getHours();
    var min = new Date().getMinutes();
    var sec = new Date().getSeconds();
    var day = new Date().getDay();

    return (
      "9.00AM, " + "Thursday " + "September " + "20 " + "2020"
      // hours + '9.00AM' + min + '' + day +'Thursday'  + month + 'September' + date + '20'+ year + '2020'
    );
  };

  const _logout = async () => {
    await AsyncStorage.clear();
    User.setlogin(false);
    User.getRefreshMainScreen();
  };

  const addSection = () => {
    setState({ showNote: true });
  };

  // default = () => {
  //   if (status == 0) {
  //     return false;
  //   } else if (status == 1) {
  //     return true;
  //   }
  // }

  // default1 = () => {
  //   if (status1 == 0) {
  //     return false;
  //   } else if (status1 == 1) {
  //     return true;
  //   }
  // }

  // return = () => {
  //   if (status == false) {
  //     return 0;
  //   } else if (status == true) {
  //     return 1;
  //   }
  // }
  // return1 = () => {
  //   if (status == false) {
  //     return 0;
  //   } else if (status == true) {
  //     return 1;
  //   }
  // }

  // update = () => {
  //   if (status == false) {
  //     return 0;
  //   } else if (status == true) {
  //     return 1;
  //   }
  // }

  const bindPrinter = async () => {
    if (printerIP.length > 0) {
      try {
        await AsyncStorage.setItem("printerIP", printerIP);

        const result = await connectToPrinter();

        if (result) {
          Alert.alert("Success", "IP has been binded");
        } else {
          Alert.alert("Error", "Unable to bind the IP");
        }
      } catch (ex) {
        console.log(ex);
      }
    } else {
      Alert.alert("Error", "Invalid IP address");
    }
  };

  const unbindPrinter = async () => {
    try {
      await AsyncStorage.removeItem("printerIP");

      Alert.alert("Succes", "IP has been unbinded");
    } catch (ex) {
      console.log(ex);

      Alert.alert("Error", "Failed to unbind the IP");
    }
  };

  const testPrinter = async () => {
    console.log("test printer");

    await connectToPrinter();

    var testItems = [
      {
        name: "Coconut Coffee",
        remarks: "LESS SWEET",
        price: 8,
        discount: 0,
        quantity: 1,
        subtotal: 8,
        tax: 0,
      },
      {
        name: "Yogurt Coffee",
        remarks: "LESS ICE",
        price: 8,
        discount: 0,
        quantity: 1,
        subtotal: 8,
        tax: 0,
      },
    ];

    try {
      var result = `${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.UNDERLINE_OFF}${ESCPOS_CMD.BOLD_OFF}`;
      result += `${ESCPOS_CMD.CENTER}${currOutlet.name}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.CENTER}${currOutlet.address}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.CENTER}${currOutlet.phone}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.CENTER}${merchantName}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.CENTER}SST ID No. 0012612771${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}ORDER #38${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
        .map((i) => "-")
        .join("")}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}Mark${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}0127148876${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${"Receipt Date".padEnd(
        12,
        " "
      )} : ${moment().format("ddd, MMM D, YYYY")}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${"Receipt #".padEnd(12, " ")} : ${"38"}${ESCPOS_CMD.NEWLINE
        }`;
      result += `${ESCPOS_CMD.LEFT}${"Cashier".padEnd(
        12,
        " "
      )} : ${"Sophie Kim"}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${"ITEM".padEnd(20, " ")}${"PRICE".padEnd(
        8,
        " "
      )}${"DISC".padEnd(8, " ")}${"QTY".padEnd(4, " ")}${"SUB".padStart(
        8,
        " "
      )}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
        .map((i) => "-")
        .join("")}${ESCPOS_CMD.NEWLINE}`;

      for (var i = 0; i < testItems.length; i++) {
        const testItem = testItems[i];

        result += `${ESCPOS_CMD.LEFT}${testItem.name
          .slice(0, 20)
          .padEnd(20, " ")}${(
            "RM" +
            testItem.price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          ).padEnd(8, " ")}${(
            "RM" +
            testItem.discount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          ).padEnd(8, " ")}${testItem.quantity.toString().padEnd(4, " ")}${(
            "RM" +
            testItem.subtotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          ).padStart(8, " ")}${ESCPOS_CMD.NEWLINE}`;

        if (testItem.remarks) {
          result += `${ESCPOS_CMD.LEFT}${testItem.remarks
            .slice(0, 20)
            .padEnd(20, " ")}${"".padEnd(8, " ")}${"".padEnd(
              8,
              " "
            )}${"".padEnd(4, " ")}${"".padEnd(8, " ")}${ESCPOS_CMD.NEWLINE}`;
        }

        if (i !== testItems.length - 1) {
          result += `${ESCPOS_CMD.NEWLINE}`;
        }
      }

      result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
        .map((i) => "-")
        .join("")}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${"Subtotal".padEnd(24, " ")}${(
        "RM" +
        testItems
          .reduce((accum, item) => accum + item.subtotal, 0)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      ).padStart(24, " ")}`;
      result += `${ESCPOS_CMD.LEFT}${"Discount".padEnd(24, " ")}${(
        "RM" +
        testItems
          .reduce((accum, item) => accum + item.discount, 0)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      ).padStart(24, " ")}`;
      result += `${ESCPOS_CMD.LEFT}${"Tax (6%)".padEnd(24, " ")}${(
        "RM" +
        testItems
          .reduce((accum, item) => accum + item.tax, 0)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      ).padStart(24, " ")}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}${"Total".padEnd(
        12,
        " "
      )}${(
        "RM" +
        testItems
          .reduce((accum, item) => accum + item.subtotal, 0)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      ).padStart(12, " ")}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
        .map((i) => "-")
        .join("")}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${"Received".padEnd(24, " ")}${(
        "RM" +
        testItems
          .reduce((accum, item) => accum + item.subtotal, 0)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      ).padStart(24, " ")}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${"Balance".padEnd(24, " ")}${(
        "RM" + (0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      ).padStart(24, " ")}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

      result += `${ESCPOS_CMD.LEFT}${"Notes:"}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${"1."}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${"2."}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.LEFT}${"3."}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE
        }${ESCPOS_CMD.NEWLINE}`;

      result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_2H
        }${"Thank you for your order"}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
      result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.BARCODE_HEIGHT}${ESCPOS_CMD.BARCODE_WIDTH}${ESCPOS_CMD.BARCODE_FONT_A}${ESCPOS_CMD.BARCODE_TXT_OFF}${ESCPOS_CMD.BARCODE_EAN13}978020137962${ESCPOS_CMD.NEWLINE}`;
      // result += `${ESCPOS_CMD.CENTER}${printBarcode({
      //   data: 'TEST12345',
      //   type: 'ean',
      // }, 'cp936')}${ESCPOS_CMD.NEWLINE}`;

      NetPrinter.printText(result);
    } catch (ex) {
      console.log(ex);
    }
  };

  // function end

  const week = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  return (
    // <View style={styles.container}>
    //   <View style={styles.sidebar}>
    <View
      style={[
        styles.container,
        {
          height: windowHeight,
          width: windowWidth,
        },
      ]}
    >
      <View style={{ flex: 0.8 }}>
        <SideBar
          navigation={props.navigation}
          selectedTab={10}
          expandSettings={true}
        />
      </View>
      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          // scrollEnabled={switchMerchant}
          style={{}}
          contentContainerStyle={{
            flex: 1,
            paddingBottom: switchMerchant
              ? windowHeight * 0.001
              : windowHeight * 0.03,
            backgroundColor: Colors.highlightColor,
          }}
        >
          <View style={styles.content}>
            {merchantDisplay ? (
              <View
                style={{
                  backgroundColor: Colors.whiteColor,
                  // height: Dimensions.get('window').height - 120,
                  width: windowWidth * 0.87,
                  alignSelf: "center",
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  // elevation: 1,
                  elevation: 3,

                  borderRadius: 5,
                  // borderRadius: 8,
                }}
              >
                <ScrollView showsVerticalScrollIndicator={false}>
                  <View
                    style={{
                      flexDirection: "row",
                      zIndex: 1,
                      padding: 30,
                    }}
                  >
                    <View style={{ flex: 1.1, paddingLeft: 15 }}>
                      <View
                        style={{
                          flexDirection: "row",
                          flex: 1,
                          alignItems: "flex-start",

                          // marginLeft: -Dimensions.get('screen').width * 0.01,
                        }}
                      >
                        {/* left */}
                        <TouchableOpacity
                          style={{
                            alignItems: "center",
                            marginTop: 20,
                            marginRight: 20,
                            // flex: 1,
                          }}
                          onPress={() => {
                            openFileSelector();
                            setLogoSelected(true);
                          }}
                        >
                          <AsyncImage
                            source={{ uri: logo }}
                            style={{
                              width: 120,
                              height: 120,
                              backgroundColor: Colors.secondaryColor,
                              borderRadius: 10,
                            }}
                          />
                          <View style={{ alignItems: "center" }}>
                            <TouchableOpacity
                              style={
                                {
                                  // height: 30,
                                  // width: 50
                                }
                              }
                              onPress={() => {
                                openFileSelector();
                                setLogoSelected(true);
                              }}>
                              <Text
                                style={{
                                  fontFamily: 'NunitoSans-Bold',
                                  fontSize: 15,
                                  color: Colors.primaryColor,
                                  marginTop: 5,
                                }}>
                                Change
                              </Text>
                            </TouchableOpacity>
                          </View>
                        </TouchableOpacity>
                        <View
                          style={{
                            flex: 3,
                            marginTop: 20,
                            //marginLeft: '-2%',
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              marginBottom: 10,
                              //justifyContent: 'space-between',
                            }}
                          >
                            <View
                              style={{
                                justifyContent: "center",
                                flex: 1,
                                paddingLeft: 10,
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                Merchant Name:
                              </Text>
                            </View>
                            <View
                              style={{
                                //width: 210,
                                height: 40,
                                justifyContent: "center",
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 8,
                                flex: 1.5,
                                marginRight: 20,
                              }}
                            >
                              {/* <Text style={{ fontFamily: 'NunitoSans-SemiBold', color: Colors.descriptionColor, paddingHorizontal: 10 }}>{merchantName}</Text> */}
                              <TextInput
                                style={{
                                  fontFamily: "NunitoSans-Regular",
                                  // color: Colors.descriptionColor,
                                  color: Colors.blackColor,
                                  paddingHorizontal: 10,
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholder={
                                  merchantName == null
                                    ? " Not Found "
                                    : merchantName
                                }
                                onChangeText={(text) => {
                                  // setState({ merchantName: text });
                                  setMerchantName(text);
                                }}
                                value={merchantName}
                                ref={myTextInput}
                                spellCheck={false}
                              />
                            </View>
                          </View>
                          {/* <View
                        style={{
                          flexDirection: 'row',
                          //justifyContent: 'space-between',
                        
                        }}>
                        <View style={{ justifyContent: 'center', flex: 1, paddingLeft: 10 }}>
                          <Text style={styles.textSize}>Choose Outlet:</Text>
                        </View>
                        <View style={{ flex: 1.6 }}>
                          <View
                            style={{
                              //width: 210,
                              height: 50,
                              justifyContent: 'center',
                              marginRight: 80
                              //marginLeft: 10,
                            }}>
                            <DropDownPicker
                              // items={outletInfo.map((item) => ({
                              //   label: item.name, //<= after hayden change you need to change it to item.name
                              //   value: item.id,
                              // }))}
                              items={targetOutletDropdownList}
                              // defaultValue={outletId}
                              containerStyle={{ height: 40, width: 220 }}
                              placeholder="Choose outlet"
                              placeholderStyle={{
                                color: Colors.descriptionColor,
                                fontSize: 16,
                                fontFamily: 'NunitoSans-Regular',
                              }}
                              style={{ backgroundColor: '#fafafa' }}
                              labelStyle={{
                                fontSize: 16,
                                fontFamily: 'NunitoSans-Regular',
                              }}
                              itemStyle={{
                                justifyContent: 'flex-start',
                                fontSize: 16,
                                fontFamily: 'NunitoSans-Regular',
                              }}
                              dropDownStyle={{ backgroundColor: '#fafafa' }}
                              onChangeItem={(item) => {
                                // setState({ outletId: item.value });

                                // setOutletId(item.value);
                                // outletById(item.value)

                                MerchantStore.update((s) => {
                                  s.currOutletId = item.value;
                                });
                              }}
                              // defaultValue={currOutletId}
                              defaultValue={selectedTargetOutletId}
                              multiple={false}
                            // onClose={() => {
                            //   outletById(outletId);
                            // }} //you didn't pass in outletid in your function parameter
                            />
                          </View>
                        </View>
                      </View> */}
                        </View>
                      </View>

                      <View
                        style={
                          (styles.merchantDisplayView,
                          {
                            // backgroundColor: 'red'
                            marginTop: 25,
                            marginBottom: 15,
                          })
                        }
                      >
                        <View
                          style={{
                            justifyContent: "center",
                            alignItems: "flex-start",
                          }}
                        >
                          <Text
                            style={{
                              marginRight: 50,
                              fontFamily: "NunitoSans-Bold",
                              fontSize: switchMerchant ? 20 : 25,
                            }}
                          >
                            Merchant Display
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              justifyContent: "flex-start",
                              width: "80%",
                            }}
                          >
                            <View>
                              <Text
                                style={{
                                  color: Colors.fieldtTxtColor,
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 16,
                                }}
                              >
                                Customer will see this
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>

                      <TouchableOpacity
                        style={
                          (styles.merchantDisplayView,
                          {
                            // backgroundColor: 'red',
                            marginBottom: 30,
                          })
                        }
                        onPress={() => {
                          openFileSelector();
                          setCoverSelected(true);
                        }}
                      >
                        <AsyncImage
                          source={{ uri: cover }}
                          style={{
                            resizeMode: "contain",
                            width: 300,
                            height: 120,
                            backgroundColor: Colors.secondaryColor,
                            borderRadius: 10,
                          }}
                        />
                      </TouchableOpacity>

                      {/* merchantname */}
                      <View style={{}}>
                        <View style={{ width: "75%" }}>
                          <View
                            style={{
                              flexDirection: "row",
                              alignItems: "flex-start",
                            }}
                          >
                            <View
                              style={{
                                width: "25%",
                                justifyContent: "center",
                                marginTop: 5,
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                Address
                              </Text>
                            </View>

                            <View style={{ width: "90%" }}>
                              <Autocomplete
                                // placeholder="📍  Outlet address"
                                placeholder={address}
                                minLength={2} // minimum length of text to search
                                autoFocus={false}
                                textInputProps={{
                                  placeholderTextColor: "black",
                                }}
                                returnKeyType={"search"} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
                                listViewDisplayed="false" // true/false/undefined
                                fetchDetails={true}
                                renderDescription={(row) => renderSearch(row)} // custom description render
                                onPlaceSelected={(
                                  place,
                                  inputRef,
                                  autocomplete
                                ) => {
                                  // 'place' is provided when fetchDetails = true
                                  console.log("place", place);
                                  console.log("inputRef", inputRef);
                                  console.log("autocomplete", autocomplete);

                                  setAddress(place.formatted_address);
                                  setOutletLat(place.geometry.location.lat);
                                  setOutletLng(place.geometry.location.lng);
                                }}
                                options={{
                                  types: ["address"],
                                }}
                                defaultValue={address}
                                // api cannot use
                                // apiKey={"AIzaSyAt1AOIT_1E5Ivec7PVWmylmDd8jSVm-j8"}
                                style={{
                                  //   backgroundColor: Colors.fieldtBgColor,
                                  //   width: "80%",
                                  //   height: switchMerchant ? 30 : 35,
                                  //   borderRadius: 5,
                                  //   padding: 5,
                                  //   marginVertical: 5,
                                  //   borderWidth: 1,
                                  //   borderColor: "#E5E5E5",
                                  //   paddingLeft: 10,
                                  //   marginLeft: 5,
                                  //   fontFamily: "NunitoSans-Regular",
                                  //   fontSize: switchMerchant ? 10 : 14,
                                  textInputContainer: {
                                    width: "100%",
                                    borderWidth: 1,
                                    borderRightWidth: 2,
                                    borderRadius: 5,
                                    borderColor: "#E5E5E5",
                                  },
                                  textInput: {
                                    backgroundColor: Colors.fieldtBgColor,
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                    height: 80,
                                    marginBottom: 0,
                                    color: "black",
                                    //backgroundColor: 'red',
                                    textAlignVertical: "top",
                                  },
                                  description: {
                                    // fontWeight: 'bold',
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: switchMerchant ? 10 : 14,
                                  },
                                  predefinedPlacesDescription: {
                                    color: "#1faadb",
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  },
                                  listView: {
                                    //backgroundColor: 'red',
                                    //marginLeft: 85,
                                    //marginTop: 40,
                                    width: "100%",
                                    //alignSelf: 'center',
                                    borderColor: "#E5E5E5",
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    zIndex: 10,
                                    // height: 800,
                                    //position: 'absolute',
                                  },
                                  container: {
                                    //backgroundColor: 'red',
                                  },
                                  zIndex: 10,
                                }}
                                enablePoweredByContainer={false}
                                //currentLocation={false}
                                currentLocationLabel="Current location"
                                nearbyPlacesAPI="GooglePlacesSearch"
                                GoogleReverseGeocodingQuery={{}}
                                GooglePlacesSearchQuery={{
                                  rankby: "distance",
                                }}
                                filterReverseGeocodingByTypes={[
                                  "locality",
                                  "administrative_area_level_3",
                                ]}
                                debounce={200}
                                keepResultsAfterBlur={true}
                              />
                              {/* <GooglePlacesAutocomplete
                                // placeholder="📍  Outlet address"
                                placeholder={address}
                                minLength={2} // minimum length of text to search
                                autoFocus={false}
                                textInputProps={{
                                  placeholderTextColor: "black",
                                }}
                                returnKeyType={"search"} // Can be left out for default return key https://facebook.github.io/react-native/docs/textinput.html#returnkeytype
                                listViewDisplayed="false" // true/false/undefined
                                fetchDetails={true}
                                renderDescription={(row) => renderSearch(row)} // custom description render
                                onPress={(data, details = null) => {
                                  // 'details' is provided when fetchDetails = true
                                  console.log("data", data);
                                  console.log("details", details);

                                  setAddress(details.formatted_address);
                                  setOutletLat(details.geometry.location.lat);
                                  setOutletLng(details.geometry.location.lng);

                                  // console.log("data.description", data.description);
                                  // console.log("data.structured_formatting.main_text", data.structured_formatting.main_text);
                                  // props.navigation.navigate('AddAddress', { test: 3, address: data.description, name: data.structured_formatting.main_text, location: details.geometry.location })
                                }}
                                getDefaultValue={() => address}
                                query={{
                                  // available options: https://developers.google.com/places/web-service/autocomplete
                                  //key: 'AIzaSyB_nKKIGIcEJ6ffsVBdYeIstW8KekjVXa8',
                                  // key: 'AIzaSyCsM9boPkQ7uaJ54RWUzPHGzjZfggkXf8g',
                                  key: "AIzaSyAt1AOIT_1E5Ivec7PVWmylmDd8jSVm-j8",
                                  language: "en", // language of the results
                                  types: "address", // default: 'geocode'
                                  components: "country:my",
                                }}
                                styles={{
                                  textInputContainer: {
                                    width: "100%",
                                    borderWidth: 1,
                                    borderRightWidth: 2,
                                    borderRadius: 5,
                                    borderColor: "#E5E5E5",
                                  },
                                  textInput: {
                                    backgroundColor: Colors.fieldtBgColor,
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                    height: 80,
                                    marginBottom: 0,
                                    color: "black",
                                    //backgroundColor: 'red',
                                    textAlignVertical: "top",
                                  },
                                  description: {
                                    // fontWeight: 'bold',
                                    fontFamily: "NunitoSans-Bold",
                                    fontSize: switchMerchant ? 10 : 14,
                                  },
                                  predefinedPlacesDescription: {
                                    color: "#1faadb",
                                    fontFamily: "NunitoSans-Regular",
                                    fontSize: switchMerchant ? 10 : 14,
                                  },
                                  listView: {
                                    //backgroundColor: 'red',
                                    //marginLeft: 85,
                                    //marginTop: 40,
                                    width: "100%",
                                    //alignSelf: 'center',
                                    borderColor: "#E5E5E5",
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    zIndex: 10,
                                    // height: 800,
                                    //position: 'absolute',
                                  },
                                  container: {
                                    //backgroundColor: 'red',
                                  },
                                  zIndex: 10,
                                }}
                                enablePoweredByContainer={false}
                                //currentLocation={false}
                                currentLocationLabel="Current location"
                                nearbyPlacesAPI="GooglePlacesSearch"
                                GoogleReverseGeocodingQuery={{}}
                                GooglePlacesSearchQuery={{
                                  rankby: "distance",
                                }}
                                filterReverseGeocodingByTypes={[
                                  "locality",
                                  "administrative_area_level_3",
                                ]}
                                debounce={200}
                                keepResultsAfterBlur={true}
                              /> */}
                            </View>
                          </View>

                          <View
                            style={{ flexDirection: "row", marginVertical: 15 }}
                          >
                            <View
                              style={{ width: "25%", justifyContent: "center" }}
                            >
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                Contact
                              </Text>
                            </View>
                            <View style={{ width: "75%" }}>
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                style={{
                                  padding: 10,
                                  height: 40,
                                  width: "120%",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                  backgroundColor: Colors.fieldtBgColor,
                                }}
                                keyboardType={"number-pad"}
                                placeholder={
                                  outlet == null
                                    ? " Fill in contact "
                                    : outlet.phone
                                }
                                onChangeText={(text) => {
                                  // setState({ phone: text });
                                  setPhone(text);
                                }}
                                value={phone}
                                ref={myTextInput}
                              />
                            </View>
                          </View>

                          <View
                            style={{ flexDirection: "row", marginVertical: 15 }}
                          >
                            <View
                              style={{ width: "25%", justifyContent: "center" }}
                            >
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                Email
                              </Text>
                            </View>
                            <View style={{ width: "75%" }}>
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={{
                                  padding: 10,
                                  height: 40,
                                  width: "120%",
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                  backgroundColor: Colors.fieldtBgColor,
                                }}
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholder={
                                  outlet == null
                                    ? " Fill in email "
                                    : outlet.email
                                }
                                onChangeText={(text) => {
                                  // setState({ phone: text });
                                  setEmail(text);
                                }}
                                value={email}
                                ref={myTextInput}
                              />
                            </View>
                          </View>

                          <View
                            style={{ flexDirection: "row", marginVertical: 15 }}
                          >
                            <View
                              style={{ width: "25%", justifyContent: "center" }}
                            >
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                Website
                              </Text>
                            </View>
                            {/* <View style={{ width: '30%', justifyContent: 'center' }}>
                          <Text style={[styles.textSize, {}]}>hominsan</Text>
                        </View> */}
                            <View
                              style={{ width: "61%", flexDirection: "row" }}
                            >
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                placeholderTextColor={"#000"}
                                style={[
                                  styles.textInput,
                                  {
                                    padding: 10,
                                    height: 40,
                                    width: 80,
                                    fontSize: switchMerchant ? 10 : 14,
                                  },
                                ]}
                                placeholder={
                                  // outlet == null
                                  //   ? ' Fill in contact '
                                  //   : outlet.phone
                                  "subdomain"
                                }
                                // onChangeText={(text) => {
                                //   setPhone(text);
                                // }}
                                // value={phone}
                                ref={myTextInput}
                              />
                            </View>
                            <View
                              style={{
                                justifyContent: "center",
                                marginLeft: windowWidth * 0.005,
                              }}
                            >
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                .mykoodoo.com
                              </Text>
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              marginVertical: 15,
                              position: "relative",
                              bottom: 7,
                            }}
                          >
                            <View
                              style={{ width: "25%", justifyContent: "center" }}
                            >
                              <Text
                                style={{
                                  position: "relative",
                                  bottom: 1,
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                {"SST\nRate (%)"}
                              </Text>
                            </View>
                            <View style={{ width: "75%" }}>
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderTextColor={"#000"}
                                style={{
                                  padding: 5,
                                  height: 40,
                                  // width: '120%',
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                  backgroundColor: Colors.fieldtBgColor,
                                }}
                                placeholder={"6"}
                                onChangeText={(text) => {
                                  setSstRate(text);
                                }}
                                value={sstRate}
                                ref={myTextInput}
                              />
                            </View>

                            <View
                              style={{
                                width: "10%",
                                // backgroundColor: 'red',

                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "4%",
                              }}
                            >
                              <Switch
                                onChange={(statusTemp) => {
                                  setSstActive(statusTemp);
                                }}
                                checked={sstActive}
                                width={35}
                                height={20}
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              />
                              {/* <Switch
                                width={42}
                                style={{
                                  //flexDirection: 'row',
                                  //width: '15%',
                                  // marginRight: 20,
                                  // marginLeft: 20,
                                  // bottom: -2,
                                }}
                                value={sstActive}
                                onSyncPress={(statusTemp) =>
                                  // setState({ status: status })
                                  setSstActive(statusTemp)
                                }
                                circleColorActive={Colors.primaryColor}
                                circleColorInactive={Colors.fieldtTxtColor}
                                backgroundActive="#dddddd"
                              /> */}
                            </View>
                          </View>

                          <View
                            style={{
                              flexDirection: "row",
                              marginVertical: 15,
                              position: "relative",
                              bottom: 7,
                            }}
                          >
                            <View
                              style={{ width: "25%", justifyContent: "center" }}
                            >
                              <Text
                                style={{
                                  position: "relative",
                                  bottom: 1,
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                              >
                                {"Service\nCharge (%)"}
                              </Text>
                            </View>
                            <View style={{ width: "75%" }}>
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                placeholderStyle={{
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                }}
                                placeholderTextColor={"#000"}
                                style={{
                                  padding: 5,
                                  height: 40,
                                  // width: '120%',
                                  fontFamily: "NunitoSans-Regular",
                                  fontSize: switchMerchant ? 10 : 14,
                                  backgroundColor: Colors.fieldtBgColor,
                                }}
                                placeholder={" 5 "}
                                onChangeText={(text) => {
                                  setScRate(text);
                                }}
                                value={scRate}
                                ref={myTextInput}
                              />
                            </View>

                            <View
                              style={{
                                width: "10%",
                                // backgroundColor: 'red',

                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "4%",
                              }}
                            >
                              <Switch
                                onChange={(value) => {
                                  setScActive(value);
                                }}
                                checked={scActive}
                                width={35}
                                height={20}
                                handleDiameter={30}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              />
                              {/* <Switch
                                width={42}
                                style={{
                                  //flexDirection: 'row',
                                  //width: '15%',
                                  // marginRight: 20,
                                  // marginLeft: 20,
                                  // bottom: -2,
                                }}
                                value={scActive}
                                onSyncPress={(statusTemp) =>
                                  // setState({ status: status })
                                  setScActive(statusTemp)
                                }
                                circleColorActive={Colors.primaryColor}
                                circleColorInactive={Colors.fieldtTxtColor}
                                backgroundActive="#dddddd"
                              /> */}
                            </View>
                          </View>
                        </View>

                        <View style={styles.viewContainer}>
                          <View>
                            <Text
                              style={[
                                styles.textSize,
                                {
                                  width: windowWidth * 0.15,
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                },
                              ]}
                            >
                              Halal
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                            }}
                          >
                            <Switch
                              onChange={(value) => {
                                setStatusHalal(value);
                              }}
                              checked={statusHalal}
                              width={35}
                              height={20}
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            />
                            {/* <Switch
                              width={42}
                              style={{
                                //flexDirection: 'row',
                                //width: '15%',
                                marginRight: 20,
                                // marginLeft: 20,
                                bottom: -2,
                              }}
                              value={statusHalal}
                              onSyncPress={(statusTemp) =>
                                // setState({ status: status })
                                setStatusHalal(statusTemp)
                              }
                              circleColorActive={Colors.primaryColor}
                              circleColorInactive={Colors.fieldtTxtColor}
                              backgroundActive="#dddddd"
                            /> */}
                            {/* <Text
                          style={{
                            fontSize: 18,
                            color: status
                              ? Colors.primaryColor
                              : Colors.fieldtTxtColor,
                            alignSelf: 'center',
                          }}>
                          {status ? 'Yes' : 'No'}
                        </Text> */}
                          </View>
                        </View>

                        <View style={styles.viewContainer}>
                          <View>
                            <Text
                              style={[
                                styles.textSize,
                                {
                                  width: windowWidth * 0.15,
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                },
                              ]}
                            >
                              Accepting Takeaway
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                            }}
                          >
                            <Switch
                              onChange={(value) => {
                                setStatus(value);
                              }}
                              checked={status}
                              width={35}
                              height={20}
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            />
                            {/* <Switch
                              width={42}
                              style={{
                                //flexDirection: 'row',
                                //width: '15%',
                                marginRight: 20,
                                // marginLeft: 20,
                                bottom: -2,
                              }}
                              value={status}
                              onSyncPress={(statusTemp) =>
                                // setState({ status: status })
                                setStatus(statusTemp)
                              }
                              circleColorActive={Colors.primaryColor}
                              circleColorInactive={Colors.fieldtTxtColor}
                              backgroundActive="#dddddd"
                            /> */}
                          </View>
                        </View>
                        <View style={styles.viewContainer}>
                          <View>
                            <Text
                              style={[
                                styles.textSize,
                                {
                                  width: windowWidth * 0.15,
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                },
                              ]}
                            >
                              Accepting Delivery
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                            }}
                          >
                            <Switch
                              onChange={(value) => {
                                setStatus1(value);
                              }}
                              checked={status1}
                              width={35}
                              height={20}
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            />
                            {/* <Switch
                              width={42}
                              style={{
                                //flexDirection: 'row',
                                //width: '15%',
                                marginRight: 20,
                                // marginLeft: 35,
                                bottom: -2,
                              }}
                              value={status1}
                              onSyncPress={(status1Temp) =>
                                // setState({ status1: status1 })
                                setStatus1(status1Temp)
                              }
                              circleColorActive={Colors.primaryColor}
                              circleColorInactive={Colors.fieldtTxtColor}
                              backgroundActive="#dddddd"
                            /> */}
                          </View>
                        </View>

                        <View style={styles.viewContainer}>
                          <View>
                            <Text
                              style={[
                                styles.textSize,
                                {
                                  width: windowWidth * 0.2,
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                },
                              ]}
                            >
                              Delivery Payment Options
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                            }}
                          >
                            <View
                              style={{
                                width: 200,
                                height: 50,
                                justifyContent: "center",
                                marginLeft: 5,
                              }}
                            >
                              {/* <Picker
                                selectedValue={deliveryPaymentOptions}
                                style={{}}
                                onValueChange={(value, text) => {
                                  setDeliveryPaymentOptions(value);
                                }}
                              >
                                {deliveryPaymentDropDown.map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker> */}
                              <Select
                                defaultValue={deliveryPaymentOptions}
                                style={{}}
                                onChange={(value, text) => {
                                  setDeliveryPaymentOptions(value);
                                }}
                                options={deliveryPaymentDropDown}
                                placeholder="Payment options"
                              />

                              {/* <DropDownPicker
                                items={[
                                  {
                                    label: "Online",
                                    value: USER_ORDER_PAYMENT_OPTIONS.ONLINE,
                                  },
                                  {
                                    label: "Offline",
                                    value: USER_ORDER_PAYMENT_OPTIONS.OFFLINE,
                                  },
                                  {
                                    label: "All",
                                    value: USER_ORDER_PAYMENT_OPTIONS.ALL,
                                  },
                                ]}
                                // defaultValue={outletId}
                                containerStyle={{ height: 40 }}
                                placeholder="Payment options"
                                placeholderStyle={{
                                  color: Colors.descriptionColor,
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontFamily: "NunitoSans-Regular",
                                }}
                                style={{ backgroundColor: "#fafafa" }}
                                labelStyle={{
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontFamily: "NunitoSans-Regular",
                                }}
                                itemStyle={{
                                  justifyContent: "flex-start",
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontFamily: "NunitoSans-Regular",
                                  marginLeft: 5,
                                }}
                                dropDownStyle={{ backgroundColor: "#fafafa" }}
                                onChangeItem={(item) => {
                                  setDeliveryPaymentOptions(item.value);
                                }}
                                defaultValue={deliveryPaymentOptions}
                                multiple={false}
                              /> */}
                            </View>
                          </View>
                        </View>

                        <View style={[styles.viewContainer, { zIndex: -1 }]}>
                          <View>
                            <Text
                              style={[
                                styles.textSize,
                                {
                                  width: windowWidth * 0.2,
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                },
                              ]}
                            >
                              Takeaway Payment Options
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                            }}
                          >
                            <View
                              style={{
                                width: 200,
                                height: 50,
                                justifyContent: "center",
                                marginLeft: 5,
                              }}
                            >
                              {/* <Picker
                                selectedValue={pickupPaymentOptions}
                                style={{}}
                                onValueChange={(value, text) => {
                                  setPickupPaymentOptions(value);
                                }}
                              >
                                {deliveryPaymentDropDown.map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker> */}
                              <Select
                                defaultValue={pickupPaymentOptions}
                                style={{}}
                                onChange={(value, text) => {
                                  setPickupPaymentOptions(value);
                                }}
                                options={deliveryPaymentDropDown}
                                placeholder="Payment options"
                              />

                              {/* <DropDownPicker
                                items={[
                                  {
                                    label: "Online",
                                    value: USER_ORDER_PAYMENT_OPTIONS.ONLINE,
                                  },
                                  {
                                    label: "Offline",
                                    value: USER_ORDER_PAYMENT_OPTIONS.OFFLINE,
                                  },
                                  {
                                    label: "All",
                                    value: USER_ORDER_PAYMENT_OPTIONS.ALL,
                                  },
                                ]}
                                // defaultValue={outletId}
                                containerStyle={{ height: 40 }}
                                placeholder="Payment options"
                                placeholderStyle={{
                                  color: Colors.descriptionColor,
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontFamily: "NunitoSans-Regular",
                                }}
                                style={{ backgroundColor: "#fafafa" }}
                                labelStyle={{
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontFamily: "NunitoSans-Regular",
                                }}
                                itemStyle={{
                                  justifyContent: "flex-start",
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontFamily: "NunitoSans-Regular",
                                  marginLeft: 5,
                                }}
                                dropDownStyle={{ backgroundColor: "#fafafa" }}
                                onChangeItem={(item) => {
                                  setPickupPaymentOptions(item.value);
                                }}
                                defaultValue={pickupPaymentOptions}
                                multiple={false}
                              /> */}
                            </View>
                          </View>
                        </View>

                        <View style={[styles.viewContainer, { zIndex: -2 }]}>
                          <View>
                            <Text
                              style={[
                                styles.textSize,
                                {
                                  width: windowWidth * 0.2,
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: switchMerchant ? 10 : 14,
                                },
                              ]}
                            >
                              Dine-in Payment Options
                            </Text>
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                            }}
                          >
                            <View
                              style={{
                                width: 200,
                                height: 50,
                                justifyContent: "center",
                                marginLeft: 5,
                              }}
                            >
                              {/* <Picker
                                selectedValue={dineinPaymentOptions}
                                style={{}}
                                onValueChange={(value, text) => {
                                  setDineinPaymentOptions(value);
                                }}
                              >
                                {deliveryPaymentDropDown.map((value, index) => {
                                  return (
                                    <Picker.Item
                                      key={index}
                                      label={value.label}
                                      value={value.value}
                                    />
                                  );
                                })}
                              </Picker> */}

                              <Select
                                defaultValue={dineinPaymentOptions}
                                style={{ zIndex: 500}}
                                onChange={(value, text) => {
                                  setDineinPaymentOptions(value);
                                }}
                                options={deliveryPaymentDropDown}
                                placeholder="Payment options"
                              />

                              {/* <DropDownPicker
                                items={[
                                  {
                                    label: "Online",
                                    value: USER_ORDER_PAYMENT_OPTIONS.ONLINE,
                                  },
                                  {
                                    label: "Offline",
                                    value: USER_ORDER_PAYMENT_OPTIONS.OFFLINE,
                                  },
                                  {
                                    label: "All",
                                    value: USER_ORDER_PAYMENT_OPTIONS.ALL,
                                  },
                                ]}
                                // defaultValue={outletId}
                                containerStyle={{ height: 40 }}
                                placeholder="Payment options"
                                placeholderStyle={{
                                  color: Colors.descriptionColor,
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontFamily: "NunitoSans-Regular",
                                }}
                                style={{ backgroundColor: "#fafafa" }}
                                labelStyle={{
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontFamily: "NunitoSans-Regular",
                                }}
                                itemStyle={{
                                  justifyContent: "flex-start",
                                  fontSize: switchMerchant ? 10 : 14,
                                  fontFamily: "NunitoSans-Regular",
                                  marginLeft: 5,
                                }}
                                dropDownStyle={{ backgroundColor: "#fafafa" }}
                                onChangeItem={(item) => {
                                  setDineinPaymentOptions(item.value);
                                }}
                                defaultValue={dineinPaymentOptions}
                                multiple={false}
                              /> */}
                            </View>
                          </View>
                        </View>

                        <View style={[styles.viewContainer, { zIndex: -3 }]}>
                          <View
                            style={{
                              width: switchMerchant
                                ? windowWidth * 0.2
                                : windowWidth * 0.2,
                              height: 50,
                              justifyContent: "center",
                              // borderWidth:1
                            }}
                          >
                            <Select
                              defaultValue={dropDownResetList}
                              onChange={(items) => {
                                setDropDownResetList(items);
                              }}
                              options={resetDropDown}
                              isMulti
                            />

                            {/* <DropDownPicker
                              items={[
                                {
                                  label: "Order",
                                  value: RESET_DATA_TYPE.USER_ORDER,
                                },
                                {
                                  label: "Customer",
                                  value: RESET_DATA_TYPE.CRM_USER,
                                },
                              ]}
                              // defaultValue={outletId}
                              containerStyle={{
                                height: switchMerchant ? 35 : 40,
                                width: "90%",
                              }}
                              placeholder="Reset Options"
                              arrowStyle={{
                                height: "180%",
                                bottom: switchMerchant ? 1 : 0,
                              }}
                              placeholderStyle={{
                                color: Colors.descriptionColor,
                                fontSize: switchMerchant ? 10 : 14,
                                fontFamily: "NunitoSans-Regular",
                              }}
                              style={{ backgroundColor: "#fafafa" }}
                              labelStyle={{
                                fontSize: switchMerchant ? 10 : 14,
                                fontFamily: "NunitoSans-Regular",
                              }}
                              itemStyle={{
                                justifyContent: "flex-start",
                                fontSize: switchMerchant ? 10 : 14,
                                fontFamily: "NunitoSans-Regular",
                                marginLeft: 5,
                              }}
                              dropDownStyle={{
                                backgroundColor: "#fafafa",
                                maxHeight: 45,
                              }}
                              onChangeItem={(items) => {
                                setDropDownResetList(items);
                              }}
                              defaultValue={dropDownResetList}
                              multiple={true}
                              multipleText="%d items have been selected"
                            /> */}
                          </View>
                          <View
                            style={{
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                            }}
                          >
                            <View
                              style={{
                                width: 200,
                                height: 50,
                                justifyContent: "center",
                                marginLeft: 5,
                              }}
                            >
                              <TouchableOpacity
                                style={{
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  borderWidth: 1,
                                  borderColor: Colors.primaryColor,
                                  backgroundColor: "#4E9F7D",
                                  borderRadius: 5,
                                  width: 120,
                                  paddingHorizontal: 10,
                                  height: switchMerchant ? 35 : 40,
                                  alignItems: "center",
                                  shadowOffset: {
                                    width: 0,
                                    height: 2,
                                  },
                                  shadowOpacity: 0.22,
                                  shadowRadius: 3.22,
                                  elevation: 1,
                                  zIndex: -4,
                                }}
                                disabled={loading}
                                onPress={() => {
                                  if (dropDownResetList.length > 0) {
                                    Alert.alert(
                                      "Warning",
                                      "Are your sure want to proceed with resetting data?\n\nNote: This will clear your existing data.",
                                      [
                                        {
                                          text: "OK",
                                          onPress: () => {
                                            resetOutletData();
                                          },
                                        },
                                        {
                                          text: "Cancel",
                                          onPress: () => { },
                                        },
                                      ],
                                      { cancelable: false }
                                    );
                                  } else {
                                    Alert.alert(
                                      "Info",
                                      "Please select at least one option to proceed.",
                                      [
                                        {
                                          text: "OK",
                                          onPress: () => { },
                                        },
                                      ],
                                      { cancelable: false }
                                    );
                                  }
                                }}
                              >
                                <Text
                                  style={{
                                    color: Colors.whiteColor,
                                    //marginLeft: 5,
                                    fontSize: switchMerchant ? 10 : 16,
                                    fontFamily: "NunitoSans-Bold",
                                  }}
                                >
                                  {"RESET"}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>

                        {/* <View style={styles.viewContainer}>
                      <View>
                        <Text style={styles.textSize}>Ordering Method:</Text>
                      </View>
                      <View
                        style={{
                          flexDirection: 'row',
                          flex: 1
                        }}>
                        <TouchableOpacity
                          style={{
                            marginVertical: 10,
                            width: '100%',
                            flexDirection: 'row',
                            flex: 1,
                            marginLeft: 35
                          }}
                          onPress={() => {
                            // setState({
                            //   selfCollect: !selfCollect,
                            // });
                            setSelfCollect(!selfCollect);
                          }}
                        >
                          <View style={{
                            marginHorizontal: 15,
                            backgroundColor:
                              selfCollect
                                ? Colors.primaryColor
                                : "#e8e9eb",
                            width: 25,
                            height: 25,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 6,
                          }}>
                            <Icon
                              name="check"
                              size={17}
                              color={
                                selfCollect
                                  ? Colors.whiteColor
                                  : Colors.descriptionColor
                              }
                            />
                          </View>
                          <Text style={{
                            fontSize: 15,
                            color: selfCollect
                              ? Colors.primaryColor
                              : Colors.descriptionColor
                          }}>Self Collection</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={{
                            marginVertical: 10,
                            width: '100%',
                            flexDirection: 'row',
                            flex: 1,
                            marginLeft: 20
                          }}
                          onPress={() => {
                            // setState({
                            //   selfCollect: !selfCollect,
                            // });
                            setSelfCollect(!selfCollect);
                          }}
                        >
                          <View style={{
                            marginHorizontal: 15,
                            backgroundColor:
                              !selfCollect
                                ? Colors.primaryColor
                                : "#e8e9eb",
                            width: 25,
                            height: 25,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: 6,
                          }}>
                            <Icon
                              name="check"
                              size={17}
                              color={
                                !selfCollect
                                  ? Colors.whiteColor
                                  : Colors.descriptionColor
                              }
                            />
                          </View>
                          <Text style={{
                            fontSize: 15,
                            color: !selfCollect
                              ? Colors.primaryColor
                              : Colors.descriptionColor
                          }}>Table Order</Text>
                        </TouchableOpacity>
                      </View>
                    </View> */}

                        {/* <View style={styles.viewContainer}>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.textSize}>
                          Payment Options:
                            </Text>
                      </View>
                      <TouchableOpacity
                        onPress={() => {
                          // setState({
                          //   expandView: !expandView,
                          // });
                          setExpandView(!expandView);
                        }}>
                        <View style={styles.textInput}>
                          <Text
                            style={{
                              fontSize: 16,
                              alignSelf: 'center',
                              marginLeft: '5%',
                              color: Colors.descriptionColor,
                            }}>
                            Payment Options
                              </Text>
                          <View style={{ marginLeft: '40%', alignSelf: 'center' }}>
                            <SimpleLineIcons name="arrow-down" size={12} />
                          </View>
                        </View>
                      </TouchableOpacity>
                    </View> */}

                        <View style={styles.viewContainer}>
                          {expandView == true ? (
                            <View
                              style={{
                                width: 300,
                                height: 220,
                                backgroundColor: Colors.fieldtBgColor,
                                borderRadius: 7,
                                marginLeft: "36%",
                                flex: 1,
                              }}
                            >
                              <ScrollView
                                showsVerticalScrollIndicator={false}
                                style={{ width: "100%", height: "100%" }}
                              >
                                <TouchableOpacity
                                  style={{
                                    marginVertical: 10,
                                    width: "100%",
                                    flexDirection: "row",
                                  }}
                                  onPress={() => {
                                    // setState({
                                    //   isChecked2: !isChecked2,
                                    // });
                                    setIsChecked2(!isChecked2);
                                  }}
                                >
                                  <View
                                    style={{
                                      marginHorizontal: 15,
                                      backgroundColor: isChecked2
                                        ? Colors.primaryColor
                                        : "#e8e9eb",
                                      width: 25,
                                      height: 25,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: 6,
                                    }}
                                  >
                                    <Icon
                                      name="check"
                                      size={17}
                                      color={
                                        isChecked2
                                          ? Colors.whiteColor
                                          : Colors.descriptionColor
                                      }
                                    />
                                  </View>
                                  <Image
                                    source={{
                                      uri: "https://i.ibb.co/jLSJNG2/creditc.png",
                                    }}
                                    style={{
                                      resizeMode: "contain",
                                      width: 100,
                                      height: 30,
                                    }}
                                  />
                                </TouchableOpacity>
                                <TouchableOpacity
                                  style={{
                                    marginVertical: 10,
                                    width: "100%",
                                    flexDirection: "row",
                                  }}
                                  onPress={() => {
                                    // setState({
                                    //   isChecked3: !isChecked3,
                                    // });
                                    setIsChecked3(!isChecked3);
                                  }}
                                >
                                  <View
                                    style={{
                                      marginHorizontal: 15,
                                      backgroundColor: isChecked3
                                        ? Colors.primaryColor
                                        : "#e8e9eb",
                                      width: 25,
                                      height: 25,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: 6,
                                    }}
                                  >
                                    <Icon
                                      name="check"
                                      size={17}
                                      color={
                                        isChecked3
                                          ? Colors.whiteColor
                                          : Colors.descriptionColor
                                      }
                                    />
                                  </View>
                                  <Image
                                    source={{
                                      uri: "https://newsroom.mastercard.com/wp-content/uploads/2016/09/paypal-logo.png",
                                    }}
                                    style={{
                                      resizeMode: "contain",
                                      width: 100,
                                      height: 30,
                                    }}
                                  />
                                </TouchableOpacity>

                                <TouchableOpacity
                                  style={{
                                    marginVertical: 10,
                                    width: "100%",
                                    flexDirection: "row",
                                  }}
                                  onPress={() => {
                                    // setState({
                                    //   isChecked4: !isChecked4,
                                    // });
                                    setIsChecked4(!isChecked4);
                                  }}
                                >
                                  <View
                                    style={{
                                      marginHorizontal: 15,
                                      backgroundColor: isChecked4
                                        ? Colors.primaryColor
                                        : "#e8e9eb",
                                      width: 25,
                                      height: 25,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: 6,
                                    }}
                                  >
                                    <Icon
                                      name="check"
                                      size={17}
                                      color={
                                        isChecked4
                                          ? Colors.whiteColor
                                          : Colors.descriptionColor
                                      }
                                    />
                                  </View>
                                  <Text
                                    style={{
                                      fontSize: 18,
                                      color: isChecked4
                                        ? Colors.primaryColor
                                        : Colors.descriptionColor,
                                    }}
                                  >
                                    E-wallet
                                  </Text>
                                </TouchableOpacity>

                                <TouchableOpacity
                                  style={{
                                    marginVertical: 10,
                                    width: "100%",
                                    flexDirection: "row",
                                  }}
                                  onPress={() => {
                                    // setState({
                                    //   isChecked5: !isChecked5,
                                    // });
                                    setIsChecked5(!isChecked5);
                                  }}
                                >
                                  <View
                                    style={{
                                      marginHorizontal: 15,
                                      backgroundColor: isChecked5
                                        ? Colors.primaryColor
                                        : "#e8e9eb",
                                      width: 25,
                                      height: 25,
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: 6,
                                    }}
                                  >
                                    <Icon
                                      name="check"
                                      size={17}
                                      color={
                                        isChecked5
                                          ? Colors.whiteColor
                                          : Colors.descriptionColor
                                      }
                                    />
                                  </View>
                                  <Text
                                    style={{
                                      fontSize: 18,
                                      color: isChecked5
                                        ? Colors.primaryColor
                                        : Colors.descriptionColor,
                                    }}
                                  >
                                    Online Transfer
                                  </Text>
                                </TouchableOpacity>
                              </ScrollView>
                            </View>
                          ) : null}
                        </View>
                      </View>
                    </View>
                    {/* right */}
                    <View style={{ flex: 1, marginTop: 20 }}>
                      {/* <TouchableOpacity
                    style={[
                      styles.addNewView,
                      {
                        // alignSelf: 'center',
                      },
                    ]}
                    onPress={() => {
                      // setState({ addOutletWindow: true })
                      setAddOutletWindow(true);
                    }}>
                    <View style={styles.addButtonView}>
                      <View style={{ marginLeft: '15%' }}>
                        <AntDesign
                          name="pluscircle"
                          size={20}
                          color={Colors.primaryColor}
                        />
                      </View>
                      <Text
                        style={{ marginLeft: 10, color: Colors.primaryColor }}>
                        Add New Outlet
                      </Text>
                    </View>
                  </TouchableOpacity> */}
                      <Modal
                        animationType="fade"
                        visible={addOutletWindow}
                        transparent={true}
                      >
                        <View
                          style={{
                            backgroundColor: Colors.modalBgColor,
                            flex: 1,
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <View
                            style={{
                              width: "40%",
                              height: "30%",
                              backgroundColor: Colors.fieldtBgColor,
                              borderRadius: 12,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <TouchableOpacity
                              style={{
                                position: "absolute",
                                top: "8%",
                                right: "5%",
                                // width: 40,
                                // height: 40,
                                // backgroundColor: 'red'
                              }}
                              onPress={() => setAddOutletWindow(false)}
                            >
                              {/* <Close size={26} color={Colors.blackColor} /> */}
                              <AIcon
                                name="closecircle"
                                size={25}
                                color={Colors.fieldtTxtColor}
                              />
                            </TouchableOpacity>

                            <View style={{ padding: 13 }}>
                              <Text
                                style={{
                                  fontFamily: "NunitoSans-SemiBold",
                                  fontSize: 18,
                                }}
                              >
                                Add New Outlet Name
                              </Text>
                            </View>

                            <View
                              style={{
                                width: "50%",
                                height: "20%",
                                backgroundColor: "white",
                                borderRadius: 10,
                                marginBottom: 15,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TextInput
                                underlineColorAndroid={Colors.fieldtBgColor}
                                style={{
                                  fontFamily: "NunitoSans-Bold",
                                  fontSize: 15,
                                  color: Colors.descriptionColor,
                                  width: "100%",
                                  textAlign: "center",
                                }}
                                placeholder=" Outlet Name"
                                onChangeText={(text) => {
                                  // setState({ addOutletName: text });
                                  setAddOutletName(text);
                                }}
                                value={addOutletName}
                              />
                            </View>

                            <View
                              style={{
                                width: "30%",
                                height: "20%",
                                backgroundColor: Colors.primaryColor,
                                borderRadius: 10,
                                justifyContent: "center",
                                alignItems: "center",
                                padding: 13,
                              }}
                            >
                              <TouchableOpacity
                                onPress={() => {
                                  addOutlet();
                                  // setState({ addOutletWindow: false });
                                  setAddOutletWindow(false);
                                }}
                              >
                                <Text
                                  style={{
                                    fontFamily: "NunitoSans-SemiBold",
                                    fontSize: 20,
                                    color: "white",
                                  }}
                                >
                                  Add
                                </Text>
                              </TouchableOpacity>
                            </View>
                          </View>
                        </View>
                      </Modal>

                      {/* opening hour */}
                      <View
                        style={{
                          //flexDirection: 'row',
                          //width: '100%',
                          marginLeft: "6%",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <View style={{ flex: 1.2 }}>
                            <Text style={styles.textSize}></Text>
                          </View>
                          <View style={{ flex: 1.3, marginBottom: 20 }}>
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              Opening Hours:
                            </Text>
                          </View>
                          <View style={{ flex: 1.3, marginBottom: 20 }}>
                            <Text
                              style={{
                                fontFamily: "NunitoSans-Bold",
                                fontSize: switchMerchant ? 10 : 14,
                              }}
                            >
                              Closing Hours:
                            </Text>
                          </View>
                        </View>
                        <View style={{}}>
                          <Modal
                            style={{ flex: 1 }}
                            visible={openHourPickerVisible}
                            transparent={true}
                            animationType="slide"
                          >
                            <View
                              style={{
                                flex: 1,
                                backgroundColor: Colors.modalBgColor,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <View
                                style={{
                                  backgroundColor: Colors.whiteColor,
                                  borderRadius: 12,
                                  padding:
                                    Dimensions.get("screen").width * 0.05,
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <TimeKeeper
                                  // time={startTimeStr}
                                  onChange={(dt) => {
                                    // const date = moment(dt);
                                    // var newOpenings = openings
                                    // newOpenings[editOpeningIndex].startTime = date.format('hh:mmA')

                                    const openingTime =
                                      currOutletOpening[WEEK[editOpeningIndex]];
                                    const endTimeStr =
                                      openingTime.split("-")[1];

                                    let hour = "";
                                    let minute = "";
                                    if (dt.hour < 10) {
                                      hour = "0" + dt.hour;
                                    } else {
                                      hour = dt.hour;
                                    }
                                    if (dt.minute < 10) {
                                      minute = "0" + dt.minute;
                                    } else {
                                      minute = dt.minute;
                                    }

                                    // setState({
                                    //   openHourPickerVisible: !openHourPickerVisible,
                                    //   closeHourPickerVisible: !closeHourPickerVisible,
                                    //   openings: newOpenings
                                    // });
                                    setCurrOutletOpening({
                                      ...currOutletOpening,
                                      [WEEK[
                                        editOpeningIndex
                                      ]]: `${hour}${minute}-${endTimeStr}`,
                                    });
                                  }}
                                  onDoneClick={() =>
                                    setOpenHourPickerVisible(false)
                                  }
                                  switchToMinuteOnHourSelect
                                />
                              </View>
                            </View>
                          </Modal>
                          {/* <DateTimePickerModal
                            locale="en_GB"
                            isVisible={openHourPickerVisible}
                            mode={"time"}
                            onConfirm={(dt) => {
                              // const date = moment(dt);
                              // var newOpenings = openings
                              // newOpenings[editOpeningIndex].startTime = date.format('hh:mmA')

                              const openingTime =
                                currOutletOpening[WEEK[editOpeningIndex]];
                              const endTimeStr = openingTime.split("-")[1];

                              // setState({
                              //   openHourPickerVisible: !openHourPickerVisible,
                              //   closeHourPickerVisible: !closeHourPickerVisible,
                              //   openings: newOpenings
                              // });
                              setOpenHourPickerVisible(false);
                              setCloseHourPickerVisible(false);
                              setCurrOutletOpening({
                                ...currOutletOpening,
                                [WEEK[editOpeningIndex]]: `${moment(dt).format(
                                  "HHmm"
                                )}-${endTimeStr}`,
                              });
                            }}
                            onCancel={() => {
                              setOpenHourPickerVisible(false);
                            }}
                          /> */}

                          <Modal
                            style={{ flex: 1 }}
                            visible={closeHourPickerVisible}
                            transparent={true}
                            animationType="slide"
                          >
                            <View
                              style={{
                                flex: 1,
                                backgroundColor: Colors.modalBgColor,
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <View
                                style={{
                                  backgroundColor: Colors.whiteColor,
                                  borderRadius: 12,
                                  padding:
                                    Dimensions.get("screen").width * 0.05,
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <TimeKeeper
                                  // time={endTimeStr}
                                  onChange={(dt) => {
                                    // const date = moment(dt);
                                    // var newOpenings = openings
                                    // newOpenings[editOpeningIndex].endTime = date.format('hh:mmA')

                                    const openingTime =
                                      currOutletOpening[WEEK[editOpeningIndex]];
                                    const startTimeStr =
                                      openingTime.split("-")[0];

                                    let hour = "";
                                    let minute = "";
                                    if (dt.hour < 10) {
                                      hour = "0" + dt.hour;
                                    } else {
                                      hour = dt.hour;
                                    }
                                    if (dt.minute < 10) {
                                      minute = "0" + dt.minute;
                                    } else {
                                      minute = dt.minute;
                                    }

                                    // setState({
                                    //   closeHourPickerVisible: !closeHourPickerVisible,
                                    //   openings: newOpenings
                                    // });
                                    setCurrOutletOpening({
                                      ...currOutletOpening,
                                      [WEEK[
                                        editOpeningIndex
                                      ]]: `${startTimeStr}-${hour}${minute}`,
                                    });
                                  }}
                                  onDoneClick={() =>
                                    setCloseHourPickerVisible(false)
                                  }
                                  switchToMinuteOnHourSelect
                                />
                              </View>
                            </View>
                          </Modal>
                          {/* <DateTimePickerModal
                             locale="en_GB"
                             isVisible={closeHourPickerVisible}
                             mode={"time"}
                             onConfirm={(dt) => {
                               // const date = moment(dt);
                               // var newOpenings = openings
                               // newOpenings[editOpeningIndex].endTime = date.format('hh:mmA')
                               const openingTime =
                                 currOutletOpening[WEEK[editOpeningIndex]];
                               const startTimeStr = openingTime.split("-")[0](
                               // setState({
                               //   closeHourPickerVisible: !closeHourPickerVisible,
                               //   openings: newOpenings
                               // });
                               setOpenHourPickerVisible(false);
                               setCloseHourPickerVisible(false);
                               setCurrOutletOpening({
                                 ...currOutletOpening,
                                 [WEEK[
                                   editOpeningIndex
                                 ]]: `${startTimeStr}-${moment(dt).format(
                                   "HHmm"
                                 )}`,
                               });
                             }}
                             onCancel={() => {
                               // setState({
                               //   closeHourPickerVisible: !closeHourPickerVisible
                               // });
                               setCloseHourPickerVisible(false);
                             }}
                           /> */}
                          {currOutletOpening &&
                            currOutletOpening.uniqueId &&
                            week.map((day, index) => {
                              // var outletDay = null
                              // //console.log("openings", openings)
                              // for (const d of openings) {
                              //   if (d.week.toLowerCase() == day.toLowerCase()) {
                              //     outletDay = d
                              //   }
                              // }

                              var outletOpeningToday = null;

                              var startTime = "";
                              var endTime = "";
                              var startTimeStr = null;
                              var endTimeStr = null;

                              ////////////////////////////////////////////////////////

                              var outletOpeningWeek = [];

                              const weekOrders = [
                                "monday",
                                "tuesday",
                                "wednesday",
                                "thursday",
                                "friday",
                                "saturday",
                                "sunday",
                              ];

                              try {
                                const outletOpening = currOutletOpening;

                                if (outletOpening) {
                                  outletOpeningToday =
                                    outletOpening[WEEK[index]];
                                }

                                if (outletOpeningToday) {
                                  startTimeStr =
                                    outletOpeningToday.split("-")[0];
                                  endTimeStr = outletOpeningToday.split("-")[1];

                                  startTime = moment(startTimeStr, "HHmm");
                                  endTime = moment(endTimeStr, "HHmm");

                                  // isOpeningNow = moment().isSameOrAfter(startTime) && moment().isBefore(endTime);
                                }

                                console.log(outletOpeningToday);

                                ////////////////////////////////////////////////////////
                                // do for all days

                                // for (var i = 0; i < weekOrders.length; i++) {
                                //   const tempOpeningDay = outletOpening[weekOrders[i]];

                                //   var startTimeStrDay = '';
                                //   var endTimeStrDay = '';
                                //   var isOpeningNowDay = false;

                                //   if (tempOpeningDay && tempOpeningDay.length > 0) {
                                //     startTimeStrDay = tempOpeningDay.split('-')[0];
                                //     endTimeStrDay = tempOpeningDay.split('-')[1];

                                //     const startTime = moment(startTimeStrDay, 'HHmm');
                                //     const endTime = moment(endTimeStrDay, 'HHmm');

                                //     isOpeningNow = moment().isSameOrAfter(startTime) && moment().isBefore(endTime);
                                //   }

                                //   outletOpeningWeek.push({
                                //     startTimeStr: startTimeStrDay,
                                //     endTimeStr: endTimeStrDay,
                                //     isOpeningNow: isOpeningNowDay,
                                //     day: i,
                                //   });
                                // }
                              } catch (ex) {
                                console.error(ex);
                              }

                              return (
                                <View
                                  style={{
                                    flexDirection: "row",
                                    marginBottom: 10,
                                  }}
                                >
                                  <View style={{ flex: 1.2 }}>
                                    <Text
                                      style={{
                                        fontFamily: "NunitoSans-Bold",
                                        fontSize: switchMerchant ? 10 : 14,
                                      }}
                                    >
                                      {day}
                                    </Text>
                                  </View>
                                  <View style={{ flex: 1.3 }}>
                                    <TouchableOpacity
                                      onPress={() => {
                                        setOpenHourPickerVisible(true);
                                        setCloseHourPickerVisible(false);
                                        setEditOpeningIndex(index);
                                      }}
                                    >
                                      <Text
                                        style={{
                                          // color: Colors.fieldtTxtColor,
                                          fontFamily: "NunitoSans-Regular",
                                          color: Colors.blackColor,
                                          fontSize: switchMerchant ? 10 : 14,
                                        }}
                                      >
                                        {startTimeStr}
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                  <View style={{ flex: 1.3 }}>
                                    <TouchableOpacity
                                      style={{}}
                                      onPress={() => {
                                        setOpenHourPickerVisible(false);
                                        setCloseHourPickerVisible(true);
                                        setEditOpeningIndex(index);
                                      }}
                                    >
                                      <Text
                                        style={{
                                          // color: Colors.fieldtTxtColor,
                                          fontFamily: "NunitoSans-Regular",
                                          color: Colors.blackColor,
                                          fontSize: switchMerchant ? 10 : 14,
                                        }}
                                      >
                                        {endTimeStr}
                                      </Text>
                                    </TouchableOpacity>
                                  </View>
                                </View>
                              );
                            })}
                        </View>
                      </View>

                      <View
                        style={{
                          marginTop: 20,
                          width: "100%",
                          // backgroundColor: 'red',

                          // flexDirection: 'row',
                          // alignItems: 'center',
                          // justifyContent: 'center',
                        }}
                      >
                        {/* <View
                      style={{
                        // backgroundColor: 'red',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        margin: 15,
                      }}> */}

                        {/* <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                          fontFamily: 'NunitoSans-Bold',
                          fontSize: 15,
                          color: Colors.fieldtTxtColor,
                          // width: '100%',
                          backgroundColor: Colors.fieldtBgColor,
                          width: 140,
                          textAlign: 'center',
                          height: 40,
                          borderRadius: 8,
                          marginBottom: 10
                        }}
                        placeholder="Printer Name"
                        onChangeText={(text) => {
                          // setState({ addOutletName: text });
                          // setAddOutletName(text);
                          setPrinterName(text);
                        }}
                        defaultValue={printerName}
                        // keyboardType={'number-pad'}
                      />       */}

                        {/* <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                          fontFamily: 'NunitoSans-Bold',
                          fontSize: 15,
                          color: Colors.fieldtTxtColor,
                          // width: '100%',
                          backgroundColor: Colors.fieldtBgColor,
                          width: 140,
                          textAlign: 'center',
                          height: 40,
                          borderRadius: 8,
                        }}
                        placeholder="Printer IP"
                        onChangeText={(text) => {
                          // setState({ addOutletName: text });
                          // setAddOutletName(text);
                          setPrinterIP(text);
                        }}
                        defaultValue={printerIP}
                        keyboardType={'number-pad'}
                      /> */}

                        {/* <View
                      style={{
                        // backgroundColor: 'red',

                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 15,

                      }}> */}

                        {/* <TouchableOpacity
                        style={{
                          justifyContent: 'center',
                          flexDirection: 'row',
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: '#4E9F7D',
                          borderRadius: 5,
                          width: 160,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: 'center',
                          shadowOffset: {
                              width: 0,
                              height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginBottom: 10,
                        }}
                        onPress={() => {
                          // setState({ addOutletWindow: true })
                          bindPrinter();
                        }}>
                        <View>
                          <Text style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: 16,
                              fontFamily: 'NunitoSans-Bold',
                          }}>
                            BIND PRINTER IP
                          </Text>
                        </View>
                      </TouchableOpacity> */}

                        {/* <TouchableOpacity
                        style={{
                          justifyContent: 'center',
                          flexDirection: 'row',
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: '#4E9F7D',
                          borderRadius: 5,
                          width: 180,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: 'center',
                          shadowOffset: {
                              width: 0,
                              height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                          marginBottom: 10,
                        }}
                        onPress={() => {
                          unbindPrinter();
                        }}>
                        <View>
                          <Text style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: 16,
                              fontFamily: 'NunitoSans-Bold',
                          }}>
                            UNBIND PRINTER IP
                          </Text>
                        </View>
                      </TouchableOpacity> */}

                        {/* <TouchableOpacity
                        style={{
                          justifyContent: 'center',
                          flexDirection: 'row',
                          borderWidth: 1,
                          borderColor: Colors.primaryColor,
                          backgroundColor: '#4E9F7D',
                          borderRadius: 5,
                          width: 160,
                          paddingHorizontal: 10,
                          height: 40,
                          alignItems: 'center',
                          shadowOffset: {
                              width: 0,
                              height: 2,
                          },
                          shadowOpacity: 0.22,
                          shadowRadius: 3.22,
                          elevation: 1,
                          zIndex: -1,
                        }}
                        onPress={() => {
                          testPrinter();
                        }}>
                        <View>
                          <Text style={{
                              color: Colors.whiteColor,
                              //marginLeft: 5,
                              fontSize: 16,
                              fontFamily: 'NunitoSans-Bold',
                          }}>
                            TEST PRINTER
                          </Text>
                        </View>
                      </TouchableOpacity> */}
                        {/* </View> */}
                        {/* </View> */}
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      alignItems: "center",
                      marginBottom: 30,
                      zIndex: -1000,
                      // borderWidth: 1,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        borderWidth: 1,
                        borderColor: Colors.primaryColor,
                        backgroundColor: "#4E9F7D",
                        borderRadius: 5,
                        width: 120,
                        paddingHorizontal: 10,
                        height: switchMerchant ? 35 : 40,
                        alignItems: "center",
                        shadowOffset: {
                          width: 0,
                          height: 2,
                        },
                        shadowOpacity: 0.22,
                        shadowRadius: 3.22,
                        elevation: 1,
                        zIndex: -4,
                      }}
                      disabled={loading}
                      onPress={() => {
                        // update();
                        // editOutlet();
                        updateOutletDetails();
                      }}
                    >
                      <Text
                        style={{
                          color: Colors.whiteColor,
                          //marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: "NunitoSans-Bold",
                        }}
                      >
                        {loading ? "LOADING..." : "SAVE"}
                      </Text>
                    </TouchableOpacity>
                  </View>
                  {/* <View
                    style={{
                      height: 120,
                    }}></View>
                  <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginHorizontal: '2%', marginBottom: '2%' }}>
                    <Text style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Bold', }}>Version: </Text>
                    <Text style={{ fontSize: switchMerchant ? 10 : 16, fontFamily: 'NunitoSans-Regular' }}>{version}</Text>
                  </View> */}
                </ScrollView>
              </View>
            ) : null}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#ffffff",
    flexDirection: "row",
    fontFamily: "NunitoSans-Regular",
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  headerLogo1: {
    width: "100%",
    height: "100%",
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: "row",
    alignItems: "center",
  },
  listItem: {
    fontFamily: "NunitoSans-Regular",
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    width: useWindowDimensions.width * Styles.sideBarWidth,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 20,
    width: useWindowDimensions.width * (1 - Styles.sideBarWidth),
    // backgroundColor: 'lightgrey',
    backgroundColor: Colors.highlightColor,
  },
  textInput: {
    fontFamily: "NunitoSans-Regular",
    width: 200,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 0,
    flex: 1,
    flexDirection: "row",
  },
  textInputLocation: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 100,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 10,

    // paddingLeft: '5%',
    paddingRight: "10%",
  },
  textInput8: {
    fontFamily: "NunitoSans-Regular",
    width: 75,
    height: 50,
    flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },
  textInput9: {
    fontFamily: "NunitoSans-Regular",
    width: 110,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "center",
  },
  textInput10: {
    fontFamily: "NunitoSans-Regular",
    width: 200,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "center",
  },
  textInput1: {
    fontFamily: "NunitoSans-Regular",
    width: 250,
    height: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 10,
  },
  textInput2: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginRight: 30,
  },
  textInput3: {
    fontFamily: "NunitoSans-Regular",
    width: "85%",
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    alignSelf: "center",
    paddingHorizontal: 10,
  },
  textInput4: {
    width: "85%",
    height: 70,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
  textInput5: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 50,
  },
  textInput6: {
    fontFamily: "NunitoSans-Regular",
    width: "80 %",
    padding: 16,
    backgroundColor: Colors.fieldtBgColor,
    marginRight: 30,
    borderRadius: 10,
    alignSelf: "center",
  },
  textInput7: {
    fontFamily: "NunitoSans-Regular",
    width: 300,
    height: 80,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 7,
    marginRight: 30,
  },
  button: {
    backgroundColor: Colors.primaryColor,
    width: 150,
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 20,
  },
  button1: {
    width: "15%",
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 20,
  },
  button2: {
    backgroundColor: Colors.primaryColor,
    width: "60%",
    padding: 8,
    borderRadius: 10,
    alignItems: "center",
    marginLeft: "2%",
  },
  button3: {
    backgroundColor: Colors.primaryColor,
    width: "30%",
    height: 50,
    borderRadius: 10,
    alignItems: "center",
    alignSelf: "center",
    marginBottom: 30,
  },
  textSize: {
    fontSize: 16,
    fontFamily: "NunitoSans-SemiBold",
  },
  viewContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginVertical: 15,
    height: 40,
  },
  openHourContainer: {
    flexDirection: "row",
    alignItems: "center",
    //justifyContent: 'space-between',
    //flex: 1,
    marginBottom: 15,
    //width: '100%',
    //paddingRight: 30,
  },
  addButtonView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 200,
    height: 40,
    alignItems: "center",
  },
  addButtonView1: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: "center",
  },
  addNewView: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 65,
    marginTop: 0,
    width: "83%",
    alignSelf: "flex-end",
  },

  addNewView1: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 10,
    alignItems: "center",
  },
  addNewView2: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 65,
    marginTop: 7,
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: "center",
  },
  merchantDisplayView: {
    flexDirection: "row",
    flex: 1,
    marginLeft: "17%",
  },
  shiftView: {
    flexDirection: "row",
    backgroundColor: Colors.fieldtBgColor2,
    borderRadius: 50,
    width: 200,
    height: 60,
    alignItems: "center",
    marginTop: 30,
    alignSelf: "center",
  },
  shiftText: {
    marginLeft: "15%",
    color: Colors.primaryColor,

    fontFamily: "NunitoSans-Bold",
    fontSize: 25,
  },
  closeView: {
    flexDirection: "row",
    borderRadius: 5,
    borderColor: Colors.primaryColor,
    borderWidth: 1,
    width: 250,
    height: 40,
    alignItems: "center",
    marginTop: 30,
    alignSelf: "center",
    marginBottom: "20%",
  },
  taxView: {
    flexDirection: "row",
    borderWidth: 2,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: "center",
    marginTop: 20,
    alignSelf: "flex-end",
  },
  sectionView: {
    flexDirection: "row",
    borderRadius: 5,
    padding: 16,
    alignItems: "center",
  },
  receiptView: {
    flexDirection: "row",
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 200,
    height: 40,
    alignItems: "center",
    alignSelf: "flex-end",
  },
  pinBtn: {
    backgroundColor: Colors.fieldtBgColor,
    width: 70,
    height: 70,
    marginBottom: 16,
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  pinNo: {
    fontSize: 20,
    fontWeight: "bold",
  },
  confirmBox: {
    width: "30%",
    height: "30%",
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
  circleIcon2: {
    width: 120,
    height: 120,
    resizeMode: "contain",
    flex: 1,
  },
  circleIcon: {
    width: 150,
    height: 150,
    resizeMode: "contain",
    alignSelf: "center",
  },
});
export default SettingsScreen;
