export const TARGET_USER_GROUP = {
    EVERYONE: 'EVERYONE',
    ACTIVE_MEMBER: 'ACTIVE_MEMBER',
    INACTIVE_MEMBER: 'INACTIVE_MEMBER',
    FEMALE: 'FEMALE',
    MALE: 'MALE',
    VIP_ONLY: 'VIP_ONLY',
};

export const TARGET_USER_GROUP_DROPDOWN_LIST = [
    {
        label: 'Everyone',
        value: TARGET_USER_GROUP.EVERYONE,
    },
    // {
    //     label: 'Active Member',
    //     value: TARGET_USER_GROUP.ACTIVE_MEMBER,
    // },
    // {
    //     label: 'Inactive Member',
    //     value: TARGET_USER_GROUP.INACTIVE_MEMBER,
    // },
    // {
    //     label: 'Female',
    //     value: TARGET_USER_GROUP.FEMALE,
    // },
    // {
    //     label: 'Male',
    //     value: TARGET_USER_GROUP.MALE,
    // },
    // {
    //     label: 'VIP Only',
    //     value: TARGET_USER_GROUP.VIP_ONLY,
    // },
];

export const EFFECTIVE_DAY = {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY',
    WEEKDAY: 'WEEKDAY',
    WEEKEND: 'WEEKEND',
    ALLDAY: 'ALLDAY',
};

export const EFFECTIVE_DAY_DROPDOWN_LIST = [
    {
        label: 'Monday',
        value: EFFECTIVE_DAY.MONDAY,
    },
    {
        label: 'Tuesday',
        value: EFFECTIVE_DAY.TUESDAY,
    },
    {
        label: 'Wednesday',
        value: EFFECTIVE_DAY.WEDNESDAY,
    },
    {
        label: 'Thursday',
        value: EFFECTIVE_DAY.THURSDAY,
    },
    {
        label: 'Friday',
        value: EFFECTIVE_DAY.FRIDAY,
    },
    {
        label: 'Saturday',
        value: EFFECTIVE_DAY.SATURDAY,
    },
    {
        label: 'Sunday',
        value: EFFECTIVE_DAY.SUNDAY,
    },
    {
        label: 'Weekday',
        value: EFFECTIVE_DAY.WEEKDAY,
    },
    {
        label: 'Weekend',
        value: EFFECTIVE_DAY.WEEKDAY,
    },
    {
        label: 'Every Day',
        value: EFFECTIVE_DAY.ALLDAY,
    },
];

export const PROMOTION_TYPE = {    
    OVERRIDE_EXISTING_PRICE: 'OVERRIDE_EXISTING_PRICE',
    BUY_A_GET_B_FOR_FREE: 'BUY_A_GET_B_FOR_FREE',    
    PWP: 'PWP',    
    TAKE_AMOUNT_OFF: 'TAKE_AMOUNT_OFF',
    TAKE_PERCENTAGE_OFF: 'TAKE_PERCENTAGE_OFF',
    CASHBACK: 'CASHBACK',
    COMBO_SET_OR_PACKAGE_OR_BUNDLE: 'COMBO_SET_OR_PACKAGE_OR_BUNDLE',
    BUY_A_GET_X_FOR_DISCOUNT: 'BUY_A_GET_B_FOR_%_DISCOUNT',
    BUY_AB_GET_VOUCHER: 'BUY_AB_GET_VOUCHER',
    DELIVERY: 'DELIVERY',
    TAKEAWAY: 'TAKEAWAY',
};

export const PROMOTION_TYPE_PARSED = {    
    OVERRIDE_EXISTING_PRICE: 'Override Existing Price',
    BUY_A_GET_B_FOR_FREE: 'Bundle Deal',
    PWP: 'Purchase With Purchase',    
    TAKE_AMOUNT_OFF: 'Take Amount Off',
    TAKE_PERCENTAGE_OFF: 'Take Percentage Off',
    CASHBACK: 'Cashback',
    COMBO_SET_OR_PACKAGE_OR_BUNDLE: 'Combo Set / Package / Bundle',
    BUY_A_GET_X_FOR_DISCOUNT: 'Buy A Get X For Discount',
    BUY_AB_GET_VOUCHER: 'Buy AB Get Voucher',
    DELIVERY: 'Delivery',
    TAKEAWAY: 'Takeaway',
};

export const PROMOTION_TYPE_DROPDOWN_LIST = [
    {
        label: 'Override Existing Price',
        value: PROMOTION_TYPE.OVERRIDE_EXISTING_PRICE,
    },
    {
        label: 'Bundle Deal',
        value: PROMOTION_TYPE.BUY_A_GET_B_FOR_FREE,
    },
    // {
    //     label: 'PWP',
    //     value: PROMOTION_TYPE.PWP,
    // },    
    {
        label: 'Take Amount Off',
        value: PROMOTION_TYPE.TAKE_AMOUNT_OFF,
    },
    {
        label: 'Take Percentage Off',
        value: PROMOTION_TYPE.TAKE_PERCENTAGE_OFF,
    },
    {
        label: 'Delivery',
        value: PROMOTION_TYPE.DELIVERY,
    },
    {
        label: 'Takeaway',
        value: PROMOTION_TYPE.TAKEAWAY,
    }
    // {
    //     label: 'Cashback',
    //     value: PROMOTION_TYPE.CASHBACK,
    // },
    // {
    //     label: 'Combo Set/Package/Bundle',
    //     value: PROMOTION_TYPE.COMBO_SET_OR_PACKAGE_OR_BUNDLE,
    // },
    // {
    //     label: 'Buy A Get B For % Discount',
    //     value: PROMOTION_TYPE.BUY_A_GET_X_FOR_DISCOUNT,
    // },
    // {
    //     label: 'Buy AB Get Voucher',
    //     value: PROMOTION_TYPE.BUY_AB_GET_VOUCHER,
    // }
];

export const PROMOTION_TYPE_VARIATION = {
    PRODUCT_OF_CATEGORY: 'PRODUCT_OF_CATEGORY',
    SPECIFIC_PRODUCTS: 'SPECIFIC_PRODUCTS',
};

export const PROMOTION_TYPE_VARIATION_DROPDOWN_LIST = [
    {
        label: 'Product Category',
        value: PROMOTION_TYPE_VARIATION.PRODUCT_OF_CATEGORY,
    },
    {
        label: 'Products',
        value: PROMOTION_TYPE_VARIATION.SPECIFIC_PRODUCTS,
    },
];

export const CRM_SEGMENT_DROPDOWN_LIST = [
    {
        label: 'Everyone',
        value: 'EVERYONE',
    },
    // {
    //     label: 'Active Member',
    //     value: TARGET_USER_GROUP.ACTIVE_MEMBER,
    // },
    // {
    //     label: 'Inactive Member',
    //     value: TARGET_USER_GROUP.INACTIVE_MEMBER,
    // },
    // {
    //     label: 'Female',
    //     value: TARGET_USER_GROUP.FEMALE,
    // },
    // {
    //     label: 'Male',
    //     value: TARGET_USER_GROUP.MALE,
    // },
    // {
    //     label: 'VIP Only',
    //     value: TARGET_USER_GROUP.VIP_ONLY,
    // },
];

export const DATE_FORMAT_TYPE = {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH'
};

export const DATE_FORMAT_DROPDOWN_LIST = [
    {
        label: 'Day',
        value: DATE_FORMAT_TYPE.DAY,
    },
    {
        label: 'Week',
        value: DATE_FORMAT_TYPE.WEEK,
    },
    {
        label: 'Month',
        value: DATE_FORMAT_TYPE.MONTH,
    },
];

export const EFFECTIVE_TYPE = {
    DAY: 'DAY',
};
