import React, { Component, useState, useEffect, useRef } from "react";
import {
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableHighlight,
  View,
  StyleSheet,
  Image,
  Dimensions,
  Alert,
  Modal,
  useWindowDimensions,
} from "react-native";
import Colors from "../constant/Colors";
import Styles from "../constant/Styles";
import { ReactComponent as Dashboard } from "../assets/svg/Dashboard.svg";
import { ReactComponent as DashboardG } from "../assets/svg/Dashboard.svg";
import { ReactComponent as CRM } from "../assets/svg/CRM.svg";
import { ReactComponent as Employees } from "../assets/svg/Employees.svg";
import { ReactComponent as Inventory } from "../assets/svg/Inventory.svg";
import { ReactComponent as Operation } from "../assets/svg/Operation.svg";
import { ReactComponent as Product } from "../assets/svg/Product.svg";
import { ReactComponent as Promotions } from "../assets/svg/Promotions.svg";
import { ReactComponent as Redemption } from "../assets/svg/Redemption.svg";
import { ReactComponent as RedemptionG } from "../assets/svg/RedemptionG.svg";
import { ReactComponent as Report } from "../assets/svg/Report.svg";
import { ReactComponent as Settings } from "../assets/svg/Settings.svg";
import { ReactComponent as Transactions } from "../assets/svg/Transactions.svg";
import { ReactComponent as CRMG } from "../assets/svg/CRMG.svg";
import { ReactComponent as Loyalty } from "../assets/svg/Loyalty.svg";
import { ReactComponent as Logout } from "../assets/svg/Logout.svg";
import { ReactComponent as TicketConfirmationOutline } from "../assets/svg/TicketConfirmationOutline.svg";
// import GCoin from '../assets/svg/GCoin';
// import Coins from '../assets/svg/Coins';
import { ReactComponent as EmployeesG } from "../assets/svg/EmployeesG.svg";
import { ReactComponent as InventoryG } from "../assets/svg/InventoryG.svg";
import { ReactComponent as OperationG } from "../assets/svg/OperationG.svg";
import { ReactComponent as ProductG } from "../assets/svg/ProductG.svg";
import { ReactComponent as PromotionsG } from "../assets/svg/PromotionsG.svg";
import { ReactComponent as ReportG } from "../assets/svg/ReportG.svg";
import { ReactComponent as SettingsG } from "../assets/svg/SettingsG.svg";
import { ReactComponent as TransactionsG } from "../assets/svg/TransactionsG.svg";

// import Arrow from '../assets/svg/Arrow';
import * as User from "../util/User";
import AsyncStorage from "@react-native-async-storage/async-storage";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
// import {isTablet} from 'react-native-device-detection';
import { CommonStore } from "../store/commonStore";
// import {color, onChange} from 'react-native-reanimated';
import { EXPAND_TAB_TYPE, MODE_ADD_CART } from "../constant/common";
// import {suppressDeprecationWarnings} from 'moment';
import ApiClient from "../util/ApiClient";
import API from "../constant/API";
// import IdleTimer from 'react-idle-timer'

import { useLinkTo, useRoute } from "@react-navigation/native";
import imgLogo from "../asset/image/logo.png";
import { isMobile } from "../util/common";
import { DataStore } from "../store/dataStore";
import { prefix } from "../constant/env";

const isTablet = true;

const SideBar = (props) => {
  // Used for navigation
  const { navigation } = props;

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const linkTo = useLinkTo();

  const [expandOperation, setExpandOperation] = useState(
    props.expandOperation === undefined ? false : props.expandOperation
  );
  const [expandProduct, setExpandProduct] = useState(
    props.expandProduct === undefined ? false : props.expandProduct
  );
  const [expandInventory, setExpandInventory] = useState(
    props.expandInventory === undefined ? false : props.expandInventory
  );
  const [expandSales, setExpandSales] = useState(
    props.expandSales === undefined ? false : props.expandSales
  );
  const [expandPromotions, setExpandPromotions] = useState(
    props.expandPromotions === undefined ? false : props.expandPromotions
  );
  const [expandCRM, setExpandCRM] = useState(
    props.expandCRM === undefined ? false : props.expandCRM
  );
  const [expandLoyaltyPoints, setExpandLoyaltyPoints] = useState(
    props.expandLoyaltyPoints === undefined ? false : props.expandLoyaltyPoints
  );
  const [expandVoucher, setExpandVoucher] = useState(
    props.expandVoucher === undefined ? false : props.expandVoucher
  );
  const [expandTransactions, setExpandTransactions] = useState(
    props.expandTransactions === undefined ? false : props.expandTransactions
  );
  const [expandReport, setExpandReport] = useState(
    props.expandReport === undefined ? false : props.expandReport
  );
  const [expandEmployees, setExpandEmployees] = useState(
    props.expandEmployees === undefined ? false : props.expandEmployees
  );
  const [expandSettings, setExpandSettings] = useState(
    props.expandSettings === undefined ? false : props.expandSettings
  );
  const [expandRedemption, setExpandRedemption] = useState(
    props.expandRedemption === undefined ? false : props.expandRedemption
  );
  const [selectedTab, setSelectedTab] = useState(
    props.selectedTab === undefined ? 0 : props.selectedTab
  );
  const [switchMerchant, setSwitchMerchant] = useState(isTablet ? false : true);

  const myScroll = useRef(null);

  // const [currPage, setCurrPage] = useState('');

  const currPage = CommonStore.useState((s) => s.currPage);
  const currPageStack = CommonStore.useState((s) => s.currPageStack);

  const expandTab = CommonStore.useState((s) => s.expandTab);

  const [isMounted, setIsMounted] = useState(false);

  // useEffect(() => {
  //     expandAction(selectedTab);
  // }, [selectedTab])

  // componentDidMount() {
  //     expandAction(selectedTab)

  //     // retrieveAsyncStorage();
  // }

  // async retrieveAsyncStorage() {
  //     const switchMerchant = await AsyncStorage.getItem('switchMerchant');

  //     if (switchMerchant === '1') {
  //         setState({
  //             switchMerchant: true,
  //         });
  //     }
  // }

  const logOutButton = async () => {
    if (window.confirm("Logout: Do you want to logout?")) {
      const body = {};

      ApiClient.POST(API.logoutUser, body).then((result) => {
        User.setlogin(false);
        // User.getRefreshMainScreen();
        linkTo && linkTo(`${prefix}/login`);
      });
    }

    return;
  };

  // const logOutButton = async () => {
  //     const [signoutTime, setSignoutTime] = useState(0);
  //         let logoutTimeout;

  //     const setTimeout = () => {
  //         logoutTimeout = setTimeout(logout, signoutTime);
  //     };

  //     const clearTimeout = () => {
  //         if (logoutTimeout) clearTimeout(logoutTimeout);
  //     };

  //     // useEffect(() => {
  //     //     setSignoutTime(20000);
  //     //     const events = [
  //     //         'load',
  //     //         'mousemove',
  //     //         'mousedown',
  //     //         'click',
  //     //         'scroll',
  //     //         'keypress'
  //     //     ];

  //     const setSignoutTime = 2000; //2 second

  //     const resetTimeout = () => {
  //         clearTimeout();
  //         setTimeout();
  //     };

  //     for (var i in events) {
  //         window.addEventListener(events[i], resetTimeout);
  //     }

  //     setTimeout();
  //     };

  //     if (signoutTime) {
  //         return (
  //             Alert.alert(
  //                 "Session Timeout",
  //                 "Timeout! You want to stay?",
  //                 [
  //                     {
  //                         text: 'Yes',
  //                         // onPress={async () => {
  //                         //     await AsyncStorage.clear();
  //                         //     User.setlogin(false);
  //                         //     User.getRefreshMainScreen();
  //                         // }}
  //                         onPress: () => {
  //                             User.setlogin(false);
  //                             User.getRefreshMainScreen();

  //                         },
  //                     },

  //                     {
  //                         text: 'No',
  //                         onPress: () => { },
  //                     }
  //                 ]
  //             )
  //         )
  //     }

  //             Alert.alert(
  //                 'Logout',
  //                 "Do you want to logout?",
  //                 [
  //                     {
  //                         text: 'Yes',
  //                         // onPress={async () => {
  //                         //     await AsyncStorage.clear();
  //                         //     User.setlogin(false);
  //                         //     User.getRefreshMainScreen();
  //                         // }}
  //                         onPress: () => {
  //                             User.setlogin(false);
  //                             User.getRefreshMainScreen();

  //                         },
  //                     },

  //                     {
  //                         text: 'No',
  //                         onPress: () => { },
  //                     }
  //                 ]
  //             )
  // }

  // Modal.setAppElement('#root')

  // const IdleTimerContainer = () => {
  //     const [isLoggedIn, setIsLoggedIn] = useState(true)
  //     const [modalIsOpen, setModalIsOpen] = useState(false)
  //     const  idleTimerRef = useRef(null)
  //     const sessionTimeoutRef = usseRef(null)

  //     const onIdle = () => {
  //         setModalIsOpen(true)
  //     }

  //     const stayActive = () => {
  //         setModalIsOpen(false)
  //         clearTimeout(sessionTimeoutRef.current)

  //     }

  //     const stayActive = () => {
  //         setModalIsOpen(false)
  //         clearTimeout(sessionTimeoutRef.current)

  //     }

  //     const logOut = () => {
  //         setModalIsOpen(false)
  //         setIsLoggedIn(false)
  //         clearTimeout(sessionTimeoutRef.current)

  //     }

  //     return (
  //     <div>
  //         {isLoggedIn ? <h2> Hello Vishawa </h2>:  <h2> Hello Guest</h2>}
  //         <Modal isOpen = {modalIsOpen}>
  //             <h2> You've been idle for a while! </h2>
  //             <p> You will be logged out soon </p>
  //             <div>
  //                 <button onClick = {logOut}> Log me out </button>
  //                 <button onClick = {stayActive}> Keep me signed in </button>
  //             </div>
  //         </Modal>
  //         <IdlerTimer ref = {idleTimerRef}
  //                     timeout = {5*1000}
  //                     onIdle = {onIdle}/>

  //     </div>
  //     )
  // }

  useEffect(() => {
    if (linkTo) {
      DataStore.update((s) => {
        s.linkToFunc = linkTo;
      });
    }
  }, [linkTo]);

  useEffect(() => {
    // reset states
    CommonStore.update((s) => {
      // s.selectedProductEdit = null;
      // s.selectedOutletCategoryEdit = null;
      // s.selectedSupplierEdit = null;
      // s.selectedPurchaseOrderEdit = null;
      // s.selectedStockTransferEdit = null;
      // s.selectedStockTakeEdit = null;
      // s.selectedVoucherEdit = null;
      // s.selectedOutletEmployeeEdit = null;

      // s.selectedPromotionEdit = null;

      // s.selectedCustomerEdit = null;

      // experimental
      s.modeAddCart = MODE_ADD_CART.NORMAL;
    });
  }, [currPage]);

  useEffect(() => {
    if (isMounted) {
      if (expandTab == EXPAND_TAB_TYPE.DASHBOARD) {
        myScroll && myScroll.current && myScroll.current.scrollTo({ y: 0 });
      } else if (expandTab == EXPAND_TAB_TYPE.OPERATION) {
        myScroll &&
          myScroll.current &&
          myScroll.current.scrollTo({
            y: Dimensions.get("screen").height * 0.1,
          });
      } else if (expandTab == EXPAND_TAB_TYPE.PRODUCT) {
        // myScroll && myScroll.current && myScroll.current.scrollTo({ y: 173 })
        myScroll &&
          myScroll.current &&
          myScroll.current.scrollTo({
            y: Dimensions.get("screen").height * 0.15,
          });
      } else if (expandTab == EXPAND_TAB_TYPE.INVENTORY) {
        // myScroll && myScroll.current && myScroll.current.scrollTo({ y: 255.5 })
        myScroll &&
          myScroll.current &&
          myScroll.current.scrollTo({
            y: Dimensions.get("screen").height * 0.2,
          });
      } else if (expandTab == EXPAND_TAB_TYPE.DOCKET) {
        myScroll &&
          myScroll.current &&
          myScroll.current.scrollTo({
            y: Dimensions.get("screen").height * 0.3,
          });
      } else if (expandTab == EXPAND_TAB_TYPE.VOUCHER) {
        myScroll &&
          myScroll.current &&
          myScroll.current.scrollTo({
            y: Dimensions.get("screen").height * 0.35,
          });
      } else if (expandTab == EXPAND_TAB_TYPE.CRM) {
        myScroll && myScroll.current && myScroll.current.scrollToEnd();
      } else if (expandTab == EXPAND_TAB_TYPE.TRANSACTIONS) {
        myScroll && myScroll.current && myScroll.current.scrollToEnd();
      } else if (expandTab == EXPAND_TAB_TYPE.REPORT) {
        myScroll && myScroll.current && myScroll.current.scrollToEnd();
      } else if (expandTab == EXPAND_TAB_TYPE.EMPLOYEES) {
        myScroll && myScroll.current && myScroll.current.scrollToEnd();
      } else if (expandTab == EXPAND_TAB_TYPE.SETTINGS) {
        myScroll && myScroll.current && myScroll.current.scrollToEnd();
      } else if (expandTab == EXPAND_TAB_TYPE.PROMOTION) {
        myScroll && myScroll.current && myScroll.current.scrollToEnd();
      } else if (expandTab == EXPAND_TAB_TYPE.LOYALTY) {
        myScroll && myScroll.current && myScroll.current.scrollToEnd();
      }
    } else {
      setIsMounted(true);
    }
  }, [expandTab]);

  const expandAction = (selectedTabParam) => {
    console.log("selectedTabParam");
    console.log(selectedTabParam);

    // reset states
    CommonStore.update((s) => {
      s.selectedProductEdit = null;
      s.selectedOutletCategoryEdit = null;
      s.selectedSupplierEdit = null;
      s.selectedPurchaseOrderEdit = null;
      s.selectedStockTransferEdit = null;
      s.selectedStockTakeEdit = null;
      s.selectedVoucherEdit = null;
      s.selectedOutletEmployeeEdit = null;

      s.selectedPromotionEdit = null;

      s.selectedCustomerEdit = null;

      // experimental
      s.modeAddCart = MODE_ADD_CART.NORMAL;
    });

    if (selectedTabParam == 0) {
      myScroll && myScroll.current && myScroll.current.scrollTo({ y: 0 });
      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: false,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(false);
    } else if (selectedTabParam == 1) {
      myScroll &&
        myScroll.current &&
        myScroll.current.scrollTo({ y: Dimensions.get("screen").height * 0.1 });
      // setState({
      //     expandOperation: true,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: false,
      //     expandRedemption: false,
      // })
      setExpandOperation(true);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(false);
    } else if (selectedTabParam == 2) {
      // myScroll && myScroll.current && myScroll.current.scrollTo({ y: 173 })
      myScroll &&
        myScroll.current &&
        myScroll.current.scrollTo({
          y: Dimensions.get("screen").height * 0.15,
        });

      // setState({
      //     expandOperation: false,
      //     expandProduct: true,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: false,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(true);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(false);
    } else if (selectedTabParam == 3) {
      // myScroll && myScroll.current && myScroll.current.scrollTo({ y: 255.5 })
      myScroll &&
        myScroll.current &&
        myScroll.current.scrollTo({ y: Dimensions.get("screen").height * 0.2 });

      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: true,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: false,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(true);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(false);
    } else if (selectedTabParam == 4) {
      myScroll &&
        myScroll.current &&
        myScroll.current.scrollTo({ y: Dimensions.get("screen").height * 0.3 });
      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: false,
      //     expandRedemption: true,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(true);
      setExpandVoucher(false);
    } else if (selectedTabParam == 5) {
      myScroll &&
        myScroll.current &&
        myScroll.current.scrollTo({
          y: Dimensions.get("screen").height * 0.35,
        });
      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: true,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: false,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(true);
    } else if (selectedTabParam == 6) {
      myScroll && myScroll.current && myScroll.current.scrollToEnd();
      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: true,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: false,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(true);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(false);
    } else if (selectedTabParam == 7) {
      myScroll && myScroll.current && myScroll.current.scrollToEnd();
      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: true,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: false,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(true);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(false);
    } else if (selectedTabParam == 8) {
      myScroll && myScroll.current && myScroll.current.scrollToEnd();
      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: true,
      //     expandEmployees: false,
      //     expandSettings: false,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(true);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(false);
    } else if (selectedTabParam == 9) {
      myScroll && myScroll.current && myScroll.current.scrollToEnd();
      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: true,
      //     expandSettings: false,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(true);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(false);
    } else if (selectedTabParam == 10) {
      myScroll && myScroll.current && myScroll.current.scrollToEnd();
      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: true,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(true);
      setExpandRedemption(false);
      setExpandVoucher(false);
    } else if (selectedTabParam == 11) {
      myScroll && myScroll.current && myScroll.current.scrollToEnd();
      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: true,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(true);
      setExpandCRM(false);
      setExpandLoyaltyPoints(false);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(false);
    } else if (selectedTabParam == 12) {
      myScroll && myScroll.current && myScroll.current.scrollToEnd();
      // setState({
      //     expandOperation: false,
      //     expandProduct: false,
      //     expandInventory: false,
      //     expandSales: false,
      //     expandPromotions: false,
      //     expandCRM: false,
      //     expandTransactions: false,
      //     expandReport: false,
      //     expandEmployees: false,
      //     expandSettings: true,
      //     expandRedemption: false,
      // })
      setExpandOperation(false);
      setExpandProduct(false);
      setExpandInventory(false);
      setExpandSales(false);
      setExpandPromotions(false);
      setExpandCRM(false);
      setExpandLoyaltyPoints(true);
      setExpandTransactions(false);
      setExpandReport(false);
      setExpandEmployees(false);
      setExpandSettings(false);
      setExpandRedemption(false);
      setExpandVoucher(false);
    }
  };

  var sidebarFontSizeTablet = 14;

  if (Dimensions.get("screen").width <= 1024) {
    sidebarFontSizeTablet = 12;
    styles.sidebarArrow = { paddingRight: 20 };

    if (isTablet) {
      styles.expandedItems = {
        fontSize: 12,
        alignSelf: "center",
        textAlign: "center",
      };
    } else {
      styles.expandedItems = {
        fontSize: 12,
        alignSelf: "center",
        textAlign: "center",
      };
    }
    //remember chg back to fontsize 12
  }

  const sidebarTextStyleScale = [
    styles.sidebarTextStyle,
    !isTablet
      ? {
        fontSize:
          Dimensions.get("screen").width <= 720
            ? 9
            : switchMerchant
              ? 10
              : 12,
      }
      : {
        fontSize: sidebarFontSizeTablet,
      },
  ];

  const sideBarItemsContainerStyleScale = [
    // styles.sidebarItems,
    !isTablet
      ? {
        // height: Dimensions.get('screen').height * 0.1,
      }
      : undefined,
  ];

  const sideBarItemsStyleScale = [
    styles.sidebarItems,
    {
      width: Dimensions.get("screen").width * Styles.sideBarWidth,
    },
    !isTablet
      ? {
        // width: Dimensions.get('screen').width * 0.08,
        height: Dimensions.get("screen").height * 0.15,
        // backgroundColor: 'red'
      }
      : undefined,
  ];

  const sidebarIconStyleScale = [
    // sidebarIconStyleScale,
    !isTablet
      ? {
        // marginRight: 10,
      }
      : {
        // marginRight: 12,
      },
  ];

  const arrowSize = !isTablet ? 17 : 12;

  const iconSize = !isTablet ? 0.04 : 0.02;

  const highlightStyle = {
    // backgroundColor: '#405d27',
    // opacity: 0,
  };

  return (
    <ScrollView
      style={[
        styles.scrollView,
        {
          // backgroundColor: 'red',
          width: windowWidth * Styles.sideBarWidth,
          height: windowHeight,
        },
        switchMerchant
          ? {
            // width: '10%'
          }
          : {},
      ]}
      showsVerticalScrollIndicator={false}
      ref={myScroll}
      contentContainerStyle={{
        paddingBottom: Dimensions.get("screen").height * 0.1,
      }}
    >
      <TouchableHighlight
        style={[
          { sideBarItemsStyleScale },
          switchMerchant
            ? {
              // borderWidth: 1,
              justifyContent: "center",
            }
            : {},
        ]}
        // underlayColor={Colors.primaryColor}
        underlayColor={Colors.lightPrimary}
        onPress={() => {
          if (expandTab !== EXPAND_TAB_TYPE.DASHBOARD) {
            CommonStore.update((s) => {
              s.expandTab = EXPAND_TAB_TYPE.DASHBOARD;
            });
          }

          // props.navigation.navigate('Dashboard');
          linkTo && linkTo(`${prefix}/dashboard`);
        }}
      >
        {expandTab == EXPAND_TAB_TYPE.DASHBOARD ? (
          <View
            style={[
              styles.sidebarView,
              { backgroundColor: "Colors.lightPrimary" },
              switchMerchant
                ? {
                  // width: '100%'
                }
                : {},
            ]}
          >
            <View style={sidebarIconStyleScale}>
              <DashboardG
                width={
                  switchMerchant
                    ? Dimensions.get("screen").width * 0.02
                    : Dimensions.get("screen").width * iconSize
                }
                height={
                  switchMerchant
                    ? Dimensions.get("screen").width * 0.02
                    : Dimensions.get("screen").width * iconSize
                }
              />
            </View>
            <View style={styles.sidebarText}>
              <Text
                style={[sidebarTextStyleScale, { color: Colors.primaryColor }]}
              >
                Dashboard
              </Text>
            </View>
          </View>
        ) : (
          <View
            style={[
              styles.sidebarView,
              ,
              switchMerchant
                ? {
                  // width: '100%'
                }
                : {},
            ]}
          >
            <View style={sidebarIconStyleScale}>
              <Dashboard
                width={
                  switchMerchant
                    ? Dimensions.get("screen").width * 0.02
                    : Dimensions.get("screen").width * iconSize
                }
                height={
                  switchMerchant
                    ? Dimensions.get("screen").width * 0.02
                    : Dimensions.get("screen").width * iconSize
                }
              />
            </View>
            <View style={styles.sidebarText}>
              <Text style={sidebarTextStyleScale}>Dashboard</Text>
            </View>
          </View>
        )}
      </TouchableHighlight>

      {/* <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setState({ expandOperation: !expandOperation, selectedTab: 1 });

            // setExpandOperation(!expandOperation);
            // setSelectedTab(1);
            // expandAction(1);

            if (expandTab !== EXPAND_TAB_TYPE.OPERATION) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.OPERATION;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.OPERATION ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <OperationG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Operation
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <Operation
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={[sidebarTextStyleScale, {}]}>Operation</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab == EXPAND_TAB_TYPE.OPERATION ? (
          <View
            style={[
              {
                paddingVertical: 16,
                paddingTop: 5,
                backgroundColor:
                  expandTab == EXPAND_TAB_TYPE.OPERATION
                    ? Colors.lightPrimary
                    : null,
                alignItems: "center",
              },
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Kitchen');
                // setSelectedTab(1);
                // expandAction(1);
                // setCurrPage('Kitchen');
                CommonStore.update((s) => {
                  s.currPage = "Kitchen";
                  s.currPageStack = [...currPageStack, "Kitchen"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.OPERATION) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.OPERATION;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Kitchen" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage === "Kitchen"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 10 } : {},
                ]}
              >
                Kitchen
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Table');
                // setSelectedTab(1);
                // expandAction(1);
                // setCurrPage('Table');
                CommonStore.update((s) => {
                  s.currPage = "Table";
                  s.currPageStack = [...currPageStack, "Table"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.OPERATION) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.OPERATION;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Table" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage === "Table"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 10 } : {},
                ]}
              >
                Table
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Order'),
                // setSelectedTab(1);
                // expandAction(1);
                CommonStore.update((s) => {
                  s.currPage = "Order";
                  s.currPageStack = [...currPageStack, "Order"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.OPERATION) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.OPERATION;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Order" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage === "Order"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 10 } : {},
                ]}
              >
                Dine-In
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Takeaway');
                // setSelectedTab(1), expandAction(1),
                CommonStore.update((s) => {
                  s.currPage = "Takeaway";
                  s.currPageStack = [...currPageStack, "Takeaway"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.OPERATION) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.OPERATION;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Takeaway" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Takeaway"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 10 } : {},
                ]}
              >
                Takeaway
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Reservation');
                // setSelectedTab(1), expandAction(1),
                CommonStore.update((s) => {
                  s.currPage = "Reservation";
                  s.currPageStack = [...currPageStack, "Reservation"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.OPERATION) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.OPERATION;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Reservation" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Reservation"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 10 } : {},
                ]}
              >
                Reservation
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Queue');
                // setSelectedTab(1), expandAction(1),
                CommonStore.update((s) => {
                  s.currPage = "Queue";
                  s.currPageStack = [...currPageStack, "Queue"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.OPERATION) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.OPERATION;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Queue" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Queue"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 10 } : {},
                ]}
              >
                Queue
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('History');
                // setSelectedTab(1), expandAction(1),
                CommonStore.update((s) => {
                  s.currPage = "History";
                  s.currPageStack = [...currPageStack, "History"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.OPERATION) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.OPERATION;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "History" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "History"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 10 } : {},
                ]}
              >
                History
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View> */}

      {/* <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setState({ expandEmployees: !expandEmployees, selectedTab: 9 }),
            // setExpandEmployees(!expandEmployees);
            // setSelectedTab(9);
            // expandAction(9)

            if (expandTab !== EXPAND_TAB_TYPE.RESERVATIONS) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.RESERVATIONS;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.RESERVATIONS ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <Inventory
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Reservations
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <InventoryG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Reservations</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab === EXPAND_TAB_TYPE.RESERVATIONS ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab === EXPAND_TAB_TYPE.RESERVATIONS
                  ? Colors.lightPrimary
                  : null,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('FloorScreen');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "FloorScreen";
                  s.currPageStack = [...currPageStack, "FloorScreen"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.RESERVATIONS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.RESERVATIONS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Employee" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Employee"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Floor`}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "Guests";
                  s.currPageStack = [...currPageStack, "Guests"];
                  linkTo && linkTo(`${prefix}/guests`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.RESERVATIONS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.RESERVATIONS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Employee" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Employee"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Guests`}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('FeedbackScreen');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "FeedbackScreen";
                  s.currPageStack = [...currPageStack, "FeedbackScreen"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.RESERVATIONS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.RESERVATIONS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Employee" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Employee"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Feedback`}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('AnalyticReservationScreen');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "AnalyticReservationScreen";
                  s.currPageStack = [
                    ...currPageStack,
                    "AnalyticReservationScreen",
                  ];
                });

                if (expandTab !== EXPAND_TAB_TYPE.RESERVATIONS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.RESERVATIONS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Employee" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Employee"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Analytics`}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('LoyaltyPhone');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "LoyaltyPhone";
                  s.currPageStack = [...currPageStack, "LoyaltyPhone"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.RESERVATIONS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.RESERVATIONS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Employee" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Employee"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Loyalty\nPhone`}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('NewSettingsScreen');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "NewSettingsScreen";
                  s.currPageStack = [...currPageStack, "NewSettingsScreen"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.RESERVATIONS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.RESERVATIONS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Employee" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Employee"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Settings`}</Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View> */}

      <View style={[sideBarItemsContainerStyleScale]}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setState({
            //     expandProduct: !expandProduct,
            //     selectedTab: 2
            // });
            // setExpandProduct(!expandProduct);
            // setSelectedTab(2);
            // expandAction(2)

            if (expandTab !== EXPAND_TAB_TYPE.PRODUCT) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.PRODUCT;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.PRODUCT ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <ProductG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Product
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <Product
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Product</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab === EXPAND_TAB_TYPE.PRODUCT ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab == EXPAND_TAB_TYPE.PRODUCT
                  ? Colors.lightPrimary
                  : null,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('ProductCategory');
                // setSelectedTab(2), expandAction(2),
                CommonStore.update((s) => {
                  s.currPage = "ProductCategory";
                  s.currPageStack = [...currPageStack, "ProductCategory"];
                });

                linkTo && linkTo(`${prefix}/productcategory`);

                if (expandTab !== EXPAND_TAB_TYPE.PRODUCT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.PRODUCT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ProductCategory" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "ProductCategory"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 10, height: "150%" } : {},
                ]}
              >{`Product\nCategory`}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('ProductMenu');
                // setSelectedTab(2), expandAction(2),
                CommonStore.update((s) => {
                  s.currPage = "ProductMenu";
                  s.currPageStack = [...currPageStack, "ProductMenu"];
                });

                linkTo && linkTo(`${prefix}/productmenu`);

                if (expandTab !== EXPAND_TAB_TYPE.PRODUCT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.PRODUCT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ProductMenu" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "ProductMenu"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                {"Menu\nDisplay"}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('PreorderPackage');
                // setSelectedTab(2), expandAction(2),
                CommonStore.update((s) => {
                  s.currPage = "PreorderPackage";
                  s.currPageStack = [...currPageStack, "PreorderPackage"];
                });

                linkTo && linkTo(`${prefix}/preorderpackage`);

                if (expandTab !== EXPAND_TAB_TYPE.PRODUCT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.PRODUCT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "PreorderPackage" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "PreorderPackage"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Manage\nPreorder`}</Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>

      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setState({ expandInventory: !expandInventory, selectedTab: 3 });
            // setExpandInventory(!expandInventory);
            // setSelectedTab(3);
            // expandAction(3)

            if (expandTab !== EXPAND_TAB_TYPE.INVENTORY) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.INVENTORY;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.INVENTORY ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <InventoryG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Inventory
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <Inventory
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Inventory</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab === EXPAND_TAB_TYPE.INVENTORY ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab == EXPAND_TAB_TYPE.INVENTORY
                  ? Colors.lightPrimary
                  : null,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate('Supplier', {supplier: true});
                // setSelectedTab(3), expandAction(3),
                CommonStore.update((s) => {
                  s.currPage = "Supplier";
                  s.currPageStack = [...currPageStack, "Supplier"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.INVENTORY) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.INVENTORY;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Supplier" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Supplier"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9 } : {},
                ]}
              >
                Supplier
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                 props.navigation.navigate('Inventory', {lowStockAlert: true});
                // setSelectedTab(3), expandAction(3),
                CommonStore.update((s) => {
                  s.currPage = "Inventory";
                  s.currPageStack = [...currPageStack, "Inventory"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.INVENTORY) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.INVENTORY;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Inventory" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Inventory"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Inventory\nOverview`}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('PurchaseOrder', {
                //   purchaseOrder: true,
                // });
                // setSelectedTab(3), expandAction(3),
                CommonStore.update((s) => {
                    s.currPage = "PurchaseOrderScreen";
                    s.currPageStack = [...currPageStack, "PurchaseOrderScreen"];
                  });

                  linkTo && linkTo(`${prefix}/purchaseorderscreen`);

                  if (expandTab !== EXPAND_TAB_TYPE.INVENTORY) {
                    CommonStore.update((s) => {
                      s.expandTab = EXPAND_TAB_TYPE.INVENTORY;
                    });
                  }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "PurchaseOrderScreen" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "PurchaseOrderScreen"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Purchase\nOrder`}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('StockTransfer', {
                //   stockTransfer: true,
                // }),
                // setSelectedTab(3), expandAction(3),
                CommonStore.update((s) => {
                  s.currPage = "StockTransferScreen";
                  s.currPageStack = [...currPageStack, "StockTransferScreen"];
                });

                linkTo && linkTo(`${prefix}/stocktransferscreen`);

                if (expandTab !== EXPAND_TAB_TYPE.INVENTORY) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.INVENTORY;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "StockTransferScreen" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "StockTransferScreen"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                {"Stock\nTransfer"}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('StockTake', {stockTake: true}),
                // setSelectedTab(3), expandAction(3),
                CommonStore.update((s) => {
                  s.currPage = "StockTake";
                  s.currPageStack = [...currPageStack, "StockTake"];
                });

                linkTo && linkTo(`${prefix}/stocktakescreen`);

                if (expandTab !== EXPAND_TAB_TYPE.INVENTORY) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.INVENTORY;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "StockTake" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "StockTake"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                {"Stock\nTake"}
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>

      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            //setState({ expandRedemption: !expandRedemption, selectedTab: 4 });
            // setExpandRedemption(!expandRedemption);
            // setSelectedTab(4);
            // expandAction(4)

            if (expandTab !== EXPAND_TAB_TYPE.DOCKET) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.DOCKET;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.DOCKET ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <RedemptionG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Docket
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <Redemption
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Docket</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab === EXPAND_TAB_TYPE.DOCKET ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab == EXPAND_TAB_TYPE.DOCKET
                  ? Colors.lightPrimary
                  : null,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('SettingRedemption');
                // setSelectedTab(4), expandAction(4),
                CommonStore.update((s) => {
                  s.currPage = "SettingRedemption";
                  s.currPageStack = [...currPageStack, "SettingRedemption"];
                });

                linkTo && linkTo(`${prefix}/settingredemptionscreen`);

                if (expandTab !== EXPAND_TAB_TYPE.DOCKET) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.DOCKET;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "SettingRedemption" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "SettingRedemption"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Manage\nDocket`}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // setSelectedTab(4),
                // props.navigation.navigate('RedemptionRedeemed'),
                // setSelectedTab(4), expandAction(4),
                CommonStore.update((s) => {
                  s.currPage = "RedemptionRedeemed";
                  s.currPageStack = [...currPageStack, "RedemptionRedeemed"];
                });

                linkTo && linkTo(`${prefix}/redemptionredeemed`);

                if (expandTab !== EXPAND_TAB_TYPE.DOCKET) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.DOCKET;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "RedemptionRedeemed" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "RedemptionRedeemed"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Redeemed\nDocket`}</Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>

      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setState({ expandPromotions: !expandPromotions, selectedTab: 5 }),
            // setExpandVoucher(!expandVoucher);
            // setSelectedTab(5);
            // expandAction(5)

            if (expandTab !== EXPAND_TAB_TYPE.VOUCHER) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.VOUCHER;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.VOUCHER ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <TicketConfirmationOutline
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    color={"#4E9F7D"}
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Voucher
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <TicketConfirmationOutline
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    color={"#ACACAC"}
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Voucher</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab == EXPAND_TAB_TYPE.VOUCHER ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab == EXPAND_TAB_TYPE.VOUCHER
                  ? Colors.lightPrimary
                  : null,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // setSelectedTab(5), expandAction(5),
                props.navigation.navigate('VoucherScreen');
                CommonStore.update((s) => {
                  s.currPage = "VoucherScreen";
                  s.currPageStack = [...currPageStack, "VoucherScreen"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.VOUCHER) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.VOUCHER;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "VoucherScreen" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "VoucherScreen"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                E-vouchers
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // setSelectedTab(5), expandAction(5),
                props.navigation.navigate('VoucherReport');
                CommonStore.update((s) => {
                  s.currPage = "VoucherReport";
                  s.currPageStack = [...currPageStack, "VoucherReport"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.VOUCHER) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.VOUCHER;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "VoucherReport" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "VoucherReport"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                {"Voucher\nReport"}
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>

      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setExpandPromotions(!expandPromotions);
            //  setSelectedTab(11);
            //  expandAction(11)
            //  props.navigation.navigate("PromotionList") 
            // setExpandCRM(!expandPromotions);
            // setSelectedTab(11);
            // expandAction(11)

            if (expandTab !== EXPAND_TAB_TYPE.PROMOTION) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.PROMOTION;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.PROMOTION ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <PromotionsG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Promotion
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <Promotions
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Promotion</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab === EXPAND_TAB_TYPE.PROMOTION ? (
          <View>
            <View
              style={{
                paddingVertical: 16,
                paddingTop: 5,
                backgroundColor:
                  expandTab == EXPAND_TAB_TYPE.PROMOTION
                    ? Colors.lightPrimary
                    : null,
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  // props.navigation.navigate('PromotionList', {selectedTab: 11}),
                  // setSelectedTab(11), expandAction(11),
                  CommonStore.update((s) => {
                    s.currPage = "PromotionList";
                    s.currPageStack = [...currPageStack, "PromotionList"];
                  });

                  linkTo && linkTo(`${prefix}/promotionlist`);

                  if (expandTab !== EXPAND_TAB_TYPE.PROMOTION) {
                    CommonStore.update((s) => {
                      s.expandTab = EXPAND_TAB_TYPE.PROMOTION;
                    });
                  }
                }}
                style={[
                  styles.subBar,
                  {
                    ...(currPage === "PromotionList" && highlightStyle),
                  },
                ]}
              >
                <Text
                  style={[
                    styles.expandedItems,
                    {
                      color:
                        currPage == "PromotionList"
                          ? Colors.primaryColor
                          : Colors.descriptionColor,
                    },
                    switchMerchant ? { fontSize: 9, height: "150%" } : {},
                  ]}
                >
                  {"Promotion\nList"}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  //   props.navigation.navigate('PromotionReport', {
                  //     selectedTab: 11,
                  //   }),
                  // setSelectedTab(11), expandAction(11),
                  CommonStore.update((s) => {
                    s.currPage = "PromotionReport";
                    s.currPageStack = [...currPageStack, "PromotionReport"];
                  });

                  linkTo && linkTo(`${prefix}/promotionreport`);

                  if (expandTab !== EXPAND_TAB_TYPE.PROMOTION) {
                    CommonStore.update((s) => {
                      s.expandTab = EXPAND_TAB_TYPE.PROMOTION;
                    });
                  }
                }}
                style={[
                  styles.subBar,
                  {
                    ...(currPage === "PromotionReport" && highlightStyle),
                  },
                ]}
              >
                <Text
                  style={[
                    styles.expandedItems,
                    {
                      color:
                        currPage == "PromotionReport"
                          ? Colors.primaryColor
                          : Colors.descriptionColor,
                    },
                    switchMerchant ? { fontSize: 9, height: "150%" } : {},
                  ]}
                >{`Promotion\nReport`}</Text>
              </TouchableOpacity>
            </View>
          </View>
        ) : null}
      </View>

      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setState({ expandPromotions: !expandPromotions, selectedTab: 6 }),
            // setExpandCRM(!expandCRM);
            // setSelectedTab(6);
            // expandAction(6)

            if (expandTab !== EXPAND_TAB_TYPE.CRM) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.CRM;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.CRM ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <CRMG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    color={Colors.primaryColor}
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    CRM
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <CRM
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>CRM</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab === EXPAND_TAB_TYPE.CRM ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab === EXPAND_TAB_TYPE.CRM ? Colors.lightPrimary : null,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "CrmScreen";
                  s.currPageStack = [...currPageStack, "CrmScreen"];
                  linkTo && linkTo(`${prefix}/crmscreen`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.CRM) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.CRM;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "CrmScreen" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "CrmScreen"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Customers`}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "SegmentScreen";
                  s.currPageStack = [...currPageStack, "SegmentScreen"];
                  linkTo && linkTo(`${prefix}/segmentscreen`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.CRM) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.CRM;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "SegmentScreen" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "SegmentScreen"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Segment\nScreen`}</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
                            onPress={() => {
                                props.navigation.navigate('NewCustomer', { selectedTab: 6 }), setSelectedTab(6), expandAction(6), CommonStore.update(s => {
                                    s.currPage = 'NewCustomer';
                                    s.currPageStack = [
                                        ...currPageStack,
                                        'NewCustomer',
                                    ];
                                });
                            }}
                            style={[styles.subBar, {
                                ...(currPage === 'NewCustomer') && highlightStyle,
                            }]}>
                            <Text style={[styles.expandedItems, { color: currPage == "NewCustomer" ? Colors.primaryColor : Colors.descriptionColor }]}>New Customers</Text>
                        </TouchableOpacity> */}
          </View>
        ) : null}
      </View>

      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setExpandLoyaltyPoints(!expandLoyaltyPoints);
            // setSelectedTab(12);
            // expandAction(12)

            if (expandTab !== EXPAND_TAB_TYPE.LOYALTY) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.LOYALTY;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.LOYALTY ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={[sidebarIconStyleScale, {}]}>
                  <Loyalty
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    color={Colors.primaryColor}
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Loyalty
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View tyle={[sidebarIconStyleScale, {}]}>
                  <Loyalty
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    color={Colors.descriptionColor}
                    style={{ opacity: 0.75 }}
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Loyalty</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab === EXPAND_TAB_TYPE.LOYALTY ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab == EXPAND_TAB_TYPE.LOYALTY
                  ? Colors.lightPrimary
                  : null,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "NewLoyaltyCampaignScreen";
                  s.currPageStack = [...currPageStack, "NewLoyaltyCampaignScreen"];
                  linkTo && linkTo(`${prefix}/newloyaltycampaignscreen`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.LOYALTY) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.LOYALTY;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "NewLoyaltyCampaignScreen" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage === "NewLoyaltyCampaignScreen"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                {"Loyalty\nCampaign"}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('SettingCredit');
                // setSelectedTab(12), expandAction(12),
                CommonStore.update((s) => {
                  s.currPage = "SettingCredit";
                  s.currPageStack = [...currPageStack, "SettingCredit"];
                  linkTo && linkTo(`${prefix}/settingcredit`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.LOYALTY) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.LOYALTY;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "SettingCredit" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage === "SettingCredit"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                {"Loyalty\nPoints"}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('LoyaltyStampScreen');
                // setSelectedTab(12), expandAction(12),
                CommonStore.update((s) => {
                  s.currPage = "LoyaltyStampScreen";
                  s.currPageStack = [...currPageStack, "LoyaltyStampScreen"];
                  linkTo && linkTo(`${prefix}/loyaltystampscreen`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.LOYALTY) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.LOYALTY;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "LoyaltyStampScreen" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "LoyaltyStampScreen"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                {"Loyalty\nStamps"}
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>

      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setState({ expandTransactions: !expandTransactions, selectedTab: 7 }),
            // setExpandTransactions(!expandTransactions);
            // setSelectedTab(7);
            // expandAction(7)

            if (expandTab !== EXPAND_TAB_TYPE.TRANSACTIONS) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.TRANSACTIONS;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.TRANSACTIONS ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <TransactionsG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Transactions
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <Transactions
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Transactions</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab === EXPAND_TAB_TYPE.TRANSACTIONS ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab == EXPAND_TAB_TYPE.TRANSACTIONS
                  ? Colors.lightPrimary
                  : null,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('AllTransaction');
                // setSelectedTab(7), expandAction(7),
                CommonStore.update((s) => {
                  s.currPage = "AllTransaction";
                  s.currPageStack = [...currPageStack, "AllTransaction"];
                });

                linkTo && linkTo(`${prefix}/alltransaction`);

                if (expandTab !== EXPAND_TAB_TYPE.TRANSACTIONS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.TRANSACTIONS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "AllTransaction" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "AllTransaction"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`All\nTransactions`}</Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>

      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setState({ expandReport: !expandReport, selectedTab: 8 }),
            // setExpandReport(!expandReport);
            // setSelectedTab(8);
            // expandAction(8)

            if (expandTab !== EXPAND_TAB_TYPE.REPORT) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.REPORT;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.REPORT ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <ReportG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Report
                  </Text>
                </View>
                {/* <View style={[styles.sidebarArrow]}>
                                    <View style={{ transform: [{ rotate: '90deg' }], width: 18 }}>
                                        <Arrow width={arrowSize} height={arrowSize} />
                                    </View>
                                </View> */}
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <Report
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Report</Text>
                </View>
                {/* <View style={[styles.sidebarArrow]}>
                                    <Arrow width={arrowSize} height={arrowSize} />
                                </View> */}
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab == EXPAND_TAB_TYPE.REPORT ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab === EXPAND_TAB_TYPE.REPORT
                  ? Colors.lightPrimary
                  : null,
              alignItems: "center",
            }}
          >
            {/* <TouchableOpacity
                            onPress={() => { props.navigation.navigate("ReportStockValue"), setSelectedTab(8), expandAction(8) }}
                            style={styles.subBar}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Stock Value</Text>
                        </TouchableOpacity> */}
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "ReportSalesOvertime";
                  s.currPageStack = [...currPageStack, "ReportSalesOvertime"];
                  linkTo && linkTo(`${prefix}/reportsalesovertime`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.REPORT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.REPORT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ReportSalesOvertime" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "ReportSalesOvertime"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                Overview
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "ReportSalesProduct";
                  s.currPageStack = [...currPageStack, "ReportSalesProduct"];
                  linkTo && linkTo(`${prefix}/reportsalesproduct`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.REPORT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.REPORT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ReportSalesProduct" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "ReportSalesProduct"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                Product
              </Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
                            onPress={() => { props.navigation.navigate('Inventory'), setSelectedTab(8), expandAction(8) }}
                            style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Inventory</Text>
                        </TouchableOpacity> */}
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "ReportSalesCategory";
                  s.currPageStack = [...currPageStack, "ReportSalesCategory"];
                  linkTo && linkTo(`${prefix}/reportsalescategory`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.REPORT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.REPORT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ReportSalesCategory" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "ReportSalesCategory"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                Category
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "ReportSalesVariant";
                  s.currPageStack = [...currPageStack, "ReportSalesVariant"];
                  linkTo && linkTo(`${prefix}/reportsalesvariant`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.REPORT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.REPORT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ReportSalesVariant" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "ReportSalesVariant"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                Variants
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "ReportSalesAddOns";
                  s.currPageStack = [...currPageStack, "ReportSalesAddOns"];
                  linkTo && linkTo(`${prefix}/reportsalesaddons`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.REPORT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.REPORT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ReportSalesAddOns" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "ReportSalesAddOns"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                Add-Ons
              </Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
                            onPress={() => { props.navigation.navigate('ReportSalesSKU'), setSelectedTab(8), expandAction(8) }}
                            style={[styles.subBar,]}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>SKU</Text>
                        </TouchableOpacity> */}
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "ReportSalesTransaction";
                  s.currPageStack = [
                    ...currPageStack,
                    "ReportSalesTransaction",
                  ];
                  linkTo && linkTo(`${prefix}/reportsalestransaction`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.REPORT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.REPORT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ReportSalesTransaction" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "ReportSalesTransaction"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                Channel
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "ReportSalesPaymentMethod";
                  s.currPageStack = [
                    ...currPageStack,
                    "ReportSalesPaymentMethod",
                  ];
                  linkTo && linkTo(`${prefix}/reportsalespaymentmethod`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.REPORT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.REPORT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ReportSalesPaymentMethod" &&
                    highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "ReportSalesPaymentMethod"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                Payment
              </Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
                            onPress={() => {
                                props.navigation.navigate('ReportSalesByRedemption'), setSelectedTab(8), expandAction(8), CommonStore.update(s => {
                                    s.currPage = 'ReportSalesByRedemption';
                                });
                            }}
                            style={[styles.subBar, {
                                ...(currPage === 'ReportSalesByRedemption') && highlightStyle,
                            }]}>
                            <Text style={[styles.expandedItems, { color: currPage == "ReportSalesByRedemption" ? Colors.primaryColor : Colors.descriptionColor }]}>Redemption</Text>
                        </TouchableOpacity> */}
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "ReportSaleByShift";
                  s.currPageStack = [...currPageStack, "ReportSaleByShift"];
                  linkTo && linkTo(`${prefix}/reportsalebyshift`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.REPORT) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.REPORT;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ReportSaleByShift" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "ReportSaleByShift"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >
                Shift
              </Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
                            onPress={() => { props.navigation.navigate('AllTransaction', {selectedTab: 8}), setSelectedTab(8), expandAction(8) }}
                            style={[styles.subBar, ]}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>All Transactions</Text>
                        </TouchableOpacity> */}
            {/* <TouchableOpacity
                            onPress={() => { props.navigation.navigate(""), setSelectedTab(8), expandAction(8) }}
                            style={styles.subBar}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Promotions report</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => { props.navigation.navigate(""), setSelectedTab(8), expandAction(8) }}
                            style={styles.subBar}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Stock Value</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => { props.navigation.navigate(""), setSelectedTab(8), expandAction(8) }}
                            style={styles.subBar}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Customer payment owing {'\n'}report</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => { props.navigation.navigate(""), setSelectedTab(8), expandAction(8) }}
                            style={styles.subBar}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Commission /overriding</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => { props.navigation.navigate(""), setSelectedTab(8), expandAction(8) }}
                            style={styles.subBar}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Loyalty Points</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => { props.navigation.navigate(""), setSelectedTab(8), expandAction(8) }}
                            style={styles.subBar}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Package/credit purchase & {'\n'}redemption</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => { props.navigation.navigate(""), setSelectedTab(8), expandAction(8) }}
                            style={styles.subBar}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>History</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => { setState({ expandSales: true }) }}
                            style={styles.subBar}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Sales</Text>
                        </TouchableOpacity> */}
            {/* {expandSales == true ? (
                            <View>
                                <TouchableOpacity
                                    onPress={() => { props.navigation.navigate('ReportSalesOvertime'), setSelectedTab(8), expandAction(8) }}
                                    style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                                    <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Overtime</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => { props.navigation.navigate('ReportSalesProduct'), setSelectedTab(8), expandAction(8) }}
                                    style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                                    <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Product</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => { props.navigation.navigate('Inventory'), setSelectedTab(8), expandAction(8) }}
                                    style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                                    <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Inventory</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => { props.navigation.navigate('ReportSalesCategory'), setSelectedTab(8), expandAction(8) }}
                                    style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                                    <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Category / Tags</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => { props.navigation.navigate('ReportSalesVariant'), setSelectedTab(8), expandAction(8) }}
                                    style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                                    <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Variants</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => { props.navigation.navigate('ReportSalesSKU'), setSelectedTab(8), expandAction(8) }}
                                    style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                                    <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>SKU</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => { props.navigation.navigate('ReportSalesTransaction'), setSelectedTab(8), expandAction(8) }}
                                    style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                                    <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Transaction</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => { props.navigation.navigate('ReportSalesPaymentMethod'), setSelectedTab(8), expandAction(8) }}
                                    style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                                    <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Payment Method</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => { props.navigation.navigate('ReportSaleByShift'), setSelectedTab(8), expandAction(8) }}
                                    style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                                    <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Shift</Text>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => { props.navigation.navigate('AllTransaction'), setSelectedTab(8), expandAction(8) }}
                                    style={[styles.subBar, { left: Dimensions.get('screen').width * 0.045 }]}>
                                    <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>All Transactions</Text>
                                </TouchableOpacity>
                            </View>

                        ) : null} */}
          </View>
        ) : null}
      </View>
      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setState({ expandEmployees: !expandEmployees, selectedTab: 9 }),
            // setExpandEmployees(!expandEmployees);
            // setSelectedTab(9);
            // expandAction(9)

            if (expandTab !== EXPAND_TAB_TYPE.EMPLOYEES) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.EMPLOYEES;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab == EXPAND_TAB_TYPE.EMPLOYEES ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <EmployeesG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Employees
                  </Text>
                </View>
                {/* <View style={[styles.sidebarArrow]}>
                                    <View style={{ transform: [{ rotate: '90deg' }], width: arrowSize }}>
                                        <Arrow width={arrowSize} height={arrowSize} />
                                    </View>
                                </View> */}
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <Employees
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Employees</Text>
                </View>
                {/* <View style={[styles.sidebarArrow]}>
                                    <Arrow width={arrowSize} height={arrowSize} />
                                </View> */}
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab === EXPAND_TAB_TYPE.EMPLOYEES ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab === EXPAND_TAB_TYPE.EMPLOYEES
                  ? Colors.lightPrimary
                  : null,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Employee');
                // setSelectedTab(9), expandAction(9),
                CommonStore.update((s) => {
                  s.currPage = "Employee";
                  s.currPageStack = [...currPageStack, "Employee"];
                  linkTo && linkTo(`${prefix}/employee`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.EMPLOYEES) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.EMPLOYEES;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "Employee" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "Employee"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9, height: "150%" } : {},
                ]}
              >{`Manage\nEmployee`}</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity
                            onPress={() => { props.navigation.navigate("Employee"); setSelectedTab(9), expandAction(9) }}
                            style={styles.subBar}>
                            <Text style={[styles.expandedItems, { color: currPage == "" ? Colors.primaryColor : Colors.descriptionColor }]}>Employee Performance</Text>
                        </TouchableOpacity> */}
          </View>
        ) : null}
      </View>

      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          onPress={() => {
            // setState({ expandSettings: !expandSettings, selectedTab: 10 }),
            // setExpandSettings(!expandSettings);
            // setSelectedTab(10);
            // expandAction(10)

            if (expandTab !== EXPAND_TAB_TYPE.SETTINGS) {
              CommonStore.update((s) => {
                s.expandTab = EXPAND_TAB_TYPE.SETTINGS;
              });
            }
          }}
          style={sideBarItemsStyleScale}
        >
          {expandTab === EXPAND_TAB_TYPE.SETTINGS ? (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  { backgroundColor: Colors.lightPrimary },
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <SettingsG
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text
                    style={[
                      sidebarTextStyleScale,
                      { color: Colors.primaryColor },
                    ]}
                  >
                    Settings
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View>
              <View
                style={[
                  styles.sidebarView,
                  switchMerchant
                    ? {
                      // borderWidth: 1,
                      // width: '48%'
                    }
                    : {},
                ]}
              >
                <View style={sidebarIconStyleScale}>
                  <Settings
                    width={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                    height={
                      switchMerchant
                        ? Dimensions.get("screen").width * 0.02
                        : Dimensions.get("screen").width * iconSize
                    }
                  />
                </View>
                <View style={styles.sidebarText}>
                  <Text style={sidebarTextStyleScale}>Settings</Text>
                </View>
              </View>
            </View>
          )}
        </TouchableHighlight>
        {expandTab === EXPAND_TAB_TYPE.SETTINGS ? (
          <View
            style={{
              paddingVertical: 16,
              paddingTop: 5,
              backgroundColor:
                expandTab === EXPAND_TAB_TYPE.SETTINGS
                  ? Colors.lightPrimary
                  : null,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('Setting');
                // setSelectedTab(10), expandAction(10),
                CommonStore.update((s) => {
                  s.currPage = "SettingsScreen";
                  s.currPageStack = [...currPageStack, "SettingsScreen"];
                  linkTo && linkTo(`${prefix}/settingsscreen`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.SETTINGS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.SETTINGS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "SettingsScreen" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "SettingsScreen"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9 } : {},
                ]}
              >
                General
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('SettingShift');
                // setSelectedTab(10), expandAction(10),
                CommonStore.update((s) => {
                  s.currPage = "SettingShift";
                  s.currPageStack = [...currPageStack, "SettingShift"];
                  linkTo && linkTo(`${prefix}/settingshift`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.SETTINGS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.SETTINGS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "SettingShift" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "SettingShift"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9 } : {},
                ]}
              >
                Shift
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('SettingReceipt');
                // setSelectedTab(10), expandAction(10),
                CommonStore.update((s) => {
                  s.currPage = "SettingReceiptScreen";
                  s.currPageStack = [...currPageStack, "SettingReceiptScreen"];
                  linkTo && linkTo(`${prefix}/settingreceiptscreen`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.SETTINGS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.SETTINGS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "ReportSalesCategory" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "SettingReceipt"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9 } : {},
                ]}
              >
                Receipt
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate('SettingOrder');
                //setSelectedTab(10), expandAction(10),
                CommonStore.update((s) => {
                  s.currPage = "SettingOrder";
                  s.currPageStack = [...currPageStack, "SettingOrder"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.SETTINGS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.SETTINGS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "SettingOrder" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "SettingOrder"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9 } : {},
                ]}
              >
                Order
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate('SettingPrinter');
                CommonStore.update((s) => {
                  s.currPage = "SettingPrinter";
                  s.currPageStack = [...currPageStack, "SettingPrinter"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.SETTINGS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.SETTINGS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "SettingPrinter" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "SettingPrinter"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9 } : {},
                ]}
              >
                Printer
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('SettingPayment');
                linkTo && linkTo(`${prefix}/settingpayment`);
                CommonStore.update((s) => {
                  s.currPage = "SettingPayment";
                  s.currPageStack = [...currPageStack, "SettingPayment"];
                });

                if (expandTab !== EXPAND_TAB_TYPE.SETTINGS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.SETTINGS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "SettingPayment" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "SettingPayment"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9 } : {},
                ]}
              >
                Payment
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => {
                // props.navigation.navigate('SettingReservation');

                CommonStore.update((s) => {
                  s.currPage = "SettingsReservation";
                  s.currPageStack = [...currPageStack, "SettingsReservation"];
                  linkTo && linkTo(`${prefix}/settingsreservation`);
                });

                if (expandTab !== EXPAND_TAB_TYPE.SETTINGS) {
                  CommonStore.update((s) => {
                    s.expandTab = EXPAND_TAB_TYPE.SETTINGS;
                  });
                }
              }}
              style={[
                styles.subBar,
                {
                  ...(currPage === "SettingsReservation" && highlightStyle),
                },
              ]}
            >
              <Text
                style={[
                  styles.expandedItems,
                  {
                    color:
                      currPage == "SettingsReservation"
                        ? Colors.primaryColor
                        : Colors.descriptionColor,
                  },
                  switchMerchant ? { fontSize: 9 } : {},
                ]}
              >
                Reservation
              </Text>
            </TouchableOpacity>

          </View>
        ) : null}
      </View>

      <View style={sideBarItemsContainerStyleScale}>
        <TouchableHighlight
          underlayColor={Colors.lightPrimary}
          style={[sideBarItemsStyleScale, {}]}
          onPress={async () => {
            await AsyncStorage.clear();
            // User.setlogin(false);
            // User.getRefreshMainScreen();
            logOutButton();
          }}
        >
          <View
            style={[
              styles.sidebarView,
              switchMerchant
                ? {
                  // borderWidth: 1,
                  // width: '48%'
                }
                : {},
            ]}
          >
            <View style={sidebarIconStyleScale}>
              <Logout
                width={
                  switchMerchant
                    ? Dimensions.get("screen").width * 0.02
                    : Dimensions.get("screen").width * iconSize
                }
                height={
                  switchMerchant
                    ? Dimensions.get("screen").width * 0.02
                    : Dimensions.get("screen").width * iconSize
                }
                color={Colors.descriptionColor}
              />
            </View>
            <View style={styles.sidebarText}>
              <Text style={sidebarTextStyleScale}>Logout</Text>
            </View>
          </View>
        </TouchableHighlight>
      </View>
    </ScrollView>
  );
};

var styles = StyleSheet.create({
  icon: {
    width: 80,
    height: 80,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  scrollView: {
    backgroundColor: Colors.whiteColor,
    // flex: 1,

    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 3,
    // width: Dimensions.get('screen').width * 0.163,
    // width: Dimensions.get('screen').width * 0.15,
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
  },
  menuIcon: {
    width: 20,
    height: 20,
    alignSelf: "center",
    marginBottom: 10,
  },
  sidebarItems: {
    width: Dimensions.get("screen").width * Styles.sideBarWidth,
    // borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: "#6e6e6e",
  },
  //sidebarView include dashboard
  sidebarView: {
    alignItems: "center",
    height: isTablet
      ? Dimensions.get("screen").height * 0.08
      : Dimensions.get("screen").height * 0.16,
    // flexDirection: 'row',
    // paddingLeft: 16,
    // width: Dimensions.get('screen').width * 0.155,
    width: isTablet
      ? Dimensions.get("screen").width * Styles.sideBarWidth
      : "100%",

    justifyContent: "center",
  },
  sidebarIcon: {
    flex: 1,
  },
  sidebarText: {
    // flex: 2,
    marginTop: 3,
  },
  sidebarArrow: {
    flex: 0.5,
    alignItems: "flex-start",

    display: "none",
  },
  sidebarTextStyle: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 15,
    color: Colors.descriptionColor,
  },
  expandedItems: {
    fontFamily: "NunitoSans-SemiBold",
    fontSize: 14,
    color: Colors.descriptionColor,
    marginLeft: Dimensions.get("screen").width * 0.01,

    // width: '60%',
    width: Dimensions.get("screen").width * 0.075,
    // width: '20%',
    //height: Dimensions.get('screen').height * 0.05,
    marginLeft: -Dimensions.get("screen").width * 0.001,
    textAlign: "center",
    // backgroundColor: 'red',
    // justifyContent: 'center',
    // textAlign: 'center',
    alignSelf: "center",
    // paddingVertical: 10,
  },
  subBar: {
    // left: Dimensions.get('screen').width * 0.01,
    // height: 35,
    justifyContent: "center",
    // flexWrap: 'wrap',
    // height: isTablet ? Dimensions.get('screen').height * 0.05 : Dimensions.get('screen').height * 0.1,
    ...(!isTablet && {
      height: Dimensions.get("screen").height * 0.1,
    }),
    alignItems: "center",
    paddingVertical: 10,
  },
});

export default SideBar;
