const primaryColor = '#4E9F7D'
const secondaryColor = '#E9CE8B'
const darkBgColor = '#345F4A'
const highlightColor = '#E5F1EC'
const mainTxtColor = '#27353C'
const descriptionColor = '#7B8E92'
const fieldtTxtColor = '#ACACAC'
const fieldtBgColor = '#F7F7F7'
const fieldtBgColor2 = '#ECECEC'
const whiteColor = '#FFFFFF'
const blackColor = '#000000'
const tabRed = '#ED2024'
const tabGrey = '#7A8E92'
const tabYellow = '#FFD15B'
const fontDark = '#27353C'
const lightPrimary = '#DCECE5'
const lightRed = '#fff4f5'
const modalBgColor = 'rgba(0,0,0,0.5)'
const lightGrey = "#F7F7F7"
const tabMint = "#6FCF97"
const tabGold = "#F2C94C"
const tabCyan = "#56CCF2"


const Colors = {
    primaryColor,
    secondaryColor,
    darkBgColor,
    highlightColor,
    mainTxtColor,
    descriptionColor,
    fieldtTxtColor,
    fieldtBgColor,
    fieldtBgColor2,
    whiteColor,
    blackColor,
    tabRed,
    tabGrey,
    tabYellow,
    fontDark,
    lightPrimary, 
    lightRed,  
    modalBgColor,
    lightGrey,
    tabMint,
    tabGold,
    tabCyan,
}

export default Colors
