import React, {Component, useReducer, useState, useEffect} from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  TextInput,
  Dimensions,
  FlatList,
  Modal,
  PermissionsAndroid,
  ActivityIndicator,
  Picker,
  useWindowDimensions
} from 'react-native';
import Colors from '../constant/Colors';
import SideBar from './SideBar';
// import Switch from 'react-native-switch-pro';
import Switch from 'react-switch';
import * as User from '../util/User';
import Icon from 'react-native-vector-icons/Feather';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import AIcon from 'react-native-vector-icons/AntDesign';
import Fontisto from 'react-native-vector-icons/Fontisto';
import Icon1 from 'react-native-vector-icons/FontAwesome';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Icon2 from 'react-native-vector-icons/EvilIcons';
// import DropDownPicker from 'react-native-dropdown-picker';
// import RNPickerSelect from 'react-native-picker-select';
// import {Picker} from '@react-native-picker/picker';
// import { ceil } from 'react-native-reanimated';
// import CheckBox from '@react-native-community/checkbox';
// import DateTimePicker from 'react-native-modal-datetime-picker';
import moment from 'moment';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Styles from '../constant/Styles';
// import DocumentPicker from 'react-native-document-picker';
import Ionicons from 'react-native-vector-icons/Ionicons';
// import RNFetchBlob from 'rn-fetch-blob';
// import {isTablet} from 'react-native-device-detection';
import 'react-native-get-random-values';
import {customAlphabet} from 'nanoid';
import {ReactComponent as Lock} from '../assets/svg/Lock.svg';
import {v4 as uuidv4} from 'uuid';
import {CommonStore} from '../store/commonStore';
import {MerchantStore} from '../store/merchantStore';
import {
  PURCHASE_ORDER_STATUS,
  PURCHASE_ORDER_STATUS_PARSED,
  EMAIL_REPORT_TYPE,
} from '../constant/common';
import {UserStore} from '../store/userStore';
import {
  convertArrayToCSV,
  uploadImageToFirebaseStorage,
  generateEmailReport,
} from '../util/common';
// import {KeyboardAwareScrollView} from 'react-native-keyboard-aware-scroll-view';
// import {useKeyboard} from '../hooks';
import AntDesign from 'react-native-vector-icons/AntDesign';
// import {
//   Table,
//   TableWrapper,
//   Row,
//   Rows,
//   Col,
//   Cols,
//   Cell,
// } from 'react-native-table-component';
import {Platform} from 'react-native';
// import {GooglePlacesAutocomplete} from 'react-native-google-places-autocomplete';
// import {launchCamera, launchImageLibrary} from 'react-native-image-picker';
// import RadioForm, {RadioButton, RadioButtonInput, RadioButtonLabel} from 'react-native-simple-radio-button';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const nanoid = customAlphabet(alphabet, 12);
// import XLSX from 'xlsx';
// const RNFS = require('react-native-fs');
// import {writeFile, readFile, DocumentDirectoryPath} from 'react-native-fs';
// import AsyncImage from '../components/asyncImage';

const CreateGuests = (props) => {
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const languageChoices = [
    {label: 'English', value:'English'},
    {label: 'Malay', value:'Malay'},
    {label: 'Mandarin', value:'Mandarin'},
  ]

  const dummyData = [
    {
        key: 1,
        name: 'Ah Boy',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
        key: 2,
        name: 'Becky',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
        key: 3,
        name: 'Candice',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
      key: 4,
        name: 'Zebra',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
      key: 5,
        name: 'Giraffe',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
      key: 6,
        name: 'test6',
        contact: '012390981237',
        guestTags: '',
        guestNotes: '',
        reservationPast: 0,
        reservationNext: 0,
        lastVisit: '',
        noShow: 0,

    },
    {
      key: 7,
      name: 'Ah Girl',
      contact: '012390981237',
      guestTags: '',
      guestNotes: '',
      reservationPast: 0,
      reservationNext: 0,
      lastVisit: '',
      noShow: 0,
    },
    {
      key: 8,
      name: 'Girl',
      contact: '012390981237',
      guestTags: '',
      guestNotes: '',
      reservationPast: 0,
      reservationNext: 0,
      lastVisit: '',
      noShow: 0,
    },
    {
      key: 9,
      name: 'Boy',
      contact: '012390981237',
      guestTags: '',
      guestNotes: '',
      reservationPast: 0,
      reservationNext: 0,
      lastVisit: '',
      noShow: 0,
    },
]

const dummyItems = [
	{
		label: 'Gold',
		value: 'Gold'
	},
	{
		label: 'Silver',
		value: 'Silver'
	},
	{
		label: 'Bronze',
		value: 'Bronze'
	}
]

const dummyRadio = [
	{
		label: 'Male',
		value: 'Male'
	},
	{
		label: 'Female',
		value: 'Female'
	},
	{
		label: 'Other',
		value: 'Other' 
	}
]

const renderDummyData = ({item, index}) => {
  return (
      <TouchableOpacity
      onPress={() => {
        props.navigation.navigate('CreateGuests')
      }}>
      <View
        style={{
          backgroundColor: '#ffffff',
          flexDirection: 'row',
          borderBottomWidth: StyleSheet.hairlineWidth,
          borderBottomColor: '#c4c4c4',
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          marginLeft: 5,
          height: windowHeight * 0.1,
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <View style={{
          flex: 1.5, 
          flexDirection: 'row',
          borderRightColor: Colors.lightGrey, 
          borderRightWidth: 1
        }}>
          <Fontisto 
            name='male'
            size={45}
            color={Colors.blackColor}
            style={{
            }}
          />
          <Text
            style={{
                color: Colors.primaryColor, 
                // fontSize: switchMerchant ? 10 : 14, 
                fontFamily: 'NunitoSans-Regular', 
                }}>
            {`${item.name}\n${item.contact}`}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  )
}
const alphabets = [
{alphabet: 'A'}, {alphabet:'B'}, {alphabet:'C'}, {alphabet:'D'}, {alphabet:'E'}, {alphabet:'F'}, 
{alphabet:'G'}, {alphabet:'H'}, {alphabet:'I'}, {alphabet:'J'}, {alphabet:'K'}, {alphabet:'L'},
{alphabet:'M'}, {alphabet:'N'}, {alphabet:'O'}, {alphabet:'P'}, {alphabet:'Q'}, {alphabet:'R'}, 
{alphabet:'S'}, {alphabet:'T'}, {alphabet:'U'}, {alphabet:'V'}, {alphabet:'W'}, {alphabet:'X'},
{alphabet:'Y'}, {alphabet:'Z'}]

const renderHeader = ({item, index}) => {
var alphabet = item.alphabet.toLowerCase()
return(
          <View style={{backgroundColor: Colors.highlightColor}}>
            {/* Header */}
              <View
                  style={{
                      marginTop: 10,
                      marginLeft: 5,
                      backgroundColor: Colors.lightGrey,
                      height: 60,
                      flexDirection: 'row',
                      borderTopLeftRadius: 10,
                      borderTopRightRadius: 10,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      alignItems: 'center',
                      justifyContent: 'center',
                  }}>
                  <View style={{flex: 1.5, justifyContent: 'flex-start'}}>
                    <Text
                        style={{
                        alignSelf: 'flex-start',
                        fontFamily: 'NunitoSans-Bold',
                        marginLeft: 10
                        // fontSize: switchMerchant ? 10 : 14,
                        }}>
                        {`${item.alphabet}`}
                    </Text>
                  </View>
              </View>
              {/* Body FlatList        */}
              <FlatList
                nestedScrollEnabled={true}
                showsVerticalScrollIndicator={false}
                renderItem={renderDummyData}
                data={dummyData.filter((item) => {
                    if (item.name[0].toLowerCase() === alphabet){
                      if (search !== '') 
                      {
                        return item.name
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      } 
                      else 
                      {
                        return true;
                      }
                    }
                })}
                extraData={dummyData.filter((item) => {
                  if (item.name[0].toLowerCase() === alphabet){
                    if (search !== '') 
                    {
                      return item.name
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    } 
                    else 
                    {
                      return true;
                    }
                  }
                })}
                />
        </View>
)
}

  const {navigation} = props;
  const [switchMerchant, setSwitchMerchant] = useState(false);

  const [search, setSearch] = useState('');
	// modal usestate
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
  const [loyaltyStatus, setLoyaltyStatus] = useState(false);
	const [marketingStatus, setMarketingStatus] = useState(false);
	const [gender, setGender] = useState('');
  const [male, setMale] = useState(false);
  const [female, setFemale] = useState(false);
  const [others, setOthers] = useState(false);
  const [language, setLanguage] = useState(null);
  const [showCreateGuest, setShowCreateGuest] = useState(false);
  const [tierModal, setTierModal] = useState(false);
  const [tier, setTier] = useState('');
  const [checkSilverTier, setCheckSilverTier] = useState(false);
  const [checkGoldenTier, setCheckGoldenTier] = useState(false);
    
  return (
      <View
      style={[
        styles.container,
        // !isTablet
        //   ? {
        //       transform: [{scaleX: 1}, {scaleY: 1}],
        //     }
        //   : {},
      ]}>
        <View
          style={[
            styles.sidebar,
            {width: windowWidth * Styles.sideBarWidth}
          ]}>
          <SideBar
            navigation={props.navigation}
            selectedTab={1}
            expandOperation={true}
          />
        </View> 

        <View>
        {/* Search bar and Create guest button */}
          <View 
          style={[
            styles.topBar, {
            height: windowHeight * 0.05,
            width: windowWidth * 0.91,
          }]}
          >
            <View
                style={{
                  // width: switchMerchant ? 450 : 500,
                  // height: switchMerchant ? 35 : 40,
                  backgroundColor: 'white',
                  borderRadius: 5,
                  flexDirection: 'row',
                  flex:1,
                  alignContent: 'center',
                  alignItems: 'center',
                  borderRadius: 10,
                  marginLeft: 10,
                }}>
                <Icon
                name="search"
                size={switchMerchant ? 13 : 18}
                color={Colors.primaryColor}
                style={{marginLeft: 15}}
                />
                <TextInput
                // editable={!loading}
                underlineColorAndroid={Colors.whiteColor}
                style={{
                    // width: switchMerchant ? 180 : 220,
                    // fontSize: switchMerchant ? 10 : 16,
                    fontFamily: 'NunitoSans-Regular',
                    paddingLeft: 5,
                    height: 45,
                }}
                clearButtonMode="while-editing"
                placeholder=" Search"
                placeholderTextColor={Platform.select({
                    ios: '#a9a9a9',
                })}
                onChangeText={(text) => {
                    setSearch(text.trim());
                }}
                value={search}
                />
              </View>
              <View style={{
                flex: 1
                }}>
                <TouchableOpacity
                  style={{
                    justifyContent: 'center',
                    marginLeft: 400,
                    borderWidth: 1,
                    borderColor: Colors.primaryColor,
                    backgroundColor: '#4E9F7D',
                    borderRadius: 5,
                    //width: 160,
                    paddingHorizontal: 10,
                    height: switchMerchant ? 35 : 40,
                    // alignContent: 'flex-end',
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                    zIndex: -1,
                  }}
                  onPress={() => {
                    setShowCreateGuest(true);
                  }}
                  >
                  <Text
                    style={{
                      color: Colors.whiteColor,
                      marginLeft: 5,
                      fontSize: switchMerchant ? 10 : 16,
                      textAlign: 'center',
                      fontFamily: 'NunitoSans-Bold',
                    }}>
                    Create Guest
                  </Text>
                </TouchableOpacity>
            </View>
          </View>

            {/* Left Side */}
            {/* Guest FlatList */}
          <View style={{flexDirection: 'row'}}>
            <View style={{
            }}>
            <FlatList
                style={ [styles.flatList, {width: windowWidth * 0.25}] }
                nestedScrollEnabled={true}
                showsVerticalScrollIndicator={false}
                renderItem={renderHeader}
                data={alphabets.filter((item) => {
                  var alphabet = item.alphabet.toLowerCase()
                  var ret = dummyData.filter((item) => {
                    if(item.name[0].toLowerCase() === alphabet){
                      return true
                    }
                  })
                  if (ret.length > 0){
                    if (search !== '') 
                    {
                      return item.alphabet
                        .toLowerCase()
                        .includes(search[0].toLowerCase());
                    } 
                    else 
                    {
                      return true;
                    }
                  }
                })}
                extraData={alphabets.filter((item) => {
                  var alphabet = item.alphabet.toLowerCase()
                  var ret = dummyData.filter((item) => {
                    if(item.name[0].toLowerCase() === alphabet){
                      return true
                    }
                  })
                  if (ret.length > 0){
                    if (search !== '') 
                    {
                      return item.alphabet
                        .toLowerCase()
                        .includes(search[0].toLowerCase());
                    } 
                    else 
                    {
                      return true;
                    }
                  }
                })}
                keyExtractor={(item, index) => String(index)}
                />
            </View>

            {/* Right Side */}
          {/* Modal for create Guests */}
          <ScrollView
            style={[
              styles.detailsContainer,
              {
                height: windowWidth * 0.52,
                width: windowWidth * 0.63,
              }
            ]}
          >
              <View style={{
                  flexDirection: 'row',
                  backgroundColor: 'white',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <View style={{flex: 0.6}}>
                <Fontisto 
                  name='male'
                  size={90}
                  color={Colors.blackColor}
                  style={{
                    marginBottom: 20
                  }}
                />
                </View>
                <View style={{
                  flex:1.4,
                  borderBottomWidth: 1,
                  height: 70,
                  borderColor: '#D3D3D3',
                  flexDirection: 'row'
                }}>
                  Male
                  <input
                  type='radio' 
                  checked={male}
                  onChange={() => {
                    setGender('Male')
                    setFemale(false)
                    setMale(true)
                    setOthers(false)
                  }}/>
                  Female
                  <input
                  type='radio' 
                  checked={female}
                  onChange={() => {
                    setGender('Female')
                    setFemale(true)
                    setMale(false)
                    setOthers(false)
                  }}
                  />
                  Others
                  <input
                  type='radio' 
                  checked={others}
                  onChange={() => {
                    setGender('Others')
                    setFemale(false)
                    setMale(false)
                    setOthers(true)
                  }}/>
                </View>
				      </View>
      
              <View 
                style={{
                flexDirection: 'column'
                }}
              >
				          <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                      marginLeft: 10
                    }}>
                    First Name
                  </Text>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={{
                      marginLeft: 10,
                    //   width: switchMerchant ? 240 : 370,
                    //   height: switchMerchant ? 35 : 50,
                      borderRadius: 5,
                      borderColor: '#E5E5E5',
                      fontSize: switchMerchant ? 10 : 16,
                    }}
                    placeholderStyle={{padding: 5}}
                    placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                    placeholder="Add First Name"
                    onChangeText={(text) => {
                      setFirstName(text);
                    }}
                    value={firstName}
                  />
				          <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                      marginLeft: 10,
                      marginTop: 10
                    }}>
                    Last Name (optional)
                  </Text>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={{
                      marginLeft: 10,
                      //   width: switchMerchant ? 240 : 370,
                      //   height: switchMerchant ? 35 : 50,
                        borderRadius: 5,
                        borderColor: '#E5E5E5',
                        fontSize: switchMerchant ? 10 : 16,
                    }}
                    placeholderStyle={{padding: 5}}
                    placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                    placeholder="Add Last Name"
                    onChangeText={(text) => {
                      setLastName(text);
                    }}
                    value={lastName}
                  />
                  <View style={{flexDirection: 'column'}}>
                    <Text
                      style={{
                        fontSize: switchMerchant ? 10 : 16,
                        fontFamily: 'NunitoSans-Bold',
                        marginLeft: 10,
                        marginTop: 10
                      }}>
                      Phone
                    </Text>
                    <View style={{flexDirection: 'row'}}>
                      <Text
                      style={{
                        marginLeft: 10,
                        paddingTop: 15
                      }}
                      >
                        +60
                      </Text>
                      <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={{
                          marginLeft: 10,
                        //   width: switchMerchant ? 240 : 370,
                        //   height: switchMerchant ? 35 : 50,
                          borderRadius: 5,
                          borderColor: '#E5E5E5',
                          fontSize: switchMerchant ? 10 : 16,
                        }}
                        placeholderStyle={{padding: 5}}
                        placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                        placeholder="Phone"
                        keyboardType='numeric'
                        onChangeText={(text) => {
                          setPhone(text);
                        }}
                        value={phone}
                      />
                    </View>
                  </View>
                  <Text
                    style={{
                      fontSize: switchMerchant ? 10 : 16,
                      fontFamily: 'NunitoSans-Bold',
                      marginLeft: 10,
                      marginTop: 10
                    }}>
                    Email (optional)
                  </Text>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={{
                      marginLeft: 10,
                      //   width: switchMerchant ? 240 : 370,
                      //   height: switchMerchant ? 35 : 50,
                        borderRadius: 5,
                        borderColor: '#E5E5E5',
                        fontSize: switchMerchant ? 10 : 16,
                    }}
                    placeholderStyle={{padding: 5}}
                    placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                    placeholder="Add Email"
                    onChangeText={(text) => {
                      setEmail(text);
                    }}
                    value={email}
                  />
                  <View style={{flexDirection: 'column'}}>
                    <Text
                      style={[
                      styles.textSize,
                      {
                        width: windowWidth * 0.15,
                        fontFamily: 'NunitoSans-Bold', 
                        marginLeft: 10,
                        fontSize: switchMerchant? 10 : 16,
                      },
                      ]}>
                      Language
                    </Text>
                    <Picker
                      arrowColor={'black'}
                      arrowSize={switchMerchant ? 10 : 20}
                      arrowStyle={{fontWeight: 'bold'}}
                      style={styles.tierDropdown}
                      selectedValue={language}
                      onChangeValue={(item) => {setLanguage(item)}}
                      placeholder={"Select "}
                      containerStyle={{height: 50, width: 170}}
                      dropDownStyle={{height: 100, width: 170}}
                      itemStyle={{
                        justifyContent: 'flex-start',
                        marginLeft: 10,
                        zIndex: 2,
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: switchMerchant ? 10 : 14,
                      }}
                    >
                      {
                        languageChoices.map((value, index) => {
                          return (
                            <Picker.Item
                            key={index}
                            label={value.label}
                            value={value.value}
                            />
                          )
                        })
                      }
                  </Picker>
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <View style={{flexDirection:'column', marginTop: 10}}>
                      <Text
                        style={[
                        styles.textSize,
                        {
                          width: windowWidth * 0.15,
                          fontFamily: 'NunitoSans-Bold', 
                          marginLeft: 10,
                          fontSize: switchMerchant? 10 : 16,
                        },
                        ]}>
                        Add to Loyalty program
                      </Text>
                      <Switch
                        checked={loyaltyStatus}
                        onChange={(statusTemp) =>
                        // setState({ status: status })
                        setLoyaltyStatus(statusTemp)
                        }
                        width={35}
                        height={20}
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      />
                    </View>
                  <View style={{flexDirection:'column', marginTop: 10}}>
                    <Text
                      style={[
                      styles.textSize,
                      {
                        width: windowWidth * 0.15,
                        fontFamily: 'NunitoSans-Bold', 
                        fontSize: switchMerchant? 10 : 16
                      },
                      ]}>
                      Receiving Marketing
                    </Text>
                    <Switch
                      checked={marketingStatus}
                      onChange={(statusTemp) =>
                      // setState({ status: status })
                      setMarketingStatus(statusTemp)
                      }
                      width={35}
                      height={20}
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    />
                  </View>
                </View>
                <View style={{flexDirection: 'column', marginTop: 10}}>
                  <View style={{flex:1}}>
                    <Text
                        style={[
                        styles.textSize,
                        {
                          width: windowWidth * 0.15,
                          fontFamily: 'NunitoSans-Bold', 
                          marginLeft: 10,
                          marginBottom: 5,
                          fontSize: switchMerchant? 10 : 16
                        },
                      ]}>                    
                        Tier
                    </Text>
                  </View>
                  <View style={{flex:1}}>
                    <TouchableOpacity 
                      style={{
                          borderWidth: 1,
                          borderColor: '#D3D3D3',
                          borderRadius: 5, 
                          justifyContent: 'center',
                          marginLeft: 10,
                          height: windowHeight * 0.05,
                          width: windowWidth * 0.2
                      }}
                      onPress={() => {setTierModal(true)
                      }}>
                          <View style={{flexDirection: 'row'}}>
                              <View style={{flex: 1}}>
                                  <Text style={{
                                      fontFamily: 'NunitoSans-Bold',
                                      marginLeft: 10
                                  }}>
                                      {tier}
                                  </Text>
                              </View>
                              <View style={{flex:1}}>
                                  <MaterialIcons
                                  name='keyboard-arrow-down'
                                  size={25}
                                  color='#D3D3D3'
                                  style={{alignSelf: 'flex-end', marginRight: 5}}
                                  />
                              </View>
                          </View>
                      </TouchableOpacity>
                  </View>
                </View>
              </View>
            </ScrollView>
          </View>
        </View>
        {/* Modal Tier */}
        <Modal supportedOrientations={['landscape', 'portrait']}
            style={{ flex: 1,}}
            visible={tierModal}
            transparent={true}>
                <View style={[styles.modalContainer]}>
                    <View
                        style={[
                            styles.modalView, 
                            { 
                              height: windowHeight * 0.4,
                              width: windowWidth * 0.4,
                              padding: windowWidth * 0.02,
                              paddingHorizontal: windowWidth * 0,
                            }
                        ]}>
                            <View style={{flexDirection: 'row', borderBottomWidth: 1, borderColor: '#D3D3D3'}}>
                                <View style={{flex: 0.5}}>
                                    <TouchableOpacity
                                    style={{
                                        alignItems: 'center'
                                    }}
                                    onPress={() => {
                                        setTierModal(false);
                                    }}>
                                        <Text style={{
                                        fontFamily: 'NunitoSans',
                                        textAlign: 'center',
                                        fontSize: switchMerchant ? 16 : 18,
                                        color: 'gray'
                                        }}>
                                            Reset
                                        </Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{flex:1.5, marginBottom: 10}}>
                                    <Text style={{
                                        fontFamily: 'NunitoSans-Bold',
                                        textAlign: 'center',
                                        fontSize: switchMerchant ? 18 : 20,
                                    }}>
                                        Loyalty Tiers
                                    </Text>
                                </View>
                                <View style={{flex:0.5}}>
                                    <TouchableOpacity onPress={() => {setTierModal(false)}}>
                                        <AIcon
                                        name="closecircle"
                                        size={switchMerchant ? 15 : 25}
                                        color={Colors.fieldtTxtColor}
                                        style={{alignSelf: 'flex-end', marginRight: 20}}
                                        />
                                    </TouchableOpacity>
                                </View>
                            </View>
                                <View style={{
                                    flex:1, 
                                    marginTop: 10, 
                                    borderBottomWidth: 1, 
                                    borderColor: '#D3D3D3',
                                    justifyContent: 'center'
                                }}>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{flex:1}}>
                                            <Text style={{marginLeft: 10}}>
                                                Silver Member
                                            </Text>
                                        </View>
                                        <View style={{flex:0.8}}>
                                            <Lock
                                            size={20}
                                            style={{alignSelf: 'flex-end'}}
                                            />
                                        </View>
                                        <View style={{flex:0.2}}>
                                            {/* <CheckBox
                                                width={42}
                                                style={{
                                                    marginRight: 10,
                                                    alignSelf: 'flex-end',
                                                    borderRadius: 15,
                                                }}
                                                value={checkSilverTier}
                                                onValueChange={() =>
                                                // setState({ status: status })
                                                {
                                                setTier('Silver Member')
                                                setCheckGoldenTier(false)
                                                setCheckSilverTier(true) 
                                                }
                                                }
                                            /> */}
                                            <input
                                            onChange={() => {
                                              setTier('Silver Member')
                                              setCheckGoldenTier(false)
                                              setCheckSilverTier(true) 
                                            }}
                                            style={{
                                              alignSelf: 'center',
                                              borderRadius: 15,
                                              paddingBottom: 3
                                            }}
                                            type={'checkbox'}
                                            checked={checkSilverTier}
                                            />
                                        </View>
                                    </View>
                                </View>
                                <View style={{
                                    flex:1, 
                                    marginTop: 10, 
                                    borderBottomWidth: 1, 
                                    borderColor: '#D3D3D3',
                                    justifyContent: 'center'
                                }}>
                                    <View style={{flexDirection: 'row'}}>
                                        <View style={{flex:1}}>
                                            <Text style={{marginLeft: 10}}>
                                                Golden Member
                                            </Text>
                                        </View>
                                        <View style={{flex:1}}>
                                          <input
                                            onChange={() => {
                                              setTier('Golden Member')
                                              setCheckGoldenTier(true)
                                              setCheckSilverTier(false) 
                                            }}
                                            style={{
                                              alignSelf: 'flex-end',
                                              borderRadius: 15,
                                              paddingBottom: 3,
                                              marginRight: 25
                                            }}
                                            type={'checkbox'}
                                            checked={checkGoldenTier}
                                            />
                                        </View>
                                    </View>
                                </View>
                                <View style={{flex: 1}}></View>
                                <View style={{flex: 1}}></View>
                                <View style={{flex: 1}}></View>
                                <View style={{flex: 1}}></View>

                    </View>
                </View>
            </Modal>
      </View>
    )

}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Colors.highlightColor,
      flexDirection: 'row',
    },
    headerLogo: {
      width: 112,
      height: 25,
      marginLeft: 10,
    },
    sidebar: {
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 8,
      },
      shadowOpacity: 0.44,
      shadowRadius: 10.32,
  
      elevation: 16,
    },
    circleIcon: {
      width: 30,
      height: 30,
      // resizeMode: 'contain',
      marginRight: 10,
      alignSelf: 'center',
    },
    confirmBox: {
      borderRadius: 12,
      backgroundColor: Colors.whiteColor,
      justifyContent: 'space-between',
    },
    flatList: {
      height: 100,
      marginLeft: 10,
      // paddingVertical: 20,
      // paddingHorizontal: 15,
      //marginTop: 10,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      backgroundColor: 'white',
      shadowColor: '#000',
      shadowOffset: {
      width: 0,   
      height: 2,
      },
      shadowOpacity: 0.22,
      shadowRadius: 3.22,
      elevation: 3,
  },
    textInput8: {
      fontFamily: 'NunitoSans-Regular',
      width: 75,
      height: 50,
      flex: 1,
      backgroundColor: Colors.fieldtBgColor,
      borderRadius: 5,
    },
  
    textInput9: {
      fontFamily: 'NunitoSans-Regular',
      width: 350,
      height: 50,
      //flex: 1,
      backgroundColor: Colors.fieldtBgColor,
      borderRadius: 5,
    },
    topBar: {
      flexDirection: 'row',
      backgroundColor: Colors.lightGrey,
      justifyContent: 'flex-start',
    },
    headerLeftStyle: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    detailsContainer: {
      margin: 10,
      // flexDirection: 'row',
      // paddingVertical: 20,
      // paddingHorizontal: 15,
      //marginTop: 10,
      backgroundColor: 'white',
      shadowColor: '#000',
      shadowOffset: {
      width: 0,   
      height: 2,
      },
      shadowOpacity: 0.22,
      shadowRadius: 3.22,
      elevation: 3,
    },
    modalContainer: {
        flex: 1,
        backgroundColor: Colors.modalBgColor,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalView: {
        backgroundColor: Colors.whiteColor,
        //borderRadius: windowWidth * 0.03,
        borderRadius: 12,
    },
    closeButton: {
        position: 'absolute',

        elevation: 1000,    
        zIndex: 1000,
    },
    modalTitle: {
      alignItems: 'center',
      top: '20%',
      position: 'absolute',
    },
    modalBody: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalTitleText: {
      fontFamily: 'NunitoSans-Bold',
      textAlign: 'center',
      fontSize: 24,
    },
    modalDescText: {
      fontFamily: 'NunitoSans-SemiBold',
      fontSize: 18,
      color: Colors.fieldtTxtColor,
    },
    modalBodyText: {
      flex: 1,
      fontFamily: 'NunitoSans-SemiBold',
      fontSize: 25,
      width: '20%',
    },
    modalSaveButton: {
      width: 130,
      backgroundColor: Colors.fieldtBgColor,
      height: 40,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
  
      shadowOffset: {
        width: 0,
        height: 1,
      },
      shadowOpacity: 0.22,
      shadowRadius: 3.22,
      elevation: 1,
  
      marginVertical: 10,
    },
    ManageFilterBox: {
      //width: windowWidth * 0.27,
      //height: Platform.OS === 'ios' ?windowHeight * 0.23: windowHeight * 0.24,
      //width: Platform.OS === 'ios' ? windowWidth * 0.4 : windowWidth * 0.33,
      borderWidth: 1,
      borderColor: '#E5E5E5',
      position: 'absolute',
      marginTop: Platform.OS === 'ios' ? '13%' : '13%',
      marginLeft: Platform.OS === 'ios' ? '12%' : '12%',
      //left: Platform.OS === 'ios' ? '38%' : '0%',
      //top: Platform.OS === 'ios' ? '46%' : '0%',
      shadowColor: '#000',
      shadowOffset: {
        width: 1,
        height: 5,
      },
      shadowOpacity: 0.32,
      shadowRadius: 3.22,
      elevation: 10,
      zIndex: 1000,
      borderRadius: 10,
      //borderTopLeftRadius: 0,
      backgroundColor: Colors.whiteColor,
      //justifyContent: 'space-between'
    },
	tierDropdown: {
		flex: 1,
		borderRadius: 10,
	  },	
  });
  
export default CreateGuests;