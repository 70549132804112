import React, { Component, useReducer, useState, useEffect } from 'react';
import {
  StyleSheet,
  ScrollView,
  Image,
  View,
  Text,
  Alert,
  TouchableOpacity,
  Dimensions,
  Modal,
  Platform,
  useWindowDimensions,
  Picker
} from 'react-native';
import Colors from '../constant/Colors';
import SideBar from './SideBar';
import Icon from 'react-native-vector-icons/Feather';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Ionicon from 'react-native-vector-icons/Ionicons';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
import { TextInput, FlatList } from 'react-native-gesture-handler';
// import DropDownPicker from 'react-native-dropdown-picker';
// import DateTimePickerModal from 'react-native-modal-datetime-picker';
import API from '../constant/API';
import ApiClient from '../util/ApiClient';
// import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
// import DialogInput from 'react-native-dialog-input';
import * as User from '../util/User';
import LoginScreen from './LoginScreen';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import CheckBox from 'react-native-check-box';
import { color } from 'react-native-reanimated';
import Close from 'react-native-vector-icons/AntDesign';
// import NumericInput from 'react-native-numeric-input';
import Styles from '../constant/Styles';
import moment, { isDate } from 'moment';
// import Barcode from "react-native-barcode-builder";
// import Switch from 'react-native-switch-pro';
import Ionicons from 'react-native-vector-icons/Ionicons';
// import { isTablet } from 'react-native-device-detection';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
// import RNPickerSelect from 'react-native-picker-select';
// import { useKeyboard } from '../hooks';
import { CommonStore } from '../store/commonStore';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useFilePicker } from "use-file-picker";
import AsyncImage from '../components/asyncImage';
import { ReactComponent as Edit } from '../assets/svg/Edit.svg';

const SettingScreen = props => {
  const {
    navigation,
  } = props;

  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  // const [keyboardHeight] = useKeyboard();

  const [selfCollect, setSelfCollect] = useState(true);
  const [openHourPickerVisible, setOpenHourPickerVisible] = useState(false);
  const [closeHourPickerVisible, setCloseHourPickerVisible] = useState(false);
  const [openHour, setOpenHour] = useState("");
  const [closeHour, setCloseHour] = useState("");
  const [isChecked2, setIsChecked2] = useState(false);
  const [isChecked3, setIsChecked3] = useState(false);
  const [isChecked4, setIsChecked4] = useState(false);
  const [isChecked5, setIsChecked5] = useState(false);
  const [isChecked6, setIsChecked6] = useState(false);
  const [isChecked7, setIsChecked7] = useState(false);
  const [isChecked8, setIsChecked8] = useState(false);
  const [isChecked9, setIsChecked9] = useState(false);
  const [isChecked10, setIsChecked10] = useState(false);
  const [isChecked11, setIsChecked11] = useState(false);
  const [isChecked12, setIsChecked12] = useState(false);
  const [isChecked13, setIsChecked13] = useState(false);
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [amount, setAmount] = useState('');
  const [hourStart, setHourStart] = useState('');
  const [hourEnd, setHourEnd] = useState('');
  const [days, setDays] = useState(false);
  const [days1, setDays1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDistance, setShowDistance] = useState('');
  const [expiryPeriod, setExpiryPeriod] = useState('');
  const [extentionCharges, setExtentionCharges] = useState('');
  const [extentionDuration, setExtentionDuration] = useState('');
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const [pickerMode, setPickerMode] = useState('datetime');
  const [switchMerchant, setSwitchMerchant] = useState(false);
  const [merchantDisplay, setMerchantDisplay] = useState(false);
  const [shift, setShift] = useState(false);
  const [tax, setTax] = useState(false);
  const [sample, setSample] = useState(true);
  const [redemption, setRedemption] = useState(false);
  const [redemptionList, setRedemptionList] = useState(true);
  const [redemptionAdd, setRedemptionAdd] = useState(false);
  const [order, setOrder] = useState(false);
  const [previousState, setPreviousState] = useState(false);
  const [receipt, setReceipt] = useState([]);
  const [detail, setDetail] = useState([]);
  const [merchantInfo, setMerchantInfo] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [outletInfo, setOutletInfo] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [merInfo, setMerInfo] = useState([]);
  const [merchantId, setMerchantId] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [showModal4, setShowModal4] = useState(false);
  const [showModal5, setShowModal5] = useState(false);
  const [closingAmount, setClosingAmount] = useState('');
  const [options, setOptions] = useState([]);
  const [shift1, setShift1] = useState([]);
  const [status, setStatus] = useState(false);
  const [logo, setLogo] = useState('');
  const [cover, setCover] = useState('');
  const [name, setName] = useState('');
  const [tname, setTname] = useState('');
  const [rate, setRate] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [payment, setPayment] = useState('');
  const [time, setTime] = useState('');
  const [statue, setStatue] = useState('');
  const [status1, setStatus1] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [outletId, setOutletId] = useState(null);
  const [myTextInput, setMyTextInput] = useState(React.createRef());
  const [start_time, setStart_time] = useState(false);
  const [end_time, setEnd_time] = useState(false);
  const [rev_time, setRev_time] = useState('');
  const [category, setCategory] = useState('');
  const [close, setClose] = useState('Closed');
  const [showNote, setShowNote] = useState(false);
  const [expandView, setExpandView] = useState(false);
  const [value, setValue] = useState('');
  const [extendOption, setExtendOption] = useState([
    { optionId: 1, price: 20, day: 7, days: false },
  ]);
  const [redemptionInfo, setRedemptionInfo] = useState([]);
  const [alloutlet, setAlloutlet] = useState([]);
  const [discount, setDiscount] = useState('');
  const [amount1, setAmount1] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [categoryOutlet, setCategoryOutlet] = useState([]);
  const [extend, setExtend] = useState([]);
  const [outletss, setOutletss] = useState([]);
  const [redemptionDetail, setRedemptionDetail] = useState([]);
  const [outletInfo1, setOutletInfo1] = useState([]);
  const [category1, setCategory1] = useState([]);
  // const [merchantName, setMerchantName] = useState('');
  const [addOutletName, setAddOutletName] = useState('');
  const [addOutletWindow, setAddOutletWindow] = useState(false);
  const [taxList, setTaxList] = useState([]);
  const [note1, setNote1] = useState("");
  const [note2, setNote2] = useState("");
  const [note3, setNote3] = useState("");
  const [openings, setOpenings] = useState([]);
  const [editOpeningIndex, setEditOpeningIndex] = useState(0);
  const [addressSecond, setAddressSecond] = useState("");
  const [merchantLogoImage,setMerchantLogoImage] = useState('');
  const [merchantLogoImageType, setMerchantLogoImageType] = useState('');
  const [isMerchantLogoImageChanged,setIsMerchantLogoImageChanged] = useState(false);

  ////////////////////////////////////////////////////////////////

  const [targetOutletDropdownList, setTargetOutletDropdownList] = useState([]);
  const [selectedTargetOutletId, setSelectedTargetOutletId] = useState('');

  const allOutlets = MerchantStore.useState(s => s.allOutlets);

  const userName = UserStore.useState(s => s.name);
  const merchantName = MerchantStore.useState(s => s.name);

  const merchantLogo = MerchantStore.useState(s => s.logo);
  const outletSelectDropdownView = CommonStore.useState(s => s.outletSelectDropdownView);

  useEffect(() => {
    setLogo(merchantLogo);
  }, [merchantLogo]);

  useEffect(() => {
    setName(merchantName);
  }, [merchantName]);

  useEffect(() => {
    const selectedTargetOutlet = allOutlets.find(outlet => outlet.uniqueId === selectedTargetOutletId);

    if (selectedTargetOutlet) {
      setPhone(selectedTargetOutlet.phone);
      setAddress(selectedTargetOutlet.address);
    }
  }, [selectedTargetOutletId]);

  useEffect(() => {
    setTargetOutletDropdownList(allOutlets.map(outlet => ({ label: outlet.name, value: outlet.uniqueId })));

    if (selectedTargetOutletId === '' && allOutlets.length > 0) {
      setSelectedTargetOutletId(allOutlets[0].uniqueId);
    }
  }, [allOutlets]);

  const setState = () => { };

  // navigation.dangerouslyGetParent().setOptions({
  //   tabBarVisible: false,
  // });

  navigation.setOptions({
    headerLeft: () => (
      <View style={{
        width: windowWidth * 0.17,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Image
          style={{
            width: 124,
            height: 26,
          }}
          resizeMode="contain"
          source={require('../assets/image/logo.png')}
        />
      </View>
    ),
    headerTitle: () => (
      <View style={[{
        justifyContent: 'center',
        alignItems: 'center',
        // bottom: -2,
        bottom: switchMerchant? '2%': 0,
      }, windowWidth >= 768 && switchMerchant ? { right: windowWidth * 0.1 } : {}, windowWidth <= 768?{right: windowWidth * 0.12}:{}]}>
        <Text
          style={{
            fontSize: switchMerchant ? 20 : 24,
            // lineHeight: 25,
            textAlign: 'center',
            fontFamily: 'NunitoSans-Bold',
            color: Colors.whiteColor,
            opacity: 1,
          }}>
          Receipt Settings
        </Text>
      </View>
    ),
    headerRight: () => (
      <View style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        {outletSelectDropdownView()}
        <View style={{
          backgroundColor: 'white',
          width: 0.5,
          height: windowHeight * 0.025,
          opacity: 0.8,
          marginHorizontal: 15,
          bottom: -1,
        }}>
        </View>
        <TouchableOpacity onPress={() => navigation.navigate('Setting')}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <Text
            style={{
              fontFamily: 'NunitoSans-SemiBold',
              fontSize: 16,
              color: Colors.secondaryColor,
              marginRight: 15,
            }}>
            {userName}
          </Text>
          <View style={{
            marginRight: 30,
            width: windowHeight * 0.05,
            height: windowHeight * 0.05,
            borderRadius: windowHeight * 0.05 * 0.5,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
          }}>
            <Image style={{
              width: windowHeight * 0.035,
              height: windowHeight * 0.035,
              alignSelf: 'center',
            }} source={require('../assets/image/profile-pic.jpg')} />
          </View>
        </TouchableOpacity>
      </View>
    ),
  });

  // componentDidMount = () => {

  //   ApiClient.GET(API.getOutletByMerchant + User.getMerchantId()).then((result) => {
  //     setState({ outletInfo: result });
  //     result.map((element) => {
  //       setState({
  //         outletId: element.id,
  //         outletName: element.name,
  //         merchantName: element.merchant.name
  //       });
  //     });
  //   });

  //   ApiClient.GET(API.getAllSettingRedemption + User.getMerchantId()).then((result) => {
  //     setState({ redemptionInfo: result });
  //   });

  //   outlet()
  //   ApiClient.GET(API.getOutletCategory + User.getOutletId()).then((result) => {
  //     if (result !== undefined) {
  //       setState({ categoryOutlet: result });
  //     }

  //   });
  // }

  const outletById = (param) => {
    //<= outletId is not used in the param
    ApiClient.GET(API.outlet2 + param).then((result) => {
      //<= all you need to do is add parameter here
      console.log("RESULT OUTLET", result)
      setState({ outlet: result, openings: result.openings });
      console.log("openings", openings)
      setState({ status: result.status });
      setState({ merInfo: result.merchant[0] });
      setState({ cover: result.cover });
      setState({ logo: result.merchant[0].logo });
      setState({ openings: result.openings });

      myTextInput.current.clear();
    });
  }

  // jian
  // function ChangeTextInReceipt() {
  //   submitData.current = true
  //   add(function (prevData) {
  //     return [
  //       {
  //         id: v4(),
  //         note,
  //         date,
  //         time,
  //       },
  //       ...prevData,
  //     ];
  //   });
  // }

  const editOutlet = (param) => {
    // var body = {
    //   outletId: param,
    //   coverUrl: cover,
    //   logoUrl: logo,
    //   address: address,
    //   name: name,
    //   latlng: '',
    //   phone: phone,
    //   taxId: '',
    //   status: '1',
    //   isBusy: '1',
    //   reservationStatus: true,
    //   openings: [
    //     {
    //       week: 'Monday',
    //       startTime: s_time,
    //       endTime: e_time,
    //     },
    //     {
    //       week: 'Tuesday',
    //       startTime: s_time1,
    //       endTime: e_time1,
    //     },
    //     {
    //       week: 'Wednesday',
    //       startTime: s_time2,
    //       endTime: e_time2,
    //     },
    //     {
    //       week: 'Thursday',
    //       startTime: s_time3,
    //       endTime: e_time3,
    //     },
    //     {
    //       week: 'Friday',
    //       startTime: s_time4,
    //       endTime: e_time4,
    //     },
    //     {
    //       week: 'Saturday',
    //       startTime: s_time5,
    //       endTime: e_time5,
    //     },
    //     {
    //       week: 'Sunday',
    //       startTime: s_time6,
    //       endTime: e_time6,
    //     },
    //   ],
    //   payments: [
    //     {
    //       name: payment,
    //     },
    //   ],
    // };

    Alert.alert(
      'Success',
      'Updated successfully',
      [
        {
          text: 'OK',
          onPress: () => { },
        },
      ],
      { cancelable: false },
    );

    // ApiClient.PATCH(API.editOutlet, body, false).then((result) => {
    //   if (result.id != null) {
    //     Alert.alert(
    //       'Congratulation!',
    //       'You Have Successfully Inserted',
    //       [
    //         {
    //           text: 'OK',
    //           onPress: () => { },
    //         },
    //       ],
    //       { cancelable: false },
    //     );
    //   }
    // });

    outletById(param);
    myTextInput.current.clear();
  }

  const [openFileSelector, { plainFiles, filesContent, loading: loadingImageInput, clear: clearImageContainer, errors }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
  });

  useEffect(() => {
    console.log(plainFiles, filesContent, loadingImageInput)

    // display the image when it is finish loaded
    if (plainFiles.length && filesContent.length && !loadingImageInput) {
      // image in base64 filecontent
      setMerchantLogoImage(filesContent[0].content);
      setMerchantLogoImageType(filesContent[0].name.slice(filesContent[0].name.lastIndexOf('.')));
      setIsMerchantLogoImageChanged(true);

    }

    if(errors.length)
      console.error(errors)

  }, [plainFiles, filesContent, loadingImageInput, errors])
  

  // const handleChoosePhoto = () => {
  //   const options = {
  //     mediaType: 'photo',
  //     maxWidth: 2000,
  //     maxHeight: 2000,
  //     includeBase64: false,
  //   }
  //   launchImageLibrary(options, (response) => {
  //     if (response.didCancel) {
  //     } else if (response.error) {
  //       Alert.alert(response.error.toString());
  //     } else {
  //       setMerchantLogoImage(response.uri);
  //       setMerchantLogoImageType(response.uri.slice(response.uri.lastIndexOf('.')));
  //     }
  //   });
  // }

  const _logout = async () => {
    await AsyncStorage.clear();
    User.setlogin(false);
    User.getRefreshMainScreen();
  };

  const addSection = () => {
    // setState({ showNote: true });
    setShowNote(true);
  }

  // function end

  const week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

  return (
    // <View style={styles.container}>
    //   <View style={styles.sidebar}>
    <View
      style={[styles.container, { height: windowHeight, width: windowWidth }]}
    >
      {/* Sidebar view */}
      <View
        style={{
          // borderWidth: 1,
          // width: Dimensions.get("screen").width * 0.9,
          // height: Dimensions.get("screen").height * 0.9,
          flex: 0.8,
        }}
      >
        <SideBar navigation={navigation} selectedTab={8} />
      </View>

      <View style={{ height: windowHeight, flex: 9 }}>
        <ScrollView 
        showsVerticalScrollIndicator={false}
        // scrollEnabled={switchMerchant}
        style={{}}
        contentContainerStyle={{
          flex: 1,
          paddingBottom: switchMerchant ? windowHeight * 0.001 : windowHeight * 0.03,
          backgroundColor: Colors.highlightColor,
        }}
        >

      <View style={[styles.content, {width: windowWidth * (1 - Styles.sideBarWidth),}]}>
        {/* <View style={{flexDirection: 'row', marginBottom: 10}}>
            <View style={{flexDirection: 'row', flex: 1}}></View>
            <TextInput
              editable={!loading}
              underlineColorAndroid={Colors.whiteColor}
              clearButtonMode="while-editing"
              style={styles.textInput}
              placeholder="search"
              onChangeText={(text) => {
                setState({search: text.trim()});
              }}
              value={email}
            />
          </View> */}
        {/* <View
            style={{
              flexDirection: 'row',
              backgroundColor: Colors.highlightColor,
              padding: 12,
            }}>
            <TouchableOpacity
              onPress={() => {
                setState({
                  merchantDisplay: true,
                  shift: false,
                  tax: false,
                  sample: false,
                  redemption: false,
                  order: false,
                });
              }}>
              <Text>General</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  shift: true,
                  merchantDisplay: false,
                  tax: false,
                  sample: false,
                  redemption: false,
                  order: false,
                });
                getCurrentShift(User.getOutletId());
              }}>
              <Text style={{ marginLeft: 30 }}>Shift</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  tax: true,
                  merchantDisplay: false,
                  shift: false,
                  sample: false,
                  redemption: false,
                  order: false,
                });
                getTax(User.getOutletId())
              }}>
              <Text style={{ marginLeft: 30 }}>Tax</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  sample: true,
                  tax: false,
                  merchantDisplay: false,
                  shift: false,
                  redemption: false,
                  order: false,
                });
                setState({
                  showNote: false,
                });
              }}>
              <Text style={{ marginLeft: 30 }}>Sample Receipt</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  merchantDisplay: false,
                  shift: false,
                  tax: false,
                  sample: false,
                  redemption: false,
                  order: true,
                });
              }}>
              <Text style={{ marginLeft: 30 }}>Order</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setState({
                  merchantDisplay: false,
                  shift: false,
                  tax: false,
                  sample: false,
                  redemption: true,
                  order: false,
                  redemptionList: true,
                });
              }}>
              <Text style={{ marginLeft: 30 }}>Redemption</Text>
            </TouchableOpacity>
          </View> */}
        <View
          style={{
            backgroundColor: Colors.whiteColor,
            // height: Dimensions.get('window').height - 120,
            height: '100%',

            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.22,
            shadowRadius: 3.22,
            // elevation: 1,
            elevation: 3,
            borderRadius: 5,
            // borderRadius: 8,
          }}>
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{
              //top: Platform.OS === 'ios' ? -keyboardHeight * 0.3 : 0,
            }}>
            <View style={{ flexDirection: 'row', padding: 30 }}>
              <View
                style={{
                  height: '100%',
                  flex: 2,
                }}>
                {/* left */}
                <View style={{ flexDirection: 'row', flex: 1, }}>
                  <View style={{ paddingLeft: switchMerchant ? 0 : 38 }}>
                    <Text style={{ marginTop: 10, fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14 }}>Choose Outlet</Text>
                  </View>
                  <View style={{ marginLeft: Platform.OS == 'ios' ? 10 : 15 }}>
                    <View style={{ width: switchMerchant ? 150 : 180, height: 50 }}>
                      <Picker
                        // items={outletInfo.map((item) => ({
                        //   label: item.name,
                        //   value: item.id,
                        // }))}
                        //defaultValue={outletId}
                        labelStyle={{fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14}}
                        containerStyle={{ height: switchMerchant ? 39 : 40 }}
                        // placeholder="Choose outlet"
                        defaultValue={selectedTargetOutletId}
                        placeholderStyle={{ color: 'black' }}
                        style={{ backgroundColor: Colors.whiteColor, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}
                        itemStyle={{
                          justifyContent: 'flex-start',
                        }}
                        dropDownStyle={{ backgroundColor: Colors.whiteColor }}
                        onChangeItem={(item) =>
                          // setState({
                          //   outletId: item.value,
                          // })
                          // setOutletId(item.value)
                          setSelectedTargetOutletId(item.value)
                        }
                      // onClose={() => {
                      //   outletById(outletId);
                      // }}
                      >
                        {targetOutletDropdownList.map((value, index) => {
                          return (
                            <Picker.Item
                            key={index}
                            value={value.value}
                            label={value.label}
                            />
                          )
                        })}
                      </Picker>
                    </View>
                  </View>
                </View>
                <View style={{ flex: 8, zIndex: -1, }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      marginBottom: 10,
                      alignSelf: 'center',
                      marginTop: 0,
                    }}>
                    <Text style={{fontFamily: 'NunitoSans-Bold', fontSize: switchMerchant ? 10 : 14}}>Merchant Logo</Text>
                  </View>
                  <View style={{ width: 150, height: 150, alignSelf: 'center', marginBottom: 20 }}>
                    <Image
                      source={{ uri: merchantLogoImage }}
                      style={{
                        width: 150,
                        height: 150,
                        backgroundColor: Colors.fieldtBgColor,
                        alignSelf: 'center'
                      }}>                        
                    </Image>
                    <TouchableOpacity
                      style={{ height: 30, width: 30, marginTop: 10, position: 'absolute', bottom: 0, 
                      right: switchMerchant ? (Platform.OS === 'ios'?-15:windowWidth * -0.01) : (Platform.OS === 'ios'?-15:windowWidth * -0.03),
                      top: switchMerchant ? (Platform.OS === 'ios'?-15:windowHeight * 0.3) : (Platform.OS === 'ios'?-15:windowWidth * 0.085)}}
                      onPress={() => {
                        // handleChoosePhoto();
                        openFileSelector();
                        console.log("PRessed!")
                    }}>
                      <Edit size={switchMerchant ? 15 : 23} color={Colors.primaryColor} /> 
                    </TouchableOpacity>
                  </View>
                  {/* <TouchableOpacity
                    style={{ height: 30, width: 30, marginTop: 10, }}
                    onPress={() => {
                      handleChoosePhoto();
                    }}>
                     <FontAwesome5 name='edit' size={23} color={Colors.primaryColor} /> 
                     <Text style={{ fontSize: 13, color: Colors.primaryColor }}>
                      Change
                    </Text> 
                  </TouchableOpacity> */}
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={[styles.textInput3, {fontSize: switchMerchant ? 10 : 16}]}
                    placeholder={merInfo == null ? 'Merchant Name' : merInfo.name}
                    placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14}}
                    placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                    onChangeText={(text) => {
                      // setState({ name: text });
                      setName(text);
                    }}
                    value={name}
                    ref={myTextInput}
                  />
                  <View style={{ flexDirection: 'row', width: '100%', alignSelf: 'center' }}>
                    {/* <View style={{ width: '28%', justifyContent: 'center', }}>
                      <Text style={{ fontSize: switchMerchant ? 10 : 13, color: 'black', paddingLeft: 1, }}>Phone No.</Text>
                    </View> */}
                    <View style={{ width: '100%' }}>
                      <TextInput
                        underlineColorAndroid={Colors.fieldtBgColor}
                        style={[styles.textInput3, {fontSize: switchMerchant ? 10 : 14}]}
                        placeholder={outlet == null ? 'e.g. +6016123456' : 'e.g. +6012345679'}
                        placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                        keyboardType={Platform.OS === 'android' ? "numeric" : "number-pad"}
                        onChangeText={(text) => {
                          // setState({ phone: text });
                          setPhone(text);

                        }}
                        value={phone}
                        ref={myTextInput}
                      />
                    </View>
                  </View>
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={[styles.textInput3, {fontSize: switchMerchant ? 10 : 14}]}
                    placeholder={outlet.address == null ? 'Merchant Address' : outlet.address}
                    placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14}}
                    placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                    onChangeText={(text) => {
                      // setState({ address: text });
                      setAddress(text);
                    }}
                    value={address}
                    ref={myTextInput}
                  />
                  <TextInput
                    underlineColorAndroid={Colors.fieldtBgColor}
                    style={[styles.textInput3, {fontSize: switchMerchant ? 10 : 14}]}
                    placeholder='Merchant Address'
                    placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14}}
                    placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                    onChangeText={(text) => {
                      // setState({ name: text });
                      setAddressSecond(text);
                    }}
                    value={addressSecond}
                  />
                  
                  <View style={{ marginTop: 10, width: '88%', alignSelf: 'center', }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        marginBottom: 0,
                        alignItems: 'center',
                      }}>
                        <Text style={{ paddingLeft: 10, fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14 }}>Customizable Notes (Optional)</Text>
                        {/* <Text>Change the text at receipt bottom</Text> */}
                    </View>
                  </View>
                  <View style={{ marginTop: 0 }}>
                    {/* Add Customizable Notes use to edit the "Thank you for your order" inside the Receipt (ui + functionality) */}
                    <View style={styles.viewContainer}>
                      <View style={{ flex: 9 }}>
                        <TextInput
                          underlineColorAndroid={Colors.fieldtBgColor}
                          style={[styles.textInput3, {fontSize: switchMerchant ? 10 : 14}]}
                          placeholder="Write the text to show at receipt"
                          placeholderStyle={{fontFamily: 'NunitoSans-Regular', fontSize: switchMerchant ? 10 : 14}}
                          placeholderTextColor={Platform.select({ios: '#a9a9a9'})}
                          onChangeText={(text) => {
                            // setState({ note1: text });
                            setNote1(text);
                          }}
                          value={note1}
                        />
                      </View>
                    </View>
                    {/* <View style={styles.viewContainer}>
                      <View style={{ flex: 1 }}>
                        <Text style={styles.textSize}>2.</Text>
                      </View>
                      <View style={{ flex: 9 }}>
                        <TextInput
                          underlineColorAndroid={Colors.fieldtBgColor}
                          style={styles.textInput6}
                          placeholder="Write your note"
                          onChangeText={(text) => {
                            // setState({ note2: text });
                            setNote2(text);
                          }}
                          value={note2}

                        />
                      </View>
                    </View>
                    {showNote ? (
                      <View>
                        <View style={styles.viewContainer}>
                          <View style={{ flex: 1 }}>
                            <Text style={styles.textSize}>3.</Text>
                          </View>
                          <View style={{ flex: 9 }}>
                            <TextInput
                              underlineColorAndroid={Colors.fieldtBgColor}
                              style={styles.textInput6}
                              placeholder="Write your note"
                              onChangeText={(text) => {
                                // setState({ note3: text });
                                setNote3(text);
                              }}
                              value={note3}

                            />
                          </View>
                        </View>
                      </View>
                    ) : null} */}
                  </View>
                  <View style={{ alignItems: 'center' }}>
                  <TouchableOpacity
                    style={{
                      justifyContent: 'center',
                      flexDirection: 'row',
                      borderWidth: 1,
                      borderColor: Colors.primaryColor,
                      backgroundColor: '#4E9F7D',
                      borderRadius: 5,
                      width: 120,
                      paddingHorizontal: 10,
                      height: switchMerchant ? 35 : 40,
                      alignItems: 'center',
                      shadowOffset: {
                          width: 0,
                          height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 1,
                      zIndex: -1,
                    }}
                    disabled={loading}
                    onPress={() => {
                      // editOutlet(outlet.id);
                      // setNote1(text);
                      setNote2(note1);
                    }}>
                      <Text style={{
                          color: Colors.whiteColor,
                          //marginLeft: 5,
                          fontSize: switchMerchant ? 10 : 16,
                          fontFamily: 'NunitoSans-Bold',
                      }}>
                        {loading ? 'LOADING...' : 'SAVE'}
                      </Text>
                  </TouchableOpacity>
                  </View>
                </View>
              </View>
              {/* right */}
              <View
                style={{
                  flex: 3,
                  borderLeftWidth: StyleSheet.hairlineWidth,
                }}>
                <View
                  style={{
                    width: '100%',
                    height: '100%',
                    marginTop: 0,
                    marginBottom: 0,
                    marginLeft: '2%',
                  }}>
                  <View style={{ alignSelf: 'center', marginBottom: 20 }}>
                    <View>
                      <AsyncImage
                        source={{ uri: logo == '' ? null : logo }}
                        style={{
                          width: 200,
                          height: 200,
                          marginBottom: 0,
                          marginTop: 0,
                          alignSelf: 'center',
                        }}></AsyncImage>
                    </View>
                    <Text style={{ textAlign: 'center', fontSize: switchMerchant ? 10 : 16 }}>
                      {/* {merInfo.name} */}
                      {merchantName == null ? 'Merchant Name' : merchantName}
                    </Text>
                    <Text style={{ textAlign: 'center', marginTop: 10, width: 300, fontSize: switchMerchant ? 10 : 16 }}>
                      {address == null ? 'Outlet Address' : address}
                    </Text>
                    <Text style={{ textAlign: 'center', marginTop: 10, fontSize: switchMerchant ? 10 : 16 }}>
                      Phone: {outlet == null ? '+6016123456' : phone}
                    </Text>
                    <Text style={{ textAlign: 'center', marginTop: 10, fontSize: switchMerchant ? 10 : 16 }}>
                      {name == null ? 'Outlet' : name}

                    </Text>
                    <Text style={{ textAlign: 'center', marginTop: 10, fontSize: switchMerchant ? 10 : 16 }}>
                      SST ID No. 0012612771
                    </Text>
                  </View>
                  <View
                    style={{
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      marginBottom: 10,
                    }}>
                    <Text style={{ fontWeight: 'bold', fontSize: switchMerchant ? 20 : 30 }}>
                      ORDER #38
                    </Text>
                  </View>
                  <View style={{ marginBottom: 20 }}>
                    <Text style={{fontSize: switchMerchant ? 10 : 16}}>Mark</Text>
                    <Text style={{fontSize: switchMerchant ? 10 : 16}}>+6012-714 8876</Text>
                  </View>
                  <View style={{ marginBottom: 20 }}>
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Receipt Date</Text>
                      <Text style={{ marginLeft: '1%', fontSize: switchMerchant ? 10 : 16 }}>:</Text>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>  Wed, Sept 27, 2020</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Receipt #</Text>
                      <Text style={{ marginLeft: switchMerchant ? '4.7%' : '4.6%', fontSize: switchMerchant ? 10 : 16 }}>:</Text>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>  0003168128515612</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Cashier</Text>
                      <Text style={{ marginLeft: switchMerchant ? '7%' : '6.6%', fontSize: switchMerchant ? 10 : 16 }}>:</Text>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>  Sophie Kim</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      borderBottomWidth: StyleSheet.hairlineWidth,
                      marginBottom: 10,
                    }}>
                    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                      <View style={{ flex: 3 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>Item</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>Price</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>Discount</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>Qty</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{ textAlign: 'right', paddingRight: 4, fontSize: switchMerchant ? 10 : 16 }}>Subtotal</Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ marginBottom: 10 }}>
                    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                      <View style={{ flex: 3 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>Coconut Coffee</Text>
                        <Text style={{ color: 'grey', fontSize: switchMerchant ? 10 : 16 }}>LESS SWEET</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM8.00</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>0.00</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>1</Text>
                      </View>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                        <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>8.00</Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      marginBottom: 10,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                    }}>
                    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                      <View style={{ flex: 3 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>Yogurt Coffee</Text>
                        <Text style={{ color: 'grey', fontSize: switchMerchant ? 10 : 16 }}>LESS ICE</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM8.00</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>0.00</Text>
                      </View>
                      <View style={{ flex: 1 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>1</Text>
                      </View>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                        <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>8.00</Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                    <View style={{ flex: 6 }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Subtotal</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                      <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>16.00</Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                    <View style={{ flex: 6 }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Discount (-)</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                      <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>0.00</Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                    <View style={{ flex: 6 }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Delivery Charge</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                      <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>0.00</Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                    <View style={{ flex: 6 }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Voucher Used</Text>
                    </View>                    
                    <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                      <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>0.00</Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                    <View style={{ flex: 6 }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Promotion</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                      <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>0.00</Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 20 }}>
                    <View style={{ flex: 6 }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Tax (6%)</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                      <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>0.00</Text>
                    </View>
                  </View>

                  {
                    // getTax(User.getOutletId()),
                    taxList.map(tax => (
                      <View style={{ flexDirection: 'row', marginBottom: 20 }}>
                        <View style={{ flex: 1 }}>
                          <Text style={{fontSize: switchMerchant ? 10 : 16}}>{tax.name} ({tax.rate}%)</Text>
                        </View>
                        <View style={{ flex: 1, alignItems: 'flex-end' }}>
                          <Text style={{fontSize: switchMerchant ? 10 : 16}}>0.00</Text>
                        </View>
                      </View>
                    ))}

                  <View
                    style={{
                      marginBottom: 10,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                    }}>
                    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                      <View style={{ flex: 1 }}>
                        <Text style={{ fontWeight: 'bold', fontSize: switchMerchant ? 15 : 20 }}>
                          Total
                        </Text>
                      </View>
                      <View style={{ flex: 1, alignItems: 'flex-end' }}>
                        <Text style={{ fontWeight: 'bold', fontSize: switchMerchant ? 15 : 20, paddingRight: 10, }}>
                          RM 16.00
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View
                    style={{
                      marginBottom: 10,
                      borderBottomWidth: StyleSheet.hairlineWidth,
                    }}>
                    <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                      <View style={{ flex: 6 }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>SST Summary</Text>
                      </View>
                      <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                        <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                        <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>0.00</Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                    <View style={{ flex: 6 }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Received</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                      <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>16.00</Text>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'row', marginBottom: 30 }}>
                    <View style={{ flex: 6 }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>Balance</Text>
                    </View>
                    <View style={{ flex: 1, justifyContent: 'space-between', flexDirection: 'row', }}>
                      <Text style={{fontSize: switchMerchant ? 10 : 16}}>RM</Text>
                      <Text style={{ paddingRight: 10, fontSize: switchMerchant ? 10 : 16 }}>0.00</Text>
                    </View>
                  </View>
                  {/* <View style={{ flexDirection: 'row', marginBottom: 30 }}>
                    <View style={{ flex: 1 }}>
                      <Text>Notes:</Text>
                      <Text>1: {note1}</Text>
                      <Text>2: {note2}</Text>
                      <Text>3: {note3}</Text>
                    </View>
                  </View> */}
                  <View style={{ alignSelf: 'center', marginBottom: 20 }}>
                    <Text
                      style={{
                        textAlign: 'center',
                        fontSize: 30,
                        fontWeight: 'bold',
                      }}>
                      { note2 }
                    </Text>

                    {/* In Receipt > Replace the Barcode with KooDoo logo and wording bottom "Powered by KooDoo" (ui) */}
                    <Image
                      style={{
                        width: 124,
                        height: 26,
                        alignSelf: 'center',
                        backgroundColor: Colors.primaryColor,
                      }}
                      resizeMode= "contain"
                      source= {require('../assets/image/logo.png')}
                    />
                    <Text
                      style={{
                        textAlign: 'center',
                        fontSize: switchMerchant ? 15 : 16,
                        fontWeight: 'bold',
                      }}>
                      Powered by KooDoo
                    </Text>

                    {/* <Barcode value="Hello World" format="CODE128" width={1} height={50} /> */}
                  </View>
                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
      </ScrollView>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    fontFamily: 'NunitoSans-Regular',
  },
  iosStyle: {
    paddingHorizontal: Platform.OS !== 'ios' ? 0 : 30
  },
  headerLogo: {
    width: 112,
    height: 25,
    marginLeft: 10,
  },
  headerLogo1: {
    width: '100%',
    height: '100%',
  },
  list: {
    paddingVertical: 20,
    paddingHorizontal: 30,
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItem: {
    fontFamily: 'NunitoSans-Regular',
    marginLeft: 20,
    color: Colors.descriptionColor,
    fontSize: 16,
  },
  sidebar: {
    //width: windowWidth * Styles.sideBarWidth,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.44,
    shadowRadius: 10.32,

    elevation: 16,
  },
  content: {
    padding: 20,
    // backgroundColor: 'lightgrey',
    backgroundColor: Colors.highlightColor,
  },
  textInput: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 0,
    flex: 1,
    flexDirection: 'row',
  },
  textInputLocation: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 100,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 10,
    marginRight: 10,
  },
  textInput8: {
    fontFamily: 'NunitoSans-Regular',
    width: 75,
    height: 50,
    flex: 1,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
  },
  textInput9: {
    fontFamily: 'NunitoSans-Regular',
    width: 110,
    height: Platform.OS == 'ios' ? 30 : 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textInput10: {
    fontFamily: 'NunitoSans-Regular',
    width: 200,
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textInput1: {
    fontFamily: 'NunitoSans-Regular',
    width: 250,
    height: 40,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginBottom: 10,
  },
  textInput2: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 5,
    marginRight: 30,
  },
  textInput3: {
    fontFamily: 'NunitoSans-Regular',
    width: '85%',
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    alignSelf: 'center',
    paddingHorizontal: 10
  },
  textInputPhone: {
    fontFamily: 'NunitoSans-Regular',
    width: Platform.OS === 'ios' ? '100%' : '85%',
    height: 50,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
    alignSelf: 'center',
    paddingHorizontal: 10
  },
  textInput4: {
    width: '85%',
    height: 70,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    marginTop: 10,
    marginBottom: 10,
    borderRadius: 10,
  },
  textInput5: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 50,
    paddingHorizontal: 20,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 50,
  },
  textInput6: {
    fontFamily: 'NunitoSans-Regular',
    width: '80 %',
    padding: 16,
    backgroundColor: Colors.fieldtBgColor,
    marginRight: 30,
    borderRadius: 10,
    alignSelf: 'center'
  },
  textInput7: {
    fontFamily: 'NunitoSans-Regular',
    width: 300,
    height: 80,
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 7,
    marginRight: 30,
  },
  button: {
    backgroundColor: Colors.primaryColor,
    width: 150,
    padding: 8,
    borderRadius: 10,
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
  },
  button1: {
    width: '15%',
    padding: 8,
    borderRadius: 10,
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 20,
  },
  button2: {
    backgroundColor: Colors.primaryColor,
    width: '60%',
    padding: 8,
    borderRadius: 10,
    alignItems: 'center',
    marginLeft: '2%',
  },
  button3: {
    backgroundColor: Colors.primaryColor,
    width: '30%',
    height: 50,
    borderRadius: 10,
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 30,
  },
  textSize: {
    fontSize: 19,
    fontFamily: 'NunitoSans-SemiBold'
  },
  viewContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 0,
    width: '100%',
    marginBottom: 15,
  },
  openHourContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    marginBottom: 15,
    width: '100%',
  },
  addButtonView: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 200,
    height: 40,
    alignItems: 'center',
  },
  addButtonView1: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: 'center',
  },
  addNewView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 65,
    marginTop: 7,
    width: '83%',
    alignSelf: 'flex-end',
  },
  addNewView1: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 10,
    alignItems: 'center',
  },
  merchantDisplayView: {
    flexDirection: 'row',
    flex: 1,
    marginLeft: '17%'
  },
  shiftView: {
    flexDirection: 'row',
    backgroundColor: Colors.fieldtBgColor,
    borderRadius: 50,
    width: 200,
    height: 60,
    alignItems: 'center',
    marginTop: 30,
    alignSelf: 'center',
  },
  shiftText: {
    marginLeft: '15%',
    color: Colors.primaryColor,
    fontFamily: 'NunitoSans-SemiBold',
    fontSize: 25,
  },
  closeView: {
    flexDirection: 'row',
    borderRadius: 5,
    borderColor: Colors.primaryColor,
    borderWidth: 1,
    width: 200,
    height: 40,
    alignItems: 'center',
    marginTop: 30,
    alignSelf: 'center',
  },
  taxView: {
    flexDirection: 'row',
    borderWidth: 2,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 150,
    height: 40,
    alignItems: 'center',
    marginTop: 20,
    alignSelf: 'flex-end',
  },
  sectionView: {
    flexDirection: 'row',
    borderRadius: 5,
    padding: 16,
    alignItems: 'center',
  },
  receiptView: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: Colors.primaryColor,
    borderRadius: 5,
    width: 200,
    height: 40,
    alignItems: 'center',
    alignSelf: 'flex-end',
  },
  pinBtn: {
    backgroundColor: Colors.fieldtBgColor,
    width: 70,
    height: 70,
    marginBottom: 16,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  pinNo: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  confirmBox: {
    width: '30%',
    height: '30%',
    borderRadius: 30,
    backgroundColor: Colors.whiteColor,
  },
});
export default SettingScreen;