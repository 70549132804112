import React, { Component, useReducer, useState, useEffect } from 'react'
import { StyleSheet, ScrollView, Text, View, TouchableOpacity, Image, RefreshControl, Platform, Modal, ImageComponent, Alert, Dimensions, BackHandler, TextInput, FlatList, ActivityIndicator, useWindowDimensions } from 'react-native'
import firebase from 'firebase';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Colors from '../constant/Colors';
import Styles from '../constant/Styles';
import Icon from 'react-native-vector-icons/Ionicons';
import IconFontisto from 'react-native-vector-icons/Fontisto';
// import MIcons from 'react-native-vector-icons/MaterialCommunityIcons'
// import FIcons from 'react-native-vector-icons/Feather'
import ApiClient from '../util/ApiClient';
import * as Token from '../util/Token';
import API from '../constant/API';
import * as User from '../util/User'
import Geolocation from '@react-native-community/geolocation';
import moment from 'moment';
// import { color } from 'react-native-reanimated';
// import FontAwesome from 'react-native-vector-icons/FontAwesome';
// import Close from "react-native-vector-icons/AntDesign";
// import Draggable from 'react-native-draggable';
// import EvilIcons from 'react-native-vector-icons/EvilIcons';
// import Entypo from "react-native-vector-icons/Entypo";
import { CommonStore } from '../store/commonStore.js';
import { USER_ORDER_STATUS, USER_QUEUE_STATUS, WEEK } from '../constant/common';
import AsyncImage from '../components/asyncImage';
import { DataStore } from '../store/dataStore';
import { UserStore } from '../store/userStore';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
// import LinearGradient from 'react-native-linear-gradient';
// import Carousel from 'react-native-banner-carousel';
import { useLinkTo, useRoute } from "@react-navigation/native";
import imgLogo from '../asset/image/logo.png';
import { Collections } from '../constant/firebase';
import { ORDER_TYPE } from '../constant/common';
import { prefix } from '../constant/env';
import { isMobile } from '../util/common';

/** 
 * Homescreen
 * function:
 * *Home page of User app
 * *Display current orders, current queue and current reservation
 * *Ring waiter 
 * *Display nearby outlets
 * *Display outlets by category
 * *Navigation bar at bottom
*/

const HomeScreen = props => {
  const {
    // navigation,
    route
  } = props;

  // useEffect(() => {
  //   if (navigation) {
  //     DataStore.update(s => {
  //       s.navigationObj = navigation;
  //     });
  //   }
  // }, [navigation]);

  const linkTo = useLinkTo();

  const {
    height: windowHeight,
    width: windowWidth,
  } = useWindowDimensions();

  // navigation.setOptions({
  //   headerLeft: () => <View></View>,
  //   headerTitle: () => (
  //     <View style={{
  //       alignSelf: 'center',
  //       marginTop: '-2%',
  //     }}>
  //       <Image
  //         style={styles.headerLogo}
  //         resizeMode="contain"
  //         source={require('../asset/image/logo.png')}
  //       />
  //     </View>
  //   ),
  //   headerRight: () => (
  //     <View style={{ flexDirection: 'row', alignItems: 'center' }}>
  //       <TouchableOpacity onPress={() => { props.navigation.navigate('Notification') }} style={{
  //         // marginTop: 10 
  //       }}>
  //         <View style={{ marginRight: 10 }}>
  //           <MaterialIcons
  //             name="notifications-none"
  //             size={30}
  //             color={Colors.descriptionColor}
  //           />
  //         </View>
  //       </TouchableOpacity>
  //       <TouchableOpacity onPress={() => { props.navigation.navigate('Profile') }} style={{
  //         // marginTop: 10 
  //       }}>
  //         <View style={{ marginRight: 15 }}>
  //           <Icon name="menu" size={30} color={Colors.primaryColor} />
  //         </View>
  //       </TouchableOpacity>
  //     </View>
  //   ),
  // });

  const [currentAction, setCurrentAction] = useState([]);
  const [outletTags, setOutletTags] = useState([]);
  const [outletTagsOutlet, setOutletTagsOutlet] = useState([]);
  const [nearbyOutlet, setNearbyOutlet] = useState([]);
  const [queueData, setQueueData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [currentQueue, setCurrentQueue] = useState([]);
  const [currentReservation, setCurrentReservation] = useState([]);
  const [currentOrder, setCurrentOrder] = useState([]);
  const [popularOutlets, setPopularOutlets] = useState([]);
  const [maxMin, setMaxMin] = useState([]);
  ////////////////////////////Test seacr bar and filter//////////////////////////////
  const [visible, setVisible] = useState(false);

  const [search, setSearch] = useState('');
  const [list1, setList1] = useState(true);
  ////////////////////////////Test seacr bar and filter//////////////////////////////
  const [fireorder, setFireorder] = useState([]);
  const [isOpenToday, setIsOpenToday] = useState(false);
  const [ringOrder, setRingOrder] = useState(null);

  const [allRecords, setAllRecords] = useState([]);

  //////////////////////////////////////////////////////////////////////    

  const nearbyOutlets = CommonStore.useState(s => s.nearbyOutlets);
  const outletsOpeningDict = CommonStore.useState(s => s.outletsOpeningDict);

  const tags = CommonStore.useState(s => s.tags);
  const selectedOutletTag = CommonStore.useState(s => s.selectedOutletTag);
  const tagOutlets = CommonStore.useState(s => s.tagOutlets);

  const outletsMinMaxDict = CommonStore.useState(s => s.outletsMinMaxDict);

  // const merchantsDict = CommonStore.useState(s => s.merchantsDict);
  // const outletsDict = CommonStore.useState(s => s.outletsDict);

  const userOrders = CommonStore.useState(s => s.userOrders);
  const userReservations = CommonStore.useState(s => s.userReservations);
  const userQueues = CommonStore.useState(s => s.userQueues);
  const availablePromotions = CommonStore.useState(s => s.availablePromotions);
  const selectedOutletPromotion = CommonStore.useState(s => s.selectedOutletPromotion);
  const selectedOutlet = CommonStore.useState(s => s.selectedOutlet);
  const allPromotions = CommonStore.useState(s => s.allPromotions);
  const nearbyPromotions = CommonStore.useState(s => s.nearbyPromotions);

  // need separate into active and history orders in future
  // useEffect(() => {
  //   console.log('userOrders');
  //   console.log(userOrders);
  // }, [userOrders]),

  useEffect(() => {
    console.log('tagOutlets changed!');
    console.log(tagOutlets);
  }, [tagOutlets]);

  useEffect(() => {
    var allRecordsTemp = [
      ...userOrders.filter(item => item.orderStatus !== USER_ORDER_STATUS.ORDER_COMPLETED),
      ...userReservations.filter(item => moment().isBefore(moment(item.reservationTime).add(12, 'hour'))),
      ...userQueues.filter(item => item.status !== USER_QUEUE_STATUS.SEATED),
    ];

    //const sortRecordTemp =
    //  allRecordsTemp.sort((a,b) => {
    //      return a.updatedAt > b.updatedAt
    //  });

    setAllRecords(allRecordsTemp.sort((a, b) => b.updatedAt - a.updatedAt));

    //setAllRecords(sortRecordTemp);

  }, [userOrders, userReservations, userQueues]);

  console.log('screen size');
  console.log(windowWidth);

  //////////////////////////////////////////////////////////////////////

  const setState = () => { };

  const backAction = () => {
    BackHandler.exitApp()
    return true;
  };

  useEffect(() => {
    if (linkTo) {
      DataStore.update(s => {
        s.linkToFunc = linkTo;
      });
    }

    firebase.auth().signInAnonymously()
      .then((result) => {
        const firebaseUid = result.user.uid;

        ApiClient.GET(API.getTokenKWeb + firebaseUid).then(async (result) => {
          console.log('getTokenKWeb');
          console.log(result);

          if (result && result.token) {
            await AsyncStorage.setItem('accessToken', result.token);
            await AsyncStorage.setItem('refreshToken', result.refreshToken);

            UserStore.update(s => {
              s.firebaseUid = result.userId;
              s.userId = result.userId;
              s.role = result.role;
              s.refreshToken = result.refreshToken;
              s.token = result.token;
            });

            // linkTo && linkTo(`${prefix}/`);

            // const outletSnapshot = await firebase.firestore().collection(Collections.Outlet)
            //     .where('uniqueId', '==', route.params.outletId)
            //     .limit(1)
            //     .get();

            // var outlet = {};
            // if (!outletSnapshot.empty) {
            //     outlet = outletSnapshot.docs[0].data();
            // }

            // if (outlet) {
            //     await AsyncStorage.setItem('latestOutletId', outlet.uniqueId);

            //     await updateUserCart(route.params, outlet, firebaseUid);

            //     // linkTo && linkTo('/web/outlet');
            // }
            // else {
            //     linkTo && linkTo(`${prefix}/scan`);
            // }
          }
          else {
            CommonStore.update(s => {
              s.alertObj = {
                title: 'Error',
                message: 'Unauthorized access',
              };

              // s.isAuthenticating = false;
            });

            linkTo && linkTo(`${prefix}/error`);
          }
        });
      });
  }, [linkTo, route]);

  useEffect(() => {
    // backHandler = BackHandler.addEventListener(
    //   "hardwareBackPress",
    //   backAction
    // );
    getNearbyOutlet(3.1042, 101.6128);
    getInfo();

    getLocation();

    setState({
      currentAction: [],
      selectedOutletTags: 0,
    });

    // getCurrentAction();
    // setInterval(() => {
    //   getCurrentAction();
    // }, 5000);

    // User.setRefreshCurrentAction(getCurrentAction.bind(this));
    if (User.getUserId() != null) {
      // ApiClient.GET(API.getUserFireOrder + User.getUserId()).then((result) => {
      //   //console.log(result)
      //   setState({ fireorder: result });
      // });
    }

    return () => {
      // backHandler.remove();
    };
  }, []);

  // function
  const serve = id => {
    const body = {
      orderItemId: id,
    }
    ApiClient.POST(API.serveFireOrder, body).then((result) => {
      //console.log(result);
    });
  }

  const getCurrentAction = () => {
    if (User.getUserId() !== null) {
      // ApiClient.GET(API.getAllUserQueue + User.getUserId()).then((result) => {
      //   //console.log(result);

      //   if (result != undefined && result.length > 0) setState({ currentQueue: result });
      //   else setState({ currentQueue: [] });
      // });

      // ApiClient.GET(API.getPopular).then((result) => {
      //     if(result.length > 0)
      //         setState({ popularOutlets: result})
      //     else
      //         setState({ popularOutlets: [] })

      // });

      // ApiClient.GET(API.getCurrentOrder + User.getUserId()).then((result) => {
      //   //console.log(result);
      //   if (result != undefined && result.length > 0) setState({ currentOrder: result }), getRingOrder(result);
      //   else setState({ currentOrder: [] });
      // });
      // ApiClient.GET(API.getReservationList + User.getUserId()).then((result) => {
      //   //console.log(result);
      //   if (result != undefined && result.length > 0) setState({ currentReservation: result });
      //   else setState({ currentReservation: [] });
      // });
    }
  }

  const getLocation = () => {
    Geolocation.getCurrentPosition(
      (position) => {
        const longitude = JSON.stringify(position.coords.longitude);
        const latitude = JSON.stringify(position.coords.latitude);
        //(longitude);
        //console.log(latitude);

        // if (longitude && latitude) getNearbyOutlet(latitude, longitude);
      },
      (error) => {
        console.log(error.message);
      },
      {
        enableHighAccuracy: false,
        timeout: 20000,
        maximumAge: 1000,
      },
    );
    // getNearbyOutlet(3.1042, 101.6128);
  }

  const getInfo = () => {
    getCurrentAction();
    setState({ refreshing: true });
    // ApiClient.GET(API.getAllOutletTags).then((result) => {
    //   setState({ outletTags: result, selectedTag: result[0].id });

    //   ApiClient.GET(API.getOutletByTags + result[0].id).then((result) => {
    //     setState({ outletTagsOutlet: result });
    //   });
    // });

    setState({ refreshing: false });
  }

  const getNearbyOutlet = (currentLatitude, currentLongitude) => {
    setState({ refreshing: true })
    // ApiClient.GET(API.outletSearch + currentLatitude + "/" + currentLongitude).then((result) => {
    //   //console.log(currentLatitude, currentLongitude);
    //   //console.log(result);
    //   if (result != undefined && result.length > 0) {
    //     var outletList = [];
    //     result.forEach((element) => {
    //       //console.log(element.items);
    //       outletList = outletList.concat(element);
    //     });
    //   }

    //   outletList.forEach((element) => {
    //     //console.log(!element.name);
    //     const outletTakeaway = outletList.filter(element => element.name === null ? "" : element.name)
    //     //console.log(outletTakeaway)
    //     setState({ nearbyOutlet: outletTakeaway })
    //     const outletTakeaway1 = outletList.filter(element => !element.name === true)
    //     //console.log(outletTakeaway1)
    //     setState({ maxMin: outletTakeaway1 })
    //   });
    // });
    setState({ refreshing: false })
  }

  // const checkOutletOpening = (item) => {
  //   var isTodayOpen = false
  //   item.outletOpenings.map((element) => {
  //     const today = currentDate;
  //     const timeNow = new Date()
  //     var timeNow24hr = timeNow.getHours().toString() + ":" + timeNow.getMinutes().toString()
  //     if (timeNow.getHours().toString() < 10) {
  //       timeNow24hr = '0' + timeNow24hr.toString()
  //     }
  //     const day = moment(today).format('dddd');
  //     //startTime, endTime format: HH:MM(AM/PM)
  //     const startTime = element.startTime.split(":")
  //     //AM or PM
  //     const startSun = startTime[1].slice(startTime[1].length - 2)
  //     var startHour = startSun == "PM" ? parseInt(startTime[0]) + 12 : parseInt(startTime[0])
  //     if (startHour < 10) {
  //       startHour = "0" + startHour.toString()
  //     }
  //     const startMinute = startTime[1].slice(0, -2)
  //     const start24Hr = startHour + ":" + startMinute
  //     const endTime = element.endTime.split(":")
  //     //AM or PM
  //     const endSun = endTime[1].slice(endTime[1].length - 2)
  //     var endHour = endSun == "PM" ? parseInt(endTime[0]) + 12 : parseInt(endTime[0])
  //     if (endHour < 10) {
  //       endHour = "0" + endHour.toString()
  //     }
  //     const endMinute = endTime[1].slice(0, -2)
  //     const end24Hr = endHour + ":" + endMinute
  //     if (element.week == day) {
  //       if (start24Hr < timeNow24hr && end24Hr > timeNow24hr) {
  //         isTodayOpen = true
  //       }
  //     }
  //   })
  //   return isTodayOpen
  // }

  const checkOutletOpening = (outlet) => {
    // var isOpeningNow = false;

    // const currDay = moment().day();

    // const outletOpeningToday = outletsOpeningDict[outlet[currDay]];

    // try {
    //   if (outletOpeningToday !== undefined) {
    //     const startTimeStr = outletOpeningToday.split('-')[0];
    //     const endTimeStr = outletOpeningToday.split('-')[1];

    //     const startTime = moment(startTimeStr, 'HHmm');
    //     const endTime = moment(endTimeStr, 'HHmm');

    //     isOpeningNow = moment().isSameOrAfter(startTime) && moment().isBefore(endTime);
    //   };
    // }
    // catch (ex) {
    //   console.error(ex);
    // }

    // return isOpeningNow;

    // item.outletOpenings.map((element) => {
    //   const today = currentDate;
    //   const timeNow = new Date()
    //   var timeNow24hr = timeNow.getHours().toString() + ":" + timeNow.getMinutes().toString()
    //   if (timeNow.getHours().toString() < 10) {
    //     timeNow24hr = '0' + timeNow24hr.toString()
    //   }
    //   const day = moment(today).format('dddd');
    //   //startTime, endTime format: HH:MM(AM/PM)
    //   const startTime = element.startTime.split(":")
    //   //AM or PM
    //   const startSun = startTime[1].slice(startTime[1].length - 2)
    //   var startHour = startSun == "PM" ? parseInt(startTime[0]) + 12 : parseInt(startTime[0])
    //   if (startHour < 10) {
    //     startHour = "0" + startHour.toString()
    //   }
    //   const startMinute = startTime[1].slice(0, -2)
    //   const start24Hr = startHour + ":" + startMinute
    //   const endTime = element.endTime.split(":")
    //   //AM or PM
    //   const endSun = endTime[1].slice(endTime[1].length - 2)
    //   var endHour = endSun == "PM" ? parseInt(endTime[0]) + 12 : parseInt(endTime[0])
    //   if (endHour < 10) {
    //     endHour = "0" + endHour.toString()
    //   }
    //   const endMinute = endTime[1].slice(0, -2)
    //   const end24Hr = endHour + ":" + endMinute
    //   if (element.week == day) {
    //     if (start24Hr < timeNow24hr && end24Hr > timeNow24hr) {
    //       isTodayOpen = true
    //     }
    //   }
    // });

    // return isTodayOpen
  }


  const checkAllOutletOpening = () => {
    const open = []
    for (const outlet of nearbyOutlet) {
      if (checkOutletOpening(outlet)) {
        open.push(outlet)
      }
    }
    return open.length > 0
  }
  // function end

  // Change font based on smaller screen width

  var HomeFontSize = 13;

  if (windowWidth <= 360) {
    HomeFontSize = 10;
    //console.log(windowWidth)
  }

  const TextStyleScale = {
    fontSize: HomeFontSize,
  };

  var cardTitleFontSize = 19;

  if (windowWidth <= 360) {
    cardTitleFontSize = 16;
    //console.log(windowWidth)
  }

  const cardTitleText = {
    fontSize: cardTitleFontSize,
  };

  var outletTitleFontSize = 14;

  if (windowWidth <= 360) {
    outletTitleFontSize = 12;
    //console.log(windowWidth)
  }

  const outletTitleText = {
    fontSize: outletTitleFontSize,
  };

  var addressFontSize = 12;

  if (windowWidth <= 360) {
    addressFontSize = 10;
    //console.log(windowWidth)
  }

  const addressTextScale = {
    fontSize: addressFontSize,
  };

  var operationFontSize = 11;

  if (windowWidth <= 360) {
    operationFontSize = 10;
    //console.log(windowWidth)
  }

  const OperationTextScale = {
    fontSize: operationFontSize,
  };

  var timeFontSize = 11;

  if (windowWidth <= 360) {
    timeFontSize = 9.5;
    //console.log(windowWidth)
  }

  const timeTextScale = {
    fontSize: timeFontSize,
  };

  var reservationFontSize = 14;

  if (windowWidth <= 360) {
    reservationFontSize = 12;
    //console.log(windowWidth)
  }

  const reservationTextScale = {
    fontSize: reservationFontSize,
  };

  var numberFontSize = 15;

  if (windowWidth <= 360) {
    numberFontSize = 12;
    //console.log(windowWidth)
  }

  const numberTextScale = {
    fontSize: numberFontSize,
  };

  var queueNumFontSize = 48;

  if (windowWidth <= 360) {
    queueNumFontSize = 36;
    //console.log(windowWidth)
  }

  const queueNumTextScale = {
    fontSize: queueNumFontSize,
  };

  var priceFontMarginLeft = 10;

  if (windowWidth <= 360) {
    priceFontMarginLeft = 5;
    //console.log(windowWidth)
  }

  const marginLeftPrice = {
    marginLeft: priceFontMarginLeft,
  };

  const BannerWidth = windowWidth;
  const BannerHeight = 200;

  var nearByOutletCardStyle = {
    ...styles.nearByOutletCard,
    width: !isMobile() ? (windowWidth * 0.4) : (windowWidth - 32),
  };

  if (windowWidth <= 1184) {
    nearByOutletCardStyle = {
      ...nearByOutletCardStyle,
      width: !isMobile() ? (windowWidth * 0.8) : (windowWidth - 32),
    }
  }

  var footerContainerStyle = {
    width: !isMobile() ? (windowWidth * 0.4) : '100%',
    alignSelf: 'center',
  };

  if (windowWidth <= 1184) {
    footerContainerStyle = {
      ...footerContainerStyle,
      width: !isMobile() ? (windowWidth * 0.8) : '100%',
    }
  }

  var imageWidthStyle = {
    width: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
  };

  if (windowWidth <= 800) {
    imageWidthStyle = {
      ...imageWidthStyle,
      width: !isMobile() ? (windowWidth * 0.25) : windowWidth * 0.3,
    }
  }
  else if (windowWidth <= 1184) {
    imageWidthStyle = {
      ...imageWidthStyle,
      width: !isMobile() ? (windowWidth * 0.15) : windowWidth * 0.3,
    }
  }

  var imageHeightStyle = {
    height: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
  };

  if (windowWidth <= 800) {
    imageHeightStyle = {
      ...imageHeightStyle,
      height: !isMobile() ? (windowWidth * 0.25) : windowWidth * 0.3,
    }
  }
  else if (windowWidth <= 1184) {
    imageHeightStyle = {
      ...imageHeightStyle,
      height: !isMobile() ? (windowWidth * 0.15) : windowWidth * 0.3,
    }
  }

  var cardLeftPartWidthStyle = {
    width: !isMobile() ? windowWidth * 0.25 : windowWidth * 0.5,
  };

  if (windowWidth <= 800) {
    cardLeftPartWidthStyle = {
      ...cardLeftPartWidthStyle,
      width: !isMobile() ? windowWidth * 0.47 : windowWidth * 0.5,
    }
  }
  else if (windowWidth <= 1184) {
    cardLeftPartWidthStyle = {
      ...cardLeftPartWidthStyle,
      width: !isMobile() ? windowWidth * 0.5 : windowWidth * 0.5,      
    }
  }

  var cardLeftPartHeightStyle = {
    height: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.32,
  };

  if (windowWidth <= 800) {
    cardLeftPartHeightStyle = {
      ...cardLeftPartHeightStyle,
      height: !isMobile() ? windowWidth * 0.25 : windowWidth * 0.32,
    }
  }
  else if (windowWidth <= 1184) {
    cardLeftPartHeightStyle = {
      ...cardLeftPartHeightStyle,
      height: !isMobile() ? windowWidth * 0.15 : windowWidth * 0.32,
    }
  }

  return (
    <View style={[styles.container, {
      width: windowWidth,
      height: windowHeight,
      backgroundColor: Colors.highlightColor,
      // backgroundColor: Colors.tabGrey,
    }]}>
      <ScrollView
        //style={styles.container}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              getInfo();
            }}
          />
        }
        contentContainerStyle={{
          // paddingBottom: 20,
        }}>
        <View style={{
          flexDirection: 'row',
          padding: 16,
          alignItems: 'center',
          marginTop: '1%',
          marginBottom: !isMobile() ? '2%' : '5%',
          width: '100%',
          justifyContent: 'center',
          // backgroundColor: 'red',
          opacity: nearbyOutlets.length > 0 ? 100 : 0,
        }}>
          {/* <Text
            style={{
              fontSize: 14,
              color: Colors.mainTxtColor,
              flex: 1,
              fontFamily: "NunitoSans-Bold",
              fontStyle: 'normal',
              // fontWeight: 'bold',
              fontSize: 23,
              lineHeight: 31,

              textAlign: 'center'
            }}>
            Nearby
          </Text> */}

          <Image style={{
            width: 300,
            height: 67,
            alignSelf: 'center',
            transform: [
              {
                scale: 0.75,
              }
            ]
          }} resizeMode="contain" source={imgLogo} />
        </View>

        {/* <View style={{
          flexDirection: 'row',
          padding: 16,
          alignItems: 'center',
          marginTop: '1%',
          marginBottom: '-2%',
          width: '100%',
          justifyContent: 'center',
          // backgroundColor: 'red',
          opacity: nearbyOutlets.length > 0 ? 100 : 0,
        }}>
          <Text
            style={{
              fontSize: 14,
              color: Colors.mainTxtColor,
              flex: 1,
              fontFamily: "NunitoSans-Bold",
              fontStyle: 'normal',
              // fontWeight: 'bold',
              fontSize: 23,
              lineHeight: 31,
              textAlign: 'center',
            }}>
            Nearby
          </Text>
        </View> */}

        {nearbyOutlets.length > 0 ?
          nearbyOutlets.map((itemParam, index) => {
            const item = {
              ...itemParam,
              minMax: outletsMinMaxDict[itemParam.uniqueId],
            };

            var isOpeningNow = false;

            const currDay = moment().day();

            var outletOpeningToday = null;

            var startTimeStr = null;
            var endTimeStr = null;

            try {
              const outletOpening = outletsOpeningDict[item.uniqueId];
              if (outletOpening) {
                outletOpeningToday = outletOpening[WEEK[currDay]];
              }

              if (outletOpeningToday) {
                startTimeStr = outletOpeningToday.split('-')[0];
                endTimeStr = outletOpeningToday.split('-')[1];

                const startTime = moment(startTimeStr, 'HHmm');
                const endTime = moment(endTimeStr, 'HHmm');

                isOpeningNow = moment().isSameOrAfter(startTime) && moment().isBefore(endTime);
              };
            }
            catch (ex) {
              console.error(ex);
            }

            // if (!isOpeningNow) {
            //   return;
            // }                           

            return (
              <View
                key={index}

                style={{
                  // backgroundColor: 'red',
                  alignItems: 'center',
                  // width: !isMobile() ? (windowWidth * 0.4) : windowWidth - 32,
                }}
              >
                <TouchableOpacity style={[styles.nearByOutletCard, nearByOutletCardStyle]}
                  onPress={() => {
                    // CommonStore.update(s => {
                    //   // s.selectedOutletItems = [];
                    //   // s.selectedOutletItemCategories = [];

                    //   s.selectedOutlet = item;
                    // });

                    // props.navigation.navigate('Outlet', { outletData: item });

                    if (item.subdomain && item.subdomain !== undefined) {
                      // means exist

                      // window.open(`https://${item.subdomain}.mykoodoo.com/web-order/takeaway`, '_blank').focus();

                      window.open(`https://mykoodoo.com/web-order/outlet/${item.subdomain}/takeaway`, '_blank').focus();
                    }
                    else {
                      CommonStore.update(s => {
                        s.alertObj = {
                          title: 'Info',
                          message: 'The web order for this outlet is not available.',
                        };
                      });
                    }
                  }}
                >
                  <View style={[{
                    // width: '70%'                     
                    // backgroundColor: 'red',

                    display: 'flex',
                    justifyContent: 'space-between',
                    // width: !isMobile() ? windowWidth * 0.25 : windowWidth * 0.5,
                    // height: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.32,
                  }, cardLeftPartWidthStyle, cardLeftPartHeightStyle]}>
                    <View style={{
                      // backgroundColor: 'red',                      
                    }}>
                      <Text style={{
                        fontSize: !isMobile() ? 24 : 20,
                        fontFamily: "NunitoSans-Bold",
                        lineHeight: 27,
                        color: Colors.fontDark,
                      }} numberOfLines={1}>
                        {item.name}
                      </Text>

                      <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: 10,
                      }}>
                        <IconFontisto
                          size={!isMobile() ? 14 : 10}
                          name='map-marker-alt'
                          color={Colors.fieldtTxtColor}
                          style={{
                            marginRight: 8,
                          }}
                        />

                        <Text
                          style={[addressTextScale, {
                            //fontSize: 12,
                            // backgroundColor: 'red',
                            marginTop: !isMobile() ? '-0.5%' : '-1.2%',
                            color: Colors.descriptionColor,
                            fontWeight: '600',
                            // lineHeight: 14,
                            fontFamily: "NunitoSans-Regular",
                            width: '90%',
                            ...!isMobile() && {
                              fontSize: 14,
                            },
                          }]} numberOfLines={3}>
                          {item.address}
                        </Text>
                      </View>
                    </View>

                    <View style={[{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',

                      // width: !isMobile() ? windowWidth * 0.25 : windowWidth * 0.5,
                      justifyContent: !isMobile() ? 'flex-start' : 'space-between',
                    }, cardLeftPartWidthStyle]}>
                      {/* <View style={{ marginTop: 27, }}>
                        {maxMin.map((element) => {
                          if (element.id == item.id) {
                            return element.categories.map((addOns) =>
                              addOns.items.map((choices) => (
                                <Text
                                  style={{
                                    fontSize: 12,
                                    marginTop: 5,
                                    lineHeight: 16,
                                    fontFamily: "NunitoSans-Bold"
                                  }}>
                                  Opening Hours :
                                </Text>
                              )),
                            );
                          }
                        })}

                        <Text
                          style={{
                            fontSize: 12,
                            marginTop: 5,
                            lineHeight: 16,
                            fontFamily: "NunitoSans-Bold"
                          }}>
                          Opening Hours :
                                </Text>

                      </View> */}

                      <View style={{
                        marginLeft: -2,
                        ...!isMobile() && {
                          marginRight: '2%',
                        },
                      }}>
                        {/* {item.minmax.map((element) => (
                      element.categories.map((addOns) => (
                        addOns.items.map((maxMin) => { return (<Text style={{ fontSize: 12, marginTop: 5, lineHeight: 16, fontWeight: 'bold' }}>RM{maxMin.min.split(".00")[0]} - RM{maxMin.max.split(".00")[0]}</Text>) })
                      ))
                    ))} */}
                        {/* {item.minMax && item.minMax.max && item.minMax.max.price && item.minMax.min && item.minMax.min.price &&                          
                          <Text style={{
                            fontFamily: 'NunitoSans-Bold',
                            fontSize: 13,
                            lineHeight: 16,
                          }}>
                            RM{Math.round(item.minMax.min.price).toString()}-RM{Math.round(item.minMax.max.price).toString()}
                          </Text>
                        } */}
                        <Text style={[OperationTextScale, {
                          fontFamily: 'NunitoSans-Bold',
                          //fontSize: 11,
                          lineHeight: 16,
                          ...!isMobile() && {
                            fontSize: 14,
                          },
                        }]}>
                          Operating Hours:
                        </Text>
                      </View>

                      <View style={{
                        // marginTop: 27, 
                        marginLeft: 0
                      }}>
                        {/* {item.outletOpenings.map((element) => {
                          const today = currentDate;
                          const day = moment(today).format('dddd');
                          //console.log(day);
                          if (element.week == day) {
                            isOpenToday = true
                            return (
                              <Text
                                style={{
                                  fontSize: 12,
                                  marginTop: 5,
                                  color: Colors.primaryColor,
                                  lineHeight: 16,
                                  fontFamily: "NunitoSans-Bold"
                                }}>
                                {element.startTime == null ? "null" : element.startTime} - {element.endTime == null ? "null" : element.endTime}
                              </Text>
                            );
                          }
                        })} */}
                        {outletOpeningToday &&

                          <View style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: !isMobile() ? '100%' : '100%',
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            // height: '50%',
                            // backgroundColor: 'red',
                          }}>
                            <Text
                              style={[timeTextScale, {
                                // fontSize: 12,
                                // marginTop: 5,
                                color: Colors.primaryColor,
                                // lineHeight: 16,
                                // fontFamily: "NunitoSans-Bold",

                                fontFamily: 'NunitoSans-Bold',
                                //fontSize: 11,
                                lineHeight: 16,
                                ...!isMobile() && {
                                  fontSize: 14,
                                },
                              }]}>
                              {startTimeStr == null ? "null" : moment(startTimeStr, 'HHmm').format('h:mma')}-{endTimeStr == null ? "null" : moment(endTimeStr, 'HHmm').format('h:mma')}
                            </Text>
                          </View>
                        }
                        {/* {!isOpeningNow ?
                          (
                            <Text
                              style={{
                                fontSize: 14,
                                marginTop: 5,
                                color: Colors.primaryColor,
                                lineHeight: 16,
                                fontFamily: "NunitoSans-Bold"
                              }}>
                              Close
                            </Text>
                          ) : null}
                        {isOpeningNow == false} */}
                      </View>
                    </View>
                  </View>


                  <View style={[{
                    flexDirection: 'row',
                    // width: '20%',
                    // width: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
                    // marginLeft: 15,
                    // position: 'absolute',
                    // right: windowWidth * 0.01,
                    // height: windowWidth * 0.35,

                    // display: 'flex',
                    // alignItems: 'center',
                    // justifyContent: 'center',
                  }, imageWidthStyle]}>
                    {/* <Image
                        source={{ uri: item.cover }}
                        style={{ width: 80, height: 80, borderRadius: 5 }}
                      /> */}
                    {!(item.cover || (item.merchant && item.merchant.logo)) &&
                      <View style={[{
                        // width: 80,
                        // height: 80,
                        // borderRadius: 5,
                        // width: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
                        // height: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
                        borderRadius: 13,
                        backgroundColor:
                          Colors.secondaryColor
                      }, imageWidthStyle, imageHeightStyle]}></View>
                    }
                    {(!item.cover && item.merchant && item.merchant.logo) &&
                      <AsyncImage source={{ uri: item.merchant.logo }} style={[{
                        // width: 80,
                        // height: 80,
                        // borderRadius: 5
                        // width: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
                        // height: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
                        borderRadius: 13,
                      }, imageWidthStyle, imageHeightStyle]} />
                    }
                    {item.cover ?
                      <AsyncImage source={{ uri: item.cover }} item={item} style={[{
                        // width: 80, 
                        // height: 80, 
                        // width: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
                        // height: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
                        borderRadius: 13,
                      }, imageWidthStyle, imageHeightStyle]} />
                      : <></>
                    }

                    {
                      !isOpeningNow
                        ?
                        <View style={[{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          backgroundColor: 'black',
                          opacity: 0.5,
                          // width: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
                          // height: !isMobile() ? windowWidth * 0.1 : windowWidth * 0.3,
                          borderRadius: 13,
                        }, imageWidthStyle, imageHeightStyle]}>
                        </View>
                        :
                        <></>
                    }
                  </View>
                </TouchableOpacity>
              </View>

            );
          }) :

          <View style={{
            justifyContent: 'center',
            alignContent: 'center',
            padding: 16,
            // backgroundColor: 'red',
            height: !isMobile() ? windowHeight * 0.6 : windowHeight * 0.5,
          }}>
            <Text style={{
              color: Colors.descriptionColor,
              textAlign: "left",
              marginVertical: 0,
              fontFamily: 'NunitoSans-Regular',
              fontSize: 16,
              // marginTop: -15,
              ...!isMobile() && {
                fontSize: 20,
              },
              textAlign: 'center',
              marginBottom: !isMobile() ? '1%' : '5%',
            }}>
              Searching nearby outlets...
            </Text>

            <ActivityIndicator
              color={Colors.primaryColor}
              size={'small'}
            />
          </View>
        }

        {nearbyOutlets.length > 0 ?
          <View style={footerContainerStyle}>
            <View style={{
              height: 1,
              width: '96%',
              alignSelf: 'center',
              backgroundColor: Colors.fieldtTxtColor,
              marginTop: 20,

              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 1,
              },
              shadowOpacity: 0.22,
              shadowRadius: 2.22,
              elevation: 1,

              opacity: 0.5,
            }}>

            </View>

            <View style={{
              width: '100%',
              height: windowHeight * 0.15,
              // backgroundColor: Colors.lightPrimary,
              marginTop: 20,

              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',

              paddingHorizontal: '3%',
              paddingVertical: '2%',

              marginBottom: windowHeight * 0.15,
            }}>
              <View style={{
                display: 'flex',
                flexDirection: 'column',

                width: '47%',
              }}>
                <Text style={{
                  color: Colors.fontDark,
                  // textAlign: "left",                  
                  fontFamily: 'NunitoSans-Bold',
                  fontSize: 26,
                  ...!isMobile() && {
                    fontSize: 28,
                  },

                  paddingLeft: '0.5%',
                  // marginLeft: '-0.4%',
                }}>
                  KooDoo
                </Text>

                <Text style={{
                  color: Colors.fontDark,
                  // textAlign: "left",                  
                  fontFamily: 'NunitoSans-Regular',
                  fontSize: 12,
                  ...!isMobile() && {
                    fontSize: 14,
                  },

                  paddingLeft: '1%',
                  marginTop: '4%',

                  lineHeight: 20,
                }}>
                  {'KooDoo was founded in 2020 under MSC status. We are a technology solution and merchant services company that connects and facilitates services such as self-ordering & take-aways between merchants and you. The company, PS Tech Solution Sdn Bhd (1350459-A) is headquartered in D-5-50, 10 Boulevard, 47400, Damansara, Kuala Lumpur, Malaysia\n\nKooDoo © 2021'}
                </Text>
              </View>

              <View style={{
                display: 'flex',
                flexDirection: 'column',

                width: '47%',
                alignItems: 'flex-end',

                // justifyContent: 'flex-end',
              }}>
                <View style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <Text style={{
                    color: Colors.fontDark,
                    textAlign: "left",
                    // width: '100%',
                    fontFamily: 'NunitoSans-Bold',
                    fontSize: 26,
                    ...!isMobile() && {
                      fontSize: 28,
                    },

                    // width: !isMobile() ? '50%' : '90%',
                    width: !isMobile() ? '100%' : '100%',       
                    
                    marginLeft: '-0.8%',
                  }}>
                    Company
                  </Text>

                  <View style={{
                    // color: Colors.fieldtTxtColor,
                    // textAlign: "right",                  
                    // fontFamily: 'NunitoSans-Regular',
                    // fontSize: 14,
                    // ...!isMobile() && {
                    //   fontSize: 14,
                    // },

                    paddingLeft: '2%',
                    marginTop: '4%',

                    flexDirection: 'column',

                    // width: !isMobile() ? '50%' : '90%',
                    width: !isMobile() ? '100%' : '100%',
                  }}>
                    <TouchableOpacity onPress={() => {
                      window.open(`https://mykoodoo.com/`, '_blank').focus();
                    }}>
                      <Text style={{
                        color: Colors.primaryColor,
                        textAlign: "left",
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 12,
                        ...!isMobile() && {
                          fontSize: 14,
                        },

                        lineHeight: 20,
                      }}>
                        Home
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => {
                      window.open(`https://mykoodoo.com/contact.html`, '_blank').focus();
                    }}>
                      <Text style={{
                        color: Colors.primaryColor,
                        textAlign: "left",
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 12,
                        ...!isMobile() && {
                          fontSize: 14,
                        },

                        lineHeight: 20,
                      }}>
                        Contact
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => {
                      window.open(`https://mykoodoo.com/privacypolicy.html`, '_blank').focus();
                    }}>
                      <Text style={{
                        color: Colors.primaryColor,
                        textAlign: "left",
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 12,
                        ...!isMobile() && {
                          fontSize: 14,
                        },

                        lineHeight: 20,
                      }}>
                        Privacy Policy
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => {
                      window.open(`https://mykoodoo.com/return_refund_policy.html`, '_blank').focus();
                    }}>
                      <Text style={{
                        color: Colors.primaryColor,
                        textAlign: "left",
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 12,
                        ...!isMobile() && {
                          fontSize: 14,
                        },

                        lineHeight: 20,
                      }}>
                        {'Return & Refund Policy'}
                      </Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={() => {
                      window.open(`https://mykoodoo.com/terms.html`, '_blank').focus();
                    }}>
                      <Text style={{
                        color: Colors.primaryColor,
                        textAlign: "left",
                        fontFamily: 'NunitoSans-Regular',
                        fontSize: 12,
                        ...!isMobile() && {
                          fontSize: 14,
                        },

                        lineHeight: 20,
                      }}>
                        Terms & Conditions
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            </View>
          </View>
          :
          <></>
        }
      </ScrollView>
    </View >
  );
}

const styles = StyleSheet.create({
  container: {
    // flex: 1,

    // backgroundColor: Colors.highlightColor,
    // width: Dimensions.get('window').width,
    // height: Dimensions.get('window').height,
  },
  headerLogo: {
    // width: 140,
    // height: 100,
    width: 112,
    height: 25,
  },
  welcomeText: {
    color: Colors.mainTxtColor,
    // fontSize: 23,
    marginVertical: 16,
    alignSelf: 'center',
    // fontFamily: 'NunitoSans-Bold',
    fontFamily: 'NunitoSans-Bold',
    // fontStyle: 'normal',
    // fontWeight: 'bold',
    fontSize: 23,
    lineHeight: 31,
  },
  searchBar: {
    marginHorizontal: 16,
    backgroundColor: Colors.fieldtBgColor,
    flexDirection: 'row',
    // padding: 12,
    padding: 0,
    borderRadius: 10,
    alignContent: 'center',
    height: 45,
    // width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 16,
    paddingRight: 16,
    alignItems: 'center',
  },
  searchBarText: {
    color: Colors.fieldtTxtColor,
    marginLeft: 10,
    fontFamily: "NunitoSans-Regular",
    fontSize: 14,
    lineHeight: 20,
  },
  outletTitle: {
    color: Colors.whiteColor,
    alignSelf: 'center',
    // width: 100,
    fontFamily: "NunitoSans-Bold",
    fontSize: 9,
    textAlign: 'center'
  },
  card: {
    flex: 1,
    // minWidth: Dimensions.get('window').width - 20,
    // minHeight: 100,
    width: Dimensions.get('window').width * 0.8,
    height: Dimensions.get('window').width * 0.40,
    maxWidth: Dimensions.get('window').width * 1.5,
    backgroundColor: Colors.darkBgColor,
    marginLeft: 16,
    borderRadius: 12,
    paddingHorizontal: 10,
    // paddingTop: 10,
    // paddingBottom: 0,
    flexDirection: 'row',
    // backgroundColor: 'blue',
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  cardpromotion: {
    flex: 1,
    width: 250,
    height: 100,
    // backgroundColor: Colors.primaryColor,
    backgroundColor: '#416D5C',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
  card1: {
    flex: 1,
    width: 250,
    height: 80,
    backgroundColor: Colors.fieldtBgColor,
    flexDirection: 'row',
    alignContent: 'center',
    //alignItems: 'center',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 3.22,
    elevation: 1,
  },
  cornerleft: {
    backgroundColor: 'white',
    height: 30,
    width: 30,
    alignSelf: 'flex-start',
    marginTop: '22%',
    borderTopRightRadius: 50,
  },
  cornerright: {
    backgroundColor: 'white',
    height: 30,
    width: 30,
    alignSelf: 'flex-start',
    borderBottomRightRadius: 50,

    // borderStyle: 'dashed',
    // borderWidth: 1,
    // borderColor: 'black',
  },
  nearByOutletCard: {
    // minWidth: Dimensions.get('window').width - 32,
    width: !isMobile() ? (Dimensions.get('window').width * 0.4) : Dimensions.get('window').width - 32,
    minHeight: 80,
    backgroundColor: Colors.whiteColor,
    borderRadius: 13,
    marginBottom: 16,
    marginHorizontal: 16,
    padding: 16,
    flexDirection: 'row',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  outletCard: {
    minWidth: Dimensions.get('window').width - 100,
    minHeight: 130,
    backgroundColor: Colors.darkBgColor,
    marginLeft: 16,
    borderRadius: 10,
  },
  outletNameTag: {
    backgroundColor: '#ffffff',
    alignSelf: 'flex-start',
    position: 'absolute',
    bottom: 14,
    left: 14,
    paddingVertical: 8,
    paddingHorizontal: 20,
    borderRadius: 6,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  horizontalScrollView: {
    flexDirection: 'row',
    marginVertical: 16,
  },
  tag: {
    borderColor: Colors.fieldtTxtColor,
    borderWidth: StyleSheet.hairlineWidth,
    width: 86,
    paddingVertical: 6,
    marginLeft: 16,
    alignItems: 'center',
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'center',

    // shadowColor: 'rgba(0, 0, 0, 0.25)',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  outletCover: {
    minWidth: Dimensions.get('window').width - 100,
    minHeight: 130,
    alignSelf: 'center',
    height: undefined,
    aspectRatio: 2,
    borderRadius: 10,
  },
  currentQueueFont: {
    //fontSize: 15,
    color: Colors.whiteColor,
    paddingBottom: 0,
    fontFamily: "NunitoSans-SemiBold",
  },
  currentTitle: {
    color: Colors.whiteColor,
    fontFamily: "NunitoSans-Bold",
  },
  currentReservationIcons: {
    marginTop: 3,
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
  currentReservationText: {
    color: Colors.whiteColor,
    //fontSize: 14,
    marginTop: 5,
    fontFamily: "NunitoSans-Regular"
  },
  currentImageLayout: {
    width: 90,
    height: 90,
    borderRadius: 10,
    marginBottom: 5,
  },
  currentOrderFont: {
    fontSize: 12,
    color: Colors.whiteColor,
    fontFamily: "NunitoSans-Regular",
    // width: '50%',
  },
  confirmBox: {
    width: '70%',
    height: '20%',
    borderRadius: 10,
    backgroundColor: Colors.whiteColor,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 5,
    borderBottomWidth: 10,
    borderLeftWidth: 5,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: Colors.descriptionColor,
    borderLeftColor: 'transparent',
    transform: [{ rotate: "90deg" }],
    alignSelf: 'flex-end'
  },
});
export default HomeScreen;
