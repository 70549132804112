import React, {
    Component,
    useReducer,
    useState,
    useEffect,
    useMemo,
} from 'react';
import {
    StyleSheet,
    ScrollView,
    Image,
    View,
    Modal,
    Text,
    Alert,
    Dimensions,
    TouchableOpacity,
    FlatList,
    Linking,
    ActivityIndicator,
    Platform,
    useWindowDimensions,
} from 'react-native';
import Colors from '../constant/Colors';
import SideBar from './SideBar';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import Entypo from 'react-native-vector-icons/Entypo';
import Icon from 'react-native-vector-icons/Feather';
import Icon3 from 'react-native-vector-icons/EvilIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import Icon1 from 'react-native-vector-icons/AntDesign';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Ionicon from 'react-native-vector-icons/Ionicons';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
import ApiClient from '../util/ApiClient';
import API from '../constant/API';
import AsyncStorage from '@react-native-async-storage/async-storage';
import DropDownPicker from 'react-native-dropdown-picker';
import * as User from '../util/User';
import Styles from '../constant/Styles';
import moment from 'moment';
import MIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import { OutletStore } from '../store/outletStore';
import { UserStore } from '../store/userStore';
import { MerchantStore } from '../store/merchantStore';
import { TextInput } from 'react-native-gesture-handler';
import { CommonStore } from '../store/commonStore';
import AsyncImage from '../components/asyncImage';
import Feather from 'react-native-vector-icons/Feather';
import { USER_QUEUE_STATUS, USER_QUEUE_STATUS_PARSED } from '../constant/common';
import {
    QUEUE_SORT_FIELD_TYPE,
    REPORT_SORT_FIELD_TYPE_COMPARE,
    QUEUE_SORT_FIELD_TYPE_VALUE,
    REPORT_SORT_COMPARE_OPERATOR,
} from '../constant/common';
import {
    // USBPrinter,
    NetPrinter,
    BLEPrinter,
} from 'react-native-thermal-receipt-printer-image-qr';
import {
    CODEPAGE,
    ESCPOS_CMD,
    PRINTER_USAGE_TYPE,
    PRINTER_USAGE_TYPE_DROPDOWN_LIST,
} from '../constant/printer';
import {
    connectToPrinter,
    connectToPrinterFast,
    encodeGB18030,
    encodeGBK,
    isPortOpen,
    padEndUnicodeStringV2,
    printShiftReport,
    sliceUnicodeStringV2,
} from '../util/printer';
import NetInfo from '@react-native-community/netinfo';
import Select from 'react-select';


const SettingPrinterScreen = (props) => {
    const { navigation } = props;

    const {
        height: windowHeight,
        width: windowWidth,
    } = useWindowDimensions();

    const [visible, setVisible] = useState(false);

    const [addQueueModal, setAddQueueModal] = useState(false);
    const [confirmQueueModal, setConfirmQueueModal] = useState(false);
    const [switchMerchant, setSwitchMerchant] = useState(false);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [queueCustomerName, setQueueCustomerName] = useState('');
    const [queuePhone, setQueuePhone] = useState('');
    const [queuePax, setQueuePax] = useState(0);

    const [printerName, setPrinterName] = useState('');
    const [printerIP, setPrinterIP] = useState('');
    const [printerArea, setPrinterArea] = useState('');
    const [printerTypes, setPrinterTypes] = useState([]);
    const [printerId, setPrinterId] = useState('');


    const [printerIdDict, setPrinterIdDict] = useState({});

    const [table, setTable] = useState([]);
    const [queue, setQueue] = useState([]);
    const [newReservationStatus, setNewReservationStatus] = useState(false);

    const [filterType, setFilterType] = useState(0);

    const [isScanning, setIsScanning] = useState(false);
    const [scanningPercentage, setScanningPercentage] = useState(0);



    // const [userQueues, setUserQueues] = useState([]);

    // const userQueuesRaw = OutletStore.useState(s => s.userQueues);

    const allOutletShifts = OutletStore.useState(s => s.allOutletShifts);

    const outletPrinters = OutletStore.useState((s) => s.outletPrinters);

    const currOutlet = MerchantStore.useState((s) => s.currOutlet);

    const userName = UserStore.useState((s) => s.name);
    const userId = UserStore.useState((s) => s.firebaseUid);
    const userEmail = UserStore.useState((s) => s.email);
    const merchantName = MerchantStore.useState((s) => s.name);
    const merchantLogo = MerchantStore.useState((s) => s.logo);

    const isLoading = CommonStore.useState((s) => s.isLoading);

    const outletSelectDropdownView = CommonStore.useState(
        (s) => s.outletSelectDropdownView,
    );

    //New UX
    const [controller1, setController1] = useState({});

    useEffect(() => {
        var printerIdDictTemp = {};

        for (var i = 0; i < outletPrinters.length; i++) {
            printerIdDictTemp[outletPrinters[i].uniqueId] = {
                name: outletPrinters[i].name,
                ip: outletPrinters[i].ip,
                area: outletPrinters[i].area || '',
                types: outletPrinters[i].types || [],
                uniqueId: outletPrinters[i].uniqueId || '',
            };
        }

        setPrinterIdDict(printerIdDictTemp);
    }, [outletPrinters]);

    // useEffect(() => {
    //   var userQueuesTemp = [];

    //   if (filterType == 0) { //Prioritize
    //     userQueuesTemp = userQueuesRaw;
    //   }
    //   if (filterType == 1) { //Prioritize
    //     userQueuesTemp = userQueuesRaw.filter(order => order.status === USER_QUEUE_STATUS.PENDING);
    //   }
    //   if (filterType == 2) { //orderid
    //     userQueuesTemp = userQueuesRaw.filter(order => order.status === USER_QUEUE_STATUS.ACCEPTED);
    //   }
    //   if (filterType == 3) { //date time
    //     userQueuesTemp = userQueuesRaw.filter(order => order.status === USER_QUEUE_STATUS.SEATED);
    //   }
    //   if (filterType == 4) { //Name
    //     userQueuesTemp = userQueuesRaw.filter(order => order.status === USER_QUEUE_STATUS.SERVED);
    //   }
    //   if (filterType == 5) { //Waiting Time
    //     userQueuesTemp = userQueuesRaw.filter(order => order.status === USER_QUEUE_STATUS.CANCELED);
    //   }
    //   if (filterType == 6) { //Waiting Time
    //     userQueuesTemp = userQueuesRaw.filter(order => order.status === USER_QUEUE_STATUS.NO_SHOW);
    //   }

    //   setUserQueues(userQueuesTemp);
    // }, [filterType, userQueuesRaw]);

    /* const getOutlet = () => {
      ApiClient.GET(API.outlet + User.getOutletId())
        .then((result) => {
          setState({ switchState: result[0].queueStatus });
        })
        .catch((err) => {
          console.log(err);
        });
    }; */

    const switchQueueStatus = (value) => {
        // self.onButtonClickHandler();

        var body = {
            // outletId: User.getOutletId()
            outletId: currOutlet.uniqueId,
            queueStatus: value,
        };

        ApiClient.POST(API.switchQueueStatus, body)
            .then((result) => {
                // if (result.queueStatus === true) {
                //     // Alert.alert("Queue is closed now")
                //     return self.setState({ switchState: false })
                // } else if (result.queueStatus === false) {
                //     //   Alert.alert("Queue is open now")
                //     return self.setState({ switchState: true })
                // }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    /* const getOutletQueue = () => {
      ApiClient.GET(API.getAllOutletQueue + User.getOutletId())
        .then((result) => {
          setState({ outletQueue: result });
        })
        .catch((err) => {
          console.log(err);
        });
    }; */

    const closeAllSwipeable = () => { };

    const servedForQueue = (param) => {
        var body = {
            queueId: param,
            // seated: 1,
        };

        ApiClient.POST(API.servedQueue, body, false)
            .then((result) => {
                if (result.status) {
                    // getOutletQueue()
                    Alert.alert('Success', 'Served successfully');
                    closeAllSwipeable();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const seatedForQueue = (param) => {
        var body = {
            queueId: param,
            seated: 1,
        };

        ApiClient.POST(API.seatedQueue, body, false)
            .then((result) => {
                if (result.status) {
                    // getOutletQueue()
                    Alert.alert('Success', 'Seated successfully');
                    closeAllSwipeable();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const noShowQueue = (param) => {
        var body = {
            queueId: param,
        };

        ApiClient.POST(API.noShowQueue, body, false)
            .then((result) => {
                if (result.status) {
                    // getOutletQueue()
                    Alert.alert('Success', 'Updated successfully');
                    closeAllSwipeable();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const cancelQueue = (param) => {
        var body = {
            queueId: param,
        };

        ApiClient.POST(API.cancelQueue, body, false)
            .then((result) => {
                if (result.status) {
                    // getOutletQueue()
                    Alert.alert('Success', 'Cancelled successfully');
                    closeAllSwipeable();
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const notifyForQueue = (param, userId) => {
        var body = {
            queueId: param,
            // seated: 1,
            userId: userId,
        };

        // ApiClient.POST(API.notifyQueueMember, body, false).then(result => {
        ApiClient.POST(API.notifyQueue, body, false)
            .then((result) => {
                if (result && result.status === 'success') {
                    // getOutletQueue()
                    Alert.alert('Success', 'Notified successfully');
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const createUserQueueByMerchant = () => {
        if (
            queueCustomerName.length <= 0 ||
            queuePhone.length <= 0 ||
            queuePax <= 0
        ) {
            Alert.alert(
                'Info',
                'Please fill in the following fields:\n\nCustomer Name\nPhone No\nCapacity',
            );
            return;
        } else {
            CommonStore.update((s) => {
                s.isLoading = true;
            });

            var body = {
                outletId: currOutlet.uniqueId,
                pax: queuePax,
                userId: userId,

                merchantId: currOutlet.merchantId,
                outletCover: currOutlet.cover,
                merchantLogo: merchantLogo,
                outletName: currOutlet.name,
                merchantName: merchantName,

                userName: queueCustomerName,
                userPhone: queuePhone,
                userEmail: userEmail,

                isMerchant: true,
            };

            ApiClient.POST(API.createUserQueueByMerchant, body, false)
                .then((result) => {
                    if (result.status) {
                        // getOutletQueue()
                        // Alert.alert('Success', "Queue created successfully.");

                        setSelectedQueue(result.userQueue);
                        setConfirmQueueModal(true);
                        setAddQueueModal(false);

                        closeAllSwipeable();

                        CommonStore.update((s) => {
                            s.isLoading = false;
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);

                    CommonStore.update((s) => {
                        s.isLoading = false;
                    });
                });
        }
    };

    const updateUserQueueByMerchant = () => {
        if (
            queueCustomerName.length <= 0 ||
            queuePhone.length <= 0 ||
            queuePax <= 0
        ) {
            Alert.alert(
                'Info',
                'Please fill in the following fields:\n\nCustomer Name\nPhone No\nCapacity',
            );
            return;
        } else {
            CommonStore.update((s) => {
                s.isLoading = true;
            });

            var body = {
                // outletId: currOutlet.uniqueId,
                pax: queuePax,
                // userId: userId,

                // merchantId: currOutlet.merchantId,
                // outletCover: currOutlet.cover,
                // merchantLogo: merchantLogo,
                // outletName: currOutlet.name,
                // merchantName: merchantName,

                userName: queueCustomerName,
                userPhone: queuePhone,
                // userEmail: userEmail,

                isMerchant: true,
                userQueueId: selectedQueue.uniqueId,
            };

            ApiClient.POST(API.updateUserQueueByMerchant, body, false)
                .then((result) => {
                    if (result.status) {
                        // getOutletQueue()
                        // Alert.alert('Success', "Queue created successfully.");

                        // setSelectedQueue(result.userQueue);
                        // setConfirmQueueModal(true);

                        Alert.alert('Succes', 'Queue has been updated');

                        setAddQueueModal(false);

                        closeAllSwipeable();

                        CommonStore.update((s) => {
                            s.isLoading = false;
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);

                    CommonStore.update((s) => {
                        s.isLoading = false;
                    });
                });
        }
    };

    const [queueOrders, setQueueOrders] = useState([]);

    // useEffect(() => {

    //   console.log('USER_QUEUE_STATUS_PARSED.SEATED')
    //   console.log(userQueues)

    //   userQueues.filter(order => order.status === USER_QUEUE_STATUS_PARSED.SEATED);
    // }, [userQueues]);

    // const filterOrders = (param) => {
    //   if (param.value == 0) { // Pending
    //     userQueues.filter(order => order.status === USER_QUEUE_STATUS.PENDING);
    //   }
    //   // if (param.value == 1) { //Accepted
    //   //   setQueueOrders(userQueues.filter(order => order.status === STATUS.ACCEPTED));
    //   // }

    //   // if (param.value == 2) { //Seated
    //   //   setDineInOrders(userOrders.filter(order => order.orderType === STATUS.SEATED));
    //   // }

    //   // if (param.value == 3) { //Served
    //   //   setDineInOrders(userOrders.filter(order => order.orderType === STATUS.SERVED));
    //   // }

    //   // if (param.value == 4) { //Rejected
    //   //   setDineInOrders(userOrders.filter(order => order.orderType === STATUS.REJECTED));
    //   // }
    //   // {USER_QUEUE_STATUS_PARSED[item.status]}
    //   // if (param.value == 5) { //No Show
    //   //   setSelectedQueue(selectedQueue.filter(order => order.orderType === STATUS.REJECTED));
    //   // }
    // }

    const [sort, setSort] = useState('');
    const [search, setSearch] = useState('');

    //Start Here Sorting

    const sortOperationQueue = (dataList, queueSortFieldType) => {
        var dataListTemp = [...dataList];
        console.log('dataList');
        console.log(dataList);

        console.log('queueSortFieldType');
        console.log(queueSortFieldType);

        const queueSortFieldTypeValue =
            QUEUE_SORT_FIELD_TYPE_VALUE[queueSortFieldType];

        const queueSortFieldTypeCompare =
            REPORT_SORT_FIELD_TYPE_COMPARE[queueSortFieldType];

        //QUEUE_ID
        if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.QUEUE_ID_ASC) {
                dataListTemp.sort(
                    (a, b) =>
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
                );
            } else {
                dataListTemp.sort(
                    (a, b) =>
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
                );
            }
        } else if (
            queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
        ) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.QUEUE_ID_DESC) {
                dataListTemp.sort(
                    (a, b) =>
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
                );
            } else {
                dataListTemp.sort(
                    (a, b) =>
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
                );
            }
        }

        //NAME
        if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.NAME_ASC) {
                dataListTemp.sort((a, b) =>
                    (a[queueSortFieldTypeValue]
                        ? a[queueSortFieldTypeValue]
                        : ''
                    ).localeCompare(
                        b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '',
                    ),
                );
            } else {
                dataListTemp.sort(
                    (a, b) =>
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
                );
            }
        } else if (
            queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
        ) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.NAME_DESC) {
                dataListTemp.sort((a, b) =>
                    (b[queueSortFieldTypeValue]
                        ? b[queueSortFieldTypeValue]
                        : ''
                    ).localeCompare(
                        a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '',
                    ),
                );
            } else {
                dataListTemp.sort(
                    (a, b) =>
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
                );
            }
        }

        //DATE_TIME
        if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.DATE_TIME_ASC) {
                dataListTemp.sort(
                    (a, b) =>
                        (moment(a[queueSortFieldTypeValue]).valueOf()
                            ? moment(a[queueSortFieldTypeValue]).valueOf()
                            : '') -
                        (moment(b[queueSortFieldTypeValue]).valueOf()
                            ? moment(b[queueSortFieldTypeValue]).valueOf()
                            : ''),
                );
            } else {
                dataListTemp.sort(
                    (a, b) =>
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
                );
            }
        } else if (
            queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
        ) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.DATE_TIME_DESC) {
                dataListTemp.sort(
                    (a, b) =>
                        (moment(b[queueSortFieldTypeValue]).valueOf()
                            ? moment(b[queueSortFieldTypeValue]).valueOf()
                            : '') -
                        (moment(a[queueSortFieldTypeValue]).valueOf()
                            ? moment(a[queueSortFieldTypeValue]).valueOf()
                            : ''),
                );
            } else {
                dataListTemp.sort(
                    (a, b) =>
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
                );
            }
        }

        //CAPACITY
        if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.CAPACITY_ASC) {
                dataListTemp.sort(
                    (a, b) =>
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
                );
            } else {
                dataListTemp.sort(
                    (a, b) =>
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
                );
            }
        } else if (
            queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
        ) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.CAPACITY_DESC) {
                dataListTemp.sort(
                    (a, b) =>
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
                );
            } else {
                dataListTemp.sort(
                    (a, b) =>
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
                );
            }
        }

        //WAITING_TIME
        if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.WAITING_TIME_ASC) {
                dataListTemp.sort(
                    (a, b) =>
                        (moment(a[queueSortFieldTypeValue]).valueOf()
                            ? moment(a[queueSortFieldTypeValue]).valueOf()
                            : '') -
                        (moment(b[queueSortFieldTypeValue]).valueOf()
                            ? moment(b[queueSortFieldTypeValue]).valueOf()
                            : ''),
                );
            }
        } else if (
            queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
        ) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.WAITING_TIME_DESC) {
                dataListTemp.sort(
                    (a, b) =>
                        (moment(b[queueSortFieldTypeValue]).valueOf()
                            ? moment(b[queueSortFieldTypeValue]).valueOf()
                            : '') -
                        (moment(a[queueSortFieldTypeValue]).valueOf()
                            ? moment(a[queueSortFieldTypeValue]).valueOf()
                            : ''),
                );
            }
        }

        //STATUS
        if (queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.ASC) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.STATUS_ASC) {
                dataListTemp.sort((a, b) =>
                    (a[queueSortFieldTypeValue]
                        ? a[queueSortFieldTypeValue]
                        : ''
                    ).localeCompare(
                        b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '',
                    ),
                );
            } else {
                dataListTemp.sort(
                    (a, b) =>
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '') -
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : ''),
                );
            }
        } else if (
            queueSortFieldTypeCompare === REPORT_SORT_COMPARE_OPERATOR.DESC
        ) {
            if (queueSortFieldType === QUEUE_SORT_FIELD_TYPE.STATUS_DESC) {
                dataListTemp.sort((a, b) =>
                    (b[queueSortFieldTypeValue]
                        ? b[queueSortFieldTypeValue]
                        : ''
                    ).localeCompare(
                        a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : '',
                    ),
                );
            } else {
                dataListTemp.sort(
                    (a, b) =>
                        (b[queueSortFieldTypeValue] ? b[queueSortFieldTypeValue] : '') -
                        (a[queueSortFieldTypeValue] ? a[queueSortFieldTypeValue] : ''),
                );
            }
        }

        return dataListTemp;
    };

    // navigation.dangerouslyGetParent().setOptions({
    //   tabBarVisible: false,
    // });

    navigation.setOptions({
        headerLeft: () => (
            <View style={styles.headerLeftStyle}>
                <Image
                    style={{
                        width: 124,
                        height: 26,
                    }}
                    resizeMode="contain"
                    source={require('../assets/image/logo.png')}
                />
            </View>
        ),
        headerTitle: () => (
            <View
                style={[
                    {
                        justifyContent: 'center',
                        alignItems: 'center',
                        // bottom: -2,
                        bottom: switchMerchant ? '2%' : 0,
                    },
                    Dimensions.get('screen').width >= 768 && switchMerchant
                        ? { right: Dimensions.get('screen').width * 0.1 }
                        : {},
                    Dimensions.get('screen').width <= 768
                        ? { right: Dimensions.get('screen').width * 0.12 }
                        : {},
                ]}>
                <Text
                    style={{
                        fontSize: switchMerchant ? 20 : 24,
                        // lineHeight: 25,
                        textAlign: 'center',
                        fontFamily: 'NunitoSans-Bold',
                        color: Colors.whiteColor,
                        opacity: 1,
                    }}>
                    Printer Settings
                </Text>
            </View>
        ),
        headerRight: () => (
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}>
                {outletSelectDropdownView()}
                <View
                    style={{
                        backgroundColor: 'white',
                        width: 0.5,
                        height: Dimensions.get('screen').height * 0.025,
                        opacity: 0.8,
                        marginHorizontal: 15,
                        bottom: -1,
                    }}></View>
                <TouchableOpacity
                    onPress={() => navigation.navigate('Setting')}
                    style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text
                        style={{
                            fontFamily: 'NunitoSans-SemiBold',
                            fontSize: 16,
                            color: Colors.secondaryColor,
                            marginRight: 15,
                        }}>
                        {userName}
                    </Text>
                    <View
                        style={{
                            marginRight: 30,
                            width: Dimensions.get('screen').height * 0.05,
                            height: Dimensions.get('screen').height * 0.05,
                            borderRadius: Dimensions.get('screen').height * 0.05 * 0.5,
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'white',
                        }}>
                        <Image
                            style={{
                                width: Dimensions.get('screen').height * 0.035,
                                height: Dimensions.get('screen').height * 0.035,
                                alignSelf: 'center',
                            }}
                            source={require('../assets/image/profile-pic.jpg')}
                        />
                    </View>
                </TouchableOpacity>
            </View>
        ),
    });

    const rightAction = (item, index) => {
        return (
            <View
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    //width: '32%',
                }}>
                <TouchableOpacity
                    onPress={() => {
                        // removeFromCartItems(item);

                        // noShowQueue(item.uniqueId);

                        notifyForQueue(item.uniqueId, item.userId);
                    }}
                    style={{
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // alignContent: 'center',
                        // alignSelf: "center",
                        backgroundColor: Colors.primaryColor,
                        underlayColor: 'rgba(0, 0, 0, 1, 0.6)',
                        // paddingBottom: 6,
                        width: 75,
                    }}>
                    <MIcon
                        name={'bell-ring-outline'}
                        size={40}
                        color={Colors.whiteColor}
                    />

                    <Text
                        style={{
                            color: Colors.whiteColor,
                            fontSize: 12,
                            fontFamily: 'NunitoSans-Regular',
                            textAlign: 'center',
                            width: '80%',
                        }}>
                        Notify
                    </Text>
                </TouchableOpacity>

                <TouchableOpacity
                    onPress={() => {
                        // removeFromCartItems(item);

                        // noShowQueue(item.uniqueId);

                        seatedForQueue(item.uniqueId);
                    }}
                    style={{
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // alignContent: 'center',
                        // alignSelf: "center",
                        backgroundColor: Colors.secondaryColor,
                        underlayColor: 'rgba(0, 0, 0, 1, 0.6)',
                        // paddingBottom: 6,
                        width: 75,
                    }}>
                    <MIcon name="seat-outline" size={40} color={Colors.whiteColor} />

                    <Text
                        style={{
                            color: Colors.whiteColor,
                            fontSize: 12,
                            fontFamily: 'NunitoSans-Regular',
                            textAlign: 'center',
                            width: '80%',
                        }}>
                        Seated
                    </Text>
                </TouchableOpacity>

                <TouchableOpacity
                    onPress={() => {
                        // removeFromCartItems(item);

                        // noShowQueue(item.uniqueId);

                        servedForQueue(item.uniqueId);
                    }}
                    style={{
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // alignContent: 'center',
                        // alignSelf: "center",
                        backgroundColor: '#00B1E1',
                        underlayColor: 'rgba(0, 0, 0, 1, 0.6)',
                        // paddingBottom: 6,
                        width: 75,
                    }}>
                    <MIcon
                        name="room-service-outline"
                        size={40}
                        color={Colors.whiteColor}
                    />

                    <Text
                        style={{
                            color: Colors.whiteColor,
                            fontSize: 12,
                            fontFamily: 'NunitoSans-Regular',
                            textAlign: 'center',
                            width: '80%',
                        }}>
                        Served
                    </Text>
                </TouchableOpacity>

                <TouchableOpacity
                    onPress={() => {
                        // removeFromCartItems(item);

                        noShowQueue(item.uniqueId);
                    }}
                    style={{
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // alignContent: 'center',
                        // alignSelf: "center",
                        backgroundColor: Colors.tabGrey,
                        underlayColor: 'rgba(0, 0, 0, 1, 0.6)',
                        // paddingBottom: 6,
                        width: 75,
                    }}>
                    <Feather name="alert-triangle" size={40} color={Colors.whiteColor} />

                    <Text
                        style={{
                            color: Colors.whiteColor,
                            fontSize: 12,
                            fontFamily: 'NunitoSans-Regular',
                            textAlign: 'center',
                            width: '80%',
                        }}>
                        No-Show
                    </Text>
                </TouchableOpacity>

                <TouchableOpacity
                    onPress={() => {
                        // removeFromCartItems(item);

                        cancelQueue(item.uniqueId);
                    }}
                    style={{
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // alignContent: 'center',
                        // alignSelf: "center",
                        backgroundColor: Colors.tabRed,
                        underlayColor: 'rgba(0, 0, 0, 1, 0.6)',
                        // paddingBottom: 6,
                        width: 75,
                    }}>
                    {/* <MaterialCommunityIcons
              name="message-alert-outline"
              size={40}
              color={Colors.whiteColor}
              style={{ marginTop: 10 }}
            /> */}

                    <FontAwesome name="trash-o" size={40} color={Colors.whiteColor} />

                    <Text
                        style={{
                            color: Colors.whiteColor,
                            fontSize: 12,
                            fontFamily: 'NunitoSans-Regular',
                            textAlign: 'center',
                            width: '80%',
                        }}>
                        Remove
                    </Text>
                </TouchableOpacity>
            </View>
        );
    };

    // const renderPrinter = ({ item, index }) => {
    //   // var dys = Math.floor(moment().diff(item.createdAt, 'hours') / 24);
    //   // var hrs = Math.floor((moment().diff(item.createdAt, 'minutes') / 60) % 24);
    //   // var mins = Math.floor(moment().diff(item.createdAt, 'minutes') % 60);

    //   return (
    //     <View
    //       style={{
    //         paddingVertical: 5,
    //         shadowOffset: {
    //           width: 0,
    //           height: 2,
    //         },
    //         shadowOpacity: 0.22,
    //         shadowRadius: 3.22,
    //         elevation: 1,
    //       }}>
    //       {/* <Swipeable
    //         renderRightActions={() => rightAction(item)}
    //         // ref={refArray[index]}
    //         onSwipeableWillOpen={() => {
    //         }}> */}
    //       <View
    //         style={{
    //           // elevation: 1,
    //           borderRadius: 5,
    //           // backgroundColor: 'white',
    //           // height: 300,
    //           paddingHorizontal: 5,
    //           paddingTop: switchMerchant ? 5 : 0,
    //         }}>
    //         <View
    //           style={{
    //             flexDirection: 'row',
    //             height: switchMerchant
    //               ? Dimensions.get('screen').height * 0.1
    //               : Dimensions.get('screen').height * 0.06,
    //             alignItems: 'center',
    //             borderBottomColor: Colors.fieldtBgColor,
    //             width: switchMerchant
    //               ? Dimensions.get('screen').width * 0.8
    //               : '100%',
    //             alignSelf: 'center',
    //           }}>
    //           <View
    //             style={{
    //               width: switchMerchant ? '12%' : '14%',
    //               marginHorizontal: switchMerchant ? 0 : 4,
    //             }}>
    //             <TextInput
    //               style={{
    //                 color: Colors.fontDark,
    //                 fontSize: switchMerchant ? 10 : 16,
    //                 fontFamily: 'NunitoSans-Bold',
    //                 borderWidth: 1,
    //                 borderRadius: 5,
    //                 borderColor: '#E5E5E5',
    //                 paddingLeft: switchMerchant ? 5 : 5,
    //                 height: 40,
    //                 width: switchMerchant ? 98 : Platform.OS === 'ios' ? Dimensions.get('screen').width * 0.1 : 150,
    //               }}
    //               placeholder={'Printer Name'}
    //               placeholderTextColor={Colors.descriptionColor}
    //               defaultValue={
    //                 printerIdDict[item.uniqueId]
    //                   ? printerIdDict[item.uniqueId].name
    //                   : ''
    //               }
    //               onChangeText={(text) => {
    //                 setPrinterIdDict({
    //                   ...printerIdDict,
    //                   [item.uniqueId]: {
    //                     ...printerIdDict[item.uniqueId],
    //                     name: text,
    //                   },
    //                 });
    //               }}></TextInput>
    //           </View>
    //           <View style={{ width: '18%', marginHorizontal: 4 }}>
    //             {/* <Text style={{ color: Colors.fontDark, fontSize: 16, fontFamily: 'NunitoSans-Bold' }}>
    //                 168.88.8888.88
    //               </Text> */}

    //             <TextInput
    //               style={{
    //                 color: Colors.fontDark,
    //                 fontSize: switchMerchant ? 10 : 16,
    //                 fontFamily: 'NunitoSans-Bold',
    //                 borderWidth: 1,
    //                 borderRadius: 5,
    //                 borderColor: '#E5E5E5',
    //                 paddingLeft: 5,
    //                 height: 40,
    //                 width: switchMerchant ? 127 : 140,
    //               }}
    //               placeholder={'Printer IP'}
    //               placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
    //               keyboardType={'number-pad'}
    //               defaultValue={
    //                 printerIdDict[item.uniqueId]
    //                   ? printerIdDict[item.uniqueId].ip
    //                   : ''
    //               }
    //               onChangeText={(text) => {
    //                 setPrinterIdDict({
    //                   ...printerIdDict,
    //                   [item.uniqueId]: {
    //                     ...printerIdDict[item.uniqueId],
    //                     ip: text,
    //                   },
    //                 });
    //               }}></TextInput>
    //           </View>
    //           <View
    //             style={{
    //               width: switchMerchant ? '8%' : '8%',
    //               marginHorizontal: switchMerchant ? 0 : 4,
    //             }}>
    //             <TextInput
    //               style={{
    //                 color: Colors.fontDark,
    //                 fontSize: switchMerchant ? 10 : 16,
    //                 fontFamily: 'NunitoSans-Bold',
    //                 borderWidth: 1,
    //                 borderRadius: 5,
    //                 borderColor: '#E5E5E5',
    //                 paddingLeft: switchMerchant ? 5 : 5,
    //                 height: 40,
    //                 width: switchMerchant ? 58 : Platform.OS === 'ios' ? Dimensions.get('screen').width * 0.06 : 80,
    //               }}
    //               placeholder={'Kitchen'}
    //               placeholderTextColor={Colors.descriptionColor}
    //               defaultValue={
    //                 printerIdDict[item.uniqueId]
    //                   ? printerIdDict[item.uniqueId].area
    //                   : ''
    //               }
    //               onChangeText={(text) => {
    //                 setPrinterIdDict({
    //                   ...printerIdDict,
    //                   [item.uniqueId]: {
    //                     ...printerIdDict[item.uniqueId],
    //                     area: text,
    //                   },
    //                 });
    //               }}></TextInput>
    //           </View>

    //           <View style={{
    //             width: switchMerchant ? '14%' : '14%',
    //             marginHorizontal: switchMerchant ? 4 : 4,
    //             zIndex: 10001 - index,
    //           }}>
    //             <DropDownPicker
    //               containerStyle={[{ height: 40 }, switchMerchant ? { height: 35, } : {}]}
    //               arrowColor={'black'}
    //               arrowSize={20}
    //               arrowStyle={{ fontWeight: 'bold' }}
    //               labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: 14 }}
    //               style={[{ width: '90%', paddingVertical: 0, backgroundColor: Colors.fieldtBgColor, borderRadius: 10 }, switchMerchant ? {
    //                 width: '90%',
    //               } : {}]}
    //               placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: 14 }}
    //               //items={[{ label: 'Dine In', value: 1 }, { label: 'Takeaway', value: 2 }, { label: 'Delivery', value: 3 } ]}
    //               items={PRINTER_USAGE_TYPE_DROPDOWN_LIST}
    //               itemStyle={{ justifyContent: 'flex-start', marginLeft: 5, zIndex: 2, fontFamily: 'NunitoSans-Regular', fontSize: 14 }}
    //               defaultValue={printerIdDict[item.uniqueId]
    //                 ? printerIdDict[item.uniqueId].types
    //                 : []}
    //               placeholder={"Type"}
    //               multipleText={'%d type(s) selected'}
    //               multiple={true}
    //               customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
    //               onChangeItem={(items) => {
    //                 setPrinterIdDict({
    //                   ...printerIdDict,
    //                   [item.uniqueId]: {
    //                     ...printerIdDict[item.uniqueId],
    //                     types: items,
    //                   },
    //                 });
    //               }}
    //               dropDownMaxHeight={100}
    //               dropDownStyle={[{ width: '90%', height: 100, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1, }, switchMerchant ? {
    //                 width: '90%',
    //               } : {}]}
    //               globalTextStyle={{
    //                 fontSize: switchMerchant ? 10 : 14,
    //               }}
    //             />
    //           </View>

    //           <View style={{ width: '14%', marginHorizontal: 4 }}>
    //             <TouchableOpacity
    //               style={{
    //                 height: 35,
    //                 width: 100,
    //                 borderColor: '#E5E5E5',
    //                 borderWidth: 1,
    //                 borderRadius: 5,
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //                 borderColor: Colors.primaryColor,
    //                 backgroundColor: Colors.primaryColor,
    //               }}
    //               onPress={() => {
    //                 updateOutletPrinter(item.uniqueId);

    //                 // bindPrinter(
    //                 //   printerIdDict[item.uniqueId]
    //                 //     ? printerIdDict[item.uniqueId].ip
    //                 //     : '',

    //                 bindPrinter(
    //                   printerIdDict[item.uniqueId]
    //                 );
    //               }}>
    //               <Text
    //                 style={{
    //                   color: Colors.whiteColor,
    //                   fontSize: switchMerchant ? 10 : 16,
    //                   fontFamily: 'NunitoSans-Bold',
    //                 }}>
    //                 BIND
    //               </Text>
    //             </TouchableOpacity>
    //           </View>
    //           {/* <View style={{ width: '14%', marginHorizontal: 4 }}>
    //             <TouchableOpacity
    //               style={{
    //                 height: 35,
    //                 width: 100,
    //                 borderColor: '#E5E5E5',
    //                 borderWidth: 1,
    //                 borderRadius: 5,
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //                 borderColor: Colors.tabCyan,
    //                 backgroundColor: Colors.tabCyan,
    //               }}
    //               onPress={() => {
    //                 unbindPrinter();
    //               }}>
    //               <Text
    //                 style={{
    //                   color: Colors.whiteColor,
    //                   fontSize: switchMerchant ? 10 : 16,
    //                   fontFamily: 'NunitoSans-Bold',
    //                 }}>
    //                 UNBIND
    //               </Text>
    //             </TouchableOpacity>
    //           </View> */}
    //           <View style={{ width: '14%', marginHorizontal: 4 }}>
    //             <TouchableOpacity
    //               style={{
    //                 height: 35,
    //                 width: 100,
    //                 borderColor: '#E5E5E5',
    //                 borderWidth: 1,
    //                 borderRadius: 5,
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //                 borderColor: Colors.tabGold,
    //                 backgroundColor: Colors.tabGold,
    //               }}
    //               onPress={() => {
    //                 testPrinter();
    //               }}>
    //               <Text
    //                 style={{
    //                   color: Colors.whiteColor,
    //                   fontSize: switchMerchant ? 10 : 16,
    //                   fontFamily: 'NunitoSans-Bold',
    //                 }}>
    //                 TEST
    //               </Text>
    //             </TouchableOpacity>
    //           </View>
    //           <View style={{ width: '14%', marginHorizontal: 4 }}>
    //             <TouchableOpacity
    //               style={{
    //                 height: 35,
    //                 width: 100,
    //                 borderColor: '#E5E5E5',
    //                 borderWidth: 1,
    //                 borderRadius: 5,
    //                 alignItems: 'center',
    //                 justifyContent: 'center',
    //                 borderColor: Colors.tabRed,
    //                 backgroundColor: Colors.tabRed,
    //               }}
    //               onPress={() => {
    //                 Alert.alert(
    //                   'Info',
    //                   'Are you sure you want to remove this printer?',
    //                   [
    //                     {
    //                       text: 'Yes',
    //                       onPress: () => {
    //                         deleteOutletPrinter(item.uniqueId);
    //                       },
    //                     },
    //                   ],
    //                   { cancelable: false },
    //                 );
    //               }}>
    //               <Text
    //                 style={{
    //                   color: Colors.whiteColor,
    //                   fontSize: switchMerchant ? 10 : 16,
    //                   fontFamily: 'NunitoSans-Bold',
    //                 }}>
    //                 REMOVE
    //               </Text>
    //             </TouchableOpacity>
    //           </View>
    //         </View>
    //       </View>
    //       {/* </Swipeable> */}
    //     </View >
    //   );
    // };

    /* const viewNote = (rowData) => {
      props.navigator.push({
        title: 'The Note',
        component: ViewNote,
        passProps: {
          noteText: rowData,
          noteId: noteId(rowData),
        },
      });
    }; */

    const createOutletPrinter = () => {
        if (printerIP.length === 0 || printerName.length === 0) {
            Alert.alert('Info', 'Printer name and ip address cannot be empty');
            return;
        }

        if (printerArea.length === 0) {
            Alert.alert('Info', 'Printer area cannot be empty');
            return;
        }

        // if (printerTypes.length === 0) {
        //   Alert.alert('Info', 'Printer type(s) cannot be empty');
        //   return;
        // }

        var isExisted = false;

        for (var i = 0; i < outletPrinters.length; i++) {
            if (
                outletPrinters[i].ip === printerIP &&
                outletPrinters[i].name === printerName &&
                outletPrinters[i].uniqueId !== printerId
            ) {
                isExisted = true;
                break;
            }
        }

        if (isExisted) {
            Alert.alert('Info', 'Printer with the same name/ip exists');
            return;
        } else {
            CommonStore.update((s) => {
                s.isLoading = true;
            });

            var body = {
                outletId: currOutlet.uniqueId,
                merchantId: currOutlet.merchantId,
                printerName: printerName,
                printerIP: printerIP,
                printerArea: printerArea,

                printerTypes: printerTypes,
            };

            ApiClient.POST(API.createOutletPrinter, body, false)
                .then((result) => {
                    if (result.status) {
                        // getOutletQueue()
                        // Alert.alert('Success', "Queue created successfully.");

                        // setSelectedQueue(result.userQueue);
                        // setConfirmQueueModal(true);
                        setAddQueueModal(false);

                        closeAllSwipeable();

                        Alert.alert('Success', 'Printer has been added');

                        CommonStore.update((s) => {
                            s.isLoading = false;
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);

                    CommonStore.update((s) => {
                        s.isLoading = false;
                    });
                });
        }
    };

    const updateOutletPrinter = (printerId) => {
        var printerIPTemp = printerIdDict[printerId]
            ? printerIdDict[printerId].ip
            : '';
        var printerNameTemp = printerIdDict[printerId]
            ? printerIdDict[printerId].name
            : '';
        var printerAreaTemp = printerIdDict[printerId]
            ? printerIdDict[printerId].area
            : '';
        var printerTypesTemp = printerIdDict[printerId]
            ? printerIdDict[printerId].types
            : [];

        if (printerIPTemp.length === 0 || printerNameTemp.length === 0) {
            Alert.alert('Info', 'Printer name and ip address cannot be empty');
            return;
        }

        if (printerAreaTemp.length === 0) {
            Alert.alert('Info', 'Printer area cannot be empty');
            return;
        }

        if (printerTypesTemp.length === 0) {
            Alert.alert('Info', 'Printer types(s) cannot be empty');
            return;
        }

        var isExisted = false;

        // for (var i = 0; i < outletPrinters.length; i++) {
        //   if (
        //     outletPrinters[i].ip === printerIPTemp &&
        //     outletPrinters[i].name === printerNameTemp &&
        //     outletPrinters[i].uniqueId !== printerId
        //   ) {
        //     isExisted = true;
        //     break;
        //   }
        // }

        if (isExisted) {
            Alert.alert('Info', 'Printer with the same name and ip exists');
            return;
        } else {
            CommonStore.update((s) => {
                s.isLoading = true;
            });

            var body = {
                outletId: currOutlet.uniqueId,
                merchantId: currOutlet.merchantId,
                printerName: printerNameTemp,
                printerIP: printerIPTemp,
                printerId: printerId,
                printerArea: printerAreaTemp,
                printerTypes: printerTypesTemp,
            };

            ApiClient.POST(API.updateOutletPrinter, body, false)
                .then((result) => {
                    if (result.status) {
                        // getOutletQueue()
                        // Alert.alert('Success', "Queue created successfully.");

                        // setSelectedQueue(result.userQueue);
                        // setConfirmQueueModal(true);
                        // setAddQueueModal(false);

                        Alert.alert('Success', 'Printer has been updated');

                        closeAllSwipeable();

                        CommonStore.update((s) => {
                            s.isLoading = false;
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);

                    CommonStore.update((s) => {
                        s.isLoading = false;
                    });
                });
        }
    };

    const deleteOutletPrinter = (printerId) => {
        CommonStore.update((s) => {
            s.isLoading = true;
        });

        var body = {
            printerId: printerId,
        };

        ApiClient.POST(API.deleteOutletPrinter, body, false)
            .then(async (result) => {
                if (result.status) {
                    // getOutletQueue()
                    // Alert.alert('Success', "Queue created successfully.");

                    // setSelectedQueue(result.userQueue);
                    // setConfirmQueueModal(true);
                    // setAddQueueModal(false);

                    //////////////////////////////////////////

                    // remove from printer list, add/update obj as well

                    const printerListRaw = await AsyncStorage.getItem('printerList');

                    var printerList = [];
                    if (printerListRaw) {
                        printerList = JSON.parse(printerListRaw);
                    }

                    printerList = printerList.filter(
                        (printerIdTemp) => printerIdTemp !== printerId,
                    );

                    await AsyncStorage.removeItem(printerId);

                    await AsyncStorage.setItem(
                        'printerList',
                        JSON.stringify(printerList),
                    );

                    Alert.alert('Success', 'Printer has been removed');

                    closeAllSwipeable();

                    CommonStore.update((s) => {
                        s.isLoading = false;
                    });
                }
            })
            .catch((err) => {
                console.log(err);

                CommonStore.update((s) => {
                    s.isLoading = false;
                });
            });
    };

    const bindPrinter = async (printerTemp) => {
        if (
            printerTemp &&
            printerTemp.ip &&
            printerTemp.ip.length > 0 &&
            printerTemp.area &&
            printerTemp.types.length > 0
        ) {
            try {
                await AsyncStorage.setItem('printerIP', printerTemp.ip);

                await AsyncStorage.setItem(printerTemp.area, printerTemp.ip);

                //////////////////////////////////////////

                // add to printer list, add/update obj as well

                const printerListRaw = await AsyncStorage.getItem('printerList');

                var printerList = [];
                if (printerListRaw) {
                    printerList = JSON.parse(printerListRaw);
                }

                if (printerList.includes(printerTemp.uniqueId)) {
                    await AsyncStorage.setItem(
                        printerTemp.uniqueId,
                        JSON.stringify({
                            ip: printerTemp.ip,
                            area: printerTemp.area,
                            types: printerTemp.types,
                        }),
                    );
                } else {
                    await AsyncStorage.setItem(
                        printerTemp.uniqueId,
                        JSON.stringify({
                            ip: printerTemp.ip,
                            area: printerTemp.area,
                            types: printerTemp.types,
                        }),
                    );

                    printerList.push(printerTemp.uniqueId);

                    await AsyncStorage.setItem(
                        'printerList',
                        JSON.stringify(printerList),
                    );
                }

                //////////////////////////////////////////

                const result = await connectToPrinter();

                if (result) {
                    // Alert.alert('Success', 'IP has been binded');
                } else {
                    // Alert.alert('Error', 'Unable to bind the IP');
                }
            } catch (ex) {
                console.log(ex);
            }
        } else {
            Alert.alert('Error', 'Invalid IP address, area name and type(s).');
        }
    };

    const unbindPrinter = async () => {
        try {
            await AsyncStorage.removeItem('printerIP');

            Alert.alert('Succes', 'IP has been unbinded');
        } catch (ex) {
            console.log(ex);

            Alert.alert('Error', 'Failed to unbind the IP');
        }
    };

    const testPrinter = async (printerTemp) => {
        console.log('test printer');
        CommonStore.update((s) => { s.isLoading = true })

        // await connectToPrinter();
        await connectToPrinter(printerTemp.ip || '');

        var testItems = [
            {
                // name: 'Coconut Coffee',
                name: '椰子口味咖啡 Coconut Flavored Coffee',
                remarks: '少糖 Less Sugar',
                price: 8,
                discount: 0,
                quantity: 1,
                subtotal: 8,
                tax: 0,
                sc: 0,
            },
            {
                name: 'Yogurt Coffee',
                remarks: 'LESS ICE',
                price: 8,
                discount: 0,
                quantity: 1,
                subtotal: 8,
                tax: 0,
                sc: 0,
            },
        ];

        try {
            for (var index = 0; index < printerTemp.types.length; index++) {
                if (printerTemp.types[index] === PRINTER_USAGE_TYPE.RECEIPT) {
                    const koodooLogoUrl = await AsyncStorage.getItem('koodooLogoUrl');

                    const merchantLogoUrl = await AsyncStorage.getItem('merchantLogoUrl');
                    if (merchantLogoUrl) {
                        await NetPrinter.printImage(merchantLogoUrl);
                    }

                    const currOutletLocalRaw = await AsyncStorage.getItem('currOutlet');
                    var currOutletLocal = null;
                    if (currOutletLocalRaw) {
                        currOutletLocal = JSON.parse(currOutletLocalRaw);
                    }

                    var result = `${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.UNDERLINE_OFF}${ESCPOS_CMD.BOLD_OFF}`;
                    result += `${ESCPOS_CMD.CENTER}${currOutlet.name}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.CENTER}${currOutlet.address}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.CENTER}${currOutlet.phone}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.CENTER}${merchantName}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}SST ID No. 0012612771${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}ORDER #38${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[
                        ...Array(48),
                    ]
                        .map((i) => '-')
                        .join('')}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}Mark${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}0127148876${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'Receipt Date'.padEnd(
                        12,
                        ' ',
                    )} : ${moment().format('ddd, MMM D, YYYY')}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'Receipt #'.padEnd(
                        12,
                        ' ',
                    )} : ${'38'}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'Cashier'.padEnd(
                        12,
                        ' ',
                    )} : ${'Sophie Kim'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'ITEM'.padEnd(
                        20,
                        ' ',
                    )}${'PRICE'.padEnd(8, ' ')}${'DISC'.padEnd(8, ' ')}${'QTY'.padEnd(
                        4,
                        ' ',
                    )}${'SUB'.padStart(8, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                        .map((i) => '-')
                        .join('')}${ESCPOS_CMD.NEWLINE}`;

                    for (var i = 0; i < testItems.length; i++) {
                        const testItem = testItems[i];

                        // result += `${ESCPOS_CMD.LEFT}${
                        //   // encodeGB18030(testItem.name)
                        //   // testItem.name
                        //   //   .slice(0, 20)
                        //   padEndUnicodeStringV2(sliceUnicodeStringV2(testItem.name, 0, 19), 20, ' ')
                        //   // .padEnd(20, ' ')
                        //   }${(
                        //     '' +
                        //     testItem.price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        //   ).padEnd(8, ' ')}${(
                        //     '' +
                        //     testItem.discount.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        //   ).padEnd(8, ' ')}${testItem.quantity.toString().padEnd(4, ' ')}${(
                        //     '' +
                        //     testItem.subtotal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        //   ).padStart(8, ' ')}${ESCPOS_CMD.NEWLINE}`;

                        result += `${ESCPOS_CMD.LEFT}${
                            // encodeGB18030(testItem.name)
                            // testItem.name
                            //   .slice(0, 20)
                            padEndUnicodeStringV2(
                                sliceUnicodeStringV2(testItem.name, 42),
                                44,
                                ' ',
                            )
                            // .padEnd(20, ' ')
                            }${ESCPOS_CMD.NEWLINE}`;

                        if (testItem.remarks) {
                            result += `${ESCPOS_CMD.LEFT}${
                                // testItem.remarks
                                //   .slice(0, 20)
                                padEndUnicodeStringV2(
                                    sliceUnicodeStringV2(testItem.remarks, 42),
                                    44,
                                    ' ',
                                )
                                // .padEnd(20, ' ')
                                }${ESCPOS_CMD.NEWLINE}`;
                        }

                        result += `${ESCPOS_CMD.LEFT}${
                            // encodeGB18030(testItem.name)
                            // testItem.name
                            //   .slice(0, 20)
                            ''.padEnd(20, ' ')
                            // .padEnd(20, ' ')
                            }${(
                                '' +
                                testItem.price
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                            ).padEnd(8, ' ')}${(
                                '' +
                                testItem.discount
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                            ).padEnd(8, ' ')}${testItem.quantity.toString().padEnd(4, ' ')}${(
                                '' +
                                testItem.subtotal
                                    .toFixed(2)
                                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                            ).padStart(8, ' ')}${ESCPOS_CMD.NEWLINE}`;

                        if (i !== testItems.length - 1) {
                            result += `${ESCPOS_CMD.NEWLINE}`;
                        }
                    }

                    result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                        .map((i) => '-')
                        .join('')}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'Subtotal'.padEnd(24, ' ')}${(
                        'RM ' +
                        testItems
                            .reduce((accum, item) => accum + item.subtotal, 0)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    ).padStart(24, ' ')}`;
                    result += `${ESCPOS_CMD.LEFT}${'Discount'.padEnd(24, ' ')}${(
                        'RM ' +
                        testItems
                            .reduce((accum, item) => accum + item.discount, 0)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    ).padStart(24, ' ')}`;

                    if (currOutletLocal && currOutletLocal.taxActive) {
                        result += `${ESCPOS_CMD.LEFT}${`Tax (${(currOutletLocal.taxRate * 100).toFixed(0)}%)`.padEnd(24, ' ')}${(
                            'RM ' +
                            testItems
                                .reduce((accum, item) => accum + (item.subtotal * currOutletLocal.taxRate), 0)
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    }

                    if (currOutletLocal && currOutletLocal.scActive) {
                        result += `${ESCPOS_CMD.LEFT}${`Service Charge (${(currOutletLocal.scRate * 100).toFixed(0)}%)`.padEnd(24, ' ')}${(
                            'RM ' +
                            testItems
                                .reduce((accum, item) => accum + (item.subtotal * currOutlet.scRate), 0)
                                .toFixed(2)
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                        ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    }

                    result += `${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}${'Total'.padEnd(
                        12,
                        ' ',
                    )}${(
                        'RM ' +
                        (Math.ceil(testItems
                            .reduce((accum, item) => accum + item.subtotal +
                                (currOutlet.taxActive ? (item.subtotal * currOutlet.taxRate) : 0) +
                                (currOutlet.scActive ? (item.subtotal * currOutlet.scRate) : 0)
                                , 0) * 20 - 0.05) / 20)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    ).padStart(12, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[
                        ...Array(48),
                    ]
                        .map((i) => '-')
                        .join('')}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'Received'.padEnd(24, ' ')}${(
                        'RM ' +
                        (Math.ceil(testItems
                            .reduce((accum, item) => accum + item.subtotal +
                                (currOutlet.taxActive ? (item.subtotal * currOutlet.taxRate) : 0) +
                                (currOutlet.scActive ? (item.subtotal * currOutlet.scRate) : 0)
                                , 0) * 20 - 0.05) / 20)
                            .toFixed(2)
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'Balance'.padEnd(24, ' ')}${(
                        'RM ' + (0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                    result += `${ESCPOS_CMD.LEFT}${'Notes:'}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'1.'}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'2.'}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'3.'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE
                        }${ESCPOS_CMD.NEWLINE}`;

                    result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_2H
                        }${'Thank you for your order'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE
                        }`;

                    // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.BARCODE_HEIGHT}${ESCPOS_CMD.BARCODE_WIDTH}${ESCPOS_CMD.BARCODE_FONT_A}${ESCPOS_CMD.BARCODE_TXT_OFF}${ESCPOS_CMD.BARCODE_EAN13}978020137962${ESCPOS_CMD.NEWLINE}`;

                    // result += `${ESCPOS_CMD.CENTER}${printBarcode({
                    //   data: 'TEST12345',
                    //   type: 'ean',
                    // }, 'cp936')}${ESCPOS_CMD.NEWLINE}`;

                    console.log('on print receipt');

                    NetPrinter.printText(result, {
                        encoding: 'GB18030',
                    });

                    ////////////////////////////////////////////////////////////

                    result = '';
                    if (koodooLogoUrl) {
                        console.log('on print koodoo logo');

                        await NetPrinter.printImage(koodooLogoUrl);
                        result += `${ESCPOS_CMD.CENTER}Powered by KooDoo${ESCPOS_CMD.NEWLINE}`;
                    }

                    result += `${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                    result += `${ESCPOS_CMD.CUT_PARTIAL}`;

                    NetPrinter.printText(result, {
                        encoding: 'GB18030',
                    });

                    result = '';
                    result += `${ESCPOS_CMD.CD_KICK_2}${ESCPOS_CMD.CD_KICK_5}`;
                    NetPrinter.printText(result, {
                        encoding: 'GB18030',
                    });
                } else if (
                    printerTemp.types[index] === PRINTER_USAGE_TYPE.KITCHEN_DOCKET
                ) {
                    var result = `${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.UNDERLINE_OFF}${ESCPOS_CMD.BOLD_OFF}`;
                    // result += `${ESCPOS_CMD.CENTER}${currOutlet.name}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}${currOutlet.address}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}${currOutlet.phone}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}${merchantName}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}SST ID No. 0012612771${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}KITCHEN DOCKET${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}TABLE: D7${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}ORDER #38${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
                    //   .map((i) => '-')
                    //   .join('')}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}Mark${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}0127148876${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_NORMAL
                        }${moment().format('YYYY.MM.DD')}  ${moment().format('HH:mm:ss')}${ESCPOS_CMD.NEWLINE
                        }${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Receipt #'.padEnd(12, ' ')} : ${'38'}${ESCPOS_CMD.NEWLINE
                    //   }`;
                    result += `${ESCPOS_CMD.LEFT}${'Cashier'.padEnd(
                        12,
                        ' ',
                    )} : ${'Sophie Kim'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'ITEM'.padEnd(20, ' ')}${''.padEnd(
                        8,
                        ' ',
                    )}${''.padEnd(8, ' ')}${''.padEnd(4, ' ')}${'QTY'.padStart(8, ' ')}${ESCPOS_CMD.NEWLINE
                        }`;
                    result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                        .map((i) => '-')
                        .join('')}${ESCPOS_CMD.NEWLINE}`;

                    for (var i = 0; i < testItems.length; i++) {
                        const testItem = testItems[i];

                        result += `${ESCPOS_CMD.LEFT}${
                            // encodeGB18030(testItem.name)
                            // testItem.name
                            //   .slice(0, 20)
                            padEndUnicodeStringV2(
                                sliceUnicodeStringV2(testItem.name, 42),
                                44,
                                ' ',
                            )
                            // .padEnd(20, ' ')
                            }${testItem.quantity.toString().padStart(4, ' ')}${ESCPOS_CMD.NEWLINE
                            }`;

                        if (testItem.remarks) {
                            result += `${ESCPOS_CMD.LEFT}${
                                // testItem.remarks
                                //   .slice(0, 20)
                                padEndUnicodeStringV2(
                                    sliceUnicodeStringV2(testItem.remarks, 48),
                                    48,
                                    ' ',
                                )
                                // .padEnd(20, ' ')
                                }${ESCPOS_CMD.NEWLINE}`;
                        }

                        if (i !== testItems.length - 1) {
                            result += `${ESCPOS_CMD.NEWLINE}`;
                        }
                    }

                    result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                        .map((i) => '-')
                        .join('')}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Subtotal'.padEnd(24, ' ')}${(
                    //   'RM ' +
                    //   testItems
                    //     .reduce((accum, item) => accum + item.subtotal, 0)
                    //     .toFixed(2)
                    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(24, ' ')}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Discount'.padEnd(24, ' ')}${(
                    //   'RM ' +
                    //   testItems
                    //     .reduce((accum, item) => accum + item.discount, 0)
                    //     .toFixed(2)
                    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(24, ' ')}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Tax (6%)'.padEnd(24, ' ')}${(
                    //   'RM ' +
                    //   testItems
                    //     .reduce((accum, item) => accum + item.tax, 0)
                    //     .toFixed(2)
                    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}${'Total'.padEnd(
                    //   12,
                    //   ' ',
                    // )}${(
                    //   'RM ' +
                    //   testItems
                    //     .reduce((accum, item) => accum + item.subtotal, 0)
                    //     .toFixed(2)
                    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(12, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
                    //   .map((i) => '-')
                    //   .join('')}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Received'.padEnd(24, ' ')}${(
                    //   'RM ' +
                    //   testItems
                    //     .reduce((accum, item) => accum + item.subtotal, 0)
                    //     .toFixed(2)
                    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Balance'.padEnd(24, ' ')}${(
                    //   'RM ' + (0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                    // result += `${ESCPOS_CMD.LEFT}${'Notes:'}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'1.'}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'2.'}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'3.'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE
                    //   }${ESCPOS_CMD.NEWLINE}`;

                    // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_2H
                    //   }${'Thank you for your order'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                    // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.BARCODE_HEIGHT}${ESCPOS_CMD.BARCODE_WIDTH}${ESCPOS_CMD.BARCODE_FONT_A}${ESCPOS_CMD.BARCODE_TXT_OFF}${ESCPOS_CMD.BARCODE_EAN13}978020137962${ESCPOS_CMD.NEWLINE}`;

                    // result += `${ESCPOS_CMD.CENTER}${printBarcode({
                    //   data: 'TEST12345',
                    //   type: 'ean',
                    // }, 'cp936')}${ESCPOS_CMD.NEWLINE}`;

                    result += `${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                    result += `${ESCPOS_CMD.CUT_PARTIAL}`;

                    NetPrinter.printText(result, {
                        encoding: 'GB18030',
                    });
                } else if (
                    printerTemp.types[index] === PRINTER_USAGE_TYPE.ORDER_SUMMARY
                ) {
                    var result = `${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.UNDERLINE_OFF}${ESCPOS_CMD.BOLD_OFF}`;
                    // result += `${ESCPOS_CMD.CENTER}${currOutlet.name}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}${currOutlet.address}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}${currOutlet.phone}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}${merchantName}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}SST ID No. 0012612771${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}ORDER SUMMARY${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}TABLE: D7${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}ORDER #38${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
                    //   .map((i) => '-')
                    //   .join('')}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}Mark${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}0127148876${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_NORMAL
                        }${moment().format('YYYY.MM.DD')}  ${moment().format('HH:mm:ss')}${ESCPOS_CMD.NEWLINE
                        }${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Receipt #'.padEnd(12, ' ')} : ${'38'}${ESCPOS_CMD.NEWLINE
                    //   }`;
                    // result += `${ESCPOS_CMD.LEFT}${'Table'.padEnd(
                    //   12,
                    //   ' ',
                    // )} : ${'D7'}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'Cashier'.padEnd(
                        12,
                        ' ',
                    )} : ${'Sophie Kim'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    result += `${ESCPOS_CMD.LEFT}${'ITEM'.padEnd(20, ' ')}${''.padEnd(
                        8,
                        ' ',
                    )}${''.padEnd(8, ' ')}${''.padEnd(4, ' ')}${'QTY'.padStart(8, ' ')}${ESCPOS_CMD.NEWLINE
                        }`;
                    result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                        .map((i) => '-')
                        .join('')}${ESCPOS_CMD.NEWLINE}`;

                    for (var i = 0; i < testItems.length; i++) {
                        const testItem = testItems[i];

                        console.log(sliceUnicodeStringV2(testItem.name, 42));
                        console.log(padEndUnicodeStringV2(
                            sliceUnicodeStringV2(testItem.name, 42),
                            44,
                            ' ',
                        ));

                        result += `${ESCPOS_CMD.LEFT}${
                            // encodeGB18030(testItem.name)
                            // testItem.name
                            //   .slice(0, 20)
                            padEndUnicodeStringV2(
                                sliceUnicodeStringV2(testItem.name, 42),
                                44,
                                ' ',
                            )
                            // .padEnd(20, ' ')
                            }${testItem.quantity.toString().padStart(4, ' ')}${ESCPOS_CMD.NEWLINE
                            }`;

                        if (testItem.remarks) {
                            result += `${ESCPOS_CMD.LEFT}${
                                // testItem.remarks
                                //   .slice(0, 20)
                                padEndUnicodeStringV2(
                                    sliceUnicodeStringV2(testItem.remarks, 48),
                                    48,
                                    ' ',
                                )
                                // .padEnd(20, ' ')
                                }${ESCPOS_CMD.NEWLINE}`;
                        }

                        if (i !== testItems.length - 1) {
                            result += `${ESCPOS_CMD.NEWLINE}`;
                        }
                    }

                    result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                        .map((i) => '-')
                        .join('')}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Subtotal'.padEnd(24, ' ')}${(
                    //   'RM ' +
                    //   testItems
                    //     .reduce((accum, item) => accum + item.subtotal, 0)
                    //     .toFixed(2)
                    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(24, ' ')}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Discount'.padEnd(24, ' ')}${(
                    //   'RM ' +
                    //   testItems
                    //     .reduce((accum, item) => accum + item.discount, 0)
                    //     .toFixed(2)
                    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(24, ' ')}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Tax (6%)'.padEnd(24, ' ')}${(
                    //   'RM ' +
                    //   testItems
                    //     .reduce((accum, item) => accum + item.tax, 0)
                    //     .toFixed(2)
                    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}${'Total'.padEnd(
                    //   12,
                    //   ' ',
                    // )}${(
                    //   'RM ' +
                    //   testItems
                    //     .reduce((accum, item) => accum + item.subtotal, 0)
                    //     .toFixed(2)
                    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(12, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
                    //   .map((i) => '-')
                    //   .join('')}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Received'.padEnd(24, ' ')}${(
                    //   'RM ' +
                    //   testItems
                    //     .reduce((accum, item) => accum + item.subtotal, 0)
                    //     .toFixed(2)
                    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'Balance'.padEnd(24, ' ')}${(
                    //   'RM ' + (0).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
                    // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                    // result += `${ESCPOS_CMD.LEFT}${'Notes:'}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'1.'}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'2.'}${ESCPOS_CMD.NEWLINE}`;
                    // result += `${ESCPOS_CMD.LEFT}${'3.'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE
                    //   }${ESCPOS_CMD.NEWLINE}`;

                    // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_2H
                    //   }${'Thank you for your order'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                    // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.BARCODE_HEIGHT}${ESCPOS_CMD.BARCODE_WIDTH}${ESCPOS_CMD.BARCODE_FONT_A}${ESCPOS_CMD.BARCODE_TXT_OFF}${ESCPOS_CMD.BARCODE_EAN13}978020137962${ESCPOS_CMD.NEWLINE}`;

                    // result += `${ESCPOS_CMD.CENTER}${printBarcode({
                    //   data: 'TEST12345',
                    //   type: 'ean',
                    // }, 'cp936')}${ESCPOS_CMD.NEWLINE}`;

                    result += `${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                    result += `${ESCPOS_CMD.CUT_PARTIAL}`;

                    NetPrinter.printText(result, {
                        encoding: 'GB18030',
                    });
                }
            }
        } catch (ex) {
            console.log(ex);
        }
        CommonStore.update((s) => { s.isLoading = false })
    };

    const scanPrinter = async () => {
        if (Platform.OS === 'ios') {
            // if (false) {
            // ios

            try {
                var intervalCounter = 0;

                var intervalTimer = setInterval(() => {
                    if (intervalCounter < 210) {
                        intervalCounter++;

                        setScanningPercentage((intervalCounter / 210) * 100);
                    }
                    else {
                        clearInterval(intervalTimer);
                    }
                }, 500);

                NetPrinter.getDeviceList().then((devices) => {
                    console.log('devices');
                    console.log(devices);

                    var newDiscoveredPrintersNum = 0;

                    var printerNamingCount = newDiscoveredPrintersNum + outletPrinters.length;

                    for (var i = 0; i < devices.length; i++) {
                        var foundPrinter = outletPrinters.find(outletPrinter => outletPrinter.ip === devices[i].host);

                        if (!foundPrinter) {
                            newDiscoveredPrintersNum++;

                            printerNamingCount++;

                            var body = {
                                outletId: currOutlet.uniqueId,
                                merchantId: currOutlet.merchantId,
                                // printerName: printerObj.device_name,
                                printerName: `Printer #${printerNamingCount.toString()}`,
                                printerIP: devices[i].host,
                                printerArea: 'Cashier',

                                printerTypes: [PRINTER_USAGE_TYPE.RECEIPT],
                            };

                            ApiClient.POST(API.createOutletPrinter, body, false)
                                .then(async (result) => {
                                    if (result && result.data) {
                                        await bindPrinter(result.data);
                                    }
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        }
                    }

                    clearInterval(intervalTimer);

                    setIsScanning(false);

                    // CommonStore.update(s => {
                    //   s.isLoading = false;
                    // });

                    setScanningPercentage(0);

                    var infoMsg = 'Printers scanning done.\n\n';
                    if (newDiscoveredPrintersNum > 0) {
                        infoMsg += `${newDiscoveredPrintersNum} new printers found.`;
                    } else {
                        infoMsg += 'No new printers found.';
                    }

                    Alert.alert('Success', infoMsg);
                });
            }
            catch (ex) {
                console.log(ex);

                Alert.alert('Error', 'Unable to find the printers, please try again.');
            }
        }
        else {
            // android

            NetInfo.fetch().then(async (state) => {
                console.log('network state');
                console.log(state);

                if (
                    state &&
                    state.details &&
                    state.details.ipAddress &&
                    state.details.ipAddress.split('.').length >= 4
                ) {
                    var ipAddressArray = state.details.ipAddress.split('.');

                    var newDiscoveredPrintersNum = 0;

                    var scanningIndex = 2;

                    var printerNamingCount =
                        newDiscoveredPrintersNum + outletPrinters.length;

                    // scan through ip address list
                    for (var i = 2; i < 255; i++) {
                        const isScanningLocal = await AsyncStorage.getItem('isScanning');

                        if (isScanningLocal === '1') {
                            ipAddressArray[3] = i.toString();
                            const currIp = ipAddressArray.join('.');

                            console.log(`${i}) scanning ${currIp}`);

                            const isPortOpenCheck = await isPortOpen(
                                currIp,
                                // success callback
                                () => {
                                    console.log(`${i}) success`);

                                    var foundPrinter = outletPrinters.find(
                                        (outletPrinter) => outletPrinter.ip === currIp,
                                    );

                                    if (!foundPrinter) {
                                        // proceed to create & store the printer

                                        newDiscoveredPrintersNum++;

                                        printerNamingCount++;

                                        var body = {
                                            outletId: currOutlet.uniqueId,
                                            merchantId: currOutlet.merchantId,
                                            // printerName: printerObj.device_name,
                                            printerName: `Printer #${printerNamingCount.toString()}`,
                                            printerIP: currIp,
                                            printerArea: 'Cashier',

                                            printerTypes: [PRINTER_USAGE_TYPE.RECEIPT],
                                        };

                                        ApiClient.POST(API.createOutletPrinter, body, false)
                                            .then(async (result) => {
                                                if (result && result.data) {
                                                    await bindPrinter(result.data);
                                                }
                                            })
                                            .catch((err) => {
                                                console.log(err);
                                            });

                                        scanningIndex++;

                                        setScanningPercentage((scanningIndex / 254) * 100);

                                        if (scanningIndex >= 254) {
                                            setIsScanning(false);

                                            // CommonStore.update(s => {
                                            //   s.isLoading = false;
                                            // });

                                            setScanningPercentage(0);

                                            var infoMsg = 'Printers scanning done.\n\n';
                                            if (newDiscoveredPrintersNum > 0) {
                                                infoMsg += `${newDiscoveredPrintersNum} new printers found.`;
                                            } else {
                                                infoMsg += 'No new printers found.';
                                            }

                                            Alert.alert('Success', infoMsg);
                                        }
                                    }
                                },
                                // fail callback
                                () => {
                                    console.log(`${i}) fail`);

                                    scanningIndex++;

                                    setScanningPercentage((scanningIndex / 254) * 100);

                                    ////////////////////////////////////////////////

                                    if (scanningIndex >= 254) {
                                        setIsScanning(false);

                                        // CommonStore.update(s => {
                                        //   s.isLoading = false;
                                        // });

                                        setScanningPercentage(0);

                                        var infoMsg = 'Printers scanning done.\n\n';
                                        if (newDiscoveredPrintersNum > 0) {
                                            infoMsg += `${newDiscoveredPrintersNum} new printers found.`;
                                        } else {
                                            infoMsg += 'No new printers found.';
                                        }

                                        Alert.alert('Success', infoMsg);
                                    }
                                },
                            );

                            // if (isPortOpenCheck) {
                            //   // const printerObj = await connectToPrinterFast(currIp);

                            //   // if (isPortOpenCheck) {
                            //   //   // means is valid printer, check if the ip existed in outlet printers already

                            //   //   var foundPrinter = outletPrinters.find(outletPrinter => outletPrinter.ip === printerObj.host);

                            //   //   if (!foundPrinter) {
                            //   //     // proceed to create & store the printer

                            //   //     newDiscoveredPrintersNum++;

                            //   //     // var body = {
                            //   //     //   outletId: currOutlet.uniqueId,
                            //   //     //   merchantId: currOutlet.merchantId,
                            //   //     //   // printerName: printerObj.device_name,
                            //   //     //   printerName: `Printer #${(outletPrinters.length + 1).toString()}`,
                            //   //     //   printerIP: printerObj.host,
                            //   //     //   printerArea: 'Cashier',

                            //   //     //   printerTypes: [PRINTER_USAGE_TYPE.RECEIPT],
                            //   //     // };

                            //   //     // ApiClient.POST(API.createOutletPrinter, body, false)
                            //   //     //   .then(async (result) => {
                            //   //     //     if (result && result.data) {
                            //   //     //       await bindPrinter(result.data);
                            //   //     //     }
                            //   //     //   })
                            //   //     //   .catch((err) => {
                            //   //     //     console.log(err);
                            //   //     //   });
                            //   //   }
                            //   // }
                            // }

                            setScanningPercentage((i / 254) * 100);
                        } else {
                            break;
                        }
                    }
                } else {
                    setIsScanning(false);

                    // CommonStore.update(s => {
                    //   s.isLoading = false;
                    // });

                    Alert.alert('Error', 'Unable to scan the available printers.');
                }
            });
        }
    };

    // function end

    return (
        <View
            style={[
                styles.container,
                {
                    height: windowHeight,
                    width: windowWidth,
                },
            ]}>
            <View style={{ flex: 0.8 }}>
                <SideBar navigation={props.navigation} selectedTab={1} expandOperation={true} />
            </View>

            <View style={{ height: windowHeight, flex: 9 }}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={{}}>
                    <View style={styles.content}>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: 2,
                                width: '100%',
                                justifyContent: 'space-between',
                            }}>
                            <Text
                                style={{
                                    fontSize: switchMerchant ? 20 : 26,
                                    fontFamily: 'NunitoSans-Bold',
                                }}>
                                {outletPrinters.length} Printer
                                {outletPrinters.length > 1 ? 's' : ''}
                            </Text>

                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <TouchableOpacity
                                    // disabled={isScanning}
                                    style={[
                                        styles.submitText,
                                        {
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: 10,
                                            height: switchMerchant ? 35 : 40,
                                            left: 0,
                                            backgroundColor: '#4E9F7D',
                                            borderWidth: 1,
                                            borderColor: Colors.primaryColor,
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                        },
                                    ]}
                                    onPress={async () => {
                                        // CommonStore.update(s => {
                                        //   s.isLoading = true;
                                        // });

                                        if (isScanning) {
                                            // setIsScanning(false);

                                            // await AsyncStorage.setItem('isScanning', '0');
                                        } else {
                                            setIsScanning(true);

                                            await AsyncStorage.setItem('isScanning', '1');

                                            scanPrinter();
                                        }
                                    }}>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            paddingRight:
                                                Dimensions.get('screen').width <= 1823 &&
                                                    Dimensions.get('screen').width >= 1820
                                                    ? 5
                                                    : 0,
                                            paddingBottom:
                                                Dimensions.get('screen').width <= 1823 &&
                                                    Dimensions.get('screen').width >= 1820
                                                    ? 2
                                                    : 0,
                                        }}>
                                        {isScanning ? (
                                            <>
                                                <Text
                                                    style={{
                                                        marginRight: 5,
                                                        color: Colors.primaryColor,
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        color: '#FFFFFF',
                                                        fontFamily: 'NunitoSans-Bold',
                                                    }}>
                                                    {`${scanningPercentage.toFixed(1)}%`}
                                                </Text>

                                                <ActivityIndicator
                                                    size={'small'}
                                                    color={Colors.whiteColor}
                                                />

                                                <Text
                                                    style={{
                                                        marginLeft: 5,
                                                        color: Colors.primaryColor,
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        color: '#FFFFFF',
                                                        fontFamily: 'NunitoSans-Bold',
                                                    }}>
                                                    SCANNING
                                                </Text>
                                            </>
                                        ) : (
                                            <>
                                                <SimpleLineIcons
                                                    name="magnifier"
                                                    size={switchMerchant ? 11 : 18}
                                                    color="#FFFFFF"
                                                />
                                                <Text
                                                    style={{
                                                        marginLeft: 5,
                                                        color: Colors.primaryColor,
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        color: '#FFFFFF',
                                                        fontFamily: 'NunitoSans-Bold',
                                                    }}>
                                                    SCAN
                                                </Text>
                                            </>
                                        )}
                                    </View>
                                </TouchableOpacity>

                                <TouchableOpacity
                                    disabled={isLoading}
                                    style={[
                                        styles.submitText,
                                        {
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            borderRadius: 10,
                                            height: switchMerchant ? 35 : 40,
                                            left: 0,
                                            backgroundColor: '#4E9F7D',
                                            borderWidth: 1,
                                            borderColor: Colors.primaryColor,
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                        },
                                    ]}
                                    onPress={() => {
                                        setQueueCustomerName('');
                                        setQueuePhone('');
                                        setQueuePax(0);

                                        setSelectedQueue(null);
                                        setAddQueueModal(true);
                                    }}>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            paddingRight:
                                                Dimensions.get('screen').width <= 1823 &&
                                                    Dimensions.get('screen').width >= 1820
                                                    ? 5
                                                    : 0,
                                            paddingBottom:
                                                Dimensions.get('screen').width <= 1823 &&
                                                    Dimensions.get('screen').width >= 1820
                                                    ? 2
                                                    : 0,
                                        }}>
                                        <AntDesign
                                            name="pluscircle"
                                            size={switchMerchant ? 13 : 20}
                                            color="#FFFFFF"
                                        />
                                        <Text
                                            style={{
                                                marginLeft: 5,
                                                color: Colors.primaryColor,
                                                fontSize: switchMerchant ? 10 : 16,
                                                color: '#FFFFFF',
                                                fontFamily: 'NunitoSans-Bold',
                                            }}>
                                            PRINTER
                                        </Text>
                                    </View>
                                </TouchableOpacity>

                                {/* <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', borderRadius: 10, height: 40, }}>
                
                    <View style={{
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: 10,
                      borderRadius: 5,
                      height: 40,
                      borderRadius: 5,
                      borderWidth: 1,
                      borderColor: '#E5E5E5',
                      backgroundColor: 'white',
                      marginRight: 15,
                      shadowColor: '#000',
                      shadowOffset: {
                        width: 0,
                        height: 2,
                      },
                      shadowOpacity: 0.22,
                      shadowRadius: 3.22,
                      elevation: 3,
  
                    }}>
                      <Text style={{ fontSize: 16, paddingRight: Platform.OS == 'ios' ? 20 : 20, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>
                        Filter
                      </Text>
                      <DropDownPicker
                        controller={instance => setController1(instance)}
                        arrowColor={Colors.primaryColor}
                        arrowSize={23}
                        arrowStyle={{ fontWeight: 'bold' }}
                        labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                        itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                        placeholderStyle={{ color: 'black' }}
                        style={{ width: 140, borderWidth: 0, paddingHorizontal: 5, paddingVertical: 0, borderRadius: 5, borderColor: '#E5E5E5', borderWidth: 0, borderLeftWidth: 0, }}
                        items={[{ label: 'Pending', value: 0 }, { label: 'Accepted', value: 1 }, { label: 'Seated', value: 2 }, { label: 'Served', value: 3 }, { label: 'Rejected', value: 4 }, { label: 'No Show', value: 5 }]} //Awaiting Authorization
                        placeholder={"Pending"}
                        onChangeItem={selectedFilter => {
                          filterOrders(selectedFilter);
                        }
                        }
                      />
                    </View>
                  </View> */}

                                {/* <View style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingLeft: 10,
                  borderRadius: 5,
                  height: 40,
                  borderRadius: 5,
                  borderWidth: 1,
                  borderColor: '#E5E5E5',
                  backgroundColor: 'white',
                  marginRight: 15,
                  shadowColor: '#000',
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.22,
                  shadowRadius: 3.22,
                  elevation: 3,
  
                }}>
                  <Text style={{ fontSize: 16, paddingRight: Platform.OS == 'ios' ? 20 : 20, borderColor: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Bold' }}>
                    Filter
                  </Text>
                  <DropDownPicker
                    // controller={instance => setController1(instance)}
                    arrowColor={Colors.primaryColor}
                    arrowSize={23}
                    arrowStyle={{ fontWeight: 'bold' }}
                    labelStyle={{ fontFamily: 'NunitoSans-Regular' }}
                    itemStyle={{ justifyContent: 'flex-start', marginLeft: 0 }}
                    placeholderStyle={{ color: 'black' }}
                    style={{ width: 140, borderWidth: 0, paddingHorizontal: 5, paddingVertical: 0, borderRadius: 5, borderColor: '#E5E5E5', borderWidth: 0, borderLeftWidth: 0, }}
                    items={[{ label: 'All', value: 0 }, { label: 'Pending', value: 1 }, { label: 'Seated', value: 3 }, { label: 'Served', value: 4 }, { label: 'Cancelled', value: 5 }]} //Awaiting Authorization
                    // placeholder={"Pending"}
                    defaultValue={filterType}
                    onChangeItem={selectedFilter => {
                      // filterOrders(selectedFilter);
                      setFilterType(selectedFilter.value);
                    }
                    }
                  //onOpen={() => controller.close()}
                  />
                </View> */}

                                <View
                                    style={[
                                        {
                                            height: 40,
                                        },
                                    ]}>
                                    <View
                                        style={{
                                            width: switchMerchant ? 200 : 250,
                                            height: 40,
                                            backgroundColor: 'white',
                                            borderRadius: 5,
                                            flexDirection: 'row',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            shadowColor: '#000',
                                            shadowOffset: {
                                                width: 0,
                                                height: 2,
                                            },
                                            shadowOpacity: 0.22,
                                            shadowRadius: 3.22,
                                            elevation: 3,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                        }}>
                                        <Icon
                                            name="search"
                                            size={switchMerchant ? 13 : 18}
                                            color={Colors.primaryColor}
                                            style={{ marginLeft: 15 }}
                                        />
                                        <TextInput
                                            underlineColorAndroid={Colors.whiteColor}
                                            style={{
                                                width: switchMerchant ? 180 : 220,
                                                fontSize: switchMerchant ? 10 : 15,
                                                fontFamily: 'NunitoSans-Regular',
                                                paddingLeft: 5,
                                                height: 45,
                                            }}
                                            clearButtonMode="while-editing"
                                            placeholder=" Search"
                                            placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                            onChangeText={(text) => {
                                                setSearch(text);
                                                // setSearch(text.trim());
                                            }}
                                        // value={search}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{ marginTop: 30, marginBottom: 100, zIndex: -1 }}>
                            <View
                                style={{
                                    width: '100%',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    paddingHorizontal: 5,
                                }}>
                                <View
                                    style={{
                                        width: switchMerchant ? '12.4%' : '14%',
                                        marginHorizontal: 4,
                                    }}>
                                    <View
                                        style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                        <View style={{}}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (sort === QUEUE_SORT_FIELD_TYPE.NAME_ASC) {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.NAME_DESC);
                                                    } else {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.NAME_ASC);
                                                    }
                                                }}>
                                                <Text
                                                    style={{
                                                        color: 'black',
                                                        fontFamily: 'NunitoSans-Regular',
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        paddingLeft: switchMerchant ? 8 : Dimensions.get('screen').height === 800 && Dimensions.get('screen').width === 1280 ? 0 : 5,
                                                    }}>
                                                    Printer Name
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: 3 }}>
                                            {/* <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.NAME_DESC) }}>
                        <Entypo name='triangle-up' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.NAME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.NAME_ASC) }}>
                        <Entypo name='triangle-down' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.NAME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity> */}
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={{
                                        width: switchMerchant ? '16.8%' : '18%',
                                        marginHorizontal: 4,
                                        alignItems: 'flex-start',
                                    }}>
                                    <View
                                        style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                        <View style={{}}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (sort === QUEUE_SORT_FIELD_TYPE.DATE_TIME_ASC) {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.DATE_TIME_DESC);
                                                    } else {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.DATE_TIME_ASC);
                                                    }
                                                }}>
                                                <Text
                                                    style={{
                                                        color: 'black',
                                                        fontFamily: 'NunitoSans-Regular',
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        paddingRight: switchMerchant ? 20 : 0,
                                                        paddingLeft: switchMerchant ? 0 : Dimensions.get('screen').height === 800 && Dimensions.get('screen').width === 1280 ? 0 : 6,
                                                    }}>
                                                    Printer IP
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: 3 }}>
                                            {/* <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.DATE_TIME_DESC) }}>
                        <Entypo name='triangle-up' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.DATE_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.DATE_TIME_ASC) }}>
                        <Entypo name='triangle-down' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.DATE_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity> */}
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={{
                                        width: switchMerchant ? '8%' : '8%',
                                        marginHorizontal: 4,
                                    }}>
                                    <View
                                        style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                        <View style={{}}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (sort === QUEUE_SORT_FIELD_TYPE.NAME_ASC) {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.NAME_DESC);
                                                    } else {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.NAME_ASC);
                                                    }
                                                }}>
                                                <Text
                                                    style={{
                                                        color: 'black',
                                                        fontFamily: 'NunitoSans-Regular',
                                                        fontSize: switchMerchant ? 10 : 16,
                                                        paddingLeft: switchMerchant ? 8 : Dimensions.get('screen').height === 800 && Dimensions.get('screen').width === 1280 ? 0 : 5,
                                                    }}>
                                                    Area
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: 3 }}>
                                            {/* <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.NAME_DESC) }}>
                        <Entypo name='triangle-up' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.NAME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.NAME_ASC) }}>
                        <Entypo name='triangle-down' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.NAME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity> */}
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={{
                                        width: switchMerchant ? '13.6%' : '14%',
                                        marginHorizontal: 4,
                                        alignItems: 'flex-start',
                                    }}>
                                    <View
                                        style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                        <View style={{}}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    // if (sort === QUEUE_SORT_FIELD_TYPE.WAITING_TIME_ASC) {
                                                    //   setSort(QUEUE_SORT_FIELD_TYPE.WAITING_TIME_DESC);
                                                    // } else {
                                                    //   setSort(QUEUE_SORT_FIELD_TYPE.WAITING_TIME_ASC);
                                                    // }
                                                }}>
                                                <Text
                                                    style={{
                                                        color: 'black',
                                                        fontFamily: 'NunitoSans-Regular',
                                                        fontSize: switchMerchant ? 10 : 16,
                                                    }}>
                                                    Printer Type(s)
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: 3 }}>
                                            {/* <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.WAITING_TIME_DESC) }}>
                        <Entypo name='triangle-up' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.WAITING_TIME_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.WAITING_TIME_ASC) }}>
                        <Entypo name='triangle-down' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.WAITING_TIME_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity> */}
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={{
                                        width: switchMerchant ? '13.5%' : '14%',
                                        marginHorizontal: 4,
                                        alignItems: 'flex-start',
                                    }}>
                                    <View
                                        style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                        <View style={{}}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (sort === QUEUE_SORT_FIELD_TYPE.CAPACITY_ASC) {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.CAPACITY_DESC);
                                                    } else {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.CAPACITY_ASC);
                                                    }
                                                }}>
                                                <Text
                                                    style={{
                                                        color: 'black',
                                                        fontFamily: 'NunitoSans-Regular',
                                                        fontSize: switchMerchant ? 10 : 16,
                                                    }}>
                                                    Save Printer
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: 3 }}>
                                            {/* <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.CAPACITY_DESC) }}>
                        <Entypo name='triangle-up' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.CAPACITY_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.CAPACITY_ASC) }}>
                        <Entypo name='triangle-down' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.CAPACITY_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity> */}
                                        </View>
                                    </View>
                                </View>

                                {/* <View
                    style={{
                      width: switchMerchant ? '13.6%' : '14%',
                      marginHorizontal: 4,
                      alignItems: 'flex-start',
                    }}>
                    <View
                      style={{
                        width: '100%',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}>
                      <View style={{}}>
                        <TouchableOpacity
                          onPress={() => {
                            if (sort === QUEUE_SORT_FIELD_TYPE.WAITING_TIME_ASC) {
                              setSort(QUEUE_SORT_FIELD_TYPE.WAITING_TIME_DESC);
                            } else {
                              setSort(QUEUE_SORT_FIELD_TYPE.WAITING_TIME_ASC);
                            }
                          }}>
                          <Text
                            style={{
                              color: 'black',
                              fontFamily: 'NunitoSans-Regular',
                              fontSize: switchMerchant ? 10 : 16,
                            }}>
                            Unbind Printer
                          </Text>
                        </TouchableOpacity>
                      </View>
                      <View style={{ marginLeft: 3 }}>
                      </View>
                    </View>
                  </View> */}

                                <View
                                    style={{
                                        width: '14%',
                                        marginHorizontal: 4,
                                        alignItems: 'flex-start',
                                    }}>
                                    <View
                                        style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                        <View style={{}}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (sort === QUEUE_SORT_FIELD_TYPE.STATUS_ASC) {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.STATUS_DESC);
                                                    } else {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.STATUS_ASC);
                                                    }
                                                }}>
                                                <Text
                                                    style={{
                                                        color: 'black',
                                                        fontFamily: 'NunitoSans-Regular',
                                                        fontSize: switchMerchant ? 10 : 16,
                                                    }}>
                                                    Test Printer
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: 3 }}>
                                            {/* <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.STATUS_DESC) }}>
                        <Entypo name='triangle-up' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.STATUS_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.STATUS_ASC) }}>
                        <Entypo name='triangle-down' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.STATUS_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity> */}
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={{
                                        width: '14%',
                                        marginHorizontal: 4,
                                        alignItems: 'flex-start',
                                    }}>
                                    <View
                                        style={{
                                            width: '100%',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}>
                                        <View style={{}}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (sort === QUEUE_SORT_FIELD_TYPE.STATUS_ASC) {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.STATUS_DESC);
                                                    } else {
                                                        setSort(QUEUE_SORT_FIELD_TYPE.STATUS_ASC);
                                                    }
                                                }}>
                                                <Text
                                                    style={{
                                                        color: 'black',
                                                        fontFamily: 'NunitoSans-Regular',
                                                        fontSize: switchMerchant ? 10 : 16,
                                                    }}>
                                                    Remove Printer
                                                </Text>
                                            </TouchableOpacity>
                                        </View>
                                        <View style={{ marginLeft: 3 }}>
                                            {/* <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.STATUS_DESC) }}>
                        <Entypo name='triangle-up' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.STATUS_ASC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={() => { setSort(QUEUE_SORT_FIELD_TYPE.STATUS_ASC) }}>
                        <Entypo name='triangle-down' size={14} color={sort === QUEUE_SORT_FIELD_TYPE.STATUS_DESC ? Colors.secondaryColor : Colors.descriptionColor}></Entypo>
                      </TouchableOpacity> */}
                                        </View>
                                    </View>
                                </View>
                            </View>
                            {/* <FlatList
                  showsVerticalScrollIndicator={false}
                  data={outletPrinters.filter((printer) => {
                    if (search !== '') {
                      const searchLowerCase = search.toLowerCase();
  
                      if (
                        printer.name.toLowerCase().includes(searchLowerCase) ||
                        printer.ip.toLowerCase().includes(searchLowerCase)
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return true;
                    }
                  })}
                  renderItem={renderPrinter}
                  keyExtractor={(item, index) => String(index)}
                  style={{
                    marginTop: 10,
                    backgroundColor: 'white',
                    height: Dimensions.get('screen').height * 0.6,
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.22,
                    shadowRadius: 3.22,
                    elevation: 1,
                  }}
                /> */}
                            <View
                                style={{
                                    marginTop: 10,
                                    backgroundColor: 'white',
                                    height: Dimensions.get('screen').height * 0.6,
                                    shadowOffset: {
                                        width: 0,
                                        height: 2,
                                    },
                                    shadowOpacity: 0.22,
                                    shadowRadius: 3.22,
                                    elevation: 1,
                                }}>
                                {outletPrinters
                                    .filter((printer) => {
                                        if (search !== '') {
                                            const searchLowerCase = search.toLowerCase();

                                            if (
                                                printer.name.toLowerCase().includes(searchLowerCase) ||
                                                printer.ip.toLowerCase().includes(searchLowerCase)
                                            ) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        } else {
                                            return true;
                                        }
                                    })
                                    .map((item, index) => {
                                        return (
                                            <View
                                                style={{
                                                    paddingVertical: 5,
                                                    shadowOffset: {
                                                        width: 0,
                                                        height: 2,
                                                    },
                                                    shadowOpacity: 0.22,
                                                    shadowRadius: 3.22,
                                                    elevation: 1,

                                                    zIndex: 10001 - index,
                                                }}>
                                                {/* <Swipeable
                            renderRightActions={() => rightAction(item)}
                            // ref={refArray[index]}
                            onSwipeableWillOpen={() => {
                            }}> */}
                                                <View
                                                    style={{
                                                        // elevation: 1,
                                                        borderRadius: 5,
                                                        // backgroundColor: 'white',
                                                        // height: 300,
                                                        paddingHorizontal: 5,
                                                        paddingTop: switchMerchant ? 5 : 0,
                                                    }}>
                                                    <View
                                                        style={{
                                                            flexDirection: 'row',
                                                            height: switchMerchant
                                                                ? Dimensions.get('screen').height * 0.1
                                                                : Dimensions.get('screen').height * 0.06,
                                                            alignItems: 'center',
                                                            borderBottomColor: Colors.fieldtBgColor,
                                                            width: switchMerchant
                                                                ? Dimensions.get('screen').width * 0.8
                                                                : '100%',
                                                            alignSelf: 'center',
                                                        }}>
                                                        <View
                                                            style={{
                                                                width: switchMerchant ? '12%' : '14%',
                                                                marginHorizontal: switchMerchant ? 0 : 4,
                                                            }}>
                                                            <TextInput
                                                                style={{
                                                                    color: Colors.fontDark,
                                                                    fontSize: switchMerchant ? 10 : 16,
                                                                    fontFamily: 'NunitoSans-Bold',
                                                                    borderWidth: 1,
                                                                    borderRadius: 5,
                                                                    borderColor: '#E5E5E5',
                                                                    paddingLeft: switchMerchant ? 5 : 5,
                                                                    height: 40,
                                                                    width: switchMerchant
                                                                        ? Platform.OS === 'ios' ? 98
                                                                            : Dimensions.get('screen').width * 0.098
                                                                        : Platform.OS === 'ios'
                                                                            ? Dimensions.get('screen').width * 0.1
                                                                            : 150,
                                                                }}
                                                                placeholder={'Printer Name'}
                                                                placeholderTextColor={Colors.descriptionColor}
                                                                defaultValue={
                                                                    printerIdDict[item.uniqueId]
                                                                        ? printerIdDict[item.uniqueId].name
                                                                        : ''
                                                                }
                                                                onChangeText={(text) => {
                                                                    setPrinterIdDict({
                                                                        ...printerIdDict,
                                                                        [item.uniqueId]: {
                                                                            ...printerIdDict[item.uniqueId],
                                                                            name: text,
                                                                        },
                                                                    });
                                                                }}></TextInput>
                                                        </View>
                                                        <View style={{ width: '18%', marginHorizontal: 4 }}>
                                                            {/* <Text style={{ color: Colors.fontDark, fontSize: 16, fontFamily: 'NunitoSans-Bold' }}>
                                    168.88.8888.88
                                  </Text> */}

                                                            <TextInput
                                                                style={{
                                                                    color: Colors.fontDark,
                                                                    fontSize: switchMerchant ? 10 : 16,
                                                                    fontFamily: 'NunitoSans-Bold',
                                                                    borderWidth: 1,
                                                                    borderRadius: 5,
                                                                    borderColor: '#E5E5E5',
                                                                    paddingLeft: 5,
                                                                    height: 40,
                                                                    width: switchMerchant ? 127 : 140,
                                                                }}
                                                                placeholder={'Printer IP'}
                                                                placeholderTextColor={Platform.select({
                                                                    ios: '#a9a9a9',
                                                                })}
                                                                keyboardType={'number-pad'}
                                                                defaultValue={
                                                                    printerIdDict[item.uniqueId]
                                                                        ? printerIdDict[item.uniqueId].ip
                                                                        : ''
                                                                }
                                                                onChangeText={(text) => {
                                                                    setPrinterIdDict({
                                                                        ...printerIdDict,
                                                                        [item.uniqueId]: {
                                                                            ...printerIdDict[item.uniqueId],
                                                                            ip: text,
                                                                        },
                                                                    });
                                                                }}></TextInput>
                                                        </View>
                                                        <View
                                                            style={{
                                                                width: switchMerchant ? '8%' : '8%',
                                                                marginHorizontal: switchMerchant ? 0 : 4,
                                                            }}>
                                                            <TextInput
                                                                style={{
                                                                    color: Colors.fontDark,
                                                                    fontSize: switchMerchant ? 10 : 16,
                                                                    fontFamily: 'NunitoSans-Bold',
                                                                    borderWidth: 1,
                                                                    borderRadius: 5,
                                                                    borderColor: '#E5E5E5',
                                                                    paddingLeft: switchMerchant ? 5 : 5,
                                                                    height: 40,
                                                                    width: switchMerchant
                                                                        ? 58
                                                                        : Platform.OS === 'ios'
                                                                            ? Dimensions.get('screen').width * 0.06
                                                                            : 80,
                                                                }}
                                                                placeholder={'Kitchen'}
                                                                placeholderTextColor={Colors.descriptionColor}
                                                                defaultValue={
                                                                    printerIdDict[item.uniqueId]
                                                                        ? printerIdDict[item.uniqueId].area
                                                                        : ''
                                                                }
                                                                onChangeText={(text) => {
                                                                    setPrinterIdDict({
                                                                        ...printerIdDict,
                                                                        [item.uniqueId]: {
                                                                            ...printerIdDict[item.uniqueId],
                                                                            area: text,
                                                                        },
                                                                    });
                                                                }}></TextInput>
                                                        </View>

                                                        <View
                                                            style={{
                                                                width: '14%',
                                                                marginHorizontal: switchMerchant ? 4 : 4,
                                                                zIndex: 10001 - index,
                                                            }}>
                                                            {/* <DropDownPicker
                                                                containerStyle={[
                                                                    { height: 40 },
                                                                    switchMerchant ? { height: 35 } : {},
                                                                ]}
                                                                arrowColor={'black'}
                                                                arrowSize={20}
                                                                arrowStyle={{ fontWeight: 'bold' }}
                                                                labelStyle={{
                                                                    fontFamily: 'NunitoSans-Regular',
                                                                    fontSize: 14,
                                                                }}
                                                                style={[
                                                                    {
                                                                        width: '90%',
                                                                        paddingVertical: 0,
                                                                        backgroundColor: Colors.fieldtBgColor,
                                                                        borderRadius: 10,
                                                                        zIndex: 10001 - index,
                                                                    },
                                                                    switchMerchant
                                                                        ? {
                                                                            width: '90%',
                                                                        }
                                                                        : {},
                                                                ]}
                                                                placeholderStyle={{
                                                                    color: Colors.fieldtTxtColor,
                                                                    fontFamily: 'NunitoSans-Regular',
                                                                    fontSize: 14,
                                                                }}
                                                                //items={[{ label: 'Dine In', value: 1 }, { label: 'Takeaway', value: 2 }, { label: 'Delivery', value: 3 } ]}
                                                                items={PRINTER_USAGE_TYPE_DROPDOWN_LIST}
                                                                itemStyle={{
                                                                    justifyContent: 'flex-start',
                                                                    marginLeft: 5,
                                                                    zIndex: 2,
                                                                    fontFamily: 'NunitoSans-Regular',
                                                                    fontSize: 14,
                                                                }}
                                                                defaultValue={
                                                                    printerIdDict[item.uniqueId]
                                                                        ? printerIdDict[item.uniqueId].types
                                                                        : []
                                                                }
                                                                placeholder={'Type'}
                                                                multipleText={'%d type(s)'}
                                                                multiple={true}
                                                                customTickIcon={(press) => (
                                                                    <Ionicon
                                                                        name={'md-checkbox'}
                                                                        color={
                                                                            press
                                                                                ? Colors.fieldtBgColor
                                                                                : Colors.primaryColor
                                                                        }
                                                                        size={25}
                                                                    />
                                                                )}
                                                                onChangeItem={(items) => {
                                                                    setPrinterIdDict({
                                                                        ...printerIdDict,
                                                                        [item.uniqueId]: {
                                                                            ...printerIdDict[item.uniqueId],
                                                                            types: items,
                                                                        },
                                                                    });
                                                                }}
                                                                dropDownMaxHeight={100}
                                                                dropDownStyle={[
                                                                    {
                                                                        width: '90%',
                                                                        height: 100,
                                                                        backgroundColor: Colors.fieldtBgColor,
                                                                        borderRadius: 10,
                                                                        borderWidth: 1,
                                                                    },
                                                                    switchMerchant
                                                                        ? {
                                                                            width: '90%',
                                                                        }
                                                                        : {},
                                                                ]}
                                                                globalTextStyle={{
                                                                    fontSize: switchMerchant ? 10 : 14,
                                                                }}
                                                                zIndex={10001 - index}
                                                            /> */}
                                                            <View style={{ width: '90%' }}>
                                                                <Select
                                                                    options={PRINTER_USAGE_TYPE_DROPDOWN_LIST}
                                                                    onChange={(items) => {
                                                                        setPrinterIdDict({
                                                                            ...printerIdDict,
                                                                            [item.uniqueId]: {
                                                                                ...printerIdDict[item.uniqueId],
                                                                                types: items,
                                                                            },
                                                                        });
                                                                    }}
                                                                    isMulti
                                                                    placeholder={'Type'}
                                                                />
                                                            </View>
                                                        </View>

                                                        <View style={{ width: '14%', marginHorizontal: 4 }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    height: 35,
                                                                    width: 100,
                                                                    borderColor: '#E5E5E5',
                                                                    borderWidth: 1,
                                                                    borderRadius: 5,
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    borderColor: Colors.primaryColor,
                                                                    backgroundColor: Colors.primaryColor,
                                                                }}
                                                                onPress={() => {
                                                                    updateOutletPrinter(item.uniqueId);

                                                                    // bindPrinter(
                                                                    //   printerIdDict[item.uniqueId]
                                                                    //     ? printerIdDict[item.uniqueId].ip
                                                                    //     : '',

                                                                    bindPrinter(printerIdDict[item.uniqueId]);
                                                                }}>
                                                                <Text
                                                                    style={{
                                                                        color: Colors.whiteColor,
                                                                        fontSize: switchMerchant ? 10 : 16,
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                    }}>
                                                                    SAVE
                                                                </Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                        {/* <View style={{ width: '14%', marginHorizontal: 4 }}>
                                  <TouchableOpacity
                                    style={{
                                      height: 35,
                                      width: 100,
                                      borderColor: '#E5E5E5',
                                      borderWidth: 1,
                                      borderRadius: 5,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      borderColor: Colors.tabCyan,
                                      backgroundColor: Colors.tabCyan,
                                    }}
                                    onPress={() => {
                                      unbindPrinter();
                                    }}>
                                    <Text
                                      style={{
                                        color: Colors.whiteColor,
                                        fontSize: switchMerchant ? 10 : 16,
                                        fontFamily: 'NunitoSans-Bold',
                                      }}>
                                      UNBIND
                                    </Text>
                                  </TouchableOpacity>
                                </View> */}
                                                        <View style={{ width: '14%', marginHorizontal: 4 }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    height: 35,
                                                                    width: 100,
                                                                    borderColor: '#E5E5E5',
                                                                    borderWidth: 1,
                                                                    borderRadius: 5,
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    borderColor: Colors.tabGold,
                                                                    backgroundColor: Colors.tabGold,
                                                                }}
                                                                onPress={async () => {
                                                                    testPrinter(printerIdDict[item.uniqueId]);

                                                                    // await printShiftReport({
                                                                    //   outletName: currOutlet.name,
                                                                    //   outletAddress: currOutlet.address,
                                                                    //   outletPhone: currOutlet.phone,
                                                                    //   merchantName: merchantName,
                                                                    //   waiterName: userName,

                                                                    //   outletShiftNum: allOutletShifts.filter(o => o.outletId === currOutlet.uniqueId).length,

                                                                    //   taxRate: currOutlet.taxRate,
                                                                    //   taxActive: currOutlet.taxActive,
                                                                    //   scRate: currOutlet.scRate,
                                                                    //   scActive: currOutlet.scActive,

                                                                    //   outletShift: {
                                                                    //     closeAmount: 500,
                                                                    //     closeDate: 1641782980163,
                                                                    //     createdAt: 1640944818565,
                                                                    //     deletedAt: null,
                                                                    //     merchantId: '018a0354-8012-4785-b7a5-ba4ac5313d47',
                                                                    //     openAmount: 20,
                                                                    //     openDate: 1640944817173,
                                                                    //     outletId: 'b422c1d9-d30b-4de7-ad49-2e601d950919',
                                                                    //     uniqueId: '5c325eb9-9e1c-4a9d-8315-6dfa19e6f77f',
                                                                    //     updatedAt: 1641782981662,
                                                                    //   },
                                                                    // });
                                                                }}>
                                                                <Text
                                                                    style={{
                                                                        color: Colors.whiteColor,
                                                                        fontSize: switchMerchant ? 10 : 16,
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                    }}>
                                                                    {isLoading ? 'Loading...' : 'TEST'}
                                                                </Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                        <View style={{ width: '14%', marginHorizontal: 4 }}>
                                                            <TouchableOpacity
                                                                style={{
                                                                    height: 35,
                                                                    width: 100,
                                                                    borderColor: '#E5E5E5',
                                                                    borderWidth: 1,
                                                                    borderRadius: 5,
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    borderColor: Colors.tabRed,
                                                                    backgroundColor: Colors.tabRed,
                                                                }}
                                                                onPress={() => {
                                                                    Alert.alert(
                                                                        'Info',
                                                                        'Are you sure you want to remove this printer?',
                                                                        [
                                                                            {
                                                                                text: 'YES',
                                                                                onPress: () => {
                                                                                    deleteOutletPrinter(item.uniqueId);
                                                                                },
                                                                            },
                                                                            {
                                                                                text: 'NO',
                                                                                onPress: () => { },
                                                                            },
                                                                        ],
                                                                        { cancelable: true },
                                                                    );
                                                                }}>
                                                                <Text
                                                                    style={{
                                                                        color: Colors.whiteColor,
                                                                        fontSize: switchMerchant ? 10 : 16,
                                                                        fontFamily: 'NunitoSans-Bold',
                                                                    }}>
                                                                    REMOVE
                                                                </Text>
                                                            </TouchableOpacity>
                                                        </View>
                                                    </View>
                                                </View>
                                                {/* </Swipeable> */}
                                            </View>
                                        );
                                    })}
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>

            {/* add modal */}
            <Modal style={{ flex: 1 }} visible={addQueueModal} transparent={true}>
                <View style={[styles.modalContainer, {}]}>
                    <View
                        style={{
                            width: windowWidth * 0.4,
                            //height: Dimensions.get('screen').height * 0.47,
                            backgroundColor: Colors.whiteColor,
                            borderRadius: 10,
                            padding: windowWidth * 0.04,
                            paddingBottom: 0,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <View
                            style={{
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                            }}>
                            <Text
                                style={{
                                    fontSize: switchMerchant ? 16 : 24,
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    fontFamily: 'NunitoSans-Bold',
                                }}>
                                {/* {selectedQueue ? 'Queue Info' : 'Add Queue'} */}
                                Add Printer
                            </Text>
                            {/* <Text style={{ fontSize: 20, justifyContent: "center", alignSelf: "center", marginTop: 5, color: Colors.descriptionColor, fontFamily: 'NunitoSans-Regular' }}>
                  {`There are currently ${outletPrinters.length} parties in printer setting.`}
                </Text> */}
                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    alignContent: 'center',
                                    marginTop: 30,
                                    flexDirection: 'row',
                                    width: '100%',
                                }}>
                                <View style={{ justifyContent: 'center', width: '45%' }}>
                                    <Text
                                        style={{
                                            color: 'black',
                                            fontFamily: 'NunitoSans-Bold',
                                            fontSize: switchMerchant ? 10 : 20,
                                        }}>
                                        Printer Name
                                    </Text>
                                </View>
                                <View style={{ justifyContent: 'center', width: '60%' }}>
                                    <TextInput
                                        placeholder="HP 1102 PowerZ"
                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                        style={{
                                            fontSize: switchMerchant ? 10 : 20,
                                            backgroundColor: Colors.fieldtBgColor,
                                            width: switchMerchant ? 150 : 200,
                                            height: switchMerchant ? 35 : 40,
                                            borderRadius: 5,
                                            padding: 5,
                                            marginVertical: 5,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                            paddingLeft: 10,
                                        }}
                                        onChangeText={(text) => {
                                            // setQueueCustomerName(text);
                                            setPrinterName(text);
                                        }}
                                        defaultValue={printerName}
                                    />
                                </View>
                            </View>

                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    alignContent: 'center',
                                    marginTop: 20,
                                    flexDirection: 'row',
                                    width: '100%',
                                }}>
                                <View style={{ justifyContent: 'center', width: '45%' }}>
                                    <Text
                                        style={{
                                            color: 'black',
                                            fontFamily: 'NunitoSans-Bold',
                                            fontSize: switchMerchant ? 10 : 20,
                                        }}>
                                        Printer IP
                                    </Text>
                                </View>
                                <View style={{ justifyContent: 'center', width: '60%' }}>
                                    <TextInput
                                        placeholder="192.168.1.254"
                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                        style={{
                                            fontSize: switchMerchant ? 10 : 20,
                                            backgroundColor: Colors.fieldtBgColor,
                                            width: switchMerchant ? 150 : 200,
                                            height: switchMerchant ? 35 : 40,
                                            borderRadius: 5,
                                            padding: 5,
                                            marginVertical: 5,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                            paddingLeft: 10,
                                        }}
                                        keyboardType={'number-pad'}
                                        onChangeText={(text) => {
                                            // setQueuePhone(text);
                                            setPrinterIP(text);
                                        }}
                                        defaultValue={printerIP}
                                    />
                                </View>
                            </View>

                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignSelf: 'center',
                                    alignContent: 'center',
                                    marginTop: 20,
                                    flexDirection: 'row',
                                    width: '100%',
                                }}>
                                <View style={{ justifyContent: 'center', width: '45%' }}>
                                    <Text
                                        style={{
                                            color: 'black',
                                            fontFamily: 'NunitoSans-Bold',
                                            fontSize: switchMerchant ? 10 : 20,
                                        }}>
                                        Printer Area
                                    </Text>
                                </View>
                                <View style={{ justifyContent: 'center', width: '60%' }}>
                                    <TextInput
                                        placeholder="Kitchen"
                                        placeholderTextColor={Platform.select({ ios: '#a9a9a9' })}
                                        style={{
                                            fontSize: switchMerchant ? 10 : 20,
                                            backgroundColor: Colors.fieldtBgColor,
                                            width: switchMerchant ? 150 : 200,
                                            height: switchMerchant ? 35 : 40,
                                            borderRadius: 5,
                                            padding: 5,
                                            marginVertical: 5,
                                            borderWidth: 1,
                                            borderColor: '#E5E5E5',
                                            paddingLeft: 10,
                                        }}
                                        onChangeText={(text) => {
                                            // setQueuePhone(text);
                                            setPrinterArea(text);
                                        }}
                                        defaultValue={printerArea}
                                    />
                                </View>
                            </View>

                            {/* <View
                  style={{
                    justifyContent: 'center',
                    alignSelf: 'center',
                    alignContent: 'center',
                    marginTop: 20,
                    flexDirection: 'row',
                    width: '100%',
                  }}>
                  <View style={{ justifyContent: 'center', width: '45%' }}>
                    <Text
                      style={{
                        color: 'black',
                        fontFamily: 'NunitoSans-Bold',
                        fontSize: switchMerchant ? 10 : 20,
                      }}>
                      Printer Type(s)
                    </Text>
                  </View>
                  <View style={{ justifyContent: 'center', width: '60%' }}>
                    <DropDownPicker
                      containerStyle={[{ height: 40 }, switchMerchant ? { height: 35, } : {}]}
                      arrowColor={'black'}
                      arrowSize={20}
                      arrowStyle={{ fontWeight: 'bold' }}
                      labelStyle={{ fontFamily: 'NunitoSans-Regular', fontSize: 14 }}
                      style={[{
                        width: '90%', paddingVertical: 0, backgroundColor: Colors.fieldtBgColor,
                        borderRadius: 10,
                      }, switchMerchant ? {
                        width: '90%',
                      } : {}]}
                      placeholderStyle={{ color: Colors.fieldtTxtColor, fontFamily: 'NunitoSans-Regular', fontSize: 14 }}
                      //items={[{ label: 'Dine In', value: 1 }, { label: 'Takeaway', value: 2 }, { label: 'Delivery', value: 3 } ]}
                      items={PRINTER_USAGE_TYPE_DROPDOWN_LIST}
                      itemStyle={{ justifyContent: 'flex-start', marginLeft: 5, zIndex: 2, fontFamily: 'NunitoSans-Regular', fontSize: 14 }}
                      defaultValue={printerTypes}
                      placeholder={"Type"}
                      multipleText={'%d type(s)'}
                      multiple={true}
                      customTickIcon={(press) => <Ionicon name={"md-checkbox"} color={press ? Colors.fieldtBgColor : Colors.primaryColor} size={25} />}
                      onChangeItem={(items) => {
                        setPrinterTypes(items);
                      }}
                      dropDownMaxHeight={100}
                      dropDownStyle={[{ width: '90%', height: 100, backgroundColor: Colors.fieldtBgColor, borderRadius: 10, borderWidth: 1, }, switchMerchant ? {
                        width: '90%',
                      } : {}]}
                      globalTextStyle={{
                        fontSize: switchMerchant ? 10 : 14,
                      }}
                    />
                  </View>
                </View> */}

                            {/* <View style={{ justifyContent: "center", alignSelf: "center", alignContent: 'center', marginTop: 20, flexDirection: 'row', width: '100%' }}>
                  <View style={{ justifyContent: 'center', width: '45%' }}>
                    <Text style={{ color: 'black', fontFamily: 'NunitoSans-Bold', fontSize: 20, }}>
                      Capacity:
                    </Text>
                  </View>
                  <View style={{ justifyContent: 'center', width: '60%' }}>
                    <View style={{ flexDirection: 'row', }}>
                      <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', paddingRight: 10 }} onPress={() => {
                        setQueuePax(queuePax - 1 >= 0 ? queuePax - 1 : 0);
                      }}>
                        <MIcon name="minus-circle" size={20} color={Colors.primaryColor} />
                      </TouchableOpacity>
                      <View style={{
                        paddingRight: 10, alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                        <TextInput
                          placeholder='10'
                          placeholderTextColor={Colors.descriptionColor}
                          style={{
                            backgroundColor: Colors.fieldtBgColor,
                            width: 60,
                            height: 40,
                            borderRadius: 5,
                            padding: 5,
                            marginVertical: 5,
                            borderWidth: 1,
                            borderColor: '#E5E5E5',
                            paddingLeft:10,
                          }}
                          onChangeText={text => {
                            setQueuePax(text.length > 0 ? parseInt(text) : 0);
                          }}
                          defaultValue={queuePax.toFixed(0)}
                        />
                      </View>
                      <TouchableOpacity style={{ justifyContent: 'center', alignItems: 'center', }} onPress={() => {
                        setQueuePax(queuePax + 1);
                      }}>
                        <MIcon name="plus-circle" size={20} color={Colors.primaryColor} />
                      </TouchableOpacity>
                    </View>
                  </View>
                </View> */}

                            {/* <View style={{ justifyContent: "center", alignSelf: "center", alignContent: 'center', marginTop: 20, flexDirection: 'row', width: '100%' }}>
                  <View style={{ justifyContent: 'center', width: '45%' }}>
                    <Text style={{ color: 'black', fontFamily: 'NunitoSans-Bold', fontSize: 20, }}>
                      Room Type:
                    </Text>
                  </View>
                  <View style={{ justifyContent: 'center', width: '60%' }}>
                    <DropDownPicker
                      containerStyle={{ height: 40 }}
                      arrowColor={'black'}
                      arrowSize={20}
                      arrowStyle={{ fontWeight: 'bold' }}
                      labelStyle={{ fontFamily: 'NunitoSans-Regular', }}
                      style={{ marginTop: 0, width: 250, paddingVertical: 0, borderColor: Colors.fieldtBgColor, borderRadius: 10, backgroundColor: Colors.fieldtBgColor, }}
                      placeholderStyle={{ color: Colors.fieldtTxtColor }}
                      // items={crmUserTagsDropdownList}
                      items={[{ label: 'Normal Seats', value: 0 }, { label: 'VIP Room', value: 1 },]}
                      itemStyle={{ justifyContent: 'flex-start', marginLeft: 5 }}
                      // onChangeItem={item => {
                      //   setSelectedTargetUserGroup(item.value);
                      // }}
                      placeholder='Normal Seats'
                      dropDownMaxHeight={150}
                      dropDownStyle={{ width: 250, height: 40, borderRadius: 10, }}
                    />
                  </View>
                </View> */}

                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: 35,
                                }}>
                                <TouchableOpacity
                                    disabled={isLoading}
                                    onPress={() => {
                                        // setConfirmQueueModal(true)
                                        // setAddQueueModal(false)

                                        // if (selectedQueue === null) {
                                        //   createUserQueueByMerchant();
                                        // }
                                        // else {
                                        //   updateUserQueueByMerchant();
                                        // }

                                        createOutletPrinter();
                                    }}
                                    style={{
                                        backgroundColor: Colors.fieldtBgColor,
                                        width: '57.8%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        height: 60,
                                        borderBottomLeftRadius: 10,
                                        borderRightWidth: StyleSheet.hairlineWidth,
                                        borderTopWidth: StyleSheet.hairlineWidth,
                                    }}>
                                    {isLoading ? (
                                        <ActivityIndicator
                                            size={'large'}
                                            color={Colors.primaryColor}
                                        />
                                    ) : (
                                        <Text
                                            style={{
                                                fontSize: switchMerchant ? 15 : 22,
                                                color: Colors.primaryColor,
                                                fontFamily: 'NunitoSans-SemiBold',
                                            }}>
                                            Confirm
                                        </Text>
                                    )}
                                </TouchableOpacity>
                                <TouchableOpacity
                                    disabled={isLoading}
                                    onPress={() => {
                                        // setState({ visible: false });
                                        setAddQueueModal(false);
                                    }}
                                    style={{
                                        backgroundColor: Colors.fieldtBgColor,
                                        width: '57.8%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        height: 60,
                                        borderBottomRightRadius: 10,
                                        borderTopWidth: StyleSheet.hairlineWidth,
                                    }}>
                                    <Text
                                        style={{
                                            fontSize: switchMerchant ? 15 : 22,
                                            color: Colors.descriptionColor,
                                            fontFamily: 'NunitoSans-SemiBold',
                                        }}>
                                        Cancel
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>

            {/* confirm modal */}
            <Modal style={{ flex: 1 }} visible={confirmQueueModal} transparent={true}>
                <View style={styles.modalContainer}>
                    <View
                        style={{
                            width: Dimensions.get('screen').width * 0.4,
                            height: Dimensions.get('screen').height * 0.4,
                            backgroundColor: Colors.whiteColor,
                            borderRadius: Dimensions.get('screen').width * 0.03,
                            padding: Dimensions.get('screen').width * 0.04,
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                        <View style={{}}>
                            <View style={{ height: Dimensions.get('screen').height * 0.1 }}>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: '700',
                                        fontSize: 30,
                                    }}>
                                    Done!
                                </Text>
                            </View>

                            <View
                                style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    alignSelf: 'center',
                                }}>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: Colors.descriptionColor,
                                        fontSize: 25,
                                        width: '80%',
                                        alignSelf: 'center',
                                    }}>
                                    You’ve added queue
                                </Text>
                                <Text
                                    style={{
                                        textAlign: 'center',
                                        color: Colors.descriptionColor,
                                        fontSize: 25,
                                        width: '80%',
                                        alignSelf: 'center',
                                    }}>
                                    successfully with number:{' '}
                                    {selectedQueue ? `#${selectedQueue.number}` : 'N/A'}
                                    {/* {item.number} */}
                                </Text>
                            </View>

                            <View
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: Dimensions.get('screen').height * 0.25,
                                    width: Dimensions.get('screen').width * 0.4,
                                }}>
                                <TouchableOpacity
                                    onPress={() => {
                                        setConfirmQueueModal(false);
                                    }}
                                    style={{
                                        backgroundColor: Colors.fieldtBgColor,
                                        width: Dimensions.get('screen').width * 0.4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        height: 60,
                                        borderBottomLeftRadius: 35,
                                        borderBottomRightRadius: 35,
                                        borderTopWidth: StyleSheet.hairlineWidth,
                                    }}>
                                    <Text
                                        style={{
                                            fontSize: 22,
                                            color: Colors.primaryColor,
                                            fontFamily: 'NunitoSans-SemiBold',
                                        }}>
                                        Confirm
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.highlightColor,
        flexDirection: 'row',
    },
    headerLogo: {
        width: 112,
        height: 25,
        marginLeft: 10,
    },
    list: {
        paddingVertical: 20,
        paddingHorizontal: 30,
        flexDirection: 'row',
        alignItems: 'center',
    },
    listItem: {
        marginLeft: 20,
        color: Colors.descriptionColor,
        fontSize: 16,
    },
    content7: {
        backgroundColor: '#e4e8eb',
        width: 800,
        height: 120,
        marginLeft: 50,
        marginVertical: 30,
        borderRadius: 20,
    },

    content8: {
        flex: 3,
        backgroundColor: '#e4e8eb',

        height: 120,
    },
    content9: {
        flex: 1,
        backgroundColor: Colors.primaryColor,

        height: 120,
    },
    content10: {
        flex: 1,
        backgroundColor: Colors.secondaryColor,

        height: 120,
    },
    content11: {
        flex: 1,
        backgroundColor: '#848f96',

        height: 120,
    },
    content6: {
        backgroundColor: Colors.whiteColor,
        width: 120,
        shadowColor: '#000',
        shadowOffset: {
            width: 8,
            height: 8,
        },
        shadowOpacity: 0.55,
        shadowRadius: 10.32,
        width: 120,
        height: 120,
        marginLeft: 50,
        marginVertical: 15,
        borderRadius: 5,
    },

    sidebar: {
        width: Dimensions.get('screen').width * Styles.sideBarWidth,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
    content: {
        width: Dimensions.get('screen').width * 0.87,
        alignSelf: 'center',
        padding: 16,
        marginHorizontal: 15,
    },
    headerLeftStyle: {
        width: Dimensions.get('screen').width * 0.17,
        justifyContent: 'center',
        alignItems: 'center',
    },
    submitText: {
        height:
            Platform.OS == 'ios'
                ? Dimensions.get('screen').height * 0.06
                : Dimensions.get('screen').height * 0.05,
        left: 295,
        paddingVertical: 5,
        paddingHorizontal: 20,
        flexDirection: 'row',
        color: '#4cd964',
        textAlign: 'center',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: Colors.primaryColor,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
    modalContainer: {
        flex: 1,
        backgroundColor: Colors.modalBgColor,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default SettingPrinterScreen;
