import firebase from "firebase";
// import storage from '@react-native-firebase/storage';
// import auth from '@react-native-firebase/auth';
import { Collections } from '../constant/firebase';
import { UserStore } from '../store/userStore';
import * as User from './User';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import { MerchantStore } from '../store/merchantStore';
// import { OutletStore } from '../store/outletStore';
import {
  // OUTLET_SHIFT_STATUS,
  // ROLE_TYPE,
  // USER_ORDER_STATUS,
  // USER_QUEUE_STATUS,
  // USER_RESERVATION_STATUS,
  // USER_RING_STATUS,
  // NOTIFICATIONS_TYPE,
  // NOTIFICATIONS_ID,
  // NOTIFICATIONS_CHANNEL,
  // ORDER_TYPE_PARSED,
  ORDER_TYPE,
} from '../constant/common';
// import { CommonStore } from '../store/commonStore';
// import API from '../constant/API';
import moment from 'moment';
// import messaging from '@react-native-firebase/messaging';
// import PushNotification from 'react-native-push-notification';
// import { NotificationStore } from '../store/notificationStore';
// const PNG = require('pngjs').PNG;
import {
  // USBPrinter,
  NetPrinter,
  BLEPrinter,
} from 'react-native-thermal-receipt-printer-image-qr';
import { CODEPAGE, ESCPOS_CMD, PRINTER_USAGE_TYPE } from '../constant/printer';
// import { CustomTextEncoder } from '../lib/text-encoding';

var Buffer = require("buffer/").Buffer;
// const EscPosEncoder = require('esc-pos-encoder');
// const iconv = require('iconv-lite');
const iconv = require('iconv-lite');

export const testPrinterReceipt = async (msg) => {
  try {
    console.log('init printers format');

    // var result = encoder
    // .codepage(CODEPAGE.CP936)
    // .text('')
    // .encode();
  } catch (ex) {
    console.error(ex);
  }
};

export const convertUtf8ArrayToStr = (array) => {
  var out, i, len, c;
  var char2, char3;

  out = '';
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0),
        );
        break;
    }
  }

  return out;
};

const $ = String.fromCharCode;

const charWidth = 12;
const gradient = true;
const gamma = 1.8;
const threshold = 128;
const split = 512;

// set print area: GS L nL nH GS W nL nH
const area = (left, width, right) => {
  const m = left * charWidth;
  const w = width * charWidth;
  return '\x1dL' + $(m & 255, m >> 8 & 255) + '\x1dW' + $(w & 255, w >> 8 & 255);
};

const alignFunc = alignParam => '\x1ba' + $(alignParam);

export const getPrinterImageData = (img, align, left, width, right, upsideDown = false) => {
  let r = upsideDown ? area(right, width, left) + alignFunc(2 - align) : area(left, width, right) + alignFunc(align);
  // const img = PNG.sync.read(Buffer.from(image, 'base64'));
  const w = img.width;
  const d = Array(w).fill(0);
  let j = upsideDown ? img.data.length - 4 : 0;
  for (let z = 0; z < img.height; z += split) {
    const h = Math.min(split, img.height - z);
    const l = (w + 7 >> 3) * h + 10;
    r += '\x1d8L' + $(l & 255, l >> 8 & 255, l >> 16 & 255, l >> 24 & 255, 48, 112, 48, 1, 1, 49, w & 255, w >> 8 & 255, h & 255, h >> 8 & 255);
    for (let y = 0; y < h; y++) {
      let i = 0, e = 0;
      for (let x = 0; x < w; x += 8) {
        let b = 0;
        const q = Math.min(w - x, 8);
        for (let p = 0; p < q; p++) {
          const f = Math.floor((d[i] + e * 5) / 16 + Math.pow(((img.data[j] * .299 + img.data[j + 1] * .587 + img.data[j + 2] * .114 - 255) * img.data[j + 3] + 65525) / 65525, 1 / gamma) * 255);
          j += upsideDown ? -4 : 4;
          if (gradient) {
            d[i] = e * 3;
            e = f < threshold ? (b |= 128 >> p, f) : f - 255;
            if (i > 0) {
              d[i - 1] += e;
            }
            d[i++] += e * 7;
          }
          else {
            if (f < threshold) {
              b |= 128 >> p;
            }
          }
        }
        r += $(b);
      }
    }
    r += '\x1d(L' + $(2, 0, 48, 50);
  }
  return r;
};

export const printBarcode = (symbol, encoding) => {
  let d = iconv.encode(symbol.data, encoding).toString('binary');
  const b =
    bartype[symbol.type] +
    (/upc|[ej]an/.test(symbol.type) && symbol.data.length < 9);
  switch (b) {
    case bartype.upc + 1:
      d = upce(d);
      break;
    case bartype.code128:
      d = code128(d);
      break;
    default:
      break;
  }
  d = d.slice(0, 255);
  return (
    '\x1dw' +
    $(symbol.width) +
    '\x1dh' +
    $(symbol.height) +
    '\x1dH' +
    $(symbol.hri ? 2 : 0) +
    '\x1dk' +
    $(b, d.length) +
    d
  );
};

const bartype = {
  upc: 65,
  ean: 67,
  jan: 67,
  code39: 69,
  itf: 70,
  codabar: 71,
  nw7: 71,
  code93: 72,
  code128: 73,
};
// generate UPC-E data (convert UPC-E to UPC-A):
const upce = (data) => {
  let r = '';
  let s = data.replace(/((?!^0\d{6,7}$).)*/, '');
  if (s.length > 0) {
    r += s.slice(0, 3);
    switch (s[6]) {
      case '0':
      case '1':
      case '2':
        r += s[6] + '0000' + s[3] + s[4] + s[5];
        break;
      case '3':
        r += s[3] + '00000' + s[4] + s[5];
        break;
      case '4':
        r += s[3] + s[4] + '00000' + s[5];
        break;
      default:
        r += s[3] + s[4] + s[5] + '0000' + s[6];
        break;
    }
  }
  return r;
};
// CODE128 special characters:
const c128 = {
  special: 123,
  codea: 65,
  codeb: 66,
  codec: 67,
  shift: 83,
};
// generate CODE128 data (minimize symbol width):
const code128 = (data) => {
  let r = '';
  let s = data.replace(/((?!^[\x00-\x7f]+$).)*/, '').replace(/{/g, '{{');
  if (s.length > 0) {
    const d = [];
    const p = s.search(/[^ -_]/);
    if (/^\d{2}$/.test(s)) {
      d.push(c128.special, c128.codec, Number(s));
    } else if (/^\d{4,}/.test(s)) {
      code128c(c128.codec, s, d);
    } else if (p >= 0 && s.charCodeAt(p) < 32) {
      code128a(c128.codea, s, d);
    } else if (s.length > 0) {
      code128b(c128.codeb, s, d);
    } else {
      // end
    }
    r = d.map((c) => $(c)).join('');
  }
  return r;
};
// process CODE128 code set A:
const code128a = (x, s, d) => {
  if (x !== c128.shift) {
    d.push(c128.special, x);
  }
  s = s.replace(
    /^((?!\d{4,})[\x00-_])+/,
    (m) => (m.split('').forEach((c) => d.push(c.charCodeAt(0))), ''),
  );
  s = s.replace(/^\d(?=\d{4}(\d{2})*)/, (m) => (d.push(m.charCodeAt(0)), ''));
  const t = s.slice(1);
  const p = t.search(/[^ -_]/);
  if (/^\d{4,}/.test(s)) {
    code128c(c128.codec, s, d);
  } else if (p >= 0 && t.charCodeAt(p) < 32) {
    d.push(c128.special, c128.shift, s.charCodeAt(0));
    code128a(c128.shift, t, d);
  } else if (s.length > 0) {
    code128b(c128.codeb, s, d);
  } else {
    // end
  }
};
// process CODE128 code set B:
const code128b = (x, s, d) => {
  if (x !== c128.shift) {
    d.push(c128.special, x);
  }
  s = s.replace(
    /^((?!\d{4,})[ -\x7f])+/,
    (m) => (m.split('').forEach((c) => d.push(c.charCodeAt(0))), ''),
  );
  s = s.replace(/^\d(?=\d{4}(\d{2})*)/, (m) => (d.push(m.charCodeAt(0)), ''));
  const t = s.slice(1);
  const p = t.search(/[^ -_]/);
  if (/^\d{4,}/.test(s)) {
    code128c(c128.codec, s, d);
  } else if (p >= 0 && t.charCodeAt(p) > 95) {
    d.push(c128.special, c128.shift, s.charCodeAt(0));
    code128b(c128.shift, t, d);
  } else if (s.length > 0) {
    code128a(c128.codea, s, d);
  } else {
    // end
  }
};
// process CODE128 code set C:
const code128c = (x, s, d) => {
  if (x !== c128.shift) {
    d.push(c128.special, x);
  }
  s = s.replace(/^\d{4,}/g, (m) =>
    m.replace(/\d{2}/g, (c) => (d.push(Number(c)), '')),
  );
  const p = s.search(/[^ -_]/);
  if (p >= 0 && s.charCodeAt(p) < 32) {
    code128a(c128.codea, s, d);
  } else if (s.length > 0) {
    code128b(c128.codeb, s, d);
  } else {
    // end
  }
};

export const connectToPrinter = async (ipToUsed = '') => {
  try {
    return await new Promise(async (resolve, reject) => {
      const printerIP = ipToUsed || (await AsyncStorage.getItem('printerIP')) || '';

      if (printerIP) {
        // await fetch(`http://${printerIP}:80/info_htm`)
        //   .then((response) => {
        //     if (response.status === 200) {
        //       console.log('success');
        //     } else {
        //       console.log('error');
        //     }

        //     NetPrinter.connectPrinter(printerIP, 9100).then(
        //       (printer) => {
        //         console.log('printer status');
        //         console.log(printer);

        //         resolve(true);
        //       },
        //       (error) => {
        //         console.log('printer error');
        //         console.log(error);

        //         resolve(false);
        //       },
        //     );
        //   })
        //   .catch((error) => {
        //     console.log('network error: ' + error);

        //     resolve(false);
        //   });

        // NetPrinter.init();

        // setTimeout(() => {
        //   console.log('connect to printer timeout');
        //   resolve(true);
        // }, 1000); // default if wait more than 1 second, just proceed

        NetPrinter.connectPrinter(printerIP, 9100).then(
          (printer) => {
            console.log('printer status');
            console.log(printer);

            resolve(true);
          },
          (error) => {
            console.log('printer error');
            console.log(error);

            resolve(false);
          },
        );
      }
    });
  } catch (ex) {
    console.log(ex);

    return false;
  }
};
export const isPortOpen = async (printerIP, callbackSuccess, callbackFail) => {
  try {
    return await new Promise(async (resolve, reject) => {
      if (printerIP) {
        NetPrinter.connectPrinter(printerIP, 9100, 500).then(
          (printer) => {
            console.log('printer status');
            console.log(printer);

            callbackSuccess();

            resolve(true);
            // resolve(printer);
          },
          (error) => {
            console.log('printer error');
            console.log(error);

            callbackFail();

            resolve(false);
          },
        );
      }
    });
  } catch (ex) {
    console.log(ex);

    return false;
  }
};

export const printUserOrder = async (msgData, isSplitReceipt = false, printerUsageTypeList = [PRINTER_USAGE_TYPE.RECEIPT]) => {
  console.log('print user order');

  const userOrderSnapshot = await firebase.firestore()
    .collection(Collections.UserOrder)
    .where('uniqueId', '==', msgData.orderId)
    .limit(1)
    .get();

  var userOrder = null;
  if (!userOrderSnapshot.empty) {
    userOrder = userOrderSnapshot.docs[0].data();
  }

  if (userOrder) {
    var availablePrinterIpDict = {};
    var defaultReceiptPrinter = null;

    var availablePrinterIdList = [];
    const printerListRaw = await AsyncStorage.getItem('printerList');
    if (printerListRaw) {
      availablePrinterIdList = JSON.parse(printerListRaw);
    }

    var availablePrinterList = [];
    for (var i = 0; i < availablePrinterIdList.length; i++) {
      const printerRaw = await AsyncStorage.getItem(availablePrinterIdList[i]);
      var printer = null;
      if (printerListRaw) {
        printer = JSON.parse(printerRaw);
        availablePrinterList.push(printer);

        availablePrinterIpDict[printer.ip] = printer;

        // if (!defaultReceiptPrinter && printer.types.includes(PRINTER_USAGE_TYPE.RECEIPT)) {
        //   defaultReceiptPrinter = printer;
        // }
        if (!defaultReceiptPrinter) {
          defaultReceiptPrinter = printer;
        }
      }
    }

    var printerIPList = [];

    for (var i = 0; i < userOrder.cartItems.length; i++) {
      const outletItemSnapshot = await firebase.firestore()
        .collection(Collections.OutletItem)
        .where('uniqueId', '==', userOrder.cartItems[i].itemId)
        .limit(1)
        .get();

      var outletItem = null;
      if (!outletItemSnapshot.empty) {
        outletItem = outletItemSnapshot.docs[0].data();
      }

      if (outletItem && outletItem.printerArea) {
        // const printerAreaIP = (await AsyncStorage.getItem(userOrder.cartItems[i].printerArea)) || '';

        // if (printerAreaIP) {
        //   printerIPList.push(printerAreaIP);
        // }

        for (var j = 0; j < availablePrinterList.length; j++) {
          if (userOrder.cartItems[i].printerArea &&
            availablePrinterList[j].area === userOrder.cartItems[i].printerArea) {
            printerIPList.push(availablePrinterList[j].ip);
          }
        }
      }
    }

    if (printerIPList.length <= 0 && defaultReceiptPrinter) {
      // printerIPList.push(''); // default printer

      printerIPList.push(defaultReceiptPrinter.ip);
    }

    printerIPList = [...new Set(printerIPList)]; // unique

    // const merchantLogoData = await AsyncStorage.getItem('merchantLogoData');    

    for (var indexPrinter = 0; indexPrinter < printerIPList.length; indexPrinter++) {
      console.log('connecting printer...');

      connectToPrinter(printerIPList[indexPrinter]);

      console.log('getting printer ip...');

      // const printerIP = (await AsyncStorage.getItem('printerIP')) || '';

      console.log('getting merchantLogoData...');

      // const merchantLogoImageElement = await AsyncStorage.getItem('merchantLogoImageElement');      

      for (var indexPrinterUsageType = 0; indexPrinterUsageType < printerUsageTypeList.length; indexPrinterUsageType++) {
        const printerUsageType = printerUsageTypeList[indexPrinterUsageType];

        if (printerIPList[indexPrinter] && availablePrinterIpDict[printerIPList[indexPrinter]]) {
          const printer = availablePrinterIpDict[printerIPList[indexPrinter]];

          for (var indexPrinterType = 0; indexPrinterType < printer.types.length; indexPrinterType++) {
            if (printer.types[indexPrinterType] === PRINTER_USAGE_TYPE.RECEIPT &&
              printerUsageType === PRINTER_USAGE_TYPE.RECEIPT) {
              try {
                const koodooLogoUrl = await AsyncStorage.getItem('koodooLogoUrl');

                const merchantLogoUrl = await AsyncStorage.getItem('merchantLogoUrl');

                var result = `${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.UNDERLINE_OFF}${ESCPOS_CMD.BOLD_OFF}`;

                if (merchantLogoUrl) {
                  await NetPrinter.printImage(merchantLogoUrl);
                }

                // if (merchantLogoImageElement) {
                //   // result += `${getPrinterImageData(JSON.parse(merchantLogoData), 0, 0, 0, 0)}`

                //   const encoder = new EscPosEncoder({
                //     // imageMode: 'raster',
                //   });

                //   const merchantLogoImageElementParsed = JSON.parse(merchantLogoImageElement);

                //   const imageBytes = encoder
                //     .initialize()
                //     .image(merchantLogoImageElementParsed, 256, 256, 'atkinson')
                //     .encode();

                //   // const imageBase64 = Buffer.from(imageBytes).toString('base64');            

                //   await NetPrinter.printBuffer(Buffer.from(imageBytes).toString('base64'));
                // }

                // if (merchantLogoData) {
                //   const encoder = new EscPosEncoder({
                //     // imageMode: 'raster',
                //   });

                //   const merchantLogoDataParsed = JSON.parse(merchantLogoData);

                //   const imageBytes = encoder
                //     .initialize()
                //     .image(merchantLogoDataParsed, 128, 128, 'atkinson')
                //     .encode();

                //   // const imageBase64 = Buffer.from(imageBytes).toString('base64');            

                //   await NetPrinter.printBuffer(Buffer.from(imageBytes).toString('base64'));
                // }
                result += `${ESCPOS_CMD.CENTER}${userOrder.outletName}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.CENTER}${userOrder.outletAddress ? userOrder.outletAddress : 'N/A'
                  }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.CENTER}${userOrder.outletPhone ? userOrder.outletPhone : 'N/A'
                  }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.CENTER}${userOrder.merchantName}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.CENTER}SST ID No. ${userOrder.outletTaxNumber ? userOrder.outletTaxNumber : 'N/A'
                  }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}ORDER #${userOrder.orderId}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
                  .map((i) => '-')
                  .join('')}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${userOrder.userName ? userOrder.userName : 'N/A'
                  }${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${userOrder.userPhone ? userOrder.userPhone : 'N/A'
                  }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'Receipt Date'.padEnd(12, ' ')} : ${moment(
                  userOrder.orderDate,
                ).format('ddd, MMM D, YYYY')}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'Receipt #'.padEnd(12, ' ')} : ${'#'}${userOrder.orderId
                  }${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'Cashier'.padEnd(12, ' ')} : ${userOrder.waiterName ? userOrder.waiterName : 'N/A'
                  }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'ITEM'.padEnd(20, ' ')}${'PRICE'.padEnd(
                  8,
                  ' ',
                )}${'DISC'.padEnd(8, ' ')}${'QTY'.padEnd(4, ' ')}${'SUB'.padStart(
                  8,
                  ' ',
                )}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                  .map((i) => '-')
                  .join('')}${ESCPOS_CMD.NEWLINE}`;

                for (var i = 0; i < userOrder.cartItems.length; i++) {
                  const cartItem = userOrder.cartItems[i];

                  // result += `${ESCPOS_CMD.LEFT}${padEndUnicodeString(sliceUnicodeString(cartItem.name, 0, 18), 20, ' ')}${(
                  //   '' + (cartItem.price / cartItem.quantity).toFixed(2)
                  // ).padEnd(8, ' ')}${(
                  //   '' + (cartItem.discount ? cartItem.discount : 0).toFixed(2)
                  // ).padEnd(8, ' ')}${cartItem.quantity.toString().padEnd(4, ' ')}${(
                  //   '' + cartItem.price.toFixed(2)
                  // ).padStart(8, ' ')}${ESCPOS_CMD.NEWLINE}`;

                  result += `${ESCPOS_CMD.LEFT}${padEndUnicodeString(sliceUnicodeString(cartItem.name, 0, 48), 48, ' ')}${ESCPOS_CMD.NEWLINE}`;

                  if (cartItem.remarks) {
                    //   result += `${ESCPOS_CMD.LEFT}${cartItem.remarks
                    //     .slice(0, 19)
                    //     .padEnd(20, ' ')}${''.padEnd(8, ' ')}${''.padEnd(
                    //     8,
                    //     ' ',
                    //   )}${''.padEnd(4, ' ')}${''.padEnd(8, ' ')}${ESCPOS_CMD.NEWLINE}`;

                    result += `${ESCPOS_CMD.LEFT}${padEndUnicodeString(sliceUnicodeString(cartItem.remarks, 0, 48), 48, ' ')}${ESCPOS_CMD.NEWLINE}`;
                  }

                  if (cartItem.addOns) {
                    result += `${ESCPOS_CMD.NEWLINE}`; // more tidy

                    for (var j = 0; j < cartItem.addOns.length; j++) {
                      var addOnStr = cartItem.addOns[j].name + ': ';

                      addOnStr += cartItem.addOns[j].choiceNames.join(', ');

                      // result += `${ESCPOS_CMD.LEFT}${addOnStr
                      //   .slice(0, 19)
                      //   .padEnd(20, ' ')}${''.padEnd(8, ' ')}${''.padEnd(
                      //   8,
                      //   ' ',
                      // )}${''.padEnd(4, ' ')}${''.padEnd(8, ' ')}${ESCPOS_CMD.NEWLINE}`;

                      result += `${ESCPOS_CMD.LEFT}${padEndUnicodeString(sliceUnicodeString(addOnStr, 0, 48), 48, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    }
                  }

                  result += `${ESCPOS_CMD.LEFT}${''.padEnd(20, ' ')}${(
                    '' + (cartItem.price / cartItem.quantity).toFixed(2)
                  ).padEnd(8, ' ')}${(
                    '' + (cartItem.discount ? cartItem.discount : 0).toFixed(2)
                  ).padEnd(8, ' ')}${cartItem.quantity.toString().padEnd(4, ' ')}${(
                    '' + cartItem.price.toFixed(2)
                  ).padStart(8, ' ')}${ESCPOS_CMD.NEWLINE}`;

                  if (i !== userOrder.cartItems.length - 1) {
                    result += `${ESCPOS_CMD.NEWLINE}`;
                  }
                }

                result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                  .map((i) => '-')
                  .join('')}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'Subtotal'.padEnd(24, ' ')}${(
                  'RM ' + userOrder.totalPrice.toFixed(2)
                ).padStart(24, ' ')}`;
                result += `${ESCPOS_CMD.LEFT}${'Discount'.padEnd(24, ' ')}${(
                  'RM ' + userOrder.discount.toFixed(2)
                ).padStart(24, ' ')}`;

                if (userOrder.orderType === ORDER_TYPE.DELIVERY) {
                  result += `${ESCPOS_CMD.LEFT}${'Discount'.padEnd(24, ' ')}${(
                    'RM ' + userOrder.deliveryFee.toFixed(2)
                  ).padStart(24, ' ')}`;
                }

                result += `${ESCPOS_CMD.LEFT}${'Tax (6%)'.padEnd(24, ' ')}${(
                  'RM ' + userOrder.tax.toFixed(2)
                ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}${'Total'.padEnd(
                  12,
                  ' ',
                )}${('RM ' + userOrder.finalPrice.toFixed(2)).padStart(12, ' ')}${ESCPOS_CMD.NEWLINE
                  }`;
                result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
                  .map((i) => '-')
                  .join('')}${ESCPOS_CMD.NEWLINE}`;

                var amountReceived = userOrder.finalPrice;
                var amountBalance = 0;

                if (
                  isSplitReceipt &&
                  userOrder.splitAmountList &&
                  userOrder.splitAmountList.length > 0
                ) {
                  amountReceived =
                    userOrder.splitAmountList[userOrder.splitAmountList.length - 1]
                      .amount;

                  var totalPaidAmount = userOrder.splitAmountList.reduce(
                    (accum, splitAmount) => accum + splitAmount.amount,
                    0,
                  );
                  amountBalance = userOrder.finalPrice - totalPaidAmount;
                }

                //   if (userOrder.paymentDetails) {
                //     amountReceived = userOrder.finalPrice;
                //     amountBalance = 0;
                //   }
                //   else {
                //     amountReceived = 0;
                //     amountBalance = userOrder.finalPrice;
                //   }

                result += `${ESCPOS_CMD.LEFT}${'Received'.padEnd(24, ' ')}${(
                  'RM ' + amountReceived.toFixed(2)
                ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'Balance'.padEnd(24, ' ')}${(
                  'RM ' + amountBalance.toFixed(2)
                ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                result += `${ESCPOS_CMD.LEFT}${'Notes:'}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'1.'}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'2.'}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'3.'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE
                  }${ESCPOS_CMD.NEWLINE}`;

                result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_2H
                  }${'Thank you for your order'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.BARCODE_HEIGHT}${ESCPOS_CMD.BARCODE_WIDTH}${ESCPOS_CMD.BARCODE_FONT_A}${ESCPOS_CMD.BARCODE_TXT_OFF}${ESCPOS_CMD.BARCODE_EAN13}${userOrder.receiptId}${ESCPOS_CMD.NEWLINE}`;

                // result += `${ESCPOS_CMD.CENTER}${printBarcode({
                //   data: 'TEST12345',
                //   type: 'ean',
                // }, 'cp936')}${ESCPOS_CMD.NEWLINE}`;   

                console.log('on print receipt');

                NetPrinter.printText(result, {
                  encoding: 'GB18030',
                });

                ////////////////////////////////////////////////////////////

                result = '';
                if (koodooLogoUrl) {
                  console.log('on print koodoo logo');

                  await NetPrinter.printImage(koodooLogoUrl);
                  result += `${ESCPOS_CMD.CENTER}Powered by KooDoo${ESCPOS_CMD.NEWLINE}`;
                }

                result += `${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                result += `${ESCPOS_CMD.CUT_PARTIAL}`;

                NetPrinter.printText(result, {
                  encoding: 'GB18030',
                });

                ////////////////////////////////////////////////////////////
              } catch (ex) {
                console.log(ex);
              }
            }
            else if (printer.types[indexPrinterType] === PRINTER_USAGE_TYPE.KITCHEN_DOCKET &&
              printerUsageType === PRINTER_USAGE_TYPE.KITCHEN_DOCKET) {
              try {
                var result = `${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.UNDERLINE_OFF}${ESCPOS_CMD.BOLD_OFF}`;
                // if (merchantLogoImageElement) {
                //   // result += `${getPrinterImageData(JSON.parse(merchantLogoData), 0, 0, 0, 0)}`

                //   const encoder = new EscPosEncoder({
                //     // imageMode: 'raster',
                //   });

                //   const merchantLogoImageElementParsed = JSON.parse(merchantLogoImageElement);

                //   const imageBytes = encoder
                //     .initialize()
                //     .image(merchantLogoImageElementParsed, 256, 256, 'atkinson')
                //     .encode();

                //   // const imageBase64 = Buffer.from(imageBytes).toString('base64');            

                //   await NetPrinter.printBuffer(Buffer.from(imageBytes).toString('base64'));
                // }

                // if (merchantLogoData) {
                //   const encoder = new EscPosEncoder({
                //     // imageMode: 'raster',
                //   });

                //   const merchantLogoDataParsed = JSON.parse(merchantLogoData);

                //   const imageBytes = encoder
                //     .initialize()
                //     .image(merchantLogoDataParsed, 128, 128, 'atkinson')
                //     .encode();

                //   // const imageBase64 = Buffer.from(imageBytes).toString('base64');            

                //   await NetPrinter.printBuffer(Buffer.from(imageBytes).toString('base64'));
                // }

                // result += `${ESCPOS_CMD.CENTER}${userOrder.outletName}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.CENTER}${userOrder.outletAddress ? userOrder.outletAddress : 'N/A'
                //   }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.CENTER}${userOrder.outletPhone ? userOrder.outletPhone : 'N/A'
                //   }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.CENTER}${userOrder.merchantName}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.CENTER}SST ID No. ${userOrder.outletTaxNumber ? userOrder.outletTaxNumber : 'N/A'
                //   }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}TABLE: ${userOrder.tableCode ? userOrder.tableCode : 'N/A'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}ORDER #${userOrder.orderId}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
                //   .map((i) => '-')
                //   .join('')}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${userOrder.userName ? userOrder.userName : 'N/A'
                //   }${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${userOrder.userPhone ? userOrder.userPhone : 'N/A'
                //   }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${moment(userOrder.orderDate).format('YYYY.MM.DD')}  ${moment(userOrder.orderDate).format('HH:mm:ss')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'Receipt Date'.padEnd(12, ' ')} : ${moment(
                //   userOrder.orderDate,
                // ).format('ddd, MMM D, YYYY')}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'Receipt #'.padEnd(12, ' ')} : ${'#'}${userOrder.orderId
                //   }${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'Cashier'.padEnd(12, ' ')} : ${userOrder.waiterName ? userOrder.waiterName : 'N/A'
                  }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'ITEM'.padEnd(20, ' ')}${''.padEnd(
                  8,
                  ' ',
                )}${''.padEnd(8, ' ')}${''.padEnd(4, ' ')}${'QTY'.padStart(
                  8,
                  ' ',
                )}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                  .map((i) => '-')
                  .join('')}${ESCPOS_CMD.NEWLINE}`;

                for (var i = 0; i < userOrder.cartItems.length; i++) {
                  const cartItem = userOrder.cartItems[i];

                  result += `${ESCPOS_CMD.LEFT}${padEndUnicodeString(sliceUnicodeString(cartItem.name, 0, 42), 44, ' ')}${('' + cartItem.quantity).padStart(4, ' ')}${ESCPOS_CMD.NEWLINE}`;

                  if (cartItem.remarks) {
                    //   result += `${ESCPOS_CMD.LEFT}${cartItem.remarks
                    //     .slice(0, 19)
                    //     .padEnd(20, ' ')}${''.padEnd(8, ' ')}${''.padEnd(
                    //     8,
                    //     ' ',
                    //   )}${''.padEnd(4, ' ')}${''.padEnd(8, ' ')}${ESCPOS_CMD.NEWLINE}`;

                    result += `${ESCPOS_CMD.LEFT}${padEndUnicodeString(sliceUnicodeString(cartItem.remarks, 0, 48), 48, ' ')}${ESCPOS_CMD.NEWLINE}`;
                  }

                  if (cartItem.addOns) {
                    result += `${ESCPOS_CMD.NEWLINE}`; // more tidy

                    for (var j = 0; j < cartItem.addOns.length; j++) {
                      var addOnStr = cartItem.addOns[j].name + ': ';

                      addOnStr += cartItem.addOns[j].choiceNames.join(', ');

                      // result += `${ESCPOS_CMD.LEFT}${addOnStr
                      //   .slice(0, 19)
                      //   .padEnd(20, ' ')}${''.padEnd(8, ' ')}${''.padEnd(
                      //   8,
                      //   ' ',
                      // )}${''.padEnd(4, ' ')}${''.padEnd(8, ' ')}${ESCPOS_CMD.NEWLINE}`;

                      result += `${ESCPOS_CMD.LEFT}${padEndUnicodeString(sliceUnicodeString(addOnStr, 0, 48), 48, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    }
                  }

                  if (i !== userOrder.cartItems.length - 1) {
                    result += `${ESCPOS_CMD.NEWLINE}`;
                  }
                }

                result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                  .map((i) => '-')
                  .join('')}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'Subtotal'.padEnd(24, ' ')}${(
                //   'RM ' + userOrder.totalPrice.toFixed(2)
                // ).padStart(24, ' ')}`;
                // result += `${ESCPOS_CMD.LEFT}${'Discount'.padEnd(24, ' ')}${(
                //   'RM ' + userOrder.discount.toFixed(2)
                // ).padStart(24, ' ')}`;

                // if (userOrder.orderType === ORDER_TYPE.DELIVERY) {
                //   result += `${ESCPOS_CMD.LEFT}${'Discount'.padEnd(24, ' ')}${(
                //     'RM ' + userOrder.deliveryFee.toFixed(2)
                //   ).padStart(24, ' ')}`;
                // }

                // result += `${ESCPOS_CMD.LEFT}${'Tax (6%)'.padEnd(24, ' ')}${(
                //   'RM ' + userOrder.tax.toFixed(2)
                // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}${'Total'.padEnd(
                //   12,
                //   ' ',
                // )}${('RM ' + userOrder.finalPrice.toFixed(2)).padStart(12, ' ')}${ESCPOS_CMD.NEWLINE
                //   }`;
                // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
                //   .map((i) => '-')
                //   .join('')}${ESCPOS_CMD.NEWLINE}`;

                // var amountReceived = userOrder.finalPrice;
                // var amountBalance = 0;

                // if (
                //   isSplitReceipt &&
                //   userOrder.splitAmountList &&
                //   userOrder.splitAmountList.length > 0
                // ) {
                //   amountReceived =
                //     userOrder.splitAmountList[userOrder.splitAmountList.length - 1]
                //       .amount;

                //   var totalPaidAmount = userOrder.splitAmountList.reduce(
                //     (accum, splitAmount) => accum + splitAmount.amount,
                //     0,
                //   );
                //   amountBalance = userOrder.finalPrice - totalPaidAmount;
                // }

                // //   if (userOrder.paymentDetails) {
                // //     amountReceived = userOrder.finalPrice;
                // //     amountBalance = 0;
                // //   }
                // //   else {
                // //     amountReceived = 0;
                // //     amountBalance = userOrder.finalPrice;
                // //   }

                // result += `${ESCPOS_CMD.LEFT}${'Received'.padEnd(24, ' ')}${(
                //   'RM ' + amountReceived.toFixed(2)
                // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'Balance'.padEnd(24, ' ')}${(
                //   'RM ' + amountBalance.toFixed(2)
                // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                // result += `${ESCPOS_CMD.LEFT}${'Notes:'}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'1.'}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'2.'}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'3.'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE
                //   }${ESCPOS_CMD.NEWLINE}`;

                // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_2H
                //   }${'Thank you for your order'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                result += `${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.BARCODE_HEIGHT}${ESCPOS_CMD.BARCODE_WIDTH}${ESCPOS_CMD.BARCODE_FONT_A}${ESCPOS_CMD.BARCODE_TXT_OFF}${ESCPOS_CMD.BARCODE_EAN13}${userOrder.receiptId}${ESCPOS_CMD.NEWLINE}`;

                // result += `${ESCPOS_CMD.CENTER}${printBarcode({
                //   data: 'TEST12345',
                //   type: 'ean',
                // }, 'cp936')}${ESCPOS_CMD.NEWLINE}`;

                result += `${ESCPOS_CMD.CUT_PARTIAL}`;

                NetPrinter.printText(result, {
                  encoding: 'GB18030',
                });
              } catch (ex) {
                console.log(ex);
              }
            }
            else if (printer.types[indexPrinterType] === PRINTER_USAGE_TYPE.ORDER_SUMMARY &&
              printerUsageType === PRINTER_USAGE_TYPE.ORDER_SUMMARY) {
              try {
                var result = `${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.UNDERLINE_OFF}${ESCPOS_CMD.BOLD_OFF}`;
                // if (merchantLogoImageElement) {
                //   // result += `${getPrinterImageData(JSON.parse(merchantLogoData), 0, 0, 0, 0)}`

                //   const encoder = new EscPosEncoder({
                //     // imageMode: 'raster',
                //   });

                //   const merchantLogoImageElementParsed = JSON.parse(merchantLogoImageElement);

                //   const imageBytes = encoder
                //     .initialize()
                //     .image(merchantLogoImageElementParsed, 256, 256, 'atkinson')
                //     .encode();

                //   // const imageBase64 = Buffer.from(imageBytes).toString('base64');            

                //   await NetPrinter.printBuffer(Buffer.from(imageBytes).toString('base64'));
                // }

                // if (merchantLogoData) {
                //   const encoder = new EscPosEncoder({
                //     // imageMode: 'raster',
                //   });

                //   const merchantLogoDataParsed = JSON.parse(merchantLogoData);

                //   const imageBytes = encoder
                //     .initialize()
                //     .image(merchantLogoDataParsed, 128, 128, 'atkinson')
                //     .encode();

                //   // const imageBase64 = Buffer.from(imageBytes).toString('base64');            

                //   await NetPrinter.printBuffer(Buffer.from(imageBytes).toString('base64'));
                // }

                // result += `${ESCPOS_CMD.CENTER}${userOrder.outletName}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.CENTER}${userOrder.outletAddress ? userOrder.outletAddress : 'N/A'
                //   }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.CENTER}${userOrder.outletPhone ? userOrder.outletPhone : 'N/A'
                //   }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.CENTER}${userOrder.merchantName}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.CENTER}SST ID No. ${userOrder.outletTaxNumber ? userOrder.outletTaxNumber : 'N/A'
                //   }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}TABLE: ${userOrder.tableCode ? userOrder.tableCode : 'N/A'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}ORDER #${userOrder.orderId}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
                //   .map((i) => '-')
                //   .join('')}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${userOrder.userName ? userOrder.userName : 'N/A'
                //   }${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${userOrder.userPhone ? userOrder.userPhone : 'N/A'
                //   }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${moment(userOrder.orderDate).format('YYYY.MM.DD')}  ${moment(userOrder.orderDate).format('HH:mm:ss')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'Receipt Date'.padEnd(12, ' ')} : ${moment(
                //   userOrder.orderDate,
                // ).format('ddd, MMM D, YYYY')}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'Receipt #'.padEnd(12, ' ')} : ${'#'}${userOrder.orderId
                //   }${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'Cashier'.padEnd(12, ' ')} : ${userOrder.waiterName ? userOrder.waiterName : 'N/A'
                  }${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.LEFT}${'ITEM'.padEnd(20, ' ')}${''.padEnd(
                  8,
                  ' ',
                )}${''.padEnd(8, ' ')}${''.padEnd(4, ' ')}${'QTY'.padStart(
                  8,
                  ' ',
                )}${ESCPOS_CMD.NEWLINE}`;
                result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                  .map((i) => '-')
                  .join('')}${ESCPOS_CMD.NEWLINE}`;

                for (var i = 0; i < userOrder.cartItems.length; i++) {
                  const cartItem = userOrder.cartItems[i];

                  result += `${ESCPOS_CMD.LEFT}${padEndUnicodeString(sliceUnicodeString(cartItem.name, 0, 42), 44, ' ')}${('' + cartItem.quantity).padStart(4, ' ')}${ESCPOS_CMD.NEWLINE}`;

                  if (cartItem.remarks) {
                    //   result += `${ESCPOS_CMD.LEFT}${cartItem.remarks
                    //     .slice(0, 19)
                    //     .padEnd(20, ' ')}${''.padEnd(8, ' ')}${''.padEnd(
                    //     8,
                    //     ' ',
                    //   )}${''.padEnd(4, ' ')}${''.padEnd(8, ' ')}${ESCPOS_CMD.NEWLINE}`;

                    result += `${ESCPOS_CMD.LEFT}${padEndUnicodeString(sliceUnicodeString(cartItem.remarks, 0, 48), 48, ' ')}${ESCPOS_CMD.NEWLINE}`;
                  }

                  if (cartItem.addOns) {
                    result += `${ESCPOS_CMD.NEWLINE}`; // more tidy

                    for (var j = 0; j < cartItem.addOns.length; j++) {
                      var addOnStr = cartItem.addOns[j].name + ': ';

                      addOnStr += cartItem.addOns[j].choiceNames.join(', ');

                      // result += `${ESCPOS_CMD.LEFT}${addOnStr
                      //   .slice(0, 19)
                      //   .padEnd(20, ' ')}${''.padEnd(8, ' ')}${''.padEnd(
                      //   8,
                      //   ' ',
                      // )}${''.padEnd(4, ' ')}${''.padEnd(8, ' ')}${ESCPOS_CMD.NEWLINE}`;

                      result += `${ESCPOS_CMD.LEFT}${padEndUnicodeString(sliceUnicodeString(addOnStr, 0, 48), 48, ' ')}${ESCPOS_CMD.NEWLINE}`;
                    }
                  }

                  if (i !== userOrder.cartItems.length - 1) {
                    result += `${ESCPOS_CMD.NEWLINE}`;
                  }
                }

                result += `${ESCPOS_CMD.CENTER}${[...Array(48)]
                  .map((i) => '-')
                  .join('')}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'Subtotal'.padEnd(24, ' ')}${(
                //   'RM ' + userOrder.totalPrice.toFixed(2)
                // ).padStart(24, ' ')}`;
                // result += `${ESCPOS_CMD.LEFT}${'Discount'.padEnd(24, ' ')}${(
                //   'RM ' + userOrder.discount.toFixed(2)
                // ).padStart(24, ' ')}`;

                // if (userOrder.orderType === ORDER_TYPE.DELIVERY) {
                //   result += `${ESCPOS_CMD.LEFT}${'Discount'.padEnd(24, ' ')}${(
                //     'RM ' + userOrder.deliveryFee.toFixed(2)
                //   ).padStart(24, ' ')}`;
                // }

                // result += `${ESCPOS_CMD.LEFT}${'Tax (6%)'.padEnd(24, ' ')}${(
                //   'RM ' + userOrder.tax.toFixed(2)
                // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${ESCPOS_CMD.SIZE_2X}${'Total'.padEnd(
                //   12,
                //   ' ',
                // )}${('RM ' + userOrder.finalPrice.toFixed(2)).padStart(12, ' ')}${ESCPOS_CMD.NEWLINE
                //   }`;
                // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${[...Array(48)]
                //   .map((i) => '-')
                //   .join('')}${ESCPOS_CMD.NEWLINE}`;

                // var amountReceived = userOrder.finalPrice;
                // var amountBalance = 0;

                // if (
                //   isSplitReceipt &&
                //   userOrder.splitAmountList &&
                //   userOrder.splitAmountList.length > 0
                // ) {
                //   amountReceived =
                //     userOrder.splitAmountList[userOrder.splitAmountList.length - 1]
                //       .amount;

                //   var totalPaidAmount = userOrder.splitAmountList.reduce(
                //     (accum, splitAmount) => accum + splitAmount.amount,
                //     0,
                //   );
                //   amountBalance = userOrder.finalPrice - totalPaidAmount;
                // }

                // //   if (userOrder.paymentDetails) {
                // //     amountReceived = userOrder.finalPrice;
                // //     amountBalance = 0;
                // //   }
                // //   else {
                // //     amountReceived = 0;
                // //     amountBalance = userOrder.finalPrice;
                // //   }

                // result += `${ESCPOS_CMD.LEFT}${'Received'.padEnd(24, ' ')}${(
                //   'RM ' + amountReceived.toFixed(2)
                // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'Balance'.padEnd(24, ' ')}${(
                //   'RM ' + amountBalance.toFixed(2)
                // ).padStart(24, ' ')}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                // result += `${ESCPOS_CMD.LEFT}${'Notes:'}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'1.'}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'2.'}${ESCPOS_CMD.NEWLINE}`;
                // result += `${ESCPOS_CMD.LEFT}${'3.'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE
                //   }${ESCPOS_CMD.NEWLINE}`;

                // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_2H
                //   }${'Thank you for your order'}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                result += `${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}${ESCPOS_CMD.NEWLINE}`;

                // result += `${ESCPOS_CMD.CENTER}${ESCPOS_CMD.SIZE_NORMAL}${ESCPOS_CMD.BARCODE_HEIGHT}${ESCPOS_CMD.BARCODE_WIDTH}${ESCPOS_CMD.BARCODE_FONT_A}${ESCPOS_CMD.BARCODE_TXT_OFF}${ESCPOS_CMD.BARCODE_EAN13}${userOrder.receiptId}${ESCPOS_CMD.NEWLINE}`;

                // result += `${ESCPOS_CMD.CENTER}${printBarcode({
                //   data: 'TEST12345',
                //   type: 'ean',
                // }, 'cp936')}${ESCPOS_CMD.NEWLINE}`;

                result += `${ESCPOS_CMD.CUT_PARTIAL}`;

                NetPrinter.printText(result, {
                  encoding: 'GB18030',
                });
              } catch (ex) {
                console.log(ex);
              }
            }
          }
        }
      }
    }
  }
};

const isASCII = (str) => {
  return /^[\x00-\x7F]*$/.test(str);
}

// export const encodeGBK = (str) => {
//   return str.replace(/./g, function (a) {
//     var code = a.charCodeAt(0);
//     if (isASCII(code)) {
//       return encodeURIComponent(a);
//     } else {
//       var key = code.toString(16);
//       if (key.length != 4) key = ('000' + key).match(/....$/)[0];
//       return U2Ghash[key] || a;
//     }
//   });
// }

export const encodeGB18030 = (str) => {
  if (isASCII(str)) {
    return str;
  }
  else {
    // const encoder = new EscPosEncoder();

    // const result = encoder
    //   .initialize()
    //   .codepage('gb18030')
    //   .text(str)
    //   .encode();

    var result = iconv.encode(str, 'GB18030');
    // result = iconv.encode(str, 'ISO-8859-1');

    console.log('testing text...')

    console.log(utf8ArrayToStr(result));
    return (utf8ArrayToStr(result));

    // console.log(utf8ByteToUnicodeStr(result));
    // return (utf8ByteToUnicodeStr(result));

    // console.log(result.toString('base64'));
    // return (result.toString('base64'));

    // console.log(result.toString('hex').replace(/../g, '\\x$&'));
    // console.log(hexToCMD(result.toString('hex').replace(/../g, '\\x$&')));
    // return hexToCMD(result.toString('hex').replace(/../g, '\\x$&'));

    // console.log(Buffer.from(result).toString());
    // return Buffer.from(result).toString();

    // console.log(bufferToHex(result));
    // console.log(hexToCMD(bufferToHex(result)));
    // return hexToCMD(bufferToHex(result));

    // const strNew = new CustomTextEncoder('gb18030').encode(str);

    // console.log(hexToCMD(bufferToHex(strNew)));

    // return hexToCMD(bufferToHex(strNew));
  }
}

const hexToCMD = (hexStr) => {
  return hexStr.replace(/\\x([0-9A-F]{2})/gi, function () {
    // console.log(arguments);
    return String.fromCharCode(parseInt(arguments[1], 16));
  });

};

const bufferToHex = (buffer) => { // buffer is an ArrayBuffer
  return Array.prototype.map.call(new Uint8Array(buffer), x => ("\\x" + ('00' + x.toString(16)).slice(-2))).join('');
};

const chunk = (str, n) => {
  var ret = [];
  var i;
  var len;

  for (i = 0, len = str.length; i < len; i += n) {
    ret.push(str.substr(i, n))
  }

  return ret
};

const utf8ArrayToStr = (array) => {
  var out, i, len, c;
  var char2, char3;

  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12: case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0F) << 12) |
          ((char2 & 0x3F) << 6) |
          ((char3 & 0x3F) << 0));
        break;
    }
  }

  return out;
}

// https://codereview.stackexchange.com/a/3589/75693
function bytesToString(bytes) {
  var chars = [];
  for (var i = 0, n = bytes.length; i < n;) {
    chars.push(((bytes[i++] & 0xff) << 8) | (bytes[i++] & 0xff));
  }
  return String.fromCharCode.apply(null, chars);
}

// https://codereview.stackexchange.com/a/3589/75693
function stringToBytes(str) {
  var bytes = [];
  for (var i = 0, n = str.length; i < n; i++) {
    var char = str.charCodeAt(i);
    bytes.push(char >>> 8, char & 0xFF);
  }
  return bytes;
}

function utf8ByteToUnicodeStr(utf8Bytes) {
  var unicodeStr = "";
  for (var pos = 0; pos < utf8Bytes.length;) {
    var flag = utf8Bytes[pos];
    var unicode = 0;
    if ((flag >>> 7) === 0) {
      unicodeStr += String.fromCharCode(utf8Bytes[pos]);
      pos += 1;

    } else if ((flag & 0xFC) === 0xFC) {
      unicode = (utf8Bytes[pos] & 0x3) << 30;
      unicode |= (utf8Bytes[pos + 1] & 0x3F) << 24;
      unicode |= (utf8Bytes[pos + 2] & 0x3F) << 18;
      unicode |= (utf8Bytes[pos + 3] & 0x3F) << 12;
      unicode |= (utf8Bytes[pos + 4] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos + 5] & 0x3F);
      unicodeStr += String.fromCharCode(unicode);
      pos += 6;

    } else if ((flag & 0xF8) === 0xF8) {
      unicode = (utf8Bytes[pos] & 0x7) << 24;
      unicode |= (utf8Bytes[pos + 1] & 0x3F) << 18;
      unicode |= (utf8Bytes[pos + 2] & 0x3F) << 12;
      unicode |= (utf8Bytes[pos + 3] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos + 4] & 0x3F);
      unicodeStr += String.fromCharCode(unicode);
      pos += 5;

    } else if ((flag & 0xF0) === 0xF0) {
      unicode = (utf8Bytes[pos] & 0xF) << 18;
      unicode |= (utf8Bytes[pos + 1] & 0x3F) << 12;
      unicode |= (utf8Bytes[pos + 2] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos + 3] & 0x3F);
      unicodeStr += String.fromCharCode(unicode);
      pos += 4;

    } else if ((flag & 0xE0) === 0xE0) {
      unicode = (utf8Bytes[pos] & 0x1F) << 12;;
      unicode |= (utf8Bytes[pos + 1] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos + 2] & 0x3F);
      unicodeStr += String.fromCharCode(unicode);
      pos += 3;

    } else if ((flag & 0xC0) === 0xC0) { //110
      unicode = (utf8Bytes[pos] & 0x3F) << 6;
      unicode |= (utf8Bytes[pos + 1] & 0x3F);
      unicodeStr += String.fromCharCode(unicode);
      pos += 2;

    } else {
      unicodeStr += String.fromCharCode(utf8Bytes[pos]);
      pos += 1;
    }
  }
  return unicodeStr;
}

// function _arrayBufferToString(buf, callback) {
//   var bb = new Blob([new Uint8Array(buf)]);
//   var f = new FileReader();
//   f.onload = function(e) {
//     callback(e.target.result);
//   };
//   f.readAsText(bb);
// }
export const sliceUnicodeStringV2 = function (str, n) {
  var r = /[^\x00-\xff]/g;
  if (str.replace(r, '@@').length <= n) {
    return str;
  }
  // var m = Math.floor(n / 2);
  var m = Math.ceil(n / 2);
  for (var i = m; i < str.length; i++) {
    if (str.substr(0, i).replace(r, '@@').length >= n) {
      // return str.substr(0, i) + '...';
      return str.substr(0, i);
    }
  }
  return str;
};

export const sliceUnicodeString = (str, start, end) => {
  var unicodeCharLength = countNonASCII(str);
  // var realLength = str.length + unicodeCharLength;

  // for (var i = 0; i < str.length; i++) {
  //   if (isASCII(str[i])) {

  //   }
  // }

  if (unicodeCharLength > 0) {
    return str.slice(start, end / 2);
  }
  else {
    return str.slice(start, end);
  }
};

export const padEndUnicodeString = (str, len, strPad) => {
  var unicodeCharLength = countNonASCII(str);
  // var realLength = str.length + unicodeCharLength;

  // for (var i = 0; i < str.length; i++) {
  //   if (isASCII(str[i])) {

  //   }
  // }

  if (unicodeCharLength > 0) {
    // return str.padEnd(len / 2, strPad);
    return str.padEnd(len - unicodeCharLength, strPad);
  }
  else {
    return str.padEnd(len, strPad);
  }
};
export const padEndUnicodeStringV2 = function (str, n, strPad) {
  var r = /[^\x00-\xff]/g;

  var padCharNum = Math.max(n - str.replace(r, '@@').length, 0);

  return str + strPad.repeat(padCharNum);
};

function countNonASCII(str) {
  var m = str.match(/[^\x00-\x80]/g);
  return (!m ? 0 : m.length);
}

(function () {
  'use strict';

  var DEFAULT_MAX_DEPTH = 6;
  var DEFAULT_ARRAY_MAX_LENGTH = 50;
  var seen; // Same variable used for all stringifications

  Date.prototype.toPrunedJSON = Date.prototype.toJSON;
  String.prototype.toPrunedJSON = String.prototype.toJSON;

  var cx = /[\u0000\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
    escapable = /[\\\"\x00-\x1f\x7f-\x9f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g,
    meta = {    // table of character substitutions
      '\b': '\\b',
      '\t': '\\t',
      '\n': '\\n',
      '\f': '\\f',
      '\r': '\\r',
      '"': '\\"',
      '\\': '\\\\'
    };

  function quote(string) {
    escapable.lastIndex = 0;
    return escapable.test(string) ? '"' + string.replace(escapable, function (a) {
      var c = meta[a];
      return typeof c === 'string'
        ? c
        : '\\u' + ('0000' + a.charCodeAt(0).toString(16)).slice(-4);
    }) + '"' : '"' + string + '"';
  }

  function str(key, holder, depthDecr, arrayMaxLength) {
    var i,          // The loop counter.
      k,          // The member key.
      v,          // The member value.
      length,
      partial,
      value = holder[key];
    if (value && typeof value === 'object' && typeof value.toPrunedJSON === 'function') {
      value = value.toPrunedJSON(key);
    }

    switch (typeof value) {
      case 'string':
        return quote(value);
      case 'number':
        return isFinite(value) ? String(value) : 'null';
      case 'boolean':
      case 'null':
        return String(value);
      case 'object':
        if (!value) {
          return 'null';
        }
        if (depthDecr <= 0 || seen.indexOf(value) !== -1) {
          return '"-pruned-"';
        }
        seen.push(value);
        partial = [];
        if (Object.prototype.toString.apply(value) === '[object Array]') {
          length = Math.min(value.length, arrayMaxLength);
          for (i = 0; i < length; i += 1) {
            partial[i] = str(i, value, depthDecr - 1, arrayMaxLength) || 'null';
          }
          v = partial.length === 0
            ? '[]'
            : '[' + partial.join(',') + ']';
          return v;
        }
        for (k in value) {
          if (Object.prototype.hasOwnProperty.call(value, k)) {
            try {
              v = str(k, value, depthDecr - 1, arrayMaxLength);
              if (v) partial.push(quote(k) + ':' + v);
            } catch (e) {
              // this try/catch due to some "Accessing selectionEnd on an input element that cannot have a selection." on Chrome
            }
          }
        }
        v = partial.length === 0
          ? '{}'
          : '{' + partial.join(',') + '}';
        return v;
    }
  }

  JSON.pruned = function (value, depthDecr, arrayMaxLength) {
    seen = [];
    depthDecr = depthDecr || DEFAULT_MAX_DEPTH;
    arrayMaxLength = arrayMaxLength || DEFAULT_ARRAY_MAX_LENGTH;
    return str('', { '': value }, depthDecr, arrayMaxLength);
  };

}());
